<template>
    <div class="w-100">
        <v-app id="my_vue_app">
            <v-card>
                <v-row no-gutters class="m-2">
                    <v-col cols="12" sm="12" md="3" class="p-1">
                        <v-autocomplete
                            v-model="filters.customer_id"
                            :items="GET_CUSTOMERS_LIST_SELECTION"
                            label="Customer"
                            outlined
                            hide-details
                            persistent-placeholder
                            auto-select-first
                            cache-items
                            allow-overflow
                            clearable
                        >
                            <template v-slot:selection="{ item }">
                                <span class="dropdown-selection">
                                    {{ item.text }}
                                </span>
                            </template>
                            <template v-slot:item="{ item }">
                                <span class="dropdown-items">
                                    {{ item.text }}
                                </span>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="2" class="p-1">
                        <v-autocomplete
                            v-model="filters.so_type"
                            :items="list.so_type_list"
                            label="So Type"
                            outlined
                            hide-details
                            persistent-placeholder
                            auto-select-first
                            allow-overflow
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="1" class="p-1">
                        <v-autocomplete
                            v-model="filters.delivery_method"
                            :items="list.delivery_method_list"
                            label="Delivery Method"
                            outlined
                            hide-details
                            persistent-placeholder
                            auto-select-first
                            allow-overflow
                        ></v-autocomplete>
                    </v-col>

                    <v-col cols="6" sm="4" md="1" class="p-1">
                        <v-menu
                            v-model="filters.menu_date_from"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                            v-if="tab==='scheduling'"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.sales_order_dates[0]"
                                    readonly
                                    hide-details
                                    outlined
                                    v-on="on"
                                    label="Date From"
                                >
                                </v-text-field>
                            </template>
                            <v-date-picker v-model="filters.sales_order_dates[0]" @input="filters.menu_date_from = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="6" sm="4" md="1" class="p-1">
                        <v-menu
                            v-model="filters.menu_date_to"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                            v-if="tab==='scheduling'"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.sales_order_dates[1]"
                                    readonly
                                    hide-details
                                    outlined
                                    v-on="on"
                                    label="Date To"
                                >
                                </v-text-field>
                            </template>
                            <v-date-picker v-model="filters.sales_order_dates[1]" @input="filters.menu_date_to = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="12" sm="12" md="1" class="p-1 align-items-end">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                @click="filter()"
                                icon
                                v-bind="attrs"
                                v-on="on"
                                :loading="sales_orders.loading"
                            >
                                <v-icon color="primary">mdi-sync</v-icon>
                            </v-btn>
                            </template>
                            <span>Filter</span>
                        </v-tooltip>

                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12" md="2" class="p-1 align-items-end">
                        <v-text-field
                            v-model="filters.order_num"
                            label="Search SO#"
                            append-icon="mdi-magnify"
                            class="mx-6"
                            single-line
                            hide-details
                            @click:append="filter()"
                            @keydown.enter="filter()"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters class="mx-2" v-if="tab === 'scheduling'">
                    <v-col cols="12">
                        <v-data-table
                            :headers="sales_orders.headers"
                            :items="sales_orders.list"
                            :search="filters.general"
                            :loading="sales_orders.loading"
                            :options.sync="sales_orders.options"
                            :server-items-length="sales_orders.total_records"
                            :items-per-page="sales_orders.items_per_page"
                            :footer-props="{
                                itemsPerPageOptions: sales_orders.items_per_page_options,
                                showFirstLastPage: true
                            }"
                            :disable-sort="$vuetify.breakpoint.mobile"
                            fixed-header
                        >
                            <template v-slot:[`item.total_cbm`]="{ item }">
                                {{ item.total_cbm | currency }}
                            </template>
                            <template v-slot:[`item.total_estimate_cbm`]="{ item }">
                                {{ item.total_estimate_cbm | currency }}
                            </template>
                            <template v-slot:[`item.total_weight`]="{ item }">
                                {{ item.total_weight | currency }}
                            </template>
                            <template v-slot:[`item.vehicle_cbm`]="{ item }">
                                {{ item.vehicle_cbm | currency }}
                            </template>
                            <template v-slot:[`item.vehicle_weight`]="{ item }">
                                {{ item.vehicle_weight | currency }}
                            </template>
                            <!-- <template v-slot:[`item.total_amount`]="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span v-bind="attrs" v-on="on" @click="view(item)">
                                            {{ item.total_amount | currency }}
                                        </span>
                                    </template>
                                    Click Amount to View Orders
                                </v-tooltip>
                            </template> -->
                            <template v-slot:[`item.delivery_comment`]="{ item }">
                                <span v-if="tab === 'scheduling'">
                                    <v-textarea
                                        v-model="item.delivery_comment"
                                        :auto-grow="true"
                                        rows="1"
                                        outlined
                                        dense
                                        hide-details
                                    >
                                    </v-textarea>
                                </span>
                                <span v-else>
                                    {{ item.delivery_comment }}
                                </span>
                            </template>
                            <template v-slot:[`item.delivery_date`]="{ item }">
                                <span v-if="tab === 'scheduling'">
                                    <v-datetime-picker
                                        v-model="item.delivery_date"
                                        date-format="yyyy-MM-dd"
                                        time-format="HH:mm"
                                        :text-field-props="{
                                            readonly: true,
                                            outlined: true,
                                            dense: true,
                                            hideDetails: true,
                                            // appendIcon: 'mdi-calendar'
                                        }"
                                        @input="updateDeliveryDate(item)"
                                    ></v-datetime-picker>
                                </span>
                                <span v-else>
                                    {{ item.delivery_date }}
                                </span>
                            </template>
                            <template v-slot:[`item.vehicle_id`]="{ item, index }">
                                <v-autocomplete
                                    v-model="item.vehicle_id"
                                    :items="list.vehicle_list"
                                    :loading="loading.vehicle_list_selection"
                                    :disabled="loading.vehicle_list_selection"
                                    outlined
                                    dense
                                    hide-details
                                    auto-select-first
                                    cache-items
                                    allow-overflow
                                    @input="updateVehicleDetails(item, index, 'sales_orders')"
                                ></v-autocomplete>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            v-bind="attrs"
                                            v-on="on"
                                            text
                                            icon
                                            @click="view(item)"
                                        >
                                            <v-icon small color="orange">mdi-eye</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>View Orders</span>
                                </v-tooltip>
                                <!-- <v-tooltip top v-if="tab==='scheduling'">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            v-bind="attrs"
                                            v-on="on"
                                            text
                                            icon
                                            @click="set(item)"
                                        >
                                            <v-icon small color="blue">fas fa-check</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Update</span>
                                </v-tooltip> -->
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
                <v-row no-gutters class="mx-2" v-else-if="tab === 'scheduled'">
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <v-sheet
                            tile
                            height="54"
                            class="d-flex"
                        >
                            <v-btn
                                icon
                                class="mx-1"
                                @click="$refs.calendar.prev()"
                            >
                                <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>
                            <v-btn
                                icon
                                class="ml-1 mr-4"
                                @click="$refs.calendar.next()"
                            >
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>
                            <v-btn
                                @click="setToday"
                            >
                                Today
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-toolbar-title v-if="$refs.calendar">
                                {{ currentDay }}
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-menu bottom right>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on">
                                        <span>{{ calendar.calendar_view.value| uppercase }}</span>
                                        <v-icon right>mdi-menu-down</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item v-for="calendar_view in calendar.calendar_view.list" :key="calendar_view" @click="calendar.calendar_view.value = calendar_view">
                                        <v-list-item-title>{{ calendar_view | capitalize }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-sheet>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <v-sheet>
                            <v-calendar
                                ref="calendar"
                                v-model="calendar.value"
                                :weekdays="calendar.week_view.value"
                                :type="calendar.calendar_view.value"
                                :events="calendar.events"
                                :event-overlap-mode="calendar.overlap_mode"
                                :event-overlap-threshold="30"
                                :event-color="getEventColor"
                                :interval-height="96"
                                :interval-minutes="30"
                                event-name="event"
                                dark
                                @change="getEvents"
                                @click:event="showOrders"
                                @click:more="viewDay"
                                @click:date="viewDay"
                            ></v-calendar>
                        </v-sheet>
                    </v-col>
                </v-row>
                <v-dialog :width="$vuetify.breakpoint.mobile ? '100%' : '95%'" v-model="view_orders.show" scrollable>
                    <v-card>
                        <v-container fluid class="p-0" style="overflow-y: scroll">
                            <v-card-text class="p-0">
                                <v-row no-gutters>
                                    <v-col cols="10">
                                        <v-card-title>
                                            {{ `${view_orders.parameters.date} Orders of ${view_orders.parameters.customer_name}` }}
                                        </v-card-title>
                                    </v-col>
                                    <v-col cols="2" class="pull-right">
                                        <v-btn text icon color="gray" class="float-right" @click="toggle(false)">
                                            <v-icon>mdi-close-circle</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-divider class="ma-1 pa-0"></v-divider>
                                <v-row no-gutters>
                                    <!-- <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="px-2">
                                        <div class="info-box m-0">
                                            <span class="info-box-icon bg-success elevation-1"><i class="mdi-cart"></i></span>
                                            <div class="info-box-content">
                                                <span class="info-box-text">SO Total Amount</span>
                                                <span class="info-box-number">&#x20b1;{{ soTotalAmount | currency }}</span>
                                            </div>
                                        </div>
                                    </v-col> -->
                                    <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="px-2">
                                        <v-card
                                            class="total-cards"
                                            elevation="2"
                                        >
                                            <v-card-text
                                                class="d-flex justify-content-start align-items-center">
                                                <div class="card-icon pt-3 pb-3 pl-2 pr-2 rounded" style="background-color: #28a745;">
                                                    <v-icon class="text-white" style="font-size:40px">mdi-cart</v-icon>
                                                </div>
                                                <div class="card-details ml-2">
                                                    <p class="mb-2">Total Item CBM</p>
                                                    <b style="color:black">{{totalItemCbm | currency}}</b>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="px-2">
                                        <v-card
                                            class="total-cards"
                                            elevation="2"
                                        >
                                            <v-card-text
                                                class="d-flex justify-content-start align-items-center">
                                                <div class="card-icon pt-3 pb-3 pl-2 pr-2 rounded" style="background-color: #28a745;">
                                                    <v-icon class="text-white" style="font-size:40px">mdi-cart</v-icon>
                                                </div>
                                                <div class="card-details ml-2">
                                                    <p class="mb-2">Total Estimate CBM</p>
                                                    <b style="color:black">{{totalEstimateCbm | currency}}</b>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="px-2">
                                        <v-card
                                            class="total-cards"
                                            elevation="2"
                                        >
                                            <v-card-text
                                                class="d-flex justify-content-start align-items-center">
                                                <div class="card-icon pt-3 pb-3 pl-2 pr-2 rounded" style="background-color: #28a745;">
                                                    <v-icon class="text-white" style="font-size:40px">mdi-cart</v-icon>
                                                </div>
                                                <div class="card-details ml-2">
                                                    <p class="mb-2">Total Weight (kgs)</p>
                                                    <b style="color:black">{{totalWeight | currency}}</b>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="px-2">
                                        <v-card
                                            class="total-cards"
                                            elevation="2"
                                        >
                                            <v-card-text
                                                class="d-flex justify-content-start align-items-center">
                                                <div class="card-icon pt-3 pb-3 pl-2 pr-2 rounded" style="background-color: #28a745;">
                                                    <v-icon class="text-white" style="font-size:40px">mdi-cash-multiple</v-icon>
                                                </div>
                                                <div class="card-details ml-2">
                                                    <p class="mb-2">Total so Amount</p>
                                                    <b style="color:black">{{ totalAmount | currency}}</b>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="px-2">
                                        <v-card
                                            class="total-cards"
                                            elevation="2"
                                        >
                                            <v-card-text
                                                class="d-flex justify-content-start align-items-center">
                                                <div class="card-icon pt-3 pb-3 pl-2 pr-2 rounded" style="background-color: #28a745;">
                                                    <v-icon class="text-white" style="font-size:40px">mdi-cash-multiple</v-icon>
                                                </div>
                                                <div class="card-details ml-2">
                                                    <p class="mb-2">Total can Serve Amount</p>
                                                    <b style="color:black">{{ defaultWarehouse | currency}}</b>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-spacer></v-spacer>
                                    <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="px-2 d-flex flex-row-reverse justify-end">
                                        <span class="px-1" v-if="update_selected">
                                            <v-btn @click="updateSelected()" :loading="loading.setLoading">Update Selected</v-btn>
                                        </span>
                                        <span class="px-1" v-else>
                                            <v-btn @click="massUpdate()" :loading="loading.setLoading">All</v-btn>
                                        </span>
                                        <span class="px-1">
                                            <v-datetime-picker
                                                v-model="view_orders.updates.delivery_date"
                                                date-format="yyyy-MM-dd"
                                                time-format="HH:mm"
                                                label="Delivery Date"
                                                :text-field-props="{
                                                    readonly: true,
                                                    outlined: true,
                                                    dense: true,
                                                    hideDetails: true,
                                                    appendIcon: 'mdi-calendar',
                                                    persistentPlaceholder: true,
                                                    label:'Delivery Date',
                                                }"
                                                @input="updateDeliveryDate(view_orders.updates)"
                                            ></v-datetime-picker>
                                        </span>
                                        <span class="px-1">
                                            <v-autocomplete
                                                v-model="view_orders.updates.vehicle_id"
                                                :items="list.vehicle_list"
                                                :loading="loading.vehicle_list_selection"
                                                :disabled="loading.vehicle_list_selection"
                                                label="Vehicle"
                                                outlined
                                                dense
                                                hide-details
                                                auto-select-first
                                                persistent-placeholder
                                                cache-items
                                                allow-overflow
                                            ></v-autocomplete>
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-divider class="ma-1 pa-0"></v-divider>
                                <v-row no-gutters>
                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                        <v-data-table
                                            :headers="view_orders.headers"
                                            :items="view_orders.list"
                                            :loading="view_orders.loading"
                                            :options.sync="view_orders.options"
                                            :server-items-length="view_orders.total_records"
                                            :items-per-page="view_orders.items_per_page"
                                            show-select
                                            item-key="order_num"
                                            v-model="view_orders.parameters.selectedSo"
                                            :footer-props="{
                                                itemsPerPageOptions: view_orders.items_per_page_options,
                                                showFirstLastPage: true
                                            }"
                                            :disable-sort="$vuetify.breakpoint.mobile"
                                            fixed-header
                                        >
                                            <!-- <template v-slot:[`item.so_total_amount`]="{ item }">
                                                {{ item.so_total_amount | currency }}
                                            </template> -->
                                            <template v-slot:[`item.total_cbm`]="{ item }">
                                                {{ item.total_cbm | currency }}
                                            </template>
                                            <template v-slot:[`item.total_estimate_cbm`]="{ item }">
                                                {{ item.total_estimate_cbm | currency }}
                                            </template>
                                            <template v-slot:[`item.total_weight`]="{ item }">
                                                {{ item.total_weight | currency }}
                                            </template>
                                            <template v-slot:[`item.vehicle_cbm`]="{ item }">
                                                {{ item.vehicle_cbm | currency }}
                                            </template>
                                            <template v-slot:[`item.vehicle_remaining_cbm`]="{ item }">
                                                {{ item.vehicle_remaining_cbm | currency }}
                                            </template>
                                            <template v-slot:[`item.vehicle_weight`]="{ item }">
                                                {{ item.vehicle_weight | currency }}
                                            </template>
                                            <template v-slot:[`item.vehicle_remaining_weight`]="{ item }">
                                                {{ item.vehicle_remaining_weight | currency }}
                                            </template>
                                            <template v-slot:[`item.order_num`]="{ item }">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{on, attrs}">
                                                        <span v-bind="attrs" v-on="on">{{ item.order_num }}</span>
                                                    </template>
                                                    <center>{{ item.so_created_at }}</center>
                                                </v-tooltip>
                                            </template>
                                            <template v-slot:[`item.in_wpf`]="{ item }">
                                                <span class="badge badge-primary" @click="viewDetails('wpf', item.in_wpf)" v-if="item.in_wpf != null">Yes</span>
                                                <span class="badge badge-danger" v-else>No</span>
                                            </template>
                                            <template v-slot:[`item.in_cf`]="{ item }">
                                                <span class="badge badge-primary" @click="viewDetails('cf', item.in_cf)" v-if="item.in_cf != null">Yes</span>
                                                <span class="badge badge-danger" v-else>No</span>
                                            </template>
                                            <template v-slot:[`item.in_pl`]="{ item }">
                                                <span class="badge badge-primary" @click="viewDetails('pl', item.in_pl)" v-if="item.in_pl != null">Yes</span>
                                                <span class="badge badge-danger" v-else>No</span>
                                            </template>
                                            <template v-slot:[`item.in_dispatch`]="{ item }">
                                                <span class="badge badge-primary" @click="viewDetails('dispatch', item.in_dispatch)" v-if="item.in_dispatch != null">Yes</span>
                                                <span class="badge badge-danger" v-else>No</span>
                                            </template>
                                            <template v-slot:[`item.delivery_comment`]="{ item }">
                                                <span v-if="item.in_dispatch == null">
                                                    <v-textarea
                                                        v-model="item.delivery_comment"
                                                        rows="1"
                                                        outlined
                                                        dense
                                                        hide-details
                                                        single-line
                                                    >
                                                    </v-textarea>
                                                </span>
                                                <span v-else>
                                                    {{ item.delivery_comment }}
                                                </span>
                                            </template>
                                            <template v-slot:[`item.delivery_date`]="{ item }">
                                                <span v-if="item.in_dispatch == null">
                                                    <v-datetime-picker
                                                        v-model="item.delivery_date"
                                                        date-format="yyyy-MM-dd"
                                                        time-format="HH:mm"
                                                        :disabled="isDisabled"
                                                        :text-field-props="{
                                                            readonly: true,
                                                            outlined: true,
                                                            dense: true,
                                                            hideDetails: true,
                                                            // appendIcon: 'mdi-calendar'
                                                        }"
                                                        @input="updateDeliveryDate(item)"
                                                    ></v-datetime-picker>
                                                </span>
                                                <span v-else>
                                                    {{ item.delivery_date }}
                                                </span>
                                            </template>
                                            <template v-slot:[`item.vehicle_id`]="{ item, index }">
                                                <span v-if="item.in_dispatch == null">
                                                    <v-autocomplete
                                                        v-model="item.vehicle_id"
                                                        :items="list.vehicle_list"
                                                        :loading="loading.vehicle_list_selection"
                                                        :disabled="isDisabled"
                                                        outlined
                                                        dense
                                                        hide-details
                                                        auto-select-first
                                                        cache-items
                                                        allow-overflow
                                                        @input="updateVehicleDetails(item, index, 'view_orders')"
                                                    ></v-autocomplete>
                                                </span>
                                                <span v-else>
                                                    {{ item.vehicle_name_plate_number }}
                                                </span>
                                            </template>
                                            <template v-slot:[`item.actions`]="{ item }">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            text
                                                            icon
                                                            dense
                                                            color="orange"
                                                        >
                                                            <v-icon class="btn-action" small @click="viewSalesOrder(item.sales_order_id)">mdi-eye</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>View SO</span>
                                                </v-tooltip>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            text
                                                            icon
                                                            dense
                                                            @click="set(item, true)"
                                                            loading:loading.setLoading
                                                        >
                                                            <v-icon small color="blue">mdi-check</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Update</span>
                                                </v-tooltip>
                                            </template>
                                            <template v-slot:[`item.status`]="{ item }">
                                                <span class="badge badge-saved" v-if="item.status == 0">{{ 'saved' }}</span>
                                                <span class="badge badge-for-sales" v-if="item.status == 1">{{ 'for sales' }}</span>
                                                <span class="badge badge-for-accounting" v-if="item.status == 2">{{ 'for accounting' }}</span>
                                                <span class="badge badge-approved" v-else-if="item.status == 3">{{ 'approved' }}</span>
                                                <span class="badge badge-completed" v-else-if="item.status == 4">{{ 'completed' }}</span>
                                                <span class="badge badge-warning" v-else-if="item.status == 5">{{ 'rejected' }}</span>
                                                <span class="badge badge-danger" v-else-if="item.status == 6">{{ 'canceled' }}</span>
                                                <span class="badge badge-dark" v-else-if="item.status == 7">{{ 'deleted' }}</span>
                                                <span class="badge badge-for-scheduling" v-else-if="item.status == 16">{{ 'for scheduling' }}</span>
                                                <span class="badge badge-purple" v-else-if="item.status == 8">{{ 'converted' }}</span>
                                                <span class="badge badge-exec-approved" v-else-if="item.status == 9">{{ 'exec approved' }}</span>
                                                <span class="badge badge-pending" v-else-if="item.status == 10">{{ 'partial' }}</span>
                                                <span class="badge badge-for-exec" v-else-if="item.status == 11">{{ 'for exec approval' }}</span>
                                                <span class="badge badge-warning" v-else-if="item.status == 12">{{ 'stockcard' }}</span>
                                                <span class="badge badge-submitted" v-else-if="item.status == 17">{{ 'waiting for stocks' }}</span>
                                                <span class="badge badge-preparing" v-else-if="item.status == 18">{{ 'preparing' }}</span>
                                                <span class="badge badge-prepared" v-else-if="item.status == 19">{{ 'prepared' }}</span>
                                                <span class="badge badge-checking" v-else-if="item.status == 20">{{ 'checking' }}</span>
                                                <span class="badge badge-checked" v-else-if="item.status == 21">{{ 'checked' }}</span>
                                                <span class="badge badge-invoiced" v-else-if="item.status == 22">{{ 'invoiced' }}</span>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-container>
                    </v-card>
                </v-dialog>
                <SalesOrdersViewComponentVue
                    :viewSalesOrderId="view_orders.sales_order_id"
                    :service="0"
                    @closeView="closeView"
                ></SalesOrdersViewComponentVue>
            </v-card>
        </v-app>
    </div>
</template>
<script>
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue'
import SalesOrdersViewComponentVue from '@/views/main/modules/Sales/SalesOrdersNew/SalesOrdersViewComponent.vue'
import swal from 'sweetalert2'
import { mapGetters } from 'vuex'

export default {
    mixins: [
        SharedFunctionsComponentVue,
    ],
    components: {
        SalesOrdersViewComponentVue
    },
    props: [
        'tab',
        'rush',
        'service',
    ],
    data() {
        return {
            filters: {
                customer_id: null,
                sales_order_dates: [
                    this.$dayjs().startOf('year').format('YYYY-MM-DD'),
                    this.$dayjs().endOf('month').format('YYYY-MM-DD'),
                ],
                delivery_date: null,
                menu_date_from: false,
                menu_date_to: false,
                order_num: null,
                general: null,
                delivery_method: 0, // All,
                so_type: 0
            },

            list: {
                customer_list: [],
                vehicle_list: [],
                delivery_method_list: [
                    { value: 0, text: 'All' },
                    { value: 1, text: 'Pick-up' },
                    { value: 2, text: 'Delivery' },
                ],
                so_type_list:[
                    { value:0, text:'All' },
                    { value:1, text:'Service' },
                    { value:2, text:'Non-Service' },
                ]
            },

            sales_orders: {
                headers: [
                    { text: 'SO Date', align: 'start', width: '7%', value: 'sales_order_date' },
                    { text: 'Customer', align: 'start', width: '20%', sortable: false, value: 'customer_name' },
                    { text: 'Address', align: 'start', sortable: false, value: 'shipping_address_text' },
                    { text: 'Last Updated', align: 'end', width: '6%', sortable: false, value: 'latest_sales_order_time' },
                    { text: 'Delivery Method', align: 'center', width: '8%', sortable: false, value: 'delivery_method' },
                    // { text: 'Notes', align: 'start', width: '20%', sortable: false, value: 'notes' },
                    // { text: 'Total Amount', align: 'end', width: '8%', sortable: false, value: 'total_amount' },
                    { text: 'Total Item CBM', align: 'end', width: '5%', sortable: false, value: 'total_cbm' },
                    { text: 'Total Estimate CBM', align: 'end', width: '5%', sortable: false, value: 'total_estimate_cbm' },
                    { text: 'Total Weight', align: 'end', width: '5%', sortable: false, value: 'total_weight' },
                    // { text: 'Comment', align: 'start', sortable: false, value: 'delivery_comment' },
                    // { text: 'Delivery Date', align: 'start', width: '14%', sortable: false, value: 'delivery_date' },
                    // { text: 'Vehicle', align: 'start', width: '15%', sortable: false, value: 'vehicle_id' },
                    // { text: 'Vehicle CBM', align: 'end', width: '5%', sortable: false, value: 'vehicle_cbm' },
                    // { text: 'Vehicle Weight', align: 'end', width: '5%', sortable: false, value: 'vehicle_weight' },
                    { text: 'Actions', align: 'center', width: '5%', sortable: false, value: 'actions' },
                ],
                list: [],
                options: {},
                loading: false,
                total_records: 0,
                items_per_page: 10,
                items_per_page_options: [10, 20, 50, 100],
                total_amount:0
            },

            view_orders: {
                show: false,
                parameters: {
                    customer_id: null,
                    customer_name: null,
                    date: null,
                    delivery_date: null,
                    delivery_time: null,
                    vehicle_id: null,
                    selectedSo: null,
                },
                headers: [
                    { text: 'SO#', align: 'start', width: '5%', sortable: false, value: 'order_num' },
                    // { text: 'PO#', align: 'start', width: '10%', sortable: false, value: 'customer_po_num' },
                    { text: 'Remarks', align: 'start', sortable: false, value: 'remarks' },
                    { text: 'Delivery Method', align: 'start', width: '7%', sortable: false, value: 'delivery_method' },
                    { text: 'Comments', align: 'start', sortable: false, value: 'delivery_comment' },
                    { text: 'Delivery Date', align: 'start', width: '12%', sortable: false, value: 'delivery_date' },
                    { text: 'Vehicle', align: 'start', width: '12%', sortable: false, value: 'vehicle_id' },
                    // { text: 'Vehicle Total CBM', align: 'end', width: '5%', sortable: false, value: 'vehicle_cbm' },
                    { text: 'Vehicle Remaining CBM', align: 'end', width: '5%', sortable: false, value: 'vehicle_remaining_cbm' },
                    // { text: 'Vehicle Weight', align: 'end', width: '5%', sortable: false, value: 'vehicle_weight' },
                    { text: 'Vehicle Remaining Weight', align: 'end', width: '5%', sortable: false, value: 'vehicle_remaining_weight' },
                    // { text: 'Total Item CBM', align: 'end', width: '5%', sortable: false, value: 'total_cbm' },
                    { text: 'Total Estimate CBM', align: 'end', width: '5%', sortable: false, value: 'total_estimate_cbm' },
                    { text: 'Item Weight', align: 'end', width: '5%', sortable: false, value: 'total_weight' },
                    // { text: 'SO Amount', align: 'end', width: '7%', sortable: false, value: 'so_total_amount' },
                    { text: 'No. of Boxes', align: 'start', width: '3%', sortable: false, value: 'no_of_boxes' },
                    { text: 'In Dispatch', align: 'start', width: '3%', sortable: false, value: 'in_dispatch' },
                    { text: 'Status', align: 'center', width: '6%', sortable: false, value: 'status' },
                    { text: 'Actions', align: 'center', width: '6%', sortable: false, value: 'actions' },
                ],
                list: [],
                options: {},
                loading: false,
                total_records: 0,
                items_per_page: 10,
                items_per_page_options: [10, 20, 50, 100],

                sales_order_id: '',

                updates: {
                    delivery_date: null,
                    vehicle_id: null,
                },
            },

            calendar: {
                events: [],
                colors: [],
                value: null,
                start_date: this.$dayjs().startOf('month').format('YYYY-MM-DD HH:mm'),
                end_date: this.$dayjs().endOf('month').format('YYYY-MM-DD HH:mm'),
                overlap_mode: 'stack',
                calendar_view: {
                    value: 'month',
                    list: ['month', 'week', 'day'],
                },
                week_view: {
                    value: [0, 1, 2, 3, 4, 5, 6],
                    list: [
                        { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
                        { text: 'Mon - Sat', value: [1, 2, 3, 4, 5, 6] },
                        { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
                    ],
                },
            },
            loading:{
                customer_selection : true,
                vehicle_list_selection: true,
                setLoading:false
            },
            update_selected: false,
            isDisabled: false,
            defaultWarehouse: 0,
        }
    },
    methods: {
        viewSalesOrder(id){
            this.view_orders.sales_order_id = id
        },
        closeView(){
            this.view_orders.sales_order_id = ''
        },
        async getOrderDeliverySchedule(){
            this.sales_orders.loading = true
            this.sales_orders.list = []

            let payload = {
                page: this.sales_orders.options.page,
                itemsPerPage: this.sales_orders.options.itemsPerPage,
                tab: this.tab,
                rush: this.rush,
                service: this.service,
                filters: this.filters,
                url: 'order-delivery-schedule/get'
            }
            await this.$store.dispatch('getDelivery',payload).then(response => {
                this.sales_orders.list = response.data.salesOrders.data
                this.sales_orders.total_records = response.data.salesOrders.total
                this.sales_orders.loading = false

            }).catch(error => {
                console.log(error)
                this.sales_orders.loading = false
            })
        },
        filter(event = null) {
            if (event !== null) {
                event.preventDefault()
            }

            if (this.tab === 'scheduling') {
                this.getOrderDeliverySchedule()
            } else if (this.tab === 'scheduled') {
                if (this.$refs.calendar) {
                    this.$refs.calendar.getEvents
                }
            }
        },
        async view(item){
            this.toggle(true)

            this.view_orders.parameters.customer_id = item.customer_id
            this.view_orders.parameters.customer_name = item.customer_name
            this.view_orders.parameters.date = item.sales_order_date
            this.view_orders.parameters.delivery_date = item.delivery_date

            await this.getOrders()
        },
        async showOrders(event){
            let item = event.event

            this.toggle(true)

            this.view_orders.parameters.customer_id = item.customer_id
            this.view_orders.parameters.customer_name = item.name
            this.view_orders.parameters.date = item.start
            this.view_orders.parameters.delivery_date = item.end
            this.view_orders.parameters.vehicle_id = item.vehicle_id

            await this.getOrders()

            this.view_orders.parameters.date = this.$dayjs(item.start).format('YYYY-MM-DD')
        },
        async getOrders(){
            this.view_orders.loading = true
            this.defaultWarehouse = 0
            let payload = {
                page: this.view_orders.options.page,
                itemsPerPage: this.view_orders.options.itemsPerPage,
                customer_id: this.view_orders.parameters.customer_id,
                dates: this.view_orders.parameters.date,
                delivery_date: this.view_orders.parameters.delivery_date,
                vehicle_id: this.view_orders.parameters.vehicle_id,
                tab: this.tab,
                service: this.service,
                url:'order-delivery-schedule/get-orders'
            }
            await this.$store.dispatch('getDelivery',payload).then(response => {
                this.view_orders.list = response.data.salesOrders.data
                this.view_orders.total_records = response.data.salesOrders.total
                this.view_orders.loading = false
                this.getCanServeAmount(this.view_orders.list)
            }).catch(error => {
                this.view_orders.loading = false
            })
        },

        async set(item, by_so = false){
            if (item.delivery_date == null || item.vehicle_id == null) {
                swal.fire('Must select Delivery Date and Vehicle!', '', 'error')
                return false
            }
            this.loading.setLoading = true
            let payload = {
                data:{
                    sales_order_date: item.sales_order_date,
                    delivery_date: this.$dayjs(item.delivery_date).format('YYYY-MM-DD HH:mm'),
                    delivery_comment: item.delivery_comment,
                    order_num: item.order_num,
                    sales_order_id: item.sales_order_id,
                    by_so: by_so,
                    vehicle_id: item.vehicle_id,
                    url:'order-delivery-schedule/set/'+item.customer_id
                },

            }
            await this.$store.dispatch('postDelivery',payload).then(response => {
                if (response.data.msg) {
                    swal.fire('Update Succcess!', '', 'success').then(confirm => {
                        this.view_orders.show = false
                        this.loading.setLoading = false
                        this.refreshCalendarData(this.calendar.start_date, this.calendar.end_date)
                        this.getOrderDeliverySchedule()
                    })
                }
                else{
                    this.loading.setLoading = false
                }
            })
            return;
        },
        massUpdate(){
            if (this.view_orders.updates.delivery_date == null || this.view_orders.updates.vehicle_id == null) {
                swal.fire('Must select Delivery Date and Vehicle!', '', 'error')
                return false
            }
            this.loading.setLoading = true
            let payload = {
                data:{
                    delivery_date: this.view_orders.updates.delivery_date,
                    so_ids: this.view_orders.list.map(m => { return m.sales_order_id }),
                    vehicle_id: this.view_orders.updates.vehicle_id,
                    url:'order-delivery-schedule/mass-update'
                }
            }
            this.$store.dispatch('postDelivery',payload).then(response => {
                if (response.data.msg) {
                    swal.fire('Update Succcess!', '', 'success').then(confirm => {
                        this.view_orders.show = false
                        this.loading.setLoading = false
                        this.getOrderDeliverySchedule()
                        this.refreshCalendarData(this.calendar.start_date, this.calendar.end_date)
                    })
                }
                else{
                    this.loading.setLoading = false
                }
            })
        },
        updateSelected(){
            if (this.view_orders.updates.delivery_date == null || this.view_orders.updates.vehicle_id == null) {
                swal.fire('Must select Delivery Date and Vehicle!', '', 'error')
                return false
            }
            this.loading.setLoading = true
            let payload = {
                data:{
                    delivery_date: this.view_orders.updates.delivery_date,
                    so_ids: this.view_orders.parameters.selectedSo.map(m => { return m.sales_order_id }),
                    vehicle_id: this.view_orders.updates.vehicle_id,
                    url:'order-delivery-schedule/mass-update'
                }
            }
            this.$store.dispatch('postDelivery',payload).then(response => {
                if (response.data.msg) {
                    swal.fire('Update Succcess!', '', 'success').then(confirm => {
                        this.view_orders.show = false
                        this.loading.setLoading = false
                        this.getOrderDeliverySchedule()
                        this.refreshCalendarData(this.calendar.start_date, this.calendar.end_date)
                    })
                }
                else{
                    this.loading.setLoading = false
                }
            })
            this.view_orders.parameters.selectedSo = []
            this.view_orders.updates.delivery_date = ''
            this.view_orders.updates.vehicle_id = ''

        },
        viewDetails(module, ids){

        },
        updateVehicle(item){
            let payload = {
                data:{
                    sales_order_id: item.sales_order_id,
                    vehicle_id: item.vehicle_id,
                    url:'order-delivery-schedule/update-vehicle'
                }
            }
            this.$store.dispatch('postDelivery',payload).then(response => {
                if (response.data.msg) {
                    swal.fire('Update Succcess!', '', 'success').then(confirm => {
                        this.view_orders.show = false
                        this.refreshCalendarData(this.calendar.start_date, this.calendar.end_date)
                    })
                }
            })
        },
        updateVehicleDetails(item, index, source = 'view_orders'){
            if (source == 'view_orders') {
                this.view_orders.list[index].vehicle_cbm = this.list.vehicle_list.filter(f => f.value === item.vehicle_id)[0].cbm
                this.view_orders.list[index].vehicle_weight = this.list.vehicle_list.filter(f => f.value === item.vehicle_id)[0].weight
                this.view_orders.list[index].vehicle_remaining_cbm = this.list.vehicle_list.filter(f => f.value === item.vehicle_id)[0].cbm - this.view_orders.list[index].total_estimate_cbm
                this.view_orders.list[index].vehicle_remaining_weight = this.list.vehicle_list.filter(f => f.value === item.vehicle_id)[0].weight - this.view_orders.list[index].total_weight
            } else if (source == 'sales_orders') {
                this.sales_orders.list[index].vehicle_cbm = this.list.vehicle_list.filter(f => f.value === item.vehicle_id)[0].cbm
                this.sales_orders.list[index].vehicle_weight = this.list.vehicle_list.filter(f => f.value === item.vehicle_id)[0].weight
            }

            if (item.vehicle_id != null && item.delivery_date != null) {
                this.checkVehicleNumberCoding(item.vehicle_id, item.delivery_date)
            }
        },
        updateDeliveryDate(item){
            if (item.vehicle_id != null && item.delivery_date != null) {
                this.checkVehicleNumberCoding(item.vehicle_id, item.delivery_date)
            }
        },
        checkVehicleNumberCoding(vehicle_id, delivery_date){
            const vehicle_info = this.list.vehicle_list.filter(f => {
                return f.value == vehicle_id
            })

            if (this.$dayjs(delivery_date).day() === vehicle_info[0].number_coding) {
                swal.fire('Vehicle selected is coding on the selected date.', 'Please be sure about this.', 'warning')
            }
        },
        getEventColor(event){
            return event.color
        },
        getEvents({ start, end }){
            this.calendar.start_date = new Date(Date.parse(`${start.date}T00:00:00.00+08:00`.toLocaleString('en-US', 'Asia/Manila')))
            this.calendar.end_date = new Date(Date.parse(`${end.date}T23:59:59.00+08:00`.toLocaleString('en-US', 'Asia/Manila')))

            this.refreshCalendarData(this.calendar.start_date, this.calendar.end_date)
        },
        refreshCalendarData(start, end){
            let payload = {
                min: start,
                max: end,
                customer_id: this.filters.customer_id,
                order_num: this.filters.order_num,
                service: this.service,
                url: 'order-delivery-schedule/scheduled/all'
            }
            this.$store.dispatch('getDelivery',payload).then(response => {
                this.calendar.events = response.data.scheduled_deliveries
                this.calendar.colors = response.data.event_colors
            })
        },
        viewDay({ date }){
            this.calendar.value = date
            this.calendar.calendar_view.value = 'day'
        },
        setToday(){
            this.calendar.value = ''
        },
        toggle(show = false){
            this.view_orders.list = []
            this.view_orders.updates.delivery_date = null
            this.view_orders.updates.vehicle_id = null
            this.view_orders.show = show
        },
        autoRefresh(){
            setInterval(() => {
                if (this.$refs.calendar) {
                    this.refreshCalendarData(this.calendar.start_date, this.calendar.end_date)
                }
            }, 5000)
        },
        getCustomers(){
            this.$store.dispatch('getAllCustomers').then(response => {
                this.list.customer_list = response.data
            })
        },
        getVehicles(){
            let payload = {
                delivery: 1,
                url: 'getVehicles',
            }
            this.$store.dispatch('getDelivery',payload).then(response => {
                this.list.vehicle_list = response.data
            })
            this.loading.vehicle_list_selection = false
        },

        getCanServeAmount(data) {
            let so_ids = data.map(e => e.sales_order_id)
            let payload = {
                so_ids: so_ids,
                url: 'order-delivery-schedule/get-logistic-warehouse'
            }
            this.$store.dispatch('getDelivery', payload).then(response => {
                let all_warehouse = response.data.data
                if(all_warehouse) {
                    let totalQuantity = 0;
                    response.data.items.forEach((x) => {
                        let e = all_warehouse.find(el => el.item_id == x.item_id);
                        if(e) {
                            if (e.item_id == x.item_id) {
                                totalQuantity += e.quantity;
                                if (totalQuantity < x.pending_qty) {
                                    this.defaultWarehouse += x.amount * totalQuantity;
                                } else {
                                    this.defaultWarehouse += x.amount * x.pending_qty;
                                }

                                this.defaultWarehouse = parseFloat(this.defaultWarehouse);
                            }
                        }
                    });
                }
            })
        }
    },
    mounted(){
        // this.getCustomers()
        this.getVehicles()
        this.setToday()
        this.autoRefresh()
        this.getOrderDeliverySchedule()
    },
    watch:{
        'sales_orders.options': {
            handler () {
                this.getOrderDeliverySchedule()
            },
            deep: true,
        },
        'GET_CUSTOMERS_LIST_SELECTION':{
            handler(val){
                this.loading.customer_selection = false
            }
        },
        'view_orders.parameters.selectedSo':{
            handler(val){
                if(val.length > 0){
                    this.update_selected = true
                    this.isDisabled = true
                }else{
                    this.update_selected = false
                    this.isDisabled = false
                }
            }
        }
    },
    computed: {
        currentDay(){
            return this.calendar.value === undefined || this.calendar.value === null || this.calendar.value === ''
                    ? this.$dayjs().format('MMMM D, YYYY')
                    : this.$dayjs(this.calendar.value).format('MMMM D, YYYY')
        },
        // soTotalAmount(){
        //     return this.view_orders.list.reduce((subtotal, current) => parseFloat(subtotal) + parseFloat(current.so_total_amount), 0)
        // },
        totalItemCbm(){
            return this.view_orders.list.reduce((subtotal, current) => parseFloat(subtotal) + parseFloat(current.total_cbm), 0)
        },
        totalEstimateCbm(){
            return this.view_orders.list.reduce((subtotal, current) => parseFloat(subtotal) + parseFloat(current.total_estimate_cbm), 0)
        },
        totalWeight(){
            return this.view_orders.list.reduce((subtotal, current) => parseFloat(subtotal) + parseFloat(current.total_weight), 0)
        },
        totalAmount(){
            return this.view_orders.list.reduce((subtotal, current) => parseFloat(subtotal) + parseFloat(current.total_amount), 0)
        },
        ...mapGetters([
            'GET_CUSTOMERS_LIST_SELECTION'
        ])
    },
}
</script>
<style scoped>
    .dropdown-items, .dropdown-selection {
        font-size: 13px;
    }
</style>
