<script>
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";

export default {
    mixins: [SharedFunctionsComponentVue],
    data() {
        return {
            // test: Math.random(),

            paperWidth: 215.9,
            paperHeight: 279.4,
            margin: 10,

            headerStartY: undefined,
            headerEndY: undefined,
            headerWidth: undefined,
            headerHeight: undefined,

            footerStartY: undefined,
            footerEndY: undefined,
            footerWidth: undefined,
            footerHeight: 0,
        };
    },
    methods: {
        drawContractorsEndorsementFormPrintHeader(doc, headerData) {
            const host = window.location.origin;

            const imageWidth = 36;
            const imageHeight = 10;
            doc.addImage(
                `${host}/images/ingcoph-logo.png`,
                'PNG',
                (this.margin + (this.paperWidth - (this.margin * 2)) / 4) - (imageWidth / 2),
                this.margin,
                imageWidth,
                imageHeight,
            );

            const titleContainerWidth = 90;
            const titleContainerHeight = 8;
            const titleContainerX = (this.paperWidth / 2) + (((this.paperWidth / 2 - this.margin) - titleContainerWidth) / 2);
            const titleContainerY = this.margin;
            doc.setDrawColor('#000000');
            doc.setFillColor('#fde047');
            doc.roundedRect(
                titleContainerX,
                titleContainerY,
                titleContainerWidth,
                titleContainerHeight,
                2,
                2,
                'FD',
            );

            const title = 'Contractors Endorsement Form';
            const titleFontSize = 15;
            const titleX = titleContainerX;
            const titleY = titleContainerY;
            doc.setFont(
                'Helvetica',
                'bold',
            );
            doc.setFontSize(titleFontSize);
            doc.text(
                title,
                titleX + 5.5,
                titleY + 6,
            );

            doc.autoTable({
                startY: this.margin + titleContainerHeight + 5,
                margin: {
                    bottom: this.margin,
                    top: this.margin,
                    left: this.margin,
                    right: this.margin,
                },
                theme: 'plain',
                columnStyles: {
                    0 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 2},
                    1 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 2},
                },
                bodyStyles: {
                    lineColor: [0, 0, 0],
                    textColor: [0, 0, 0],
                    fontSize: 8,
                },
                body: [
                    ['', `Date: ${headerData.created_at}`],
                    [
                        {content: 'CLIENT DETAILS', styles: {halign: 'center', fillColor: [254, 249, 195], fontStyle: 'bold'}},
                        {content: 'CONTRACTOR DETAILS', styles: {halign: 'center', fillColor: [254, 249, 195], fontStyle: 'bold'}},
                    ],
                    [
                        `Owner: ${headerData.owner}`,
                        `Name: ${headerData.contractor_name}`,
                    ],
                    [
                        `Project Name: ${headerData.project_name}`,
                        `Contact Person: ${headerData.contact_person}`,
                    ],
                    [
                        `Location: ${headerData.location}`,
                        `Contact No.: ${headerData.contact_number}`,
                    ],
                    [
                        `Contact Person: ${headerData.contact_person}`,
                        `Address: ${headerData.address}`,
                    ],
                    [
                        `Contact Number: ${headerData.contact_number}`,
                        `Payment Terms: ${headerData.terms}`,
                    ],
                    [
                        `Minimum Order of Client: ${headerData.minimum_order}`,
                        `Payable to: ${headerData.checks_payable_to}`,
                    ],
                ],
                didDrawCell: (data) => {
                    if (data.row.index === 0 && data.column.index === 0) return;
                    doc.rect(
                        data.cell.x,
                        data.cell.y,
                        data.cell.width,
                        data.cell.height,
                    );
                },
            });

            this.headerStartY = this.margin;
            this.headerEndY = doc.lastAutoTable.finalY;
            this.headerWidth = this.paperWidth - (this.margin * 2);
            this.headerHeight = this.headerEndY - this.headerStartY;

            return this.headerEndY;
        },

        drawContractorsEndorsementFormPrintFooter(doc, footerData) {
            const footerHeight = 62.773;

            doc.autoTable({
                startY: this.paperHeight - (this.margin + footerHeight),
                margin: {
                    bottom: this.margin,
                    top: this.margin,
                    left: this.margin,
                    right: this.margin,
                },
                theme: 'grid',
                columnStyles: {
                    0: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    1: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    2: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    3: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    4: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    5: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    6: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    7: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    8: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    9: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    10: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    11: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                },
                headStyles: {
                    valign: 'middle',
                    halign: 'center',
                    lineColor: [0, 0, 0],
                    fillColor: [254, 249, 195],
                    textColor: [0, 0, 0],
                    fontStyle: 'italic',
                    fontSize: 8,
                },
                head: [
                    [
                        {content: 'CONCEPT STORE AND INITIAL PAYMENT DETAILS', colSpan: 12},
                    ],
                ],
                bodyStyles: {
                    minCellHeight: 13,
                    lineColor: [0, 0, 0],
                    textColor: [0, 0, 0],
                    fontSize: 8,
                },
                body: [
                    [
                        {content: `Turn Over Date`, colSpan: 6},
                        {content: `Total Contracted Price`, colSpan: 6},
                    ],
                    [
                        {content: `Grand Opening Date`, colSpan: 6},
                        {content: 'DP Amount & Date Released', colSpan: 3},
                        {content: 'DP Reference Document', colSpan: 3},
                    ],
                    [
                        {content: 'Remarks', colSpan: 6},
                        {content: 'Prepared By', colSpan: 3},
                        {content: 'Endorsed By', colSpan: 3},
                    ],
                    [
                        {content: 'Noted By', colSpan: 3},
                        {content: 'Noted By', colSpan: 3},
                        {content: 'Approved By', colSpan: 3},
                        {content: 'Processed By', colSpan: 3},
                    ],
                ],
                didDrawCell: (data) => {
                    if (data.section === 'head') {
                        doc.rect(
                            data.cell.x,
                            data.cell.y,
                            data.cell.width,
                            data.cell.height,
                        );
                    }

                    if (data.section === 'body') {
                        if (data.row.index === 0) {
                            doc.autoTable({
                                startY: data.cell.y,
                                margin: {
                                    top: data.cell.y,
                                    bottom: this.paperHeight - (data.cell.y + data.cell.height),
                                    left: data.cell.x,
                                    right: this.paperWidth - (data.cell.x + data.cell.width),
                                },
                                theme: 'plain',
                                bodyStyles: {
                                    minCellHeight: 12,
                                    valign: 'end',
                                    halign: 'center',
                                    lineColor: [0, 0, 0],
                                    textColor: [0, 0, 0],
                                    fontSize: 8,
                                },
                                body: [
                                    [{content: `\n${data.column.index === 0
                                        ? footerData.turn_over_date
                                        : this.toCurrency(null, footerData.turn_over_date, 2)
                                    }`}],
                                ],
                            });
                        } else if (data.row.index === 1) {
                            doc.autoTable({
                                startY: data.cell.y,
                                margin: {
                                    top: data.cell.y,
                                    bottom: this.paperHeight - (data.cell.y + data.cell.height),
                                    left: data.cell.x,
                                    right: this.paperWidth - (data.cell.x + data.cell.width),
                                },
                                theme: 'plain',
                                bodyStyles: {
                                    minCellHeight: 12,
                                    valign: 'end',
                                    halign: 'center',
                                    lineColor: [0, 0, 0],
                                    textColor: [0, 0, 0],
                                    fontSize: 8,
                                },
                                body: [
                                    data.column.index === 6
                                    ? [{content: `${this.toCurrency(null, footerData.dp_amount, 2)}\n${footerData.dp_date}`}]
                                    : [{content: `\n${data.column.index === 0
                                        ? footerData.opening_date
                                        : footerData.reference
                                    }`}],
                                ],
                            });
                        } else if (data.row.index === 2) {
                            doc.autoTable({
                                startY: data.cell.y,
                                margin: {
                                    top: data.cell.y,
                                    bottom: this.paperHeight - (data.cell.y + data.cell.height),
                                    left: data.cell.x,
                                    right: this.paperWidth - (data.cell.x + data.cell.width),
                                },
                                theme: 'plain',
                                bodyStyles: {
                                    minCellHeight: 12,
                                    valign: 'end',
                                    halign: 'center',
                                    lineColor: [0, 0, 0],
                                    textColor: [0, 0, 0],
                                    fontSize: 8,
                                },
                                body: [
                                    data.column.index === 0
                                    ? [{content: `\n${footerData.remarks}`}]
                                    : [{content: `${data.column.index === 6
                                        ? footerData.prepared_by + '\nRequestor'
                                        : footerData.endorsed_by + '\nImmediate Supervisor'
                                    }`}],
                                ],
                            });
                        } else if (data.row.index === 3) {
                            doc.autoTable({
                                startY: data.cell.y,
                                margin: {
                                    top: data.cell.y,
                                    bottom: this.paperHeight - (data.cell.y + data.cell.height),
                                    left: data.cell.x,
                                    right: this.paperWidth - (data.cell.x + data.cell.width),
                                },
                                theme: 'plain',
                                bodyStyles: {
                                    minCellHeight: 12,
                                    valign: 'end',
                                    halign: 'center',
                                    lineColor: [0, 0, 0],
                                    textColor: [0, 0, 0],
                                    fontSize: 8,
                                },
                                body: [
                                    [{content: `${data.column.index === 0
                                        ? footerData.noted_by + '\nDepartment Head'
                                        : data.column.index === 3
                                            ? 'Steven Andrew Yang\nPresident'
                                            : data.column.index === 6
                                                ? footerData.approved_by + '\nFinance Manager'
                                                : footerData.processed_by + '\nAP Officer'
                                    }`}],
                                ],
                            });
                        }
                    }
                },
            });

            this.footerStartY = doc.previousAutoTable.settings.startY;
            this.footerEndY = doc.previousAutoTable.finalY;
            this.footerWidth = this.paperWidth - (this.margin * 2);
            this.footerHeight = footerHeight;

            doc.setFont("Gill", "italic");
            doc.setFontSize(7);
            doc.text(
                'ADM-06-v03-2021 Copy 1 - Requestor Copy 2 - Oper, Copy 3 - Mktg, Copy 4 - Admin, Copy 5 - Acctg',
                this.margin,
                doc.previousAutoTable.finalY + 3,
            );

            doc.setFont("Helvetica", "normal");
            doc.text(
                `Page ${doc.internal.getNumberOfPages()}`,
                this.margin,
                272,
            );

            doc.setFont("Helvetica", "bold");
            doc.setFontSize(14);
            doc.setTextColor('#ef4444');
            doc.text(
                'CEF NO.',
                135,
                doc.previousAutoTable.finalY + 6,
            );

            doc.setTextColor('#000000');
            doc.text(
                footerData.cef_number,
                158,
                doc.previousAutoTable.finalY + 6,
            );

            return this.footerEndY;
        },

        drawContractorsEndorsementFormPrintContent(doc, cef, contentData) {
            doc.autoTable({
                startY: this.headerEndY,
                margin: {
                    top: this.headerEndY,
                    bottom: this.footerHeight + this.margin,
                    left: this.margin,
                    right: this.margin,
                },
                theme: 'grid',
                columnStyles: {
                    0: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    1: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    2: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    3: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    4: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    5: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    6: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    7: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    8: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    9: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    10: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    11: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                },
                headStyles: {
                    valign: 'middle',
                    halign: 'center',
                    lineColor: [0, 0, 0],
                    fillColor: [254, 249, 195],
                    textColor: [0, 0, 0],
                    fontStyle: 'italic',
                    fontSize: 8,
                },
                head: [
                    [
                        {content: 'DESCRIPTION OF WORK', colSpan: 10},
                        {content: 'LABOR & MATERIALS COST', colSpan: 2},
                    ],
                ],
                bodyStyles: {
                    lineColor: [0, 0, 0],
                    textColor: [0, 0, 0],
                    fontSize: 8,
                },
                body: [
                    ...contentData.map(content => [
                        {
                            content: content.description + (content.unit_price ? ` (${this.toCurrency(null, content.unit_price, 2)} * ${this.toCurrency(null, content.qty, 0)})` : ''),
                            colSpan: 10,
                            styles: {halign: 'center'},
                        },
                        {content: this.toCurrency(null, content.material_cost ? content.material_cost : content.labor_cost, 2) + '  -', colSpan: 2, styles: {halign: 'right'}},
                    ]),

                    // content footer
                    [
                        {content: 'Total Labor And Material Cost >>>', colSpan: 10, styles: { valign: 'middle', halign: 'right', fillColor: [209, 213, 219], fontSize: 9, fontStyle: 'bold' }},
                        {
                            content: this.toCurrency(
                                null,
                                contentData.reduce((acc, item) => {
                                    if (item.labor_cost) {
                                        acc.total_cost = (acc.total_cost || 0) + parseFloat(item.labor_cost);
                                    }
                                    if (item.material_cost) {
                                        acc.total_cost = (acc.total_cost || 0) + parseFloat(item.material_cost);
                                    }
                                    return acc;
                                }, { total_cost: 0 }).total_cost,
                                2,
                            ) + '  -',
                            colSpan: 2,
                            styles: { valign: 'middle', halign: 'right', fontStyle: 'bold' },
                        },
                    ],
                ],
                didDrawCell: (data) => {
                    if (data.section === 'head') {
                        doc.rect(
                            data.cell.x,
                            data.cell.y,
                            data.cell.width,
                            data.cell.height,
                        );
                    }
                },
                didDrawPage: (data) => {
                    if (doc.getNumberOfPages() > 1) {
                        this.drawContractorsEndorsementFormPrintHeader(doc, cef.header);
                        this.drawContractorsEndorsementFormPrintFooter(doc, cef.footer);
                    }
                },
            });
        },

        printContractorsEndorsementForm(cef) {
            const doc = new this.$jspdf({
                orientation: 'portrait',
                unit: 'mm',
                format: 'letter',
            });

            this.drawContractorsEndorsementFormPrintHeader(doc, cef.header);
            this.drawContractorsEndorsementFormPrintFooter(doc, cef.footer);
            this.drawContractorsEndorsementFormPrintContent(doc, cef, cef.content);

            doc.autoPrint();
            window.open(doc.output("bloburl"), "_blank");
        },
    },
    watch: {
        // 'test': {
        //     handler(val) {
        //         this.printContractorsEndorsementForm();
        //     },
        //     immediate: true,
        // },
    },
}
</script>