<template >
  <div style="display:none;"></div>
</template>
<script charset="utf-8">
    import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
    import { mapGetters } from 'vuex';
    import GILL from '@/assets/fonts/Gil_normal';
    export default {
        mixins: [
            SharedFunctionsComponentVue,
            GILL
        ],
        data() {
            return {
                printData: [],
                chunk_data: [],
                tempItems: [],
                temp_index: 0,
                base64Image: null,
                system: [],
                ItemsLastY: 0,
            }
        },
        computed:{
            ...mapGetters([
                "GET_SYSTEM",
                'GET_ADMIN_DELIVERY_RECEIPT',
                'USERACCOUNT_LOAD',
                'PURCHASE_ORDER',
            ]),
        },
        mounted(){
        },
        methods: {
            formatDate(date) {
            date = new Date(date);
            const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
            const day = String(date.getDate()).padStart(2, '0');
            const year = date.getFullYear();
            const formattedDate = `${day}-${month}-${year}`;

            return formattedDate;
            },
            async renderPDF(headerAndFooter){
                this.total_sales = 0
                this.temp_index = 0
                let paperWidth = 612;
                let paperHeight = 792;
                let margin = 36;
                let host = window.location.origin;

                let doc = new this.$jspdf({
                    orientation: 'portrait',
                    unit: 'pt',
                    format: 'letter'
                });

                let printDetails = this.printData;

                // this.chunk_data.forEach((array)=> {
                    this.tempItems = this.chunk_data
                    let current_page = doc.getCurrentPageInfo().pageNumber;
                    doc.autoTable({
                        theme: 'grid',
                        tableWidth: paperWidth - (margin * 2),
                        margin: {top: 180, bottom: (this.tempItems.length == 25 && current_page == this.chunk_data.length) ? 80 : 145, left:margin, right: margin},
                        styles: {
                            textColor: '#000',
                            lineWidth: 0.1,
                            lineColor: '#000',
                            fontSize: 10,
                            font: 'GILL'
                        },
                        columnStyles: {
                        0: {cellWidth: (paperWidth - (margin * 2)) * .1},
                        1: {cellWidth: (paperWidth - (margin * 2)) * .4},
                        },
                        head: [[
                        {content: 'Line No.', styles: {
                            valign: 'middle',
                            halign: 'center',
                            cellPadding: 3.7,
                            fillColor: '#ffffff',
                            fontStyle: 'bold',
                            lineColor: [0, 0, 0],
                        }},
                        {content: 'DESCRIPTION', styles: {
                            valign: 'middle',
                            halign: 'center',
                            cellPadding: 3.7,
                            fillColor: '#ffffff',
                            fontStyle: 'bold',
                            lineColor: [0, 0, 0],
                        }},
                        {content: 'UNITS', styles: {
                            valign: 'middle',
                            halign: 'center',
                            cellPadding: 3.7,
                            fillColor: '#ffffff',
                            fontStyle: 'bold',
                            lineColor: [0, 0, 0],
                            cellWidth: 51.8
                        }},
                        {content: 'QTY', styles: {
                            valign: 'middle',
                            halign: 'center',
                            cellPadding: 3.7,
                            fillColor: '#ffffff',
                            fontStyle: 'bold',
                            lineColor: [0, 0, 0],
                            cellWidth: 51.8
                        }},
                        {content: 'UNIT PRICE', styles: {
                            valign: 'middle',
                            halign: 'center',
                            cellPadding: 3.7,
                            fillColor: '#ffffff',
                            fontStyle: 'bold',
                            lineColor: [0, 0, 0],
                            cellWidth: 90

                        }},
                        {content: 'TOTAL', styles: {
                            valign: 'middle',
                            halign: 'center',
                            cellPadding: 3.7,
                            fillColor: '#ffffff',
                            fontStyle: 'bold',
                            lineColor: [0, 0, 0],
                        }},
                        ]],
                        body: [
                            ...this.generateItemsList(this.tempItems, doc),
                        ],

                        didDrawPage: function(data) {
                        //HEADER AND FOOTER
                        headerAndFooter(doc, printDetails)
                        }
                    });
                // })

                this.ItemsLastY = doc.lastAutoTable.finalY;
                // Page Number
                doc.setFont('GILL', 'normal');
                doc.setFontSize(7);
                doc.setTextColor('#000');
                let pageInfo = doc.internal.getCurrentPageInfo();
                let index = 0
                for (let i = 0; i < pageInfo.pageNumber; i++) {
                    doc.setPage(i + 1);
                    doc.text(`Page ${i + 1} of ${pageInfo.pageNumber}`, margin + 220, paperHeight - 18);
                    index = i + 1
                }

                // For printing.
                // doc.autoPrint();
                window.open(doc.output('bloburl'), '_blank');
            },
            generateItemsList(tempItems, doc) {

                let items = tempItems.map((item, index) => {
                this.temp_index++
                return [
                    { content: this.temp_index, styles: { valign: 'middle', halign: 'center', cellPadding: 3.7 } },
                    { content: item.item_name, styles: { valign: 'middle', halign: 'center', cellPadding: 3.7 } },
                    { content: item.uom, styles: { valign: 'middle', halign: 'center', cellPadding: 3.7 } },
                    { content: item.quantity, styles: { valign: 'middle', halign: 'center', cellPadding: 3.7 } },
                    { content: this.currencyWithoutSign(item.price), styles: { valign: 'middle', halign: 'right', cellPadding: 3.7 } },
                    { content: this.currencyWithoutSign(item.total_price), styles: { valign: 'middle', halign: 'right', cellPadding: 3.7} },
                ];
                });
                // let whatpage = doc.internal.getCurrentPageInfo().pageNumber;
                // if(items.length < 25 || (this.temp_index == items.length && whatpage == this.chunk_data.length)){
                //     items.push(
                //         [
                //             {content: `${this.printData.remarks ? this.printData.remarks : ''}`,colSpan: 4,  styles: { valign: 'middle', halign: 'left', fillColor: '#eceff1', minCellHeight: 50}},
                //             {content: 'TOTAL', rowSpan:2,  styles: {valign: 'middle', halign: 'left', fontStyle: 'bold', fillColor: '#eceff1', minCellHeight: 50}},
                //             {content: `${ this.thousandSeprator(this.formatCurrencyPHPwithoutRoundingUp(this.printData.total_amount))}`, rowSpan:2, styles: { valign: 'middle', halign: 'right', fillColor: this.system.theme_color}},
                //         ],
                //     );
                // }
                return items;
            },
            // paymentMethod(payment_method){
            //     switch (payment_method) {
            //         case 1:
            //             return 'PAYMENT FIRST';
            //         case 2:
            //             return 'RECEIVE FIRST';
            //         case 3:
            //             return 'PARTIAL PAYMENT';
            //         default:
            //             return '';
            //     }
            // },
            trim(location){
                let trim = location.split('-')
                let result = trim[1].trim()
                return result;
            },
            formatDate(raw_date) {
                const date = new Date(raw_date);
                const month = ('0' + (date.getMonth() + 1)).slice(-2);
                const day = ('0' + date.getDate()).slice(-2);
                const year = date.getFullYear();
                return `${month}/${day}/${year}`;
            },
            getHeaderAndFooterPdf(doc, printData){
                let paperWidth = 612;
                let paperHeight = 792;
                let margin = 36;
                let margin_top = 15;
                let host = window.location.origin;

                // PURCHASE ORDER LABEL
                // let rightLabelFontSize = 19;

                // doc.setFontSize(rightLabelFontSize)
                // .setTextColor(this.system.title_color)
                // .setFont('GILL','bold')
                // .text('PURCHASE ORDER', (paperWidth - margin) - 180, margin_top + 15);
                try {
                    let aspectRatio = this.getAspectRatio(this.base64Image.naturalWidth, this.base64Image.naturalHeight);
                    let image_width = 0;
                    let image_height = 0;

                    switch(aspectRatio){
                        case '1:1':
                            image_width = 40;
                            image_height = 40;
                            break;
                        case '4:3':
                            image_width = 60;
                            image_height = 40;
                            break;
                        case '3:2':
                            image_width = 50;
                            image_height = 40;
                            break;
                        case '16:9':
                            image_width = 80;
                            image_height = 40;
                            break;
                        default:
                            image_width = 120;
                            image_height = 30;
                    }

                    doc.addImage(printData.image_path, 'PNG', margin, margin_top, image_width, image_height);
                   
                } catch {
                    console.error('image is not found for print.');
                }

                //Set the font format
                doc.setFont('GILL', 'bold');
                doc.setFontSize(12);
                doc.setTextColor('#000');
                //PO# and DATE
                doc.text(483, 50.7, "ADR#")
                // doc.text(476, 73.7, "PO #")
                doc.setFont('GILL', 'normal');
                doc.text(522, 50, `${printData.transaction_number ? printData.transaction_number : ''}`)
                // doc.text(522, 71.7, `${printData.po_num}`)
                //Box for DATE and PO#
                // doc.setDrawColor(0, 0, 0);
                // doc.rect(500, margin + 15.4, 75, 12);
                // doc.rect(500, margin + 27.5, 75, 12);

                //Company Details
                doc.autoTable({
                    theme: 'plain',
                    startY: margin_top + 35,
                    tableWidth: paperWidth - (margin * 2),
                    margin: margin,
                    styles: {
                    lineColor: [0, 0, 0],
                    textColor: [0, 0, 0],
                    fontSize: 10,
                    font: 'GILL'
                    },
                    columnStyles: {
                    0: {cellWidth: (paperWidth - (margin * 2)) * .2},
                    1: {cellWidth: (paperWidth - (margin * 2)) * .3},
                    2: {cellWidth: (paperWidth - (margin * 2)) * .2},
                    3: {cellWidth: (paperWidth - (margin * 2)) * .3},
                    },
                    body: [
                    [
                        {content: `${printData.company_name ? printData.company_name : ''}`, colSpan: 2,  styles: {valign: 'middle', halign: 'left', cellPadding: 1}},
                        {content: ``, colSpan: 2,  styles: {valign: 'middle', halign: 'left', cellPadding: 1}},
                    ],
                    [
                        {content: `${printData.company_address ? printData.company_address : ''}`, colSpan: 2,  styles: {valign: 'middle', halign: 'left', cellPadding: 1}},
                        {content: ``, colSpan: 2,  styles: {valign: 'middle', halign: 'left', cellPadding: 1}},
                    ],
                    [
                        {content: `Cell No.:    ${printData.mobile ? printData.mobile : ''}`, colSpan: 2, styles: {valign: 'middle', cellPadding: 1}},
                        {content: ``, colSpan: 2, styles: {valign: 'middle', cellPadding: 1}},
                    ],
                    [
                        {content: `Tel. No.:   ${printData.landline ? printData.landline : ''}`, colSpan: 2, styles: {valign: 'middle', cellPadding: 1}},
                        {content: ``, colSpan: 2, styles: {valign: 'middle', cellPadding: 1}},
                    ],
                    ],
                });

                // Customer Details
                doc.autoTable({
                    theme: 'plain',
                    startY: margin_top + 115,
                    tableWidth: paperWidth - (margin * 2),
                    margin: margin,
                    styles: {
                    lineColor: [0, 0, 0],
                    textColor: [0, 0, 0],
                    fontSize: 10,
                    font: 'GILL'
                    },
                    columnStyles: {
                    0: {cellWidth: (paperWidth - (margin * 2)) * .2},
                    1: {cellWidth: (paperWidth - (margin * 2)) * .3},
                    2: {cellWidth: (paperWidth - (margin * 2)) * .2},
                    3: {cellWidth: (paperWidth - (margin * 2)) * .3},
                    },
                    body: [
                    [
                        {content: `Customer:  ${printData.customer_name ? printData.customer_name : ''}`, colSpan: 3,  styles: {valign: 'middle', halign: 'left', cellPadding: 1}},
                        {content: ``, colSpan: 1,  styles: {valign: 'middle', halign: 'left', cellPadding: 1}},
                    ],
                    [
                        {content: `Address:    ${printData.delivery_address ? printData.delivery_address : ''}`, colSpan: 3, styles: {valign: 'middle', cellPadding: 1}},
                        {content: ``, colSpan: 1, styles: {valign: 'middle', cellPadding: 1}},
                    ],
                    [
                        {content: `Remarks:   ${printData.remarks ? printData.remarks : ''}`, colSpan: 3, styles: {valign: 'middle', cellPadding: 1}},
                        {content: ``, colSpan: 1, styles: {valign: 'middle', cellPadding: 1}},
                    ],
                    ],
                });

                //FOOTER
                doc.setFont('GILL', 'normal');
                doc.setFontSize(10);
                doc.setTextColor('#000');
                let signatories_upper_y_margin = 715
                let signatories_lower_y_margin = 742
                // doc.text(margin,  signatories_upper_y_margin, "Prepred By")
                // doc.text(margin,  signatories_lower_y_margin, "Admin In-Charge")
                // doc.text(margin + 110,  signatories_upper_y_margin, "Checked By")
                // doc.text(margin + 110,  signatories_lower_y_margin, "Admin Officer")
                // doc.text(margin + 250,  signatories_upper_y_margin, "Noted By")
                // doc.text(margin + 250,  signatories_lower_y_margin, "Admin Manager")
                // doc.text(margin + 376,  signatories_upper_y_margin, "Approved By")
                // doc.text(margin + 376,  signatories_lower_y_margin, "Admin Manager / President")

                // TOTAL SALES
                doc.autoTable({
                    theme: 'grid',
                    startY: signatories_upper_y_margin - 135,
                    tableWidth: 120,
                    styles:{
                        lineWidth: .2,
                        lineColor: '#000000',
                        font: 'GILL'
                    },
                    margin: {top: margin, left: 440, right: 40, bottom: margin },
                    columnStyles: {
                        0: { cellWidth: 48, halign: "left", textColor: "#000000" },
                        1: { cellWidth: 48, halign: "right", textColor: "#000000" },
                    },
                    body: [
                    [
                        {content: `TOTAL SALES`, colSpan: 3,  styles: {valign: 'middle', halign: 'center', cellPadding: 1}},
                    ],
                    [
                        {content: `${this.currencyWithoutSign(this.chunk_data.reduce((total, item) => parseFloat(total) + parseFloat(item.price), 0))}`, colSpan: 3, styles: {valign: 'middle', halign: 'right', cellPadding: 1}},
                    ],

                    ],
                });

                doc.autoTable({
                    theme: 'grid',
                    tableWidth: 100,
                    startY: signatories_upper_y_margin - 19,
                    styles: {
                        font: 'GILL',
                        fontSize: printData.created_by == null ? Math.ceil(this.USERACCOUNT_LOAD.name / 20) > 1 ? 6 : 8 : Math.ceil(printData.created_by.length / 20) > 1 ? 6 : 8
                    },
                    margin: margin,
                    columnStyles: {
                        0: {cellWidth: 38, halign:'center', textColor: '#000000'},
                        1: {cellWidth: 38, halign:'center', textColor: '#000000'},
                        2: {cellWidth: 38, halign:'center', textColor: '#000000'},
                    },
                    body: [
                    [
                        {content: `Issued By`, colSpan: 4,  styles: {valign: 'middle', halign: 'center', cellPadding: 1}},
                    ],
                    [
                        {content: `${ printData.created_by == null ? this.USERACCOUNT_LOAD.name : printData.created_by}`, colSpan: 4,  styles: {valign: 'middle', halign: 'center'}},
                    ],
                    ],
                    bodyStyles: {
                        lineColor: '#000000',
                    }
                });

                doc.text(margin + 376, signatories_upper_y_margin - 55, "Received the above goods and")
                doc.text(margin + 376, signatories_upper_y_margin - 35, "services in good order & condition")
                doc.text(margin + 376, signatories_upper_y_margin, "_______________________________")
                doc.text(margin + 376, signatories_lower_y_margin, "Signature over printed name / date")
            },

            printNow(){
                this.renderPDF(this.getHeaderAndFooterPdf)
            },
        },
        watch:{
            'GET_ADMIN_DELIVERY_RECEIPT.PRINT':{
                handler(val){
                    if(val){
                        this.$store.commit('ADMIN_DELIVERY_RECEIPT_PRINT', false)
                        this.base64Image = this.PURCHASE_ORDER.COMPANY_IMAGE
                        this.printData = this.GET_ADMIN_DELIVERY_RECEIPT.DETAILS.adr_data
                        this.chunk_data = this.GET_ADMIN_DELIVERY_RECEIPT.DETAILS.items
                        this.printNow();
                    }

                }
            }
        }
    }
</script>
<style scope>
.border-left-none{
  border-left:1px solid red;
}
</style>
