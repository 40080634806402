<template>
    <v-card>
        <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
            <span class="text-h5">{{actions + ' Timekeeping Record' }}</span>
            <v-row class="m-0">
                <v-col cols="pull-right-10 p-2">
                    <v-btn
                        text
                        icon
                        small
                        color="gray"
                        class="float-right"
                        @click="closeDialog()"
                    >
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text>
            <v-container class="pb-0 my-2" fluid>
                <v-row>
                    <v-col cols="12" sm="5" class="pa-0 pr-1">
                        <v-text-field
                            v-model="form.bio_period"
                            label="Biometrics Period"
                            background-color="grey lighten-2"
                            readonly
                            outlined
                            required
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" class="pa-0 pr-1">
                        <v-tooltip bottom color="green" v-if="userAccess.includes('compute_timekeeping_to_excel') && !loading.datatable">
                            <template v-slot:activator="{ on, attrs }" >
                                <v-btn
                                    icon
                                    small
                                    @click="computeTimekeepingToExcel()"
                                    color="green"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon>mdi-file-excel</v-icon>
                                </v-btn>
                            </template>
                            <span>Compute Timekeeping</span>
                        </v-tooltip>
                        <v-tooltip bottom color="#f69f1a" v-if="userAccess.includes('generate_filemaker_excel') && GET_TIMEKEEPING_RECORD_VIEW.status === 3">
                            <template v-slot:activator="{ on, attrs }" >
                                <v-btn
                                    icon
                                    small
                                    @click="checkStatusForGenerate()"
                                    color="#f69f1a"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon>mdi-file-chart-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Generate Filemaker Excel</span>
                        </v-tooltip>
                    </v-col>
                    <v-col cols="12" sm="4" class="pa-0 pr-1">
                        <v-textarea
                            v-model="form.remarks"
                            :readonly="disabled_view"
                            label="Remarks"
                            rows="2"
                            outlined
                            dense
                        >
                        </v-textarea>
                    </v-col>
                </v-row>
                <v-row>
                    <v-spacer></v-spacer>
                    <v-col cols="4">
                        <v-card>
                            <v-card-text>
                                <v-row no-gutters>
                                    <v-col>
                                        Legend:
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <!-- <v-col v-for="(item, i) in legends" :key="i" cols="2" class="ma-0 "> -->
                                    <span v-for="(item, i) in legends" :key="i" class="ma-1">
                                        <v-chip :color="item.color" small>
                                            <span :style="`color:${item.text_color}`">{{ item.text }}</span>
                                        </v-chip>
                                    </span>
                                    <!-- </v-col> -->
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                 <v-row>
                    <v-spacer></v-spacer>
                    <v-col cols="2">
                        <v-switch
                            v-model="filters.with_incomplete_time_in_out"
                            :true-value="1"
                            :false-value="0"
                            label="Show Incomplete Records"
                            dense
                        >
                        </v-switch>
                    </v-col>
                    <v-col cols="2">
                        <v-switch
                            v-model="filters.with_manual"
                            :true-value="1"
                            :false-value="0"
                            label="Show Manual Records"
                            dense
                        >
                        </v-switch>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-card class=" pa-0 overflow-auto" style="max-height:60vh;" elevation="0">
                            <v-data-table
                                :headers="datatable.headers"
                                :items="datatable.items"
                                :expanded.sync="datatable.expanded"
                                :item-class="itemRowBackground"
                                :loading="loading.datatable"
                                @item-expanded="loadItemsDetails"
                                show-expand
                                disable-sort
                                item-key="employee_id"
                                dense
                            >
                                <template v-slot:[`item.employee_name`]="{item}">
                                        <span>{{ item.employee_name }}</span>
                                        <v-icon color="red" class="ml-2" small v-if="item.inCompleteLog > 0 && item.schedule_type_id !== 3">mdi-alert</v-icon>
                                </template>
                                <template v-slot:expanded-item="{ headers, item: parentItem }">
                                    <td :colspan="headers.length" >
                                        <v-data-table
                                            :headers="datatable.sub_header"
                                            :items="parentItem.biometricdata"
                                            :items-per-page="-1"
                                            class="w-100"
                                            disable-sort
                                            item-key="id"
                                            hide-default-footer
                                            dense
                                        >

                                            <template v-slot:header.action v-if="actions != 'View'">
                                                <v-row class="d-flex justify-content-end">
                                                    <v-spacer></v-spacer>
                                                    <v-col cols="4">
                                                        <v-btn
                                                            v-if="actions != 'View'"
                                                            fab dark small color="primary"
                                                            style="height: 20px; width: 20px"
                                                            @click="addRecord(parentItem), getCurrentIndexDates(parentItem.biometricdata)"
                                                        >
                                                            <v-icon dark small style="font-size: 12px">mdi-plus</v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                    <v-spacer></v-spacer>
                                                </v-row>
                                            </template>
                                            <template v-slot:item="{ item, index }">
                                                <tr v-if="item.editActive == false"
                                                :class="{
                                                    'leave_w_pay': checkLeaveData(item, 1),
                                                    'leave_wo_pay': checkLeaveData(item, 0),
                                                    'highlight_manual': item.manual == 1,
                                                    'rest_day_schedule': item.isRestDay,
                                                    'with_no_time_out': checkIfWithNoTimeOut(item, parentItem),
                                                    'no_record': item.absent == 1 && item.isRestDay == 0,
                                                }"
                                                >
                                                    <!-- Date -->
                                                    <td> {{ item.date_punched }} </td>
                                                    <!-- Day -->
                                                    <td> {{ item.day }} </td>
                                                    <!-- Hours Worked -->
                                                    <td> {{ item.hours_worked }} </td>
                                                    <!-- Late in Hours -->
                                                    <td> {{ item.late_in_hours }} </td>
                                                    <!-- Location -->
                                                    <td> {{ item.location }} </td>
                                                    <!--  Time In -->
                                                    <td> {{ item.time_in }} </td>
                                                    <!-- Time Out -->
                                                    <td> {{ item.time_out }} </td>
                                                    <!-- Origin -->
                                                    <td>
                                                        <span
                                                            v-if="item.manual == 1"
                                                            :class="`ml-1 badge badge-manual`"
                                                        >
                                                        manual
                                                        </span>
                                                        <span
                                                            v-else-if="item.mobile == 1"
                                                            :class="`ml-1 badge badge-mobile`"
                                                        >
                                                        mobile
                                                        </span>
                                                        <span
                                                            v-else-if="item.mobile == 0 && item.manual == 0"
                                                            :class="`ml-1 badge badge-biometrics`"
                                                        >
                                                        biometrics
                                                        </span>
                                                    </td>
                                                    <!-- Status -->
                                                    <td>
                                                        <span
                                                        v-if="item.manual == 1 && item.approved_by !== null"
                                                        :class="`ml-1 badge badge-approved`"
                                                        >
                                                        approved
                                                        </span>
                                                        <span
                                                        v-else-if="item.manual == 1 && item.approved_by == null"
                                                        :class="`ml-1 badge badge-pending`"
                                                        >
                                                        pending
                                                        </span>
                                                        <span
                                                        v-else-if="item.mobile == 0 && item.manual == 0"
                                                        :class="`ml-1 badge badge-biometrics`"
                                                        >
                                                        biometrics
                                                        </span>
                                                    </td>

                                                    <td v-if="actions != 'View'"  class="d-flex justify-content-center align-items-center">
                                                        <v-tooltip bottom color="#337CCF">
                                                            <template v-slot:activator="{ on, attrs }" >
                                                                <v-btn
                                                                    icon
                                                                    small
                                                                    @click="item.editActive = true, getCurrentIndexDates(parentItem.biometricdata)"
                                                                    color="#337CCF"
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                >
                                                                    <v-icon>mdi-calendar-edit</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Edit Record</span>
                                                        </v-tooltip>

                                                        <v-tooltip bottom v-if="item.manual == 1">
                                                            <template v-slot:activator="{ on, attrs }" >
                                                                <v-btn
                                                                    icon
                                                                    small
                                                                    @click="viewRecordFiles(item.id)"
                                                                    color=""
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                >
                                                                    <v-badge
                                                                        :content="item.uploadedFile.length"
                                                                        offset-x="20"
                                                                        offset-y="-3"
                                                                        color="#f69f1a"
                                                                    ></v-badge>
                                                                    <v-icon>mdi-file-clock-outline</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>View Record Proof</span>
                                                        </v-tooltip>

                                                        <v-tooltip bottom color="red">
                                                            <template v-slot:activator="{ on, attrs }" >
                                                                <v-btn
                                                                    icon
                                                                    small
                                                                    @click="deleteRecord(parentItem.employee_id, index, item.id)"
                                                                    color="red"
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                >
                                                                    <v-icon>mdi-delete</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Delete Record</span>
                                                        </v-tooltip>
                                                    </td>
                                                    <td v-else-if="actions =='View' && item.manual == 1" class="d-flex justify-content-center align-items-center">
                                                        <v-tooltip bottom color="#50C4ED" v-if="item.approved_by == null">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    icon
                                                                    small
                                                                    @click="approveRecord(item.id, item, parentItem)"
                                                                    color="#50C4ED"
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                >
                                                                    <v-icon>mdi-check-circle</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Approve Record</span>
                                                        </v-tooltip>

                                                        <v-tooltip bottom color="#1198b9">
                                                            <template v-slot:activator="{ on, attrs }" >
                                                                <v-btn
                                                                    icon
                                                                    small
                                                                    @click="viewRecordFiles(item.id)"
                                                                    color="#1198b9"
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                >
                                                                    <v-badge
                                                                        :content="item.uploadedFile.length"
                                                                        offset-x="10"
                                                                        offset-y="-3"
                                                                        color="#1198b9"
                                                                    ></v-badge>
                                                                    <v-icon>mdi-file-clock-outline</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>View Record Proof</span>
                                                        </v-tooltip>
                                                    </td>
                                                    <td v-else-if="actions =='View' && item.manual == 0">
                                                    </td>
                                                </tr>
                                                <tr v-if="item.editActive == true">
                                                    <td>
                                                        <v-menu
                                                            v-model="item.date_menu"
                                                            :close-on-content-click="true"
                                                            transition="scale-transition"
                                                            offset-y
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field
                                                                    v-model="item.date_punched"
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    :readonly="item.readOnly"
                                                                    :rules="errors.required"
                                                                    outlined
                                                                    dense
                                                                >
                                                                </v-text-field>
                                                            </template>
                                                            <v-date-picker
                                                                v-model="item.date_punched"
                                                                @input="setDay(item, parentItem)"
                                                                :allowed-dates="allowedDates"
                                                                :min="GET_TIMEKEEPING_RECORD_VIEW.timekeeping_period_start"
                                                                :max="GET_TIMEKEEPING_RECORD_VIEW.timekeeping_period_end"
                                                                color="#f69f1a"
                                                                header-color="#f69f1a"
                                                                scrollable
                                                            >
                                                            </v-date-picker>
                                                        </v-menu>
                                                    </td>
                                                    <td>
                                                        <v-text-field
                                                            v-model="item.day"
                                                            outlined
                                                            readonly
                                                            background-color="grey lighten-2"
                                                            dense
                                                        >
                                                        </v-text-field>
                                                    </td>
                                                    <td>
                                                        <v-text-field
                                                            v-model="item.hours_worked"
                                                            outlined
                                                            readonly
                                                            background-color="grey lighten-2"
                                                            dense
                                                        >
                                                        </v-text-field>
                                                    </td>
                                                    <td>
                                                        <v-text-field
                                                            v-model="item.late_in_hours"
                                                            outlined
                                                            readonly
                                                            background-color="grey lighten-2"
                                                            dense
                                                        >
                                                        </v-text-field>
                                                    </td>
                                                    <td>
                                                        <v-text-field
                                                            v-model="item.location"
                                                            outlined
                                                            readonly
                                                            background-color="grey lighten-2"
                                                            dense
                                                        >
                                                        </v-text-field>
                                                    </td>
                                                    <td>
                                                        <v-menu
                                                            v-model="item.time_in_menu"
                                                            :close-on-content-click="false"
                                                            transition="scale-transition"
                                                            offset-y
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field
                                                                    v-model="item.time_in"
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    :rules="errors.required"
                                                                    readonly
                                                                    outlined
                                                                    dense
                                                                >
                                                                </v-text-field>
                                                            </template>
                                                            <v-time-picker format="24hr" :max="item.time_out" scrollable @click:minute="item.time_in_menu = false" v-model="item.time_in">
                                                            </v-time-picker>
                                                        </v-menu>
                                                    </td>
                                                    <td>
                                                        <v-menu
                                                            v-model="item.time_out_menu"
                                                            :close-on-content-click="false"
                                                            transition="scale-transition"
                                                            offset-y
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field
                                                                    v-model="item.time_out"
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    :rules="errors.required"
                                                                    readonly
                                                                    outlined
                                                                    dense
                                                                >
                                                                </v-text-field>
                                                            </template>
                                                            <v-time-picker format="24hr" :min="item.time_in" scrollable @click:minute="item.time_out_menu = false" v-model="item.time_out">
                                                            </v-time-picker>
                                                        </v-menu>
                                                    </td>
                                                    <!-- Origin -->
                                                    <td>
                                                        <span
                                                        v-if="item.manual == 1"
                                                        :class="`ml-1 badge badge-manual`"
                                                        >
                                                        manual
                                                        </span>
                                                        <span
                                                        v-else-if="item.mobile == 1"
                                                        :class="`ml-1 badge badge-mobile`"
                                                        >
                                                        mobile
                                                        </span>
                                                        <span
                                                        v-else-if="item.mobile == 0 && item.manual == 0"
                                                        :class="`ml-1 badge badge-biometrics`"
                                                        >
                                                        biometrics
                                                        </span>
                                                    </td>

                                                    <!-- Status -->
                                                    <td>
                                                        <span
                                                        v-if="item.manual == 1 && item.approved_by === null"
                                                        :class="`ml-1 badge badge-manual`"
                                                        >
                                                        pending
                                                        </span>
                                                        <span
                                                        v-else-if="item.manual == 1 && item.approved_by !== null"
                                                        :class="`ml-1 badge badge-mobile`"
                                                        >
                                                        approved
                                                        </span>
                                                        <span
                                                        v-else-if="item.mobile == 0 && item.manual == 0"
                                                        :class="`ml-1 badge badge-biometrics`"
                                                        >
                                                        biometrics
                                                        </span>
                                                    </td>
                                                    <td v-if="actions != 'View'"  class="d-flex justify-content-center align-items-center">
                                                        <v-tooltip  bottom color="blue">
                                                            <template v-slot:activator="{ on, attrs }" >
                                                                <v-btn
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    icon
                                                                    small
                                                                    @click="reComputeTimekeepingRecord(parentItem, item)"
                                                                    color="blue"
                                                                >
                                                                    <v-icon>mdi-check-bold</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Save Record</span>
                                                        </v-tooltip>

                                                        <input type="file" @change="handleFileUpload(parentItem, item, $event)" style="display: none;" ref="fileInput" accept=".jpg,.jpeg,.png" />
                                                        <v-tooltip bottom color="#7383e3">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    icon
                                                                    small
                                                                    @click="$refs.fileInput.click()"
                                                                    color="#7383e3"
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                >
                                                                    <v-icon>mdi-upload</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Upload Proof</span>
                                                        </v-tooltip>

                                                        <v-tooltip bottom color="red">
                                                            <template v-slot:activator="{ on, attrs }" >
                                                                <v-btn
                                                                    icon
                                                                    small
                                                                    @click="deleteRecord(parentItem.employee_id, index, item.id)"
                                                                    color="red"
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                >
                                                                    <v-icon>mdi-delete</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Delete Record</span>
                                                        </v-tooltip>
                                                    </td>
                                                </tr>
                                            </template>
                                        </v-data-table>
                                    </td>
                                </template>
                                <template v-slot:[`item.action`]="{ item, index }">
                                    <v-tooltip bottom color="#B80000">
                                        <template v-slot:activator="{ on, attrs }" >
                                            <v-btn
                                                v-if="form.status != 3 && actions != 'View'"
                                                @click="removeEmployee(item.id, index, item.employee_id)"
                                                icon
                                                small
                                                color="#B80000"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>mdi-minus-circle</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Remove Employee</span>
                                    </v-tooltip>
                                </template>
                            </v-data-table>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions
            class="text-center"
            style="border-top: 1px solid #dee2e6"
        >
            <v-row class="ma-1 pb-2">
                <v-col cols="12" v-if="actions != 'View'">
                    <v-btn
                        :loading="loading.button"
                        @click="updateTimekeepingRecord()"
                    >
                        <span>{{ actions }}</span>
                    </v-btn>
                    <span class="pr-12">&nbsp;</span>
                </v-col>
                <v-col cols="12" v-if="actions == 'View'">
                    <v-btn
                        v-if="GET_TIMEKEEPING_RECORD_VIEW.status == 1 && (userAccess.includes('approve_timekeeping_record') || USERACCOUNT_LOAD.manager == 1)"
                        :loading="loading.button"
                        @click="changeTimekeepingRecordStatus('approve')"
                    >
                        <span>Approve</span>
                    </v-btn>
                    <v-btn
                        v-if="GET_TIMEKEEPING_RECORD_VIEW.status == 2 && (userAccess.includes('confirm_timekeeping_record') || USERACCOUNT_LOAD.manager == 1)"
                        :loading="loading.button"
                        @click="changeTimekeepingRecordStatus('confirm')"
                    >
                        <span>Confirm</span>
                    </v-btn>
                    <span class="pr-12">&nbsp;</span>
                </v-col>
            </v-row>
        </v-card-actions>
        <BaseFileViewer :timekeeping_record_id="timekeeping_record_id"></BaseFileViewer>
        <ConfirmOverrideComponentVue
            :approve_override_dialog="override.override_dialog"
            :departments_allowed="override.override_departments_allowed"
            :heading="override.headers"
            @closeConfirmOverride="closeConfirmOverride"
        ></ConfirmOverrideComponentVue>
    </v-card>
</template>
<script>
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue'
import HRTimekeepingFunctionsComponent from '@/views/main/Shared/HRTimekeepingFunctionsComponent.vue'
import BaseFileViewer from "@/views/main/layouts/dialogs/HR/Timekeeping/TimekeepingRecordFilesViewer.vue";
import ConfirmOverrideComponentVue from "@/views/main/Utils/ConfirmOverrideComponent.vue";

import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'

export default {
    mixins: [
        SharedFunctionsComponentVue,
        HRTimekeepingFunctionsComponent
    ],
    components: {
		BaseFileViewer,
        ConfirmOverrideComponentVue,
    },
    data(){
        return{
            valid: false,
            overlay: false,
            disabled_view: false,
            errors: {
                required: [(v) => !!v || "Field is required"],
                number: [
                    (v) => !!v || "Field is required",
                    (v) => !isNaN(v) || "Input must be a number",
                ],
            },
            form: {
                record_trash_ids: []
            },
            datatable:{
                headers: [
                    { text: 'Employee Name', align: 'start', value: 'employee_name'},
                    { text: 'Hours Worked', align: 'start', value: 'total_hours_worked'},
                    { text: 'Days Worked', align: 'start', value: 'days_worked'},
                    { text: 'Late & Tardiness in Hours Total', align: 'start', value: 'total_late_in_hours'},
                    { text: 'Pending Records Count', align: 'center', value: 'pending_record_count_employee'},
                    { text: 'Attachment Count', align: 'center', value: 'attachment_count'},
                    { text: 'Action',  align: 'center', value: 'action'},
                ],
                sub_header: [
                    { text: 'Date',  align: 'start', value: 'date_punched'},
                    { text: 'Day',  align: 'start', value: 'day'},
                    // { text: 'Excess Hours',  align: 'start', value: 'excess_minutes'},
                    { text: 'Hours Worked',  align: 'start', value: 'hours_worked'},
                    { text: 'Late In Hours',  align: 'start', value: 'late_in_hours'},
                    { text: 'Location',  align: 'start', value: 'location'},
                    { text: 'Time In',  align: 'start', value: 'time_in'},
                    { text: 'Time Out',  align: 'start', value: 'time_out'},
                    { text: 'Origin', width: '15%',  align: 'start', value: 'origin'},
                    { text: 'Status', width: '15%',  align: 'start', value: 'status'},
                    { text: 'Action', width: '5%',  align: 'center', value: 'action'},
                ],
                sub_item: [],
                items: [],
                filtered_items: [],
                options: {},
                totalItems: 0,
                expanded: [],
            },
            loading:{
                button: false,
                datatable: false
            },
            notIncludedDates: [],

            trash_ids:{
                record_details: []
            },
            component_dispatches: [
                'getHolidays',
                'getHourTypes'
            ],
            userAccess: [],
            timekeeping_record_id: '',
            override: {
                override_dialog: false,
                errors: [],
                override_departments_allowed: ["HR", "IT"],
                override_authenticated: false,
            },
            filters: {
                with_manual: false,
                with_incomplete_time_in_out: false,
            },
            legends: [
                { text: "Inactive", color: "#f44336", text_color: "white" },
                { text: "Manual Add", color: "#a9dcb5", text_color: 'black' },
                { text: "Deleted Record", color: "#fda1a1", text_color: 'black' },
                { text: "Incomplete Logs", color: "#6c99fa", text_color: 'white' },
                { text: "Rest Day", color: "#28ffe2", text_color: 'black' },
                { text: "No Record/Absent", color: "#c990ff", text_color: 'black' },
                { text: "Leave w/pay", color: "#eead8e", text_color: "black" },
                { text: "Leave wo/pay", color: "#ff92d5", text_color: "black" },
            ],
            flexiManagerScheduleID: ''
        }
    },
    computed:{
        ...mapGetters([
            'GET_EMPLOYEE_BIO_TIME',
            'GET_SELECTED_DATE_RANGE',
            'GET_BIOMETRICS_DATA',
            'ACTION',
            'GET_TIMEKEEPING_RECORD_VIEW',
            'DIALOGS',
            'GET_HR_DISPATCH',
            'GET_HOLIDAYS_SELECTION',
            'GET_HOUR_TYPE_SELECTION',
            'USER_ACCESS',
            'USERACCOUNT_LOAD',
            'GET_EMPLOYEE_SCHEDULE_TYPE_SELECTION'
        ]),
        actions() {
            let index = this.datatable.headers.findIndex(item => item.value === 'action');

            if (this.ACTION == "Submit") {
                this.disabled_view = false;
                return "Submit";
            } else if (this.ACTION == "Update") {
                this.disabled_view = false;
                this.getTimekeepingRecordData();
                if (index == -1) {
                    this.datatable.headers.push(
                        { text: 'Action', align: 'center', value: 'action' }
                    );
                }
                return "Update";
            } else if (this.ACTION == "View") {
                this.disabled_view = true;
                this.getTimekeepingRecordData();
                if (index !== -1) {
                    this.datatable.headers.splice(index, 1);
                }
                return "View";
            }
        },
    },
    mounted(){
        this.getAllDropdowns();
        this.$store.dispatch('getHolidays')
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        this.$store.dispatch('getEmployeeScheduleType').then((res)=>{
            this.setManagerFlexiID()
        })
    },
    methods:{
        getAllDropdowns(){
            this.component_dispatches.forEach((e)=>{
                this.$store.dispatch(e)
            })
        },
        closeDialog(){
            this.$store.commit('DIALOG', false);
            this.$store.commit('TIMEKEEPING_RECORD_VIEW', {});
            this.form.record_trash_ids = [];
        },
        getBiometricDetails(){
            this.form.bio_period = this.dateWithFormat(this.GET_TIMEKEEPING_RECORD_VIEW.timekeeping_period_start, "MMMM DD, YYYY") + ' - ' + this.dateWithFormat(this.GET_TIMEKEEPING_RECORD_VIEW.timekeeping_period_end, "MMMM DD, YYYY")
        },
        loadItemsDetails(){
        },
        addRecord(item) {
            if(item.biometricdata.length < 15){
                let index = this.findIndexInArrayObjects(this.datatable.items, 'employee_id', item.employee_id);
                const newItem = {
                    date_punched: this.$dayjs(this.GET_TIMEKEEPING_RECORD_VIEW.timekeeping_period_start).format('YYYY-MM'),
                    day: '',
                    excess_minutes: 0,
                    hours_worked: 0,
                    late_in_hours: 0,
                    time_in: '',
                    time_out: '',
                    editActive: true,
                    readOnly: false,
                    manual: 1,
                    uploadedFile: '',
                    approved_by: null,
                };
                this.datatable.items[index].biometricdata.push(newItem)
            }else{
                Swal.fire({
                    title: "Excess Date",
                    text: `Must be only 15 days`,
                    icon: "warning",
                })
                return false
            }
        },
        allowedDates(val){
            return !this.notIncludedDates.includes(val);
        },
        deleteRecord(employee_id, key, id = null){
            Swal.fire({
                title: "Delete",
                text: `Are you sure you want to delete ?`,
                icon: 'question',
                showConfirmButton:true,
                confirmButtonText:'Confirm',
                showCancelButton:true,
                showCloseButton:true,
                confirmButtonColor: '397373',
                cancelButtonColor:'grey',
                reverseButtons:true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    let index = this.findIndexInArrayObjects(this.datatable.items, 'employee_id', employee_id);
                    this.datatable.items[index].biometricdata.splice(key, 1);
                    if(id != null){
                        this.trash_ids.record_details.push(id)
                    }
                }
            });
        },
        getCurrentIndexDates(data){
            let dateRange = this.getDatesFromRange(this.GET_TIMEKEEPING_RECORD_VIEW.timekeeping_period_start, this.GET_TIMEKEEPING_RECORD_VIEW.timekeeping_period_end, "array");
            this.notIncludedDates = data.map(e=>{
                if(dateRange.includes(e.date_punched)){
                    return e.date_punched
                }
            })
        },
        setDay(item, parentItem = null){
            let dayName = this.$dayjs(item.date_punched).format('dddd');
            let payload = {
                employee_id: parentItem.employee_id,
                day: dayName
            }

            this.$store.dispatch('getTimekeepingScheduleDay', payload).then((response)=>{
                this.$set(item, 'day', dayName)
                this.$set(item, 'schedule_time_in', response.time_in)
                this.$set(item, 'schedule_time_out', response.time_out)
                this.$set(item, 'hours_to_work_day', response.hours_to_work_day)
                this.$set(item, 'break_duration', response.break_duration)
            }).catch((error)=>{
                Swal.fire(
                    "Network Error",
                    "",
                    "error"
                );
                console.log(error)
            })
        },
        updateTimekeepingRecord(){

            // let hasTimeInOut = this.datatable.items.every((item) => item.biometricdata.length === 0)
            // const has_time_date_swal_text 			    = hasTimeInOut ? 'Timekeeping Record Details' : '';

            // const array_text =
            // [
            //     has_time_date_swal_text,
            // ]
            // const filtered = array_text.filter(function (el) {
            //     return el.replace(',', '');
            // })

            // if (!hasTimeInOut) {
            this.loading.button = true;
            let payload = {
                ...this.form,
                timekeeping_records: this.datatable.items,
                // record_detail_trash_ids: this.trash_ids.record_details
            }
            this.$store.dispatch(this.GET_HR_DISPATCH.update, payload).then((res)=>{
                this.loading.button = false;
                Swal.fire(
                    res.message,
                    "",
                    res.icon
                )
                this.closeDialog();
                this.$store.commit('BIOMETRICS_SUMMARY_DIALOG', false);
            }).catch((err)=>{
                this.loading.button = false;
                console.log(err)
                this.$store.commit('BIOMETRICS_SUMMARY_DIALOG', false);
                this.closeDialog();
            })
            // }
            // else {
            //     Swal.fire({
            //         text: `Fill Required Fields (${filtered})`,
            //         icon: 'warning',
            //         allowOutsideClick: false,
            //     });
            //     this.loading.button = false;
            // }

        },
        handleFileUpload(parentItem, item, event) {
            const file = event.target.files[0];
            let that = this
            const blobFile = new Blob([file], { type: file.type });

            var reader  = new FileReader();
            reader.readAsDataURL(blobFile);
            reader.onloadend = function () {
                that.$set(item, 'uploadedFile', reader.result);
            }
        },
        getTimekeepingRecordData(){
            if(this.DIALOGS){
                if(_.isEmpty(this.GET_TIMEKEEPING_RECORD_VIEW)){
                    this.loading.datatable = true;
                }else{
                    this.loading.datatable = false;
                }
                this.form.bio_period = [
                    this.dateWithFormat(this.GET_TIMEKEEPING_RECORD_VIEW.timekeeping_period_start, "MMMM DD, YYYY") +
                    ' - ' +
                    this.dateWithFormat(this.GET_TIMEKEEPING_RECORD_VIEW.timekeeping_period_end, "MMMM DD, YYYY")
                ];
                this.form.remarks = this.GET_TIMEKEEPING_RECORD_VIEW.remarks;
                this.datatable.items = this.GET_TIMEKEEPING_RECORD_VIEW.timekeeping_record_details;
                this.form.id = this.GET_TIMEKEEPING_RECORD_VIEW.id
                this.form.status = this.GET_TIMEKEEPING_RECORD_VIEW.status
                this.form.pending_record_count = this.GET_TIMEKEEPING_RECORD_VIEW.pending_record_count
                this.datatable.expanded = [];
            }
        },
        resetFields(){
            this.trash_ids.record_details = [];
        },
        approveRecord(id, item, parentItem){
            // if(item.uploadedFile.length == 0){
            //     Swal.fire(
            //         "Cannot Approve Record!",
            //         "No File Uploaded!",
            //         "warning"
            //     )
            //     return false;
            // }else{
            Swal.fire({
                title: `${item.uploadedFile.length == 0 ? 'No File Uploaded!' : ''}`,
                text: 'Are you sure you want to Approve?',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(async(result) => {
                if(result.isConfirmed) {
                    let payload = {
                        id: id
                    }
                    this.$store.dispatch('approveTimekeepingRecordManual', payload).then((res)=>{
                        this.$set(item, 'approved_by', res.approved_by);
                        this.$set(parentItem, 'pending_record_count_employee', res.pending_record_count_employee);
                        this.form.pending_record_count = res.pending_record_count
                        Swal.fire(
                            "Success",
                            "",
                            "success"
                        )
                    }).catch((err)=>{
                        console.log(err)
                        Swal.fire(
                            "Error",
                            "",
                            "error"
                        )
                    })
                }
            })
            // }
        },
        async computeTimekeepingToExcel(){
            Swal.fire({
                title: 'Computing Records Please Wait.',
                allowOutsideClick: false,
                showCancelButton: false,
                showConfirmButton: false,
            });
            Swal.showLoading()
            let timekeeping_datas = await this.computeTimekeepingHoursRecord(this.datatable.items);

            const workbook = new this.$exceljs.Workbook();
            const rawDataSheetName = `Detailed`;

            let worksheet = workbook.addWorksheet(rawDataSheetName);
            let headers = [
                { header: 'Employee Name', key: 'employee_name' },
                { header: 'Date', key: 'date' },
                { header: 'Day', key: 'day' },
                { header: 'Rest Day', key: 'rest_day' },
                { header: 'Holiday', key: 'holiday' },
                { header: 'Absent', key: 'absent' },
                { header: 'Leave', key: 'with_leave' },
                { header: 'Time In', key: 'time_in' },
                { header: 'Time Out', key: 'time_out' },
                { header: 'Total Hours Worked', key: 'total_hours_worked' },
                { header: 'Late (mins)', key: 'late_in_mins' },
                { header: 'Excess Minutes', key: 'excess_minutes' },
                { header: 'OT Confirmed (mins)', key: 'ot_confirmed' },
            ];

            worksheet.views = [
                { state: 'frozen', xSplit: 1, ySplit: 1 }
            ]

            worksheet.columns = headers.map(header => ({
                header: header.header,
                key: header.key,
                width: Math.max(15, header.header.length * 1.2), // Adjust minimum width if needed
                style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } },
            }));

            worksheet.columns.forEach(column => {
                column.width = Math.max(15, column.header.length * 1.2); // Adjust minimum width if needed
            });


            worksheet.getRow(1).font = { bold: true };
            worksheet.getRow(1).height = 20;
            let borderStyles = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" }
            };
            worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
                row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
                    cell.border = borderStyles;
                });
            });


            let index = 0;
            let classificationArray = []
            for await (let timekeeping_data of timekeeping_datas) {
                for await (let timekeeping_details of timekeeping_data.data) {
                    worksheet.addRow(timekeeping_details)
                    let classificationObject = await this.calculateHourClassification(timekeeping_details, timekeeping_data.data);
                    classificationArray.push({
                        date: timekeeping_details.date,
                        hours_classification: classificationObject,
                        employee_id: timekeeping_details.employee_id
                    })
                    let keyValueString = Object.entries(classificationObject)
                        .filter(([key, value]) => (value != 0 || value == 'HP') && key !== 'day_worked')
                        .map(([key, value]) => `${key}: ${value}`)
                        .join('\n');
                    if(keyValueString){
                        worksheet.getCell(`J${index + 2}`).note = keyValueString;
                    }
                    // worksheet.getCell(`H${index + 2}`).note = JSON.stringify(await this.calculateHourClassification(x, e));
                    index++
                }
            }

            const summaryDataSheetName = `Summary`;

            let summarySheet = workbook.addWorksheet(summaryDataSheetName);
            let summaryHeaders = [
                { header: 'Employee Name', key: 'employee_name' },
                { header: 'Department', key: 'department_name' },
                { header: 'Total Late (mins)', key: 'total_late_in_minutes' },
                { header: 'Total Days Worked', key: 'total_days_worked' },
                { header: 'Total No. Of Days', key: 'total_no_of_days' },
                { header: 'Total Absent Days', key: 'total_absent' },
                { header: 'Total Leave With Pay Days', key: 'total_leave_with_pay' },
            ];

            summarySheet.columns = summaryHeaders;

            summarySheet.columns.forEach(column => {
                column.width = Math.max(15, column.header.length * 1.2); // Adjust minimum width if needed
            });

            summarySheet.views = [
                { state: 'frozen', xSplit: 1, ySplit: 1 }
            ]

            summarySheet.getRow(1).font = { bold: true };
            summarySheet.getRow(1).height = 20;
            summarySheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
                row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
                    cell.border = borderStyles;
                });
            });

            let timekeeping_summary_datas = await this.computeTimekeepingRecordSummary(timekeeping_datas, classificationArray);

            let summaryIndex = 0;
            for await (let timekeeping_summary_data of timekeeping_summary_datas) {
                summarySheet.addRow(timekeeping_summary_data)
            }
            // Swal.close()


            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheetcharset=utf-8' })
                const filename = `Timekeeping With Hours System.xlsx`
                this.$filesaver.saveAs(blob, filename)
                Swal.close()
            })
        },
        async computeTimekeepingHoursRecord(biometricsdata) {
            let dateRangeArr = await this.getDatesFromRange(this.GET_TIMEKEEPING_RECORD_VIEW.timekeeping_period_start, this.GET_TIMEKEEPING_RECORD_VIEW.timekeeping_period_end, 'array');

            let timekeepingRecordArr = [];
            for await (let e of biometricsdata) {
                let bioDataArr = [];

                for await (let date of dateRangeArr) {
                    let record = null;
                    let holiday = this.GET_HOLIDAYS_SELECTION.find((e)=> e.date == date);
                    let dayName = this.$dayjs(date).format('dddd');
                    let hasExemption = 0;

                    if (e.biometricdata.find((e) => e.date_punched == date)) {
                        record = e.biometricdata.find((e) => e.date_punched == date);
                    } else {
                        let excluded_days = e.employee_schedule_exemption_days.find((x)=> x.day == dayName)
                        if(excluded_days){
                            record = await this.setDefaultRecord(date, e, 1);
                            hasExemption = 1
                        }
                        record = await this.setDefaultRecord(date, e);
                    }
                    let bioDataObj = {
                        remarks: '',
                        date: '',
                        day: '',
                        rest_day: '',
                        holiday: '',
                        time_in: '',
                        time_out: '',
                        total_hours_worked: '',
                        late_in_mins: '',
                        excess_minutes: '',
                        ot_break: '',
                        isHoliday: 0,
                        provincial: null,
                        break_duration: '',
                        hours_to_work_day: '',
                        schedule_time_out: '',
                        schedule_time_in: '',
                        isRestDay: 0,
                        store_branch_id: 0,
                        employee_id: 0,
                        salary_type: 0,
                        schedule_type: 0,
                        holiday_pay_hours: 0,
                        employee_name: '',
                        employee_schedule_exemption_days: [],
                        ot_confirmed: 0,
                        with_leave: 0,
                        absent: 0,
                        day_worked: 0,
                    };
                    // Assign values to bioDataObj
                    bioDataObj.date = date;
                    bioDataObj.day = record.day;
                    bioDataObj.rest_day = record.isRestDay ? 'Y' : '';
                    // bioDataObj.rest_day = record.rest_day;
                    bioDataObj.time_in = record.time_in ?? null;
                    bioDataObj.time_out = record.time_out ?? null;
                    bioDataObj.break_duration = record.break_duration;
                    bioDataObj.hours_to_work_day = record.hours_to_work_day;
                    bioDataObj.schedule_time_out = record.schedule_time_out;
                    bioDataObj.schedule_time_in = record.schedule_time_in;
                    bioDataObj.employee_id = e.employee_id;
                    bioDataObj.salary_type = e.salary_type;
                    bioDataObj.schedule_type = e.schedule_type_id;
                    bioDataObj.hasExemption = hasExemption;
                    bioDataObj.with_leave = record.with_leave === 1 ? this.checkLeaveRecordType(record) : '';
                    bioDataObj.absent = record.absent == 1 ? 'Y' : '';
                    bioDataObj.store_branch_id = e.store_branch_id;
                    bioDataObj.isRestDay = record.isRestDay ? record.isRestDay : 0;
                    bioDataObj.holiday = holiday ? holiday.holiday_type_code : '';
                    bioDataObj.isHoliday = bioDataObj.holiday ? 1 : 0;
                    bioDataObj.day_worked = record.day_worked ? record.day_worked : 0;
                    bioDataObj.holiday_pay_hours = holiday ? holiday.holiday_pay_hours : 0;
                    bioDataObj.provincial = holiday ? holiday.provincial_holiday : null;
                    // bioDataObj.rest_day = record.day == 'Sunday' ? 'Y' : '' ;
                    bioDataObj.employee_name = e.employee_name ?? null;
                    bioDataObj.employee_schedule_exemption_days = e.employee_schedule_exemption_days ?? [];

                    const scheduleTimeIn = this.$dayjs(record.schedule_time_in, 'HH:mm');
                    const actualTimeIn = this.$dayjs(record.time_in, 'HH:mm');

                    const scheduleTimeOut = this.$dayjs(record.schedule_time_out, 'HH:mm');
                    const actualTimeOut = this.$dayjs(record.time_out, 'HH:mm');

                    const lateInMinutes = actualTimeIn.diff(scheduleTimeIn, 'minute');
                    let lateduration = null;
                    let lateinHours = null

                    var duration = this.$dayjs.duration(actualTimeOut.diff(scheduleTimeOut));
                    var excess_minutes = duration.asMinutes() - (lateInMinutes ?? 0);
                    // let excess_minutes = this.computeHours(record.schedule_time_out, record.time_out) - (lateInMinutes / 60 ?? 0);
                    bioDataObj.ot_confirmed = await this.getConfirmedOT(date, e);

                    if(!record.noTimeIn){
                        // If Flexi Manager
                        if(e.schedule_type_id == 3){
                            bioDataObj.total_hours_worked = 8;
                            bioDataObj.late_in_mins = 0;
                            bioDataObj.excess_minutes = 0;
                            bioDataObj.ot_break = 0;
                        }
                        // else if (hasExemption == 1){
                        //     bioDataObj.total_hours_worked = 8;
                        //     bioDataObj.late_in_mins = 0;
                        //     bioDataObj.excess_minutes = 0;
                        //     bioDataObj.ot_break = 0;
                        // }
                        else{

                            if(record.time_in && record.time_out){
                                let total_hours_worked = actualTimeIn.isBefore(scheduleTimeIn) ? this.computeHours(record.schedule_time_in , record.time_out, 'HH:mm') - record.break_duration  : this.computeHours(lateInMinutes > 0 ? record.time_in : record.schedule_time_in , record.time_out, 'HH:mm') - record.break_duration;
                                let hours_to_work = record.hours_to_work_day - record.break_duration;
                                if(actualTimeOut.isSameOrAfter(scheduleTimeOut)){
                                    lateduration = this.$dayjs.duration(actualTimeIn.diff(scheduleTimeIn));
                                    lateinHours = lateduration.asHours();
                                    duration = this.$dayjs.duration(actualTimeOut.diff(actualTimeIn));

                                    total_hours_worked = duration.asHours();
                                    total_hours_worked = total_hours_worked - record.break_duration;
                                    total_hours_worked = total_hours_worked > 8 ? 8 : total_hours_worked;
                                    total_hours_worked = total_hours_worked - lateinHours;
                                }

                                bioDataObj.total_hours_worked = total_hours_worked >= hours_to_work ? 8 : parseFloat(total_hours_worked.toFixed(2));
                                bioDataObj.late_in_mins = lateInMinutes < 0 ? 0 : lateInMinutes;
                                bioDataObj.excess_minutes = excess_minutes < 30 ? 0 : excess_minutes;
                                bioDataObj.ot_break = record.ot_break;
                            }else{
                                bioDataObj.total_hours_worked = 0;
                                bioDataObj.late_in_mins = 0;
                                bioDataObj.excess_minutes = 0;
                                bioDataObj.ot_break = 0;
                            }
                        }
                    }

                    if(record.manual == 1){
                        if(record.approved_by != null){
                            bioDataArr.push(bioDataObj);
                        }
                    }else{
                        bioDataArr.push(bioDataObj);
                    }
                }

                // Push bioDataArr to timekeepingRecordArr
                timekeepingRecordArr.push(
                    {
                    ...e,
                    data: bioDataArr
                    }
                );
            }
            // Return the computed timekeepingRecordArr
            return timekeepingRecordArr;
        },
        setDefaultRecord(date, biodata, with_default) {
            return new Promise((resolve, reject) => {
                let dayName = this.$dayjs(date).format('dddd');
                if(with_default){
                    dayName = 'Monday'
                }
                let payload = {
                    employee_id: biodata.employee_id,
                    day: dayName,
                    date: date
                }

                this.$store.dispatch('getTimekeepingScheduleDay', payload).then((response) => {
                    let defaultRecord = {
                        break_duration: '',
                        hours_to_work_day: '',
                        date_punched: '',
                        day: dayName,
                        editActive: '',
                        employee_id: biodata.employee_id,
                        hours_worked: '',
                        late_in_hours: '',
                        latitude_in: '',
                        latitude_out: '',
                        longitude_in: '',
                        longitude_out: '',
                        manual: '',
                        mobile: '',
                        reason: '',
                        noTimeIn: 1,
                        schedule_time_out: '',
                        schedule_time_in: '',
                        isRestDay: 0
                    };

                    if (response != 'No Schedule for that day') {
                        defaultRecord.break_duration = response.break_duration;
                        defaultRecord.hours_to_work_day = response.hours_to_work_day;
                        defaultRecord.schedule_time_out = response.time_out;
                        defaultRecord.schedule_time_in = response.time_in;
                        defaultRecord.isRestDay = response.isRestDay;
                        resolve(defaultRecord); // Resolve the promise with defaultRecord
                    } else {
                        defaultRecord.isRestDay = response == 0 ? 1 : 0;
                        resolve(defaultRecord); // Resolve with defaultRecord even if no schedule
                    }
                }).catch((error) => {
                    reject(error); // Reject the promise if there's an error
                });
            });
        },
        getConfirmedOT(date, biodata) {
            return new Promise((resolve, reject) => {
                let payload = {
                    employee_id: biodata.employee_id,
                    date: date
                }

                this.$store.dispatch('getConfirmedOT', payload).then((response) => {
                    resolve(response); // Resolve the promise with defaultRecord
                }).catch((error) => {
                    reject(error); // Reject the promise if there's an error
                });
            });
        },
        // async calculateHourClassification(entry, entry_list) {
        //     let day_type = entry.holiday;
        //     let hour_types = this.GET_HOUR_TYPE_SELECTION;

        //     let hours_summary = {}

        //     switch(day_type){
        //         case "R":
        //                 let with_holiday_pay = 0;

        //                 let checker = {
        //                     after_date: this.$dayjs(entry.date).add(1, 'd').format('YYYY-MM-DD'),
        //                     after_date_record: {},
        //                     before_date: this.$dayjs(entry.date).subtract(1, 'day').format('YYYY-MM-DD'),
        //                     before_date_record: {},
        //                 }

        //                 checker.after_date_record = entry_list.find((x)=> x.date == checker.after_date) ? entry_list.find((x)=> x.date == checker.after_date) : this.checkOtherTimeRecords(checker.after_date, entry.employee_id);
        //                 checker.before_date_record = entry_list.find((x)=> x.date == checker.before_date) ? entry_list.find((x)=> x.date == checker.before_date) : this.checkOtherTimeRecords(checker.before_date, entry.employee_id);

        //                 let hasHolidayOrSunday = this.checkIfHasHolidayBeforeAndAfterOrSunday(checker.after_date, checker.before_date);

        //                 // If holiday or sunday before and after
        //                 if(
        //                     hasHolidayOrSunday.after != -1 &&
        //                     hasHolidayOrSunday.before != -1
        //                 ){
        //                     with_holiday_pay = "HP"
        //                 }
        //                 //  else if hindi holiday or sunday before and after
        //                 else if (
        //                     hasHolidayOrSunday.after == -1 &&
        //                     hasHolidayOrSunday.before == -1
        //                 ){
        //                     // If pumasok before and after holiday date
        //                     if(
        //                         checker.after_date_record.time_in &&
        //                         checker.after_date_record.time_out &&
        //                         checker.before_date_record.time_in &&
        //                         checker.before_date_record.time_out
        //                     ){
        //                         with_holiday_pay = "HP"
        //                     }else{
        //                         with_holiday_pay = 0
        //                     }
        //                 }
        //                 // else if holiday before or sunday and after holiday date is regular day
        //                 // Ex. Regular Holiday date is Monday
        //                 else if (hasHolidayOrSunday.before != -1 && hasHolidayOrSunday.after == -1){
        //                     // If pumasok after holiday date
        //                     if(
        //                         checker.after_date_record.time_in &&
        //                         checker.after_date_record.time_out
        //                     ){
        //                         with_holiday_pay = "HP"
        //                     }else{
        //                         with_holiday_pay = 0
        //                     }
        //                 }
        //                 // else if holiday after or sunday and before holiday date is regular day
        //                 // Ex. Regular Holiday date is Saturday
        //                 else if (hasHolidayOrSunday.after != -1 && hasHolidayOrSunday.before == -1){
        //                     // If pumasok before holiday date
        //                     if(
        //                         checker.before_date_record.time_in &&
        //                         checker.before_date_record.time_out
        //                     ){
        //                         with_holiday_pay = "HP"

        //                     }else{
        //                         with_holiday_pay = 0
        //                     }
        //                 }
        //                 hour_types = hour_types.filter((x)=> x.holiday_type_id == 1)
        //                 hour_types.forEach((x, i)=>{
        //                     // If pumasok on regular holiday, compute based on time in and out, else check if entitled to holiday pay
        //                     if(entry.time_in && entry.time_out){
        //                         if (x.isND == 1){
        //                             hours_summary[x.name] = this.computeHoursBasedOnType(entry, x , 1);
        //                         }
        //                         else if(x.rest_day == 1){
        //                             hours_summary[x.name] = this.computeHoursBasedOnType(entry, x, );
        //                         }
        //                         else{
        //                             hours_summary[x.name] = this.computeHoursBasedOnType(entry, x);
        //                         }
        //                     }else{
        //                         hours_summary[x.name] =  with_holiday_pay;
        //                     }
        //                 })

        //             break;
        //         case "SNW":
        //             hour_types = hour_types.filter((x)=> x.holiday_type_id == 2)

        //             if(entry.salary_type == 1){
        //                 let with_holiday_pay = 0;

        //                 let checker = {
        //                     after_date: this.$dayjs(entry.date).add(1, 'd').format('YYYY-MM-DD'),
        //                     after_date_record: {},
        //                     before_date: this.$dayjs(entry.date).subtract(1, 'day').format('YYYY-MM-DD'),
        //                     before_date_record: {},
        //                 }

        //                 checker.after_date_record = entry_list.find((x)=> x.date == checker.after_date) ? entry_list.find((x)=> x.date == checker.after_date) : this.checkOtherTimeRecords(checker.after_date, entry.employee_id);
        //                 checker.before_date_record = entry_list.find((x)=> x.date == checker.before_date) ? entry_list.find((x)=> x.date == checker.before_date) : this.checkOtherTimeRecords(checker.before_date, entry.employee_id);

        //                 let hasHolidayOrSunday = this.checkIfHasHolidayBeforeAndAfterOrSunday(checker.after_date, checker.before_date);
        //                 console.log(entry.date)
        //                 console.log(hasHolidayOrSunday)
        //                 // // If holiday or sunday before and after
        //                 if(
        //                     hasHolidayOrSunday.after != -1 &&
        //                     hasHolidayOrSunday.before != -1
        //                 ){
        //                     with_holiday_pay = "HP"
        //                 }
        //                 //  else if hindi holiday or sunday before and after
        //                 else if (
        //                     hasHolidayOrSunday.after == -1 &&
        //                     hasHolidayOrSunday.before == -1
        //                 ){
        //                     // If pumasok before and after holiday date
        //                     if(
        //                         checker.after_date_record.time_in &&
        //                         checker.after_date_record.time_out &&
        //                         checker.before_date_record.time_in &&
        //                         checker.before_date_record.time_out
        //                     ){
        //                         with_holiday_pay = "HP"
        //                     }else{
        //                         with_holiday_pay = 0
        //                     }
        //                 }
        //                 // else if holiday before or sunday and after holiday date is regular day
        //                 // Ex. Regular Holiday date is Monday
        //                 else if (hasHolidayOrSunday.before != -1 && hasHolidayOrSunday.after == -1){
        //                     // If pumasok after holiday date
        //                     if(
        //                         checker.after_date_record.time_in &&
        //                         checker.after_date_record.time_out
        //                     ){
        //                         with_holiday_pay = "HP"
        //                     }else{
        //                         with_holiday_pay = 0
        //                     }
        //                 }
        //                 // else if holiday after or sunday and before holiday date is regular day
        //                 // Ex. Regular Holiday date is Saturday
        //                 else if (hasHolidayOrSunday.after != -1 && hasHolidayOrSunday.before == -1){
        //                     // If pumasok before holiday date
        //                     if(
        //                         checker.before_date_record.time_in &&
        //                         checker.before_date_record.time_out
        //                     ){
        //                         with_holiday_pay = "HP"

        //                     }else{
        //                         with_holiday_pay = 0
        //                     }
        //                 }
        //                 hour_types.forEach((x, i)=>{
        //                     if(entry.time_in && entry.time_out){
        //                         if (x.isND == 1){
        //                             hours_summary[x.name] = this.computeHoursBasedOnType(entry, x , 1);
        //                         }
        //                         else if(x.rest_day == 1){
        //                             hours_summary[x.name] = this.computeHoursBasedOnType(entry, x, );
        //                         }
        //                         else{
        //                             hours_summary[x.name] = this.computeHoursBasedOnType(entry, x);
        //                         }
        //                     }else{
        //                         hours_summary[x.name] =  with_holiday_pay;
        //                     }
        //                 })
        //             }else{
        //                 hour_types.forEach((x, i)=>{
        //                     if (x.isND == 1){
        //                         hours_summary[x.name] = this.computeHoursBasedOnType(entry, x , 1);
        //                     }
        //                     else if(x.rest_day == 1){
        //                         hours_summary[x.name] = this.computeHoursBasedOnType(entry, x, );
        //                     }
        //                     else{
        //                         hours_summary[x.name] = this.computeHoursBasedOnType(entry, x);
        //                     }
        //                 })
        //             }
        //             break
        //         case "SW":
        //             hour_types = hour_types.filter((x)=> x.holiday_type_id == 3)
        //             hour_types.forEach((x, i)=>{
        //                 if (x.isND == 1){
        //                     hours_summary[x.name] = this.computeHoursBasedOnType(entry, x , 1);
        //                 }
        //                 else if(x.rest_day == 1){
        //                     hours_summary[x.name] = this.computeHoursBasedOnType(entry, x, );
        //                 }
        //                 else{
        //                     hours_summary[x.name] = this.computeHoursBasedOnType(entry, x);
        //                 }
        //             })
        //             break;
        //         case '':
        //             // Regular Day
        //             hour_types = hour_types.filter((x)=> x.holiday_type_code == null)
        //             hour_types.forEach((x, i)=>{
        //                 if (x.isND == 1){
        //                     hours_summary[x.name] = this.computeHoursBasedOnType(entry, x , 1);
        //                 }
        //                 else if(x.rest_day == 1){
        //                     hours_summary[x.name] = this.computeHoursBasedOnType(entry, x, );
        //                 }
        //                 else{
        //                     hours_summary[x.name] = this.computeHoursBasedOnType(entry, x);
        //                 }
        //             })
        //             break;
        //     }
        //     // console.log(hours_summary)
        //     // console.log(entry.date)
        //     return hours_summary
        // },
        async calculateHourClassification(entry, entry_list) {
            let day_type = entry.holiday;
            let hour_types = this.GET_HOUR_TYPE_SELECTION;
            let hours_summary = {};

            // Checker for leave with pay abang
            // let with_leave = 0;
            // let payload = {
            //     employee_id: entry.employee_id,
            //     date:        entry.date
            // }
            // await this.$store.dispatch('checkLeaveRequestWithPay', payload).then((res)=>{
            //     if(res){
            //         with_leave = 1;
            //     }
            // })

            let getChecker = (date) => ({
                after_date: this.$dayjs(date).add(1, 'd').format('YYYY-MM-DD'),
                after_date_record: {},
                before_date: this.$dayjs(date).subtract(1, 'day').format('YYYY-MM-DD'),
                before_date_record: {},
            });

            let getRecordOrCheckOtherTime = (date, employee_id) => {
                let record = entry_list.find((x) => x.date === date);
                return record ? record : this.checkOtherTimeRecords(date, employee_id);
            };

            let hasHolidayOrSunday = (after_date, before_date) => {
                let result = this.checkIfHasHolidayBeforeAndAfterOrSunday(after_date, before_date);
                return {
                    hasAfter: result.after !== -1,
                    hasBefore: result.before !== -1,
                };
            };

            let setHolidayPay = (hasAfter, hasBefore, checker) => {
                // If holiday or Sunday before and after ng holiday date
                if (hasAfter && hasBefore) {
                    return "HP";
                }
                // If hindi holiday or Sunday before and after ng holiday date
                else if (!hasAfter && !hasBefore) {
                    return (checker.after_date_record.time_in && checker.after_date_record.time_out &&
                            checker.before_date_record.time_in && checker.before_date_record.time_out) ? "HP" : 0;
                // Else if holiday or sunday before and after holiday date is regular day
                // Ex. Holiday date is Monday
                } else if (hasBefore && !hasAfter) {
                    return (checker.after_date_record.time_in && checker.after_date_record.time_out) ? "HP" : 0;
                // Else if holiday after or sunday and before holiday date is regular day
                // Ex. Holiday date is Saturday
                } else if (hasAfter && !hasBefore) {
                    return (checker.before_date_record.time_in && checker.before_date_record.time_out) ? "HP" : 0;
                }
            };

            let findIfApplicableStore = day_type != '' ? entry.provincial.find((x) => x.store_branch_id == entry.store_branch_id) : false;

            switch (day_type) {
                case "R":
                    hour_types = entry.isRestDay == 1 ? hour_types.filter((x) => x.holiday_type_code == day_type && x.rest_day == 1) : hour_types.filter((x) => x.holiday_type_code == day_type & x.rest_day == 0);
                    // hour_types = hour_types.filter((x) => x.holiday_type_id === 1);

                    if(entry.provincial.length > 0 && findIfApplicableStore){
                        let checkerR = getChecker(entry.date);
                        checkerR.after_date_record = getRecordOrCheckOtherTime(checkerR.after_date, entry.employee_id);
                        checkerR.before_date_record = getRecordOrCheckOtherTime(checkerR.before_date, entry.employee_id);

                        let { hasAfter: hasAfterR, hasBefore: hasBeforeR } = hasHolidayOrSunday(checkerR.after_date, checkerR.before_date);

                        let with_holiday_pay_R = setHolidayPay(hasAfterR, hasBeforeR, checkerR);


                        hour_types.forEach((x, i) => {
                            hours_summary[x.name] = entry.time_in && entry.time_out ?
                                (x.isND === 1 ? this.computeHoursBasedOnType(entry, x, 1).hours_worked:
                                    (x.rest_day === 1 ? this.computeHoursBasedOnType(entry, x, 2).hours_worked: this.computeHoursBasedOnType(entry, x)).hours_worked
                                ): with_holiday_pay_R;
                        });
                    }else{
                        let checkerR = getChecker(entry.date);
                        checkerR.after_date_record = getRecordOrCheckOtherTime(checkerR.after_date, entry.employee_id);
                        checkerR.before_date_record = getRecordOrCheckOtherTime(checkerR.before_date, entry.employee_id);

                        let { hasAfter: hasAfterR, hasBefore: hasBeforeR } = hasHolidayOrSunday(checkerR.after_date, checkerR.before_date);

                        let with_holiday_pay_R = setHolidayPay(hasAfterR, hasBeforeR, checkerR);

                        hour_types.forEach((x, i) => {
                            hours_summary[x.name] = entry.time_in && entry.time_out ?
                                (x.isND === 1 ? this.computeHoursBasedOnType(entry, x, 1).hours_worked :
                                    (x.rest_day === 1 ? this.computeHoursBasedOnType(entry, x, 2).hours_worked : this.computeHoursBasedOnType(entry, x)).hours_worked
                                ): with_holiday_pay_R;
                        });
                    }

                    break;
                case "SNW":
                    hour_types = entry.isRestDay == 1 ? hour_types.filter((x) => x.holiday_type_code == day_type && x.rest_day == 1) : hour_types.filter((x) => x.holiday_type_code == day_type & x.rest_day == 0);
                    // hour_types = hour_types.filter((x) => x.holiday_type_id === 2);
                    if(entry.provincial.length > 0 && findIfApplicableStore){
                        let checkerSNW = getChecker(entry.date);
                        checkerSNW.after_date_record = getRecordOrCheckOtherTime(checkerSNW.after_date, entry.employee_id);
                        checkerSNW.before_date_record = getRecordOrCheckOtherTime(checkerSNW.before_date, entry.employee_id);

                        let { hasAfter: hasAfterSNW, hasBefore: hasBeforeSNW } = hasHolidayOrSunday(checkerSNW.after_date, checkerSNW.before_date);

                        let with_holiday_pay_SNW = setHolidayPay(hasAfterSNW, hasBeforeSNW, checkerSNW);

                        // Monthly Salary Type
                        if (entry.salary_type === 1) {
                            hour_types.forEach((x, i) => {
                                hours_summary[x.name] = entry.time_in && entry.time_out ?
                                (x.isND === 1 ? this.computeHoursBasedOnType(entry, x, 1).hours_worked :
                                    (x.rest_day === 1 ? this.computeHoursBasedOnType(entry, x, 2).hours_worked : this.computeHoursBasedOnType(entry, x).hours_worked )
                                ): with_holiday_pay_SNW;
                            });
                        // Daily Salary Type
                        } else {
                            hour_types.forEach((x, i) => {
                                hours_summary[x.name] = entry.time_in && entry.time_out ?
                                    (x.isND === 1 ? this.computeHoursBasedOnType(entry, x, 1).hours_worked :
                                        (x.rest_day === 1 ? this.computeHoursBasedOnType(entry, x, 2).hours_worked : this.computeHoursBasedOnType(entry, x).hours_worked)
                                    ): 0 ;
                            });
                        }
                    }
                    else{

                        let checkerSNW = getChecker(entry.date);
                        checkerSNW.after_date_record = getRecordOrCheckOtherTime(checkerSNW.after_date, entry.employee_id);
                        checkerSNW.before_date_record = getRecordOrCheckOtherTime(checkerSNW.before_date, entry.employee_id);

                        let { hasAfter: hasAfterSNW, hasBefore: hasBeforeSNW } = hasHolidayOrSunday(checkerSNW.after_date, checkerSNW.before_date);

                        let with_holiday_pay_SNW = setHolidayPay(hasAfterSNW, hasBeforeSNW, checkerSNW);

                        // Monthly Salary Type
                        if (entry.salary_type === 1 && entry.schedule_type !== 3) {
                            hour_types.forEach((x, i) => {
                                hours_summary[x.name] = entry.time_in && entry.time_out ?
                                (x.isND === 1 ? this.computeHoursBasedOnType(entry, x, 1).hours_worked :
                                    (x.rest_day === 1 ? this.computeHoursBasedOnType(entry, x, 2).hours_worked : this.computeHoursBasedOnType(entry, x).hours_worked)
                                ): with_holiday_pay_SNW;
                            });
                        // Daily Salary Type
                        }
                        else if ( entry.schedule_type === 3 ){
                            hour_types.forEach((x, i) => {
                                hours_summary[x.name] =  with_holiday_pay_SNW;
                            });
                        }
                        else {
                            hour_types.forEach((x, i) => {
                                hours_summary[x.name] = entry.time_in && entry.time_out ?
                                    (x.isND === 1 ? this.computeHoursBasedOnType(entry, x, 1).hours_worked :
                                        (x.rest_day === 1 ? this.computeHoursBasedOnType(entry, x, 2).hours_worked : this.computeHoursBasedOnType(entry, x, 0 ).hours_worked)
                                    ): 0 ;
                            });
                        }
                    }

                    break;
                case "SW":
                    hour_types = entry.isRestDay == 1 ? hour_types.filter((x) => x.holiday_type_code == day_type && x.rest_day == 1) : hour_types.filter((x) => x.holiday_type_code == day_type & x.rest_day == 0);
                    if(entry.provincial.length > 0 && findIfApplicableStore){
                        // hour_types = hour_types.filter((x) => x.holiday_type_id == null);

                        hour_types.forEach((x, i) => {
                            hours_summary[x.name] = entry.time_in && entry.time_out ?
                                (x.isND === 1 ? this.computeHoursBasedOnType(entry, x, 1).hours_worked :
                                    (x.rest_day === 1 ? this.computeHoursBasedOnType(entry, x, 2).hours_worked :
                                        this.computeHoursBasedOnType(entry, x).hours_worked))
                                : 0;
                        });
                    }else{
                        // hour_types = hour_types.filter((x) => x.holiday_type_id == null);

                        hour_types.forEach((x, i) => {
                            hours_summary[x.name] = entry.time_in && entry.time_out ?
                                (x.isND === 1 ? this.computeHoursBasedOnType(entry, x, 1).hours_worked :
                                    (x.rest_day === 1 ? this.computeHoursBasedOnType(entry, x, 2).hours_worked :
                                        this.computeHoursBasedOnType(entry, x).hours_worked))
                                : 0;
                        });
                    }

                    break;
                case '':
                    hour_types = entry.isRestDay === 1 ? hour_types.filter((x) => x.holiday_type_code == null && x.rest_day == 1) : hour_types.filter((x) => x.holiday_type_code == null & x.rest_day == 0);
                    hour_types.forEach((x, i) => {
                        hours_summary[x.name] = entry.time_in && entry.time_out ?
                            (x.isND === 1 ? this.computeHoursBasedOnType(entry, x, 1).hours_worked :
                                (x.rest_day === 1 ? this.computeHoursBasedOnType(entry, x, 2).hours_worked : this.computeHoursBasedOnType(entry, x).hours_worked)
                            ): 0;
                        });
                    break;
                default:
                    hour_types = hour_types.filter((x) => x.holiday_type_code === null);

                    hour_types.forEach((x, i) => {
                        hours_summary[x.name] = entry.time_in && entry.time_out ?
                            (x.isND === 1 ? this.computeHoursBasedOnType(entry, x, 1).hours_worked :
                                (x.rest_day === 1 ? this.computeHoursBasedOnType(entry, x, 2).hours_worked : this.computeHoursBasedOnType(entry, x).hours_worked)
                            ): 0;
                    });
                    break;
            }

            let hours_to_work_day = entry.hours_to_work_day - entry.break_duration;
            let day_worked = 0;
            Object.values(hours_summary).forEach((x)=>{
                if(x == 'HP' || x == 'LP'){
                    hours_summary.day_worked = 8
                }else{
                    if(day_worked >= 8){
                        day_worked = 8;
                    }
                    day_worked += x
                    day_worked = day_worked / hours_to_work_day;
                    hours_summary.day_worked = day_worked ? day_worked : 0;
                }
            })
            return hours_summary;
        },
        computeHoursBasedOnType(data, hour_data, criteria = 0, with_leave_w_pay = 0){
            let parameters = {
                time_in: data.time_in,
                time_out: data.time_out,
                start: hour_data.start,
                end: hour_data.end,
                hours_worked: 0,
                schedule_time_out: data.schedule_time_out,
                schedule_time_in: data.schedule_time_in,
                day_worked: 0,
            }

            let timeFormat = 'HH:mm';
            let timeIn = this.$dayjs(parameters.time_in, timeFormat);
            let timeOut = this.$dayjs(parameters.time_out, timeFormat);
            let schedule_time_out = this.$dayjs(parameters.schedule_time_out, timeFormat);
            let schedule_time_in = this.$dayjs(parameters.schedule_time_in, timeFormat);
            let duration = null;
            let lateduration = null;
            let lateinHours = null

            // Variables to be used for checking hours worked
            let total_hours_worked = 0;
            duration = this.$dayjs.duration(timeOut.diff(timeIn));
            total_hours_worked = duration.asMinutes() / 60;
            total_hours_worked = total_hours_worked - data.break_duration;

            let hours_to_work_day = data.hours_to_work_day - data.break_duration;

            let start = this.$dayjs(parameters.start, "HH:mm");
            let end = this.$dayjs(parameters.end, "HH:mm");
            let NDend = this.$dayjs(parameters.end, "HH:mm").add(1, 'day');
            //  Condition for flexible manager
            if((data.schedule_type === 3 || data.schedule_type_id === 3) && hour_data.holiday_type_id == null && hour_data.isND == 0 && hour_data.isOT == 0 && hour_data.rest_day == 0 ){
                if(parameters.time_in){
                    parameters.hours_worked = 8;
                }else{
                    parameters.hours_worked = 0
                }
            }
            else if((data.schedule_type === 3 || data.schedule_type_id === 3) && hour_data.holiday_type_id != null && hour_data.isND == 0 && hour_data.isOT == 0 && hour_data.rest_day == 0 ){
                if(parameters.time_in){
                    parameters.hours_worked = 8;
                }else{
                    parameters.hours_worked = 0
                }
            }
            else if(data.hasExemption == 1 && hour_data.holiday_type_id == null && hour_data.isND == 0 && hour_data.isOT == 0 && hour_data.rest_day == 0 ){
                parameters.hours_worked = 8;
            }
            // Code block for not flexible managers
            else{
                switch(criteria){
                    // Regular Hours
                    case 0:
                        lateduration = this.$dayjs.duration(timeIn.diff(schedule_time_in));
                        lateinHours = lateduration.asMinutes();
                        lateinHours = lateinHours > 4 ? 0 : lateinHours;

                            if(hour_data.isOT == 1){
                                if (total_hours_worked >= 8.5) {
                                    let TimeOutBasis = this.$dayjs.min(timeOut, end);

                                    duration = this.$dayjs.duration(TimeOutBasis.diff(schedule_time_out));
                                    parameters.hours_worked = duration.asMinutes();
                                    // parameters.hours_worked = duration.asHours();
                                    parameters.hours_worked = parameters.hours_worked - lateinHours >= 30 ? parameters.hours_worked - lateinHours : 0 ;
                                    parameters.hours_worked = parameters.hours_worked.toFixed(2);
                                    // parameters.hours_worked = parameters.hours_worked - lateinHours >= 0.5 ? parameters.hours_worked - lateinHours : 0 ;
                                    // parameters.hours_worked =  total_hours_worked - hours_to_work_day - lateinHours ;

                                }else{
                                    parameters.hours_worked = 0;
                                }
                            }else{
                                if(with_leave_w_pay == 1){
                                    parameters.hours_worked = 'LP';
                                }else{
                                    let TimeOutBasis = this.$dayjs.min(schedule_time_out, timeOut);

                                    duration = this.$dayjs.duration(TimeOutBasis.diff(timeIn));
                                    parameters.hours_worked = duration.asHours();
                                    parameters.hours_worked = parameters.hours_worked - data.break_duration;
                                    parameters.hours_worked = parameters.hours_worked > 8 ? 8 : parameters.hours_worked;
                                    if(timeOut.isSameOrAfter(schedule_time_out)){
                                        parameters.hours_worked = parameters.hours_worked - (lateinHours / 60);
                                        parameters.hours_worked = parameters.hours_worked > 8 ? 8 : parameters.hours_worked;
                                        // parameters.day_worked = parameters.hours_worked / hours_to_work_day;
                                        parameters.hours_worked = parameters.hours_worked.toFixed(2);
                                    }
                                }
                            }
                        break;
                    // Night Diff
                    case 1:
                        if(hour_data.isOT == 1){
                            if(total_hours_worked >= 8.5){
                                // if (timeOut.isBetween(start, NDend)) {
                                    let nightTimeIn = this.$dayjs.max(timeIn, start);
                                    let nightTimeOut = this.$dayjs.min(timeOut, NDend);

                                    let nightDiffInMinutes = nightTimeOut.diff(nightTimeIn, 'minute');
                                    let nightDiffInHours = nightDiffInMinutes ;
                                    // let nightDiffInHours = nightDiffInMinutes / 60;

                                    let nightHoursWorked = nightDiffInHours;

                                    parameters.hours_worked = nightHoursWorked >= 30 ? nightHoursWorked : 0;
                                    parameters.hours_worked = parameters.hours_worked.toFixed(2);

                                // }
                            }else{
                                parameters.hours_worked = 0;
                            }
                        }else{
                            if(total_hours_worked <= 8){
                                //  if (schedule_time_out.isBetween(start, NDend)) {
                                    let nightTimeIn = this.$dayjs.max(timeIn, start);
                                    let nightTimeOut = this.$dayjs.min(timeOut, NDend);

                                    let nightDiffInMinutes = nightTimeOut.diff(nightTimeIn, 'minute');
                                    let nightDiffInHours = nightDiffInMinutes / 60;

                                    let nightHoursWorked = nightDiffInHours;

                                    parameters.hours_worked = nightHoursWorked;
                                    parameters.hours_worked = parameters.hours_worked.toFixed(2);
                                // }
                            }else{
                                parameters.hours_worked = 0;
                            }
                        }
                        break;
                    // Rest Day
                    case 2:
                        lateduration = this.$dayjs.duration(timeIn.diff(schedule_time_in));
                        lateinHours = lateduration.asMinutes();
                        lateinHours = lateinHours > 4 ? 0 : lateinHours;

                        // lateinHours = lateduration.asHours();
                            if(hour_data.isOT == 1){
                                if (total_hours_worked >= 8.5) {
                                    let TimeOutBasis = this.$dayjs.min(timeOut, end);

                                    duration = this.$dayjs.duration(TimeOutBasis.diff(schedule_time_out));
                                    parameters.hours_worked = duration.asMinutes();
                                    // parameters.hours_worked = duration.asHours();
                                    parameters.hours_worked = parameters.hours_worked - lateinHours >= 30 ? parameters.hours_worked - (lateinHours / 60) : 0 ;
                                    // parameters.hours_worked = parameters.hours_worked - lateinHours >= 0.5 ? parameters.hours_worked - lateinHours : 0 ;
                                    // parameters.hours_worked =  total_hours_worked - hours_to_work_day - lateinHours ;
                                    parameters.hours_worked = parameters.hours_worked.toFixed(2);
                                }else{
                                    parameters.hours_worked = 0;
                                }
                            }else{
                                if(with_leave_w_pay == 1){
                                    parameters.hours_worked = 'LP';
                                }else{
                                    duration = this.$dayjs.duration(timeOut.diff(timeIn));
                                    parameters.hours_worked = duration.asHours();
                                    parameters.hours_worked = parameters.hours_worked - data.break_duration;
                                    parameters.hours_worked = parameters.hours_worked > 8 ? 8 : parameters.hours_worked;
                                    if(timeOut.isSameOrAfter(schedule_time_out)){
                                        parameters.hours_worked = parameters.hours_worked - (lateinHours / 60);
                                        parameters.hours_worked = parameters.hours_worked > 8 ? 8 : parameters.hours_worked;
                                        // parameters.day_worked = parameters.hours_worked / hours_to_work_day;
                                        parameters.hours_worked = parameters.hours_worked.toFixed(2);
                                    }
                                }
                            }
                        break;
                }
                if(with_leave_w_pay == 0){
                    if(isNaN(parameters.hours_worked) || parameters.hours_worked < 0 ){
                        parameters.hours_worked = 0
                    }
                }
            }
            return parameters;
        },
        async checkOtherTimeRecords(date, employee_id){
            let record = null;
            let payload = {
                employee_id: employee_id,
                date: date
            }
            await this.$store.dispatch('checkTimekeepingRecordDay', payload).then((res)=>{
                if(res){
                    record = res
                }else{
                    record = {
                        time_in: null,
                        time_out: null
                    };
                }
            }).catch((err)=>{
                console.log(err)
                Swal.fire(
                    "Error",
                    "",
                    "error"
                )
            })

            return record
        },
        checkIfHasHolidayBeforeAndAfterOrSunday(after_date, before_date){
            let hasHolidayOrSunday = {
                after: -1,
                before: -1
            }
            let afterDayName = this.$dayjs(after_date).format('dddd');
            let beforeDayName = this.$dayjs(before_date).format('dddd');

            switch(afterDayName){
                case 'Sunday':
                    hasHolidayOrSunday.after = 1
                    break;
                default:
                    hasHolidayOrSunday.after = this.findIndexInArrayObjects(this.GET_HOLIDAYS_SELECTION, 'date', after_date);
                    break;
            }

            switch(beforeDayName){
                case 'Sunday':
                    hasHolidayOrSunday.before = 1
                    break;
                default:
                    hasHolidayOrSunday.before = this.findIndexInArrayObjects(this.GET_HOLIDAYS_SELECTION, 'date', before_date);
                    break;
            }
            return hasHolidayOrSunday
        },
        changeTimekeepingRecordStatus(status){
            if(this.checkIncompleteLogs(1) > 0){
                Swal.fire(
                    "There are incomplete logs!",
                    "Please update timekeeping record for those incomplete logs",
                    "warning"
                )
                this.filters.with_incomplete_time_in_out = 1;
                return false
            }
            else if(this.form.pending_record_count == 0){
                Swal.fire({
                    text: `Are you sure you want to ${status}?`,
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then(async(result) => {
                    if(result.isConfirmed) {
                        let payload = {
                            id: this.GET_TIMEKEEPING_RECORD_VIEW.id,
                            status: status
                        }
                        this.$store.dispatch('changeTimekeepingRecordStatus', payload).then((res)=>{
                            Swal.fire(
                                "Success",
                                "",
                                "success"
                            )
                        }).catch((err)=>{
                            console.log(err)
                            Swal.fire(
                                "Error",
                                "",
                                "error"
                            )
                        })
                    }
                })
            }else{
                Swal.fire(
                    "Please check pending records!",
                    '',
                    'warning'
                )
                this.filters.with_manual = 1;
                return false
            }
        },
        viewRecordFiles(id){
            this.timekeeping_record_id = id;
            this.$store.commit('filesShow');
        },
        async computeTimekeepingRecordSummary(datas, hourClassificationArray){
            datas.forEach((x, i)=>{
                // let absentCount = x.data.filter((x) => x.absent == 'Y').length;
                let daysWorkedCount = x.data.filter((x) => x.day_worked > 0).length
                let timekeepingDetails = hourClassificationArray.filter((e)=> e.employee_id == x.employee_id);
                x.total_days_worked = 0;
                x.total_late_in_minutes = 0;
                x.total_leave_with_pay = 0;

                const startMoment = this.$dayjs(this.GET_TIMEKEEPING_RECORD_VIEW.timekeeping_period_start);
                const endMoment = this.$dayjs(this.GET_TIMEKEEPING_RECORD_VIEW.timekeeping_period_end);

                const daysDifference = endMoment.diff(startMoment, 'day') + 1;

                let rd_count = x.data.filter((x) => x.rest_day == "Y").length;
                // x.total_no_of_days = daysDifference - rd_count;
                x.total_no_of_days = 13;

                x.total_days_worked = daysWorkedCount;
                // x.total_days_worked = x.total_no_of_days - absentCount;
                x.total_absent = x.total_no_of_days - daysWorkedCount;
                // x.total_absent = absentCount;
                let leave_with_pay = x.biometricdata.filter((e) => !_.isEmpty(e.leave));
                if(leave_with_pay){
                    leave_with_pay.forEach(el=>{
                        x.total_leave_with_pay += parseFloat(el.leave.days_with_pay)
                    });
                }
                x.data.forEach((l)=>{
                    if(l.late_in_mins){
                        x.total_late_in_minutes = x.total_late_in_minutes + parseInt(l.late_in_mins)
                    }
                })

                // timekeepingDetails.forEach((r)=>{
                //     if(r.hours_classification.day_worked > 0){
                //         x.total_days_worked = x.total_days_worked + 1;
                //     }else{
                //         x.total_days_worked += r.hours_classification.day_worked;
                //     }
                // })
            })
            return datas
        },
        async generateFileMakerExcel(){
            Swal.fire({
                title: 'Generating FileMaker Excel Please Wait.',
                allowOutsideClick: false,
                showCancelButton: false,
                showConfirmButton: false,
            });
            Swal.showLoading()
            let payload = {
                id: this.form.id
            }
            await this.$store.dispatch('generateFilemakerData', payload).then(async (res)=>{

                let timekeeping_datas = res;

                const workbook = new this.$exceljs.Workbook();
                const rawDataSheetName = `Sheet1`;

                let worksheet = workbook.addWorksheet(rawDataSheetName);
                let headers = [
                    { header: 'USER ID', key: 'real_employee_id' },
                    { header: 'LAST NAME', key: 'last_name' },
                    { header: 'FIRST NAME', key: 'first_name' },
                    { header: 'DEVICE NUMBER', key: 'device_number' },
                    { header: 'DATE', key: 'date_punched', style: { numFmt: 'dd-mmm-yyyy' } },
                    { header: 'TIME', key: 'time' },
                    { header: 'STATUS', key: 'status' },
                    { header: 'DEVICE NAME', key: 'device_name' },
                ];

                worksheet.autoFilter = {
                    from: {
                        row: 1,
                        column: 1
                    },
                    to: {
                        row: 1,
                        column: headers.length
                    }
                };
                worksheet.columns = headers
                // worksheet.columns = headers.map(header => ({
                //     header: header.header,
                //     key: header.key,
                //     width: Math.max(15, header.header.length * 1.2), // Adjust minimum width if needed
                //     style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } },
                // }));

                worksheet.columns.forEach(column => {
                    column.width = Math.max(15, column.header.length * 1.2); // Adjust minimum width if needed
                });


                worksheet.getRow(1).font = { bold: true };
                worksheet.getRow(1).height = 20;

                const headerRow = worksheet.getRow(1);
                headerRow.eachCell((cell) => {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'ffc000' }
                    };
                });

                let borderStyles = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" }
                };
                worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
                    row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
                        cell.border = borderStyles;
                    });
                });


                for await (let timekeeping_data of timekeeping_datas) {
                    timekeeping_data.date_punched = this.$dayjs(timekeeping_data.date_punched).format('DD-MMM-YYYY');
                    worksheet.addRow(timekeeping_data);
                }

                workbook.xlsx.writeBuffer().then((data) => {
                    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheetcharset=utf-8' })
                    const filename = `dtr-sample.xlsx`
                    this.$filesaver.saveAs(blob, filename)
                    Swal.close()
                })
            })
        },
        checkStatusForGenerate(){
            if(this.GET_TIMEKEEPING_RECORD_VIEW.printed == 1){
                this.override.override_dialog = true
            }else{
                this.generateFileMakerExcel()
            }
        },
        closeConfirmOverride(user_id, authenticated = false) {
            if (authenticated) {
                this.generateFileMakerExcel();
            }

            this.override.override_dialog = false;
        },
        filterTable(for_filter = 0){
            let filteredBiometricData = this.datatable.items.map((e) => {
                return {
                    ...e,
                    biometricdata: e.biometricdata.filter((x) => x.manual == 1),
                };
            });
            // Filter this.datatable.items as well
            filteredBiometricData = filteredBiometricData.filter((e) => {
                return e.biometricdata.length > 0; // Filter out elements with no valid biometric data
            });
            if(for_filter == 1){
                return filteredBiometricData
            }
            this.datatable.items = filteredBiometricData;
        },
        itemRowBackground(item){
            if(this.filters.with_manual == 1){
                return 'highlight_manual'
            }
        },
        checkIncompleteLogs(for_validation = 0){
            let filteredBiometricData = this.datatable.items.map((e) => {
                return {
                    ...e,
                    biometricdata: e.biometricdata.filter((x) => ( ((!x.time_out || !x.time_in)) && x.isRestDay == 0 && x.with_leave == 0 && !x.isHoliday && x.absent == 0)),
                };
            });
            // Filter this.datatable.items as well
            filteredBiometricData = filteredBiometricData.filter((e) => {
                if(e.schedule_type_id !== this.flexiManagerScheduleID){
                    return e.biometricdata.length > 0; // Filter out elements with no valid biometric data
                }
            });
            if(for_validation == 1){
                return filteredBiometricData.length;
            }
            this.datatable.items = filteredBiometricData;
        },
        setManagerFlexiID(){
            this.flexiManagerScheduleID = this.GET_EMPLOYEE_SCHEDULE_TYPE_SELECTION.find((x) => x.code == 'FMG').value;
        },
        checkIfWithNoTimeOut(item, parentItem){
            if(parentItem.schedule_type_id !== this.flexiManagerScheduleID && ((!item.time_out || !item.time_in) && !item.leave)  && item.isRestDay == 0 && item.isHoliday == 0){
                return true
            }
            return false
        },
        removeEmployee(item_id, i, employee_id){
            Swal.fire({
                title: "Delete",
                text: `Are you sure you want to remove employee ?`,
                icon: 'question',
                showConfirmButton:true,
                confirmButtonText:'Confirm',
                showCancelButton:true,
                showCloseButton:true,
                confirmButtonColor: '397373',
                cancelButtonColor:'grey',
                reverseButtons:true,
            }).then((action)=>{
                if(action.isConfirmed){
                    this.datatable.items.splice(i,1);
                    this.form.record_trash_ids.push({
                        record_detail_id: item_id,
                        employee_id: employee_id,
                    });
                }
            })
        },
        checkLeaveData(item, w_pay){
            if(_.isEmpty(item.leave)){
                return false
            }else{
                if(w_pay == 1){
                    return item.leave.days_with_pay > 0 && item.isRestDay == 0 ? true : false
                }else{
                    return item.leave.days_with_pay == 0 ? true : false
                }
            }
        },
        checkLeaveRecordType(record){
            if(record.leave.days_with_pay > 0){
                switch(record.leave.leave_type_name){
                    case 'Half Day':
                        return 'Leave HD Paid'
                    case 'Undertime':
                        return 'Leave UT Paid'
                    case 'Maternity Leave':
                        return 'Maternity Leave'
                    case 'Solo Parent Leave':
                        return 'Solo Parent Leave'
                    default:
                        return 'Leave with Pay'
                }
            }else{
                return 'Leave w/o Pay'
            }
        }
    },
    watch:{
        GET_BIOMETRICS_DATA:{
            handler(val){
                this.datatable.items = val
            },
            deep: true
        },
        "datatable.items":{
            deep: true,
            handler(val){
            }
        },
        DIALOGS:{
            handler(val){
                if(!val){
                    this.resetFields();
                    this.filters.with_manual = 0;
                    this.filters.with_incomplete_time_in_out = 0;
                }
            }
        },
        USER_ACCESS:{
            handler(val){
                this.userAccess = val.map(e=>e.actions_code);
            }
        },
        "filters.with_manual":{
            handler(val){
                if(val){
                    this.filterTable();
                }else if (val == 0){
                    this.datatable.items = this.GET_TIMEKEEPING_RECORD_VIEW.timekeeping_record_details;
                    // console.log(this.datatable.items)
                }
            }
        },
        "filters.with_incomplete_time_in_out":{
            handler(val){
                if(val){
                    this.checkIncompleteLogs(0);
                }else if (val == 0){
                    this.datatable.items = this.GET_TIMEKEEPING_RECORD_VIEW.timekeeping_record_details;
                    // console.log(this.datatable.items)
                }
            }
        },
    }
}
</script>
<style>
.show_with_manual{
    background-color: #1198b9;
}
.highlight_manual{
    background-color: #a9dcb5;
}
.deleted_item{
    background-color: #fda1a1;
}
.with_no_time_out{
    background-color: #6c99fa;
}
.rest_day_schedule{
    background-color: #28ffe2;
}
.no_record{
    background-color: #c990ff;
}
.leave_w_pay{
    background-color: #eead8e;
}
.leave_wo_pay{
    background-color: #ff92d5;
}
</style>
