<template>
  <v-dialog v-model="addBaklasDialog" persistent max-width="98%" scrollable>
      <v-card>
          <v-row class="m-0">
            <v-card-title>
                <span class="headline">Add Baklas Item</span>
            </v-card-title>
            <v-col cols="pull-right-10 p-2">
                <v-btn text icon color="gray" class="float-right" @click="closeAddBaklasDialog()">
                    <v-icon>mdi-close-circle</v-icon>
                </v-btn>
            </v-col>
        </v-row>

        <v-divider class="mt-0" />

        <div>
            <v-card>
                <v-data-table
                    :headers="spHeaders"
                    :items="spLists"
                >

                </v-data-table>
            </v-card>
        </div>
      </v-card>
  </v-dialog>
</template>

<script>


export default {
    data() {
        return {
            spHeaders: [
                { text: 'Item Model', value: 'item_model' },
                { text: 'Serial Number', value: 'serial_number' },
                { text: 'Quantity', value: 'item_quantity' },
            ],
            spLists: []
        }
    },
    components: {

    },
    props: ['itemId'],
    methods: {

    }
}
</script>

<style>

</style>
