<template>
  <v-dialog v-model="rrDetailsDialog" persistent max-width="98%" scrollable>
      <v-card class="pb-4">
        <v-row class="m-0">
            <v-col>
                <v-card-title>
                    <span class="headline">Receiving Detail</span>
                </v-card-title>
            </v-col>
            <v-col cols="pull-right-10 p-2">
                <v-btn text icon color="gray" class="float-right" @click="closeRrDetailsDialog()">
                    <v-icon>mdi-close-circle</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-divider class="mt-0" />
        <v-container fluid style="background-color: white; overflow-y: scroll">
            <v-col>
                 <v-card-text>
                    <div class="ma-4">
                        <v-layout>
                            <v-flex lg4>
                                <div>
                                    <span style="font-weight:bold">RD#: </span>
                                    <span>{{ rrNumber }}</span>
                                </div>
                                <div>
                                    <span style="font-weight:bold">Company Name: </span>
                                    <span>{{ companyName }}</span>
                                </div>
                                <div>
                                    <span style="font-weight:bold">Customer Name: </span>
                                    <span>{{ customerName }}</span>
                                </div>
                                <div>
                                    <span style="font-weight:bold">Address: </span>
                                    <span>{{ address }}</span>
                                </div>
                                <div>
                                    <span style="font-weight:bold">Email: </span>
                                    <span>{{ email }}</span>
                                </div>
                            </v-flex>
                            <v-flex lg4>
                                <div>
                                    <span style="font-weight:bold">Date: </span>
                                    <span>{{ rrDateCreated }}</span>
                                </div>
                                <div>
                                    <span style="font-weight:bold">Company Number: </span>
                                    <span>{{ companyContactNumber }}</span>
                                </div>
                                <div>
                                    <span style="font-weight:bold">Customer Number: </span>
                                    <span>{{ contactNumber }}</span>
                                </div>
                            </v-flex>
                            <v-flex lg4>
                                <div>
                                    <span style="font-weight:bold">Pull Out No: </span>
                                    <span>{{ pulloutNumber }}</span>
                                </div>
                                <div>
                                    <span style="font-weight:bold">Location: </span>
                                    <span>{{ locationName }}</span>
                                </div>
                                <div>
                                    <span style="font-weight:bold">Delivered by: </span>
                                    <span>{{ deliveredBy }}</span>
                                </div>
                                <div>
                                    <span style="font-weight:bold">Received by: </span>
                                    <span>{{ receivedBy }}</span>
                                </div>
                            </v-flex>
                        </v-layout>
                        <v-layout class="mt-2">
                            <v-card style="width:100%">
                                <v-data-table
                                    :headers="rfTableHeaders"
                                    :items="rfLists"
                                    :loading="loading"
                                    :no-data-text="noDataText"
                                    :items-per-page="12"
                                >

                                </v-data-table>
                            </v-card>
                        </v-layout>
                    </div>
                </v-card-text>
            </v-col>
        </v-container>
        <v-divider class="mt-0" />
        <div class="mx-4">
            <v-btn @click="printRrDetails()">Print</v-btn>
            <v-dialog v-model="showOverridePrint" persistent max-width="30%" scrollable>
                <v-card>
                    <v-row class="m-0">
                        <v-col>
                            <v-card-title>
                                <span class="headline">Confirm Override Print</span>
                            </v-card-title>
                        </v-col>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right" @click="closeOverrideDialog(); override_username = ''; override_user_password = '';">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card-text>
                        <div class="row">
                            <!-- <div class="col-sm-12 col-md-6">
                                <label for="override_username" class="float-left control-label col-form-label">Username</label>
                                <input type="text" class="form-control" id="override_username" placeholder="Username" v-model="override_username" autocomplete="new-password">
                                <span v-for="errors in errors.override_username" class="text-warning" v-bind:key="errors">{{errors}}</span>
                            </div> -->
                            <div class="col-sm-12 col-md-12">
                                <label for="override_user_password" class="float-left control-label col-form-label">Password</label>
                                <input type="password" class="form-control" id="override_user_password" placeholder="Password" v-model="overrideUserPassword" autocomplete="new-password">
                                <!-- <span v-for="errors in errors.override_user_password" class="text-warning" v-bind:key="errors">{{errors}}</span> -->
                            </div>
                        </div>
                        <div class="border-top">
                            <div class="card-body py-4 d-flex justify-content-center">
                                <v-btn  v-on:click="confirmedOverridePrint()">Confirm</v-btn>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
      </v-card>
       <!-- For Printing Details -->
      <PrintReceivingReportComponent />
  </v-dialog>
</template>

<script>
import PrintReceivingReportComponent from './Views/PrintReceivingReportComponent.vue';
import _ from 'lodash';
import swal from 'sweetalert2';
import { mapGetters } from 'vuex';
export default {
    components:{
        PrintReceivingReportComponent,
    },
    data() {
        return {
            rrDetailsDialog: false,
            rrDetails: null,
            rfTableHeaders: [
                { text: 'RF#', value: 'rf_number' },
                { text: 'Item Name', value: 'sc_repair_form_item.model' },
                { text: 'Item Model', value: 'sc_repair_form_item.product_name'  },
                { text: 'Serial No', value: 'sc_repair_form_item.serial' },
                { text: 'Included Items', value: 'sc_repair_form_item.accessories' },
                { text: 'Physical Condition', value: 'sc_repair_form_item.unit_condition' },
                { text: 'Complaint/Symptom', value: 'sc_repair_form_item.defect_description' },
            ],
            rfLists: [],
            loading: true,
            noDataText: '',
            rrDateCreated: '',
            rrNumber: '',
            pulloutNumber: '',
            companyName: '',
            companyContactNumber: '',
            customerName: '',
            customerContactNumber: '',
            address: '',
            contactNumber: '',
            email: '',
            customerId: '',
            itemModel: '',
            itemProductName: '',
            itemSerial: '',
            itemDefectDescription: '',
            rfListsCount: 0,
            deliveredBy: '',
            receivedBy: '',
            pulloutNumber: '',
            warehouse: '',
            showOverridePrint: false,
            overrideUserPassword: '',
            confirmOverride: false,
            warehouseName: '',
            locationName: ''
        }
    },
    computed:{
        ...mapGetters([
            'GET_RR_DETAILS',
            'GET_CONFIRM_OVERRIDE_PRINT_PL',
        ])
    },
    props: ['showRrDetailsDialog', 'rrId'],

    watch: {
        showRrDetailsDialog() {
            if(this.showRrDetailsDialog) {
                this.rrDetailsDialog = this.showRrDetailsDialog;
            }
        },
        rrId() {
            if(!!this.rrId) {
                this.getRrDetails(this.rrId);
            }
        },
        rrDetails() {
            if(!!this.rrDetails) {
                this.rrNumber = this.rrDetails.rr_number;
                if(!!this.rrDetails.pullout_number) {
                    this.pulloutNumber = this.rrDetails.pullout_number;
                }
                this.rfLists = _.compact(this.rrDetails.sc_repair_forms);
                this.rrDateCreated = this.$dayjs(this.rrDetails.created_at).format('YYYY-MM-DD, hh:mm:ss a');
                this.customerId = this.rrDetails.customer_id;
                this.companyName = this.rrDetails.customer.company_name;

                if(this.rfLists.length > 0) {
                    this.rfListsCount = _.compact(this.rfLists).length;
                } else {
                    this.rfListsCount = this.rfLists.length;
                }
                this.deliveredBy = this.rrDetails.delivered_by;
                this.receivedBy = this.rrDetails.received_by;
                this.warehouseName = this.rrDetails.warehouse_name;
                let contact = [];
                let fullName = [];

                if(!!this.rrDetails.sc_location) {
                    this.locationName = this.rrDetails.sc_location.name;
                }

                if(this.customerId == 275) {
                    let sc_customer = this.rrDetails.sc_customer;
                    if(!!sc_customer.first_name) {
                         fullName.push(sc_customer.first_name);
                    }
                    if(!!sc_customer.middle_name) {
                         fullName.push(sc_customer.middle_name);
                    }
                    if(!!sc_customer.last_name) {
                         fullName.push(sc_customer.last_name);
                    }
                    this.customerName = fullName.toString().replaceAll(',', ' ');
                    this.address = this.rrDetails.sc_customer.address;
                    this.contactNumber = this.rrDetails.sc_customer.contact_number;
                    this.email = this.rrDetails.sc_customer.email;
                } else {
                    let customer = this.rrDetails.customer;
                    if(!!customer.first_name) {
                         fullName.push(customer.first_name);
                    }

                    if(!!customer.middle_name) {
                         fullName.push(customer.middle_name);
                    }

                    if(!!customer.last_name) {
                         fullName.push(customer.last_name);
                    }
                    // this.customerName = fullName.toString().replaceAll(',', ' ');
                    this.address = JSON.parse(customer.shipping_addresses)[0].address;
                     if(!!customer.mobile_1) {
                         contact.push(customer.mobile_1);
                     }
                     if(!!customer.mobile_2) {
                         contact.push(customer.mobile_2);
                     }
                     if(!!customer.mobile_3) {
                         contact.push(customer.mobile_3);
                     }
                     if(!!customer.tel_1) {
                         contact.push(customer.tel_1);
                     }
                     if(!!customer.tel_2) {
                         contact.push(customer.tel_2);
                     }
                     if(!!customer.tel_3) {
                         contact.push(customer.tel_3);
                     }
                    this.companyContactNumber = contact.toString().replaceAll(',', ' / ');
                }
            }
        }
    },
    methods: {
        clearRfDetails() {
            this.purchaseDate = "";
            this.rfLists = [];
            this.rrNumber = "";
            this.rrDateCreated = "";
            this.companyName = "";
            this.companyContactNumber = "";
            this.customerName = "";
            this.customerContactNumber = "";
            this.address = "";
            this.contactNumber = "";
            this.email = "";
            this.customerId = "";
            this.pulloutNumber = "";
        },
        closeRrDetailsDialog() {
            this.rrDetailsDialog = false;
            this.clearRfDetails();
            this.$emit('closeRrDialog');
        },
        getRrDetails(id) {
            this.loading = true
            this.$store.dispatch('getRrDetails',id).then(response => {
                this.rrDetails = this.GET_RR_DETAILS;
                this.loading = false
            });
        },
        printRrDetails() {
            if(!!this.rrDetails) {
                if(this.rrDetails.printed) {
                    if(this.confirmOverride ==  false) {
                        this.confirmedOverridePrint();
                    } else {
                        // window.open(doc.output('bloburl'), '_blank')
                        this.$store.commit('PRINT_RR_ID', this.rrDetails.id);
                        this.confirmOverride = false;
                    }
                } else {
                     swal.fire({
                        title: 'Are you sure you want to print?',
                        text: 'It will require override after successfull print',
                        icon: 'question',
                        showConfirmButton:true,
                        showCancelButton  :  true,
                        reverseButtons:true,
                        allowOutsideClick :  false,
                        confirmButtonColor: '#397373',
                        cancelButtonColor : 'grey',
                        confirmButtonText:'Confirm',
                        showCloseButton:true,
                    }).then((result)=> {
                        if(result.isConfirmed) {
                            this.$store.commit('PRINT_RR_ID', this.rrDetails.id);
                            let payload = {
                                id: this.rrDetails.id,
                                params:{}
                            }
                            this.$store.dispatch('successRrPrint',payload)
                            this.closeRrDetailsDialog();
                            this.getRrDetails(this.rrId);
                            this.confirmOverride = false;
                            this.$emit('successPrint', confirm);
                        }
                    });
                }
            }
        },
        confirmedOverridePrint() {
            this.showOverridePrint = true;
            if(!!this.overrideUserPassword) {
                let payload = {
                    departments_allowed: ['SR', 'IT' , 'EX'],
                    override_user_password: this.overrideUserPassword
                }
                this.$store.dispatch('confirmOverridePrintPL',payload).then((response)=> {
                    if(this.GET_CONFIRM_OVERRIDE_PRINT_PL.error) {
                        swal.fire("", this.GET_CONFIRM_OVERRIDE_PRINT_PL.error, "warning").then(()=>{this.confirmOverride = false;})
                        return false;
                    }

                    this.confirmOverride = true;
                    this.showOverridePrint = false;
                    this.overrideUserPassword = "";
                    this.printRrDetails();
                });
            }
        },
        closeOverrideDialog() {
            this.showOverridePrint = false;
        }
    }
}
</script>

<style>

</style>
