<script>
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
import { mapGetters } from 'vuex';
export default {
    mixins: [SharedFunctionsComponentVue],
    data() {
        return {
            data: Math.random(),

            paperWidth: 215.9,
            paperHeight: 279.4,
            margin: 10,

            headerStartY: undefined,
            headerEndY: undefined,
            headerWidth: undefined,
            headerHeight: undefined,

            footerStartY: undefined,
            footerEndY: undefined,
            footerWidth: undefined,
            footerHeight: undefined,

            base64Image: null,
        };
    },
    computed:{
        ...mapGetters(['PURCHASE_ORDER']),

    },
    methods: {
        drawAccountabilityTurnoverFormPrintHeader(doc, headerData) {
            // const host = window.location.origin;

            const imageWidth = 36;
            const imageHeight = 10;
            // doc.addImage(
            //     `${host}/images/ingcoph-logo.png`,
            //     'PNG',
            //     (this.margin + (this.paperWidth - (this.margin * 2)) / 4) - (imageWidth / 2),
            //     this.margin,
            //     imageWidth,
            //     imageHeight,
            // );

            try {
                let aspectRatio = this.getAspectRatio(this.base64Image.naturalWidth, this.base64Image.naturalHeight);
                let image_width = 0;
                let image_height = 0;

                switch(aspectRatio){
                    case '1:1':
                        image_width = 40;
                        image_height = 40;
                        break;
                    case '4:3':
                        image_width = 60;
                        image_height = 40;
                        break;
                    case '3:2':
                        image_width = 50;
                        image_height = 40;
                        break;
                    case '16:9':
                        image_width = 80;
                        image_height = 40;
                        break;
                    default:
                        image_width = 80;
                        image_height = 15;
                }

                doc.addImage(this.printData.image_path, 'PNG', 15, this.margin - 5, image_width, image_height);
            } catch (error){
                console.log(error)
                console.error('image is not found for print.');
            }

            const titleContainerWidth = 80;
            const titleContainerHeight = 8;
            const titleContainerX = (this.paperWidth / 2) + (((this.paperWidth / 2) - 5) / 2) - (titleContainerWidth / 2);
            const titleContainerY = this.margin;
            doc.setDrawColor('#000000');
            doc.setFillColor('#e5e7eb');
            doc.roundedRect(
                titleContainerX,
                titleContainerY,
                titleContainerWidth,
                titleContainerHeight,
                2,
                2,
                'FD',
            );

            const title = 'ACCOUNTABILITY TURNOVER FORM';
            const titleFontSize = 10;
            const titleX = titleContainerX;
            const titleY = titleContainerY;
            doc.setFont(
                'Helvetica',
                'bold',
            );
            doc.setFontSize(titleFontSize);
            doc.text(
                title,
                titleX + 8,
                titleY + 5.5,
            );

            doc.autoTable({
                startY: this.margin + titleContainerHeight + 5,
                margin: {
                    bottom: this.margin,
                    top: this.margin,
                    left: this.margin,
                    right: this.margin,
                },
                theme: 'plain',
                columnStyles: {
                    0 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 16},
                    1 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 16},
                    2 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 16},
                    3 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 16},
                    4 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 16},
                    5 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 16},
                    6 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 16},
                    7 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 16},
                    8 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 16},
                    9 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 16},
                    10 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 16},
                    11 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 16},
                    12 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 16},
                    13 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 16},
                    14 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 16},
                    15 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 16},
                },
                bodyStyles: {
                    lineColor: [0, 0, 0],
                    textColor: [0, 0, 0],
                    fontSize: 8,
                },
                body: [
                    [
                        {content: `PERSON ACCOUNTABLE: ${headerData.person_accountable ?? ''}`, colSpan: 8},
                        {content: `LOCATION: ${headerData.location ?? ''}`, colSpan: 8},
                    ],
                    [
                        {content: `DEPARTMENT: ${headerData.department ?? ''}`, colSpan: 8},
                        {content: `DATE: ${this.formattedDate(new Date(headerData.date ?? ''))}`, colSpan: 8},
                    ],
                    [
                        {content: `POSITION: ${headerData.position ?? ''}`, colSpan: 8},
                        {content: `PURPOSE: ${headerData.purpose ?? ''}`, colSpan: 8},
                    ],
                    [
                        {content: 'OBJECTIVES:', colSpan: 2, styles: {fontStyle: 'bold'}},
                        {content: 'Certify status of assets accounted to employees and serve as condition endorsement. This will be prerequisite in completion of exit clearance.', colSpan: 14},
                    ],
                    [
                        {content: 'GUIDELINES:', colSpan: 2, styles: {fontStyle: 'bold'}},
                        {content: 'To be executed by accountable employee prior intended turnover to asset. Indicate fixed asset condition and put a remarks if there is unnecessary precentible to the aforementioned.', colSpan: 14},
                    ],
                    [
                        {content: 'Fixed Asset Condition', colSpan: 8, styles: {fontStyle: 'bolditalic', textColor: 'red'}},
                        {content: '', colSpan: 8},
                    ],
                    [
                        {content: `USED A`, colSpan: 2, styles: {halign: 'center', fontStyle: 'bold', textColor: 'red'}},
                        {content: `Refers to assets with no issue and are in pristine condition.`, colSpan: 14},
                    ],
                    [
                        {content: `USED B`, colSpan: 2, styles: {halign: 'center', fontStyle: 'bold', textColor: 'red'}},
                        {content: `Refers to assets with minor dents that is negligible and can still function. Usually from reasonable wear and tear.`, colSpan: 14},
                    ],
                    // [
                    //     {content: `USED C`, colSpan: 2, styles: {halign: 'center', fontStyle: 'bold', textColor: 'red'}},
                    //     {content: `Refers to assets that are idle and needs to undergo repair.`, colSpan: 14},
                    // ],
                ],
                didDrawCell: (data) => {
                    if (data.row.index <= 2) {
                        doc.rect(
                            data.cell.x,
                            data.cell.y,
                            data.cell.width,
                            data.cell.height,
                        );
                    } else {
                        if (data.column.index == 0) {
                            doc.line(data.cell.x, data.cell.y, data.cell.x, data.cell.y + data.cell.height);
                        } else {
                            doc.line(data.cell.x + data.cell.width, data.cell.y, data.cell.x + data.cell.width, data.cell.y + data.cell.height);
                        }
                    }
                },
            });

            this.headerStartY = this.margin;
            this.headerEndY = doc.lastAutoTable.finalY;
            this.headerWidth = this.paperWidth - (this.margin * 2);
            this.headerHeight = this.headerEndY - this.headerStartY;

            return this.headerEndY;
        },

        drawAccountabilityTurnoverFormPrintFooter(doc, footerData) {
            const footerHeight = 36;

            doc.autoTable({
                startY: this.paperHeight - (this.margin + footerHeight),
                margin: {
                    bottom: this.margin,
                    top: this.margin,
                    left: this.margin,
                    right: this.margin,
                },
                theme: 'grid',
                columnStyles: {
                    0 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 4},
                    1 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 4},
                    2 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 4},
                    3 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 4},
                },
                bodyStyles: {
                    minCellHeight: 13,
                    lineColor: [0, 0, 0],
                    textColor: [0, 0, 0],
                    fontSize: 8,
                },
                body: [
                    [
                        {
                            content: '*Write down the result of Investigation, Report, Recommendation, & Discussion (HR, IT Officer & ADMIN)',
                            colSpan: 4,
                            styles: {minCellHeight: 23},
                        },
                    ],
                    [
                        'Requestor',
                        'Endorsed by',
                        'Noted by',
                        'Approved by',
                    ],
                ],
                didDrawCell: (data) => {
                    const keys = Object.keys(footerData);

                    doc.autoTable({
                        startY: data.cell.y,
                        margin: {
                            top: data.cell.y,
                            bottom: this.paperHeight - (data.cell.y + data.cell.height),
                            left: data.cell.x,
                            right: this.paperWidth - (data.cell.x + data.cell.width),
                        },
                        theme: 'plain',
                        bodyStyles: {
                            minCellHeight: 13,
                            valign: 'end',
                            halign: 'center',
                            lineColor: [0, 0, 0],
                            textColor: [0, 0, 0],
                            fontSize: 8,
                        },
                        body: [
                            [{
                                content: `\n${
                                    data.row.index === 0
                                    ? footerData[keys[0]] ?? ''
                                    : footerData[keys[1 + (1 * data.column.index)]] ?? ''
                                }`,
                            }],
                        ],
                    });
                },
            });

            this.footerStartY = doc.previousAutoTable.settings.startY;
            this.footerEndY = doc.previousAutoTable.finalY;
            this.footerWidth = this.paperWidth - (this.margin * 2);
            this.footerHeight = footerHeight;

            doc.setFont("Gill", "italic");
            doc.setFontSize(7);
            doc.text(
                'ADM-020-2023',
                this.margin,
                doc.previousAutoTable.finalY + 3,
            );

            return this.footerEndY;
        },

        drawAccountabilityTurnoverFormPrintContent(doc, contentData) {
            doc.autoTable({
                startY: this.headerEndY,
                margin: {
                    top: this.headerEndY,
                    bottom: this.footerHeight + this.margin,
                    left: this.margin,
                    right: this.margin,
                },
                theme: 'grid',
                columnStyles: {
                    0: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    1: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    2: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    3: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    4: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    5: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    6: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    7: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    8: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    9: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    10: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    11: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                },
                headStyles: {
                    halign: 'center',
                    lineColor: [0, 0, 0],
                    fillColor: [229, 231, 235],
                    textColor: [0, 0, 0],
                    fontStyle: 'italic',
                    fontSize: 8,
                },
                head: [
                    [
                        {content: 'AS #', colSpan: 1},
                        {content: 'ASSET TAGGING', colSpan: 2},
                        {content: 'ITEM PARTICULAR', colSpan: 2},
                        {content: 'CONDITION', colSpan: 2},
                        {content: 'NAME OF TRANSFEREE', colSpan: 3},
                        {content: 'REMARKS', colSpan: 2},
                    ],
                ],
                bodyStyles: {
                    halign: 'center',
                    lineColor: [0, 0, 0],
                    textColor: [0, 0, 0],
                    fontSize: 8,
                },
                body: [
                    ...contentData.map(item => [
                        {content: item.asset_number ?? '', colSpan: 1},
                        {content: item.asset_code ?? '', colSpan: 2},
                        {content: item.item_particular ?? '', colSpan: 2},
                        {content: item.condition ?? '', colSpan: 2},
                        {content: item.transferee ?? '', colSpan: 3},
                        {content: item.remarks ?? '', colSpan: 2},
                    ]),
                ],
                didDrawCell: (data) => {
                    if (data.section === 'head') {
                        doc.rect(
                            data.cell.x,
                            data.cell.y,
                            data.cell.width,
                            data.cell.height,
                        );
                    }
                },
                didDrawPage: (data) => {
                    if (doc.getNumberOfPages() > 1) {
                        this.drawAccountabilityTurnoverFormPrintHeader(doc);
                        this.drawAccountabilityTurnoverFormPrintFooter(doc, this.footerData);
                    }
                },
            });
        },

        printAccountabilityTurnoverForm(eb) {
            const doc = new this.$jspdf({
                orientation: 'portrait',
                unit: 'mm',
                format: 'letter',
            });

            this.printData = eb.header;
            this.base64Image = this.PURCHASE_ORDER.COMPANY_IMAGE;
            this.drawAccountabilityTurnoverFormPrintHeader(doc, eb.header);
            this.drawAccountabilityTurnoverFormPrintFooter(doc, eb.footer);
            this.drawAccountabilityTurnoverFormPrintContent(doc, eb.content);

            doc.autoPrint();
            window.open(doc.output("bloburl"), "_blank");
        },
    },
    watch: {
        'data': {
            handler(val) {
                // this.printAccountabilityTurnoverForm({
                //     header: {},
                //     footer: {},
                //     content: [],
                // });
            },
            immediate: true,
        }
    },
}
</script>