<template>
  <div class="tabs-body-color">
    <v-container fluid class="pa-0">
      <v-row class="pt-4 pl-2" v-if="userAccess.create">
        <v-col cols="6" class="d-flex no-block align-items-center">
          <div class="btn-toolbar pl-0">
            <v-btn class="ml-1 mb-2" @click="AddDialog()"> RRF </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <RepairRequestFormTableComponent
          v-if="GET_RRF_DISPATCH_STATUS.tab_name == isActive_tab"
        ></RepairRequestFormTableComponent>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import RepairRequestFormTableComponent from "@/views/main/modules/Admin/RepairRequestForm/RepairRequestFormTableComponent.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    RepairRequestFormTableComponent,
  },
  props: ["isActive_tab"],
  data() {
    return {
      userAccess: {
        create: false,
      },
    };
  },
  computed: {
    ...mapGetters(["USER_ACCESS", "GET_RRF_DISPATCH_STATUS"]),
  },
  mounted() {},
  methods: {
    AddDialog() {
      this.$store.dispatch('getAdminSupplierSelection');
      this.$store.commit("ADMIN_ITEMS_LIST_SECTION", true);
      this.$store.commit("DIALOG", true);
      this.$store.commit("ACTION", "Submit");
    },
  },
  watch: {
    USER_ACCESS: {
      handler(val) {
        if (val != "fail") {
          this.userAccess.create = false;
          val.forEach((e) => {
            if (e.actions_code == "create") {
              this.userAccess.create = true;
            }
          });
        }
      },
    },
  },
};
</script>
