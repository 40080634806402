<template>
    <v-dialog v-model="techRfEstDialog" persistent max-width="98%">
        <v-card class="pb-4">
            <v-row class="m-0">
                <v-col>
                    <v-card-title>
                        <span class="headline">Repair Form Details</span>
                    </v-card-title>
                </v-col>
                <v-col cols="pull-right-10 p-2">
                    <v-btn text icon color="gray" class="float-right" @click="closeTechRfEstDialog()">
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class="mt-0" />
            <div class="ma-4">
                <v-layout row class="px-6">
                    <v-flex lg4>
                        <div>RF#: <span>{{ rfNumber }}</span></div>
                        <div>Item Model: <span>{{ itemModel }}</span></div>
                        <div>Item Name: <span>{{ itemProductName }}</span></div>
                        <div>Serial: <span>{{ itemSerialNumber }}</span></div>
                    </v-flex>
                    <v-flex lg4>
                        <div>Physical Condition: <span>{{ itemUnitCondition }}</span></div>
                        <div>Other Physical Condition: <span>{{ itemOtherUnitCondition }}</span></div>
                        <div>Complaint / Symptom: <span>{{ itemDefectDescription }}</span></div>
                        <div>Remarks: <span>{{ itemDefectRemarks }}</span></div>
                        <div>QA Remarks: <span>{{ itemQaRemarks }}</span></div>
                    </v-flex>
                    <v-flex lg4>
                        <div>Warranty Type: <span>{{ itemWarrantyType }}</span></div>
                        <div>Status: <span>{{ rfStatus }}</span></div>
                        <div>Purchase Date: <span>{{ itemPurchaseDate }}</span></div>
                        <div><a :class="`text-checking`" @click="showRepairStatus()">Repair Status: <span><v-chip :color="repairStatusColor()" text-color="white" small>{{ itemRepairStatus }}</v-chip></span></a></div>
                    </v-flex>
                </v-layout>
                <!-- v-if="!forReturnSp && !forReplacement"  -->
                <v-layout row class="px-6">
                    <v-flex lg12>
                        <div v-if="rfStatus == 'PIN' && !forReturnSp && !forReplacement" class="d-flex flex-row" >
                            <v-tooltip bottom color="#f69f1a">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-badge
                                            :content="GET_APPROVE_ORDERS.item_rejected_counter"
                                            color="red"
                                            offset-x="15"
                                            offset-y="15"
                                            bordered
                                            overlap
                                            :loading="loading"
                                            :value="GET_APPROVE_ORDERS.item_rejected_counter ? true : false"
                                        >
                                        <v-btn
                                            @click="showRejectedItemDialog()"
                                            style="opacity:1;"
                                            v-bind="attrs"
                                            v-on="on"
                                            text
                                            icon
                                            outlined
                                            :loading="loading"
                                            color="red"
                                        ><v-icon>mdi-close-thick</v-icon>
                                        </v-btn>
                                    </v-badge>
                                </template>
                                <span>Rejected Item Check List</span>
                            </v-tooltip>
                            <v-tooltip bottom color="#f69f1a">
                                <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            :loading="loading"
                                            style="opacity:1;"
                                            class="ml-4"
                                            color="#f69f1a"
                                            v-bind="attrs"
                                            v-on="on"
                                            outlined
                                            text
                                            icon
                                            @click="showUploadWarranty()"
                                        >
                                            <v-icon>mdi-attachment</v-icon>
                                        </v-btn>
                                </template>
                                <span>Attach File</span>
                            </v-tooltip>
                            <v-flex class="ml-4 mb-4">
                                <BaseFileViewer :rfId="rf?.id" :delete="true" :rfNumber="rfNumber" :buttonColor="`#f69f1a`" :buttonLoading="loading"/>
                            </v-flex>
                        </div>
                    </v-flex>
                    <v-flex lg12>
                        <span v-if="(rfStatus == 'PIN') && (forReturnSp || forReplacement)" style="text-align:end" >
                            <v-tooltip bottom color="#f69f1a">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        @click="openReasons()"
                                        fab
                                        text
                                        small
                                        class="mr-4"
                                        color="blue"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon>mdi-text-box-outline</v-icon>
                                    </v-btn>

                                </template>
                                <span>Reason</span>
                            </v-tooltip>
                        </span>
                    </v-flex>
                </v-layout>
            </div>
            <v-layout row class="mx-4">
                <v-flex>
                    <v-card class="pa-4">
                        <v-layout row class="pa-4">
                            <v-flex lg6>
                                <div v-if="rfStatus != 'REP'" class="pb-2">Requested <span v-if="itemRepairStatus == 'For-Replacement'">Item</span><span v-else>Spare Parts</span></div>
                                <div v-if="rfStatus != 'REP' && rfStatus != 'RET'" class="pb-2">Requested Date: {{ rfPartsOrderDate }}</div>
                                <div v-if="showRetItems()" class="pb-2">Cancelled Date: {{ rfCancelledDate }}</div>

                                <div v-if="rfStatus == 'REP'" class="pb-2">Completed Date: {{ rfCompletedDate }}</div>

                            </v-flex>
                            <v-flex lg6>
                                <v-layout row class="d-flex flex-row justify-space-between pr-2">
                                    <v-flex lg4>
                                        <!-- <v-btn v-if="rfStatus != 'REP'" @click="refreshPartsOrder()">Refresh</v-btn> -->
                                        <v-tooltip v-if="rfStatus != 'REP'" bottom color="#f69f1a">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    @click="refreshPartsOrder()"
                                                    fab
                                                    text
                                                    small
                                                    class="mr-4"
                                                    color="blue"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    <v-icon>mdi-sync</v-icon>
                                                </v-btn>

                                            </template>
                                            <span>Refresh</span>
                                        </v-tooltip>
                                    </v-flex>

                                    <v-flex lg2 v-if="rfStatus == 'PIN'" class="d-flex flex-row justify-space-betweeen justify-end">
                                        <div v-if="showCreateEstimationButton"  class="mr-2">
                                              <v-tooltip bottom color="#f69f1a">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        :loading="loading"
                                                        color="#f69f1a"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        small
                                                        text
                                                        fab
                                                        @click="createNewEstimation"
                                                    >
                                                        <v-icon>
                                                            mdi-open-in-new
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>New Estimation</span>
                                            </v-tooltip>
                                        </div>
                                        <div v-if="rfStatus != 'REP'" class="mr-2">
                                            <v-tooltip  bottom color="#f69f1a">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        color="#f69f1a"
                                                        :loading="loading"
                                                        :disabled="forReplacement || forChangeDefectiveSp"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        small
                                                        text
                                                        fab
                                                        @click="() => forReturnSp = !forReturnSp"
                                                    >
                                                        <v-icon>mdi-book-arrow-left</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Return SP</span>
                                            </v-tooltip>
                                        </div>
                                        <div v-if="rfStatus != 'REP'" class="mr-2">
                                            <v-tooltip bottom color="#f69f1a">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        color="#f69f1a"
                                                        :loading="loading"
                                                        :disabled="forReturnSp || forChangeDefectiveSp"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        small
                                                        text
                                                        fab
                                                        @click="() => forReplacement = !forReplacement"
                                                    >
                                                        <v-icon>mdi-file-replace</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Replace SP</span>
                                            </v-tooltip>
                                        </div>
                                        <!-- <div class="mr-4">
                                            <v-tooltip bottom color="#f69f1a">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        color="#f69f1a"
                                                        :loading="loading"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        small
                                                        text
                                                        fab
                                                        @click="showCreateNewEstimation()"
                                                    >
                                                        <v-icon>mdi-file-plus</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Create New Estimation</span>
                                            </v-tooltip>
                                        </div> -->
                                        <div class="mr-2">
                                            <v-tooltip bottom color="#f69f1a">
                                                <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                            :disabled="forReplacement || forReturnSp"
                                                            :loading="loading"
                                                            color="#f69f1a"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            small
                                                            text
                                                            fab
                                                            @click="() => forChangeDefectiveSp = !forChangeDefectiveSp"
                                                         >
                                                            <v-icon>
                                                                mdi-file-arrow-left-right
                                                            </v-icon>
                                                        </v-btn>
                                                </template>
                                                <span>Change Damaged Parts</span>
                                            </v-tooltip>
                                        </div>
                                        <div class="mr-2">
                                            <v-tooltip bottom color="#f69f1a">
                                                <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                            :loading="loading"
                                                            color="#f69f1a"
                                                            class="pt-4"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            small
                                                            text
                                                            icon
                                                            @click="showRequestNewSp"
                                                         >
                                                            <v-icon style="font-size:28px">
                                                                mdi-truck-plus
                                                            </v-icon>
                                                        </v-btn>
                                                </template>
                                                <span>Request New Spare Part</span>
                                              </v-tooltip>
                                        </div>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>

                        <v-data-table
                            v-if="!loading"
                            :headers="headers()"
                            :items="rfPartsOrderItems"
                            :items-per-page="rfPartsOrderItems.length"
                            :no-data-text="noDataText"
                            v-model="selectedForReturn"
                            :item-class="itemRowBackground"
                        >
                            <!-- <template v-slot:header.data-table-select="{ header }">
                                <v-simple-checkbox style="display:none"></v-simple-checkbox>
                            </template>

                            <template v-slot:item.data-table-select="{ item, isSelected, select }">
                                <v-simple-checkbox
                                    ref="checkBox"
                                    :value="isSelected"
                                    :readonly="false"
                                    :disabled="false"
                                    @input="select($event)"
                                ></v-simple-checkbox>
                            </template> -->

                            <template v-if="rfStatus == 'RET'" v-slot:item="{ item }">
                                <tr v-if="item.status == 'for-return' || item.status == 'for-confirmation'" style="pointer-events: none;">
                                    <td v-text="item.serve_qty"></td>
                                    <td v-text="item.return_qty"></td>
                                    <td v-text="item.model"></td>
                                    <td v-text="item.name"></td>
                                    <td v-text="item.category"></td>
                                    <td class="px-0">
                                        <v-chip
                                            :color="requestedStatusColor(item)"
                                            text-color="white"
                                            >
                                            {{ item.status }}
                                        </v-chip>
                                    </td>
                                </tr>
                            </template>

                            <template v-slot:item.return_qty="{ item }">
                                <span v-if="rfStatus != 'RET'">
                                    <v-checkbox
                                        hide-details
                                        dense
                                        :disabled="item.serve_qty == 0"
                                        v-model="item.checked"
                                        style="margin-top:0 !important; padding-top:0 !important;display:inline-block"
                                        @change="enableReturn(item)"
                                        />
                                        <span class="text-nowrap">
                                        <input
                                        type="number"
                                        :min="0"
                                        :max="item.serve_qty"
                                        v-model="item.return_qty"
                                        dense
                                        outlined
                                        style="
                                                text-align:right;
                                                cursor:pointer;
                                                margin:1px 0px 1px !important;
                                                height: 1.5rem !important;
                                                padding:0 5px !important;
                                                border: 1px solid black;
                                                border-radius: 6px;
                                                display:inline-block
                                            "
                                            :readonly="!item.checked"
                                            @blur="checkReturnQty(item)"
                                            @focus="$event.target.select()"
                                            />
                                        </span>
                                    </span>
                                <span v-else>
                                    {{ item.return_qty }}
                                </span>
                            </template>

                            <template v-slot:item.status="{ item }">
                                <td class="px-0">
                                    <v-chip
                                    :color="requestedStatusColor(item)"
                                    text-color="white"
                                    >
                                    {{ item.status }}
                                </v-chip>
                                </td>
                            </template>

                            <template v-if="forReplacement" v-slot:item.model="{ item }">
                                <v-autocomplete
                                    dense
                                    outlined
                                    @input="searchByPartNum(item)"
                                    :items="item.list"
                                    v-model="item.item_id"
                                    style="width: 240px;"
                                    :placeholder="item.model"
                                    auto-select-first
                                    >
                                </v-autocomplete>
                            </template>

                            <!-- <template v-if="forReplacement" v-slot:item.name="{ item }">
                                <span >
                                    {{ item.list.find(e=>e.item_id == item.item_id).name }}
                                </span>
                            </template> -->

                            <template v-if="rfStatus != 'REP'" v-slot:item.action="{ item }">
                                <!-- <v-btn v-if="item.status == 'for-confirmation' && rfStatus != 'RET'" @click="itemReceived(item)">Confirm</v-btn> -->
                            </template>
                        </v-data-table>
                        <v-row v-if="loading">
                            <v-col lg="12" class="d-flex justify-content-center align-items-center">
                                <LoadingComponentVue class="my-2"></LoadingComponentVue>
                            </v-col>
                        </v-row>
                    </v-card>

                    <!-- <v-divider />

                    <v-card>
                        <v-data-table
                            :headers="headers()"
                            :items="rfEstimationItems"
                            :items-per-page="rfEstimationItems.length"
                            :loading="loading"
                            :no-data-text="noDataText"
                            v-model="selectedForReturn"
                        >
                        </v-data-table>
                    </v-card> -->
<!--
                    <v-divider />
                    <v-dialog v-model="reasonDialog" persistent max-width="50%">
                        <v-card class="pa-2">
                            <v-row class="m-0">
                                <v-col>
                                    <v-card-title>
                                        <span class="headline">Reason for {{ this.forReturnSp == true ? 'Return' : 'Replace'}}</span>
                                    </v-card-title>
                                </v-col>
                                <v-col cols="pull-right-10 p-2">
                                    <v-btn
                                        text
                                        icon
                                        color="gray"
                                        class="float-right"
                                        @click="closeReasons()"
                                    >
                                    <v-icon>mdi-close-circle</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-divider />
                            <v-textarea v-model="reasonSpareParts" dense outlined></v-textarea>

                            <div style="text-align:center">
                                <v-tooltip bottom color="#f69f1a">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            @click="submitReasons()"
                                            fab
                                            text
                                            small
                                            class="mr-4"
                                            color="blue"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon>mdi-plus-box-outline</v-icon>
                                        </v-btn>

                                    </template>
                                    <span>Submit Reason</span>
                                </v-tooltip>
                            </div>
                        </v-card>
                    </v-dialog> -->

                    <v-layout v-if="rfStatus != 'REP'" row>
                        <v-flex lg12>
                            <div style="text-align:center" class="py-4">
                                <!-- <v-btn v-if="rfStatus == 'PIN' && !!forReturnSp" @click="returnSelectedSp()" :loading="returnSpOnFlight">Return Selected Spare Parts</v-btn> -->
                                <!-- <v-btn v-else-if="rfStatus == 'PIN'" :disabled="showRepairDone" @click="showJobDoneDialog()">Repair Done</v-btn> -->
                                <div v-if="readyForQa == 1">
                                    <v-tooltip v-if="rfStatus == 'PIN' && !forReturnSp && !forReplacement && !forChangeDefectiveSp" color="#f69f1a" top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span
                                            class="mx-2"
                                            dark
                                            v-bind="attrs"
                                            v-on="on">
                                              <v-btn
                                                  :disabled="checkReturnDamagePart()"
                                                  :loading="loading"
                                                  color="blue"
                                                  class="mr-4"
                                                  icon
                                                  text
                                                  fab
                                                  @click="showQaRemarks()"
                                              >
                                                    <v-icon style="transform: rotateY(180deg);">mdi-text-box-multiple-outline</v-icon>
                                                </v-btn>
                                            </span>
                                        </template>
                                        <span>QA Tester</span>
                                    </v-tooltip>
                                </div>

                                <div v-if="readyForQa == 0">
                                    <v-tooltip v-if="rfStatus == 'PIN' && !forReturnSp && !forReplacement && !forChangeDefectiveSp" color="#f69f1a" top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span
                                            class="mx-2"
                                            dark
                                            v-bind="attrs"
                                            v-on="on">
                                                <v-btn
                                                    :disabled="checkReturnDamagePart()"
                                                    :loading="loading"
                                                    color="blue"
                                                    class="mr-4"
                                                    icon
                                                    text
                                                    fab
                                                    @click="repairedDateDialog = true"
                                                >
                                                    <v-icon>mdi-check-circle-outline</v-icon>
                                                </v-btn>
                                            </span>
                                        </template>
                                        <span>Ready For QA</span>
                                    </v-tooltip>
                                </div>

                                <v-tooltip v-if="rfStatus == 'PIN' && !!forReplacement && !forChangeDefectiveSp" color="#f69f1a" top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span
                                        class="mx-2"
                                        dark
                                        v-bind="attrs"
                                        v-on="on">
                                            <v-btn
                                                @click="replaceSelectedSp()"
                                                fab
                                                text
                                                small
                                                class="mr-4"
                                                color="red"
                                                icon
                                            >
                                                <v-icon style="font-size:40px; transform: rotateY(180deg);">mdi-truck-delivery</v-icon>
                                            </v-btn>
                                        </span>
                                    </template>
                                    <span>Replace Parts</span>
                                </v-tooltip>
                                <!-- <v-btn v-if="rfStatus == 'PIN' && !!forReturnSp" @click="returnSelectedSp()" :loading="returnSpOnFlight"></v-btn> -->
                                <v-tooltip color="#f69f1a" top >
                                    <template v-slot:activator="{ on, attrs }">
                                        <span
                                        class="mx-2"
                                        dark
                                        v-bind="attrs"
                                        v-on="on">
                                            <v-btn v-if="rfStatus == 'PIN' && !!forReturnSp" :loading="returnSpOnFlight" @click="returnSelectedSp()" text icon color="red">
                                                <v-icon style="font-size:40px; transform: rotateY(180deg);">mdi-truck-delivery</v-icon>
                                            </v-btn>
                                        </span>
                                    </template>
                                    <span>Return Parts</span>
                                </v-tooltip>
                                <v-tooltip v-if="rfStatus == 'PIN' && !!forChangeDefectiveSp" color="#f69f1a" top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span
                                            v-bind="attrs"
                                            v-on="on"
                                            :loading="changePartLoading"
                                            class="mx-2"
                                            dark
                                        >
                                           <v-btn color="#f69f1a" icon small text @click="returnDefectiveSp()">
                                                <v-icon style="font-size:25px; transform: rotateY(180deg)" small>mdi-receipt-text-edit-outline</v-icon>
                                            </v-btn>
                                        </span>
                                    </template>
                                    <span>Request Parts</span>
                                </v-tooltip>
                                <!-- <v-tooltip v-if="rfStatus == 'PIN'" top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            :disabled="showRepairDone"
                                            @click="showQaRemarks()"
                                            fab
                                            text
                                            small
                                            class="mr-4"
                                            color="blue"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon>mdi-text-box-multiple-outline</v-icon>
                                        </v-btn>

                                    </template>
                                    <span>QA Tester</span>
                                </v-tooltip> -->
                                    <!-- <v-btn v-else-if="rfStatus == 'PIN'" :disabled="showRepairDone" @click="showJobDoneDialog()">Repair Done</v-btn> -->
                                    <!-- <v-btn" @click="returnSpareParts()">Return Spare Parts</v-btn> -->
                                    <!-- <v-tooltip top v-if="showRetItems()">
                                    </template>
                                    <span>Return Parts</span>
                                </v-tooltip> -->
                                    <!-- </template>
                                    <span>Return Parts</span>
                                </v-tooltip> -->
                                <!-- <v-btn v-else-if="rfStatus == 'PIN'" :disabled="showRepairDone" @click="showJobDoneDialog()">Repair Done</v-btn> -->
                                <!-- <v-btn" @click="returnSpareParts()">Return Spare Parts</v-btn> -->
                                <div v-if="showRetItems()">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                                <span
                                                class="mx-2"
                                                dark
                                                v-bind="attrs"
                                                v-on="on">
                                                    <v-btn :loading="returnPartsOnFlight || loading" @click="returnSpareParts()" text icon color="success">
                                                        <v-icon style="font-size:40px" >
                                                            mdi-truck
                                                        </v-icon>
                                                    </v-btn>
                                                </span>
                                        </template>
                                        <span>Return Parts</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                                <span
                                                class="mx-2"
                                                dark
                                                v-bind="attrs"
                                                v-on="on">
                                                    <v-btn
                                                        :loading="loading"
                                                        color="success"
                                                        text
                                                        icon
                                                        @click="revertRfStatus('PIN')"
                                                    >
                                                        <v-icon style="font-size:40px" >
                                                            mdi-arrow-left-circle
                                                        </v-icon>
                                                    </v-btn>
                                                </span>
                                        </template>
                                        <span>Revert To PIN</span>
                                    </v-tooltip>
                                </div>
                                <v-btn v-if="rfStatus == 'WFP' && !rfReEstimate && !repairStarted || rfStatus == 'WFR' && !rfReEstimate && !repairStarted" :disabled="showStartRepair" @click="startRepair()">Start Repair</v-btn>
                                <v-btn v-else-if="rfStatus == 'WFP' || rfStatus == 'WFR'" :disabled="showStartRepair" @click="continueRepair()">Continue Repair</v-btn>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
        </v-card>

        <RequestNewSparePartsDialogVue
            :rfPartsOrderId="rfPartsOrderId"
            :itemWarrantyType="itemWarrantyType"
            :itemToSearch="itemToSearch"
            :rfPartsOrderItems="rfPartsOrderItems"
            @successRequestNewSp="successRequestNewSp"
            @closeRequestNewSp="closeRequestNewSp"
            :rf="rf"
        />
        <!-- New Added -->
        <!-- <CreateEstimationDialog
            :repairFormDetails="repairFormDetails"
            :rfEstimations="repairFormEstimations"
            :isOpenDialog="showCreateNewEstimationDialog"
            @successAddNewEstimation = "successAddNewEstimation"
            @isCloseDialog="closeCreateNewEstimation"
        /> -->

        <!-- For Printing Details -->
        <v-container fluid style="display:none">
            <div id="div_return_sp_logo" >
                <div style="font-size: 12px; line-height: 1.9">
                    <p>
                        <span style="font-size: 18px; font-weight: bold;">
                            INGCOPH Traders Inc.
                        </span>
                        <br>2 D Arellano St. Bagong Barrio, Caloocan City
                        <br>Cell No.:
                        <br>Tel. No.:
                        <br>Service Mobile / Viber No.: +63 917 625 2286
                        <br>Spare Parts Mobile / Viber No.:
                    </p>
                </div>
            </div>
            <div id="div_return_sp_num" style="font-size: 12px; line-height: 1.9">
                <div style="border-top: 1px dashed #CFCFCF; padding: 2px; padding-bottom: -4px; text-align: center; border-bottom: none;">&nbsp;</div>
                <p>
                    <span style="font-size: 18px; font-weight: bold;">Spare Parts Return Form</span>
                    <br>
                    <b>Rf#: {{ rfNumber }}</b>
                    <br>
                    <b>Issued: {{ spForReturnPrintIssued }}</b>
                </p>
            </div>

            <div>
                <table id="return_sp_item_details">
                    <tbody>
                        <tr>
                            <td>Return Qty</td>
                            <td>Item Model</td>
                            <td>Item Name</td>
                            <td>Category</td>
                            <td>Date/Signature</td>
                        </tr>
                        <tr v-for="sp in selectedForReturn" :key="sp.id">
                            <td>{{ sp.return_qty }}</td>
                            <td>{{ sp.model }}</td>
                            <td>{{ sp.name }}</td>
                            <td>{{ sp.category }}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colspan="10">Total: {{ selectedForReturn.length }}</td>
                        </tr>
                    </tbody>
                </table>
                <table id="return_sp_process_details">
                    <tbody>
                        <tr>
                            <td colspan="4">Received by: </td>
                            <td colspan="4">Processed by: {{ processedReturnBy }}</td>
                        </tr>
                    </tbody>
                </table>
                <table id="reason-pdf">
                    <thead>
                        <tr>
                            <td colspan="8">Reason: </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="8">{{ displayReasonSpareParts }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </v-container>

        <!-- Job Done Dialog -->
        <v-dialog v-model="jobDoneDialog" persistent max-width="40%">
            <v-card class="pa-4">
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Job Done</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                            text
                            icon
                            color="gray"
                            class="float-right"
                            @click="closeJobDoneDialog()"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider />

                <div v-if="focSpareParts.length > 0">
                    <div>Upload Spare Parts Images for FOC:</div>
                    <div>
                        <v-data-table
                            :headers="focItemHeaders"
                            :items="focSpareParts"
                        >
                            <template v-slot:item.action="{ item }">
                                <td>
                                    <v-btn @click="showUploadDialog(item)">Upload</v-btn>
                                </td>
                            </template>
                        </v-data-table>
                    </div>
                </div>

                <div>Remarks: <span style="color:red">*</span></div>
                <v-textarea v-model="jobDoneRemarks" dense outlined></v-textarea>

                <v-divider />

                <div style="text-align:center">
                    <v-btn @click="repairDone()">Submit</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="qaRemarksDialog" persistent max-width="30%">
            <v-card class="pa-4" height="700">
                <v-row class="m-0">
                    <v-col class="d-flex justify-space-between">
                        <v-card-title class="pr-0 pt-0 pl-3 pb-0">
                            <span class="headline"> QA Approve Remarks </span>
                        </v-card-title>

                        <v-btn
                            text
                            icon
                            color="gray"
                            class="float-right"
                            @click="closeQaRemarksDialog()"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-card class="w-100" height="420" style="overflow-y: scroll;">
                    <v-card-text>
                        <v-row class="m-0">
                            <v-col class="d-flex justify-space-between" sm="12">
                                    <span style="font-size: 140%">Check Lists</span>
                                    <v-checkbox
                                        v-model="checklist.all"
                                        true-value="1"
                                        false-value="0"
                                        label="All"
                                    ></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row class="mx-0 p-0">
                            <v-col v-for="(checklist, index) in GET_SPECIFIC_CHECK_LISTS_SELECTION" :key="index" lg="12" >
                                <span class="d-flex align-items-center">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span v-bind="attrs" v-on="on">
                                                <v-checkbox
                                                    v-model="checklist.selected_checklist"
                                                    true-value="1"
                                                    false-value="0"
                                                    :label="checklist.name"
                                                    >
                                                </v-checkbox>
                                            </span>
                                        </template>
                                        <span>{{ checklist.code }}</span>
                                    </v-tooltip>
                                </span>
                            </v-col>

                        </v-row>
                    </v-card-text>

                </v-card>
                <v-divider/>
                <v-textarea v-model="qaRemarks" height="100" label="Remarks" dense outlined></v-textarea>

                <v-divider />
                <v-row>
                    <v-col class="d-flex justify-center">
                        <v-card-actions>
                            <div style="text-align:center">
                                <v-btn @click="submit()">Submit</v-btn>
                            </div>
                        </v-card-actions>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>


        <v-dialog v-model="uploadDialog" persistent max-width="40%">
            <v-card class="pb-4">
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Upload Spare Parts</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeUploadDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider class="mt-0" />

                <v-layout row class="mx-0 px-4">
                    <v-flex lg6>
                        <div>Model: {{ itemSpModel }}</div>
                        <div>Name: {{ itemSpName }}</div>
                        <div>Name: {{ itemSpCategory }}</div>
                    </v-flex>
                    <v-flex lg6>
                        <div style="text-align:end">
                            <div style="display:inline-block" class="pr-2">
                                Choose File / Image:
                            </div>
                            <div style="display:inline-block">
                                <FileUploadComponentVue
                                    ref="fileUpload"
                                    @uploadedData="uploadedData"
                                    @reset="clearFileUpload"
                                />
                            </div>
                        </div>
                    </v-flex>
                </v-layout>

                <div class="pa-4">
                    <v-data-table
                        :headers="filesHeader"
                        :items="files"
                    >
                        <template v-slot:item.action="{ item }">
                            <td class="px-0">
                                <span class="text-nonwrap">
                                    <v-btn text icon color="orange">
                                        <v-icon class="btn-action" small @click="showFile(item)">mdi-eye</v-icon>
                                    </v-btn>
                                    <v-btn text icon color="blue">
                                        <v-icon class="btn-action" small @click="downloadFile(item)">mdi-download</v-icon>
                                    </v-btn>
                                    <v-btn text icon color="red">
                                        <v-icon class="btn-action" small @click="deleteFile(item)">mdi-delete</v-icon>
                                    </v-btn>
                                </span>
                            </td>
                        </template>
                    </v-data-table>
                </div>

                <v-dialog v-model="viewFile" persistent max-width="80%">
                    <v-card>
                        <v-row class="m-0">
                            <v-col>
                                <v-card-title>
                                    <span class="headline">{{ itemName }}</span>
                                </v-card-title>
                            </v-col>
                            <v-col cols="pull-right-10 p-2">
                                <v-btn text icon color="gray" class="float-right" @click="closeViewFile()">
                                    <v-icon>mdi-close-circle</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>

                        <v-divider class="my-0" />

                        <div>
                            <pinch-scroll-zoom
                                ref="zoomer"
                                :width="300"
                                :height="400"
                                :scale="scale"
                                style="width:100%;height:600px;overflow:auto;"
                            >
                                <img :src="itemPath" />
                            </pinch-scroll-zoom>
                        </div>
                    </v-card>
                </v-dialog>

                <v-divider class="mt-0" />

                <div style="text-align:center">
                    <v-btn @click="uploadImage()" :disabled="!uploadedFiles">Submit</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <!-- Rejected Counter Dialog -->
        <v-dialog v-model="rejectedCounterDialog" persistent max-width="40%">
            <v-card class="pa-4">
                <v-row>
                    <v-col>
                        <v-card-title>
                            <span class="headline"> Rejected Item Check List </span>
                        </v-card-title>
                    </v-col>

                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                            text
                            icon
                            color="gray"
                            class="float-right"
                            @click="closeRejectedCounterDialog()"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-card-text>
                            <v-simple-table height="300px">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Code</th>
                                            <th>Description</th>
                                            <th>Rejected Count</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr
                                            v-for="(check_list, index) in GET_CHECK_LISTS_SELECTION"
                                            :key="index"
                                        >
                                            <td>
                                                <span>
                                                {{ check_list.name }}
                                                </span>
                                            </td>

                                            <td>
                                                <span>
                                                {{ check_list.code }}
                                                </span>
                                            </td>

                                            <td>
                                                <span>
                                                {{ check_list.description }}
                                                </span>
                                            </td>

                                            <td>
                                                <span>
                                                {{ check_list.rejected_counter }}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>

        <v-dialog v-model="reasonDialog" persistent max-width="50%">
            <v-card class="pa-2">
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Reason for {{ this.forReturnSp == true ? 'Return' : 'Replace'}}</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                            text
                            icon
                            color="gray"
                            class="float-right"
                            @click="closeReasons()"
                        >
                        <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider />
                <v-textarea v-model="reasonSpareParts" dense outlined></v-textarea>

                <div style="text-align:center">
                    <v-tooltip bottom color="#f69f1a">
                        <template v-slot:activator="{ on, attrs }">
                            <!-- @click="refreshPartsOrder()" -->
                            <v-btn
                                @click="submitReasons()"
                                fab
                                text
                                small
                                class="mr-4"
                                color="blue"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon>mdi-plus-box-outline</v-icon>
                            </v-btn>

                        </template>
                        <span>Submit Reason</span>
                    </v-tooltip>
                </div>
            </v-card>
        </v-dialog>
        <!--For Uploading File-->
        <v-dialog v-model="uploadWarranty" persistent max-width="20%">
            <v-card class="pa-4">
                <v-btn
                text
                icon
                color="gray"
                class="float-right"
                @click="closeUploadWarranty()"
                >
                <v-icon>mdi-close-circle</v-icon>
                </v-btn>
                <div class="py-2">
                    <!-- <v-checkbox v-model="attach" label="Attach Documents" class="ma-0 pa-0 pt-2" /> -->
                    <span>Attach Documents via Camera or Upload</span><span v-if="(this.images == null && this.attachedFiles == null) && ((this.itemWarrantyType == 'Dealer-Stock' || this.itemWarrantyType == 'In-Warranty') && (this.itemRepairStatus == 'For-Repair' || this.itemRepairStatus == 'For-Replacement'))" style="color:red">  *</span>
                    <v-divider/>
                </div>

                <div class="py-2 mt-2">
                    <v-layout row class="mx-0">
                        <v-flex lg2 class="mr-2">
                            <BaseCameraCaptureVue ref="cameraCapture" @capturedImages="getImages" />
                        </v-flex>
                        <div class="mt-2">
                            <v-chip
                                v-if="!!images && images.length > 0"
                                color="#f69f1a"
                                outlined
                                close
                                @click:close="clearCameraCapture()"
                            >
                                <span class="mr-2">Images Count: {{ images.length }}</span>
                            </v-chip>
                        </div>
                    </v-layout>
                </div>

                <div class="py-2 mt-3">
                    <v-layout row class="mx-0">
                        <v-flex :class="{'mr-2': true, 'lg3': !!attachedFiles && attachedFiles.attachments.length > 1, 'lg6': !!attachedFiles && attachedFiles.attachments.length == 1}">
                            <FileUploadComponentVue ref="fileUpload" @uploadedData="attachedFile" @reset="clearFileAttached"/>
                        </v-flex>
                        <v-flex v-if="!!attachedFiles && attachedFiles.attachments.length > 0" >
                            <div class="mt-2">
                                <v-chip
                                    v-if="!!attachedFiles && attachedFiles.attachments.length > 0"
                                    color="#f69f1a"
                                    outlined
                                    close
                                    @click:close="clearFileAttached()"
                                >
                                    <span class="mr-2">Upload Count: {{ attachedFiles.attachments.length }}</span>
                                </v-chip>
                            </div>
                        </v-flex>
                    </v-layout>
                </div>
                <div class="py-2 mt-4">
                    <v-card-actions class="d-flex justify-content-end">
                        <v-btn
                            color="#f69f1a"
                            style="opacity:1;"
                            @click="submitWarranty()"
                        >
                            <v-icon dense>mdi-content-save-all</v-icon>
                            <span class="ml-2">Submit</span>
                        </v-btn>
                    </v-card-actions>
                </div>
                <!-- <div class="ma-4">
                            <v-layout class="mx-0">
                                <v-flex>
                                    <div style="text-align:center">
                                        <v-btn :disabled="showPrintEstimation()" :loading="submitOnFlight" @click="submit()">Submit</v-btn>
                                    </div>
                                </v-flex>
                                <v-flex lg6>
                                    <div class="pt-2" style="text-align:center">
                                        <v-btn :loading="submitOnFlight" @click="submit">Submit</v-btn>
                                    </div>
                                </v-flex>
                            </v-layout>
                        </div> -->
            </v-card>
        </v-dialog>
        <!-- FOR PRINTING DETAILS -->
        <v-container fluid style="display:none">
                <HeaderPdf :header="forPrintingDataHeader"></HeaderPdf>
                <BodyPdf :tableContents="forPrintingDataBody" :remarks="remarks"></BodyPdf>
                <FooterPdf :footers="forPrintingDataFooter"></FooterPdf>
        </v-container>
        <v-dialog v-model="repairStatusInfoDialog" persistent max-width="20%">
            <v-card>
                <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                <span class="headline">Edit Repair Status</span>
                    <v-row class="m-0">
                        <v-col cols="pull-right-10 p-2">
                            <v-btn
                                text
                                icon
                                small
                                color="gray"
                                class="float-right"
                                @click="closeRepairStatus()"
                            >
                            <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-row class="m-0 pt-1">
                        <v-col cols="12">
                            <v-autocomplete
                                v-model="editRepairStatus"
                                :items="editRepairStatusList"
                                auto-select-first
                                outlined
                                dense
                                label="Repair Status"
                            />
                        </v-col>
                    </v-row>
                    <v-row class="text-right">
                        <v-col cols="12">
                            <v-btn
                                @click="updateRepairStatus"
                            >
                            Submit
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="repairedDateDialog" persistent max-width="22%">
            <v-card>
                <v-row class="m-0">
                    <v-col cols="11" class="p-2">
                        <span class="headline">RF's Repaired Date</span>
                    </v-col>
                    <v-col cols="1" class=" pl-2">
                        <v-btn
                            text
                            icon
                            color="gray"
                            class="float-right"
                            @click="closeRepairedDateDialog()"
                            >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider class="mt-0" />
                <v-card-text>
                    <v-row class="mx-2 my-4">
                        <v-col cols="12">
                            <v-menu
                                transition="scale-transition"
                                :close-on-content-click="false"
                                v-model="datePickerMenu"
                                :nudge-right="40"
                                min-width="auto"
                                offset-y
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        prepend-icon="mdi-calendar"
                                        v-model="repairedDate"
                                        label="Repaired Date"
                                        v-bind="attrs"
                                        readonly
                                        v-on="on"
                                        outlined
                                        dense
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="repairedDate"
                                    @input="datePickerMenu = false"
                                    :max="dateToday()"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row class="text-right">
                        <v-col cols="12">
                            <v-btn
                                @click="confirmRepairedDate"
                            >
                            Confirm
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="repairedDateReasonDialog" persistent max-width="25%">
            <v-card class="pa-2">
                <v-row class="m-0">
                    <v-col cols="11">
                        <v-card-title>
                            <span class="headline">Reason for Repaired Date Override</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="1" class="pull-right-10 p-2">
                        <v-btn
                            text
                            icon
                            color="gray"
                            class="float-right"
                            @click="repairedDateReasonDialog = false; reasonForRepairedDate = ''"
                        >
                        <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider />
                <v-textarea v-model="reasonForRepairedDate" label="Reason for Repaired Date Override" placeholder="Enter your reason here..." dense outlined></v-textarea>

                <div style="text-align:center">
                    <v-tooltip bottom color="#f69f1a">
                        <template v-slot:activator="{ on, attrs }">
                            <!-- @click="refreshPartsOrder()" -->
                            <v-btn
                                @click="submitRepairedDateReason()"
                                fab
                                text
                                small
                                class="mr-4"
                                color="blue"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon>mdi-plus-box-outline</v-icon>
                            </v-btn>

                        </template>
                        <span>Submit Reason</span>
                    </v-tooltip>
                </div>
            </v-card>
        </v-dialog>
        <!-- <v-dialog  v-model="requestPartsDialog" persistent max-width="45%">
            <v-card class="pa-2">
                <v-row class="m-0">
                    <v-col cols="11">
                        <v-card-title>
                            <span class="headline">Request Parts</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="1" class="pull-right-10 p-2">
                        <v-btn
                            text
                            icon
                            color="gray"
                            class="float-right"
                            @click="requestPartsDialog = false"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider/>
                <v-row>
                    <v-col>
                        <v-tooltip bottom color="#f69f1a">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    @click="downloadRequestPartsTemplate()"
                                    fab
                                    text
                                    small
                                    class="mr-4"
                                    color="#f69f1a"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon>mdi-arrow-down</v-icon>
                                </v-btn>

                            </template>
                            <span>Request Parts Template</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog> -->
        <ConfirmOverrideComponentVue
            :approve_override_dialog="overrideRepairedDate.override_dialog"
            :departments_allowed="overrideRepairedDate.override_departments_allowed"
            :heading="'Repaired Date'"
            @closeConfirmOverride="closeConfirmOverrideRepairedDate"
        ></ConfirmOverrideComponentVue>
    </v-dialog>
</template>

<script>
import PinchScrollZoom, { PinchScrollZoomEmitData } from "@coddicat/vue-pinch-scroll-zoom";
import FileUploadComponentVue from '../../Shared/FileUploadComponent.vue';
import RequestNewSparePartsDialogVue from './Views/TechnicianViewComponent/RequestNewSparePartsDialog.vue';
// import CreateEstimationDialog from '@/views/main/modules/Services/Views/TechnicianViewComponent/CreateEstimationDialog.vue';
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
import SharedFunctionsComponentVue from '../../Shared/SharedFunctionsComponent.vue';
import BodyPdf from '@/views/main/modules/Services/BasePrint/BodyPdf.vue';
import HeaderPdf from '@/views/main/modules/Services/BasePrint/HeaderPdf.vue';
import FooterPdf from '@/views/main/modules/Services/BasePrint/FooterPdf.vue';
import BaseCameraCaptureVue from '@/views/main/modules/Services/Base/BaseCameraCapture.vue';
import BaseFileViewer from '@/views/main/modules/Services/Base/BaseFilesViewer.vue';
import LoadingComponentVue from '@/views/main/modules/Services/Views/TechnicianManagerComponent/LoadingComponent.vue';
import ConfirmOverrideComponentVue from '@/views/main/Utils/ConfirmOverrideComponent.vue';

export default {
    mixins:[SharedFunctionsComponentVue],
    data() {
        return {
            noDataText: '',
            rfNumber: '',
            itemId: '',
            itemModel: '',
            itemProductName: '',
            itemSerialNumber: '',
            itemUnitCondition: '',
            itemOtherUnitCondition: '',
            itemDefectDescription: '',
            itemDefectRemarks: '',
            itemWarrantyType: '',
            rfStatus: '',
            itemPurchaseDate: '',
            remarks:{
                value:'',
                title:'Reason',
                enable:false
            },
            rfPartOrderItemHeaders: [
                { text: "Request Qty", value: "requested_qty" },
                { text: "Serve Qty", value: "serve_qty" },
                { text: "Incoming Qty", value: "transfer_qty" },
                { text: "Item Model", value: "model" },
                { text: "Item Name", value: "name" },
                { text: "Category", value: "category" },
                { text: "Status", value: "status" },
                { text: "Action", value: "action" },
            ],
            rfPartReturnItemHeaders: [
                { text: "Return Qty", value: "return_qty" },
                { text: "Request Qty", value: "requested_qty" },
                { text: "Serve Qty", value: "serve_qty" },
                { text: "Incoming Qty", value: "transfer_qty" },
                { text: "Item Model", value: "model" },
                { text: "Item Name", value: "name" },
                { text: "Category", value: "category" },
                { text: "Status", value: "status" },
                { text: "Action", value: "action" },
            ],
            rfCompletedItemHeaders: [
                { text: "Request Qty", value: "requested_qty" },
                { text: "Serve Qty", value: "serve_qty" },
                { text: "Item Model", value: "model" },
                { text: "Item Name", value: "name" },
                { text: "Category", value: "category" },
                { text: "Status", value: "status" },
            ],
            rfReturnHeaders: [
                // { text: "Requested Qty", value: "requested_qty" },
                { text: "Served Qty", value: "serve_qty" },
                { text: "Return Qty", value: "return_qty" },

                { text: "Item Model", value: "model" },
                { text: "Item Name", value: "name" },
                { text: "Category", value: "category" },
                // { text: "SR#", value: "stock_request_num" },
                // { text: "ST#", value: "stock_transfer_num" },
                { text: "Status", value: "status" },
            ],
            rfEstimation: null,
            rfPartsOrder: null,
            rfPartsOrderStatus: '',
            rfPartsOrderItems: [],
            rfEstimationItems: [],
            rfEstItemsDate: '',
            itemRepairStatus: '',
            rfPartsOrderDate: '',
            loading: true,
            noDataText: '',
            showRepairDone: true,
            repairForm: null,
            rfCompletedDate: '',
            modelToSearch: '',
            itemToSearch: '',
            rfPartsOrderId: '',
            showStartRepair: true,
            rfItemId: '',
            withParts: false,
            rfCancelledDate: '',
            rfReEstimate: false,
            repairStarted: false,
            itemForReturnListCount: 0,
            spForReturnPrintIssued: this.$dayjs().format('YYYY-MM-DD, hh:mm:ss a'),
            jobDoneDialog: false,
            jobDoneRemarks: "",
            forReturnSp: false,
            forReplacement: false,
            showSelect: false,
            selectedForReturn: [],
            rfPartsOrderItemsForReturn: [],
            itemQaRemarks: '',
            // warehousesCode: [],
            returnSpOnFlight: false,
            processedReturnBy: '',
            currentUser: null,
            focSpareParts: [],
            focItemHeaders: [
                { text: "Model", value: "model" },
                { text: "Name", value: "name" },
                { text: "Category", value: "category" },
                { text: "FOC", value: "foc_item" },
                { text: "Uploaded Image", value: "uploaded_count" },
                { text: "Action", value: "action" },
            ],
            focItems: [],
            uploadDialog: false,
            itemSpId: '',
            itemSpModel: '',
            itemSpName: '',
            itemSpCategory: '',
            uploadedFiles: null,
            files: [],
            filesHeader: [
                { text: 'Name', value: 'name' },
                { text: 'Uploaded By', value: 'uploaded_by' },
                { text: 'Date', value: 'created_at' },
                { text: 'Action', value: 'action' },
            ],
            viewFile: false,
            itemPath: '',
            scale: 2,
            itemName: '',
            qaRemarksDialog: false,
            qaRemarks: '',
            checklist: {
                selected_checklist: null,
                all: "0",
            },
            check_list: [],
            rejectedCounterDialog: false,
            selectedItemId: '',
            selectedItemForSearch: null,
            hasChanges: false,
            searchByModelDialog: false,
            forReplacementHeaders: [
                { text: 'Item Model', value: 'item.model' },
                { text: 'Part Num', value: 'item.part_num' },
                { text: 'Warehouse', value: 'warehouse_code' },
                { text: 'Qty', value: 'quantity' },
                { text: 'Price', value: 'selling_price' },
                { text: 'Action', value: 'action' },
            ],
            spLists: [],
            replacementSp: null,
            sp_selection: [],
            forPrintingDataHeader: {
                form_title:'',
                header_details: [
                    {id:1, label: 'RF#', value:''},
                    {id:2, label: 'Issued', value: this.$dayjs().format('YYYY-MM-DD, hh:mm:ss a')},
                ],
            },
            forPrintingDataBody: [],
            // forPrintingDataBodyFinal: [],
            forPrintingDataFooter: [
                {id:1, label:'Transact by', value:''},
                {id:2, label:'Technician', value:''},
            ],
            rfPrepared: false,
            salesOrderId: '',
            salesOrderNum: '',
            packingListNum: '',
            packingListId: '',
            printedPl: false,
            readyForDelivery: false,
            reasonSpareParts: '',
            reasonDialog: false,
            readyForQa: 0,
            showCreateEstimationButton: false,
            showCreateNewEstimationDialog: false,
            repairFormId: null,
            repairFormDetails: {},
            repairFormEstimations: [],
            uploadWarranty: false,
            images: [],
            attachedFiles: null,
            repairStatusInfoDialog: false,
            editRepairStatusList: ['For-Repair', 'For-Replacement', 'No-Problem-Found', 'Repair-w/o-Parts', 'Return-w/o-Repair', 'Credit-Memo', 'Replaced'],
            editRepairStatus: '',
            displayReasonSpareParts: '',
            repairedDateDialog: false,
            repairedDate: this.$dayjs().format('YYYY-MM-DD'),
            datePickerMenu: false,
            overrideRepairedDate: {
                override_dialog: false,
                override_departments_allowed: ['SR', 'SM', 'EX', 'IT'],
                override_authenticated: false,
            },
            reasonForRepairedDate: '',
            repairedDateReasonDialog:false,
            returnPartsOnFlight: false,
            forChangeDefectiveSp: false,
            changePartLoading: false,
        }
    },
    components: {
        RequestNewSparePartsDialogVue,
        FileUploadComponentVue,
        // CreateEstimationDialog,
        PinchScrollZoom,
        HeaderPdf,
        BodyPdf,
        FooterPdf,
        BaseCameraCaptureVue,
        BaseFileViewer,
        LoadingComponentVue,
        ConfirmOverrideComponentVue
    },
    props:['rf','warehousesCode'],
    async mounted() {
        this.getCurrentUser();
        this.$store.dispatch('getLists')
        this.$store.dispatch('getItemChecked')
        // this.getAllSpByModel();
        this.$store.dispatch('getSpReplacementsSelection')
        this.$store.dispatch('getSpecificListSelection')
    },
    watch: {
        rf() {
            if (!!this.rf) {
                this.rfDetails = this.rf;
                this.repairFormDetails = this.rf;
                this.repairFormId = this.rf.id;
                this.getApproveOrders(this.rf.id);
                this.getRfPartsOrder();
                let rr = this.rfDetails.sc_receiving_report;
                this.rfNumber = this.rfDetails.rf_number;
                this.rrNumber = rr.rr_number;
                this.rfStatus = this.rfDetails.status;
                this.rfReEstimate = this.rf.re_estimate;
                this.customerId = rr.customer_id;
                this.forPrintingDataHeader.header_details[0].value = this.rfDetails.rf_number;
                this.packingListId = this.rfDetails.packing_list_id;
                this.packingListNum = this.rfDetails.packing_list_num;
                this.readyForQa = this.rfDetails.ready_for_qa;
                if(!!this.rf.completed_at) {
                    this.rfCompletedDate = this.$dayjs(this.rf.completed_at).format('YYYY-MM-DD, hh:mm:ss a');
                }

                if(!!this.rf.cancelled) {
                    this.rfCancelledDate = this.$dayjs(this.rf.cancelled_at).format('YYYY-MM-DD, hh:mm:ss a');
                }

                if (!!this.rfDetails.sc_repair_form_item) {
                    this.rfItemId = this.rfDetails.sc_repair_form_item.id;
                    this.itemId = this.rfDetails.sc_repair_form_item.item_id;
                    this.itemModel = this.rfDetails.sc_repair_form_item.model;
                    this.itemProductName = this.rfDetails.sc_repair_form_item.product_name;
                    this.itemCategory = this.rfDetails.sc_repair_form_item.category;
                    this.itemDefectDescription = this.rfDetails.sc_repair_form_item.defect_description;
                    this.itemDefectRemarks = this.rfDetails.sc_repair_form_item.defect_remarks;
                    this.itemSerialNumber = this.rfDetails.sc_repair_form_item.serial;
                    this.itemAccessories = this.rfDetails.sc_repair_form_item.accessories;
                    this.itemOtherAccessory = this.rfDetails.sc_repair_form_item.other_accessory;
                    this.itemWarrantyType = this.rfDetails.sc_repair_form_item.warranty_type;
                    this.itemWarranty = this.rfDetails.sc_repair_form_item.warranty;
                    this.itemWarrantyDocument = this.rfDetails.sc_repair_form_item.warranty;
                    this.itemUnitCondition = this.rfDetails.sc_repair_form_item.unit_condition;
                    this.itemOtherUnitCondition = this.rfDetails.sc_repair_form_item.other_unit_condition;
                    this.itemPurchaseDate = this.rfDetails.sc_repair_form_item.purchase_date;
                    this.itemRepairStatus = this.rfDetails.sc_repair_form_item.repair_status;
                    this.repairStarted = this.rfDetails.sc_repair_form_item.repair_started;
                    this.itemQaRemarks = this.rfDetails.sc_repair_form_item.qa_remarks;
                }

            }
        },
        rfEstimation() {
            if(!!this.rfEstimation) {
                this.rfEstItemsDate = this.$dayjs(this.rfEstimation.created_at).format('YYYY-MM-DD, hh:mm:ss a');
            }
        },
        rfPartsOrder() {
            if(!!this.rfPartsOrder) {
                this.rfPartsOrderId = this.rfPartsOrder.id;
                this.rfPartsOrderDate = this.$dayjs(this.rfPartsOrder.created_at).format('YYYY-MM-DD, hh:mm:ss a');
            }
        },
        rfPartsOrderItems() {
            if(this.rfPartsOrderItems.length > 0) {
                // this.loading = false;
                let itemsReceived = this.rfPartsOrderItems.every(item => {
                    return item.status == 'completed';
                });

                this.showStartRepair = !itemsReceived;
                this.showRepairDone = !itemsReceived;

                this.itemForReturnListCount = this.rfPartsOrderItems.length;

                this.rfPartsOrderItemsForReturn = this.rfPartsOrderItems.filter(x=>x.transfer_qty > 0);
            } else {
                setTimeout(() => {
                    this.loading = false;
                }, 10000);
            }
        },
        async repairForm() {
            if(!!this.repairForm) {
                const { sc_rf_estimations, sc_parts_orders } = this.repairForm;

                // this.rfEstimation = this.repairForm.sc_rf_estimations[this.repairForm.sc_rf_estimations.length - 1];
                // this.rfPartsOrder = this.repairForm.sc_parts_orders[this.repairForm.sc_parts_orders.length - 1];
                this.rfEstimation = _.last(sc_rf_estimations);
                this.rfPartsOrder = _.last(sc_parts_orders);

                if(!!this.rfPartsOrder) {
                    this.rfPartsOrderStatus = this.rfPartsOrder.status;
                    // this.rfPartsOrderItems = this.rfPartsOrder.sc_parts_order_items;

                    let data = this.rfPartsOrder.sc_parts_order_items;
                    let modified = [];

                    if(data.length > 0) {
                        data.forEach(x=>{
                            modified.push(
                                Object.assign({
                                    checked: false,
                                    return_qty: 0,
                                }, x)
                            )
                        });
                    }

                    for(let i in modified){
                        modified[i].list = await this.selectedDetails(modified[i].item_id)
                        modified[i].old_item_id = modified[i].item_id
                    }
                    this.$forceUpdate()
                    this.rfPartsOrderItems = modified
                    this.loading = false;
                }

                if(!!this.rfEstimation) {

                    let rf_data = this.rfEstimation.sc_rf_est_items;
                    let rf_modified = [];

                    if(rf_data.length > 0) {
                        rf_data.forEach(x=>{
                            rf_modified.push(
                                Object.assign({
                                    checked: false,
                                    return_qty: 0,
                                }, x)
                            )
                        });
                    }

                    this.rfEstimationItems = rf_modified;

                }
            }
        },
        showStartRepair() {
            if(!this.showStartRepair && !!this.rfItemId) {
                let payload = {
                    rfItemId: this.rfItemId
                }
                this.$store.dispatch('logTechReceivedItems',payload);
            }
        },
        forReturnSp() {
            if(!!this.forReturnSp) {
                this.showSelect = true;
                this.forPrintingDataHeader.form_title = 'Return Spare Parts Form';
            } else {
                this.showSelect = false;
                this.selectedForReturn = [];
            }
        },

        forReplacement() {
            if(!!this.forReplacement) {
                this.showSelect = true;
                this.forPrintingDataHeader.form_title = 'Replace Spare Parts Form';
            } else {
                this.showSelect = false;
                this.selectedForReturn = [];
            }
        },

        selectedForReturn() {
            // console.log(this.selectedForReturn)
        },
        currentUser() {
            if(!!this.currentUser) {
                this.processedReturnBy = this.currentUser.name;
            }
        },
        'GET_SPECIFIC_CHECK_LISTS_SELECTION':{
            handler(val){
                val = val.map(e=>{
                    e.selected_checklist = '0'
                    return e
                })
            }
        },
        "checklist.all": {
            handler(val) {
                if (val == 0) {
                    this.resetCheckboxes();
                } else {
                    this.checklist.selected_checklist = this.GET_SPECIFIC_CHECK_LISTS_SELECTION.map((e) => {
                        e.selected_checklist = "1";
                        return e;
                    });

                    this.checklist.selected_checklist = this.checklist.selected_checklist.sort((a, b) => {
                        return a.id - b.id;
                    });
                }
                },
        },

        salesOrderId() {
            if(!!this.salesOrderId) {
                this.getSalesOrderStatus()
            }
        },

        'itemRepairStatus': {
            handler(val){
                if (val) {
                    let elementInArray = ['Return-w/o-Repair', 'Repair-w/o-Parts', 'No-Problem-Found'].map(item => item.trim()).includes(val.trim())
                    if (elementInArray) {
                        return this.showCreateEstimationButton = true;
                    }else {
                        return this.showCreateEstimationButton = false;
                    }
                }
            },
        },
        forChangeDefectiveSp() {
            if(!!this.forChangeDefectiveSp) {
                this.showSelect = true;
            } else {
                this.showSelect = false;
                this.selectedForReturn = [];
            }
        }
    },
    computed: {
        ...mapGetters([
            'GET_APPROVE_ORDERS',
            'GET_FOC_SPARE_PARTS',
            'GET_SERVICE_GET_CURRENT_USER',
            'GET_PARTS_ORDER_ITEM_FILES',
            'GET_CHECK_LISTS_SELECTION',
            'GET_SP_REPLACEMENT_SELECTION',
            'GET_SERVICE_SALES_ORDER_STATUS',
            'GET_ALL_TECH_RFS',
            'GET_SPECIFIC_CHECK_LISTS_SELECTION',
        ]),
        techRfEstDialog() {
            return this.$store.state.service.techRfEstDialog;
        },
        sortedChecklistName() {
            return this.GET_SPECIFIC_CHECK_LISTS_SELECTION.sort((a, b) =>
            a.name.localeCompare(b.name)
                );
        },
    },
    methods: {
        closeTechRfEstDialog() {
            this.$store.commit('closeTechRfEstDialog');
            this.jobDoneRemarks = "";
            this.forReturnSp = false;
            this.forReplacement = false;
            this.showSelect = false;
            this.selectedForReturn = [];
            this.itemQaRemarks = "";
            this.forPrintingDataBody = [];
            this.forPrintingDataHeader.header_details[0].value = '';
            this.rfPartsOrderItems = [];
            this.repairedDate = this.$dayjs().format('YYYY-MM-DD');
            this.repairedDateDialog = false;
            this.reasonForRepairedDate = '';
            this.forChangeDefectiveSp = false;
        },
        getApproveOrders(rfId) {
            this.loading = true
            this.repairForm = [];
            this.$store.dispatch('getApproveOrders',rfId).then((response) => {
                if (!!response.data) {
                    this.repairForm = this.GET_APPROVE_ORDERS;
                    this.repairFormEstimations = this.GET_APPROVE_ORDERS.sc_rf_estimations;
                }
                // this.loading = false
            }).catch(e=>{
                this.loading = false
                console.log(e)
            });
        },
        requestedStatusColor(item) {
            if(item.status == 'pending') {
                return 'red';
            }

            if(item.status == 'partial' || item.status == 'shipping') {
                return 'blue';
            }

            if(item.status == 'for-confirmation' || item.status == 'preparing') {
                return '#6f42c1';
            }

            if(item.status == 'for-return' || item.status == 'for-approval') {
                return 'orange';
            }

            if(item.status == 'completed' || item.status == 'returned') {
                return 'green';
            }

            return '';
        },
        itemReceived(item) {
            swal.fire({
                title: 'Requested Items Received?',
                text: 'Make sure that all requested items are received',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then((result) => {
                if(result.isConfirmed) {
                    let payload = {
                        item: item,
                        scPartsOrderItemId: item.id
                    }
                    this.$store.dispatch('confirmItemReceived',payload).then(response=> {
                        if(response.data.msg) {
                            swal.fire("", response.data.msg, "success");
                            this.getApproveOrders(this.rf.id)
                        }
                    });
                }
            });
        },
        refreshPartsOrder() {
            // this.loading = true;
            this.rfPartsOrderItems = [];
            this.getApproveOrders(this.rf.id);
        },
        showJobDoneDialog() {
            this.jobDoneDialog = true;

            this.getFocSpareParts();
        },
        getFocSpareParts() {
            this.$store.dispatch('getFocSpareParts',this.rf.id).then(response=>{
                this.focSpareParts = this.GET_FOC_SPARE_PARTS;
            });
        },
        closeJobDoneDialog() {
            this.jobDoneDialog = false;
            this.focSpareParts = [];
        },
        repairDone() {
            if(this.focSpareParts.length > 0) {
                let checker = this.focSpareParts.every(x=>{
                    return x.uploaded_count > 0;
                });

                if(checker) {
                    this.submitRepairDone();
                } else {
                    swal.fire('', 'Please upload clear image for foc spare parts', 'warning');
                }
            } else {
                this.submitRepairDone();
            }
        },
        submitRepairDone() {
            if(!!this.jobDoneRemarks) {
                swal.fire({
                    title: 'Repair Done?',
                    text: 'Click ok to proceed',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then((result) => {
                    if(result.isConfirmed) {
                        let payload = {
                            rfId: this.repairForm.id,
                            itemId: this.rf.sc_repair_form_item.id,
                            jobDone: this.jobDoneRemarks
                        }
                        this.$store.dispatch('repairDone',payload).then(response=> {
                            if(response.data.msg) {
                                this.closeTechRfEstDialog();
                                this.getApproveOrders(this.rf.id);
                                this.$emit('successRepair', true);
                                swal.fire("", response.data.msg, "success");
                            }
                        });
                    }
                });
            } else {
                swal.fire('', 'Please add remarks', 'warning');
            }
        },
        cancelRepair() {

        },
        headers() {
            if(this.rfStatus == 'REP') {
                return this.rfCompletedItemHeaders;
            } if(this.rfStatus == 'RET') {
                return this.rfReturnHeaders;
            } else {
                if(!!this.forReturnSp || this.forChangeDefectiveSp) {
                    return this.rfPartReturnItemHeaders;
                } else if (!!this.forReplacement){
                    return this.rfPartOrderItemHeaders;
                } else {
                    return this.rfPartOrderItemHeaders;
                }

            }
        },
        showRequestNewSp() {
            this.itemToSearch = this.itemId;
            this.$store.commit('showRequestNewSparePartsDialog');
        },
        closeRequestNewSp() {
            this.modelToSearch = "";
            this.itemToSearch = "";
        },
        successRequestNewSp(confirm) {
            if(confirm) {
                this.closeTechRfEstDialog();
                this.$emit('successRequestNewSp', true);
                this.getApproveOrders(this.rf.id);
            }
        },
        startRepair() {
            swal.fire({
                title: 'Start Repair?',
                text: 'Click ok to proceed',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then((result) => {
                if(result.isConfirmed) {
                    let payload = {
                        rfId: this.rf.id
                    }
                    this.$store.dispatch('startRepair',payload).then(response=>{
                        if(response.data.msg) {
                            this.closeTechRfEstDialog();
                            this.getApproveOrders(this.rf.id);
                            this.$emit('successStartRepair', true);
                            swal.fire("", response.data.msg, "success");
                        }
                    });
                }
            });
        },
        returnSpareParts() {
            swal.fire({
                title: 'Are you sure you want to return?',
                text: 'Be sure all items are ready before proceed',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(async (result) => {
                if(result.isConfirmed) {
                    this.returnPartsOnFlight = true;
                    let items_for_transfer = []
                    if(this.rfPartsOrderItems.length > 0) {
                        this.rfPartsOrderItems = this.rfPartsOrderItems.filter(e=>{
                            if(e.status == 'for-return'){
                                return e
                            }
                        })
                        this.rfPartsOrderItems.forEach(item=>{

                            items_for_transfer.push({
                                item_id: item.item_id,
                                request_type_quantity: 1,
                                requested_uom_quantity: item.serve_qty,
                                parts_order_items_id: item.id
                            });

                        });
                    }else{
                        swal.fire('Warning!','- No Item/s to return','error')
                        return false
                    }
                    // let desti_warehouse = this.warehousesCode.filter(x=>x.code == 'DM')[0];
                    // let source_warehouse = this.warehousesCode.filter(x=>x.code == 'SC-DMS')[0];

                    await this.$store.dispatch('servicePost',{
                        // source_warehouse_id: source_warehouse.id,
                        // destination_warehouse_id: desti_warehouse.id,
                        item_id:this.rfPartsOrderItems.filter(e=>{
                            if(e.status != 'completed'){
                                e.quantity = e.return_qty
                                return e
                            }
                        }),
                        total_quantity:this.sumArraybyAttribute(this.rfPartsOrderItems,'quantity'),
                        sc_repair_form_id:this.rf.id,
                        url:'create-transfer',
                        sc_parts_order_id: this.rfPartsOrder.id,
                        remarks:'Return Items, RF#: '+ this.rfNumber,
                    }).then(async response=>{
                        if (response.data.status == 'success') {
                            await this.$store.dispatch('returnSpareParts',{rfId:this.rf.id}).then(res=>{
                                if(res.data.msg) {
                                    this.$emit('successStartRepair', true);
                                    swal.fire(res.data.msg, response.data.message, "success");
                                    this.getApproveOrders(this.rf.id);
                                    this.returnPartsOnFlight = false;
                                }
                            });
                        }
                    }).catch(err=>{
                        console.log(err)
                        swal.fire('Error!','','error')
                    })

                } else if (result.isDismissed) {
                    this.returnPartsOnFlight = false;
                }
            });
        },
        continueRepair() {
            swal.fire({
                title: 'Are you sure you want to continue repair?',
                text: 'Click ok to proceed',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then((result) => {
                if(result.isConfirmed) {
                    let payload = {
                        rfId: this.rf.id
                    }
                    this.$store.dispatch('continueRepair',payload).then(response=>{
                        if(response.data.msg) {
                            this.closeTechRfEstDialog();
                            this.getApproveOrders(this.rf.id);
                            this.$emit('successStartRepair', true);
                            swal.fire("", response.data.msg, "success");
                        }
                    });
                }
            });
        },
        printForReturnSp() {
            let doc = new this.$jspdf('p', 'mm', 'letter');
            // doc.fromHTML($("#div_return_sp_logo").get(0), 7,-4,{'width':600});
            // doc.fromHTML($("#div_return_sp_num").get(0), 142,-2,{'width':400});

            doc.setFont(undefined, 'bold').setFontSize(13).text(6,8,"INGCOPH Traders Inc.")
            // doc.setFont(undefined, 'normal').setFontSize(9).text(6,13,"Service Center, 2D Arellano St. bagong Barrio Caloocan\nCell No.: +63 935 713 2293 / +63 917 170 0979 / +63 919 078 0016 \nTel. No.: +63 2 8727 1771 / +63 2 8535 9968 / +63 2 7091 4539 \nService Mobile / Viber No.: +63 919 078 0019 / +63 927 414 5539 \nSpare Parts Mobile / Viber No.: \nEmail:Ingcocares@ingco.com.ph+63 995 095 3153")
            doc.setFont(undefined, 'normal').setFontSize(9).text(6,13,"2 D Arellano St. Bagong Barrio, Caloocan City \nLandline:7002-3394\nSpare Parts Mobile / Viber No.: \nEmail:Ingcocares@ingco.com.ph")
            doc.setFont(undefined, 'bold').setFontSize(13).text(162,8,"Spare Parts")
            doc.setFont(undefined, 'bold').setFontSize(9).text(162,18,"RF: "+this.rf.rf_number)
            doc.setFont(undefined, 'bold').setFontSize(9).text(162,13,"Issued: "+this.spForReturnPrintIssued)

            doc.autoTable({
                html: '#return_sp_item_details',
                margin: {left: 7, right: 6},
                startY: 50,
                columnStyles: {
                    0: {cellWidth: 15, halign:'left', textColor: '#000000', fontSize: 8},
                    1: {cellWidth: 63, halign:'left', textColor: '#000000', fontSize: 8},
                    2: {cellWidth: 45, halign:'left', textColor: '#000000', fontSize: 8},
                    3: {cellWidth: 30, halign:'left', textColor: '#000000', fontSize: 8},
                    4: {cellWidth: 45, halign:'left', textColor: '#000000', fontSize: 8},
                }
            });
            doc.autoTable({
                html: '#return_sp_process_details',
                theme: 'grid',
                startY: 258,
                margin: {top: 37, left: 7, right: 6},
                columnStyles: {
                    0: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                    1: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                    2: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                    3: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                    4: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                    5: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                    6: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                    7: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8}
                }
            });
            doc.autoPrint();

            window.open(doc.output('bloburl'), '_blank')

        },
        showPrintForReturnSp() {
            let container = [];
            this.rfPartsOrderItems.forEach(x=>{
                container.push(x.status);
            });

            if(
                container.includes("for-return") && (this.rfStatus == "PIN" || this.rfStatus == "RET") ||
                container.includes("for-confirmation") && (this.rfStatus == "PIN" || this.rfStatus == "RET")
            ) {
                return false;
            }

            return true;
        },
        async returnSelectedSp() {
            this.returnSpOnFlight = true;
            this.remarks.enable = true;

            let collectedForReturn = _.compact(this.rfPartsOrderItems).filter(x=>!!x.checked && x.return_qty > 0);
            this.selectedForReturn = collectedForReturn

            if(!!this.selectedForReturn)
            {
                this.forPrintingDataBody = [];
                this.selectedForReturn.forEach( obj => {
                let dataObj = new Object();
                dataObj.id = obj.id;
                dataObj.return_qty = obj.return_qty;
                dataObj.request_qty = obj.requested_qty;
                dataObj.serve_qty = obj.serve_qty;
                dataObj.model = obj.model;
                dataObj.name = obj.name;
                dataObj.category = obj.category;
                dataObj.date_signature = '';
                this.forPrintingDataBody.push(dataObj);
                })
            }

            if(collectedForReturn.length == 0) {
                swal.fire('','Please Select For Return Spare Parts','warning').then(confirm=>{
                    this.returnSpOnFlight = false;
                });
            } else  if(!this.reasonSpareParts) {
                swal.fire('','- Please input a reason','info').then(confirm=>{
                    this.reasonDialog = true;
                    this.returnSpOnFlight = false;
                });
            } else {
                swal.fire({
                    title: 'Are You Sure You Want to Return Selected Items?',
                    text: 'Click Ok To Proceed',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                }).then(async (confirm) => {
                    if(confirm.isConfirmed) {
                        let payload = {
                            rfId: this.rf.id,
                            selectedSpForReturn: collectedForReturn
                        }
                        await this.$store.dispatch('returnSelectedSp',payload).then(response=> {
                            this.closeTechRfEstDialog();
                            this.getApproveOrders(this.rf.id);
                            this.$emit('successStartRepair', true);
                            swal.fire("Success!", response.data.msg, "success").then(confirm=>{
                                this.returnSpOnFlight = false;
                            });
                            this.printForm();
                        }).catch(err=>{
                            this.returnSpOnFlight = false;
                            swal.fire("Error!", '', "error")
                            console.log(err)
                        });
                    } else {
                        this.returnSpOnFlight = false;
                    }
                });
            }
        },
        showRetItems() {
            if(this.rfStatus == 'RET') {
                let itemStatus = this.rfPartsOrderItems.map(x=>{
                    return x.status;
                });
                let forRetChecker = itemStatus.includes('for-return')

                if(forRetChecker && this.rfPartsOrderItems.length != 0) {
                    return true;
                } else {
                    return false;
                }
            }

            return false;
        },
        getCurrentUser(){
            this.$store.dispatch('serviceGetCurrentUser').then(response=>{
                this.currentUser = this.GET_SERVICE_GET_CURRENT_USER;
                this.forPrintingDataFooter[0].value = this.GET_SERVICE_GET_CURRENT_USER?.name;
            });
        },
        showUploadDialog(item) {
            let sparePart = this.focSpareParts.find(x=>x.id == item.id);

            if(!!sparePart) {
                this.itemSpId = sparePart.id;
                this.itemSpModel = sparePart.model;
                this.itemSpName = sparePart.name;
                this.itemSpCategory = sparePart.category;
            }

            this.getPartsOrderItemFiles();

            this.uploadDialog = true;
            this.jobDoneDialog = false;
        },
        getPartsOrderItemFiles() {
            this.$store.dispatch('getPartsOrderItemFiles',this.itemSpId).then(response=>{
                this.files = this.GET_PARTS_ORDER_ITEM_FILES;
            });
        },
        closeUploadDialog() {
            this.uploadDialog = false;
            this.jobDoneDialog = true;
            this.uploadedFiles = null;
            this.files = [];
            this.$refs.fileUpload.reset();
            this.getFocSpareParts();
        },
        uploadImage() {
            swal.fire({
                title: 'Are you sure you want to Upload?',
                text: 'Click Ok To Proceed',
                icon: 'question',
                dangerMode: true,
                buttons: true
            }).then((confirm) => {
                if(confirm) {
                    const data = new FormData();

                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }

                    if(!!this.uploadedFiles && this.uploadedFiles.attachments.length > 0) {
                        let files = this.uploadedFiles.attachments;

                        for(let i=0;i < _.compact(files).length;i++) {
                            data.append('files[]', files[i]);
                        }
                    }

                    data.append('partsOrderId', this.itemSpId);
                    data.append('rfId', this.rf.id);
                    let payload = {
                        params:data,
                        config:config,
                    }
                    this.$store.dispatch('uploadSparePartImage',payload).then(response=> {
                        if(response.data.msg) {
                            this.getPartsOrderItemFiles();
                            this.uploadedFiles = null;
                            this.$refs.fileUpload.reset();
                            swal.fire("", response.data.msg, "success");
                        }
                    });
                }
            });
        },

        uploadedData(data) {
            if(!data) {
                this.uploadedFiles = null;
            }

            this.uploadedFiles = data;
        },
        clearFileUpload(confirm) {
            if(confirm) {
                this.uploadedFiles = null;
            }
            this.$refs.fileUpload.reset();
        },
        showFile(file) {
            this.viewFile = true;
            this.itemPath = file.path;
            this.itemName = file.name;
        },
        downloadFile(file) {
            swal.fire({
                text: 'Are you sure you want to download?',
                icon: 'question',
                dangerMode: true,
                buttons: true
            }).then((confirm) => {
                if(confirm) {
                    let filelink = document.createElement('a');
                    filelink.href = file.path;
                    filelink.setAttribute('download', file.name);
                    document.body.appendChild(filelink);
                    filelink.click();
                }
            });
        },
        deleteFile(file) {
            swal.fire({
                text: 'Are you sure you want to delete?',
                icon: 'question',
                dangerMode: true,
                buttons: true
            }).then((confirm) => {
                if(confirm) {
                    this.$store.dispatch('deleteSparePartImage',file.id).then(response=>{
                        if(response.data.error) {
                            swal.fire("", response.data.error, "error");
                        } else {
                            this.getPartsOrderItemFiles();
                            swal.fire("", response.data.msg, "success");
                        }
                    });
                }
            });
        },
        closeViewFile() {
            this.reset();
            this.itemPath = "";
            this.viewFile = false;
        },
        reset() {
            this.$refs.zoomer.setData({
                scale: 1,
                originX: 0,
                originY: 0,
                translateX: 0,
                translateY: 0
            });
        },
        enableReturn(item) {
            if(!!item.checked) {
                item.return_qty = item.serve_qty;
            } else {
                item.return_qty = 0;
            }
        },
        checkReturnQty(item) {
            if(item.return_qty > item.serve_qty) {
                item.return_qty = item.serve_qty;
            }
        },
        submit() {
            let checker = this.GET_SPECIFIC_CHECK_LISTS_SELECTION.filter(e=>{
                 if(e.selected_checklist == '0'){
                     return e;
                 }
            })

            if(checker.length > 0 ) {
                 this.qaDisapproved();

             } else {
                 this.confirmRepairedItem();
             }
         },
        closeQaRemarksDialog() {
            this.qaRemarksDialog = false;
            this.qaRemarks = "";
            this.resetCheckboxes();
        },
        // Iterate through the checklist items and set selected_checklist to false
        resetCheckboxes() {
            let checklist = [];
            checklist =  this.GET_SPECIFIC_CHECK_LISTS_SELECTION.map((e) => {
                e.selected_checklist = "0";
            return e;
            });
            // this.$store.commit('CHECK_LISTS',checklist)
            this.checklist = {
                selected_checklist: null,
                all: "0",
            };

        },
        showQaRemarks() {
            this.qaRemarksDialog = true;
        },
        qaDisapproved() {
            let selectedChecklist = this.GET_SPECIFIC_CHECK_LISTS_SELECTION.filter(
            (e) => e.selected_checklist == 1
            );

            swal.fire({
                title: 'Are You Sure You Want To Reject?',
                icon: 'question',
                buttons: true,
                dangerMode: true,
                showCancelButton:true,
                reverseButtons:true
            }).then((confirm)=> {
                if(confirm.isConfirmed) {
                    let payload = {
                        rfId: this.rf.id,
                        qaRemarks: this.qaRemarks,
                        check_list: selectedChecklist.map((e) => e.check_list_id),
                    }
                    this.$store.dispatch('qaDisapproved',payload).then(response => {
                        if(response.data.error) {
                            swal.fire("Error!", response.data.error, "error");
                        } else {
                            this.refreshPartsOrder()
                            this.closeTechRfEstDialog()
                            this.getApproveOrders(this.rf.id);
                            this.$emit('successStartRepair', true);
                            this.$emit('successConfirm', true);
                            this.closeRepairFormDetailsDialog();
                            swal.fire("'Success!", response.data.msg, "success");
                        }
                    });
                }
            });
        },
        confirmRepairedItem() {
            let selectedChecklist = this.GET_SPECIFIC_CHECK_LISTS_SELECTION.filter(
            (e) => e.selected_checklist == 1
            );

            if(!this.qaRemarks) {
                swal.fire('','Please input remarks','warning');
            } else {
                swal.fire({
                    title: 'Are you sure you want to Approve?',
                    text: 'This Repair Form will move to RFD',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then(result=>{
                    if(result.isConfirmed) {
                        let payload = {
                            rfId: this.rf.id,
                            qaRemarks: this.qaRemarks,
                            warranty: this.itemWarrantyType,
                            repair_status:this.itemRepairStatus,
                            check_list: selectedChecklist.map((e) => e.check_list_id),
                        }
                        this.$store.dispatch('repairedRfConfirm',payload).then(response=>{
                            if(response.data.msg) {
                                this.refreshPartsOrder()
                                this.closeTechRfEstDialog()
                                this.$emit('successConfirm', true);
                                swal.fire("", response.data.msg, "success");
                                let payload = {
                                    rfId: this.rf.id,
                                    customer_id: this.customerId,
                                }
                                this.$store.dispatch('serviceGenerateSo',payload).then(response=>{
                                    this.refreshPartsOrder()
                                    this.getRfPartsOrder();
                                    this.getApproveOrders(this.rf.id);
                                    this.$emit('successConfirm', true);
                                    swal.fire("Success!", '', "success");
                                }).catch(err=> {
                                    console.log(err)
                                    swal.fire("Error!", '', "error");
                                })
                                // if (response.data.msg){
                                //     this.refreshPartsOrder()
                                //     this.getRfPartsOrder();
                                //     this.getApproveOrders(this.rf.id);
                                //     this.$emit('successConfirm', true);
                                //     swal.fire("Success!", response.data.msg, "success");
                                // } else {
                                //     swal.fire("Error!", response.data.error, "error");
                                // }

                                this.closeRepairFormDetailsDialog();
                            }
                            else{
                                // this.closeRepairFormDetailsDialog();
                                // this.$emit('successConfirm', true);
                                swal.fire("", response.data.error, "error");
                            }
                        }).catch(err=>{
                            console.log(err)
                        });
                    }
                });
            }
        },

        closeRepairFormDetailsDialog() {
            this.$store.commit('closeRepairFormDetailsDialog');
            this.$store.commit('APPROVE_ORDERS',[])
            this.qaRemarks = '';
            this.resetCheckboxes()
            this.clearFields()
        },

        clearFields() {
            this.qaRemarksDialog = false;
            this.qaRemarks = "";
        },

        generateSo() {
            // this.generateSoOnFlight = true;
            swal.fire({
                title: "",
                text: "Are you sure you want to Generate SO?",
                icon: 'question',
                buttons: true,
                dangerMode: true,
                showCancelButton:true,
                reverseButtons:true
            }).then((confirm) => {
                if (confirm.isConfirmed) {
                    let payload = {
                        rfId: this.rf.id,
                        customer_id: this.customerId,
                    }
                    this.$store.dispatch('serviceGenerateSo',payload).then(response=>{
                        this.refreshPartsOrder()
                        this.getRfPartsOrder();
                        this.getApproveOrders(this.rf.id);
                        this.$emit('successConfirm', true);
                        swal.fire("Success!", '', "success");
                    }).catch(err=> {
                        console.log(err)
                        swal.fire("Error!", '', "error");
                    })
                }
                // this.generateSoOnFlight = false;
            });
        },
        getRfPartsOrder() {
            // this.loading = true
            this.$store.dispatch('getApproveOrders',this.rf.id).then((response) => {
                if (!!this.GET_APPROVE_ORDERS) {
                    this.rfPrepared = this.GET_APPROVE_ORDERS.prepared;
                    this.salesOrderId = this.GET_APPROVE_ORDERS.sales_order_id;
                    this.salesOrderNum = this.GET_APPROVE_ORDERS.sales_order_num;
                    if(!!this.salesOrderNum) {
                        this.getSalesOrderStatus();
                    }
                    this.packingListNum = this.GET_APPROVE_ORDERS.packing_list_num;
                    this.packingListId = this.GET_APPROVE_ORDERS.packing_list_id;
                    this.printedPl = this.GET_APPROVE_ORDERS.printed_pl;
                    this.readyForDelivery = this.GET_APPROVE_ORDERS.ready_for_delivery;
                    this.rfPartsOrder = this.GET_APPROVE_ORDERS.sc_parts_orders[this.GET_APPROVE_ORDERS.sc_parts_orders.length - 1];
                }
                this.loading = false
            });
        },
        getSalesOrderStatus() {
            this.$store.dispatch('serviceGetSalesOrderStatus',this.salesOrderId).then(response=>{
                this.salesOrderStatus = this.GET_SERVICE_SALES_ORDER_STATUS;
            })
        },
        showRejectedItemDialog(){
            this.rejectedCounterDialog = true;
        },
        closeRejectedCounterDialog(){
            this.rejectedCounterDialog = false;
        },

        searchByPartNum(item) {
            this.selectedModel = item.item_id
            this.selectedName = item.name
        },

        replaceSelectedSp() {
            this.returnSpOnFlight = true;
            this.remarks.enable = true;

            let collectedForReplace = _.compact(this.rfPartsOrderItems).filter(x=>!!x.item_id && !!x.model && x.item_id != x.old_item_id);

            if(!!collectedForReplace)
            {
                this.forPrintingDataBody = [];
                collectedForReplace.forEach( obj => {
                let dataObj = new Object();
                dataObj.id = obj.id;
                dataObj.return_qty = obj.return_qty;
                dataObj.request_qty = obj.requested_qty;
                dataObj.serve_qty = obj.serve_qty;
                dataObj.model = obj.model;
                dataObj.name = obj.name;
                dataObj.category = obj.category;
                dataObj.date_signature = '';
                this.forPrintingDataBody.push(dataObj);
                })
            }

            if(collectedForReplace.length == 0) {
                swal.fire('','Please Select For Replace Spare Parts','warning').then(confirm=>{
                    this.returnSpOnFlight = false;
                });
            } else  if(!this.reasonSpareParts) {
                swal.fire('','- Please input a reason','info').then(confirm=>{
                    this.reasonDialog = true;
                    this.returnSpOnFlight = false;
                });
            } else {
                swal.fire({
                    title: 'Are You Sure You Want to Replace Selected Items?',
                    text: 'Click Ok To Proceed',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                }).then(async confirm => {
                    if(confirm.isConfirmed){
                        let payload = {
                            rfId: this.rf.id,
                            selectedSpForReplace: collectedForReplace,
                        }
                        await this.$store.dispatch('replaceSelectedSp', payload).then((response)=>{
                            this.closeTechRfEstDialog();
                            this.getApproveOrders(this.rf.id);
                            this.$emit('successStartRepair', true);
                            swal.fire('Success!','','success')
                            this.returnSpOnFlight = false;
                            this.printForm();
                        }).catch(err=>{
                            this.returnSpOnFlight = false;
                            swal.fire('Error!','','error')
                            console.log(err)
                        })
                    } else {
                        this.returnSpOnFlight = false;
                    }
                })
            }

        },

        async selectedDetails(id){
            let a = []
            await this.$store.dispatch('serviceGet',{
                url:'parts-replacement',
                id:id
            }).then(response=>{
                a = response.data
            })

            return a
        },

        printForm() {
            let pdf = new this.$jspdf("p", "mm", "letter"); // paper size in mm. = 215.9 x 279.4
                let pageNumber = pdf.internal.getNumberOfPages();

                let ingcoPhLogo = new Image();
                ingcoPhLogo.src = "/images/ingcoph-logo.png";
                pdf.addImage(ingcoPhLogo, "png", 65, 12.7, 90, 20);

                // For Header
                pdf.autoTable({
                    html: '#form-title',
                    theme:"plain",
                    margin: {top: 0},
                    startY: 37.7,
                    styles: {
                        fontSize:14,
                        overflow:'hidden',
                    },
                    columnStyles: {
                        0: {cellWidth: 'auto', halign:'center'},
                    },
                })

                pdf.autoTable({
                    html: '#header-left',
                    theme:"plain",
                    margin: {left:12.7},
                    startY: 52.7,
                    styles: {
                        fontSize:10,
                        overflow:'hidden',
                    },
                })

                let headerEndPosition = pdf.lastAutoTable.finalY;

                if ($('#header-thirdPart tr').length != 0)
                {

                    pdf.autoTable({
                        html: '#header-thirdPart',
                        theme:"plain",
                        margin: {left: 75},
                        startY: 52.7,
                        styles: {
                            fontSize:10,
                            overflow:'hidden',
                        },
                    })
                }

                if ($('#header-right tr').length != 0)
                {
                    pdf.autoTable({
                        html: '#header-right',
                        theme:"plain",
                        margin: {left: 137},
                        startY: 52.7,
                        styles: {
                            fontSize:10,
                            overflow:'hidden',
                        },
                    })
                }

                // For Body
                pdf.autoTable({
                  html: '#body-pdf',
                  theme:"grid",
                  styles: {textColor: '#000000', fontSize: 8, lineWidth:0.1, lineColor:"#000000"},
                  headStyles:{textType: "bold", fillColor: "#F7FF00", halign: "center"},
                  footStyles:{textType: "bold", fontSize: 16, fillColor: "#F73302"},
                  margin: {top:50, left: 12.7, right: 12.7},
                  startY: headerEndPosition,
                  columnStyles: {
                      0: { cellWidth: 11.9, halign:'center'},
                      1: { cellWidth: 18, halign:'center'},
                      2: { cellWidth: 18, halign:'center'},
                      3: { cellWidth: 18, halign:'center'},
                      4: { cellWidth: 30, halign:'center'},
                      5: { cellWidth: 30, halign:'center'},
                      6: { cellWidth: 21.4, halign:'center'},
                      7: { cellWidth: 44, halign:'center'},
                  }
                });

                let bodyEndPosition = pdf.lastAutoTable.finalY;

                if(this.remarks.enable){
                    pdf.autoTable({
                        html: '#reason-pdf',
                        theme:"grid",
                        styles: {textColor: '#000000', fontSize: 8, lineWidth:0.1, lineColor:"#000000"},
                        headStyles:{textType: "bold", fillColor: "#F7FF00", halign: "center"},
                        footStyles:{textType: "bold", fontSize: 16, fillColor: "#F73302"},
                        margin: {top:50, left: 12.7, right: 12.7},
                        startY: bodyEndPosition + 3,
                        columnStyles: {
                            0: { cellWidth: 11.9, halign:'left'},
                            1: { cellWidth: 18, halign:'left'},
                            2: { cellWidth: 18, halign:'left'},
                            3: { cellWidth: 18, halign:'left'},
                            4: { cellWidth: 30, halign:'left'},
                            5: { cellWidth: 30, halign:'left'},
                            6: { cellWidth: 21, halign:'left'},
                            7: { cellWidth: 21, halign:'left'},
                            8: { cellWidth: 26, halign:'left'}
                        }
                    });
            }

                let reasonEndPosition = pdf.lastAutoTable.finalY;
                // For Footer
                pdf.autoTable({
                    html: '#footer-left',
                    theme:"plain",
                    margin: {right:107},
                    startY: reasonEndPosition + 80,
                    showHead: 'firstPage',
                    styles: {
                        fontSize:10,
                        overflow:'hidden',
                    },
                })

                pdf.setPage(pageNumber)

                pdf.autoTable({
                    html: '#footer-right',
                    theme:"plain",
                    margin: {left: 137},
                    startY: reasonEndPosition + 80,
                    showHead: 'firstPage',
                    styles: {
                        fontSize:10,
                        overflow:'hidden',
                    },
                })

                pdf.save('Spare Parts Form.pdf');
        },
        closeReasons() {
            this.reasonDialog = false;
            this.displayReasonSpareParts = this.reasonSpareParts
            this.reasonSpareParts = '';
        },

        submitReasons() {
            let selectedPartsOrderItems = _.compact(this.rfPartsOrderItems).filter(x=>!!x.checked && x.return_qty > 0);
            let collectedForReplace = _.compact(this.rfPartsOrderItems).filter(x=>!!x.item_id && !!x.model && x.item_id != x.old_item_id);

            this.remarks.enable = true;
            if(!this.reasonSpareParts) {
                swal.fire('','- Please input a reason','info')
            } else {
                swal.fire({
                    title: '- Are you sure you want to submit reason?',
                    text: 'Click Ok To Proceed',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                }).then(async confirm => {
                    if(confirm.isConfirmed) {
                        let payload = {
                            rfId: this.rf.id,
                            reason: this.reasonSpareParts,
                            selectedPartsOrderItems: selectedPartsOrderItems.length == 0 ? collectedForReplace : selectedPartsOrderItems,
                        }
                        await this.$store.dispatch('submitReasons', payload).then(response => {
                            swal.fire('Success!','','success')
                            if(this.forReturnSp) {
                                this.returnSelectedSp()
                            } else if(this.forReplacement) {
                                this.replaceSelectedSp()
                            }
                            this.closeReasons();
                            this.getApproveOrders(this.rf.id);
                        }).catch(err=> {
                            swal.fire('Error!','','error')
                            console.error(err)
                        })
                    }
                })
            }
        },

        openReasons() {
            this.reasonDialog = true;
        },

        readyForQaTester(reason='') {
            swal.fire({
                title: '- Are you sure you want to ready this for QA?',
                text: 'Click Ok To Proceed',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
            }).then(async confirm => {
                if(confirm.isConfirmed) {
                    let payload = {
                        rfId: this.rf.id,
                        readyForQa: 1,
                        repairedDate: this.repairedDate + ' ' + this.$dayjs().format('HH:mm:ss'),
                        repairedDateReason: reason
                    }
                    await this.$store.dispatch('readyForQaTester', payload).then(response => {
                        this.refreshPartsOrder()
                        this.closeTechRfEstDialog()
                        this.getApproveOrders(this.rf.id);
                        this.$emit('successStartRepair', true);
                        swal.fire('Success!','','success')
                    }).catch(err=> {
                        swal.fire('Error!','','error')
                        console.error(err)
                    })
                }
            })
        },

        createNewEstimation() {
            swal.fire( {
                title: 'Are you sure to create new Estimation?',
                text: '-Repair Form status will return to UAS',
                icon: 'question',
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                allowOutsideClick: false,
                confirmButtonColor: '#397373',
                cancelButtonColor: 'grey',
                confirmButtonText: 'Confirm'
            }).then(async confirm => {
                if (confirm.isConfirmed) {
                    let payload = {
                        rfId: this.rf.id,
                        itemRepairStatus: this.itemRepairStatus,
                        url: 'new-estimation',
                    };
                    await this.$store.dispatch('servicePost', payload).then( response  => {
                        this.closeTechRfEstDialog();
                        this.refreshPartsOrder();
                        swal.fire('Success', `${response.data.message}`, 'success');
                    }).catch(() => {
                        swal.fire('Error', '', 'error');
                        console.error(`${response.data.error}`);
                    })
                }
            })

        },
        successAddNewEstimation(confirm) {
            if(confirm) {
                this.closeTechRfEstDialog();
                this.$emit('successAddNewEstimation', true);
                this.refreshPartsOrder();
            }
        },
        showCreateNewEstimation() {
            this.showCreateNewEstimationDialog = true;
        },
        closeCreateNewEstimation(data) {
            this.showCreateNewEstimationDialog = data;
            // this.refreshPartsOrder();
        },
        getImages(images) {
            this.images = images;
        },
        clearCameraCapture(){
            this.$refs.cameraCapture.reset();
            this.images = null;
        },
        attachedFile(data) {
            if(!data) {
                this.attachedFiles = null;
            }

            this.attachedFiles = data;
        },
        submitWarranty(){
            const data = new FormData();
            // this.images == null && this.uploadedFiles == null
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            if(!!this.attachedFiles && this.attachedFiles.attachments.length > 0) {
                let files = this.attachedFiles.attachments;

                for(let i=0;i < _.compact(files).length;i++) {
                    data.append('files[]', files[i]);
                }
            }

            if(!!this.images && this.images.length > 0) {
                for(let i=0;i < _.compact(this.images).length;i++) {
                    data.append('images[]', _.compact(this.images)[i]);
                }
            }
            data.append('rfId', this.rf.id);

            if (!this.attachedFiles && this.images.length == 0) {
                swal.fire("Error" ,'- No file found', 'info')
            } else {
                swal.fire({
                    title: 'Are you sure you want to Capture or Upload Image?',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then(confirm=>{
                    if(confirm.isConfirmed){
                     let payload = {
                        rfId: this.rf.id,
                        params: data,
                        config: config,
                    }
                    this.$store.dispatch('uploadWarranty', payload).then(response=>{
                    this.closeUploadWarranty();
                    this.$emit('successConfirm', true);
                    swal.fire("Success", '', "success");

                    }).catch(err=>{
                        console.log(err)
                        swal.fire('Failed!','','error')
                        this.loading = false
                         })
                    }
                });
            }

        },
        showUploadWarranty() {
            this.uploadWarranty = true;
        },
        clearFileAttached() {
            this.attachedFiles = null;
            this.$refs.fileUpload.reset();
        },
        closeUploadWarranty(){
            this.uploadWarranty = false;
            this.clearCameraCapture()
            this.clearFileAttached(confirm)
        },

        repairStatusColor() {
            if(this.itemRepairStatus == 'Repaired' || this.itemRepairStatus == 'Credit-Memo' || this.itemRepairStatus == 'Replaced') {
                return 'green';
            } else if(this.itemRepairStatus == 'No-Problem-Found' || this.itemRepairStatus == 'Repair-w/o-Parts' || this.itemRepairStatus == 'Return-w/o-Repair') {
                return 'blue';
            } else {
                return 'red';
            }
        },

        showRepairStatus() {
            this.editRepairStatus = this.itemRepairStatus
            this.repairStatusInfoDialog = true;
        },

        closeRepairStatus() {
            this.repairStatusInfoDialog = false;
        },

        updateRepairStatus() {
            this.returnSpOnFlight = true;
            swal.fire({
                title: 'Are you sure you want to edit repair status?',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(confirm=>{
                if(confirm.isConfirmed){
                    let payload = {
                    rfId: this.rf.id,
                    repairStatus: this.editRepairStatus,
                }
                this.$store.dispatch('updateRepairStatus', payload).then(response=>{
                    this.closeTechRfEstDialog();
                    this.closeRepairStatus();
                    swal.fire("Success", '', "success")
                    this.$emit('successStartRepair', true);
                    this.returnSpOnFlight = false;
                }).catch(err=>{
                    console.log(err)
                    swal.fire('Failed!','','error')
                    this.loading = false
                    })
                }
            });
        },
        confirmRepairedDate() {
            const dateBeforeThreeDays = this.$dayjs().subtract(2, 'day').format('YYYY-MM-DD');
            if (this.$dayjs(this.repairedDate).isBefore(dateBeforeThreeDays, 'D')) {
                this.overrideRepairedDate.override_dialog = true;
            } else {
                this.readyForQaTester();
            }
        },
        closeRepairedDateDialog() {
            this.repairedDateDialog = false;
            this.repairedDate = this.$dayjs().format('YYYY-MM-DD');
        },
        dateToday() {
            return this.$dayjs().format('YYYY-MM-DD');
        },
        closeConfirmOverrideRepairedDate(user_id, authenticated = false) {
            if (authenticated) {
                this.overrideRepairedDate.override_dialog = false;
                this.repairedDateReasonDialog = true;
            } else {
                this.overrideRepairedDate.override_dialog = false;
            }
        },
        submitRepairedDateReason() {
            if (this.reasonForRepairedDate.trim().length > 0) {
                this.readyForQaTester(this.reasonForRepairedDate);
            } else {
                swal.fire('', '- Please input a reason.', 'info')
            }
        },
        revertRfStatus(status) {
            swal.fire({
                title: 'Are you sure you want to revert RF status to PIN?',
                text: 'Click "Confirm" to proceed',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton:  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
            }).then(async confirm => {
                if(confirm.isConfirmed) {
                    this.loading = true
                    this.$store.dispatch('servicePost', {rfId:this.rf.id,status:status,url:'revert-status'}).then( res => {
                        let message = res.data.message;
                        swal.fire(message, '', message.toLowerCase());
                        this.closeTechRfEstDialog();
                        this.$emit('successStartRepair', true);
                        this.loading = false;
                    }).catch(err => {
                        console.log(err);
                        this.loading = false;
                    });
                }
            });
        },
        returnDefectiveSp() {
            swal.fire({
                title: 'Are you sure you want to request for parts change?',
                text: 'Click "Confirm" to proceed',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton:  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
            }).then(async confirm => {
                if (confirm.isConfirmed) {
                    this.changePartLoading = true;
                    let selectedItems = this.rfPartsOrderItems.filter( e => e.checked && e.return_qty > 0).map(e => {
                        return {
                            id: e.id,
                            item_id: e.item_id,
                            quantity: e.return_qty,
                        }
                    })
                    let payload = {
                        rfId: this.rf.id,
                        items: selectedItems,
                        url:'sc-return-receipts'
                    }
                    this.$store.dispatch('servicePost', payload).then( response => {
                        swal.fire(response.data);
                        this.changePartLoading = false;
                        this.closeTechRfEstDialog();
                        this.$emit('successStartRepair', true);
                    }).catch( e => {
                        console.log(e);
                        this.changePartLoading = false;
                    })
                }

            })
        },
        itemRowBackground(item){
            if (this.rfStatus == 'PIN') {
                if(item.return_damage_part == 1){
                    return 'red lighten-4';
                }
            }
        },
        checkReturnDamagePart() {
            let damagedItems = this.rfPartsOrderItems.filter( e => e.return_damage_part == 1);
            if (damagedItems.length > 0) {
                return true;
            }
            return false;
        }
    },
}
</script>

<style scoped>
.v-input--selection-controls {
    margin-top: 0px;
    padding-top: 0px;
}

</style>
