<template>
    <v-card class="">
        <v-card-title>
            <v-spacer />
                <v-col lg="3">
                    <v-text-field
                    v-model="search"
                    label="Search"
                    single-line
                    hide-details
                    clearable
                >
                </v-text-field>
                <v-icon>mdi-magnify</v-icon>
                </v-col>
        </v-card-title>
        <v-card-text>
            <v-data-table
                :headers="headers"
                :items="technicians"
                :loading="loading"
            >
                <template v-slot:item.specialties="{ item }">
                    {{ convertToString(item) }}
                </template>

                <template v-slot:item.action="{ item }">
                    <td class="px-0">
                        <span class="text-nonwrap">
                            <v-btn text icon color="orange">
                                <v-icon class="btn-action" small @click="showTechnician(item)">mdi-eye</v-icon>
                            </v-btn>
                            <v-btn text icon color="blue">
                                <v-icon class="btn-action" small @click="editTechnician(item)">mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn text icon color="red">
                                <v-icon class="btn-action" small @click="deleteTechnician(item)">mdi-delete</v-icon>
                            </v-btn>
                        </span>
                    </td>
                </template>
            </v-data-table>
        </v-card-text>

        <v-dialog v-model="technicianDetailsDialog" persistent max-width="30%">
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Technician Details</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeTechDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider class="mt-0" />

                <div class="px-4 pb-4">
                    <div>Name: <span>{{ name }}</span></div>
                    <div>Specialties:<span>{{ specialties }}</span></div>
                    <div>Skills:<span>{{ skills }}</span></div>
                    <div>User Name:<span>{{ userName }}</span></div>
                </div>

            </v-card>
        </v-dialog>

        <TechnicianProfileDialogComponentVue :id="id" ref="techDialog" @closeTechProfile="closeTechProfile"/>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import TechnicianProfileDialogComponentVue from '../TechnicianManagerComponent/TechnicianProfileDialogComponent.vue';
import swal from 'sweetalert2';
export default {
    data() {
        return {
            search: '',
            headers: [
                { text: 'Name', value: 'name' },
                { text: 'Specialties', value: 'specialties' },
                { text: 'Skills', value: 'skills' },
                { text: 'Action', value: 'action' },
            ],
            technicians: [],
            loading: true,
            technicianId: '',
            technicianDetailsDialog: false,

            name: '',
            specialties: '',
            skills: '',
            userName: '',
            id: ''
        }
    },
    async mounted() {
        await this.getTechnicians();
    },
    components: {
        TechnicianProfileDialogComponentVue
    },
    computed: {
        ...mapGetters([
            'GET_TECHNICIANS'
        ]),
        addEditSuccess() {
            return this.$store.state.service.technician.success;
        }
    },
    watch: {
        technicians() {
            if(this.technicians.length > 0) {
                this.loading = false;
            }
        },
        addEditSuccess() {
            if(this.addEditSuccess) {
                this.getTechnicians();
            }
        }
    },
    methods:{
        getTechnicians() {

            this.$store.dispatch('getTechnicians').then(response=>{
                this.technicians = this.GET_TECHNICIANS;
            });
        },
        convertToString(item) {
            if(!!item) {
                return JSON.parse(item.specialties).toString().replaceAll(',', ', ');
            }

            return '';
        },
        editTechnician(item) {
            this.$store.commit('setTechnicianId', item.id);
            this.$store.commit('showTechnicianDialog');
        },
        deleteTechnician(item) {
            swal.fire({
                title: 'Are you sure you want to delete technician?',
                text: 'Make sure that all information are correct',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then((result)=>{
                if(result.isConfirmed) {

                    this.$store.dispatch('deleteTechnician',item.id).then(response=>{
                        if(response.data.error) {
                            swal.fire("", response.data.error, "error");
                        } else {
                            this.getTechnicians();
                            swal.fire("", response.data.msg, "success");
                        }
                    });
                }
            });
        },
        showTechnician(item) {
            this.name = item.name;
            this.specialties = JSON.parse(item.specialties).toString().replaceAll(',',', ');
            this.skills = item.skills;
            this.userName = item.user_name;

            // this.technicianDetailsDialog = true;
            this.id = item.id;
            this.$refs.techDialog.showTechProfileDialog();
        },
        closeTechDialog() {
            this.name = '';
            this.specialties = '';
            this.skills = '';
            this.userName = '';

            this.technicianDetailsDialog = false;
        },
        closeTechProfile(confirm) {
            if(confirm) {
                this.id = '';
            }
        },
    }
}
</script>

<style>

</style>
