<template>
<v-row >
    <div class="col-lg-4 col-5 m-4" v-if="visible">
        <v-card elevation="1" >
            <v-progress-linear
                v-if="load"
                indeterminate
                color="green"
            ></v-progress-linear>
            <v-card-text>
                <v-row class="ma-0">
                    <v-col cols="8">
                        <h6 class="text-gray-dark"> <b>Sales Target for {{currentDate}}</b></h6>
                    </v-col>
                </v-row>
                <v-row class="ma-0">
                    <v-col cols="8">
                        <h6>Target: {{target}}</h6>
                        <h6>Running: {{running}}</h6>
                        <h6>Remaining: {{remaining}}</h6>
                    </v-col>
                </v-row>
                <v-row class="row">
                    <v-col cols="12">
                    <apexchart
                        type="donut"
                        height="300"
                        :options="chartOptions"
                        :series="chartOptions.series"
                    >
                    </apexchart>
                    </v-col>
                </v-row>

                <div class=" d-flex justify-content-end ">
                        <v-btn text @click="openCustomerTarget=true">See All <v-icon>mdi-arrow-right-circle</v-icon> </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </div>

    <div class="col-lg-5" v-if="showDms">
        <daily-management-system-view-component :show='showDms'></daily-management-system-view-component>
    </div>

    <v-app>
        <v-dialog v-model="openCustomerTarget">
            <v-row class="ma-0" style="background-color:#f49e17">
                    <v-col cols="8" class="d-flex mt-2 justify-content-start">
                        <v-col cols="6" class="mx-2 text-white"> <h2>Customer Sales Targets {{currentDate}}</h2></v-col>
                    </v-col>
                <v-col cols="4" class="pull-right-10">
                    <v-btn text icon color="gray" class="float-right pull-right" @click="openCustomerTarget = false">
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

                <v-tabs background-color="#f49e17">
                    <v-tab dark style="background-color:#f49e17" class="text-light"><b>By Customer</b></v-tab>
                    <v-tab dark style="background-color:#f49e17" class="text-light"><b>By Classification</b></v-tab>
                    <v-tab dark style="background-color:#f49e17" class="text-light"><b>By Concept Store</b></v-tab>
                    <v-tab-item>
                        <v-card>
                            <v-card-title class="text-h5 heading">
                                <v-col class=" col-12 d-flex justify-content-around align-items-end">
                                    <div class="col-2 mx-2"></div>
                                    <div class=" col-2 d-flex justify-content-center text-white">Monthly</div>
                                    <div class=" col-2 d-flex justify-content-center text-white">Quarterly</div>
                                    <div class=" col-2 d-flex justify-content-center text-white">Semi Annual</div>
                                    <!-- <div class=" col-2 d-flex justify-content-center text-white">Annual</div> -->
                                </v-col>
                            </v-card-title>
                            <v-card-text>
                                <template>
                                    <v-data-table
                                        :headers="customerTableHeaders"
                                        :items="customerList"
                                        class="elevation-1"
                                        :calculate-widths=true
                                    >
                                    </v-data-table>
                                </template>
                            </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                        </v-card>
                    </v-tab-item>

                    <v-tab-item>
                            <v-card>
                            <v-card-title class="text-h5 heading">
                                <v-col class=" col-12 d-flex justify-content-around align-items-end">
                                    <div class="col-2 mx-2"></div>
                                    <div class=" col-2 d-flex justify-content-center text-white">Monthly</div>
                                    <div class=" col-2 d-flex justify-content-center text-white">Quarterly</div>
                                    <div class=" col-2 d-flex justify-content-center text-white">Semi Annual</div>
                                    <!-- <div class=" col-2 d-flex justify-content-center text-white">Annual</div> -->
                                </v-col>
                            </v-card-title>
                            <v-card-text>
                                <template>
                                    <v-data-table
                                        :headers="ClassificationTableHeader"
                                        :items="classificationList"
                                        class="elevation-1"
                                    >
                                    </v-data-table>
                                </template>
                            </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                        </v-card>
                    </v-tab-item>

                    <v-tab-item>
                            <v-card>
                            <v-card-title class="text-h5 heading">
                                <v-col class=" col-12 d-flex justify-content-around align-items-end">
                                    <div class="col-2 mx-2"></div>
                                    <div class=" col-2 d-flex justify-content-center text-white">Monthly</div>
                                    <div class=" col-2 d-flex justify-content-center text-white">Quarterly</div>
                                    <div class=" col-2 d-flex justify-content-center text-white">Semi Annual</div>
                                    <!-- <div class=" col-2 d-flex justify-content-center text-white">Annual</div> -->
                                </v-col>
                            </v-card-title>
                            <v-card-text>
                                <template>
                                    <v-data-table
                                        :headers="customerTableHeaders"
                                        :items="conceptStoreList"
                                        class="elevation-1"
                                    >
                                    </v-data-table>
                                </template>
                            </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                        </v-card>
                    </v-tab-item>
                </v-tabs>
        </v-dialog>
    </v-app>
</v-row>
</template>

<script>
import { mounted } from 'qrcode.vue'
import { mapGetters } from 'vuex';
// import SharedFunctionsComponent from '../Shared/SharedFunctionsComponent.vue'
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue'

export default {
    mixins: [ SharedFunctionsComponentVue ],
    data(){
        return {
            currentDate: this.$dayjs().format('YYYY'),
            targetSalesTotal:{
                monthly:0,
                quarterly:0,
                semiAnnual:0,
                annual:0
            },
            showDms:false,
            running:0,
            visible:false,
            target:0,
            remaining:0,
            chartOptions: {
            series: [],
            labels:[],
            chart: {
                type: 'donut',
            },
                tooltip: {
                style: '20px',
                y: {
                    formatter: (val) => {
                        if (val !== '' || val !== undefined || val !== 0 || val !== '0' || val !== null) {
                            return val.toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                        } else {
                            return 0
                        }
                    },
                },
            },

            responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                width: 200,
                },
                legend: {
                    fontSize:'100px',
                        tooltipHoverFormatter: function(val, opts) {
                            return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
                    },
                }
                }
            }]
        },
            load:false,
            openCustomerTarget:false,

            conceptStoreList:[],
            classificationList:[],
            customerList:[],
            customerTableHeaders:[
                { text:'Customer', align:'start',width:'20%',value:'name'},

                { text:'Target Sales', align:'end',value:'monthlyTargetSales'},
                { text:'Actual Sales', align:'center',value:'monthlyActual'},
                { text:'Percent', align:'start',value:'monthlyPercent'},

                { text:'Target Sales', align:'end',value:'quarterlyTargetSales'},
                { text:'Actual Sales', align:'center',value:'quarterlyActual'},
                { text:'Percent', align:'start',value:'quarterlyPercent'},

                { text:'Target Sales', align:'end',value:'semiAnnualTargetSales'},
                { text:'Actual Sales', align:'center',value:'semiAnnualActual'},
                { text:'Percent', align:'start',value:'semiAnnualPercent'},

                // { text:'Target Sales', align:'end',value:'annualTargetSales'},
                // { text:'Actual Sales', align:'center',value:'annualActual'},
                // { text:'Percent', align:'start',value:'annualPercent'},
            ],
            ClassificationTableHeader:[
                { text:'Classification', align:'start',width:'20%',value:'name'},

                { text:'Target Sales', align:'end',value:'monthlyTargetSales'},
                { text:'Actual Sales', align:'center',value:'monthlyActual'},
                { text:'Percent', align:'start',value:'monthlyPercent'},

                { text:'Target Sales', align:'end',value:'quarterlyTargetSales'},
                { text:'Actual Sales', align:'center',value:'quarterlyActual'},
                { text:'Percent', align:'start',value:'quarterlyPercent'},

                { text:'Target Sales', align:'end',value:'semiAnnualTargetSales'},
                { text:'Actual Sales', align:'center',value:'semiAnnualActual'},
                { text:'Percent', align:'start',value:'semiAnnualPercent'},

                // { text:'Target Sales', align:'end',value:'annualTargetSales'},
                // { text:'Actual Sales', align:'center',value:'annualActual'},
                // { text:'Percent', align:'start',value:'annualPercent'},
            ],

            selectedOption:2,
            filterOption:
                [
                    { value: 1, text: 'Past Week' },
                    { value: 2, text: 'Past Month' },
                    { value: 3, text: 'Past Quarter'},
                    { value: 4, text: 'Custom' },
                ],
            items: [],
            newItemsHeaders: [
                { text: 'Image', align: 'start', value: 'image_path'},
                { text: 'Model', align: 'start', value: 'model_name', },
                { text: 'Name', align: 'start', value: 'item_name', },
                { text: 'Type', align: 'start', value: 'type_name' },
                { text: 'Category', align: 'start', value: 'category_name', },
                { text: 'SRP', align: 'end', value: 'srp', },
                { text: 'Qty Arrived (UOM)', align: 'end', value: 'quantity_arrived', },
            ],
            no_image:'/images/noimage.png',
            custom_date: false,
            date_from: this.$dayjs().startOf('month').format('YYYY-MM-DD'),
            date_to: this.$dayjs().endOf('month').format('YYYY-MM-DD'),
            menu2: false,
            menu3: false,
            errors: [],

            options: {},
            totalItems: 0,
            itemsPerPageOptions: [5, 10, 15, 50, 100, 250, 500, 1000],
            itemsPerPage: 10,
            loading: false,
        }
    },
    computed:{
        ...mapGetters([
            'GET_ITEM_TYPES',
            'GET_ITEM_CATEGORIES',
        ])
    },
    methods:{
        getCustomerSalesTarget(){
            this.load=true
            this.$store.dispatch('getSalesTarget',{

            }).then(response=>{
                this.visible = response.data.visible
                if(response.status == 200){
                    this.customerList = response.data.customer
                    this.chartOptions = {
                        labels:response.data.pieChart.labels,
                        series:response.data.pieChart.series
                    }
                    this.target = response.data.pieChart.target;
                    this.running = response.data.pieChart.running;
                    this.remaining = response.data.pieChart.remaining;
                    this.load = false;
                }
                else{
                    swal('An Error occured','','error')
                }
            })
        },

        getClassificationsSalesTarget(){
            this.load=true
            this.$store.dispatch('getCustomerClassificationSalesTarget',{
            }).then(response=>{
                if(response.status==200){
                this.classificationList=response.data.customer
                this.load=false;
                }
                else{
                    swal('An Error occured','','error')
                }
            })

        },

        getConceptStoreSalesTarget(){
            this.load=true
            this.$store.dispatch('getConceptStoreSalesTarget').then(response=>{
                if(response.status==200){
                this.conceptStoreList=response.data.customer
                this.load=false;
                }
                else{
                    swal('An Error occured','','error')
                }
            })
        },

        list(page = 1){
            let that = this
            that.load = true

            this.$store.dispatch('list',{
                salesmanIds :[]
            }).then(response=>{
                if(response.data.data != 'error'){
                    that.showDms = true
                }
                else{
                    that.showDms = false
                }
            })
        },
        checkImagePath(item) {
            return item.image_path == null
                ? process.env.VUE_APP_API_HOST + "/images/items/no-image.png"
                : process.env.VUE_APP_API_HOST + "/" + item.image_path;
        },
        checkImage(item) {
            item.image_path = process.env.VUE_APP_API_HOST + "/images/items/no-image.png";
            return item.image_path;
        },
        upperCaseFirstLetter(str) {
            const arr = str.split(" ");
            for (var i = 0; i < arr.length; i++) {
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
            }
            str = arr.join(" ");
            return str
        },

    },
    mounted:function(){
        this.$store.dispatch('getItemTypes');
        this.$store.dispatch('getItemCategories');
        this.getCustomerSalesTarget();
        this.getClassificationsSalesTarget();
        this.getConceptStoreSalesTarget();
        this.list();
    },
}
</script>
<style scoped>
.heading{
    background-color: #f49e17 !important;
}
.card-actions {
    position: absolute;
    bottom: 0;
    right: 0;
}
</style>
