<template>
    <div class="w-100">
        <v-app id="my_vue_app">
            <v-card>
                <v-row no-gutters class="">
                    <v-col cols="12" sm="12" md="3" class="p-1">
                        <v-autocomplete
                            v-model="filters.customer_id"
                            :items="GET_CUSTOMERS_LIST_SELECTION"
                            :loading="!GET_CUSTOMERS_LIST_SELECTION"
                            :disabled="!GET_CUSTOMERS_LIST_SELECTION"
                            label="Customer"
                            outlined
                            hide-details
                            persistent-placeholder
                            auto-select-first
                            cache-items
                            allow-overflow
                            @input="filter()"
                        >
                            <template v-slot:selection="{ item }">
                                <span class="dropdown-selection">
                                    {{ item.text }}
                                </span>
                            </template>
                            <template v-slot:item="{ item }">
                                <span class="dropdown-items">
                                    {{ item.text }}
                                </span>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="12" sm="12" md="2" class="p-1 align-items-end">
                        <v-text-field
                            v-model="filters.general"
                            label="Search SO#"
                            append-outer-icon="mdi-magnify"
                            single-line
                            hide-details
                            @click:append-outer="filter()"
                            @keydown.enter="filter"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters class="mx-2">
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <v-sheet
                            tile
                            height="54"
                            class="d-flex"
                        >
                            <v-btn
                                icon
                                class="mx-1"
                                @click="calendarPrev()"
                            >
                                <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>
                            <v-btn
                                icon
                                class="ml-1 mr-4"
                                @click="calendarNext()"
                            >
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>
                            <v-btn
                                @click="setToday"
                            >
                                Today
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-toolbar-title v-if="tab == 'wpf' && $refs.wpf_calendar">
                                {{ currentDay }}
                            </v-toolbar-title>
                            <v-toolbar-title v-else-if="tab == 'cf' && $refs.cf_calendar">
                                {{ currentDay }}
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-menu bottom right>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on">
                                        <span v-if="tab == 'wpf'">{{ wpf_calendar.calendar_view.value| uppercase }}</span>
                                        <span v-else-if="tab == 'cf'">{{ cf_calendar.calendar_view.value| uppercase }}</span>
                                        <v-icon right>mdi-menu-down</v-icon>
                                    </v-btn>
                                </template>
                                <v-list v-if="tab == 'wpf'">
                                    <v-list-item v-for="calendar_view in wpf_calendar.calendar_view.list" :key="calendar_view" @click="wpf_calendar.calendar_view.value = calendar_view">
                                        <v-list-item-title>{{ calendar_view | capitalize }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                                <v-list v-else-if="tab == 'cf'">
                                    <v-list-item v-for="calendar_view in cf_calendar.calendar_view.list" :key="calendar_view" @click="cf_calendar.calendar_view.value = calendar_view">
                                        <v-list-item-title>{{ calendar_view | capitalize }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-sheet>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <v-sheet v-if="tab == 'wpf'">
                            <v-calendar
                                ref="wpf_calendar"
                                v-model="wpf_calendar.value"
                                :weekdays="wpf_calendar.week_view.value"
                                :type="wpf_calendar.calendar_view.value"
                                :events="wpf_calendar.events"
                                :event-overlap-mode="wpf_calendar.overlap_mode"
                                :event-overlap-threshold="wpf_calendar.overlap_threshold"
                                :event-color="getEventColor"
                                :interval-height="96"
                                :interval-minutes="30"
                                dark
                                @change="getEvents"
                                @click:event="showWpfs"
                                @click:more="viewDay"
                                @click:date="viewDay"
                            ></v-calendar>
                        </v-sheet>
                        <v-sheet v-else-if="tab == 'cf'">
                            <v-calendar
                                ref="cf_calendar"
                                v-model="cf_calendar.value"
                                :weekdays="cf_calendar.week_view.value"
                                :type="cf_calendar.calendar_view.value"
                                :events="cf_calendar.events"
                                :event-overlap-mode="cf_calendar.overlap_mode"
                                :event-overlap-threshold="cf_calendar.overlap_threshold"
                                :event-color="getEventColor"
                                :interval-height="96"
                                :interval-minutes="30"
                                dark
                                @change="getEvents"
                                @click:event="showCfs"
                                @click:more="viewDay"
                                @click:date="viewDay"
                            ></v-calendar>
                        </v-sheet>
                    </v-col>
                </v-row>
                <v-dialog v-if="tab == 'wpf'" :width="$vuetify.breakpoint.mobile ? '100%' : '90%'" v-model="view_wpfs.show">
                    <v-card height="100%">
                        <v-row no-gutters>
                            <v-col cols="10">
                                <v-card-title>
                                    {{ `${view_wpfs.parameters.date} WPFs of ${view_wpfs.parameters.customer_name}` }}
                                </v-card-title>
                            </v-col>
                            <v-col cols="2" class="pull-right">
                                <v-btn text icon color="gray" class="float-right" @click="toggle(false)">
                                    <v-icon>mdi-close-circle</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-divider class="ma-1 pa-0"></v-divider>
                        <v-row no-gutters>
                            <!-- <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="px-2">
                                <div class="info-box">
                                    <span class="info-box-icon bg-success elevation-1"><i class="fas fa-shopping-cart"></i></span>
                                    <div class="info-box-content">
                                        <span class="info-box-text">SO Total Amount</span>
                                        <span class="info-box-number">&#x20b1;{{ soTotalAmount | currency }}</span>
                                    </div>
                                </div>
                            </v-col> -->
                            <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="px-2">
                                <v-card
                                    class="total-cards"
                                    elevation="2"
                                >
                                    <v-card-text
                                        class="d-flex justify-content-start align-items-center">
                                        <div class="card-icon pt-3 pb-3 pl-2 pr-2 rounded" style="background-color: #28a745;">
                                            <v-icon class="text-white" style="font-size:40px">mdi-cart</v-icon>
                                        </div>
                                        <div class="card-details ml-2">
                                            <p class="mb-0">Total Item CBM</p>
                                            <b style="color:black">{{totalItemCbm | currency}}</b>
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="px-2">
                                <v-card
                                    class="total-cards"
                                    elevation="2"
                                >
                                    <v-card-text
                                        class="d-flex justify-content-start align-items-center">
                                        <div class="card-icon pt-3 pb-3 pl-2 pr-2 rounded" style="background-color: #28a745;">
                                            <v-icon class="text-white" style="font-size:40px">mdi-cart</v-icon>
                                        </div>
                                        <div class="card-details ml-2">
                                            <p class="mb-0">Total Estimate CBM</p>
                                            <b style="color:black">{{totalEstimateCbm | currency}}</b>
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="px-2">
                                <v-card
                                    class="total-cards"
                                    elevation="2"
                                >
                                    <v-card-text
                                        class="d-flex justify-content-start align-items-center">
                                        <div class="card-icon pt-3 pb-3 pl-2 pr-2 rounded" style="background-color: #28a745;">
                                            <v-icon class="text-white" style="font-size:40px">mdi-cart</v-icon>
                                        </div>
                                        <div class="card-details ml-2">
                                            <p class="mb-0">Total Weight (kgs)</p>
                                            <b style="color:black">{{totalWeight | currency}}</b>
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="px-2 d-flex flex-row-reverse align-items-end">
                                <span class="px-1">
                                    <v-btn @click="massUpdate()">All</v-btn>
                                </span>
                                <span class="px-1">
                                    <v-datetime-picker
                                        v-model="view_wpfs.updates.prepare_date"
                                        date-format="yyyy-MM-dd"
                                        time-format="HH:mm"
                                        :text-field-props="{
                                            readonly: true,
                                            outlined: true,
                                            dense: true,
                                            hideDetails: true,
                                            appendIcon: 'mdi-calendar',
                                            persistentPlaceholder: true,
                                            label:'Current Prepare Date',
                                        }"
                                    ></v-datetime-picker>
                                </span>
                            </v-col>
                        </v-row>
                        </v-row>
                        <v-divider class="ma-1 pa-0"></v-divider>
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                <v-data-table
                                    :headers="view_wpfs.headers"
                                    :items="view_wpfs.list"
                                    :loading="view_wpfs.loading"
                                    :options.sync="view_wpfs.options"
                                    :server-items-length="view_wpfs.total_records"
                                    :items-per-page="view_wpfs.items_per_page"
                                    :footer-props="{
                                        itemsPerPageOptions: view_wpfs.items_per_page_options
                                    }"
                                    :disable-sort="$vuetify.breakpoint.mobile"
                                    fixed-header
                                >
                                    <template v-slot:[`item.so_total_amount`]="{ item }">
                                        {{ item.so_total_amount | currency }}
                                    </template>
                                    <template v-slot:[`item.total_cbm`]="{ item }">
                                        {{ item.total_cbm | currency }}
                                    </template>
                                    <template v-slot:[`item.total_estimate_cbm`]="{ item }">
                                        {{ item.total_estimate_cbm | currency }}
                                    </template>
                                    <template v-slot:[`item.total_weight`]="{ item }">
                                        {{ item.total_weight | currency }}
                                    </template>
                                    <template v-slot:[`item.pl_confirmed`]="{ item }">
                                        <span v-if="item.pl_confirmed !== null">
                                            <span v-for="(value, index) in item.pl_confirmed.split(', ')" :key="index">
                                                <span class="badge badge-primary" v-if="value===1">Yes</span>
                                                <span class="badge badge-danger" v-else>No</span>
                                            </span>
                                        </span>
                                    </template>
                                    <template v-slot:[`item.wp_list_id`]="{ item }">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{on, attrs}">
                                                <span v-bind="attrs" v-on="on">{{ item.wp_list_id }}</span>
                                            </template>
                                            <center>{{ item.wpf_created_at }}</center>
                                        </v-tooltip>
                                    </template>
                                    <template v-slot:[`item.prepare_comment`]="{ item }">
                                        <v-textarea
                                            v-model="item.prepare_comment"
                                            :disabled="item.in_cf != null"
                                            :auto-grow="true"
                                            rows="1"
                                            outlined
                                            dense
                                            hide-details
                                        >
                                        </v-textarea>
                                    </template>
                                    <template v-slot:[`item.current_prepare_date`]="{ item }">
                                        <v-datetime-picker
                                            v-model="item.current_prepare_date"
                                            date-format="yyyy-MM-dd"
                                            time-format="HH:mm"
                                            :text-field-props="{
                                                readonly: true,
                                                outlined: true,
                                                dense: true,
                                                hideDetails: true,
                                                appendIcon: 'mdi-calendar'
                                            }"
                                            :disabled="item.in_cf != null"
                                        ></v-datetime-picker>
                                    </template>
                                    <template v-slot:[`item.status_text`]="{ item }">
                                        <span class="badge badge-preparing" v-if="item.status_text=='preparing'">{{ item.status_text }}</span>
                                        <span class="badge badge-primary" v-else-if="item.status_text=='prepared'">{{ item.status_text }}</span>
                                        <span class="badge badge-warning" v-else-if="item.status_text=='rejected'">{{ item.status_text }}</span>
                                        <span class="badge badge-danger" v-else-if="item.status_text=='canceled'">{{ item.status_text }}</span>
                                        <span class="badge badge-dark" v-else-if="item.status_text=='deleted'">{{ item.status_text }}</span>
                                        <span class="badge badge-success" v-else-if="item.status_text=='completed'">{{ item.status_text }}</span>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    text
                                                    icon
                                                    color="orange"
                                                    @click="viewWpf(item.wp_list_id)"
                                                >
                                                    <v-icon class="btn-action" small>mdi-eye</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>View WPF</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    text
                                                    icon
                                                    @click="set(item, true)"
                                                >
                                                    <v-icon small color="blue">mdi-pencil</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Update</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-dialog>
                <v-dialog v-else-if="tab == 'cf'" :width="$vuetify.breakpoint.mobile ? '100%' : '90%'" v-model="view_cfs.show">
                    <v-card height="100%">
                        <v-row no-gutters>
                            <v-col cols="10">
                                <v-card-title>
                                    {{ `${view_cfs.parameters.date} CFs of ${view_cfs.parameters.customer_name}` }}
                                </v-card-title>
                            </v-col>
                            <v-col cols="2" class="pull-right">
                                <v-btn text icon color="gray" class="float-right" @click="toggle(false)">
                                    <v-icon>mdi-close-circle</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-divider class="ma-1 pa-0"></v-divider>
                        <v-row no-gutters>
                            <!-- <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="px-2">
                                <div class="info-box">
                                    <span class="info-box-icon bg-success elevation-1"><i class="fas fa-shopping-cart"></i></span>
                                    <div class="info-box-content">
                                        <span class="info-box-text">SO Total Amount</span>
                                        <span class="info-box-number">&#x20b1;{{ soTotalAmount | currency }}</span>
                                    </div>
                                </div>
                            </v-col> -->
                            <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="px-2">
                                <v-card
                                    class="total-cards"
                                    elevation="2"
                                >
                                    <v-card-text
                                        class="d-flex justify-content-start align-items-center">
                                        <div class="card-icon pt-3 pb-3 pl-2 pr-2 rounded" style="background-color: #28a745;">
                                            <v-icon class="text-white" style="font-size:40px">mdi-cart</v-icon>
                                        </div>
                                        <div class="card-details ml-2">
                                            <p class="mb-0">Total Item CBM</p>
                                            <b style="color:black">{{totalItemCbm | currency}}</b>
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="px-2">
                                <v-card
                                    class="total-cards"
                                    elevation="2"
                                >
                                    <v-card-text
                                        class="d-flex justify-content-start align-items-center">
                                        <div class="card-icon pt-3 pb-3 pl-2 pr-2 rounded" style="background-color: #28a745;">
                                            <v-icon class="text-white" style="font-size:40px">mdi-cart</v-icon>
                                        </div>
                                        <div class="card-details ml-2">
                                            <p class="mb-0">Total Estimate CBM</p>
                                            <b style="color:black">{{totalEstimateCbm | currency}}</b>
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="px-2">
                                <v-card
                                    class="total-cards"
                                    elevation="2"
                                >
                                    <v-card-text
                                        class="d-flex justify-content-start align-items-center">
                                        <div class="card-icon pt-3 pb-3 pl-2 pr-2 rounded" style="background-color: #28a745;">
                                            <v-icon class="text-white" style="font-size:40px">mdi-cart</v-icon>
                                        </div>
                                        <div class="card-details ml-2">
                                            <p class="mb-0">Total Weight (kgs)</p>
                                            <b style="color:black">{{totalWeight | currency}}</b>
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-spacer></v-spacer>
                        </v-row>
                        <v-divider class="ma-1 pa-0"></v-divider>
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                <v-data-table
                                    :headers="view_cfs.headers"
                                    :items="view_cfs.list"
                                    :loading="view_cfs.loading"
                                    :options.sync="view_cfs.options"
                                    :server-items-length="view_cfs.total_records"
                                    :items-per-page="view_cfs.items_per_page"
                                    :footer-props="{
                                        itemsPerPageOptions: view_cfs.items_per_page_options
                                    }"
                                    :disable-sort="$vuetify.breakpoint.mobile"
                                    fixed-header
                                >
                                    <!-- <template v-slot:[`item.so_total_amount`]="{ item }">
                                        {{ item.so_total_amount | currency }}
                                    </template> -->
                                    <template v-slot:[`item.total_cbm`]="{ item }">
                                        {{ item.total_cbm | currency }}
                                    </template>
                                    <template v-slot:[`item.total_estimate_cbm`]="{ item }">
                                        {{ item.total_estimate_cbm | currency }}
                                    </template>
                                    <template v-slot:[`item.total_weight`]="{ item }">
                                        {{ item.total_weight | currency }}
                                    </template>
                                    <template v-slot:[`item.pl_confirmed`]="{ item }">
                                        <span v-if="item.pl_confirmed !== null">
                                            <span v-for="(value, index) in item.pl_confirmed.split(', ')" :key="index">
                                                <span class="badge badge-primary" v-if="value===1">Yes</span>
                                                <span class="badge badge-danger" v-else>No</span>
                                            </span>
                                        </span>
                                    </template>
                                    <template v-slot:[`item.checker_form_id`]="{ item }">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{on, attrs}">
                                                <span v-bind="attrs" v-on="on">{{ item.checker_form_id }}</span>
                                            </template>
                                            <center>{{ item.cf_created_at }}</center>
                                        </v-tooltip>
                                    </template>
                                    <template v-slot:[`item.status_text`]="{ item }">
                                        <span class="badge badge-primary" v-if="item.status_text=='checking'">{{ item.status_text }}</span>
                                        <span class="badge badge-purple" v-else-if="item.status_text=='checked'">{{ item.status_text }}</span>
                                        <span class="badge badge-warning" v-else-if="item.status_text=='rejected'">{{ item.status_text }}</span>
                                        <span class="badge badge-danger" v-else-if="item.status_text=='canceled'">{{ item.status_text }}</span>
                                        <span class="badge badge-dark" v-else-if="item.status_text=='deleted'">{{ item.status_text }}</span>
                                        <span class="badge badge-success" v-else-if="item.status_text=='completed'">{{ item.status_text }}</span>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    text
                                                    icon
                                                    color="orange"
                                                    @click="viewCf(item.checker_form_id)"
                                                >
                                                    <v-icon class="btn-action" small>mdi-eye</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>View CF</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-dialog>
                <WarehousePreparationViewComponentVue
                    :viewWarehousePreparationListId="view_wpfs.wp_list_id"
                    @closeView="closeView">
                </WarehousePreparationViewComponentVue>
                <CheckerFormViewComponentVue
                    :viewCheckerFormId="view_cfs.checker_form_id"
                    @closeView="closeView">
                </CheckerFormViewComponentVue>
            </v-card>
        </v-app>
    </div>
</template>
<script>
// import SharedFunctionsComponent from '../Shared/SharedFunctionsComponent.vue'
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue'
import WarehousePreparationViewComponentVue from '../WPF/WarehousePreparationViewComponent.vue'
import CheckerFormViewComponentVue from '../CF/CheckerFormViewComponent.vue'
import swal from 'sweetalert2'
import { mapGetters } from 'vuex'
export default {
    mixins: [
        SharedFunctionsComponentVue,
    ],
    components: {
        WarehousePreparationViewComponentVue,
        CheckerFormViewComponentVue
    },
    props: [
        'tab',
    ],
    data() {
        return {
            filters: {
                customer_id: null,
                wpf_dates: [
                    this.$dayjs().startOf('year').format('YYYY-MM-DD'),
                    this.$dayjs().endOf('month').format('YYYY-MM-DD'),
                ],
                cf_dates: [
                    this.$dayjs().startOf('year').format('YYYY-MM-DD'),
                    this.$dayjs().endOf('month').format('YYYY-MM-DD'),
                ],
                prepare_date: null,
                general: null,
            },
            list: {
                customer_list: [],
            },
            view_wpfs: {
                show: false,
                parameters: {
                    customer_id: null,
                    customer_name: null,
                    date: null,
                    prepare_date: null,
                    prepare_time: null,
                },
                headers: [
                    { text: 'WPF#', align: 'start', width: '5%', sortable: false, value: 'wp_list_id' },
                    { text: 'SO#', align: 'start', width: '5%', sortable: false, value: 'order_num' },
                    { text: 'Remarks', align: 'start', sortable: false, value: 'remarks' },
                    { text: 'Status', align: 'left', width: '5%', sortable: false, value: 'status_text', },
                    { text: 'Comments', align: 'start', sortable: false, value: 'prepare_comment' },
                    { text: '1st Prep Date', align: 'start', width: '10%', sortable: false, value: 'first_prepare_date' },
                    { text: 'Current Prep Date', align: 'start', width: '15%', sortable: false, value: 'current_prepare_date' },
                    { text: 'Item CBM', align: 'end', width: '5%', sortable: false, value: 'total_cbm' },
                    { text: 'Estimate CBM', align: 'end', width: '5%', sortable: false, value: 'total_estimate_cbm' },
                    { text: 'Item Weight', align: 'end', width: '5%', sortable: false, value: 'total_weight' },
                    // { text: 'WPF Amount', align: 'end', width: '9%', sortable: false, value: 'wpf_total_amount' },
                    // { text: 'SO Amount', align: 'end', width: '9%', sortable: false, value: 'so_total_amount' },
                    { text: 'Actions', align: 'center', width: '10%', sortable: false, value: 'actions' },
                ],
                list: [],
                options: {},
                total_amounts: [],
                loading: false,
                total_records: 0,
                items_per_page: 10,
                items_per_page_options: [10, 20, 50, 100],

                updates: {
                    prepare_date: null,
                },

                wp_list_id: '',
            },
            view_cfs: {
                show: false,
                parameters: {
                    customer_id: null,
                    customer_name: null,
                    date: null,
                },
                headers: [
                    { text: 'CF#', align: 'start', width: '5%', sortable: false, value: 'checker_form_id' },
                    { text: 'WPF#', align: 'start', width: '5%', sortable: false, value: 'wp_list_id' },
                    { text: 'SO#', align: 'start', width: '5%', sortable: false, value: 'order_num' },
                    { text: 'Remarks', align: 'start', sortable: false, value: 'remarks' },
                    { text: 'Status', align: 'left', width: '5%', sortable: false, value: 'status_text', },
                    { text: 'Item CBM', align: 'end', width: '5%', sortable: false, value: 'total_cbm' },
                    { text: 'Estimate CBM', align: 'end', width: '5%', sortable: false, value: 'total_estimate_cbm' },
                    { text: 'Item Weight', align: 'end', width: '5%', sortable: false, value: 'total_weight' },
                    // { text: 'SO Amount', align: 'end', width: '9%', sortable: false, value: 'so_total_amount' },
                    { text: 'Actions', align: 'center', width: '10%', sortable: false, value: 'actions' },
                ],
                list: [],
                options: {},
                total_amounts: [],
                loading: false,
                total_records: 0,
                items_per_page: 10,
                items_per_page_options: [10, 20, 50, 100],

                updates: {
                    prepare_date: null,
                },

                checker_form_id: '',
            },
            wpf_calendar: {
                events: [],
                colors: [],
                value: null,
                start_date: this.$dayjs().startOf('month').format('YYYY-MM-DD HH:mm'),
                end_date: this.$dayjs().endOf('month').format('YYYY-MM-DD HH:mm'),
                overlap_mode: 'column',
                overlap_threshold: 1,
                calendar_view: {
                    value: 'month',
                    list: ['month', 'week', 'day'],
                },
                week_view: {
                    value: [0, 1, 2, 3, 4, 5, 6],
                    list: [
                        { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
                        { text: 'Mon - Sat', value: [1, 2, 3, 4, 5, 6] },
                        { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
                    ],
                },
            },
            cf_calendar: {
                events: [],
                colors: [],
                value: null,
                start_date: this.$dayjs().startOf('month').format('YYYY-MM-DD HH:mm'),
                end_date: this.$dayjs().endOf('month').format('YYYY-MM-DD HH:mm'),
                overlap_mode: 'column',
                overlap_threshold: 1,
                calendar_view: {
                    value: 'month',
                    list: ['month', 'week', 'day'],
                },
                week_view: {
                    value: [0, 1, 2, 3, 4, 5, 6],
                    list: [
                        { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
                        { text: 'Mon - Sat', value: [1, 2, 3, 4, 5, 6] },
                        { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
                    ],
                },
            },
        }
    },
    methods: {
        viewWpf(id){
            this.view_wpfs.wp_list_id = id
        },
        viewCf(id){
            this.view_cfs.checker_form_id = id
        },
        closeView(){
            if (this.tab == 'wpf') {
                this.view_wpfs.wp_list_id = ''
            } else if (this.tab == 'cf') {
                this.view_cfs.checker_form_id = ''
            }
        },
        filter(event = null) {
            if (event !== null) {
                event.preventDefault()
            }

            if (this.tab == 'wpf') {
                if (this.$refs.wpf_calendar) {
                    this.$refs.wpf_calendar.getEvents
                }
            } else if (this.tab == 'cf') {
                if (this.$refs.cf_calendar) {
                    this.$refs.cf_calendar.getEvents
                }
            }
        },
        async view(item){
            this.toggle(true)

            if (this.tab == 'wpf') {
                this.view_wpfs.parameters.customer_id = item.customer_id
                this.view_wpfs.parameters.customer_name = item.customer_name
                this.view_wpfs.parameters.date = item.wpf_date
                this.view_wpfs.parameters.prepare_date = item.prepare_date

                await this.getWpfCalendarTransactions()
            } else if (this.tab == 'cf') {
                this.view_cfs.parameters.customer_id = item.customer_id
                this.view_cfs.parameters.customer_name = item.customer_name
                this.view_cfs.parameters.date = item.wpf_date

                await this.getCfCalendarTransactions()
            }
        },
        async showWpfs(event){
            let item = event.event

            this.toggle(true)

            this.view_wpfs.parameters.customer_id = item.customer_id
            this.view_wpfs.parameters.customer_name = item.name
            this.view_wpfs.parameters.date = item.start
            this.view_wpfs.parameters.prepare_date = item.end

            await this.getWpfCalendarTransactions()

            this.view_wpfs.parameters.date = this.$dayjs(item.start).format('YYYY-MM-DD')
        },
        async showCfs(event){
            let item = event.event

            this.toggle(true)

            this.view_cfs.parameters.customer_id = item.customer_id
            this.view_cfs.parameters.customer_name = item.name
            this.view_cfs.parameters.date = item.start

            await this.getCfCalendarTransactions()

            this.view_cfs.parameters.date = this.$dayjs(item.start).format('YYYY-MM-DD')
        },
        async getWpfCalendarTransactions(){
            this.view_wpfs.loading = true
            let payload = {
                page: this.view_wpfs.options.page,
                itemsPerPage: this.view_wpfs.options.itemsPerPage,
                customer_id: this.view_wpfs.parameters.customer_id,
                dates: this.view_wpfs.parameters.date,
                prepare_date: this.view_wpfs.parameters.prepare_date,
                url:    'warehouse-calendar/wpf/get'
            }
            await this.$store.dispatch('getCalendar',payload).then(response => {
                this.view_wpfs.list = response.data.wpfs.data
                this.view_wpfs.total_records = response.data.wpfs.total
                this.view_wpfs.loading = false
            }).catch(error => {
                this.view_wpfs.loading = false
            })
        },
        async getCfCalendarTransactions(){
            this.view_cfs.loading = true
            let payload = {
                page: this.view_cfs.options.page,
                itemsPerPage: this.view_cfs.options.itemsPerPage,
                customer_id: this.view_cfs.parameters.customer_id,
                dates: this.view_cfs.parameters.date,
                url:'warehouse-calendar/cf/get'
            }
            await this.$store.dispatch('getCalendar',payload).then(response => {
                this.view_cfs.list = response.data.cfs.data
                this.view_cfs.total_records = response.data.cfs.total
                this.view_cfs.loading = false
            }).catch(error => {
                this.view_cfs.loading = false
            })
        },
        set(item, by_wpf = false){
            if (item.current_prepare_date == null) {
                swal.fire('Must select Prepare Date!', '', 'error')
                return false
            }
            let payload = {
                data: {
                    wpf_date: item.wpf_date,
                    prepare_date: this.$dayjs(item.current_prepare_date).format('YYYY-MM-DD HH:mm'),
                    prepare_comment: item.prepare_comment,
                    wp_list_id: item.wp_list_id,
                    by_wpf: by_wpf,
                    url: 'warehouse-calendar/wpf/set-prepare-date/'+item.customer_id
                }
            }
            this.$store.dispatch('postCalendar',payload).then(response => {
                if (response.data.msg) {
                    this.refreshCalendarData(this.wpf_calendar.start_date, this.wpf_calendar.end_date)
                    this.getWpfCalendarTransactions()
                    swal.fire('Update Succcess!', '', 'success')
                }
            })
        },
        massUpdate(){
            if (this.view_wpfs.updates.prepare_date == null) {
                swal.fire('Must select Prepare Date!', '', 'error')
                return false
            }
            let payload = {
                data: {
                    prepare_date: this.view_wpfs.updates.prepare_date,
                    wpf_ids: this.view_wpfs.list.map(m => { return m.wp_list_id }),
                    url:'warehouse-calendar/wpf/mass-update-prepare-date'
                }
            }
            this.$store.dispatch('postCalendar',payload).then(response => {
                if (response.data.msg) {
                    swal.fire('Update Succcess!', '', 'success').then(confirm => {
                        this.view_wpfs.show = false
                        this.getWpfCalendarTransactions()
                        this.refreshCalendarData(this.wpf_calendar.start_date, this.wpf_calendar.end_date)
                    })
                }
            })
        },
        getEventColor(event){
            return event.color
        },
        getEvents({ start, end }){
            if (this.tab == 'wpf') {
                this.wpf_calendar.start_date = new Date(Date.parse(`${start.date}T00:00:00.00+08:00`.toLocaleString('en-US', 'Asia/Manila')))
                this.wpf_calendar.end_date = new Date(Date.parse(`${end.date}T23:59:59.00+08:00`.toLocaleString('en-US', 'Asia/Manila')))

                this.refreshCalendarData(this.wpf_calendar.start_date, this.wpf_calendar.end_date)
            } else if (this.tab == 'cf') {
                this.cf_calendar.start_date = new Date(Date.parse(`${start.date}T00:00:00.00+08:00`.toLocaleString('en-US', 'Asia/Manila')))
                this.cf_calendar.end_date = new Date(Date.parse(`${end.date}T23:59:59.00+08:00`.toLocaleString('en-US', 'Asia/Manila')))

                this.refreshCalendarData(this.cf_calendar.start_date, this.cf_calendar.end_date)
            }
        },
        refreshCalendarData(start, end){
            if (this.tab == 'wpf') {
                let payload = {
                    min: start,
                    max: end,
                    customer_id: this.filters.customer_id,
                    search_string: this.filters.general,
                    url:'warehouse-calendar/wpf/calendar'
                }
                this.$store.dispatch('getCalendar',payload).then(response => {
                    this.wpf_calendar.events = response.data.scheduled_preparations
                    this.wpf_calendar.colors = response.data.event_colors
                })
            } else if (this.tab == 'cf') {
                let payload = {
                    min: start,
                    max: end,
                    customer_id: this.filters.customer_id,
                    search_string: this.filters.general,
                    url:'warehouse-calendar/cf/calendar'
                }
                this.$store.dispatch('getCalendar',payload).then(response => {
                    this.cf_calendar.events = response.data.scheduled_preparations
                    this.cf_calendar.colors = response.data.event_colors
                })
            }
        },
        viewDay({ date }){
            if (this.tab == 'wpf') {
                this.wpf_calendar.value = date
                this.wpf_calendar.calendar_view.value = 'day'
            } else if (this.tab == 'cf') {
                this.cf_calendar.value = date
                this.cf_calendar.calendar_view.value = 'day'
            }
        },
        setToday(){
            if (this.tab == 'wpf') {
                this.wpf_calendar.value = ''
            } else if (this.tab == 'cf') {
                this.cf_calendar.value = ''
            }
        },
        calendarPrev(){
            if (this.tab == 'wpf') {
                this.$refs.wpf_calendar.prev()
            } else if (this.tab == 'cf') {
                this.$refs.cf_calendar.prev()
            }
        },
        calendarNext(){
            if (this.tab == 'wpf') {
                this.$refs.wpf_calendar.next()
            } else if (this.tab == 'cf') {
                this.$refs.cf_calendar.next()
            }
        },
        toggle(show = false){
            if (this.tab == 'wpf') {
                if (!show) {
                    this.view_wpfs.list = []
                }
                this.view_wpfs.show = show
            } else if (this.tab == 'cf') {
                if (!show) {
                    this.view_cfs.list = []
                }
                this.view_cfs.show = show
            }
        },
        autoRefresh(){
            if (this.tab == 'wpf') {
                setInterval(() => {
                    if (this.$refs.wpf_calendar) {
                        this.refreshCalendarData(this.wpf_calendar.start_date, this.wpf_calendar.end_date)
                    }
                }, 5000)
            } else if (this.tab == 'cf') {
                setInterval(() => {
                    if (this.$refs.cf_calendar) {
                        this.refreshCalendarData(this.cf_calendar.start_date, this.cf_calendar.end_date)
                    }
                }, 5000)
            }
        },
        getCustomers(){
            // this.$store.dispatch('getAllCustomers').then(response => {
            //     this.list.customer_list = response.data
            // })
        },
    },
    mounted(){
        // this.getCustomers()
        this.setToday()
        this.autoRefresh()
        if (this.tab == 'wpf') {
            this.getWpfCalendarTransactions()
        } else if (this.tab == 'cf') {
            this.getCfCalendarTransactions()
        }
    },
    computed: {
        currentDay(){
            if (this.tab == 'wpf') {
                return this.wpf_calendar.value === undefined || this.wpf_calendar.value === null || this.wpf_calendar.value === ''
                    ? this.$dayjs().format('MMMM D, YYYY')
                    : this.$dayjs(this.wpf_calendar.value).format('MMMM D, YYYY')
            } else if (this.tab == 'cf') {
                return this.cf_calendar.value === undefined || this.cf_calendar.value === null || this.cf_calendar.value === ''
                    ? this.$dayjs().format('MMMM D, YYYY')
                    : this.$dayjs(this.cf_calendar.value).format('MMMM D, YYYY')
            }
        },
        // soTotalAmount(){
        //     return this.view_wpfs.list.reduce((subtotal, current) => parseFloat(subtotal) + parseFloat(current.so_total_amount), 0)
        // },
        totalItemCbm(){
            if (this.tab == 'wpf') {
                return this.view_wpfs.list.reduce((subtotal, current) => parseFloat(subtotal) + parseFloat(current.total_cbm), 0)
            } else if (this.tab == 'cf') {
                return this.view_cfs.list.reduce((subtotal, current) => parseFloat(subtotal) + parseFloat(current.total_cbm), 0)
            }
        },
        totalEstimateCbm(){
            if (this.tab == 'wpf') {
                return this.view_wpfs.list.reduce((subtotal, current) => parseFloat(subtotal) + parseFloat(current.total_estimate_cbm), 0)
            } else if (this.tab == 'cf') {
                return this.view_cfs.list.reduce((subtotal, current) => parseFloat(subtotal) + parseFloat(current.total_estimate_cbm), 0)
            }
        },
        totalWeight(){
            if (this.tab == 'wpf') {
                return this.view_wpfs.list.reduce((subtotal, current) => parseFloat(subtotal) + parseFloat(current.total_weight), 0)
            } else if (this.tab == 'cf') {
                return this.view_cfs.list.reduce((subtotal, current) => parseFloat(subtotal) + parseFloat(current.total_weight), 0)
            }
        },
        ...mapGetters([
            'GET_CUSTOMERS_LIST_SELECTION'
        ])
    },
}
</script>
<style scoped>
    .dropdown-items, .dropdown-selection {
        font-size: 13px;
    }
</style>
