<template>
    <div class="w-100">
        <v-app id="my_vue_app">
            <v-card>
                <v-card-text style="overflow:auto;">
                    <v-card-title class="p-0">
                        <v-container class="p-0 pl-4" fluid>
                            <v-row class="ma-1">
                                <v-col cols="2">
                                    <v-autocomplete
                                        v-model="$store.state.journalvoucher.journal_voucher_filters.vendor_id"
                                        dense
                                        outlined
                                        label="Vendor"
                                        auto-select-first
                                        :items="GET_ADMIN_SUPPLIER_SELECTION"
                                        hide-details
                                        clearable
                                    >
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="2">
                                    <v-autocomplete
                                        v-model="$store.state.journalvoucher.journal_voucher_filters.company_id"
                                        dense
                                        outlined
                                        label="Company"
                                        auto-select-first
                                        :items="GET_COMPANY_SELECTION"
                                        hide-details
                                        clearable
                                    >
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="2">
                                    <v-autocomplete
                                        v-model="$store.state.journalvoucher.journal_voucher_filters.stores_id"
                                        :items="clonedStores"
                                        dense
                                        outlined
                                        auto-select-first
                                        label="Stores"
                                        hide-details
                                        clearable
                                    >
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="1" class="my-auto pl-2">
                                    <v-btn
                                    @click="getAllJournalVoucher()"
                                    >
                                    FILTER
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row class="ma-1">
                                <v-col cols="2">
                                    <v-menu
                                        v-model="transact_date"
                                        ref="transact_date"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="formattedTransactDate"
                                                label="Transaction Date"
                                                append-icon="mdi-calendar"
                                                readonly
                                                dense
                                                outlined
                                                clearable
                                                v-bind="attrs"
                                                v-on="on"
                                                @click="transact_date = true"
                                            />
                                        </template>
                                        <v-date-picker
                                            v-model="$store.state.journalvoucher.journal_voucher_filters.transact_date"
                                            no-title
                                            scrollable
                                            range
                                        >
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                :disabled="$store.state.journalvoucher.journal_voucher_filters.transact_date.length != 2"
                                                text
                                                color="primary"
                                                @click="formattedTransactDateFilter"
                                            >
                                                OK
                                            </v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="2">
                                    <v-autocomplete
                                        v-model="$store.state.journalvoucher.journal_voucher_filters.prepared_by"
                                        :items="GET_PREPARED_BY"
                                        label="Prepared By"
                                        outlined
                                        dense
                                        auto-select-first
                                        clearable
                                        no-data-text="No Users Found!"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="2">
                                    <v-autocomplete
                                        v-model="$store.state.journalvoucher.journal_voucher_filters.company_classification_id"
                                        :items="GET_COMPANY_CLASSIFICATION_SELECTION"
                                        label="Classification"
                                        no-data-text="No Classifications Found!"
                                        outlined
                                        dense
                                        auto-select-first
                                        clearable
                                    ></v-autocomplete>
                                </v-col>
                                <v-spacer />
                                <v-col md="2">
                                    <v-text-field
                                        id="searchBar"
                                        v-model="$store.state.journalvoucher.journal_voucher_filters.search"
                                        label="Search by JV#"
                                        hide-details
                                        append-icon="mdi-magnify"
                                        @keypress.enter="getAllJournalVoucher()"
                                        @click:append="getAllJournalVoucher()">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-title>
                    <v-container fluid>
                        <v-row>
                            <v-col>
                                <v-data-table
                                    :headers="headers"
                                    :items="items"
                                    :search="$store.state.journalvoucher.journal_voucher_filters.search"
                                    :options.sync="options"
                                    :server-items-length="totalItems"
                                    :items-per-page="10"
                                    :loading="!loaded"
                                    :footer-props="{
                                        itemsPerPageOptions: itemsPerPageOptions,
                                    }"
                                    loading-text="Loading... Please wait"
                                    fixed-header
                                >
                                    <template v-slot:[`item.status_text`]="{ item }">
                                        <span>
                                            <a  class="ml-1 badge text-white" :style="`background-color:${ STATUS.JOURNAL_VOUCHER.find(e=>e.value === parseInt(item.status))?.color }`">
                                                {{ STATUS.JOURNAL_VOUCHER.find(e=>e.value === parseInt(item.status))?.text }}
                                            </a>
                                        </span>
                                    </template>
                                    <template v-slot:[`item.jv_date`]="{ item }">
                                        <span>
                                            {{ formatDate(item.jv_date) }}
                                        </span>
                                    </template>
                                    <template v-slot:[`item.action`]="{ item }">
                                        <v-btn text icon color="orange" v-if="userAccess.view">
                                            <v-icon
                                            class="btn-action"
                                            small
                                            @click="ViewJV(item,'View')"
                                            >mdi-eye</v-icon>
                                        </v-btn>
                                        <v-btn text icon color="blue"
                                            v-if="allowedStatus(item.status) && userAccess.edit"
                                        >
                                            <v-icon
                                            class="btn-action"
                                            small
                                            @click="EditJV(item,'Update')"
                                            >mdi-square-edit-outline</v-icon
                                            >
                                        </v-btn>
                                        <v-btn text icon color="red"
                                            v-if="
                                            allowedStatus(item.status) &&
                                            userAccess.delete && userAccess.edit"
                                        >
                                            <v-icon
                                            class="btn-action"
                                            small
                                            @click="DeleteJV(item)"
                                            >mdi-delete</v-icon
                                            >
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-app>
    </div>
</template>
<script>
import swal from "sweetalert2";
import { mapGetters } from 'vuex';
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
export default {
    mixins: [
        SharedFunctionsComponentVue
    ],
    data() {
        return {
            formattedTransactDate: [],
            transact_date: false,
            items: [],
            export: [],
            loaded: true,
            totalItems: 0,
            options: {},
            itemsPerPageOptions: [5, 10, 15, 50, 100],
            itemsPerPage: 10,
            status_text:'',
            status:0,
            viewJournalVoucherId:'',
            moments:(date)=>{
                return this.$dayjs(date).format('YYYY-MM-DD')
            },
            headers:[
                { text: "JV #", align: "left", sortable:false, value:'jv_num' },
                { text: "Vendor", align: "left", sortable:false, value:'vendor_name' },
                { text: "Company", align: "left", sortable:false, value:'company_name' },
                { text: "Store Branch", align: "left", sortable:false, value:'store_branch_name' },
                { text: "Prepared by", align: "left", sortable:false, value: 'prepared_by_name' },
                { text: "Released by", align: "left", sortable:false, value: 'released_by_name' },
                { text: "JV Date", align: "center", sortable:false, value: 'jv_date' },
                { text: "Status", align: "center", sortable:false, value: 'status_text' },
                { text: "Action", align: "center", value: "action",sortable:false},
            ],
            userAccess: {
                view: false,
                create: false,
                edit: false,
                delete: false,
            },
            statuses:[
                {status:'pending',badge_color:'gray'},
                {status:'approved',badge_color:'success'},
                {status:'rejected',badge_color:'danger'},
                {status:'cancelled',badge_color:'danger'},
            ],
            clonedStores:[]
        }
    },

    computed:{
        ...mapGetters([
            'USER_ACCESS',
            'GET_NEW_JOURNAL_VOUCHER_TABLE',
            'GET_JOURNAL_VOUCHER_STATUS',
            'GET_EDIT_JOURNAL_VOUCHER',
            'GET_PV_STORE_BRANCHES_SELECTION',
            'GET_COMPANY_SELECTION',
            'GET_ADMIN_SUPPLIER_SELECTION',
            'STATUS',
            'GET_PREPARED_BY',
            'GET_COMPANY_CLASSIFICATION_SELECTION'
        ]),
        computedStatus() {
            switch (this.GET_JOURNAL_VOUCHER_STATUS.tab_name) {
            case 'ALL':
                return -1;
            case 'PENDING':
                return 1;
            case 'APPROVED':
                return 2;
            case 'REJECTED':
                return 3;
            case 'CANCELLED':
                return 4;
            case 'RELEASED':
                return 6;
            case 'REJECTED':
                return 5;
            default:
                return -1;
            }
        },
    },
    mounted() {
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        this.getAllJournalVoucher();
        this.getBranches()
    },
    methods: {
        formatDate(date){
            return this.$dayjs(date).format('YYYY-MM-DD')
        },
        formattedTransactDateFilter(){
            this.formattedTransactDate = this.$store.state.journalvoucher.journal_voucher_filters.transact_date.length > 0
                ? this.$store.state.journalvoucher.journal_voucher_filters.transact_date[0] + ' to ' + (this.$store.state.journalvoucher.journal_voucher_filters.transact_date[1] == undefined
                    ? ''
                    : this.$store.state.journalvoucher.journal_voucher_filters.transact_date[1])
                : [];
            this.transact_date = false;
        },
        getAllJournalVoucher() {
            this.loaded = false
            this.items = []
            let payload = {
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                url:'getAllJournalVoucher',
                status:this.computedStatus,
                search:this.$store.state.journalvoucher.journal_voucher_filters.search,
                filters: this.$store.state.journalvoucher.journal_voucher_filters,
            }
            this.$store.dispatch('getJournalVoucher',payload).then(response=>{
                this.items = response.data.data.data
                this.totalItems = response.data.data.total
                this.loaded = true
            })
        },
        ViewJV(item,action) {
            let payload = {
                id: item.id,
                url:'getJournalVoucherItem',
            }
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", action);
            this.$store.dispatch("getJournalVoucher", payload).then(response=>{
                this.$store.commit("EDIT_JOURNAL_VOUCHER", response.data);
            });
        },
        EditJV(item,action) {
            let payload = {
                id: item.id,
                url:'getJournalVoucherItem',
            }
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", action);
            this.$store.dispatch('getAccountingJobSelection');
            this.$store.dispatch('getPaymentVoucherBranchesSelection',{company_id:item.company_id})
            this.$store.dispatch("getJournalVoucher", payload).then(response=>{
                this.$store.commit("EDIT_JOURNAL_VOUCHER", response.data);
            });
        },
        DeleteJV(item) {
            swal
            .fire({
                icon: 'question',
                text: "Are you sure you want to Delete?",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#397373",
                cancelButtonColor: "#397373",
            })
            .then((result) => {
                if (result.isConfirmed) {
                    let payload = {
                        url:item.id,
                    }
                    this.$store.dispatch('deleteJournalVoucher',payload).then(response=>{
                        swal.fire('',response.data.msg,response.data.icon)
                        this.getAllJournalVoucher();
                    })
                }
            });
        },
        allowedStatus(s){
            let status = [2,3,4,5,6]
            if(status.includes(Number(s))) return false
            else return true
        },
        async getBranches(){
            try{
                this.clonedStores = await this.getStoreBranches()
            } catch (error) {
                console.error(error)
            }
        },
    },
    watch: {
        GET_NEW_JOURNAL_VOUCHER_TABLE:{
            handler(val){
                if(val){
                    this.getAllJournalVoucher();
                    this.$store.commit("NEW_JOURNAL_VOUCHER_TABLE", false);
                }
            }
        },
        options: {
            handler() {
                this.getAllJournalVoucher();
            },
            deep: true,
        },
        USER_ACCESS: {
            handler(val) {
                if (val != "fail") {
                    this.userAccess.edit = false;
                    this.userAccess.delete = false;
                    this.userAccess.view = false;
                    this.userAccess.create = false;
                    val.forEach((e) => {
                        if (e.actions_code == "edit") {
                            this.userAccess.edit = true;
                        } else if (e.actions_code == "delete") {
                            this.userAccess.delete = true;
                        } else if (e.actions_code == "view") {
                            this.userAccess.view = true;
                        } else if (e.actions_code == "create") {
                            this.userAccess.create = true;
                        }
                    });
                }
            },
            immediate: true,
            deep: true
        },
        // '$store.state.journalvoucher.journal_voucher_filters.vendor_id': {
        //     handler(val) {
        //         this.$store.commit('PV_STORE_BRANCHES_SELECTION', [])
        //         this.$store.dispatch('getPaymentVoucherBranchesSelection', {company_id:val});
        //     },
        //     deep: true
        // },
        'formattedTransactDate': {
            handler(val){
                if (val == null)
                this.$store.state.journalvoucher.journal_voucher_filters.transact_date = []
            },
            immediate: true
        }
    },
};
</script>
<style scoped>
.search-container {
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    margin-right: 25px;
    z-index: 1;
}
</style>