<template>
    <div class="w-100">
      <v-app id="my_vue_app">
        <v-card>
          <v-card-title>
              <v-container fluid>
                <v-row>
                  <v-col cols="12" sm="8" class="d-flex justify-content-start">
                    <v-row>
                   <v-col cols="12" lg="3" >
                    <v-container fluid class="d-flex justify-content-between">
                    <v-checkbox label="Head Office" v-model="filter.location" value="headoffice"></v-checkbox>
                    <v-checkbox label="Store" v-model="filter.location" value="stores"></v-checkbox>
                  </v-container>
                  </v-col>
                  <v-col cols="12" md="1">
                    <v-btn class="mt-3" @click="getAllAdminAssets()">FILTER</v-btn>
                  </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="4">
                  <div class="row col-md-12 align-items-end">
                  <v-text-field
                      id="searchBar"
                      v-model="filter.search"
                      label="Search"
                      single-line
                      hide-details
                      @change="searchInput()"
                  >
                  </v-text-field>
                  <v-icon @click="searchInput()">mdi-magnify</v-icon>
                </div>
                </v-col>
                </v-row>
        
            </v-container>
          </v-card-title>
          <v-container fluid>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :options.sync="options"
              :server-items-length="totalItems"
              :items-per-page="10"
              :loading="!loaded"
              :footer-props="{
                  itemsPerPageOptions: itemsPerPageOptions,
                }"
              loading-text="Loading... Please wait"
              fixed-header
            >
            <template v-slot:[`item.image_path`]="{ item }">
              <v-img :src="url+'/'+item.image_path" class="img-thumbnail" width="50" height="50" eager contain></v-img>
            </template>
            <template v-slot:[`item.cost`]="{ item }">
  
                <span>{{ item.cost | currency }}</span>
            </template>
            <template v-slot:[`item.full_name`]="{ item }">
                <span v-if="item.accountability_id == 0">GENERAL ACCOUNTABILITY</span>
                <span v-else>{{ item.full_name }}</span>
            </template>
            </v-data-table>
          </v-container>
        </v-card>
      </v-app>
    </div>
  </template>
  <script>
  import swal from "sweetalert2";
  import { mapGetters } from 'vuex';
  export default {
    data() {
      return {
        loaded: false,
        totalItems: 0,
        options: {},
        items: [],
        itemsPerPageOptions: [5, 10, 15, 50, 100],
        itemsPerPage: 10,
        search: "",
        url:process.env.VUE_APP_API_HOST,
        filter:{
          search:"",
          location:['headoffice','stores']
        },
        awaitingSearch: false,
        headers: [
          { text: 'Picture', align: 'left',value:'image_path' },
          { text: "AS NO.", align: "left", value: "asset_num" },
          { text: "Full Name", align: "left", value: "full_name" },
          { text: "Description", align: "left", value: "description" },
          { text: "Brand", align: "left", value: "brand_name" },
          { text: "Classification", align: "center", value: "classification" },
          { text: "Sub-Class", align: "center", value: "sub_classification" },
          { text: "Asset Tagging", align: "center", value: "asset_code" },
          { text: "Location", align: "center", value: "location_name" },
          { text: "Department / Site", align: "left", value: "department_name" },
          // { text: "Employee Name", align: "left", value: "full_name" },
          { text: "Supplier", align: "left", value: "vendor_name" },
          { text: "Amount", align: "left", value: "cost" },
          { text: "Status", align: "left", value: "status_name" },
        ],
        userAccess: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      };
    },
    computed:{
      ...mapGetters(['USER_ACCESS','GET_NEW_ADMIN_ASSETS_TABLE','GET_ADMIN_DISPATCH','GET_ASSETS_DATA','GET_ADMIN_TAB_IS_ACTIVE'])
    },
    mounted() {
      this.getAllAdminAssets();
      this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
    },
    methods: {
      getAllAdminAssets(filter = null) {
        this.items = [];
        this.loaded = false;
        let payload = {
          page: this.options.page,
          itemsPerPage: this.options.itemsPerPage,
          search: this.filter.search,
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          location:this.filter.location,
          filter: filter,
          accountability:this.GET_ADMIN_TAB_IS_ACTIVE == 'accountability'?true:false,
        };
        
        this.$store.dispatch(this.GET_ADMIN_DISPATCH.get, payload).then((response) => {
          this.items = response.data.data;
          this.loaded = true;
          this.totalItems = response.data.total;
        }).catch((e)=>{
          this.loaded = true;
        });
      },
      searchInput(){
          this.getAllAdminAssets({ query: this.search });
      },  
      ViewAssets(item) {
        this.$store.commit("EDIT_ADMIN_ASSETS_DETAILS", item);
        this.$store.commit("DIALOG", true);
        this.$store.commit("ACTION", "View");
      },
      EditAssets(item) {
        this.$store.commit("EDIT_ADMIN_ASSETS_DETAILS", item);
        this.$store.commit("DIALOG", true);
        this.$store.commit("ACTION", "Update");
      },
      deleteAssets(id) {
        swal
          .fire({
            icon: 'question',
            text: "Are you sure you want to Delete?",
            cancelButtonText: "Cancel",
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonColor: "#397373",
            cancelButtonColor: "#397373",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$store
                .dispatch(this.GET_ADMIN_DISPATCH.delete, id)
                .then((success) => {});
            }
          });
      },
    },
    watch: {
      options: {
        handler() {
          this.getAllAdminAssets();
        },
        deep: true,
      },
      GET_NEW_ADMIN_ASSETS_TABLE:{
          handler(val){
              if(val){
                this.getAllAdminAssets();
              }
          }
      },  
      USER_ACCESS: {
        handler(val) {
          if (val != "fail") {
            this.userAccess.edit = false;
            this.userAccess.delete = false;
            this.userAccess.view = false;
            this.userAccess.create = false;
            val.forEach((e) => {
              if (e.actions_code == "edit") {
                this.userAccess.edit = true;
              } else if (e.actions_code == "delete") {
                this.userAccess.delete = true;
              } else if (e.actions_code == "view") {
                this.userAccess.view = true;
              } else if (e.actions_code == "create") {
                this.userAccess.create = true;
              }
            });
          }
        },
      },
    },
  };
  
  </script>