	<template>
		<v-container fluid >
			<h3 class="page-title">Timekeeping</h3>
			<v-container class="ma-0">
                <v-app id="item-tabs">
                    <v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" background-color="#424242" dark show-arrows>
                        <v-tabs-slider color="cyan"></v-tabs-slider>
                            <template v-for="(item, i) in items">
                                <v-spacer v-if="item.tab_name === 'For Approval'"></v-spacer>
                                <v-tab ripple v-if="tabAccess(item)" :key="i" @click="tabs(i)">
                                    <v-badge
                                        :content="GET_FOR_APPROVAL_COUNT ?? 0"
                                        :value="GET_FOR_APPROVAL_COUNT == 0 ? false : true"
                                        v-if="item.tab_name == 'For Approval'"
                                        offset-x="20"
                                        offset-y="-3"
                                        color="#f69f1a"
                                    ></v-badge>
                                    {{ item.tab_name }}
                                </v-tab>
                            </template>
                            <!-- <v-spacer></v-spacer> -->
                            <!-- <v-tab @click="tabs(4)">
                                For Approval
                            </v-tab> -->
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                        <v-tab-item v-for="(table, index) in timekeeping_component" :key="index" v-if="tabAccess(table)">
                            <keep-alive>
                                <component :is="table.components" :export_file_name="table.export_file_name" :isActive_tab="table.status_text">
                                </component>
                            </keep-alive>
                        </v-tab-item>
                    </v-tabs-items>
                </v-app>
			</v-container>
			<DialogComponent :cp="components" :scrollable="dialog_scrollable" :width="height(dialog_width)" :retain-focus="false"></DialogComponent>
		</v-container>
	</template>

<script>
    import DialogComponent from '@/views/main/layouts/dialogs/Dialog.vue';
    import TimekeepingComponent from '@/views/main/modules/HR/Timekeeping/TimekeepingComponent.vue'

    import ScheduleTemplateComponentView from '@/views/main/layouts/dialogs/HR/Timekeeping/ScheduleTemplateComponentView.vue'
    import EmployeeScheduleComponentView from '@/views/main/layouts/dialogs/HR/Timekeeping/EmployeeScheduleComponentView.vue'
    import TemporaryScheduleComponentView from '@/views/main/layouts/dialogs/HR/Timekeeping/TemporaryScheduleComponentView.vue'
    import TimekeepingRecordComponentView from '@/views/main/layouts/dialogs/HR/Timekeeping/TimekeepingRecordComponent.vue'
    import DepartmentScheduleComponentView from '@/views/main/layouts/dialogs/HR/Timekeeping/DepartmentScheduleComponentView.vue'
    import EmployeeOBRecordComponentView from '@/views/main/layouts/dialogs/HR/Timekeeping/EmployeeOBRecordComponentView.vue'
    import EmployeeScheduleForApprovalComponentView from '@/views/main/layouts/dialogs/HR/Timekeeping/EmployeeScheduleForApprovalComponentView.vue'
    import PusherFunctionComponentVue from '@/views/main/Shared/PusherFunctionComponent3.vue';
    import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue'


	import { mapGetters } from 'vuex';
	export default {
    mixins:[PusherFunctionComponentVue, SharedFunctionsComponentVue],
		components:{
			DialogComponent,
            TimekeepingComponent,
            ScheduleTemplateComponentView,
            EmployeeScheduleComponentView,
            TemporaryScheduleComponentView,
            TimekeepingRecordComponentView,
            DepartmentScheduleComponentView,
            EmployeeScheduleForApprovalComponentView,
            EmployeeOBRecordComponentView,
		},
		data() {
			return {
				components:'',
				items: [
                    {tab_name:"Schedule Template", access:'schedule_template'},
                    {tab_name:"Department Schedule", access:'department_schedule'},
                    {tab_name:"Employee Schedule", access:'employee_schedule'},
                    {tab_name:"Biometrics Data", access:'biometrics_data'},
                    {tab_name:"Official Business", access:'ob_module'},
                    {tab_name:"For Approval", access:'for_approval_temp_schedule'},
                ],
				timekeeping_component: [
                    {
                        components: TimekeepingComponent,
                        access:'schedule_template',
                        status_text: "schedule_template",
                        dialog: ScheduleTemplateComponentView,
                        dialog_title:'Schedule Template',
                        dialog_width: "50%",
                        scrollable:true,
                        dispatch:{get:'getScheduleTemplate',
                                    add:'addScheduletemplate',
                                    update:'updateScheduleTemplate',
                                    delete:'deleteScheduleTemplate'},
                    },
                    {
                        components: TimekeepingComponent,
                        access:'department_schedule',
                        status_text: "department_schedule",
                        dialog: DepartmentScheduleComponentView,
                        dialog_title:'Department Schedule',
                        dialog_width: "70%",
                        scrollable: true,
                        dispatch:{get:'getDepartmentSchedule',
                                    add:'addDepartmentSchedule',
                                    update:'updateDepartmentSchedule',
                                    delete:'deleteDepartmentSchedule',
                                    show:'showDepartmentSchedule',
                                },
                    },
                    {
                        components: TimekeepingComponent,
                        access:'employee_schedule',
                        status_text: "employee_schedule",
                        dialog: EmployeeScheduleComponentView,
                        dialog_title:'Employee Schedule',
                        dialog_width: "65%",
                        scrollable: true,
                        dispatch:{get:'getEmployeeSchedule',
                                    add:'addEmployeeSchedule',
                                    update:'updateEmployeeSchedule',
                                    delete:'deleteEmployeeSchedule'},
                    },
                    {
                        components: TimekeepingComponent,
                        access:'biometrics_data',
                        status_text: "biometrics_data",
                        dialog: TimekeepingRecordComponentView,
                        dialog_title:'Employee Schedule',
                        dialog_width: "90%",
                        scrollable: true,
                        dispatch:{get:'getTimekeepingRecords',
                                    add:'addEmployeeSchedule',
                                    update:'updateTimekeepingRecords',
                                    delete:'deleteTimekeepingRecords'},
                    },
                    {
                        components: TimekeepingComponent,
                        access:'ob_module',
                        status_text: "ob_module",
                        dialog: EmployeeOBRecordComponentView,
                        dialog_title:'Official Business Form',
                        dialog_width: "90%",
                        scrollable: true,
                        dispatch:{get:'hrEmployeeOBRecords',
                                    add:'hrEmployeeOBRecords',
                                    update:'hrEmployeeOBRecords',
                                    delete:'hrEmployeeOBRecords'},
                    },
                    {
                        components: TimekeepingComponent,
                        access:'for_approval_temp_schedule',
                        status_text: "for_approval_temp_schedule",
                        dialog: EmployeeScheduleForApprovalComponentView,
                        dialog_title:'For Approval Schedule',
                        dialog_width: "65%",
                        scrollable: true,
                        dispatch:{get:'getForApprovalSchedules',
                                    approve:'approveTemporarySchedules',
                                    delete:'deleteForApprovalTemp',
                                },
                    },
				],
				dialog_width: "",
				loaded: false,
				options: {},
				itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
				itemsPerPage: 10,
				tab: null,
				dialog_scrollable:true,
                selectedRange:{
					from: this.$dayjs().startOf('week').format('YYYY-MM-DD'),
					to: this.$dayjs().endOf('week').format('YYYY-MM-DD'),
                    from_menu: false,
                    to_menu: false,
				},
			};
		},
		computed:{
			...mapGetters([
                'USERACCOUNT_LOAD',
                'USER_ACCESS',
                'GET_HR_TAB_IS_ACTIVE',
                'GET_FOR_APPROVAL_COUNT'
            ])
		},
        beforeDestroy() {
            this.removeEchoListener();
        },
		mounted(){
            this.tabs(0)
            this.callPusher()
            this.addEchoListener()
            this.$store.commit('SELECTED_DATE_RANGE', this.selectedRange)
            this.$store.dispatch('checkAccessUser',this.$router.currentRoute.params.id);

		},
		methods:{
        addEchoListener(){
            window.Echo.channel('ingco-channel').listen('RealtimeHR',(e)=>{
                if(e.user_id === this.USERACCOUNT_LOAD.id){
                    this.$store.commit('FOR_APPROVAL_COUNT',e.for_approval_count);
                }else{
                    if(e.new_schedule == 1 && e.hr_ids.includes(this.USERACCOUNT_LOAD.id)){
                    // if(e.new_schedule == 1 && !e.updated_by_id && this.USERACCOUNT_LOAD.department_id == 7){
                        this.$store.dispatch('getForApprovalSchedules');
                        this.$toast[e.icon](e.message, {
                            position: 'bottom-right',
                            showCloseButtonOnHover: true,
                            timeout: 3000, // Time in milliseconds (ms) for the notification to disappear
                        });
                    }
                    else if (e.new_schedule == 1 && e.updated_by_id == this.USERACCOUNT_LOAD.id){
                        this.$store.dispatch('getEmployeeSchedule');
                        this.$toast[e.icon](e.message, {
                            position: 'bottom-right',
                            showCloseButtonOnHover: true,
                            timeout: 3000, // Time in milliseconds (ms) for the notification to disappear
                            onClick: () => {
                            // Call your method here
                                this.showEmpScheduleTab();
                            }
                        });
                    }
                }
            })
        },
        showEmpScheduleTab(){
            let index = this.findIndexInArrayObjects(this.timekeeping_component, 'status_text', 'employee_schedule');
            this.tab = index - 1;
            this.tabs(index)
        },
        removeEchoListener() {
            window.Echo.channel('ingco-channel').stopListening('RealtimeHR', this.echoListener);
        },
		tabs(key) {
			this.components = this.timekeeping_component[key].dialog;
			this.dialog_width = this.timekeeping_component[key].dialog_width;
			this.dialog_scrollable = this.timekeeping_component[key].scrollable;

			this.$store.commit('HR_DIALOG_TITLE',this.timekeeping_component[key].dialog_title)
			this.$store.commit('HR_TAB_IS_ACTIVE',this.timekeeping_component[key].status_text)
			this.$store.commit('HR_DISPATCH',this.timekeeping_component[key].dispatch)
			this.$store.commit('HR_GETTERS',this.timekeeping_component[key].getters)
            this.$store.commit('EXPORT_HEADERS',this.timekeeping_component[key].exportHeaders)
		},
		getSearchFromUrl() {
            if (Object.keys(this.$route.query).length != 0) {
                Object.keys(this.$route.query).forEach(e=>{
					if(e == 'for_approval'){
						this.tab = this.items.length - 1
						this.tabs(this.tab)
					}
                })
            }
        },
		height(width) {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs': return "100%"
				case 'sm': return "100%"
				case 'md': return width
				case 'lg': return width
				case 'xl': return width
			}
		},
        tabAccess(item){
            if(this.USER_ACCESS != "fail"){
                if(this.USER_ACCESS.find(e=>e.actions_code == item.access)){
                    if(this.USER_ACCESS.find(e=>e.actions_code == item.access).actions_code == item.access)
                    {
                        return true
                    }
                }
            }
        },
	},
	watch:{
		USERACCOUNT_LOAD:{
			handler(val){
			}
		},
        selectedRange:{
            handler(val){
                this.$store.commit('SELECTED_DATE_RANGE', val)
            },
            deep: true
        },
        USER_ACCESS:{
            handler(val){
                // if(!_.isEmpty(val)){
                //     let filtered = this.timekeeping_component.filter((x) =>{
                //         if(val.find(e=> e.actions_code == x.access)){
                //             return x
                //         }
                //     })
                //     this.timekeeping_component = filtered;
                //     console.log(this.timekeeping_component)
			    //     this.tabs(0)
                // }
            },
            immediate: true
        }
	}

	};
	</script>
	<style>
	#group-tab {
		padding: 0 !important;
	}
	</style>
