<template>
    <v-card class="d-flex justify-content-center flex-column">
        <v-card-title outline class="d-flex justify-content-between align-items-center">
            <span>Template</span>
            <v-btn text icon small color="gray" class="float-right" @click="() => {
               closeDialog()
            }">
            <v-icon>mdi-close-circle</v-icon>
            </v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pt-2">
            <v-col cols="12" class="mb-2">
                <SectionHeading :heading="'Template Information'"></SectionHeading>
                <v-row class="ml-1">
                    <v-col cols="3" class="pa-4">
                        <v-text-field
                        v-model="form.code"
                        label="Code"
                        :disabled="ACTION == 'View'"
                        outlined
                        dense
                        @keypress="forceUpperCaseText"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="3" class="pa-4">
                        <v-text-field
                        v-model="form.name"
                        label="Name"
                        :disabled="ACTION == 'View'"
                        outlined
                        dense
                        @keypress="forceUpperCaseText"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-card-text>
        <v-card-text>
            <v-row dense>
                <v-col cols="1">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn @click="()=>{
                                batchAddition.dialog = !batchAddition.dialog
                                }"
                                v-on="on"
                                :disabled="ACTION == 'View'"
                                color="orange"
                                icon
                                fab
                                small
                            >
                            <v-icon>mdi-archive-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Batch Addition</span>
                    </v-tooltip>
                </v-col>
            </v-row>
            <v-col cols="12">
                <SectionHeading :heading="'Template Items'"></SectionHeading>
                <v-row>
                    <v-col lg="12" class="pa-4">
                        <v-data-table
                            :items="form.item_list"
                            :headers="dataTable.headers"
                            dense
                            disable-sort
                        >
                        <template v-slot:[`header.button`]="{ header }">
                            <v-btn
                                color="success"
                                x-small
                                fab
                                v-if="ACTION != 'View'"
                                @click="modifyItem(1)"
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                            <v-btn
                                color="error"
                                x-small
                                fab
                                v-if="ACTION != 'View'"
                                @click="modifyItem(2)"
                            >
                                <v-icon>mdi-minus</v-icon>
                            </v-btn>
                            </template>

                            <template v-slot:[`item.admin_item_id`]="{ item, index }">
                                <v-autocomplete
                                    v-model="item.admin_item_id"
                                    :items="selection.admin_items"
                                    :disabled="ACTION == 'View'"
                                    item-value="value"
                                    item-text="admin_item_name"
                                    dense
                                    outlined
                                    hide-details
                                    auto-select-first
                                    @change="item.admin_item_name = selection.admin_items.find(e => e.value == item.admin_item_id)?.admin_item_name"
                                >
                                </v-autocomplete>
                            </template>
                            <template v-slot:[`item.admin_item_uom_id`]="{ item, index }">
                                <v-autocomplete
                                    v-model="item.admin_item_uom_id"
                                    :items="GET_ADMIN_UOM_SELECTION"
                                    :disabled="ACTION == 'View'"
                                    item-value="value"
                                    item-text="text"
                                    dense
                                    outlined
                                    hide-details
                                    auto-select-first
                                >
                                </v-autocomplete>
                            </template>
                            <template v-slot:[`item.quantity`]="{ item }">
                                <v-text-field
                                    v-model="item.quantity"
                                    class="centered-input"
                                    outlined
                                    dense
                                    :disabled="ACTION == 'View'"
                                    @keypress="numberandDotOnly"
                                >
                                </v-text-field>
                            </template>
                            <template v-slot:[`item.admin_item_name`]="{ item }">
                                <v-text-field
                                    v-model="item.admin_item_name"
                                    class="centered-input"
                                    outlined
                                    dense
                                    :disabled="ACTION == 'View'"
                                >
                                </v-text-field>
                            </template>
                            <template v-slot:[`item.button`]="{ item, index }">
                                <v-btn
                                    color="error"
                                    small
                                    icon
                                    :disabled="ACTION == 'View'"
                                    @click="modifyItem(3, item.id)"
                                    :class="[4,1].includes(form.status) ? 'mb-3' : ''"
                                >
                                    <v-icon>
                                        mdi-delete
                                    </v-icon>
                                </v-btn>

                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-col>
            <!-- <v-row>
                <v-col lg="5" v-if="ACTION == 'Add'">
                    <b>Item Not Found: </b> <span style="color:red">{{ errors.item_not_found.map(e=>e.item_name).join(", ") }}</span>
                </v-col>
            </v-row> -->
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-content-center p-3">
            <v-btn @click="createOrUpdatePoItemTemplates()" v-if="ACTION !== 'View'">
                <span >{{ACTION == 'Add' ? 'Submit' : 'Update'}}</span>
            </v-btn>
        </v-card-actions>
        <BatchAdditionCopyPasteDialogComponent :dialog="batchAddition.dialog" @closeDialog="retrieveData" :headers="['Item Name', 'Uom', 'Quantity']"></BatchAdditionCopyPasteDialogComponent>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import swal from "sweetalert2";
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
import BatchAdditionCopyPasteDialogComponent from "../Operations/General/batchAdditionCopyPastComponent.vue";
import SectionHeading from '@/views/main/Components/SectionHeading.vue';

export default {
    mixins: [
            SharedFunctionsComponentVue
        ],
    data(){
        return {
            form: {
                id: '',
                name: '',
                code: '',
                item_list: [
                    { id: null, value:'', admin_item_name:'', admin_item_uom_id:'', quantity:'',  vendor:'' }
                ],
                result: [],
                trash_item_ids: [],
                old_ids: [],
            },
            selection:{
                admin_items:[],
            },
            dataTable:{
                headers:[
                    { text:'Item Name',value:'admin_item_id', width:'25%' },
                    { text:'Uom',value:'admin_item_uom_id', width:'10%' },
                    { text:'Quantity', align:'center', value:'quantity',width:'10%' },
                    { text:'Action',align:'center',value:'button',width:'25%' },
                ]
            },
            loading: true,
            batchAddition:{
                dialog:false,
                data:[]
            },
            errors:{
                item_not_found:[]
            },
        }
    },
    components:{
        BatchAdditionCopyPasteDialogComponent,
        SectionHeading,
    },
    computed: {
        ...mapGetters([
            "ACTION",
            "PURCHASE_ORDER_ITEMS_TEMPLATE_DIALOG",
            "SELECTED",
            "GET_ADMIN_ITEMS_SELECTION",
            "GET_ADMIN_UOM_SELECTION",
        ]),
    },
    async mounted() {
        await this.$store.dispatch('getAdminItemsSelection').then((response)=>{
            this.selection.admin_items = response.data
        });
        document.addEventListener('keydown', this.handleKeydown);
    },
    beforeDestroy(){
        document.removeEventListener('keydown', this.handleKeydown);
    },
    methods: {
        createOrUpdatePoItemTemplates(){
            let trash = this.form.item_list.map(e => e.id)
            this.form.trash_item_ids = this.form.old_ids ? this.form.old_ids.filter(e => !trash.includes(e)) : null
            swal.fire({
                title:'Warning',
                text: this.ACTION === 'View' ? 'Are you sure you want to Update?': 'Are you sure you want to '+this.ACTION+'?',
                showCancelButton:true,
                reverseButtons:true,
                icon:'warning'
            }).then(action=>{
                if(action.isConfirmed){
                    let checker = [
                        [this.form.item_list.some(e=>!e.quantity) || this.form.item_list.length == 0,'Please Check the Item/s'],
                        [this.form.item_list.some(e=>e.quantity == 0), 'Please Check the Quantity of Item/s'],
                        [!this.form.name,'Please enter Name'],
                        [!this.form.code,'Please enter Code'],
                    ]
                    if(checker.find(e=>e[0] == true)){
                        swal.fire('Error!',`- ${checker.find(e=>e[0] == true)[1]}`,'error')
                        return
                    }
                    this.$store.dispatch('purchaseOrderItemsTemplatePost',{
                        url:!this.form.id ? 'add-po-item-template' : 'edit-po-item-template/'+ this.form.id,
                        ...this.form,
                    }).then(response=>{
                        swal.fire('Success!','','success')
                        this.closeDialog();
                        
                    }).catch(e=>{
                        console.log(e)
                        swal.fire('Error','','error')
                    });
                }
            })
        },
       modifyItem(action, id){
            if([2,3].includes(action)){
                if(action == 2){
                    this.form.item_list.pop()
                }
                else{
                    const index = this.form.item_list.findIndex(item => item.id === id);
                    if (index !== -1) {
                        this.form.item_list.splice(index, 1);
                    }
                }
            }
            else{
                this.addItems()
            }
            if(this.form.item_list.length == 0){
                this.addItems()
            }
            this.$forceUpdate()
        },
        resetFields(){
            this.form = {
                id: '',
                name: '',
                code: '',
                item_list: [
                    { id: null, value:'', admin_item_name:'', admin_item_uom_id:'', quantity:'',  vendor:'',}
                ],
                trash_item_ids: [],

            },
            this.loading = true
        },
        closeDialog(){
            this.resetFields();
            this.$store.commit('PURCHASE_ORDER_ITEMS_TEMPLATE_DIALOG', false);
        },
        handleKeydown(event){
            this.closeDialogByEsc(event, this.closeDialog)
        },
        addItems(){
            this.form.item_list.push({
                id: null, 
                value:'', 
                admin_item_name:'', 
                admin_item_uom_id:'',  
                quantity:'',  
                vendor:'',
            })
        },
        async retrieveData(data){
            if(data.length >  0){
                data = data.map(e=>{
                    let obj = {
                        admin_item_name:e[0],
                        uom:e[1],
                        quantity:e[2],
                    }
                    e = obj
                    return e
                })
                await this.$store.dispatch('purchaseOrderItemsTemplateGet',{
                        url:'check-item-if-exist',
                        admin_items:data.map(e=>e.admin_item_name),
                }).then(response=>{
                    this.form.item_list = [];
                    data = data.filter(e=>{
                        let item = response.find(el=>el.admin_item_name.toUpperCase() == e.admin_item_name.toUpperCase())
                        if(!!item){
                            item.quantity = e.quantity
                            item.admin_item_uom_id = this.GET_ADMIN_UOM_SELECTION.find(a => a.text == e.uom)?.value
                            item.id = item.admin_item_id

                            if(item.admin_item_id){
                                this.form.item_list.push(item)
                            }
                        }else{
                            this.errors.item_not_found.push(e)
                        }

                            if(this.errors.item_not_found.length > 0){
                            swal.fire('Some item/s are not available on the selection','- Please check the items in admin items module','warning');
                        }
                    })
                    
                })
            }
            this.batchAddition.dialog = false
        },
    },   
    watch: {
        SELECTED:{
            async handler(val){
                this.loading = true
                if(!!val){
                    await this.$store.dispatch('purchaseOrderItemsTemplateGet',{
                        url:'show-po-item-template/'+val.id,
                        id: val.id
                    }).then(response=>{
                        if(!this.PURCHASE_ORDER_ITEMS_TEMPLATE_DIALOG) return
                        this.form = response[0]
                        this.form.old_ids = response[0].item_list.map(e => e.id)
                    }).catch(e=>{
                        console.log(e)
                    })
                }
                setTimeout(()=>{
                    this.loading = false
                },1000)

                this.$forceUpdate();
            },
            deep:true,
            immediate:true
        },
    },
}
</script>
<style scoped>
::v-deep .right-align-text input {
    text-align: right;
}
</style>