<template>
    <v-form ref="form" v-model="valid" lazy-validation>
        <v-card v-if="actions != 'Submit'">
            <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                <span class="text-h5">{{ GET_HR_DIALOG_TITLE }}</span>
                <v-row class="m-0">
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                            text
                            icon
                            small
                            color="gray"
                            class="float-right"
                            @click="closeDialog()"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-container class="pb-0 my-2" fluid>
                    <v-row>
                        <v-col cols="12" sm="4" class="pa-0 pr-1">
                            <v-text-field
                                v-model="form.employee_name"
                                label="Employee Name"
                                readonly
                                outlined
                                required
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-spacer>
                        </v-spacer>
                        <v-col cols="1" class="mr-2 pa-0">
                            <v-checkbox
                                v-model="form.temporary"
                                :true-value="1"
                                :false-value="0"
                                :disabled="disabled_view"
                                label="Temporary"
                                dense
                            >
                            </v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="4" class="pa-0 pr-1" v-if="!disabled_view">
                            <v-autocomplete
                                v-model="form.schedule_type"
                                :items="dropdowns.schedule_type"
                                :rules="errors.required"
                                :readonly="disabled_view"
                                @input="filterTemplates()"
                                item-disabled="isDisabled"
                                label="Schedule Type"
                                item-value="value"
                                item-text="text"
                                auto-select-first
                                outlined
                                required
                                dense
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="4" class="pa-0 pr-1" v-else>
                            <v-text-field
                                v-model="form.schedule_type_name"
                                label="Schedule Type"
                                readonly
                                outlined
                                required
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-spacer>
                        </v-spacer>

                        <!-- <v-col cols="12" sm="4" class="pa-0 pr-1" v-if="form.temporary">
                            <v-menu
                                v-model="form.effectivity_date_menu"
                                :close-on-content-click="false"
                                ref="menu"
                                :disabled="disabled_view"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="dateRangeText"
                                        v-on="on"
                                        :readonly="disabled_view"
                                        :rules="errors.required"
                                        label="Effectivity Date (YYYY-MM-DD)"
                                        outlined
                                        dense
                                    ></v-text-field>
                                </template>

                                <v-date-picker
                                    v-model="form.effectivity_date"
                                    no-title
                                    scrollable
                                    range
                                >
                                </v-date-picker>
                            </v-menu>
                        </v-col> -->
                        <!-- <v-col cols="12" sm="4" class="pa-0 pr-1">
                            <v-text-field
                                v-model="form.break_duration"
                                :readonly="disabled_view"
                                label="Break Duration (Hours)"
                                type="number"
                                min="0"
                                outlined
                                required
                                dense
                            >
                            </v-text-field>
                        </v-col> -->
                    </v-row>
                    <v-row>
                        <!-- <v-col cols="12" sm="4" class="pa-0 pr-1" v-if="!disabled_view">
                            <v-autocomplete
                                v-model="form.schedule_template"
                                :items="dropdowns.schedule_template"
                                :readonly="disabled_view"
                                @input="setTemplateData()"
                                item-disabled="isDisabled"
                                label="Schedule Template (optional)"
                                item-value="value"
                                item-text="text"
                                auto-select-first
                                outlined
                                required
                                dense
                            ></v-autocomplete>
                        </v-col> -->
                        <!-- <v-col cols="12" sm="4" class="pa-0 pr-1" v-else>
                            <v-text-field
                                v-model="form.schedule_template_name"
                                label="Schedule Template"
                                readonly
                                outlined
                                required
                                dense
                            ></v-text-field>
                        </v-col> -->
                        <v-col cols="12" sm="4" class="pa-0 pr-1" v-if="form.rejected == 1">
                            <v-textarea
                                v-model="form.reject_reason"
                                background-color="#ff5338"
                                label="Reject Reason"
                                rows="2"
                                no-resize
                                auto-grow
                                readonly
                                outlined
                                required
                                dense
                            ></v-textarea>
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>
                    <v-row>
                    <v-row>
                        <v-col cols="12" class="px-4 pt-3 pb-3">
                            <v-card elevation="2" class="pa-0">
                                <v-tabs v-model="tab" id="group-tab" color="cyan" background-color="#424242" dark slider-color="cyan" show-arrows @change="changeTab()">
                                    <v-tab ripple>Temporary</v-tab>
                                    <v-tab ripple>Fixed</v-tab>
                                    <v-spacer></v-spacer>
                                    <v-tabs-items v-model="tab" touchless>
                                        <v-tab-item>
                                            <v-expansion-panels accordion hover multiple v-model="panel">
                                                <v-expansion-panel
                                                    v-for="(item,i) in form.employee_temporary_schedule_items"
                                                    :key="i"
                                                >
                                                    <v-expansion-panel-header disable-icon-rotate>
                                                        <template v-slot="{ open }">
                                                            <v-row no-gutters>
                                                                <v-col
                                                                    cols="8"
                                                                    class="text--secondary"
                                                                >
                                                                    <v-fade-transition leave-absolute>
                                                                    <span v-if="open">Temporary Schedule Details</span>
                                                                    <v-row
                                                                        v-else
                                                                        no-gutters
                                                                        style="width: 100%"
                                                                    >
                                                                        <v-col cols="6">
                                                                        Effectivity Dates: {{ joinedDates(item.effectivity_date, ' to ') || 'Not set' }}
                                                                        </v-col>
                                                                    </v-row>
                                                                    </v-fade-transition>
                                                                </v-col>
                                                            </v-row>
                                                        </template>
                                                        <template v-slot:actions>
                                                             <!-- <v-tooltip bottom color="#2196f3" v-if="item.approved == 0">
                                                                <template v-slot:activator="{ on, attrs }" >
                                                                    <v-icon
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        color="#2196f3"
                                                                    >
                                                                    mdi-check-circle
                                                                    </v-icon>
                                                                </template>
                                                                <span>Approve Temporary Schedule</span>
                                                            </v-tooltip> -->
                                                        </template>
                                                    </v-expansion-panel-header>
                                                    <!-- <v-expansion-panel-header>
                                                        Temporary Schedule Date: {{joinedDates(item.effectivity_date)}}
                                                        <template v-slot:actions>
                                                            <v-tooltip bottom color="#B80000">
                                                                <template v-slot:activator="{ on, attrs }" >
                                                                    <v-btn
                                                                        @click.stop="removeTemporaryScheduleItem(item.id, i)"
                                                                        color="#B80000"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        small
                                                                        icon
                                                                    >
                                                                        <v-icon color="#B80000">mdi-minus-circle</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Remove Temporary Schedule</span>
                                                            </v-tooltip>
                                                        </template>
                                                    </v-expansion-panel-header> -->
                                                    <v-expansion-panel-content>
                                                        <v-container>
                                                            <v-row no-gutters>
                                                                <v-col cols="12" sm="3">
                                                                    <v-menu
                                                                        v-model="item.effectivity_date_menu"
                                                                        :close-on-content-click="false"
                                                                        :disabled="disabled_view"
                                                                        allow-overflow
                                                                        ref="menu"
                                                                        transition="scale-transition"
                                                                        offset-y
                                                                        max-width="290px"
                                                                        min-width="auto"
                                                                    >
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-text-field
                                                                                :value="joinedDates(item.effectivity_date, ' ~ ')"
                                                                                @click:clear="clearTempDate(item)"
                                                                                :clearable="disabled_view? false : true"
                                                                                v-on="on"
                                                                                v-bind="attrs"
                                                                                readonly
                                                                                label="Temporary Effectivity Date (YYYY-MM-DD)"
                                                                                outlined
                                                                                dense
                                                                            ></v-text-field>
                                                                        </template>

                                                                        <v-date-picker
                                                                            v-model="item.effectivity_date"
                                                                            @input="item.effectivity_date.length === 2 ? checkIfTempDateOverlap(item) : ''"
                                                                            no-title
                                                                            scrollable
                                                                            range
                                                                        >
                                                                        </v-date-picker>
                                                                    </v-menu>
                                                                </v-col>
                                                                <v-col cols="12" sm="3" class="ml-2">
                                                                    <v-text-field
                                                                        v-model="item.restday"
                                                                        :items="dropdowns.days"
                                                                        :readonly="disabled_view"
                                                                        @change="setRestDay(item)"
                                                                        label="Rest Day"
                                                                        item-text="name"
                                                                        item-value="name"
                                                                        auto-select-first
                                                                        outlined
                                                                        required
                                                                        dense
                                                                    ></v-text-field>
                                                                </v-col>
                                                                <v-spacer></v-spacer>
                                                                <v-col cols="12" sm="2">

                                                                </v-col>
                                                                <v-col cols="12" sm="3" class="d-flex justify-content-center align-content-center"  v-if="actions != 'View'">
                                                                    <v-toolbar  rounded class="mt-2">
                                                                        <v-col cols="8">
                                                                            <v-autocomplete
                                                                                v-model="item.helper_settings.day_type_id"
                                                                                :items="dropdowns.setting_type"
                                                                                :readonly="disabled_view"
                                                                                item-disabled="isDisabled"
                                                                                label="Day Type"
                                                                                item-value="value"
                                                                                item-text="text"
                                                                                auto-select-first
                                                                                outlined
                                                                                required
                                                                                dense
                                                                            ></v-autocomplete>
                                                                        </v-col>
                                                                        <v-spacer></v-spacer>
                                                                        <v-col cols="12" sm="2">
                                                                            <v-menu
                                                                                :close-on-content-click="false"
                                                                                transition="slide-y-transition"
                                                                                offset-y
                                                                                left
                                                                                bottom
                                                                            >
                                                                                <template v-slot:activator="{ on: onMenu }">
                                                                                    <v-tooltip bottom color="#f69f1a">
                                                                                        <template #activator="{ on: onTooltip }">
                                                                                            <v-btn
                                                                                                v-on="{ ...onMenu, ...onTooltip }"
                                                                                                text
                                                                                                small
                                                                                                icon
                                                                                            >
                                                                                                <v-icon color="#f69f1a">
                                                                                                    mdi-timer-settings
                                                                                                </v-icon>
                                                                                            </v-btn>
                                                                                        </template>
                                                                                        <span>Time In/Out Settings</span>
                                                                                    </v-tooltip>
                                                                                </template>
                                                                                <v-list>
                                                                                    <v-list-item>
                                                                                        <v-container>
                                                                                            <v-row>
                                                                                                <v-col cols="6">
                                                                                                    <v-menu
                                                                                                        v-model="item.helper_settings.time_in_menu"
                                                                                                        :disabled="disabled_view"
                                                                                                        :close-on-content-click="false"
                                                                                                        transition="slide-y-transition"
                                                                                                        left
                                                                                                    >
                                                                                                        <template v-slot:activator="{ on, attrs }">
                                                                                                            <v-text-field
                                                                                                                v-model="item.helper_settings.time_in"
                                                                                                                v-bind="attrs"
                                                                                                                v-on="on"
                                                                                                                label="Time In"
                                                                                                                :readonly="disabled_view"
                                                                                                                outlined
                                                                                                                dense
                                                                                                                flat
                                                                                                            ></v-text-field>
                                                                                                        </template>
                                                                                                        <v-time-picker
                                                                                                            v-model="item.helper_settings.time_in"
                                                                                                            @click:minute="()=>{
                                                                                                                item.helper_settings.time_in_menu = !item.helper_settings.time_in_menu
                                                                                                            }"
                                                                                                            format="24hr"
                                                                                                            scrollable
                                                                                                            >
                                                                                                        </v-time-picker>
                                                                                                    </v-menu>
                                                                                                </v-col>
                                                                                                <v-col cols="6">
                                                                                                    <v-menu
                                                                                                        v-model="item.helper_settings.time_out_menu"
                                                                                                        :disabled="disabled_view"
                                                                                                        :close-on-content-click="false"
                                                                                                        transition="slide-y-transition"
                                                                                                        offset-y
                                                                                                    >
                                                                                                        <template v-slot:activator="{ on, attrs }">
                                                                                                            <v-text-field
                                                                                                                v-model="item.helper_settings.time_out"
                                                                                                                v-bind="attrs"
                                                                                                                v-on="on"
                                                                                                                label="Time Out"
                                                                                                                :readonly="disabled_view"
                                                                                                                outlined
                                                                                                                dense
                                                                                                                flat
                                                                                                            ></v-text-field>
                                                                                                        </template>
                                                                                                        <v-time-picker
                                                                                                            v-model="item.helper_settings.time_out"
                                                                                                             @click:minute="()=>{
                                                                                                                item.helper_settings.time_out_menu = !item.helper_settings.time_out_menu
                                                                                                            }"
                                                                                                            format="24hr"
                                                                                                            scrollable
                                                                                                            >
                                                                                                        </v-time-picker>
                                                                                                    </v-menu>
                                                                                                </v-col>
                                                                                            </v-row>
                                                                                        </v-container>
                                                                                    </v-list-item>
                                                                                </v-list>
                                                                            </v-menu>
                                                                        </v-col>
                                                                        <v-col cols="12" sm="2">
                                                                            <v-tooltip bottom color="blue">
                                                                                <template v-slot:activator="{ on, attrs }" >
                                                                                    <v-btn
                                                                                        @click="batchUpdate(item)"
                                                                                        :disabled="!item.helper_settings.time_in || !item.helper_settings.time_out"
                                                                                        color="blue"
                                                                                        v-bind="attrs"
                                                                                        v-on="on"
                                                                                        small
                                                                                        icon
                                                                                    >
                                                                                        <v-icon color="blue">mdi-content-save-check</v-icon>
                                                                                    </v-btn>
                                                                                </template>
                                                                                <span>Apply To Dates</span>
                                                                            </v-tooltip>
                                                                        </v-col>
                                                                    </v-toolbar>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-container fluid class="pa-0 ma-0">
                                                                    <v-card class="pa-0">
                                                                        <v-card-title class="pr-0 pt-1 pr-1 pb-0 border-bottom"  v-if="!disabled_view">
                                                                            <v-row class="m-0">
                                                                                <v-col cols="pull-right-10">

                                                                                </v-col>
                                                                            </v-row>
                                                                        </v-card-title>
                                                                        <v-card-text>
                                                                            <v-simple-table height="300px">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Date</th>
                                                                                        <th>Time In</th>
                                                                                        <th>Time Out</th>
                                                                                        <!-- <th>Rest Day</th> -->
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr
                                                                                        v-for="(
                                                                                        data, i
                                                                                        ) in item.employee_temporary_schedule_item_details"
                                                                                        :key="i"
                                                                                    >
                                                                                        <td>
                                                                                            <v-text-field
                                                                                                v-model="data.date"
                                                                                                :background-color="data.isRestDay == 1 ? '#a8d1df': ''"
                                                                                                readonly
                                                                                                auto-select-first
                                                                                                outlined
                                                                                                required
                                                                                                dense
                                                                                            >
                                                                                                <template #append>
                                                                                                    <span class="mt-1">{{ "(" + getDayFromDate(data.date)+ ")"}}</span>
                                                                                                </template>
                                                                                            </v-text-field>
                                                                                        </td>

                                                                                        <td>
                                                                                            <v-menu
                                                                                                v-model="data.time_in_menu"
                                                                                                :disabled="disabled_view"
                                                                                                :close-on-content-click="false"
                                                                                                transition="scale-transition"
                                                                                                offset-y
                                                                                            >
                                                                                                <template v-slot:activator="{ on, attrs }">
                                                                                                    <v-text-field
                                                                                                        v-model="data.time_in"
                                                                                                        :readonly="disabled_view"
                                                                                                        :rules="errors.required"
                                                                                                        v-bind="attrs"
                                                                                                        v-on="on"
                                                                                                        outlined
                                                                                                        dense
                                                                                                        flat
                                                                                                    ></v-text-field>
                                                                                                </template>
                                                                                                <v-time-picker format="24hr" scrollable @click:minute="data.time_in_menu = false" v-model="data.time_in">
                                                                                                </v-time-picker>
                                                                                            </v-menu>
                                                                                        </td>

                                                                                        <td>
                                                                                            <v-menu
                                                                                                v-model="data.time_out_menu"
                                                                                                :close-on-content-click="false"
                                                                                                :disabled="disabled_view"
                                                                                                transition="scale-transition"
                                                                                                offset-y
                                                                                            >
                                                                                                <template v-slot:activator="{ on, attrs }">
                                                                                                    <v-text-field
                                                                                                        v-model="data.time_out"
                                                                                                        v-bind="attrs"
                                                                                                        v-on="on"
                                                                                                        :readonly="disabled_view"
                                                                                                        :rules="errors.required"
                                                                                                        outlined
                                                                                                        dense
                                                                                                        flat
                                                                                                    ></v-text-field>
                                                                                                </template>
                                                                                                <v-time-picker format="24hr" scrollable :min="data.time_in" @click:minute="data.time_out_menu = false" v-model="data.time_out"></v-time-picker>
                                                                                            </v-menu>
                                                                                        </td>

                                                                                        <!-- <td>
                                                                                            <v-checkbox
                                                                                                v-model="data.isRestDay"
                                                                                                :disabled="disabled_view"
                                                                                                :true-value="1"
                                                                                                :false-value="0"
                                                                                                color="green"
                                                                                                icon
                                                                                            >
                                                                                            </v-checkbox>
                                                                                        </td> -->
                                                                                    </tr>
                                                                                </tbody>
                                                                            </v-simple-table>
                                                                        </v-card-text>
                                                                    </v-card>
                                                                </v-container>
                                                            </v-row>
                                                        </v-container>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                            </v-expansion-panels>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <v-container fluid class="pa-0 ma-0">
                                                <v-card class="pa-0">
                                                    <v-card-title class="pr-0 pt-1 pr-1 pb-0 border-bottom"  v-if="!disabled_view">
                                                        <v-row class="m-0">
                                                            <v-col cols="pull-right-10">

                                                            </v-col>
                                                        </v-row>
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <v-simple-table height="300px">
                                                            <thead>
                                                                <tr>
                                                                    <th>Day</th>
                                                                    <th v-if="![2].includes(form.schedule_type)">Time In</th>
                                                                    <th v-if="![2].includes(form.schedule_type)">Time Out</th>
                                                                    <th v-if="[2].includes(form.schedule_type)">Work Hours</th>
                                                                    <th>Rest Day</th>
                                                                    <!-- <th v-if="!disabled_view">
                                                                        <v-btn
                                                                            @click="addWorkDays()"
                                                                            style="height: 20px; width: 20px"
                                                                            class="float-right"
                                                                            color="primary"
                                                                            small
                                                                            dark
                                                                            fab
                                                                        >
                                                                            <v-icon dark small style="font-size: 12px"
                                                                            >mdi-plus</v-icon
                                                                            >
                                                                        </v-btn>
                                                                    </th> -->
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr
                                                                    v-for="(
                                                                    data, i
                                                                    ) in form.workDays"
                                                                    :key="i"
                                                                >
                                                                    <td v-if="!disabled_view">
                                                                        <v-autocomplete
                                                                            v-model="data.day"
                                                                            :items="dropdowns.days"
                                                                            :readonly="disabled_view"
                                                                            item-disabled="isDisabled"
                                                                            item-text="name"
                                                                            item-value="name"
                                                                            auto-select-first
                                                                            outlined
                                                                            required
                                                                            dense
                                                                        ></v-autocomplete>
                                                                    </td>

                                                                    <td v-else>
                                                                        <v-text-field
                                                                            v-model="data.day"
                                                                            outlined
                                                                            readonly
                                                                            required
                                                                            dense
                                                                        ></v-text-field>
                                                                    </td>

                                                                    <td v-if="![2].includes(form.schedule_type)">
                                                                        <v-menu
                                                                            v-model="data.time_in_menu"
                                                                            :disabled="disabled_view"
                                                                            :close-on-content-click="false"
                                                                            transition="scale-transition"
                                                                            offset-y
                                                                        >
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-text-field
                                                                                    v-model="data.time_in"
                                                                                    :readonly="disabled_view"
                                                                                    v-bind="attrs"
                                                                                    v-on="on"
                                                                                    outlined
                                                                                    dense
                                                                                    flat
                                                                                ></v-text-field>
                                                                            </template>
                                                                            <v-time-picker format="24hr" scrollable @click:minute="data.time_in_menu = false" v-model="data.time_in">
                                                                            </v-time-picker>
                                                                        </v-menu>
                                                                    </td>

                                                                    <td v-if="![2].includes(form.schedule_type)">
                                                                        <v-menu
                                                                            v-model="data.time_out_menu"
                                                                            :close-on-content-click="false"
                                                                            :disabled="disabled_view"
                                                                            transition="scale-transition"
                                                                            offset-y
                                                                        >
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-text-field
                                                                                    v-model="data.time_out"
                                                                                    v-bind="attrs"
                                                                                    v-on="on"
                                                                                    :readonly="disabled_view"
                                                                                    outlined
                                                                                    dense
                                                                                    flat
                                                                                ></v-text-field>
                                                                            </template>
                                                                            <v-time-picker format="24hr" scrollable :min="data.time_in" @click:minute="data.time_out_menu = false" v-model="data.time_out"></v-time-picker>
                                                                        </v-menu>
                                                                    </td>

                                                                    <td v-if="[2].includes(form.schedule_type)">
                                                                        <v-text-field
                                                                            v-model="data.hours_to_work_day"
                                                                            :readonly="disabled_view"
                                                                            type="number"
                                                                            min="0"
                                                                            outlined
                                                                            required
                                                                            dense
                                                                        ></v-text-field>
                                                                    </td>

                                                                    <td>
                                                                        <v-checkbox
                                                                            v-model="data.isRestDay"
                                                                            :disabled="disabled_view"
                                                                            :true-value="1"
                                                                            :false-value="0"
                                                                            color="green"
                                                                            icon
                                                                        >
                                                                        </v-checkbox>
                                                                    </td>

                                                                    <!-- <td v-if="!disabled_view">
                                                                        <v-btn
                                                                            @click="deleteWorkDays(i, data.id)"
                                                                            color="red"
                                                                            icon
                                                                            dark
                                                                        >
                                                                            <v-icon dark >mdi-delete</v-icon>
                                                                        </v-btn>
                                                                    </td> -->
                                                                </tr>
                                                            </tbody>
                                                        </v-simple-table>
                                                    </v-card-text>
                                                </v-card>
                                            </v-container>
                                        </v-tab-item>
                                    </v-tabs-items>
                                </v-tabs>
                            </v-card>
                        </v-col>
                    </v-row>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions
                class="text-center"
                style="border-top: 1px solid #dee2e6"
            >
                <v-row class="ma-1 pb-2">
                    <v-col cols="12" v-if="form.approved != 1 && form.rejected == 0"  >
                        <v-btn
                            @click="approveEmployeeSchedule()"
                        >
                            <span>Approve</span>
                        </v-btn>
                        <v-btn
                            @click="rejectEmployeeScheduleTemp()"
                            color="red"
                            class="ml-2"
                        >
                            <span>Reject</span>
                        </v-btn>
                        <span class="pr-12">&nbsp;</span>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        <v-dialog
            v-model="tempRejection.dialog"
            width="40%"
            persistent
        >
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                        <span class="headline">Reject Reason</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="triggerTempRejectionDialog(false)">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider class="my-0" />
                <v-card-text>
                    <v-row class="mt-4">
                        <v-col>
                            <v-textarea
                                v-model="form.reject_reason"
                                label="Reason"
                                height="85"
                                outlined
                                required
                                dense
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="text-center">
                    <v-row class="ma-1">
                        <v-col cols="12">
                            <v-btn
                            class="float-center"
                            @click="rejectTempNow()"
                            >
                            <span>Submit</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-form>
</template>
<script>
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
    mixins: [SharedFunctionsComponentVue],
    data() {
        return {
            valid: false,
            overlay: false,
            disabled_view: false,
            errors: {
                required: [(v) => !!v || "Field is required"],
                number: [
                    (v) => !!v || "Field is required",
                    (v) => !isNaN(v) || "Input must be a number",
                ],
            },
            form: {},
            dropdowns: {
                schedule_type: [],
                days:[],
                employee_lists: [],
                schedule_template: [],
                department_lists: [],
                position_lists: [],
                level_lists: [],
                store_branches: [],
                employee_ids: [],
                setting_type: [
                    { value: -1 , text: 'Custom' },
                    { value: 0 , text: 'Weekends' },
                    { value: 1 , text: 'Weekdays' },
                ]
            },
            component_dispatches:[
		        'getEmployeeScheduleType',
                'getEmployeeScheduleTemplates',
                'getDepartment',
			],
            loading:{
                positions:false,
                department: false,
                button: false,
                employee_lists: false
            },
            selected_days: [],
            datatable:{
                items:[],
                headers:[
                    {text: 'Employee ID', value: 'employee_id'},
                    {text: 'Employee Name', value: 'full_name'},
                    {text: 'Position', value: 'job_title_name'},
                    {text: 'Level', value: 'job_level_name'},
                    {text: 'Action', value: 'action'},
                ],
                loading: true,
                itemsPerPageOptions: [5, 10, 15, 50, 100],
            },
            filters:{
                position_ids: [],
                department_ids: [],
                job_level_ids: [],
                store_branch_ids: [],
                employee_list: [],
                search: '',
            },
            defaults: {
                position_ids: [],
                department_ids: [],
                job_level_ids: [],
                store_branch_ids: [],
                employee_list: [],
                search: '',
            },
            tmp: true,
            hr_user_department_access: [],
            search: '',
            includedDates: [],
            filter_count: [],
            tab: 0,
            panel: [],
            tempRejection:{
                dialog: false
            },
        };
    },
    computed: {
        ...mapGetters([
            "ACTION",
            "GET_HR_DIALOG_TITLE",
            'GET_EMPLOYEE_SCHEDULE_TYPE_SELECTION',
            "DIALOGS",
            "GET_HR_DISPATCH",
            "GET_HR_OTHER_TABS_DIALOG_VIEW",
            "GET_EMPLOYEE_DATA",
            "GET_EMPLOYEE_SCHEDULE_TEMPLATE_SELECTION",
            "GET_LIST_DEPARTMENT",
            "GET_EMPLOYEE_SCHEDULE",
            'GET_JOB_TITLE_SELECTION',
            'GET_HR_USER_DEPARTMENTS',
        ]),
        actions() {
            if (this.ACTION == "Submit") {
                this.disabled_view = false;
                this.isViewBtn = false;
                let filtered_departments = this.dropdowns.department_lists.filter(x=> this.GET_HR_USER_DEPARTMENTS.includes(x.value))
                this.dropdowns.department_lists = filtered_departments
                return "Submit";
            } else if (this.ACTION == "Update") {
                this.disabled_view = false;
                this.isViewBtn = true;
                this.getScheduleTemplateData();
                return "Update";
            } else if (this.ACTION == "View") {
                this.disabled_view = true;
                this.isViewBtn = true;
                this.getScheduleTemplateData();
                return "View";
            }
        },
        dateRangeText () {
            return this.form.effectivity_date.join(' ~ ')
        },
    },
    created() {
        this.initialForm();
    },
    mounted() {
        this.getAllDropdowns();
        this.getEmployeeData();
    },
    methods: {
        clearTempDate(item){
            this.$set(item, "effectivity_date", []);
        },
        joinedDates(date, separator){
            return date.join(separator)
        },
        getAllDropdowns(){
            this.loading.positions = true
            this.loading.department = true
            this.component_dispatches.forEach(el=>{
                this.$store.dispatch(el)
            })
            // this.getEmployeeData();
            this.resetDaysSelection();
        },
        getEmployeeData(){
            this.datatable.items = [];
            let effectivity_dates = this.form.effectivity_date.map((r) => {
                return this.$dayjs(r).format('YYYY-MM-DD')
            })
            this.datatable.loading = true;
            this.loading.employee_lists = true;
            let payload = {
                with_hr_user: 1,
                effectivity_date: effectivity_dates,
                filters: this.filters,
                temporary: this.form.temporary
            }
            this.$store.dispatch('getEmployeeDataDropdown', payload).then((res)=>{
                this.datatable.items = res
                this.dropdowns.employee_lists = res;
                this.datatable.loading = false
                this.loading.employee_lists = false;
            }).catch((err)=>{
                console.log(err)
                this.loading.employee_lists = false;
                this.datatable.loading = false
            })
        },
        initialForm() {
            this.form = {
                schedule_type:'',
                workDays: [],
                effectivity_date: [
                    // this.$dayjs().startOf('month').format('YYYY-MM-DD'),
                    // this.$dayjs().endOf('month').format('YYYY-MM-DD'),
                ],
                effectivity_date_menu: false,
                employee_schedule_trash_ids: [],
                employee_ids: [],
                temporary: 0,
                employee_temporary_schedule_items: [],
                employee_temporary_schedule_items_trash_ids: []
            };
        },
        height(width) {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "100%";
                case "sm":
                    return "100%";
                case "md":
                    return width;
                case "lg":
                    return width;z
                case "xl":
                    return width;
            }
        },
        closeDialog() {
            this.$store.commit("DIALOG", false);
            this.initialForm();
            this.$store.commit('HR_OTHER_TABS_DIALOG_VIEW', {})
        },
        approveEmployeeSchedule(){
            Swal.fire({
                text: `Are you sure you want to approve?`,
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(async(result) => {
                if(result.isConfirmed) {
                    let payload = {
                        id: this.form.id
                    }
                    this.$store.dispatch('approveEmployeeScheduleTemp', payload).then((res)=>{
                        Swal.fire(
                            "Changes Applied to Employee Schedule!",
                            "",
                            "success"
                        )
                        this.closeDialog();
                    }).catch((err)=>{
                        Swal.fire(
                            "Network Error",
                            "",
                            "error"
                        )
                        console.log(err)
                        this.closeDialog();
                    })
                }
            })
        },
        rejectEmployeeScheduleTemp(){
            this.triggerTempRejectionDialog(true)
        },
        addWorkDays(){
            if(this.form.workDays.length < 7){
                if(this.form.schedule_type == 1){
                        this.form.workDays.push(
                            {
                                time_in: null,
                                time_out: null,
                                day: '',
                            }
                        )
                }else{
                    this.form.workDays.push(
                        {
                            hours_to_work_day: null,
                            day: '',
                        }
                    )
                }
            }
        },
        deleteWorkDays(index,item_id = -1){
            this.form.workDays.splice(index,1);
            this.form.employee_schedule_trash_ids.push(item_id)
        },
        resetDaysSelection(){
            this.dropdowns.days = this.$dayjs.weekdays(true).map(day => ({ name: day, isDisabled: false }));
        },
        getScheduleTemplateData(){
            if(this.tmp){
                this.form.id                                                = this.GET_HR_OTHER_TABS_DIALOG_VIEW.id;
                this.form.approved                                          = this.GET_HR_OTHER_TABS_DIALOG_VIEW.approved;
                this.form.rejected                                          = this.GET_HR_OTHER_TABS_DIALOG_VIEW.rejected;
                this.form.name                                              = this.GET_HR_OTHER_TABS_DIALOG_VIEW.name;
                this.form.employee_schedule_id                              = this.GET_HR_OTHER_TABS_DIALOG_VIEW.employee_schedule_id;
                this.form.employee_id                                       = this.GET_HR_OTHER_TABS_DIALOG_VIEW.employee_id;
                this.form.schedule_template                                 = this.GET_HR_OTHER_TABS_DIALOG_VIEW.schedule_template;
                this.form.schedule_template_name                            = this.GET_HR_OTHER_TABS_DIALOG_VIEW.schedule_template_name;
                this.form.break_duration                                    = this.GET_HR_OTHER_TABS_DIALOG_VIEW.break_duration;
                this.form.schedule_type                                     = this.GET_HR_OTHER_TABS_DIALOG_VIEW.schedule_type;
                this.form.break_duration                                    = parseFloat(this.GET_HR_OTHER_TABS_DIALOG_VIEW.break_duration);
                this.form.schedule_type_name                                = this.GET_HR_OTHER_TABS_DIALOG_VIEW.schedule_type_name;
                this.form.employee_name                                     = this.GET_HR_OTHER_TABS_DIALOG_VIEW.full_name;
                this.form.workDays                                          = this.GET_HR_OTHER_TABS_DIALOG_VIEW.employee_schedule_details;
                this.form.effectivity_date                                  =
                                                                                this.GET_HR_OTHER_TABS_DIALOG_VIEW.effectivity_date_start ?
                                                                                [ this.GET_HR_OTHER_TABS_DIALOG_VIEW.effectivity_date_start, this.GET_HR_OTHER_TABS_DIALOG_VIEW.effectivity_date_end ] :
                                                                                [];
                this.includedDates                                          = this.GET_HR_OTHER_TABS_DIALOG_VIEW.existing_date;
                this.form.temporary                                         = this.GET_HR_OTHER_TABS_DIALOG_VIEW.temporary;
                this.form.reject_reason                                     = this.GET_HR_OTHER_TABS_DIALOG_VIEW.reject_reason;
                this.form.employee_temporary_schedule_items                 = this.GET_HR_OTHER_TABS_DIALOG_VIEW.employee_temporary_schedule_items ?? [];
                this.form.employee_temporary_schedule_items_trash_ids       = [];

                let helper = {
                                day_type_id: -1,
                                time_in: null,
                                time_out: null,
                                time_in_menu: false,
                                time_out_menu: false,
                            }
                this.form.employee_temporary_schedule_items.forEach(e => e.helper_settings = helper);
            }
        },
        // setTemplateData(){
        //     if(this.form.schedule_template){
        //         let filtered_template = this.GET_EMPLOYEE_SCHEDULE_TEMPLATE_SELECTION.find((e)=>e.id == this.form.schedule_template)
        //         this.form.workDays = filtered_template.work_schedule_template_details;
        //         this.form.schedule_type = filtered_template.schedule_type_id;
        //         if(!this.form.schedule_template){
        //             this.dropdowns.schedule_type = this.GET_EMPLOYEE_SCHEDULE_TYPE_SELECTION;
        //         }
        //     }
        // },
        setTemplateData() {
            if (this.form.schedule_template) {
                let filtered_template = this.GET_EMPLOYEE_SCHEDULE_TEMPLATE_SELECTION.find((e) => e.id == this.form.schedule_template);
                this.form.workDays = filtered_template.work_schedule_template_details;
                this.form.schedule_type = filtered_template.schedule_type_id;
                this.form.break_duration = filtered_template.break_duration;

                this.form.schedule_type = filtered_template.schedule_type_id;
                if (!this.form.schedule_template) {
                    this.dropdowns.schedule_type = this.GET_EMPLOYEE_SCHEDULE_TYPE_SELECTION;
                }
            }
        },
        filterTemplates(){
            this.dropdowns.schedule_template = this.GET_EMPLOYEE_SCHEDULE_TEMPLATE_SELECTION.filter((e)=>e.schedule_type_id == this.form.schedule_type);
            if(!this.form.schedule_type){
                this.dropdowns.schedule_template = this.GET_EMPLOYEE_SCHEDULE_TEMPLATE_SELECTION;
            }
            if(this.form.schedule_type == 3){
                this.form.workDays = [];
            }
        },
        resetFilters(){
            Object.assign(this.filters, {
                position_ids: this.defaults.position_ids,
                department_ids: this.defaults.department_ids,
                job_level_ids: this.defaults.job_level_ids,
                store_branch_ids: this.defaults.store_branch_ids,
                employee_list: this.defaults.employee_list,
                search: this.defaults.search,
            })
        },
        removeEmployee(i){
            if(this.datatable.items.length != 1){
                Swal.fire({
                    text: `Are you sure you want to remove?`,
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then(async(result) => {
                    if(result.isConfirmed) {
                        this.datatable.items.splice(i,1)
                    }
                })
            }
        },
        allowedDates(date){
            return !this.includedDates.includes(date);
        },
        clearDates(){
            this.form.effectivity_date = [];
        },
        checkIfTemp(){
            if(this.form.temporary == 1){
                if(this.form.effectivity_date.length === 2){
                    return true
                }else{
                    return false
                }
            }else{
                return true;
            }
        },
        changeTab(){

        },
        addTemporarySchedules(){
            this.form.employee_temporary_schedule_items.push({
                employee_temporary_schedule_item_details: [],
                // employee_temporary_schedule_item_details: this.form.workDays.map((e) => {
                //     return {
                //         day: e.day,
                //         time_in: e.time_in,
                //         time_out: e.time_out,
                //         isRestDay: e.isRestDay,
                //         helper_settings: {
                //             day_type_id: null,
                //             time_in: null,
                //             time_out: null,
                //             time_in_menu: false,
                //             time_out_menu: false,
                //         },
                //     };
                // }),
                effectivity_date: [
                ],
                helper_settings: {
                    day_type_id: -1,
                    time_in: null,
                    time_out: null,
                    time_in_menu: false,
                    time_out_menu: false,
                },

                effectivity_date_menu: false,
                employee_schedule_trash_ids: [],
                employee_ids: [],
                temporary: 0,
                employee_temporary_schedule_items: [],
                break_duration: this.form.break_duration,

            })
            // for (let i = 0; i < 7; i++) {
            //     const currentDay = this.$dayjs().day(i + 1).format('dddd');
            //     this.form.employee_temporary_schedule_items.push({
            //         time_in: null,
            //         time_out: null,
            //         day: currentDay,
            //     });
            // }
        },
        checkIfTempDateOverlap(item){
            this.$set(item, 'effectivity_date_menu', false)
            if(this.validateDateOverlap(item) == 1){
                if(item.effectivity_date[0] > item.effectivity_date[1]){
                    Swal.fire(
                        "Date Range Not Valid!",
                        "Please select a valid date range!",
                        "warning"
                    )
                    this.$set(item, 'effectivity_date', [])
                    // this.$set(item, 'employee_temporary_schedule_item_details', [])
                    return false
                }
                let dates = this.getDatesFromRange(item.effectivity_date[0], item.effectivity_date[1], 'array')
                let temp_items = [];
                dates.forEach((x)=>{
                    let index = item.employee_temporary_schedule_item_details.map(e => e.date).indexOf(x);
                    if(index == -1){
                        temp_items.push({
                            date: x,
                            time_in: null,
                            time_out: null,
                            isRestDay: 0
                        })
                    }else{
                        let existing_item = item.employee_temporary_schedule_item_details[index]
                        temp_items.push(existing_item)
                    }
                })
                this.$set(item, 'employee_temporary_schedule_item_details', temp_items)
            }
        },
        validateDateOverlap(item) {
            for (let i = 0; i < this.form.employee_temporary_schedule_items.length; i++) {
                for (let j = i + 1; j < this.form.employee_temporary_schedule_items.length; j++) {
                    const item1 = this.form.employee_temporary_schedule_items[i];
                    const item2 = this.form.employee_temporary_schedule_items[j];

                    if (this.datesOverlap(item1.effectivity_date[0], item1.effectivity_date[1], item2.effectivity_date[0], item2.effectivity_date[1])) {
                        Swal.fire(
                            "Effectivity Date Range selected overlaps!",
                            "Please select other dates!",
                            "warning"
                        )
                        this.$set(item, 'effectivity_date', []);
                        return 0;
                    }
                }
            }
            return 1
        },
        datesOverlap(start1, end1, start2, end2) {
            return start1 <= end2 && end1 >= start2;
        },
        batchUpdate(item){
            let arr = item.employee_temporary_schedule_item_details.map(data => {
                const dayOfWeek = this.$dayjs(data.date).day();
                let condition = true;
                switch(item.helper_settings.day_type_id){
                    case -1:
                        break;
                    case 0:
                        condition = (dayOfWeek === 0 || dayOfWeek === 6);
                        break ;
                    case 1:
                        condition = dayOfWeek >= 1 && dayOfWeek <= 5;
                        break ;
                }
                if(condition){
                    data.time_in = item.helper_settings.time_in;
                    data.time_out = item.helper_settings.time_out;
                    data.isRestDay = 0;
                }
                return data
            });
            this.$set(item, 'employee_temporary_schedule_item_details', arr)
            Swal.fire(
                "Success",
                "Time In/Out successfully applied!",
                "success"
            );
            this.$set(item.helper_settings, 'day_type_id', -1);
            this.$set(item.helper_settings, 'time_in', null);
            this.$set(item.helper_settings, 'time_out', null);
        },
        getDayFromDate(date){
            if(date){
                return this.$dayjs(date).format('ddd');
            }
        },
        checkTempItemsTimeInOut(){
            let incompleteCount = 0;
            this.form.employee_temporary_schedule_items.forEach((x) => {
                let hasItems = x.employee_temporary_schedule_item_details.filter(e => !e.time_in && !e.time_out)
                if(hasItems){
                    incompleteCount += hasItems.length
                }
            })
            return incompleteCount == 0 ? true : false;
        },
        triggerTempRejectionDialog(value){
            this.tempRejection.dialog = value;
        },
        rejectTempNow(){
            if(!this.form.reject_reason){
                Swal.fire(
                    "Please input reject reason!",
                    "",
                    "warning"
                )
                return false;
            }else{
                Swal.fire({
                    text: `Are you sure you want to reject?`,
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then(async(result) => {
                    if(result.isConfirmed) {
                        let payload = {
                            id: this.form.id,
                            reject_reason: this.form.reject_reason
                        }
                        this.$store.dispatch('rejectEmployeeScheduleTemp', payload).then((res)=>{
                            Swal.fire(
                                "Temporary Schedule Rejected!",
                                "",
                                "success"
                            )
                            this.triggerTempRejectionDialog(false);
                            this.closeDialog();
                        }).catch((err)=>{
                            Swal.fire(
                                "Network Error",
                                "",
                                "error"
                            )
                            console.log(err)
                            this.triggerTempRejectionDialog(false);
                            this.closeDialog();
                        })
                    }
                })
            }
        }
    },
    watch: {
        DIALOGS: {
            async handler(val) {
                if (val && this.ACTION == 'Submit') {
                    this.initialForm();
                    this.resetFilters();
                    this.getEmployeeData();
                }
                else if (val && this.ACTION == 'Update' || this.ACTION == 'View'){
                    this.getScheduleTemplateData();
                    this.tmp = true;
                }
            },
        },
        GET_EMPLOYEE_SCHEDULE_TYPE_SELECTION:{
            handler(val){
                this.dropdowns.schedule_type = val;
            }
        },
        GET_EMPLOYEE_SCHEDULE_TEMPLATE_SELECTION:{
            handler(val){
                this.dropdowns.schedule_template = val;
            }
        },
        'form':{
            deep:true,
            handler(val){
                // disable selected day
                let selected_days = []
                this.resetDaysSelection()
                if(val.workDays){
                    selected_days = val.workDays.map(x=> x.day)
                    selected_days.forEach(async x=>{
                        await this.dropdowns.days.forEach(async el=>{
                            if(x == el.name){
                                el.isDisabled = true
                            }
                        })
                    })
                }
                // close effectivity date menu
                if(val.effectivity_date.length == 2){
                    this.form.effectivity_date_menu = false
                    if(val.effectivity_date[0] > val.effectivity_date[1]){
                        Swal.fire("Effectivity Range Not Valid","","warning")
                        this.form.effectivity_date = [];
                    }
                }

                if(val.schedule_template && this.ACTION == 'Submit'){
                    let filtered_template = this.GET_EMPLOYEE_SCHEDULE_TEMPLATE_SELECTION.find((e)=>e.id == val.schedule_template)
                    this.form.workDays = filtered_template.work_schedule_template_details;
                    this.form.schedule_type = filtered_template.schedule_type_id;
                    this.form.break_duration = filtered_template.break_duration;
                    if(!val.schedule_template){
                        this.dropdowns.schedule_type = this.GET_EMPLOYEE_SCHEDULE_TYPE_SELECTION;
                    }
                }
                this.tmp = false
            }
        },
        // GET_EMPLOYEE_DATA:{
        //     handler(val){
        //         if(val){
        //             this.datatable.loading = false;
        //             this.datatable.items = val;
        //         }
        //     }
        // },
        GET_LIST_DEPARTMENT:{
            handler(val){
                this.dropdowns.department_lists = val
            }
        },
        GET_STORE_BRANCHES_SELECTION:{
            handler(val){
                if(val){
                    this.dropdowns.store_branches = val
                }
            }
        },
        filters: {
            handler(val) {
                this.filter_count = [];
                let filtered = this.GET_EMPLOYEE_DATA;
                this.datatable.items = this.GET_EMPLOYEE_DATA;
                // Filter By Department
                if (val.department_ids.length > 0) {
                    filtered = filtered.filter((e) => {
                        return val.department_ids.includes(e.department_id);
                    });
                    this.filter_count.push('dept');
                }

                // Filter By Level
                if (val.job_level_ids.length > 0) {
                    filtered = filtered.filter((e) => {
                        return val.job_level_ids.includes(e.job_level_id);
                    });
                    this.filter_count.push('level');
                }
                // Filter By Store Branch
                if (val.store_branch_ids.length > 0) {
                    filtered = filtered.filter((e) => {
                        return val.store_branch_ids.includes(e.store_branch_id);
                    });
                    this.filter_count.push('branch');
                }

                // Show No Schedule yet
                // if (val.no_schedule_yet) {
                //     filtered = filtered.filter((e) => {
                //         return e.employee_schedule_id === null;
                //     });
                // }

                // Search Filter
                if (val.search) {
                    filtered = filtered.filter(item => item.full_name.toLowerCase().indexOf(val.search) > -1);
                }

                // Employee List Filter
                if (val.employee_list.length > 0) {
                    filtered = filtered.filter((e) => {
                        return val.employee_list.includes(e.value);
                    });
                    this.filter_count.push('employee_list');
                }

                this.datatable.items = filtered;
            },
            deep: true
        },
        GET_HR_USER_DEPARTMENTS:{
            handler(val){
                // Filter Department Selection Based on HR User Departments
                this.hr_user_department_access = val;
                let filtered_departments = this.dropdowns.department_lists.filter(x=> this.hr_user_department_access.includes(x.value))
                this.dropdowns.department_lists = filtered_departments
            }
        }
    },
};
</script>
<style scoped>
    .modal_title {
        position: sticky;
        top: 0;
        z-index: 10;
        background-color: white;
    }
</style>
