<template>
    <v-container class="m-0 py-0">
        <v-card>
            <v-card-title>
                <v-row class="px-4">
                    <v-spacer></v-spacer>
                    <v-col cols="2">
                        <v-text-field
                            v-model="dataTable.search_key"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            @change="reloadDataTable"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-data-table
                class="pb-4"
                :headers="dataTable.headers"
                :items="GET_ADMIN_ASSET_CONDITIONS"
                :options.sync="dataTable.options"
                :server-items-length="dataTable.total_items"
                :loading="dataTable.loading"
                :no-data-text="
                    dataTable.search_key === ''
                        ? 'No asset condition found.'
                        : 'No asset condition match the search.'
                "
                disable-sort
                @update:page="getAll()"
                @update:items-per-page="getAll()"
                >
                <template v-slot:[`item.percentage`]="{ item }">
                    {{ item.percentage }} %
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-btn
                        v-if="userAccess.includes('asset_condition_view')"
                        color="orange"
                        text
                        icon
                        @click="viewOrUpdateAssetCondition(item.id)"
                    ><v-icon small>mdi-eye</v-icon></v-btn>
                    <v-btn
                        v-if="userAccess.includes('asset_condition_edit')"
                        color="blue"
                        text
                        icon
                        @click="viewOrUpdateAssetCondition(item.id, true)"
                    ><v-icon small>mdi-square-edit-outline</v-icon></v-btn>
                    <v-btn
                        v-if="userAccess.includes('asset_condition_delete')"
                        color="red"
                        text
                        icon
                        @click="deleteAssetCondition(item.id)"
                    ><v-icon small>mdi-delete</v-icon></v-btn>
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>

<script>
import swal from 'sweetalert2';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            userAccess: [],

            dataTable: {
                loading: false,
                options: {},
                totalItems: 0,
                search_key: '',

                headers: [
                    { text: 'Code', value: 'code' },
                    { text: 'Description', value: 'description' },
                    { text: 'Actions', width: '10%', value: 'actions', align: 'center' },
                ],
            },
        };
    },

    mounted() {
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        .then(() => {
            this.userAccess = this.USER_ACCESS.map(access => access.actions_code);
        });
        this.getAssetConditions();
    },

    computed: {
        ...mapGetters([
            'USER_ACCESS',
            'GET_ADMIN_ASSET_CONDITION_DATA_TABLE_RELOAD',
            'GET_ADMIN_ASSET_CONDITIONS',
        ]),
    },

    methods: {
        viewOrUpdateAssetCondition(id, update = false) {
            this.$store.commit('ADMIN_ASSET_CONDITION_VIEW_ID', id);
            this.$store.commit('DIALOG', true);
            this.$store.commit('ACTION', update ? 'Update' : 'View');
        },
        deleteAssetCondition(id) {
            swal.fire({
                title: 'Warning!',
                text: 'Are you sure you want to delete this asset condition?',
                icon: 'question',
                cancelButtonText: "Cancel",
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#397373",
                cancelButtonColor: "grey",
                reverseButtons: true,
                showLoaderOnConfirm: true,

                preConfirm: async () => {
                    return this.$store.dispatch('adminAssetConditionDelete', {url: id}).then(() => {
                        this.$store.commit('ADMIN_ASSET_CONDITION_DATA_TABLE_RELOAD', true);

                        swal.fire({
                            title: 'Delete asset condition Success',
                            icon: 'success',
                        });
                    }).catch(error => {
                        console.log(error);
                        swal.fire({
                            title: 'Delete asset condition Failed',
                            icon: 'error',
                        });
                    });
                },
            });
        },
        getAssetConditions() {
            const payload = {
                url: 'get-asset-conditions',
                search_key: this.dataTable.search_key,
                ...this.dataTable.options,
            };

            this.dataTable.loading = true;
            this.$store.dispatch('adminAssetConditionGet', payload).then(response => {
                this.$store.commit('ADMIN_ASSET_CONDITIONS', response.data.data.data);
                this.dataTable.loading = false;
                this.dataTable.totalItems = response.data.total_items;
            });
        },
        reloadDataTable() {
            this.dataTable.options.page = 1;
            this.getAssetConditions();
        },
    },

    watch: {
        'GET_ADMIN_ASSET_CONDITION_DATA_TABLE_RELOAD': {
            handler(val) {
                if (!val) return;

                this.getAssetConditions();
                this.$store.commit('ADMIN_ASSET_CONDITION_DATA_TABLE_RELOAD', false);
            },
            immediate: true,
        },
    },
}
</script>