<template>
    <span>
        <v-tooltip top>
            <template v-slot:activator="{ on }">
                <v-btn
                    v-on="on"
                    elevation="0"
                    text
                    icon
                    @click="handleFileUploadClick"
                ><v-icon small>mdi-paperclip</v-icon></v-btn>
            </template>
            <span>Upload Files</span>
        </v-tooltip>
        <div>
            <input
                ref="inputUploader"
                type="file"
                name="files"
                style="display: none;"
                multiple
                @change="onChange"
            />
        </div>
    </span>
</template>

<script>
import swal from 'sweetalert2';

export default {
    props:{
        fileIndex: {
            default: 0,
            type: Number,
        },
        onFileChange: {
            type: Function,
        },
        onFileClear: {
            type: Function,
        },
    },

    data() {
        return {
            attachments: [],
            files: [],
        };
    },

    methods: {
        handleFileUploadClick() {
            this.$refs.inputUploader.click();
        },
        async onChange(e) {
            if (!e) return;

            this.attachments = [];

            const config = {
                headers: { 'content-type': 'multipart/form-data' },
            }
            const fileTypes = ['jpg', 'jpeg', 'png', 'csv', 'txt', 'xlx', 'xls', 'xlsx', 'pdf', 'zip', 'doc', 'docx'];
            const selectedFiles = e.target.files;

            if (selectedFiles.length <= 0) return false;

            selectedFiles.forEach(element => {
                this.attachments.push(element);
            });

            const fileSizeChecker = _.compact(this.attachments).every(x=> {
                return x.size < 8192000;
            });

            if (!fileSizeChecker) {
                this.attachments = [];
                
                return swal.fire(
                    "",
                    'Each File size not more than 8MB',
                    "warning",
                );
            }

            const fileTypeChecker = _.compact(this.attachments).every(x=> {
                let fileName = x.name;
                let fileNameLength = fileName.split('.').length;
                let ext = fileName.split('.')[fileNameLength - 1];
                return fileTypes.includes(ext);
            });

            if (!fileTypeChecker) {
                this.attachments = [];

                return swal.fire(
                    "",
                    'Each File must be jpg, jpeg, png, csv, txt, xlx, xls, xlsx, pdf and zip type',
                    "warning",
                );
            }

            this.files = [];

            for (let i = 0; i < this.attachments.length; i++) {
                const element = this.attachments[i]
                let reader = new FileReader()
                reader.readAsDataURL(element)
                await new Promise((resolve) => {
                    reader.onloadend = () => {
                        this.files.push({
                            local: true,
                            name: element.name,
                            type: element.type,
                            data: reader.result.split(',')[1],
                            localFileState: element,
                        })
                        resolve();
                    }
                });
            }

            this.onFileChange({
                config: config,
                attachments: this.attachments,
                files: this.files,
            }, this.fileIndex);
        },
    },
}
</script>

<style scoped>
    .v-text-field {
        margin: 0 !important;
        padding: 0 !important;
    }
</style>