<template>
	<div class="w-100">
		<v-app id="my_vue_app">
			<v-card>
                <v-card-title>
                    <v-container>
                        <v-row>
                            <v-col cols="12" lg="4" md="2" sm="2">
                                <v-autocomplete
                                    v-model="filter.department_id"
                                    @change="getAllLeaveBalance(search)"
                                    :items="dropdown.department_list"
                                    item-text="text"
                                    item-value="value"
                                    label="Department"
                                    auto-select-first
                                    deletable-chips
                                    small-chips
                                    clearable
                                    outlined
                                    required
                                    multiple
                                    dense
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="12" sm="12" md="3" class="d-flex justify-content-end align-items-center">
                                <a
                                    v-for="(status, index ) in statuses" :key="index"
                                    :class="`ml-1 badge badge-${status.text.toLowerCase()} ${status.value == selected_status ? `selected` : `non-selected`}`" @click="selectedStatus(status)"
                                    >
                                {{ status.text ? status.text.replace('-'," ").toLowerCase() : null  }}
                                </a>
                            </v-col>
                        </v-row>
                        <v-row class="mb-2">
                            <v-spacer></v-spacer>
                            <div class="row col-sm-12 col-md-4 mr-3 align-items-end">
                                <v-text-field
                                    id="searchBar"
                                    v-model="search"
                                    label="Search"
                                    single-line
                                    hide-details
                                    @change="searchInput()"
                                >
                                </v-text-field>
                                <v-icon @click="searchInput()">mdi-magnify</v-icon>
                            </div>
                        </v-row>
                    </v-container>
                </v-card-title>
				<v-container fluid>
					<v-data-table
						:headers="datatable.headers"
						:items="datatable.items"
                        :options.sync="datatable.options"
                        :server-items-length="datatable.totalItems"
                        :loading="loading.table"
						:search="search"
						:multi-sort="true"
						:disable-sort="true"
						loading-text="Loading... Please wait"
						fixed-header
                        dense
					>
						<template v-slot:[`item.action`]="{ item }">
                            <v-btn text icon color="orange" v-if="userAccess.view">
                                <v-icon
                                class="btn-action"
                                small
                                @click="viewLeaveBalance(item)"
                                >mdi-eye</v-icon
                                >
                            </v-btn>
                            <!-- <v-btn text icon color="blue" v-if="userAccess.edit">
                                <v-icon
                                class="btn-action"
                                small
                                @click="editLeaveBalance(item)"
                                >mdi-pencil</v-icon
                                >
                            </v-btn> -->
                            <!-- <v-btn text icon color="red" v-if="userAccess.delete">
                                <v-icon
                                class="btn-action"
                                small
                                @click="deleteLeaveBalance(item.id)"
                                >mdi-delete</v-icon
                                >
                            </v-btn> -->
						</template>
						<template v-slot:[`item.status_name`]="{ item }">
                            <span :class="`badge badge-${item.status_name.toLowerCase()}`">{{ item.status_name.toLowerCase() }}</span>
                        </template>
					</v-data-table>
				</v-container>
			</v-card>
		</v-app>
	</div>
</template>
<script>
	import swal from "sweetalert2";
	import { mapGetters } from 'vuex';
	export default {
	data() {
		return {
			host: window.location.origin,
            datatable:{
                headers: [
                    { text: "Employee Name", align: "left", value: "full_name"},
                    { text: "Employment Date", align: "left", value: "employment_date"},
                    { text: "Department", align: "left", value: "department_name"},
                    { text: "Status", align: "left", value: "status_name"},
                    { text: "Action", align: "center", value: "action" },
                ],
                items: [],
                itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
                itemsPerPage: 10,
			    options: {},
                totalItems: 0
            },
            loading:{
                table: false
            },
			search: "",
			userAccess: {
				view: false,
				create: false,
				edit: false,
				delete: false,
			},
            selected_status: '',
            statuses: [
                {text: 'All', value: 0}
            ],
            checked_department_ids: [],
            filter:{
                department_id: []
            },
            dropdown:{
                department_list: []
            },
		};
	},
	computed:{
		...mapGetters([
			'USER_ACCESS',
			'GET_HR_LEAVES_NEW_DATA',
			'GET_DEPARTMENT_ROLE_LOAD',
			'GET_HR_TAB_IS_ACTIVE',
			'GET_HR_DISPATCH',
			'GET_HR_LEAVES_TYPE',
            'GET_HR_STATUS_SELECTION',
            'GET_DEPARTMENT_ACCESS',
            'GET_LIST_DEPARTMENT',
            'USERACCOUNT_LOAD'
		]),
		itemsTable(){
			if(this.GET_HR_TAB_IS_ACTIVE == 'leave_type'){
				return this.GET_HR_LEAVES_TYPE
			}
		}
	},
	mounted() {
        this.$store.dispatch("checkDepartmentAccess");
        this.$store.dispatch('getStatusHR')
		this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        this.$store.dispatch('getDepartment')
        this.$store.dispatch('getLeaveTypeSelection', {default: 1}).then((res)=>{
            let leave_types = res.map((e) => {
                return {
                    text: e.code,
                    value: e.code,
                };
            }).filter((x) => x.text !== undefined );
            this.datatable.headers.splice(3, 0, ...leave_types);
        })
	},
	methods: {
        async checkDepartmentAccess(){
            let payload ={
                access: this.USER_ACCESS.map(e => e.actions_code)
            }
            await this.$store.dispatch('checkDepartmentAccess', payload).then(response=>{
                this.checked_department_ids = response;
            })
        },
		searchInput(){
			this.getAllLeaveBalance(this.search);
		},
		async getAllLeaveBalance(filter = null) {
            await this.checkDepartmentAccess()
            this.loading.table = true;
            this.datatable.items = [];
            let payload = {
                page: this.datatable.options.page,
                itemsPerPage: this.datatable.options.itemsPerPage,
                search: filter,
                sortBy: this.datatable.options.sortBy,
                sortDesc: this.datatable.options.sortDesc,
                selected_status: this.selected_status,
                department_ids: this.GET_DEPARTMENT_ACCESS,
                department_id: this.filter.department_id
            };
            this.$store.dispatch(this.GET_HR_DISPATCH.get, payload).then((response) => {
                this.loading.table          = false;
                this.datatable.items        = response.data.data;
                this.datatable.totalItems   = response.data.total;
            }).catch((error) => {
                swal.fire({
                    title: "Error",
                    icon: "error",
                    timer: 1500,
                    allowOutsideClick: false,
                });
                this.loading.table = false;
                console.log(error)
            });
		},
		async viewLeaveBalance(item) {
            let payload = {
                id: item.id
            }
            await this.$store.dispatch(this.GET_HR_DISPATCH.show, payload).then(res=>{
                let item_data = {
                    leaves: res,
                    id: item.id,
                    pro_rated: item.pro_rated
                }
                this.$store.commit("HR_LEAVES_DATA_VIEW", item_data);
                this.$store.commit("DIALOG", true);
                this.$store.commit("ACTION", "View");
            }).catch((error)=>{
                swal.fire(
                    "Network Error",
                    "",
                    "error"
                )
                console.log(error)
            })
		},
		async editLeaveBalance(item) {
			let payload = {
                id: item.id
            }
            await this.$store.dispatch(this.GET_HR_DISPATCH.show, payload).then(res=>{
                let item_data = {
                    leaves: res,
                    id: item.id
                }
                this.$store.commit("HR_LEAVES_DATA_VIEW", item_data);
                this.$store.commit("DIALOG", true);
                this.$store.commit("ACTION", "Update");
            }).catch((error)=>{
                swal.fire(
                    "Network Error",
                    "",
                    "error"
                )
                console.log(error)
            })
		},
		deleteLeaveBalance(id) {
            swal.fire({
                    icon: 'question',
                    text: "Are you sure you want to Delete?",
                    cancelButtonText: "Cancel",
                    showCancelButton: true,
                    allowOutsideClick: false,
                    confirmButtonColor: "#397373",
                    cancelButtonColor: "#397373",
                })
                .then((result) => {
                if (result.isConfirmed) {
                    this.$store
                    .dispatch(this.GET_HR_DISPATCH.delete, id)
                    .then((success) => {
                        swal.fire({
                            title: 'Success',
                            icon: 'success',
                            timer: 1500,
                        });
                    });
                }
            });
		},
		height(width) {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs': return "100%"
				case 'sm': return "100%"
				case 'md': return width
				case 'lg': return width
				case 'xl': return width
			}
    	},
        selectedStatus(data){
            this.selected_status = data.value
            this.getAllLeaveBalance(this.search)
        },
	},
	watch:{
        'datatable.options':{
            handler(){
                this.getAllLeaveBalance(this.search);
            },
            deep: true
        },
		GET_HR_LEAVES_NEW_DATA:{
			handler(val){
				if(val && this.GET_HR_TAB_IS_ACTIVE == 'leave_balance'){
                    this.getAllLeaveBalance(this.search)
                    this.$store.commit('HR_LEAVES_NEW_DATA',false);
				}
			}
		},
		USER_ACCESS: {
			handler(val) {
				if(val != "fail"){
					this.userAccess.edit = false;
					this.userAccess.delete = false;
					this.userAccess.view = false;
					this.userAccess.create = false;
						val.forEach(e => {
						if (e.actions_code == 'edit') {
							this.userAccess.edit = true;
						} else if (e.actions_code == 'delete') {
							this.userAccess.delete = true;
						} else if (e.actions_code == 'view') {
							this.userAccess.view = true;
						} else if (e.actions_code == 'create') {
							this.userAccess.create = true;
						}
					});
				}
			}
		},
        GET_HR_STATUS_SELECTION:{
            handler(val){
                this.statuses.push(...val)
                // this.statuses.unshift({text:'All',value:0})

            }
        },
        GET_LIST_DEPARTMENT: {
            handler(val) {
                this.hr_id = val.find(e => e.code == 'HR-A').value
                this.dropdown.department_list = val
                if(this.USERACCOUNT_LOAD.department_id !== this.hr_id){
                    val = val.filter(item => this.checked_department_ids.includes(item.value) || this.USERACCOUNT_LOAD.department_id === item.value);
                   this.dropdown.department_list = val
                }
            },
            deep: true
        },
	}
};
</script>
<style>

.selected {
    opacity: 100%;
}

.non-selected {
    opacity: 40%;
}
</style>
