<template>
    <div class="tabs-body-color">
        <v-container fluid class="pa-0">
                <v-row class="pt-4 pl-2" v-if="userAccess.create && GET_CHART_OF_ACCOUNTS_STATUS.tab_name == 'ACCOUNT'">
                    <v-col cols="6" class="d-flex no-block align-items-center">
                        <div class="btn-toolbar pl-0">
                            <v-btn class="ml-1 mb-2" @click="AddDialog()">
                                <v-icon>mdi-plus</v-icon>ADD
                            </v-btn>
                        </div>
                    </v-col>
                    <v-col cols="6" class="d-flex no-block align-items-center justify-end">
                        <div class="btn-toolbar pl-0">
                            <v-btn class="mr-3 mb-2" @click="ImportExcelDialog()" :disabled="loading">
                                <span v-if="!loading">IMPORT</span>
                                <v-progress-circular v-else indeterminate color="primary" size="24"></v-progress-circular>
                            </v-btn>
                            <v-btn class="mr-3 mb-2" @click="ExportExcel()" :disabled="loading">
                                <span v-if="!loading">EXPORT</span>
                                <v-progress-circular v-else indeterminate color="primary" size="24"></v-progress-circular>
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
                <v-row class="pt-4 pl-2" v-if="userAccess.create && tabNames.includes(GET_CHART_OF_ACCOUNTS_STATUS.tab_name) ">
                    <v-col cols="6" class="d-flex no-block align-items-center">
                        <div class="btn-toolbar pl-0">
                            <v-btn class="ml-1 mb-2" @click="AddDialog()">
                                <v-icon>mdi-plus</v-icon>ADD
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            <v-row>
                <ChartOfAccountTableComponent v-if="GET_CHART_OF_ACCOUNTS_STATUS.tab_name == isActive_tab"></ChartOfAccountTableComponent>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import ChartOfAccountTableComponent from '@/views/main/modules/Accounting/ChartOfAccounts/ChartOfAccountTableComponent.vue';
import { mapGetters } from 'vuex'

export default {
    components: {
        ChartOfAccountTableComponent,
    },
    props:["isActive_tab"],
    data() {
        return {
            userAccess:{
                create:false
            },
            dialog_width:"",
            loading: false,
            tabNames: ['TYPES', 'ACCOUNT GROUP'],
        }
    },
    computed: {
        ...mapGetters([
            'USER_ACCESS',
            'GET_CHART_OF_ACCOUNTS_STATUS',
            'GET_CHART_OF_ACCOUNTS',
            "GET_ACCOUNT_TYPES_SELECTION",
            "GET_GENERAL_LEDGER",
            "GET_PV_COMPANIES_SELECTION",
            "GET_PV_STORE_BRANCHES_SELECTION"
        ]),
    },
    mounted() {},
    methods: {
        AddDialog(){
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", "Submit");
        },
        enterBillDialog(){
            this.$store.commit("ENTER_BILL_DIALOG", true);
            this.dialog_width = "50%"
            this.$store.commit("ACTION", "Submit");
        },
        width(width) {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return "100%"
                case 'sm': return "100%"
                case 'md': return '100%'
                case 'lg': return '100%'
                case 'xl': return width
            }
        },
        ImportExcelDialog() {
            this.$store.commit("COA_IMPORT_DIALOG", true);
            this.$store.commit("ACTION", "Submit");
        },
        async ExportExcel() {
            this.loading = true;
            try{
                const workbook = new this.$exceljs.Workbook();

                const sheetNames = [
                    'COA',
                    'Company (Guide)',
                    'Store Branch (Guide)',
                ];

                const accountTypes = this.$store.getters.GET_ACCOUNT_TYPES_SELECTION;
                const accountTypeMap = accountTypes.reduce((map, type) => {
                    map[type.value] = type.text;
                    return map;
                }, {});

                sheetNames.forEach((sheetName) => {
                    const worksheet = workbook.addWorksheet(sheetName);
                    if (sheetName === 'COA') {
                        const headerRow = worksheet.addRow([
                            'Mother Account Code',
                            'Mother Account',
                            'Child Account Code',
                            'Child Account',
                            'Contra Account Code',
                            'Contra Account',
                            'Account Type',
                            'Fundamental',
                            'Account Group',
                            'Companies',
                            'Branches',
                        ]);

                        headerRow.eachCell((cell) => {
                            cell.font = { bold: true, color: { argb: 'FFFFFFFF' }, underline: true };
                        });
                        for (let i = 1; i <= 11; i++) {
                            headerRow.getCell(i).fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFED7D31' },
                            };
                        }


                        this.GET_CHART_OF_ACCOUNTS.data.forEach((motherAccount) => {
                            motherAccount.children.forEach((childAccount) => {
                                worksheet.addRow([
                                    // motherAccount.code,
                                    // motherAccount.name,
                                    '',
                                    '',
                                    childAccount.code,
                                    childAccount.name,
                                    childAccount.contra_coa_code,
                                    childAccount.contra_coa_name,
                                    accountTypeMap[childAccount.account_type_id],
                                    childAccount.fundamental,
                                    childAccount.account_group,
                                    this.mapCompanyIdsToNames(childAccount.companies),
                                    this.mapBranchIdsToCodes(childAccount.branches),
                                ]);

                                if (childAccount.children.length > 0) {
                                    childAccount.children.forEach(element => {
                                        worksheet.addRow([
                                            childAccount.code,
                                            childAccount.name,
                                            element.code,
                                            element.name,
                                            element.contra_coa_code,
                                            element.contra_coa_name,
                                            accountTypeMap[element.account_type_id],
                                            element.fundamental ?? childAccount.fundamental,
                                            childAccount.account_group,
                                            this.mapCompanyIdsToNames(element.companies),
                                            this.mapBranchIdsToCodes(element.branches),
                                        ]);
                                    });
                                }
                            });
                        });
                    }
                    if (sheetName === 'Company (Guide)') {
                            const headerRow = worksheet.addRow(['', 'USE THIS'],[
                                'CODE',
                                'NAME'
                            ]);
                            headerRow.getCell(2).font = { italic: true, color: { argb: 'FFFF0000' } };

                            let companies = this.GET_PV_COMPANIES_SELECTION;
                            companies.forEach((company) => {
                                worksheet.addRow([company.code,company.text]);
                            });
                    } else if (sheetName === 'Store Branch (Guide)') {
                        const headerRow = worksheet.addRow(['','','USE THIS',''],[
                            'COMPANY CODE',
                            'COMPANY NAME',
                            'STORE BRANCH CODE',
                            'STORE BRANCH NAME'
                        ]);
                        headerRow.getCell(3).font = { italic: true, color: { argb: 'FFFF0000' } };

                        let storeBranches = this.GET_PV_STORE_BRANCHES_SELECTION;
                        storeBranches.forEach((storeBranch) => {
                            let companyData = this.GET_PV_COMPANIES_SELECTION.find(e=>e.value == storeBranch.company_id)
                            if (companyData != undefined) {
                                worksheet.addRow([
                                    companyData.code,
                                    companyData.text,
                                    storeBranch.code,
                                    storeBranch.text
                                ]);
                            }
                        });
                    }
                });

                const buffer = await workbook.xlsx.writeBuffer();
                const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'COA.xlsx';

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(link.href);
            } finally {
                setTimeout(() => {
                    this.loading = false;
                }, 1500)
            }
        },
        mapCompanyIdsToNames(companyIds) {
            let joinedCompanies = ''
            joinedCompanies = companyIds.map(id => this.GET_PV_COMPANIES_SELECTION.find(c=>c.value == id).text).join(', ');
            return joinedCompanies
        },
        mapBranchIdsToCodes(branchIds) {
            let joinedBranches = ''
            joinedBranches = branchIds.map(id => this.GET_PV_STORE_BRANCHES_SELECTION.find(b=>b.value == id).code).join(', ');
            return joinedBranches
        }
    },
    watch:{
        USER_ACCESS:{
            handler(val){
                if(val != "fail") {
                    this.userAccess.create = false;
                    val.forEach(e => {
                        if (e.actions_code == 'create') {
                            this.userAccess.create = true;
                            }
                    });
                }
            }
        },
    }
};
</script>
