<template>
  <v-container fluid>
    <v-card-title class="pa-3">
        <v-row>
          <div class="row mb-1 mt-1" v-if="userAccess.includes('create') && GET_STOCK_MENU != 'pending' && GET_STOCK_MENU != 'for_approval' && GET_STOCK_MENU != 'approved'">
            <div class="col-12 d-flex no-block align-items-center">
              <div class="btn-toolbar">
                <v-btn data-modal-id="addContainerModal" @click="adddialogs()" >
                  <v-icon>mdi-plus</v-icon>Add
                </v-btn>
              </div>
            </div>
          </div>
        </v-row>
    </v-card-title>
    <usersTable :selectedAction="selectedAction" ref="usersTable"></usersTable>
    <dialogs :selectedAction="selectedAction" :cp="components" :scrollable="true" :retain-focus="false" :width="'75%'"></dialogs>
  </v-container>
</template>
<script>
import { computed } from "vue";
import usersTable from "@/views/main/modules/Admin/AdminStockAdjustment/AdminStockAdjustmentTableComponent.vue";
import batchAddtionDialog from "@/views/main/layouts/dialogs/Admin/AdminStockAdjustmentBatchDialog.vue";
import Dialogs from "@/views/main/layouts/dialogs/Dialog.vue";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
export default {
  components: {
    usersTable,
    Dialogs,
  },
  data() {
    return {
      color: "orange",
      action: "add",
      dialog_text: false,
      dialog_icon: "mdi-plus",
      components: batchAddtionDialog,
      userAccess: [],
      selectedAction: "",
      resetFilter:null,
    };
  },

  mounted() {
    this.$store.dispatch('getAdminWarehouseCodeDropdown');
    // this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
    // this.$store.dispatch(
    //   "checkAccessUser",
    //   this.$router.currentRoute.params.id
    // );
    // this.$store.dispatch("getAllWarehouses");
    // this.$store.dispatch("getAllUsersSelect");
  },
  methods: {
    adddialogs() {
      this.$store.commit("DIALOG", true);
      this.$store.commit("ACTION", "Submit");
      this.selectedAction = "Add";
    },

  },
  computed: {
    ...mapGetters([
        "DIALOGS",
        "USER_ACCESS",
        "USERACCOUNT_LOAD",
        'GET_STOCK_MENU'
    ]),
  },
  watch: {
    USER_ACCESS:{
        handler(val){
            if(val != "fail")
            this.userAccess = val.map(e=>e.actions_code);
        }
    },
  },
};
</script>
