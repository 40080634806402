<template>
    <div class="w-100">
        <v-app id="my_vue_app">
            <v-card>
                <v-card-title>
                    <v-row class="pa-3 justify-center">
                        <v-col class="text-left">
                            <h3>Edit Email Message</h3>
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>
                </v-card-title>

                <v-container fluid>
                    <v-row class="pa-3">                                                                <!--Placement of Selection and Button Actions-->
                        <v-col cols="3" >
                            <v-autocomplete
                                v-model="form.application_status_id"
                                :items="dropdowns.application_status_selection"
                                :loading="loading.application_status_selection"
                                @change="changeMessageTable()"
                                auto-select-first
                                item-text="text"
                                item-value="value"
                                label="Application Status"
                                outlined
                                required
                                dense
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="2">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }" >
                                    <v-btn :disabled="editorOptions.editorData ? false: true" v-on="on" v-bind="attrs" text @click="preview()">
                                        <v-icon color="orange" class="font-size-12">mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                                    <span>Preview</span>
                            </v-tooltip>

                            <!-- <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }" > -->
                                    <v-badge
                                        bordered
                                        color="primary"
                                        overlap
                                        v-if="!isSaved"
                                    >
                                        <v-btn text @click="saveEmailMessageTable()">
                                            <v-icon :color="isSaved ? 'blue': ''" class="font-size-12">mdi-content-save</v-icon>
                                        </v-btn>
                                    </v-badge>

                                    <v-btn v-if="isSaved"  text @click="saveEmailMessageTable()">
                                        <v-icon :color="isSaved ? 'blue': ''" class="font-size-12">mdi-content-save</v-icon>
                                    </v-btn>
                                <!-- </template>
                                    <span>Save</span>
                            </v-tooltip> -->
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>
                    <v-row ref="scrollableRow" class="pa-3 overflow-auto" style="max-height: 60vh;">    <!--Rich Text Editor-->
                        <v-col cols="12">
                            <v-card>
                                <ckeditor :editor="editorOptions.editor" v-model="editorOptions.editorData" :config="editorOptions.editorConfig"></ckeditor>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
                <v-dialog v-model="preview_dialog" width="35%" persistent>                                         <!--Preview of the Settings-->
                     <div class="preview" >
                        <v-card class="">
                            <v-card-title class="pr-0 pt-0 pl-3 pb-0">
                                <v-row class="m-0">
                                    <v-col cols="pull-right-10 p-2">
                                        <v-btn
                                            text
                                            icon
                                            small
                                            color="gray"
                                            class="float-right"
                                            @click="closeDialog()"
                                        >
                                            <v-icon>mdi-close-circle</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <div class="container">
                                <div class="section">

                                    <div>

                                        <p class="text">Dear Valued Applicant,</p>

                                        <p class="text">Name	: APPLICANT NAME</p>
                                        <p class="text">Application Date : APPLICATION SUBMISSION DATE</p>
                                        <!-- <p class="text">Dear {{ ucfirst($data['name']) }},</p> -->

                                        <div class="px-8 py-0 text" v-html="editorOptions.editorData"></div>


                                        <!-- <span class="code bg">{{$data['body']}}</span> -->

                                        <br>

                                        <p class="text" v-if="form.application_status_id == 6">You can upload your requirements in the requirements section of the application form thru the link below.</p>

                                        <a class="text" v-if="form.application_status_id == 6">requirements.ingco.com.ph</a>

                                        <p class="text"><i>CONFIDENTIALITY NOTICE: The content of this email is confidential and intended for the recipient specified in the message only. It is strictly forbidden to share any part of this message with any third party, without a written consent of the sender.
                                        If you received this message by mistake, please reply to this message and follow with its deletion, so that we can ensure such a mistake does not occur in the future.</i></p>
                                    </div>
                                </div>
                            </div>
                        </v-card>
                    </div>
                </v-dialog>
            </v-card>
        </v-app>
    </div>
</template>
<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import Swal from 'sweetalert2'

import { mapGetters } from 'vuex';
export default {
    data(){
        return {
            userAccess: {
                view: false,
                create: false,
                edit: false,
                delete: false,
            },
            editorOptions:{
                editor: ClassicEditor,
                editorData: '',
                editorConfig: {
                },
            },
            dropdowns:{
                application_status_selection:[]
            },
            form:{},

            loading:{
                application_status_selection:true
            },

            defaults:{
                application_status_id : 1
            },

            preview_dialog: false,
            isSaved: true,
            tmp:false
        }
    },
    created(){
        this.$store.dispatch('GetApplicantStatusMessage')
    },
    async mounted(){
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        await this.$store.dispatch('getApplicationStatusSelection');
        await this.setDefaultValues()
        // document.addEventListener("keydown", this.handleKeyDown);
        window.addEventListener('beforeunload', this.confirmClose);
    },
    computed:{
        ...mapGetters(['GET_APPLICATION_STATUS_SELECTION','GET_HR_DISPATCH','GET_HR_APPLICATION_MESSAGE','GET_HR_RECRUITMENT_NEW_DATA'])
    },
    components:{
    },
    beforeUnmount() {
        window.removeEventListener('beforeunload', this.confirmClose);
        document.removeEventListener("keydown", this.handleKeyDown);
    },
    methods:{
        confirmClose(event) {
            if (!this.isSaved) {
                event.preventDefault();
                event.returnValue = '';
                return 'Are you sure you want to leave this page?';
            }
        },
        handleKeyDown(event) {
        // Check if Ctrl + S is pressed
            if (event.ctrlKey && event.key === "s") {
                event.preventDefault(); // Prevent default browser save behavior
                this.saveEmailMessageTable();
            }
        },
        async setDefaultValues() {
            Object.assign(this.form, {
                application_status_id: this.defaults.application_status_id,
            })
            await this.changeMessageTable()
        },
        checkRowScrollable() {
            const row = this.$refs.scrollableRow;
            if(row){
                const isScrollable = row.clientHeight < row.scrollHeight;
                if (isScrollable) {
                } else {
                    $('.ck').find('.ck-sticky-panel').removeClass('ck-sticky-panel')
                }
            }
        },
        saveEmailMessageTable(){
            const application_status_id_swal_text = this.form.application_status_id ? '' : 'Application Status';

			const array_text = [application_status_id_swal_text]

			const filtered = array_text.filter(function (el) {
				return el.replace(',', '');
			})

			this.form.message = this.editorOptions.editorData;

			if (this.form.application_status_id) {
				this.$store.dispatch(this.GET_HR_DISPATCH.update, this.form).then(response=>{
                    Swal.fire(response.icon.toUpperCase(),response.message,response.icon);
                })
			} else {
				Swal.fire({
					text: `Fill Required Fields (${filtered})`,
					icon: 'warning',
					allowOutsideClick: false,
				});
			}
        },
        changeMessageTable(){
            this.editorOptions.editorData =  this.GET_HR_APPLICATION_MESSAGE.find(e=>e.id == this.form.application_status_id).editorData;
            this.isSaved = true
            this.tmp = true
        },
        preview(){
            this.preview_dialog = true
        },
        closeDialog(){
            this.preview_dialog = false
        }
    },
    watch:{
        USER_ACCESS: {
            handler(val) {
                if(val != "fail"){
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                this.userAccess.create = false;
                    val.forEach(e => {
                        if (e.actions_code == 'edit') {
                            this.userAccess.edit = true;
                        } else if (e.actions_code == 'delete') {
                            this.userAccess.delete = true;
                        } else if (e.actions_code == 'view') {
                            this.userAccess.view = true;
                        } else if (e.actions_code == 'create') {
                            this.userAccess.create = true;
                        }
                    });
                }
            }
        },
        GET_APPLICATION_STATUS_SELECTION:{
            handler(val){
                this.dropdowns.application_status_selection = val
                this.loading.application_status_selection = false
                this.tmp = true
            }
        },
         'editorOptions.editorData': {
            handler(val){
                if(this.tmp != true){
                    this.isSaved = false
                }
                this.tmp = false
                this.checkRowScrollable()
            }
        },
        GET_HR_RECRUITMENT_NEW_DATA:{
            handler(val){
                if(val){
                    this.isSaved = true
                    this.$store.commit('HR_RECRUITMENT_NEW_DATA',false)
                }
            }
        },
    }
}
</script>
<style scoped>

.container
{
    text-align: center;

}
.code{
    font-size: 3rem;
    font-weight: bold;
    color:black;
}
.bg{
    background-color: #F69F1A;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 200%;
    text-align: start ;
    width: 500px;
    border: 2px solid black;
    overflow: hidden;
    display: inline-block;
    padding: 10px;
    margin: 15px;
}
.text{
    text-align: left;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
}
</style>
