<template >
  <div style="display:none;"></div>
</template>
<script charset="utf-8">
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
import { mapGetters } from 'vuex';
import GILL from '@/assets/fonts/Gil_normal';
export default {
  mixins: [
      SharedFunctionsComponentVue,
      GILL
  ],
  data() {
      return {
        printData: [],
        chunk_data: [],
        tempItems: [],
        temp_index: 0,
        base64Image: null,
        system: [],
      }
  },
  computed:{
      ...mapGetters([
        // "GET_PRINT_PURCHASE_ORDER",
        // "GET_EDIT_PURCHASE_ORDER_TABLE",
        // "GET_UOM_SELECTION",
        // "GET_ADMIN_CLASSIFICATION_SELECTION",
        "PURCHASE_ORDER",
        "GET_SYSTEM",
        ]),
  },
  mounted(){
  },
  methods: {
    formatDate(date) {
      date = new Date(date);
      const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
      const day = String(date.getDate()).padStart(2, '0');
      const year = date.getFullYear();
      const formattedDate = `${day}-${month}-${year}`;

      return formattedDate;
    },
    // formatCurrencyPHPwithoutRoundingUp(number) {
    //   const formatted = parseFloat(number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

    //   return formatted;
    // },
    async renderPDF(headerAndFooter){
        this.temp_index = 0
        let paperWidth = 612;
        let paperHeight = 792;
        let margin = 36;
        let host = window.location.origin;

        let doc = new this.$jspdf({
            orientation: 'portrait',
            unit: 'pt',
            format: 'letter'
        });

        // let tempItems = [];
        let printDetails = this.printData;

        // for (let i  = 0; i < this.printData.item_list.length; i++) {
        //     tempItems.push(this.printData.item_list[i]);
        // }
        this.chunk_data.forEach((array)=> {
            this.tempItems = array
            let current_page = doc.getCurrentPageInfo().pageNumber;
            doc.autoTable({
                theme: 'grid',
                tableWidth: paperWidth - (margin * 2),
                margin: {top: 205, bottom: (this.tempItems.length == 25 && current_page == this.chunk_data.length) ? 80 : 145, left:margin, right: margin},
                styles: {
                    textColor: '#000',
                    lineWidth: 0.1,
                    lineColor: '#000',
                    fontSize: 8,
                    font: 'GILL'
                },
                columnStyles: {
                0: {cellWidth: (paperWidth - (margin * 2)) * .1},
                1: {cellWidth: (paperWidth - (margin * 2)) * .4},
                },
                head: [[
                {content: 'Line No.', styles: {
                    valign: 'middle',
                    halign: 'center',
                    cellPadding: 3.7,
                    fillColor: this.system.theme_color,
                    fontStyle: 'bold',
                    lineColor: [0, 0, 0],
                }},
                {content: 'DESCRIPTION', styles: {
                    valign: 'middle',
                    halign: 'center',
                    cellPadding: 3.7,
                    fillColor: this.system.theme_color,
                    fontStyle: 'bold',
                    lineColor: [0, 0, 0],
                }},
                {content: 'QTY', styles: {
                    valign: 'middle',
                    halign: 'center',
                    cellPadding: 3.7,
                    fillColor: this.system.theme_color,
                    fontStyle: 'bold',
                    lineColor: [0, 0, 0],
                    cellWidth: 51.8
                }},
                {content: 'UOM', styles: {
                    valign: 'middle',
                    halign: 'center',
                    cellPadding: 3.7,
                    fillColor: this.system.theme_color,
                    fontStyle: 'bold',
                    lineColor: [0, 0, 0],
                    cellWidth: 51.8
                }},
                {content: 'UNIT PRICE', styles: {
                    valign: 'middle',
                    halign: 'center',
                    cellPadding: 3.7,
                    fillColor: this.system.theme_color,
                    fontStyle: 'bold',
                    lineColor: [0, 0, 0],
                    cellWidth: 90

                }},
                {content: 'TOTAL', styles: {
                    valign: 'middle',
                    halign: 'center',
                    cellPadding: 3.7,
                    fillColor: this.system.theme_color,
                    fontStyle: 'bold',
                    lineColor: [0, 0, 0],
                }},
                ]],
                body: [
                    ...this.generateItemsList(this.tempItems, doc),
                ],
                // didDrawCell: data => {
                //     if(data.column.index == 5 && data.row.index == 1 && data.section == 'body'){
                //         doc.line(
                //             data.cell.x,
                //             data.cell.y,
                //             data.cell.x + data.cell.width,
                //             data.cell.y,
                //         );
                //     }
                //     if(data.column.index == 0){
                //         doc.line(
                //             data.cell.x,
                //             data.cell.y,
                //             data.cell.x,
                //             data.cell.y + data.cell.height,
                //         );
                //     }else if(data.column.index == 5 && data.section == 'body'){
                //         doc.rect(
                //             data.cell.x,
                //             data.cell.y,
                //             data.cell.width,
                //             data.cell.height,
                //         );
                //     }
                //     let current_page = doc.getCurrentPageInfo().pageNumber;
                //     if(this.printData.item_list.length > data.row.index){
                //         doc.line(
                //             data.cell.x,
                //             data.cell.y + data.cell.height,
                //             data.cell.x + data.cell.width,
                //             data.cell.y + data.cell.height,
                //         );
                //     }

                // },
                didDrawPage: function(data) {
                //HEADER AND FOOTER
                headerAndFooter(doc, printDetails)
                }
            });
        })


        //Page Number
        doc.setFont('GILL', 'normal');
        doc.setFontSize(7);
        doc.setTextColor('#000');
        let pageInfo = doc.internal.getCurrentPageInfo();
        let index = 0
        for (let i = 0; i < pageInfo.pageNumber; i++) {
            doc.setPage(i + 1);
            doc.text(`Page ${i + 1} of ${pageInfo.pageNumber}`, margin + 220, paperHeight - 18);
            index = i + 1
            let lastAutoTable = doc.lastAutoTable.finalY
            if(index == pageInfo.pageNumber){
                doc.setFont('GILL', 'normal');
                doc.setFontSize(8);
                doc.setTextColor('#000');
                doc.text(margin + 5, lastAutoTable - 39, "REMARKS / PO NOTES")
            }
        }

        // For printing.
        // doc.autoPrint();
        window.open(doc.output('bloburl'), '_blank');
    },
    generateItemsList(tempItems, doc) {
        let items = tempItems.map((item, index) => {;
        this.temp_index++
        return [
        { content: this.temp_index, styles: { valign: 'middle', halign: 'center', cellPadding: 3.7 } },
        { content: item.item_name, styles: { valign: 'middle', halign: 'center', cellPadding: 3.7 } },
        { content: item.quantity, styles: { valign: 'middle', halign: 'center', cellPadding: 3.7 } },
        { content: item.uom, styles: { valign: 'middle', halign: 'center', cellPadding: 3.7 } },
        { content: this.thousandSeprator(this.formatCurrencyPHPwithoutRoundingUp(item.total_original_price)), styles: { valign: 'middle', halign: 'right', cellPadding: 3.7 } },
        { content: this.thousandSeprator(this.formatCurrencyPHPwithoutRoundingUp(item.total_amount)), styles: { valign: 'middle', halign: 'right', cellPadding: 3.7} },
        ];
        });
         let whatpage = doc.internal.getCurrentPageInfo().pageNumber;
        if(items.length < 25 || (this.temp_index == items.length && whatpage == this.chunk_data.length)){
            items.push([
                {content: `${this.printData.remarks ? this.printData.remarks : ''}`,colSpan: 4,  styles: { valign: 'middle', halign: 'left', fillColor: '#eceff1', minCellHeight: 50}},
                {content: 'TOTAL', rowSpan:2,  styles: {valign: 'middle', halign: 'left', fontStyle: 'bold', fillColor: '#eceff1', minCellHeight: 50}},
                {content: `${ this.thousandSeprator(this.formatCurrencyPHPwithoutRoundingUp(this.printData.total_amount))}`, rowSpan:2, styles: { valign: 'middle', halign: 'right', fillColor: this.system.theme_color}},
            ],
            // [
            //     {content: `${this.printData.remarks ? this.printData.remarks : ''}`,  colSpan: 4,  styles: { valign: 'top', halign: 'left', fillColor: '#eceff1', minCellHeight: 30}},
            // ]
            );
        }
        // else{
        //      items.push([
        //         {content: ``,  colSpan: 4,  styles: {lineColor: [0, 0, 0], valign: 'top', halign: 'left', minCellHeight: 5}},
        //         {content: '', rowSpan:2,  styles: { valign: 'middle', halign: 'right', fontStyle: 'bold', minCellHeight: 30}},
        //         {content: ``, rowSpan:2, styles: {  valign: 'middle', halign: 'right'}},
        //     ],
        //     [
        //         {content: ``,  colSpan: 4,  styles: { valign: 'top', halign: 'left', minCellHeight: 30}},
        //     ]);
        // }

        return items;
    },
    paymentMethod(payment_method){
        switch (payment_method) {
            case 1:
                return 'PAYMENT FIRST';
            case 2:
                return 'RECEIVE FIRST';
            case 3:
                return 'PARTIAL PAYMENT';
            default:
                return '';
        }
    },
    trim(location){
        let trim = location.split('-')
        let result = trim[1].trim()
        return result;
    },
    formatDate(raw_date) {
        const date = new Date(raw_date);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
    },
    getHeaderAndFooterPdf(doc, printData){
        let paperWidth = 612;
        let paperHeight = 792;
        let margin = 36;
        let margin_top = 15;
        let host = window.location.origin;

        // PURCHASE ORDER LABEL
        let rightLabelFontSize = 19;

        doc.setFontSize(rightLabelFontSize)
        .setTextColor(this.system.title_color)
        .setFont('GILL','bold')
        .text('PURCHASE ORDER', (paperWidth - margin) - 180, margin_top + 15);

        try {
            let aspectRatio = this.getAspectRatio(this.base64Image.naturalWidth, this.base64Image.naturalHeight);
            let image_width = 0;
            let image_height = 0;

            switch(aspectRatio){
                case '1:1':
                    image_width = 40;
                    image_height = 40;
                    break;
                case '4:3':
                    image_width = 60;
                    image_height = 40;
                    break;
                case '3:2':
                    image_width = 50;
                    image_height = 40;
                    break;
                case '16:9':
                    image_width = 80;
                    image_height = 40;
                    break;
                default:
                    image_width = 120;
                    image_height = 30;
            }

            doc.addImage(this.printData.image_path, 'PNG', margin, margin_top, image_width, image_height);
        } catch {
            console.error('image is not found for print.');
        }

        //Set the font format
        doc.setFont('GILL', 'bold');
        doc.setFontSize(7);
        doc.setTextColor('#000');
        //PO# and DATE
        doc.text(474, 58.7, "DATE")
        doc.text(476, 73.7, "PO #")
        doc.setFont('GILL', 'normal');
        doc.text(522, 60, `${this.formatDate(this.printData.date_created)}`)
        doc.text(522, 71.7, `${this.printData.po_num}`)
        //Box for DATA and PO#
        doc.setDrawColor(0, 0, 0);
        doc.rect(500, margin + 15.4, 75, 12);
        doc.rect(500, margin + 27.5, 75, 12);


        // VENDOR AND SHIPPED TO DETAILS
        doc.autoTable({
            theme: 'plain',
            startY: margin_top + 36,
            tableWidth: paperWidth - (margin * 2),
            margin: margin,
            styles: {
            lineColor: [0, 0, 0],
            textColor: [0, 0, 0],
            fontSize: 8,
            font: 'GILL'
            },
            columnStyles: {
            0: {cellWidth: (paperWidth - (margin * 2)) * .2},
            1: {cellWidth: (paperWidth - (margin * 2)) * .3},
            2: {cellWidth: (paperWidth - (margin * 2)) * .2},
            3: {cellWidth: (paperWidth - (margin * 2)) * .3},
            },
            body: [
            [
                {content: `${this.printData.company_name ? this.printData.company_name + ' - ' +  this.printData.store_branch: ''}`, colSpan: 2, styles: {lineWidth: 1, lineColor: [255, 255, 255], fillColor: '#fff', fontStyle: 'bold', cellPadding: 2}},
                {content: '', colSpan: 2, styles: {valign: 'middle', halign: 'right', fontStyle: 'bold', cellPadding: 2}},
            ],
            [
                {content: `${this.printData.store_branch_address ? this.printData.store_branch_address : ''}`, colSpan: 2, styles: {lineWidth: 1, lineColor: [255, 255, 255], fillColor: '#fff', cellPadding: 2}},
                {content: ``, colSpan: 2, styles: {valign: 'middle', halign: 'right', fontStyle: 'bold', cellPadding: 2}},
            ],
            [
                {content: 'VENDOR', colSpan: 2,  styles: {valign: 'middle', halign: 'left', fillColor: this.system.theme_color, fontStyle: 'bold', cellPadding: 3}},
                {content: 'SHIP TO', colSpan: 2, styles: {valign: 'middle', halign: 'left', fillColor: this.system.theme_color, fontStyle: 'bold', cellPadding: 3}},
            ],
            [
                {content: `${this.printData.vendor ? this.printData.vendor : ''}`, colSpan: 2, styles: {valign: 'middle', cellPadding: 4}},
                {content: `${this.printData.shipped_to_address ? this.printData.shipped_to_address : ''}`, colSpan: 2, styles: {valign: 'middle', cellPadding: 4}},
            ],
            [
                {content: `${this.printData.vendor_address ? this.printData.vendor_address : ''}`, colSpan: 2, styles: {valign: 'middle', cellPadding: 2}},
                {content: `${this.printData.contact_person ? this.printData.contact_person : ''}`, colSpan: 2, styles: {valign: 'middle', cellPadding: 2}},
            ],
            [
                {content: `${this.printData.vendor_contact_person ? this.printData.vendor_contact_person : ''}`, colSpan: 2, styles: {valign: 'middle', cellPadding: 2}},
                {content: `${this.printData.contact_number ? this.printData.contact_number : ''}`, colSpan: 2, styles: {valign: 'middle', cellPadding: 2}},
            ],
            [
                {content: `${this.printData.vendor_contact_number ? this.printData.vendor_contact_number : ''}`, colSpan: 2, styles: {valign: 'middle', cellPadding: 2}},
                {content: ``, colSpan: 2, styles: {valign: 'middle', cellPadding: 2}},
            ],
            ],
        });

        //REF TABLE
        doc.autoTable({
            theme: 'grid',
            tableWidth: paperWidth - (margin * 2),
            startY: 164,
            margin: margin,
            styles: {
                textColor: '#000',
                lineWidth: 0.1,
                lineColor: '#000',
                fontSize: 8,
                font: 'GILL'
            },
            columnStyles: {
              0: {cellWidth: (paperWidth - (margin * 2)) * .1},
              1: {cellWidth: (paperWidth - (margin * 2)) * .4},
            },
            head: [[
              {content: 'REF.DOC', styles: {
                valign: 'middle',
                halign: 'center',
                cellPadding: 3.7,

                fillColor: this.system.theme_color,
                fontStyle: 'bold',
              }},
              {content: 'SHIPPING VIA', styles: {
                valign: 'middle',
                halign: 'center',
                cellPadding: 3.7,
                fillColor: this.system.theme_color,
                fontStyle: 'bold',
              }},
              {content: 'TERMS', styles: {
                valign: 'middle',
                halign: 'center',
                cellPadding: 3.7,
                fillColor: this.system.theme_color,
                fontStyle: 'bold',
              }},
              {content: 'EXPECTED DATE', styles: {
                valign: 'middle',
                halign: 'center',
                cellPadding: 3.7,
                fillColor: this.system.theme_color,
                fontStyle: 'bold',
                cellWidth: 116.4
              }},
              {content: 'PAYMENT METHOD', styles: {
                valign: 'middle',
                halign: 'center',
                cellPadding: 3.7,
                fillColor: this.system.theme_color,
                fontStyle: 'bold',
              }},
            ]],

            body:[[
            {content: `${this.printData.id.toString().padStart(4, '0')}`, styles: {valign: 'middle', halign: 'center', cellPadding: 3.7}},
            {content: `${this.printData.shipping_via ? this.printData.shipping_via : ''}`, styles: {valign: 'middle', halign: 'center', cellPadding: 3.7}},
            {content: `${this.printData.terms ? this.printData.terms + ' Days' : ''}`, styles: {valign: 'middle', halign: 'center', cellPadding: 3.7}},
            {content: `${this.formatDate(this.printData.expected_date)}`, styles: {valign: 'middle', halign: 'center', cellPadding: 3.7}},
            {content: this.paymentMethod(this.printData.payment_method), styles: {valign: 'middle', halign: 'center', cellPadding: 3.7}},
            ]]

          });

        //FOOTER
        doc.setFont('GILL', 'normal');
        doc.setFontSize(7);
        doc.setTextColor('#000');
        let signatories_upper_y_margin = 715
        let signatories_lower_y_margin = 742
        doc.text(margin,  signatories_upper_y_margin, "Prepred By")
        doc.text(margin,  signatories_lower_y_margin, "Admin In-Charge")
        doc.text(margin + 110,  signatories_upper_y_margin, "Checked By")
        doc.text(margin + 110,  signatories_lower_y_margin, "Admin Officer")
        doc.text(margin + 250,  signatories_upper_y_margin, "Noted By")
        doc.text(margin + 250,  signatories_lower_y_margin, "Admin Manager")
        doc.text(margin + 376,  signatories_upper_y_margin, "Approved By")
        doc.text(margin + 376,  signatories_lower_y_margin, "Admin Manager / President")
    },

    printNow(){
        this.renderPDF(this.getHeaderAndFooterPdf)
    },
},
  watch:{
    "PURCHASE_ORDER.PRINT":{
          handler(val){
             if(val){
                this.system = this.GET_SYSTEM
                this.printData = this.PURCHASE_ORDER.DATA
                this.chunk_data = this.sliceIntoChunks(this.printData.item_list, 25)
                this.base64Image = this.PURCHASE_ORDER.COMPANY_IMAGE
                this.printNow()
                this.$store.commit('PRINT_PURCHASE_ORDER',false);
             }
          },
          deep:true
      },
  }
}
</script>
<style scope>
.border-left-none{
  border-left:1px solid red;
}
</style>
