<template >
    <v-form>
        <!-- Table -->
      <v-container fluid style="display:none;">
        <div>
            <table id="accountability_name">
                <tbody>
                    <tr>
                        <td>Recipient: {{GET_PRINT_ADMIN_DETAIL.emp_id == 0 ? GET_PRINT_ADMIN_DETAIL.full_name + ' ' + GET_PRINT_ADMIN_DETAIL.department_code : GET_PRINT_ADMIN_DETAIL.full_name}}</td>
                    </tr>
                    <tr>
                        <td>Department / Location : {{GET_PRINT_ADMIN_DETAIL.department_name}} / {{GET_PRINT_ADMIN_DETAIL.location}}</td>
                    </tr>
                </tbody>
            </table>
            <table id="headers_of_accountability">
                <tbody>
                    <tr>
                        <td rowspan="2"> Item Classification</td>
                        <td rowspan="2"> Sub-Class</td>
                        <td rowspan="2"> Particulars</td>
                        <td rowspan="2"> Asset Tagging</td>
                        <td rowspan="2"> Purchase / Depreciated Price</td>
                        <td rowspan="1" colspan="2">* For General Assets</td>
                        <!-- <td>Specific Asset</td> -->
                        <td rowspan="2"> REMARKS</td>
                    </tr>
                    <tr>
                        <td rowspan="1"> QTY</td>
                        <td rowspan="1"> Specific Asset Location</td>
                    </tr>
                    <tr v-for="(item, index) in (GET_PRINT_ADMIN_DETAIL.admin_accountabilityitem.length <= 6 ? [...GET_PRINT_ADMIN_DETAIL.admin_accountabilityitem, ...new Array(6 - GET_PRINT_ADMIN_DETAIL.admin_accountabilityitem.length).fill({})] : GET_PRINT_ADMIN_DETAIL.admin_accountabilityitem)" :key="index">
                        <template v-if="index === check_for_count">
                            <td colspan="8" class="text-center">**NOTHING FOLLOWS**</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </template>
                        <template v-else>
                            <td v-if="item.classification_code">{{item.classification_code}}</td>
                            <td v-else></td>
                            <td v-if="item.asset_num">{{item.specific_asset}}</td>
                            <td v-else></td>
                            <td v-if="item.name">{{item.name}}</td>
                            <td v-else></td>
                            <td v-if="item.code">{{item.code}}</td>
                            <td v-else></td>
                            <td v-if="item.total_original_price">{{parseFloat(item.total_original_price).toFixed(2)}}</td>
                            <td v-else></td>
                            <td v-if="GET_PRINT_ADMIN_DETAIL.admin_accountabilityitem[index] && GET_PRINT_ADMIN_DETAIL.emp_id == 0">1</td>
                            <td v-else></td>
                            <td v-if="GET_PRINT_ADMIN_DETAIL.admin_accountabilityitem[index] && GET_PRINT_ADMIN_DETAIL.emp_id == 0">{{GET_PRINT_ADMIN_DETAIL.location}}</td>
                            <td v-else></td>
                            <td v-if="item.description">{{item.status}}{{item.item_serial_number}}</td>
                            <td v-else></td>
                        </template>
                    </tr>
                    <tr v-if="check_for_count > 6">
                        <td colspan="8">**NOTHING FOLLOWS**</td>
                    </tr>
                    <tr>
                        <td colspan="7"></td>
                        <td>AS No. {{ GET_PRINT_ADMIN_DETAIL.accountability_num }}</td>
                    </tr>
                    <tr>
                        <td colspan="8">A C K N O W L E D G E M E N T and C E R T I F I C A T I O N</td>
                    </tr>
                    <tr>
                        <td colspan="7">
                            By signing, I acknowledge the receipt of the following items listed above mentions endorsed, entrust and/or transferred to my/our custody.
                            Furthermore, I/we acccept the responsibility to demonstrate appropriate usange and keep the same in good working condition and notify the
                            Management should the property been damaged, lost or stolen, likewise return the same in good working condition. If/we am/are found negligent
                            in my/our responsibility to safeguard the listed fixed asset/s and deemed to be damage or lost, I/we authorize the Company to withhold/demand the value
                            of the lost or damaged property from my/our salary to compensate the cost of the same.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            I/we am/are aware that if I/we don't abide by these rules, my/our access to the company property may be revoked and may face disciplinary measures. I/we
                            have read and understood the procedure which covers the issuance of the fixed asset/s and hereby abide and the comply with the same policy.
                        </td>
                        <td colspan="3">
                        </td>
                    </tr>
                </tbody>
            </table>
            <table id="accountability_name_copy">
                <tbody>
                    <tr>
                        <td>Recipient: {{GET_PRINT_ADMIN_DETAIL.emp_id == 0 ? GET_PRINT_ADMIN_DETAIL.full_name + ' ' + GET_PRINT_ADMIN_DETAIL.department_code : GET_PRINT_ADMIN_DETAIL.full_name}}</td>
                    </tr>
                    <tr>
                        <td>Department / Location : {{GET_PRINT_ADMIN_DETAIL.department_name}} / {{GET_PRINT_ADMIN_DETAIL.location}}</td>
                    </tr>
                </tbody>
            </table>
            <table id="headers_of_accountability_copy">
                <tbody>
                    <tr>
                        <td rowspan="2"> Item Classification</td>
                        <td rowspan="2"> Sub-Class</td>
                        <td rowspan="2"> Particulars</td>
                        <td rowspan="2"> Asset Tagging</td>
                        <td rowspan="2"> Purchase / Depreciated Price</td>
                        <td rowspan="1" colspan="2">* For General assets</td>
                        <!-- <td>Specific Asset</td> -->
                        <td rowspan="2"> REMARKS</td>
                    </tr>
                    <tr>
                        <td rowspan="1"> QTY</td>
                        <td rowspan="1"> Specific Asset Location</td>
                    </tr>
                    <tr v-for="(item, index) in (GET_PRINT_ADMIN_DETAIL.admin_accountabilityitem.length <= 6 ? [...GET_PRINT_ADMIN_DETAIL.admin_accountabilityitem, ...new Array(6 - GET_PRINT_ADMIN_DETAIL.admin_accountabilityitem.length).fill({})] : GET_PRINT_ADMIN_DETAIL.admin_accountabilityitem)" :key="index">
                        <template v-if="index === check_for_count">
                            <td colspan="8" class="text-center">**NOTHING FOLLOWS**</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </template>
                        <template v-else>
                            <td v-if="item.classification_code">{{item.classification_code}}</td>
                            <td v-else></td>
                            <td v-if="item.asset_num">{{item.specific_asset}}</td>
                            <td v-else></td>
                            <td v-if="item.name">{{item.name}}</td>
                            <td v-else></td>
                            <td v-if="item.code">{{item.code}}</td>
                            <td v-else></td>
                            <td v-if="item.total_original_price">{{parseFloat(item.total_original_price).toFixed(2)}}</td>
                            <td v-else></td>
                            <td v-if="GET_PRINT_ADMIN_DETAIL.admin_accountabilityitem[index] && GET_PRINT_ADMIN_DETAIL.emp_id == 0">1</td>
                            <td v-else></td>
                            <td v-if="GET_PRINT_ADMIN_DETAIL.admin_accountabilityitem[index] && GET_PRINT_ADMIN_DETAIL.emp_id == 0">{{GET_PRINT_ADMIN_DETAIL.location}}</td>
                            <td v-else></td>
                            <td v-if="item.description">{{item.status}}{{item.item_serial_number}}</td>
                            <td v-else></td>
                        </template>
                    </tr>
                    <tr v-if="check_for_count > 6">
                        <td colspan="8">**NOTHING FOLLOWS**</td>
                    </tr>
                    <tr>
                        <td colspan="7"></td>
                        <td>AS No. {{ GET_PRINT_ADMIN_DETAIL.accountability_num }}</td>
                    </tr>
                    <tr>
                        <td colspan="8">A C K N O W L E D G E M E N T and C E R T I F I C A T I O N</td>
                    </tr>
                    <tr>
                        <td colspan="7">
                            By signing, I acknowledge the receipt of the following items listed above mentions endorsed, entrust and/or transferred to my/our custody.
                            Furthermore, I/we acccept the responsibility to demonstrate appropriate usange and keep the same in good working condition and notify the
                            Management should the property been damaged, lost or stolen, likewise return the same in good working condition. If/we am/are found negligent
                            in my/our responsibility to safeguard the listed fixed asset/s and deemed to be damage or lost, I/we authorize the Company to withhold/demand the value
                            of the lost or damaged property from my/our salary to compensate the cost of the same.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            I/we am/are aware that if I/we don't abide by these rules, my/our access to the company property may be revoked and may face disciplinary measures. I/we
                            have read and understood the procedure which covers the issuance of the fixed asset/s and hereby abide and the comply with the same policy.
                        </td>
                        <td colspan="3">
                        </td>
                    </tr>
                </tbody>
            </table>
          <!-- <table id="item_details_accountability">
            <thead>
              <tr>
                <th>ITEM DETAILS</th>
                <th>ITEM CODE /</th>
                <th>PURCHASE / DEPRECIATED PRICE</th>
                <th>REMARKS</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in data" :key="row">
                <td>{{ row.name }}</td>
                <td>{{ row.code }}</td>
                <td></td>
                <td>{{ row.remarks }}</td>
              </tr>
              <tr>
                <td colspan="6" class="red-text">***** NOTHING FOLLOWS *****</td>
              </tr>
            </tbody>
          </table> -->
        </div>
        <!-- <v-col cols="6">
            <v-btn
            class="float-center"
            @click="renderPDF()"
            >
              <span>Print</span>
            </v-btn>
          </v-col>  -->
      </v-container>
    </v-form>

  </template>
  <script>
  import { mapGetters } from 'vuex';
  import GILL from '@/assets/fonts/Gil_normal';
  import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
import { bottom, right } from '@popperjs/core';
  export default {
    mixins:[GILL,SharedFunctionsComponentVue],
    data() {
        return {
            check_for_count: 0,
            base64Image: null,
            info: {
                logo : "",
                name : "",
                deptLoc : "",
                typeOfAcct : "",
                transferOfAcct : "",
                newAs : "",
                reference_as : "",
                prepared_by : "",
                checked_by : "",
                noted_by : "",
                asNo : "",
                prepared_by_data : "",
                checked_by_data : "",
                noted_by_data : "",
                prepared_by_position : "",
                checked_by_position : "",
                noted_by_position : "",
                signature_name : "",
                acknowledgement : "",
                acknowledgement_text : "",
                sheetVersion : "",
                sheetCopy : "",

            },
            sheet_data: {
                name:"",
                date:null,
                dept:"",
                location:"",
                dept_loc:"",
            },
            copy: {
                sheet_copy_1: "",
                sheet_copy_2: "",
                sheet_copy_3: "",
            },
            headers: ["ITEM DETAILS", "ITEM CODE/s", "PURCHASE / DEPRECIATED PRICE", "REMARKS"],
            data: [],
            attributes: [
                {
                    table_name: 'accountability_name',
                    startY: 15,
                    margin_top: 0,
                    recipient: 19,
                    department_location: 27,
                    img_top: 4,
                    accountability_logo: 5,
                    accountability_text: 10,
                    type_of_accountability_box_rect_first:15,
                    type_of_accountability_box_text:19,
                    type_of_accountability_box_rect_second:22,
                    transfer_accountability_text:25,
                    type_of_accountability_box_rect_third:26.5,
                    new_as:30,
                    reference_as:19,
                    previous_num: 24,
                    line_first:25,
                    line_second:28,
                    headers_of_accountability: 'headers_of_accountability',
                    headers_startY:37.2,
                    prepared_by:88.5,
                    prepared_name:94,
                    prepared_position:99,
                    sheetCopy_Y:124,
                    sinature_line:122,
                    signature_text:124,
                    box_height: 22,

                },
                {
                    table_name: 'accountability_name_copy',
                    startY: 154,
                    margin_top: 22,
                    recipient: 157,
                    department_location: 165.5,
                    img_top: 144,
                    accountability_logo: 144,
                    accountability_text: 149,
                    type_of_accountability_box_rect_first:154,
                    type_of_accountability_box_text:158,
                    type_of_accountability_box_rect_second:160,
                    transfer_accountability_text:163,
                    type_of_accountability_box_rect_third:165.5,
                    new_as:168,
                    reference_as:159,
                    previous_num:162,
                    line_first:163,
                    line_second:167,
                    headers_of_accountability: 'headers_of_accountability_copy',
                    headers_startY:176,
                    prepared_by:227,
                    prepared_name:232,
                    prepared_position:237.5,
                    sheetCopy_Y:266.5,
                    sinature_line:259,
                    signature_text:261,
                    box_height: 22,
                },
            ],
            attributes_more: [
                {
                    table_name: 'accountability_name',
                    startY: 15,
                    margin_top: 0,
                    recipient: 19,
                    department_location: 27,
                    img_top: 4,
                    accountability_logo: 5,
                    accountability_text: 10,
                    type_of_accountability_box_rect_first:15,
                    type_of_accountability_box_text:19,
                    type_of_accountability_box_rect_second:22,
                    transfer_accountability_text:25,
                    type_of_accountability_box_rect_third:26.5,
                    new_as:30,
                    reference_as:19,
                    previous_num: 24,
                    line_first:25,
                    line_second:28,
                    headers_of_accountability: 'headers_of_accountability',
                    headers_startY:37.2,
                    prepared_by:88.5,
                    prepared_name:94,
                    prepared_position:99,
                    sheetCopy_Y:124,
                    sinature_line:122,
                    signature_text:124,
                    box_height: 22,

                },
                {
                    table_name: 'accountability_name',
                    startY: 15,
                    margin_top: 0,
                    recipient: 19,
                    department_location: 27,
                    img_top: 4,
                    accountability_logo: 5,
                    accountability_text: 10,
                    type_of_accountability_box_rect_first:15,
                    type_of_accountability_box_text:19,
                    type_of_accountability_box_rect_second:22,
                    transfer_accountability_text:25,
                    type_of_accountability_box_rect_third:26.5,
                    new_as:30,
                    reference_as:19,
                    previous_num: 24,
                    line_first:25,
                    line_second:28,
                    headers_of_accountability: 'headers_of_accountability',
                    headers_startY:37.2,
                    prepared_by:88.5,
                    prepared_name:94,
                    prepared_position:99,
                    sheetCopy_Y:124,
                    sinature_line:122,
                    signature_text:124,
                    box_height: 22,
                },
            ],

        }
    },
    computed:{
        ...mapGetters(['GET_PRINT_ACCOUNTABILITY','GET_PRINT_ADMIN_DETAIL','PURCHASE_ORDER']),
    },
    mounted(){

    },
    methods: {
        async renderPDF(){
            let doc = await new this.$jspdf({
                        orientation: 'p',
                        unit: 'mm',
                        format: 'letter'
                    });

                    let length = 0;
                    let get_total_length = 0;
                    if(this.GET_PRINT_ADMIN_DETAIL.admin_accountabilityitem.length > 6){
                        length = this.GET_PRINT_ADMIN_DETAIL.admin_accountabilityitem.length;
                        this.check_for_count = length;
                    } else {
                        get_total_length = this.GET_PRINT_ADMIN_DETAIL.admin_accountabilityitem.length;
                        length = 6;
                    }
                    this.sheet_data.name = this.GET_PRINT_ADMIN_DETAIL.full_name?this.GET_PRINT_ADMIN_DETAIL.full_name:'GENERAL ACCOUNTABILITY'
                    this.sheet_data.date = this.$dayjs().format('YYYY-MM-DD');
                    this.sheet_data.dept = 'IT' + ' Department'
                    this.sheet_data.location = this.GET_PRINT_ADMIN_DETAIL.location
                    this.sheet_data.dept_loc = this.GET_PRINT_ADMIN_DETAIL.department_name+" / "+this.GET_PRINT_ADMIN_DETAIL.location


                    this.copy.sheet_copy_1 = 'Employee'
                    this.copy.sheet_copy_2 = '201 File '
                    this.copy.sheet_copy_3 = 'Admin File'


                    this.info.logo             = 'ingcoph-logo'
                    this.info.name             = 'Name of Recipients:'
                    this.info.deptLoc          = 'Department / Location :'
                    this.info.typeOfAcct       = 'Type of Accountability'
                    this.info.transferOfAcct   = 'Transfer of Accountability'
                    this.info.newAs            = 'New AS'
                    this.info.reference_as     = `Reference AS No's`

                    this.info.prepared_by      = 'Prepared by:'
                    this.info.checked_by       = 'Checked by:'
                    this.info.noted_by         = 'Noted by:'
                    this.info.asNo             = 'AS No- ' +  this.GET_PRINT_ADMIN_DETAIL.accountability_num

                    this.info.prepared_by_data = ''
                    this.info.checked_by_data  = ''
                    this.info.noted_by_data    = ''

                    this.info.prepared_by_position = 'Admin Assistant'
                    this.info.checked_by_position  = 'Senior Admin Supervisor'
                    this.info.noted_by_position    = 'HR Manager'

                    this.info.signature_name       = 'Signature over Printed Name/Date'
                    this.info.acknowledgement      = 'ACKNOWLEDGEMENT and CERTIFICATION'
                    this.info.acknowledgement_text = `
                    By signing, I acknowledge the receipt of the following items listed above mentioned that endorsed, entrust or transferred to my custody.
                    Also, I agree to keep the same in good working condition and notify the Management should the property been damaged, lost or stolen, likewise return the same in good working condition.
                    If I am found negligent in my responsibility to safeguard the listed fixed asset/s and deemed to be damaged or lost, I authorize the Company to withhold/demand the value of the lost or damaged property from my salary to compensate the cost of the same.
                    I have read and understood the procedure which covers the issuance of the fixed asset/s and hereby abide and comply with the same policy.`

                    this.info.sheetVersion         = 'ADM-01-v02-2022'
                    this.info.sheetCopy            = `Copy 1 – ${this.copy.sheet_copy_1}   Copy 2 – ${this.copy.sheet_copy_2}   Copy 3 – ${this.copy.sheet_copy_3}`

                    if(length == 6){
                        this.attributes.map(e => {
                        // Insertion of Image and Title
                        let left = 10;
                        let top = e.img_top - 1;
                        const imgWidth = 40;
                        const imgHeight = 10;
                        // let host = window.location.origin;
                        // doc.addImage(`${host}/images/${this.info.logo}.png`, 'PNG', left, top, imgWidth, imgHeight);
                        try {
                            let aspectRatio = this.getAspectRatio(this.base64Image.naturalWidth, this.base64Image.naturalHeight);
                            let image_width = 0;
                            let image_height = 0;

                            switch(aspectRatio){
                                case '1:1':
                                    image_width = 25;
                                    image_height = 11;
                                break;
                                case '4:3':
                                    image_width = 60;
                                    image_height = 40;
                                    break;
                                case '3:2':
                                    image_width = 50;
                                    image_height = 40;
                                    break;
                                case '16:9':
                                    image_width = 80;
                                    image_height = 40;
                                    break;
                                default:
                                    image_width = 40;
                                    image_height = 10;
                            }

                            doc.addImage(this.printData.image_path, 'PNG', left, top, image_width, image_height);
                        } catch (error){
                            console.log(error)
                            console.error('image is not found for print.');
                        }

                        doc.autoTable({
                        html: `#${e.table_name}`,
                        theme: 'grid',
                        startY: e.startY,
                        margin: {left: 10, top: e.margin_top, right: 0, bottom: 0},
                        columnStyles: {
                            0: {cellWidth: 100, minCellHeight:11, halign:'left', textColor: '#000000',fontSize: "8"},
                        },
                        bodyStyles: {
                            lineColor: [0, 0, 0],
                        },
                        styles: {font: "GILL", fontSize:"8", textColor: '#000000', fontStyle: 'bold'},
                        didParseCell: function (data) {
                            if (data.row.index == 1) {
                                data.cell.styles.fontSize = 6;
                                data.cell.styles.halign = 'left';
                            }
                        },
                        });

                        // types of acoountability box
                        doc.setDrawColor(0, 0, 0);
                        doc.rect(110, e.type_of_accountability_box_rect_first, 95, e.box_height, 'S');

                        // headers of accountability
                        doc.autoTable({
                        html: `#${e.headers_of_accountability}`,
                        theme: 'grid',
                        startY: e.headers_startY,
                        margin: {left: 10, right: 10.55, top: 0, bottom: 0},
                        columnStyles: {
                            // Center align text in all columns
                            0: {halign: 'center', valign: 'middle', cellWidth: 25, minCellHeight: 2},
                            1: {halign: 'center', valign: 'middle', cellWidth: 20, minCellHeight: 2},
                            2: {halign: 'center', valign: 'middle', cellWidth: 30, minCellHeight: 2, overflow: 'ellipsize'},
                            3: {halign: 'center', valign: 'middle', cellWidth: 25, minCellHeight: 2},
                            4: {halign: 'center', valign: 'middle', cellWidth: 30, minCellHeight: 2},
                            5: {halign: 'center', valign: 'middle', cellWidth: 13, minCellHeight: 2},
                            6: {halign: 'center', valign: 'middle', cellWidth: 17, minCellHeight: 2},
                            7: {halign: 'center', valign: 'middle', cellWidth: 35, overflow: 'ellipsize', minCellHeight: 2},
                            // Add more columns as needed
                        },
                        bodyStyles: {
                            lineColor: [0, 0, 0],
                            halign: 'center',
                            Padding: 0,
                        },
                        styles: {
                            font: "GILL",

                            halign: 'center'
                        },
                        didParseCell: function (data) {
                                // if (data.row.index === 0) {
                                //     data.cell.styles.textColor = [255, 0, 0];
                                // }
                                // // else if(data.row.index === 0){
                                // //     data.cell.styles.fontSize = 7;
                                // //     data.cell.minCellHeight = 2;
                                // // }
                                if(data.row.index === get_total_length + 2){
                                    data.cell.minCellHeight = 2;
                                    data.cell.styles.textColor = [255, 0, 0];
                                }
                                else if(data.row.index === length + 2){
                                    // data.cell.styles.cellPadding = 9;
                                    data.cell.styles.cellPadding = { top: 4.5, bottom: 4.5 };
                                    data.cell.styles.textColor = [255, 0, 0];
                                }
                                else if(data.row.index === length + 4){
                                    data.cell.styles.fontSize = 6;
                                    data.cell.styles.cellPadding = .5;
                                    data.cell.styles.overflow = 'linebreak';
                                    data.cell.styles.halign = 'justify';
                                    data.cell.styles.textColor = [0, 0, 0];
                                    data.cell.styles.lineWidth = 0;
                                }
                                else if(data.row.index === length + 5){
                                    data.cell.styles.fontSize = 6;
                                    data.cell.styles.cellPadding = .5;
                                    // data.cell.styles.lineWidth = [0, 0, 0, 0.5]; // top, right, bottom, left
                                    data.cell.styles.textColor = [0, 0, 0];
                                    data.cell.styles.halign = 'justify';
                                    data.cell.styles.lineWidth = 0;
                                }
                                else if(data.row.index >= 1 && data.row.index <= (length + 1)){
                                    if(data.cell.text[0].length > 11){
                                        data.cell.minCellHeight = 2;
                                        data.cell.styles.fontSize = 4.7;
                                        data.cell.styles.overflow = 'linebreak';
                                    } else {
                                        data.cell.minCellHeight = 2;
                                        data.cell.styles.fontSize = 7;
                                    }
                                }
                                else {
                                    data.cell.minCellHeight = 2;
                                    data.cell.styles.fontSize = 7;
                                    data.cell.styles.textColor = [0, 0, 0]; // Set text color
                                }
                            }
                        });

                        doc.setDrawColor(0, 0, 0);
                        doc.setFontSize(9).setFont('GILL','normal').text(113.5,e.type_of_accountability_box_text,'Type of Accountability');
                        doc.rect(115, e.type_of_accountability_box_rect_second, 4, 4, this.GET_PRINT_ADMIN_DETAIL.transfer_asset ? 'F' : 'S');
                        doc.setFontSize(9).setFont('GILL','normal').text(120.5,e.transfer_accountability_text,`Transfer Accountability`);
                        doc.setFontSize(9).setFont('GILL','normal').text(121,e.new_as,`New AS`);
                        doc.rect(115, e.type_of_accountability_box_rect_third, 4, 4, this.GET_PRINT_ADMIN_DETAIL.new_asset ? 'F' : 'S');
                        doc.setFontSize(9).setFont('GILL','normal').text(121,e.new_as + 4.5,`Re-issue AS`);
                        doc.rect(115, e.type_of_accountability_box_rect_third + 4.6, 4, 4, this.GET_PRINT_ADMIN_DETAIL?.re_issue_as ? 'F' : 'S');
                        doc.setFontSize(9).setFont('GILL','normal').text(170,e.reference_as,`Reference AS No's`);
                        doc.setFontSize(7).setFont('GILL','normal').text(170,e.previous_num, this.GET_PRINT_ADMIN_DETAIL?.previous_num ? this.GET_PRINT_ADMIN_DETAIL.previous_num : '');
                        doc.line(170,e.line_first,205,e.line_first);
                        doc.line(170,e.line_second,205,e.line_second);

                        // for the label of name and department
                        // doc.setFontSize(9).setFont('GILL','normal').text(11,e.recipient,'Recipient:');
                        // doc.setFontSize(9).setFont('GILL','normal').text(11,e.department_location,'Department / Location :');

                        
                        // accountability logo right
                        var text = "ACCOUNTABILITY SHEET";
                        doc.setFillColor(192,192,192);
                        doc.setDrawColor(0);
                        doc.setLineWidth(0.5);
                        doc.roundedRect(120, e.accountability_logo, 81, 8, 2, 2, 'FD');
                        doc.setTextColor(0,0,0);
                        doc.setFontSize(10).setFont('GILL','bold').text(135,e.accountability_text,text);
                        
                        let first_y = doc.lastAutoTable.finalY;
                        
                        // prepared by and others
                        doc.setFontSize(5).setFont('GILL','normal').text(11,first_y - 33.8,`Prepared By:`);
                        doc.setFontSize(7).setFont('GILL','normal').text(11,first_y - 28, this.GET_PRINT_ADMIN_DETAIL.prepared_by ?? '');
                        doc.setFontSize(5).setFont('GILL','normal').text(11,first_y - 23.5,`Admin Assistant`);
                        doc.setFontSize(5).setFont('GILL','normal').text(71,first_y - 33.8,`Checked By:`);
                        doc.setFontSize(7).setFont('GILL','normal').text(71,first_y - 28, this.GET_PRINT_ADMIN_DETAIL.checked_by ?? '');
                        doc.setFontSize(5).setFont('GILL','normal').text(71,first_y - 23.5,`Admin Officer`);
                        doc.setFontSize(5).setFont('GILL','normal').text(121,first_y - 33.8,`Noted By:`);
                        doc.setFontSize(7).setFont('GILL','normal').text(121,first_y - 28, this.GET_PRINT_ADMIN_DETAIL.noted_by ?? '');
                        doc.setFontSize(5).setFont('GILL','normal').text(121,first_y - 23.5,`Admin Manager`);
                        
                        
                        this.lastY = doc.lastAutoTable.finalY;

                        // under the form
                        doc.setFontSize(4).setFont('GILL','bold').text(165,this.lastY + 1.5,this.info.sheetCopy);
                        doc.setFontSize(4).setFont('GILL','bold').text(12,this.lastY + 1.5,this.info.sheetVersion);

                        // for signature
                        doc.setDrawColor(0, 0, 0);
                        doc.line(160,this.lastY - 5,205,this.lastY - 5);
                        doc.setFontSize(5).setFont('GILL','normal').text(170,this.lastY - 3.7,"Signature over Printed Name/Date");
                        
                        doc.setLineWidth(0.1);
                        doc.setDrawColor(0, 0, 0);
                        doc.line(10,this.lastY - 16.25 ,205,this.lastY - 16.25);
                        doc.line(10,this.lastY ,205,this.lastY);
                        doc.line(10,this.lastY - 30 ,10,this.lastY);
                        doc.line(205,this.lastY - 30 ,205,this.lastY);

                        })

                        doc.setLineDash([2, 2], 0);
                        doc.line(0, 140, 300, 140);

                        doc.autoPrint();
                        // doc.save('Accountability Sheet.pdf');
                        window.open(doc.output('bloburl'), '_blank');
                    } else {
                        this.attributes_more.forEach((e, index) => {
                        if (index > 0) {
                            doc.addPage();
                        }

                        let left = 10;
                        let top = e.img_top;
                        const imgWidth = 40;
                        const imgHeight = 10;

                        doc.autoTable({
                        html: `#${e.table_name}`,
                        theme: 'grid',
                        startY: e.startY,
                        margin: {left: 10, top: e.margin_top},
                        columnStyles: {
                            0: {cellWidth: 100, minCellHeight:8, halign:'left', textColor: '#000000',fontSize: "8"},
                        },
                        bodyStyles: {
                            lineColor: [0, 0, 0],
                        },
                        styles: {font: "GILL", fontSize:"8", textColor: '#000000', fontStyle: 'bold'},
                        didParseCell: function (data) {
                            if (data.row.index == 1) {
                                data.cell.styles.fontSize = 6;
                                data.cell.styles.halign = 'left';
                            }
                        },
                        });

                        try {
                            let aspectRatio = this.getAspectRatio(this.base64Image.naturalWidth, this.base64Image.naturalHeight);
                            let image_width = 0;
                            let image_height = 0;

                            switch(aspectRatio){
                                case '1:1':
                                    image_width = 25;
                                    image_height = 11;
                                break;
                                case '4:3':
                                    image_width = 60;
                                    image_height = 40;
                                    break;
                                case '3:2':
                                    image_width = 50;
                                    image_height = 40;
                                    break;
                                case '16:9':
                                    image_width = 80;
                                    image_height = 40;
                                    break;
                                default:
                                    image_width = 40;
                                    image_height = 10;
                            }

                            doc.addImage(this.printData.image_path, 'PNG', left, top, image_width, image_height);
                        } catch (error){
                            console.log(error)
                            console.error('image is not found for print.');
                        }

                        doc.autoTable({
                        html: `#${e.table_name}`,
                        theme: 'grid',
                        startY: e.startY,
                        margin: {left: 10, top: e.margin_top, right: 0, bottom: 0},
                        columnStyles: {
                            0: {cellWidth: 100, minCellHeight:11, halign:'left', textColor: '#000000',fontSize: "8"},
                        },
                        bodyStyles: {
                            lineColor: [0, 0, 0],
                        },
                        styles: {font: "GILL", fontSize:"8", textColor: '#000000', fontStyle: 'bold'},
                        didParseCell: function (data) {
                            if (data.row.index == 1) {
                                data.cell.styles.fontSize = 6;
                                data.cell.styles.halign = 'left';
                            }
                        },
                        });

                        // types of acoountability box
                        doc.setDrawColor(0, 0, 0);
                        doc.rect(110, e.type_of_accountability_box_rect_first, 95, e.box_height, 'S');

                        // headers of accountability
                        doc.autoTable({
                        html: `#${e.headers_of_accountability}`,
                        theme: 'grid',
                        startY: e.headers_startY,
                        margin: {left: 10, right: 10.55, top: 0, bottom: 0},
                        columnStyles: {
                            // Center align text in all columns
                            0: {halign: 'center', valign: 'middle', cellWidth: 25, minCellHeight: 2},
                            1: {halign: 'center', valign: 'middle', cellWidth: 20, minCellHeight: 2},
                            2: {halign: 'center', valign: 'middle', cellWidth: 30, minCellHeight: 2, overflow: 'ellipsize'},
                            3: {halign: 'center', valign: 'middle', cellWidth: 25, minCellHeight: 2},
                            4: {halign: 'center', valign: 'middle', cellWidth: 30, minCellHeight: 2},
                            5: {halign: 'center', valign: 'middle', cellWidth: 13, minCellHeight: 2},
                            6: {halign: 'center', valign: 'middle', cellWidth: 17, minCellHeight: 2},
                            7: {halign: 'center', valign: 'middle', cellWidth: 35, overflow: 'ellipsize', minCellHeight: 2},
                            // Add more columns as needed
                        },
                        bodyStyles: {
                            lineColor: [0, 0, 0],
                            halign: 'center',
                            Padding: 0,
                        },
                        styles: {
                            font: "GILL",
                            halign: 'center'
                        },
                        didParseCell: function (data) {
                                // if (data.row.index === length + 1) {
                                //     data.cell.styles.textColor = [255, 0, 0];
                                // }
                                // // else if(data.row.index === 0){
                                // //     data.cell.styles.fontSize = 7;
                                // //     data.cell.minCellHeight = 2;
                                // // }
                                // else if(data.row.index === get_total_length + 2){
                                //     data.cell.minCellHeight = 2;
                                //     data.cell.styles.textColor = [255, 0, 0];
                                // }
                                if(data.row.index === length + 3){
                                    // data.cell.styles.cellPadding = 9;
                                    data.cell.styles.cellPadding = { top: 4.5, bottom: 4.5 };
                                    data.cell.styles.textColor = [255, 0, 0];
                                }
                                else if(data.row.index === length + 5){
                                    data.cell.styles.fontSize = 6;
                                    data.cell.styles.cellPadding = .5;
                                    data.cell.styles.overflow = 'linebreak';
                                    data.cell.styles.halign = 'justify';
                                    data.cell.styles.textColor = [0, 0, 0];
                                    data.cell.styles.lineWidth = 0;
                                }
                                else if(data.row.index === length + 6){
                                    data.cell.styles.fontSize = 6;
                                    data.cell.styles.cellPadding = .5;
                                    // data.cell.styles.lineWidth = [0, 0, 0, 0.5]; // top, right, bottom, left
                                    data.cell.styles.textColor = [0, 0, 0];
                                    data.cell.styles.halign = 'justify';
                                    data.cell.styles.lineWidth = 0;
                                }
                                else if(data.row.index >= 1 && data.row.index <= (length + 1)){
                                    if(data.cell.text[0].length > 11){
                                        data.cell.minCellHeight = 2;
                                        data.cell.styles.fontSize = 4.7;
                                        data.cell.styles.overflow = 'linebreak';
                                    } else {
                                        data.cell.minCellHeight = 2;
                                        data.cell.styles.fontSize = 7;
                                    }
                                }
                                else {
                                    data.cell.minCellHeight = 2;
                                    data.cell.styles.fontSize = 7;
                                    data.cell.styles.textColor = [0, 0, 0]; // Set text color
                                }
                            }
                        });

                        doc.setDrawColor(0, 0, 0);
                        doc.setFontSize(9).setFont('GILL','normal').text(113.5,e.type_of_accountability_box_text,'Type of Accountability');
                        doc.rect(115, e.type_of_accountability_box_rect_second, 4, 4, this.GET_PRINT_ADMIN_DETAIL.transfer_asset ? 'F' : 'S');
                        doc.setFontSize(9).setFont('GILL','normal').text(120.5,e.transfer_accountability_text,`Transfer Accountability`);
                        doc.setFontSize(9).setFont('GILL','normal').text(121,e.new_as,`New AS`);
                        doc.rect(115, e.type_of_accountability_box_rect_third, 4, 4, this.GET_PRINT_ADMIN_DETAIL.new_asset ? 'F' : 'S');
                        doc.setFontSize(9).setFont('GILL','normal').text(121,e.new_as + 4.5,`Re-issue AS`);
                        doc.rect(115, e.type_of_accountability_box_rect_third + 4.6, 4, 4, this.GET_PRINT_ADMIN_DETAIL?.re_issue_as ? 'F' : 'S');
                        doc.setFontSize(9).setFont('GILL','normal').text(170,e.reference_as,`Reference AS No's`);
                        doc.setFontSize(7).setFont('GILL','normal').text(170,e.previous_num, this.GET_PRINT_ADMIN_DETAIL?.previous_num ? this.GET_PRINT_ADMIN_DETAIL.previous_num : '');
                        doc.line(170,e.line_first,205,e.line_first);
                        doc.line(170,e.line_second,205,e.line_second);

                        // for the label of name and department
                        // doc.setFontSize(9).setFont('GILL','normal').text(11,e.recipient,'Recipient:');
                        // doc.setFontSize(9).setFont('GILL','normal').text(11,e.department_location,'Department / Location :');

                        
                        // accountability logo right
                        var text = "ACCOUNTABILITY SHEET";
                        doc.setFillColor(192,192,192);
                        doc.setDrawColor(0);
                        doc.setLineWidth(0.5);
                        doc.roundedRect(120, e.accountability_logo, 81, 8, 2, 2, 'FD');
                        doc.setTextColor(0,0,0);
                        doc.setFontSize(10).setFont('GILL','bold').text(135,e.accountability_text,text);
                        
                        let first_y = doc.lastAutoTable.finalY;
                        
                        // prepared by and others
                        doc.setFontSize(5).setFont('GILL','normal').text(11,first_y - 33.8,`Prepared By:`);
                        doc.setFontSize(7).setFont('GILL','normal').text(11,first_y - 28, this.GET_PRINT_ADMIN_DETAIL.prepared_by ?? '');
                        doc.setFontSize(5).setFont('GILL','normal').text(11,first_y - 23.5,`Admin Assistant`);
                        doc.setFontSize(5).setFont('GILL','normal').text(71,first_y - 33.8,`Checked By:`);
                        doc.setFontSize(7).setFont('GILL','normal').text(71,first_y - 28, this.GET_PRINT_ADMIN_DETAIL.checked_by ?? '');
                        doc.setFontSize(5).setFont('GILL','normal').text(71,first_y - 23.5,`Admin Officer`);
                        doc.setFontSize(5).setFont('GILL','normal').text(121,first_y - 33.8,`Noted By:`);
                        doc.setFontSize(7).setFont('GILL','normal').text(121,first_y - 28, this.GET_PRINT_ADMIN_DETAIL.noted_by ?? '');
                        doc.setFontSize(5).setFont('GILL','normal').text(121,first_y - 23.5,`Admin Manager`);
                        
                        
                        this.lastY = doc.lastAutoTable.finalY;

                        // under the form
                        doc.setFontSize(4).setFont('GILL','bold').text(165,this.lastY + 1.5,this.info.sheetCopy);
                        doc.setFontSize(4).setFont('GILL','bold').text(12,this.lastY + 1.5,this.info.sheetVersion);

                        // for signature
                        doc.setDrawColor(0, 0, 0);
                        doc.line(160,this.lastY - 5,205,this.lastY - 5);
                        doc.setFontSize(5).setFont('GILL','normal').text(170,this.lastY - 3.7,"Signature over Printed Name/Date");
                        
                        doc.setLineWidth(0.1);
                        doc.setDrawColor(0, 0, 0);
                        doc.line(10,this.lastY - 16.25 ,205,this.lastY - 16.25);
                        doc.line(10,this.lastY ,205,this.lastY);
                        doc.line(10,this.lastY - 30 ,10,this.lastY);
                        doc.line(205,this.lastY - 30 ,205,this.lastY);

                        });

                        doc.autoPrint();
                        // doc.save('Accountability Sheet.pdf');
                        window.open(doc.output('bloburl'), '_blank');
                    }

        }
    },
    watch:{
        GET_PRINT_ACCOUNTABILITY:{
            handler(val){
               if(val){
                this.printData = this.GET_PRINT_ADMIN_DETAIL
                this.base64Image = this.PURCHASE_ORDER.COMPANY_IMAGE
                this.check_for_count = this.GET_PRINT_ADMIN_DETAIL.admin_accountabilityitem.length;
                console.log(this.check_for_count);
                this.data = this.GET_PRINT_ADMIN_DETAIL.admin_accountabilityitem;
                this.renderPDF();
                this.$store.commit('PRINT_ADMIN',false);
               }
            },
            deep:true
        }
    }
  }
  </script>
  <style>
  </style>