<template>
    <v-container class="p-4" fluid>
        <v-card-title class="mb-2 p-0">
            <h4 class="page-title">VAT Types</h4>
        </v-card-title>
        <v-container class="m-0 p-0">
            <div>
                <v-btn
                    v-if="userAccess.includes('create')"
                    @click="openDialog"
                >
                    <v-icon>mdi-plus</v-icon>
                    Add
                </v-btn>
            </div>
        </v-container>
        <dialogs :cp="component" :width="'32rem'"></dialogs>
        <div class="mt-2">
            <vatTypeTableComponent></vatTypeTableComponent>
        </div>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import dialogs from '../../layouts/dialogs/Dialog.vue';
import vatTypeComponent from '../../layouts/dialogs/Masterdata/VatTypeComponent.vue';
import vatTypeTableComponent from '@/views/main/modules/MasterData/tables/VatTypeTableComponent.vue';

export default {
    props: [],
    mixins: [],

    components: {
        dialogs,
        vatTypeComponent,
        vatTypeTableComponent,
    },

    data() {
        return {
            component: vatTypeComponent,
            userAccess: [],
        };
    },

    mounted() {
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        .then(response => {
            this.userAccess = this.USER_ACCESS.map(access => access.actions_code);
        });
    },

    computed: {
        ...mapGetters([
            'USER_ACCESS',
        ]),
    },

    methods: {
        openDialog() {
            this.$store.commit('DIALOG', true);
            this.$store.commit('ACTION', 'Submit');
        }
    },

    watch: {

    },
}
</script>