import { render, staticRenderFns } from "./DefectiveDtaViewComponent.vue?vue&type=template&id=411230e3&scoped=true"
import script from "./DefectiveDtaViewComponent.vue?vue&type=script&lang=js"
export * from "./DefectiveDtaViewComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "411230e3",
  null
  
)

export default component.exports