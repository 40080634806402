<template>
    <div class="w-100">
      <v-app id="my_vue_app">
        <v-card>
          <v-card-title>
              <v-container fluid>
                <v-row>
                  <v-col cols="12" sm="6" class="d-flex justify-content-start">
                    <v-col cols="4" sm="4" class="pa-0 pr-2">
                    <v-autocomplete
                          v-model="filter.department_id"
                          outlined
                          :items="GET_LIST_DEPARTMENT"
                          item-text="text"
                          item-value="value"
                          auto-select-first
                          required
                          multiple
                          label="Department"
                          small-chips
                          deletable-chips
                          dense
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="4" sm="4" class="pa-0 pr-2">
                        <v-autocomplete
                          v-model="filter.location_id"
                          outlined
                          :items="GET_ADMIN_LOCATION"
                          item-text="text"
                          item-value="value"
                          auto-select-first
                          required
                          multiple
                          label="Location"
                          small-chips
                          deletable-chips
                          dense
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="4" class="pa-0">
                          <v-btn @click="getAllfasrequisition()">
                              FILTER
                          </v-btn>
                      </v-col>
                  </v-col>
                  <v-col cols="12" md="2"></v-col>
                  <v-col cols="8" md="4">
                      <div class="row col-md-12 align-items-end">
                          <v-text-field
                              id="searchBar"
                              v-model="filter.search"
                              label="Search REF #"
                              single-line
                              hide-details
                              @change="searchInput()"
                              @keydown.enter="searchInput()">
                          </v-text-field>
                      <v-icon @click="searchInput()">mdi-magnify</v-icon>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="6" class="d-flex justify-content-start py-0">
                    <v-col cols="4" sm="4" class="pa-0 pr-2">
                    <v-autocomplete
                          v-model="filter.company_id"
                          outlined
                          :items="GET_PV_COMPANIES_SELECTION"
                          item-text="text"
                          item-value="value"
                          auto-select-first
                          required
                          multiple
                          label="Company"
                          small-chips
                          deletable-chips
                          dense
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="4" sm="4" class="pa-0 pr-2">
                        <v-autocomplete
                          v-model="filter.store_branches_id"
                          outlined
                          :items="GET_PV_STORE_BRANCHES_SELECTION"
                          item-text="text"
                          item-value="value"
                          auto-select-first
                          required
                          multiple
                          label="Store Branch"
                          small-chips
                          deletable-chips
                          dense
                        ></v-autocomplete>
                      </v-col>
                  </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pl-5">
                  <v-btn @click="massApprove()" v-if="selectItem.length > 0">APPROVE</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-title>
          <v-container fluid>
            <v-data-table
              v-model="selectItem"
              :headers="headers"
              :items="items"
              :search="search"
              :options.sync="options"
              :server-items-length="totalItems"
              :items-per-page="10"
              :loading="!loaded"
              :footer-props="{
                      itemsPerPageOptions: itemsPerPageOptions,
              }"
              loading-text="Loading... Please wait"
              fixed-header
              selectable-key="id"
              :show-select="userAccess.mass_approve && this.GET_ADMIN_FASREQUISITION_STATUS.tab_name != 'APPROVED'"
            >
            <template v-slot:[`item.status`]="{ item }">
                    <a  class="ml-1 badge text-white" :style="`background-color:${STATUS.ADMIN_FAS_REQUISITION.find(e=>e.value === item.status)?.color}`">{{STATUS.ADMIN_FAS_BASKET.find(e=>e.value === item.status)?.text}}</a>
            </template>
            <template v-slot:[`item.department_id`]="{ item }">
                {{ GET_LIST_DEPARTMENT.find(e=>e.value == item.department_id)?GET_LIST_DEPARTMENT.find(e=>e.value == item.department_id).text:'N/A' }}
            </template>
            <template v-slot:[`item.location_id`]="{ item }">
                {{ GET_ADMIN_LOCATION.find(e=>e.value == item.location_id)?GET_ADMIN_LOCATION.find(e=>e.value == item.location_id).text:'N/A' }}
            </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-btn text icon color="orange" v-if="userAccess.view">
                  <v-icon class="btn-action" small @click="ShowItem(item,'View')"
                    >mdi-eye</v-icon>
                </v-btn>
                <v-btn text icon color="blue" v-if="userAccess.edit && ![2,3].includes(item.status)">
                  <v-icon class="btn-action" small @click="ShowItem(item,'Update')"
                    >mdi-square-edit-outline</v-icon
                  >
                </v-btn>
                <v-btn text icon color="red" v-if="userAccess.delete && ![2,3].includes(item.status)" >
                  <v-icon class="btn-action" small @click="Delete(item.id)"
                    >mdi-delete</v-icon
                  >
                </v-btn>
              </template>
            </v-data-table>
          </v-container>
        </v-card>
      </v-app>
    </div>
  </template>
  <script>
  import swal from "sweetalert2";
  import { mapGetters } from 'vuex';
  export default {
    data() {
      return {
        loaded: false,
        totalItems: 0,
        options: {},
        items: [],
        itemsPerPageOptions: [5, 10, 15, 50, 100],
        itemsPerPage: 10,
        search: "",
        filter:{
          search:"",
          location_id:[],
          department_id:[],
          company_id:[],
          store_branches_id:[]
        },
        awaitingSearch: false,
        headers: [
          { text: "REF #", align: "left", value: "fas_number" },
          { text: "Transaction", align: "left", value: "transaction_type" },
          { text: "Full Name", align: "left", value: "full_name" },
          { text: "Department", align: "left", value: "department_id" },
          { text: "Company", align: "left", value: "company_name" },
          { text: "Store Branch", align: "left", value: "store_branch_name" },
          { text: "Location", align: "left", value: "location_id" },
          { text: "Status", align: "left", value: "status" },
          { text: "Remarks", align: "left", value: "justification_remarks" },
          { text: "Action", align: "center", value: "action",sortable:false},
        ],
        count:0,
        userAccess: {
          view: false,
          create: false,
          edit: false,
          delete: false,
          approve:false,
          mass_approve:false
        },
        selectItem:[],
        tmp:[]
      };
    },
    computed:{
      ...mapGetters(['USER_ACCESS','GET_NEW_ADMIN_ASSETS_TABLE',
      'GET_ADMIN_FASREQUISITION_DISPATCH',
      'GET_ASSETS_DATA',
      'GET_NEW_ADMIN_FASREQUISITION',
      'GET_ADMIN_FASREQUISITION_STATUS',
      "GET_LIST_DEPARTMENT",
      "GET_ADMIN_LOCATION",
      'GET_ADMIN_FASREQUISITION_DISPATCH_REFRESH',
      'STATUS',
      'GET_PV_STORE_BRANCHES_SELECTION',
      'GET_PV_COMPANIES_SELECTION'

    ]),
   
    },
    mounted() {
     
      this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
    },
    methods: {
      getAllfasrequisition(filter = null) {
        this.items = [];
        this.loaded = false;
        let payload = {
          page: this.options.page,
          itemsPerPage: this.options.itemsPerPage,
          search: this.filter.search,
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          location_id:  JSON.stringify(this.filter.location_id),
          department_id: JSON.stringify(this.filter.department_id),
          company_id: JSON.stringify(this.filter.company_id),
          store_branches_id: JSON.stringify(this.filter.store_branches_id),
          forPO:false,
          status:this.GET_ADMIN_FASREQUISITION_STATUS.tab_name
        };
        this.$store.dispatch(this.GET_ADMIN_FASREQUISITION_DISPATCH.get, payload).then((response) => {
          this.items =response.data.data.data;
          this.loaded = true;
          this.totalItems = response.data.data.total;
          this.$store.commit('NEW_ADMIN_FASREQUISITION',false);
        }).catch((e)=>{
          this.loaded = true;
        });
      },
      searchInput(){
          this.options.page = 1;
          this.getAllfasrequisition({ query: this.search });
      },  
      ShowItem(item,action) {
        this.$store.commit('EDIT_FAS_REQUISITION',item);
        this.$store.dispatch("showFasrequisition", {id:item.id});
        this.$store.commit("DIALOG", true);
        this.$store.commit("ACTION", action);
      },
      Delete(id) {
        swal
          .fire({
            icon: 'question',
            text: "Are you sure you want to Delete?",
            cancelButtonText: "Cancel",
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonColor: "#397373",
            cancelButtonColor: "#397373",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$store
                .dispatch(this.GET_ADMIN_FASREQUISITION_DISPATCH.delete, id)
                .then((success) => {});
            }
          });
      },
      massApprove(){
        swal
          .fire({
            icon: 'question',
            text: "Are you sure you want to approve the selected items? This action cannot be undone.",
            cancelButtonText: "Cancel",
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonColor: "#397373",
            cancelButtonColor: "#397373",
          })
          .then((result) => {
            if (result.isConfirmed) {
              let ids = this.$queryBuilder.table(this.selectItem).pluck('id').get();
              this.$store.dispatch(this.GET_ADMIN_FASREQUISITION_DISPATCH.approve_all,{ids:JSON.stringify(ids)}).then((success) => {});
            }
          });
      },
      onSelect(selectedItems){
        if(this.$queryBuilder.table(this.selectItem).where('check',1).pluck('check').get().length > 0){
          // this.$queryBuilder.table(this.selectItem).where('check',1).delete()
          this.selectItem = this.$queryBuilder.table(this.selectItem).where('check',1).get()
          this.selectItem.forEach(e=>{
            e.check = 0
        })
        }else{
          this.selectItem = this.$queryBuilder.table(this.selectItem).where('status','!=',3).where('check','!=',0).get()
          this.selectItem.forEach(e=>{
            e.check = 1
        })
        }
        
        
        // console.log(this.selectItem)
        
        // this.selectItem = selectedItems.filter(item => item.status !== 3);
      }
    },
    watch: {
      'GET_ADMIN_FASREQUISITION_STATUS.tab_name':{
        handler(val){
          this.getAllfasrequisition();
        }
      },
      GET_NEW_ADMIN_FASREQUISITION:{
        handler(val){
          if(val){
            this.getAllfasrequisition();
            this.selectItem = [];
          }
        }
      },
      options: {
        handler() {
          this.getAllfasrequisition();
        },
        deep: true,
      },  
      USER_ACCESS: {
        handler(val) {
          if (val != "fail") {
            this.userAccess.edit = false;
            this.userAccess.delete = false;
            this.userAccess.view = false;
            this.userAccess.create = false;
            this.userAccess.approve = false
            this.userAccess.mass_approve = false
            val.forEach((e) => {
              if (e.actions_code == "edit") {
                this.userAccess.edit = true;
              } else if (e.actions_code == "delete") {
                this.userAccess.delete = true;
              } else if (e.actions_code == "view") {
                this.userAccess.view = true;
              } else if (e.actions_code == "create") {
                this.userAccess.create = true;
              }else if(e.actions_code == 'approve'){
                this.userAccess.approve = true
              }
              else if(e.actions_code == 'mass_approve'){
                this.userAccess.mass_approve = true
              }
            });
          }
        },
      },
    },
  };
  </script>