<template>
    <div class="tabs-body-color">
        <v-container fluid class="pa-0">
            <v-row class="pt-4 px-3" v-if="userAccess.create">
                <v-col cols="12">
                    <div class="btn-toolbar pl-0">
                        <v-btn class="ml-1 mb-2" @click="AddDialog()">
                            <v-icon>mdi-plus</v-icon>Add
                        </v-btn>
                        <v-spacer></v-spacer>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <AdminItemUomTableComponent v-if="GET_ADMIN_ITEM_STATUS == isActive_tab"></AdminItemUomTableComponent>
            </v-row>
        </v-container>
    </div>
</template>
<script>

import AdminItemUomTableComponent from '@/views/main/modules/Admin/Items/AdminItemUomTableComponent.vue';
import { mapGetters } from 'vuex'
export default {
    components: {
        AdminItemUomTableComponent,
    },
    props:["isActive_tab"],
    data() {
        return {
            userAccess:{
                create:false
            }
        }
    },
    computed: {
        ...mapGetters(['USER_ACCESS','GET_ADMIN_ITEM_STATUS'])
    },
    mounted() {},
    methods: {
        AddDialog(){
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", "Submit");
        },
    },
    watch:{
        USER_ACCESS:{
            handler(val){
                if(val != "fail"){
                    this.userAccess.create = false;
                    val.forEach(e => {
                        if (e.actions_code == 'create') {
                            this.userAccess.create = true;
                        }
                    });
                }
            }
        }
    }
};
</script>
