<template>
  <div>
      <div>
          <v-btn v-if="showAddButton()" :disabled="!selectedCustomer" @click="showAddRfDialog()">Add Item</v-btn>
      </div>
      <v-data-table
        :headers="repairFormTableHeaders"
        :items="inputItemList"
        :loading="loading"
        :no-data-text="noDataText"
      >
      <template v-slot:item.action="{ item }">
          <td>
              <v-icon class="btn-action" color="red" small @click="deleteRf(item.id)">mdi-delete</v-icon>
          </td>
      </template>
      </v-data-table>

      <!-- Add Repair Form Dialog -->
      <v-dialog v-model="addRfDialog" persistent max-width="85%" scrollable>
          <v-card>
              <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Add Item</span>
                        </v-card-title>
                    </v-col>

                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeAddRfDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider class="mt-0" />
                <v-card-text>
                    <div class="pa-2">
                        <v-card>
                            <v-layout v-if="false" class="pa-2 mx-2" row>
                                <v-spacer />
                                <v-flex lg2 class="mr-2">
                                    <div>
                                        <label style="color:gray">Upload Warranty Document:</label>
                                    </div>
                                    <div>
                                        <v-file-input
                                            v-model="warrantyImages"
                                            prepend-icon="mdi-camera"
                                            accept="image/png, image/jpeg, image/bmp"
                                            outlined
                                            dense
                                            :disabled="true"
                                        />
                                    </div>
                                </v-flex>
                                <v-flex lg2>
                                    <div>
                                        <label style="color:gray">Upload Item Image:</label>
                                    </div>
                                    <div>
                                        <v-file-input
                                            v-model="itemImages"
                                            prepend-icon="mdi-camera"
                                            accept="image/png, image/jpeg, image/bmp"
                                            outlined
                                            dense
                                            :disabled="true"
                                        />
                                    </div>
                                </v-flex>
                            </v-layout>
                            <v-divider v-if="false" class="mt-0" />
                            <v-layout class="pa-2 mx-2" row>
                                <v-flex lg1 class="pr-2">
                                    <v-checkbox
                                    label="Backjob"
                                    v-model="isBackjob"
                                    dense
                                    :disabled="isOnline || isDefective "
                                    >
                                    </v-checkbox>

                                </v-flex>
                                <v-flex lg1 class="pr-2">
                                    <v-checkbox
                                    label="Online"
                                    v-model="isOnline"
                                    dense
                                    :disabled="isBackjob || isDefective "

                                    >
                                    </v-checkbox>
                                </v-flex>
                                <v-flex lg1 class="pr-2">
                                    <v-checkbox
                                    label="Defective"
                                    v-model="isDefective"
                                    dense
                                    :disabled="isOnline || isBackjob "
                                    >
                                    </v-checkbox>
                                </v-flex>
                            </v-layout>
                            <v-layout class="pa-2 mx-2" row>
                                <v-flex lg4 class="pr-2">
                                        <v-autocomplete
                                            v-model="selectedItemModel"
                                            :items="GET_ITEMS_EXCLUDE_SP"
                                            :rules="rules.default"
                                            item-text="model"
                                            item-value="id"
                                            label="Select Model"
                                            auto-select-first
                                            dense
                                            outlined
                                            @input="selectedItem()"
                                        >
                                        <template #append >
                                            <span style="color:red">*</span>
                                        </template>

                                    </v-autocomplete>

                                </v-flex>

                                <v-flex lg5 class="pr-2">
                                    <div>
                                        <v-text-field v-model="itemProductName" :rules="rules.default" label="Item Name" readonly dense outlined>
                                            <template #append >
                                            <span style="color:red">*</span>
                                        </template>
                                        </v-text-field>
                                    </div>
                                </v-flex>

                                <v-flex lg3>
                                    <div>
                                        <v-text-field v-model="itemCategory" :rules="rules.default" label="Category" readonly dense outlined>
                                            <template #append >
                                            <span style="color:red">*</span>
                                        </template>
                                        </v-text-field>
                                    </div>
                                </v-flex>
                            </v-layout>
                            <v-divider class="mt-2"/>
                            <v-layout class="pa-2 mx-2 mb-1" row>
                                <v-flex lg4 class="pr-2">
                                    <div>
                                        <v-text-field v-model="itemSerialNumber" :rules="rules.default" label="Serial No" dense outlined>
                                            <template #append >
                                            <span style="color:red">*</span>
                                        </template>
                                    </v-text-field>
                                    </div>
                                </v-flex>

                                <v-flex lg3 class="pr-2">
                                    <div>
                                        <v-autocomplete
                                            v-model="itemWarrantyType"
                                            :items="warrantyTypeItems()"
                                            :rules="rules.default"
                                            label="Warranty Type"
                                            auto-select-first
                                            dense
                                            outlined
                                        >
                                        <template #append >
                                            <span style="color:red">*</span>
                                        </template>
                                    </v-autocomplete>
                                    </div>
                                </v-flex>

                                <v-flex v-if="checkWarrantyType" lg2 class="pr-2">
                                    <div>
                                        <v-autocomplete
                                            v-model="itemWarrantyDocument"
                                            :items="['Receipt','Warranty Card', 'Receipt & Warranty Card']"
                                            :rules="rules.default"
                                            label="Warranty Document"
                                            auto-select-first
                                            clearable
                                            dense
                                            outlined
                                        >
                                        <template #append >
                                            <span style="color:red">*</span>
                                        </template>
                                    </v-autocomplete>
                                    </div>
                                </v-flex>

                                <v-spacer />

                                <v-flex v-if="checkWarrantyType" lg2>
                                    <div>
                                        <v-dialog
                                            ref="dateDialog"
                                            v-model="dateModal"
                                            :return-value.sync="itemPurchaseDate"
                                            persistent
                                            width="290px"

                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="itemPurchaseDate"
                                                v-bind="attrs"
                                                v-on="on"
                                                :rules="rules.default"
                                                label="Purchase Date"
                                                readonly
                                                clearable
                                                dense
                                                outlined
                                            >
                                            <template #append >
                                            <span style="color:red">*</span>
                                        </template>
                                        </v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="itemPurchaseDate"
                                                scrollable
                                                :min="minDate()"
                                                :max="maxDate"
                                            >
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="dateModal = false"
                                            >
                                                Cancel
                                            </v-btn>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="$refs.dateDialog.save(itemPurchaseDate)"
                                            >
                                                OK
                                            </v-btn>
                                            </v-date-picker>
                                        </v-dialog>
                                    </div>
                                </v-flex>
                            </v-layout>
                            <v-layout  class="pa-2 mx-2 mb-1">
                                <v-flex lg4 class="pr-2" v-if="isBackjob == 1">
                                        <v-autocomplete
                                            v-model="backJob"
                                            :items="GET_BACKJOB_COM"
                                            :rules="rules.default"
                                            item-text="text"
                                            item-value="value"
                                            label="Backjob"
                                            auto-select-first
                                            dense
                                            outlined
                                            @input="selectedItem()"
                                        >
                                         <template #append >
                                             <span style="color:red">*</span>
                                         </template>
                                        </v-autocomplete>

                                </v-flex>
                                <v-flex lg4 class="pr-2" v-if="isOnline == 1">
                                    <v-text-field
                                        v-model="onlineStore"
                                        :rules="rules.default"
                                        label="Store"
                                        dense
                                        outlined
                                    >
                                   <template #append >
                                        <span style="color:red">*</span>
                                    </template>
                                   </v-text-field>

                                </v-flex>
                            </v-layout>
                            <v-divider class="mt-0" />

                            <div class="mx-4">
                                <label style="color:gray">Included Items: <span style="color:red">*</span></label>
                            </div>
                            <v-layout class="pa-2 mx-2" row>
                                <v-flex lg3>
                                    <v-checkbox
                                        v-model="includedItems"
                                        label="Packaging"
                                        value="Packaging"
                                    />
                                </v-flex>
                                <v-flex lg3>
                                    <v-checkbox
                                        v-model="includedItems"
                                        label="Warranty Docs"
                                        value="Warranty Docs"
                                    />
                                </v-flex>
                                <v-flex lg3>
                                    <v-checkbox
                                        v-model="includedItems"
                                        label="Manual"
                                        value="Manual"
                                    />
                                </v-flex>
                                <v-flex lg3>
                                    <v-checkbox
                                        v-model="includedItems"
                                        label="Accessories"
                                        value="Accessories"
                                    />
                                </v-flex>
                                <v-flex lg12 class="mb-2">
                                    <v-textarea
                                        v-model="otherAccessory"
                                        label="Others included items:"
                                        outlined
                                        dense
                                    />
                                </v-flex>
                            </v-layout>

                            <v-divider class="mt-0" />

                            <div class="mx-4">
                                <label style="color:gray">Physical Condition: <span style="color:red">*</span></label>
                            </div>
                            <v-layout class="pa-2 mx-2" row>
                                <v-flex lg3>
                                    <v-checkbox
                                        v-model="physicalCondition"
                                        label="Dents"
                                        value="Dents"
                                    />
                                </v-flex>
                                <v-flex lg>
                                    <v-checkbox
                                        v-model="physicalCondition"
                                        label="Scratches"
                                        value="Scratches"
                                    />
                                </v-flex>
                                <v-flex lg3>
                                    <v-checkbox
                                        v-model="physicalCondition"
                                        label="Faded Paint"
                                        value="Faded Paint"
                                    />
                                </v-flex>
                                <v-flex lg3>
                                    <v-checkbox
                                        v-model="physicalCondition"
                                        label="Crack/Broken"
                                        value="Crack/Broken"
                                    />
                                </v-flex>
                                <v-flex lg12 class="mb-2">
                                    <v-textarea
                                        v-model="otherPhysicalCondition"
                                        label="Others physical condition:"
                                        outlined
                                        dense
                                    />
                                </v-flex>
                            </v-layout>

                            <v-divider class="mt-0" />
                            <v-layout class="pa-2 mx-2" row>
                                <v-flex lg6 class="pr-2">
                                    <div>
                                        <v-textarea
                                            v-model="itemDefectDescription"
                                            :rules="rules.default"
                                            label="Complaint / Symptom"
                                            dense
                                            outlined
                                        >
                                    <template #append>
                                        <span style="color:red">*</span>
                                    </template>
                                    </v-textarea>
                                    </div>
                                </v-flex>
                                <v-flex lg6 class="pr-2">
                                    <div>
                                        <v-textarea
                                            v-model="itemDefectRemarks"
                                            label="Remarks:"
                                            outlined
                                            dense
                                        />
                                    </div>
                                </v-flex>
                            </v-layout>
                        </v-card>
                    </div>
                </v-card-text>
                <v-divider />

                <div class="pb-4 d-flex justify-center">
                    <v-btn class="mr-2" @click="addItem()">Add</v-btn>
                    <!-- <v-btn v-if="isBackjob == true" @click="showOverride()">Confirm Override</v-btn> -->
                </div>

                <ConfirmOverrideComponentVue
                    :approve_override_dialog="override.override_dialog"
                    :departments_allowed="override.override_departments_allowed"
                    :heading="'For Backjob'"
                    @closeConfirmOverride="closeConfirmOverride"
                ></ConfirmOverrideComponentVue>
          </v-card>
      </v-dialog>
  </div>
</template>

<script>
import ConfirmOverrideComponentVue from "../../Utils/ConfirmOverrideComponent.vue";
import BaseDatePicker from './Base/BaseDatePicker.vue';
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';

export default {
    data() {
        return {
            repairFormTableHeaders: [
                { text: 'Model', value: 'model' },
                { text: 'Description', value: 'productName' },
                { text: 'Serial No', value: 'serialNumber' },
                { text: 'Included Items', value: 'accessories' },
                { text: 'Physical Condition', value: 'physicalCondition' },
                { text: 'Action', value: 'action' }
            ],
            loading: false,
            noDataText: '',
            inputItemList: [],
            addRfDialog: false,
            itemModel: '',
            itemProductName: '',
            itemCategory: '',
            itemSerialNumber: '',
            itemWarrantyType: '',
            itemWarrantyDocument: '',
            itemDefectDescription: '',
            itemDefectRemarks: '',
            itemList: [],
            selectedItemModel: '',
            includedItems: [],
            otherAccessory: '',
            physicalCondition: [],
            otherPhysicalCondition: '',
            itemPurchaseDate: '',
            itemPullOutNo: '',
            dateModal: false,
            itemImages: [],
            warrantyImages: [],
            warehouse: '',
            maxDate: this.$dayjs().format('YYYY-MM-DD'),

            selectedCustomer: '',
            isOnline: 0,
            isBackjob: 0,
            isDefective: 0,
            onlineStore: null,
            backJob: '',
            override: {
                override_dialog: false,
                errors: [],
                override_departments_allowed: ['SR', 'IT'],
                override_authenticated: false,
            },
            rules: {
                default: [
                v => !!v || 'This is required',
                ],
            },
        }
    },
    props: ['successCreate', 'customerId'],
    components: {
        BaseDatePicker,
        ConfirmOverrideComponentVue,

    },
    computed: {
        ...mapGetters([
            'GET_SERVICE_ALL_ITEMS','GET_ITEMS_EXCLUDE_SP','GET_BACKJOB_COM', 'GET_BACKJOB_SELECTION'
        ]),
        checkWarrantyType() {
            if(this.itemWarrantyType == 'In-Warranty') {
                return true;
            }
            return false;
        }
    },
    watch: {
        customerId() {
            if(!!this.customerId) {
                this.selectedCustomer = this.customerId;
            } else {
                this.selectedCustomer = '';
            }
        },
        selectedItemModel() {
            if(!!this.selectedItemModel) {
                let item = this.GET_ITEMS_EXCLUDE_SP.find(item => item.id == this.selectedItemModel);
                if(!!item) {
                    this.itemProductName = item.name;
                    this.itemModel = item.model;
                }
            } else {
                this.itemProductName = "";
            }
        },
        inputItemList() {
            this.$emit('inputItemList', _.compact(this.inputItemList));
        },
        successCreate() {
            if(this.successCreate) {
                this.inputItemList = [];
            }
        },
    },
    methods: {
        showAddRfDialog() {
            this.addRfDialog = true;
        },
        closeAddRfDialog() {
            this.addRfDialog = false;
            this.resetFields()
        },
        resetFields() {
            this.selectedItemModel = "";
            this.itemProductName = "";
            this.itemSerialNumber = "";
            this.itemWarrantyType = "";
            this.itemDefectDescription = "";
            this.itemUnitCondition = "";
            this.includedItems = [];
            this.otherAccessory = '';
            this.physicalCondition = [];
            this.otherPhysicalCondition = '';
            this.itemPurchaseDate = '';
            this.itemWarrantyDocument = '';
            this.itemDefectRemarks = '';
            this.itemCategory = '';
            this.warehouse = '';
            this.itemPullOutNo = '';
            this.online = 0;
            this.store_name = null;
            this.backJob = ''
            this.isBackjob = false
            this.isOnline = false
            this.isDefective = false
        },
        addItem() {
            if(!this.requiredFields()) {
                if(!!this.selectedItemModel) {
                    if(this.isOnline && this.onlineStore === null){
                        swal.fire('Online is active','Please input Online Store name','warning')
                        return false;
                    }
                    let item = {
                        id: _.random(0,1000),
                                item_id: this.selectedItemModel,
                                model: this.itemModel,
                                productName: this.itemProductName,
                                serialNumber: this.itemSerialNumber,
                                category: this.itemCategory,
                                warrantyType: this.itemWarrantyType,
                                warrantyDocument: this.itemWarrantyDocument,
                                accessories: this.includedItems.toString(),
                                otherAccessory: this.otherAccessory,
                                physicalCondition: this.physicalCondition.toString(),
                                otherPhysicalCondition: this.otherPhysicalCondition,
                                defectDescription: this.itemDefectDescription,
                                defectRemarks: this.itemDefectRemarks,
                                purchaseDate: this.itemPurchaseDate,
                                online: this.isOnline,
                                store_name: this.onlineStore,
                                sc_repair_form_id:this.backJob,
                                isBackjob:this.isBackjob,
                                isDefective: this.isDefective,
                                created_at: this.GET_BACKJOB_SELECTION.created_at,
                                updated_at: this.GET_BACKJOB_SELECTION.updated_at,
                            }

                            let created = this.$dayjs(item.created_at);
                            let updated = this.$dayjs(item.updated_at);
                            let result = updated.diff(created, 'day')
                            if (this.isBackjob == true) {
                                if(result > 7) {
                                    swal.fire({
                                        title:'Warning !',
                                    text:'- This RF is not within 7 days, are you sure you want to override?',
                                    icon: 'question',
                                    showCancelButton:true,
                                    reverseButtons:true,
                                    }).then((confirm)=>{
                                        if(confirm.isConfirmed){
                                            return this.override.override_dialog = true;
                                            }
                                        })
                                } else if (this.$dayjs(item.purchaseDate.toString()).diff(this.$dayjs().subtract(6, 'month'),'day') < 0 ) {
                                    swal.fire({
                                        title:'Warning !',
                                        text:'- Purchase Date has passed more than 6 months, are you sure you want to continue?',
                                        icon: 'question',
                                        showCancelButton:true,
                                    }).then((confirm)=>{
                                        if(confirm.isConfirmed){
                                            return this.override.override_dialog = true;
                                        }
                                    })
                                } else {
                                    this.inputItemList.push(item);
                                    this.addRfDialog = false;
                                    this.resetFields();
                                }
                            } else {
                                this.inputItemList.push(item);
                                this.addRfDialog = false;
                                this.resetFields();
                            }

                            if(this.isBackjob == 1){
                                if(!this.backJob){
                                    swal.fire('Error!','- Please include RF','error')
                                    return false
                                }
                            }

                    }
                }
        },
        deleteRf(id) {
            if(this.inputItemList.length > 0) {
                this.inputItemList = this.inputItemList.filter(item => item.id != id);
            }
        },
        selectedPurchaseDate(date) {
            if(!!date) {
                this.itemPurchaseDate = date;
            }
        },
        showAddButton(){
            if(this.inputItemList.length >= 12) {
                return false;
            }
            return true;
        },
        selectedItem(){
            if(!!this.backJob){
                let payload = {
                    id: this.backJob,
                }
                // == 'Dealer-Stock' ? 'Dealer-Stock' : 'In-Warranty'
                this.$store.dispatch('getBackJobSelection',payload).then(response=>{
                    this.isOnline                  = this.GET_BACKJOB_SELECTION.online;
                    this.selectedItemModel         = this.GET_BACKJOB_SELECTION.item_id;
                    this.itemProductName           = this.GET_BACKJOB_SELECTION.product_name;
                    this.itemCategory              = this.GET_BACKJOB_SELECTION.category;
                    this.itemSerialNumber          = this.GET_BACKJOB_SELECTION.serial;
                    this.itemWarrantyType          = this.GET_BACKJOB_SELECTION.warranty_type;
                    this.itemWarrantyDocument      = this.GET_BACKJOB_SELECTION.warranty;
                    this.itemPurchaseDate          = this.GET_BACKJOB_SELECTION.purchase_date;
                    this.includedItems             = this.GET_BACKJOB_SELECTION.accessories.split(",");
                    this.otherAccessory            = this.GET_BACKJOB_SELECTION.other_accessory;
                    this.physicalCondition         = this.GET_BACKJOB_SELECTION.unit_condition.split(",");
                    this.otherPhysicalCondition    = this.GET_BACKJOB_SELECTION.other_unit_condition;
                    this.itemDefectDescription     = this.GET_BACKJOB_SELECTION.defect_description;
                    this.itemDefectRemarks         = this.GET_BACKJOB_SELECTION.defect_remarks;

                }).catch((err)=>[
                    console.log(err)
                ]);
            }

            if(!!this.selectedItemModel) {
                this.itemProductName = this.GET_ITEMS_EXCLUDE_SP.find(x => x.id == this.selectedItemModel).name;
                this.itemCategory = this.GET_ITEMS_EXCLUDE_SP.find(x => x.id == this.selectedItemModel).item_category;

            }
        },
        minDate() {
            return this.$dayjs().subtract(6, 'month').format('YYYY-MM-DD');
        },
        warrantyTypeItems() {
            if(this.customerId == 275) {
                return ['In-Warranty', 'Out-Warranty'];
            } else {
                if(this.itemCategory == 'Hand Tools' || this.itemCategory == 'Accessories' || this.itemCategory == 'Electrical' || this.itemCategory == 'Lights and Lamps') {
                    return ['In-Warranty', 'Dealer-Stock'];
                }
            }

            if(this.isBackjob == true){
                return ['In-Warranty', 'Out-Warranty', 'Dealer-Stock'];
            }

            if(this.isDefective == true) {
                return ['In-Warranty', 'Dealer-Stock'];
            }

            return ['In-Warranty', 'Out-Warranty'];
        },

        // showOverride(){
        //     this.override.override_dialog = true;
        // },

        closeConfirmOverride(user_id, authenticated = false) {
            if (authenticated) {
                this.override.override_dialog = false;
                this.pushConfirmOverride()
                // this.forReplacementApproval();
            } else {
                this.override.override_dialog = false;
                // this.showWarrantyReason = true;
            }
        },

        pushConfirmOverride(){
            let item = {
                id: _.random(0,1000),
                item_id: this.selectedItemModel,
                model: this.itemModel,
                productName: this.itemProductName,
                serialNumber: this.itemSerialNumber,
                category: this.itemCategory,
                warrantyType: this.itemWarrantyType,
                warrantyDocument: this.itemWarrantyDocument,
                accessories: this.includedItems.toString(),
                otherAccessory: this.otherAccessory,
                physicalCondition: this.physicalCondition.toString(),
                otherPhysicalCondition: this.otherPhysicalCondition,
                defectDescription: this.itemDefectDescription,
                defectRemarks: this.itemDefectRemarks,
                purchaseDate: this.itemPurchaseDate,
                online: this.isOnline,
                store_name: this.onlineStore,
                sc_repair_form_id:this.backJob,
                isBackjob:this.isBackjob,
                isDefective: this.isDefective,
                created_at: this.GET_BACKJOB_SELECTION.created_at,
                updated_at: this.GET_BACKJOB_SELECTION.updated_at,
            }

            if(this.inputItemList.length > 0) {
                let container = [];
                this.inputItemList.forEach(x => {
                    if(x.id == item.id) {
                        container.push(x);
                    }
                });

                if(container.length > 0) {
                    swal.fire('', 'Duplicate Id Detected', 'warning').then(confirm=>{
                        this.inputItemList = this.inputItemList.filter(x=>{
                            x.id != item.id;
                        });
                    })
                } else {
                    this.inputItemList.push(item);
                    this.addRfDialog = false;
                    this.resetFields();
                }

            } else {
                this.inputItemList.push(item);
                this.addRfDialog = false;
                this.resetFields();
            }
        },

        requiredFields() {
            let warning = '';
            if (!this.selectedItemModel) warning += 'Please select a Model.<br>';
            if (!this.itemProductName) warning += 'Please select a Item Name.<br>';
            if (!this.itemCategory) warning += 'Please select a Category.<br>';
            if (!this.itemSerialNumber) warning += 'Please ensure that the empty field for the serial number is filled in.<br>';
            if (!this.itemWarrantyType) warning += 'Please select a Warranty Type.<br>';
            if (!this.itemDefectDescription) warning += 'Please ensure that the empty field for the complaint / symptom is filled in.<br>';

            if(this.checkWarrantyType) {
                if (!this.itemWarrantyDocument) warning += 'Please select a Warranty Document.<br>';
                if (!this.itemPurchaseDate) warning += 'Please select a Purchase Date.<br>';
            }

            if(this.isBackjob == 1) {
                if (!this.backJob) warning += 'Please select a Backjob.<br>';
            }
            
            if(this.isOnline == 1) {
                if (!this.onlineStore) warning += 'Please select a Store.<br>';
            }


            if (warning !== '') return swal.fire({
                title: 'Please Fill out Information:',
                html: warning,
                icon: "warning",
            });
        }
    }
}
</script>

<style scope>
    .v-label.theme--light {
        font-size: 14px
    }
</style>
