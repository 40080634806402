<template>
  <v-container>
    <v-row>
      <v-col md="16" class="d-flex no-block align-items-center ma-3">
        <h4 class="page-title">{{ $route.meta.title }}</h4>
      </v-col>
    </v-row>
    <v-row class="pa-4">
        <v-expansion-panels v-model="panel" multiple v-if="filteredReports.length">
            <v-expansion-panel v-for="(button, i) in filteredReports" :key="i" v-if="hasAccessibleChildren(button.children)">
                <v-expansion-panel-header class="h5">
                    <div>{{ button.name.toUpperCase() }}</div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row no-gutters class="pa-0">
                      <v-col v-for="(item, e) in button.children" :key="e" v-if="access(item.access) && item.report_category_route == $route.name" lg="3" sm="12" class="pa-3">
                          <reportbutton
                              :button_title="item.title"
                              :button_icon="item.icon"
                              :button_route="item.route"
                              @click="salesReportsRoutes(item.route)"
                          >
                          </reportbutton>
                      </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-row v-else align="center" justify="center" class="text-center" style="height: 90%; padding-top: 17%;">
            <v-col cols="12">
                <h5 class="text-h1 text-white"> NO ACCESS </h5>
            </v-col>
        </v-row>
    </v-row>
  </v-container>
</template>
<script>
import reportbutton from "@/views/main/layouts/Buttons/ReportButtonComponent";
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            reports: [],
            userAccess: [],
            report_data: [],
            panel: [],
        };
    },
    props: [],
    methods:{
        salesReportsRoutes(route){
            this.$router.push({
                name:route
            })
        },
        all() {
            this.panel = Object.keys(this.reports).map((k, i) => i)
        },
        access(access) {
            let new_access = access.toLowerCase().replaceAll(/[`~!#\$%\^\*\+=\{\}\[\]\,\?;():]/g, "").replaceAll(/[\s]/g, "_")
            return this.userAccess.includes(new_access)
        },
        hasAccessibleChildren(children) {
            return children.some(child => this.access(child.access));
        },
    },

    components:{
        reportbutton
    },

    mounted(){
        this.$store.commit("LINK_ACTIVE", this.$router.app._route.params.id);
        this.$store.dispatch('accessReports').then(response => {
            this.reports = response.data
            this.all()
        })
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
    },

    watch:{
        '$route.meta.type':{
            handler(val){
                this.$store.dispatch('checkAccessUser',this.$router.currentRoute.params.id);
            },
        },
        USER_ACCESS: {
            handler(val) {
                this.userAccess = val.map(e => e.actions_code)
            }
        },

    },

    computed: {
        ...mapGetters([
            'GET_REPORTS',
            'USER_ACCESS',
        ]),

        filteredReports() {
            return this.reports.filter(button => this.hasAccessibleChildren(button.children));
        },
    },
  }

</script>

<style scoped>
</style>
