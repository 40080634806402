<template>
    <v-container>

        <ComponentHeader
            :button="true"
            title='Cycle Count Consolidated Item Variance'
            type='Inventory'
        ></ComponentHeader>


        <v-container fluid class="bg-bluish-gray">
            <v-row class="pa-2">
                <v-col lg="3">
                    <v-autocomplete :items="warehouses_list"
                        :loading="loading.warehouses_selection"
                        :disabled="loading.warehouses_selection"
                        background-color="white"
                        chips
                        outlined
                        dense
                        deletable-chips
                        small-chips
                        v-model="warehouse_id"
                        option-value="value"
                        option-text="text"
                        label="Select Warehouse"
                        class="small">
                    </v-autocomplete>
                </v-col>
                <v-col lg="3">
                    <v-autocomplete :items="GET_ITEMS"
                        :loading="loading.item_selection"
                        :disabled="loading.item_selection"
                        background-color="white"
                        multiple
                        chips
                        outlined
                        dense
                        deletable-chips
                        small-chips
                        v-model="item_ids"
                        item-value="value"
                        item-text="text"
                        label="Select Model"
                        class="small"
                        @select="onSelectItems">
                    </v-autocomplete>
                </v-col>
                <v-col lg="3">
                </v-col>
                <v-col class="text-right">
                    <v-btn small class="ml-1"  v-on:click="resetFields()">Clear</v-btn>
                    <v-btn small class="ml-1" v-on:click="getcycleCountConsolidatedItemVariance()">Filter</v-btn>
                </v-col>
            </v-row>

            <v-row class="pa-2">
                <v-col lg="3">
                    <v-autocomplete :items="GET_ITEM_TYPES"
                        :loading="loading.item_type_selection"
                        :disabled="loading.item_type_selection"
                        background-color="white"
                        multiple
                        chips
                        outlined
                        dense
                        deletable-chips
                        small-chips
                        v-model="item_type_ids"
                        item-value="value"
                        item-text="text"
                        label="Select Discount Type"
                        class="small"
                        @select="onSelectTypes">
                    </v-autocomplete>
                </v-col>
                <v-col lg="3">
                    <v-autocomplete :items="GET_ITEM_CATEGORIES"
                        :loading="loading.item_category_selection"
                        :disabled="loading.item_category_selection"
                        background-color="white"
                        multiple
                        chips
                        outlined
                        dense
                        deletable-chips
                        small-chips
                        v-model="item_category_ids"
                        item-value="value"
                        item-text="text"
                        label="Select Item Category"
                        class="small"
                        @select="onSelectCategories">
                    </v-autocomplete>
                </v-col>
                <v-col lg="3" id="date_from">

                    <v-menu
                        v-model="date_from_dialog"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="date_from"
                                readonly
                                outlined
                                    dense
                                    background-color="white"
                                    label="Date From"
                                append-icon="mdi-calendar-clock"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="date_from" @input="date_from_dialog = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col lg="3" id="date_from">

                    <v-menu
                        v-model="date_to_dialog"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="date_to"
                                readonly
                                outlined
                                    dense
                                    background-color="white"
                                    label="Date From"
                                append-icon="mdi-calendar-clock"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="date_to" @input="date_to_dialog = false"></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>

            <div class="w-100">
                <v-card v-show="loaded">
                    <v-container fluid>
                        <v-data-table
                            :headers="datatable.headers"
                            :items="datatable.data"
                            :options.sync="datatable.pagination.options"
                            :items-per-page="datatable.pagination.perPageDefault"
                            :footer-props="{
                                showFirstLastPage: true,
                                itemsPerPageOptions: [2, 5, 10 ,15, 50, 100, 250, 500, 1000]
                            }"
                            :server-items-length="datatable.pagination.total"
                            dense>
                        </v-data-table>
                    </v-container>
                </v-card>

        <v-app id="load_bar" v-if="!loaded">
            <v-text-field color="info" loading disabled></v-text-field>
        </v-app>
            </div>
        </v-container>

    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
export default {
    name: "CycleCountConsolidatedItemVarianceComponent",

    components: {
        ComponentHeader
    },

    mounted(){
        this.getWarehouses()
        // this.getItems()
        this.$store.dispatch('getItems')
        this.$store.dispatch('getItemTypes')
        this.$store.dispatch('getItemCategories')
        // this.getcycleCountConsolidatedItemVariance();
    },
    computed:{
        ...mapGetters([
            'GET_ITEMS',
            'GET_ALL_WAREHOUSES',
            'GET_ITEM_TYPES',
            'GET_ITEM_CATEGORIES',
        ])
    },
    data(){
        return{
            loaded: true,
            date_from_dialog: false,
            date_from: this.$dayjs().startOf('day').format('YYYY-MM-DD'),
            date_to_dialog: false,
            date_to: this.$dayjs().endOf('day').format('YYYY-MM-DD'),
            warehouses_list: [],
            warehouse_id: '',

            items_list: [],
            item_ids: [],

            item_type_list: [],
            item_type_ids: [],

            item_category_list: [],
            item_category_ids: [],

            datatable:{
                headers: [],
                data: [],
                pagination:{
                    options:{},
                    total : 0,
                    perPageDefault: 1000
                }
            },
            loading:{
                warehouses_selection    : true,
                item_selection          : true,
                item_type_selection     : true,
                item_category_selection : true,
                initialLoad             : true,
            }
        }
    },

    methods:{
        onSelectItems(item){
            this.item_ids = item
        },
        onSelectTypes(item){
            this.item_type_ids = item
        },
        onSelectCategories(item){
            this.item_category_ids = item
        },

        getItems(){
                this.items_list = this.GET_ITEMS

        },
        getWarehouses(no_virtual = false){
            let payload = {
                    no_virtual: no_virtual
            }
                this.$store.dispatch('getAllWarehouses',payload).then((response)=>{
                    this.warehouses_list = this.GET_ALL_WAREHOUSES
            });
        },

        getcycleCountConsolidatedItemVariance(page = 1, ){
            let that = this;

            that.datatable.pagination.options.page = page;

            if (!that.loaded) {
                return false;
            }

            that.loaded = false;

            that.datatable.headers = [];
            let payload = {
                page: page,
                itemsPerPage: that.datatable.pagination.options.itemsPerPage
                    || that.datatable.pagination.perPageDefault,
                warehouse_id: that.warehouse_id,
                date_from: that.date_from,
                date_to: that.date_to,
                item_id: that.item_ids,
                type_id: that.item_type_ids,
                category_id: that.item_category_ids,
                url: "getCycleCountConsolidatedItemVariance",
            }

            that.$store.dispatch('reports',payload).then(response => {
                that.datatable.headers.push({ text: 'MODEL', value: 'item_model', align: 'start', sortable: false, width: "250px"});
                that.datatable.headers.push({ text: 'NAME', value: 'item_name', align: 'start', sortable: false, width: "300px"});
                response.data.extender.forEach(extender => {
                    that.datatable.headers.push({
                        text: extender.text,
                        value: parseInt(extender.value).toString(),
                        align: 'right',
                        sortable: false,
                        width: "130px"
                    });
                });

                that.datatable.data = response.data.cycle_counts.data;
                that.datatable.pagination.total = response.data.cycle_counts.total;
                that.loaded = true;
            });
        },

        resetFields(){
            this.warehouse_id = '';
            this.item_ids  = []
            this.item_type_ids = []
            this.item_category_ids = []
        },

        thousandSeprator(amount) {
            if (amount !== '' || amount !== undefined || amount !== 0 || amount !== '0' || amount !== null) {
                return amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            } else {
                return amount;
            }
        },
    },

    watch:{
        'datatable.pagination.options': {
            handler() {
                if(!this.loading.initialLoad){
                    this.getcycleCountConsolidatedItemVariance(this.datatable.pagination.options.page)
                }else{
                    this.loading.initialLoad = false
                }
            },
            deep: true,
        },
        'GET_ITEM_TYPES':{
            handler(val){
                this.item_type_list = val.data
                this.loading.item_type_selection = false
            }
        },
        'GET_ITEM_CATEGORIES':{
            handler(val){
                this.item_category_list = val.data
                this.loading.item_category_selection = false
            }
        },
        'GET_ALL_WAREHOUSES':{
            handler(val){
                this.warehouses_list = val
                this.loading.warehouses_selection = false
            }
        },
        'GET_ITEMS':{
            handler(val){

                this.loading.item_selection = false
            }
        },
    }
}
</script>

<style scoped>

</style>
