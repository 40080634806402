<template>
    <v-container fluid>
        <v-card flat>
            <v-card-title>
                <v-row class="mx-auto d-flex justify-space-between">
                    <v-spacer></v-spacer>
                    <v-col sm="12" md="12" lg="2" class="mr-2">
                        <v-text-field
                            clearable
                            single-line
                            v-model="search"
                            hide-details
                            label="Search Parts Claim# / ST#"
                            @keydown.enter="searchDisposal()"
                            @click:clear="search='';searchDisposal()"
                        ></v-text-field>

                    </v-col>
                    <v-col sm="12" md="12" lg="2" class="mr-3">
                        <v-text-field
                            clearable
                            single-line
                            hide-details
                            v-model="searchItem"
                            append-outer-icon="mdi-magnify"
                            label="Search Item Model / Name"
                            @keydown.enter="searchDisposal()"
                            @click:append-outer="searchDisposal()"
                            @click:clear="searchItem='';searchDisposal()"
                        ></v-text-field>

                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <!-- :search="search" -->
                <v-data-table
                    dense
                    class="mt-4"
                    :items="disposalTable.items"
                    :headers="disposalTable.header"
                    :options.sync="options"
                    :loading="loading.dataTable"
                    :server-items-length="disposalTable.totalItems"
                    disable-sort
                >
                    <template v-slot:[`header.source_wh`]="{ header }">
                        <v-tooltip top color="#F69F1A">
                            <template v-slot:activator="{ on, attrs }">
                                <span
                                    v-bind="attrs"
                                    v-on="on"
                                    style="cursor:pointer"
                                >
                                   SW
                                </span>
                            </template>
                            <span>SOURCE WAREHOUSE</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`header.disposal_wh_code`]="{ header }">
                        <v-tooltip top color="#F69F1A">
                            <template v-slot:activator="{ on, attrs }">
                                <span
                                    v-bind="attrs"
                                    v-on="on"
                                    style="cursor:pointer"
                                >
                                    DW
                                </span>
                            </template>
                            <span>DISPOSAL WAREHOUSE</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`header.parts_transfer.series`]="{ header }">
                        <v-tooltip top color="#F69F1A">
                            <template v-slot:activator="{ on, attrs }">
                                <span
                                    v-bind="attrs"
                                    v-on="on"
                                    style="cursor:pointer"
                                >
                                    PT#
                                </span>
                            </template>
                            <span>PARTS TRANSFER NUMBER</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                        <div :class="statuses[item.status]">
                            {{ item.status }}
                        </div>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                        <v-btn v-if="userAccess.includes('view')" icon text @click="viewItemDisposal(item); dialogState=true" color="orange" small>
                            <v-icon  class="btn-action" small >mdi-eye</v-icon>
                        </v-btn>
                        <v-btn v-if="['saved'].includes(item.status) && userAccess.includes('edit')" icon text @click="editItemDisposal(item),dialogState=true" color="blue" small>
                            <v-icon class="btn-action" small>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn text icon color="red" v-if="['saved'].includes(item.status) && userAccess.includes('delete')" small>
                            <v-icon class="btn-action" small @click="cancelOrDelete(item, 'delete')">mdi-delete</v-icon>
                        </v-btn>
                        <v-btn text icon color="red" v-if="['saved'].includes(item.status) && userAccess.includes('cancel')" small>
                            <v-icon class="btn-action" small @click="cancelOrDelete(item, 'cancel')">mdi-cancel</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-row>
            <DisposalViewComponent :dialogState="dialogState" @successTransaction="successTransaction" @successUpload="successUpload" @closeDialog="closeDisposalDialog"/>
        </v-row>
    </v-container>
</template>

<script>
import DisposalViewComponent from '@/views/main/layouts/dialogs/Service/Disposal/DisposalViewComponent.vue';
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
export default {
    components: {
        DisposalViewComponent,
    },
    props: {
        tabName: {
            type: String,
            default: () => '',
        },
    },
    data() {
        return {
            search: '',
            loading: {
                dataTable:false,
            },
            disposalTable: {
                header:[
                    {text:'Parts Claims#',value:'disposal_number'},
                    {text:'Stock Transfer#',value:'transfer_num'},
                    // {text:'Source', value:'source'},
                    // {text:'Source Warehouse',value:'source_wh'},
                    // {text: 'Disposal Warehouse', value:'disposal_wh_code'},
                    {text:'Created By',value:'created_by'},
                    {text:'Created Date',value:'created_at'},
                    // {text:'Approved By',value:'approved_by'},
                    // {text:'Approved Date',value:'approved_date'},
                    {text:'Remarks', value:'disposal_remarks', width: '20%'},
                    {text:'Status',value:'status'},
                    // {text:'PT#',value:'parts_transfer.series'},
                    {text:'Action', value:'action', align:'center'}
                ],
                items:[],
                totalItems: 0,
            },
            options:{},
            page: 1,
            itemPerPage: 10,
            statuses:{
                saved: 'badge badge-saved',
                // pending: 'badge badge-pending',
                approved: 'badge badge-approved',
                canceled: 'badge badge-cancelled',
                disposed: 'badge badge-full',
                recovered: 'badge badge-recovery',
            },
            userAccess:[],
            searchItem:'',
            dialogState:false,
        }
    },
    computed: {
        ...mapGetters([
            'GET_DISPOSALS_SP',
            'GET_DISPOSAL_DIALOG',
            'GET_DISPOSAL_STATUS',
            'GET_DISPOSAL_REFRESH',
            'USER_ACCESS',
        ])
    },
    watch: {
        'options': {
            handler(val) {
                this.getDisposals(this.GET_DISPOSAL_STATUS);
            }
        },
        'GET_DISPOSALS_SP': {
            handler(val){
                this.disposalTable.items = val.disposals.data;
                this.disposalTable.totalItems = val.total_count;
            }
        },
        'GET_DISPOSAL_STATUS': {
            handler(val){
                this.options.page = 1;
                this.getDisposals(val);
            }
        },
        'GET_DISPOSAL_REFRESH': {
            handler(val) {
                if(val == true) {
                    this.getDisposals();
                }
            }
        },
        'USER_ACCESS': {
            handler(val) {
                if (val != 'fail'){
                    this.userAccess = val.map( e => e.actions_code)
                }
            }
        },
    },
    methods: {
        getDisposals(status='') {
            this.disposalTable.items = [];
            this.loading.dataTable = true;
            this.$store.commit('DISPOSAL_STATUS', status);
            let payload = {
                page: this.options.page,
                itemPerPage: this.options.itemsPerPage,
                search: !!this.search ? this.search.trim() : '',
                searchItem: !!this.searchItem ? this.searchItem.trim() : '',
                status: status
            }
            this.$store.dispatch('getDisposalSp', payload).then(response => {
                this.disposalTable.items = this.GET_DISPOSALS_SP.disposals.data;
                this.disposalTable.totalItems = this.GET_DISPOSALS_SP.total_count;
                this.loading.dataTable = false;
            }).catch(e => {
                console.log(e);
                this.loading.dataTable = false;
            })
        },
        viewItemDisposal(item) {
            this.$store.commit('DISPOSAL_DIALOG', true);
            this.$store.commit('DISPOSAL_DETAILS', item);
            this.$store.commit('DISPOSAL_ACTION', 'View');
            this.getDisposalItems(item.id, item.stock_transfer_id);
        },
        closeDisposalDialog() {
            this.dialogState = false;
        },
        getDisposalItems(disposalId,stockTransferId){
            this.$store.commit('REQUEST_ON_FLIGHT', true);
            let payload = {
                disposal_id: disposalId,
                stock_transfer_id: stockTransferId,
                url:'disposals-sp-items'
            }
            this.$store.dispatch('disposalGet', payload).then( res => {
                this.$store.commit('DISPOSAL_ITEMS', res.data.items);
                this.$store.commit('UPLOADED_FILE_EXISTS', res.data.files_exists);
                this.$store.commit('REQUEST_ON_FLIGHT', false);
            }).catch(e => {
                console.log(e);
                this.$store.commit('REQUEST_ON_FLIGHT', false);
            })
        },
        searchDisposal(){
            this.options.page = 1;
            this.getDisposals();
        },
        editItemDisposal(item) {
            this.$store.commit('DISPOSAL_DIALOG', true);
            this.$store.commit('DISPOSAL_DETAILS', item);
            this.$store.commit('DISPOSAL_ACTION', 'Edit');
            this.getDisposalItems(item.id,item.stock_transfer_id);
        },
        cancelOrDelete(item, action) {
            swal.fire({
                title: `Are you sure to ${action} this disposal?`,
                text: '-Confirm to proceed',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then( ans => {
                if (ans.isConfirmed) {
                    let payload = {
                        url: `${action}/${item.id}`,
                    }

                    this.$store.dispatch('disposalPost', payload).then( res => {
                        let data = res.data;
                        swal.fire(Object.assign(data, {timer:1500}));
                        this.getDisposals();
                    }).catch(e => {
                        console.log(e);
                    })
                }
            });
        },
        successTransaction() {
            this.getDisposals();
        },
        successUpload(data) {
            this.getDisposalItems(data.disposalId, data.stock_transfer_id);
        }
    },
    mounted() {
        this.getDisposals();
        this.$store.dispatch('checkAccessUser',this.$router.currentRoute.params.id);
    }
}
</script>

<style>

</style>