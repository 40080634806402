<template>
  <v-container fluid >
      <h3 class="page-title">Asset Management</h3>
      <v-container class="ma-0">
          <v-app id="item-tabs">
            <v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" background-color="#424242" dark show-arrows>
                <v-tabs-slider color="cyan"></v-tabs-slider>
                <v-tab ripple v-for="(item, i) in items" :key="item" @click="tabs(i)" :class="i == 6 ? 'ml-auto' : ''">
                {{ item }}
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item v-for="(table, index) in items" :key="index">
                    <keep-alive>
                      <component :is="assetmanagement_component[index].components" :assetmanagement_component="assetmanagement_component" :isActive_tab="assetmanagement_component[index].status_text"></component>
                    </keep-alive>
                </v-tab-item>
            </v-tabs-items>
          </v-app>
      </v-container>
      <DialogComponent :cp="components" :scrollable="dialog_scrollable" :width="width(dialog_width)" :retainfocus="false"></DialogComponent>
  </v-container>
</template>

<script>
import DialogComponent from '@/views/main/layouts/dialogs/Dialog.vue';
import AdminAssetmanagementComponent from '@/views/main/modules/Admin/AssetManagement/AssetManagementComponent.vue';
import AdminAssetmanagementDialogComponent from '@/views/main/layouts/dialogs/Admin/AssetManagementViewComponent.vue';
import AdminAssetmanagementClassificaionDialogComponent from '@/views/main/layouts/dialogs/Admin/AssetManagementOtherViewComponent.vue';
import AssetsAccountabilitysheetComponent from '@/views/main/modules/Admin/Accountability/AssetsAccountabilitysheetComponent.vue';
import AssetsAccountabilitysheetDialogComponent from '@/views/main/layouts/dialogs/Admin/AssetsAccountabilitysheetViewComponent.vue';
import AssetConditionComponent from '@/views/main/modules/Admin/AssetCondition/AssetConditionComponent.vue';
import AssetConditionViewComponent from '@/views/main/layouts/dialogs/Admin/AssetConditionViewComponent.vue';
import AdminAssetTurnoverComponent from '@/views/main/modules/Admin/AssetTurnover/AdminAssetTurnoverComponent.vue';
import AdminAssetTurnoverViewComponent from '@/views/main/layouts/dialogs/Admin/AdminAssetTurnoverViewComponent.vue';
import AccountabilityLowValueAssetsComponent from '@/views/main/modules/Admin/AccountabilityLowValueAssets/AccountabilityLowValueAssetsComponent.vue';
import AccountabilityLowValueAssetsViewComponent from '@/views/main/layouts/dialogs/Admin/AccountabilityLowValueAssetsViewComponent.vue';
import { mapGetters } from 'vuex';
import PusherFunctionComponent from '@/views/main/Shared/PusherFunctionComponent.vue';
export default {
  mixins: [PusherFunctionComponent],
  components:{
      DialogComponent,
      AdminAssetmanagementDialogComponent,
      AdminAssetmanagementComponent,
      AdminAssetmanagementClassificaionDialogComponent,
      AssetsAccountabilitysheetDialogComponent,
      AssetsAccountabilitysheetComponent,
      AssetConditionComponent,
      AssetConditionViewComponent,
      AdminAssetTurnoverComponent,
      AdminAssetTurnoverViewComponent,
      AccountabilityLowValueAssetsComponent,
      AccountabilityLowValueAssetsViewComponent,
  },
  data() {
      return {
        form: {
            code: '',
            name: '',
            sub_class:[],
            sub_class_trash:[],
          },
          components:'',
          items: ["ASSETS","CLASSIFICATION","LOCATION","BRAND","STATUS","CATEGORY", "AS SUMMARY", "LVAS UNACCOUNTED", "FA UNACCOUNTED", "TURN OVER", "CONDITION"],
          assetmanagement_component: [
                  // {
                  // components: AdminAssetmanagementComponent,
                  // status_text: "assetslist-basket",
                  // dialog: AdminAssetmanagementDialogComponent,
                  // dialog_title:'New Assets',
                  // dialog_width: "65%",
                  // scrollable:true,
                  // dispatch:{
                  //           get:'getAllAdminAssetsList',
                  //           add:'AdminCreateAssets',
                  //           update:'AdminUpdateAssets',
                  //           delete:'AdminDeleteAssets'
                  //         },
                  // },
                  {
                  components: AdminAssetmanagementComponent,
                  status_text: "assetslist",
                  dialog: AdminAssetmanagementDialogComponent,
                  dialog_title:'New Assets',
                  dialog_width: "65%",
                  scrollable:true,
                  dispatch:{
                            get:'getAllAdminAssetsList',
                            add:'AdminCreateAssets',
                            update:'AdminUpdateAssets',
                            delete:'AdminDeleteAssets'
                          },
                  },
                  {
                  components: AdminAssetmanagementComponent,
                  status_text: "classification",
                  dialog: AdminAssetmanagementClassificaionDialogComponent,
                  dialog_title:'Admin Classification',
                  dialog_width: "50%",
                  scrollable:false,
                  dispatch:{
                            get:'GetAllAdminClassification',
                            add:'AddAdminClassification',
                            update:'UpdateAdminClassification',
                            delete:'DeleteAdminClassification'
                          },
                  getters:'GET_ADMIN_CLASSIFICATION_TABLE'
                  },
                  {
                  components: AdminAssetmanagementComponent,
                  status_text: "location",
                  dialog: AdminAssetmanagementClassificaionDialogComponent,
                  dialog_title:'Location',
                  dialog_width: "30%",
                  scrollable:false,
                  dispatch:{
                            get:'GetAllAdminLocation',
                            add:'AddAdminLocation',
                            update:'UpdateAdminLocation',
                            delete:'DeleteAdminLocation'
                      },
                  getters:'GET_ADMIN_LOCATION_TABLE'
                  },
                  {
                  components: AdminAssetmanagementComponent,
                  status_text: "brand",
                  dialog: AdminAssetmanagementClassificaionDialogComponent,
                  dialog_title:'Brand',
                  dialog_width: "30%",
                  scrollable:false,
                  dispatch:{
                            get:'GetAllAdminBrand',
                            add:'AddAdminBrand',
                            update:'UpdateAdminBrand',
                            delete:'DeleteAdminBrand'
                      },
                  getters:'GET_ADMIN_BRAND_TABLE'

                  },
                  {
                  components: AdminAssetmanagementComponent,
                  status_text: "status",
                  dialog: AdminAssetmanagementClassificaionDialogComponent,
                  dialog_title:'Status',
                  dialog_width: "30%",
                  scrollable:false,
                  dispatch:{
                            get:'GetAllStatus',
                            add:'AddStatus',
                            update:'UpdateStatus',
                            delete:'DeleteStatus'
                        },
                  getters:'GET_STATUS_TABLE'

                  },
                  {
                  components: AdminAssetmanagementComponent,
                  status_text: "category",
                  dialog: AdminAssetmanagementClassificaionDialogComponent,
                  dialog_title:'Category',
                  dialog_width: "30%",
                  scrollable:false,
                  dispatch:{
                            get:'GetAllAdminCategories',
                            add:'AddAdminCategories',
                            update:'UpdateAdminCategories',
                            delete:'DeleteAdminCategories'
                        },
                  getters:'GET_CATEGORY_TABLE'

                  },
                //   {
                //   components: AdminAssetmanagementComponent,
                //   status_text: "accountability-high",
                //   dialog: '',
                //   dialog_title:'Accountability',
                //   dialog_width: "30%",
                //   scrollable:false,
                //   dispatch:{
                //             get:'getAllAdminAssets',
                //         },
                //   getters:'GET_ASSETS_ACCOUNTABILITY'

                //   },
                //   {
                //   components: AdminAssetmanagementComponent,
                //   status_text: "accountability-low",
                //   dialog: '',
                //   dialog_title:'Accountability',
                //   dialog_width: "30%",
                //   scrollable:false,
                //   dispatch:{
                //             get:'getAllAdminAssets',
                //         },
                //   getters:'GET_ASSETS_ACCOUNTABILITY'

                //   },
                  {
                    components: AssetsAccountabilitysheetComponent,
                    status_text: "accountability",
                    dialog: AssetsAccountabilitysheetDialogComponent,
                    dialog_title:'Accountability',
                    dialog_width: "70%",
                    scrollable:true,
                    dispatch:{
                        get:'getAllAccountability',
                        add:'AdminCreateAccountability',
                        update:'AdminUpdateAccountability',
                        delete:'AdminDeleteAccountability'
                    },
                    dataTable:[
                        { text: "Accountability No.", align: "left", value: "accountability_num_display" },
                        { text: "Department", align: "left", value: "department_name" },
                        { text: "Full Name", align: "left", value: "full_name" },
                        { text: "Prepared By", align: "left", value: "prepared_by" },
                        { text: "Updated By", align: "left", value: "updated_by" },
                        { text: "Status", align: "center", value: "printed"},
                        { text: "Filed", align: "left", value: "filed" },
                        { text: "Filed Date", align: "left", value: "filed_date" },
                        { text: "Action", align: "center", value: "action",sortable:false},
                    ]
                  },
                  {
                    components: AdminAssetmanagementComponent,
                    status_text: "low-value-assets",
                    dialog: AccountabilityLowValueAssetsViewComponent,
                    dialog_title:'Accountability',
                    dialog_width: "70%",
                    scrollable:true,
                    dispatch:{
                      get:'getAllAdminAssetsList',
                      add:'AdminCreateAccountability',
                      update:'AdminUpdateAccountability',
                      delete:'AdminDeleteAccountability'
                    },
                    // dataTable:[
                    //     { text: 'Picture', align: 'left',value:'image_path' },
                    //     { text: 'Item Name', align: 'center',value:'item_name' },
                    //     { text: "AS NO.", align: "left", value: "asset_num" },
                    //     { text: "Description", align: "left", value: "description" },
                    //     // { text: "Brand", align: "left", value: "brand_name" },
                    //     { text: "Classification", align: "center", value: "classification" },
                    //     { text: "Sub-Class", align: "center", value: "sub_classification" },
                    //     { text: "Asset Tagging", align: "center", value: "asset_code" },
                    //     { text: "Inventory", align: "center", value: "total_stocks" },
                    //     { text: "Accounted", align: "center", value: "accounted" },
                    //     { text: "Unaccounted", align: "center", value: "unaccounted" },
                    //     { text: "P.O #", align: "center", value: "po_num" },
                    //     { text: "Price", align: "center", value: "price" },
                    //     // { text: "Location", align: "center", value: "location_name" },
                    //     // { text: "Department / Site", align: "left", value: "department_name" },
                    //     // { text: "Employee Name", align: "left", value: "full_name" },
                    //     { text: "Supplier", align: "left", value: "vendor_name" },
                    //     // { text: "Amount", align: "left", value: "cost" },
                    //     { text: "Status", align: "left", value: "status_name" },
                    //     // { text: "Action", align: "center", value: "action",sortable:false},
                    // ]
                  },
                  {
                    components: AdminAssetmanagementComponent,
                    status_text: "fixed-assets",
                    dialog: AssetsAccountabilitysheetDialogComponent,
                    dialog_title:'Accountability',
                    dialog_width: "70%",
                    scrollable:true,
                    dispatch:{
                      get:'getAllAdminAssetsList',
                      add:'AdminCreateAccountability',
                      update:'AdminUpdateAccountability',
                      delete:'AdminDeleteAccountability'
                    },
                    // dataTable:[
                    //     { text: 'Picture', align: 'left',value:'image_path' },
                    //     { text: 'Item Name', align: 'center',value:'item_name' },
                    //     { text: "AS NO.", align: "left", value: "asset_num" },
                    //     { text: "Description", align: "left", value: "description" },
                    //     // { text: "Brand", align: "left", value: "brand_name" },
                    //     { text: "Classification", align: "center", value: "classification" },
                    //     { text: "Sub-Class", align: "center", value: "sub_classification" },
                    //     { text: "Asset Tagging", align: "center", value: "asset_code" },
                    //     { text: "Inventory", align: "center", value: "total_stocks" },
                    //     { text: "Accounted", align: "center", value: "accounted" },
                    //     { text: "Unaccounted", align: "center", value: "unaccounted" },
                    //     { text: "P.O #", align: "center", value: "po_num" },
                    //     { text: "Price", align: "center", value: "price" },
                    //     // { text: "Location", align: "center", value: "location_name" },
                    //     // { text: "Department / Site", align: "left", value: "department_name" },
                    //     // { text: "Employee Name", align: "left", value: "full_name" },
                    //     { text: "Supplier", align: "left", value: "vendor_name" },
                    //     // { text: "Amount", align: "left", value: "cost" },
                    //     { text: "Status", align: "left", value: "status_name" },
                    //     // { text: "Action", align: "center", value: "action",sortable:false},
                    // ]
                  },
                  {
                      components: AdminAssetTurnoverComponent,
                      // status_text: "unaccounted",
                      dialog: AdminAssetTurnoverViewComponent,
                      dialog_title:'Asset Condition',
                      dialog_width: "1900px",
                      scrollable:true,
                      dispatch:{
                          get:'adminAssetTurnoverGet',
                          add:'adminAssetTurnoverPost',
                          update:'adminAssetTurnoverPut',
                          delete:'adminAssetTurnoverDelete',
                      },
                      dataTable:[],
                  },
                  {
                      components: AssetConditionComponent,
                      // status_text: "unaccounted",
                      dialog: AssetConditionViewComponent,
                      dialog_title:'Asset Condition',
                      dialog_width: "500px",
                      scrollable:true,
                      dispatch:{
                          get:'adminAssetConditionGet',
                          add:'adminAssetConditionPost',
                          update:'adminAssetConditionPut',
                          delete:'adminAssetConditionDelete',
                      },
                      dataTable:[],
                  },
          ],
          dialog_width: "",
          loaded: false,
          options: {},
          itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
          itemsPerPage: 10,
          tab: null,
          dialog_scrollable:true,
      };
  },
  computed:{
      ...mapGetters(['GET_NEW_ADMIN_OTHER_TABLE','GET_NEW_ADMIN_ASSETS_TABLE','GET_ADMIN_PO_SELECTION'])
  },
  mounted(){
    this.callPusher();
      this.ServerPusher();
        this.dropDowns()
        this.tabs(0);
  },
  methods:{
  ServerPusher(){
    window.Echo.channel('ingco-channel').listen('RealtimeAdmin',(e)=>{
      this.$store.commit('ADMIN_TABLE_COUNT',e.count);
      this.$store.commit('ADMIN_CLASSIFICATION_COUNT',e.classification_count);
    })
    window.Echo.channel('ingco-channel').listen('RealtimePONumber',(e)=>{
      if(e.vendor_id != ''){
        this.$store.dispatch("getPONumberAssetsSelectionn",{id:e.vendor_id});
      }
    })
  },
  dropDowns(){
        // this.$store.dispatch('getDepartment')
        // this.$store.dispatch("getAdminClassificationSelection");
        // this.$store.dispatch("getAdminLocationSelection");
        // this.$store.dispatch("getAdminBrandSelection");
        // this.$store.dispatch("getAdminCategories");
        // this.$store.dispatch("getStatus");
        // this.$store.dispatch("getEmployeeDetails");
        // this.$store.dispatch('getAdminItemUomSelection');
        // this.$store.dispatch('getAdminSupplierSelection');
        // this.$store.dispatch('getAdminWarehouseCodeDropdown');
        // this.$store.dispatch('getAdminSupplierSelection');
        // this.$store.dispatch('getCOASelection');
        // this.$store.dispatch("getAdminAssetConditionSelection");
  },
  tabs(key) {
        this.components = this.assetmanagement_component[key].dialog;
        this.dialog_width = this.assetmanagement_component[key].dialog_width;
        this.dialog_scrollable = this.assetmanagement_component[key].scrollable;

        this.$store.commit('ADMIN_DIALOG_TITLE',this.assetmanagement_component[key].dialog_title)
        this.$store.commit('ADMIN_TAB_IS_ACTIVE',this.assetmanagement_component[key].status_text)
        this.$store.commit('ADMIN_DISPATCH',this.assetmanagement_component[key].dispatch)
        this.$store.commit('ADMIN_GETTERS',this.assetmanagement_component[key].getters)
        this.$store.commit('ADMIN_ITEMS_COLLECTIONS',[]);
        this.$store.commit('ADMIN_DATA_TABLE_HEADERS',this.assetmanagement_component[key].dataTable)
        this.$store.commit('ADMIN_DISPATCH',this.assetmanagement_component[key].dispatch)
},
  width(width) {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return "100%"
        case 'sm': return "100%"
        case 'md': return width
        case 'lg': return width
        case 'xl': return width
      }
    },
  },
  watch:{
    GET_NEW_ADMIN_OTHER_TABLE:{
      handler(val){
        if(val)
        {
          this.dropDowns()
        }

      }
    },
    GET_NEW_ADMIN_ASSETS_TABLE:{
      handler(val){
       if(val){
        this.ServerPusher();

       }

      },
      deep:true
    }
  }

};
</script>
<style>
#group-tab {
  padding: 0 !important;
}
</style>
