<template>
    <v-dialog v-model="importItemModal" persistent max-width="100%" class="fixed-size-dialog" allow-scrollable="false" :retain-focus="false">
        <v-card class="fixed-size-card no-overflow-x">
            <v-row class="m-0">
                <v-col>
                    <v-card-title>
                        <span class="headline">Import</span>
                    </v-card-title>
                </v-col>
                <v-col cols="pull-right-10 p-2">
                    <v-btn text icon color="gray" class="float-right" @click="closeImportItemModal()">
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-tabs v-model="tab" class="mb-5" grow color="cyan" slider-color="cyan" background-color="#424242" dark show-arrows>
                <v-tab v-if="userAccess.create">New</v-tab>
                <v-tab v-if="userAccess.edit">Update</v-tab>
                <v-tab v-if="userAccess.create && userAccess.edit">Pictures</v-tab>
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-card>
                            <v-card-title>
                                <v-container fluid>
                                    <v-app id="my_vue_app">
                                        <v-row>
                                            <v-col cols="12" justify="center" class="pt-2">
                                                <v-row justify="center" class="pt-5 pb-1">
                                                    <v-card width="460" class="ml-1">
                                                        <v-file-input
                                                            v-model="selectedNewFile"
                                                            form-control
                                                            outlined
                                                            label="Select .xlsx file"
                                                            dense
                                                            accept=".xlsx"
                                                            @change="handleNewFileChange"
                                                        ></v-file-input>
                                                    </v-card>
                                                </v-row>
                                                <v-row justify="center" class="pt-3 pb-1">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon v-show="!loading_new" size="32" color="#f69f1a" v-on="on" @click="downloadNewTemplate()" style="cursor: pointer;" :disabled="loading_new">mdi-download</v-icon>
                                                        </template>
                                                        <span>New Item Template</span>
                                                    </v-tooltip>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-app>
                                </v-container>
                            </v-card-title>
                            <v-card-text style="height:500px; overflow-y:auto;">
                                    <v-divider/>
                                        <v-container v-if="showNewDataTable" class="text-center">
                                            <v-row class="table-container">
                                                <v-data-table
                                                    :headers="headers"
                                                    :items="items"
                                                    :options.sync="options"
                                                    :server-items-length="totalItems"
                                                    :items-per-page="100"
                                                    :footer-props="{
                                                        itemsPerPageOptions: itemsPerPageOptions,
                                                    }"
                                                    fixed-header
                                                    dense
                                                >
                                                </v-data-table>
                                            </v-row>
                                        </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-divider/>
                                <div class="text-center">
                                        <v-btn @click="importNewExcel" :disabled="loadingUploadItems">
                                            <template v-if="loadingUploadItems">
                                                <v-progress-circular indeterminate color="primary"></v-progress-circular>
                                            </template>
                                            <template v-else>
                                                Upload Items
                                            </template>
                                        </v-btn>
                                </div>
                            </v-card-actions>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card-title>
                            <v-col cols="12" justify="center" class="pt-2">
                                <v-row justify="center" class="pt-5 pb-1">
                                    <v-card width="460" class="ml-1">
                                        <v-file-input
                                            ref="fileInput"
                                            v-model="selectedUpdateFile"
                                            form-control
                                            outlined
                                            label="Select .xlsx file"
                                            dense
                                            accept=".xlsx"
                                            @change="handleUpdateFileChange()"
                                        ></v-file-input>
                                    </v-card>
                                </v-row>
                                <v-row justify="center" class="pt-3 pb-1">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon size="32" color="#f69f1a" v-on="on" @click="openUpdateTemplateDialog()" style="cursor: pointer;">mdi-download</v-icon>
                                        </template>
                                        <span>Update Item Template</span>
                                    </v-tooltip>
                                </v-row>
                            </v-col>
                        </v-card-title>
                        <v-card-text style="height:500px; overflow-y:auto;">
                            <v-divider/>
                                    <v-container v-if="showUpdateDataTable" class="text-center" fluid>
                                        <v-row class="table-container">
                                            <v-layout child-flex>
                                                <v-data-table
                                                :headers="generateHeaders()"
                                                :items="itemsUpdate"
                                                :page.sync="currentPage2"
                                                :items-per-page="itemsPerPage2"
                                                fixed-header
                                                dense
                                                >
                                                </v-data-table>
                                            </v-layout>
                                        </v-row>
                                    </v-container>
                        </v-card-text>
                                <v-card-actions>
                                    <v-divider/>
                                    <div class="text-center">
                                        <v-btn @click="importUpdateExcel" :disabled="loadingUpdateItems">
                                            <template v-if="loadingUpdateItems">
                                                <v-progress-circular indeterminate color="primary"></v-progress-circular>
                                            </template>
                                            <template v-else>
                                                Update Items
                                            </template>
                                        </v-btn>
                                    </div>
                                </v-card-actions>
                                <v-dialog v-model="updateTemplateDialog" max-width="800px" max-height="600px" persistent>
                                    <v-card>
                                        <v-card-title class="headline">
                                            <v-row align="center">
                                                <v-col cols="auto">
                                                    Select Fields Included on Update Template
                                                </v-col>
                                                <v-col cols="auto" class="ml-auto">
                                                    <v-btn text icon color="gray" @click="closeUpdateTemplateDialog()">
                                                        <v-icon>mdi-close-circle</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-combobox
                                                v-model="selectedFields"
                                                :items="itemUpdate"
                                                chips
                                                clearable
                                                label="Select fields"
                                                multiple
                                                solo
                                                @keydown.delete="removeLastChip"
                                            >
                                                <template v-slot:selection="{ attrs, item, select, selected }">
                                                <v-chip
                                                    v-bind="attrs"
                                                    :input-value="selected"
                                                    close
                                                    @click="select"
                                                    @click:close="removeChip(item)"
                                                >
                                                    <strong>{{ item.text }}</strong>&nbsp;
                                                    <span>({{ item.value }})</span>
                                                </v-chip>
                                                </template>
                                            </v-combobox>
                                        </v-card-text>
                                        <v-card-actions class="justify-center">
                                            <template>
                                                <div>
                                                    <v-btn v-if="!loading_update" @click="downloadUpdateTemplate()" :disabled="loading_update">Download Update Template</v-btn>
                                                </div>
                                            </template>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                    </v-tab-item>
                    <v-tab-item>
                        <v-col cols="12" justify="center" class="pt-2">
                            <v-row justify="center" class="pt-5 pb-1">
                                <v-card width="460" class="ml-1">
                                    <v-file-input
                                        v-model="imageUpload"
                                        accept=".xlsx"
                                        label="Select .xlsx file"
                                        prepend-icon="mdi-camera"
                                        show-size
                                        form-control
                                        outlined
                                        dense
                                        multiple
                                    ></v-file-input>
                                </v-card>
                                <!-- <v-card width="460" class="ml-1">
                                    <v-file-input
                                        v-model="imageUpload"
                                        accept="image/jpeg, image/png"
                                        label="Select images to upload"
                                        prepend-icon="mdi-camera"
                                        show-size
                                        form-control
                                        outlined
                                        dense
                                        multiple
                                        :rules="fileInputRules"
                                    ></v-file-input>
                                </v-card> -->
                            </v-row>
                            <v-row justify="center" class="pt-3 pb-3">
                                <!-- <v-btn @click="uploadImages()">Import Pictures</v-btn> -->
                                <v-btn @click="uploadImagesExcel()" :loading="loading.imageUpload">Import Pictures
                                    <template v-slot:loader>
                                        <span class="custom-loader">
                                        <v-icon light color="white">mdi-cached</v-icon>
                                        </span>
                                    </template>
                                </v-btn>
                            </v-row>
                        </v-col>
                    </v-tab-item>
                </v-tabs-items>
            </v-tabs>
        </v-card>
    </v-dialog>
</template>

<script>
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { mapGetters } from "vuex";

export default {
    props: [
        'is_inactive_items',
        'is_sp',
    ],
    data() {
        return {
            selectedNewFile: null,
            selectedUpdateFile: null,
            imageUpload: [],
            items: [],
            itemsUpdate: [],
            totalItems: 0,
            options: {
                page: 1,
                itemsPerPage: 100,
            },
            itemsPerPageOptions: [5, 100],
            currentPage: 1,
            currentPage2: 1,
            itemsPerPage2: 100,
            extractedData: [],
            fileInputRules: [
                value => (value && value.length <= 50) || 'You can only upload up to 50 images',
                value => {
                    for (let i = 0; i < value.length; i++) {
                        const file = value[i];
                        if (file.size > 6 * 1024 * 1024) {
                            return 'Each image can only have a file size of 6MB';
                        }
                    }
                    return true;
                }
            ],
            headers: [
                { text: 'MODEL', align: 'start', value: 'model', sortable: false },
                { text: 'NAME', align: 'start', value: 'itemName', sortable: false },
                { text: 'DESCRIPTION', align: 'start', value: 'description', sortable: false },
                { text: 'UOM', align: 'start', value: 'uom', sortable: false },
                { text: 'DISCOUNT TYPE', align: 'start', value: 'type', sortable: false },
                { text: 'CATEGORY', align: 'start', value: 'category', sortable: false },
                { text: 'PART NO.', align: 'start', value: 'part_no', sortable: false },
                { text: 'SRP', align: 'start', value: 'srp', sortable: false },
                { text: 'SP', align: 'start', value: 'sp_price', sortable: false },
                { text: 'MOQ', align: 'start', value: 'moq', sortable: false },
                { text: 'MB QTY', align: 'start', value: 'master_box_qty', sortable: false },
                { text: 'SB QTY', align: 'start', value: 'small_box_qty', sortable: false },
                { text: 'ITEM SUGGESTIONS', align: 'start', value: 'item_suggest', sortable: false },
                { text: 'Related Accessories', align: 'start', value: 'item_accessory', sortable: false },
                { text: 'NEW MODELS', align: 'start', value: 'item_sub', sortable: false },
                { text: 'BRAND', align: 'start', value: 'brand', sortable: false },
                { text: 'SIZE', align: 'start', value: 'size', sortable: false },
                { text: 'CBM', align: 'start', value: 'cbm', sortable: false },
                { text: 'WEIGHT', align: 'start', value: 'weight', sortable: false },
                { text: 'MBB', align: 'right', value: 'master_box_barcode', sortable: false },
                { text: 'MPOQ', align: 'right', value: 'moq_po', sortable: false },
                { text: 'LOW STOCK QTY', align: 'right', value: 'low_stock_qty', sortable: false },
                { text: 'STOCK ALERT', align: 'right', value: 'stock_alert', sortable: false },
                { text: 'FOC', align: 'right', value: 'foc', sortable: false },
                { text: 'WITH EXPIRATION', align: 'right', value: 'with_expiration', sortable: false },
                { text: 'PHASED OUT', align: 'right', value: 'phased_out', sortable: false },
                { text: 'EXCLUDE', align: 'right', value: 'exclude', sortable: false },
                { text: 'ACTIVE', align: 'right', value: 'active', sortable: false },
                { text: 'REMARKS', value: 'remarks' },
                { text: 'DISCONTINUE ORDER DATE', value: 'discontinue_order_date' },
                { text: 'LIMIT ORDER', value: 'limit_order' },
            ],
            itemUpdate: [
                { text: 'NAME', value: 'Item Name' },
                { text: 'DESCRIPTION', value: 'Description' },
                { text: 'UOM', value: 'UOM' },
                { text: 'DISCOUNT TYPE', value: 'Type' },
                { text: 'CATEGORY', value: 'Category' },
                { text: 'PART NO.', value: 'Part No.' },
                { text: 'SRP', value: 'SRP' },
                { text: 'SP', value: 'SP Price' },
                { text: 'MOQ', value: 'MOQ' },
                { text: 'MB QTY', value: 'Master Box Quantity' },
                { text: 'SB QTY', value: 'Small Box Quantity' },
                { text: 'ALTERNATE MODELS', value: 'Alternate Models' },
                { text: 'RELATED ACCESSORIES', value: 'Related Accessories' },
                { text: 'NEW MODELS', value: 'New Models' },
                { text: 'BRAND', value: 'Brand' },
                { text: 'SIZE', value: 'Size' },
                { text: 'CBM', value: 'CBM' },
                { text: 'WEIGHT', value: 'Weight' },
                { text: 'MBB', value: 'Master Box Barcode' },
                { text: 'MPOQ', value: 'Minimum Purchase Order Quantity' },
                { text: 'LOW STOCK QTY', value: 'Low Stock Quantity' },
                { text: 'STOCK ALERT', value: 'Stock Alert' },
                { text: 'FOC', value: 'FOC' },
                { text: 'WITH EXPIRATION', value: 'With Expiration' },
                { text: 'PHASED OUT', value: 'Phased Out' },
                { text: 'EXCLUDE', value: 'Exclude' },
                { text: 'ACTIVE', value: 'Active' },
                { text: 'REMARKS', value: 'Remarks' },
                { text: 'DISCONTINUE ORDER DATE', value: 'Discontinue Order Date' },
                { text: 'LIMIT ORDER', value: 'Limit Order' },
            ],
            selectedFields: [],
            showNewDataTable: false,
            showUpdateDataTable: false,
            updateTemplateDialog: false,
            tab: 'New',
            loading_new: false,
            loading_update: false,
            userAccess: {
                create: false,
                edit: false,
            },
            loadingUploadItems: false,
            loadingUpdateItems: false,
            loading:{
                imageUpload: false,
            },
            relatedItems: {},
            systemDetailSelection: [],
        }
    },
    mounted() {
        this.$store.dispatch(
            'checkAccessUser',
            this.$router.currentRoute.params.id
        );
    },
    computed: {
        ...mapGetters([
            "GET_STORE_IMPORT_ITEM",
            "GET_STORE_IMPORT_IMAGE",
            "GET_UPDATE_IMPORT_ITEM",
            "USER_ACCESS",
            'GET_SYSTEM_DETAIL_SELECTION',
            'GET_SYSTEM_ITEM',
        ]),
        importItemModal() {
            return this.$store.state.Items.importItemModal;
        },
    },
    components: {},
    methods: {
        removeLastChip() {
            if (this.selectedFields.length > 0) {
            this.selectedFields.pop();
            }
        },
        removeChip(item) {
            const index = this.selectedFields.indexOf(item);
            if (index > -1) {
            this.selectedFields.splice(index, 1);
            }
        },
        generateHeaders() {
            if (this.itemsUpdate.length === 0) {
            return [];
            }

            const headers = Object.keys(this.itemsUpdate[0]);
            return headers.map((header) => ({
            text: header,
            value: header,
            }));
        },
        closeUpdateTemplateDialog() {
            this.selectedFields = [];
            this.updateTemplateDialog = false;
        },
        openUpdateTemplateDialog() {
            this.updateTemplateDialog = true;
        },
        closeImportItemModal() {
            this.selectedNewFile = null;
            this.selectedFields = [];
            this.imageUpload = [];
            this.items = [],
            this.selectedUpdateFile = null;
            this.$store.commit('closeImportItemModal');
        },
        async downloadUpdateTemplate() {
            if (this.selectedFields.length === 0) {
                Swal.fire('', "No Fields Selected", "error");
                return;
            }

            this.loading_update = true;

            const sheetNames = ['Data'];

            if (this.selectedFields.some(field => field.text === 'DISCOUNT TYPE')) {
                sheetNames.push('Discount Type (Guide)');
            }
            if (this.selectedFields.some(field => field.text === 'CATEGORY')) {
                sheetNames.push('Category (Guide)');
            }
            if (this.selectedFields.some(field => field.text === 'UOM')) {
                sheetNames.push('UOM (Guide)');
            }
            if (this.selectedFields.some(field => field.text === 'BRAND')) {
                sheetNames.push('Brand (Guide)');
            }
            if (this.selectedFields.some(field => field.text === 'SIZE')) {
                sheetNames.push('Size (Guide)');
            }
            if (this.selectedFields.some(field => field.text === 'ALTERNATE MODELS')) {
                sheetNames.push('Alternate Models (Guide)');
            }
            if (this.selectedFields.some(field => field.text === 'RELATED ACCESSORIES')) {
                sheetNames.push('Related Accessories (Guide)');
            }
            if (this.selectedFields.some(field => field.text === 'NEW MODELS')) {
                sheetNames.push('New Models (Guide)');
            }

            if (this.selectedFields.some(field => field.text === 'NEW MODELS')) {
                sheetNames.push('Discontinue Order Date (Guide)');
            }

            this.GET_SYSTEM_DETAIL_SELECTION.forEach(system => {
                if (this.selectedFields.some(field => field.text === `RELATED ${system.brand_name.toUpperCase()}`)) {
                    sheetNames.push(`Related ${system.brand_name} (Guide)`);
                }
            });

            try {
                const workbook = new this.$exceljs.Workbook();

                sheetNames.forEach((sheetName) => {
                    const worksheet = workbook.addWorksheet(sheetName);

                    const discontinueOrderDateColumn = worksheet.getColumn('AB');
                    discontinueOrderDateColumn.numFmt = '@';
                    if (sheetName === 'Data') {
                        const headerRow = worksheet.addRow([]);
                        const rowValues = ['Model', ...this.selectedFields.map(field => field.value)];
                        headerRow.values = rowValues;
                        headerRow.font = { bold: true };
                    } else if (sheetName === 'Discount Type (Guide)') {
                        const headerRow = worksheet.addRow(['Discount Types']);
                        headerRow.getCell(1).font = { bold: true };
                        const itemTypes = this.$store.getters.GET_ITEM_TYPES;
                        itemTypes.forEach((itemType) => {
                            worksheet.addRow([itemType.text]);
                        });
                    } else if (sheetName === 'Category (Guide)') {
                        const headerRow = worksheet.addRow(['Categories']);
                        headerRow.getCell(1).font = { bold: true };
                        const itemCategories = this.$store.getters.GET_ITEM_CATEGORIES;
                        itemCategories.forEach((itemCategory) => {
                            worksheet.addRow([itemCategory.text]);
                        });
                    } else if (sheetName === 'UOM (Guide)') {
                        const headerRow = worksheet.addRow(['UOM']);
                        headerRow.getCell(1).font = { bold: true };
                        const itemUOM = this.$store.getters.GET_ITEM_UOM.data;
                        itemUOM.forEach((item) => {
                            worksheet.addRow([item.text]);
                        });
                    } else if (sheetName === 'Brand (Guide)') {
                        const headerRow = worksheet.addRow(['Brands']);
                        headerRow.getCell(1).font = { bold: true };
                        const itemBrands = this.$store.getters.GET_ITEM_BRAND;
                        itemBrands.forEach((itemBrand) => {
                            worksheet.addRow([itemBrand.text]);
                        });
                    } else if (sheetName === 'Size (Guide)') {
                        const headerRow = worksheet.addRow(['Sizes']);
                        headerRow.getCell(1).font = { bold: true };
                        const itemSizes = this.$store.getters.GET_ITEM_SIZE.data;
                        itemSizes.forEach((itemSize) => {
                            worksheet.addRow([itemSize.text]);
                        });
                    } else if (sheetName === 'Alternate Models (Guide)') {
                        const headerRow = worksheet.addRow(['Alternate Models']);
                        headerRow.getCell(1).font = { bold: true };
                        const itemSuggestions = this.$store.getters.GET_ITEMS;
                        itemSuggestions.forEach((itemSuggestion) => {
                            const row = worksheet.addRow([itemSuggestion.text]);
                            row.getCell(1).font = { bold: false };
                        });
                    }
                     else if (sheetName === 'Related Accessories (Guide)') {
                        const headerRow = worksheet.addRow(['Related Accessories']);
                        headerRow.getCell(1).font = { bold: true };
                        const itemAccessories = this.$store.getters.GET_ITEMS;
                        itemAccessories.forEach((itemAccessories) => {
                            const row = worksheet.addRow([itemAccessories.text]);
                            row.getCell(1).font = { bold: false };
                        });
                    } else if (sheetName === 'New Models (Guide)') {
                        const headerRow = worksheet.addRow(['New Models']);
                        headerRow.getCell(1).font = { bold: true };
                        const itemSubstitutions = this.$store.getters.GET_ITEMS;
                        itemSubstitutions.forEach((itemSubstitution) => {
                            const row = worksheet.addRow([itemSubstitution.text]);
                            row.getCell(1).font = { bold: false };
                        });
                    } else if (sheetName === 'Discontinue Order Date (Guide)') {
                        const headerRow = worksheet.addRow(['Discontinue Order Date']);
                        headerRow.getCell(1).font = { bold: true };
                        const dates = ['YYYY-MM-DD', '2022-12-31', 'YYYY/MM/DD', '2024/12/31'];
                        dates.forEach((date) => {
                            worksheet.addRow([date]);
                        });
                        worksheet.columns.forEach((column) => {
                            column.width = 22;
                        });
                    }

                    for (let element of this.GET_SYSTEM_DETAIL_SELECTION) {
                        if(sheetName == `Related ${element.brand_name} (Guide)`) {
                            const headerRow = worksheet.addRow([`Related ${element.brand_name} (Guide)`]);
                            headerRow.getCell(1).font = { bold: true };

                            const itemBrands = this.relatedItems[element.brand_name];
                            itemBrands.forEach((brands) => {
                                const row = worksheet.addRow([brands.text]);
                                row.getCell(1).font = { bold: false };
                            });
                        }
                    }
                });

                const buffer = await workbook.xlsx.writeBuffer();
                const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'Update Items(Template).xlsx';

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(link.href);
                this.loading_update = false;
            } catch (error) {
                console.error(error);
                Swal.fire('', 'Error downloading the file', 'error');
            }
        },
        async downloadNewTemplate() {
            this.loading_new = true;
            try{

                const workbook = new this.$exceljs.Workbook();
                const sheetNames = [
                    'Data',
                    'Discount Type (Guide)',
                    'Category (Guide)',
                    'UOM (Guide)',
                    'Brand (Guide)',
                    'Size (Guide)',
                    'Alternate Models (Guide)',
                    'Related Accessories (Guide)',
                    'New Models (Guide)',
                    'Discontinue Order Date (Guide)'
                ];

                this.GET_SYSTEM_DETAIL_SELECTION.forEach(system => {
                    sheetNames.push(`Related ${system.brand_name} (Guide)`);
                });

                sheetNames.forEach((sheetName) => {
                    const worksheet = workbook.addWorksheet(sheetName);
                    if (sheetName === 'Data') {
                        let headerArray = [
                            'Model',
                            'Item Name',
                            'Description',
                            'UOM',
                            'Discount Type',
                            'Category',
                            'Part No.',
                            'Selling Price',
                            'SP Price',
                            'MOQ',
                            'Master Box Quantity',
                            'Small Box Quantity',
                            'Alternate Models (Format: Model,Model)',
                            'Related Accessories (Format: Model,Model)',
                            'New Models (Format: Model,Model)',
                            'Brand',
                            'Size',
                            'CBM',
                            'Weight (kgs)',
                            'Master Box Barcode',
                            'Minimum Purchase Order Quantity',
                            'Low Stock Quantity',
                            'Stock Alert',
                            'FOC (Y/N)',
                            'With Expiration (Y/N)',
                            'Phased Out (Y/N)',
                            'Exclude in Pricelist (Y/N)',
                            'Active (Y/N)',
                            'Remarks',
                            'Discontinue Order Date',
                            'Limit Order'
                        ];

                        this.GET_SYSTEM_DETAIL_SELECTION.forEach(system => {
                            headerArray.push(`Related ${system.brand_name} (Format: Model,Model)`);
                        });

                        const headerRow = worksheet.addRow(headerArray);

                        headerRow.eachCell((cell) => {
                            cell.font = { bold: true };
                        });
                        const discontinueOrderDateColumn = worksheet.getColumn('AB');
                        discontinueOrderDateColumn.numFmt = '@';
                    }
                    if (sheetName === 'Discount Type (Guide)') {
                        const headerRow = worksheet.addRow(['Discount Types']);
                        headerRow.getCell(1).font = { bold: true };

                        const itemTypes = this.$store.getters.GET_ITEM_TYPES;
                        itemTypes.forEach((itemType) => {
                            worksheet.addRow([itemType.text]);
                        });
                    } else if (sheetName === 'Category (Guide)') {
                        const headerRow = worksheet.addRow(['Categories']);
                        headerRow.getCell(1).font = { bold: true };

                        const itemCategories = this.$store.getters.GET_ITEM_CATEGORIES;
                        itemCategories.forEach((itemCategory) => {
                            worksheet.addRow([itemCategory.text]);
                        });
                    } else if (sheetName === 'UOM (Guide)') {
                        const headerRow = worksheet.addRow(['UOM']);
                        headerRow.getCell(1).font = { bold: true };

                        const itemUOM = this.$store.getters.GET_ITEM_UOM.data;
                        itemUOM.forEach((item) => {
                            worksheet.addRow([item.text]);
                        });
                    } else if (sheetName === 'Brand (Guide)') {
                        const headerRow = worksheet.addRow(['Brands']);
                        headerRow.getCell(1).font = { bold: true };

                        const itemBrands = this.$store.getters.GET_ITEM_BRAND;
                        itemBrands.forEach((itemBrand) => {
                            worksheet.addRow([itemBrand.text]);
                        });
                    } else if (sheetName === 'Size (Guide)') {
                        const headerRow = worksheet.addRow(['Sizes']);
                        headerRow.getCell(1).font = { bold: true };

                        const itemSizes = this.$store.getters.GET_ITEM_SIZE.data;
                        itemSizes.forEach((itemSize) => {
                            worksheet.addRow([itemSize.text]);
                        });
                    }
                    else if (sheetName === 'Alternate Models (Guide)') {
                        const headerRow = worksheet.addRow(['Alternate Models']);
                        headerRow.getCell(1).font = { bold: true };

                        const itemSuggestions = this.$store.getters.GET_ITEMS;
                        itemSuggestions.forEach((itemSuggestion) => {
                            const row = worksheet.addRow([itemSuggestion.text]);
                            row.getCell(1).font = { bold: false };
                        });
                    } else if (sheetName === 'Related Accessories (Guide)') {
                        const headerRow = worksheet.addRow(['Related Accessories']);
                        headerRow.getCell(1).font = { bold: true };

                        const itemAccessories = this.$store.getters.GET_ITEMS;
                        itemAccessories.forEach((itemAccessory) => {
                            const row = worksheet.addRow([itemAccessory.text]);
                            row.getCell(1).font = { bold: false };
                        });
                    } else if (sheetName === 'New Models (Guide)') {
                        const headerRow = worksheet.addRow(['New Models']);
                        headerRow.getCell(1).font = { bold: true };

                        const itemSubstitutions = this.$store.getters.GET_ITEMS;
                        itemSubstitutions.forEach((itemSubstitution) => {
                            const row = worksheet.addRow([itemSubstitution.text]);
                            row.getCell(1).font = { bold: false };
                        });
                    } else if (sheetName === 'Discontinue Order Date (Guide)') {
                        const headerRow = worksheet.addRow(['Discontinue Order Date']);
                        headerRow.getCell(1).font = { bold: true };

                        const dates = ['YYYY-MM-DD', '2022-12-12', 'YYYY/MM/DD', '2024/03/01'];
                        dates.forEach((date) => {
                            worksheet.addRow([date]);
                        });
                        worksheet.columns.forEach((column) => {
                            column.width = 22;
                        });
                    }

                    for (let element of this.GET_SYSTEM_DETAIL_SELECTION) {
                        if(sheetName == `Related ${element.brand_name} (Guide)`) {
                            const headerRow = worksheet.addRow([`Related ${element.brand_name} (Guide)`]);
                            headerRow.getCell(1).font = { bold: true };

                            const itemBrands = this.relatedItems[element.brand_name];
                            itemBrands.forEach((brands) => {
                                const row = worksheet.addRow([brands.text]);
                                row.getCell(1).font = { bold: false };
                            });
                        }
                    }
                });

                const buffer = await workbook.xlsx.writeBuffer();
                const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'Items(Template).xlsx';

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(link.href);
                this.loading_new = false;
            } catch (error) {
                console.error(error);
                Swal.fire('', 'Error downloading the file', 'error');
            }
        },
        importNewExcel() {
                this.loadingUploadItems = true;
                const fileReader = new FileReader();
                const config = {
                    headers: {
                        "content-type": "multipart/form-data",
                    },
                };
                fileReader.onload = (e) => {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: 'array' });

                    const worksheet = workbook.Sheets['Data'];
                    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: '' });
                    const headers = jsonData[0];
                    const dataRows = jsonData.slice(1);

                    const modelIndex = headers.indexOf('Model');
                    const nameIndex = headers.indexOf('Item Name');
                    const descriptionIndex = headers.indexOf('Description');
                    const uomIndex = headers.indexOf('UOM');
                    const typeIndex = headers.indexOf('Discount Type');
                    const categoryIndex = headers.indexOf('Category');
                    const part_no_Index = headers.indexOf('Part No.');
                    const selling_price_Index = headers.indexOf('Selling Price');
                    const sp_price_Index = headers.indexOf('SP Price');
                    const moqIndex = headers.indexOf('MOQ');
                    const master_box_qty_Index = headers.indexOf('Master Box Quantity');
                    const small_box_quantity_Index = headers.indexOf('Small Box Quantity');
                    const item_suggestions_Index = headers.indexOf('Alternate Models (Format: Model,Model)');
                    const item_accessories_Index = headers.indexOf('Related Accessories (Format: Model,Model)');
                    const item_substitutions_Index = headers.indexOf('New Models (Format: Model,Model)');
                    const brandIndex = headers.indexOf('Brand');
                    const sizeIndex = headers.indexOf('Size');
                    const cbmIndex = headers.indexOf('CBM');
                    const weightIndex = headers.indexOf('Weight (kgs)');
                    const master_box_barcode_Index = headers.indexOf('Master Box Barcode');
                    const min_po_qty_Index = headers.indexOf('Minimum Purchase Order Quantity');
                    const low_stock_qty_Index = headers.indexOf('Low Stock Quantity');
                    const stock_alert_Index = headers.indexOf('Stock Alert');
                    const focIndex = headers.indexOf('FOC (Y/N)');
                    const withExpirationIndex = headers.indexOf('With Expiration (Y/N)');
                    const phased_out_Index = headers.indexOf('Phased Out (Y/N)');
                    const excludeIndex = headers.indexOf('Exclude in Pricelist (Y/N)');
                    const activeIndex = headers.indexOf('Active (Y/N)');
                    const remarks_Index = headers.indexOf('Remarks');
                    const discontinue_order_date_Index = headers.indexOf('Discontinue Order Date');
                    const limit_order_Index = headers.indexOf('Limit Order');

                    let result = {}
                    this.GET_SYSTEM_DETAIL_SELECTION.forEach(system => {
                        const system_id = system.id;
                        const brand_name = system.brand_name;

                        const brandIndex = headers.indexOf(`Related ${brand_name} (Format: Model,Model)`);
                        if (!result[system_id]) {
                            result[system_id] = [];
                        }
                        if (brandIndex !== -1) {
                            result[system_id].push(brandIndex);
                        }
                    });

                    const items = [];
                    dataRows.forEach((row) => {
                        const model = row[modelIndex];
                        const name = row[nameIndex];
                        const description = row[descriptionIndex];
                        const uom_id = row[uomIndex];
                        const type_id = row[typeIndex];
                        const category_id = row[categoryIndex];
                        const part_no = row[part_no_Index];
                        const selling_price = row[selling_price_Index];
                        const sp_price = row[sp_price_Index];
                        const moq = row[moqIndex];
                        const master_box_qty = row[master_box_qty_Index];
                        const small_box_qty = row[small_box_quantity_Index];
                        const item_suggestions = row[item_suggestions_Index];
                        const item_accessories = row[item_accessories_Index];
                        const item_substitutions = row[item_substitutions_Index];
                        const brand_id = row[brandIndex];
                        const size_id = row[sizeIndex];
                        const cbm = row[cbmIndex];
                        const weight = row[weightIndex];
                        const master_box_barcode = row[master_box_barcode_Index];
                        const moq_po = row[min_po_qty_Index];
                        const low_stock_qty = row[low_stock_qty_Index];
                        const alert_qty = row[stock_alert_Index];
                        const foc = row[focIndex];
                        const with_expiration = row[withExpirationIndex];
                        const phased_out = row[phased_out_Index];
                        const exclude_in_pricelist = row[excludeIndex];
                        const active = row[activeIndex];
                        const remarks = row[remarks_Index];
                        const discontinue_order_date = row[discontinue_order_date_Index];
                        const limit_order = row[limit_order_Index];


                        let item_brands = []
                        for  (let key of Object.keys(result)) {
                            let test = row[result[key][0]]
                            item_brands.push({[key]: test})
                        }
                        console.log(item_brands)
                        const item = {
                            model,
                            name,
                            description,
                            uom_id,
                            type_id,
                            category_id,
                            part_no,
                            selling_price,
                            sp_price,
                            moq,
                            master_box_qty,
                            small_box_qty,
                            item_suggestions,
                            item_accessories,
                            item_substitutions,
                            brand_id,
                            size_id,
                            cbm,
                            weight,
                            master_box_barcode,
                            moq_po,
                            low_stock_qty,
                            alert_qty,
                            foc,
                            with_expiration,
                            phased_out,
                            exclude_in_pricelist,
                            active,
                            remarks,
                            discontinue_order_date,
                            limit_order,
                            item_brands,
                        };
                            items.push(item);
                    });
                    const payload = {
                        data: items
                    };

                    this.$store.dispatch("storeImportItem", payload, config)
                    .then((response) => {
                        if (response.data.type && response.data.message) {
                            let title = response.data.title;
                            let message = response.data.message;

                            if (response.data.type === 'error' && response.data['Existing Models']) {
                                message += '\n: ' + response.data['Existing Models'].join(', ');
                            }

                            Swal.fire(title, message, response.data.type);
                        }
                        this.closeImportItemModal();
                    })
                    .catch((error) => {
                        if (error.response.data.type && error.response.data.message) {
                            let title = error.response.data.title;
                            let message = error.response.data.message;

                            if (error.response.data.type === 'error' && error.response.data['Existing Models']) {
                                message += '\n: ' + error.response.data['Existing Models'].join(', ');
                            }

                            Swal.fire(title, message, error.response.data.type);

                            if (error.response.data.type === 'warning') {
                                let title = error.response.data.title;
                                let additionalMessage = '';
                                if (error.response.data['Existing Models']) {
                                    additionalMessage += 'Existing Models Found: ' + error.response.data['Existing Models'].join(', ') + '\n';
                                }
                                if (error.response.data['Newly Added Models']) {
                                    additionalMessage += 'New Models Added: ' + error.response.data['Newly Added Models'].join(', ');
                                }
                                if (additionalMessage !== '') {
                                    Swal.fire(title, additionalMessage, 'info');
                                }
                                this.closeImportItemModal();
                            }
                        }
                    })
                    .finally(() => {
                        this.loadingUploadItems = false;
                    });
                };
                fileReader.readAsArrayBuffer(this.selectedNewFile);
        },
        uploadImages() {
            if (this.imageUpload.length > 50) {
                Swal.fire('ERROR!', "You can only upload up to 50 images", "error");
                return;
            }
            for (let i = 0; i < this.imageUpload.length; i++) {
                const file = this.imageUpload[i];
                if (file.size > 6 * 1024 * 1024) {
                    Swal.fire('ERROR!', "Each image can only have a file size of 6MB", "error");
                    return;
                }
            }
            if (this.imageUpload.length === 0) {
                Swal.fire('ERROR!', "No Image/s Selected", "error");
                return;
            }
            let formData = new FormData();
            for (let i = 0; i < this.imageUpload.length; i++) {
                formData.append('images[]', this.imageUpload[i]);
            }
            let config = {
                headers: {
                    "content-type": "multipart/form-data",
                },
            };
            this.$store.dispatch("storeImportImage", formData, config)
            .then((response) => {
                if (response.data.error) {
                    Swal.fire({
                        title: 'ERROR!',
                        text: response.data.error,
                        icon: 'error',
                        showConfirmButton: true
                    });
                } else if (response.data.message) {
                    Swal.fire({
                        title: 'SUCCESS!',
                        text: response.data.message,
                        icon: 'success',
                        showConfirmButton: true
                    });
                    this.closeImportItemModal();
                }
            })
            .catch((error) => {
                console.log(error);
                Swal.fire({
                    title: 'ERROR!',
                    text: error.response.data.error,
                    icon: 'error',
                    showConfirmButton: true
                });
            });
        },
        uploadImagesExcel() {
            // for (let i = 0; i < this.imageUpload.length; i++) {
            //     const file = this.imageUpload[i];
            //     if (file.size > 6 * 1024 * 1024) {
            //         Swal.fire('ERROR!', "Each image can only have a file size of 6MB", "error");
            //         return;
            //     }
            // }
            if (this.imageUpload.length === 0) {
                Swal.fire('ERROR!', "No Image/s Selected", "error");
                return;
            }
            let formData = new FormData();
            if(this.$route.name == 'serviceitems'){
                formData.append('service', 1);
            }
            for (let i = 0; i < this.imageUpload.length; i++) {
                formData.append('images[]', this.imageUpload[i]);
            }
            let config = {
                headers: {
                    "content-type": "multipart/form-data",
                },
            };
            this.loading.imageUpload = true;

            this.$store.dispatch("storeImportImageExcel", formData, config)
            .then((response) => {
                if (response.data.error) {
                    Swal.fire({
                        title: 'ERROR!',
                        text: response.data.error,
                        icon: 'error',
                        showConfirmButton: true
                    });
                } else if (response.data.message) {
                    Swal.fire({
                        title: 'SUCCESS!',
                        text: response.data.message,
                        icon: 'success',
                        showConfirmButton: true
                    });
                    this.closeImportItemModal();
                }
                this.loading.imageUpload = false;
            })
            .catch((error) => {
                console.log(error);
                Swal.fire({
                    title: 'ERROR!',
                    text: error.response.data.error,
                    icon: 'error',
                    showConfirmButton: true
                });
                this.loading.imageUpload = false;
            });
        },
        async importUpdateExcel() {
            this.loadingUpdateItems = true;
            const file = this.selectedUpdateFile;
            const fileReader = new FileReader();
            const config = {
                headers: {
                    "content-type": "multipart/form-data",
                },
            };

            fileReader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const worksheet = workbook.Sheets['Data'];
                const headers = [];
                const range = XLSX.utils.decode_range(worksheet['!ref']);
                for (let C = range.s.c; C <= range.e.c; C++) {
                const cellAddress = XLSX.utils.encode_cell({ r: range.s.r, c: C });
                const headerCell = worksheet[cellAddress];
                    if (headerCell) {
                        headers.push(headerCell.v);
                    }
                }

                const dataRows = XLSX.utils.sheet_to_json(worksheet, { header: 1, range: 1, defval: '' });

                const items = dataRows.map((row) => {
                const item = {};
                const itemsa = {};
                    headers.forEach((header, index) => {
                        if(this.GET_SYSTEM_DETAIL_SELECTION.some(e => e.brand_name === header)) {
                            let systems = this.GET_SYSTEM_DETAIL_SELECTION.filter(x=> x.brand_name == header).map(e=> e.id)
                            let brands = this.GET_SYSTEM_DETAIL_SELECTION.find(x=> x.brand_name == header)

                            let brand = brands ? `Related ${brands.brand_name}` : ''
                            itemsa[systems] = row[index]
                            let data = itemsa
                            item[brand] = data
                        } else {
                            item[header] = row[index];
                        }
                    });
                    return item;
                });

                const payload = {
                data: items,
                };

                this.$store.dispatch("updateImportItem", payload, config).then((response) => {
                    if (response.data.not_found_models) {
                        this.closeImportItemModal()
                        Swal.fire({
                            title: 'Import Complete',
                            html: 'The following models were not found:<br>' + response.data.not_found_models.join('<br>'),
                            icon: 'info',
                            confirmButtonText: 'OK'
                        })
                    } else if (this.GET_UPDATE_IMPORT_ITEM.error) {
                        Swal.fire('', "Update Item/s Failed", "error");
                    } else {
                        Swal.fire('', "Update Item/s Successful!", "success");
                        this.closeImportItemModal();
                    }
                })
                .finally(() => {
                    this.loadingUpdateItems = false;
                });
            };

            fileReader.readAsArrayBuffer(file);
        },
        handleNewFileChange() {
            const file = this.selectedNewFile;
                if (!file) {
                    this.selectedNewFile = null;
                    this.showNewDataTable = false;
                    return;
                }

            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                const headers = jsonData[0];
                const modelIndex = headers.indexOf('Model');
                const itemNameIndex = headers.indexOf('Item Name');
                const descriptionIndex = headers.indexOf('Description');
                const uomIndex = headers.indexOf('UOM');
                const typeIndex = headers.indexOf('Discount Type');
                const categoryIndex = headers.indexOf('Category');
                const part_no_Index = headers.indexOf('Part No.');
                const selling_price_Index = headers.indexOf('Selling Price');
                const sp_price_Index = headers.indexOf('SP Price');
                const moqIndex = headers.indexOf('MOQ');
                const master_box_qty_Index = headers.indexOf('Master Box Quantity');
                const small_box_quantity_Index = headers.indexOf('Small Box Quantity');
                const item_suggestions_Index = headers.indexOf('Alternate Models (Format: Model,Model)');
                const item_accessories_Index = headers.indexOf('Related Accessories (Format: Model,Model)');
                const item_substitutions_Index = headers.indexOf('New Models (Format: Model,Model)');
                const brandIndex = headers.indexOf('Brand');
                const sizeIndex = headers.indexOf('Size');
                const cbmIndex = headers.indexOf('CBM');
                const weightIndex = headers.indexOf('Weight (kgs)');
                const master_box_barcode_Index = headers.indexOf('Master Box Barcode');
                const min_po_qty_Index = headers.indexOf('Minimum Purchase Order Quantity');
                const low_stock_qty_Index = headers.indexOf('Low Stock Quantity');
                const stock_alert_Index = headers.indexOf('Stock Alert');
                const focIndex = headers.indexOf('FOC (Y/N)');
                const phased_out_Index = headers.indexOf('Phased Out (Y/N)');
                const excludeIndex = headers.indexOf('Exclude in Pricelist (Y/N)');
                const activeIndex = headers.indexOf('Active (Y/N)');
                const remarks_Index = headers.indexOf('Remarks');
                const discontinue_order_date_Index = headers.indexOf('Discontinue Order Date');
                const limit_order_Index = headers.indexOf('Limit Order');

                this.extractedData = jsonData.slice(1).map((row) => ({
                    model: row[modelIndex],
                    itemName: row[itemNameIndex],
                    description: row[descriptionIndex],
                    uom: row[uomIndex],
                    type: row[typeIndex],
                    category: row[categoryIndex],
                    part_no: row[part_no_Index],
                    srp: row[selling_price_Index],
                    sp_price: row[sp_price_Index],
                    moq: row[moqIndex],
                    master_box_qty: row[master_box_qty_Index],
                    small_box_qty: row[small_box_quantity_Index],
                    item_suggest: row[item_suggestions_Index],
                    item_accessory: row[item_accessories_Index],
                    item_sub: row[item_substitutions_Index],
                    brand: row[brandIndex],
                    size: row[sizeIndex],
                    cbm: row[cbmIndex],
                    weight: row[weightIndex],
                    master_box_barcode: row[master_box_barcode_Index],
                    moq_po: row[min_po_qty_Index],
                    low_stock_qty: row[low_stock_qty_Index],
                    stock_alert: row[stock_alert_Index],
                    foc: row[focIndex],
                    phased_out: row[phased_out_Index],
                    exclude: row[excludeIndex],
                    active: row[activeIndex],
                    remarks: row[remarks_Index],
                    discontinue_order_date: row[discontinue_order_date_Index],
                    limit_order: row[limit_order_Index],
                }));

                this.totalItems = this.extractedData.length;
                this.updateItems();
                this.showNewDataTable = true;
            };

            reader.readAsArrayBuffer(file);
        },
        updateItems() {
            const startIndex = (this.options.page - 1) * this.options.itemsPerPage;
            const endIndex = startIndex + this.options.itemsPerPage;
            this.items = this.extractedData.slice(startIndex, endIndex);
        },
        handleUpdateFileChange() {
            const file = this.selectedUpdateFile;
            if (!file) {
                this.selectedUpdateFile = null;
                this.showUpdateDataTable = false;
                return;
            }

            const reader = new FileReader();

            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                const headers = jsonData[0];
                const extractedData = jsonData.slice(1).map((row) => {
                    const rowData = {};
                    headers.forEach((header, index) => {
                        rowData[header] = row[index];
                    });
                    return rowData;
                });

                this.itemsUpdate = extractedData;
                this.showUpdateDataTable = true;
            };

            reader.readAsArrayBuffer(file);
        },
        clearFileInput() {
            this.$refs.fileInput.value = null;
            this.selectedUpdateFile = null;
        }
    },
    watch: {
        selectedNewFile(newVal) {
            this.showNewDataTable = newVal !== null;
        },
        selectedUpdateFile(newVal) {
            this.showUpdateDataTable = newVal !== null;

            if (newVal === null) {
                this.$refs.fileInput.value = null;
            }
        },
        options: {
            handler() {
                this.updateItems();
            },
            deep: true,
        },
        USER_ACCESS: {
            handler(val) {
                this.userAccess.create = false;
                this.userAccess.edit = false;
                if (val != "fail") {
                    val.forEach((e) => {
                        if (e.actions_code == "create") {
                            this.userAccess.create = true;
                        } else if (e.actions_code == "edit") {
                            this.userAccess.edit = true;
                        }
                    });
                }
            },
        },

        GET_SYSTEM_DETAIL_SELECTION: {
            async handler(val) {
                if(val.length > 0) {
                    this.systemDetailSelection = val
                    for await (let element of this.systemDetailSelection) {
                        this.itemUpdate.push({text: `RELATED ${element.brand_name.toUpperCase()}`,value: `${element.brand_name}`})
                        await this.$store.dispatch('systemItemDropdowns', {
                            url: 'system-item-dropdowns',
                            connection_name: element.connection_name,
                        }).then(response => {
                            this.relatedItems[element.brand_name] = this.GET_SYSTEM_ITEM
                        })
                    }
                }
            },
        }
    }
};
</script>

<style>
.fixed-size-dialog {
    width: 500px;
}

.no-overflow-x {
    overflow-x: hidden;
}

.table-container {
    display: flex;
    margin-top: 20px;
    flex-grow: 1;
    overflow: hidden;
}
.flex-table {
    display: flex;
    flex-grow: 1;
    width: 100%;
}

.flex-table > div {
    width: 100%;
}

.custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
