<template>
    <span>
        <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }" >
                    <v-badge
                            :content="GET_FILE_PREVIEWS.length"
                            color="green"
                            offset-x="25"
                            offset-y="10"
                            bordered
                            overlap
                            :value="GET_FILE_PREVIEWS.length == 0 ? false : true"
                        >
                        <v-btn
                            @click="showFilesViewerDialog()"
                            style="opacity:1;"
                            v-bind="attrs"
                            v-on="on"
                            text
                        ><v-icon>mdi-file-document-multiple-outline</v-icon>
                        </v-btn>
                    </v-badge>
                </template>
            <span>View Files</span>
        </v-tooltip>
        <v-dialog v-model="filesViewerDialog" persistent max-width="60%">
            <v-card v-if="action == 'Submit'"> <!--If Create Button -->
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                        <span class="headline">Preview Files</span>
                        </v-card-title>
                    </v-col>

                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeFilesViewerDialog">
                        <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider class="my-0" />
                    <v-container v-if="GET_FILE_PREVIEWS.length > 0">
                        <v-window v-model="onboarding" >
                            <v-window-item v-for="(preview, index) in GET_FILE_PREVIEWS" :key="`card-${index}`">
                                <v-card color="transparent" height="500">
                                    <v-row class="fill-height" align="center" justify="center">
                                        <v-card-text class="text-center d-flex justify-center">
                                            <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }" >
                                                        <v-btn
                                                            @click="removeItem(index,preview)"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            style="top: 20px; right: 10px; z-index: 100;"
                                                            class="position-absolute"
                                                            icon
                                                            text
                                                            small
                                                        >
                                                            <v-icon color="red">mdi-delete-circle-outline</v-icon>
                                                        </v-btn>
                                                    </template>
                                                <span>Remove File </span>
                                            </v-tooltip>
                                            <v-row>
                                                <v-col class="d-flex justify-content-center">
                                                    <v-card elevation="0">
                                                        <v-card-text>
                                                            <template v-if="preview.type == 'image'">
                                                                <v-img :src="preview.data" contain width="800" height="400" class="responsive-iframe" />
                                                                <span>{{preview.name}}</span>
                                                            </template>
                                                            <template v-if="preview.type == 'pdf'">
                                                                <iframe :src="preview.data" :width="800" :height="450" />
                                                                <span>{{preview.name}}</span>
                                                            </template>
                                                            <!-- <template v-else>
                                                                <v-img :src="preview.data" contain max-width="800" max-height="400" />
                                                            </template> -->
                                                        </v-card-text>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-row>
                                </v-card>
                            </v-window-item>
                        </v-window>
                        <v-card-actions class="justify-space-between" v-if="GET_FILE_PREVIEWS.length > 1">
                            <v-btn
                                text
                                @click="prev"
                            >
                                <v-icon>mdi-arrow-left-drop-circle</v-icon>
                            </v-btn>
                            <v-item-group
                                v-model="onboarding"
                                class="text-center d-flex flex-row justify-content-center"
                                mandatory
                            >
                                <v-item
                                style="cursor:pointer"
                                v-for="(file,i) in GET_FILE_PREVIEWS"
                                :key="i"
                                v-slot="{ active, toggle }"
                                >
                                    <v-img v-if="file.type =='image'" :src="file.data" :input-value="active"
                                        @click="toggle"
                                        :class="{ 'mx-2': true, 'active-border': active }"
                                        width="50"
                                        height="50"
                                        contain
                                        icon
                                        text
                                    >
                                        <v-overlay :value="active" absolute/>
                                    </v-img>
                                    <v-btn
                                        v-else
                                        text
                                        icon
                                        @click="toggle"
                                        class='mx-2'
                                    >
                                        <v-icon
                                            style="font-size: 60px;"
                                            class="mt-4"
                                            :color="active ? 'black' : 'gray'"
                                        >mdi-file-pdf-box
                                        </v-icon>
                                    </v-btn>
                                </v-item>
                            </v-item-group>
                            <v-btn
                                text
                                @click="next"
                            >
                                <v-icon>mdi-arrow-right-drop-circle</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-container>
                    <v-container v-else>
                        <v-row class="text-center">
                            <v-col>
                                <span>No Files Uploaded</span>
                            </v-col>
                        </v-row>
                    </v-container>

                <v-card-actions class="text-center" style="border-top:1px solid #dee2e6;">
                    <v-row class="ma-1 pb-2">
                        <v-col>
                            <v-btn
                                @click="closeFilesViewerDialog()"
                            >
                                <span>Okay</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
            <v-card v-else-if="action == 'Update' || action =='Approve'"> <!--IF Update or Approve -->
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">Files Viewer</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeFilesViewerDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider class="my-0" />
                <v-card-text class="pa-1">
                    <v-data-table
                        :headers="filesHeader"
                        :items="files"
                        :loading="loading"
                        disable-sort
                        :no-data-text="'No Data Available'"
                    >
                    <template v-slot:item.action="{ item }">
                        <td class="px-0">
                            <span class="text-nonwrap ml-12">
                                <v-btn text icon color="orange">
                                    <v-icon class="btn-action" small @click="showFile(item); newImagePath()">mdi-eye</v-icon>
                                </v-btn>
                                <!-- <v-btn text icon color="blue">
                                    <v-icon class="btn-action" small @click="downloadFile(item)">mdi-download</v-icon>
                                </v-btn> -->
                            </span>
                        </td>
                    </template>
                    <template v-slot:item.created_at="{ item }">
                        <td class="px-0">
                            {{ date(item) }}
                        </td>
                    </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="viewFile" persistent max-width="60%">
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                        <span class="headline">Preview Files</span>
                        </v-card-title>
                    </v-col>

                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeViewFileOnly">
                        <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider class="my-0" />
                    <v-container v-if="GET_FILE_PREVIEWS.length > 0">
                        <v-window v-model="onboarding" >
                            <v-window-item v-for="(preview, index) in GET_FILE_PREVIEWS" :key="`card-${index}`">
                                <v-card color="transparent" height="500">
                                    <v-row class="fill-height" align="center" justify="center">
                                        <v-card-text class="text-center d-flex justify-center">
                                            <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }" >
                                                        <v-btn
                                                            @click="removeItem(index,preview)"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            style="top: 20px; right: 10px; z-index: 100;"
                                                            class="position-absolute"
                                                            icon
                                                            text
                                                            small
                                                        >
                                                            <v-icon color="red">mdi-delete-circle-outline</v-icon>
                                                        </v-btn>
                                                    </template>
                                                <span>Remove File </span>
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }" >
                                                        <v-btn
                                                            text
                                                            icon
                                                            style="top: 20px; right: 35px; z-index: 100;"
                                                            small
                                                            class="position-absolute"
                                                            color="blue"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            <v-icon class="btn-action" @click="downloadFile(preview)">mdi-download-circle-outline</v-icon>
                                                        </v-btn>
                                                    </template>
                                                <span>Download File </span>
                                            </v-tooltip>

                                            <v-row>
                                                <v-col class="d-flex justify-content-center">
                                                    <v-card elevation="0" class="fluid">
                                                        <v-card-text>
                                                            <template v-if="preview.type == 'image'">
                                                                <v-img :src="checkImagePath(preview.name)" contain width="500" height="300" class="responsive-iframe" />
                                                            </template>
                                                            <template v-if="preview.file_type == 'pdf'">
                                                                <v-row class="d-flex flex-column">
                                                                    <iframe :src="checkImagePath(preview.name)" :width="800" :height="450" />
                                                                    <span>{{preview.name}}</span>
                                                                </v-row>
                                                            </template>
                                                            <template v-else>
                                                                <v-img :src="checkImagePath(preview.name)" contain max-width="800" max-height="400" />
                                                                <span>{{preview.name}}</span>
                                                            </template>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-row>
                                </v-card>
                            </v-window-item>
                        </v-window>
                            <v-card-actions class="justify-space-between" v-if="GET_FILE_PREVIEWS.length > 1">
                                <v-btn
                                    text
                                    @click="prev"
                                >
                                    <v-icon>mdi-arrow-left-drop-circle</v-icon>
                                </v-btn>
                                <v-item-group
                                    v-model="onboarding"
                                    class="text-center d-flex flex-row justify-content-center"
                                    mandatory
                                >
                                    <v-item
                                        style="cursor:pointer"
                                        v-for="(file, i) in GET_FILE_PREVIEWS"
                                        :key="i"
                                        v-slot="{ active, toggle }"
                                    >
                                        <v-img
                                            v-if="file.file_type != 'pdf'"
                                            :src="checkImagePath(file.name)"
                                            :input-value="active"
                                            @click="toggle"
                                            :class="{ 'mx-2': true, 'active-border': active }"
                                            width="50"
                                            contain
                                            height="50"
                                        >
                                            <v-overlay :value="active" absolute/>
                                        </v-img>
                                        <v-btn
                                            v-else
                                            text
                                            icon
                                            @click="toggle"
                                            class='mx-2'
                                        >
                                            <v-icon
                                                style="font-size: 60px;"
                                                class="mt-4"
                                                :color="active ? 'black' : 'gray'"
                                            >mdi-file-pdf-box
                                            </v-icon>
                                        </v-btn>
                                    </v-item>
                                </v-item-group>

                                <v-btn
                                    text
                                    @click="next"
                                >
                                    <v-icon>mdi-arrow-right-drop-circle</v-icon>
                                </v-btn>
                            </v-card-actions>
                    </v-container>
                    <v-container v-else>
                        <v-row class="text-center">
                            <v-col>
                                <span>No Files Uploaded</span>
                            </v-col>
                        </v-row>
                    </v-container>

                <v-card-actions class="text-center" style="border-top:1px solid #dee2e6;">
                    <v-row class="ma-1 pb-2">
                        <v-col>
                            <v-btn
                                @click="closeViewFileOnly()"
                            >
                                <span>Okay</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </span>
</template>

<script>
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';

export default {
    data() {
        return {
            filesHeader: [
                { text: 'Name', value: 'name', align:'start', width:"30%" },
                { text: 'Uploaded By', value: 'uploader_name', align:'start' },
                { text: 'Date', value: 'created_at', align:'start' },
                { text: 'Action', value: 'action', align:'center' },
            ],
            files: [],
            applicant_id:'',
            loading: false,
            noDataText: '',
            viewFile: false,
            itemPath: '',
            itemName: '',
            withDelete: false,
            sourceFrame:'',
            type:null,
            previews: [],
            onboarding: 0,
            attachments: [],
            seen : false
        }
    },
    props: ['url','folder_name','action','uploads','delete_url'],
    computed: {
        ...mapGetters([
            'GET_FILE_PREVIEWS',
            'GET_FILE_VIEWER_ID',
            'GET_VIEW_DATA'
        ]),
        filesViewerDialog() {
            return this.$store.state.accounting.files.filesViewerDialog;
        },
    },
    mounted(){
    },
    methods: {
        checkImagePath(item) {
            return item == null
                ? process.env.VUE_APP_API_HOST + "/images/items/no-image.png"
                : process.env.VUE_APP_API_HOST + `/img/operations?image_name=${item}&folder_name=${this.folder_name}`;
        },
        removeItem(index,data) {
            if(['Update','Approve','Confirm'].includes(this.action)){
                swal.fire({
                    text: 'Are you sure you want to delete the file?',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then(async(result) => {
                    if(result.isConfirmed) {
                        let payload = {
                            url : this.delete_url,
                            id : data.id
                        }
                        this.$store.dispatch('deleteSampleFormFiles',payload).then(res=>{
                            swal.fire({
                                title:res.message,
                                icon:res.icon
                            })
                            if(this.GET_FILE_PREVIEWS.length == 0){
                                this.closeViewFileOnly();
                            }
                            let new_files = this.delete_url == 'deleteSampleFormFiles' ? res.data.sample_form_files : res.data.return_form_files;
                            this.$store.commit('FILE_PREVIEWS', new_files);
                            this.getFiles();
                        }).catch((err)=>{
                            console.log(err);
                            swal.fire({
                                icon: 'Network Error',
                                title: 'error',
                            })
                        })
                    }
                });
            }
            else{
                this.previews.splice(index, 1);
                this.$store.commit('FILE_PREVIEWS',this.previews);
                this.$emit('removeAttachment',index);
                if(this.previews.length == 0){
                    this.closeFilesViewerDialog();
                }
            }
        },
        next(){
            this.onboarding = this.onboarding + 1 === this.GET_FILE_PREVIEWS.length ? 0 : this.onboarding + 1
        },
        prev(){
            this.onboarding = this.onboarding - 1 < 0 ? this.GET_FILE_PREVIEWS.length - 1 : this.onboarding - 1
        },
        clearFields() {
            this.loading = true;
            this.noDataText = '';
            // this.$store.commit('FILE_PREVIEWS',[]);
        },
        getFiles(){
            this.files = [];
            let payload = {
                id : this.GET_FILE_VIEWER_ID,
                url: this.url.api
            }
            this.loading = true;
            this.$store.dispatch(this.url.dispatch,payload).then(response=>{
                this.files = response.data;
                this.loading = false;
            }).catch((err)=>{
                console.log(err)
            });
        },
        showFilesViewerDialog() {
            this.seen = true;
            this.$store.commit('filesShow');
            this.getFiles()
            this.$emit('openFilesViewer', true);
        },
        closeFilesViewerDialog() {
            this.$store.commit('filesClose');
            this.clearFields();

            this.$emit('closeFilesViewer', true);
        },
        date(item) {
            if(!!item) {
                return this.$dayjs(item.created_at).format('YYYY-MM-DD, hh:mm:ss a');
            }
            return '';
        },
        showFile(file) {
            this.viewFile = true;
            this.itemPath = file.path;
            this.itemName = file.name
            this.$store.commit('filesClose');
        },
        downloadFile(file) {
            swal.fire({
                text: 'Are you sure you want to download?',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(async(result) => {
                if(result.isConfirmed) {
                    let uri = ''
                    file.name = file.name.replaceAll(' ','%20')
                    file.name = file.name.replaceAll('#','%23')
                    uri = `${process.env.VUE_APP_API_HOST}/img/operations?image_name=${file.name}&folder_name=${this.folder_name}`
                    fetch(uri)
                    .then(resp => resp.blob())
                    .then(blobobject => {
                        const blob = window.URL.createObjectURL(blobobject);
                        const anchor = document.createElement('a');
                        anchor.style.display = 'none';
                        anchor.href = blob;
                        anchor.download = file.name;
                        document.body.appendChild(anchor);
                        anchor.click();
                        window.URL.revokeObjectURL(blob);
                    })
                    .catch((err) => console.log('An error in downloading the file \n' +  err));
                }
            });
        },
        closeViewFile() {
            this.itemPath = "";
            this.viewFile = false;
            this.applicant_id = "";
            this.$store.commit('filesShow');
        },
        closeViewFileOnly(){
            this.viewFile = false;
        },
        async newImagePath(path){
            let uri = ''
            this.itemName = this.itemName.replaceAll(' ','%20')
            this.itemName = this.itemName.replaceAll('#','%23')
            // if(!!this.rfId){
            //     uri = `${process.env.VUE_APP_API_HOST}/img/service?image_name=${this.itemName}`
            // }
            // else{
            //     uri = `${process.env.VUE_APP_API_HOST}/img/accounting?image_name=${this.itemName}&folder_name=${this.folder_name}`
            // }
            uri = `${process.env.VUE_APP_API_HOST}/img/operations?image_name=${this.itemName}&folder_name=${this.folder_name}`
            fetch(uri)
            .then(resp => resp.blob())
            .then(blobobject => {
                this.type = blobobject.type == 'image/png' ? "image" : "pdf"
                const blob = window.URL.createObjectURL(blobobject);
                this.sourceFrame = blob;
            }).catch((err)=>{
                console.log(err)
            })
        },
        closePreviewWithFile(){
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            this.$emit('uploadedData', {
                attachments: this.attachments,
                config: config
            });
            this.closeFilesViewerDialog();
            // this.reset();
        },
        closeFilePreview(){
            this.closeFilesViewerDialog();
        },
    },
    watch:{
        uploads:{
            handler(val){
                this.previews = val;
            }
        },
    }
}
</script>

<style scoped>
    .iframe-container {
        position: relative;
        overflow: hidden;
        width: 100%;        padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    }

    .iframe img{
        margin: 0 auto !important;
    }

    .active-border {
        border: 2px solid #2598f3; /* Customize the border style */
    }
</style>
