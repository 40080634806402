<template>
    <div class="page-wrapper">
        <v-row class="ma-2">
            <v-col class="d-flex no-block align-items-center">
                <h4 class="page-title">Bank</h4>
            </v-col>
        </v-row>
        <v-container class="container">
            <v-tabs v-model="tab" id="group-tab" color="cyan" background-color="#424242" dark slider-color="cyan" class="col-12 pb-0" show-arrows>
                <v-tab ripple v-if="userAccess.includes('all')" @click="bankType = 'ALL'">ALL</v-tab>
                <v-tab ripple v-if="userAccess.includes('ar')" @click="bankType = 'AR'">AR</v-tab>
                <v-tab ripple v-if="userAccess.includes('ap')" @click="bankType = 'AP'">AP</v-tab>
                <v-tab ripple v-if="userAccess.includes('closed')" @click="bankType = 'CLOSED'">CLOSED</v-tab>
                <v-spacer/>
                <v-tab ripple v-if="userAccess.includes('opening_balances')" @click="bankType = 'OB'">OPENING BALANCES</v-tab>
            </v-tabs>
            <add-bank-component :bankType="bankType" />
            <v-tabs-items v-model="tab" touchless style="background-color:unset">
                <v-tab-item v-for="(item, index) in 5" :key="index">
                    <banks-table-component :bankType="bankType" />
                </v-tab-item>
            </v-tabs-items>
        </v-container>
    </div>
</template>

<script>
import BanksTableComponent from '../../Accounting/Views/BanksComponent/BanksTableComponent.vue';
import AddBankComponent from './BanksComponent/AddBankComponent.vue';
export default {
    data() {
        return {
            tab: null,
            bankType:'',
            userAccess: []
        }
    },
    components: {
        BanksTableComponent,
        AddBankComponent
    },
    mounted(){
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id).then(response=>{
            this.userAccess = response.map(e=>e.actions_code)
            if (this.userAccess.includes('all')) {
                this.bankType = 'ALL'
            } else if(this.userAccess.includes('ar')){
                this.bankType = 'AR'
            } else if (this.userAccess.includes('ap')) {
                this.bankType = 'AP'
            } else if (this.userAccess.includes('closed')) {
                this.bankType = 'CLOSED'
            } else if (this.userAccess.includes('opening_balances')) {
                this.bankType = 'OB'
            }
        })
    },
}
</script>

<style>

</style>
