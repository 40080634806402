<template>
    <div class="w-100">
        <v-app id="my_vue_app">
            <v-card v-if="GET_CHART_OF_ACCOUNTS_STATUS.tab_name == 'ACCOUNT'">
                <v-overlay :value="!loaded">
                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                </v-overlay>
                <v-card-text style="height:500px;overflow:auto;">
                    <v-container fluid>
                        <v-row class="px-2">
                            <v-col cols="2">
                                <v-autocomplete
                                    v-model="filter.company_id"
                                    :items="GET_PV_COMPANIES_SELECTION"
                                    label="Company"
                                    outlined
                                    dense
                                    auto-select-first
                                    clearable
                                    @change="insertStore()"
                                />
                            </v-col>
                            <v-col cols="2">
                                <v-autocomplete
                                    v-model="filter.store_branch_id"
                                    :items="branchItems"
                                    label="Branch"
                                    outlined
                                    dense
                                    auto-select-first
                                    clearable
                                    @change="insertCompany(filter.store_branch_id)"
                                />
                            </v-col>
                            <v-col cols="2">
                                <v-btn
                                    @click="getCOAData()"
                                >
                                    FILTER
                                </v-btn>
                            </v-col>
                                <v-spacer />
                            <v-col cols="2">
                                <v-text-field
                                    v-model="filter.search"
                                    class="mb-5 pb-2"
                                    id="searchBar"
                                    label="Search by Mother Account Code/Name"
                                    single-line
                                    hide-details
                                    clearable
                                    append-icon="mdi-magnify"
                                    @keydown.enter="searchInput()"
                                    @click:append="searchInput()"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-treeview activatable :items="items" search>
                                    <template v-slot:prepend="{ item }">
                                        <v-btn text icon small v-if="item.children && !!item.account_type_id" @click="ViewEditAccounts(item,'Update')">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                <v-icon icon small color="primary" v-bind="attrs" v-on="on">mdi-square-edit-outline</v-icon>
                                                </template>
                                                    Edit {{ item.name }}
                                            </v-tooltip>
                                        </v-btn>
                                        <v-btn
                                            text
                                            small
                                            v-if="item.children"
                                            @click="addchild(item)"
                                            color="green"
                                            dark
                                        >
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                <v-icon v-bind="attrs" v-on="on">mdi-plus</v-icon>
                                                </template>
                                                Add Child
                                            </v-tooltip>
                                        </v-btn>
                                    </template>
                                </v-treeview>
                                <v-row v-if="loaded && items.length === 0" align="center" justify="center" class="text-center" style="height: 90%; padding-top: 150px;">
                                    <v-col cols="12">
                                        <h5 class="text-h1">NO DATA AVAILABLE</h5>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
            <v-card v-if="tabNames.includes(GET_CHART_OF_ACCOUNTS_STATUS.tab_name)">
                <v-card-text style="overflow:auto;">
                    <v-card-title>
                        <v-container fluid>
                            <v-row>
                                <v-col cols="12" sm="8" class="d-flex justify-content-start"></v-col>
                                <v-col cols="12" md="4">
                                    <div class="row col-md-12 align-items-end">
                                        <v-icon @click="searchInput()">mdi-magnify</v-icon>
                                        <v-text-field
                                            id="searchBar"
                                            v-model="filter.search"
                                            label="Search by Code/Name"
                                            single-line
                                            hide-details
                                            @change="searchInput()">
                                        </v-text-field>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-title>
                    <v-container fluid>
                        <v-row>
                            <v-col>
                                <v-data-table
                                    :headers="headers"
                                    :items="joined_items"
                                    :search="search"
                                    :options.sync="options"
                                    :server-items-length="totalItems"
                                    :items-per-page="10"
                                    :loading="!loaded"
                                    :footer-props="{
                                        itemsPerPageOptions: itemsPerPageOptions,
                                    }"
                                    loading-text="Loading... Please wait"
                                    fixed-header
                                >
                                <template v-slot:[`item.isActive`]="{ item }">
                                    <v-chip :color="item.isActive ? 'blue' : 'red'" x-small class="white--text">
                                        {{ item.isActive ? 'Active' : 'Inactive' }}
                                    </v-chip>
                                </template>
                                        <template v-slot:[`item.action`]="{ item }">
                                            <v-btn text icon color="orange" v-if="userAccess.view">
                                                <v-icon
                                                class="btn-action"
                                                small
                                                @click="ViewCOAData(item,'View')"
                                                >mdi-eye</v-icon>
                                            </v-btn>
                                            <v-btn text icon color="blue"
                                                v-if="(userAccess.edit) && !(GET_CHART_OF_ACCOUNTS_STATUS.tab_name == 'FUNDAMENTALS')"
                                            >
                                                <v-icon
                                                    class="btn-action"
                                                    small
                                                    @click="ViewCOAData(item,'Update')"
                                                >
                                                    mdi-square-edit-outline
                                                </v-icon>
                                            </v-btn>
                                            <v-btn text icon color="red"
                                                v-if="
                                                (userAccess.delete && userAccess.edit) && !(GET_CHART_OF_ACCOUNTS_STATUS.tab_name == 'FUNDAMENTALS')"
                                            >
                                                <v-icon
                                                class="btn-action"
                                                small
                                                @click="deleteCOAData(item.id)"
                                                >mdi-delete</v-icon
                                                >
                                            </v-btn>
                                        </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-app>
    </div>
</template>
<script>
import swal from "sweetalert2";
import { mapGetters } from 'vuex';
import SharedFunctionsComponent from "@/views/main/Shared/SharedFunctionsComponent.vue";
export default {
    mixins:[SharedFunctionsComponent],
    data() {
        return {
            items: [],
            joined_items: [],
            loaded: false,
            totalItems: 0,
            options: {},
            itemsPerPageOptions: [5, 10, 15, 50, 100],
            itemsPerPage: 10,
            search: "",
            filter:{
                search:"",
                location:['headoffice','stores'],
                company_id: null,
                store_branch_id: null,
            },
            for_payment:[],
            refresher:false,
            moments:(date)=>{
                return this.$dayjs(date).format('YYYY-MM-DD')
            },
            awaitingSearch: false,
            headers:[
                { text: "Code", align: "left", value: "code",sortable:false },
                { text: "Name", align: "left", value: "name",sortable:false },
                { text: "Status", align: "left", value: "isActive",sortable:false },
                { text: "Action", align: "center", value: "action",sortable:false},
            ],
            count:0,
            userAccess: {
                view: false,
                create: false,
                edit: false,
                delete: false,
            },
            tabNames: ['TYPES', 'FUNDAMENTALS', 'ACCOUNT GROUP'],
            clonedStores: []
        }
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS',
            'GET_CHART_OF_ACCOUNTS_DISPATCH',
            'GET_ASSETS_DATA',
            'GET_NEW_CHART_OF_ACCOUNTS_TABLE',
            'GET_CHART_OF_ACCOUNTS_STATUS',
            'GET_CHART_OF_ACCOUNTS_TYPES',
            'GET_NEW_CHART_OF_ACCOUNTS_TYPES_TABLE',
            'GET_EDIT_CHART_OF_ACCOUNTS_TYPES',
            'GET_FUNDAMENTALS',
            'GET_ACCOUNTING_GROUPS',
            'GET_NEW_FUNDAMENTALS_TABLE',
            'GET_NEW_ACCOUNTING_GROUPS_TABLE',
            'GET_PV_COMPANIES_SELECTION',
            'GET_PV_STORE_BRANCHES_SELECTION'
        ]),
        branchItems(){
            let items = this.clonedStores.filter(e=>e.company_id == this.filter.company_id)
            if (!this.filter.company_id) return this.clonedStores
            if (!this.filter.store_branch_id) this.filter.store_branch_id = items[0].value
            return items
        },
    },
    mounted() {
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        this.getCOAData();
        this.dropDowns();
        this.getBranches()
    },
    methods: {
        dropDowns() {
            this.$store.dispatch("getAccountTypes");
            this.$store.dispatch("getFundamentals");
            this.$store.dispatch("getAccountGroup");
        },
        getCOAData(filter = null) {
            this.loaded = false;
            this.items = [];
            this.joined_items = [];
            let payload = {
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                search: this.filter.search,
                company_id: this.filter.company_id,
                store_branch_id: this.filter.store_branch_id,
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                location: this.filter.location,
                status: this.GET_CHART_OF_ACCOUNTS_STATUS.tab_name
            };
            if (this.GET_CHART_OF_ACCOUNTS_STATUS.tab_name == 'ACCOUNT') {
                this.$store.dispatch(this.GET_CHART_OF_ACCOUNTS_DISPATCH.get, payload)
                .then((response) => {
                    this.items = response.data;
                    this.totalItems = response.data.total;
                    this.$store.commit("NEW_CHART_OF_ACCOUNTS_TABLE", false);
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.loaded = true;
                });
            } else if (this.GET_CHART_OF_ACCOUNTS_STATUS.tab_name == 'TYPES') {
                this.$store.dispatch('getAllChartOfAccountsTypes', payload)
                .then((response) => {
                    this.joined_items = response.data.data;
                    this.totalItems = response.data.total;
                    this.$store.commit("NEW_CHART_OF_ACCOUNTS_TABLE", false);
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.loaded = true;
                });
            } else if (this.GET_CHART_OF_ACCOUNTS_STATUS.tab_name == 'FUNDAMENTALS') {
                this.$store.dispatch('getAllFundamentals', payload)
                .then((response) => {
                    this.joined_items = response.data.data;;
                    this.totalItems = response.data.total;
                    this.$store.commit("NEW_CHART_OF_ACCOUNTS_TABLE", false);
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.loaded = true;
                });
            } else if (this.GET_CHART_OF_ACCOUNTS_STATUS.tab_name == 'ACCOUNT GROUP') {
                this.$store.dispatch('getAllAccountingGroups', payload)
                .then((response) => {
                    this.joined_items = response.data.data;;
                    this.totalItems = response.data.total;
                    this.$store.commit("NEW_CHART_OF_ACCOUNTS_TABLE", false);
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.loaded = true;
                });
            }
        },
        searchInput(){
            this.getCOAData({ query: this.search });
        },
        ViewEditAccounts(item,action) {
            this.$store.dispatch("getChartOfAccountsItems", { id: item.id });
            this.$store.commit("EDIT_CHART_OF_ACCOUNTS_TABLE", item);
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", action);
        },
        deleteAccountability(id) {
            swal
            .fire({
                icon: 'question',
                text: "Are you sure you want to Delete?",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#397373",
                cancelButtonColor: "#397373",
            })
            .then((result) => {
                if (result.isConfirmed) {
                this.$store
                    .dispatch(this.GET_CHART_OF_ACCOUNTS_DISPATCH.delete, id)
                    .then((success) => {});
                }
            });
        },
        addchild(item){
            this.$store.commit('CHART_OF_ACCOUNT_PARENT_ID',item.id);
            // console.log(item)
            this.$store.commit('EDIT_CHART_OF_ACCOUNTS',item);
            this.$store.commit('DIALOG',true);
            this.$store.commit('ACTION','Submit');
        },
        ViewCOAData(item,action) {
            if (this.GET_CHART_OF_ACCOUNTS_STATUS.tab_name === 'TYPES') {
                this.$store.dispatch("getChartOfAccountsTypesItem", { id: item.id });
                this.$store.commit("EDIT_CHART_OF_ACCOUNTS_TYPES", item.code);
            }
            else if (this.GET_CHART_OF_ACCOUNTS_STATUS.tab_name === 'FUNDAMENTALS') {
                this.$store.dispatch("getFundamentalsItem", { id: item.id });
                this.$store.commit("EDIT_FUNDAMENTALS", item.code);
            }
            else if (this.GET_CHART_OF_ACCOUNTS_STATUS.tab_name === 'ACCOUNT GROUP') {
                this.$store.dispatch("getAccountingGroupItems", { id: item.id });
                this.$store.commit("EDIT_ACCOUNTING_GROUPS", item.code);
            }
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", action);
        },
        deleteCOAData(id) {
            swal.fire({
                icon: 'question',
                text: "Are you sure you want to Delete?",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#397373",
                cancelButtonColor: "#397373",
            })
            .then((result) => {
                if (result.isConfirmed && this.GET_CHART_OF_ACCOUNTS_STATUS.tab_name === 'TYPES') {
                    this.$store.dispatch("DeleteChartOfAccountsTypes", id)
                        .catch((error) => {
                            console.error("Error deleting account type:", error);
                        });
                }
                else if (result.isConfirmed && this.GET_CHART_OF_ACCOUNTS_STATUS.tab_name === 'FUNDAMENTALS') {
                    this.$store.dispatch("DeleteFundamentals", id)
                        .catch((error) => {
                            console.error("Error deleting fundamental:", error);
                        });
                }
                else if (result.isConfirmed && this.GET_CHART_OF_ACCOUNTS_STATUS.tab_name === 'ACCOUNT GROUP') {
                    this.$store.dispatch("DeleteAccountGroups", id)
                        .catch((error) => {
                            console.error("Error deleting account group:", error);
                        });
                }
            });
        },
        async getBranches(){
            try{
                this.clonedStores = await this.getStoreBranches()
            } catch (error) {
                console.error(error)
            }
        },
        insertStore(){
            this.filter.store_branch_id = this.branchItems[0].value
        },
        insertCompany(storeId){
            if (!storeId) return
            let store = this.clonedStores.find(e=>e.value == storeId)
            this.filter.company_id = this.GET_PV_COMPANIES_SELECTION.find(e=>e.value == store.company_id).value
        },
    },
    watch: {
        GET_NEW_CHART_OF_ACCOUNTS_TABLE:{
            handler(val){
                if(val){
                    this.getCOAData();
                    swal.fire({
                        title: val.message.title,
                        text: val.message.msg,
                        icon: val.message.icon,
                        confirmButtonText: 'OK'
                    });
                }
            }
        },
        GET_NEW_CHART_OF_ACCOUNTS_TYPES_TABLE: {
            handler(val) {
                if (val) {
                    this.getCOAData();
                    swal.fire({
                        title: val.message.title,
                        text: val.message.msg,
                        icon: val.message.icon,
                        confirmButtonText: 'OK'
                    });
                }
            },
        },
        GET_NEW_FUNDAMENTALS_TABLE:{
            handler(val){
                if(val){
                    this.getCOAData();
                    swal.fire({
                        title: val.message.title,
                        text: val.message.msg,
                        icon: val.message.icon,
                        confirmButtonText: 'OK'
                    });
                }
            }
        },
        GET_NEW_ACCOUNTING_GROUPS_TABLE:{
            handler(val){
                if(val){
                    this.getCOAData();
                    swal.fire({
                        title: val.message.title,
                        text: val.message.msg,
                        icon: val.message.icon,
                        confirmButtonText: 'OK'
                    });
                }
            }
        },
        options: {
            handler() {
                this.getCOAData();
            },
            deep: true,
        },
        USER_ACCESS: {
            handler(val) {
                if (val != "fail") {
                    this.userAccess.edit = false;
                    this.userAccess.delete = false;
                    this.userAccess.view = false;
                    this.userAccess.create = false;
                    val.forEach((e) => {
                        if (e.actions_code == "edit") {
                            this.userAccess.edit = true;
                        } else if (e.actions_code == "delete") {
                            this.userAccess.delete = true;
                        } else if (e.actions_code == "view") {
                            this.userAccess.view = true;
                        } else if (e.actions_code == "create") {
                            this.userAccess.create = true;
                        }
                    });
                }
            },
        },
        'filter.company_id':{
            handler(val){
                if (!val) {
                    this.filter.store_branch_id = null
                }
            },
            immediate: true
        },
        'filter.store_branch_id':{
            handler(val){
                if (!val) {
                    this.filter.company_id = null
                }
            },
            immediate: true
        },
    },
};
</script>
<style scoped>

</style>