import repository from '@/api/modules/Services/index'

export default {
    state: {
        disposals_sp: [],
        TAB: 0,
        disposal_action: '',
        disposal_refresh: false,
        containers_sp: [],
        dropdown_loading: false,
        request_on_flight: false,
        disposal_dialog: false,
        disposal_details: {},
        disposal_items:[],
        status:'',
        disposal_warehouses:[],
        all_active_items: [],
        warehouse_lists:[],
        uploaded_file_exists: false,
    },

    getters: {
        GET_DISPOSALS_SP:(state) => state.disposals_sp,
		GET_DISPOSAL_TAB:(state) => state.TAB,
        GET_DISPOSAL_REFRESH:(state) => state.disposal_refresh,
        GET_CONTAINERS_SP:(state) => state.containers_sp,
        GET_DROPDOWN_LOADING:(state) => state.dropdown_loading,
        GET_DISPOSAL_DIALOG:(state) => state.disposal_dialog,
        GET_DISPOSAL_DETAILS:(state) => state.disposal_details,
        GET_DISPOSAL_ITEMS:(state) => state.disposal_items,
        GET_REQUEST_ON_FLIGHT:(state) => state.request_on_flight,
        GET_DISPOSAL_STATUS:(state) => state.status,
        GET_DISPOSAL_WAREHOUSES:(state) => state.disposal_warehouses,
        GET_DISPOSAL_ACTION:(state) => state.disposal_action,
        GET_ALL_ACTIVE_ITEMS:(state) => state.all_active_items,
        GET_WAREHOUSE_LISTS:(state) => state.warehouse_lists,
        GET_UPLOADED_FILE_EXISTS:(state) => state.uploaded_file_exists,
    },

    mutations: {
        DISPOSALS_SP:(state,data) => {state.disposals_sp = data},
		DISPOSAL_TAB:(state,data) => {state.TAB = data},
        DISPOSAL_REFRESH:(state,data) => {state.disposal_refresh = data},
        CONTAINERS_SP:(state,data) => {state.containers_sp = data},
        DROPDOWN_LOADING:(state,data) => {state.dropdown_loading = data},
        DISPOSAL_DIALOG:(state,data) => {state.disposal_dialog = data},
        DISPOSAL_DETAILS:(state,data) => {state.disposal_details = data},
        DISPOSAL_ITEMS:(state,data) => {state.disposal_items =  data},
        REQUEST_ON_FLIGHT:(state,data) => {state.request_on_flight = data},
        DISPOSAL_STATUS:(state,data) => {state.status = data},
        DISPOSAL_WAREHOUSES:(state,data) => {state.disposal_warehouses = data},
        DISPOSAL_ACTION:(state,data) => {state.disposal_action = data},
        ALL_ACTIVE_ITEMS:(state,data) => {state.all_active_items = data},
        WAREHOUSE_LISTS:(state,data) => {state.warehouse_lists = data},
        UPLOADED_FILE_EXISTS:(state,data) => {state.uploaded_file_exists = data},
    },

    actions: {
        async disposalGet({commit},payload) {
            return await new Promise((resolve,reject)=>{
                repository.disposalGet(payload).then(response=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        async disposalPost({commit},payload) {
            return await new Promise((resolve,reject)=>{
                repository.disposalPost(payload).then(response=>{
                    commit('REFRESH', true)
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        async disposalDestroy({commit}, payload) {
            return await new Promise((resolve,reject)=>{
                repository.disposalDestroy(payload).then(response=>{
                    commit('REFRESH', true)
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        getDisposalSp({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.getdisposalsp(payload).then((response)=>{
                    if(response.status == 200){
                        commit('DISPOSALS_SP',response.data)
                        resolve(response.data)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
        getContainersSp({commit},) {
            return new Promise((resolve,reject) => {
                commit('DROPDOWN_LOADING', true)
                repository.getcontainerssp().then((response) => {
                    if (response.status == 200){
                        commit('CONTAINERS_SP', response.data)
                        commit('DROPDOWN_LOADING', false)
                        resolve(response)
                    }
                }).catch((error) => {
                    commit('DROPDOWN_LOADING', false)
                    reject(error);
                });
            })
        },
        getAllActiveItems({commit}) {
            return new Promise((resolve,reject) => {
                repository.getallactiveitems().then((response)=> {
                    if(response.status == 200){
                        commit('ALL_ACTIVE_ITEMS',response.data)
                        resolve(response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getWarehousesLists({commit}) {
            return new Promise((resolve, reject) => {
                repository.getwarehouseslists().then((response) => {
                    if (response.status == 200){
                        commit('WAREHOUSE_LISTS', response.data)
                        resolve(response.data)
                    }
                }).catch( error => {
                    reject(error)
                })
            })
        },
        uploadDisposalFile({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.uploaddisposalfile(payload.params,payload.config).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
    },
}