<template>
    <div class="w-100">
        <v-app id="my_vue_app">
            <v-card>
                <v-card-title>
                    <div class="d-flex justify-content-between w-100">
                        <div>
                            {{boundType == 'IOTH' ? 'INBOUND ITEM' : 'OUTBOUND ITEM'}}
                        </div>
                        <v-btn text icon small color="gray" class="float-right" @click="() => {
                            $store.commit('DIALOG', false);
                            this.resetField();
                        }">
                        <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </div>
                    <v-container fluid>
                        <slot name="type"></slot>
                        <v-row v-if="boundType != 'IOTH'">
                            <v-col cols="2">
                                <v-text-field
                                    v-model="filter.deliver_to"
                                    label="Deliver To"
                                    required
                                    dense
                                    outlined
                                ></v-text-field>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field
                                    v-model="filter.deliver_to_address"
                                    label="Deliver Address"
                                    required
                                    dense
                                    outlined
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row v-if="boundType == 'IOTH'">
                            <v-col cols="2">
                                <v-autocomplete
                                    v-model="filter.location"
                                    :items="GET_ADMIN_LOCATION"
                                    item-text="text"
                                    item-value="value"
                                    auto-select-first
                                    outlined
                                    label="Location (Optional)"
                                    required
                                    dense
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="2">
                                <v-autocomplete
                                    v-model="filter.department"
                                    :items="GET_LIST_DEPARTMENT"
                                    item-text="text"
                                    item-value="value"
                                    auto-select-first
                                    outlined
                                    label="Department (Optional)"
                                    required
                                    dense
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="2" v-if="boundType == 'IOTH'">
                                <v-text-field
                                    v-model="form.po_num"
                                    label="Ref #"
                                    required
                                    dense
                                    outlined
                                ></v-text-field>
                            </v-col>
                            <v-col cols="2" v-if="boundType == 'OOTH' || boundType == 'DLR'">
                                <v-text-field
                                    v-model="form.arf_num"
                                    :label="boundType == 'DLR' ? 'Reference Num# (Optional)' : 'ARF Number (Optional)'"
                                    required
                                    dense
                                    outlined
                                ></v-text-field>
                            </v-col>
                            <v-col cols="2">
                                <v-autocomplete
                                    v-model="form.warehouse_id"
                                    :items="GET_ADM_WAREHOUSE_CODE_DROPDOWN"
                                    item-text="text"
                                    item-value="value"
                                    auto-select-first
                                    outlined
                                    label="Warehouse"
                                    required
                                    dense
                                ></v-autocomplete>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="2" class="d-flex justify-end" style="display:inline-block">
                                <div style="display:inline-block" v-if="!['DLR'].includes(boundType)">
                                    <v-file-input
                                        ref="fileInput"
                                        v-model="file"
                                        accept=".xls, .xlsx"
                                        @change="handleFileUpload"
                                        hide-input
                                        :disabled="disabled"
                                        hide-details
                                        hide-spin-buttons
                                    />
                                </div>
                            </v-col>
                        </v-row>
                        <v-row v-if="boundType == 'DLR'">
                            <v-col cols="4">
                                <v-autocomplete
                                    v-model="form.customer_id"
                                    :items="customers"
                                    item-text="text"
                                    item-value="value"
                                    auto-select-first
                                    outlined
                                    label="Customer"
                                    required
                                    dense
                                ></v-autocomplete>
                            </v-col>
                            <v-spacer></v-spacer>
                        </v-row>
                        <v-row v-if="boundType == 'DLR'">
                            <v-col cols="4">
                                <v-autocomplete
                                    v-model="form.admin_purchase_order_id"
                                    :items="GET_ADMIN_PO_SELECTION"
                                    :disabled="!form.customer_id"
                                    item-text="text"
                                    item-value="value"
                                    label="PO#"
                                    auto-select-first
                                    dense
                                    outlined
                                    required
                                    @change="getPOitems"
                                ></v-autocomplete>
                            </v-col>
                            <v-spacer></v-spacer>
                        </v-row>
                        <v-row>
                            <v-col cols="4">
                                <v-text-field
                                    label="Remarks"
                                    v-model="form.remarks"
                                    required
                                    dense
                                    outlined
                                ></v-text-field>
                            </v-col>
                            <v-spacer></v-spacer>
                        </v-row>
                    </v-container>
                <v-divider></v-divider>
                </v-card-title>
                <span class="items-section m-3" v-if="['DLR'].includes(boundType)">
                    <strong style="font-size: 18px; text-transform: uppercase; color: #616161;"> Purchase Order Items </strong>
                    <v-divider class="m-0 p-0"></v-divider>
                </span>
                <!-- <v-card elevation="0" style="height: 450px; overflow-y: auto;"> -->
                    <v-card-text style="height: 450px; overflow-y: auto;">
                        <!-- <v-card class="p-2"> -->
                            <!-- <div class="ml-2 w-100"> -->
                                <v-row class="p-2">
                                    <v-col cols="2">
                                        <v-tooltip top v-if="!['DLR'].includes(boundType)">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    color="primary"
                                                    dark
                                                    small
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    @click="triggerFileInput"
                                                    :disabled="disabled"
                                                >
                                                    Batch Addition
                                                </v-btn>
                                            </template>
                                            <span><img src="/images/SampleBatchAdditionInboundOutbound.png"/></span>
                                        </v-tooltip>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                    <v-col cols="2" class="d-flex justify-end mr-5">
                                        <v-btn
                                            :disabled="!form.warehouse_id"
                                            color="#497e7e"
                                            class="mx-2"
                                            small
                                            @click="addItems"
                                        >
                                            Add
                                        </v-btn>
                                        <v-btn
                                            color="#ef5350"
                                            small
                                            @click="clearForm"
                                        >
                                            Clear
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            <!-- </div> -->
                            <v-simple-table dense class="dynamic_list_table" style="overflow-y: scroll">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-center mx-2" width="6%" v-if="['DLR'].includes(boundType)">PO#</th>
                                            <th class="text-center mx-2" width="17%" v-if="!['DLR'].includes(boundType)">Created by</th>
                                            <th class="text-center mx-2" width="17%" v-if="!['DLR'].includes(boundType)">Created Date</th>
                                            <th class="text-center mx-2" width="17%">Item</th>
                                            <th class="text-center mx-2" width="6%">Qty</th>
                                            <th class="text-center mx-2" width="6%" v-if="!['IOTH', 'DLR'].includes(boundType)">Ref Doc</th>
                                            <th class="text-center mx-2" width="10%" v-if="['DLR'].includes(boundType)">Price</th>
                                            <th class="text-center mx-2" width="10%" v-if="['DLR'].includes(boundType)">Total Price</th>
                                            <th class="text-center mx-2" width="3%"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <span v-for="(item, i) in  form.item_list" :key="i" class="d-contents">
                                            <tr>
                                                <td v-if="['DLR'].includes(boundType)">
                                                    <v-text-field
                                                        v-model="item.po_num"
                                                        class="d-inline-block mt-2 centered-input"
                                                        style="width:100%"
                                                        background-color="grey lighten-2"
                                                        placeholder="PO#"
                                                        color="black"
                                                        outlined
                                                        readonly
                                                        dense
                                                        hide-details
                                                        :disabled="disabled"
                                                    ></v-text-field>
                                                </td>
                                                <td  v-if="!['DLR'].includes(boundType)">
                                                    <v-text-field
                                                        v-model="item.created_by"
                                                        class="d-inline-block mt-2 centered-input"
                                                        style="width:100%"
                                                        background-color="grey lighten-2"
                                                        placeholder=""
                                                        color="black"
                                                        outlined
                                                        readonly
                                                        dense
                                                        hide-details
                                                        :disabled="disabled"
                                                    ></v-text-field>
                                                </td>
                                                <td  v-if="!['DLR'].includes(boundType)">
                                                    <v-text-field
                                                        v-model="item.created_at"
                                                        class="d-inline-block mt-2 centered-input"
                                                        style="width:100%"
                                                        background-color="grey lighten-2"
                                                        placeholder=""
                                                        color="black"
                                                        outlined
                                                        dense
                                                        readonly
                                                        hide-details
                                                        :disabled="disabled"
                                                    ></v-text-field>
                                                </td>
                                                <td>
                                                    <v-autocomplete
                                                        v-model="item.id"
                                                        :items="admin_items"
                                                        :disabled="disabled"
                                                        :readonly="!item.item_edittable"
                                                        style="width:100%"
                                                        item-text="item_name"
                                                        item-value="admin_item_id"
                                                        placeholder=""
                                                        :background-color="item.item_edittable ? '' : 'grey lighten-2'"
                                                        color="black"
                                                        class="d-inline-block mt-2 centered-input"
                                                        auto-select-first
                                                        hide-details
                                                        dense
                                                        outlined
                                                        @paste="onPaste"
                                                    ></v-autocomplete>
                                                </td>
                                                <td>
                                                    <v-text-field
                                                        v-model="item.receive_qty"
                                                        :disabled="disabled && !['DLR'].includes(boundType)"
                                                        class="alignText centered-input"
                                                        hide-details
                                                        :readonly="!item.item_edittable"
                                                        dense
                                                        outlined
                                                        @paste="onPaste"
                                                        @change="updatePrice(item)"
                                                    ></v-text-field>
                                                </td>
                                                <td v-if="!['IOTH', 'DLR'].includes(boundType)">
                                                    <v-text-field
                                                        v-model="item.ref_doc"
                                                        style="width:100%"
                                                        placeholder=""
                                                        color="black"
                                                        class="d-inline-block mt-2 centered-input"
                                                        hide-details
                                                        dense
                                                        outlined
                                                    ></v-text-field>
                                                </td>
                                                <td v-if="['DLR'].includes(boundType)">
                                                    <v-text-field
                                                        v-model="item.price"
                                                        :readonly="!item.item_edittable"
                                                        style="width:100%"
                                                        placeholder="Price"
                                                        color="black"
                                                        class="d-inline-block mt-2 centered-input"
                                                        hide-details
                                                        dense
                                                        outlined
                                                        @keypress="numberandDotOnly"
                                                        @change="updatePrice(item)"
                                                    ></v-text-field>
                                                </td>
                                                <td v-if="['DLR'].includes(boundType)">
                                                   <v-text-field
                                                       v-model="item.total_price"
                                                       style="width:100%"
                                                       placeholder="Total Price"
                                                       background-color="grey lighten-2"
                                                       color="black"
                                                       class="d-inline-block mt-2 centered-input"
                                                       hide-details
                                                       readonly
                                                       dense
                                                       outlined
                                                   ></v-text-field>
                                                </td>
                                                <td class="text-center">
                                                   <v-btn
                                                       style="height:20px; width:20px;"
                                                       color="primary"
                                                       fab
                                                       dark
                                                       small
                                                       @click="removeIndex(i)"
                                                   >
                                                        <v-icon dark small style="font-size:12px">mdi-minus</v-icon>
                                                    </v-btn>
                                                </td>
                                            </tr>
                                        </span>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        <!-- </v-card> -->
                    </v-card-text>
                    <v-card-actions>
                            <div class="d-flex w-100 justify-center p-2">
                                <v-btn @click="submitInboundOutbound()" :disabled="submitLoading || form.item_list.every(e => e.total_price == '')">Submit</v-btn>
                            </div>
                    </v-card-actions>
                <!-- </v-card> -->
            </v-card>
            <AdminReleaseFormTemplateComponentV2/>
        </v-app>
    </div>
</template>

<script>
import swal from "sweetalert2";
import { mapGetters } from 'vuex';
import FileUploadComponentVue from '@/views/main/Shared/FileUploadComponent.vue'
import AdminReleaseFormTemplateComponentV2 from "@/views/main/layouts/PrintTemplate/AdminReleaseFormTemplateComponentV2.vue";
import * as XLSX from 'xlsx';
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
import Decimal from 'decimal.js';



export default {
    mixins: [SharedFunctionsComponentVue,],
    props:['boundType'],
    data() {
        return {
            form:{
                customer_id: '',
                po_num: '',
                warehouse_id: '',
                admin_purchase_order_id: '',
                type: '',
                remarks: '',
                arf_num: '',
                item_list: [
                    {
                        id: '',
                        receive_qty: '',
                        created_at:this.$dayjs().format('YYYY-MM-DD'),
                        created_by: '',
                        item_edittable: 1,
                        price: '',
                        total_price: '',
                    }
                ],
            },
            result: [],
            uploadedFiles: null,
            customers: [],
            file: null,
            admin_items: [],
            submitLoading: false,
            disabled: true,
            filter:{
                search:"",
                location:"",
                employee_data_id:'',
                category_type: '',
                warehouse: '',
                classification: '',
                department: "",
                deliver_to: "",
                deliver_to_address: ""
            },
            dropdowns:{
                po_selection: []
            },
        };
    },
    components: {
        FileUploadComponentVue,
        AdminReleaseFormTemplateComponentV2
    },
    mounted(){
        // this.$store.dispatch('getPRFAdminItems').then(response => {
        //     this.admin_items = response;
        //     if(this.admin_items.length > 0){
        //         this.disabled = false
        //     }
        // });
        // this.$store.dispatch('getEmployeeDetails');
        this.$store.dispatch('getAdminWarehouseCodeDropdown');
    },
    computed:{
        ...mapGetters([
            'USERACCOUNT_LOAD',
            'SELECTED_TAB',
            // 'EMPLOYEE_DATA',
            'GET_ADM_WAREHOUSE_CODE_DROPDOWN',
            'GET_ADMIN_LOCATION',
            'GET_LIST_DEPARTMENT',
            'DIALOGS',
            'GET_INBOUND_OUTBOUND_TYPE',
            'GET_ADMIN_PO_SELECTION',
        ]),
    },
    methods:{
        triggerFileInput() {
            this.$refs.fileInput.$refs.input.click();
        },
        clearForm(){
            swal.fire({
                icon: "question",
                text: `Do you want to Clear all Items?`,
                cancelButtonText: "Cancel",
                showCancelButton: true,
                allowOutsideClick: false,
                cancelButtonColor: "grey",
                reverseButtons: true
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.form.item_list = [
                        {
                            id: '',
                            receive_qty: '',
                            created_at:this.$dayjs().format('YYYY-MM-DD'),
                            created_by: '',
                            po_num: '',
                            price: '',
                            total_price:'',
                            item_edittable: 1,
                        }
                    ]
                    this.file = null
                }
            });

        },
        submitInboundOutbound(){
            if(this.boundType == 'IOTH' || this.boundType == 'OOTH'){

                const warehouse_id_swal_text = !this.form.warehouse_id ? "Warehouse" : "";
                // const reference_swal_text = !this.form.po_num ? "Reference #" : "";
                const remark_swal_text = !this.form.remarks ? "Remarks" : "";
                const concatenatedArray = [warehouse_id_swal_text, remark_swal_text].filter(text => text !== "");

                const filtered = concatenatedArray.filter(function(el) {
                    return el;
                });

                const all_ref_doc = this.form.item_list.filter(text => text.ref_doc == "" || text.ref_doc == null);

                let get_item_name = [];
                all_ref_doc.map((e) => {
                    get_item_name.push(e.name)
                })

                if(this.boundType == 'OOTH'){
                    const deliver_to_swal_text = !this.filter.deliver_to ? "Deliver To" : "";
                    const deliver_to_address_swal_text = !this.filter.deliver_to_address ? "Deliver To Address" : "";
                    const concatenatedArray = [deliver_to_swal_text, deliver_to_address_swal_text].filter(text => text !== "");

                    if (concatenatedArray.length > 0) {
                        swal.fire("Missing Fields", concatenatedArray.join(", "), "warning");
                        return;
                    }

                    if(get_item_name.length > 0){
                        swal.fire("Missing Ref Doc", get_item_name.join(", "), "warning");
                        return;
                    }
                }

                if (filtered.length > 0) {
                    swal.fire("Missing Fields", filtered.join(", "), "warning");
                    return;
                }

                if (this.form.item_list.length == 0) {
                    swal.fire("Empty Items",'', "warning");
                    return;
                }
            }

            swal.fire({
                icon: "question",
                text: `Do you want to Submit this ${this.boundType == 'IOTH' ? 'Inbound' : 'Outbound'}?`,
                cancelButtonText: "Cancel",
                showCancelButton: true,
                allowOutsideClick: false,
                cancelButtonColor: "#grey",
                reverseButtons: true
            })
            .then((result) => {
                if (result.isConfirmed) {
                    if(this.boundType == 'IOTH'){
                        let payload = {
                            form: this.form,
                            filter: this.filter,
                            inbound_outbound_type_id: this.GET_INBOUND_OUTBOUND_TYPE.find(e => e.code == this.boundType).value,
                            transact_type_model:'inboundItems',
                            type: this.SELECTED_TAB,
                            url: 'createInboundOutbound'
                        }
                        this.$store.dispatch("adminInboundOutboundPost",payload).then(response => {
                            swal.fire('Added Successfully!','','success');
                            this.submitLoading = false;
                            this.resetField();
                            this.$store.commit('DIALOG', false);
                        });
                    }else if(this.boundType == 'DLR'){
                        this.$store.dispatch('adminDeliveryReceiptPost', {
                            url: 'add-admin-delivery-receipt',
                            reference_number: this.form.arf_num,
                            delivery_to: this.filter.deliver_to,
                            delivery_address: this.filter.deliver_to_address,
                            inbound_outbound_type_id: this.GET_INBOUND_OUTBOUND_TYPE.find(e => e.code == this.boundType).value,
                            type: 2,
                            ...this.form
                        }).then((response)=>{
                            swal.fire('Success', '', 'success')
                            this.resetField();
                            this.$store.commit('DIALOG', false);
                        })
                    } else {
                        let transact_type_model = '';
                        if(this.boundType == 'DLR'){
                            transact_type_model = 'dealer';
                        } else {
                            transact_type_model = 'outboundItems';
                        }
                        let payloads = {
                            location_id: this.filter.location,
                            department: this.filter.department,
                            admin_accountability: this.form.item_list,
                            admin_warehouse_id: this.form.warehouse_id,
                            inbound_outbound_type_id:this.GET_INBOUND_OUTBOUND_TYPE.find(e => e.code == this.boundType).value,
                            transact_type_model:transact_type_model,
                            type: this.SELECTED_TAB,
                            url: 'addAdminReleaseForm',
                            arf_num: this.form.arf_num,
                            remarks: this.form.remarks,
                            customer: this.form.customer,
                            form: this.form,
                            filter: this.filter
                        }
                        this.$store.dispatch("addAdminReleaseForm",payloads).then(response => {
                            swal.fire('Added Successfully!','','success');
                            this.submitLoading = false;
                            this.form.item_list.forEach((e) => {
                                e.arf_num = response.arf_num
                            })
                            const departmentObject = this.GET_LIST_DEPARTMENT.find(e => e.value == this.filter.department);
                            const departmentText = departmentObject ? departmentObject.text : this.filter.deliver_to_address;
                            const adminLocationObject = this.GET_ADMIN_LOCATION.find(e => e.value == this.filter.location);
                            const adminLocationText = adminLocationObject ? adminLocationObject.text : this.filter.deliver_to;

                            let payload_for_print = {
                                department: departmentText,
                                location: adminLocationText,
                                admin_accountabilityitem: this.form.item_list,
                                types: 'others',
                                image: response.image,
                                prepared_by: this.USERACCOUNT_LOAD.name,
                                arf_details: {
                                    ...response.arf_details,
                                },
                                transaction_number: response.arf_num
                            }

                            this.$store.commit('PRINT_ADMIN_ARF', true);
                            this.$store.commit('PRINT_ADMIN_ARF_DETAIL',payload_for_print);
                            this.resetField();
                            this.$store.commit('DIALOG', false);
                            });
                    }
                    this.submitLoading = false;
                }
                this.submitLoading = false;
            });
            this.submitLoading = false;
        },
        resetField(){
            this.form = {
                customer_id: '',
                po_num: '',
                warehouse_id: '',
                admin_purchase_order_id: '',
                remarks: '',
                type: '',
                arf_num: '',
                item_list: [
                    {
                        id: '',
                        receive_qty: '',
                        created_at:this.$dayjs().format('YYYY-MM-DD'),
                        created_by: '',
                        receive_qty: 0,
                        price: 0,
                        total_price: 0,
                        item_edittable: 1,
                    }
                ],
            },
            this.result = []
            this.uploadedFiles = null
            this.file = null
            this.admin_items = []
            this.customers = []
            this.submitLoading = false
            this.disabled = true
            this.filter = {
                search:"",
                location:"",
                employee_data_id:'',
                category_type: '',
                warehouse: '',
                classification: '',
                department: "",
                deliver_to: "",
                deliver_to_address: ""
            }
        },
        handleFileUpload(event) {
            const file = event;
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: 'array' });
                    const firstSheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[firstSheetName];
                    const jsonData = XLSX.utils.sheet_to_json(worksheet, {
                        header: 1,
                        defval: '',
                        blankrows: false,
                        range: 1
                    });

                    this.result = jsonData;
                    this.form.item_list = [];
                    const notFoundItems = [];
                    jsonData.map((e) => {
                        let item = this.admin_items.find(item => item.item_name === e[0]);
                        if (item) {
                            this.form.item_list.push({
                                id: item.admin_item_id,
                                receive_qty: e[1],
                                created_at: this.$dayjs().format('YYYY-MM-DD'),
                                created_by: this.USERACCOUNT_LOAD.name,
                                accountability_num_without_category: 'N/A',
                                accountability_num: 'N/A',
                                name: e[0],
                                item_id: item.admin_item_id,
                                admin_items_id: item.admin_item_id,
                                item_category: 0,
                                warehouse_item_id: 0,
                                quantity: e[1],
                                uom: 'N/A',
                            });
                        } else {
                            notFoundItems.push(e[0]);
                        }
                    });
                    if (notFoundItems.length > 0) {
                        swal.fire({
                            title: 'Items Not Found',
                            text: `The following items were not found: ${notFoundItems.join(', ')}`,
                            icon: 'warning',
                            confirmButtonText: 'Ok'
                        });
                    }
                };
                reader.readAsArrayBuffer(file);
            } else {
                console.log('No file selected');
            }
        },
        removeIndex(index){
            if(this.form.item_list.length > 1){
                this.form.item_list.splice(index, 1);
            }
        },
        async onPaste(evt){
            evt.preventDefault();
            let pastedText = evt.clipboardData.getData("Text");
            if (pastedText.search(/[\r\n][\n]/g)) {
                this.result = []
                this.form.item_list = [];
                let rows = pastedText.split("\r\n");
                const notFoundItems = [];
                for (let y = 0; y < rows.length; y++) {
                    let cells = rows[y].split("\t");
                    if (cells[0] !== '') {
                        let item = this.admin_items.find(item => item.item_name === cells[0]);
                        if (item) {
                            this.result.push({
                                id: item.admin_item_id,
                                receive_qty: cells[1],
                                created_at:this.$dayjs().format('YYYY-MM-DD'),
                                created_by: this.USERACCOUNT_LOAD.name,
                                accountability_num_without_category: 'N/A',
                                accountability_num: 'N/A',
                                name: cells[0],
                                item_id: item.admin_item_id,
                                admin_items_id: item.admin_item_id,
                                item_category: 0,
                                warehouse_item_id: 0,
                                quantity: cells[1],
                                uom: 'N/A',
                            });
                        } else {
                            notFoundItems.push(cells[0]);
                        }
                    }
                }
                if (notFoundItems.length > 0) {
                    swal.fire({
                        title: 'Items Not Found',
                        text: `The following items were not found: ${notFoundItems.join(', ')}`,
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    });
                }
            } else {
                swal.fire('', 'Pasted Data format is not supported!', 'warning')
                return
            }
            this.form.item_list = this.result;
        },

        getPOitems(po_id){
            this.$store.dispatch('adminInboundOutboundGet', {
                url: `get-po-items-by-outbound/${po_id}`,
            }).then((response) => {
                this.form.item_list = [];
                response.forEach(e =>{
                   this.form.item_list.push({
                        id: e.admin_item_id,
                        po_num: e.po_num,
                        receive_qty: parseInt(e.received_qty),
                        price: parseFloat(e.price),
                        total_price: parseFloat(e.total_price),
                        item_edittable: 1,
                        admin_purchase_order_item_id: e.admin_purchase_order_item_id
                    })
                })
            })
        },
        addItems(){
            this.form.item_list.push({
                id: null,
                po_num:'',
                receive_qty: 0,
                price: 0,
                total_price: 0,
                item_edittable: 1,
            })
        },
        updatePrice(item){

            if(item.price && item.receive_qty){
                let quantity = Decimal(parseFloat(item.receive_qty))
                let price = Decimal(parseFloat(item.price))
                item.total_price = parseFloat((quantity).times(price))
            }

            this.$forceUpdate();
        },
    },
    watch: {
        DIALOGS: {
            handler(val){
                if(val && (this.boundType == 'IOTH' || this.boundType == 'OOTH')){
                    this.$store.dispatch('getPRFAdminItems').then(response => {
                        this.admin_items = response;
                        if(this.admin_items.length > 0){
                            this.disabled = false
                        }
                    });
                    this.$store.dispatch('getDepartment');
                    this.$store.dispatch('getAdminLocationSelection');
                }
                if(val && this.boundType == 'DLR'){
                    this.$store.dispatch('getPRFAdminItems').then(response => {
                        this.admin_items = response;
                        if(this.admin_items.length > 0){
                            this.disabled = false
                        }
                    });
                    this.$store.dispatch('getAllCustomers').then(response =>{
                        this.customers = response;
                    });
                }
            },
            deep: true,
            immediate: true
        },
        'form.customer_id':{
            async handler(val){
                if(val){
                    await this.$store.dispatch('getAdminPoSelection', {
                        customer_id: val,
                    })
                }
            }
        },
        'form.warehouse_id':{
            async handler(val){
                if(val && this.boundType == 'DLR'){
                    await this.$store.dispatch('adminInboundOutboundGet', {
                        url: 'get-items-on-warehouse-item-stocks',
                        warehouse_id: val,
                    }).then((response) =>{
                        this.admin_items = response
                    })
                }
            }
        }
    }
}
</script>

<style scope>
    .items-section h3 {
        font-weight: bold;
        margin-bottom: 0;
    }

    .items-section v-divider {
        margin-top: -10px;
    }
</style>
