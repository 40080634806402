<template>
    <div style="text-align:end">
        <v-btn @click="showExportDialog()">Excel</v-btn>

        <v-dialog v-model="showExportExcelDialog" persistent max-width="40%" scrollable>
            <v-card>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">Choose Date Range</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeExportDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider />
                <v-layout class="pa-2">
                    <v-flex lg6 class="mr-2">
                        <v-dialog
                            ref="dateStartDialog"
                            v-model="startDateModal"
                            :return-value.sync="startDate"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="startDate"
                                label="Date Start"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="startDate"
                                scrollable
                                :max="minDate"
                            >
                            <v-spacer />
                            <v-btn
                                text
                                color="primary"
                                @click="startDateModal = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.dateStartDialog.save(startDate)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-flex>

                    <v-flex lg6>
                        <v-dialog
                            ref="endDateDialog"
                            v-model="endDateModal"
                            :return-value.sync="endDate"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="endDate"
                                label="Date End"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            />
                            </template>
                            <v-date-picker
                                v-model="endDate"
                                scrollable
                                :max="minDate"
                            >
                            <v-spacer />
                            <v-btn
                                text
                                color="primary"
                                @click="endDateModal = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.endDateDialog.save(endDate)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-flex>
                </v-layout>
                <v-divider />
                <div style="text-align:center" class="pb-4">
                    <v-btn @click="exportExcel()">Export Excel</v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showExportExcelDialog: false,
            startDateModal: false,
            endDateModal: false,
            startDate: '',
            endDate: '',
            minDate: this.$dayjs().format('YYYY-MM-DD'),
        }
    },
    props: ['status'],
    methods: {
        showExportDialog() {
            this.showExportExcelDialog = true;
        },
        closeExportDialog() {
            this.showExportExcelDialog = false;
            this.startDate = '';
            this.endDate = '';
        },
        exportExcel() {
            window.open(`/service/exportRepairForms/?start_date=${this.startDate}&end_date=${this.endDate}&status=${this.status}`, '_blank');
        }
    }
}
</script>

<style>
</style>
