<template>
    <v-card color="#EBEBEB" style="border:0px" elevation="0" width="100%" :disabled="disabled" >
        <v-row class="overflow-auto" :style="selection ? 'height: 650px;' : ''"  v-if="!loading">
            <v-col v-for="(tech,index) in technicians" :key="tech.id" lg="4" class="d-flex justify-content-center align-items-center" >
                <v-card class="pa-4 mr-2 mb-2 d-flex align-items-between justify-content-between flex-column" lg4 width="98%" height="97%" shaped>
                    <v-card-text >
                        <v-row>
                            <v-col>
                                <div>
                                    <img width="150px" height="150px" :src="image(tech)" style="object-fit: cover"/>
                                </div>
                            </v-col>
                            <v-col>
                                <div v-if="!selection">Level: <span style="color:blue">{{ tech.level }}</span></div>
                                <div>Name: <span style="color:blue">{{ tech.name }}</span></div>
                                <div>Specialties: <span style="color:blue">{{ specialties(tech) }}</span></div>
                                <div v-if="!selection">Skills: <span style="color:blue">{{ tech.skills }}</span></div>
                                <div>Accuracy: <span style="color:blue">{{ tech.accuracy }}%</span></div>
                                <div>TAT: <span style="color:blue">{{ tech.turn_around_time }}%</span></div>
                                <div>Pending: <span style="color:blue">{{ tech.total_pending }}</span></div>

                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-content-between">
                        <v-checkbox v-model="tech.selected" false-value="0" true-value="1" class="float-right" @change="checkSelected(index)" v-if="selection">

                        </v-checkbox>
                        <span>
                            <v-btn text icon color="red">
                                <v-icon color="orange" small @click="showTechProfileDialog(tech.id)">mdi-eye</v-icon>
                            </v-btn>
                            <v-btn text icon color="blue">
                                <v-icon class="btn-action" small @click="editTechnician(tech.id)">mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn text icon color="red" >
                                <v-icon class="btn-action" small @click="deleteTechnician(tech.id)">mdi-delete</v-icon>
                            </v-btn>
                        </span>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <!-- <v-row style="position:fixed; width:inherit;" > -->
            <v-row v-if="loading">
                <v-col lg="12" class="d-flex justify-content-center align-items-center">
                    <LoadingComponentVue class="my-2"></LoadingComponentVue>
                </v-col>
            </v-row>

        <v-row v-if="selection">
            <v-col lg12 class="d-flex justify-content-center align-items-center">
                <v-card class="w-100" elevation="0" color="#EBEBEB">
                    <v-card-text class="w-100 d-flex justify-content-center align-items-center">
                        <v-btn @click="selected()">
                            select
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <AddTechnicianComponent :parentComponent="'technicianCard'" />
        <TechnicianProfileDialogComponentVue :id="id" ref="techDialog" @closeTechProfile="closeTechProfile"/>
    </v-card>
</template>

<script>
import AddTechnicianComponent from '@/views/main/modules/Services/Views/TechnicianManagerComponent/AddTechnicianComponent.vue'
import ViewportCheckerComponentVue from '@/views/main/Shared/ViewportCheckerComponent.vue';
import TechnicianProfileDialogComponentVue from '../TechnicianManagerComponent/TechnicianProfileDialogComponent.vue';
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
import LoadingComponentVue from './LoadingComponent.vue';

export default {
    mixins: [ViewportCheckerComponentVue],
    props:{
        selection:{
            default:()=>false,
            type:Boolean
        },
        reRender:{
            default:()=>false,
            type:Boolean
        },
        rfId:{
            default:()=>null,
            type:Number
        }
    },
    data() {
        return {
            technicians: [],
            id: '',
            selectedTechnicians:[],
            disabled:false,
            loading:false,
            technician: null,

        }
    },
    mounted() {
        this.getTechnicians();
    },
    components: {
        TechnicianProfileDialogComponentVue,
        LoadingComponentVue,
        AddTechnicianComponent
    },
    computed: {
        ...mapGetters([
            'GET_TECHNICIANS',
            'GET_SERVICE_USERS',
            'GET_TECHNICIAN',

        ]),
        addEditSuccess() {
            return this.$store.state.service.technician.success;
        },
        technicianId() {
            return this.$store.state.service.technician.id;
        }
    },
    watch: {
        addEditSuccess() {
            if(this.addEditSuccess) {
                this.getTechnicians();
            }
        },
        technicianId() {
            if(!!this.technicianId) {
                this.$store.dispatch('getTechnician',this.technicianId).then(response=>{
                    this.getItemCategories();
                    this.getUsers();

                    this.technician = this.GET_TECHNICIAN;
                });
            }
        },
    },
    methods: {
        async getTechnicians() {
            this.loading = true
            await this.$store.dispatch('getTechnicians').then(async response=>{

                await this.$store.dispatch('serviceGet',{
                    url:'technician-accuracy',
                    ids:this.GET_TECHNICIANS.map(e=>e.id)
                }).then(response=>{
                    this.GET_TECHNICIANS.forEach(e=>{
                        e.accuracy = response.data.find(x=>x.id == e.id)?.accuracy ?? 0
                        e.turn_around_time = response.data.find(x=>x.id == e.id)?.turn_around_time ?? 0
                        e.total_pending = response.data.find(x=>x.id == e.id)?.total_pending ?? 0
                        
                    })
                }).catch(err=>{
                    console.log(err)
                })

                this.technicians = this.GET_TECHNICIANS;
            });
            this.loading = false
        },
        specialties(tech) {
            if(!!tech) {
                return JSON.parse(tech.specialties).toString().replaceAll(',', ', ');
            }

            return '';
        },
        showTechProfileDialog(id) {
            this.id = id;
            this.$refs.techDialog.showTechProfileDialog();
        },
        closeTechProfile(confirm) {
            if(confirm) {
                this.id = '';
            }
        },
        image(tech) {
            if(!!tech) {

                if(tech.sc_technician_images.length > 0) {

                    return process.env.VUE_APP_API_HOST +  tech.sc_technician_images[0].path;
                }
            }

            return process.env.VUE_APP_API_HOST+'/images/noimage.png';
        },
        editTechnician(id) {
            this.$store.commit('setTechnicianId', id);
            this.$store.commit('showTechnicianDialog');
        },
        closeAddTechnicianComponent() {

        },
        deleteTechnician(id) {
            swal.fire({
                title: 'Are you sure you want to delete technician?',
                text: 'Make sure that all information are correct',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then((result)=>{
                if(result.isConfirmed) {
                    this.$store.dispatch('deleteTechnician',id).then(response=>{
                        if(response.data.error) {
                            swal.fire("", response.data.error, "error");
                        } else {
                            this.getTechnicians();
                            swal.fire("", response.data.msg, "success");
                        }
                    });
                }
            });
        },
        checkSelected(index){

            this.technicians = this.technicians.map((e,i)=>{
                if(index == i){
                    e.selected = '1'
                }else{
                    e.selected = '0'
                }
                return e
            })
        },
        selected(){
            this.disabled = true
            this.loading = true
            this.$emit('selected',this.technicians.filter(e=>e.selected == '1')[0]);
            this.$store.dispatch('servicePost',{
                technicianId : this.technicians.filter(e=>e.selected == '1')[0].id,
                rfId:this.rfId,
                url:'assignTechnician',
                updateRf:0
            }).then(response=>{
                swal.fire('Success!','','success')
                Object.assign(this.$data, this.$options.data.call(this));
                this.$emit('success',true)
            }).catch(err=>{
                console.log(err)
                swal.fire('Error!','','error')
                Object.assign(this.$data, this.$options.data.call(this));
            })
        },
        getUsers(){
            this.$store.dispatch('getServiceUsers').then(response => {
                this.users = this.GET_SERVICE_USERS;
            });
        },
        getItemCategories(){
            this.$store.dispatch('getItemCategories').then(response => {
                this.itemCategories = response.data
            });
        },
    }
}
</script>

<style>

</style>
