<template>
    <v-card elevation="0" rounded="0" class="p-3">
        <v-card-title class="d-flex p-0 m-0">
            <div class="w-100 p-0 m-0 d-flex flex-column">
                <v-row class="pl-2 pt-2">
                    <v-col lg="2">
                        <v-autocomplete
                            v-model="filters.vendor_id"
                            :items="GET_ADMIN_SUPPLIER_SELECTION"
                            label="Vendor"
                            auto-select-first
                            hide-details
                            clearable
                            dense
                            outlined
                        />
                    </v-col>
                    <v-col lg="2">
                        <v-autocomplete
                            v-model="filters.company_id"
                            :items="GET_PV_COMPANIES_SELECTION"
                            label="Company"
                            auto-select-first
                            hide-details
                            clearable
                            dense
                            outlined
                        />
                    </v-col>
                    <v-col lg="2">
                        <v-autocomplete
                            v-model="filters.store_branches_id"
                            :items="GET_PV_STORE_BRANCHES_SELECTION"
                            no-data-text="No Stores Found"
                            label="Store Branch"
                            auto-select-first
                            hide-details
                            clearable
                            dense
                            outlined
                        />
                    </v-col>
                    <v-col lg="1" class="pt-1">
                        <v-btn
                            @click="list()"
                        >
                            Filter
                        </v-btn>
                    </v-col>
                    <v-col lg="5" class="d-flex justify-end pr-6">
                        <v-btn @click="exportWFR('export')">
                            EXPORT
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="pl-2">
                    <v-col cols="2"  v-if="!this.module">
                        <v-autocomplete
                            v-model="transact_id"
                            :items="GET_TRANSACT_TYPES_FOR_WFR"
                            label="Transact Type"
                            clearable
                            dense
                            outlined
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-col lg="2">
                        <v-autocomplete
                            v-model="filters.payment_method"
                            :items="transact_type == 'Admin Purchase Order' ? STATUS.ADMIN_PURCHASE_ORDER_PAYMENT_METHOD : STATUS.ADMIN_JOB_ORDER_PAYMENT_METHOD"
                            no-data-text="No Payment Method Found"
                            label="Payment method"
                            auto-select-first
                            hide-details
                            clearable
                            dense
                            outlined
                        />
                    </v-col>
                    <v-col :lg="module ? '2' : '1'">
                        <v-autocomplete
                            v-model="filters.status"
                            :items="statusBasedOnTransactTypes"
                            no-data-text="No Status Found"
                            label="Status"
                            auto-select-first
                            hide-details
                            clearable
                            dense
                            outlined
                        />
                    </v-col>
                    <v-col :lg="module ? '2' : '1'">
                        <v-autocomplete
                            v-model="filters.hasEnterBill"
                            :items="hasEnterBillDropdown"
                            no-data-text="No EnterBill Found"
                            label="Has EnterBill"
                            auto-select-first
                            hide-details
                            clearable
                            dense
                            outlined
                        />
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col lg="4" class="d-flex justify-end">
                        <v-card-subtitle>
                            <v-row>
                                <v-col cols="1" class="d-flex align-items-center pr-4 pt-2">
                                    <span class="badge bg-dark ml-1 text-white" style="cursor:pointer" @click="status('')">All</span>
                                    <span class="badge bg-primary ml-1 text-white" style="cursor:pointer" @click="status('1')">Paid</span>
                                    <span class="badge bg-danger ml-1 text-white" style="cursor:pointer" @click="status('0')">Unpaid</span>
                                </v-col>
                            </v-row>
                        </v-card-subtitle>
                    </v-col>
                </v-row>
                <v-row class="d-flex justify-end">
                    <v-col cols="2" class="d-flex">
                        <v-text-field v-model="filters.search" label="Search Transact#" single-line hide-details @keydown.enter="list()"></v-text-field>
                        <div class="pt-5 pr-2">
                            <v-icon @click="list()">mdi-magnify</v-icon>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-card-title>
        <v-data-table
            :headers="allHeader"
            :items-per-page.sync="form.itemsPerPage"
            :items="form.items"
            :loading="loading"
            :option.sync="filters.options"
            :page.sync="form.page"
            :server-items-length="form.totalItems"
            class="w-100 pt-6"
            dense
            loading-text="Loading ...Please wait"
            disable-sort
            :footer-props="{
                itemsPerPageOptions: itemsPerPageOptions,
            }"
        >
            <template v-slot:[`item.total_amount`]="{ item }">
                <span class="text-nowrap">
                    &#x20b1;{{ item.total_amount | currency }}
                </span>
            </template>
            <template v-slot:[`item.amount_to_pay`]="{ item }">
                <span class="text-nowrap">
                    &#x20b1;{{ item.amount_to_pay | currency }}
                </span>
            </template>
            <template v-slot:[`item.received`]="{ item }">
                    <span class="badge bg-primary text-white" v-if="item.received">Yes</span>
                    <span class="badge bg-danger text-white" v-else>No</span>
                </template>
            <template v-slot:[`item.paid`]="{ item }">
                <span class="badge bg-primary text-white" v-if="item.paid == 1">Yes</span>
                <span class="badge bg-danger text-white" v-else>No</span>
            </template>
            <template v-slot:[`item.action`]="{ item }">
                <span class="text-nowrap">
                    <v-btn text icon color="orange" @click="dialog('View',item)" v-if="userAccess.includes('view')">
                        <v-icon class="btn-action" small>mdi-eye</v-icon>
                    </v-btn>
                    <BaseFileViewerComponent
                        :access="true"
                        :payloadIds="[]"
                        :payloadId="item.pv_id ? item.pv_id : null"
                        :getFilesPath="'getPaymentBillFiles'"
                        :deleteFilesPath="false"
                        :module="'accounting'"
                        :folder="'paybills'"
                    ></BaseFileViewerComponent>
                </span>
            </template>
            <template v-slot:[`item.payment_method`]="{ item }">
                    <span :class="`badge ${transact_type == 'Admin Purchase Order' ? STATUS.ADMIN_PURCHASE_ORDER_PAYMENT_METHOD.find(e => e.value == 1).color : STATUS.ADMIN_JOB_ORDER_PAYMENT_METHOD.find(e => e.value == 1).color}`" v-if="item.payment_method === 1">
                        {{transact_type == 'Admin Purchase Order' ? STATUS.ADMIN_PURCHASE_ORDER_PAYMENT_METHOD.find(e => e.value == 1).text : STATUS.ADMIN_JOB_ORDER_PAYMENT_METHOD.find(e => e.value == 1).text}}
                    </span>
                    <span :class="`badge ${transact_type == 'Admin Purchase Order' ? STATUS.ADMIN_PURCHASE_ORDER_PAYMENT_METHOD.find(e => e.value == 2).color : STATUS.ADMIN_JOB_ORDER_PAYMENT_METHOD.find(e => e.value == 2).color}`" v-if="item.payment_method === 2">
                        {{transact_type == 'Admin Purchase Order' ? STATUS.ADMIN_PURCHASE_ORDER_PAYMENT_METHOD.find(e => e.value == 2).text : STATUS.ADMIN_JOB_ORDER_PAYMENT_METHOD.find(e => e.value == 2).text}}
                    </span>
                    <span :class="`badge ${transact_type == 'Admin Purchase Order' ? STATUS.ADMIN_PURCHASE_ORDER_PAYMENT_METHOD.find(e => e.value == 3).color : STATUS.ADMIN_JOB_ORDER_PAYMENT_METHOD.find(e => e.value == 3).color}`" v-if="item.payment_method === 3">
                        {{transact_type == 'Admin Purchase Order' ? STATUS.ADMIN_PURCHASE_ORDER_PAYMENT_METHOD.find(e => e.value == 3).text : STATUS.ADMIN_JOB_ORDER_PAYMENT_METHOD.find(e => e.value == 3).text}}
                    </span>
            </template>
        </v-data-table>
        <v-dialog v-model="WAITING_FOR_RECEIVE_DIALOG" width="80%" style="align-items: center;" persistent>
            <WaitingForReceivingViewComponent v-if="dialogType !== ''"/>
        </v-dialog>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import WaitingForReceivingViewComponent from '@/views/main/modules/Accounting/AccountsPayable/WaitingForReceivingViewComponent';
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
import BaseFileViewerComponent from "@/views/main/Shared/BaseFilesViewerComponent.vue"
import department from '@/store/modules/MasterData/department';

export default {
    props: ['module'],
    mixins: [SharedFunctionsComponentVue],
    components: {
        WaitingForReceivingViewComponent,
        BaseFileViewerComponent
    },
    mounted(){
        this.$store.dispatch(
        "checkAccessUser",
        this.$router.currentRoute.params.id
        );
        this.$store.dispatch("getTransactTypesForWFR");
        this.$store.dispatch("getPaymentVoucherBranchesSelection",{company_id: null});
        this.$store.dispatch('getPvCompanies')
        this.$store.dispatch('getAdminSupplierSelection')
        this.list(this.module);
    },
    data(){
        return {
            itemsPerPageOptions: [5, 10, 15, 50, 100],
            dialogType: '',
            dialogVisible: false,
            transact_id: '', // default to Purchase Order
            // transact_type: 'Admin Purchase Order', // default to Purchase Order
            transact_type: '', // default to Purchase Order
            form: {
                items: [],
                page: 1,
                itemsPerPage: 10,
                totalItems: 0,
            },
            ListHeaders: [
                // THIS IS PURCHASE ORDER
                { text: 'Transact #', value: 'trasact_number', align: 'left', header: 'Transact #', key: 'trasact_number' },
                { text: 'Total Amount', value: 'total_amount', align: 'right', header: 'Total Amount', key: 'total_amount', formatAs:'float', style: { numFmt: '#,##0.00' } },
                { text: 'Amount to pay', value: 'amount_to_pay', align: 'center',header: 'Amount to pay', key: 'amount_to_pay', formatAs:'float', style: { numFmt: '#,##0.00' } },
                // THIS IS PURCHASE ORDER
            ],
            ItemListHeaders: [],
            filters: {
                search: '',
                options: {},
                company_id: '',
                store_branches_id: '',
                vendor_id: '',
                payment_method: '',
                status: null,
                hasEnterBill: null,
            },
            paid: '',
            userAccess:[],
            loading: false,
            allHeader: [],
            allItemHeader: [],
            statusBasedOnTransactTypes: [],
            hasEnterBillDropdown:[
                {value: 1, text: 'Yes'},
                {value: 2, text: 'No'}
            ]
        };
    },
    computed:{
        ...mapGetters([
            "USER_ACCESS",
            "GET_PO_LIST",
            "ACTION",
            "SELECTED",
            "WAITING_FOR_RECEIVE_DIALOG",
            "GET_TRANSACT_TYPES_FOR_WFR",
            "GET_PV_COMPANIES_SELECTION",
            "GET_ADMIN_SUPPLIER_SELECTION",
            "GET_PV_STORE_BRANCHES_SELECTION",
            "PURCHASE_ORDER",
            "STATUS",
            "USERACCOUNT_LOAD"
        ])
    },
    methods: {
        paymentMethod(method){
            if(method == 1){
                return 'Payment First'
            } else if(method == 2){
                return 'Receive First'
            } else if(method == 3){
                return 'Partial Payment'
            } else{
                return 'Payment not Found'
            }
        },
        exportWFR(export_wfr){
            this.$store.dispatch("getWaitingForReceiving",{
                id: this.transact_type,
                export: export_wfr,
                search: this.filters.search,
                paid: this.paid,
                company_id: this.filters.company_id,
                store_branches_id: this.filters.store_branches_id,
                vendor_id: this.filters.vendor_id,
                payment_method: this.filters.payment_method,
                filters: this.filters
            }).then(async (response) => {
                let allItems = [];
                response.data.data.data.map((e) => {
                    let tmp_items = [];
                    tmp_items = e.items;
                    tmp_items.map((d) => {
                        allItems.push(d);
                    })
                })
                let header = {
                    [`${this.transact_type ? this.transact_type : 'SUMMARY'}`]: {
                        headers: this.allHeader,
                        data: response.data.data.data.map((e) => {
                            e.paid = parseInt(e.paid) === 0
                                    ? 'No' 
                                    : 'Yes';
                            e.received = parseInt(e.received) === 0
                                    ? 'No' 
                                    : 'Yes';
                            if (e.payment_method) {
                                e.payment_method = e.transact_type === 'Admin Purchase Order' 
                                    ? this.STATUS.ADMIN_PURCHASE_ORDER_PAYMENT_METHOD.find(pm => pm.value === e.payment_method)?.text 
                                    : this.STATUS.ADMIN_JOB_ORDER_PAYMENT_METHOD.find(pm => pm.value === e.payment_method)?.text ;
                            }
                            return e;
                        })
                    },
                    [`${this.transact_type ? this.transact_type + ' Items' : 'DETAILED'}`]: {
                        headers: this.allItemHeader,
                        data: allItems.map((e) => {
                            e.paid = parseInt(e.paid) === 0
                                    ? 'No' 
                                    : 'Yes';
                            return e;
                        })
                    }
                }
                await this.exportExcel(header, `Waiting For Releasing (${this.transact_type ? this.transact_type : 'ALL'})`)
            })
        },
        dialog(action, item){
            this.$store.commit('WAITING_FOR_RECEIVE_DIALOG', true);
            this.$store.commit('ACTION', action);
            this.$store.commit('SELECTED',item);
            this.dialogType = action;
            this.$store.commit('GET_MODULE_NAME', item.transact_type);
        this.dialogVisible = true;
        },
        status(status){
            this.paid = status
            this.list();
        },
        async list(){
            if(this.module){
                this.transact_type = this.module;
            }
            this.form.items = [];
            this.loading = true;
            await this.$store.dispatch("getWaitingForReceiving",{
                id: this.transact_type,
                page: this.form.page,
                itemsPerPage: this.form.itemsPerPage,
                search: this.filters.search,
                paid: this.paid,
                company_id: this.filters.company_id,
                store_branches_id: this.filters.store_branches_id,
                vendor_id: this.filters.vendor_id,
                payment_method: this.filters.payment_method,
                filters: this.filters
            }).then(response => {   
                    this.form.items = response.data.data.data
                    this.form.totalItems = response.data.total;
                    this.loading = false;
            }).catch(error => {
                console.error(error)
                this.loading = false;
            });
        },
    },
    watch: {
        'form.page'(val){
            this.list(val, this.form.itemsPerPage);
        },
        'form.itemsPerPage'(val){
            this.list(this.form.page, val);
        },
        'filters.option': {
            handler(val){
                this.list();
            }
        },
        USER_ACCESS:{
            handler(val){
             if(val != "fail")
                this.userAccess = val.map(e=>e.actions_code);
            }
        },
        transact_id: {
            handler(val,oldVal) {
                if(val == null){
                    this.transact_type = ''
                }else {
                    this.transact_type = this.GET_TRANSACT_TYPES_FOR_WFR.find(e=>e.value == this.transact_id) ? this.GET_TRANSACT_TYPES_FOR_WFR.find(e=>e.value == this.transact_id).text : ''
                    const formattedTransactType = this.transact_type.replace(/ /g, "_").toUpperCase() + '_STATUS';
                    let allTransactType = this.STATUS[formattedTransactType];
                    this.statusBasedOnTransactTypes = allTransactType.filter(e => e.value != 0);
                }
                if(val != oldVal){
                    this.form.page = 1;
                }
                this.list();
            },
            deep: true,
            immediate: true,
        },
        transact_type:{
            handler(val){
                switch(val) {
                    case 'Admin Purchase Order':
                        this.allHeader = this.ListHeaders.concat([
                            { text: 'Vendor', value: 'vendor_name', align: 'left', header: 'Vendor', key: 'vendor_name' },
                            { text: 'Company', value: 'company_name', align: 'left',header: 'Company', key: 'company_name' },
                            { text: 'Store Branch', value: 'store_branch_name', align: 'left',header: 'Store Branch', key: 'store_branch_name' },
                            { text: 'Location', value: 'location', align: 'left', header: 'Location', key: 'location' },
                            { text: 'Paid', value: 'paid', align: 'center', header: 'Paid', key: 'paid' },
                            { text: 'Received', value: 'received', align: 'center',header: 'Received', key: 'received', },
                            { text: 'Payment Method', value: 'payment_method', align: 'center',header: 'Payment Method', key: 'payment_method' },
                            {text: 'Actions', align: 'center', value: 'action'},
                        ])

                        this.allItemHeader = this.ItemListHeaders.concat([
                            { header: 'Transact Number', key: 'transact_number', align: 'left', sortable: false},
                            { header: 'Item Name', key: 'particulars', align: 'left', sortable: false},
                            { header: 'Price', key: 'price', align: 'right', sortable: false, formatAs:'float', style: { numFmt: '#,##0.00' }},
                            { header: 'Paid', key: 'paid', align: 'right', sortable: false },
                            { header: 'Total Amount', key: 'total_amount', align: 'right', sortable: false, formatAs:'float', style: { numFmt: '#,##0.00' }},
                            { header: 'Item Amount to Pay', key: 'item_amount_to_pay', align: 'right', sortable: false, formatAs:'float', style: { numFmt: '#,##0.00' }},
                            { header: 'Item Paid Amount', key: 'item_paid_amount', align: 'right', sortable: false, formatAs:'float', style: { numFmt: '#,##0.00' }},
                            { header: 'Receive Paid Amount', key: 'receive_paid_amount', align: 'right', sortable: false, formatAs:'float', style: { numFmt: '#,##0.00' }},
                            { header: 'Item Quantity', key: 'item_quantity', align: 'center', sortable: false, formatAs:'int'},
                            { header: 'Receive Quantity', key: 'receive_qty', align: 'center', sortable: false, formatAs:'int' },
                            { header: 'Item Pending Quantity', key: 'item_pending_qty', align: 'center', sortable: false, formatAs:'int' },
                        ])

                        break;
                    case 'Admin Job Order':
                        this.allHeader = this.ListHeaders.concat([
                            { text: 'Vendor', value: 'vendor_name', align: 'left', header: 'Vendor', key: 'vendor_name' },
                            { text: 'Company', value: 'company_name', align: 'left',header: 'Company', key: 'company_name' },
                            { text: 'Store Branch', value: 'store_branch_name', align: 'left',header: 'Store Branch', key: 'store_branch_name' },
                            { text: 'Location', value: 'location', align: 'left', header: 'Location', key: 'location' },
                            { text: 'Repair Type', value: 'repair_type', align: 'left', header: 'Repair Type', key: 'repair_type' },
                            { text: 'Other Repair Type', value: 'other_repair_type', align: 'left', header: 'Other Repair Type', key: 'other_repair_type' },
                            { text: 'Vehicle', value: 'vehicle', align: 'left', header: 'Vehicle', key: 'vehicle' },
                            { text: 'Paid', value: 'paid', align: 'center', header: 'Paid', key: 'paid' },
                            { text: 'Payment Method', value: 'payment_method', align: 'center',header: 'Payment Method', key: 'payment_method' },
                            {text: 'Actions', align: 'center', value: 'action'},
                        ])

                        this.allItemHeader = this.ItemListHeaders.concat([
                            { header: 'Transact Number', key: 'transact_number', align: 'left', sortable: false},
                            { header: 'Service', key: 'particulars', align: 'center', sortable: false },
                            { header: 'Total Amount', key: 'total_amount', align: 'right', sortable: false, formatAs:'float', style: { numFmt: '#,##0.00' } },
                            { header: 'Quantity', key: 'quantity', align: 'center', sortable: false },
                        ])

                        break;
                    case 'Transaction Journal':
                        this.allHeader = this.ListHeaders.concat([
                            { text: 'Vendor', value: 'vendor_name', align: 'left', header: 'Vendor', key: 'vendor_name' },
                            { text: 'Company', value: 'company_name', align: 'left',header: 'Company', key: 'company_name' },
                            { text: 'Store Branch', value: 'store_branch_name', align: 'left',header: 'Store Branch', key: 'store_branch_name' },
                            { text: 'Paid', value: 'paid', align: 'center', header: 'Paid', key: 'paid' },
                            {text: 'Actions', align: 'center', value: 'action'},
                        ])

                        this.allItemHeader = this.ItemListHeaders.concat([
                            { header: 'Transact Number', key: 'transact_number', align: 'left', sortable: false},
                            { header: 'COA', key: 'coa', align: 'center', sortable: false},
                            { header: 'Particular', key: 'particulars', align: 'center', sortable: false },
                            { header: 'Amount', key: 'amount', align: 'center', sortable: false, formatAs:'float', style: { numFmt: '#,##0.00' } },
                            { header: 'Recognition Month', key: 'recognition_month', align: 'center', sortable: false, formatAs:'date' },
                            { header: 'Department', key: 'department', align: 'center', sortable: false },
                            { header: 'Job', key: 'job', align: 'center', sortable: false },
                        ])

                        break;
                    case 'Service Payment Request':
                        this.allHeader = this.ListHeaders.concat([
                            { text: 'Vendor', value: 'vendor_name', align: 'left', header: 'Vendor', key: 'vendor_name' },
                            { text: 'Company', value: 'company_name', align: 'left',header: 'Company', key: 'company_name' },
                            { text: 'Store Branch', value: 'store_branch_name', align: 'left',header: 'Store Branch', key: 'store_branch_name' },
                            { text: 'Paid', value: 'paid', align: 'center', header: 'Paid', key: 'paid' },
                            {text: 'Actions', align: 'center', value: 'action'},
                        ])

                        this.allItemHeader = this.ItemListHeaders.concat([
                            { header: 'Transact Number', key: 'transact_number', align: 'left', sortable: false},
                            { header: 'COA', key: 'coa', align: 'center', sortable: false},
                            { header: 'Particular', key: 'particulars', align: 'center', sortable: false },
                            { header: 'Amount', key: 'amount', align: 'center', sortable: false, formatAs:'float', style: { numFmt: '#,##0.00' } },
                            { header: 'Recognition Month', key: 'recognition_month', align: 'center', sortable: false, formatAs:'date' },
                        ])

                        break;
                    case 'Recurring Bill':
                        this.allHeader = this.ListHeaders.concat([
                            { text: 'Vendor', value: 'vendor_name', align: 'left', header: 'Vendor', key: 'vendor_name' },
                            { text: 'Company', value: 'company_name', align: 'left',header: 'Company', key: 'company_name' },
                            { text: 'Store Branch', value: 'store_branch_name', align: 'left',header: 'Store Branch', key: 'store_branch_name' },
                            { text: 'Paid', value: 'paid', align: 'center', header: 'Paid', key: 'paid' },
                            {text: 'Actions', align: 'center', value: 'action'},
                        ])

                        this.allItemHeader = this.ItemListHeaders.concat([
                            { header: 'Transact Number', key: 'transact_number', align: 'left', sortable: false},
                            { header: 'Particular', key: 'particulars', align: 'center', sortable: false },
                            { header: 'Amount', key: 'amount', align: 'center', sortable: false, formatAs:'float', style: { numFmt: '#,##0.00' } },
                            { header: 'Bill Date', key: 'bill_date', align: 'center', sortable: false, formatAs:'date' },
                            { header: 'Due Date', key: 'due_date', align: 'center', sortable: false, formatAs:'date' },
                        ])
                        break;
                    default:
                        this.allHeader = this.ListHeaders.concat([
                            { text: 'Vendor', value: 'vendor_name', align: 'left', header: 'Vendor', key: 'vendor_name' },
                            { text: 'Company', value: 'company_name', align: 'left',header: 'Company', key: 'company_name' },
                            { text: 'Store Branch', value: 'store_branch_name', align: 'left',header: 'Store Branch', key: 'store_branch_name' },
                            { text: 'Location', value: 'location', align: 'left', header: 'Location', key: 'location' },
                            { text: 'Payment Method', value: 'payment_method', align: 'center',header: 'Payment Method', key: 'payment_method' },
                            { text: 'Paid', value: 'paid', align: 'center', header: 'Paid', key: 'paid' },
                            { text: 'Received', value: 'received', align: 'center',header: 'Received', key: 'received', },
                            { text: 'Repair Type', value: 'repair_type', align: 'left', header: 'Repair Type', key: 'repair_type' },
                            { text: 'Other Repair Type', value: 'other_repair_type', align: 'left', header: 'Other Repair Type', key: 'other_repair_type' },
                            { text: 'Vehicle', value: 'vehicle', align: 'left', header: 'Vehicle', key: 'vehicle' },
                            {text: 'Actions', align: 'center', value: 'action'},
                        ])

                        this.allItemHeader = this.ItemListHeaders.concat([
                            { header: 'Transact Number', key: 'transact_number', align: 'left', sortable: false},
                            { header: 'Particular', key: 'particulars', align: 'center', sortable: false },
                            { header: 'Price', key: 'price', align: 'right', sortable: false, formatAs:'float', style: { numFmt: '#,##0.00' }},
                            // { header: 'Paid', key: 'paid', align: 'right', sortable: false },
                            { header: 'Total Amount', key: 'total_amount', align: 'right', sortable: false, formatAs:'float', style: { numFmt: '#,##0.00' }},
                            { header: 'Item Amount to Pay', key: 'item_amount_to_pay', align: 'right', sortable: false, formatAs:'float', style: { numFmt: '#,##0.00' }},
                            { header: 'Item Paid Amount', key: 'item_paid_amount', align: 'right', sortable: false, formatAs:'float', style: { numFmt: '#,##0.00' }},
                            { header: 'Receive Paid Amount', key: 'receive_paid_amount', align: 'right', sortable: false, formatAs:'float', style: { numFmt: '#,##0.00' }},
                            { header: 'Item Quantity', key: 'item_quantity', align: 'center', sortable: false, formatAs:'int'},
                            { header: 'Receive Quantity', key: 'receive_qty', align: 'center', sortable: false, formatAs:'int' },
                            { header: 'Item Pending Quantity', key: 'item_pending_qty', align: 'center', sortable: false, formatAs:'int' },
                            { header: 'Quantity', key: 'quantity', align: 'center', sortable: false },
                            { header: 'COA', key: 'coa', align: 'center', sortable: false},
                            { header: 'Amount', key: 'amount', align: 'center', sortable: false, formatAs:'float', style: { numFmt: '#,##0.00' } },
                            { header: 'Recognition Month', key: 'recognition_month', align: 'center', sortable: false, formatAs:'date' },
                            { header: 'Department', key: 'department', align: 'center', sortable: false },
                            { header: 'Job', key: 'job', align: 'center', sortable: false },
                            { header: 'Bill Date', key: 'bill_date', align: 'center', sortable: false, formatAs:'date' },
                            { header: 'Due Date', key: 'due_date', align: 'center', sortable: false, formatAs:'date' },
                        ])
                }
            },
            deep: true,
            immediate: true
        },
        'paid':{
            handler(oldVal, newVal){
                if(oldVal !== newVal){
                    this.form.page = 1;
                }
            }
        }
    }
}
</script>
