<template>
    <v-dialog v-model="GET_ASSET_MANAGEMENT_BATCH_DIALOG" max-width="1500">
        <v-card class="d-flex flex-column" height="800" style="overflow-y: hidden;">
            <v-card-title class="d-flex justify-content-between align-items-center">
                <span> Asset Management Batch Addition</span>
                <v-btn text icon small color="gray" class="float-right" @click="() => {
                    resetField();
                    $store.commit('ASSET_MANAGEMENT_BATCH_DIALOG', false);
                }">
                <v-icon>mdi-close-circle</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text style="max-height: 1000px; overflow-y: auto;"> <!-- Adjust max-height as per your requirement -->
                <v-row class="mb-4 mt-4">
                    <v-col cols="1">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                            <v-btn color="teal darken-3" dark v-on="on" @click="triggerFileInput">
                                <v-icon>
                                mdi-file-import
                                </v-icon>
                                Batch Addition
                            </v-btn>
                            </template>
                            <span>Select a file</span>
                        </v-tooltip>
                        <v-file-input
                            ref="fileInput"
                            v-model="file"
                            accept=".xls, .xlsx"
                            @change="handleFileUpload"
                            style="display: none;"
                        />
                    </v-col>
                </v-row>
                <!-- <v-file-input label="File input" @change="handleFileUpload"></v-file-input> -->
                <v-card class="p-2" elevation="0" v-for="(item, i) in finalResult" :key="i">
                    <v-card-text>
                        <v-row class="p-2">
                            <v-col cols="3" class="d-flex pr-6">
                                <v-img v-model="item.image_path" class="image-fluid" height="500" width="200" :src="item.image_path == ''?url+'/'+'images/noimage.png':url+'/'+item.image_path" eager contain></v-img>
                            </v-col>
                            <v-col cols="9">
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field
                                        outlined
                                        v-model="item.asset_tag"
                                        label="Asset Tag"
                                        required
                                        readonly
                                        dense
                                    ></v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-autocomplete
                                            auto-select-first
                                            outlined
                                            :items="GET_ADMIN_CLASSIFICATION_SELECTION"
                                            label="Classification"
                                            v-model="item.classification_id"
                                            item-text="text"
                                            item-value="value"
                                            dense
                                            readonly
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field
                                            v-model="item.cost"
                                            outlined
                                            label="Cost"
                                            dense
                                            readonly
                                        >
                                            <template #prepend-inner>
                                            <span>&#8369;</span>
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field
                                            outlined
                                            v-model="item.prepared_by"
                                            label="Prepared By"
                                            dense
                                            required
                                            readonly

                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-autocomplete label="Vendor"
                                        v-model="item.vendor_id"
                                        :items="GET_ALL_VENDORS_LIST.data"
                                        item-text="text"
                                        item-value="value"
                                        auto-select-first
                                        required
                                        outlined
                                        dense
                                        readonly
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-autocomplete label="Sub Classification"
                                        v-model="item.sub_classification_id"
                                        :items="item.subClass"
                                        outlined
                                        dense
                                        readonly
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-autocomplete
                                        label="Category"
                                        outlined
                                        dense
                                        :items="GET_ADMIN_CATEGORY_SELECTION"
                                        v-model="item.category_id"
                                        item-text="text"
                                        item-value="value"
                                        auto-select-first
                                        required
                                        readonly

                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-autocomplete label="Location"
                                        outlined
                                        :items="GET_ADMIN_LOCATION"
                                        v-model="item.location"
                                        item-text="text"
                                        item-value="value"
                                        auto-select-first
                                        dense
                                        required
                                        :rules="valid ? item.rules : null"
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-autocomplete
                                        label="Status"
                                        :items="GET_STATUS_SELECTION"
                                        v-model="item.status"
                                        item-text="text"
                                        item-value="value"
                                        auto-select-first
                                        required
                                        outlined
                                        dense
                                        :rules="valid ? item.rules : null"
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-autocomplete label="Brand"
                                        :items="GET_ADMIN_BRAND"
                                        v-model="item.brand"
                                        outlined
                                        dense
                                        required
                                        readonly

                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field
                                        label="Warranty"
                                        outlined
                                        v-model="item.warranty"
                                        dense
                                        suffix="Month(s)"
                                        :rules="valid ? item.rules : null"
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-autocomplete
                                        label="Department"
                                        v-model="item.department_id"
                                        :items="GET_LIST_DEPARTMENT"
                                        item-text="text"
                                        item-value="value"
                                        auto-select-first
                                        required
                                        outlined
                                        dense
                                        :rules="valid ? item.rules : null"
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                        label="Serial"
                                        v-model="item.serial"
                                        outlined
                                        dense
                                        readonly
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-textarea
                                        v-model="item.description"
                                        label="Remarks"
                                        outlined
                                        height="162"
                                        :rules="valid ? item.rules : null"
                                        >
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" v-show="false">
                                        <qrcode-vue
                                            ref="qrcode"
                                            :value="item.asset_tag"
                                            size="150"
                                            level="H"
                                            class="float-right"
                                            id="qrcode"
                                            :location="item.location"
                                            :name="item.name"
                                        />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card-actions class="d-flex justify-content-center p-3">
                    <v-btn v-if="finalResult.length > 0" @click="confirmAddAssets()">Print QR Codes</v-btn>
                </v-card-actions>
            </v-card-text>
        </v-card>
    </v-dialog>

</template>

<script>
import { mapGetters } from 'vuex';
import QrcodeVue from "qrcode.vue";
import swal from "sweetalert2";
import * as XLSX from 'xlsx';
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
export default {
    mixins: [
        SharedFunctionsComponentVue
    ],
    components: {
        QrcodeVue,
    },
    data(){
        return {
            loading: false,
            result: [],
            form: {
                items: [],
            },
            finalResult: [{}],
            url:process.env.VUE_APP_API_HOST,
            valid: false,
            qr_codeBase69: null
        }
    },
    computed: {
        ...mapGetters([
            "ACTION",
            "SELECTED",
            "GET_ASSET_MANAGEMENT_BATCH_DIALOG",
            "GET_WAREHOUSE_ITEM_SELECTION",
            "GET_ADMIN_CLASSIFICATION_SELECTION",
            "GET_ADMIN_CATEGORY_SELECTION",
            "GET_ALL_VENDORS_LIST",
            "GET_ADMIN_BRAND",
            "GET_ADMIN_LOCATION",
            "GET_STATUS_SELECTION",
            "GET_LIST_DEPARTMENT",
            "GET_ADMIN_CLASSIFICATION_COUNT",
            "GET_ADMIN_TABLE_COUNT",
            "GET_ADM_WAREHOUSE_CODE_DROPDOWN"
        ]),
    },
    mounted() {
        document.addEventListener('keydown', this.handleKeydown);
        // this.$store.dispatch('getWarehouseItemSelections');
    },
    beforeDestroy(){
        document.removeEventListener('keydown', this.handleKeydown);
    },
    methods: {
        triggerFileInput() {
            this.$refs.fileInput.$refs.input.click();
        },
        ImagePath(item){
        let splited = item.image_path?item.image_path.split("/"):""
            if(splited == '' || splited[3] == ''){
                return this.url+'/'+this.noImage
            }else{
                return this.url+'/'+item.image_path
            }
        },
        resetField(){
            // this.form = [
            //     items: [],
            // ]
            this.loading = false,
            this.$store.commit('ACTION', '');
            this.$store.commit('ACCOUNTING_JOB_DIALOG', false);
        },
        handleFileUpload(event) {
            console.log('event',event);
            this.valid = true;
            // console.log('GET_WAREHOUSE_ITEM_SELECTION.data',this.GET_WAREHOUSE_ITEM_SELECTION.data);
            // console.log('GET_ADMIN_CLASSIFICATION_COUNT',this.GET_ADMIN_CLASSIFICATION_COUNT);
            // console.log('GET_ADMIN_LOCATION',this.GET_ADMIN_LOCATION);
            // console.log('GET_ADMIN_CLASSIFICATION_SELECTION',this.GET_ADMIN_CLASSIFICATION_SELECTION);
            const file = event;
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: 'array' });
                    const firstSheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[firstSheetName];
                    const jsonData = XLSX.utils.sheet_to_json(worksheet, {
                        header: 1,
                        defval: '',
                        blankrows: false,
                        range: 1
                    });

                    this.result = jsonData;
                    console.log(this.result);
                    this.result.map((e) => {
                        e[0] = `${e[0]} | ${e[1]}`;
                    })
                    this.result.map((e) => this.form.items = e);
                    this.finalResult = [];
                    this.finalResult = this.GET_WAREHOUSE_ITEM_SELECTION.data.filter(e => {
                        const result = this.result.some(d => e.text === d[0]);
                        if (result) {
                            this.result.forEach(f => {
                                if (e.text === f[0]) {
                                    e.description = f[6]
                                    e.status = this.GET_STATUS_SELECTION.find(status => status.text.toLowerCase() === f[3].toLowerCase()).value;
                                    e.location = this.GET_ADMIN_LOCATION.find(location => location.text.toLowerCase() === f[2].toLowerCase()).value;
                                    e.department_id = this.GET_LIST_DEPARTMENT.find(department => department.text.toLowerCase() === f[5].toLowerCase()).value;
                                    e.warranty = f[4]
                                }
                            });
                        }
                        return result;
                    });

                    const history = [];
                    let increment = 0;
                    this.finalResult.map((e) => {
                        let ass_no = (this.GET_ADMIN_TABLE_COUNT + increment).toString();
                        increment++;
                        e.rules =  [
                                    v => !!v || 'required',
                                ]
                        e.asset_code = ass_no;
                        e.warehouse = this.GET_ADM_WAREHOUSE_CODE_DROPDOWN.filter(e => e.default === 1)[0].value;
                        let classification_name = this.GET_ADMIN_CLASSIFICATION_SELECTION.find(item => item.value == e.classification_id);
                        e.subClass = classification_name.sub_class;
                        let element = this.GET_ADMIN_CLASSIFICATION_COUNT.find(item => item.name == classification_name.text);
                        let str = element.count.toString();
                        let baseTag = classification_name.code + "-" + str.padStart(3, "0");
                        let uniqueTag = baseTag;
                        let suffix = 1;

                        let wareHouseItemText = this.GET_WAREHOUSE_ITEM_SELECTION.data.find(d => d.value == e.value).text;
                        let trimItem = this.trimWareHouseItemName(wareHouseItemText);
                        e.name = trimItem;

                        while (history.includes(uniqueTag)) {
                            let str = (element.count + suffix).toString();
                            let baseTag = classification_name.code + "-" + str.padStart(3, "0");
                            uniqueTag = `${baseTag}`;
                            suffix++;
                        }
                        e.asset_tag = uniqueTag;

                        e.serial = this.generateSerial(e);
                        history.push(uniqueTag);
                    });
                    console.log('finalResult',this.finalResult);
                };
                reader.readAsArrayBuffer(file);
            } else {
                console.log('No file selected');
            }
        },
        handleKeydown(event){
            this.closeDialogByEsc(event, this.resetField)
        },
        generateSerial(e){
            let classification  = '';
            let subClass = '';
            let wareHouseItemText= '';

            classification = this.GET_ADMIN_CLASSIFICATION_SELECTION.find(d=>d.value == e.classification_id).code;
            if(this.GET_ADMIN_CLASSIFICATION_SELECTION.find(d=>d.value == e.classification_id).sub_class.find(el=>el.value == e.sub_classification_id) != undefined){
                subClass = this.GET_ADMIN_CLASSIFICATION_SELECTION.find(d=>d.value == e.classification_id).sub_class.find(el=>el.value == e.sub_classification_id).code;
            }
            wareHouseItemText = this.GET_WAREHOUSE_ITEM_SELECTION.data.find(d => d.value == e.value).text;
            let trimItem = this.trimWareHouseItemName(wareHouseItemText);
            return (classification + "-" + subClass.replace(" ", "") + "-" + trimItem).toUpperCase();
        },
        async confirmAddAssets() {
            this.valid = true;
            let fieldHasEmpty = [];
            this.finalResult.forEach((e,i) => {
                if(!e.location || !e.warranty || !e.department_id || !e.status || !e.description){
                    fieldHasEmpty.push(i + 1);
                }
            })
            let validate = this.finalResult.every((e,i) => {
                return e.location && e.warranty && e.department_id && e.status && e.description;
            });
            if(validate){
                let that = this;
                this.qr_code_image = '';

                let new_canvas = document.createElement('canvas');
                new_canvas.setAttribute('height', '335px');
                new_canvas.setAttribute('width', '300px');
                const new_ctx = new_canvas.getContext("2d");

                new_ctx.fillStyle = "white";
                new_ctx.fillRect(0, 0, 300, 335);

                const canvasElements = document.getElementsByTagName("canvas");
                const canvasImages = [];

                for (let i = 0; i < canvasElements.length; i++) {
                    canvasImages.push(canvasElements[i].toDataURL("image/jpeg"));
                }

                const assetTagCanvas = document.querySelector('#qrcode canvas');

                let assetTagQr = '';

                if (assetTagCanvas) {
                    assetTagQr = assetTagCanvas.toDataURL("image/jpeg");
                }

                let image_asset_code = document.createElement('img');

                if (assetTagQr) {
                    await image_asset_code.setAttribute('src', assetTagQr);
                    image_asset_code.setAttribute('id', 'qrcode_canvas');
                }

                let doc = new this.$jspdf({
                    orientation: 'p',
                    unit: 'mm',
                    format: 'letter'
                });

                const finalImage = [];

                for (let i = 0; i < canvasImages.length; i++) {
                    if (i > 0) {
                        doc.addPage();
                    }

                    let currentImg = document.createElement('img');
                    await currentImg.setAttribute('src', canvasImages[i]);

                    new_ctx.clearRect(0, 0, new_canvas.width, new_canvas.height);
                    new_ctx.fillStyle = "white";
                    new_ctx.fillRect(0, 0, 300, 335);
                    new_ctx.drawImage(currentImg, 10, 10, 280, 280);

                    if (assetTagQr) {
                        new_ctx.fillStyle = "white";
                        new_ctx.fillRect(215, 245, 90, 90);
                        new_ctx.drawImage(currentImg, 220, 220, 70, 70);
                    }

                    new_ctx.fillStyle = "black";
                    new_ctx.font = "bold 16px arial";
                    let location = this.GET_ADMIN_LOCATION.find(e => e.value === that.$refs.qrcode[i].$attrs.location).text;
                    let locationTextWidth = new_ctx.measureText(location).width;
                    let locationX = (new_canvas.width - locationTextWidth) / 2;
                    new_ctx.fillText(location, locationX, 305);
                    new_ctx.font = "bold 30px arial";
                    let qrCodeText = that.$refs.qrcode[i].value;
                    let qrCodeTextWidth = new_ctx.measureText(qrCodeText).width;
                    let qrCodeX = (new_canvas.width - qrCodeTextWidth) / 2;
                    new_ctx.fillText(qrCodeText, qrCodeX, 330);

                    let itemName = that.$refs.qrcode[i].$attrs.name;
                    new_ctx.font = "bold 9px arial";
                    let itemNameX = 250;
                    let itemNameY = 330;

                    if (itemName.length > 10) {
                        itemNameX -= 35;
                    }

                    new_ctx.fillText(itemName, itemNameX, itemNameY);

                    doc.addImage(`${new_canvas.toDataURL("image/jpeg")}`, 'PNG', 0, 0, 215, 279);
                    finalImage.push(new_canvas.toDataURL("image/jpeg"));
                    this.finalResult[i].qr_image = new_canvas.toDataURL("image/jpeg");
                }

                swal.fire({
                title: "",
                text: `Are you sure you want to Submit and Print QR code?`,
                icon: "warning",
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                allowOutsideClick: false,
                confirmButtonColor: '#397373',
                cancelButtonColor: 'grey',
                confirmButtonText: 'Confirm',
                showCloseButton: true,
                }).then((actions) =>{
                    if (actions.isConfirmed) {
                        doc.autoPrint();
                        window.open(doc.output('bloburl'), '_blank');


                        for (let i = 0; i < canvasImages.length; i++) {
                            let xhr = new XMLHttpRequest();
                            xhr.responseType = "blob";
                            xhr.onload = function () {
                                let a = document.createElement("a");
                                a.href = window.URL.createObjectURL(xhr.response);
                                a.download = `${that.$refs.qrcode[i].value}.png`;
                                a.style.display = "none";
                                document.body.appendChild(a);
                                a.click();
                                a.remove();
                            };

                            xhr.open("GET", finalImage[i]);
                            xhr.send();
                        }
                        this.form.qr_codeBase69 = canvasImages.join();
                        this.$store.dispatch("adminAssetsbatchAddition", {
                            form: this.finalResult,
                        }).then(response=>{
                            console.log('qwe')
                            swal.fire('Batch Addition Asset Management Success!','','success');
                            this.$store.commit('ASSET_MANAGEMENT_BATCH_DIALOG', false);
                        }).catch(err=>{
                            console.error(err);
                            swal.fire('Error!','','error');
                        });
                    }
                }).catch(error => {
                    console.error(error);
                    swal.fire('Error!','','error');
                });
            }
            else {
                let indices = fieldHasEmpty.join(', ')
                swal.fire('Required All Fields',`empty Field/s in Item ${indices}`,'warning');
            }
        },
    },
    watch: {
        'form': {
            handler(val){
            },
            deep: true
        },
        'ACTION': {
            handler(val) {
            },
            immediate: true,
            deep:true
        },
    }
}
</script>
<style scoped>
::v-deep .right-align-text input {
    text-align: right;
}
</style>
