<script>
import Swal from 'sweetalert2';
export default {
    methods: {
        agingDate(created_at) {
            if(!!created_at) {
                let startDay = this.$dayjs(created_at);

                let endDay = this.$dayjs();

                let days =  this.$dayjs(endDay).diff(this.$dayjs(startDay), 'day');
                return days;

                // if(days == 0) {
                //     let startTime = this.$dayjs(this.$dayjs(created_at), 'DD-MM-YYYY hh:mm:ss');
                //     let endTime = this.$dayjs(this.$dayjs(), 'DD-MM-YYYY hh:mm:ss');

                //     let timeDifference = endTime.diff(startTime, 'hour');

                //     if(timeDifference == 1) {
                //         time = `${Math.abs(timeDifference)} hour`;
                //     } else {
                //         time = `${Math.abs(timeDifference)} hours`;
                //     }
                // }

                // return days == 0 ? time : this.$dayjs.duration(days, 'day').humanize();

            }

            return '';
        },
        getStatuses(tab) {
            if(tab == 'ta') {
                return 'Technical Assign';
            } else if(tab == 'uas   ') {
                return 'Under Repair';
            } else if(tab == 'quo') {
                return 'Quotations';
            } else if(tab == 'fta') {
                return 'For Technical Approval';
            } else if(tab == 'fca') {
                return 'For Customer Approval';
            } else if(tab == 'wcm') {
                return 'Waiting For CM';
            } else if(tab == 'pp') {
                return 'Pending';
            } else if(tab == 'wfp') {
                return 'Waiting For Parts';
            } else if(tab == 'pin') {
                return 'Parts Installation';
            } else if(tab == 'wfr') {
                return 'Waiting For Replacement';
            } else if(tab == 'ret') {
                return 'Return';
            } else if(tab == 'rep') {
                return 'Repaired';
            } else if(tab == 'rfd') {
                return 'Ready For Delivery';
            } else if(tab == 'del') {
                return 'Delivered';
            } else if(tab == 'col') {
                return 'Collected';
            } else if(tab == 'def') {
                return 'Defective';
            } else if(tab == 'dta') {
                return 'Defective Test Assessment';
            } else if(tab == 'dca') {
                return 'Defective Customer Approval';
            } else if(tab == 'prq') {
                return 'Parts Request';
            } else if(tab == 'cf') {
                return 'Checker Form';
            } else if(tab == 'sppt') {
                return 'Spare Parts Transfer';
            } else if(tab == 'pfo') {
                return 'Parts For Order';
            } else {
                return '';
            }
        },
        async updateStatus(status = '',rfId){
            if(!status || !rfId){
                Swal.fire('Error!','- Status or RF# not found','error')
                return false
            }

            return await new Promise((resolve,reject)=>{
                this.$store.dispatch('serviceGet',{
                    url:`update-rf-status/${rfId}/${status}`,
                }).then(response =>{
                    resolve(response.data)
                }).catch(err=>{
                    reject(err)
                })
            })
        }
    }
}
</script>
