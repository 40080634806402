<template>
    <v-container fluid>
        <h3 class="page-title">PURCHASE ORDER</h3>
        <v-container class="ma-0">
            <v-app id="item-tabs">
                <v-tabs
                    v-model="tab"
                    id="group-tab"
                    color="cyan"
                    slider-color="cyan"
                    background-color="#424242"
                    dark

                    show-arrows
                >
                    <v-tabs-slider color="cyan"></v-tabs-slider>
                    <v-tab
                        ripple
                        v-for="(item, i) in STATUS.ADMIN_PURCHASE_ORDER_STATUS"
                        :key="i"

                        @click="()=>{
                            $store.commit('SELECTED_TAB',item.value);
                            components_index = 0

                        }"
                    >
                        {{ item.text }}
                    </v-tab>
                    <v-spacer></v-spacer>
                    <v-tab  @click="()=>{
                        components_index = 3
                    }">
                        WFR
                    </v-tab>
                    <!-- <v-tab   @click="()=>{
                        components_index = 1
                    }">
                        APPROVERS
                    </v-tab> -->
                    <v-tab  @click="()=>{
                        components_index = 2
                    }">
                        Shipping Via
                    </v-tab>

                    <v-tab  @click="()=>{
                        components_index = 4
                    }">
                        TEMPLATE
                    </v-tab>

                    <v-tab  @click="()=>{
                        components_index = 5
                    }">
                        SHIPPED TO
                    </v-tab>

                </v-tabs>
                <v-card>
                    <v-card-text class="p-0">
                        <PurchaseOrderComponent :status="tab" v-if="components_index == 0">
                        </PurchaseOrderComponent>
                        <AdministrativeApproverComponent v-if="components_index == 1"/>
                        <ShippingViaComponent v-if="components_index == 2"/>
                        <WaitingForReceivingComponent v-if="components_index == 3" :module="'Admin Purchase Order'"/>
                        <PurchaseOrderItemsTemplateComponent v-if="components_index == 4"/>
                        <AdminDeliveryAddressesComponent v-if="components_index == 5"/>
                    </v-card-text>
                </v-card>
            </v-app>
        </v-container>
        <DialogComponent
            :cp="components"
            :scrollable="dialog_scrollable"
            :width="width(dialog_width)"
            :retainfocus="false"
        ></DialogComponent>
        <v-dialog v-model="GET_ENTER_BILL_DIALOG" :width="width(dialog_width)" :retain-focus="false" :scrollable="false" persistent>
                <admin-enter-bill-view-component-vue></admin-enter-bill-view-component-vue>
         </v-dialog>
    </v-container>
</template>

<script>
import DialogComponent from "@/views/main/layouts/dialogs/Dialog.vue";
import PurchaseOrderComponent from "@/views/main/modules/Admin/PurchaseOrder/PurchaseOrderComponent.vue";
import PurchaseOrderViewComponent from "@/views/main/layouts/dialogs/Admin/PurchaseOrderViewComponent.vue";
import ShippingViaComponent from "@/views/main/modules/Admin/PurchaseOrder/ShippingViaComponent.vue";
import ShippingViaViewComponent from "@/views/main/layouts/dialogs/Admin/ShippingViaViewComponent.vue";
import { mapGetters } from "vuex";
import PusherFunctionComponent from '@/views/main/Shared/PusherFunctionComponent.vue';
import AdminEnterBillViewComponentVue from '@/views/main/layouts/dialogs/Admin/AdminEnterBillViewComponent.vue';
import AdministrativeApproverComponent from '@/views/main/modules/Admin/PurchaseOrder/AdministrativeApproverComponent.vue';
import AdministrativeApproverViewComponent from '@/views/main/modules/Admin/PurchaseOrder/AdministrativeApproverViewComponent.vue';
import WaitingForReceivingComponent from '@/views/main/modules/Accounting/AccountsPayable/WaitingForReceivingComponent.vue';
import PurchaseOrderItemsTemplateComponent from "@/views/main/modules/Admin/PurchaseOrder/PurchaseOrderItemsTemplateComponent.vue";
import PurchaseOrderItemsTemplateViewComponent from "@/views/main/layouts/dialogs/Admin/PurchaseOrderItemsTemplateViewComponent.vue";
import AdminDeliveryAddressesComponent from "@/views/main/modules/Admin/AdminDeliveryAddresses/AdminDeliveryAddressesComponent.vue";

export default {
    mixins:[PusherFunctionComponent],
    components: {
        DialogComponent,
        PurchaseOrderViewComponent,
        PurchaseOrderComponent,
        AdminEnterBillViewComponentVue,
        AdministrativeApproverComponent,
        AdministrativeApproverViewComponent,
        ShippingViaComponent,
        ShippingViaViewComponent,
        WaitingForReceivingComponent,
        PurchaseOrderItemsTemplateComponent,
        PurchaseOrderItemsTemplateViewComponent,
        AdminDeliveryAddressesComponent,
    },
    data() {
        return {
            components_index:0,
            form: {
                code: "",
                name: "",
                sub_class: [],
                sub_class_trash: [],
            },
            tab_index:0,
            components: "",
            items: [],
            purchase_order_tabs_items:{
                components: PurchaseOrderComponent,
                dialog: PurchaseOrderViewComponent,
                dialog_title:'Purchase Requisition',
                status_text:"ALL",
                dialog_width: "100%",
                scrollable:true,
                dispatch:{
                    get:'getAllPurchaseOrder',
                    add:'CreatePurchaseOrder',
                    update:'UpdatePurchaseOrder',
                    delete:'DeletePurchaseOrder'
                },
            },
            dialog_width: "",
            loaded: false,
            options: {},
            itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
            itemsPerPage: 10,
            tab: 0,
            dialog_scrollable: true,
            administrative_approver: false,
            shipping_via: false
        };
    },
    computed: {
        ...mapGetters([
            // "GET_NEW_ADMIN_OTHER_TABLE",
            // "GET_NEW_PURCHASE_ORDER_TABLE",
            // "GET_PURCHASE_ORDER_DISPATCH_REFRESH",
            // "GET_PRF_SELECTION",
            "GET_ENTER_BILL_DIALOG",
            // "GET_PURCHASE_ORDER_DISPATCH_STATUS"
            'PURCHASE_ORDER',
            'SELECTED_TAB',
            'ADMIN_PURCHASE_ORDER_STATUS',
            'STATUS',
            'ACTION'
        ]),
        filteredItems() {
            return this.items.filter((item, index) => index !== 9);
        },
    },
    mounted() {
        this.callPusher()
        this.tabs(0);
        this.$store.commit('SELECTED_TAB', 0)
        // this.ServerPusher();

    },
    methods: {
        dropDowns() {
            //this.$store.dispatch("getPRFSelection");
            this.$store.dispatch("getAdminSupplierSelection", {with_active: 0});
            this.$store.dispatch("getAdminLocationSelection");
            this.$store.dispatch("getAdminClassificationSelection");
            this.$store.dispatch("getAdminItemUomSelection");
            this.$store.dispatch('getAdminDeliveryAddressSelection');
            // this.$store.dispatch("getadminPurchaseOrderNum");
            // this.$store.dispatch("getPurchaseOrderItemsTemplateSelection", {url: 'get-po-items-template-selection'});
        },
        tabs(key) {
            // console.log(this.purchase_order_tabs_items)
            this.components = this.purchase_order_tabs_items.dialog;
            this.dialog_width = this.purchase_order_tabs_items.dialog_width;
            this.dialog_scrollable = this.purchase_order_tabs_items.scrollable;
            // console.log(this.items)
            this.$store.commit(
                "PURCHASE_ORDER_DISPATCH_STATUS",
                this.items[key]
            );

            this.purchase_order_tabs_items.dispatch.delete = this.PURCHASE_ORDER.STATUS == 'FOR PAYMENT'?'DeleteEnterBill':'DeletePurchaseOrder'
            if(this.PURCHASE_ORDER.STATUS != 'FOR PAYMENT'){
                this.dropDowns();
            }

            this.$store.commit(
                "PURCHASE_ORDER_DISPATCH",
                this.purchase_order_tabs_items.dispatch
            );
        },

    ServerPusher(){
    window.Echo.channel('ingco-channel').listen('RealtimePRFNumber',(e)=>{
        if(e.prf_realtime_selection != ''){
            this.GET_PRF_SELECTION.push({value:e.prf_realtime_selectionm,text:e.prf_realtime_selection})
        }
    })
    window.Echo.channel('ingco-channel-2').listen('RealtimeRefNumEnterBill',(e)=>{
        if(e.ref_num != ''){
            this.$store.commit('ENTER_BILL_REF_NUM',e.ref_num);
        }
    })
   },
    width(width) {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return "100%"
          case 'sm': return "100%"
          case 'md': return "100%"
          case 'lg': return "100%"
          case 'xl': return width
        }
      },
    },
    watch: {
        GET_PURCHASE_ORDER_DISPATCH_REFRESH: {
            handler(val) {},
        },
        GET_NEW_PURCHASE_REQUEST_TABLE: {
            handler(val) {
                if (val) {
                    // this.ServerPusher();
                }
            },
        },
        // tab:{
        //     handler(val){
        //         if(val === 11){
        //             this.shipping_via = true;
        //         }
        //         else if(val === 12){
        //             this.administrative_approver = true;
        //         }else{
        //             this.administrative_approver = false;
        //             this.shipping_via = false;
        //             this.$store.commit('SELECTED_TAB',val);
        //         }
        //     }
        // },
    },
};
</script>
<style>
#group-tab {
    padding: 0 !important;
}
</style>
