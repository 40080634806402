<template>
    <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
            <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                <span class="text-h5">{{GET_HR_DIALOG_TITLE}}</span>
                <v-row class="m-0">
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                            text
                            icon
                            small
                            color="gray"
                            class="float-right"
                            @click="closeDialog()"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-container class="pb-0 my-2" fluid>
                    <v-row>
                        <v-col cols="12" sm="5" class="pa-0 pr-1" v-if="!disabled_view && this.ACTION == 'Submit'">
                            <v-autocomplete
                                v-model="form.employee_ids"
                                :items="dropdowns.employee_lists"
                                :loading="loading.employee_lists"
                                :rules="errors.required"
                                :readonly="disabled_view"
                                multiple
                                item-disabled="isDisabled"
                                label="Employee Name"
                                item-value="value"
                                item-text="full_name"
                                auto-select-first
                                outlined
                                required
                                dense
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="3" class="pa-0 pr-1" v-else>
                            <v-text-field
                                v-model="form.full_name"
                                label="Employee Name"
                                readonly
                                outlined
                                required
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-spacer>
                        </v-spacer>
                        <v-col cols="12" sm="4" class="pa-0 pr-1">
                            <v-menu
                                v-model="form.effectivity_date_menu"
                                :close-on-content-click="false"
                                ref="menu"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="dateRangeText"
                                        v-on="on"
                                        :disabled="disabled_view"
                                        label="Effectivity Date (YYYY-MM-DD)"
                                        outlined
                                        dense
                                    ></v-text-field>
                                </template>

                                <v-date-picker
                                    v-model="form.effectivity_date"
                                    no-title
                                    scrollable
                                    range
                                >
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-container fluid class="pa-0 ma-0">
                            <v-card class="pa-0">
                                <v-card-text>
                                    <v-simple-table height="300px">
                                        <thead>
                                            <tr>
                                                <th>Time In</th>
                                                <th>Time Out</th>
                                                <!-- <th v-if="!disabled_view">Action</th> -->
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <v-menu
                                                        v-model="form.time_in_menu"
                                                        :disabled="disabled_view"
                                                        :close-on-content-click="false"
                                                        transition="scale-transition"
                                                        offset-y
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                v-model="form.time_in"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                :readonly="disabled_view"
                                                                outlined
                                                                dense
                                                                flat
                                                            ></v-text-field>
                                                        </template>
                                                        <v-time-picker v-model="form.time_in" format="24hr" scrollable @click:minute="form.time_in_menu = false"></v-time-picker>
                                                    </v-menu>
                                                </td>

                                                <td>
                                                    <v-menu
                                                        v-model="form.time_out_menu"
                                                        :close-on-content-click="false"
                                                        :disabled="disabled_view"
                                                        transition="scale-transition"
                                                        offset-y
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                v-model="form.time_out"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                :readonly="disabled_view"
                                                                outlined
                                                                dense
                                                                flat
                                                            ></v-text-field>
                                                        </template>
                                                        <v-time-picker v-model="form.time_out" format="24hr" scrollable :min="form.time_in" @click:minute="form.time_out_menu = false"></v-time-picker>
                                                    </v-menu>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </v-simple-table>
                                </v-card-text>
                            </v-card>
                        </v-container>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions
                class="text-center"
                style="border-top: 1px solid #dee2e6"
            >
                <v-row class="ma-1 pb-2">
                    <v-col cols="12" v-if="actions != 'View'">
                        <v-btn
                            @click="
                            actions == 'Submit'? addScheduleTemplate() : updateScheduleTemplate()"
                            :disabled="disabled_view"
                        >
                            <span>{{ actions }}</span>
                        </v-btn>
                        <span class="pr-12">&nbsp;</span>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-form>
</template>
<script>
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
    mixins: [SharedFunctionsComponentVue],
    data() {
        return {
            valid: false,
            overlay: false,
            disabled_view: false,
            errors: {
                required: [(v) => !!v || "Field is required"],
                number: [
                    (v) => !!v || "Field is required",
                    (v) => !isNaN(v) || "Input must be a number",
                ],
            },
            form: {},
            dropdowns: {
                schedule_type: [],
                employee_lists: [],
            },
            component_dispatches:[
		        'getEmployeeScheduleType',
                'getEmployeeDataDropdown',
			],
            loading:{
                employee_lists:false,
                department: false
            },
            selected_days: [
                {id:1, text:'emoji_asdasdasdasdas'}
            ]
        };
    },
    computed: {
        ...mapGetters([
            "ACTION",
            "GET_HR_DIALOG_TITLE",
            'GET_EMPLOYEE_SCHEDULE_TYPE_SELECTION',
            "DIALOGS",
            "GET_HR_DISPATCH",
            "GET_HR_OTHER_TABS_DIALOG_VIEW",
            "GET_EMPLOYEE_DATA",
        ]),
        actions() {
            if (this.ACTION == "Submit") {
                this.disabled_view = false;
                this.isViewBtn = false;
                return "Submit";
            } else if (this.ACTION == "Update") {
                this.disabled_view = false;
                this.isViewBtn = true;
                this.getScheduleTemplateData();
                return "Update";
            } else if (this.ACTION == "View") {
                this.disabled_view = true;
                this.isViewBtn = true;
                this.getScheduleTemplateData();
                return "View";
            }
        },
        dateRangeText () {
            return this.form.effectivity_date.join(' ~ ')
        },
    },
    created() {
        this.initialForm();
    },
    mounted() {
        this.getAllDropdowns();
    },
    methods: {
        getAllDropdowns(){
            this.loading.employee_lists = true
            this.component_dispatches.forEach(el=>{
                this.$store.dispatch(el)
            })
        },
        initialForm() {
            this.form = {
                schedule_type: '',
                time_in: null,
                time_out: null,
                effectivity_date: [],
                effectivity_date_menu: false,
                temporary: 0,
                schedule_detail_trash_ids: [],
                break_duration: 0,
                time_in_menu: false,
                time_out_menu: false,
            };
        },
        height(width) {1
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "100%";
                case "sm":
                    return "100%";
                case "md":
                    return width;
                case "lg":
                    return width;z
                case "xl":
                    return width;
            }
        },
        closeDialog() {
            this.$store.commit("DIALOG", false);
        },
        addScheduleTemplate() {
            this.$refs.form.validate();

            // const array_text =
            // [
            //     department_swal_text,
            // ]
            // const filtered = array_text.filter(function (el) {
            //     return el.replace(',', '');
            // })

            // if (this.form.department_id ) {
                this.$store.dispatch(this.GET_HR_DISPATCH.add,this.form).then(response=>{
                    Swal.fire(response.icon.toUpperCase(),response.message,response.icon);
                    this.$store.commit("DIALOG", false);
                })
            // }
            // else {
            //     Swal.fire({
            //         text: `Fill Required Fields (${filtered})`,
            //         icon: 'warning',
            //         allowOutsideClick: false,
            //     });
            // }
        },
        updateScheduleTemplate() {
            this.$store.dispatch(this.GET_HR_DISPATCH.add, this.form).then((response) => {
				Swal.fire(response.icon.toUpperCase(),response.message,response.icon);
				this.closeDialog();
			});
        },
        getScheduleTemplateData(){
            this.form.id                        = this.GET_HR_OTHER_TABS_DIALOG_VIEW.id
            this.form.full_name                 = this.GET_HR_OTHER_TABS_DIALOG_VIEW.full_name
            this.form.time_in                   = this.GET_HR_OTHER_TABS_DIALOG_VIEW.time_in
            this.form.time_out                  = this.GET_HR_OTHER_TABS_DIALOG_VIEW.time_out
            this.form.employee_ids              = [ parseInt(this.GET_HR_OTHER_TABS_DIALOG_VIEW.employee_id) ]
            this.form.effectivity_date          = [ this.GET_HR_OTHER_TABS_DIALOG_VIEW.date_start, this.GET_HR_OTHER_TABS_DIALOG_VIEW.date_end ]
        }
    },
    watch: {
        DIALOGS: {
            handler(val) {
                if (val) {
                    this.initialForm();
                }
            },
        },
        GET_EMPLOYEE_SCHEDULE_TYPE_SELECTION:{
            handler(val){
                this.dropdowns.schedule_type = val
            }
        },
        'form':{
            deep:true,
            handler(val){
                // close effectivity date menu
                if(val.effectivity_date.length == 2){
                    this.form.effectivity_date_menu = false
                    if(val.effectivity_date[0] > val.effectivity_date[1]){
                        Swal.fire("Effectivity Range Not Valid","","warning")
                        this.form.effectivity_date = [];
                    }
                }
            }
        },
        GET_EMPLOYEE_DATA:{
            handler(val){
                this.dropdowns.employee_lists = val;
                this.loading.employee_lists = false;
            }
        },
    },
};
</script>
<style scoped>
.modal_title {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: white;
}
</style>
