import { render, staticRenderFns } from "./ServerLogsComponent.vue?vue&type=template&id=c63c5bd8"
import script from "./ServerLogsComponent.vue?vue&type=script&lang=js"
export * from "./ServerLogsComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports