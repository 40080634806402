<template>
    <v-container fluid>
      <v-card-title>
        <v-container fluid class="pa-0 ma-0">
        <v-row>
          <v-col class=" align-items-center">
            <h4 class="page-title">ACCOUNTING DEPARTMENTS</h4>
          </v-col>
        </v-row>
        <v-row>
  
          <!-- <buttons
              v-if="userAccess.create"
              :action="buttons.actions.create"
              :button_icon="buttons.icons.create"
              :color="buttons.colors.create"
              @click="adddialogs"
            >
            </buttons> -->
        <div class="row" v-if="userAccess.create">
            <div class="col-12 d-flex no-block align-items-center">
                <div class="btn-toolbar">
                    <v-btn @click="adddialogs()">
                        <v-icon>mdi-plus</v-icon>
                        Add
                    </v-btn>
                </div>
            </div>
        </div>
  
        </v-row>
      </v-container>
      </v-card-title>
      <department-table></department-table>
      <dialogs :cp="components" :width="width()"></dialogs>
    </v-container>
  </template>
  
  <script>
  import { mapGetters } from 'vuex'
  // import buttons from '../../layouts/Buttons/DialogButton.vue'
  // import buttons_components from '../../../../assets/js/components-js.js'
  import editDepartmentdialog from '../../../layouts/dialogs/Accounting/AccountingDepartmentsViewComponent.vue'
  import departmentTable from '../AccountingDepartment/AccountingDepartmentsTableComponent.vue'
  import Dialogs from '../../../layouts/dialogs/Dialog.vue'
  export default {
    components: {
      // buttons,
      // buttons_components,
      departmentTable,
      editDepartmentdialog,
      Dialogs,
    },
    data() {
      return {
        // buttons: buttons_components.buttons,
        loaded: false,
        departments: [],
        itemsPerPageOptions: [5, 10, 15],
        itemsPerPage: 15,
        search: '',
        viewDepartmentId: '',
        json_data: [],
        json_meta: [
          [
            {
              key: 'charset',
              value: 'utf-8'
            },
          ],
        ],
        userAccess: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
        selected: {},
        components: editDepartmentdialog,
      }
    },
    mounted() {
      this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
      this.$store.commit('LINK_ACTIVE',window.location.pathname.split("/")[3])
    },
    methods: {
      adddialogs() {
        this.$store.commit('DIALOG', true);
        this.$store.commit('ACTION', 'Submit')
        this.$store.commit('EDIT_ACCOUNTING_DEPARTMENT', '')
      },
      width() {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return "100%"
          case 'sm': return "100%"
          case 'md': return "40%"
          case 'lg': return "40%"
          case 'xl': return "40%"
        }
      },
    },
    computed: {
      ...mapGetters([
          'DIALOGS',
          'USER_ACCESS',
      ]),
    },
    watch: {
      USER_ACCESS: {
        handler(val) {
           if(val != "fail"){
          this.userAccess.create = false;
          val.forEach(e => {
  
            if (e.actions_code == 'create') {
              this.userAccess.create = true;
            }
          });
          }
        }
      },
    },
  };
  </script>
  