import Reports from "@/views/main/modules/Reports/ServiceReportComponent.vue";

const routes = [{
    path: "/service/reports/",
    name: "servicereports",
    component: Reports,
    meta: {
        requiresAuth: true,
        title: 'Sales Reports',
        type: 'sales'
    },
}]

export default routes;
