<template>
    <v-container class="tabs-body-color p-0">
        <v-btn class="my-2" @click="dialog('Add','')" v-if="userAccess.includes('create')">
            <v-icon>mdi-plus</v-icon> Add
        </v-btn>
        <v-card elevation="0" rounded="0" class="p-3">
            <v-card-title class="d-flex justify-content-end align-items-end p-0 m-0">
                <div class="w-25 p-0 m-0 d-flex justify-content-center align-items-center">
                    <v-text-field v-model="filters.search" label="Search" single-line hide-details @keydown.enter="search()"></v-text-field>
                    <div class="pt-5">
                        <v-icon @click="search()">mdi-magnify</v-icon>
                    </div>
                </div>
            </v-card-title>
            <v-data-table
                :headers="form.headers"
                :items="form.items"
                :loading="loading"
                :server-items-length="form.totalItems"
                :option.sync="filters.options"
                loading-text="Loading...Please wait"
                class="w-100"
                dense
                :page.sync="form.page"
                :items-per-page.sync="form.itemsPerPage"
                disable-sort
            >
                <template v-slot:[`item.action`]="{ item }">
                    <span class="text-nowrap">
                        <v-btn text icon color="orange" @click="dialog('View',item)" v-if="userAccess.includes('view')">
                            <v-icon class="btn-action" small>mdi-eye</v-icon>
                        </v-btn>
                        <v-btn text icon color="blue" @click="dialog('Update',item)" v-if="userAccess.includes('edit')">
                            <v-icon class="btn-action" small>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn text icon color="red" @click="deleteApprover(item)" v-if="userAccess.includes('delete')">
                            <v-icon class="btn-action" small>mdi-delete</v-icon>
                        </v-btn>
                    </span>
                </template>
            </v-data-table>
            <v-dialog v-model="SHIPPING_VIA_DIALOG" width="30%" style="align-items: center;" persistent>
                <ShippingViaViewComponent/>
            </v-dialog>

        </v-card>
    </v-container>
    
</template>
  
<script>
    import { mapGetters } from 'vuex';
    import swal from "sweetalert2";
    import ShippingViaViewComponent from "@/views/main/layouts/dialogs/Admin/ShippingViaViewComponent.vue";
    export default {
        components: {
        ShippingViaViewComponent,
    },
    data() {
        return {
            form: {
                items:[],
                headers: [
                    {text: 'Code', align: 'left', value: 'code'},
                    {text: 'Name', align: 'left', value: 'name'},
                    {text: 'Actions', align: 'center', value: 'action'},
                ],
                page: 1,
                itemsPerPage: 10,
                totalItems: 0,
            },
            filters: {
                search: '',
                option: {},
            },
            userAccess:[],
            loading: false,
            // dialogType: '',
        }
    },
    mounted() {
        this.$store.dispatch(
        "checkAccessUser",
        this.$router.currentRoute.params.id
        );
        this.list();
    },
    computed: {
        ...mapGetters([
            "USER_ACCESS",
            "SHIPPING_VIA_DIALOG",
            "ACTION",
            "SELECTED",
        ]),
    },
    methods: {
        list(){
            this.form.items = [];
            this.loading = true;
            this.$store.dispatch("shippingViaGet",{
                url: "get-all-shipping-via",
                page: this.form.page,
                itemsPerPage: this.form.itemsPerPage,
                search: this.filters.search,
            })
            .then(response => {
                this.form.items = response.data.data
                this.form.totalItems = response.data.total
                this.loading = false;
            }).catch(error => {
                console.error("Error fetching Administrative Approver:", error)
                this.loading = false;
            });
        },
        dialog(action, item){
            this.$store.commit('SHIPPING_VIA_DIALOG', true);
            this.$store.commit('ACTION', action);
            this.$store.commit('SELECTED',item);
            // this.dialogType = action;
            this.dialogVisible = true;
        },
        deleteApprover(item){
            swal.fire({
            icon: "warning",
            text: "Are you sure you want to Delete?",
            cancelButtonText: "Cancel",
            reverseButtons: true,
            showCancelButton: true,
            allowOutsideClick: false,
            cancelButtonColor: "#grey",
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch("shippingViaGet", {
                    url: 'delete-shipping-via/' + item.id,
                    id: item.id
                    }).then(response=>{
                        this.list();
                        swal.fire('Success', '', 'success');
                    });
                }
            });
        },
        search(){
            this.form.page = 1
            this.list()
        }
    },
    watch: {
        SHIPPING_VIA_DIALOG: {
            handler(val){
                if(!val){
                    this.list();
                }
            },
            deep: true
        },
        'filters.option': {
            handler(val){
                this.list();
            }
        },
        'form.page'(val){
            this.list(val, this.form.itemsPerPage);
        },
        'form.itemsPerPage'(val){
            this.list(this.form.page, val);
        },
        USER_ACCESS:{
            handler(val){
             if(val != "fail")
                this.userAccess = val.map(e=>e.actions_code);
            }
        }
        
    }
}
</script>
  