<template>
  <v-card>
    <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
      <span class="text-h5">{{
        GET_COMPONENT_SETTINGS.children.dialog.title
      }}</span>
      <v-row class="m-0">
        <v-col cols="pull-right-10 p-2">
          <v-btn
            text
            icon
            small
            color="gray"
            class="float-right"
            @click="closeDialog()"
          >
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row class="d-flex">
          <v-col cols="12" sm="12">
            <v-autocomplete
              v-model="form.user_id"
              :items="selection.users.items"
              label="Select User"
              item-value="value"
              item-text="text"
              dense
              outlined
              hide-details
              :readonly="is_disabled"
              auto-select-first
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <v-autocomplete
              v-model="form.company_id"
              :items="GET_COMPANY_SELECTION"
              label="Select Company"
              item-value="value"
              item-text="text"
              dense
              outlined
              multiple
              chips
              deletable-chips
              small-chips
              hide-details
              :readonly="is_disabled"
              auto-select-first
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <v-autocomplete
              v-model="form.store_branch_ids"
              :items="selection.store_branches"
              label="Select Store"
              item-value="value"
              item-text="text"
              dense
              outlined
              multiple
              chips
              deletable-chips
              small-chips
              hide-details
              :readonly="is_disabled"
              auto-select-first
            >
          </v-autocomplete>
          </v-col>
        </v-row>
        <v-card-actions
    
          class="text-center d-flex border-top justify-content-center"
        >
          <v-btn
            class="float-center"
            :loading="loading"
            :disabled="is_disabled"
            @click="saveorupdate()"
          >
            <span>{{ actions }}</span>
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      form: {
        user_id: "",
        company_id: [],
        store_branch_ids: [],
      },
      selected_company:[],
      selection: {
        users: {
          items: [],
        },
        store_branches:[]
      },
      loading: false,
      dispatch_action: "",
      is_disabled:false
    };
  },
  computed: {
    ...mapGetters([
      "ACTION",
      "GET_COMPONENT_SETTINGS",
      "GET_COMPANY_SELECTION",
      "GET_PV_STORE_BRANCHES_SELECTION",
      "GET_EDIT_FROM_DATA_TABLE",
      "DIALOGS",
      "GET_NEW_DATA_FOR_DATA_TABLE"
    ]),
    actions() {
      this.dropdowns()
      if (this.ACTION == "Save") {
        console.log(this.GET_COMPONENT_SETTINGS.children.dispatch.post._action)
        this.dispatch_action =
          this.GET_COMPONENT_SETTINGS.children.dispatch.post._action;
          this.is_disabled = false
        return "Create";
      } else if (this.ACTION == "Update") {
        this.is_disabled = false
        this.getEdit()
        this.dispatch_action = this.GET_COMPONENT_SETTINGS.children.dispatch.put._action;
 
        return "Update";
      }
      else if (this.ACTION == "View") {
        this.is_disabled = true
        this.getEdit()
        return "Update";
      }
    },
    _cs(){
      return this.GET_COMPONENT_SETTINGS;
    }
  },
  mounted() {

  },
  methods: {
    getEdit(){
       let _edit = this.GET_EDIT_FROM_DATA_TABLE
        if(_edit){
          
          let payload = {
            id:_edit.id
          }
          Object.assign(payload,this._cs.children.dispatch.show)
          this.form.id = _edit.id
          this.form.user_id = _edit.user_id
          this.form.company_id = _edit.company_id.map(e=>e.company_ids)
          this.$store.dispatch(this._cs.children.dispatch.show._action, payload)
          .then(res=>{
              res.data.forEach(e=>{
                  this.form.store_branch_ids.push(e.store_branches_id)
              })
          });
        }
        // this.form.user_id = this.GET_EDIT_FROM_DATA_TABLE.user_id
    },
    closeDialog() {
      this.$store.commit('EDIT_FROM_DATA_TABLE',0)
      this.$store.commit("DIALOG", false);
    },
    dropdowns() {
      this.$store.dispatch("getAccountingUsers").then((response) => {
        this.selection.users.items = response;
      });
    
    },
    resetfields(){
   
      Object.assign(this.$data, this.$options.data.call(this));
     
    },
    saveorupdate() {
      this.loading = true;
      let method = Object.keys(this._cs.children.dispatch);
      let _obj = method.find(e=>e == this.dispatch_action)
      Object.assign(this.form,this._cs.children.dispatch[_obj])
      this.$store.dispatch(this.dispatch_action, this.form).then((e) => {
        this.loading = false;
      }).catch(err=>{
        this.loading = false;
      });
    },
  },
  watch: {
    DIALOGS:{
      handler(val){
        if(!val){
          this.resetfields()
        }
      }
    },
    GET_NEW_DATA_FOR_DATA_TABLE:{
        handler(val){
            if(val){
              this.$store.dispatch('SwalAlertSuccess',{
                title:"Success!",
              })
            }else{

             this.closeDialog()
            }
        
        }
    },
    "form.company_id": {
      handler(val) {
        // this.form.store_branch_ids = [];
        this.selection.store_branches = []
        if(val.length > 0){
        this.$store.dispatch("getPaymentVoucherBranchesSelection", {
          company_id: val,
        });
        } 
      },
    },
    GET_PV_STORE_BRANCHES_SELECTION:{
      handler(val){
         val.forEach(e=>{
          let _sb = this.selection.store_branches;
          let company = this.GET_COMPANY_SELECTION.find(ele=>ele.value == e.company_id)
          let find_exist = _sb.some(find => find.header == company.text)
          if(!find_exist){
            _sb.push({header:company.text})
          }
          _sb.push(e)
         })
      }
    }
  },
};
</script>