<template>
    <v-card class="pa-4">
        <v-btn text icon color="gray" class="float-right" @click="closeSpDialog()">
                <v-icon>mdi-close-circle</v-icon>
        </v-btn>
        <v-card-text>
        <v-row class="m-0">
            <v-card-title>
                <span class="#757575--text headline">Spare Part</span>
            </v-card-title>
        </v-row>

        <v-row class="px-5">
            <v-col >
                <v-card-title class="d-flex align-center header-border">
                    <h3 class="#757575--text px-2">{{ GET_SPARE_PART.name + ' (' + GET_SPARE_PART.model + ')' }}</h3>
                </v-card-title>
            </v-col>
        </v-row>

        <v-row class="pr-5 pl-5">
            <v-col md="12">
                    <!-- ITEM DATA TABLE -->
                <v-data-table
                    show-select
                    :headers="headers_selected"
                    v-model="spare_part_parents_selected"
                    :items="GET_SPARE_PART_PARENTS"
                >
                <template v-slot:[`item.image_path`]="{ item }">
                    <v-img class="img-thumbnail" width="50" height="50" :src="checkImagePath(item)" @error="checkImage(item)" eager contain></v-img>
                </template>

                <template v-slot:[`item.name`]="{ item }">
                    <span class="text-nowrap"> {{ item.name === null || item.name == 'null' ? '-': item.name }} </span>
                </template>

                <template v-slot:[`item.baklas_qty`]="{ item }">
                    <span>{{ item.baklas_qty === null || item.baklas_qty == 'null' ? '-': item.baklas_qty }}</span>
                </template>
             
                <template v-slot:[`item.part_num`]="{ item }">
                    <span> {{ item.part_num === null || item.part_num == 'null' ? '-': item.part_num }} </span>
                </template>
                
                </v-data-table>
                <!-- ITEM DATA TABLE -->
            </v-col >
        </v-row>
        </v-card-text>

        <v-card-actions class="d-flex justify-end mr-3">
            <v-btn
                color="red py-4 px-6"
                :disabled="remove_sp"
                @click="detachSparePartParent(GET_SPARE_PART.id)">
                Delete
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import Swal from "sweetalert2";

export default {
    name:'DeleteSparePartsDialog',
    // props: ['getAllPtItemsFunction'],
    props: {
        sparePartParents: Object,
    },
    data(){
        return{
            spare_part_parents_selected: [],
            selected_id: '',
            remove_sp: true,
            selected_spare_part: [],
            headers_selected: [
                { text: 'Image', align: 'left', value: 'image_path' },
                { text: 'Model', value: 'model'},
                { text: 'Name', value: 'name'},
                { text: 'UOM', value: 'uom' },
                { text: 'Baklas Qty', value: 'baklas_qty'},
                { text: 'Part#', value: 'part_num'},
            ],
            userAccess: {
                view: false,
                edit: false,
                delete: false,
                assign: false,
            },
            reset_itemsperpage: false,
        }

    },
    computed:{
            ...mapGetters([
            'GET_SP_DIALOG',
            'GET_SPARE_PART',
            'GET_SPARE_PART_PARENTS'
        ]),
    },
    methods:{
        closeSpDialog() {
            this.$store.commit('SP_DIALOG',false);
            this.spare_part_parents_selected = [];
        },
        checkImagePath(item) {
            this.$store.dispatch('checkImagePath', item);
        },
        checkImage(item) {
            item.image_path = "/images/items/no-image.png";
            return item.image_path;
        },
        detachSparePartParent(sparePart_Id){
            Swal.fire({
                title: 'Are you sure you want to Remove these Item',
                icon: 'warning',
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                allowOutsideClick: false,
                confirmButtonColor: '#d63b30',
                cancelButtonColor: 'grey',
                confirmButtonText: 'Confirm',
            }).then(result=>{
                if (result.isConfirmed) {
                    let payload = { 
                        delete_items: this.spare_part_parents_selected.map(e=>{
                        return {
                            item_id: e.id,
                        }
                        }),
                        // itemId: this.GET_SPARE_PART_PARENTS?.find(e=>e.id).id,
                        sparePartId: sparePart_Id,
                    }
                    this.$store.dispatch('deleteSparePart', payload).then(response => {   
                        Swal.fire(response.data.msg, '' ,response.data.icon)
                        // this.getAllPtItemsFunction()
                        this.closeSpDialog()
                    });
                }
            })
        },
    },
    watch:{
        'spare_part_parents_selected':{
            handler(val){
                if(val.length >= 1){
                    this.remove_sp = false
                }else{
                    this.remove_sp = true
                }
            }
        },
    }
}
</script>

<style scoped>

.header-border {
    border: 1px solid #9E9E9E;
    border-radius: 4px;
}

</style>