<template>
    <v-row>
      <v-container class="second-tabs-body-color">
        <!-- <v-row class="row mt-1" v-if="userAccess.create"> -->
        <v-row class="row mt-1">
            <div class="col-12 d-flex no-block align-items-center">
                <div class="btn-toolbar">
                    <v-btn
                        data-modal-id="addContainerModal"
                        @click="showAddContainer()"
                        v-if="ACCESS_INBOUND.includes('create_wir')"
                    >
                        <v-icon>mdi-plus</v-icon>Add
                    </v-btn>
                </div>
            </div>
      </v-row>
      <v-row class="ma-0">
        <warehouse-return-table-component
            ref="child"
            :containerActionResult="action"
            :status="status"
            :radioGroup="radioGroup"
            @showEditModal="showEditModal"
            :user_access="USER_ACCESS"
        >
        </warehouse-return-table-component>
      </v-row>
      </v-container>
      <v-app id="addEditContainerModal">
        <v-dialog
          v-model="add_edit_container_dialog"
          persistent
          max-width="90%"
          scrollable
        >
          <v-card>
            <v-row class="m-0">
              <v-col>
                <v-card-title>
                  <span class="headline">{{ container_title }}</span>
                </v-card-title>
              </v-col>
              <v-col cols="pull-right-10 p-2">
                <v-btn
                    text
                    icon
                    color="gray"
                    class="float-right"
                    @click="
                        closeAddEditDialog();
                        resetFields();
                        $emit('closeModal');
                    "
                >
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-container style="background-color: white; overflow-y: scroll">
              <v-col>
                <v-card-text v-if="loaded == true">
                  <form
                    class="form-horizontal"
                    action="/containers"
                    method="post"
                    @submit.prevent="onSubmit"
                  >
                    <div class="card-body">
                      <v-row>
                        <v-col class="mx-4">
                            <v-autocomplete
                                class="mt-1"
                                v-model="warehouse_id"
                                :items="GET_ALL_WAREHOUSES"
                                label="Select Warehouse"
                                outlined
                                dense
                                hide-details
                                auto-select-first
                                :disabled="disableInput()"
                            >
                            </v-autocomplete>
                          <span
                            v-for="errors in errors.warehouse_id"
                            class="text-warning"
                            :key="errors"
                            >{{ errors }}</span
                          >
                        </v-col>

                        <v-col class="mx-4">
                          <v-textarea
                            class="mt-1"
                            dense
                            label="Note"
                            no-resize
                            rows="2"
                            v-model="note"
                            :disabled="disableInput()"
                          ></v-textarea>
                        </v-col>

                        <v-col class="mx-4">
                          <v-textarea
                            class="mt-1"
                            dense
                            label="Remarks"
                            no-resize
                            rows="2"
                            v-model="remarks"
                            :disabled="disableInput()"
                          ></v-textarea>
                        </v-col>
                        <v-col class="mx-4">
                          <v-textarea
                            class="mt-1"
                            dense
                            label="Prepared By"
                            no-resize
                            rows="2"
                            v-model="prepared_by"
                            :disabled="disableInput()"
                          ></v-textarea>
                        </v-col>
                        <v-col class="mx-4">
                          <v-textarea
                            class="mt-1"
                            dense
                            label="Check By"
                            no-resize
                            rows="2"
                            v-model="check_by"
                            :disabled="disableInput()"
                          ></v-textarea>
                        </v-col>
                        <v-col md="2"> </v-col>
                      </v-row>
                      <v-row>
                        <v-col lg="3" class="mx-4">
                          <v-autocomplete
                            v-model="customer_id"
                            :items="GET_CUSTOMERS_SELECTION"
                            auto-select-first
                            item-value="value"
                            item-text="text"
                            class="mt-1"
                            label="Select Customer"
                            :disabled="disableInput()"
                            @input="getDocNums"
                          ></v-autocomplete>
                        </v-col>
                        <v-col :lg="isDropDownActive ? 2 : 3" class="mx-4">
                          <v-autocomplete
                            v-model="type.selected"
                            :items="type.selection"
                            item-value="value"
                            item-text="text"
                            class="mt-1"
                            label="Type"
                            :disabled="disableInput()"
                          ></v-autocomplete>
                        </v-col>
                        <v-col :lg="isDropDownActive ? 2 : 3" class="mx-4">
                          <v-autocomplete
                            v-model="source.selected"
                            :items="source.selection"
                            item-value="value"
                            item-text="text"
                            class="mt-1"
                            label="Select Source"
                            :disabled="disableInput() || customer_id == null || customer_id == ''"
                            @input="changeSource"
                          ></v-autocomplete>
                        </v-col>
                        <v-col  v-if="isDropDownActive" lg="2" class="mx-4">
                          <v-autocomplete
                            clearable
                            class="mt-1"
                            return-object
                            item-text="text"
                            v-model="docNum"
                            auto-select-first
                            :label="document_num"
                            :items="docNumsDropdown"
                            :disabled="disableInput()"
                            @input="getItemsForDropdown"
                          ></v-autocomplete>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col
                            lg="2"
                            class="d-flex"
                        >
                        <base-file-viewer class="mx-2"></base-file-viewer>
                        <file-upload-component class="mx-2" ref="fileUpload" @uploadedData="uploadedData" @reset="clearFileUpload"></file-upload-component>
                        <base-camera-capture class="mx-2" @capturedImages="getImages" ref="cameraCapture"></base-camera-capture>
                        </v-col>
                      </v-row>
                      <v-card outlined class="p-2">
                        <div class="row mt-4">
                        <div class="float-left mx-2">
                            <v-btn
                                :disabled="disabled_batch_addition || disableInput()"
                                dark
                                small
                                color="primary"
                                @click="openBatchDialog()"

                            >
                              <span>{{ batch_addition_text.title }}</span>
                            </v-btn>
                        </div>
                          <v-btn
                            fab
                            dark
                            small
                            color="primary"
                            style="height: 20px; width: 20px"
                            v-if="source.selected != 2"
                            @click="addItemDefault"
                          >
                          <v-icon small dark>mdi-plus</v-icon>
                        </v-btn>
                        <v-dialog
                            v-model="batch_addition.dialog"
                            persistent
                            max-width="30%"
                            max-height="30%"
                            scrollable
                          >
                            <v-card>
                              <v-row class="m-0">
                                <v-col>
                                  <v-card-title>
                                    <span class="headline">{{
                                        batch_addition_text.title
                                    }}</span>
                                  </v-card-title>
                                </v-col>
                                <v-col cols="pull-right-10 p-2">
                                  <v-btn
                                    text
                                    icon
                                    color="gray"
                                    class="float-right"
                                    @click="
                                      closeBatchAdditionDialog();
                                      dataFromExcel = '';
                                    "
                                  >
                                    <v-icon>mdi-close-circle</v-icon>
                                  </v-btn>
                                </v-col>
                              </v-row>
                              <v-card-text class="ma-0">
                                <span v-if="hasError">
                                  <span style="color: red">Red : </span>Model not
                                  found in the system</span
                                >
                                <div
                                  class="pt-4 pa-2"
                                  style="height: auto;
                                    width: 100%;
                                    overflow-y: hidden;
                                    overflow-x: hidden;"
                                >
                                  <v-row
                                    class="mx-0 pa-0 ma-0"
                                    v-for="(
                                      text_area_field, i
                                    ) in text_area_fields"
                                    :key="i"
                                  >
                                    <div class="col-5 pa-0 ma-0">
                                      <v-text-field
                                        :class="{
                                          notInModel: text_area_fields[i].error,
                                        }"
                                        class="InModel"
                                        dense
                                        outlined
                                        v-model="
                                          text_area_fields[i].dataFromExcel
                                        "
                                        rows="1"
                                        small
                                        color="primary"
                                        label="Model"
                                        :autofocus="batch_addition.dialog"
                                        @paste="onPaste"
                                        @change="validateInputModel(i)"
                                        @click="$event.target.focus()"
                                      >
                                      </v-text-field>
                                    </div>
                                    <div class="col-5 py-0 ma-0">
                                      <v-text-field
                                        :class="{
                                          notInModel: text_area_fields[i].error,
                                        }"
                                        class="InModel"
                                        dense
                                        outlined
                                        label="Quantity"
                                        v-model="
                                          text_area_fields[i].dataFromExcel2
                                        "
                                        rows="1"
                                        small
                                        color="primary"
                                      >
                                      </v-text-field>
                                    </div>
                                    <div class="col-2 ma-0">
                                      <v-btn
                                        fab
                                        dark
                                        small
                                        color="red"
                                        style="height: 20px; width: 20px"
                                        @click="clearItem(i)"
                                      >
                                        <v-icon
                                          dark
                                          small
                                          style="font-size: 12px"
                                        >
                                          mdi-minus
                                        </v-icon>
                                      </v-btn>
                                    </div>
                                  </v-row>
                                  <v-row>
                                    <v-col class="ml-3">
                                      <v-btn
                                        dark
                                        small
                                        color="primary"
                                        style="height: 20; width: 40px"
                                        @click="manualAddItem();"
                                      >
                                        <span> Add </span>
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </div>
                                <v-row>
                                  <div class="col-12">
                                    <v-btn
                                      :disabled="isDisabledButton"
                                      class="float-right ml-2"
                                      @click="submittedBtn()"
                                    >
                                      Submit
                                    </v-btn>
                                    <v-btn
                                      class="float-right"
                                      dark
                                      color="secondary"
                                      @click="clearFields()"
                                    >
                                      Clear
                                    </v-btn>
                                  </div>
                                </v-row>
                              </v-card-text>
                            </v-card>
                          </v-dialog>

                          <div class="col-sm-12 col-md-12 mt-4">
                            <v-simple-table class="dynamic_list_table">
                              <template v-slot:default>
                                <span>
                                  <thead>
                                    <tr>
                                      <th class="text-center mx-2" width="7%">
                                        UOM Qty
                                      </th>
                                      <th class="text-center mx-2" width="15%">
                                        Model
                                      </th>
                                      <th class="text-center mx-2" width="63%">
                                        Name
                                      </th>
                                      <th class="text-center mx-2" width="10%">
                                        UOM
                                      </th>
                                      <th class="text-center mx-2" width="5%">
                                        Type
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(
                                        container_item, i
                                      ) in container_items"
                                      :key="i"
                                    >
                                      <td>
                                        <v-text-field
                                          v-if="source.selected == 1"
                                          color="black"
                                          hide-details
                                          dense
                                          outlined
                                          type="number"
                                          :max="getMaxQty(container_items[i].model)"
                                          :min="0"
                                          class="alignText small mt-2"
                                          v-model="container_items[i].quantity"
                                          @input="checkInputQty(container_items[i])"
                                          >
                                        </v-text-field>
                                        <v-text-field
                                            :background-color="container_items[i].edit_color == undefined ? 'grey lighten-2': container_items[i].edit_color"
                                            v-else-if="source.selected == 2"
                                            color="black"
                                            hide-details
                                            dense
                                            outlined
                                            readonly
                                            class="alignText small mt-2"
                                            v-model="container_items[i].quantity"
                                        >

                                        </v-text-field>

                                        <v-text-field
                                          v-else
                                          color="black"
                                          hide-details
                                          dense
                                          outlined
                                          type="number"
                                          :min="0"
                                          class="alignText small mt-2"
                                          v-model="container_items[i].quantity"
                                          >
                                        </v-text-field>
                                      </td>
                                      <td>
                                        <v-text-field
                                            v-if="source.selected == 2"
                                            :background-color="container_items[i].edit_color == undefined ? 'grey lighten-2': container_items[i].edit_color"
                                            color="black"
                                            hide-details
                                            dense
                                            outlined
                                            readonly
                                            class="alignText small mt-2"
                                            v-model="container_items[i].model"
                                        >
                                        </v-text-field>
                                        <v-autocomplete
                                          v-else
                                          :loading="loading.itemDropdown"
                                          :items="itemsDropdown"
                                          v-model="container_items[i].model"
                                          item-value="text"
                                          item-text="text"
                                          class="alignText small mt-2"
                                          hide-details
                                          dense
                                          outlined
                                          @change="manualAddItem(container_items[i].model); "
                                        >
                                        </v-autocomplete>
                                      </td>
                                      <td>
                                        <v-text-field
                                            :background-color="container_items[i].color ? '' : 'grey lighten-2'"
                                            color="black"
                                            hide-details
                                            dense
                                            outlined
                                            readonly
                                            class="alignText small mt-2"
                                            :style="container_items[i].color"
                                            v-model="container_items[i].name"
                                        >
                                        </v-text-field>
                                      </td>
                                      <td>
                                        <v-text-field
                                            :background-color="container_items[i].color ? '' : 'grey lighten-2'"
                                            color="black"
                                            hide-details
                                            dense
                                            outlined
                                            readonly
                                            class="alignText small mt-2"
                                            :style="container_items[i].color"
                                            v-model="container_items[i].uom"
                                        >
                                        </v-text-field>
                                      </td>
                                      <td>
                                        <v-text-field
                                          :background-color="container_items[i].color ? '' : 'grey lighten-2'"
                                          color="black"
                                          hide-details
                                          dense
                                          outlined
                                          readonly
                                          class="alignText small mt-2"
                                          :style="container_items[i].color"
                                          v-model="container_items[i].type"
                                        >
                                        </v-text-field>
                                      </td>
                                      <td v-if="source.selected != 2">
                                        <v-btn
                                            fab
                                            dark
                                            small
                                            color="warning"
                                            style="height: 20px; width: 20px"
                                            @click="removeOrderByIndex(i)"
                                            >
                                            <v-icon
                                                dark
                                                small
                                                style="font-size: 12px"
                                            >
                                                mdi-minus
                                            </v-icon>
                                        </v-btn>
                                      </td>
                                    </tr>
                                  </tbody>
                                </span>
                              </template>
                            </v-simple-table>
                          </div>
                        </div>
                      </v-card>
                    </div>
                    <div class="border-top">
                      <div class="card-body py-4 d-flex justify-content-center">
                        <v-btn
                          :loading="waitForProcess"
                          @click="addNewWarehouseReturn()"
                          v-if="action == 'Add'"
                        >
                        submit</v-btn
                        >
                        <v-btn
                          :loading="waitForProcess"
                          @click="editWir()"
                          v-else-if="action == 'Edit'"
                        >
                          Update</v-btn
                        >
                      </div>
                    </div>
                  </form>
                </v-card-text>

                <v-card-text v-else-if="loaded == false">
                  <div class="text-center">
                    <v-progress-circular
                      :size="50"
                      color="primary"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                </v-card-text>
              </v-col>
            </v-container>
          </v-card>
        </v-dialog>
      </v-app>
    </v-row>
  </template>
  <script>

  import WarehouseReturnTableComponent from "./WarehouseReturnTableComponent.vue";
  import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue'
  import FileUploadComponent from "@/views/main/Shared/FileUploadComponent.vue";
  import BaseCameraCapture from "../../Services/Base/BaseCameraCapture.vue";
  import BaseFileViewer from "@/views/main/modules/Operations/WarehouseReturn/BaseFileViewr.vue";
  import { computed } from "vue";
  import { mapGetters } from "vuex";
  import getters from "@/store/getters";
  import swal from "sweetalert2";
  export default {
    mixins:[SharedFunctionsComponentVue],
    props: ["status", "radioGroup"],
    components: {
      WarehouseReturnTableComponent,
      FileUploadComponent,
      BaseCameraCapture,
      BaseFileViewer
    },
    data() {
      return {
        color: "orange",
        // buttons:buttons_components.buttons,
        action: "",
        check_by:'',
        prepared_by:'',
        batch_addition: {
            dialog: false,
            data: [],
            excel_item_id: null,
            errors: [],
        },
        removeItems:[],
        default_items:[],
        dataFromExcel: "",
        dataFromExcel2: "",
        dataFromExcel3: "",
        dataFromExcel4: "",
        editContainerId: "",
        add_edit_container_dialog: false,
        container_num: "",
        loading_list_id: "",
        loading_lists: [],
        warehouse_id: "",
        warehouses: [],
        broker: "",
        remarks: "",
        note:'',
        container_items: [],
        text_area_fields: [],
        received_type: "1",
        text_area_field: {
          dataFromExcel: "",
          dataFromExcel2: "",
        },
        container_item: {
          item_id: "",
          quantity: "",
          quantity_entered: "",
          remarks: "",
          packing_type: "",
          id: "",
          active: "",
          model: "",
          name: "",
          type_id: "",
          item_type_text: "",
          uom_id: "",
          item_uom_text: "",
          master_box_qty: "",
          small_box_qty: "",
          quantity_master_box: "",
          loading_list_item_id: "",
        },
        type:{
          selection:[
            { text:'Customer Pull out',value:1 },
            { text:'others',value:2 },
          ],
          selected:1
        },
        customer_id:'',
        scanner: {
          item_model: "",
          item_barcode: 0,
          quantity: 0,
        },
        autoOpenCamera: 0,
        date_created: "",
        user: "",
        items_list: [],
        max_items_count: 999,
        errors: [],
        action: "",
        actionResult: {
          model: "Container",
          action: "",
        },
        loaded: false,
        userAccess: [],

        packing_type_list: [
          { value: 1, text: "UOM" },
          { value: 2, text: "MB" },
          { value: 3, text: "SB" },
        ],

        waitForProcess: false,
        po_id: {
          selection: [],
          selected: "",
        },
        received_date: null,
        selected_po_number: "",
        isDone: true,
        isViewEdit: false,
        hasError: false,
        isDisabledButton: false,
        listOfNotInModels: [],
        counterBatch: 0,
        container_item_id: "",
        batch_addition_text: {
          title: "Batch Addition",
          quantity_text: "Quantity",
        },
        receiving_data: [],
        container_title: "Warehouse Return",
        disabled_batch_addition: false,
        loading: {
          warehouse_selection: true,
          itemDropdown: false,
        },
        images:null,
        source:{
          selection:[
            { text:'Others',value:0 },
            { text:'Packing List',value:1 },
            { text: 'Stock Card', value:2},
          ],
          selected:0
        },
        packingListNumsDropdown:[],
        stockCardNumsDropdown:[],
        wirItemsDropdown:[],
        packingListItems:[],
        isTNDropdownActive:false,
        docNum:null,
      };
    },
    methods: {
      onScanSuccess(decodedText, decodedResult) {},
      calculateFinalQty(index) {
        if (this.container_items[index].item_id != "") {
          let multiplier = 0;
          switch (this.container_items[index].packing_type) {
            case 1:
              multiplier = 1;
              break;
            case 2:
              multiplier = this.container_items[index].item.master_box_qty;
              break;
            case 3:
              multiplier = this.container_items[index].item.small_box_qty;
              break;
          }
          let quantity_entered =
            this.container_items[index].quantity_entered == ""
              ? 0
              : parseInt(this.container_items[index].quantity_entered);
          this.container_items[index].quantity = quantity_entered * multiplier;
        }
      },
      myFunction() {
        this.text_area_fields.splice(0, 1);
        this.batch_addition.dialog = true;
      },
      closeBatchAdditionDialog() {
        this.batch_addition.dialog = false;
      },
      closeAddEditDialog() {
        this.add_edit_container_dialog = false;
        this.isViewEdit = false;
      },
      openBatchDialog() {
        this.batch_addition.dialog = true;
        this.isDone = true;
        this.text_area_fields = [];

        if (this.text_area_fields.length < 1) {
          this.text_area_fields.push({
            dataFromExcel: "",
            dataFromExcel2: "",
            error: false,
          });
        }
        this.batch_addition.data = [];
        this.listOfNotInModels = [];
        this.hasError = false;
      },

       async submittedBtn() {

        if (this.isViewEdit == true) {
          for (var i = 0; i < this.text_area_fields.length; i++) {

            if(!!this.container_items.find(item=>item.model == this.text_area_fields[i].dataFromExcel)){

                this.container_items.find(item=>item.model == this.text_area_fields[i].dataFromExcel).quantity = parseInt(this.text_area_fields[i].dataFromExcel2.toString().replace(",", ""))
                this.container_items.find(item=>item.model == this.text_area_fields[i].dataFromExcel).quantity_entered = parseInt(this.text_area_fields[i].dataFromExcel2.toString().replace(",", ""))
                this.container_items.find(item=>item.model == this.text_area_fields[i].dataFromExcel).update_quantity = 1

            }else{
              let array = [ this.text_area_fields[i].dataFromExcel.toUpperCase() ];
              await this.$store.dispatch("getItemsByModel", {
                  items: array,
              }).then((response) => {

                let data = response.data[0]

                this.container_items.push({
                  warehouse_return_id: this.editContainerId,
                  id: this.container_num,
                  item_id: this.text_area_fields[i].dataFromExcel.toUpperCase(),
                  quantity: parseInt(this.text_area_fields[i].dataFromExcel2.toString().replace(",", "")),
                  quantity_entered: parseInt(
                  this.text_area_fields[i].dataFromExcel2
                  .toString()
                  .replace(",", "")
                  ),
                  remarks: "",
                  packing_type: "",
                  item: {
                    id: "",
                    active: "",
                    model: "",
                    name: "",
                    type_id: "",
                    item_type_text: "",
                    uom_id: "",
                    item_uom_text: "",
                    master_box_qty: "",
                    small_box_qty: "",
                  },
                  quantity_master_box: "",
                  quantity_entered: parseInt(
                    this.text_area_fields[i].dataFromExcel2
                    .toString()
                    .replace(",", "")
                    ),
                    remarks: "",
                    uom_id: "",
                    quantity_master_box_conv: "",
                    model: this.text_area_fields[i].dataFromExcel,
                    name: data.name,
                    loading_list_item_id: "",
                    item_uom_text: "",
                    item_type_text: "",
                    uom: data.uom_code,
                    type: data.type_code,
                    new_item : 11,
                    color: this.action == 'Edit' ? "background-color: #F9EFA0" : '',
                });
              })
            }
          }
          //   this.fetchAllData();
          this.counterBatch++;
          this.batch_addition.dialog = false;
        } else if (this.isViewEdit == false && !this.is_receiving) {
          this.isViewEdit = false;
          if (this.container_items.length < this.max_items_count) {
            if (this.counterBatch == 0) {
              this.container_items.pop();
            }

            for (var i = 0; i < this.text_area_fields.length; i++) {
              this.container_items.push({
                item_id: this.text_area_fields[i].dataFromExcel.toUpperCase(),
                quantity: parseInt(
                  this.text_area_fields[i].dataFromExcel2
                      .toString()
                      .replace(",", "")
                  ),
                quantity_entered: "",
                quantity_received: "",
                remarks: "",
                packing_type: "",
                id: "",
                active: "",
                model: this.text_area_fields[i].dataFromExcel.toUpperCase(),
                name: "",
                type_id: "",
                item_type_text: "",
                uom_id: "",
                item_uom_text: "",
                master_box_qty: "",
                small_box_qty: "",
                quantity_master_box: "",
                loading_list_item_id: "",
              });
            }
            this.fetchAllData();
            this.counterBatch++;
            this.batch_addition.dialog = false;
            this.disabled_batch_addition = true;
          }
        }
      },
      async showEditModal(value) {
        this.action = "Edit";
        this.editContainerId = value.id;
        this.text_area_fields.length = 0;
        this.batch_addition.data = [];
        this.listOfNotInModels = [];
        this.isViewEdit = true;
        this.disabled_batch_addition = false;
        if (this.editContainerId != null && this.editContainerId > 0) {
          let payload = {
              url:'show/' + this.editContainerId
          }
          await this.$store.dispatch('warehouseReturnGet',payload).then(response => {
            this.container_items = response.data
            this.default_items = response.data
            this.add_edit_container_dialog = true;
            this.warehouse_id = this.WIR_SELECTED.warehouse_id
            this.remarks    = this.WIR_SELECTED.remarks
            this.type.selected = parseInt(this.WIR_SELECTED.type)
            this.prepared_by = this.WIR_SELECTED.prepared_by
            this.check_by = this.WIR_SELECTED.check_by
            this.customer_id = this.WIR_SELECTED.customer_id
            this.note = this.WIR_SELECTED.note
            this.source.selected = parseInt(this.WIR_SELECTED.source)
            this.docNum = this.WIR_SELECTED.doc_num_object
            this.getPackingListNums(this.WIR_SELECTED.customer_id);
            this.getStockCardNums(this.WIR_SELECTED.customer_id);
            if (this.source.selected == 1) {
              this.$store.dispatch('warehouseReturnGet', {url:`dropdown/${this.docNum.id}/packing-list-items`}).then( response => {
                this.packingListItems = response.data;
              }).catch( e => console.log(e))
            }
            [1, 2].includes(this.source.selected) ? this.disabled_batch_addition = true : this.disabled_batch_addition = false;
          }).catch(error => {
              console.log(error)
              if(error.response.status == 422){
                this.errors = error.response.data.errors
              }
          });
        }
      },
      removeItem() {
        if (this.container_items.length > 1) {
          this.container_items.pop();
        }
      },
      clearFields() {
        this.isDone = true;
        this.text_area_fields = [];
        if (this.text_area_fields.length < 1) {
          this.text_area_fields.push({
            dataFromExcel: "",
            dataFromExcel2: "",
            error: false,
          });
        }
        this.batch_addition.data = [];
        this.listOfNotInModels = [];
        this.isDisabledButton = false;
        this.hasError = false;
      },
      clearItem(i) {
        let temp = [];
        if (this.text_area_fields.length == 1) {
          this.clearFields();
        } else {
          if (this.listOfNotInModels.length > 1) {

          } else {
            this.isDisabledButton = false;
          }
          this.text_area_fields.forEach((e, index) => {
            if (i != index) {
              temp.push(e);
              let checkingOfError = this.listOfNotInModels.includes(
                this.text_area_fields[i].dataFromExcel
              );
              if (checkingOfError) {
                this.listOfNotInModels.pop();
              }
            }
          });
          this.text_area_fields = temp;
        }
      },
      removeOrderByIndex(index) {
        this.removeItems.push(this.container_items[index])
        this.container_items.splice(index, 1);

        if (this.container_items.length == 0) {
          this.container_items = [];
          this.container_items.push({
            item_id: "",
            quantity: "",
            quantity_entered: "",
            quantity_received: "",
            remarks: "",
            packing_type: "",
            id: "",
            active: "",
            model: "",
            name: "",
            type_id: "",
            item_type_text: "",
            uom_id: "",
            item_uom_text: "",
            master_box_qty: "",
            small_box_qty: "",
            quantity_master_box: "",
            loading_list_item_id: "",
            color: this.action == 'Edit' ? "background-color: #F9EFA0" : ''
          });
        }

        return false;
      },
      addItem() {
        if (this.container_items.length < this.max_items_count) {
          this.container_items.push({
            item_id: "",
            quantity: "",
            quantity_entered: "",
            quantity_received: "",
            remarks: "",
            packing_type: "",
            id: "",
            active: "",
            model: "",
            name: "",
            type_id: "",
            item_type_text: "",
            uom_id: "",
            item_uom_text: "",
            master_box_qty: "",
            small_box_qty: "",
            quantity_master_box: "",
            loading_list_item_id: "",
          });
        }
      },
      showAddContainer() {
        this.container_items = [];
        this.isDone = true;
        this.text_area_fields.length = 0;
        this.batch_addition.data = [];
        this.listOfNotInModels = [];
        this.isViewEdit = false;
        this.counterBatch = 0;
        this.disabled_batch_addition = false;
        if (this.po_id.selection.find((e) => e.value === this.po_id.selected)) {
          this.selected_po_number = this.po_id.selection.find(
            (e) => e.value === this.po_id.selected
          ).text;
        }
        this.add_edit_container_dialog = true;
        this.action = "Add";
      },
      getWarehouseItemStock(item_id, warehouse_id, index) {
        let payload = {
          item_id: item_id,
          warehouse_id: warehouse_id,
        };
        this.$store
          .dispatch("getWarehouseItemStocks", payload)
          .then((response) => {
            let data = response.data;
            if (data != null) {
              if (this.container_items.length > 0) {
                this.container_items[index].stock = data;
              }
            } else {
              this.container_items[index].stock = 0;
            }
          });
      },
      closeQrScannerDialog(confirm) {
        if (confirm) {
        }
        return false;
      },
      async addNewWarehouseReturn() {
        this.waitForProcess = true;
        this.errors = [];
        this.counterBatch = 0;

        if(this.checkIfHasZeroQty()){
          swal.fire('Error!','- 0 quantity found, Please add items','error')
          this.waitForProcess = false;
          return
        }

        if(!this.warehouse_id){
            swal.fire('Error!','- Please select warehouse','error')
            this.waitForProcess = false;
            return false
        }

        let payload = {
            data:{
                warehouse_id: this.warehouse_id,
                remarks: this.remarks,
                type:this.type.selected,
                prepared_by:this.prepared_by,
                check_by:this.check_by,
                customer_id:this.customer_id,
                note:this.note,
                source: this.source.selected,
                doc_num: this.source.selected != 0 ? this.docNum.text : ''
            },
            items: this.container_items,
            url:'store'
        };

        await this.$store.dispatch("warehouseReturnPost", payload).then((response) => {

                const data = new FormData();

                const config = {
                    headers: {
                        "content-type": "multipart/form-data",
                    },
                };

                if (!!this.uploadedFiles && this.uploadedFiles.attachments.length > 0) {
                    let files = this.uploadedFiles.attachments;
                    for (let i = 0; i < _.compact(files).length; i++) {
                        data.append("files[]", files[i]);
                    }
                }

                if(!!this.images && this.images.length > 0) {
                    for(let i=0;i < _.compact(this.images).length;i++) {
                        data.append('images[]', _.compact(this.images)[i]);
                    }
                }

                data.append('id',response.data.id)
                this.$store.dispatch('warehouseReturnPost',{
                    config:config,
                    data:data,
                    url:'save-file'
                })
                this.resetFields()
                swal.fire('Success!',`- ${this.leadingZeros(response.data.id)} created`,'success')
                this.$store.dispatch('getAllcustomersSelection')
                this.add_edit_container_dialog = false

            })
            .catch((error) => {
                console.log(error)
                swal.fire(error.response.data.title,error.response.data.message,error.response.data.icons)
            if (error) {
                this.errors = error;
            }
          });
        this.$refs.child.getAllWarehouseReturn()
        this.waitForProcess = false;
      },
      editWir() {
        this.fetchAllData()

        if(this.checkIfHasZeroQty()){
          swal.fire('Error!','- 0 Quantity Found Please add items','error')
          this.waitForProcess = false;
          return
        }

        swal.fire({
            cancelButtonText:'cancel',
            reverseButtons: true,
            showCancelButton:true,
            confirmButtonText:'OK',
            title:'Warning',
            text:'Are you sure you want to update',
            icon:'warning'
        }).then(async confirm=>{
            if(confirm.isConfirmed){
                this.loaded = false
                const data = new FormData();
                let payload = {
                    data : {
                        warehouse_id: this.warehouse_id,
                        check_by:this.check_by,
                        prepared_by:this.prepared_by,
                        remarks:this.remarks,
                        note:this.note,
                        customer_id:this.customer_id,
                        source:this.source.selected,
                        doc_num:this.source.selected != 0 ? this.docNum.text : ''
                    },
                    url:'update-details/'+this.WIR_SELECTED.id,
                    withitems:true
                }

                await this.$store.dispatch('warehouseReturnGet',payload).then(response=>{
                    this.removeItems = []
                })
                await this.$store.dispatch('warehouseReturnPost',{
                    url:'update-items',
                    items:this.container_items.map(e=>{
                        let obj = {
                            item_id:e.item_id,
                            quantity:e.quantity,
                        }
                        return obj
                    }),
                    warehouse_return_id: this.WIR_SELECTED.id,
                    data: payload.data
                })
                this.$refs.child.getAllWarehouseReturn()
                swal.fire('Success !','','success')
                this.resetFields()
                const config = {
                    headers: {
                        "content-type": "multipart/form-data",
                    },
                };

                if (!!this.uploadedFiles && this.uploadedFiles.attachments.length > 0) {
                    let files = this.uploadedFiles.attachments;
                    for (let i = 0; i < _.compact(files).length; i++) {
                        data.append("files[]", files[i]);
                    }
                }

                if(!!this.images && this.images.length > 0) {
                    for(let i=0;i < _.compact(this.images).length;i++) {
                        data.append('images[]', _.compact(this.images)[i]);
                    }
                }

                data.append('id',this.WIR_SELECTED.id)
                this.$store.dispatch('warehouseReturnPost',{
                    config:config,
                    data:data,
                    url:'save-file'
                })

                this.add_edit_container_dialog = false
                this.loaded = true
            }
        })
      },
      resetFields() {
        this.errors = [];
        this.container_num = "";
        this.warehouse_id = "";
        this.loading_list_id = "";
        this.container_items = [];
        this.broker = "";
        this.remarks = "";
        this.user = "";
        this.action = "";
        this.po_id.selected = "";
        this.note = "";
        this.prepared_by = "";
        this.check_by = "";
        this.customer_id = "";
        this.source.selected = 0;
        this.docNum = null;
      },
      addKeyListener() {
        window.addEventListener(
          "keyup",
          function (e) {
            var keypressed = String.fromCharCode(e.keyCode);
            if (e.ctrlKey && e.keyCode == 32) {
              this.addItem();
              $("input").blur();
            } else if (keypressed == "-") {
              this.removeItem();
              $("input").blur();
            }
          }.bind(this)
        );
      },
      async fetchAllData() {
          if (this.isViewEdit === true) {
              let that = this;
              let duplicate_models = [];

            try {
              let dataCounter = 0;
              let models = this.container_items.map((e) => {

                return e.model.toUpperCase();
              });
              let mb_qty = this.container_items.map((e) => {

                return e.master_box_qty;
              });
              let filteredModel = models.filter(function (model, pos) {

                return models.indexOf(model) != pos;
              });
              duplicate_models.push(filteredModel);
              let showItemsAsString = duplicate_models.join("\r\n");

              if (filteredModel.length > 0) {
                swal
                  .fire({
                    title: "Warning",
                    text:
                      "Duplicate model detected \n NOTE: Please update the quantity of\n \n" +
                      showItemsAsString.split(",").join("\r\n"),
                    showConfirmButton: true,
                  })
                  .then((result) => {
                    if (result.isConfirmed) {
                      return false;
                    } else {
                      return false;
                    }
                  });
                }

              const sumByModel = this.container_items.reduce((acc, { model, quantity }) => {
                acc[model] = (acc[model] || 0) + Number(quantity);
                return acc;
              }, {});

              let quantities = this.container_items.map((e) => {
                return e.quantity;
              });

              let quantity_master_boxes = this.container_items.map((e) => {
                return e.quantity_master_box;
              });

              let payload = {
                items: models,
                isChecking: false, //true
              };

              await this.$store.dispatch("getItemsByModel", payload).then((response) =>
              {

                let designatedIndex = this.container_items.length - response.data.length;
                for (let x = designatedIndex; x < this.container_items.length; x++) {

                  let data = response.data[dataCounter];

                  this.container_items[x].warehouse_return_id = this.editContainerId;
                  // this.container_items[x].id = this.container_item_id;
                  this.container_items[x].item_id = data.item_id;
                  this.container_items[x].model = data.model
                    this.container_items[x].quantity_entered = that.container_items[x].quantity;
                    this.container_items[x].remarks = this.remarks;
                    this.container_items[x].type = data.type_code;
                    this.container_items[x].pending_quantity_received = that.container_items[x].quantity;
                    this.container_items[x].name = data.name;
                    this.container_items[x].quantity_master_box_conv = that.container_items[x].quantity;
                    this.container_items[x].received_type = 1;
                    this.container_items[x].uom = data.uom_code;
                    this.container_items[x].quantity_master_box = data.master_box_qty;
                    dataCounter++;
                  }

              });
            } catch (err) {
              console.log(err);
            }
          }

         else {
          this.isViewEdit = false;
          let that = this;
          let duplicate_models = [];

          try {
            let models = this.container_items.map((e) => {
              return e.model.toUpperCase();
            });
            let mb_qty = this.container_items.map((e) => {
              return e.master_box_qty;
            });

            let filteredModel = models.filter(function (model, pos) {
              return models.indexOf(model) != pos;
            });
            duplicate_models.push(filteredModel);
            let showItemsAsString = duplicate_models.join("\r\n");
            if (filteredModel.length > 0) {
              swal
                .fire({
                  title: "Warning",
                  text:
                    "Duplicate model detected \n NOTE: Model with duplicates will be automatically sum the quantity and removed duplicated model.\n \n" +
                    showItemsAsString.split(",").join("\r\n"),
                  buttons: true,
                })
                .then((confirm) => {
                  if (confirm) {
                  } else {
                    return false;
                  }
                });
            }
            const sumByModel = this.container_items.reduce((acc, {model, quantity}) => {
            acc[model] = (acc[model] || 0) + Number(quantity);
            return acc;
            }, {});

            let quantities = this.container_items.map((e) => {
              return e.quantity;
            });

            let quantity_master_boxes = this.container_items.map((e) => {
              return e.quantity_master_box;
            });
            let payload = {
              items: models,
              isChecking: false,
            };
            await this.$store.dispatch("getItemsByModel", payload).then((response) => {
              that.container_items = this.GET_ITEMS_BY_MODELS.filter((e, i) => {
                e.quantity = Object.values(sumByModel)[i];
                e.model = Object.keys(sumByModel)[i];
                e.quantity_master_box = quantity_master_boxes[i];
                  if (this.container_items[i].item_id != "") {
                    let quo = that.container_items[i].quantity / e.master_box_qty;
                    e.quantity_master_box = quo;
                  } else {
                    e.quantity_master_box = 0;
                  }
                  that.container_items[i].quantity = e.quantity;
                  that.container_items[i].id = e.id;
                  that.container_items[i].item_id = e.model;
                  that.container_items[i].master_box_qty = e.master_box_qty;
                  e.uom = e.uom_code
                  e.type = e.type_code

                  return e;
              });
            });
          } catch (err) {
            console.log(err);
          }
        }
      },
      manualAddItem(model = '') {
        let models = this.container_items.filter(e=>{
          if(e.model == model){
            return e
          }
        })

        if(models.length  == 1){
          this.text_area_fields.push({
            dataFromExcel: model,
            dataFromExcel2: "",
            error: false,
          });
          this.fetchAllData();
        }else{
          swal.fire({
            icon: "warning",
            title: "Duplicate Model Detected",
            allowOutsideClick: false,
            text: "NOTE: Model with duplicates will be removed, Please sum the quantity of existing model.",
            showConfirmButton: true,
          })
          this.container_items.pop()
        }
      },
      validateInputModel(index) {

        if (this.listOfNotInModels.length != 0) {
          let payload = {
            items: this.text_area_fields.map((e) => {
              return e.dataFromExcel.toUpperCase();
            }),
            isChecking: true,
          };
          this.$store.dispatch("getItemsByModel", payload).then((response) => {
            response.data = response.data.map((e) => {
              return e.model;
            });
            if (
              response.data.includes(
                this.text_area_fields[index].dataFromExcel.toUpperCase()
              )
            ) {
              this.isDisabledButton = false;
              this.text_area_fields[index].error = false;
            } else {
              this.text_area_fields[index].error = true;
              this.isDisabledButton = true;
            }
          });
        } else {
          let payload = {
            items: this.text_area_fields.map((e) => {
              return e.dataFromExcel.toUpperCase();
            }),
            isChecking: true,
          };
          this.$store.dispatch("getItemsByModel", payload).then((response) => {
            if(response.data != 'Item not found'){
              response.data = response.data.map((e) => {
                return e.model;
              });
              if(response.data.includes(this.text_area_fields[index].dataFromExcel.toUpperCase(),)) {
                this.text_area_fields[index].error = false;
              }
            }
            else {
              this.text_area_fields[index].error = true;
              this.listOfNotInModels.push(
                this.text_area_fields[index].dataFromExcel.toUpperCase()
              );
              this.isDisabledButton = true;
            }
          });
        }
      },
      getWarehouses(no_virtual = false) {
        // let payload = {
        // }
        // this.$store.dispatch('getAllWarehouses',payload).then((response)=>{
        //     this.warehouses = this.GET_ALL_WAREHOUSES
        // });
      },
      onPaste(evt) {
        this.batch_addition.data = []
        var pastedText = evt.clipboardData.getData('Text');
        if(!pastedText){
            return false;
        }
        else{
          var rows = pastedText.split("\r\n")
          var cells = '';
          for (var y in rows) {
            if (y != rows.length - 1) {
              cells = rows[y].split("\t");
              this.text_area_fields.push({
                  dataFromExcel: cells[0],
                  dataFromExcel2: cells[1],
                  error:false
              })
              if (y == rows.length - 1){
                  this.text_area_fields.push({
                      dataFromExcel: cells[0],
                      dataFromExcel2: cells[1],
                      error:false
                  })
              }
              if(this.is_receiving){
                this.receiving_data.push(Object.assign({},{
                    model: cells[0].trim(),
                    qty: cells[1].trim(),
                    // foc: cells[2],
                    id: '',
                    skip: false,
                }))
              }
              else{
                this.batch_addition.data.push(Object.assign({}, {
                    model: cells[0].trim(),
                    qty: cells[1].trim(),
                    // foc: cells[2],
                    id: '',
                    skip: false,
                }))
              }
            }
          }

          let payload={
            items: this.text_area_fields.map(e=>{ return e.dataFromExcel}),
            isChecking: false // true change into false
          }
          this.$store.dispatch('getItemsByModel',payload).then(response => {
            response.data = this.GET_ITEMS_BY_MODELS.map(e=>{ return e.model})
            this.text_area_fields.forEach((e, i)=>{
              if(response.data.includes(e.dataFromExcel.toUpperCase())){
              }
              else{
                this.listOfNotInModels.push(e.dataFromExcel.toUpperCase());
                this.text_area_fields[i].error = true;
              }
            })
            if(this.listOfNotInModels.length > 0){
              this.isDisabledButton = true
              this.hasError = true
            }
          });
          this.isDone = false;
          this.closeBatchAdditionDialog();
          setTimeout(this.myFunction, 1);
        }
      },
        cleanUserAccess(){
            this.userAccess = this.USER_ACCESS.map(e=>{
                return e.actions_code
            })
        },
        uploadedData(data) {
            if (!data) {
                this.uploadedFiles = null;
            }

            this.uploadedFiles = data;
        },
        getImages(images) {
            this.images = images;
        },
        clearCameraCapture() {
            this.$refs.cameraCapture.reset();
            this.images = null;
        },
        clearFileUpload(confirm) {
            if (confirm) {
                this.uploadedFiles = null;
            }
            this.uploadedFiles = null;
            this.$refs.fileUpload.reset();
        },

        disableInput(){
            if(!!this.WIR_SELECTED.status && this.WIR_SELECTED.status == 2){
                return true
            }

            return false
        },
        addItemDefault(){
          if(!!this.container_items[this.container_items.length - 1].model){
            this.container_items.push({
            warehouse_return_id: this.editContainerId,
            id: this.container_num,
            item_id: '',
            quantity: 0,
            quantity_entered: 0,
            remarks: "",
            packing_type: "",
            item: {
              id: "",
              active: "",
              model: '',
              name: "",
              type_id: "",
              item_type_text: "",
              uom_id: "",
              item_uom_text: "",
              master_box_qty: "",
              small_box_qty: "",
            },
            quantity_master_box: "",
            quantity_entered:0,
            remarks: "",
            uom_id: "",
            quantity_master_box_conv: "",
            model: '',
            loading_list_item_id: "",
            item_uom_text: "",
            item_type_text: "",
            new_item : 1,
            color: this.action == 'Edit' ? "background-color: #F9EFA0" : '',
            });
          }else{
        }
        },
        checkIfHasZeroQty() {
          let hasZeroQty = false;
          this.container_items.forEach((items, index) => {
            if (items.quantity <= 0) {
              hasZeroQty = true;
            }
          });
          return hasZeroQty;
        },
        getPackingListNums(cusId) {
          this.loading.itemDropdown = true;
          this.$store.dispatch('warehouseReturnGet', {customerId: cusId, url:'dropdown/packing-list-nums'}).then( response => {
            this.packingListNumsDropdown = response.data;
            this.loading.itemDropdown = false;
          }).catch( error => {
            console.log(error);
            this.loading.itemDropdown = false;
          })
        },
        getWarehouseReturnItemsDropdown() {
          this.$store.dispatch('warehouseReturnGet', {url:'dropdown/warehouse-return-items'}).then(response => {
            this.wirItemsDropdown = response.data;
          }).catch( error => {
            console.log(error);
          })
        },
        getStockCardNums(cusId) {
          this.$store.dispatch('warehouseReturnGet', {customerId: cusId, url:'dropdown/stock-card-nums'}).then(response => {
            this.stockCardNumsDropdown = response.data;
          }).catch(error => {
            console.log(error);
          })
        },
        changeSource(){
          this.container_items = [];
          [1, 2].includes(this.source.selected) ? this.disabled_batch_addition = true : this.disabled_batch_addition = false;
          this.container_items.push({
            item_id: "",
            quantity: "",
            quantity_entered: "",
            quantity_received: "",
            remarks: "",
            packing_type: "",
            id: "",
            active: "",
            model: "",
            name: "",
            type_id: "",
            item_type_text: "",
            uom_id: "",
            item_uom_text: "",
            master_box_qty: "",
            small_box_qty: "",
            quantity_master_box: "",
            loading_list_item_id: "",
            color: this.action == 'Edit' ? "background-color: #F9EFA0" : '',
            edit_color: this.action == 'Edit' ? "#F9EFA0" : "grey lighten-2"
          });
          this.getStockCardNums(this.customer_id);
          this.getPackingListNums(this.customer_id);
        },
        getItemsForDropdown() {
          if (this.docNum != null) {
            if (this.source.selected == 1) {
              this.loading.itemDropdown = true;
              this.changeSource();
              this.$store.dispatch('warehouseReturnGet', {url:`dropdown/${this.docNum.id}/packing-list-items`}).then( response => {
                this.packingListItems = response.data;
                this.loading.itemDropdown = false;
              }).catch( error => {
                console.log(error);
                this.loading = false;
              })
            } else if (this.source.selected == 2) {
              swal.fire('','Loading Items Please wait.')
              swal.showLoading()
              this.container_items = [];
              this.$store.dispatch('warehouseReturnGet', {url:`stock-card-items/${this.docNum.id}`}).then( response => {
                response.data.forEach( e => {
                  e = Object.assign(e, {color: this.action == 'Edit' ? "background-color: #F9EFA0" : '', edit_color: this.action == 'Edit' ? "#F9EFA0" : "grey lighten-2" })
                });
                this.container_items = response.data
                swal.close();
              }).catch( error => {
                console.log(error);
                swal.close();
              })
            }
          } else {

            this.packingListItems = [];
            this.container_items = [];
            this.changeSource();
          }
        },
        getMaxQty(id) {
          let item = this.packingListItems.find(e => e.text == id);
          if (item) {
            return item.pli_qty
          }
        },
        checkInputQty(item) {
          let plItem = this.packingListItems.find(e => e.text == item.model);
          if (plItem) {
            if (plItem.pli_qty < item.quantity) {
              swal.fire({text:'-Value above serve quantity.', icon:'warning'})
              item.quantity = 0;
              return
            }
          }
        },
        getDocNums() {
          if (this.source.selected !== 0) {
            this.docNum = null
            this.packingListItems = [];
            this.container_items = [];
            this.packingListNumsDropdown = [];
            this.stockCardNumsDropdown = [];
            this.changeSource();
            this.getStockCardNums(this.customer_id);
            this.getPackingListNums(this.customer_id);
          }
        }
    },
    computed: {
      ...mapGetters([
        "GET_ITEMS_BY_MODELS",
        "ADD_NEW_CONTAINERS",
        "EDIT_CONTAINERS",
        "USER_ACCESS",
        "GET_ALL_WAREHOUSES",
        'GET_ALL_OUTBOUND',
        'WIR_VIEW_DIALOG',
        'WIR_SELECTED',
        'GET_CUSTOMERS_SELECTION',
        'ACCESS_INBOUND',
        'GET_ITEM_SELECTION'
      ]),
      itemsDropdown() {
        const dropDownSource = {
            0: this.GET_ITEM_SELECTION,
            1: this.packingListItems,
            2: '',
          }
          return dropDownSource[this.source.selected];
      },
      docNumsDropdown() {
        const dropDownTransNums = {
          1: this.packingListNumsDropdown,
          2: this.stockCardNumsDropdown
        }
        return dropDownTransNums[this.source.selected];
      },
      document_num() {
        return `Select ${this.source.selected == 1 ? 'PL#' : (this.source.selected == 2 ? 'SC#' : '')}`
      },
      isDropDownActive() {
        return this.source.selected == 0 ? false : true;
      }

    },
    watch: {
      add_edit_container_dialog: function () {
        if (this.add_edit_container_dialog == true) {
          this.loaded = true;

          if (this.container_items.length == 0) {
            this.addItem();
          }
        }
      },
      warehouse_id: function (val) {
        if (val != null && val != "" && this.container_items.length > 0) {
          this.container_items.forEach((item, index) => {
            if (item.item_id != null && item.item_id != "") {
              this.getWarehouseItemStock(item.item_id, this.warehouse_id, index);
            }
          });
        }
      },
      GET_ALL_WAREHOUSES: {
        handler(val) {
          this.dealersList = val.data;
          this.loading.warehouse_selection = false;
        },
      },
    },
    mounted() {
        this.$store.dispatch('getAllcustomersSelection')
        this.$store.dispatch('itemselection');
        this.cleanUserAccess()
        this.getWarehouseReturnItemsDropdown();
    },
  };
  </script>

  <style>
  </style>
