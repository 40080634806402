<template>
    <v-dialog v-model="rfEditDialog" persistent max-width="98%" scrollable>
        <v-card class="pb-1">
            <v-container fluid style="background-color: white; overflow-y: scroll">
                <v-col>
                    <v-card-text>
                        <v-row class="m-0">
                            <v-col>
                                <v-card-title>
                                    <span class="headline">Repair Form Details</span>
                                </v-card-title>
                            </v-col>
                            <v-col cols="pull-right-10 p-2">
                                <v-btn
                                    text
                                    icon
                                    color="gray"
                                    class="float-right"
                                    @click="closeRfInquiryEditDialog()"
                                >
                                    <v-icon>mdi-close-circle</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-divider class="mt-0" />

                        <div class="px-3 pb-4" style="width:100%">
                            <v-layout row class="px-4 py-2 mb-2">
                                <v-flex lg6 style="font-weight:bold">
                                    Repair Form Information
                                </v-flex>
                                <v-flex lg6>
                                    <v-layout row class="mr-0">
                                        <v-spacer />
                                        <v-flex v-if="!!rfEstId" shrink class="pr-2 pt-2">
                                            <!-- <div style="text-align:end">
                                                <v-btn @click="showEstHistoryDialog()">Conversation</v-btn>
                                            </div> -->
                                            <v-tooltip bottom color="#f69f1a">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn @click="showEstHistoryDialog()"
                                                        :loading="loading"
                                                        color="#f69f1a"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        fab
                                                        small
                                                        icon
                                                        text
                                                        >
                                                        <v-icon color="#f69f1a">mdi-message-text</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Conversation</span>
                                            </v-tooltip>
                                        </v-flex>

                                        <v-flex style="text-align:end" shrink class="pr-2 pt-2">
                                            <!-- <v-btn @click="showOverride()">Print QrCode </v-btn> -->
                                            <v-tooltip bottom color="#f69f1a">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        @click="showOverride()"
                                                        :loading="loading"
                                                        color="#f69f1a"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        fab
                                                        small
                                                        icon
                                                        text
                                                    >
                                                        <v-icon color="#f69f1a">mdi-qrcode</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Print QrCode</span>
                                            </v-tooltip>
                                        </v-flex>

                                        <v-flex style="text-align:end" shrink class="pr-2 pt-2">
                                            <!-- <v-btn @click="showExportDialog()">Export</v-btn> -->
                                            <v-tooltip bottom color="#f69f1a">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        @click="showExportDialog()"
                                                        :loading="loading"
                                                        color="#f69f1a"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        fab
                                                        small
                                                        icon
                                                        text
                                                    >
                                                        <v-icon color="#f69f1a">mdi-printer-pos-wrench</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Export</span>
                                            </v-tooltip>
                                        </v-flex>

                                        <v-flex style="text-align:end" shrink class="pr-2 pt-2">
                                            <!-- <v-btn @click="override.override_dialog = true">Re-print </v-btn> -->
                                            <v-tooltip bottom  color="#f69f1a">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        @click="override.override_dialog = true"
                                                        :loading="loading"
                                                        color="#f69f1a"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        fab
                                                        small
                                                        icon
                                                        text
                                                    >
                                                        <v-icon color="#f69f1a">mdi-cloud-download</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Re-print RF</span>
                                            </v-tooltip>
                                        </v-flex>
                                        <v-flex style="text-align:end" shrink class="pr-2 pt-2">
                                            <v-tooltip bottom  color="#f69f1a">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                            fab
                                                            text
                                                            small
                                                            v-on="on"
                                                            v-bind="attrs"
                                                            color="#f69f1a"
                                                            :loading="loading"
                                                            @click="showFileUploadDialog()"
                                                        >
                                                            <v-icon>mdi-attachment</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>File Upload</span>
                                            </v-tooltip>
                                        </v-flex>
                                        <v-flex shrink class="pt-2">
                                            <div style="text-align:end">
                                                <ServiceFilesViewer v-if="!!rfId" :rfId="rfId" :rfNumber="rfNumber" :buttonColor="`#f69f1a`"/>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>


                            <v-card class="px-8 py-2 mb-2" :loading="loading">
                                <v-layout row class="pa-2">
                                    <v-flex lg3>
                                        <div>
                                            RF#:<span style="color:blue">{{ rfNumber }}</span>
                                        </div>
                                        <div>
                                            RD#:<span style="color:blue">{{ rrNumber }}</span>
                                        </div>
                                        <div>
                                            Pullout#:<span style="color:blue">{{ pulloutNumber }}</span>
                                        </div>
                                        <div>
                                            Issued Date:<span style="color:blue">{{ issuedDate }}</span>
                                        </div>
                                    </v-flex>

                                    <v-flex lg3>
                                        <div>
                                            Status: <span style="color:blue">{{ rfStatus }}</span>
                                        </div>
                                        <div>
                                            Assigned Technician: <span style="color:blue">{{ assignedTechnician }}</span>
                                        </div>
                                    </v-flex>

                                    <v-flex lg3>
                                        <div>
                                            Crate Number: <span style="color:blue">{{ crateNumber }}</span>
                                        </div>
                                        <div>
                                            Location Number: <span style="color:blue">{{ locationNumber }}</span>
                                        </div>
                                        <div v-if="!!itemRepairStatus">
                                            Repair Status:<span style="color:blue">{{ itemRepairStatus }}</span>
                                        </div>
                                        <div v-if="!!itemRepairStartedDate">
                                            Repair Start Date:<span style="color:blue">{{ itemRepairStartedDate }}</span>
                                        </div>
                                        <div v-if="!!itemRepairCompletedDate">
                                            Repair Completed Date:<span style="color:blue">{{ itemRepairCompletedDate }}</span>
                                        </div>
                                        <div v-if="!!itemRepairCancelledDate">
                                            Repair Cancelled Date:<span style="color:blue">{{ itemRepairCancelledDate }}</span>
                                        </div>
                                        <div v-if="!!itemQaApprovedBy">
                                            Approved QA/QC By:<span style="color:blue">{{ itemQaApprovedBy }}</span>
                                        </div>
                                        <div v-if="!!itemQaRejectedBy">
                                            Reject QA/QC By:<span style="color:blue">{{ itemQaRejectedBy }}</span>
                                        </div>
                                        <div v-if="!!itemQaRejectedBy">
                                            QA/QC Remarks:<span style="color:blue">{{ itemQaRemarks }}</span>
                                        </div>
                                    </v-flex>

                                    <v-flex v-if="!!approvedForReplacement" lg3>
                                        <div>
                                            Approved For Replacement By:<span style="color:blue">{{ approvedForReplacementBy }}</span>
                                        </div>
                                        <div>
                                            Approved For Replacement Date:<span style="color:blue">{{ approvedForReplacementDate }}</span>
                                        </div>
                                    </v-flex>

                                    <v-flex v-if="!!approvedForCm" lg3>
                                        <div>
                                            CM Number: <span style="color:blue">{{ cmNumber }}</span>
                                        </div>
                                        <div>
                                            Approved Cm By: <span style="color:blue">{{ approvedCmBy }}</span>
                                        </div>
                                        </v-flex>
                                </v-layout>
                            </v-card>

                            <!-- <div class="pa-2" style="font-weight:bold">
                                Document Information
                            </div> -->

                            <!-- <v-card class="px-4 py-2 mb-2" :loading="loading">
                                <v-layout class="pa-2">
                                    <v-flex lg3>
                                        <div>
                                            SQ#:<span style="color:blue">{{ sqNumber }}</span>
                                        </div>
                                        <div>
                                            SO#:<span style="color:blue">{{ soNumber }}</span>
                                        </div>
                                        <div>
                                            PL#:<span style="color:blue">{{ plNumber }}</span>
                                        </div>
                                    </v-flex>
                                </v-layout>
                            </v-card> -->

                            <!-- <div class="pa-2" style="font-weight:bold">
                                Delivery Information
                            </div>

                            <v-card class="px-4 py-2 mb-2" :loading="loading">
                                <v-layout class="pa-2">
                                    <v-flex lg3>
                                        <div>
                                            DR#:<span style="color:blue">{{ rfDeliveryNumber }}</span>
                                        </div>
                                        <div>
                                            Delivered Date:<span style="color:blue">{{ rfDeliveredDate }}</span>
                                        </div>
                                        <div>
                                            Collected Date:<span style="color:blue">{{ rfCollectedDate }}</span>
                                        </div>
                                        <div>
                                            Processed By:<span style="color:blue">{{ rfDeliveryProcessedBy }}</span>
                                        </div>
                                    </v-flex>
                                </v-layout>
                            </v-card> -->

                            <div class="pa-2" style="font-weight:bold">
                                Customer Information
                            </div>

                            <v-card class="px-8 py-2 mb-2" :loading="loading">
                                <v-layout row class="pa-2">
                                    <v-flex lg6>
                                        <div class="">
                                            <!-- <pre>{{ GET_SERVICE_ALL_CUSTOMERS }}</pre> -->
                                            <!-- <span style="color:blue">{{ companyName }}</span> -->
                                            <!-- GET_SERVICE_ALL_CUSTOMERS -->
                                            <v-autocomplete
                                                v-model="customerId"
                                                :items="GET_SERVICE_ALL_CUSTOMERS"
                                                style="width: 400px; color: blue"
                                                outlined
                                                dense
                                                item-value="id"
                                                item-text="company_name"
                                                @input="selectedCustomer()"
                                                label="Company Name"
                                                auto-select-first
                                                >
                                            </v-autocomplete>
                                        </div>
                                        <span v-if="this.customerId != 275">
                                            <div>
                                                Customer Name:<span style="color:blue">{{ customerName }}</span>
                                            </div>
                                            <div>
                                                Contact Number:<span style="color:blue">{{ customerContactNumber }}</span>
                                            </div>
                                            <div>
                                                Address:<span style="color:blue">{{ customerAddress }}</span>
                                            </div>
                                            <div>
                                                Email:<span style="color:blue">{{ customerEmal }}</span>
                                            </div>
                                        </span>
                                        <span class="pa-0 ma-0" v-if="this.customerId == 275">
                                            <v-form ref="form" v-model="valid">
                                                <v-row class="mt-2 mb-2">
                                                    <v-col cols='4'>
                                                        <v-text-field
                                                            v-model="customerFirstName"
                                                            style="width: 400px; color: blue"
                                                            outlined
                                                            dense
                                                            label="First Name"
                                                            required
                                                            :rules="rules"
                                                        >
                                                            <template #append >
                                                                <span style="color:red">*</span>
                                                            </template>
                                                        </v-text-field>
                                                    </v-col>

                                                    <v-col cols='4'>
                                                        <v-text-field
                                                            v-model="customerMiddleName"
                                                            style="width: 400px; color: blue"
                                                            outlined
                                                            dense
                                                            label="Middle Name"
                                                        >
                                                        </v-text-field>
                                                    </v-col>

                                                    <v-col cols='4'>
                                                        <v-text-field
                                                            v-model="customerLastName"
                                                            style="width: 400px; color: blue"
                                                            outlined
                                                            dense
                                                            label="Last Name"
                                                            :rules="rules"
                                                            required
                                                        >
                                                            <template #append >
                                                                <span style="color:red">*</span>
                                                            </template>
                                                        </v-text-field>
                                                    </v-col>

                                                </v-row>

                                                <v-row class="mt-2 mb-2">
                                                    <v-col cols='6'>
                                                        <v-text-field
                                                            v-model="contactNumber"
                                                            outlined
                                                            dense
                                                            label="Contact Number"
                                                            :rules="rules"
                                                            required
                                                        >
                                                            <template #append >
                                                                <span style="color:red">*</span>
                                                            </template>
                                                        </v-text-field>
                                                    </v-col>

                                                    <v-col cols='6'>
                                                        <v-text-field
                                                            v-model="customerEmail"
                                                            outlined
                                                            dense
                                                            label="Email"
                                                        >
                                                        </v-text-field>
                                                    </v-col>
                                                </v-row>

                                                <v-row class="mt-2 mb-2">
                                                    <v-col cols='12'>
                                                        <v-textarea
                                                            v-model="address"
                                                            outlined
                                                            dense
                                                            label="Address"
                                                            :rules="rules"
                                                            required
                                                        >
                                                            <template #append >
                                                                <span style="color:red">*</span>
                                                            </template>
                                                        </v-textarea>
                                                    </v-col>
                                                </v-row>
                                            </v-form>
                                        </span>
                                    </v-flex>
                                    <v-flex lg6>

                                    </v-flex>
                                </v-layout>
                            </v-card>

                            <div class="pa-2" style="font-weight:bold">
                                Item Information
                            </div>

                            <v-card class="px-8 py-2 mb-2" :loading="loading">
                                <v-layout row class="pa-2">
                                    <v-flex lg12>
                                        <div>
                                            Warranty Type:<span style="color:blue">{{ itemWarrantyType }}</span>
                                        </div>
                                        <div>
                                            Purchase Date:<span style="color:blue">{{ itemPurchaseDate }}</span>
                                        </div>
                                        <div>
                                            Item Model:
                                            <v-autocomplete
                                                v-model="itemId"
                                                :items="GET_ITEMS_EXCLUDE_SP"
                                                style="width: 400px"
                                                auto-select-first
                                                item-text="model"
                                                item-value="id"
                                                outlined
                                                dense
                                                @input="selectedItemModel()">
                                            </v-autocomplete>
                                        </div>
                                        <div>
                                            Item Name:<span style="color:blue">{{ itemName }}</span>
                                        </div>
                                        <div>
                                            Category:<span style="color:blue">{{ itemCategory }}</span>
                                        </div>
                                        <div>
                                            Serial No:<span style="color:blue">{{ itemSerial }}</span>
                                        </div>
                                        <div>
                                            Accessories:<span style="color:blue">{{ itemAccessories }}</span>
                                        </div>
                                        <div>
                                            Other Accessory:<span style="color:blue">{{ itemOtherAccessory }}</span>
                                        </div>
                                        <div>
                                            Unit Condition:<span style="color:blue">{{ itemUnitCondition }}</span>
                                        </div>
                                        <div>
                                            Other Unit Condition:<span style="color:blue">{{ itemOtherUnitCondition }}</span>
                                        </div>
                                        <div>
                                            Complaint / Symptom:<span style="color:blue">{{ itemDefectDescription }}</span>
                                        </div>
                                        <div>
                                            Remarks:<span style="color:blue">{{ itemDefectRemarks }}</span>
                                        </div>
                                        <div>
                                            Symptom As Tested:<span style="color:blue">{{ itemSymptomUponTested }}</span>
                                        </div>
                                        <div>
                                            Job Done:<span style="color:blue">{{ itemJobDone }}</span>
                                        </div>
                                        <div>
                                            Change Warranty Reason:<span style="color:blue">{{ itemWarrantyRejectReason }}</span>
                                        </div>
                                    </v-flex>
                                </v-layout>
                            </v-card>

                            <div style="margin-top: 6px; text-align: center;">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            v-bind="attrs"
                                            v-on="on"
                                            color="blue"
                                            fab
                                            small
                                            icon
                                            text
                                            outlined
                                            @click="updateRepairForm()"
                                        >
                                            <v-icon color="#f69f1a">mdi-content-save-all</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Save</span>
                                </v-tooltip>
                            </div>
                            <!-- <div class="pa-2" style="font-weight:bold">
                                Estimation Information
                            </div>

                            <v-card class="px-8 py-2 mb-2">
                                <v-data-table
                                    :headers="quotationHeaders"
                                    :items="quotations"
                                    :loading="loading"
                                >
                                <template v-slot:item.created_at="{ item }">
                                    {{ date(item.created_at) }}
                                </template>

                                <template v-slot:item.approved_at="{ item }">
                                    {{ date(item.approved_at) }}
                                </template>

                                <template v-slot:item.cancelled_at="{ item }">
                                    {{ date(item.cancelled_at) }}
                                </template>

                                <template v-slot:item.total_amount="{ item }">
                                    {{ item.total_amount | currency }}
                                </template>

                                <template v-slot:item.action="{ item }">
                                    <td class="px-0">
                                        <span class="text-nonwrap">
                                            <v-btn text icon color="orange">
                                                <v-icon class="btn-action" small @click="showQuotationItemsDialog(item)">fas fa-eye</v-icon>
                                            </v-btn>
                                        </span>
                                    </td>
                                </template>
                                </v-data-table>
                            </v-card> -->

                            <!-- <div class="pa-2" style="font-weight:bold">
                                Repair Form Movement
                            </div>

                            <v-expansion-panels focusable>
                                <v-expansion-panel>
                                    <v-expansion-panel-header>
                                        <v-data-table
                                            :headers="rfMovementheaders"
                                            :items="lastMovement"
                                            :items-per-page="lastMovement.length"
                                            :hide-default-footer="true"
                                            :loading="loading"
                                        >
                                            <template v-slot:item.updated_by="{ item }">
                                                {{ updatedBy(item) }}
                                            </template>

                                            <template v-slot:item.created_at="{ item }">
                                                {{ date(item.created_at) }}
                                            </template>
                                        </v-data-table>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-data-table
                                            :headers="rfMovementheaders"
                                            :items="rfMovements"
                                            :items-per-page="rfMovements.length"
                                            :hide-default-footer="true"
                                            :loading="loading"
                                        >
                                            <template v-slot:item.updated_by="{ item }">
                                                {{ updatedBy(item) }}
                                            </template>

                                            <template v-slot:item.created_at="{ item }">
                                                {{ date(item.created_at) }}
                                            </template>
                                        </v-data-table>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels> -->
    <!--
                            <div class="pa-2" style="font-weight:bold">
                                {{ `${itemRepairStatus == 'For-Replacement' ? 'Replaced' : 'Recommended'} Parts Information` }}
                            </div>

                            <v-card class="px-8 py-2 mb-2">
                                <v-data-table
                                    :headers="partsOrderItemsheaders"
                                    :items="partsOrderItems"
                                >
                                <template v-slot:item.created_at="{ item }">
                                    {{ date(item.created_at) }}
                                </template>
                                </v-data-table>
                            </v-card> -->
                        </div>

                        <!-- Quotation Items Dialog -->
                        <v-dialog v-model="quotationItemsDialog" persistent max-width="98%" scrollable>
                            <v-card style="height:100%">
                                <v-row class="m-0">
                                    <v-col>
                                        <v-card-title>
                                            <span class="headline">Sales Quotation Items</span>
                                        </v-card-title>
                                    </v-col>
                                    <v-col cols="pull-right-10 p-2">
                                        <v-btn text icon color="gray" class="float-right" @click="closeQuotationItemsDialog()">
                                            <v-icon>mdi-close-circle</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-divider class="my-0" />
                                <div class="pa-4">
                                    <v-data-table
                                        :headers="quotationItemsHeaders"
                                        :items="quotationItems"
                                    >
                                    <template v-slot:item.foc="{ item }">
                                        {{ !!item.foc ? 'Yes' : 'No' }}
                                    </template>

                                    <template v-slot:item.selling_price="{ item }">
                                        {{ item.selling_price | currency }}
                                    </template>

                                    <template v-slot:item.total="{ item }">
                                        {{ item.total | currency }}
                                    </template>
                                    </v-data-table>
                                </div>
                            </v-card>
                        </v-dialog>
                        <!-- FILE UPLOAD DIALOG -->
                        <v-dialog v-model="fileUploadIR" persistent max-width="20%">
                            <v-card class="pa-4">
                                <v-btn
                                text
                                icon
                                color="gray"
                                class="float-right"
                                @click="closeShowFileUploadDialog()"
                                >
                                <v-icon>mdi-close-circle</v-icon>
                                </v-btn>
                                <div class="py-2">
                                    <span>Attach Incident Report Document</span><span v-if="(this.uploadedIRFile == null)" style="color:red">  *</span>
                                    <v-divider/>
                                </div>

                                <div class="py-2 mt-2">
                                    <v-layout row class="mx-0">
                                        <v-flex lg2 class="mr-2">
                                            <ServiceFilesViewer :rfId="rfId" :rfNumber="rfNumber" :buttonColor="`#f69f1a`"/>
                                        </v-flex>
                                    </v-layout>
                                </div>

                                <div class="py-2 mt-3">
                                    <v-layout row class="mx-0">
                                        <v-flex :class="{'mr-2': true, 'lg3': !!uploadedIRFile && uploadedIRFile.attachments.length > 1, 'lg6': !!uploadedIRFile && uploadedIRFile.attachments.length == 1}">
                                            <div style="display:flex; align-items:center; gap: 2; width: 300px;">
                                                <FileUploadComponentVue ref="fileUpload" @uploadedData="uploadedData" @reset="clearFileUpload"/>
                                                <div v-if="!!uploadedIRFile && uploadedIRFile.attachments.length > 0" class="ml-2" >
                                                    Upload Count: {{ uploadedIRFile.attachments.length }}
                                                </div>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </div>
                                <div class="py-2 mt-3">
                                    <v-card-actions class="d-flex justify-content-end">
                                        <v-btn  @click="submitFileUpload()">Submit</v-btn>
                                    </v-card-actions>
                                </div>
                            </v-card>
                        </v-dialog>
                  </v-card-text>
              </v-col>
          </v-container>
      </v-card>

      <ConversationDialog :rfEstId="rfEstId" />

    <v-dialog v-model="exportDialog" persistent max-width="35%">
        <v-card>
            <v-row class="m-0">
                <v-col>
                    <v-card-title>
                        <span class="headline">Export</span>
                    </v-card-title>
                </v-col>

                <v-col cols="pull-right-10 p-2">
                    <v-btn text icon color="gray" class="float-right" @click="closeExportDialog()">
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-divider class="mt-0" />

            <div class="row mx-0">
                <div class="col-sm-12 col-md-4 mb-3">
                    <div style="text-align:center">
                        <v-btn @click="printEstimationForm()" color="#f69f1a">Sales Quotation Form</v-btn>
                    </div>
                </div>
            </div>
        </v-card>
    </v-dialog>

    <!-- For Estimation Form Print -->
    <v-container fluid style="display:none">
        <div id="sq_logo" >
            <div style="font-size: 12px; line-height: 1.9">
                <p>
                    <span style="font-size: 18px; font-weight: bold;">Estimation Form</span>
                    <br>RF#: {{ rfNumber }}, Crate# {{ crateNumber }}, Loc# {{ locationNumber }}
                    <br>Company Name: {{ companyName }}
                    <br>Customer Name: {{ customerName }}
                    <br>Warranty Type: {{ itemWarrantyType }}
                </p>
            </div>
        </div>
        <div id="sq_headers" >
            <div style="font-size: 12px; line-height: 1.9">
                <p>
                    <br>Model: {{ itemModel }}
                    <br>Name: {{ itemName }}
                    <br>Serial Number: {{ itemSerial }}
                    <br>Complaint / Symptom: {{ itemDefectDescription }}
                </p>
            </div>
        </div>

        <div>
            <table id="sq_items">
                <tbody>
                    <tr>
                        <td>Qty</td>
                        <td>Model</td>
                        <td>Name</td>
                        <td>Category</td>
                        <td>Selling Price</td>
                        <td>Discounted Price</td>
                    </tr>
                    <tr v-for="sqi in salesQuotationItems" :key="sqi.id">
                        <td>{{ sqi.quantity || '' }}</td>
                        <td>{{ sqi.view_item.model || '' }}</td>
                        <td>{{ sqi.view_item.name || '' }}</td>
                        <td>{{ sqi.view_item.item_category_text || '' }}</td>
                        <td>{{ sqi.srp || '' }}</td>
                        <td>{{ sqi.disc_price || '' }}</td>
                    </tr>
                    <tr>
                        <td colspan="6">Total: {{ salesQuotationItems?.length }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div>
            <table id="sq_footer">
                <tbody>
                    <tr>
                        <td>Date:</td>
                        <td>{{ dateToday }}</td>
                        <td>Received By:</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Technician:</td>
                        <td>{{ assignedTechnician }}</td>
                        <td>Prepared By:</td>
                        <td>{{ preparedBy }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <ConfirmOverrideComponentVue
            :approve_override_dialog="override.override_dialog"
            :departments_allowed="override.override_departments_allowed"
            :heading="'Re-Print RF'"
            :module_name="'Inquiry'"
            @closeConfirmOverride="closeConfirmOverride"
        ></ConfirmOverrideComponentVue>

        <ConfirmOverrideComponentVue
            :approve_override_dialog="override.override_dialogs"
            :departments_allowed="override.override_departments_allowed"
            :heading="'Override'"
            :module_name="'Inquiry'"
            @closeConfirmOverride="closeConfirmOverrides"
        ></ConfirmOverrideComponentVue>

        <ConfirmOverrideComponentVue
            :approve_override_dialog="override.overide_dialog_edit"
            :departments_allowed="override.override_departments_allowed"
            :heading="'Edit RF Details'"
            :module_name="'Inquiry'"
            @closeConfirmOverride="closeConfirmOverrideEdit"
        ></ConfirmOverrideComponentVue>

        <v-flex lg1 class="mr-2" @click="showQrDialog()" style="cursor:pointer">
            <VueQrcode :value="!!rfNumber ? rfNumber : 'rfNumber'" ref="qr" id="qr" :options="{ width: 120, errorCorrectionLevel: 'H' }"></VueQrcode>
        </v-flex>

        <PrintRepairFormComponentVue></PrintRepairFormComponentVue>
    </v-container>

  </v-dialog>
</template>

<script>
import ConversationDialog from '../RepairFormViewComponent/ConversationDialog.vue';
import ServiceFilesViewer from '../../Base/BaseFilesViewer.vue';
import PrintRepairFormComponentVue from '../../PrintRepairFormComponent.vue';
import FileUploadComponentVue from '@/views/main/Shared/FileUploadComponent.vue';
import { mapGetters } from 'vuex';
import $ from 'jquery';
import swal from 'sweetalert2';
import VueQrcode from 'qrcode.vue'
import ConfirmOverrideComponentVue from '@/views/main/Utils/ConfirmOverrideComponent.vue';
import repository from "@/api/modules/Services/index";

export default {
  data() {
        return {
            valid: false,
            print_repair_form:false,
            search: '',
            customerName: '',
            customerContactNumber: '',
            customerAddress: '',
            customerEmal: '',
            repairForm: null,
            rfNumber: '',
            assignedTechnician: '',
            issuedDate: '',
            rfStatus: '',
            rrNumber: '',
            pulloutNumber: '',
            itemWarrantyType: '',
            itemPurchaseDate: '',
            itemModel: '',
            itemName: '',
            itemCategory: '',
            itemSerial: '',
            itemAccessories: '',
            itemOtherAccessory: '',
            itemUnitCondition: '',
            itemOtherUnitCondition: '',
            itemDefectDescription: '',
            itemDefectRemarks: '',
            quotations: [],
            quotationItems: [],
            quotationHeaders: [
                { text: 'Quotation No', value: 'sales_quotation_number' },
                { text: 'Total Amount', value: 'total_amount', align: 'right' },
                { text: 'Quotation Date', value: 'created_at' },
                { text: 'Approved Date', value: 'approved_at' },
                { text: 'Cancelled Date', value: 'cancelled_at' },
                { text: 'Status', value: 'status' },
                { text: 'Action', value: 'action' },
            ],
            quotationItemsHeaders: [
                { text: 'Qty', value: 'requested_qty' },
                { text: 'Item Model', value: 'model' },
                { text: 'Item Name', value: 'name' },
                { text: 'Category', value: 'category' },
                { text: 'Selling Price', value: 'selling_price', align: 'right' },
                { text: 'Total', value: 'total' },
                { text: 'FOC', value: 'foc' },
                { text: 'Status', value: 'status' },
            ],
            partsOrderItemsheaders: [
                { text: 'Requested Qty', value: 'requested_qty' },
                { text: 'Item Model', value: 'model' },
                { text: 'Item Name', value: 'name' },
                { text: 'Category', value: 'category' },
                { text: 'Requested Date', value: 'created_at' },
                { text: 'Status', value: 'status' },
            ],
            partsOrderItems: [],
            quotationItemsDialog: false,
            rfEstId: '',
            rfMovementheaders: [
                { text: 'Status', value: 'status' },
                { text: 'Updated By', value: 'updated_by' },
                { text: 'Created At', value: 'created_at' },
            ],
            rfMovements: [],
            rfId: '',
            itemRepairStatus: '',
            itemRepairStartedDate: '',
            itemRepairCompletedDate: '',
            itemRepairCancelledDate: '',
            rfDeliveryNumber: '',
            rfDeliveredDate: '',
            rfCollectedDate: '',
            rfDeliveryProcessedBy: '',
            itemSymptomUponTested: "",
            itemJobDone: "",
            cmNumber: '',
            approvedCmBy: '',
            itemWarrantyRejectReason: '',
            approvedForReplacementBy: '',
            approvedForReplacementDate: '',
            approvedForCm: false,
            approvedForReplacement: false,
            itemQaApprovedBy: '',
            itemQaRejectedBy: '',
            itemQaRemarks: '',

            crateNumber: '',
            locationNumber: '',
            exportDialog: false,

            estItems: [],
            companyName: '',
            dateToday: this.$dayjs().format('YYYY-MM-DD, hh:mm:ss a'),
            preparedBy: '',
            lastMovement: [],

            sqNumber: '',
            soNumber: '',
            plNumber: '',
            override: {
                override_dialog: false,
                override_dialogs: false,
                overide_dialog_edit: false,
                errors: [],
                override_departments_allowed: ['SR'],
                override_authenticated: false,
            },
            printedQrcode: false,
            loading: true,
            customerId: '',
            itemId: '',
            uploadedIRFile: null,
            fileUploadIR: false,
            overrideSuccess: false,
            salesQuotations: [],
            salesQuotationItems: [],
            customerFirstName: '',
            customerMiddleName: '',
            customerLastName: '',
            contactNumber: '',
            address: '',
            customerEmail: '',
            receivingReportId: '',
            rules: [
                v => !!v || 'This field is required',
            ],
        }
    },
    components: {
        ConversationDialog,
        ServiceFilesViewer,
        PrintRepairFormComponentVue,
        ConfirmOverrideComponentVue,
        FileUploadComponentVue,
        VueQrcode,
    },
    props: ['repairFormId'],
    computed: {
        ...mapGetters([
            'GET_SEARCH_REPAIR_FORM',
            'GET_SERVICE_ALL_CUSTOMERS',
            'GET_ITEMS_EXCLUDE_SP',
        ]),
        rfEditDialog() {
            this.loading = true;
        return this.$store.state.service.rfEditDialog;
        }
    },
    watch: {
        repairFormId() {
            if(!!this.repairFormId) {
                this.getRfDetails();
            }
        },
        repairForm() {
            this.clearFields();
            if(!!this.repairForm) {
                this.loading = false;
                let rf = this.repairForm;
                this.receivingReportId = rf.sc_receiving_report_id
                this.rfId = this.repairForm.id;
                this.rfNumber = rf.rf_number;
                this.assignedTechnician = rf.assigned_technician;
                this.issuedDate = rf.created_at ? this.$dayjs(rf.created_at).format('YYYY-MM-DD, hh:mm:ss a') : '';
                this.rfStatus = rf.status;

                this.crateNumber = rf.crate_number;
                this.locationNumber = rf.location_number;

                if(!!this.repairForm.printed_qrcode) {
                    this.printedQrcode = true;
                } else {
                    this.printedQrcode = false;
                }

                if(!!rf.sales_order_id) {
                    this.soNumber = rf.sales_order_num;
                }

                if(!!rf.packing_list_id) {
                    this.plNumber = rf.packing_list_num;
                }

                if(!!rf.approved_for_cm) {
                    this.approvedForCm = !!rf.approved_for_cm;
                    this.cmNumber = rf.cm_number;
                    this.approvedCmBy = rf.approved_cm_by;
                }

                if(!!rf.approved_for_replacement) {
                    this.approvedForReplacement = !!rf.approved_for_replacement;
                    this.approvedForReplacementBy = rf.approved_for_replacement_by;
                    this.approvedForReplacementDate = this.$dayjs(rf.approved_for_replacement_at).format('YYYY-MM-DD, hh:mm:ss a');
                }


                let rr = this.repairForm.sc_receiving_report;
                if(!!rr) {
                    this.rrNumber = !!rr.rr_number ? rr.rr_number : '';
                    this.pulloutNumber = !!rr.pullout_number ? rr.pullout_number : '';

                    let contact = [];
                    let fullName = [];

                    if(rr.customer_id == 275) {
                        if(!!rr.sc_customer) {
                            if(!!rr.sc_customer.first_name) {
                                fullName.push(rr.sc_customer.first_name);
                            } if(!!rr.sc_customer.middle_name) {
                                fullName.push(rr.sc_customer.middle_name);
                            } if(!!rr.sc_customer.last_name) {
                                fullName.push(rr.sc_customer.last_name);
                            }

                            this.customerContactNumber = rr.sc_customer.contact_number;
                            this.customerAddress = rr.sc_customer.address;
                            this.customerEmal = rr.sc_customer.email;
                            this.customerFirstName = rr.sc_customer.first_name
                            this.customerMiddleName = rr.sc_customer.middle_name
                            this.customerLastName = rr.sc_customer.last_name
                            this.contactNumber = rr.sc_customer.contact_number
                            this.address = rr.sc_customer.address
                            this.customerEmail = rr.sc_customer.email
                        }
                        // Edited
                        let customer = this.GET_SERVICE_ALL_CUSTOMERS.find( cus => cus.id ===  rr.customer.id)
                        if(!!customer)
                        {
                            this.customerId = customer.id
                            this.companyName = customer.company_name;
                            this.customerName = `${customer.first_name} ${customer.middle_name} ${customer.last_name}`;
                        }

                    } else {
                        // Edited
                        let customer = this.GET_SERVICE_ALL_CUSTOMERS.find( cus => cus.id ===  rr.customer.id)
                        if(!!customer)
                        {
                            this.customerId = customer.id
                            this.companyName = customer.company_name;
                            this.customerName = `${customer.first_name} ${customer.middle_name} ${customer.last_name}`;
                            this.customerEmal = customer.email;
                            let customerAdd = JSON.parse(customer.shipping_addresses)
                            this.customerAddress = customerAdd[0].address;
                            if(!!customer.mobile_1) {
                                contact.push(customer.mobile_1);
                            }
                            if(!!customer.mobile_2) {
                                contact.push(customer.mobile_2);
                            }
                            if(!!customer.mobile_3) {
                                contact.push(customer.mobile_3);
                            }
                            if(!!customer.tel_1) {
                                contact.push(customer.tel_1);
                            }
                            if(!!customer.tel_2) {
                                contact.push(customer.tel_2);
                            }
                            if(!!customer.tel_3) {
                                contact.push(customer.tel_3);
                            }

                            this.customerContactNumber = contact.toString().replaceAll(',', ' / ');
                        }
                        // dxds
                        // this.companyName = rr.customer.company_name;
                        // this.customerContactNumber = contact.toString().replaceAll(',',' ');

                        // this.customerAddress = rr.customer.shipping_addresses.address;
                        // this.customerEmal = rr.customer.email;
                        // if(!!rr.customer.mobile_1) {
                        //     contact.push(rr.customer.mobile_1);
                        // }
                        // if(!!rr.customer.mobile_2) {
                        //     contact.push(rr.customer.mobile_2);
                        // }
                        // if(!!rr.customer.mobile_3) {
                        //     contact.push(rr.customer.mobile_3);
                        // }
                        // if(!!rr.customer.tel_1) {
                        //     contact.push(rr.customer.tel_1);
                        // }
                        // if(!!rr.customer.tel_2) {
                        //     contact.push(rr.customer.tel_2);
                        // }
                        // if(!!rr.customer.tel_3) {
                        //     contact.push(rr.customer.tel_3);
                        // }
                        // this.customerContactNumber = contact.toString().replaceAll(',', ' / ');
                    }

                    if(!!rf.sc_rf_movements && !!rf.sc_rf_movements.length > 0) {
                        this.rfMovements = _.compact(rf.sc_rf_movements);
                        this.lastMovement.push(_.last(_.compact(rf.sc_rf_movements)));
                    }
                }

                let rfi = this.repairForm.sc_repair_form_item;
                if(!!rfi) {
                    this.itemWarrantyType = rfi.warranty_type;
                    this.itemPurchaseDate = !!rfi.purchase_date ? rfi.purchase_date : '';
                    // Edited
                    let item = this.GET_ITEMS_EXCLUDE_SP.find(item => item.id === rfi.item_id)
                    if(!!item){
                        this.itemId = item.id;
                        this.itemModel = item.model;
                        this.itemName = item.name;
                        this.itemCategory = item.item_category;
                    }

                    if(!!rfi.replacement_serial_number) {
                        this.itemSerial = rfi.replacement_serial_number;
                    } else {
                        this.itemSerial = rfi.serial;
                    }

                    this.itemAccessories = rfi.accessories;
                    this.itemOtherAccessory = rfi.other_accessory;
                    this.itemUnitCondition = rfi.unit_condition;
                    this.itemOtherUnitCondition = rfi.other_unit_condition;
                    this.itemDefectDescription = rfi.defect_description;
                    this.itemDefectRemarks = rfi.defect_remarks;
                    this.itemRepairStatus = rfi.repair_status;
                    this.itemRepairStartedDate = rfi.repair_started_at == null ? '' : this.$dayjs(rfi.repair_started_at).format('YYYY-MM-DD, hh:mm:ss a');
                    this.itemRepairCompletedDate = rfi.repair_completed_at == null  ? ''  : this.$dayjs(rfi.repair_completed_at).format('YYYY-MM-DD, hh:mm:ss a');
                    this.itemRepairCancelledDate = rfi.repair_cancelled_at == null  ? ''  : this.$dayjs(rfi.repair_cancelled_at).format('YYYY-MM-DD, hh:mm:ss a');
                    this.itemSymptomUponTested = rfi.symptom_upon_tested;
                    this.itemJobDone = rfi.job_done;
                    this.itemQaRemarks = rfi.qa_remarks;

                    if(!!rfi.warranty_reject_reason) {
                        this.itemWarrantyRejectReason = rfi.warranty_reject_reason;
                    }

                    if(!!rfi.qa_approved_by) {
                        this.itemQaApprovedBy = rfi.qa_approved_by;
                    }

                    if(!!rfi.qa_rejected_by) {
                        this.itemQaRejectedBy = rfi.qa_rejected_by;
                    }

                }

                let rf_estimations = this.repairForm.sc_rf_estimations;
                if(!!rf_estimations) {
                    this.quotations = rf_estimations;

                    let quotationsCount = this.quotations.length;

                    if(quotationsCount > 0) {
                        let rfEstimation = _.last(this.quotations);

                        if(!!rfEstimation) {
                            this.rfEstId = rfEstimation.id;

                            this.estItems = rfEstimation.sc_rf_est_items;

                            if(!!rfEstimation.sales_quotation_id) {
                                this.sqNumber = rfEstimation.sales_quotation_number;
                            }
                        }
                    }
                }

                let parts_orders = this.repairForm.sc_parts_orders
                if(!!parts_orders && parts_orders.length > 0) {
                    this.partsOrderItems = parts_orders[parts_orders.length-1].sc_parts_order_items;
                }

                let delivery = this.repairForm.sc_delivery
                if(!!delivery) {
                    this.rfDeliveryNumber =  delivery.dr_number;
                    this.rfDeliveredDate =  this.$dayjs(delivery.delivered_at).format('YYYY-MM-DD, hh:mm:ss a');
                    this.rfCollectedDate =  this.$dayjs(delivery.received_at).format('YYYY-MM-DD, hh:mm:ss a');
                    this.rfDeliveryProcessedBy =  delivery.processed_by;
                }

                if(this.repairForm.sales_quotations) {
                    this.salesQuotations.push(this.repairForm.sales_quotations);
                    if (this.salesQuotations.length > 0) {
                        const sales_quotation = _.last(this.salesQuotations);
                        ({ sales_quotation_items: this.salesQuotationItems} = sales_quotation);
                    }
                }
            }
        },
        selectedCustr() {
            if(!!this.customerId)
            {
            this.selectedCustomer();
            }
        },
        selectedItem() {
            if(!!this.itemId) {
                this.selectedItemModel();
            }
        },
        customerId: {
            handler(val) {
                // console.log(val)
            }
        }
    },
    methods:{
        closeRfInquiryEditDialog() {
            this.repairForm = [];
            this.$emit('closeInquiryEditDetailsDialog', true);
            this.$store.commit('closeRfEditDialog');
            this.clearFields();
            this.overrideSuccess = false;
            this.loading = true;
        },
        clearFields() {
            this.rfNumber = '';
            this.assignedTechnician = '';
            this.issuedDate = '';
            this.rfStatus = '';
            this.rrNumber = '';
            this.pulloutNumber = '';
            this.companyName = '';
            this.customerName = '';
            this.customerContactNumber = '';
            this.customerAddress = '';
            this.customerEmal = '';
            this.rfEstId = '';
            this.partsOrderItems = [];
            this.quotations = [];
            this.itemRepairStatus = '';
            this.itemRepairStartedDate = '';
            this.itemRepairCompletedDate = '';
            this.rfDeliveryNumber = '';
            this.rfDeliveredDate = '';
            this.rfCollectedDate = '';
            this.rfDeliveryProcessedBy = '';
            this.itemWarrantyType = '';
            this.itemPurchaseDate = '';
            this.itemModel = '';
            this.itemName = '';
            this.itemCategory = '';
            this.itemSerial = '';
            this.itemAccessories = '';
            this.itemOtherAccessory = '';
            this.itemUnitCondition = '';
            this.itemOtherUnitCondition = '';
            this.itemDefectDescription = '';
            this.itemDefectRemarks = '';
            this.rfMovements = [];
            this.rfId = '';
            this.cmNumber = '';
            this.approvedCmBy = '';
            this.itemWarrantyRejectReason = '';
            this.approvedForReplacementBy = '';
            this.approvedForReplacementDate = '';
            this.approvedForCm = false;
            this.approvedForReplacement = false;
            this.itemQaApprovedBy = '';
            this.itemQaRejectedBy = '';
            this.lastMovement = [];
            this.sqNumber = '';
            this.soNumber = '';
            this.plNumber = '';
            this.locationNumber = '';
            this.customerId = '';
            this.itemId = '';
            this.itemSymptomUponTested = '';
            this.crateNumber = '';
            this.itemJobDone = '';
            this.salesQuotations = [];
            this.customerFirstName = '';
            this.customerMiddleName = '';
            this.customerLastName = '';
            this.contactNumber = '';
            this.address = '';
            this.customerEmail = '';
        },
        getRfDetails() {
            this.repairForm = [];
            this.$store.dispatch('searchRepairForm',this.repairFormId).then(res=>{
                this.repairForm = this.GET_SEARCH_REPAIR_FORM;
            })
        },
        date(date) {
            if(!!date) {
                return this.$dayjs(date).format('YYYY-MM-DD, hh:mm:ss a');
            }

            return '';
        },
        showQuotationItemsDialog(item) {
            this.quotationItemsDialog = true;

            if(!!item.sc_rf_est_items) {
                this.quotationItems = item.sc_rf_est_items;
            }
        },
        closeQuotationItemsDialog() {
            this.quotationItemsDialog = false;
        },
        showEstHistoryDialog() {
            this.$store.commit("showConversationDialog");
        },
        updatedBy(item) {
            if(!!item) {
                return item.updated_by.name;
            }

            return '';
        },
        printEstimationForm() {
            let doc = new this.$jspdf('p', 'mm', 'letter');

            doc.setFont(undefined, 'bold').setFontSize(13).text(6,8,"Sales Quotation Form")
            doc.setFont(undefined, 'normal').setFontSize(9).text(6,13,"RF#: "+this.rfNumber + " Crate# "+this.crateNumber+" Loc# "+this.locationNumber +"\n\nCompany Name: "+ this.companyName+"\n\nCustomer Name: "+ this.customerName+"\n\nWarranty Type: "+this.itemWarrantyType)
            doc.setFont(undefined, 'normal').setFontSize(9).text(140,13,"Model: "+this.itemModel+"\n\nName: "+this.itemName+"\n\nSerial Number: "+ this.itemSerial+"\n\nComplaint / Symptom: "+ this.itemDefectDescription);

            doc.autoTable({
                html: '#sq_items',
                theme: 'grid',
                startY: 42,
                margin: {top: 37, left: 7, right: 6},
                columnStyles: {
                    0: {cellWidth: 15, halign:'left', textColor: '#000000', fontSize: 8},
                    1: {cellWidth: 35, halign:'left', textColor: '#000000', fontSize: 8},
                    3: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                    4: {cellWidth: 35, halign:'left', textColor: '#000000', fontSize: 8},
                    5: {cellWidth: 35, halign:'left', textColor: '#000000', fontSize: 8},
                }
            });
            doc.autoTable({
                html: '#sq_footer',
                theme: 'grid',
                startY: 250,
                margin: {top: 37, left: 7, right: 6},
                columnStyles: {
                    0: {cellWidth: 24, halign:'left', textColor: '#000000', fontSize: 8},
                    1: {cellWidth: 77, halign:'left', textColor: '#000000', fontSize: 8},
                    2: {cellWidth: 24, halign:'left', textColor: '#000000', fontSize: 8},
                    3: {cellWidth: 77, halign:'left', textColor: '#000000', fontSize: 8},
                }
            });
            doc.autoPrint();
            window.open(doc.output('bloburl'), '_blank');
        },
        showExportDialog() {
            this.exportDialog = true;
        },
        closeExportDialog() {
            this.exportDialog = false;
        },
        // printed(){
        //     this.print_repair_form = false
        // },
        closeConfirmOverride(user_id, authenticated = false) {
            if (authenticated) {
                this.override.override_dialog = false;
                this.$store.commit('PRINT_RF_ID', this.rfId);
            } else {
                // swal.fire('Error!','- invalid password','error')
                this.override.override_dialog = false;
            }
        },

        closeConfirmOverrides(user_id, authenticated = false) {
            if (authenticated) {
                this.override.override_dialogs = false;
                this.downloadQrcode()
            } else {
                // swal.fire('Error!','- invalid password','error')
                this.override.override_dialogs = false;
            }
        },

        closeConfirmOverrideEdit(user_id, authenticated = false) {
            if (authenticated) {
                this.overrideSuccess = true;
                this.updateRepairForm();
                this.override. overide_dialog_edit = false;
            } else {
                this.override. overide_dialog_edit = false;
            }
        },

        showOverride() {
            if(!!this.printedQrcode) {
                this.override.override_dialogs = true;
            } else {
                this.downloadQrcode();
            }
        },

        downloadQrcode() {
            let pdf = new this.$jspdf('l', 'mm', [25,110]);
            // let pdf = new this.$jspdf('l', 'mm', 'letter');
            const assetTagCanvas = document.querySelector('#qr canvas');
            let image = assetTagCanvas.toDataURL('image/jpeg');


            var width = pdf.internal.pageSize.getWidth();
            var height = pdf.internal.pageSize.getHeight();

            pdf.addImage(image, 'png', 3,3,17,17);

            // pdf.setFontSize(6);
            // pdf.text(`Customer: ${this.customerName}`, 22,5);

            pdf.setFontSize(6);
            pdf.text(`RF#: ${this.rfNumber}`, 22,6);

            pdf.setFontSize(6);
            pdf.text(`Model: ${this.itemModel}`, 22, 10);

            pdf.setFontSize(6);
            pdf.text(`Serial: ${this.itemSerial}`, 50, 6);

            pdf.setFontSize(6);
            pdf.text(`Category: ${this.itemCategory}`, 50, 10);

            pdf.setFontSize(6);
            pdf.text(this.companyName, 22, 14);

            pdf.setFontSize(6);
            pdf.text(this.customerName, 22, 18);


            if(!!this.customerName) {
                pdf.setFontSize(6);
                pdf.text(`Date: ${this.rfDate}`, 50, 18);
            } else {
                pdf.setFontSize(6);
                pdf.text(`Date: ${this.rfDate}`, 22, 18);
            }


            pdf.addImage(image, 'png', 78,3,17,17);

            // open pdf to another window
            pdf.output('dataurlnewwindow');

            // pdf.save(`${this.rfNumber}-Qrcode.pdf`); allow to download pdf
            let payload = {
                rfId: this.rfId
            }
            this.$store.dispatch('updatePrintedQrcode',payload).then((response) => {
                if(response.data.msg) {
                    this.getRfDetails(this.rfId);
                    swal.fire('', response.data.msg, 'success');
                }
            }).catch((e)=> {
                console.log(e)
            })
        },
        selectedCustomer(){
            if(!!this.customerId)
            {
                let contact = [];
                let customer = this.GET_SERVICE_ALL_CUSTOMERS.find( cus => cus.id ===  this.customerId);
                this.customerName = `${customer.first_name} ${customer.middle_name} ${customer.last_name}`;
                this.customerEmal = customer.email;
                let customerAdd = JSON.parse(customer.shipping_addresses)
                this.customerAddress = customerAdd[0].address;
                if(!!customer.mobile_1) {
                    contact.push(customer.mobile_1);
                }
                if(!!customer.mobile_2) {
                    contact.push(customer.mobile_2);
                }
                if(!!customer.mobile_3) {
                    contact.push(customer.mobile_3);
                }
                if(!!customer.tel_1) {
                    contact.push(customer.tel_1);
                }
                if(!!customer.tel_2) {
                    contact.push(customer.tel_2);
                }
                if(!!customer.tel_3) {
                    contact.push(customer.tel_3);
                }
                this.customerContactNumber = contact.toString().replaceAll(',', ' / ');
            }
        },
        selectedItemModel() {
            if(!!this.itemId) {
                this.itemName  = this.GET_ITEMS_EXCLUDE_SP.find(item => item.id == this.itemId).name;
                this.itemCategory  = this.GET_ITEMS_EXCLUDE_SP.find(item => item.id == this.itemId).item_category;
            }
        },
        updateRepairForm(){
            if(this.customerId == 275) {
                if(this.valid == false) {
                    this.$refs.form.validate()
                    return;
                } else {
                    let payload = {
                    rfId: this.rfId,
                    customerFirstName: this.customerFirstName,
                    customerMiddleName: this.customerMiddleName,
                    customerLastName: this.customerLastName,
                    contactNumber: this.contactNumber,
                    address: this.address,
                    customerEmail: this.customerEmail,
                    customerId: this.customerId,
                    receivingReportId: this.receivingReportId,
                    item: {
                        id: this.itemId,
                        category: this.itemCategory,
                    }
                    };
                    return new Promise((resolve, reject) =>{
                        repository.updateRepairForm(payload).then((response) => {
                            if (response.status == 200) {
                                resolve(response)
                                this.clearFileUpload(confirm);
                                this.closeRfInquiryEditDialog();
                                swal.fire('Success', '-RF Details was updated successfuly', 'success');
                            }
                        }).catch((error) => {
                            reject(error)
                            console.error(error)
                        });
                    })
                }
            }
            if(!this.uploadedIRFile) {
                swal.fire('Info!','- Please upload file document','info');
            } else if (this.overrideSuccess === false) {
                swal.fire('Info!', '-Need Confirm Override to proceed.', 'info').then(ok => {
                    if(ok.isConfirmed) {
                            return this.override.overide_dialog_edit = true;
                    }
                });
            }  else {
                let payload = {
                    rfId: this.rfId,
                    customerFirstName: this.customerFirstName,
                    customerMiddleName: this.customerMiddleName,
                    customerLastName: this.customerLastName,
                    contactNumber: this.contactNumber,
                    address: this.address,
                    customerEmail: this.customerEmail,
                    customerId: this.customerId,
                    receivingReportId: this.receivingReportId,
                    item: {
                        id: this.itemId,
                        category: this.itemCategory,
                    }
                };
                return new Promise((resolve, reject) =>{
                    repository.updateRepairForm(payload).then((response) => {
                        if (response.status == 200) {
                            resolve(response)
                            this.clearFileUpload(confirm);
                            this.closeRfInquiryEditDialog();
                            swal.fire('Success', '-RF Details was updated successfuly', 'success');
                        }
                    }).catch((error) => {
                        reject(error)
                        console.error(error)
                    });
                })
            }
        },
        showFileUploadDialog(){
            this.fileUploadIR = true;
        },
        uploadedData(data) {
            if(!data) {
                this.uploadedIRFile = null;
            }

            this.uploadedIRFile = data;
        },
        submitFileUpload(){
            const data = new FormData();

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            if(!!this.uploadedIRFile && this.uploadedIRFile.attachments.length > 0) {
                let files = this.uploadedIRFile.attachments;

                for(let i=0;i < _.compact(files).length;i++) {
                    data.append('files[]', files[i]);
                }

                data.append('rfId', this.rfId);
                swal.fire({
                    title: 'Are you sure you want to upload the File?',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then(confirm=>{
                    if(confirm.isConfirmed){
                    let payload = {
                        rfId: this.rfId,
                        params: data,
                        config: config,
                    };
                    this.$store.dispatch('uploadWarranty', payload).then(response=>{
                    this.override. overide_dialog_edit = true;
                    this.closeShowFileUploadDialog();
                    swal.fire("Success", '', "success");
                    }).catch(err=>{
                        console.log(err)
                        swal.fire('Failed!','','error')
                        })
                    }
                });
            } else if (this.uploadedIRFile === null) {
                swal.fire('Info!','- Please select file document','info');
            }
        },
        clearFileUpload(confirm) {
            if(confirm) {
                this.uploadedIRFile = null;
            }
        },
        closeShowFileUploadDialog(){
            this.fileUploadIR = false;
            // this.clearFileUpload(confirm)
            this.$refs.fileUpload.reset();
        },
  },
  mounted() {
    this.$store.dispatch('getServiceAllCustomers')
    this.$store.dispatch('dropdownGet',{url:'itemsExcludedSp'});
  }
}
</script>

<style scoped>

.v-select__selection,
.v-select__selection--comma,
.v-select.v-text-field input {
    color: blue !important;
    }

</style>
