<template>
    <div>
        <AddEditPaymentTypeDialogComponentVue class="ml-3" />

        <v-card>
            <v-card-title>
                <v-layout row class="mx-3">
                    <v-spacer />
                    <v-flex lg2>
                        <div style="text-align:end">
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Name"
                                single-line
                                hide-details
                                clearable
                            />
                        </div>
                    </v-flex>
                </v-layout>
            </v-card-title>

            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="paymentTypes"
                    :search="search"
                    :no-data-text="noDataText"
                    :loading="loading"
                >
                    <template v-slot:[`item.active`]="{ item }">
                        {{ !!item.active }}
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <span class="text-nowrap">
                            <v-btn text icon color="orange">
                                <v-icon class="btn-action" small @click="viewPaymentType(item.id)" v-if="userAccess.view">mdi-eye</v-icon>
                            </v-btn>
                            <v-btn text icon color="blue">
                                <v-icon class="btn-action" small @click="editPaymentType(item)" v-if="userAccess.edit">mdi-square-edit-outline</v-icon>
                            </v-btn>
                            <v-btn text icon color="red">
                                <v-icon class="btn-action" small @click="deletePaymentType(item.id)" v-if="userAccess.delete">mdi-delete</v-icon>
                            </v-btn>
                        </span>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AddEditPaymentTypeDialogComponentVue from '../PaymentsComponent/AddEditPaymentTypeDialogComponent.vue';
import swal from 'sweetalert2';
export default {
    data() {
        return {
            headers: [
                { text: 'Code', value: 'code', },
                { text: 'Name', value: 'name' },
                { text: 'Active', value: 'active' },
                { text: 'Action', value: 'actions' },
            ],
            paymentTypes: [],
            search: '',
            loading: true,
            noDataText: '',
            paymentTypeId: '',
            userAccess: {
                    create  : false,
                    edit    : false,
                    view    : false,
                    delete  : false,
                },
        }
    },
    components: {
        AddEditPaymentTypeDialogComponentVue
    },
    mounted() {
        this.getAllPaymentTypes();
        this.checkAccess();
    },

    watch: {
        // USER_ACCESS:{
        //     handler(val){
        //         this.userAccess.create = false;
        //         this.userAccess.edit = false;
        //         this.userAccess.delete = false;
        //         this.userAccess.view = false;
        //         if(val != "fail"){
        //         val.forEach((e) => {
        //             if (e.actions_code == "create"){
        //                 this.userAccess.create = true;
        //             }
        //             else if (e.actions_code == "edit"){
        //                 this.userAccess.edit = true;
        //             }
        //             else if (e.actions_code == "view"){
        //                 this.userAccess.view = true;
        //             }
        //             else if (e.actions_code == "delete"){
        //                 this.userAccess.delete = true;
        //             }
        //          });
        //         }
        //     },
        // },
        paymentTypes() {
            if(this.paymentTypes.length > 0) {
                this.loading = false;
            } else {
                this.loading = false;
                this.noDataText = 'No Data';
            }
        },
        successAddEdit() {
            if(!!this.successAddEdit) {
                this.getAllPaymentTypes();
            }
        }
    },
    computed: {
        ...mapGetters([
            'GET_ALL_PAYMENT_TYPES',
            'USER_ACCESS'
        ]),
        successAddEdit() {
            return this.$store.state.accounting.paymentType.successAddEdit;
        },
    },
    methods: {
        checkAccess(){
                    let val = this.USER_ACCESS;
                    if(val != "fail"){
                        val.forEach((e) => {
                            if (e.actions_code == "create"){
                                this.userAccess.create = true;
                            }
                            else if (e.actions_code == "edit"){
                                this.userAccess.edit = true;
                            }
                            else if (e.actions_code == "view"){
                                this.userAccess.view = true;
                            }
                            else if (e.actions_code == "delete"){
                                this.userAccess.delete = true;
                            }
                        });
                    }
        },
        clearFields() {
            this.search = '';
            this.noDataText = '';
            this.loading = true;
        },
        getAllPaymentTypes(){
            this.$store.dispatch('getAllPaymentTypes').then(response=>{
                this.paymentTypes = this.GET_ALL_PAYMENT_TYPES;
            });
        },
        viewPaymentType(id){
            this.paymentTypeId = id;
        },
        editPaymentType(item){
            this.$store.commit('paymentTypeSetId', item.id);
            this.$store.commit('paymentTypeDialogAction', 'edit');
            this.$store.commit('paymentTypeShowAddEditDialog');
        },
        deletePaymentType(id){
            swal.fire({
                title: "",
                text: "Are you sure you want to Delete Payment Type?",
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then((result) => {
                if (result.isConfirmed) {
                    let payload = {
                        paymentTypeId: id
                    }

                    this.$store.dispatch('deletePaymentType',payload).then(response => {
                        if(response.data.error) {
                            swal.fire('', response.data.error, 'error');
                        } else {
                            this.getAllPaymentTypes();
                            swal.fire('', response.data.msg, 'success');
                        }
                    })
                }
            });
        },
    }
}
</script>

<style>

</style>
