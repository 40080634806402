<template>
    <v-form ref="form" v-model="valid">
        <v-card class="x-scroll y-scroll" v-if="this.loader">
            <v-row class="m-0">
                <v-col cols="8">
                    <v-card-title>
                        <span v-if="this.ACTION == 'Submit'"> Create Journal Voucher Manager </span>
                        <span v-else>
                            JVM #
                            {{ journalVoucherManager.jvm_num }}
                            {{ classificationText ? '(' + classificationText + ')' : '' }}
                        </span>
                    </v-card-title>
                </v-col>
                <v-col  cols="4" class="pull-right">
                    <v-btn text icon color="gray" class="float-right" @click="closeDialog()">
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
                <v-card-text style="border: 1px solid #e0e0e0;">
                    <v-card v-if="(ACTION == 'View')">
                        <v-row class="m-0 my-6">
                            <v-container fluid>
                                <v-row>
                                    <v-col sm="12" md="8" class="text-left text-dark">
                                        <v-row class="ml-2 pt-5">
                                            <v-col sm="12" md="9" class="p-0">JVM No#: <b>{{ this.journalVoucherManager.jvm_num }}</b></v-col>
                                            <v-col sm="12" md="3" class="p-0">JVM Date: <b>{{ this.journalVoucherManager.jvm_date }}</b></v-col>
                                        </v-row>
                                        <v-row class="ml-2">
                                            <v-col sm="12" md="9" class="p-0">Company: <b>{{ companyName }}</b></v-col>
                                            <v-col sm="12" md="3" class="p-0">Store Branch: <b>{{ branchName }}</b></v-col>
                                        </v-row>
                                        <v-row class="ml-2">
                                            <v-col sm="12" md="9" class="pb-5">Status:
                                                <a  class="ml-1 badge text-white" :style="`background-color:${ STATUS.JOURNAL_VOUCHER.find(e=>e.value === parseInt(GET_EDIT_JOURNAL_VOUCHER_MANAGER.status))?.color }`">
                                                    {{ STATUS.JOURNAL_VOUCHER.find(e=>e.value === parseInt(GET_EDIT_JOURNAL_VOUCHER_MANAGER.status))?.text }}
                                                </a>
                                                <!-- <span :class="'badge badge-'+getStatusColor(GET_EDIT_JOURNAL_VOUCHER_MANAGER.status_text)">
                                                    <b>{{ this.GET_EDIT_JOURNAL_VOUCHER_MANAGER.status_text }}</b>
                                                </span> -->
                                            </v-col>
                                            <!-- <v-col sm="12" md="9" class="p-0">
                                                Recognition Month: <b>
                                                    {{ getFormattedMonth(this.GET_EDIT_JOURNAL_VOUCHER_MANAGER.recognition_month) }}
                                                </b>
                                            </v-col> -->

                                            <v-col sm="12" md="3" class="p-0">Open Balance: <b> &#x20b1; {{ this.journalVoucherManager.open_balance | currency }}</b></v-col>
                                        </v-row>
                                        <!-- <v-row class="ml-2 pb-5">

                                        </v-row> -->
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-row>
                    </v-card>
                    <v-card v-else>
                        <v-row class="mt-3 px-5 pt-sm-2">
                            <v-col cols="2">
                                <v-autocomplete
                                    v-model="journalVoucherManager.vendor_id"
                                    :items="GET_ADMIN_SUPPLIER_SELECTION"
                                    :rules="required"
                                    outlined
                                    dense
                                    auto-select-first
                                    label="Vendor"
                                    clearable
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="2">
                                <v-autocomplete
                                    v-model="journalVoucherManager.company_id"
                                    :items="GET_PV_COMPANIES_SELECTION"
                                    :rules="required"
                                    item-value="value"
                                    item-text="text"
                                    outlined
                                    dense
                                    auto-select-first
                                    label="Company"
                                    clearable
                                    @change="insertStore()"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="2">
                                <v-autocomplete
                                    v-model="journalVoucherManager.store_branches_id"
                                    :items="branchItems"
                                    :rules="required"
                                    outlined
                                    dense
                                    auto-select-first
                                    label="Branch"
                                    clearable
                                    @change="insertCompany(journalVoucherManager.store_branches_id)"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="3">
                                <vuetify-money
                                    v-model="journalVoucherManager.open_balance"
                                    outlined
                                    required
                                    dense
                                    label="Open Balance"
                                    placeholder="0.00"
                                    masked
                                    :options="options"
                            ></vuetify-money>
                            </v-col>
                            <v-col cols="3" class="text-right">
                                <v-menu
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                max-width="300"
                                disabled
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            v-model="journalVoucherManager.created_at"
                                            dense
                                            readonly
                                            append-icon="mdi-calendar-clock"
                                            v-on="on"
                                            label="Date Issued"
                                            outlined
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="journalVoucherManager.created_at"/>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row class="mt-3 px-5 pt-sm-2 pb-md-5">

                        </v-row>
                    </v-card>
                    <v-row class="mt-5 px-2">
                        <v-col cols="12">
                            <v-card class="mb-2" elevation="0">
                                <v-card-text>
                                    <v-row>
                                        <v-spacer></v-spacer>
                                        <v-col cols="6" class="d-flex justify-content-end">
                                            <BaseCameraCaptureVue
                                                v-if="ACTION != 'View'"
                                                ref="cameraCapture"
                                                @capturedImages="getImages"
                                            ></BaseCameraCaptureVue>
                                            <v-badge
                                                v-if="!!images && images.length > 0"
                                                :content="'+'+(images.length).toString()"
                                                color="teal darken-1"
                                                offset-x="20"
                                                offset-y="50"
                                                bottom
                                                bordered
                                            ></v-badge>
                                            <FileUploadComponentVue
                                                v-if="ACTION != 'View'"
                                                style="display:inline-block; transform: translateY(-10px);"
                                                ref="fileUpload"
                                                id="attachment"
                                                @uploadedData="uploadedData"
                                                @reset="clearFileUpload"
                                            ></FileUploadComponentVue>
                                            <BaseFileViewerComponent
                                                :access="true"
                                                :payloadIds="[]"
                                                :payloadId="journalVoucherManager.id ? journalVoucherManager.id : null"
                                                :getFilesPath="'getJVMFiles'"
                                                :deleteFilesPath="journalVoucherManager.status == 1 ? 'deleteJVMFiles' : ''"
                                                :module="'accounting'"
                                                :folder="'journal_voucher_manager_files'"
                                                :localFiles="uploadedFiles"
                                                @files="processFiles"
                                                @return="insertUpload"
                                                ref="closeDialog"
                                            ></BaseFileViewerComponent>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6" class="pa-2" v-if="!(ACTION == 'View')">
                                            <h5>
                                                Particulars
                                            </h5>
                                        </v-col>
                                        <v-col cols="6" class="text-right" v-if="!(ACTION == 'View')">
                                            <v-btn icon dark small color="primary" style="height: 20px; width: 20px"
                                                @click="addNewParticularJvm()">
                                                <v-icon>
                                                    mdi-plus-circle
                                                </v-icon>
                                            </v-btn>
                                            <v-btn icon dark small color="primary" class="ml-1"
                                                style="height: 20px; width: 20px" @click="removeParticularJvm()">
                                                <v-icon>
                                                    mdi-minus-circle
                                                </v-icon>
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-simple-table fixed-header dense class="overflow-y-auto" :max-width="'90%'" style="border: 1px solid #e0e0e0; max-height: 290px; overflow-x: hidden;">
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th width="15%" class="text-left">COA</th>
                                                            <th width="28%" class="text-left">PARTICULARS</th>
                                                            <th width="3%" class="text-right">DEBIT AMOUNT</th>
                                                            <th width="3%" class="text-right">CREDIT AMOUNT</th>
                                                            <th width="10%" class="text-left">RECOGNITION MONTH</th>
                                                            <th width="15%" class="text-left">DEPARTMENT</th>
                                                            <th width="26%" class="text-left">JOB</th>
                                                            <th v-if="!(ACTION == 'View')" width="3%" class="text-center pa-0"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="particulars-container">
                                                        <tr v-for="(item, index) in journalVoucherManager.journal_voucher_manager_items" :key="item.id" class="particulars-container-row font-weight-medium text-gray py-2">
                                                            <td>
                                                                <span v-if="ACTION == 'View'" class="d-block" style="font-size: 12px;">
                                                                    <v-icon :class="(item.credit_amount > 0 ? 'mb-3' : 'd-none')">mdi-subdirectory-arrow-right</v-icon>
                                                                    <span>{{item.coa_by_name }}</span>
                                                                </span>
                                                                <span v-else :class="{'d-block pl-5': item.credit_amount > 0}">
                                                                    <v-spacer></v-spacer>
                                                                    <v-autocomplete class="my-1 text-left"
                                                                        id="coa_id"
                                                                        :items="GET_CHART_OF_ACCOUNTS_SELECTION"
                                                                        :rules="required"
                                                                        style="font-size: 12px;"
                                                                        item-value="id"
                                                                        item-text="account_name"
                                                                        outlined
                                                                        dense
                                                                        auto-select-first
                                                                        v-model="item.coa_id"
                                                                        :disabled="(ACTION == 'View')"
                                                                    />
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span v-if="(ACTION == 'View')" style="font-size: 12px;">
                                                                    {{item.particulars}}
                                                                </span>
                                                                <v-textarea v-else class="my-1 text-left"
                                                                    id="particulars"
                                                                    style="font-size: 12px;"
                                                                    v-model="item.particulars"
                                                                    :rules="required"
                                                                    outlined
                                                                    dense
                                                                    auto-grow
                                                                    rows="1"
                                                                    :disabled="(ACTION == 'View')"
                                                                    @keydown="newLine($event, false, false, index, 'particulars', addNewParticularJvm)"
                                                                />
                                                            </td>
                                                            <td>
                                                                <span v-if=" ACTION == 'View' && item.debit_amount == '0.00'" style="font-size: 12px;"></span>
                                                                <span v-else>
                                                                    <span v-if="ACTION == 'View'" class="float-right">
                                                                        &#x20b1; {{ thousandSeprator(item.debit_amount, index, 'debit_amount') }}
                                                                    </span>
                                                                    <vuetify-money
                                                                        v-else
                                                                        id="debit_amount"
                                                                        v-show="item.debit_amount > 0 || (item.debit_amount == 0 && item.credit_amount == 0)"
                                                                        v-model="item.debit_amount"
                                                                        outlined
                                                                        dense
                                                                        reverse
                                                                        class="align-right"
                                                                        placeholder="0.00"
                                                                        :disabled="ACTION == 'View'"
                                                                        :options="options"
                                                                        @blur="formatNumber(item.amount, index, 'amount')"
                                                                        @keydown.native="newLine($event, false, false, index, 'debit_amount', addNewParticularJvm, true)"
                                                                    />
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span v-if="ACTION == 'View' && item.credit_amount == '0.00'" style="font-size: 12px;"></span>
                                                                <span v-else>
                                                                    <span v-if="ACTION == 'View'" class="float-right">
                                                                        &#x20b1; {{ thousandSeprator(item.credit_amount, index, 'credit_amount') }}
                                                                    </span>
                                                                    <vuetify-money
                                                                        v-else
                                                                        id="credit_amount"
                                                                        v-show="item.credit_amount > 0 || (item.debit_amount == 0 && item.credit_amount == 0)"
                                                                        v-model="item.credit_amount"
                                                                        outlined
                                                                        dense
                                                                        reverse
                                                                        class="align-right"
                                                                        placeholder="0.00"
                                                                        :disabled="ACTION == 'View'"
                                                                        :options="options"
                                                                        @blur="formatNumber(item.amount, index, 'amount')"
                                                                        @keydown.native="newLine($event, false, false, index, 'credit_amount', addNewParticularJvm, true)"
                                                                    />
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span v-if="ACTION == 'View'" style="font-size: 12px;">
                                                                    {{ item.recognition_month }}
                                                                </span>
                                                                <v-menu
                                                                    v-else
                                                                    v-model="item.menu"
                                                                    ref="menu"
                                                                    :close-on-content-click="false"
                                                                    absolute
                                                                    transition="scale-transition"
                                                                    max-width="500"
                                                                    disable-keys
                                                                >
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-text-field
                                                                            id="recognition_month"
                                                                            v-model="item.recognition_month"
                                                                            :rules="required"
                                                                            style="font-size: 12px;"
                                                                            append-icon="mdi-calendar"
                                                                            dense
                                                                            outlined
                                                                            clearable
                                                                            :disabled="action == 'view'"
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            @click="item.menu = true"
                                                                            @paste="(event) => validateRecognitionMonth(event, journalVoucherManager.journal_voucher_manager_items, index)"
                                                                            @keydown="newLine($event, false, false, index, 'recognition_month', addNewParticularJvm)"
                                                                            :autofocus="item.recognitionMonthFocus"
                                                                        />
                                                                    </template>
                                                                    <v-date-picker
                                                                        v-model="item.recognition_month"
                                                                        no-title
                                                                        scrollable
                                                                        type="month"
                                                                    >
                                                                        <v-spacer></v-spacer>
                                                                    </v-date-picker>
                                                                </v-menu>
                                                            </td>
                                                            <td>
                                                                <span v-if="(ACTION == 'View')" style="font-size: 12px;">
                                                                    {{ item.department_id &&
                                                                        GET_DEPARTMENT_ACCOUNTING_LOAD.find(
                                                                            department => department.value == item.department_id
                                                                        )?.text || ''
                                                                    }}
                                                                </span>
                                                                <v-autocomplete v-else class="my-1 text-left"
                                                                    :items="GET_DEPARTMENT_ACCOUNTING_LOAD"
                                                                    :rules="required"
                                                                    style="font-size: 12px;"
                                                                    item-value="value"
                                                                    item-text="text"
                                                                    outlined
                                                                    dense
                                                                    auto-select-first
                                                                    v-model="item.department_id"
                                                                    :disabled="(ACTION == 'View')"
                                                                />
                                                            </td>
                                                            <td>
                                                                <span v-if="(ACTION == 'View')" style="font-size: 12px;">
                                                                    {{ GET_ACCOUNTING_JOB_SELECTION.find(e=>e.value == item.job_id.value)?.text }}
                                                                </span>
                                                                <v-combobox v-else class="my-1 text-left"
                                                                    id="job_id"
                                                                    v-model="item.job_id"
                                                                    :items="GET_ACCOUNTING_JOB_SELECTION"
                                                                    :rules="required"
                                                                    item-text="text"
                                                                    item-value="value"
                                                                    style="font-size: 12px;"
                                                                    outlined
                                                                    dense
                                                                    @keydown="newLine($event, true, true, index, 'coa_id', addNewParticularJvm)"
                                                                    :disabled="(ACTION == 'View')"
                                                                />
                                                            </td>
                                                            <td class="text-center" v-if="!(ACTION == 'View')">
                                                                <v-btn fab dark x-small color="red" style="height: 20px; width: 20px" @click="removeSpecificItem(index)">
                                                                    <v-icon small>
                                                                        mdi-minus
                                                                    </v-icon>
                                                                </v-btn>
                                                            </td>
                                                        </tr>
                                                        <tr v-if="journalVoucherManager.journal_voucher_manager_items.length > 0">
                                                            <td colspan="2" width="78%" class="text-right"><strong>TOTALS:</strong></td>
                                                            <td width="11%">
                                                                <strong>
                                                                    <span class="float-left total">&#x20b1;</span>
                                                                    <span class="float-right total">{{ totalDebitAmount | currency }}</span>
                                                                </strong>
                                                            </td>
                                                            <td width="11%">
                                                                <strong>
                                                                    <span class="float-left total">&#x20b1;</span>
                                                                    <span class="float-right total">{{ totalCreditAmount | currency }}</span>
                                                                </strong>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row class="mt-3 px-2">
                        <v-spacer></v-spacer>
                        <v-col sm="12" md="3" class="pull-right text-right">
                            <span>
                                <span v-if="(this.ACTION == 'Submit' || 'Update') && totalDebitAmount != totalCreditAmount" style="font-style: italic; color: red;">
                                    Debit and Credit Amount Totals do not match!
                                </span>
                            </span>
                        </v-col>
                    </v-row>
                </v-card-text>
            <v-card-actions>
                <v-card-text v-if="(journalVoucherManager.status == '1' || journalVoucherManager.status == '2') && (this.ACTION == 'View')" class="p-0 text-center">
                    <v-row class="m-0" justify="space-between">
                        <v-col :cols="accessApprove ? 4 : 12">
                            <v-btn v-if="journalVoucherManager.status == '1' && accessReject" :loading="loadButton" @click="updateJournalVoucherManagerStatus(3, 'Reject')" color="red">Reject</v-btn>
                        </v-col>
                        <v-col :cols="accessReject ? 4 : 12">
                            <v-btn v-if="journalVoucherManager.status == '1' && accessApprove" :loading="loadButton" @click="updateJournalVoucherManagerStatus(2, 'Approve')">Approve</v-btn>
                        </v-col>
                        <v-col cols="12">
                            <v-btn v-if="journalVoucherManager.status == '2' && accessCancel" :loading="loadButton" @click="updateJournalVoucherManagerStatus(4, 'Cancel')" color="red">Cancel</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-text class="text-center p-0" v-else>
                    <v-spacer></v-spacer>
                    <v-btn @click="submitJournalVoucherManager();" v-if="(ACTION == 'Submit')" :loading="loadButton" :disabled="!validToProceed">
                        Submit
                    </v-btn>
                    <v-btn @click="editJournalVoucherManager();" v-else-if="(ACTION == 'Update')" :loading="loadButton" :disabled="!validToProceed">
                        Update
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-text>
            </v-card-actions>
        </v-card>
        <v-card v-else class="ma-0 pa-2">
            <v-card-text class="text-center">
                <v-progress-circular
                    :size="50"
                    :width="7"
                    color="primary"
                    indeterminate
                ></v-progress-circular>
            </v-card-text>
        </v-card>
        <v-dialog v-model="cancel_dialog" max-width="42rem" persistent>
            <v-card>
                <v-card-text class="py-5">
                    <v-row>
                        <v-spacer/>
                        <v-col cols="4" class="text-center">
                            <h3>
                                Cancel Reason
                            </h3>
                        </v-col>
                        <v-col cols="4" class="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right" @click="()=>{cancel_dialog = !cancel_dialog}" >
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="text-center">
                            <span>Enter Reason</span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-spacer/>
                            <v-col cols="8" class="text-center">
                                <v-text-field
                                v-model="cancel_reason"
                                outlined
                                dense
                                />
                            </v-col>
                        <v-spacer/>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-col cols="12" class="text-center">
                        <v-btn :disabled="!cancel_reason" @click="completeCancel()">
                            Confirm
                        </v-btn>
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog  v-model="progressDialog" max-width="500" height="100" persistent>
            <v-card flat>
                <v-card-title>
                    <p class="font-weight-medium"  style="font-size:12px">Saving Data...</p>
                </v-card-title>
                <v-card-text class="w-100">
                    <v-row class="w-100 mx-auto">
                        <v-col
                            cols="12"
                        >
                            <v-progress-linear
                                class="mx-auto"
                                v-model="chunksPercentage"
                                height="15"
                            >
                                <strong>{{ chunksPercentage }}%</strong>
                            </v-progress-linear>
                            <p class="font-weight-light align-right"  style="font-size:10px">{{ itemSave  }} / {{ toProcessFiles.length }} are saved</p>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-form>
</template>
<script>
import swal from "sweetalert2";
import { mapGetters } from "vuex";
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
import AddEditVendorPaymentDetailComponent from '@/views/main/modules/Accounting/Payments/PaymentsComponent/AddEditVendorPaymentDetailComponent.vue';
import FileUploadComponentVue from '@/views/main/Shared/FileUploadComponent.vue'
import BaseFileViewerComponent from "@/views/main/Shared/BaseFilesViewerComponent.vue"
import BaseCameraCaptureVue from "@/views/main/modules/HR/Base/BaseCameraCapture.vue"
export default {
    mixins: [
        SharedFunctionsComponentVue
    ],
    inject: ['theme'],
    data() {
        return {
            overlay:false,
            valid:false,
            journalVoucherManager:{
                id:'',
                jvm_num:'',
                check_no:'',
                check_date:'',
                status:1,
                status_text:'',
                vendor_id:'',
                recognition_month:'',
                company_id:'',
                store_branches_id:'',
                open_balance:'',
                journal_voucher_manager_items:[
                    // {
                    //     coa_id:0,
                    //     particulars:'',
                    //     debit_amount:'',
                    //     credit_amount:'',
                    //     recognition_month:this.$dayjs().format('YYYY-MM'),
                    //     job_id: '',
                    //     department_id: 0
                    // }
                ],
                bill_due:'',
                created_at:this.$dayjs().format('YYYY-MM-DD'),
                journal_voucher_manager_items_trash: [],
            },
            vendorDetails:{
                billing_address_id:'',
                contact_number:'',
                contact_person:''
            },
            options:{
                prefix: "₱",
                suffix: "",
                length: 16,
                precision: 2
            },
            statuses:[
                {status:'hold',badge_color:'gray'},
                {status:'pending',badge_color:'gray'},
                {status:'approved',badge_color:'success'},
                {status:'signing',badge_color:'primary'},
                {status:'signed',badge_color:'success'},
                {status:'cancelled',badge_color:'danger'},
                {status:'releasing',badge_color:'warning'},
                {status:'released',badge_color:'info'},
            ],
            terms: '',
            action:'',
            menu:false,
            check_date:false,
            disabled_view: false,
            URL:process.env.VUE_APP_API_HOST,
            noImage:"images/noimage.png",
            child_dialog:false,
            children_coas: [],
            loader:false,
            clonedStores: [],
            cancel_dialog: false,
            cancel_reason: '',
            required: [ v => !!v || 'This field is required' ],
            loadButton: false,
            uploadedImages: [],
            images: [],
            compiledFiles: [],
            uploadedFiles: [],
            viewUploadedFiles: [],
            saveFiles: [],
            chunksPercentage: 0,
            itemSave: 0,
            progressDialog: false,
            toProcessFiles: [],
            dispatchCoa: false
        };
    },
    components: {
        AddEditVendorPaymentDetailComponent,
        FileUploadComponentVue,
        BaseFileViewerComponent,
        BaseCameraCaptureVue
    },
    computed: {
        ...mapGetters([
            "ACTION",
            "DIALOGS",
            "USER_ACCESS",
            "GET_JOURNAL_VOUCHER_MANAGER_DISPATCH",
            "GET_NEW_JOURNAL_VOUCHER_MANAGER_TABLE",
            "GET_EDIT_JOURNAL_VOUCHER_MANAGER",
            "GET_JOURNAL_VOUCHER_MANAGER_STATUS",
            "GET_JOURNAL_VOUCHER_MANAGER",
            "GET_ADMIN_SUPPLIER_SELECTION",
            "GET_CHART_OF_ACCOUNTS_SELECTION",
            // "GET_JVM_NUM",
            "GET_DEPARTMENT_ACCOUNTING_LOAD",
            "GET_ACCOUNTING_JOB_SELECTION",
            // "GET_COMPANY_SELECTION",
            "GET_PV_COMPANIES_SELECTION",
            "GET_PV_STORE_BRANCHES_SELECTION",
            "STATUS",
            "GET_COMPANY_CLASSIFICATION_SELECTION"
        ]),
        actions() {
            if (this.ACTION == "Submit") {
                this.disabled_view = false;
                this.loader = true
                return "Create";
            } else if (this.ACTION == "Update") {
                this.insertJVMData();
                this.disabled_view = true;
                return 'Update';
            } else if (this.ACTION == "View") {
                this.insertJVMData();
                this.disabled_view = true;
                return "View";
            }
        },
        totalDebitAmount() {
            return this.journalVoucherManager.journal_voucher_manager_items.reduce((total, item) => {
                return total + parseFloat(item.debit_amount || 0)
            }, 0).toFixed(2);
        },
        totalCreditAmount() {
            return this.journalVoucherManager.journal_voucher_manager_items.reduce((total, item) => {
                return total + parseFloat(item.credit_amount || 0)
            }, 0).toFixed(2);
        },
        // isSubmitButtonDisabled() {
        //     return this.totalDebitAmount != this.totalCreditAmount;
        // },
        // getChildrenChartofAccounts(){
        //     this.updateCoas(this.GET_CHART_OF_ACCOUNTS_SELECTION)
        //     return this.children_coas
        // },
        companyName(){
            let comp_id = this.journalVoucherManager.company_id
            let company = this.GET_PV_COMPANIES_SELECTION.find(e=> e.value == comp_id)
            if (company) {
                return company.text
            }
        },
        branchName(){
            let branch_id = this.journalVoucherManager.store_branches_id
            let branch = this.GET_PV_STORE_BRANCHES_SELECTION.find(e=>e.value == branch_id)
            if (branch) {
                return branch.text.toUpperCase()
            }
        },
        branchItems(){
            let items = this.clonedStores.filter(e=>e.company_id == this.journalVoucherManager.company_id)
            if (!this.journalVoucherManager.company_id) return this.clonedStores
            if (!this.journalVoucherManager.store_branches_id) this.journalVoucherManager.store_branches_id = items[0].value
            return items
        },
        accessReject(){
            return this.USER_ACCESS.find(e=>e.actions_code == 'reject')
        },
        accessApprove(){
            return this.USER_ACCESS.find(e=>e.actions_code == 'approve')
        },
        accessCancel(){
            return this.USER_ACCESS.find(e=>e.actions_code == 'cancel')
        },
        validToProceed(){
            if (this.totalDebitAmount != '0.00' && this.totalCreditAmount != '0.00') {
                if (this.totalDebitAmount === this.totalCreditAmount) {
                    return this.valid
                }
            }
            return false
        },
        classificationText() {
            let classificationId = this.branchItems.find(e => e.value == this.journalVoucherManager.store_branches_id)?.company_classification_id
                ?? this.GET_PV_COMPANIES_SELECTION.find(e => e.value == this.journalVoucherManager.company_id)?.company_classification_id;
            return classificationId
                ? this.GET_COMPANY_CLASSIFICATION_SELECTION.find(el => el.value == classificationId)?.text ?? ''
                : '';
        }
    },
    mounted() {
        this.getBranches()
        this.valid = false
    },
    methods: {
        closeDialog(){
            this.resetfields();
            this.$store.commit('DIALOG',false);
            this.$store.commit("ACTION", '');
            this.$store.commit("JVM_NUM", '');
            this.$store.dispatch('getAccountingJobSelection');
        },
        resetfields(){
            Object.assign(this.$data, this.$options.data.call(this));
        },
        insertJVMData() {
            Object.assign(this.journalVoucherManager, this.GET_EDIT_JOURNAL_VOUCHER_MANAGER)
            this.journalVoucherManager.created_at = this.$dayjs(this.journalVoucherManager.created_at).format('YYYY-MM-DD')
            this.journalVoucherManager.journal_voucher_manager_items.forEach(e=>{
                e.debit_amount = parseFloat(e.debit_amount).toFixed(2)
                e.credit_amount = parseFloat(e.credit_amount).toFixed(2)
                e.recognition_month = this.$dayjs(e.recognition_month).format('YYYY-MM')
                e.job_id = this.GET_ACCOUNTING_JOB_SELECTION.find(element=> element.value == e.job_id) != null ? this.GET_ACCOUNTING_JOB_SELECTION.find(element=> element.value == e.job_id) : e.job_id;
            })
            this.loader = true
        },
        submitJournalVoucherManager() {
            this.loadButton = true
            let payload = {
                form: this.journalVoucherManager,
                url:'addNewJournalVoucherManager'
            }
            swal.fire({
                    title: "",
                    text: `- Are you sure you want to Save this Journal Voucher Manager ?`,
                    icon: 'question',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    allowOutsideClick: false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor: 'grey',
                    confirmButtonText: 'Confirm',
                    showCloseButton: true,
            })
            .then((result) => {
                if(result.isConfirmed) {
                    this.$store.dispatch('postJournalVoucherManager',payload).then(response=>{
                        this.saveFileDetails(response.data.jvm_id)
                    }).catch(error=>{
                        swal.fire('', error.response.data.message, error.response.data.status);
                        this.loader = false
                        this.loadButton = false
                    })
                }
            })
        },
        editJournalVoucherManager() {
            let payload = {
                form: this.journalVoucherManager,
                url:'updateJournalVoucherManager'
            }
            swal.fire({
                    title: "",
                    text: `- Are you sure you want to Update this Journal Voucher Manager ?`,
                    icon: 'question',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    allowOutsideClick: false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor: 'grey',
                    confirmButtonText: 'Confirm',
                    showCloseButton: true,
            })
            .then((result) => {
                if(result.isConfirmed) {
                    this.$store.dispatch('putJournalVoucherManager',payload).then(response=>{
                        this.saveFileDetails(response.data.jvm_id)
                    }).catch(error=>{
                        swal.fire('', error.response.data.message, error.response.data.status);
                        this.loader = false
                    })
                }
            })
        },
        updateJournalVoucherManagerStatus(status,type) {
            swal.fire({
                    title: "",
                    text: `Are you sure you want to ${type} ?`,
                    icon: 'question',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    allowOutsideClick: false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor: 'grey',
                    confirmButtonText: 'Confirm',
                    showCloseButton: true,
            })
            .then((result) => {
                if(result.isConfirmed) {
                    if (status == 4) {
                        this.cancel_dialog = true
                    } else {
                        let payload = {
                            url:'updateJournalVoucherManagerStatus',
                            new_status: status,
                            id:this.journalVoucherManager.id
                        }
                        this.$store.dispatch('postJournalVoucherManager',payload).then(response=>{
                            swal.fire('',response.data.msg,response.data.icon)
                            this.closeDialog();
                            this.$store.commit("NEW_JOURNAL_VOUCHER_MANAGER_TABLE", true);
                        })
                    }
                }
            })
        },
        getAddressOfVendor(){
            let payload = {
                id:this.journalVoucherManager.vendor_id
            }
            this.$store.dispatch('getAddressByVendor',payload).then(response=>{
                let address = response.data;
                if(address.length > 0){
                    this.vendorDetails.billing_address_id = address[0].address
                    this.vendorDetails.contact_number = address[0].contact_number
                    this.vendorDetails.contact_person = address[0].contact_person
                }else{
                    this.vendorDetails.billing_address_id = ''
                    this.vendorDetails.contact_number = ''
                    this.vendorDetails.contact_person = ''
                }
                this.terms = address[0].terms
            })
        },
        addNewParticularJvm(attributeNameToFocus){
            this.journalVoucherManager.journal_voucher_manager_items.forEach(element => {
                element.menu = false;
                element.recognitionMonthFocus = false;
            });
            this.journalVoucherManager.journal_voucher_manager_items.push({
                menu:false,
                coa_id:0,
                particulars:'',
                debit_amount:'',
                credit_amount:'',
                recognition_month:this.$dayjs().format('YYYY-MM'),
                recognitionMonthFocus: attributeNameToFocus === 'recognition_month' ? true : false,
                department_id: '',
                job_id: '',
            })
        },
        removeParticularJvm() {
            if (this.journalVoucherManager.journal_voucher_manager_items && this.journalVoucherManager.journal_voucher_manager_items.length > 0) {
                this.journalVoucherManager.journal_voucher_manager_items.splice(0, this.journalVoucherManager.journal_voucher_manager_items.length);
            }
            this.addNewParticularJvm();
        },
        removeSpecificItem(index){
            if(this.journalVoucherManager.journal_voucher_manager_items.length != 1){
                this.journalVoucherManager.journal_voucher_manager_items_trash.push(this.journalVoucherManager.journal_voucher_manager_items[index])
                this.journalVoucherManager.journal_voucher_manager_items.splice(index, 1)
            }
        },
        formatNumber(number, index, field) {
            this.journalVoucherManager.journal_voucher_manager_items[index][field] =
                this.thousandSeprator(number);
        },
        getStatusColor(statusText) {
            const status = this.statuses.find((s) => s.status == statusText);
            return status ? status.badge_color : '';
        },
        getFormattedMonth(dateString) {
            const date = new Date(dateString + "-01");
            const monthName = date.toLocaleString('default', { month: 'long' });
            return `${monthName} ${date.getFullYear()}`;
        },
        getClearedDateText(postDate) {
            if (postDate) {
                const date = new Date(postDate);
                return date.toDateString();
            } else {
                return "Not Cleared Yet";
            }
        },
        tabLine(evt, last, array) {
            if (evt.key == 'Tab' || evt.key === 'ArrowDown') {
                if (evt.key === 'Tab' && evt.shiftKey) return;
                if (evt.key === 'Tab' && !last) return;
                if (array && evt.key !== 'Tab') return;
                evt.preventDefault();
                this.addNewParticularJvm();
            }
        },
        updateCoas(parentCOA) {
            const filteredChildrenCOAS = this.GET_CHART_OF_ACCOUNTS_SELECTION.filter(e => e.parent_account_id != 0)
            this.children_coas = filteredChildrenCOAS
        },
        // getStoreBranches(){
        //     this.$store.dispatch('getPaymentVoucherBranchesSelection',{company_id:this.journalVoucherManager.company_id})
        // },
        async getBranches(){
            try{
                this.clonedStores = await this.getStoreBranches()
            } catch (error) {
                console.error(error)
            }
        },
        insertCompany(storeId){
            if (!storeId) return
            let store = this.clonedStores.find(e=>e.value == storeId)
            this.journalVoucherManager.company_id = this.GET_PV_COMPANIES_SELECTION.find(e=>e.value == store.company_id).value
        },
        insertStore(){
            this.journalVoucherManager.store_branches_id = this.branchItems[0].value
        },
        completeCancel(){
            swal.fire({
                    title: "",
                    text: `Are you sure you want to cancel JVM # ${this.journalVoucherManager.jvm_num} ?`,
                    icon: 'question',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    allowOutsideClick: false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor: 'grey',
                    confirmButtonText: 'Confirm',
                    showCloseButton: true,
            }).then(result=>{
                if (result.isConfirmed) {
                    this.cancel_dialog = false
                    let payload = {
                        url:'updateJournalVoucherManagerStatus',
                        new_status: 4,
                        id:this.journalVoucherManager.id,
                        cancel_reason: this.cancel_reason
                    }
                    this.$store.dispatch('postJournalVoucherManager',payload).then(response=>{
                        swal.fire('',response.data.msg,response.data.icon)
                        this.closeDialog();
                        this.$store.commit("NEW_JOURNAL_VOUCHER_MANAGER_TABLE", true);
                    })
                }
            })
        },
        getImages(dataUrls) {
            this.images = dataUrls.map(dataUrl => {
                try {
                    if (!dataUrl.startsWith('data:image/jpeg;base64,')) {
                        throw new Error('Invalid data URL format');
                    }

                    const base64String = dataUrl.split(',')[1];
                    const byteString = atob(base64String);
                    const arrayBuffer = new ArrayBuffer(byteString.length);
                    const uint8Array = new Uint8Array(arrayBuffer);

                    for (let i = 0; i < byteString.length; i++) {
                        uint8Array[i] = byteString.charCodeAt(i);
                    }

                    const blob = new Blob([uint8Array], { type: 'image/jpeg' });
                    const timestamp = new Date().toISOString().replace(/[:.-]/g, '');
                    const filename = `captured-image-${timestamp}.jpg`;
                    const file = new File([blob], filename, { type: 'image/jpeg' });
                    return file;
                } catch (error) {
                    console.error('Failed to convert base64 to file:', error);
                    return null;
                }
            }).filter(file => file !== null);
            this.uploadedImages = []
            this.images.forEach(e=>{
                e.upload_type = 2,
                this.uploadedImages.push(e)
            })
            let fileData = []
            for (let i = 0; i < this.uploadedImages.length; i++) {
                const element = this.uploadedImages[i]
                let reader = new FileReader()
                reader.readAsDataURL(element)
                new Promise((resolve)=>{
                    reader.onloadend = function () {
                        fileData.push({
                            name: element.name,
                            type: element.type,
                            data: reader.result.split(',')[1],
                            upload_type: element.upload_type,
                        })
                        resolve()
                    }
                })
            }
            this.uploadedImages = fileData
        },
        clearFileUpload(confirm){
            if (confirm) {
                this.uploadedFiles = []
            }
            this.uploadedFiles = []
            this.$refs.fileUpload.reset()
        },
        clearCameraCapture(){
            this.$refs.cameraCapture.reset();
            this.images = [];
        },
        uploadedData(data){
            let dataToProcess = {
                attachments: []
            }
            if (this.viewUploadedFiles != null && this.viewUploadedFiles.length > 0) {
                data.attachments.forEach(a=>{
                    let isDuplicate = this.viewUploadedFiles.some(e=>{
                        return e.name.concat(`.${e.extension}`) === a.name
                    })
                    if (isDuplicate) {
                        swal.fire('Duplicate Files Detected!', '', 'warning')
                        return
                    } else {
                        dataToProcess.attachments.push(a)
                    }
                })
            } else {
                dataToProcess.attachments = data.attachments
            }
            this.uploadedFiles = dataToProcess
        },
        async processFiles(files){
            files.forEach(e=>{
                this.compiledFiles.push(e.localFileState)
            })
            let fileData = []
            for (let i = 0; i < this.compiledFiles.length; i++) {
                let element = this.compiledFiles[i]
                let reader = new FileReader()
                reader.readAsDataURL(element)
                await new Promise((resolve) => {
                    reader.onloadend = function () {
                        fileData.push({
                            name: element.name,
                            type: element.type,
                            data: reader.result.split(',')[1],
                            upload_type: 1,
                        })
                        resolve()
                    }
                })
            }
            this.saveFiles = fileData
        },
        insertUpload(file){
            if (!!file) this.viewUploadFiles = file
        },
        async saveFileDetails(jvmId){
            let chunkedFiles = this.chunkArray(this.saveFiles, 1)
            let chunkedImages = this.chunkArray(this.uploadedImages, 1)
            await this.saveData(chunkedFiles, chunkedImages, jvmId);
        },
        chunkArray(array, chunkSize) {
            const chunks = [];
            for (let i = 0; i < array.length; i += chunkSize) {
                chunks.push(array.slice(i, i + chunkSize));
            }
            return chunks;
        },
        async saveData(chunkedFiles, chunkedImages, jvmId) {
            this.toProcessFiles = [...chunkedFiles, ...chunkedImages]
            let count = 0;
            this.progressDialog = true;
            const chunksLength = this.toProcessFiles.length;
            if (this.toProcessFiles.length != 0) {
                for (const chunk of this.toProcessFiles) {
                    this.chunksPercentage = Math.round((count / chunksLength) * 100);
                    let payload = {
                        file: chunk,
                        jvm_id: jvmId,
                        url:'process-files'
                    }
                    await this.$store.dispatch('postJournalVoucherManager', payload).then(response => {
                        if (response.data.message == 'success') {
                            this.itemSave += chunk.length
                            count ++;
                            if (this.itemSave == this.toProcessFiles.length) {
                                this.chunksPercentage = 100;
                                this.progressDialog = false;
                                this.loadButton = false
                                this.itemSave = 0;
                            }
                        } else {
                            swal.fire('Error','- Error in saving file details', 'error');
                            this.progressDialog = false;
                            this.itemSave = 0;
                            this.loadButton = false
                        }
                    });
                }
            }
            this.closeDialog()
            this.loadButton = false
            this.$store.commit('NEW_JOURNAL_VOUCHER_MANAGER_TABLE', true)
            swal.fire('','- Journal Voucher Manager Saved!','success').then(()=>{
                this.$store.commit('NEW_JOURNAL_VOUCHER_MANAGER_TABLE', false);
            })
        },
    },
    watch:{
        DIALOGS:{
            handler(val){
                if (val) this.getBranches()
            },
            deep:true,
            immediate:true
        },
        ACTION: {
            handler(val) {
                if (val == 'Submit') {
                    this.loader = true;
                    this.addNewParticularJvm();
                }
            },
            immediate: true,
        },
        GET_EDIT_JOURNAL_VOUCHER_MANAGER:{
            handler(val){
                if(val){
                    this.insertJVMData();
                }
            }
        },
        terms:function(value){
            if(value != '' && this.journalVoucherManager.created_at != ''){
                var computedDueDate = new Date(this.journalVoucherManager.created_at)
                if(computedDueDate != null){
                    computedDueDate.setDate(computedDueDate.getDate() + parseInt(value));
                    this.journalVoucherManager.bill_due = computedDueDate.toISOString().substr(0, 10)
                }else{
                    this.journalVoucherManager.bill_due = this.journalVoucherManager.created_at
                }
            }else{
                this.journalVoucherManager.bill_due = this.journalVoucherManager.created_at
            }
        },
        GET_PV_STORE_BRANCHES_SELECTION:{
            handler(val){
                this.branchesByCompany = val
            }
        },
        'journalVoucherManager.company_id':{
            handler(oldVal, newVal){
                if (oldVal != newVal && !!this.journalVoucherManager.company_id) {
                    this.dispatchCoa = true
                }
                if (!this.journalVoucherManager.company_id) {
                    this.journalVoucherManager.store_branches_id = null
                }
            },
            immediate: true
        },
        'journalVoucherManager.store_branches_id':{
            handler(oldVal, newVal){
                if (oldVal != newVal && !!this.journalVoucherManager.store_branches_id) {
                    this.dispatchCoa = true
                }
                if (!this.journalVoucherManager.store_branches_id) {
                    this.journalVoucherManager.company_id = null
                }
            },
            immediate: true
        },
        'dispatchCoa':{
            handler(val){
                if (val) {
                    if (this.ACTION == 'View') {
                        this.dispatchCoa = false
                        return
                    }
                    let payload = {
                        company_id: this.journalVoucherManager.company_id,
                        store_branch_id: this.journalVoucherManager.store_branches_id,
                    }
                    this.$store.dispatch('getCOASelection',payload)
                    this.dispatchCoa = false
                }
            },
            immediate: true
        }
    },
};
</script>
<style scoped>
.x-scroll {
    overflow-x: hidden;
}

.y-scroll {
    overflow-y: hidden;
}

.total {
    font-size: 22px;
}
</style>
