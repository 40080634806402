<template>
  <div>
    <div class="page-wrapper ma-1">
        <div class="row pa-5">
              <div class="col-12 d-flex no-block align-items-center">
                  <h4 class="page-title">Spare Parts</h4>
              </div>
        </div>
        <div class="container">
          <v-tabs v-model="tab" id="group-tab" color="cyan" dark slider-color="cyan" background-color="#424242" class="col-12 pb-0" show-arrows>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-tab v-bind="attrs" v-on="on" ripple @click="sparePartsTab(0)">MODEL</v-tab>
                </template>
                <span>MODEL/UNIT</span>
            </v-tooltip>
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-tab v-bind="attrs" v-on="on" ripple @click="sparePartsTab(1)">UP</v-tab>
                </template>
                <span>ASSIGN PARTS</span>
            </v-tooltip>
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-tab v-bind="attrs" v-on="on" ripple @click="sparePartsTab(2)">PC</v-tab>
                </template>
                <span>PARTS COMPATIBILITY</span>
            </v-tooltip>
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-tab v-bind="attrs" v-on="on" ripple @click="sparePartsTab(3)">LOCATION</v-tab>
                </template>
                <span>ITEM LOCATION</span>
            </v-tooltip>
          </v-tabs>
          <v-tabs-items v-model="tab" touchless style="background-color:unset">
              <v-tab-item>
                <ItemsTableComponent :tab="tab"></ItemsTableComponent>
              </v-tab-item>
              <v-tab-item>
                  <SparePartsComponent :tab="tab"></SparePartsComponent>
              </v-tab-item>
              <v-tab-item>
                  <PartsSubstitutionComponentVue></PartsSubstitutionComponentVue>
              </v-tab-item>
              <v-tab-item>
                  <SparePartsLocationTableComponent></SparePartsLocationTableComponent>
              </v-tab-item>
          </v-tabs-items>
        </div>
    </div>
  </div>
</template>

<script>
import ItemsTableComponent from '@/views/main/modules/Services/Views/SparePartsComponent/ItemsTableComponent.vue'
import SparePartsComponent from '@/views/main/modules/Services/Views/SparePartsComponent/SparePartsTableComponent.vue'
import PartsSubstitutionComponentVue from '@/views/main/modules/Services/Views/SparePartsComponent/PartsSubstitutionComponent.vue'
import SparePartsLocationTableComponent from '@/views/main/modules/Services/Views/SparePartsComponent/SparePartsLocationTableComponent.vue'
export default {
  data(){
    return{
      tab: null,
      tableView: false
    }
  },
  components:{
    ItemsTableComponent,
    SparePartsComponent,
    PartsSubstitutionComponentVue,
    SparePartsLocationTableComponent
  },
  methods:{
    sparePartsTab(tab){
      this.tab = tab;
    }
  }
}
</script>

<style>
.tab-page-wrapper{
  display: none;
}
</style>
