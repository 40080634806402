<template>
   <v-container>
    <v-row>
      <v-col md="16" class="d-flex no-block align-items-center ma-3">
        <h4 class="page-title">Service Reports</h4>
      </v-col>
    </v-row>
    <v-row v-for="(report, i) in reports" :key="i">
      <v-col lg="3" sm="12" class="pa-3" v-for="(button, i) in report" :key="i">
        <reportbutton
          :button_title="button.title"
          :button_icon="button.icon"
          :button_route="button.route"
          @click="salesReportsRoutes(button.route)"
        >
        </reportbutton>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import reportbutton from '@/views/main/layouts/Buttons/ReportButtonComponent';
export default {
    data(){
        return{
            reports:[],
        }
    },
    props:[

    ],
    methods:{
        changebutton(val){

            if(val == 'sales'){
                this.reports =  [
                [
                    // {
                    //     icon:'mdi-account',
                    //     title:'Repair Forms Master',
                    //     route:'service-rf-master-report'
                    // },
                    // {
                    //     icon:'mdi-account',
                    //     title:'Spare Parts Master',
                    //     route:'service-sp-master-report'
                    // },
                    // {
                    //     icon:'mdi-account',
                    //     title:'Baklas Report',
                    //     route:'service-baklas-report'
                    // },
                    // {
                    //     icon:'mdi-account',
                    //     title:'China Defective',
                    //     route:'service-china-defective-report'
                    // },
                    {
                        icon:'mdi-account',
                        title:'Repair Form Count Per Status Report',
                        route:'repiar-count-report'
                    },
                    {
                        icon:'mdi-account',
                        title:'Repair Forms Per Item Report',
                        route:'pending-repair-form-report'
                    },
                    {
                        icon:'mdi-account',
                        title:'Repair Form Count per Customer/Warranty/Tehcnician',
                        route:'repair-form-age-report'
                    },
                    {
                        icon:'mdi-account',
                        title:'Repair Form Average Age Per Customer/Warranty/Tehcnician',
                        route:'in-and-out-per-warranty'
                    },
                    {
                        icon:'mdi-account',
                        title:'Technician Accuracy Report',
                        route:'technician-accuracy-report'
                    },
                ],
            ];
            }
        },

        salesReportsRoutes(routeName){
            this.$router.push({
                name:routeName
            })
        }
    },

    components:{
        reportbutton
    },

    mounted(){
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
        this.changebutton(this.$route.meta.type)

    },
    watch:{
        '$route.meta.type':{
            handler(val){
                this.changebutton(val)
            }
        }
    }
}
</script>
