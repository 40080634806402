<template>
    <v-container fluid class="pa-3">
        <ComponentHeader
            :button="true"
            :title="report.title"
            :type="report.type"
        ></ComponentHeader>
        <v-container class="bg-bluish-gray default-container ma-0">
            <v-row no-gutters class="pb-1">
                <v-col sm="12" md="2">
                    <v-autocomplete
                        v-model="filters.item_type_ids"
                        :items="dropdowns.item_types.list"
                        :loading="loading.item_types"
                        :disabled="loading.item_types"
                        class="d-inline-block w-100 small"
                        label="Item Type"
                        background-color="white"
                        clearable
                        multiple
                        small-chips
                        deletable-chips
                        outlined
                        dense
                        auto-select-first
                        hide-details
                    ></v-autocomplete>
                </v-col>
                <v-col sm="12" md="3">
                    <v-autocomplete
                        v-model="filters.item_category_ids"
                        :items="dropdowns.item_categories.list"
                        :loading="loading.item_categories"
                        :disabled="loading.item_categories"
                        class="d-inline-block w-100 small"
                        label="Category"
                        background-color="white"
                        clearable
                        multiple
                        small-chips
                        deletable-chips
                        outlined
                        dense
                        auto-select-first
                        hide-details
                    ></v-autocomplete>
                </v-col>
                <v-col sm="12" md="2">
                    <v-autocomplete
                        v-model="filters.item_id"
                        :items="GET_ITEM_SELECTION_DEFAULT"
                        class="d-inline-block w-100 small"
                        item-text="text"
                        item-value="value"
                        label="Model"
                        background-color="white"
                        clearable
                        multiple
                        small-chips
                        deletable-chips
                        outlined
                        dense
                        auto-select-first
                        hide-details
                    ></v-autocomplete>
                </v-col>
                <v-col sm="12" md="2">
                    <v-autocomplete
                        v-model="filters.status"
                        :items="dropdowns.status.list"
                        class="d-inline-block w-100 small"
                        label="Status"
                        background-color="white"
                        outlined
                        dense
                        auto-select-first
                        hide-details
                    ></v-autocomplete>
                </v-col>
                <v-spacer></v-spacer>
                <v-col sm="12" md="3" class="text-right">
                    <v-btn class="mr-1" @click="resetFields()">Clear</v-btn>
                    <v-btn @click="getItemsWithSubstitute(1)">Export</v-btn>
                    <v-btn class="ml-1" @click="clearUrlParams(); getItemsWithSubstitute()">Filter</v-btn>
                </v-col>
            </v-row>
            <v-row dense no-gutters>
                <v-col cols="12">
                    <v-card>
                        <v-container fluid>
                            <v-data-table
                                :headers="datatable.headers"
                                :items="datatable.items.list"
                                :options.sync="datatable.options"
                                :server-items-length="datatable.items.count"
                                :loading="toggles.loaded"
                                class="w-100"
                                dense
                                disable-sort
                                @update:page="getItemsWithSubstitute()"
                                @update:items-per-page="getItemsWithSubstitute()"
                            >
                                <template v-slot:item.image_path="{ item }">
                                    <v-img class="img-thumbnail" width="50" height="50" :src="checkImagePath(item.image_path)" eager contain></v-img>
                                </template>
                                <template v-slot:item.selling_price="{ item }">
                                    <span class="text-nowrap">
                                        {{ thousandSeprator(item.selling_price) }}
                                    </span>
                                </template>
                                <template v-slot:item.actual_stocks="{ item }">
                                    <span class="text-nowrap">
                                        {{ thousandSeprator(item.actual_stocks) }}
                                    </span>
                                </template>
                                <template v-slot:item.virtual_stocks="{ item }">
                                    <span class="text-nowrap">
                                        {{ thousandSeprator(item.virtual_stocks) }}
                                    </span>
                                </template>
                                <template v-slot:item.sub_image_path="{ item }">
                                    <v-img class="img-thumbnail mx-auto" width="50" height="50" :src="checkImagePath(item.sub_image_path)" eager contain></v-img>
                                </template>
                                <template v-slot:item.action="{ item }">
                                    <v-btn text icon color="orange">
                                        <v-icon class="btn-action" small @click="viewItem(item.id)">mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </v-container>
                    </v-card>
                    <ItemsViewComponent
                        :viewItemId="datatable.items.selected"
                        @closeView="closeView"
                    ></ItemsViewComponent>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>
<script>
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent'
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
import ItemsViewComponent from "@/views/main/layouts/dialogs/Sales/Items/ItemsViewComponent.vue"
import { mapGetters } from 'vuex'

export default {
    mixins: [
        SharedFunctionsComponent
    ],
    components: {
        ComponentHeader,
        ItemsViewComponent
    },
    data: () => ({
        report: {
            title: 'Items With New Models Report',
            type: 'Inventory',
        },
        urlParams: {
            type_ids: [],
            category_ids: [],
            status: [],
            item_ids: [],
        },
        defaults: {
            item_type_ids: [],
            item_category_ids: [],
            item_ids: [],
            status: 1,
            for_export: 0,
        },
        toggles: {
            loaded: false,
        },
        loading: {
            item_types: true,
            item_categories: true,
        },
        filters: {},
        dropdowns: {
            status: {
                list: [
                    { value: -1, text: 'All' },
                    { value: 1, text: 'Active' },
                    { value: 0, text: 'Inactive' },
                ],
            },
            item_types: {
                list: [],
            },
            item_categories: {
                list: [],
            },
        },
        datatable: {
            items: {
                list: [],
                count: 0,
                selected: 0,
            },
            headers: [
                { text: 'IMAGE', align: 'start', value: 'image_path', },
                { text: 'MODEL', align: 'start', value: 'model', },
                { text: 'NAME', align: 'start', value: 'name', },
                { text: 'UOM', align: 'start', value: 'uom' },
                { text: 'TYPE', align: 'start', value: 'type' },
                { text: 'CATEGORY', align: 'start', value: 'category', },
                { text: 'STATUS', align: 'center', value: 'status', },
                { text: 'SRP', align: 'end', value: 'selling_price', },
                { text: 'ACTUAL STOCKS', align: 'end', value: 'actual_stocks', },
                { text: 'VIRTUAL STOCKS', align: 'end', value: 'virtual_stocks', },
                { text: 'DEACTIVATE REASON', align: 'center', value: 'deactivate_reason', },
                { text: 'NEW MODEL', align: 'start', value: 'substitute_models', },
                { text: 'NEW MODEL IMAGE', align: 'center', value: 'sub_image_path', },
                {text: 'ACTION', align: 'center', value: 'action' },
            ],
            options: {},
        },
    }),
    async mounted(){
        this.resetFields()
        await this.storeUrlParams()
        await this.loadDropdowns()
        await this.setDefaultValues()
        await this.$store.dispatch('getAllitemSelectionDefault')
    },
    computed: {
        ...mapGetters([
            'GET_ITEM_TYPES',
            'GET_ITEM_CATEGORIES',
            'GET_ITEM_SELECTION_DEFAULT',
        ])
    },
    methods: {
        viewItem(item_id) {
            this.datatable.items.selected = item_id
        },
        closeView() {
            this.datatable.items.selected = 0
        },
        async storeUrlParams() {
            const params = new URLSearchParams(window.location.search)

            Object.assign(this.urlParams, {
                item_ids: params.get('item_ids') == null ? this.defaults.item_ids : JSON.parse(params.get('item_ids')),
                type_ids: params.get('type_id') == null ? this.defaults.item_type_ids : JSON.parse(params.get('type_id')),
                category_ids: params.get('category_id') == null ? this.defaults.item_category_ids : JSON.parse(params.get('category_id')),
                status: params.get('status') == null ? this.defaults.status : JSON.parse(params.get('status')),
            })

            Object.assign(this.filters, {
                item_type_ids: this.urlParams.type_ids,
                item_category_ids: this.urlParams.category_ids,
                item_id: this.urlParams.item_ids,
                status: this.urlParams.status,
            })
        },
        async loadDropdowns() {
            await this.$store.dispatch('getItemTypes')
            await this.$store.dispatch('getItemCategories')
        },
        async setDefaultValues() {
            Object.assign(this.filters, {
                item_type_ids:
                    this.dropdowns.item_types.list.filter(f => {
                        return [1, 2].includes(f.value)
                    }).map(m => parseInt(m.value))
            })
        },
        clearUrlParams(){
            Object.assign(this.urlParams, {
                type_ids: [],
                category_ids: [],
            })
        },
        getItemsWithSubstitute(for_export = 0) {
            this.toggles.loaded = true
            this.filters.for_export = for_export
            this.datatable.items.list = []
            let currentDate = this.$dayjs().format('YYYY-MM-DD')

            let payload = {
                pagination: this.datatable.options,
                filters: this.filters,
                url: 'getItemsWithSubstituteItemsWithStocksReport',
            }

            this.$store.dispatch('reports', payload).then(async response => {
                if (for_export == 0) {
                    let data = response.data.data
                    this.datatable.items.list = data.data
                    this.datatable.items.count = data.total

                } else {
                    let data = response.data
                    const workbook = new this.$exceljs.Workbook()
                    const rawDataSheetName = `ItemsWithSubstituteItemWithStocks ${this.$dayjs().format('YYYY-MM-DD')}`
                    let worksheet = workbook.addWorksheet(rawDataSheetName)

                    const columns = [
                        { header: 'IMAGE', key: '', width: 22, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                        { header: 'MODEL', key: 'model', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                        { header: 'NAME', key: 'name', width: 30, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                        { header: 'UOM', key: 'uom', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                        { header: 'TYPE', key: 'type', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                        { header: 'CATEGORY', key: 'category', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                        { header: 'STATUS', key: 'status', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                        { header: 'SRP', key: 'selling_price', width: 18, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: '#,##0.00' } },
                        { header: 'ACTUAL STOCKS', key: 'actual_stocks', width: 13, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: '#,##0' } },
                        { header: 'VIRTUAL STOCKS', key: 'virtual_stocks', width: 13, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: '#,##0' } },
                        { header: 'DEACTIVATE REASON', key: 'deactivate_reason', width: 13, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                        { header: 'NEW MODEL', key: 'substitute_models', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                        { header: 'NEW MODEL IMAGE', key: '', width: 22, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                    ]

                    worksheet.columns = columns

                    let pos = 1

                    for await (const item of data) {
                        item.selling_price = parseFloat(item.selling_price)
                        item.actual_stocks = parseInt(item.actual_stocks)
                        item.virtual_stocks = parseInt(item.virtual_stocks)
                        worksheet.addRow(item)

                        await this.addImageToWorksheet(workbook, worksheet, item, pos);

                        pos++
                    }

                    worksheet.getRow(1).font = { bold: true };
                    worksheet.getRow(1).height = 30;
                    let borderStyles = {
                        top: { style: "thin" },
                        left: { style: "thin" },
                        bottom: { style: "thin" },
                        right: { style: "thin" }
                    };

                    worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
                        row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
                            cell.border = borderStyles;
                        });
                    });

                    workbook.xlsx.writeBuffer().then((data) => {
                        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheetcharset=utf-8' })
                        const filename = `Items with New Models as of ${currentDate}.xlsx`
                        this.$filesaver.saveAs(blob, filename)
                    })
                }

                this.toggles.loaded = false
            })
        },
        resetFields(){
            this.filters = JSON.parse(JSON.stringify(this.defaults))
        },
    },
    watch: {
        'GET_ITEM_TYPES': {
            handler(val) {
                this.dropdowns.item_types.list = val
                this.loading.item_types = false
            }
        },
        'GET_ITEM_CATEGORIES': {
            handler(val) {
                this.dropdowns.item_categories.list = val
                this.loading.item_categories = false
            }
        },
    }
}
</script>
<style>
    .card-body{
        padding: 3px !important
    }
</style>
