<template>
    <v-container fluid>
        <h3 class="page-title"> ADMIN INVENTORY </h3>
        <v-container class="ma-0">
            <v-app id="item-tabs">
                <v-tabs
                    v-model="tab"
                    id="group-tab"
                    color="cyan"
                    slider-color="cyan"
                    background-color="#424242"
                    dark
                    show-arrows
                >
                    <v-tabs-slider color="cyan"></v-tabs-slider>
                        <v-tab
                            ripple
                            v-for="(item, i) in items"
                            :key="i"
                            @click="tabs(i)"
                        >
                            {{ item.tab_name }}
                        </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item v-for="(item, i) in items" :key="i">
                        <keep-alive>
                            <component
                                :is="admin_inventory_tabs_items.components"
                                :admin_inventory_tabs_items="admin_inventory_tabs_items"
                                :isActive_tab="item.tab_name"
                            >
                            </component>
                        </keep-alive>
                    </v-tab-item>
                </v-tabs-items>
            </v-app>
        </v-container>
    </v-container>
</template>
<script>
import AdminInventoryComponent from "@/views/main/modules/Admin/AdminInventory/AdminInventoryComponent.vue";
import { mapGetters } from 'vuex';
export default {
components: {
    AdminInventoryComponent,
},
data() {
    return {
        components: "",
        items: [],
        admin_inventory_tabs_items: {
            components: AdminInventoryComponent,
            dispatch: {
            get: "getAllAdminInventory",
            },
        },
        dialog_width: "",
        loaded: false,
        options: {},
        itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
        itemsPerPage: 10,
        tab: null,
        dialog_scrollable: true,
    };
},
mounted() {
    this.$store.dispatch('getAdminWarehouseCode');
    this.dropDowns();
},
computed: {
    ...mapGetters([
            "GET_ADM_WAREHOUSE_CODE",
            "GET_ADM_INVENTORY_CODE"
        ]),
},
methods: {
    dropDowns() {},
    headerItems() {
        this.items = [
            { tab_name: "ALL", view: true },
            ...this.GET_ADM_WAREHOUSE_CODE.data.map((item) => ({
                tab_name: item.code,
                view: true,
            })),
        ];
    },
    tabs(key) {
        this.$store.commit("ADM_INVENTORY_CODE", this.items[key]);
        this.$store.commit("ADM_INVENTORY_DISPATCH", this.admin_inventory_tabs_items.dispatch);
    },
    width(width) {
        switch (this.$vuetify.breakpoint.name) {
            case "xs":
            return "100%";
            case "sm":
            return "100%";
            case "md":
            return width;
            case "lg":
            return width;
            case "xl":
            return width;
        }
    },
},
watch: {
    'GET_ADM_WAREHOUSE_CODE': {
        handler(val) {
            if (val) {
                this.headerItems();
                this.tabs(0);
            }
        }
    },
},
};
</script>
<style>
#group-tab {
padding: 0 !important;
}
</style>
