<template>
    <div class="m-1" fluid>
        <v-row class="pt-2">
            <v-col cols="6" class="d-flex no-block align-items-center">
                <div class="btn-toolbar">
                    <v-btn class="ml-1 mb-2" @click="showAddLogisticsBasket()">
                        <v-icon>mdi-plus</v-icon>Add
                    </v-btn>
                </div>

            </v-col>
        </v-row>

        <v-dialog v-model="form.addLogisticsBasket" persistent width="100%" >
            <v-card elevation="0">
                <v-card-title>
                    <v-container>
                        <v-row>
                            <span>
                                Create Logistic Basket
                            </span>
                            <v-col cols="pull-right-10 p-2">
                                <v-btn text icon color="gray" class="float-right" @click="closeDialog()">
                                    <v-icon>mdi-close-circle</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col lg="9" >
                                <v-card >
                                    <v-card-title>
                                        <v-row xl='6'>
                                            <v-col lg="2">
                                                <v-autocomplete
                                                    :items="GET_VEHICLE_TYPE_SELECTION"
                                                    outlined
                                                    dense
                                                    label="Vehicle Type"
                                                    v-model="form.vehicle_type_id"
                                                    @change="getVehicles()"
                                                    clearable
                                                    >
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col lg="2">
                                                <v-autocomplete
                                                    :items="form.filtered_vehicle"
                                                    item-value="id"
                                                    item-text="name"
                                                    outlined
                                                    dense
                                                    label="Vehicles"
                                                    v-model="form.vehicle_id"
                                                    >
                                                    <!-- :disabled="form.vehicle_type_id == null" -->
                                                </v-autocomplete>
                                            </v-col>

                                            <v-col lg="2">
                                                <v-datetime-picker
                                                    v-model="form.delivery_date"
                                                    date-format="yyyy-MM-dd"
                                                    time-format="HH:mm a"
                                                    label="Delivery Date"
                                                    ref="delivery_date"
                                                    :text-field-props="{
                                                        readonly: true,
                                                        outlined: true,
                                                        dense: true,
                                                        hideDetails: true,
                                                        appendIcon: 'mdi-calendar',
                                                        persistent: true,
                                                    }"
                                                >
                                                </v-datetime-picker>
                                            </v-col>
                                            <v-col lg="3">
                                                <v-autocomplete
                                                    v-model="form.customer_list"
                                                    :items="form.customers"
                                                    label="Customer"
                                                    outlined
                                                    dense
                                                    clearable
                                                    :loading="customer.loading"
                                                    :disabled="customer.loading"
                                                    @keydown.enter="search()"
                                                >
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col lg="2">
                                                <v-textarea
                                                    v-model="form.delivery_comment"
                                                    label="Comments"
                                                    :auto-grow="true"
                                                    rows="1"
                                                    outlined
                                                    dense
                                                    hide-details
                                                >
                                                </v-textarea>
                                            </v-col>
                                            <v-col lg="1">
                                                <v-switch
                                                    v-model="form.rush"
                                                    hide-details
                                                    dense
                                                    label="Rush"
                                                    color="primary"
                                                    :true-value="1"
                                                    :false-value="0"
                                                    class="m-1 d-flex align-items-center"
                                                >
                                                </v-switch>
                                            </v-col>
                                        </v-row>
                                        <v-row xl='6'>
                                            <v-col lg="4">
                                                <v-autocomplete
                                                    v-model="form.regions"
                                                    :items="GET_ALL_REGIONS"
                                                    label="Region"
                                                    outlined
                                                    dense
                                                    cache-items
                                                    clearable
                                                    @change="getProvince()"
                                                >
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col lg="4">
                                                <v-autocomplete
                                                    v-model="form.provinces"
                                                    :items="province"
                                                    label="Province"
                                                    outlined
                                                    dense
                                                    clearable
                                                    :disabled="form.regions == null"
                                                    @change="getCities()"
                                                >
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col lg="4">
                                                <v-autocomplete
                                                    v-model="form.cities"
                                                    :items="cities"
                                                    label="City"
                                                    outlined
                                                    dense
                                                    clearable
                                                    :disabled="form.provinces == null"
                                                >
                                                </v-autocomplete>
                                            </v-col>
                                        </v-row>
                                        <v-row xl='6'>
                                            <v-col lg="3">
                                                <v-menu
                                                    v-model="menu2"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    min-width="290px"
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field
                                                            v-model="form.sales_order_dates[0]"
                                                            v-on="on"
                                                            dense
                                                            outlined
                                                            hide-details
                                                            readonly
                                                            append-icon="mdi-calendar-clock"
                                                            label="Date From"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                    v-model="form.sales_order_dates[0]" @input="menu2 = false"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col lg="3">
                                                <v-menu
                                                    v-model="menu3"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    min-width="290px"
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field
                                                            v-model="form.sales_order_dates[1]"
                                                            v-on="on"
                                                            dense
                                                            outlined
                                                            hide-details
                                                            readonly
                                                            append-icon="mdi-calendar-clock"
                                                            label="Date To"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                    v-model="form.sales_order_dates[1]" @input="menu3 = false"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col lg="3">
                                                <v-text-field
                                                    background-color="white"
                                                    dense
                                                    v-model="form.target_total_amount"
                                                    label="Target Total SO Amount"
                                                    outlined
                                                    type="number"
                                                    min="0"
                                                    hide-spin-buttons
                                                    >

                                                    <template #prepend-inner>
                                                        <span class="mt-1">&#8369;</span>
                                                    </template>
                                                </v-text-field>
                                            </v-col>
                                            <v-col lg="2">
                                                <v-autocomplete
                                                    v-model="form.clusters"
                                                    :items="GET_CLUSTER_SELECTION"
                                                    label="Cluster"
                                                    outlined
                                                    dense
                                                    clearable
                                                    @change="getCustomerCluster()"
                                                >
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col lg="1">
                                                <v-tooltip bottom color="#f69f1a">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                            @click="search()"
                                                            fab
                                                            text
                                                            small
                                                            color="#f69f1a"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            <v-icon>mdi-filter</v-icon>
                                                        </v-btn>

                                                    </template>
                                                    <span>Filter</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-card-title>

                                    <v-card-text>
                                        <v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" background-color="#424242" dark class="col-12" show-arrows>
                                            <v-tabs-slider color="cyan"></v-tabs-slider>
                                            <v-tab ripple v-for="(item, i) in items" :key="i" >
                                                {{ item }}
                                            </v-tab>
                                        </v-tabs>
									    <v-tabs-items v-model="tab">
                                            <!-- SALES ORDER -->
                                            <v-tab-item>
                                                <v-row>
                                                    <v-col lg="3" class="d-flex justify-content-center text-align-center">
                                                        <span style="color:rgb(118, 122, 1); font-size:12px" class="mt-5"> SELECTION</span>
                                                    </v-col>
                                                    <v-spacer />

                                                    <v-col lg="3" class="d-flex justify-content-center text-align-center">
                                                        <v-text-field
                                                            v-model="form.search"
                                                            label="Search SO# / Remarks"
                                                            class="mb-3 mr-3"
                                                            single-line
                                                            hide-details
                                                            append-icon="mdi-magnify"
                                                            @keydown.enter="search()"
                                                            @click:append="search()"
                                                            >
                                                        </v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-data-table
                                                    :items="form.sOForScheduling"
                                                    :headers="headers"
                                                    :options.sync="form.options"
                                                    :search="form.search"
                                                    :loading="!form.loaded"
                                                    :server-items-length="form.totalItems"
                                                    v-model="form.sOForSchedulingSelected"
                                                    show-select
                                                    dense
                                                    item-key="so_id"
                                                    height="360px"
                                                    :footer-props="{
                                                        itemsPerPageOptions: itemsPerPageOptions,
                                                    }"
                                                >
                                                    <!-- mga pag pipilian na SO -->
                                                    <template v-slot:[`header.data-table-select`]="{ header }">
                                                        <v-menu offset-x>
                                                            <template v-slot:activator="{ on: menu, attrs }">
                                                                <v-tooltip bottom>
                                                                    <template v-slot:activator="{ on: tooltip }">
                                                                        <v-icon
                                                                            class="ml-0 pa-0"
                                                                            color="primary"
                                                                            v-on="{ ...tooltip, ...menu }"
                                                                            v-bind="attrs"
                                                                            medium
                                                                        >
                                                                        mdi-view-list
                                                                        </v-icon>
                                                                    </template>
                                                                    <span>Select items</span>
                                                                </v-tooltip>
                                                            </template>
                                                            <v-list
                                                            color="blue-grey lighten-5"
                                                            outlined
                                                            dense
                                                            tile

                                                            >
                                                            <v-list-item
                                                            v-for="(item, index) in ['Page', 'All', 'Clear']"
                                                            :key="index"
                                                            light
                                                            dense
                                                            @click="selectedOption(item)"
                                                            >
                                                                <v-list-item-title>{{ item }}</v-list-item-title>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-menu>
                                                        <v-simple-checkbox style="display: none;"></v-simple-checkbox>
                                                    </template>

                                                    <template v-slot:[`item.in_dispatch`]="{ item }">
                                                        <span class="badge badge-primary" @click="viewDetails('dispatch', item.in_dispatch)" v-if="item.in_dispatch != null">Yes</span>
                                                        <span class="badge badge-danger" v-else>No</span>
                                                    </template>

                                                    <template v-slot:[`item.status`]="{ item }">
                                                        <span class="badge badge-for-scheduling" v-if="item.status == 16">{{ 'for scheduling' }}</span>
                                                    </template>

                                                    <template v-slot:[`item.total_amount`]="{ item }">
                                                        <span style="">
                                                            &#x20b1; {{ item.total_amount | currency }}
                                                        </span>
                                                    </template>

                                                </v-data-table>
                                            </v-tab-item>

                                            <!-- STOCK CARD -->
                                            <v-tab-item>
                                                <v-row>
                                                    <v-col lg="3" class="d-flex justify-content-center text-align-center">
                                                        <span style="color:rgb(118, 122, 1); font-size:12px" class="mt-5"> SELECTION</span>
                                                    </v-col>
                                                    <v-spacer />

                                                    <v-col lg="3" class="d-flex justify-content-center text-align-center">
                                                        <v-text-field
                                                            v-model="stockCards.search"
                                                            label="Search SC# / Remarks"
                                                            class="mb-3 mr-3"
                                                            single-line
                                                            hide-details
                                                            append-icon="mdi-magnify"
                                                            @keydown.enter="search()"
                                                            @click:append="search()"
                                                            >
                                                        </v-text-field>
                                                    </v-col>
                                                </v-row>

                                                <v-data-table
                                                    :items="stockCards.stockCardSelection"
                                                    :headers="stockCards.stockCardHeaders"
                                                    :options.sync="stockCards.options"
                                                    :search="stockCards.search"
                                                    :loading="!stockCards.loaded"
                                                    :server-items-length="stockCards.totalStockCards"
                                                    v-model="stockCards.stockCardSelected"
                                                    show-select
                                                    dense
                                                    item-key="id"
                                                    height="350px"
                                                    :footer-props="{
                                                        itemsPerPageOptions: itemsPerPageOptions,
                                                    }"
                                                >

                                                <template v-slot:[`header.data-table-select`]="{ header }">
                                                    <v-menu offset-x>
                                                        <template v-slot:activator="{ on: menu, attrs }">
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on: tooltip }">
                                                                    <v-icon
                                                                        class="ml-0 pa-0"
                                                                        color="primary"
                                                                        v-on="{ ...tooltip, ...menu }"
                                                                        v-bind="attrs"
                                                                        medium
                                                                    >
                                                                    mdi-view-list
                                                                    </v-icon>
                                                                </template>
                                                                <span>Select items</span>
                                                            </v-tooltip>
                                                        </template>
                                                        <v-list
                                                        color="blue-grey lighten-5"
                                                        outlined
                                                        dense
                                                        tile

                                                        >
                                                        <v-list-item
                                                        v-for="(item, index) in ['Page', 'All', 'Clear']"
                                                        :key="index"
                                                        light
                                                        dense
                                                        @click="selectedOptionStockCard(item)"
                                                        >
                                                            <v-list-item-title>{{ item }}</v-list-item-title>
                                                            </v-list-item>
                                                        </v-list>
                                                    </v-menu>
                                                    <v-simple-checkbox style="display: none;"></v-simple-checkbox>
                                                </template>

                                                <template v-slot:[`item.customer_name`]="{ item }">
                                                    <span style="font-size:12px !important;">
                                                        {{ item.customer_name  }}
                                                    </span>
                                                </template>

                                                <template v-slot:[`header.complete_date`]="{ header }">
                                                    <v-tooltip top color="#F69F1A">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                style="cursor:pointer"
                                                            >
                                                                D. Completed
                                                            </span>
                                                        </template>
                                                        <span>Date Completed</span>
                                                    </v-tooltip>
                                                </template>

                                                <template v-slot:[`item.total_amount`]="{ item }">
                                                    <span class="text-nowrap">
                                                        &#x20b1;{{ item.total_amount | currency }}
                                                    </span>
                                                </template>

                                                <template v-slot:[`item.created_at`]="{ item }">
                                                    <span class="text-nowrap">
                                                        {{ item.created_at | formatDateTime }}
                                                    </span>
                                                </template>

                                                <template v-slot:[`item.printed`]="{ item }">
                                                    <span class="badge badge-primary" v-if="item.printed">Yes</span>
                                                    <span class="badge badge-danger" v-else>No</span>
                                                </template>

                                                <template v-slot:[`item.status_text`]="{ item }">
                                                    <span class="badge badge-preparing" v-if="item.status_text=='preparing'">{{ item.status_text }}</span>
                                                    <span class="badge badge-primary" v-else-if="item.status_text=='prepared'">{{ item.status_text }}</span>
                                                    <span class="badge badge-warning" v-else-if="item.status_text=='rejected'">{{ item.status_text }}</span>
                                                    <span class="badge badge-danger" v-else-if="item.status_text=='canceled'">{{ item.status_text }}</span>
                                                    <span class="badge badge-dark" v-else-if="item.status_text=='deleted'">{{ item.status_text }}</span>
                                                    <span class="badge badge-success" v-else-if="item.status_text=='completed'">{{ item.status_text }}</span>
                                                </template>
                                                </v-data-table>
                                            </v-tab-item>
                                        </v-tabs-items>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col lg='3'>
                                <v-card elevation="0" style="border:2px dashed #ccc" height="670px">
                                    <v-card-title>
                                        <v-row no-gutters>
                                            <v-col cols="6" xl="6">
                                                <v-card
                                                    class="total-cards mr-1"
                                                >
                                                        <v-card-text>
                                                            <span class="text-dark">
                                                                Total Item CBM:
                                                                <div class="ml-1">
                                                                    <b style="color:black"> {{ totalItemCbm | currency }} </b>
                                                                </div>
                                                            </span>
                                                        </v-card-text>
                                                </v-card>
                                            </v-col>
                                            <!-- <v-tooltip bottom min-width="15%">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon small
                                                    v-bind="attrs"
                                                    v-on="on">
                                                    mdi-information
                                                    </v-icon>
                                                </template>

                                                <v-card flat height="100%" width="100%" class="ma-0 pa-0">
                                                    <v-card-text>
                                                    Tooltip Text is here
                                                    </v-card-text>
                                                </v-card>
                                            </v-tooltip> -->
                                            <v-col cols="6" xl="6">
                                                <v-card
                                                    class="total-cards mr-1"
                                                >
                                                    <v-card-text>
                                                        <span class="text-dark">
                                                            Total Estimate CBM:
                                                            <div class="ml-1">
                                                                <b style="color:black">{{ totalEstimateCbm | currency }}</b>
                                                            </div>
                                                        </span>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters>
                                            <v-col cols="6" xl="6">
                                                <v-card
                                                    class="total-cards mr-1"
                                                >
                                                    <v-card-text>
                                                            <span class="text-dark">
                                                                Total Weight (kgs):
                                                            <div class="ml-1">
                                                                <b style="color:black">{{ totalWeight | currency }}</b>
                                                            </div>
                                                            </span>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="6" xl="6">
                                                <v-card
                                                    class="total-cards mr-1"
                                                >
                                                    <v-card-text>
                                                        <span class="text-dark">
                                                            Total SO Amount:
                                                            <div class="ml-1">
                                                                <b style="color:black">{{ totalAmount | currency }}</b>
                                                            </div>
                                                        </span>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters>
                                            <v-col cols="12" xl="12">
                                                <v-card
                                                    class="total-cards mr-1"
                                                >
                                                    <v-card-text>
                                                            <span class="text-dark">
                                                                Total can Serve Amount: <br> <b style="color:black"> {{ defaultWarehouse | currency }} </b>
                                                            </span>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                        </v-row>

                                        <v-row>
                                            <v-col lg="4" class="d-flex justify-content-center text-align-center">
                                                <span style="color:rgb(118, 122, 1); font-size:12px" class="mt-1"> SELECTED</span>
                                            </v-col>
                                            <v-spacer />

                                            <v-col lg="5" class="d-flex justify-content-center text-align-center">
                                                <v-text-field
                                                    v-model="form.searchSelectionSelected"
                                                    label="Search Selected"
                                                    class="mt-1 mr-2"
                                                    dense
                                                    append-icon="mdi-magnify"
                                                    single-line
                                                    hide-details
                                                    @keyup="searchSelected"
                                                    >
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-card-title>

                                    <v-card-text class="ma-0 pa-0">
                                        <v-card class="pa-1 ma-1" max-height="360px" style="overflow-y:auto" elevation="0">
                                            <v-card-text>
                                                <v-row class="mr-1" style="1">
                                                    <v-col lg='4' v-for="(select,index) in form.filterSelected" :key="index">
                                                        <div class="d-flex column-content position-relative">
                                                            <!-- <v-row class="absolute-element">
                                                                <v-col>
                                                                    <v-btn
                                                                        text
                                                                        icon
                                                                        small
                                                                        color="gray"
                                                                        class="float-right"
                                                                        @click="selectedSo(select)"
                                                                >
                                                                        <v-icon class="btn-action" small>mdi-close-circle</v-icon>
                                                                    </v-btn>
                                                                </v-col>
                                                            </v-row> -->
                                                            <v-chip
                                                                class="w-100"
                                                                :color="select.from == 0 ? '#CC6600' : 'primary'"
                                                                label
                                                                text-color="white"
                                                                style="cursor:pointer"
                                                                v-on="on"
                                                                @click="viewSalesOrder(select, select.from == 0 ? select.so_id : select.id)"
                                                                >
                                                                <div class="ma-0" style="font-size: 14px;">
                                                                    {{ select.from == 0 ? 'SO#' : 'SC#'}} {{ select.from == 0 ? select.order_num : select.stock_card_num}}
                                                                </div>
                                                            </v-chip>
                                                            <!-- <v-tooltip top>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn
                                                                        class="mt-1"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        text
                                                                        icon
                                                                        x-small
                                                                        color="orange"
                                                                    >
                                                                        <v-icon class="btn-action" small @click="viewSalesOrder(select.so_id)">mdi-eye</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>View SO</span>
                                                            </v-tooltip> -->
                                                            <v-btn
                                                                class="ml-1 mt-1"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                text
                                                                icon
                                                                x-small
                                                                color="gray"
                                                            >
                                                                <v-icon class="btn-action" small @click="selectedSo(select)">mdi-close-circle</v-icon>
                                                            </v-btn>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-title>
                <v-card-actions class="d-flex justify-content-center">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <span
                                class="mx-2"
                                dark
                                v-bind="attrs"
                                v-on="on">
                                <v-btn class="mx-2" text :loading="!form.loadingButton" @click="updateBatchStatus()">
                                    <v-icon style="font-size:40px" color="success" >
                                        mdi-content-save
                                    </v-icon>
                                </v-btn>
                            </span>
                        </template>
                        <span>Create</span>
                    </v-tooltip>
                </v-card-actions>

                <SalesOrdersViewComponentVue
                    :viewSalesOrderId="so_id"
                    :service="0"
                    :enableButton="false"
                    @closeView="closeView"
                ></SalesOrdersViewComponentVue>

                <stockcardviewcomponent
                    :viewStockCardId="sc_id"
                    @closeView="closeView"
                    :enableButton="false"
                ></stockcardviewcomponent >

            </v-card>
        </v-dialog>
    </div>

</template>

<script>
import swal from 'sweetalert2'
import { mapGetters } from 'vuex'

import LogisticsBasketComponent from './LogisticsBasketComponent.vue'
import SalesOrdersViewComponentVue from '@/views/main/modules/Sales/SalesOrdersNew/SalesOrdersViewComponent.vue'
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
import stockcardviewcomponent from '@/views/main/modules/Operations/StockCards/StockCardViewComponent.vue'

export default {
    mixins:[LogisticsBasketComponent, SharedFunctionsComponentVue],
    components: {
        LogisticsBasketComponent,
        SalesOrdersViewComponentVue,
        stockcardviewcomponent
    },
    props: [
        'service',
    ],
    data() {
        return {
            form: {
                valid: false,
                addLogisticsBasket: false,
                delivery_date: null,
                loaded: false,
                loadingButton: true,
                search: '',
                options: {},
                sOForScheduling: [],
                sOForSchedulingSelected: [],
                filterSelected: [],
                totalItems: 0,
                option: '',
                allSo: [],
                vehicle_id: [],
                vehicle_type_id: [],
                customer_list: [],
                delivery_comment: '',
                regions: [],
                provinces: [],
                cities: [],
                searchSelectionSelected: '',
                target_total_amount: 0,
                rush: 0,
                filtered_vehicle: [],
                sales_order_dates: [
                    this.$dayjs().startOf("year").format("YYYY-MM-DD"),
                    this.$dayjs().endOf("month").format("YYYY-MM-DD"),
                ],
                clusters: [],
                customers: [],
            },
            stockCards: {
                options: {},
                stockCardHeaders: [
                    { text: 'SC#' , width: '80px',sortable: false, value: 'stock_card_num'},
                    { text: 'Warehouse', align: 'left', sortable: false, value: 'warehouse_name' },
                    { text: 'PL#', width: '8%', align: 'left', sortable: false, value: 'packing_list_num', },
                    { text: 'SO#', width: '8%', align: 'left', sortable: false, value: 'order_num', },
                    { text: 'Customer', align: 'left', sortable: false, value: 'customer_name', },
                    { text: 'Status', align: 'left', sortable: false, value: 'status_text', },
                    { text: 'Date Issued', align: 'left', sortable: false, value: 'created_at', },
                    { text: 'Issuer', width: '11%', align: 'left', sortable: false, value: 'issuer_name', },
                    { text: 'Date Completed', width: '8%', align: 'left', sortable: false, value: 'complete_date', },
                    { text: 'Completed By', width: '10%', align: 'left', sortable: false, value: 'completed_by_name', },
                ],
                search: '',
                loaded: false,
                stockCardSelection: [],
                totalStockCards: 0,
                stockCardSelected: [],
                option: '',
                stockCardsLb: [],
            },
            so_id: 0,
            sc_id: 0,
            items: ['Sales Order', 'Stock Card'],
            tab: 0,
            attrs: '',
            on: '',
            component_dispatches:[
				'getCountries',
				'getRegions',
				'getProvince',
				'getCities',
				'getVehicleType',
				'getClusterSelection',
			],
            menu2: false,
            menu3: false,
            defaultWarehouse: 0,
            allWarehouse: 0,
            province: [],
            cities: [],
            customer: {
                loading: false,
            },
            itemsPerPageOptions: [ 10, 20, 50, 100 ],
        }
    },

    computed: {
        totalItemCbm(){
            return this.form.sOForSchedulingSelected.reduce((subtotal, current) => parseFloat(subtotal) + parseFloat(current.total_cbm), 0)
        },
        totalEstimateCbm(){
            return this.form.sOForSchedulingSelected.reduce((subtotal, current) => parseFloat(subtotal) + parseFloat(current.total_estimate_cbm), 0)
        },
        totalWeight(){
            return this.form.sOForSchedulingSelected.reduce((subtotal, current) => parseFloat(subtotal) + parseFloat(current.total_weight), 0)
        },
        totalAmount(){
            return this.form.sOForSchedulingSelected.reduce((subtotal, current) => parseFloat(subtotal) + parseFloat(current.total_amount), 0)
        },

        ...mapGetters([
            "GET_ITEM_TYPES",
            "GET_VEHICLES",
            "GET_ALL_COUNTRIES",
            "GET_ALL_REGIONS",
            "GET_ALL_PROVINCE",
            "GET_ALL_CITIES",
            "GET_CUSTOMERS_LIST_SELECTION",
            "GET_CLUSTER_SELECTION",
            "GET_VEHICLE_TYPE_SELECTION",
      ]),

        headers() {
            if(this.service == 0) {
                return [
                    { text: 'SO#' , width: '80px',sortable: false, value: 'order_num'},
                    { text: 'Customer' , sortable: false, value: 'customer_name'},
                    { text: 'Address' , sortable: false, value: 'shipping_address_text'},
                    { text: 'Amount' , width: '12%', sortable: false, value: 'total_amount'},
                    { text: 'Delivery Method' , width: '10%', align: 'center', sortable: false, value: 'delivery_method'},
                    { text: 'In Dispatch' , width: '10%', align: 'center', sortable: false, value: 'in_dispatch'},
                    { text: 'Status' , sortable: false, value: 'status'},
                ]
            } else {
                return [
                    { text: 'SO#' , width: '80px',sortable: false, value: 'order_num'},
                    { text: 'Customer' , sortable: false, value: 'customer_name'},
                    { text: 'Remarks' , sortable: false, value: 'remarks'},
                    { text: 'Amount' , width: '12%', sortable: false, value: 'total_amount'},
                    { text: 'Delivery Method' , width: '10%', align: 'center', sortable: false, value: 'delivery_method'},
                    { text: 'In Dispatch' , width: '10%', align: 'center', sortable: false, value: 'in_dispatch'},
                    { text: 'Status' , sortable: false, value: 'status'},
                ]
            }
        }
    },

    methods: {
        getSoForScheduling() {
            this.form.loaded = false
            this.form.sOForScheduling = []
            let payload = {
                itemsPerPage                 : this.form.options.itemsPerPage,
                page                         : this.form.options.page,
                searchSelection              : this.form.search,
                customer                     : this.form.customer_list,
                rush                         : this.form.rush,
                service                      : this.service,
                date                         : this.form.sales_order_dates,
            }
            this.$store.dispatch('getSoForScheduling', payload).then(response => {
                this.form.loaded = true
                this.form.sOForScheduling        = response.data.data
                this.form.totalItems             = response.data.total
            }).catch( err =>{
                console.log(err)
                swal.fire('Error!','- an error occured','error')
            });
        },

        showAddLogisticsBasket() {
            this.form.addLogisticsBasket = true;
            this.form.delivery_date = null;
            this.form.vehicle_type_id = null;
            this.form.regions = null;
            this.form.provinces = null;
            this.form.cities = null;
        },

        closeDialog() {
            this.form.addLogisticsBasket = false;
            this.getSoForScheduling()
            this.getAllStockCards()
            this.clearFields()
        },

        clearFields() {
            this.form.delivery_date = null;
            this.$refs.delivery_date.clearHandler();
            this.form.customer_list = [];
            this.form.vehicle_id = [];
            this.form.delivery_comment = '';
            this.form.sOForSchedulingSelected = [];
            this.form.loadingButton = true;
            this.form.target_total_amount = 0;
            this.form.rush = 0;
            this.stockCards.stockCardSelected = [];
        },

        async updateBatchStatus() {
            this.form.loadingButton = false;

            if (this.form.delivery_date == null || this.form.vehicle_id.length == 0) {
                swal.fire('Must select Delivery Date and Vehicle!', '', 'warning').then(confirm => {
                    this.form.loadingButton = true;
                })
                return false
            } else if (this.form.filterSelected.length == 0) {
                swal.fire('Must select Sales Order or Stock Card.', '', 'warning').then(confirm => {
                    this.form.loadingButton = true;
                })
                return false
            } else if (this.totalAmount < this.form.target_total_amount) {
                swal.fire({
                    title: 'The target total amount has not been reached.',
                    text: '- Do you still want to continue?',
                    icon: 'info',
                    showCancelButton: true,
                    cancelButtonColor : 'grey',
                    reverseButtons:true,
                    allowOutsideClick :  false,
                }).then(confirm => {
                    if(confirm.isConfirmed) {
                        this.form.loadingButton = true;
                        this.createLogisticBasket()
                    } else if (confirm.isDismissed) {
                        this.form.loadingButton = true;
                    }
                })
                return false
            } else {
                this.createLogisticBasket()
            }
        },

        selectedOption(opt) {
            this.form.option = opt;

            let test = this.form.filterSelected.filter((e) => {
                return e.from == 0;
            });

            if (opt === 'Page') {
                if (this.form.sOForSchedulingSelected.length > 0) {
                    if(this.stockCards.stockCardSelected.length > 0) {
                        this.form.sOForSchedulingSelected = this.form.sOForScheduling.concat(test)
                    } else {
                        this.form.sOForSchedulingSelected = [...new Set([...this.form.sOForScheduling, ...this.form.filterSelected])]
                    }
                } else {
                    this.form.sOForSchedulingSelected = this.form.sOForScheduling.slice();
                }
            } else if (opt === 'All') {
                if (this.form.sOForSchedulingSelected.length > 0) {
                    if(this.stockCards.stockCardSelected.length > 0) {
                        this.form.sOForSchedulingSelected = this.form.allSo.concat(test)
                    } else {
                        this.form.sOForSchedulingSelected = [...new Set([...this.form.allSo, ...this.form.filterSelected])]
                    }
                } else {
                    this.form.sOForSchedulingSelected = this.form.allSo.slice();
                }
            } else if (opt === 'Clear') {
                this.form.sOForSchedulingSelected = [];
                this.form.filterSelected = [];
                this.stockCards.stockCardSelected = [];
            }
            this.defaultWarehouse = 0
            this.allWarehouse = 0
        },

        selectedOptionStockCard(opt) {
            this.stockCards.option = opt;

            let test = this.form.filterSelected.filter((e) => {
                return e.from == 1;
            });

            if (opt === 'Page') {
                if (this.stockCards.stockCardSelected.length > 0) {
                    if(this.form.sOForSchedulingSelected.length > 0) {
                        this.stockCards.stockCardSelected = this.stockCards.stockCardSelection.concat(test)
                    } else {
                        this.stockCards.stockCardSelected = [...new Set([...this.stockCards.stockCardSelection, ...this.form.filterSelected])]
                    }
                } else {
                    this.stockCards.stockCardSelected = this.stockCards.stockCardSelection.slice();
                }
            } else if (opt === 'All') {
                if (this.stockCards.stockCardSelected.length > 0) {
                    if(this.form.sOForSchedulingSelected.length > 0){
                        this.stockCards.stockCardSelected = this.stockCards.stockCardsLb.concat(test)
                    } else {
                        this.stockCards.stockCardSelected = [...new Set([...this.stockCards.stockCardsLb, ...this.form.filterSelected])]
                    }
                } else {
                    this.stockCards.stockCardSelected = this.stockCards.stockCardsLb;
                }
            } else if (opt === 'Clear') {
                this.stockCards.stockCardSelected = [];
                this.form.filterSelected = [];
                this.form.sOForSchedulingSelected = [];
            }
        },

        selectedSo(select) {
            if(select.from == 1) {
                this.stockCards.stockCardSelected = this.stockCards.stockCardSelected.filter(e=>{
                    if(e.so_id != select.so_id) {
                        return e
                    }
                })

                if (this.form.sOForSchedulingSelected.length > 0 || this.stockCards.stockCardSelected > 0) {
                    this.form.filterSelected = this.stockCards.stockCardSelected.concat(this.form.sOForSchedulingSelected)
                } else {
                    this.form.filterSelected = this.stockCards.stockCardSelected
                }

            } else {
                this.form.sOForSchedulingSelected = this.form.sOForSchedulingSelected.filter(e=>{
                    if(e.so_id != select.so_id) {
                        return e
                    }
                })

                if (this.form.sOForSchedulingSelected.length > 0 || this.stockCards.stockCardSelected > 0) {
                    this.form.filterSelected = this.form.sOForSchedulingSelected.concat(this.stockCards.stockCardSelected)
                } else {
                    this.form.filterSelected = this.form.sOForSchedulingSelected
                }
            }
        },

        viewSalesOrder(select,id){
            if(select.from == 0) {
                if(!id) {
                    return false
                }
                this.so_id = id
            } else {
                if(!id) {
                    return false
                }
                this.sc_id = id
            }
        },

        closeView(){
            this.so_id = 0
            this.sc_id = 0
        },

        getCustomerAddress() {
            this.customer.loading = true

            let payload = {
                regions : this.form.regions,
                provinces : this.form.provinces,
                cities : this.form.cities,
                url: 'order-delivery-schedule/get-customer-address-delivery'
            }
            this.$store.dispatch('getDelivery', payload).then(response => {
                this.form.customers = this.GET_CUSTOMERS_LIST_SELECTION.filter( e => response.data.find( x => x.customer_id == e.value) )
                this.customer.loading = false
            })
        },

        createLogisticBasket() {
            let rush = this.form.sOForSchedulingSelected.filter(e=> { return e.rush == 1 })
            let sales_order = this.form.filterSelected.filter(e => { return e.from == 0 })
            let stock_card = this.form.filterSelected.filter(e => { return e.from == 1 })

            swal.fire({
                title: '',
                text:'- Are you sure you want to create?',
                icon: 'info',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
            }).then(result => {
                if(result.isConfirmed) {
                    let payload = {
                        data:{
                            delivery_date               : this.form.delivery_date,
                            selectedSo                  : sales_order,
                            selectedSc                  : stock_card,
                            vehicle_id                  : this.form.vehicle_id,
                            delivery_comment            : this.form.delivery_comment,
                            // dispatch_id                 : 0,
                            target_total_amount         : this.form.target_total_amount,
                            service                     : this.service,
                            rush                        : rush.length > 0 ? 1 : 0,
                            url                         :'order-delivery-schedule/create-logistic-basket'
                        }
                    }
                    this.$store.dispatch('postDelivery',payload).then(response => {
                        if (response.data.msg) {
                            swal.fire('Update Succcess!', response.data.msg , 'success').then(confirm => {
                                this.closeDialog()
                                this.form.loadingButton = true;
                                this.$store.commit('REFRESH_LOGISTIC', true)
                            })
                        }
                        else{
                            this.form.loadingButton = true
                        }
                    }).catch(err => {
                        swal.fire('Oooppsss!' , err ,'error').then(confirm => {
                            this.form.loadingButton = true;
                        })
                    })
                } else if (result.isDismissed) {
                    this.form.loadingButton = true;
                }
            })
        },

        searchSelected() {
            if(this.form.searchSelectionSelected == '') {
                this.form.filterSelected = this.form.sOForSchedulingSelected.concat(this.stockCards.stockCardSelected)
            } else {
                let test = this.form.sOForSchedulingSelected.concat(this.stockCards.stockCardSelected)
                this.form.filterSelected = test.filter((e) => {
                        return e.from == 0 ? e.order_num == this.form.searchSelectionSelected : e.stock_card_num == this.form.searchSelectionSelected
                });
            }
        },

        getAllStockCards(){
            this.stockCards.loaded = false;
            this.stockCards.stockCardSelection = [];
            let payload = {
                url:            'order-delivery-schedule/get-stock-card-lb',
                page:           this.stockCards.options.page,
                itemsPerPage:   this.stockCards.options.itemsPerPage,
                sortBy:         this.stockCards.options.sortBy,
                sortDesc:       this.stockCards.options.sortDesc,
                search:         this.stockCards.search,
                customer_id:    this.form.customer_list,
                date:           this.form.sales_order_dates
            }
            this.$store.dispatch('getDelivery',payload).then(response => {
                this.stockCards.stockCardSelection              = response.data.data.data
                this.stockCards.loaded                          = true
                this.stockCards.totalStockCards                 = response.data.data.total
            })
        },

        search() {
            if(this.tab == 0) {
                this.form.options.page = 1
                this.getSoForScheduling({ query: this.form.search, customer: this.form.customer_list, date: this.form.sales_order_dates, service: this.service, })
                this.getAllSoForScheduling({ query: this.form.search, customer: this.form.customer_list, date: this.form.sales_order_dates, service: this.service, })
            } else {
                this.stockCards.options.page = 1
                this.getAllStockCards({ query: this.stockCards.search, customer: this.form.customer_list, date: this.form.sales_order_dates, })
                this.getAllDataStockCards({ query: this.stockCards.search, customer: this.form.customer_list, date: this.form.sales_order_dates, })
            }
        },

        getVehicles() {
            this.$store.dispatch("getVehicle", {type: this.form.vehicle_type_id}).then(response => {
                this.form.filtered_vehicle = response.data
            })
        },

        getAllSoForScheduling() {
            this.form.loaded = false
            this.form.sOForScheduling = []
            let payload = {
                searchSelection              : this.form.search,
                customer                     : this.form.customer_list,
                rush                         : this.form.rush,
                service                      : this.service,
                date                         : this.form.sales_order_dates,
            }
            this.$store.dispatch('getAllSoForScheduling', payload).then(response => {
                this.form.allSo                  = response.all_data
            }).catch( err =>{
                console.log(err)
                swal.fire('Error!','- an error occured','error')
            });
        },

        getAllDataStockCards(){
            this.stockCards.loaded = false;
            this.stockCards.stockCardSelection = [];
            let payload = {
                url:            'order-delivery-schedule/get-all-stock-card-lb',
                search:         this.stockCards.search,
                customer_id:    this.form.customer_list,
                date:           this.form.sales_order_dates,
            }
            this.$store.dispatch('getDelivery',payload).then(response => {
                this.stockCards.stockCardsLb                    = response.data.stock_card
            })
        },

        getAllDropdowns(){
			this.component_dispatches.forEach(el=>{
				this.$store.dispatch(el)
			})
		},

        tabAccess() {
            if(this.service == 0) {
                return this.items = ['Sales Order', 'Stock Card']
            } else {
                return this.items = ['Sales Order']
            }
        },

        getProvince() {
            this.province = [];

            if(this.form.regions) {
                this.province = this.GET_ALL_PROVINCE.filter( e => e.regCode == this.form.regions )
            }
        },

        getCities() {
            this.cities = [];

            if(this.form.provinces) {
                this.cities = this.GET_ALL_CITIES.filter(e => e.provCode == this.form.provinces )
            }
        },

        getCustomerCluster() {
            this.customer.loading = true
            this.$store.dispatch('dropdownGet', { cluster: this.form.clusters, url: 'get-customer-cluster' }).then(response => {
                if(this.form.clusters) {
                    this.form.customers = this.GET_CUSTOMERS_LIST_SELECTION.filter( e => response.data.find( x => x.customer_id == e.value) )
                }
                this.customer.loading = false
            })
        }
    },

    watch: {
        'form.options': {
            handler(val){
                this.getSoForScheduling();
            },
            deep: true,
        },

        'form.regions': {
            handler(val) {
                if(val == null) {
                    this.form.provinces = null;
                }
                this.getCustomerAddress()
            },
            immediate: true,
        },

        'form.provinces': {
            handler(val) {
                if(val == null) {
                    this.form.cities = null;
                }
                this.getCustomerAddress()
            },
            immediate: true,
        },

        'form.cities': {
            handler(val) {
                this.getCustomerAddress()
            },
            immediate: true,
        },

        'form.sOForSchedulingSelected': {
            handler(val) {
                val.forEach((e)=>{
                    e.from = 0
                })

                if(this.selectedOption()){
                    this.defaultWarehouse = 0
                    this.allWarehouse = 0
                    this.form.filterSelected = val
                } else {
                    if (val.length > 0 || this.stockCards.stockCardSelected.length > 0) {
                        this.form.filterSelected = [...new Set([...val, ...this.stockCards.stockCardSelected])]
                    } else {
                        this.form.filterSelected = val
                    }
                }
            },

            deep: true
        },

        'stockCards.stockCardSelected': {
            handler(val) {
                val.forEach((e)=>{
                    e.from = 1
                })

                if(this.selectedOptionStockCard()){
                    this.form.filterSelected = val
                } else {
                    if (val.length > 0 || this.form.sOForSchedulingSelected.length > 0) {
                        this.form.filterSelected = [...new Set([...val, ...this.form.sOForSchedulingSelected])]
                    } else {
                        this.form.filterSelected = val
                    }
                }
            },
            deep: true

        },

        'form.rush': {
            handler(val) {
                this.getSoForScheduling();
            },
        },

        'stockCards.options': {
            handler(val) {
                this.getAllStockCards()
            },
            deep: true,
        },

        'form.vehicle_type_id': {
            handler(val) {
                if(val == null) {
                    this.form.filtered_vehicle = [];
                }
            },
            immediate: true,
            deep: true,
        },

        'tab': {
            handler(val) {
                if(this.service == 0) {
                    return this.items = ['Sales Order', 'Stock Card']
                } else {
                    return this.items = ['Sales Order']
                }
            }
        },

        'form.filterSelected': {
            handler(val) {
                if(val.length > 0) {
                        let so_ids = val.filter(e=> e.from == 0).map(e => e.so_id)
                        let payload = {
                            so_ids: so_ids,
                            url: 'order-delivery-schedule/get-logistic-warehouse'
                        }
                        this.$store.dispatch('getDelivery', payload).then(response => {
                            this.defaultWarehouse = 0
                            this.allWarehouse = 0
                            let default_warehouse = response.data.data.filter(e=> e.id == 16)
                            if(default_warehouse) {
                                response.data.items.forEach((x) => {
                                    let e = default_warehouse.find(el => el.item_id == x.item_id);
                                    if(e) {
                                        if(e.item_id == x.item_id) {
                                            if(e.quantity < x.pending_qty) {
                                                this.defaultWarehouse += x.amount * e.quantity
                                            } else {
                                                this.defaultWarehouse += x.amount * x.pending_qty
                                            }

                                            this.defaultWarehouse = parseFloat(this.defaultWarehouse)
                                        }
                                    }
                                })
                            }
                            let all_warehouse = response.data.data
                            if(all_warehouse) {
                                let totalQuantity = 0;
                                response.data.items.forEach((x) => {
                                    let e = all_warehouse.find(el => el.item_id == x.item_id);
                                    if(e) {
                                        if (e.item_id == x.item_id) {
                                            totalQuantity += e.quantity;

                                        if (totalQuantity < x.pending_qty) {
                                            this.allWarehouse += x.amount * totalQuantity;
                                        } else {
                                            this.allWarehouse += x.amount * x.pending_qty;
                                        }

                                        this.allWarehouse = parseFloat(this.allWarehouse);
                                    }
                                }
                            });
                        }
                    })
                }
            }
        },

        'form.clusters': {
            handler(val) {
                if(val == null) {
                    this.getCustomerAddress()
                }
            }
        }
    },

    mounted() {
        this.getSoForScheduling()
        this.getAllSoForScheduling()
        this.getAllStockCards()
        this.getAllDataStockCards()
        this.getCustomerAddress()
        this.getAllDropdowns()
        this.getVehicles()
    },
}
</script>

<style scoped>
.position-relative {
    position: relative;
}

.absolute-element {
    position: absolute;
    top: 0;
    right: 0;
}

#group-tab{
    padding: 0 !important;
}
</style>