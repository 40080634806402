<template>
  	<v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
            <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                <span class="headline">
                    Spare Parts Quotation Details
                </span>
                <v-row class="m-0">
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                        text
                        icon
                        small
                        color="gray"
                        class="float-right"
                        @click="closeDialog()"
                        >
                        <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-card-text v-if="this.GET_SWITCH_ITEM == false">
                <v-flex class="d-flex">
                    <v-col class="text-dark" cols="10">
                        <v-row class="m-0">
                            <v-col cols="6">
                                Spare Parts Quotation No: {{ quoteNum }}
                            </v-col>
                            <v-col cols="6" class="d-flex justify-space-between">
                                <span>Issuer: {{ issuerName }}</span>
                            </v-col>
                        </v-row>
                        <v-row class="m-0">
                            <v-col>
                                Customer: {{ customerName }}
                            </v-col>
                            <v-col>
                                Date Issued: {{ dateIssued | formatDateTime }}
                            </v-col>
                        </v-row>
                        <v-row class="m-0">
                            <v-col>
                                Remarks: {{ remarks }}
                            </v-col>
                        </v-row>
                        <v-row class="m-0">
                            <v-col>
                                Notes: {{ notes }}
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="2" class="d-flex justify-end">
                        <BaseFilesViewerVue :delete="showDelete()" :sqId="sqId" :buttonLoading="GET_REFRESH_SP_AREA == true"/>
                        <v-tooltip bottom color="#f69f1a">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    fab
                                    icon
                                    text
                                    small
                                    v-on="on"
                                    v-bind="attrs"
                                    color="#f69f1a"
                                    @click="exportToExcel(GET_SWITCH_ITEM)"
                                >
                                    <v-icon color="#f69f1a">mdi-printer-pos-wrench</v-icon>
                                </v-btn>
                            </template>
                            <span>Export</span>
                        </v-tooltip>
                        <v-tooltip bottom color="#f69f1a">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    fab
                                    icon
                                    text
                                    small
                                    v-on="on"
                                    v-bind="attrs"
                                    color="#f69f1a"
                                    @click="printPDF(GET_SWITCH_ITEM)"
                                >
                                    <v-icon color="#f69f1a">mdi-cloud-download</v-icon>
                                </v-btn>
                            </template>
                            <span>Print PDF</span>
                        </v-tooltip>
                    </v-col>
                </v-flex>
                <v-data-table
                    :headers="itemHeaders"
                    :items="salesQuotationItems"
                    :items-per-page="500"
                    :loading="loaded"
                    hide-default-footer
                    class="w-100"
                    >

                    <template v-slot:[`item.foc`]="{ item }">
                        <span> {{ item.foc == 1 ? 'Yes' : 'No' }}</span>
                    </template>

                    <template v-slot:[`item.srp`]="{ item }">
                        <span> &#x20b1;{{ item.srp | currency }}</span>
                    </template>

                    <template v-slot:[`item.disc_price`]="{ item }">
                        <span> &#x20b1;{{ item.disc_price | currency }}</span>
                    </template>

                    <template v-slot:[`item.final_price`]="{ item }">
                        <span> &#x20b1;{{ item.final_price | currency }}</span>
                    </template>

                    <template v-slot:[`item.total_amount`]="{ item }">
                        <span> &#x20b1;{{ item.total_amount | currency }}</span>
                    </template>

                </v-data-table>

                <v-row class="m-0">
                    <v-container fluid>
                        <v-row>
                        <div class="col-sm-8 col-md-6 text-left">
                            <div class="card-body py-2 d-flex">
                                <!-- :disabled="sales_quotation.status != 0"
                                v-if="userAccess.convert_to_sales_order && !this.sales_quotation.sc_repair_form_id" -->
                                <span class="m-1"
                                    ><v-btn
                                    @click="convertToSalesOrder()"
                                    :loading="loaded"
                                    >Convert to SO</v-btn
                                    ></span
                                >
                            </div>
                        </div>
                        <br/>
                        <!-- <div
                            class="col-sm-12 col-md-4 pull-right text-right text-dark"
                        >
                            Total Amount: &#x20b1;
                            {{
                                totalAmount | currency
                            }}
                            <br/>
                            Total Amount with Stocks: &#x20b1;
                            {{
                                totalAmountwithStocks | currency
                            }}
                            <br/>
                            Total Amount without Stocks: &#x20b1;
                            {{
                                totalAmountwithoutStocks | currency
                            }}
                        </div> -->
                        </v-row>
                    </v-container>
                </v-row>
            </v-card-text>

            <v-card-text v-if="this.GET_SWITCH_ITEM == true">
                <v-flex class="d-flex">
                    <v-col class="text-dark" cols="10">
                        <v-row class="m-0">
                            <v-col>
                                Parts: {{ itemModel }}
                            </v-col>
                            <v-col>
                                Item Name: {{ itemName }}
                            </v-col>
                        </v-row>
                        <v-row class="m-0">
                            <v-col>
                                Item Description: {{ itemDescription }}
                            </v-col>
                            <v-col>
                                Stocks: {{ itemStocks }}
                            </v-col>
                        </v-row>
                        <v-row class="m-0">
                            <v-col>
                                Ideal Stocks: {{ itemIdealStocks }}
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="2" class="d-flex justify-end">
                        <v-tooltip bottom color="#f69f1a">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    fab
                                    icon
                                    text
                                    small
                                    v-on="on"
                                    v-bind="attrs"
                                    color="#f69f1a"
                                    @click="exportToExcel(GET_SWITCH_ITEM)"
                                >
                                    <v-icon color="#f69f1a">mdi-printer-pos-wrench</v-icon>
                                </v-btn>
                            </template>
                            <span>Export</span>
                        </v-tooltip>
                        <v-tooltip bottom color="#f69f1a">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    fab
                                    icon
                                    text
                                    small
                                    v-on="on"
                                    v-bind="attrs"
                                    color="#f69f1a"
                                    @click="printPDF(GET_SWITCH_ITEM)"
                                >
                                    <v-icon color="#f69f1a">mdi-cloud-download</v-icon>
                                </v-btn>
                            </template>
                            <span>Print PDF</span>
                        </v-tooltip>
                    </v-col>
                </v-flex>
                <v-data-table
                    :headers="sQHeaders"
                    :items="salesQuotation"
                    :items-per-page="500"
                    :loading="loaded"
                    hide-default-footer
                    class="w-100"
                >

                <template v-slot:[`item.total_amount`]="{ item }">
                    <span class="text-nowrap">
                        &#x20b1;{{ item.total_amount | currency }}
                    </span>
                </template>

                <template v-slot:[`item.status_text`]="{ item }">
                    <span class="badge badge-saved" v-if="item.status_text == 'saved'">{{ item.status_text }}</span>
                    <span class="badge badge-purple" v-else-if="item.status_text == 'converted'">{{ item.status_text }}</span>
                    <span class="badge badge-warning" v-else-if="item.status_text == 'rejected'">{{ item.status_text }}</span>
                    <span class="badge badge-danger" v-else-if="item.status_text == 'canceled'">{{ item.status_text }}</span>
                    <span class="badge badge-dark" v-else-if="item.status_text == 'deleted'">{{ item.status_text }}</span>
                </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-form>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseFilesViewerVue from "@/views/main/modules/Services/Base/BaseFilesViewer.vue";
import Swal from 'sweetalert2'

export default {
    data() {
        return {
            valid: false,
            itemHeaders: [
                { text: 'Model', value: 'model', sortable: false },
                { text: 'Name', value: 'name', sortable: false },
                { text: 'Type', value: 'type_name', sortable: false },
                { text: 'FOC', value: 'foc', sortable: false },
                { text: 'SRP', value: 'srp', sortable: false },
                { text: 'Disc Price', value: 'disc_price', sortable: false },
                { text: 'Qty', value: 'quantity', sortable: false },
                { text: 'UOM', value: 'uom_name', sortable: false },
                { text: 'Price', value: 'final_price', sortable: false },
                { text: 'Total', value: 'total_amount', sortable: false },
            ],
            sQHeaders: [
                { text: 'Quote Num', value: 'quote_num' },
                { text: 'Customer', value: 'customer_name' },
                // { text: 'Quantity', value: 'quantity' },
                { text: 'Remarks', value: 'remarks' },
                { text: 'Amount', value: 'total_amount' },
                { text: 'Status', value: 'status_text' },
                // { text: 'Action', value: 'actions' },
            ],
            quoteNum: '',
            issuerName: '',
            customerName: '',
            dateIssued: '',
            remarks: '',
            notes: '',
            totalAmount: '',
            sqId: '',
            salesQuotationItems: [],
            loaded: true,
            totalAmountwithStocks: 0,
            totalAmountwithoutStocks: 0,
            itemId: '',
            salesQuotation: [],
            itemModel: '',
            itemName: '',
            itemDescription: '',
            itemStocks: 0,
            itemIdealStocks: 0,
            created_at: '',
            salesId: '',
            saleQuotationFiles: [],
        }
    },

    computed: {
        ...mapGetters([
            'DIALOGS',
            'ACTION',
            'GET_SP_AREA_DIALOG_VIEW',
            'GET_SPARE_PARTS_STAGING_AREA',
            'GET_REFRESH_SP_AREA',
            'GET_SPARE_PARTS_AREA_TAB',
            'GET_SP_AREA_DISPATCH',
            'GET_SP_AREA_DIALOG_TITLE',
            'GET_SWITCH_ITEM',
            'GET_FILTER_DATE',
            'USERACCOUNT_LOAD',
        ]),

        actions(){
             if (this.ACTION == 'View'){

                this.getSqData()
                this.getSqItemData()
                this.viewSqItem()
                this.viewSq()
                return 'View'
            } else {
            }
        }
    },

    mounted() {
        this.getSqData()
        this.viewSqItem()
        this.getSqItemData()
        this.viewSq()
    },

    methods: {
        closeDialog() {
            this.$store.commit("DIALOG", false);
            // this.$store.commit('REFRESH_SP_AREA', true)
            this.$store.commit('SP_AREA_DIALOG_VIEW', {});
        },

        getSqData() {
            this.quoteNum                     = this.GET_SP_AREA_DIALOG_VIEW.quote_num;
            this.sqId                         = this.GET_SP_AREA_DIALOG_VIEW.sq_id;
            this.issuerName                   = this.GET_SP_AREA_DIALOG_VIEW.issuer_name;
            this.customerName                 = this.GET_SP_AREA_DIALOG_VIEW.customer_name;
            this.dateIssued                   = this.GET_SP_AREA_DIALOG_VIEW.created_at;
            this.remarks                      = this.GET_SP_AREA_DIALOG_VIEW.remarks;
            this.notes                        = this.GET_SP_AREA_DIALOG_VIEW.notes;
            this.totalAmount                  = this.GET_SP_AREA_DIALOG_VIEW.total_amount;
        },

        getSqItemData() {
            this.itemModel                    = this.GET_SP_AREA_DIALOG_VIEW.model;
            this.itemId                       = this.GET_SP_AREA_DIALOG_VIEW.item_id;
            this.itemName                     = this.GET_SP_AREA_DIALOG_VIEW.name;
            this.itemDescription              = this.GET_SP_AREA_DIALOG_VIEW.itemDescription;
            this.itemStocks                   = this.GET_SP_AREA_DIALOG_VIEW.stocks;
            this.itemIdealStocks              = this.GET_SP_AREA_DIALOG_VIEW.ideal_stocks;
            this.created_at                   = this.GET_SP_AREA_DIALOG_VIEW.created_at;
            this.salesId                      = this.GET_SP_AREA_DIALOG_VIEW.sales_quotation_id;
        },

        viewSqItem() {
            this.salesQuotationItems = [];
            this.loaded = true;
            this.$store.commit('REFRESH_SP_AREA', true)
            this.$store.dispatch('serviceGet',{
                url                     : this.GET_SP_AREA_DISPATCH.view[0],
                sqId                    : this.sqId,
            }).then(response => {
                this.salesQuotationItems    = response.data.data
                this.saleQuotationFiles = response.data.quotation_files;
                    if(!!this.salesQuotationItems) {
                        let sumWithStocks = 0;
                        let sumWithoutStocks = 0;
                        this.salesQuotationItems.forEach(e => {
                            sumWithStocks += e.amount_with_stocks
                            sumWithoutStocks += e.amount_without_stocks
                        })
                        this.totalAmountwithStocks      = sumWithStocks
                        this.totalAmountwithoutStocks   = sumWithoutStocks
                    }
                this.$store.commit('REFRESH_SP_AREA', false)
                this.loaded = false;
            }).catch( err =>{
                console.log(err)
                Swal.fire('Error!','- an error occured','error')
            });
        },

        viewSq() {
            this.salesQuotation = [];
            this.loaded = true;
            this.$store.commit('REFRESH_SP_AREA', true)
            this.$store.dispatch('serviceGet',{
                url                     : this.GET_SP_AREA_DISPATCH.view[1],
                item_id                 : this.itemId,
                sales_quotation_dates   : this.GET_FILTER_DATE,
            }).then(response => {
                this.salesQuotation    = response.data.data
                this.$store.commit('REFRESH_SP_AREA', false)
                this.loaded = false;
            }).catch( err =>{
                console.log(err)
                Swal.fire('Error!','- an error occured','error')
            });
        },

        exportToExcel(switchItem) {
            let workbook = new this.$exceljs.Workbook()
            let worksheet = workbook.addWorksheet('Spare Parts Staging Area')

            if (switchItem) {
                worksheet.columns = [
                    { header:'SPARE PART MODEL', key: 'sp_model', width: 20},
                    { header:'SPARE PART NAME', key: 'sp_name', width: 20},
                    { header:'SPARE PART DESCRIPTION', key: 'sp_desc', width: 20},
                    { header:'STOCKS', key: 'stocks', width: 10},
                    { header:'IDEAL STOCKS', key: 'ideal_stocks', width: 15},
                    { header:'QUOTE NUM', key: 'quote_num', width: 15 },
                    { header:'CUSTOMER', key: 'customer_name', width: 45 },
                    { header:'REMARKS', key: 'remarks', width: 20 },
                    { header:'AMOUNT', key: 'total_amount', width: 10 },
                    { header:'STATUS', key: 'status_text', width: 10 },
                ];
                let row = worksheet.getRow(1);
                row.font = { bold: true };
                for (let i=1;i<=worksheet.columns.length;i++)  {
                    row.getCell(i).fill = {
                        type: 'pattern',
                        pattern:'solid',
                        fgColor:{argb:'F69F1A'},
                    }
                }
                if (!!this.salesQuotation) {
                    let spQuotationDetails = JSON.parse(JSON.stringify(this.salesQuotation));
                    spQuotationDetails.map( e => {
                        Object.assign(e, {sp_model:this.itemModel, sp_name:this.itemName, sp_desc:this.itemDescription,  stocks:this.itemStocks, ideal_stocks:this.itemIdealStocks})
                    })
                    spQuotationDetails.forEach(record => {
                        worksheet.addRow(record)
                    });
                }
            } else {
                worksheet.columns = [
                        { header:'QUOTE NUM', key:'quote_num', width: 15},
                        { header:'CUSTOMER', key:'customer', width: 45},
                        { header:'MODEL', key: 'model', width: 25 },
                        { header:'NAME', key: 'name', width: 30 },
                        { header:'TYPE', key: 'type_name', width: 5 },
                        { header:'FOC', key: 'foc_text', width: 5 },
                        { header:'SRP', key: 'srp', width: 10 },
                        { header:'DISC PRICE', key: 'disc_price', width: 12 },
                        { header:'QTY', key: 'quantity', width: 5 },
                        { header:'UOM', key: 'uom_name', width: 5 },
                        { header:'PRICE', key: 'final_pricce', width: 10},
                        { header:'TOTAL', key: 'total_amount', width: 10 },
                        { header: 'REMARKS', key: 'remarks', width: 30},
                        { header: 'ISSUER', key: 'issuer', width: 20},
                        { header:'DATE ISSUED', key:'date_issued', width: 30},
                        { header: 'NOTES', key:'notes', width: 15}
                    ];

                let row = worksheet.getRow(1);
                row.font = { bold: true };
                for (let i=1;i<=worksheet.columns.length;i++)  {
                    row.getCell(i).fill = {
                        type: 'pattern',
                        pattern:'solid',
                        fgColor:{argb:'F69F1A'},
                    }
                }
                if (!!this.salesQuotationItems) {
                    let perQuotationDetails = JSON.parse(JSON.stringify(this.salesQuotationItems));
                    perQuotationDetails.map( e => {
                        Object.assign(e, {quote_num:this.quoteNum, customer:this.customerName, remarks:this.remarks,issuer:this.issuerName, date_issued: this.dateIssued, notes: this.notes});
                    })
                    perQuotationDetails.forEach(record => {
                        worksheet.addRow(record)
                    });
                }
            }
            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                this.$filesaver.saveAs(blob, 'Spare Parts Quotation Details.xlsx');
            })
        },

        printPDF(switchItem) {
            let doc = new this.$jspdf("p", "mm", "letter");

            let ingcoPhLogo = new Image();
            ingcoPhLogo.src = "/images/ingcoph-logo.png";
            doc.addImage(ingcoPhLogo, "png", 65, 12.7, 90, 20);
            doc.setFontSize(14);
            doc.text('Spare Parts Quotation Details', 215/2, 38, "center");

            doc.setFontSize(10);
            if (switchItem) {
                doc.text(`Parts: ${this.itemModel}`, 10, 52, "left");
                doc.text(`Item Description: ${this.itemDescription ?? ''}`, 10, 57, "left");
                doc.text(`Ideal Stocks: ${this.itemIdealStocks}`, 10, 62, "left");
                doc.text(`Item Name: ${this.itemName}`, 135, 52, "left");
                doc.text(`Stocks: ${this.itemStocks}`, 135, 57, "left");

                let tableColumns = this.sQHeaders.map( h => {
                    return {
                        header: h.text.toUpperCase(),
                        dataKey: h.value
                    }
                })

                doc.autoTable(
                    {
                        body: this.salesQuotation,
                        columns: tableColumns,
                        headStyles: {
                            fillColor: '#F69F1A',
                            textColor: "black",
                            textType: "bold",
                        },
                        margin: {left: 10, right: 10},
                        startY: 65,
                        styles: {
                            fontSize: 8,
                            lineWidth: 0.1,
                            lineColor: 'black',
                            overflow: 'hidden'
                        },
                        showHead: 'firstPage',
                        theme: "plain",
                    });
            } else {
                let fileLength = this.saleQuotationFiles.length > 4 ? Math.round(this.saleQuotationFiles.length / 5) : 1;
                doc.text(`Spare Parts Quotation No: ${this.quoteNum}`, 10, 52, "left");
                doc.text(`Customer: ${this.customerName}`, 10, 57, "left");
                doc.text(`Remarks: ${this.remarks}`, 10, 62, "left");
                doc.text(`Issuer: ${this.issuerName}`, 135, 52, "left");
                doc.text(`Date Issued: ${this.$dayjs(this.dateIssued).format('MMMM Do YYYY, h:mm:ss a')}`, 135, 57, "left");
                doc.text(`Notes: ${this.notes ?? ''}`, 135, 62, "left");

                doc.autoTable(
                    {
                        body: this.salesQuotationItems,
                        columns: [
                            { header: "MODEL", dataKey: "model" },
                            { header: "NAME", dataKey: "name" },
                            { header: "TYPE", dataKey: "type_name" },
                            { header: "FOC", dataKey: "foc_text" },
                            { header: "SRP", dataKey: "srp" },
                            { header: "DISC PRICE", dataKey: "disc_price" },
                            { header: "QTY", dataKey: "quantity" },
                            { header: "UOM", dataKey: "uom_name" },
                            { header: "PRICE", dataKey: "final_pricce" },
                            { header: "TOTAL", dataKey: "total_amount" },
                        ],
                        headStyles: {
                            fillColor: '#F69F1A',
                            textColor: "black",
                            textType: "bold",
                        },
                        margin: {left: 10, right: 10},
                        startY: 65,
                        styles: {
                            fontSize: 8,
                            lineWidth: 0.1,
                            lineColor: 'black',
                            overflow: 'hidden'
                        },
                        showHead: 'firstPage',
                        theme: "plain",
                });

                if(this.saleQuotationFiles.length > 0) {
                    // IMAGE CONTAINER
                    doc.setFillColor('white')
                    let x = 15;
                    let y = doc.lastAutoTable.finalY + 20;
                    this.saleQuotationFiles.forEach((e,i)=>{
                        let supporting_img = new Image();
                        supporting_img = `${process.env.VUE_APP_API_HOST}/img/service?image_name=${e.name}`
                        fetch(supporting_img)
                        .then(resp => resp.blob())
                        .then(blobobject => {
                            const blob = window.URL.createObjectURL(blobobject);
                            const anchor = document.createElement('a');
                            anchor.style.display = 'none';
                            anchor.href = blob;
                            document.body.appendChild(anchor);
                            window.URL.revokeObjectURL(blob);
                        })
                        let imgName = e.name;
                        let imageTypeArray = imgName.split('.');
                        let imageExt = imageTypeArray[1];
                        if (['jpeg', 'png'].includes(imageExt)) {
                            doc.addImage(supporting_img, imageExt, x, y,  35, 35 );
                        }
                        x += 37;
                        if(i == 4){
                        y= y + 40;
                        x=15;
                        }
                    })
                }
                doc.rect(10, doc.lastAutoTable.finalY + 15, 196, (45 * fileLength));

            }

            doc.autoPrint();
            window.open(doc.output('bloburl'), '_blank');
        },

        showDelete() {
            if(this.ACTION == 'View') {
                return true;
            }
            return false;
        },

        convertToSalesOrder() {
    //     if(this.sales_quotation.override_amount_approval == 1 &&  this.override.authenticated == false){
    //             this.override.dialog = true
    //             this.override.heading = 'Amount'
    //             return
    //         }
    //         this.loading = true

    //   if (this.sales_quotation.status == 0) {
        // this.errors = [];
        Swal.fire({
          title: "",
          text: "Are you sure you want to Convert this to SO?",
          icon: 'question',
          showConfirmButton:true,
        showCancelButton  :  true,
        reverseButtons:true,
        allowOutsideClick :  false,
        confirmButtonColor: '#397373',
        cancelButtonColor : 'grey',
        confirmButtonText:'Confirm',
        showCloseButton:true,
        }).then((willConvert) => {
          if (willConvert.isConfirmed) {
            this.$store.dispatch("convertToSalesOrder", this.sqId)
              .then((response) => {
                if(!!response.data.message){
                  Swal.fire(response.data.message,'','warning').then(ok=>{
                    this.loaded = false
                    return false;
                  })
                }
                window.Echo.channel('smod-channel').listen('ConvertToSOMessage',(e)=>{
                  let data = e.data.find(el => el.userID == this.USERACCOUNT_LOAD.id);
                  let check_unique_id = (e.data[0].unique_key.toString().replace(/"/g,'') == this.sqId)
                  if(check_unique_id && data && (data.message.msg || data.message.error) ){
                      if(data.message.error){
                          Swal.fire("", data.message.error, "warning");
                          this.loaded = false
                          return false;
                      }
                      this.closeDialog()
                      Swal.fire("", data.message.msg, "success");
                      this.loaded = false
                  }
                });
              }).catch(err=>{
                console.log(err)
                this.loaded = false
              });
          }
          else{
            this.loaded = false
          }
        });
    //   }
    },

    },

    watch: {
        DIALOGS:{
            handler(val){
                if(val){
                    this.actions
                }
            }
        },

        GET_SWITCH_ITEM: {
            handler(val) {
            }
        },
    },

    components: {
        BaseFilesViewerVue,
    },

    // sqAreaDetails() {
    // },
}
</script>

<style scoped>

</style>
