<template>
    <div class="w-100">
        <v-app id="my_vue_app">
            <v-card>
                <v-card-title>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" sm="8" class="d-flex justify-content-start"></v-col>
                            <v-col cols="12" md="4">
                                <div class="row col-md-12 align-items-end">
                                    <v-text-field
                                        id="searchBar"
                                        v-model="filter.search"
                                        label="Search by CEF"
                                        single-line
                                        hide-details
                                        @change="searchInput()">
                                    </v-text-field>
                                    <v-icon @click="searchInput()">mdi-magnify</v-icon>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-title>
                    <v-container fluid>
                        <v-data-table
                            :headers="headers"
                            :items="items"
                            :search="search"
                            :options.sync="options"
                            :server-items-length="totalItems"
                            :items-per-page="10"
                            :loading="!loaded"
                            :footer-props="{
                                itemsPerPageOptions: itemsPerPageOptions,
                            }"
                            loading-text="Loading... Please wait"
                            fixed-header
                        >
                            <template v-slot:[`item.total_cost`]="{item}">
                                <span>{{ item.total_cost | currency }}</span>
                            </template>
                                <template v-slot:[`item.status`]="{ item }">
                                    <a  
                                    class="ml-1 badge badge-primary text-white" 
                                    v-if="item.status == 1"
                                    >pending
                                    </a>
                                    <a  
                                    class="ml-1 badge badge-success text-white" 
                                    v-if="item.status == 2"
                                    >approved
                                    </a>
                                </template>
                                <template v-slot:[`item.action`]="{ item }">
                                    <v-btn text icon color="orange" v-if="userAccess.view">
                                        <v-icon 
                                        class="btn-action" 
                                        small 
                                        @click="ViewCEForm(item,'View')"
                                        >mdi-eye</v-icon>
                                    </v-btn>
                                    <v-btn text icon color="blue" 
                                        v-if="
                                        userAccess.edit && 
                                        item.status != 3 && 
                                        userAccess.edit && 
                                        item.status != 2
                                        "
                                    >
                                        <v-icon 
                                        class="btn-action" 
                                        small 
                                        @click="ViewCEForm(item,'Update')"
                                        >mdi-square-edit-outline</v-icon
                                        >
                                    </v-btn>
                                    <v-btn text icon color="red" 
                                        v-if="
                                        userAccess.delete && 
                                        item.status != 3  && 
                                        userAccess.edit && 
                                        item.status != 2
                                        "
                                    >
                                        <v-icon 
                                        class="btn-action" 
                                        small 
                                        @click="deleteCEF(item.id)"
                                        >mdi-delete</v-icon
                                        >
                                    </v-btn>
                                </template>
                        </v-data-table>
                    </v-container>
            </v-card>
        </v-app>
    </div>
</template>
<script>
import swal from "sweetalert2";
import { mapGetters } from 'vuex';

export default {
data() {
    return {
    loaded: false,
    totalItems: 0,
    options: {},
    items: [],
    itemsPerPageOptions: [5, 10, 15, 50, 100],
    itemsPerPage: 10,
    search: "",
    filter:{
        search:"",
    },
    awaitingSearch: false,
    headers: [
        { text: "CEF #", align: "left", value: "cef_number" },
        { text: "Owner", align: "left", value: "owner" },
        { text: "Project Name", align: "left", value: "project_name" },
        { text: "Contractor Name", align: "left", value: "vendor_name" },
        { text: "Total Cost", align: "left", value: "total_cost" },
        { text: "Prepared By", align: "left", value: "full_name" },
        { text: "Status", align: "left", value: "status" },
        { text: "Action", align: "center", value: "action",sortable:false},
    ],
    count:0,
    userAccess: {
        view: false,
        create: false,
        edit: false,
        delete: false,
    },
    };
},
computed:{
    ...mapGetters([
    "USER_ACCESS",
    "GET_CEF_DISPATCH",
    "GET_NEW_CEF_TABLE",
    "GET_CEF_DISPATCH_STATUS",
]),
},
mounted() {
    this.$store.dispatch(
    'checkAccessUser', 
    this.$router.currentRoute.params.id
    );
},
methods: {
    getAllCEF(filter = null) {
        this.items = [];
        this.loaded = false;
        let payload = {
            page:         this.options.page,
            itemsPerPage: this.options.itemsPerPage,
            search:       this.filter.search,
            sortBy:       this.options.sortBy,
            sortDesc:     this.options.sortDesc,
            status:       this.GET_CEF_DISPATCH_STATUS.tab_name
        };
        this.$store.dispatch(this.GET_CEF_DISPATCH.get, payload)
        .then((response) => {
                this.items =response.data.data;
                this.loaded = true;
                this.$store.commit("NEW_CEF_TABLE", false);
                this.totalItems = response.data.total;
            }).catch((e)=>{
                this.loaded = true;
        });
    },
    searchInput(){
    this.getAllCEF({ query: this.search });
    },  
    ViewCEForm(item, action) {
    this.$store.dispatch("getCEFItems", { id: item.id });
    this.$store.commit("EDIT_CEF", item);
    this.$store.commit("DIALOG", true);
    this.$store.commit("ACTION", action);
    },
    deleteCEF(id) {
    swal
        .fire({
        icon: 'question',
        text: "Are you sure you want to Delete?",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonColor: "#397373",
        cancelButtonColor: "#397373",
        })
        .then((result) => {
            if (result.isConfirmed) {
            this.$store
            .dispatch(this.GET_CEF_DISPATCH.delete, id)
            }
        });
    },
},
watch: {
    GET_NEW_CEF_TABLE:{
    handler(val){
        if(val){
        this.getAllCEF();
        }
    }
    },
    options: {
    handler() {
        this.getAllCEF();
    },
    deep: true,
    },  
    USER_ACCESS: {
    handler(val) {
        if (val != "fail") {
        this.userAccess.edit = false;
        this.userAccess.delete = false;
        this.userAccess.view = false;
        this.userAccess.create = false;
        val.forEach((e) => {
            if (e.actions_code == "edit") {
            this.userAccess.edit = true;
            } else if (e.actions_code == "delete") {
            this.userAccess.delete = true;
            } else if (e.actions_code == "view") {
            this.userAccess.view = true;
            } else if (e.actions_code == "create") {
            this.userAccess.create = true;
            }
        });
        }
    },
    },
},
};
</script>