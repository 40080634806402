<template>
    <v-card style="border-radius: 0px;" class="ma-0 pa-0 h-100" flat>
        <v-card-title>
            <v-row class="mx-2 mb-1 d-flex justify-content-center align-items-center">
                <v-col cols="4">
                    <v-img src="@/assets/ingcoph_logo/ingcoph_logo.jpg" :aspect-ratio="16/9" width="200px" contain></v-img>
                </v-col>
                <v-col class="d-flex justify-content-center align-items-center">
                    <h2>{{ !!url_meta.type_text ? `${url_meta.queueing_type} (${url_meta.type_text})` : `${url_meta.queueing_type}` }}</h2>
                </v-col>
                <v-col  cols="4" class="ma-0 pa-0 d-flex justify-content-center align-items-center">
                    <v-row>
                        <v-col>
                            <v-switch
                                v-model="toggleView"
                                inset
                                @change="toggleViewing()"
                            >
                                <template v-slot:label>
                                    <v-icon large :color="toggleView ? 'primary' : 'gray'">
                                        {{url_meta.toggle_icon}}
                                    </v-icon>
                                </template>
                            </v-switch>
                        </v-col>
                        <!-- <v-col v-if="url_meta.queueing_type != 'DISPATCH'"> -->
                        <v-col>
                            <v-switch
                                v-model="withCounter"
                                inset
                                @change="withCounterToggle()"
                            >
                                <template v-slot:label>
                                    <v-icon large :color="withCounter ? 'orange' : 'gray'">
                                        mdi-clipboard-list
                                    </v-icon>
                                </template>
                            </v-switch>
                        </v-col>
                    </v-row>
                    <v-dialog
                        ref="dialog"
                        v-model="modal"
                        :return-value.sync="date"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            class='ml-4'
                            outlined
                            dense
                            v-model="date"
                            label="Select Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="date"
                            scrollable
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="modal = false"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(date)"
                        >
                            OK
                        </v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-col>
            </v-row>
        </v-card-title>
        <v-row class="mx-2 mb-1 d-flex justify-content-end align-items-center">
            <v-col class="mr-2 text-left" cols="2" v-if="url_meta.queueing_type != 'DISPATCH'">
                <v-autocomplete
                    :items="view_options.selection"
                    v-model="view_options.selected"
                    outlined
                    dense
                    label="View Option"
                    class="ml-2"
                >
                </v-autocomplete>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="mr-2 text-right">
                <i><span>Last Updated: <b>{{ lastUpdated }}</b> </span></i>
            </v-col>
            </v-row>
        <v-card-text>
            <v-row v-if='toggleView' style="min-height: 86vh;">
                <v-col v-for="(item,key) in groupView" :key="key" :cols="checkColumnSize(key)" :style="checkIfHidden(key)">
                    <v-card elevation="5" class="ma-2 rounded-xl" height="100%" >
                        <v-row class="">
                            <v-col class="text-center mt-2" >
                                <h2>{{url_meta.statuses[key]}}</h2>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row class="ma-1 rounded-xl">
                            <v-col class="text-center ma-0 pa-0" cols="4" v-for="(x,i) in item" :key="i">
                                <v-divider class="ma-0 pa-0"></v-divider>
                                    <span style="font-size: 20px;">
                                        <b>{{ x.name }}</b>
                                    </span>
                                    <br>
                                    <span v-if="url_meta.queueing_type == 'DISPATCH'">{{ !!x.plate_number ? x.plate_number : 'N/A'}}</span>
                                    <v-divider class="ma-0 pa-0"></v-divider>
                                    <v-list class="toggle-list d-flex ma-0 pa-0">
                                        <v-list-item class="text-wrap" v-for="(y,s) in x" :key=s>
                                            <v-btn
                                                class="mx-0 pa-0"
                                                large
                                                text
                                                :color=url_meta.status_color[key]
                                                >
                                                <span class="text-xl-h4 font-weight-bold text-sm-h4">{{ y.display_id }}</span>
                                            </v-btn>
                                        </v-list-item>
                                    </v-list>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-else-if='withCounter && url_meta.queueing_type != "DISPATCH"' style="min-height: 86vh;">
                <v-col v-for="(item,key) in withCounterView" :key="key" :cols="checkColumnSize(key)" :style="checkIfHidden(key)">
                    <v-card  elevation="5" class="ma-2 rounded-xl" height="100%">
                        <v-row class="">
                            <v-col class="text-center mt-2" >
                                <h2>{{url_meta.statuses[key]}}</h2>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-list class="ul-listing">
                            <span v-for="(x,i) in item" :key=i style="column-rule: 4px groove orange;">
                                <span v-if="x.zero_status != 0" >
                                    <div v-for="(s,k) in x" :key="k">
                                        <v-list-item v-if="k == 0" >
                                            <v-tooltip right :color="url_meta.status_color[key]">
                                                <template v-slot:activator="{ on, attrs }" >
                                                    <v-btn
                                                        class="mx-0 pa-0"
                                                        text
                                                        color="black"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                            <span class="text-h4">
                                                                <span v-if="s.name.length < name_limit">{{ s.name }} </span>
                                                                <span v-else>{{ s.name | str_limit(name_limit) }} </span>
                                                                (
                                                                <span :style="`color: ${url_meta.status_color[key]};`">
                                                                    <b>{{ x.total_count - x.zero_status  }}</b>
                                                                </span>
                                                                /
                                                                <span :style="`color: ${url_meta.status_color[key]};`">
                                                                    <b>{{ x.total_count }}</b>
                                                                </span>
                                                                )
                                                            </span>
                                                    </v-btn>
                                                </template>
                                                <span>{{s.name}}</span>
                                            </v-tooltip>
                                        </v-list-item>
                                    </div>
                                </span>
                            </span>
                        </v-list>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-else-if='withCounter && url_meta.queueing_type == "DISPATCH"' style="min-height: 86vh;">
                <v-col v-for="(item,key) in withCounterView" :key="key" cols="6">
                    <v-card  elevation="5" class="ma-2 rounded-xl" height="100%">
                        <v-row class="">
                            <v-col class="text-center mt-2" >
                                <h2>{{url_meta.statuses[key]}}</h2>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-list class="ul-listing">
                            <span v-for="(x,i) in item" :key=i style="column-rule: 4px groove orange;">
                                <span>
                                    <div v-for="(s,k) in x" :key="k">
                                        <v-list-item v-if="k==0">
                                            <v-tooltip right :color="url_meta.status_color[key]">
                                                <template v-slot:activator="{ on, attrs }" >
                                                    <v-btn
                                                        class="mx-0 pa-0"
                                                        text
                                                        color="black"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                            <span class="text-h4">
                                                                <span v-if="s.company_name.length < name_limit">{{ s.company_name }} </span>
                                                                <span v-else>{{ s.company_name | str_limit(name_limit) }} </span>
                                                                (
                                                                <span :style="`color: ${url_meta.status_color[key]};`">
                                                                    <b>{{ x.total_count }}</b>
                                                                </span>
                                                                )
                                                            </span>
                                                    </v-btn>
                                                </template>
                                                <span>{{s.name}}</span>
                                            </v-tooltip>
                                        </v-list-item>
                                    </div>
                                </span>
                            </span>
                        </v-list>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-else-if="url_meta.queueing_type != 'DISPATCH' && !withCounter && !toggleView" style="min-height: 75vh;">
                <v-col v-for="(item,key) in items" :key="key" :cols="checkColumnSize(key)" :style="checkIfHidden(key)">
                    <v-card  elevation="5" class="ma-2 rounded-xl" height="100%">
                        <v-row class="">
                            <v-col class="text-center mt-2" >
                                <h2>{{url_meta.statuses[key]}}</h2>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-list :class="url_meta.queueing_type == 'DISPATCH' ? 'ul-listing small-spacing' : 'ul-listing large-spacing' " >
                            <span v-for="(x,i) in item" :key=i>
                                <v-list-item  class="text-wrap" v-if="url_meta.queueing_type != 'DISPATCH' && x.status == 0">
                                    <v-btn class="mx-0 pa-0"
                                        text
                                        x-large
                                        :color=url_meta.status_color[key]
                                    >
                                        <span class="text-xl-h3 font-weight-bold">{{x.display_id}}</span>
                                    </v-btn>
                                </v-list-item>
                                <v-list-item  class="text-wrap" v-else-if="url_meta.queueing_type == 'DISPATCH'">
                                    <v-btn class="mx-0 pa-0"
                                        text
                                        x-large
                                        :color=url_meta.status_color[key]
                                    >
                                        <span class="text-xl-h3 font-weight-bold">{{x.display_id}}</span>
                                    </v-btn>
                                </v-list-item>
                            </span>
                        </v-list>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-else-if="url_meta.queueing_type == 'DISPATCH' && !withCounter && !toggleView" style="min-height: 75vh;">
                <v-col v-for="(item,key) in groupViewDispatch" :key="key">
                    <v-card  elevation="5" class="ma-2 rounded-xl" height="100%">
                        <v-row class="">
                            <v-col class="text-center mt-2" >
                                <h2>{{url_meta.statuses[key]}}</h2>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row class="ma-2">
                            <v-col cols="4" v-for="(x,i) in item" :key=i class="pl-4" style="display: block;">
                                <v-list-item  class="text-left ml-0 pa-0 listItem">
                                    <v-list-item-content class="text-left ma-0 pa-0 listItem">
                                        <v-btn class="ma-0 pa-0"
                                            text
                                            x-large
                                            :color=url_meta.status_color[key]
                                            :class="buttonSizeClass"
                                        >
                                            <span :class="buttonSizeClass ">{{x.display_id}} ({{ !!x.num_of_box ? x.num_of_box : 0}})</span>
                                        </v-btn>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                        </v-row>
                        <!-- <v-list >
                            <span v-for="(x,i) in item" :key=i>
                                <v-list-item  class="text-wrap">
                                    <v-btn class="mx-0 pa-0"
                                        text
                                        x-large
                                        :color=url_meta.status_color[key]
                                    >
                                        <span class="text-xl-h3 font-weight-bold">{{x.display_id}} ({{ !!x.num_of_box ? x.num_of_box : 0}})</span>
                                    </v-btn>
                                </v-list-item>
                            </span>
                        </v-list> -->
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue'
import PusherFunctionComponent from '@/views/main/Shared/PusherFunctionComponent.vue';

export default {
    mixins: [SharedFunctionsComponentVue,PusherFunctionComponent],
    data(){
        return {
            items:[],
            modal:false,
            date:this.$dayjs().format('YYYY-MM-DD'),
            toggleView:false,
            url_meta:{
                status_color:this.$router.currentRoute.meta.status_color,
                statuses:this.$router.currentRoute.meta.statuses,
                queueing_type:this.$router.currentRoute.meta.title,
                url:this.$router.currentRoute.meta.url,
                realtime_event:this.$router.currentRoute.meta.realtime_event,
                toggle_icon:this.$router.currentRoute.meta.toggle_icon,
                type_id:this.$router.currentRoute.meta.type_id,
                type_text:this.$router.currentRoute.meta.type_text,
            },
            withCounter:true,
            groupView:[],
            withCounterView:[],
            groupViewDispatch:[],
            lastUpdated:this.$dayjs().format('YYYY-MM-DD HH:mm:ss'),
            view_options:{
                selected:2,
                selection:[
                    {text:'Both',value:2, title:'WPF-CF'},
                    {text:'WPF Only',value:1, title:'Warehouse Preparation Form'},
                    {text:'CF Only',value:0, title:'Checker Form'},
                ]
            },
            name_limit:35
        }
    },
    mounted(){
        this.callPusher();
        this.getAllItems();
        this.ServerPusher();
        this.checkUrlParams()
    },
    methods:{
        async getAllItems(){
            await this.$store.dispatch('getqueue',{
                url:this.url_meta.url,
                date:this.date,
                type_id:this.url_meta.type_id
            }).then(response=>{
                this.items = response.data
                this.dispatch_items = response.data
                this.convertToGroupView();
                this.convertToCustomerView();
                this.convertToDispatchGrouping();
            })
        },
        ServerPusher(){
            window.Echo.channel('ingco-channel').listen(this.url_meta.realtime_event,(e)=>{
                let id = e.data['data'][0].display_id
                let origin = e.data['origin']
                this.lastUpdated = e.data['last_updated'];
                let newVal = []
                if(this.url_meta.queueing_type == "DISPATCH"){
                    if(origin == 'save'){
                        this.items[0].push(e.data['data'][0])
                    }
                    else{
                        let find_display_id = {}
                        this.items.forEach((e,i)=>{
                            if(i == 0){
                                find_display_id = _.find(e,['display_id',id]);
                            }
                            let a = _.pullAllBy(e,[{'display_id':id}],'display_id');
                            newVal.push(a);
                            if(!!find_display_id){
                                this.items[1].push(find_display_id)
                            }
                        })
                        this.items = newVal
                    }
                }
                else{
                    let new_cf_object = e.data['data'][0]
                    if(origin == 'save'){
                        this.items[0].push(e.data['data'][0])
                    }
                    else{
                        let wp_list_id = e.data['data'][0].wp_list_id
                        this.items.forEach((e,i)=>{
                            let a = _.pullAllBy(e, [{'display_id':wp_list_id}], 'display_id');
                            newVal.push(a);
                        })
                        this.items = newVal
                        this.items[1].push(new_cf_object)
                    }
                }
                this.convertToCustomerView();
                this.convertToGroupView();
                this.convertToDispatchGrouping()
            })
        },
        convertToGroupView(){
            let group = []

            this.items.forEach(e=>{
                if(this.url_meta.queueing_type != 'DISPATCH') e = _.filter(e,{status:0})
                let a = _.groupBy(e,'group_id')
                group.push(a)
            })
            this.groupView = group
            this.groupView.forEach(e=>{
                for(const prop in e){
                    if(this.url_meta.queueing_type == 'DISPATCH'){
                        e[prop] = _.uniqBy(e[prop],'display_id')
                    }
                    Object.assign(e[prop],{plate_number:e[prop][0].plate_number,name:e[prop][0].name})
                }
            })
        },
        convertToCustomerView(){
            let groupType = ''
            if(this.url_meta.queueing_type != 'DISPATCH'){
                groupType = 'group_id'
            }
            else{
                groupType = 'company_name'
            }
            this.withCounterView = []
            this.withCounterView.push(_.groupBy(this.items[0],groupType))
            this.withCounterView.push(_.groupBy(this.items[1],groupType))
            this.withCounterView.forEach(e=>{
                for (const prop in e){
                    let a = _.filter(e[prop],{status:0})
                    let l = a.length
                    Object.assign(e[prop],{zero_status:l,total_count:e[prop].length})
                }
            })
        },
        convertToDispatchGrouping(){
            this.groupViewDispatch = []
            this.items.forEach(e=>{
                this.groupViewDispatch.push(_.uniqBy(e,'display_id'));
            })
        },
        checkIfHidden(key){
            if(key == this.view_options.selected && this.url_meta.queueing_type != "DISPATCH"){
                return 'display:none;';
            }
        },
        checkColumnSize(key){
            if(key == this.view_options.selected && this.url_meta.queueing_type != "DISPATCH"){
                return 12
            }
        },
        withCounterToggle(){
            if(this.toggleView){
                this.toggleView = false
            }
        },
        toggleViewing(){
            if(this.withCounter){
                this.withCounter = false
            }
        },
        checkUrlParams(){
            if(Object.keys(this.$route.query).length > 0){
                this.view_options.selected = Number(this.$route.query.view)
                this.withCounter = Number(this.$route.query.clipboard)
                let title = this.view_options.selection.find(e=> e.value == Number (this.$route.query.view))
                this.url_meta.queueing_type = `${this.$router.currentRoute.meta.title.toUpperCase()}`
            }
        }
    },
    computed: {
        buttonSizeClass() {
        // Determine the button size based on screen width
        if (this.$vuetify.breakpoint.width < 600) {
            return 'text-h3 font-weight-bold';
        } else if (this.$vuetify.breakpoint.width < 960) {
            return 'text-body-1 font-weight-bold';
        } else {
            return 'text-h3 font-weight-bold tex-left justify-content-start';
        }
        }
    },
    watch:{
        date:{
            handler(){
                this.getAllItems();
            }
        },
        withCounter:{
            handler(val){
                this.convertToGroupView();
                this.convertToCustomerView();
            }
        },
        toggleView:{
            handler(){
                this.convertToGroupView();
            }
        },
        view_options:{
            handler(val){
                if(val.selected == 2){
                    this.name_limit = 35;
                }
                else{
                    this.name_limit = 100
                }
            },
            deep:true
        }
    }
}
</script>

<style scoped>
.ul-listing {
    column-count: auto;
    column-gap: normal;
    column-fill: auto;
    height:77vh;
    overflow:auto
}
.large-spacing{
    column-width: 9.03rem;
}
.small-spacing{
    column-width: 8.5rem;
}
.toggle-list{
    max-height: 500px;
    column-count: auto;
    flex-flow: column wrap;
    list-style-type: disc;
    list-style-position: inside;
    overflow:auto
}
.listItem{
    display: inline-block;
    flex-direction: column;
    align-items: start;
    min-height: 0px !important;
    align-items: left !important;
    align-self:start !important
}
</style>
