<template>
    <v-container class="pa-0" fluid>
        <v-card class="rounded-0" flat>
            <v-card-title>
                <v-row class="mx-auto d-flex justify-space-between">
                    <v-spacer></v-spacer>
                    <v-col sm="12" md="12" lg="4" class="mx-1">
                        <v-text-field
                            clearable
                            single-line
                            v-model="search"
                            hide-details
                            append-icon="mdi-magnify"
                            label="Product# / Inbound/Outbound# / Business# / Client Name"
                            @keydown.enter="searchStoreFlows()"
                            @click:append="searchStoreFlows()"
                            @click:clear="search='';searchStoreFlows()"
                        ></v-text-field>

                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-divider class="mt-2"/>
                <v-data-table
                    :server-items-length="GET_STORE_FLOWS.total_count"
                    :footer-props="{
                        itemsPerPageOptions:itemPerPageOptions
                    }"
                    :options.sync="options"
                    :items-per-page="15"
                    :items="store_flows"
                    :loading="loading"
                    :headers="tableHeaders"
                    disable-sort
                    dense
                > 
                    <template v-slot:[`header.movement_date`]="{ header }">
                        <v-tooltip top color="#F69F1A">
                            <template v-slot:activator="{ on, attrs }">
                                <span
                                    v-bind="attrs"
                                    v-on="on"
                                    style="cursor:pointer"
                                >
                                    MOV. DATE
                                </span>
                            </template>
                            <span>MOVEMENT DATE</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`header.inbound_outbound_number`]="{ header }">
                        <v-tooltip top color="#F69F1A">
                            <template v-slot:activator="{ on, attrs }">
                                <span
                                    v-bind="attrs"
                                    v-on="on"
                                    style="cursor:pointer"
                                >
                                    I/O BOUND NO.
                                </span>
                            </template>
                            <span>INBOUND/OUTBOUND NO.</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`header.business_type`]="{ header }">
                        <v-tooltip top color="#F69F1A">
                            <template v-slot:activator="{ on, attrs }">
                                <span
                                    v-bind="attrs"
                                    v-on="on"
                                    style="cursor:pointer"
                                >
                                    B. TYPE
                                </span>
                            </template>
                            <span>BUSINESS TYPE</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`header.qty_before_movement`]="{ header }">
                        <v-tooltip top color="#F69F1A">
                            <template v-slot:activator="{ on, attrs }">
                                <span
                                    v-bind="attrs"
                                    v-on="on"
                                    style="cursor:pointer"
                                >
                                   QBM 
                                </span>
                            </template>
                            <span>QTY BEFORE MOVEMENT</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`header.qty_of_movement`]="{ header }">
                        <v-tooltip top color="#F69F1A">
                            <template v-slot:activator="{ on, attrs }">
                                <span
                                    v-bind="attrs"
                                    v-on="on"
                                    style="cursor:pointer"
                                >
                                   QOM 
                                </span>
                            </template>
                            <span>QTY OF MOVEMENT</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`header.qty_after_movement`]="{ header }">
                        <v-tooltip top color="#F69F1A">
                            <template v-slot:activator="{ on, attrs }">
                                <span
                                    v-bind="attrs"
                                    v-on="on"
                                    style="cursor:pointer"
                                >
                                   QAM 
                                </span>
                            </template>
                            <span>QTY AFTER MOVEMENT</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`header.client_name`]="{ header }">
                        <v-tooltip top color="#F69F1A">
                            <template v-slot:activator="{ on, attrs }">
                                <span
                                    v-bind="attrs"
                                    v-on="on"
                                    style="cursor:pointer"
                                >
                                   C. NAME 
                                </span>
                            </template>
                            <span>CLIENT NAME</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`item.image_path`]="{ item }">
                        <v-img class="img-thumbnail" width="50" height="50" :src="item.image_path == null?url+'/'+'images/items/no-image.png':url+'/'+item.image_path" eager contain></v-img>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        headers: {
            type: Array,
            default: ()=>[],
        }
    },
    data() {
        return {
            search: '',
            loading: false,
            options: {},
            page: 1,
            store_flows: [],
            itemPerPageOptions: [5, 10, 15, 50, 100],
            tableHeaders: [
                {text: 'IMAGE', align: 'start', value: 'image_path'},
                { text: 'MOVEMENT DATE', align: 'start', value: 'movement_date', width:'7%'},
                { text: 'STORE NAME', align: 'start', value: 'store_name', width: '7%' },
                { text: 'PRODUCT NO.', align: 'start', value: 'product_number' },
                { text: 'PRODUCT NAME', align: 'start', value: 'product_name', width: '8%'},
                { text: 'BRAND', align: 'start', value: 'brand' },
                { text: 'UNIT', align: 'start', value: 'unit' },
                { text: 'INBOUND/OUTBOUND NO.', align: 'start', value: 'inbound_outbound_number', width: '8%' },
                { text: 'BUSINESS NO.', align: 'start', value: 'business_number'},
                { text: 'BUSINESS TYPE', align: 'start', value: 'business_type', width:'8%'},
                { text: 'UOM', align: 'start', value: 'uom' },
                { text: 'QTY BEFORE MOVEMENT', align: 'start', value: 'qty_before_movement' },
                { text: 'QTY OF MOVEMENT', align: 'start', value: 'qty_of_movement' },
                { text: 'QTY AFTER MOVEMENT', align: 'start', value: 'qty_after_movement' },
                { text: 'CLIENT NAME', align: 'start', value: 'client_name' },
                { text: 'REMARKS', align: 'start', value: 'remarks' },
            ],
            url:process.env.VUE_APP_API_HOST,
        }
    },
    computed: {
        ...mapGetters([
            'GET_STORE_FLOWS',
            'GET_RELOAD',
            'GET_FILTER_DIALOG',
        ]),
    },
    methods: {
        getStoreFlows() {
            this.store_flows = [];
            this.loading = true;
            let payload = {
                page: this.options.page,
                itemPerPage: this.options.itemsPerPage,
                search: !!this.search ? this.search.trim() : '',
            }
            this.$store.dispatch('getAllStoreFlows', payload).then(res => {
                this.store_flows = this.GET_STORE_FLOWS.store_flows.data;
                this.loading = false;
            }).catch(e => {
                console.log(e);
                this.loading = false;
            })
        },
        searchStoreFlows() {
            this.options.page = 1;
            this.getStoreFlows(); 
        },
        
    },
    watch:{
        'options': {
            handler(val) {
                this.getStoreFlows();
            }
        },
        'GET_RELOAD': {
            handler(val) {
                if (val == true) {
                    this.getStoreFlows();
                    this.$store.commit('RELOAD', false);
                }
            }
        }
    },
    mounted() {
        this.$store.dispatch('getAllStoreFlows');
    }

}
</script>

<style>

</style>