<template>
    <div class="mb-2">
        <div>
            <div>
                Assign Technician:
                <!-- <v-btn x-small @click="getTechnianRfs()">Refresh</v-btn> -->
                <v-tooltip bottom color="#f69f1a">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            @click="getTechnianRfs()"
                            fab
                            text
                            small
                            class="mr-4"
                            color="#f69f1a"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon>mdi-sync</v-icon>
                        </v-btn>

                    </template>
                    <span>Refresh</span>
                </v-tooltip>
            </div>
            <v-data-table
                :headers="headers"
                :items="technicianRfs"
                :items-per-page="technicianRfs.length"
                :loading="loading"
            >
                <template v-slot:[`item.view`]="{ item }">
                    <td>
                        <v-btn text icon color="orange">
                            <v-icon class="btn-action" small @click="showTechnician(item)">fas fa-eye</v-icon>
                        </v-btn>
                    </td>
                </template>

                <template v-slot:[`item.specialties`]="{ item }">
                    <td>{{ getSpecialties(item) }}</td>
                </template>

                <template v-slot:[`item.action`]="{ item }">
                    <!-- <v-btn small @click="assignTechnician(item)">Assign</v-btn> -->
                    <v-tooltip bottom color="#f69f1a">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                @click="assignTechnician(item)"
                                fab
                                text
                                small
                                class="mr-4"
                                color="#f69f1a"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon>mdi-account-wrench</v-icon>
                            </v-btn>
                        </template>
                        <span>Assign Technician</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </div>

        <technician-profile-dialog :id="techId" ref="techDialog" @closeTechProfile="closeTechProfile"/>
    </div>
</template>
<script>
import TechnicianProfileDialog from '../Services/Views/TechnicianManagerComponent/TechnicianProfileDialogComponent.vue';
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
export default {
    data() {
        return {
            technicians: [],
            selectedTechnician: '',
            name: '',
            specialties: '',
            skills: '',
            technicianRfs: [],
            loading: true,
            headers: [
                { text: 'Profile', value: 'view' },
                { text: 'Name', value: 'technician_name' },
                { text: 'Level', value: 'level' },
                { text: 'Specialties', value: 'specialties' },
                { text: 'Skills', value: 'skills' },
                { text: 'UAS', value: 'ure_count' },
                { text: 'PIN', value: 'pin_count' },
                { text: 'RET', value: 'ret_count' },
                { text: 'Total', value: 'total_count' },
                { text: 'Assign', value: 'action' },
            ],
            techId: '',
        }
    },
    components: {
        TechnicianProfileDialog
    },
    props: ['rfId','itemId', 'closeRfDetailsPlainDialog'],
    async mounted() {
        await this.getAllTechnicians();
        // await this.getTechnianRfs();
    },
    computed:{
        ...mapGetters([
            'GET_TECHNIAN_RFS',
            'GET_ALL_TECHNICIANS'
        ])
    },
    watch: {
        rfId() {
            if(!!this.rfId) {
                this.getTechnianRfs();
            }
        },
        closeRfDetailsDialog() {
            if(this.closeRfDetailsDialog) {
                this.selectedTechnician = "";
            }
        },
        selectedTechnician() {
            if(!!this.selectedTechnician) {
                let tech = this.technicians.find( x => x.id == this.selectedTechnician);

                this.name = tech.name;
                this.specialties = !!tech.specialties ? JSON.parse(tech.specialties).toString().replaceAll(',', ', ') : '';
                this.skills = tech.skills;
            }
        }
    },
    methods: {
        clearFields() {
            this.name = '';
            this.specialties = '';
            this.skills = '';
            this.selectedTechnician = "";
        },
        getTechnianRfs() {
            this.technicianRfs = [];
            this.loading = true;
            let payload = {
                itemId: this.itemId
            }

            this.$store.dispatch('getTechnianRfs',payload).then(response=>{
                this.technicianRfs = this.GET_TECHNIAN_RFS;
                this.loading = false;
            });
        },
        getAllTechnicians() {


            this.$store.dispatch('getAllTechnicians').then((response)=> {
                this.technicians = this.GET_ALL_TECHNICIANS;
            })
        },
        getSpecialties(item) {
            if(!!item) {
                return JSON.parse(item.specialties).toLocaleString().replaceAll(',', ', ');
            }

            return '';
        },
        assignTechnician(tech) {
            swal.fire({
                text: `Are you sure you want to assign technician "${tech.technician_name}"`,
                icon: 'warning',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then((result)=> {
                if(result.isConfirmed) {
                    let payload = {
                        technicianId: tech.id,
                        rfId: this.rfId
                    }
                    this.$store.dispatch('assignTechnician',payload).then((response)=> {
                        if(response.data.msg) {
                            swal.fire("", response.data.msg, "success");
                            this.$emit('successAssignTech');
                            this.clearFields();
                        }
                    }).catch((e)=> {
                        console.log(e);
                    });
                }
            });
        },
        showTechnician(item) {
            this.techId = item.id;
            this.$refs.techDialog.showTechProfileDialog();
        },
        closeTechProfile(confirm) {
            if(confirm) {
                this.techId = '';
            }
        },
    }
}
</script>

<style>

</style>
