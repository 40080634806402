<template>
    <div class="w-100">
        <v-card>
            <v-card-text class="py-2">
                <v-container fluid class="py-2">
                    <v-row>
                        <v-col cols="12" class="d-flex justify-start" style="gap: 20px;">
                            <v-switch
                                v-model="showAllCards"
                                :true-value="1"
                                :false-value="0"
                                color="orange"
                                label="Show Cards"
                                hide-details
                            ></v-switch>
                            <v-switch
                                v-model="showAllWithBalance"
                                :true-value="1"
                                :false-value="0"
                                class="pl-3"
                                color="orange"
                                label="w/ Balance"
                                hide-details
                                @click="getPeriods()"
                            ></v-switch>
                            <v-switch
                                v-model="showAffiliateOnly"
                                :true-value="1"
                                :false-value="0"
                                class="pl-3"
                                color="orange"
                                label="Affiliate Only"
                                hide-details
                                @click="getPeriods()"
                            ></v-switch>
                            <v-switch
                                v-model="showAllForReceiving"
                                :true-value="1"
                                :false-value="0"
                                class="pl-1"
                                color="orange"
                                label="For Receiving"
                                hide-details
                                @click="getPeriods()"
                            ></v-switch>
                            <v-switch
                                v-model="showAllForConfirm"
                                :true-value="1"
                                :false-value="0"
                                class="pl-1"
                                color="orange"
                                label="For Confirm Payment"
                                hide-details
                                @click="getPeriods()"
                            ></v-switch>
                            <v-switch
                                v-model="showAllWithBadDebt"
                                :true-value="1"
                                :false-value="0"
                                class="pl-1"
                                color="orange"
                                label="w/ Bad Debt"
                                hide-details
                                @click="getPeriods()"
                            ></v-switch>
                            <v-switch
                                v-model="showAllDueAccount"
                                :true-value="1"
                                :false-value="0"
                                class="pl-1"
                                color="orange"
                                label="Due Account"
                                hide-details
                                @click="getPeriods()"
                            ></v-switch>
                            <v-switch
                                v-model="showAllOverdue"
                                :true-value="1"
                                :false-value="0"
                                class="pl-1"
                                color="orange"
                                label="w/ Overdue"
                                hide-details
                                @click="getPeriods()"
                            ></v-switch>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6" md="4" lg="3" xl="3">
                            <v-card class="total-cards" elevation="2">
                                <v-card-text class="d-flex justify-content-start align-items-center">
                                    <div class="card-icon pt-2 pb-2 pl-1 pr-1 rounded bg-success">
                                        <v-icon class="text-white" style="font-size:30px">mdi-cash-multiple</v-icon>
                                    </div>
                                    <div class="card-details ml-2">
                                        <p class="mb-3">Total Collectibles</p>
                                        <b style="color:black">&#8369;{{ soa_card_details.uncollectibles | currency }}</b>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3" xl="3">
                            <v-card class="total-cards" elevation="2">
                                <v-card-text class="d-flex justify-content-start align-items-center">
                                    <div class="card-icon pt-2 pb-2 pl-1 pr-1 rounded bg-danger">
                                        <v-icon class="text-white" style="font-size:30px">mdi-cash-clock</v-icon>
                                    </div>
                                    <div class="card-details ml-2">
                                        <p class="mb-3">Total Overdue</p>
                                        <b style="color:black">&#8369; {{ soa_card_details.total_overdue | currency }}</b>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3" xl="3">
                            <v-card class="total-cards" elevation="2">
                                <v-card-text class="d-flex justify-content-start align-items-center">
                                    <div class="card-icon pt-2 pb-2 pl-1 pr-1 rounded bg-danger">
                                        <v-icon class="text-white" style="font-size:30px">mdi-cash-off</v-icon>
                                    </div>
                                    <div class="card-details ml-2">
                                        <p class="mb-3">Total Bad Debt</p>
                                        <b style="color:black">&#8369; {{ soa_card_details.total_bad_debt | currency }}</b>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col v-if="showAllCards" cols="12" sm="6" md="4" lg="3" xl="3">
                            <v-card class="total-cards" elevation="2">
                                <v-card-text class="d-flex justify-content-start align-items-center">
                                    <div class="card-icon pt-2 pb-2 pl-1 pr-1 rounded bg-success">
                                        <v-icon class="text-white" style="font-size:30px">mdi-checkbook</v-icon>
                                    </div>
                                    <div class="card-details ml-2">
                                        <p class="mb-3">PDC | Total Amount</p>
                                        <b style="color:black">{{ soa_card_details.pdc.count }} | &#8369; {{ soa_card_details.pdc.total_net_amount | currency }}</b>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col v-if="showAllCards" cols="12" sm="6" md="4" lg="3" xl="3">
                            <v-card class="total-cards" elevation="2">
                                <v-card-text class="d-flex justify-content-start align-items-center">
                                    <div class="card-icon pt-2 pb-2 pl-1 pr-1 rounded bg-success">
                                        <v-icon class="text-white" style="font-size:30px">mdi-currency-php</v-icon>
                                    </div>
                                    <div class="card-details ml-2">
                                        <p class="mb-3">Cheque Dated Today</p>
                                        <b style="color:black">&#8369; {{ soa_card_details.dated_cheque | currency }}</b>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col v-if="showAllCards" cols="12" sm="6" md="4" lg="3" xl="3">
                            <v-card class="total-cards" elevation="2">
                                <v-card-text class="d-flex justify-content-start align-items-center">
                                    <div class="card-icon pt-2 pb-2 pl-1 pr-1 rounded bg-success">
                                        <v-icon class="text-white" style="font-size:30px">mdi-checkbook</v-icon>
                                    </div>
                                    <div class="card-details ml-2">
                                        <p class="mb-3">Dated Cheque Week# {{ weekToday }}</p>
                                        <b style="color:black">{{ soa_card_details.week.count }} | &#8369; {{ soa_card_details.week.total_net_amount | currency }}</b>
                                        <v-btn @click="goToChequeDepositReport()" text x-small class="float-right">
                                            <v-icon>mdi-arrow-right</v-icon>
                                        </v-btn>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col v-if="showAllCards" cols="12" sm="6" md="4" lg="3" xl="3">
                            <v-card class="total-cards" elevation="2">
                                <v-card-text class="d-flex justify-content-start align-items-center">
                                    <div class="card-icon pt-2 pb-2 pl-1 pr-1 rounded bg-warning">
                                        <v-icon class="text-white" style="font-size:30px">mdi-cash-sync</v-icon>
                                    </div>
                                    <div class="card-details ml-2">
                                        <p class="mb-3">Cash terms with Balance</p>
                                        <b style="color:black">&#8369; {{ soa_card_details.cash_terms_with_balance | currency }}</b>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-divider></v-divider>

                    <v-row class="p-2">
                        <v-col cols="2">
                            <v-autocomplete
                                v-model="selectedCustomer"
                                :items="GET_CUSTOMERS_LIST_SELECTION"
                                label="Select Customer"
                                dense
                                outlined
                                clearable
                                auto-select-first
                            />
                        </v-col>
                        <v-col cols="2">
                            <v-autocomplete
                                v-model="selectedSalesman"
                                :items="GET_SALESMAN_SELECTION"
                                label="Salesman"
                                dense
                                outlined
                                clearable
                                auto-select-first
                            />
                        </v-col>
                        <v-col cols="2">
                            <v-autocomplete
                                v-model="selectedUser"
                                :items="GET_ACCOUNTING_USERS"
                                label="Prepared By"
                                dense
                                outlined
                                clearable
                                auto-select-first
                            />
                        </v-col>
                        <v-col cols="2">
                            <v-autocomplete
                                v-model="selectedAccountingUser"
                                :items="GET_ACCOUNTING_USERS"
                                label="Accounting User"
                                dense
                                outlined
                                clearable
                                auto-select-first
                            />
                        </v-col>
                        <v-col cols="2">
                            <v-autocomplete
                                v-model="selectedCustomerCategory"
                                :items="GET_CUSTOMER_CATEGORY_SELECTION"
                                label="Select Customer Category"
                                background-color="white"
                                dense
                                outlined
                                clearable
                                auto-select-first
                            />
                        </v-col>
                        <v-col cols="2">
                            <v-autocomplete
                                v-model="selectedCustomerClassification"
                                :items="GET_CLASSIFICATIONS_SELECTION"
                                label="Select Customer Classification"
                                background-color="white"
                                dense
                                outlined
                                clearable
                                auto-select-first
                            />
                        </v-col>
                        <v-col cols="2">
                            <v-autocomplete
                                v-model="selectedCluster"
                                :items="GET_CLUSTER_SELECTION"
                                item-value="value"
                                item-text="text"
                                label="Cluster"
                                dense
                                outlined
                                clearable
                                auto-select-first
                            />
                        </v-col>
                        <v-col cols="2">
                            <v-autocomplete
                                v-model="selectedSubCluster"
                                :items="GET_SUB_CLUSTER_SELECTION"
                                item-value="value"
                                item-text="text"
                                label="Sub Cluster"
                                dense
                                outlined
                                clearable
                                multiple
                                small-chips
                                deletable-chips
                            />
                        </v-col>
                        <v-col cols="2">
                            <v-autocomplete
                                v-model="selectedMotherCustomer"
                                :items="GET_AFFLIATE_CUSTOMER"
                                label="Select Mother Customer"
                                background-color="white"
                                dense
                                outlined
                                hide-details
                                clearable
                                auto-select-first
                            />
                        </v-col>
                        <v-col cols="2">
                            <v-autocomplete
                                v-model="selectedPayment"
                                :items="GET_ALL_PAYMENT_TYPES"
                                item-value="id"
                                item-text="name"
                                label="Payment Type"
                                dense
                                outlined
                                clearable
                                auto-select-first
                            />
                        </v-col>
                        <v-col cols="2">
                            <v-autocomplete
                                v-model="selectedBank"
                                :items="GET_BANK_SELECTION"
                                :disabled="![2, 3].includes(selectedPayment)"
                                item-value="id"
                                item-text="name"
                                placeholder="Select Bank"
                                dense
                                outlined
                                clearable
                                auto-select-first
                            />
                        </v-col>
                        <v-col cols="2" v-if="selectedPayment == 2">
                            <v-text-field
                                v-model="chequeNumber"
                                :disabled="!selectedBank"
                                placeholder="Cheque Number"
                                dense
                                outlined
                                clearable
                            />
                        </v-col>
                        <v-col cols="2" v-if="selectedPayment == 3">
                            <v-text-field
                                v-model="accountNumber"
                                :disabled="!selectedBank"
                                placeholder="Account Number"
                                dense
                                outlined
                                clearable
                            />
                        </v-col>
                        <v-col v-if="showFilter" cols="1" class="ml-3 d-flex justify-start" style="gap: 10px;">
                            <v-btn @click="getPeriods()">Filter</v-btn>
                        </v-col>
                    </v-row>
                    <v-row class="p-2">
                        <v-spacer />
                        <v-col cols="1" class="d-flex justify-end" style="gap: 10px;">
                            <v-btn @click="openPrintSummaryDialog()">Print Summary</v-btn>
                            <v-btn @click="exportPerCustomer()">Export</v-btn>
                            <v-btn v-if="!showFilter" @click="getPeriods()">Filter</v-btn>
                        </v-col>
                    </v-row>
                    <v-row class="p-2">
                        <v-col v-if="showAllForReceiving == 1 && userAccess.receive" cols="1" class="d-flex justify-start" style="gap: 10px;">
                            <v-btn @click="updateMassReceived()" :disabled="selected == 0">Mass Receive</v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="2">
                            <v-text-field
                                v-model="search"
                                type="number"
                                label="SoaID#"
                                append-icon="mdi-magnify"
                                single-line
                                hide-details
                                clearable
                                @click:append-icon="getPeriods()"
                                @keydown.enter="getPeriods()"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>

                <v-divider class="ma-0"></v-divider>

                <v-container fluid>
                    <v-data-table
                        :headers="period.headers"
                        :items="period.items"
                        :loading="period.loading"
                        :expanded-sync="period.expanded"
                        :items-per-page="12"
                        item-key="period"
                        disable-sort
                        disable-pagination
                        hide-default-footer
                        show-expand
                        single-expand
                        dense
                    >
                        <template v-slot:[`item.total_gross_amount`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.total_gross_amount | currency }}
                            </span>
                        </template>
                        <template v-slot:[`item.total_net_amount`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.total_net_amount | currency }}
                            </span>
                        </template>
                        <template v-slot:[`item.total_deduction_amount`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.total_deduction_amount | currency }}
                            </span>
                        </template>
                        <template v-slot:[`item.total_addition_amount`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.total_addition_amount | currency }}
                            </span>
                        </template>
                        <template v-slot:[`item.total_balance_amount`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.total_balance_amount | currency }}
                            </span>
                        </template>
                        <template v-slot:[`item.total_paid_amount`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.total_paid_amount | currency }}
                            </span>
                        </template>
                        <template v-slot:[`item.total_bad_debt_amount`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.total_bad_debt_amount | currency }}
                            </span>
                        </template>
                        <template v-slot:[`item.period`]="{ item }">
                            <span class="text-nowrap">
                                {{ item.period | formatMonthSoa }}
                            </span>
                        </template>
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                                <v-data-table
                                    :headers="customers_table.headers"
                                    :items="item.soas"
                                    :items-per-page="10"
                                    :loading="customers_table.loading"
                                    :expanded-sync="customers_table.expanded"
                                    item-key="id"
                                    class="w-100"
                                    disable-sort
                                    disable-pagination
                                    hide-default-footer
                                    dense
                                    show-expand
                                    single-expand
                                    @item-expanded="loadDetails"
                                >
                                    <template v-slot:[`item.addition_amount`]="{ item }">
                                        <span class="text-nowrap">
                                            &#x20b1;{{ item.cust_total_addition_amount | currency }}
                                        </span>
                                    </template>
                                    <template v-slot:[`item.deduction_amount`]="{ item }">
                                        <span class="text-nowrap">
                                            &#x20b1;{{ item.cust_total_deduction_amount | currency }}
                                        </span>
                                    </template>
                                    <template v-slot:[`item.gross_amount`]="{ item }">
                                        <span class="text-nowrap">
                                            &#x20b1;{{ item.cust_total_gross_amount | currency }}
                                        </span>
                                    </template>
                                    <template v-slot:[`item.total_amount`]="{ item }">
                                        <span class="text-nowrap">
                                            &#x20b1;{{ item.cust_total_net_amount | currency }}
                                        </span>
                                    </template>
                                    <template v-slot:[`item.paid_amount`]="{ item }">
                                        <span class="text-nowrap">
                                            &#x20b1;{{ item.cust_total_paid_amount | currency }}
                                        </span>
                                    </template>
                                    <template v-slot:[`item.balance_amount`]="{ item }">
                                        <span class="text-nowrap">
                                            &#x20b1;{{ item.cust_total_balance_amount | currency }}
                                        </span>
                                    </template>
                                    <template v-slot:[`item.bad_debt_amount`]="{ item }">
                                        <span class="text-nowrap">
                                            &#x20b1;{{ item.cust_total_bad_debt_amount | currency }}
                                        </span>
                                    </template>
                                    <template v-slot:expanded-item="{ headers }">
                                        <td :colspan="headers.length">
                                            <v-data-table
                                                v-model="selected"
                                                :headers="main_table.headers"
                                                :items="statementOfAccounts"
                                                :search="search"
                                                :items-per-page="10"
                                                :server-items-length="totalStatementOfAccounts"
                                                :options.sync="options"
                                                :loading="loading"
                                                :show-select="showAllForReceiving == 1"
                                                class="w-100"
                                                disable-sort
                                            >
                                                <template v-slot:[`item.customer_name`]="{ item }">
                                                    <span style="font-size:10px !important;">
                                                        {{ item.customer_name }}
                                                    </span>
                                                </template>
                                                <template v-slot:[`item.terms`]="{ item }">
                                                    <span class="text-nowrap" v-if="item.terms == 0">
                                                        {{ 'CASH' }}
                                                    </span>
                                                    <span class="text-nowrap" v-else>
                                                        {{ item.terms }}
                                                    </span>
                                                </template>
                                                <template v-slot:[`item.gross_amount`]="{ item }">
                                                    <span class="text-nowrap">
                                                        &#x20b1;{{ item.gross_amount | currency }}
                                                    </span>
                                                </template>
                                                <template v-slot:[`item.discount_amount`]="{ item }">
                                                    <span class="text-nowrap">
                                                        &#x20b1;{{ item.discount_amount | currency }}
                                                    </span>
                                                </template>
                                                <template v-slot:[`item.total_amount`]="{ item }">
                                                    <span class="text-nowrap">
                                                        &#x20b1;{{ item.total_amount | currency }}
                                                    </span>
                                                </template>
                                                <template v-slot:[`item.paid_amount`]="{ item }">
                                                    <span class="text-nowrap">
                                                        &#x20b1;{{ item.paid_amount | currency }}
                                                    </span>
                                                </template>
                                                <template v-slot:[`item.balance_amount`]="{ item }">
                                                    <span class="text-nowrap">
                                                        &#x20b1;{{ item.balance_amount | currency }}
                                                    </span>
                                                </template>
                                                <template v-slot:[`item.addition_amount`]="{ item }">
                                                    <span class="text-nowrap">
                                                        &#x20b1;{{ item.addition_amount | currency }}
                                                    </span>
                                                </template>
                                                <template v-slot:[`item.deduction_amount`]="{ item }">
                                                    <span class="text-nowrap">
                                                        &#x20b1;{{ item.deduction_amount | currency }}
                                                    </span>
                                                </template>
                                                <template v-slot:[`item.bad_debt_amount`]="{ item }">
                                                    <span class="text-nowrap">
                                                        &#x20b1;{{ item.bad_debt_amount | currency }}
                                                    </span>
                                                </template>
                                                <template v-if="(filtered_month == 0)" v-slot:[`item.selected_month`]="{ item }">
                                                    <span class="text-nowrap">
                                                        {{ soa_month(item.selected_month, item.selected_year) | formatMonthSoa }}
                                                    </span>
                                                </template>
                                                <template v-slot:[`item.created_at`]="{ item }">
                                                    <span class="text-nowrap">
                                                        {{ item.created_at | formatDateTime }}
                                                    </span>
                                                </template>
                                                <template v-slot:[`item.due_date`]="{ item }">
                                                    <span class="text-nowrap">
                                                        {{ item.due_date | formatMonthSoa }}
                                                    </span>
                                                </template>
                                                <template v-slot:[`item.age`]="{ item }">
                                                    <span class="text-nowrap">
                                                        {{ agingDate(item.due_date) }}
                                                    </span>
                                                </template>
                                                <template v-slot:[`item.status`]="{ item }">
                                                    <span v-if="item.status == 0" class="badge white--text bg-secondary">saved</span>
                                                    <span v-if="item.status == 2" class="badge white--text bg-danger">rejected</span>
                                                    <span v-if="item.status == 5" class="badge white--text bg-primary">confirmed</span>
                                                    <span v-else-if="item.status == 6" class="badge bg-warning">partial</span>
                                                    <span v-else-if="item.status == 7" class="badge white--text bg-success">paid</span>
                                                    <span v-else-if="item.status == 8" class="badge white--text bg-danger">overdue</span>
                                                    <span v-else-if="item.status == 9" class="badge bg-warning">partial</span>
                                                    <span v-else-if="item.status == 11" class="badge bg-info">for confirmation</span>
                                                    <span v-else-if="item.status == 12" class="badge bg-dark">replaced</span>
                                                </template>
                                                <template v-slot:[`item.payment_methods`]="{ item }">
                                                    <span v-if="checkIfCombi(item.payment_methods).length > 1">
                                                        <span class="badge badge-info">combi</span>
                                                    </span>
                                                    <span v-else>
                                                        <span class="badge badge-success"
                                                            v-if="checkIfCombi(item.payment_methods) == '1'">cash</span>
                                                        <span class="badge badge-purple"
                                                            v-else-if="checkIfCombi(item.payment_methods) == '2'">cheque</span>
                                                        <span class="badge badge-warning"
                                                            v-else-if="checkIfCombi(item.payment_methods) == '3'">deposit</span>
                                                        <span class="badge badge-primary"
                                                            v-else-if="checkIfCombi(item.payment_methods) == '4'">online</span>
                                                        <span class="badge badge-secondary"
                                                            v-else-if="checkIfCombi(item.payment_methods) == '5'">credit/debit</span>
                                                        <span v-else-if="checkIfCombi(item.payment_methods) == ''"></span>
                                                    </span>
                                                </template>
                                                <template v-slot:[`item.action`]="{ item }">
                                                    <span class="text-nowrap">
                                                        <v-btn text icon color="orange" v-if="userAccess.view">
                                                            <v-icon class="btn-action" small @click="viewStatementOfAccount(item.id)">mdi-eye</v-icon>
                                                        </v-btn>
                                                        <v-btn text icon color="blue" v-if="showEditButton(item) && item.status != 7">
                                                            <v-icon class="btn-action" small @click="editStatementOfAccount(item.id, item.balance_amount, item.status)">mdi-pencil</v-icon>
                                                        </v-btn>
                                                        <v-btn text icon color="red" v-if="userAccess.delete && item.status != 7">
                                                            <v-icon class="btn-action" small @click="deleteStatementOfAccount(item.id, item)">mdi-delete</v-icon>
                                                        </v-btn>
                                                    </span>
                                                </template>
                                            </v-data-table>
                                        </td>
                                    </template>
                                </v-data-table>
                            </td>
                        </template>
                    </v-data-table>
                </v-container>
            </v-card-text>
        </v-card>
        <!-- </v-app> -->

        <!-- VIEW MODAL-->
        <StatementOfAccountsViewComponentVue :viewStatementOfAccountId="viewStatementOfAccountId" @closeView="closeView"></StatementOfAccountsViewComponentVue>

        <v-dialog v-model="printSummaryDialog" persistent width="30%">
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Print Summary Filter</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closePrintSummaryDialog();">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text>
                    <v-row class="m-0 my-6">
                        <v-container fluid>
                            <v-row>
                                <v-col>
                                    <v-radio-group v-model="printSummaryFilter" row>
                                        <v-radio label="Customer" value="customer_id"></v-radio>
                                        <v-radio label="Salesman" value="salesman_id"></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="col" v-if="printSummaryFilter == 'customer_id'">
                                    <v-autocomplete
                                        v-model="printFilter.customer_id"
                                        :items="GET_CUSTOMERS_LIST_SELECTION"
                                        label="Select Customer"
                                        dense
                                        outlined
                                        clearable
                                    />
                                </v-col>
                                <v-col class="col mt-1" v-else>
                                    <v-autocomplete
                                        v-model="printFilter.salesman_id"
                                        :items="GET_SALESMAN_SELECTION"
                                        label="Salesman"
                                        dense
                                        outlined
                                        clearable
                                    />
                                </v-col>
                            </v-row>
                            <v-row justify="space-around">
                                <v-col>
                                    <!-- First v-menu with v-text-field -->
                                    <v-menu
                                        v-model="printFilter.menu_month_date_from"
                                        :close-on-content-click="true"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                v-model="printFilter.date_from"
                                                v-on="on"
                                                label="Date From"
                                                dense
                                                outlined
                                                hide-details
                                                readonly
                                            />
                                        </template>
                                        <v-date-picker
                                            v-model="printFilter.date_from"
                                            :show-current="false"
                                            type="month"
                                            no-title
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col>
                                    <!-- Second v-menu with v-text-field -->
                                    <v-menu
                                        v-model="printFilter.menu_date_to"
                                        :close-on-content-click="true"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                v-model="printFilter.date_to"
                                                v-on="on"
                                                label="Date To"
                                                dense
                                                outlined
                                                hide-details
                                                readonly
                                            />
                                        </template>
                                        <v-date-picker
                                            v-model="printFilter.date_to"
                                            type="month"
                                            no-title
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-row>
                </v-card-text>
                <v-card-actions class="text-center" style="border-top:1px solid #dee2e6;">
                    <v-row class="ma-1 pb-2">
                        <v-col cols="12">
                            <v-btn @click="printSummary()">
                                <span>Print</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
            <v-container fluid style="display: none">
                <div>
                    <table id="soa_summary_print">
                        <thead>
                            <tr>
                                <td>Customer Name</td>
                                <td>Period</td>
                                <td>Outstanding Balance</td>
                                <td>Due Date</td>
                                <td v-if="printSummaryFilter == 'salesman_id'">DAYS OVERDUE</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in GET_PRINT_SUMMARY.data" :key="index">
                                <td>{{ item.customer_name }}</td>
                                <td>{{ item.period }}</td>
                                <td>{{ item.balance_amount | currency }}</td>
                                <td>{{ item.due_date }}</td>
                                <td v-if="printSummaryFilter == 'salesman_id'">{{ item.overdue }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <table id="summary_footer">
                        <tbody>
                            <tr>
                                <td>{{ USERACCOUNT_LOAD.name }}<br>________________<br><br>Prepared By</td>
                                <td><br>________________<br><br>Received By</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </v-container>
        </v-dialog>
    </div>
</template>
<script>
import StatementOfAccountsViewComponentVue from './StatementOfAccountsViewComponent.vue';
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue'
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';

export default {
    mixins: [
        SharedFunctionsComponent,
    ],
    components: {
        StatementOfAccountsViewComponentVue,
    },
    props: [
        'statementOfAccountsActionResult',
        'status_value',
        'filtered_year',
        'filtered_month',
        'status_text',
        'months_selection',
        'date_from',
        'date_to',
        'week_count',
    ],
    data() {
        return {
            actionResult: {
                model: 'StatementOfAccounts',
                action: ''
            },
            loaded: false,
            loading: true,
            totalStatementOfAccounts: 0,
            options: {},
            statementOfAccounts: [],
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            itemsPerPage: 10,
            search: '',
            awaitingSearch: false,
            main_table: {
                headers: [
                    { text: 'SOA Id', align: 'left', value: 'id', },
                    { text: 'Customer', align: 'left', value: 'company_name', },
                    { text: 'Soa Month', align: 'left', value: 'selected_month', },
                    { text: 'Due Month', align: 'left', value: 'due_date', },
                    { text: 'Gross Amount', align: 'left', value: 'gross_amount', },
                    { text: 'DM Amount', align: 'left', value: 'addition_amount', },
                    { text: 'CM Amount', align: 'left', value: 'deduction_amount', },
                    { text: 'Net Amount', align: 'left', value: 'total_amount', },
                    { text: 'Paid Amount', align: 'left', value: 'paid_amount', },
                    { text: 'Bad Debt Amount', align: 'left', value: 'bad_debt_amount', },
                    { text: 'Balance Amount', align: 'left', value: 'balance_amount', },
                    { text: 'Agent', align: 'left', value: 'salesman_name', },
                    { text: 'Prepared By', align: 'left', value: 'prepared_by_name', },
                    { text: 'Completed By', align: 'left', value: 'completed_by_name', },
                    { text: 'Complete Date', align: 'left', value: 'complete_date', },
                    { text: 'Status', align: 'left', value: 'status', },
                    { text: 'Payment Method', align: 'left', value: 'payment_methods', },
                    { text: 'Terms', align: 'left', value: 'terms', },
                    { text: 'Age (Day)', align: 'left', value: 'age', },
                    { text: 'Actions', align: 'center', value: 'action', sortable: false },
                ]
            },
            soa_month_header: { text: 'Soa Month', align: 'left', value: 'selected_month', },
            viewStatementOfAccountId: '',

            userAccess: {
                view: false,
                edit: false,
                delete: false,
                receive: false,
            },
            customerList: [],
            selectedCustomer: '',
            usersLists: [],
            selectedUser: '',
            paymentLists: [],
            selectedCluster: [],
            selectedSubCluster: [],
            selectedPayment: '',
            salesmanLists: [],
            selectedSalesman: '',
            bankLists: [],
            selectedBank: '',
            selectedMotherCustomer: '',
            status: 10,
            chequeNumber: '',
            accountNumber: '',
            exceed: true,
            soa_status: [
                { value: 0, name: 'saved' },
                { value: 1, name: 'approved' },
                { value: 2, name: 'rejected' },
                { value: 3, name: 'canceled' },
                { value: 4, name: 'deleted' },
                { value: 5, name: 'confirmed' },
                { value: 6, name: 'partial' },
                { value: 7, name: 'paid' },
                { value: 8, name: 'overdue' },
                { value: 9, name: 'partial_u' },
            ],
            dateFrom: null,
            dateTo: null,
            mode: '',
            weekCount: '',
            partialStatus: '',
            selectedEdit: {
                id: '',
                balance: ''
            },
            period: {
                headers: [
                    { text: 'Period', value: 'period' },
                    { text: 'Total Gross Amount', align: 'end', value: 'total_gross_amount' },
                    { text: 'Total DM Amount', align: 'end', value: 'total_addition_amount' },
                    { text: 'Total CM Amount', align: 'end', value: 'total_deduction_amount' },
                    { text: 'Total NET Amount', align: 'end', value: 'total_net_amount' },
                    { text: 'Total Paid Amount', align: 'end', value: 'total_paid_amount' },
                    { text: 'Total Bad Debt Amount', align: 'end', value: 'total_bad_debt_amount' },
                    { text: 'Total Balance Amount', align: 'end', value: 'total_balance_amount' },
                ],
                items: [],
                expanded: [],
                loading: false,
            },
            customers_table: {
                headers: [
                    { text: 'Customer Name', value: 'company_name' },
                    { text: 'Terms', value: 'terms' },
                    { text: 'Total Gross Amount', align: 'end', value: 'gross_amount' },
                    { text: 'Total DM Amount', align: 'end', value: 'addition_amount' },
                    { text: 'Total CM Amount', align: 'end', value: 'deduction_amount' },
                    { text: 'Total NET Amount', align: 'end', value: 'total_amount' },
                    { text: 'Total Paid Amount', align: 'end', value: 'paid_amount' },
                    { text: 'Total Bad Debt Amount', align: 'end', value: 'bad_debt_amount' },
                    { text: 'Total Balance Amount', align: 'end', value: 'balance_amount' },
                ],
                expanded: [],
                loading: false,
            },
            overpayment: false,
            head_id: 0,
            activeUserHeadId: [],
            soa_card_details: {
                uncollectibles: 0,
                pdc: {
                    total_net_amount: 0,
                    pdc_count: 0
                },
                dated_cheque: 0,
                week_pdc: {
                    total_net_amount: 0,
                    pdc_count: 0
                },
                week_filters: {
                    from: '',
                    to: ''
                },
                week: {
                    total_net_amount: 0
                },
                total_overdue: 0,
                total_bad_debt: 0,
                cash_terms_with_balance: 0
            },
            weekToday: this.$dayjs().week(),
            showAllCards: 0,
            showAllWithBalance: 0,
            showAllForReceiving: 0,
            showAllForConfirm: 0,
            showAffiliateOnly: 0,
            showAllWithBadDebt: 0,
            showAllDueAccount: 0,
            showAllOverdue: 0,
            selectedCustomerClassification: [],
            selectedCustomerCategory: [],
            selectedAccountingUser: '',
            printSummaryDialog: false,
            printSummaryFilter: 'customer_id',
            printFilter: {
                customer_id: '',
                salesman_id: '',
                menu_month_date_from: false,
                menu_date_to: false,
                date_from: null,
                date_to: null,
            },
            pdfPrintData: {},
            selected: [],
            showFilter: true,
        }
    },
    async mounted() {
        this.$store.dispatch('getAllCustomers')
        this.$store.dispatch('getAccountingUsers')
        this.$store.dispatch("getallcustomerCategory")
        this.$store.dispatch('getAllcustomerClassificationSelection')
        this.$store.dispatch('getAllPaymentTypes')
        this.$store.dispatch('getAllsalesmanSelection')
        this.$store.dispatch('getBankSelection', {
            international: 0,
            type: 'AR',
        })
        this.$store.dispatch('getClusterSelection')
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id).then(response => {
            this.checkAccess()
        })
        this.$store.dispatch('getAffliatecustomer')
    },
    watch: {
        statementOfAccountsActionResult: function (val) {
            if (this.statementOfAccountsActionResult == 'success') {
                this.getPeriods()
            }
        },
        status_value: {
            handler(val) {
                this.status = val

                this.getPeriods()
            }
        },
        'date_from': {
            handler(val) {
                this.dateFrom = val
            },
            deep: true,
        },
        'date_to': {
            handler(val) {
                this.dateTo = val
            },
            deep: true,
        },
        'week_count': {
            handler(val) {
                this.weekCount = val
            },
            deep: true,
        },
        printSummaryFilter: {
            handler(val) {
                if (val == 'customer_id') {
                    this.printFilter.salesman_id = ''
                }
                else {
                    this.printFilter.customer_id = ''
                }
            }
        },
        'printFilter.menu_date_to': {
            handler(val) {
                if (!val) {
                    this.validatePrintDateRange();
                }
            }
        },
        'selectedCluster': {
            handler(val) {
                this.$store.dispatch("getSubClusterSelection", {cluster_ids: [val]});
            },
            immediate: true,
            deep: true,
        },
        'selectedPayment': {
            handler(val) {
                if(val == 2 || val == 3) {
                    this.showFilter = false
                } else {
                    this.showFilter = true
                }
            }
        },
    },
    computed: {
        ...mapGetters([
            'GET_ALL_SOA',
            'USER_ACCESS',
            'USERACCOUNT_LOAD',
            'GET_PRINT_SUMMARY',
            'GET_CUSTOMERS_LIST_SELECTION',
            'GET_ACCOUNTING_USERS',
            'GET_CUSTOMER_CATEGORY_SELECTION',
            'GET_CLASSIFICATIONS_SELECTION',
            'GET_ALL_PAYMENT_TYPES',
            'GET_SALESMAN_SELECTION',
            'GET_BANK_SELECTION',
            'GET_CLUSTER_SELECTION',
            'GET_SUB_CLUSTER_SELECTION',
            'GET_AFFLIATE_CUSTOMER',
        ]),
    },
    methods: {
        getAllStatementOfAccounts() {
            if (this.status == 9) {
                this.partialStatus = 'Under'
            }
            else if (this.status == 6) {
                this.partialStatus = 'Over'
            }
            else {
                this.partialStatus = ''
            }
            this.statementOfAccounts = []
            this.loading = true
            let payload = {
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                search: this.search,
                status: this.status,
                customerId: this.selectedCustomer,
                selected_year: this.filtered_year,
                selected_month: this.filtered_month,
                prepared_by: this.selectedUser,
                payment_method: this.selectedPayment,
                bank: this.selectedBank,
                cheque_num: this.chequeNumber,
                account_number: this.accountNumber,
                date_to: this.dateTo,
                date_from: this.dateFrom,
                mode: this.mode,
                week_count: this.weekCount,
                partial_status: this.partialStatus,
                salesman_id: this.selectedSalesman,
                overpayment: this.overpayment,
                filtered_customer_list: null,
                with_balance: this.showAllWithBalance,
                for_confirm: this.showAllForConfirm,
                for_receiving: this.showAllForReceiving,
                affiliate_only: this.showAffiliateOnly,
                with_bad_debt: this.showAllWithBadDebt,
                due_account: this.showAllDueAccount,
                with_overdue: this.showAllOverdue,
                selectedCustomerClassification: this.selectedCustomerClassification,
                selectedCustomerCategory: this.selectedCustomerCategory,
                accounting_user: this.selectedAccountingUser,
                cluster: this.selectedCluster,
                sub_cluster: this.selectedSubCluster,
                mother_customer: this.selectedMotherCustomer,
            }
            this.$store.dispatch('getAllSoa', payload).then(response => {
                this.statementOfAccounts = this.GET_ALL_SOA.data
                this.loading = false
                this.totalStatementOfAccounts = this.GET_ALL_SOA.total
            })
        },
        loadDetails(item) {
            if (Object.hasOwnProperty('item')) {
                let item = item.item
            }

            if (this.status == 9) {
                this.partialStatus = 'Under'
            }
            else {
                this.partialStatus = ''
            }
            this.statementOfAccounts = []
            this.loading = true
            let payload = {
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                search: this.search,
                status: this.status,
                customerId: item.item.id,
                selected_year: item.item.selected_year,
                selected_month: item.item.selected_month,
                prepared_by: this.selectedUser,
                payment_method: this.selectedPayment,
                bank: this.selectedBank,
                cheque_num: this.chequeNumber,
                account_number: this.accountNumber,
                date_to: this.dateTo,
                date_from: this.dateFrom,
                mode: this.mode,
                week_count: this.weekCount,
                partial_status: this.partialStatus,
                salesman_id: this.selectedSalesman,
                overpayment: this.overpayment,
                filtered_customer_list: null,
                with_balance: this.showAllWithBalance,
                for_confirm: this.showAllForConfirm,
                for_receiving: this.showAllForReceiving,
                affiliate_only: this.showAffiliateOnly,
                with_bad_debt: this.showAllWithBadDebt,
                due_account: this.showAllDueAccount,
                with_overdue: this.showAllOverdue,
                accounting_user: this.selectedAccountingUser,
                cluster: this.selectedCluster,
                sub_cluster: this.selectedSubCluster,
                mother_customer: this.selectedMotherCustomer,
            }
            this.$store.dispatch('getAllSoa', payload).then(response => {
                this.statementOfAccounts = this.GET_ALL_SOA.data
                this.loading = false
                this.totalStatementOfAccounts = this.GET_ALL_SOA.total
                return false
            })
        },
        viewStatementOfAccount(id) {
            this.viewStatementOfAccountId = id
        },
        editStatementOfAccount(id, balance, status) {
            this.selectedEdit.id = id
            this.selectedEdit.balance = balance
            this.$emit('showEditModal', this.selectedEdit.id);
            this.$emit('showEditModalBalance', this.selectedEdit.balance);
        },
        deleteStatementOfAccount(id, item) {
            if(item.has_cm > 0 || item.has_dm > 0) {
                swal.fire({
                    title:'This SOA has CM/DM! Do you want to proceed?',
                    text: '- Deleting this SOA will also remove the tag from the CM/DM',
                    icon:'info',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                }).then(response => {
                    if(response.isConfirmed) {
                        this.delete(id)
                    } else if (response.isDismissed) {
                        return;
                    }
                })
            } else {
                swal.fire({
                    title: "",
                    text: "Are you sure you want to Delete?",
                    icon: 'question',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    allowOutsideClick: false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor: 'grey',
                    confirmButtonText: 'Confirm',
                    showCloseButton: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch('deleteSoa', id).then(response => {
                            this.getPeriods()
                            swal.fire(response.data, { icon: "success", });
                        }).catch(error => {
                            console.log(error)
                            swal.fire("An Error Occured", error, "error");
                        })
                    }
                });
            }


        },
        closeView(action = false) {
            this.viewStatementOfAccountId = 0
            if (action == 'convertSuccess' || action == 'updateStatusSuccess') {
                this.getPeriods()
            }
        },
        checkAccess() {
            let val = this.USER_ACCESS
            this.userAccess.edit = false;
            this.userAccess.delete = false;
            this.userAccess.view = false;
            this.userAccess.receive = false;
            if (val != "fail") {
                val.forEach((e) => {
                    if (e.actions_code == "edit") {
                        this.userAccess.edit = true;
                    }
                    if (e.actions_code == "view") {
                        this.userAccess.view = true;
                    }
                    if (e.actions_code == "delete") {
                        this.userAccess.delete = true;
                    }
                    if (e.actions_code == "receive") {
                        this.userAccess.receive = true;
                    }
                });
            }
        },
        filterByStatus(dateFrom, dateTo, mode, weekCount) {
            this.dateFrom = dateFrom
            this.dateTo = dateTo
            this.mode = mode
            this.weekCount = weekCount

            if (!!this.dateFrom || !!this.dateTo) {
                this.getPeriods()
            }

        },
        showEditButton(item) {
            if (!!this.userAccess.edit) {
                let checker = [0, 5, 6, 7, 8, 9];

                if (checker.includes(item.status)) {
                    return true;
                }
            }

            return false;
        },
        soa_month(month, year) {
            this.months_selection.forEach((e, i) => {
                if (e.value == month) {
                    month = e.value
                }
            })
            return `${year}-${month}`;
        },
        agingDate(due_date) {
            if (!!due_date) {
                let startDay = this.$dayjs(due_date);

                let endDay = this.$dayjs();

                let days = this.$dayjs(endDay).diff(this.$dayjs(startDay), 'day');
                if (days <= 0) {
                    days = 0
                    return days
                }
                else {
                    return days
                }
            }
            return '';
        },
        async exportPerCustomer() {
            if (this.period.items.length == 0) {
                swal.fire({
                    title: 'Cannot Export with an empty data!',
                    text: 'Please run a Filter first before exporting',
                    icon: 'info'
                })

                return false
            }

            let that = this
            let payload = {
                date_to: this.dateTo,
                date_from: this.dateFrom,
            }
            let customer_payments = []
            await this.$store.dispatch('exportPaymentDetailsPerCustomer', payload).then(response => {
                customer_payments = response.data
            })
            const workbook = new this.$exceljs.Workbook();
            that.loading = true
            swal.fire('Exporting Files Please wait.')
            swal.showLoading()

            const worksheet = workbook.addWorksheet('SUMMARY')
            const detailed = workbook.addWorksheet('DETAILED')
            // const pl_per_soa = workbook.addWorksheet('PL PER SOA')
            const payment_details_worksheet = workbook.addWorksheet('PER CLIENT')
            const multiple_client_worksheet = workbook.addWorksheet('MULTIPLE CLIENT')

            const setBgWhite = {
                type: 'pattern', pattern: 'solid', fgColor: { argb: 'fffbfe' }
            }
            worksheet.columns = [
                { header: 'Customer Name', key: 'company_name' },
                { header: 'Period', key: 'selected_month' },
                { header: 'Due Month', key: 'due_month' },
                { header: 'Ageing', key: 'ageing' },
                { header: 'Terms', key: 'terms' },
                { header: 'Limit', style: { numFmt: '#,##0.00' }, key: 'credit_limit' },
                { header: 'Cluster', key: 'cluster' },
                { header: 'Sub Cluster', key: 'sub_cluster' },
                { header: 'Total Gross Amount', style: { numFmt: '#,##0.00' }, key: 'cust_total_gross_amount' },
                { header: 'Total DM Amount', style: { numFmt: '#,##0.00' }, key: 'cust_total_addition_amount' },
                { header: 'Total CM Amount', style: { numFmt: '#,##0.00' }, key: 'cust_total_deduction_amount' },
                { header: 'Total NET Amount', style: { numFmt: '#,##0.00' }, key: 'cust_total_net_amount' },
                { header: 'Total Paid Amount', style: { numFmt: '#,##0.00' }, key: 'cust_total_paid_amount' },
                { header: 'Total Bad Debt Amount', style: { numFmt: '#,##0.00' }, key: 'cust_total_bad_debt_amount' },
                { header: 'Total Balance Amount', style: { numFmt: '#,##0.00' }, key: 'cust_total_balance_amount' }
            ]

            detailed.columns = [
                { header: 'Customer Name', key: 'company_name' },
                { header: 'Period', key: 'selected_month' },
                { header: 'SOA ID', key: 'id' },
                { header: 'Due Month', key: 'due_month' },
                { header: 'Ageing', key: 'ageing' },
                { header: 'Terms', key: 'terms' },
                { header: 'Limit', style: { numFmt: '#,##0.00' }, key: 'credit_limit' },
                { header: 'Cluster', key: 'cluster' },
                { header: 'Sub Cluster', key: 'sub_cluster' },
                { header: 'Total Gross Amount', style: { numFmt: '#,##0.00' }, key: 'gross_amount' },
                { header: 'Total DM Amount', style: { numFmt: '#,##0.00' }, key: 'addition_amount' },
                { header: 'Total CM Amount', style: { numFmt: '#,##0.00' }, key: 'deduction_amount' },
                { header: 'Total NET Amount', style: { numFmt: '#,##0.00' }, key: 'total_amount' },
                { header: 'Total Paid Amount', style: { numFmt: '#,##0.00' }, key: 'paid_amount' },
                { header: 'Total Bad Debt Amount', style: { numFmt: '#,##0.00' }, key: 'bad_debt_amount' },
                { header: 'Total Balance Amount', style: { numFmt: '#,##0.00' }, key: 'balance_amount' }
            ]

            // pl_per_soa.columns = [
            //     { header: 'Customer Name', key: 'company_name' },
            //     { header: 'Period', key: 'period' },
            //     { header: 'SOA ID', key: 'soa_id' },
            //     { header: 'Due Month', key: 'due_month' },
            //     { header: 'Ageing', key: 'ageing' },
            //     { header: 'Terms', key: 'terms' },
            //     { header: 'Limit', key: 'credit_limit', style: { numFmt: '#,##0.00' } },
            //     { header: 'Cluster', key: 'cluster' },
            //     { header: 'Sub Cluster', key: 'sub_cluster' },
            //     { header: 'PL#', key: 'packing_list_num', style: { numFmt: '@' } },
            //     { header: 'PL Date', key: 'packing_list_date', style: { numFmt: 'yyyy-mm-dd' } },
            //     { header: 'PL Remarks', key: 'packing_list_remarks' },
            //     { header: 'PL Amount', key: 'packing_list_total_amount', style: { numFmt: '#,##0.00' } },
            // ]

            let detailed_columns = [
                { header: 'Month', key: 'company_name', style: { fill: setBgWhite } },
                { header: 'Total Gross Amount', style: { numFmt: '#,##0.00', fill: setBgWhite }, key: '' },
                { header: 'Total DM Amount', style: { numFmt: '#,##0.00', fill: setBgWhite }, key: '' },
                { header: 'Total CM Amount', style: { numFmt: '#,##0.00', fill: setBgWhite }, key: '' },
                { header: 'Total NET Amount', style: { numFmt: '#,##0.00', fill: setBgWhite }, key: '' },
                { header: 'Total Balance Amount', style: { numFmt: '#,##0.00', fill: setBgWhite }, key: '' },
                { header: '', key: '', width: '0.50', style: { fill: setBgWhite } },
                { header: 'SOA ID', key: '', style: { fill: setBgWhite } },
                { header: 'Amount', style: { numFmt: '#,##0.00', fill: setBgWhite }, key: '' },
                { header: 'Check Details', key: '', style: { fill: setBgWhite } },
                { header: 'Date', key: '', style: { fill: setBgWhite } },
                { header: 'Total Paid', style: { numFmt: '#,##0.00', fill: setBgWhite }, key: '' },
                { header: 'SOA Balance Amount', style: { numFmt: '#,##0.00', fill: setBgWhite }, key: '' },
                { header: 'Remarks', key: '', style: { fill: setBgWhite } }
            ]
            payment_details_worksheet.columns = detailed_columns
            multiple_client_worksheet.columns = detailed_columns

            let filtered_data = []
            let temp = [];

            for await (let e of this.period.items) {
                temp.push(_.groupBy(e.soas, 'id'))
            }

            for await (let e of temp) {
                for (const prop in e) {
                    let a = _.filter(customer_payments, ['period', e[prop][0].period]);
                    let b = _.filter(a[0].soas, ['id', e[prop][0].id])
                    filtered_data.push({
                        month: e[prop][0].period,
                        total_gross_amount: e[prop][0].cust_total_gross_amount,
                        total_addition_amount: e[prop][0].cust_total_addition_amount,
                        total_addition_amount: e[prop][0].cust_total_addition_amount,
                        total_deduction_amount: e[prop][0].cust_total_deduction_amount,
                        total_net_amount: e[prop][0].cust_total_net_amount,
                        total_balance_amount: e[prop][0].cust_total_balance_amount,
                        company_name: e[prop][0].company_name,
                        asr: e[prop][0].asr,
                        cluster_head: e[prop][0].cluster_head,
                        customer_address: e[prop][0].customer_address,
                        payments: b,
                        vip: e[prop][0].vip,
                        credit_limit: e[prop][0].credit_limit,
                        terms: e[prop][0].terms,
                    })
                }
            }

            let count = 2
            let column_names = ['A', 'B', 'C', 'D', 'E', 'F', 'H', 'I', 'J', 'K', 'L', 'M', 'N']
            filtered_data = _.groupBy(filtered_data, 'company_name');

            // PER CLIENT WORKSHEET

            for (const prop in filtered_data) {
                let host = window.location.origin;
                let img = document.createElement('img');
                let base64 = ''
                await this.getBase64Image(`${host}/images/ingcoph-logo.png`, (res) => {
                    img.setAttribute('data', res);

                }).then(response => {
                    base64 = response
                })

                const imageId2 = workbook.addImage({
                    base64: base64,
                    extension: 'png',
                });

                payment_details_worksheet.addImage(imageId2, `A${count}:D${count + 2}`);
                count += 3;

                let customer_container = payment_details_worksheet.getCell(`A${count}}`)
                customer_container.value = 'CUSTOMER:'
                customer_container.font = { bold: true }

                let customer_name = payment_details_worksheet.getCell(`B${count}}`)
                customer_name.value = prop

                let asr_container = payment_details_worksheet.getCell(`I${count}}`)
                asr_container.value = 'ASR:'
                asr_container.font = { bold: true }

                let asr_name = payment_details_worksheet.getCell(`J${count}}`)
                asr_name.value = filtered_data[prop][0].asr

                count += 1;

                let customer_container_address = payment_details_worksheet.getCell(`A${count}}`)
                customer_container_address.value = 'ADDRESS:'
                customer_container_address.font = { bold: true }

                let customer_address = payment_details_worksheet.getCell(`B${count}}`)
                customer_address.value = filtered_data[prop][0].customer_address.address

                let cluster_container = payment_details_worksheet.getCell(`I${count}}`)
                cluster_container.value = 'CLUSTER HEAD:'
                cluster_container.font = { bold: true }

                let cluster_name = payment_details_worksheet.getCell(`J${count}}`)
                cluster_name.value = !!filtered_data[prop][0].cluster_head ? filtered_data[prop][0].cluster_head : ''

                count += 1;

                let customer_terms_container = payment_details_worksheet.getCell(`I${count}}`)
                customer_terms_container.value = 'CREDIT TERMS:'
                customer_terms_container.font = { bold: true }

                let customer_terms = payment_details_worksheet.getCell(`J${count}}`)
                customer_terms.value = filtered_data[prop][0].terms

                count += 1;

                let customer_limit_container = payment_details_worksheet.getCell(`I${count}}`)
                customer_limit_container.value = 'CREDIT LIMIT:'
                customer_limit_container.font = { bold: true }

                let customer_limit = payment_details_worksheet.getCell(`J${count}}`)
                customer_limit.value = filtered_data[prop][0].vip ? 'VIP' : parseFloat(filtered_data[prop][0].credit_limit)
                customer_limit.numFmt = '#,##0.00'

                count += 2;

                payment_details_worksheet.mergeCells(`A${count}:F${count}`);
                payment_details_worksheet.getCell(`A${count}`).value = 'SOA DETAILS';
                payment_details_worksheet.getCell(`A${count}`).alignment = { vertical: 'middle', horizontal: 'center' };
                payment_details_worksheet.getCell(`A${count}`).font = { bold: true }

                payment_details_worksheet.getCell(`A${count}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'f4b084' }
                };

                payment_details_worksheet.mergeCells(`H${count}:N${count}`);
                payment_details_worksheet.getCell(`H${count}`).value = 'PAYMENT DETAILS';
                payment_details_worksheet.getCell(`H${count}`).alignment = { vertical: 'middle', horizontal: 'center' };
                payment_details_worksheet.getCell(`H${count}`).font = { bold: true }
                payment_details_worksheet.getCell(`H${count}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'f4b084' }
                };

                count = count + 1;

                let sub_header = payment_details_worksheet.getRow(count)
                sub_header.font = { bold: true }
                sub_header.values = ['Month', 'Total Gross Amount', 'Total DM Amount', 'Total CM Amount', 'Total NET Amount', 'Total Balance Amount', '', 'SOA ID', 'Amount', 'Check Details', 'Date', 'Total Paid', 'SOA Balance', 'Remarks']
                let sub_header_array = [`A${count}`, `B${count}`, `C${count}`, `D${count}`, `E${count}`, `F${count}`, `H${count}`, `I${count}`, `J${count}`, `K${count}`, `L${count}`, `M${count}`, `N${count}`];
                sub_header_array.map(key => {
                    payment_details_worksheet.getCell(key).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'f8cbad' },
                    };
                });

                count += 1;

                let start = count
                let end = 0

                for await (let e of filtered_data[prop]) {
                    let period = payment_details_worksheet.getCell(`A${count}`);
                    period.value = this.formatMonthSoa(e.month)
                    let total_gross_amount = payment_details_worksheet.getCell(`B${count}`);
                    total_gross_amount.value = e.total_gross_amount
                    let total_addition_amount = payment_details_worksheet.getCell(`C${count}`);
                    total_addition_amount.value = e.total_addition_amount
                    let total_deduction_amount = payment_details_worksheet.getCell(`D${count}`);
                    total_deduction_amount.value = e.total_deduction_amount
                    let total_net_amount = payment_details_worksheet.getCell(`E${count}`);
                    total_net_amount.value = e.total_net_amount
                    let total_balance_amount = payment_details_worksheet.getCell(`F${count}`);
                    total_balance_amount.value = e.total_balance_amount
                    column_names.forEach(y => {
                        let fields = payment_details_worksheet.getCell(`${y}${count}`)
                        fields.border = { top: { style: 'thin' } };
                    })
                    e.payments.forEach((s, x) => {
                        let soa_id = payment_details_worksheet.getCell(`H${count + x}`)
                        soa_id.value = s.soa_id
                        let payment_amount = payment_details_worksheet.getCell(`I${count + x}`)
                        payment_amount.value = Number(s.total_amount)
                        let reference_number = payment_details_worksheet.getCell(`J${count + x}`)
                        reference_number.value = s.check_details
                        let cheque_date = payment_details_worksheet.getCell(`K${count + x}`)
                        cheque_date.value = s.transact_date
                        let paid_amount = payment_details_worksheet.getCell(`L${count + x}`)
                        paid_amount.value = Number(s.paid_amount)
                        let balance_amount = payment_details_worksheet.getCell(`M${count + x}`)
                        balance_amount.value = Number(s.balance_amount)
                        let remarks = payment_details_worksheet.getCell(`N${count + x}`)
                        remarks.value = s.remarks
                    })
                    end += (e.payments.length > 0 ? e.payments.length : 1);
                    count += (e.payments.length > 0 ? e.payments.length : 1);
                }

                for await (let y of column_names) {
                    let fields = payment_details_worksheet.getCell(`${y}${count}`)
                    if (!['H', 'J', 'K', 'L', 'M', 'N'].includes(y)) {
                        fields.value = y == 'A' ? "Total Amount" : { formula: `SUM(${y}${start}:${y}${start + end - 1})` };
                    }
                    fields.font = {
                        color: { argb: 'ff0000' },
                        bold: true
                    }
                    fields.border = { top: { style: 'thick' } };
                }

                count += 3

                payment_details_worksheet.mergeCells(`B${count}:C${count}`);
                payment_details_worksheet.getCell(`B${count}`).value = this.USERACCOUNT_LOAD.name;
                payment_details_worksheet.getCell(`B${count}`).alignment = { vertical: 'middle', horizontal: 'center' };
                payment_details_worksheet.getCell(`B${count}`).font = { bold: true }

                payment_details_worksheet.mergeCells(`K${count}:L${count}`);
                payment_details_worksheet.getCell(`K${count}`).value = 'Jessica Reago Demetrio';
                payment_details_worksheet.getCell(`K${count}`).alignment = { vertical: 'middle', horizontal: 'center' };
                payment_details_worksheet.getCell(`K${count}`).font = { bold: true }

                count++

                payment_details_worksheet.mergeCells(`B${count}:C${count}`);
                payment_details_worksheet.getCell(`B${count}`).value = 'PREPARED BY:';
                payment_details_worksheet.getCell(`B${count}`).alignment = { vertical: 'middle', horizontal: 'center' };
                payment_details_worksheet.getCell(`B${count}`).font = { bold: true }
                payment_details_worksheet.getCell(`B${count}`).border = { top: { style: 'thin' } };

                payment_details_worksheet.mergeCells(`K${count}:L${count}`);
                payment_details_worksheet.getCell(`K${count}`).value = 'NOTED BY:';
                payment_details_worksheet.getCell(`K${count}`).alignment = { vertical: 'middle', horizontal: 'center' };
                payment_details_worksheet.getCell(`K${count}`).font = { bold: true }
                payment_details_worksheet.getCell(`K${count}`).border = { top: { style: 'thin' } };

                count += 3

                payment_details_worksheet.mergeCells(`K${count}:L${count}`);
                payment_details_worksheet.getCell(`K${count}`).value = '';
                payment_details_worksheet.getCell(`K${count}`).alignment = { vertical: 'middle', horizontal: 'center' };
                payment_details_worksheet.getCell(`K${count}`).font = { bold: true }

                count++

                payment_details_worksheet.mergeCells(`K${count}:L${count}`);
                payment_details_worksheet.getCell(`K${count}`).value = 'RECEIVED BY:';
                payment_details_worksheet.getCell(`K${count}`).alignment = { vertical: 'middle', horizontal: 'center' };
                payment_details_worksheet.getCell(`K${count}`).font = { bold: true }
                payment_details_worksheet.getCell(`K${count}`).border = { top: { style: 'thin' } };

                count += 3
            }

            // MULTIPLE CLIENT WORKSHEET
            count = 1

            for (const prop in filtered_data) {
                let customer_header = multiple_client_worksheet.getRow(count)
                customer_header.font = { bold: true }
                customer_header.values = [`${prop}`]
                let customer_header_array = [`A${count}`, `B${count}`, `C${count}`, `D${count}`, `E${count}`, `F${count}`, `H${count}`, `I${count}`, `J${count}`, `K${count}`, `L${count}`, `M${count}`];
                customer_header_array.map(key => {
                    multiple_client_worksheet.getCell(key).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'f4b084' },
                    };
                });

                count++;

                multiple_client_worksheet.mergeCells(`A${count}:F${count}`);
                multiple_client_worksheet.getCell(`A${count}`).value = 'SOA DETAILS';
                multiple_client_worksheet.getCell(`A${count}`).alignment = { vertical: 'middle', horizontal: 'center' };
                multiple_client_worksheet.getCell(`A${count}`).font = { bold: true }

                multiple_client_worksheet.getCell(`A${count}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'f4b084' }
                };

                multiple_client_worksheet.mergeCells(`H${count}:M${count}`);
                multiple_client_worksheet.getCell(`H${count}`).value = 'PAYMENT DETAILS';
                multiple_client_worksheet.getCell(`H${count}`).alignment = { vertical: 'middle', horizontal: 'center' };
                multiple_client_worksheet.getCell(`H${count}`).font = { bold: true }
                multiple_client_worksheet.getCell(`H${count}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'f4b084' }
                };

                count = count + 1;

                let sub_header = multiple_client_worksheet.getRow(count)
                sub_header.font = { bold: true }
                sub_header.values = ['Month', 'Total Gross Amount', 'Total DM Amount', 'Total CM Amount', 'Total NET Amount', 'Total Balance Amount', '', 'SOA ID', 'Amount', 'Check Details', 'Date', 'Total Paid', 'Remarks']
                let sub_header_array = [`A${count}`, `B${count}`, `C${count}`, `D${count}`, `E${count}`, `F${count}`, `H${count}`, `I${count}`, `J${count}`, `K${count}`, `L${count}`, `M${count}`, `N${count}`];
                sub_header_array.map(key => {
                    multiple_client_worksheet.getCell(key).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'f8cbad' },
                    };
                });

                count += 1;

                let start = count
                let end = 0

                for await (let e of filtered_data[prop]) {
                    let period = multiple_client_worksheet.getCell(`A${count}`);
                    period.value = this.formatMonthSoa(e.month)
                    let total_gross_amount = multiple_client_worksheet.getCell(`B${count}`);
                    total_gross_amount.value = e.total_gross_amount
                    let total_addition_amount = multiple_client_worksheet.getCell(`C${count}`);
                    total_addition_amount.value = e.total_addition_amount
                    let total_deduction_amount = multiple_client_worksheet.getCell(`D${count}`);
                    total_deduction_amount.value = e.total_deduction_amount
                    let total_net_amount = multiple_client_worksheet.getCell(`E${count}`);
                    total_net_amount.value = e.total_net_amount
                    let total_balance_amount = multiple_client_worksheet.getCell(`F${count}`);
                    total_balance_amount.value = e.total_balance_amount
                    column_names.forEach(y => {
                        let fields = multiple_client_worksheet.getCell(`${y}${count}`)
                        fields.border = { top: { style: 'thin' } };
                    })
                    e.payments.forEach((s, x) => {
                        let soa_id = multiple_client_worksheet.getCell(`H${count + x}`)
                        soa_id.value = s.soa_id
                        let payment_amount = multiple_client_worksheet.getCell(`I${count + x}`)
                        payment_amount.value = Number(s.total_amount)
                        let reference_number = multiple_client_worksheet.getCell(`J${count + x}`)
                        reference_number.value = s.check_details
                        let cheque_date = multiple_client_worksheet.getCell(`K${count + x}`)
                        cheque_date.value = s.transact_date
                        let paid_amount = multiple_client_worksheet.getCell(`L${count + x}`)
                        paid_amount.value = Number(s.paid_amount)
                        let balance_amount = multiple_client_worksheet.getCell(`M${count + x}`)
                        balance_amount.value = Number(s.balance_amount)
                        let remarks = multiple_client_worksheet.getCell(`N${count + x}`)
                        remarks.value = s.remarks
                    })
                    end += (e.payments.length > 0 ? e.payments.length : 1);
                    count += (e.payments.length > 0 ? e.payments.length : 1);
                }

                for await (let y of column_names) {
                    let fields = multiple_client_worksheet.getCell(`${y}${count}`)
                    if (!['H', 'J', 'K', 'L', 'M', 'N'].includes(y)) {
                        fields.value = y == 'A' ? "Total Amount" : { formula: `SUM(${y}${start}:${y}${start + end - 1})` };
                    }
                    fields.font = {
                        color: { argb: 'ff0000' },
                        bold: true
                    }
                    fields.border = { top: { style: 'thick' } };
                }

                count++
            }

            for await (let e of this.period.items) {
                if (e.soas.length > 0) {
                    for await (let x of e.soas) {
                        x.selected_month = this.formatMonthSoa(this.soa_month(x.selected_month, x.selected_year))
                        x.terms = x.terms == 0 ? 'COD' : x.terms
                        x.credit_limit = x.vip ? 'VIP' : parseFloat(x.credit_limit)

                        if(!!x.cluster) {
                            let arr = new Set(x.cluster.split(', '))
                            x.cluster = Array.from(arr).join(', ')
                        }

                        if(!!x.sub_cluster) {
                            let arr = new Set(x.sub_cluster.split(', '))
                            x.sub_cluster = Array.from(arr).join(', ')
                        }

                        x.due_month = this.$dayjs(x.due_month).format('MMM YYYY')
                        worksheet.addRow(x)
                    }
                }
            }

            for await (let e of this.period.items) {
                if (e.soas.length > 0) {
                    for await (let x of e.soas) {
                        if (x.amounts.length > 0) {
                            for await (let z of x.amounts) {
                                z.terms = z.terms == 0 ? 'COD' : z.terms
                                z.credit_limit = z.vip ? 'VIP' : parseFloat(z.credit_limit)

                                // turn to int
                                z.selected_month = x.selected_month;
                                if(!!z.cluster) {
                                    let arr = new Set(z.cluster.split(', '))
                                    z.cluster = Array.from(arr).join(', ')
                                }

                                if(!!z.sub_cluster) {
                                    let arr = new Set(z.sub_cluster.split(', '))
                                    z.sub_cluster = Array.from(arr).join(', ')
                                }

                                z.due_month = this.$dayjs(z.due_month).format('MMM YYYY')
                                z.gross_amount = parseFloat(z.gross_amount);
                                z.addition_amount = parseFloat(z.addition_amount);
                                z.deduction_amount = parseFloat(z.deduction_amount);
                                z.total_amount = parseFloat(z.total_amount);
                                z.paid_amount = parseFloat(z.paid_amount);
                                z.bad_debt_amount = parseFloat(z.bad_debt_amount);
                                z.balance_amount = parseFloat(z.balance_amount);

                                detailed.addRow(z)
                            }
                        }
                    }
                }
            }

            let soa_ids = []
            for await (let m of this.period.items) {
                for await (let n of m.soas) {
                    for await (let o of n.amounts) {
                        soa_ids.push(o.id)
                    }
                }
            }

            // const chunkSize = 300;
            // for (let i = 0; i < soa_ids.length; i += chunkSize) {
            //     const chunk = soa_ids.slice(i, i + chunkSize);
            //     await this.$store.dispatch('getSoaPls', {
            //         id: chunk,
            //     }).then(async response => {
            //         pl_per_soa.addRows(response.data)
            //     })
            // }

            worksheet.getRow(`0${1}`).font = { bold: true }
            detailed.getRow(`0${1}`).font = { bold: true }
            // pl_per_soa.getRow(`0${1}`).font = { bold: true }
            payment_details_worksheet.getRow(`0${1}`).hidden = true;

            let selectedStatusText = this.status_text.toUpperCase()
            let filtered_monthText = ''
            this.months_selection.forEach(e => {
                if (e.value == this.filtered_month) {
                    filtered_monthText = e.name
                }
            })
            if (!this.selectedCustomer) {
                this.selectedCustomer = ''
            }
            let exportName = `SOA (${selectedStatusText}) - ${this.$dayjs().format("YYYY-MM-DD")}`
            that.loading = false
            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                this.$filesaver.saveAs(blob, `${exportName}.xlsx`);
                swal.close()
            });
        },
        checkIfCombi(payment_method) {
            if (!!payment_method) {
                payment_method = Array.from(new Set(payment_method.split(','))).toString();
                if (!!payment_method) {
                    if (payment_method.includes(',')) {
                        return payment_method
                    }
                    else {
                        return payment_method
                    }
                }
                else {
                    return payment_method;
                }
            }
            else {
                return ''
            }

        },
        async getPeriods() {
            this.closeAllExpanded();
            this.period.items = [];
            this.period.loading = true
            this.customers_table.items = [];
            this.customers_table.loading = true
            this.main_table.items = []

            if (this.status == 11) {
                this.overpayment = 'Overpayment'
                this.status = 10
            } else {
                this.overpayment = ''
            }

            let payload = {
                date_from: this.dateFrom,
                date_to: this.dateTo,
                search: this.search,
                status: this.status,
                customerId: this.selectedCustomer,
                selected_year: this.filtered_year,
                selected_month: this.filtered_month,
                prepared_by: this.selectedUser,
                payment_method: this.selectedPayment,
                bank: this.selectedBank,
                cheque_num: this.chequeNumber,
                account_number: this.accountNumber,
                mode: this.mode,
                week_count: this.weekCount,
                partial_status: this.partialStatus,
                salesman_id: this.selectedSalesman,
                overpayment: this.overpayment,
                filtered_customer_list: null,
                week_filter: [this.soa_card_details.week_filters.from, this.soa_card_details.week_filters.to],
                with_balance: this.showAllWithBalance,
                for_confirm: this.showAllForConfirm,
                for_receiving: this.showAllForReceiving,
                affiliate_only: this.showAffiliateOnly,
                with_bad_debt: this.showAllWithBadDebt,
                due_account: this.showAllDueAccount,
                with_overdue: this.showAllOverdue,
                selectedCustomerClassification: this.selectedCustomerClassification,
                selectedCustomerCategory: this.selectedCustomerCategory,
                accounting_user: this.selectedAccountingUser,
                cluster: this.selectedCluster,
                sub_cluster: this.selectedSubCluster,
                mother_customer: this.selectedMotherCustomer,
            }
            this.$store.dispatch('getSoaPerPeriod', payload).then(response => {
                this.period.items = response.data;
                this.period.loading = false
                this.customers_table.loading = false
            });
            this.$store.dispatch('getSoaCardDetails', payload).then(response => {
                this.soa_card_details.uncollectibles = response.data.uncollectibles[0].balance;
                this.soa_card_details.pdc.total_net_amount = response.data.pdc[0].total_net_amount;
                this.soa_card_details.pdc.count = response.data.pdc[0].pdc_count;
                this.soa_card_details.dated_cheque = response.data.dc[0].dc_today;
                this.soa_card_details.week.total_net_amount = response.data.week_pdc[0].total_net_amount;
                this.soa_card_details.week.count = response.data.week_pdc[0].pdc_count;
                this.soa_card_details.total_overdue = response.data.total_overdue[0].total_balance_amount;
                this.soa_card_details.total_bad_debt = response.data.total_bad_debt[0].total_bad_debt_amount;
                this.soa_card_details.cash_terms_with_balance = response.data.cash_terms_with_balance[0].total_net_amount;
            });
        },
        formatMonthSoa(value) {
            if (value) {
                return this.$dayjs(String(value)).format('MMM YYYY')
            }
        },
        closeAllExpanded() {
            this.period.expanded = [];
            this.customers_table.expanded = [];
        },
        goToChequeDepositReport() {
            window.open("#/home/reports/accounting/cheque-deposit-report", '_blank');
        },
        async getBase64Image(src, callback) {
            let img = new Image();
            img.src = src
            img.crossOrigin = "Anonymous"
            let imageHandler = new Promise(resolve => {
                img.onload = function () {
                    let canvas = document.createElement("canvas");
                    canvas.width = img.naturalWidth;
                    canvas.height = img.naturalHeight;
                    let ctx = canvas.getContext("2d");
                    ctx.drawImage(this, 0, 0);

                    let dataUrl = canvas.toDataURL("image/jpg");
                    resolve(dataUrl);
                }
            })
            return imageHandler
        },
        openPrintSummaryDialog() {
            this.printSummaryDialog = true;
            this.resetPrintFilter();
        },
        closePrintSummaryDialog() {
            this.printSummaryDialog = false;
        },
        async printSummary() {
            // if (this.printFilter.date_from == null && this.printFilter.date_to == null){
            //     swal.fire(
            //         "Please select a date range.",
            //         "",
            //         'warning'
            //     )
            //     return false;
            // }else{
            swal.fire('Generating File Please wait.')
            swal.showLoading()
            this.printFilter.filterType = this.printSummaryFilter
            await this.$store.dispatch('printSummarySOA', this.printFilter).then(async (res) => {
                this.pdfPrintData = res.data;
                if (res.count == 0) {
                    swal.fire(
                        "Warning",
                        "No outstanding balance for this specified period.",
                        'warning'
                    )
                    return false;
                }
                else {
                    await this.printSummaryNow();
                }
            }).catch((err) => {
                console.log(err);
                swal.fire(
                    "Error",
                    "",
                    "error"
                );
            })
            // }
        },
        async printSummaryNow() {
            let doc = new this.$jspdf("p", "mm", "letter");
            let table_height = 0;

            let filter = this.printSummaryFilter == 'customer_id' ? 'CUSTOMER' : "SALESMAN"
            let ingcoPhLogo = new Image();
            let date_today = this.$dayjs().format('YYYY-MM-DD');

            ingcoPhLogo.src = "/images/ingcoph-logo.png";
            doc.addImage(ingcoPhLogo, "png", 4, 3, 60, 22);

            doc.rect(90, 0, 200, 12);
            doc.setFontSize(18).setFont(undefined, "bold")
            doc.text(105, 8, "STATEMENT OF ACCOUNT");

            doc.setFontSize(8).setFont(undefined, "bold")
            doc.rect(130, 19, 100, 6);
            doc.text(135, 23, `${filter}: ${this.GET_PRINT_SUMMARY.filter_name ?? ''}`).setFont(undefined, "bold");


            doc.rect(130, 25, 100, 6);
            doc.text(135, 29, `DATE PRINTED: ${date_today}`).setFont(undefined, "bold");

            doc.autoTable({
                html: "#soa_summary_print",
                theme: "grid",
                margin: { top: 40, left: 6, right: 6, bottom: 30 },
                startY: 40,
                headStyles: {
                    halign: "center",
                    fillColor: "#ffffff",
                    textColor: "#000000",
                    lineColor: "#000000",
                    lineWidth: 0.1,
                    fontSize: 8,
                },
                columnStyles: {
                    0: { cellWidth: 90, halign: "center", textColor: "#000000" },
                    1: { halign: "center", textColor: "#000000" },
                    2: { halign: "center", textColor: "#000000" },
                    3: { halign: "center", textColor: "#000000" },
                    4: { halign: "center", textColor: '#000000' },
                },
                bodyStyles: {
                    lineColor: "#000000",
                },
                didParseCell: function (data) {
                    if (data.column.index == 4 && data.cell.raw !== undefined) {
                        // Change color of text to red if not 0
                        if (parseInt(data.cell.raw.textContent) > 0) {
                            data.cell.styles.textColor = 'red';
                        }
                    }
                }
            });
            if (table_height > 200) {
                doc.addPage()
            }
            var footer_y = 252;
            doc.autoTable({
                html: '#summary_footer',
                startY: footer_y,
                theme: 'plain',
                margin: { top: 0, left: 0, right: 30, bottom: 0 },
                columnStyles: {
                    0: { cellWidth: 60, halign: 'center' },
                    1: { cellWidth: 60, halign: 'center' },
                },
                pageBreak: 'auto',
                didDrawPage: (d) => {
                    table_height = d.cursor.y
                },
            });

            const totalPages = doc.internal.getNumberOfPages();
            for (let i = 1; i <= totalPages; i++) {
                doc.setPage(i); // Set the current page
                if (totalPages > 1) {
                    let filter = this.printSummaryFilter == 'customer_id' ? 'CUSTOMER' : "SALESMAN"
                    let ingcoPhLogo = new Image();
                    let date_today = this.$dayjs().format('YYYY-MM-DD');

                    ingcoPhLogo.src = "/images/ingcoph-logo.png";
                    doc.addImage(ingcoPhLogo, "png", 4, 3, 60, 22);

                    doc.rect(90, 0, 200, 12);
                    doc.setFontSize(18).setFont(undefined, "bold")
                    doc.text(105, 8, "STATEMENT OF ACCOUNT");

                    doc.setFontSize(8).setFont(undefined, "bold")
                    doc.rect(130, 19, 100, 6);
                    doc.text(135, 23, `${filter}: ${this.GET_PRINT_SUMMARY.filter_name ?? ''}`).setFont(undefined, "bold");


                    doc.rect(130, 25, 100, 6);
                    doc.text(135, 29, `DATE PRINTED: ${date_today}`).setFont(undefined, "bold");
                }
                // doc.setFont(undefined, 'bold').setFontSize(13).text(162, 13, "Sample Form")
                // doc.setFont(undefined, 'bold').setFontSize(11).text(162, 18, "SF#: " +this.form.sample_form_num)
                // doc.setFont(undefined, "bold").setFontSize(13).text(6, 13, "INGCOPH Traders Inc.\n");
                // doc.setFont(undefined, 'normal').setFontSize(10).text(6, 18, "130-A 6th St. 7th Ave, East Grace Park, Caloocan City \nCell No.: +63 935 713 2293 / +63 917 170 0979 / +63 919 078 0016 \nTel. No.: +63 2 8727 1771 / +63 2 8535 9968 / +63 2 7091 4539")

                let pageNumber = "Page " + i + " of " + totalPages;
                doc.setFontSize(7);
                doc.setTextColor("#000000");
                doc.text(190, 270, pageNumber);
            }

            doc.autoPrint();
            window.open(doc.output("bloburl"), "_blank");
            swal.close();
        },
        resetPrintFilter() {
            this.printFilter = {
                customer_id: '',
                salesman_id: '',
                menu_month_date_from: false,
                menu_date_to: false,
                date_from: null,
                date_to: null,
            }
        },
        validatePrintDateRange() {
            if (this.printFilter.date_from > this.printFilter.date_to) {
                swal.fire(
                    "Invalid Date Range",
                    "",
                    "warning"
                )
                this.printFilter.date_from = '';
                this.printFilter.date_to = '';
            }
        },

        delete(id) {
            this.$store.dispatch('deleteSoa', id).then(response => {
                this.getPeriods()

                swal.fire(response.data, { icon: "success", });
            }).catch(error => {
                console.log(error)
                swal.fire("An Error Occured", error, "error");
            })
        },

        updateMassReceived() {
            swal.fire({
                title: "",
                text: "Are you sure you want to receive selected SOA's?",
                icon: 'question',
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                allowOutsideClick: false,
                confirmButtonColor: '#397373',
                cancelButtonColor: 'grey',
                confirmButtonText: 'Confirm',
                showCloseButton: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    let payload = {
                        soas: this.selected,
                    }
                    this.$store.dispatch('updateMassReceived', payload).then(response => {
                        swal.fire("", 'Successfully Received', "success");
                        this.selected = []
                        this.getPeriods()
                    }).catch(error => {
                        console.log(error)
                        swal.fire("", 'An Error Occured', "error");
                    })
                }
            });
        }
    },
};
</script>
