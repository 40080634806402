<template>
    <v-container fluid class="pa-3 tabs-items">
      <h3 class="page-title">Warehouse User</h3>
          <v-tabs v-model="WAREHOUSE_USER.TAB" color="cyan" dark slider-color="cyan" background-color="#424242" class="col-12" show-arrows touchless>
              <v-tab>Warehouse</v-tab>
              <v-tab>User</v-tab>
              <v-tab>Personnel</v-tab>
          </v-tabs>
          <v-tabs-items  v-model="WAREHOUSE_USER.TAB">
              <v-tab-item>
                  <warehouse-user-add-component></warehouse-user-add-component>
              </v-tab-item>
              <v-tab-item>
                  <warehouse-user-component ref="view"></warehouse-user-component>
              </v-tab-item>
              <v-tab-item>
                 <warehouse-personnel-component></warehouse-personnel-component>
              </v-tab-item>
          </v-tabs-items>
    </v-container>
  </template>

  <script>
  import WarehouseUserAddComponent from './WarehouseUserAddComponent.vue'
  import WarehouseUserComponent from './WarehouseUserViewComponent.vue'
  import WarehousePersonnelComponent from './WarehousePersonel/WarehousePersonnelComponent.vue'

  import { mapGetters } from 'vuex'
  export default {
      data(){
          return{
              tab:{
                  tab:0
              }
          }
      },
      components:{
          WarehouseUserComponent,
          WarehouseUserAddComponent,
          WarehousePersonnelComponent
      },
      computed:{
          ...mapGetters(['WAREHOUSE_USER','GET_WAREHOUSE_LIST','GET_ALL_USER'])
      },
      mounted(){
        this.$store.dispatch("getWarehouse",{warehouse_user: 1})
        this.$store.dispatch("getAllUsersSelect")

      },
      watch:{
          'WAREHOUSE_USER.DIALOG':{
              async handler(val){
                  if(val){
                    let warehouses = this.GET_WAREHOUSE_LIST
                    warehouses.forEach(e=>{
                        e.selected = !!this.WAREHOUSE_USER.SELECTED.warehouses.find(el => el.id == e.id) ? '1' : '0'
                    })
                    this.$store.commit('WAREHOUSE_LIST',warehouses)
                  }else{
                        let warehouses = this.GET_WAREHOUSE_LIST
                        warehouses.forEach(e => {
                            e.selected = '0'
                        });
                      this.$store.commit('WAREHOUSE_LIST',warehouses)
                  }
              }
        },
      }
  }
  </script>

  <style>
   .tabs-items .theme--light.v-tabs-items{
      background-color: #1e1e1c !important;
  }
  .tabs-items .v-window__container{
      padding-left: 10px !important;
      padding-right: 10px !important;
  }
  .tabs-items .v-tabs{
      padding: 12px 10px 0px 10px !important;
  }
  /* PAGE TITEL CSS */
  .tabs-items .page-title{
      padding-left: 10px;
  }
  </style>
