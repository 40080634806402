<template>
    <v-container class="m-0 p-0">
        <v-card class="pa-3">
            <v-card-title>
                <v-row class="ml-0">
                    <v-col lg="2">
                        <v-autocomplete
                            v-model="filters.company_id"
                            dense
                            outlined
                            label="Company"
                            auto-select-first
                            :items="GET_PV_COMPANIES_SELECTION"
                            hide-details
                            clearable
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-col lg="2">
                        <v-autocomplete
                            v-model="filters.stores_id"
                            :items="GET_PV_STORE_BRANCHES_SELECTION"
                            dense
                            outlined
                            auto-select-first
                            label="Stores"
                            hide-details
                            clearable
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-col lg="2">
                        <v-autocomplete
                            v-model="filters.statement"
                            :items="statements"
                            label="Statement"
                            outlined
                            dense
                            auto-select-first
                        />
                    </v-col>
                    <v-col lg="2">
                        <v-autocomplete
                            v-model="filters.year"
                            :items="years"
                            label="Year"
                            outlined
                            dense
                            auto-select-first
                        />
                    </v-col>
                    <!-- <v-col cols="1" class="pl-2">
                        <v-btn
                            :disabled="!filters.company_id"
                            @click="()=>{
                                options.page = 1
                                getAllFinancials()
                            }"
                        >
                            FILTER
                        </v-btn>
                    </v-col> -->
                    <v-spacer/>
                    <v-col cols="1" class="text-end">
                        <v-btn
                            :disabled="!filters.statement"
                            :loading="exportLoading"
                            @click="()=>{
                                exportLoading = true
                                getAllFinancials(1)
                            }"
                        >
                            EXPORT
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-subtitle>
                <v-row class="pt-5 px-2">
                    <v-col cols="12">
                        <v-data-table
                            :headers="fundamental.headers"
                            :items="fundamental.items"
                            :loading="fundamental.loading"
                            :expanded.sync="fundamental.expanded"
                            :item-key="'id'"
                            dense
                            disable-sort
                            disable-pagination
                            show-expand
                            hide-default-footer
                        >
                            <template v-slot:expanded-item="{ headers, item }">
                                <td :colspan="headers.length">
                                    <v-data-table
                                        :headers="type.headers"
                                        :items="getTypeItems(item.id)"
                                        :loading="type.loading"
                                        :expanded-sync="type.expanded"
                                        :items-per-page="10"
                                        :item-key="'type_id'"
                                        :show-expand="item.children.length > 0 ? true : false"
                                        dense
                                        disable-sort
                                        disable-pagination
                                        hide-default-footer
                                    >
                                        <template v-slot:expanded-item="{ headers, item }">
                                            <td :colspan="headers.length">
                                                <v-data-table
                                                    :headers="group.headers"
                                                    :items="getGroupItems(item.type_id, item.fundamental_id)"
                                                    :loading="group.loading"
                                                    :item-key="item.account_group_id ? 'account_group_id' : 'id'"
                                                    :show-expand="item.children.length > 0 ? true : false"
                                                    dense
                                                    disable-sort
                                                >
                                                    <template v-slot:expanded-item="{ headers, item }">
                                                        <td :colspan="headers.length">
                                                            <v-data-table
                                                                :headers="accounts.headers"
                                                                :items="getAccountItems(item.id)"
                                                                :loading="accounts.loading"
                                                                dense
                                                                disable-sort
                                                            />
                                                        </td>
                                                    </template>
                                                </v-data-table>
                                            </td>
                                        </template>
                                    </v-data-table>
                                </td>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-subtitle>
        </v-card>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue';
export default {
    mixins:[SharedFunctionsComponent],
    props:['userAccess'],
    data(){
        return{
            fundamental: {
                headers: [
                    { text: 'Fundamental', value: 'name', align: 'left' },
                ],
                items: [],
                expanded: [],
                loading: false,
            },
            type: {
                headers: [
                    { text: 'Type', value: 'name', align: 'left' },
                ],
                items: [],
                expanded: [],
                loading: false,
            },
            group: {
                headers: [
                    { text: 'Group', value: 'name', align: 'left' },
                ],
                items: [],
                expanded: [],
                loading: false,
            },
            accounts: {
                headers: [
                    { text: 'Account Name', value: 'name', align: 'left' },
                ],
                items: [],
                expanded: [],
                loading: false,
            },
            options: {},
            filters:{
                company_id: null,
                store_branch_id: null,
                // statement: [1,2],
                statement: 3,
                year: 2024
            },
            clonedStores: [],
            statements: [
                { text: 'Trial Balance', value: 3 },
                { text: 'Balance Sheet', value: 1 },
                { text: 'Income Statement', value: 2 },
            ],
            years: [
                { text: 2024, value: 2024 },
            ],
            exportLoading: false,
            exportData: [],
            groupSum:{
                previousYear: [],
                January: [],
                February: [],
                percentage_diff_1: [],
                March: [],
                percentage_diff_2: [],
                quarter_1: [],
                April: [],
                percentage_diff_3: [],
                May: [],
                percentage_diff_4: [],
                June: [],
                percentage_diff_5: [],
                quarter_2: [],
                quarter_diff_1: [],
                July: [],
                percentage_diff_6: [],
                August: [],
                percentage_diff_7: [],
                September: [],
                percentage_diff_8: [],
                quarter_3: [],
                quarter_diff_2: [],
                October: [],
                percentage_diff_9: [],
                November: [],
                percentage_diff_10: [],
                December: [],
                percentage_diff_11: [],
                quarter_4: [],
                quarter_diff_3: [],
                end_year: []
            },
            monthSum:{
                previousYear: [],
                January: [],
                February: [],
                March: [],
                quarter_1: [],
                April: [],
                May: [],
                June: [],
                quarter_2: [],
                July: [],
                August: [],
                September: [],
                quarter_3: [],
                October: [],
                November: [],
                December: [],
                quarter_4: [],
                end_year: []
            },
        }
    },
    mounted(){
        this.getAllFinancials()
        this.getBranches()
    },
    computed:{
        ...mapGetters([
            'GET_PV_COMPANIES_SELECTION',
            'GET_PV_STORE_BRANCHES_SELECTION',
        ]),
    },
    methods:{
        async getBranches(){
            try{
                this.clonedStores = await this.getStoreBranches()
            } catch (error) {
                console.error(error)
            }
        },
        insertCompany(storeId){
            if (!storeId) return
            let store = this.clonedStores.find(e=>e.value == storeId)
            this.filters.company_id = this.GET_PV_COMPANIES_SELECTION.find(e=>e.value == store.company_id).value
        },
        // insertStore(){
        //     this.filters.store_branch_id = this.clonedStores[0].value
        // },
        getAllFinancials(for_export = 0){
            if (for_export != 1) {
                this.fundamental.loading = true
                this.fundamental.items = []
            }
            let payload = {
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
            }
            this.$store.dispatch('getAllChartOfAccounts', payload).then(response=>{
                if (for_export) {
                    this.exportData = response.data
                    this.generateFS()
                    return
                }
                let fundamentalData = response.data
                let typeData = []
                let groupData = []
                let accountData = []
                fundamentalData.forEach(e => {
                    typeData.push(
                        ...e.children
                            .map(value => ({
                                name: value.account_type,
                                type_id: value.account_type_id,
                                fundamental_id: value.fundamental_id,
                                children: value.children
                            }))
                            .filter((value, index, array) =>
                                array.findIndex(item =>
                                    item.name === value.name &&
                                    item.type_id === value.type_id &&
                                    item.fundamental_id === value.fundamental_id
                                ) === index
                            )
                    );
                    groupData.push(
                        ...e.children
                            .map(value => ({
                                id: value.id,
                                name: value.name,
                                account_group: value.account_group,
                                account_group_id: value.account_group_id,
                                type_id: value.account_type_id,
                                fundamental_id: value.fundamental_id,
                            }))
                            .filter((value, index, array) =>
                                array.findIndex(item =>
                                    item.id === value.id &&
                                    item.name === value.name &&
                                    item.account_group === value.account_group &&
                                    item.account_group_id === value.account_group_id &&
                                    item.type_id === value.type_id &&
                                    item.fundamental_id === value.fundamental_id
                                ) === index
                            )
                    )
                    e.children.forEach(parent=>{
                        parent.children.forEach(children => {
                            accountData.push(children)
                        })
                    })
                });
                this.fundamental.items = fundamentalData
                this.type.items = typeData
                this.group.items = groupData
                this.accounts.items = accountData
                this.fundamental.loading = false
            })
        },
        getTypeItems(id) {
            return this.type.items.filter(e=>e.fundamental_id == id);
        },
        getGroupItems(id, fundamental_id) {
            return this.group.items.filter(e=>e.type_id == id && e.fundamental_id == fundamental_id);
        },
        getAccountItems(id) {
            return this.accounts.items.filter(e =>
                e.parent_account_id == id
            )
        },
        async generateFS(){
            let data = [];
            let previousYear = [];
            let headers = [];
            let cYear = ''
            let pYear = ''
            try {
                const workbook = new this.$exceljs.Workbook();
                let payload = {
                    company_id: this.filters.company_id,
                    store_branch_id: this.filters.store_branch_id,
                    year: this.filters.year ?? this.$dayjs().format('YYYY')
                }
                await this.$store.dispatch('exportFS', payload).then(response => {
                    data = response.data.currentYear
                    previousYear = response.data.previousYear
                    cYear = response.data.cYear
                    pYear = response.data.pYear
                })

                // let year = this.filters.year ?? this.$dayjs().format('YYYY');
                // let currentMonth = this.$dayjs().month();
                // let date = this.$dayjs().year(year).month(currentMonth);

                let sheetNames = [
                    'FS'
                ];

                sheetNames.forEach((sheetName) => {
                    const worksheet = workbook.addWorksheet(sheetName);

                    worksheet.views = [
                            { showGridLines: false, zoomScale: 85, state: 'frozen', ySplit: 5, xSplit: 2, frozen: true, }
                        ]

                        worksheet.getColumn(1).width = 45

                        let rowToAdd

                        if (cYear == this.$dayjs().year()) {
                            rowToAdd = ['as of' + ` ${this.$dayjs().format('MMMM')} ${cYear}`]
                        } else {
                            rowToAdd = ['Year' + ` ${cYear}`]
                        }
                        worksheet.addRows([
                            ['INGCOPH TRADERS INC.'],
                            rowToAdd
                        ])
                        worksheet.getCell('A1').font = { name: 'Trebuchet MS', size: 20, bold: true, color: { argb: 'ED7D31' } }
                        worksheet.getCell('A2').font = { name: 'Trebuchet MS', size: 10, bold: true }

                        headers = [
                            '',
                            // this.$dayjs().subtract(1, 'year').format('YYYY'),
                            pYear,
                            'January',
                            'February',
                            '▲',
                            'March',
                            '▲',
                            'Q1 ' + this.$dayjs().format('YYYY'),
                            'April',
                            '▲',
                            'May',
                            '▲',
                            'June',
                            '▲',
                            'Q2 ' + this.$dayjs().format('YYYY'),
                            'Q1-Q2▲',
                            'July',
                            '▲',
                            'August',
                            '▲',
                            'September',
                            '▲',
                            'Q3 ' + this.$dayjs().format('YYYY'),
                            'Q2-Q3▲',
                            'October',
                            '▲',
                            'November',
                            '▲',
                            'December',
                            '▲',
                            'Q4 ' + this.$dayjs().format('YYYY'),
                            'Q3-Q4▲',
                            this.$dayjs().format('YYYY'),
                        ]

                        worksheet.columns = [
                            { key: 'name', width: 44.14 },
                            { key: 'previousYear', width: 18.57, style: { numFmt: '#,#0.00' } },
                            { key: 'January', width: 18.57, style: { numFmt: '#,#0.00' } },
                            { key: 'February', width: 18.57, style: { numFmt: '#,#0.00' } },
                            { key: 'percentage_diff_1', width: 11.14, style: { numFmt: '0.00%' } },
                            { key: 'March', width: 18.57, style: { numFmt: '#,#0.00' } },
                            { key: 'percentage_diff_2', width: 11.14, style: { numFmt: '0.00%' } },
                            { key: 'quarter_1', width: 18.57, style: { numFmt: '#,#0.00' } },
                            { key: 'April', width: 18.57, style: { numFmt: '#,#0.00' } },
                            { key: 'percentage_diff_3', width: 11.14, style: { numFmt: '0.00%' } },
                            { key: 'May', width: 18.57, style: { numFmt: '#,#0.00' } },
                            { key: 'percentage_diff_4', width: 11.14, style: { numFmt: '0.00%' } },
                            { key: 'June', width: 18.57, style: { numFmt: '#,#0.00' } },
                            { key: 'percentage_diff_5', width: 11.14, style: { numFmt: '0.00%' } },
                            { key: 'quarter_2', width: 18.57, style: { numFmt: '#,#0.00' } },
                            { key: 'quarter_diff_1', width: 8.71, style: { numFmt: '0%' } },
                            { key: 'July', width: 18.57, style: { numFmt: '#,#0.00' } },
                            { key: 'percentage_diff_6', width: 11.14, style: { numFmt: '0.00%' } },
                            { key: 'August', width: 18.57, style: { numFmt: '#,#0.00' } },
                            { key: 'percentage_diff_7', width: 11.14, style: { numFmt: '0.00%' } },
                            { key: 'September', width: 18.57, style: { numFmt: '#,#0.00' } },
                            { key: 'percentage_diff_8', width: 11.14, style: { numFmt: '0.00%' } },
                            { key: 'quarter_3', width: 18.57, style: { numFmt: '#,#0.00' } },
                            { key: 'quarter_diff_2', width: 8.71, style: { numFmt: '0%' } },
                            { key: 'October', width: 18.57, style: { numFmt: '#,#0.00' } },
                            { key: 'percentage_diff_9', width: 11.14, style: { numFmt: '0.00%' } },
                            { key: 'November', width: 18.57, style: { numFmt: '#,#0.00' } },
                            { key: 'percentage_diff_10', width: 11.14, style: { numFmt: '0.00%' } },
                            { key: 'December', width: 18.57, style: { numFmt: '#,#0.00' } },
                            { key: 'percentage_diff_11', width: 11.14, style: { numFmt: '0.00%' } },
                            { key: 'quarter_4', width: 18.57, style: { numFmt: '#,#0.00' } },
                            { key: 'quarter_diff_3', width: 8.71, style: { numFmt: '0%' } },
                            { key: 'end_year', width: 21.57, style: { numFmt: '#,#0.00' } },
                        ];

                        let headerRow = worksheet.getRow(4);
                        headers.forEach((header, index) => {
                            let cell = headerRow.getCell(index+1);
                            cell.value = header;
                        })

                        worksheet.getRow(3).eachCell({ includeEmpty: true }, function (cell, rowNumber) {
                            cell.font = {  name: 'Trebuchet MS', size: 10, bold: true }
                            cell.alignment = { vertical: 'middle', horizontal: 'center' }
                            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFC000' } }
                        })

                        if ([1,3].includes(this.filters.statement)) {
                            worksheet.addRow([
                                'Statement of Financial Position',
                                ...Array(32).fill('')
                            ])
                        }
                        if (this.filters.statement == 2) {
                            worksheet.addRow([
                                'Statement of Financial Performance',
                                ...Array(32).fill('')
                            ])
                        }

                        worksheet.getRow(4).eachCell({ includeEmpty: true }, function (cell, rowNumber) {
                            cell.font = {  name: 'Trebuchet MS', size: 10, bold: true, color: { argb: '002060' } }
                            cell.alignment = { vertical: 'middle', horizontal: 'right' }
                            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFC000' } }
                        })

                        worksheet.getRow(5).eachCell({ includeEmpty: true }, function (cell, rowNumber) {
                            cell.font = {  name: 'Trebuchet MS', size: 10, bold: true, color: { argb: '002060' } }
                            cell.alignment = { vertical: 'middle', horizontal: rowNumber == 1 ? 'left' : 'right' }
                            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFE699' } }
                        })

                        new Promise((resolve, reject) => {
                            let fs_data = [];
                            if (this.filters.statement == 1) fs_data = this.exportData.filter(e=> [1,2,3].includes(e.id))
                            if (this.filters.statement == 2) fs_data = this.exportData.filter(e=> [4,5].includes(e.id))
                            if (this.filters.statement == 3) fs_data = this.exportData
                            fs_data.forEach((fundamental, index) => {
                                if (index > 0) {
                                    worksheet.addRow([])
                                }

                                // Fundamentals
                                worksheet.addRow([
                                    fundamental.name.toUpperCase()
                                ])

                                let fundamental_row = worksheet.lastRow.number

                                worksheet.lastRow.getCell(1).font = {  name: 'Trebuchet MS', size: 10, bold: true, underline: true, color: { argb: '002060' } }
                                worksheet.lastRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' }

                                    let account_types = fundamental.children.map(type => type.account_type).filter((value, index, array) => {
                                        return array.indexOf(value) === index
                                    })

                                    account_types.forEach((e, i) => {
                                        if (i > 0) {
                                            worksheet.addRow([])
                                        }

                                        worksheet.addRow([
                                            e
                                        ])

                                        worksheet.lastRow.getCell(1).font = {  name: 'Trebuchet MS', size: 10, bold: true, color: { argb: '002060' } }
                                        worksheet.lastRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' }

                                        let account_groups = fundamental.children.filter(f => f.account_type === e).map(m => m.account_group).filter((value, index, array) => {
                                            return array.indexOf(value) === index &&
                                                value != null
                                        })

                                        let fundamental_coa = fundamental.children.filter(f => f.account_type === e && f.children.length == 0 && f.parent_account == null).map(m => m.account_type).filter((value, index, array) => {
                                            return array.indexOf(value) === index && value != null
                                        })

                                        // fundamental level coas
                                        fundamental_coa.forEach(e => {
                                            let higher_coas = fundamental.children.filter(f => f.account_type === e && !f.account_group)
                                            let coaCount = higher_coas.length
                                            let currentRow = worksheet.lastRow.number

                                            worksheet.addRow(this.matchingGroupRow(worksheet,e,coaCount));

                                            for (i = 1; i <= 32; i++) {
                                                let letter = this.numberToColumnLetter(i+1)
                                                worksheet.getCell(letter+currentRow).font = { name: 'Trebuchet MS', size: 9, bold: true, color: { argb: '002060' } }
                                            }
                                            worksheet.lastRow.getCell(1).font = {  name: 'Trebuchet MS', size: 10, bold: true }
                                            worksheet.lastRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' }

                                            higher_coas.forEach(e => {
                                                let coaData = data[e.id] || 0;
                                                let previousCoaData = previousYear[e.id] || 0;
                                                let currentRow = worksheet.lastRow.number

                                                worksheet.addRow(this.matchingCoaRow(worksheet,coaData,previousCoaData,e));

                                                for (i = 1; i <= 32; i++) {
                                                    let letter = this.numberToColumnLetter(i+1)
                                                    worksheet.getCell(letter+currentRow).font = { name: 'Trebuchet MS', size: 10, color: { argb: '000000' } }
                                                }
                                                worksheet.lastRow.getCell(1).font = { name: 'Trebuchet MS', size: 10, color: { argb: '000000' } }

                                            })
                                        })

                                        if (fundamental.id === 3) {
                                            if (fundamental.children.length) {
                                                worksheet.addRow(['']);
                                            }
                                        }

                                        // coas with account groups
                                        account_groups.forEach(e => {
                                            let coas_with_account_group = fundamental.children.filter(f => f.account_group === e)
                                            let coaCount = coas_with_account_group.length
                                            let currentRow = worksheet.lastRow.number+1

                                            worksheet.addRow(this.matchingGroupRow(worksheet,e,coaCount));

                                            for (i = 1; i <= 32; i++) {
                                                let letter = this.numberToColumnLetter(i+1)
                                                worksheet.getCell(letter+currentRow).font = { name: 'Trebuchet MS', size: 9, bold: true, color: { argb: '002060' } }
                                            }
                                            worksheet.lastRow.getCell(1).font = {  name: 'Trebuchet MS', size: 10, bold: true }
                                            worksheet.lastRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' }

                                            coas_with_account_group.forEach(e => {
                                                let coaData = data[e.id] || 0;
                                                let previousCoaData = previousYear[e.id] || 0;
                                                let currentRow = worksheet.lastRow.number+1

                                                worksheet.addRow(this.matchingCoaRow(worksheet,coaData,previousCoaData,e));

                                                for (i = 1; i <= 32; i++) {
                                                    let letter = this.numberToColumnLetter(i+1)
                                                    worksheet.getCell(letter+currentRow).font = {  name: 'Trebuchet MS', size: 10, color: { argb: '808080' } }
                                                }
                                                worksheet.lastRow.getCell(1).font = {  name: 'Trebuchet MS', size: 10, color: { argb: '808080' } }
                                                worksheet.lastRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'left', indent: 1 }
                                            })
                                        })

                                        // coas without account groups
                                        fundamental.children.filter(f => f.account_type === e && f.children.length > 0).forEach(e => {
                                            let coaCount = e.children.length
                                            let currentRow = worksheet.lastRow.number+1

                                            worksheet.addRow(this.matchingGroupRow(worksheet,e.name,coaCount));

                                            for (i = 1; i <= 32; i++) {
                                                let letter = this.numberToColumnLetter(i+1)
                                                worksheet.getCell(letter+currentRow).font = { name: 'Trebuchet MS', size: 9, bold: true, color: { argb: '002060' } }
                                            }
                                            worksheet.lastRow.getCell(1).font = {  name: 'Trebuchet MS', size: 10, bold: true }
                                            worksheet.lastRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' }

                                            e.children.forEach(c => {
                                                let coaData = data[c.id] || 0;
                                                let previousCoaData = previousYear[c.id] || 0;
                                                let currentRow = worksheet.lastRow.number+1

                                                worksheet.addRow(this.matchingCoaRow(worksheet,coaData,previousCoaData,c));

                                                for (i = 1; i <= 32; i++) {
                                                    let letter = this.numberToColumnLetter(i+1)
                                                    worksheet.getCell(letter+currentRow).font = { name: 'Trebuchet MS', size: 10, color: { argb: '808080' } }
                                                }
                                                worksheet.lastRow.getCell(1).font = { name: 'Trebuchet MS', size: 10, color: { argb: '808080' } }
                                                worksheet.lastRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'left', indent: 1 }
                                            });
                                        })

                                        // Statement of Financial Performance
                                        if (this.filters.statement === 3) {
                                            let equity_last_row = fundamental.children.filter(f => f.fundamental_id == 3)
                                            if (equity_last_row.length > 0) {
                                                worksheet.addRow(
                                                    [
                                                        'Statement of Financial Performance',
                                                        ...Array(32).fill('')
                                                    ]
                                                );
                                                worksheet.lastRow.getCell(1).font = {  name: 'Trebuchet MS', size: 10, bold: true, color: { argb: '002060' } }
                                                worksheet.lastRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'left',}

                                                worksheet.lastRow.eachCell({ includeEmpty: true }, function (cell, colNumber) {
                                                    cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFE699' } };
                                                });
                                            }
                                        }
                                    })

                                this.matchingFundamentalRow(worksheet, fundamental_row)
                            })
                            resolve(worksheet)
                        })
                        if ([1,3].includes(this.filters.statement)) {
                            this.matchingFinancial(worksheet)
                        }
                        this.applyFill(worksheet, 'B', 'FFC000');
                        this.applyFill(worksheet, 'H', 'FFF6DD');
                        this.applyFill(worksheet, 'O', 'FFF6DD');
                        this.applyFill(worksheet, 'P', 'FFF6DD');
                        this.applyFill(worksheet, 'W', 'FFF6DD');
                        this.applyFill(worksheet, 'X', 'FFF6DD');
                        this.applyFill(worksheet, 'AE', 'FFF6DD');
                        this.applyFill(worksheet, 'AF', 'FFF6DD');
                        this.applyFill(worksheet, 'AG', 'FFC000');
                })
                const buffer = await workbook.xlsx.writeBuffer();
                const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'FS.xlsx';

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(link.href);
            } finally {
                setTimeout(()=>{
                    this.exportLoading = false
                }, 1500)
            }
        },
        matchingCoaRow(worksheet,coaData,previousCoaData,val){
            let currentRow = worksheet.lastRow.number+1

            let percentageDiff1Formula = `IFERROR((D${currentRow}/C${currentRow})-1, 0)`;
            let percentageDiff2Formula = `IFERROR((F${currentRow}/D${currentRow})-1, 0)`;
            let quarterFormula1 = `=F${currentRow}`
            let percentageDiff3Formula = `IFERROR((I${currentRow}/F${currentRow})-1, 0)`;
            let percentageDiff4Formula = `IFERROR((K${currentRow}/I${currentRow})-1, 0)`;
            let percentageDiff5Formula = `IFERROR((M${currentRow}/K${currentRow})-1, 0)`;
            let quarterFormula2 = `=M${currentRow}`
            let quarterDiffFormula1 = `IFERROR((O${currentRow}/H${currentRow})-1, 0)`;
            let percentageDiff6Formula = `IFERROR((Q${currentRow}/M${currentRow})-1, 0)`;
            let percentageDiff7Formula = `IFERROR((S${currentRow}/Q${currentRow})-1, 0)`;
            let percentageDiff8Formula = `IFERROR((U${currentRow}/S${currentRow})-1, 0)`;
            let quarterFormula3 = `=U${currentRow}`
            let quarterDiffFormula2 = `IFERROR((W${currentRow}/O${currentRow})-1, 0)`;
            let percentageDiff9Formula = `IFERROR((Y${currentRow}/U${currentRow})-1, 0)`;
            let percentageDiff10Formula = `IFERROR((AA${currentRow}/Y${currentRow})-1, 0)`;
            let percentageDiff11Formula = `IFERROR((AC${currentRow}/AA${currentRow})-1, 0)`;
            let quarterFormula4 = `=AC${currentRow}`
            let quarterDiffFormula3 = `IFERROR((AE${currentRow}/W${currentRow})-1, 0)`;
            let endYear = `=AC${currentRow}`

            let matchingCoaRow = {
                name: val.name,
                previousYear: previousCoaData ? previousCoaData: 0,
                January: { formula: `B${currentRow}+(${coaData.January ?? 0})`},
                February: { formula: `C${currentRow}+(${coaData.February ?? 0})`},
                percentage_diff_1: { formula: percentageDiff1Formula },
                March: { formula: `D${currentRow}+(${coaData.March ?? 0})`},
                percentage_diff_2: { formula: percentageDiff2Formula },
                quarter_1: { formula: quarterFormula1 },
                April: { formula: `F${currentRow}+(${coaData.April ?? 0})`},
                percentage_diff_3: { formula: percentageDiff3Formula },
                May: { formula: `I${currentRow}+(${coaData.May ?? 0})`},
                percentage_diff_4: { formula: percentageDiff4Formula },
                June: { formula: `K${currentRow}+(${coaData.June ?? 0})`},
                percentage_diff_5: { formula: percentageDiff5Formula },
                quarter_2: { formula: quarterFormula2 },
                quarter_diff_1: { formula: quarterDiffFormula1 },
                July: { formula: `M${currentRow}+(${coaData.July ?? 0})`},
                percentage_diff_6: { formula: percentageDiff6Formula },
                August: { formula: `Q${currentRow}+(${coaData.August ?? 0})`},
                percentage_diff_7: { formula: percentageDiff7Formula },
                September: { formula: `S${currentRow}+(${coaData.September ?? 0})`},
                percentage_diff_8: { formula: percentageDiff8Formula },
                quarter_3: { formula: quarterFormula3 },
                quarter_diff_2: { formula: quarterDiffFormula2 },
                October: { formula: `U${currentRow}+(${coaData.October ?? 0})`},
                percentage_diff_9: { formula: percentageDiff9Formula },
                November: { formula: `Y${currentRow}+(${coaData.November ?? 0})`},
                percentage_diff_10: { formula: percentageDiff10Formula },
                December: { formula: `AA${currentRow}+(${coaData.December ?? 0})`},
                percentage_diff_11: { formula: percentageDiff11Formula },
                quarter_4: { formula: quarterFormula4 },
                quarter_diff_3: { formula: quarterDiffFormula3 },
                end_year: { formula: endYear }
            }
            return matchingCoaRow;
        },
        matchingGroupRow(worksheet,val,coaCount){
            let currentRowNum = worksheet.lastRow.number+1
            let percentageDiff1Formula = `IFERROR((D${currentRowNum}/C${currentRowNum})-1, 0)`;
            let percentageDiff2Formula = `IFERROR((F${currentRowNum}/D${currentRowNum})-1, 0)`;
            let quarterFormula1 = `=F${currentRowNum}`
            let percentageDiff3Formula = `IFERROR((I${currentRowNum}/F${currentRowNum})-1, 0)`;
            let percentageDiff4Formula = `IFERROR((K${currentRowNum}/I${currentRowNum})-1, 0)`;
            let percentageDiff5Formula = `IFERROR((M${currentRowNum}/K${currentRowNum})-1, 0)`;
            let quarterFormula2 = `=M${currentRowNum}`
            let quarterDiffFormula1 = `IFERROR((O${currentRowNum}/H${currentRowNum})-1, 0)`;
            let percentageDiff6Formula = `IFERROR((Q${currentRowNum}/M${currentRowNum})-1, 0)`;
            let percentageDiff7Formula = `IFERROR((S${currentRowNum}/Q${currentRowNum})-1, 0)`;
            let percentageDiff8Formula = `IFERROR((U${currentRowNum}/S${currentRowNum})-1, 0)`;
            let quarterFormula3 = `=U${currentRowNum}`
            let quarterDiffFormula2 = `IFERROR((W${currentRowNum}/O${currentRowNum})-1, 0)`;
            let percentageDiff9Formula = `IFERROR((Y${currentRowNum}/U${currentRowNum})-1, 0)`;
            let percentageDiff10Formula = `IFERROR((AA${currentRowNum}/Y${currentRowNum})-1, 0)`;
            let percentageDiff11Formula = `IFERROR((AC${currentRowNum}/AA${currentRowNum})-1, 0)`;
            let quarterFormula4 = `=AC${currentRowNum}`
            let quarterDiffFormula3 = `IFERROR((AE${currentRowNum}/W${currentRowNum})-1, 0)`;
            let endYear = `=AC${currentRowNum}`

            let currentRow = worksheet.lastRow.number + 2
            coaCount = coaCount - 1

            this.groupSum.previousYear.push(`B${currentRow-1}`)
            this.groupSum.January.push(`C${currentRow-1}`)
            this.groupSum.February.push(`D${currentRow-1}`)
            this.groupSum.March.push(`F${currentRow-1}`)
            this.groupSum.April.push(`I${currentRow-1}`)
            this.groupSum.May.push(`K${currentRow-1}`)
            this.groupSum.June.push(`M${currentRow-1}`)
            this.groupSum.July.push(`Q${currentRow-1}`)
            this.groupSum.August.push(`S${currentRow-1}`)
            this.groupSum.September.push(`U${currentRow-1}`)
            this.groupSum.October.push(`Y${currentRow-1}`)
            this.groupSum.November.push(`AA${currentRow-1}`)
            this.groupSum.December.push(`AC${currentRow-1}`)
            this.groupSum.end_year.push(`AG${currentRow-1}`)

            this.groupSum.percentage_diff_1.push(percentageDiff1Formula)
            this.groupSum.percentage_diff_2.push(percentageDiff2Formula)
            this.groupSum.percentage_diff_3.push(percentageDiff3Formula)
            this.groupSum.percentage_diff_4.push(percentageDiff4Formula)
            this.groupSum.percentage_diff_5.push(percentageDiff5Formula)
            this.groupSum.percentage_diff_6.push(percentageDiff6Formula)
            this.groupSum.percentage_diff_7.push(percentageDiff7Formula)
            this.groupSum.percentage_diff_8.push(percentageDiff8Formula)
            this.groupSum.percentage_diff_9.push(percentageDiff9Formula)
            this.groupSum.percentage_diff_10.push(percentageDiff10Formula)
            this.groupSum.percentage_diff_11.push(percentageDiff11Formula)

            this.groupSum.quarter_1.push(`H${currentRow-1}`)
            this.groupSum.quarter_2.push(`O${currentRow-1}`)
            this.groupSum.quarter_3.push(`W${currentRow-1}`)
            this.groupSum.quarter_4.push(`AE${currentRow-1}`)

            this.groupSum.quarter_diff_1.push(quarterFormula1)
            this.groupSum.quarter_diff_2.push(quarterFormula2)
            this.groupSum.quarter_diff_3.push(quarterFormula3)

            let coaData = {
                previousYear: { formula: `SUM(B${currentRow}:B${currentRow+coaCount})`},
                January: { formula: `SUM(C${currentRow}:C${currentRow+coaCount})`},
                February: { formula: `SUM(D${currentRow}:D${currentRow+coaCount})` },
                March: { formula: `SUM(F${currentRow}:F${currentRow+coaCount})` },
                quarter1: { formula: `SUM(H${currentRow}:H${currentRow+coaCount})` },
                April: { formula: `SUM(I${currentRow}:I${currentRow+coaCount})` },
                May: { formula: `SUM(K${currentRow}:K${currentRow+coaCount})` },
                June: { formula: `SUM(M${currentRow}:M${currentRow+coaCount})` },
                quarter2: { formula: `SUM(O${currentRow}:O${currentRow+coaCount})` },
                quarter1_2: { formula: `SUM(P${currentRow}:P${currentRow+coaCount})` },
                July: { formula: `SUM(Q${currentRow}:Q${currentRow+coaCount})` },
                August: { formula: `SUM(S${currentRow}:S${currentRow+coaCount})` },
                September: { formula: `SUM(U${currentRow}:U${currentRow+coaCount})` },
                quarter3: { formula: `SUM(W${currentRow}:W${currentRow+coaCount})` },
                quarter2_3: { formula: `SUM(X${currentRow}:X${currentRow+coaCount})` },
                October: { formula: `SUM(Y${currentRow}:Y${currentRow+coaCount})` },
                November: { formula: `SUM(AA${currentRow}:AA${currentRow+coaCount})` },
                December: { formula: `SUM(AC${currentRow}:AC${currentRow+coaCount})` },
                quarter4: { formula: `SUM(AE${currentRow}:AE${currentRow+coaCount})` },
                quarter3_4: { formula: `SUM(AF${currentRow}:AF${currentRow+coaCount})` },
                end_year: { formula: `SUM(AG${currentRow}:AG${currentRow+coaCount})` },
            }

            let matchingGroupRow = {
                name: val,
                previousYear: coaData.previousYear ? coaData.previousYear : 0,
                January: coaData.January ? coaData.January : coaData.previousYear,
                February: coaData.February ? coaData.February : coaData.January,
                percentage_diff_1: { formula: percentageDiff1Formula },
                March: coaData.March ? coaData.March : coaData.February,
                percentage_diff_2: { formula: percentageDiff2Formula },
                quarter_1: coaData.quarter1 ? coaData.quarter1 : '-',
                April: coaData.April ? coaData.April : coaData.March,
                percentage_diff_3: { formula: percentageDiff3Formula },
                May: coaData.May ? coaData.May : coaData.April,
                percentage_diff_4: { formula: percentageDiff4Formula },
                June: coaData.June ? coaData.June : coaData.May,
                percentage_diff_5: { formula: percentageDiff5Formula },
                quarter_2: coaData.quarter2 ? coaData.quarter2 : '-',
                quarter_diff_1: coaData.quarter1_2 ? coaData.quarter1_2 : 0,
                July: coaData.July ? coaData.July : coaData.June,
                percentage_diff_6: { formula: percentageDiff6Formula },
                August: coaData.August ? coaData.August : coaData.July,
                percentage_diff_7: { formula: percentageDiff7Formula },
                September: coaData.September ? coaData.September : coaData.August,
                percentage_diff_8: { formula: percentageDiff8Formula },
                quarter_3: coaData.quarter3 ? coaData.quarter3 : '-',
                quarter_diff_2: coaData.quarter2_3 ? coaData.quarter2_3 : 0,
                October: coaData.October ? coaData.October : coaData.September,
                percentage_diff_9: { formula: percentageDiff9Formula },
                November: coaData.November ? coaData.November : coaData.October,
                percentage_diff_10: { formula: percentageDiff10Formula },
                December: coaData.December ? coaData.December : coaData.November,
                percentage_diff_11: { formula: percentageDiff11Formula },
                quarter_4: coaData.quarter4 ? coaData.quarter4 : '-',
                quarter_diff_3: coaData.quarter3_4 ? coaData.quarter3_4 : 0,
                end_year: coaData.end_year ? coaData.end_year : '-'
            }
            return matchingGroupRow;
        },
        matchingFundamentalRow(worksheet, fundamental_row){
            worksheet.getCell(`B${fundamental_row}`).value = { formula: `SUM(${this.groupSum.previousYear.join(',')})` }
            worksheet.getCell(`C${fundamental_row}`).value = { formula: `SUM(${this.groupSum.January.join(',')})` }
            worksheet.getCell(`D${fundamental_row}`).value = { formula: `SUM(${this.groupSum.February.join(',')})` }
            worksheet.getCell(`F${fundamental_row}`).value = { formula: `SUM(${this.groupSum.March.join(',')})` }
            worksheet.getCell(`H${fundamental_row}`).value = { formula: `SUM(${this.groupSum.quarter_1.join(',')})` }
            worksheet.getCell(`I${fundamental_row}`).value = { formula: `SUM(${this.groupSum.April.join(',')})` }
            worksheet.getCell(`K${fundamental_row}`).value = { formula: `SUM(${this.groupSum.May.join(',')})` }
            worksheet.getCell(`M${fundamental_row}`).value = { formula: `SUM(${this.groupSum.June.join(',')})` }
            worksheet.getCell(`O${fundamental_row}`).value = { formula: `SUM(${this.groupSum.quarter_2.join(',')})` }
            worksheet.getCell(`Q${fundamental_row}`).value = { formula: `SUM(${this.groupSum.July.join(',')})` }
            worksheet.getCell(`S${fundamental_row}`).value = { formula: `SUM(${this.groupSum.August.join(',')})` }
            worksheet.getCell(`U${fundamental_row}`).value = { formula: `SUM(${this.groupSum.September.join(',')})` }
            worksheet.getCell(`W${fundamental_row}`).value = { formula: `SUM(${this.groupSum.quarter_3.join(',')})` }
            worksheet.getCell(`Y${fundamental_row}`).value = { formula: `SUM(${this.groupSum.October.join(',')})` }
            worksheet.getCell(`AA${fundamental_row}`).value = { formula: `SUM(${this.groupSum.November.join(',')})` }
            worksheet.getCell(`AC${fundamental_row}`).value = { formula: `SUM(${this.groupSum.December.join(',')})` }
            worksheet.getCell(`AE${fundamental_row}`).value = { formula: `SUM(${this.groupSum.quarter_4.join(',')})` }
            worksheet.getCell(`AG${fundamental_row}`).value = { formula: `SUM(${this.groupSum.end_year.join(',')})` }

            this.monthSum.previousYear.push(`B${fundamental_row}`)
            this.monthSum.January.push(`C${fundamental_row}`)
            this.monthSum.February.push(`D${fundamental_row}`)
            this.monthSum.March.push(`F${fundamental_row}`)
            this.monthSum.April.push(`I${fundamental_row}`)
            this.monthSum.May.push(`K${fundamental_row}`)
            this.monthSum.June.push(`M${fundamental_row}`)
            this.monthSum.July.push(`Q${fundamental_row}`)
            this.monthSum.August.push(`S${fundamental_row}`)
            this.monthSum.September.push(`U${fundamental_row}`)
            this.monthSum.October.push(`Y${fundamental_row}`)
            this.monthSum.November.push(`AA${fundamental_row}`)
            this.monthSum.December.push(`AC${fundamental_row}`)
            this.monthSum.end_year.push(`AG${fundamental_row}`)

            this.monthSum.quarter_1.push(`H${fundamental_row}`)
            this.monthSum.quarter_2.push(`O${fundamental_row}`)
            this.monthSum.quarter_3.push(`W${fundamental_row}`)
            this.monthSum.quarter_4.push(`AE${fundamental_row}`)

            let percentageDiff1Formula = `IFERROR((D${fundamental_row}/C${fundamental_row})-1, 0)`;
            let percentageDiff2Formula = `IFERROR((F${fundamental_row}/D${fundamental_row})-1, 0)`;
            let quarterFormula1 = `=F${fundamental_row}`
            let percentageDiff3Formula = `IFERROR((I${fundamental_row}/F${fundamental_row})-1, 0)`;
            let percentageDiff4Formula = `IFERROR((K${fundamental_row}/I${fundamental_row})-1, 0)`;
            let percentageDiff5Formula = `IFERROR((M${fundamental_row}/K${fundamental_row})-1, 0)`;
            let quarterFormula2 = `=M${fundamental_row}`
            let quarterDiffFormula1 = `IFERROR((O${fundamental_row}/H${fundamental_row})-1, 0)`;
            let percentageDiff6Formula = `IFERROR((Q${fundamental_row}/M${fundamental_row})-1, 0)`;
            let percentageDiff7Formula = `IFERROR((S${fundamental_row}/Q${fundamental_row})-1, 0)`;
            let percentageDiff8Formula = `IFERROR((U${fundamental_row}/S${fundamental_row})-1, 0)`;
            let quarterFormula3 = `=U${fundamental_row}`
            let quarterDiffFormula2 = `IFERROR((W${fundamental_row}/O${fundamental_row})-1, 0)`;
            let percentageDiff9Formula = `IFERROR((Y${fundamental_row}/U${fundamental_row})-1, 0)`;
            let percentageDiff10Formula = `IFERROR((AA${fundamental_row}/Y${fundamental_row})-1, 0)`;
            let percentageDiff11Formula = `IFERROR((AC${fundamental_row}/AA${fundamental_row})-1, 0)`;
            let quarterFormula4 = `=AC${fundamental_row}`
            let quarterDiffFormula3 = `IFERROR((AE${fundamental_row}/W${fundamental_row})-1, 0)`;

            worksheet.getCell(`E${fundamental_row}`).value = { formula: percentageDiff1Formula }
            worksheet.getCell(`G${fundamental_row}`).value = { formula: percentageDiff2Formula }
            worksheet.getCell(`J${fundamental_row}`).value = { formula: percentageDiff3Formula }
            worksheet.getCell(`L${fundamental_row}`).value = { formula: percentageDiff4Formula }
            worksheet.getCell(`N${fundamental_row}`).value = { formula: percentageDiff5Formula }
            worksheet.getCell(`R${fundamental_row}`).value = { formula: percentageDiff6Formula }
            worksheet.getCell(`T${fundamental_row}`).value = { formula: percentageDiff7Formula }
            worksheet.getCell(`V${fundamental_row}`).value = { formula: percentageDiff8Formula }
            worksheet.getCell(`Z${fundamental_row}`).value = { formula: percentageDiff9Formula }
            worksheet.getCell(`AB${fundamental_row}`).value = { formula: percentageDiff10Formula }
            worksheet.getCell(`AD${fundamental_row}`).value = { formula: percentageDiff11Formula }

            worksheet.getCell(`P${fundamental_row}`).value = { formula: quarterDiffFormula1 }
            worksheet.getCell(`X${fundamental_row}`).value = { formula: quarterDiffFormula2 }
            worksheet.getCell(`AF${fundamental_row}`).value = { formula: quarterDiffFormula3 }

            Object.keys(this.groupSum).forEach(element => {
                this.groupSum[element] = []
            });
        },
        matchingFinancial(worksheet){
            Object.keys(this.monthSum).forEach(monthKey => {
                let asset = this.monthSum[monthKey][0];
                let liability = this.monthSum[monthKey][1];
                let equity = this.monthSum[monthKey][2];

                let row = worksheet.getRow(5);
                let cell = row.getCell(monthKey);

                if (cell) {
                    cell.value = { formula: `${asset}-SUM(${liability},${equity})` };
                }
            });
        },
        applyFill(worksheet, column, fillColor) {
            let startRow = 6
            let endRow = worksheet.rowCount
            for (let row = startRow; row <= endRow; row++) {
                let cellAddress = column + row;
                let cell = worksheet.getCell(cellAddress);

                cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: fillColor } };
            }
        },
        numberToColumnLetter(number) {
            let columnLetter = '';
            while (number > 0) {
                const remainder = (number - 1) % 26;
                columnLetter = String.fromCharCode(65 + remainder) + columnLetter;
                number = Math.floor((number - 1) / 26);
            }
            return columnLetter;
        }
    },
    watch:{
        'filters.company_id': {
            handler(val) {
                this.$store.commit('PV_STORE_BRANCHES_SELECTION', [])
                this.$store.dispatch('getPaymentVoucherBranchesSelection', {company_id:val});
            },
            deep: true
        },
    },
}
</script>
