    <template>

    <div class="container">

        <ComponentHeader
            :button="true"
            :title="'Customer Pending Orders By Items'"
            :type="'Sales'"
        ></ComponentHeader>
        <div class="row">
        <div class="container bg-bluish-gray default-container m-3">
            <v-text-field v-if="!loaded" color="info" loading disabled></v-text-field>
        <v-app id="my_vue_app" class="bg-bluish-gray">
            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <v-autocomplete
                        dense
                        outlined
                        :items="GET_CUSTOMERS_SELECTION"
                        :loading="loading.customer_selection"
                        :disabled="loading.customer_selection"
                        v-model="customer_id"
                        items-value="value"
                        items-text="text"
                        label="Select Customer"
                        background-color="white"
                        class="d-inline-block small w-100"
                    >
                    </v-autocomplete>
                </div>
                <div class="col-sm-12 col-md-3">
                    <v-autocomplete
                        dense
                        outlined
                        :items="GET_SALESMAN_SELECTION"
                        :loading="loading.salesman_selection"
                        :disabled="loading.salesman_selection"
                        v-model="salesman_id"
                        items-value="value"
                        items-text="text"
                        label="Select Salesman"
                        background-color="white"
                        class="d-inline-block small w-100"
                    >
                    </v-autocomplete>
                </div>
                <div class="col-sm-12 col-md-3 text-right">
                    <v-btn small v-on:click="resetFields()">Clear</v-btn>
                    <v-btn small class="ml-2" v-on:click="ExportCustomersWithPendingOrdersBySOItems()">Export</v-btn>
                    <v-btn small class="ml-2" v-on:click="getCustomersWithPendingOrdersBySOItems()">Filter</v-btn>
                </div>
            </div>
            <div class="row">
                <!-- <div class="col-sm-12 col-md-3"> -->
                    <!-- <label for="brand_id" class="float-left control-label col-form-label">Brand</label>
                    <v-autocomplete
                        dense
                        outlined
                        :items="brand_list"
                        v-model="brand_id"
                        items-value="id"
                        items-text="name"
                        placeholder="Select Brand"
                        class="d-inline-block small"
                    >
                    </v-autocomplete>
                </div> -->
                <div class="col-sm-6 col-md-2" id="date_from">
                    <label class="float-left control-label col-form-label">Date From</label>
                    <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="date_from"
                                readonly
                                append-icon="mdi-calendar-clock"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="date_from" @input="menu2 = false"></v-date-picker>
                    </v-menu>
                    <span v-for="errors in errors.date_from" class="text-warning" v-bind:key="errors">{{errors}}</span>
                </div>
                <div class="col-sm-6 col-md-2" id="date_to">
                    <label class="float-left control-label col-form-label">Date To</label>
                    <v-menu
                        v-model="menu3"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="date_to"
                                readonly
                                append-icon="mdi-calendar-clock"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="date_to" @input="menu3 = false"></v-date-picker>
                    </v-menu>
                    <span v-for="errors in errors.date_to" class="text-warning" v-bind:key="errors">{{errors}}</span>
                </div>
            </div>

            <div class="w-100"  v-if="customersList.length > 0">
                <v-card v-show="loaded">

                    <v-container fluid>
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-3">
                                <div class="info-box mb-3">
                                    <span class="info-box-icon bg-warning elevation-1"><i class="fas fa-users"></i></span>
                                    <div class="info-box-content">
                                        <span class="info-box-text">Customers</span>
                                        <span class="info-box-number">{{ customer_count }}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="clearfix hidden-md-up"></div>

                        </div>

                        <v-app id="load_bar" v-if="!loaded">
                            <v-text-field color="info" loading disabled></v-text-field>
                        </v-app>

                        <div class="container"   v-for="(customer, i) in customersList" :key="i" v-show="loaded">
                            <div class="row">
                                <div class="col-12 d-flex no-block align-items-center">
                                    <h6 class="report-title">{{ customer.company_name }}</h6>
                                </div>
                            </div>

                            <v-app id="my_vue_app">
                                <v-card>
                                    <!-- <v-card-title>
                                        <v-spacer></v-spacer>
                                        <v-text-field
                                            v-model="search"
                                            label="Search"
                                            single-line
                                            hide-details
                                        >
                                        </v-text-field>
                                        <v-icon>fas fa-search</v-icon>
                                    </v-card-title>-->

                                    <v-container fluid>
                                        <v-data-table
                                            :headers="customerSOheaders"
                                            :items="customer.sales_order_items"
                                            :search="search"
                                            :items-per-page="5"
                                            show-expand
                                            class="w-100"
                                            @item-expanded="loadDetails"
                                            dense
                                        >

                                            <template v-slot:[`item.created_at`]="{ item }">
                                                <span class="text-nowrap">
                                                    {{ item.created_at | formatDate }}
                                                </span>
                                            </template>

                                            <template v-slot:expanded-item="{ headers, item }">
                                                <td :colspan="headers.length" class="p-0">
                                                    <v-simple-table dense dark >
                                                        <template v-slot:default>

                                                            <thead>
                                                                <td width="8%">SO#</td>
                                                                <td width="16%">Date Created</td>
                                                                <td width="10%" class="text-center">Qty</td>
                                                                <td width="14%" class="text-center">Served Qty</td>
                                                                <td width="14%" class="text-center">Unserved Qty</td>
                                                                <td width="18%" class="text-right">Price</td>
                                                                <td width="20%" class="text-right">Total Amount</td>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(order, i) in item.salesOrders" :key="i">
                                                                    <td>{{ order.order_num }}</td>
                                                                    <td>{{ order.created_at | formatDate }}</td>
                                                                    <td class="text-center">{{ order.item_qty }}</td>
                                                                    <td class="text-center">{{ order.quantity_served }}</td>
                                                                    <td class="text-center">{{ order.item_qty - order.quantity_served }}</td>
                                                                    <td class="text-right">{{ order.amount | currency }}</td>
                                                                    <td class="text-right">{{ order.item_total_amount | currency }}</td>
                                                                </tr>
                                                            </tbody>
                                                        </template>
                                                    </v-simple-table>
                                                </td>
                                            </template>
                                        </v-data-table>
                                    </v-container>
                                </v-card>
                            </v-app>
                        </div>
                    </v-container>
                </v-card>
            </div>

        </v-app>
    </div>
        </div>
    </div>

    </template>

    <script>
        import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
        import { mapGetters } from 'vuex';
        import swal from 'sweetalert2';

        export default {
            data(){
                return {
                    errors: [],
                    customer_id: '',
                    customers_list: [],
                    salesman_id: '',
                    salesman_list: [],
                    brand_list: [],
                    brand_id: '',
                    loaded: true,

                    date_from: this.$dayjs().startOf('month').format('YYYY-MM-DD'),
                    date_to: this.$dayjs().endOf('month').format('YYYY-MM-DD'),

                    customer_count: '',
                    customersList: [],

                    row: null,
                    menu2: false,
                    menu3: false,

                    totalSalesReport: 0,
                    options: {},
                    salesList: [],
                    warehouseList: [],
                    itemsPerPageOptions: [5, 10, 20, 50, 100],
                    itemsPerPage: 10,
                    search: '',
                    awaitingSearch: false,
                    customerSOheaders: [
                        { text: 'Model', align: 'left', value: 'model', },
                        { text: 'Name', align: 'left', value: 'name', },
                    ],
                    json_data: [],
                    json_meta: [
                        [
                            {
                                'key': 'charset',
                                'value': 'utf-8'
                            }
                        ]
                    ],

                    loading:{
                                customer_selection  : true,
                                salesman_selection  : true
                    }
                }
            },
            computed:{
                ...mapGetters([
                'GET_CLASSIFICATIONS_SELECTION',
                'GET_WAREHOUSE_LIST',
                'GET_CUSTOMERS_SELECTION',
                'GET_SALESMAN_SELECTION',
                ])
            },
            components: {
                ComponentHeader
            },
            mounted: function (){
                this.$store.dispatch('getAllcustomersSelection')
                this.$store.dispatch('getAllcustomerClassificationSelection')
                this.$store.dispatch('getWarehouse')
                this.$store.dispatch('getAllsalesmanSelection')
            },
            methods: {
                loadDetails(item){
                    // let data = {
                    //             customer_id:    item.item.customer_id,
                    //             salesman_id:    this.salesman_id,
                    //             date_from:      this.date_from,
                    //             date_to:        this.date_to,
                    //             brand_id:       this.brand_id,
                    //             expansion_url:'getItemSalesOrders/'+item.item.item_id
                    // }
                    // if(item.value){
                    //     this.$store.dispatch('salesReportExpansion',data).then(response => {
                    //         var data = response.data
                    //         item.item.salesOrders       = data.salesOrders
                    //         this.$forceUpdate()
                    //     })
                    // }
                    let data ={
                        customer_id:    item.item.customer_id,
                        salesman_id:    this.salesman_id,
                        date_from:      this.date_from,
                        date_to:        this.date_to,
                        brand_id:       this.brand_id,
                        url:'getItemSalesOrders/'+item.item.item_id
                    }
                    if(item.value){
                        this.$store.dispatch('reports',data).then(response => {
                            var data = response.data
                            item.item.salesOrders       = data.salesOrders
                            this.$forceUpdate()
                        })
                    }
                },
                getCustomersWithPendingOrdersBySOItems(){
                    this.loaded = false
                    let data = {
                            customer_id:    this.customer_id,
                            page:           this.options.page,
                            itemsPerPage:   this.options.itemsPerPage,
                            sortBy:         this.options.sortBy,
                            sortDesc:       this.options.sortDesc,
                            search:         this.search,
                            salesman_id:    this.salesman_id,
                            date_from:      this.date_from,
                            date_to:        this.date_to,
                            brand_id:       this.brand_id,
                            url:'getCustomersWithPendingOrdersBySOItems'
                    }
                    this.$store.dispatch('reports',data).then(response => {
                        let data = response.data
                        if(data.customersList == ''){
                            swal.fire("", 'No Items on the selected filter', "info");
                            this.loaded = true
                        }
                        this.customersList          = data.customersList
                        this.customer_count         = data.customersList.length
                        this.loaded                 = true
                    });
                },
                thousandSeprator(amount) {
                    if (amount !== '' || amount !== undefined || amount !== 0 || amount !== '0' || amount !== null) {
                        return amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                    } else {
                        return amount;
                    }
                },
                resetFields(){
                    this.errors                 = []
                    this.customer_id            = ''
                    this.salesman_id            = ''
                    this.confirmed              = true
                    this.unconfirmed            = false
                    this.date_from              = ''
                    this.date_to                = ''
                    this.sales_total_amount     = ''
                    this.customer_count         = ''
                    this.confirmed_count        = ''
                    this.unconfirmed_count      = ''
                    this.brand_id               = ''
                },
                ExportCustomersWithPendingOrdersBySOItems(){
                    this.loaded = false
                    let payload = {
                        salesman_id:    this.salesman_id,
                        customer_id:    this.customer_id,
                        date_from:      this.date_from,
                        date_to:        this.date_to,
                        url:            'ExportCustomersWithPendingOrdersBySOItems',
                        export:         true
                    }
                    this.$store.dispatch('reports', payload).then(response =>{
                        let data = response.data.salesOrders
                        if(data == ''){
                            swal.fire("", 'No Items on the selected filter', "info");
                            this.loaded = true
                        } else {
                            const workbook = new this.$exceljs.Workbook();
                            const worksheet = workbook.addWorksheet(`Customer Pending Orders By Items ${this.$dayjs().format('YYYY-MM-DD')}`)
                            if(data){
                                worksheet.columns = [
                                    {header:'SO #', width: 10, key:'order_num'},
                                    {header:'COMPANY', width: 40,key:'company_name'},
                                    {header:'DATE CREATED', width: 20,style: { numFmt: 'mm/dd/yyyy' },key:'created_at'},
                                    {header:'NAME',width: 40, key:'name'},
                                    {header:'MODEL', width: 20, key:'model'},
                                    {header:'PRICE', width: 10, key:'amount', style: { numFmt: '#,##0.00' }},
                                    {header:'QUANTITY',width: 10,key:'item_qty'},
                                    {header:'SERVE QUANTITY',width: 10, key:'quantity_served'},
                                    {header:'UNSERVED QUANTITY',width: 10,key:'unserved_qty'},
                                    {header:'TOTAL AMOUNT', width: 20,key:'item_total_amount', style: { numFmt: '#,##0.00' }},
                                ]
                            worksheet.getRow(`0${1}`).font = { bold: true };
                            data.forEach((e,i) =>{
                                e.unserved_qty = e.item_qty - e.quantity_served
                                worksheet.addRow(e)
                            })

                            workbook.xlsx.writeBuffer().then((data) => {
                                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                                this.$filesaver.saveAs(blob, `Customer Pending Orders By Items from ${this.date_from} to ${this.date_to}.xlsx`);
                                swal.fire("", "Export Successfully", "success");
                                this.getCustomersWithPendingOrdersBySOItems()
                            });

                            }

                        }
                    }).catch((e) => {
                            swal.fire("Error !", "", "error");
                            console.log(e);
                            this.loaded = true;
                        });
                }
            },
            watch:{
                'GET_CUSTOMERS_SELECTION':{
                handler(val){
                    this.customers_list = val
                    this.loading.customer_selection = false
                }
                },
                // 'GET_WAREHOUSE_LIST':{
                // handler(val){
                //     this.warehouse_list = val.map(e=>{return {text:e.name,value:e.id}})
                // }
                // },
                // 'GET_CLASSIFICATIONS_SELECTION':{
                // handler(val){
                //     this.classification_list = val
                // }
                // },
                'GET_SALESMAN_SELECTION':{
                handler(val){
                    this.salesman_list = val
                    this.loading.salesman_selection = false
                }
                },
            }
        };
    </script>
    <style>
        .col{
            padding: 2px !important;
        }

        .card-body{
            padding: 3px !important;
        }
    </style>
