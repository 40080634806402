<template>
    <v-form ref="form" v-model="validForm" lazy-validation>
        <v-card>
            <v-card-title class="d-flex py-2 pr-2 border-bottom">
                <span>JOB ORDER</span>
                <v-btn class="ml-auto"
                    text
                    icon
                    small
                    color="gray"
                    @click="closeDialog()"
                ><v-icon>mdi-close-circle</v-icon></v-btn>
            </v-card-title>
            <v-container fluid style="max-height: 700px; overflow: auto; overflow-x:hidden">
                <slot name="type"></slot>
                <v-row class="p-2">
                    <v-col cols="6" class="mb-2">
                        <SectionHeading :heading="'Company Details'"></SectionHeading>
                        <v-row class="m-0">
                            <v-col cols="4">
                                <v-autocomplete
                                    v-model="form.company_id"
                                    :items="GET_PV_COMPANIES_SELECTION"
                                    :readonly="disabled_view"
                                    :loading="loadingForm"
                                    auto-select-first
                                    outlined
                                    dense
                                    label="Company"
                                />
                            </v-col>
                            <v-col cols="4">
                                <v-autocomplete
                                    v-model="form.store_branch_id"
                                    :items="storeBranchDropdownItems"
                                    item-text="text"
                                    item-value="value"
                                    auto-select-first
                                    :readonly="disabled_view"
                                    :loading="loadingForm"
                                    outlined
                                    dense
                                    label="Store Branch"
                                />
                            </v-col>
                            <v-col cols="4">
                                <v-autocomplete
                                    v-model="form.department_id"
                                    :items="GET_LIST_DEPARTMENT"
                                    :disabled="disabled_view"
                                    :loading="loadingForm"
                                    auto-select-first
                                    outlined
                                    dense
                                    label="Department"
                                />
                            </v-col>                
                        </v-row>
                    </v-col>
                    <v-col cols="6" class="mb-2">
                        <SectionHeading :heading="'Requestor Details'"></SectionHeading>
                        <v-row class="m-0">
                            <v-spacer></v-spacer>
                            <v-col lg="2">
                                <v-checkbox
                                    v-model="form.billable"
                                    :true-value="1"
                                    :false-value="0"
                                    :disabled="ACTION == 'View' || loadingForm"
                                    style="margin-top: 4px"
                                    label="Billable"
                                    hide-details
                                ></v-checkbox>
                            </v-col>
                            <v-col lg="6">
                                <v-combobox
                                    v-model="form.requestor"
                                    :items="GET_CUSTOMERS_LIST_SELECTION"
                                    :disabled="ACTION == 'View' || !form.billable"
                                    label="Requestor"
                                    dense
                                    outlined
                                    hide-details
                                    auto-select-first
                                    @input="onInput"
                                    @keypress="forceUpperCaseText($event)"
                                ></v-combobox>
                            </v-col>           
                        </v-row>
                    </v-col>
                </v-row>
                <v-row  class="p-2">
                    <v-col cols="12" class="mb-2">
                        <SectionHeading :heading="'Vendor Details'"></SectionHeading>
                        <v-row class="m-0">
                            <v-col cols="3">
                                <v-autocomplete
                                    v-model="form.vendor_id"
                                    :items="GET_ADMIN_SUPPLIER_SELECTION"
                                    :readonly="disabled_view"
                                    :loading="loadingForm"
                                    auto-select-first
                                    outlined
                                    dense
                                    label="Vendor Name"
                                    @change="changeVendor"
                                />
                            </v-col>
                            <v-col cols="3">
                                <v-autocomplete
                                    v-model="form.payment_id"
                                    :items="payment_items"
                                    auto-select-first
                                    item-text="terms"
                                    item-value="id"
                                    :readonly="disabled_view"
                                    :loading="loadingForm"
                                    outlined
                                    dense
                                    label="Payment Terms"
                                />
                            </v-col>
                            <v-col cols="3">
                                <v-autocomplete
                                    v-model="form.vendor_address_id"
                                    :items="contact_person"
                                    item-text="contact_person"
                                    item-value="id"
                                    auto-select-first
                                    :readonly="disabled_view"
                                    :loading="loadingForm"
                                    outlined
                                    dense
                                    label="Contact Person"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="3">
                                <v-autocomplete
                                    v-model="form.vendor_payment_item_id"
                                    :items="payee_dropdown"
                                    item-text="name"
                                    item-value="id"
                                    auto-select-first
                                    :readonly="disabled_view"
                                    :loading="loadingForm"
                                    outlined
                                    dense
                                    label="Checks Payable to"
                                ></v-autocomplete>
                            </v-col>            
                        </v-row>
                    </v-col>
                </v-row>
                <v-row  class="p-2">
                    <v-col cols="12" class="mb-2">
                        <SectionHeading :heading="'JO Details'"></SectionHeading>
                        <v-row class="m-0">
                            <v-col cols="3" >
                                <v-text-field
                                    v-model="form.jo_number"
                                    :loading="loadingForm"
                                    :disabled="disabled_view"
                                    outlined
                                    dense
                                    label="JO #"
                                    @keypress="forceUpperCaseText($event)"
                                >
                                    <!-- <template v-slot:append v-if="ACTION === 'Submit'">
                                        <span class="mt-1" style="color: red;">-{{GET_JO_NUM}}</span>
                                    </template> -->
                                </v-text-field>
                            </v-col>
                            <v-col cols="3" >
                                <v-dialog
                                    ref="dialog"
                                    v-model="date_needed_dialog"
                                    :disabled="disabled_view"
                                    persistent
                                    width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="form.date_needed"
                                            v-bind="attrs"
                                            v-on="on"
                                            append-icon="mdi-calendar"
                                            readonly
                                            :loading="loadingForm"
                                            dense
                                            outlined
                                            label="Date Needed"
                                        />
                                    </template>
                                    <v-date-picker
                                        v-model="form.date_needed"
                                        scrollable
                                        :min="minDate"
                                        :disabled="disabled_view"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="() => {
                                                form.date_needed = '';
                                                date_needed_dialog = false;
                                            }"
                                        >clear</v-btn>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="$refs.dialog.save()"
                                        >ok</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>
                            <v-col cols="3" >
                                <v-dialog
                                    ref="dialog1"
                                    v-model="duration_dialog"
                                    :disabled="disabled_view"
                                    persistent
                                    width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="form.duration"
                                            v-bind="attrs"
                                            v-on="on"
                                            label="Duration"
                                            append-icon="mdi-calendar"
                                            readonly
                                            :loading="loadingForm"
                                            dense
                                            outlined
                                        />
                                    </template>
                                    <v-date-picker
                                        v-model="form.duration"
                                        scrollable
                                        range
                                        :min="minDate"
                                        :disabled="disabled_view"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="() => {
                                                form.duration = [];
                                                duration_dialog = false;
                                            }"
                                        >clear</v-btn>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="$refs.dialog1.save()"
                                        >ok</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>
                            <v-col cols="3" >
                                <v-autocomplete
                                    v-model="form.admin_location_id"
                                    :items="GET_ADMIN_DELIVERY_ADDRESSES"
                                    :loading="loadingForm"
                                    :readonly="disabled_view"
                                    item-text="text"
                                    item-value="value"
                                    label="Location"
                                    auto-select-first
                                    dense
                                    outlined
                                >
                                </v-autocomplete>
                            </v-col>            
                        </v-row>
                        <v-row class="m-0">
                            <v-col cols="3" >
                                <v-text-field
                                    v-model="form.client_contact_person"
                                    item-text="text"
                                    item-value="value"
                                    :readonly="disabled_view"
                                    :loading="loadingForm"
                                    outlined
                                    dense
                                    label="Client Contact Person"
                                />
                            </v-col>
                            <v-col cols="3" >
                                <v-text-field
                                    v-model="form.client_contact"
                                    item-text="text"
                                    item-value="value"
                                    :readonly="disabled_view"
                                    :loading="loadingForm"
                                    outlined
                                    dense
                                    label="Client Contact #"
                                />
                            </v-col>
                            <v-col lg="3" >
                                <v-autocomplete
                                    v-model="form.payment_method"
                                    :items="GET_JOB_ORDERS.JO_PAYMENT_METHOD"
                                    :readonly="disabled_view"
                                    :loading="loadingForm"
                                    item-value="value"
                                    item-text="text"
                                    auto-select-first
                                    outlined
                                    dense
                                    label="Payment Method"
                                >

                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="m-0">
                            <v-col cols="3" >
                                <v-autocomplete
                                    v-model="form.repair_category"
                                    :items="[...GET_ADMIN_REPAIR_CATEGORY_SELECTION, 'Other']"
                                    :readonly="disabled_view"
                                    :loading="loadingForm"
                                    auto-select-first
                                    outlined
                                    dense
                                    label="Service Type"
                                ></v-autocomplete>
                            </v-col>
                            <v-col v-if="form.repair_category == 'Other'" cols="3" >
                                <v-text-field
                                    v-model="form.other_repair_category"
                                    :readonly="disabled_view"
                                    :loading="loadingForm"
                                    outlined
                                    dense
                                    label="Specify Other Service Type"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-autocomplete
                                    v-if="form.repair_category != 'Other'"
                                    v-model="form.repair_type_id"
                                    :items="repairTypeDropdownItems"
                                    :readonly="disabled_view"
                                    :loading="loadingForm"
                                    auto-select-first
                                    outlined
                                    dense
                                    label="Service Particular"
                                    @change="() => {
                                        form.other_repair_type = undefined;
                                        form.vehicle_id = undefined;
                                    }"
                                ></v-autocomplete>
                            </v-col>
                            <v-col v-if="getRepairType()?.others == 1" cols="3">
                                <v-text-field
                                    v-model="form.other_repair_type"
                                    :readonly="disabled_view"
                                    :loading="loadingForm"
                                    outlined
                                    dense
                                    label="Specify Other Service Particular"
                                ></v-text-field>
                            </v-col>
                            <v-col v-if="form.repair_type_id == 1" cols="3">
                                <v-autocomplete
                                    v-model="form.vehicle_id"
                                    :items="GET_ADMIN_ITEMS_SELECTION"
                                    :readonly="disabled_view"
                                    :loading="loadingForm"
                                    item-value="value"
                                    item-text="text"
                                    auto-select-first
                                    outlined
                                    dense
                                    label="Vehicle"
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="m-0">
                            <v-col cols="6">
                                <v-textarea
                                    v-model="form.remarks"
                                    :readonly="disabled_view"
                                    :loading="loadingForm"
                                    :auto-grow="true"
                                    label="Remarks"
                                    dense
                                    outlined
                                    rows="1"
                                />
                            </v-col>
                            <v-col cols="3"  v-if="ACTION != 'Submit'">
                                <v-text-field
                                    :value="formatCurrencyPHPwithoutRoundingUp(parseFloat(form.total_paid_amount ?? 0))"
                                    readonly
                                    :loading="loadingForm"
                                    outlined
                                    dense
                                    label="Paid Amount"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-spacer></v-spacer>
                            <v-col lg="3">
                                <div style=" float: right;" class="pr-2">
                                    <FileUploadComponentVue
                                        v-if="this.ACTION != 'View' || this.form.status == 6"
                                        style="display:inline-block; transform: translateY(5px);"
                                        ref="fileUpload"
                                        id="attachment"
                                        @uploadedData="uploadedData"
                                        @reset="clearFileUpload"
                                    />
                                    <BaseFileViewerComponent
                                        style="padding: 0;"
                                        :access="true"
                                        :payloadId="GET_EDIT_JO.id ? GET_EDIT_JO.id : null"
                                        :payloadIds="[]"
                                        :getFilesPath="'getAdminJOFiles'"
                                        :deleteFilesPath="[1,3,8].includes(form.status) ? 'deleteAdminJOFiles' : ''"
                                        :module="'admin'"
                                        :folder="'jo_file_uploads'"
                                        :localFiles="uploadedFiles"
                                        @files="processFiles"
                                        ref="clearFiles"
                                    />
                                    <div
                                        v-if="this.ACTION != 'View' || this.form.status == 6"
                                        class="py-2 mt-5"
                                        style="display: inline-block;"
                                    >
                                        <v-layout row class="mx-0">
                                            <v-flex lg2 class="mr-2">
                                                <BaseCameraCaptureVue ref="cameraCapture" @capturedImages="getImages" />
                                            </v-flex>
                                            <v-flex v-if="!!images && images.length > 0" lg3 class="mr-2 mt-1 ">
                                                <span>Images Count: {{ images.length }}</span>
                                            </v-flex>
                                            <v-flex v-if="!!images && images.length > 0">
                                                <v-btn @click="clearCameraCapture()">Clear</v-btn>
                                            </v-flex>
                                        </v-layout>
                                    </div>
                                </div>
                            </v-col>           
                        </v-row>
                    </v-col>
                </v-row>
                <v-row  class="p-2">
                    <v-col cols="12" class="mb-2">
                        <SectionHeading :heading="'Service Details'"></SectionHeading>
                    </v-col>
                </v-row>
                <v-divider class="my-2" />
                <v-row  class="p-2">
                    <v-col cols="12" >
                        <v-card elevation="0">
                            <v-card-text>
                                <v-row>
                                    <v-col lg="12">
                                        <v-data-table
                                            :items="admin_items"
                                            disable-sort
                                            :headers="[
                                                { text:'Services', value:'item_name', width:'15%' },
                                                { text:'Quantity', value:'quantity', width:'10%' },
                                                { text:'Price', value:'original_price', width:'10%' },
                                                { text:'Service Total', value:'total_amount', width:'10%' },
                                                { text:'Materials Total', value:'material_total_amount', width:'10%' },
                                                { text:'Overall Total', value:'overall_total_amount', width:'10%' },
                                                { text:'Has Dimension', value:'has_dimension', width:'8%', align: 'center' },
                                                { text:'Action', value:'button', width:'15%', align: 'center' },
                                            ]"
                                            dense
                                        >
                                            <template v-slot:[`header.button`]="{ header }">
                                                <v-btn
                                                    color="success"
                                                    x-small
                                                    fab
                                                    v-if="ACTION != 'View'"
                                                    @click="modifyItem(1)"
                                                >
                                                    <v-icon>mdi-plus</v-icon>
                                                </v-btn>
                                                <v-btn
                                                    color="error"
                                                    x-small
                                                    fab
                                                    v-if="ACTION != 'View'"
                                                    @click="modifyItem(2)"
                                                >
                                                    <v-icon>mdi-minus</v-icon>
                                                </v-btn>
                                            </template>

                                            <template v-slot:[`item.item_name`]="{ item }">
                                                <v-combobox
                                                    v-model="item.item_name"
                                                    :items="GET_SERVICE_NAME_SUGGESTIONS"
                                                    dense
                                                    outlined
                                                    clearable
                                                    :no-filter="false"
                                                ></v-combobox>
                                                <!-- <v-text-field
                                                    v-model="item.item_name"
                                                    outlined
                                                    dense
                                                    :disabled="ACTION == 'View'"
                                                >
                                                </v-text-field> -->
                                            </template>
                                            <template v-slot:[`item.quantity`]="{ item }">
                                                <v-text-field
                                                    v-model="item.quantity"
                                                    class="centered-input"
                                                    outlined
                                                    dense
                                                    :disabled="ACTION == 'View'"
                                                    @change="updatePrice(item)"
                                                    @keypress="numberandDotOnly"

                                                >
                                                </v-text-field>
                                            </template>
                                            <template v-slot:[`item.original_price`]="{ item }">
                                                <v-text-field
                                                    v-model="item.original_price"
                                                    outlined
                                                    dense
                                                    :disabled="ACTION == 'View'"
                                                    @change="updatePrice(item)"
                                                    @keydown="decimalOnly"
                                                >
                                                </v-text-field>
                                            </template>
                                            <template v-slot:[`item.total_amount`]="{ item }">
                                                <v-text-field
                                                    :value="item.total_amount ? formatCurrencyPHPwithoutRoundingUp(item.total_amount) : formatCurrencyPHPwithoutRoundingUp('0')"
                                                    outlined
                                                    dense
                                                    readonly
                                                    background-color="#CFD8DC"
                                                    :disabled="ACTION == 'View'"
                                                >
                                                </v-text-field>
                                            </template>
                                            <template v-slot:[`item.material_total_amount`]="{ item }">
                                                <v-text-field
                                                    :value="formatCurrencyPHPwithoutRoundingUp(
                                                        item.materials.reduce(function(total, current) {
                                                            return total + parseFloat(current.total_amount);
                                                        }, 0)
                                                    )"
                                                    outlined
                                                    dense
                                                    readonly
                                                    background-color="#CFD8DC"
                                                >
                                                </v-text-field>
                                            </template>
                                            <template v-slot:[`item.overall_total_amount`]="{ item }">
                                                <v-text-field
                                                    :value="formatCurrencyPHPwithoutRoundingUp(
                                                        parseFloat(item.total_amount == '' ? 0 : item.total_amount)
                                                        +
                                                        item.materials.reduce(function(total, current) {
                                                            return total + parseFloat(current.total_amount);
                                                        }, 0)
                                                    )"
                                                    outlined
                                                    dense
                                                    readonly
                                                    background-color="#CFD8DC"
                                                >
                                                </v-text-field>
                                            </template>
                                            <template v-slot:[`item.has_dimension`]="{ item }">
                                                <div class="d-flex justify-center align-center">
                                                    <v-checkbox
                                                        v-model="item.has_dimension"
                                                        :true-value="1"
                                                        :false-value="0"
                                                        :readonly="ACTION == 'View'"
                                                    />
                                                </div>
                                            </template>
                                            <template v-slot:[`item.button`]="{ item, index }">
                                                <v-btn
                                                    color="orange"
                                                    small
                                                    icon
                                                    :disabled="!item.has_dimension"
                                                    @click="() => {
                                                        cacheDimension = JSON.parse(JSON.stringify(item));
                                                        currentItem = item;
                                                        showDimension = true;
                                                    }"
                                                >
                                                    <v-icon>
                                                        mdi-texture-box
                                                    </v-icon>
                                                </v-btn>
                                                <v-menu
                                                    open-on-hover
                                                    bottom
                                                    offset-y
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                            class="mx-2"
                                                            color="blue"
                                                            small
                                                            icon
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            @click="() => {
                                                                cacheMaterials = JSON.parse(JSON.stringify(item));
                                                                currentItem = item;
                                                                showMaterials = true;
                                                            }"
                                                        >
                                                            <v-icon>
                                                                mdi-hammer-wrench
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>

                                                    <v-simple-table dark>
                                                        <template v-slot:default>
                                                        <thead>
                                                            <tr>
                                                            <th class="text-left">
                                                                Material Name
                                                            </th>
                                                            <th class="text-left">
                                                                UOM
                                                            </th>
                                                            <th class="text-right">
                                                                Price
                                                            </th>
                                                            <th class="text-right">
                                                                QTY
                                                            </th>
                                                            <th class="text-right">
                                                                Total
                                                            </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                            v-for="(item,index) in item.materials"
                                                            :key="index"
                                                            >
                                                            <td>{{ item.name }}</td>
                                                            <td>{{
                                                                item.item_uom_id
                                                                    ? GET_ADMIN_UOM_SELECTION
                                                                        .filter(temp => temp.value == item.item_uom_id)
                                                                        .shift().text
                                                                    : ''
                                                            }}</td>
                                                            <td class="text-right">{{ formatCurrencyPHPwithoutRoundingUp(item.price ? item.price : 0) }}</td>
                                                            <td class="text-right">{{ thousandSeprator(item.quantity) }}</td>
                                                            <td class="text-right">{{ formatCurrencyPHPwithoutRoundingUp(item.total_amount) }}</td>
                                                            </tr>
                                                        </tbody>
                                                        </template>
                                                    </v-simple-table>
                                                </v-menu>
                                                <v-btn
                                                    color="error"
                                                    small
                                                    icon
                                                    :disabled="ACTION == 'View'"
                                                    @click="modifyItem(3, index)"
                                                >
                                                    <v-icon>
                                                        mdi-delete
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
            <v-divider></v-divider>
            <slot name="card-action-bound"></slot>
            <v-card-actions class="text-center pb-4 bound--">
                <v-row class="m-0">
                    <v-col class="actions-wrapper">

                        <v-btn :loading="btn_loading" v-if="ACTION == 'View'" @click="renderPDF()">Print</v-btn>
                        <v-btn
                        v-if="ACTION === 'View' && userApprover(form.status)"
                        @click="()=>{
                            action = 'jobOrderGet';
                            createOrUpdateJOForm(form.status == 6 ? undefined : 'update-job-order-status')

                        }"
                        >
                            <span>
                                {{ form.status == 1 ? 'Confirm' : '' }}

                                {{
                                    form.status == 3 || form.status == 8
                                        ? `${STATUS.ADMIN_JOB_ORDER_STATUS.find(e => e.value === form.status)?.text} Approve`
                                        : ''
                                }}

                                <!-- 50/50 -->
                                <!-- {{ form.payment_method == 1 && form.status == 8 ? 'Approve For Payment' : '' }} -->
                                {{ form.payment_method == 1 && form.status == 5 ? 'On Going' : '' }}
                                {{ form.payment_method == 1 && form.status == 6 ? 'Approve For Payment' : '' }}

                                <!-- SBP -->
                                <!-- {{ form.payment_method == 2 && form.status == 8 ? 'On Going' : '' }} -->
                                {{ form.payment_method == 2 && form.status == 6 ? 'Approve For Payment' : '' }}

                                <!-- PBS -->
                                <!-- {{ form.payment_method == 3 && form.status == 8 ? 'Approve For Payment' : '' }} -->
                                {{ form.payment_method == 3 && form.status == 4 ? 'On Going' : '' }}
                                {{ form.payment_method == 3 && form.status == 6 ? 'Completed' : '' }}
                            </span>
                        </v-btn>
                        <v-btn
                            v-if="ACTION !== 'View'"
                            @click="createOrUpdateJOForm()"
                            :disabled="disabled_view || hasExistingEntebill"
                        ><span>{{ ACTION }}</span></v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        <v-dialog v-model="showMaterials" persistent max-width="70%">
            <v-card>
                <v-card-title>
                    Materials
                    <v-btn class="ml-auto"
                        text
                        icon
                        small
                        color="gray"
                        @click="() => {
                            currentItem.materials = [...cacheMaterials.materials];
                            showMaterials = false;
                        }"
                    ><v-icon>mdi-close-circle</v-icon></v-btn>
                </v-card-title>
                <!-- <v-card-text style="max-height: 600px; overflow: auto;"> -->
                    <v-data-table
                        :headers="[
                            { text: 'Material Name', value: 'name', width: '30%' },
                            { text: 'UOM', value: 'item_uom_id', width: '15%' },
                            { text: 'Price', value: 'price', width: '15%' },
                            { text: 'Quantity', value: 'quantity', width: '15%' },
                            { text: 'Total Amount', value: 'total_amount', width: '15%' },
                            { text: 'Actions', value: 'actions', align: 'center' },
                        ]"
                        :items="currentItem.materials"
                        disable-sort
                        dense
                    >
                        <template v-slot:[`header.actions`]="{ header }">
                            <v-btn
                                color="success"
                                x-small
                                fab
                                v-if="ACTION != 'View'"
                                @click="() => {
                                    currentItem.materials.push({
                                        name: '',
                                        item_uom_id: undefined,
                                        quantity: '1',
                                        price: 0,
                                        total_amount: 0,
                                    });
                                }"
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                            <v-btn
                                class="ml-2"
                                color="error"
                                x-small
                                fab
                                v-if="ACTION != 'View'"
                                @click="() => {
                                    currentItem.materials.pop();
                                    if (currentItem.materials.length <= 0) {
                                        currentItem.materials.push({
                                            name: '',
                                            item_uom_id: undefined,
                                            quantity: '1',
                                            price: 0,
                                            total_amount: 0,
                                        });
                                    }
                                }"
                            >
                                <v-icon>mdi-minus</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:[`item.name`]="{ item,index }">
                            <v-text-field
                                v-model="item.name"
                                outlined
                                dense
                                :disabled="ACTION == 'View'"
                                @change="() => {}"
                            />
                        </template>
                        <template v-slot:[`item.item_uom_id`]="{ item,index }">
                            <v-autocomplete
                                v-model="item.item_uom_id"
                                :items="GET_ADMIN_UOM_SELECTION"
                                item-value="value"
                                item-text="text"
                                auto-select-first
                                outlined
                                dense
                                :disabled="ACTION == 'View' || item.name == ''"
                                @change="() => {}"
                            />
                        </template>
                        <template v-slot:[`item.price`]="{ item,index }">
                            <v-text-field
                                v-model="item.price"
                                outlined
                                dense
                                :disabled="ACTION == 'View' || item.name == ''"
                                reverse
                                @keydown="decimalOnly"
                                @change="() => {
                                    item.total_amount = parseFloat(item.price) * parseFloat(item.quantity);
                                }"
                            />
                        </template>
                        <template v-slot:[`item.quantity`]="{ item,index }">
                            <v-text-field
                                class="centered-input"
                                v-model="item.quantity"
                                outlined
                                dense
                                :disabled="ACTION == 'View' || item.name == ''"
                                @keypress="numberandDotOnly"
                                @change="() => {
                                    item.total_amount = parseFloat(item.price) * parseFloat(item.quantity);
                                }"
                            />
                        </template>
                        <template v-slot:[`item.total_amount`]="{ item,index }">
                            <v-text-field
                                background-color="#CFD8DC"
                                :value="item.total_amount ? formatCurrencyPHPwithoutRoundingUp(item.total_amount) : formatCurrencyPHPwithoutRoundingUp('0')"
                                outlined
                                dense
                                readonly
                                reverse
                                @change="() => {}"
                            />
                        </template>
                        <template v-slot:[`item.actions`]="{ item,index }">
                            <v-btn
                                color="error"
                                small
                                icon
                                :disabled="ACTION == 'View'"
                                @click="() => {
                                    currentItem.materials.splice(index, 1);
                                    if (currentItem.materials.length <= 0) {
                                        currentItem.materials.push(JSON.parse(JSON.stringify(defaultMaterialFormat)));
                                    }
                                }"
                            >
                                <v-icon>
                                    mdi-delete
                                </v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                <!-- </v-card-text> -->
                <v-divider />
                <v-card-actions class="justify-content-center">
                    <v-btn v-if="ACTION != 'View'" @click="() => {showMaterials = false}" >Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="showDimension" persistent max-width="50%">
            <v-card v-if="!!currentItem.dimension">
                <v-card-title>
                    Materials
                    <v-btn class="ml-auto"
                        text
                        icon
                        small
                        color="gray"
                        @click="() => {
                            currentItem.dimension = cacheDimension.dimension;
                            showDimension = false;
                        }"
                    ><v-icon>mdi-close-circle</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                    <v-row class="m-0">
                        <v-col cols="6">
                            <v-text-field
                                v-model="currentItem.dimension.length"
                                :readonly="disabled_view"
                                outlined
                                dense
                                label="Length"
                                @keydown="decimalOnly"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="currentItem.dimension.width"
                                :readonly="disabled_view"
                                outlined
                                dense
                                label="Width"
                                @keydown="decimalOnly"
                            />
                        </v-col>
                    </v-row>
                    <v-row class="m-0">
                        <v-col cols="6">
                            <v-text-field
                                v-model="currentItem.dimension.height"
                                :readonly="disabled_view"
                                outlined
                                dense
                                label="Height"
                                @keydown="decimalOnly"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="currentItem.dimension.cbm"
                                :readonly="disabled_view"
                                outlined
                                dense
                                label="CBM"
                                @keydown="decimalOnly"
                            />
                        </v-col>
                    </v-row>
                    <v-divider />
                    <v-card-actions class="justify-content-center">
                        <v-btn v-if="ACTION != 'View'" @click="() => {showDimension = false}" >Save</v-btn>
                    </v-card-actions>
                </v-card-text>
            </v-card>
        </v-dialog>
        <AdminJobOrderPrintComponentV3></AdminJobOrderPrintComponentV3>
    </v-form>
</template>

<script>
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import AdminJobOrderPrintComponentV3 from "@/views/main/layouts/PrintTemplate/AdminJobOrderPrintComponentV3.vue";
import FileUploadComponentVue from '@/views/main/Shared/FileUploadComponent.vue';
import BaseFileViewerComponent from "@/views/main/Shared/BaseFilesViewerComponent.vue";
import BaseCameraCaptureVue from "@/views/main/modules/HR/Base/BaseCameraCapture.vue";
import SectionHeading from '@/views/main/Components/SectionHeading.vue';

export default {
    mixins: [SharedFunctionsComponentVue],
    components: {
        FileUploadComponentVue,
        BaseFileViewerComponent,
        BaseCameraCaptureVue,
        AdminJobOrderPrintComponentV3,
        SectionHeading,
    },
    data() {
        return {
            showMaterials: false,
            cacheMaterials: [],

            showDimension: false,
            cacheDimension: {},

            currentItem: {},

            date_needed_dialog: false,
            disabled_view: false,
            disabled_view_nums: false,
            duration_dialog: false,
            loadingForm: false,
            validForm: true,
            btn_loading: false,

            action: 'Submit',

            form: {
                jo_number: '',
                date_needed: this.$dayjs().endOf('month').format('YYYY-MM-DD'),
                duration: [],
                client_contact_person: '',
                client_contact: '',
                vendor_id: '',
                payment_id: '',
                vendor_address_id: '',
                vendor_payment_item_id: '',
                remarks: '',
                department_id: undefined,
                admin_location_id: undefined,
                repair_category: undefined,
                other_repair_category: undefined,
                other_repair_type: undefined,
                repair_type_id: undefined,
                vehicle_id: undefined,
                company_id: undefined,
                store_branch_id: undefined,
                payment_method: undefined,
                // customer_request:0,
                requestor:'',
                billable:0
            },
            uploadedFiles: null,
            images: null,
            uploadedImages: null,
            admin_items: [],
            admin_items_dropdown: [],

            admin_job_order_items: [],
            contact_person: [],
            payment_items: [],
            userAccess: [],
            payee_dropdown: [],
            joPrintData: undefined,
            phoneRules: [
                v => (v && v.length >= 10) || 'Invalid Phone Number',
            ],
            hasExistingEntebill: false,
        }
    },
    mounted(){
        let self = this;

        document.addEventListener('keydown', this.handleKeydown);
        if(this.admin_items.length === 0){
            this.admin_items.push({
                value:'',
                item_name:'',
                quantity: '1',
                original_price: 0,
                total_amount:0,
                has_dimension:0,
                materials: [ self.defaultMats() ],
                dimension: {
                    length: 0,
                    width: 0,
                    height: 0,
                    cbm: 0,
                },
            })
        }
        this.$store.dispatch('checkAccessUser',
            this.$router.currentRoute.params.id
        );
        this.$store.dispatch('getAllCustomers')
        this.$store.dispatch('getAdminItemsSelection', { classification_name: 'COMPANY VEHICLE' });
    },

    beforeDestroy() {
        document.removeEventListener('keydown', this.handleKeydown);
    },

    computed: {
        ...mapGetters([
            "ACTION",
            "DIALOGS",
            "USER_ACCESS",
            "SELECTED",
            // "GET_RRF_NUM",
            "GET_VEHICLES",
            "GET_JO_DISPATCH",
            "GET_JO_NUM",
            "GET_ADMIN_SUPPLIER_SELECTION",
            "GET_JO_ITEMS_LOADED",
            "GET_JO_ITEMS_LIST",
            "GET_EDIT_JO",
            "GET_NEW_JO_TABLE",
            "GET_ALL_RRF_ITEMS_LIST",
            "GET_RRF_ITEMS_LOADED",
            "GET_ADMIN_UOM_SELECTION",
            "GET_VENDOR_PAYMENT_ITEM",
            'GET_ADMIN_DELIVERY_ADDRESSES',
            'GET_ADMIN_REPAIR_CATEGORY_SELECTION',
            'GET_ADMIN_REPAIR_TYPE_SELECTION',
            'GET_LIST_DEPARTMENT',
            'GET_PV_COMPANIES_SELECTION',
            'GET_PV_STORE_BRANCHES_SELECTION',
            'GET_JOB_ORDERS',
            'STATUS',
            'USERACCOUNT_LOAD',
            'GET_ADMIN_ITEMS_SELECTION',
            'GET_SERVICE_NAME_SUGGESTIONS',
            'GET_CUSTOMERS_LIST_SELECTION'
        ]),
        minDate() {
            return this.$dayjs().format('YYYY-MM-DD');
        },
        storeBranchDropdownItems() {
            if (!this.form.company_id) return this.GET_PV_STORE_BRANCHES_SELECTION;
            let items = this.GET_PV_STORE_BRANCHES_SELECTION.filter(selection => selection.company_id == this.form.company_id);
            // if (this.form.company_id) this.form.store_branch_id = items[0].value
            return items
        },
        repairTypeDropdownItems() {
            if (!this.form.repair_category) return this.GET_ADMIN_REPAIR_TYPE_SELECTION;

            let items = this.GET_ADMIN_REPAIR_TYPE_SELECTION
                .filter(selection => selection.type == this.form.repair_category);

            return items;
        },
    },
    methods: {
        getRepairType() {
            const repair_type = this.GET_ADMIN_REPAIR_TYPE_SELECTION.find(type => type.value == this.form.repair_type_id);

            return repair_type;
        },
        defaultMats(){
            return {
                name: '',
                item_uom_id: undefined,
                quantity: '1',
                price: 0,
                total_amount: 0,
            }
        },
        handleKeydown(event) {
            if (this.showMaterials) return;
            if (this.loadingForm) return;
            this.closeDialogByEsc(event, this.closeDialog);
        },
        uploadedData(data){
            if (!data) {
                this.uploadedFiles = null
            }
            this.uploadedFiles = data
        },
        clearFileUpload(confirm){
            if (confirm) {
                this.uploadedFiles = null
            }
            this.uploadedFiles = null
            this.$refs.fileUpload.reset()
        },
        async processFiles(files){
            this.uploadedFiles.attachments = []
            files.forEach(e=>{
                this.uploadedFiles.attachments.push(
                    e.localFileState
                )
            })
            let fileData = []
            for(let i = 0; i < this.uploadedFiles.attachments.length; i++) {
                const element = this.uploadedFiles.attachments[i]
                let reader = new FileReader()
                reader.readAsDataURL(element)
                await new Promise((resolve) => {
                    reader.onloadend = function () {
                        fileData.push({
                            name: element.name,
                            type: element.type,
                            data: reader.result.split(',')[1]
                        })
                        resolve()
                    }
                })
            }
            this.uploadedFiles = fileData
        },
        getImages(dataUrls) {
            this.images = dataUrls.map(dataUrl => {
                try {
                    if (!dataUrl.startsWith('data:image/jpeg;base64,')) {
                        throw new Error('Invalid data URL format');
                    }

                    const base64String = dataUrl.split(',')[1];
                    const byteString = atob(base64String);
                    const arrayBuffer = new ArrayBuffer(byteString.length);
                    const uint8Array = new Uint8Array(arrayBuffer);

                    for (let i = 0; i < byteString.length; i++) {
                        uint8Array[i] = byteString.charCodeAt(i);
                    }

                    const blob = new Blob([uint8Array], { type: 'image/jpeg' });
                    const timestamp = new Date().toISOString().replace(/[:.-]/g, '');
                    const filename = `captured-image-${timestamp}.jpg`;
                    const file = new File([blob], filename, { type: 'image/jpeg' });
                    return file;
                } catch (error) {
                    console.error('Failed to convert base64 to file:', error);
                    return null;
                }
            }).filter(file => file !== null);

            this.uploadedImages = []
            this.images.forEach(e=>{
                this.uploadedImages.push(e)
            })
            let fileData = []
            for (let i = 0; i < this.uploadedImages.length; i++) {
                const element = this.uploadedImages[i]
                let reader = new FileReader()
                reader.readAsDataURL(element)
                new Promise((resolve)=>{
                    reader.onloadend = function () {
                        fileData.push({
                            name: element.name,
                            type: element.type,
                            data: reader.result.split(',')[1]
                        })
                        resolve()
                    }
                })
            }
            this.uploadedImages = fileData
        },
        clearCameraCapture(){
            this.$refs.cameraCapture.reset();
            this.images = null;
        },
        closeDialog() {
            let self = this;
            this.form = {
                jo_number: '',
                date_needed: this.$dayjs().endOf('month').format('YYYY-MM-DD'),
                duration: [],
                client_contact_person: '',
                client_contact: '',
                vendor_id: '',
                payment_id: '',
                vendor_address_id: '',
                vendor_payment_item_id: '',
                remarks: '',
                department_id: undefined,
                admin_location_id: undefined,
                repair_category: undefined,
                other_repair_category: undefined,
                other_repair_type: undefined,
                repair_type_id: undefined,
                vehicle_id: undefined,
                company_id: undefined,
                store_branch_id: undefined,
            }
            this.admin_items_dropdown = [];
            this.admin_items = [{
                value:'',
                item_name:'',
                quantity: '1',
                original_price: 0,
                total_amount:0,
                has_dimension:0,
                materials: [ this.defaultMats() ],
                dimension: {
                    length: 0,
                    width: 0,
                    height: 0,
                    cbm: 0,
                },
            }];

            this.uploadedFiles = null;
            this.images = null;
            this.uploadedImages = null;

            this.$refs.clearFiles.$emit('clear-files');

            this.$store.commit("EDIT_JO", {});
            this.$store.commit("JO_NUM", '');
            this.$store.commit("DIALOG", false);
            this.$store.commit("ACTION", "");
            this.$store.commit('JO_PRINT', false);
            this.$store.commit('JO_PRINT_DATA', []);
        },

        createOrUpdateJOForm(url = undefined) {
            if (!this.isFormRequiredFieldsValid()) return;
            if (!this.isNextStepValid()) return;
            if (!this.isItemsValid()) return;

            let payload = {
                url: url
                    ? url
                    : this.ACTION === 'Submit' ? 'CreateJO' : `UpdateJO/${this.form.id}`,
                form: {
                    ...this.form,
                    last_jo_number: '-' + this.GET_JO_NUM,
                },
                admin_items: this.admin_items,
                file: this.uploadedFiles,
                image: this.uploadedImages,
            }

            Swal.fire({
                title:'Save changes',
                text:'- Are you sure you want to Save Changes ?',
                icon:'question',
                showCancelButton:true,
                reverseButtons:true
            }).then(action=>{
                if(action.isConfirmed){
                    if (this.ACTION === 'View') {
                        // saved -> admin
                        if (payload.form.status == 1) payload.form.status = 3;

                        // admin -> exec
                        else if (payload.form.status == 3) payload.form.status = 8;

                        // 50/50 exec -> for payment
                        else if (this.form.payment_method == 1 && payload.form.status == 8) payload.form.status = 2;
                        // 50/50 partial -> on going
                        else if (this.form.payment_method == 1 && payload.form.status == 5) payload.form.status = 6;
                        // 50/50 on going -> for payment
                        else if (this.form.payment_method == 1 && payload.form.status == 6) {
                            payload.form.accomplished = 1;
                            payload.form.status = 2;
                        }

                        // SBP exec -> on going
                        else if (this.form.payment_method == 2 && payload.form.status == 8) payload.form.status = 6;
                        // SBP on going -> for payment
                        else if (this.form.payment_method == 2 && payload.form.status == 6) {
                            payload.form.accomplished = 1;
                            payload.form.status = 2;
                        }

                        // PBS exec -> for payment
                        else if (this.form.payment_method == 3 && payload.form.status == 8) payload.form.status = 2;
                        // PBS paid -> on going
                        else if (this.form.payment_method == 3 && payload.form.status == 4) payload.form.status = 6;
                        // PBS on going -> completed
                        else if (this.form.payment_method == 3 && payload.form.status == 6) {
                            payload.form.accomplished = 1;
                            payload.form.status = 7;
                        }
                    }

                    this.$store.dispatch('jobOrderPost', payload).then(response => {
                        this.$store.commit('NEW_JO_TABLE', true);
                        this.$store.commit("JO_NUM", '');
                        Swal.fire('Success!','','success')
                        this.admin_items = []
                        this.closeDialog();
                    }).catch(err=>{
                        if (err.response.status === 406)
                            return Swal.fire({
                                title: 'Error',
                                text: err.response.data.error,
                                icon: 'error',
                            });
                        if (err.response.status === 409)
                            return Swal.fire({
                                title: 'Error',
                                text: err.response.data.error,
                                icon: 'error',
                            });

                        Swal.fire('Error','','error')
                    });
                }
            })
        },
        isFormRequiredFieldsValid() {
            let invalidField = '';

            if (!this.form.jo_number) invalidField = 'JO Number';
            else if (!this.form.company_id) invalidField = 'Company';
            else if (!this.form.store_branch_id) invalidField = 'Store Branch';
            else if (!this.form.department_id) invalidField = 'Department';
            else if (!this.form.admin_location_id) invalidField = 'Location';
            else if (!this.form.date_needed) invalidField = 'Date Needed';
            else if (this.form.duration.length <= 0) invalidField = 'Duration';
            else if (!this.form.client_contact_person) invalidField = 'Client Contact Person';
            else if (!this.form.client_contact) invalidField = 'Client Contact #';
            else if (!this.form.vendor_id) invalidField = 'Vendor Name';
            else if (!this.form.payment_id) invalidField = 'Payment Terms';
            else if (!this.form.vendor_address_id) invalidField = 'Contact Person #';
            else if (!this.form.vendor_payment_item_id) invalidField = 'Checks Payable to';
            else if (!this.form.repair_category) invalidField = 'Service Type';
            else if (this.form.repair_category == 'Other' && !this.form.other_repair_category) invalidField = 'Other Service Type';
            else if (this.getRepairType()?.others == 1 && !this.form.other_repair_type) invalidField = 'Other Service Particular';
            else if (this.form.repair_category != 'Other' && !this.form.repair_type_id) invalidField = 'Service Particulars';
            else if (this.form.repair_type_id == 1 && !this.form.vehicle_id) invalidField = 'Vehicle';
            else if (!this.form.payment_method) invalidField = 'Payment Method';
            else if (!this.form.remarks) invalidField = 'Remarks';

            if (!invalidField) return true;

            Swal.fire(
                'Warning',
                `${invalidField} is required.`,
                'warning',
            );

            return false;
        },
        isNextStepValid() {
            if (this.ACTION == 'View' && this.form.status == 6 && (!this.uploadedFiles && !this.images)) {
                Swal.fire(
                    'Warning',
                    'Need file attachments to continue.',
                    'warning',
                );
                return false;
            }

            return true;
        },
        isItemsValid() {
            let valid = true;

            this.admin_items.forEach((item, index) => {
                if (item.item_name == '' || parseFloat(item.quantity) <= 0) {
                    Swal.fire('Warning',`Service ${index + 1} have invalid inputs.`,'warning');
                    return valid = false;
                }

                const itemsWithInvalidMaterialDetails = item.materials.some(
                    mat => mat.name != '' && (
                        !mat.item_uom_id ||
                        mat.quantity < 1
                    )
                );

                if (itemsWithInvalidMaterialDetails) {
                    Swal.fire('Warning',`Service ${index + 1} have invalid material inputs.`,'warning');
                    return valid = false;
                }
            });

            return valid;
        },
        changeVendor() {
            this.form.payment_id = '';
            this.form.vendor_address_id = '';
            this.form.vendor_payment_item_id = '';
        },
        modifyItem(action, key = -1){
            if([2,3].includes(action)){
                if(action == 2){
                    this.admin_items.pop()
                }
                else{
                    this.admin_items.splice(key,1);
                }
            }
            else{
                this.addItems()
            }
            if(this.admin_items.length == 0){
                this.addItems()
            }
            this.$forceUpdate()
        },
        addItems(){
            let self = this;

            this.admin_items.push({
                    value:'',
                    item_name:'',
                    quantity: '1',
                    original_price: 0,
                    total_amount:0,
                    has_dimension:0,
                    materials: [ this.defaultMats() ],
                    dimension: {
                        length: 0,
                        width: 0,
                        height: 0,
                        cbm: 0,
                    },
                })

        },
        async appendItem(item,index){
            let new_obj = this.admin_items_dropdown.find(e => e.id === item.value)
            this.$set(item, 'item_vendor_price_list_id', new_obj.item_vendor_price_list_id)
            this.$set(item, 'total_amount', new_obj.total_amount)
            this.$set(item, 'original_price', new_obj.original_price)
            this.$set(item, 'quantity', new_obj.quantity)
            this.$set(item, 'item_name', new_obj.item_name)
            this.$set(item, 'id',  item.id)
        },
        updatePrice(item){
            // const amount = this.admin_items_dropdown.find(e => e.id === item.value)?.original_price
            item.total_amount = parseFloat(item.original_price) * parseInt(item.quantity)
        },

        userApprover(status){
            if (this.userAccess.includes('confirm') && status === 1) return true;

            else if (this.userAccess.includes('admin_approve') && status === 3) return true;

            else if (this.userAccess.includes('exec_approve') && status === 8) return true;

            else if (this.userAccess.includes('approve_on_going') && this.form.payment_method == 1 && this.form.status == 5) return true;
            else if (this.userAccess.includes('approve_on_going') && this.form.payment_method == 2 && this.form.status == 8) return true;
            else if (this.userAccess.includes('approve_on_going') && this.form.payment_method == 3 && this.form.status == 4) return true;

            else if (this.userAccess.includes('approve_payment') && this.form.payment_method == 1 && this.form.status == 8) return true;
            else if (this.userAccess.includes('approve_payment') && this.form.payment_method == 1 && this.form.status == 6) return true;
            else if (this.userAccess.includes('approve_payment') && this.form.payment_method == 2 && this.form.status == 6) return true;
            else if (this.userAccess.includes('approve_payment') && this.form.payment_method == 3 && this.form.status == 8) return true;

            else if (this.userAccess.includes('approve_completed') && this.form.payment_method == 3 && this.form.status == 6) return true;

            else return false;
        },
        renderPDF() {
            this.$store.commit('JO_PRINT', true)
            this.$store.commit('JO_PRINT_DATA', this.joPrintData)
        },
        async updateStatus(form){
            let new_status = 0
            if(form.status == 3){ // check if status is approved
                if(form.payment_method == 2){ // check if payment method is (Service before payment)
                   new_status = 4; // Receiving
                }else{
                   new_status = 6; // For payment
                }
            }else if (form.status == 4){ // check is status is receiving
                if(form.paid == 1 && form.received == 1){ // check if all items are paid and received
                   new_status = 7; // Completed
                }else if(form.paid == 1 && form.received == 0){
                   new_status = 6; // Receiving
                }else{
                   new_status = 2; // For payment
                }
            }else if(form.status == 5){ //Partial
               new_status = 2; //Partial to For Payment
            }
        },
        async imageLoader(imagePath){
            let image = await this.loadImage(imagePath);
            this.$store.commit('COMPANY_IMAGE', image);
        },
        onInput(value) {

            const selectedItem = this.GET_CUSTOMERS_LIST_SELECTION.find(item => item.text === value);
            if (selectedItem) {
                this.form.requestor = {
                    value: selectedItem.value,
                    text: selectedItem.text
                };
            } else {
                this.form.requestor = value;
            }
        },
    },
    watch: {
        'form.company_id': {
            handler(val) {
                if (!val) {
                    this.form.store_branch_id = null
                    return;
                }

                const storeBranchIds = this.GET_PV_STORE_BRANCHES_SELECTION
                    .filter(selection => selection.company_id == val)
                    .map(selection => selection.value);

                if (!storeBranchIds.includes(this.form.store_branch_id))
                    this.form.store_branch_id = storeBranchIds[0];
            },
        },
        'form.store_branch_id':{
            handler(val){
                if (!val) {
                    this.form.company_id = null
                    return;
                }

                const storeBranchCompanyId = this.GET_PV_STORE_BRANCHES_SELECTION
                    .filter(selection => selection.value == val)
                    .shift().company_id;
                this.form.company_id = storeBranchCompanyId;
            },
        },
        'form.repair_category':{
            handler(val){
                if (!val) return;

                if (val == 'Other') {
                    this.form.repair_type_id = undefined;
                }

                if (!this.form.repair_type_id)
                    return this.form.repair_type_id = this.repairTypeDropdownItems[0].value;

                const currentRepairType = this.GET_ADMIN_REPAIR_TYPE_SELECTION
                    .filter(item => item.value == this.form.repair_type_id).shift();

                if (currentRepairType.type != val)
                    return this.form.repair_type_id = this.repairTypeDropdownItems[0].value;
            },
        },
        'form.repair_type_id':{
            handler(val){
                if (!val) return;

                this.form.repair_category = this.repairTypeDropdownItems
                    .filter(item => item.value == val).shift().type;
            },
        },
        'GET_EDIT_JO.id': {
            handler(val) {
                if (val){
                    this.btn_loading = true;
                    this.$store.dispatch('joPrintData', {id: val}).then(response => {
                        this.joPrintData = response.data;
                        this.btn_loading = false;
                    });
                }
            },
            immediate: true,
        },
         'USER_ACCESS': {
            handler(val){
                if(val !== 'fail'){
                    this.userAccess = val.map(e=>e.actions_code)
                }
            }
        },
        'GET_VENDOR_PAYMENT_ITEM': {
            handler(val) {
                this.payee_dropdown = val
                if (this.payee_dropdown.length > 0 && !this.form.vendor_payment_item_id && this.ACTION === 'Submit') this.form.vendor_payment_item_id = val[0].id;
            },
            immediate: true,
            deep: true,
        },
        'ACTION': {
            handler(val) {
                if (val === 'Submit') {
                    this.disabled_view = false;
                    this.disabled_view_nums = false;
                    this.action = this.GET_JO_DISPATCH.add;

                    this.form.department_id = this.USERACCOUNT_LOAD.department_id;
                    let department = this.GET_LIST_DEPARTMENT.find(item => item.value == this.form.department_id);
                    this.form.jo_number = `${department.code}-${this.GET_JO_NUM}`;
                } else if (val === 'Update') {
                    this.disabled_view = false;
                    this.disabled_view_nums = true;
                    this.action = this.GET_JO_DISPATCH.update;
                } else if (val == "View") {
                    this.action = this.GET_JO_DISPATCH.update;
                    this.disabled_view = true;
                    this.disabled_view_nums = true;
                }
            },
            immediate: true,
        },
        'form.department_id': {
            handler(val) {
                if (val) {
                    if (this.ACTION == 'Submit') {
                        let department = this.GET_LIST_DEPARTMENT.find(item => item.value == val)
                        this.form.jo_number = `${department.code}-${this.GET_JO_NUM}`;
                    }
                }
            },
        },
        'form.vendor_id': {
            handler(val) {
                if (!val) return;
                this.payment_items = []
                this.$store.dispatch("showvendors", val)
                    .then(data=>{
                        this.payment_items = data.payments
                        this.contact_person = data.vendor_address
                        this.$store.dispatch("getVendorPaymentItems", val);
                    })

                if (this.ACTION === 'Update' || this.ACTION === 'Submit') {
                    this.$store.dispatch('jobOrderGet',{
                        url: `getAdminItemsJO/${this.form.vendor_id}`,
                        vendor_id:this.form.vendor_id,
                    }).then(response=>{
                        this.admin_items_dropdown = response
                    })
                }
            },
            immediate: true,
        },
        'GET_EDIT_JO': {
            handler(val) {
                let self = this;
                // this.addItems();
                if (!val.id || this.ACTION === 'Submit') return;
                this.hasExistingEntebill = false;
                this.loadingForm = true;
                this.$store.dispatch("jobOrderGet", {url: "getJobOrder", id: val.id}).then(async response => {
                    const admin_job_order = response.admin_job_order;
                    this.form = admin_job_order;
                    this.form.duration = admin_job_order.work_duration.split(',');
                    this.admin_items = response.admin_job_order.admin_job_order_items;

                    this.admin_items.forEach(element => {
                        if (element.materials.length <= 0)
                            element.materials.push(this.defaultMats());
                        if (!element.dimension) {
                            element.dimension = {
                                length: 0,
                                width: 0,
                                height: 0,
                                cbm: 0,
                            };
                        }
                    });

                        await this.$store.dispatch('jobOrderGet',{
                            url: `getAdminItemsJO/${this.form.vendor_id}`,
                            vendor_id:this.form.vendor_id,
                        }).then(response=>{
                            this.admin_items_dropdown = response
                        })

                        if(val.status == 2 && this.ACTION === 'Update'){
                            await this.$store.dispatch('jobOrderGet', {
                                url: `check-if-has-enter-bill/${val.id}`,
                                transact_type_id: 28,
                            }).then((response) =>{
                                if(response){
                                    this.hasExistingEntebill = true;
                                    Swal.fire('This JO has existing Enterbill', '', 'info')
                                }
                            })
                        }
                    this.loadingForm = false;
                });
            },
            immediate: true,
            deep: true,
        },
        'payment_items': {
            handler(val) {
                if (val.length > 0 && !this.form.payment_id && this.ACTION === 'Submit') this.form.payment_id = val[0].id;
            },
            immediate: true,
            deep: true,
        },

        'contact_person': {
            handler(val) {
                if (val.length > 0 && !this.form.vendor_address_id && this.ACTION === 'Submit') this.form.vendor_address_id = val[0].id;
            },
            immediate: true,
            deep: true,
        },
        'GET_JO_NUM': {
            handler(val) {
                if (this.ACTION == 'Submit') {
                    let department = this.GET_LIST_DEPARTMENT.find(item => item.value == this.form.department_id);
                    this.form.jo_number = `${department.code}-${this.GET_JO_NUM}`;
                }
            },
        },
        joPrintData: {
            handler(val) {
                if(val){
                    this.imageLoader(val.header.image_path);
                }
            },
            immediate: true,
            deep: true,
        },
        // 'form.customer_request':{
        //     handler(val){
        //         if(!val){
        //             this.form.requestor = ''
        //         }
        //     }
        // },
    },
};
</script>

<style scoped>
.view-table {
    padding: 1rem;
    padding-bottom: .5rem;
    background-color: #f3f4f6 !important;
}
.expand-table th,
.expand-table td {
    text-align: left;
}
.expand-table .center {
    text-align: center;
}
.expand-table .right {
    text-align: right;
}
.expand-table .selected {
    background-color: #bbf7d0;
}
.expand-table .selected:hover td {
    background-color: #86efac;
}
.expand-table .not-selected {
    background-color: #fecaca;
}
.expand-table .not-selected:hover td {
    background-color: #fca5a5;
}
.expand-table .action {
    width: 8rem;
}
.hide {
    display: none;
}
.actions-wrapper {
    display: flex;
    gap: .5rem;
    justify-content: center;
}
.quantity-input input {
    text-align: center !important;
}
</style>
