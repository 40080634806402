import store from '../../../store';
import api from '../../api'

export default {
    createFasRequisition(params){
       return api.post('/admin/fasrequisition',params)
    },
    updateFasRequisition(params){
        return api.put(`/admin/fasrequisition/${params.id}`,params)
     },
     deleteFasRequisition(params){
        return api.delete(`/admin/fasrequisition/${params}`)
     },
     getAllFasRequisition(params){
        return api.get('/admin/getAllfasrequisition',{params})
    },
    showFasrequisition(params){
        return api.get('/admin/fasrequisition/'+params.id,{params})
    },
    getPreviousData(params){
        return api.get('/admin/getPreviousData',{params})
    },
    getFasItems(params){
        return api.get('/admin/getFasItems',{params})
    },
    approveAllFasRequisition(params){
        return api.post('/admin/approveAllFasRequisition',params)
    },
    
}

