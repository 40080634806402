<template>
    <div class="ma-1">
        <div class="row p-2">
            <div class="col-12 d-flex no-block align-items-center pb-0">
                <h4 class="page-title">Technician Admin Settings</h4>
            </div>
        </div>
        <div class="container pt-0 mt-1">
            <v-card class="pa-4">
                <v-data-table
                    :headers="tableheaders"
                    :items="scUserLists"
                    :loading="loading"
                    :no-data-text="noDataText"
                >
                <template v-slot:item.action="{ item }">
                    <td>
                        <v-switch v-model="item.technician_admin" @change="updateTechRole(item)"/>
                    </td>
                </template>
                </v-data-table>
            </v-card>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            tableheaders: [
                { text: 'Name', value: 'name' },
                { text: 'Department', value: 'department_name' },
                { text: 'Job Title', value: 'job_title' },
                { text: 'Technician Admin', value: 'action' }
            ],
            scUserLists: [],
            loading: false,
            noDataText: '',
            toggleTechnician: false,
        }
    },
    mounted: function () {
        this.getAllScUsers();
    },
    computed:{
        ...mapGetters([
            'GET_SERVICE_GET_USERS'
        ])
    },
    watch: {
        getAllScUsers() {
            if(this.scUserLists.length > 0) {
                this.loading = false;
            } else {
                this.loading = true;
                setTimeout(()=> {
                    this.loading = false;
                    this.noDataText = "No data for Service Users";
                }, 10000);
            }
        }
    },
    methods: {
        getAllScUsers() {
            this.$store.dispatch('serviceGetUsers').then(response => {
                this.scUserLists = this.GET_SERVICE_GET_USERS;
            });
        },
        updateTechRole(item) {
            if(!!item) {
                let payload = {
                    user_id: item.id,
                    toggle: item.technician_admin
                }
                this.$store.dispatch('updateUserTech',payload).then(response => {
                    if(!!response.data.msg) {
                        this.getAllScUsers();
                    }
                });
            }
        }
    }
}
</script>

<style>

</style>
