<template>
    <v-form v-model="valid" @submit.prevent="ACTION == 'Submit' ? submit() : update()">
        <v-card>
            <v-row class="px-3 py-2" dense no-gutters>
                <v-col cols="11">
                    <v-card-title class="pa-0 pl-3">
                        <span class="text-h5"> {{ actions == 'Add' ? 'Add' : ACTION }} Report Information</span>
                    </v-card-title>
                </v-col>
                <v-col cols="1">
                    <v-btn text icon small color="gray" class="float-right" @click="closeDialog()">
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-divider class="pa-0 ma-0"></v-divider>
            <v-row class="px-3 py-2" dense no-gutters>
                <v-card-text>
                    <v-row class="d-flex justify-content-between align-center pl-3 pr-3">
                        <v-col v-if="ACTION == 'Submit'" cols="3">
                            <span class="h6">What do you want to create?</span>
                        </v-col>
                        <v-col col="2">
                            <v-radio-group
                                v-model="group_button"
                                :mandatory="false"
                                row
                                :readonly="ACTION == 'View'"
                                >
                                <v-radio
                                    label="Group"
                                    color="primary"
                                    value="group"
    
                                ></v-radio>
                                <v-radio
                                    label="Report"
                                    color="primary"
                                    value="report"
    
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-spacer />
                        <v-col cols="2">
                            <v-switch
                            v-model="report.fillables.active"
                            :label="`Active`"
                            :false-value="0"
                            :true-value="1"
                            :readonly="ACTION == 'View'"
                        ></v-switch>
                        </v-col>
                    </v-row>
                    <v-row v-if="group_button != ''" class="pl-3 pr-3">
                        <v-col :lg="group_button == 'report' ? 12 : 6">
                            <v-text-field 
                                v-model="report.fillables.name" 
                                label="Report Name" 
                                :readonly="ACTION == 'View'" 
                                outlined 
                                dense 
                            >
                            </v-text-field>
                        </v-col>
                        <v-col :lg="group_button == 'group' ? 6 : 4">
                            <v-text-field 
                                v-model="report.fillables.code" 
                                label="Code" 
                                :readonly="ACTION == 'View'" 
                                outlined 
                                dense 
                            >
                            </v-text-field>
                        </v-col>
                        <v-col v-if="group_button == 'report'" lg="4">
                            <v-autocomplete 
                                v-model="report.fillables.icon_id" 
                                :items="GET_ICONS_LIST"
                                label="Icon" 
                                :readonly="ACTION == 'View'" 
                                outlined 
                                dense 
                                clearable
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col v-if="group_button == 'report'" lg="4">
                            <v-autocomplete 
                                v-model="report.fillables.route"
                                :items="route_list" 
                                label="Route" 
                                :readonly="ACTION == 'View'" 
                                outlined 
                                dense 
                                return-object
                                clearable
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col v-if="group_button == 'report'" lg="6">
                            <v-autocomplete 
                                v-model="report.fillables.group_id" 
                                :items="GET_REPORT_DROPDOWN" 
                                label="Group" 
                                :readonly="ACTION == 'View'" 
                                outlined 
                                dense
                                clearable
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col v-if="group_button == 'report'" lg="6">
                            <v-autocomplete 
                                v-model="report.fillables.report_category_id" 
                                :items="GET_REPORT_CATEGORY"
                                label="Report Category" 
                                :readonly="ACTION == 'View'" 
                                outlined 
                                dense
                                clearable
                            >
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-row v-if="group_button != ''" class="d-flex justify-content-between align-center" dense no-gutters>
                    <v-col class="pr-3" v-if="!ACTION.includes('View') " >
                        <v-btn class="float-right" @click="ACTION == 'Submit' ? submit() : update()" small>
                            <v-icon>mdi-plus</v-icon>
                            {{ ACTION == 'Edit' ? 'Update' : ACTION }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-row>
        </v-card>
    </v-form>
</template>

<script>
import swal from 'sweetalert2'
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            valid: true,
            report: {
                fillables: {
                    name: '',
                    code: '',
                    icon_id: '',
                    route: '',
                    access: '',
                    report_category_id: '',
                    active: 1,
                    group_id: '',
                },
            },
            route_list: [],
            group_button: '',
        }
    },

    methods: {
        closeDialog() {            
            this.group_button  = ''
            this.$store.commit('DIALOG', false)
            this.$store.commit('REPORT_MODULE_VIEW', {})
        },

        submit() {
            let warning = '';

            if (!this.report.fillables.name) warning += 'Please input a report name.<br>';
            if (!this.report.fillables.code) warning += 'Please input a code.<br>';
            if(this.group_button == 'report') {
                if (!this.report.fillables.icon_id) warning += 'Please input a icon.<br>';
                if (!this.report.fillables.route) warning += 'Please input a route.<br>';
                if (!this.report.fillables.report_category_id) warning += 'Please input a report category.<br>';
                if (!this.report.fillables.group_id) warning += 'Please input a group.<br>';
            } 
            if (warning !== '') return swal.fire({
                title: 'Must meet the following before confirming:',
                html: warning,
                icon: "info",
            });
            this.report.fillables.access = this.report.fillables.name
            swal.fire({
                title:'Add',
                text:'- Are you sure you want to Submit?',
                icon: 'question',
                showCancelButton:true,
                reverseButtons: true,
            }).then(action=>{
                if(action.isConfirmed){
                    this.$store.dispatch('reportsModulePost',{
                        url: 'addReportModule',
                        fillables: this.report.fillables,
                        switch: this.group_button == 'group' ? true : null,
                    }).then(response=>{
                        swal.fire('Success!','','success')
                        this.closeDialog()
                        this.clearData()
                        this.$store.commit('REFRESH_REPORT', true)
                    }).catch(err=>{
                        swal.fire('Error!','','error')
                    })
                }
            })
        },

        update() {
            let warning = '';

            if (!this.report.fillables.name) warning += 'Please input a report name.<br>';
            if (!this.report.fillables.code) warning += 'Please input a code.<br>';
            if(this.group_button == 'report') {
                if (!this.report.fillables.icon_id) warning += 'Please input a icon.<br>';
                if (!this.report.fillables.route) warning += 'Please input a route.<br>';
                if (!this.report.fillables.report_category_id) warning += 'Please input a report category.<br>';
                if (!this.report.fillables.group_id) warning += 'Please input a group.<br>';
            } 

            if (warning !== '') return swal.fire({
                title: 'Must meet the following before confirming:',
                html: warning,
                icon: "info",
            });
            this.report.fillables.access = this.report.fillables.name
            swal.fire({
                title:'Update',
                text:'- Are you sure you want to Update?',
                icon: 'question',
                showCancelButton:true,
                reverseButtons: true,
            }).then(action=>{
                if(action.isConfirmed){
                    this.$store.dispatch('reportsModulePost',{
                        url: 'updateReportModule',
                        fillables: this.report.fillables,
                        id: this.GET_REPORT_MODULE_VIEW.report_id,
                        switch: this.group_button == 'group' ? true : null,
                    }).then(response=>{
                        swal.fire('Success!','','success')
                        this.closeDialog()
                        this.clearData()
                        this.$store.commit('REFRESH_REPORT', true)
                    }).catch(err=>{
                        swal.fire('Error!','','error')
                    })
                }
            })
        },
        
        getReportData() {
            this.report.fillables.name                      = this.GET_REPORT_MODULE_VIEW.title
            this.report.fillables.code                      = this.GET_REPORT_MODULE_VIEW.code
            this.report.fillables.icon_id                   = this.GET_REPORT_MODULE_VIEW.icon_id
            this.report.fillables.route                     = this.GET_REPORT_MODULE_VIEW.route
            this.report.fillables.access                    = this.GET_REPORT_MODULE_VIEW.access
            this.report.fillables.report_category_id        = this.GET_REPORT_MODULE_VIEW.report_category_id
            this.report.fillables.active                    = this.GET_REPORT_MODULE_VIEW.active
            this.report.fillables.group_id                  = this.GET_REPORT_MODULE_VIEW.group_id
        },
        
        clearData() {
            this.report.fillables.name                      = ''
            this.report.fillables.code                      = ''
            this.report.fillables.icon_id                   = ''
            this.report.fillables.route                     = ''
            this.report.fillables.access                    = ''
            this.report.fillables.report_category_id        = ''
            this.report.fillables.group_id                  = ''
            this.group_button                               = ''
        },
    },

    
    computed: {
        ...mapGetters([
            'DIALOGS',
            'ACTION',
            'GET_REPORT_MODULE_VIEW',
            'GET_REPORT_CATEGORY',
            'GET_ICONS_LIST',
            'GET_REPORT_DROPDOWN'
        ]),

        actions(){
            if(this.ACTION == 'Submit'){
                return 'Add'
            } else if(this.ACTION == 'Edit'){
                this.getReportData()
                return 'Edit'
            } else if(this.ACTION == 'View'){
                this.getReportData()
                return 'View'
            } 
        }
    },

    watch: {
        'report.fillables.group_id': {
            handler(val) {
                if(val != undefined) {
                    if(this.ACTION == 'Edit' || this.ACTION == 'View') {
                        val == '' ? this.group_button = 'group' : this.group_button = 'report'
                    }
                }
            }
        },

        ACTION: {
            handler(val) {
                if(val == 'Submit') {
                    this.report.fillables.active = 1;
                }
            }
        }
    },

    mounted() {
        this.$store.dispatch('getReportCategory')
        this.$store.dispatch('IconsList')
        this.$store.dispatch('getReportsDropdown')
        let find = 'reports';
        this.$router.options.routes.forEach(e=>{
          if(e.hasOwnProperty('children'))
          {
            e.children.forEach(el=>{
              if(el.path.match(find)){
                this.route_list.push(el.name)
              }
            })
          }

        })
    }
}
</script>

<style scoped>

</style>