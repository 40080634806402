<template>
    <div class="tabs-body-color">
        <v-container fluid>
            <v-row class="pt-2">
                <v-col cols="6" class="d-flex no-block align-items-center">
                    <div class="btn-toolbar">
                        <v-btn class="ml-1 mb-2" @click="AddDialog()" v-if="isActive_tab !='inventory_list' && (isActive_tab == 'sample_form_list' && userAccess.create_issue == true) || (isActive_tab == 'return_list' && userAccess.create_return)">
                            <v-icon>mdi-plus</v-icon>Add
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <InventoryTable v-if="isActive_tab == 'inventory_list'"></InventoryTable>
                <SampleFormStatusGroupComponent v-if="isActive_tab == 'sample_form_list'"></SampleFormStatusGroupComponent>
                <ItemReturnStatusGroupComponent v-if="isActive_tab == 'return_list'"></ItemReturnStatusGroupComponent>
                <SampleFormLogsTableComponentVue v-if="isActive_tab == 'sf_logs'"></SampleFormLogsTableComponentVue>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import InventoryTable from "@/views/main/modules/Operations/SampleForm/SampleFormComponent/InventoryTableComponent.vue"
import SampleFormStatusGroupComponent from "@/views/main/modules/Operations/SampleForm/SampleFormComponent/SampleFormStatusGroupComponent.vue";
import ItemReturnStatusGroupComponent from "@/views/main/modules/Operations/SampleForm/SampleFormComponent/ItemReturnStatusGroupComponent.vue"
import SampleFormLogsTableComponentVue from "@/views/main/modules/Operations/SampleForm/SampleFormComponent/SampleFormLogsTableComponent.vue"
import { mapGetters } from 'vuex';

export default {
    props:["isActive_tab"],
    components: {
        InventoryTable,
        SampleFormStatusGroupComponent,
        ItemReturnStatusGroupComponent,
        SampleFormLogsTableComponentVue
    },
    data() {
        return {
            userAccess: {
                create_issue: false,
                create_return: false,
            },
        }
    },
    computed: {
        ...mapGetters(['USER_ACCESS'])
    },
    mounted() {
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
    },
    methods: {
        AddDialog(){
            this.$store.commit("DIALOG", true);
            this.$store.commit('FILE_PREVIEWS',[]);
            this.$store.commit("ACTION", "Submit");
        },
    },
    watch:{
        USER_ACCESS:{
            handler(val){
                if(val != "fail"){
                    this.userAccess.create_issue = false;
                    this.userAccess.create_return = false;

                    val.forEach(e => {
                        if (e.actions_code == 'create_issue') {
                            this.userAccess.create_issue = true;
                        } else if (e.actions_code == 'create_return') {
                            this.userAccess.create_return = true;
                        }
                    });
                }
            }
        }
    }
};
</script>
