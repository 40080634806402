<template>
  <v-dialog v-model="showQrCodeDialog" persistent max-width="18%">
      <v-card class="pa-4">
          <v-row class="m-0">
                <v-col>
                    <v-card-title>
                        <span class="headline">QR Code</span>
                    </v-card-title>
                </v-col>
                <v-col cols="pull-right-10 p-2">
                    <v-btn
                        text
                        icon
                        color="gray"
                        class="float-right"
                        @click="closeShowQrCodeDialog()"
                    >
                    <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-divider class="mt-0" />

            <v-row class="pa-1">
                <v-col class="d-flex justify-content-center">
                    <vue-qrcode :value="rfNumber" ref="qr" :options="{ width: 500, height: 500, errorCorrectionLevel: 'H' }"></vue-qrcode>
                </v-col>
            </v-row>

            <v-divider v-if="downloadable" />

            <div v-if="downloadable" style="text-align:center">
                <v-btn @click="download()">Download</v-btn>
            </div>
      </v-card>
  </v-dialog>
</template>

<script>
import VueQrcode from 'qrcode.vue'

export default {
    data() {
        return {
            rfNumber: '',
            itemModel: '',
            itemName: '',
            itemSerial: '',
            itemCategory: '',
            customerName: '',
            downloadable: false
        }
    },
    computed: {
        showQrCodeDialog() {
            return this.$store.state.service.showQrCodeDialog;
        }
    },
    props: ['rf', 'withDownload'],
    components: {
        VueQrcode
    },
    watch: {
        withDownload() {
            if(!!this.withDownload) {
                this.downloadable = true;
            } else {
                this.downloadable = false;
            }
        },
        rf() {
            if(!!this.rf) {
                let rf = this.rf;
                this.rfNumber = rf.rf_number;

                let rf_item = rf.sc_repair_form_item;
                this.itemModel = rf_item.model;
                this.itemName = rf_item.product_name;
                this.itemSerial = rf_item.serial;
                this.itemCategory = rf_item.category;

                let rr = this.rf.sc_receiving_report;
                let endUserFullName = [];
                if(!!rr) {
                    if(rr.customer_id == 275) {
                        if(!!rr.sc_customer) {
                            if(!!rr.sc_customer.first_name) {
                                endUserFullName.push(rr.sc_customer.first_name);
                            } if(!!rr.sc_customer.middle_name) {
                                endUserFullName.push(rr.sc_customer.middle_name);
                            } if(!!rr.sc_customer.last_name) {
                                endUserFullName.push(rr.sc_customer.last_name);
                            }
                        }
                        this.customerName = endUserFullName.toString().replaceAll(',', ' ');
                    } else {
                        this.customerName = rr.customer.company_name;
                    }
                }
            }
        }
    },
    methods: {
        closeShowQrCodeDialog() {
            this.$store.commit('closeShowQrCodeDialog');
        },
        download() {
            let pdf = new this.$jspdf('p', 'mm', [130, 130])

            let contentHtml = this.$refs.qr.$el;
            let image = contentHtml.toDataURL('image/jpeg');
            pdf.addImage(image, 'JPEG',19,3,26,26);

            pdf.setFontSize(4);
            pdf.text(`Customer: ${this.customerName}`, 1, 5);

            pdf.setFontSize(4);
            pdf.text(`RF#: ${this.rfNumber}`, 1, 9);

            pdf.setFontSize(5);
            pdf.text(`Model: ${this.itemModel}`, 1, 13);

            pdf.setFontSize(5);
            pdf.text(`Serial: ${this.itemSerial}`, 1, 17);

            pdf.setFontSize(5);
            pdf.text(`Category: ${this.itemCategory}`, 1, 21);

            pdf.save(`${this.rfNumber}-Qrcode.pdf`);
        }
    }
}
</script>

<style>

</style>
