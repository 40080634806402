<template>
    <div class="ma-2">
        <div class="page-wrapper">
            <div class="row p-2">
                <div class="col-12 d-flex no-block align-items-center">
                <h4 class="page-title">Technician</h4>
                </div>
            </div>
            <div class="container">
                <div id="item-tabs" class="row mx-auto">
                    <v-tabs id="group-tab" color="cyan" dark slider-color="cyan" background-color="#424242" class="col-12 pb-0" show-arrows>
                        <v-tooltip v-for="(tab,index) in tabs.collection" :key="index"  top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-tab v-bind="attrs" v-on="on" ripple @click="tabs.selected = tab.text">{{ tab.text }}</v-tab>
                            </template>
                            <span>{{ tab.description }}</span>
                            <!-- <span>{{ tab }}</span> -->
                        </v-tooltip>
                        <!-- <v-tab ripple @click="rfStatusAction('UAS')">UAS</v-tab>
                        <v-tab ripple @click="rfStatusAction('WFP')">WFP</v-tab>
                        <v-tab ripple @click="rfStatusAction('WFR')">WFR</v-tab>
                        <v-tab ripple @click="rfStatusAction('PIN')">PIN</v-tab>
                        <v-tab ripple @click="rfStatusAction('PFR')">PFR</v-tab>
                        <v-tab ripple @click="rfStatusAction('RET')">RET</v-tab>
                        <v-tab ripple @click="rfStatusAction('FRR')">FRR</v-tab>
                        <v-tab ripple @click="rfStatusAction('REP')">REP</v-tab>
                        <v-tab ripple @click="rfStatusAction('DEL')">DEL</v-tab>
                        <v-tab ripple @click="rfStatusAction('COL')">COL</v-tab> -->
                    </v-tabs>
                    <TechnicianRepairFormsTableVue v-if="tabs.text.length > 0" :rfStatus="tabs.selected.toUpperCase()" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TechnicianRepairFormsTableVue from '../TechnicianRepairFormsTable.vue';
import ServiceFunctionsComponentVue from '@/views/main/Shared/ServiceFunctionsComponent.vue';
import { mapGetters } from 'vuex';

export default {
    mixins: [ServiceFunctionsComponentVue],
    data() {
        return {
            // rfStatus: '',
            tabs: {
                text:[],
                collection:[
                    { text:'' }
                ],
                selected:'UAS',
                filtered:false
            },
        }
    },
    mounted() {
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
        this.$store.dispatch('serviceGetWarehouses');
        this.$store.dispatch('serviceGetCurrentUser');
        // this.rfStatus = 'UAS';
    },
    components: {
        TechnicianRepairFormsTableVue
    },

    computed:{
        ...mapGetters([
            'GET_ALL_PROCESSING_TABS'
        ])
    },


    async created() {
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
        this.getAllProcessingTabs();

    },
    methods: {
        rfStatusAction(status) {
            this.rfStatus = status;
        },

        async getAllProcessingTabs() {
            this.tabs.text = []
            this.tabs.filtered = false
            await this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id).then(response=>{
                let val = response;
                if(val != "fail"){
                    if(val != "fail"){
                        let actions = ["GEN_PL",'view','PRNT_PL',"view_prev_quotations"]
                        val.forEach((e) => {
                            if(!actions.includes(e.actions_code)){
                                this.tabs.text.push(e.actions_code.toUpperCase())
                            }
                        });
                    }
                }
            })

            this.tabs.filtered = true
            this.getAlllStatuses();
        },

        async getAlllStatuses(){
            this.tabs.collection = []
            await this.$store.dispatch('serviceGet',{
                url:'statuses',
            }).then(response=>{

                response.data.forEach(e=>{
                    if(this.tabs.text.find(el => el == e.text)){
                        this.tabs.collection.push(e)
                    }
                })
            })
        }
    }
}
</script>

<style>

</style>
