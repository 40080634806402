<template>
    <div class="page-wrapper">
        <div class="container">
            <div class="row">
                <div class="col-12 d-flex no-block align-items-center">
                    <h4 class="page-title">Cycle Count</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-12 d-flex no-block align-items-center">
                    <v-btn v-on:click="resetSelections();toggleDialog(`createDialog`);">
                        <i class="fas fa-plus mr-1"></i>Create/Schedule
                    </v-btn>
                    <v-btn v-on:click="getSchedules();toggleDialog(`scheduleDialog`);" class="ml-2">
                        <i class="fas fa-calendar mr-1"></i>Schedules
                    </v-btn>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <v-switch v-model="warehouse.no_virtual" dark label="NONE VIRTUAL">
                    </v-switch>
                </div>
            </div>
        </div>

        <!-- SCHEDULES DIALOG -->
        <v-dialog v-model="scheduleDialog.dialog" scrollable persistent style="z-index: 80;">
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="text-lg font-semibold" v-text="`Scheduled Cycle Counts`"></span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="toggleDialog(`scheduleDialog`)">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-progress-linear
                    v-if="scheduleDialog.loading"
                    indeterminate
                    color="green"
                ></v-progress-linear>

                <v-row dense no-gutters tag="div" class="pl-4">
                    <v-col sm="9">
                    </v-col>
                    <v-col sm="3">
                        <v-btn text icon color="gray" class="float-right" @click="getSchedules();">
                            <v-icon>mdi-sync</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-card-text style="max-height: 460px; border:1px solid #e0e0e0; padding: 0;">
                    <v-data-table
                        :headers="scheduleDialog.datatable.headers"
                        :items="scheduleDialog.data"
                        :options.sync="scheduleDialog.datatable.pagination.options"
                        :items-per-page="scheduleDialog.datatable.pagination.perPageDefault"
                        :footer-props="{
                            showFirstLastPage: true,
                            itemsPerPageOptions: [5, 10, 25, 100]
                        }"
                        :server-items-length="scheduleDialog.datatable.pagination.total"
                        item-key="id"
                        dense
                        style="border:1px solid #e0e0e0; margin: 0;">
                        <template v-slot:item.auto_approve="{ item }">
                            <v-icon v-if="item.auto_approve" dense small color="green">mdi-check</v-icon>
                        </template>
                        <template v-slot:item.schedule="{ item }">
                            <span v-text="scheduleDescription(item)"></span>
                        </template>
                        <template v-slot:item.item_filter_description="{ item }">
                            <span v-text="itemFilterDescription(item)"></span>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <v-btn small text icon color="red">
                                <v-icon class="btn-action" small @click="destroySchedule(item)">mdi-delete</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- CREATE/SCHEDULE DIALOG -->
        <v-dialog v-model="createDialog.dialog" scrollable persistent max-width="770px" style="z-index: 70;">
            <div v-if="datePicker.dialog || timePicker.dialog || dateRangePicker.dialog" style="background-color: rgba(47,47,47,0.5); position:absolute; width:100%; height:100%; z-index: 80;"></div>
            <v-card>
                <v-progress-linear
                    v-if="loading.state"
                    indeterminate
                    color="green"
                ></v-progress-linear>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="text-lg font-semibold" v-text="`Create/Schedule Cycle Count`"></span>
                        </v-card-title>
                    </v-col>


                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="toggleDialog(`createDialog`)">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-card-text style="max-height: 660px; border:1px solid #e0e0e0; padding: 0;">
                    <!-- WAREHOUSE SELECTION -->
                    <v-row tag="div" no-gutters class="px-2">
                        <v-col class="d-flex" cols="6">
                            <v-row dense no-gutters tag="div">
                                <v-col dense tag="div" sm="12" class="d-flex justify-start">
                                    <div class="font-weight-medium" v-text="`Warehouse`"></div>
                                </v-col>
                                <v-col dense tag="div" sm="12">
                                    <v-autocomplete outlined dense
                                        hide-details
                                        item-text="text"
                                        item-value="value"
                                        :items="warehouse.selection"
                                        :disabled="loading.warehouse_selection"
                                        :loading="loading.warehouse_selection"
                                        v-model="warehouse.value"
                                        flat

                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <!-- CYCLE COUNT TYPE SELECTION -->
                    <v-row tag="div" no-gutters class="px-2">
                        <v-col class="d-flex" cols="6">
                            <v-row dense no-gutters tag="div">
                                <v-col dense tag="div" sm="12" class="d-flex justify-start">
                                    <div class="font-weight-medium" v-text="`Type`"></div>
                                </v-col>
                                <v-col dense tag="div" sm="12">
                                    <v-autocomplete outlined dense
                                        :disabled="loading.state"
                                        hide-details
                                        item-text="text"
                                        item-value="value"
                                        :items="cycleCountType.selection"
                                        v-model="createDialog.cycleCount.type"
                                        dense
                                        outlined
                                        flat
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-col>

                        <!-- CUSTOM SCHEDULE DATE AND TIME PICKER -->
                        <v-col v-if="[ENUMS.CYCLE_COUNT_TYPE.CUSTOM_SCHEDULED].indexOf(createDialog.cycleCount.type) >= 0" class="d-flex" cols="12">
                            <v-row dense no-gutters tag="div">
                                <v-col dense tag="div" sm="6">
                                    <v-dialog ref="datePickerDialog" v-model="datePicker.dialog"
                                              :return-value.sync="createDialog.cycleCount.date"
                                              persistent width="290px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                :disabled="loading.state"
                                                :value="formatDateRange([createDialog.cycleCount.date])"
                                                hide-details
                                                readonly
                                                dense
                                                outlined
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="createDialog.cycleCount.date">
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="datePicker.dialog = false">Cancel</v-btn>
                                            <v-btn text color="primary"
                                                   @click="$refs.datePickerDialog.save(createDialog.cycleCount.date)">OK</v-btn>
                                        </v-date-picker>
                                    </v-dialog>
                                </v-col>
                                <v-col dense tag="div" sm="6">
                                    <v-dialog ref="dialog" v-model="timePicker.dialog" :return-value.sync="createDialog.cycleCount.time" persistent width="290px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                :disabled="loading.state"
                                                v-model="createDialog.cycleCount.time"
                                                hide-details
                                                readonly
                                                dense
                                                outlined
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-time-picker v-if="timePicker.dialog" format="24hr" v-model="createDialog.cycleCount.time" full-width>
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="timePicker.dialog = false">Cancel</v-btn>
                                            <v-btn text color="primary" @click="$refs.dialog.save(createDialog.cycleCount.time)">OK</v-btn>
                                        </v-time-picker>
                                    </v-dialog>
                                </v-col>
                            </v-row>
                        </v-col>

                        <!-- SCHEDULED BY FREQUENCY / INTERVAL -->
                        <v-col v-if="[ENUMS.CYCLE_COUNT_TYPE.SCHEDULED_BY_INTERVAL].indexOf(createDialog.cycleCount.type) >= 0" class="d-flex" cols="12">
                            <v-row dense no-gutters tag="div" sm="6">
                                <v-col dense tag="div" :sm="scheduleByIntervalColumnDistriution">
                                    <v-autocomplete outlined dense
                                        :disabled="loading.state"
                                        hide-details
                                        item-text="text"
                                        item-value="value"
                                        :items="cycleCountscheduleType.selection"
                                        v-model="createDialog.cycleCount.interval_type"
                                        dense
                                        outlined
                                        flat
                                    ></v-autocomplete>
                                </v-col>

                                <!-- DAY OF WEEK PICKER -->
                                <v-col v-if="[ENUMS.SCHEDULE_TYPE.WEEKLY,ENUMS.SCHEDULE_TYPE.TWO_WEEKS].indexOf(createDialog.cycleCount.interval_type) >= 0" dense tag="div" :sm="scheduleByIntervalColumnDistriution">
                                    <v-autocomplete outlined dense
                                        :disabled="loading.state"
                                        hide-details
                                        item-text="text"
                                        item-value="value"
                                        :items="dayOfWeek.selection"
                                        v-model="createDialog.cycleCount.interval_day"
                                        dense
                                        outlined
                                        flat
                                    ></v-autocomplete>
                                </v-col>

                                <!-- TIME PICKER -->
                                <v-col v-if="[
                                    ENUMS.SCHEDULE_TYPE.DAILY,
                                    ENUMS.SCHEDULE_TYPE.WEEKLY,
                                    ENUMS.SCHEDULE_TYPE.TWO_WEEKS,
                                    ENUMS.SCHEDULE_TYPE.MONTHLY].indexOf(createDialog.cycleCount.interval_type) >= 0" dense tag="div" :sm="scheduleByIntervalColumnDistriution">
                                    <v-dialog ref="dialog" v-model="timePicker.dialog" :return-value.sync="createDialog.cycleCount.time" persistent width="290px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                :disabled="loading.state"
                                                v-model="createDialog.cycleCount.time"
                                                hide-details
                                                readonly
                                                dense
                                                outlined
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-time-picker v-if="timePicker.dialog" format="24hr" v-model="createDialog.cycleCount.time" full-width>
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="timePicker.dialog = false">Cancel</v-btn>
                                            <v-btn text color="primary" @click="$refs.dialog.save(createDialog.cycleCount.time)">OK</v-btn>
                                        </v-time-picker>
                                    </v-dialog>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <!-- CYCLE COUNT ITEMS PRESETS SELECTION OR FILTER -->
                    <v-tabs color="cyan" dark slider-color="cyan" class="col-12" v-model="createDialog.activeTab">
                        <v-tab :href="'#tab-system-generated'">SYSTEM GENERATED</v-tab>
                        <v-tab :href="'#tab-item-templates'">ITEM TEMPLATES</v-tab>
                        <v-tab-item :value="'tab-system-generated'" class="pb-4" style="border:1px solid #e0e0e0;">
                            <!-- ITEM SIZE -->
                            <!-- <v-row tag="div" no-gutters class="px-2 mt-4">
                                <v-col class="d-flex" cols="8">
                                    <v-row dense no-gutters tag="div">
                                        <v-col dense tag="div" sm="12">
                                            <div class="font-weight-medium" v-text="`Filter Item Size`"></div>
                                        </v-col>
                                        <v-col dense tag="div" sm="12">
                                            <v-autocomplete
                                                :options="itemSizes.selection"
                                                v-model="itemSizes.selected"
                                                @select="getSelectedItemSizes"
                                                placeholder="Item Size/s"
                                                class="d-inline-block">
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row> -->

                            <v-row tag="div" no-gutters class="px-2 mt-4">
                                <v-col class="d-flex" cols="8">
                                    <v-row dense no-gutters tag="div">
                                        <v-col dense tag="div" sm="12">
                                            <div class="font-weight-medium" v-text="`Filter Item Type`"></div>
                                        </v-col>
                                        <v-col dense tag="div" sm="12">
                                            <v-autocomplete
                                                multiple
                                                chips
                                                outlined
                                                dense
                                                :items="itemType.selection"
                                                :loading="loading.item_type_selection"
                                                :disabled="loading.item_type_selection"
                                                v-model="itemType.selected"
                                                @select="getSelectedItemType"
                                                placeholder="Item type/s"
                                                class="d-inline-block">
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-row tag="div" no-gutters class="px-2 mt-4">
                                <v-col class="d-flex" cols="8">
                                    <v-row dense no-gutters tag="div">
                                        <v-col dense tag="div" sm="12">
                                            <div class="font-weight-medium" v-text="`Filter Item Category`"></div>
                                        </v-col>
                                        <v-col dense tag="div" sm="12">
                                            <v-autocomplete
                                                multiple
                                                chips
                                                outlined
                                                dense
                                                :items="itemCategory.selection"
                                                :loading="loading.item_category_selection"
                                                :disabled="loading.item_category_selection"
                                                v-model="itemCategory.selected"
                                                @select="getSelectedItemCategory"
                                                placeholder="Item Categories"
                                                class="d-inline-block">
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <!-- PRINTED BY PL -->
                            <v-row v-if="isSelectedWarehouseDefault" tag="div" no-gutters class="px-2 mt-2">
                                <v-col class="d-flex" cols="12">
                                    <v-row dense no-gutters tag="div" style="border:1px solid #e0e0e0; border-radius: 4px;">
                                        <v-col dense tag="div" sm="12">
                                            <v-switch :disabled="loading.state" v-model="createDialog.cycleCount.printed_by_pl.filter" class="ml-2">
                                                <template v-slot:label>
                                                    <span class="font-weight-medium" style="font-size: 0.875rem !important;" v-text="`Filter by Top Printed PL`"></span>
                                                </template>
                                            </v-switch>
                                        </v-col>
                                        <v-col v-if="createDialog.cycleCount.printed_by_pl.filter" dense tag="div" sm="12">
                                            <v-row tag="div" no-gutters>
                                                <!--<v-col class="d-flex" cols="8">
                                                    <v-row dense no-gutters tag="div">
                                                        <v-col v-if="true" dense tag="div" sm="12">
                                                            <div class="font-weight-medium" v-text="`Date Range`"></div>
                                                        </v-col>
                                                        <v-col dense tag="div" sm="12">
                                                            <v-dialog ref="dateRangePickerDialog" v-model="dateRangePicker.dialog"
                                                                      :return-value.sync="createDialog.cycleCount.printed_by_pl.range"
                                                                      persistent width="290px">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-text-field
                                                                        :disabled="loading.state"
                                                                        :value="formatDateRange(createDialog.cycleCount.printed_by_pl.range)"
                                                                        hide-details
                                                                        readonly
                                                                        dense
                                                                        outlined
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                    ></v-text-field>
                                                                </template>
                                                                <v-date-picker range v-model="createDialog.cycleCount.printed_by_pl.range">
                                                                    <v-spacer></v-spacer>
                                                                    <v-btn text color="primary" @click="dateRangePicker.dialog = false">Cancel</v-btn>
                                                                    <v-btn text color="primary"
                                                                           @click="$refs.dateRangePickerDialog.save(createDialog.cycleCount.printed_by_pl.range)">OK</v-btn>
                                                                </v-date-picker>
                                                            </v-dialog>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>-->
                                                <!-- PRECEDING DAYS -->
                                                <v-col class="d-flex" cols="4">
                                                    <v-row dense no-gutters tag="div">
                                                        <v-col v-if="true" dense tag="div" sm="12">
                                                            <div class="font-weight-medium" v-text="`Preceding Days`"></div>
                                                        </v-col>
                                                        <v-col dense tag="div" sm="12">
                                                            <v-text-field
                                                                type="number"
                                                                min="0"
                                                                :disabled="loading.state"
                                                                hide-details
                                                                v-model="createDialog.cycleCount.time_prior.days"
                                                                @click="$event.target.focus()"
                                                                @focus="$event.target.select()"
                                                                @keypress="pressQuantity($event)"
                                                                @blur="blurQuantity(createDialog.cycleCount.time_prior, 'days');"
                                                                dense
                                                                outlined
                                                                flat
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <!-- PRECEDING MONTHS -->
                                                <v-col class="d-flex" cols="4">
                                                    <v-row dense no-gutters tag="div">
                                                        <v-col v-if="true" dense tag="div" sm="12">
                                                            <div class="font-weight-medium" v-text="`Preceding Months`"></div>
                                                        </v-col>
                                                        <v-col dense tag="div" sm="12">
                                                            <v-text-field
                                                                type="number"
                                                                min="0"
                                                                :disabled="loading.state"
                                                                hide-details
                                                                v-model="createDialog.cycleCount.time_prior.months"
                                                                @click="$event.target.focus()"
                                                                @focus="$event.target.select()"
                                                                @keypress="pressQuantity($event)"
                                                                @blur="blurQuantity(createDialog.cycleCount.time_prior, 'month');"
                                                                dense
                                                                outlined
                                                                flat
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <!-- ITEM PRESETS LIMITTER -->
                                                <v-col class="d-flex" cols="4">
                                                    <v-row dense no-gutters tag="div">
                                                        <v-col v-if="true" dense tag="div" sm="12">
                                                            <div class="font-weight-medium" v-text="`Limit`"></div>
                                                        </v-col>
                                                        <v-col dense tag="div" sm="12">
                                                            <v-autocomplete
                                                                :disabled="loading.state"
                                                                hide-details
                                                                item-text="text"
                                                                item-value="value"
                                                                :items="[{value:50,text:'50'},{value:100,text:'100'},{value:200,text:'200'}]"
                                                                v-model="createDialog.cycleCount.printed_by_pl.limit"
                                                                dense
                                                                outlined
                                                                flat
                                                            ></v-autocomplete>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <!-- BY STOCK TRANSFER -->
                            <v-row v-if="!isSelectedWarehouseDefault" tag="div" no-gutters class="px-2 mt-2">
                                <v-col class="d-flex" cols="12">
                                    <v-row dense no-gutters tag="div" style="border:1px solid #e0e0e0; border-radius: 4px;">
                                        <v-col dense tag="div" sm="12">
                                            <v-switch :disabled="loading.state" v-model="createDialog.cycleCount.by_outbound.filter" class="ml-2">
                                                <template v-slot:label>
                                                    <span class="font-weight-medium" style="font-size: 0.875rem !important;" v-text="`Filter by Top Outbound`"></span>
                                                </template>
                                            </v-switch>
                                        </v-col>
                                        <v-col v-if="createDialog.cycleCount.by_outbound.filter" dense tag="div" sm="12">
                                            <v-row tag="div" no-gutters>
                                                <!-- PRECEDING DAYS -->
                                                <v-col class="d-flex" cols="4">
                                                    <v-row dense no-gutters tag="div">
                                                        <v-col v-if="true" dense tag="div" sm="12">
                                                            <div class="font-weight-medium" v-text="`Preceding Days`"></div>
                                                        </v-col>
                                                        <v-col dense tag="div" sm="12">
                                                            <v-text-field
                                                                type="number"
                                                                min="0"
                                                                :disabled="loading.state"
                                                                hide-details
                                                                v-model="createDialog.cycleCount.time_prior.days"
                                                                @click="$event.target.focus()"
                                                                @focus="$event.target.select()"
                                                                @keypress="pressQuantity($event)"
                                                                @blur="blurQuantity(createDialog.cycleCount.time_prior, 'days');"
                                                                dense
                                                                outlined
                                                                flat
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <!-- PRECEDING MONTHS -->
                                                <v-col class="d-flex" cols="4">
                                                    <v-row dense no-gutters tag="div">
                                                        <v-col v-if="true" dense tag="div" sm="12">
                                                            <div class="font-weight-medium" v-text="`Preceding Months`"></div>
                                                        </v-col>
                                                        <v-col dense tag="div" sm="12">
                                                            <v-text-field
                                                                type="number"
                                                                min="0"
                                                                :disabled="loading.state"
                                                                hide-details
                                                                v-model="createDialog.cycleCount.time_prior.months"
                                                                @click="$event.target.focus()"
                                                                @focus="$event.target.select()"
                                                                @keypress="pressQuantity($event)"
                                                                @blur="blurQuantity(createDialog.cycleCount.time_prior, 'month');"
                                                                dense
                                                                outlined
                                                                flat
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <!-- ITEM PRESETS LIMITTER -->
                                                <v-col class="d-flex" cols="4">
                                                    <v-row dense no-gutters tag="div">
                                                        <v-col v-if="true" dense tag="div" sm="12">
                                                            <div class="font-weight-medium" v-text="`Limit`"></div>
                                                        </v-col>
                                                        <v-col dense tag="div" sm="12">
                                                            <v-text-field
                                                                :disabled="loading.state"
                                                                hide-details
                                                                v-model="createDialog.cycleCount.by_outbound.limit"
                                                                dense
                                                                outlined
                                                                type="number"
                                                                flat
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                        <v-tab-item :value="'tab-item-templates'" class="pb-4" style="border:1px solid #e0e0e0;">
                            <v-row tag="div" align="center" no-gutters class="px-2 mt-4">
                                <v-col class="d-flex" cols="12">
                                    <v-row dense no-gutters tag="div">
                                        <v-col dense tag="div" sm="12" class="d-flex justify-start">
                                            <div class="font-weight-medium" v-text="`Warehouse Item Template`"></div>
                                        </v-col>
                                        <v-col dense tag="div" sm="12">
                                            <v-autocomplete outlined dense
                                                :disabled="loading.state"
                                                hide-details
                                                aria-placeholder="Select Warehouse Item Template"
                                                item-text="text"
                                                item-value="value"
                                                class="cycle-count-text-sm"
                                                :items="cycleCountTemplate.selection"
                                                v-model="cycleCountTemplate.value"
                                                dense
                                                outlined
                                                flat
                                            ></v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                    </v-tabs>

                    <v-row tag="div" no-gutters class="px-2 mb-4">
                        <v-col class="d-flex" cols="12">
                            <v-row dense no-gutters tag="div" style="border:1px solid #e0e0e0; border-radius: 4px;">
                                <v-col dense tag="div" sm="12" class="d-flex justify-center">
                                    <v-switch :disabled="loading.state" v-model="createDialog.cycleCount.auto_approve" class="ml-2">
                                        <template v-slot:label>
                                            <span class="font-weight-medium" style="font-size: 0.875rem !important;" v-text="`Auto Approve`"></span>
                                        </template>
                                    </v-switch>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions class="d-flex justify-end">
                    <v-btn
                        small
                        :disabled="!warehouse.value || loading.state"
                        v-on:click="createDialog.cycleCount.type == 0 ? create() : schedule()">
                        <i class="fas fa-plus mr-1"></i>
                        <span v-text="createDialog.cycleCount.type == 0 ? `Create` : `Schedule`"></span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-tabs color="cyan" background-color="#424242" dark slider-color="cyan" class="col-12" v-model="warehouse.vtabs.tab">
            <v-tab v-for="(item,index) in warehouse.vtabs.selection" :key="index">{{item.code}}</v-tab>
            <v-tabs-items v-model="warehouse.vtabs.tab">
                <v-tab-item v-for="(item,index) in warehouse.vtabs.selection" :key="index">
                    <ItemTemplateComponentVue ref='template' v-show="warehouse.vtabs.tab == 0"> </ItemTemplateComponentVue>
                    <CycleCountComponentVue ref="warehouse" v-show="warehouse.vtabs.tab > 0" :warehouse_id="item.value" :form="form"></CycleCountComponentVue>
                </v-tab-item>
            </v-tabs-items>
        </v-tabs>
    </div>
</template>

<script>

import CycleCountComponentVue from "./CycleCountComponent.vue";
import ItemTemplateComponentVue from "../ItemTemplateComponent.vue"
import swal from "sweetalert2";
import { mapGetters } from "vuex";
import ENUMS from '@/enums';
export default {
    name: "CycleCountGroupComponent",

    components: {
        CycleCountComponentVue,
        ItemTemplateComponentVue
    },

    mounted(){
        let that = this;

        that.getWarehouses();
        that.getItemSizes();
        that.getSchedules();
        that.getItemTypes();
        that.getItemCategory();
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
    },

    data() {
        return {
            loading: {
                warehouse_selection     : true,
                item_type_selection     : true,
                item_category_selection : true
            },
            activeTab: 'tab-noneScheduled',
            cycleCountPreFill :{
                noneScheduled : {
                    type : [CYCLE_COUNT_TYPE.NONE_SCHEDULED],
                    schedule_interval : []
                },
                customScheduled : {
                    type : [CYCLE_COUNT_TYPE.CUSTOM_SCHEDULED],
                    schedule_interval: []
                },
                interval : {
                    daily : {
                        type : [CYCLE_COUNT_TYPE.SCHEDULED_BY_INTERVAL],
                        schedule_interval: [SCHEDULE_TYPE.DAILY]
                    },
                    weekly : {
                        type : [CYCLE_COUNT_TYPE.SCHEDULED_BY_INTERVAL],
                        schedule_interval: [SCHEDULE_TYPE.WEEKLY]
                    },
                    monthly : {
                        type : [CYCLE_COUNT_TYPE.SCHEDULED_BY_INTERVAL],
                        schedule_interval: [SCHEDULE_TYPE.MONTHLY]
                    },
                    quarterly : {
                        type : [CYCLE_COUNT_TYPE.SCHEDULED_BY_INTERVAL],
                        schedule_interval: [SCHEDULE_TYPE.QUARTERLY]
                    },
                    yearly : {
                        type : [CYCLE_COUNT_TYPE.SCHEDULED_BY_INTERVAL],
                        schedule_interval: [SCHEDULE_TYPE.YEARLY]
                    },
                    two_weeks : {
                        type : [CYCLE_COUNT_TYPE.SCHEDULED_BY_INTERVAL],
                        schedule_interval: [SCHEDULE_TYPE.TWO_WEEKS]
                    }
                }
            },

            loading: {
                title: 'Please wait',
                state: false
            },

            datePicker:{
                dialog: false
            },

            dateRangePicker:{
                dialog: false
            },

            timePicker:{
                dialog: false
            },

            createDialog:{
                dialog: false,
                activeTab: 'tab-system-generated',
                cycleCount: {
                    auto_approve: false,
                    type: 0,
                    interval_type: null,
                    interval_day: null,
                    date: this.$dayjs().add(1, 'd').format('YYYY-MM-DD'),
                    time: '00:00',
                    time_prior : {
                        days : 0,
                        months : 0
                    },
                    printed_by_pl: {
                        filter: false,
                        limit: 50,
                        //range: [this.$dayjs().format('YYYY-MM-DD'), this.$dayjs().add(1, 'd').format('YYYY-MM-DD')]
                    },
                    by_outbound: {
                        filter: false,
                        limit: 50,
                        //range: [this.$dayjs().format('YYYY-MM-DD'), this.$dayjs().add(1, 'd').format('YYYY-MM-DD')]
                    }
                }
            },

            scheduleDialog:{
                loading: false,
                dialog: false,
                datatable : {
                    search: '',
                    filters: {},
                    headers: [
                        {text: 'WAREHOUSE', value: 'warehouse.name', sortable: false},
                        {text: 'USER', value: 'user.name', sortable: false},
                        {text: 'AUTO APPROVE', align: 'center', value: 'auto_approve', sortable: false},
                        {text: 'SCHEDULE', value: 'schedule', sortable: false},
                        {text: 'ITEMS', value: 'item_filter_description', sortable: false},
                        {text: 'ACTIONS', align: 'center', value: 'action', sortable: false },
                    ],
                    pagination : {
                        options: {},
                        total : 0,
                        perPageDefault: 25
                    },
                },
            },

            cycleCountscheduleType:{
                selection:[
                    {value:null,text:'Select Frequency'},
                    {value:0,text:'Daily'},
                    {value:1,text:'Weekly'},
                    {value:5,text:'Every 2 Weeks'},
                    {value:2,text:'Monthly'},
                    {value:3,text:'Quarterly'},
                    {value:4,text:'Yearly'}
                ]
            },

            dayOfWeek:{
                selection:[
                    {value:null,text:'Select day of week'},
                    {value:0,text:'Sunday'},
                    {value:1,text:'Monday'},
                    {value:2,text:'Tuesday'},
                    {value:3,text:'Wednesday'},
                    {value:4,text:'Thursday'},
                    {value:5,text:'Friday'},
                    {value:6,text:'Saturday'}
                ]
            },

            dayOfMonth:{
                selection:[
                    {value:null,text:'Select first of month'}
                ]
            },

            cycleCountType:{
                selection:[
                    {value:0,text:'Immediate / None Scheduled'},
                    {value:1,text:'Custom Scheduled'},
                    {value:2,text:'Schedule by Frequency / Interval'}
                ]
            },

            cycleCountTemplate:{
                selection: [{
                    value: null,
                    text: 'NONE SELECTED'
                }],
                value: null
            },

            warehouse: {
                selection: [{
                    value: null,
                    text: 'NONE SELECTED'
                }],
                vtabs:{
                    selection:[],
                    tab:0
                },
                value: null,
                no_virtual:false

            },

            itemMovements: {
                search: '',
                data: [],
                selection: [],
                selected: []
            },

            itemSizes: {
                search: '',
                data: [],
                selection: [],
                selected: []
            },

            itemType:{
                data:[],
                selection:[],
                selected:[],
            },

            itemCategory:{
                data:[],
                selection:[],
                selected:[]
            },
            form:null,
        }
    },

    computed:{
        ...mapGetters([
            'GET_ALL_WAREHOUSES',
            'GET_ITEM_TYPES',
            'GET_ITEM_SIZE',
            'GET_ALL_ITEM_CATEGORIES',
            'GET_ITEM_TEMPLATE',
            'GET_SCHEDULE_CYCLE_COUNT',
            ''
        ]),
        scheduleByIntervalColumnDistriution() {
            return {
                [null] : 6,
                [SCHEDULE_TYPE.DAILY] : 6,
                [SCHEDULE_TYPE.WEEKLY] : 4,
                [SCHEDULE_TYPE.MONTHLY] : 6,
                [SCHEDULE_TYPE.QUARTERLY] : 6,
                [SCHEDULE_TYPE.YEARLY] : 6
            }[this.createDialog.cycleCount.interval_type];
        },

        isSelectedWarehouseDefault(){
            let that = this;

            let isDefault = {
                [undefined] : false,
                [0] : false,
                [1] : true,
            }[_.filter(that.warehouse.selection, (warehouse) => {
                return warehouse.value == that.warehouse.value
            })[0].default];

            if(isDefault){
                that.createDialog.cycleCount.by_outbound.filter = false;
            } else {
                that.createDialog.cycleCount.printed_by_pl.filter = false;
            }
            return isDefault;
        }
    },

    methods: {
        getSeachFromUrl(){
            let that = this

            let warehouse_id =  new URL(location.href).searchParams.get('warehouse_id')
            let form =  new URL(location.href).searchParams.get('form')

            if(warehouse_id != null && form != null)
            {
                warehouse_id = parseInt(warehouse_id)
                that.form = form
            }else {
                return false
            }

            that.warehouse.vtabs.tab = that.warehouse.vtabs.selection.map(object => object.value).indexOf(warehouse_id)
            this.$forceUpdate();
        },

        selectedAllFilterModuleOptions(selection, selected) {
            return selected.length === selection.length;
        },

        selectedSomeFilterModuleOptions(selection, selected) {
            return selected.length > 0 && !this.selectedAllFilterModuleOptions(selection, selected);
        },

        filterSelectionIcon(selection, selected){
            if (this.selectedAllFilterModuleOptions(selection, selected)) return 'mdi-close-box'
            if (this.selectedSomeFilterModuleOptions(selection, selected)) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },

        toggleFilterSelection(filter) {
            this.$nextTick(() => {
                if (this.selectedAllFilterModuleOptions(filter.selection, filter.selected)) {
                    filter.selected = [];
                } else {
                    filter.selected = filter.selection.map(filter => filter.value);
                }
            })
        },

        searchFilterSelection(filter){
            if (!filter.search){
                filter.selection = filter.data;
            }

            filter.selection = filter.data.filter(data => {
                return data.text.toLowerCase().indexOf(filter.search.toLowerCase()) > -1
            })
        },

        toggleDialog(dialogable){
            let that = this;

            if (that.loading.state || !dialogable) {
                return false;
            }

            dialogable = dialogable.split('.');

            if(dialogable.length === 1 ){
                that[dialogable].dialog = !that[dialogable].dialog;
            } else {

                let path = that;

                dialogable.forEach(i => {
                    path = path[i];
                });

                path.dialog = !path.dialog;
            }
        },

        toggleLoading(){
            this.loading.state = !this.loading.state;
        },

        formatDateRange(dateRange){
            return dateRange.length > 1
                ? (this.$dayjs(dateRange[0]).diff(this.$dayjs(dateRange[1])) > 0
                    ? this.$dayjs(dateRange[1]).format('MMM Do YYYY') + ' - ' + this.$dayjs(dateRange[0]).format('MMM Do YYYY')
                    : (this.$dayjs(dateRange[0]).diff(this.$dayjs(dateRange[1])) === 0
                        ? this.$dayjs(dateRange[0]).format('MMM Do YYYY')
                        : this.$dayjs(dateRange[0]).format('MMM Do YYYY') + ' - ' + this.$dayjs(dateRange[1]).format('MMM Do YYYY')))
                : this.$dayjs(dateRange[0]).format('MMM Do YYYY');
        },

        resetSelections(){
            this.warehouse.value = null;
            this.cycleCountTemplate.value = null;
            this.itemSizes.selected = [];
            this.itemCategory.selected = [];
            this.itemType.selected = [];

            // Reset Selection/Dialog Values
            this.createDialog.cycleCount = {
                auto_approve: false,
                type: 0,
                interval_type: null,
                interval_day: null,
                date: this.$dayjs().add(1, 'd').format('YYYY-MM-DD'),

                /*
                * Prefill by
                * today : this.$dayjs().format('HH:mm')
                * or midnight: '00:00'
                * */
                time: '00:00',
                time_prior : {
                    days : 0,
                    months : 0
                },
                printed_by_pl: {
                    filter: false,
                    limit: 50,
                    //range: [this.$dayjs().format('YYYY-MM-DD'), this.$dayjs().add(1, 'd').format('YYYY-MM-DD')]
                },
                by_outbound: {
                    filter: false,
                    limit: 50
                }
            }
        },

        async getWarehouses(){
            let that = this
            let payload = {
                no_virtual:that.warehouse.no_virtual
            }
            await that.$store.dispatch('getAllWarehouses',payload).then((response)=>{
                that.warehouse.selection = [{
                    value: null,
                    text: 'NONE SELECTED'
                }].concat(this.GET_ALL_WAREHOUSES);
                that.warehouse.vtabs.selection = [{value:0,text:'Template',code:'Template'}]
                this.GET_ALL_WAREHOUSES.forEach(e=>{
                    that.warehouse.vtabs.selection.push(e)
                })
            });
            this.getSeachFromUrl()

        },

        getItemSizes(){
            this.$store.dispatch('getItemSize').then((response)=>{
                let selection = this.GET_ITEM_SIZE.data.reduce((selection, data)=>{
                    selection.push({
                        value: data.value,
                        text: data.text
                    });

                    return selection;
                },[]);

                this.itemSizes.selection = selection;
                this.itemSizes.data = selection;
            });
        },

        getItemTypes(){
            let that = this;
            that.$store.dispatch('getItemTypes').then((response)=>{
                that.GET_ITEM_TYPES.forEach(e=>{
                    that.itemType.selection.push({value : e.value, text : e.text})
                })
                that.itemType.data = that.itemType.selection
                // that.itemType.selected = that.itemType.selection
            })

        },

        getItemCategory(){
            let that = this
            that.$store.dispatch('getAllItemCategories').then(response=>{
                that.GET_ALL_ITEM_CATEGORIES.forEach(e=>{

                    this.itemCategory.selection.push({value : e.id, text : e.name})
                })

                that.itemCategory.data = that.itemCategory.selection
            })

        },

        // getSelectedItemSizes(itemSize){
        //     this.itemSizes.selected = itemSize
        // },

        getSelectedItemType(val){
            this.itemType.selected = val
        },

        getSelectedItemCategory(val){
            this.itemCategory.selected = val
        },

        getWarehouseItemTemplate(params = {page: 1, itemsPerPage: 10}, itemTemplates = []){
            let that = this;

            if(_.isNull(that.warehouse.value)){
                return false;
            }

            that.loading.state = true;

            that.cycleCountTemplate.value = null;
            let payload = {
                ...params, warehouse_id:[that.warehouse.value]
            }
            that.$store.dispatch('getItemTemplate',payload).then((response)=>{
                that.GET_ITEM_TEMPLATE.item_templates.data.forEach(data => {
                    itemTemplates.push(data);
                })

                if(params.page < that.GET_ITEM_TEMPLATE.item_templates.last_page){
                    that.getWarehouseItemTemplate(Object.assign({},{...params, page : params.page + 1}), itemTemplates);
                } else {

                    that.cycleCountTemplate.selection = [{
                        value: null,
                        text: 'NONE SELECTED'
                    }].concat(itemTemplates.reduce((selection, itemTemplate)=>{
                        selection.push({
                            value: itemTemplate.id,
                            text: `${itemTemplate.template_number} ${itemTemplate.name}`
                        });

                        return selection;
                    },[]));

                    that.loading.state = false;
                }
            });
        },

        create(){

            let that = this;

            if (that.loading.state) {
                return false;
            }

            that.toggleLoading();
            let cycleCount = Object.assign({},{
                tab:            that.createDialog.activeTab,
                template_id:    that.cycleCountTemplate.value,
                warehouse_id:   that.warehouse.value,
                auto_approve:   that.createDialog.cycleCount.auto_approve,
                type:           that.createDialog.cycleCount.type,

                // Attributes for scheduled
                interval_type:  that.createDialog.cycleCount.interval_type == null ? -1 : that.createDialog.cycleCount.interval_type,
                interval_day:   that.createDialog.cycleCount.interval_day,
                date:           that.createDialog.cycleCount.date,
                time:           that.createDialog.cycleCount.time,

                // item_filter : serialization of item filters
                item_movement: [],
                item_sizes:         that.itemSizes.selected.map(e=> {return e.value}),
                item_category:      that.itemCategory.selected.map(e=> {return e.value}),
                item_type:          that.itemType.selected.map(e=> {return e.value}),
                time_prior : {
                    days :      that.createDialog.cycleCount.time_prior.days,
                    months :    that.createDialog.cycleCount.time_prior.months
                },
                printed_by_pl: {
                    filter:     that.createDialog.cycleCount.printed_by_pl.filter,
                    limit:      that.createDialog.cycleCount.printed_by_pl.limit
                },
                by_outbound: {
                    filter:     that.createDialog.cycleCount.by_outbound.filter,
                    limit:      that.createDialog.cycleCount.by_outbound.limit
                }
            });

            this.$store.dispatch('createCycleCount',cycleCount).then((response) => {

                swal.fire({
                    title: `Cycle Count Created : ${response.data.cycle_count.cycle_count_form_number}`,
                    text: {
                        0: "Cycle Count is now waiting for approval.",
                        1: "Cycle Count approved."
                    }[response.data.cycle_count.status]
                });

                that.resetSelections();
                that.toggleLoading();
                that.errors = [];
                that.toggleDialog(`createDialog`);
                that.$refs.template[0].list();

            }).catch((error) => {
                that.toggleLoading();
                that.alertWarnings(error.response.data.message, error.response.data.errors);
            });
        },

        schedule(){
            let that = this;

            if (that.loading.state) {
                return false;
            }

            that.toggleLoading();
            let itemFilter = {
                // item_filter : to be serialized
                item_movement: [],
                item_sizes: that.itemSizes.selected,
                item_sizes:         that.itemSizes.selected.map(e=> {return e.value}),
                item_category:      that.itemCategory.selected.map(e=> {return e.value}),
                item_type:          that.itemType.selected.map(e=> {return e.value}),
                time_prior : {
                    days :      that.createDialog.cycleCount.time_prior.days,
                    months :    that.createDialog.cycleCount.time_prior.months
                },
                printed_by_pl: {
                    filter:     that.createDialog.cycleCount.printed_by_pl.filter,
                    limit:      that.createDialog.cycleCount.printed_by_pl.limit
                },
                by_outbound: {
                    filter:     that.createDialog.cycleCount.by_outbound.filter,
                    limit:      that.createDialog.cycleCount.by_outbound.limit
                }
            };

            let cycleCount = Object.assign({},{
                tab:            that.createDialog.activeTab,
                template_id:    that.cycleCountTemplate.value,
                warehouse_id:   that.warehouse.value,
                auto_approve:   that.createDialog.cycleCount.auto_approve,
                type:           that.createDialog.cycleCount.type,

                // Attributes for scheduled
                interval_type:  that.createDialog.cycleCount.interval_type,
                interval_day:   that.createDialog.cycleCount.interval_day,
                date:           that.createDialog.cycleCount.date,
                time:           that.createDialog.cycleCount.time,
                item_filter_description: _.isNull(that.cycleCountTemplate.value) ? that.itemFilterDescriptionBuilder(itemFilter): null,
                ...itemFilter
            });

            that.$store.dispatch('scheduleCycleCount',cycleCount).then((response) => {

                swal.fire({title: `Cycle Count Scheduled`});

                that.resetSelections();
                that.toggleLoading();
                that.toggleDialog(`createDialog`);
            }).catch((error) => {
                that.toggleLoading();
                that.alertWarnings(error.response.data.message, error.response.data.errors);
            });
        },

        getSchedules(page = 1){

            let that = this;

            that.scheduleDialog.datatable.pagination.options.page = page;

            if (that.scheduleDialog.loading) {
                return false;
            }

            that.scheduleDialog.loading = true;
            let payload = {
                page: page,
                itemsPerPage: that.scheduleDialog.datatable.pagination.options.itemsPerPage
                    || that.scheduleDialog.datatable.pagination.perPageDefault
            }
            that.$store.dispatch('getScheduleCycleCount',payload).then((response) => {

                that.scheduleDialog.data = this.GET_SCHEDULE_CYCLE_COUNT.schedules.data;
                that.scheduleDialog.datatable.pagination.total = this.GET_SCHEDULE_CYCLE_COUNT.schedules.total;
                that.scheduleDialog.loading = false;
            });
        },

        scheduleDescription(cycleCountSchedule){
            return {
                [ENUMS.CYCLE_COUNT_TYPE.CUSTOM_SCHEDULED] : cycleCountSchedule.date + ' @ ' + this.$dayjs(cycleCountSchedule.time, "HH:mm:ss").format("HH:mm"),
                [ENUMS.CYCLE_COUNT_TYPE.SCHEDULED_BY_INTERVAL] : {
                    [ENUMS.SCHEDULE_TYPE.DAILY] : 'Daily @ ' + this.$dayjs(cycleCountSchedule.time, "HH:mm:ss").format("HH:mm"),
                    [ENUMS.SCHEDULE_TYPE.WEEKLY] : {
                        0 : 'Weekly : Every Sunday @ ' + this.$dayjs(cycleCountSchedule.time, "HH:mm:ss").format("HH:mm"),
                        1 : 'Weekly : Every Monday @ ' + this.$dayjs(cycleCountSchedule.time, "HH:mm:ss").format("HH:mm"),
                        2 : 'Weekly : Every Tuesday @ ' + this.$dayjs(cycleCountSchedule.time, "HH:mm:ss").format("HH:mm"),
                        3 : 'Weekly : Every Wednesday @ ' + this.$dayjs(cycleCountSchedule.time, "HH:mm:ss").format("HH:mm"),
                        4 : 'Weekly : Every Thursday @ ' + this.$dayjs(cycleCountSchedule.time, "HH:mm:ss").format("HH:mm"),
                        5 : 'Weekly : Every Friday @ ' + this.$dayjs(cycleCountSchedule.time, "HH:mm:ss").format("HH:mm"),
                        6 : 'Weekly : Every Saturday @ ' + this.$dayjs(cycleCountSchedule.time, "HH:mm:ss").format("HH:mm"),
                    }[cycleCountSchedule.interval_day],

                    [ENUMS.SCHEDULE_TYPE.MONTHLY] : 'Monthly @ ' + this.$dayjs(cycleCountSchedule.time, "HH:mm:ss").format("HH:mm"),
                    [ENUMS.SCHEDULE_TYPE.QUARTERLY] : 'Quarterly : [Jan 01, Apr 01, Jul 01, Oct 01] @ 00:00 Midnight',
                    [ENUMS.SCHEDULE_TYPE.YEARLY] : 'Yearly : Every Jan 01 @ 00:00 Midnight',
                        [ENUMS.SCHEDULE_TYPE.TWO_WEEKS] : {
                        0 : 'Weekly : Every Sunday @ ' + this.$dayjs(cycleCountSchedule.time, "HH:mm:ss").format("HH:mm"),
                        1 : 'Weekly : Every Monday @ ' + this.$dayjs(cycleCountSchedule.time, "HH:mm:ss").format("HH:mm"),
                        2 : 'Weekly : Every Tuesday @ ' + this.$dayjs(cycleCountSchedule.time, "HH:mm:ss").format("HH:mm"),
                        3 : 'Weekly : Every Wednesday @ ' + this.$dayjs(cycleCountSchedule.time, "HH:mm:ss").format("HH:mm"),
                        4 : 'Weekly : Every Thursday @ ' + this.$dayjs(cycleCountSchedule.time, "HH:mm:ss").format("HH:mm"),
                        5 : 'Weekly : Every Friday @ ' + this.$dayjs(cycleCountSchedule.time, "HH:mm:ss").format("HH:mm"),
                        6 : 'Weekly : Every Saturday @ ' + this.$dayjs(cycleCountSchedule.time, "HH:mm:ss").format("HH:mm"),
                    }[cycleCountSchedule.interval_day],
                }[cycleCountSchedule.interval_type]
            }[cycleCountSchedule.type];
        },

        itemFilterDescription(cycleCountSchedule){

            let description = '';

            if(!_.isNull(cycleCountSchedule.template_id)){
                description = cycleCountSchedule.template.template_number + ' ' + cycleCountSchedule.template.name
            } else {
                description = this.ucFirst(cycleCountSchedule.item_filter_description);
            }

            return description;
        },

        ucFirst(string) {

            return string.charAt(0).toUpperCase() + string.slice(1);
        },

        destroySchedule(cycleCountSchedule){
            swal.fire({
                title: "Confirm Delete",
                text: ``,
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((confirm) => {
                if(confirm){
                    let that = this;

                    if (that.scheduleDialog.loading) {
                        return false;
                    }
                    that.scheduleDialog.loading = true;
                    that.$store.dispatch('destroyCycleCountSchedule',cycleCountSchedule.id).then((response) => {
                        that.scheduleDialog.loading = false;
                        swal.fire({title: `Cycle Count Schedule Deleted`});
                        that.getSchedules();
                    }).catch((error)=>{
                        that.scheduleDialog.loading = false;
                        that.alertWarnings(error.response.data.message, error.response.data.errors);
                    });
                }
            });
        },

        /**
         * Readable Item Filter Description
         * base on filter settings
         *
         * @return string
         */
        itemFilterDescriptionBuilder(filter){
            let that = this;

            let description = [];

            description.push(filter[`item_movement`].length
                ? that.collectionToReadable(filter[`item_movement`], that.itemMovements.data) + ` moving items`
                : `all items`);

            description.push(filter[`item_sizes`].length
                ? `with size${filter[`item_sizes`].length > 1 ? 's' : ''} of ` + that.collectionToReadable(filter[`item_sizes`], that.itemSizes.data)
                : `of all sizes`);

            description.push(filter[`printed_by_pl`].filter
                ? `printed @ top ${filter[`printed_by_pl`].limit} PL ${filter['time_prior']['days']} day/s and ${filter['time_prior']['month']} month/s prior to schedule`
                //? `printed @ top ${filter[`printed_by_pl`].limit}  PL of ${filter[`printed_by_pl`].range.join(' to ')}`
                : ``);

            description.push(filter[`by_outbound`].filter
                ? `@ top ${filter[`by_outbound`].limit} outbound ${filter['time_prior']['days']} day/s and ${filter['time_prior']['month']} month/s prior to schedule`
                : ``);
            return description.join(` `);
        },

        /**
         * Filter source base on selected
         * then perform collection to readable
         *
         * @return string
         */
        collectionToReadable(data, sourceCollection){
            data = data.map(e=>e.value)

            let collection = _.filter(sourceCollection, (sc) => {

                return data.indexOf(sc.value) >= 0;
            });

            let collectionLength = collection.length - 1;
            let secondToLast = collection.length - 2;

            return collection.reduce((sentence, cursor, index) => {

                if (index == collectionLength) {

                    if (collectionLength >= 1) {
                        sentence = sentence + ` and `;
                    }

                    sentence = sentence + `${cursor.text.toLowerCase()}`;

                } else {
                    sentence = sentence + `${cursor.text.toLowerCase()}`;

                    if (collectionLength > 1 && index != secondToLast) {
                        sentence = sentence + `,`;
                    }
                }

                return sentence;
            }, '');
        },

        alertWarnings(message, errors){
            let that = this;
            let errorMessages = [];

            Object.keys(errors).forEach((key) => {

                errors[key].forEach(message => {
                    errorMessages.push(`- ${message}`);
                })
            })

            swal.fire({
                title: message,
                text: errorMessages.join("\n"),
                icon: "warning",
                dangerMode: true,
            });
        },

        pressQuantity(event) {
            let charCode = (event.which) ? event.which : event.keyCode;

            if(charCode < 48 || charCode > 57){
                event.preventDefault();
            } else {
                return true;
            }
        },

        blurQuantity(list, key){
            let quantity = list[key];
            let value = (!isNaN(quantity) && quantity && (Math.abs(parseInt(quantity)) > 0)) ? Math.abs(parseInt(quantity)) : 0;

            list[key] = value;
        },
    },

    watch: {
        'warehouse.value': {
            handler() {
                this.getWarehouseItemTemplate();
            }
        },
        'createDialog.cycleCount.printed_by_pl.filter': {
            handler(value) {
                if(value){
                    ENUMS.cycleCountTemplate.value = null;
                }
            }
        },
        'createDialog.activeTab': {
            handler(value) {
                switch(value){
                    case 'tab-system-generated':
                        ENUMS.cycleCountTemplate.value = null;
                        break;
                    case 'tab-item-templates':
                        this.createDialog.cycleCount.printed_by_pl.filter = false;
                        this.createDialog.cycleCount.by_outbound.filter = false;
                        break;
                }
            }
        },
        'cycleCountTemplate.value': {
            handler(value) {
                if(value){
                    this.createDialog.cycleCount.printed_by_pl.filter = false;
                    this.createDialog.cycleCount.by_outbound.filter = false;
                }
            }
        },
        'scheduleDialog.datatable.pagination.options': {
            handler() {
                this.getSchedules(ENUMS.scheduleDialog.datatable.pagination.options.page)
            },
            deep: true,
        },
        'warehouse.no_virtual':{
            handler(val){
                let that = this

                that.warehouse.vtabs.selection = []
                that.warehouse.no_virtual = val
                that.getWarehouses()
            }
        },
        'GET_ALL_WAREHOUSES':{
            handler(val){
                this.dealersList = val.data
                this.loading.warehouse_selection = false
            }
        },
        'GET_ALL_ITEM_CATEGORIES':{
            handler(val){
                this.dealersList = val.data
                this.loading.item_category_selection = false
            }
        },
        'GET_ITEM_TYPES':{
            handler(val){
                this.dealersList = val.data
                this.loading.item_type_selection = false
            }
        },
    },
}
</script>

<style scoped>

</style>
