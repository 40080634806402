	<template>
		<v-dialog ref="form" v-model="GET_EMPLOYEE_EMAIL_DIALOG" width="30%" persistent>
			<v-card>
				<v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
					<v-row :class="`m-0 ${ $vuetify.breakpoint.name == 'xs' ||  $vuetify.breakpoint.name == 'sm' ||  $vuetify.breakpoint.name == 'md' ? 'd-flex flex-column-reverse' : ''}`">
						<v-col :class="`p-2 ${  $vuetify.breakpoint.name == 'xs' ||  $vuetify.breakpoint.name  == 'sm' ||  $vuetify.breakpoint.name == 'md'  ?  'd-flex justify-center' :''}`" cols="12" sm="12" md="12" lg="11" xl="11">
							<span class="text-h5">{{actions}} EMPLOYEE EMAIL ACCOUNTS</span>
						</v-col>
						<v-col :class="`p-2 pull-right ${ $vuetify.breakpoint.name == 'xs' ||  $vuetify.breakpoint.name  == 'sm' ? 'pull-right' :''}`" cols="12" sm="12" md="12" lg="1" xl="1">
							<v-btn text icon small color="gray" class="float-right" @click="closeDialog()">
								<v-icon>mdi-close-circle</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-card-title>
				<v-card-text class="pa-2 pb-0">
					<v-container fluid class="py-5 pa-2">
						<v-row class="pa-2">
							<v-text-field
								v-model="form.full_name"
								readonly
								label="Full Name"
								outlined
								required
								dense
							></v-text-field>
						</v-row>	
						<v-row class="pa-2">
							<v-text-field
								v-model="form.personal"
								:readonly="disabled_view"
								label="Email Account"
								outlined
								required
								dense
							></v-text-field>
						</v-row>			
						<v-row class="pa-2">
							<v-text-field
								v-model="form.gmail"
								:readonly="disabled_view"
								label="Gmail Account"
								outlined
								required
								dense
							></v-text-field>
						</v-row>			
						<v-row class="pa-2">
							<v-text-field
								v-model="form.corporate"
								:readonly="disabled_view"
								label="Corporate Account"
								outlined
								required
								dense
							></v-text-field>
						</v-row>			
					</v-container>
				</v-card-text>

				<v-card-actions class="text-center">
					<v-row class="ma-1">
						<v-col cols="12" v-if="actions != 'VIEW'">
							<v-btn :loading="loading.button" class="float-center" :disabled="disabled_view" @click="updateEmailAccount()">
								<span>{{ actions }}</span>
							</v-btn>
						</v-col>
					</v-row>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</template>
	<script>
	import Swal from 'sweetalert2'
	import { mapGetters } from 'vuex'
	export default {
	data() {
		return {
			valid: false,
			form: {},
			disabled_view:false,
			quotaIsDisabled: false,
			showPassword: false,
			loading:{
				button:false
			},
			breakpoint : this.$vuetify.breakpoint.name,
			dropdowns:{
				employee_list:[],
				department_list:[]
			},
			component_dispatches:[
				"getDepartment",
				"getEmployeeDataDropdown"
			]
		}
	},
	computed: {
		...mapGetters(['ACTION',
			'DIALOGS',
			'USERACCOUNT_LOAD',
			'GET_EMPLOYEE_EMAILS',
			'GET_WEBMAIL_NEW_DATA_TRIGGER',
			'GET_WEBMAIL_TAB_IS_ACTIVE',
			'GET_WEBMAIL_DIALOG_TITLE',
			'GET_WEBMAIL_DISPATCH',
			'GET_LIST_DEPARTMENT',
			'GET_EMPLOYEE_EMAIL_DIALOG'
		]),
		actions() {
			if (this.ACTION == 'VIEW') {
				this.disabled_view = true;
				this.getWebEmail()
				return 'VIEW'
			}
			if (this.ACTION == 'Update') {
				this.disabled_view = false;
				this.getWebEmail()
				return 'UPDATE'
			}
		},
	},
	mounted(){   
		this.getAllDropdowns()
	},
	components: {
		},
	methods: {
		getAllDropdowns(){
			this.component_dispatches.forEach(el=>{
				this.$store.dispatch(el)
			})
		},
		closeDialog() {
            this.$store.commit('EMPLOYEE_EMAIL_DIALOG',false);
			this.resetfields()
		},
		async getWebEmail(){
			this.form.full_name  	= this.GET_EMPLOYEE_EMAILS.full_name ;
			this.form.employee_id	= this.GET_EMPLOYEE_EMAILS.employee_id
			this.form.corporate    	= this.GET_EMPLOYEE_EMAILS.corporate ;
			this.form.gmail    		= this.GET_EMPLOYEE_EMAILS.gmail ;
			this.form.personal    	= this.GET_EMPLOYEE_EMAILS.personal ;
		},
		resetfields(){
			this.form = {
				full_name:'',
				corporate:'',
				gmail:'',
				personal:'',
			};
		},
		updateEmailAccount(){
			let payload = {
				employee_id : this.form.employee_id,
				emails: [
					{Corporate:this.form.corporate},
					{Gmail:this.form.gmail},
					{Personal:this.form.personal}
				]
			}
			this.$store.dispatch('updateEmployeeDataEmails',payload).then(response=>{
				Swal.fire({
					text: response.message,
					icon: response.icon,
					allowOutsideClick: false,
				}).then((result) => {
					if (result.isConfirmed) {
							this.closeDialog()
					}
				});
			})
		}
	},
	watch: {
		DIALOGS:{
			handler(val){
				if(!val){
					this.resetfields()
				}
			}
		},
		'form.edit_pop_quota':{
			deep:true,
			handler(val){
				if(val != 0){
					this.form.unlimited_quota = false
				}
			}
		},
		GET_LIST_DEPARTMENT:{
			deep:true,
			handler(val){
				this.dropdowns.department_list = val
			}
		},
		GET_EMPLOYEE_DATA:{
			deep:true,
			handler(val){
				this.dropdowns.employee_list = val
			}
		}
	},
};
</script>
	