<template>
    <div class="page-wrapper">
        <v-container>
            <h4 class="page-title">Purchase Order</h4>
            <v-btn class="mx-1 my-2" v-on:click="toggleDialog(`create`);" v-if="userAccess.create || is_super_admin==1">Purchase Order</v-btn>
            <v-btn class="mx-1 my-2" v-on:click="toggleDialog(`china_pending`); addInitialTextAreas()" v-if="userAccess.add_china_pending || is_super_admin==1">Upload China Pending</v-btn>
            <!-- CHINA PENDING ORDER DIALOG -->
            <v-dialog v-model="china_pending.dialog" scrollable persistent style="z-index: 70;" :width="width">
                <v-card>
                    <v-row class="m-0">
                        <v-card-title>
                            <span class="text-lg font-semibold" v-text="`Paste From Excel`"></span>
                        </v-card-title>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right" @click="toggleDialog(`china_pending`); clearBatchAdditionData('close')">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card-text>
                        <v-row class="ma-0 pa-0">
                            <v-col>
                                <!-- <div class="ma-0" style="max-height:400px; margin-bottom:10px; overflow-y:auto; overflow-x:hidden">
                                    <v-row class="mb-2 mt-1 mx-auto">
                                        <v-col cols="5">Model</v-col>
                                        <v-col cols="5">China Pending</v-col>
                                        <v-col cols="2" class="text-center">Action</v-col>
                                    </v-row>
                                    <v-row class="mx-0 pa-0 ma-0" v-for="(text_area,i) in china_pending.text_areas" :key="i">
                                        <v-col cols="5" class="pa-0 ma-0 my-1">
                                            <v-text-field
                                            v-model="text_area.model"
                                            dense
                                            outlined
                                            hide-details
                                            @paste="onPaste"
                                            class="mx-1"
                                            :background-color="text_area.color"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="5" class="py-0 my-0 my-1">
                                            <v-text-field
                                            v-model="text_area.quantity"
                                            dense
                                            outlined
                                            hide-details
                                            class="mx-1"
                                            :background-color="text_area.color"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="2" class="ma-0 text-center my-1">
                                            <v-btn
                                                style="height:20px; width:20px;"
                                                color="primary"
                                                fab
                                                dark
                                                small
                                                @click="removeBatchAdditionItem(i)"
                                            >
                                                <v-icon dark small style="font-size:12px">mdi-minus</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-row class="mx-0 pa-0 ma-0">
                                        <v-col>
                                            <v-btn
                                                dark
                                                small
                                                color="primary"
                                                style="height: 20; width: 40px"
                                                @click="manualAddItem()"
                                            >
                                                <span> Add </span>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div> -->
                                <div class="ma-0" style="max-height:400px; margin-bottom:10px; overflow-y:auto; overflow-x:hidden">
                                    <table>
                                        <thead>
                                            <tr class="mb-2 mt-1 mx-auto">
                                                <th class="text-left">Model</th>
                                                <th class="text-left">China Pending</th>
                                                <th class="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="mx-0 pa-0 ma-0" v-for="(text_area,i) in china_pending.text_areas" :key="i">
                                                <td class="pa-0 ma-0 my-1">
                                                    <input
                                                        type="text"
                                                        v-model="text_area.model"
                                                        class="mx-1 input-with-border"
                                                        @paste="onPaste"
                                                        :style="{ backgroundColor: text_area.color }"
                                                    />
                                                </td>
                                                <td class="py-0 my-0 my-1">
                                                    <input
                                                        type="text"
                                                        v-model="text_area.quantity"
                                                        class="mx-1 input-with-border"
                                                        :style="{ backgroundColor: text_area.color, textAlign: 'right'  }"
                                                    />
                                                </td>
                                                <td class="ma-0 text-center my-1">
                                                    <button
                                                        style="height:20px; width:20px;"
                                                        class="btn-primary btn-dark btn-small"
                                                        @click="removeBatchAdditionItem(i)"
                                                    >
                                                        <i class="mdi mdi-minus" style="font-size:12px;"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr class="mx-0 pa-0 ma-0">
                                                <td colspan="3">
                                                    <v-btn
                                                        small
                                                        color="primary"
                                                        style="height: 20px; width: 40px"
                                                        @click="manualAddItem()"
                                                    >
                                                        <span>Add</span>
                                                    </v-btn>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-row class="mx-0 pa-0 ma-0 mt-2">
                            <v-col cols="12">
                                <v-btn :loading="loading.state" class="float-right ml-2" small @click="submittedBtn()">Submit</v-btn>
                                <v-btn :loading="loading.state" class="float-right" small dark color="secondary" @click="clearBatchAdditionData('clear')">Clear</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- CREATE PURCHASE ORDER DIALOG -->
            <v-dialog v-model="create.dialog" scrollable persistent max-width="740px" style="z-index: 70;">
                <v-card>
                    <v-progress-linear v-if="loading.state" indeterminate color="green"></v-progress-linear>
                    <v-row class="m-0">
                        <v-card-title>
                            <span class="text-lg font-semibold" v-text="`Create Purchase Order`"></span>
                        </v-card-title>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right" @click="toggleDialog(`create`); create.inputs.pasted = [{ model:'', quantity:null, error:false }]; purchaseOrder.action = ''">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card-text style="max-height: 560px; border:1px solid #e0e0e0; padding: 0;">
                        <v-row v-if="loading.state" tag="div" align="center" no-gutters class="px-2" v-text="loading.title"></v-row>
                        <v-row v-if="!loading.state" tag="div" align="center" no-gutters class="px-2">
                            <v-col lg="12">
                                <v-tabs v-model="create.tab">
                                    <v-tab v-if="purchaseOrder.action != 'Add'">By Type</v-tab>
                                    <v-tab>Manual</v-tab>
                                    <v-tabs-items v-model="create.tab">
                                        <v-tab-item v-if="purchaseOrder.action != 'Add'">
                                            <v-col class="d-flex pr-2" cols="10">
                                                <v-row dense no-gutters tag="div" align="end">
                                                    <v-col dense tag="div" cols="2">
                                                        <div v-text="`Supplier`"></div>
                                                    </v-col>
                                                    <v-col dense tag="div">
                                                        <v-autocomplete
                                                            v-model="suppliers.value"
                                                            :items="suppliers.selection"
                                                            dense
                                                            hide-details
                                                            flat
                                                        ></v-autocomplete>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col class="d-flex pr-2" cols="10">
                                                <v-row dense no-gutters tag="div" align="end">
                                                    <v-col dense tag="div" cols="2">
                                                        <div v-text="`Item Type`"></div>
                                                    </v-col>
                                                    <v-col dense tag="div">
                                                        <v-autocomplete
                                                            v-model="filters.type_id.selected"
                                                            :items="filters.type_id.selection"
                                                            placeholder="Filter Item Type"
                                                            class="text-md text-gray-700"
                                                            style="font-size: 0.8rem !important"
                                                            dense
                                                            hide-details
                                                            multiple
                                                        >
                                                            <template v-slot:selection="{ item, index }">
                                                                <v-chip x-small v-if="index <= 2">
                                                                    <span v-text="item.text"></span>
                                                                </v-chip>
                                                            </template>
                                                            <template v-slot:prepend-item>
                                                                <v-row align="center" dense no-gutters tag="div" class="d-flex px-3 pb-1">
                                                                    <div>
                                                                        <v-icon
                                                                            @click="toggleFilterSelection(filters.type_id)"
                                                                            :color="filters.type_id.selected.length > 0 ? 'indigo darken-4' : ''">
                                                                            {{ filterSelectionIcon(filters.type_id.selection, filters.type_id.selected) }}
                                                                        </v-icon>
                                                                    </div>
                                                                    <div>
                                                                        <input
                                                                            @input="searchFilterSelection(filters.type_id)"
                                                                            v-model="filters.type_id.search"
                                                                            type="text"
                                                                            placeholder="Search Type"
                                                                            style="text-align: left; font-size: .8rem !important; outline: none; height: 1.5rem !important;"
                                                                            class="form-control" />
                                                                    </div>
                                                                </v-row>
                                                                <v-divider class="m-0 p-0"></v-divider>
                                                            </template>
                                                            <template v-slot:item="{ active, item, attrs, on }">
                                                                <v-list-item dense v-on="on" v-bind="attrs" #default="{ active }">
                                                                    <v-row dense no-gutters align="center">
                                                                        <input class="mr-1" type="checkbox" :checked="active" />
                                                                        <div class="caption" v-text="item.text"></div>
                                                                    </v-row>
                                                                </v-list-item>
                                                            </template>
                                                        </v-autocomplete>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <v-card>
                                                <v-card-text>
                                                    <v-row class="mx-0 pa-0 ma-0" v-for="(text_area_field, i) in create.inputs.pasted" :key="i">
                                                        <div class="col-4 pa-0 ma-0" >
                                                            <v-text-field
                                                                v-model="text_area_field.model"
                                                                :class="text_area_field.error ? 'notInModel':'InModel'"
                                                                rows="1"
                                                                color="primary"
                                                                label="Model"
                                                                dense
                                                                outlined
                                                                small
                                                                @paste="onPasteForPo"
                                                                @click="$event.target.focus()"
                                                            ></v-text-field>
                                                        </div>
                                                        <div class="col-2 pa-0 my-0 mx-2">
                                                            <v-text-field
                                                                v-model="text_area_field.quantity"
                                                                :class="text_area_field.error ? 'notInModel':'InModel'"
                                                                rows="1"
                                                                color="primary"
                                                                label="Quantity"
                                                                dense
                                                                outlined
                                                                small
                                                            ></v-text-field >
                                                        </div>
                                                        <div class="col-5 pa-0 ma-0">
                                                            <v-text-field
                                                                v-model="text_area_field.remarks"
                                                                :class="text_area_field.error ? 'notInModel':'InModel'"
                                                                rows="1"
                                                                color="primary"
                                                                label="Remarks"
                                                                dense
                                                                outlined
                                                                small
                                                            ></v-text-field >
                                                        </div>
                                                        <div class="ma-2">
                                                            <v-btn
                                                                fab
                                                                dark
                                                                small
                                                                color="red"
                                                                style="height:20px; width:20px;"
                                                                @click="manualRemoveItem(i)"
                                                            >
                                                                <v-icon dark small style="font-size:12px">mdi-minus</v-icon>
                                                            </v-btn>
                                                        </div>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col class="ml-3">
                                                            <v-btn
                                                                dark
                                                                small
                                                                color="primary"
                                                                @click="manualAddItems()"
                                                            >
                                                                <v-icon>mdi-plus</v-icon>Add
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-tab-item>
                                    </v-tabs-items>
                                </v-tabs>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-end">
                        <v-btn small :disabled="loading.state" @click="createPurchaseOrder(); filters.type_id.selected=[];create.inputs.pasted = [{ model:'', quantity:null, error:false }]">
                            <v-icon>mdi-plus</v-icon>Create
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- IMPORT EXCEL -->
            <v-dialog v-model="importExcel.dialog" scrollable persistent max-width="580px" style="z-index: 80;">
                <v-card>
                    <v-progress-linear v-if="importExcel.loading" indeterminate color="green"></v-progress-linear>
                    <v-row class="m-0">
                        <v-card-title>
                            <span class="text-lg font-semibold" v-text="`Import Excel`"></span>
                        </v-card-title>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right"
                                @click="toggleDialog(`importExcel`); resetImportExcel()">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row tag="div" align="center" no-gutters style="border-top:1px solid #e0e0e0; padding: 0;" class="pa-3">
                        <v-col dense cols="12">
                            <!-- <v-file-input
                                    @change="importExcel.errors.data=[]"
                                    v-model="importExcel.form.excel"
                                    prepend-icon="mdi-file-excel"
                                    placeholder="Import Excel"
                                    counter
                                    show-size
                                    truncate-length="50"
                                >
                                </v-file-input> -->
                            <!-- <xlsx-read :file="importExcel.form.excel ">
                                        <xlsx-json :sheet="importExcel.json">
                                        <template #default="{collection}">
                                            <div>
                                            {{ collection }}
                                            </div>
                                        </template>
                                        </xlsx-json>
                                    </xlsx-read> -->
                            <v-file-input label="File input" outlined dense @change="handleChange" />
                        </v-col>
                    </v-row>
                    <v-card-text style="max-height: 453px; border:1px solid #e0e0e0; padding: 0;">
                        <v-data-table
                            :headers="importExcel.errors.headers"
                            :items="importExcel.errors.data"
                            :items-per-page="10"
                            style="margin: 0;"
                            dense
                        ></v-data-table>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-end">
                        <v-btn color="red" small v-on:click="importOrderTemplate(editedPurchaseOrder)">
                            <v-icon small color="white">mdi-arrow-up-bold-circle</v-icon><span>&nbsp;Import</span>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- PURCHASE ORDER DIALOG -->
            <v-dialog v-model="editedPurchaseOrder.dialog" scrollable persistent max-width="90%" style="z-index: 70;">
                <div v-if="datePicker.dialog || importExcel.dialog" style="background-color: rgba(47,47,47,0.5); position:absolute; width:100%; height:100%; z-index: 80;"></div>
                <v-card>
                    <v-progress-linear v-if="editedPurchaseOrder.details.loading" indeterminate color="green"></v-progress-linear>
                    <v-row class="m-0">
                        <!--<v-card-title>
                                <span class="text-lg font-semibold" v-text="`${editedPurchaseOrder.poNumber || ``}`"></span>
                            </v-card-title>-->
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right" @click="toggleDialog(`editedPurchaseOrder`)">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card-text style="max-height: 575px; border:1px solid #e0e0e0; padding: 10px;" class="pa-3">
                        <v-row tag="div" align="center" no-gutters>
                            <v-col dense cols="6">
                                <span v-text="`PO# :`"></span>&nbsp;
                                <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                <span v-if="!loading.state" class="font-weight-bold" v-text="`${editedPurchaseOrder.poNumber ? editedPurchaseOrder.poNumber : ``}`"></span>
                            </v-col>
                            <v-col dense cols="6">
                                <v-col tag="div" class="d-flex">
                                    <div><span>Date&nbsp;:</span></div>
                                    <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                    <span v-if="!loading.state" class="ml-2 font-weight-bold" v-text="`${editedPurchaseOrder.date ? editedPurchaseOrder.date : ``}`"></span>
                                </v-col>
                            </v-col>
                        </v-row>
                        <v-row tag="div" class="align-items-center" no-gutters>
                            <v-col dense cols="6">
                                <span v-text="`Supplier :`"></span>&nbsp;
                                <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                <span v-if="!loading.state" class="font-weight-bold" v-text="`${editedPurchaseOrder.supplier ? editedPurchaseOrder.supplier : ``}`"></span>
                            </v-col>
                            <v-col dense cols="6">
                                <span v-text="`Total Order: :`"></span>&nbsp;
                                <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                <span v-if="!loading.state" class="font-weight-bold" v-text="`${editedPurchaseOrder.totalCount}`"></span>
                            </v-col>
                            <v-col dense cols="6">
                                <span v-text="`Address :`"></span>&nbsp;
                                <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                <span v-if="!loading.state" class="font-weight-bold" v-text="`${editedPurchaseOrder.supplierAddress ? editedPurchaseOrder.supplierAddress : ``}`"></span>
                            </v-col>
                            <v-col dense cols="6">
                                <span v-text="`Total Quantity:`"></span>&nbsp;
                                <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                <span v-if="!loading.state" class="font-weight-bold" v-text="`${editedPurchaseOrder.totalQuantity}`"></span>
                            </v-col>
                            <v-col dense cols="12">
                                <v-row tag="div" align="center" no-gutters>
                                    <v-col v-if="editedPurchaseOrder.mode == `view`" tag="div" class="d-flex">
                                        <div><span>Remarks&nbsp;:</span></div>
                                        <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                        <span v-if="!loading.state" class="ml-1 font-weight-bold" v-text="`${editedPurchaseOrder.remarks ? editedPurchaseOrder.remarks : ``}`"></span>
                                    </v-col>
                                    <v-col v-if="editedPurchaseOrder.mode == `edit`" tag="div" class="d-flex justify-start" cols="6">
                                        <div class=" w-100">
                                            <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                            <v-text-field
                                                v-if="!loading.state"
                                                v-model="editedPurchaseOrder.remarks"
                                                type="text"
                                                height="10px"
                                                label="Remarks"
                                                outlined
                                                dense
                                                style="text-align: left; font-size: .8rem !important; outline: none; height: 1.5rem !important;"
                                            ></v-text-field>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row tag="div" align="center" no-gutters>
                            <!-- <v-col dense cols="12">
                                <v-progress-circular v-if="loading.state" size="16" indeterminate
                                    color="gray"></v-progress-circular>
                                    <div style="display:inline-block" class="mt-3">
                                    <v-menu v-model="menu3" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" min-width="20px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                            v-model="estemated_date"
                                            readonly label="ETD"
                                            append-icon="mdi-calendar-clock"
                                            v-on="on"
                                            outlined
                                            dense
                                            hide-details>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker v-model="estemated_date" @input="menu3 = false"></v-date-picker>
                                    </v-menu>
                                </div>
                            </v-col> -->
                            <!-- <v-col dense cols="3">
                                <v-switch v-model="purchaseOrder.withComputations" flat false-value="0" true-value="1"
                                    :label="`Show Computations`"></v-switch>
                            </v-col>
                            <v-col dense cols="3">
                                <v-switch v-model="purchaseOrder.withOrderQuantity" flat false-value="0" true-value="1"
                                    :label="`Show with Ordered Quantity`"></v-switch>
                            </v-col>

                            <v-col lg="3">
                                <v-switch :disabled="loading.state" v-model="exclude_fully_served" class="mx-2">
                                    <template v-slot:label>
                                        <span class="font-weight-medium" style="font-size: 0.875rem !important;"
                                            v-text="`Exclude Fully Served on Export`"></span>
                                    </template>
                                </v-switch>
                            </v-col> -->
                        </v-row>
                        <v-divider></v-divider>
                        <v-row tag="div" align="center" no-gutters class=" mt-2 mr-2">
                            <v-col class="text-left">
                                <v-btn
                                    v-if="editedPurchaseOrder.mode == `edit`"
                                    :disabled="editedPurchaseOrder.details.loading"
                                    :loading="loading.state"
                                    small
                                    @click="exportExcel(editedPurchaseOrder, 'order-template')"
                                >
                                    <v-icon small color="white">mdi-file-excel</v-icon><span>&nbsp;Export Order Template</span>
                                </v-btn>
                                <v-btn
                                    v-if="(editedPurchaseOrder.mode == `edit` && purchaseOrder.status == 0) || (user.president && editedPurchaseOrder.mode == `edit`) || (is_super_admin == 1 && editedPurchaseOrder.mode == `edit`)"
                                    :disabled="editedPurchaseOrder.details.loading"
                                    class="ml-2"
                                    small
                                    @click="toggleDialog(`importExcel`)"
                                >
                                    <v-icon small color="white">mdi-arrow-up-bold-circle</v-icon><span>&nbsp;Import Order Template</span>
                                </v-btn>
                                <v-btn
                                    v-if="(purchaseOrder.status == 0 || user.president == 1 || user.manager == 1) && editedPurchaseOrder.mode == `edit`"
                                    :disabled="editedPurchaseOrder.details.loading"
                                    class="mx-3"
                                    small
                                    @click="purchaseOrder.action = 'Add'; toggleDialog(`create`)"
                                >
                                    <v-icon>mdi-plus</v-icon>Add Items
                                </v-btn>
                            </v-col>
                            <v-col class="d-flex" cols="12" v-if="editedPurchaseOrder.mode == `view`">
                                <v-row dense no-gutters tag="div" align="center" class="">
                                    <v-col lg="3">
                                        <v-switch :disabled="loading.state" v-model="exclude_fully_served" class="mx-2">
                                            <template v-slot:label>
                                                <span class="font-weight-medium" style="font-size: 0.875rem !important;" v-text="`Exclude Fully Served on Export`"></span>
                                            </template>
                                        </v-switch>
                                    </v-col>
                                    <v-col dense cols="3">
                                        <v-switch v-model="purchaseOrder.withComputations" flat false-value="0" true-value="1" :label="`Show Computations`"></v-switch>
                                    </v-col>
                                    <v-col dense cols="3">
                                        <v-switch v-model="purchaseOrder.withOrderQuantity" flat false-value="0" true-value="1" :label="`Show with Ordered Quantity`"></v-switch>
                                    </v-col>

                                    <v-col lg="3" class="d-flex justify-content-end">
                                        <v-btn
                                            v-if="purchaseOrder.status == 0 || user.president == 1 || user.manager == 1"
                                            :disabled="editedPurchaseOrder.details.loading"
                                            small
                                            @click="exportExcel(editedPurchaseOrder, 'purchase-order-template')"
                                        >
                                            <v-icon small color="white">receipt-text-check</v-icon><span>&nbsp;Export Purchase Order</span>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="mt-2 ml-1" v-if="editedPurchaseOrder.mode == `edit`">
                            <v-col class="d-flex" cols="2">
                                <v-autocomplete
                                    v-model="filters.type_id.selected"
                                    :items="filters.type_id.selection"
                                    :loading="loading.item_type_selection"
                                    :disabled="editedPurchaseOrder.details.loading"
                                    auto-select-first
                                    label="Discount Types"
                                    dense
                                    outlined
                                    hide-details
                                    multiple
                                    small-chips
                                    @change="getSelectedItemType"
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="d-flex" cols="2">
                                <v-autocomplete
                                    v-model="filters.category_id.selected"
                                    :items="filters.category_id.selection"
                                    :loading="loading.item_category_selection"
                                    :disabled="editedPurchaseOrder.details.loading"
                                    auto-select-first
                                    label="Item Category"
                                    dense
                                    outlined
                                    hide-details
                                    multiple
                                    small-chips
                                    @change="getSelectedItemCategory"
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="d-flex" cols="2">
                                <v-autocomplete
                                    v-model="filters.item_id.selected"
                                    :items="filters.item_id.selection"
                                    :loading="loading.po_item_selection"
                                    :disabled="editedPurchaseOrder.details.loading"
                                    auto-select-first
                                    label="Item Model"
                                    dense
                                    outlined
                                    hide-details
                                    multiple
                                    small-chips
                                    @change="getSelectedItem"
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="d-flex" cols="2">
                                <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                <v-menu
                                    v-model="menu3"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    min-width="20px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            v-model="estemated_date"
                                            v-on="on"
                                            label="ETD"
                                            append-icon="mdi-calendar-clock"
                                            dense
                                            outlined
                                            hide-details
                                            readonly
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="estemated_date" @input="menu3 = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row class="mt-2 ml-1" v-if="editedPurchaseOrder.mode == `edit`">
                            <v-col lg="2">
                                <v-autocomplete
                                    v-model="editedPurchaseOrder.details.additional.item_id"
                                    :items="GET_ITEM_SELECTION_DEFAULT"
                                    :disabled="editedPurchaseOrder.details.loading"
                                    auto-select-first
                                    label="Select Model"
                                    dense
                                    outlined
                                    hide-details
                                ></v-autocomplete>
                            </v-col>
                            <v-col lg="2">
                                <v-text-field
                                    v-model="editedPurchaseOrder.details.additional.order_quantity"
                                    :disabled="editedPurchaseOrder.details.loading"
                                    label="Order Quantity"
                                    dense
                                    outlined
                                    hide-details
                                ></v-text-field>
                            </v-col>
                            <v-col lg="1" class="d-flex justify-content-start align-items-center">
                                <v-btn
                                    :disabled="editedPurchaseOrder.details.loading"
                                    color="primary"
                                    style="height: 20px; width: 20px; margin-left:4px"
                                    fab
                                    dark
                                    small
                                    @click="addItemDetails(editedPurchaseOrder.details.additional.item_id,editedPurchaseOrder.details.additional.order_quantity);"
                                >
                                    <v-icon dark small style="font-size: 12px">mdi-plus</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row class="mr-2">
                            <v-col class="mt-2 ma-2"> <!-- continue -->
                                <v-data-table :headers="editedPurchaseOrder.details.datatable.headers"
                                    :items="editedPurchaseOrder.details.datatable.data"
                                    :options.sync="editedPurchaseOrder.details.datatable.pagination.options"
                                    :items-per-page="editedPurchaseOrder.details.datatable.pagination.perPageDefault"
                                    :footer-props="{
                                        showFirstLastPage: true,
                                        itemsPerPageOptions: [5, 10, 25]
                                    }" :header-props="{
                                        divider: true
                                    }"
                                    :item-class="rowClass"
                                    :server-items-length="editedPurchaseOrder.details.datatable.pagination.total" dense
                                    style="border:1px solid #e0e0e0; margin: 0;">

                                    <template v-slot:item.action="{ item,index }">
                                        <v-btn
                                        text
                                        icon
                                        color="red"
                                        v-if="editedPurchaseOrder.mode == `edit`"
                                        >
                                            <v-icon
                                                class="btn-action" small
                                                @click="removeItem(item)">mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <template v-slot:item.model="{ item }">
                                        <v-autocomplete
                                        v-if="editedPurchaseOrder.mode == `edit`"
                                        hide-details
                                        dense
                                        outlined
                                        :items="GET_ITEM_SELECTION_DEFAULT"
                                        item-value="value"
                                        item-text="text"
                                        class="small mt-2"
                                        v-model="item.item_id"
                                        auto-select-first
                                        @change="updateItemDetails(item,index)"
                                        >
                                        </v-autocomplete>
                                        <span v-else
                                            v-text="item.model">
                                        </span>
                                    </template>
                                    <!-- <template v-slot:item.item_img="{ item }">
                                        <img class="img-thumbnail" width="50" height="50"
                                        :src="checkImagePath(item.item_img)">
                                    </template> -->

                                    <template v-slot:item.base64img="{ item }">
                                        <img class="img-thumbnail" width="50" height="50"
                                        :src="item.base64img">
                                    </template>

                                    <template v-slot:item.total_incoming_stocks="{ item }">
                                        <span
                                            v-text="item.total_incoming_stocks ? formatNumber(item.total_incoming_stocks) : ''"></span>
                                    </template>
                                    <template v-slot:item.total_average_sales="{ item }">
                                        <span
                                            v-text="item.total_average_sales ? formatNumber(parseInt(item.total_average_sales)) : ''"></span>
                                    </template>
                                    <template v-slot:item.item_total_stock="{ item }">
                                        <span
                                            v-text="item.item_total_stock ? formatNumber(item.item_total_stock) : ''"></span>
                                    </template>
                                    <template v-slot:item.purchase_order_total_pending="{ item }">
                                        <span v-if="item.purchase_order_total_pending"
                                            v-text="formatNumber(parseInt(item.purchase_order_total_pending) - parseInt(item.total_incoming_stocks)) < 0 ?
                                            0 : formatNumber(parseInt(item.purchase_order_total_pending) - parseInt(item.total_incoming_stocks))"></span>
                                    </template> -->
                                    <template v-slot:item.total_pending_so="{ item }">
                                        <span
                                            v-text="item.total_pending_so ? formatNumber(item.total_pending_so) : 0"></span>
                                    </template>
                                    <template v-slot:item.item_po_moq="{ item }">
                                        <span v-text="item.item_po_moq ? formatNumber(item.item_po_moq) : 0"></span>
                                    </template>
                                    <template v-slot:item.order_quantity="{ item }">
                                        <!-- <span
                                            v-text="item.order_quantity ? formatNumber(item.order_quantity) : 0"></span> -->
                                            <v-text-field
                                            v-if="editedPurchaseOrder.mode == `edit`"
                                            v-model="item.order_quantity"
                                            @change="item.updated = true"
                                            outlined dense >
                                            </v-text-field>
                                            <span v-else
                                            v-text="item.order_quantity ? formatNumber(item.order_quantity) : 0"></span>
                                    </template>
                                    <template v-slot:[`item.remarks`]="{ item }">
                                        <!-- <span
                                            v-text="item.order_quantity ? formatNumber(item.order_quantity) : 0"></span> -->
                                            <v-text-field
                                            v-if="editedPurchaseOrder.mode == `edit`"
                                            v-model="item.remarks"
                                            @change="item.updated = true"
                                            outlined dense >
                                            </v-text-field>
                                            <span v-else
                                            v-text="item.remarks ?? ''"></span>
                                    </template>
                                    <template v-slot:item.purchase_order_total_pending="{ item }">
                                        <span
                                            v-text="item.purchase_order_total_pending ? formatNumber(item.purchase_order_total_pending) : 0"></span>
                                    </template>
                                    <template v-slot:item.total_order_quantity="{ item }">
                                        <span
                                            v-text="item.order_quantity ? formatNumber(item.order_quantity) : 0"></span>
                                    </template>
                                    <template v-slot:header.total_average_sales>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-bind="attrs" v-on="on">
                                                    AC
                                                </span>
                                            </template>
                                            <span small>AVG CONSUMPTION 3 MONTHS</span>
                                        </v-tooltip>
                                    </template>
                                    <!-- <template v-slot:header.total_loading_list_quantity>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <span
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                    PlL
                                                    </span>
                                                </template>
                                                <span>PLANNING LOADING LIST</span>
                                            </v-tooltip>
                                        </template> -->
                                    <template v-slot:header.total_incoming_stocks>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-bind="attrs" v-on="on">
                                                    IS
                                                </span>
                                            </template>
                                            <span small>INCOMING STOCKS</span>
                                        </v-tooltip>
                                    </template>
                                    <template v-slot:header.total_pending_container_stocks>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-bind="attrs" v-on="on">
                                                    PC
                                                </span>
                                            </template>
                                            <span small>PENDING CONTAINER</span>
                                        </v-tooltip>
                                    </template>

                                    <template v-slot:header.purchase_order_total_pending>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-bind="attrs" v-on="on">
                                                    PP
                                                </span>
                                            </template>
                                            <span>PENDING PO</span>
                                        </v-tooltip>
                                    </template>

                                    <template v-slot:header.total_pending_so>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-bind="attrs" v-on="on">
                                                    PS
                                                </span>
                                            </template>
                                            <span>PENDING SO</span>
                                        </v-tooltip>
                                    </template>

                                    <template v-slot:header.item_total_stock>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-bind="attrs" v-on="on">
                                                    AS
                                                </span>
                                            </template>
                                            <span>AVAILABLE STOCKS</span>
                                        </v-tooltip>
                                    </template>
                                    <template v-slot:header.item_po_moq>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-bind="attrs" v-on="on">
                                                    BQ
                                                </span>
                                            </template>
                                            <span>BASIC QTY</span>
                                        </v-tooltip>
                                    </template>
                                    <template v-slot:header.previous_order_quantity v-if="purchaseOrder.status == 1">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-bind="attrs" v-on="on">
                                                    POQ
                                                </span>
                                            </template>
                                            <span>PREVIOUS ORDER QUANTITY</span>
                                        </v-tooltip>
                                    </template>
                                    <template v-slot:header.order_quantity>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-bind="attrs" v-on="on">
                                                    OQ
                                                </span>
                                            </template>
                                            <span>ORDER QUANTITY</span>
                                        </v-tooltip>
                                    </template>
                                    <template v-slot:header.total_order_quantity>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-bind="attrs" v-on="on">
                                                    TOQ
                                                </span>
                                            </template>
                                            <span>TOTAL OQ</span>
                                        </v-tooltip>
                                    </template>
                                    <!--<template v-if="[0].indexOf(0) >= 0 && !editedPurchaseOrder.details.loading" v-slot:item.action="{item}">
                                            <v-icon small color="red" @click="removeItem(item)">mdi-delete</v-icon>
                                        </template>-->
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions class="d-flex justify-space-between">
                        <div>
                        </div>
                        <div>
                            <v-btn v-if="editedPurchaseOrder.mode == `edit`" small
                                :disabled="editedPurchaseOrder.details.loading" v-on:click="update(editedPurchaseOrder); addDetails(); updateDetails();"
                                class="ml-1">
                                <v-icon small color="white">mdi-content-save</v-icon><span>&nbsp;Save</span>
                            </v-btn>
                            <v-btn
                                v-if="editedPurchaseOrder.mode == `view` && purchaseOrder.status == 0 && editedPurchaseOrder.details.edited != undefined && !user.president"
                                small :disabled="editedPurchaseOrder.details.loading"
                                v-on:click="submitForChecking(editedPurchaseOrder)" class="ml-1">
                                <v-icon small color="white">mdi-checkbox-marked-circle </v-icon><span>&nbsp;Submit for
                                    chekcing</span>
                            </v-btn>
                            <v-btn
                                v-if="editedPurchaseOrder.mode == `view` && purchaseOrder.status == 1 && user.allowToApprove"
                                small :disabled="editedPurchaseOrder.details.loading"
                                v-on:click="update(editedPurchaseOrder, 'approve')" class="ml-1">
                                <v-icon small color="white">mdi-checkbox-marked-circle
                                </v-icon><span>&nbsp;Approve</span>
                            </v-btn>
                            <!--
                                <v-btn
                                    small
                                    :disabled="editedPurchaseOrder.details.loading"
                                    v-on:click=""
                                    class="ml-1">
                                    <v-icon small color="white">fas fa-clipboard-check</v-icon><span>&nbsp;Complete Purchase Order</span>
                                </v-btn>
                                -->
                        </div>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- LIST -->
            <div class="row mt-2" no-gutters dense>
                <v-card class="w-100 mx-4">
                    <v-progress-linear v-if="loading.state" indeterminate color="green"></v-progress-linear>
                    <v-row dense class="pa-4">
                        <v-col cols="6" sm="3" md="2" class="px-1 d-flex align-items-center" id="date_from">
                            <v-menu v-model="filters.menu1" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                    v-model="filters.date_from"
                                    readonly
                                    append-icon="mdi-calendar-clock"
                                    label="Date From"
                                    v-on="on"
                                    outlined
                                    hide-details
                                    dense
                                    >
                                </v-text-field>
                                </template>
                                <v-date-picker v-model="filters.date_from"
                                    @input="filters.menu1 = false"></v-date-picker>
                            </v-menu>
                            <span v-for="errors in errors.date_from" class="text-warning"
                                :key="errors">{{ errors }}</span>
                        </v-col>
                        <v-col cols="6" sm="3" md="2" id="date_to" class="d-flex align-items-center">
                            <v-menu v-model="filters.menu2" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                    v-model="filters.date_to"
                                    readonly label="Date To"
                                    append-icon="mdi-calendar-clock"
                                    v-on="on"
                                    outlined
                                    hide-details
                                    dense
                                    >
                                </v-text-field>
                                </template>
                                <v-date-picker v-model="filters.date_to" @input="filters.menu2 = false"></v-date-picker>
                            </v-menu>
                            <span v-for="errors in errors.date_to" class="text-warning" :key="errors">{{ errors }}</span>
                        </v-col>
                        <v-col cols="8" class="p-2 align-items-center">
                            <v-btn @click="list()" class="float-right" :loading="loading.state">Filter</v-btn>
                            <v-btn @click="exportDialog = true" class="float-right mx-2"
                                :loading="loading.state">Export</v-btn>
                                <!-- exportList() -->
                        </v-col>
                    </v-row>
                    <v-row dense no-gutters tag="div" class="px-3 pt-2">
                        <v-col sm="3" class="mb-2">
                            <!-- <label for="users" class="float-left control-label col-form-label">Created By</label> -->
                            <v-autocomplete
                                multiple
                                small-chips
                                :items="filters.users.selection"
                                :loading="loading.manager_selection"
                                :disabled="loading.manager_selection"
                                v-model="filters.users.selected"
                                label="Created By"
                                @change="getSelectedManager"
                                class="w-100"
                                return-object
                                outlined
                                dense
                                hide-details
                                auto-select-first
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col sm="3">
                        </v-col>
                        <v-col sm="3" style="margin-top:15px">
                            <v-row dense no-gutters tag="form" class="d-flex" @submit.prevent="list()">
                                <v-text-field
                                dense
                                autocomplete="off"
                                v-model="purchaseOrder.datatable.searchPo"
                                label="Search PO#/Remarks"
                                clearable
                                hide-details
                                >
                                </v-text-field>
                            </v-row>
                        </v-col>
                        <v-col sm="3" style="margin-top:15px">
                            <v-row dense no-gutters tag="form" class="d-flex" @submit.prevent="list()">
                                <v-text-field
                                dense
                                autocomplete="off"
                                v-model="purchaseOrder.datatable.searchItem"
                                label="SEARCH ITEM NAME/MODEL"
                                clearable
                                hide-details
                                >
                                </v-text-field>
                                <v-icon @click="list()">mdi-magnify</v-icon>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row tag="div" class="mt-2" style="background-color:#1e1e1c;">
                        <v-col>
                            <v-tabs v-model="tab" color="cyan" background-color="#424242" dark slider-color="cyan" class="col-12 w-100"
                                show-arrows>
                                <v-tab @click="filters.type = 0">All</v-tab>
                                <v-tab @click="filters.type = 1">Pending</v-tab>
                                <v-tab @click="filters.type = 2">Partial</v-tab>
                                <v-tab @click="filters.type = 3">Completed</v-tab>
                                <v-tabs-items v-model="tab">
                                    <v-tab-item>
                                        <v-data-table :headers="purchaseOrder.datatable.headers"
                                            :items="purchaseOrder.datatable.data"
                                            :options.sync="purchaseOrder.datatable.pagination.options"
                                            :items-per-page="10" :footer-props="{
                                                showFirstLastPage: true,
                                                itemsPerPageOptions: [5, 10, 25]
                                            }" :loading="loading.state" loading-text="Loading... Please wait"
                                            :server-items-length="purchaseOrder.datatable.pagination.total">
                                            <template v-slot:item.status="{ item }">
                                                <span
                                                    :class="PURCHASE_ORDER_STATUS[item.status].class">{{ PURCHASE_ORDER_STATUS[item.status].text }}</span>
                                            </template>
                                            <template v-slot:item.action="{ item }">
                                                <span class="text-nowrap">
                                                    <v-btn v-if="userAccess.view || is_super_admin == 1" text icon
                                                        color="orange">
                                                        <v-icon class="btn-action" small
                                                            @click="view(item);">mdi-eye</v-icon>
                                                    </v-btn>
                                                    <v-btn v-if="item.status == 0 || user.president" text icon
                                                        color="blue">
                                                        <v-icon v-if="userAccess.edit || is_super_admin == 1"
                                                            class="btn-action" small
                                                            @click="view(item, `edit`)">mdi-pencil</v-icon>
                                                    </v-btn>
                                                    <v-btn text icon color="red">
                                                        <v-icon v-if="userAccess.delete || is_super_admin == 1"
                                                            class="btn-action" small
                                                            @click="destroy(item)">mdi-delete</v-icon>
                                                    </v-btn>
                                                </span>
                                                <!-- <span>{{item.status}}</span> -->
                                            </template>
                                        </v-data-table>
                                    </v-tab-item>
                                    <v-tab-item>
                                        <v-data-table :headers="purchaseOrder.datatable.headers"
                                            :items="purchaseOrder.datatable.data"
                                            :options.sync="purchaseOrder.datatable.pagination.options"
                                            :items-per-page="10" :footer-props="{
                                                showFirstLastPage: true,
                                                itemsPerPageOptions: [5, 10, 25]
                                            }" :loading="loading.state"
                                            :server-items-length="purchaseOrder.datatable.pagination.total">
                                            <template v-slot:item.status="{ item }">
                                                <span
                                                    :class="PURCHASE_ORDER_STATUS[item.status].class">{{ PURCHASE_ORDER_STATUS[item.status].text }}</span>
                                            </template>
                                            <template v-slot:item.action="{ item }">
                                                <span class="text-nowrap">
                                                    <v-btn v-if="userAccess.view || is_super_admin == 1" text icon
                                                        color="orange">
                                                        <v-icon class="btn-action" small
                                                            @click="view(item)">mdi-eye</v-icon>
                                                    </v-btn>
                                                    <v-btn v-if="item.status == 0 || user.president" text icon
                                                        color="blue">
                                                        <v-icon v-if="userAccess.edit || is_super_admin == 1"
                                                            class="btn-action" small
                                                            @click="view(item, `edit`)">mdi-pencil</v-icon>
                                                    </v-btn>
                                                    <v-btn text icon color="red">
                                                        <v-icon v-if="userAccess.delete || is_super_admin == 1"
                                                            class="btn-action" small
                                                            @click="destroy(item)">mdi-delete</v-icon>
                                                    </v-btn>
                                                </span>
                                                <!-- <span>{{item.status}}</span> -->
                                            </template>
                                        </v-data-table>
                                    </v-tab-item>
                                    <v-tab-item>
                                        <v-data-table :headers="purchaseOrder.datatable.headers"
                                            :items="purchaseOrder.datatable.data"
                                            :options.sync="purchaseOrder.datatable.pagination.options"
                                            :items-per-page="10" :footer-props="{
                                                showFirstLastPage: true,
                                                itemsPerPageOptions: [5, 10, 25]
                                            }" :loading="loading.state"
                                            :server-items-length="purchaseOrder.datatable.pagination.total">
                                            <template v-slot:item.status="{ item }">
                                                <span
                                                    :class="PURCHASE_ORDER_STATUS[item.status].class">{{ PURCHASE_ORDER_STATUS[item.status].text }}</span>
                                            </template>
                                            <template v-slot:item.action="{ item }">
                                                <span class="text-nowrap">
                                                    <v-btn v-if="userAccess.view || is_super_admin == 1" text icon
                                                        color="orange">
                                                        <v-icon class="btn-action" small
                                                            @click="view(item)">mdi-eye</v-icon>
                                                    </v-btn>
                                                    <v-btn v-if="item.status == 0 || user.president" text icon
                                                        color="blue">
                                                        <v-icon v-if="userAccess.edit || is_super_admin == 1"
                                                            class="btn-action" small
                                                            @click="view(item, `edit`)">mdi-pencil</v-icon>
                                                    </v-btn>
                                                    <v-btn text icon color="red">
                                                        <v-icon v-if="userAccess.delete || is_super_admin == 1"
                                                            class="btn-action" small
                                                            @click="destroy(item)">mdi-delete</v-icon>
                                                    </v-btn>
                                                </span>
                                                <!-- <span>{{item.status}}</span> -->
                                            </template>
                                        </v-data-table>
                                    </v-tab-item>
                                    <v-tab-item>
                                        <v-data-table :headers="purchaseOrder.datatable.headers"
                                            :items="purchaseOrder.datatable.data"
                                            :options.sync="purchaseOrder.datatable.pagination.options"
                                            :items-per-page="10" :footer-props="{
                                                showFirstLastPage: true,
                                                itemsPerPageOptions: [5, 10, 25]
                                            }" :loading="loading.state"
                                            :server-items-length="purchaseOrder.datatable.pagination.total">
                                            <template v-slot:item.status="{ item }">
                                                <span
                                                    :class="PURCHASE_ORDER_STATUS[item.status].class">{{ PURCHASE_ORDER_STATUS[item.status].text }}</span>
                                            </template>
                                            <template v-slot:item.action="{ item }">
                                                <span class="text-nowrap">
                                                    <v-btn v-if="userAccess.view || is_super_admin == 1" text icon
                                                        color="orange">
                                                        <v-icon class="btn-action" small
                                                            @click="view(item)">mdi-eye</v-icon>
                                                    </v-btn>
                                                    <v-btn v-if="item.status == 0 || user.president" text icon
                                                        color="blue">
                                                        <v-icon v-if="userAccess.edit || is_super_admin == 1"
                                                            class="btn-action" small
                                                            @click="view(item, `edit`)">mdi-pencil</v-icon>
                                                    </v-btn>
                                                    <v-btn text icon color="red">
                                                        <v-icon v-if="userAccess.delete || is_super_admin == 1"
                                                            class="btn-action" small
                                                            @click="destroy(item)">mdi-delete</v-icon>
                                                    </v-btn>
                                                </span>
                                                <!-- <span>{{item.status}}</span> -->
                                            </template>
                                        </v-data-table>
                                    </v-tab-item>
                                </v-tabs-items>
                            </v-tabs>
                        </v-col>
                    </v-row>
                </v-card>
            </div>
            <!-- export dialog -->
             <v-dialog v-model="exportDialog" persistent max-width="500">
                <v-card>
                    <v-card-title class="d-flex justify-space-between">
                        <span class="text-lg font-semibold">Export Options</span>
                        <v-btn text icon color="gray" class="float-right" @click="exportDialog = false; exportOptions.po_details = 0">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-divider class="ma-0" />
                    <v-card-text>
                        <v-row class="d-flex flex-sm-column flex-md-row align-lg-center col-12 py-0 pt-2">
                            <label class="font-weight-medium d-flex justify-start col-12 col-md-3 px-xl-4">TYPE :</label>
                            <v-col class="d-flex flex-sm-column flex-md-row align-start col-12 col-sm-8 col-md-9 pb-5">
                                <v-col xl="6">
                                    <v-checkbox
                                        v-model="exportOptions.po_details"
                                        label="PO Details"
                                        color="orange"
                                        v-bind:true-value="1"
                                        v-bind:false-value="0"
                                        hide-details
                                    ></v-checkbox>
                                </v-col>
                                <v-col xl="6" outlined>
                                    <v-checkbox
                                        v-model="exportOptions.item_details"
                                        label="Item Details"
                                        color="orange"
                                        v-bind:true-value="1"
                                        v-bind:false-value="0"
                                        hide-details
                                    ></v-checkbox>
                                </v-col>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex flex-sm-column flex-md-row align-center col-12 py-0">
                            <label class="font-weight-medium d-flex justify-start col-12 col-md-3 px-xl-4"> IMAGE :</label>
                            <v-col class="d-flex flex-sm-column flex-md-row align-start col-12 col-sm-8 col-md-9 pb-5">
                                <v-col xl="6" outlined>
                                    <v-checkbox
                                        v-model="exportOptions.with_image"
                                        label="With Image"
                                        color="orange"
                                        v-bind:true-value="1"
                                        v-bind:false-value="0"
                                        hide-details
                                    ></v-checkbox>
                                </v-col>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-center align-center ma-0">
                        <v-btn class="btn-toolbar px-4" :loading="loading.state" @click="exportList()">
                            <v-icon class="btn-action" color="success" dense>
                                mdi-cloud-download
                            </v-icon>
                            <span class="ml-1">Export Now</span>
                        </v-btn>
                    </v-card-actions>
                </v-card>
             </v-dialog>
        </v-container>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue'

var _ = require('lodash');
export default {
    name: "PurchaseOrderComponent",
    mixins: [ SharedFunctionsComponentVue ],
    mounted() {
        this.$store.dispatch('getAllitemSelectionDefault');
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
        this.$store.dispatch('getUserCosting')
        this.list();
        this.PURCHASE_ORDER_STATUS = [
            { text: 'saved', value: 0, class: 'badge badge-saved' },
            { text: 'submitted', value: 1, class: 'badge badge-partial' },
            { text: 'approved', value: 2, class: 'badge badge-approved' },
        ]
        // this.resetEdited();
        this.getItemTypes();
        this.getItemCategory();
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id);
        // this.checkAccess('IT', 'purchase_orders', 'approve_exec')
        // this.checkAccess('OP', 'purchase_orders', 'view')
        // this.checkAccess('OP', 'purchase_orders', 'edit')
        // this.checkAccess('OP', 'purchase_orders', 'delete')
        // this.checkAccess('OP', 'purchase_orders', 'create')
        this.getManagers();
        // this.tab = 0
        // this.filters.type = 0
        // // this.url = window.location.origin
    },

    components: {

    },

    data() {
        return {
            loading: {
                title: 'Please wait',
                state: false,
                manager_selection       : true,
                item_type_selection     : true,
                item_category_selection : true,
                po_item_selection       : true
            },
            tab: 1,

            url: '',
            create:{
                dialog: false,
                tab:0,
                inputs:{
                    pasted:[
                        { model:'', quantity:null, remarks: '', error:false }
                    ],
                    notIncluded:[],
                    model:'',
                    quantity:''
                }
            },
            errors: [],

            suppliers: {
                selection: [{
                    value: 1,
                    text: 'INGCO China - Mainland China'
                }],
                value: 1
            },

            filters: {
                type_id: {
                    search: '',
                    data: [],
                    selection: [],
                    selected: []
                },
                category_id: {
                    data: [],
                    selection: [],
                    selected: []
                },

                item_id: {
                    data: [],
                    selection: [],
                    selected: []
                },
                users: {
                    selection: [],
                    selected: [],
                },
                date_from: this.$dayjs().startOf('month').format('YYYY-MM-DD'),
                date_to: this.$dayjs().endOf('month').format('YYYY-MM-DD'),
                menu1: false,
                menu2: false,
                type: 0,
            },
            estemated_date:  this.$dayjs().format('YYYY-MM-DD'),
            menu3: false,

            exclude_fully_served: false,
            is_super_admin: 0,

            importExcel: {
                dialog: false,
                loading: false,
                form: {
                    excel: null
                },
                errors: {
                    headers: [
                        { text: 'ROW', align: 'left', value: 'row', sortable: false },
                        { text: 'ERROR MESSAGE', align: 'left', value: 'errors', sortable: false },
                    ],
                    data: []
                },
                json: null,
            },

            has_images: 0,

            datePicker: {
                dialog: false
            },

            editedIndex: -1,
            editedPurchaseOrder: {
                mode: 'view',
                dialog: false,
                id: null,
                poNumber: null,
                remarks: null,
                date: null,
                supplier: null,
                supplierAddress: null,
                details: {
                    loading: false,
                    datatable: {
                        search: '',
                        headers: [],
                        edited: null,
                        data: [],
                        pagination: {
                            options: {},
                            total: 0,
                            perPageDefault: 10
                        },
                        exportFields: {
                            'IMAGE': 'item_img',
                            'MODEL': 'item_model',
                            'NAME': 'item_name',
                            'AC': 'total_average_sales',
                            'IS': 'total_incoming_stocks',
                            'PP': 'pipe_line_total_pending',
                            'PS': 'total_pending_so',
                            'AS': 'item_total_stock',
                            'BQ': 'item_po_moq',
                            'OQ': 'order_quantity',
                        }
                    },
                    additional:{
                        datatable:{
                            items:[
                                { model:'',name:'',quantity:'',total_order_quantity:'' }
                            ],
                            headers:[]
                        }
                    }
                },
                totalQuantity: 0,
                totalCount: 0,
                paginatedItems: [],
            },

            purchaseOrder: {
                datatable: {
                    searchPo: '',
                    searchItem: '',
                    headers: [
                        { text: 'PO NUMBER', align: 'left', value: 'po_number', sortable: false },
                        { text: 'DATE', align: 'left', value: 'date', sortable: false },
                        { text: 'SUPPLIER', align: 'left', value: 'supplier', sortable: false },
                        { text: 'REMARKS', align: 'left', value: 'remarks', sortable: false },
                        { text: 'CREATED BY', align: 'left', value: 'user.name', sortable: false },
                        { text: 'ETD ', align: 'left', value: 'estemated_time_departure', sortable: false },
                        { text: 'STATUS ', align: 'left', value: 'status', sortable: false },
                        { text: 'FULFILLMENT DATE ', align: 'left', value: 'fulfillment_date', sortable: false },
                        { text: 'ACTIONS', align: 'center', value: 'action', sortable: false },
                    ],
                    data: [],
                    pagination: {
                        options: {},
                        total: 0,
                        perPageDefault: 10
                    },
                    status: 0,
                },
                withComputations: '0',
                withOrderQuantity: '1',
                action:''
            },

            userAccess: {
                view: false,
                create: false,
                edit: false,
                delete: false,
                approve_exec: false,
                add_china_pending:false
            },
            user: {
                president: false,
                manager: false,
                allowToApprove: false
            },
            PURCHASE_ORDER_STATUS: [],
            expData: [],
            loading: {
                title: 'Please wait',
                state: false
            },
            china_pending:{
                dialog:false,
                batch_addition:{
                    data:[]
                },
                text_areas:[
                    {
                        model:'',
                        quantity:''
                    }
                ],
                first_model:''
            },
            has_images: 0,
            not_found_items: [],
            exportDialog: false,
            exportOptions:{
                po_details: 0,
                item_details:1,
                with_image: 1
            }
        }
    },

    methods: {

        handleChange(e) {
            this.importExcel.form.excel = e
        },

        submitForChecking() {
            let that = this
            swal.fire({
                title: "",
                text: "Are you sure you want to Submit?",
                icon: 'question',
                showConfirmButton: true,
                confirmButtonText: 'Confirm',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: '397373',
                cancelButtonColor: 'grey',
                reverseButtons: true,
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch('submitForChecking', that.editedPurchaseOrder.id).then(response => {

                            that.purchaseOrder.status = response.data.status
                            swal.fire('Success', `${response.data.po_number} ready for approvals`, 'success')
                            that.list()
                            that.editedPurchaseOrder.dialog = false
                            that.toggleDialog(`editedPurchaseOrder`)
                        }).catch(e => {
                            console.log(e.response.message)
                        })
                    }
                })

        },

        getSelectedManager(val) {
            this.filters.users.selected = val
        },

        selectedAllFilterModuleOptions(selection, selected) {
            return selected.length === selection.length;
        },

        selectedSomeFilterModuleOptions(selection, selected) {
            return selected.length > 0 && !this.selectedAllFilterModuleOptions(selection, selected);
        },

        filterSelectionIcon(selection, selected) {
            if (this.selectedAllFilterModuleOptions(selection, selected)) return 'mdi-close-box'
            if (this.selectedSomeFilterModuleOptions(selection, selected)) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },

        toggleFilterSelection(filter) {
            this.$nextTick(() => {
                if (this.selectedAllFilterModuleOptions(filter.selection, filter.selected)) {
                    filter.selected = [];
                } else {
                    filter.selected = filter.selection.map(filter => filter.value);
                }
            })
        },

        searchFilterSelection(filter) {
            if (!filter.search) {
                filter.selection = filter.data;
            }

            filter.selection = filter.data.filter(data => {
                return data.text.toLowerCase().indexOf(filter.search.toLowerCase()) > -1
            })
        },

        getItemTypes() {
            let that = this;
            this.$store.dispatch('getItemTypes').then((response) => {
                that.filters.type_id.data = that.GET_ITEM_TYPES;
                that.filters.type_id.selection = that.GET_ITEM_TYPES.map(e => {
                    return { text: e.text, value: e.value }
                });
            });
        },

        getSelectedItemType(val) {
            this.filters.type_id.selected = val
        },

        startDownload() {
            this.exportLoading = true;
        },

        finishDownload() {
            this.exportLoading = false;
        },

        getItemCategory() {
            let that = this
            that.$store.dispatch('getAllItemCategories').then(response => {
                that.filters.category_id.data = this.GET_ALL_ITEM_CATEGORIES

                that.filters.category_id.selection = this.GET_ALL_ITEM_CATEGORIES.map(e => {
                    return { text: e.name, value: e.id }
                })
            })
        },

        getItems() {
            let that = this
            that.$store.dispatch('getPoItemSelection', that.editedPurchaseOrder.id).then(response => {
                this.filters.item_id.selection = this.GET_PO_ITEM_SELECTION.data
            })
        },

        getSelectedItem(val) {
            this.filters.item_id.selected = val
        },

        getSelectedItemCategory(val) {
            this.filters.category_id.selected = val
        },

        toggleLoading() {
            this.loading.state = !this.loading.state;
        },

        resetEdited() {
            let that = this;

            that.editedIndex = -1;

            that.editedPurchaseOrder = Object.assign({}, {
                mode: 'view',
                dialog: false,
                id: null,
                poNumber: null,
                remarks: null,
                date: null,
                supplier: null,
                supplierAddress: null,
                details: {
                    loading: false,
                    datatable: {
                        search: '',
                        headers: that.editedPurchaseOrder.details.datatable.headers,
                        data: [],
                        pagination: {
                            options: {},
                            total: 0,
                            perPageDefault: 10
                        }
                    },
                    additional:{
                        item_id:'',
                        order_quantity:''

                    }
                }
            });
        },

        list(page = 1, wait = false) {
            let that = this;

            that.purchaseOrder.datatable.data = []
            that.purchaseOrder.datatable.pagination.options.page = page;

            if (that.loading.state) {
                return false;
            }

            that.toggleLoading();

            let promise = new Promise(resolve => {
                let payload = {
                    page: page,
                    itemsPerPage: that.purchaseOrder.datatable.pagination.options.itemsPerPage
                        || that.purchaseOrder.datatable.pagination.perPageDefault,
                    searchPo: that.purchaseOrder.datatable.searchPo,
                    searchItem: that.purchaseOrder.datatable.searchItem,
                    date_from: that.filters.date_from,
                    date_to: that.filters.date_to,
                    users: that.filters.users.selected.map(e => { return e.value }),
                    filter: that.filters.type
                }
                this.$store.dispatch('getPoPurchaseOrder', payload).then((response) => {
                    that.is_super_admin = this.GET_PO_PURCHASE_ORDER.is_super_admin
                    that.user.president = this.GET_PO_PURCHASE_ORDER.user
                    resolve(response);
                });
            });

            if (wait) {
                that.toggleLoading();
                return promise;
            } else {
                promise.then((response) => {
                    that.purchaseOrder.datatable.data = this.GET_PO_PURCHASE_ORDER.purchase_orders.data;
                    that.purchaseOrder.datatable.pagination.total = this.GET_PO_PURCHASE_ORDER.purchase_orders.total;
                    that.toggleLoading();
                });
            }

            that.url = process.env.VUE_APP_API_HOST
        },

        view(purchaseOrder, mode = `view`) {
            let that = this;
            that.resetEdited();
            let view = _.find(that.purchaseOrder.datatable.data, item => purchaseOrder.id === item.id);
            that.editedIndex = that.purchaseOrder.datatable.data.indexOf(view);
            that.estemated_time
            that.toggleDialog(`editedPurchaseOrder`);
            that.estemated_date = !!purchaseOrder.estemated_time_departure ? purchaseOrder.estemated_time_departure : this.$dayjs().endOf('month').format('YYYY-MM-DD')
            that.editedPurchaseOrder = Object.assign({}, {
                ...that.editedPurchaseOrder,
                mode: mode,
                id: view.id,
                poNumber: view.po_number,
                remarks: view.remarks,
                date: view.date,
                supplier: view.supplier,
                supplierAddress: view.supplier_address
            });
            that.getItems();
            that.getPurchaseOrderDetails(1, 'view');
        },

        async getPurchaseOrderDetails(page = 1, from = '') {
            let that = this;
            let items = []

            that.editedPurchaseOrder.details.datatable.pagination.options.page = page;
            that.editedPurchaseOrder.details.datatable.headers = []

            // if (that.editedPurchaseOrder.details.loading || that.editedPurchaseOrder.id === null) {
            //     return false;
            // }

            that.editedPurchaseOrder.details.loading = true;

            // paginate in array
            let itemsPerpage = that.editedPurchaseOrder.details.datatable.pagination.options.itemsPerPage
                || that.editedPurchaseOrder.details.datatable.pagination.perPageDefault

            let offset = itemsPerpage * (page - 1)

            if (from == 'view' || from == 'orderQuantity') {
                let payload = {
                    id: that.editedPurchaseOrder.id,
                    params: {
                        types: that.filters.type_id.selected.map(e => { return e.value }),
                        categories: that.filters.category_id.selected.map(e => { return e.value }),
                        items: items.map(e => { return e.id }),
                        withOrderQuantity: that.purchaseOrder.withOrderQuantity,
                    }
                }

                await that.$store.dispatch('getPoDetailsItem', payload).then(response => {
                    that.editedPurchaseOrder.paginatedItems = response.data.items
                    this.expData = response.data.items
                    that.editedPurchaseOrder.details.datatable.pagination.total = response.data.total_records
                })
            }

            // if (that.editedPurchaseOrder.paginatedItems.length == 0) {
            //     that.editedPurchaseOrder.details.loading = false;
            //     return false
            // }
            items = that.editedPurchaseOrder.paginatedItems?.filter((e, i) => {
                if (offset - 1 < i && i < (itemsPerpage + offset)) {
                    return e
                }
            })
            let payload = {
                id: that.editedPurchaseOrder.id,
                params: {
                    page: page,
                    items: items,
                    itemsPerPage: that.editedPurchaseOrder.details.datatable.pagination.options.itemsPerPage
                        || that.editedPurchaseOrder.details.datatable.pagination.perPageDefault,
                    withComputations: that.purchaseOrder.withComputations,
                    types:that.filters.type_id.selected,
                    categories:that.filters.category_id.selected,
                    items:that.filters.item_id.selected
                }
            }
            this.$store.dispatch('getPoDetails', payload).then((response) => {
                that.editedPurchaseOrder.details.datatable.data = this.GET_PO_DETAILS.original.details.data.map(e=>{e.remove = false; return e});
                that.editedPurchaseOrder.details.loading = false;
                that.editedPurchaseOrder.details.edited = this.GET_PO_DETAILS.original.details.data.find(a => a.order_quantity > 0)
                that.user.allowToApprove = this.GET_PO_DETAILS.user
                that.user.manager = this.GET_PO_DETAILS.manager
                that.purchaseOrder.status = this.GET_PO_DETAILS.status
                that.editedPurchaseOrder.totalQuantity = this.GET_PO_DETAILS.original.total_quantity
                that.editedPurchaseOrder.totalCount = this.GET_PO_DETAILS.original.total_count

                if (that.purchaseOrder.withComputations == '1') {
                    that.editedPurchaseOrder.details.datatable.headers.push({ text: 'IMAGE', value: 'base64img',align: 'center', sortable: false, width: "100px", divider: true })
                    that.editedPurchaseOrder.details.datatable.headers.push({ text: 'MODEL', value: 'model', sortable: false, width: "150px", divider: true })
                    that.editedPurchaseOrder.details.datatable.headers.push({ text: 'NAME', value: 'name', sortable: false, width: "300px", divider: true })
                    that.editedPurchaseOrder.details.datatable.headers.push({ text: 'AC', value: 'total_average_sales', sortable: false, width: "50px", divider: true })
                    that.editedPurchaseOrder.details.datatable.headers.push({ text: 'IS', value: 'total_incoming_stocks', sortable: false, width: "50px", divider: true }),
                    that.editedPurchaseOrder.details.datatable.headers.push({ text: 'PC', value: 'total_pending_container_stocks', sortable: false, width: "50px", divider: true }),
                    that.editedPurchaseOrder.details.datatable.headers.push({ text: 'PP', value: 'purchase_order_total_pending', align: 'right', sortable: false, width: "50px", divider: true })
                    that.editedPurchaseOrder.details.datatable.headers.push({ text: 'PS', value: 'total_pending_so', align: 'right', sortable: false, width: "50px", divider: true })
                    that.editedPurchaseOrder.details.datatable.headers.push({ text: 'AS', value: 'item_total_stock', align: 'right', sortable: false, width: "50px", divider: true })
                    that.editedPurchaseOrder.details.datatable.headers.push({ text: 'BQ', value: 'item_po_moq', align: 'right', sortable: false, width: "50px", divider: true })
                    that.editedPurchaseOrder.details.datatable.headers.push({ text: 'OQ', value: 'order_quantity', align: 'right', sortable: false, width: "40px", divider: true })
                    that.editedPurchaseOrder.details.datatable.headers.push({ text: 'TOQ', value: 'total_order_quantity', align: 'right', sortable: false, width: "40px", divider: true })
                    that.editedPurchaseOrder.details.datatable.headers.push({ text: 'REMARKS', value:'remarks', align: 'left', sortable: false, width: "270px", divider: true })
                    that.editedPurchaseOrder.details.datatable.headers.push({ text: 'SERVED BY CONTAINER #', value: 'container_numbers', align: 'left', sortable: false, width: "250px", divider: true })
                    that.editedPurchaseOrder.details.datatable.headers.push({ text: 'Actions', value: 'action', align: 'center', sortable: false, width: "30px", divider: true })
                } else {
                    that.editedPurchaseOrder.details.datatable.headers.push({ text: 'IMAGE', value: 'base64img', align: 'center',sortable: false, width: "100px", divider: true })
                    that.editedPurchaseOrder.details.datatable.headers.push({ text: 'MODEL', value: 'model', sortable: false, width: "150px", divider: true })
                    that.editedPurchaseOrder.details.datatable.headers.push({ text: 'NAME', value: 'name', sortable: false, width: "300px", divider: true })
                    that.editedPurchaseOrder.details.datatable.headers.push({ text: 'OQ', value: 'order_quantity', align: 'right', sortable: false, width: "40px", divider: true })
                    that.editedPurchaseOrder.details.datatable.headers.push({ text: 'TOQ', value: 'total_order_quantity', align: 'right', sortable: false, width: "40px", divider: true })
                    that.editedPurchaseOrder.details.datatable.headers.push({ text: 'REMARKS', value:'remarks', align: 'left', sortable: false, width: "270px", divider: true })
                    that.editedPurchaseOrder.details.datatable.headers.push({ text: 'SERVED BY CONTAINER #', value: 'container_numbers', align: 'left', sortable: false, width: "250px", divider: true })
                    that.editedPurchaseOrder.details.datatable.headers.push({ text: 'Actions', value: 'action', align: 'center', sortable: false, width: "30px", divider: true })
                }

                that.editedPurchaseOrder.details.datatable.headers = _.uniqBy(that.editedPurchaseOrder.details.datatable.headers, 'text')
            });
        },

        formatNumber(num) {
            let num_parts = num.toString().split(".");
            num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return num_parts.join(".");
        },

        async createPurchaseOrder() {
            let that = this;

            that.toggleLoading();

            if(that.purchaseOrder.action == 'Add'){
                that.editedPurchaseOrder.details.datatable.data.forEach(e=>{
                    that.create.inputs.pasted.forEach(element =>{
                        if(element.model == e.model){
                            element.quantity += parseInt(e.order_quantity)
                        }
                    })
                })
            }

            let payload = {
                type_id: that.filters.type_id.selected,
                items: that.create.inputs.pasted.length > 0 ? that.create.inputs.pasted.map(e=>{
                    e.order_quantity = parseInt(e.quantity)
                    return e
                }) : [],
                id:that.purchaseOrder.action == 'Add' ? that.editedPurchaseOrder.id : '',
                action: that.purchaseOrder.action
            }

            if(that.create.tab == 1 || that.purchaseOrder.action == 'Add'){
                let notInSysten = that.create.inputs.pasted.filter(e=>e.error == true)
                if(notInSysten.length > 0){
                    swal.fire('Warning!','plase check the items pasted','warning')
                    that.toggleLoading();
                    return false
                }
            }else{
                that.create.inputs.pasted = {}
                payload.items = null
            }

            await new Promise((resolve, reject) => {
                this.$store.dispatch(`storePo`, payload).then(response => {
                    resolve(response);
                }).catch(error => reject(error));
            }).then(async resolved => {
                that.toggleLoading();
                that.toggleDialog(`create`);

                let createdPurchaseOrder = resolved.data.created;

                that.purchaseOrder.datatable.searchPo = createdPurchaseOrder.po_number;
                that.purchaseOrder.datatable.searchItem = '';

                await that.list(1, true).then((response) => {
                    that.purchaseOrder.datatable.data = response.data.purchase_orders.data;
                    that.purchaseOrder.datatable.pagination.total = response.data.purchase_orders.total;

                    that.view(createdPurchaseOrder, 'edit');
                });
            }).catch(error => {
                that.toggleLoading();
                that.toggleDialog(`create`);
                that.list();
                alert(error)
            });
        },

        update(purchaseOrder, action) {
            let that = this;

            if (that.loading.state) {
                return false;
            }

            let currentDate = new Date().getTime()

            if (currentDate > new Date(that.estemated_date).getTime()) {
                swal.fire('Error!', '- Please check the ETD', 'error')
                return false
            }

            that.toggleLoading();
            let payload = {
                id: purchaseOrder.id,
                params: {
                    remarks: purchaseOrder.remarks,
                    estemated_time_departure: that.estemated_date,
                    action: action
                }
            }
            that.$store.dispatch('poUpdate', payload).then((response) => {
                swal.fire(`Purchase Order Updated : ${purchaseOrder.poNumber}`, '', 'success');
                that.toggleLoading();
                that.toggleDialog('editedPurchaseOrder');
                that.list(that.purchaseOrder.datatable.pagination.options.page);
            }).catch((error) => {
                that.toggleLoading();
                that.alertWarnings(error.response.data.message, error.response.data.errors);
            });
        },

        destroy(purchaseOrder) {
            swal.fire({
                title: "",
                text: "Are you sure you want to Delete?",
                icon: 'question',
                showConfirmButton: true,
                confirmButtonText: 'Confirm',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: '397373',
                cancelButtonColor: 'grey',
                reverseButtons: true,
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        let that = this;

                        if (that.loading.state) {
                            return false;
                        }

                        that.toggleLoading();

                        that.$store.dispatch('destroyPo', purchaseOrder.id).then((response) => {
                            swal.fire(`Purchase Order Deleted : ${purchaseOrder.po_number}`, '', 'success');
                            that.toggleLoading();
                            that.list();
                        }).catch((error) => {
                            that.toggleLoading();
                            that.alertWarnings(error.response.data.message, error.response.data.errors);
                        });
                    }
                });
        },
        toggleLoading(){
            this.loading.state = !this.loading.state;
        },

        async exportExcel(purchaseOrder, format = null) {
            let that = this
            let filters = JSON.stringify({ exclude_fully_served: this.exclude_fully_served });
            that.toggleLoading()
            const workbook = new this.$exceljs.Workbook()
            const worksheet = workbook.addWorksheet('Purchase Order');
            let po_details = []
            let po = ''

            let headerStyle = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '5b9bd5' }
            }

            let bodyStyle = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'ddebf7' }
            }

            if (format == 'order-template') {

                let payload = {
                    id: purchaseOrder.id,
                    params: {
                        format: format,
                        filters: filters
                    }
                }
                await that.$store.dispatch('getPoExport', payload).then(response => {
                    po_details = that.GET_PO_EXPORT.data
                    po = that.GET_PO_EXPORT.po
                })

                let payload_incoming = {
                    items: po_details.map(e => { return e.item_id })
                }

                await that.$store.dispatch('getIncomingStocks', payload_incoming).then(response => {

                    that.GET_INCOMING_STOCKS.data.forEach((e, i) => {
                        po_details[i].total_incoming_stocks = e.total_incoming_stocks
                    })
                })

                await that.$store.dispatch('getAverageConsumption', payload_incoming).then(response => {

                    that.GET_AVERAGE_CONSUMPTION.data.forEach((e, i) => {
                        po_details[i].total_average_sales = e.total_average_sales
                    })
                })

                await that.$store.dispatch('getPendingOrders', payload_incoming).then(response => {

                    that.GET_PENDING_ORDERS.data.forEach((e, i) => {
                        po_details[i].total_pending_so = e.total_pending_so
                    })
                })

                await that.$store.dispatch('getPoItemStocks', payload_incoming).then(response => {

                    that.GET_PO_ITEM_STOCKS.data.forEach((e, i) => {
                        po_details[i].item_total_stock = e.item_total_stock
                    })
                })
                worksheet.columns = [
                    { header: 'Detail id', key: 'detail_id' },
                    { header: 'Model', key: 'model' },
                    { header: 'Name', key: 'name' },
                    { header: 'AC', key: 'total_average_sales' },
                    { header: 'IS', key: 'total_incoming_stocks' },
                    { header: 'PP', key: 'purchase_order_total_pending' },
                    { header: 'PS', key: 'total_pending_so' },
                    { header: 'AS', key: 'item_total_stock' },
                    { header: 'BQ', key: 'item_po_moq' },
                    { header: 'OQ', key: 'order_quantity' },
                    { header: 'TOQ', key: 'total_order_quantity' },
                    { header: 'Remarks', key: 'remarks'},
                    { header: 'SERVED BY CONTAINER #', key: 'container_numbers' },
                ]

                worksheet.getCell(`A${1}`).fill = headerStyle
                worksheet.getCell(`B${1}`).fill = headerStyle
                worksheet.getCell(`C${1}`).fill = headerStyle
                worksheet.getCell(`D${1}`).fill = headerStyle
                worksheet.getCell(`E${1}`).fill = headerStyle
                worksheet.getCell(`F${1}`).fill = headerStyle
                worksheet.getCell(`G${1}`).fill = headerStyle
                worksheet.getCell(`H${1}`).fill = headerStyle
                worksheet.getCell(`I${1}`).fill = headerStyle
                worksheet.getCell(`J${1}`).fill = headerStyle
                worksheet.getCell(`K${1}`).fill = headerStyle
                worksheet.getCell(`L${1}`).fill = headerStyle
                worksheet.getCell(`M${1}`).fill = headerStyle

                po_details.forEach((e, i) => {

                    // e.total_order_quantity = parseInt(e.total_order_quantity)
                    // e.item_po_moq = parseInt(e.item_po_moq)
                    // e.item_total_stock = parseInt(e.item_total_stock)
                    // e.total_pending_so = parseInt(e.total_pending_so)
                    // e.purchase_order_total_pending = parseInt(e.purchase_order_total_pending)
                    // e.total_incoming_stocks = parseInt(e.total_incoming_stocks)
                    // e.total_average_sales = parseInt(e.total_average_sales)

                    worksheet.addRow(e)

                    worksheet.getCell(`E${i + 2}`).value = parseInt(e.total_incoming_stocks)
                    worksheet.getCell(`E${i + 2}`).numFmt = '#,##0'

                    worksheet.getCell(`F${i + 2}`).value = parseInt(e.purchase_order_total_pending)
                    worksheet.getCell(`F${i + 2}`).numFmt = '#,##0'

                    worksheet.getCell(`G${i + 2}`).value = parseInt(e.total_pending_so)
                    worksheet.getCell(`G${i + 2}`).numFmt = '#,##0'

                    worksheet.getCell(`H${i + 2}`).value = parseInt(e.item_total_stock)
                    worksheet.getCell(`H${i + 2}`).numFmt = '#,##0'


                    worksheet.getCell(`K${i + 2}`).value = { formula: `I${i + 2}*J${i + 2}`, date1904: false }

                    if (i % 2 != 0) {

                        worksheet.getCell(`A${i + 2}`).fill = bodyStyle
                        worksheet.getCell(`B${i + 2}`).fill = bodyStyle
                        worksheet.getCell(`C${i + 2}`).fill = bodyStyle
                        worksheet.getCell(`D${i + 2}`).fill = bodyStyle
                        worksheet.getCell(`E${i + 2}`).fill = bodyStyle
                        worksheet.getCell(`F${i + 2}`).fill = bodyStyle
                        worksheet.getCell(`G${i + 2}`).fill = bodyStyle
                        worksheet.getCell(`H${i + 2}`).fill = bodyStyle
                        worksheet.getCell(`I${i + 2}`).fill = bodyStyle
                        worksheet.getCell(`J${i + 2}`).fill = bodyStyle
                        worksheet.getCell(`K${i + 2}`).fill = bodyStyle
                        worksheet.getCell(`L${i + 2}`).fill = bodyStyle
                        worksheet.getCell(`M${i + 2}`).fill = bodyStyle
                    }
                })

                workbook.xlsx.writeBuffer().then((data) => {
                    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                    this.$filesaver.saveAs(blob, `${po.po_number} .xlsx`);
                });
                that.toggleLoading()

            } else {

                let date = new Date();
                this.dateToday = date.toLocaleString().split('T')[0];
                let payload = {
                    id: purchaseOrder.id,
                    params: {
                        format: format,
                        items: this.expData
                    }
                }
                await that.$store.dispatch('getPoExport', payload).then(async response => {
                    po_details = that.GET_PO_EXPORT.data
                    po = that.GET_PO_EXPORT.po

                        let img = document.createElement('img');
                        let logo = ''

                        await this.getBase64Image(`${process.env.VUE_APP_API_HOST}/img/public?image=ingcoph-logo.png`, (res) => {
                            img.setAttribute('data', res);

                        }).then(response => {
                            logo = response

                        }).catch(err=>{
                            console.log(err)
                            that.toggleLoading()
                        });

                        let addLogo = workbook.addImage({
                            base64: logo,
                            extension: 'jpg',
                        });

                        worksheet.addImage(addLogo, {
                            tl: { col: 1.95, row: 1  },
                            ext: { width: 170, height: 75 },
                            editAs: 'undefined'
                        });
                        // let ingcoPhLogo = new Image();
                        // ingcoPhLogo.src = '/images/ingcoph-logo.png';
                        // doc.addImage(ingcoPhLogo, 'png', 4, 3, 40, 15);

                    worksheet.getRow(10).values = ['IMAGE', 'MODEL', 'NAME', 'Qty'];
                    worksheet.columns = [
                        { key: '', width: 15, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } },},
                        { key: 'item_model', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }},
                        { key: 'name', width: 30, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }},
                        { key: 'qty', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }}
                    ]

                    let pos = 10;
                    let addrow = 11
                    this.has_images = 1

                    for (let index in this.GET_PO_EXPORT.data) {

                        worksheet.addRow(this.GET_PO_EXPORT.data[index]).height =100;

                        if (this.has_images == 1) {
                            let img = document.createElement('img');
                            let base64 = ''

                            await this.getBase64Image(`${process.env.VUE_APP_API_HOST}/img/items?image=${this.GET_PO_EXPORT.data[index].item_model}`, (res) => {
                                img.setAttribute('data', res);

                            }).then(response => {
                                base64 = response

                            })

                            let finalImage = workbook.addImage({
                                base64: base64,
                                extension: 'jpg',
                            });

                            worksheet.addImage(finalImage, {
                                tl: { col: 0.4, row: pos+ .2  },
                                ext: { width: 60, height: 60 },
                                editAs: 'undefined'
                            });
                            worksheet.getRow(addrow).height = 50;

                            pos++;
                            addrow++;
                        }
                    }

                    let borderStyles = {
                        top: { style: "thin" },
                        left: { style: "thin" },
                        bottom: { style: "thin" },
                        right: { style: "thin" }
                    };

                    worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
                        row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
                            cell.border = borderStyles;
                        });
                    });
                    worksheet.getRow(`0${8}`).font = { bold: true };
                    worksheet.getRow(`0${7}`).font = { bold: true };



                    const supplier_po_Details = ['Supplier: INGCO China', 'PO#: ' + po_details[0].po_number];
                    const address_po_date = ['Address: Mainland China', 'Date: ' + po_details[0].date];
                    const signature = ['DIANNE ORLEANS', 'STEVEN ANDREW YANG']
                    const line = ['___________', '______________']
                    const by = ['CREATED BY', 'APPROVED BY']
                    worksheet.headerFooter.oddFooter = "&L"+this.dateToday + "&RPage &P of &N";

                    worksheet.getCell('B7').value = 'Supplier: INGCO China';
                    worksheet.getCell('C7').value = 'PO#: ' + po_details[0].po_number;
                    worksheet.getCell('B8').value = 'Address: Mainland China';
                    worksheet.getCell('C8').value = 'Date: ' + po_details[0].date;

                    const row = worksheet.lastRow.number + 2;
                    worksheet.getRow('0'+row).font = { bold: true };

                    worksheet.getCell('B'+row).width = 50;

                    worksheet.getCell('B'+row).value = 'DIANNE ORLEANS'
                    worksheet.getCell('C'+row).value = 'STEVEN ANDREW YANG'
                    worksheet.getCell('B'+(row+1)).value = '___________'
                    worksheet.getCell('C'+(row+1)).value = '______________'
                    worksheet.getCell('B'+(row+2)).value = 'CREATED BY'
                    worksheet.getCell('C'+(row+2)).value = 'APPROVED BY'



                    workbook.xlsx.writeBuffer().then((data) => {
                        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                        this.$filesaver.saveAs(blob, `${po.po_number} .xlsx`);
                    });
                    that.toggleLoading()
                })

            }
        },

        async getBase64Image(src, callback) {
            let img = new Image();
            img.src = src
            img.crossOrigin = "Anonymous"
            let imageHandler = new Promise(resolve => {
                img.onload = function () {
                    let canvas = document.createElement("canvas");
                    canvas.width = img.naturalWidth;
                    canvas.height = img.naturalHeight;
                    let ctx = canvas.getContext("2d");
                    ctx.drawImage(this, 0, 0);

                    let dataUrl = canvas.toDataURL("image/jpg");
                    resolve(dataUrl);
                }
            })

            return imageHandler
        },

        async onPasteForPo(evt) {
            this.toggleLoading()
            let handler = []
            var pastedText = evt.clipboardData.getData('Text');

            if(!pastedText){
                this.toggleLoading()
                return false;
            }
            else{
                var rows = pastedText.split("\r\n")
                var cells = '';

                for (var y in rows) {

                    if (y != rows.length + 1) {
                        cells = rows[y].split("\t");
                        handler.push({
                            model: cells[0],

                            quantity: parseInt(cells[1]),
                            remarks: cells[2],
                            error:false
                        })
                    }
                }

                await this.$store.dispatch('getItemsByModel', {
                    items: handler.map(e=>{ return e.model.toUpperCase()}),
                    isChecking:true,
                }).then(response => {
                    let models = response.data.map(e=>{ return e.model})

                    handler = handler.filter((e, i)=>{
                        if(!models.includes(e.model.toUpperCase())){
                            e.error = true;
                        }else{
                            let item = _.find(response.data,item=> item.model == e.model)
                            if(item){
                                e.id = item.item_id
                            }
                        }

                        return e
                    })

                    this.create.inputs.pasted = this.create.inputs.pasted.concat(handler)

                    this.create.inputs.pasted = this.create.inputs.pasted.filter(e=>{
                        if(!!e.quantity){
                            return e
                        }
                    })

                    let temp = []

                    this.create.inputs.pasted.forEach(e=>{
                        if(!!temp.find(element=>element.model == e.model)){
                            temp.forEach(element=>{
                                if(element.model == e.model){
                                    element.quantity += e.quantity
                                }
                            })

                        }else{
                            temp.push(e)
                        }
                    })

                    this.create.inputs.pasted = temp

                });
                this.toggleLoading()
            }
        },

        validateInputModel(index){
            this.toggleLoading()
            if(this.create.inputs.notIncluded.length != 0){
                 this.$store.dispatch('getItemsByModel', {
                    items: this.create.inputs.pasted.map(e=>{return e.dataFromExcel.toUpperCase()}),
                    isChecking:true
                }).then(response => {
                    this.toggleLoading()
                    response.data = response.data.map(e=>{return e.model})
                    if(response.data.includes(this.create.inputs.pasted[index].dataFromExcel.toUpperCase())){
                        this.isDisabledButton = false;
                        this.create.inputs.pasted[index].error = false;
                    }
                    else{
                        this.create.inputs.pasted[index].error = true
                        this.isDisabledButton = true;
                    }
                });
            }
            else{
                this.$store.dispatch('getItemsByModel', {
                    items: this.create.inputs.pasted.map(e=>{return e.dataFromExcel.toUpperCase()}),
                    isChecking:true
                }).then(response => {
                    this.toggleLoading()
                    response.data = response.data.map(e=>{return e.model})
                    if(response.data.includes(this.create.inputs.pasted[index].dataFromExcel.toUpperCase())){
                        this.create.inputs.pasted[index].error = false
                    }
                    else{
                        this.create.inputs.pasted[index].error = true
                        this.create.inputs.notIncluded.push(this.create.inputs.pasted[index].dataFromExcel.toUpperCase())
                        this.isDisabledButton = true;
                    }
                });
            }
        },

        manualAddItems(){
            this.create.inputs.pasted.push({
                model: '',
                quantity:null,
                remarks: '',
                error:false
            })
        },

        resetImportExcel() {
            this.importExcel.errors.data = [];
            // this.importExcel.form.excel = null;
            this.importExcel.loading = false;
        },

        getManagers() {
            let that = this

            that.$store.dispatch('getAllManagers').then(response => {
                that.filters.users.selection = that.GET_ALL_MANAGERS
            })
        },

        manualRemoveItem(index){
            if(this.create.inputs.pasted.length == 1){
                this.create.inputs.pasted = [{ model:'', quantity:null, error:false }]
            }
            this.create.inputs.pasted = this.create.inputs.pasted.filter((e,i)=>{
                if(i != index){
                    return e
                }
            })

        },
        rowClass(item) {
            const rowClass = '#f4fab5'
            if(item.auto_updated == 1){
                return ''
            }else if(item.new_item){
                return 'new-item'
            }else if(item.updated){
                return 'updated-item'
            }
        },

        async importOrderTemplate(purchaseOrder) {
            let that = this
            const wb = new this.$exceljs.Workbook();
            const reader = new FileReader();
            let data = [];

            reader.readAsArrayBuffer(this.importExcel.form.excel)
            reader.onload = () => {
                const buffer = reader.result;
                wb.xlsx.load(buffer).then(workbook => {
                    workbook.eachSheet((sheet, id) => {
                        sheet.eachRow((row, rowIndex) => {
                            if (rowIndex > 1) {
                                if (row.values.length == 11) {
                                    !!row.values[9] ? data.push({ id: row.values[1], order_quantity: row.values[9], remarks: row.values[11]}) : ''
                                } else {
                                    !!row.values[10] ? data.push({ id: row.values[1], order_quantity: row.values[10], remarks: row.values[12]}) : ''
                                }
                            }
                        })
                    })
                    let payload = {
                        id: purchaseOrder.id,
                        params: {
                            data: data,
                        }
                    }
                    this.$store.dispatch('importOrderTemplate', payload).then(function (response) {
                        that.importExcel.loading = false;
                        swal.fire({
                            title: `Excel Imported`,
                            text: `Success`,
                            icon: "info",
                            dangerMode: false,
                        });
                        that.getPurchaseOrderDetails(1);
                        that.resetImportExcel();
                        that.importExcel.dialog = false;
                    }).catch(function (error) {
                        that.importExcel.loading = false;
                        that.importExcel.form.excel = null;

                        if (_.isArray(error.response.data.errors)) {
                            that.importExcel.errors.data = error.response.data.errors
                        } else {
                            that.alertWarnings(error.response.data.message, error.response.data.errors);
                        }
                    });
                })
            }
        },

        toggleDialog(dialogable = null) {
            let that = this;

            if (that.loading.state || !dialogable || that.editedPurchaseOrder.details.loading) {
                return false;
            }

            dialogable = dialogable.split('.');

            if (dialogable.length === 1) {
                that[dialogable].dialog = !that[dialogable].dialog;
            } else {

                let path = that;

                dialogable.forEach(i => {
                    path = path[i];
                });

                path.dialog = !path.dialog;
            }
        },

        alertWarnings(message, errors) {
            let that = this;
            let errorMessages = [];

            Object.keys(errors).forEach((key) => {

                if (key === 'authorization') {
                    //Close Recent Dialog || All Dialog
                }

                errors[key].forEach(message => {
                    errorMessages.push(`- ${message}`);
                })
            })

            swal.fire({
                title: message,
                text: errorMessages.join("\n"),
                icon: "warning",
                dangerMode: true,
            });
        },

        async exportList() {
            let item_base64_arr = [];
            let that = this
            let items = [];

            that.toggleLoading()

            if(this.exportOptions.with_image == 1){
                await this.$store.dispatch('purchaseOrderPost',{url:'getItemBase64PO'}).then((res)=>{
                    item_base64_arr = res.data
                })
            }

            const workbook = new this.$exceljs.Workbook();

            if (this.exportOptions.po_details) {
                const po_details = workbook.addWorksheet('PO details');
                let payload = {
                    filter: that.filters.type,
                    date_from: that.filters.date_from,
                    date_to: that.filters.date_to,
                    has_image: that.exportOptions.with_image
                }

                await that.$store.dispatch('exportRawData', payload).then(async response => {

                    items = that.GET_PO_EXPORT_RAW_DATA.items
                    let po_details_columns = [
                        { header: 'IMAGE', key: 'image', width: 22, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, },
                        { header: 'PO#', key: 'po_number', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } } },
                        // { header: 'PO#', key: 'po_number' },
                        { header: 'Model', key: 'model', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } } },
                        // { header: 'Model', key: 'model' },
                        { header: 'Name', key: 'name', width: 50, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } } },
                        // { header: 'Name', key: 'name' },
                        { header: 'UOM', key: 'uom', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } } },
                        // { header: 'UOM', key: 'uom' },
                        { header: 'Type', key: 'type', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } } },
                        // { header: 'Type', key: 'type' },
                        { header: 'Category', key: 'category', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } } },
                        // { header: 'Category', key: 'category' },
                        { header: 'ETD', key: 'estemated_time_departure', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } } },
                        // { header: 'ETD', key: 'estemated_time_departure' },
                        { header: 'Fulfillment Date', key: 'fulfillment_date', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } } },
                        // { header: 'Fulfillment Date', key: 'fulfillment_date' },
                        { header: 'Created Date', key: 'date_created', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } } },
                        // { header: 'Created Date', key: 'date_created' },
                        { header: 'Order Qty', key: 'order_quantity', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } } },
                        // { header: 'Order Qty', key: 'order_quantity' },
                        { header: 'Served Qty', key: 'served_quantity', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } } },
                        // { header: 'Served Qty', key: 'served_quantity' },
                        { header: 'Pending Qty', key: 'pending_quantity', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } } },
                        // { header: 'Pending Qty', key: 'pending_quantity' },
                        { header: 'Containers', key: 'container_nums', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } } },
                        { header: 'Containers', key: 'container_nums' },
                    ];

                    if (this.exportOptions.with_image != 1) {
                        po_details_columns.splice(0, 1)
                    }
                    po_details.columns = po_details_columns

                    let po_details_data = that.GET_PO_EXPORT_RAW_DATA.data;
                    // that.GET_PO_EXPORT_RAW_DATA.data.forEach(e => {
                    //     po_details.addRow(e);
                    // });
                    let po_details_pos = 1
                    for await (const item of po_details_data) {
                        po_details.addRow(item);
                        if(this.exportOptions.with_image == 1){
                            item.base64img              = item_base64_arr.find((e)=> e.model == item.model).base64img;
                            await this.addImageToWorksheet(workbook, po_details, item, po_details_pos);
                        }
                        po_details_pos++
                    }
                }).catch(e => {
                    swal.fire('Error!', '', 'error')
                    console.log(e)
                    that.toggleLoading()
                })
            }

            if (this.exportOptions.item_details) {
                const item_details = workbook.addWorksheet('Item Details');
                let payload_item = {
                    items: items
                }

                await that.$store.dispatch('exportItemDetail', payload_item).then(async response => {
                    let item_detail_columns = [
                        { header: 'IMAGE', key: 'image', width: 22, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, },
                        { header: 'Model', key: 'model', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } } },
                        { header: 'Name', key: 'name', width: 50, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } } },
                        { header: 'Remarks', key: 'remarks', width: 50, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } } },
                        { header: 'New Models', key: 'new_models', width: 40, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } } },
                        { header: 'UOM', key: 'uom', width: 40, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } } },
                        { header: 'Type', key: 'type', width: 40, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } } },
                        { header: 'Category', key: 'category', width: 40, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } } },
                        // { header: 'Average Sales (6mos)', style: { numFmt: '#,##0' }, key: 'total_average_consumption_6' },
                        // { header: 'Average Sales (3mos)', style: { numFmt: '#,##0' }, key: 'total_average_consumption_3' },
                        // { header: 'Previous Stocks', style: { numFmt: '#,##0' }, key: 'stocks_prior_3' },
                        // { header: 'Sales Consumption (1mo)', style: { numFmt: '#,##0' }, key: 'total_consumption_first' },
                        // { header: 'Sales Consumption (2mo)', style: { numFmt: '#,##0' }, key: 'total_consumption_second' },
                        // { header: 'Sales Consumption (3mo)', style: { numFmt: '#,##0' }, key: 'total_consumption_third' },
                        { header: 'Total Sales (3mos)', key: 'total_consumption_3', width: 30, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false }, numFmt: '#,##0' } },
                        // { header: 'Total Sales (3mos)', style: { numFmt: '#,##0' }, key: 'total_consumption_3' },
                        { header: 'Incoming Stocks', key: 'total_incoming_stocks', width: 30, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false }, numFmt: '#,##0' } },
                        // { header: 'Incoming Stocks', style: { numFmt: '#,##0' }, key: 'total_incoming_stocks' },
                        // { header: 'Total Pending SO (6mos)', style: { numFmt: '#,##0' }, key: 'total_pending_so_6' },
                        { header: 'Total Pending SO (3mos)', key: 'total_pending_so_3', width: 30, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false }, numFmt: '#,##0' } },
                        // { header: 'Total Pending SO (3mos)', style: { numFmt: '#,##0' }, key: 'total_pending_so_3' },
                        // { header: 'Total Pending SO', style: { numFmt: '#,##0' }, key: 'total_pending_so' },
                        { header: 'Total Pending SC', key: 'total_pending_sc', width: 30, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false }, numFmt: '#,##0' } },
                        // { header: 'Total Pending SC', style: { numFmt: '#,##0' }, key: 'total_pending_sc' },
                        // { header: 'Total Pending PO', style: { numFmt: '#,##0' }, key: 'total_pending_po' },
                        { header: 'Available Stocks', key: 'quantity', width: 30, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false }, numFmt: '#,##0' } },
                        // { header: 'Available Stocks', style: { numFmt: '#,##0' }, key: 'quantity' },
                        // { header: 'Total PO Ordered', style: { numFmt: '#,##0' }, key: 'total_po' },
                        // { header: 'Total PO Served', style: { numFmt: '#,##0' }, key: 'total_po_served' },
                        { header: 'Available BOQ', key: 'moq_po', width: 30, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false }, numFmt: '#,##0' } },
                        // { header: 'BOQ', style: { numFmt: '#,##0' }, key: 'moq_po' },
                        { header: 'Discontinue Order Date', key: 'discontinue_order_date', width: 30, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } } },
                        { header: 'Limit Order', key: 'limit_order', width: 30, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false }, numFmt: '#,##0' } },
                        { header: 'China Pending', key: 'china_pending', width: 30, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false }, numFmt: '#,##0' } },
                        // { header: 'China Pending', style: { numFmt: '#,##0' }, key: 'china_pending' },
                        { header: 'Last Update (China Pending)', key: 'china_pending_last_updated', width: 30, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false }, } },
                        // { header: 'Last Update (China Pending)', key: 'china_pending_last_updated' },
                    ]

                    // if (this.GET_USER_COSTING) {
                    //     item_detail_columns.push({ header: 'Item Cost', style: { numFmt: '#,##0.00' }, key: 'cost_in_php' })
                    //     item_detail_columns.push({ header: 'Total PO Cost', style: { numFmt: '#,##0.00' }, key: 'total_po_cost' })
                    // }
                    if (this.exportOptions.with_image != 1) {
                        item_detail_columns.splice(0, 1)
                    }
                    item_details.columns = item_detail_columns
                    let item_details_pos = 1
                    let item_details_data = that.GET_PO_EXPORT_ITEM_DETAILS.data;
                    for await (const item of item_details_data) {

                        item.total_consumption_3 = parseInt(item.total_consumption_3)
                        // item.total_consumption_first = parseInt(item.total_consumption_first)
                        // item.total_consumption_second = parseInt(item.total_consumption_second)
                        // item.total_consumption_third = parseInt(item.total_consumption_third)
                        item.total_incoming_stocks = parseInt(item.total_incoming_stocks)
                        // item.total_pending_so_6 = parseInt(item.total_pending_so_6)
                        item.total_pending_so_3 = parseInt(item.total_pending_so_3)
                        // item.total_pending_so = parseInt(item.total_pending_so)
                        item.total_pending_sc = parseInt(item.total_pending_sc)
                        // item.total_pending_po = parseInt(item.total_pending_po)
                        item.quantity = parseInt(item.quantity)
                        // item.total_po = parseInt(item.total_po)
                        // item.total_po_served = parseInt(item.total_po_served)
                        item.moq_po = parseInt(item.moq_po)
                        item.limit_order = !!item.limit_order ? parseInt(item.limit_order) : "";
                        item.discontinue_order_date = !!item.discontinue_order_date ? new Date(item.discontinue_order_date) : "";
                        item.china_pending_last_updated = !!item.china_pending_last_updated ? new Date(item.china_pending_last_updated) : "";
                        // const counter = {
                        //     '0': 'total_consumption_first',
                        //     '1': 'total_consumption_second',
                        //     '2': 'total_consumption_third',
                        // }
                        // let remainder_stocks = item.stocks_prior_3
                        // for (let c = 0; c <= 2; c++) {
                        //     if (remainder_stocks < 0) {
                        //         e[counter[c]] = 0
                        //     } else if (e[counter[c]] > remainder_stocks) {
                        //         e[counter[c]] = remainder_stocks
                        //     }

                        //     remainder_stocks -= e[counter[c]]
                        // }

                        // if (this.GET_USER_COSTING) {
                        //     item.cost_in_php = parseFloat(item.cost_in_php)
                        //     item.total_po_cost = parseFloat(item.total_po_cost)
                        // }
                        item_details.addRow(item);
                        if(this.exportOptions.with_image == 1){
                            item.base64img              = item_base64_arr.find((e)=> e.model == item.model).base64img;
                            await this.addImageToWorksheet(workbook, item_details, item, item_details_pos);
                        }
                        item_details_pos++
                    }

                    // that.GET_PO_EXPORT_ITEM_DETAILS.data.forEach(async (e, i) => {
                    //     // e.total_average_consumption_6 = parseInt(e.total_average_consumption_6)
                    //     // e.total_average_consumption_3 = parseInt(e.total_average_consumption_3)
                    //     // e.stocks_prior_3 = parseInt(e.stocks_prior_3)
                    //     if(this.has_images == 1){
                    //         e.base64img              = item_base64_arr.find((e)=> e.model == e.model).base64img;
                    //     }
                    //     if (this.has_images == 1) {
                    //         await this.addImageToWorksheet(workbook, item_details, e, item_details_pos);
                    //     }
                    //     e.total_consumption_3 = parseInt(e.total_consumption_3)
                    //     // e.total_consumption_first = parseInt(e.total_consumption_first)
                    //     // e.total_consumption_second = parseInt(e.total_consumption_second)
                    //     // e.total_consumption_third = parseInt(e.total_consumption_third)
                    //     e.total_incoming_stocks = parseInt(e.total_incoming_stocks)
                    //     // e.total_pending_so_6 = parseInt(e.total_pending_so_6)
                    //     e.total_pending_so_3 = parseInt(e.total_pending_so_3)
                    //     // e.total_pending_so = parseInt(e.total_pending_so)
                    //     e.total_pending_sc = parseInt(e.total_pending_sc)
                    //     // e.total_pending_po = parseInt(e.total_pending_po)
                    //     e.quantity = parseInt(e.quantity)
                    //     // e.total_po = parseInt(e.total_po)
                    //     // e.total_po_served = parseInt(e.total_po_served)
                    //     e.moq_po = parseInt(e.moq_po)
                    //     e.china_pending_last_updated = !!e.china_pending_last_updated ? new Date(e.china_pending_last_updated) : "";
                    //     // const counter = {
                    //     //     '0': 'total_consumption_first',
                    //     //     '1': 'total_consumption_second',
                    //     //     '2': 'total_consumption_third',
                    //     // }
                    //     // let remainder_stocks = e.stocks_prior_3
                    //     // for (let c = 0; c <= 2; c++) {
                    //     //     if (remainder_stocks < 0) {
                    //     //         e[counter[c]] = 0
                    //     //     } else if (e[counter[c]] > remainder_stocks) {
                    //     //         e[counter[c]] = remainder_stocks
                    //     //     }

                    //     //     remainder_stocks -= e[counter[c]]
                    //     // }

                    //     // if (this.GET_USER_COSTING) {
                    //     //     e.cost_in_php = parseFloat(e.cost_in_php)
                    //     //     e.total_po_cost = parseFloat(e.total_po_cost)
                    //     // }

                    //     item_details.addRow(e);
                    //     item_details_pos++
                    // })
                }).catch(e => {
                    swal.fire('Error!', '', 'error')
                    console.log(e)
                    that.toggleLoading()
                })
            }

            workbook.xlsx.writeBuffer().then(function (buffer) {
                const blob = new Blob([buffer], { type: "applicationi/xlsx" });
                this.$filesaver.saveAs(blob, `PO ${that.filters.date_from} - ${that.filters.date_to}.xlsx`);
            });
            this.exportOptions.po_details = 0;
            that.toggleLoading();
        },
        manualAddEditedItem() {
            this.editedPurchaseOrder.details.datatable.data.push({
                dataFromExcel: model,
                dataFromExcel2: "",
                error: false,
            });
        },
        addItemDetails(item_id,quantity){
            if(!item_id || !quantity){
                return false
            }

            if(this.editedPurchaseOrder.details.datatable.data.find(e=>e.item_id == item_id)){
                return false;
            }

            this.editedPurchaseOrder.details.datatable.data.unshift({
                auto_updated:0,
                order_quantity:quantity,
                item_id:item_id,
                model:this.GET_ITEM_SELECTION_DEFAULT.find(e=>e.value == item_id).text,
                name:this.GET_ITEM_SELECTION_DEFAULT.find(e=>e.value == item_id).name,
                new_item:true
            })

            if(this.editedPurchaseOrder.details.datatable.pagination.total == 0){
                this.editedPurchaseOrder.details.datatable.pagination.total = this.editedPurchaseOrder.details.datatable.data.length
            }

            this.editedPurchaseOrder.details.additional.order_quantity = ''
            this.editedPurchaseOrder.details.additional.item_id = ''
        },
        updateItemDetails(item,index){
            item.updated = true
            // this.editedPurchaseOrder.details.datatable.data[index].item_id =
        },

        addDetails(){
            let items_to_update = this.editedPurchaseOrder.details.datatable.data.filter(e=>{
                if(e.new_item){
                    e.procurement_purchase_order_id = this.editedPurchaseOrder.id
                    return e
                }
            })

            if(items_to_update.length == 0){
                return false
            }

            this.$store.dispatch('purchaseOrderPost',{
                url:'modify-details',
                items:items_to_update,
                action:'add'
            })
        },

        updateDetails(){
            let items_to_add = this.editedPurchaseOrder.details.datatable.data.filter(e=>{
                if(e.updated){
                    e.procurement_purchase_order_id = this.editedPurchaseOrder.id
                    return e
                }
            })

            if(items_to_add.length == 0){
                return false
            }

            this.$store.dispatch('purchaseOrderPost',{
                url:'modify-details',
                items:items_to_add,
                id:this.editedPurchaseOrder.id,
                action:'edit'
            })
        },
        removeItem(item){
            swal.fire({
                title:'Warning!',
                text:'- are you sure you want to delete this item ?',
                icon: 'question',
                showCancelButton:true,
                reverseButtons:true,
            }).then(action=>{
                if(action.isConfirmed){
                    this.$store.dispatch('purchaseOrderPost',{
                        url:'modify-details',
                        po_detail_id:item.purchase_order_detail_id,
                        id:this.editedPurchaseOrder.id,
                        action:'delete'
                    }).then(response=>{
                        this.getPurchaseOrderDetails()
                    })
                }
            })
        },
        onPaste(evt) {
            evt.preventDefault();

            let pastedText = evt.clipboardData.getData('Text');
            if (!pastedText) {
                return false;
            }

            let rows = [];
            if (pastedText.search(/[\r\n][\n]/g)) {
                rows = pastedText.split(/[\r\n][\n]/g);

                let data = [];
                rows.forEach((row, index) => {
                    if (row.trim() === "") return; // Skip empty rows

                    let cells = row.split("\t");
                    if (index === 0) {
                        this.china_pending.first_model = cells[0];
                    }

                    let e = Object.assign({}, {
                        model: cells[0],
                        quantity: cells[1],
                        color: 'white'
                    });

                    data.push(e);
                });

                this.china_pending.text_areas = data;
                setTimeout(this.fixTextAreaData, 1);
            }
        },
        fixTextAreaData(){
            this.china_pending.text_areas[0].model = this.china_pending.first_model
        },
        clearBatchAdditionData(action){
            this.china_pending = {
                dialog: action == 'close' ? false: true,
                batch_addition:{
                    data:[]
                },
                text_areas:[
                    {
                        model:'',
                        quantity:'',
                        color:'white'
                    }
                ],
                first_model:''
            }
            if(action == 'clear'){
                this.addInitialTextAreas()
            }
        },
        async submittedBtn(){
            this.loading.state = true
            let payload = {
                url:'add-china-pending',
                data:this.china_pending.text_areas,
                models:this.china_pending.text_areas.map(e=>{ return e.model})
            }
            await this.$store.dispatch('purchaseOrderPost',payload).then(response=>{

                this.not_found_items = response.data.model_not_found

                this.china_pending.text_areas.forEach(e=>{
                    e.color = 'white';
                })
                if( this.not_found_items.length > 0){
                    let notFoundModel = this.not_found_items.map(item => `&nbsp;&nbsp;${item.model}`).join('<br>');
                     this.not_found_items.forEach(e=>{
                        this.china_pending.text_areas.forEach(y=>{
                            if(y.model == e ){
                                y.color = 'red';
                            }
                        })
                    })
                    this.list();
                    this.toggleDialog('china_pending');
                    this.clearBatchAdditionData('close')
                    this.loading.state = false
                    swal.fire({
                        title: 'Item/s added Successfully',
                        html: `Except items not found: <br> ${notFoundModel}`,
                        icon: 'success'
                    }).then(()=>{
                        this.exportItemsNotFound();
                    })
                } else {
                    this.toggleDialog(`china_pending`);
                    this.clearBatchAdditionData('close');
                    swal.fire({
                        title: 'Item/s added Successfully',
                        icon: 'success'
                    }).then(() => {
                        this.toggleLoading();
                    });
                }
            })
        },
        async exportItemsNotFound() {
            //Export of not_found_items in upload china pending
            const workbook = new this.$exceljs.Workbook();
            const worksheet = workbook.addWorksheet('Not Found Items');

            worksheet.addRow(['Model', 'Quantity']);

            let arr_of_not_found = Object.values(this.not_found_items);

            arr_of_not_found.forEach(item => {
                worksheet.addRow([item.model, item.quantity = parseInt(item.quantity)]);
            });

            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                this.$filesaver.saveAs(blob, `Not Found Items.xlsx`);
            });
        },

        removeBatchAdditionItem(index){
            if(this.china_pending.text_areas.length != 1){
                this.china_pending.text_areas.splice(index, 1)
            }
        },
        manualAddItem() {
            this.china_pending.text_areas.push({
                model: "",
                quantity: "",
                color: 'white',
            });
        },
        addInitialTextAreas(){
            for(let i = 1;i <= 5;i++){
                this.china_pending.text_areas.push({
                    model: "",
                    quantity: "",
                    color: 'white',
                });
            }
        },
    },
    computed: {
        ...mapGetters([
            'GET_USER_COSTING',
            'GET_ALL_MANAGERS',
            'GET_PO_PURCHASE_ORDER',
            'GET_ITEM_TYPES',
            'GET_ITEM_CATEGORIES',
            'GET_PO_ITEM_SELECTION',
            'GET_PO_DETAILS_ITEM',
            'GET_PO_DETAILS',
            'GET_PO_EXPORT',
            'GET_INCOMING_STOCKS',
            'GET_AVERAGE_CONSUMPTION',
            'GET_PENDING_ORDERS',
            'GET_PO_ITEM_STOCKS',
            'GET_PO_EXPORT_RAW_DATA',
            'GET_PO_EXPORT_ITEM_DETAILS',
            'USER_ACCESS',
            'GET_ALL_ITEM_CATEGORIES',
            'GET_ITEM_SELECTION_DEFAULT'

        ]),
        width () {
            switch (this.$vuetify.breakpoint.width) {
                case 2560:
                    return '19%';
                case 1440:
                    return '31%';
                case 1366:
                    return '34%';
                case 1280:
                    return '37%';
                case 1024:
                    return '46%';
                case 912:
                    return '51%';
                case 820:
                    return '57%';
                case 768:
                    return '61%';
                case 540:
                    return '86%';
                case this.$vuetify.breakpoint.width <= 430:
                    return '100%';
                default:
                    return '25%';
            }
        },
    },
    watch: {
        'GET_ALL_MANAGERS':{
            handler(val){

                this.loading.manager_selection = false
            }
        },
        'GET_ITEM_TYPES':{
            handler(val){

                this.loading.item_type_selection = false
            }
        },
        'GET_ALL_ITEM_CATEGORIES':{
            handler(val){

                this.loading.item_category_selection = false
            }
        },
        'GET_PO_ITEM_SELECTION':{
            handler(val){

                this.loading.po_item_selection = false
            }
        },
        USER_ACCESS: {
            handler(val) {
                this.userAccess.create = false;
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                this.userAccess.approve_exec = false;
                this.userAccess.add_china_pending = false;
                if (val != "fail") {
                    val.forEach((e) => {
                        if (e.actions_code == "create") {
                            this.userAccess.create = true;
                        }
                        if (e.actions_code == "edit") {
                            this.userAccess.edit = true;
                        }
                        if (e.actions_code == "view") {
                            this.userAccess.view = true;
                        }
                        if (e.actions_code == "delete") {
                            this.userAccess.delete = true;
                        }
                        if (e.actions_code == "approve_exec") {
                            this.userAccess.approve_exec = true;
                        }
                        if (e.actions_code == "add_china_pending") {
                            this.userAccess.add_china_pending = true;
                        }
                    });
                }
            }
        },
        'purchaseOrder.datatable.pagination.options': {
            handler() {
                this.list(this.purchaseOrder.datatable.pagination.options.page)
            },
            deep: true,
        },
        'purchaseOrder.withComputations': {
            handler() {
                this.getPurchaseOrderDetails(1, 'computation')
            }
        },
        'purchaseOrder.withOrderQuantity': {
            handler() {
                this.getPurchaseOrderDetails(1, 'orderQuantity')
            }
        },
        'editedPurchaseOrder.details.datatable.pagination.options': {
            handler() {
                if (this.purchaseOrder.withComputations == '1') {
                    this.getPurchaseOrderDetails(this.editedPurchaseOrder.details.datatable.pagination.options.page, 'computation')
                } else {
                    this.getPurchaseOrderDetails(this.editedPurchaseOrder.details.datatable.pagination.options.page)
                }
            },
            deep: true,
        },
        'filters.type_id.selected': {
            handler() {
                this.getPurchaseOrderDetails()
            },
            deep: true,
        },
        'filters.category_id.selected': {
            handler() {
                this.getPurchaseOrderDetails()
            },
            deep: true,
        },
        'filters.item_id.selected': {
            handler() {
                this.getPurchaseOrderDetails()
            },
            deep: true,
        },
        tab(val) {
            this.filters.type = val
            this.list(1)
        }
    }
}
</script>

<style lang="scss"scoped>
    ::v-deep .new-item{
        background-color: #FCFFB2 !important;
    }
    ::v-deep .updated-item{
        background-color: #ADD8E6 !important;
    }
    .remove{
        background-color: '#facdb5';
    }
    .updated{
        background-color: '#f4fab5';
    }
    .add{
        background-color: '#befab5';
    }
    .input-with-border {
        border: 1px solid black;
        padding: 4px;
        border-radius: 5%;
    }
    table {
        border-collapse: separate;
        border-spacing: 0 10px;
    }
</style>
