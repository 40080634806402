<template>
    <v-container fluid>
        <h3 class="page-title">CHART OF ACCOUNTS</h3>
        <v-container class="ma-0">
            <v-app id="item-tabs">
                <v-tabs
                    v-model="tab"
                    id="group-tab"
                    color="cyan"
                    slider-color="cyan"
                    background-color="#424242"
                    dark
                    show-arrows
                >
                    <v-tabs-slider color="cyan"></v-tabs-slider>
                    <v-tab
                        ripple
                        v-for="(item, i) in items"
                        :key="i"
                        @click="tabs(i)"
                    >
                        {{ item.tab_name }}
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item v-for="(item, i) in items" :key="i">
                        <keep-alive>
                            <component
                                :is="chart_of_accounts_items.components"
                                :chart_of_accounts_items="
                                    chart_of_accounts_items
                                "
                                :isActive_tab="item.tab_name"
                            >
                            </component>
                        </keep-alive>
                    </v-tab-item>
                </v-tabs-items>
            </v-app>
        </v-container>
        <DialogComponent
            :cp="components"
            :scrollable="dialog_scrollable"
            :width="width(dialog_width)"
            :retainfocus="false"
        ></DialogComponent>
        <v-dialog v-model="GET_ENTER_BILL_DIALOG" :width="width(dialog_width)" :retain-focus="false" :scrollable="false" class="x-scroll">
                <admin-enter-bill-view-component-vue></admin-enter-bill-view-component-vue>
        </v-dialog>
        <v-dialog v-model="GET_COA_IMPORT_DIALOG" :width="width(dialog_width)" :retain-focus="false" :scrollable="false" persistent>
            <ChartImportExcelViewComponent/>
        </v-dialog>
    </v-container>
</template>

<script>
import DialogComponent from "@/views/main/layouts/dialogs/Dialog.vue";
import ChartOfAccountComponent from "@/views/main/modules/Accounting/ChartOfAccounts/ChartOfAccountComponent.vue";
import ChartOfAccountViewComponent from "@/views/main/layouts/dialogs/Accounting/ChartOfAccountViewComponent.vue";
import ChartImportExcelViewComponent from '@/views/main/layouts/dialogs/Accounting/ChartImportExcelViewComponent.vue';
import { mapGetters } from "vuex";

export default {
    components: {
        DialogComponent,
        ChartOfAccountViewComponent,
        ChartOfAccountComponent,
        ChartImportExcelViewComponent
    },
    data() {
        return {
            form: {
                code: "",
                name: "",
                sub_class: [],
                sub_class_trash: [],
            },
            components: "",
            items: [
                {tab_name:"ACCOUNT",view:true},
                {tab_name:"TYPES",view:true},
                {tab_name:"FUNDAMENTALS",view:true},
                {tab_name:"ACCOUNT GROUP",view:true},
                
            ],
            chart_of_accounts_items: {
                components: ChartOfAccountComponent,
                dialog: ChartOfAccountViewComponent,
                dialog_title:'Account',
                status_text:"ALL",
                dialog_width: "50%",
                scrollable:true,
                dispatch:{
                    get:'getAllChartOfAccounts',
                    add:'CreateChartOfAccounts',
                    update:'UpdateChartOfAccounts',
                    delete:'DeleteChartOfAccounts'
                },
            },
            dialog_width: "",
            loaded: false,
            options: {},
            itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
            itemsPerPage: 10,
            tab: null,
            dialog_scrollable: true,
        };
    },
    computed: {
        ...mapGetters([
            "GET_NEW_ADMIN_OTHER_TABLE",
            "GET_NEW_CHART_OF_ACCOUNTSR_TABLE",
            "GET_CHART_OF_ACCOUNTS_DISPATCH_REFRESH",
            "GET_PRF_SELECTION",
            "GET_ENTER_BILL_DIALOG",
            "GET_CHART_OF_ACCOUNTS_STATUS",
            "GET_COA_IMPORT_DIALOG"
        ]),
    },
    mounted() {
        this.dropDowns();
        this.tabs(0);
    },
    methods: {
        dropDowns() {
            this.$store.dispatch("getAccountTypes");
            this.$store.dispatch("getFundamentals");
            this.$store.dispatch("getAccountGroup");
            this.$store.dispatch("getCurrency");
            this.$store.dispatch("getCOASelection");
            this.$store.dispatch("getEntryTypesSelection");
            this.$store.dispatch("getCashFlowActivities");
            this.$store.dispatch('getPvCompanies');
            this.$store.dispatch('getPaymentVoucherBranchesSelection');
        },
        tabs(key) {
            this.components = this.chart_of_accounts_items.dialog;
            this.dialog_width = this.chart_of_accounts_items.dialog_width;
            this.dialog_scrollable = this.chart_of_accounts_items.scrollable;
            this.$store.commit(
                "CHART_OF_ACCOUNTS_STATUS",
                this.items[key]
            );
            this.$store.commit(
                "CHART_OF_ACCOUNTS_DISPATCH",
                this.chart_of_accounts_items.dispatch
            );
        },
        width(width) {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return "100%"
                case 'sm': return "100%"
                case 'md': return "100%"
                case 'lg': return "100%"
                case 'xl': return width
            }
        },
    },
    watch: {},
};
</script>
<style>
#group-tab {
    padding: 0 !important;
}

.x-scroll {
    overflow-x: hidden;
}
</style>
