<template>
<div class="page-wrapper">

    <div class="ma-0 pa-2">
          <h4 class="page-title">Packing List</h4>
    </div>
    <v-container class="ma-0 pa-4">
        <v-app id="item-tabs" class="ma-0">
            <v-tabs v-model="tab" id="group-tab" color="cyan" dark slider-color="cyan" background-color="#424242" show-arrows v-if="!service">
                <v-tab ripple @click="getTabName('is_pending')">Pending</v-tab>
                <v-tab ripple @click="getTabName('is_for_approval')">For Approval</v-tab>
                <v-tab ripple @click="getTabName('is_for_print')">For Print</v-tab>
                <v-tab ripple @click="getTabName('is_for_confirm')">For Confirm</v-tab>
                <v-tab ripple @click="getTabName('is_canceled')">Cancelled</v-tab>
                <v-tab ripple @click="getTabName('is_all')">All</v-tab>

                <!-- <v-tabs-items v-model="tab" touchless> -->
                    <!-- PENDING TAB -->
                    <!-- <v-tab-item>
                        <packing-lists-component :is_preparation="0" :unconfirmed="0" :is_stockcard="0" :tab_name="'is_pending'"></packing-lists-component>
                    </v-tab-item> -->

                    <!-- FOR PRINTING TAB -->
                    <!-- <v-tab-item>
                        <packing-lists-component :is_preparation="0" :unconfirmed="0" :is_stockcard="0" :tab_name="'is_for_print'"></packing-lists-component>
                    </v-tab-item> -->

                    <!-- FOR CONFIRM TAB -->
                    <!-- <v-tab-item>
                        <packing-lists-component :is_preparation="0" :unconfirmed="0" :is_stockcard="0" :tab_name="'is_for_confirm'"></packing-lists-component>
                    </v-tab-item> -->

                    <!-- CANCELED TAB -->
                    <!-- <v-tab-item>
                        <packing-lists-component :is_preparation="0" :unconfirmed="0" :is_stockcard="0" :tab_name="'is_canceled'"></packing-lists-component>
                    </v-tab-item> -->

                    <!-- ALL PL TAB -->
                    <!-- <v-tab-item>
                        <packing-lists-component :is_preparation="0" :unconfirmed="0" :is_stockcard="0" :tab_name="'is_all'"></packing-lists-component>
                    </v-tab-item> -->
                <!-- </v-tabs-items> -->
            </v-tabs>

            <PackingListsComponentVue :is_preparation="0" :unconfirmed="0" :is_stockcard="0" :tab_name="tabName"></PackingListsComponentVue>
        </v-app>

    </v-container>

</div>
</template>

<script>
    import PackingListsComponentVue from './PackingListsComponent.vue';
    export default {
        data(){
            return {
                tab: null,
                tabName: 'is_pending',
                service:this.$router.currentRoute.meta.service
            }
        },
        components: {
            PackingListsComponentVue,
        },
        methods: {
            getTabName(tabName) {
                this.tabName = tabName;
            }
        },
        mounted(){
            this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
        }
    };
</script>
<style>
    #group-tab{
        padding: 0 !important;
    }
</style>
