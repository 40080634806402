var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('h3',{staticClass:"page-title"},[_vm._v("PURCHASE ORDER")]),_c('v-container',{staticClass:"ma-0"},[_c('v-app',{attrs:{"id":"item-tabs"}},[_c('v-tabs',{attrs:{"id":"group-tab","color":"cyan","slider-color":"cyan","background-color":"#424242","dark":"","show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{attrs:{"color":"cyan"}}),_vm._l((_vm.STATUS.ADMIN_PURCHASE_ORDER_STATUS),function(item,i){return _c('v-tab',{key:i,attrs:{"ripple":""},on:{"click":()=>{
                        _vm.$store.commit('SELECTED_TAB',item.value);
                        _vm.components_index = 0

                    }}},[_vm._v(" "+_vm._s(item.text)+" ")])}),_c('v-spacer'),_c('v-tab',{on:{"click":()=>{
                    _vm.components_index = 3
                }}},[_vm._v(" WFR ")]),_c('v-tab',{on:{"click":()=>{
                    _vm.components_index = 2
                }}},[_vm._v(" Shipping Via ")]),_c('v-tab',{on:{"click":()=>{
                    _vm.components_index = 4
                }}},[_vm._v(" TEMPLATE ")]),_c('v-tab',{on:{"click":()=>{
                    _vm.components_index = 5
                }}},[_vm._v(" SHIPPED TO ")])],2),_c('v-card',[_c('v-card-text',{staticClass:"p-0"},[(_vm.components_index == 0)?_c('PurchaseOrderComponent',{attrs:{"status":_vm.tab}}):_vm._e(),(_vm.components_index == 1)?_c('AdministrativeApproverComponent'):_vm._e(),(_vm.components_index == 2)?_c('ShippingViaComponent'):_vm._e(),(_vm.components_index == 3)?_c('WaitingForReceivingComponent',{attrs:{"module":'Admin Purchase Order'}}):_vm._e(),(_vm.components_index == 4)?_c('PurchaseOrderItemsTemplateComponent'):_vm._e(),(_vm.components_index == 5)?_c('AdminDeliveryAddressesComponent'):_vm._e()],1)],1)],1)],1),_c('DialogComponent',{attrs:{"cp":_vm.components,"scrollable":_vm.dialog_scrollable,"width":_vm.width(_vm.dialog_width),"retainfocus":false}}),_c('v-dialog',{attrs:{"width":_vm.width(_vm.dialog_width),"retain-focus":false,"scrollable":false,"persistent":""},model:{value:(_vm.GET_ENTER_BILL_DIALOG),callback:function ($$v) {_vm.GET_ENTER_BILL_DIALOG=$$v},expression:"GET_ENTER_BILL_DIALOG"}},[_c('admin-enter-bill-view-component-vue')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }