<template>
    <v-container fluid>

        <ComponentHeader
            :button="true"
            :title="'ASSET MANAGEMENT REPORT'"
            :type="'Admin'"
        ></ComponentHeader>


        <v-container fluid class="container bg-bluish-gray default-container">
            <v-row>
                <v-col cols="12" sm="3">
                    <v-autocomplete v-model="classsification" :items="GET_ADMIN_CLASSIFICATION_SELECTION" outlined dense background-color="white" label="Classification" multiple small-chips deletable-chips>

                    </v-autocomplete>

                </v-col>
                <v-col cols="3" sm="3">
                    <v-autocomplete v-model="location" :items="location_list" outlined dense background-color="white" label="Location" multiple small-chips deletable-chips>
                    </v-autocomplete>
                </v-col>
                   <div class="col-sm-12 col-md-6 text-right">
                    <v-btn small class="ml-1" @click="resetFields()" :loading="!loaded">Clear</v-btn>
                    <v-btn small class="ml-1" @click="exportTrucksPerDayReport()" :loading="!loaded">Excel</v-btn>
                    <v-btn small class="ml-1" @click="getAllData()" :loading="!loaded">Filter</v-btn>
                </div>
            </v-row>
            <!-- <div class="row">

                    <div class="col-sm-6 col-md-3" id="date_from">

                    <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="date_from"
                                readonly
                                outlined
                                    dense
                                    background-color="white"
                                    label="Date From"
                                append-icon="mdi-calendar-clock"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="date_from" @input="menu2 = false"></v-date-picker>
                    </v-menu>
                    <span v-for="errors in errors.date_from" class="text-warning" :key="errors">{{errors}}</span>
                </div>

                <div class="col-sm-6 col-md-3" id="date_to">

                    <v-menu
                        v-model="menu3"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="date_to"
                                readonly
                                append-icon="mdi-calendar-clock"
                                outlined
                                    dense
                                    background-color="white"
                                    label="Date To"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="date_to" @input="menu3 = false"></v-date-picker>
                    </v-menu>
                    <span v-for="errors in errors.date_to" class="text-warning" :key="errors">{{errors}}</span>
                </div>

            </div> -->

            <div class="w-100"  >
                <v-card >




                                    <v-container fluid>
                                        <v-data-table
                                            :headers="tableHeaders"
                                            :items="data_handler"
                                            :options.sync="options"
                                            :server-items-length="totalItems"
                                            :items-per-page="10"
                                            :footer-props="{
                                                    itemsPerPageOptions: itemsPerPageOptions,
                                                  }"
                                            class="w-100"
                                            dense
                                        >
                                        <template v-slot:[`item.cost`]="{ item }">
                                            <span>{{ item.cost | currency }}</span>
                                        </template>
                                        <template v-slot:[`item.full_name`]="{ item }">
                                        <span v-if="item.emp_id == 0">GENERAL ACCOUNTABILITY</span>
                                            <span v-else>{{ item.full_name }}</span>
                                        </template>
                                        </v-data-table>
                                    </v-container>



                </v-card>
            </div>
        </v-container>

    </v-container>

</template>

<script>
  import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
  import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
  import { mapGetters } from 'vuex';

  export default {
    mixins: [ SharedFunctionsComponentVue ],
        data(){
            return {
                errors: [],
                data_handler: [],
                specific_day: [],
                list:[],
                loaded: true,
                dispatch_items:[],
                date_from: this.$dayjs().format('YYYY-MM-DD'),
                date_to: this.$dayjs().format('YYYY-MM-DD'),
                table_count:0,
                menu2: false,
                menu3: false,
                total_amount:'',
                vehicle_name:'',
                classsification:[1],
                totalItems: 0,
                options: {},
                items: [],
                itemsPerPageOptions: [5, 10, 15, 50,100],
                itemsPerPage: 10,
                location_list:['headoffice','stores'],
                location:['headoffice'],
                tableHeaders: [
                // { text: 'Picture', align: 'left',value:'image_path' },
                { text: "AS NO.", align: "left", value: "asset_num" },
                { text: "", align: "left", value: "" },
                { text: "Description", align: "left", value: "description" },
                { text: "Brand", align: "left", value: "brand_name" },
                { text: "Particulars", align: "left", value: "particulars" },
                { text: "Serial Number", align: "left", value: "serial" },
                { text: "Classification", align: "center", value: "classification" },
                { text: "Sub-Class", align: "center", value: "sub_classification" },
                { text: "Asset Tagging", align: "center", value: "asset_code" },
                { text: "Location", align: "center", value: "location_name" },
                { text: "Department / Site", align: "left", value: "department_name" },
                { text: "Employee Name", align: "left", value: "full_name" },
                { text: "Supplier", align: "left", value: "vendor_name" },
                { text: "Reference Doc.", align: "left", value: "po_num" },
                { text: "Amount", align: "left", value: "cost" },
                { text: "Status", align: "left", value: "status_name" },

                ],
            }
        },
        computed:{
            ...mapGetters(['GET_ADMIN_CLASSIFICATION_SELECTION'])
        },
        mounted: function (){
            this.$store.dispatch("getAdminClassificationSelection");
        },
        components:{
          ComponentHeader
        },
        methods: {
            getAllData() {
                this.loaded = false

                this.$store.dispatch('reports',{
                        date_from:          this.date_from,
                        date_to:            this.date_to,
                        classfication:      this.classsification,
                        page:               this.options.page,
                        itemsPerPage:       this.options.itemsPerPage,
                        sortBy:             this.options.sortBy,
                        sortDesc:           this.options.sortDesc,
                        accountability:     'assetslist',
                        url:                'getAssetManagement',
                        location:           this.location
                }).then(response => {
                    this.data_handler     = response.data.data.data
                    this.totalItems   = response.data.total
                    this.loaded           = true
                });
            },
            exportTrucksPerDayReport(){

                this.$store.dispatch(`reports`,{
                    date_from : this.date_from,
                    date_to:this.date_to,
                    classfication:      this.classsification,
                    location:           this.location,
                    url:'ExportAssetManagement'
                }).then(async response =>{
                    let data_item = response.data
                    let workbook = new this.$exceljs.Workbook()
                    let worksheet = workbook.addWorksheet('ASSET MASTERFILE')
                    let headerStyle = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000000' },


                 }
                 let bodyStyle = {

                    color: { argb: '000000' },


                 }
                    worksheet.mergeCells('A1:D2');
                    worksheet.getCell('A1').value = 'ACCOUNTABILITY SHEET MASTERLIST';
                    worksheet.getCell('A1').style = {alignment:{vertical:'middle',horizontal:'center'},font:{size:16,bold:true}}
                    worksheet.getRow(3).values = [  'Asset Num',
                            'Description',
                            'Brand',
                            'Particulars',
                            'Serial',
                            'Categories',
                            'Classification',
                            'Sub-Class',
                            'Asset Tagging',
                            'Location',
                            'Department / Site',
                            'Employee Name',
                            'Supplier',
                            'Reference Doc.',
                            'Amount',
                            'Life'];

                    worksheet.columns =[
                            { key: 'asset_num', width: 15, style: {font:{color:{argb:'FFFFFF'}}, alignment: { vertical: 'middle', horizontal: 'center', wrapText: false, backgroundColor:'black'} }, pricelist: true, raw_data: false },
                            { key: 'description', width: 14, style: {font:{color:{argb:'FFFFFF'}}, alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } }, pricelist: true, raw_data: true },
                            { key: 'brand', width: 14, style: {font:{color:{argb:'FFFFFF'}}, alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } }, pricelist: false, raw_data: true },
                            {  width: 14, key: 'particulars', style: {font:{color:{argb:'FFFFFF'}}, alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } }, pricelist: true, raw_data: true },
                            { key: 'serial',width: 14, style: {font:{color:{argb:'FFFFFF'}}, alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } }, pricelist: false, raw_data: true },
                            { key: 'categories', width: 14, style: {font:{color:{argb:'FFFFFF'}}, alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } }, pricelist: false, raw_data: true },
                            {  key: 'classification', width: 14, style: {font:{color:{argb:'FFFFFF'}}, alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } }, pricelist: false, raw_data: true },
                            {  key: 'sub_classification', width: 14,style: {font:{color:{argb:'FFFFFF'}}, alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } }, pricelist: false, raw_data: true },
                            {  key: 'asset_code',width: 14, style: {font:{color:{argb:'FFFFFF'}}, alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } }, pricelist: false, raw_data: true },
                            {key: 'location',width: 14, style: {font:{color:{argb:'FFFFFF'}}, alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } }, pricelist: false, raw_data: true },
                            {  key: 'department',width: 16, style: {font:{color:{argb:'FFFFFF'}}, alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } }, pricelist: false, raw_data: true },
                            { key: 'employee_name',width: 16, style: {font:{color:{argb:'FFFFFF'}}, alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } }, pricelist: false, raw_data: true },
                            { key: 'supplier',width: 14, style: {font:{color:{argb:'FFFFFF'}}, alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } }, pricelist: false, raw_data: true },
                            { key: 'po_num',width: 14, style: {font:{color:{argb:'FFFFFF'}}, alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } }, pricelist: false, raw_data: true },
                            {  key: 'amount',width: 14, style: {font:{color:{argb:'FFFFFF'}}, alignment: { vertical: 'middle', horizontal: 'center', wrapText: false }, numFmt: '#,##0.00' }, pricelist: true, raw_data: true },
                            { key: 'life', width: 10, style: {font:{color:{argb:'FFFFFF'}}, alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } }, pricelist: true, raw_data: false },
                        ]

                        worksheet.getRow(3)._cells.forEach(e=>{
                            worksheet.getCell(e._address).fill = headerStyle
                            worksheet.getCell(e._address).style.font = {color:{argb:'FFFFFF'}}
                            worksheet.getCell(e._address).style.alignment = {vertical: 'middle', horizontal: 'center'}
                        })



                    data_item.forEach((e,i)=> {
                        e.amount = parseInt(e.amount);
                        worksheet.addRow(e)
                        worksheet.getRow(i + 4)._cells.forEach(e=>{
                            worksheet.getCell(e._address).style.font = bodyStyle
                        })
                    });

                    await workbook.xlsx.writeBuffer().then((data) => {
                        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                        this.$filesaver.saveAs(blob, `ASSET-MANAGEMENT.xlsx`);
                    });
                });
            },


            resetFields(){
                this.errors                 = []
                this.date_from              = this.$dayjs().format('YYYY-MM-DD')
                this.date_to                = this.$dayjs().format('YYYY-MM-DD')
            },

            viewDispatch(id){
                axios.post('/viewTrucksPerDay',{
                    id: id,
                }).then(response=>{
                    this.dispatchItems    = response.data.dispatch_details;
                    this.total_amount     = response.data.total_amount;
                    this.vehicle_name     = response.data.vehicle_name;
                    this.dispatch_dialog  = true

                })
            }
        },
        watch:{
        options: {
            handler() {
                this.getAllData();
            },
            deep: true,
        },
        }

 }


</script>
