<template>
    <v-dialog v-model="salesQuotationDetailsDialog" persistent max-width="80%">
        <v-card>
            <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">Spare Parts Quotation</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeSalesQuotationDetailsDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider class="mt-0" />

                <v-divider class="mt-0" />

                <div></div>
        </v-card>
    </v-dialog>
</template>

<script>
export default {

    computed: {
        salesQuotationDetailsDialog() {
            return this.$store.state.service.salesQuotation.detailsDialog;
        }
    },
    methods: {
        closeSalesQuotationDetailsDialog() {
            this.$store.commit('closeSqDialog');
        }
    }
}
</script>

<style>

</style>
