<template>
    <v-container>
        <v-row class="mx-auto p-0 my-1 d-flex flex-row align-items-center">
            <v-col sm="12" md="2" lg="2">
                <v-autocomplete
                    v-model="item_type_ids"
                    :items="item_type_list"
                    :loading="loadview.item_type_selection"
                    :disabled="loadview.item_type_selection"
                    label="Discount Type"
                    class="mx-1"
                    dense
                    outlined
                    hide-details
                    multiple
                    small-chips
                ></v-autocomplete>
            </v-col>
            <v-col sm="12" md="3" class="d-flex flex-column">
                <v-autocomplete
                    v-model="category_ids"
                    :items="category_list"
                    :loading="loadview.item_category_selection"
                    :disabled="loadview.item_category_selection"
                    label="Category Type"
                    class="mx-1"
                    dense
                    outlined
                    hide-details
                    multiple
                    small-chips
                ></v-autocomplete>
            </v-col>
            <v-col sm="12" md="2" lg="2">
                <v-autocomplete
                    v-model="status_id"
                    :items="statusOption"
                    label="Item Status"
                    class="mx-1"
                    dense
                    outlined
                    hide-details
                ></v-autocomplete>
            </v-col>
            <v-col sm="12" md="5" class="text-right" align-self="start">
                <v-btn class="mx-1" @click="getAllItems()">Filter</v-btn>
                <v-btn class="my-2 mx-1" @click="getAllItems(1)" >Export</v-btn>
                <v-btn class="my-2 mx-1" @click="importDialog = true">Import</v-btn>
            </v-col>
        </v-row>
        <v-row class="mx-0 pa-0 my-3 justify-content-end">
            <v-col cols="3" class="my-2 mx-2 text-right">
                <v-text-field
                    id="searchBar"
                    v-model="search"
                    label="Search Model / Name / Tiktok Id"
                    single-line
                    dense
                    hide-details
                    append-icon="mdi-magnify"
                    @keydown.enter="getAllItems()"
                    @click:append="getAllItems()"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-dialog v-model="importDialog" persistent max-width="400">
            <v-card>
                <v-row class="m-0">
                    <v-col cols="10">
                        <v-card-title>
                            <span class="headline">Import Excel File</span>
                        </v-card-title>
                    </v-col>

                    <v-col cols="pull-right-10 p-0">
                        <v-btn text icon color="gray" class="float-right" @click="closeImportDialog()" :loading="excelLoading">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider class="mt-0" />

                <v-card-text>
                    <v-file-input
                        :loading="excelLoading"
                        :disabled="excelLoading"
                        show-size
                        v-model="excelFile"
                        truncate-length="50"
                        placeholder="Choose excel file"
                        prepend-icon="mdi-file-excel"
                    ></v-file-input>
                    <br>
                    <span v-if="excelLoading">{{ `Batch ${batch.current} of ${batch.total}` }}</span>
                </v-card-text>

                <v-divider></v-divider>

                <div style="text-align:center" class="pb-4">
                    <v-btn :loading="excelLoading" @click="importExcel()">Import</v-btn>
                </div>

            </v-card>
        </v-dialog>
        <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="10"
            :loading="loading"
            :disable-sort="true"
        >
            <template v-slot:[`item.image_path`]="{ item }">
                <v-img class="img-thumbnail" width="60" height="60" :src="item.image_path == null ? no_image : env+item.image_path" eager contain></v-img>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import swal from 'sweetalert2'
import * as XLSX from 'xlsx'
import { mapGetters } from 'vuex';
export default {
    data(){
        return {
            importDialog:false,
            excelLoading:false,
            excelFile:null,
            batch: {
                current: 0,
                total: 0,
            },
            loadview:{
                item_type_selection     : true,
                item_category_selection : true,
                currency_selection      :true
            },
            itemsPerPageOptions: [5, 10 ,15, 20],
            itemsPerPage: 10,
            loading:false,
            search:null,
            items:[],
            headers: [
                { text: 'Image', width: '5%', value: 'image_path' },
                { text: 'Model', value: 'model' },
                { text: 'Name', value: 'name' },
                { text: 'Type',  align: 'center', value: 'type' },
                { text: 'Category',  value: 'category' },
                { text: 'UOM', align: 'center', value: 'uom' },
                { text: 'Tiktok Id', align: 'center', value: 'tiktok_item_id' },
            ],
            env: process.env.VUE_APP_API_HOST + '/',
            category_ids:[],
            category_list:[],
            item_type_ids:[],
            item_type_list:[],
            status_id: -1,
            statusOption:[
                { text: 'All', value: -1 },
                { text: 'Active', value: 1 },
                { text: 'Inactive', value: 0 },
            ],
        }
    },
    methods:{
        async setDefaultValues(){
            this.item_type_ids = await this.item_type_list.filter(f => {
                return [1, 2].includes(f.value)
            }).map(m => m.value)
        },
        closeImportDialog(){
            this.importDialog = false
        },
        async importExcel() {
            let warnings = []
            let excel = this.excelFile

            if(!excel) {
                warnings.push("File is required.");
            } else {
                if (_.indexOf(['xls', 'xlsx'], excel.name.split('.').pop().toLowerCase()) === -1) {
                    warnings.push("File must be type of excel spreadsheet.");
                }
            }

            if (warnings.length) {
                this.alertWarnings('Warning', {
                    'validation warning' : warnings
                });
                return false;
            }

            new Promise((resolve) => {
                const reader = new FileReader()
                reader.onload = (e) => {
                    let data = new Uint8Array(e.target.result)
                    let workbook = XLSX.read(data)
                    let sheet = workbook.Sheets[workbook.SheetNames[0]]
                    let result = XLSX.utils.sheet_to_json(sheet, { header: 1 })
                    result.shift()
                    resolve(result)
                }
                reader.readAsArrayBuffer(excel)
            }).then(async r => {
                this.excelLoading = true

                let alert = {
                    title: '',
                    text: '',
                    icon: 'success',
                    status: 200,
                }

                const chunk_size = 100
                this.batch.total = Math.ceil(r.length / chunk_size)

                for (let i = 0; i < r.length; i += chunk_size) {
                    this.batch.current += 1
                    const chunk = r.slice(i, i + chunk_size)
                    let payload = {
                        data: chunk,
                        url:'importTiktokId'
                    }

                    await this.$store.dispatch('postWebScraping', payload).then(response => {
                        if (response.data.status == 200) {
                            alert.title = response.data.message
                        } else {
                            alert.title = response.data.message
                            alert.text = response.data.error
                            alert.icon = 'danger'
                        }
                    }).catch(function(error){
                        this.alertWarnings(error.response.data.message, error.response.data.errors);
                    });
                }

                swal.fire(alert);

                this.getAllItems()
                this.excelLoading = false
                this.excelFile = null
                this.importDialog = false
            })
        },
        async getAllItems(for_export = 0){
            this.loading = true

            let payload = {
                search            : this.search,
                item_type_id      : this.item_type_ids,
                item_category_id  : this.category_ids,
                export            : for_export,
                status_id         : this.status_id,
                url               : 'getAllOnlineItems'
            }

            await this.$store.dispatch('getWebScraping', payload).then(response => {
                if (for_export == 0) {
                    this.items = response.data
                    this.loading = false
                } else {
                    const workbook = new this.$exceljs.Workbook();
                    const worksheet = workbook.addWorksheet('Raw Data')
                    worksheet.columns = [
                        { header: 'ID', key: 'id' },
                        { header: 'MODEL', key: 'model' },
                        { header: 'NAME', key: 'name' },
                        { header: 'TYPE', key: 'type' },
                        { header: 'UOM', key: 'uom' },
                        { header: 'CATEGORY', key: 'category' },
                        { header: 'TIKTOK ID', key: 'tiktok_item_id' },
                    ]

                    this.items.forEach((e,i) => {
                        worksheet.addRow(e)

                    });

                    worksheet.getRow(`0${1}`).font = { bold: true };
                    workbook.xlsx.writeBuffer().then((data) => {
                    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                    this.$filesaver.saveAs(blob, `ItemsToImport.xlsx`);
                });
                }
            }).catch(e => {
                swal.fire('Error !', '', 'error')
                console.log(e)
            })

            this.loading = false
        },
        async getItemTypes(){
            await this.$store.dispatch('getItemTypes').then(response => {
                this.item_type_list = this.GET_ITEM_TYPES
                this.item_type_list.pop();
                this.setDefaultValues()
            });
        },
        async getItemCategories(){
            await this.$store.dispatch('getAllItemCategories').then(response => {
                this.category_list = this.GET_ALL_ITEM_CATEGORIES.map(e=>{return {text:e.name,value:e.id}})
            });
        },
    },
    async mounted(){
        await this.getItemCategories()
        await this.getItemTypes()
        await this.setDefaultValues()
        this.getAllItems();
    },
    watch:{
        'GET_ITEM_TYPES':{
            handler(val){
                this.loadview.item_type_selection = false
            }
        },
        'GET_ALL_ITEM_CATEGORIES':{
            handler(val){
                this.loadview.item_category_selection = false
            }
        },
    },
    computed:{
        ...mapGetters([
            'GET_ALL_ITEM_CATEGORIES',
            'GET_ITEM_TYPES'
        ])
    }
}
</script>

<style>

</style>