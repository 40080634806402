<template>
    <v-card class="d-flex justify-content-center flex-column">
        <v-card-title outline class="d-flex justify-content-between align-items-center">
            <span>Shipping Via</span>
            <v-btn text icon small color="gray" class="float-right" @click="() => {
               closeDialog()
            }">
            <v-icon>mdi-close-circle</v-icon>
            </v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pt-6">
            <v-row class="p-2">
                <v-col cols="9">
                    <span>CODE: </span>
                    <v-text-field
                    v-model="form.code"
                    placeholder="Enter Code"
                    :disabled="ACTION == 'View'"
                    outlined
                    dense
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row class="p-2">
                <v-col cols="9">
                    <span>NAME: </span>
                    <v-text-field
                    v-model="form.name"
                    placeholder="Enter Name"
                    :disabled="ACTION == 'View'"
                    outlined
                    dense
                    >
                    </v-text-field>
                </v-col>
            </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-content-center p-3">
            <v-btn @click="createOrUpdateShippingVia()" v-if="ACTION !== 'View'">
                <span >{{ACTION == 'Add' ? 'Submit' : 'Update'}}</span>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import swal from "sweetalert2";
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
export default {
    mixins: [
            SharedFunctionsComponentVue
        ],
    data(){
        return {
            form: {
                id: '',
                name: '',
                code: '',
            },
            loading: true,
        }
    },
    computed: {
        ...mapGetters([
            "ACTION",
            "SHIPPING_VIA_DIALOG",
            "SELECTED",
        ]),
    },
    mounted() {
        document.addEventListener('keydown', this.handleKeydown);
    },
    beforeDestroy(){
        document.removeEventListener('keydown', this.handleKeydown);
    },
    methods: {
        createOrUpdateShippingVia(){
            swal.fire({
                title:'Warning',
                text: this.ACTION === 'View' ? 'Are you sure you want to Update?': 'Are you sure you want to '+this.ACTION+'?',
                showCancelButton:true,
                reverseButtons:true,
                icon:'warning'
            }).then(action=>{
                if(action.isConfirmed){
                    let checker = [
                        [!this.form.name,'- Please enter Name'],
                        [!this.form.code,'- Please enter Code']
                    ]
                    if(checker.find(e=>e[0] == true)){
                        swal.fire('Error!',`- ${checker.find(e=>e[0] == true)[1]}`,'error')
                        return
                    }
                    this.$store.dispatch('shippingViaPost',{
                        url:!this.form.id ? 'add-shipping-via' : 'edit-shipping-via/'+ this.form.id,
                        ...this.form,
                    }).then(response=>{
                        swal.fire('Success!','','success')
                        this.closeDialog();
                        this.$store.dispatch('ACTION','DONE')
                    }).catch(e=>{
                        console.log(e)
                        swal.fire('Error','','error')
                    });
                }
            })
        },
        resetFields(){
            this.form = {
                id: '',
                name: '',
                code: '', 
            },
            this.loading = true
        },
        closeDialog(){
            this.resetFields();
            this.$store.commit('SHIPPING_VIA_DIALOG', false);
        },
        handleKeydown(event){
            this.closeDialogByEsc(event, this.closeDialog)
        }
    },   
    watch: {
        SELECTED:{
            async handler(val){
                this.loading = true
                if(!!val){
                    await this.$store.dispatch('shippingViaGet',{
                        url:'show-shipping-via/'+val.id,
                        id: val.id
                    }).then(response=>{
                        if(!this.SHIPPING_VIA_DIALOG) return
                        this.form = response[0]
                    }).catch(e=>{
                        console.log(e)
                    })
                }
                setTimeout(()=>{
                    this.loading = false
                },1000)

                this.$forceUpdate();
            },
            deep:true,
            immediate:true
        },
    }
}
</script>
<style scoped>
::v-deep .right-align-text input {
    text-align: right;
}
</style>