<template>
    <v-container class="tabs-body-color ma-0 pa-0">
        <v-btn @click="showAddEditDialog('')" class="mt-2" v-if="userAccess.create">
            <v-icon>
                mdi-plus
            </v-icon>
            Add
        </v-btn>
        <MemoViewComponentVue :view_memo_details="viewMemoDetails" :memo_type="memoType"></MemoViewComponentVue>
        <v-dialog v-model="addEditDialog" max-width="65%" persistent>
            <v-card>
                <v-card-title>
                    <v-row>
                        <v-col cols="8">
                            Change Credit Memo Date
                        </v-col>
                        <v-col cols="pull-right 4">
                            <v-btn text icon color="gray" class="float-right" @click="closeDialog()">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-divider class="px-0 my-0"></v-divider>
                <v-card-text>
                    <v-row class="my-2">
                        <v-col cols="6">
                            <v-row class="mt-5 mx-auto">
                                <v-col cols='12'>
                                    <v-autocomplete
                                        :readonly="isReadOnly"
                                        auto-select-first
                                        outlined
                                        dense
                                        :items="GET_CUSTOMER_WITH_CREDIT_MEMO_SELECTION" v-model="credit_memo.customer_id"
                                        label="Customer"
                                        @change="onSelectCustomer()">

                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="11">
                                    <v-autocomplete
                                        :readonly="isReadOnly"
                                        auto-select-first
                                        outlined
                                        dense
                                        :items="GET_CREDIT_MEMO_SELECTION" v-model="credit_memo.credit_memo_id"
                                        item-value="value" item-text="text" label="Credit Memo #"
                                        @change="onSelectCM()">
                                        <template #append>
                                            <span style="color:red">*</span>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="1" class="d-flex justify-content-center align-item-center">
                                    <v-icon text icon small color="orange" @click="viewMemo()" :disabled="!(!!credit_memo.credit_memo_id)">
                                        mdi-eye
                                    </v-icon>
                                </v-col>
                                <v-col cols="12">
                                    <v-menu
                                        :close-on-content-click="false" :nudge-right="40"
                                        v-model="date_picker.menu" transition="scale-transition"
                                        :readonly="isReadOnly"
                                        min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field outlined dense class=""
                                                label="New SOA Month" type="text"
                                                placeholder="Select Year-Month"
                                                v-model="credit_memo.soa_month_year" :readonly="isReadOnly"
                                                v-on="on">
                                                <template #append>
                                                    <span style="color:red">*</span>
                                                </template>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker
                                            :readonly="isReadOnly"
                                            type="month"
                                            v-model="credit_memo.soa_month_year"
                                            @change="formatDate('soa_month_year',credit_memo.soa_month_year)"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                        :readonly="isReadOnly"
                                        v-model="credit_memo.reason"
                                        outlined
                                        dense
                                        rows="2"
                                        label="Reason"
                                    >
                                        <template #append>
                                            <span style="color:red">*</span>
                                        </template>
                                    </v-textarea>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="6" >
                            <v-row class="mt-5 mx-auto">
                                <v-col>
                                    <v-card class="rounded-lg" style="height: 207px;">
                                        <v-card-title class="my-0 pa-0 px-2">
                                            Credit Memo Details
                                        </v-card-title>
                                        <v-card-text>
                                            CM#: <b>{{ selected_cm[0].text }} | {{ selected_cm[0].credit_memo_type_by_name }}</b><br/>
                                            CM Date: <b>{{ selected_cm[0].credit_memo_date }}</b><br/>
                                            SOA Month <b>{{ selected_cm[0].soa_month_year }}</b><br/>
                                            SOA ID: <b>{{ selected_cm[0].soa_id }}</b><br/>
                                            Remarks: <b>{{ selected_cm[0].remarks }}</b><br/>
                                            Total: <b>&#x20b1; {{ selected_cm[0].total_amount | currency }}</b><br/>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                </v-card-text>
                <v-card-actions class="border-top">
                    <div class="card-body py-4 d-flex justify-content-center">
                        <v-btn @click="addCreditMemoHistory()" v-if="action=='add'">Submit</v-btn>
                        <v-btn @click="updateCreditMemoHistory()" v-else-if="action == 'edit' && isApprove == null">Update</v-btn>
                        <v-btn @click="approveCreditMemoHistory()" v-else-if="action == 'view' && isApprove == null && userAccess.approve">Approve CM</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <CreditMemoHistoryTableComponentVue :status="status" @showAddEditDialog="showItems" ref="cmHistoryTable"></CreditMemoHistoryTableComponentVue>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import CreditMemoHistoryTableComponentVue from './CreditMemoHistoryTableComponent.vue'
import swal from 'sweetalert2'
import MemoViewComponentVue from '../Base/MemoViewComponent.vue';
export default {
    props:['status'],
    data(){
        return{
            addEditDialog:false,
            customer:{
                value:0,
            },
            action:'',
            date_picker:{
                menu:false,
            },
            credit_memo:{
                selected_month:'',
                selected_year:'',
                customer_id:0,
                credit_memo_id:0,
                soa_id:null,
                reason:'',
            },
            selected_cm:[
                {
                    text:'',
                    credit_memo_date:'',
                    soa_month_year:'',
                    soa_id:null,
                    remarks:null,
                    soa_month_year:'',
                    total_amount:0,
                    credit_memo_type_by_name:'',
                }
            ],
            isReadOnly:false,
            isApprove:null,
            userAccess:{
                create:false,
                approve:false
            },
            viewMemoDetails:[],
            memoType:'',
            viewMemoItems:[],
        }
    },
    mounted(){

    },
    components:{
        CreditMemoHistoryTableComponentVue,
        MemoViewComponentVue
    },
    methods:{
        showAddEditDialog(item){
            this.addEditDialog = true
            this.action = 'add'
            this.isReadOnly = false
            this.$store.dispatch('getAllCreditMemoSelection',{url:'getAllCreditMemoSelection'})
            if(!!item){
                this.credit_memo = item
            }
            else{
                this.clearFields();
            }

        },
        closeDialog(){
            this.addEditDialog = false
            this.clearFields();
        },
        formatDate(date_field, date_string) {
            if (date_field == "soa_month_year") {
                this.date_picker.soa_month_year =
                    this.$dayjs(date_string).format("YYYY-MM");
                this.credit_memo.selected_month = this.$dayjs(date_string).format("M");
                this.credit_memo.selected_year = this.$dayjs(date_string).format("YYYY");
                this.date_picker.menu = false;
            }
        },
        onSelectCustomer(){
            this.$store.dispatch('getAllCreditMemoSelection',{url:'getAllCreditMemoSelection',customer_id:this.credit_memo.customer_id})
        },
        onSelectCM(){
            this.selected_cm = this.GET_CREDIT_MEMO_SELECTION.filter(e=>{
                if(e.value == this.credit_memo.credit_memo_id){
                    return e
                }
            })
            this.credit_memo.soa_id = this.selected_cm[0].soa_id
        },
        addCreditMemoHistory(){
            this.$store.dispatch('postCreditMemoHistory',{
                credit_memo:this.credit_memo,
                url:'check-credit-memo-history',
            }).then(response=>{
                let checkStatus = response.data

                if(checkStatus == 'paid'){
                    swal.fire({
                        title: "Existing SOA with Paid status Detected!!!",
                        text: "Are you sure you want to proceed? This will recompute tagged SOA ID upon approval",
                        icon: "warning",
                        showConfirmButton: true,
                        showCancelButton: true,
                        reverseButtons: true,
                        allowOutsideClick: false,
                        confirmButtonColor: "#397373",
                        cancelButtonColor: "grey",
                        confirmButtonText: "Confirm",
                        showCloseButton: true,
                    })
                    .then(async (result) => {
                        if (result.isConfirmed) {
                            this.saveCreditMemoHistory()
                        }
                    });
                }
                else{
                    this.saveCreditMemoHistory()
                }
            })
        },
        saveCreditMemoHistory(){
            this.$store.dispatch('postCreditMemoHistory',{
                credit_memo:this.credit_memo,
                url:'save-credit-memo-history',
            }).then(response=>{
                swal.fire('',response.data.msg,response.data.icon)
                if(response.data.icon == 'success'){
                    this.closeDialog();
                    this.$refs.cmHistoryTable.getAll();
                }
            })
        },
        clearFields(){
            this.credit_memo = {
                selected_month:'',
                selected_year:'',
                customer_id:0,
                credit_memo_id:0,
                soa_month_year:'',
                reason:'',
            },
            this.selected_cm = [
                {
                    text:'',
                    credit_memo_date:'',
                    soa_month_year:'',
                    soa_id:null,
                    remarks:null,
                    total_amount:0,
                }
            ]
        },
        showItems(item,action){
            this.isApprove = item.approved_by
            this.showAddEditDialog();
            this.credit_memo = item;
            this.selected_cm = [
                {
                    text:item.credit_memo_num,
                    credit_memo_date:item.credit_memo_date,
                    soa_month_year:item.old_soa_month_year,
                    soa_id:item.soa_id,
                    remarks:item.remarks,
                    total_amount:item.total_amount,
                    credit_memo_type_by_name:item.credit_memo_type_by_name,
                }
            ]
            this.action = action
            if(this.action == 'view'){
                this.isReadOnly = true
            }
        },
        approveCreditMemoHistory(){
            this.$store.dispatch('postCreditMemoHistory',{
                credit_memo:this.credit_memo,
                url:'approve-credit-memo-history',
            }).then(response=>{
                swal.fire('',response.data.msg,response.data.icon)
                if(response.data.icon == 'success'){
                    this.closeDialog();
                    this.$refs.cmHistoryTable.getAll();
                }
            })
        },
        updateCreditMemoHistory(){
            this.$store.dispatch('postCreditMemoHistory',{
                credit_memo:this.credit_memo,
                url:'update-credit-memo-history',
            }).then(response=>{
                swal.fire('',response.data.msg,response.data.icon)
                if(response.data.icon == 'success'){
                    this.closeDialog();
                    this.$refs.cmHistoryTable.getAll();
                }
            })
        },
        checkAccess() {
            let val = this.USER_ACCESS;
            if (val != "fail") {
                val.forEach((e) => {
                    if (e.actions_code == "create") {
                        this.userAccess.create = true;
                    }
                    if (e.actions_code == "approve") {
                        this.userAccess.approve = true;
                    }
                });
            }
        },
        viewMemo(){
            this.$store.dispatch("showCM", this.credit_memo.credit_memo_id).then(response=>{
                this.memoType = 'Credit'
                this.viewMemoDetails = response.data.credit_memo
                this.viewMemoDetails = Object.assign(this.viewMemoDetails,{
                    credit_memo_items:response.data.credit_memo_items
                })
            })
        }
    },
    computed:{
        ...mapGetters([
            'GET_CUSTOMER_WITH_CREDIT_MEMO_SELECTION',
            'GET_CREDIT_MEMO_SELECTION',
            'USER_ACCESS'
        ])
    },
    watch:{
        USER_ACCESS: function () {
            this.checkAccess();
        },
    }

}
</script>

<style>

</style>