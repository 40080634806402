<template>
<v-form ref="form" v-model="valid">
    <v-card class="pa-2">
            <v-row class="m-0">
                <v-col cols="10">
                    <v-card-title>
                        <span>Return Form Details </span>
                    </v-card-title>
                </v-col>
                <v-col cols="2">
                    <v-btn text icon color="gray" class="float-right" @click="closeReturnFormDialog(); resetFields();">
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-container class="overflow-auto" fluid>
                <v-row class="m-0">
                    <v-col cols="2" lg="6" class="ma-0 pa-0 pb-1 d-flex flex-row mb-1 ml-1">
                        <FileViewer class="mx-1 mt-1" :delete_url="'deleteReturnFormFiles'" :url="file_viewer_url_details" :action="ACTION" @removeAttachment="removeAttachment" :folder_name="'return_form_uploads'" ></FileViewer>
                        <FileUploadComponent v-if="actions == 'Create' || actions == 'Update'" id="attachment" ref="fileUpload" @reset="clearFileUpload" @uploadedData="uploadedData" :action="actions" :parent_id="form.id" ></FileUploadComponent>
                    </v-col>
                    <v-spacer/>
                    <v-col cols="4" class="d-flex justify-content-end pa-2" v-if="actions != 'Create'">
                        <!-- <v-btn :disabled="form.status != 0 || form.status != 1 ? false : true" class="mr-2">
                            <span class="hidden-sm-and-down">Print QR</span>
                            <v-icon right color="white">
                                mdi-qrcode
                            </v-icon>
                        </v-btn> -->
                        <v-btn
                            :disabled="form.status == 2 || form.status == 3 ? false : true"
                            @click="showOverride('print')"
                            class="mr-2"
                        >
                            <span class="hidden-sm-and-down">Print PDF</span>
                            <v-icon right color="white">
                                mdi-file-pdf-box
                            </v-icon>
                        </v-btn>

                        <v-btn
                            :disabled="form.status == 2 || form.status == 3 ? false : true"
                            @click="showOverride('download')"
                        >
                            <span class="hidden-sm-and-down">Download PDF</span>
                            <v-icon right color="white">
                                mdi-file-download
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="m-0">
                    <v-col cols="12">
                        <v-row class="d-flex justify-content-start">
                            <v-col cols="12" sm="3" md="3" v-if="actions == 'Create' || actions == 'Update'">
                                <v-autocomplete
                                    v-model="form.sample_form_id"
                                    @input="setSampleFormItems()"
                                    @click-clear="resetSalesmanSelection()"
                                    :items="dropdowns.sample_form_selection"
                                    :rules="rules.required"
                                    :disabled="actions == 'Approve' || ( actions == 'Update' && form.status != 0 ) || loading.sample_form_selection ? true : false"
                                    :loading="loading.sample_form_selection"
                                    :readonly="actions == 'Create' ? false : true "
                                    :clearable="actions == 'Create' ? true : false "
                                    label="Select Sample Form #"
                                    item-text="sample_form_num"
                                    item-value="id"
                                    auto-select-first
                                    hide-details
                                    outlined
                                    dense
                                >
                                </v-autocomplete>

                                <v-autocomplete
                                    v-model="form.salesman_id"
                                    @input="filterSampleFormNumber()"
                                    @click-clear="resetSampleFormSelection()"
                                    :items="dropdowns.salesman_selection"
                                    :rules="rules.required"
                                    :disabled="actions == 'Approve' || ( actions == 'Update' && form.status != 0 ) || loading.salesman_selection  ? true : false"
                                    :loading="loading.salesman_selection"
                                    :readonly="actions == 'Create' ? false : true "
                                    :clearable="actions == 'Create' ? true : false "
                                    label="Select Salesman"
                                    class="mt-2"
                                    item-text="name"
                                    item-value="id"
                                    auto-select-first
                                    outlined
                                    dense
                                    hide-details
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="3" md="3" v-else>
                                <v-text-field
                                    v-model="form.sample_form_num"
                                    color="black"
                                    hide-details
                                    outlined
                                    disabled
                                    dense
                                ></v-text-field>
                                <v-text-field
                                    v-model="form.salesman_name"
                                    color="black"
                                    class="mt-3"
                                    hide-details
                                    outlined
                                    disabled
                                    dense
                                ></v-text-field>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="3" sm="6" md="6">
                                <v-text-field
                                    v-model="form.remarks"
                                    :disabled="actions == 'Approve' || actions == 'Confirm' ? true : false"
                                    label="Remarks"
                                    outlined rows="3"
                                    auto-select-first
                                    dense
                                >
                                </v-text-field>
                                <v-text-field
                                    v-if="form.status == 1"
                                    v-model="form.cancel_reason"
                                    :disabled="actions == 'Approve' || actions == 'Confirm'? true : false"
                                    class="mt-3"
                                    label="Cancellation Reason"
                                    outlined rows="3"
                                    auto-select-first
                                    dense
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="mx-3">
                        <v-simple-table dense fixed-header class="dynamic_list_table mt-5 overflow-auto" style="max-height:400px;">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-center mx-2" width="22%">Model</th>
                                        <th class="text-center mx-2" width="40%">Name</th>
                                        <th class="text-center mx-2" width="30%">Category</th>
                                        <th class="text-center mx-2" width="6%">UOM</th>
                                        <th class="text-center mx-2" width="6%">Type</th>
                                        <!-- <th class="text-center mx-2" width="6%">QR</th> -->
                                        <th class="text-center mx-2" width="6%">Remaining Qty</th>
                                        <th class="text-center mx-2" width="6%">Borrowed Qty</th>
                                        <th class="text-center mx-2" width="6%">Return Qty</th>
                                        <th class="text-center mx-2" width="3%"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <span v-for="(returnFormItem, i) in  form.returnFormItems" :key="i" class="d-contents">
                                        <tr>
                                            <td>
                                                <v-autocomplete
                                                    v-if="actions == 'Create' || actions =='Update' "
                                                    v-model="returnFormItem.item_id"
                                                    :items="dropdowns.inventory_items"
                                                    :rules="rules.required"
                                                    @change="validateManualAddItem(i)"
                                                    item-text="text"
                                                    item-value="value"
                                                    class="small mt-2"
                                                    placeholder="Select Model"
                                                    item-disabled="isDisabled"
                                                    auto-select-first
                                                    hide-selected
                                                    hide-details
                                                    readonly
                                                    outlined
                                                    dense
                                                >
                                                </v-autocomplete>
                                                <v-text-field
                                                    v-else
                                                    v-model="returnFormItem.item_model"
                                                    :readonly="['Update','Create','Approve','View'].includes(actions) || ( actions == 'Update' && form.status != 0 ) ? true : false "
                                                    background-color="grey lighten-2"
                                                    color="black"
                                                    hide-details
                                                    outlined
                                                    dense
                                                ></v-text-field>
                                            </td>
                                            <td>
                                                <v-text-field
                                                    v-model="returnFormItem.item_name"
                                                    :readonly="['Update','Create','Approve','View'].includes(actions) || ( actions == 'Update' && form.status != 0 ) ? true : false "
                                                    background-color="grey lighten-2"
                                                    color="black"
                                                    hide-details
                                                    outlined
                                                    dense
                                                ></v-text-field>
                                            </td>
                                            <td>
                                                <v-text-field
                                                    v-model="returnFormItem.item_categories"
                                                    :readonly="['Update','Create','Approve','View'].includes(actions) || ( actions == 'Update' && form.status != 0 ) ? true : false "
                                                    background-color="grey lighten-2"
                                                    color="black"
                                                    hide-details
                                                    dense
                                                    outlined
                                                ></v-text-field>
                                            </td>
                                            <td>
                                                <v-text-field
                                                    v-model="returnFormItem.item_uom"
                                                    :readonly="['Update','Create','Approve','View'].includes(actions) || ( actions == 'Update' && form.status != 0 ) ? true : false "
                                                    background-color="grey lighten-2"
                                                    color="black"
                                                    class="centered-input"
                                                    dense
                                                    hide-details
                                                    outlined
                                                ></v-text-field>
                                            </td>
                                            <td>
                                                <v-text-field
                                                    v-model="returnFormItem.item_types"
                                                    :readonly="['Update','Create','Approve','View'].includes(actions) || ( actions == 'Update' && form.status != 0 ) ? true : false "
                                                    background-color="grey lighten-2"
                                                    color="black"
                                                    class="centered-input"
                                                    dense
                                                    hide-details
                                                    outlined
                                                ></v-text-field>
                                            </td>
                                            <!-- <td  style="text-align: center; vertical-align: middle;">
                                                <v-img
                                                    :src="returnFormItem.qr_code_base64"
                                                    style="margin-top:7px;"
                                                    class="img-thumbnail"
                                                    width="41"
                                                    height="41"
                                                    contain
                                                >
                                                </v-img>
                                            </td> -->
                                             <td>
                                                <v-text-field
                                                    v-model="returnFormItem.remaining_qty"
                                                    :readonly="['Update','Create','Approve','View'].includes(actions) || ( actions == 'Update' && form.status != 0 ) ? true : false "
                                                    class="alignText centered-input"
                                                    background-color="grey lighten-2"
                                                    outlined
                                                    dense
                                                    hide-details
                                                ></v-text-field>
                                            </td>
                                            <td>
                                                <v-text-field
                                                    v-model="returnFormItem.borrowed_qty"
                                                    :readonly="['Update','Create','Approve','View'].includes(actions) || ( actions == 'Update' && form.status != 0 ) ? true : false "
                                                    class="alignText centered-input"
                                                    background-color="grey lighten-2"
                                                    color="black"
                                                    outlined
                                                    dense
                                                    hide-details
                                                ></v-text-field>
                                            </td>
                                            <td>
                                                <v-text-field
                                                    v-model="returnFormItem.return_qty"
                                                    :readonly="['Approve','View'].includes(actions) || ( actions == 'Update' && form.status != 0 ) ? true : false "
                                                    :rules="rules.required"
                                                    min="0"
                                                    type="number"
                                                    class="alignText centered-input"
                                                    outlined
                                                    dense
                                                    hide-details
                                                    @blur="validateReturnQty(i,returnFormItem)"
                                                ></v-text-field>
                                            </td>
                                            <td class="text-center">
                                                <v-btn
                                                    @click="removeAdjustmentItemByIndex(i,returnFormItem.id)"
                                                    :disabled="actions == 'Approve' ? true : false"
                                                    style="height:20px; width:20px;"
                                                    color="primary"
                                                    fab
                                                    dark
                                                    small
                                                >
                                                    <v-icon dark small style="font-size:12px">mdi-minus</v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </span>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-container>
            <v-card-actions class="text-center" style="border-top:1px solid #dee2e6; padding-top: 10px;" >
                <v-row class="ma-1 pb-2" v-if="form.status == 0 || actions == 'Create'">
                    <v-col cols="4" class="d-flex justify-center" v-if="actions == 'Approve' ">
                        <v-btn
                            @click="openCancelReasonDialog()"
                            color="red"
                        >
                        Cancel
                        </v-btn>
                        <!-- <v-btn
                            @click="changeReturnFormStatus(1)"
                            color="red"
                        >
                        Cancel
                        </v-btn> -->
                    </v-col>
                    <v-spacer v-if="actions == 'Approve' || actions == 'Update' ">
                    </v-spacer>
                    <v-col :cols="actions == 'Approve' ? 4 : 12" class="d-flex justify-center">
                        <v-btn
                            @click="actions == 'Create' || actions == 'Update'  ? submitReturnForm() : changeReturnFormStatus()"
                            :disabled="(actions =='Approve' && form.approvable == false) || ((actions == 'Create' || actions == 'Update')&& stock_validate == false) ? true : false"
                            :loading="loading.button"
                        >
                            {{actions}}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
            <v-container fluid style="display: none">
                <div>
                <table id="rf_details">
                    <tbody>
                    <tr>
                        <td>Salesman:</td>
                        <td>{{form.returned_by_name}}</td>
                        <td>Date:</td>
                        <td>{{ form.return_date | formatDate }}</td>
                    </tr>
                    <tr>
                        <!-- <td>Remarks:</td>
                        <td>{{ form.remarks }}</td> -->
                        <td></td>
                        <td></td>
                    </tr>
                    </tbody>
                </table>
                </div>

                <div>
                <table id="items_details">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>MODEL</th>
                        <th>NAME</th>
                        <th>CATEGORY</th>
                        <th>UNITS</th>
                        <th>RETURNED QTY</th>
                        <th>BORROWED QTY</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in form.returnFormItems" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td>{{ item.item_model }}</td>
                        <td>{{ item.item_name }}</td>
                        <td>{{ item.item_categories }}</td>
                        <td>{{ item.item_uom }}</td>
                        <td>{{ item.return_qty }}</td>
                        <td>{{ item.borrowed_qty }}</td>
                    </tr>
                    <tr>
                        <td colspan="7">***** NOTHING FOLLOWS *****</td>
                    </tr>
                    </tbody>
                </table>
                </div>

                <div>
                <table id="footer">
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                        </tr>

                        <tr>
                            <!-- <td>{{ form.prepare_by_name }}<br />___________</td> -->
                            <td>{{ form.returned_by_name }}</td>
                            <td>{{ form.approve_by_name }}</td>
                        </tr>

                        <tr>
                            <!-- <td>Prepared By</td> -->
                            <td>Returned By</td>
                            <td>Approved By</td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </v-container>
            <v-dialog v-model="override.override_dialog" persistent max-width="30%" scrollable>
                <v-card>
                    <v-row class="m-0">
                        <v-card-title>
                            <span class="headline">Confirm Override Print</span>
                        </v-card-title>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right" @click="closeOverrideDialog(); override_username = ''; override_user_password = '';">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card-text>
                        <div class="row">
                            <!-- <div class="col-sm-12 col-md-6">
                                <label for="override_username" class="float-left control-label col-form-label">Username</label>
                                <input type="text" class="form-control" id="override_username" placeholder="Username" v-model="override_username" autocomplete="new-password">
                                <span v-for="errors in errors.override_username" class="text-warning" v-bind:key="errors">{{errors}}</span>
                            </div> -->
                            <div class="col-sm-12 col-md-12">
                                <label for="override_user_password" class="float-left control-label col-form-label">Password</label>
                                <input type="password" class="form-control" id="override_user_password" placeholder="Password" v-model="override.override_user_password" autocomplete="new-password">
                                <!-- <span v-for="errors in errors.override_user_password" class="text-warning" v-bind:key="errors">{{errors}}</span> -->
                            </div>
                        </div>
                        <div class="border-top">
                            <div class="card-body py-4 d-flex justify-content-center">
                                <v-btn  v-on:click="confirmedOverridePrint()">Confirm</v-btn>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="cancellation.dialog" width="40%" persistent>
                <v-card>
                    <v-row class="m-0">
                        <v-col>
                            <v-card-title>
                                <span class="headline">Cancel Reason</span>
                            </v-card-title>
                        </v-col>

                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right" @click="closeCancelReasonDialog()">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-divider class="my-0" />
                        <v-row class="pa-5">
                            <v-col>
                                <v-textarea v-model="cancellation.reason" outlined rows="3" no-resize label="Reason"></v-textarea>
                            </v-col>
                        </v-row>
                    <v-card-actions class="text-center" style="border-top:1px solid #dee2e6;">
                        <v-row class="ma-1 pb-2">
                            <v-col>
                                <v-btn
                                    @click="changeReturnFormStatus(1)"
                                >
                                    <span>Submit</span>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-dialog>
    </v-card>
</v-form>
</template>

<script>
import { mapGetters } from 'vuex'
import swal from 'sweetalert2';
import FileViewer from '@/views/main/layouts/dialogs/Operations/SampleForm/FileViewer/SampleFormFileViewer.vue'
import FileUploadComponent from '@/views/main/layouts/dialogs/Operations/SampleForm/FileViewer/FileWithPreviewUploadComponent.vue'

export default {
    components: {
        FileViewer,
        FileUploadComponent
    },
    data() {
        return {
            valid:false,
            itemsPerPageOptions: [5, 10, 15],
            itemsPerPage: 15,
            search: '',
            batch_addition: {
                dialog: false,
                data: [],
                excel_item_id: null,
                errors: [],
            },
            form:{
                salesman_id:'',
                returnFormItems: [],
                remarks: '',
                return_qty:'',
                status:0
            },
            dialog: false,
            cancellation:{
                dialog:false,
                reason:''
            },
            max_adjustment_items_count: 999,
            dropdowns:{
                sample_form_items: [],
                inventory_items: [],
                sample_form_selection: [],
                salesman_selection: []
            },
            rules: {
                required: [ value => !!value || 'Required.' ],
            },
            loading:{
                sample_form_selection: true,
                salesman_selection : true,
                button: false
            },
            item_details:'',
            item_data:{},
            icon: 'justify',
            component_dispatches : [
                'getSalesman',
                'getSampleFormSelection'
            ],
            override: {
                override_dialog: false,
                authenticated:false,
                override_user_password:"",
                errors: [],
                override_departments_allowed: ['SM', 'EX', 'IT'],
                override_authenticated: false,
                type:"",
                isPrint:false
            },
             file_viewer_url_details:{
                dispatch:"getUploadFiles",
                api:"getRFUploadedFiles"
            },
            stock_validate: true,
            userAccess : []

        }
    },
    created(){
        this.initialForm();
    },
    mounted() {
        this.getallDropdowns()
        this.getInventoryItems()
        this.$store.dispatch('checkAccessUser',this.$router.currentRoute.params.id);
    },
    methods: {
        getallDropdowns(){
            this.component_dispatches.forEach(e=>{
                this.$store.dispatch(e);
                this.loading.sample_form_selection = true;
                this.loading.salesman_selection = true
            })
        },
        initialForm(){
            this.form = {
                salesman_id:'',
                returnFormItems: [],
                remarks: '',
                files:{
                    attachments:[],
                    config:{}
                },
                rf_trash_ids:[]
            }
        },
        getInventoryItems() {
            let payload = {
                url: 'getAllInventoryItems'
            }
            this.$store.dispatch('getInventorySampleFormItems', payload).then(response => {
                this.dropdowns.inventory_items = this.GET_SAMPLE_FORM_ITEMS
            }).catch((err)=>{
                console.log(err);
                swal.fire({
                    icon: 'Network Error',
                    title: 'error',
                })
            })
        },
        closeReturnFormDialog() {
            this.$store.commit("DIALOG", false);
            this.stock_validate = true
            this.clearFileUpload();
        },
        submitReturnForm() {
            switch(this.actions){
                case 'Create':
                    if(!this.userAccess.includes('create_return')){
                        swal.fire('Error!','- No Access','error')
                        return
                    }
                    break;
                case 'Update':
                    if(!this.userAccess.includes('update_return')){
                        swal.fire('Error!','- No Access','error')
                        return
                    }
                    break;
            }
            if(this.ACTION == 'Submit'){
                this.$refs.form.validate();
                if(this.form.files.attachments.length == 0){
                    swal.fire(
                        '',
                        'Please Fill Upload Files',
                        'warning'
                    )
                }
                else if (this.form.returnFormItems.length > 0 && this.form.salesman_id) {
                    let valid_count = 0;
                    let invalid_models = [];
                    this.form.returnFormItems.forEach(el=>{
                        if(el.return_qty > el.borrowed_qty || el.return_qty == 0 || el.return_qty == null){
                            valid_count ++
                            invalid_models.push(el.item_model)
                        }
                    })
                    if(valid_count > 0){
                        swal.fire(
                            "Invalid Return Quantity",
                            `Invalid Quantity for Model/s: ${invalid_models}`,
                            "warning"
                        );
                    }
                    else{
                        swal.fire({
                            title: 'Are you sure you want to Return?',
                            text: 'Make sure that all information are correct',
                            icon: 'question',
                            showConfirmButton: true,
                            showCancelButton: true,
                            reverseButtons: true,
                            allowOutsideClick: false,
                            confirmButtonColor: '#397373',
                            cancelButtonColor: 'grey',
                            confirmButtonText: 'Confirm',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                let payload = {
                                    url: 'addUpdateReturnForm',
                                    id: this.form.id ?  this.form.id : 0,
                                    salesman_id: this.form.salesman_id,
                                    remarks: this.form.remarks,
                                    return_items: this.form.returnFormItems,
                                    sample_form_id:this.form.sample_form_id
                                }
                                this.$store.dispatch(this.GET_SF_DISPATCH.upsert, payload).then(response => {
                                    swal.fire({
                                        icon: response.data.icon,
                                        title: response.data.icon.toUpperCase(),
                                        text: response.data.msg,
                                    })
                                    const data = new FormData();
                                    data.append("id",response.data.id);

                                    if (!!this.form.files && this.form.files.attachments.length > 0) {
                                        let files = this.form.files.attachments;
                                        let payload = {
                                            url:"uploadfilesRF",
                                            data: data,
                                            config: this.form.files.config,
                                        };
                                        for (let i = 0; i < _.compact(files).length; i++) {
                                            data.append("files[]", files[i]);
                                        }
                                        this.$store.dispatch('uploadfilesSF',payload)
                                    }
                                    this.resetFields()
                                    this.$store.commit("DIALOG", false);
                                }).catch((err)=>{
                                    console.log(err);
                                    swal.fire({
                                        icon: 'Network Error',
                                        title: 'error',
                                    })
                                })
                            }
                        })
                    }
                }
                else {
                    swal.fire(
                        '',
                        'Please Fill Required Fields',
                        'warning'
                    )
                }
            }
            else{
                  if (this.form.returnFormItems.length > 0 && this.form.salesman_id) {
                    let valid_count = 0;
                    let invalid_models = [];
                    this.form.returnFormItems.forEach(el=>{
                        if(el.return_qty > el.borrowed_qty){
                            valid_count ++
                            invalid_models.push(el.item_model)
                        }
                    })
                    if(valid_count > 0){
                        swal.fire(
                            "Invalid Return Quantity",
                            `Invalid Quantity Model/s: ${invalid_models}`,
                            "warning"
                        );
                    }
                    else{
                        swal.fire({
                            title: 'Return Form will be Updated',
                            text: 'Make sure that all information are correct',
                            icon: 'warning',
                            showConfirmButton: true,
                            showCancelButton: true,
                            reverseButtons: true,
                            allowOutsideClick: false,
                            confirmButtonColor: '#397373',
                            cancelButtonColor: 'grey',
                            confirmButtonText: 'Confirm',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                let payload = {
                                    url                 : 'addUpdateReturnForm',
                                    id                  : this.form.id ?  this.form.id : 0,
                                    salesman_id         : this.form.salesman_id,
                                    remarks             : this.form.remarks,
                                    return_items        : this.form.returnFormItems,
                                    sample_form_id      : this.form.sample_form_id,
                                    rf_trash_ids        : this.form.rf_trash_ids
                                }
                                this.$store.dispatch(this.GET_SF_DISPATCH.upsert, payload).then(response => {
                                    swal.fire({
                                        icon: response.data.icon,
                                        title: response.data.icon.toUpperCase(),
                                        text: response.data.msg,
                                    })
                                    const data = new FormData();
                                    data.append("id",response.data.id);

                                    if (!!this.form.files && this.form.files.attachments.length > 0) {
                                        let files = this.form.files.attachments;
                                        let payload = {
                                            url:"uploadfilesRF",
                                            data: data,
                                            config: this.form.files.config,
                                        };
                                        for (let i = 0; i < _.compact(files).length; i++) {
                                            data.append("files[]", files[i]);
                                        }
                                        this.$store.dispatch('uploadfilesSF',payload).catch((err)=>{
                                            console.log(err);
                                            swal.fire({
                                                icon: 'Network Error',
                                                title: 'error',
                                            })
                                        })
                                    }
                                    this.resetFields()
                                    this.$store.commit("DIALOG", false);
                                })
                            }
                        })
                    }
                }
                else {
                    swal.fire(
                        '',
                        'Please Fill Required Fields',
                        'warning'
                    )
                }
            }
        },
        resetFields() {
            this.errors = [];
            this.form.returnFormItems = [];
            this.form.salesman_id = "";
            this.form.remarks = "";
        },
        validateReturnQty(i,data){
            this.checkIfCreateable();
            if(this.ACTION == 'Submit' || this.ACTION == 'Update'){
                if(data.return_qty > data.remaining_qty) {
                    swal.fire({
                        title: 'Invalid Quantity',
                        text: 'Return Qty must not be greater than Remaining Qty!',
                        icon: 'warning',
                        allowOutsideClick: false,
                    })
                    this.form.returnFormItems[i].return_qty = 0;
                    return false;
                }
                if(data.return_qty <= 0){
                    swal.fire({
                        title: 'Invalid Input',
                        text: 'Please Input a valid quantity',
                        icon: 'warning',
                        allowOutsideClick: false,
                    })
                    // this.form.returnFormItems[i].return_qty = 0;
                }
            }
        },
        closeBatchAdditionDialog() {
            this.batch_addition.dialog = false;
        },
        // validateManualAddItem(i){
        //     let payload = {
        //         item_id: this.form.returnFormItems[i].item_id,
        //         manualAdd: true,
        //         url: 'getSelectedInventoryItem'
        //     }
        //     this.$store.dispatch('getSelectedItems', payload).then(response => {
        //         this.item_data = response.data
        //         this.form.returnFormItems[i].item_model = this.form.returnFormItems[i].item_id
        //         this.form.returnFormItems[i].item_name = this.item_data.name
        //         this.form.returnFormItems[i].item_categories = this.item_data.item_categories
        //         this.form.returnFormItems[i].item_uom = this.item_data.item_uom
        //         this.form.returnFormItems[i].item_types = this.item_data.item_types
        //         this.form.returnFormItems[i].remain_stocks = this.item_data.virtual_qty
        //         // this.generateQR(this.item_data,i)
        //     })
        // },
        openBatchDialog() {
            this.batch_addition.dialog = true;
        },
        closeAddEditDialog() {
            this.$store.commit('DIALOG', false);
        },
        removeAdjustmentItemByIndex(index,id) {
            if (this.form.returnFormItems.length > 1) {
                this.form.returnFormItems.splice(index, 1);
                if(this.actions == 'Update'){
                    this.form.rf_trash_ids.push(id)
                }
            }

        },
        getAllData(){
            this.form.id                    = this.GET_VIEW_DATA.id;
            this.form.salesman_id           = this.GET_VIEW_DATA.return_by_id;
            this.form.status                = this.GET_VIEW_DATA.status;
            this.form.sample_form_id        = this.GET_VIEW_DATA.sample_form_id;
            this.form.returnFormItems       = this.GET_VIEW_DATA.return_form_items
            this.form.return_date           = this.GET_VIEW_DATA.return_date
            this.form.sample_form_num       = this.GET_VIEW_DATA.sample_form_num
            this.form.return_form_num       = this.GET_VIEW_DATA.return_form_num
            this.form.approve_by_name       = this.GET_VIEW_DATA.approve_by_name
            this.form.returned_by_name      = this.GET_VIEW_DATA.returned_by_name
            this.form.printed_pdf           = this.GET_VIEW_DATA.printed_pdf
            this.form.approvable            = this.GET_VIEW_DATA.approvable
            this.form.remarks               = this.GET_VIEW_DATA.remarks
            this.form.salesman_name         = this.GET_VIEW_DATA.salesman_name
            this.form.cancel_reason         = this.GET_VIEW_DATA.cancel_reason
            this.$store.commit('FILE_VIEWER_ID',this.GET_VIEW_DATA.id)
            this.$store.commit('FILE_PREVIEWS',this.GET_VIEW_DATA.return_form_files);
        },
        changeReturnFormStatus(status){
            switch(status){
                case 1:
                    if(!this.userAccess.includes('cancel_return')){
                        swal.fire('Error!','- No Access','error')
                        return
                    }
                    break;
                default :
                    if(!this.userAccess.includes('approve_return')){
                        swal.fire('Error!','- No Access','error')
                        return
                    }
                    break
            }
            swal.fire({
                title               : `Are you sure you want to Approve?`,
                text                : 'Available Stocks per item will be added.',
                icon: 'question',
                showConfirmButton   : true,
                showCancelButton    : true,
                reverseButtons      : true,
                allowOutsideClick   : false,
                confirmButtonColor  : '#397373',
                cancelButtonColor   : 'grey',
                confirmButtonText   : 'Confirm',
            }).then((res)=>{
                if(res.isConfirmed){
                    this.loading.button = true;
                    let payload = {
                        url                         : 'changeStatusReturnForm',
                        return_form_id              : this.form.id,
                        return_form_items           : this.form.returnFormItems,
                        status                      : status,
                        sample_form_id              : this.form.sample_form_id,
                        remarks                     : this.form.remarks,
                        cancel_reason               : this.cancellation.reason
                    }
                    this.$store.dispatch(this.GET_SF_DISPATCH.changestatus,payload).then((res)=>{
                        swal.fire({
                            title   : res.data.icon.toUpperCase(),
                            icon    : res.data.icon
                        })
                        this.loading.button = false;
                    }).catch((err)=>{
                        console.log(err)
                        swal.fire({
                            title   :"Error",
                            text    :"Network error",
                            icon    :"error"
                        })
                        this.loading.button = false;
                    })
                }
            })
        },
        resetInventoryItemsSelection(){
            this.dropdowns.inventory_items.forEach(el=>{
                el.isDisabled = false
            })
        },
        // setSampleFormItems() {
        //     console.log(this.GET_SAMPLE_FORM_SELECTION);
        //     let returnFormItems = this.GET_SAMPLE_FORM_SELECTION.find(obj => obj.id == this.form.sample_form_id).sample_form_items ?? [];
        //     let salesman_id = this.GET_SAMPLE_FORM_SELECTION.find(obj => obj.id == this.form.sample_form_id).salesman_id ?? '';
        //     // Loop through each item in the returnFormItems array and set the qr_code_base64 property to null
        //     // returnFormItems.forEach(item => {
        //     //     item.qr_code_base64 = null;
        //     // });
        //     this.form.salesman_id = salesman_id

        //     this.form.returnFormItems = returnFormItems;
        // },
        setSampleFormItems() {
            this.form.returnFormItems = [];
            this.form.salesman_id = '';

            let selectedSampleForm = this.GET_SAMPLE_FORM_SELECTION.without_completed.find(obj => obj.id === this.form.sample_form_id);


            if (selectedSampleForm) {
                selectedSampleForm.sample_form_items = selectedSampleForm.sample_form_items.filter((e)=> e.remaining_qty > 0);
                let returnFormItems = selectedSampleForm.sample_form_items ?? [];
                let salesman_id = selectedSampleForm.salesman_id ?? '';

                this.form.salesman_id = salesman_id;
                this.form.returnFormItems = returnFormItems;
            }
        },
        filterSampleFormNumber(){
            let filtered_sf_number = this.GET_SAMPLE_FORM_SELECTION.without_completed.map(obj => {
                if(obj.salesman_id == this.form.salesman_id){
                    return obj
                }
            });
            this.dropdowns.sample_form_selection = filtered_sf_number;
            if(filtered_sf_number[0] == undefined){
                this.resetSampleFormSelection();
                swal.fire({
                    title   :"Warning",
                    text    :"No Sample Form Found For Selected Salesman",
                    icon    :"warning"
                }).then((res)=>{
                    if(res.isConfirmed){
                        this.initialForm();
                        this.$refs.form.resetValidation()
                    }
                })
            }
        },
        async renderPDF(pdfAction) {
            var doc = new this.$jspdf("p", "mm", "letter");
            let table_height = 0 ;
            doc.setFont(undefined, 'bold').setFontSize(13).text(162, 13, "Return Form")
            doc.setFont(undefined, 'bold').setFontSize(11).text(162, 18, "RF#: " +this.form.return_form_num)
            // doc.setFont(undefined, 'bold').setFontSize(11).text(162, 23, "SF#: " +this.form.sample_form_num)
            doc
                .setFont(undefined, "bold")
                .setFontSize(13)
                .text(6, 13, "INGCOPH Traders Inc.\n");


            doc.setFont(undefined, 'normal').setFontSize(10).text(6, 18, "130-A 6th St. 7th Ave, East Grace Park, Caloocan City \nCell No.: +63 935 713 2293 / +63 917 170 0979 / +63 919 078 0016 \nTel. No.: +63 2 8727 1771 / +63 2 8535 9968 / +63 2 7091 4539")

            // let customer_details_top = this.service ? 50 : 37;
            let customer_details_top = 40 ;

            doc.autoTable({
                html: "#rf_details",
                theme: "plain",
                margin: { top: customer_details_top, left: 6, right: 6 },
                columnStyles: {
                0: { cellWidth: 20 },
                1: { cellWidth: 134 },
                2: { cellWidth: 15 },
                3: { cellWidth: 25 },
                },
            });

                doc.autoTable({
                html: "#items_details",
                startY: doc.autoTable.previous.finalY + 3,
                margin: { left: 6, right: 6 },
                theme: "grid",
                headStyles: {
                    halign: "center",
                    fillColor: "#ffffff",
                    textColor: "#000000",
                    lineWidth: 0.2,
                    lineColor: "#000000",
                },
                columnStyles: {
                    0: {  halign: "center", textColor: "#000000" },
                    1: {  halign: "center", textColor: "#000000" },
                    2: {  halign: "center", textColor: "#000000" },
                    3: {  halign: "center", textColor: "#000000" },
                    4: { halign: "center", textColor: "#000000" },
                    5: {  halign: "center", textColor: "#000000" },
                    6: {  halign: "center", textColor: "#000000" },
                },
                bodyStyles: {
                    lineColor: "#000000",
                },
                didDrawPage:(d) => table_height = d.cursor.y,
                });


            if(table_height > 200){
                doc.addPage()
            }


            // var footer_y = doc.autoTable.previous.finalY + 3;
            var footer_y =  230;

            doc.autoTable({
                html: "#footer",
                theme: "plain",
                startY: footer_y,
                margin: { left: 6, right: 6 },
                columnStyles: {
                    0: { cellWidth: 40, halign: "center", textColor: "#000000" },
                    1: { cellWidth: 40, halign: "center", textColor: "#000000" },
                },
                bodyStyles: {
                    lineColor: "#000000",
                },
                didDrawCell: function (data) {
                    doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height, "S");
                },
            });

            let finalY = doc.lastAutoTable.finalY; // The y position on the page
            const totalPages = doc.internal.getNumberOfPages();
            for (let i = 1; i <= totalPages; i++) {
                doc.setPage(i); // Set the current page
                let pageNumber = "Page " + i + " of " + totalPages;
                doc.setFontSize(7);
                doc.setTextColor("#000000");
                doc.text(190, finalY, pageNumber);
            }

            if (pdfAction == "download") {
                doc.save("Return Form.pdf");
            } else if (pdfAction == "print") {
                doc.autoPrint();
                this.setPrint('pdf')
                window.open(doc.output("bloburl"), "_blank");
            }
        },
        setPrint(type){
            let payload = {
                id: this.form.id,
                url: 'printReturnForm',
                type: type
            }
            this.$store.dispatch('printReturnForm',payload).then(response=>{
                swal.fire({
                    title: response.data.icon.toUpperCase(),
                    icon: response.data.icon,
                })
                this.$store.dispatch('viewReturnFormDetails', this.form.id).then(response => {
                    this.$store.commit('VIEW_DATA',response.data)
                }).catch((err)=>{
                    console.log(err);
                    swal.fire({
                        icon: 'Network Error',
                        title: 'error',
                    })
                });
            }).catch((err)=>[
                console.log(err)
            ])
        },
        showOverride(print){
            if(this.form.printed_pdf == 1){
                this.override.override_dialog = true;
                this.override.action = print
            }
            else{
                this.renderPDF(print)
            }
        },
        uploadedData(data) {
            if (!data) {
                this.form.files = null;
            }
            if(this.form.files.attachments.length == 0){
                this.file_previews = data.preview;
                this.form.files = data;
            }
            else{
                this.form.files.attachments.push(...data.attachments);
            }
        },
        removeAttachment(data){
            // Remove file attachment based on the index from file preview
            this.form.files.attachments.splice(data, 1);
        },
        clearFileUpload(confirm) {
            if (confirm) {
                this.form.files = null;
            }
            if(this.ACTION == 'Submit' || this.ACTION == 'Update'){
                this.form.files = null;
                this.$refs.fileUpload.reset();
            }
        },
        resetSampleFormSelection(){
            this.dropdowns.sample_form_selection    = this.GET_SAMPLE_FORM_SELECTION.without_completed;
        },
        resetSalesmanSelection(){
            this.dropdowns.salesman_selection       = this.GET_SALESMAN_LIST;
        },
        confirmedOverridePrint() {
             if (!this.override.override_user_password) {
                this.override_user_id = -1
                this.override.override_dialog = false
            } else {
                let payload = {
                    departments_allowed:        this.override.override_departments_allowed,
                    override_user_password:     this.override.override_user_password,
                }
                this.$store.dispatch('confirmOverride',payload).then(response => {
                    let data = this.GET_CONFIRM_OVERRIDE

                    this.override.override_user_password = ''

                    if (data.error) {
                        this.override_user_id = -1
                        swal.fire("", data.error, "warning")
                        return false
                    }

                    this.override_user_id = data.user_id
                    this.override.override_authenticated = true
                    if (this.override.override_authenticated) {
                        this.override.override_dialog = this.override.override_authenticated;
                        this.renderPDF(this.override.action)
                        this.override.override_dialog = false;
                    } else {
                        this.override.override_dialog = this.override.override_authenticated;
                    }
                }).catch(error => {
                    console.log(error)
                })
            }
        },
        closeOverrideDialog(){
            this.override.override_dialog = false
        },
        checkIfCreateable(){
            let not_acceptable_count = 0;
            if(this.form.returnFormItems){
                this.form.returnFormItems.forEach((e,i)=>{
                    if(e.remaining_qty < e.return_qty || e.return_qty == 0 || e.return_qty == undefined){
                        not_acceptable_count ++
                    }
                })
                this.stock_validate = not_acceptable_count > 0 ?  false : true ;
            }
        },
        openCancelReasonDialog(){
            this.cancellation.dialog = true
        },
        closeCancelReasonDialog(){
            this.cancellation.dialog = false
        }
    },
    computed: {
        ...mapGetters([
            'GET_SALESMAN_LIST',
            'GET_SAMPLE_FORM_ITEMS',
            'GET_VIEW_DATA',
            'ACTION',
            'GET_SF_DISPATCH',
            'DIALOGS',
            'GET_SAMPLE_FORM_SELECTION',
            'GET_CONFIRM_OVERRIDE',
            'USER_ACCESS'
        ]),
        actions(){
            if(this.ACTION == 'Submit'){
                this.dropdowns.sample_form_selection = this.GET_SAMPLE_FORM_SELECTION.without_completed
                return 'Create'
            }
            else if(this.ACTION == 'View'){
                this.getAllData();
                this.dropdowns.sample_form_selection = this.GET_SAMPLE_FORM_SELECTION.without_completed

                return 'View'
            }
            else if(this.ACTION == 'Update'){
                this.getAllData();
                this.checkIfCreateable();
                this.dropdowns.sample_form_selection = this.GET_SAMPLE_FORM_SELECTION.without_completed

                return 'Update'
            }
            else if(this.ACTION == 'Approve'){
                this.getAllData();
                this.dropdowns.sample_form_selection = this.GET_SAMPLE_FORM_SELECTION.without_completed
                return 'Approve'
            }
        }
    },
    watch: {
        GET_SAMPLE_FORM_ITEMS: {
            handler(val){
            }
        },
        DIALOGS:{
            handler(val){
                if(val && this.ACTION == 'Submit'){
                    this.getallDropdowns();
                    this.initialForm();
                    this.$refs.form.resetValidation()
                }
            }
        },
        GET_SALESMAN_LIST:{
            handler(val){
                this.dropdowns.salesman_selection = val;
                this.loading.salesman_selection = false;
            }
        },
        'form.salesman_id':{
            handler(val){
                if(val == null){
                    this.resetSampleFormSelection();
                }
            }
        },
        'dropdowns.sample_form_selection':{
            handler(val){
                if(val){
                    val.forEach(e=>{
                        e.sample_form_items.forEach((x)=>{
                            delete x.id
                        })
                    });
                    this.loading.sample_form_selection = false
                }
            }
        },
        USER_ACCESS:{
            handler(val){
                if(val != "fail"){
                    this.userAccess = val.map(e=>e.actions_code);
                }
            }
        }
    }
}
</script>

<style scoped>
.centered-input >>> input {
    text-align: center
}
</style>
