<template>
    <v-container fluid class="pa-3">
        <h3 class="page-title">Container Discrepancy</h3>
        <v-container class="ma-0">
            <v-app id="item-tabs">
                <v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" background-color="#424242" dark show-arrows>
                    <v-tab ripple  @click="setTabName('is_saved')">SAVED</v-tab>
                    <v-tab ripple @click="setTabName('is_confirmed')">CONFIRMED</v-tab>
                    <v-tab ripple  @click="setTabName('is_approved')">APPROVED</v-tab>
                    <v-tab ripple @click="setTabName('is_all')">ALL</v-tab>
                </v-tabs>
                <container-discrepancy-component :tab_name="tab_name" :component_details="component_details"></container-discrepancy-component>
            </v-app>
        </v-container>
    </v-container>
</template>

<script>
import ContainerDiscrepancyComponent from './ContainerDiscrepancyComponent.vue'
export default {
    components: {
        ContainerDiscrepancyComponent,
    },
    data() {
        return {
            component_details:{
                title:"Container Discrepancy",
                statuses:[
                    {
                        name:'saved',
                        class:'badge white--text bg-secondary'
                    },
                    {
                        name:'confirmed',
                        class:'badge white--text bg-primary'
                    },
                    {
                        name:'approved',
                        class:'badge white--text bg-success'
                    }
                ]
            },
            tab: null,
            tab_name:'is_saved'
        }
    },
    mounted(){
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
        this.$store.dispatch('getContainerDiscrepancy',{url:'containers-selection',mutation:'CONTAINERS_SELECTION'})
        this.$store.dispatch('getContainerDiscrepancy',{url:'discrepancy-selection',mutation:'DISCREPANCY_SELECTION'})
        this.$store.dispatch('getAllUsers')
        this.$store.dispatch("getAllWarehouses");
        this.$store.dispatch('checkAccessUser',this.$router.currentRoute.params.id);
    },
    methods:{
        setTabName(val){
            this.tab_name = val
        }
    }
}
</script>
<style>
    #group-tab{
      padding: 0 !important;
    }
</style>
