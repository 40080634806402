<template>
  <v-container fluid>
    <ComponentHeader
      :button="true"
      :title="'Shipment Per Item'"
      :type="'Inventory'"
    ></ComponentHeader>
    <v-container fluid class="bg-bluish-gray">
      <v-row no-gutters>
        <v-col lg="2">
          <v-text-field
            v-model="filters.item_model"
            background-color="white"
            label="Select Model"
            dense
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col lg="2">
          <v-text-field
            v-model="filters.item_name"
            background-color="white"
            label="Select Name"
            dense
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col lg="2">
          <v-select
            v-model="filters.item_status"
            :items="dropdowns.item_status"
            background-color="white"
            label="Select Item Status"
            dense
            outlined
            hide-details
          ></v-select>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="d-flex justify-end" style="gap: 7px">
          <v-btn :disabled="dataTable.loading" :loading="exportData.loading" small @click="getData('xlsx')">Export</v-btn>
          <v-btn :disabled="exportData.loading" :loading="dataTable.loading" small @click="getData()">Filter</v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col lg="2">
          <v-autocomplete
            v-model="filters.item_type_ids"
            :items="GET_ITEM_TYPES"
            background-color="white"
            label="Select Disc Type"
            dense
            outlined
            hide-details
            auto-select-first
            chips
            deletable-chips
            multiple
            small-chips
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col lg="4">
          <v-autocomplete
            v-model="filters.item_category_ids"
            :items="GET_ALL_ITEM_CATEGORIES"
            background-color="white"
            label="Select Item Category"
            item-value="id"
            item-text="name"
            dense
            outlined
            hide-details
            auto-select-first
            chips
            deletable-chips
            multiple
            small-chips
            clearable
          ></v-autocomplete>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row no-gutters>
        <v-col lg="3">
          <v-autocomplete
            v-model="filters.warehouse_ids"
            :items="GET_ALL_WAREHOUSES"
            background-color="white"
            label="Select Warehouse"
            dense
            outlined
            hide-details
            auto-select-first
            chips
            deletable-chips
            multiple
            small-chips
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col lg="3">
          <v-select
            v-model="filters.container_status"
            :items="dropdowns.container_status"
            background-color="white"
            label="Select Container Status"
            dense
            outlined
            hide-details
            clearable
          ></v-select>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row no-gutters>
        <v-col lg="2">
          <v-select
            v-model="filters.date_type"
            :items="dropdowns.date_type"
            background-color="white"
            label="Select Date Type"
            dense
            outlined
            hide-details
          ></v-select>
        </v-col>
        <v-col lg="2" id="date_from">
          <v-menu
            v-model="menus.date_from"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="filters.date_from"
                v-on="on"
                append-icon="mdi-calendar-clock"
                background-color="white"
                label="Date From"
                dense
                outlined
                hide-details
                readonly
              ></v-text-field>
            </template>
            <v-date-picker v-model="filters.date_from" @input="menus.date_from = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col lg="2" id="date_to">
          <v-menu
            v-model="menus.date_to"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="filters.date_to"
                v-on="on"
                append-icon="mdi-calendar-clock"
                background-color="white"
                label="Date To"
                dense
                outlined
                hide-details
                readonly
              ></v-text-field>
            </template>
            <v-date-picker v-model="filters.date_to" @input="menus.date_to = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row no-gutters>
        <v-col lg="2">
          <v-checkbox
            v-model="filters.exclude_no_containers"
            :true-value="1"
            :false-value="0"
            class="ma-0 d-flex align-items-center"
            color="white"
            hide-details
          >
            <template #label>
              <span style="color: white">Exclude No Created Containers</span>
            </template>
          </v-checkbox>
        </v-col>
        <v-col lg="2">
          <v-checkbox
            v-model="filters.has_images"
            :true-value="1"
            :false-value="0"
            class="ma-0 d-flex align-items-center"
            color="white"
            hide-details
          >
            <template #label>
              <span style="color: white">Has Images (Export)</span>
            </template>
          </v-checkbox>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row class="pt-2" no-gutters>
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <v-data-table
                :headers="dataTable.headers"
                :items="dataTable.items"
                :loading="dataTable.loading"
                :options.sync="dataTable.options"
                :server-items-length="dataTable.length"
                :expanded.sync="dataTable.expanded"
                :footer-props="{
                  itemsPerPageOptions: dataTable.itemsPerPageOptions
                }"
                class="w-100"
                dense
                show-expand
                disable-sort
                @item-expanded="loadDetails"
              >
                <template v-slot:[`item.image_path`]="{ item }">
                  <v-img
                    :src="checkImagePath(item.image_path)"
                    class="img-thumbnail"
                    width="60"
                    height="60"
                    eager
                    contain
                  ></v-img>
                </template>

                <template v-slot:[`item.selling_price`]="{ item }">
                  <span class="text-nowrap">
                    &#x20b1;{{ item.selling_price | currency }}
                  </span>
                </template>

                <template v-slot:[`item.sp_price`]="{ item }">
                  <span class="text-nowrap">
                    &#x20b1;{{ item.sp_price | currency }}
                  </span>
                </template>

                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length" class="p-0">
                    <v-simple-table dense dark>
                      <template v-slot:default>
                        <thead>
                          <th class="text-center">Container#</th>
                          <th class="text-center">Warehouse</th>
                          <th class="text-center">Broker</th>
                          <th>Date Created</th>
                          <th>Received By</th>
                          <th>Receive Date</th>
                          <th>Confirm Date</th>
                          <th class="text-center">Container Qty</th>
                          <th class="text-center">Container Qty Received</th>
                          <th class="text-center">Status</th>
                        </thead>
                        <tbody v-if="item.containers.length == 0">
                          <tr>
                            <td colspan="10" class="text-center"><strong>No Data Available</strong></td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr v-for="(containers, i) in item.containers" :key="i">
                            <td width="8%" class="text-center">{{ containers.container_num }}</td>
                            <td width="10%" class="text-center">{{ containers.warehouse_code }}</td>
                            <td width="10%" class="text-center">{{ containers.broker }}</td>
                            <td width="10%">{{ containers.created_at | formatDate }}</td>
                            <td width="10%">{{ containers.received_by }}</td>
                            <td width="10%">{{ containers.received_date | formatDate }}</td>
                            <td width="10%">{{ containers.confirm_date | formatDate }}</td>
                            <td width="10%" class="text-center">{{ thousandSeprator(containers.quantity) }}</td>
                            <td width="10%" class="text-center">{{ thousandSeprator(containers.quantity_received) }}</td>
                            <td class="text-center">
                              <v-chip
                                :color="containers.status_color"
                                text-color="white"
                                x-small
                              >
                                <strong>{{ containers.status_text }}</strong>
                              </v-chip>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </td>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
  import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue';
  import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue';
  import { mapGetters } from 'vuex';

  export default {
    data() {
      return {
        filters: {
          item_model: null,
          item_name: null,
          item_type_ids: [],
          item_category_ids: [],
          warehouse_ids: [],
          container_status: null,
          item_status: 1,
          date_type: 'created_at',
          date_from: this.$dayjs().subtract(2, 'month').startOf('month').format('YYYY-MM-DD'),
          date_to: this.$dayjs().endOf('month').format('YYYY-MM-DD'),
          exclude_no_containers: 1,
          has_images: 0,
        },
        dropdowns: {
          item_status: [
            { text: 'Active', value: 1 },
            { text: 'Inactive', value: 0 },
            { text: 'All', value: -1 },
          ],
          container_status: [
            { text: 'Pending', value: 'pending' },
            { text: 'Received', value: 'received' },
            { text: 'Approved', value: 'approved' },
          ],
          date_type: [
            { text: 'Container Creation', value: 'created_at' },
            { text: 'Container Receive Date', value: 'received_date' },
          ],
        },
        menus: {
          date_from: false,
          date_to: false,
        },
        dataTable: {
          headers: [
            { text: 'Image', align: 'left', value: 'image_path' },
            { text: 'Model', align: 'left', value: 'model', },
            { text: 'Name', align: 'left', value: 'name', },
            { text: 'UOM', align: 'center', value: 'item_uom', },
            { text: 'Type', align: 'center', value: 'item_type', },
            { text: 'Category', align: 'center', value: 'item_category', },
            { text: 'SRP', align: 'right', value: 'selling_price', },
            { text: 'Container Qty', align: 'right', value: 'quantity', },
            { text: 'Container Qty Received', align: 'right', value: 'quantity_received', },
          ],
          items: [],
          length: 0,
          expanded: [],
          options: {},
          itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
          loading: false,
        },
        exportData: {
          filename: `Shipment Per Item ${this.$dayjs().format('YYYY-MM-DD')}`,
          loading: false,
        }
      }
    },
    mixins: [
      SharedFunctionsComponent
    ],
    components: {
      ComponentHeader,
    },
    mounted() {
      this.setDefaultValues()
      this.loadDropdowns()
    },
    methods: {
      setDefaultValues() {
        this.filters.item_type_ids = [1, 2]
      },
      loadDropdowns() {
        this.$store.dispatch('getItemTypes');
        this.$store.dispatch('getAllItemCategories');
        this.$store.dispatch('getAllWarehouses');
      },
      toggleLoading(type = 'list') {
        let object = 'dataTable'

        if (type == 'list') {
          object = 'dataTable'
        } else if (type == 'xlsx') {
          object = 'exportData'
        }

        this[object].loading = !this[object].loading
      },
      resetData(type = 'list') {
        let object = 'dataTable'

        if (type == 'list') {
          object = 'dataTable'
          this[object].items = []
        }

      },
      getData(type = 'list') {
        this.toggleLoading(type)
        this.resetData(type)

        this.$store.dispatch('reports', {
          page: this.dataTable.options.page,
          itemsPerPage: this.dataTable.options.itemsPerPage,
          item_type_ids: this.filters.item_type_ids,
          item_category_ids: this.filters.item_category_ids,
          warehouse_ids: this.filters.warehouse_ids,
          item_status: this.filters.item_status,
          container_status: this.filters.container_status,
          date_from: this.filters.date_from,
          date_to: this.filters.date_to,
          exclude_no_containers: this.filters.exclude_no_containers,
          has_images: this.filters.has_images,
          type: type,
          url: 'getShipmentPerItem'
        }).then(async response => {
          if (type == 'list') {
            this.dataTable.expanded = []

            this.dataTable.items = response.data.data
            this.dataTable.length = response.data.total

            this.toggleLoading(type)
          } else if (type == 'xlsx') {
            let data = {
              ['Summary']: {
                headers: [
                  { header: 'Image', key: '', width: 22, include: this.filters.has_images },
                  { header: 'Model', key: 'model', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                  { header: 'Name', key: 'name', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                  { header: 'UOM', key: 'item_uom', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                  { header: 'Type', key: 'item_type', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                  { header: 'Category', key: 'item_category', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                  { header: 'SRP', key: 'selling_price', formatAs: 'float', style: { numFmt: '#,##0.00', alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                  { header: 'Container Qty', key: 'quantity', formatAs: 'int', style: { numFmt: '#,##0', alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                  { header: 'Container Qty Received', key: 'quantity_received', formatAs: 'int', style: { numFmt: '#,##0', alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                ],
                data: response.data.summary,
                hasImages: this.filters.has_images,
              },
              ['Detailed']: {
                headers: [
                  { header: 'Image', key: '', include: this.filters.has_images },
                  { header: 'Model', key: 'model', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                  { header: 'Container#', key: 'container_num', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                  { header: 'Warehouse', key: 'warehouse_code', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                  { header: 'Broker', key: 'broker', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                  { header: 'Date Created', key: 'created_at', formatAs: 'date', style: { numFmt: 'mm/dd/yyyy', alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                  { header: 'Received By', key: 'received_by', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                  { header: 'Receive Date', key: 'received_date', formatAs: 'date', style: { numFmt: 'mm/dd/yyyy', alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                  { header: 'Confirm Date', key: 'confirm_date', formatAs: 'date', style: { numFmt: 'mm/dd/yyyy', alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                  { header: 'Container Qty', key: 'quantity', formatAs: 'int', style: { numFmt: '#,##0', alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                  { header: 'Container Qty Received', key: 'quantity_received', formatAs: 'int', style: { numFmt: '#,##0', alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                  { header: 'Status', key: 'status_text', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                ],
                data: response.data.detailed,
                hasImages: this.filters.has_images,
              },
            }
            await this.exportExcel(data, this.exportData.filename)

            this.toggleLoading(type)
          }
        }).catch(error => {
          console.log(error)

          this.toggleLoading(type)
        })
      },
      loadDetails(item) {
        this.$store.dispatch('reports', {
          warehouse_ids: this.filters.warehouse_ids,
          container_status: this.filters.container_status,
          date_type: this.filters.date_type,
          date_from: this.filters.date_from,
          date_to: this.filters.date_to,
          item_id: item.item.id,
          type: 'expansion',
          url: 'getShipmentPerItem',
        }).then(response => {
          item.item.containers = response.data

          this.$forceUpdate()
        })
      },
    },
    computed: {
      ...mapGetters([
        'GET_ALL_ITEM_CATEGORIES',
        'GET_ITEM_TYPES',
        'GET_ALL_WAREHOUSES'
      ]),
    },
  }
</script>