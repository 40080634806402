<template>
    <v-row>
        <v-container class="tabs-body-color">
            <!-- <buttons class="ma-2"
                v-if="userAccess.create"
                :action="buttons.actions.create"
                :button_icon="buttons.icons.create"
                :color="buttons.colors.create"
                @click="showAddOutbound()"
                >
            </buttons> -->
            <div class="row mt-1" v-if="userAccess.create">
                <div class="col-12 d-flex no-block align-items-center">
                    <div class="btn-toolbar">
                        <v-btn data-modal-id="addContainerModal" @click="showAddOutbound()">
                            <v-icon>mdi-plus</v-icon>Add
                            <!-- ^^ Add Outbound -->
                        </v-btn>
                    </div>
                </div>
            </div>
        </v-container>
        <v-row class="ma-0">
            <OutboundTableComponent ref="child"
                :containerActionResult="action"
                :is_pending="is_pending"
                :is_approved="is_approved"
                :is_receiving="is_receiving"
                :radioGroup="radioGroup"
                @showEditModal="showEditModal"
                :user_access="USER_ACCESS">
            </OutboundTableComponent>
        </v-row>
        <v-app id="addEditOutboundModal">
            <v-dialog
            v-model="add_edit_outbound_dialog"
            persistent max-width="90%"
            scrollable>
                <v-card>
                    <v-row class="m-0">
                        <v-col>
                            <v-card-title>
                                <span class="headline">{{outbound_title}}</span>
                            </v-card-title>
                        </v-col>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn
                            text icon color="gray"
                            class="float-right"
                            @click="closeAddEditDialog();
                            resetFields();
                            $emit('closeModal');">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-container style="background-color: white; overflow-y: scroll">
                        <v-col>
                            <v-card-text v-if="loaded == true">
                                <form class="form-horizontal"
                                action="/containers"
                                method="post"
                                @submit.prevent="onSubmit">
                                    <div class="card-body">
                                        <v-row>
                                            <v-col class="mx-4">
                                                <v-text-field
                                                    class="mt-1"
                                                    dense
                                                    outlined
                                                    id="outbound_num"
                                                    label="Outbound Num"
                                                    v-model="outbound_num"
                                                    :disabled="is_receiving">
                                                </v-text-field>
                                                <span
                                                v-for="errors in errors.outbound_num"
                                                class="text-warning"
                                                :key="errors">{{ errors }}</span>
                                            </v-col>
                                            <v-col class="mx-4">
                                                    <v-autocomplete
                                                        class="mt-1"
                                                        v-model='warehouse_id'
                                                        :items="GET_ALL_WAREHOUSES"

                                                        label="Select Warehouse"
                                                        outlined
                                                        dense
                                                    >
                                                    </v-autocomplete>
                                                <span
                                                v-for="errors in errors.warehouse_id"
                                                class="text-warning"
                                                :key="errors">{{ errors }}</span>
                                            </v-col>
                                            <v-col class="mx-4">
                                                <v-textarea  class="mt-1" dense label="Broker" no-resize rows="2" v-model="broker">
                                                </v-textarea>
                                            </v-col>
                                            <v-col class="mx-4">
                                                <v-textarea  class="mt-1" dense label="Remarks" no-resize rows="2" v-model="remarks"
                                                    :disabled="is_receiving"></v-textarea>
                                            </v-col>
                                            <v-col md="2">

                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col
                                                v-if="false">
                                                <v-checkbox label="MB" true-value="1" false-value="0" v-model="received_type">
                                                </v-checkbox>
                                            </v-col>
                                        </v-row>
                                        <v-card outlined class="p-2">
                                            <div class="row mt-4" >
                                                <div class="float-left mx-2">
                                                    <v-btn :disabled="disabled_batch_addition" dark small color="primary" @click="openBatchDialog();">
                                                        <span>{{ batch_addition_text.title }}</span>
                                                    </v-btn>
                                                </div>
                                                <v-dialog v-model="batch_addition.dialog" persistent max-width="30%"
                                                   >
                                                    <v-card>
                                                        <v-row class="m-0">
                                                            <v-col>
                                                            <v-card-title>
                                                                <span class="headline">{{ batch_addition_text.title }}</span>
                                                            </v-card-title>
                                                            </v-col>
                                                            <v-col cols="pull-right-10 p-2">
                                                                <v-btn text icon color="gray" class="float-right"
                                                                    @click="closeBatchAdditionDialog(); dataFromExcel = ''">
                                                                    <v-icon>mdi-close-circle</v-icon>
                                                                </v-btn>
                                                            </v-col>
                                                        </v-row>
                                                        <v-card-text class="ma-0">
                                                            <span v-if="hasError"> <span style="color:red;">Red : </span>Model not found in the system</span>
                                                            <div class="pt-4 pa-2"
                                                                style="height:auto; width:100%; overflow-y:auto; overflow-x:hidden">
                                                                <v-row class="mx-0 pa-0 ma-0"
                                                                    v-for="(text_area_field, i) in text_area_fields" :key="i">
                                                                <div class="col-5 pa-0 ma-0" >
                                                                        <v-text-field
                                                                            :class="{notInModel: text_area_fields[i].error}"
                                                                            class="InModel"
                                                                            dense
                                                                            outlined
                                                                            v-model="text_area_fields[i].dataFromExcel"
                                                                            rows="1"
                                                                            small color="primary"
                                                                            label="Model"
                                                                            :autofocus="batch_addition.dialog"
                                                                            @paste="onPaste"
                                                                            @change="validateInputModel(i)"
                                                                            @click="$event.target.focus()">
                                                                        </v-text-field >
                                                                    </div>
                                                                    <div class="col-5 py-0 ma-0">
                                                                        <v-text-field
                                                                            :class="{notInModel: text_area_fields[i].error}"
                                                                            class="InModel"
                                                                            dense
                                                                            outlined
                                                                            label="Quantity"
                                                                            v-model="text_area_fields[i].dataFromExcel2"
                                                                            rows="1"
                                                                            small
                                                                            color="primary"
                                                                            >
                                                                        </v-text-field >
                                                                    </div>
                                                                    <div class="col-2  ma-0">
                                                                        <v-btn
                                                                            fab
                                                                            dark
                                                                            small
                                                                            color="red"
                                                                            style="height:20px; width:20px;"
                                                                            @click="clearItem(i)">
                                                                            <v-icon
                                                                                dark
                                                                                small
                                                                                style="font-size:12px">
                                                                                mdi-minus
                                                                            </v-icon>
                                                                        </v-btn>
                                                                    </div>
                                                                </v-row>
                                                                <v-row>
                                                                    <v-col class="ml-3 mb-1">
                                                                        <v-btn
                                                                        dark
                                                                        small
                                                                        color="primary"
                                                                        style="height:20; width:40px;"
                                                                        @click="manualAddItem()">
                                                                            <span>
                                                                                Add
                                                                            </span>
                                                                        </v-btn>
                                                                    </v-col>
                                                                </v-row>
                                                            </div>
                                                            <v-row class="mt-2">
                                                                <div class="col-12">
                                                                    <v-btn :disabled="isDisabledButton" class="float-right ml-2" @click="submittedBtn()">
                                                                        Submit
                                                                    </v-btn>
                                                                    <v-btn class="float-right" dark color="secondary"
                                                                        @click="clearFields()">
                                                                        Clear
                                                                    </v-btn>
                                                                </div>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-dialog>

                                                <div class="col-sm-12 col-md-12 mt-4">
                                                    <v-simple-table class="dynamic_list_table">
                                                        <template v-slot:default>
                                                            <span v-if="!is_receiving">
                                                                <thead class="mb-4">
                                                                    <tr>
                                                                        <th class="text-center mx-2" width="7%">UOM Qty</th>
                                                                        <th class="text-center mx-2" width="7%">Qty/MB</th>
                                                                        <th class="text-center mx-2" width="7%">MB Qty</th>
                                                                        <th class="text-center mx-2" width="15%">Model</th>
                                                                        <th class="text-center mx-2" width="55%">Name</th>
                                                                        <th class="text-center mx-2" width="5%">UOM</th>
                                                                        <th class="text-center mx-2" width="5%">Type</th>
                                                                        <th class="text-center mx-2" width="1%"></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="(outbound_item, i) in outbound_items" :key="i">
                                                                        <td>
                                                                            <v-text-field
                                                                                hide-details
                                                                                dense
                                                                                outlined
                                                                                class="alignText mt-2" placeholder="0"
                                                                                :id="'quantity_' + i"
                                                                                v-model="outbound_items[i].quantity"
                                                                                @blur="computeMBQty(i)">
                                                                            </v-text-field>
                                                                        </td>
                                                                        <td v-if="isViewEdit">
                                                                            <v-text-field
                                                                                background-color="grey lighten-2"
                                                                                color="black"
                                                                                hide-details
                                                                                dense
                                                                                outlined
                                                                                readonly
                                                                                class="alignText mt-2" placeholder="0"
                                                                                :id="'quantity_' + i"
                                                                                v-model="outbound_items[i].item.master_box_qty"
                                                                                >
                                                                            </v-text-field>
                                                                        </td>
                                                                        <td v-else>
                                                                            <v-text-field
                                                                                background-color="grey lighten-2"
                                                                                color="black"
                                                                                hide-details
                                                                                dense
                                                                                outlined
                                                                                readonly
                                                                                class="alignText mt-2" placeholder="0"
                                                                                :id="'quantity_' + i"
                                                                                v-model="outbound_items[i].master_box_qty"
                                                                                >
                                                                            </v-text-field>
                                                                        </td>
                                                                        <td>
                                                                            <v-text-field
                                                                                background-color="grey lighten-2"
                                                                                color="black"
                                                                                hide-details
                                                                                dense
                                                                                outlined
                                                                                readonly
                                                                                class="alignText mt-2" placeholder="0"
                                                                                :id="'quantity_' + i"
                                                                                v-model="outbound_items[i].quantity_master_box"
                                                                                >
                                                                            </v-text-field>

                                                                        </td>
                                                                        <td>
                                                                            <v-text-field
                                                                                background-color="grey lighten-2"
                                                                                color="black"
                                                                                hide-details
                                                                                dense
                                                                                outlined
                                                                                readonly
                                                                                v-model="outbound_items[i].model"
                                                                                option-value="value" option-text="text"
                                                                                placeholder="Model" class="alignText mt-2"
                                                                                :isError="outbound_items[i].active === 0">
                                                                            </v-text-field>
                                                                        </td>
                                                                        <td>
                                                                            <v-text-field
                                                                                background-color="grey lighten-2"
                                                                                color="black"
                                                                                hide-details
                                                                                dense
                                                                                outlined
                                                                                readonly
                                                                                class=" small mt-2"
                                                                                v-model="outbound_items[i].name" >
                                                                            </v-text-field>
                                                                        </td>
                                                                        <td v-if="isViewEdit">
                                                                            <v-text-field
                                                                                background-color="grey lighten-2"
                                                                                color="black"
                                                                                hide-details
                                                                                dense
                                                                                outlined
                                                                                readonly
                                                                                class=" alignText small mt-2"
                                                                                v-model="outbound_items[i].item.item_uom_text"
                                                                                >
                                                                            </v-text-field>
                                                                        </td>
                                                                        <td v-else>
                                                                            <v-text-field
                                                                                background-color="grey lighten-2"
                                                                                color="black"
                                                                                hide-details
                                                                                dense
                                                                                outlined
                                                                                readonly
                                                                                class=" alignText small mt-2"
                                                                                v-model="outbound_items[i].item_uom_text"
                                                                                >
                                                                            </v-text-field>
                                                                        </td>
                                                                        <td>
                                                                            <v-text-field
                                                                                background-color="grey lighten-2"
                                                                                color="black"
                                                                                hide-details
                                                                                dense
                                                                                outlined
                                                                                readonly
                                                                                class=" alignText small mt-2"
                                                                                v-model="outbound_items[i].item_type_text"
                                                                                >
                                                                            </v-text-field>
                                                                        </td>
                                                                        <td>
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                color="primary"
                                                                                style="height:20px; width:20px;"
                                                                                @click="removeOrderByIndex(i)">
                                                                                <v-icon
                                                                                    dark
                                                                                    small
                                                                                    style="font-size:12px">
                                                                                    mdi-minus
                                                                                </v-icon>
                                                                            </v-btn>
                                                                        </td>

                                                                    </tr>
                                                                </tbody>
                                                            </span>
                                                            <span v-else>
                                                                <thead>
                                                                    <tr>
                                                                        <th class="text-center mx-2" width="7%">{{ received_type
                                                                                == '1' ? 'Receive MB Qty' : 'Receive UOM Qty'
                                                                        }}
                                                                        </th>
                                                                        <th class="text-center mx-2" width="7%">MB Qty</th>
                                                                        <th class="text-center mx-2" width="7%">UOM Qty</th>
                                                                        <th class="text-center mx-2" width="7%">MB Conv</th>
                                                                        <th class="text-center mx-2" width="15%">Model</th>
                                                                        <th class="text-center mx-2" width="45%">Name</th>
                                                                        <th class="text-center mx-2" width="5%">UOM</th>
                                                                        <th class="text-center mx-2" width="5%">Type</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="(outbound_item, i) in outbound_items" :key="i">
                                                                        <td>
                                                                            <v-text-field
                                                                                hide-details
                                                                                dense
                                                                                outlined

                                                                                class="alignText mt-2"
                                                                                placeholder="0"
                                                                                :id="'quantity_' + i"
                                                                                v-model="outbound_items[i].quantity_received"
                                                                                @input="computeMBQty(i)">
                                                                            </v-text-field>
                                                                        </td>
                                                                        <td>
                                                                            <v-text-field
                                                                                background-color="grey lighten-2"
                                                                                color="black"
                                                                                hide-details
                                                                                dense
                                                                                outlined
                                                                                readonly
                                                                                class="alignText mt-2" placeholder="0"
                                                                                :id="'quantity_' + i"
                                                                                v-model="outbound_items[i].quantity_master_box"
                                                                                >
                                                                            </v-text-field>
                                                                        </td>
                                                                        <td>
                                                                            <v-text-field
                                                                                background-color="grey lighten-2"
                                                                                color="black"
                                                                                hide-details
                                                                                dense
                                                                                outlined
                                                                                readonly
                                                                                class="alignText mt-2" placeholder="0"
                                                                                :id="'quantity_' + i"
                                                                                v-model="outbound_items[i].quantity" >
                                                                            </v-text-field>
                                                                        </td>
                                                                        <td v-if="isViewEdit">
                                                                            <v-text-field
                                                                                background-color="grey lighten-2"
                                                                                color="black"
                                                                                hide-details
                                                                                dense
                                                                                outlined
                                                                                readonly
                                                                                class="alignText mt-2" placeholder="0"
                                                                                :id="'quantity_' + i"
                                                                                v-model="outbound_items[i].item.master_box_qty"
                                                                                >
                                                                            </v-text-field>
                                                                        </td>

                                                                        <td v-else>
                                                                            <v-text-field
                                                                                background-color="grey lighten-2"
                                                                                color="black"
                                                                                hide-details
                                                                                dense
                                                                                outlined
                                                                                readonly
                                                                                class="alignText mt-2" placeholder="0"
                                                                                :id="'quantity_' + i"
                                                                                v-model="outbound_items[i].master_box_qty"
                                                                                >
                                                                            </v-text-field>
                                                                        </td>
                                                                        <td>
                                                                            <v-text-field
                                                                                background-color="grey lighten-2"
                                                                                color="black"
                                                                                hide-details
                                                                                dense
                                                                                outlined
                                                                                readonly
                                                                                class=" small mt-2"
                                                                                v-model="outbound_items[i].model">
                                                                            </v-text-field>
                                                                        </td>
                                                                        <td>
                                                                            <v-text-field
                                                                                background-color="grey lighten-2"
                                                                                color="black"
                                                                                hide-details
                                                                                dense
                                                                                outlined
                                                                                readonly
                                                                                class=" small mt-2"
                                                                                v-model="outbound_items[i].name">
                                                                            </v-text-field>
                                                                        </td>
                                                                        <td>
                                                                            <v-text-field
                                                                                background-color="grey lighten-2"
                                                                                color="black"
                                                                                hide-details
                                                                                dense
                                                                                outlined
                                                                                readonly
                                                                                class="alignText small mt-2"
                                                                                v-model="outbound_items[i].item_uom_text">
                                                                            </v-text-field>
                                                                        </td>
                                                                        <td>
                                                                            <v-text-field
                                                                                background-color="grey lighten-2"
                                                                                color="black"
                                                                                hide-details
                                                                                dense
                                                                                outlined
                                                                                readonly
                                                                                class="alignText small mt-2"
                                                                                v-model="outbound_items[i].item_type_text">
                                                                            </v-text-field>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </span>
                                                        </template>
                                                    </v-simple-table>
                                                </div>
                                            </div>
                                        </v-card>
                                    </div>
                                    <div class="border-top">
                                        <div class="card-body py-4 d-flex justify-content-center">
                                            <v-btn :loading="waitForProcess" @click="addNewOutbound()" v-if="action == 'Add'">
                                                Submit</v-btn>
                                            <v-btn :loading="waitForProcess" @click="editOutbound()"
                                                v-else-if="action == 'Edit'">
                                                Update</v-btn>
                                        </div>
                                    </div>
                                </form>
                            </v-card-text>
                    <v-card-text v-else-if="loaded == false">
                        <div class="text-center">
                            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                        </div>
                    </v-card-text>
                        </v-col>
                    </v-container>
                </v-card>
            </v-dialog>
        </v-app>
    </v-row>


</template>

<script>
    // import usersTable from './tables/StockAdjustmentTable.vue';
    // import batchAddtionDialog from '../../layouts/dialogs/IT/StockAdjustmentDialog.vue';
    // import Dialogs from '../../layouts/dialogs/Dialog.vue'
    // import buttons from '@/views/main/layouts/Buttons/DialogButton.vue';
    // import buttons_components from '@/assets/js/components-js.js';
    import OutboundTableComponent from './tables/OutboundTableComponent.vue'
    import { computed } from 'vue'
    import { mapGetters } from 'vuex';
    import getters from '@/store/getters';
    import swal from 'sweetalert2'
        export default{
            props: [
                'is_pending',
                'is_receiving',
                'is_approved',
                'radioGroup',
            ],
            components:{
                // buttons,
                // buttons_components,
                OutboundTableComponent,
            },
            data(){
                return{
                    color:'orange',
                    // buttons:buttons_components.buttons,
                    action:'',
                    batch_addition: {
                        dialog: false,
                        data: [],
                        excel_item_id: null,
                        errors: []
                    },
                    dataFromExcel: '',
                    dataFromExcel2: '',
                    editOutboundId: '',
                    add_edit_outbound_dialog: false,
                    outbound_num: '',
                    loading_list_id: '',
                    loading_lists: [],
                    warehouse_id: '',
                    warehouses: [],
                    broker: '',
                    remarks: '',
                    outbound_items: [],
                    text_area_fields: [],
                    received_type: '1',
                    text_area_field: {
                        dataFromExcel: '',
                        dataFromExcel2: '',
                    },
                    outbound_item: {
                        item_id: '',
                        quantity: '',
                        quantity_entered: '',
                        remarks: '',
                        packing_type: '',
                        id: '',
                        active: '',
                        model: '',
                        name: '',
                        type_id: '',
                        item_type_text: '',
                        uom_id: '',
                        item_uom_text: '',
                        master_box_qty: '',
                        small_box_qty: '',
                        quantity_master_box: '',
                        loading_list_item_id: '',
                    },
                    scanner: {
                        item_model: '',
                        item_barcode: 0,
                        quantity: 0
                    },
                    autoOpenCamera: 0,
                    date_created: '',
                    user: '',
                    items_list: [],
                    status: '',
                    max_items_count: 999,
                    errors: [],
                    action: '',
                    actionResult: {
                        model: 'Outbound',
                        action: ''
                    },
                    loaded: false,
                    userAccess: {
                        create: false,
                    },

                    packing_type_list: [
                        { value: 1, text: 'UOM' },
                        { value: 2, text: 'MB' },
                        { value: 3, text: 'SB' },
                    ],

                    waitForProcess: false,
                    po_id: {
                        selection: [],
                        selected: ''
                    },
                    received_date: null,
                    selected_po_number: '',
                    isDone: true,
                    isViewEdit:false,
                    hasError:false,
                    isDisabledButton:false,
                    listOfNotInModels:[],
                    counterBatch:0,
                    outbound_item_id:'',
                    batch_addition_text:{
                        title: "Batch Addition",
                        quantity_text: "Quantity"
                    },
                    receiving_data:[],
                    outbound_title: "Outbound",
                    disabled_batch_addition: false,
                    loading:{
                        warehouse_selection:true
                    }
                }
        },
        methods:{
            onScanSuccess(decodedText, decodedResult) {

            },
            calculateFinalQty(index) {
                if (this.outbound_items[index].item_id != '') {
                    let multiplier = 0
                    switch (this.outbound_items[index].packing_type) {
                        case 1:
                            multiplier = 1
                            break
                        case 2:
                            multiplier = this.outbound_items[index].item.master_box_qty
                            break
                        case 3:
                            multiplier = this.outbound_items[index].item.small_box_qty
                            break
                    }
                    let quantity_entered = this.outbound_items[index].quantity_entered == '' ? 0 : parseInt(this.outbound_items[index].quantity_entered)
                    this.outbound_items[index].quantity = quantity_entered * multiplier
                }
            },
            checkMBMoq(index) {

                let mb_qty = 0;
                if(this.isViewEdit){
                    mb_qty = this.outbound_items[index].quantity / this.outbound_items[index].item.master_box_qty
                }
                else{
                    mb_qty = this.outbound_items[index].quantity / this.outbound_items[index].master_box_qty
                }

                if (mb_qty % 1 != 0) {
                    swal.fire(
                        ''
                        `UOM qty does not convert to MB qty properly (Result: ${mb_qty}). \n\nPlease check item MB conversion or UOM qty entered`,
                        'warning')
                        .then(result => {
                            this.outbound_items[index].quantity = ''
                            return 0
                        })
                } else {
                    return mb_qty
                }
            },
            myFunction() {
                this.text_area_fields.splice(0, 1);
                this.batch_addition.dialog = true;
            },
            closeBatchAdditionDialog() {
                this.batch_addition.dialog = false;
            },
            closeAddEditDialog(){
                this.add_edit_outbound_dialog = false;
                this.isViewEdit = false;
            },
            openBatchDialog() {
                this.batch_addition.dialog = true;
                this.isDone = true;
                this.text_area_fields = [];

                if(this.text_area_fields.length < 1){
                    this.text_area_fields.push({
                        dataFromExcel: '',
                        dataFromExcel2: '',
                        error:false
                    })
                }
                this.batch_addition.data = [];
                this.listOfNotInModels = [];
                this.hasError = false
            },
            onPaste(evt) {
                this.batch_addition.data = []
                var pastedText = evt.clipboardData.getData('Text');
                if(!pastedText){
                    return false;
                }
                else{
                    var rows = pastedText.split("\r\n")
                    var cells = '';
                    for (var y in rows) {
                        if (y != rows.length - 1) {
                            cells = rows[y].split("\t");
                            this.text_area_fields.push({
                                dataFromExcel: cells[0],
                                dataFromExcel2: cells[1],
                                error:false
                            })
                            if (y == rows.length - 1){
                                this.text_area_fields.push({
                                    dataFromExcel: cells[0],
                                    dataFromExcel2: cells[1],
                                    error:false
                                })
                            }
                            if(this.is_receiving){
                                this.receiving_data.push(Object.assign({},{
                                    model: cells[0].trim(),
                                    qty: cells[1].trim(),
                                    // foc: cells[2],
                                    id: '',
                                    skip: false,
                                }))
                            }
                            else{
                                this.batch_addition.data.push(Object.assign({}, {
                                    model: cells[0].trim(),
                                    qty: cells[1].trim(),
                                    // foc: cells[2],
                                    id: '',
                                    skip: false,
                                }))
                            }
                        }
                    }
                let payload={
                    items: this.text_area_fields.map(e=>{ return e.dataFromExcel}),
                    isChecking:true
                }
                this.$store.dispatch('getItemsByModel',payload).then(response => {
                    response.data = this.GET_ITEMS_BY_MODELS.map(e=>{ return e.model})
                    this.text_area_fields.forEach((e, i)=>{
                        if(response.data.includes(e.dataFromExcel.toUpperCase())){
                        }
                        else{
                            this.listOfNotInModels.push(e.dataFromExcel.toUpperCase());
                            this.text_area_fields[i].error = true;
                        }
                    })
                    if(this.listOfNotInModels.length > 0){
                        this.isDisabledButton = true
                        this.hasError = true
                    }
                });
                this.isDone = false;
                this.closeBatchAdditionDialog();
                setTimeout(this.myFunction, 1);
                }
            },
            submittedBtn() {
                if(this.isViewEdit == true && !this.is_receiving){
                    for (var i = 0; i < this.text_area_fields.length; i++) {
                        this.outbound_items.push({
                            outbound_id: this.editOutboundId,
                            id: this.outbound_num,
                            item_id: this.text_area_fields[i].dataFromExcel.toUpperCase(),
                            quantity: parseInt(this.text_area_fields[i].dataFromExcel2.toString().replace(',','')),
                            quantity_entered:parseInt(this.text_area_fields[i].dataFromExcel2.toString().replace(',','')),
                            remarks: '',
                            packing_type: '',
                            item: {
                                id: '',
                                active: '',
                                model: this.text_area_fields[i].dataFromExcel.toUpperCase(),
                                name: '',
                                type_id: '',
                                item_type_text: '',
                                uom_id: '',
                                item_uom_text: '',
                                master_box_qty: '',
                                small_box_qty: '',
                            },
                            quantity_master_box: '',
                            quantity_entered: parseInt(this.text_area_fields[i].dataFromExcel2.toString().replace(',','')),
                            remarks:'',
                            uom_id:'',
                            quantity_master_box_conv:'',
                            model:this.text_area_fields[i].dataFromExcel,
                            loading_list_item_id: '',
                            item_uom_text: '',
                            item_type_text: '',
                        })

                    }
                    this.fetchAllData();
                    this.counterBatch++;
                    this.batch_addition.dialog = false;
                }
                else if(this.isViewEdit == false && !this.is_receiving){
                    this.isViewEdit = false;
                    if (this.outbound_items.length < this.max_items_count) {
                        if(this.counterBatch == 0){
                            this.outbound_items.pop();
                        }
                        for (var i = 0; i < this.text_area_fields.length; i++) {
                            this.outbound_items.push({
                                item_id: this.text_area_fields[i].dataFromExcel.toUpperCase(),
                                quantity: parseInt(this.text_area_fields[i].dataFromExcel2.toString().replace(',','')),
                                quantity_entered: '',
                                quantity_received: '',
                                remarks: '',
                                packing_type: '',
                                id: '',
                                active: '',
                                model: this.text_area_fields[i].dataFromExcel.toUpperCase(),
                                name: '',
                                type_id: '',
                                item_type_text: '',
                                uom_id: '',
                                item_uom_text: '',
                                master_box_qty: '',
                                small_box_qty: '',
                                quantity_master_box: '',
                                loading_list_item_id: '',
                            })
                        }
                        this.fetchAllData();
                        this.counterBatch++;
                        this.batch_addition.dialog = false;
                        this.disabled_batch_addition = true;
                    }
                }
                if(this.is_receiving){
                    let raw_model = this.text_area_fields.map(e=>{return e.dataFromExcel.toUpperCase()});
                    let counter = 0;
                    let x = 0
                    let outbound_models = this.outbound_items.map(e=>{return e.model})
                    try{
                        while(x < this.text_area_fields.length){
                            this.outbound_items.forEach(e=>{
                                if(counter == this.text_area_fields.length){
                                    this.batch_addition.dialog = false;
                                    x = this.text_area_fields.length;
                                }
                                else if(outbound_models.includes(this.text_area_fields[counter].dataFromExcel.toUpperCase())){
                                    if(raw_model.find(element => element === e.model)){
                                        if(e.model == this.text_area_fields[counter].dataFromExcel.toUpperCase()){
                                            e.quantity_received = this.text_area_fields[counter].dataFromExcel2
                                            counter++;
                                            x++;
                                        }
                                    }
                                }
                                else{
                                    x = (this.text_area_fields.length + 1)
                                }
                            })
                            if(x === (this.text_area_fields.length + 1)){
                                swal.fire("Item not found in this outbound","Please check your item",'error')
                                break;
                            }
                        }

                        this.batch_addition.dialog = false;
                    }
                    catch(e){
                        swal.fire("Someting went wrong",e+"","error")
                        console.log(e)
                    }
                }
            },
            computeMBQty(index) {
                if (this.is_receiving) {
                    // if (this.outbound_items[index].item_id != '') {
                    //     if (this.outbound_items[index].quantity_received > this.outbound_items[index].quantity_master_box) {
                    //         this.outbound_items[index].quantity_received = this.outbound_items[index].quantity_master_box
                    //     }
                    // }
                } else {
                    if (this.outbound_items[index].item_id != '') {
                        this.outbound_items[index].quantity_master_box = this.checkMBMoq(index)
                    } else {
                        this.outbound_items[index].quantity_master_box = 0
                    }
                }
            },
            showEditModal(value, is_receiving = false) {
                this.action = 'Edit'
                this.editOutboundId = value
                this.text_area_fields.length = 0;
                this.batch_addition.data = [];
                this.listOfNotInModels = [];
                this.isViewEdit = true;
                this.disabled_batch_addition = false;
                if(this.is_receiving){
                    this.batch_addition_text.quantity_text = "Receive MB Quantity";
                    this.outbound_title = "Outbound (Receiving)"
                }
                if (this.editOutboundId != null && this.editOutboundId > 0) {
                    this.$store.dispatch('viewOutbound',this.editOutboundId).then(response => {
                        let data = response.data

                        this.outbound_item_id = data.id
                        this.outbound_num = data.outbound_num
                        this.warehouse_id = data.warehouse_id
                        this.loading_list_id = data.loading_list_id
                        this.broker = data.broker
                        this.remarks = data.remarks
                        this.outbound_items = data.outbound_items
                        this.selected_po_number = data.po_id
                        this.status = data.status
                        this.received_date = data.received_date

                        if (!!this.po_id.selection.find(e => e.value === this.selected_po_number)) {
                            this.selected_po_number = this.po_id.selection.find(e => e.value === this.selected_po_number)
                            this.po_id.selected = this.selected_po_number.value
                        }

                        this.add_edit_outbound_dialog = true
                    }).catch(error => {
                        console.log(error)
                    });
                }
            },
            removeItem() {
                if (this.outbound_items.length > 1) {
                    this.outbound_items.pop();
                }
            },
            clearFields() {
                this.isDone = true;
                this.text_area_fields = [];
                if(this.text_area_fields.length < 1){
                    this.text_area_fields.push({
                        dataFromExcel: '',
                        dataFromExcel2: '',
                        error:false
                    })
                }
                this.batch_addition.data = [];
                this.listOfNotInModels = [];
                this.isDisabledButton = false;
                this.hasError = false;
            },
            clearItem(i) {
                let temp = [];
                if(this.text_area_fields.length == 1)
                {
                    this.clearFields();
                }
                else{
                    if(this.listOfNotInModels.length > 1 ){

                    }
                    else{
                        this.isDisabledButton = false;
                    }
                    this.text_area_fields.forEach((e, index) => {
                        if (i != index) {
                            temp.push(e);
                            let checkingOfError = this.listOfNotInModels.includes(this.text_area_fields[i].dataFromExcel)
                            if(checkingOfError){
                                this.listOfNotInModels.pop();
                            }
                        }
                    })
                    this.text_area_fields = temp;
                }
            },
            removeOrderByIndex(index) {
                if(this.outbound_items.length == 1){
                    this.outbound_items = [];
                    this.outbound_items.push({
                        item_id: '',
                        quantity: '',
                        quantity_entered: '',
                        quantity_received: '',
                        remarks: '',
                        packing_type: '',
                        id: '',
                        active: '',
                        model: '',
                        name: '',
                        type_id: '',
                        item_type_text: '',
                        uom_id: '',
                        item_uom_text: '',
                        master_box_qty: '',
                        small_box_qty: '',
                        quantity_master_box: '',
                        loading_list_item_id: '',
                    })
                    return false;
                }
                else{
                    this.outbound_items.splice(index, 1)
                }
            },
            addItem() {
                if (this.outbound_items.length < this.max_items_count) {
                    this.outbound_items.push({
                        item_id: '',
                        quantity: '',
                        quantity_entered: '',
                        quantity_received: '',
                        remarks: '',
                        packing_type: '',
                        id: '',
                        active: '',
                        model: '',
                        name: '',
                        type_id: '',
                        item_type_text: '',
                        uom_id: '',
                        item_uom_text: '',
                        master_box_qty: '',
                        small_box_qty: '',
                        quantity_master_box: '',
                        loading_list_item_id: '',
                    })
                }
            },
            showAddOutbound() {
                this.outbound_items = [];
                this.isDone = true;
                this.text_area_fields.length = 0;
                this.batch_addition.data = [];
                this.listOfNotInModels = [];
                this.isViewEdit = false;
                this.counterBatch = 0;
                this.disabled_batch_addition = false;
                if (this.po_id.selection.find(e => e.value === this.po_id.selected)) {
                    this.selected_po_number = this.po_id.selection.find(e => e.value === this.po_id.selected).text
                }
                this.add_edit_outbound_dialog = true
                this.action = 'Add'
            },
            getWarehouseItemStock(item_id, warehouse_id, index) {
                let payload = {
                    item_id: item_id,
                    warehouse_id: warehouse_id,
                }
                this.$store.dispatch('getWarehouseItemStocks',payload).then(response => {
                    let data = response.data
                    if (data != null) {
                        this.outbound_items[index].stock = data
                    }
                    else{
                        this.outbound_items[index].stock = 0
                    }
                });
            },
            closeQrScannerDialog(confirm) {
                if (confirm) {

                }
                return false
            },
            addNewOutbound() {
                this.waitForProcess = true
                this.errors = []
                this.counterBatch = 0;
                let payload = {
                    outbound_num: this.outbound_num,
                    warehouse_id: this.warehouse_id,
                    loading_list_id: this.loading_list_id,
                    broker: this.broker,
                    remarks: this.remarks,
                    outbound_items: this.outbound_items,
                    po_id: this.po_id.selected,
                }
                this.$store.dispatch('addNewOutbound',payload).then(response => {
                    let data = this.ADD_NEW_OUTBOUND
                    if (data.error) {
                        swal.fire("", data.error, "warning");
                        return false
                    }
                    this.resetFields()
                    this.add_edit_outbound_dialog = false
                    this.action = 'success'
                    swal.fire("", data.msg, "success");
                    this.waitForProcess = false
                    this.$refs.child.getAllOutbound();
                }).catch(error => {
                    if (error) {
                        this.errors = error
                        this.waitForProcess = false
                    }
                    this.waitForProcess = false
                });
            },
            editOutbound() {
                this.waitForProcess = true
                this.errors = []
                let not_received = []
                this.outbound_items.forEach(e=>{
                    if(e.quantity_received == 0){
                        not_received.push(e.model)
                    }
                })
                if(not_received.length > 0 && this.is_receiving){
                    swal.fire({
                        title: 'Some items has 0 Receive MB Quantity',
                        text: ''+not_received.join("\n")+"\n\n Do you still want to continue? \n",
                        icon:"warning",
                        buttons: true,
                    }).then(confirm => {
                        if (confirm) {
                            this.saveEditOutbound();
                            this.$refs.child.getAllOutbound();
                        } else {
                            this.waitForProcess = false
                            return false
                        }
                    })
                }else{
                    this.saveEditOutbound();
                }
            },
            resetFields() {
                this.errors = []
                this.outbound_num = ''
                this.warehouse_id = ''
                this.loading_list_id = ''
                this.outbound_items = []
                this.broker = ''
                this.remarks = ''
                this.user = ''
                this.action = ''
                this.po_id.selected = ''
            },
            addKeyListener() {
                window.addEventListener("keyup", function (e) {
                    var keypressed = String.fromCharCode(e.keyCode)
                    if (e.ctrlKey && e.keyCode == 32) {
                        this.addItem()
                        $("input").blur();

                    } else if (keypressed == '-') {
                        this.removeItem()
                        $("input").blur();
                    }
                }.bind(this));
            },
            async fetchAllData() {
                if(this.is_receiving){
                    return false;
                }
                if(this.isViewEdit == true){
                    let that = this;
                    let duplicate_models = []
                    try {
                        let dataCounter = 0;
                        let models = this.text_area_fields.map(e => {
                            return e.dataFromExcel.toUpperCase();
                        });
                        let mb_qty = this.outbound_items.map(e => {
                            return e.master_box_qty;
                        });

                        let filteredModel = models.filter(function (model, pos) {
                            return models.indexOf(model) != pos;
                        });

                        duplicate_models.push(filteredModel);
                        let showItemsAsString = duplicate_models.join("\r\n");

                        if (filteredModel.length > 0) {
                            swal.fire({
                                title: 'Warning',
                                text: 'Duplicate model detected \n NOTE: Model with duplicates will be automatically removed \n \n' + showItemsAsString.split(',').join("\r\n"),
                                showConfirmButton: true,
                            }).then(result => {
                                if (result.isConfirmed) {

                                } else {
                                    return false
                                }
                            })
                        }
                        let quantities = this.outbound_items.map(e => {
                            return e.quantity;
                        });
                        let quantity_master_boxes = this.outbound_items.map(e => {
                            return e.quantity_master_box;
                        });
                        let payload = {
                            items: models,
                            isChecking:false
                        }
                        this.$store.dispatch('getItemsByModel',payload).then(response => {

                            let designatedIndex = this.outbound_items.length - this.GET_ITEMS_BY_MODELS.length;

                            for (let x = designatedIndex; x < this.outbound_items.length; x++){
                                let data = this.GET_ITEMS_BY_MODELS[dataCounter];

                                this.outbound_items[x].outbound_id               = this.editOutboundId
                                this.outbound_items[x].id                         = this.outbound_item_id
                                this.outbound_items[x].item_id                    = data.item_id
                                this.outbound_items[x].quantity_entered           = that.outbound_items[x].quantity
                                this.outbound_items[x].remarks                    = this.remarks
                                this.outbound_items[x].item_type_text             = data.item_type_text
                                this.outbound_items[x].pending_quantity_received  = that.outbound_items[x].quantity
                                this.outbound_items[x].model                      = data.model
                                this.outbound_items[x].name                       = data.name
                                this.outbound_items[x].quantity_master_box_conv   = that.outbound_items[x].quantity
                                this.outbound_items[x].received_type              = 1
                                this.outbound_items[x].uom_id                     = data.uom_id

                                this.outbound_items[x].item.active                = data.active
                                this.outbound_items[x].item.model                 = data.model
                                this.outbound_items[x].item.name                  = data.name
                                this.outbound_items[x].item.item_type_text        = data.item_type_text
                                this.outbound_items[x].item.type_id               = data.type_id
                                this.outbound_items[x].item.uom_id                = data.uom_id
                                this.outbound_items[x].item.item_uom_text         = data.item_uom_text
                                this.outbound_items[x].item.master_box_qty        = data.master_box_qty
                                this.outbound_items[x].item.small_box_qty         = data.small_box_qty

                                this.outbound_items[x].item.id                    = data.item_id
                                if (this.outbound_items[x].item_id != '') {
                                        let quo = (that.outbound_items[x].quantity / data.master_box_qty)
                                        data.quantity_master_box = quo
                                    } else {
                                        data.quantity_master_box = 0
                                    }
                                this.outbound_items[x].quantity_master_box   = data.quantity_master_box
                                dataCounter++;

                            };
                        });
                    } catch (err) {
                        console.log(err)
                    }
                }
                else{
                    this.isViewEdit = false;
                    let that = this;
                    let duplicate_models = []
                    try {
                        let models = this.text_area_fields.map(e => {
                            return e.dataFromExcel.toUpperCase();
                        });
                        let mb_qty = this.outbound_items.map(e => {
                            return e.master_box_qty;
                        });
                        let filteredModel = models.filter(function (model, pos) {
                            return models.indexOf(model) != pos;
                        });
                        duplicate_models.push(filteredModel);
                        let showItemsAsString = duplicate_models.join("\r\n");
                        if (filteredModel.length > 0) {
                            swal.fire({
                                title: 'Warning',
                                text: 'Duplicate model detected \n NOTE: Model with duplicates will be automatically removed \n \n' + showItemsAsString.split(',').join("\r\n"),
                                buttons: true,
                            }).then(confirm => {
                                if (confirm) {

                                } else {
                                    return false
                                }
                            })
                        }
                        let quantities = this.outbound_items.map(e => {
                            return e.quantity;
                        });
                        let quantity_master_boxes = this.outbound_items.map(e => {
                            return e.quantity_master_box;
                        });
                        let payload = {
                            items: models,
                            isChecking:false
                        }
                        this.$store.dispatch('getItemsByModel',payload).then(response => {
                            that.outbound_items = this.GET_ITEMS_BY_MODELS.filter((e, i) => {
                                e.quantity = quantities[i];
                                e.model = models[i];
                                e.quantity_master_box = quantity_master_boxes[i]
                                if (that.outbound_items[i].quantity % e.master_box_qty == 1) {
                                    swal.fire(
                                        '',
                                        `UOM qty does not convert to MB qty properly. \n\nPlease check item MB conversion or UOM qty entered`,
                                        'warning')
                                        .then(result => {
                                            this.outbound_items[i].quantity = ''
                                            return 0
                                        })
                                    return e;
                                }
                                else {
                                    if (this.outbound_items[i].item_id != '') {
                                        let quo = (that.outbound_items[i].quantity / e.master_box_qty)
                                        e.quantity_master_box = quo
                                    } else {
                                        e.quantity_master_box = 0
                                    }
                                    that.outbound_items[i].quantity = e.quantity
                                    that.outbound_items[i].id = e.id
                                    that.outbound_items[i].item_id = e.model
                                    that.outbound_items[i].master_box_qty = e.master_box_qty
                                    return e;
                                }
                            })
                        });
                    } catch (err) {
                        console.log(err);
                    }
                }
            },
            manualAddItem(){
                this.text_area_fields.push({
                    dataFromExcel: '',
                    dataFromExcel2: '',
                    error:false
                })
            },
            saveEditOutbound(){
                let params = {
                    id:this.editOutboundId,
                    outbound_num: this.outbound_num,
                    warehouse_id: this.warehouse_id,
                    broker: this.broker,
                    remarks: this.remarks,
                    outbound_items: this.outbound_items,
                    po_id: this.po_id.selected,
                    received_type: parseInt(this.received_type)
                }
                this.$store.dispatch('editOutbound',params).then(response => {
                    let data = this.EDIT_OUTBOUND
                    if (data.error) {
                        swal.fire("", data.error, "warning");
                        return false
                    }
                    this.resetFields()
                    this.add_edit_outbound_dialog = false
                    this.editOutboundId = 0
                    this.action = 'success'
                    swal.fire("", 'Outbound Update Successful', "success");
                    this.waitForProcess = false
                }).catch(error => {
                    if (error.response.status == 422) {
                        this.errors = error.this.EDIT_OUTBOUND.errors
                    }
                });
            },
            validateInputModel(index){
                if(this.listOfNotInModels.length != 0){
                    let payload = {
                        items: this.text_area_fields.map(e=>{return e.dataFromExcel.toUpperCase()}),
                        isChecking:true
                    }
                    this.$store.dispatch('getItemsByModel',payload).then(response => {
                        response.data = response.data.map(e=>{return e.model})
                        if(response.data.includes(this.text_area_fields[index].dataFromExcel.toUpperCase())){
                            this.isDisabledButton = false;
                            this.text_area_fields[index].error = false
                        }
                        else{
                            this.text_area_fields[index].error = true
                            this.isDisabledButton = true;
                        }
                    });
                }
                else{
                    let payload = {
                        items: this.text_area_fields.map(e=>{return e.dataFromExcel.toUpperCase()}),
                        isChecking:true
                    }
                    this.$store.dispatch('getItemsByModel',payload).then(response => {
                        response.data = response.data.map(e=>{return e.model})
                        if(response.data.includes(this.text_area_fields[index].dataFromExcel.toUpperCase())){
                            this.text_area_fields[index].error = false
                        }
                        else{
                            this.text_area_fields[index].error = true
                            this.listOfNotInModels.push(this.text_area_fields[index].dataFromExcel.toUpperCase())
                            this.isDisabledButton = true;
                        }
                    });
                }
            },
            getWarehouses(no_virtual = false){
                // let payload = {

                // }
                // this.$store.dispatch('getAllWarehouses',payload).then((response)=>{

                //     this.warehouses = this.GET_ALL_WAREHOUSES
                // });
            },
        },

        computed:{
            ...mapGetters([
                'GET_ITEMS_BY_MODELS',
                'ADD_NEW_OUTBOUND',
                'EDIT_OUTBOUND',
                'USER_ACCESS',
                'GET_ALL_WAREHOUSES',
            ]),
        },
        watch: {
            'GET_ALL_WAREHOUSES':{
            handler(val){

                this.loading.warehouse_selection = false
            },
            add_edit_outbound_dialog: function () {
                if (this.add_edit_outbound_dialog == true) {
                    this.loaded = true

                    if (this.outbound_items.length == 0) {
                        this.addItem();
                    }
                }
            },
            warehouse_id: function (val) {
                if (val != null && val != '' && this.outbound_items.length > 0) {
                    this.outbound_items.forEach((item, index) => {
                        if (item.item_id != null && item.item_id != '') {
                            this.getWarehouseItemStock(item.item_id, this.warehouse_id, index)
                        }
                    })
                }
            },
            USER_ACCESS: {
                handler(val) {
                    if(val != "fail"){
                    val.forEach((e) => {

                    if (e.actions_code == "create") {
                                this.userAccess.create = true;
                            }
                    });
                    }
                }
            },
        },
        mounted(){
            // this.$store.dispatch('getWarehouseItemStocks');
            // this.getWarehouses();
            // this.$store.dispatch('checkAccessUser',this.$router.currentRoute.params.id);
        }
    }
}
    </script>

<style>

</style>
