<template>
  <div>
    <table id="footer-left">
        <tbody>
            <tr v-for="(footer) in footers_details.footerLeft" :key="footer.id">
              <td>
                <p>{{ footer.label.toUpperCase() }}:
                  <br />
                  <br />
                  <br />
                  <span>{{ footer.value }}</span>
                  <br />
                </p>
              </td>
            </tr>
        </tbody>
    </table>
    <table id="footer-right">
        <tbody>
            <tr v-for="(footer) in footers_details.footerRight" :key="footer.id">
                <td>
                  <p>{{ footer.label.toUpperCase() }}: 
                    <br />
                    <br />
                    <br />
                    <span>{{ footer.value }}</span>
                    <br />
                  </p>
                </td>
            </tr>
        </tbody>
    </table>
  </div>
</template>

<script>
  // SAMPLE DATA FORMAT TO BE FOLLOW IN USING THIS COMPONENT
  // footers: [
  //     {
  //         id:1,
  //         label:'Processed by',
  //         value: '',
  //     }, 
  //     {
  //         id:2,
  //         label: 'RECEIVED/DOUBLE CHECKED BY',
  //         value: 'Mr. John dela Cruz'
  //     },
  //     {
  //         id:3,
  //         label: 'CHECKED BY',
  //         value: ''
  //     },
  //     {
  //         id: 4,
  //         label: 'CHECKED BY',
  //         value: '',
  //     },
  // ],

// WAY TO RENDER IT TO PDF
// YOU HAVE TO INCLUDE THE FOLLOWING INTO YOUR FUNCTION
// let bodyEndPosition = pdf.lastAutoTable.finalY;
// pdf.autoTable({
//     html: '#footer-left',
//     theme:"plain",
//     margin: {right:107},
//     startY: bodyEndPosition + 80,
//     showHead: 'firstPage',
//     styles: {
//         fontSize:10,
//         overflow:'hidden',
//     },
// })
// pdf.setPage(pageNumber)
// pdf.autoTable({
//     html: '#footer-right',
//     theme:"plain",
//     margin: {left: 137},
//     startY: bodyEndPosition + 80,
//     showHead: 'firstPage',
//     styles: {
//         fontSize:10,
//         overflow:'hidden',
//     },
// }) 
export default {
  props: { 
    footers: {
      type: Array,
      dafault: () => [],
    },
  },
  computed: {
    footers_details() {
      const footers = this.footers;
      const footersLength = this.footers.length;
      const footerLeft = [];
      const footerRight = [];
      if (footersLength % 2 === 0)
      {
        for (let foot = 0; foot < footers.length / 2; foot++)
        {
          if (footers[foot].value === '')
          {
            footers[foot].value = '______________________________';
          }

          footerLeft.push(footers[foot]);
        }
        for (let foot = footers.length / 2; foot < footers.length; foot++)
        {
          if (footers[foot].value === '')
          {
            footers[foot].value = '______________________________';
          }

          footerRight.push(footers[foot]);
        }
      }

      if (footersLength % 2 === 1)
      { 
        for ( let foot = 0; foot < (footers.length / 2) + .5; foot++)
        {
          if (footers[foot].value === '')
          {
            footers[foot].value = '______________________________';
          }
          footerLeft.push(footers[foot]);
        }
        for (let foot = (footers.length / 2) + .5; foot < footers.length; foot++ )
        {
          if (footers[foot].value === '')
          {
            footers[foot].value = '______________________________';
          }
          footerRight.push(footers[foot]);
        }
      }
      return {
        footerLeft,
        footerRight,
      }
    },
    footers_data() {
      this.footers;
    }
  }
}
</script>

<style>

</style>