<template>
  <div class="tabs-body-color">
    <v-container fluid class="pa-0">
      <v-row class="pt-4 pl-2">
        <v-col cols="12" class="d-flex no-block align-items-center">
          <div class="btn-toolbar pl-0">
            <!-- <v-btn class="ml-1 mb-2" @click="openViewDialog()">
              <v-icon>mdi-plus</v-icon>ADD
            </v-btn> -->
          </div>
        </v-col>
      </v-row>
      <v-row>
        <ChequeVoucherTableComponent/>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import ChequeVoucherTableComponent from '@/views/main/modules/Accounting/ChequeVoucher/ChequeVoucherTableComponent.vue';
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';

export default {
  mixins: [
    SharedFunctionsComponentVue,
  ],

  components: {
    ChequeVoucherTableComponent,
  },

  created() {
    this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id);
  },

  data() {
    return {
      userAccess:{
        create:false
      },
    }
  },

  methods: {
    // openViewDialog(){
    //   this.$store.commit("DIALOG", true);
    //   this.$store.state.chequevoucher.chequeVoucherFormLoaded = true;
    //   this.$store.state.chequevoucher.chequeVoucherViewAction = 'create';
    //   this.$store.state.chequevoucher.chequeVoucherViewActionWithTable = false;
    // },
    openViewDialog(){
      this.$store.commit("DIALOG", true)
      this.$store.commit("CV_LOAD", true)
      this.$store.commit("CV_ACTION", 'create')
    },
  },
};
</script>
