<template>

        <v-container class="container tabs-body-color ma-0 pa-0">
            <!-- NEW DEBIT MEMO -->
            <v-row class="col-12">
                <v-col cols="12" class="d-flex no-block align-items-center">
                    <div class="btn-toolbar mt-2">
                        <v-btn v-if="userAccess.create" @click="openDebitMemoTypeDialog()">
                            <v-icon>mdi-plus</v-icon>New
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <!-- DEBIT MEMO TYPE SELECTION -->
            <v-dialog v-model="debitMemoType.dialog" scrollable persistent max-width="30%" style="z-index: 30">
                <v-card>
                    <v-progress-linear v-if="loading.state" indeterminate color="green"></v-progress-linear>
                    <v-row class="m-0">
                        <v-col>
                            <v-card-title>
                                <span class="text-lg font-semibold" v-text="`Create Debit Memo For`"></span>
                            </v-card-title>
                        </v-col>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right" @click="closeDebitMemoTypeDialog()">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-card-text style="max-height: 460px; border: 1px solid #e0e0e0; padding: 0">
                        <v-row v-if="loading.state" tag="div" align="center" no-gutters class="px-2" v-text="loading.title">
                        </v-row>
                        <v-row v-if="!loading.state" tag="div" align="center" no-gutters class="px-2 my-2">
                            <v-col class="d-flex pr-2" cols="10">
                                <v-row dense no-gutters tag="div">
                                    <v-col dense tag="div" sm="12" class="d-flex justify-start">
                                    </v-col>
                                    <v-col dense tag="div" sm="12" class="my-1">
                                        <v-autocomplete hide-details item-text="text" item-value="value"
                                            :items="GET_DEBIT_MEMO_TYPES" v-model="debitMemoType.value" dense
                                            auto-select-first
                                            return-object
                                            @change="selectedDMType()"
                                            label="Debit Memo Type" outlined flat></v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions class="d-flex justify-end">
                        <v-btn small :disabled="!debitMemoType.value || loading.state" @click="editedIndex = -1; dialog = true; showNewDebitMemoDialog(); debitMemoType.dialog = false">
                            <v-icon class="m-0">mdi-plus</v-icon>
                            Create
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- CREATE/EDIT DEBIT MEMO -->
            <v-dialog v-model="dialog" scrollable persistent max-width="50%" style="z-index: 50; position: relative;">
                <!-- <div v-if="packingListItem.dialog" style="background-color: rgba(47,47,47,0.5); position:absolute; width:100%; height:100%; z-index: 60;"></div> -->
                <form>
                    <v-card>
                        <v-row class="m-0">
                            <v-col cols="8">
                                <v-card-title>
                                    <span v-if="editedIndex != null" class="text-lg font-semibold" v-text="editedIndex < 0 ? `Create Debit Memo | ${debitMemoType.value.text}` : (editedIndex === 1 ? `View` : `Edit`) + ` Debit Memo : ${editedDebitMemo.debit_memo_num} | ${editedDebitMemo.debit_memo_type}`"></span>
                                    <span v-if="editedIndex === null" class="text-lg font-semibold" v-text="editedDebitMemo.debit_memo_num"></span>
                                </v-card-title>
                            </v-col>
                            <v-col cols="pull-right 4">
                                <v-btn text icon color="gray" class="float-right" @click="closeDialog()">
                                    <v-icon>mdi-close-circle</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-progress-linear
                            v-if="loading.state"
                            indeterminate
                            color="green"
                        ></v-progress-linear>
                        <v-card-text style="border:1px solid #e0e0e0;">
                            <div class="card-body">
                                <v-row style="text-align:end" class="align-items-center d-flex justify-end mr-2 mb-1">
                                    <v-col dense cols="5" class="text-right">
                                        <div style="display: flex; float:right;" class="pa-2">
                                            <FileUploadComponentVue v-if="addEditDialogAction == 'new' || addEditDialogAction == 'edit'" ref="fileUpload" @uploadedData="uploadedData" @reset="clearFileUpload" />
                                            <v-divider v-if="addEditDialogAction == 'new' || addEditDialogAction == 'edit'" class='mx-2' vertical></v-divider>
                                            <BaseCameraCapture v-if="addEditDialogAction == 'new' || addEditDialogAction == 'edit'" ref="cameraCapture" @capturedImages="getImages"></BaseCameraCapture>

                                            <BaseFilesViewerVue class="mx-2 mt-1" :debitMemoId="debitMemoId" :delete="addEditDialogAction == 'edit'" />
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row tag="div" align="start" class="mx-1">
                                    <v-col dense cols="6">
                                        <v-row dense no-gutters tag="div" class="d-flex flex-row align-item-center justify-content-center">
                                            <v-col dense tag="div" cols="12">
                                                <v-autocomplete outlined dense
                                                    :items="GET_CUSTOMERS_LIST_SELECTION"
                                                    v-model="customer.value"
                                                    item-value="value"
                                                    auto-select-first
                                                    item-text="text"
                                                    label="Customer"
                                                    @input="onSelectCustomer()"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col dense tag="div" cols="12">
                                                <v-autocomplete outlined dense
                                                    v-model="editedDebitMemo.billing_address_id"
                                                    :items="customer.billing_address_list"
                                                    :disabled=" editedIndex === 1"
                                                    auto-select-first
                                                    item-value="value"
                                                    item-text="text"
                                                    label="Billing Address"
                                                    @input="onSelectBillingAddress()"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col dense tag="div" cols="12">
                                                <v-row dense no-gutters tag="div">
                                                    <v-col dense tag="div" cols="6">
                                                        <v-text-field
                                                            outlined
                                                            dense
                                                            label="Contact Person"
                                                            class="w-100"
                                                            v-model="editedDebitMemo.billing_contact_person"
                                                            :disabled="loading.state || editedIndex === 1"/>
                                                    </v-col>
                                                    <v-col dense tag="div" cols="6">
                                                        <v-text-field
                                                            outlined
                                                            dense
                                                            label="Contact Number"
                                                            class="w-100"
                                                            v-model="editedDebitMemo.billing_contact_number"
                                                            :disabled="loading.state || editedIndex === 1"/>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col dense cols="6">
                                        <v-row dense no-gutters tag="div">
                                            <v-col dense tag="div" cols="12" class="mt-0 mb-2">
                                                <v-row dense no-gutters tag="div">
                                                    <v-col dense tag="div" cols="3"></v-col>
                                                    <v-col dense tag="div" cols="3">
                                                        <v-menu
                                                            :disabled="loading.state || editedIndex === 1"
                                                            :close-on-content-click="true"
                                                            :nudge-right="40"
                                                            v-model="soaPicker"
                                                            transition="scale-transition"
                                                            min-width="290px">
                                                            <template v-slot:activator="{ on }">
                                                                <v-text-field
                                                                    outlined
                                                                    dense
                                                                    class="w-100"
                                                                    label="SOA Month"
                                                                    v-model="editedDebitMemo.soa_month_year"
                                                                    readonly
                                                                    v-on="on"/>
                                                            </template>
                                                            <v-date-picker type="month" v-model="editedDebitMemo.soa_month_year" @input="formatDate('soa_month_year', editedDebitMemo.soa_month_year)"></v-date-picker>
                                                        </v-menu>
                                                    </v-col>
                                                    <v-col dense tag="div" cols="6">
                                                        <v-menu
                                                            :disabled="loading.state || editedIndex === 1"
                                                            :close-on-content-click="false"
                                                            :nudge-right="40"
                                                            v-model="datePicker"
                                                            transition="scale-transition"
                                                            min-width="290px">
                                                            <template v-slot:activator="{ on }">
                                                                <v-text-field
                                                                    outlined
                                                                    dense
                                                                    label="Date Prepared"
                                                                    class="w-100"
                                                                    v-model="editedDebitMemo.debit_memo_date_long"
                                                                    readonly
                                                                    v-on="on"/>
                                                            </template>
                                                            <v-date-picker v-model="editedDebitMemo.debit_memo_date" @input="formatDate('debit_memo_date', editedDebitMemo.debit_memo_date)"></v-date-picker>
                                                        </v-menu>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col dense tag="div" cols="12" class="mb-3">
                                                <v-row dense no-gutters tag="div">
                                                    <v-col dense tag="div" cols="3"></v-col>
                                                    <v-col dense tag="div" cols="9">
                                                        <v-textarea
                                                            outlined
                                                            dense
                                                            no-resize
                                                            class="w-100 mt-1 mb-1"
                                                            label="Remarks"
                                                            v-model="editedDebitMemo.remarks"
                                                            :readonly="loading.state || editedIndex === 1">
                                                        </v-textarea>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col dense tag="div" cols="12" v-if="editedDebitMemo.rejection_remarks != null">
                                                <v-row dense no-gutters tag="div">
                                                    <v-col dense tag="div" cols="3"></v-col>
                                                    <v-col dense tag="div" cols="9">
                                                        <label for="customer_id" style="color:red;" class="float-left control-label col-form-label">Rejection Remarks</label>
                                                        <textarea
                                                            multiple
                                                            v-model="editedDebitMemo.rejection_remarks"
                                                            class="form-control"
                                                            style="resize: vertical"
                                                            readonly>
                                                        </textarea>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-simple-table fixed-header dense class="overflow-y-auto" style="border:1px solid #e0e0e0; max-height: 283px;">
                                    <template v-slot:default>
                                        <thead>
                                        <tr>
                                            <th class="text-left">PARTICULARS</th>
                                            <th class="text-right" width="18%">AMOUNT</th>
                                            <th class="text-right" width="9%" v-if="editedIndex < 1">
                                                <v-btn
                                                    fab
                                                    class="mr-1"
                                                    dark
                                                    small
                                                    color="primary"
                                                    style="height:20px; width:20px;"
                                                    @click="addLine()"
                                                    v-if="editedIndex < 1"
                                                >
                                                    <v-icon dark small style="font-size:12px">
                                                        mdi-plus
                                                    </v-icon>
                                                </v-btn>
                                                <v-btn
                                                    fab
                                                    dark
                                                    small
                                                    color="primary"
                                                    style="height:20px; width:20px;"
                                                    @click="removeLine()"
                                                    v-if="editedIndex < 1"
                                                >
                                                    <v-icon dark small style="font-size:12px">
                                                        mdi-minus
                                                    </v-icon>
                                                </v-btn>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in editedDebitMemoItems.data" :key="item.id" class="font-weight-medium text-gray py-2">
                                                <td>
                                                    <v-text-field
                                                        outlined
                                                        dense
                                                        class="w-100 mt-2 mb-1"
                                                        label="Particulars"
                                                        v-model="item.remarks"
                                                        @click="$event.target.focus()"
                                                        @focus="$event.target.select()"
                                                        :disabled="loading.state || editedIndex === 1" />
                                                </td>
                                                <td>
                                                    <v-text-field
                                                        outlined
                                                        dense
                                                        class="w-100 mt-2 mb-1"
                                                        label="Amount"
                                                        v-model="item.total_amount"
                                                        @click="$event.target.focus()"
                                                        @focus="$event.target.select()"
                                                        @blur="formatNumber(item.total_amount, index)"
                                                        :disabled="loading.state || editedIndex === 1" />
                                                </td>
                                                <td class="text-right" v-if="editedIndex < 1">
                                                    <v-btn
                                                        fab
                                                        dark
                                                        small
                                                        color="primary"
                                                        style="height:20px; width:20px;"
                                                        @click="removeLine(index, item.id)"
                                                        v-if="editedIndex < 1"
                                                    >
                                                        <v-icon dark small style="font-size:12px">
                                                            mdi-minus
                                                        </v-icon>
                                                    </v-btn>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                                <v-row class="m-0 my-2">
                                    <v-spacer></v-spacer>
                                    <v-col sm="12" md="4" class="pull-right text-right mt-1 mb-2">
                                        <h5>Total Amount: &#x20b1;{{ this.debit_memo_total_amount | currency }}</h5>
                                    </v-col>
                                </v-row>
                                <div class="">
                                    <v-row class="">
                                        <v-col sm="12" md="5" class="pull-right text-left">
                                            <v-btn
                                                v-if="editedIndex === 1 && editedDebitMemo.status === 1 && userAccess.approve_acctg"
                                                :disabled="loading.state || editedDebitMemoItems.data.length == 0"
                                                @click="showRejectDialog()"
                                                color="error"
                                                class="mx-2 my-2">
                                                    REJECT
                                            </v-btn>
                                        </v-col>
                                        <v-col sm="12" md="7" class="pull-right text-right">
                                            <v-btn
                                                v-if="editedIndex === -1"
                                                :disabled="loading.state || editedDebitMemoItems.data.length == 0"
                                                @click="saveNewDebitMemo()"
                                                class="mr-1 mt-4">
                                                    <v-icon class="mr-1">mdi-plus</v-icon>
                                                    Save DM
                                            </v-btn>
                                            <v-btn
                                                v-if="editedIndex === 0"
                                                :disabled="loading.state || editedDebitMemoItems.data.length == 0"
                                                @click="updateDebitMemo(editedDebitMemo.id)"
                                                class="mr-1 mx-2 my-2">
                                                    <v-icon class="mr-1">mdi-plus</v-icon>
                                                    Update DM
                                            </v-btn>
                                            <v-btn
                                                v-if="editedIndex === 1 && editedDebitMemo.status === 0"
                                                :disabled="loading.state || editedDebitMemoItems.data.length == 0"
                                                @click="approveDebitMemo(1)"
                                                class="mx-2 my-2">
                                                    Submit For Approval
                                            </v-btn>
                                            <v-btn
                                                v-if="editedIndex === 1 && editedDebitMemo.status === 1 && userAccess.approve_acctg"
                                                :disabled="loading.state || editedDebitMemoItems.data.length == 0"
                                                @click="approveDebitMemo(3)"
                                                class="mx-2 my-2">
                                                    Approval (Accounting)
                                            </v-btn>
                                            <!-- <v-btn
                                                v-if="editedIndex === 1 && editedDebitMemo.status === 2 && userAccess.approve_exec"
                                                :disabled="loading.state || editedDebitMemoItems.data.length == 0"
                                                @click="approveDebitMemo(3)"
                                                class="mr-1">
                                                    Approval (Exec)
                                            </v-btn> -->
                                            <v-btn
                                                v-if="editedIndex === 1 && editedDebitMemo.status === 3"
                                                :disabled="loading.state || editedDebitMemoItems.data.length == 0"
                                                @click="printDebitMemo()"
                                                class="mr-1 mt-4">
                                                    <v-icon class="mr-1">mdi-plus</v-icon>Print DM
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </v-card-text>

                        <v-dialog v-model="rejection_dialog" scrollable persistent max-width="400">
                            <v-card>
                            <v-row class="m-0">
                                <v-col>
                                    <v-card-title>
                                        <span class="headline">Rejection Remarks</span>
                                    </v-card-title>
                                </v-col>
                                <v-col cols="pull-right-10 p-2">
                                    <v-btn text icon color="gray" class="float-right" @click="closeRejectDialog()">
                                        <v-icon>mdi-close-circle</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-divider class="mt-0" />
                            <v-card-text>
                                <v-row>
                                    <textarea
                                        v-model="rejection_remarks"
                                        class="form-control d-inline-block"
                                        style="resize: vertical"
                                        >
                                    </textarea>
                                </v-row>
                            </v-card-text>
                            <v-divider />

                            <div style="text-align:center" class="pb-4">
                                <v-btn @click="rejectDebitMemo()">Submit</v-btn>
                            </div>
                            </v-card>
                        </v-dialog>

                    </v-card>
                </form>
            </v-dialog>

            <!-- DEBIT MEMO LIST -->
            <v-row class="ma-0">
                <v-col class="col-12">
                    <v-progress-linear
                        v-if="loading.state"
                        indeterminate
                        color="green"
                    ></v-progress-linear>
                    <v-card>
                        <v-container fluid class="p-0">
                            <v-row class="mx-auto pt-3 my-2">
                                <v-col sm="12" md="2" class="pl-3 d-flex align-content-center">
                                    <v-autocomplete
                                        outlined
                                        dense
                                        label="Customer"
                                        :items="GET_CUSTOMERS_LIST_SELECTION"
                                        v-model="customer.search"
                                        item-value="value"
                                        item-text="text"
                                        class="d-inline-block"
                                        return-object
                                        auto-select-first
                                    >
                                    </v-autocomplete>
                                </v-col>
                                <v-col lg="2" sm="12" md="2" class="d-flex align-content-center">
                                    <v-menu
                                        v-model="debitMemoList.datatable.filters.soa_month.menu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                v-model="debitMemoList.datatable.filters.soa_month.values"
                                                prepend-inner-icon="mdi-calendar"
                                                outlined
                                                dense
                                                label="SOA Month"
                                                readonly
                                                clearable
                                                v-on="on"
                                                style="display: block"
                                                hide-details
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker type="month" v-model="debitMemoList.datatable.filters.soa_month.values" @click:month="debitMemoList.datatable.filters.soa_month.menu = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col lg="2" sm="12" md="2" class="d-flex align-content-center" >
                                    <v-menu v-model="debitMemoList.datatable.filters.soa_month_to.menu"
                                        :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                                        min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="debitMemoList.datatable.filters.soa_month_to.values" prepend-inner-icon="mdi-calendar" outlined dense readonly label="SOA Month To" clearable v-on="on" style="display: block" hide-details></v-text-field>
                                        </template>
                                        <v-date-picker type="month" v-model="debitMemoList.datatable.filters.soa_month_to.values" @click:month="debitMemoList.datatable.filters.soa_month_to.menu = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col sm="12" md="2">
                                    <v-autocomplete
                                        outlined
                                        dense
                                        hide-details
                                        auto-select-first
                                        :items="GET_DEBIT_MEMO_TYPES"
                                        v-model="debitMemoList.datatable.filters.dm_type" item-value="value" label="DM Type" item-text="text"
                                        clearable>
                                    </v-autocomplete>
                                </v-col>
                                <v-col sm="12" md="2" class="d-flex align-content-center">
                                    <v-btn @click="getAllDebitMemos()">Filter</v-btn>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col sm="12" md="2" class="pr-3 text-right">
                                    <a href="#" class="badge badge-all ml-1" @click="debitMemoList.datatable.status_id=-1; getAllDebitMemos()">all</a>
                                    <a href="#" class="badge badge-pending ml-1" @click="debitMemoList.datatable.status_id=0; getAllDebitMemos()">pending</a>
                                    <a href="#" class="badge badge-for-accounting ml-1" @click="debitMemoList.datatable.status_id=1; getAllDebitMemos()">for accounting</a>
                                    <a href="#" class="badge badge-for-exec ml-1" @click="debitMemoList.datatable.status_id=2; getAllDebitMemos()">for exec</a>
                                    <a href="#" class="badge badge-approved ml-1" @click="debitMemoList.datatable.status_id=3; getAllDebitMemos()">approved</a>
                                    <a href="#" class="badge badge-completed ml-1" @click="debitMemoList.datatable.status_id=4; getAllDebitMemos()">completed</a>
                                    <!-- <a href="#" class="badge badge-rejected" @click="debitMemoList.datatable.status_id=5; getAllDebitMemos()">rejected</a> -->
                                    <a href="#" class="badge badge-canceled ml-1" @click="debitMemoList.datatable.status_id=6; getAllDebitMemos()">canceled</a>
                                    <!-- <a href="#" class="badge badge-deleted" @click="debitMemoList.datatable.status_id=7; getAllDebitMemos()">deleted</a> -->
                                </v-col>

                            </v-row>
                        </v-container>
                        <v-container fluid>
                            <v-row class="mb-2 mr-2">
                                <v-spacer></v-spacer>
                                <v-col sm="12" md="4" class="mr-3 align-items-end">
                                    <v-text-field
                                        v-model="debitMemoList.datatable.search"
                                        label="Search DM# / Remarks"
                                        single-line
                                        hide-details
                                        append-icon="mdi-magnify"
                                        @click:append="getAllDebitMemos()"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-col cols="12">
                                <v-data-table
                                    dense
                                    v-model="dataTable"
                                    :search="debitMemoList.datatable.search"
                                    :headers="debitMemoList.datatable.headers"
                                    :items="debitMemoList.data"
                                    :loading="!debitMemoList.datatable.loaded"
                                    :options.sync="pagination.options"
                                    :server-items-length="debitMemoList.datatable.total"
                                    :show-select="showCheckbox"
                                    @input="getSelectedID"
                                    class="w-100"

                                >

                                    <template v-slot:[`item.status`]="{ item }">
                                        <span class="badge badge-pending" v-if="item.status_text=='pending'">{{ item.status_text }}</span>
                                        <span class="badge badge-for-accounting" v-if="item.status_text=='for accounting'">{{ item.status_text }}</span>
                                        <span class="badge badge-for-exec" v-else-if="item.status_text=='for exec'">{{ item.status_text }}</span>
                                        <span class="badge badge-approved" v-else-if="item.status_text=='approved'">{{ item.status_text }}</span>
                                        <span class="badge badge-completed" v-else-if="item.status_text=='completed'">{{ item.status_text }}</span>
                                        <span class="badge badge-rejected" v-else-if="item.status_text=='rejected'">{{ item.status_text }}</span>
                                        <span class="badge badge-canceled" v-else-if="item.status_text=='canceled'">{{ item.status_text }}</span>
                                        <span class="badge badge-dark" v-else-if="item.status_text=='deleted'">{{ item.status_text }}</span>
                                    </template>
                                    <template v-slot:[`item.total_amount`]="{ item }">
                                        <span class="text-nowrap">
                                            &#x20b1;{{ item.total_amount | currency }}
                                        </span>
                                    </template>
                                    <template v-slot:[`item.printed`]="{ item }">
                                        <span class="badge bg-primary" v-if="item.printed">Yes</span>
                                        <span class="badge bg-danger" v-else>No</span>
                                    </template>
                                    <template v-slot:[`item.action`]="{ item }">
                                        <span class="text-nowrap">
                                            <v-btn text icon color="orange" v-if="userAccess.view">
                                                <v-icon class="btn-action" small @click="viewDebitMemo(item.id)">mdi-eye</v-icon>
                                            </v-btn>
                                            <v-btn text icon color="blue" v-if="userAccess.edit && item.status === 0">
                                                <v-icon class="btn-action" small @click="editDebitMemo(item.id)">mdi-pencil</v-icon>
                                            </v-btn>
                                            <!-- <v-btn text icon color="red" v-if="userAccess.delete">
                                                <v-icon class="btn-action" small @click="deleteDebitMemo(item.id)">fas fa-trash-alt</v-icon>
                                            </v-btn> -->
                                            <v-btn text icon color="red" v-if="userAccess.cancel && (item.status !== 6)">
                                                <v-icon class="btn-action" small @click="cancelDebitMemo(item.id)">mdi-cancel</v-icon>
                                            </v-btn>
                                        </span>
                                    </template>
                                </v-data-table>
                                <div v-if="convertBtn" class="table-footer-prepend d-flex pl-2 align-center" style="margin-top:-52px; height:58px;">
                                    <v-btn :loading="btn_loader" v-if="debitMemoList.datatable.status_id == 0  && userAccess.view" @click="updateDMStatus(1)">SUBMIT FOR APPROVAL</v-btn>
                                    <v-btn :loading="btn_loader" v-if="debitMemoList.datatable.status_id == 1  && userAccess.view" @click="updateDMStatus(3)">APPROVAL (ACCOUNTING)</v-btn>
                                    <!-- <v-btn :loading="btn_loader" v-if="debitMemoList.datatable.status_id == 2  && userAccess.view" @click="updateDMStatus(3)">APPROVAL (EXEC)</v-btn> -->
                                    <v-btn :loading="btn_loader" v-if="debitMemoList.datatable.status_id == 3  && userAccess.view" @click="printDM()">PRINT DM</v-btn>
                                </div>
                            </v-col>
                        </v-container>
                    </v-card>
                </v-col>
            </v-row>

            <v-container fluid style="display:none">
                <div id="ctrl_num_label_print" style="font-weight:bold">
                    Control No.:
                </div>
                <div id="ctrl_num_value_print" style="font-weight:bold">
                    {{ 'DM# ' + print.debit_memo_num }}
                </div>

                <div>
                    <table id="details_print">
                        <tbody>
                            <tr>
                                <td>SOA Month:</td>
                                <td>{{ print.soa_month_year}}</td>
                                <td colspan="2" style="font-weight:bold">Date Prepared</td>
                                <td>{{ print.debit_memo_date }}</td>

                            </tr>
                            <tr>
                                <td style="font-weight:bold">Customer:</td>
                                <td colspan="4">{{ print.customer_name }}</td>
                            </tr>
                            <tr>
                                <td style="font-weight:bold">Address:</td>
                                <td colspan="4">{{ print.billing_address_text }}</td>
                            </tr>
                            <tr>
                                <td style="font-weight:bold">Contact Person:</td>
                                <td>{{ print.billing_contact_person }}</td>
                                <td colspan="2" style="font-weight:bold">Contact Number:</td>
                                <td>{{ print.billing_contact_number }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div>
                    <table id="items_print">
                        <thead>
                            <tr>
                                <td colspan="4" style="font-weight:bold; text-align:center">PARTICULARS</td>
                                <td style="font-weight:bold; text-align:center">AMOUNT</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in print.debit_memo_items" :key="index">
                                <td colspan="4">{{ item.remarks }}</td>
                                <td v-if="item.id != null">{{ item.total_amount | currency }}</td>
                                <td v-else>{{  }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div>
                    <table id="totals_print">
                        <tbody>
                            <tr>
                                <td colspan="4">AMOUNT FOR DM</td>
                                <td>{{ print.total_amount | currency }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div>
                    <table id="signatories_print">
                        <tbody>
                            <tr>
                                <td>Processed by:</td>
                                <td><br><br>{{ print.created_by_name }}</td>
                                <td colspan="2">Checked by:</td>
                                <td><br><br>{{ print.acct_approved_by_name }}</td>
                            </tr>
                            <tr>
                                <td>Approved by:</td>
                                <td><br><br>{{ print.exec_approved_by_name }}</td>
                                <td colspan="2">Received by:</td>
                                <td><br><br>{{ }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div id="footer_1_print" style="font-size:10px">
                    <span style="margin-right:16px"><i>WHITE - ACCTG COPY</i></span>
                </div>

                <div id="footer_2_print" style="font-size:10px">
                    <span><i>PINK - CUSTOMER COPY</i></span>
                </div>
            </v-container>

            <!-- PRINT DEBIT MEMO -->
            <DebitMemoPrintComponentVue
                :debit_memo="editedDebitMemo"
                :debit_memo_items="editedDebitMemoItems.print_data"
                @getAllDebitMemos="getAllDebitMemos"
                @closeDialog="closeDialog"
                v-if="print_debit_memo"
            ></DebitMemoPrintComponentVue>
        </v-container>

</template>

<script>
    import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
    import BaseFilesViewerVue from '../Base/BaseFilesViewer.vue';
    import FileUploadComponentVue from '@/views/main/Shared/FileUploadComponent.vue';
    import DebitMemoPrintComponentVue from './DebitMemoPrintComponent.vue';
    import BaseCameraCapture from "../../Services/Base/BaseCameraCapture.vue";
    import { mapGetters } from 'vuex';
    import swal from 'sweetalert2';

    export default {
        mixins: [SharedFunctionsComponentVue],
        components: {
            DebitMemoPrintComponentVue,
            BaseFilesViewerVue,
            FileUploadComponentVue,
            BaseCameraCapture
        },
        props: [
            'status_text',
            'print'
        ],
        mounted: function() {
            // this.debitMemoList.datatable.search = (Uri.hasValueAndProperty('hash', 'debit_memo_num')) ? Uri.toObject('hash')['debit_memo_num'] : '';
            // this.$store.dispatch('checkAccessUser',this.$router.currentRoute.params.id);
            // this.checkAccess('AC', 'debit_memos', 'view')
            // this.checkAccess('AC', 'debit_memos', 'create')
            // this.checkAccess('AC', 'debit_memos', 'edit')
            // this.checkAccess('AC', 'debit_memos', 'delete')
            // this.checkAccess('AC', 'debit_memos', 'cancel')
            // this.checkAccess('AC', 'debit_memos', 'approve_acctg')
            // this.checkAccess('IT', 'debit_memos', 'approve_exec')

            // this.getCustomers()
            this.getAllDebitMemos()
            this.checkAccess()
        },
        data (){
            return {
                rejection_dialog:false,
                rejection_remarks:'',
                reject_id:'',

                loading: {
                    title: 'Please wait',
                    state: false,
                    customer_selection          : true,
                    customer_address_selection  : true,
                },

                pagination: {
                    options: {},
                },

                soaPicker: false,
                datePicker: false,

                print_debit_memo: false,

                editedIndex: -1,
                editedDebitMemo: {
                    id: null,
                    type: null,
                    debit_memo_num: null,
                    customer_id: null,
                    customer_name: null,
                    billing_address_id: null,
                    billing_address_text: null,
                    billing_contact_person: null,
                    billing_contact_number: null,
                    debit_memo_date: null,
                    debit_memo_date_long: null,
                    total_amount: null,
                    remarks: null,
                    rejection_remarks: null,
                    soa_id: null,
                    soa_month_year: null,
                    soa_month: null,
                    soa_year: null,
                    status: null,
                    printed: null,
                    created_by_name: null,
                    acct_approved_by_name: null,
                    exec_approved_by_name: null,
                    debit_memo_type:null
                },

                editedDebitMemoItems: {
                    datatable: {
                        search: '',
                        headers: [
                            {text: 'PARTICULARS', value: 'remarks'},
                            {text: 'AMOUNT', align: 'right', value: 'total_amount'},
                        ],
                    },
                    data: [],
                    print_data: [],
                },

                customer: {
                    selection: [],
                    billing_address_list: [],
                    value: null,
                    text: null,
                    search: '',
                },

                soa: {
                    selection: [],
                    value: null,
                },

                debitMemoList: {
                    datatable: {
                        total: 0,
                        search: '',
                        filters: {
                            soa_month: {
                                // values: this.$dayjs().format('YYYY-MM'),
                                values: null,
                                menu: false,
                            },
                            soa_month_to:{
                                values: null,
                                menu: false,
                            },
                            dm_type:''
                        },
                        headers: [
                            {text: 'DM#', value: 'debit_memo_num',align:'center'},
                            {text: 'DM DATE', value: 'debit_memo_date',align:'center'},
                            {text: 'DM Type', value: 'debit_memo_type_by_name',align:'center'},
                            {text: 'CUSTOMER', value: 'customer_company_name',align:'center'},
                            {text: 'REMARKS', value: 'remarks',align:'center'},
                            {text: 'STATUS', value: 'status',align:'center'},
                            {text: 'PROCESSED BY', value: 'created_by_name',align:'center'},
                            {text: 'ACCT APPROVED BY', value: 'acct_approved_by_name',align:'center'},
                            {text: 'EXEC APPROVED BY', value: 'exec_approved_by_name',align:'center'},
                            {text: 'SOA MONTH', align: 'center', value: 'soa_month_year'},
                            {text: 'TOTAL DM AMOUNT', align: 'right', value: 'total_amount'},
                            {text: 'PRINTED', align: 'center', value: 'printed'},
                            {text: 'ACTIONS', align: 'center', value: 'action'},
                        ],
                        loaded: false,
                        status_id: -1,
                    },
                    data: [],
                },

                dialog: false,
                errors: [],
                data: [],
                selected: [],

                userAccess: {
                    view: false,
                    create: false,
                    edit: false,
                    delete: false,
                    cancel: false,
                    approve_acctg: false,
                    approve_exec: false,
                },

                selected_id:[],
                convertBtn:false,
                dataTable:[],
                showCheckbox:false,
                btn_loader:false,

                uploadedFiles: null,
                debitMemoId: '',
                addEditDialogAction: '',
                debitMemoType:{
                    dialog:false,
                    value:''
                },
                images:null,
                debitMemoItemId: [],
            }
        },
        methods: {
            showMassConversion(){
            if(this.debitMemoList.datatable.status_id == -1 || this.status_text == 'canceled' || this.debitMemoList.datatable.status_id == 6){
                this.showCheckbox = false
                return false
            }else{
                this.showCheckbox = true
                return false
            }
        },
            async printDM(){
                this.btn_loader = true
                await swal.fire({
                    title: "",
                    text: "Are you sure you want to Print selected Debit Memo?",
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                })
                .then(async (result) => {
                    if (result.isConfirmed) {
                        let payload = {
                            selected_id: this.selected_id
                        }
                        await this.$store.dispatch('getDMDetails',payload).then(async response => {
                        let data = this.GET_DM_DETAILS
                            if(data != ''){
                                for (const index in data.data){
                                    await new Promise((resolve)=>{
                                        let month                           = data.data[index].selected_month
                                        if(month < 10) { month = '0'+month }
                                        let id                              = data.data[index].id
                                        this.print.debit_memo_num           = data.data[index].debit_memo_num
                                        this.print.soa_month_year           = data.data[index].selected_year+'-'+month
                                        this.print.debit_memo_date          = this.$dayjs(data.data[index].debit_memo_date).format('dddd, MMMM DD, YYYY')
                                        this.print.customer_name            = data.data[index].customer_company_name
                                        this.print.billing_address_text     = data.data[index].billing_address_text
                                        this.print.billing_contact_person   = data.data[index].billing_contact_person
                                        this.print.billing_contact_number   = data.data[index].billing_contact_number
                                        this.print.debit_memo_items         = data.data[index].dm_items
                                        this.print.remarks                  = data.data[index].remarks
                                        this.print.total_amount             = data.data[index].total_amount
                                        this.print.created_by_name          = data.data[index].created_by_name
                                        this.print.acct_approved_by_name    = data.data[index].acct_approved_by_name
                                        this.print.exec_approved_by_name    = data.data[index].exec_approved_by_name

                                        while(this.print.debit_memo_items.length < 4){
                                            this.print.debit_memo_items.push(Object.assign({},{
                                                id: null,
                                                remarks: null,
                                                total_amount: null,
                                            }))
                                        }
                                        this.$store.dispatch('printDebitMemo',id).then(response => {
                                                if(response){
                                                this.printDetails()
                                                resolve()
                                            }
                                        })
                                    })
                                }
                                swal.fire('Selected Debit Memo Printing Success', '', 'success')
                                this.getAllDebitMemos()
                                this.btn_loader = false
                            }
                        })
                    }
                    this.btn_loader = false
                })
            },
            printDetails(){
                let doc = new this.$jspdf('p', 'mm', 'letter');

                let ingcoPhLogo = new Image();
                ingcoPhLogo.src = '/images/ingcoph-logo.png';
                doc.addImage(ingcoPhLogo, 'png', 7, 6, 40, 15);

                let debitMemoLogo = new Image();
                debitMemoLogo.src = '/images/debit-memo-logo.png';
                doc.addImage(debitMemoLogo, 'png', 118, 6, 85, 8);


                doc.autoTable({
                    html: '#details_print',
                    theme: 'grid',
                    margin: {top: 25, left: 4, right: 11},
                    columnStyles: {
                        0: {cellWidth: 30, halign:'left', textColor: '#000000', fontSize: 8},
                        1: {cellWidth: 69, halign:'left', textColor: '#000000', fontSize: 8},
                        2: {cellWidth: 15, halign:'left', textColor: '#000000', fontSize: 8},
                        3: {cellWidth: 15, halign:'left', textColor: '#000000', fontSize: 8},
                        4: {cellWidth: 72, halign:'left', textColor: '#000000', fontSize: 8},
                    },
                    bodyStyles: {
                        lineColor: '#000000',
                    }
                });

                doc.autoTable({
                    html: '#items_print',
                    theme: 'grid',
                    margin: {left: 4, right: 11},
                    startY: doc.autoTable.previous.finalY,
                    headStyles: {
                        halign:'center',
                        fillColor: '#ffffff',
                        textColor: '#000000',
                        lineColor: '#000000',
                        lineWidth: .1,
                        fontSize: 8,
                    },
                    columnStyles: {
                        0: {cellWidth: 30, halign:'left', textColor: '#000000', fontSize: 8},
                        1: {cellWidth: 101, halign:'left', textColor: '#000000', fontSize: 8},
                        2: {cellWidth: 15, halign:'left', textColor: '#000000', fontSize: 8},
                        3: {cellWidth: 15, halign:'left', textColor: '#000000', fontSize: 8},
                        4: {cellWidth: 40, halign:'right', textColor: '#000000', fontSize: 8},
                    },
                    bodyStyles: {
                        lineColor: '#000000',
                    }
                });

                doc.autoTable({
                    html: '#totals_print',
                    theme: 'grid',
                    margin: {left: 4, right: 11},
                    startY: doc.autoTable.previous.finalY,
                    columnStyles: {
                        0: {cellWidth: 30, halign:'right', textColor: '#000000', fontSize: 8},
                        1: {cellWidth: 69, halign:'right', textColor: '#000000', fontSize: 8},
                        2: {cellWidth: 15, halign:'right', textColor: '#000000', fontSize: 8},
                        3: {cellWidth: 47, halign:'right', textColor: '#000000', fontSize: 8},
                        4: {cellWidth: 40, halign:'right', textColor: '#000000', fontSize: 8},
                    },
                    bodyStyles: {
                        lineColor: '#000000',
                    }
                });

                doc.autoTable({
                    html: '#signatories_print',
                    theme: 'grid',
                    margin: {left: 4, right: 11},
                    startY: doc.autoTable.previous.finalY,
                    columnStyles: {
                        0: {cellWidth: 30, halign:'left', textColor: '#000000', fontSize: 8},
                        1: {cellWidth: 69, halign:'center', textColor: '#000000', fontSize: 8},
                        2: {cellWidth: 15, halign:'left', textColor: '#000000', fontSize: 8},
                        3: {cellWidth: 15, halign:'left', textColor: '#000000', fontSize: 8},
                        4: {cellWidth: 72, halign:'center', textColor: '#000000', fontSize: 8},
                    },
                    bodyStyles: {
                        lineColor: '#000000',
                    }
                });

                let footerY = doc.autoTable.previous.finalY + 3


                doc.autoPrint();
                window.open(doc.output('bloburl'), '_blank');

            },
            async updateDMStatus(newStatus){
                this.btn_loader = true
                let ids = this.selected_id
                let length = this.selected_id.length - 1

                await swal.fire({
                    title: "",
                    text: "Are you sure you want to Approve selected Debit Memo?",
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                })
                .then((result) => {
                    if(result.isConfirmed){
                        ids.forEach(async (id, index) => {
                            let payload = {
                                id: id,
                                params:{
                                    new_status: newStatus,
                                }
                            }

                          await this.$store.dispatch('approveDM',payload).then(response => {
                                if (response.data.status == 'success' && length == index) {
                                    swal.fire('', response.data.msg, response.data.status)
                                    this.btn_loader = false
                                    this.convertBtn = false
                                    this.getAllDebitMemos()
                                }
                            }).catch(error => {
                                swal.fire("An Error Occured", error, "error")
                                this.btn_loader = false
                            })
                        });
                    }else{
                        this.btn_loader = false
                    }
                })
            },
            getSelectedID(val){
                this.selected_id = val.map(function(e){ return e.id })

                if(this.debitMemoList.datatable.status_id == -1){
                    swal.fire('Filter status first', 'required for multiple conversion', 'warning')
                        const index = this.dataTable.indexOf(this.dataTable)
                        this.dataTable.splice(index, 1)
                        this.convertBtn = false
                        this.showCheckbox = false
                        return false
                }else{
                    if(this.dataTable.length > 0){
                        this.convertBtn = true
                    }else{
                        this.convertBtn = false
                    }
                }
            },
            rejectDebitMemo(){
                    if(this.rejection_remarks == '' || this.rejection_remarks.length < 5) {
                        swal.fire("Remarks cannot be blank", "put a valid remarks more than 5 letters", "error")
                        return false
                    }else{
                        let payload = {
                            id:      this.reject_id,
                            remarks: this.rejection_remarks
                        }

                        this.$store.dispatch('rejectDebitMemo',payload).then(response => {
                        if(response.data.status == 'success')
                        {
                            this.rejection_dialog = false
                            this.rejection_remarks = ''
                            this.approveDebitMemo(0)
                        }else
                        {
                            swal.fire("Error Notification", response.data.msg, "error")
                        }
                    })
                }
            },
            showRejectDialog(){
                this.rejection_dialog = true
                this.dialog = false
            },
            closeRejectDialog(){
                this.rejection_dialog = false
                this.dialog = true
            },
            checkAccess(){
                    let val = this.USER_ACCESS;
                    this.userAccess.view = false;
                    this.userAccess.create = false;
                    this.userAccess.edit = false;
                    this.userAccess.delete = false;
                    this.userAccess.cancel = false;
                    this.userAccess.approve_acctg = false;
                    this.userAccess.approve_exec = false;
                    if(val != "fail"){
                        val.forEach((e) => {
                            if (e.actions_code == "view"){
                                this.userAccess.view = true;
                            }
                            if (e.actions_code == "create"){
                                this.userAccess.create = true;
                            }
                            if (e.actions_code == "edit"){
                                this.userAccess.edit = true;
                            }
                            if (e.actions_code == "delete"){
                                this.userAccess.delete = true;
                            }
                            if (e.actions_code == "cancel"){
                                this.userAccess.cancel = true;
                            }
                            if (e.actions_code == "approve_acctg"){
                                this.userAccess.approve_acctg = true;
                            }
                            if (e.actions_code == "approve_exec"){
                                this.userAccess.approve_exec = true;
                            }
                        });
                        }
            },
            onSelectCustomer(){
                if (this.customer.value !== null) {
                    this.editedDebitMemo.customer_id = this.customer.value

                    this.customer.billing_address_list = []

                    this.getCustomerBillingAddresses(this.editedDebitMemo.customer_id)
                }
            },
            onSelectBillingAddress(){
                let index = this.editedDebitMemo.billing_address_id
                this.editedDebitMemo.billing_address_text = this.customer.billing_address_list[index].text
                this.editedDebitMemo.billing_contact_person = this.customer.billing_address_list[index].contact_person
                this.editedDebitMemo.billing_contact_number = this.customer.billing_address_list[index].contact_number
            },
            getAllDebitMemos(){
                this.debitMemoList.datatable.loaded = false
                this.debitMemoList.data = []
                let payload = {
                    customer_filter: this.customer.search,
                    status_text: this.status_text,
                    status_id: this.debitMemoList.datatable.status_id,
                    soa_month_filter: this.debitMemoList.datatable.filters.soa_month.values,
                    search: this.debitMemoList.datatable.search,
                    pagination: this.pagination,
                    debit_memo_type:this.debitMemoList.datatable.filters.dm_type,
                    soa_month_filter_to: this.debitMemoList.datatable.filters.soa_month_to.values,
                }

                this.$store.dispatch('getAllDM',payload).then(response => {
                    this.debitMemoList.data = this.GET_ALL_DM.debit_memos.data
                    this.debitMemoList.datatable.total = this.GET_ALL_DM.debit_memos.total
                    this.debitMemoList.datatable.loaded = true
                    this.dataTable = []
                    this.showMassConversion()
                }).catch(error => {

                })
            },
            viewDebitMemo(id){
                this.reject_id = id
                this.debitMemoId = id;
                this.editedIndex = 1

                this.$store.dispatch('showDM',id).then(response => {
                    let debit_memo = this.GET_SHOW_DM.debit_memo
                    let debit_memo_items = this.GET_SHOW_DM.debit_memo_items

                    this.editedDebitMemo = {
                        ...Object.assign({}, {
                            id: debit_memo.id,
                            type: debit_memo.type,
                            debit_memo_num: debit_memo.debit_memo_num,
                            customer_id: debit_memo.customer_id,
                            customer_name: debit_memo.customer_company_name,
                            billing_address_id: debit_memo.billing_address_id,
                            billing_address_text: debit_memo.billing_address_text,
                            billing_contact_person: debit_memo.billing_contact_person,
                            billing_contact_number: debit_memo.billing_contact_number,
                            debit_memo_date: debit_memo.debit_memo_date,
                            debit_memo_date_long: this.$dayjs(debit_memo.debit_memo_date).format('dddd, MMMM DD, YYYY'),
                            total_amount: this.getTwoDecimals(debit_memo.total_amount),
                            remarks: debit_memo.remarks,
                            rejection_remarks: debit_memo.rejection_remarks,
                            soa_id: debit_memo.soa_id,
                            soa_month_year: debit_memo.soa_month_year,
                            soa_month: debit_memo.selected_month,
                            soa_year: debit_memo.selected_year,
                            status: debit_memo.status,
                            printed: debit_memo.printed,
                            created_by_name: debit_memo.created_by_name,
                            acct_approved_by_name: debit_memo.acct_approved_by_name,
                            exec_approved_by_name: debit_memo.exec_approved_by_name,
                            debit_memo_type: debit_memo.debit_memo_type_by_name
                        })
                    }

                    debit_memo_items.forEach(d => {
                        this.editedDebitMemoItems.data.push(Object.assign({},{
                            id: d.id,
                            remarks: d.remarks,
                            total_amount: this.getTwoDecimals(d.total_amount),
                        }))
                    })

                    this.customer.value = debit_memo.customer_id
                    this.getCustomerBillingAddresses(debit_memo.customer_id)

                    this.dialog = true
                }).catch(error => {
                    swal.fire("An Error Occured", error, "error")
                })
            },
            editDebitMemo(id){
                this.debitMemoId = id;
                this.addEditDialogAction = 'edit';
                this.editedIndex = 0

                this.$store.dispatch('showDM',id).then(response => {
                    let debit_memo = this.GET_SHOW_DM.debit_memo
                    let debit_memo_items = this.GET_SHOW_DM.debit_memo_items

                    this.editedDebitMemo = {
                        ...Object.assign({}, {
                            id: debit_memo.id,
                            type: debit_memo.type,
                            debit_memo_num: debit_memo.debit_memo_num,
                            customer_id: debit_memo.customer_id,
                            customer_name: debit_memo.customer_company_name,
                            billing_address_id: debit_memo.billing_address_id,
                            billing_address_text: debit_memo.billing_address_text,
                            billing_contact_person: debit_memo.billing_contact_person,
                            billing_contact_number: debit_memo.billing_contact_number,
                            debit_memo_date: debit_memo.debit_memo_date,
                            debit_memo_date_long: this.$dayjs(debit_memo.debit_memo_date).format('dddd, MMMM DD, YYYY'),
                            total_amount: debit_memo.total_amount,
                            remarks: debit_memo.remarks,
                            rejection_remarks: debit_memo.rejection_remarks,
                            soa_id: debit_memo.soa_id,
                            soa_month_year: debit_memo.soa_month_year,
                            soa_month: debit_memo.selected_month,
                            soa_year: debit_memo.selected_year,
                            status: debit_memo.status,
                            printed: debit_memo.printed,
                            created_by_name: debit_memo.created_by_name,
                            acct_approved_by_name: debit_memo.acct_approved_by_name,
                            exec_approved_by_name: debit_memo.exec_approved_by_name,
                            debit_memo_type: debit_memo.debit_memo_type_by_name
                        })
                    }

                    debit_memo_items.forEach(d => {
                        this.editedDebitMemoItems.data.push(Object.assign({},{
                            id: d.id,
                            remarks: d.remarks,
                            total_amount: d.total_amount,
                        }))
                    })

                    this.customer.value = debit_memo.customer_id
                    this.getCustomerBillingAddresses(debit_memo.customer_id)

                    this.dialog = true
                }).catch(error => {
                    swal.fire("An Error Occured", error, "error")
                })
            },
            saveNewDebitMemo(){
                this.loading.state = true
                if (this.editedDebitMemoItems.data.length == 0) {
                    swal.fire('', 'No Items Found in Debit Memo', 'warning')
                    this.loading.state = false
                } else if(this.uploadedFiles == null && this.images == null) {
                    swal.fire({
                        title: 'Please Upload Images or Files',
                        icon: 'warning',
                        dangerMode: true,
                    }).then(confirm=> {
                        this.loading.state = false;
                    });
                } else {
                    const data = new FormData();

                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }

                    if(!!this.uploadedFiles && this.uploadedFiles.attachments.length > 0) {
                        let files = this.uploadedFiles.attachments;

                        for(let i=0;i < _.compact(files).length;i++) {
                            data.append('files[]', files[i]);
                        }
                    }

                    if(!!this.images && this.images.length > 0) {
                        for(let i=0;i < _.compact(this.images).length;i++) {
                            data.append('images[]', _.compact(this.images)[i]);
                        }
                    }

                    data.append('debit_memo', JSON.stringify(this.editedDebitMemo));

                    for(let i=0;i < _.compact(this.editedDebitMemoItems.data).length;i++){
                        data.append('debit_memo_items[]', JSON.stringify(this.editedDebitMemoItems.data[i]));
                    }

                    let payload = {
                        params: data,
                        config: config,
                    }

                    this.$store.dispatch('postDM',payload).then(response => {
                        if (response.data.error) {
                            swal.fire('', response.data.error, response.data.status)
                            this.loading.state = false
                        } else {
                            swal.fire('', response.data.msg, response.data.status)
                            this.dialog = false
                            this.loading.state = false
                            this.resetEditedDebitMemo()
                            this.getAllDebitMemos()
                            this.clearFileUpload();
                        }
                    })
                }
            },
            updateDebitMemo(id){
                this.loading.state = true
                if (this.editedDebitMemoItems.data.length == 0) {
                    swal.fire('', 'No Items Found in Debit Memo', 'warning')
                    this.loading.state = false
                } else {
                    const data = new FormData();

                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }

                    if(!!this.uploadedFiles && this.uploadedFiles.attachments.length > 0) {
                        let files = this.uploadedFiles.attachments;

                        for(let i=0;i < _.compact(files).length;i++) {
                            data.append('files[]', files[i]);
                        }
                    }

                    if(!!this.images && this.images.length > 0) {
                        for(let i=0;i < _.compact(this.images).length;i++) {
                            data.append('images[]', _.compact(this.images)[i]);
                        }
                    }

                    data.append('id', JSON.stringify(id));
                    data.append('debit_memo', JSON.stringify(this.editedDebitMemo));
                    for(let i=0;i < _.compact(this.debitMemoItemId).length;i++){
                        data.append('debit_memo_item_id[]', JSON.stringify(this.debitMemoItemId[i]));
                    }

                    for(let i=0;i < _.compact(this.editedDebitMemoItems.data).length;i++){
                        data.append('debit_memo_items[]', JSON.stringify(this.editedDebitMemoItems.data[i]));
                    }
                    let payload = {
                        params: data,
                        config: config,
                    }

                    this.$store.dispatch('updateDM',payload).then(response => {
                        if (response.data.error) {
                            swal.fire('', response.data.error, response.data.status)
                            this.loading.state = false
                        } else {
                            swal.fire('', response.data.msg, response.data.status)
                            this.dialog = false
                            this.loading.state = false
                            this.resetEditedDebitMemo();
                            this.getAllDebitMemos();
                            this.clearFileUpload();
                        }
                    })
                }
            },
            deleteDebitMemo(id){
                this.loading.state = true
                swal.fire({
                    title: "",
                    text: "Are you sure you want to Delete?",
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                })
                .then((result) => {
                    if (result.isConfirmed) {

                        this.$store.dispatch('deleteDM',id).then(response => {
                            if (response.data.status == 'success') {
                                this.getAllDebitMemos()
                            }
                            swal.fire('', response.data.msg, response.data.status)
                            this.loading.state = false
                        }).catch(error => {
                            swal.fire("An Error Occured", error, "error")
                            this.loading.state = false
                        })
                    } else {
                        this.loading.state = false
                    }
                })
            },
            cancelDebitMemo(id){
                this.loading.state = true
                swal.fire({
                    title: "",
                    text: "Are you sure you want to Cancel?",
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch('cancelDM',id).then(response => {
                            if (response.data.status == 'success') {
                                this.getAllDebitMemos()
                            }
                            swal.fire('', response.data.msg, response.data.status)
                            this.loading.state = false
                        }).catch(error => {
                            swal.fire("An Error Occured", error, "error")
                            this.loading.state = false
                        })
                    } else {
                        this.loading.state = false
                    }
                })
            },
            approveDebitMemo(new_status){
                this.loading.state = true

                let payload = {
                    id: this.editedDebitMemo.id,
                    params:{
                        new_status: new_status,
                    }
                }
                this.$store.dispatch('approveDM',payload).then(response => {
                    if (response.data.status == 'success') {
                        this.getAllDebitMemos()
                    }
                    swal.fire('', response.data.msg, response.data.status)

                    this.resetEditedDebitMemo()
                    this.dialog = false
                    this.loading.state = false
                }).catch(error => {
                    swal.fire("An Error Occured", error, "error")
                    this.loading.state = false
                })
            },
            printDebitMemo(){
                this.print_debit_memo = true
            },
            addLine(){
                this.editedDebitMemoItems.data.push(Object.assign({},{
                    id: null,
                    remarks: null,
                    total_amount: null,
                }))
            },
            removeLine(index = null, debitMemoItemId){
                if (index === null) {
                    this.editedDebitMemoItems.data.pop()
                } else {
                    this.editedDebitMemoItems.data.splice(index, 1)
                    this.debitMemoItemId.push(debitMemoItemId)
                }
            },
            formatDate(date_field, date_string){
                if (date_field == 'debit_memo_date') {
                    this.editedDebitMemo.debit_memo_date_long = this.$dayjs(date_string).format('dddd, MMMM DD, YYYY')
                    this.datePicker = false
                }
                if (date_field == 'soa_month_year') {
                    this.editedDebitMemo.soa_month_year = this.$dayjs(date_string).format('YYYY-MM')
                    this.editedDebitMemo.soa_month = this.$dayjs(date_string).format('M')
                    this.editedDebitMemo.soa_year = this.$dayjs(date_string).format('YYYY')
                    this.soaPicker = false
                }
            },
            formatNumber(number, index){
                this.editedDebitMemoItems.data[index].total_amount = this.thousandSeprator(number)
            },
            resetEditedDebitMemo(){
                this.editedDebitMemo = {
                    ...Object.assign({}, {
                        id: null,
                        type: null,
                        debit_memo_num: null,
                        customer_id: null,
                        customer_name: null,
                        billing_address_id: null,
                        billing_address_text: null,
                        billing_contact_person: null,
                        billing_contact_number: null,
                        debit_memo_date: null,
                        debit_memo_date_long: null,
                        total_amount: null,
                        remarks: null,
                        soa_id: null,
                        soa_month_year: null,
                        soa_month: null,
                        soa_year: null,
                        status: null,
                        printed: null,
                        created_by_name: null,
                        acct_approved_by_name: null,
                        exec_approved_by_name: null,
                        debit_memo_type: null,
                    })
                }
                this.debitMemoType.value = []

                this.editedDebitMemoItems = {
                    ...Object.assign({}, {
                        datatable: {
                            search: '',
                        },
                        data: []
                    })
                }

                this.customer.value = null
            },
            // getCustomers(){
            //     this.$store.dispatch('getAllCustomers').then((response)=>{ this.customer.selection = this.GET_CUSTOMERS_LIST_SELECTION })
            // },
            getSoas(){
                this.$store.dispatch('getCustomerSoas',this.editedDebitMemo.customer_id).then((response)=>{ this.soa.selection = this.GET_CUSTOMER_SOAS })
            },
            getCustomerBillingAddresses(customer_id){
                let payload = {
                    id: customer_id,
                    params:{
                        type: 'billing',
                    }
                }
                this.$store.dispatch('getCustomerAddress',payload).then(response => {
                    let data = this.GET_CUSTOMER_ADDRESSES

                    this.customer.billing_address_list = data

                    if (this.editedIndex < 0) {
                        this.editedDebitMemo.billing_address_id = 0
                        this.editedDebitMemo.billing_address_text = data[0].text
                        this.editedDebitMemo.billing_contact_person = data[0].contact_person
                        this.editedDebitMemo.billing_contact_number = data[0].contact_number

                        this.getSoas()
                    }
                })
            },
            closeDialog(){
                this.addEditDialogAction = '';
                this.resetEditedDebitMemo()
                this.dialog = false
            },
            uploadedData(data) {
                if(!data) {
                    this.uploadedFiles = null;
                }

                this.uploadedFiles = data;
            },
            clearFileUpload(confirm) {
                if(confirm) {
                    this.uploadedFiles = null;
                }
                this.$refs.fileUpload.reset();
            },
            showNewDebitMemoDialog() {
                this.editedIndex = -1
                this.addEditDialogAction = 'new';
            },
            closeDebitMemoTypeDialog() {
                this.debitMemoType.dialog = false;
            },
            selectedDMType(){
                this.editedDebitMemo.debit_memo_type = this.debitMemoType.value.value
            },
            openDebitMemoTypeDialog(){
                this.$store.dispatch('getDebitMemoTypes')
                this.debitMemoType.dialog = true;
                this.debitMemoType.value = []
            },
            getImages(images) {
                this.images = images;
            },
            clearCameraCapture() {
                this.$refs.cameraCapture.reset();
                this.images = null;
            },
        },
        watch: {
            'dialog': function(){
                if (this.dialog) {
                    // this.getCustomers()
                    this.getSoas()
                    if (this.editedIndex < 0) {
                        this.addLine()
                        this.editedDebitMemo.debit_memo_date = this.$dayjs()
                        this.formatDate('debit_memo_date', this.editedDebitMemo.debit_memo_date)
                        this.formatDate('soa_month_year', this.$dayjs())
                    }
                }
            },
            'USER_ACCESS':function(){
                this.checkAccess()
            },
            'print_debit_memo': function() {
                if (this.print_debit_memo) {
                    this.loading.state = true
                    this.editedDebitMemoItems.print_data = this.editedDebitMemoItems.data

            //         while (this.editedDebitMemoItems.print_data.length < 4) {
            //             this.editedDebitMemoItems.print_data.push(Object.assign({},{
            //                 id: null,
            //                 remarks: null,
            //                 total_amount: null,
            //             }))
            //         }

                    this.loading.state = false

                    setTimeout(() => {
                        this.print_debit_memo = false
                    }, 1000)
                }
            },
            'GET_CUSTOMERS_LIST_SELECTION':{
            handler(val){
                this.loading.customer_selection = false
            },'GET_CUSTOMER_ADDRESSES':{
            handler(val){
                this.loading.customer_address_selection = false
            },
            },
            },
            'pagination.options': {
                handler(){
                    this.getAllDebitMemos()
                    // this.getCustomers()
                },
                deep: true,
            },
        },
        computed: {
            ...mapGetters([
                'GET_DM_DETAILS',
                'GET_SHOW_DM',
                'GET_ALL_DM',
                'GET_CUSTOMERS_LIST_SELECTION',
                'GET_CUSTOMER_SOAS',
                'GET_CUSTOMER_ADDRESSES',
                'USER_ACCESS',
                'GET_DEBIT_MEMO_TYPES'
            ]),
            debit_memo_total_amount(){
                let total = 0.00
                this.editedDebitMemoItems.data.forEach(element => {
                    total += (element.total_amount == null || element.total_amount == '' ? 0 : parseFloat(element.total_amount.replace(/\,/g,'')))
                });
                return total
            }
        }
    }
</script>
