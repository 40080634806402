<template>
    <div class="" style="border:unset">
        <v-card class="pa-2">
            <div class="row px-4">
                <div class="col-sm-12 col-md-6">
                    <v-autocomplete
                        v-model="selectedItems"
                        :items="itemsFilter"
                        placeholder="Select Items"
                        multiple
                        dense
                        outlined
                        hide-details
                        auto-select-first
                        return-object
                        class="d-inline-block">
                    </v-autocomplete>
                </div>

                <div class="col-sm-12 col-md-6 text-right">
                    <v-btn class="ml-1" @click="resetFilter()">Clear</v-btn>
                    <v-btn class="ml-1" @click="filter()">Filter</v-btn>
                </div>
            </div>

            <v-layout class="mx-0 px-4">
                <v-spacer />
                <v-flex lg3>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Item Model"
                        single-line
                        hide-details
                        clearable
                        @keydown.enter="searchItem()"
                        @click:clear="clearFields()"
                    />
                </v-flex>
            </v-layout>

            <v-card-text>
                <v-data-table
                    :headers="tableHeaders()"
                    :items="items"
                    :search="search"
                    :options.sync="options"
                    :server-items-length="totalItems"
                >
                    <template v-slot:item.upload="{ item }">
                        <v-btn @click="showAttachmentDialog(item)">Exploded View</v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <v-dialog v-model="attachmentDialog" persistent max-width="40%">
            <v-card class="pa-4">
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Upload Exploded View</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                            text
                            icon
                            color="gray"
                            class="float-right"
                            @click="closeAttachmentDialog()"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider />

                <div class="pb-4">
                    <div>Model: {{ itemModel }}</div>
                    <div>Name: {{ itemName }}</div>
                </div>

                <div v-if="!batchDownload" style="text-align:end">
                    <div style="display:inline-block" class="pr-2">
                        Choose File / Image:
                    </div>
                    <div style="display:inline-block">
                        <FileUploadComponentVue ref="fileUpload" @uploadedData="uploadedData" @reset="clearFileUpload" />
                    </div>
                </div>

                <div>
                    <v-layout class="mx-0 px-4">
                        <v-flex shrink>
                            <v-checkbox v-model="batchDownload"/>
                        </v-flex>
                        <v-flex>
                            <div class="mt-3">Batch Download</div>
                        </v-flex>
                    </v-layout>

                    <v-data-table
                        class="pa-4"
                        :headers="filesHeader"
                        :items="files"
                        :loading="loading"
                        :no-data-text="noDataText"
                        :show-select="batchDownload"
                        v-model="selectedImages"
                    >
                        <template v-slot:item.action="{ item }">
                            <td class="px-0">
                                <span class="text-nonwrap">
                                    <v-btn text icon color="orange">
                                        <v-icon class="btn-action" small @click="showFile(item)">mdi-eye</v-icon>
                                    </v-btn>
                                    <v-btn v-if="!batchDownload" text icon color="blue">
                                        <v-icon class="btn-action" small @click="downloadFile(item)">mdi-download</v-icon>
                                    </v-btn>
                                    <v-btn v-if="!batchDownload" text icon color="red">
                                        <v-icon class="btn-action" small @click="deleteFile(item)">mdi-delete</v-icon>
                                    </v-btn>
                                </span>
                            </td>
                        </template>
                        <template v-slot:item.created_at="{ item }">
                            <td class="px-0">
                                {{ date(item) }}
                            </td>
                        </template>
                    </v-data-table>

                    <v-dialog v-model="viewFile" persistent max-width="80%">
                        <v-card>
                            <v-row class="m-0">
                                <v-col>
                                    <v-card-title>
                                        <span class="headline">{{ itemName }}</span>
                                    </v-card-title>
                                </v-col>
                                <v-col cols="pull-right-10 p-2">
                                    <v-btn text icon color="gray" class="float-right" @click="closeViewFile()">
                                        <v-icon>mdi-close-circle</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <v-divider class="my-0" />

                            <div class="iframe-container">
                                <iframe :src="itemPath" frameborder="0" class="responsive-iframe" height="100%" width="100%"></iframe>
                            </div>
                        </v-card>
                    </v-dialog>
                </div>

                <v-divider />

                <div v-if="batchDownload" class="pb-4">
                    <div style="text-align:center">
                        <v-btn @click="downloadSelectedImages()" :loading="downloadOnFlight">Download</v-btn>
                    </div>
                </div>

                <div v-else style="text-align:center">
                    <v-btn :disabled="!uploadedFiles" @click="showOverrideDialog()">Submit</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <div style="display:none">
            <span v-for="(img,index) in selectedImages" :key="img.name">
                <img :id="`img${index}`" :src="`${origin}${img.path}`" alt="" style="cursor:move">
            </span>
        </div>

        <ConfirmOverrideComponentVue
            :approve_override_dialog="override.override_dialog"
            :departments_allowed="override.override_departments_allowed"
            :heading="'Upload Exploded Views'"
            @closeConfirmOverride="closeConfirmOverride"
        ></ConfirmOverrideComponentVue>
    </div>
</template>

<script>
import BaseFilesViewerVue from '../../../Accounting/Base/BaseFilesViewer.vue';
import FileUploadComponentVue from '@/views/main/Shared/FileUploadComponent.vue';
import ConfirmOverrideComponentVue from '@/views/main/Utils/ConfirmOverrideComponent.vue';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            search: '',
            itemHeaders: [
                { text: 'Model', value: 'model', },
                { text: 'Name', value: 'name', },
                { text: 'Category', value: 'category', },
                { text: 'Upload', value: 'upload', width: '50px' },
                { text: 'View', value: 'view', width: '50px' },
            ],
            spHeaders: [
                { text: 'Model', value: 'model', },
                { text: 'Name', value: 'name', },
                { text: 'Type Id', value: 'type_id', },
                { text: 'Actions', value: 'action' },
            ],
            items: [],
            edit: false,
            itemId: '',
            loading: true,

            page: 1,
            itemsPerPage: 10,
            options: {},
            totalItems: 0,

            attachmentDialog: false,
            uploadedFiles: null,
            itemModel: '',
            itemName: '',

            files: [],
            filesHeader: [
                { text: 'Name', value: 'name' },
                { text: 'Uploaded By', value: 'uploaded_by' },
                { text: 'Date', value: 'created_at' },
                { text: 'Action', value: 'action' },
            ],
            viewFile: false,
            itemPath: '',
            noDataText: '',

            override: {
                override_dialog: false,
                errors: [],
                override_departments_allowed: ['SR', 'EX', 'IT'],
                override_authenticated: false,
            },
            batchDownload: false,
            selectedImages: [],
            origin: window.location.origin,
            downloadOnFlight: false,

            selectedItems: [],
            itemsFilter: []
        }
    },
    components: {
        BaseFilesViewerVue,
        FileUploadComponentVue,
        ConfirmOverrideComponentVue
    },
    mounted: function() {
        this.getServiceItems();
    },
    computed:{
        ...mapGetters([
            'GET_SERVICE_ITEMS_POST',
            'GET_ITEM_WITH_SP_FILES',
        ])
    },
    props: ['type'],
    watch: {
        options(data) {
            this.loading = true;
            this.page = data.page;
            this.getServiceItems();
        },
        type() {
            if(!!this.type) {
                this.getServiceItems();
            }
        },
        items() {
            if(this.items.length > 0) {
                this.loading = false;

                this.items.forEach(x=>{
                    this.itemsFilter.push({
                        text: x.model,
                        value: x.id
                    });
                });
            }
        }
    },
    methods: {
        clearFields() {
            this.loading = true;
            this.items = [];
            this.itemId = '';
            this.search = '';
            this.getServiceItems();
        },
        tableHeaders() {
            if(this.type == 1) {
                return this.itemHeaders;
            } else if(this.type == 3) {
                return this.spHeaders;
            }
        },
        searchItem() {
            this.getServiceItems();
        },
        getServiceItems() {
            let payload = {
                typeId: this.type,
                page: this.page,
                itemsPerPage: this.itemsPerPage,
                search: this.search,
                searchItems: this.selectedItems
            }
            this.$store.dispatch('getServiceItems',payload).then(response=> {
                this.items = this.GET_SERVICE_ITEMS_POST.data;
                this.totalItems = this.GET_SERVICE_ITEMS_POST.total
            })
        },
        getItemFiles() {
            this.$store.dispatch('getItemWithSpFiles',this.itemId).then(response=>{
                this.files = this.GET_ITEM_WITH_SP_FILES;
            });
        },
        successCreate(confirm) {
            if(confirm) {
                this.getAllItemWithSpareParts();
            }
        },
        closeDialog(confirm) {
            if(confirm) {
                this.clearFields();
            }
        },
        showItemDetailsDialog(item) {
            this.$store.commit('showItemSpDialog');
            this.itemId = item.id;
        },
        showAttachmentDialog(item) {
            this.itemId = item.id
            this.itemModel = item.model;
            this.itemName = item.name;
            this.getItemFiles();
            this.attachmentDialog = true;
        },
        closeAttachmentDialog() {
            this.attachmentDialog = false;
            this.uploadedFiles = null;
            this.itemName = '';
            this.itemModel = '';
            this.batchDownload = false;
            this.selectedImages = [];
            this.downloadOnFlight = false;

            if(this.$refs.fileUpload) {
                this.$refs.fileUpload.reset();
            }

        },
        uploadedData(data) {
            if(!data) {
                this.uploadedFiles = null;
            }

            this.uploadedFiles = data;
        },
        clearFileUpload(confirm) {
            if(confirm) {
                this.uploadedFiles = null;
            }
            this.$refs.fileUpload.reset();
        },
        submitExplodedViewFile() {
            const data = new FormData();

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            if(!!this.uploadedFiles && this.uploadedFiles.attachments.length > 0) {
                let files = this.uploadedFiles.attachments;

                for(let i=0;i < _.compact(files).length;i++) {
                    data.append('files[]', files[i]);
                }
            }

            data.append('itemId', this.itemId);

            swal.fire({
                title: 'Are you sure you want to Upload?',
                text: 'Click ok to proceed',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(result=> {
                if(result.isConfirmed) {
                    let payload = {
                        params:data,
                        config:config,
                    }
                    this.$store.dispatch('uploadExplodedViews',payload).then(response=>{
                        if(response.data.error) {
                            swal.fire('', response.data.error, 'error');
                        } else {
                            this.closeAttachmentDialog();
                            this.$refs.fileUpload.reset();
                            swal.fire("", response.data.msg, "success");
                        }
                    });
                }

                this.confirmChangeWarrantyOnFlight = false;
            });
        },
        closeViewFile() {
            this.itemPath = "";
            this.viewFile = false;
        },
        showFile(file) {
            this.viewFile = true;
            this.itemPath = file.path;
        },
        downloadFile(file) {
            swal.fire({
                text: 'Are you sure you want to download?',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then((result) => {
                if(result.isConfirmed) {
                    let filelink = document.createElement('a');
                    filelink.href = file.path;
                    filelink.setAttribute('download', file.name);
                    document.body.appendChild(filelink);
                    filelink.click();
                }
            });
        },
        deleteFile(file) {
            swal.fire({
                text: 'Are you sure you want to delete?',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then((result) => {
                if(result.isConfirmed) {
                    let payload = {
                        fileId: file.id
                    }
                    this.$store.dispatch('serviceDeleteFile',payload).then(response=>{
                        this.getItemFiles();
                        swal.fire("", response.data.msg, "success");
                    }).catch(e => {
                        swal.fire("", e.data.error, "error");
                    })
                }
            });
        },
        date(item) {
            if(!!item) {
                return this.$dayjs(item.created_at).format('YYYY-MM-DD, hh:mm:ss a');
            }

            return '';
        },
        showOverrideDialog() {
            this.override.override_dialog = true;
        },
        closeConfirmOverride(user_id, authenticated = false) {
            if (authenticated) {
                this.override.override_dialog = false;
                this.submitExplodedViewFile();
            } else {
                this.override.override_dialog = false;
            }
        },
        async downloadSelectedImages() {
            this.downloadOnFlight = true;

            let zip = new this.$jszip();
            let dateToday = this.$dayjs().format('YYYY-MM-DD');
            let name = `${this.itemModel} Exploded View Images`
            let vm = this;

            if(this.selectedImages == 0) {
                swal.fire('', 'Please select images', 'warning').then(confirm=>{
                    this.downloadOnFlight = false;
                });
            } else {
                this.selectedImages.forEach((x,i)=>{
                    let path = this.origin+x.path;
                    let imgId = `img${i}`;

                    let img = document.getElementById(imgId);

                    let canvas = document.createElement("canvas")
                    let ctx = canvas.getContext("2d");
                    canvas.height = img.height;
                    canvas.width = img.width;
                    ctx.drawImage(img, 0, 0);

                    let dataURL = canvas.toDataURL("image/jpg");

                    let base64Img = dataURL.replace(/^data:image\/(png|jpg);base64,/, "")
                    zip.file(x.name, base64Img, { base64: true });
                });

                zip.generateAsync({type:"base64"}).then(function(content) {
                    let link = document.createElement('a')
                    link.download = `${name}-${dateToday}`;
                    link.href="data:application/zip;base64," + content;
                    link.click();

                    vm.downloadOnFlight = false;
                });
            }
        },
        resetFilter() {
            this.selectedItems = [];
            this.getServiceItems();
        },
        filter() {
           this.getServiceItems();
        }
    }
}
</script>

<style scoped>
    .iframe-container {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    }

    .responsive-iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
</style>
