<template>
    <v-layout class="w-100 w-md-80 w-lg-80 d-flex flex-column">
        <v-flex class="d-flex flex-sm-column flex-md-column flex-lg-row justify-center col-sm-12 w-100 pa-0">
            <v-col class="d-flex flex-column flex-lg-row col-12 pa-0">
                <v-col cols="12" xl="6">
                        <v-menu
                            transition="scale-transition"
                            :close-on-content-click="false"
                            v-model="startDatePickerMenu"
                            :nudge-right="40"
                            min-width="auto"
                            offset-y
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    prepend-icon="mdi-calendar"
                                    v-model="startOfMovementDate"
                                    label="Start Date"
                                    v-bind="attrs"
                                    readonly
                                    v-on="on"
                                    outlined
                                    dense
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="startOfMovementDate"
                                @input="startDatePickerMenu = false"
                                :max="dateToday()"
                            ></v-date-picker>
                        </v-menu>
                </v-col>
                <v-col cols="12" xl="6">
                    <v-menu
                    transition="scale-transition"
                    :close-on-content-click="false"
                    v-model="endDatePickerMenu"
                    :nudge-right="40"
                        min-width="auto"
                        offset-y
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                prepend-icon="mdi-calendar"
                                v-model="endOfMovementDate"
                                label="End Date"
                                v-bind="attrs"
                                readonly
                                v-on="on"
                                outlined
                                dense
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="endOfMovementDate"
                            @input="endDatePickerMenu = false"
                            :max="dateToday()"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
            </v-col>
        </v-flex>
        <v-flex class="d-flex flex-sm-column flex-md-column flex-lg-row justify-center col-sm-12">
            <label class="col-12 col-xl-3" >STORE NAME :</label>
            <v-autocomplete
                :loading="loading.storeNames"
                placeholder="Select Store Name"
                class="col-12 col-xl-9"
                item-text="store_name"
                v-model="storeName"
                :items="storeNames"
                value="store_name"
                deletable-chips
                clearable
                multiple
                outlined
                chips
                dense
            >
            </v-autocomplete>
        </v-flex>
        <v-flex class="d-flex flex-sm-column flex-md-column flex-lg-row justify-center col-sm-12">
            <label class="col-12 col-xl-3" >PRODUCT NO.:</label>
            <v-autocomplete
                :loading="loading.productNumbers"
                placeholder="Select Product No."
                item-text="product_number"
                :items="productNumbers"
                value="product_number"
                v-model="productNo"
                deletable-chips
                class="col-12 col-xl-9"
                clearable
                multiple
                outlined
                chips
                dense
            >
            </v-autocomplete>
        </v-flex>
        <v-flex class="d-flex flex-sm-column flex-md-column flex-lg-row justify-center col-12">
            <label class="col-xl-3" >BUSINESS TYPE :</label>
            <v-autocomplete
                :loading="loading.businessTypes"
                placeholder="Select Business Type"
                item-text="business_type"
                v-model="businessType"
                :items="businessTypes"
                value="business_type"
                deletable-chips
                class="col-xl-9"
                clearable
                multiple
                outlined
                chips
                dense
            >
            </v-autocomplete>
        </v-flex>
        <v-flex class="d-flex flex-sm-column flex-md-column flex-lg-row justify-center col-12">
            <label class="col-xl-3" >ITEM FLOW :</label>
            <v-autocomplete
                :loading="loading.businessTypes"
                placeholder="Select Item Flow"
                v-model="itemFlow"
                :items="['Non-Moving']"
                class="col-xl-9"
                clearable
                outlined
                chips
                dense
            >
            </v-autocomplete>
        </v-flex>
        <v-flex class="d-flex flex-sm-column flex-md-column flex-lg-row align-lg-center col-12 py-0">
            <label class="d-flex justify-start col-12 col-xl-3 px-xl-4"> EXPORT TYPE :</label>
            <v-col class="d-flex flex-sm-column flex-md-row align-start col-12 col-sm-8 col-lg-9 pb-4">
                <v-col xl="6">
                    <v-checkbox
                        v-model="exportType.summary"
                        label="Summary"
                        color="orange"
                        v-bind:true-value="1"
                        v-bind:false-value="0"
                        hide-details
                    ></v-checkbox>
                </v-col>
                <v-col xl="6">
                    <v-checkbox
                        v-model="exportType.detailed"
                        label="Detailed"
                        color="orange"
                        v-bind:true-value="1"
                        v-bind:false-value="0"
                        hide-details
                    ></v-checkbox>
                </v-col>
            </v-col>
        </v-flex>
        <v-flex class="d-flex flex-sm-column flex-md-column flex-lg-row align-lg-center col-12 py-0">
            <label class="d-flex justify-start col-12 col-xl-3 px-xl-4"> WITH IMAGE :</label>
            <v-col class="d-flex flex-sm-column flex-md-row align-start col-12 col-sm-8 col-lg-9 pb-4">
                <v-col xl="6">
                    <v-checkbox
                        v-model="exportType.summaryWithImage"
                        label="For Summary"
                        color="orange"
                        v-bind:true-value="1"
                        v-bind:false-value="0"
                        hide-details
                    ></v-checkbox>
                </v-col>
                <v-col xl="6">
                    <v-checkbox
                        v-model="exportType.detailedWithImage"
                        label="For Detailed"
                        color="orange"
                        v-bind:true-value="1"
                        v-bind:false-value="0"
                        hide-details
                    ></v-checkbox>
                </v-col>
            </v-col>
        </v-flex>
        <v-flex class="d-flex justify-start align-start col-12 mt-1">
            <v-btn :loading="loading.export || loading.productNumbers" @click="exportData">
                <!-- <v-icon class="btn-action" color="success" dense>
                    mdi-cloud-download
                </v-icon>  -->
                <span class="ml-0">export now</span>
            </v-btn>
        </v-flex>
    </v-layout>
</template>

<script>
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue'
import { mapGetters } from 'vuex';
import swal from "sweetalert2";
export default {
    mixins: [ SharedFunctionsComponentVue ],
    computed: {
        ...mapGetters([
            'GET_FILTER_DIALOG',
            'GET_STORE_FLOWS',
        ])
    },
    data() {
        return {
            businessType:[],
            businessTypes: [],
            productNo:[],
            productNumbers:[],
            storeName:[],
            storeNames:[],
            exportType:{
                detailed: 0,
                summary: 1,
                summaryWithImage:1,
                detailedWithImage:0
            },
            loading: {
                export: false,
                storeNames: false,
                productNumbers: false,
                businessTypes: false,
            },
            ifEmptyFilters: {},
            startOfMovementDate: '',
            endOfMovementDate: this.$dayjs().format('YYYY-MM-DD'),
            startDatePickerMenu:false,
            endDatePickerMenu:false,
            storeFlowCount: 0,
            itemFlow: null,

        }
    },
    methods: {
        getStoreNames() {
            this.loading.storeNames = true;
            this.$store.dispatch('storeFlowGet', {url:'store-names'}).then(response => {
                this.storeNames = response.data
                this.ifEmptyFilters.storeName = this.storeNames.map(e => e.store_name)
                this.loading.storeNames = false;
            })
        },
        getProductNumbers() {
            this.loading.productNumbers = true;
            this.$store.dispatch('storeFlowGet', {url:'product-numbers'}).then(response => {
                this.productNumbers = response.data
                this.ifEmptyFilters.productNo = this.productNumbers.map(e => e.product_number)
                this.loading.productNumbers = false;
            })
        },
        getBusinessTypes() {
            this.loading.businessTypes = true;
            this.$store.dispatch('storeFlowGet', {url:'business-types'}).then(response => {
                this.businessTypes = response.data
                this.ifEmptyFilters.businessType = this.businessTypes.map(e => e.business_type)
                this.loading.businessTypes = false;
            })
        },
        clearFields() {
            this.businessType = [];
            this.storeName = [];
            this.productNo = [];
            this.exportType.detailed = 0;
            this.exportType.summary = 1;
            this.exportType.summaryWithImage = 1;
            this.exportType.detailedWithImage = 0;
            this.startOfMovementDate = '';
            this.endOfMovementDate = this.$dayjs().format('YYYY-MM-DD');
            this.itemFlow = null;
        },
        async exportData() {
            if (this.checkDataSizeToExport()) {
                swal.fire('Enormous data to export', `- ${this.proposal}`, 'info');
            } else {
                this.loading.export = true;

                let workbook = new this.$exceljs.Workbook();
                if (this.exportType.summary == 1) {

                    var summarySheet = workbook.addWorksheet('Summary')

                    summarySheet.columns = [
                        { header: 'IMAGE', key: 'image', width: 22, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, raw_data: true },
                        { header: 'STORE NAME', width: '24', key: 'store_name', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                        { header: 'TYPE', width: '12', key: 'item_type', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                        { header: 'CATEGORY', width: '12', key: 'category', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                        { header: 'MODEL', width: '18', key: 'model', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                        { header: 'NAME', width: '24', key: 'name', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }},
                        { header: 'BUSINESS TYPE', width: '20', key: 'business_type', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                        { header: 'FIRST TRANSACTION DATE', width: '24', key: 'min_of_movement_date', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }},
                        { header: 'LAST TRANSACTION DATE', width: '24', key: 'max_of_movement_date', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }},
                        { header: 'NUMBER OF TRANSACTIONS', width: '26', key: 'count_of_movement_date', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }},
                        { header: 'TOTAL QUANTITY', width: '26', key: 'sum_of_qty_of_movement', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }},
                        // { header: 'COUNT', width: '12', key: 'count', style: { alignment: { vertical: 'middle', horizontal: 'center'}} },
                        { header: 'SRP', width: '12', key: 'srp', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: "#,##0.00"} },
                        { header: 'TOTAL SRP', width: '12', key: 'total_srp', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: "#,##0.00" } },
                    ]

                    let sheet1HeaderRow = summarySheet.getRow(1)
                    sheet1HeaderRow.font = { bold:true }
                    for (let c=1;c<=summarySheet.columns.length;c++) {
                        let currCell = sheet1HeaderRow.getCell(c);
                        currCell.fill = {
                            type: 'pattern',
                            pattern:'solid',
                            fgColor:{argb:'F69F1A'},
                        }
                    }

                }

                if (this.exportType.detailed == 1) {

                    var detailedSheet = workbook.addWorksheet('Detailed')
                    detailedSheet.columns = [
                            { header: 'IMAGE', key: 'image', width: 22, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, raw_data: true },
                            { header: 'MOVEMENT DATE', width: '18', key: 'movement_date', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }},
                            { header: 'STORE NAME', width: '24', key: 'store_name', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                            { header: 'PRODUCT NO.', width: '18', key: 'product_number', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                            { header: 'PRODUCT NAME', width: '32', key: 'product_name', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                            { header: 'BRAND', width: '12', key: 'brand', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                            { header: 'UNIT', width: '12', key: 'unit', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                            { header: 'INBOUND/OUTBOUND NO.', width: '24', key: 'inbound_outbound_number', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                            { header: 'BUSINESS NO.', width: '22', key: 'business_number', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                            { header: 'BUSINESS TYPE', width: '20', key: 'business_type', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                            { header: 'UOM', width: '10', key: 'uom', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                            { header: 'QTY BEFORE MOVEMENT', width: '24', key: 'qty_before_movement', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                            { header: 'QTY OF MOVEMENT', width: '20', key: 'qty_of_movement', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                            { header: 'QTY AFTER MOVEMENT', width: '22', key: 'qty_after_movement', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                            { header: 'CLIENT NAME', width: '18', key: 'client_name', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                            { header: 'REMARKS', width: '18', key: 'remarks', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                        ];

                        let sheet2HeaderRow = detailedSheet.getRow(1);
                        sheet2HeaderRow.font = { bold:true }
                        for (let c=1;c<=detailedSheet.columns.length;c++) {
                            let currentCell = sheet2HeaderRow.getCell(c);
                            currentCell.fill = {
                                type: 'pattern',
                                pattern:'solid',
                                fgColor:{argb:'F69F1A'},
                            }
                        }
                }

                if (this.exportType.summary == 0 && this.exportType.detailed == 0) {
                    swal.fire('Info', '-You must select at least one export type', 'info');
                    this.loading.export = false
                    return;
                }

                let payload = {
                    store_names: this.storeName,
                    product_numbers: this.productNo,
                    business_types: this.businessType,
                    start_date: this.startOfMovementDate == '' ? this.GET_STORE_FLOWS.min_movement_date : this.startOfMovementDate,
                    end_date: this.endOfMovementDate,
                    export_type: this.exportType,
                    item_flow: this.itemFlow,
                    url:'store-flows-exports'
                }

                await this.$store.dispatch('storeFlowGet', payload).then( response => {
                    const storeFlowsDetailed = response.data.detailed;
                    const storeFlowsSummary = response.data.summary;

                    if (this.exportType.summary == 1) {
                        let pos = 1
                        storeFlowsSummary.forEach( record => {
                            record.min_of_movement_date = this.formatDate(record.min_of_movement_date);
                            record.max_of_movement_date = this.formatDate(record.max_of_movement_date);
                            record.srp = parseFloat(record.srp)
                            record.total_srp = parseFloat(record.total_srp)
                            summarySheet.addRow(record);
                            if (this.exportType.summaryWithImage == 1) {
                                this.addImageToWorksheet(workbook, summarySheet, record, pos);
                                pos++;
                            }
                        })
                        if (this.exportType.summaryWithImage != 1) {
                            summarySheet.spliceColumns(1,1)
                        }
                    }

                    if (this.exportType.detailed == 1) {
                        let pos = 1
                        storeFlowsDetailed.forEach( record => {
                            record.movement_date = this.formatDate(record.movement_date);
                            detailedSheet.addRow(record);
                            if (this.exportType.detailedWithImage == 1) {
                                this.addImageToWorksheet(workbook, detailedSheet, record, pos);
                                pos++;
                            }
                        });
                        if (this.exportType.detailedWithImage != 1) {
                            detailedSheet.spliceColumns(1, 1)
                        }
                    }

                    workbook.xlsx.writeBuffer().then((data) => {
                        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                        this.$filesaver.saveAs(blob, 'Store Stock Flows.xlsx');
                        this.loading.export = false;
                        this.clearFields();
                    })
                }).catch( e => {
                    swal.fire('Error', '', 'error');
                    console.log(e);
                    this.loading.export = false;
                });
            }
        },
        dateToday() {
            return this.$dayjs().format('YYYY-MM-DD');
        },
        getDiffOfTwoDates(startDate, endDate) {
            startDate = startDate == '' ? this.GET_STORE_FLOWS.min_movement_date : startDate;
            if (startDate != '' && endDate != '') {
                let momentStartDate = this.$dayjs(startDate);
                let momentEndDate = this.$dayjs(endDate);
                return momentEndDate.diff(momentStartDate, 'day');
            } else {
                swal.fire('', '- Make sure to enter date range.', 'info')
            }
        },
        checkDataSizeToExport() {
            if (this.GET_STORE_FLOWS.total_count <= 2000) {
                return false;
            } else {
                const productNumbers =  this.productNo.length == 0 ? this.ifEmptyFilters.productNo : this.productNo
                // const storeNames =  this.storeName.length == 0 ? this.ifEmptyFilters.storeName : this.storeName
                const dateDiff = this.getDiffOfTwoDates(this.startOfMovementDate, this.endOfMovementDate)
                if (dateDiff <= 15) {
                    if (productNumbers.length <= 1500) {
                        return false;
                    } else {
                        if (this.exportType.detailedWithImage == 0 && this.exportType.summaryWithImage == 1) {
                            return false;
                        }

                        if (this.exportType.detailedWithImage == 0 && this.exportType.summaryWithImage == 0) {
                            return false;
                        }

                    }
                    return true;
                } else if (dateDiff <= 31) {
                    if (productNumbers.length <= 1000) {
                        return false;
                    } else {
                        if (this.exportType.detailedWithImage == 0 && this.exportType.summaryWithImage == 1) {
                            return false;
                        }

                        if (this.exportType.detailedWithImage == 0 && this.exportType.summaryWithImage == 0) {
                            return false;
                        }
                        return true;
                    }
                } else {
                    if (productNumbers.length <= 500) {
                        return false;
                    } else {
                        if (this.exportType.detailedWithImage == 0 && this.exportType.summaryWithImage == 0) {
                            return false;
                        } else {
                            if (this.itemFlow != null && this.exportType.detailedWithImage == 0 && this.exportType.summaryWithImage == 1) {
                                return false;
                            } else if (this.itemFlow == null && this.exportType.detailedWithImage == 0 && this.exportType.summaryWithImage == 1) {
                                this.proposal = `Unselect filters on with image option.`
                                return true;
                            }
                        }
                    }
                    this.proposal = `Unselect 'For Detailed' under with image.`
                    return true;
                }
            }
        },
    },
    watch: {
        'GET_FILTER_DIALOG': {
            handler(val) {
                if (val == true) {
                    this.getStoreNames();
                    this.getProductNumbers();
                    this.getBusinessTypes();

                } else {
                    this.clearFields();
                }
            }
        },
    },
    mounted() {
        this.getStoreNames();
        this.getProductNumbers();
        this.getBusinessTypes();
    }
}
</script>

<style>

</style>