<script>
import { mapGetters } from 'vuex';
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";

export default {
    mixins: [SharedFunctionsComponentVue],
    data() {
        return {
            data: Math.random(),

            paperWidth: 215.9,
            paperHeight: 279.4,
            margin: 10,

            headerStartY: undefined,
            headerEndY: undefined,
            headerWidth: undefined,
            headerHeight: undefined,

            footerStartY: undefined,
            footerEndY: undefined,
            footerWidth: undefined,
            footerHeight: undefined,

            printData: undefined,

            headerColor: [254, 249, 195],
        };
    },
    computed:{
        ...mapGetters([
            "PURCHASE_ORDER",
            'GET_JOB_ORDERS',
        ]),
    },
    methods: {
        drawJobOrderPrintHeader(doc, headerData) {
            const currentURL = window.location.href;

            if (currentURL.split('//')[1].split('.')[0] == 'wf') {
                this.headerColor = [191, 219, 254];
            }

            try {
                let aspectRatio = this.getAspectRatio(this.PURCHASE_ORDER.COMPANY_IMAGE.naturalWidth, this.PURCHASE_ORDER.COMPANY_IMAGE.naturalHeight);

                const host = window.location.origin;

                let imageWidth = 0;
                let imageHeight = 0;

                switch(aspectRatio){
                    case '1:1':
                        imageWidth = 14.111;
                        imageHeight = 14.111;
                        break;
                    case '4:3':
                        imageWidth = 21.166;
                        imageHeight = 14.111;
                        break;
                    case '3:2':
                        imageWidth = 17.6389;
                        imageHeight = 14.111;
                        break;
                    case '16:9':
                        imageWidth = 28.222;
                        imageHeight = 14.111;
                        break;
                    default:
                        imageWidth = 42.333;
                        imageHeight = 10.5833;
                }

                doc.addImage(
                    // `${host}/images/ingcoph-logo.png`,
                    headerData.image_path,
                    headerData.ext.toUpperCase() == 'JPG' ? 'JPEG' : headerData.ext.toUpperCase(),
                    this.margin + 5,
                    3,
                    imageWidth,
                    imageHeight,
                );
            } catch {
                console.error('image is not found for print.');
            }

            const titleContainerWidth = 70;
            const titleContainerHeight = 8;
            const titleContainerX = (this.paperWidth / 2) + (((this.paperWidth / 2) - 5) / 2) - (titleContainerWidth / 2);
            const titleContainerY = this.margin;
            doc.setDrawColor('#000000');
            doc.setFont('GILL','normal');
            doc.setFillColor('#fde047');
            // doc.roundedRect(
            //     titleContainerX,
            //     titleContainerY,
            //     titleContainerWidth,
            //     titleContainerHeight,
            //     2,
            //     2,
            //     'FD',
            // );

            const title = 'Job Order';
            const titleFontSize = 15;
            const titleX = titleContainerX;
            const titleY = titleContainerY;
            doc.setFont(
                'GILL',
                'bold',
            );
            doc.setFontSize(titleFontSize);
            doc.setTextColor('#000000');
            doc.text(
                title,
                titleX + 20,
                titleY,
            );

            doc.autoTable({
                startY: this.margin + 3,
                margin: {
                    bottom: this.margin,
                    top: this.margin,
                    left: this.margin,
                    right: this.margin,
                },
                theme: 'plain',
                columnStyles: {
                    0 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 12 - 5},
                    1 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 12},
                    2 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 12 + 5},
                    3 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 12},
                    4 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 12},
                    5 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 12},
                    6 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 12 - 5},
                    7 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 12},
                    8 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 12 + 5},
                    9 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 12},
                    10 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 12},
                    11 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 12},
                },
                bodyStyles: {
                    lineColor: [0, 0, 0],
                    textColor: [0, 0, 0],
                    fontSize: 8,
                    font: 'GILL'
                },
                body: [
                    [
                        {content: '', colSpan: 6},
                        {content: 'Date Prepared', colSpan: 2},
                        {content: this.formatPrintDate(headerData.created_at), colSpan: 4},
                    ],
                    [
                        {content: 'CLIENT DETAILS', colSpan: 6, styles: {halign: 'center', fillColor: this.headerColor, fontStyle: 'bold'}},
                        {content: 'VENDOR DETAILS', colSpan: 6, styles: {halign: 'center', fillColor: this.headerColor, fontStyle: 'bold'}},
                    ],
                    [
                        {content: 'Requestor', colSpan: 2},
                        {content: headerData.requestor ?? '', colSpan: 4},
                        {content: 'Name', colSpan: 2},
                        {content: headerData.vendor_name ?? '', colSpan: 4, styles: headerData.vendor_name.length > 35 ? {fontSize: 6.5} : {}},
                    ],
                    [
                        {content: 'Contact Person', colSpan: 2},
                        {content: headerData.contact_person ?? '', colSpan: 4},
                        {content: 'Contact Person', colSpan: 2},
                        {content: headerData.vendor_contact_person ?? '', colSpan: 4},
                    ],
                    [
                        {content: 'Location', colSpan: 2},
                        {content: headerData.location ?? '', colSpan: 4},
                        {content: 'Contact No.', colSpan: 2},
                        {content: headerData.vendor_contact_number ?? '', colSpan: 4},
                    ],
                    [
                        {content: 'Contact No.', colSpan: 2},
                        {content: headerData.contact_number ?? '', colSpan: 4},
                        {content: 'Payment Terms', colSpan: 2},
                        {content: headerData.payment_term ?? '', colSpan: 4},
                    ],
                    [
                        {content: 'Date Needed', colSpan: 2},
                        {content: headerData.date_needed ?? '', colSpan: 4},
                        {content: 'Checks Payable To', colSpan: 2},
                        {content: headerData.payable_to ?? '', colSpan: 4, styles: headerData.vendor_name.length > 35 ? {fontSize: 6.5} : {}},
                    ],
                    [
                        {content: 'JOB ORDER TYPE', colSpan: 12, styles: {fillColor: this.headerColor, fontStyle: 'italic', halign: 'center'}},
                    ],
                ],
                didDrawCell: (data) => {
                    if (data.row.index === 0 && data.column.index === 0) return;
                    else if (data.row.index === 1 || data.row.index == 7)
                        doc.rect(
                            data.cell.x,
                            data.cell.y,
                            data.cell.width,
                            data.cell.height,
                        );
                    else if (data.column.index == 0 || data.column.index == 6) {
                        doc.line(data.cell.x, data.cell.y, data.cell.x, data.cell.y + data.cell.height);
                        doc.line(data.cell.x, data.cell.y, data.cell.x + data.cell.width, data.cell.y);
                        doc.line(data.cell.x, data.cell.y + data.cell.height, data.cell.x + data.cell.width, data.cell.y + data.cell.height);
                    }
                    else if (data.column.index == 2 || data.column.index == 8) {
                        doc.line(data.cell.x + data.cell.width, data.cell.y, data.cell.x + data.cell.width, data.cell.y + data.cell.height);
                        doc.line(data.cell.x, data.cell.y, data.cell.x + data.cell.width, data.cell.y);
                        doc.line(data.cell.x, data.cell.y + data.cell.height, data.cell.x + data.cell.width, data.cell.y + data.cell.height);
                    }
                },
            });

            const headerCheckBoxesContainerHeight = 40;
            const headerCheckBoxesFontSize = 9;

            doc.setTextColor('#000000');
            doc.setFontSize(headerCheckBoxesFontSize);
            doc.setFont('GILL','normal');
            doc.setDrawColor('#000000');

            // doc.rect(
            //     this.margin + 2,
            //     doc.lastAutoTable.finalY + 2,
            //     2.5,
            //     2.5,
            //     headerData.repair_type == 'Repair / Maintenance' ? 'F' : 'S',
            // );
            doc.text('REPAIR/MAINTENANCE', this.margin + 3, doc.lastAutoTable.finalY + 5);

            // doc.rect(
            //     this.margin + 8,
            //     doc.lastAutoTable.finalY + 7,
            //     2.5,
            //     2.5,
            //     headerData.repair_name == 'Vehicle' ? 'F' : 'S',
            // );
            doc.text('Vehicle [Type]', this.margin + 9, doc.lastAutoTable.finalY + 10);
            doc.line(this.margin + 35, doc.lastAutoTable.finalY + 11, (this.paperWidth / 2) - 5, doc.lastAutoTable.finalY + 11);
            // doc.text('/', (this.paperWidth / 2) - 3, doc.lastAutoTable.finalY + 10);
            doc.text(headerData.vehicle ?? '', this.margin + 35, doc.lastAutoTable.finalY + 10);

            doc.text('[Plate No.]', this.margin + 14.2, doc.lastAutoTable.finalY + 15);
            doc.line(this.margin + 35, doc.lastAutoTable.finalY + 16, (this.paperWidth / 2) - 5, doc.lastAutoTable.finalY + 16);
            doc.text(headerData.plate_number ?? '', this.margin + 35, doc.lastAutoTable.finalY + 15);

            // doc.rect(
            //     this.margin + 8,
            //     doc.lastAutoTable.finalY + 17,
            //     2.5,
            //     2.5,
            //     headerData.repair_name == 'Equipment' ? 'F' : 'S',
            // );
            doc.text('Equipment [Type]', this.margin + 9, doc.lastAutoTable.finalY + 20);
            doc.line(this.margin + 35, doc.lastAutoTable.finalY + 21, (this.paperWidth / 2) - 5, doc.lastAutoTable.finalY + 21);
            // doc.text('/', (this.paperWidth / 2) - 3, doc.lastAutoTable.finalY + 20);

            doc.text('[Serial No.]', this.margin + 17.4, doc.lastAutoTable.finalY + 25);
            doc.line(this.margin + 35, doc.lastAutoTable.finalY + 26, (this.paperWidth / 2) - 5, doc.lastAutoTable.finalY + 26);

            // doc.rect(
            //     this.margin + 8,
            //     doc.lastAutoTable.finalY + 29,
            //     2.5,
            //     2.5,
            //     headerData.repair_name == 'Office Area' ? 'F' : 'S',
            // );
            doc.text('Facility', this.margin + 9, doc.lastAutoTable.finalY + 32);
            // doc.rect(
            //     this.margin + 32,
            //     doc.lastAutoTable.finalY + 29,
            //     2.5,
            //     2.5,
            //     headerData.repair_name == 'Store' ? 'F' : 'S',
            // );
            const facilities = [
                'Office Area',
                'Store',
                'Staff House',
            ];
            doc.text(facilities.includes(headerData.repair_name) ? headerData.repair_name : '', this.margin + 35, doc.lastAutoTable.finalY + 32);
            doc.line(this.margin + 35, doc.lastAutoTable.finalY + 33, (this.paperWidth / 2) - 5, doc.lastAutoTable.finalY + 33);
            // doc.rect(
            //     this.margin + 47.5,
            //     doc.lastAutoTable.finalY + 29,
            //     2.5,
            //     2.5,
            //     headerData.repair_name == 'Staff House' ? 'F' : 'S',
            // );
            // doc.text('Staff House', this.margin + 51.5, doc.lastAutoTable.finalY + 32);

            let types = [
                'Vehicle',
                'Equipment',
                'Office Area',
                'Store',
                'Staff House',
            ];

            // doc.rect(
            //     this.margin + 8,
            //     doc.lastAutoTable.finalY + 34,
            //     2.5,
            //     2.5,
            //     headerData.repair_type == 'Repair / Maintenance' && !types.includes(headerData.repair_name) ? 'F' : 'S',
            // );
            doc.text('Others', this.margin + 9, doc.lastAutoTable.finalY + 37);
            // doc.line(this.margin + 25, doc.lastAutoTable.finalY + 37, this.margin + 68, doc.lastAutoTable.finalY + 37);

            doc.text(
                headerData.repair_type == 'Repair / Maintenance' && !types.includes(headerData.repair_name)
                    ? headerData.other_repair_type ?? ''
                    : '',
                this.margin + 35,
                doc.lastAutoTable.finalY + 37,
            );
            doc.line(this.margin + 35, doc.lastAutoTable.finalY + 38, (this.paperWidth / 2) - 5, doc.lastAutoTable.finalY + 38);

            // doc.rect(
            //     (this.paperWidth / 2) + 2,
            //     doc.lastAutoTable.finalY + 2,
            //     2.5,
            //     2.5,
            //     headerData.repair_name == 'Installation' ? 'F' : 'S',
            // );
            doc.text('INSTALLATION (pls. provide details)', (this.paperWidth / 2) + 3, doc.lastAutoTable.finalY + 5);

            doc.line((this.paperWidth / 2) + 6, doc.lastAutoTable.finalY + 11, this.paperWidth - (this.margin * 1.5), doc.lastAutoTable.finalY + 11);

            doc.line((this.paperWidth / 2) + 6, doc.lastAutoTable.finalY + 16, this.paperWidth - (this.margin * 1.5), doc.lastAutoTable.finalY + 16);

            // doc.rect(
            //     (this.paperWidth / 2) + 2,
            //     doc.lastAutoTable.finalY + 17,
            //     2.5,
            //     2.5,
            //     headerData.repair_name == 'Rentals' ? 'F' : 'S',
            // );
            doc.text('Rentals (pls. provide details)', (this.paperWidth / 2) + 3, doc.lastAutoTable.finalY + 20);

            doc.line((this.paperWidth / 2) + 6, doc.lastAutoTable.finalY + 26, this.paperWidth - (this.margin * 1.5), doc.lastAutoTable.finalY + 26);

            types = [
                'Installation',
                'Rentals',
            ];

            // doc.rect(
            //     (this.paperWidth / 2) + 2,
            //     doc.lastAutoTable.finalY + 27,
            //     2.5,
            //     2.5,
            //     headerData.repair_type == 'INSTALLATION' && !types.includes(headerData.repair_name) ? 'F' : 'S',
            // );
            doc.text('Others (pls. provide details)', (this.paperWidth / 2) + 3, doc.lastAutoTable.finalY + 30);

            doc.line((this.paperWidth / 2) + 6, doc.lastAutoTable.finalY + 38, this.paperWidth - (this.margin * 1.5), doc.lastAutoTable.finalY + 38);
            doc.text(
                headerData.repair_type == null
                    ? headerData.other_repair_category
                    : headerData.repair_type == 'INSTALLATION' && !types.includes(headerData.repair_name)
                        ? headerData.other_repair_type ?? ''
                        : '',
                (this.paperWidth / 2) + 6,
                doc.lastAutoTable.finalY + 37,
            );



            doc.rect(this.margin, doc.lastAutoTable.finalY, (this.paperWidth - (this.margin * 2)), headerCheckBoxesContainerHeight);
            doc.line(this.paperWidth / 2, doc.lastAutoTable.finalY, this.paperWidth / 2, doc.lastAutoTable.finalY + headerCheckBoxesContainerHeight);

            this.headerStartY = this.margin;
            this.headerEndY = doc.lastAutoTable.finalY + headerCheckBoxesContainerHeight;
            this.headerWidth = this.paperWidth - (this.margin * 2);
            this.headerHeight = this.headerEndY - this.headerStartY;

            return this.headerEndY;
        },

        drawJobOrderPrintFooter(doc, footerData) {
            // data table 26
            // adjustment 4
            const footerHeight = footerData.payment_method == 3 ? 43 : 107;

            doc.autoTable({
                startY: this.paperHeight - (this.margin + footerHeight),
                margin: {
                    bottom: this.margin,
                    top: this.margin,
                    left: this.margin,
                    right: this.margin,
                },
                theme: 'grid',
                columnStyles: {
                    0 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 6},
                    1 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 6},
                    2 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 6},
                    3 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 6},
                    4 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 6},
                    5 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 6},
                },
                bodyStyles: {
                    minCellHeight: 13,
                    lineColor: [0, 0, 0],
                    textColor: [0, 0, 0],
                    fontSize: 8,
                    font: 'GILL'
                },
                body: [
                    [
                        {content: 'Prepared by', colSpan: 2},
                        {content: 'Checked by', colSpan: 2},
                        {content: 'Down Payment Amount', styles: {fontSize: 7, valign: 'middle'}},
                        {content: footerData.down_payment ? this.toCurrency(null, parseFloat(footerData.down_payment), 2) : 'N/A', styles: {valign: 'middle', halign: 'right'}},
                    ],
                    [
                        {content: 'Noted by', colSpan: 2},
                        {content: 'Approved by', colSpan: 2},
                        {
                            content: 'Date Released\n\nDP Reference Document',
                            styles: {valign: 'middle'},
                        },
                        '',
                    ],
                    [
                        {content: 'Approved by (Finance and Acctg)', colSpan: 2},
                        {content: 'Processed by (Finance and Acctg)', colSpan: 2},
                        {
                            content: 'JO No.',
                            colSpan: 2,
                            styles: {
                                valign: 'middle',
                                halign: 'left',
                                fontSize: 15,
                                fontStyle: 'bold',
                            },
                        },
                    ],
                ],
                didDrawCell: (data) => {
                    let cellText = '';

                    if (data.row.index == 0 && data.column.index == 0) cellText = `\n${footerData.requestor_name ?? ''}`;
                    else if (data.row.index == 0 && data.column.index == 2) cellText = '';
                    else if (data.row.index == 1 && data.column.index == 0) cellText = `\n${footerData.noter_name ?? ''}`;
                    else if (data.row.index == 1 && data.column.index == 2) cellText = `\n${footerData.approver_name ?? ''}`;
                    else if (data.row.index == 2 && data.column.index == 4) cellText = footerData.jo_number ?? '';

                    if (data.row.index == 6 && data.column.index == 0) cellText = `\n${footerData.requestor_name ?? ''}`;
                    else if (data.row.index == 6 && data.column.index == 2) cellText = `\n${footerData.approver_name ?? ''}`;

                    doc.autoTable({
                        startY: data.cell.y,
                        margin: {
                            top: data.cell.y,
                            bottom: this.paperHeight - (data.cell.y + data.cell.height),
                            left: data.cell.x,
                            right: this.paperWidth - (data.cell.x + data.cell.width),
                        },
                        theme: 'plain',
                        bodyStyles: {
                            minCellHeight: data.row.index === 2 && data.column.index === 4 ? 13 : 12,
                            valign: 'end',
                            halign: 'center',
                            lineColor: [0, 0, 0],
                            textColor: [0, 0, 0],
                            fontSize: 8,
                            font: 'GILL'
                        },
                        body: [
                            [{
                                content: cellText,
                                styles: data.row.index === 2 && data.column.index === 4
                                    ? {
                                        valign: 'middle',
                                        halign: 'right',
                                        textColor: [255, 0, 0],
                                        fontSize: 10,
                                        fontStyle: 'bold',
                                    } : {},
                            }],
                        ],
                    });
                },
            });

            this.footerStartY = doc.previousAutoTable.settings.startY;

            if (footerData.payment_method != 3) {
                doc.autoTable({
                    startY: doc.lastAutoTable.finalY,
                    margin: {
                        bottom: this.margin,
                        top: this.margin,
                        left: this.margin,
                        right: this.margin,
                    },
                    theme: 'plain',
                    columnStyles: {
                        0 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 3},
                        1 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 3},
                        2 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 3},
                    },
                    bodyStyles: {
                        lineColor: [0, 0, 0],
                        textColor: [0, 0, 0],
                        fontSize: 8,
                        font: 'GILL'
                    },
                    body: [
                        [
                            {
                                content: '--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------',
                                colSpan: 3,
                                styles: {
                                    halign: 'center',
                                },
                            },
                        ],
                        [
                            {
                                content: 'FULL PAYMENT DETAILS (to be filed out upon completion of the Project)',
                                colSpan: 3,
                                styles: {
                                    fillColor: this.headerColor,
                                    halign: 'center',
                                    fontStyle: 'italic',
                                },
                            },
                        ],
                        [
                            {content: 'Full Payment Amount', styles: {minCellHeight: 13}},
                            {content: 'Date Released', styles: {minCellHeight: 13}},
                            {content: 'FP Reference Document', styles: {minCellHeight: 13}},
                        ],
                        [
                            {
                                content: 'By endorsing this form for final payment processing, I acknowledge that all work, including construction, fabrication, installation, repairs, and maintenance, is complete and accept responsibility for any issues that may arise following confirmation of completion.\n\n\n\nSignature Over Printed Name/ Date',
                                colSpan: 3,
                                styles: {
                                    halign: 'center',
                                    fontStyle: 'italic',
                                },
                            }
                        ],
                        [
                            {content: `Prepared by`, styles: {minCellHeight: 13}},
                            {content: `Approved by`, styles: {minCellHeight: 13}},
                            {content: `Processed by`, styles: {minCellHeight: 13}},
                        ],
                    ],
                    didDrawCell: (data) => {
                        if (data.row.index != 0) {
                            doc.rect(
                                data.cell.x,
                                data.cell.y,
                                data.cell.width,
                                data.cell.height,
                            );
                        }

                        if ([4, 2].includes(data.row.index)) {
                            let cellText = '';


                            if (data.row.index == 2 && data.column.index == 0) cellText = `\n${footerData.full_payment_amount ? this.toCurrency(null, footerData.full_payment_amount, 2) : ''}`;
                            else if (data.row.index == 4 && data.column.index == 0) cellText = `\n${footerData.requestor_name ?? ''}`;
                            else if (data.row.index == 4 && data.column.index == 2) cellText = `\n${footerData.approver_name ?? ''}`;

                            doc.autoTable({
                                startY: data.cell.y,
                                margin: {
                                    top: data.cell.y,
                                    bottom: this.paperHeight - (data.cell.y + data.cell.height),
                                    left: data.cell.x,
                                    right: this.paperWidth - (data.cell.x + data.cell.width),
                                },
                                theme: 'plain',
                                bodyStyles: {
                                    minCellHeight: 12,
                                    valign: 'end',
                                    halign: 'center',
                                    lineColor: [0, 0, 0],
                                    textColor: [0, 0, 0],
                                    fontSize: 8,
                                    font: 'GILL'
                                },
                                body: [
                                    [{
                                        content: cellText,
                                    }],
                                ],
                            });
                        }
                    },
                });
            }

            this.footerEndY = doc.previousAutoTable.finalY;
            this.footerWidth = this.paperWidth - (this.margin * 2);
            this.footerHeight = footerHeight;

            doc.setFont("GILL", "italic");
            doc.setFontSize(7);
            doc.text(
                'ADM-010-v01-2021',
                this.margin,
                doc.previousAutoTable.finalY + 3,
            );

            doc.setFont("GILL", "normal");
            // doc.text(
            //     `Page ${doc.internal.getNumberOfPages()}`,
            //     this.margin,
            //     272,
            // );

            doc.setFont("GILL", "italic");
            doc.text(
                '1 - Requestor 2 - Accounting 3 - Admin',
                170,
                doc.previousAutoTable.finalY + 3,
            );

            return this.footerEndY;
        },

        drawJobOrderPrintContent(doc, contentData, data) {
            doc.autoTable({
                startY: this.headerEndY,
                margin: {
                    top: this.headerEndY,
                    bottom: this.footerHeight + this.margin,
                    left: this.margin,
                    right: this.margin,
                },
                theme: 'grid',
                columnStyles: {
                    0: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    1: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    2: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    3: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    4: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    5: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    6: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    7: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    8: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    9: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    10: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    11: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                },
                headStyles: {
                    halign: 'center',
                    lineColor: [0, 0, 0],
                    fillColor: this.headerColor,
                    textColor: [0, 0, 0],
                    fontStyle: 'italic',
                    fontSize: 8,
                    font: 'GILL'
                },
                head: [
                    [
                        {content: 'DESCRIPTION OF WORK', colSpan: 10},
                        {content: 'LABOR COST', colSpan: 2},
                    ],
                ],
                bodyStyles: {
                    lineColor: [0, 0, 0],
                    textColor: [0, 0, 0],
                    fontSize: 8,
                    font: 'GILL'
                },
                body: [
                    ...contentData.jobs.map(content => [
                        {content: content.item_name ?? '', colSpan: 10, styles: {halign: 'center', fontStyle: 'italic'}},
                        {
                            content: this.toCurrency(null, content.total_amount, 2),
                            colSpan: 2,
                            styles: {halign: 'right'},
                        },
                    ]),
                    // [
                    //     {content: 'INSTALLATION OF 14 CCTV CAMERA @800/CAM', colSpan: 10, styles: {halign: 'center'}},
                    //     {content: '11,200.00', colSpan: 2, styles: {halign: 'right'}},
                    // ],
                    // [
                    //     {content: 'INSTALLATION OF 14 CCTV CAMERA @800/CAM', colSpan: 10, styles: {halign: 'center'}},
                    //     {content: '11,200.00', colSpan: 2, styles: {halign: 'right'}},
                    // ],
                    // [
                    //     {content: 'INSTALLATION OF 14 CCTV CAMERA @800/CAM', colSpan: 10, styles: {halign: 'center'}},
                    //     {content: '11,200.00', colSpan: 2, styles: {halign: 'right'}},
                    // ],

                    // content footer
                    [
                        {content: `No. of days to work\n${contentData.jobs[0].working_days + 1} ${(contentData.jobs[0].working_days + 1) > 1 ? 'days' : 'day'}`, colSpan: 2, styles: { minCellHeight: 10, halign: 'center' }},
                        {content: `Date Start           ${this.formatPrintDate(contentData.jobs[0].date_start)}`, colSpan: 3, styles: { minCellHeight: 10, valign: 'middle', halign: 'center' }},
                        {content: `Date Finish           ${this.formatPrintDate(contentData.jobs[0].date_finish)}`, colSpan: 3, styles: { minCellHeight: 10, valign: 'middle', halign: 'center' }},
                        {content: 'TOTAL Labor Cost', colSpan: 2, styles: { minCellHeight: 10, valign: 'middle', halign: 'center', fontSize: 9, fontStyle: 'bold' }},
                        {
                            content: data.total_labor_cost,
                            colSpan: 2,
                            styles: { minCellHeight: 10, valign: 'middle', halign: 'right', fontStyle: 'bold' },
                        },
                    ],
                ],
                didDrawCell: (data) => {
                    if (data.section === 'head') {
                        doc.rect(
                            data.cell.x,
                            data.cell.y,
                            data.cell.width,
                            data.cell.height,
                        );
                    }
                },
                didDrawPage: (data) => {
                    if (doc.getNumberOfPages() > 1) {
                        this.drawJobOrderPrintHeader(doc, this.printData.header);
                        this.drawJobOrderPrintFooter(doc, this.printData.footer);
                    }
                },
            });

            doc.autoTable({
                startY: doc.lastAutoTable.finalY,
                margin: {
                    top: this.headerEndY,
                    bottom: this.footerHeight + this.margin,
                    left: this.margin,
                    right: this.margin,
                },
                theme: 'grid',
                columnStyles: {
                    0: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    1: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    2: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    3: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    4: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    5: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    6: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    7: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    8: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    9: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    10: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    11: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                },
                headStyles: {
                    halign: 'center',
                    lineColor: [0, 0, 0],
                    fillColor: this.headerColor,
                    textColor: [0, 0, 0],
                    fontStyle: 'italic',
                    fontSize: 8,
                },
                head: [
                    [
                        {content: 'Line'},
                        {content: 'Description of MATERIALS / SUPPLIES / PARTS NEEDED', colSpan: 6},
                        {content: 'QTY'},
                        {content: 'UOM'},
                        {content: 'Unit Price'},
                        {content: 'Amount', colSpan: 2},
                    ],
                ],
                bodyStyles: {
                    lineColor: [0, 0, 0],
                    textColor: [0, 0, 0],
                    fontSize: 8,
                    font: 'GILL'
                },
                body: [
                    ...this.populateMaterials(contentData.materials),

                    // content footer
                    [
                        {content: `Remarks\n\n${contentData.jo_details.remarks ?? ''}`, colSpan: 8, rowSpan: 2},
                        {content: 'TOTAL Material Cost', colSpan: 2, styles: {halign: 'center', valign: 'middle', fontSize: 7, fontStyle: 'bold'}},
                        {
                            content: data.total_material_cost,
                            colSpan: 2,
                            styles: {valign: 'middle', halign: 'right', fontStyle: 'bold'},
                        },
                    ],
                    [
                        {content: 'TOTAL Cost', colSpan: 2, styles: {fontStyle: 'bold', halign: 'center'}},
                        {
                            content: data.total_amount,
                            colSpan: 2,
                            styles: {valign: 'middle', halign: 'right', fontStyle: 'bold'},
                        },
                    ],
                ],
                didDrawCell: (data) => {
                    if (data.section === 'head') {
                        doc.rect(
                            data.cell.x,
                            data.cell.y,
                            data.cell.width,
                            data.cell.height,
                        );
                    }
                },
                didDrawPage: (data) => {
                    if (doc.getNumberOfPages() > 1) {
                        this.drawJobOrderPrintHeader(doc, this.printData.header);
                        this.drawJobOrderPrintFooter(doc, this.printData.footer);
                    }
                },
            });

            let pageInfo = doc.internal.getCurrentPageInfo();

            for (let i = 0; i < pageInfo.pageNumber; i++) {
                doc.setPage(i + 1);
                // doc.text(`Page ${i + 1} out of ${pageInfo.pageNumber}`, 18, paperHeight - 18);
                doc.setTextColor('black');
                doc.setFont("GILL", "normal");
                doc.text(
                    `Page ${i + 1} out of ${pageInfo.pageNumber}`,
                    this.paperWidth - (this.margin + 20),
                    272,
                );
            }
        },

        printJobOrder() {
            const doc = new this.$jspdf({
                orientation: 'portrait',
                unit: 'mm',
                format: 'letter',
            });

            this.printData = this.GET_JOB_ORDERS.PRINT_DATA;

            this.drawJobOrderPrintHeader(doc, this.printData.header);
            this.drawJobOrderPrintFooter(doc, this.printData.footer);
            this.drawJobOrderPrintContent(doc, this.printData.content, this.printData.header);

            doc.autoPrint();
            window.open(doc.output("bloburl"), "_blank");
            this.$store.commit('JO_PRINT', false);
        },

        populateMaterials(materials) {
            let items = [];

            if (materials.length > 0) {
                items = materials.map((material, index) => [
                    {content: index + 1},
                    {content: material.name, colSpan: 6},
                    {content: this.thousandSeprator(material.quantity)},
                    {content: material.uom},
                    {content: this.getTwoDecimals(material.price), styles: {halign: 'right'}},
                    {content: this.getTwoDecimals(material.price * material.quantity), colSpan: 2, styles: {halign: 'right'}},
                ]);
            } else {
                items = [[
                    {content: ''},
                    {content: '', colSpan: 6},
                    {content: ''},
                    {content: ''},
                    {content: '', styles: {halign: 'right'}},
                    {content: '', colSpan: 2, styles: {halign: 'right'}},
                ]];
            }

            return items;
        },
        formatPrintDate(dateString) {
            // Parse the date string
            const date = new Date(dateString);

            // Extract the month, day, and year
            const month = date.getMonth() + 1; // Months are zero-based
            const day = date.getDate();
            const year = date.getFullYear();

            // Return the formatted date
            return `${month}/${day}/${year}`;
        },
    },
    watch: {
        'GET_JOB_ORDERS.PRINT':{
            handler(val){
                if(val){
                    this. printJobOrder();
                }
            }
        }
    },
}
</script>