import { render, staticRenderFns } from "./ItemsTableComponent.vue?vue&type=template&id=b9435a1c&scoped=true"
import script from "./ItemsTableComponent.vue?vue&type=script&lang=js"
export * from "./ItemsTableComponent.vue?vue&type=script&lang=js"
import style0 from "./ItemsTableComponent.vue?vue&type=style&index=0&id=b9435a1c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9435a1c",
  null
  
)

export default component.exports