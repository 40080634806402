<template>
<div class="w-100">
    <v-app id="load_bar" v-if="!loaded">
        <v-text-field color="info" loading disabled></v-text-field>
    </v-app>
    <v-app id="my_vue_app">
        <v-card v-show="loaded">
            <div class="row mx-auto mt-1">
                <div class="col-sm-12 col-md-2">
                    <v-autocomplete outlined dense
                        :items="customers_list"
                        :loading="loading.customer_selection"
                        :disabled="loading.customer_selection || disabled_customer_selection"
                        id="customer_id"
                        v-model="customer_id"
                        item-value="value"
                        auto-select-first
                        label="Customer"
                        item-text="text"
                        class="w-100"
                    >
                    </v-autocomplete>
                </div>
                <div v-if="!service" class="col-sm-12 col-md-2">
                    <v-autocomplete outlined dense
                        :items="salesman_list"
                        :loading="loading.salesman_selection"
                        :disabled="loading.salesman_selection"
                        id="salesman_id"
                        label="Salesman"
                        v-model="salesman_id"
                        item-value="value"
                        auto-select-first
                        item-text="text"
                        class="w-100"
                    >
                    </v-autocomplete>
                </div>
                <div class="col-sm-12 col-md-2">
                    <v-text-field
                    type="text"
                    outlined
                    dense
                    class="w-100"
                    label="Issuer"
                    v-model="prepared_by" />
                </div>
                <div class="col-sm-12 col-md-1">
                    <v-btn @click="getAllPackingLists()">Filter</v-btn>
                </div>

                <!-- <v-col sm="12" md="5" lg="5" class="text-right mb-3" align-self="start">
                    <a href="#" class="badge badge-all mr-2" @click="status_id=-1; getAllPackingLists()">all</a>
                    <a href="#" class="badge badge-saved mr-2" @click="status_id=0; getAllPackingLists()">pending</a>
                    <a href="#" class="badge badge-submitted mr-2" @click="status_id=5; getAllPackingLists()">submitted</a>
                    <a href="#" class="badge badge-purple mr-2" @click="status_id=1; getAllPackingLists()">confirmed</a>
                    <a href="#" class="badge badge-canceled mr-2" @click="status_id=3; getAllPackingLists()">canceled</a>
                </v-col> -->
            </div>
            <div class="row mx-auto">
                 <div class="col-sm-12 col-md-2">
                    <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="date_range[0]"
                                v-on="on"
                                label="Date From"
                                append-icon="mdi-calendar-clock"
                                dense
                                outlined
                                hide-details
                                readonly
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="date_range[0]" @input="menu = false"></v-date-picker>
                    </v-menu>
                </div>
                <div class="col-sm-12 col-md-2">
                     <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="date_range[1]"
                                v-on="on"
                                label="Date To"
                                append-icon="mdi-calendar-clock"
                                dense
                                outlined
                                hide-details
                                readonly
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="date_range[1]" @input="menu2 = false"></v-date-picker>
                    </v-menu>
                </div>
            </div>

            <v-row>
                <v-spacer></v-spacer>
                <div class="row col-sm-12 col-md-4 mr-3 align-items-end">
                    <v-text-field
                        id="searchBar"
                        v-model="search"
                        label="Search PL# / CF# / SO#"
                        single-line
                        hide-details
                    >
                    </v-text-field>
                    <v-icon @click="searchInput()">mdi-magnify</v-icon>
                </div>
            </v-row>

            <v-container fluid>
                <v-data-table
                    :headers="headers"
                    :items="packingLists"
                    :search="search"
                    :options.sync="options"
                    :server-items-length="totalPackingLists"
                    :items-per-page="10"
                    :footer-props="{
                        itemsPerPageOptions: itemsPerPageOptions
                    }"
                    class="w-100"
                    v-if="is_stockcard==0"
                >

                    <template v-slot:[`item.packing_list_date`]="{ item }">
                        <span class="text-nowrap">
                            {{ item.packing_list_date | formatDate }}
                        </span>
                    </template>

                    <template v-slot:[`item.customer_name`]="{ item }">
                        <span style="font-size:10px !important;">
                            {{ item.customer_name  }}
                        </span>
                    </template>

                    <template v-slot:[`item.served_amount`]="{ item }">
                        <span class="text-nowrap">
                            &#x20b1;{{ item.served_amount | currency }}
                        </span>
                    </template>

                    <template v-slot:[`item.stock_card`]="{ item }">
                        <span class="badge bg-primary" v-if="item.stock_card">Yes</span>
                        <span class="badge bg-danger" v-else>No</span>
                    </template>

                    <template v-slot:[`item.total_amount`]="{ item }">
                        <span class="text-nowrap">
                            &#x20b1;{{ item.total_amount | currency }}
                        </span>
                    </template>

                    <template v-slot:[`item.created_at`]="{ item }">
                        <span class="text-nowrap">
                            {{ item.created_at | formatDateTime }}
                        </span>
                    </template>

                    <template v-slot:[`item.promo_id`]="{ item }">
                        <span class="badge bg-primary" v-if="item.promo_id != null">Yes</span>
                        <span class="badge bg-danger" v-else>No</span>
                    </template>

                    <template v-slot:[`item.printed`]="{ item }">
                        <span class="badge badge-primary" v-if="item.printed == 1">Yes</span>
                        <span class="badge badge-danger" v-else>No</span>
                    </template>

                    <template v-slot:[`item.status_text`]="{ item }">
                        <span class="badge badge-saved" v-if="item.status==0">pending</span>
                        <span class="badge badge-submitted" v-if="item.status==5">submitted</span>
                    </template>

                    <template v-slot:[`item.action`]="{ item }">
                        <span class="text-nowrap">
                            <v-btn text icon color="orange">
                                <v-icon class="btn-action" small @click="viewPackingList(item.id)">mdi-eye</v-icon>
                            </v-btn>
                            <v-btn text icon color="blue" v-if="userAccess.edit && item.status == 0 && is_stockcard == 0" v-show="!is_preparation && !is_accounting">
                                <v-icon class="btn-action" small @click="editPackingList(item.id)">mdi-pencil</v-icon>
                            </v-btn>
                            <!-- <v-btn text icon color="red" v-if="userAccess.delete && !is_preparation && !is_accounting">
                                <v-icon class="btn-action" small @click="initiateOverride('delete', item.id, true)">fas fa-trash-alt</v-icon>
                            </v-btn> -->
                            <v-btn text icon color="red" v-if="userAccess.cancel && !is_preparation && !is_accounting">
                                <v-icon class="btn-action" small @click="initiateOverride('cancel', item.id, true)">mdi-ban</v-icon>
                            </v-btn>
                        </span>
                    </template>

                </v-data-table>

                <v-data-table
                    :headers="headers_SC"
                    :items="packingLists"
                    :search="search"
                    :options.sync="options"
                    :server-items-length="totalPackingLists"
                    :items-per-page="10"
                    :footer-props="{
                        itemsPerPageOptions: itemsPerPageOptions
                    }"
                    class="w-100"
                    v-else
                >

                    <template v-slot:[`item.packing_list_date`]="{ item }">
                        <span class="text-nowrap">
                            {{ item.packing_list_date | formatDate }}
                        </span>
                    </template>

                    <template v-slot:[`item.customer_name`]="{ item }">
                        <span style="font-size:10px !important;">
                            {{ item.customer_name  }}
                        </span>
                    </template>

                    <template v-slot:[`item.served_amount`]="{ item }">
                        <span class="text-nowrap">
                            &#x20b1;{{ item.served_amount | currency }}
                        </span>
                    </template>

                    <template v-slot:[`item.created_at`]="{ item }">
                        <span class="text-nowrap">
                            {{ item.created_at | formatDateTime }}
                        </span>
                    </template>

                    <!-- item.printed -->
                    <template v-slot:[`item.printed`]="{ item }">
                        <span class="badge badge-primary" v-if="item.printed">Yes</span>
                        <span class="badge badge-danger" v-else>No</span>
                    </template>

                    <template v-slot:[`item.status_text`]="{ item }">
                        <span class="badge badge-saved" v-if="item.status==0">pending</span>
                        <span class="badge badge-submitted" v-if="item.status==5">submitted</span>
                    </template>

                    <template v-slot:[`item.action`]="{ item }">
                        <span class="text-nowrap">
                            <v-btn text icon color="orange">
                                <v-icon class="btn-action" small @click="viewPackingList(item.id)">mdi-eye</v-icon>
                            </v-btn>
                            <v-btn text icon color="blue" v-if="userAccess.edit && item.status == 0 && is_stockcard == '0'" v-show="!is_preparation && !is_accounting">
                                <v-icon class="btn-action" small @click="editPackingList(item.id)">mdi-pencil</v-icon>
                            </v-btn>
                            <!-- <v-btn text icon color="red" v-if="userAccess.delete && !is_preparation && !is_accounting && is_stockcard == '0'">
                                <v-icon class="btn-action" small @click="currentPackingListId=item.id; override.approve_override_delete_dialog=true">fas fa-trash-alt</v-icon>
                            </v-btn> -->
                        </span>
                    </template>

                </v-data-table>
            </v-container>
        </v-card>
    </v-app>

    <!-- <confirm-override-component
        :approve_override_dialog="override.approve_override_delete_dialog"
        :departments_allowed="override.override_departments_allowed"
        :heading="override.override_heading"
        @closeConfirmOverride="closeConfirmOverride"
    ></confirm-override-component> -->

    <!-- VIEW MODAL-->
    <PackingListsViewComponentVue
        :viewPackingListId="viewPackingListId"
        :is_stockcard="is_stockcard" @closeView="closeView"
        :is_preparation="is_preparation"
        :unconfirmed="unconfirmed"
        :service="service"
    ></PackingListsViewComponentVue>

    <ConfirmOverrideComponentVue
        :approve_override_dialog="override.dialog"
        :departments_allowed="override.departments_allowed"
        :heading="override.heading"
        @closeConfirmOverride="closeConfirmOverride"
    ></ConfirmOverrideComponentVue>

</div>
</template>

<script>
import PackingListsViewComponentVue from './PackingListsViewComponent.vue';
import ConfirmOverrideComponentVue from '@/views/main/Utils/ConfirmOverrideComponent.vue';
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
import $ from 'jquery';

export default {
    components: {
        PackingListsViewComponentVue,
        ConfirmOverrideComponentVue
    },
    props: [
        'packingListsActionResult',
        'tab_name',
        'is_preparation',
        'unconfirmed',
        'is_stockcard',
        'is_accounting',
        'service'
    ],
    data () {
        return {
            override: {
                dialog: false,
                heading: 'Packing List',
                departments_allowed: ['AC', 'EX', 'IT'],
                authenticated: false,
                action: '',
            },
            reason: {
                text: '',
            },
            actionResult : {
                model : 'PackingLists',
                action : ''
            },
            loaded: false,
            totalPackingLists: 0,
            options: {},
            packingLists: [],
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            itemsPerPage: 10,
            search: '',
            awaitingSearch: false,
            menu: false,
            menu2: false,
            date_range: [
                this.$dayjs().startOf('month').format('YYYY-MM-DD'),
                this.$dayjs().endOf('month').format('YYYY-MM-DD')
            ],
            headers: [
                { text: 'PL Num', align: 'left', value: 'packing_list_num', },
                { text: 'CF Num', align: 'left', value: 'checker_form_id', },
                { text: 'SO Num', align: 'left', value: 'order_num', },
                { text: 'Warehouse', align: 'left', value: 'warehouse_code', },
                { text: 'PL Date', align: 'left', value: 'packing_list_date', },
                { text: 'Customer', align: 'left', value: 'customer_name', },
                { text: 'Salesman', align: 'left', value: 'salesman_name', },
                // { text: 'Served Amount', align: 'right', value: 'served_amount', },
                { text: 'SC', align: 'center', value: 'stock_card' },
                { text: 'Total Amount', align: 'right', value: 'total_amount', },
                { text: 'Status', align: 'left', value: 'status_text', },
                { text: 'Date Issued', align: 'left', value: 'created_at', },
                { text: 'Issuer', align: 'left', value: 'prepared_by', },
                { text: 'Promo', align: 'left', value: 'promo_id', },
                { text: 'Printed', align: 'left', value: 'printed', },
                { text: 'Actions', align: 'center', value: 'action', sortable: false },
            ],
            preparationHeaders:  [
                { text: 'PL Num', align: 'left', value: 'packing_list_num', },
                { text: 'Customer', align: 'left', value: 'customer_name', },
                { text: 'Salesman', align: 'left', value: 'salesman_name', },
                { text: 'Status', align: 'left', value: 'status_text', },
                { text: 'Date Issued', align: 'left', value: 'created_at', },
                { text: 'Issuer', align: 'left', value: 'issuer_name', },
                { text: 'Promo', align: 'left', value: 'promo_id', },
                { text: 'Actions', align: 'center', value: 'action', sortable: false },
            ],
            headers_SC: [
                { text: 'PL Num', align: 'left', value: 'packing_list_num', },
                { text: 'SO Num', align: 'left', value: 'order_num', },
                { text: 'PL Date', align: 'left', value: 'packing_list_date', },
                { text: 'Customer', align: 'left', value: 'customer_name', },
                { text: 'Salesman', align: 'left', value: 'salesman_name', },
                // { text: 'Served Amount', align: 'right', value: 'served_amount', },
                { text: 'Status', align: 'left', value: 'status_text', },
                { text: 'Date Issued', align: 'left', value: 'created_at', },
                { text: 'Issuer', align: 'left', value: 'prepared_by', },
                { text: 'Promo', align: 'left', value: 'promo_id', },
                { text: 'Printed', align: 'left', value: 'printed', },
                { text: 'Actions', align: 'center', value: 'action', sortable: false },
            ],

            viewPackingListId: '',
            currentPackingListId: '',

            customers_list: [],
            customer_id: '',
            salesman_list: [],
            salesman_id: '',
            status_id: -1,
            prepared_by: '',

            userAccess: {
                view: false,
                edit: false,
                delete: false,
                cancel: false,
            },
            isSalesman: false,
            salesmanTitle: '',
            salesmanChildrenIds: [],
            userChildrenIds: [],
            salesmanDetails: null,
            isSalesCoordinator: false,
            currentSalesmanId: '',
            loading:{
                customer_selection : true,
                salesman_selection : true
            }
        }
    },
    async mounted(){
        // this.checkAccess('AC', 'packing_list', 'view')
        // this.checkAccess('AC', 'packing_list', 'edit')
        // this.checkAccess('AC', 'unconfirmed_pl', 'view')
        // this.checkAccess('AC', 'packing_list', 'delete')
        // this.checkAccess('AC', 'packing_list', 'cancel')
        this.getCustomers()
        this.getSalesmanList()
        this.checkAccess();

        if(this.is_preparation){
            this.headers = this.preparationHeaders
        }
        // this.addKeyListener()
        // $('input').attr('autocomplete','off');
        this.getCurrentSalesman();
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS',
            'GET_ALL_PACKING_LISTS',
            'GET_SALESMAN_LISTS',
            'GET_ALL_USER',
            'GET_CURRENT_SALESMAN',
            'GET_CUSTOMERS_LIST_SELECTION',
            'GET_SALESMAN_SELECTION'
        ]),
    },
    methods:{
        initiateOverride(action = null, id, dialog_status){
            switch (action) {
                case 'delete':
                    this.override.heading = 'Delete Packing List'
                    this.override.departments_allowed = ['AC', 'EX', 'IT']
                    break
                case 'cancel':
                    this.override.heading = 'Cancel Packing List'
                    this.override.departments_allowed = ['AC', 'EX', 'IT']
                    break
            }

            this.override.dialog = dialog_status
            this.override.action = action
            this.currentPackingListId = id
        },
        closeConfirmOverride(user_id, authenticated = false){
            this.override.dialog = false
            if (authenticated) {
                this.override.authenticated = true
                switch (this.override.action) {
                    case 'delete':
                        this.deletePackingList(this.currentPackingListId)
                        break
                    case 'cancel':
                        this.cancelPackingList(this.currentPackingListId)
                        break
                }
            }
        },
        getAllPackingLists(){
            this.loaded = false

            let payload = {
                page:               this.options.page,
                itemsPerPage:       this.options.itemsPerPage,
                sortBy:             this.options.sortBy,
                sortDesc:           this.options.sortDesc,
                search:             this.search,
                unconfirmed:        this.unconfirmed,
                is_stockcard:       this.is_stockcard,
                status_filter:      5,
                customer_id:        this.customer_id,
                salesman_id:        this.salesman_id,
                prepared_by:        this.prepared_by,
                service:            this.service,
                print_status:       1,
                url:                'getAll',
                packing_list_dates: this.date_range,

            }
            this.$store.dispatch('getPL',payload).then(response => {
                this.packingLists        = response.data.data.data
                this.loaded              = true
                this.totalPackingLists   = response.data.data.total
            })

            // this.$store.dispatch('getAllPackingLists',payload).then(response => {
            //     this.packingLists        = this.GET_ALL_PACKING_LISTS.data.data
            //     this.loaded              = true
            //     this.totalPackingLists   = this.GET_ALL_PACKING_LISTS.total
            // })
        },
        viewPackingList(id){
            this.viewPackingListId = id
        },
        editPackingList(id){
            this.$emit('showEditModal', id);
        },
        deletePackingList(id){
            if(this.override.override_authenticated){
                swal.fire({
                    title: "",
                    text: "Are you sure you want to Delete?",
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch('deletePackingLists',id).then(response => {
                            if (response.data.status == 'success') {
                                this.resetFields()
                                this.getAllPackingLists()
                            }

                            swal.fire(response.data.msg, {icon: response.data.status,});
                        }).catch(error => {
                            this.resetFields()
                            swal.fire("An Error Occured", error, "error");
                        })
                    }else{
                        this.resetFields()
                    }
                });
            }
        },
        cancelPackingList(id){
            this.errors = []
            this.status_action_text = 'cancel'

            swal.fire({
                title: "",
                text: "Are you sure you want to " + this.status_action_text + " Packing List?",
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    swal.fire({
                        title: 'Cancel Reason Remarks',
                        content: {
                            element: 'input',
                            attributes: {
                                type: 'text',
                                placeholder: 'Enter reason',
                            },
                        },
                        buttons: {
                            confirm: true,
                        },
                    }).then((result) => {
                        if (result != '') {
                            this.reason.text = result
                            let payload = {
                                id: id,
                                params:{
                                    reason: this.reason.text
                                }
                            }
                            this.$store.dispatch('cancelPackingLists',payload).then(response => {
                                let data = response.data

                                if(data.error){
                                    swal.fire("", data.error, "warning");
                                    return false
                                }

                                swal.fire("", data.msg, "success");

                                this.getAllPackingLists()
                            })
                        }
                    })
                }
            });
        },
        closeView(action = false){
            this.viewPackingListId = 0
            if(action == 'convertSuccess' || action == 'updateStatusSuccess'){
                this.getAllPackingLists()
            }
        },
        searchInput(){
            this.getAllPackingLists(
                {

                    search:             this.search,
                    unconfirmed:        this.unconfirmed,
                    is_stockcard:       this.is_stockcard,
                    customer_id:        this.customer_id,
                    salesman_id:        this.salesman_id,
                    prepared_by:        this.prepared_by,
                }
            );
        },
        addKeyListener(){
            window.addEventListener("keyup", function(e) {
                if(e.keyCode == 13){
                    // if(document.activeElement.id == "searchBar"){
                        this.searchInput();
                        $("input").blur();
                    // }else if(document.activeElement.id == "status_id" || document.activeElement.id == "customer_id" || document.activeElement.id == "salesman_id"){
                    //     this.getAllPackingLists(status_filter);
                    // }
                }
            }.bind(this));
        },
        checkAccess(){
            this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id).then(response=>{
                let val = response;
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                if(val != "fail"){
                val.forEach((e) => {
                    if (e.actions_code == "edit"){
                        this.userAccess.edit = true;
                    }
                    if (e.actions_code == "view"){
                        this.userAccess.view = true;
                    }
                    if (e.actions_code == "delete"){
                        this.userAccess.delete = true;
                    }
                    if (e.actions_code == "cancel"){
                        this.userAccess.cancel = true;
                    }
                });
                }
            })
        },
        getCustomers() {
            this.$store.dispatch('getAllCustomers').then(response => {
                let lists = this.GET_CUSTOMERS_LIST_SELECTION;
                if(this.isSalesman && this.currentSalesmanPositionId != 1) {
                    if(this.isSalesCoordinator) {
                        let filtered_list = lists.filter(x=> x.sales_coordinator_id == this.currentSalesmanId);
                        this.customers_list = filtered_list;
                    } else {
                        let filtered_list = lists.filter(x=> _.compact(this.salesmanChildrenIds).includes(x.salesman_id));
                        this.customers_list = filtered_list
                    }
                } else {
                    this.customers_list = lists;
                }

                this.loading.customer_selection = false
            })
        },
        getSalesmanList(){
            this.$store.dispatch('getAllsalesmanSelection').then(response => {
                if(this.isSalesman && this.currentSalesmanPositionId != 1) {
                    let lists = this.GET_SALESMAN_SELECTION;
                    if(this.isSalesCoordinator) {
                        let filtered_list = lists.filter(x=> x.value == this.currentSalesmanId);
                        this.salesman_list = filtered_list;
                    } else if (_.compact(this.salesmanChildrenIds).length > 0) {
                        let filtered_list = lists.filter(x=>_.compact(this.salesmanChildrenIds).includes(x.value));
                        this.salesman_list = filtered_list
                    }
                } else {
                    this.salesman_list = response
                }

                this.loading.salesman_selection = false
            });
        },
        getUsers(){
            this.$store.dispatch('getAllUsersSelect').then(response => {
                if(this.isSalesman && this.currentSalesmanPositionId != 1) {
                    if(_.compact(this.salesmanChildrenIds).length > 0) {
                        let lists = response.data;
                        let filtered_list = lists.filter(x=>_.compact(this.userChildrenIds).includes(x.value));
                        this.users_list = filtered_list
                    }
                } else {
                    this.users_list = response.data
                }
            });
        },
        resetFields(){
            this.errors = []
        },
        getCurrentSalesman() {
            this.$store.dispatch('getCurrentSalesman').then(response=>{
                this.salesmanDetails = response.data;
            });
        },
    },
    watch: {
        tab_name: function() {
            this.getAllPackingLists();
        },
        search() {
            if(!this.search && this.service == 1) {
                this.getAllPackingLists();
            }
        },
        packingListsActionResult: function(val){
            if(this.packingListsActionResult=='success'){
                this.getAllPackingLists()
            }
        },
        options: {
            handler () {
                this.getAllPackingLists()

            },
            deep: true,
        },
        salesmanDetails() {
            if(!!this.salesmanDetails) {
                this.isSalesman = this.salesmanDetails.isSalesman;
                this.salesmanTitle = this.salesmanDetails.salesmanPositionTitle;
                this.salesmanChildrenIds = this.salesmanDetails.salesmanChildrenIds;
                this.userChildrenIds = this.salesmanDetails.userChildrenIds;

                if(!!this.salesmanDetails.salesman) {
                    this.currentSalesmanId = this.salesmanDetails.salesman.id;
                    this.isSalesCoordinator = !!this.salesmanDetails.salesman.sales_coordinator;
                    this.currentSalesmanPositionId = this.salesmanDetails.salesman.salesman_position_id;
                }
            }
        },
        /*search: {
            handler () {
            if (!this.awaitingSearch) {
                setTimeout(() => {
                    this.getAllPackingLists({ query: this.search });
                    this.awaitingSearch = false;
                }, 2000); // 1 sec delay
            }
                this.awaitingSearch = true;
            },
            deep: true,
        },*/
    }
};
</script>
