<template>
    <div class="w-100">
        <v-card elevation="0">
            <v-app class="pa-1 px-5">
                <v-row dense class="my-2">
                    <v-col sm="12" md="4">
                        <!-- <v-autocomplete
                            v-model="filters.customers"
                            :items="GET_CUSTOMERS_SELECTION"
                            :loading="!GET_CUSTOMERS_SELECTION"
                            :disabled="!GET_CUSTOMERS_SELECTION"
                            label="Select Customers"
                            class="d-inline-block w-100"
                            outlined
                            dense
                            multiple
                            background-color="white"
                            small-chips
                            hide-details
                            clearable
                            deletable-chips
                            @change="onSelectCustomers"
                            >
                            </v-autocomplete> -->
                    </v-col>
                    <v-col sm="12" md="2">
                        <!-- <v-autocomplete
                            :items="payments.selection"
                            v-model="payments.selected"
                            dense
                            outlined
                            item-value="id"
                            item-text="name"
                            label="Payment Type"
                            background-color="white"
                            multiple
                            small-chips
                            hide-details
                            clearable
                            deletable-chips
                        /> -->
                    </v-col>
                    <v-col sm="12" md="2">
                            <!-- <v-autocomplete
                                :items="banks.selection"
                                v-model="banks.selected"
                                dense
                                outlined
                                item-value="id"
                                item-text="name"
                                label="Banks"
                                background-color="white"
                                multiple
                                small-chips
                                hide-details
                                clearable
                                deletable-chips
                            /> -->
                        </v-col>
                    <v-col sm="12" md="2">
                        <!-- <v-btn @click="getPaymentTransmittal()">Filter</v-btn> -->
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col sm="12" md="2" class="d-flex flex-row">
                        <!-- <v-menu
                                v-model="menu.date_from"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="filters.date_from"
                                        prepend-inner-icon="mdi-calendar"
                                        outlined
                                        dense
                                        readonly
                                        v-on="on"
                                        style="display: block"
                                        background-color="white"
                                        label="Date From"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="filters.date_from"
                                @click:date="menu.date_from = false"
                                >
                                </v-date-picker>
                            </v-menu> -->
                        </v-col>
                        <v-col sm="12" md="2" class="d-flex flex-row">
                            <!-- <v-menu
                                v-model="menu.date_to"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="filters.date_to"
                                        prepend-inner-icon="mdi-calendar"
                                        outlined
                                        dense
                                        readonly
                                        v-on="on"
                                        style="display: block"
                                        background-color="white"
                                        label="Date To"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="filters.date_to"
                                @click:date="menu.date_to = false"
                                >
                                </v-date-picker>
                            </v-menu> -->
                        </v-col>
                        <v-col sm="12" md="2" class="d-flex flex-row">
                            <!-- <v-autocomplete
                                :items="collection_types.selection"
                                v-model="collection_types.selected"
                                dense
                                outlined
                                label="Collection Type"
                                background-color="white"
                                hide-details
                                clearable
                            /> -->
                        </v-col>
                        <v-spacer />
                        <v-col cols="2" class="text-right">
                            <v-text-field
                                label="Search PYT# "
                                v-model="filters.search"
                                append-icon="mdi-magnify"
                                clearable
                                style="width: 300px"
                                @click:append="getPaymentTransmittal()"
                                @keypress.enter="getPaymentTransmittal()"
                                >
                            </v-text-field>
                        </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="1">
                        <!-- <v-btn :disabled="disableButton" @click="updatePaymentTransmittal()">
                            Confirm
                        </v-btn> -->
                    </v-col>
                </v-row>
            </v-app>
            <v-card-text>
                <v-data-table
                    :items="dataTable.items"
                    :headers="dataTable.headers"
                    :options.sync="dataTable.options"
                    :loading="dataTable.loading"
                    :search="filters.search"
                    :server-items-length="dataTable.totalItems"
                    :footer-props="{
                        itemsPerPageOptions: itemsPerPageOptions,
                    }"
                    disable-sort
                    dense
                >
                    <template v-slot:[`item.date_transmitted`]="{ item }">
                        <span>{{ formatDateandTime(item.date_transmitted) }}</span>
                    </template>

                    <template v-slot:[`item.status`]="{ item }">
                        <span class="badge badge-saved" v-if="item.status=='saved'">{{ item.status }}</span>
                        <span class="badge badge-transmitted" v-if="item.status=='transmitted'">{{ item.status }}</span>
                    </template>

                    <template v-slot:[`item.date_from`]="{ item }">
                        <span>{{ item.date_from | formatDate }}</span>
                    </template>

                    <template v-slot:[`item.date_to`]="{ item }">
                        <span>{{ item.date_to | formatDate }}</span>
                    </template>

                    <template v-slot:[`item.action`]="{ item }">
                        <v-btn text icon color="orange" small @click="viewPaymentTransmittal('View', item)">
                            <v-icon small> mdi-eye </v-icon>
                        </v-btn>
                        <v-btn text icon color="primary" small @click="editPaymentTransmittal('Edit', item)" v-if="userAccess.edit && item.status=='saved'">
                            <v-icon small> mdi-pencil </v-icon>
                        </v-btn>
                        <v-btn text icon color="error" small @click="deletePaymentTransmittal(item)" v-if="userAccess.delete && item.status=='saved'">
                            <v-icon small> mdi-delete </v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue';

export default {
    mixins: [SharedFunctionsComponent],
    data() {
        return {
            filters: {
                search: '',
            },
            dataTable: {
                items: [],
                loading: false,
                options: {},
                headers: [
                    { text: 'PYT#', align: 'center', value:'payment_transmittal_num' },
                    { text: 'Date Transmitted', align: 'center', value:'date_transmitted' },
                    { text: 'Date From', align: 'center', value:'date_from' },
                    { text: 'Date To', align: 'center', value:'date_to' },
                    { text: 'Status', align: 'center', value:'status' },
                    { text: 'Actions', align: 'center', value:'action' },
                ],
                totalItems: 0,
            },
            itemsPerPageOptions: [5, 10, 25, 50, 100, 250, 500, 1000],
            userAccess: {
                edit: false,
                delete: false,
            },
        }
    },

    computed: {
        ...mapGetters([
            'GET_PAYMENT_TRANSMITTAL_DISPATCH',
            'USER_ACCESS',
            'GET_PAYMENT_TRANSMITTAL_REFRESH',
        ]),
    },

    components: {

    },

    mounted() {
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
    },

    methods: {
        getPaymentTransmittal() {
            this.dataTable.items = []
            this.dataTable.loading = true;
            this.$store.commit('PAYMENT_TRANSMITTAL_REFRESH', true);
            let payload = {
                url: this.GET_PAYMENT_TRANSMITTAL_DISPATCH.get[1],
                page: this.dataTable.options.page,
                itemsPerPage: this.dataTable.options.itemsPerPage,
                search: this.filters.search,
            }
            this.$store.dispatch('paymentTransmittalGet',payload).then(response => {
                this.dataTable.items        = response.data.data.data
                this.dataTable.totalItems   = response.data.data.total
                this.dataTable.loading      = false;
                this.$store.commit('PAYMENT_TRANSMITTAL_REFRESH', false);
            })
        },

        viewPaymentTransmittal(action = '' , item) {
            this.$store.commit("DIALOG", true);
            this.$store.commit("PAYMENT_TRANSMITTAL_VIEW", item);
            this.$store.commit("ACTION", action);
        },

        editPaymentTransmittal(action = '', item) {
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", action);
            this.$store.commit("PAYMENT_TRANSMITTAL_VIEW", item);
        },

        deletePaymentTransmittal(item) {
            swal.fire({
                text: '- Are you sure you want to delete?',
                icon: 'question',
                reverseButtons: true,
                showCancelButton: true,
            }).then(action => {
                if(action.isConfirmed) {
                    let payload = {
                        url: this.GET_PAYMENT_TRANSMITTAL_DISPATCH.delete[0],
                        id: item.id,
                    }
                    this.$store.dispatch('paymentTransmittalPost', payload).then(response => {
                        swal.fire("Success!", "Successfully Deleted!", "success");
                        this.getPaymentTransmittal()
                        this.$store.commit('PAYMENT_TRANSMITTAL_REFRESH', true);
                    }).catch(e => {
                        swal.fire("Error!", "An Error Occured", "error");
                    })
                }
            })
        },
    },

    watch: {
        'dataTable.options': {
            handler(val) {
                this.getPaymentTransmittal()
            }
        },

        USER_ACCESS:{
            handler(val){
                if(val != "fail"){
                    this.userAccess.edit = false;
                    this.userAccess.delete = false;
                    val.forEach(e => {
                    if (e.actions_code == 'edit') {
                        this.userAccess.edit = true;
                    } else if (e.actions_code == 'delete') {
                        this.userAccess.delete = true;
                    }
                    });
                }
            }
        },

        GET_PAYMENT_TRANSMITTAL_REFRESH: {
            handler(val) {
                if(val) {
                    this.getPaymentTransmittal()
                }
            }
        }

    },
}
</script>

<style>

</style>