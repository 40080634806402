<template>
    <v-container fluid class="pa-3">
            <h3 class="page-title">Financials</h3>
            <FinancialsTableComponent :userAccess="userAccess"/>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import FinancialsTableComponent from '@/views/main/modules/Accounting/Financials/FinancialsTableComponent'
export default {
    data(){
        return{
            userAccess: []
        }
    },
    components:{
        FinancialsTableComponent,
    },
    mounted(){
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        this.$store.dispatch('getPvCompanies');
        this.$store.dispatch('getPaymentVoucherBranchesSelection')
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS'
        ])
    },
    methods: {},
    watch: {
        USER_ACCESS:{
            handler(val){
                if (val != 'fail') {
                    this.userAccess = val.map(e=>e.actions_code)
                }
            }
        },
    }
}
</script>