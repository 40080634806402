import repository from '@/api/modules/Admin/adminItem'
import store from '@/store';
export default {
	state: {
        edit_admin_items:{},
        new_admin_items:false,
        admin_item_dispatch:{},
        admin_item_status:null,
        admin_item_service:null,
        asset_num: "",
        export_items: false,
        export_items_with_price: false,
        edit_admin_items_uom:{},
	},
	getters: {
        GET_EDIT_ADMIN_ITEMS:(state)=>state.edit_admin_items,
        GET_EDIT_ADMIN_ITEM_UOM:(state)=>state.edit_admin_items_uom,
        GET_NEW_ADMIN_ITEM:(state)=>state.new_admin_items,
        GET_ADMIN_ITEM_DISPATCH:(state)=>state.admin_item_dispatch,
        GET_ADMIN_ITEM_STATUS:(state)=>state.admin_item_status,
        GET_ADMIN_ITEM_SERVICE:(state)=>state.admin_item_service,
        GET_ASSET_NUM: (state) => state.asset_num,
        GET_EXPORT_ITEMS: (state) => state.export_items,
        GET_EXPORT_ITEMS_WITH_PRICE: (state) => state.export_items_with_price,
	},
	actions:{
        adminItemGet({}, payload){
            return new Promise((resolve, reject) => {
                repository.adminItemGet(payload).then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                })
            })
        },
        adminItemPost({}, payload){
            return new Promise((resolve, reject) => {
                repository.adminItemPost(payload).then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                })
            })
        },
        adminItemPut({}, payload){
            return new Promise((resolve, reject) => {
                repository.adminItemPut(payload).then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                })
            })
        },
        adminItemDelete({}, payload){
            return new Promise((resolve, reject) => {
                repository.adminItemDelete(payload).then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                })
            })
        },
        getAllAdminItem({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.getAllAdminItem(payload).then((response)=>{
                    resolve(response);
                }).catch((err)=>{
                    reject(err);
                })
            })
        },
        AdminCreateAdminItem({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.AdminCreateAdminItem(payload).then((response)=>{
                    commit('NEW_ADMIN_ITEM',true);
                    resolve(response);
                }).catch((err)=>{
                    reject(err);
                })
            })
        },
        AdminUpdateAdminItem({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.AdminUpdateAdminItem(payload).then((response)=>{
                    commit('NEW_ADMIN_ITEM',true);
                    resolve(response);
                }).catch((err)=>{
                    reject(err);
                })
            })
        },
        AdminDeleteAdminItem({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.AdminDeleteAdminItem(payload).then((response)=>{
                    commit('NEW_ADMIN_ITEM',true);
                    resolve(response);
                }).catch((err)=>{
                    reject(err);
                })
            })
        },
        exportBatch({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.exportBatch(payload).then((response)=>{
                    resolve(response);
                })
            }) 
        },
        getLastAssetNum({ commit }) {
            return new Promise((resolve, reject) => {
                    repository.getLastAssetNum()
                    .then((response) => {
                            commit("ASSET_NUM", response.data);
                            resolve(response.data);
                    })
                    .catch((e) => {
                            reject();
                    });
            });
        },
        adminItemDelete({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.adminItemDelete(payload).then((response) => {
                    resolve(response.data);
                }).catch((error) => {
                    reject(error);
                });
            });
        },
	},
	mutations:{
        ADMIN_ITEM_STATUS:(state,data)=>{
            state.admin_item_status = data
        },
        ADMIN_ITEM_SERVICE:(state,data)=>{
            state.admin_item_service = data
        },
        ADMIN_ITEM_DISPATCH:(state,data)=>{
            state.admin_item_dispatch = data
        },
        EDIT_ADMIN_ITEMS:(state,data)=>{
            state.edit_admin_items = data;
        },
        NEW_ADMIN_ITEM:(state,data)=>{
            (state.new_admin_items = data)
        },
        ASSET_NUM: (state, data) => {
            state.asset_num = data
        },
        EXPORT_ITEMS: (state, data) => {
            state.export_items = data
        },
        EXPORT_ITEMS_WITH_PRICE: (state, data) => {
            state.export_items_with_price = data
        },
        EDIT_ADMIN_ITEM_UOM: (state, data) => {
            state.edit_admin_items_uom = data
        },
	},
}
