<template>
    <div>
        <div class="page-wrapper ma-1">
          <v-row class="p-2">
              <v-col cols="8" md="8" class="d-flex no-block align-items-center">
                  <h4 class="page-title">Inquiry</h4>
              </v-col>
            </v-row>
          <v-container>
              <v-card class="px-4">
                    <div class="row">
                        <div class="col-sm-4 d-flex">
                            <v-autocomplete
                                :items="customerList"
                                v-model="selectedCustomers"
                                outlined
                                dense
                                multiple
                                small-chips
                                deletable-chips
                                label="Select Customers"
                                class="d-inline-block"
                                auto-select-first
                            >
                            </v-autocomplete>
                        </div>

                        <div class="col-sm-8 text-right">
                            <v-btn @click="resetFilter()" class="ma-1">Clear</v-btn>
                            <v-btn @click="getAllRepairForms()" class="ml-1">Filter</v-btn>
                            <span class="ml-1">
                                <BaseQrReaderVue @scanResult="scanResult" @closeQrScannerDialog="closeQrScannerDialog" />
                            </span>
                            <v-btn @click="exportDialog.state = true" class="ml-1">Export...</v-btn>
                        </div>
                    </div>
                    <v-card-title class="px-0">
                        <v-row class="w-100 mr-3">
                            <div class="col-sm-12 col-md-2">
                                <v-dialog
                                    ref="dateStartDialog"
                                    v-model="startDateModal"
                                    :return-value.sync="startDate"
                                    persistent
                                    width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="startDate"
                                        label="Date Start"
                                        readonly
                                        dense
                                        append-icon="mdi-calendar-clock"
                                        class="hide-text-field-details"
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                        clearable
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="startDate"
                                        scrollable
                                        :max="minDate"
                                    >
                                    <v-spacer />
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="startDateModal = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.dateStartDialog.save(startDate)"
                                    >
                                        OK
                                    </v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-sm-12 col-md-1" id="endDate">
                                <v-dialog
                                    ref="endDateDialog"
                                    v-model="endDateModal"
                                    :return-value.sync="endDate"
                                    persistent
                                    width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="endDate"
                                        label="Date Start"
                                        readonly
                                        dense
                                        hidden
                                        append-icon="mdi-calendar-clock"
                                        class="hide-text-field-details"
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                        :disabled="!startDate"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="endDate"
                                        scrollable
                                        :min="startDate"
                                        :max="minDate"
                                    >
                                    <v-spacer />
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="endDateModal = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.endDateDialog.save(endDate)"
                                    >
                                        OK
                                    </v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <v-spacer v-if="viewportName != 'xs'" />
                            <v-flex :lg2="viewportName != 'xs'" :lg12="viewportName == 'xs'" :order-2="viewportName == 'xs'" class="pr-2" >
                                <v-text-field
                                    v-model="searchSerial"
                                    append-icon="mdi-magnify"
                                    label="Search Exact Serial"
                                    single-line
                                    hide-details
                                    clearable
                                    @keydown.enter="findBySerial()"
                                    @click:append="findBySerial()"
                                    @click:clear="clearSearchSerial()"
                                />
                            </v-flex>
                            <v-flex :lg2="viewportName != 'xs'" :lg12="viewportName == 'xs'" :order-2="viewportName == 'xs'" >
                                <v-text-field
                                    v-model="searchRf"
                                    append-outer-icon="mdi-magnify"
                                    label="RF#, Model, Serial, CM#"
                                    single-line
                                    hide-details
                                    clearable
                                    @keydown.enter="findRepairForm()"
                                    @click:append-outer="findRepairForm()"
                                    @click:clear="clearSearch()"
                                    />
                            </v-flex>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                            :headers="rfHeaders"
                            :items="repairForms"
                            :search="searchRf"
                            :options.sync="options"
                            :server-items-length="totalItems"
                            :loading="loading"
                        >
                            <template v-slot:item.action="{ item }">
                                <v-btn text icon color="orange">
                                    <v-icon class="btn-action" small @click="showRfInquiryDialog(item)">mdi-eye</v-icon>
                                </v-btn>
                                <v-btn text icon color="blue">
                                    <v-icon class="btn-action" small @click="showRfEditDialog(item)">mdi-pencil</v-icon>
                                </v-btn>
                            </template>

                            <template v-slot:item.ready_for_qa="{ item }">
                                <v-chip :color="item.ready_for_qa == 1 ? 'success' : 'error'" small>
                                    {{ item.ready_for_qa == 1 ? 'Yes' : 'No' }}
                                </v-chip>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
                <v-dialog v-model="exportDialog.state" max-width="400" persistent>
                    <v-card>
                        <v-card-title class="pr-1 pb-2">
                            <v-col cols="8">
                                <label class="text-lg font-semibold">Export Details</label>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-btn text icon color="gray" class="float-right" @click="exportDialog.state = false">
                                    <v-icon>mdi-close-circle</v-icon>
                                </v-btn>
                            </v-col>
                        </v-card-title>
                        <v-divider class="ma-0 mb-2" />
                        <v-card-text>
                            <v-row class="d-flex flex-column align-center w-100 mx-auto px-4">
                                <v-col cols="12" class="mt-2" >
                                    <v-btn @click="exportToExcel()" class="w-100" :loading="loading">Repair Forms</v-btn>
                                </v-col>
                                <v-col cols="12" class="mt-2">
                                    <v-btn @click="exportMovementToExcel()" class="w-100" :loading="loading">Repair Form Movement</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <RfDetailsDialog :repairFormId="rfId" @closeInquiryDetailsDialog="closeInquiryDetailsDialog" />
                <RfEditDetailsDialog :repairFormId="rfId" @closeInquiryEditDetailsDialog="closeInquiryEditDetailsDialog" />
            </v-container>
        </div>
    </div>
</template>

<script>
import RfDetailsDialog from '../Views/InquiryComponent/rfInquiryDetailsDialogComponent.vue';
import RfEditDetailsDialog from '../Views/InquiryComponent/rfInquiryEditDetailsDialogComponent.vue';
import BaseQrReaderVue from '../Base/BaseQrReader.vue';
import ViewportCheckerComponentVue from '@/views/main/Shared/ViewportCheckerComponent.vue';
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue';
export default {
    mixins: [ViewportCheckerComponentVue, SharedFunctionsComponent],
    data() {
        return {
            rfHeaders: [
                { text: 'RF#', value: 'rf_number' },
                { text: 'RD#', value: 'rr_number' },
                { text: 'Model', value: 'model' },
                { text: 'Company Name', value: 'company_name' },
                { text: 'Customer Name', value: 'customer_name' },
                { text: 'Serial', value: 'serial' },
                { text: 'Status', value: 'status' },
                { text: 'Crate#', value: 'crate_number' },
                { text: 'Loc#', value: 'location_number' },
                { text: 'Warranty', value: 'warranty_type' },
                { text: 'Date', value: 'created_at' },
                { text: 'Ready For QA', value: 'ready_for_qa', align: 'center' },
                { text: 'Technician', value: 'assigned_technician' },
                { text: 'Action', value: 'action' },
            ],
            repairForms: [],
            searchRf: '',
            rfId: 0,
            viewportName: this.breakpointName(),
            page: 1,
            itemsPerPage: 10,
            options: {},
            totalItems: 0,
            loading: true,
            customerList: [],
            selectedCustomer: '',
            selectedCustomers: [],

            startDateModal: false,
            startDate: '',
            endDateModal: false,
            endDate: '',
            minDate: this.$dayjs().format('YYYY-MM-DD'),
            searchSerial: '',
            exportDialog: {
                state: false,
            }
        }
    },
    components: {
        RfDetailsDialog,
        BaseQrReaderVue,
        RfEditDetailsDialog,
    },
    async mounted(){
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
        await this.$store.dispatch('getAllCustomers')
        await this.getSearchFromUrl();
        await this.getCustomers();
    },
    computed: {
        ...mapGetters([
            'GET_CUSTOMERS_LIST_SELECTION',
            'GET_ALL_REPAIR_FORMS',
        ])
    },
    watch: {
        options(data) {
            this.loading = true;
            this.page = data.page;
            this.itemsPerPage = data.itemsPerPage
            this.getAllRepairForms();
        },
        repairForms() {
            if(this.repairForms.length > 0) {
                this.loading = false;
            } else {
                this.loading = true;
                setTimeout(()=>{
                    this.loading = false;
                    this.noDataText = "No data for Receiving Detail";
                }, 10000);
            }
        },
        selectedCustomer() {
            this.searchRf = '';
        },
        startDate() {
            if(!!this.startDate) {
                this.endDate = this.minDate;
            } else {
                this.endDate = '';
            }
        }
    },
    methods: {
        getSearchFromUrl(){

            this.searchRf = new URL(location.href).searchParams.get('rf_id') != null ? new URL(location.href).searchParams.get('rf_id') : ''
            if(this.searchRf){
                 this.showRfInquiryDialog({id:this.searchRf})
            }
            this.getAllRepairForms()
        },
        resetFilter() {
            this.selectedCustomers = [];
            this.startDate = '';
            this.endDate = '';
            this.searchSerial = '';
            this.getAllRepairForms();
        },
        getCustomers(){
                this.customerList = this.GET_CUSTOMERS_LIST_SELECTION
                this.customerList.push({
                    value: 'all',
                    text: 'Select All'
                })
        },
        getAllRepairForms() {
            this.loading = true
            this.repairForms = []
            let payload = {
                page: this.page,
                itemsPerPage: this.itemsPerPage,
                searchRf: this.searchRf,
                selectedCustomers: this.selectedCustomers,
                startDate: this.startDate,
                endDate: this.endDate,
                serial: this.searchSerial
            }

            this.$store.dispatch('getAllRepairForms',payload).then(response=>{
                this.repairForms = this.GET_ALL_REPAIR_FORMS.data;
                this.totalItems = this.GET_ALL_REPAIR_FORMS.total;
                this.loading = false;
            })
        },
        showRfInquiryDialog(item) {
            this.rfId = '';
            this.rfId = item.id;
            this.$store.commit('showRfInquiryDialog');
        },
        scanResult(result) {
            if(!!result) {
                this.searchRf = result;

                if(this.repairForms.length == 0) {
                    swal.fire('','No repair form found','warning');
                } else {
                    let scannedRf = this.repairForms.find(x=>x.rf_number == result);
                    this.showRfInquiryDialog(scannedRf);
                }
            }

        },
        // clearSearch() {
        //     this.searchRf = "";
        // },
        closeInquiryDetailsDialog(confirm) {
            if(confirm) {
                this.clearSearch();
            }
        },
        closeQrScannerDialog(confirm) {
            if(confirm) {
                this.clearSearch();
            }
        },
        findRepairForm() {
            this.loading = true;
            this.options.page = 1;
            this.getAllRepairForms();
        },
        clearSearch() {
            this.rfId = '';
            this.loading = true;
            this.searchRf = '';
            this.getAllRepairForms();
        },
        filterList() {
            this.loading = true;
            this.getAllRepairForms();
        },
        clearFilter() {
            this.selectedCustomer = '';
            this.clearSearch();
        },
        checkSearch() {
            if(!this.searchRf) {
                this.getAllRepairForms();
            }
        },
        onSelectCustomers(items) {
            this.selectedCustomers = items
        },
        findBySerial() {
            if(!!this.searchSerial) {
                this.options.page = 1;
                this.getAllRepairForms();
            }
        },
        clearSearchSerial() {
            this.searchSerial = '';
            this.getAllRepairForms();
        },
        async exportMovementToExcel(){
            this.loading = true
            await this.$store.dispatch('serviceGet',{
                    url: 'export-movement-report'

            }).then(async response => {
                const data = response.data.data;
                data.forEach(e => {
                    e.created_at = this.formatDate(e.created_at)
                })
                const exportHeadersAndData = {
                    Movement: {
                        headers: [
                            { header: 'RF#', key: 'rf_number' },
                            { header: 'Status', key: 'status' },
                            { header: 'Date', key: 'created_at', width:14 },
                            { header: 'user', key: 'name', width:24},
                        ],
                        data: data
                    }
                }

                await this.exportExcel(exportHeadersAndData, 'Inquiry', 'F69F1A');
                this.loading = false
            })
        },
        async exportToExcel(){
            this.loading = true
            await this.$store.dispatch('getAllRepairForms',{
                    page: this.page,
                    itemsPerPage: this.itemsPerPage,
                    searchRf: this.searchRf,
                    selectedCustomers: this.selectedCustomers,
                    startDate: this.startDate,
                    endDate: this.endDate,
                    serial: this.searchSerial,
                    exportToExcel: true

            }).then(async response => {
                let data = response.data;
                data.forEach( e => {
                    e.po_total_amount = e.po_total_amount ? parseFloat(e.po_total_amount) : ''
                })
                const exportHeadersAndData = {
                    Inquiry: {
                        headers: [
                                { header: 'RF#', key: 'rf_number', style: { alignment: { vertical: 'middle', horizontal: 'left' }} },
                                { header: 'DR#', key: 'dr_number', style: { alignment: { vertical: 'middle', horizontal: 'left' }} },
                                { header: 'RD#', key: 'rr_number', style: { alignment: { vertical: 'middle', horizontal: 'left' }} },
                                { header: 'CM#', key: 'cm_number', style: { alignment: { vertical: 'middle', horizontal: 'left' }} },
                                { header: 'Model', key: 'model', style: { alignment: { vertical: 'middle', horizontal: 'left' }} },
                                { header: 'Category', key: 'category', style: { alignment: { vertical: 'middle', horizontal: 'left' }} },
                                { header: 'Company Name', key: 'company_name', style: { alignment: { vertical: 'middle', horizontal: 'left' }} },
                                { header: 'Customer Name', key: 'customer_name', style: { alignment: { vertical: 'middle', horizontal: 'left' }} },
                                { header: 'Customer Contact No.', key: 'contact_number', style: { alignment: { vertical: 'middle', horizontal: 'left' }}},
                                { header: 'Total Amount', key: 'po_total_amount', width:12, style: { alignment: { vertical: 'middle', horizontal: 'right' }, numFmt: "#,##0.00" }},
                                { header: 'Serial', key: 'serial', style: { alignment: { vertical: 'middle', horizontal: 'left' }} },
                                { header: 'Status', key: 'status', style: { alignment: { vertical: 'middle', horizontal: 'left' }} },
                                { header: 'Crate#', key: 'crate_number', style: { alignment: { vertical: 'middle', horizontal: 'left' }} },
                                { header: 'Loc#', key: 'location_number', style: { alignment: { vertical: 'middle', horizontal: 'left' }} },
                                { header: 'Warranty', key: 'warranty_type', style: { alignment: { vertical: 'middle', horizontal: 'left' }} },
                                { header: 'Created At', key: 'created_at', width:12, style: { alignment: { vertical: 'middle', horizontal: 'left' }} },
                                { header: 'Status Date', key: 'latest_date', width:12, style: { alignment: { vertical: 'middle', horizontal: 'left' }} },
                                { header: 'Technician', key: 'assigned_technician', style: { alignment: { vertical: 'middle', horizontal: 'left' }} },
                                { header: 'Repair Status', key: 'repair_status', style: { alignment: { vertical: 'middle', horizontal: 'left' }} },
                                { header: 'Repair Date', key: 'repair_completed', width:12, style: { alignment: { vertical: 'middle', horizontal: 'left' }} },
                                { header: 'Cancelled Date', key: 'repair_cancelled_at', width:12,  style: { alignment: { vertical: 'middle', horizontal: 'left' }} },
                                { header: 'QUO Date', key: 'quo_date', width:12, style: { alignment: { vertical: 'middle', horizontal: 'left' }} },
                                { header: 'PIN Date', key: 'pin_date', width:12, style: { alignment: { vertical: 'middle', horizontal: 'left' }} },
                            ],
                        data: data
                    }
                }
                await this.exportExcel(exportHeadersAndData, 'Inquiry', 'F69F1A');
                this.loading = false;
            })
        },
        showRfEditDialog(item)
        {
            this.rfId = '';
            this.rfId = item.id;
            this.$store.commit('showRfEditDialog');
        },
        closeInquiryEditDetailsDialog(confirm) {
            if(confirm) {
                this.clearSearch();
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    th, td {
        padding-left: 8px;
        padding-top: 2px;
    }
    #endDate{
        display:none;
    }
</style>
