import TransactionJournalGroupComponent from "@/views/main/modules/Accounting/TransactionJournal/TransactionJournalGroupComponent.vue"
import repository from "@/api/credentials"

const routes = [{
    path: "/accounting/transaction-journal/:id",
    name: "accttransactionjournal",
    component: TransactionJournalGroupComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
                }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Transaction Journal',
    }
}]

export default routes;
