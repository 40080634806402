<template>
  <div class="w-100">
    <v-app id="my_vue_app">
      <v-card>
        <v-card-subtitle>
          <!-- Filter -->
          <v-row class="ma-1">
            <v-col cols="2">
                <v-autocomplete
                    v-model="bank_id"
                    :items="GET_BANK_SELECTION"
                    item-value="id"
                    item-text="name"
                    dense
                    outlined
                    label="Bank"
                    auto-select-first
                    hide-details
                    clearable
                >
                </v-autocomplete>
            </v-col>
            <v-col cols="2">
                <v-autocomplete
                    v-model="vendor_id"
                    :items="GET_ADMIN_SUPPLIER_SELECTION"
                    item-value="value"
                    item-text="text"
                    dense
                    outlined
                    auto-select-first
                    label="Vendor"
                    hide-details
                    clearable
                >
                </v-autocomplete>
            </v-col>
            <v-col cols="1" class="my-auto pl-2">
                <v-btn
                @click="getChequeVouchers()"
                >
                FILTER
                </v-btn>
            </v-col>
          </v-row>
          <!-- Search Bar -->
          <v-row>
              <v-spacer />
              <v-col cols="3" class="px-3 mr-3">
                  <v-text-field
                    v-model="search"
                    label="Search by CV#/ Bank"
                    append-icon="mdi-magnify"
                    @keydown.enter="searchInput()"
                    @click:append="searchInput()"
                  ></v-text-field>
              </v-col>
          </v-row>
        </v-card-subtitle>
        <v-card-text style="overflow:auto;">

          <!-- filters and search components here... -->

          <v-container fluid>
            <v-row>
              <v-col>
                <v-data-table
                  :headers="dataTable.headers"
                  :items="dataTable.items"
                  :server-items-length="dataTable.totalItems"
                  :options.sync="dataTable.options"
                  :loading="!loadedChequeVouchers"
                  no-data-text="No entries found."
                >
                <template v-slot:[`item.settings.color_code`]="{ item }">
                    <v-badge :color="item.settings.color_code" offset-x="-13"/>
                  </template>
                  <template v-slot:[`item.amount`]="{ item }">
                    <span class="currency">{{ toCurrency('PHP', item.amount) }}</span>
                  </template>
                  <template v-slot:[`item.printed`]="{ item }">
                    <span :class="`badge badge-${statuses.printed[item.printed].badge}`">{{ statuses.printed[item.printed].text }}</span>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn text icon color="orange" @click="getChequeVoucher(item.id, 'update',item)">
                      <v-icon class="btn-action" small>mdi-eye</v-icon>
                    </v-btn>
                    <!-- <v-btn text icon color="blue" @click="getChequeVoucher(item.id, 'update')">
                      <v-icon class="btn-action" small>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn text icon color="red" @click="removeChequeVoucher(item.id)">
                      <v-icon class="btn-action" small>mdi-delete</v-icon>
                    </v-btn> -->
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-app>
  </div>
</template>

<script>
import swal from 'sweetalert2';
import { mapGetters } from 'vuex';
export default {
  props:['pbStatus'],
  created() {
    this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
  },

  data() {
    return {
      // isFormValid: false,
      loadedChequeVoucher: true,
      loadedChequeVouchers: false,
      // paymentDateModal: false,
      // readOnlyBooklet: true,
      // readOnlyPayee: true,

      dataTable: {
        totalItems: 0,
        headers: [],
        items: [],
        itemsPerPageOptions: [5, 10, 15, 50, 100],
        options: {
          page: 1,
          itemsPerPage: 10,
        },
      },
      // form: {
      //   bank_id: null,
      //   vendor_id: null,
      //   vendor_payment_item_id: null,
      //   booklet_id: null,
      //   cheque_date: '',
      //   amount: 0,
      // },
      options:{
        prefix: "₱",
        suffix: "",
        length: 16,
        precision: 2,
      },
      statuses: {
        printed: [
          { badge: 'gray', text: 'No' },
          { badge: 'success', text: 'Yes' },
        ],
      },
      search: '',
      bank_id: null,
      vendor_id: null,
    }
  },

  computed: {
    ...mapGetters([
        'GET_CV_TABLE',
        'GET_BANK_SELECTION',
        'GET_ADMIN_SUPPLIER_SELECTION',
        'GET_SELECTED_TAB',
        'SELECTED',
        'GET_RELOAD_CHEQUE_VOUCHER',
        'GET_BANK_SELECTION'
    ]),
    toCurrency() {
      return (currency, value) => {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: currency,
        }).format(parseFloat(value));
      };
    },
  },

  watch: {
    SELECTED:{
      handler(val){
        if(val){
          this.getChequeVouchers();
        }
      },
      deep: true
    },
    'GET_SELECTED_TAB': {
      handler(val){
          this.getChequeVouchers();
      },
      deep: true,
    },
    GET_CV_TABLE: {
      handler(val) {
        if (val == true) {
          this.dataTable.options.page = 1;
          this.getChequeVouchers();
          this.$store.commit('CV_TABLE', false)
        } 
      },
      deep: true,
    },
    'dataTable.options': {
      handler(val) {
        if (val) {
          this.getChequeVouchers();
        } 
      },
      deep: true,
    },
    pbStatus:{
      handler(val){
        this.getChequeVouchers();
      }
    }
  },

  methods: {
    getChequeVoucher(id, action,item) {
      this.$store.commit("DIALOG", true);
      this.$store.commit("CV_LOAD", false)
      this.$store.commit("CV_ACTION", action)
      this.$store.commit("SELECTED",item);
      this.$store.dispatch('getChequeVoucher', id).then(response => {
        this.$store.state.chequevoucher.form = response.data;
        this.$store.commit("CV_LOAD", true)
      });
    },
    getChequeVouchers(search = null) {
      this.loadedChequeVouchers = false;
      this.dataTable.items = [];
      let payload = {
        search: this.search,
        page: this.dataTable.options.page,
        items_per_page: this.dataTable.options.itemsPerPage,
        status: this.pbStatus ? this.pbStatus : this.GET_SELECTED_TAB == 0 ? '' : this.GET_SELECTED_TAB,
        bank_id: this.bank_id,
        vendor_id: this.vendor_id
      };
      if(payload.status == 2){
          this.dataTable.headers = [{ text:'CV #', value:'cheque_voucher_number', sortable: false},
          { text:'PB #', value:'pv_num', sortable: false},
          { text:'Bank', value:'bank', sortable: false},
          { text:'Booklet #', value:'booklet_number', sortable: false},
          { text:'Cheque #', value:'cheque_number', sortable: false},
          { text:'Vendor', value:'vendor', sortable: false},
          { text:'Payee', value:'payee', sortable: false},
          { text:'Amount', value:'amount', sortable: false, align: 'right'},
          { text:'Printed', value:'printed', sortable: false, align: 'center'},
          { text:'Cheque Date', value:'cheque_date', sortable: false},
          { text:'Cleared Date', value:'cleared_date', sortable: false},
          { text:'Age', value:'settings.age', sortable: false},
          { text:'Color Code', value:'settings.color_code', sortable: false},
          { text:'Actions', value:'actions', sortable: false, align: 'center'}]
      }else{
        this.dataTable.headers = [{ text:'CV #', value:'cheque_voucher_number', sortable: false},
          { text:'PB #', value:'pv_num', sortable: false},
          { text:'Bank', value:'bank', sortable: false},
          { text:'Booklet #', value:'booklet_number', sortable: false},
          { text:'Cheque #', value:'cheque_number', sortable: false},
          { text:'Vendor', value:'vendor', sortable: false},
          { text:'Payee', value:'payee', sortable: false},
          { text:'Amount', value:'amount', sortable: false, align: 'right'},
          { text:'Printed', value:'printed', sortable: false, align: 'center'},
          { text:'Cheque Date', value:'cheque_date', sortable: false},
          { text:'Cleared Date', value:'cleared_date', sortable: false},
          { text:'Actions', value:'actions', sortable: false, align: 'center'}]
      }
      this.$store.dispatch('getChequeVouchers', payload).then(response => {
        this.loadedChequeVouchers = true,
        this.dataTable.items = response.data.data.data;
        this.dataTable.totalItems = response.data.data.total;
      });
    },
    removeChequeVoucher(id) {
      swal.fire({
        title: 'Are you sure?',
        text: 'You are about to remove this cheque voucher. This action cannot be undone.',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, remove it!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch('removeChequeVoucher', id)
          .then(response => {
            swal.fire({
              icon: "success",
              title: "Success!",
              text: "Cheque voucher removed successfully.",
            });
          });
          this.$store.commit("CV_TABLE", true)
        }
        // If the user clicks cancel, nothing happens.
      });
    },
    searchInput() {
      this.getChequeVouchers({ query: this.search });
    }
  },
};
</script>

<style scoped>
.currency {
  font-family: 'Consolas';
}
</style>