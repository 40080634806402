<template>
    <span>
        <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }" >
                    <v-btn
                        style="opacity:1;"
                        v-bind="attrs"
                        v-on="on"
                        text
                        @click="showFilesViewerDialog()"
                    ><v-icon>mdi-file-check-outline</v-icon>
                    </v-btn>
            </template>
            <span>View Requirements</span>
        </v-tooltip>
        <v-dialog v-model="filesViewerDialog" persistent :width="height('50%')">               <!-- Requirements Table If Approved -->
            <v-card>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">Requirements File Viewer</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeFilesViewerDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider class="my-0" />
                <v-row  class="d-flex justify-end align-center ma-5" v-if="this.GET_FILE_VIEWER_SETTINGS.module == 'applicant_data'">
                    <v-col sm="8" class="text-right">
                    <a :class="`ml-1 badge badge-all ${filter.all === -1 ? 'selected' : 'non-selected'}`" @click="filter.all = -1; filter.status_id = null; filter.required = null; getFiles();">all</a>
                    <a :class="`ml-1 badge badge-yes ${filter.status_id === 1 ? 'selected' : 'non-selected'}`" @click="toggleFilter('status_id', 1);">approved</a>
                    <a :class="`ml-1 badge badge-no ${filter.status_id === 0 ? 'selected' : 'non-selected'}`" @click="toggleFilter('status_id', 0);">not approved</a>
                    </v-col>
                    <v-col sm="8" class="text-right">
                    <a :class="`ml-1 badge badge-completed ${filter.required === 1 ? 'selected' : 'non-selected'}`" @click="toggleFilter('required', 1);">required</a>
                    <a :class="`ml-1 badge badge-for-accounting ${filter.required === 0 ? 'selected' : 'non-selected'}`" @click="toggleFilter('required', 0);">not required</a>
                    </v-col>
                </v-row>
                <v-container>
                    <v-data-table
                        @toggle-select-all="selectAllRequirements($event)"
                        :headers="tableHeader"
                        :items="files"
                        :loading="loading"
                        :no-data-text="noDataText"
                        :items-per-page="15"
                        loading-text="Loading... Please wait"
                        item-key="id"
                        class="pa-4"
                        height="500"
                        :show-select="GET_FILE_VIEWER_SETTINGS.module == 'applicant_data' ? true : false"
                        hide-default-footer
                        fixed-header
                    >
                        <!-- <template v-slot:top>
                            <v-row>
                                <v-spacer></v-spacer>
                                <v-col class="d-flex justify-content-end mr-3">
                                    <v-tooltip bottom color="#f69f1a">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                @click="downloadFile(requirement_selection,1)"
                                                v-bind="attrs"
                                                v-on="on"
                                                color="#f69f1a"
                                                fab
                                                small
                                                icon
                                                text
                                            >
                                                <v-icon>mdi-cloud-download</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Download Selected Files</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </template> -->

                    <template v-slot:item.data-table-select="{ item }">
                            <td>
                                <v-simple-checkbox
                                v-model="item.selected"
                                :disabled="item.approved == 1"
                                ></v-simple-checkbox>
                            </td>
                        </template>


                        <template v-slot:item.action="{ item }">
                            <td class="px-0">
                                <span class="text-nonwrap">
                                    <v-btn text icon color="orange">
                                        <v-icon class="btn-action" small @click="newImagePath(item)">mdi-eye</v-icon>
                                    </v-btn>
                                    <v-btn text icon color="blue">
                                        <v-icon class="btn-action" small @click="downloadFile(item,-1)">mdi-download</v-icon>
                                    </v-btn>
                                </span>
                            </td>
                        </template>

                        <template v-slot:item.created_at="{ item }">
                            <td class="px-0">
                                {{ date(item) }}
                            </td>
                        </template>

                        <template v-slot:[`item.req_for_employment`]="{ item }">
                            <span :class="`badge badge-${item.req_for_employment.toLowerCase()}`">{{item.req_for_employment}}</span>
                        </template>

                        <template v-slot:item.uploaded_count="{ item }">
                            <span class="pl-5">{{item.requirements.length}}</span>
                        </template>

                        <template v-slot:item.approved="{ item }">
                            <v-icon :color="`${item.approved == 1 ? 'green':'red'}`">{{ item.approved == 1 ? 'mdi-check-bold': 'mdi-minus-circle-outline' }}</v-icon>
                        </template>

                    </v-data-table>
                </v-container>
                <v-card-actions class="text-center px-5" style="border-top:1px solid #dee2e6;">
                    <v-container>
                        <v-row>
                            <v-col class="d-flex justify-content-center justify-space-between" v-if="GET_EMPLOYEE_DATA_VIEW.status_id == 6 && GET_FILE_VIEWER_SETTINGS.module =='applicant_data'">
                                <v-btn
                                    @click="approveMultipleRequirements()"
                                    class="ma-2"
                                    color="blue"
                                >Approve
                                </v-btn>
                                <v-btn
                                    @click="openRejectDialog()"
                                    class="ma-2"
                                    color="red"
                                >Reject
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="viewRequirements" persistent max-width="50%">                <!-- Requirements Table -->
             <v-progress-linear
                :active="loading"
                :indeterminate="loading"
                absolute
                bottom
                color="#f69f1a"
            ></v-progress-linear>
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                        <span class="headline">{{ itemName }}</span>
                        </v-card-title>
                    </v-col>

                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="requirementsCloseDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider class="my-0" />
                <v-col>
                    <v-data-table
                        :headers="requirements_header"
                        :items="requirement_items"
                        :loading="loading"
                        no-data-text="No Data Found"
                        loading-text="Loading... Please wait"
                        class="pa-4"
                    >
                        <template v-slot:top>
                            <v-row>
                                <v-col class="d-flex justify-content-end mr-3">
                                    <v-tooltip bottom color="green">
                                        <template v-slot:activator="{ on, attrs }">
                                            <input class="d-none" id="fileUpload" type="file" @change="uploadFile($event)" accept=".jpg,.jpeg,.png,.gif,.pdf" multiple ></input>
                                            <v-btn
                                                @click="upload"
                                                v-bind="attrs"
                                                v-on="on"
                                                color="green"
                                                fab
                                                small
                                                icon
                                                text
                                            >
                                                <v-icon>mdi-cloud-upload</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Upload Requirements</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </template>

                        <!-- <template v-slot:item.data-table-select="{ item }">
                            <td>
                            <v-checkbox
                                :disabled="item.approved === 1"
                                v-model="item.selected"
                            ></v-checkbox>
                            </td>
                        </template> -->

                        <template v-slot:item.action="{ item }">
                            <span class="text-nonwrap mr-2">
                                <v-btn text icon color="orange">
                                    <v-icon class="btn-action" small @click="viewRequirement(item)">mdi-eye</v-icon>
                                </v-btn>
                                <v-btn text icon color="red">
                                    <v-icon class="btn-action" small @click="deleteRequirement(item)">mdi-delete</v-icon>
                                </v-btn>
                            </span>
                        </template>

                        <template v-slot:item.created_at="{ item }">
                            <td class="px-0">
                                {{ date(item) }}
                            </td>
                        </template>

                        <template v-slot:item.image_path="{ item }">
                            <!-- <v-img class="img-thumbnail" width="50" height="50" :src="checkImagePath(item.file_name)" eager contain></v-img> -->
                            <!-- Show PDF preview -->
                            <embed v-if="isPDF(item.file_name)" :src="checkImagePath(item.file_name)" type="application/pdf" width="50" height="50" />

                            <!-- Show image preview -->
                            <v-img v-else class="img-thumbnail" width="50" height="50" :src="checkImagePath(item.file_name)" eager contain></v-img>
                        </template>

                    </v-data-table>
                </v-col>
            </v-card>
        </v-dialog>

        <v-dialog v-model="viewFile" persistent max-width="80%">                        <!-- Requirements View -->
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                        <span class="headline">{{ itemName }}</span>
                        </v-card-title>
                    </v-col>

                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeViewFile()">
                        <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider class="my-0" />

                <div class="iframe-container" v-if="type == 'pdf'">
                    <iframe :src="sourceFrame" frameborder="0"  class="pdf-iframe"></iframe>
                </div>

                <div class="d-flex justify-content-center">
                    <v-img v-if="type == 'image' && !loading" :src="sourceFrame" max-width="400px" style="" contain />
                </div>

                <v-progress-linear
                    :active="loading"
                    :indeterminate="loading"
                    absolute
                    bottom
                    color="#f69f1a"
                ></v-progress-linear>
            </v-card>
        </v-dialog>
        <v-dialog v-model="rejectDialog" persistent max-width="40%">
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                        <span class="headline">Requirements Rejection</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeRejectDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider class="my-0" />
                <v-card-text>
                    <v-row class="mt-4">
                        <v-col>
                            <v-textarea
                                v-model="rejectionReason"
                                label="Reason"
                                height="85"
                                outlined
                                required
                                dense
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="text-center">
                    <v-row class="ma-1">
                        <v-col cols="12">
                            <v-btn
                            class="float-center"
                            @click="rejectRequirements()"
                            >
                            <span>Submit</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
         <v-dialog v-model="override.override_dialog" persistent max-width="30%" scrollable>
                <v-card>
                    <v-row class="m-0">
                        <v-card-title>
                            <span class="headline">Confirm Override Print</span>
                        </v-card-title>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right" @click="closeOverrideDialog(); override_username = ''; override_user_password = '';">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card-text>
                        <div class="row">
                            <!-- <div class="col-sm-12 col-md-6">
                                <label for="override_username" class="float-left control-label col-form-label">Username</label>
                                <input type="text" class="form-control" id="override_username" placeholder="Username" v-model="override_username" autocomplete="new-password">
                                <span v-for="errors in errors.override_username" class="text-warning" v-bind:key="errors">{{errors}}</span>
                            </div> -->
                            <div class="col-sm-12 col-md-12">
                                <label for="override_user_password" class="float-left control-label col-form-label">Password</label>
                                <input type="password" class="form-control" id="override_user_password" placeholder="Password" v-model="override.override_user_password" autocomplete="new-password">
                                <!-- <span v-for="errors in errors.override_user_password" class="text-warning" v-bind:key="errors">{{errors}}</span> -->
                            </div>
                        </div>
                        <div class="border-top">
                            <div class="card-body py-4 d-flex justify-content-center">
                                <v-btn  v-on:click="confirmedOverridePrint()">Confirm</v-btn>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>
    </span>
</template>

<script>
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
export default {
    data() {
        return {
            filesHeader: [
                { text: 'Name', value: 'name' },
                { text: 'No. Of Files', value: 'uploaded_count',align: 'left'  },
                { text: 'Required for Employment', value: 'req_for_employment', align: 'center' },
                { text: 'Approved', value: 'approved', align: 'center' },
                { text: 'Action', value: 'action'  },
            ],
            requirements_header:[
                { text: 'File Name', value: 'file_name' },
                { text: 'Upload Date', value: 'created_at' },
                { text: 'Preview', value: 'image_path' },
                { text: 'Action', value: 'action',align: 'center', width:'20%' },
            ],
            employee_requirements_header:[
                { text: 'Name', value: 'name' },
                { text: 'Required for Employment', value: 'req_for_employment', align: 'center' },
                { text: 'Action', value: 'action'  },
            ],
            files: [],
            applicant_id:'',
            noDataText: '',
            viewRequirements: false,
            itemPath: '',
            itemName: '',
            withDelete: false,
            folder_name:'',
            sourceFrame:'',
            type:'',
            loading: false,
            filesViewerDialog: false,
            requirement_items:[],
            requirement_selection:[],
            requirement_type:'',
            viewFile: false,
            rejectDialog: false,
            rejectionReason: '',
            filter:{
                all: -1,
                status_id:-1,
                required_id:''
            },
            override: {
                override_dialog: false,
                authenticated:false,
                override_user_password:"",
                errors: [],
                override_departments_allowed: ['HR','SM', 'EX', 'IT'],
                override_authenticated: false,
                type:"",
                isPrint:false
            },
        }
    },
    props: ['module','dispatch','delete_url'],
    watch: {
        files :{
            handler(val){
                if(val.length > 0) {
                    this.loading = false;
                } else {
                    this.loading = false,
                    this.noDataText = 'No Uploaded Files';
                }
            }
        },
        GET_EMPLOYEE_DATA_VIEW:{
            handler(val){
                this.applicant_id = val.id
            }
        },
        GET_HR_FILE_VIEWER_NEW_DATA:{
            handler(val){
                if(val){
                    this.getFiles()
                    this.clearFields()
                    this.$store.commit('HR_FILE_VIEWER_NEW_DATA',false)
                }
            }
        }
    },
    computed: {
        ...mapGetters([
            'GET_CUSTOMER_PAYMENT_DETAIL_FILE',
            'GET_FILES',
            'GET_EMPLOYEE_DATA_VIEW',
            'GET_HR_FILE_VIEWER_NEW_DATA',
            'GET_FILE_VIEWER_SETTINGS',
            'GET_CONFIRM_OVERRIDE'
        ]),
        tableHeader(){
            if(this.GET_FILE_VIEWER_SETTINGS.module == 'applicant_data'){
                return this.filesHeader;
            }
            else{
                return this.employee_requirements_header;
            }
        }
        // filesViewerDialog() {
        //     return this.$store.state.accounting.files.filesViewerDialog;
        // },
    },
    mounted(){
        this.applicant_id = this.GET_EMPLOYEE_DATA_VIEW.id
    },
    methods: {
        upload(){
            if(this.GET_EMPLOYEE_DATA_VIEW.contract_type_id == 3 ){
                this.override.override_dialog = true;
            }
            else{
                document.getElementById("fileUpload").click()
            }
        },
        closeViewFile() {
            this.itemPath = "";
            this.viewFile = false;
        },
        approveMultipleRequirements(){
            let has_null = [];

            this.files.forEach(e=>{
                if(e.selected == true && e.requirements.length == 0){
                    has_null.push(e.name)
                }
            })

            // this.requirement_selection.forEach(e=>{
            //     if(e.requirements.length == 0){
            //         has_null.push(e.name)
            //     }
            // })

            let requirement_ids = this.files.filter(e=>{
                if(e.selected == true){
                    return e
                }
            })

            let payload = {
                applicant_id : this.GET_EMPLOYEE_DATA_VIEW.id,
                requirement_ids: requirement_ids
            }
            if(this.files.filter(e=>e.selected).length == 0){
                swal.fire({
                    title: 'Warning',
                    text: 'Select a requirement to approve',
                    icon: 'warning',
                    confirmButtonText: 'Okay'
                })
                return false;
            }
            if(has_null.length != 0){
                let filtered = has_null.filter(function (el){
                    return el.replace(',', ' ');
                })
                swal.fire({
                    title: 'Warning',
                    text: `No requirements uploaded for ${filtered}`,
                    icon: 'warning',
                    confirmButtonText: 'Okay'
                })
                return false;
            }
            else{
                this.$store.dispatch('ApproveApplicationRequirements',payload).then(response=>{
                    swal.fire(response.icon.toUpperCase(),response.message,response.icon);
                }).catch((err)=>{
                    swal.fire({
                        title: 'Error',
                        text: 'Network Error',
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                    console.log(err)
                })
            }
        },
        checkImagePath(item) {
            let folder = this.GET_FILE_VIEWER_SETTINGS.module == 'applicant_data' ? '/uploads/hr/applicants_requirements/' : '/uploads/hr/employee_requirements/';
            return item == null
                ? process.env.VUE_APP_API_HOST + "/images/items/no-image.png"
                : process.env.VUE_APP_API_HOST + folder + this.requirement_type.toLocaleLowerCase().replace(' ','_') + '/' + item;
        },
        isPDF(fileName) {
        // Check if the file extension is ".pdf"
            return fileName && fileName.toLowerCase().endsWith(".pdf");
        },
        clearFields() {
            this.loading = true;
            this.noDataText = '';
            this.requirement_selection = [];
            this.requirement_items = [];
            this.files = [];
        },
        getFiles(){
            this.loading = true;
            let payload = {
                id : this.GET_FILE_VIEWER_SETTINGS.module == 'applicant_data' ? this.GET_EMPLOYEE_DATA_VIEW.id : this.GET_EMPLOYEE_DATA_VIEW.id ,
                filter: this.filter
            }
            this.$store.dispatch(this.GET_FILE_VIEWER_SETTINGS.dispatch,payload).then(response=>{
                this.files = response;
                this.loading = false;
            }).catch((err)=>{
                swal.fire({
                    title: 'Error',
                    text: 'Network Error',
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
                this.loading = false;
                console.log(err)
            })
        },
        showFilesViewerDialog() {
            this.getFiles()
            this.filesViewerDialog = true
            this.folder_name = 'applicants_upload'
        },
        closeFilesViewerDialog() {
            this.filesViewerDialog = false
            this.filter.all = -1;
            this.clearFields();
        },
        date(item) {
            if(!!item) {
                return this.$dayjs(item.created_at).format('YYYY-MM-DD, hh:mm:ss a');
            }
            return '';
        },
        showFile(file) {
            this.viewRequirements = true;
            this.itemPath = file.path;
            this.itemName = file.name
            this.$store.commit('filesClose');
        },
        downloadFile(file, multiple = -1) {
            if(multiple == 1 && this.requirement_selection.length == 0){
                swal.fire({
                    title: 'Warning',
                    text: 'No Requirements Selected',
                    icon: 'warning',
                    confirmButtonText: 'Okay'
                })
                this.loading = false
                return false;
            }
            swal.fire({
                text: 'Are you sure you want to download?',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(async(result) => {
                if (result.isConfirmed) {
                    swal.fire('Downloading File Please wait.');
                    swal.showLoading();

                    const zip = new this.$jszip();

                    if (multiple == -1) {
                        this.requirement_type = file.name;

                        // Get the Files From Domain
                        let applicant_requirements = file.requirements;
                        if (applicant_requirements.length != 0) {
                            await Promise.all(applicant_requirements.map(async e => {
                                let payload = {
                                    file_name: e.file_name,
                                    requirement_type: file.name
                                };
                                await fetchRequirements(payload, zip);
                            })).then(() => {
                                // Generate the zip file after the loop ends
                                generateZipFile(zip, `${file.full_name}-${file.name}.zip`);
                            });
                        }
                    } else {
                        this.requirement_selection.forEach(async el => {
                            this.requirement_type = el.name;

                            // Create a new zip instance for each loop iteration
                            const zip = new this.$jszip();

                            // Get the Files From Domain
                            let applicant_requirements = el.requirements;
                            if (applicant_requirements.length != 0) {
                                await Promise.all(applicant_requirements.map(async e => {
                                    let payload = {
                                        file_name: e.file_name,
                                        requirement_type: el.name
                                    };
                                    await fetchRequirements(payload, zip,this.GET_FILE_VIEWER_SETTINGS.module);
                                })).then(() => {
                                    // Generate the zip file after the loop ends
                                    generateZipFile(zip, `${el.full_name}-${el.name}.zip`);
                                }).catch(err => {
                                    swal.fire({
                                        title: 'Error',
                                        text: 'Network Error',
                                        icon: 'error',
                                        confirmButtonText: 'Okay'
                                    });
                                    console.log(err);
                                });
                            } else {
                                swal.fire({
                                    title: 'Warning',
                                    text: 'No Requirements Uploaded',
                                    icon: 'warning',
                                    confirmButtonText: 'Okay'
                                });
                                this.loading = false;
                                return false;
                            }
                        });
                    }
                }

                async function fetchRequirements(payload, zip,active_module) {
                    let uri = `${process.env.VUE_APP_API_HOST}/img/hr/requirements?file_name=${payload.file_name}&requirement_type=${payload.requirement_type}&module=${active_module}`;
                    const response = await fetch(uri);
                    if (!response.ok) {
                        throw new Error('An error occurred while downloading the file');
                    }
                    const blobobject = await response.blob();
                    zip.file(payload.file_name, blobobject);
                    swal.close();
                }

                function generateZipFile(zip, fileName) {
                    zip.generateAsync({ type: 'blob' }).then(content => {
                        let anchor = document.createElement('a');
                        anchor.style.display = 'none';
                        anchor.download = fileName; // Choose the desired name for the zip file
                        anchor.href = window.URL.createObjectURL(content);
                        document.body.appendChild(anchor);
                        anchor.click();
                        window.URL.revokeObjectURL(anchor.href);
                    });
                }
            });
        },
        closeviewRequirements() {
            this.itemPath = "";
            this.viewRequirements = false;
            this.applicant_id = "";
            this.$store.commit('filesShow');
        },
        async newImagePath(file){
            this.loading = true
            this.requirement_type = file.name

            // Get the Files From Domain
            let applicant_requirements = file.requirements;
            // if(applicant_requirements.length != 0){
            //    await applicant_requirements.forEach(async e=>{
            //         let payload = {
            //             file_name : e.file_name,
            //             requirement_type:file.name
            //         }
            //          this.$store.dispatch('getRequirementsFromDomain',payload).then(async response=>{
            //             this.loading = false
            //             this.showFile(file)
            //             this.requirement_items = file.requirements
            //         }).catch((err)=>{

            //             this.showFile(file)
            //             this.requirement_items = file.requirements
            //             this.loading = false
            //         })
            //     })
            // }
            if (applicant_requirements.length !== 0 && this.GET_FILE_VIEWER_SETTINGS.module == 'applicant_data') {
                await Promise.all(applicant_requirements.map(async (e) => {
                    let payload = {
                        file_name: e.file_name,
                        requirement_type: file.name
                    };

                    try {
                    let response = await this.$store.dispatch('getRequirementsFromDomain', payload);
                        this.loading = false;
                        this.showFile(file);
                        this.requirement_items = file.requirements;
                    } catch (err) {
                    if (err.response && err.response.status === 500) {
                        this.showFile(file)
                        this.requirement_items = file.requirements
                        this.loading = false
                    } else {
                        swal.fire({
                            title: 'Error',
                            text: 'Network Error',
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                        console.log(err)
                    }
                    this.showFile(file);
                    this.requirement_items = file.requirements;
                    this.loading = false;
                    }
                }));
            }
            else{
                // swal.fire({
                //     title: 'Warning',
                //     text: 'No Requirements Uploaded',
                //     icon: 'warning',
                //     confirmButtonText: 'Okay'
                // })
                this.showFile(file)
                this.requirement_items = file.requirements;
                this.loading = false
            }
        },
        viewRequirement(file){
            this.loading = true

            let uri = ''
            uri = `${process.env.VUE_APP_API_HOST}/img/hr/requirements?file_name=${file.file_name}&requirement_type=${this.requirement_type}&module=${this.GET_FILE_VIEWER_SETTINGS.module}`
            fetch(uri)
            .then(resp => resp.blob())
            .then(blobobject => {
                this.viewFile = true
                const blob = window.URL.createObjectURL(blobobject);
                if(blobobject.type == "image/png" || blobobject.type == 'image/webp' || blobobject.type == 'image/jpeg'){
                    this.type = 'image'
                }
                else{
                    this.type = 'pdf'
                }
                this.sourceFrame = blob;
                this.loading = false
            })

        },
        deleteRequirement(item){
            swal.fire({
                text: 'Are you sure you want to delete?',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(async(result) => {
                if (result.isConfirmed) {
                    let payload = {
                        id: item.requirement_id,
                        requirement_type: this.itemName.toLowerCase().replace(' ','_')
                    }
                    this.$store.dispatch(this.delete_url,payload).then(res=>{
                        swal.fire({
                            title: res.message,
                            icon: res.icon,
                        })
                        this.requirement_items = res.data ?? []
                    }).catch((err)=>{
                        swal.fire({
                            title: 'Error',
                            text: 'Network Error',
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                        console.log(err)
                    })
                }
            })

        },
        uploadFile(file){
            swal.fire({
                text: 'Are you sure you want to upload?',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(async(result) => {
                if (result.isConfirmed) {
                    swal.fire('Uploading Files Please wait.');
                    swal.showLoading();
                    let files = file.target.files
                    let data = new FormData();
                    let config = {
                        headers: {
                            "content-type": "multipart/form-data",
                        },
                    };
                    let payload = {
                        params: data,
                        config: config,
                    };
                    data.append("id", this.GET_FILE_VIEWER_SETTINGS.module == "applicant_data" ? this.GET_EMPLOYEE_DATA_VIEW.id : this.GET_EMPLOYEE_DATA_VIEW.employee_id);
                    data.append("requirement_type",this.requirement_type);

                    for (let i = 0; i < _.compact(files).length; i++) {
                        data.append("files[]", files[i]);
                    }
                    this.$store.dispatch(this.GET_FILE_VIEWER_SETTINGS.upload,payload).then(response=>{
                        swal.close()
                        swal.fire({
                            title: response.msg,
                            text: response.msg,
                            icon: response.icon,
                            allowOutsideClick: false,
                        })
                        this.viewRequirements = false
                    }).catch((err)=>{
                        swal.fire({
                            title: 'Error',
                            text: 'Network Error',
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                        console.log(err)
                    })
                }
            })

        },
        requirementsCloseDialog(){
            this.viewRequirements = false
            this.requirement_items = []
        },
        selectAllRequirements(item){

            item.items.forEach(e=>{
                if(e.approved == 0){
                    e.selected = item.value
                }

            })
        },
        openRejectDialog(){
            let has_null = [];

            this.files.forEach(e=>{
                if(e.selected == true && e.requirements.length == 0){
                    has_null.push(e.name)
                }
            })

            if(this.files.filter(e=>e.selected).length == 0){
                swal.fire({
                    title: 'Warning',
                    text: 'Select a requirement to reject',
                    icon: 'warning',
                    confirmButtonText: 'Okay'
                })
                return false;
            }
            if(has_null.length != 0){
                let filtered = has_null.filter(function (el){
                    return el.replace(',', ' ');
                })
                swal.fire({
                    title: 'Warning',
                    text: `No requirements uploaded for ${filtered}`,
                    icon: 'warning',
                    confirmButtonText: 'Okay'
                })
                return false;
            }
            else {
                this.rejectDialog = true;
            }
        },
        closeRejectDialog(){
            this.rejectDialog = false;
            this.rejectionReason = '';
        },
        rejectRequirements(){
            let requirement_ids = this.files.filter(e=>{
                if(e.selected == true){
                    return e
                }
            })

            if(this.rejectionReason){
                let payload = {
                    applicant_id : this.GET_EMPLOYEE_DATA_VIEW.id,
                    requirement_ids: requirement_ids,
                    reason: this.rejectionReason
                }
                this.$store.dispatch('RejectApplicantRequirements',payload).then(response=>{
                    swal.fire(response.icon.toUpperCase(),response.message,response.icon);
                    this.closeRejectDialog();
                }).catch((err)=>{
                    swal.fire({
                        title: 'Error',
                        text: 'Network Error',
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                    console.log(err)
                    this.rejectDialog = false;
                })
            }
            else{
                swal.fire({
                    title: 'Warning',
                    text: `Please input your reason.`,
                    icon: 'warning',
                    confirmButtonText: 'Okay'
                })
            }
        },
        toggleFilter(key, value) {
            if (this.filter[key] === value) {
                this.filter[key] = null;
            } else {
                this.filter[key] = value;
            }
            if (this.filter.status_id === null && this.filter.required === null) {
                this.filter.all = -1;
            } else {
                this.filter.all = 0;
            }
            this.getFiles();
        },
        confirmedOverridePrint() {
             if (!this.override.override_user_password) {
                this.override_user_id = -1
                this.override.override_dialog = false
            } else {
                let payload = {
                    departments_allowed:        this.override.override_departments_allowed,
                    override_user_password:     this.override.override_user_password,
                }
                this.$store.dispatch('confirmOverride',payload).then(response => {
                    let data = this.GET_CONFIRM_OVERRIDE

                    this.override.override_user_password = ''

                    if (data.error) {
                        this.override_user_id = -1
                        swal.fire("", data.error, "warning")
                        return false
                    }

                    this.override_user_id = data.user_id
                    this.override.override_authenticated = true
                    if (this.override.override_authenticated) {
                        this.override.override_dialog = this.override.override_authenticated;
                        document.getElementById("fileUpload").click()
                        this.override.override_dialog = false;
                    }
                    else {
                        this.override.override_dialog = this.override.override_authenticated;
                    }
                }).catch(error => {
                    console.log(error)
                })
            }
        },
        closeOverrideDialog(){
            this.override.override_dialog = false
        },
        height(width) {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return "100%"
                case 'sm': return "100%"
                case 'md': return width
                case 'lg': return width
                case 'xl': return width
            }
        },
    },
}
</script>

<style scoped>
    .iframe-container {
        position: relative;
        width: 100%;
        padding-bottom: 100%; /* Change the aspect ratio as needed */
    }

    .pdf-iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .iframe img{
        margin: 0 auto !important;
    }
</style>
