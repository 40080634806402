<template>
    <v-container fluid >
        <h3 class="page-title">REQUISITION</h3>
        <v-container class="ma-0">
            <v-app id="item-tabs">
                <v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" background-color="#424242" dark show-arrows>
                    <v-tabs-slider color="cyan"></v-tabs-slider>
                    <v-tab ripple v-for="(item, i) in items" :key="i" @click="tabs(i)" v-if="item.view">
                          {{ item.tab_name }}
                   </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item  v-for="(item, i) in items" :key="i">
          <keep-alive>
            <component  :is="purchase_request_tabs_items.components" :purchase_request_tabs_items="purchase_request_tabs_items" :isActive_tab="item.tab_name">
            </component>
          </keep-alive>
        </v-tab-item>
      </v-tabs-items>
            </v-app>
        </v-container>
        <DialogComponent :cp="components" :scrollable="dialog_scrollable" :width="width(dialog_width)" :retainfocus="false"></DialogComponent>
    </v-container>
  </template>
  
  <script>
  import DialogComponent from '@/views/main/layouts/dialogs/Dialog.vue';
  import PurchaseRequisitionComponent from '@/views/main/modules/Admin/FAS/FasRequisitionComponent.vue';
  import FasRequisitionViewComponent from '@/views/main/layouts/dialogs/Admin/FasRequisitionViewComponent.vue';
  import { mapGetters } from 'vuex';
  export default {
    components:{
        DialogComponent,
        FasRequisitionViewComponent,
        PurchaseRequisitionComponent,
    },
    data() {
        return {
          form: {
              code: '',
              name: '',
              sub_class:[],
              sub_class_trash:[],
            },
            components:'',
            items: [
                    {tab_name:"ALL",view:true},
                    {tab_name:"PENDING",view:true},
                    {tab_name:"FOR APPROVAL",view:true},
                    {tab_name:"APPROVED",view:true},
                    // {tab_name:"OVERDUE",view:true},
                    // {tab_name:"REJECTED",view:true}
                  ],
            purchase_request_tabs_items: 
                    {
                    components: PurchaseRequisitionComponent,
                    dialog: FasRequisitionViewComponent,
                    dialog_title:'Purchase Requisition',
                    dialog_width: "50%",
                    scrollable:true,
                    dispatch:{
                              get:'getAllFasRequisition',
                              add:'createFasRequisition',
                              update:'updateFasRequisition',
                              delete:'deleteFasRequisition',
                              approve_all:'approveAllFasRequisition'
                        },
                    },
            dialog_width: "",
            loaded: false,
            options: {},
            itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
            itemsPerPage: 10,
            tab: null,
            dialog_scrollable:true,
        };
    },
    computed:{
        ...mapGetters(['DIALOGS'])
    },
    mounted(){
          this.dropDowns()
          this.tabs(0);
        
    },
    methods:{
    dropDowns(){
          this.$store.dispatch('getDepartment')
          this.$store.dispatch('getitemUomSelection');
          this.$store.dispatch("getAdminLocationSelection");
          this.$store.dispatch("getAdminClassificationSelection");
          this.$store.dispatch('getPvCompanies',{});
          this.$store.dispatch('getPaymentVoucherBranchesSelection',{company_id:0})
    },
    tabs(key) {
       this.components = this.purchase_request_tabs_items.dialog;
       this.dialog_width = this.purchase_request_tabs_items.dialog_width;
       this.dialog_scrollable = this.purchase_request_tabs_items.scrollable;
       this.$store.commit('ADMIN_FASREQUISITION_STATUS',this.items[key])
       this.$store.commit('ADMIN_FASREQUISITION_DISPATCH',this.purchase_request_tabs_items.dispatch)
    },
    width(width) {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return "100%"
          case 'sm': return "100%"
          case 'md': return "100%"
          case 'lg': return "100%"
          case 'xl': return "100%"
        }
      },
    },
    watch:{
      DIALOGS:{
        handler(val){
          if(!val){
            this.components  = this.purchase_request_tabs_items.dialog
          }
        },
        deep:true
      }
    }
  };
  </script>
  <style>
  #group-tab {
    padding: 0 !important;
  }
  </style>
  