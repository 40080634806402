<template>
    <div class="tabs-body-color">
        <v-container class="pa-0" fluid>
            <v-row
                class="pt-4 pl-2"
                v-if="accessCodes.includes('asset_turnover_create')"
            >
                <v-col cols="12" class="d-flex no-block align-items-center">
                    <div class="w-100 d-flex pl-0 mr-4">
                        <v-btn class="ml-1 mb-2" @click="openDialog()">
                            <v-icon>mdi-plus</v-icon>Add
                        </v-btn>
                        <v-btn class="ml-1 mb-2 ml-auto" @click="exportTurnover()">
                            <v-icon>mdi-file-export</v-icon>Export
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <AdminAssetTurnoverTableComponent v-if="GET_ADMIN_TAB_IS_ACTIVE == isActive_tab"></AdminAssetTurnoverTableComponent>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import AdminAssetTurnoverTableComponent from '@/views/main/modules/Admin/AssetTurnover/AdminAssetTurnoverTableComponent.vue';

export default {
    props: ["isActive_tab"],

    components: {
        AdminAssetTurnoverTableComponent,
    },

    data() {
        return {
            accessCodes: [],
        };
    },

    computed: {
        ...mapGetters([
            "USER_ACCESS",
            "GET_ADMIN_TAB_IS_ACTIVE",
            "GET_ADMIN_ITEMS_COLLECTIONS",
        ]),
    },

    methods: {
        exportTurnover() {
            console.log();
            this.$store.commit('ADMIN_ASSET_TURNOVER_EXPORT', true);
        },
        openDialog() {
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", "Submit");
        },
    },

    watch: {
        USER_ACCESS: {
            handler(val) {
                this.accessCodes = val.map(access => access.actions_code);
            },
            immediate: true,
            deep: true,
        }
    }
};
</script>
