<template>
    <div class="tabs-body-color">
        <v-container fluid>
            <v-row class="pt-2">
                <v-col cols="6" class="d-flex no-block align-items-center">
                    <div class="btn-toolbar" v-if="is_inactive_items == false" >
                        <!-- <buttons class="ml-3"
                            v-if="userAccess.create"
                            :action="buttons.actions.create"
                            :button_icon="buttons.icons.create"
                            :color="buttons.colors.create"
                            @click="showAddItem()"
                        ></buttons> -->
                        <v-btn class="ml-1 mb-2" @click="showAddItem()" v-if="userAccess.create">
                            <v-icon>mdi-plus</v-icon>Add
                        </v-btn>
                    </div>
                </v-col>
                <v-col cols="6" align="right">
                    <v-btn class="mr-1 mb-2" @click="showExportItemModal()">Export</v-btn>
                    <v-btn v-if="userAccess.create" class="mr-1 mb-2" @click="showImportItemModal()">Import</v-btn>
                </v-col>
            </v-row>
            <v-row>
                <ItemsTableComponent
                    :itemsActionResult="action"
                    :is_inactive_items="is_inactive_items"
                    :is_sp="is_sp"
                    :successUpdate="successUpdate"
                    @showEditModal="showEditModal"
                    @warehouseStockSaved="warehouseStockSaved"
                ></ItemsTableComponent>
            </v-row>
        </v-container>

        <v-app id="addEditItemModal">
            <v-dialog v-model="add_edit_item_dialog" persistent max-width="90%" scrollable>
                <v-card>
                    <v-row class="m-0">
                        <v-col>
                            <v-card-title>
                                <span class="headline">Item Information</span>
                            </v-card-title>
                        </v-col>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right" @click="closeAddEditDialog()">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-card-text v-if="loaded == true">
                        <v-row class="mt-4">
                            <v-col class="text-right" >
                                <div class="d-flex flex-row align-items-center mr-5" style="float:right;">
                                    <BaseCameraCapture class="mx-1" ref="cameraCapture" @capturedImages="getImages"></BaseCameraCapture>
                                    <FileUploadComponentVue class="mx-1 mb-2" ref="fileUpload" @uploadedData="uploadedData" @reset="clearFileUpload" />
                                </div>
                                    <BaseFilesViewerVue v-if="action != 'Add'" class="mr-3 ml-2 mt-1" :item_id="editItemId"/>
                            </v-col>
                        </v-row>
                        <v-row class="m-0 mt-2" v-if="action != 'Add'">
                            <v-container fluid>
                                <v-row>
                                    <v-col sm="12" md="4" class="text-left">
                                        <v-img fluid responsive :src="checkImagePath(image_path)"></v-img>
                                    </v-col>
                                    <v-col sm="12" md="4" class="text-left embed-responsive embed-responsive-4by3">
                                        <iframe class="embed-responsive-item" :src="video_link" frameborder="0" allowfullscreen ng-show="showvideo"></iframe>
                                    </v-col>
                                    <v-col sm="12" md="4" class="col-sm-12 col-md-4 pull-right text-right">
                                        <div class="mb-2">
                                            <v-btn class="ma-2" :disabled="action != 'Edit'" @click="editItemSubstitutions(editItemId)">New Models</v-btn>
                                            <v-btn class="ma-2" :disabled="action != 'Edit'" @click="openItemExclusiveDialog(editItemId)">Exclusive For</v-btn>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-row>
                        <v-row v-else>
                            <v-container fluid>
                                <v-row>
                                    <v-col sm="12" md="12" class="col-sm-12 col-md-12 pull-right text-right">
                                        <div class="mb-2 mt-2">
                                            <v-btn class="ma-2" @click="openItemExclusiveDialog()">Exclusive For</v-btn>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-row>
                        <form class="form-horizontal" action="/items" method="post" enctype="multipart/form-data" @submit.prevent="onSubmit">
                            <v-card-text>
                                <v-row>
                                    <v-col md="4">
                                        <label for="model" class="float-left control-label col-form-label">Model</label>
                                        <v-text-field :rules="rules" dense outlined hide-details class="w-100" id="model" placeholder="Model" v-model="model"></v-text-field>
                                        <span v-for="errors in errors.model" class="text-warning" :key="errors">{{ errors }}</span>
                                    </v-col>
                                    <v-col md="4">
                                        <label for="name" class="float-left control-label col-form-label">Item Name</label>
                                        <v-text-field dense outlined hide-details class="w-100" id="name" placeholder="Item Name" v-model="name"></v-text-field>
                                        <span v-for="errors in errors.name" class="text-warning" :key="errors">{{ errors }}</span>
                                    </v-col>
                                    <v-col md="4">
                                        <label for="barcode" class="float-left control-label col-form-label">Barcode</label>
                                        <v-text-field dense outlined hide-details class="w-100" id="barcode" placeholder="Barcode" v-model="barcode"></v-text-field>
                                        <span v-for="errors in errors.barcode" class="text-warning" :key="errors">{{ errors }}</span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col md="4">
                                        <v-textarea label="Description" auto-grow v-model="description" outlined></v-textarea>
                                        <span v-for="errors in errors.description" class="text-warning" :key="errors">{{ errors }}</span>
                                    </v-col>
                                    <v-col md="2">
                                        <label for="video_link" class="float-left control-label col-form-label">Video Link</label>
                                        <v-text-field dense outlined hide-details class="w-100" id="video_link" placeholder="Video Link" v-model="video_link"></v-text-field>
                                        <span v-for="errors in errors.video_link" class="text-warning" :key="errors">{{ errors }}</span>
                                    </v-col>
                                    <v-col md="2">
                                        <label for="image" class="float-left control-label col-form-label">Image</label>
                                        <v-file-input :rules="rules" ref="image" class="w-100" id="file" type="file" placeholder="Image" outlined dense required hide-details :prepend-icon="null" prepend-inner-icon="mdi-camera" @change="onAttach" @click:clear="clearImageInput"/>
                                    </v-col>
                                    <v-col md="4">
                                        <label for="part_num" class="float-left control-label col-form-label">Part No.</label>
                                        <v-text-field dense outlined hide-details class="w-100" id="part_num" placeholder="Part No." v-model="part_num"></v-text-field>
                                        <span v-for="errors in errors.part_num" class="text-warning" :key="errors">{{ errors }}</span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col md="3">
                                        <label for="type_id" class="float-left control-label col-form-label">Discount Type</label>
                                        <v-autocomplete :rules="rules" v-model="type_id" :items="GET_ITEM_TYPES" placeholder="Select Type" dense outlined hide-details auto-select-first color="#b3d4fc" class="w-100" :disabled="addSp"></v-autocomplete>
                                        <span v-for="errors in errors.type_id" class="text-warning" :key="errors">{{ errors }}</span>
                                    </v-col>

                                    <v-col md="3">
                                        <label for="category_id" class="float-left control-label col-form-label">Category</label>
                                        <v-autocomplete :rules="rules" v-model="category_id" :items="GET_ALL_ITEM_CATEGORIES" placeholder="Select Category" item-value="id" item-text="name" dense outlined hide-details auto-select-first color="#b3d4fc" class="w-100"></v-autocomplete>
                                        <span v-for="errors in errors.category_id" class="text-warning" :key="errors">{{ errors }}</span>
                                    </v-col>
                                    <v-col md="3">
                                        <label for="uom_id" class="float-left control-label col-form-label">UOM</label>
                                        <v-autocomplete :rules="rules" v-model="uom_id" :items="GET_ITEM_UOM.data" placeholder="Select UOM" dense outlined hide-details auto-select-first color="#b3d4fc" class="w-100"></v-autocomplete>
                                        <span v-for="errors in errors.uom_id" class="text-warning" :key="errors">{{ errors }}</span>
                                    </v-col>
                                    <v-col md="3">
                                        <label for="brand_id" class="float-left control-label col-form-label">Brand</label>
                                        <v-autocomplete :rules="rules" v-model="brand_id" :items="GET_ITEM_BRAND" placeholder="Select Brand" dense outlined hide-details auto-select-first color="#b3d4fc" class="w-100"></v-autocomplete>
                                        <span v-for="errors in errors.brand_id" class="text-warning" :key="errors">{{ errors }}</span>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col md="3">
                                        <label for="master_box_qty" class="float-left control-label col-form-label">Master Box Quantity</label>
                                        <v-text-field :rules="rules" dense outlined hide-details class="w-100" id="master_box_qty" placeholder="0" v-model="master_box_qty" @keypress="numberOnly"></v-text-field>
                                        <span v-for="errors in errors.master_box_qty" class="text-warning" :key="errors">{{ errors }}</span>
                                    </v-col>
                                    <v-col md="3">
                                        <label for="master_box_barcode" class="float-left control-label col-form-label">Master Box Barcode</label>
                                        <v-text-field dense outlined hide-details class="w-100" id="master_box_barcode" placeholder="Master Box Barcode" v-model="master_box_barcode"></v-text-field>
                                        <span v-for="errors in errors.master_box_barcode" class="text-warning" :key="errors">{{ errors }}</span>
                                    </v-col>
                                    <v-col md="3">
                                        <label for="small_box_qty" class="float-left control-label col-form-label">Small Box Quantity</label>
                                        <v-text-field :rules="rules" dense outlined hide-details class="w-100" id="small_box_qty" placeholder="0" v-model="small_box_qty" @keypress="numberOnly"></v-text-field>
                                        <span v-for="errors in errors.small_box_qty" class="text-warning" :key="errors">{{ errors }}</span>
                                    </v-col>
                                    <v-col md="3">
                                        <label for="size_id" class="float-left control-label col-form-label">Size</label>
                                        <v-autocomplete v-model="size_id" :items="GET_ITEM_SIZE.data" placeholder="Select Size" dense outlined hide-details auto-select-first color="#b3d4fc" class="w-100"></v-autocomplete>
                                        <span v-for="errors in errors.size_id" class="text-warning" :key="errors">{{ errors }}</span>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col md="3">
                                        <label for="selling_price" class="float-left control-label col-form-label">Selling Price</label>
                                        <v-text-field :disabled="!(action == 'Add' || (action == 'Edit' && userAccess.edit_srp))" :rules="rules" dense outlined hide-details class="w-100" id="selling_price" placeholder="0.00" v-model="selling_price" @keypress="decimalOnly"></v-text-field>
                                        <span v-for="errors in errors.selling_price" class="text-warning" :key="errors">{{ errors }}</span>
                                    </v-col>
                                    <v-col md="3">
                                        <label for="sp_price" class="float-left control-label col-form-label">SP Price</label>
                                        <v-text-field :disabled="!(action == 'Add' || (action == 'Edit' && userAccess.edit_srp))" dense outlined hide-details class="w-100" id="sp_price" placeholder="0.00" v-model="sp_price" @keypress="decimalOnly"></v-text-field>
                                        <span v-for="errors in errors.sp_price" class="text-warning" :key="errors">{{ errors }}</span>
                                    </v-col>
                                    <v-col md="3">
                                        <label for="cbm" class="float-left control-label col-form-label">CBM</label>
                                        <v-text-field dense outlined hide-details class="w-100" id="cbm" placeholder="0" v-model="cbm" @keypress="decimalOnly"></v-text-field>
                                        <span v-for="errors in errors.cbm" class="text-warning" :key="errors">{{ errors }}</span>
                                    </v-col>
                                    <v-col md="3">
                                        <label for="weight" class="float-left control-label col-form-label">Weight (kgs)</label>
                                        <v-text-field dense outlined hide-details class="w-100" id="weight" placeholder="0" v-model="weight" @keypress="decimalOnly"></v-text-field>
                                        <span v-for="errors in errors.weight" class="text-warning" :key="errors">{{ errors }}</span>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col md="3">
                                       <div class="d-flex flex-column">
                                            <label for="stock_warning_qty" class="float-left control-label col-form-label">Stock Alert</label>
                                            <v-text-field dense outlined hide-details class="w-100" id="stock_warning_qty" placeholder="0" v-model="stock_warning_qty"></v-text-field>
                                            <span v-for="errors in errors.stock_warning_qty" class="text-warning" :key="errors">{{ errors }}</span>
                                       </div>
                                    </v-col>
                                    <v-col md="3">
                                        <label for="moq" class="float-left control-label col-form-label">Minimum Order Quantity</label>
                                        <v-text-field dense outlined hide-details class="w-100" id="moq" placeholder="1" v-model="moq" @keypress="numberOnly"></v-text-field>
                                        <span v-for="errors in errors.moq" class="text-warning" :key="errors">{{ errors }}</span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col md="3">
                                        <label for="moq_po" class="float-left control-label col-form-label">Minimum Purchase Order Quantity</label>
                                        <v-text-field dense outlined hide-details class="w-100" id="size" placeholder="1" v-model="moq_po" @keypress="numberOnly"></v-text-field>
                                        <span v-for="errors in errors.moq_po" class="text-warning" :key="errors">{{ errors }}</span>
                                    </v-col>
                                    <v-col md="3">
                                        <label for="limit_order" class="float-left control-label col-form-label">Limit Order</label>
                                        <v-text-field dense outlined hide-details class="w-100" id="limit_order" v-model="limit_order" @keypress="numberOnly"></v-text-field>
                                        <span v-for="errors in errors.limit_order" class="text-warning" :key="errors">{{ errors }}</span>
                                    </v-col>
                                    <v-col md="3">
                                        <label for="discontinue_order_date" class="float-left control-label col-form-label">Discontinue Order Date</label>
                                            <v-menu
                                                v-model="menu"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        v-model="discontinue_order_date"
                                                        v-on="on"
                                                        class="w-100"
                                                        append-icon="mdi-calendar-month"
                                                        dense
                                                        outlined
                                                        hide-details
                                                        readonly
                                                        clearable
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker v-model="discontinue_order_date" @input="menu = false"></v-date-picker>
                                            </v-menu>
                                        <span v-for="errors in errors.discontinue_order_date" class="text-warning" :key="errors">{{ errors }}</span>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col :md="addSp && action == 'Add' ? 5 : 6">
                                        <label for="suggested_item_id" class="float-left control-label col-form-label">Alternate Models</label>
                                        <v-combobox v-model="suggestedItems" multiple :items="GET_ITEMS" dense outlined chips hide-details placeholder="Select Items" class="d-inline-block w-100"></v-combobox>
                                    </v-col>
                                    <v-col md="6">
                                        <label for="substitution_item_id" class="float-left control-label col-form-label">New Models</label>
                                        <v-combobox v-model="substitutionItems" multiple :items="GET_ITEMS" dense outlined chips hide-details placeholder="Select Items" class="d-inline-block w-100"></v-combobox>
                                    </v-col>
                                    <v-col md="6">
                                        <label for="remarks" class="float-left control-label col-form-label">Remarks</label>
                                        <v-text-field dense outlined hide-details class="w-100" id="remarks" placeholder="remarks" v-model="remarks"></v-text-field>
                                        <span v-for="errors in errors.remarks" class="text-warning" :key="errors">{{ errors }}</span>
                                    </v-col>
                                    <v-col md="6">
                                        <label for="accessory" class="float-left control-label col-form-label">Related Accessories</label>
                                        <v-combobox v-model="accessoryItem" multiple :items="GET_ITEMS" dense outlined chips hide-details placeholder="Select Items" class="d-inline-block w-100">
                                        </v-combobox>
                                    </v-col>
                                    <v-col v-for="(item, i) in systemDetailSelection" :key="i" md="6">
                                        <label for="accessory" class="float-left control-label col-form-label">Related {{ item.brand_name }}</label>
                                        <v-combobox
                                            v-model="relatedSystem[item.id]"
                                            :items="relatedItems[item.brand_name]"
                                            placeholder="Select Items"
                                            class="d-inline-block w-100"
                                            hide-details
                                            dense
                                            outlined
                                            chips
                                            multiple
                                            :loading="systemLoading"
                                        ></v-combobox>
                                    </v-col>
                                    <v-col v-if="addSp && action == 'Add'" md="3">
                                        <label for="size_id" class="float-left control-label col-form-label">SP Number</label>
                                        <v-text-field dense outlined hide-details class="w-100" id="spNumber" placeholder="SP Number" v-model="spNumber" @input="checkSpNumber"></v-text-field>
                                    </v-col>
                                    <v-col v-if="addSp && action == 'Add'" md="3">
                                        <label for="size_id" class="float-left control-label col-form-label">Parent Item</label>
                                        <v-autocomplete
                                            placeholder="Select Parent Item"
                                            :items="GET_ITEMS_EXCLUDE_SP"
                                            :disabled="spNumber == ''"
                                            v-model="parentItemId"
                                            auto-select-first
                                            item-text="model"
                                            item-value="id"
                                            color="#b3d4fc"
                                            class="w-100"
                                            hide-details
                                            outlined
                                            dense
                                            @input="checkItemSpareParts"
                                        />
                                    </v-col>
                                    <v-spacer></v-spacer>
                                    <v-col md="1" v-if="GET_USER_COSTING && this.action != 'Add'">
                                        <label for="cost_in_foreign" class="float-left control-label col-form-label">Cost (USD)</label>
                                        <v-text-field
                                            v-model="cost_in_foreign"
                                            type="number"
                                            class="d-inline-block w-100"
                                            placeholder="0"
                                            dense
                                            outlined
                                            hide-details
                                            hide-spin-buttons
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col md="2">
                                        <v-switch v-model="foc" label="foc" color="primary" input-value="1" hide-details></v-switch>
                                    </v-col>
                                    <v-col md="2">
                                        <v-switch v-model="with_expiration" label="with expiration" color="primary" :true-value="1" :false-value="0" hide-details></v-switch>
                                    </v-col>
                                    <v-col md="2">
                                        <v-switch v-model="phased_out" label="phased out" color="primary" :input-value="1" :true-value="1" :false-value="0" hide-details></v-switch>
                                    </v-col>
                                    <v-col md="2">
                                        <v-switch v-model="exclude_in_pricelist" label="exclude in pricelist" color="primary" :input-value="1" :true-value="1" :false-value="0"></v-switch>
                                    </v-col>
                                    <v-col md="2">
                                        <v-switch v-model="is_active" label="active" color="primary" :true-value="1" :false-value="0" :input-value="1" hide-details></v-switch>
                                    </v-col>
                                    <v-col md="12" v-if="!is_active">
                                        <v-textarea label="Reason for deactivation" rows="2" v-model="deactivate_reason" hide-details></v-textarea>
                                        <span v-for="errors in errors.deactivate_reason" class="text-warning" :key="errors">{{ errors }}</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>

                            <div class="border-top">
                                <div class="card-body py-4 d-flex justify-content-center">
                                    <v-btn :loading="addNewItemOnFlight" v-on:click="addNewItem()"
                                        v-if="action == 'Add'">
                                        Submit</v-btn>
                                    <v-btn :loading="editItemOnFlight" v-on:click="editItem()"
                                        v-else-if="action == 'Edit'">Update</v-btn>
                                </div>
                            </div>
                        </form>
                    </v-card-text>
                    <v-card-text v-else-if="loaded == false">
                        <div class="text-center">
                            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-dialog v-model="deactivate_reason_dialog" persistent max-width="50%" scrollable>
                <v-card>
                    <v-row class="m-0">
                        <v-col>
                            <v-card-title>
                                <span class="headline"></span>
                            </v-card-title>
                        </v-col>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right" @click="deactivate_reason_dialog = false">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card-text>
                        <div class="row">
                            <div class="col-sm-12 col-md-12" v-if="!is_active">
                                <v-textarea label="Reason for deactivation" rows="2" v-model="deactivate_reason">
                                </v-textarea>
                            </div>
                        </div>
                        <div class="border-top">
                            <div class="card-body py-4 d-flex justify-content-center">
                                <v-btn v-on:click="confirmDeactivateReason()">Confirm</v-btn>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-app>

        <!-- WAREHOUSE STOCKS MODAL-->
        <ItemWarehouseStocksComponentVue
            :warehouse_item_id="warehouse_item_id"
            @closeWarehouseStocksModal="closeWarehouseStocksModal"
            v-if="action == 'Edit'"
        ></ItemWarehouseStocksComponentVue>

        <!-- Export Item Modal -->
        <ExportItemModalComponentVue
            :is_inactive_items="is_inactive_items"
            :is_sp="is_sp"
        ></ExportItemModalComponentVue>

        <!-- Import Item Modal -->
        <ImportItemModalComponentVue
        :is_inactive_items="is_inactive_items"
        :is_sp="is_sp"
        ></ImportItemModalComponentVue>

        <!-- Exclusive For -->
        <ItemExclusiveComponent
            @closeItemExclusiveDialog="closeItemExclusiveDialog"
            @getExclusiveData="getExclusiveData"
            :action="action"
            :item_id="editItemId"
            :showItemExclusiveModal="showItemExclusiveModal">
        </ItemExclusiveComponent>
</div>
</template>

<script>
import SharedFunctionsComponent from "@/views/main/Shared/SharedFunctionsComponent.vue";
import ItemsTableComponent from "../tables/ItemsTableComponent.vue";
import ItemWarehouseStocksComponentVue from "../../../../layouts/dialogs/Sales/Items/ItemWarehouseStocksComponent.vue";
import ExportItemModalComponentVue from "../ExportItemModalComponent.vue";
import ImportItemModalComponentVue from "../ImportItemModalComponent.vue";
import { mapGetters } from "vuex";
import swal from "sweetalert2";
import ItemExclusiveComponent from "@/views/main/layouts/dialogs/Sales/Items/ItemExclusiveComponent.vue";
import FileUploadComponentVue from "@/views/main/Shared/FileUploadComponent.vue";
import BaseFilesViewerVue from "@/views/main/modules/Sales/Items/Base/BaseFilesViewer.vue"


import BaseCameraCapture from "@/views/main/modules/Services/Base/BaseCameraCapture.vue";
// import buttons from '@/views/main/layouts/Buttons/DialogButton.vue';
// import buttons_components from '@/assets/js/components-js.js';



export default {
    mixins: [
        SharedFunctionsComponent
    ],
    components: {
        ItemsTableComponent,
        // buttons,
        // buttons_components,
        ItemWarehouseStocksComponentVue,
        ExportItemModalComponentVue,
        ImportItemModalComponentVue,
        ItemExclusiveComponent,
        FileUploadComponentVue,
        BaseFilesViewerVue,
        BaseCameraCapture,
    },
    // props: [
    //     'is_inactive_items',
    //     'is_sp',
    // ],
    props: {
        is_inactive_items:{
            type:Boolean,
            default:()=>false
        },
        is_sp:{
            type:Boolean,
            default:()=>false
        },
        addSp:{
            type:Boolean,
            default:()=>false
        },
    },
    data() {
        return {
            rules: [
                (v) => !!v || "File is required"
            ],
            deactivate_reason_dialog: false,
            editItemId: '',
            add_edit_item_dialog: false,
            model: '',
            name: '',
            description: '',
            type_id: '',
            category_id: '',
            uom_id: '',
            uom_text: '',
            brand_id: '',
            size_id: '',
            barcode: '',
            alert_qty: '',
            selling_price: '',
            sp_price: '',
            is_active: 1,
            deactivate_reason: '',
            foc: 0,
            type_list: [],
            category_list: [],
            brand_list: [],
            size_list: [],
            video_link: '',
            image_url: '',
            master_box_qty: '',
            master_box_barcode: '',
            small_box_qty: '',
            small_box_barcode: '',
            moq: '',
            moq_po: '',
            remarks: '',
            cbm: '',
            weight: '',
            low_stock_qty: '',
            stock_warning_qty:'',
            uom_list: [],
            errors: [],
            suggestedItems: [],
            accessoryItem: [],
            substitutionItems: [],
            items: [],
            action: '',
            actionResult: {
                model: "Item",
                action: '',
            },
            warehouse_item_id: '',
            substitution_item_id: '',
            userAccess: {
                create: false,
                edit_srp: false,
                edit_sp: false,
            },
            loaded: false,
            part_num: null,
            phased_out: 0,
            exclude_in_pricelist: 0,
            image_path: 'images/items/no-image.png',
            image: '',
            successUpdate: false,
            addNewItemOnFlight: false,
            editItemOnFlight: false,
            exclusiveForCustomers:[],
            // buttons:buttons_components.buttons,
            showItemExclusiveModal:false,
            exclusiveData: null,
            cost_in_foreign: 0,
            spNumber: '',
            parentItemId: null,
            menu: false,
            limit_order: '',
            discontinue_order_date: '',
            with_expiration: 0,
            uploadedFiles:{
                attachments: [],
                config: ''
            },
            systemLoading: true,
            relatedSystem: {},
            systemDetailSelection: [],
            relatedItems: {}
        };
    },
    computed: {
        ...mapGetters([
            'GET_ITEMS',
            'GET_ITEM_CATEGORIES',
            'GET_ITEM_TYPES',
            'GET_ITEM_UOM',
            'GET_ITEM_BRAND',
            'GET_ITEM_SIZE',
            'GET_UPDATE_SELECTED_ITEM',
            'GET_SHOW_ITEM',
            'GET_STORE_ITEM',
            'USER_ACCESS',
            'GET_ALL_ITEM_CATEGORIES',
            'GET_CUSTOMERS_SELECTION',
            'GET_USER_COSTING',
            'GET_ITEMS_EXCLUDE_SP',
            'GET_SYSTEM_DETAIL_SELECTION',
            'GET_SYSTEM_ITEM',
        ]),
    },
    mounted: async function () {
        this.$store.dispatch(
            'checkAccessUser',
            this.$router.currentRoute.params.id
        );
        this.$store.dispatch('getAllcustomersSelection')
        this.$store.dispatch("getClassification");
        this.$store.dispatch("getallcustomerCategory");
        this.$store.dispatch("getExclusivityTypeSelection");
        this.$store.dispatch('getUserCosting')
        if (!!this.addSp) {
            this.$store.dispatch('dropdownGet', { is_active: true, url:'itemsExcludedSp'});
        }
        this.$store.dispatch('systemDetailDropdown')
    },
    methods: {
        clearImageInput() {
            this.image = '';
        },
        confirmDeactivateReason() {
            this.deactivate_reason_dialog = false;
        },
        warehouseStockSaved(event) {
            this.action = 'Edit';
        },
        closeWarehouseStocksModal(event) {
            this.warehouse_item_id = 0;
            this.action = 'updateWarehouseStocks';

            // if(event == 'success'){
            //     this.getAllItems()
            // }
        },
        editWarehouseStocks(id) {
            this.warehouse_item_id = id;
        },
        editItemSubstitutions(id) {
            this.substitution_item_id = id;
        },
        closeItemSubstitutionsModal(event) {
            this.substitution_item_id = 0;
            this.action = 'Edit';
        },
        onSelectSuggested(items) {
            this.suggestedItems = items;
        },
        onSelectSubstitution(items) {
            this.substitutionItems = items;
        },
        showAddItem() {
            this.add_edit_item_dialog = true;
            this.action = 'Add';
            this.is_active = 1;
            this.foc = 0;
        },
        addNewItem() {
            // this.addNewItemOnFlight = true;
            this.errors = [];

            const data = new FormData();
            const config = {
                headers: {
                    "content-type": "multipart/form-data",
                },
            };

            if (this.addSp) {
                if (this.spNumber && this.parentItemId == null) {
                    swal.fire("Parent Item is Required", "-Unable to process without parent item.", "warning");
                    return;
                }
            }

            if (this.image.length <= 0) {
                swal.fire("Image is Required", "Please Upload an Image", "warning");
                this.addNewItemOnFlight = false;
                return false;
            }
            else if (!this.checkRequiredFields()) return
            else if(this.GET_ALL_ITEM_CATEGORIES.find(e=>e.id == this.category_id).min_allowed_moq > this.moq){
                swal.fire(`MOQ must be greater than or equal to ${this.GET_ALL_ITEM_CATEGORIES.find(e=>e.id == this.category_id).min_allowed_moq}`, `${this.GET_ALL_ITEM_CATEGORIES.find(e=>e.id == this.category_id).name} Category`, "info");
            }
            else{
                data.append("image", this.image);
                data.append("model", this.model);
                data.append("name", this.name);
                data.append("description", this.description);
                data.append("type_id", this.type_id);
                data.append("category_id", this.category_id);
                data.append("uom_id", this.uom_id);
                data.append("brand_id", this.brand_id);
                data.append("size_id", this.size_id);
                data.append("barcode", this.barcode);
                data.append("selling_price", this.selling_price);
                data.append("sp_price", this.sp_price);
                data.append("video_link", this.video_link);
                data.append("image_url", this.image_url);
                data.append("master_box_qty", this.master_box_qty);
                data.append("master_box_barcode", this.master_box_barcode);
                data.append("small_box_qty", this.small_box_qty);
                data.append("small_box_barcode", this.small_box_barcode);
                data.append("moq", this.moq);
                data.append("moq_po", this.moq_po);
                data.append("cbm", this.cbm);
                data.append("weight", this.weight);
                data.append("stock_warning_qty", this.stock_warning_qty);
                data.append("active", this.is_active);
                data.append("foc", this.foc);
                data.append("deactivate_reason", this.deactivate_reason);
                data.append("part_num", this.part_num);
                data.append("phased_out", this.phased_out);
                data.append('exclude_in_pricelist', this.exclude_in_pricelist);
                data.append("remarks", this.remarks);
                data.append("limit_order", this.limit_order);
                data.append("discontinue_order_date", this.discontinue_order_date);
                data.append('sp_number', this.spNumber);
                data.append('parent_item_id', this.parentItemId);
                data.append('with_expiration', this.with_expiration);
                Object.keys(this.relatedSystem).forEach(key => {
                    data.append(`relatedSystem[${key}]`, JSON.stringify(this.relatedSystem[key]));
                });

                if (!!this.uploadedFiles && this.uploadedFiles.attachments.length > 0) {
                    let files = this.uploadedFiles.attachments;

                    for (let i = 0; i < _.compact(files).length; i++) {
                        data.append("files[]", files[i]);
                    }
                }

                let obj = JSON.stringify({
                    suggestedItems: this.suggestedItems,
                    substitutionItems: this.substitutionItems,
                    exclusiveData: !!this.exclusiveData ? this.exclusiveData : null,
                    accessoryItem: this.accessoryItem,
                });
                data.append("data", obj);
                let payload = data;
                this.$store.dispatch("storeItem", payload, config).then((response) => {
                    if (this.GET_STORE_ITEM.error) {
                        this.addNewItemOnFlight = false;
                        swal.fire('', "Add Item Failed", "error");
                    }
                    this.resetFields();
                    this.add_edit_item_dialog = false;

                    this.action = "success";
                    this.addNewItemOnFlight = false;
                    swal.fire('', response.data.message, response.data.icon);
                }).catch((error) => {
                    console.log(error);
                    swal.fire('', "Failed", "error");
                });
            }
        },
        async showEditModal(value) {
            this.action = 'Edit';
            this.editItemId = value;
            // this.getItems();

            if (this.editItemId != null && this.editItemId > 0) {
                await this.$store.dispatch("showItem", this.editItemId).then(async (response) => {
                    let data = this.GET_SHOW_ITEM;
                    this.model = data.model;
                    this.name = data.name;
                    this.description = data.description;
                    this.code = data.code;
                    this.type_id = data.type_id;
                    this.category_id = data.category_id;
                    this.uom_id = data.uom_id;
                    this.brand_id = data.brand_id;
                    this.size_id = data.size_id;
                    this.barcode = data.barcode;
                    this.selling_price = data.selling_price;
                    this.sp_price = data.sp_price;
                    // this.alert_qty = data.alert_qty;
                    this.video_link = data.video_link;
                    this.image_url = data.image_url;
                    this.master_box_qty = data.master_box_qty;
                    this.master_box_barcode = data.master_box_barcode;
                    this.small_box_qty = data.small_box_qty;
                    this.small_box_barcode = data.small_box_barcode;
                    this.moq = data.moq;
                    this.moq_po = data.moq_po;
                    this.cbm = data.cbm;
                    this.weight = data.weight;
                    // this.low_stock_qty = data.low_stock_qty;
                    this.stock_warning_qty = data.stock_warning_qty;
                    this.is_active = data.active;
                    this.suggestedItems = data.suggested_items;
                    this.accessoryItem = data.accessories;
                    this.substitutionItems = data.substitution_items;
                    this.foc = data.foc;
                    this.deactivate_reason = data.deactivate_reason;
                    this.part_num = data.part_num;
                    this.phased_out = data.phased_out;
                    this.exclude_in_pricelist = data.exclude_in_pricelist
                    this.image_path = data.image_path;
                    this.remarks = data.remarks;
                    this.limit_order = data.limit_order;
                    this.discontinue_order_date = data.discontinue_order_date;
                    this.with_expiration = data.with_expiration;

                    if (this.GET_USER_COSTING) {
                        this.cost_in_foreign = data.cost_in_foreign;
                    }
                    for await (let key of Object.keys(data.item_brand_suggestion)) {
                        this.relatedSystem[key] = data.item_brand_suggestion[key]
                    }

                    this.add_edit_item_dialog = true;
                }).catch((error) => {
                    swal.fire('', "Failed", "error");
                });
            }
        },
        editItem() {
            this.editItemOnFlight = true;
            this.errors = [];

            const data = new FormData();
            const config = {
                headers: {
                    "content-type": "multipart/form-data",
                },
            };

            if (this.image_path == null) {
                if (this.image.length <= 0) {
                    swal.fire("Image is Required", "Please Upload an Image", "warning");
                    this.editItemOnFlight = false;
                    return false;
                }
            }
            let obj = JSON.stringify({
                suggestedItems: this.suggestedItems,
                substitutionItems: this.substitutionItems,
                exclusiveData: !!this.exclusiveData ? this.exclusiveData : null,
                accessoryItem: this.accessoryItem,
            });

            data.append("data", obj);
            data.append("id", this.editItemId);
            data.append("image", this.image);
            data.append("model", this.model);
            data.append("name", this.name);
            data.append("description", this.description);
            data.append("type_id", this.type_id);
            data.append("category_id", this.category_id);
            data.append("uom_id", this.uom_id);
            data.append("brand_id", this.brand_id);
            data.append("size_id", this.size_id);
            data.append("barcode", this.barcode);
            data.append("selling_price", this.selling_price);
            data.append("sp_price", this.sp_price);
            data.append("video_link", this.video_link);
            data.append("image_url", this.image_url);
            data.append("master_box_qty", this.master_box_qty);
            data.append("master_box_barcode", this.master_box_barcode);
            data.append("small_box_qty", this.small_box_qty);
            data.append("small_box_barcode", this.small_box_barcode);
            data.append("moq", this.moq);
            data.append("moq_po", this.moq_po);
            data.append("cbm", this.cbm);
            data.append("weight", this.weight);
            data.append("stock_warning_qty", this.stock_warning_qty);
            data.append("active", this.is_active);
            data.append("foc", this.foc);
            data.append("deactivate_reason", this.deactivate_reason);
            data.append("part_num", this.part_num);
            data.append("phased_out", this.phased_out);
            data.append('exclude_in_pricelist', this.exclude_in_pricelist);
            data.append("remarks", this.remarks);
            data.append("limit_order", this.limit_order);
            data.append("discontinue_order_date", this.discontinue_order_date);
            data.append("with_expiration", this.with_expiration);
            Object.keys(this.relatedSystem).forEach(key => {
                data.append(`relatedSystem[${key}]`, JSON.stringify(this.relatedSystem[key]));
            });

            if (!!this.uploadedFiles && this.uploadedFiles.attachments.length > 0) {
                let files = this.uploadedFiles.attachments;

                for (let i = 0; i < _.compact(files).length; i++) {
                    data.append("files[]", files[i]);
                }
            }


            if (this.GET_USER_COSTING) {
                data.append('cost_in_foreign', this.cost_in_foreign)
            }

            let payload = data;

            this.$store.dispatch("updateSelectedItem", payload, config).then((response) => {
                if (this.GET_UPDATE_SELECTED_ITEM.error) {
                    this.editItemOnFlight = false;
                    swal.fire('', "Edit Item Failed!", "error");
                } else {
                    this.resetFields();
                    this.successUpdate = true;
                    this.add_edit_item_dialog = false;
                    this.editItemId = 0;
                    this.action = "success";
                    this.editItemOnFlight = false;
                    swal.fire('', "Edit Item Successful!", "success").then((result) => {
                        this.successUpdate = false;
                    });
                }
            }).catch((error) => {
                swal.fire('', error, "error");
                this.action = "Edit";
                this.editItemOnFlight = false;
            });
        },
        resetFields() {
            this.errors = [];
            this.model = '';
            this.name = '';
            this.description = '';
            this.type_id = '';
            this.category_id = '';
            this.uom_id = '';
            this.brand_id = '';
            this.size_id = '';
            this.barcode = '';
            this.selling_price = '';
            this.sp_price = '';
            this.alert_qty = '';
            this.video_link = '';
            this.image_url = '';
            this.master_box_qty = '';
            this.master_box_barcode = '';
            this.small_box_qty = '';
            this.small_box_barcode = '';
            this.moq = '';
            this.moq_po = '';
            this.cbm = '';
            this.weight = '';
            this.low_stock_qty = '';
            this.stock_warning_qty = ''
            this.suggestedItems = [];
            this.accessoryItem = [];
            this.substitutionItems = [];
            this.is_active = 1;
            this.foc = 0;
            this.deactivate_reason = '';
            this.part_num = null;
            this.phased_out = 0;
            this.exclude_in_pricelist = 0;
            this.image_path = "images/items/no-image.png";
            this.image = '';
            this.$refs.image.reset();
            this.spNumber = '';
            this.parentItemId = null;
            this.remarks = '';
            this.limit_order = '';
            this.discontinue_order_date = '';
            this.with_expiration = 0;
            this.relatedSystem = {},
            this.uploadedFiles = {
                attachments: [],
                config: ''
            }
        },
        showExportItemModal() {
            this.$store.commit("showExportItemModal");
        },
        showImportItemModal() {
            this.$store.commit("showImportItemModal");
        },
        onAttach(image) {
            let kb = 0; // convert the file size into byte to kb
            let mb = 0; // convert kb to mb
            let maxMb = 1;
            if (image) {
                kb = image.size / 1024; // convert the file size into byte to kb
                mb = kb / 1024; // convert kb to mb
            }

            if (!!image) {
                if (image.type != "image/jpeg") {
                    swal.fire({
                        text: "Image must be jpg, jpeg type",
                        icon: "warning",
                        dangerMode: true,
                        buttons: true,
                    });
                    this.$refs.image.reset();
                } else if (mb > maxMb) {
                    swal.fire({
                        text: "File size is too large, (required file size is atleast 1MB)",
                        icon: "warning",
                        dangerMode: true,
                        buttons: true,
                    });
                    this.$refs.image.reset();
                } else {
                    this.image = image;
                }
            }
        },
        closeAddEditDialog() {
            this.add_edit_item_dialog = false;
            this.resetFields();
            this.$emit("closeModal");
        },
        checkImagePath(image_path) {
            if (image_path == null) {
                image_path = process.env.VUE_APP_API_HOST + "/images/items/no-image.png";
                return image_path;
            }
            return process.env.VUE_APP_API_HOST + "/" + image_path;
        },
        openItemExclusiveDialog() {
            this.showItemExclusiveModal = true;
        },
        closeItemExclusiveDialog() {
            this.showItemExclusiveModal = false;
        },
        getExclusiveData(data){
            this.exclusiveData = data
        },
        checkSpNumber() {
            this.spNumber = this.spNumber.trim();
        },
        checkItemSpareParts() {
            let payload = {
                    sp_number: this.spNumber,
                    parent_item_id: this.parentItemId
            }
            this.$store.dispatch('checkItemSpareParts', payload).then( response => {
                    if (response.data.length > 0) {
                        swal.fire('Warning', '-Sp Number already exists to the parent item.', 'warning');
                        this.parentItemId = null;
                    }
            }).catch( e => {
                    console.error(e);
            })
        },
        checkRequiredFields() {
            let required = [
                { key: 'model', label: 'Model' },
                { key: 'type_id', label: 'Type' },
                { key: 'category_id', label: 'Category' },
                { key: 'uom_id', label: 'UOM' },
                { key: 'brand_id', label: 'Brand' },
                { key: 'master_box_qty', label: 'Master Box Quantity' },
                { key: 'small_box_qty', label: 'Small Box Quantity' },
                { key: 'selling_price', label: 'Selling Price' }
            ]
            let emptyFields = required.filter(e=>!this[e.key])

            if (emptyFields.length > 0) {
                let list = emptyFields.map(x=>`<li>${x.label}</li>`).join('');
                swal.fire({
                    title: 'Please fill the following fields: ',
                    html: `<ul>${list}</ul>`,
                    icon: 'error'
                })
                return false
            }
            return true
        },
        uploadedData(data) {
            if (!data) {
                this.uploadedFiles.attachments = [];
            }
            this.uploadedFiles = data;
        },
        clearFileUpload(confirm) {
            if (confirm) {
                this.uploadedFiles.attachments = [];

            }
            this.uploadedFiles.attachments = [];
        },
        getImages(images) {
            let tempFiles = [];

            images.forEach((base64Image, index) => {
                let byteString = atob(base64Image.split(',')[1]);
                let mimeString = base64Image.split(',')[0].split(':')[1].split(';')[0];

                let ab = new ArrayBuffer(byteString.length);
                let ia = new Uint8Array(ab);
                for (let i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                }

                let blob = new Blob([ab], { type: mimeString });

                let file = new File([blob], `image_${index}.png`, { type: mimeString });

                tempFiles.push(file);
            });
            if (!!this.uploadedFiles && this.uploadedFiles.attachments.length > 0) {
                this.uploadedFiles.attachments.push(...tempFiles);
            }else{
                this.uploadedFiles.attachments.push(tempFiles)
            }
        }
    },
    watch: {
        add_edit_item_dialog: function (val) {
            this.loaded = false;
            if (val == true) {
                if(this.addSp){
                    this.type_id = 3
                }
                this.loaded = true;
            }
        },

        is_active: function () {
            if (!this.is_active && !this.is_inactive_items) {
                this.deactivate_reason_dialog = true;
            }
        },
        USER_ACCESS: {
            handler(val) {
                this.userAccess.create = false;
                if (val != "fail") {
                    val.forEach((e) => {
                        if (e.actions_code == "create") {
                            this.userAccess.create = true;
                        } else if (e.actions_code == 'edit_srp') {
                            this.userAccess.edit_srp = true;
                        } else if (e.actions_code == 'edit_sp') {
                            this.userAccess.edit_sp = true;
                        }
                    });
                }
            },
        },
        GET_SYSTEM_DETAIL_SELECTION: {
            async handler(val) {
                if(val.length > 0) {
                    this.systemDetailSelection = val
                    for await (let element of this.systemDetailSelection) {
                        await this.$store.dispatch('systemItemDropdowns', {
                            url: 'system-item-dropdowns',
                            connection_name: element.connection_name,
                        }).then(response => {
                            this.relatedItems[element.brand_name] = this.GET_SYSTEM_ITEM
                            this.systemLoading = false
                        })
                    }
                }
            },
        }
    },
};
</script>
<style>
.v-text-field.v-text-field--enclosed .v-text-field__details {
    display: none;
}

.item_custom_button {
    width: 100% !important;
    height: 100% !important;
    display: inline-table;
    white-space: normal;
    padding: 1px !important;
    font-size: smaller !important;
}

.item-input-field {
    margin-top: 32px;
}
</style>
