<template>
    <div class="w-100">
      <v-app id="my_vue_app">
        <v-card>
          <v-card-title>
              <v-container fluid>
                <v-row>
                  <v-col cols="12" sm="8" class="d-flex justify-content-start">
                <v-row dense>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      v-model="filter.department"
                      :items="GET_LIST_DEPARTMENT"
                      item-text="text"
                      item-value="value"
                      outlined
                      dense
                      multiple
                      chips
                      small-chips
                      deletable-chips
                      label="Department"
                      auto-select-first
                    ></v-autocomplete>
                  </v-col>
                  <!-- <v-col cols="12" sm="4">
                    <v-autocomplete
                      v-model="filter.category"
                      :items="subClass"
                      item-text="text"
                      item-value="value"
                      outlined
                      dense
                      multiple
                      chips
                      small-chips
                      deletable-chips
                      label="Sub Category"
                    ></v-autocomplete>
                  </v-col> -->
                  <v-col cols="12" sm="4">
                    <v-btn @click="getAllPurchaseRequest()">FILTER</v-btn>
                  </v-col>
                </v-row>
              </v-col>
                <v-col cols="12" md="4">
                    <div class="row col-md-12 align-items-end">
                    <v-text-field
                        id="searchBar"
                        v-model="filter.search"
                        label="Search"
                        single-line
                        hide-details
                        @change="searchInput()">
                    </v-text-field>
                    <v-icon @click="searchInput()">mdi-magnify</v-icon>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-title>
          <v-container fluid>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :options.sync="options"
              :server-items-length="totalItems"
              :items-per-page="10"
              :loading="!loaded"
              :footer-props="{
                      itemsPerPageOptions: itemsPerPageOptions,
              }"
              loading-text="Loading... Please wait"
              fixed-header
            >
            <template v-slot:[`item.status`]="{ item }">
              <span   class="badge badge-secondary text-white" v-if="item.status == 1">for department head</span >
              <span   class="badge badge-secondary text-white" v-if="item.status == 2">pending</span >
              <span   class="badge badge-info text-white" v-if="item.status == 3">received</span >
              <span   class="badge badge-primary text-white" v-if="item.status == 4">confirmed</span >
              <span   class="badge badge-success text-white" v-if="item.status == 5">approved</span >
              <span   class="badge badge-danger text-white" v-if="item.status == 6">reject</span >
                <span   class="badge badge-warning text-white" v-if="item.status == 7">overdue</span >
            </template>
            <template v-slot:[`item.full_name`]="{ item }">
                <span v-if="item.emp_id == 0">GENERAL ACCOUNTABILITY</span>
                <span v-else>{{ item.full_name }}</span>
            </template>
            <template v-slot:[`item.remarks`]="{ item }">
                <span class="d-inline-block text-truncate" style="max-width: 300px;">{{ item.remarks }}</span>
            </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-btn text icon color="orange" v-if="userAccess.view">
                  <v-icon class="btn-action" small @click="ViewAssets(item,'View')"
                    >mdi-eye</v-icon>
                </v-btn>
                <v-btn text icon color="blue" v-if="userAccess.edit && item.status != 6 && userAccess.edit && item.status != 5">
                  <v-icon class="btn-action" small @click="ViewAssets(item,'Update')"
                    >mdi-square-edit-outline</v-icon
                  >
                </v-btn>
                <v-btn text icon color="red" v-if="userAccess.delete && item.status != 6  && userAccess.edit && item.status != 5" >
                  <v-icon class="btn-action" small @click="deleteAccountability(item.id)"
                    >mdi-delete</v-icon
                  >
                </v-btn>
              </template>
            </v-data-table>
          </v-container>
        </v-card>
      </v-app>
    </div>
  </template>
  <script>
  import swal from "sweetalert2";
  import { mapGetters } from 'vuex';
  export default {
    data() {
      return {
        loaded: false,
        totalItems: 0,
        options: {},
        items: [],
        itemsPerPageOptions: [5, 10, 15, 50, 100],
        itemsPerPage: 10,
        search: "",
        filter:{
          search:"",
          department:[],
        },
        awaitingSearch: false,
        headers: [
          // { text: 'Picture', align: 'left',value:'qr_code_base69' },
          { text: "PRF #", align: "left", value: "prf_num",sortable:false },
          { text: "Prepared By", align: "left", value: "full_name",sortable:false },
          { text: "Status", align: "left", value: "status",sortable:false },
          {text: "Date Needed",align:"left",value:"date_needed",sortable:false},
          { text: "Remarks", align: "left", value: "remarks",sortable:false },
          { text: "Action", align: "center", value: "action",sortable:false},
        ],
        count:0,
        userAccess: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
        subClass:[],
        search_classification:''
      };
    },
    computed:{
      ...mapGetters(['USER_ACCESS','GET_NEW_ADMIN_ASSETS_TABLE',
      'GET_PURCHASE_REQUEST_DISPATCH',
      'GET_ASSETS_DATA',
      'GET_NEW_PURCHASE_REQUEST_TABLE',
      'GET_PURCHASE_REQUEST_DISPATCH_STATUS',
      'GET_PURCHASE_REQUEST_DISPATCH_REFRESH',
    'GET_ADMIN_CLASSIFICATION_SELECTION',
  'GET_LIST_DEPARTMENT','GET_PURCHASE_REQUEST_NUMBER']),
   
    },
    mounted() {

    },
    methods: {
      getAllPurchaseRequest(filter = null) {
        this.items = [];
        this.loaded = false;
        let payload = {
          page: this.options.page,
          itemsPerPage: this.options.itemsPerPage,
          search: this.filter.search,
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          location:this.filter.location,
          status:this.GET_PURCHASE_REQUEST_DISPATCH_STATUS.tab_name,
          department:this.filter.department,
        };
        this.$store.dispatch(this.GET_PURCHASE_REQUEST_DISPATCH.get, payload).then((response) => {
          this.items =response.data.data;
          this.loaded = true;
          this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
          this.$store.commit("NEW_PURCHASE_REQUEST_TABLE", false);

          this.totalItems = response.data.total;
  
        }).catch((e)=>{
          this.loaded = true;
        });
      },
      searchInput(){
          this.getAllPurchaseRequest({ query: this.search });
      },  
      ViewAssets(item,action) {
        this.$store.commit("EDIT_PURCHASE_REQUEST_TABLE", item);
        this.$store.commit("DIALOG", true);
        this.$store.commit("ACTION", action);
      },
      deleteAccountability(id) {
        swal
          .fire({
            icon: 'question',
            text: "Are you sure you want to Delete?",
            cancelButtonText: "Cancel",
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonColor: "#397373",
            cancelButtonColor: "#397373",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$store
                .dispatch(this.GET_PURCHASE_REQUEST_DISPATCH.delete, id)
                .then((success) => {});
            }
          });
      },
    },
    watch: {
      search_classification:{
        handler(val) {
        this.GET_ADMIN_CLASSIFICATION_SELECTION.filter((e) => {
          if (e.text == val) {
            this.subClass = e.sub_class;
          }
        });
      },
      },
      'GET_PURCHASE_REQUEST_DISPATCH_STATUS.tab_name':{
        handler(val){
          this.getAllPurchaseRequest();
        }
      },
      GET_NEW_PURCHASE_REQUEST_TABLE:{
        handler(val){
            if(val){
              this.getAllPurchaseRequest();
            }
        }
      },
      options: {
        handler() {
          this.getAllPurchaseRequest();
        },
        deep: true,
      },  
      USER_ACCESS: {
        handler(val) {
          if (val != "fail") {
            this.userAccess.edit = false;
            this.userAccess.delete = false;
            this.userAccess.view = false;
            this.userAccess.create = false;
            val.forEach((e) => {
              if (e.actions_code == "edit") {
                this.userAccess.edit = true;
              } else if (e.actions_code == "delete") {
                this.userAccess.delete = true;
              } else if (e.actions_code == "view") {
                this.userAccess.view = true;
              } else if (e.actions_code == "create") {
                this.userAccess.create = true;
              }
            });
          }
        },
      },
    },
  };
  </script>