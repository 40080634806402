<template>
    <v-container fluid class="pa-3">
            <h3 class="page-title">Store Cash In</h3>
        <v-container class="m-0">
            <v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" dark background-color="#424242" class="col-12">
                <v-tab
                    v-for="(item, i) in tabs"
                    :key="i"
                    ripple
                    @click="commitTabData(i)"
                >
                    {{ item.tab_name }}
                </v-tab>
                <v-spacer/>
                <v-tab>Templates</v-tab>
            </v-tabs>
            <StoreCashInComponent v-if="[0,1,2].includes(this.tab)" :userAccess="userAccess" />
            <StoreCashInTemplateComponent v-if="[3].includes(this.tab)" :userAccess="userAccess" />
        </v-container>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import StoreCashInComponent from '@/views/main/modules/Accounting/StoreCashIn/StoreCashInComponent'
import StoreCashInTemplateComponent from '@/views/main/modules/Accounting/StoreCashIn/StoreCashInTemplateComponent'
export default {
    data(){
        return{
            tab:0,
            tabs: [
                { tab_name: 'ALL' },
                { tab_name: 'PENDING' },
                { tab_name: 'CONFIRMED' }
            ],
            userAccess: [],
        }
    },
    components:{
        StoreCashInComponent,
        StoreCashInTemplateComponent
    },
    mounted(){
        this.$store.dispatch('getCOASelection')
        this.$store.dispatch('getPvCompanies');
        this.$store.dispatch('getPaymentVoucherBranchesSelection')
        this.$store.dispatch('GetPreparedBy', {url: 'get-prepared-by', module: 'StoreCashIn'})
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        this.$store.dispatch('getCompanyClassificationDropdown')
        this.commitTabData(0)
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS'
        ])
    },
    methods: {
        commitTabData(key){
            this.$store.commit('STORE_CASH_IN_TAB', this.tabs[key])
        }
    },
    watch: {
        tab:{
            handler(val){
                this.$store.commit('SELECTED_TAB', val)
            }
        },
        USER_ACCESS:{
            handler(val){
                if (val != 'fail') {
                    this.userAccess = val.map(e=>e.actions_code)
                }
            },
            immediate: true
        },
    }
}
</script>