<template>
    <div class="tabs-body-color">
        <v-container fluid>
            <v-row>
                <DefectiveTableComponent v-if="isActive_tab == rfStatus" :rfStatus="rfStatus"></DefectiveTableComponent >  
            </v-row>
        </v-container>
    </div>
</template>

<script>
import DefectiveTableComponent from "@/views/main/modules/Services/Views/DefectiveComponent/DefectiveTableComponent.vue";

import { mapGetters } from 'vuex';
export default {
    data(){
        return{
            
        }
    },

    props:["isActive_tab",'rfStatus'],

    components: {
        DefectiveTableComponent,
    },

    computed: {
        ...mapGetters([
            'GET_REFRESH_DEFECTIVE',
            'GET_DEFECTIVE_TAB',
        ]),
    },

    watch: {
        'isActive_tab':{
            handler(val){
                this.$store.commit('REFRESH_DEFECTIVE', true)
            }
        }
    }
}
</script>

<style scoped>

</style>