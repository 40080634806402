<template>
    <div class="tabs-body-color">
      <div class="d-flex">
        <v-btn class="py-auto my-3" @click="AddDialog()"><v-icon>mdi-plus</v-icon> Job Order </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          class="py-auto my-3"
          :loading="GET_EXPORT_JOB_ORDERS"
          @click="() => { $store.commit('EXPORT_JOB_ORDERS', true) }"
        ><v-icon>mdi-file-export</v-icon> Export </v-btn>
      </div>
      <v-card fluid class="pa-0 rounded-0">
        <v-card-text>
          <!-- <v-row class="pt-4 pl-2" v-if="userAccess.create">
            <v-col cols="6" class="d-flex no-block align-items-center">
              
            </v-col>
          </v-row> -->
          <v-row>
            <v-col lg="12">
              <JobOrderTableComponent></JobOrderTableComponent>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </template>
  <script>
  import JobOrderTableComponent from "@/views/main/modules/Admin/JobOrder/JobOrderTableComponent.vue";
  import { mapGetters } from "vuex";
  export default {
    components: {
      JobOrderTableComponent,
    },
    data() {
      return {
        userAccess: {
          create: false,
        },
      };
    },
    computed: {
      ...mapGetters(["USER_ACCESS", "GET_JO_DISPATCH_STATUS", 'GET_EXPORT_JOB_ORDERS']),
    },
    mounted() {},
    methods: {
      AddDialog() {
        this.$store.dispatch('getServiceNameSuggestions')
        this.$store.dispatch("getLastJONum");
        // this.$store.dispatch('getAllRRFNum');
        this.$store.commit("ADMIN_ITEMS_LIST_SECTION", true);
        this.$store.commit("DIALOG", true);
        this.$store.commit("ACTION", "Submit");
      },
    },
    watch: {
      USER_ACCESS: {
        handler(val) {
          if (val != "fail") {
            this.userAccess.create = false;
            val.forEach((e) => {
              if (e.actions_code == "create") {
                this.userAccess.create = true;
              }
            });
          }
        },
      },
    },
  };
  </script>
  <style scoped>
  .rounded-0 {
    border-radius: 0;
  }
  </style>