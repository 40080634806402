
import SpareParts from "@/views/main/modules/Services/Views/SparePartsComponent/SparePartsView.vue";
import repository from "@/api/credentials";

const routes = 
        [{
              path: "/services/spare-parts/:id",
              name: "spareparts",
              component: SpareParts,
              beforeEnter:(to,from,next)=>{
                repository.checkAccessUser(to.params.id).then((response)=>{
                    if(response.data == 'fail'){
                        next('/no-access')
                        }else{
                        next()
                    }
                }).catch(()=>{

                })

          },
              meta:{
                   requiresAuth:true
                 }
          }]
  

  

export default routes;
