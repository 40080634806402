<template>
  <div>
    <!-- <table id="company_name" >
      <tbody style="line-height: 1.9">
        <tr>
          <td>
            <p style="font-size: 18px; font-weight: bold;">
              INGCOPH Traders Inc.
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <table id="company_address_and_contacts" >
      <tbody style="font-size: 12px; line-height: 1.9">
        <tr>
          <td>
            <p>2 D Arellano St. Bagong Barrio, Caloocan City
              <br>Cell No.:
              <br>Tel. No.:
              <br>Service Mobile / Viber No.: +63 917 625 2286
              <br>Spare Parts Mobile / Viber No.:
            </p>
          </td>
        </tr>
      </tbody>
    </table> -->
    <table id="form-title">
      <tbody>
        <tr>
            <td>
              <p><span style="font-size: 18px; font-weight: bold;">{{ header.form_title?.toUpperCase() }}</span></p>
            </td>
          </tr>
      </tbody>
    </table>

    <table id="header-left" style="font-size: 12px; line-height: 1.9">
        <tbody>
          <tr v-for="header in headers_details.headerLeft" :key="header?.id">
              <td>
                  <p style="font-weight: bold;">{{header.label?.toUpperCase() }}: {{ header.value }}</p>
              </td>
          </tr>
        </tbody>
    </table>

    <table id="header-thirdPart"  v-if="headers_details.headerThirdPart?.length != 0" style="font-size: 12px; line-height: 1.9">
        <tbody>
          <tr v-for="header in headers_details.headerThirdPart" :key="header?.id">
              <td>
                  <p style="font-weight: bold;"><span>{{ header?.label.toUpperCase() }} {{ header?.value }}</span></p>
              </td>
          </tr>
        </tbody>
    </table>

    <table id="header-right" v-if="headers_details.headerRight?.length != 0" style="font-size: 12px; line-height: 1.9">
        <tbody>
          <tr v-for="header in headers_details.headerRight" :key="header?.id">
              <td>
                  <p style="font-weight: bold;"><span>{{ header?.label.toUpperCase() }}: {{ header?.value }}</span></p>
              </td>
          </tr>
        </tbody>
    </table>
  </div>
</template>

<script>
  // SAMPLE DATA FORMAT TO BE FOLLOW IN USING THIS COMPONENT

  // header:{
  //     form_title: 'Title of the form here.',
  //     header_details: [
  //         {
  //             id:1,
  //             label: 'issued at',
  //             value: 'Date'
  //         }, 
  //         {
  //             id:2,
  //             label: 'From',
  //             value: 'location'
  //         },
  //         {
  //             id:3,
  //             label: 'From',
  //             value: 'location'
  //         },
  //         {
  //             id: 4,
  //             label:'Ref number',
  //             value: 'Reference number',
  //         },
  //     ],
  // }

// WAY TO RENDER IT TO PDF
// YOU HAVE TO INCLUDE THE FOLLOWING INTO YOUR FUNCTION

// pdf.autoTable({
//   html: '#form-title',
//   theme:"plain",
//   margin: {top: 0},
//   startY: 37.7,
//   styles: {
//       fontSize:14,
//       overflow:'hidden',
//   },
//   columnStyles: {
//       0: {cellWidth: 'auto', halign:'center'},
//   },
// })

// pdf.autoTable({
//   html: '#header-left',
//   theme:"plain",
//   margin: {left:12.7},
//   startY: 52.7,
//   styles: {
//       fontSize:10,
//       overflow:'hidden',
//   },
// })
// if ($('#header-thirdPart tr').length != 0)
// {
//   pdf.autoTable({
//       html: '#header-thirdPart',
//       theme:"plain",
//       margin: {left: 75},
//       startY: 52.7,
//       styles: {
//           fontSize:10,
//           overflow:'hidden',
//       },
//   })
// }
// if ($('#header-right tr').length != 0)
// {
//     pdf.autoTable({
//         html: '#header-right',
//         theme:"plain",
//         margin: {left: 137},
//         startY: 52.7,
//         styles: {
//             fontSize:10,
//             overflow:'hidden',
//         },
//     })
// }
export default {
  props: {
    header: {
      type: Object,
      default: () => {},
    }
  },
  computed: {
    headers_details() {
      const headers = this.header.header_details;
      const headerLength = this.header.header_details?.length;
      const headerLeft = [];
      const headerRight = [];
      const headerThirdPart = [];

      if (headerLength <= 2)
      {
        headers.forEach((e) => headerLeft.push(e));
      } else {
        if (headers?.every( e => (e.label.length + e.value.length) < 20))
        {
          if (headerLength % 3 === 0)
          {
            for (let head = 0; head < headers.length / 3; head++)
            {
              headerLeft.push(headers[head]);
            }
            for (let head = headers.length / 3; head < (headers.length / 3) * 2; head++)
            {
              headerThirdPart.push(headers[head]);
            }
            for (let head = (headers.length / 3) * 2; head < (headers.length / 3) * 3; head++)
            {
              headerRight.push(headers[head]);
            }
          }

          if (headerLength % 3 === 1)
          { 
            for ( let head = 0; head < Math.floor(headers.length / 3) + 1; head++)
            {
              headerLeft.push(headers[head]);
            }
            for (let head = Math.floor(headers.length / 3) + 1; head < (Math.floor(headers.length / 3) * 2)  + 1; head++ )
            {
              headerThirdPart.push(headers[head]);
            }
            for (let head = (Math.floor(headers.length / 3) * 2) + 1; head < (Math.floor(headers.length / 3) * 3)  + 1; head++ )
            {
              headerRight.push(headers[head]);
            }
          }

          if (headerLength % 3 === 2)
          { 
            for ( let head = 0; head < Math.floor(headers.length / 3) + 1; head++)
            {
              headerLeft.push(headers[head]);
            }
            for (let head = Math.floor(headers.length / 3) + 1; head < (Math.floor(headers.length / 3) * 2 ) + 2; head++ )
            {
              headerThirdPart.push(headers[head]);
            }
            for (let head = (Math.floor(headers.length / 3) * 2 ) + 2; head < (Math.floor(headers.length / 3) * 3 ) + 2; head++ )
            {
              
              headerRight.push(headers[head]);
            }
          }
        } else {
          if (headerLength % 2 === 0)
          {
            for (let head = 0; head < headers.length / 2; head++)
            {
              headerLeft.push(headers[head]);
            }
            for (let head = headers.length / 2; head < headers.length; head++)
            {
              headerRight.push(headers[head]);
            }
          }

          if (headerLength % 2 === 1)
          { 
            for ( let head = 0; head < (headers.length / 2) + .5; head++)
            {
              headerLeft.push(headers[head]);
            }
            for (let head = (headers.length / 2) + .5; head < headers.length; head++ )
            {
              headerRight.push(headers[head]);
            }
          }   
        }
      }
    
      return {
        headerLeft,
        headerRight,
        headerThirdPart
      }
    }
  },
}
</script>

<style>

</style>