<template>
  <v-card elevation="20">
    <v-col
      class="d-flex no-block align-items-center justify-content-start pa-2"
      v-if="loaded == true"
    >
      <!-- <buttons class="ml-2"
			v-if="cp_text != 'is_inactive'"
			:action="buttons.actions.create"
			:button_icon="buttons.icons.create"
			:color="buttons.colors.create"
			@click="adddialog"
		></buttons> -->
      <div class="row" v-if="cp_text != 'is_inactive'">
        <div class="col-12 d-flex no-block align-items-center">
          <div class="btn-toolbar">
            <v-btn @click="adddialog()">
              <v-icon>mdi-plus</v-icon>
              Add
            </v-btn>
          </div>
        </div>
      </div>
    </v-col>

    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        @change="searchInput()"
        v-model="search"
        label="Search"
        single-line
        hide-details
      >
      </v-text-field>
      <v-icon>'mdi-trash-can</v-icon>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="item"
      :search="search"
      :options.sync="options"
      :server-items-length="totalCustomers"
      :items-per-page="10"
      class="w-100"
      :multi-sort="true"
      :loading="!loaded"
      loading-text="Loading... Please wait"
      :footer-props="{
        itemsPerPageOptions: itemsPerPageOptions,
      }"
    >
      <template v-slot:[`item.action`]="{ item }">
        <!-- <buttons
			v-if="userAccess.view"
			:action="buttons.actions.view"
			:button_icon="buttons.icons.view"
			:color="buttons.colors.view"
			:button_text="buttons.type.text"
			:icon="buttons.type.icon"
			@click="viewdialogs(item)"
        ></buttons>
        <buttons
			v-if="userAccess.edit"
			:action="buttons.actions.edit"
			:button_icon="buttons.icons.edit"
			:color="buttons.colors.edit"
			:button_text="buttons.type.text"
			:icon="buttons.type.icon"
			@click="editdialogs(item)"
        ></buttons>
        <buttons
			v-if="userAccess.delete"
			:action="buttons.actions.delete"
			:button_icon="buttons.icons.delete"
			:color="buttons.colors.delete"
			:button_text="buttons.type.text"
			:icon="buttons.type.icon"
			@click="deleteCustomer(item.id)"
        ></buttons> -->
        <v-btn text icon color="orange">
          <v-icon class="btn-action" small @click="viewdialogs(item)"
            >mdi-eye</v-icon
          >
        </v-btn>
        <v-btn text icon color="blue">
          <v-icon class="btn-action" small @click="editdialogs(item)"
            >mdi-pencil</v-icon
          >
        </v-btn>
        <v-btn text icon color="red">
          <v-icon class="btn-action" small @click="deleteCustomer(item.id)"
            >mdi-delete</v-icon
          >
        </v-btn>
      </template>
      <template v-slot:[`item.marker`]="{ item }">
        <v-img
          class="img-thumbnail"
          width="30"
          height="30"
          :src="host + item.marker"
          @error="checkImagePath(item)"
          eager
          contain
        ></v-img>
      </template>
    </v-data-table>
  </v-card>
</template>
	<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
// import buttons from "../../../layouts/Buttons/DialogButton.vue";
// import buttons_components from "../../../../../assets/js/components-js.js";

export default {
  props: ["cp_text", "component_customer"],
  // components: { buttons, buttons_components },
  data() {
    return {
      // buttons: buttons_components.buttons,
      loaded: false,
      totalCustomers: 0,
      options: {},
      customers: [],
      itemsPerPageOptions: [5, 10, 15, 20],
      itemsPerPage: 10,
      search: "",
      awaitingSearch: false,
      headers: [
        {
          text: "Classifications",
          align: "left",
          value: "classification_name",
        },
        { text: "Marker", align: "left", value: "marker" },
        { text: "Actions", align: "center", value: "action", sortable: false },
      ],
      json_data: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      userAccess: {
        view: false,
        create: false,
        edit: false,
        delete: false,
      },
      status_text: this.cp_text,
      selected: {},
      item: [],
      image_path: null,
      host: process.env.VUE_APP_API_HOST,
    };
  },
  mounted() {
    this.$store.dispatch(
      "checkAccessUser",
      this.$router.currentRoute.params.id
    );
  },
  methods: {
    getAllCustomers() {
      const payload = {
        search: this.search,
        page: this.options.page,
        itemsPerPage: this.options.itemsPerPage,
        url: "getAllMapMarker",
        method: "table",
        tab:'legends',
      };
      this.loaded = false;
      this.$store
        .dispatch("getCustomerMaps", payload)
        .then((response) => {
          let data = response.data.data;
          this.item = data.data;
          this.totalCustomers = data.total;
          this.loaded = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchInput() {
      this.getAllCustomers();
    },
    viewdialogs(item) {
      this.$store.commit("EDIT_MARKER", item);
      this.$store.commit("DIALOG", true);
      this.$store.commit("ACTION", "View");
    },
    editdialogs(item) {
      this.$store.commit("EDIT_MARKER", item);
      this.$store.commit("DIALOG", true);
      this.$store.commit("ACTION", "Update");
    },
    deleteCustomer(id) {
      Swal.fire({
        icon: 'question',
        text: "Are you sure you want to Delete?",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonColor: "#397373",
        cancelButtonColor: "#397373",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("postCustomerMaps", {
              id: id,
              url: "deleteMapMarker",
              tab:'legends',
            })
            .then((success) => {
              Swal.fire({
                text: "Delete Customer Successful!",
                icon: "success",
              });
            });
        }
      });
    },
    adddialog() {
      this.$store.commit("DIALOG", true);
      this.$store.commit("ACTION", "Submit");
    },
    checkImagePath() {
      if (this.image_path == null) {
        return process.env.VUE_APP_API_HOST + "/images/items/no-image.png";
      }
      return this.image_path;
    },
  },
  computed: {
    ...mapGetters([
      "DIALOGS",
      "GET_ALL_CUSTOMER",
      "TOTAL_CUSTOMER",
      "ACTION",
      "USER_ACCESS",
      "GET_CLASSIFICATIONS_DROPDOWN",
      "MARKER_REFRESH",
      'SELECTED_MARKER',
      'GET_NEW_MARKER',
      'MAP_REFRESH',
    ]),
  },
  watch: {
    options: {
      handler() {
        this.getAllCustomers();
      },
    },
    USER_ACCESS: {
      handler(val) {
        if (val != "fail") {
          this.userAccess.edit = false;
          this.userAccess.delete = false;
          this.userAccess.view = false;
          this.userAccess.create = false;
          val.forEach((e) => {
            if (e.actions_code == "edit") {
              this.userAccess.edit = true;
            } else if (e.actions_code == "delete") {
              this.userAccess.delete = true;
            } else if (e.actions_code == "view") {
              this.userAccess.view = true;
            } else if (e.actions_code == "create") {
              this.userAccess.create = true;
            }
          });
        }
      },
    },
    GET_NEW_MARKER: {
      handler(val) {
        if(val){
          this.getAllCustomers()
          this.$store.commit('NEW_MARKER', false)
        }
      }
    },
  },
};
</script>
