<template>
    <div class="tabs-body-color">
        <v-container fluid class="pa-0">
                <v-row class="pt-4 pl-2" v-if="userAccess.create">
                    <v-col cols="12" class="d-flex no-block align-items-center">
                        <div class="btn-toolbar pl-0">
                            <v-btn class="ml-1 mb-2" @click="AddDialog()">
                                <v-icon>mdi-plus</v-icon>ADD
                            </v-btn>
                        </div>
                        <div class="btn-toolbar pl-0 ml-auto mr-4">
                            <v-btn
                                class="ml-1 mb-2"
                                :loading="getJournalVoucherManagerItemExportInProgress"
                                @click="exportJournalVoucherManagerItems()"
                            >EXPORT</v-btn>
                        </div>
                    </v-col>
                </v-row>
            <v-row>
                <JournalVoucherManagerTableComponent v-if="GET_JOURNAL_VOUCHER_MANAGER_STATUS.tab_name == isActive_tab"/>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import JournalVoucherManagerTableComponent from '@/views/main/modules/Accounting/JournalVoucherManager/JournalVoucherManagerTableComponent.vue';
import { mapGetters } from 'vuex'
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
export default {
    components: {
        JournalVoucherManagerTableComponent,
    },
        props:["isActive_tab"],
    mixins: [SharedFunctionsComponentVue],
    data() {
        return {
            getJournalVoucherManagerItemExportInProgress: false,
            userAccess:{
                create:false
            },
            dialog_width:"",
            loading: false,
        }
    },
    computed: {
        ...mapGetters([
            'USER_ACCESS',
            'GET_JOURNAL_VOUCHER_MANAGER_STATUS', 
            'STATUS'
        ]),
        computedStatus() {
            switch (this.GET_JOURNAL_VOUCHER_MANAGER_STATUS.tab_name) {
            case 'ALL':
                return -1;
            case 'PENDING':
                return 1;
            case 'APPROVED':
                return 2;
            case 'REJECTED':
                return 3;
            case 'CANCELLED':
                return 4;
            case 'RELEASED':
                return 6;
            case 'CANCELLED':
                return 5;
            default:
                return -1;
            }
        },
    },
    mounted() {},
    methods: {
        AddDialog(){
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", "Submit");
            // this.$store.dispatch('getJVNum');
            // this.$store.dispatch('getAccountingJobSelection');
        },
        width(width) {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return "100%"
                case 'sm': return "100%"
                case 'md': return '100%'
                case 'lg': return '100%'
                case 'xl': return width
            }
        },
        async exportJournalVoucherManagerItems() {
            this.getJournalVoucherManagerItemExportInProgress = true;
            let payload = {
                filters: this.$store.state.journalvouchermanager.journal_voucher_manager_filters,
                status:this.computedStatus,
                export: true,
                url:'getAllJournalVoucherManager',
            }
            this.$store.dispatch('getJournalVoucherManager', payload).then((response) => {
                let exportData = response.data;
                this.exportJVM(exportData)
            })
        },
        async exportJVM(exportData){
            let detailsTable = []

            exportData.forEach(e=>{
                let statusText = this.STATUS.JOURNAL_VOUCHER.find(s=>s.value == e.status).text
                e.status = statusText
                
                e.journal_voucher_manager_items.forEach(v=>{
                    detailsTable.push({
                        ...v,
                        jvm_num: e.jvm_num,
                        vendor_name: e.vendor_name,
                        company_name: e.company_name,
                        store_branch_name: e.store_branch_name,
                        prepared_by_name: e.prepared_by_name,
                        released_by_name: e.released_by_name,
                        jvm_date: e.jvm_date,
                        status: statusText,
                    })
                })
            })
            let jvHeaderAndData = {
                ['JOURNAL VOUCHER MANAGER']: {
                    headers:[
                        { header: 'JVM #', key:'jvm_num', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'VENDOR', key:'vendor_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'COMPANY', key:'company_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'STORE BRANCH', key:'store_branch_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'PREPARED BY', key:'prepared_by_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'RELEASED BY', key:'released_by_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'TRANSACT DATE', key:'jvm_date', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'STATUS', key:'status', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                    ],
                    data: []
                },
                ['JOURNAL VOUCHERS MANAGER (DETAILED)']: {
                    headers:[
                        { header: 'JVM #', key:'jvm_num', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'VENDOR', key:'vendor_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'COMPANY', key:'company_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'STORE BRANCH', key:'store_branch_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'PREPARED BY', key:'prepared_by_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'RELEASED BY', key:'released_by_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'TRANSACT DATE', key:'jvm_date', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'STATUS', key:'status', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'PARTICULARS', key:'particulars', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'DEBIT', key:'debit_amount', formatAs: 'float', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'right' } } },
                        { header: 'CREDIT', key:'credit_amount', formatAs: 'float', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'right' } } },
                        { header: 'RECOGNITION MONTH', key:'recognition_month', formatAs: 'date', width: 20, style: { alignment: { vertical: 'center', horizontal: 'center' } } },
                        { header: 'DEPARTMENT', key:'department_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'JOB', key:'job_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                    ],
                    data: []
                }
            }
            jvHeaderAndData['JOURNAL VOUCHER MANAGER'].data = exportData
            jvHeaderAndData['JOURNAL VOUCHERS MANAGER (DETAILED)'].data = detailsTable
            await this.exportExcel(jvHeaderAndData, 'JOURNAL VOUCHER MANAGER', 'F69F1A')
            this.getJournalVoucherManagerItemExportInProgress = false
        }
    },
    watch:{
        USER_ACCESS:{
            handler(val){
                if(val != "fail") {
                    this.userAccess.create = false;
                    val.forEach(e => {
                        if (e.actions_code == 'create') {
                            this.userAccess.create = true;
                            }
                    });
                }
            }
        }
    }
};
</script>
