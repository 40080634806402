import api from "../../api";

export default {
    adminAssetTurnoverGet(params) {
        return api.get(`/admin/asset-turnover/${params.url}`, { params });
    },
    adminAssetTurnoverPost(params) {
        return api.post(`/admin/asset-turnover/${params.url}`, params);
    },
    adminAssetTurnoverPut(params) {
        return api.put(`/admin/asset-turnover/${params.url}`, params);
    },
    adminAssetTurnoverDelete(params) {
        return api.delete(`/admin/asset-turnover/${params.url}`, params);
    },
    getAdminAssetTurnoverItemFile(params){
        return api.get(`admin/get-admin-turnover-item-file/${params.id}`);
    },
    deleteAdminAssetTurnoverItemFile(params){
        return api.delete(`admin/delete-admin-turnover-item-file/${params.id}`)
    },
    printTurnOverForm(params){
        return api.get('/admin/print-turnover-form',{ params })
    },
    exportTurnOvers(params){
        return api.get('/admin/export-turnovers',{ params })
    },
};
