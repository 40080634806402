import repository from "@/api/modules/Accounting/ChequeVoucher";

export default {
  state: {
    autoFill: {},
    disableVendor: false,
    chequeVoucherFormLoaded: true,
    chequeVoucherTableRefreshToken: false,
    chequeVoucherViewAction: '',
    form: {},
    isDialogVisible: false,
    lastCreatedChequeVoucher: null,
  },
  getters: {
    GET_CV_LOAD:(state)=>state.chequeVoucherFormLoaded,
    GET_CV_ACTION:(state)=>state.chequeVoucherViewAction,
    GET_CV_TABLE:(state)=>state.chequeVoucherTableRefreshToken,
    GET_FORM_DATA: (state)=>state.form,
    GET_DISABLE_VENDOR: (state)=>state.disableVendor,
  },
  actions: {
    addBooklet({commit}, payload) {
      return new Promise((resolve, reject) => {
          repository.addBooklet(payload).then((response) => {
              if (response.status == 200) {
                resolve(response)
              }
          }).catch((error) => {
              reject(error)
          });
      })
    },
    createChequeVoucher({commit}, payload) {
        return new Promise((resolve, reject) => {
            repository.createChequeVoucher(payload).then((response) => {
                if (response.status == 200) {
                  resolve(response)
                }
            }).catch((error) => {
                reject(error)
            });
        })
    },
    getBooklet({commit}, payload) {
      return new Promise((resolve, reject) => {
          repository.getBooklet(payload).then((response) => {
              if (response.status == 200) {
                resolve(response)
              }
          }).catch((error) => {
              reject(error)
          });
      })
    },
    getBooklets({commit}, payload) {
      return new Promise((resolve, reject) => {
          repository.getBooklets(payload).then((response) => {
              if (response.status == 200) {
                resolve(response)
              }
          }).catch((error) => {
              reject(error)
          });
      })
    },
    getChequeVoucher({commit}, payload) {
      return new Promise((resolve, reject) => {
          repository.getChequeVoucher(payload).then((response) => {
              if (response.status == 200) {
                resolve(response)
              }
          }).catch((error) => {
              reject(error)
          });
      })
    },
    getChequeVouchers({commit}, payload) {
      return new Promise((resolve, reject) => {
          repository.getChequeVouchers(payload).then((response) => {
              if (response.status == 200) {
                resolve(response)
              }
          }).catch((error) => {
              reject(error)
          });
      })
    },
    getNextChequeNumber({commit}, payload) {
      return new Promise((resolve, reject) => {
          repository.getNextChequeNumber(payload).then((response) => {
              if (response.status == 200) {
                resolve(response)
              }
          }).catch((error) => {
              reject(error)
          });
      })
    },
    pulseBooklet({commit}, payload) {
      return new Promise((resolve, reject) => {
          repository.pulseBooklet(payload).then((response) => {
              if (response.status == 200) {
                resolve(response)
              }
          }).catch((error) => {
              reject(error)
          });
      })
    },
    removeChequeVoucher({commit}, payload) {
      return new Promise((resolve, reject) => {
          repository.removeChequeVoucher(payload).then((response) => {
              if (response.status == 200) {
                resolve(response)
              }
          }).catch((error) => {
              reject(error)
          });
      })
    },
    selectBooklet({commit}, payload) {
      return new Promise((resolve, reject) => {
          repository.selectBooklet(payload).then((response) => {
              if (response.status == 200) {
                resolve(response)
              }
          }).catch((error) => {
              reject(error)
          });
      })
    },
    updateChequeVoucher({commit}, payload) {
      return new Promise((resolve, reject) => {
          repository.updateChequeVoucher(payload).then((response) => {
              if (response.status == 200) {
                resolve(response)
              }
          }).catch((error) => {
              reject(error)
          });
      })
    },
    replaceChequeVoucher({commit}, payload) {
      return new Promise((resolve, reject) => {
          repository.replaceChequeVoucher(payload).then((response) => {
              if (response.status == 200) {
                resolve(response)
              }
          }).catch((error) => {
              reject(error)
          });
      })
    },
    updateClearedDate({commit}, payload) {
      return new Promise((resolve, reject) => {
          repository.updateClearedDate(payload).then((response) => {
              if (response.status == 200) {
                resolve(response)
              }
          }).catch((error) => {
              reject(error)
          });
      })
    },
    getBookletList({commit}, payload) {
      return new Promise((resolve, reject) => {
          repository.getBookletList(payload).then((response) => {
              if (response.status == 200) {
                resolve(response)
              }
          }).catch((error) => {
              reject(error)
          });
      })
    },
    cancelBooklet({commit}, payload) {
      return new Promise((resolve, reject) => {
          repository.cancelBooklet(payload).then((response) => {
              if (response.status == 200) {
                resolve(response)
              }
          }).catch((error) => {
              reject(error)
          });
      })
    },
    cancelBookletParent({commit}, payload) {
      return new Promise((resolve, reject) => {
          repository.cancelBookletParent(payload).then((response) => {
              if (response.status == 200) {
                resolve(response)
              }
          }).catch((error) => {
              reject(error)
          });
      })
    },
  },
  mutations: {
    CV_LOAD: (state, data) => { 
      state.chequeVoucherFormLoaded = data
    },
    CV_ACTION: (state, data) => {
      state.chequeVoucherViewAction = data
    },
    CV_TABLE: (state, data) => {
      state.chequeVoucherTableRefreshToken = data
    },
    FORM_DATA: (state, data) => {
      state.form = data;
    },
    IS_DIALOG_VISIBLE: (state, data) => {
      state.isDialogVisible = data;
    },
    DISABLE_VENDOR: (state, data) => {
      state.disableVendor = data;
    },
  },
}