<template>
    <div style="width:100%">
        <v-card class="pa-4 overflow-auto" style="border-radius:0px">
             <v-row class="pa-5">
                <v-col md="12" class="pa-3">
                    <v-flex class="d-flex mb-4">
                        <h5 class="pb-2">Assigned Parts</h5>
                        <v-spacer />
                        <v-col class="d-flex justify-end" v-if="isModule == 'Items'" cols="2">
                            <v-menu
                                open-delay="2"
                                right
                                offset-y
                            >
                                <template v-slot:activator="{ on: menu, attrs }">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-btn
                                                :loading="exportLoading"
                                                color="#f69f1a"
                                                v-bind="attrs"
                                                v-on="{...tooltip, ...menu}"
                                                outlined
                                                small
                                                dark
                                                fab
                                            >
                                                <v-icon>mdi-printer-pos-wrench</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Export To Excel</span>
                                    </v-tooltip>
                                </template>
                                <v-list>
                                    <v-list-item
                                        v-for="(item, i) in ['Page', 'All']"
                                        :key="i"
                                        dense
                                        @click="exportDetails(item)"
                                    >
                                        <v-list-item-title>{{ item }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-col>
                    </v-flex>
                    <div class="d-flex justify-space-between pb-5">
                            <v-col md="6">
                                <v-row>
                                    <v-col cols="3">
                                        <v-autocomplete
                                        :items="GET_ITEM_TYPES_EXCLUDE_SP"
                                        v-model="item_type_ids"
                                        item-value="id"
                                        item-text="name"
                                        variant="solo"
                                        clearable
                                        outlined
                                        auto-select-first
                                        dense
                                        label="Type"
                                        placeholder="Select Type"
                                        @change="onSelectTypes"
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-autocomplete
                                        :items="GET_ITEM_CATEGORIES"
                                        v-model="category_ids"
                                        item-value="value"
                                        item-text="text"
                                        multiple
                                        small-chips
                                        deletable-chips
                                        outlined
                                        clearable
                                        dense
                                        auto-select-first
                                        label="Category"
                                        placeholder="Select Category"
                                        @change="onSelectCategory"
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col md="3">
                                <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details
                                clearable
                                dense
                                @click:append="getAllPtItems()"
                                @keydown.enter="getAllPtItems()"
                            />
                            </v-col>
                        </div>

                        <!-- ITEM DATA TABLE -->
                        <v-data-table
                            :headers="headers"
                            :search="search"
                            :items="exclude_sp_items"
                            :loading="loading"
                            :options.sync="exclude_sp_options"
                            :server-items-length="excludesp_totalItems"
                            item-key="id"
                            single-select
                        >
                        <template v-slot:[`item.image_path`]="{ item }">
                            <v-img class="img-thumbnail" width="50" height="50" :src="checkImagePath(item)" @error="checkImage(item)" eager contain></v-img>
                        </template>

                        <template v-slot:[`item.action`]="{ item }">
                            <span class="text-nowrap">
                                <v-btn text icon color="orange">
                                    <v-icon class="btn-action" small @click="rowClick(item,'view')" >mdi-eye</v-icon>
                                </v-btn>
                                <v-btn text icon color="blue" v-if="userAccess.edit_sp">
                                    <v-icon class="btn-action" small @click="rowClick(item,'edit')" >mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn text icon color="red" v-if="userAccess.remove_sp">
                                    <v-icon class="btn-action" small @click="rowClick(item,'delete')" >mdi-delete</v-icon>
                                </v-btn>
                            </span>
                        </template>

                        </v-data-table>
                        <!-- ITEM DATA TABLE -->
                </v-col>
             </v-row>
        </v-card>

        <div class="text-center">
            <v-dialog
            v-model="dialog"
            scrollable
            >
            <v-card class="pa-4 overflow-auto" style="height:800px;">
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">Spare Parts</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="dialogClose()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text class="pa-4 overflow-auto" style="height:100%;" v-if="!loaded">
                    <v-row>
                    <v-col md="7" class="d-flex justify-space-between align-center">
                        <v-btn
                            small
                            class="ml-8"
                            v-if="userAccess.remove_sp && action == 'delete' && isModule != 'Items'"
                            @click="updateSpareParts()"
                            :disabled="!remove_sp"
                            icon
                            text
                            >
                            <v-icon color="red">mdi-delete</v-icon>
                        </v-btn>
                        <v-flex v-if="isModule=='Items' && ['edit','view'].includes(action)" class="d-flex align-center pl-6">
                            <!-- <v-tooltip bottom color="#f69f1a">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        color="#f69f1a"
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                        dark
                                        fab
                                        x-small
                                        icon
                                        @click="exportDetails(items,'',selected_item_model)"
                                    >
                                        <v-icon color="#f69f1a">mdi-printer-pos-wrench</v-icon>
                                    </v-btn>
                                </template>
                                <span>Export</span>
                            </v-tooltip> -->
                            <v-tooltip bottom color="#f69f1a">
                                <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="ml-4"
                                            color="#f69f1a"
                                            v-bind="attrs"
                                            v-on="on"
                                            outlined
                                            dark
                                            fab
                                            x-small
                                            icon
                                            @click="openUploadFilesDialog()"
                                        >   
                                            <v-icon>mdi-attachment</v-icon>
                                        </v-btn>   
                                </template>
                                <span>Attach File/Image</span>
                            </v-tooltip>
                            <v-tooltip bottom color="#f69f1a">
                                <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="ml-4"
                                            color="#f69f1a"
                                            v-bind="attrs"
                                            v-on="on"
                                            outlined
                                            dark
                                            fab
                                            x-small
                                            icon
                                            @click="downloadItemsExplodedFile()"
                                        >   
                                            <v-icon>mdi-cloud-download</v-icon>
                                        </v-btn>   
                                </template>
                                <span>Download File/Image</span>
                            </v-tooltip>
                        </v-flex>
                        <v-spacer></v-spacer>

                        <v-text-field
                        v-model="search_sp"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                        clearable
                        dense
                        @click:append="getAllChildSpareParts()"
                        @keydown.enter="getAllChildSpareParts()"
                        style="max-width: 500px;"
                        />
                    </v-col>
                    <v-col class="text-center">
                        <h3>{{ selected_row_name  }}</h3>
                    </v-col>
                </v-row>
               <v-row class="pa-5" >
                <v-col md="7" v-if="selected_item">
                        <!-- ITEM DATA TABLE -->
                        <v-data-table
                        :headers="isModule === 'Items' ? items_sp_headers : sp_headers"
                        v-model="sp_selected"
                        :items="items"
                        :loading="loading_sp"
                        :item-class="rowClass"
                        item-key="id"
                        :show-select="action == 'delete'"
                        dense
                        >
                        <template v-slot:[`item.image_path`]="{ item }">
                            <v-img class="img-thumbnail" width="50" height="50" :src="checkImagePath(item)" @error="checkImage(item)" eager contain></v-img>
                        </template>
                        <template v-if="isModule != 'Items'" v-slot:[`item.model`] = "{item}">
                            <span v-if="action == 'edit'">
                                <v-autocomplete 
                                :items="GET_UNASSIGNED_SPARE_PARTS"
                                :placeholder="getPlaceHolder(item.model)"
                                item-text="model"
                                v-model="item.model"
                                auto-select-first
                                return-object
                                color="#b3d4fc"
                                outlined
                                dense
                                flat
                                @input="selectItemModel(item)"
                                />
                            </span>
                            <span v-else>{{item.model}}</span>
                        </template>
                        <template v-if="isModule === 'Items'" v-slot:[`item.description`]="{ item,index }">
                            <span v-if="action=='view'">{{item.description}}</span>
                            <span v-else>
                                <v-text-field
                                    class="mt-2"
                                    v-model="item.description"
                                    outlined
                                    dense
                                    @change="changeDescription(item,index)"
                                    @input = "checkChanges()"
                                    >
                                </v-text-field>
                            </span>
                        </template>
                        <template v-slot:[`item.baklas_qty`]="{ item,index }">
                            <span v-if="action=='view'">{{item.baklas_qty}}</span>
                            <span v-else>
                                <v-text-field
                                    v-if="isModule != 'Items'"
                                    v-model="item.baklas_qty"
                                    outlined
                                    dense
                                    @change="updateBaklasQty(item,index)"
                                >
                                </v-text-field>
                                <span v-else>{{item.baklas_qty}}</span>
                            </span>
                        </template>

                        </v-data-table>
                        <!-- ITEM DATA TABLE -->
                        <v-flex v-if="isModule == 'Items' && action == 'edit'" class="d-flex justify-end">
                            <v-btn
                                :disabled="disableUpdate"
                                color="#f69f1a"
                                small
                                @click="updateDescription"
                            >
                                <v-icon class="mr-2" small>mdi-file-edit</v-icon>
                                <span>Update</span>
                            </v-btn>
                        </v-flex>
                </v-col>
                <v-col md="5" class="d-flex align-start justify-center">
                    <v-row>
                        <v-col cols="10" class="text-center d-flex justify-end align-start">
                                <PinchScrollZoom
                                    ref="zoomer"
                                    :width="600"
                                    :height="500"
                                    :scale="this.scale"
                                    :draggable="true"
                                    @scaling="scalingHandler"
                                    >
                                    <img :src="selected_row_img"
                                    class="explode_img"
                                    id="myimage"

                                    >

                                </PinchScrollZoom>

                        </v-col>
                        <v-col cols="2" class="d-flex flex-column">

                            <v-btn
                                icon
                                text
                                small
                                @click="ZoomImage()"
                                >
                                <v-icon
                                class="btn-hover"
                                >
                                    mdi-magnify-plus
                                </v-icon>
                            </v-btn>
                            <v-btn
                                icon
                                text
                                small
                                @click="ZoomImageOut()"
                                >
                                <v-icon
                                class="btn-hover"
                                >
                                mdi-magnify-minus
                                </v-icon>
                            </v-btn>
                        </v-col>

                    </v-row>

                </v-col>
               </v-row>
                </v-card-text>

                <v-card-text v-else class="text-center">
                    <v-progress-circular
                    :size="50"
                    color="amber"
                    indeterminate
                    ></v-progress-circular>
                </v-card-text>
                <v-card-actions v-if="isModule != 'Items'" class="d-flex justify-content-end">
                    <v-btn @click="updateSpareParts" v-if="action == 'edit'">Update</v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <!-- For Uploading Files -->
            <v-dialog v-model="uploadFilesDialog" persistent max-width="20%">
                <v-card class="pa-4">
                    <v-btn
                    text
                    icon
                    color="gray"
                    class="float-right"
                    @click="closeUploadFilesDialog()"
                    >
                    <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                    <div class="py-2">
                        <!-- <v-checkbox v-model="attach" label="Attach Documents" class="ma-0 pa-0 pt-2" /> -->
                        <span>Upload Files</span><span v-if="this.uploadedFiles == null" style="color:red">  *</span>
                        <v-divider/>
                    </div>

                    <div class="py-2 mt-3">
                        <v-layout row class="mx-0">
                            <v-flex :class="{'mr-2': true, 'lg3': !!uploadedFiles && uploadedFiles.attachments.length > 1, 'lg6': !!uploadedFiles && uploadedFiles.attachments.length == 1}">
                                <FileUploadComponentVue ref="fileUpload" @uploadedData="uploadedData" @reset="clearFileUpload"/>
                            </v-flex>
                            <v-flex v-if="!!uploadedFiles && uploadedFiles.attachments.length > 0" >
                                <div class="mt-2">
                                    <v-chip
                                        v-if="!!uploadedFiles && uploadedFiles.attachments.length > 0"
                                        color="#f69f1a"
                                        outlined
                                        close
                                        @click:close="clearFileUpload()"
                                    >
                                        <span class="mr-2">Upload Count: {{ uploadedFiles.attachments.length }}</span>
                                    </v-chip>
                                </div>
                            </v-flex>
                        </v-layout>
                    </div>
                    <div class="py-2 mt-4">
                        <v-card-actions class="d-flex justify-content-end">
                            <v-btn
                                color="#f69f1a"
                                style="opacity:1;"
                                @click="submitExplodedViewFile()"
                            >
                                <v-icon dense>mdi-content-save-all</v-icon>
                                <span class="ml-2">Submit</span>
                            </v-btn>
                        </v-card-actions>
                    </div>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
import PinchScrollZoom, { PinchScrollZoomEmitData } from "@coddicat/vue-pinch-scroll-zoom";
import Swal from "sweetalert2";
import { mapGetters } from 'vuex';
import FileUploadComponentVue from '@/views/main/Shared/FileUploadComponent.vue';
import SharedFunctionsComponent from "@/views/main/Shared/SharedFunctionsComponent.vue";
export default {
    mixins:[SharedFunctionsComponent],
    data(){
        return{
            singleSelect: true,
            selected: [],
            sp_selected:[],
            items:[],
            exclude_sp_items:[],
            options:{},
            exclude_sp_options:{},
            totalItems: 0,
            excludesp_totalItems: 0,
            itemsPerPage: 10,
            exclude_sp_itemsPerPage: 10,
            loading: true,
            loading_sp: true,
            search:'',
            search_sp:'',
            selected_id: '',
            selected_row_img:'',
            selected_row_name: '',
            loaded: false,
            headers: [
                { text: 'Image', align: 'left', value: 'image_path'},
                { text: 'Model', value: 'model'},
                { text: 'Name', value: 'name' },
                { text: 'UOM', value: 'uom' },
                { text: 'Category', value: 'category'},
                { text: 'Actions', align: 'center', value: 'action', sortable: false }

            ],
            sp_headers: [
                { text: 'Image', align: 'left', value: 'image_path', width: '15%' },
                { text: 'Model', value: 'model',width: '25%'},
                { text: 'Name', value: 'name' ,width: '28%'},
                { text: 'UOM', value: 'uom' ,width: '10%'},
                { text: 'Baklas Qty', value: 'baklas_qty' ,width: '15%'},
                { text: 'SP#', value: 'sp_number' ,width: '17%'},

            ],
            items_sp_headers: [,
                { text: 'Model', value: 'model',width: '15%', sortable: false},
                { text: 'Part No.', value: 'part_num' ,width: '15%', sortable: false},
                { text: 'Name', value: 'name' ,width: '28%'},
                { text: 'Description', value: 'description',width:'33%', sortable: false},
                { text: 'UOM', value: 'uom' ,width: '15%'},
                { text: 'Qty', value: 'baklas_qty' ,width: '15%', sortable: false},
            ],
            action:'',

            selected_item: false,
            dialog: false,
            scale: 1,
            remove_sp: false,

            userAccess: {
                view: false,
                edit: false,
                delete: false,
                assign: false,
                remove_sp: false,
                edit_sp:false
            },
            category_ids: [],
            item_type_ids: '',
            reset_itemsperpage: false,
            exportLoading:false,
            selected_item_model: '',
            uploadFilesDialog: false,
            uploadedFiles : null,
            attachedFiles : [],
            selectedSpToUpdate: [],
            disableUpdate: true,
            itemsCopy: [],
        }

    },
    props: ['tab', 'isModule'],
    components:{
        PinchScrollZoom,
        FileUploadComponentVue
    },
    computed:{
            ...mapGetters([
            'GET_ALL_SPARE_PARTS',
            'GET_ALL_ITEM_EXCLUDE_SP',
            'GET_ALL_ITEM_SPARE_PARTS',
            'USER_ACCESS',
            'GET_ITEM_SPARE_PARTS',
            'GET_ITEM_TYPES',
            'GET_ITEM_CATEGORIES',
            'GET_ITEM_TYPES_EXCLUDE_SP',
            'GET_ITEM_WITH_SP_FILES',
            'GET_UNASSIGNED_SPARE_PARTS'
             ]),

        },
        methods:{
            rowClick(item,action = 'view') {
                this.action = action
                this.loaded= true
                this.dialog = true;
                this.selected_row_img = process.env.VUE_APP_API_HOST + "/" + item.image_path
                this.selected_row_name = item.name +' (' +item.model+')'
                this.selected_id = item.id
                this.selected_item_model = item.model
                this.getAllChildSpareParts()
                if (this.isModule == 'Items') {
                    this.$store.dispatch('getItemWithSpFiles',item.id).then( response => {
                        this.attachedFiles = response.data;
                    });
                }
            },
            rowClass(item) {
                const rowClass = 'yellow accent-4'
                return item.item_parent_id === null ? rowClass : '';
            },
            getAllPtItems(){
                if(this.reset_itemsperpage){
                    this.exclude_sp_options.itemsPerPage = 10
                    this.reset_itemsperpage = false
                }
                this.loading = true;
                // this.exclude_sp_items = []
                let payload = {
                page: this.exclude_sp_options.page,
                itemsPerPage: this.exclude_sp_options.itemsPerPage,
                search: this.search,
                category_id: this.category_ids.map(e=>{return e}),
                item_types:  this.item_type_ids,
                }
                this.$store.dispatch('getAllItemsExcludeSp',payload).then(response=> {

                    this.exclude_sp_items = response.data.data.data
                    this.excludesp_totalItems = response.data.totalItems
                    this.loading = false;
                })
            },
            checkImagePath(item) {
            return item.image_path == null
                ? process.env.VUE_APP_API_HOST + "/images/items/no-image.png"
                : process.env.VUE_APP_API_HOST + "/" + item.image_path;
            },
            checkImage(item) {
                item.image_path = process.env.VUE_APP_API_HOST + "/images/items/no-image.png";
                return item.image_path;
            },
            scalingHandler(e){
                this.scale = e.scale
            },
            dialogClose(){
                this.scale = 1
                this.dialog = false;
                this.sp_selected = []
                this.selectedSpToUpdate = [];
                this.disableUpdate = true;
            },
            ZoomImage(){
                this.scale += 0.50
            },
            ZoomImageOut(){
                this.scale -= 0.50
            },
            update(){

            },
            updateSpareParts(){
                Swal.fire({
                    title: 'Are you sure you want to Remove these Item',
                    icon: 'warning',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    allowOutsideClick: false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor: 'grey',
                    confirmButtonText: 'Confirm',
                }).then(result=>{
                    if (result.isConfirmed) {
                        let payload = {
                                update_items: this.action == 'edit' ? this.items : this.sp_selected ,
                                parent_id:  this.selected_id,
                                action:this.action
                            }

                        this.$store.dispatch('unassignSpareParts',payload).then(response=> {
                            Swal.fire(response.data.msg,'',response.data.icon)
                            this.sp_selected = []
                            this.getAllChildSpareParts()
                        })
                    }

                })

            },
            getAllChildSpareParts(){
                this.loading_sp = true
                let payload = {
                    id: this.selected_id,
                    search: this.search_sp,
                }
                this.$store.dispatch('getItemSpareParts',payload).then(response=> {
                    this.selected_item = true
                    this.items = response.data
                    this.loading_sp = false
                    this.loaded = false
                })
            },
            onSelectTypes(item){
                this.item_type_ids = item
                this.getAllPtItems();
            },
            onSelectCategory(item){
                this.category_ids = item
                this.getAllPtItems();
            },
            updateBaklasQty(item,index){
                this.items[index] = item
            },
            changeDescription(item,index) {
                this.items[index] = item
                let isExists = this.selectedSpToUpdate.find(e => e.id == item.id);
                if (!!isExists) {
                    isExists.description = item.description;
                } else {
                    this.selectedSpToUpdate.push(item);
                }
            },

            updateDescription() {
                Swal.fire({
                    title: 'Are you sure you want to update description?',
                    icon: 'question',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    allowOutsideClick: false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor: 'grey',
                    confirmButtonText: 'Confirm',
                }).then(result=>{
                    if (result.isConfirmed) {
                        let payload = {
                            spareParts: this.selectedSpToUpdate,
                            url: 'update-description'
                        }
        
                        this.$store.dispatch('servicePost', payload).then (response => {
                            let res = response.data.message;
                            Swal.fire(res, "", res.toLowerCase());
                            this.getAllChildSpareParts()
                            this.selectedSpToUpdate = [];
                            this.disableUpdate = true;
                        }).catch( e => {
                            console.error(e)
                        })
                    }
                });
            },
            async exportDetails(option) {
                let itemIds;
                if (option == 'Page') {
                    itemIds = this.exclude_sp_items.map( e => e.id);
                }
                this.exportLoading = true;
                let exportHeadersAndData = {
                    Items: {
                        headers: [
                            {header:'Unit',key:'unit_model' ,width: 18},
                            {header:'Name',key:'unit_name', width: 45},
                            {header: 'SP Model', key:'sp_model' , width: 20},
                            {header:'SP Name',key:'sp_name', width: 45},
                            {header:'SP Part No.',key:'sp_part_num', width: 15},
                            {header:'SP Description',key:'sp_desc', width: 35},
                            {header:'Uom', key:'sp_uom', width: 10},
                            {header:'Baklas Qty', key:'sp_baklas_qty', width:10}
                        ],
                        data:[]
                    }
                }
                let payload = {
                    item_ids: itemIds,
                    option: option,
                    url: 'export-items-with-spare-parts'
                    }
                await this.$store.dispatch('serviceGet',payload).then(async response=> {
                    exportHeadersAndData.Items.data = response.data;
                    await this.exportExcel(exportHeadersAndData, 'Items', 'F69F1A');
                    this.exportLoading = false;
                });
            },

            uploadedData(data) {
                if(!data) {
                    this.uploadedFiles = null;
                }

                this.uploadedFiles = data;
            },

            clearFileUpload() {
                this.uploadedFiles = null;
                this.$refs.fileUpload.reset();
            },

            openUploadFilesDialog() {
                if (this.attachedFiles.length > 0) {
                    Swal.fire({
                    title: 'An attached file already exists. Do you want to replace it?',
                    text: '-Confirm to proceed',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                    }).then(result=> {
                        if(result.isConfirmed) {
                            this.uploadFilesDialog = true;
                        }
                    });
                } else {
                    this.uploadFilesDialog = true;
                }
            },

            closeUploadFilesDialog(){
                this.uploadFilesDialog = false;
                this.clearFileUpload();
            },

            submitExplodedViewFile() {
                const data = new FormData();
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }

                if(!!this.uploadedFiles && this.uploadedFiles.attachments.length > 0) {
                    let files = this.uploadedFiles.attachments;

                    for(let i=0;i < _.compact(files).length;i++) {
                        data.append('files[]', files[i]);
                    }
                }

                data.append('itemId', this.selected_id);

                Swal.fire({
                    title: 'Are you sure you want to Upload?',
                    text: 'Confirm to proceed',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then(result=> {
                    if(result.isConfirmed) {
                        let payload = {
                            params:data,
                            config:config,
                        }
                        this.$store.dispatch('uploadExplodedViews',payload).then(response=>{
                            if(response.data.error) {
                                Swal.fire('', response.data.error, 'error');
                            } else {
                                this.$store.dispatch('getItemWithSpFiles',this.selected_id);
                                this.closeUploadFilesDialog();
                                this.$refs.fileUpload.reset();
                                Swal.fire("", response.data.msg, "success");
                            }
                        });
                    }

                    this.confirmChangeWarrantyOnFlight = false;
                });
            },

            downloadItemsExplodedFile() {
                Swal.fire({
                    text: 'Are you sure you want to download?',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then((result) => {
                    if ( result.isConfirmed) {
                        let file = _.last(this.GET_ITEM_WITH_SP_FILES);
                        if (!!file) {
                            let uri = `${process.env.VUE_APP_API_HOST}/img/service/explodedviews?image_name=${file.name}`
                            fetch(uri)
                            .then(resp => resp.blob())
                            .then(blobobject => {
                                const blob = window.URL.createObjectURL(blobobject);
                                const anchor = document.createElement('a');
                                anchor.style.display = 'none';
                                anchor.href = blob;
                                anchor.download = file.name;
                                document.body.appendChild(anchor);
                                anchor.click();
                                window.URL.revokeObjectURL(blob);
                            })
                            .catch(() => console.log('An error in downloading the file sorry'));
                        } else {
                            Swal.fire("Info", "-No uploaded files found.", 'info')
                            return ;
                        }
                    }
                });
            },
            checkChanges() {
                this.disableUpdate = false
            },
            getPlaceHolder(model) {
                if(typeof(model) == 'object') {
                    return model.model;
                } else {
                    return model;
                }
            },
            selectItemModel(item) {
                let selectedItem = this.items.find( e => e.id == item.id)
                
                selectedItem.name = selectedItem.model.name;
                selectedItem.uom = selectedItem.model.uom;
                selectedItem.baklas_qty = selectedItem.model.baklas_qty;
                // selectedItem.sp_number = item.sp_number;
            }
        },
        mounted(){
         this.$store.dispatch('getItemTypes');
         this.$store.dispatch('getItemCategories');
         this.$store.dispatch('getItemTypeExcludeSp');
         this.getAllPtItems();
         this.$store.dispatch("checkAccessUser", this.$router.currentRoute.params.id);
         this.$store.dispatch("getUnassignedSpareParts");
        },
        watch:{
            'sp_selected':{
                handler(val){
                   if(val.length >= 1){
                     this.remove_sp = true
                   }else{
                    this.remove_sp = false
                   }
                }
            },
            'selected':{
                handler(val){

                }
            },
            'search_sp':{
                handler(val){
                    if(!!val){
                        this.reset_itemsperpage = false
                        this.exclude_sp_options.page = 1
                    }else{
                        this.reset_itemsperpage = true
                        this.getAllChildSpareParts();
                    }

                }
            },
            'search':{
                handler(val){
                    if(!!val){
                        this.exclude_sp_options.page = 1
                    }else{
                        this.getAllPtItems();
                    }

                }
            },
            exclude_sp_options: {
                    handler(val){
                    if(val.itemsPerPage < 0){
                        val.itemsPerPage = this.excludesp_totalItems;
                    }
                    this.getAllPtItems();
                },
                deep: true,
            },
            USER_ACCESS: {
            handler(val) {
                if(val != "fail"){
                        this.userAccess.edit = false;
                        this.userAccess.delete = false;
                        this.userAccess.view = false;
                        this.userAccess.assign = false;
                        this.userAccess.remove_sp = false;
                    val.forEach(e => {
                        if (e.actions_code == 'edit') {
                            this.userAccess.edit = true;
                        } else if (e.actions_code == 'delete') {
                            this.userAccess.delete = true;
                        } else if (e.actions_code == 'view') {
                            this.userAccess.view = true;
                        } else if (e.actions_code == 'assign') {
                            this.userAccess.assign = true;
                        } else if (e.actions_code == 'remove_sp') {
                            this.userAccess.remove_sp = true;
                        } else if (e.actions_code == 'edit_sp') {
                            this.userAccess.edit_sp = true;
                        }else if (e.view_sp == 'update_baklas_qty') {
                            this.userAccess.view_sp = true;
                        }else if (e.edit_sp == 'update_baklas_qty') {
                            this.userAccess.edit_sp = true;
                        }else if (e.delete_sp == 'update_baklas_qty') {
                            this.userAccess.delete_sp = true;
                        }
                    });
                }
                },
            },
            tab:{
                handler(val){
                    if(val == 0){
                        this.getAllPtItems();
                    }
                }
            },
            // 'items': {
            //     handler(newVal, oldVal) {
            //         if (!!newVal.description == !!oldVal.description) {
            //             this.disableUpdate = true
            //         } else {
            //             this.disableUpdate = false
            //         }
            //     },
            //     deep: true
            // }

    }


}

</script>

<style scoped>

  .explode_img{
    max-width: 500px;
    height: auto;
  }
.selecting_row >>> tbody tr :hover{
    cursor: pointer !important;
}
.btn-hover:hover{
    color: #f69f1a !important;
}
</style>
