<template>
    <v-container fluid>
        <h3 class="page-title"> Credit Bill</h3>
        <v-container class="ma-0">
            <v-app id="item-tabs">
                <v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" background-color="#424242" dark show-arrows>
                    <v-tabs-slider color="cyan"></v-tabs-slider>
                    <v-tab ripple v-for="(item, i) in items" :key="i" @click="tabs(i)">
                        {{ item.tab_name }}
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item v-for="(item, i) in items" :key="i">
                        <keep-alive>
                            <component :is="credit_bill.components" :credit_bill="credit_bill" :isActive_tab="item.tab_name"></component>
                        </keep-alive>
                    </v-tab-item>
                </v-tabs-items>
            </v-app>
        </v-container>
            <DialogComponent :cp="components" :scrollable="dialog_scrollable" :width="width(dialog_width)" :retainFocus="false"></DialogComponent>
    </v-container>
</template>
<script>
    import DialogComponent from '@/views/main/layouts/dialogs/Dialog.vue'
    import CreditBillComponent from '@/views/main/modules/Accounting/CreditBill/CreditBillComponent.vue'
    import CreditBillViewComponent from '@/views/main/layouts/dialogs/Accounting/CreditBillViewComponent.vue'
    import { mapGetters } from 'vuex'
    export default {
        components: {
            DialogComponent,
            CreditBillComponent,
            CreditBillViewComponent
        },
        data() {
            return {
                components: '',
                items: [
                    { tab_name: 'ALL', view: true },
                    { tab_name: 'PENDING', view: true },
                    { tab_name: 'FOR APPROVAL', view: true },
                    { tab_name: 'APPROVED', view: true },
                    { tab_name: 'PAID', view: true },
                ],
                credit_bill: {
                    components: CreditBillComponent,
                    dialog: CreditBillViewComponent,
                    dialog_title: 'Credit Bill',
                    dialog_width: '100%',
                    scrollable: true,
                    dispatch: {
                        url: 'credit-bill',
                        store: 'creditBillStore',
                        index: 'creditBillIndex',
                        update: 'creditBillUpdate',
                        destroy: 'creditBillDestroy',
                        approve_all: 'creditBillApproveAll',
                    }
                },
                dialog_width: '',
                tab: null,
                dialog_scrollable: true,
            }
        },
        computed: {
            ...mapGetters([
                'DIALOGS',
            ])
        },
        mounted() {
            this.dropDowns()
            this.tabs(0)
            this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        },
        methods: {
            dropDowns() {
                this.$store.dispatch('getCompanyClassificationDropdown')
                this.$store.dispatch('getAdminSupplierSelection')
                this.$store.dispatch('getPvCompanies')
                this.$store.dispatch('getPaymentVoucherBranchesSelection')
                this.$store.dispatch('getBankSelection', { type: 'AP' })
                this.$store.dispatch('getBankCards', { type: 'credit' })
                this.$store.dispatch('GetPreparedBy',{url: 'get-prepared-by', module: 'CreditBill'})
                this.$store.dispatch('getTransactTypesSelection',{include_model_name:1})
                this.$store.dispatch('getDepartmentListforAccounting')
                this.$store.dispatch('getAccountingJobSelection')
            },
            tabs(key) {
                this.components = this.credit_bill.dialog
                this.dialog_width = this.credit_bill.dialog_width
                this.dialog_scrollable = this.credit_bill.scrollable
                this.$store.commit('CREDIT_BILL_STATUS', this.items[key])
                this.$store.commit('CREDIT_BILL_DISPATCH', this.credit_bill.dispatch)
            },
            width(width) {
                switch(this.$vuetify.breakpoint.name) {
                    case 'xs': return "100%"
                    case 'sm': return "100%"
                    case 'md': return width
                    case 'lg': return width
                    case 'xl': return width
                }
            },
        },
        watch: {
            DIALOGS: {
                handler(val) {
                    if (!val) {
                        this.components = this. credit_bill.dialog
                    }
                },
                deep: true
            },
        }
    }
</script>
<style>
    #group-tab {
        padding: 0 !important
    }
</style>