	<template>
		<v-container fluid >
			<h3 class="page-title">Email Settings</h3>
			<v-container class="ma-0">
					<v-app id="item-tabs">
						<v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" background-color="#424242" dark show-arrows>
							<v-tabs-slider color="cyan"></v-tabs-slider>
								<v-tab ripple v-for="(item, i) in items" :key="i" @click="tabs(i)">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }" >
                                            <span v-on="on"
                                                v-bind="attrs">
                                                {{ item.code }}
                                            </span>
                                        </template>
                                        <span>{{item.text}}</span>
                                    </v-tooltip>
								</v-tab>
						</v-tabs>
						<v-tabs-items v-model="tab">
							<v-tab-item v-for="(table, index) in items" :key="index">
                                <EmailDepartmentGroupComponent :isActive_tab="table.value"/>
							</v-tab-item>
						</v-tabs-items>
					</v-app>
			</v-container>
		</v-container>
	</template>

<script>
	import DialogComponent from '@/views/main/layouts/dialogs/Dialog.vue';
    import EmailDepartmentGroupComponent from '@/views/main/SystemSettings/Email/EmailSettingsDeptGroupComponent.vue'
    import ManpowerRequestViewComponentVue from '@/views/main/layouts/dialogs/HR/RecruitmentSystem/ManpowerRequestViewComponent.vue';
	import ApplicantDataViewComponent from '@/views/main/layouts/dialogs/HR/RecruitmentSystem/ApplicantDataViewComponent.vue';


	import { mapGetters } from 'vuex';
	export default {
		components:{
			DialogComponent,
			ManpowerRequestViewComponentVue,
			ApplicantDataViewComponent,
            EmailDepartmentGroupComponent
		},
		data() {
			return {
				components:'',
				items: [
                    // {tab_name:"Personnel Request", access:'personnel_request'},
                    // {tab_name:"Applicant List",access:'applicant_list'},
                    // {tab_name:"(SH) Applicant",access:'applicant_list'},
                    // {tab_name:"(REQ) For Approval", access:'req_for_approval'},
                    // {tab_name:"Privacy Settings", access:'privacy_settings'},
                    // {tab_name:"Email Message", access:'email_message'}
                ],
				dialog_width: "",
				loaded: false,
				options: {},
				itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
				itemsPerPage: 10,
				tab: null,
				dialog_scrollable:true,
			};
		},
		computed:{
			...mapGetters(['USERACCOUNT_LOAD','USER_ACCESS','GET_LIST_DEPARTMENT'])
		},
		mounted(){
            this.$store.dispatch('checkAccessUser',this.$router.currentRoute.params.id);
			this.$store.dispatch('getDepartment')
		},
		methods:{
		tabs(key) {
            let payload = {
                department_id: this.items[key].value
            }
            this.$store.dispatch('getEmailSettings',payload)
		},
		getSearchFromUrl() {
            if (Object.keys(this.$route.query).length != 0) {
                Object.keys(this.$route.query).forEach(e=>{
					if(e == 'for_approval'){
						this.tab = this.items.length - 1
						this.tabs(this.tab)
					}
                })
            }
        },
		height(width) {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs': return "100%"
				case 'sm': return "100%"
				case 'md': return width
				case 'lg': return width
				case 'xl': return width
			}
		},
        tabAccess(item){
            if(this.USER_ACCESS != "fail"){
                if(this.USER_ACCESS.find(e=>e.actions_code == item.access)){
                    if(this.USER_ACCESS.find(e=>e.actions_code == item.access).actions_code == item.access)
                    {
                        return true
                    }
                }
            }
        }
	},
	watch:{
		USERACCOUNT_LOAD:{
			handler(val){
			}
		},
        GET_LIST_DEPARTMENT:{
            handler(val){
                // console.log(val)
                // console.log(this.USERACCOUNT_LOAD)
                if(this.USERACCOUNT_LOAD.president == 0 || this.USERACCOUNT_LOAD.super_admin == 0 ){
                    this.items = val;
                    this.items = val.filter((e)=> e.value == this.USERACCOUNT_LOAD.department_id )
                }
                else{
                    this.items = val;
                }
			    this.tabs(0)
            }
        }

	}

	};
	</script>
	<style>
	#group-tab {
		padding: 0 !important;
	}
	</style>

