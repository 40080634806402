<template>
    <v-dialog v-model="addEditDialog" persistent max-width="1200">
    <v-card>
        <v-card-title>
            <v-row>
                <v-col cols="10">
                    <span class="text-h5">{{ action.title_text }}</span>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="2" class="pull-right-10 p-2">
                    <v-icon class="float-right" @click="closeDialog();">mdi-close-circle</v-icon>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col>
                        <v-autocomplete
                            v-model="warehouse_id"
                            :items="GET_WAREHOUSE_LIST"
                            label="Select Warehouse"
                            class="mt-1"
                            outlined
                            dense
                            hide-details
                            auto-select-first
                            disabled
                        ></v-autocomplete>
                    </v-col>
                    <v-col>
                        <v-autocomplete
                            v-model="created_by_id"
                            :items="GET_CURRENT_USER"
                            label="Created By"
                            class="mt-1"
                            outlined
                            dense
                            hide-details
                            auto-select-first
                            disabled
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="remarks"
                            label="Remarks"
                            class="mt-1"
                            outlined
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
            </v-container>
            <v-card outlined class="pa-2">
                <div class="mt-2">
                    <v-btn
                        color="primary"
                        dark
                        small
                        @click="openBatchDialog();"
                    >
                        Batch Addition
                    </v-btn>
                    <v-btn
                        class="mx-1"
                        color="primary"
                        dark
                        small
                        plain
                        @click="addAdjustmentItem()">
                        <v-icon >mdi-plus</v-icon>
                    </v-btn>
                </div>

                <v-dialog v-model="batch_addition.dialog" persistent max-width="30%" max-height="20%" scrollable>
                    <v-card>
                        <v-row class="m-0">
                            <v-col cols="10">
                                <v-card-title>
                                    <span class="headline">Batch Addition</span>
                                </v-card-title>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col class="pull-right-10 p-2">
                                <v-btn
                                    class="float-right"
                                    color="gray"
                                    text
                                    icon
                                    @click="closeBatchAdditionDialog(); dataFromExcel = ''"
                                >
                                    <v-icon>mdi-close-circle</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-card-text v-if="!loading">
                            <span v-if="hasError">
                                <span>
                                    <table>
                                        <tr class="m-0 p-0">
                                            <td>
                                                <v-simple-checkbox dense v-model="showError" @click="showNotInModel()"></v-simple-checkbox>
                                            </td>
                                            <td>
                                                <div class="redBox"></div>
                                            </td>
                                            <td>
                                                <span> Model not found in the system</span>
                                            </td>
                                        </tr>
                                        <tr class="m-0 p-0">
                                            <td>
                                                <v-simple-checkbox dense v-model="showDuplicate" @click="showAllDuplicate()"></v-simple-checkbox>
                                            </td>
                                            <td>
                                                <div class="yellowBox"></div>
                                            </td>
                                            <td>
                                                <span> Duplicate model detected</span>
                                            </td>
                                        </tr>
                                        <tr class="m-0 p-0">
                                            <td>
                                                <v-simple-checkbox dense v-model="showDuplicateFromBatch" @click="showAllDuplicateFromBatch()">
                                                </v-simple-checkbox>
                                            </td>
                                            <td>
                                                <div class="orangeBox"></div>
                                            </td>
                                            <td>
                                                <span> Model already exist</span>
                                            </td>
                                        </tr>
                                    </table>
                                </span>
                            </span>
                            <div class="ma-0" style="max-height:400px; width:100%; margin-bottom:10px; overflow-y:auto; overflow-x:hidden">
                                <v-row class="mb-2 mt-1 mx-auto">
                                    <v-col cols="5">Model</v-col>
                                    <v-col cols="5">Quantity</v-col>
                                    <v-col cols="2" class="text-center">Action</v-col>
                                </v-row>
                                <v-row class="mx-0 pa-0 ma-0" v-if="isDone">
                                    <v-col cols="5" class="pa-0 ma-0">
                                        <v-text-field
                                            v-if="isDone"
                                            v-model="dataFromExcel"
                                            :autofocus="batch_addition.dialog"
                                            color="primary"
                                            rows="1"
                                            outlined
                                            dense
                                            small
                                            @paste="onPaste"
                                            @click="$event.target.focus()"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="5" class="py-0 my-0">
                                        <v-text-field
                                            v-if="isDone"
                                            v-model="dataFromExcel2"
                                            color="primary"
                                            rows="1"
                                            outlined
                                            dense
                                            small
                                            hide-details
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="2" class="ma-0 text-center">
                                        <v-btn
                                            v-if="isDone"
                                            style="height:20px; width:20px;"
                                            color="primary"
                                            fab
                                            dark
                                            small
                                            @click="clearSingleAddition()"
                                        >
                                            <v-icon dark small style="font-size:12px">mdi-minus</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <div v-if="showError">
                                    <v-row class="mx-0 pa-0 ma-0" v-for="(ShowNotInModel, i) in  ShowNotInModels" :key="i">
                                        <v-col cols="5" class="pa-0 ma-0">
                                            <v-text-field
                                                v-model="ShowNotInModels[i].e.dataFromExcel"
                                                :class="{ notInModel: ShowNotInModels[i].e.error, duplicateModel: ShowNotInModels[i].e.duplicate, duplicateBatchModel: ShowNotInModels[i].e.duplicateBatch }"
                                                :autofocus="batch_addition.dialog"
                                                class="InModel"
                                                color="primary"
                                                rows="1"
                                                outlined
                                                dense
                                                small
                                                hide-details
                                                @paste="onPaste"
                                                @change="getAllData()"
                                                @click="$event.target.focus()"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="5" class="py-0 ma-0">
                                            <v-text-field
                                                v-model="ShowNotInModels[i].e.dataFromExcel2"
                                                :class="{ notInModel: ShowNotInModels[i].e.error, duplicateModel: ShowNotInModels[i].e.duplicate, duplicateBatchModel: ShowNotInModels[i].e.duplicateBatch }"
                                                :autofocus="batch_addition.dialog"
                                                class="InModel"
                                                color="primary"
                                                rows="1"
                                                outlined
                                                dense
                                                small
                                                hide-details
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="2" class="ma-0">
                                            <v-btn
                                                style="height:20px; width:20px;"
                                                color="primary"
                                                fab
                                                dark
                                                small
                                                @click="deleteNotInModel(ShowNotInModels[i].e.dataFromExcel, i)"
                                            >
                                                <v-icon dark small style="font-size:12px">mdi-minus</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                                <div v-if="showDuplicate">
                                    <v-row class="mx-0 pa-0 ma-0" v-for="(ShowAllDuplicate, i) in  ShowAllDuplicates" :key="i">
                                        <v-col cols="5" class="pa-0 ma-0">
                                            <v-text-field
                                                v-model="ShowAllDuplicates[i].e.dataFromExcel"
                                                :class="{ notInModel: ShowAllDuplicates[i].e.error, duplicateModel: ShowAllDuplicates[i].e.duplicate, duplicateBatchModel: ShowAllDuplicates[i].e.duplicateBatch }"
                                                :autofocus="batch_addition.dialog"
                                                class="InModel"
                                                color="primary"
                                                rows="1"
                                                outlined
                                                dense
                                                small
                                                hide-details
                                                @paste="onPaste"
                                                @change="getAllData()"
                                                @click="$event.target.focus()"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="5" class="py-0 ma-0">
                                            <v-text-field
                                                v-model="ShowAllDuplicates[i].e.dataFromExcel2"
                                                :class="{ notInModel: ShowAllDuplicates[i].e.error, duplicateModel: ShowAllDuplicates[i].e.duplicate, duplicateBatchModel: ShowAllDuplicates[i].e.duplicateBatch }"
                                                :autofocus="batch_addition.dialog"
                                                class="InModel"
                                                color="primary"
                                                rows="1"
                                                outlined
                                                dense
                                                small
                                                hide-details
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="2" class="ma-0">
                                            <v-btn
                                                style="height:20px; width:20px;"
                                                color="primary"
                                                fab
                                                dark
                                                small
                                                @click="deleteDuplicatesInModel(ShowAllDuplicates[i].e.dataFromExcel, i)"
                                            >
                                                <v-icon dark small style="font-size:12px">mdi-minus</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                                <div v-if="showDuplicateFromBatch">
                                    <v-row class="mx-0 pa-0 ma-0" v-for="( ShowAllDuplicateFromBatch, i ) in  ShowAllDuplicateFromBatchs" :key="i">
                                        <v-col cols="5" class="pa-0 ma-0">
                                            <v-text-field
                                                v-model="ShowAllDuplicateFromBatchs[i].e.dataFromExcel"
                                                :class="{ notInModel: ShowAllDuplicateFromBatchs[i].e.error, duplicateModel: ShowAllDuplicateFromBatchs[i].e.duplicate, duplicateBatchModel: ShowAllDuplicateFromBatchs[i].e.duplicateBatch }"
                                                :autofocus="batch_addition.dialog"
                                                class="InModel"
                                                color="primary"
                                                rows="1"
                                                outlined
                                                dense
                                                small
                                                hide-details
                                                @paste="onPaste"
                                                @click="$event.target.focus()"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="5" class="py-0 ma-0">
                                            <v-text-field
                                                v-model="ShowAllDuplicateFromBatchs[i].e.dataFromExcel2"
                                                :class="{ notInModel: ShowAllDuplicateFromBatchs[i].e.error, duplicateModel: ShowAllDuplicateFromBatchs[i].e.duplicate, duplicateBatchModel: ShowAllDuplicateFromBatchs[i].e.duplicateBatch }"
                                                :autofocus="batch_addition.dialog"
                                                class="InModel"
                                                color="primary"
                                                rows="1"
                                                outlined
                                                dense
                                                small
                                                hide-details
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="2" class="ma-0">
                                            <v-btn
                                                style="height:20px; width:20px;"
                                                color="primary"
                                                fab
                                                dark
                                                small
                                                @click="deleteDuplicatesFromBatchInModel(ShowAllDuplicateFromBatchs[i].e.dataFromExcel, i)"
                                            >
                                                <v-icon dark small style="font-size:12px">mdi-minus</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                                <div v-if="!showError && !showDuplicate && !showDuplicateFromBatch">
                                    <v-row class="mx-0 pa-0 ma-0" v-for="( text_area_field, i ) in  text_area_fields" :key="i">
                                        <v-col cols="5" class="pa-0 ma-0">
                                            <v-text-field
                                                v-model="text_area_fields[i].dataFromExcel"
                                                :class="{ notInModel: text_area_fields[i].error, duplicateModel: text_area_fields[i].duplicate, duplicateBatchModel: text_area_fields[i].duplicateBatch }"
                                                :autofocus="batch_addition.dialog"
                                                class="InModel"
                                                color="primary"
                                                rows="1"
                                                outlined
                                                dense
                                                small
                                                hide-details
                                                @paste="onPaste"
                                                @change="getAllData()"
                                                @click="$event.target.focus()"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="5" class="py-0 ma-0">
                                            <v-text-field
                                                v-model="text_area_fields[i].dataFromExcel2"
                                                :class="{ notInModel: text_area_fields[i].error, duplicateModel: text_area_fields[i].duplicate, duplicateBatchModel: text_area_fields[i].duplicateBatch }"
                                                :autofocus="batch_addition.dialog"
                                                class="InModel"
                                                color="primary"
                                                rows="1"
                                                outlined
                                                dense
                                                small
                                                hide-details
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="2" class="ma-0">
                                            <v-btn
                                                style="height:20px; width:20px;"
                                                color="primary"
                                                fab
                                                dark
                                                small
                                                @click="clearItem(i)"
                                            >
                                                <v-icon dark small style="font-size:12px">mdi-minus</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                            <v-row class="mt-2">
                                <v-col cols="12">
                                    <v-btn :disabled="isDisabledButton" class="float-right ml-2" @click="submittedBtn()">Submit</v-btn>
                                    <v-btn class="float-right" dark color="secondary" @click="clearFields()">Clear</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-text v-else>
                            <div class="text-center">
                                <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-dialog>

                <v-simple-table dense class="dynamic_list_table" style="overflow-y: scroll">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-center mx-2" width="22%">Model</th>
                                <th class="text-center mx-2" width="40%">Name</th>
                                <th class="text-center mx-2" width="6%">Qty</th>
                                <th class="text-center mx-2" width="5%">Stocks</th>
                                <th class="text-center mx-2" width="3%"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <span v-for="(adjustmentItem, i) in  adjustmentItems" :key="i" class="d-contents">
                                <tr>
                                    <td>
                                        <v-autocomplete
                                            hide-details
                                            dense
                                            outlined
                                            :items="itemSelectionFiltered"
                                            placeholder="Select Model"
                                            item-value="text"
                                            item-text="text"
                                            class="small mt-2"
                                            item-disabled="isDisabled"
                                            v-model="adjustmentItems[i].item_model"
                                            @change="validateManualAddItem(i)"
                                        >
                                        </v-autocomplete>
                                    </td>
                                    <td>
                                        <v-text-field
                                            v-model="adjustmentItems[i].item_name"
                                            background-color="grey lighten-2"
                                            color="black"
                                            dense
                                            hide-details
                                            outlined
                                            readonly
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field
                                            v-model="adjustmentItems[i].qty"
                                            class="alignText"
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field
                                            v-model="adjustmentItems[i].remain_stocks"
                                            class="alignText"
                                            background-color="grey lighten-2"
                                            color="black"
                                            outlined
                                            dense
                                            hide-details
                                            readonly
                                        ></v-text-field>
                                    </td>
                                    <td class="text-center">
                                        <v-btn
                                            style="height:20px; width:20px;"
                                            color="primary"
                                            fab
                                            dark
                                            small
                                            @click="removeAdjustmentItemByIndex(i)"
                                        >
                                            <v-icon dark small style="font-size:12px">mdi-minus</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </span>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>
        </v-card-text>
        <div class="border-top">
            <div class="card-body py-4 d-flex justify-content-center">
                <v-btn v-if="action.type == 'add'" @click="addNewAdjustment()">
                    {{ action.button_text ?? 'Submit'}}
                </v-btn>

                <v-btn v-if="action.type == 'update'" @click="updateAdjustment()">
                    {{ action.button_text ?? 'Submit'}}
                </v-btn>

            </div>
        </div>
    </v-card>
</v-dialog>
</template>
<script>
import swal from 'sweetalert2'
import { mapGetters } from 'vuex'
import buttons from '../../Buttons/DialogButton.vue'
import buttons_components from '../../../../../assets/js/components-js.js'

export default {
components: {
    buttons,
    buttons_components
},
props: [
    'selectedAction'
],
data() {
    return {
        buttons: buttons_components.buttons,
        loaded: false,
        users: [],
        itemsPerPageOptions: [5, 10, 15],
        itemsPerPage: 15,
        search: '',
        headers: [
            { text: 'Ref#', align: 'start', value: 'reference_num' },
            { text: 'Warehouse', align: 'start', value: 'warehouse_name' },
            { text: 'Remarks', align: 'start', value: 'remarks' },
            { text: 'Created By', align: 'start', value: 'created_by_name' },
            { text: 'Status', align: 'center', value: 'status_text' },
            { text: 'Processed By', align: 'start', value: 'processed_by_name' },
            { text: 'Process Date', align: 'start', value: 'processed_date' },
            { text: 'Actions', align: 'center', value: 'actions', sortable: false }
        ],
        viewUserId: '',
        userAccess: {
            view: true,
            create: true,
            edit: true,
            delete: true
        },
        dialog: false,
        access: {
            department_code: 'MD',
            module: 'users',
            action: 'create'
        },
        loaded: false,
        warehouse_list: [],
        user_list: [],
        created_by_id: '',
        created_by_name: '',
        warehouse_id: '',
        remarks: '',
        adjustmentItems: [],
        adjustmentItem: {
            item_id: '',
            item_model: '',
            item_name: '',
            qty: ''
        },
        max_adjustment_items_count: 999,
        viewAdjustmentId: '',
        action: {},
        batch_addition: {
            dialog: false,
            data: [],
            excel_item_id: null,
            errors: []
        },
        isDone: true,
        hasError: false,
        text_area_fields: [],
        text_area_field: {
            dataFromExcel: '',
            dataFromExcel2: ''
        },
        dataFromExcel: '',
        dataFromExcel2: '',
        isViewEdit: false,
        isDisabledButton: false,
        listOfNotInModels: [],
        listOfDuplicates: [],
        counterBatch: 1,
        max_items_count: 999,
        batchCounter: 0,
        lastBatchModel: [],
        duplicateFromBatch: [],
        tempAdjustmentItems: [],
        showError: false,
        showDuplicate: false,
        showDuplicateFromBatch: false,
        ShowNotInModel: {
            dataFromExcel: '',
            dataFromExcel2: ''
        },
        ShowNotInModels: [],
        ShowAllDuplicate: {
            dataFromExcel: '',
            dataFromExcel2: ''
        },
        ShowAllDuplicates: [],
        ShowAllDuplicateFromBatch: {
            dataFromExcel: '',
            dataFromExcel2: ''
        },
        ShowAllDuplicateFromBatchs: [],
        retrieved: false,
        showLoader: false,
        isSingleAdded: false,
        addEditDialog: false,
        loading: false,
        updateBaklasUnit: null,
        itemSelectionFiltered: null,

    }
},
mounted() {
    if (this.adjustmentItems.length == 0) {
        this.addAdjustmentItem()
    }
    this.$store.dispatch("getCurrentUser", {userId: this.USERACCOUNT_LOAD.id})
    this.$store.dispatch('getAllWarehouses');
    this.setDefaultWarehouse()
    this.setDefaultUser()
    this.$store.dispatch('itemselection');
},
computed: {
    ...mapGetters([
        'GET_ALL_STOCK',
        'GET_STOCK_ADJUSMENT',
        'DIALOGS',
        'ACTION',
        'GET_WAREHOUSE_LIST',
        'GET_ALL_USER',
        'GET_CURRENT_USER',
        'USERACCOUNT_LOAD',
        'GET_ADD_UNIT',
        'GET_ITEM_SELECTION'
    ]),
},
methods: {
    setDefaultWarehouse(){
        this.GET_WAREHOUSE_LIST.map(e =>{
            if(e.code == 'DM-B'){
                return this.warehouse_id = e.value
            }
        })
    },
    setDefaultUser(){
        this.GET_CURRENT_USER.map(e =>{
            this.created_by_id = e.value
            this.created_by_name = e.text
        })
    },
    closeDialog() {
        this.$store.commit("ADD_UNIT_DIALOG", {
            action:{
                type: '',
                button_text: '',
                title_text: ''
            },
            dialogs:{
                add_unit_dialog: false,
            },
            data: {},
            reload_table: false
        });
        this.clearFields()
        this.remarks         = ''
        this.counterBatch = 0
        this.adjustmentItems = [{
            item_id: '',
            item_model: '',
            item_name: '',
            qty: '',
        }]
        this.itemSelectionFiltered.forEach(e =>{
            e.isDisabled = false
        })
    },
    addAdjustmentItem() {
        this.adjustmentItems.forEach((e)=>{

            this.itemSelectionFiltered.filter(val=>{
                if(val.text == e.item_model){
                    val.isDisabled = true;
                }
            })
        })
        if (this.adjustmentItems.length < this.max_adjustment_items_count) {
            this.adjustmentItems.push({
                item_id: '',
                item_model: '',
                item_name: '',
                qty: '',
            })
        }
    },
    openBatchDialog() {
        if (this.warehouse_id == '') {
            swal.fire(
                '',
                'Please input required fields',
                'warning'
            )
            return false;
        }
        else {
            if (this.adjustmentItems.length == 1) {
                this.lastBatchModel.length = 0;
                this.lastBatchModel = [];
            }
            this.batch_addition.dialog = true;
            this.isDone = true;
            this.text_area_fields.length = 0;
            this.batch_addition.data = [];
            this.listOfNotInModels = [];
            this.listOfNotInModels.length = 0;
            this.hasError = false;
            this.listOfDuplicates = [];
            this.listOfDuplicates.length = 0;
            this.duplicateFromBatch = [];
            this.duplicateFromBatch.length = 0;
            this.isDisabledButton = false;
            this.showError = false;
            this.showDuplicate = false;
            this.showDuplicateFromBatch = false;
            this.ShowNotInModels = [];
            this.ShowNotInModels.length = 0;
            this.ShowAllDuplicates = [];
            this.ShowAllDuplicates.length = 0;
            this.ShowAllDuplicateFromBatchs = [];
            this.ShowAllDuplicateFromBatchs.length = 0;
            this.showLoader = false;
            this.dataFromExcel = '';
            this.dataFromExcel2 = '';
        }
    },
    onPaste(evt) {
        this.loading = true
        let that = this;
        this.batch_addition.data = []
        var pastedText = evt.clipboardData.getData('Text');

        if (!pastedText) {
            return false;
        }
        else {
            var rows = pastedText.split("\r\n")
            var cells = '';
            if (this.text_area_fields.length > 1) {
                this.text_area_fields.pop();
            }
            for (var y in rows) {
                if (y != rows.length - 1) {
                    cells = rows[y].split("\t");
                    this.text_area_fields.push({
                        dataFromExcel: cells[0],
                        dataFromExcel2: cells[1],
                        error: false,
                        duplicate: false,
                        duplicateBatch: false
                    })
                    if (y == rows.length - 1) {
                        this.text_area_fields.push({
                            dataFromExcel: cells[0],
                            dataFromExcel2: cells[1],
                            error: false,
                            duplicate: false,
                            duplicateBatch: false

                        })
                    }
                    this.batch_addition.data.push(Object.assign({}, {
                        model: cells[0],
                        qty: cells[1],
                        // foc: cells[2],
                        id: '',
                        skip: false,
                    }))
                }
            }
            that.getAllData();
        }
    },
    myFunction() {
        this.batch_addition.dialog = true;
    },
    closeBatchAdditionDialog() {
        this.batch_addition.dialog = false;
    },
    getAllData() {
        this.showError = false;
        this.showDuplicate = false;
        this.showDuplicateFromBatch = false;
        this.showLoader = true;
        let that = this;
        let checkModel = [];
        this.listOfNotInModels = [];
        this.listOfNotInModels.length = 0;
        this.listOfDuplicates = [];
        this.listOfDuplicates.length = 0;
        this.duplicateFromBatch = [];
        this.duplicateFromBatch.length = 0;

        let payload = {
            items: this.text_area_fields.map(e => { return e.dataFromExcel.toUpperCase() }),
            isChecking: true,
            forSA: 'forSA',
            warehouse_id: this.warehouse_id
        }
        this.$store.dispatch('validateModel', payload).then(response => {

            //True Resposne
            if(response.data == 'Invalid Item!'){
                swal.fire('Invalid Item','Spareparts Item is not applicable for New Unit. Please check your item.','error')
                this.batch_addition.dialog = false;
                this.loading = false
                this.clearFields();
                return false
            }else if(response.data == 'Item not found'){
                swal.fire('Invalid Item','Item not Found. Please check your item.','error')
                this.batch_addition.dialog = false;
                this.loading = false
                this.clearFields();
                return false
            }

            this.tempAdjustmentItems = response.data;

            response.data = response.data.map(e => { return e.model })
            let lowerCaseResponseModel = [];

            response.data.forEach(e => {
                lowerCaseResponseModel.push(e.toLowerCase());
            })
            let models = this.text_area_fields.map(e => { return e.dataFromExcel });

            if (this.batchCounter > 0) {
                checkModel = this.text_area_fields.map(e => {
                    return e.dataFromExcel;
                })
                checkModel.forEach((e, i) => {
                    if (that.lastBatchModel.includes(e)) {
                        that.text_area_fields[i].duplicateBatch = true;
                        that.duplicateFromBatch.push(e);
                    }
                    else {
                        that.text_area_fields[i].duplicateBatch = false;
                    }
                })
            }
            let showItemsAsString = that.duplicateFromBatch.join("\r\n");

            if (that.duplicateFromBatch.length > 0) {
                swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'These models already exist in your adjustment  \n \n' + showItemsAsString.split(',').join("\r\n"),
                })
            }
            let filteredModel = models.filter(function (e, i) {
                if (models.indexOf(e) != i) {
                    that.text_area_fields[i].duplicate = true;
                    that.listOfDuplicates.push(e);
                } else {
                    that.text_area_fields[i].duplicate = false;
                }
            });

            this.text_area_fields.forEach((e, i) => {
                if (!response.data.includes(e.dataFromExcel) && !lowerCaseResponseModel.includes(e.dataFromExcel.toLowerCase())) {
                    this.listOfNotInModels.push(e.dataFromExcel);
                    this.text_area_fields[i].error = true;
                }
                else {
                    this.text_area_fields[i].error = false;
                }
            })
            if (this.listOfNotInModels.length > 0 || this.listOfDuplicates.length > 0 || this.duplicateFromBatch.length > 0) {
                this.isDisabledButton = true
                this.hasError = true
            } else {
                this.isDisabledButton = false
                this.hasError = false
            }
            this.showLoader = false;
            this.loading = false
        })
        this.isDone = false;
        this.closeBatchAdditionDialog();
        setTimeout(this.myFunction, 1);
    },
    getSingleData() {
        let that = this;
        this.batchCounter++;
        this.closeBatchAdditionDialog();
        this.batch_addition.dialog = false;
        let models = this.tempAdjustmentItems.map(e => {
            return e.model.toUpperCase();
        });

        if (this.adjustmentItems.length < this.max_items_count) {

            if (this.counterBatch == 0 && this.isSingleAdded == false) {
                this.adjustmentItems.pop();
                this.counterBatch++;
                this.isSingleAdded = true;
            }
            for (var i = 0; i < 1; i++) {
                this.adjustmentItems.push({
                    item_model: this.dataFromExcel.toUpperCase(),
                    item_id: this.tempAdjustmentItems[i].id,
                    item_name: this.tempAdjustmentItems[i].name,
                    remain_stocks: this.tempAdjustmentItems[i].warehouse_stock_quantity,
                    qty: this.dataFromExcel2,
                })
            }
            models.forEach(e => {
                that.lastBatchModel.push(e)
            });
        }
    },
    submittedBtn() {
        let duplicateItems = [];
        let filteredItems = [];
        if (this.isDone == true) {
            let singleDataArray = [];
            this.showError = false;
            this.showDuplicate = false;
            this.showDuplicateFromBatch = false;
            this.showLoader = true;
            let that = this;
            let checkModel = [];
            this.listOfNotInModels = [];
            this.listOfNotInModels.length = 0;
            this.listOfDuplicates = [];
            this.listOfDuplicates.length = 0;
            this.duplicateFromBatch = [];
            this.duplicateFromBatch.length = 0;
            singleDataArray.push(this.dataFromExcel);
            let payload = {
                items: singleDataArray.map(e => { return e.toUpperCase() }),
                isChecking: true,
                forSA: 'forSA',
                warehouse_id: this.warehouse_id
            }
            this.$store.dispatch('getItemsByModel', payload).then(response => {
                let listOfModels = this.adjustmentItems.map(e => { return e.item_model });
                let checkSingleInputDuplicate = listOfModels.includes(this.dataFromExcel.toUpperCase());
                if (response.data.length == 0) {
                    swal.fire({
                        icon: 'error',
                        title: 'Item not Found',
                    })
                }
                else {
                    if (checkSingleInputDuplicate) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Oops...',
                            text: this.dataFromExcel + ' already exist in your adjustment',
                        })
                    }
                    else {
                        this.tempAdjustmentItems = response.data;
                        this.getSingleData();
                    }
                }
            })
        }
        else {
            let that = this;
            let models = this.tempAdjustmentItems.map(e => {
                return e.model.toUpperCase();
            });
            if (this.adjustmentItems.length < this.max_items_count) {
                if (this.counterBatch == 0) {
                    this.adjustmentItems.pop();
                }
                let tempArr = []
                for (let i = 0; i < this.tempAdjustmentItems.length; i++) {
                    tempArr.push({
                        item_model: this.text_area_fields[i].dataFromExcel.toUpperCase(),
                        item_id: this.tempAdjustmentItems[i].id,
                        item_name: this.tempAdjustmentItems[i].name,
                        remain_stocks: this.tempAdjustmentItems[i].warehouse_stock_quantity,
                        qty: this.text_area_fields[i].dataFromExcel2,
                    })
                }
                tempArr.forEach(e => {
                    this.adjustmentItems.forEach(x => {
                        if(e.item_model == x.item_model){
                            duplicateItems.push(e)
                        }
                    })
                })

                let duplicate_models = duplicateItems.map(e => {
                    return e.item_model
                })
                let showItemsAsString = duplicate_models.join("<br>");
                if (duplicateItems.length > 0) {
                    swal.fire({
                        title: 'Warning',
                        html: `<h4>Duplicate model detected NOTE:<br>
                            Model with duplicates will be automatically removed<br>
                            ${showItemsAsString.split(',').join("\n")}</h4>`,
                        showConfirmButton: true
                    }).then(result => {
                        if (result.isConfirmed) {
                        } else {
                            return false
                        }
                    })
                }

                // Filtering the arrays
                const filteredArray1 = tempArr.filter(obj1 => !this.adjustmentItems.some(obj2 =>  obj1.item_model === obj2.item_model));

                this.adjustmentItems = filteredArray1.concat(this.adjustmentItems);
                models.forEach(e => {
                    that.lastBatchModel.push(e)
                });
                this.counterBatch++;
                this.batchCounter++; // for Checking duplicates from last Batch
                this.batch_addition.dialog = false;

            }
        }
    },
    clearItem(i) {
        let temp = [];
        let tempArr = [];
        if (this.text_area_fields.length == 1) {
            this.clearFields();
        }
        else {
            if (this.listOfNotInModels.length > 1 || this.listOfDuplicates.length > 1 || this.duplicateFromBatch.length > 1) {

            }
            else {
                this.hasError = false;
                this.isDisabledButton = false;
            }
            this.text_area_fields.forEach((e, index) => {
                if (i != index) {
                    temp.push(e);

                    let checkingOfError = this.listOfNotInModels.includes(this.text_area_fields[i].dataFromExcel)
                    let checkingOfDuplicate = this.listOfDuplicates.includes(this.text_area_fields[i].dataFromExcel)
                    let checkingOfBatchDuplicate = this.duplicateFromBatch.includes(this.text_area_fields[i].dataFromExcel)

                    if (checkingOfError && !checkingOfBatchDuplicate && !checkingOfDuplicate) {
                        this.listOfNotInModels.pop();
                    }
                    if (checkingOfDuplicate && !checkingOfError && !checkingOfBatchDuplicate) {
                        this.listOfDuplicates.pop();
                    }
                    if (checkingOfBatchDuplicate && !checkingOfError && !checkingOfDuplicate) {
                        this.duplicateFromBatch.pop();
                    }
                }
            })
            this.text_area_fields = temp;
        }
    },
    clearFields() {
        this.isDone = true;
        this.text_area_fields.length = 0;
        this.batch_addition.data = [];
        this.listOfNotInModels = [];
        this.listOfNotInModels.length = 0;
        this.listOfDuplicates = [];
        this.listOfDuplicates.length = 0;
        this.duplicateBatch = [];
        this.duplicateBatch.length = 0;
        this.text_area_fields = [];
        this.hasError = false;
        this.duplicateFromBatch = [];
        this.duplicateFromBatch.length = 0;
        this.isDisabledButton = false;
        this.showError = false;
        this.showDuplicate = false;
        this.showDuplicateFromBatch = false;
        this.ShowNotInModels = [];
        this.ShowNotInModels.length = 0;
        this.ShowAllDuplicates = [];
        this.ShowAllDuplicates.length = 0;
        this.ShowAllDuplicateFromBatchs = [];
        this.ShowAllDuplicateFromBatchs.length = 0;
        this.showLoader = false;
        this.dataFromExcel = '';
        this.dataFromExcel2 = '';
    },
    addNewAdjustment() {
        if (!this.adjustmentItems[0].item_id == '' && !this.adjustmentItems[0].qty == '') {
            swal.fire({
                title: 'Are you sure you want to Add New Unit?',
                text: 'Make sure that all information are correct',
                icon: 'question',
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                allowOutsideClick: false,
                confirmButtonColor: '#397373',
                cancelButtonColor: 'grey',
                confirmButtonText: 'Confirm',

            }).then((result) => {
                if (result.isConfirmed) {
                    let payload = {
                        warehouse_id: this.warehouse_id,
                        remarks: this.remarks,
                        add_new_unit_items: this.adjustmentItems,
                        created_by_id: this.created_by_id,
                        created_by_name: this.created_by_name
                    }
                    this.$store.dispatch('BaklasAddNewUnit', payload)
                        .then(response => {
                            let data = response.data
                            if (data.error) {
                                swal.fire(
                                    '',
                                    data.error,
                                    'question'
                                )
                                return false
                            }
                            this.clearFields()
                            this.$store.commit("ADD_UNIT_DIALOG", {
                                action:{
                                    type: '',
                                    button_text: '',
                                    title_text: ''
                                },
                                dialogs:{
                                    add_unit_dialog: false,
                                },
                                data: {},
                                reload_table: true
                            });
                            this.adjustmentItems = [{
                                item_id: '',
                                item_name: '',
                                qty: '',
                            }]
                            this.itemSelectionFiltered.forEach(e =>{
                                e.isDisabled = false
                            })
                            this.counterBatch = 0
                            this.action = 'success'
                            swal.fire({
                                icon: 'success',
                                title: '',
                                text: response.data.msg,
                            })
                        });
                }
            });
        } else {
            swal.fire(
                '',
                'Please Fill Required Fields',
                'warning'
            )

        }
    },
    showNotInModel() {
        let that = this;
        if (this.ShowNotInModels.length == 0) {
            if (this.showError == true) {
                this.text_area_fields.filter(function (e, i) {
                    if (e.error == true) {
                        that.ShowNotInModels.push({ e, i });
                    }
                });
            }
        }
    },
    showAllDuplicate() {
        let that = this;
        if (this.ShowAllDuplicates.length == 0) {
            if (this.showDuplicate == true) {
                this.text_area_fields.filter(function (e, i) {
                    if (e.duplicate == true) {
                        that.ShowAllDuplicates.push({ e, i });
                    }
                });
            }
        }
    },
    showAllDuplicateFromBatch() {
        let that = this;
        if (this.ShowAllDuplicateFromBatchs.length == 0) {
            if (this.showDuplicateFromBatch == true) {
                this.text_area_fields.filter(function (e, i) {
                    if (e.duplicateBatch == true) {
                        that.ShowAllDuplicateFromBatchs.push({ e, i });
                    }
                });
            }
        }
    },
    deleteNotInModel(deleteModel, i) {
        let that = this;
        let tempArr = [];
        if (this.listOfNotInModels.length > 1 || this.listOfDuplicates.length > 1 || this.duplicateFromBatch.length > 1) {

        }
        else {
            this.isDisabledButton = false;
        }
        let temp = that.text_area_fields.filter(e => {
            if (e.dataFromExcel == deleteModel) {

            }
            else {
                this.listOfNotInModels.pop();
                return e;
            }
        })
        this.ShowNotInModels.forEach((e, index) => {
            if (i != index) {
                tempArr.push(e);
            }
        })
        this.ShowNotInModels = tempArr;
        that.text_area_fields = temp;
    },
    deleteDuplicatesInModel(deleteModel, i) {
        let that = this;
        let tempArr = [];
        if (this.listOfNotInModels.length > 1 || this.listOfDuplicates.length > 1 || this.duplicateFromBatch.length > 1) {

        }
        else {
            this.isDisabledButton = false;
        }
        let temp = that.text_area_fields.filter(e => {
            if (e.dataFromExcel == deleteModel) {

            }
            else {
                this.listOfDuplicates.pop();
                return e;
            }
        })
        this.ShowAllDuplicates.forEach((e, index) => {
            if (i != index) {
                tempArr.push(e);
            }
        })

        this.ShowAllDuplicates = tempArr;
        that.text_area_fields = temp;
    },
    deleteDuplicatesFromBatchInModel(deleteModel, i) {
        let that = this;
        let tempArr = [];
        if (this.listOfNotInModels.length > 1 || this.listOfDuplicates.length > 1 || this.duplicateFromBatch.length > 1) {

        }
        else {

            this.isDisabledButton = false;
        }
        let temp = that.text_area_fields.filter(e => {
            if (e.dataFromExcel == deleteModel) {

            }
            else {
                this.duplicateFromBatch.pop();
                return e;
            }
        })
        this.ShowAllDuplicateFromBatchs.forEach((e, index) => {
            if (i != index) {
                tempArr.push(e);
            }
        })
        this.ShowAllDuplicateFromBatchs = tempArr;
        that.text_area_fields = temp;
    },
    clearSingleAddition() {
        this.dataFromExcel = '';
        this.dataFromExcel2 = '';
    },
    viewAdjustment(id) {
        this.viewAdjustmentId = id
    },
    closeView(action = false) {
        this.viewAdjustmentId = 0
        if (action == 'updateStatusSuccess') {
            this.$store.dispatch('getAllStockAdjustment');

        }
    },
    closeAddEditDialog() {
        this.clearFields();
        this.$store.commit('DIALOG', false);
    },
    removeAdjustmentItemByIndex(index) {
        let temp = [];
        if (this.adjustmentItems.length > 1) {
            this.adjustmentItems.splice(index, 1);
            temp = this.adjustmentItems.map(e => { return e.item_model })
            this.lastBatchModel = temp;
            this.itemSelectionFiltered.forEach(e =>{
                if(e.text != this.lastBatchModel){
                    e.isDisabled = false
                }
            })

        }
        else if (this.adjustmentItems.length == 1) {
            this.adjustmentItems.splice(index, 1);
            this.adjustmentItems.push({
                item_id: '',
                item_name: '',
                qty: '',
            })
            this.isSingleAdded = false;
            this.counterBatch = 0;
        }
    },
    async assignUpdateItems(id){
        let payload = {
            id: id,
        }
        await this.$store.dispatch('getAllBaklasAddNewUnit', payload).then(response =>  {
            let data = response.data.data.data[0]
            this.warehouse_id = data.warehouse_id
            this.remarks = data.remarks
            this.updateBaklasUnit = data.id
            this.adjustmentItems = data.items
            this.adjustmentItems.forEach((item, key) => {
                this.adjustmentItems[key].qty = item.quantity
                this.adjustmentItems[key].item_model = item.model
                this.adjustmentItems[key].item_name = item.name
            })
        })
        this.counterBatch = 1;
    },
    updateAdjustment() {
        if (!this.adjustmentItems[0].item_id == '') {
            swal.fire({
                icon: 'question',
                title: 'Are you sure you want to Update Stock Adjustment?',
                text: 'Make sure that all information are correct',
                showConfirmButton: true,
                confirmButtonText: 'Confirm',
                reverseButtons: true,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: '#397373',
                cancelButtonColor: 'grey',
            })
            .then((result) => {
                if (result.isConfirmed) {
                    let payload = {
                        id:  this.updateBaklasUnit,
                        warehouse_id: this.warehouse_id,
                        remarks: this.remarks,
                        adjustmentItems: this.adjustmentItems,
                    }
                    this.$store.dispatch('updateBaklasNewUnit', payload).then(response => {
                        let data = response.data
                        if (data.error) {
                            swal.fire(
                                '',
                                data.error,
                                'warning'
                            )
                            return false
                        }
                        this.clearFields()
                        this.$store.commit("ADD_UNIT_DIALOG", {
                            action:{
                                type: '',
                                button_text: '',
                                title_text: ''
                            },
                            dialogs:{
                                add_unit_dialog: false,
                            },
                            data: {},
                            reload_table: true
                        });
                        this.action = 'success'
                        this.counterBatch = 0
                        swal.fire(
                            '',
                            response.data.msg,
                            'success'
                        )
                    });
                }
            });
        } else {
            swal.fire('', 'Please Fill Required Fields', 'warning');
        }
    },
    validateManualAddItem(i){
        let payload = {
            model: this.adjustmentItems[i].item_model,
            manualAdd: true,
            warehouse_id: this.warehouse_id
        }
        this.$store.dispatch('validateModel', payload).then(response => {
            let data = response.data[0]
            this.adjustmentItems[i].item_name = data.name
            this.adjustmentItems[i].remain_stocks = data.warehouse_stock_quantity
            this.adjustmentItems[i].item_id = data.id
        })

    }

},
watch: {
    GET_ADD_UNIT:{
        handler(val){
            this.action = val.action
            this.addEditDialog = val.dialogs.add_unit_dialog
            this.setDefaultUser()
            if(val.action.type == 'update'){
                this.assignUpdateItems(val.data.id)
            }else{
                this.setDefaultWarehouse()
            }
        }
    },
    DIALOGS(val) {
        if (this.ACTION == 'Submit') {
            this.adjustmentItems = [];
            this.warehouse_id = ''
            this.remarks = ''
            if (this.adjustmentItems.length == 0) {
                this.addAdjustmentItem();
            }
        }
    },
    GET_ITEM_SELECTION:{
        handler(val){
           this.itemSelectionFiltered = val.filter((item) => (item.type_id != 3 && !Number.isNaN(item)));
        }
    },
}
}
</script>
<style>
