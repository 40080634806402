<template>
    <v-form ref="form" v-model="valid" lazy-validation>
        <v-card class="d-flex justify-content-center flex-column">
            <v-card-title outline class="d-flex justify-content-between align-items-center">
                <span class="pt-2">{{ ACTION == "Submit" ? 'CREATE UOM' : ACTION + ' UOM'}}</span>
                <v-btn text icon small color="gray" class="float-right" @click="() => {
                closeDialog()
                }">
                    <v-icon>mdi-close-circle</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider/>
            <v-card-text>
                <v-row class="p-2">
                    <v-col cols="11" class="ml-4">
                        <span>NAME: </span>
                        <v-text-field
                        v-model="form.name"
                        :disabled="ACTION == 'View'"
                        outlined
                        dense
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row class="p-2">
                    <v-col cols="11" class="ml-4">
                        <span>CODE: </span>
                        <v-text-field
                        v-model="form.code"
                        :disabled="ACTION == 'View'"
                        outlined
                        dense
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row class="p-2">
                    <v-col cols="11" class="ml-4">
                        <span>DESCRIPTION: </span>
                        <v-text-field
                        v-model="form.description"
                        :disabled="ACTION == 'View'"
                        outlined
                        dense
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="d-flex justify-content-center p-3">
                <v-btn @click="createOrUpdateAdminItemUom(form.id)" v-if="ACTION !== 'View'">
                    <span >{{ACTION}}</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-form>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent'
export default {
    mixins: [SharedFunctionsComponent],
    data() {
        return {
            valid:true,
            form: {
               code: '',
               name: '',
               description: ''
            },

        };
    },
    // components: {
    //     CameraLayout
    // },
    computed: {
        ...mapGetters([
            "ACTION",
            "DIALOGS",
            "GET_NEW_ADMIN_ITEM",
            "GET_ADMIN_ITEM_DISPATCH",
            "GET_EDIT_ADMIN_ITEM_UOM"
        ]),
        actions() {
            if (this.ACTION == "Submit") {
                this.disabled_view = false;
                // this.dialog_title = 'CREATE ITEM UOM'
                this.action = this.GET_ADMIN_ITEM_DISPATCH.add
                return "Submit";
            } else if (this.ACTION == "Update") {
                this.disabled_view = false;
                this.action = this.GET_ADMIN_ITEM_DISPATCH.update;
                // this.dialog_title = 'UPDATE ITEM UOM'
                this.getEditAdminItems()
                return "Update";
            } else if (this.ACTION == "View") {
                // this.dialog_title = 'VIEW ITEM UOM'
                this.getEditAdminItems()
                this.disabled_view = true;
                return "View";
            }
        },
    },
    mounted() {},
    methods: {
        async createOrUpdateAdminItemUom(id){
            Swal.fire({
            icon: "warning",
            text: `Are you sure you want to ${this.ACTION}?`,
            cancelButtonText: "Cancel",
            reverseButtons: true,
            showCancelButton: true,
            allowOutsideClick: false,
            cancelButtonColor: "#grey",
            })
            .then((result) => {
                if (result.isConfirmed) {
                    let checker = [
                            [!this.form.name,'- Please Enter Name'],
                            [!this.form.code,'- Please Enter Code'],
                            [!this.form.description,'- Please Enter Description'],
                        ]

                    if(checker.find(e=>e[0] == true)){
                            Swal.fire('Error!',`${checker.find(e=>e[0] == true)[1]}`,'warning')
                            return
                    }

                    this.$store.dispatch("adminItemPost", {
                        id: id,
                    ...this.form,
                    url: 'update-or-create-admin-item-uom',
                    }).then(response=>{
                        Swal.fire('Success', '', 'success');
                        this.closeDialog();
                    });
                }
            });
        },
        closeDialog() {
            this.resetFields();
            this.$store.commit("DIALOG", false);
            this.$store.commit("EDIT_ADMIN_ITEM_UOM", {});
            this.$store.commit('NEW_ADMIN_ITEM', true);

        },
        resetFields(){
            this.form = {
               code: '',
               name: '',
               description: ''
            }
        },

    },
    watch: {
        GET_NEW_ADMIN_ITEM:{
            handler(val){
                if(val && this.ACTION != ''){
                    this.resetFields();
                    this.$store.commit("DIALOG", false);
                    this.$store.commit('NEW_ADMIN_ITEM',false);
                }
            }
        },
        DIALOGS: {
            handler(val) {},
        },
        GET_EDIT_ADMIN_ITEM_UOM:{
            async handler(val){
                this.loading = true
                this.form = val;

                // this.$forceUpdate();
            },
            deep:true,
            // immediate:true
        }
    },
};
</script>
<style scope>
</style>
