<template>
    <div>
        <div v-if="parentComponent != 'technicianCard'">
            <div class="row mt-1">
                <div class="col-12 d-flex no-block align-items-center">
                    <div class="btn-toolbar">
                        <v-btn data-modal-id="drFormModal" v-on:click="showAddTechDialog()">
                            <i class="fas fa-plus"></i>Add
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>

        <v-dialog v-if="parentComponent == 'technicianCard'" v-model="technicianAddEditDialog" persistent max-width="30%">
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">{{ `${ !!technicianId ? 'Edit' : 'Add'} Technician` }}</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeAddTechDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider class="mt-0" />

                <v-card-text>
                    <div style="text-align:end">
                        <FileUploadComponentVue ref="fileUpload" @uploadedData="uploadedData" @reset="clearFileUpload"/>
                    </div>
                    <div>
                        <div class="mt-2">
                            <v-autocomplete
                                v-model="selectedUser"
                                :items="users"
                                :rules="rules.default"
                                item-text="text"
                                item-value="value"
                                placeholder="Select User"
                                auto-select-first
                            >
                            </v-autocomplete>
                        </div>
                    </div>
                    <div class="pb-4">
                        <div class="mt-2">
                            <v-autocomplete
                                :items="itemCategories"
                                v-model="selectedSpecialties"
                                item-value="value"
                                item-text="text"
                                multiple
                                class="d-inline-block small"
                                label="Item Category"
                                auto-select-first
                                return-object
                                small-chips
                            >
                            </v-autocomplete>
                        </div>
                    </div>
                    <div>
                        <div>
                            <v-textarea
                            v-model="skills"
                            dense
                            outlined
                            label="Skills"
                            :rules="rules.default"
                            >
                            <template #append>
                                <span style="color:red">*</span>
                            </template>
                            </v-textarea>
                        </div>
                    </div>
                    <div class="pb-4">
                        <div>
                            <v-autocomplete
                                :items="[1,2,3]"
                                outlined
                                v-model="selectedLevel"
                                label="Level"
                                auto-select-first
                            >
                            </v-autocomplete>
                        </div>
                    </div>
                </v-card-text>

                <v-divider class="mt-0" />

                <div style="text-align:center" class="pb-4">
                    <v-btn v-if="technicianId" @click="update()">Update</v-btn>
                    <v-btn v-else @click="submit()">Submit</v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import FileUploadComponentVue from '@/views/main/Shared/FileUploadComponent.vue';
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
export default {
    props:['parentComponent'],
    data() {
        return {
            name: '',
            specialty: '',
            itemCategories: [],
            skills: '',

            users: [],
            selectedUser: '',

            selectedSpecialties: [],
            technician: null,

            uploadedFiles: null,
            selectedLevel: 1,
            rules: {
                default: [
                v => !!v || 'This is required',
                ],
            },
        }
    },
    components: {
        FileUploadComponentVue
    },
    async mounted() {
        await this.getItemCategories();
        await this.getUsers();
    },
    watch: {
        technicianId() {
            if(!!this.technicianId) {
                this.$store.dispatch('getTechnician',this.technicianId).then(response=>{
                    this.getItemCategories();
                    this.getUsers();

                    this.technician = this.GET_TECHNICIAN;
                });
            }
        },
        technician() {
            if(!!this.technician) {
                const { name, category_ids, user_id, skills } = this.technician;

                let container = [];

                let categories = JSON.parse(category_ids);

                categories.forEach(c=>{
                    let cat = this.itemCategories.find(x=>x.value == c);

                    if(!!cat) {
                        container.push(cat);
                    }
                });

                this.name = name;
                this.selectedSpecialties = container;
                this.skills = skills;
                this.selectedUser = user_id;
            }
        },
        selectedUser() {
            if(!!this.selectedUser) {
                let user = this.users.find(x=> x.value == this.selectedUser);
                if(!!user) {
                    this.name = user.text;
                }
            }
        }
    },
    computed: {
        ...mapGetters([
            'GET_TECHNICIAN',
            'GET_SERVICE_USERS',
            'GET_ITEM_CATEGORIES',
        ]),
        technicianAddEditDialog() {
            return this.$store.state.service.technician.addEditDialog;;
        },
        technicianId() {
            return this.$store.state.service.technician.id;
        }
    },
    methods: {
        clearFields() {
            this.name = '';
            this.skills = '';
            this.selectedUser = '';
            this.selectedSpecialties = [];
            this.$store.commit('unsetTechnicianId');
        },
        getUsers(){
            this.$store.dispatch('getServiceUsers').then(response => {
                this.users = this.GET_SERVICE_USERS;
            });
        },
        getItemCategories(){
            this.$store.dispatch('getItemCategories').then(response => {
                this.itemCategories = response.data
            });
        },
        // onSelectedSpecialty(specialty) {
        //     this.selectedSpecialties = specialty
        // },
        showAddTechDialog() {
            this.$store.commit('showTechnicianDialog');
        },
        closeAddTechDialog() {
            this.$store.commit('closeTechnicianDialog');
            this.clearFields();
        },
        submit() {
            if(!this.requiredFields() && this.selectedSpecialties.length > 0) {
                const data = new FormData();

                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }

                if(!!this.uploadedFiles && this.uploadedFiles.attachments.length > 0) {
                    let files = this.uploadedFiles.attachments;

                    for(let i=0;i < _.compact(files).length;i++) {
                        data.append('files[]', files[i]);
                    }
                }

                data.append('name', this.name);
                data.append('skills', this.skills);
                data.append('specialties', JSON.stringify(this.selectedSpecialties));
                data.append('userId', this.selectedUser);
                data.append('level', this.selectedLevel);

                swal.fire({
                    title: 'Are you sure you want to create technician?',
                    text: 'Make sure that all information are correct',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then((result)=>{
                    if(result.isConfirmed) {
                        let payload = {
                            params: data,
                            config: config,
                        }

                        this.$store.dispatch('createTechnician',payload).then(response=>{
                            if(response.data.error) {
                                swal.fire("", response.data.error, "error");
                            } else {
                                this.closeAddTechDialog();
                                this.$store.commit('successAddEditTechnician');
                                swal.fire("", response.data.msg, "success").then(confirm=>{
                                    this.$store.commit('unsuccessAddEditTechnician');
                                });
                            }
                        });
                    }
                });
            }
        },
        update() {
            if(!this.requiredFields() && this.selectedSpecialties.length > 0) {
                swal.fire({
                    title: 'Are you sure you want to update technician?',
                    text: 'Make sure that all information are correct',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then((confirm)=>{
                    if(confirm.isConfirmed) {
                        const data = new FormData();

                        const config = {
                            headers: {
                                'content-type': 'multipart/form-data'
                            }
                        }

                        if(!!this.uploadedFiles && this.uploadedFiles.attachments.length > 0) {
                            let files = this.uploadedFiles.attachments;

                            for(let i=0;i < _.compact(files).length;i++) {
                                data.append('files[]', files[i]);
                            }
                        }

                        data.append('technicianId', this.technicianId);
                        data.append('name', this.name);
                        data.append('skills', this.skills);
                        data.append('specialties', JSON.stringify(this.selectedSpecialties));
                        data.append('userId', this.selectedUser);
                        data.append('level', this.selectedLevel);

                        let payload = {
                            params:data,
                            config:config,
                        }
                        this.$store.dispatch('updateTechnician',payload).then(response=>{
                            if(response.data.error) {
                                swal.fire("", response.data.error, "error");
                            } else {
                                this.closeAddTechDialog();
                                this.$store.commit('successAddEditTechnician');
                                swal.fire("", response.data.msg, "success").then(confirm=>{
                                    this.$store.commit('unsuccessAddEditTechnician');
                                });
                            }
                        });
                    }
                });
            }
        },
        uploadedData(data) {
            if(!data) {
                this.uploadedFiles = null;
            }

            this.uploadedFiles = data;
        },
        clearFileUpload(confirm) {
            if(confirm) {
                this.uploadedFiles = null;
            }
            // this.$refs.fileUpload.reset();
        },
        requiredFields() {
            let warning = '';
            if (!this.selectedUser) warning += 'Please select a User.<br>';
            if (!this.skills) warning += 'Please ensure that the empty field for the skills is filled in.<br>';

            if (warning !== '') return swal.fire({
                title: 'Please Fill out Information:',
                html: warning,
                icon: "warning",
            })
        },
    }
}
</script>

<style>

</style>
