<template>
    <div class="w-100">
        <v-app id="my_vue_app">
            <v-card>
                <v-card-title>
                    <v-container>
                        <v-row class="mb-2">
                            <v-spacer></v-spacer>
                            <div class="row col-sm-12 col-md-4 mr-3 align-items-end">
                                <v-text-field
                                    v-model="filter.search"
                                    @change="searchInput()"
                                    clearable
                                    id="searchBar"
                                    label="Search"
                                    single-line
                                    hide-details
                                >
                                </v-text-field>
                                <v-icon @click="searchInput()">mdi-magnify</v-icon>
                            </div>
                        </v-row>
                    </v-container>
                </v-card-title>
                <v-container fluid>
                    <v-data-table
                        :headers="datatable.email_logs.headers"
                        :items="datatable.items"
                        :search="filter.search"
                        :options.sync="datatable.options"
                        :server-items-length="datatable.totalItems"
                        :items-per-page="10"
                        :loading="loading.datatable"
                        :footer-props="{
                            itemsPerPageOptions: datatable.itemsPerPageOptions
                        }"
                        loading-text="Loading... Please wait"
                        fixed-header
                    >
                        <template v-slot:[`item.action`]="{ item }">
                            <v-btn text icon color="orange" v-if="userAccess.view">
                                <v-icon
                                    class="btn-action"
                                    small
                                    @click="viewWebMailData(item)"
                                    >mdi-eye</v-icon
                                >
                            </v-btn>
                            <!-- <v-btn text icon color="blue" v-if="userAccess.edit">
                                <v-icon
                                    class="btn-action"
                                    small
                                    @click=" editWebMailData(item)"
                                    >mdi-pencil</v-icon
                                >
                            </v-btn>
                            <v-btn text icon color="red" v-if="userAccess.delete && !toggles.isEmployeeList">
                            <v-icon
                                class="btn-action"
                                small
                                @click="deleteWebMailData(item)"
                                >mdi-delete</v-icon
                            >
                            </v-btn> -->
                        </template>
                    </v-data-table>
                </v-container>
                <EmployeeEmailListViewVue></EmployeeEmailListViewVue>
            </v-card>
        </v-app>
    </div>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from 'vuex';
import EmployeeEmailListViewVue from '@/views/main/layouts/dialogs/WebMail/EmployeeEmailListView.vue';
export default {
    data(){
        return {
            loading:{
                datatable:false
            },
            search: "",
            filter:{
                search:"",
                department_id:'',
                job_title_id:'',
                job_level_id:'',
            },

            datatable:{

                email_logs:{
                    headers:[
                        { text: 'Employee Name', align: 'start',value:'full_name' },
                        { text: 'Department', align: 'center', value: 'department_name'},
                        { text: 'Position', align: 'center', value: 'position_name'},
                        { text: 'Email', align: 'center', value: 'email'},
                        { text: "Action", align: "center", value: "action" },
                    ],
                },

                items:[],
                options:{},
                itemsPerPageOptions: [5, 10, 15, 50, 100],
                itemsPerPage: 10,
                totalItems: 0,
            },

            toggles:{
                isEmployeeList:0,
                withMail:0
            },
           
            userAccess: {
                view: false,
                create: false,
                edit: false,
                delete: false,
            },
           
            component_dispatches:[
                "credentials",
			] ,
        }
    },
    created(){
    },
    async mounted(){
        this.getAllDropdowns()        
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS',
            'GET_WEBMAIL_NEW_DATA_TRIGGER',
            'DIALOGS',
            'USERACCOUNT_LOAD',
            'GET_WEBMAIL_TAB_IS_ACTIVE',
            'GET_WEBMAIL_DISPATCH'
        ]),
    },
    components:{
        EmployeeEmailListViewVue
    },
    methods:{
        getAllDropdowns(){
            this.component_dispatches.forEach(el=>{
                this.$store.dispatch(el)
			})
		},
        searchInput(){
            this.getAllEmailList({ query: this.search })
        },  
        // Web Mail Functions ----------------------------------------------------------------------------------------------------------------------------------------- 
        async getAllEmailList(filter = null){
            this.items = [];
            this.loading.datatable = true;
            let payload = {
                page                        : this.datatable.options.page,
                itemsPerPage                : this.datatable.options.itemsPerPage,
                search                      : this.filter.search,
                filter_department_id        : this.filter.department_id,
                filter_job_title_id         : this.filter.job_title_id,
                filter_job_level_id         : this.filter.job_level_id,
                sortBy                      : this.datatable.options.sortBy,
                sortDesc                    : this.datatable.options.sortDesc,
                user_id                     : this.USERACCOUNT_LOAD.id,
                contract_type               : this.selected_contract,
                filter                      : filter,
                department_id               : this.USERACCOUNT_LOAD.department_id,
            };
            await this.$store.dispatch(this.GET_WEBMAIL_DISPATCH.get, payload).then((response) => {
                this.datatable.items = response.data.data;

                this.loading.datatable = false;

                this.datatable.totalItems = response.total;
            });
        },
        viewWebMailData(item){
            let items = {
                data: item.web_mail_logs,
                count : item.web_mail_logs_count
            }
            this.$store.commit("WEBMAIL_VIEW", items);
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", "View");
        },
    },
    watch:{
        'datatable.options': {
            handler() {
                if(this.toggles.isEmployeeList == 1){
                    this.getAllEmployeeData()
                }
                else{
                    this.getAllEmailList();
                }
            },
            deep: true,
        },
        USER_ACCESS: {
            handler(val) {
                if(val != "fail"){
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                this.userAccess.create = false;
                    val.forEach(e => {
                        if (e.actions_code == 'edit') {
                            this.userAccess.edit = true;
                        } else if (e.actions_code == 'delete') {
                            this.userAccess.delete = true;
                        } else if (e.actions_code == 'view') {
                            this.userAccess.view = true;
                        } else if (e.actions_code == 'create') {
                            this.userAccess.create = true;
                        }
                    });
                }
            }
        },
        GET_WEBMAIL_NEW_DATA_TRIGGER:{
            deep:true,
            handler(val){
                if(val){
                    this.getAllEmailList();
                    this.$store.commit('WEBMAIL_NEW_DATA_TRIGGER',false)
                }
            }
        },
    }
}  
</script>
<style scoped>
.selected {
    opacity: 100%;
}

.non-selected {
    opacity: 40%;
}
</style>