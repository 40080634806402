<template>
    <v-container fluid>
        <ComponentHeader
            :button="true"
            :filterd="filters"
            :title="'Unconfirmed Packing List (> N Days)'"
            :type="'Accounting'"
        ></ComponentHeader>
        <ReportBodyComponent
            :headers="headers"
            totals
            expand
            trigger
            :user-costing="{ userCosting: GET_USER_COSTING, showCost: filters.show_cost }"
        >
            <template #first-row>
                <v-card-text>
                    <v-row>
                        <v-col lg="3" style="padding: 3px">
                            <v-autocomplete
                                v-model="filters.customers.selected"
                                :items="GET_CUSTOMERS_SELECTION"
                                :loading="loading.customer_selection"
                                :disabled="loading.customer_selection"
                                outlined
                                dense
                                background-color="white"
                                label="Select Customer"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col lg="3">
                            <v-autocomplete
                                v-model="filters.salesman.selected"
                                :items="GET_SALESMAN_SELECTION"
                                :loading="loading.salesman_selection"
                                :disabled="loading.salesman_selection"
                                outlined
                                dense
                                background-color="white"
                                label="Select Salesman"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="3">
                            <v-autocomplete
                                v-model="filters.customer_cluster.selected"
                                :items="GET_CLUSTER_SELECTION"
                                :loading="loading.customer_cluster_selection"
                                :disabled="loading.customer_cluster_selection"
                                label="Select Cluster"
                                background-color="white"
                                dense
                                outlined
                                hide-details
                                clearable
                            ></v-autocomplete>
                        </v-col>
                        <v-col lg="1">
                            <v-text-field
                                v-model="filters.days"
                                outlined
                                dense
                                type="number"
                                background-color="white"
                                min="1"
                                hide-spin-buttons
                                :suffix="filters.days <= 1 ? 'Day' : 'Days'"
                                @keypress="numberOnly($event);"
                                @blur="backspaceLimit"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </template>
        </ReportBodyComponent>

    </v-container>
</template>

<script>
import SharedFunctionsComponent from "@/views/main/Shared/SharedFunctionsComponent.vue";
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
import ReportBodyComponent from '@/views/main/layouts/Reports/ReportsBodyComponent.vue'
import { mapGetters } from 'vuex';
    export default {
        mixins: [
            SharedFunctionsComponent,
        ],
        data(){
            return{
                errors: [],
                filters:{
                    customers:{
                        selection:null,
                        selected:'',
                    },
                    salesman:{
                        selected:'',
                        selection:null
                    },
                    customer_cluster:{
                        selected:'',
                        selection:null
                    },
                    days: 5,
                },
                headers: [
                    { text: 'Customer', align: 'left', value: 'customer_name', },
                    { text: 'Cluster', align: 'left', value: 'customer_clusters', },
                    { text: `# of PL's`, align: 'left', value: 'packing_lists_count', },
                    { text: `Oldest PL Date`, align: 'left', value: 'oldest_date', },
                    { text: `Newest PL Date`, align: 'left', value: 'newest_date', },
                    { text: 'Amount', align: 'right', width: '10%', value: 'total_amount', },
                ],
                expand_header: [
                    { text:'pl#', type:'string', allCaps: true, },
                    { text:'pl_date', type:'string', allCaps: true, },
                    { text:'remarks', type:'string', allCaps: true, },
                    { text:'total_amount', type:'price', allCaps: true, },
                ],
                selected:{
                    customers:'',
                    salesman:'',
                    customer_cluster:'',
                },
                loading:{
                    customer_selection      : true,
                    salesman_selection      : true,
                    customer_cluster_selection: true,
                },
            }
        },
    mounted() {
            this.$store.dispatch('getAllcustomersSelection')
            this.$store.dispatch('getAllsalesmanSelection')
            this.$store.dispatch('getClusterSelection')
            this.$store.commit('FILTER_VALUE',this.getFilters())
            this.getFilters()
        },
        methods:{
            getFilters(){
                let data = {
                    salesman_id: this.filters.salesman.selected,
                    customer_id: this.filters.customers.selected,
                    customer_cluster_id: this.filters.customer_cluster.selected,
                    days: this.filters.days,
                    filename:'Unconfirmed Packing List (> 5days)',
                    url: 'unconfirmed-packing-lists',
                    expand_url:'packing-lists/getUnconfirmedPackingListsAdditionalInformation/detail_id',
                    headers: this.headers,
                    expand_header: this.expand_header,
                }
                return data
            },
            backspaceLimit() {
                if (this.filters.days === '') {
                    this.filters.days = 1;
                }
            }
        },

        computed:{
            ...mapGetters([
                'GET_SALESMAN_SELECTION',
                'GET_CUSTOMERS_SELECTION',
                'GET_CLUSTER_SELECTION',
                'GET_SALES_BY_CUSTOMER_DETAILED_DATA',
            ]),
        },

        components:{
            ComponentHeader,
            ReportBodyComponent,
        },

        watch:{
            'filters':{
                handler(){
                    this.$store.commit('FILTER_VALUE',this.getFilters())
                },
                deep:true
            },
            'GET_CUSTOMERS_SELECTION':{
                handler(val){
                    this.filters.customers.selection = val
                    this.loading.customer_selection = false
                }
            },
            'GET_SALESMAN_SELECTION':{
                handler(val){
                    this.filters.salesman.selection = val
                    this.loading.salesman_selection = false
                }
            },
            'GET_CLUSTER_SELECTION':{
                handler(val){
                    this.filters.customer_cluster.selection = val
                    this.loading.customer_cluster_selection = false
                }
            },
        }
    }
</script>
