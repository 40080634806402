<template>
    <div class="pb-4" style="width:100%">
        <div class="mb-2 mt-2">
            <div class="row">
                <div class="col-12 d-flex no-block align-items-center">
                    <div class="btn-toolbar">
                        <v-btn data-modal-id="drFormModal" v-on:click="showLocationDialog()">
                            <i class="fas fa-plus pr-2"></i>Add
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
        <v-card class="pa-2" style="border-radius:0px">
            <v-card-title>
                <v-layout>
                    <v-spacer />
                    <v-flex lg3>
                        <v-text-field
                            v-model="search"
                            append-outer-icon="mdi-magnify"
                            label="Search Code / Name"
                            single-line
                            hide-details
                            clearable
                            @keydown.enter="searchLocation()"
                            @click:append-outer="searchLocation()"
                            @click:clear="search='';searchLocation()"
                        />
                    </v-flex>
                </v-layout>
            </v-card-title>
            <v-card-text>
                <v-data-table
                    :headers="scLocationHeaders"
                    :server-items-length="totalScLocations"
                    :options.sync="options"
                    :items="scLocations"
                    :loading="loading"
                    :search="search"
                    >
                <template v-slot:item.action="{ item }">
                    <td class="px-0">
                        <span class="text-nonwrap">
                            <v-btn text icon color="orange">
                                <v-icon class="btn-action" small @click="showScLocationDetails(item)">mdi-eye</v-icon>
                            </v-btn>
                            <v-btn text icon color="blue">
                                <v-icon class="btn-action" small @click="editScLocationDetails(item)">mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn text icon color="red">
                                <v-icon class="btn-action" small @click="deleteScLocationDetails(item)">mdi-delete</v-icon>
                            </v-btn>
                        </span>
                    </td>
                </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <AddLocationDialogComponentVue/>
    </div>
</template>

<script>
import AddLocationDialogComponentVue from '@/views/main/modules/Services/Views/LocationComponent/AddLocationDialogComponent.vue';
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';

export default {
    data() {
        return {
            search: '',
            scLocationHeaders: [
                { text: 'Code', value: 'code' },
                { text: 'Name', value: 'name' },
                { text: 'Contact Number', value: 'contact_number' },
                { text: 'Address', value: 'address' },
                // { text: 'Default Warehouse', value: 'warehouse_name' },
                // { text: 'Repair Warehouse', value: 'repair_warehouse_name' },
                // { text: 'Reserve Warehouse', value: 'reserve_warehouse_name' },
                // { text: 'Defective Warehouse', value: 'defective_warehouse_name' },
                // { text: 'Baklas Warehouse', value: 'baklas_warehouse_name' },
                { text: 'Action', value: 'action' },
            ],
            scLocations: [],
            totalScLocations:0,
            scLocation: null,
            loading:false,
            options:{},
            locationDetails:{},
        }
    },
    components: {
        AddLocationDialogComponentVue
    },
    async mounted() {
        await this.getAllLocations();
    },
    watch: {
        createLocation() {
            if(!!this.createLocation) {
                this.getAllLocations();
            }
        },
        'options': {
            handler(val) {
                this.getAllLocations(this.GET_DISPOSAL_STATUS);
            }
        },
    },
    computed: {
        ...mapGetters([
            'GET_SERVICE_ALL_LOCATIONS'
        ]),
        createLocation() {
            return this.$store.state.service.scLocation.createLocation;
        },
        action() {
            return this.$store.state.service.scLocation.action;
        }
    },
    methods: {
        async getAllLocations() {
            this.loading = true;
            let payload = {
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                search: this.search,
                url:'sc_locations',
            }
            await this.$store.dispatch('serviceGet', payload).then(res => {
                this.scLocations = res.data.data;
                this.totalScLocations = res.data.total;
            }).catch( e => {
                console.log(e);
            })
            this.loading = false;
        },
        showScLocationDetails(item) {
            this.$store.commit('scLocationShowAddEditDialog');
            this.$store.commit('scLocationViewAction');
            this.$store.commit('scLocationGetId', item.id);
            this.$store.commit('setLocationWarehouses', item);
        },
        deleteScLocationDetails(item) {
            swal.fire({
                title: 'Are you sure you want to Delete Location?',
                text: 'Make sure that all information are correct',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then((result)=>{
                if(result.isConfirmed) {
                    let payload = {
                        scLocationId: item.id
                    }
                    this.$store.dispatch('deleteLocation',payload).then(response=>{
                        if(!!response.data.error) {
                            swal.fire('', response.data.error, 'error');
                        } else {
                            swal.fire('', response.data.msg, 'success');
                            this.getAllLocations();
                        }
                    });
                }
            });
        },
        editScLocationDetails(item) {
            this.$store.commit('scLocationShowAddEditDialog');
            this.$store.commit('scLocationEditAction');
            this.$store.commit('scLocationGetId', item.id);
            this.$store.commit('setLocationWarehouses', item);
        },
        searchLocation(){
            this.options.page = 1;
            this.getAllLocations();
        },
        showLocationDialog() {
            this.$store.commit('scLocationShowAddEditDialog');
            this.$store.commit('scLocationAddAction');
        },
    }
}
</script>

<style>

</style>
