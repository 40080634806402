<template>
    <v-container fluid>
        <h3 class="page-title"> Store / Branches User Access</h3>
        <v-container class="ma-0">
            <v-app id="item-tabs">
                <v-tabs
                v-model="tab"
                id="group-tab"
                color="cyan"
                slider-color="cyan"
                background-color="#424242"
                dark
                show-arrows
                >
                    <v-tabs-slider color="cyan"></v-tabs-slider>
                        <v-tab
                            ripple
                            v-for="(item, i) in items"
                            :key="i"
                            @click="tabs(item)"
                        >
                            {{ item.tab_name }}
                        </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item v-for="(item, i) in items" :key="i">
                        <keep-alive>
                            <component
                                :is="item.children.components"
                                :admin_inventory_tabs_items="item.children"
                                :isActive_tab="item.tab_name"
                            >
                            </component>
                        </keep-alive>
                    </v-tab-item>
                </v-tabs-items>
            </v-app>
        </v-container>
        <dialog-component :width="GET_COMPONENT_SETTINGS.children.dialog.width" :cp="GET_COMPONENT_SETTINGS.children.dialog.components"></dialog-component>
    </v-container>
</template>
<script>

import { mapGetters } from 'vuex';
import ComponentSettings from '@/views/main/modules/Accounting/StoreBranchesAccess/Settings';
import DialogComponent from '@/views/main/layouts/dialogs/Dialog.vue'
export default {
mixins:[ComponentSettings],
data() {
    return {
        components: "",
        items: ComponentSettings.tab_item,
        admin_inventory_tabs_items: [],
        dialog_width: "",
        loaded: false,
        options: {},
        itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
        itemsPerPage: 10,
        tab: null,
        dialog_scrollable: true,
    };
},
components:{DialogComponent},
created(){
    this.tabs(this.items[0])
},
mounted() {
    this.dropDowns();
 
},
computed: {
    ...mapGetters([
            "GET_COMPONENT_SETTINGS",
        ]),
        width() {
        switch (this.$vuetify.breakpoint.name) {
            case "xs":
            return "100%";
            case "sm":
            return "100%";
            case "md":
            return '40%';
            case "lg":
            return '60%';
            case "xl":
            return '60%';
        }
    },
},
methods: {
    dropDowns() {
        this.$store.dispatch("getCompanySelection"); 
        this.$store.dispatch("getDepartment");
    },
    headerItems() {
        this.items = [
            { tab_name: "ALL", view: true },
            ...this.GET_ADM_WAREHOUSE_CODE.data.map((item) => ({
                tab_name: item.code,
                view: true,
            })),
        ];
    },
    tabs(item) {
        item.children.dialog.width = this.width
        this.$store.commit("COMPONENT_SETTINGS", item);
    },
},
watch: {

},
};
</script>
<style>
#group-tab {
padding: 0 !important;
}
</style>