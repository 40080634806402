<template>
    <div class="page-wrapper">
        <TechnicianSettingsComponentVue />
    </div>
</template>

<script>
import TechnicianSettingsComponentVue from '../TechnicianSettingsComponent.vue';

export default {
    data() {
        return {

    }
    },
    mounted(){
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
    },
    components: {
        TechnicianSettingsComponentVue
    }
}
</script>

<style>

</style>
