<template>
    <v-container fluid class="pa-3">
        <h3 class="page-title">Service Payment Request</h3>
        <v-container class="m-0">
            <v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" dark background-color="#424242" class="col-12">
                <v-tab
                    ripple
                    v-for="(item) in STATUS.SERVICE_PAYMENT_REQUEST_STATUS"
                    :key="item.value"
                    @click="changeTab(item.value)"
                >
                    {{ item.text }}
                </v-tab>
                <v-spacer/>
                <v-tab @click="changeTab(6)">Types</v-tab>
            </v-tabs>
            <ServicePaymentRequestComponent v-if="tab !== 6" :key="tab" :userAccess="userAccess" />
            <ServicePaymentRequestTypeComponent v-if="tab === 6" :userAccess="userAccess" />
        </v-container>
    </v-container>
</template>

<script>
import ServicePaymentRequestComponent from '@/views/main/modules/Accounting/ServicePaymentRequest/ServicePaymentRequestComponent.vue'
import ServicePaymentRequestTypeComponent from '@/views/main/modules/Accounting/ServicePaymentRequest/ServicePaymentRequestTypeComponent.vue'
import { mapGetters } from 'vuex'

export default {
    data(){
        return{
            tab:0,
            userAccess: []
        }
    },
    components:{
        ServicePaymentRequestComponent,
        ServicePaymentRequestTypeComponent
    },
    beforeMount(){
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
    },
    mounted(){
        this.$store.dispatch('getCOASelection')
        this.$store.dispatch('getDepartmentListforAccounting')
        this.$store.dispatch('getAdminSupplierSelection')
        this.$store.dispatch('getPvCompanies')
        this.$store.dispatch('getPaymentVoucherBranchesSelection')
        this.$store.dispatch('getTransactTypesSelection',{include_model_name:1})
        this.$store.dispatch('getServicePaymentRequestType',{filter:1})
        this.$store.dispatch('GetPreparedBy',{url: 'get-prepared-by', module: 'ServicePaymentRequest'})
        this.$store.dispatch('getCompanyClassificationDropdown')
    },
    computed: {
        ...mapGetters([
            'STATUS',
            'SELECTED_TAB',
            'SERVICE_PAYMENT_REQUEST_STATUS',
            'USER_ACCESS'
        ])
    },
    methods: {
        changeTab(status_id) {
            this.tab = status_id;
            this.$store.commit('SELECTED_TAB',status_id)
        }
    },
    watch:{
        USER_ACCESS:{
            handler(val){
                if (val != 'fail') {
                    this.userAccess = val.map(e=>e.actions_code)
                }
            },
            immediate: true
        }
    }
}
</script>
