<template>
    <v-dialog v-model="dialog" persistent max-width="50%">
        <v-card>
            <v-row class="m-0">
                <v-col cols="10">
                    <v-card-title>
                        <p class="ma-0">{{ memo_type }} Memo #: {{ memo_type == 'Credit' ? memo_items.credit_memo_num : memo_items.debit_memo_num }} | {{ memo_items.credit_memo_type_by_name }}</p>
                    </v-card-title>
                </v-col>
                <v-col cols="col-2 p-2">
                    <v-btn text icon color="gray" class="float-right" @click="closeMemoView();">
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-card-text>
                
                <div v-if="memo_type == 'Credit'">
                    <p class="ma-0">Reference PLs: {{ memo_items.packing_list_nums }}</p>
                    <p class="ma-0">Dealer Ref#: {{ memo_items.customer_ref_no }}</p>
                    <p class="ma-0">Warehouse: {{ memo_items.warehouse_name }}</p>
                </div>
                <v-data-table
                dense
                :headers="selected_header"
                :items="memo_type == 'Credit' ? memo_items.credit_memo_items : memo_items.debit_memo_items"
                hide-default-footer
                >
                    <template v-slot:[`item.total_amount`]="{ item }">
                        <span class="text-nowrap">
                            &#x20b1;{{ item.total_amount | currency }}
                        </span>
                    </template>
                    <template v-slot:[`item.item_amount`]="{ item }">
                        <span class="text-nowrap">
                            &#x20b1;{{ item.item_amount | currency }}
                        </span>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props:['view_memo_details','memo_type'],
    data(){
        return{
            dialog:false,
            memo_items:[],
            headers:{
                creditMemoHeaders:[
                    { text: 'Model', align: 'left', value: 'item_model', },
                    { text: 'Name', align: 'left', value: 'item_name', },
                    { text: 'Quantity', align: 'center', value: 'quantity_cm', },
                    { text: 'Unit Price', align: 'left', value: 'item_amount', },
                    { text: 'Remarks', align: 'left', value: 'remarks', },
                    { text: 'Total Amount', align: 'left', value: 'total_amount', },
                ],
                debitMemoHeaders:[
                    { text: 'Remarks', align: 'left', value: 'remarks', },
                    { text: 'Total Amount', align: 'left', value: 'total_amount', },
                ]
            },
            selected_header:[]
        }
    },
    methods:{
        closeMemoView(){
            this.dialog = false;
        }
    },  
    watch:{
        view_memo_details:{
            dialog:false,
            handler(val){
                if(val.length != 0){
                    this.memo_items = val
                    if(this.memo_type == 'Credit'){
                        this.selected_header = this.headers.creditMemoHeaders   
                    }
                    else if(this.memo_type == 'Debit'){
                        this.selected_header = this.headers.debitMemoHeaders
                    }
                    this.dialog = true
                }        
            }
        }
    }
}
</script>

<style>

</style>