<template>
     <v-container fluid style="display:none">
            <div>
                <table id="rr_details">
                    <tbody>
                        <tr>
                            <td colspan="4">Warehouse: {{ warehouseName }}</td>
                            <td colspan="2">Pull Out No: {{ pulloutNumber }}</td>
                        </tr>
                        <tr>
                            <td colspan="4">Company Name: {{ companyName }}</td>
                            <td colspan="4">Contact Number: {{ companyContactNumber }}</td>
                        </tr>
                        <tr>
                            <td colspan="4">Customer Name: {{ customerName }}</td>
                            <td colspan="4">Contact Number: {{ contactNumber }}</td>
                        </tr>
                    </tbody>
                </table>
                <table id="rr_item_details">
                    <tbody>
                        <tr>
                            <td>RF#</td>
                            <td>Item Model</td>
                            <td colspan="2">Item Name</td>
                            <td>Serial No</td>
                            <td colspan="2">Included Items</td>
                            <td colspan="2">Physical Condition</td>
                            <td colspan="1">Complaint/Symptom</td>
                        </tr>
                        <tr v-for="rf in rfLists" :key="rf.id">
                            <td>{{ rf.rf_number }}</td>
                            <td>{{ rf.sc_repair_form_item.model }}</td>
                            <td colspan="2">{{ rf.sc_repair_form_item.product_name }}</td>
                            <td>{{ rf.sc_repair_form_item.serial }}</td>
                            <td colspan="2">{{ rf.sc_repair_form_item.accessories }}</td>
                            <td colspan="2">{{ rf.sc_repair_form_item.unit_condition }}</td>
                            <td>{{ rf.sc_repair_form_item.defect_description }}</td>
                        </tr>
                        <tr>
                            <td colspan="10">Total: {{ rfLists.length }}</td>
                        </tr>
                    </tbody>
                </table>
                <table id="rr_process_details">
                    <tbody>
                        <tr>
                            <td colspan="8">
                                <b>Note:</b>
                                Any action requested on each item shall be subject for approval and may be declined if found unacceptable under the company's Service Policy.
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">Delivered by: {{ deliveredBy }}</td>
                            <td colspan="2">Received by: {{ receivedBy }}</td>
                            <td colspan="2">Noted by: </td>
                            <td colspan="2">Processed by:</td>
                        </tr>
                        <tr>
                            <td colspan="2"></td>
                            <td colspan="2"></td>
                            <td colspan="2"></td>
                            <td colspan="2"></td>
                        </tr>
                        <tr>
                            <td colspan="2">Signature over Printed Name: </td>
                            <td colspan="2">Service Personnel:</td>
                            <td colspan="2">Service Department:</td>
                            <td colspan="2">Accounting Department:</td>
                        </tr>
                    </tbody>
                </table>
            </div>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent'
export default {
    mixins:[SharedFunctionsComponent],
    components:{

    },
    props:{
        rrId: {
            type: Number,
            default: () => null
        }
    },
    data() {
        return {
            rrDetails:{},
            rfLists: [],
            rrNumber:'',
            pulloutNumber:'',
            warehouseName:'',
            companyName:'',
            companyContactNumber:'',
            customerName:'',
            contactNumber:'',
            deliveredBy:'',
            receivedBy:'',
            rrDateCreated:'',
            companyDetails:{},
        }
    },
    computed:{
        ...mapGetters([
            'GET_RR_DETAILS',
            'GET_SYSTEM',
        ]),

        receivingReportId() {
            return this.$store.state.Services.print_rr_id
        }
    },
    methods:{
        async printRrDetails(id) {
            await this.getRrDetails(id);
            this.$forceUpdate();
            this.rfLists = _.compact(this.rfLists);
            let doc = new this.$jspdf('p', 'mm', 'letter');
            doc.setFont(undefined, 'bold').setFontSize(13).text(6,8,`${this.companyDetails.company_name}`)
            doc.setFont(undefined, 'normal').setFontSize(9).text(6,13,`${this.companyDetails.address} \n\nCell No.: ${this.companyDetails.mobile} \n\nTel. No.: \nLandline:${this.companyDetails.landline}\n\nSpare Parts Mobile / Viber No.: ${this.companyDetails.viber} \nEmail:${this.companyDetails.email}`)
            doc.setFont(undefined, 'bold').setFontSize(13).text(162,8,"Receiving Details")
            doc.setFont(undefined, 'bold').setFontSize(9).text(162,13,"\nRD#:"+this.rrNumber+"\n\nPullout#: "+this.pulloutNumber+"\n\nIssued: "+this.rrDateCreated)
            doc.autoTable({
                html: '#rr_details',
                theme: 'grid',
                margin: {top: 50, left: 7, right: 6},
                columnStyles: {
                    0: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                    1: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                    2: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                    3: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                    4: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                    5: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                    6: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                    7: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8}
                }
            });
            doc.autoTable({
                html: '#rr_item_details',
                margin: {left: 7, right: 6},
                columnStyles: {
                    0: {cellWidth: 15, halign:'left', textColor: '#000000', fontSize: 8},
                    1: {cellWidth: 15, halign:'left', textColor: '#000000', fontSize: 8},
                    2: {cellWidth: 6, halign:'left', textColor: '#000000', fontSize: 8},
                    3: {cellWidth: 6, halign:'left', textColor: '#000000', fontSize: 8},
                    4: {cellWidth: 22, halign:'left', textColor: '#000000', fontSize: 8},
                    5: {cellWidth: 6, halign:'left', textColor: '#000000', fontSize: 7},
                    6: {cellWidth: 6, halign:'left', textColor: '#000000', fontSize: 7},
                    7: {cellWidth: 6, halign:'left', textColor: '#000000', fontSize: 7},
                    8: {cellWidth: 6, halign:'left', textColor: '#000000', fontSize: 7},
                    9: {cellWidth: 45, halign:'left', textColor: '#000000', fontSize: 8}
                }
            });

            doc.autoTable({
                html: '#rr_process_details',
                theme: 'grid',
                startY: 235,
                margin: {top: 37, left: 7, right: 6},
                columnStyles: {
                    0: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                    1: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                    2: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                    3: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                    4: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                    5: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                    6: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                    7: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8}
                }
            });
            doc.setFont(undefined, 'normal').setFontSize(8).text(6,270,"Original - Accounting Department, Yellow - Service Department, Blue - Operations Department")
            window.open(doc.output('bloburl'), '_blank');
            this.$store.commit('PRINT_RR_ID', 0);
        },
        async getRrDetails(id) {
            await this.$store.dispatch('getRrDetails',id).then(response => {
                this.rrDetails = this.GET_RR_DETAILS;
            });
        },
    },
    watch: {
        'receivingReportId': {
            handler(val){
                if(val != 0 ){
                    this.printRrDetails(val)
                }
            }
        },
        rrDetails() {
            if(!!this.rrDetails) {
                this.rrNumber = this.rrDetails.rr_number;
                if(!!this.rrDetails.pullout_number) {
                    this.pulloutNumber = this.rrDetails.pullout_number;
                }
                this.rfLists = _.compact(this.rrDetails.sc_repair_forms);
                this.rrDateCreated = this.$dayjs(this.rrDetails.created_at).format('YYYY-MM-DD, hh:mm:ss a');
                this.customerId = this.rrDetails.customer_id;
                this.companyName = this.rrDetails.customer.company_name;

                if(this.rfLists.length > 0) {
                    this.rfListsCount = _.compact(this.rfLists).length;
                } else {
                    this.rfListsCount = this.rfLists.length;
                }
                this.deliveredBy = this.rrDetails.delivered_by;
                this.receivedBy = this.rrDetails.received_by;
                this.warehouseName = this.rrDetails.warehouse_name;
                let contact = [];
                let fullName = [];

                if(!!this.rrDetails.sc_location) {
                    this.locationName = this.rrDetails.sc_location.name;
                }

                if(this.customerId == 275) {
                    let sc_customer = this.rrDetails.sc_customer;
                    if(!!sc_customer.first_name) {
                         fullName.push(sc_customer.first_name);
                    }
                    if(!!sc_customer.middle_name) {
                         fullName.push(sc_customer.middle_name);
                    }
                    if(!!sc_customer.last_name) {
                         fullName.push(sc_customer.last_name);
                    }
                    this.customerName = fullName.toString().replaceAll(',', ' ');
                    this.address = this.rrDetails.sc_customer.address;
                    this.contactNumber = this.rrDetails.sc_customer.contact_number;
                    this.email = this.rrDetails.sc_customer.email;
                } else {
                    let customer = this.rrDetails.customer;
                    if(!!customer.first_name) {
                         fullName.push(customer.first_name);
                    }

                    if(!!customer.middle_name) {
                         fullName.push(customer.middle_name);
                    }

                    if(!!customer.last_name) {
                         fullName.push(customer.last_name);
                    }
                    // this.customerName = fullName.toString().replaceAll(',', ' ');
                    this.address = JSON.parse(customer.shipping_addresses)[0].address;
                     if(!!customer.mobile_1) {
                         contact.push(customer.mobile_1);
                     }
                     if(!!customer.mobile_2) {
                         contact.push(customer.mobile_2);
                     }
                     if(!!customer.mobile_3) {
                         contact.push(customer.mobile_3);
                     }
                     if(!!customer.tel_1) {
                         contact.push(customer.tel_1);
                     }
                     if(!!customer.tel_2) {
                         contact.push(customer.tel_2);
                     }
                     if(!!customer.tel_3) {
                         contact.push(customer.tel_3);
                     }
                    this.companyContactNumber = contact.toString().replaceAll(',', ' / ');
                }
            }
        },
        GET_SYSTEM:{
            handler(val){
                if(Object.keys(val).length > 0) {
                    switch (val.code) {
                        case 'ING':
                            this.getCompanyDetails(['Service'], 'ING').then( result => {
                                this.companyDetails = result[0];
                            })
                            break;
                        case 'WAD':
                            this.getCompanyDetails(['Service'], 'WAD').then( result => {
                                this.companyDetails = result[0];
                            })
                            break;
                    }
                }
            }
        },
    },
    mounted() {
        this.getSystemPlatform();
    }

}
</script>

<style>

</style>