<template>
    <div class="w-100">
        <v-app id="my_vue_app">
            <v-card>
                <v-card-title>
                    <v-container>
                        <v-row>
                            <v-col cols="2">
                                <v-autocomplete
                                    v-model="filter.department_ids"
                                    @change="getForApprovalTemporaryEmployeeSchedule({ query: search })"
                                    :items="dropdowns.departments_lists"
                                    label="Department"
                                    auto-select-first
                                    small-chips
                                    clearable
                                    multiple
                                    outlined
                                    required
                                    dense
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="12" sm="12" md="3" class="d-flex justify-content-end align-items-center">
                                <a
                                    v-for="(status, index ) in statuses" :key="index"
                                    :class="`ml-1 badge badge-${status.text} ${status.value == selected_status ? `selected` : `non-selected`}`" @click="selectedStatus(status)"
                                    >
                                {{ status.text ? status.text.replace('-'," ") : null  }}
                                </a>
                            </v-col>
                        </v-row>
                        <v-row class="mb-2">
                            <v-spacer></v-spacer>
                            <div class="row col-sm-12 col-md-4 mr-3 align-items-end">
                                <v-text-field
                                    id="searchBar"
                                    v-model="filter.search"
                                    label="Search Employee Name/ID"
                                    single-line
                                    hide-details
                                    @change="searchInput()"
                                >
                                </v-text-field>
                                <v-icon @click="searchInput()">mdi-magnify</v-icon>
                            </div>
                        </v-row>
                    </v-container>
                </v-card-title>
                <v-container fluid>
                    <v-data-table
                        :headers="datatable.headers"
                        :items="GET_FOR_APPROVAL_SCHEDULE.data ? GET_FOR_APPROVAL_SCHEDULE.data.data :  []"
                        :search="search"
                        :options.sync="datatable.options"
                        :server-items-length="datatable.totalItems"
                        :items-per-page="10"
                        :loading="loading.datatable"
                        :footer-props="{
                            itemsPerPageOptions: datatable.itemsPerPageOptions
                        }"
                        loading-text="Loading... Please wait"
                        fixed-header
                        dense
                    >
                        <template v-slot:[`item.status`]="{item}">
                            <span :class="`badge badge-rejected`" v-if="item.rejected == 1">rejected</span>
                            <span :class="`badge badge-${item.approved == 1 ? 'approved' : 'pending'}`" v-else>{{ item.approved == 1 ? 'approved' : 'pending' }}</span>
                        </template>
                        <template v-slot:[`item.action`]="{ item }">
                            <v-tooltip bottom color="#f69f1a"  v-if="userAccess.view">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn text icon color="orange"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon
                                            class="btn-action"
                                            small
                                            @click="viewEmployeeSchedule(item)"
                                        >mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                                <span>View Schedule</span>
                            </v-tooltip>
                            <v-tooltip bottom color="red" v-if="(userAccess.delete_temp_schedule && !item.approved) && item.rejected == 0">
                                <template v-slot:activator="{ on, attrs }">
                                        <v-btn text icon color="red"
                                            v-bind="attrs"
                                            v-on="on"
                                         >
                                            <v-icon
                                                class="btn-action"
                                                small
                                                @click="deleteTempSchedule(item.id)"
                                                >mdi-delete</v-icon
                                            >
                                        </v-btn>
                                </template>
                                <span>Delete Schedule</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-container>
            </v-card>
        </v-app>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2';
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
export default {
    mixins:[SharedFunctionsComponentVue],
    data(){
        return {
            loaded: false,
            search: "",
            filter:{
                search:"",
                dialog: false,
                department_ids: [],
                year: this.$dayjs().year(),
                date_from_menu: false,
                date_to_menu: false,
                effectivity_date: []
            },

            datatable:{
                headers:
                    [
                        // { text: 'Employee Name', align: 'start', value: 'employee_full_name'},
                        // { text: 'Pending Count', align: 'start', value: 'pending_count'},
                        // { text: 'Approved Count', align: 'start', value: 'approved_count'},
                        // { text: 'Total Count', align: 'start', value: 'total_count'},
                        // { text: "Action", align: "center", value: "action" }
                        { text: 'Employee Name', align: 'start', value: 'full_name'},
                        { text: 'Effectivity Date', align: 'start', value: 'effectivity_date'},
                        // { text: 'Schedule Type', align: 'start', value: 'schedule_type_name'},
                        { text: 'Monday', align: 'start',value:'Monday' },
                        { text: 'Tuesday', align: 'start',value:'Tuesday' },
                        { text: 'Wednesday', align: 'start',value:'Wednesday' },
                        { text: 'Thursday', align: 'start', value: 'Thursday'},
                        { text: 'Friday', align: 'start', value: 'Friday'},
                        { text: 'Saturday', align: 'start', value: 'Saturday'},
                        { text: 'Sunday', align: 'start', value: 'Sunday'},
                        { text: "Approved By", align: "center", value: "approved_by_name" },
                        { text: "Approved Date", align: "center", value: "approved_date" },
                        { text: "Status", align: "center", value: "status" },
                        { text: "Action", align: "center", value: "action" },
                    ],
                items:[],
                options:{},
                totalItems: 0,
                itemsPerPageOptions: [5, 10, 15, 50, 100],
                itemsPerPage: 10,
            },

            loading:{
                datatable: false,
                button: {
                    filter: false,
                    biometrics: false
                }
            },

            userAccess: {
                view: false,
                create: false,
                edit: false,
                delete: false,
                view_employee_bio_time: false,
                delete_temp_schedule: false
            },

            dropdowns:{
                departments_lists: []
            },

            selected_status: -1,
            isDepartmentView: 0,
            component_dispatches:[
                'getDepartment',
                'getEmployeeScheduleType'
			],
            hr_user_department_access: [],
            statuses: [
                { text: 'all', value: -1 },
                { text: 'pending', value: 1 },
                { text: 'approved', value: 2 },
                { text: 'rejected', value: 3 },
            ]
        }
    },
    mounted(){
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        this.getSearchFromUrl()
        this.getAllDropdowns();
        this.getYearRange('YEAR_RANGE');
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS',
            'GET_TIMEKEEPING_NEW_DATA',
            'USERACCOUNT_LOAD',
            'GET_HR_TAB_IS_ACTIVE',
            'GET_HR_DISPATCH',
            'GET_SELECTED_DATE_RANGE',
            'GET_LIST_DEPARTMENT',
            'GET_HR_USER_DEPARTMENTS',
            'DIALOG',
            'GET_EMPLOYEE_SCHEDULE_TYPE_SELECTION',
            'GET_YEAR_RANGE',
            'GET_FOR_APPROVAL_SCHEDULE'
        ]),
        dateRangeText () {
            if(this.filter.effectivity_date.length == 2){
                return this.filter.effectivity_date.join(' ~ ')
            }
        },
    },
    components:{
    },
    methods:{
        deleteTempSchedule(id){
            Swal.fire({
				icon: 'question',
				text: "Are you sure you want to delete employee schedule?",
				cancelButtonText: "Cancel",
				showCancelButton: true,
				allowOutsideClick: false,
				confirmButtonColor: "#397373",
				cancelButtonColor: "#397373",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store
                    .dispatch(this.GET_HR_DISPATCH.delete, id)
                    .then((res) => {
                        Swal.fire(res.icon.toUpperCase(),res.message,res.icon);
                        this.getForApprovalTemporaryEmployeeSchedule({ query: this.search });
                    });
                }
            });

        },
        selectedStatus(data){
            this.selected_status = data.value
            this.getForApprovalTemporaryEmployeeSchedule({ query: this.search })
        },
        async getForApprovalTemporaryEmployeeSchedule(filter = null){
            this.datatable.items = [];
            this.loading.datatable = true;
            let payload = {
                page            : this.datatable.options.page,
                itemsPerPage    : this.datatable.options.itemsPerPage,
                sortBy          : this.datatable.options.sortBy,
                sortDesc        : this.datatable.options.sortDesc,
                status          : this.selected_status,
                filter          : filter,
                ...this.filter
            };
            await this.$store.dispatch(this.GET_HR_DISPATCH.get, payload).then((response) => {
                this.datatable.items                    = response.data.data;
                this.datatable.totalItems               = response.data.total;
                this.loading.datatable                  = false;
            }).catch((err)=>{
                Swal.fire({
                    title: 'Error',
                    text: 'Network Error',
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
                console.log(err)
            });
        },
        searchInput(){
            this.datatable.options.page = 1
            this.getForApprovalTemporaryEmployeeSchedule({ query: this.search });
        },
        async viewEmployeeSchedule(item){
            await this.$store.dispatch('showEmployeeScheduleTemp', item).then((response) => {
                this.$store.commit("HR_OTHER_TABS_DIALOG_VIEW", response);
                this.$store.commit("DIALOG", true);
                this.$store.commit("ACTION", "View");
            })
        },
        getSearchFromUrl() {
            if (Object.keys(this.$route.query).length != 0) {
                Object.keys(this.$route.query).forEach(e=>{
                if(e != 'for_approval')
                    this.filter.search = atob(this.$route.query[e]);
                })
                this.searchInput();
            }
        },
        extractBiometricsData(){
            this.loading.button.biometrics = true;
            let period = [this.GET_SELECTED_DATE_RANGE.from, this.GET_SELECTED_DATE_RANGE.to]

            // let period = this.getDatesFromRange(this.GET_SELECTED_DATE_RANGE.from, this.GET_SELECTED_DATE_RANGE.to, "array");
            let payload = {
                period: period,
                filter: this.filter
            }
            this.$store.dispatch('getBiometricsData', payload).then((res)=>{
                this.$store.commit('BIOMETRICS_SUMMARY_DIALOG', true)
                this.loading.button.biometrics = false;
                this.resetFilters()
            }).catch((err)=>{
                Swal.fire(
                    "Error",
                    "",
                    "error"
                )
                this.loading.button.biometrics = false;
                this.filter.dialog = false;
                this.resetFilters()
                console.log(err)
            })
        },
        viewEmployeeBioTimeData(data){
            // let period = this.getDatesFromRange(this.GET_SELECTED_DATE_RANGE.from, this.GET_SELECTED_DATE_RANGE.to, "array");
            let period = [this.GET_SELECTED_DATE_RANGE.from, this.GET_SELECTED_DATE_RANGE.to]
            let payload = {
                emp_code: data.emp_code,
                period: period
            }
            this.$store.dispatch('viewEmployeeBioTime', payload).then((res)=>{
                if(res.biometricdata.length != 0){
                    this.$store.commit('EMPLOYEE_BIOMETRICS_DIALOG', true)
                }else{
                    Swal.fire({
                        title: "No Biometrics for the selected period.",
                        text: "",
                        icon: "warning"
                    })
                    return false;
                }
            }).catch((err)=>{
                Swal.fire(
                    "Error",
                    "",
                    "error"
                );
                console.log(err)
            })
        },
        closeEmployeeFilterDialog(){
            this.filter.dialog = false;
            this.resetFilters()
        },
        openEmployeeFilterDialog(){
            this.loading.button.filter = true;
            this.filter.dialog = true;
            this.loading.button.filter = false;
        },
        getAllDropdowns(){
            this.component_dispatches.forEach(el=>{
                this.$store.dispatch(el)
            })
        },
        resetFilters(){
            Object.assign(this.filter, {
                dialog: false,
                department_ids:[],
            })
        },
        deleteEmployeeSchedule(id) {
			Swal.fire({
				icon: 'question',
				text: "Are you sure you want to delete employee schedule?",
				cancelButtonText: "Cancel",
				showCancelButton: true,
				allowOutsideClick: false,
				confirmButtonColor: "#397373",
				cancelButtonColor: "#397373",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store
                    .dispatch(this.GET_HR_DISPATCH.delete, id)
                    .then((res) => {
                        Swal.fire(res.icon.toUpperCase(),res.message,res.icon);
                        this.getForApprovalTemporaryEmployeeSchedule({ query: this.search });
                    });
                }
            });
		},
        closeDateRange(clear = 0){
            if(clear == 1){
                this.filter.date_from_menu = false;
                this.filter.effectivity_date = [];
                this.getForApprovalTemporaryEmployeeSchedule({ query: this.search })
            }else{
                if(this.filter.effectivity_date[0] > this.filter.effectivity_date[1]){
                    this.filter.date_from_menu = false;
                    this.filter.effectivity_date = [];
                    Swal.fire(
                        "Date Range invalid!",
                        "",
                        "warning"
                    )
                    return false
                }
                if(this.filter.effectivity_date.length == 2){
                    this.filter.date_from_menu = false;
                    this.getForApprovalTemporaryEmployeeSchedule({ query: this.search })
                }
            }
        },
        originText(origin){
            switch(origin){
                case 0:
                    return 'schedule'
                case 1:
                    return 'position'
                case 2:
                    return 'department'
            }
        }
    },
    watch:{
        'datatable.options': {
            handler() {
                this.getForApprovalTemporaryEmployeeSchedule({ query: this.search });
            },
            deep: true,
        },
        GET_TIMEKEEPING_NEW_DATA:{
            handler(val){
                if(val){
                    this.getForApprovalTemporaryEmployeeSchedule({ query: this.search });
                    this.$store.commit('TIMEKEEPING_NEW_DATA', false)
                }
            }
        },
        USER_ACCESS: {
            handler(val) {
                if(val != "fail"){
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                this.userAccess.create = false;
                this.userAccess.delete_temp_schedule = false;
                    val.forEach(e => {
                        if (e.actions_code == 'edit') {
                            this.userAccess.edit = true;
                        } else if (e.actions_code == 'delete') {
                            this.userAccess.delete = true;
                        } else if (e.actions_code == 'view') {
                            this.userAccess.view = true;
                        } else if (e.actions_code == 'create') {
                            this.userAccess.create = true;
                        } else if (e.actions_code == 'delete_temp_schedule') {
                            this.userAccess.delete_temp_schedule = true;
                        }
                    });
                }
            }
        },
        GET_LIST_DEPARTMENT:{
            handler(val){
                this.dropdowns.departments_lists = val
            }
        },
        GET_HR_USER_DEPARTMENTS:{
            handler(val){
                this.hr_user_department_access = val;
                let filtered_departments = this.dropdowns.departments_lists.filter(x=> this.hr_user_department_access.includes(x.value) )
                this.dropdowns.departments_lists = filtered_departments
            }
        },
        DIALOGS:{
            handler(val){
                if(val){
                    this.$store.commit("HR_OTHER_TABS_DIALOG_VIEW", {});
                }
            }
        },
        GET_HR_TAB_IS_ACTIVE:{
            handler(val){
                if(val == 'for_approval_temp_schedule'){
                    this.getForApprovalTemporaryEmployeeSchedule({ query: this.search });
                }
            }
        },
        GET_FOR_APPROVAL_COUNT:{
            handler(val){
                this.getForApprovalTemporaryEmployeeSchedule({ query: this.search });
            },
            immediate: true
        }
    }
}
</script>
<style scoped>
    .selected {
        opacity: 100%;
    }

    .non-selected {
        opacity: 40%;
    }

    .custom-loader {
        animation: loader 1s infinite;
        display: flex;
    }
    @-moz-keyframes loader {
        from {
        transform: rotate(0);
        }
        to {
        transform: rotate(360deg);
        }
    }
    @-webkit-keyframes loader {
        from {
        transform: rotate(0);
        }
        to {
        transform: rotate(360deg);
        }
    }
    @-o-keyframes loader {
        from {
        transform: rotate(0);
        }
        to {
        transform: rotate(360deg);
        }
    }
    @keyframes loader {
        from {
        transform: rotate(0);
        }
        to {
        transform: rotate(360deg);
        }
    }

    .badge-schedule{
        padding: .3em .6em .3em;
        font-weight: 500;
        background-color: orange !important;
        color: white !important;
    }
    .badge-position{
        padding: .3em .6em .3em;
        font-weight: 500;
        background-color: rgb(255, 25, 102) !important;
        color: white !important;
    }
    .badge-department{
        padding: .3em .6em .3em;
        font-weight: 500;
        background-color: rgb(200, 116, 255) !important;
        color: white !important;
    }
</style>
