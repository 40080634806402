<template>
  <span>
      <v-btn @click="showQrScannerDialog()">{{type == 'barcode' ? 'Barcode Scan' : 'QR Scan'}}</v-btn>

      <v-dialog v-model="qrScannerDialog" :width="width()" persistent scrollable>
          <v-card>
            <v-row class="m-0">
                <v-col>
                    <v-card-title>
                        <div class="headline">Scan QrCode</div>
                    </v-card-title>
                </v-col>
                <v-col cols="pull-right-10 p-2">
                    <v-btn
                        text
                        icon
                        color="gray"
                        class="float-right"
                        @click="closeQrScannerDialog()"
                        >
                    <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row >
            <v-row v-if="autoOpen" class="m-0" style="padding-left: 20px;">
                <span class="headline" v-text="`${scanner.item_model} : ${scanner.quantity}`"></span>
            </v-row>

            <v-divider class="mt-0" />

            <div>
                <qrcode-stream ref="qrScanner" :camera="openCamera" @decode="onDecode" @init="onInit" />
            </div>
          </v-card>
      </v-dialog>
  </span>
</template>

<script>
// Author: Jimson Lomio, 03/07/2022
// https://gruhn.github.io/vue-qrcode-reader/demos/DecodeAll.html
// https://gruhn.github.io/vue-qrcode-reader/api/QrcodeStream.html#events
// https://github.com/gruhn/vue-qrcode-reader/blob/master/src/components/QrcodeStream.vue

import ViewportCheckerComponentVue from '@/views/main/Shared/ViewportCheckerComponent.vue';

export default {
    mixins: [ViewportCheckerComponentVue],
    data() {
        return {
            openCamera: 'off',
            mt3: false,
            viewportName: this.breakpointName()
        }
    },
    props:[
        'autoOpen',
        'scanner',
        'type',
    ],
    watch: {
    },
    computed: {
        qrScannerDialog() {
            return this.$store.state.service.qrScannerDialog;
        }
    },
    methods: {
        showQrScannerDialog() {
            this.$emit('closeQrScannerDialog', true);
            this.startCamera();
            this.$store.commit('showQrScannerDialog');
        },
        closeQrScannerDialog() {
            this.stopCamera();
            this.$store.commit('closeQrScannerDialog');
        },
        async onDecode (result) {
            if(!!result) {
                this.$emit('scanResult', result);
                if(this.autoOpen){
                    await new Promise(resolve=>{
                        this.stopCamera();

                        //generate sound after scanning
                        var context = new AudioContext()
                        var o = context.createOscillator()
                        o.type = "sine"
                        o.connect(context.destination)

                        o.frequency.value = 415.3

                        o.start()

                        setTimeout(function(){
                            o.stop()
                        },200)

                        resolve()
                    })

                    await new Promise(resolve=>{
                        this.startCamera();
                        resolve()
                    })
                }else{
                    this.stopCamera();
                    this.closeQrScannerDialog();
                }


            }
        },
        startCamera() {
            // const constraints = (window.constraints = {
			// 	audio: false,
			// 	video: true
			// });

            // navigator.mediaDevices
			// 	.getUserMedia(constraints)
			// 	.then(stream => {
			// 		this.$refs.qrScanner.$refs.video.srcObject = stream;
			// 	})

            this.openCamera = 'auto';
        },
        stopCamera() {
            this.openCamera = "off";

            let tracks = this.$refs.qrScanner.$refs.video.srcObject.getTracks();

			tracks.forEach(track => {
				track.stop();
			});
        },
        async onInit (promise) {
            try {
                await promise
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                    swal.fire('','ERROR: you need to grant camera access permission','warning').then(confirm=>{
                        this.closeQrScannerDialog();
                    });
                } else if (error.name === 'NotFoundError') {
                    swal.fire('','ERROR: no camera on this device','warning').then(confirm=>{
                        this.closeQrScannerDialog();
                    });
                } else if (error.name === 'NotSupportedError') {
                    swal.fire('','"ERROR: secure context required (HTTPS, localhost)','warning').then(confirm=>{
                        this.closeQrScannerDialog();
                    });
                } else if (error.name === 'NotReadableError') {
                    swal.fire('','ERROR: is the camera already in use?','warning').then(confirm=>{
                        this.closeQrScannerDialog();
                    });
                } else if (error.name === 'OverconstrainedError') {
                    swal.fire('','ERROR: installed cameras are not suitable','warning').then(confirm=>{
                        this.closeQrScannerDialog();
                    });
                } else if (error.name === 'StreamApiNotSupportedError') {
                    swal.fire('','ERROR: Stream API is not supported in this browser','warning').then(confirm=>{
                        this.closeQrScannerDialog();
                    });
                } else if (error.name === 'InsecureContextError') {
                    swal.fire('','ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.','warning').then(confirm=>{
                        this.closeQrScannerDialog();
                    });
                } else {
                    swal.fire('',`ERROR: Camera error (${error.name})`, 'warning').then(confirm=>{
                        this.closeQrScannerDialog();
                    });
                }
            }
        },
        width() {
            if(this.viewportName == 'xs') {
                return '98%';
            } else if(this.viewportName == 'sm') {
                return '60%';
            }

            return '60%';
        }
    }
}
</script>

<style>

</style>
