<template>
    <div class="pb-4 mt-4" style="width:100%">
        <v-layout row class="px-3">
            <v-flex v-if="drStatus == 'pending'" lg5 class="pr-2">
                <v-card class="pa-2" style="border-radius:0px">
                    <v-card-title>
                        <v-layout>
                            <v-spacer />
                            <v-flex lg6>
                                <v-text-field
                                    v-model="searchCustomer"
                                    append-icon="mdi-magnify"
                                    label="Company Name"
                                    single-line
                                    hide-details
                                    clearable
                                />
                            </v-flex>
                        </v-layout>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                            :headers="customersListHeaders"
                            :items="customersList"
                            :search="searchCustomer"
                        >

                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-flex>

            <v-flex :class="drStatus == 'pending' ? 'lg7' : 'lg12'">
                <v-card class="pa-2" style="border-radius:0px">
                    <div style="text-align:end" class="px-4 pt-2">
                        <v-btn @click="showExportDialog()">Export</v-btn>
                    </div>
                    <v-card-title>
                        <v-layout>
                            <v-spacer />
                            <v-flex lg6>
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    label="DR# / Company Name"
                                    single-line
                                    hide-details
                                    clearable
                                />
                            </v-flex>
                        </v-layout>
                    </v-card-title>
                    <v-card-text>
                        <div>
                            <span style="font-weight:bold">Count: </span><span>{{ deliveryReceipts.length }}</span>
                        </div>
                        <v-data-table
                            :headers="headers"
                            :items="deliveryReceipts"
                            :search="search"
                        >
                        <template v-slot:item.company_name="{ item }">
                            {{ companyName(item) }}
                        </template>
                        <template v-slot:item.delivered_at="{ item }">
                            {{ deliveredDate(item) }}
                        </template>
                        <template v-slot:item.received_at="{ item }">
                            {{ receivedDate(item) }}
                        </template>
                        <template v-slot:item.status="{ item }">
                            <v-chip :color="statusColor(item)" text-color="white" small>
                                {{ item.status }}
                            </v-chip>
                        </template>
                        <template v-slot:item.date="{ item }">
                            <td>{{ date(item) }}</td>
                        </template>
                        <template v-slot:item.created_at="{ item }">
                            <td>{{ agingDate(item.created_at) }}</td>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <td>
                                <span class="text-nonwrap">
                                    <v-btn text icon color="orange">
                                        <v-icon class="btn-action" small @click="showDeliveryReceipt(item)">mdi-eye</v-icon>
                                    </v-btn>
                                    <v-btn v-if="!item.sc_rrf_id && drStatus == 'pending' || drStatus == 'defective'" text icon color="red">
                                        <v-icon class="btn-action" small @click="deleteDeliveryReceipt(item)">mdi-delete</v-icon>
                                    </v-btn>
                                </span>
                            </td>
                        </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>

        <delivery-receipt-dialog
            :drStatus="drStatus"
            :deliveryReceiptId="deliveryReceiptId"
            @closeDrDetailsDialog="closeDrDetailsDialog"
            @successDeliver="successDeliver"
            @successDeleteDrRf="successDeleteDrRf"
        />

        <!-- Export Dialog -->
        <v-dialog v-model="exportDialog" persistent max-width="40%" scrollable>
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Choose Date Range</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeExportDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider />

                <v-layout class="pa-2">
                    <v-flex lg6 class="mr-2">
                        <v-dialog
                            ref="dateStartDialog"
                            v-model="startDateModal"
                            :return-value.sync="startDate"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="startDate"
                                label="Date Start"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="startDate"
                                scrollable
                                :max="minDate"
                            >
                            <v-spacer />
                            <v-btn
                                text
                                color="primary"
                                @click="startDateModal = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.dateStartDialog.save(startDate)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-flex>
                    <v-flex lg6>
                        <v-dialog
                            ref="endDateDialog"
                            v-model="endDateModal"
                            :return-value.sync="endDate"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="endDate"
                                label="Date End"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :disabled="!startDate"
                            />
                            </template>
                            <v-date-picker
                                v-model="endDate"
                                scrollable
                                :min="startDate"
                                :max="minDate"
                            >
                            <v-spacer />
                            <v-btn
                                text
                                color="primary"
                                @click="endDateModal = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.endDateDialog.save(endDate)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-flex>
                </v-layout>

                <v-divider />

                <div style="text-align:center" class="pb-4">
                    <v-btn @click="exportExcel( excelHeadersAndData, exportName)">Export Now</v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import DeliveryReceiptDialog from './DeliveryReceiptDetailsDialog.vue';
import ServiceFunctionsComponentVue from '@/views/main/Shared/ServiceFunctionsComponent.vue';
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue';
import swal from 'sweetalert2';
import { mapGetters } from 'vuex';
export default {
    mixins: [ServiceFunctionsComponentVue, SharedFunctionsComponent],
    data() {
        return {
            headers: [
                { text: 'DR#', value: 'dr_number' },
                { text: 'Customer Name', value: 'company_name' },
                { text: 'Processed By', value: 'processed_by' },
                { text: 'Status', value: 'status' },
                { text: 'Created At', value: 'date' },
                { text: 'Days', value: 'created_at' },
                { text: 'Action', value: 'action' },
            ],
            deliveryReceipts: [],
            deliveryReceiptId: '',
            status: 'pending',
            search: '',
            customersListHeaders: [
                { text: 'Customer Name', value: 'company_name' },
                { text: 'RF Count', value: 'repair_form_count', width: "150px" },
            ],
            customersList: [],
            searchCustomer: "",
            startDateModal: false,
            endDateModal: false,
            startDate: '',
            endDate: '',
            minDate: this.$dayjs().format('YYYY-MM-DD'),
            excelHeadersAndData:{
                Sheet1:{
                    headers:  [
                        {header: 'DR#', key: 'dr_number'},
                        {header: 'Company Name', key: 'company_name', width: 45},
                        {header: 'Customer Name', key: 'customer_name', width: 20},
                        {header: 'RF#', key: 'rf_number'},
                        {header: 'PL#', key: 'packing_list_num'},
                        {header: 'Model', key: 'model', width: 15},
                        {header: 'Serial', key:  'serial', width: 15},
                        {header: 'Replacement Serial', key: 'new_serial', width: 18},
                        {header: 'Status', key: 'status'},
                        {header: 'Date', key: 'created_at', width: 15 },
                        {header: 'Processed By', key: 'processed_by', width: 22},
                        {header: 'Driver Name', key: 'driver_name', width: 22},
                        {header: 'Helper Name', key: 'helper_name', width: 22},
                        {header: 'Vehicle', key: 'vehicle_name', width: 20},
                        {header: 'Vehicle Plate Number', key: 'plate_number', width: 22},
                        {header: 'Guard Name', key: 'guard_name', width: 16},
                    ],
                    data:[]
                },
            },
            exportName: `Service Delivery List - ${this.$dayjs().format('YYYY-MM-DD')}`,
            exportLoading: false
        }
    },
    components: {
        DeliveryReceiptDialog,
    },
    mounted: function() {
        this.getCustomerReadyRfs();
        this.getAllDeliveryReceipts();
    },
    props: ['drStatus', 'createDrSuccess'],
    watch: {
        drStatus() {
            this.getAllDeliveryReceipts();
        },
        createDrSuccess() {
            this.getCustomerReadyRfs();
            this.getAllDeliveryReceipts();
        },
        startDate() {
            if(!!this.startDate) {
                this.endDate = this.minDate;
            }
        },
        endDate() {
            this.getForExportServiceDelivery();
        },
        'excelHeadersAndData.Sheet1.data': {
            handler(val) {
                if(val.length == 0 && !!this.exportDialog) {
                    swal.fire('','No Data Found','warning');
                    this.exportLoading = false;
                } else if(val.length > 0 && !!this.exportDialog){
                    swal.fire('', `${val.length} Data - Found` ,'success');
                }
            }
        }
    },
    computed: {
        ...mapGetters([
            'GET_CUSTOMER_READY_RFS',
            'GET_DELIVERY_RECEIPTS',
            'GET_FOR_EXPORT_SERVICE_DELIVERY',
        ]),
        exportDialog() {
            return this.$store.state.service.exportDialog;
        }
    },
    methods: {
        getCustomerReadyRfs() {

            this.$store.dispatch('getCustomerReadyRfs').then(response=>{
                this.customersList = this.GET_CUSTOMER_READY_RFS;
            });
        },
        getAllDeliveryReceipts() {
            this.$store.dispatch('getDeliveryReceipts',this.drStatus).then(response=>{
                this.deliveryReceipts = this.GET_DELIVERY_RECEIPTS;
            });
        },
        statusColor(dr) {
            if(dr.status == 'delivered') {
                return 'green';
            }

            if(dr.status == 'in-transit') {
                return 'orange';
            }

            return 'red';
        },
        showDeliveryReceipt(item) {
            this.deliveryReceiptId = item.id;
            this.$store.commit('showDeliveryReceiptDetailsDialog');
        },
        deleteDeliveryReceipt(item) {
            swal.fire({
                title: 'Are you sure you want to submit?',
                text: 'Make sure that all information are correct',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then((result)=>{
                if(result.isConfirmed) {
                    let payload = {
                        deliveryId: item.id
                    }
                    this.$store.dispatch('deleteDeliveryReceipt',payload).then(response=>{
                        if(!!response.data.msg) {
                            this.getCustomerReadyRfs();
                            this.getAllDeliveryReceipts();
                            swal.fire("", response.data.msg, "success");
                        }
                    });
                }
            });
        },
        deliveredDate(dr) {
            if(!!dr.delivered_at) {
                return this.$dayjs(dr.delivered_at).format('YYYY-MM-DD, hh:mm:ss a');
            }
            return '';
        },
        receivedDate(dr) {
            if(!!dr.received_at) {
                return this.$dayjs(dr.received_at).format('YYYY-MM-DD, hh:mm:ss a');
            }
            return '';
        },
        successDeliver(confirm) {
            if(confirm) {
                this.getCustomerReadyRfs();
                this.getAllDeliveryReceipts();
            }
        },
        successDeleteDrRf(confirm) {
             if(confirm) {
                this.getCustomerReadyRfs();
                this.getAllDeliveryReceipts();
            }
        },
        closeDrDetailsDialog(confirm) {
            if(confirm) {
                this.deliveryReceiptId = '';
                this.getCustomerReadyRfs();
                this.getAllDeliveryReceipts();
            }
        },
        companyName(item) {
            if(this.drStatus == 'pending' || this.drStatus == 'in-transit' || this.drStatus == 'delivered') {
                return !!item.customer ? item.customer.company_name : '';
            }

            if(this.drStatus == 'defective' || this.drStatus == 'def-in-transit' || this.drStatus == 'def-delivered') {
                return !!item.sc_location ? item.sc_location.name : '';
            }
        },
        showExportDialog() {
            this.getForExportServiceDelivery();
            this.$store.commit('showExportDialog');
        },
        startDownload() {
            this.exportLoading = true;
        },
        finishDownload() {
            this.exportLoading = false;
        },
        getForExportServiceDelivery() {
            let payload = {
                status: this.drStatus,
                startDate: this.startDate,
                endDate: this.endDate,
            }
            this.$store.dispatch('getForExportServiceDelivery',payload).then(response=>{
                let exportData = this.GET_FOR_EXPORT_SERVICE_DELIVERY;
                exportData.forEach( e => e.created_at = this.formatDate(e.created_at))
                this.excelHeadersAndData.Sheet1.data = exportData;
            });
        },
        closeExportDialog() {
            this.startDate = "";
            this.endDate = "";
            this.exportLoading = false;
            this.$store.commit('closeExportDialog');
        },
        exportNow() {
            console.log('export')
        },
        date(item) {
            if(!!item.created_at) {
                return this.$dayjs(item.created_at).format('YYYY-MM-DD, hh:mm:ss a');
            }

            return '';
        }
    }
}
</script>

<style>

</style>
