<template>
  <v-container fluid>
    <h3 class="page-title">Job Order</h3>
    <v-container class="ma-0">
      <v-app id="item-tabs">
        <v-tabs
          v-model="tab"
          id="group-tab"
          color="cyan"
          slider-color="cyan"
          background-color="#424242"
          dark
          show-arrows
        >
          <v-tabs-slider color="cyan"></v-tabs-slider>
          <!-- <v-tab
          @click="tabs(0)"
            ripple>
            All
          </v-tab> -->
          <v-tab
            ripple
            v-for="(item, i) in STATUS.ADMIN_JOB_ORDER_STATUS"
            :key="i"
            @click="tabs(item.value);components_index = 0"
          >
            {{ item.text }}
          </v-tab>
          <v-spacer></v-spacer>
          <v-tab  @click="()=>{
                components_index = 3
            }">
              WFR
          </v-tab>
          <v-tab  @click="()=>{
                components_index = 1
            }">
              LOCATION
          </v-tab>
        </v-tabs>
        <v-card>
            <v-card-text class="p-0">
              <JobOrderComponent :status="tab" v-if="components_index == 0"></JobOrderComponent>
              <AdminDeliveryAddressesComponent v-if="components_index == 1"/>
              <WaitingForReceivingComponent v-if="components_index == 3" :module="'Admin Job Order'"/>
            </v-card-text>
        </v-card>
      </v-app>
    </v-container>
    <!-- <DialogComponent
      :cp="components"
      :scrollable="dialog_scrollable"
      :width="width(dialog_width)"
      :retainfocus="false"
    ></DialogComponent> -->

  </v-container>
</template>
<script>
import DialogComponent from "@/views/main/layouts/dialogs/Dialog.vue";
import JobOrderComponent from "@/views/main/modules/Admin/JobOrder/JobOrderComponent.vue";
import JobOrderViewComponent from "@/views/main/layouts/dialogs/Admin/JobOrderViewComponent.vue"
import JobOrderEquipmentComponentVue from './JobOrderEquipmentComponent.vue';
import AdminDeliveryAddressesComponent from "@/views/main/modules/Admin/AdminDeliveryAddresses/AdminDeliveryAddressesComponent.vue";
import WaitingForReceivingComponent from '@/views/main/modules/Accounting/AccountsPayable/WaitingForReceivingComponent.vue';
import { mapGetters } from "vuex";
export default {
  components: {
    DialogComponent,
    JobOrderComponent,
    JobOrderEquipmentComponentVue,
    JobOrderViewComponent,
    AdminDeliveryAddressesComponent,
    WaitingForReceivingComponent
  },
  data() {
    return {
      components_index:0,
      form: {
        code: "",
        name: "",
        sub_class: [],
        sub_class_trash: [],
      },
      components: "",
      items: [
        { tab_name: "ALL", view: true },
        // { tab_name: "PENDING", view: true },
        // { tab_name: "FOR PAYMENT", view: true},
        // { tab_name: "APPROVED", view: true },
        // { tab_name: "PAID", view: true },
        // { tab_name: "PARTIAL", view: true },
      ],
      jo_tabs_items: {
        components: JobOrderComponent,
        dialog: JobOrderViewComponent,
        dialog_title: "Job Order Form",
        dialog_width: "100%",
        scrollable: true,
        dispatch: {
          get: "getAllJO",
          add: "CreateJO",
          update: "UpdateJO",
          delete: "DeleteJO",
        },
      },
      dialog_width: "",
      loaded: false,
      options: {},
      itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
      itemsPerPage: 10,
      tab: null,
      dialog_scrollable: true,
    };
  },
  mounted() {
    this.dropDowns();
    this.tabs(0);
    this.tabNameSorting()
    // this.items.push(...this.STATUS.ADMIN_JOB_ORDER_STATUS.map(status => {
    //       const temp = {
    //         tab_name: status.text,
    //         view: true,
    //       }
    //       return temp;
    //     }));
  },
  computed: {
    ...mapGetters([
            "STATUS",
        ]),
  },
  methods: {
    dropDowns() {
      this.$store.dispatch("getDepartment", {get_user_department: 1});
      this.$store.dispatch('getAdminDeliveryAddressSelection');
      // this.$store.dispatch('getAllRRFNum');
      this.$store.dispatch("repairCategorySelection");
      this.$store.dispatch("repairTypeSelection");
      this.$store.dispatch("getVehicle");
      this.$store.dispatch('getJOVendorDetails');
      this.$store.dispatch('getAdminItemUomSelection');
    },
    tabs(key) {
      // this.components = this.jo_tabs_items.dialog;
      // this.dialog_width = this.jo_tabs_items.dialog_width;
      // this.dialog_scrollable = this.jo_tabs_items.scrollable;
      // this.$store.commit("JO_DISPATCH_STATUS", this.$store.commit('SELECTED_TAB',this.STATUS.ADMIN_JOB_ORDER_STATUS[val].value));
      // this.$store.commit("JO_DISPATCH", this.jo_tabs_items.dispatch);
      if (key < (this.STATUS.ADMIN_JOB_ORDER_STATUS.length + 1)) {
        this.$store.commit('SELECTED_TAB',key)
      }
    },
    width(width) {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%";
        case "sm":
          return "100%";
        case "md":
          return width;
        case "lg":
          return width;
        case "xl":
          return width;
      }
    },
    tabNameSorting(){
       return this.STATUS.ADMIN_JOB_ORDER_STATUS.sort((a, b) => a.series - b.series)
    }
  },
  watch: {


  },
};
</script>
  <style>
#group-tab {
  padding: 0 !important;
}
</style>
