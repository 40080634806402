<template>
    <v-card class="pa-2">
        <v-form ref="form" v-model="valid">
            <v-row class="m-0">
                <v-col cols="10">
                    <v-card-title>
                        <span>Sample Form Log</span>
                    </v-card-title>
                </v-col>
                <v-col cols="2">
                    <v-btn text icon color="gray" class="float-right" @click="closeReturnFormDialog(); resetFields();">
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-container class="pa-2">
            </v-container>
            <v-card-actions class="text-center" style="border-top:1px solid #dee2e6; padding-top: 10px;" >
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import swal from 'sweetalert2';

export default {
    components: {
    },
    data() {
        return {
            valid:false,
            itemsPerPageOptions: [5, 10, 15],
            itemsPerPage: 15,
            search: '',
            userAccess: {
                view: true,
                create: true,
                edit: true,
                delete: true
            },
            batch_addition: {
                dialog: false,
                data: [],
                excel_item_id: null,
                errors: [],
            },
            form:{
                salesman_id:'',
                returnFormItems: [],
                remarks: '',
                return_qty:'',
                status:0
            },
            dialog: false,
            max_adjustment_items_count: 999,
            dropdowns:{
                sample_form_items: [],
                inventory_items: [],
                sample_form_selection: [],
                salesman_selection: []
            },
            rules: {
                required: [ value => !!value || 'Required.' ],
            },
            loading:{
                sample_form_selection: true,
                salesman_selection : true
            },
            item_details:'',
            item_data:{},
            icon: 'justify',
            component_dispatches : [
                'getSalesman',
                'getSampleFormSelection'
            ],
            override: {
                override_dialog: false,
                authenticated:false,
                override_user_password:"",
                errors: [],
                override_departments_allowed: ['SM', 'EX', 'IT'],
                override_authenticated: false,
                type:"",
                isPrint:false
            },
            url_details:{
                dispatch:"uploadfilesSF",
                api:"uploadfilesRF"
            },
             file_viewer_url_details:{
                dispatch:"getUploadFiles",
                api:"getRFUploadedFiles"
            }
        }
    },
    created(){
        this.initialForm();
    },
    mounted() {
        this.getInventoryItems()
    },
    methods: {
        getallDropdowns(){
            this.component_dispatches.forEach(e=>{
                this.$store.dispatch(e);
                this.loading.sample_form_selection = true;
                this.loading.salesman_selection = true
            })
        },
        initialForm(){
            this.form = {
                salesman_id:'',
                returnFormItems: [],
                remarks: '',
            }
        },
        getInventoryItems() {
            let payload = {
                url: 'getAllInventoryItems'
            }
            this.$store.dispatch('getInventorySampleFormItems', payload).then(response => {
                this.dropdowns.inventory_items = this.GET_SAMPLE_FORM_ITEMS
            })
        },
        closeReturnFormDialog() {
            this.$store.commit("DIALOG", false);
            this.clearFileUpload();
        },
        submitReturnForm() {
            this.$refs.form.validate();
            delete this.form.id;
            if (this.form.returnFormItems.length > 0 && this.form.salesman_id) {
                swal.fire({
                    title: 'Are you sure you want to Return?',
                    text: 'Make sure that all information are correct',
                    icon: 'question',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    allowOutsideClick: false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor: 'grey',
                    confirmButtonText: 'Confirm',
                }).then((result) => {
                    if (result.isConfirmed) {
                        let payload = {
                            url: 'addUpdateReturnForm',
                            id: this.form.id ?? 0,
                            salesman_id: this.form.salesman_id,
                            remarks: this.form.remarks,
                            return_items: this.form.returnFormItems,
                            sample_form_id:this.form.sample_form_id
                        }
                        this.$store.dispatch(this.GET_SF_DISPATCH.upsert, payload).then(response => {
                            swal.fire({
                                icon: response.data.icon,
                                title: response.data.icon.toUpperCase(),
                                text: response.data.msg,
                            })
                            const data = new FormData();
                            data.append("id",response.data.id);

                            if (!!this.form.files && this.form.files.attachments.length > 0) {
                                let files = this.form.files.attachments;
                                let payload = {
                                    url:"uploadfilesRF",
                                    data: data,
                                    config: this.form.files.config,
                                };
                                for (let i = 0; i < _.compact(files).length; i++) {
                                    data.append("files[]", files[i]);
                                }
					            this.$store.dispatch('uploadfilesSF',payload)
                            }
                            this.resetFields()
                            this.$store.commit("DIALOG", false);
                        })
                    }
                })
            }
            else {
                swal.fire(
                    '',
                    'Please Fill Required Fields',
                    'warning'
                )
            }
        },
        resetFields() {
            this.errors = [];
            this.form.returnFormItems = [];
            this.form.salesman_id = "";
            this.form.remarks = "";
        },
        addSampleFormItem() {
            if (this.form.returnFormItems.length < this.max_adjustment_items_count) {
                this.form.returnFormItems.push({
                    item_model: '',
                    item_name: '',
                    categories: '',
                    uom: '',
                    types: '',
                    borrow_qty: 0,
                    remain_stocks: '',
                })
            }
        },
        validateReturnQty(i,data){
            if(this.form.returnFormItems[i].return_qty > this.form.returnFormItems[i].remaining_qty) {
                swal.fire({
                    title: 'Invalid Quantity',
                    text: 'Return Qty must not be greater than Remaining Qty',
                    icon: 'warning',
                    allowOutsideClick: false,
                })
                this.form.returnFormItems[i].return_qty = 0;
                return false;
            }
            // this.form.returnFormItems[i].qr_code_base64 = null;
            // if(this.actions == 'Create'){
            //     // console.log(this.form.returnFormItems[i].return_qty)
            //     // console.log(this.item_data);
            //     // this.item_data
            //     this.item_data.return_qty = this.form.returnFormItems[i].return_qty;
            //     this.generateQR(this.item_data,i)
            // }
        },
        closeBatchAdditionDialog() {
            this.batch_addition.dialog = false;
        },
        // validateManualAddItem(i){
        //     let payload = {
        //         item_id: this.form.returnFormItems[i].item_id,
        //         manualAdd: true,
        //         url: 'getSelectedInventoryItem'
        //     }
        //     this.$store.dispatch('getSelectedItems', payload).then(response => {
        //         this.item_data = response.data
        //         this.form.returnFormItems[i].item_model = this.form.returnFormItems[i].item_id
        //         this.form.returnFormItems[i].item_name = this.item_data.name
        //         this.form.returnFormItems[i].item_categories = this.item_data.item_categories
        //         this.form.returnFormItems[i].item_uom = this.item_data.item_uom
        //         this.form.returnFormItems[i].item_types = this.item_data.item_types
        //         this.form.returnFormItems[i].remain_stocks = this.item_data.virtual_qty
        //         // this.generateQR(this.item_data,i)
        //     })
        // },
        openBatchDialog() {
            this.batch_addition.dialog = true;
        },
        closeAddEditDialog() {
            this.$store.commit('DIALOG', false);
        },
        removeAdjustmentItemByIndex(index) {
            if (this.form.returnFormItems.length > 1) {
                this.form.returnFormItems.splice(index, 1);
            }
            else if (this.form.returnFormItems.length == 1) {
                this.form.returnFormItems.push({
                    item_id: '',
                    item_name: '',
                    qty: '',
                })
            }
        },
        getAllData(){
            this.form.id                    = this.GET_VIEW_DATA.id;
            this.form.salesman_id           = this.GET_VIEW_DATA.return_by_id;
            this.form.status                = this.GET_VIEW_DATA.status;
            this.form.sample_form_id        = this.GET_VIEW_DATA.sample_form_id;
            this.form.returnFormItems       = this.GET_VIEW_DATA.return_form_items
            this.form.return_date           = this.GET_VIEW_DATA.return_date
            this.form.sample_form_num       = this.GET_VIEW_DATA.sample_form_num
            this.form.return_form_num       = this.GET_VIEW_DATA.return_form_num
            this.form.approve_by_name       = this.GET_VIEW_DATA.approve_by_name
            this.form.returned_by_name      = this.GET_VIEW_DATA.returned_by_name
            this.form.printed_pdf           = this.GET_VIEW_DATA.printed_pdf
        },
        changeReturnFormStatus(status){
            let payload = {
                url                         : 'changeStatusReturnForm',
                return_form_id              : this.form.id,
                return_form_items           : this.form.returnFormItems,
                status                      : status,
                sample_form_id              : this.form.sample_form_id,
            }

            this.$store.dispatch(this.GET_SF_DISPATCH.changestatus,payload).then((res)=>{
                swal.fire({
                    title   : res.data.icon.toUpperCase(),
                    text    : res.data.msg,
                    icon    : res.data.icon
                })
            }).catch((err)=>{
                console.log(err)
            })
        },
        resetInventoryItemsSelection(){
            this.dropdowns.inventory_items.forEach(el=>{
                el.isDisabled = false
            })
        },
        // setSampleFormItems() {
        //     console.log(this.GET_SAMPLE_FORM_SELECTION);
        //     let returnFormItems = this.GET_SAMPLE_FORM_SELECTION.find(obj => obj.id == this.form.sample_form_id).sample_form_items ?? [];
        //     let salesman_id = this.GET_SAMPLE_FORM_SELECTION.find(obj => obj.id == this.form.sample_form_id).salesman_id ?? '';
        //     // Loop through each item in the returnFormItems array and set the qr_code_base64 property to null
        //     // returnFormItems.forEach(item => {
        //     //     item.qr_code_base64 = null;
        //     // });
        //     this.form.salesman_id = salesman_id

        //     this.form.returnFormItems = returnFormItems;
        // },
        setSampleFormItems() {
            this.form.returnFormItems = [];
            this.form.salesman_id = '';

            let selectedSampleForm = this.GET_SAMPLE_FORM_SELECTION.without_completed.find(obj => obj.id === this.form.sample_form_id);

            if (selectedSampleForm) {
                let returnFormItems = selectedSampleForm.sample_form_items ?? [];
                let salesman_id = selectedSampleForm.salesman_id ?? '';

                // Loop through each item in the returnFormItems array and set the qr_code_base64 property to null
                // returnFormItems.forEach(item => {
                //     item.qr_code_base64 = null;
                // });

                this.form.salesman_id = salesman_id;
                this.form.returnFormItems = returnFormItems;
            }
        },
        filterSampleFormNumber(){
            let filtered_sf_number = this.GET_SAMPLE_FORM_SELECTION.without_completed.map(obj => {
                if(obj.salesman_id == this.form.salesman_id){
                    return obj
                }
            });
            this.dropdowns.sample_form_selection = filtered_sf_number;
            if(filtered_sf_number[0] == undefined){
                this.resetSampleFormSelection();
                swal.fire({
                    title   :"Warning",
                    text    :"No Sample Form Found For Selected Salesman",
                    icon    :"warning"
                }).then((res)=>{
                    if(res.isConfirmed){
                        this.initialForm();
                        this.$refs.form.resetValidation()
                    }
                })
            }
        },
        async renderPDF(pdfAction) {
            var doc = new this.$jspdf("p", "mm", "letter");
            let table_height = 0 ;
            doc.setFont(undefined, 'bold').setFontSize(13).text(162, 13, "Return Form")
            doc.setFont(undefined, 'bold').setFontSize(11).text(162, 18, "RF#: " +this.form.return_form_num)
            doc.setFont(undefined, 'bold').setFontSize(11).text(162, 23, "SF#: " +this.form.sample_form_num)
            doc
                .setFont(undefined, "bold")
                .setFontSize(13)
                .text(6, 13, "INGCOPH Traders Inc.\n");


            doc.setFont(undefined, 'normal').setFontSize(10).text(6, 18, "420 Del Monte Ave, Brgy. Siena, Quezon City \nCell No.: +63 935 713 2293 / +63 917 170 0979 / +63 919 078 0016 \nTel. No.: +63 2 8727 1771 / +63 2 8535 9968 / +63 2 7091 4539")

            // let customer_details_top = this.service ? 50 : 37;
            let customer_details_top = 50 ;

            doc.autoTable({
                html: "#customer_details",
                theme: "plain",
                margin: { top: customer_details_top, left: 6, right: 6 },
                columnStyles: {
                0: { cellWidth: 20 },
                1: { cellWidth: 134 },
                2: { cellWidth: 30 },
                3: { cellWidth: 25 },
                },
            });

                doc.autoTable({
                html: "#items_details",
                startY: doc.autoTable.previous.finalY + 3,
                margin: { left: 6, right: 6 },
                theme: "grid",
                headStyles: {
                    halign: "center",
                    fillColor: "#ffffff",
                    textColor: "#000000",
                    lineWidth: 0.2,
                    lineColor: "#000000",
                },
                columnStyles: {
                    0: {  halign: "center", textColor: "#000000" },
                    1: {  halign: "center", textColor: "#000000" },
                    2: {  halign: "center", textColor: "#000000" },
                    3: {  halign: "center", textColor: "#000000" },
                    4: { halign: "center", textColor: "#000000" },
                    5: {  halign: "center", textColor: "#000000" },
                    6: {  halign: "center", textColor: "#000000" },
                },
                bodyStyles: {
                    lineColor: "#000000",
                },
                didDrawPage:(d) => table_height = d.cursor.y,
                });


            if(table_height > 200){
                doc.addPage()
            }


            // var footer_y = doc.autoTable.previous.finalY + 3;
            var footer_y =  240;

            doc.autoTable({
                html: "#footer",
                theme: "plain",
                startY: footer_y,
                margin: { left: 6, right: 6 },
                columnStyles: {
                    0: { cellWidth: 40, halign: "center", textColor: "#000000" },
                    1: { cellWidth: 40, halign: "center", textColor: "#000000" },
                },
                bodyStyles: {
                    lineColor: "#000000",
                },
                didDrawCell: function (data) {
                    doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height, "S");
                },
            });

            let finalY = doc.lastAutoTable.finalY; // The y position on the page
            const totalPages = doc.internal.getNumberOfPages();
            for (let i = 1; i <= totalPages; i++) {
                doc.setPage(i); // Set the current page
                let pageNumber = "Page " + i + " of " + totalPages;
                doc.setFontSize(7);
                doc.setTextColor("#000000");
                doc.text(190, finalY, pageNumber);
            }

            if (pdfAction == "download") {
                doc.save("Return Form.pdf");
            } else if (pdfAction == "print") {
                doc.autoPrint();
                this.setPrint('pdf')
                window.open(doc.output("bloburl"), "_blank");
            }
        },
        setPrint(type){
            let payload = {
                id: this.form.id,
                url: 'printReturnForm',
                type: type
            }
            this.$store.dispatch('printReturnForm',payload).then(response=>{
                swal.fire({
                    icon: response.data.icon,
                    title: response.data.icon.toUpperCase(),
                    text: response.data.msg,
                })
            }).catch((err)=>[
                console.log(err)
            ])
        },
        showOverride(print){
            if(this.form.printed_pdf == 1){
                this.override.override_dialog = true;
                this.override.action = print
            }
            else{
                this.renderPDF(print)
            }
        },
        uploadedData(data) {
            if (!data) {
                this.form.files = null;
            }
            this.form.files = data;
        },
        clearFileUpload(confirm) {
            if (confirm) {
                this.form.files = null;
            }
            this.form.files = null;
            this.$refs.fileUpload.reset();
        },
        resetSampleFormSelection(){
            this.dropdowns.sample_form_selection    = this.GET_SAMPLE_FORM_SELECTION.without_completed;
        },
        resetSalesmanSelection(){
            this.dropdowns.salesman_selection       = this.GET_SALESMAN_LIST;
        },
        confirmedOverridePrint() {
             if (!this.override.override_user_password) {
                this.override_user_id = -1
                this.override.override_dialog = false
            } else {
                let payload = {
                    departments_allowed:        this.override.override_departments_allowed,
                    override_user_password:     this.override.override_user_password,
                }
                this.$store.dispatch('confirmOverride',payload).then(response => {
                    let data = this.GET_CONFIRM_OVERRIDE

                    this.override.override_user_password = ''

                    if (data.error) {
                        this.override_user_id = -1
                        swal.fire("", data.error, "warning")
                        return false
                    }

                    this.override_user_id = data.user_id
                    this.override.override_authenticated = true
                    if (this.override.override_authenticated) {
                        this.override.override_dialog = this.override.override_authenticated;
                        this.renderPDF(this.override.action)
                    } else {
                        this.override.override_dialog = this.override.override_authenticated;
                    }
                }).catch(error => {
                    console.log(error)
                })
            }
        },
        closeOverrideDialog(){
            this.override.override_dialog = false
        }
    },
    computed: {
        ...mapGetters([
            'GET_SALESMAN_LIST',
            'GET_SAMPLE_FORM_ITEMS',
            'GET_VIEW_DATA',
            'ACTION',
            'GET_SF_DISPATCH',
            'DIALOGS',
            'GET_SAMPLE_FORM_SELECTION',
            'GET_CONFIRM_OVERRIDE'
        ]),
        actions(){
            if(this.ACTION == 'Submit'){
                this.dropdowns.sample_form_selection = this.GET_SAMPLE_FORM_SELECTION.without_completed
                return 'Create'
            }
            else if(this.ACTION == 'View'){
                this.getAllData();
                this.dropdowns.sample_form_selection = this.GET_SAMPLE_FORM_SELECTION.with_completed

                return 'View'
            }
            else if(this.ACTION == 'Update'){
                this.getAllData();
                this.dropdowns.sample_form_selection = this.GET_SAMPLE_FORM_SELECTION.with_completed

                return 'Update'
            }
            else if(this.ACTION == 'Approve'){
                this.getAllData();
                this.dropdowns.sample_form_selection = this.GET_SAMPLE_FORM_SELECTION.with_completed
                return 'Approve'
            }
        }
    },
    watch: {
        GET_SAMPLE_FORM_ITEMS: {
            handler(val){
            }
        },
        DIALOGS:{
            handler(val){
                if(val){
                    this.getallDropdowns();
                    this.initialForm();
                    this.$refs.form.resetValidation()
                }
            }
        },
        // GET_SAMPLE_FORM_SELECTION:{
        //     handler(val){
        //         if(val){
        //             // Remove the ID Property for each sample form items
        //             this.dropdowns.sample_form_selection = val;
        //             this.dropdowns.sample_form_selection.forEach(e=>{
        //                 e.sample_form_items.forEach((x)=>{
        //                     delete x.id
        //                 })
        //             });
        //         }
        //     }
        // },
        GET_SALESMAN_LIST:{
            handler(val){
                this.dropdowns.salesman_selection = val;
                this.loading.salesman_selection = false;
            }
        },
        'form.salesman_id':{
            handler(val){
                if(val == null){
                    this.resetSampleFormSelection();
                }
            }
        },
        'dropdowns.sample_form_selection':{
            handler(val){
                if(val){
                    val.forEach(e=>{
                        e.sample_form_items.forEach((x)=>{
                            delete x.id
                        })
                    });
                    this.loading.sample_form_selection = false
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
