<template>
    <div class="w-100">
        <v-app id="my_vue_app">
            <v-card>
                <v-card-title>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" sm="8" class="d-flex justify-content-start"></v-col>
                        </v-row>
                    </v-container>
                </v-card-title>
                    <v-container fluid>
                        <v-data-table
                            :headers="headers"
                            :items="items"
                            :search="search"
                            :options.sync="options"
                            :server-items-length="totalItems"
                            :items-per-page="10"
                            :loading="!loaded"
                            :footer-props="{
                                itemsPerPageOptions: itemsPerPageOptions,
                            }"
                            loading-text="Loading... Please wait"
                            fixed-header
                        >
                        </v-data-table>
                    </v-container>
            </v-card>
        </v-app>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
        loaded: false,
        totalItems: 0,
        options: {},
        items: [],
        itemsPerPageOptions: [5, 10, 15, 50, 100],
        itemsPerPage: 10,
        search: "",
        filter:{
            search:"",
        },
        awaitingSearch: false,
        headers: [],
        count:0,
        userAccess: {
            view: false,
            create: false,
            edit: false,
            delete: false,
        },
        };
    },
    computed:{
        ...mapGetters([
            "USER_ACCESS",
            "GET_ADM_INVENTORY_DISPATCH",
            "GET_ADM_INVENTORY_CODE"
        ]),
    },
    mounted() {
        this.$store.dispatch(
        'checkAccessUser',
        this.$router.currentRoute.params.id
        );
    },
    methods: {
        async getAllAdminInventory(filter = null) {
            this.items = [];
            this.loaded = false;
            let payload = {
                page:           this.options.page,
                itemsPerPage:   this.options.itemsPerPage,
                search:         this.filter.search,
                sortBy:         this.options.sortBy,
                sortDesc:       this.options.sortDesc,
                code:           this.GET_ADM_INVENTORY_CODE.tab_name,
            };
            this.$store.dispatch(this.GET_ADM_INVENTORY_DISPATCH.get, payload)
            .then((response) => {
                    this.items =response.data.data;
                    this.loaded = true;
                    this.totalItems = response.data.total;
                    this.headers = [
                        { text: "PO #", align: "left", value: "po_num" },
                        { text: "DESCRIPTION", align: "left", value: "description" },
                        { text: "CLASS", align: "left", value: "classification_code" },
                        { text: "UOM", align: "left", value: "uom_code" },
                        { text: "QUANTITY", align: "left", value: "quantity", divider: true },
                    ];
                }).catch((e)=>{
                    this.loaded = true;
            });
        },
        searchInput(){
        this.getAllAdminInventory({ query: this.search });
        },
    },
    watch: {
        options: {
            handler() {
                this.getAllAdminInventory();
            },
            deep: true,
        },
        USER_ACCESS: {
            handler(val) {
                if (val != "fail") {
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                this.userAccess.create = false;
                val.forEach((e) => {
                    if (e.actions_code == "edit") {
                    this.userAccess.edit = true;
                    } else if (e.actions_code == "delete") {
                    this.userAccess.delete = true;
                    } else if (e.actions_code == "view") {
                    this.userAccess.view = true;
                    } else if (e.actions_code == "create") {
                    this.userAccess.create = true;
                    }
                });
                }
            },
        },
    },
};
</script>
