<template>
    <v-container fluid>
      <ComponentHeader
        class="ma-n4"
        :button="true"
        title='Sales Incentives'
        type='Accounting'
      >
    </ComponentHeader>
        <v-row>
            <v-app id="my_vue_app" style="width:100%" class="mx-4 bg-bluish-gray pa-3 px-5">
                <v-row class="p-2">
                    <v-col lg="5">
                        <v-autocomplete
                            v-model="salesman"
                            :items="GET_SALESMAN_SELECTION"
                            outlined
                            placeholder="Select Salesman"
                            dense
                            background-color="white"
                            label="Select Salesman"
                        >
                        </v-autocomplete>
                    </v-col>


                    <v-spacer />
                    <v-col sm="12" md="4" class="text-right">
                        <v-btn small class="ml-1" @click="resetFields()">Clear</v-btn>
                        <v-btn small class="ml-1" @click="exportToExcel()">Excel</v-btn>
                        <v-btn small class="ml-1" @click="getSalesIncentives()">Filter</v-btn>
                    </v-col>
                </v-row>

                <v-row class="p-2">
                    <v-col sm="2" md="1">
                        <v-btn readonly color="#FFFFFF" @click="getSelectedMode('month')" medium>
                            <div class="text-dark pa-0 ma-0" style="font-size: 25px"> Month </div>
                        </v-btn>
                    </v-col>
                    <v-col sm="12" md="2" class="d-flex flex-row">
                        <v-menu
                            ref="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="selectedRange.from"
                                    v-on="on"
                                    label="Date From"
                                    dense
                                    outlined
                                    hide-details
                                    readonly
                                    background-color="white"
                                />
                            </template>
                            <v-date-picker
                                v-model="selectedRange.from"
                                type="month"
                                no-title
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col sm="12" md="2" class="d-flex flex-row">
                        <v-menu
                            ref="menus"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="selectedRange.to"
                                    v-on="on"
                                    label="Date To"
                                    dense
                                    outlined
                                    hide-details
                                    readonly
                                    background-color="white"
                                />
                            </template>
                            <v-date-picker
                                v-model="selectedRange.to"
                                type="month"
                                no-title
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-app>
        </v-row>

        <v-row>
            <v-app id="my_vue_app" style="width:100%" class="mx-4 bg-bluish-gray px-5">
                <v-row dense class="my-2">
                    <v-col cols="12">
                        <v-card>
                            <v-card-text>
                                <v-data-table
                                    :headers="dataTable.headers"
                                    :items="dataTable.items"
                                    :loading="dataTable.loading"
                                    :options.sync="dataTable.options"
                                    :footer-props="{
                                        itemsPerPageOptions: itemsPerPageOptions,
                                    }"
                                    dense
                                    :server-items-length="dataTable.totalItems"
                                >
                                    <template v-slot:[`item.cluster`]="{ item }">
                                        <span class="text-nowrap">
                                             {{ cluster(item) }}
                                        </span>
                                    </template>

                                    <template v-slot:[`item.sub_cluster`]="{ item }">
                                        <span class="text-nowrap">
                                             {{ subCluster(item) }}
                                        </span>
                                    </template>

                                    <template v-slot:[`item.total_sales`]="{ item }">
                                        <span class="text-nowrap">
                                            ₱ {{ item.total_sales | currency }}
                                        </span>
                                    </template>

                                    <template v-slot:[`item.gross_amount`]="{ item }">
                                        <span class="text-nowrap">
                                            ₱ {{ item.gross_amount | currency }}
                                        </span>
                                    </template>

                                    <template v-slot:[`item.total_amount`]="{ item }">
                                        <span class="text-nowrap">
                                            ₱ {{ item.total_amount | currency }}
                                        </span>
                                    </template>

                                    <template v-slot:[`item.paid_amount`]="{ item }">
                                        <span class="text-nowrap">
                                            ₱ {{ item.paid_amount | currency }}
                                        </span>
                                    </template>

                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-app>
        </v-row>
    </v-container>
</template>

<script>
import SharedFunctionsComponent from "@/views/main/Shared/SharedFunctionsComponent.vue";
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
import { mapGetters } from 'vuex';
import swal from "sweetalert2";

export default {
    mixins: [
        SharedFunctionsComponent,
    ],
    data() {
        return {
            salesman: [],
            week_range: [],
            week: {
                year: this.$dayjs().year(),
                start: this.$dayjs().weekYear(),
                end: 0
            },
            year_selected: new Date().getFullYear() + "-" + new Date().getMonth(),
            date: {
                month: '',
                year: new Date().getFullYear()
            },
            filters: {
                filtered_year: this.$dayjs().year(),
                filtered_month: 0,
                status_value: 10,
                status_text: "all"
            },
            rangeSelectDialog: false,
            year_range: {
                start: this.$dayjs().year(),
                end: this.$dayjs().year(),
            },
            componentKey: 0,
            dateFrom: null,
            dateTo: null,
            weekCount: '',
            mode: 'month',
            activeSubTab: 'year',
            yearRange: [],
            selectedRange: {
                from: this.$dayjs().startOf('month').format('YYYY-MM'),
                to: this.$dayjs().endOf('month').format('YYYY-MM')
            },
            months_selection: [
                { value: 0, name: "All" },
                { value: 1, name: "January" },
                { value: 2, name: "Febuary" },
                { value: 3, name: "March" },
                { value: 4, name: "April" },
                { value: 5, name: "May" },
                { value: 6, name: "June" },
                { value: 7, name: "July" },
                { value: 8, name: "August" },
                { value: 9, name: "September" },
                { value: 10, name: "October" },
                { value: 11, name: "November" },
                { value: 12, name: "December" },
            ],
            yearCountBackward: 5,
            yearCountForward: 1,
            dataTable: {
                headers: [
                    { text: 'Customer', sortable: false, align: 'left', value: 'customer_name' },
                    { text: `Category`, sortable: false, align: 'left', value: 'category_code' },
                    { text: `Classification`, sortable: false, align: 'left', value: 'classification' },
                    { text: `Cluster`, sortable: false, align: 'left', value: 'cluster' },
                    { text: 'Sub Cluster', sortable: false, align: 'left', width: '10%', value: 'sub_cluster' },
                    { text: `Total Sales`, sortable: false, align: 'left', value: 'total_sales' },
                    { text: `Total SOA Amount`, sortable: false, align: 'left', value: 'gross_amount' },
                    { text: `Total Collectibles`, sortable: false, align: 'left', value: 'total_amount' },
                    { text: `Total Paid`, sortable: false, align: 'left', value: 'paid_amount' },
                ],
                items: [],
                for_summary: [],
                totalItems: 0,
                loading: false,
                options: {},
            },
            itemsPerPageOptions: [5,10,50,250,500,1000,-1],
        }
    },
    methods: {
        getSelectedMode(mode) {
            this.mode = mode

            if (mode == 'week' && this.week.year != null) {
                this.getNumberOfWeeks(this.week.year)
                this.week.start = this.$dayjs().week()
            }
        },

        getNumberOfWeeks(y) {
            this.week_range = [];
            for (let i = 1; i <= this.$dayjs().isoWeeksInYear(); i++) {
                this.week_range.push(i)
            }
        },

        async filterRange(isPerPL = true, stats = null) {
            if (!isPerPL) {
                this.isPerPL = isPerPL
                this.filters.status_value = stats.status
                this.filters.status_text = stats.status_text
            }

            if (this.mode == 'month') {
                if (!!this.selectedRange.from && this.selectedRange.to) {
                    this.dateFrom = this.selectedRange.from
                    this.dateTo = this.selectedRange.to
                    this.weekCount = ''
                }
            }
            if (this.mode == 'year') {
                if (!!this.year_range.start && !!this.year_range.end) {
                    this.dateFrom = this.year_range.start + '-01'
                    this.dateTo = this.year_range.end + '-12'
                    this.weekCount = ''
                }
                else {
                    const date = new Date();
                    let year = date.getFullYear();

                    this.dateFrom = year + '-01'
                    this.dateTo = year + '-12'
                    this.weekCount = ''
                }
            }
            if (this.mode == 'week') {
                if (!!this.week.year && !!this.week.start) {
                    let week_count = this.getDateRangeOfWeek(this.week.start, this.week.year)

                    this.dateFrom = this.week.year
                    this.dateTo = ''
                    this.weekCount = week_count
                }
            }

            // this.$refs.child.reloadTable(this.dateFrom, this.dateTo, this.mode, this.weekCount)

            this.rangeSelectDialog = false;
        },
        async getYearRange() {
            let yearMin = this.date.year - this.yearCountBackward;
            let yearMax = this.date.year + this.yearCountForward;
            for (let i = yearMin; i <= yearMax; i++) {
                this.yearRange.push(i)
            }

            this.filters.filtered_year = yearMin
        },
        getYear() {
            this.date.year = Number(this.year_selected.split('-')[0]);
            this.componentKey++;
        },
        activateSubTab(tab) {
            this.activeSubTab = tab
            if (tab == 'year') {
                this.filters.filtered_month = 0
                this.filters.status_text = 'all'
            }
        },
        getDateRangeOfWeek(w, y) {
            let monthNames = [];
            this.months_selection.forEach((e) => {
                if (e.value != 0) monthNames.push(e.value);
            });
            let date = this.getDateOfISOWeek(w, y);
            let monthStart = monthNames[date.getMonth()];

            date.setDate(date.getDate() + 6);

            let monthEnd = monthNames[date.getMonth()];
            return monthStart == monthEnd ? monthStart : [monthStart, monthEnd];
        },
        getDateOfISOWeek(w, y) {
            let simple = new Date(y, 0, 1 + (w - 1) * 7);
            let dow = simple.getDay();
            let ISOweekStart = simple;
            if (dow <= 4)
                ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
            else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
            return ISOweekStart;
        },

        getSalesIncentives() {
            if(this.selectedRange.from != this.selectedRange.to) return swal.fire({
                text: '- Filter must be only 1 month',
                icon: "info",
            });

            this.dataTable.loading      = true;
            this.dataTable.items        = [];

            let payload = {
                url:                        'sales-incentives',
                page:                       this.dataTable.options.page,
                itemsPerPage:               this.dataTable.options.itemsPerPage,
                salesman:                   this.salesman,
                date_from:                  this.selectedRange.from,
                date_to:                    this.selectedRange.to,
            }
            this.$store.dispatch('reports', payload).then(response => {
                this.dataTable.items            = response.data.data.data
                this.dataTable.totalItems       = response.data.data.total
                this.dataTable.loading          = false;
            })
        },

        resetFields() {
            this.salesman = [];
        },

        async exportToExcel() {
            let workbook = new this.$exceljs.Workbook();

            swal.fire({
                    title:                      'Exporting Data',
                    allowOutsideClick:          false,
                })
            swal.showLoading();
            let payload = {
                url:                        'sales-incentives-export',
                salesman:                   this.salesman,
                date_from:                  this.selectedRange.from,
                date_to:                    this.selectedRange.to,
                mode:                       this.mode,
            }
            await this.$store.dispatch('reports', payload).then((response) => {
                const summary     = response.data.summary;
                const detailed    = response.data.detailed;

                const summarySheet = workbook.addWorksheet("Summary");

                summarySheet.columns = [
                    { header: "Customer", key: "customer_name", width: 60 },
                    { header: "Category", key: "category_code", width: 15 },
                    { header: "Classification", key: "classification", width: 25 },
                    { header: "Cluster", key: "cluster", width: 15 },
                    { header: "Sub Cluster", key: "sub_cluster_name", width: 15 },
                    { header: "Total Sales", key: "total_sales", style: { numFmt: '#,##0.00' }, width: 25 },
                    { header: "Total SOA Amount", key: "gross_amount", style: { numFmt: '#,##0.00' }, width: 25 },
                    { header: "Total Collectibles", key: "total_amount", style: { numFmt: '#,##0.00' }, width: 25 },
                    { header: "Total Paid", key: "paid_amount", style: { numFmt: '#,##0.00' }, width: 25 },
                ]

                const headerRow = summarySheet.getRow(1);
                headerRow.font = { bold: true }
                for (let c=1;c<=summarySheet.columns.length;c++) {
                    let currentCell = headerRow.getCell(c);
                    currentCell.fill = {
                        type: 'pattern',
                        pattern:'solid',
                        fgColor:{argb:'F69F1A'},
                    }
                }
                summary.forEach((e,i) => {
                    e.total_sales = e.total_sales  == null ? 0 : parseFloat(e.total_sales)
                    e.gross_amount = e.gross_amount  == null ? 0 : parseFloat(e.gross_amount)
                    e.total_amount = e.total_amount  == null ? 0 : parseFloat(e.total_amount)
                    e.paid_amount = e.paid_amount  == null ? 0 : parseFloat(e.paid_amount)
                    const worksheetRow = summarySheet.addRow(e);
                    const firstCell = worksheetRow.getCell(2);
                    firstCell.alignment = { vertical: 'middle', horizontal: 'center' };
                })

                const detailedSheet = workbook.addWorksheet("Detailed");

                detailedSheet.columns = [
                    { header: "Customer", key: "customer_name", width: 60 },
                    { header: "Category", key: "category_code", width: 15 },
                    { header: "Classification", key: "classification", width: 25 },
                    { header: "Cluster", key: "cluster", width: 15 },
                    { header: "Sub Cluster", key: "sub_cluster_name", width: 15 },
                    { header: "Item Type", key: "item_types_code", width: 15 },
                    { header: "Item Category", key: "item_categories_name", width: 15 },
                    { header: "Total Sales", key: "pl_item_total_amount", style: { numFmt: '#,##0.00' }, width: 25 },
                    { header: "Total SOA Amount", key: "gross_amount", style: { numFmt: '#,##0.00' }, width: 25 },
                    { header: "Total Collectibles", key: "total_amount", style: { numFmt: '#,##0.00' }, width: 25 },
                ]

                const headerDetailedRow = detailedSheet.getRow(1);
                headerDetailedRow.font = { bold: true }
                for (let c=1;c<=detailedSheet.columns.length;c++) {
                    let currentCell = headerDetailedRow.getCell(c);
                    currentCell.fill = {
                        type: 'pattern',
                        pattern:'solid',
                        fgColor:{argb:'F69F1A'},
                    }
                }
                detailed.forEach((e,i) => {
                    e.pl_item_total_amount = e.pl_item_total_amount == null ? 0 : parseFloat(e.pl_item_total_amount)
                    e.gross_amount = e.gross_amount  == null ? 0 : parseFloat(e.gross_amount)
                    e.total_amount = e.total_amount  == null ? 0 : parseFloat(e.total_amount)
                    const worksheetRow = detailedSheet.addRow(e);
                    const firstCell = worksheetRow.getCell(2);
                    firstCell.alignment = { vertical: 'middle', horizontal: 'center' };
                    const secondCell = worksheetRow.getCell(6);
                    secondCell.alignment = { vertical: 'middle', horizontal: 'center' };
                })

            }).catch((e) => {
                swal.fire("Error !", "", "error");
            })

            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], {
                    type: "applicationi/xlxs",
                })
                this.$filesaver.saveAs(blob, `Sales Incentives.xlsx`);
                swal.close();
            })
        },

        cluster(item) {
            let cluster = JSON.parse(item.cluster)
            cluster = new Set(cluster)
            if(cluster) {
                return Array.from(cluster).join(", ")
            } else {
                return ''
            }
        },

        subCluster(item) {
            let sub_cluster = JSON.parse(item.sub_cluster)
            sub_cluster = new Set(sub_cluster)
            if(sub_cluster) {
                return Array.from(sub_cluster).join(", ")
            } else {
                return ''
            }
        }
    },
    components:{
        ComponentHeader
    },
    computed:{
        ...mapGetters([
            'GET_SALESMAN_SELECTION',
        ]),
    },
    async mounted() {
        await this.getYearRange();
        this.$store.dispatch('getAllsalesmanSelection')
        this.dateFrom = new Date().getFullYear() + '-01'
        this.dateTo = new Date().getFullYear() + '-12'
        // this.getSalesIncentives()
    },

    watch: {
        'dataTable.options': {
            handler(val){
                if(val) {
                    this.getSalesIncentives()
                }
            },
            deep: true,
        },

        'selectedRange.from': {
            handler(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.$refs.menu.isActive = false;
                }
            }
        },

        'selectedRange.to': {
            handler(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.$refs.menus.isActive = false;
                }
            }
        },

        'year_range.start': {
            handler(val) {
                this.date.year = val
            }
        },
    },

}
</script>

<style>

</style>