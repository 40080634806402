import PayBillsComponent from "@/views/main/modules/Accounting/PayBills/PayBillsComponent.vue";
import PayBillsViewComponent from "@/views/main/layouts/dialogs/Accounting/PayBillsViewComponent.vue";
let settings = {
    tab_item: [
        {
            tab_name: 'PAID', children: {
                components: PayBillsComponent,
                dialog:{
                    components:PayBillsViewComponent,
                    title:'SELECT BILLS TO BE PAID',
                    width:''
                },
                dispatch: {
                    get:{
                        _action:'getAllData',
                        url:'accounting/get-all-store-branches-data'
                    },
                    post:{
                        _action:'post',
                        url:''
                    },
                    put:{
                        _action:'put',
                        url:''
                    },
                    delete:{
                        _action:'delete',
                        url:''
                    },
                    show:{
                        _action:'show',
                        url:''
                    },
                },
                dropdowns:[
                    'getCompanySelection',
                ],
                color:{

                },
                data_table: {
                    items:[],
                    total_items:1,
                    headers: [
                        { text: "REF #", align: "left", value: "name" },
                        { text: "Company", align: "left", value: "company_id" },
                        { text: "Action", align: "center", value: "action", sortable:false},
                    
                    ],
                    options: {},
                    filters:(options,search)=> {
                        return {
                            page: options.page,
                            itemsPerPage:options.itemsPerPage,
                            search: search,
                            sortBy:options.sortBy,
                            sortDesc:options.sortDesc,
                        }
                    },
                    itemsPerPageOptions: [5, 10, 15, 50, 100],
                    itemsPerPage: 10,
                }
            },
            
        },
        {
            tab_name: 'UNPAID', children: {
                components: PayBillsComponent,
                dialog:{
                    components:PayBillsViewComponent,
                    title:'SELECT BILLS TO BE PAID',
                    width:''
                },
                dispatch: {
                    get:{
                        _action:'getAllData',
                        url:''
                    },
                    post:{
                        _action:'post',
                        url:''
                    },
                    put:{
                        _action:'put',
                        url:''
                    },
                    delete:{
                        _action:'delete',
                        url:''
                    },
                    show:{
                        _action:'show',
                        url:''
                    },
                },
                dropdowns:[
                    'getCompanySelection',
                ],
                color:{

                },
                data_table: {
                    items:[],
                    total_items:1,
                    headers: [
                        { text: "REF #", align: "left", value: "name" },
                        { text: "Company", align: "left", value: "company_id" },
                        { text: "Action", align: "center", value: "action", sortable:false},
                    
                    ],
                    options: {},
                    filters:(options,search)=> {
                        return {
                            page: options.page,
                            itemsPerPage:options.itemsPerPage,
                            search: search,
                            sortBy:options.sortBy,
                            sortDesc:options.sortDesc,
                        }
                    },
                    itemsPerPageOptions: [5, 10, 15, 50, 100],
                    itemsPerPage: 10,
                }
            },
            
        },
    ]
}
export default settings;