<template>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
          <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
              <span class="headline">
                Sales Order Details
              </span>
              <v-row class="m-0">
                  <v-col cols="pull-right-10 p-2">
                      <v-btn
                      text
                      icon
                      small
                      color="gray"
                      class="float-right"
                      @click="closeDialog()"
                      >
                      <v-icon>mdi-close-circle</v-icon>
                      </v-btn>
                  </v-col>
              </v-row>
          </v-card-title>

          <v-card-text v-if="this.GET_SWITCH_ITEM == false">
            <v-flex class="d-flex">
                <v-col class="text-dark" cols="10">
                    <v-row class="m-0">
                        <v-col>
                            Sales Order No: {{ orderNum }}
                        </v-col>
                        <v-col>
                            Issuer: {{ issuerName }}
                        </v-col>
                    </v-row>
                    <v-row class="m-0">
                        <v-col>
                            Customer: {{ customerName }}
                        </v-col>
                        <v-col>
                            Date Issued: {{ dateIssued | formatDateTime }}
                        </v-col>
                    </v-row>
                    <v-row class="m-0">
                        <v-col>
                            Remarks: {{ remarks }}
                        </v-col>
                    </v-row>
                    <v-row class="m-0">
                        <v-col>
                            Notes: {{ notes }}
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="2" class="d-flex justify-end">
                    <v-tooltip bottom color="#f69f1a">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                fab
                                icon
                                text
                                small
                                v-on="on"
                                v-bind="attrs"
                                color="#f69f1a"
                                @click="exportToExcel(GET_SWITCH_ITEM)"
                            >
                                <v-icon color="#f69f1a">mdi-printer-pos-wrench</v-icon>
                            </v-btn>
                        </template>
                        <span>Export</span>
                    </v-tooltip>
                    <v-tooltip bottom color="#f69f1a">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                fab
                                icon
                                text
                                small
                                v-on="on"
                                v-bind="attrs"
                                color="#f69f1a"
                                @click="printPDF(GET_SWITCH_ITEM)"
                            >
                                <v-icon color="#f69f1a">mdi-cloud-download</v-icon>
                            </v-btn>
                        </template>
                        <span>Print PDF</span>
                    </v-tooltip>
                </v-col>
            </v-flex>

            <v-data-table
                :headers="itemHeaders"
                :items="salesOrderItems"
                :items-per-page="500"
                :loading="loaded"
                hide-default-footer
                class="w-100"
            >
                <template v-slot:[`item.foc`]="{ item }">
                    <span> {{ item.foc == 1 ? 'Yes' : 'No' }}</span>
                </template>

                <template v-slot:[`item.srp`]="{ item }">
                    <span> &#x20b1;{{ item.srp | currency }}</span>
                </template>

                <template v-slot:[`item.disc_price`]="{ item }">
                    <span> &#x20b1;{{ item.disc_price | currency }}</span>
                </template>

                <template v-slot:[`item.final_price`]="{ item }">
                    <span> &#x20b1;{{ item.final_price | currency }}</span>
                </template>

                <template v-slot:[`item.total_amount`]="{ item }">
                    <span> &#x20b1;{{ item.total_amount | currency }}</span>
                </template>
            </v-data-table>

            <!-- <v-row class="m-0">
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="pull-right text-right text-dark">
                    Total Amount: &#x20b1;
                    {{ totalAmount | currency }}
                </v-col>
                <v-col cols="12" sm="12" md="8" lg="8" xl="8" class="text-left">
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="pull-right text-right text-dark" v-if="soStatus != 4">
                    Served Amount: &#x20b1;
                    {{ sales_order.total_amount_served | currency }}
                    <br>
                    Unserved Amount Total: &#x20b1;
                    {{ sales_order.total_amount_with_without_stocks | currency }}
                    <br><br>
                    Unserved Amount with Stocks: &#x20b1;
                    {{ sales_order.total_amount_with_stocks | currency }}
                    <br>
                    Unserved Amount without Stocks: &#x20b1;
                    {{ sales_order.total_amount_without_stocks | currency }}
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="col-sm-12 col-md-4 pull-right text-right text-dark" v-else>
                    <b>FULLY SERVED!</b>
                </v-col>
            </v-row> -->
        </v-card-text>

        <v-card-text v-if="this.GET_SWITCH_ITEM == true">
            <v-flex class="d-flex">
                <v-col class="text-dark" cols="10">
                    <v-row class="m-0">
                        <v-col>
                            Parts: {{ itemModel }}
                        </v-col>
                        <v-col>
                            Item Name: {{ itemName }}
                        </v-col>
                    </v-row>
                    <v-row class="m-0">
                        <v-col>
                            Item Description: {{ itemDescription }}
                        </v-col>
                        <v-col>
                            Stocks: {{ itemStocks }}
                        </v-col>
                    </v-row>
                    <v-row class="m-0">
                        <v-col>
                            Ideal Stocks: {{ itemIdealStocks }}
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="2" class="d-flex justify-end">
                    <v-tooltip bottom color="#f69f1a">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                fab
                                icon
                                text
                                small
                                v-on="on"
                                v-bind="attrs"
                                color="#f69f1a"
                                @click="exportToExcel(GET_SWITCH_ITEM)"
                            >
                                <v-icon color="#f69f1a">mdi-printer-pos-wrench</v-icon>
                            </v-btn>
                        </template>
                        <span>Export</span>
                    </v-tooltip>
                    <v-tooltip bottom color="#f69f1a">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                fab
                                icon
                                text
                                small
                                v-on="on"
                                v-bind="attrs"
                                color="#f69f1a"
                                @click="printPDF(GET_SWITCH_ITEM)"
                            >
                                <v-icon color="#f69f1a">mdi-cloud-download</v-icon>
                            </v-btn>
                        </template>
                        <span>Print PDF</span>
                    </v-tooltip>
                </v-col>
            </v-flex>
            <v-data-table
                :headers="sOHeaders"
                :items="salesOrders"
                :items-per-page="500"
                :loading="loaded"
                hide-default-footer
                class="w-100"
            >
                <template v-slot:[`item.total_amount`]="{ item }">
                    <span class="text-nowrap">
                        &#x20b1;{{ item.total_amount | currency }}
                    </span>
                </template>

                <template v-slot:[`item.status_text`]="{ item }">
                    <span class="badge badge-saved" v-if="item.status_text == 'saved'">{{ item.status_text }}</span>
                    <span class="badge badge-purple" v-else-if="item.status_text == 'converted'">{{ item.status_text }}</span>
                    <span class="badge badge-warning" v-else-if="item.status_text == 'rejected'">{{ item.status_text }}</span>
                    <span class="badge badge-danger" v-else-if="item.status_text == 'canceled'">{{ item.status_text }}</span>
                    <span class="badge badge-dark" v-else-if="item.status_text == 'deleted'">{{ item.status_text }}</span>
                </template>
            </v-data-table>
          </v-card-text>
      </v-card>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2'

export default {
  data() {
      return {
        valid: false,
        salesOrderItems: [],
        itemHeaders: [
            { text: 'Model', value: 'model', sortable: false },
            { text: 'Name', value: 'name', sortable: false },
            { text: 'Type', value: 'type_name', sortable: false },
            { text: 'FOC', value: 'foc', sortable: false },
            { text: 'SRP', value: 'srp', sortable: false },
            { text: 'Disc Price', value: 'disc_price', sortable: false },
            { text: 'Qty', value: 'quantity', sortable: false },
            { text: 'Served Qty', align: 'left', value: 'quantity_served', sortable: false },
            { text: 'Unserved Qty', align: 'left', value: 'unserved_qty', sortable: false },
            { text: 'Canceled Qty', align: 'left', value: 'quantity_canceled', sortable: false },
            { text: 'Prepared Qty', align: 'left', value: 'prepared_quantity', sortable: false },
            { text: 'UOM', value: 'uom_name', sortable: false },
            { text: 'Price', value: 'final_price', sortable: false },
            { text: 'Total', value: 'total_amount', sortable: false },
        ],
        sOHeaders: [
            { text: 'Order Num', value: 'order_num' },
            { text: 'Quote Num', value: 'sq_quote_num' },
            { text: 'Customer', value: 'so_customer_name' },
            // { text: 'Quantity', value: 'quantity' },
            { text: 'Remarks', value: 'so_remarks' },
            { text: 'Amount', value: 'total_amount' },
            { text: 'Status', value: 'status_text' },
            // { text: 'Action', value: 'actions' },
        ],
        exportHeader:  [
            {header:'MODEL', key:'model', width: 20},
            {header:'NAME', key:'name', width: 25},
            {header:'TYPE', key:'type_name', width: 5},
            {header:'FOC', key:'foc_text', width: 5},
            {header:'SRP', key:'srp', width: 10},
            {header:'DISC PRICE', key:'disc_price', width: 10},
            {header:'QTY', key:'quantity', width: 7},
            {header:'SERVED QTY', key:'quantity_served', width: 12},
            {header:'UNSERVED QTY', key:'unserved_qty', width: 15},
            {header:'CANCELED QTY', key:'quantity_canceled', width: 15},
            {header:'PREPARED QTY', key:'prepared_quantity', width: 15},
            {header:'UOM', key:'uom_name', width: 7},
            {header:'PRICE', key:'final_price', width: 10},
            {header:'TOTAL', key:'total_amount', width: 10},
        ],
        loaded: true,
        orderNum: '',
        issuerName: '',
        customerName: '',
        dateIssued: '',
        remarks: '',
        notes: '',
        totalAmount: 0,
        totalAmountwithStocks: 0,
        totalAmountwithoutStocks: 0,
        soId: '',
        soStatus: '',
        itemModel: '',
        itemName: '',
        itemDescription: '',
        itemStocks: '',
        itemIdealStocks: '',
        itemId: '',
        salesOrders: [],
      }
  },

  computed: {
      ...mapGetters([
          'DIALOGS',
          'ACTION',
          'GET_SP_AREA_DIALOG_VIEW',
          'GET_SPARE_PARTS_STAGING_AREA',
          'GET_REFRESH_SP_AREA',
          'GET_SPARE_PARTS_AREA_TAB',
          'GET_SP_AREA_DISPATCH',
          'GET_SP_AREA_DIALOG_TITLE',
          'GET_SWITCH_ITEM',
          'GET_FILTER_DATE',
      ]),

      actions(){
             if (this.ACTION == 'View'){

                this.getSoData()
                this.getSoItemData()
                this.viewSoItem()
                this.viewSo()
                return 'View'
            } else {
            }
        }
  },

  mounted() {
        this.getSoData()
        this.getSoItemData()
        this.viewSoItem()
        this.viewSo()
  },

  methods: {
      closeDialog() {
          this.$store.commit("DIALOG", false);
          this.$store.commit('REFRESH_SP_AREA', true)
          this.$store.commit('SP_AREA_DIALOG_VIEW', {});
      },

      getSoData() {
        this.orderNum                   = this.GET_SP_AREA_DIALOG_VIEW.order_num;
        this.issuerName                 = this.GET_SP_AREA_DIALOG_VIEW.issuer_name;
        this.remarks                    = this.GET_SP_AREA_DIALOG_VIEW.so_remarks;
        this.customerName               = this.GET_SP_AREA_DIALOG_VIEW.so_customer_name;
        this.dateIssued                 = this.GET_SP_AREA_DIALOG_VIEW.created_at;
        this.notes                      = this.GET_SP_AREA_DIALOG_VIEW.notes;
        this.soId                       = this.GET_SP_AREA_DIALOG_VIEW.so_id;
        this.soStatus                   = this.GET_SP_AREA_DIALOG_VIEW.status;
        this.totalAmount                = this.GET_SP_AREA_DIALOG_VIEW.total_amount;
      },

      getSoItemData() {
        this.itemModel                  = this.GET_SP_AREA_DIALOG_VIEW.model;
        this.itemName                   = this.GET_SP_AREA_DIALOG_VIEW.name;
        this.itemDescription            = this.GET_SP_AREA_DIALOG_VIEW.description;
        this.itemStocks                 = this.GET_SP_AREA_DIALOG_VIEW.stocks;
        this.itemIdealStocks            = this.GET_SP_AREA_DIALOG_VIEW.ideal_stocks;
        this.itemId                     = this.GET_SP_AREA_DIALOG_VIEW.item_id;
      },

      viewSoItem() {
        this.salesOrderItems = [];
        this.loaded = true;
        this.$store.commit('REFRESH_SP_AREA', true)
        this.$store.dispatch('serviceGet',{
            url                     : this.GET_SP_AREA_DISPATCH.view[0],
            soId                    : this.soId,
        }).then(response => {
            this.salesOrderItems    = response.data.data
                if(!!this.salesOrderItems) {
                    let sumWithStocks = 0;
                    let sumWithoutStocks = 0;
                    this.salesOrderItems.forEach(e => {
                        sumWithStocks += e.amount_with_stocks
                        sumWithoutStocks += e.amount_without_stocks
                    })
                    this.totalAmountwithStocks      = sumWithStocks
                    this.totalAmountwithoutStocks   = sumWithoutStocks
                }
            this.$store.commit('REFRESH_SP_AREA', false)
            this.loaded = false;
        }).catch( err =>{
            console.log(err)
            Swal.fire('Error!','- an error occured','error')
        });
      },

      viewSo() {
        this.salesOrders = [];
        this.loaded = true;
        this.$store.commit('REFRESH_SP_AREA', true)
        this.$store.dispatch('serviceGet',{
            url                     : this.GET_SP_AREA_DISPATCH.view[1],
            item_id                 : this.itemId,
            sales_quotation_dates   : this.GET_FILTER_DATE,
        }).then(response => {
            this.salesOrders    = response.data.data
            this.$store.commit('REFRESH_SP_AREA', false)
            this.loaded = false;
        }).catch( err =>{
            console.log(err)
            Swal.fire('Error!','- an error occured','error')
        });
      },

        exportToExcel(switchSoItem) {
            const workbook = new this.$exceljs.Workbook();
            const worksheet = workbook.addWorksheet('Sales Order Details');

            if (switchSoItem) {
                let headers = this.sOHeaders.map( col => {
                    return {
                        header: col.text.toUpperCase(),
                        key:col.value,
                        width: col.value === 'so_customer_name' || col.value === 'so_remarks' ? 50 : 15
                    }
                });

                let additionalDetails = [
                    { header: 'SPARE PART MODEL', key: 'sp_model', width: 20},
                    { header: 'SPARE PART NAME', key: 'sp_name', width: 20},
                    { header: 'SPARE PART DESCRIPTION', key: 'description', width: 25},
                    { header: 'STOCKS', key: 'stocks', width: 10},
                    { header: 'IDEAL STOCKS', key: 'ideal_stocks', width: 15}
                ]

                worksheet.columns = [...headers, ...additionalDetails];
                let headerRow = worksheet.getRow(1);
                    headerRow.font = { bold:true }
                for (let c=1;c<=worksheet.columns.length;c++) {
                    let currentCell = headerRow.getCell(c);
                    currentCell.fill = {
                        type: 'pattern',
                        pattern:'solid',
                        fgColor:{argb:'F69F1A'},
                    }
                }

                if (!!this.salesOrders) {
                    let salesOrderDetails = JSON.parse(JSON.stringify(this.salesOrders));
                    salesOrderDetails.map( e => {
                        Object.assign(e, {sp_model:this.itemModel, sp_name:this.itemName, description:this.itemDescription, stocks:this.itemStocks, ideal_stocks:this.itemIdealStocks});
                    })
                    salesOrderDetails.forEach( row => {
                        worksheet.addRow(row);
                    })
                }

            } else {
                const additionalHeaders = [
                    {header:'SO#', key: 'so_num', width: 10},
                    {header:'CUSTOMER', key: 'customer', width: 45},
                    {header:'ISSUER', key: 'issuer', width: 15},
                    {header:'REMARKS', key:'remarks', width: 35},
                    {header:'DATE ISSUED', key:'date_issued', width: 28},
                    {header:'NOTES', key:'notes', width:10}
                ]
                worksheet.columns = [...additionalHeaders, ...this.exportHeader];
                let headerRow = worksheet.getRow(1);
                    headerRow.font = { bold:true };
                for (let c=1;c<=worksheet.columns.length;c++) {
                    headerRow.fill = {
                        type: 'pattern',
                        pattern:'solid',
                        fgColor:{argb:'F69F1A'},
                    }
                }

                if (!!this.salesOrderItems) {
                    let soOrderItemDetails = JSON.parse(JSON.stringify(this.salesOrderItems));
                    soOrderItemDetails.map(e => {
                        Object.assign(e, {so_num:this.orderNum, customer:this.customerName,issuer:this.issuerName,remarks:this.remarks,date_issued:this.dateIssued,notes:this.notes});
                    })
                    soOrderItemDetails.forEach( row =>
                        worksheet.addRow(row));
                }
            }

            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                this.$filesaver.saveAs(blob, 'Spare Parts Quotation Details.xlsx');
            });
        },

        printPDF(switchSoItem) {
            if (switchSoItem) {
                let doc = new this.$jspdf("p", "mm", "letter");

                let ingcoPhLogo = new Image();
                ingcoPhLogo.src = "/images/ingcoph-logo.png";
                doc.addImage(ingcoPhLogo, "png", 65, 12.7, 90, 20);
                doc.setFontSize(14);
                doc.text('Spare Order Details', 215/2, 38, "center");

                doc.setFontSize(10);
                doc.text(`Parts: ${this.itemModel}`, 10, 52, "left");
                doc.text(`Item Description: ${this.itemDescription ?? ''}`, 10, 57, "left");
                doc.text(`Ideal Stocks: ${this.itemIdealStocks}`, 10, 62, "left");
                doc.text(`Item Name: ${this.itemName}`, 135, 52, "left");
                doc.text(`Stocks: ${this.itemStocks}`, 135, 57, "left");

                const tableColumns = this.sOHeaders.map( h => {
                    return {
                        header: h.text.toUpperCase(),
                        dataKey: h.value
                    }
                })

                doc.autoTable(
                    {
                        body: this.salesOrders,
                        columns: tableColumns,
                        headStyles: {
                            fillColor: '#F69F1A',
                            textColor: "black",
                            textType: "bold",
                        },
                        margin: {left: 10, right: 10},
                        startY: 65,
                        styles: {
                            fontSize: 8,
                            lineWidth: 0.1,
                            lineColor: 'black',
                            overflow: 'hidden'
                        },
                        showHead: 'firstPage',
                        theme: "plain",
                    });

                    doc.autoPrint();
                    window.open(doc.output('bloburl'), '_blank');
            } else {
                let doc = new this.$jspdf("l", "mm", "letter");

                let ingcoPhLogo = new Image();
                ingcoPhLogo.src = "/images/ingcoph-logo.png";
                doc.addImage(ingcoPhLogo, "png", 95, 10, 90, 20);
                doc.setFontSize(14);
                doc.text('Sales Order Details', 138, 38, "center");

                doc.setFontSize(10);
                doc.text(`Sales Order No: ${this.orderNum}`, 10, 52, "left");
                doc.text(`Customer: ${this.customerName}`, 10, 57, "left");
                doc.text(`Remarks: ${this.remarks}`, 10, 62, "left");
                doc.text(`Issuer: ${this.issuerName}`, 175, 52, "left");
                doc.text(`Date Issued: ${this.$dayjs(this.dateIssued).format('MMMM Do YYYY, h:mm:ss a')}`, 175, 57, "left");
                doc.text(`Notes: ${this.notes ?? ''}`, 175, 62, "left");

                const tableColumns = this.exportHeader.map( h => {
                    return {
                        header: h.header.toUpperCase(),
                        dataKey: h.key
                    }
                })

                doc.autoTable(
                    {
                        body: this.salesOrderItems,
                        columns: tableColumns,
                        headStyles: {
                            fillColor: '#F69F1A',
                            textColor: "black",
                            textType: "bold",
                        },
                        margin: {left: 10, right: 10},
                        startY: 65,
                        styles: {
                            fontSize: 8,
                            lineWidth: 0.1,
                            lineColor: 'black',
                            overflow: 'hidden'
                        },
                        showHead: 'firstPage',
                        theme: "plain",
                    });

                    doc.autoPrint();
                    window.open(doc.output('bloburl'), '_blank');
            }
        }
    },

  watch: {
    DIALOGS:{
        handler(val){
            if(val){
                this.actions
            }
        }
    },

    GET_SWITCH_ITEM: {
        handler(val) {
        }
    },
  },

  components: {

  },
}
</script>

<style scoped>

</style>