<template>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
          <span class="text-h5">FAS Purchase Order</span>
          <v-row class="m-0">
            <v-col cols="pull-right-10 p-2">
              <v-btn
                text
                icon
                small
                color="gray"
                class="float-right"
                @click="closeDialog()">
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text style="height: 300px;">

          <v-card-text style="height:270px;" class="over-flow-auto">
            <v-container fluid >
              <v-row >
                <v-col cols="12" lg="4" >
                  <v-row>
                    <v-col cols="12">
                  <v-card >
                    <v-card-text>
                      <v-row class="pa-2">
                      <span class="font-weight-bold">FAS # : {{ GET_EDIT_FAS_PO.fas_number }}</span>
                    </v-row>
                    <v-row class="pa-2">
                      <span class="font-weight-bold">Deparment : {{ GET_EDIT_FAS_PO.department_id?GET_LIST_DEPARTMENT.find(e=>e.value == GET_EDIT_FAS_PO.department_id).text:'' }}</span>
                    </v-row>
                    <v-row class="pa-2">
                      <span class="font-weight-bold">Location : {{ GET_EDIT_FAS_PO.location_id?GET_ADMIN_LOCATION.find(e=>e.value == GET_EDIT_FAS_PO.location_id).text:'' }}</span>
                    </v-row>
                    <v-row class="pa-2">
                    <span class="font-weight-bold text-truncate" :title="GET_EDIT_FAS_PO.justification_remarks">Requisition Remarks : {{ GET_EDIT_FAS_PO.justification_remarks }}</span>
                </v-row>
              </v-card-text>
              </v-card>
            </v-col>
            </v-row>
              <v-row class="pt-1">
                <v-textarea v-model="form.remarks" outlined rows="3" no-resize label="PO Remarks" ></v-textarea>
              </v-row>
                </v-col>
                <v-col cols="12" lg="8" class="pa-0 pl-2">
                 <v-card >
                  <v-container bg fill-height grid-list-md text-sm-center v-if="loaded">
                        <v-layout row wrap align-center>
                           <v-flex>
                              <v-progress-circular
                              :size="70"
                              color="primary"
                              indeterminate>
                            </v-progress-circular>
                          </v-flex>
                        </v-layout>
                  </v-container>
                  <v-card-text>
                  <v-row class="pa-2" v-if="!loaded">
                    <v-col>
                      <v-simple-table
                        fixed-header
                        height="230px"
                        >
                        <template v-slot:default>
                            <thead class="pa-0">
                                <tr>
                                <th class="text-center">
                                    Item
                                </th>
                                <th class="text-center">
                                    QTY
                                </th>
                                <!-- <th class="text-center">
                                    PRICE
                                </th> -->

                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                v-for="(item,i) in form.batchAdditionItems" :key="i"
                                >

                                    <td class="pa-0 pr-1 text-center">{{ getItemSelected(item)}}</td>
                                    <td class="pa-0 text-center">{{ form.batchAdditionItems[i].approved_quantity }}</td>
                                    <!-- <td class="pa-1 text-center">{{ form.batchAdditionItems[i].price }}</td> -->

                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
              </v-card-text>
              </v-card>
                </v-col>
              </v-row>
            </v-container>
        </v-card-text>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions :class="'text-center d-flex border-top'+ actions == 'View' && GET_EDIT_FAS_PO.status != 3?'justify-content-between':'justify-content-center'">
          <v-btn v-if="actions == 'Update' && GET_EDIT_FAS_PO.status == 1"

            class="float-center"
            @click="createPO()"
          ><span>{{ actions }}</span>
          </v-btn>
          <v-btn v-if="actions == 'View' && GET_EDIT_FAS_PO.status != 3"
            class="float-center"
            @click="createPO()"
          >
            <span>{{ status_text }}</span>
          </v-btn>
        </v-card-actions>
        <FasRequisitionComponent></FasRequisitionComponent>
      </v-card>
    </v-form>
  </template>
      <script>
  import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
  import Swal from "sweetalert2";
  import { mapGetters } from "vuex";
  import FasRequisitionComponent from '@/views/main/layouts/PrintTemplate/FasRequisitionComponent.vue'
  import ItemsViewComponent from '@/views/main/layouts/dialogs/itemsViewComponent.vue';
  import images from '../../../../../assets/ingcoph_bg/ingcoph_logo_alt.jpg'
  export default {
    mixins: [
        SharedFunctionsComponentVue
    ],
    data() {
      return {
        offset: true,
        valid:true,
        dialog:false,
        form: {
            fas_number:'',
            department_id:'',
            location_id:'',
            remarks:'',
            status:1,
            batchAdditionItems:[],
        },
        menu:false,
        employee_selection_data:[{'value':0,'text':'GENERAL ACCOUNTABILITY','department_id':0}],
        search_employee:'',
        search_department:'',
        action:'',
        disabled_view: false,
        errors: {
          emp_id: [(v) => !!v || "Employee Name is required"],
          location: [(v) => !!v || "Location is required"],
        },
        URL:process.env.VUE_APP_API_HOST,
        batchAdditionItems:[],
        collection:[],
        collectionQTY:[],
        loaded:false,
        userAccess: {
          approve:false,
        },
        selection:{
          admin_items:[]
        }
      };
    },
    components: {
      FasRequisitionComponent,
      ItemsViewComponent
    },
    computed: {
      ...mapGetters([
        "ACTION",
        "DIALOGS",
        "USER_ACCESS",
        "GET_ADMIN_LOCATION",
        "GET_EMPLOYEE_DATA_SELECTION",
        "GET_EDIT_ADMIN_FASREQUISITION",
        "GET_NEW_PURCHASE_REQUEST_TABLE",
        "GET_ADMIN_FASPO_DISPATCH",
        "GET_LIST_DEPARTMENT",
        "GET_UOM_SELECTION",
        "GET_NEW_ADMIN_FASPO",
        "GET_EDIT_FAS_PO",
        "GET_ADMIN_FASPO_STATUS"
      ]),
      actions() {
        if (this.USER_ACCESS != "fail") {
            this.userAccess.approve = false
            this.USER_ACCESS.forEach((e) => {
             if(e.actions_code == 'approve'){
                this.userAccess.approve = true
              }
            });
          }
        if (this.ACTION == "Submit") {
          this.disabled_view = false;
          this.loaded = false;
          this.action = this.GET_ADMIN_FASPO_DISPATCH.add
          this.form.status = 1;
          return "Submit";
        } else if (this.ACTION == "Update") {
          this.disabled_view = false;
          this.showItems()
          this.form.status = 1;
          return 'Update';
        } else if (this.ACTION == "View") {
          this.showItems()
          this.disabled_view = true;
          return "View";
        }
      },
      status_text(){
        if(this.GET_ADMIN_FASPO_STATUS == 'APPROVED'){
          this.form.status = 1;
          this.action = this.GET_ADMIN_FASPO_DISPATCH.add;
          return 'Create'
        }
        else{
          this.action = this.GET_ADMIN_FASPO_DISPATCH.update;
          if(this.GET_ADMIN_FASPO_STATUS == 'ALL'){
          if(this.GET_EDIT_FAS_PO.status == 1){
            this.form.status = 2;
          }
          if(this.GET_EDIT_FAS_PO.status == 2){
            this.form.status = 3;
          }

          return 'approve'
        }
        if(this.GET_ADMIN_FASPO_STATUS == 'PENDING'){
          this.form.status = 3;
          return 'approve'
        }
        if(this.GET_ADMIN_FASPO_STATUS == 'APPROVEDPO'){
          return 'approve'
        }

        }



      }

    },
    mounted() {
        this.addItems();
        this.getItemsSelection()
    },
    methods: {
    addItems(){
        this.form.batchAdditionItems.push({
            id:'',
            code:'',
            qty:0,
            uom_id:'',
            exists:false,
            price:0,
        })
    },
    getItemsSelection(){
      this.$store.dispatch('getPRFAdminItems',{
                            vendor_id:0
                        }).then(response=> {
                            this.selection.admin_items = response

            });
    },
    getItemSelected(item){
      let findItemfromSelection = this.selection.admin_items.find(e=>e.admin_item_id == item.item_id)
        if(findItemfromSelection){
          return findItemfromSelection.item_name
        }
        return 'N/A';
    },
  async showItems(){
      this.loaded = true;
      let tmp = false;
      this.form.id = this.GET_EDIT_FAS_PO.id
      this.form.fas_number = this.GET_EDIT_FAS_PO.fas_number;
      this.form.department_id = this.GET_EDIT_FAS_PO.department_id;
      this.form.location_id = this.GET_EDIT_FAS_PO.location_id;
      this.form.remarks = this.GET_EDIT_FAS_PO.remarks;
      this.form.batchAdditionItems = await [...this.GET_EDIT_ADMIN_FASREQUISITION];
      setTimeout(()=>{
        this.loaded = false
        tmp = true;
         }
        ,2000);
         if(this.form.batchAdditionItems.length == 0)
          {
            this.addItems()
          }

    },
     createPO(){
      let params = {
          id:this.GET_EDIT_FAS_PO.id,
          data:this.form.fas_number,
          remarks:this.form.remarks,
          status:this.form.status
        };
       this.$store.dispatch(this.action,params);
     },
      renderPDF(){
        this.$store.commit('PRINT_FAS_PO_DETAIL',{});
        this.$store.commit('PRINT_FAS_PO_DETAIL',{items_list:this.GET_EDIT_ADMIN_FASREQUISITION,data:this.GET_EDIT_FAS_PO});
        this.$store.commit('PRINT_FAS_PO',true);
      },
      closeDialog() {
          this.loaded = false;
          this.$store.commit("DIALOG", false);
          this.resetFields();
          this.clearItems();
          this.$store.commit('EDIT_FAS_PO',{});
          this.$store.commit('EDIT_ADMIN_FASPO',[]);
      },
      clearItems(){
        this.form.batchAdditionItems=[]
        this.batchAdditionItems = [];
        this.collection = [];
        this.collectionQTY = [];
        if(this.form.batchAdditionItems.length == 0){
          this.addItems()
        }
      },
      resetFields(){
        this.form = {
          department_id:'',
            location_id:'',
            remarks:'',
            status:1,
            batchAdditionItems:[],
        }
      },
    },
    watch: {
      GET_NEW_ADMIN_FASPO:{
        handler(val){
          if(val)
          {
            this.closeDialog();
            Swal.fire("Success!", "Fas Purchase Order Saved", "success");
           }

        },deep:true
      },
      DIALOGS: {
        handler(val) {
          if(val){
            this.getItemsSelection()
            if(this.form.batchAdditionItems.length == 0){
              this.addItems();
            }
          }
        },
      },
    },
  };
  </script>
  <style scoped>
    .red-text {
      color: red;
    }
  </style>