<template>
  <v-dialog v-model="searchByPartNumDialog" persistent max-width="50%">
      <v-card class="pa-4">
          <v-row class="m-0">
                <v-col>
                    <v-card-title>
                        <span class="headline">Search By Part Number</span>
                    </v-card-title>
                </v-col>
                <v-col cols="pull-right-10 p-2">
                    <v-btn
                        text
                        icon
                        color="gray"
                        class="float-right"
                        @click="closeSearchByPartNumDialog()"
                    >
                    <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-divider class="mt-0" />
            <v-data-table
                :headers="spHeaders"
                :items="spLists"
                :loading="loading"
                :no-data-text="noDataText"
            >
                <template v-slot:item.selling_price="{ item }">
                    {{ sellingPrice(item) }}
                </template>

                <template v-slot:item.action="{ item }">
                    <v-btn @click="selectedItem(item)">Select</v-btn>
                </template>
            </v-data-table>
      </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            spHeaders: [
                { text: 'Item Model', value: 'item.model' },
                { text: 'Part Num', value: 'item.part_num' },
                { text: 'Warehouse', value: 'warehouse_code' },
                { text: 'Qty', value: 'quantity' },
                { text: 'Price', value: 'selling_price' },
                { text: 'Action', value: 'action' },
            ],
            spLists: [],
            partNumberToSearch: '',
            loading: true,
            noDataText: '',
            oldRfEstitems: [],
            warehouseCode: '',
            warehouseId: 0,
            selectedWarehouse: 'SR',
            warehouses: [],
            selectedEstItemId: '',
            modelToSearch: '',
        }
    },
    props: ['est_item', 'rfEstItems'],
    mounted: function() {
        this.getWarehouses();
    },
    computed: {
        ...mapGetters([
            'GET_SAME_PART_NUMBER',
            'GET_ALL_SP_BY_MODEL',
            'GET_SERVICE_GET_WAREHOUSES',
        ]),
        searchByPartNumDialog() {
            return this.$store.state.service.searchByPartNumDialog;
        }
    },
    watch: {
        est_item() {
            if(!!this.est_item) {
                let model = this.est_item.model;
                let modelToSearch = model.split('-SP')[0].concat('-SP');
                this.modelToSearch = modelToSearch;

                this.selectedEstItemId = this.est_item.item_id;
                this.partNumberToSearch = this.est_item.item.part_num;
                this.selectedWarehouse = this.est_item.warehouse_code;
                this.warehouseId = this.est_item.warehouse_id;
            }
        },
        rfEstItems() {
            this.oldRfEstitems = this.rfEstItems;
        },
        partNumberToSearch() {
            if(!!this.partNumberToSearch) {
                this.getSamePartNumber();
            } else {
                this.getAllSpByModel();
            }
        },
        spLists() {
            if(this.spLists.length > 0) {
                this.loading = false;
            } else {
                this.loading = true;
                setTimeout(()=>{
                    this.loading = false;
                    this.noDataText = "No data for Repair Forms";
                }, 10000);
            }
        },
        warehouseId() {
            this.spLists = [];
            if(!!this.partNumberToSearch) {
                this.getSamePartNumber();
            } else {
                this.getAllSpByModel();
            }
        },
    },
    methods: {
        clearFields() {
            this.partNumberToSearch = "";
            this.warehouseCode = "";
            this.warehouseId = 0;
            this.spLists = [];
            this.oldRfEstitems = [];
            this.selectedEstItemId = "";
        },
        closeSearchByPartNumDialog() {
            this.clearFields();
            this.$store.commit('closeSearchByPartNumDialog');
            this.$emit('closeSearchByPartNumDialog', true);
        },
        getSamePartNumber() {
            let payload = {
                partNumber: this.partNumberToSearch,
                warehouseId: this.warehouseId
            }
            this.$store.dispatch('getSamePartNumber',payload).then(response => {
                this.spLists = this.GET_SAME_PART_NUMBER;
            });
        },
        getAllSpByModel() {
            let payload = {
                model: this.modelToSearch,
                warehouseId: this.warehouseId
            }

            this.$store.dispatch('getAllSpByModel',payload).then(response => {
                this.spLists = this.GET_ALL_SP_BY_MODEL;
            });
        },
        getWarehouses() {
            this.$store.dispatch('getServiceWarehouses').then(response => {
                this.warehouses = this.GET_SERVICE_GET_WAREHOUSES;
            });
        },
        selectedItem(item) {
            this.$emit('searchResult', item);
            this.closeSearchByPartNumDialog();
        },
        sellingPrice(item) {
            if(!!item) {
                return `₱ ${item.item.selling_price}`;
            }

            return '₱ 0';
        }
    }
}
</script>

<style>

</style>
