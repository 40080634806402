<template>
    <div class="page-wrapper">
        <!-- IMPORT EXCEL -->
        <v-dialog v-model="importExcel.dialog" scrollable persistent max-width="580px" style="z-index: 80;">
            <v-card>
                <v-progress-linear
                    v-if="importExcel.loading"
                    indeterminate
                    color="green"
                ></v-progress-linear>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="text-lg font-semibold" v-text="`Import Excel`"></span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="toggleDialog(`importExcel`);resetImportExcel()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row tag="div" align="center" no-gutters style="border-top:1px solid #e0e0e0; padding: 0;">
                    <v-col dense cols="12">
                        <v-file-input
                            @change="importExcel.errors.data=[]"
                            v-model="importExcel.form.excel"
                            prepend-icon="mdi-file-excel"
                            placeholder="Import Excel"
                            counter
                            show-size
                            truncate-length="50"
                        ></v-file-input>
                    </v-col>
                </v-row>

                <v-card-text style="max-height: 453px; border:1px solid #e0e0e0; padding: 0;">
                    <v-data-table
                        :headers="importExcel.errors.headers"
                        :items="importExcel.errors.data"
                        :items-per-page="10"
                        dense
                        style="margin: 0;"
                    ></v-data-table>
                </v-card-text>

                <v-card-actions class="d-flex justify-end">
                    <v-btn
                        color="red"
                        small
                        v-on:click="importCounterTemplate(editedCycleCount)">
                        <v-icon small color="white">mdi-upload</v-icon><span>&nbsp;Import</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- CYCLE COUNT DIALOG -->
        <v-dialog v-model="editedCycleCount.dialog" scrollable persistent max-width="1666px" style="z-index: 60; position:relative;">
            <div v-if="importExcel.dialog" style="background-color: rgba(47,47,47,0.5); position:absolute; width:100%; height:100%; z-index: 70;"></div>
            <v-card>
                <v-progress-linear
                    v-if="editedCycleCount.details.loading"
                    indeterminate
                    color="green"
                ></v-progress-linear>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title class="d-flex" align="center">
                            <span class="text-lg font-semibold" v-text="`${editedCycleCount.cycle_count_form_number || ``}`"></span>
                            <span
                                :class="`ml-1 badge ${Lodash.get(CYCLE_COUNT_STATUS_CLASS, editedCycleCount.status, 'badge-pending')}`"
                                v-text="Lodash.get(CYCLE_COUNT_STATUS_DESCRIPTION, editedCycleCount.status, 'STATUS NOT FOUND')">
                            </span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="toggleDialog(`editedCycleCount`)">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-card-text style="max-height: 495px; border:1px solid #e0e0e0; padding: 10px;">
                    <div>
                        <v-row dense no-gutters>
                            <v-col sm="2">
                                <v-autocomplete
                                    v-model="editedCycleCount.details.datatable.filters.type_id.selected"
                                    :items="editedCycleCount.details.datatable.filters.type_id.selection"
                                    label="Type"
                                    multiple
                                    dense
                                    outlined
                                    auto-select-first
                                    hide-details
                                    return-object
                                ></v-autocomplete>
                            </v-col>
                            <v-col sm="3">
                                <v-autocomplete
                                    v-model="editedCycleCount.details.datatable.filters.category_id.selected"
                                    :items="editedCycleCount.details.datatable.filters.category_id.selection"
                                    label="Category"
                                    multiple
                                    dense
                                    outlined
                                    auto-select-first
                                    hide-details
                                    return-object
                                ></v-autocomplete>
                            </v-col>
                            <v-col sm="2">
                                <v-autocomplete
                                    v-model="editedCycleCount.details.datatable.filters.uom_id.selected"
                                    :items="editedCycleCount.details.datatable.filters.uom_id.selection"
                                    label="UOM"
                                    multiple
                                    dense
                                    outlined
                                    auto-select-first
                                    hide-details
                                    return-object
                                ></v-autocomplete>
                            </v-col>
                            <v-col v-if="[CYCLE_COUNT_STATUS.CHECKING, CYCLE_COUNT_STATUS.COMPLETED].indexOf(editedCycleCount.status) >= 0" sm="3">
                                <v-autocomplete
                                    v-model="editedCycleCount.details.datatable.filters.status.selected"
                                    :items="editedCycleCount.details.datatable.filters.status.selection"
                                    label="Status"
                                    multiple
                                    dense
                                    outlined
                                    auto-select-first
                                    hide-details
                                    return-object
                                ></v-autocomplete>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col sm="2" class="mt-2">
                                <v-row dense no-gutters tag="form" class="d-flex" @submit.prevent="getCycleCountDetails()">
                                    <v-text-field
                                        v-model="editedCycleCount.details.datatable.search"
                                        label="Search Name/Model"
                                        autocomplete="off"
                                        dense
                                        single-line
                                        hide-details>
                                    </v-text-field>
                                    <v-icon @click="getCycleCountDetails()">mdi-magnify</v-icon>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-data-table
                            :headers="cycleCountDetailsDatatableHeader"
                            :items="editedCycleCount.details.datatable.data"
                            :options.sync="editedCycleCount.details.datatable.pagination.options"
                            :items-per-page="editedCycleCount.details.datatable.pagination.perPageDefault"
                            :footer-props="{
                                showFirstLastPage: true,
                                itemsPerPageOptions: [5, 10, 25, 250, 500, 1000]
                            }"
                            :server-items-length="editedCycleCount.details.datatable.pagination.total"
                            dense
                            style="border:1px solid #e0e0e0; margin: 0;">
                            <template v-if="[CYCLE_COUNT_STATUS.COUNTING].indexOf(editedCycleCount.status) >= 0 || [CYCLE_COUNT_STATUS.RECOUNT].indexOf(editedCycleCount.status) >= 0 && !viewOnly" v-slot:item.location="{ item }">
                                    <input
                                        class="form-control cycle-count-text-sm float-right"
                                        style="text-align: right; outline: none;cursor:pointer; margin:1px 0px 1px !important; height: 1.5rem !important; padding:0 5px !important;"
                                        type="text"
                                        min="0"
                                        :disabled="editedCycleCount.details.loading || viewOnly"
                                        v-model="item.location"
                                        @click="$event.target.focus()"
                                        @focus="$event.target.select()"
                                    >
                            </template>
                            <!-- VISIBLE ONLY IF COUNTING AND RECOUNTING -->
                            <template v-if="[CYCLE_COUNT_STATUS.COUNTING].indexOf(editedCycleCount.status) >= 0 || [CYCLE_COUNT_STATUS.RECOUNT].indexOf(editedCycleCount.status) >= 0 && !viewOnly" v-slot:item.actual_inventory_formatted="{item}">
                                <input
                                    class="form-control cycle-count-text-sm float-right"
                                    style="text-align: right; outline: none;cursor:pointer; margin:1px 0px 1px !important; height: 1.5rem !important; padding:0 5px !important;"
                                    type="number"
                                    min="0"
                                    :disabled="editedCycleCount.details.loading || viewOnly"
                                    v-model="item.actual_inventory"
                                    @click="$event.target.focus()"
                                    @focus="$event.target.select()"
                                    @keypress="pressQuantity($event)"
                                    @blur="blurQuantity(editedCycleCount.details.datatable.data, editedCycleCount.details.datatable.data.indexOf(item), 'actual_inventory')" />
                            </template>

                            <!-- VISIBLE ONLY IF CHECKING -->
                            <template v-if="[CYCLE_COUNT_STATUS.CHECKING].indexOf(editedCycleCount.status) >= 0" v-slot:item.variance="{item}">
                                <span v-if="[0,2].indexOf(editedCycleCount.status) >= 0" v-text="item.variance"></span>
                                <span v-if="editedCycleCount.status === 1" v-text="parseInt(item.actual_inventory) - parseInt(item.system_inventory)"></span>
                            </template>
                            <template
                                v-if="[
                                    CYCLE_COUNT_STATUS.FOR_APPROVAL,
                                    CYCLE_COUNT_STATUS.CHECKING,
                                    CYCLE_COUNT_STATUS.COMPLETED
                                ].indexOf(editedCycleCount.status) >= 0"
                                v-slot:item.status="{item}">
                                <div class="w-100" justify="center">
                                    <span
                                        :class="`badge ${Lodash.get(CYCLE_COUNT_DETAIL_STATUS_CLASS, item.status, 'badge-cycle-default')}`"
                                      v-text="Lodash.get(CYCLE_COUNT_DETAIL_DESCRIPTION, item.status, 'FOR COUNTING')">
                                    </span>
                                </div>
                                <!--<span
                                    v-if="[0,2].indexOf(editedCycleCount.status) >= 0"
                                    :class="`badge ${Lodash.get(CYCLE_COUNT_DETAIL_STATUS_CLASS, item.status, 'badge-cycle-default')}`"
                                    v-text="Lodash.get(CYCLE_COUNT_DETAIL_DESCRIPTION, item.status, 'FOR COUNTING')"></span>
                                <span
                                    v-if="editedCycleCount.status === 1"
                                    :class="`badge ${Lodash.get(CYCLE_COUNT_DETAIL_STATUS_CLASS, itemCycleStatus(item.system_inventory, item.actual_inventory), 'badge-cycle-default')}`"
                                    v-text="Lodash.get(CYCLE_COUNT_DETAIL_DESCRIPTION, itemCycleStatus(item.system_inventory, item.actual_inventory))"></span>-->
                            </template>
                            <template v-if="[CYCLE_COUNT_STATUS.CHECKING].indexOf(editedCycleCount.status) >= 0" v-slot:item.remarks="{item}">
                                <input
                                    :disabled="editedCycleCount.details.loading || viewOnly"
                                    @click="$event.target.focus()"
                                    @focus="$event.target.select()"
                                    class="form-control cycle-count-text-sm w-100"
                                    style="text-align: left; outline: none; margin:1px 0px 1px !important; height: 1.5rem !important; padding:0 5px !important;"
                                    type="text"
                                    v-model="item.remarks" />
                            </template>
                        </v-data-table>
                    </div>
                </v-card-text>

                <v-card-actions class="d-flex justify-space-between">
                    <div>
                        <!--VISIBLE AS LONG AS CYCLE COUNT IS ON PROGRESS-->
                        <v-btn
                            small
                            v-if="[
                                CYCLE_COUNT_STATUS.COUNTING,
                                CYCLE_COUNT_STATUS.RECOUNT,
                                CYCLE_COUNT_STATUS.CHECKING
                            ].indexOf(editedCycleCount.status) >= 0 && !viewOnly"
                            :disabled="editedCycleCount.details.loading"
                            v-on:click="prepareDownloadable(editedCycleCount)">
                            <v-icon small color="white">mdi-download</v-icon><span>&nbsp;Download Count Preparation</span>
                        </v-btn>

                        <v-btn
                            small
                            v-if="[
                                CYCLE_COUNT_STATUS.COUNTING,
                                CYCLE_COUNT_STATUS.RECOUNT,
                                CYCLE_COUNT_STATUS.CHECKING
                            ].indexOf(editedCycleCount.status) >= 0 && !viewOnly"
                            :disabled="editedCycleCount.details.loading"
                            v-on:click="exportExcel(editedCycleCount, 'counter-template')">
                            <v-icon small color="white">mdi-excel</v-icon><span>&nbsp;Export to Excel</span>
                        </v-btn>

                        <v-btn
                            small
                            v-if="[
                                CYCLE_COUNT_STATUS.COUNTING,
                                CYCLE_COUNT_STATUS.RECOUNT
                            ].indexOf(editedCycleCount.status) >= 0 && !viewOnly"
                            :disabled="editedCycleCount.details.loading"
                            v-on:click="toggleDialog(`importExcel`)">
                            <v-icon small color="white">mdi-upload</v-icon><span>&nbsp;Import Excel</span>
                        </v-btn>

                        <v-btn
                            small
                            v-if="[CYCLE_COUNT_STATUS.CHECKING].indexOf(editedCycleCount.status) >= 0 && !viewOnly"
                            :disabled="editedCycleCount.details.loading"
                            v-on:click="returnForRecount(editedCycleCount)">
                            <v-icon small color="white">mdi-clipboard-check</v-icon><span>&nbsp;Return For Recount</span>
                        </v-btn>
                    </div>
                    <div>
                        <!--VISIBLE ONLY IF COUNTING and CHECKING-->
                        <v-btn
                            small
                            v-if="[
                                CYCLE_COUNT_STATUS.COUNTING,
                                CYCLE_COUNT_STATUS.RECOUNT,
                                CYCLE_COUNT_STATUS.CHECKING
                                ].indexOf(editedCycleCount.status) >= 0 && !viewOnly"
                            :disabled="editedCycleCount.details.loading"
                            v-on:click="saveCurrentPage(editedCycleCount)">
                            <v-icon small color="white">mdi-content-save</v-icon><span>&nbsp;Save Current Page</span>
                        </v-btn>

                        <!--VISIBLE ONLY IF COUNTING-->
                        <v-btn
                            small
                            v-if="[CYCLE_COUNT_STATUS.COUNTING].indexOf(editedCycleCount.status) >= 0 || [CYCLE_COUNT_STATUS.RECOUNT].indexOf(editedCycleCount.status) >= 0 && !viewOnly"
                            :disabled="editedCycleCount.details.loading || viewOnly"
                            v-on:click="submitForChecking(editedCycleCount)">
                            <v-icon small color="white">mdi-clipboard-check</v-icon><span>&nbsp;Submit for Checking</span>
                        </v-btn>

                        <!--VISIBLE ONLY IF CHECKING-->
                        <v-btn
                            small
                            v-if="[CYCLE_COUNT_STATUS.CHECKING].indexOf(editedCycleCount.status) >= 0 && !viewOnly"
                            :disabled="editedCycleCount.details.loading"
                            v-on:click="complete(editedCycleCount)">
                            <v-icon small color="white">mdi-clipboard-check</v-icon><span>&nbsp;Complete Cycle Count</span>
                        </v-btn>

                        <!--VISIBLE ONLY IF FOR APPROVAL-->
                        <v-btn
                            small
                            v-if="editedCycleCount.status === CYCLE_COUNT_STATUS.FOR_APPROVAL && !viewOnly"
                            :disabled="editedCycleCount.details.loading"
                            @click="approve(editedCycleCount)">
                            <v-icon small color="white">mdi-clipboard-check</v-icon><span>&nbsp;Approve</span>
                        </v-btn>
                        <v-btn
                            small
                            v-if=" editedCycleCount.status === CYCLE_COUNT_STATUS.FOR_EXCEC_APPROVAL && !viewOnly"
                            :disabled="editedCycleCount.details.loading"
                            @click="user.hasAccess ? approve(editedCycleCount) : showOverride(editedCycleCount);">
                            <v-icon small color="white">mdi-clipboard-check</v-icon><span>&nbsp;Approve</span>
                        </v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <ConfirmOverrideComponentVue
            :approve_override_dialog="override.dialog"
            :departments_allowed="override.departments_allowed"
            :heading="override.heading"
            @closeConfirmOverride="closeConfirmOverride"
        ></ConfirmOverrideComponentVue>

        <!-- CYCLE COUNT LIST -->
        <v-row no-gutters dense tag="div">
            <v-card class="w-100" tile>
                <v-progress-linear
                    v-if="loading.state"
                    indeterminate
                    color="green"
                ></v-progress-linear>
                <v-row dense no-gutters tag="div" class="px-2 pt-2">
                    <v-spacer></v-spacer>
                    <v-col sm="3">
                        <v-row dense no-gutters tag="form" class="d-flex" @submit.prevent="list()">
                            <v-text-field
                                dense
                                autocomplete="off"
                                v-model="cycleCount.datatable.search"
                                label="Search CCF Number"
                                single-line
                                hide-details>
                            </v-text-field>
                            <v-icon @click="list()">mdi-magnify</v-icon>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row no-gutters dense tag="div" class="mt-2 px-2">
                    <v-col>
                        <v-data-table
                            :headers="cycleCount.datatable.headers"
                            :items="cycleCount.datatable.data"
                            :options.sync="cycleCount.datatable.pagination.options"
                            :items-per-page="10"
                            :footer-props="{
                                showFirstLastPage: true,
                                itemsPerPageOptions: [5, 10, 25]
                            }"
                            :server-items-length="cycleCount.datatable.pagination.total">
                            <template v-slot:item.status="{item}">
                                <span
                                    :class="`badge ${Lodash.get(CYCLE_COUNT_STATUS_CLASS, item.status, 'badge-pending')}`"
                                    v-text="Lodash.get(CYCLE_COUNT_STATUS_DESCRIPTION, item.status, 'STATUS NOT FOUND')">
                                </span>
                            </template>
                            <template v-slot:item.action="{ item }">
                                <span class="text-nowrap">
                                    <v-btn text icon color="orange">
                                        <v-icon class="btn-action" small @click="view(item,true)">mdi-eye</v-icon>
                                    </v-btn>
                                    <v-btn text icon color="blue" v-if="item.status != 3">
                                        <v-icon class="btn-action" small @click="view(item,false)" >mdi-pencil</v-icon>
                                    </v-btn>
                                    <v-btn text icon color="red">
                                        <v-icon class="btn-action" small @click="destroy(item)">mdi-delete</v-icon>
                                    </v-btn>
                                </span>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card>
        </v-row>
    </div>
</template>

<script>
import swal from 'sweetalert2'
import ConfirmOverrideComponentVue from '@/views/main/Utils/ConfirmOverrideComponent.vue'
import { mapGetters } from 'vuex'
export default {
    name: "CycleCountComponent",

    props:[
        'warehouse_id',
        'form'
    ],

    components:{
        ConfirmOverrideComponentVue
    },

    mounted() {
        let that = this;

        that.getWarehouses();
        that.getItemTypes();
        that.getItemUom();
        that.getItemCategories();


        let statusSelection = [
            {value: null, text: 'For Counting'},
            {value: 0, text: 'SHORT'},
            {value: 1, text: 'OK'},
            {value: 2, text: 'OVER'}
        ];

        that.editedCycleCount.details.datatable.filters.status.data         = statusSelection;
        that.editedCycleCount.details.datatable.filters.status.selection    = statusSelection;
        that.editedCycleCount.details.datatable.filters.status.selected     = [];
        that.cycleCount.datatable.search = that.form
        that.list();
    },

    data() {
        return {
            loading: {
                title: 'Please wait',
                state: false
            },

            override: {
                dialog: false,
                heading: 'Approve',
                departments_allowed: ['AU'],
                authenticated: false,
                action: '',
            },

            viewOnly:false,

            password:{
                dialog:false,
                text:'',
                selectedCycleCount:null
            },

            ifSubmitForChecking:true,
            isCounting:false,
            editedIndex: -1,
            editedCycleCount:{
                id: null,
                dialog: false,
                cycle_count_form_number: null,
                warehouse: null,
                status: null,
                date: null,
                details: {
                    loading: false,
                    datatable : {
                        search: '',
                        filters: {
                            status : {
                                search: '',
                                data: [],
                                selection: [],
                                selected: []
                            },
                            type_id : {
                                search: '',
                                data: [],
                                selection: [],
                                selected: []
                            },
                            uom_id : {
                                search: '',
                                data: [],
                                selection: [],
                                selected: []
                            },
                            category_id : {
                                search: '',
                                data: [],
                                selection: [],
                                selected: []
                            },
                        },
                        headers: [
                            {text: 'ITEM MODEL', value: 'item.model', sortable: false},
                            {text: 'ITEM NAME', value: 'item.name', sortable: false},
                            {text: 'CATEGORY', value: 'item.item_category_text', sortable: false},
                            {text: 'TYPE', value: 'item.item_type_text', sortable: false},
                            {text: 'UOM', value: 'item.item_uom_text', sortable: false},
                            {text: 'SYSTEM INVENTORY', value: 'system_inventory_formatted', align: 'right', sortable: false},
                            {text: 'LOCATION', value: 'location', align: 'right', sortable: false},
                            {text: 'PREVIOUS ACTUAL COUNTED', value: 'previous_actual_inventory', align: 'right', sortable: false},
                            {text: 'ACTUAL COUNTED', value: 'actual_inventory_formatted', align: 'right', sortable: false},
                            {text: 'VARIANCE', value: 'variance', align: 'right', sortable: false},
                            {text: 'STATUS', value: 'status', align: 'left', sortable: false},
                            {text: 'REMARKS', value: 'remarks', align: 'left', sortable: false},
                        ],
                        data : [],
                        pagination : {
                            options: {},
                            total : 0,
                            perPageDefault: 10
                        },
                    },

                }
            },

            importExcel : {
                dialog: false,
                loading: false,
                form: {
                    excel: null
                },
                errors: {
                    headers: [
                        { text: 'ROW',  align: 'left', value: 'row', sortable: false},
                        { text: 'ERROR MESSAGE',  align: 'left', value: 'errors', sortable: false},
                    ],
                    data: []
                }
            },

            user:{
                hasAccess:false
            },

            cycleCount: {
                datatable: {
                    search: '',
                    filters: {
                        warehouse : {
                            search: '',
                            data: [],
                            selection: [],
                            selected: []
                        }
                    },
                    headers: [
                        { text: 'CCF NUMBER',  align: 'left',value: 'cycle_count_form_number', sortable: false},
                        { text: 'WAREHOUSE',  align: 'left',value: 'warehouse.name', sortable: false},
                        { text: 'TYPE',  align: 'left',value: 'type_description', sortable: false},
                        { text: 'DATE',  align: 'left',value: 'date', sortable: false},
                        { text: 'STATUS',  align: 'left',value: 'status', sortable: false},
                        { text: 'CREATED BY',  align: 'left',value: 'user.name', sortable: false},
                        { text: 'ACTIONS', align: 'center', value: 'action', sortable: false },
                    ],
                    data: [],
                    pagination : {
                        options: {},
                        total : 0,
                        perPageDefault: 10
                    },
                },
                selectedCycleCount: null,
            },

            errors: [],
            CYCLE_COUNT_INTERVALS: [
                {text:'DAILY',value:0},
                {text:'WEEKLY',value:1},
                {text:'MONTHLY',value:2},
                {text:'QUARTERLY',value:3},
                {text:'YEALY',value:4},
                {text:'EVERY 2 WEEKS',value:5},
                {text:'NONE SCHEDULED',value:-1},
            ],
            CYCLE_COUNT_TYPE: {
                NONE_SCHEDULED: 0,
                CUSTOM_SCHEDULED: 1,
                SCHEDULED_BY_INTERVAL: 2,
            },
            CYCLE_COUNT_TYPE_DESCRIPTION:{
                0 : "NONE SCHEDULED",
                1 : "CUSTOM SCHEDULED",
                2 : "SCHEDULED BY INTERVAL"
            },
            CYCLE_COUNT_STATUS:{
                FOR_APPROVAL: 0,
                COUNTING: 1,
                CHECKING: 2,
                COMPLETED: 3,
                RECOUNT: 4,
                FOR_EXCEC_APPROVAL: 5,
            },
            CYCLE_COUNT_STATUS_CLASS: {
                0 : "badge-pending",
                1 : "badge-preparing",
                2 : "badge-submitted",
                3 : "badge-completed",
                4 : "badge-danger",
                5 : "badge-warning",
            },
            CYCLE_COUNT_STATUS_DESCRIPTION: {
                0 : "FOR APPROVAL",
                1 : "FOR COUNTING",
                2 : "FOR CHECKING",
                3 : "COMPLETED",
                4 : "FOR RECOUNT",
                5 : "FOR AUDIT APPROVAL"
            },
            CYCLE_COUNT_DETAIL_STATUS: {
                INADEQUATE: 0,
                ADEQUATE: 1,
                EXCESS: 2,
            },
            CYCLE_COUNT_DETAIL_STATUS_CLASS: {
                0 : "badge-cycle-inadequate",
                1 : "badge-cycle-adequate",
                2 : "badge-cycle-excess"
            },
            CYCLE_COUNT_DETAIL_DESCRIPTION: {
                0 : "SHORT",
                1 : "MATCH",
                2 : "OVER"
            },
            SCHEDULE_TYPE: {
                DAILY: 0,
                WEEKLY: 1,
                MONTHLY: 2,
                QUARTERLY: 3,
                YEARLY: 4,
                TWO_WEEKS:5,
            },
            SCHEDULE_TYPE_DESCRIPTION: {
            0 : "DAILY",
            1 : "WEEKLY",
            2 : "MONTHLY",
            3 : "QUARTERLY",
            4 : "YEARLY",
            5 : "EVERY_2_WEEKS"
        },
        }
    },

    computed:{
        ...mapGetters([
            'GET_ITEM_TYPES',
            'GET_ALL_WAREHOUSES',
            'GET_ITEM_UOM',
            'GET_ALL_ITEM_CATEGORIES',
            'GET_SERVICE_GET_CURRENT_USER',
            'GET_CYCLE_COUNT',
            'GET_CYCLE_COUNT_DETAILS',
            'GET_SAVE_DETAIL_CYCLE_COUNT',
            'GET_EXPORT_CYCLE_COUNT',
        ]),
        cycleCountDetailsDatatableHeader: function(){
            let that = this;
            let headers = [];

            switch(that.editedCycleCount.status){
                case this.CYCLE_COUNT_STATUS.FOR_APPROVAL:
                    this.isCounting = false
                    headers = that.editedCycleCount.details.datatable.headers.filter(header => [
                        'item.model',
                        'item.name',
                        'item.item_category_text',
                        'item.item_type_text',
                        'item.item_uom_text',
                        'location',
                        'actual_inventory_formatted',
                        'status'
                    ].indexOf(header.value) >= 0)
                    break;
                case this.CYCLE_COUNT_STATUS.FOR_EXCEC_APPROVAL:
                    this.isCounting = false
                    headers = that.editedCycleCount.details.datatable.headers.filter(header => [
                        'item.model',
                        'item.name',
                        'item.item_category_text',
                        'item.item_type_text',
                        'item.item_uom_text',
                        'location',
                        'actual_inventory_formatted',
                        'status'
                    ].indexOf(header.value) >= 0)
                    break;
                case this.CYCLE_COUNT_STATUS.COUNTING:
                    this.isCounting = true
                    headers = that.editedCycleCount.details.datatable.headers.filter(header => [
                        'item.model',
                        'item.name',
                        'item.item_category_text',
                        'item.item_type_text',
                        'item.item_uom_text',
                        'location',
                        'actual_inventory_formatted'
                    ].indexOf(header.value) >= 0)
                    break;
                case this.CYCLE_COUNT_STATUS.RECOUNT:
                    this.isCounting = true
                    headers = that.editedCycleCount.details.datatable.headers.filter(header => [
                        'item.model',
                        'item.name',
                        'item.item_category_text',
                        'item.item_type_text',
                        'item.item_uom_text',
                        'system_inventory_formatted',
                        'location',
                        'actual_inventory_formatted',
                    ].indexOf(header.value) >= 0)
                    break;
                case this.CYCLE_COUNT_STATUS.CHECKING:
                                     this.isCounting = false
                    headers = that.editedCycleCount.details.datatable.headers.filter(header => [
                        'item.model',
                        'item.name',
                        'item.item_category_text',
                        'item.item_type_text',
                        'item.item_uom_text',
                        'system_inventory_formatted',
                        'previous_actual_inventory',
                        'location',
                        'actual_inventory_formatted',
                        'variance',
                        'previous_variance',
                        'status',
                        'remarks'
                    ].indexOf(header.value) >= 0)
                    break;
                case this.CYCLE_COUNT_STATUS.COMPLETED:
                       this.isCounting = false
                    headers = that.editedCycleCount.details.datatable.headers.filter(header => [
                        'item.model',
                        'item.name',
                        'item.item_category_text',
                        'item.item_type_text',
                        'item.item_uom_text',
                        'system_inventory_formatted',
                        'previous_actual_inventory',
                        'location',
                        'actual_inventory_formatted',
                        'variance',
                        'previous_variance',
                        'status',
                        'remarks'
                    ].indexOf(header.value) >= 0)
                    break;
                default: headers = []
            }
            return headers;
        }
    },

    methods: {
        getSeachFromUrl(){
            let that = this

            let form =  new URL(location.href).searchParams.get('form')
            if(form != null)
            {
                that.cycleCount.datatable.search = form
            }else {
                return false
            }
            this.$forceUpdate();
            that.list()
        },

        selectedAllFilterModuleOptions(selection, selected) {
            return selected.length === selection.length;
        },

        selectedSomeFilterModuleOptions(selection, selected) {
            return selected.length > 0 && !this.selectedAllFilterModuleOptions(selection, selected);
        },

        filterSelectionIcon(selection, selected){
            if (this.selectedAllFilterModuleOptions(selection, selected)) return 'mdi-close-box'
            if (this.selectedSomeFilterModuleOptions(selection, selected)) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },

        toggleFilterSelection(filter) {
            this.$nextTick(() => {
                if (this.selectedAllFilterModuleOptions(filter.selection, filter.selected)) {
                    filter.selected = [];
                } else {
                    filter.selected = filter.selection.map(filter => filter.value);
                }
            })
        },

        searchFilterSelection(filter){
            if (!filter.search){
                filter.selection = filter.data;
            }

            filter.selection = filter.data.filter(data => {
                return data.text.toLowerCase().indexOf(filter.search.toLowerCase()) > -1
            })
        },

        onSelectWarehouse(val){
            this.cycleCount.datatable.filters.warehouse.selected=val
        },

        async getWarehouses(){
            let payload = {
                no_virtual:true
            }
            await this.$store.dispatch('getAllWarehouses',payload).then((response)=>{
                let selection = this.GET_ALL_WAREHOUSES.reduce((selection, warehouse)=>{
                    selection.push({
                        value: warehouse.value,
                        text: warehouse.text
                    });

                    return selection;
                },[]);

                this.cycleCount.datatable.filters.warehouse.selection = selection;
                this.cycleCount.datatable.filters.warehouse.data = selection;
            });
            // this.getSeachFromUrl()
        },

        getItemTypes(){
            let that = this;
            that.$store.dispatch('getItemTypes').then((response)=>{
                let selection = that.GET_ITEM_TYPES.reduce((selection, filterItem)=>{
                    selection.push({
                        value: filterItem.value,
                        text: filterItem.text
                    });

                    return selection;
                },[]);

                that.editedCycleCount.details.datatable.filters.type_id.data = selection;
                that.editedCycleCount.details.datatable.filters.type_id.selection = selection;
                that.editedCycleCount.details.datatable.filters.type_id.selected = selection
            });
        },

        getItemUom(){
            let that = this;
            this.$store.dispatch('getItemUom').then((response)=>{
                let selection = this.GET_ITEM_UOM.data.reduce((selection, filterItem)=>{
                    selection.push({
                        value: filterItem.value,
                        text: filterItem.text
                    });

                    return selection;
                },[]);

                that.editedCycleCount.details.datatable.filters.uom_id.data = selection;
                that.editedCycleCount.details.datatable.filters.uom_id.selection = selection;
                that.editedCycleCount.details.datatable.filters.uom_id.selected = [];
            });
        },

        async getItemCategories(){
            let that = this;

            await that.$store.dispatch('getAllItemCategories').then((response)=>{

                let selection = that.GET_ALL_ITEM_CATEGORIES.reduce((selection, filterItem)=>{
                    selection.push({
                        value: filterItem.id,
                        text: filterItem.name
                    });

                    return selection;
                },[]);

                that.editedCycleCount.details.datatable.filters.category_id.data = selection;
                that.editedCycleCount.details.datatable.filters.category_id.selection = selection;
                that.editedCycleCount.details.datatable.filters.category_id.selected = [];
            });
            let payload = {
                access : 'AU'
            }
            await this.$store.dispatch('serviceGetCurrentUser',payload).then(response=>{
                this.GET_SERVICE_GET_CURRENT_USER.access_controls.forEach(e => {

                    if(e.department == 'AU'){
                        e.access_control = JSON.parse(e.access_control);
                        that.user.hasAccess = e.access_control.cycleCount.approve
                    }
                });
            })
        },

        toggleLoading(){
            this.loading.state = !this.loading.state;
        },

        resetSelections(){
            this.warehouse.value = null;
            this.cycleCountTemplate.value = null;
        },

        setCycleCountDetailsStatusFilter(includeAdequate = false){

            if(includeAdequate){
                this.editedCycleCount.details.datatable.filters.status.selected = [];
            } else {
                this.editedCycleCount.details.datatable.filters.status.selected = [null,0,2];
            }
        },

        resetEdited() {
            let that = this;

            that.editedIndex = -1;

            that.editedCycleCount = Object.assign({},{
                id: null,
                dialog: that.editedCycleCount.dialog,
                cycle_count_form_number: null,
                warehouse: null,
                status: null,
                date: null,
                details: {
                    loading: false,
                    datatable : {
                        search: '',
                        filters: that.editedCycleCount.details.datatable.filters,
                        headers: that.editedCycleCount.details.datatable.headers,
                        data : [],
                        pagination : {
                            options: {},
                            total : 0,
                            perPageDefault: 10
                        },
                    },
                }
            });
        },

        toggleDialog(dialogable){
            let that = this;

            if (that.loading.state || !dialogable || that.editedCycleCount.details.loading || that.importExcel.loading) {
                return false;
            }

            dialogable = dialogable.split('.');

            if(dialogable.length === 1 ){
                that[dialogable].dialog = !that[dialogable].dialog;
            } else {

                let path = that;

                dialogable.forEach(i => {
                    path = path[i];
                });

                path.dialog = !path.dialog;
            }
        },

        list(page = 1){
            let that = this;

            that.cycleCount.datatable.pagination.options.page = page;

            if (that.loading.state) {
                return false;
            }

            that.toggleLoading();
            let payload = {
                page: page,
                    itemsPerPage:       that.cycleCount.datatable.pagination.options.itemsPerPage
                    || that.cycleCount.datatable.pagination.perPageDefault,
                    ccfNumber:          that.cycleCount.datatable.search,
                    warehouse_id:       [that.warehouse_id]
            }
            this.$store.dispatch('getCycleCount',payload).then((response)=>{
                that.cycleCount.datatable.data              = this.GET_CYCLE_COUNT.cycle_counts.data;
                that.cycleCount.datatable.pagination.total  = this.GET_CYCLE_COUNT.cycle_counts.total;
                that.toggleLoading();
            });
        },

        getCycleCountDetails(page = 1){
            let that = this;

            that.editedCycleCount.details.datatable.pagination.options.page = page;

            if (that.editedCycleCount.details.loading || that.editedCycleCount.id === null) {
                return false;
            }
            let payload = {
                id: that.editedCycleCount.id,
                params: {
                    ifSubmitForChecking:  this.ifSubmitForChecking,
                    page: page,
                    itemsPerPage:       that.editedCycleCount.details.datatable.pagination.options.itemsPerPage
                        || that.editedCycleCount.details.datatable.pagination.perPageDefault,
                    search:             that.editedCycleCount.details.datatable.search,
                    status:             that.editedCycleCount.details.datatable.filters.status.selected,
                    type_id:            that.editedCycleCount.details.datatable.filters.type_id.selected,
                    uom_id:             that.editedCycleCount.details.datatable.filters.uom_id.selected,
                    category_id:        that.editedCycleCount.details.datatable.filters.category_id.selected
                }
            }
            that.editedCycleCount.details.loading = true;
            this.$store.dispatch('showCycleCountDetails',payload).then((response) => {
                that.editedCycleCount.details.datatable.data = this.GET_CYCLE_COUNT_DETAILS.cycle_count_details.data;
                that.editedCycleCount.details.datatable.pagination.total = this.GET_CYCLE_COUNT_DETAILS.cycle_count_details.total;
                that.editedCycleCount.details.loading = false;
            });
        },

        view(cycleCount,type){
            let that = this;

            this.viewOnly = type;

            if(that.loading.state){
                return false;
            }

            that.toggleLoading();

            let action = null;

            if ([
                this.CYCLE_COUNT_STATUS.COUNTING,
                this.CYCLE_COUNT_STATUS.RECOUNT,
                this.CYCLE_COUNT_STATUS.CHECKING,
                this.CYCLE_COUNT_STATUS.COMPLETED

                ].indexOf(cycleCount.status) >= 0) {
                switch(cycleCount.status){
                    case this.CYCLE_COUNT_STATUS.COUNTING:
                        that.setCycleCountDetailsStatusFilter(true);
                        action = 'can-count';
                        break;
                    case this.CYCLE_COUNT_STATUS.RECOUNT:
                        that.setCycleCountDetailsStatusFilter(true);
                        action = 'can-count';
                        break;
                    case this.CYCLE_COUNT_STATUS.CHECKING:
                    case this.CYCLE_COUNT_STATUS.COMPLETED:
                        that.setCycleCountDetailsStatusFilter(true);
                        action = 'can-check';
                        break;
                }
                if(_.isNull(action)){
                    that.toggleLoading();
                    return false;
                } else {
                    new Promise((resolve, reject) => {
                        this.$store.dispatch('postCycleCount',action).then(response=>{resolve(response)}).catch(error => reject(error));
                    }).then(resolved => {
                        setTimeout(()=>{
                            that.toggleLoading();
                            that.viewCycleCount(cycleCount);
                        }, 700);
                    }).catch(error => {
                        that.toggleLoading();
                        that.resetEdited();
                        that.errors = error.response.data.errors;
                        that.alertWarnings(error.response.data.message, that.errors);
                    });
                }
            } else {
                that.toggleLoading();
                that.setCycleCountDetailsStatusFilter(true);
                that.viewCycleCount(cycleCount);
            }

        },

        viewCycleCount(cycleCount){
            let that = this;

            that.resetEdited();
            that.editedIndex = that.cycleCount.datatable.data.indexOf(cycleCount);
            that.toggleDialog(`editedCycleCount`);

            that.editedCycleCount = Object.assign({},{
                ...that.editedCycleCount,
                id:                         cycleCount.id,
                cycle_count_form_number:    cycleCount.cycle_count_form_number,
                warehouse:                  cycleCount.warehouse,
                status:                     cycleCount.status,
                date:                       cycleCount.date
            });

            that.getCycleCountDetails(1);
        },

        approve(cycleCount){
            swal.fire({
                title: `Confirm Cycle Count Approve : ${cycleCount.cycle_count_form_number}`,
                text: `Warning : approving a cycle count will prevent warehouse's inventory movement.\n\n
                    Will only then resume upon cycle count completion`,
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((confirm) => {
                if(confirm){
                    let that = this;

                    if (that.loading.state) {
                        return false;
                    }

                    that.toggleLoading();
                    this.$store.dispatch('approveCycleCount',cycleCount.id).then((response) => {
                        swal.fire({
                            title: `Cycle Count Approved : ${cycleCount.cycle_count_form_number}`,
                            text: ``
                        });
                        that.toggleLoading();
                        that.toggleDialog(`editedCycleCount`);
                        that.resetEdited();
                        that.errors = [];
                        that.list(that.cycleCount.datatable.pagination.options.page);

                    }).catch((error)=>{
                        that.toggleLoading();
                        that.toggleDialog(`editedCycleCount`);
                        that.resetEdited();
                        that.errors = error.response.data.errors;
                        that.alertWarnings(error.response.data.message, that.errors);
                        that.list();
                    });
                }
            });
        },

        itemCycleStatus(system, actual){
            actual =  parseInt(actual);

            if (actual < system) {
                return 0;
            } else if (actual > system) {
                return 2;
            } else {
                return 1;
            }
        },

        async saveCurrentPage(cycleCount){
            let tablePage   = 0;
            let that        = this;
            let services    = [];
            let action      = null;

            switch(cycleCount.status){
                case this.CYCLE_COUNT_STATUS.COUNTING: action = 'can-count';break;
                case this.CYCLE_COUNT_STATUS.RECOUNT: action = 'can-count';break;
                case this.CYCLE_COUNT_STATUS.CHECKING: action = 'can-check';break;
            }

            if(_.isNull(action)){
                return false;
            }

            that.editedCycleCount.details.loading = true;
            await new Promise((resolve, reject) => {
                this.$store.dispatch('postCycleCount',action).then(response=>{resolve(response)}).catch(error => reject(error));
            }).then(resolved => {
                that.editedCycleCount.details.datatable.data.forEach(detail => {
                    services.push(new Promise((resolve, reject) => {
                        let payload = {
                            id: detail.id,
                            params:{
                                table_page:             this.editedCycleCount.details.datatable.pagination.options.page,
                                actual_inventory:       detail.actual_inventory,
                                variance:               parseInt(detail.actual_inventory) - parseInt(detail.system_inventory),
                                status:                 that.itemCycleStatus(detail.system_inventory, detail.actual_inventory),
                                remarks:                detail.remarks,
                                status_action:          action,
                                location:               detail.location,
                                item: {
                                    model: detail.item.model,
                                    name: detail.item.name
                                }
                            }
                        }
                        this.$store.dispatch('saveDetailCycleCount',payload).then(response => {
                            tablePage = this.GET_SAVE_DETAIL_CYCLE_COUNT.table_page
                            resolve(response);
                        }).catch(error => {
                            reject(error);
                        });
                    }));
                });
            }).catch(error => {
                that.editedCycleCount.details.loading = false;
                that.toggleDialog(`editedCycleCount`);
                that.resetEdited();
                that.errors = error.response.data.errors;
                that.alertWarnings(error.response.data.message, that.errors);
            });

            if (services.length) {
                await Promise.allSettled(services).then(values => {

                    let failed = values.filter(result => result.status === 'rejected').map(result => result.reason);

                    if(failed.length){
                        //Get First Failed
                        let error = failed[0];

                        that.alertWarnings(error.response.data.message, error.response.data.errors);
                    } else {
                        swal.fire({
                            title: `Current Page Saved`,
                            text: `Success`,
                            icon: "info",
                            dangerMode: false,
                        });
                    }
                });

                that.editedCycleCount.details.loading = false;

                switch(cycleCount.status){
                    case this.CYCLE_COUNT_STATUS.COUNTING:
                        that.setCycleCountDetailsStatusFilter(true);
                        that.getCycleCountDetails(tablePage);
                        break;
                    case this.CYCLE_COUNT_STATUS.RECOUNT:
                        that.setCycleCountDetailsStatusFilter(true);
                        that.getCycleCountDetails(tablePage);
                        break;
                    case this.CYCLE_COUNT_STATUS.CHECKING:
                    case this.CYCLE_COUNT_STATUS.COMPLETED:
                        that.getCycleCountDetails(tablePage);
                        break;
                }
            } else {
                that.editedCycleCount.details.loading = false;
            }
            this.getCycleCountDetails(tablePage)
        },

        submitForChecking(cycleCount){

            swal.fire({
                title: `Submit Cycle Count for Checking : ${cycleCount.cycle_count_form_number}`,
                text: `Note : if there are items in the list that remains untouched, the request won't proceed.`,
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((confirm) => {
                if (confirm) {
                    this.ifSubmitForChecking = true

                    let that = this;

                    that.toggleLoading();
                    that.editedCycleCount.details.loading = true;
                    this.$store.dispatch('submitForCheckingCycleCount',cycleCount.id).then(response => {
                        swal.fire({
                            title: `Cycle Count Submitted : ${response.data.cycle_count.cycle_count_form_number}`
                        });

                        that.toggleLoading();
                        that.editedCycleCount.details.loading = false;
                        that.toggleDialog(`editedCycleCount`);
                        that.list();
                    }).catch((error)=>{
                        that.toggleLoading();
                        that.editedCycleCount.details.loading = false;
                        that.alertWarnings(error.response.data.message, error.response.data.errors);
                    });
                }
            });
        },

        async returnForRecount(cycleCount){

            await new Promise((resolve,reject)=>{
                swal.fire({
                    title: `Return Cycle Count for Recounting : ${cycleCount.cycle_count_form_number}`,
                    text: ``,
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then((confirm) => {

                    if (confirm) {

                        let that = this;

                        that.toggleLoading();
                        that.editedCycleCount.details.loading = true;
                        this.$store.dispatch('returnForRecountCycleCount',cycleCount.id).then(response => {
                            swal.fire({
                                title: `Cycle Count now Recounting : ${response.data.cycle_count.cycle_count_form_number}`
                            });

                            that.toggleLoading();
                            that.editedCycleCount.details.loading = false;
                            that.toggleDialog(`editedCycleCount`);
                            that.list();
                            that.ifSubmitForChecking = false;
                            resolve(response)
                        }).catch((error)=>{
                            reject(error)
                            that.toggleLoading();
                            that.editedCycleCount.details.loading = false;
                            that.alertWarnings(error.response.data.message, error.response.data.errors);
                        });
                    }
                });
            }).then(resolve=>{
            })
        },

        complete(cycleCount){

            swal.fire({
                title: `Confirm Cycle Count Completion : ${cycleCount.cycle_count_form_number}`,
                text: `Begin inventory adjustment by actual count.`,
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((confirm) => {
                if (confirm) {
                    let that = this;

                    that.toggleLoading();
                    that.editedCycleCount.details.loading = true;
                    this.$store.dispatch('completeCycleCount',cycleCount.id).then(response => {
                        swal.fire({
                            title: `Cycle Count Completed : ${response.data.cycle_count.cycle_count_form_number}`,
                            text: `${response.data.cycle_count.warehouse.name} Inventory Adjusted.`
                        });

                        that.toggleLoading();
                        that.editedCycleCount.details.loading = false;
                        that.toggleDialog(`editedCycleCount`);
                        that.list();
                    }).catch((error)=>{
                        that.toggleLoading();
                        that.editedCycleCount.details.loading = false;
                        that.alertWarnings(error.response.data.message, error.response.data.errors);
                    });
                }
            });
        },

        destroy(cycleCount){
            swal.fire({
                title: "Confirm Delete",
                text: `${cycleCount.cycle_count_form_number}`,
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((confirm) => {
                if(confirm){
                    let that = this;

                    if (that.loading.state) {
                        return false;
                    }
                    that.toggleLoading();
                    this.$store.dispatch('destroyCycleCount',cycleCount.id).then((response) => {
                        swal.fire({title: `Cycle Count Deleted : ${cycleCount.cycle_count_form_number}`});
                        that.toggleLoading();
                        that.errors = [];
                        that.list();
                    }).catch((error)=>{
                        that.toggleLoading();
                        that.errors = error.response.data.errors;
                        that.alertWarnings(error.response.data.message, that.errors);
                    });
                }
            });
        },

        alertWarnings(message, errors){
            let that = this;
            let errorMessages = [];

            Object.keys(errors).forEach((key) => {

                errors[key].forEach(message => {
                    errorMessages.push(`- ${message}`);
                })
            })

            swal.fire({
                title: message,
                text: errorMessages.join("\n"),
                icon: "warning",
                dangerMode: true,
            });
        },
        async exportExcel(cycleCount, format = null){
                let that = this

                const workbook = new this.$exceljs.Workbook();
                let headerStyle = {
                    style:{font:{bold: true, name: 'Comic Sans MS'}},
                }
                let payload = {
                    id:cycleCount.id,
                    params:{
                        format: format,
                        isCounting: that.isCounting
                        }
                }
                await that.$store.dispatch('exportCyclecount',payload).then(response => {
                    const worksheet = workbook.addWorksheet('Raw Data')
                    worksheet.columns = [
                        {header:'Detail',key:'detail_id'},
                        {header:'Item',key:'item_id'},
                        {header:'Name',key:'name'},
                        {header:'Model',key:'model'},
                        {header:'location',key:'location'},
                        {header:'Actual Count',key:'actual_inventory'},
                    ]

                    that.GET_EXPORT_CYCLE_COUNT.forEach(e => {
                        worksheet.addRow(e);
                    });

                }).catch(e => {
                    swal.fire('Error !', '', 'error')
                    console.log(e)
                })
                let filename = cycleCount.cycle_count_form_number.replace(' ','').replace('#', '')
                workbook.xlsx.writeBuffer().then((data) => {
                    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                      this.$filesaver.saveAs(blob, `${filename}.xlsx`);
                });
            },
        resetImportExcel(){
            this.importExcel.errors.data = [];
            this.importExcel.form.excel = null;
            this.importExcel.loading = false;
        },

        importCounterTemplate(cycleCount){
            let that = this;
            that.importExcel.errors.data = [];
            let formData = new FormData();
            let excel = that.importExcel.form.excel;
            let warnings = [];
            if(!excel) {
                warnings.push("File is required.");
            } else {
                if (_.indexOf(['xls', 'xlsx'], excel.name.split('.').pop().toLowerCase()) === -1) {
                    warnings.push("File must be type of excel spreadsheet.");
                }

                if (excel.size > 2097152) {
                    warnings.push("File must not exceed 2 megabytes");
                }

                if(excel.name.split('.')[0].split(' ')[0] !== cycleCount.cycle_count_form_number.replace(' ','').replace('#', '')){
                    warnings.push("Excel file isn't compatible for this importer");
                }
            }

            if (warnings.length) {
                that.alertWarnings('Warning', {
                    'validation warning' : warnings
                });
                return false;
            }

            that.importExcel.loading = true;

            formData.append('excel', that.importExcel.form.excel);
            let payload = {
                id: cycleCount.id,
                params: formData
            }

            this.$store.dispatch('importCycleCountTemplate',payload, {
                onUploadProgress: function (progressEvent) {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                }
            }).then(function(response){
                that.importExcel.loading = false;
                swal.fire({
                    title: `Excel Imported`,
                    text: `Success`,
                    icon: "info",
                    dangerMode: false,
                });
                that.getCycleCountDetails(1);
                that.resetImportExcel();
                that.importExcel.dialog = false;
            }).catch(function(error){
                that.importExcel.loading = false;
                that.importExcel.form.excel = null;

                if(_.isArray(error.response.data.errors)){
                    that.importExcel.errors.data = error.response.data.errors
                } else {
                    that.alertWarnings(error.response.data.message, error.response.data.errors);
                }
            });
        },

        prepareDownloadable(cycleCount, params = {page: 1, itemsPerPage: 100}, items = []){
            let that = this;

            that.editedCycleCount.details.loading = true;

            let payload = {
                id: cycleCount.id,
                params:{
                    ...params,
                    search: that.editedCycleCount.details.datatable.search,
                    status: that.editedCycleCount.details.datatable.filters.status.selected,
                    type_id: that.editedCycleCount.details.datatable.filters.type_id.selected,
                    uom_id: that.editedCycleCount.details.datatable.filters.uom_id.selected,
                    category_id: that.editedCycleCount.details.datatable.filters.category_id.selected
                }
            }

            this.$store.dispatch('showCycleCountDetails',payload).then((response) => {
                this.GET_CYCLE_COUNT_DETAILS.cycle_count_details.data.forEach(data => {
                    items.push(data);
                })

                if(params.page < this.GET_CYCLE_COUNT_DETAILS.cycle_count_details.last_page){
                    that.prepareDownloadable(cycleCount,Object.assign({},{...params, page : params.page + 1}), items);
                } else {
                    that.buildDownloadable(cycleCount, items);
                }
            });
        },

        buildDownloadable(cycleCount, items){
            let that = this;
            let pdf = new this.$jspdf('p', 'mm', 'letter');

            let formNumberSpaced = cycleCount.cycle_count_form_number;
            let formNumber = cycleCount.cycle_count_form_number.replace(" ", "").replace("#", "");
            let fileName = `${formNumber}.pdf`;

            pdf.setFontSize(20);

            pdf.autoTable({
                theme: 'plain',
                styles: {
                    lineWidth: 0.1,
                    lineColor: '#7E7E7E'
                },
                headStyles: {
                    fillColor: false,
                    textColor: 'black',
                    fontStyle: 'bold'
                },
                bodyStyles: {
                    fillColor: false,
                    textColor: '#7E7E7E'
                },
                columnStyles: {
                    0:{textColor:'black',fontStyle:'bold',fontSize:8},
                    1:{textColor:'black',fontSize:10, cellWidth: 'auto',fontSize:8},
                    2:{textColor:'black',cellWidth: 'auto',minCellWidth: 40,fontSize:8},
                    3:{textColor:'black',halign: 'right',setFontSize:30, fontSize:8},
                    },
                columns: [
                    { header: 'ITEM MODEL', dataKey: 'model',setFontSize:20},
                    { header: 'ITEM NAME', dataKey: 'name',setFontSize:20 },
                    { header: 'LOCATION', dataKey: 'location',setFontSize:20 },
                    { header: 'ACTUAL UOM COUNTED', dataKey: 'actual_uom_counted',setFontSize:20}
                ],
                body: items.reduce((row, detail)=>{

                    let preparation = Object.assign({},{
                        model: detail.item.model,
                        name: detail.item.name,
                        actual_uom_counted: detail.actual_inventory_formatted,
                        location:detail.location,
                    });
                    row.push(preparation);
                    return row;
                }, []),
            });

            for(let page = 1; page <= pdf.internal.getNumberOfPages(); page++){
                pdf.setPage(page);
                pdf.setTextColor('#7E7E7E');
                pdf.setFontSize(10);
                pdf.text(`Page ${page} of ${pdf.internal.getNumberOfPages()}`, 14, pdf.internal.pageSize.height - 7);
            }

            pdf.save(fileName);
            that.editedCycleCount.details.loading = false;
        },

        pressQuantity(event) {
            let charCode = (event.which) ? event.which : event.keyCode;

            if(charCode < 48 || charCode > 57){
                event.preventDefault();
            } else {
                return true;
            }
        },

        blurQuantity(list, index, key){
            let quantity = list[index][key];

            list[index][key] = (!isNaN(quantity) && quantity && (Math.abs(parseInt(quantity)) > 0)) ? Math.abs(parseInt(quantity)) : 0;
        },

        closeConfirmOverride(user_id, authenticated = false) {
            let that = this
            if (authenticated) {
                that.approve(that.cycleCount.selectedCycleCount)
                that.override.dialog = false
            } else {
                that.override.dialog = false
            }
        },

        showOverride(selected){
            this.override.dialog = true
            this.cycleCount.selectedCycleCount = selected
        }
    },

    watch: {
        warehouse_id(val){

        },
        'cycleCount.datatable.pagination.options': {
            handler () {
                this.list(this.cycleCount.datatable.pagination.options.page)
            },
            deep: true,
        },
        'editedCycleCount.details.datatable.pagination.options': {
            handler () {
                this.getCycleCountDetails(this.editedCycleCount.details.datatable.pagination.options.page);
            },
            deep: true,
        },
        'warehouse.value': {
            handler() {
                this.getWarehouseItemTemplate();
            }
        },
        // 'warehouse_id': {
        //     handler(val){
        //          console.log(val)
        //          this.cycleCount.datatable.search = ''
        //     }
        // }
    },
}
</script>

<style scoped>
.cycle-count-text-xs{
    font-size: .8rem !important;
    line-height: 1rem;
}

.cycle-count-text-sm{
    font-size: 1rem !important;
    line-height: 1rem;
}
</style>
