<template>
  <div class="w-100">
    <v-app id="my_vue_app">
      <v-card>
        <v-card-title>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="8" class="d-flex justify-content-start">
                <v-row dense>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      v-model="filter.department"
                      :items="GET_LIST_DEPARTMENT"
                      item-text="text"
                      item-value="value"
                      outlined
                      dense
                      multiple
                      chips
                      small-chips
                      deletable-chips
                      label="Department"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      v-model="filter.location"
                      :items="GET_ADMIN_LOCATION"
                      item-text="text"
                      item-value="value"
                      outlined
                      dense
                      multiple
                      chips
                      small-chips
                      deletable-chips
                      label="Location"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-btn @click="getAllPurchaseRequest()">FILTER</v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="4">
                <div class="row col-md-12 align-items-end">
                  <v-text-field
                    id="searchBar"
                    v-model="filter.search"
                    label="Search by RRF/Requestor"
                    single-line
                    hide-details
                    @change="searchInput()"
                  >
                  </v-text-field>
                  <v-icon @click="searchInput()">mdi-magnify</v-icon>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>

        <v-container fluid>
          <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            :options.sync="options"
            :server-items-length="totalItems"
            :items-per-page="10"
            :loading="!loaded"
            :footer-props="{
              itemsPerPageOptions: itemsPerPageOptions,
            }"
            loading-text="Loading... Please wait"
            fixed-header
          >
            <template v-slot:[`item.status`]="{ item }">
              <a
                class="ml-1 badge badge-primary text-white"
                v-if="item.status == 1"
                >pending</a
              >
              <a
                class="ml-1 badge badge-warning text-white"
                v-if="item.status == 2"
                >for admin</a
              >
              <a
                class="ml-1 badge badge-success text-white"
                v-if="item.status == 3"
                >approved</a
              >
            </template>
            <template v-slot:[`item.department_id`]="{ item }">
              <span>{{
                GET_LIST_DEPARTMENT.find((e) => e.value == item.department_id)?GET_LIST_DEPARTMENT.find((e) => e.value == item.department_id)
                  .text:''
              }}</span>
            </template>
            <template v-slot:[`item.total_cost`]="{ item }">
              <span>{{ item.total_cost | currency }}</span>
            </template>
            <template v-slot:[`item.location_id`]="{ item }">
              <span>{{
               GET_ADMIN_LOCATION.find((e) => e.value == item.location_id)? GET_ADMIN_LOCATION.find((e) => e.value == item.location_id).text:''
              }}</span>
            </template>
            <template v-slot:[`item.repairtype_id`]="{ item }">
              <span>{{
               GET_ADMIN_REPAIR_TYPE_SELECTION.find(
                  (e) => e.value == item.repairtype_id
                )? GET_ADMIN_REPAIR_TYPE_SELECTION.find(
                  (e) => e.value == item.repairtype_id
                ).text:''
              }}</span>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn text icon color="orange" v-if="userAccess.view">
                <v-icon
                  class="btn-action"
                  small
                  @click="ViewAssets(item, 'View')"
                  >mdi-eye</v-icon
                >
              </v-btn>
              <v-btn
                text
                icon
                color="blue"
                v-if="
                  userAccess.edit &&
                  item.status != 3 &&
                  userAccess.edit &&
                  item.status != 2
                "
              >
                <v-icon
                  class="btn-action"
                  small
                  @click="ViewAssets(item, 'Update')"
                  >mdi-square-edit-outline</v-icon
                >
              </v-btn>
              <v-btn
                text
                icon
                color="red"
                v-if="
                  userAccess.delete &&
                  item.status != 3 &&
                  userAccess.edit &&
                  item.status != 2
                "
              >
                <v-icon
                  class="btn-action"
                  small
                  @click="deleteAccountability(item.id)"
                  >mdi-delete</v-icon
                >
              </v-btn>
            </template>
          </v-data-table>
        </v-container>
      </v-card>
    </v-app>
  </div>
</template>
  <script>
import swal from "sweetalert2";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      loaded: false,
      totalItems: 0,
      options: {},
      items: [],
      itemsPerPageOptions: [5, 10, 15, 50, 100],
      itemsPerPage: 10,
      search: "",
      filter: {
        search: "",
        location: [],
        department: [],
      },
      awaitingSearch: false,
      headers: [
        { text: "RRF #", align: "left", value: "rrf_number" },
        { text: "Department", align: "left", value: "department_id" },
        { text: "Location", align: "left", value: "location_id" },
        { text: "Repair Type", align: "left", value: "repairtype_id" },
        { text: "Requested By", align: "left", value: "full_name" },
        { text: "Priorities", align: "left", value: "priorities" },
        { text: "Total Cost", align: "left", value: "total_cost" },
        { text: "Status", align: "left", value: "status" },
        { text: "Action", align: "center", value: "action", sortable: false },
      ],
      count: 0,
      userAccess: {
        view: false,
        create: false,
        edit: false,
        delete: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      "USER_ACCESS",
      "GET_LIST_DEPARTMENT",
      "GET_ADMIN_LOCATION",
      "GET_RRF_DISPATCH",
      "GET_ADMIN_REPAIR_TYPE_SELECTION",
      "GET_NEW_RRF_TABLE",
      "GET_RRF_DISPATCH_STATUS",
    ]),
  },
  mounted() {
    this.$store.dispatch(
      "checkAccessUser",
      this.$router.currentRoute.params.id
    );
  },
  methods: {
    getAllPurchaseRequest(filter = null) {
      this.items = [];
      this.loaded = false;
      let payload = {
        page: this.options.page,
        itemsPerPage: this.options.itemsPerPage,
        search: this.filter.search,
        sortBy: this.options.sortBy,
        sortDesc: this.options.sortDesc,
        location: JSON.stringify(this.filter.location),
        department: JSON.stringify(this.filter.department),
        status: this.GET_RRF_DISPATCH_STATUS.tab_name,
      };
      this.$store
        .dispatch(this.GET_RRF_DISPATCH.get, payload)
        .then((response) => {
          this.items = response.data.data;
          this.loaded = true;
          this.$store.commit("NEW_RRF_TABLE", false);
          this.totalItems = response.data.total;
        })
        .catch((e) => {
          this.loaded = true;
        });
    },
    searchInput() {
      this.getAllPurchaseRequest({ query: this.search });
    },
    ViewAssets(item, action) {
      this.$store.dispatch('getAdminSupplierSelection');
      this.$store.dispatch("getRRFItems", { id: item.id });
      this.$store.commit("EDIT_RRF", item);
      this.$store.commit("DIALOG", true);
      this.$store.commit("ACTION", action);
    },
    deleteAccountability(id) {
      swal
        .fire({
          icon: 'question',
          text: "Are you sure you want to Delete?",
          cancelButtonText: "Cancel",
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonColor: "#397373",
          cancelButtonColor: "#397373",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$store
              .dispatch(this.GET_RRF_DISPATCH.delete, id)
              .then((success) => {});
          }
        });
    },
  },
  watch: {
    GET_NEW_RRF_TABLE: {
      handler(val) {
        if (val) {
          this.getAllPurchaseRequest();
        }
      },
    },
    options: {
      handler() {
        this.getAllPurchaseRequest();
      },
      deep: true,
    },
    USER_ACCESS: {
      handler(val) {
        if (val != "fail") {
          this.userAccess.edit = false;
          this.userAccess.delete = false;
          this.userAccess.view = false;
          this.userAccess.create = false;
          val.forEach((e) => {
            if (e.actions_code == "edit") {
              this.userAccess.edit = true;
            } else if (e.actions_code == "delete") {
              this.userAccess.delete = true;
            } else if (e.actions_code == "view") {
              this.userAccess.view = true;
            } else if (e.actions_code == "create") {
              this.userAccess.create = true;
            }
          });
        }
      },
    },
  },
};
</script>