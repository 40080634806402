<template>
    <div class="tabs-body-color">
        <v-container fluid class="pa-0">
            <v-row class="pt-4 pl-2" v-if="userAccess.create">
                <v-col cols="12" class="d-flex no-block align-items-center">
                    <div class="w-100 d-flex pl-0 mr-4">
                        <v-btn class="ml-1 mb-2" @click="AddDialog()">
                            <v-icon>mdi-plus</v-icon>Add
                        </v-btn>
                        <v-btn class="ml-1 mb-2 ml-auto" @click="exportAccountability()">
                            <v-icon>mdi-file-export</v-icon>Export
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <AssetsAccountabilitysheetTableComponent v-if="GET_ADMIN_TAB_IS_ACTIVE == isActive_tab"></AssetsAccountabilitysheetTableComponent>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
import AssetsAccountabilitysheetTableComponent from '@/views/main/modules/Admin/Accountability/AssetsAccountabilitysheetTableComponent.vue';
import { mapGetters } from 'vuex'
import Swal from "sweetalert2";
export default {
    mixins: [SharedFunctionsComponentVue],
    components: {
        AssetsAccountabilitysheetTableComponent,
    },
    props:["isActive_tab"],
    data() {
        return {
            userAccess:{
                create:false
            }
        }
    },
    computed: {
        ...mapGetters(['USER_ACCESS','GET_ADMIN_TAB_IS_ACTIVE','GET_ADMIN_ITEMS_COLLECTIONS'])
    },
    mounted() {},
    methods: {
        exportAccountability() {
            if (this.isActive_tab == 'accountability') {
                this.$store.dispatch('exportAccountability', {export_type: 2}).then(response => {
                    const exportSheets = {
                        'SUMMARY': {
                            headers: [
                                { header: "ACCOUNTABILITY #",  key: 'accountability_num',  width:10, style:{alignment: {horizontal: 'center'}} },
                                { header: "DEPARTMENT", key: 'department',  width:35, style:{alignment: {horizontal: 'center'}}},
                                { header: "FULL NAME", key: 'full_name',  width:35, style:{alignment: {horizontal: 'center'}}},
                                { header: "LOCATION", key: 'location',  width:35, style:{alignment: {horizontal: 'center'}}},
                                { header: "PREPARED BY", key: 'prepared_by',  width:25, style:{alignment: {horizontal: 'center'}} },
                                { header: "UPDATED BY", key: 'updated_by',  width:25, style:{alignment: {horizontal: 'center'}} },
                                { header: "STATUS", key: 'status',  width:12, style:{alignment: {horizontal: 'center'}} },
                                { header: "FILED", key: 'filed',  width:12, style:{alignment: {horizontal: 'center'}} },
                                { header: "FILED DATE", key: 'filed_date',  width:25, style:{alignment: {horizontal: 'center'}}, formatAs: 'date' },
                            ],
                            data: [
                                ...response.summary.map(e =>{
                                    // e.filed_date = new Date(e.filed_date);
                                    return e
                                })
                            ],
                        },
                        'DETAILED': {
                            headers: [
                                { header: "ACCOUNTABILITY #", key: 'accountability_num', width: 12, style:{alignment: {horizontal: 'center'}} },
                                { header: "DEPARTMENT", key: 'department',  width:35, style:{alignment: {horizontal: 'center'}}},
                                { header: "FULL NAME", key: 'full_name',  width:35, style:{alignment: {horizontal: 'center'}}},
                                { header: "LOCATION", key: 'location', width: 35,  style:{alignment: {horizontal: 'center'}} },
                                { header: "PREPARED BY", key: 'prepared_by', width: 25,  style:{alignment: {horizontal: 'center'}} },
                                { header: "UPDATED BY", key: 'updated_by',  width:25, style:{alignment: {horizontal: 'center'}} },
                                { header: "STATUS", key: 'status',  width:12, style:{alignment: {horizontal: 'center'}} },
                                { header: "FILED", key: 'filed',  width:12, style:{alignment: {horizontal: 'center'}} },
                                { header: "FILED DATE", key: 'filed_date',  width:25, style:{alignment: {horizontal: 'center'}}, formatAs: 'date' },
                                { header: "ASSET CODE", key: 'asset_code', width: 18,  style:{alignment: {horizontal: 'center'}} },
                                { header: "ASSET NAME", key: 'item_name', width: 25,  style:{alignment: {horizontal: 'center'}} },
                                { header: "PREVIOUS ACCOUNTABILITY #", key: 'previous_accountability_num', width: 28, style:{alignment: {horizontal: 'center'}} },
                            ],
                            data: [
                                ...response.detailed.map(e =>{
                                    // e.filed_date = new Date(e.filed_date);
                                    return e
                                })
                            ],
                        }
                    };
                    this.exportExcel(exportSheets, `ACCOUNTABILITY SHEET`,'F69F1A');
                });
            }
            else if (this.isActive_tab == 'unaccounted') {
                this.$store.dispatch('exportAssets').then(response => {
                    const exportSheets = {
                        'UNACCOUNTED ASSETS': {
                            headers: [
                                { header: "ASSET #", key: 'asset_num' },
                                { header: "DESCRIPTION", key: 'description' },
                                { header: "BRAND", key: 'brand_name' },
                                { header: "CLASSIFICATION", key: 'classification' },
                                { header: "SUB CLASSIFICATION", key: 'sub_classification' },
                                { header: "ASSET CODE", key: 'asset_code' },
                                { header: "INVENTORY", key: 'total_stocks' },
                                { header: "SUPPLIER", key: 'vendor_name' },
                                { header: "AMOUNT", key: 'cost', style: { numFmt: '#,##0.00' }, formatAs: 'float' },
                                { header: "STATUS NAME", key: 'status_name' },
                                // { header: "ITEM NAME", key: 'item_name' },
                                // { header: "LOCATION", key: 'location' },
                            ],
                            data: [
                                ...response,
                            ],
                        },
                    };
                    this.exportExcel(exportSheets, `UNACCOUNTED ASSETS`,'F69F1A');
                });
            }
        },
        AddDialog(){
            if(this.GET_ADMIN_TAB_IS_ACTIVE == 'unaccounted' && this.GET_ADMIN_ITEMS_COLLECTIONS.length == 0){
                Swal.fire({
                    title:'please select items'
                })
                return false;
            }
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", "Submit");
        }
    },
    watch:{
        USER_ACCESS:{
            handler(val){
                if(val != "fail"){
                    this.userAccess.create = false;
                    val.forEach(e => {
                        if (e.actions_code == 'create') {
                            this.userAccess.create = true;
                        }
                    });
                }
            }
        }
    }
};
</script>
