
import WarehouseCalendar from "@/views/main/modules/Operations/WarehouseCalendar/WarehouseCalendarGroupComponent.vue";
import repository from "@/api/credentials";

const routes = [{
    path: "/operations/warehouse-calendar/:id",
    name: "warehousecalendar",
    component: WarehouseCalendar,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Warehouse Calendar'
    }
}]

export default routes;
