	<template>
		<v-container fluid >
			<h3 class="page-title">Employee Memo</h3>
			<v-container class="ma-0">
                <v-app id="item-tabs">
                    <v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" background-color="#424242" dark show-arrows>
                        <v-tabs-slider color="cyan"></v-tabs-slider>
                            <template v-for="(item, i) in items">
                                <v-tab ripple v-if="tabAccess(item)" :key="i" @click="tabs(i)">
                                    <v-badge
                                        :content="GET_FOR_APPROVAL_COUNT ?? 0"
                                        :value="GET_FOR_APPROVAL_COUNT == 0 ? false : true"
                                        v-if="item.tab_name == 'For Approval'"
                                        offset-x="20"
                                        offset-y="-3"
                                        color="#f69f1a"
                                    ></v-badge>
                                    {{ item.tab_name }}
                                </v-tab>
                            </template>
                            <!-- <v-spacer></v-spacer> -->
                            <!-- <v-tab @click="tabs(4)">
                                For Approval
                            </v-tab> -->
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                        <v-tab-item v-for="(table, index) in employee_memo_component" :key="index" v-if="tabAccess(table)">
                            <keep-alive>
                                <component :is="table.components" :export_file_name="table.export_file_name" :isActive_tab="table.status_text">
                                </component>
                            </keep-alive>
                        </v-tab-item>
                    </v-tabs-items>
                </v-app>
			</v-container>
			<DialogComponent :cp="components" :scrollable="dialog_scrollable" :width="height(dialog_width)" :retain-focus="false"></DialogComponent>
		</v-container>
	</template>

<script>
	import DialogComponent from '@/views/main/layouts/dialogs/Dialog.vue';
    import EmployeeMemoComponent from '@/views/main/modules/HR/EmployeeMemo/EmployeeMemoComponent.vue'

    import EmployeeMemoComponentView from '@/views/main/layouts/dialogs/HR/EmployeeMemo/EmployeeMemoComponentView.vue'
    import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue'


	import { mapGetters } from 'vuex';
	export default {
        mixins:[SharedFunctionsComponentVue],
		components:{
			DialogComponent,
            EmployeeMemoComponent,
            EmployeeMemoComponentView,
		},
		data() {
			return {
				components:'',
				items: [
                    {tab_name:"Tardiness", access:'tardiness'},
                ],
				employee_memo_component: [
                    {
                        components: EmployeeMemoComponent,
                        access:'tardiness',
                        status_text: "tardiness",
                        dialog: EmployeeMemoComponentView,
                        dialog_title:'Schedule Template',
                        dialog_width: "50%",
                        scrollable:true,
                        dispatch:
                            {
                                get:'getEmployeeForMemo',
                            },
                    },
				],
				dialog_width: "",
				loaded: false,
				options: {},
				itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
				itemsPerPage: 10,
				tab: null,
				dialog_scrollable:true,
			};
		},
		computed:{
			...mapGetters([
                'USERACCOUNT_LOAD',
                'USER_ACCESS',
                'GET_HR_TAB_IS_ACTIVE',
                'GET_FOR_APPROVAL_COUNT'
            ])
		},
		mounted(){
            this.tabs(0)
            this.$store.dispatch('checkAccessUser',this.$router.currentRoute.params.id);
		},
		methods:{
            showEmpScheduleTab(){
                let index = this.findIndexInArrayObjects(this.employee_memo_component, 'status_text', 'employee_schedule');
                this.tab = index - 1;
                this.tabs(index)
            },
            removeEchoListener() {
                window.Echo.channel('ingco-channel').stopListening('RealtimeHR', this.echoListener);
            },
            tabs(key) {
                this.components = this.employee_memo_component[key].dialog;
                this.dialog_width = this.employee_memo_component[key].dialog_width;
                this.dialog_scrollable = this.employee_memo_component[key].scrollable;

                this.$store.commit('HR_DIALOG_TITLE',this.employee_memo_component[key].dialog_title)
                this.$store.commit('HR_TAB_IS_ACTIVE',this.employee_memo_component[key].status_text)
                this.$store.commit('HR_DISPATCH',this.employee_memo_component[key].dispatch)
                this.$store.commit('HR_GETTERS',this.employee_memo_component[key].getters)
                this.$store.commit('EXPORT_HEADERS',this.employee_memo_component[key].exportHeaders)
            },
            getSearchFromUrl() {
                if (Object.keys(this.$route.query).length != 0) {
                    Object.keys(this.$route.query).forEach(e=>{
                        if(e == 'for_approval'){
                            this.tab = this.items.length - 1
                            this.tabs(this.tab)
                        }
                    })
                }
            },
            height(width) {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': return "100%"
                    case 'sm': return "100%"
                    case 'md': return width
                    case 'lg': return width
                    case 'xl': return width
                }
            },
            tabAccess(item){
                if(this.USER_ACCESS != "fail"){
                    if(this.USER_ACCESS.find(e=>e.actions_code == item.access)){
                        if(this.USER_ACCESS.find(e=>e.actions_code == item.access).actions_code == item.access)
                        {
                            return true
                        }
                    }
                }
            },
        },
        watch:{
            USERACCOUNT_LOAD:{
                handler(val){
                }
            },
            USER_ACCESS:{
                handler(val){
                    // if(!_.isEmpty(val)){
                    //     let filtered = this.employee_memo_component.filter((x) =>{
                    //         if(val.find(e=> e.actions_code == x.access)){
                    //             return x
                    //         }
                    //     })
                    //     this.employee_memo_component = filtered;
                    //     console.log(this.employee_memo_component)
                    //     this.tabs(0)
                    // }
                },
                immediate: true
            }
        }

	};
	</script>
	<style>
	#group-tab {
		padding: 0 !important;
	}
	</style>

