<template>
    <v-container>
        <ComponentHeader
                :button="true"
                :title="'Item Sales Details Count'"
                :type="'Sales'"
        ></ComponentHeader>
        <v-row>
        <div class="container bg-bluish-gray default-container m-3">
        <v-app id="load_bar" v-if="!loaded">
            <v-text-field color="info" loading disabled></v-text-field>
        </v-app>

        <v-dialog v-model="loading.dialog" width="500">
            <v-card>
                <v-card-title >
                    <span style="font-size:12px">
                        Processing: {{ loading.text }}
                    </span>
                </v-card-title>
                <v-card-text>
                    <v-progress-linear
                        v-model="loading.value"
                        :buffer-value="loading.bufferValue"
                        color="green"
                    >
                    </v-progress-linear>
                    {{Math.ceil(loading.value)}}%
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-app id="my_vue_app"  class="bg-bluish-gray">
            <div class="row">

                <div class="col-md-7"></div>
                <div class="col-sm-6 col-md-5 text-right float-right pull-right">
                <v-btn icon color="#f69f1a" text @click="resetFields()" :loading="!loaded" class="mx-3"><v-icon>mdi-cancel</v-icon></v-btn>
                    <v-menu
                        transition="slide-y-transition"
                        bottom
                        :close-on-content-click="false"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                text
                                color="#f69f1a"
                                v-bind="attrs"
                                v-on="on"
                                :loading="!loaded"
                            >
                              <v-icon>mdi-cloud-download</v-icon></v-btn>
                        </template>
                        <v-list>
                            <v-list-item>
                                <v-card
                                    class="mx-auto"
                                    max-width="400"
                                    max-height="200"
                                >
                                    <v-card-text>
                                        <v-chip-group
                                            v-model="filters.selectedDetails"
                                            column
                                            multiple
                                        >
                                            <v-chip
                                                filter
                                                outlined
                                            >
                                            SQ({{ this.chipCounts.SQ }})
                                            </v-chip>
                                            <v-chip
                                                filter
                                                outlined
                                            >
                                            SO({{ this.chipCounts.SO }})
                                            </v-chip>
                                            <v-chip
                                                filter
                                                outlined
                                            >
                                            WPF({{ this.chipCounts.WPF }})
                                            </v-chip>
                                            <v-chip
                                                filter
                                                outlined
                                            >
                                            CF({{ this.chipCounts.CF }})
                                            </v-chip>
                                            <v-chip
                                                filter
                                                outlined
                                            >
                                            SC({{ this.chipCounts.SC }})
                                            </v-chip>
                                            <v-chip
                                                filter
                                                outlined
                                            >
                                            PL({{ this.chipCounts.PL }})
                                            </v-chip>
                                        </v-chip-group>
                                    </v-card-text>
                                    <v-card-text>
                                        <v-btn small icon text color="green" @click="exportToExcel()" class="pull-right float-right mx-2 mr-6 pr-8"><v-icon>mdi-cloud-download</v-icon></v-btn>
                                    </v-card-text>
                                </v-card>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-menu
                        transition="slide-y-transition"
                        bottom
                        :close-on-content-click="false"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                class="mx-3"
                                icon
                                text
                                color="#f69f1a"
                                v-bind="attrs"
                                v-on="on"
                                :loading="!loaded"
                                @click="getAllItemSalesDetailsCount()"
                            >
                            <v-icon>mdi-sync</v-icon>
                            </v-btn>
                        </template>
                    </v-menu>
                </div>
            <div class="row ml-1">
                <div class="col-md-2 col-sm-12">
                    <v-autocomplete
                        background-color="white"
                        :items="GET_ITEM_TYPES"
                        :loading="loading.item_type_selection"
                        :disabled="loading.item_type_selection"
                        multiple
                        deletable-chips
                        chips
                        outlined
                        dense
                        return-object
                        v-model="filters.types.selected"
                        label="Select Item Types"
                        class="d-inline-block w-100">
                    </v-autocomplete>
                </div>
                <div class="col-md-2 col-sm-12">
                    <v-autocomplete
                        background-color="white"
                        :items="GET_ITEM_CATEGORIES"
                        :loading="loading.category_selection"
                        :disabled="loading.category_selection"
                        multiple
                        deletable-chips
                        chips
                        outlined
                        dense
                        return-object
                        v-model="filters.categories.selected"
                        label="Select Categories"
                        class="d-inline-block w-100">
                    </v-autocomplete>
                </div>
                <div class="col-md-2 col-sm-12">
                    <v-autocomplete
                        background-color="white"
                        :items="GET_ITEM_SELECTION"
                        :loading="loading.item_selection"
                        :disabled="loading.item_selection"
                        multiple
                        deletable-chips
                        chips
                        outlined
                        dense
                        return-object
                        v-model="filters.items.selected"
                        label="Select Models"
                        class="d-inline-block w-100">
                    </v-autocomplete>
                </div>
                <div class="col-md-1"></div>
            </div>
        </div>
            <div class="row">
                <div class="col-sm-4 col-md-2" id="date_from">
                    <v-menu
                        v-model="filters.date.menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="filters.date.date_from"
                                readonly
                                append-icon="mdi-calendar-clock"
                                outlined
                                dense
                                background-color="white"
                                label="Date From: "
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="filters.date.date_from" @input="filters.date.menu2 = false"></v-date-picker>
                    </v-menu>
                </div>

                <div class="col-sm-4 col-md-2" id="date_to">
                    <v-menu
                        v-model="filters.date.menu3"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                        z-index="100"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="filters.date.date_to"
                                readonly
                                append-icon="mdi-calendar-clock"
                                outlined
                                dense
                                background-color="white"
                                label="Date to: "
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="filters.date.date_to" @input="filters.date.menu3 = false"  style="z-index:100"></v-date-picker>
                    </v-menu>
                </div>
                <div class="col-sm-4 col-md-2 ml-5" id="checkbox">
                    <v-row>
                    <v-container class="row" fluid>
                    <v-checkbox
                    color="green"
                    v-model="checkbox"
                    :true-value="1"
                    :false-value="0"
                    >
                    </v-checkbox>
                    <p  :class="{'green--text mt-5': checkbox, 'red--text mt-5 large': !checkbox}">
                    {{ checkbox ? 'Active' : 'Inactive' }} </p>
                    </v-container>
                    </v-row>
                </div>
            </div>
        </v-app>
    </div>
        </v-row>
        <div class="w-100"  >
                <v-card >
                    <v-container fluid>
                        <v-data-table
                            v-model="itemList"
                            :loading="loading.itemList"
                            :headers="tableHeaders"
                            :items="data_handler"
                            :options.sync="options"
                            :items-per-page="10"
                            :footer-props="{
                            itemsPerPageOptions: itemsPerPageOptions,
                            }"
                            :server-items-length="total"
                            class="w-100"
                            dense
                        >
                        <template v-slot:[`item.cost`]="{ item }">
                            <span>{{ item.cost | currency }}</span>
                        </template>
                        <template v-slot:[`item.full_name`]="{ item }">
                        <span v-if="item.emp_id == 0">GENERAL ACCOUNTABILITY</span>
                            <span v-else>{{ item.full_name }}</span>
                        </template>
                        </v-data-table>
                    </v-container>
                </v-card>
            </div>
    </v-container>



</template>

<script>
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
import swal from 'sweetalert2'
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent'
import { mapGetters } from 'vuex';
export default {
    data(){
        return {
            loaded: true,
            checkbox: true,
            options: {},
            items: [],
            itemList: null,
            itemsPerPageOptions: [5, 10, 15, 50,100],
            itemsPerPage: 10,
            data_handler: [],
            total: null,
            chipCounts: {
                SQ: 0,
                SO: 0,
                WPF: 0,
                CF: 0,
                SC: 0,
                PL: 0,
            },
            filters:{
                filter:1,
                date:{
                    date_from:this.$dayjs().startOf('month').format('YYYY-MM-DD'),
                    date_to:this.$dayjs().endOf('month').format('YYYY-MM-DD'),
                    menu2: false,
                    menu3: false,
                    errors:[],
                },
                items:{
                    selected:[],
                    selection:[],
                },
                categories:{
                    selected:[],
                    selection:[],
                },
                types:{
                    selected:[],
                    selection:[],
                },
                selectedDetails:[0,1,2,3,4,5,6]
            },
            tableHeaders: [
            { text: "Item Model", align: "left", value: "model" },
            { text: "Item Name", align: "left", value: "name" },
            { text: "Item Category", align: "left", value: "category_id" },
            { text: "Item Uom", align: "left", value: "uom_id" },
            { text: "Item Type", align: "left", value: "type_id" },
            { text: "Active", align: "left", value: "active" },
            { text: "SQ Count", align: "center", value: "SQ" },
            { text: "SO Count", align: "center", value: "SO" },
            { text: "WPF Count", align: "center", value: "WPF" },
            { text: "CF Count", align: "center", value: "CF" },
            { text: "SC Count", align: "center", value: "SC" },
            { text: "PL Count", align: "center", value: "PL" },
            ],
            dataTable:{
                headers:[
                    {
                        title:"SO",
                        headers:[
                            { text: 'SO#', value: 'order_num' },
                            { text: 'SO Date', value: 'order_date' },
                            { text: 'SQ#', value: 'quote_num' },
                            { text: 'SQ Date',value:'quote_date' },
                            { text: 'Customer',value: 'company_name' },
                            { text: 'Model',value: 'model' },
                            { text: 'Name',value: 'name'},
                            { text: 'Category',value: 'category'},
                            { text: 'UOM',value: 'uom'},
                            { text: 'Type',value: 'type'},
                            { text: 'Remarks',value: 'remarks'},
                            { text: 'Qty',value: 'qty'},
                        ],
                        items: [],
                        loaded:true,
                    },
                    {
                        title:"SQ",
                        headers:[
                            { text: 'SQ#', value: 'quote_num' },
                            { text: 'SQ Date',value: 'quote_date' },
                            { text: 'Customer',value: 'company_name' },
                            { text: 'Model',value: 'model' },
                            { text: 'Name',value: 'name'},
                            { text: 'Category',value: 'category' },
                            { text: 'UOM',value: 'uom'},
                            { text: 'Type',value: 'type'},
                            { text: 'Remarks',value: 'remarks'},
                            { text: 'Qty',value: 'qty'},
                        ],
                        items: [],
                        loaded:true,
                    },
                    {
                        title:"WPF",
                        headers:[
                            { text: 'SO#', value: 'order_num'},
                            { text: 'SO Date', value: 'order_date'},
                            { text: 'WPF#',value: 'wpf_num'},
                            { text: 'WPF Date', value: 'wpf_date'},
                            { text: 'Customer',value: 'company_name'},
                            { text: 'Model',value: 'model'},
                            { text: 'Name',value: 'name'},
                            { text: 'Category',value: 'category'},
                            { text: 'UOM',value: 'uom'},
                            { text: 'Type',value: 'type'},
                            { text: 'Remarks',value: 'remarks'},
                            { text: 'Qty',value: 'qty'},
                        ],
                        items: [],
                        loaded:true,
                    },
                    {
                        title:"CF",
                        headers:[
                            { text: 'SO#', value: 'order_num'},
                            { text: 'SO Date', value: 'order_date'},
                            { text: 'WPF#',value: 'wpf_num'},
                            { text: 'WPF Date', value: 'wpf_date'},
                            { text: 'CF#', value: 'cf_num'},
                            { text: 'CF Date', value: 'cf_date'},
                            { text: 'Customer',value: 'company_name'},
                            { text: 'Model',value: 'model'},
                            { text: 'Name',value: 'name'},
                            { text: 'Category',value: 'category'},
                            { text: 'UOM',value: 'uom'},
                            { text: 'Type',value: 'type'},
                            { text: 'Remarks',value: 'remarks'},
                            { text: 'Qty',value: 'qty'},
                        ],
                        items: [],
                        loaded:true,
                    },
                    {
                        title:"SC",
                        headers:[
                            { text: 'SO#', value: 'order_num'},
                            { text: 'SO Date', value: 'order_date'},
                            { text: 'WPF#',value: 'wpf_num'},
                            { text: 'WPF Date', value: 'wpf_date'},
                            { text: 'CF#', value: 'cf_num'},
                            { text: 'CF Date', value: 'cf_date'},
                            { text: 'SC#', value: 'sc_num'},
                            { text: 'SC Date', value: 'sc_date'},
                            { text: 'Customer',value: 'company_name'},
                            { text: 'Model',value: 'model'},
                            { text: 'Name',value: 'name'},
                            { text: 'Category',value: 'category'},
                            { text: 'UOM',value: 'uom'},
                            { text: 'Type',value: 'type'},
                            { text: 'Remarks',value: 'remarks'},
                            { text: 'Qty',value: 'qty'},
                        ],
                        items: [],
                        loaded:true,
                    },
                    {
                        title:"PL",
                        headers:[
                            { text: 'SO#', value: 'order_num'},
                            { text: 'SO Date', value: 'order_date'},
                            { text: 'WPF#',value: 'wpf_num'},
                            { text: 'WPF Date', value: 'wpf_date'},
                            { text: 'CF#', value: 'cf_num'},
                            { text: 'CF Date', value: 'cf_date'},
                            { text: 'Customer',value: 'company_name'},
                            { text: 'Model',value: 'model'},
                            { text: 'Name',value: 'name'},
                            { text: 'Category',value: 'category'},
                            { text: 'UOM',value: 'uom'},
                            { text: 'Type',value: 'type'},
                            { text: 'Remarks',value: 'remarks'},
                            { text: 'Qty',value: 'qty'},
                        ],
                        items: [],
                        loaded:true,
                    },
                ],

                pagination:{
                    options:{},
                    totalItems:0,
                }
            },
            loading: {
                text: '',
                bufferValue: 100,
                value: 0,
                dialog:false,
                itemList: false,
                item_type_selection         : true,
                item_selection              : true,
                category_selection          : true,
                customer_selection          : true,
                initialLoad                 : true,
            },
            filter:false,
        }
    },
    components: {
        SharedFunctionsComponent,
        ComponentHeader
    },
    mounted(){
        this.$store.dispatch('getAllcustomersSelection')
        this.$store.dispatch('getItemTypes')
        this.$store.dispatch('getItemCategories')
        this.$store.dispatch('getAllitemSelectionDefault')
    },
    methods: {
        async  getAllItemSalesDetailsCount(){
            this.data_handler = [];
            this.loading.itemList = true;
            await this.$store.dispatch('reports',{
                    page:               this.options.page,
                    itemsPerPage:       this.options.itemsPerPage,
                    sortBy:             this.options.sortBy,
                    sortDesc:           this.options.sortDesc,
                    active:             this.checkbox,
                    date_from:          this.filters.date.date_from,
                    date_to:            this.filters.date.date_to,
                    categories:         this.filters.categories.selected.map(e=>{return e.value}),
                    types:              this.filters.types.selected.map(e=>{return e.value}),
                    models:             this.filters.items.selected.map(e=>{return e.value}),
                    url:                'item-sales-details-count',
            }).then(response => {
                this.data_handler     = response.data.data.data;
                this.total            = response.data.data.total;
                this.chipCounts.SQ = this.data_handler.reduce((total, item) => total + item.SQ, 0);
                this.chipCounts.SO = this.data_handler.reduce((total, item) => total + item.SO, 0);
                this.chipCounts.WPF = this.data_handler.reduce((total, item) => total + item.WPF, 0);
                this.chipCounts.CF = this.data_handler.reduce((total, item) => total + item.CF, 0);
                this.chipCounts.SC = this.data_handler.reduce((total, item) => total + item.SC, 0);
                this.chipCounts.PL = this.data_handler.reduce((total, item) => total + item.PL, 0);

            });
            this.loading.itemList = false;
        },
        selectedCategories(val){
            this.filters.categories.selected = val
        },
        selectedTypes(val){
            this.filters.types.selected = val
        },
        selectedItems(val){
            this.filters.items.selected = val
        },
        async exportToExcel() {
            let that = this
            let workbook = new this.$exceljs.Workbook();
            let style = { alignment: { vertical: 'middle', horizontal: 'center' }, width: 100 };
            let numberFormat = {numFmt: '@'}
            let worksheet = {}
            let beginningMonthAndYear = this.$dayjs(that.filters.date.date_from)
            let endMonthAndYear = this.$dayjs(that.filters.date.date_to)
            let loopThruMonths = true
            let montDifferenecs = endMonthAndYear.diff(beginningMonthAndYear, 'month')
            let headerCounter = 100 / (parseInt(that.dataTable.headers.length) * (montDifferenecs + 1))

            that.loaded = false
            that.loading.dialog = true
                await this.$store.dispatch('reports',{
                    date_from :     that.filters.date.date_from,
                    date_to :       that.filters.date.date_to,
                    categories :    that.filters.categories.selected.map(e=>{return e.value}),
                    types:          that.filters.types.selected.map(e => { return e.value }),
                    items:          that.filters.items.selected.map(e => { return e.value }),
                    active:         that.checkbox,
                    url:'exportAllItemSalesDetailsCount'
                }).then(response => {

                    that.CountItems             = response.data;
                    that.dataTable.headers[1].loaded            = true;

                }).catch(e=>{
                    that.dataTable.headers[1].loaded = true
                })

                worksheet =  workbook.addWorksheet('Counts');
                worksheet.columns = [
                { header: "Item Model", width: 18, align: "left", key: "model" },
                { header: "Item Name", width: 35, align: "left", key: "name" },
                { header: "Item Category", width: 18, align: "left", key: "category_id" },
                { header: "Item Uom", width: 18, align: "left", key: "uom_id" },
                { header: 'Item Type', width: 18, key: 'type_id', style: JSON.parse(JSON.stringify(style)) },
                { header:'Active', width: 18, key: 'active',style: JSON.parse(JSON.stringify(style)) },
                { header:'SQ Count', width: 18, key: 'SQ' ,style: JSON.parse(JSON.stringify(style)) },
                { header:'SO Count', width: 18, key: 'SO',style: JSON.parse(JSON.stringify(style)) },
                { header:'WPF Count', width: 18, key: 'WPF',style: JSON.parse(JSON.stringify(style)) },
                { header:'CF Count', width: 18, key: 'CF',style: JSON.parse(JSON.stringify(style)) },
                { header:'SC Count', width: 18, key: 'SC',style: JSON.parse(JSON.stringify(style)) },
                { header:'PL Count', width: 18, key: 'PL',style: JSON.parse(JSON.stringify(style)) },
                    ];

                let items = that.CountItems.map(item => {
                    let newItem = { ...item };

                    if (!that.filters.selectedDetails.includes(0)) {
                        newItem.SQ = 0;
                    }
                    if (!that.filters.selectedDetails.includes(1)) {
                        newItem.SO = 0;
                    }
                    if (!that.filters.selectedDetails.includes(2)) {
                        newItem.WPF = 0;
                    }
                    if (!that.filters.selectedDetails.includes(3)) {
                        newItem.CF = 0;
                    }
                    if (!that.filters.selectedDetails.includes(4)) {
                        newItem.SC = 0;
                    }
                    if (!that.filters.selectedDetails.includes(5)) {
                        newItem.PL = 0;
                    }

                    return newItem;
                });
                worksheet.addRows(items);

            let counter  = 0
            while (loopThruMonths) {

                counter++
                let date_from = ''
                let date_to = ''
                if (beginningMonthAndYear.format('YYYY-MM-DD') == that.filters.date.date_from) {
                    date_from = beginningMonthAndYear.format('YYYY-MM-DD')
                    date_to = beginningMonthAndYear.endOf('month').format('YYYY-MM-DD')
                }

                else if (beginningMonthAndYear.format('YYYY-MM') == endMonthAndYear.format('YYYY-MM')) {
                    date_from = endMonthAndYear.startOf('month').format('YYYY-MM-DD')
                    date_to = this.$dayjs(that.filters.date.date_to).format('YYYY-MM-DD')
                }
                else {
                    date_from = beginningMonthAndYear.startOf('month').format('YYYY-MM-DD')
                    date_to = beginningMonthAndYear.endOf('month').format('YYYY-MM-DD')
                }

                that.loading.text = "SQ (" + beginningMonthAndYear.format('MMM') + ")"
                that.loading.value += headerCounter

                if (that.filters.selectedDetails.includes(0)) {
                    // sales quotation
                    await this.$store.dispatch('reports',{
                        date_from :     date_from,
                        date_to:        date_to ,
                        active:         that.checkbox,
                        categories :    that.filters.categories.selected.map(e=>{return e.value}),
                        types:          that.filters.types.selected.map(e => { return e.value }),
                        items:          that.filters.items.selected.map(e => { return e.value }),

                        url:'exportAllItemSalesDetailsCount'

                    }).then(response => {

                    response.data.data.data.forEach((e,i) => {
                        worksheet['sq'].addRow(e)
                        //SQ#
                        worksheet['sq'].getCell(`A${i + 2}`).value = parseInt(e.SQ)
                        worksheet['sq'].getCell(`A${i + 2}`).numFmt = '0'
                    })
                }).catch(e=>{
                    // swal.fire('Error in SQ !', 'An error occured', 'error')
                })
                }

                if (beginningMonthAndYear.format('YYYY-MM') == endMonthAndYear.format('YYYY-MM')) {
                    loopThruMonths = false
                }
                beginningMonthAndYear = beginningMonthAndYear.add(1, 'month')
            }

            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                this.$filesaver.saveAs(blob, `Items Sales Details ${that.filters.date.date_from}-${that.filters.date.date_to}.xlsx`);
            });

            that.loaded = true
            that.loading.dialog = false
            that.loading.value = 0
            swal.fire('Success!','','success')
        },
        thousandSeprator(amount) {
            if (amount !== '' || amount !== undefined || amount !== 0 || amount !== '0' || amount !== null) {
                return amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            } else {
                return amount;
            }
        },
        resetFields(){
            let that = this
            this.date_from =this.$dayjs().startOf('month').format('YYYY-MM-DD'),
            this.date_to = this.$dayjs().endOf('month').format('YYYY-MM-DD'),
            this.checkbox = true,
            that.filters.items.selected = []
            that.filters.categories.selected = []
            that.filters.customers.selected = ''
            that.filters.types.selected = []
            // that.filters.types.selected = that.filters.types.selected.filter(e=>{return e.value != 3})
            that.filters.date.date_from = this.$dayjs().startOf('month').format('YYYY-MM-DD')
            that.filters.date.date_to = this.$dayjs().endOf('month').format('YYYY-MM-DD')
        },
    },
    computed:{
        ...mapGetters([
        'GET_CUSTOMERS_SELECTION',
        'GET_ITEM_CATEGORIES',
        'GET_ITEM_TYPES',
        'GET_ITEM_SELECTION',
        ])
    },
    watch:{
        'GET_ITEM_TYPES':{
                handler(val){
                    this.filters.types.selection = val.data
                    this.loading.item_type_selection = false

                }
            },
        'GET_ITEM_SELECTION':{
            handler(val){
                this.filters.items.selection = val
                this.loading.item_selection = false
            }
        },
        'GET_ITEM_CATEGORIES':{
            handler(val){
                this.filters.categories.selection = val.data
                this.loading.category_selection = false
            }
        },
        options: {
            handler() {
                if(!this.loading.initialLoad){
                    this.getAllItemSalesDetailsCount();
                }else{
                    this.loading.initialLoad = false
                }
                // this.loading.itemList = false
            },
            deep: true,
        },
    }
};
</script>
<style>
    .col{
        padding: 2px !important;
    }

    .card-body{
        padding: 3px !important;
    }
</style>
