<template>
    <v-container fluid >
        <h3 class="page-title">LOW VALUE ASSETS ACCOUNTABILITY</h3>
        <v-container class="ma-0">
            <v-app id="item-tabs">
                <v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" background-color="#424242" dark show-arrows>
                    <v-tabs-slider color="cyan"></v-tabs-slider>
                    <v-tab ripple v-for="(item, i) in items" :key="item" @click="tabs(i)">
                        {{ item }}
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item  v-for="(item, i) in items" :key="i">
                        <keep-alive>
                            <component :is="assetsaccountability_tabs[i].components" :assetsaccountability_tabs="assetsaccountability_tabs" :isActive_tab="assetsaccountability_tabs[i].status_text">
                            </component>
                        </keep-alive>
                    </v-tab-item>
                </v-tabs-items>
            </v-app>
        </v-container>
            <DialogComponent :cp="components" :scrollable="dialog_scrollable" :width="width(dialog_width)" :retainfocus="false"></DialogComponent>
    </v-container>
</template>
<script>
import DialogComponent from '@/views/main/layouts/dialogs/Dialog.vue';
import AccountabilityLowValueAssetsComponent from '@/views/main/modules/Admin/AccountabilityLowValueAssets/AccountabilityLowValueAssetsComponent.vue';
// import AssetConditionComponent from '@/views/main/modules/Admin/AssetCondition/AssetConditionComponent.vue';
import AccountabilityLowValueAssetsViewComponent from '@/views/main/layouts/dialogs/Admin/AccountabilityLowValueAssetsViewComponent.vue';
// import AssetConditionViewComponent from '@/views/main/layouts/dialogs/Admin/AssetConditionViewComponent.vue';
import { mapGetters } from 'vuex';
export default {
    components:{
        DialogComponent,
        AccountabilityLowValueAssetsViewComponent,
        AccountabilityLowValueAssetsComponent,
        // AssetConditionComponent,
        // AssetConditionViewComponent,
    },
    data() {
        return {
            form: {
                code: '',
                name: '',
                sub_class:[],
                sub_class_trash:[],
            },
            components:'',
            items: ["ALL","PENDING","OUTBOUNDED"],
            assetsaccountability_tabs: [
                {
                    components: AccountabilityLowValueAssetsComponent,
                    status_text: "low-value-assets",
                    dialog: AccountabilityLowValueAssetsViewComponent,
                    dialog_title:'Accountability',
                    dialog_width: "70%",
                    scrollable:true,
                    dispatch:{
                        get:'getAllAccountability',
                        add:'AdminCreateAccountability',
                        update:'AdminUpdateAccountability',
                        delete:'AdminDeleteAccountability'
                    },
                    dataTable:[
                        { text: "AS #", align: "left", value: "accountability_num_display" },
                        { text: "Department", align: "left", value: "department_name" },
                        { text: "Full Name", align: "left", value: "full_name" },
                        { text: "Prepared By", align: "left", value: "prepared_by" },
                        { text: "Updated By", align: "left", value: "updated_by" },
                        { text: "Status", align: "center", value: "printed"},
                        { text: "Filed", align: "left", value: "filed" },
                        { text: "Filed Date", align: "left", value: "filed_date" },
                        { text: "Created Date", align: "center", value: "created_at" },
                        { text: "Action", align: "center", value: "action",sortable:false},
                    ]
                },
                {
                    components: AccountabilityLowValueAssetsComponent,
                    status_text: "low-value-assets",
                    dialog: AccountabilityLowValueAssetsViewComponent,
                    dialog_title:'Accountability',
                    dialog_width: "70%",
                    scrollable:true,
                    dispatch:{
                        get:'getAllAccountability',
                        add:'AdminCreateAccountability',
                        update:'AdminUpdateAccountability',
                        delete:'AdminDeleteAccountability'
                    },
                    dataTable:[
                        { text: "AS #", align: "left", value: "accountability_num_display" },
                        { text: "Department", align: "left", value: "department_name" },
                        { text: "Full Name", align: "left", value: "full_name" },
                        { text: "Prepared By", align: "left", value: "prepared_by" },
                        { text: "Updated By", align: "left", value: "updated_by" },
                        { text: "Status", align: "center", value: "printed"},
                        { text: "Filed", align: "left", value: "filed" },
                        { text: "Filed Date", align: "center", value: "filed_date" },
                        { text: "Created Date", align: "left", value: "created_at" },
                        { text: "Action", align: "center", value: "action",sortable:false},
                    ]
                },
                {
                    components: AccountabilityLowValueAssetsComponent,
                    status_text: "low-value-assets",
                    dialog: AccountabilityLowValueAssetsViewComponent,
                    dialog_title:'Accountability',
                    dialog_width: "70%",
                    scrollable:true,
                    dispatch:{
                        get:'getAllAccountability',
                        add:'AdminCreateAccountability',
                        update:'AdminUpdateAccountability',
                        delete:'AdminDeleteAccountability'
                    },
                    dataTable:[
                        { text: "AS #", align: "left", value: "accountability_num_display" },
                        { text: "Department", align: "left", value: "department_name" },
                        { text: "Full Name", align: "left", value: "full_name" },
                        { text: "Prepared By", align: "left", value: "prepared_by" },
                        { text: "Updated By", align: "left", value: "updated_by" },
                        { text: "Status", align: "center", value: "printed"},
                        { text: "Filed", align: "left", value: "filed" },
                        { text: "Filed Date", align: "center", value: "filed_date" },
                        { text: "Created Date", align: "left", value: "created_at" },
                        { text: "Action", align: "center", value: "action",sortable:false},
                    ]
                },
            ],
            dialog_width: "",
            loaded: false,
            options: {},
            itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
            itemsPerPage: 10,
            tab: null,
            dialog_scrollable:true,
        };
    },
    computed:{
        ...mapGetters([
            'GET_NEW_ADMIN_OTHER_TABLE',
            'GET_NEW_ADMIN_ASSETS_TABLE'
        ])
    },
    mounted(){
        this.dropDowns()
        this.tabs(0);
    },
    methods:{
        dropDowns(){
            this.$store.dispatch('getDepartment')
            this.$store.dispatch("getAdminLocationSelection");
            this.$store.dispatch("getEmployeeDetails");
            this.$store.dispatch('getAdminWarehouseCodeDropdown');
        },
        tabs(key) {
            this.$store.commit('SELECTED_TAB',key);
            this.components = this.assetsaccountability_tabs[key].dialog;
            this.dialog_width = this.assetsaccountability_tabs[key].dialog_width;
            this.dialog_scrollable = this.assetsaccountability_tabs[key].scrollable;
            this.$store.commit('ADMIN_ITEMS_COLLECTIONS',[]);
            this.$store.commit('ADMIN_TAB_IS_ACTIVE',this.assetsaccountability_tabs[key].status_text)
            this.$store.commit('ADMIN_DATA_TABLE_HEADERS',this.assetsaccountability_tabs[key].dataTable)
            this.$store.commit('ADMIN_DISPATCH',this.assetsaccountability_tabs[key].dispatch)
        },
        width(width) {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return "100%"
                case 'sm': return "100%"
                case 'md': return width
                case 'lg': return width
                case 'xl': return width
            }
        },
    },
    watch:{}
};
</script>
<style>
#group-tab {
    padding: 0 !important;
}
</style>
