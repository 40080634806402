<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card>
      <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
        <span class="text-h5">{{ GET_ADMIN_DIALOG_TITLE }}</span>
        <v-row class="m-0">
          <v-col cols="pull-right-10 p-2">
            <v-btn text icon small color="gray" class="float-right" @click="closeDialog()">
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-container fluid class="py-5">
          <v-row v-if="GET_ADMIN_TAB_IS_ACTIVE == 'location'">
            <v-col>
              <v-radio-group row mandatory v-model="form.location_type"
                  :disabled="disabled_view">
                  <v-radio label="Head Office" value="headoffice"></v-radio>
                  <v-radio label="Stores" value="stores"></v-radio>
              </v-radio-group> 
            </v-col>
          </v-row>
          <v-row v-if="GET_ADMIN_TAB_IS_ACTIVE == 'location'">
            <v-col>
                <v-autocomplete
                    v-model="form.warehouse_id"
                    :items="GET_ADM_WAREHOUSE_CODE_DROPDOWN"
                    item-text="text"
                    item-value="value"
                    auto-select-first
                    outlined
                    label="Inventory"
                    required
                    dense
                    :disabled="ACTION == 'View'"
                ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row v-if="GET_ADMIN_TAB_IS_ACTIVE == 'location'">
            <v-col>
              <v-autocomplete
                v-model="form.company_id"
                :items="GET_PV_COMPANIES_SELECTION"
                item-text="text"
                item-value="value"
                auto-select-first
                outlined
                label="Company"
                required
                :disabled="ACTION == 'View' || !form.warehouse_id"
                dense
                @change="insertStore()"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row v-if="GET_ADMIN_TAB_IS_ACTIVE == 'location'">
            <v-col>
              <v-autocomplete
                v-model="form.store_branch_id"
                :items="GET_PV_STORE_BRANCHES_SELECTION"
                item-text="text"
                item-value="value"
                auto-select-first
                outlined
                label="Branch"
                required
                :disabled="ACTION == 'View' || !form.warehouse_id"
                dense
                @change="insertCompany(form.store_branch_id)"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row v-if="GET_ADMIN_TAB_IS_ACTIVE !== 'location'">
            <v-col>
              <v-text-field
                v-model="form.code"
                required
                :rules="errors.code"
                label="Code"
                outlined
                dense
               :disabled="disabled_view">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="GET_ADMIN_TAB_IS_ACTIVE !== 'location'">
            <v-col>
              <v-text-field
                v-model="form.prefix_text"
                required
                :rules="errors.prefix_text"
                label="Prefix Text"
                outlined
                dense
               :disabled="disabled_view">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="form.name"
                required
                :rules="errors.name"
                label="Name"
                outlined
                dense
               :disabled="disabled_view">
              </v-text-field>
            </v-col>
            <v-col v-if="GET_ADMIN_TAB_IS_ACTIVE == 'category'">
                <v-radio-group
                    v-model="type_selected"
                    row
                    hide-details
                    :disabled="disabled_view"
                    @change="triggerRadioButton(type_selected)"
                >
                    <v-radio label="Consumable" value="Consumable"></v-radio>
                    <v-radio label="Low Value" value="Low_value_assets"></v-radio>
                </v-radio-group>
                <!-- <v-checkbox
                    class="ma-0 pa-0"
                    v-model="form.consumable"
                    label="Consumable"
                    :disabled="disabled_view"
                    hide-details
                    >
                </v-checkbox> -->
            </v-col>
          </v-row>
          <!-- <v-row v-if="GET_ADMIN_TAB_IS_ACTIVE !== 'location'" class="ma-0 pa-0">
              <v-col>
                  <v-checkbox
                    v-model="form.consumable"
                    label="Consumable"
                  :disabled="disabled_view">
                  </v-checkbox>
              </v-col>
          </v-row> -->
          <!-- <v-row v-if="GET_ADMIN_TAB_IS_ACTIVE == 'location'">
            <v-col>
              <v-text-field
                v-model="form.address"
                required
                :rules="errors.address"
                label="Address"
                outlined
                dense
               :disabled="disabled_view">
              </v-text-field>
            </v-col>
          </v-row> -->
          <!-- Dropdown for Classification only -->
          <!-- <v-row v-if="GET_ADMIN_TAB_IS_ACTIVE=='classification'">
            <v-col>
              <v-autocomplete
                v-model="form.classification_type"
                :items="GET_ADMIN_CATEGORY_SELECTION"
                item-text="text"
                item-value="value"
                required
                :rules="errors.classification_type"
                label="Classification Type"
                outlined
                dense
                :disabled="disabled_view">
              </v-autocomplete>
            </v-col>
          </v-row> -->
          <v-row v-if="USERACCOUNT_LOAD.super_admin == 1 && GET_ADMIN_TAB_IS_ACTIVE == 'status'">
            <v-col>
              <v-autocomplete
                  v-model="form.department_id"
                  outlined
                  :items="GET_LIST_DEPARTMENT"
                  item-text="text"
                  item-value="value"
                  required
                  label="Department"
                  :disabled="disabled_view"
                  :rules="errors.department_id"
                  dense>
                </v-autocomplete>
            </v-col>
          </v-row>
          <v-row v-if="GET_ADMIN_TAB_IS_ACTIVE == 'location'">
            <v-col>
              <v-text-field
                v-model="form.asset_tag_assign"
                required
                :rules="errors.asset_tag_assign"
                label="asset assign as"
                outlined
                dense
               :disabled="disabled_view">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="GET_ADMIN_TAB_IS_ACTIVE=='classification'">
            <v-container fluid class="pa-0 ma-0">
            <v-card class="py-5 my-2" elevation="0" height="400">
                <v-card-subtitle class="p-0">
                    <span class="float-right mx-2">
                        <v-btn fab dark small color="primary"
                            style="height: 20px; width: 20px"
                            @click="addSubClass()"
                            :disabled="disabled_view">
                            <v-icon dark small
                                style="font-size: 12px">mdi-plus</v-icon>
                        </v-btn>
                    </span>
                    <span class="font-weight-bold">Sub Class</span>
                </v-card-subtitle>
                <v-card  class="overflow-auto pa-0" height="400" elevation="0">
                <v-simple-table>
                    <thead class="position-sticky">
                        <tr>
                          <th width="30%">Code</th>
                          <th width="30%">Name</th>
                          <th width="30%" v-if="GET_ADMIN_TAB_IS_ACTIVE == 'location'">Address</th>
                          <th width="30%">COA</th>
                          <th v-if="actions != 'View'">Action</th>
                        </tr>
                    </thead>
                    <tbody >
                        <tr v-for="(data,i) in form.sub_class" :key="i">
                          <td class="pa-2">
                              <v-text-field v-if="actions != 'View' " dense outlined v-model="data.code" :disabled="disabled_view"></v-text-field>
                              <span v-else>{{data.code}}</span>
                          </td>
                          <td class="pa-2">
                              <v-text-field v-if="actions != 'View' " dense outlined v-model="data.name" :disabled="disabled_view"></v-text-field>
                              <span v-else>{{data.name}}</span>
                          </td>
                          <td class="pa-2" v-if="GET_ADMIN_TAB_IS_ACTIVE == 'location'">
                              <v-text-field v-if="actions != 'View' " dense outlined v-model="data.address" :disabled="disabled_view"></v-text-field>
                          </td>
                          <td class="pa-2">
                              <v-autocomplete v-if="actions != 'View' " dense outlined v-model="data.coa_id" :items="GET_CHART_OF_ACCOUNTS_SELECTION" item-text="account_name" item-value="id" :disabled="disabled_view"></v-autocomplete>
                              <span v-else>{{data.coa_id && coaAccountName(data.coa_id) != 0 ? coaAccountName(data.coa_id) : ''}}</span>
                          </td>
                          <td v-if="actions != 'View'">
                            <v-btn text icon color="red" @click="deleteSubClass(i)" :disabled="disabled_view">
                                  <v-icon class="btn-action" small>mdi-delete</v-icon>
                            </v-btn>
                          </td>
                        </tr>
                    </tbody>
                </v-simple-table>
              </v-card>
            </v-card>
        </v-container>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="text-center">
        <v-row class="ma-1">
          <v-col cols="12" v-if="actions != 'View' ">
            <v-btn class="float-center" @click="actions == 'Submit' ? addAdminOtherView() : UpdateAdminOtherView()" :disabled="disabled_view">
              <span>{{ actions }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import Swal from 'sweetalert2'
import { mapGetters } from 'vuex'
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";

export default {
    mixins: [
        SharedFunctionsComponentVue,
    ],
  data() {
    return {
      valid: false,
      form: {
        code: '',
        name: '',
        classification_type: '',
        sub_class:[],
        sub_class_trash:[],
        department_id:'',
        location_type:'headoffice',
        address: '',
        company_id: '',
        store_branch_id: '',
        warehouse_id: '',
        consumable: 0,
        low_value_assets: 0,
        prefix_text: '',
      },
      item: '',
      errors: {
        code: [v => !!v || 'The code is required'],
        name: [v => !!v || 'The name is required'],
        address: [v => !!v || 'The address is required'],
        department_id:[v => !!v || 'The department is required'],
        classification_type:[v => !!v || 'The classification type is required']
      },
      disabled_view:false,
      clonedStores: [],
      type_selected: null,
      
    }
  },
  computed: {
    ...mapGetters([
    'ACTION',
    'DIALOGS',
    'SELECTED_ADMIN_CLASSIFICATION',
    'GET_NEW_ADMIN_OTHER_TABLE',
    'GET_ADMIN_DIALOG_TITLE',
    'GET_ADMIN_TAB_IS_ACTIVE',
    'GET_ADMIN_DISPATCH',
    'USERACCOUNT_LOAD',
    'GET_LIST_DEPARTMENT',
    'GET_ADMIN_CATEGORY_SELECTION',
    'GET_CHART_OF_ACCOUNTS_SELECTION',
    'GET_PV_COMPANIES_SELECTION',
    'GET_PV_STORE_BRANCHES_SELECTION',
    'GET_ADM_WAREHOUSE_CODE_DROPDOWN',
  ]),
    actions() {
      if (this.ACTION == 'Submit') {
        this.disabled_view = false;
        return 'Submit'
      } else if (this.ACTION == 'Update') {
        this.disabled_view = false;
        this.getAllOtherAdmin();
        return 'Update'
      }else if (this.ACTION == 'View') {
        this.disabled_view = true;
        this.getAllOtherAdmin();
        return 'View'
      }
    },
    branchItems(){
        let items = this.clonedStores.filter(e=>e.company_id == this.form.company_id)
        if (!this.form.company_id) return this.clonedStores
        if (!this.form.store_branch_id) this.form.store_branch_id = items[0].value
        return items
    },
  },
  mounted(){
    if(this.GET_ADMIN_TAB_IS_ACTIVE == 'classification')
    {
      if(this.form.sub_class.length == 0){
        this.addSubClass()
       }
    }
    if(this.GET_ADMIN_TAB_IS_ACTIVE == 'location'){
        this.getBranches();
        this.$store.dispatch('getAdminWarehouseCodeDropdown');
    }
  },
  methods: {
    triggerRadioButton(val){
        if(val == 'Consumable'){
          this.form.consumable = 1;
          this.form.low_value_assets = 0;
        } else if (val == 'Low_value_assets') {
          this.form.consumable = 0;
          this.form.low_value_assets = 1;
        } else {
          this.form.type_selected = '';
        }
    },
    addSubClass(){
        this.form.sub_class.push({
          code:'',
          name:''
        })
    },
    deleteSubClass(i){
      this.form.sub_class_trash.push(
          {
              id:this.form.sub_class[i].id,
              name:this.form.sub_class[i].name
          }
          );
          this.form.sub_class.splice(i,1);
    },
    getAllOtherAdmin(){
        this.form.sub_class = this.SELECTED_ADMIN_CLASSIFICATION.sub_class
        this.form.name = this.SELECTED_ADMIN_CLASSIFICATION.name
        this.form.code = this.SELECTED_ADMIN_CLASSIFICATION.code
        this.form.consumable = this.SELECTED_ADMIN_CLASSIFICATION.consumable
        this.form.low_value_assets = this.SELECTED_ADMIN_CLASSIFICATION.low_value_assets
        if(this.form.consumable == 1){
          this.type_selected = 'Consumable';
        } else if (this.form.low_value_assets == 1) {
          this.type_selected = 'Low_value_assets';
        } else {
          this.type_selected = null;
        }
        this.form.address = this.SELECTED_ADMIN_CLASSIFICATION.address
        this.form.warehouse_id = this.SELECTED_ADMIN_CLASSIFICATION.admin_warehouse_id
        this.form.company_id = this.SELECTED_ADMIN_CLASSIFICATION.company_id
        this.form.store_branch_id = this.SELECTED_ADMIN_CLASSIFICATION.store_branch_id
        if (this.SELECTED_ADMIN_CLASSIFICATION.type) {

        }
        this.form.classification_type = this.SELECTED_ADMIN_CLASSIFICATION.type
        this.form.asset_tag_assign = this.SELECTED_ADMIN_CLASSIFICATION.asset_tag_assign
        this.form.location_type = this.SELECTED_ADMIN_CLASSIFICATION.location_type
        this.form.prefix_text = this.SELECTED_ADMIN_CLASSIFICATION.prefix_text
        if(this.USERACCOUNT_LOAD.super_admin == 1 && this.GET_ADMIN_TAB_IS_ACTIVE == 'status')
        {
          // this.form.department_id = this.SELECTED_ADMIN_CLASSIFICATION.id
          this.form.department_id = this.SELECTED_ADMIN_CLASSIFICATION.department_id
        }
        this.item = this.SELECTED_ADMIN_CLASSIFICATION;
    },
    closeDialog() {
      this.$store.commit('DIALOG', false);
      this.$store.commit('ACTION', '')
      this.resetfields();
    },
    addAdminOtherView() {
      this.$refs.form.validate()
      const code_swal_text = this.form.code ? '' : 'Code';
      const name_swal_text = this.form.name ? '' : 'Name';
      const address_swal_text = this.form.address ? '' : 'Address';
      const classification_type_swal_text = this.form.classification_type ? '' : 'Classification Type';
      const department_swal_text = this.form.department_id ? '':'Department';
      const warehouse_swal_text = this.form.warehouse_id ? '':'Inventory';
      const company_swal_text = this.form.company_id ? '':'Company';
      const store_branch_swal_text = this.form.store_branch_id ? '':'Branch';
      const array_text = [code_swal_text, name_swal_text, address_swal_text]
      if(this.USERACCOUNT_LOAD.super_admin == 1 && this.GET_ADMIN_TAB_IS_ACTIVE == 'status'){
        array_text.push(department_swal_text)
      } else if (this.USERACCOUNT_LOAD.super_admin == 1 && this.GET_ADMIN_TAB_IS_ACTIVE == 'classification') {
        array_text.push(classification_type_swal_text)
      } else if (this.GET_ADMIN_TAB_IS_ACTIVE == 'location') {
        array_text.push(warehouse_swal_text, company_swal_text, store_branch_swal_text)
      }
      const filtered = array_text.filter(function (el) {
        return el.replace(',', '');
      })
      if (this.form.code && this.form.name 
        && (this.USERACCOUNT_LOAD.super_admin == 1 && this.GET_ADMIN_TAB_IS_ACTIVE == 'status'?this.form.department_id:!this.form.department_id)
      ) {
          this.$store.dispatch(this.GET_ADMIN_DISPATCH.add, this.form);
      } else if (this.USERACCOUNT_LOAD.super_admin == 1 && this.GET_ADMIN_TAB_IS_ACTIVE == 'classification'?this.form.classification_type:!this.form.classification_type) {
        this.$store.dispatch(this.GET_ADMIN_DISPATCH.add, this.form);
      }
      else {
        Swal.fire({
          text: `Fill Required Fields (${filtered})`,
          icon: 'warning',
          allowOutsideClick: false,
        });
      }
    },
    UpdateAdminOtherView() {
      this.$refs.form.validate();
      const code_swal_text = this.form.code ? '' : 'Code';
      const name_swal_text = this.form.name ? '' : 'Name';
      const address_swal_text = this.form.address ? '' : 'Address';
      const warehouse_swal_text = this.form.warehouse_id ? '':'Inventory';
      const company_swal_text = this.form.company_id ? '':'Company';
      const store_branch_swal_text = this.form.store_branch_id ? '':'Branch';
    //   const desc_swal_text = this.form.description ? '' : 'Description'
        let array_text = [name_swal_text]
        if (this.GET_ADMIN_TAB_IS_ACTIVE == 'location') {
            array_text = array_text.concat(warehouse_swal_text, company_swal_text, store_branch_swal_text)
        }else{
            array_text.push(code_swal_text)
        }
        let filtered = array_text.filter(function (el) {
        return el.replace(',', '');
        })
      if (!this.form.name || (this.GET_ADMIN_TAB_IS_ACTIVE !== 'location' && !this.form.code ) || (this.GET_ADMIN_TAB_IS_ACTIVE == 'location' && (!this.form.warehouse_id || !this.form.company_id || !this.form.store_branch_id))) {
        Swal.fire({
          text: `Fill Required Fields (${filtered})`,
          icon: 'warning',
          allowOutsideClick: false,
        });
      } else {
         this.$store.dispatch(this.GET_ADMIN_DISPATCH.update, {
          id: this.item.id,
          payload: this.form,
        });
      }
    },
    resetfields() {
      this.form = {
        code: '',
        name: '',
        sub_class: [],
        sub_class_trash:[],
        company_id: '',
        store_branch_id: '',
        warehouse_id: '',
        prefix_text: '',
      };
    },
    coaAccountName(id) {
      return id != 0 ? this.GET_CHART_OF_ACCOUNTS_SELECTION.find(e=>e.id == id).account_name : '';
    },
    async getBranches(){
        try{
            this.clonedStores = await this.getStoreBranches()
        } catch (error) {
            console.error(error)
        }
    },
    insertCompany(storeId){
        if (!storeId) return
        let store = this.clonedStores.find(e=>e.value == storeId)
        this.form.company_id = this.GET_PV_COMPANIES_SELECTION.find(e=>e.value == store.company_id).value
        this.$store.dispatch('getAdminLocationSelection', {store_branch_id: storeId})
    },
    insertStore(){
        this.form.store_branch_id = this.branchItems[0].value
    },
  },
  watch: {
    DIALOGS:{
      handler(val){
        if(val){
          if(this.GET_ADMIN_TAB_IS_ACTIVE == 'classification')
          {
            this.addSubClass()
          } 
        }
      }
    },
      GET_NEW_ADMIN_OTHER_TABLE: {
      handler(val) {
        if(val)
        {
          this.$store.commit('DIALOG', false);
        Swal.fire({
          title: 'Success',
          icon: 'success',
          timer: 1500,
          didClose: () => {
            this.$store.commit('NEW_ADMIN_OTHER_TABLE',false);
          }
        });
        this.$store.commit('ACTION', '')
        this.resetfields()
        }
      },
      immediate: true
    },
    'form.company_id': {
        handler(val) {
            if (!val) {
                this.form.store_branch_id = null
                return;
            }

            const storeBranchIds = this.GET_PV_STORE_BRANCHES_SELECTION
                .filter(selection => selection.company_id == val)
                .map(selection => selection.value);

            if (!storeBranchIds.includes(this.form.store_branch_id))
                this.form.store_branch_id = storeBranchIds[0];
        },
    },
    'form.store_branch_id':{
        handler(val){
            if (!val) {
                this.form.company_id = null
                return;
            }

            const storeBranchCompanyId = this.GET_PV_STORE_BRANCHES_SELECTION
                .filter(selection => selection.value == val)
                .shift().company_id;
            this.form.company_id = storeBranchCompanyId;
        },
    },
    'form.warehouse_id':{
        handler(val){
            if(val){
                if(ACTION == 'Submit'){
                    if([2, 12].includes(val)){
                        this.form.company_id = '';
                        this.form.store_branch_id = '';
                        this.$store.dispatch("getPvCompanies", {code: ['ING', 'WAD', 'IPHT', 'IPHT8']}).then((response)=>{
                        });
                    }else{
                        this.form.company_id = '';
                        this.form.store_branch_id = '';
                        this.$store.dispatch("getPvCompanies", {not_code: ['ING', 'WAD', 'IPHT', 'IPHT8']}).then((response)=>{
                        });
                    }
                }
                this.getBranches();
            }
        }
    },
  },
};
</script>
<style scoped>
th {
background: white;
position: sticky;
top: 0; /* Don't forget this, required for the stickiness */
box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}
</style>
