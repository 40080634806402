import ServicePaymentRequest from "@/views/main/modules/Accounting/ServicePaymentRequest/ServicePaymentRequestGroupComponent.vue"
import repository from "@/api/credentials"

const routes = [{
    path: "/accounting/service-payment-request/:id",
    name: "servicepaymentrequest",
    component: ServicePaymentRequest,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
                }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Service Payment Request',
    }
}]

export default routes;
