<template>
    <div class="tabs-body-color">
        <v-container fluid class="pa-0">
            <v-row class="pt-4 pl-2" v-if="USER_ACCESS.some(e=>e.actions_code == 'create')">
                <v-col cols="6" class="d-flex no-block align-items-center">
                    <div class="btn-toolbar pl-0">
                        <v-btn class="ml-1 mb-2" @click="addDialog()">
                            + ADD
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <CreditBillTableComponent v-if="GET_CREDIT_BILL_STATUS.tab_name == isActive_tab"></CreditBillTableComponent>
            </v-row>
        </v-container>
    </div>
</template>
<script>
    import CreditBillTableComponent from './CreditBillTableComponent.vue'
    import { mapGetters } from 'vuex'
    export default {
        components: {
            CreditBillTableComponent,
        },
        props: [
            'isActive_tab',
        ],
        data() {
            return {

            }
        },
        computed: {
            ...mapGetters([
                'USER_ACCESS',
                'GET_CREDIT_BILL_STATUS',
                'DIALOGS',
            ])
        },
        mounted() {
            
        },
        methods: {
            addDialog() {
                this.$store.commit('DIALOG', true);
                this.$store.commit('ACTION', 'Submit');
            }
        },
        watch: {
            
        }
    }
</script>