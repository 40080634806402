<template>
    <v-dialog v-model="baklasDetailsDialog" persistent :max-width="['recovery', 'recovered'].includes(status) ? '60%' : '98%'" scrollable>
        <v-card>
            <v-row class="m-0">
                <v-card-title>
                    <span class="headline">Cannibalize Details</span>
                </v-card-title>
                <v-col cols="pull-right-10 p-2">
                    <v-btn text icon color="gray" class="float-right" @click="closeBaklasDetailsDialog()">
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-divider />

            <div v-if="status == 'new'">
                 <v-layout row class="mx-4">
                    <v-flex lg4>
                        <div>Baklas#: <span>{{ baklasNumber }}</span></div>
                        <div>Item Model: <span>{{ itemModel }}</span></div>
                        <div>Item Name: <span>{{ itemName }}</span></div>
                        <div>Item Category: <span>{{ itemCategory }}</span></div>
                        <div>Serial#: <span>{{ itemSerialNumber }}</span></div>
                        <!-- <div>
                            <v-layout row class="mx-0">
                                <v-flex shrink class="pr-2">
                                    <div class="mt-2">Serial Number: <span style="color:red">*</span></div>
                                </v-flex>
                                <v-flex lg8>
                                    <div>
                                        <v-text-field v-model="serialNumber" outlined dense />
                                    </div>
                                </v-flex>
                            </v-layout>
                        </div> -->
                    </v-flex>
                    <v-flex lg4>
                        <div>Status: <span>{{ status }}</span></div>
                        <div>Created At: <span>{{ baklasCreatedAt }}</span></div>
                        <div>Created By: <span>{{ createdBy }}</span></div>
                    </v-flex>
                    <v-flex lg4>
                        <div>Item Status:
                            <!-- <v-chip :style="styleStatus()" small> -->
                                {{ itemStatus }}
                            <!-- </v-chip> -->
                        </div>
                    </v-flex>
                </v-layout>

                <v-divider />

                <v-card-text>
                    <v-card class="pa-4 overflow-auto" style="height:400px;">
                        <div class="pb-4">
                            <span>
                                Spare Part
                            </span>
                            <v-checkbox v-model="select_available" label="Select Available">

                            </v-checkbox>
                        </div>
                        <div>
                            <v-data-table
                                v-model="dataTableSelected"
                                :headers="newHeaders"
                                :items="sparePartsDef"
                                :items-per-page="sparePartsDef.length"
                                :item-class="itemRowBackground"
                                item-key="id"
                                show-select
                            >
                                <template v-slot:[`item.transfer_qty`]="{ item }">
                                    <input
                                        type="number"
                                        :min="0"
                                        :max="item.original_qty"
                                        v-model="item.transfer_qty"
                                        dense
                                        outlined
                                        :disabled="!item.checked"
                                        style="
                                            text-align:right;
                                            cursor:pointer;
                                            margin:1px 0px 1px !important;
                                            height: 1.5rem !important;
                                            padding:0 5px !important;
                                            border: 1px solid black;
                                            border-radius: 6px;
                                            display: inline-block;
                                            width: 100px;
                                        "
                                        @blur="getDefMaxQty(item)"
                                        @focus="$event.target.select()"
                                    />
                                </template>
                            </v-data-table>
                        </div>
                    </v-card>
                </v-card-text>

                <v-divider />

                <div style="text-align:center" class="pb-4">
                    <v-btn :loading="setDefectiveOnFlight" @click="setDefective()">Set Defective</v-btn>
                </div>
            </div>

            <div v-else>
                <v-layout row class="mx-4">
                    <v-flex lg4>
                        <div>Baklas#: <span>{{ baklasNumber }}</span></div>
                        <div>Item Model: <span>{{ itemModel }}</span></div>
                        <div>Item Name: <span>{{ itemName }}</span></div>
                        <div>Item Category: <span>{{ itemCategory }}</span></div>
                        <div>Serial Number: <span>{{ itemSerialNumber }}</span></div>
                    </v-flex>
                    <v-flex lg4>
                        <div>Status: <span>{{ status }}</span></div>
                        <div>Created At: <span>{{ baklasCreatedAt }}</span></div>
                        <div>Created By: <span>{{ createdBy }}</span></div>
                    </v-flex>
                    <v-flex lg4>
                        <!-- <div>Item Status: <span>{{ itemStatus }}</span></div> -->
                        <div>Item Status:
                            <!-- <v-chip :style="styleStatus()" small> -->
                                {{ itemStatus }}
                            <!-- </v-chip> -->
                        </div>
                        <div v-if="!['consumed','disposed','recovery','recovered'].includes(this.status)">
                            <v-autocomplete
                                dense
                                outlined
                                :items="warehouses"
                                v-model="warehouseId"
                                item-value="id"
                                item-text="name"
                                placeholder="Select Warehouse"
                            />
                        </div>
                    </v-flex>
                </v-layout>

                <v-divider />
                <v-row  class="d-flex justify-end align center mx-4">
                    <v-menu
                        open-delay="2"
                        left
                        offset-y
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="#f69f1a"
                                class="ma-2"
                                v-bind="attrs"
                                v-on="on"
                                small
                            >
                                <v-icon>mdi-printer</v-icon>
                                <span class="ml-2">Print</span>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                v-for="(item, i) in ['All', 'Transferred']"
                                :key="i"
                                dense
                                @click="printPDF(item)"
                            >
                                <v-list-item-title>{{ item }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-row>
                <v-card-text v-if="!['recovery', 'recovered'].includes(status)">
                    <v-card class="pa-4 overflow-auto" style="height:400px;">
                        <div class="pb-4">Spare Parts</div>
                        <v-data-table
                            :headers="headers()"
                            :items="spareParts"
                            :items-per-page="spareParts.length"
                            :item-class="itemRowBackground"
                        >
                            <template v-slot:[`item.transfer_qty`]="{ item }">
                                <v-checkbox
                                    :disabled="checkBalance(item)"
                                    hide-details
                                    dense
                                    v-model="item.checked"
                                    style="margin-top:0 !important; padding-top:0 !important;display:inline-block"
                                    @change="enableTransferQty(item)"
                                />
                                <input
                                    type="number"
                                    :min="0"
                                    :max="item.remaining_quantity"
                                    v-model="item.transfer_qty"
                                    dense
                                    outlined
                                    :disabled="!item.checked"
                                    style="
                                        text-align:right;
                                        cursor:pointer;
                                        margin:1px 0px 1px !important;
                                        height: 1.5rem !important;
                                        padding:0 5px !important;
                                        border: 1px solid black;
                                        border-radius: 6px;
                                        display:inline-block;
                                        width: 100px;
                                    "
                                    @blur="getMaxQty(item)"
                                    @focus="$event.target.select()"
                                />
                            </template>
                        </v-data-table>
                    </v-card>
                </v-card-text>

                <v-divider />

                <div style="text-align:center" class="pb-4"  v-if="status == 'available'">
                    <v-btn :loading="submitOnFlight" color="#f69f1a" @click="showConfirmationDialog()" class="mx-3">Transfer</v-btn>
                    <v-btn :loading="submitOnFlight" color="#f69f1a" @click="dispose()">Dispose</v-btn>
                </div>
                <div style="text-align:center" class="pb-4"  v-if="status == 'recovery'">
                    <!-- <v-btn :loading="submitOnFlight" color="#f69f1a" @click="showConfirmationDialog()" class="mx-3">Recovered</v-btn> -->
                    <v-tooltip bottom color="#f69f1a">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="forRecovered()"
                                fab
                                text
                                small
                                color="#f69f1a"
                                v-bind="attrs"
                                v-on="on"
                                :loading="submitOnFlight"
                            >
                                <v-icon>mdi-cog-outline</v-icon>
                            </v-btn>

                        </template>
                        <span>Recovered</span>
                    </v-tooltip>

                    <!-- <v-btn :loading="submitOnFlight" color="#f69f1a" @click="showConfirmationDialog()" class="mx-3">For Cannibalization</v-btn> -->
                    <v-tooltip bottom color="#f69f1a">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="forCannibalization()"
                                fab
                                text
                                small
                                color="#f69f1a"
                                v-bind="attrs"
                                v-on="on"
                                :loading="submitOnFlight"
                            >
                                <v-icon>mdi-tools</v-icon>
                            </v-btn>

                        </template>
                        <span>For Cannibalization</span>
                    </v-tooltip>

                    <!-- <v-btn :loading="submitOnFlight" color="#f69f1a" @click="dispose()">Dispose</v-btn> -->
                    <v-tooltip bottom color="#f69f1a">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="forDispose()"
                                fab
                                text
                                small
                                color="#f69f1a"
                                v-bind="attrs"
                                v-on="on"
                                :loading="submitOnFlight"
                            >
                                <v-icon>mdi-delete-outline</v-icon>
                            </v-btn>

                        </template>
                        <span>Dispose</span>
                    </v-tooltip>

                </div>
                <!-- For Printing Details -->
                <div style="display:none">
                    <HeaderPdf  :header="forPrintingDataHeader"/>
                    <BodyPdf :tableContents="forPrintingDataBody"/>
                    <FooterPdf  :footers="forPrintingDataFooter"/>
                    <table id="transferred">
                        <thead v-if="!transferredData.length == 0">
                            <tr>
                                <td v-for="(header,index) in headersOfTable" :key="index" >{{ header.toUpperCase() }}</td>
                            </tr>
                        </thead>
                        <tbody v-if="!transferredData.length == 0">
                            <tr v-for="(content,index) in transferredData" :key="index">
                                <td v-for="(header, index) in transferredDataProperties" :key="index">{{ content[header] }}</td>
                            </tr>
                            <tr>
                                <td :colspan="transferredDataProperties.length">Total Qty Transferred: {{ getTotalTransferred(transferredData) }}</td>
                            </tr>
                        </tbody>
                            <tfoot>
                                <tr v-if="transferredData.length == 0">
                                    <td colspan="8">
                                        No Qty Transferred
                                    </td>
                                </tr>
                            </tfoot>
                    </table>
                </div>
            </div>
        </v-card>

        <v-dialog v-model="confirmationDialog" persistent max-width="30%">
            <v-card class="pa-4 overflow-auto" style="height:400px;">
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">Transfer Spare Parts</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeConfirmationDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider class="mt-0" />
                <v-row class="w-100 mx-auto">
                    <v-col cols="12">
                        <v-text-field
                            type="'text'"
                            v-model="additionalRemarks"
                            label="Additional Remarks"
                            outlined
                        />
                    </v-col>
                </v-row>
                <div class="px-4">
                    <v-data-table
                        :headers="confirmationHeaders"
                        :items="selectedSpareParts"
                        :hide-default-footer="true"
                        :items-per-page="selectedSpareParts.length"
                    >

                    </v-data-table>
                </div>

                <v-divider class="mt-0" />

                <div style="text-align:center" class="pb-4">
                    <v-btn :disabled="selectedSpareParts.length == 0" :loading="submitOnFlight" @click="submit()">Submit</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <ConfirmOverrideComponentVue
            :approve_override_dialog="override.override_dialog"
            :departments_allowed="override.override_departments_allowed"
            :heading="'Disposal'"
            @closeConfirmOverride="closeConfirmOverride"
        ></ConfirmOverrideComponentVue>
    </v-dialog>
</template>

<script>
import ConfirmOverrideComponentVue from '@/views/main/Utils/ConfirmOverrideComponent.vue';
import { mapGetters } from 'vuex';
import { timeoutWith } from 'rxjs';
import swal from 'sweetalert2';
import BodyPdf from '@/views/main/modules/Services/BasePrint/BodyPdf.vue';
import HeaderPdf from '@/views/main/modules/Services/BasePrint/HeaderPdf.vue';
import FooterPdf from '@/views/main/modules/Services/BasePrint/FooterPdf.vue';

export default {
    data() {
        return {
            sortBy: 'defective_qty',
            dataTableSelected:[],
            sortDesc: true,
            baklasId: '',
            baklasNumber: '',
            select_available:false,
            itemId: '',
            itemModel: '',
            itemName: '',
            itemCategory: '',
            itemSerialNumber: '',
            itemSpHeaders: [
                { text: 'Defective Qty', value: 'transfered_quantity' },
                { text: 'Actual Qty', value: 'original_quantity' },
                { text: 'Item Model', value: 'model' },
                { text: 'Item Name', value: 'name' },
                { text: 'Part Number', value: 'part_num' },
                { text: 'Transferred', value: 'transferred' },
            ],
            warehouseItemStocksListHeaders: [
                { text: 'Stocks', value: 'stocks' },
                { text: 'Item Model', value: 'item_model' },
                { text: 'Item Name', value: 'item_name' },
                { text: 'Category', value: 'category' },
                { text: 'Warehouse', value: 'warehouse_id' },
            ],
            itemSpLists: [],
            collectedItemSpLists: [],
            baklasCreatedBy: '',
            baklasCreatedAt: '',
            search: '',
            selectedSpareParts: [],
            warehouses: [],
            selectedWarehouse: '',
            transferDialog: false,
            selectedTransferWarehouse: '',
            warehouseItemStocksList: [],
            searchWarehouseItem: '',
            set: false,
            itemStatus: '',
            warehouseName: '',
            warehouseId: 1,
            itemSparePartIds: [],
            newHeaders: [
                { text: 'Model', value: 'model' },
                { text: 'Name', value: 'name' },
                { text: 'Original Qty', value: 'original_qty' },
                { text: 'Set Defective Qty', value: 'transfer_qty' }
            ],
            availableHeaders: [
                { text: 'Model', value: 'model' },
                { text: 'Name', value: 'name' },
                { text: 'Part Num', value: 'part_num' },
                { text: 'Original Qty', value: 'original_quantity' },
                { text: 'Remaining Qty', value: 'remaining_quantity' },
                { text: 'Transferred Qty', value: 'transfered_quantity' },
                { text: 'Transfer Qty', value: 'transfer_qty' },
                { text: 'Defective Qty', value: 'defective_qty' },
                { text: 'Transferred By', value: 'transferred_by' },
                { text: 'Updated At', value: 'updated_at' },
            ],
            consumedHeaders: [
                { text: 'Model', value: 'model' },
                { text: 'Name', value: 'name' },
                { text: 'Part Num', value: 'part_num' },
                { text: 'Original Qty', value: 'original_quantity' },
                { text: 'Remaining Qty', value: 'remaining_quantity' },
                { text: 'Transferred Qty', value: 'transfered_quantity' },
                { text: 'Defective Qty', value: 'defective_qty' },
                { text: 'Transferred By', value: 'transferred_by' },
                { text: 'Updated At', value: 'updated_at' },
            ],
            spareParts: [],
            createdBy: '',
            status: '',
            confirmationDialog: false,
            submitOnFlight: false,
            confirmationHeaders: [
                { text: 'Model', value: 'model' },
                { text: 'Transfer Qty', value: 'transfer_qty' },
            ],
            selectedSpareParts: [],

            override: {
                override_dialog: false,
                errors: [],
                override_departments_allowed: ['SR', 'EX', 'IT'],
                override_authenticated: false,
            },

            serialNumber: '',
            setDefectiveOnFlight: false,
            setDefectiveSpareParts: [],

            baklasItemSpareParts: [],
            sparePartsDef: [],
            forPrintingDataHeader: {
                form_title: 'BAKLAS SPARE PARTS STOCK REQUEST/TRANSFER',
                header_details: [
                    {label: 'Item Name', value:''},
                    {label: 'Item Model', value:''},
                    {label: 'Item Category', value:''},
                    {label: 'Created Date', value:''},
                    {label: 'Baklas#', value:''},
                    {label: 'Serial #', value:''},
                    {label:'Item Status', value:''}
                ],
            },
            forPrintingDataBody: [],
            forPrintingDataFooter: [
                {label: 'Created by', value: ''},
            ],
            transferredData: [],
            transferredDataProperties:[],
            headersOfTable: [],
            warehousesCode: [],
            rfId: '',
            rfNumber: '',
            additionalRemarks:'',
        }
    },
    components: {
        ConfirmOverrideComponentVue,
        HeaderPdf,
        BodyPdf,
        FooterPdf
    },
    props: ['baklasDetails'],
    computed: {
        ...mapGetters([
            'GET_ALL_ITEM_WAREHOUSE_STOCKS',
            'GET_SERVICE_WAREHOUSES',
            'GET_ALL_SPARE_PARTS_OF_ITEM',
            'GET_BAKLAS_ITEM_SPARE_PARTS',
        ]),
        baklasDetailsDialog() {
            return this.$store.state.service.baklasDetailsDialog;
        },
    },

    watch: {
        select_available(val){
            this.newHeaders.pop()
            this.newHeaders.push(val ? {text:'Set Available Qty',value:"transfer_qty"} : {text:'Set Defective Qty',value:"transfer_qty"})
        },
        baklasDetails() {
            this.getWarehouses()
            if(!!this.baklasDetails) {
                const { id, baklas_number, model, name, category, status, item_status, serial_number, created_by, created_at, item_id, warehouse_name, set, warehouse_id , sc_repair_form_id, rf_number }  = this.baklasDetails;

                this.baklasId = id;
                this.baklasNumber = baklas_number;
                this.baklasCreatedAt = this.$dayjs(created_at).format('YYYY-MM-DD, hh:mm:ss a');

                this.itemId = item_id;
                // this.warehouseId = warehouse_id;
                this.itemModel = model;
                this.itemName = name;
                this.itemCategory = category;
                this.itemSerialNumber = serial_number;
                this.status = status;
                this.itemStatus = item_status;
                this.warehouseName = warehouse_name;
                this.createdBy = created_by;
                this.set = !!set;
                this.rfId = sc_repair_form_id;
                this.rfNumber = rf_number;
                // For Printing Header Details
                const headers = [
                    this.itemName,
                    this.itemModel,
                    this.itemCategory,
                    this.baklasCreatedAt,
                    this.baklasNumber,
                    this.itemSerialNumber,
                    this.itemStatus,
                ]
                headers.forEach( (el,index) => {
                    this.forPrintingDataHeader.header_details[index].value =  el;
                });
                this.forPrintingDataFooter[0].value = this.createdBy;
            }
        },
        async baklasId() {
            if(!!this.baklasId){
                if(this.status == 'new') {
                    this.getAllSparePartsOfItem();
                } else {
                    this.getBaklasItemSpareParts();
                }
            }
        },
        selectedWarehouse() {
            if(!!this.selectedWarehouse) {
                this.getWarehouseItemStocks();
                // this.searchWarehouseItem = this.itemModel;
            }
        },
        itemSpLists() {
            if(this.itemSpLists.length > 0) {
                this.itemSparePartIds = _.compact(this.itemSpLists).map(x=>x.item_id);
            }
        },
        dataTableSelected(val){
            if(val.length > 0 ){
                this.sparePartsDef.forEach(x=>{
                    if(!!val.find(e=>e.id == x.id)){
                        x.checked = true
                        x.transfer_qty = x.original_qty
                    }else{
                        x.checked = false
                        x.transfer_qty = 0
                    }
                })
            }else{
                this.sparePartsDef =  this.sparePartsDef.map(e=>{
                    e.checked = false
                    return e
                })
            }
        }
    },
    methods: {
        clearFields() {
            this.itemSpLists = [];
            this.baklasId = '';
            this.baklasNumber = '';
            this.itemId = '';
            this.itemModel = '';
            this.itemName = '';
            this.itemCategory = '';
            this.itemSerialNumber = '';
            this.collectedItemSpLists = [];
            this.baklasCreatedBy = '';
            this.baklasCreatedAt = '';
            this.search = '';
            this.warehouses = [];
            this.selectedWarehouse = "";
            this.warehouseItemStocksList = [];
            this.spareParts = [];
            this.set = false;
            // this.warehouseId = '';
            this.status = '';
            this.serialNumber = '';
        },
        closeBaklasDetailsDialog() {
            this.clearFields();
            this.$emit('closeShowDialog', true);
            this.$store.commit('closeBaklasDetailsDialog');
        },
        getAllSparePartsOfItem() {
            let payload = {
                warehouseId: this.warehouseId,
                itemId: this.itemId
            }
            this.$store.dispatch('getAllSparePartsOfItem',payload).then(response=>{
                let data = response.data;

                let modified = [];

                if(data.length > 0) {
                    data.forEach(x=>{
                        modified.push(
                            Object.assign({
                                transfer_qty: 0,
                                checked: false
                            }, x)
                        )
                    });
                }

                this.sparePartsDef = modified;
            });
        },
        getBaklasItemSpareParts() {
            this.$store.dispatch('getBaklasItemSpareParts',this.baklasId).then(response=>{
                let data = this.GET_BAKLAS_ITEM_SPARE_PARTS;

                let modified = [];

                if(data.length > 0) {
                    data.forEach(x=>{
                        modified.push(
                            Object.assign({
                                transfer_qty: 0,
                                checked: false
                            }, x)
                        )
                    });
                }

                this.spareParts = modified.sort((a, b) => {
                    return b.remaining_quantity - a.remaining_quantity;
                });

                if (this.spareParts.length > 0){
                    this.getContentForPrinting(this.spareParts);
                }
            });
        },
        closeTransferDialog() {
            this.transferDialog = false;
            this.selectedTransferWarehouse = "";
        },
        transferSelectedSp() {
            this.transferDialog = true;
        },
        async getWarehouses() {
            await this.$store.dispatch('serviceGetWarehouses').then(response=>{
                this.warehousesCode = response.data.data
                let data = response.data.data
                this.warehouses = data.filter(x=>{
                    return x.code == 'DM-B' || x.code == 'DM'
                });
            })
        },
        getWarehouseItemStocks() {
            let payload = {
                warehouseId:  this.selectedWarehouse,
                itemSparePartIds: this.itemSparePartIds,
            }
            this.$store.dispatch('getAllItemWarehouseStocks',payload).then(response=>{
                this.warehouseItemStocksList = this.GET_ALL_ITEM_WAREHOUSE_STOCKS;
            })
        },
        getTransferQty(item) {
            if(!!item) {
                return item.transfer_quantity;
            }
        },
        getWarehouseName(item) {
            if(!!item) {
                if(this.warehouses.length > 0) {
                    let warehouse = this.warehouses.find(x=>x.id == item.warehouse_id);

                    return warehouse.name;
                }
            } else {
                return '';
            }
        },
        getMaxRemainingQty(item) {
            if(!!item) {
                return item.original_quantity - item.transfered_quantity;
            }
        },
        getMaxSetRemainingQty(item) {
            if(!!item) {
                return item.original_quantity;
            }
        },
        remainingQtyChecker(item) {
            if(!!item){
                return item.original_quantity == item.transfered_quantity;
            } else {
                return false;
            }
        },
        maxChecker(item) {
            // console.log(item)
        },
        setUsedParts() {
            let spListsWithCount = _.compact(this.itemSpLists).filter(x=>parseInt(x.transfered_quantity) > 0);

             swal.fire({
                title: `Are you sure you want to set Defective Spare Parts?`,
                text: 'Click ok to proceed',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then((result)=>{
                if(result.isConfirmed) {
                    let payload = {
                        baklasId: this.baklasId,
                        spLists: spListsWithCount
                    }
                    this.$store.dispatch('setUsedParts',payload).then(response=>{
                        if(response.data.error) {
                            swal.fire('', response.data.error,'error');
                        } else {
                            this.transferDialog = false;
                            this.selectedSpareParts = [];
                            this.selectedTransferWarehouse = '';
                            this.selectedWarehouse = "";
                            this.$emit('setSuccessfull', true);
                            this.closeBaklasDetailsDialog();
                            swal.fire('', response.data.msg,'success');
                        }
                    });
                }
            });
        },
        showConfirmationDialog() {
            this.selectedSpareParts = this.spareParts.filter(x=>{
                return !!x.checked && x.transfer_qty > 0;
            })

            this.confirmationDialog = true;
        },
        enableTransferQty(item) {
            if(!!item.checked) {
                item.checked = item.checked;
                item.transfer_qty = item.remaining_quantity
            } else {
                item.checked = item.checked;
                item.transfer_qty = 0;
            }
        },
        getMaxQty(item) {
            let selectedItem = this.spareParts.find(x=>x.item_id == item.item_id);
            if(!!selectedItem) {
                if(parseInt(item.transfer_qty) > item.remaining_quantity) {
                    item.transfer_qty = item.remaining_quantity;
                } else if(parseInt(item.transfer_qty) == -0 || parseInt(item.transfer_qty) < 0 || item.transfer_qty=="") {
                    item.transfer_qty = 0;
                }
            }
        },
        enableDefectiveQty(item) {
            if(!!item.checked) {
                item.checked = item.checked;
                item.transfer_qty = item.original_qty
            } else {
                item.checked = item.checked;
                item.transfer_qty = 0;
            }
        },
        getDefMaxQty(item) {
            let selectedItem = this.sparePartsDef.find(x=>x.id == item.id);

            if(!!selectedItem) {
                if(parseInt(item.transfer_qty) > item.original_qty) {
                    item.transfer_qty = item.original_qty;
                } else if(parseInt(item.transfer_qty) == -0 || parseInt(item.transfer_qty) < 0 || item.transfer_qty=="") {
                    item.transfer_qty = 0;
                }
            }
        },
        closeConfirmationDialog() {
            this.selectedSpareParts = [];
            this.confirmationDialog = false;
            this.additionalRemarks = '';
        },
        submit() {

            if(!this.warehouseId){
                swal.fire('Please Select Warehosue','','warning')
                return false
            }

            this.submitOnFlight = true;
            swal.fire({
                title: 'Are you sure you want transfer spare parts to '+this.warehouses.find(e=>e.id == this.warehouseId).code + ' ?',
                text: 'Click ok to proceed',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then((result)=>{
                if(result.isConfirmed) {

                    let payload = {
                        baklasId: this.baklasId,
                        remarks: this.additionalRemarks,
                        spareParts: _.compact(this.selectedSpareParts),
                        warehouse_id:this.warehouseId
                    }
                    this.$store.dispatch('transferBaklasSpareParts',payload).then(response=>{
                        if(response.data.error) {
                            swal.fire('', response.data.error,'error').then(confirm=>{
                                this.submitOnFlight = false;
                            });
                        } else {
                            this.closeConfirmationDialog();
                            this.closeBaklasDetailsDialog();
                            this.$emit('successTransfer', true);
                            swal.fire('', response.data.msg,'success').then(confirm=>{
                                if(confirm) {
                                    this.$emit('successTransfer', false)
                                }
                                this.submitOnFlight = false;
                            });
                        }
                    });
                } else {
                    this.submitOnFlight = false;
                }
            });
        },
        showOverrideTransfer() {
            this.override.override_dialog = true;
            this.confirmationDialog = false;
        },
        closeConfirmOverride(user_id, authenticated = false) {
            if (authenticated) {
                this.$store.dispatch('serviceGet',{
                    url:'baklas/updateStatus/'+this.baklasId+'/disposed',
                }).then(response => {
                    if(response.data == 1){
                        swal.fire('Success!',`- Disposed`,'success')
                    }else{
                        swal.fire('Error!',`- Disposed`,'error')
                    }

                    // this.closeConfirmationDialog();
                    // this.closeBaklasDetailsDialog();
                })
                this.override.override_dialog = false;
                this.confirmationDialog = true;
                // this.submit();
            } else {
                this.override.override_dialog = false;
            }
        },
        setDefective() {
            this.setDefectiveOnFlight = true;

            let selected = _.compact(this.sparePartsDef).filter(x=>x.checked);

            if(selected.length == 0) {
                swal.fire('', 'Please Select and Input Defective Qty', 'warning').then(confirm=>{
                    this.setDefectiveOnFlight = false;
                });
            } else {
                swal.fire({
                    title: 'Are you sure you want to Set Defective?',
                    text: 'Click ok to proceed',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then((result)=>{
                    if(result.isConfirmed) {
                        let payload = {
                            baklasId: this.baklasId,
                            warehouseId: this.warehouseId,
                            itemId: this.itemId,
                            spareParts: _.compact(this.sparePartsDef),
                            select_availabble:this.select_available
                        }

                        this.$store.dispatch('createBaklasItems',payload).then(response=>{
                            if(response.data.error) {
                                swal.fire('', response.data.error,'error').then(confirm=>{
                                    this.setDefectiveOnFlight = false;
                                });
                            } else {
                                this.closeConfirmationDialog();
                                this.closeBaklasDetailsDialog();
                                this.$emit('successTransfer', true);
                                swal.fire('', response.data.msg,'success').then(confirm=>{
                                    if(confirm) {
                                        this.$emit('successTransfer', false)
                                    }
                                    this.setDefectiveOnFlight = false;
                                });
                            }
                        });
                    } else {
                        this.setDefectiveOnFlight = false;
                    }
                });
            }
        },
        checkBalance(item) {
            if((item.transfered_quantity + item.defective_qty) == item.original_quantity) {
                return true;
            }

            return false;
        },
        headers() {
            // if(this.status == 'consumed') {
            if(['consumed','disposed'].includes(this.status)) {
                return this.consumedHeaders;
            }

            return this.availableHeaders;
        },
        itemRowBackground(item){
            if(item.defective_qty > 0){
                return 'yellow';
            }
        },
        dispose(){
            swal.fire({
                title:'Warning!',
                text:'- Are you  sure you want to dispose ?',
                icon: 'question',
                reverseButtons:true,
                showCancelButton:true,
            }).then(actions=>{
                if(actions.isConfirmed){
                    this.override.override_dialog = true
                }
            })
        },
        getContentForPrinting(data) {
            this.forPrintingDataBody = [];
            data.forEach( e => {
                const spSelectedProperties = new Object();
                spSelectedProperties.model = e.model;
                spSelectedProperties.name = e.name;
                spSelectedProperties.part_num = e.part_num;
                spSelectedProperties.original_qty = e.original_quantity;
                spSelectedProperties.remaining_quantity = e.remaining_quantity;
                spSelectedProperties.transferred_qty = e.transfered_quantity;
                spSelectedProperties.transferred_by = e.transferred_by
                spSelectedProperties.updated_at = e.updated_at;
                this.forPrintingDataBody.push(spSelectedProperties);
            })

            this.transferredData = this.forPrintingDataBody.filter( data => data.transferred_qty != 0);
            if (!!this.transferredData) {
                this.getHeadersOfTable();
            }
        },
        printPDF(selected)
        {
            let pdf = new this.$jspdf("p", "mm", "letter"); // paper size in mm. = 215.9 x 279.4
            let pageNumber = pdf.internal.getNumberOfPages();

            let ingcoPhLogo = new Image();
            ingcoPhLogo.src = "/images/ingcoph-logo.png";
            pdf.addImage(ingcoPhLogo, "png", 65, 12.7, 90, 20);

            // For Header
            pdf.autoTable({
                html: '#form-title',
                theme:"plain",
                margin: {top: 0},
                startY: 37.7,
                styles: {
                    fontSize:14,
                    overflow:'hidden',
                },
                columnStyles: {
                    0: {cellWidth: 'auto', halign:'center'},
                },
            })
            pdf.autoTable({
                html: '#header-left',
                theme:"plain",
                margin: {left:12.7},
                startY: 52.7,
                styles: {
                    fontSize:10,
                    overflow:'hidden',
                },
            })

            let headerEndPosition = pdf.lastAutoTable.finalY;

            if ($('#header-thirdPart tr').length != 0)
            {

                pdf.autoTable({
                    html: '#header-thirdPart',
                    theme:"plain",
                    margin: {left: 75},
                    startY: 52.7,
                    styles: {
                        fontSize:10,
                        overflow:'hidden',
                    },
                })
            }

            if ($('#header-right tr').length != 0)
            {
                pdf.autoTable({
                    html: '#header-right',
                    theme:"plain",
                    margin: {left: 137},
                    startY: 52.7,
                    styles: {
                        fontSize:10,
                        overflow:'hidden',
                    },
                })
            }
            // For Body
            pdf.autoTable({
            html: selected == 'All' ? '#body-pdf' : '#transferred',
            theme:"grid",
            styles: {textColor: '#000000', fontSize: 8, lineWidth:0.1, lineColor:"#000000"},
            headStyles:{textType: "bold", fillColor: "#F7FF00", halign: "center"},
            footStyles:{textType: "bold", fontSize: 16, fillColor: "#F73302"},
            margin: {left: 8, right: 8},
            startY: headerEndPosition,
            pageBreak: 'auto',
            showHead: 'firstPage',
            columnStyles: {
                0: { cellWidth: 22, halign:'left'},
                1: { cellWidth: 37, halign:'left'},
                2: { cellWidth: 22, halign:'left'},
                3: { cellWidth: 18, halign:'left'},
                4: { cellWidth: 20, halign:'left'},
                5: { cellWidth: 25, halign:'left'},
                6: { cellWidth: 26, halign:'left'},
                7: { cellWidth: 30, halign:'left'},
            }
            });

            let bodyEndPosition = pdf.lastAutoTable.finalY;

            if (bodyEndPosition > pdf.internal.pageSize.height / 2) {
                bodyEndPosition += 20;
            } else {
                bodyEndPosition += 80;
            }

            // For Footer
            pdf.autoTable({
                html: '#footer-left',
                theme:"plain",
                margin: {right:107},
                startY: bodyEndPosition,
                pageBreak: 'auto',
                showHead: 'firstPage',
                styles: {
                    fontSize:10,
                    overflow:'hidden',
                },
            })

            pdf.setPage(pageNumber)

            pdf.autoTable({
                html: '#footer-right',
                theme:"plain",
                margin: {left: 137},
                startY: bodyEndPosition,
                pageBreak: 'auto',
                showHead: 'firstPage',
                styles: {
                    fontSize:10,
                    overflow:'hidden',
                },
            })

            pdf.autoPrint();
            window.open(pdf.output('bloburl'), '_blank');
        },
        getHeadersOfTable() {
            const headers = this.transferredData[0];
            if (!!headers) {
                this.transferredDataProperties  = Object.keys(headers);
            }
            if (!!this.transferredDataProperties) {
                this.headersOfTable =  this.transferredDataProperties.map(header => header.replaceAll('_', ' '))

            }
        },
        getTotalTransferred(transferredData) {
            if (!!transferredData){
                const transferredQty = transferredData.map(data => data.transferred_qty)
                const total = transferredQty.reduce((total, qty) => total + qty,0);
                return total;
            }
        },
        styleStatus() {
            if (this.itemStatus == "new") {
                return "background-color:blue;border-radius:6px;padding:6px;color:white";
            }

            if (this.itemStatus == "available") {
                return "background-color:success;border-radius:6px;padding:6px;color:white";
            }

            if (this.itemStatus == "disposed") {
                return "background-color:warning;border-radius:6px;padding:6px;color:white";
            }

            if (this.itemStatus == "consumed") {
                return "background-color:danger;border-radius:6px;padding:6px;color:white";
            }

            if (this.itemStatus == "recovery") {
                return "background-color:info;border-radius:6px;padding:6px;color:white";
            }
            return "";
        },

        // forRecovered() {
        //     swal.fire({
        //         title: 'Are you sure you want to confirm?',
        //         text: '- This baklas will be considered as recovered',
        //         icon: 'info',
        //         showConfirmButton:true,
        //         showCancelButton  :  true,
        //         reverseButtons:true,
        //         allowOutsideClick :  false,
        //         confirmButtonColor: '#397373',
        //         cancelButtonColor : 'grey',
        //         confirmButtonText:'Confirm',
        //         showCloseButton:true,
        //     }).then(confirm=>{
        //         if(confirm.isConfirmed){
        //             this.$store.dispatch('servicePost', {
        //                 status: 'recovered',
        //                 baklasId: this.baklasId,
        //                 url: 'baklasRecovered',
        //             }).then((response) => {
        //                 swal.fire('Success!','','success')
        //                 this.$emit('setSuccessfull', true);
        //                 this.closeBaklasDetailsDialog()
        //                 let desti_warehouse = this.warehousesCode.filter(x=>x.code == 'C8')[0];
        //                 let source_warehouse = this.warehousesCode.filter(x=>x.code == 'DM')[0];
        //                 let payload = {
        //                     warehouse_id: desti_warehouse.id,
        //                     initial_serving_warehouse_id: source_warehouse.id,
        //                     remarks: `For Service And Repair, RF#: ${this.rfNumber}, Defective Item (CM) Serial# ${this.itemSerialNumber} `,
        //                     service: 1,
        //                     rfId: this.rfId,
        //                     delivery_module: true,
        //                     baklas: 1,
        //                     allow_butal:1
        //                 }
        //                 this.$store.dispatch('requestCreate',payload).then(response=>{
        //                     swal.fire("", `Stock Request ${response.data.stock_request_form_number},
        //                         Stock Transfer ${response.data.stock_transfer_form_number} Create Successfully!`, "success").then(confirm=>{
        //                         this.closeBaklasDetailsDialog()
        //                     });
        //                 });
        //             }).catch(err=>{
        //                 swal.fire('Error!','','error')
        //                 console.log(err)
        //             })
        //         }
        //     })
        // },

        // forCannibalization() {
        //     swal.fire({
        //         title: 'Are you sure you want to confirm?',
        //         text: '- This baklas will be considered as new',
        //         icon: 'info',
        //         showConfirmButton:true,
        //         showCancelButton  :  true,
        //         reverseButtons:true,
        //         allowOutsideClick :  false,
        //         confirmButtonColor: '#397373',
        //         cancelButtonColor : 'grey',
        //         confirmButtonText:'Confirm',
        //         showCloseButton:true,
        //     }).then(confirm=>{
        //         if(confirm.isConfirmed){
        //             this.$store.dispatch('servicePost', {
        //                 status: 'new',
        //                 baklasId: this.baklasId,
        //                 url: 'baklasCannibalization',
        //             }).then((response) => {
        //                 swal.fire('Success!','','success')
        //                 this.$emit('setSuccessfull', true);
        //                 this.closeBaklasDetailsDialog()
        //             }).catch(err=>{
        //                 swal.fire('Error!','','error')
        //                 console.log(err)
        //             })
        //         }
        //     })
        // },

        // forDispose() {
        //     swal.fire({
        //         title: 'Are you sure you want to confirm?',
        //         text: '- This baklas will be considered as disposed',
        //         icon: 'info',
        //         showConfirmButton:true,
        //         showCancelButton  :  true,
        //         reverseButtons:true,
        //         allowOutsideClick :  false,
        //         confirmButtonColor: '#397373',
        //         cancelButtonColor : 'grey',
        //         confirmButtonText:'Confirm',
        //         showCloseButton:true,
        //     }).then(confirm=>{
        //         if(confirm.isConfirmed){
        //             this.$store.dispatch('servicePost', {
        //                 status: 'disposed',
        //                 baklasId: this.baklasId,
        //                 url: 'baklasDispose',
        //             }).then((response) => {
        //                 swal.fire('Success!','','success')
        //                 this.$emit('setSuccessfull', true);
        //                 this.closeBaklasDetailsDialog()
        //             }).catch(err=>{
        //                 swal.fire('Error!','','error')
        //                 console.log(err)
        //             })
        //         }
        //     })
        // },
    },
}
</script>

<style lang="scss" scoped>
    .unset-overflow {
        overflow: unset;
    }
    .hide-text-field-details {
        .v-text-field__details {
            display: none;
        }
    }
    .orange {
        background-color: red;
    }
</style>
