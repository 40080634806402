<template>
  	<v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
            <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                <span class="headline">Repair Form Quotations</span>
                <v-row class="m-0">
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                        text
                        icon
                        small
                        color="gray"
                        class="float-right"
                        @click="closeDialog()"
                        >
                        <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-container fluid style="background-color: white; overflow-y: scroll">
                <v-card-text>
                    <div class="ma-4">
                        <v-layout row class="px-4">
                            <v-flex lg4>
                                <div>
                                    RD#: <span>{{ rrNumber }}</span>
                                </div>
                                <div>
                                    Date: <span>{{ rrDate }}</span>
                                </div>
                                <div>
                                    Status: <span>{{ rfStatus }}</span>
                                </div>
                                <div>
                                    Purchase Date:
                                    <span>{{ itemPurchaseDate }}</span>
                                </div>
                                <div>
                                    Warranty Type: <v-chip :style="checkWarrantyType()" small>{{ itemWarrantyType }}</v-chip>
                                </div>
                                <!-- <div>
                                    Repair Status: <span><v-chip :color="repairStatusColor()" text-color="white" x-small>{{ itemRepairStatus }}</v-chip></span>
                                </div> -->
                            </v-flex>

                            <v-flex lg4>
                                <div>
                                    Crate Number: <span>{{ crateNumber }}</span>
                                </div>
                                <div>
                                    Location Number: <span>{{ locationNumber }}</span>
                                </div>
                            </v-flex>
                        </v-layout>
                        <v-divider />
                    </div>

                    <div class="px-4 pb-4">
                        <v-layout row class="mx-0 mt-2">
                            <v-flex lg6>
                                <v-tooltip bottom color="#f69f1a">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn @click="showCustomerInfoDialog()"
                                            fab
                                            text
                                            small
                                            color="#f69f1a"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon>mdi-account-question</v-icon>
                                        </v-btn>

                                    </template>
                                    <span>Customer Info</span>
                                </v-tooltip>

                                <v-tooltip bottom color="#f69f1a">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn @click="showItemInfoDialog()"
                                            fab
                                            text
                                            small
                                            color="#f69f1a"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                        <v-icon>mdi-wrench</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Item Info</span>
                                </v-tooltip>

                                <v-tooltip bottom color="#f69f1a">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn @click="showFileUpload()"
                                            fab
                                            text
                                            small
                                            color="#f69f1a"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon>mdi-attachment</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>File Upload</span>
                                </v-tooltip>
                                <v-tooltip bottom color="#f69f1a">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn @click="printTechnicalReport()"
                                            fab
                                            text
                                            small
                                            color="#f69f1a"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon>mdi-printer</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Print Technical Report</span>
                                </v-tooltip>
                            </v-flex>

                            <v-flex lg6>
                                <v-layout row class="mx-0 mt-2">
                                    <v-spacer/>
                                    <v-flex shrink>
                                        <BaseFilesViewerVue :delete="showDelete()" :rfId="rfId" />
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </div>
                </v-card-text>
                <v-card>
                     <v-data-table
                        :items="[DCAitems]"
                        :headers="headers"
                        dense
                    >
                    </v-data-table>
                </v-card>
            </v-container>
            <v-divider/>

            <v-card-actions class="pb-5">
                <v-layout row >
                    <v-flex style="text-align: center;">
                        <v-tooltip bottom color="#f69f1a">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    @click="confirmDCA()"
                                    fab
                                    text
                                    small
                                    color="#f69f1a"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon>mdi-check-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Confirm Customer Pull Out</span>
                        </v-tooltip>
                        <v-tooltip bottom color="#f69f1a" v-if="userAccess.re_assessment">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    @click="showReAssessmentUpdate()"
                                    fab
                                    text
                                    small
                                    color="#f69f1a"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                <v-icon>mdi-text-box-search-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>For Re-assessment</span>
                        </v-tooltip>
                        <v-tooltip bottom color="#f69f1a" v-if="userAccess.confirm_repair">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    @click="confirmRepair()"
                                    fab
                                    text
                                    small
                                    color="#f69f1a"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                <v-icon>mdi-wrench</v-icon>
                                </v-btn>
                            </template>
                            <span>Confirm Repair</span>
                        </v-tooltip>
                    </v-flex>
                </v-layout>
            </v-card-actions>

                    <!-- CUSTOMER INFO DIALOG -->
            <v-dialog v-model="customerInfoDialog" persistent max-width="50%">
                <v-card class="pa-4">
                    <v-row class="m-0">
                        <v-col>
                            <v-card-title>
                                <span class="headline">Customer Information</span>
                            </v-card-title>
                            </v-col>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn
                            text
                            icon
                            color="gray"
                            class="float-right"
                            @click="closeCustomerInfoDialog()"
                            >
                            <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-divider class="mt-0" />

                    <v-layout row class="mx-4">
                        <v-flex lg4>
                            <label style="color: gray">Company Name:</label>
                        </v-flex>
                        <v-flex lg8>
                            {{ companyName }}
                        </v-flex>
                    </v-layout>
                    <v-layout row class="mx-4">
                        <v-flex lg4>
                            <label style="color: gray">Company Number:</label>
                        </v-flex>
                        <v-flex lg8>
                            {{ companyContactNumber }}
                        </v-flex>
                    </v-layout>
                    <v-layout row class="mx-4">
                        <v-flex lg4>
                            <label style="color: gray">Customer Number:</label>
                        </v-flex>
                        <v-flex lg8>
                            {{ contactNumber }}
                        </v-flex>
                    </v-layout>
                    <v-layout row class="mx-4">
                        <v-flex lg4>
                            <label style="color: gray">Customer Name:</label>
                        </v-flex>
                        <v-flex lg8>
                            {{ customerName }}
                        </v-flex>
                    </v-layout>
                    <v-layout row class="mx-4">
                        <v-flex lg4>
                            <label style="color: gray">Address:</label>
                        </v-flex>
                        <v-flex lg8>
                            {{ address }}
                        </v-flex>
                    </v-layout>
                    <v-layout row class="mx-4">
                        <v-flex lg4>
                            <label style="color: gray">Email:</label>
                        </v-flex>
                        <v-flex lg8>
                            {{ email }}
                        </v-flex>
                    </v-layout>
                </v-card>
            </v-dialog>

                    <!-- ITEM INFO DIALOG -->
            <v-dialog v-model="itemInfoDialog" persistent max-width="50%">
                <v-card class="pa-4">
                    <v-row class="m-0">
                        <v-col>
                            <v-card-title>
                                <span class="headline">Item Information</span>
                            </v-card-title>
                        </v-col>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn
                            text
                            icon
                            color="gray"
                            class="float-right"
                            @click="closeItemInfoDialog()"
                            >
                            <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-divider class="mt-0" />

                    <v-layout row class="mx-4">
                        <v-flex lg4>
                            <label style="color: gray">Warranty Type:</label>
                        </v-flex>
                        <v-flex lg8>
                            {{ itemWarrantyType }}
                        </v-flex>
                    </v-layout>

                    <v-layout row class="mx-4">
                        <v-flex lg4>
                            <label style="color: gray">Purchase Date:</label>
                        </v-flex>
                        <v-flex lg8>
                            <span>{{ itemPurchaseDate }}</span>
                        </v-flex>
                    </v-layout>

                    <v-layout row class="mx-4">
                        <v-flex lg4>
                            <label style="color: gray">Item Model:</label>
                        </v-flex>
                        <v-flex lg8>
                            {{ itemModel }}
                        </v-flex>
                    </v-layout>

                    <v-layout row class="mx-4">
                        <v-flex lg4>
                            <label style="color: gray">Item Name:</label>
                        </v-flex>
                        <v-flex lg8>
                            {{ itemProductName }}
                        </v-flex>
                    </v-layout>

                    <v-layout row class="mx-4">
                        <v-flex lg4>
                            <label style="color: gray">Serial No:</label>
                        </v-flex>
                        <v-flex lg8>
                            {{ itemSerial }}
                        </v-flex>
                    </v-layout>

                    <v-layout row class="mx-4">
                        <v-flex lg4>
                            <label style="color: gray">Accessories:</label>
                        </v-flex>
                        <v-flex lg8>
                            {{ itemAccessories }}
                        </v-flex>
                    </v-layout>

                    <v-layout row class="mx-4">
                        <v-flex lg4>
                            <label style="color: gray">Other Accessory:</label>
                        </v-flex>
                        <v-flex lg8>
                            {{ itemOtherAccessory }}
                        </v-flex>
                    </v-layout>

                    <v-layout row class="mx-4">
                        <v-flex lg4>
                            <label style="color: gray">Other Unit Condition:</label>
                        </v-flex>
                        <v-flex lg8>
                            {{ itemOtherUnitCondition }}
                        </v-flex>
                    </v-layout>

                    <v-layout row class="mx-4">
                        <v-flex lg4>
                            <label style="color: gray">Complaint / Symptom:</label>
                        </v-flex>
                        <v-flex lg8>
                            {{ itemDefectDescription }}
                        </v-flex>
                    </v-layout>

                    <v-layout row class="mx-4">
                        <v-flex lg4>
                            <label style="color: gray">Remarks:</label>
                        </v-flex>
                        <v-flex lg8>
                            {{ itemDefectRemarks }}
                        </v-flex>
                    </v-layout>

                    <v-layout row class="mx-4">
                        <v-flex lg4>
                            <label style="color: gray">Assigned Technician:</label>
                        </v-flex>
                        <v-flex lg8>
                            {{ assignedTechnician }}
                        </v-flex>
                    </v-layout>

                    <v-layout row class="mx-4">
                        <v-flex lg4>
                            <label style="color: gray">Symptom Upon Tested:</label>
                        </v-flex>
                        <v-flex lg8>
                            {{ itemSymptomUponTested }}
                        </v-flex>
                    </v-layout>

                    <v-layout row class="mx-4">
                        <v-flex lg4>
                            <label style="color: gray">Change Warranty Reason:</label>
                        </v-flex>
                        <v-flex lg8>
                            {{ rfItemRejectReason }}
                        </v-flex>
                    </v-layout>

                    <v-layout row class="mx-4">
                        <v-flex lg4>
                            <label style="color: gray">Diagnosis Reject Reason:</label>
                        </v-flex>
                        <v-flex lg8>
                            {{ rfItemDiagnosisRejectReason }}
                        </v-flex>
                    </v-layout>

                    <v-layout row class="mx-4">
                        <v-flex lg4>
                            <label style="color: gray">Job Done:</label>
                        </v-flex>
                        <v-flex lg8>
                            {{ itemJobDone }}
                        </v-flex>
                    </v-layout>
                </v-card>
            </v-dialog>

                    <!-- FILE UPLOAD DIALOG -->
            <v-dialog v-model="fileUploadDefective" persistent max-width="20%">
                <v-card class="pa-4">
                    <v-btn
                    text
                    icon
                    color="gray"
                    class="float-right"
                    @click="closeCameraCapture()"
                    >
                    <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                    <div class="py-2">
                        <span>Attach Documents via Camera or Upload</span><span v-if="(this.images == null && this.uploadedFiles == null) && ((this.itemWarrantyType == 'Dealer-Stock' || this.itemWarrantyType == 'In-Warranty') )" style="color:red">  *</span>
                        <v-divider/>
                    </div>

                    <div class="py-2 mt-2">
                        <v-layout row class="mx-0">
                            <v-flex lg2 class="mr-2">
                                <BaseCameraCaptureVue ref="cameraCapture" @capturedImages="getImages" />
                            </v-flex>
                            <v-flex v-if="!!images && images.length > 0" lg3 class="mr-2 mt-1 ">
                                <span>Images Count: {{ images.length }}</span>
                            </v-flex>
                            <v-flex v-if="!!images && images.length > 0">
                                <v-btn @click="clearCameraCapture()">Clear</v-btn>
                            </v-flex>
                        </v-layout>
                    </div>

                    <div class="py-2 mt-3">
                        <v-layout row class="mx-0">
                            <v-flex :class="{'mr-2': true, 'lg3': !!uploadedFiles && uploadedFiles.attachments.length > 1, 'lg6': !!uploadedFiles && uploadedFiles.attachments.length == 1}">
                                <FileUploadComponentVue ref="fileUpload" @uploadedData="uploadedData" @reset="clearFileUpload"/>
                            </v-flex>
                            <v-flex v-if="!!uploadedFiles && uploadedFiles.attachments.length > 0" >
                                <span>Upload Count: {{ uploadedFiles.attachments.length }}</span>
                            </v-flex>
                        </v-layout>
                    </div>
                    <div class="py-2 mt-3">
                        <v-card-actions class="d-flex justify-content-end">
                            <v-btn  @click="submitFileUpload()">Submit</v-btn>
                        </v-card-actions>
                    </div>
                </v-card>
            </v-dialog>

              <!-- UPDATE REPAIR FORM DIALOG -->
            <v-dialog v-model="updateRfDialog" persistent max-width="85%" scrollable>
                <v-card>
                    <v-row class="m-0">
                            <v-col>
                                <v-card-title>
                                    <span class="headline">Update Item</span>
                                </v-card-title>
                            </v-col>

                            <v-col cols="pull-right-10 p-2">
                                <v-btn text icon color="gray" class="float-right" @click="closeUpdateRfDialog()">
                                    <v-icon>mdi-close-circle</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-divider class="mt-0" />
                        <v-card-text>
                            <div class="pa-2">
                                <v-card>
                                    <v-divider v-if="false" class="mt-0" />
                                    <v-layout class="pa-2 mx-2" row>
                                        <v-flex lg1 class="pr-2">
                                            <v-checkbox
                                            label="Backjob"
                                            v-model="isBackjob"
                                            dense
                                            disabled
                                            >
                                            </v-checkbox>

                                        </v-flex>
                                        <v-flex lg1 class="pr-2">
                                            <v-checkbox
                                            label="Online"
                                            v-model="isOnline"
                                            dense
                                            disabled
                                            >
                                            </v-checkbox>
                                        </v-flex>
                                        <v-flex lg1 class="pr-2">
                                            <v-checkbox
                                            label="Defective"
                                            v-model="isDefective"
                                            dense
                                            disabled
                                            >
                                            </v-checkbox>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout class="pa-2 mx-2" row>
                                        <v-flex lg4 class="pr-2">
                                                <v-autocomplete
                                                    v-model="updateRf.item_id"
                                                    :items="GET_ITEMS_EXCLUDE_SP"
                                                    :rules="rules.default"
                                                    item-text="model"
                                                    item-value="id"
                                                    label="Select Model"
                                                    dense
                                                    outlined
                                                    @input="selectedItem()"
                                                >
                                                <template #append >
                                                    <span style="color:red">*</span>
                                                </template>

                                            </v-autocomplete>

                                        </v-flex>

                                        <v-flex lg5 class="pr-2">
                                            <div>
                                                <v-text-field v-model="updateRf.product_name" :rules="rules.default" label="Item Name" readonly dense outlined>
                                                    <template #append >
                                                    <span style="color:red">*</span>
                                                </template>
                                                </v-text-field>
                                            </div>
                                        </v-flex>

                                        <v-flex lg3>
                                            <div>
                                                <v-text-field v-model="updateRf.category" :rules="rules.default" label="Category" readonly dense outlined>
                                                    <template #append >
                                                    <span style="color:red">*</span>
                                                </template>
                                                </v-text-field>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                    <v-divider class="mt-2"/>
                                    <v-layout class="pa-2 mx-2 mb-1" row>
                                        <v-flex lg4 class="pr-2">
                                            <div>
                                                <v-text-field v-model="updateRf.serial" :rules="rules.default" label="Serial No" dense outlined>
                                                    <template #append >
                                                    <span style="color:red">*</span>
                                                </template>
                                            </v-text-field>
                                            </div>
                                        </v-flex>

                                        <v-flex lg3 class="pr-2">
                                            <div>
                                                <v-autocomplete
                                                    v-model="updateRf.warranty_type"
                                                    :items="warrantyTypeItems()"
                                                    :rules="rules.default"
                                                    label="Warranty Type"
                                                    dense
                                                    outlined
                                                >
                                                <template #append >
                                                    <span style="color:red">*</span>
                                                </template>
                                            </v-autocomplete>
                                            </div>
                                        </v-flex>

                                        <v-flex v-if="checkWarrantyTypes" lg2 class="pr-2">
                                            <div>
                                                <v-autocomplete
                                                    v-model="updateRf.warranty"
                                                    :items="['Receipt','Warranty Card', 'Receipt & Warranty Card']"
                                                    :rules="rules.default"
                                                    label="Warranty Document"
                                                    clearable
                                                    dense
                                                    outlined
                                                >
                                                <template #append >
                                                    <span style="color:red">*</span>
                                                </template>
                                            </v-autocomplete>
                                            </div>
                                        </v-flex>

                                        <v-spacer />

                                        <v-flex v-if="checkWarrantyTypes" lg2>
                                            <div>
                                                <v-dialog
                                                    ref="dateDialog"
                                                    v-model="dateModal"
                                                    :return-value.sync="updateRf.purchase_date"
                                                    persistent
                                                    width="290px"

                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="updateRf.purchase_date"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        :rules="rules.default"
                                                        label="Purchase Date"
                                                        readonly
                                                        clearable
                                                        dense
                                                        outlined
                                                    >
                                                    <template #append >
                                                    <span style="color:red">*</span>
                                                </template>
                                                </v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="updateRf.purchase_date"
                                                        scrollable
                                                        :min="minDate()"
                                                        :max="maxDate"
                                                    >
                                                    <v-spacer></v-spacer>
                                                    <v-btn
                                                        text
                                                        color="primary"
                                                        @click="dateModal = false"
                                                    >
                                                        Cancel
                                                    </v-btn>
                                                    <v-btn
                                                        text
                                                        color="primary"
                                                        @click="$refs.dateDialog.save(updateRf.purchase_date)"
                                                    >
                                                        OK
                                                    </v-btn>
                                                    </v-date-picker>
                                                </v-dialog>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout  class="pa-2 mx-2 mb-1">
                                        <v-flex lg4 class="pr-2" v-if="isBackjob == 1">
                                                <v-autocomplete
                                                dense
                                                outlined
                                                label="Backjob"
                                                v-model="backJob"
                                                :items="GET_BACKJOB_COM"
                                                item-value="value"
                                                item-text="text"
                                                >
                                                <template #append >
                                                    <span style="color:red">*</span>
                                                </template>
                                                </v-autocomplete>

                                        </v-flex>
                                        <v-flex lg4 class="pr-2" v-if="isOnline == 1">
                                            <v-text-field
                                            dense
                                            outlined
                                            label="Store"
                                            v-model="onlineStore"
                                        >
                                        <template #append >
                                                <span style="color:red">*</span>
                                            </template>
                                        </v-text-field>

                                        </v-flex>
                                    </v-layout>
                                    <v-divider class="mt-0" />

                                    <div class="mx-4">
                                        <label style="color:gray">Included Items: <span style="color:red">*</span></label>
                                    </div>
                                    <v-layout class="pa-2 mx-2" row>
                                        <v-flex lg3>
                                            <v-checkbox
                                                v-model="includedItems"
                                                label="Packaging"
                                                value="Packaging"
                                            />
                                        </v-flex>
                                        <v-flex lg3>
                                            <v-checkbox
                                                v-model="includedItems"
                                                label="Warranty Docs"
                                                value="Warranty Docs"
                                            />
                                        </v-flex>
                                        <v-flex lg3>
                                            <v-checkbox
                                                v-model="includedItems"
                                                label="Manual"
                                                value="Manual"
                                            />
                                        </v-flex>
                                        <v-flex lg3>
                                            <v-checkbox
                                                v-model="includedItems"
                                                label="Accessories"
                                                value="Accessories"
                                            />
                                        </v-flex>
                                        <v-flex lg12 class="mb-2">
                                            <v-textarea
                                                v-model="updateRf.other_accessory"
                                                label="Others included items:"
                                                outlined
                                                dense
                                            />
                                        </v-flex>
                                    </v-layout>

                                    <v-divider class="mt-0" />

                                    <div class="mx-4">
                                        <label style="color:gray">Physical Condition: <span style="color:red">*</span></label>
                                    </div>
                                    <v-layout class="pa-2 mx-2" row>
                                        <v-flex lg3>
                                            <v-checkbox
                                                v-model="physicalCondition"
                                                label="Dents"
                                                value="Dents"
                                            />
                                        </v-flex>
                                        <v-flex lg>
                                            <v-checkbox
                                                v-model="physicalCondition"
                                                label="Scratches"
                                                value="Scratches"
                                            />
                                        </v-flex>
                                        <v-flex lg3>
                                            <v-checkbox
                                                v-model="physicalCondition"
                                                label="Faded Paint"
                                                value="Faded Paint"
                                            />
                                        </v-flex>
                                        <v-flex lg3>
                                            <v-checkbox
                                                v-model="physicalCondition"
                                                label="Crack/Broken"
                                                value="Crack/Broken"
                                            />
                                        </v-flex>
                                        <v-flex lg12 class="mb-2">
                                            <v-textarea
                                                v-model="updateRf.other_unit_condition"
                                                label="Others physical condition:"
                                                outlined
                                                dense
                                            />
                                        </v-flex>
                                    </v-layout>

                                    <v-divider class="mt-0" />
                                    <v-layout class="pa-2 mx-2" row>
                                        <v-flex lg6 class="pr-2">
                                            <div>
                                                <v-textarea
                                                    v-model="updateRf.defect_description"
                                                    :rules="rules.default"
                                                    label="Complaint / Symptom"
                                                    dense
                                                    outlined
                                                >
                                            <template #append>
                                                <span style="color:red">*</span>
                                            </template>
                                            </v-textarea>
                                            </div>
                                        </v-flex>
                                        <v-flex lg6 class="pr-2">
                                            <div>
                                                <v-textarea
                                                    v-model="updateRf.defect_remarks"
                                                    label="Remarks:"
                                                    outlined
                                                    dense
                                                />
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </v-card>
                            </div>
                        </v-card-text>
                        <v-divider />

                        <div class="pb-4 d-flex justify-center">
                            <!-- <v-btn class="mr-2" @click="updateItem()">Update</v-btn> -->
                            <v-tooltip bottom color="#f69f1a">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        @click="updateItem()"
                                        fab
                                        text
                                        small
                                        class="mr-4"
                                        color="#f69f1a"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon>mdi-plus-box-outline</v-icon>
                                    </v-btn>

                                </template>
                                <span>Update</span>
                            </v-tooltip>
                        </div>
                </v-card>
                </v-dialog>


            <PrintTechnicalReportPdfVue
                :printTechnicalReport="printTechnicalReport"
            >
            </PrintTechnicalReportPdfVue>

        </v-card>
    </v-form>
</template>

<script>
import swal from 'sweetalert2'
import BaseCameraCaptureVue from '@/views/main/modules/Services/Base/BaseCameraCapture.vue';
import FileUploadComponentVue from '@/views/main/Shared/FileUploadComponent.vue';
import BaseFilesViewerVue from "@/views/main/modules/Services/Base/BaseFilesViewer.vue";
import PrintTechnicalReportPdfVue from '@/views/main/modules/Services/PrintTechnicalReportPdf.vue';
import { mapGetters } from 'vuex';

export default {
    props: ['successCreate', 'customerId'],
    data(){
        return {
            valid: false,
            customerInfoDialog: false,
            itemInfoDialog: false,
            fileUploadDefective: false,
            rrNumber: "",
            rrDate: "",
            rfStatus: "",
            itemPurchaseDate: "",
            itemWarrantyType: "",
            itemRepairStatus: "",
            crateNumber: "",
            locationNumber: "",
            companyName: "",
            companyContactNumber: "",
            contactNumber: "",
            customerName: "",
            address: "",
            email: "",
            itemModel: "",
            itemProductName: "",
            itemSerial: "",
            itemAccessories: "",
            itemOtherAccessory: "",
            itemOtherUnitCondition: "",
            itemDefectDescription: "",
            itemDefectRemarks: "",
            assignedTechnician: "",
            itemSymptomUponTested: "",
            rfItemRejectReason: "",
            rfItemDiagnosisRejectReason: "",
            itemJobDone: "",
            images: null,
            uploadedFiles: null,
            rfId: "",
            updateRfDialog: false,
            isOnline: 0,
            isBackjob: 0,
            isDefective: 0,
            dateModal: false,
            backJob: '',
            onlineStore: null,
            includedItems: [],
            physicalCondition: [],
            DCAitems:[],
            updateRf: {
                model: '',
                item_id: '',
                product_name: '',
                category: '',
                serial: '',
                warranty_type: '',
                warranty: '',
                purchase_date: '',
                other_accessory: '',
                other_unit_condition: '',
                defect_description: '',
                defect_remarks: '',
            },
            selectedCustomer: '',
            maxDate: this.$dayjs().format('YYYY-MM-DD'),
            headers:[
                    { text: 'Purchase Date', value: 'purchase_date' },
                    { text: 'Item Model', value: 'model' },
                    { text: 'Item Name', value: 'product_name' },
                    { text: 'Serial#', value: 'serial' },
                    { text: 'Accessories', value: 'accessories' },
                    { text: 'Unit Condition', value: 'unit_condition' },
                    { text: 'Complaint/Symptom', value: 'defect_description' },
                    { text: 'Remarks', value: 'defect_remarks' },
                ],
            userAccess: {
                re_assessment: false,
                confirm_repair: false,
            },
            rules: {
                default: [
                v => !!v || 'This is required',
                ],
            },
        }
    },

    methods: {
        closeDialog(){
            this.clearRfDefective()
            this.$store.commit("DIALOG", false);
            this.$store.commit("DEFECTIVE_DIALOG_VIEW", {});
        },

        clearRfDefective() {
            this.rrNumber = '';
            this.rrDate = '';
            this.rfStatus = '';
            this.itemPurchaseDate = '';
            this.itemWarrantyType = '';
            this.itemRepairStatus = '';
            this.crateNumber = '';
            this.locationNumber = '';
            this.companyName = '';
            this.companyContactNumber = '';
            this.contactNumber = '';
            this.customerName = '';
            this.address = '';
            this.email = '';
            this.itemModel = '';
            this.itemProductName = '';
            this.itemSerial = '';
            this.itemAccessories = '';
            this.itemOtherAccessory = '';
            this.itemOtherUnitCondition = '';
            this.itemDefectDescription = '';
            this.itemDefectRemarks = '';
            this.assignedTechnician = '';
            this.itemSymptomUponTested = '';
            this.rfItemRejectReason = '';
            this.rfItemDiagnosisRejectReason = '';
            this.itemJobDone = '';
            this.rfId = '';
        },

        getRfDefectiveDCA() {
            this.rfId                               = this.GET_DEFECTIVE_DIALOG_VIEW.id
            this.rrNumber                           = this.GET_DEFECTIVE_DIALOG_VIEW.sc_receiving_report.rr_number
            this.rrDate                             = this.$dayjs(this.GET_DEFECTIVE_DIALOG_VIEW.created_at).format("YYYY-MM-DD, hh:mm:ss a")
            this.rfStatus                           = this.GET_DEFECTIVE_DIALOG_VIEW.status
            this.itemPurchaseDate                   = this.GET_DEFECTIVE_DIALOG_VIEW.sc_repair_form_item.purchase_date
            this.itemWarrantyType                   = this.GET_DEFECTIVE_DIALOG_VIEW.sc_repair_form_item.warranty_type
            this.itemRepairStatus                   = this.GET_DEFECTIVE_DIALOG_VIEW.sc_repair_form_item.repair_status
            this.crateNumber                        = this.GET_DEFECTIVE_DIALOG_VIEW.crate_number
            this.locationNumber                     = this.GET_DEFECTIVE_DIALOG_VIEW.location_number
            this.companyName                        = this.GET_DEFECTIVE_DIALOG_VIEW.sc_receiving_report.customer.company_name
            this.companyContactNumber               = this.GET_DEFECTIVE_DIALOG_VIEW.sc_receiving_report.customer.tel_1
            this.contactNumber                      = JSON.parse(this.GET_DEFECTIVE_DIALOG_VIEW.sc_receiving_report.customer.shipping_addresses)[0].contact_number
            this.customerName                       = this.GET_DEFECTIVE_DIALOG_VIEW.sc_receiving_report.customer.customer_name
            this.address                            = this.GET_DEFECTIVE_DIALOG_VIEW.sc_receiving_report.customer.all_shipping_addresses[0].text
            this.email                              = this.GET_DEFECTIVE_DIALOG_VIEW.sc_receiving_report.customer.email
            this.itemModel                          = this.GET_DEFECTIVE_DIALOG_VIEW.sc_repair_form_item.item.model
            this.itemProductName                    = this.GET_DEFECTIVE_DIALOG_VIEW.sc_repair_form_item.product_name
            this.itemSerial                         = this.GET_DEFECTIVE_DIALOG_VIEW.sc_repair_form_item.serial
            this.itemAccessories                    = this.GET_DEFECTIVE_DIALOG_VIEW.sc_repair_form_item.accessories
            this.itemOtherAccessory                 = this.GET_DEFECTIVE_DIALOG_VIEW.sc_repair_form_item.other_accessory
            this.itemOtherUnitCondition             = this.GET_DEFECTIVE_DIALOG_VIEW.sc_repair_form_item.other_unit_condition
            this.itemDefectDescription              = this.GET_DEFECTIVE_DIALOG_VIEW.sc_repair_form_item.defect_description
            this.itemDefectRemarks                  = this.GET_DEFECTIVE_DIALOG_VIEW.sc_repair_form_item.defect_remarks
            this.assignedTechnician                 = this.GET_DEFECTIVE_DIALOG_VIEW.sc_repair_form_item.technician
            this.itemSymptomUponTested              = this.GET_DEFECTIVE_DIALOG_VIEW.sc_repair_form_item.symptom_upon_tested
            this.rfItemRejectReason                 = this.GET_DEFECTIVE_DIALOG_VIEW.sc_repair_form_item.warranty_reject_reason
            this.rfItemDiagnosisRejectReason        = this.GET_DEFECTIVE_DIALOG_VIEW.sc_repair_form_item.diagnosis_reject_reason
            this.itemJobDone                        = this.GET_DEFECTIVE_DIALOG_VIEW.sc_repair_form_item.job_done
            this.DCAitems                           = this.GET_DEFECTIVE_DIALOG_VIEW?.sc_repair_form_item
        },

        showReAssessmentUpdate() {
            this.updateRfDialog = true;
            this.isBackjob                          = this.GET_DEFECTIVE_DIALOG_VIEW.backjob
            this.isDefective                        = this.GET_DEFECTIVE_DIALOG_VIEW.defective
            this.isOnline                           = this.GET_DEFECTIVE_DIALOG_VIEW.sc_repair_form_item.online
            this.updateRf.item_id                   = this.GET_DEFECTIVE_DIALOG_VIEW.sc_repair_form_item.item_id
            this.updateRf.model                     = this.GET_DEFECTIVE_DIALOG_VIEW.sc_repair_form_item.item.model
            this.updateRf.product_name              = this.GET_DEFECTIVE_DIALOG_VIEW.sc_repair_form_item.product_name
            this.updateRf.category                  = this.GET_DEFECTIVE_DIALOG_VIEW.sc_repair_form_item.category
            this.updateRf.serial                    = this.GET_DEFECTIVE_DIALOG_VIEW.sc_repair_form_item.serial
            this.updateRf.warranty_type             = this.GET_DEFECTIVE_DIALOG_VIEW.sc_repair_form_item.warranty_type
            this.updateRf.warranty                  = this.GET_DEFECTIVE_DIALOG_VIEW.sc_repair_form_item.warranty
            this.updateRf.purchase_date             = this.GET_DEFECTIVE_DIALOG_VIEW.sc_repair_form_item.purchase_date
            this.includedItems                      = this.GET_DEFECTIVE_DIALOG_VIEW.sc_repair_form_item.accessories.split(",")
            this.updateRf.other_accessory           = this.GET_DEFECTIVE_DIALOG_VIEW.sc_repair_form_item.other_accessory
            this.physicalCondition                  = this.GET_DEFECTIVE_DIALOG_VIEW.sc_repair_form_item.unit_condition.split(",")
            this.updateRf.other_unit_condition      = this.GET_DEFECTIVE_DIALOG_VIEW.sc_repair_form_item.other_unit_condition
            this.updateRf.defect_description        = this.GET_DEFECTIVE_DIALOG_VIEW.sc_repair_form_item.defect_description
            this.updateRf.defect_remarks            = this.GET_DEFECTIVE_DIALOG_VIEW.sc_repair_form_item.defect_remarks
        },

        checkWarrantyType() {
            if (this.itemWarrantyType == "Dealer-Stock") {
                return "background-color:green;border-radius:6px;padding:6px;color:white";
            }

            if (this.itemWarrantyType == "In-Warranty") {
                return "background-color:violet;border-radius:6px;padding:6px;color:white";
            }

            if (this.itemWarrantyType == "No-Warranty-Type") {
                return "background-color:red;border-radius:6px;padding:6px;color:white";
            }

            if (this.itemWarrantyType == "Out-Warranty") {
                return "background-color:orange;border-radius:6px;padding:6px;color:white";
            }
            return "";
        },

        repairStatusColor() {
            if(this.itemRepairStatus == 'Repaired' || this.itemRepairStatus == 'Credit-Memo' || this.itemRepairStatus == 'Replaced') {
                return 'green';
            } else if(this.itemRepairStatus == 'No-Problem-Found' || this.itemRepairStatus == 'Repair-w/o-Parts' || this.itemRepairStatus == 'Return-w/o-Repair') {
                return 'blue';
            } else {
                return 'red';
            }
        },

        showCustomerInfoDialog() {
            this.customerInfoDialog = true;
        },

        closeCustomerInfoDialog() {
            this.customerInfoDialog = false;
        },

        showItemInfoDialog() {
            this.itemInfoDialog = true;
        },

        closeItemInfoDialog() {
            this.itemInfoDialog = false;
        },

        showFileUpload() {
            this.fileUploadDefective = true;
        },

        getImages(images) {
            this.images = images;
        },

        clearCameraCapture(){
            this.$refs.cameraCapture.reset();
            this.images = null;
        },
        clearFileUpload(confirm) {
            if(confirm) {
                this.uploadedFiles = null;
            }
        },

        closeCameraCapture(){
            this.fileUploadDefective = false;
            this.clearCameraCapture()
            this.clearFileUpload(confirm)
            this.$refs.cameraCapture.reset();
            this.$refs.fileUpload.reset();
        },

        uploadedData(data) {
            if(!data) {
                this.uploadedFiles = null;
            }

            this.uploadedFiles = data;
        },

        showDelete() {
            if(this.rfStatus == 'DTA') {
                return true;
            }
            return false;
        },

        submitFileUpload(filePaths){
            const data = new FormData();

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            if(!!this.uploadedFiles && this.uploadedFiles.attachments.length > 0) {
                let files = this.uploadedFiles.attachments;

                for(let i=0;i < _.compact(files).length;i++) {
                    data.append('files[]', files[i]);
                }
            }

            if(!!this.images && this.images.length > 0) {
                for(let i=0;i < _.compact(this.images).length;i++) {
                    data.append('images[]', _.compact(this.images)[i]);
                }
            }
            data.append('rfId', this.rfId);
            swal.fire({
                    title: 'Are you sure you want to Capture or Upload Image?',
                    icon:'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then(confirm=>{
                    if(confirm.isConfirmed){
                     let payload = {
                        rfId: this.rfId,
                        params: data,
                        config: config,
                    }
                    this.$store.dispatch('uploadWarranty', payload).then(response=>{
                    this.closeCameraCapture();
                    this.$emit('successConfirm', true);
                    swal.fire("Success", '', "success");

                    }).catch(err=>{
                        console.log(err)
                        swal.fire('Failed!','','error')
                        this.loading = false
                         })
                    }
                });
        },

        printTechnicalReport() {
            this.$store.commit('GET_PRINT_PDF_ID', this.rfId)
        },

        confirmDCA(){
            swal.fire({
                title: 'Are you sure you want to confirm pull-out?',
                text: '- This RF# will move to RFD',
                icon:'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(confirm=>{
                if(confirm.isConfirmed){
                    let payload = {
                        rfId: this.rfId,
                    }
                    this.$store.dispatch(this.GET_DEFECTIVE_DISPATCH.update[0], payload).then((response) => {
                        swal.fire('Success!','- RF# ' + response.message,'success')
                        this.closeDialog()
                        this.$store.commit('REFRESH_DEFECTIVE', true)
                    }).catch(err=>{
                        swal.fire('Error!','','error')
                        console.log(err)
                    })
                }
            })
        },

        confirmRepair(){
            swal.fire({
                title: 'Are you sure you want to confirm repair?',
                text: '- This RF# will move to UAS',
                icon:'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(confirm=>{
                if(confirm.isConfirmed){
                    let payload = {
                        rfId: this.rfId,
                    }
                    this.$store.dispatch(this.GET_DEFECTIVE_DISPATCH.update[1], payload).then((response) => {
                        swal.fire('Success!','- RF# ' + response.message,'success')
                        this.closeDialog()
                        this.$store.commit('REFRESH_DEFECTIVE', true)
                    }).catch(err=>{
                        swal.fire('Error!','','error')
                        console.log(err)
                    })
                }
            })
        },
        updateItem() {
            if(!this.requiredFields(this.updateRf, this.includedItems, this.physicalCondition)) {
                swal.fire({
                    title: 'Are you sure you want to update?',
                    icon:'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                }).then(action=>{
                    if(action.isConfirmed){
                        this.$store.dispatch(this.GET_DEFECTIVE_DISPATCH.update[2], {
                            updateRf: this.updateRf,
                            rfId: this.rfId,
                            accessories: this.includedItems.toString(),
                            unit_condition: this.physicalCondition.toString(),
                        }).then((response)=>{
                            swal.fire('Success!','','success')
                            this.getRfDefectiveDCA()
                            this.closeDialog()
                            this.$store.commit('REFRESH_DEFECTIVE', true)
                        }).catch(err=>{
                            swal.fire('Error!','','error')
                            console.log(err)
                        })
                    }
                })
            }
        },


        closeUpdateRfDialog() {
            this.updateRfDialog = false;
        },

        selectedItem() {
            if(!!this.updateRf.item_id) {
                this.updateRf.product_name = this.GET_ITEMS_EXCLUDE_SP.find(x => x.id == this.updateRf.item_id).name;
                this.updateRf.category = this.GET_ITEMS_EXCLUDE_SP.find(x => x.id == this.updateRf.item_id).item_category.name;

            }
        },

        warrantyTypeItems() {
            if(this.customerId == 275) {
                return ['In-Warranty', 'Out-Warranty'];
            } else {
                if(this.itemWarrantyType == 'In-Warranty') {
                    return ['In-Warranty','Out-Warranty'];
                } else {
                    return ['In-Warranty','Out-Warranty', 'Dealer-Stock'];
                }
            }
        },

        minDate() {
            return this.$dayjs().subtract(6, 'month').format('YYYY-MM-DD');
        },

        requiredFields(data, included, physical_condition) {
            let warning = '';
            if (!data.item_id) warning += 'Please select a Model.<br>';
            if (!data.product_name) warning += 'Please select a Item Name.<br>';
            if (!data.category) warning += 'Please select a Category.<br>';
            if (!data.serial) warning += 'Please ensure that the empty field for the serial number is filled in.<br>';
            if (!data.warranty_type) warning += 'Please select a Warranty Type.<br>';
            if (!data.defect_description) warning += 'Please ensure that the empty field for the complaint / symptom is filled in.<br>';

            if(this.checkWarrantyTypes) {
                if (!data.warranty) warning += 'Please select a Warranty Document.<br>';
                if (!data.purchase_date) warning += 'Please select a Purchase Date.<br>';
            }

            if (warning !== '') return swal.fire({
                title: 'Please Fill out Information:',
                html: warning,
                icon: "warning",
            });
        },

    },


    computed: {
        ...mapGetters([
            'GET_DEFECTIVE_DIALOG_VIEW',
            'GET_DEFECTIVE',
            'ACTION',
            'DIALOGS',
            'GET_DEFECTIVE_DISPATCH',
            'GET_REFRESH_DEFECTIVE',
            'GET_ITEMS_EXCLUDE_SP',
            'GET_BACKJOB_COM',
            'USER_ACCESS',
        ]),

        actions(){
             if (this.ACTION == 'View'){
                    this.getRfDefectiveDCA()
                    return 'View'
                } else {
                }
        },

        checkWarrantyTypes() {
            if(this.updateRf.warranty_type == 'In-Warranty') {
                return true;
            }
            return false;
        }

    },

    mounted() {
        this.getRfDefectiveDCA()
        this.$store.dispatch('dropdownGet',{url:'itemsExcludedSp'});
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
    },

    watch: {
        DIALOGS:{
            handler(val){
                if(val){
                    this.actions
                }
            }
        },

        GET_DEFECTIVE_DIALOG_VIEW:{
            handler(val){
            }
        },

        customerId() {
            if(!!this.customerId) {
                this.selectedCustomer = this.customerId;
            } else {
                this.selectedCustomer = '';
            }
        },

        selectedItemModel() {
            if(!!this.updateRf.item_id) {
                let item = this.GET_ITEMS_EXCLUDE_SP.find(item => item.id == this.updateRf.item_id);
                if(!!item) {
                    this.updateRf.product_name = item.name;
                    this.updateRf.category = item.model;
                }
            } else {
                this.updateRf.product_name = "";
            }
        },

        USER_ACCESS:{
            handler(val){
                if(val != "fail"){
                    this.userAccess.re_assessment = false;
                    this.userAccess.confirm_repair = false;
                    val.forEach(e => {
                    if (e.actions_code == 're_assessment') {
                        this.userAccess.re_assessment = true;
                    } else if (e.actions_code == 'confirm_repair') {
                        this.userAccess.confirm_repair = true;
                    }
                    });
                }
            }
        },
    },

    components: {
        BaseCameraCaptureVue,
        FileUploadComponentVue,
        BaseFilesViewerVue,
        PrintTechnicalReportPdfVue,
    },
}
</script>

<style scoped>

</style>