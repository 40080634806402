<template>
    <v-dialog :retain-focus="false" v-model="GET_EDIT_COMPONENT" width="35%" persistent>
        <v-card class="overflow-auto" elevation="0">
            <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                <span class="headline">Edit Logistic Basket</span>
                <v-row class="m-0">
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                        text
                        icon
                        small
                        color="gray"
                        class="float-right"
                        @click="closeDialog()"
                        >
                        <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-card-text>
                <v-row class="m-0" dense no-gutters>
                    <v-col lg="6">
                        <v-autocomplete
                            v-model="logistic.fillables.type"
                            :items="GET_VEHICLE_TYPE_SELECTION"
                            label="Vehicle Type"
                            outlined
                            dense
                            @change="getVehicles()"
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-col lg="6">
                        <v-autocomplete
                            v-model="logistic.fillables.vehicle_id"
                            :items="logistic.vehicles"
                            item-value="id"
                            item-text="name"
                            label="Vehicle"
                            outlined
                            dense
                            :disabled="logistic.fillables.type == null"
                        >
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-row class="m-0" dense no-gutters>
                    <v-col lg="12">
                        <v-datetime-picker
                            v-model="logistic.fillables.delivery_date"
                            date-format="yyyy-MM-dd"
                            time-format="HH:mm a"
                            label="Delivery Date"
                            ref="delivery_date"
                            :text-field-props="{
                                readonly: true,
                                outlined: true,
                                dense: true,
                                hideDetails: true,
                                appendIcon: 'mdi-calendar',
                                persistent: true,
                            }"
                        >
                        </v-datetime-picker>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-row class="d-flex justify-content-between align-center" dense no-gutters>
                <v-col class="pr-3 pb-3">
                    <v-btn class="float-right" @click="update()" small>
                            Update
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
import swal from 'sweetalert2'
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            logistic: {
                fillables: {
                    type: [],
                    vehicle_id: [],
                    delivery_date: null,
                },
                vehicles: [],
            },
            component_dispatches: [
				'getVehicleType',
            ],
        }
    },

    computed: {
        ...mapGetters([
            'GET_EDIT_COMPONENT',
            'GET_LOGISTIC_VIEW',
            'GET_VEHICLE_TYPE_SELECTION',
            'GET_VEHICLES',
        ])
    },

    methods: {
        closeDialog() {
            this.$store.commit('EDIT_COMPONENT', false)
            this.$store.commit('LOGISTIC_VIEW', {})
        },

        getAllDropdowns(){
			this.component_dispatches.forEach(el=>{
				this.$store.dispatch(el)
			})
		},

        getVehicles() {
            this.$store.dispatch("getVehicle", {type: this.logistic.fillables.type}).then(response => {
                this.logistic.vehicles = response.data
            })
        },

        getData() {
            this.logistic.fillables.type                        = this.GET_VEHICLE_TYPE_SELECTION.filter(e=> e.value == this.GET_LOGISTIC_VIEW.type)[0]?.value
            this.logistic.fillables.vehicle_id                  = this.GET_LOGISTIC_VIEW.vehicle_id
            this.logistic.fillables.delivery_date               = this.$dayjs(this.GET_LOGISTIC_VIEW.delivery_date, 'YYYY-MM-DD, hh:mm:ss').toDate();
        },

        clearData() {
            this.logistic.fillables.type = []
            this.logistic.fillables.vehicle_id = []
            this.logistic.fillables.delivery_date = null;
            this.$refs.delivery_date.clearHandler();
        },

        update() {
            if(!this.requiredFields(this.logistic.fillables)) {
                this.logistic.fillables.delivery_date = this.logistic.fillables.delivery_date
                let payload = {
                    data:{
                        id: this.GET_LOGISTIC_VIEW.logistic_basket_id,
                        fillables: this.logistic.fillables,
                        url:'order-delivery-schedule/update-logistic-basket'
                    }
                }
                this.$store.dispatch('postDelivery',payload).then(response => {
                    swal.fire('Update Succcess!', response.data.msg , 'success').then(confirm => {
                        this.closeDialog()
                    })
                })
            }
        },

        requiredFields(data) {
            let warning = '';
            if (!data.vehicle_id) warning += 'Please select a vehicle.<br>';
            if (!data.delivery_date) warning += 'Please select a delivery date.<br>';

            if (warning !== '') return swal.fire({
                title: 'Please Fill out Information:',
                html: warning,
                icon: "warning",
            });
        }
    },

    watch: {
        'logistic.fillables.type': {
            handler(val) {
                if(val == null) {
                    this.logistic.vehicles = []
                }
            }
        },

        GET_EDIT_COMPONENT: {
            handler(val) {
                if(val == true) {
                    this.logistic.fillables.type = null
                    this.getData()
                } else {
                    this.clearData()
                }
            }
        }
    },

    mounted() {
        this.$store.commit('EDIT_COMPONENT', false)
        this.getAllDropdowns()
        this.getVehicles()
        this.getData()
    },

}
</script>

<style scoped>

</style>