<template >
    <v-form>
        <!-- Table -->
      <v-container fluid>
        <div v-for="(chunkItem,i) in this.arraySlice(data,20)">
          <table :id="'item_details_accountability-'+i" class="d-none">
            <thead>
                <tr>
                <th class="border-bottom-none"></th>
                <th colspan="6">Requestor</th>
                <th colspan="3">For Admin Use</th>
              </tr>
              <tr>
                <th>Line No.</th>
                <th>Item Description</th>
                <th>QTY</th>
                <th>UOM</th>
                <th v-for="(vendor,x) in removeDuplicateVendor(chunkItem)" :key="x">{{ vendor.vendor_name_2 && vendor.vendor_name_1?vendor['vendor_name_'+(x+1)]:vendor.vendor_name_1 }} </th>
                <th v-if="removeDuplicateVendor(chunkItem).length == 1">{{ removeDuplicateVendor(chunkItem)[0]['vendor_name_2']?removeDuplicateVendor(chunkItem)[0]['vendor_name_2']:'Vendor 2' }}</th>
                <th>Item Type</th>
                <th>With Stock</th>
                <th>Without Stock</th>
                <th>Remarks / PO number</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row,x) in chunkItem" :key="x" class="tdata">
                <td></td>
                <td>{{ row.item_name }}</td>
                <td>{{ row.quantities }}</td>
                <td>{{ GET_UOM_SELECTION.find(e=>e.value == row.uom_id)?GET_UOM_SELECTION.find(e=>e.value == row.uom_id).text:'' }}</td>
                <td v-for="(vendor,x) in removeDuplicateVendor(chunkItem)" :key="x">
                  {{ vendor['vendor_1'] == row['vendor_1'] && vendor['vendor_2'] == row['vendor_2']  || (row.id == 0 && row['vendor_1'] && row.id == 0 && row['vendor_2'])?row.price:0.00 | currency }}
                </td>
                <td v-if="removeDuplicateVendor(chunkItem).length == 1">{{ row.prf_item_id == removeDuplicateVendor(chunkItem)[0].prf_item_id && removeDuplicateVendor(chunkItem)[0]['vendor_name_2']? removeDuplicateVendor(chunkItem)[0].price : "0.00"}}</td>
                <td>{{ row.id == 0? 'New' : ""}}</td>
                <td class="stockcheckbox">
                  <div class="checkboxes"></div>
                </td>
                <td class="stockcheckbox"></td>
                <td>{{ row.item_remarks }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-container>
    </v-form>

  </template>
  <script charset="utf-8">
  import { mapGetters } from 'vuex';
  import SharedFunctionsComponent from "@/views/main/Shared/SharedFunctionsComponent.vue";
  import GILL from '@/assets/fonts/Gil_normal';
  export default {
    mixins:[SharedFunctionsComponent,GILL],
    data() {
        return {
          counts:0,
            info: {
                logo : "",
                name : "",
                deptLoc : "",
                typeOfAcct : "",
                transferOfAcct : "",
                newAs : "",
                reference_as : "",
                prepared_by : "",
                checked_by : "",
                noted_by : "",
                asNo : "",
                prepared_by_data : "",
                checked_by_data : "",
                noted_by_data : "",
                prepared_by_position : "",
                checked_by_position : "",
                noted_by_position : "",
                signature_name : "",
                acknowledgement : "",
                acknowledgement_text : "",
                sheetVersion : "",
                sheetCopy : "",

            },
            sheet_data: {
                name:"",
                date:null,
                dept:"",
                location:"",
                dept_loc:"",
            },
            copy: {
                sheet_copy_1: "",
                sheet_copy_2: "",
                sheet_copy_3: "",
            },
            total_amount:0,
            headers: ["ITEM DETAILS", "ITEM CODE/s", "PURCHASE / DEPRECIATED PRICE", "REMARKS"],
            data: [],
            chunkItems:[],
        }
    },
    computed:{
        ...mapGetters(['GET_PRINT_PURCHASE_REQUEST','GET_PRINT_PURCHASE_REQUEST_DETAIL',"GET_UOM_SELECTION","GET_ADMIN_CLASSIFICATION_SELECTION"]),
    },
    mounted(){
    },
    methods: {
        async renderPDF(){
            let doc = await new this.$jspdf({
                        orientation: 'p',
                        unit: 'mm',
                        format: 'letter'
                    });

                    doc.deletePage(1)
               this.arraySlice(this.data,20).forEach((e,index)=>{ //From SharedFunction
                this.chunkItems = e;

                    doc.addPage();

                    let request_department_title = 'Requesting Department / Location';
                    let request_department_text = this.GET_PRINT_PURCHASE_REQUEST_DETAIL.department_name
                    let prepare_by_title = 'Prepared by';
                    let approve_by_title = 'Approved by';
                    let for_admin_use_title = 'For Admin Use'
                    let prepared_name = this.GET_PRINT_PURCHASE_REQUEST_DETAIL.full_name;
                    let date_prepared_title = 'Date Prepared';
                    let date_needed_title = 'Date Needed';
                    let date_prepared_text = this.$dayjs().format('MMMM D, YYYY');
                    let date_needed_text = this.$dayjs( this.GET_PRINT_PURCHASE_REQUEST_DETAIL.date_needed).format('MMMM D, YYYY');
                    let justification_remarks_title = 'Justification / Remarks'
                    let justification_remarks_text = this.GET_PRINT_PURCHASE_REQUEST_DETAIL.remarks?this.GET_PRINT_PURCHASE_REQUEST_DETAIL.remarks:' ';
                    let prf_number_title = 'PRF No.'
                    let prf_number_text = this.GET_PRINT_PURCHASE_REQUEST_DETAIL.prf_num;
                    let second_section_title = 'Products/Services';
                    let requestor_title = 'Requestor'
                    let requestor_text = this.GET_PRINT_PURCHASE_REQUEST_DETAIL.full_name ?  this.GET_PRINT_PURCHASE_REQUEST_DETAIL.full_name : '';
                    let department_head_title= 'Department head'
                    let president_title= 'President'
                    let president_text= 'Steven Andrew Yang'
                    let receive_by_date_admin_use= 'Received by /Date:'
                    this.info.logo = 'ingcoph-logo'
                    let left = 10;
                    let top = 10;
                    const imgWidth = 40;
                    const imgHeight = 12;
                    let host = window.location.origin;

                    doc.addImage(`${host}/images/${this.info.logo}.png`, 'PNG', left, top, imgWidth, imgHeight);
                    doc.setFont("Gill Sans", "bold");
                    var text = "PURCHASE REQUISITION FORM";
                    var textWidth = doc.getTextWidth(text);

                    doc.setFont("Gill");
                    doc.setFontSize(14);
                    doc.setFillColor(192,192,192);

                    doc.roundedRect(100, 10, textWidth + 20 + (index > 0?45:0), 14, 3, 3, 'FD');
                    doc.setTextColor(0,0,0);
                    doc.text(text, 117 , 19);

                    //container box
                    doc.rect(10, 27, 200, 240);

                    //first section
                    doc.rect(10, 27, 70, 14);
                    //first box text
                    doc.setTextColor(0,0,0);
                    doc.setFontSize(12);
                    doc.setFont("Gill", "normal");
                     doc.setFont("Gill");
                    doc.text(request_department_text, 40 , 38);
                    doc.setFontSize(8);
                    doc.text(request_department_title, 12 , 31);

                     //second box text
                    doc.text(date_prepared_title, 82 , 31);
                    doc.text(date_needed_title, 152 , 31);
                    doc.setFont("Gill", "normal");
                    doc.setFont("Gill");
                    doc.setFontSize(10);
                    doc.text(date_prepared_text, 99 , 38);

                    doc.setFont("Gill", "normal");
                    doc.setFont("Gill");
                    doc.setFontSize(10);
                    doc.text(date_needed_text, 166 , 38);
                    doc.rect(80, 27, 70, 14);
                    doc.rect(150, 27, 60, 14);

                    //second section
                    doc.setFontSize(11);
                    doc.setFont("Gill", "bold")
                    doc.text(second_section_title,12, 45);
                    doc.rect(10, 41, 200, 7);
                    let rect_ = 12;

            let chunks = [];
            let i = 0;
            let n = this.GET_ADMIN_CLASSIFICATION_SELECTION.length;
            let tmp = 1;
            let tmp_1 = 1;
            let tmp_2 = 1;
            let classification_max = 0;
            while (i < n) {
              classification_max = tmp_2++ * tmp_1++ - 10;
              chunks.push({"y":52 + (i > 1?8* tmp++:1),"x":12,data:this.GET_ADMIN_CLASSIFICATION_SELECTION.slice(i, i += 3)})
            }
                doc.setFont("Gill", "normal");
                chunks.forEach(el => {
                  let a = el.x;
                  let b = 77
                  el.data.forEach((element,i) => {
                  let find =  this.data.find(e=>{
                      return e.category_id == element.id
                    })

                      doc.rect(a+2,  el.y-2, 2, 2, find != undefined && find.category_id == element.id?'F':'S');
                      doc.text(element.text,a+5,el.y + .5);
                      a = a + b
                  });
                });
                $('.tdata').each(function(e){
                  $(this).find('td')[0].innerHTML = e + 1
                 })
                    // // Insert Tables
                    doc.autoTable({
                                html: `#item_details_accountability-${index}`,
                                startY: 77.5 + classification_max,
                                margin: {left: 10, right: 6},
                                theme: 'grid',
                                headStyles: {
                                    halign:'center',
                                    fontSize:8,
                                    fillColor: '#ffffff',
                                    textColor: '#000000',
                                    lineWidth: .1,
                                    lineColor: '#000000',
                                },
                                columnStyles: {
                                  0: {cellWidth: 12, halign:'center',  textColor: '#000000',fontSize:8,},
                                  1: {cellWidth: 50, halign:'left',    textColor: '#000000',fontSize:8,},
                                  2: {cellWidth: 12, halign:'center', textColor: '#000000',fontSize:8,},
                                  3: {cellWidth: 14, halign:'center', textColor: '#000000',fontSize:8,},
                                  4: {cellWidth: 19, halign:'center', textColor: '#000000',fontSize:8,},
                                  5: {cellWidth: 19, halign:'center', textColor: '#000000',fontSize:8,},
                                  6: {cellWidth: 14, halign:'center', textColor: '#000000',fontSize:8,},
                                  7: {cellWidth: 14, halign:'center', textColor: '#000000',fontSize:8,},
                                  8: {cellWidth: 14, halign:'center', textColor: '#000000',fontSize:8,},
                                  9: {cellWidth: 32, halign:'left', textColor: '#000000',fontSize:8,},
                              },
                                bodyStyles: {
                                    lineColor: '#000000',
                                },
                                willDrawCell: function (data) {
                                    var rows = data.table.body;
                                },
                    });


                    let finalY = doc.lastAutoTable.finalY

                    doc.setDrawColor(0, 0, 0);
                    doc.rect(10, 236.5, 140, 15);
                    doc.rect(150, 236.5, 60, 15);
                    doc.setFont("Gill", "bold");
                    doc.setFont("Gill");
                    doc.setFontSize(10);
                    doc.text(justification_remarks_title, 12 , 241);
                    doc.text(justification_remarks_text, 20 , 247);
                    doc.text(prf_number_title, 152 , 241);
                    doc.setFontSize(12);
                    doc.setTextColor(255,0,0);
                    doc.text(prf_number_text, 166 , 247);
                    doc.setFontSize(8);
                    doc.setTextColor(0,0,0);

                    doc.text( prepare_by_title, 12 , 255);
                    doc.text( requestor_title, 25, 265);
                    doc.text( requestor_text, 20, 260);
                    doc.text( department_head_title, 69, 265);
                    doc.text( president_title, 117, 265);
                    doc.text( president_text, 112, 260);
                    doc.text( approve_by_title, 58 , 255);
                    doc.text( approve_by_title, 104 , 255);
                    doc.text( for_admin_use_title, 152 , 255);
                    doc.text( receive_by_date_admin_use, 162, 265);
                    doc.rect(10, 251.5, 46, 15.5);
                    doc.rect(56, 251.5, 46, 15.5);
                    doc.rect(102, 251.5, 48, 15.5);

                  })

                    //---------------------------------------------------------------//
                    doc.autoPrint();
                    // doc.save('Accountability Sheet.pdf');
                    window.open(doc.output('bloburl'), '_blank');
        },
        removeDuplicateVendor(arr){
          const uniquevendor = [];

          const unique = arr.filter(e=>{
            const isDuplicate = uniquevendor.includes(e.vendor_name_1);
            const isDuplicate2 = uniquevendor.includes(e.vendor_name_2);
            if(!isDuplicate && e.vendor_name_1 != ''){
               uniquevendor.push(e.vendor_name_1);
               return true;
             }
             if(!isDuplicate2 && e.vendor_name_2 != ''){
               uniquevendor.push(e.vendor_name_2);
               return true;
             }
             return false;
          });
          return unique;
        }
    },
    watch:{
        GET_PRINT_PURCHASE_REQUEST:{
            handler(val){
               if(val){
                this.total_amount = 0;
                this.data = this.GET_PRINT_PURCHASE_REQUEST_DETAIL.purchase_request_item;
                this.renderPDF()
                this.$store.commit('PRINT_PURCHASE_REQUEST',false);
               }
            },
            deep:true
        }
    }
  }
  </script>
  <style>
    .checkboxes{
      width:10px;
      height:10px;
      border:1px solid black;
    }
  </style>
