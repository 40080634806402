<template>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card v-if="GET_ADMIN_ITEMS_LIST_SECTION">
        <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
          <span class="text-h5">PURCHASE REQUEST</span>
          <v-row class="m-0">
            <v-col cols="pull-right-10 p-2">
              <v-btn
                text
                icon
                small
                color="gray"
                class="float-right"
                @click="closeDialog()"
              >
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text style="height: 600px;">
         <ItemsViewComponent></ItemsViewComponent>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
      <v-card class="overflow-hidden" v-else>
        <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
          <span class="text-h5">{{ this.actions == 'View'?'VIEW PR':(this.actions == 'Submit'?'CREATE PR':'EDIT PR') }}</span>
          <v-row class="m-0">
            <v-col cols="pull-right-10 p-2">
              <v-btn
                text
                icon
                small
                color="gray"
                class="float-right"
                @click="closeDialog()"
              >
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text style="height: 500px; overflow-y: auto;">
          <v-container>
            <v-row>
              <v-col cols="12" lg="4" >
                <v-row class="pa-1"  >
                  <v-col cols="12" >
                    <v-btn v-if="this.actions == 'Update'"  small @click="backtoitems()">ADD NEW ITEM</v-btn>
                    <v-btn text icon small v-if="actions == 'Submit'" color="gray" @click="backtoitems()">
                      <v-icon>mdi-arrow-left-circle</v-icon>
                    </v-btn>
                    </v-col>
                </v-row>
                <v-row class="pa-1">
                  <v-col cols="12" >
                    <v-text-field v-model="form.prf_num" dense small outlined label="PRF Number" readonly></v-text-field>
                  </v-col>
                  </v-row>
                  <v-row class="pa-1">
                    <v-col cols="12">
                      <v-menu v-model="menu"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="form.date_needed"
                                    readonly
                                    append-icon="mdi-calendar-clock"
                                    v-on="on"
                                    label="Date Needed"
                                    outlined
                                    dense
                                    :disabled="disabled_view"
                                ></v-text-field>
                            </template>
                          <v-date-picker v-model="form.date_needed" @input="menu = false">
                          </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-textarea outlined height="200" label="Justifications / Remarks" v-model="form.remarks" :auto-grow="false" no-resize :disabled="disabled_view"></v-textarea>
                    </v-col>
                </v-row>
                <div v-if="GET_PURCHASE_REQUEST_DISPATCH_STATUS.tab_name === 'REJECTED'" class="rejection-info mt-5">
                  <strong>Reason for rejection:</strong>
                  <p>{{ this.GET_EDIT_PURCHASE_REQUEST_TABLE.rejected_remarks }}</p>
                </div>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="12" lg="8" class="pa-0">
                <v-card-text class="pa-0" style="height: 450px;width:100%;overflow-y:scroll">
                  <div class="border-bottom mb-3" v-for="(items,i) in  form.item_list" :key="i">
                    <v-container fluid>
                      <div class="row g-0" >
                          <div class="no-gutters float-left pr-1 pt-2" v-if="items.edited_item">

                            <div :class="'previous-item-container justify-content-between d-flex'+' previous-item-container-'+i">

                              <div :class="'justify-content-between d-block w-100 '+'previous-item-content-'+i">
                                 <div :class="'pb-5 previous-footer text-center previous-footer-'+i">
                                   <span>Previous Item</span>
                                  </div>

                                <div :class="'previous-item-content justify-content-between d-flex w-75'">

                              <img :src="items.previous_other_item_image_path?URL+'/'+items.previous_other_item_image_path:URL+'/'+noImage" :class="'image-fluid fluid text-center previous-image '+'previous-image-'+i" width="100%">

                            <div class="pl-2">
                              <h5 :class="'text-caption font-weight-bold previous-text ' + 'previous-text-'+i"><span class="card-title">Name: </span>{{items.previous_description}} </h5>
                              <p :class="'card-text  text-caption previous-text '+'previous-text-'+i">Price: &#x20b1;{{ items.previous_price | currency }}</p>
                              <p :class="'card-text  text-caption previous-text '+'previous-text-'+i">QTY: {{ items.previous_quantities }}</p>
                            </div>
                                </div>

                              </div>

                              <div class="previous-item-btn float-right cursor-pointer" title="check previous item" @click="viewpreviousitem(i)"><v-icon x-small class="previous-btn-icon">mdi-{{previousitemiconbtn}}-than</v-icon></div>
                            </div>

                          </div>
                          <div :class="'col-md-4 pa-2 '+'current-item-container-image-'+i">
                          <v-card class="secondary" elevation="9">
                            <img v-if="items.other_item_image_path != null && items.image_path == null" :src="items.new_image?items.other_item_image_path:(items.other_item_image_path?URL+'/'+items.other_item_image_path:URL+'/'+noImage)" class="a image-fluid" width="100%">
                            <img v-else-if="items.otherItem" :src="items.image_path?items.image_path:URL+'/'+noImage" class="b image-fluid" width="100%">
                            <img v-else :src="items.image_path?URL+'/'+items.image_path:URL+'/'+noImage" class="c image-fluid" width="100%">

                          </v-card>

                          </div>
                          <div :class="(items.edited_item?'pa-2 col-md-7':'pa-2 col-md-8')+' current-item-container-text-'+i">
                            <div class="card-body">
                                <h5 class="text-caption font-weight-bold"><span class="card-title">Name: </span>{{items.item_name}}
                                  <v-menu offset-y :close-on-content-click="false" v-if="actions == 'Update'">
                                      <template v-slot:activator="{ on, attrs }">
                                        <span class="float-right text-primary font-weight-regular cursor-pointer pl-2" v-bind="attrs"
                                        @click="changeitems(items)"  v-on="on">change item</span>
                                      </template>
                                        <v-card>
                                          <v-card-text>
                                              <v-container fluid>
                                                <v-row>
                                                  <v-col cols="12">
                                                    <span class="text-caption">CHANGE ITEM</span>
                                                    <v-btn class="float-right"  small @click="editCurrentitems(items,i)">EDIT CURRENT ITEM</v-btn>
                                                  </v-col>
                                                </v-row>
                                                  <v-row>
                                                    <v-col cols="12">
                                                      <v-autocomplete
                                                      v-model="vendor"
                                                      :items="GET_ADMIN_SUPPLIER_SELECTION"
                                                      item-text="text"
                                                      item-value="value"
                                                      auto-select-first
                                                      outlined
                                                      label="Vendor"
                                                      required
                                                      small-chips
                                                      deletable-chips
                                                      multiple
                                                      dense
                                                    ></v-autocomplete>
                                                  </v-col>
                                                  </v-row>
                                                  <v-row>
                                                    <v-col>
                                                      <v-autocomplete
                                                        v-model="category_id"
                                                        :items="GET_ADMIN_CLASSIFICATION_SELECTION"
                                                        :search-input.sync="search_classification"
                                                        item-text="text"
                                                        item-value="value"
                                                        auto-select-first
                                                        outlined
                                                        label="Category"
                                                        required
                                                        dense
                                                      ></v-autocomplete>
                                                    </v-col>
                                                  </v-row>
                                                  <v-row>
                                                    <v-col>
                                                      <v-autocomplete
                                                        v-model="sub_category_id"
                                                        :items="subClass"
                                                        item-text="text"
                                                        item-value="value"
                                                        auto-select-first
                                                        outlined
                                                        label="Sub Category"
                                                        required
                                                        dense
                                                      ></v-autocomplete>
                                                    </v-col>
                                                  </v-row>
                                                <v-row>
                                                  <v-col cols="12" sm="6">
                                                  <v-btn small @click="getAllAdminItem()">FILTER</v-btn>
                                                </v-col>
                                                <v-col cols="12" sm="6">
                                                <div class="row col-md-12 align-items-end">
                                                  <v-text-field
                                                      id="searchBar"
                                                      v-model="filter.search"
                                                      label="Search"
                                                      single-line
                                                      hide-details
                                                      @change="searchInput()"
                                                  >
                                                  </v-text-field>
                                                  <v-icon @click="searchInput()">mdi-magnify</v-icon>
                                                </div>
                                                </v-col>
                                                </v-row>
                                                <v-row>
                                                  <v-col>
                                                    <v-card-text style="height:350px; overflow-y:auto;">
                                                    <v-data-table
                                                      :headers="headers"
                                                      :items="change_items"
                                                      :search="search"
                                                      :options.sync="options"
                                                      :server-items-length="totalItems"
                                                      :items-per-page="5"
                                                      :loading="!loaded"
                                                      :footer-props="{
                                                              itemsPerPageOptions: itemsPerPageOptions,
                                                      }"
                                                      loading-text="Loading... Please wait"
                                                      fixed-header
                                                    >
                                              <template v-slot:[`item.category_id`]="{ item }">
                                                  <span>{{GET_ADMIN_CLASSIFICATION_SELECTION.find(e=>e.value == item.category_id).text}}</span>
                                              </template>
                                              <template v-slot:[`item.image_path`]="{ item }">
                                                  <v-img class="img-thumbnail" width="50" height="50" :src="item.image_path == null?url+'/'+'images/items/no-image.png':url+'/'+item.image_path" eager contain></v-img>
                                              </template>
                                                <template v-slot:[`item.action`]="{ item,index }">
                                                  <v-menu offset-y>
                                                        <template v-slot:activator="{ on, attrs }">
                                                          <v-btn x-small color="success" :class="'btn-selected-item-'+index"    v-bind="attrs"
                                                            v-on="on" @click="selectchangeitem(item,index)">
                                                                select
                                                          </v-btn>
                                                        </template>
                                                        <v-list>
                                                          <v-list-item-group
                                                              v-model="selectedItemprice"
                                                              color="primary"
                                                            >
                                                          <v-list-item
                                                            v-for="(item, index) in item_vendor_price_lists"
                                                            :key="index"
                                                          >
                                                            <v-list-item-title>{{ GET_ADMIN_SUPPLIER_SELECTION.find(e=>e.value == item.vendor_id).text }} <span> - </span> &#x20b1; {{ item.price | currency }}</v-list-item-title>
                                                          </v-list-item>
                                                          </v-list-item-group>
                                                        </v-list>
                                                      </v-menu>

                                                </template>
                                                    </v-data-table>
                                                  </v-card-text>
                                                  <v-divider/>
                                                    <v-btn small class="float-right" @click="saveChangeitem(i)">Save Change item</v-btn>
                                                  </v-col>
                                                </v-row>
                                              </v-container>



                                          </v-card-text>
                                          </v-card>
                                        </v-menu>
                                       <v-menu offset-y :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                      <span class="float-right text-primary font-weight-regular cursor-pointer" v-bind="attrs"
                                        @click="viewDetails(i)"  v-on="on">view details</span>
                                    </template>
                                        <v-card>
                                          <v-card-title class="d-block">
                                            <p class="text-caption">Vendor 1: {{ items.vendor_name_1?items.vendor_name_1:(GET_ADMIN_SUPPLIER_SELECTION.find(e=>e.value == items.vendor_1)?GET_ADMIN_SUPPLIER_SELECTION.find(e=>e.value == items.vendor_1).text:'No vendor') }}</p>
                                            <p class="text-caption">Vendor 2: {{ items.vendor_name_2?items.vendor_name_2:(GET_ADMIN_SUPPLIER_SELECTION.find(e=>e.value == items.vendor_2)?GET_ADMIN_SUPPLIER_SELECTION.find(e=>e.value == items.vendor_2).text:'No vendor' )}}</p>

                                          </v-card-title>
                                            <v-card-text style="height:350px; overflow-y:auto;">
                                                <v-simple-table>
                                                  <v-simple-table dark dense>
                                                    <template v-slot:default>
                                                      <thead>
                                                        <tr>
                                                          <th class="text-left">
                                                            Employee Name
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        <tr v-for="(emp_list,i) in items.emp_id"
                                                          :key="i">
                                                          <td>{{ GET_EMPLOYEE_DATA_SELECTION.find(e=>e.value == emp_list)?GET_EMPLOYEE_DATA_SELECTION.find(e=>e.value == emp_list).text:'No Employee' }}</td>
                                                        </tr>
                                                      </tbody>
                                                    </template>
                                                  </v-simple-table>
                                                </v-simple-table>
                                            </v-card-text>
                                        </v-card>
                                      </v-menu>
                                  </h5>
                                <p :class="'card-text  text-caption' + items.otherItem == true || items.price == 0.00?'text-decoration-line-through':''">Price: &#x20b1;{{ items.price | currency }}</p>
                                <p class="card-text  text-caption">QTY: {{ items.quantities }}</p>
                                <p class="card-text  text-caption float-right">Sub Total: &#x20b1;{{ SubTotal(items) | currency}}</p>
                            </div>
                          </div>
                       </div>
                    </v-container>
                  </div>
                </v-card-text>
                <v-container>
                    <span class="text-caption font-weight-bold float-right">Total amount: {{ form.total_amount | currency }}</span>
                </v-container>
              </v-col>
            </v-row>
        </v-container>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions :class="'text-center d-flex border-top'+ actions == 'View' && GET_EDIT_PURCHASE_REQUEST_TABLE.status != 3?'justify-content-between':'justify-content-center'">

          <v-btn v-if="actions == 'View' && checkStatus() && userAccess.reject"
          color="error"
            class="float-center"
            @click="reject()"
          ><span>REJECT</span>
          </v-btn>
          <v-btn v-if="actions == 'View' && (GET_EDIT_PURCHASE_REQUEST_TABLE.status != 6)"
            class="float-center"
            @click="renderPDF()"
          ><span>Print</span>
          </v-btn>

          <v-btn v-if=" (actions == 'Submit' || actions == 'Update') || checkStatus() &&  userAccess.approve"
            class="float-center"
            :loading="loading"
            @click="createOrUpdatePurchaseRequest()"
          >
            <span>{{ actions == 'View'?button_text:actions }}</span>
          </v-btn>
        </v-card-actions>
        <PurchaseRequestComponent></PurchaseRequestComponent>
        <EditCurrentItemsViewComponent></EditCurrentItemsViewComponent>
      </v-card>
    </v-form>
  </template>
      <script>
  import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
  import QrcodeVue from "qrcode.vue";
  import Swal from "sweetalert2";
  import { mapGetters } from "vuex";
  import PurchaseRequestComponent from '@/views/main/layouts/PrintTemplate/PurchaseRequestComponent.vue'
  import ItemsViewComponent from '@/views/main/layouts/dialogs/itemsViewComponent.vue';
  import images from '../../../../../assets/ingcoph_bg/ingcoph_logo_alt.jpg'
  import AddOtherItemViewComponent from '@/views/main/layouts/dialogs/Admin/AddOtherItemViewComponent.vue';
  import EditCurrentItemsViewComponent from '@/views/main/layouts/dialogs/Admin/EditCurrentItemsViewComponent.vue';
  export default {
    mixins: [
        SharedFunctionsComponentVue
    ],
    data() {
      return {
        valid:true,
        dialog:false,
        form: {
          emp_id: "",
          location: "",
          item_list:[],
          item_trash:[],
          previous_asset:[],
          department_id:'',
          prf_id_tag:'',
          asset_tag_scan:'',
          prf_id:'',
          remarks:'',
          date_needed: this.$dayjs().format('YYYY-MM-DD'),
          status:'',
          prf_num:'',
          rejected_remarks:'',
          total_amount:0,
          previous_prf_item:[],
        },
        batch_addition_item:[],
        menu:false,
        employee_selection_data:[{'value':0,'text':'GENERAL ACCOUNTABILITY','department_id':0}],
        search_employee:'',
        search_department:'',
        action:'',
        disabled_view: false,
        errors: {
          emp_id: [(v) => !!v || "Employee Name is required"],
          location: [(v) => !!v || "Location is required"],
        },
        URL:process.env.VUE_APP_API_HOST,
        userAccess:{
          approve:false,
          reject:false,
        },
        loading:false,
        button_text:'',
        noImage:"images/noimage.png",
        expand:false,
        previousitemiconbtn:'greater',
        totalItems: 0,
        options: {},
        items: [],
        itemsPerPageOptions: [5, 10, 15, 50, 100],
        itemsPerPage: 5,
        search: "",
        filter:{
          search:"",
          location:['headoffice','stores']
        },
        headers: [
          { text: 'Image', align: 'left',value:'image_path' },
          { text: "Name", align: "left", value: "item_name" },
          { text: "Category", align: "left", value: "category_id" },
          { text: "Remarks", align: "left", value: "remarks" },
          { text: "Action", align: "center", value: "action",sortable:false},
        ],
        change_items:[],
        url:process.env.VUE_APP_API_HOST,
        loaded:false,
        vendor:[],
        category_id:'',
        sub_category_id:'',
        search_classification:'',
        subClass:[],
        changeitemselected:{},
        item_vendor_price_lists:[],
        selectedItemprice:0,
        previous_item_selected_object:{},
        tab:null,
        current_edit_items:false,
        add_employee_dialog:false,
        emp_id:[],
        department_id:'',
        rejected_remarks: "",
      };
    },
    components: {
      QrcodeVue,
      PurchaseRequestComponent,
      ItemsViewComponent,
      AddOtherItemViewComponent,
      EditCurrentItemsViewComponent
    },
    computed: {
      ...mapGetters([
        "ACTION",
        "DIALOGS",
        "GET_ADMIN_LOCATION",
        "GET_EMPLOYEE_DATA_SELECTION",
        "GET_EDIT_PURCHASE_REQUEST_TABLE",
        "GET_NEW_PURCHASE_REQUEST_TABLE",
        "GET_PURCHASE_REQUEST_DISPATCH",
        "GET_LIST_DEPARTMENT",
        "GET_UOM_SELECTION",
        "GET_PRINT_PURCHASE_REQUEST_DETAIL",
        "GET_ADMIN_CLASSIFICATION_SELECTION",
         "GET_PURCHASE_REQUEST_DISPATCH_STATUS",
         "GET_PURCHASE_REQUEST_NUMBER",
         "GET_ADMIN_PR_CART",
         "GET_ADMIN_PR_SUB_DIALOG",
         "GET_ADMIN_ITEMS_LIST_SECTION",
         "USER_ACCESS",
         "GET_ADMIN_SUPPLIER_SELECTION",
         "GET_ADMIN_CHANGE_PRF_ITEM",
         "GET_ADMIN_EDIT_OTHER_ITEM_DIALOG",
         "GET_ADMIN_ADD_NEW_ITEMS"
      ]),
      actions() {
        if (this.ACTION == "Submit") {
          this.disabled_view = false;
          this.form.prf_num = this.GET_PURCHASE_REQUEST_NUMBER
          this.action = this.GET_PURCHASE_REQUEST_DISPATCH.add
          if(this.form.item_list.length == 0){
           this.addItem();
         }
          return "Submit";
        } else if (this.ACTION == "Update") {
          this.disabled_view = false;
          this.action = this.GET_PURCHASE_REQUEST_DISPATCH.update;
          this.form.status = this.GET_EDIT_PURCHASE_REQUEST_TABLE.status
          if(!this.GET_ADMIN_ADD_NEW_ITEMS){
            this.getItemsrequest()
          }
          return 'Update';
        } else if (this.ACTION == "View") {
          this.action = this.GET_PURCHASE_REQUEST_DISPATCH.update;
          if(this.GET_EDIT_PURCHASE_REQUEST_TABLE.status == 4 ){
            this.form.status = 5
            this.button_text = 'Approve';
          }else if(this.GET_EDIT_PURCHASE_REQUEST_TABLE.status == 1 || this.GET_EDIT_PURCHASE_REQUEST_TABLE.status == 7){
            this.form.status = 2
            this.button_text = 'Approve';
          }else if(this.GET_EDIT_PURCHASE_REQUEST_TABLE.status == 2 || this.GET_EDIT_PURCHASE_REQUEST_TABLE.status == 7){
            this.form.status = 4
            this.button_text = 'Confirm';
          }
          // else if(this.GET_EDIT_PURCHASE_REQUEST_TABLE.status == 3 || this.GET_EDIT_PURCHASE_REQUEST_TABLE.status == 7){
          //   this.form.status = 4
          //   this.button_text = 'Confirm';
          // }
          this.getItemsrequest()
          this.disabled_view = true;
          return "View";
        }
      },
    },
    mounted() {
        this.add_batch_addition_item();
    },
    methods: {
      amount(){
        let sum = 0;
        this.GET_ADMIN_PR_CART.forEach(e=>{
          e.price =isNaN(e.price)?0:e.price;
          sum += (parseInt(e.price) * e.quantities);
        })

        this.form.total_amount = sum;
      },
      closeSubDialog(){
        this.dialog = false;
        this.$store.commit('ADMIN_PR_SUB_DIALOG',false);
      },
      add_batch_addition_item(){
        this.batch_addition_item.push({item:'',category:''});
      },
      async onPaste(e){
        let pastedText = await e.clipboardData.getData('Text');
        pastedText.split('\r\n').forEach(e=>{
         let t=  e.split('\t');
         if(t[0] != ''){

          this.batch_addition_item.push({item:t[0],category:t[1]});
         }

        })
        this.batch_addition_item.shift()
      },
      getItemsrequest(){
        let sum = 0
        this.form.item_list = [];
        this.form.prf_num = this.GET_EDIT_PURCHASE_REQUEST_TABLE.prf_num
        this.form.emp_id = this.GET_EDIT_PURCHASE_REQUEST_TABLE.prepared_by
        this.form.location = this.GET_EDIT_PURCHASE_REQUEST_TABLE.location_id
        this.form.item_list = this.GET_EDIT_PURCHASE_REQUEST_TABLE.purchase_request_item
        this.form.date_needed = this.GET_EDIT_PURCHASE_REQUEST_TABLE.date_needed
        this.form.total_amount = this.GET_EDIT_PURCHASE_REQUEST_TABLE.total_amount
        this.form.prf_id = this.GET_EDIT_PURCHASE_REQUEST_TABLE.id;
        this.form.department_id = this.GET_EDIT_PURCHASE_REQUEST_TABLE.department;
        // this.rejected_remarks = this.GET_EDIT_PURCHASE_REQUEST_TABLE.rejected_remarks;
        this.GET_EDIT_PURCHASE_REQUEST_TABLE.department_name = this.GET_LIST_DEPARTMENT.find(e=>e.value == this.GET_EDIT_PURCHASE_REQUEST_TABLE.department)?this.GET_LIST_DEPARTMENT.find(e=>e.value == this.GET_EDIT_PURCHASE_REQUEST_TABLE.department).text:''
        this.form.remarks = this.GET_EDIT_PURCHASE_REQUEST_TABLE.remarks;
        this.form.item_list.forEach(e=>{
              sum += e.sub_total
            })
          this.form.total_amount = sum
          if(this.form.item_list.length == 0){
           this.addItem();
         }
      },
      addItem(){
          this.form.item_list.push({
            emp_id:[],
            item_id:'',
            item_desc:'',
            item_qty:0,
            item_uom:'',
            item_amount:0,
            total_amount:0,
            item_remarks:'',
            item_classification:'',
          })
      },
      renderPDF(){
        this.$store.commit('PRINT_PURCHASE_REQUEST_DETAIL',{});
        this.$store.commit('PRINT_PURCHASE_REQUEST_DETAIL',this.GET_EDIT_PURCHASE_REQUEST_TABLE);
        this.$store.commit('PRINT_PURCHASE_REQUEST',true);
    },
     async createOrUpdatePurchaseRequest(){
        this.loading = true;
        let date_now = this.$dayjs().format('YYYY-MM-DD');
        const empid_swal_text = this.form.emp_id ? "" : "Employee Name";
        const location_swal_text = this.form.location ? "" : "Location";
        const array_text = [empid_swal_text,location_swal_text];
        const filtered = array_text.filter((el)=> {
            return el;
        });
        let tmp_boolean = false;
        if(!this.GET_EDIT_PURCHASE_REQUEST_TABLE.status && this.GET_EDIT_PURCHASE_REQUEST_TABLE.status != 5 && this.form.date_needed < date_now){
          Swal.fire({
            icon:'warning',
            title:'Date needed is invalid',
          })
          this.loading = false;
        }else{
          if([7,2,3,4].includes(this.GET_EDIT_PURCHASE_REQUEST_TABLE.status) && this.form.status != 6){
            let swal_obj = {
                title:'',
                text:'Are you sure you want to '+(this.actions=='View'?this.button_text:'Update')+'?',
                icon:'question',
                confirmButtonText:this.actions=='View'?this.button_text:'Update',
                cancelButtonText:'Cancel',
                allowOutsideClick:false,
            }
            let swal_warning_obj = {
              text:'Zero value found',
            }
            this.$store.dispatch('SwalAlertQuestion',swal_obj).then(e=>{
              if(e.isConfirmed){

                if(['Approve','Receive','Confirm'].includes(this.button_text)){
                    let find_zero = this.GET_EDIT_PURCHASE_REQUEST_TABLE.purchase_request_item.map(e => {
                    if(e.price == "0.00"){
                      return true
                    }
                    else{
                      return false;
                    }
                  })
                  if(find_zero.includes(true)){
                    this.loading = false;
                    this.$store.dispatch('SwalAlertWarning',swal_warning_obj)

                    return false
                  }
                }
                this.$store.dispatch(this.action,this.form).then((response)=>{
                      this.$store.commit('ADMIN_EDIT_OTHER_ITEMS',{})
                  });
                  this.loading = false;
              }else{
                this.loading = false;
              }
            })
          }else{
            this.$store.dispatch(this.action,this.form).then((response)=>{
                this.$store.commit('ADMIN_EDIT_OTHER_ITEMS',{})
            });
          }
        }

      },
      SwalAlert(){
        return Swal.fire({

          })
      },
      deleteItem(i){
        this.form.item_trash.push(
        {
          item_id:this.form.item_list[i].item_id,
        }
        );
        this.form.item_list.splice(i,1);
      },
      reject() {
        this.dialog = false;
        Swal.fire({
          title: 'Are you sure you want to reject?',
          icon: 'question',
          input: 'textarea',
          inputAttributes: {
            autocapitalize: 'off',
            placeholder: 'Please provide a reason...',
          },
          showCancelButton: true,
          confirmButtonText: 'Reject',
          confirmButtonColor: '#ff5252',
          cancelButtonText: 'Cancel',
          icon: 'question',
          preConfirm: (reason) => {
            if (!reason) {
              Swal.showValidationMessage('Please provide a reason');
            } else {
              this.form.status = 6;
              this.form.rejected_remarks = reason;
            }
          },
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: 'Item Rejected',
              text: `The item was rejected for the following reason: ${result.value}`,
              icon: 'info',
              confirmButtonColor: '#4caf50',
            }).then(() => {
              this.createOrUpdatePurchaseRequest();
            });
          } else {
            this.dialog = true;
          }
        });
      },
      closeDialog() {
          if(this.ACTION == "Update" || this.ACTION == "View"){
            // this.$store.commit("EDIT_ADMIN_ACCOUNTABILITY_DETAILS", {});
            this.resetFields();
          }
          this.$store.commit('ADMIN_CHANGE_PRF_ITEM',{});
          this.loading = false
          this.$store.commit('ADMIN_ITEMS_LIST_SECTION',false);
          this.$store.commit("DIALOG", false);
          this.$store.commit('ADMIN_ADD_NEW_ITEMS',false)
      },
      resetFields(){
        this.form = {
          emp_id: "",
          location: "",
          item_list:[],
          item_trash:[],
          previous_asset:[],
          department_id:'',
          prf_id_tag:'',
          asset_tag_scan:'',
          prf_id:'',
          remarks:'',
          date_needed: this.$dayjs().format('YYYY-MM-DD'),
          status:'',
          prf_num:'',
          rejected_remarks:'',
          total_amount:0,
          previous_prf_item:[],
        }
      },
      SubTotal(items){

        items.sub_total = parseInt(items.price * items.quantities);
        return items.sub_total;
      },
      checkStatus(){
        if(this.GET_EDIT_PURCHASE_REQUEST_TABLE.status == 5 || this.GET_EDIT_PURCHASE_REQUEST_TABLE.status == 6){
          return false;
        }else{
          return true;
        }
      },
      backtoitems(){

        this.$store.commit('ADMIN_ITEMS_LIST_SECTION',true);
        if(this.actions == 'Update'){
          this.$store.commit('ADMIN_ADD_NEW_ITEMS',true);
          this.$store.commit('ADMIN_PR_CART',this.form.item_list)
          this.$store.commit("ACTION", this.actions);
          this.getItemsrequest()
        }

        // this.GET_ADMIN_ITEMS_LIST_SECTION = true
      },
      async viewDetails(item){
        if(this.actions == 'Update' || this.actions == 'View' ){
          this.form.item_list[item].emp_id = []
          this.$store.dispatch('findItemEmployee',this.form.item_list[item].prf_item_id).then((data)=>{
          data.forEach(e=>{
           this.form.item_list[item].emp_id.push(e.emp_id)
          })
        })
        }
      },
      viewpreviousitem(i){

        if($('.previous-item-container-'+i)[0].style.width == '350px'){

          $('.previous-item-container-'+i).animate({width: '20px'});

          $('.previous-text-'+i).hide()
          $('.previous-item-content-'+i).removeClass('overflow-y-auto')
          $('.previous-image-'+i).animate({width: '0px'});
          $('.previous-footer-'+i).hide();

          setTimeout(e=>{
            $('.current-item-container-image-'+i).removeClass('col-md-2')
            $('.current-item-container-text-'+i).removeClass('col-md-3')
            $('.current-item-container-image-'+i).addClass('col-md-4')
            $('.current-item-container-text-'+i).addClass('col-md-7')
          },310)

            this.previousitemiconbtn = 'greater'

        }else{
          this.previousitemiconbtn = 'less'
          $('.previous-image-'+i).show()
          $('.previous-text-'+i).show()
          $('.previous-item-content-'+i).addClass('overflow-y-auto')
          $('.previous-image-'+i).animate({width: '40%'});
          $('.previous-item-container-'+i).animate({width: '350px'});
          $('.previous-footer-'+i).show();
          $('.current-item-container-image-'+i).removeClass('col-md-4')
            $('.current-item-container-text-'+i).removeClass('col-md-7')
            $('.current-item-container-image-'+i).addClass('col-md-2')
            $('.current-item-container-text-'+i).addClass('col-md-3')
        }
      },
      changeitems(items){
        this.change_items = []

        items.previous_item_id = items.id
        items.previous_item_id_id = items.item_id
        items.previous_prf_item_id = items.prf_item_id
        items.previous_description = items.item_name
        items.previous_price = items.price
        items.previous_quantities = items.quantities
        items.previous_other_item_image_path = items.other_item_image_path?items.other_item_image_path:items.image_path
        items.previous_uom_id = items.uom_id
        items.previous_sub_total = items.sub_total
        items.previous_category_id = items.category_id
        items.previous_vendor_1 = items.vendor_1
        items.previous_vendor_2 = items.vendor_2
        items.previous_vendor_name_1 = items.vendor_1
        items.previous_vendor_name_2 = items.vendor_2


        this.$store.commit('ADMIN_CHANGE_PRF_ITEM',items);
        this.$store.dispatch("getAdminSupplierSelection");
        this.$store.dispatch("getAdminClassificationSelection");
        this.$store.commit("ACTION", "Update");
        this.getAllAdminItem()
      },

      editCurrentitems(items,i){
        let obj = {
            index:i,
            items:items
        }
        this.viewDetails(i)
        this.$store.commit('ADMIN_EDIT_OTHER_CURRENT_ITEMS',obj)
        this.$store.commit('ADMIN_EDIT_OTHER_ITEM_DIALOG',true);
      },
      searchInput(){
          this.getAllAdminItem({ query: this.search });
      },
      getAllAdminItem(filter = null) {
        this.items = [];
        this.item_vendor_price_lists = []
        this.loaded = false;
        let payload = {
          page: this.options.page,
          vendor:this.vendor,
          category_id:this.category_id,
          sub_category_id:this.sub_category_id,
          itemsPerPage: this.options.itemsPerPage,
          search: this.filter.search,
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          location:this.filter.location,
          active:1,
          filter: filter,
        };

        this.$store.dispatch('getAllAdminItem', payload).then((response) => {
          this.change_items = response.data.data.data;
          this.loaded = true;
          this.totalItems = response.data.data.total;
        }).catch((e)=>{
          this.loaded = true;
        });
      },
      selectchangeitem(item,i){

        let find_item_if_exist = this.form.item_list.find(e=>e.id == item.id)

        if(find_item_if_exist){
          Swal.fire({
              icon:'warning',
              text:"This item is already on your list!"
          })
          return false;
        }


        $('.btn-selected-item-'+i).parent().parent().parent().find('tr').find('.v-btn__content').html("Select")
        $('.btn-selected-item-'+i).parent().parent().parent().find('tr').removeClass('bg-warning')
        $('.btn-selected-item-'+i).parent().parent().addClass('bg-warning')
        this.item_vendor_price_lists = item.item_vendor_price_lists
        this.changeitemselected = item

        $('.btn-selected-item-'+i).find('.v-btn__content').html("Selected")
      },
      saveChangeitem(i){
        Swal.fire({
          icon:'question',
          title:'Are you sure you want to change this item?',
          showCancelButton:true,
          footer:'<i><span class="text-danger text-caption">Note: Item will effect after the PR update</span></i>'
        }).then(e=>{
          if(e.isConfirmed){

            console.log(this.form.item_list[i]);
            console.log(this.changeitemselected);

            if (this.form.item_list[i].item_id == this.changeitemselected.id && !this.form.item_list[i].edited_item) {
              Swal.fire({
                  title: 'Not Allowed!',
                  icon: 'error',
                  text: 'You cannot select a similar item.',
              });
              return;
            }

            let sum = 0

            this.form.item_list[i].category_id = this.changeitemselected.category_id
            this.form.item_list[i].id = this.changeitemselected.id
            this.form.item_list[i].item_name = this.changeitemselected.item_name
            this.form.item_list[i].image_path = this.changeitemselected.image_path
            this.form.item_list[i].other_item_image_path = this.changeitemselected.other_item_image_path
            this.form.item_list[i].otherItem = false
            this.form.item_list[i].edited_item = !(this.form.item_list[i].previous_item_id_id == this.changeitemselected.id)
            this.form.item_list[i].sub_total = parseInt(this.form.item_list[i].price * this.form.item_list[i].quantities);
            if (this.selectedItemprice) {
              this.form.item_list[i].uom_id = this.item_vendor_price_lists[this.selectedItemprice].uom_id
              this.form.item_list[i].price = this.item_vendor_price_lists[this.selectedItemprice].price
              this.form.item_list[i].vendor_id = this.item_vendor_price_lists[this.selectedItemprice].id
            }
            this.form.item_list.forEach(e=>{
              sum += e.sub_total
            })

            this.form.total_amount = sum
          }
        })

      }
    },
    watch: {
      GET_ADMIN_PR_CART:{
        handler(val){

          this.form.item_list = val;
          this.amount();
        //   if(this.actions == 'Update'){


        //   this.$store.commit("ACTION", 'Update');
        //   this.getItemsrequest()
        // }
        }
      },
      GET_PURCHASE_REQUEST_NUMBER:{
        handler(val){
          this.form.prf_num = val;
        }
      },
      department_id:{
          handler(val){
            if(val){
              this.employee_selection_data = [{'value':0,'text':'GENERAL ACCOUNTABILITY','department_id':0}]
            this.GET_EMPLOYEE_DATA_SELECTION.filter(e=>{
              if(e.department_id == val){
                this.employee_selection_data.push(e)
              }
            })
            }

          }
      },
      GET_NEW_PURCHASE_REQUEST_TABLE:{
        handler(val){
          if(val){
            this.$store.commit('ACTION', '');
            this.$store.commit("DIALOG", false);
            this.resetFields();
            Swal.fire("Success!", "Purchase Request Save", "success");
            this.loading = false
            this.$store.commit('ADMIN_PR_CART',[]);
            this.$store.commit('ADMIN_OTHER_ITEMS',{})
            this.$store.commit('ADMIN_ADD_NEW_ITEMS',false)
          }
        }
      },
      DIALOGS: {
        handler(val) {
          if(val){

            if(this.batch_addition_item.length == 0){
              this.add_batch_addition_item();
            }
          }
          if(this.ACTION == "Submit" )
          {
            this.resetFields()
          }
        },
      },
      USER_ACCESS: {
        handler(val) {
          if (val != "fail") {
            this.userAccess.approve = false;
            this.userAccess.reject = false;
            val.forEach((e) => {
              if (e.actions_code == "approve") {
                this.userAccess.approve = true;
              } else if (e.actions_code == "reject") {
                this.userAccess.reject = true;
              }
            });
          }
        },
      },
      search_classification: {
      handler(val) {
        this.GET_ADMIN_CLASSIFICATION_SELECTION.filter((e) => {
          if (e.text == val) {
            this.subClass = e.sub_class;
          }
        });
      },
    },
      options: {
        handler() {
          this.getAllAdminItem();
        },
        deep: true,
      },
    },
  };
  </script>
  <style scoped>
    .red-text {
      color: red;
    }
    .previous-item-btn{
      width:20px;
      height: 139px;
      background-color:rgb(254, 176, 32) ;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
    }
    .previous-item-btn:hover{
      background-color: rgb(249, 204, 120) ;
    }
    .previous-btn-icon{
      top:40%;
      padding-left:2px;
    }
    .previous-item-container{
      box-shadow: 1px 6px 20px rgb(192, 192, 192);
      width:20px;
      height: 140px;
      background-color:rgb(255, 255, 255) ;
      border:1px solid #e7e7e7;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;


    }
    .previous-image{
      display: none;
    }
    .previous-text{
      display: none;
    }
    .previous-footer{
      width: 100%;
      height: 16px;
      background-color: rgba(0,0,0,.87);
      position:relative;
      display: none;
    }
    .previous-footer > span{
      font-size: 12px;
      color: white;
    }
  </style>