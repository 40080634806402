<template>
    <div class="w-100">
        <v-app id="my_vue_app">
            <v-card>
                <v-card-title>
                    <v-container>
                        <v-row>
                            <v-col cols="2" class="pa-0 ma-2">
                                <v-menu
                                    v-model="date_range_menu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            readonly
                                            outlined
                                            dense
                                            append-icon="mdi-calendar-clock"
                                            v-on="on"
                                            label="Date Range Filter"
                                            v-model="dateRangeText"
                                            clearable
                                            @click:clear="clearDateRange"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker  v-model="filter.date_range" range></v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-spacer></v-spacer>
                            <v-col cols="2" class="pa-0 ma-2 text-right">
                                <v-btn
                                @click="searchInput()"
                                >
                                    Filter
                                </v-btn>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="2" class="pa-0 ma-2">
                                <v-autocomplete
                                    v-model="filter.department_id"
                                    :items="GET_LIST_DEPARTMENT"
                                    @change="getHRApplicantList()"
                                    label="Select Department"
                                    dense
                                    outlined
                                    clearable
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="2" class="pa-0 ma-2">
                                <v-autocomplete
                                    v-model="filter.job_title_id"
                                    :items="GET_APPLICATION_FORM_JOB_TITLE_SELECTION"
                                    @change="getHRApplicantList()"
                                    label="Select Position"
                                    dense
                                    outlined
                                    clearable
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="2" class="pa-0 ma-2">
                                <v-autocomplete
                                    v-model="filter.job_level_id"
                                    :items="GET_JOB_LEVEL_SELECTION"
                                    @change="getHRApplicantList()"
                                    label="Select Level"
                                    dense
                                    outlined
                                    clearable
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col class="d-flex justify-end align-center mr-3" v-if="GET_HR_TAB_IS_ACTIVE !='shortlisted_applicant_list'">
                                <a
                                    v-for="(status, index ) in application_statuses" :key="index"
                                    :class="`ml-1 badge badge-${status.text.toLowerCase().replace(/\s+/g, '_')} ${status.value == selected_status ? `selected` : `non-selected`}`" @click="selectedStatus(status)"
                                    >
                                    {{ status.text ? status.text.toLowerCase() : null  }}
                                </a>
                            </v-col>
                        </v-row>
                        <v-row class="mb-2">
                            <v-spacer></v-spacer>
                            <div class="row col-sm-12 col-md-4 mr-3 align-items-end">
                                <v-text-field
                                    id="searchBar"
                                    v-model="filter.search"
                                    label="Search"
                                    single-line
                                    hide-details
                                    @change="searchInput()"
                                >
                                </v-text-field>
                                <v-icon @click="searchInput()">mdi-magnify</v-icon>
                            </div>
                        </v-row>
                    </v-container>

                </v-card-title>
                <v-container fluid>
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        :search="search"
                        :options.sync="options"
                        :server-items-length="totalItems"
                        :items-per-page="10"
                        :loading="!loaded"
                        :footer-props="{
                            itemsPerPageOptions: itemsPerPageOptions
                        }"
                        loading-text="Loading... Please wait"
                        fixed-header
                    >
                        <template v-slot:[`item.action`]="{ item, index }">
                            <v-btn text icon color="orange" :loading="loading.button && loading.index == index" v-if="userAccess.view">
                                <v-icon
                                    class="btn-action"
                                    small
                                    @click="ApproveRejectApplicant(item, index)"
                                    >mdi-eye</v-icon
                                >
                            </v-btn>
                            <v-btn text icon color="blue" :loading="loading.button && loading.index == index" v-if="userAccess.edit">
                                <v-icon
                                    class="btn-action"
                                    small
                                    @click="editApplicant(item,index)"
                                    >mdi-pencil</v-icon
                                >
                            </v-btn>
                            <!-- <v-btn text icon color="red" v-if="userAccess.delete">
                            <v-icon
                                class="btn-action"
                                small
                                @click="deletePlantilla(item)"
                                >mdi-delete</v-icon
                            >
                            </v-btn> -->
                        </template>

                        <template v-slot:[`item.status_name`]="{ item }">
                            <span :class="`badge badge-${item.status_name.toLowerCase().replace(/\s+/g, '_')}`">{{ item.status_name.toLowerCase() }}</span>
                        </template>

                        <template v-slot:[`item.job_level_name`]="{ item }">
                            <span :class="`badge badge-${item.job_level_name == null ? 'yes' :'no'}`">{{ item.job_level_name }}</span>
                        </template>
                    </v-data-table>
                </v-container>
            </v-card>
        </v-app>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
export default {
    data(){
        return {
            loaded: false,
            totalItems: 0,
            options: {},
            items: [],
            itemsPerPageOptions: [5, 10, 15, 50, 100],
            itemsPerPage: 10,
            search: "",
            filter:{
                search:"",
                department_id: '',
                job_title_id: '',
                job_level_id: '',
                status: '',
                date_range : []
            },

            headers: [
                { text: 'Full Name', align: 'left', value: 'full_name'},
                { text: 'Email', align: 'left',value:'email' },
                { text: 'Mobile No.', align: 'left', value: 'mobile_num'},
                { text: 'Position', align: 'left',value:'position_name' },
                { text: 'Level', align: 'left',value:'job_level_name' },
                { text: 'Department', align: 'left',value:'department_name' },
                { text: 'Status', align: 'left', value: 'status_name'},
                { text: 'Submission Date', align: 'left', value: 'submission_date'},

                { text: "Action", align: "center", value: "action" },
            ],

            application_statuses:[
                {text:'all', value:0},
                {text:'Pending', value:1},
                {text:'Shortlisted', value:2},
                {text:'Passed', value:3},
                {text:'Failed', value:4},
                {text:'Backout', value:5},
                // {text:'For Requirements', value:6},
                {text:'Hired', value:7},
            ],

            userAccess: {
                view: false,
                create: false,
                edit: false,
                delete: false,
                approve_reject_manpower_request:false
            },

            selected_status:'',
            isDepartmentView:0,
            loading:{
                button:false,
                index: null
            },
            selected_row:'',
            component_dispatches:[
                "getDepartment",
                "getApplicationFormJobTitlesSelection",
                "getJobLevelSelection",
			],
            date_range_menu : false
        }
    },
    mounted(){
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        this.getSearchFromUrl()
        this.getAllDropdowns()
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS',
            'GET_HR_APPLICANT_NEW_DATA',
            'USERACCOUNT_LOAD',
            'GET_HR_TAB_IS_ACTIVE',
            'GET_HR_DISPATCH',
            'GET_LIST_DEPARTMENT',
            'GET_APPLICATION_FORM_JOB_TITLE_SELECTION',
            'GET_JOB_LEVEL_SELECTION',
        ]),
        dateRangeText () {
            return this.filter.date_range.join(' - ')
        },
    },
    components:{
    },
    methods:{
        getAllDropdowns(){
			this.component_dispatches.forEach(el=>{
				this.$store.dispatch(el)
			})
		},
        selectedStatus(data){
            this.selected_status = data.value
            this.filter.status = data.value
            this.$store.commit('EXPORT_FILTERS',this.filter)
            this.getHRApplicantList()
        },
        getHRApplicantList(filter = null){
            this.items = [];
            this.loaded = false;
            let payload = {
                page            : this.options.page,
                itemsPerPage    : this.options.itemsPerPage,
                search          : this.filter.search,
                sortBy          : this.options.sortBy,
                sortDesc        : this.options.sortDesc,
                status          : this.selected_status,
                department_id        : this.filter.department_id,
                job_title_id         : this.filter.job_title_id,
                job_level_id         : this.filter.job_level_id,
                date_range                  : this.filter.date_range,
                filter          : filter,
                active_tab      : this.GET_HR_TAB_IS_ACTIVE
            };
            this.$store.dispatch(this.GET_HR_DISPATCH.get, payload).then((response) => {
                this.items      = response.data.data;
                this.loaded     = true;

                this.items.forEach(el=>{
                    this.GET_JOB_LEVEL_SELECTION.map(e=>{
                        if(el.job_level_name == e.value)
                        {
                            el.job_level_name = e.text
                        }
                        else if (el.job_level_name == null){
                            el.job_level_name = 'none'
                        }
                    })
                })

                this.totalItems = response.data.total;
            }).catch((err)=>{
                swal.fire({
                    title: 'Error',
                    text: 'Network Error',
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
                console.log(err)
            });
        },
        searchInput(){
            this.options.page = 1
            this.getHRApplicantList({ query: this.search });
        },
        ApproveRejectApplicant(item, index = -1){
            let items = {}
            this.loading.button = true
            this.selected_row = item.id
            this.loading.index = index;

            this.$store.dispatch('ShowHrApplicantList',item.id).then(response =>{
                items = response
                this.loading.button = false
                this.$store.commit("EMPLOYEE_DATA_VIEW", items);
                this.$store.commit("DIALOG", true);
                this.$store.commit("ACTION", "Approve");
                this.selected_row = ''
            }).catch((err)=>{
                swal.fire({
                    title: 'Error',
                    text: 'Network Error',
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
                console.log(err)
                this.loading.button = false
            })
        },
        editApplicant(item, index = -1){
            let items = {}
            this.loading.button = true
            this.loading.index = index;

            this.$store.dispatch('ShowHrApplicantList',item.id).then(response =>{
                items = response
                this.loading.button = false
                this.$store.commit("EMPLOYEE_DATA_VIEW", items);
                this.$store.commit("DIALOG", true);
                this.$store.commit("ACTION", "Update");
            }).catch((err)=>{
                swal.fire({
                    title: 'Error',
                    text: 'Network Error',
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
                console.log(err)
            })
        },
        getSearchFromUrl() {
            if (Object.keys(this.$route.query).length != 0) {
                Object.keys(this.$route.query).forEach(e=>{
                if(e != 'for_approval')
                    this.filter.search = atob(this.$route.query[e]);
                })
                this.searchInput();
            }
        },
        clearDateRange(){
            this.filter.date_range = [];
        },
    },
    watch:{
        options: {
            handler() {
                this.getHRApplicantList();
            },
            deep: true,
        },
        USER_ACCESS: {
            handler(val) {
                if(val != "fail"){
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                this.userAccess.create = false;
                    val.forEach(e => {
                        if (e.actions_code == 'edit') {
                            this.userAccess.edit = true;
                        } else if (e.actions_code == 'delete') {
                            this.userAccess.delete = true;
                        } else if (e.actions_code == 'view') {
                            this.userAccess.view = true;
                        } else if (e.actions_code == 'create') {
                            this.userAccess.create = true;
                        }
                    });
                }
            }
        },
        GET_HR_APPLICANT_NEW_DATA:{
            handler(val){
                if(val){
                    this.getHRApplicantList();
                    this.$store.commit('HR_APPLICANT_NEW_DATA',false)
                }
            },
            deep:true,
        },
        GET_HR_RECRUITMENT_NEW_DATA:{
            handler(val){
                if(val){
                    this.getHRApplicantList();
                    this.$store.commit('HR_RECRUITMENT_NEW_DATA',false)
                }
            },
            deep:true,
        },
        filter:{
            deep:true,
            handler(val){
                this.$store.commit('EXPORT_FILTERS',val)
            }
        },
        'filter.date_range':{
            handler(val){
                if(val.length == 2){
                    this.date_range_menu = false
                }
                if(val[0] > val[1]){
                    swal.fire("Date Range Not Valid","","warning")
                    this.filter.date_range = [];
                }
            }
        }
    }
}
</script>
<style scoped>
.selected {
    opacity: 100%;
}

.non-selected {
    opacity: 40%;
}
</style>
