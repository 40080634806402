import LogisticsReportComponent from '@/views/main/modules/Reports/LogisticsReports/LogisticsReportComponent.vue'
import repository from "@/api/credentials";

const routes = [{
    path: '/reports/logistics/logistics-report/:id',
    name: 'logistics-report',
    component: LogisticsReportComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Logistics Report',
        }
    }
]

export default routes;