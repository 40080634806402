<template>
    <v-form ref="form" v-model="valid">
        <v-card class="px-3 rounded-0">
            <!-- Table Functions -->
            <v-card-subtitle>
                <v-row>
                    <v-col cols="3">
                        <v-btn v-if="userAccess.includes('create')" class="m-3" @click="mode()">
                            <v-icon>mdi-plus</v-icon>Create
                        </v-btn>
                    </v-col>
                    <v-spacer/>
                    <v-col v-if="STORE_CASH_IN.TAB.tab_name === 'ALL'" lg="1" class="d-flex align-items-center">
                        <span :class="`badge ml-1 text-white ${chip_status === 0 ? 'bg-dark' : 'bg-secondary'}`" style="cursor:pointer" @click="chip_status = 0;getAllStoreCashIns(); ">all</span>
                        <span :class="`badge ml-1 text-white ${chip_status === 1 ? 'bg-primary' : 'bg-secondary'}`" style="cursor:pointer" @click="chip_status = 1;getAllStoreCashIns(); ">pending</span>
                        <span :class="`badge ml-1 text-white ${chip_status === 2 ? 'bg-success' : 'bg-secondary'}`" style="cursor:pointer" @click="chip_status = 2;getAllStoreCashIns(); ">confirmed</span>
                    </v-col>
                </v-row>
                <v-row class="ml-0">
                    <v-col lg="2">
                        <v-autocomplete
                            v-model="filters.prepared_by"
                            :items="GET_PREPARED_BY"
                            label="Prepared By"
                            outlined
                            dense
                            auto-select-first
                            hide-details
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col lg="2">
                        <v-autocomplete
                            v-model="filters.company_id"
                            :items="GET_PV_COMPANIES_SELECTION"
                            label="Company"
                            outlined
                            dense
                            auto-select-first
                            hide-details
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col lg="2">
                        <v-autocomplete
                            v-model="filters.store_branch_id"
                            :items="clonedStores"
                            label="Store Branch"
                            outlined
                            dense
                            auto-select-first
                            hide-details
                            clearable
                            no-data-text="No Stores Found!"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="1" class="pl-2">
                        <v-btn @click="()=>{
                                options.page = 1
                                getAllStoreCashIns()
                            }"
                        >
                            FILTER
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="ml-0">
                    <v-col cols="2">
                        <v-autocomplete
                            v-model="filters.company_classification_id"
                            :items="GET_COMPANY_CLASSIFICATION_SELECTION"
                            label="Classification"
                            no-data-text="No Classifications Found!"
                            outlined
                            dense
                            auto-select-first
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="2">
                        <v-menu
                            v-model="menu.date_from"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_from"
                                    prepend-inner-icon="mdi-calendar"
                                    outlined
                                    dense
                                    readonly
                                    v-on="on"
                                    style="display: block"
                                    background-color="white"
                                    label="Date From"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_from" @input="menu.date_from = false" :max="minDate"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="2">
                        <v-menu
                            v-model="menu.date_to"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_to"
                                    prepend-inner-icon="mdi-calendar"
                                    outlined
                                    dense
                                    readonly
                                    v-on="on"
                                    style="display: block"
                                    background-color="white"
                                    label="Date To"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_to" @input="menu.date_to = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
                <v-row>
                    <v-spacer />
                    <v-col cols="3" class="pull-right">
                        <v-text-field
                            v-model="search"
                            label="Search by Transaction #/Remarks"
                            append-icon="mdi-magnify"
                            @keydown.enter="getAllStoreCashIns()"
                            @click:append="getAllStoreCashIns()"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card-subtitle>
            <!-- Table -->
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-data-table
                            :headers="mainHeaders"
                            :items="mainItems"
                            :options.sync="options"
                            :server-items-length="totalItems"
                            :items-per-page="10"
                            :loading="tableLoad"
                            :footer-props="{
                                itemsPerPageOptions: itemsPerPageOptions
                            }"
                            dense
                            disable-sort
                        >
                            <template v-slot:[`item.store_branch_name`]="{ item }">
                                {{ item.store_branch_name != '' ? item.store_branch_name : 'ALL' }}
                            </template>
                            <template v-slot:[`item.deposit_date`]="{ item }">
                                {{ item.deposit_date | formatDate }}
                            </template>
                            <template v-slot:[`item.total_amount`]="{ item }">
                                {{ item.total_amount | currency }}
                            </template>
                            <template v-slot:[`item.status`]="{ item }">
                                <a  class="ml-1 badge text-white" :style="`background-color:${ STATUS.STORE_CASH_IN.find(e=>e.value === item.status)?.color }`">
                                    {{ STATUS.STORE_CASH_IN.find(e=>e.value === item.status)?.text }}
                                </a>
                            </template>
                            <template v-slot:[`item.confirmed_at`]="{ item }">
                                {{ item.confirmed_at | formatDateTime24hours }}
                            </template>
                            <template v-slot:[`item.action`]="{ item }">
                                <v-btn v-if="userAccess.includes('view')" text icon color="orange" @click="mode('view', item.id)">
                                    <v-icon small>mdi-eye</v-icon>
                                </v-btn>
                                <v-btn v-if="userAccess.includes('edit') && item.status == 1" text icon color="primary" @click="mode('edit', item.id)">
                                    <v-icon small>mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn v-if="userAccess.includes('delete') && item.status == 1" text icon color="error" @click="destroy(item.id)">
                                    <v-icon small>mdi-delete</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
            <!-- Dialog -->
            <v-dialog v-model="mainDialog" persistent scrollable width="65%">
                <v-card elevation="0" class="pa-0 m-0 overflow-hidden">
                    <v-card-title v-if="!dialogLoader">
                        <v-container class="pa-0">
                            <v-row class="m-0">
                                <v-col>
                                    <span class="headline">
                                        {{
                                            STORE_CASH_IN.ACTION.charAt(0).toUpperCase() +
                                            STORE_CASH_IN.ACTION.slice(1)
                                        }}
                                        Store Cash In
                                        {{ recordNumber }}
                                        {{ classificationText ? '(' + classificationText + ')' : '' }}
                                    </span>
                                </v-col>
                                <v-col cols="pull-right-1">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                color="gray"
                                                class="float-right"
                                                text
                                                icon
                                                @click="toggleDialog('close')"
                                            >
                                                <v-icon>mdi-close-circle</v-icon>
                                            </v-btn>
                                        </template>
                                            <span>Close</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                            <v-card elevation="0" dense>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-card elevation="0" dense>
                                                <v-card-text>
                                                    <v-row>
                                                        <v-col cols="3">
                                                            <v-autocomplete
                                                                v-model="form.company_id"
                                                                :readonly="isDisabled() || STORE_CASH_IN.ACTION == 'edit'"
                                                                :items="GET_PV_COMPANIES_SELECTION"
                                                                :loading="GET_PV_COMPANIES_SELECTION.length == 0"
                                                                :clearable="STORE_CASH_IN.ACTION == 'create'"
                                                                :rules="required"
                                                                hide-details="auto"
                                                                auto-select-first
                                                                outlined
                                                                dense
                                                                @change="insertStore()"
                                                            >
                                                                <template v-slot:label>
                                                                    <span>Select Company <span style="color: red;">*</span></span>
                                                                </template>
                                                            </v-autocomplete>
                                                        </v-col>
                                                        <v-col cols="3">
                                                            <v-autocomplete
                                                                v-model="form.store_branch_id"
                                                                :readonly="isDisabled() || STORE_CASH_IN.ACTION == 'edit'"
                                                                :items="branchItems"
                                                                :clearable="STORE_CASH_IN.ACTION == 'create'"
                                                                :rules="required"
                                                                hide-details="auto"
                                                                no-data-text="No Stores Found!"
                                                                auto-select-first
                                                                outlined
                                                                dense
                                                                @change="insertCompany(form.store_branch_id)"
                                                            >
                                                                <template v-slot:label>
                                                                    <span>Select Branch <span style="color: red;">*</span></span>
                                                                </template>
                                                            </v-autocomplete>
                                                        </v-col>
                                                        <v-col cols="2">
                                                            <v-menu
                                                                v-model="menu.created_at"
                                                                :close-on-content-click="false"
                                                                transition="scale-transition"
                                                                max-width="290"
                                                                disabled
                                                            >
                                                                <template v-slot:activator="{ on }">
                                                                    <v-text-field
                                                                        v-model="form.created_at"
                                                                        v-on="on"
                                                                        append-icon="mdi-calendar"
                                                                        label="Created At"
                                                                        readonly
                                                                        outlined
                                                                        dense
                                                                    ></v-text-field>
                                                                </template>
                                                                    <v-date-picker v-model="form.created_at" @input="menu.created_at = false" />
                                                            </v-menu>
                                                        </v-col>
                                                        <v-col cols="2">
                                                            <v-menu
                                                                v-model="menu.sales_date"
                                                                :close-on-content-click="false"
                                                                transition="scale-transition"
                                                                max-width="290"
                                                                :disabled="isDisabled()"
                                                            >
                                                                <template v-slot:activator="{ on }">
                                                                        <v-text-field
                                                                            v-model="form.sales_date"
                                                                            v-on="on"
                                                                            :rules="required"
                                                                            :clearable="STORE_CASH_IN.ACTION != 'view'"
                                                                            append-icon="mdi-calendar"
                                                                            readonly
                                                                            outlined
                                                                            dense
                                                                        >
                                                                            <template v-slot:label>
                                                                                <span>Sales Date <span style="color: red;">*</span></span>
                                                                            </template>
                                                                        </v-text-field>
                                                                </template>
                                                                    <v-date-picker v-model="form.sales_date" @input="menu.sales_date = false" />
                                                            </v-menu>
                                                        </v-col>
                                                        <v-col cols="2">
                                                            <v-menu
                                                                v-model="menu.deposit_date"
                                                                :close-on-content-click="false"
                                                                transition="scale-transition"
                                                                max-width="290"
                                                                :disabled="isDisabled()"
                                                            >
                                                                <template v-slot:activator="{ on }">
                                                                        <v-text-field
                                                                            v-model="form.deposit_date"
                                                                            v-on="on"
                                                                            :rules="required"
                                                                            :clearable="STORE_CASH_IN.ACTION != 'view'"
                                                                            append-icon="mdi-calendar"
                                                                            readonly
                                                                            outlined
                                                                            dense
                                                                        >
                                                                            <template v-slot:label>
                                                                                <span>Deposit Date <span style="color: red;">*</span></span>
                                                                            </template>
                                                                        </v-text-field>
                                                                </template>
                                                                    <v-date-picker v-model="form.deposit_date" @input="menu.deposit_date = false"/>
                                                            </v-menu>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <v-textarea
                                                                v-model="form.remarks"
                                                                :readonly="isDisabled()"
                                                                :rules="required"
                                                                rows="2"
                                                                auto-grow
                                                                outlined
                                                                dense
                                                            >
                                                                <template v-slot:label>
                                                                    <span>Remarks <span style="color: red;">*</span></span>
                                                                </template>
                                                            </v-textarea>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="2" class="mt-2">
                                                            <h6>Details: </h6>
                                                        </v-col>
                                                        <v-spacer/>
                                                        <v-col cols="2">
                                                            <v-text-field
                                                                v-model="searchDetails"
                                                                label="Search"
                                                                append-icon="mdi-magnify"
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-spacer/>
                                                        <v-col cols="1" class="text-right" v-show="!isDisabled()">
                                                            <v-tooltip top>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn v-on="on" fab dark small color="primary" style="height: 20px; width: 20px;" @click="addDetail()">
                                                                        <v-icon dark small style="font-size: 12px;">
                                                                            mdi-plus
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                    <span>Add</span>
                                                            </v-tooltip>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <v-card max-height="400px" style="overflow-y: auto">
                                                                <v-card-text>
                                                                    <v-data-table
                                                                        :headers="store_cash_in_detail_headers.filter(e=>e.visible != false)"
                                                                        :items="form.store_cash_in_details"
                                                                        :search="searchDetails"
                                                                        :items-per-page="5"
                                                                        :loading="store_cash_in_details_load"
                                                                        disable-sort
                                                                    >
                                                                        <template #[`item.name`]="{item}">
                                                                            <v-text-field
                                                                                v-if="STORE_CASH_IN.ACTION != 'view'"
                                                                                v-model="item.name"
                                                                                :rules="required"
                                                                                hide-details="auto"
                                                                                class="center-align-text"
                                                                                dense
                                                                            />
                                                                            <span v-else>
                                                                                {{ item.name }}
                                                                            </span>
                                                                        </template>
                                                                        <template #[`item.coa_id`]="{item}">
                                                                            <v-autocomplete
                                                                                v-if="STORE_CASH_IN.ACTION != 'view'"
                                                                                v-model="item.coa_id"
                                                                                :items="GET_CHART_OF_ACCOUNTS_SELECTION"
                                                                                :rules="required"
                                                                                hide-details="auto"
                                                                                class="center-align-text"
                                                                                item-value="id"
                                                                                item-text="account_name"
                                                                                dense
                                                                            ></v-autocomplete>
                                                                            <span v-else>
                                                                                {{ GET_CHART_OF_ACCOUNTS_SELECTION.find(e => e.id == item.coa_id).account_name }}
                                                                            </span>
                                                                        </template>
                                                                        <template #[`item.plus_minus`]="{item}">
                                                                            <v-icon
                                                                                v-if="STORE_CASH_IN.ACTION != 'view'"
                                                                                :color="item.plus_minus == '+' ? '#5AB55E' : '#FF6060'"
                                                                                @click="()=>{
                                                                                    item.plus_minus = item.plus_minus == '+' ? '-' : '+'
                                                                                    sumAmount()
                                                                                }"
                                                                            >
                                                                                {{ item.plus_minus == '+' ? 'mdi-plus-box' : 'mdi-minus-box'  }}
                                                                            </v-icon>
                                                                            <v-icon
                                                                                v-else
                                                                                :color="item.plus_minus == '+' ? '#5AB55E' : '#FF6060'"
                                                                            >
                                                                                {{ item.plus_minus == '+' ? 'mdi-plus-box' : 'mdi-minus-box'  }}
                                                                            </v-icon>
                                                                        </template>
                                                                        <template #[`item.total_amount`]="{item}">
                                                                            <span v-if="STORE_CASH_IN.ACTION == 'view'">{{ item.total_amount | currency }}</span>
                                                                            <v-text-field
                                                                                v-else
                                                                                v-model="item.total_amount"
                                                                                :rules="required"
                                                                                hide-details="auto"
                                                                                class="right-align-text"
                                                                                dense
                                                                                @keypress="numberAndDotOnly($event, item)"
                                                                                @input="sumAmount()"
                                                                            ></v-text-field>
                                                                        </template>
                                                                        <template #[`item.action`]="{ item, index }">
                                                                            <v-btn text icon color="red" @click="deleteDetail(index)" :disabled="isDisabled()">
                                                                                <v-icon class="btn-action" small>mdi-delete</v-icon>
                                                                            </v-btn>
                                                                        </template>
                                                                        <!-- <template v-slot:body.append="{ headers, items }">
                                                                            <tr v-if="items.length">
                                                                                <td :colspan="headers.length - 1" class="text-right">
                                                                                    <strong>{{ `Total Amount: ` }}</strong>
                                                                                </td>
                                                                                <td class="text-right"><strong>{{!isNaN(form.total_amount) ? form.total_amount : ''}}</strong></td>
                                                                            </tr>
                                                                        </template> -->
                                                                    </v-data-table>
                                                                </v-card-text>
                                                            </v-card>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-spacer/>
                                                        <v-col cols="8" class="text-right">
                                                            <strong>Total Amount: </strong>
                                                        </v-col>
                                                        <v-col cols="1" class="text-right">
                                                            <strong>{{ form.total_amount | currency }}</strong>
                                                        </v-col>
                                                        <v-spacer/>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                            <v-container>
                                                <v-row class="py-2">
                                                    <v-col class="mt-5">
                                                        <span>Required fields are marked with <span style="color: red;">*</span></span>
                                                    </v-col>
                                                    <v-col class="text-center">
                                                        <v-btn :disabled="!validToProceed" :loading="btnLoad"
                                                            v-if="STORE_CASH_IN.ACTION != 'view'"
                                                            @click="commitStoreCashIn(STORE_CASH_IN.ACTION, STORE_CASH_IN.ACTION == 'create' ? 'addStoreCashIn' : 'updateStoreCashIn')"
                                                        >
                                                            {{ STORE_CASH_IN.ACTION == 'create' ? 'Create' : 'Update' }}
                                                        </v-btn>
                                                        <v-btn v-else-if="form.status === 1 && userAccess.includes('confirm')" @click="commitStoreCashIn('approve','updateStoreCashIn')">
                                                            CONFIRM
                                                        </v-btn>
                                                    </v-col>
                                                    <v-spacer/>
                                                </v-row>
                                            </v-container>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-container>
                    </v-card-title>
                    <div v-else class="text-center pa-2">
                        <v-progress-circular
                            :size="80"
                            color="primary"
                            indeterminate
                        />
                    </div>
                </v-card>
                <!-- <StoreCashInViewComponent :viewStoreCashIn="viewStoreCashIn" :userAccess="userAccess" @closeView="toggleDialog('close')"/> -->
            </v-dialog>
        </v-card>
    </v-form>
</template>
<script>
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue'
import StoreCashInViewComponent from './StoreCashInViewComponent.vue'
export default {
    mixins: [SharedFunctionsComponentVue],
    props: ['userAccess'],
    components:{StoreCashInViewComponent},
    data(){
        return{
            valid: false,
            mainDialog: false,
            mainHeaders: [
                { text: 'Transaction #', value:'transaction_number' },
                { text: 'Company', value:'company_name' },
                { text: 'Store Branch', value:'store_branch_name' },
                { text: 'Deposit Date', value:'deposit_date', align: 'left' },
                { text: 'Remarks', value:'remarks', align:'left' },
                { text: 'Total Amount', value:'total_amount', align:'right' },
                { text: 'Prepared By', value:'prepared_by_name', align:'center' },
                { text: 'Confirmed By', value:'confirmed_by_name', align:'center' },
                { text: 'Confirmed At', value:'confirmed_at', align:'center' },
                { text: 'Status', value:'status', align:'center' },
                { text: 'Actions', value:'action', align: 'center' }
            ],
            mainItems: [],
            options: {},
            totalItems: 0,
            tableLoad: false,
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            itemsPerPage: 10,
            search: '',
            dialogLoader: false,
            form:{
                id: null,
                transaction_number: null,
                company_id: null,
                store_branch_id: null,
                created_at: this.$dayjs().format('YYYY-MM-DD'),
                deposit_date: this.$dayjs().format('YYYY-MM-DD'),
                sales_date: this.$dayjs().format('YYYY-MM-DD'),
                remarks: null,
                status: 1,
                total_amount: 0.00,
                store_cash_in_details: [],
                store_cash_in_template: {}
            },
            store_cash_in_details_trash: [],
            btnLoad: false,
            filters:{
                prepared_by: null,
                company_id: null,
                store_branch_id: null,
                date_from: this.$dayjs().startOf('month').format('YYYY-MM-DD'),
                date_to:  this.$dayjs().endOf('month').format('YYYY-MM-DD'),
                company_classification_id: null
            },
            clonedStores: [],
            required: [
                v => !!v || 'This field is required'
            ],
            store_cash_in_detail_headers:[
                { text: 'Criteria', value: 'name', width: 200, align: 'center', class: 'required' },
                { text: 'CoA', value: 'coa_id', width: 200, align: 'center', class: 'required' },
                { text: 'Plus/Minus', value: 'plus_minus', width: 50, align: 'center', class: 'required' },
                { text: 'Amount', value: 'total_amount', width: 100, align: 'right', class: 'required' },
                { text: 'Action', value: 'action', width: 50, align: 'center', visible: true },
                { text: '', value: 'fill', width: 50, align: 'center', visible: true },
            ],
            store_cash_in_details_load: false,
            chip_status: 0,
            dispatchDetails: false,
            allFields: false,
            menu: {
                date_from: false,
                date_to: false,
                created_at: false,
                sales_date: false,
                deposit_date: false
            },
            minDate: this.$dayjs().format('YYYY-MM-DD'),
            viewStoreCashIn: 0,
            searchDetails: ''
        }
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS',
            'USERACCOUNT_LOAD',
            'GET_CHART_OF_ACCOUNTS_SELECTION',
            'STORE_CASH_IN',
            'GET_PV_COMPANIES_SELECTION',
            'GET_PV_STORE_BRANCHES_SELECTION',
            'STATUS',
            'SELECTED_TAB',
            'GET_STORE_CASH_IN_SELECTION',
            'GET_PREPARED_BY',
            'GET_COMPANY_CLASSIFICATION_SELECTION'
        ]),
        branchItems(){
            let items = this.clonedStores.filter(e=>e.company_id == this.form.company_id)
            if (!this.form.company_id) return this.clonedStores
            // if (!this.form.store_branch_id) this.form.store_branch_id = items[0].value
            return items
        },
        validToProceed(){
            if (this.form.store_cash_in_details.length == 0) {
                return false
            }
            return this.valid
        },
        recordNumber(){
            return this.form.transaction_number ? '# : ' + this.form.transaction_number : ''
        },
        classificationText() {
            let classificationId = this.branchItems.find(e => e.value == this.form.store_branch_id)?.company_classification_id
                ?? this.GET_PV_COMPANIES_SELECTION.find(e => e.value == this.form.company_id)?.company_classification_id;
            return classificationId
                ? this.GET_COMPANY_CLASSIFICATION_SELECTION.find(el => el.value == classificationId)?.text ?? ''
                : '';
        }
    },
    mounted(){
        this.getAllStoreCashIns()
        this.getBranches()
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.handleKeydown);
    },
    methods:{
        toggleDialog(action = 'close'){
            switch(action){
                case 'open':
                    this.valid = false
                    // this.dispatchDetails = true
                    this.mainDialog = true
                    document.addEventListener('keydown', this.handleKeyDown)
                    if (this.form.store_cash_in_details.length == 0) this.addDetail()
                    break;
                case 'close':
                    this.mainDialog = false
                    this.clearFields()
                    this.getAllStoreCashIns()
                    document.removeEventListener('keydown', this.handleKeydown);
                    break;
            }
        },
        clearFields(){
            this.$refs.form.resetValidation()
            this.form = {
                id: null,
                company_id: null,
                store_branch_id: null,
                created_at: this.$dayjs().format('YYYY-MM-DD'),
                deposit_date: this.$dayjs().format('YYYY-MM-DD'),
                sales_date: this.$dayjs().format('YYYY-MM-DD'),
                remarks: null,
                status: 1,
                total_amount: 0.00,
                store_cash_in_details: []
            }
            this.chip_status = 0
            this.dispatchDetails = false
            // this.viewStoreCashIn = 0
            this.$forceUpdate()
        },
        commitStoreCashIn(action, url){
            if (!this.$refs.form.validate()) {
                if (!this.checkRequiredFields()) return
            } else {
                if (this.form.store_cash_in_details.length == 0) {
                    Swal.fire('', '- No Details Found!', 'error')
                    return false
                }
                this.btnLoad = true
                Swal.fire({
                    title: '',
                    text: `Are you sure you want to ${action} this store cash in?`,
                    icon: 'question',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    allowOutsideClick: false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor: 'grey',
                }).then(async (result) => {
                    if (!result.isConfirmed) {
                        this.btnLoad = false
                        return
                    }
                    this.dialogLoader = true
                    if (action == 'approve') this.form.status = 2
                    let payload = {
                        form: this.form,
                        trash: this.store_cash_in_details_trash,
                        url: url
                    }
                    this.$store.dispatch('storeCashInPost', payload).then(response => {
                        Swal.fire('', response.data.message, response.data.status);
                        this.getAllStoreCashIns();
                        this.toggleDialog('close');
                        this.btnLoad = false
                        this.dialogLoader = false
                    }).catch(error=>{
                        Swal.fire('', error.response.data.message, error.response.data.status);
                        this.btnLoad = false
                        this.dialogLoader = false
                    })
                })
            }
        },
        checkRequiredFields(){
            let emptyFields = []
            let required = {
                'company_id': 'Company',
                'store_branch_id': 'Store Branch',
                'sales_date': 'Sales Date',
                'deposit_date': 'Deposit Date',
                'remarks': 'Remarks'
            }
            let requiredDetails = {
                'total_amount': 'Total Amount'
            }
            Object.keys(required).forEach(e=>{
                if (!this.form[e]) {
                    emptyFields.push(required[e])
                }
            })
            let rowCount = 1
            this.form.store_cash_in_details.forEach(item => {
                Object.entries(item).forEach(([key, value]) => {
                    if (key != 'deleted_at' && key != 'paid' && key != 'autoFill' && (value == null || value == '')) {
                        emptyFields.push(`Detail ${rowCount}: ${requiredDetails[key]}`)
                    }
                })
                rowCount++
            })
            if (emptyFields.length > 0) {
                Swal.fire({
                    title: '- Please fill the following fields: ',
                    html: emptyFields.map(field => `${field}<br>`).join(''),
                    icon: 'error'
                })
                return false
            }
            return true
        },
        getAllStoreCashIns(){
            this.tableLoad = true
            this.mainItems = []
            let payload = {
                prepared_by: this.filters.prepared_by,
                company_id: this.filters.company_id,
                store_branch_id: this.filters.store_branch_id,
                search: this.search,
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                tab: this.STORE_CASH_IN.TAB.tab_name,
                chip_status: this.chip_status,
                date_range:[this.filters.date_from,this.filters.date_to],
                company_classification_id: this.filters.company_classification_id,
                url: 'getAllStoreCashIns'
            }
            this.$store.dispatch('transactionJournalGet', payload).then(response=>{
                let data = response.data.data
                this.mainItems = data.data
                this.totalItems = data.total
                this.tableLoad = false
            })
        },
        mode(action = 'create', id = null){
            this.dispatchDetails = false
            this.$store.commit('STORE_CASH_IN_ACTION', action)
            if (!id) {
                // this.dispatchDetails = true
                this.clearFields()
                this.toggleDialog('open')
            } else {
                this.dialogLoader = true
                this.btnLoad = true
                this.toggleDialog('open')
                // this.viewStoreCashIn = id
                this.$store.dispatch('storeCashInPost', {id: id, url: 'getStoreCashIn'}).then(response=>{
                    this.getStoreBranches(2)
                    this.form = response.data
                    this.form.created_at = this.$dayjs(this.form.created_at).format('YYYY-MM-DD')
                    this.form.deposit_date = this.$dayjs(this.form.deposit_date).format('YYYY-MM-DD')
                    this.form.sales_date = this.$dayjs(this.form.sales_date).format('YYYY-MM-DD')
                    this.form.total_amount = parseFloat(this.form.total_amount).toFixed(2)
                    this.form.store_cash_in_details.forEach(e=>{
                        // e.store_cash_in_template.store_cash_in_detail_template_items.forEach(v=>{
                        //     if (e.coa_id == v.coa_id) {
                        //         e.name = v.name
                        //         e.coa_name = v.coa_name
                        //         e.plus_minus = v.plus_minus
                                e.total_amount = parseFloat(e.total_amount).toFixed(2)
                        //     }
                        // })
                    })
                    this.dialogLoader = false
                    this.btnLoad = false
                })
            }
        },
        isDisabled(){
            switch(this.STORE_CASH_IN.ACTION){
                case 'view':
                    return true
            }
        },
        destroy(id){
            Swal.fire({
                title: '',
                text: '- Are you sure you want to delete this record?',
                icon: 'warning',
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                allowOutsideClick: false,
                confirmButtonColor: '#397373',
                cancelButtonColor: 'grey',
                confirmButtonColor: 'Confirm'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('storeCashInPost', { id: id, url: 'deleteStoreCashIn' }).then(response => {
                        Swal.fire('', response.data.message, response.data.status)
                        this.getAllStoreCashIns();
                    }).catch(error => {
                        Swal.fire('', error.response.data.message, error.response.data.status)
                    })
                }
            })
        },
        async getBranches(){
            try{
                this.clonedStores = await this.getStoreBranches()
            } catch (error) {
                console.error(error)
            }
        },
        insertCompany(storeId){
            if (!storeId) return
            let store = this.clonedStores.find(e=>e.value == storeId)
            this.form.company_id = this.GET_PV_COMPANIES_SELECTION.find(e=>e.value == store.company_id).value
            // if (this.form.company_id && this.form.store_branch_id) this.dispatchDetails = true
        },
        insertStore(){
            this.form.store_branch_id = this.branchItems[0].value
            // if (this.form.company_id && this.form.store_branch_id) this.dispatchDetails = true
        },
        handleKeyDown(event){
            if (this.mainDialog && !this.dialogLoader) this.closeDialogByEsc(event, this.toggleDialog)
        },
        numberAndDotOnly($event, item) {
            const allowedCharacters = /^[0-9.]*$/;
            const inputChar = $event.key;
            if (!allowedCharacters.test(inputChar)) {
                $event.preventDefault();
                return
            }
            if (inputChar === '.' && item.total_amount.includes('.')) {
                $event.preventDefault();
            }
        },
        sumAmount(){
            this.form.total_amount = 0.00;
            this.form.store_cash_in_details.forEach(e => {
                if (e.plus_minus === "+") {
                    this.form.total_amount += parseFloat(e.total_amount);
                } else {
                    this.form.total_amount -= parseFloat(e.total_amount);
                }
            });
            if (isNaN(this.form.total_amount)) {
                this.form.total_amount = 0.00;
            } else {
                this.form.total_amount = this.form.total_amount
            }
        },
        addDetail(){
            this.form.store_cash_in_details.push({
                name: '',
                coa_id: null,
                plus_minus: '+',
                total_amount: 0.00
            })
        },
        deleteDetail(index){
            this.store_cash_in_details_trash.push(this.form.store_cash_in_details[index])
            this.form.store_cash_in_details.splice(index, 1)
            if (this.form.store_cash_in_details.length == 0) this.addDetail()
            this.sumAmount()
        },
    },
    watch:{
        options:{
            handler(){
                this.getAllStoreCashIns()
            },
            deep:true
        },
        SELECTED_TAB:{
            handler(){
                this.options.page = 1
                this.getAllStoreCashIns()
            }
        },
        search:{
            handler(val){
                if (val) {
                    this.options.page = 1
                }
            },
            immediate: true
        },
        'form.company_id':{
            handler(oldVal, newVal){
                if (oldVal != newVal && !!this.form.company_id) {
                    this.dispatchDetails = true
                }
                if (!this.form.company_id) {
                    this.form.store_branch_id = null
                    this.form.store_cash_in_details = []
                }
            },
            immediate: true
        },
        'form.store_branch_id':{
            handler(oldVal, newVal){
                if (oldVal != newVal && !!this.form.store_branch_id) {
                    this.dispatchDetails = true
                }
                if (!this.form.store_branch_id) {
                    this.form.company_id = null
                    this.form.store_cash_in_details = []
                }
            },
            immediate: true
        },
        'dispatchDetails':{
            handler(val){
                if (val) {
                    if (this.STORE_CASH_IN.ACTION != 'create') {
                        this.dispatchDetails = false
                        return
                    }
                    this.form.store_cash_in_details = []
                    let payload = {
                        company_id: this.form.company_id,
                        store_branch_id: this.form.store_branch_id,
                        url: 'getStoreCashInTemplates'
                    }
                    this.$store.dispatch('storeCashInGet', payload).then(response=>{
                        this.store_cash_in_details_load = true
                        this.dispatchDetails = false
                        this.form.total_amount = 0.00
                        this.form.store_cash_in_details = response.data
                        if (this.form.store_cash_in_details.length == 0) {
                            Swal.fire('No Template Found!', '- create a template in the TEMPLATES tab', 'error');
                        }
                        this.form.store_cash_in_details.forEach(e=>{
                            e.total_amount = 0.00
                        })
                        this.store_cash_in_details_load = false
                    })
                }
            },
            immediate: true
        },
        'STORE_CASH_IN.ACTION':{
            handler(val){
                let actionHeader = this.store_cash_in_detail_headers.find(e=>e.value == 'action')
                let fillHeader = this.store_cash_in_detail_headers.find(e=>e.value == 'fill')
                if (val === 'view') {
                    actionHeader.visible = false
                    fillHeader.visible = true
                } else {
                    actionHeader.visible = true
                    fillHeader.visible = false
                }
            },
            immediate: true
        }
    }
}
</script>
<style scoped>
::v-deep .right-align-text input {
    text-align: right;
}
::v-deep .center-align-text input {
    text-align: center;
}
</style>
<style>
.required:after {
    content: ' *';
    color:red;
}
</style>