import StoreFlowGroupComponent from "@/views/main/modules/Stores_/StoreFlow/StoreFlowGroupComponent.vue";
import repository from "@/api/credentials";

const routes =
        [{
              path: "/stores/store-flow/:id",
              name: "storeflow",
              component: StoreFlowGroupComponent,
              beforeEnter:(to,from,next)=>{
                repository.checkAccessUser(to.params.id).then((response)=>{
                    if(response.data == 'fail'){
                        next('/no-access')
                        }else{
                        next()
                    }
                }).catch(()=>{

                })

          },
              meta:{
                   requiresAuth:true
                 }
          }]


export default routes;