<template>
    <v-form ref="form" lazy-validation>
      <v-card>
        <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
          <span class="text-h5">{{ACTION == 'Submit' ? 'CREATE' : ACTION.toUpperCase()}} PURCHASE ORDER {{form.po_num ? '#: '+ form.po_num : ''}}</span>
          <v-row class="m-0">
            <v-col cols="pull-right-10 p-2">
              <v-btn
                v-if="!loading"
                text
                icon
                small
                color="gray"
                class="float-right"
                @click="closeDialog()"
              >
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text style="height: 600px;" v-if="!loading">
            <v-container fluid class="pb-0">
                <v-row>
                    <v-col cols="6" class="mb-2">
                       <SectionHeading :heading="'Company Details'"></SectionHeading>
                       <v-row>
                            <v-col cols="5" class="pa-4">
                                <v-autocomplete
                                    v-model="form.company_id"
                                    :items="GET_PV_COMPANIES_SELECTION"
                                    :disabled="ACTION == 'View'"
                                    item-text="text"
                                    item-value="value"
                                    auto-select-first
                                    dense
                                    outlined
                                    required
                                    @change="insertStore()"
                                >
                                    <template v-slot:label>
                                        <span>Company <span style="color: red;">*</span></span>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="5" class="pa-4">
                                <v-autocomplete
                                    v-model="form.store_branch_id"
                                    :items="branchItems"
                                    :disabled="ACTION == 'View'"
                                    item-text="text"
                                    item-value="value"
                                    auto-select-first
                                    dense
                                    outlined
                                    required
                                    @change="insertCompany(form.store_branch_id)"
                                >
                                    <template v-slot:label>
                                        <span>Branch <span style="color: red;">*</span></span>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                       </v-row>
                    </v-col>
                    <v-col cols="6" class="mb-2">
                        <SectionHeading :heading="'Requestor Details'"></SectionHeading>
                        <v-row>
                            <v-spacer></v-spacer>
                            <v-col cols="1" class="pa-4 ">
                                <v-checkbox
                                    v-model="form.billable"
                                    :true-value="1"
                                    :false-value="0"
                                    :disabled="ACTION == 'View'"
                                    style="margin-top: 4px"
                                    label="Billable"
                                    hide-details
                                ></v-checkbox>
                            </v-col>
                            <v-col cols="5" class="pa-4 ml-3">
                                <v-combobox
                                    v-model="form.po_requestor"
                                    :items="GET_CUSTOMERS_LIST_SELECTION"
                                    :disabled="ACTION == 'View' || !form.billable"
                                    label="Requestor"
                                    dense
                                    outlined
                                    hide-details
                                    auto-select-first
                                    @input="onInput"
                                    @keypress="forceUpperCaseText($event)"
                                ></v-combobox>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="mb-2">
                       <SectionHeading :heading="'Vendor Details'"></SectionHeading>
                    </v-col>
                    <v-col cols="12" class="pa-4">
                        <v-row>
                            <v-col cols="3">
                                <v-autocomplete
                                    v-model="form.vendor_id"
                                    :items="GET_ADMIN_SUPPLIER_SELECTION"
                                    :disabled="ACTION == 'View'"
                                    item-text="text"
                                    item-value="value"
                                    auto-select-first
                                    dense
                                    outlined
                                    required
                                >
                                    <template v-slot:label>
                                        <span>Vendor <span style="color: red;">*</span></span>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="2">
                                <v-autocomplete
                                    v-model="form.vendor_payment_item_id"
                                    :items="GET_TERM_SELECTION"
                                    :disabled="ACTION == 'View'"
                                    item-text="text"
                                    item-value="value"
                                    label="Term"
                                    auto-select-first
                                    dense
                                    outlined
                                    required
                                ></v-autocomplete>
                            </v-col>
                            <v-col :cols="form.payment_method === 3 ? 2 : 4">
                                <v-autocomplete
                                    v-model="form.vendor_address_id"
                                    :items="GET_CONTACT_PERSON_SELECTION"
                                    :disabled="ACTION == 'View'"
                                    item-text="text"
                                    item-value="value"
                                    label="Contact person"
                                    auto-select-first
                                    dense
                                    outlined
                                    required
                                ></v-autocomplete>
                            </v-col>
                            <v-col :lg="form.payment_method === 3 ? 2 : 3" class="d-flex justify-content-center align-items-center">
                               <v-autocomplete
                                   v-model="form.payment_method"
                                   :items="PURCHASE_ORDER.PAYMENT_METHOD"
                                   :disabled="ACTION == 'View'"
                                   item-text="text"
                                   item-value='value'
                                   auto-select-first
                                   clearable
                                   dense
                                   outlined
                                   required
                               >
                                    <template v-slot:label>
                                        <span>Payment Method <span style="color: red;">*</span></span>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col lg="2" class="d-flex justify-content-center align-items-center" v-if="form.payment_method == 3">
                                <v-text-field
                                    v-model="form.amount_to_pay"
                                    :label="form.type == 0 ? 'Enter amount to pay' : 'Enter percentage to pay'"
                                    outlined
                                    dense
                                    :disabled="ACTION == 'View' || form.item_list[0].total_amount == 0"
                                    @keypress="numberandDotOnly"
                                >
                                <template v-slot:append>
                                        <v-btn
                                        icon
                                        text
                                        small
                                        :disabled="ACTION == 'View'"
                                        @click="form.type = form.type == 1 ? 0 : 1;cashOrPercentage()"
                                        >
                                            <v-icon v-if="form.type == 0" color="green">mdi-cash</v-icon>
                                            <v-icon v-else color="orange">mdi-percent-outline</v-icon>
                                        </v-btn>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col lg="1" class="d-flex justify-content-center align-items-center" v-if="form.payment_method == 3">
                                <v-text-field
                                    :value="percentageComputation(form.amount_to_pay, form.type)"
                                    :label="form.type == 1 ? 'Cash Value' : 'Percentage Value'"
                                    outlined
                                    dense
                                    disabled
                                >
                                <template v-slot:append>
                                        <v-btn
                                        icon
                                        text
                                        small
                                        disabled
                                        >
                                            <v-icon v-if="form.type == 1" color="green">mdi-cash</v-icon>
                                            <v-icon v-else color="orange">mdi-percent-outline</v-icon>
                                        </v-btn>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="mb-2">
                        <SectionHeading :heading="'PO Details'"></SectionHeading>
                    </v-col>
                    <v-col cols="12" class="pa-4">
                        <v-row>
                            <v-col cols="3">
                                <v-text-field
                                    v-model="form.po_num"
                                    label="PO#"
                                    outlined
                                    dense
                                    :disabled="ACTION == 'View'"
                                    @keydown="forceUpperCaseText($event)"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-autocomplete
                                    v-model="form.payment_type_id"
                                    :items="GET_PAYMENT_TYPES"
                                    outlined
                                    dense
                                    label="Payment Type"
                                    :disabled="ACTION == 'View'"
                                    item-text="name"
                                    item-value="id"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="3">
                                <v-autocomplete
                                    v-model="form.location_id"
                                    :items="GET_ADMIN_DELIVERY_ADDRESSES"
                                    :disabled="ACTION == 'View'"
                                    auto-select-first
                                    outlined
                                    required
                                    dense
                                    clearable
                                >
                                    <template v-slot:label>
                                        <span>Shipped to <span style="color: red;">*</span></span>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="2">
                                <v-autocomplete
                                    v-model="form.shipping_via_id"
                                    :items="GET_SHIPPING_VIA"
                                    :disabled="ACTION == 'View'"
                                    item-text="text"
                                    item-value="value"
                                    dense
                                    outlined
                                    required
                                >
                                    <template v-slot:label>
                                        <span>Shipping Via <span style="color: red;">*</span></span>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field
                                    v-model="form.contact_person"
                                    outlined
                                    required
                                    label="Shipped to Contact Person"
                                    :disabled="ACTION == 'View'"
                                    dense
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field
                                    v-model="form.contact_number"
                                    outlined
                                    required
                                    label="Shipped to Contact Number"
                                    :disabled="ACTION == 'View'"
                                    dense
                                ></v-text-field>
                            </v-col>
                            <v-col cols="2">
                                <v-menu
                                :close-on-content-click="true"
                                :nudge-right="80"
                                :nudge-bottom="42"
                                transition="scale-transition"
                                max-width="290"
                                >
                                <template v-slot:activator="{ on }">
                                   <v-text-field
                                       v-model="form.expected_date"
                                       v-on="on"
                                       :disabled="ACTION == 'View'"
                                       label="Expected date"
                                       append-icon="mdi-calendar"
                                       readonly
                                       dense
                                       outlined
                                   ></v-text-field>
                                </template>
                                    <v-date-picker v-model="form.expected_date">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="1">
                                <v-text-field
                                    v-model="form.number_of_days"
                                    class="right-input"
                                    outlined
                                    dense
                                    suffix="days"
                                    :disabled="ACTION == 'View'"
                                    @keypress="numberOnly"
                                />
                            </v-col>
                            <v-col cols="1">
                                <v-checkbox
                                    v-model="form.rush"
                                    :disabled="ACTION == 'View'"
                                    :true-value="1"
                                    :false-value="0"
                                    label="Rush"
                                    hide-details
                                    dense
                                >
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                               <v-textarea
                                   v-model="form.remarks"
                                   :auto-grow="true"
                                   :disabled="ACTION == 'View'"
                                   label="Remarks"
                                   dense
                                   outlined
                                   rows="1"
                               ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="mb-2">
                       <SectionHeading :heading="'Item Detials'"></SectionHeading>
                    </v-col>
                    <v-col cols="12" class="pa-4">
                        <v-row dense>
                            <span cols="1" class="pull-right pt-2">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" text icon color="red" @click="()=>{getPOActionHistory()}">
                                            <v-icon class="btn-action" >mdi-text-box-search</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Action History</span>
                                </v-tooltip>
                            </span>
                            <v-spacer/>
                            <div v-if="ACTION != 'View'" cols="3" class="pull-right pb-2">
                                <FileUploadComponentVue
                                    style="display:inline-block; transform: translateY(5px);"
                                    ref="fileUpload"
                                    id="attachment"
                                    @uploadedData="uploadedData"
                                    @reset="clearFileUpload"
                                />
                            </div>
                            <!-- <div v-if="[4,6,7,9,10].includes(form.status) && ACTION == 'Submit'" class="p-0 mt-4"> -->
                            <div  class="p-0 mt-4">
                                <BaseFileViewerComponent
                                    :access="userAccess.includes('file_view')"
                                    :payloadIds="[]"
                                    :payloadId="SELECTED ? SELECTED.id : null"
                                    :getFilesPath="'getAdminPOFiles'"
                                    :deleteFilesPath="[1,2,3].includes(form.status) ? 'deleteAdminPOFiles' : ''"
                                    :module="'admin'"
                                    :folder="'po_file_uploads'"
                                    :localFiles="uploadedFiles"
                                    @files="processFiles"
                                    ref="closeDialog"
                                />
                            </div>
                            <div class="py-2 mt-5" v-if="ACTION == 'Submit'">
                                <v-layout row class="mx-0">
                                    <v-flex lg2 class="mr-2">
                                        <BaseCameraCaptureVue ref="cameraCapture" @capturedImages="getImages" />
                                    </v-flex>
                                    <v-flex v-if="!!images && images.length > 0" lg3 class="mr-2 mt-1 ">
                                        <span>Images Count: {{ images.length }}</span>
                                    </v-flex>
                                    <v-flex v-if="!!images && images.length > 0">
                                        <v-btn @click="clearCameraCapture()">Clear</v-btn>
                                    </v-flex>
                                </v-layout>
                            </div>
                        </v-row>
                        <v-row dense>
                            <v-col cols="1">
                                <v-btn @click="()=>{
                                        batchAddition.dialog = !batchAddition.dialog
                                    }"
                                    color="orange"
                                    :disabled="!form.vendor_id"
                                    v-if="ACTION != 'View'"
                                >
                                <v-icon dense>mdi-plus</v-icon>batch
                                </v-btn>
                            </v-col>
                            <v-col lg="2">
                                <v-autocomplete
                                    v-if="ACTION !== 'View'"
                                    v-model="form.template_id"
                                    :items="selection.po_item_template"
                                    auto-select-first
                                    item-value="value"
                                    item-text="text"
                                    outlined
                                    hide-details
                                    dense
                                    placeholder="Item Template"
                                    label="Item Template"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="1" class="pl-2 d-flex justify-content-start">
                                <v-tooltip top v-if="ACTION !== 'View'">
                                    <template v-slot:activator="{ on }">
                                        <v-btn @click="()=>{
                                            getItemTemplateDetails(form.template_id)
                                            }"
                                            v-on="on"
                                            :disabled="!form.vendor_id"
                                            icon
                                            small
                                            fab
                                            :loading="apply_loading"
                                            color="#497e7e"
                                            class="d-flex align-items-start pt-2 px-3"
                                        >
                                            <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Apply Template</span>
                                </v-tooltip>
                                <v-tooltip top v-if="ACTION !== 'View'">
                                    <template v-slot:activator="{ on }">
                                        <v-btn @click="()=>{
                                            modifyItem(4)
                                            }"
                                            v-on="on"
                                            color="error"
                                            :disabled="!form.vendor_id"
                                            icon
                                            fab
                                            small
                                            class="d-flex align-items-start pt-2 px-3"
                                        >
                                        <v-icon>mdi-archive-remove-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Clear Items</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" >
                                <v-card elevation="0">
                                    <v-card-text>
                                        <v-row>
                                            <v-col lg="12">
                                                <v-data-table
                                                    :items="form.item_list"
                                                    :headers="formattedHeaders(dataTable.headers)"
                                                    :item-class="itemRowBackground"
                                                    dense
                                                    disable-sort
                                                >
                                                <template v-slot:footer>
                                                    <v-row class="pa-4 justify-end">
                                                        <v-col>
                                                            <v-chip
                                                                color="red lighten-4"
                                                                class="ma-2"
                                                                >
                                                                Price Changed
                                                            </v-chip>
                                                            <v-chip
                                                                color="amber lighten-4"
                                                                class="ma-2"
                                                                >
                                                                Doesn't match Supplier MOQ
                                                            </v-chip>
                                                        </v-col>
                                                    </v-row>
                                                </template>
                                                    <template v-slot:[`header.button`]="{ header }">
                                                        <v-btn
                                                            color="success"
                                                            x-small
                                                            fab
                                                            :disabled="!form.vendor_id"
                                                            v-if="ACTION != 'View'"
                                                            @click="modifyItem(1)"
                                                        >
                                                            <v-icon>mdi-plus</v-icon>
                                                        </v-btn>
                                                        <v-btn
                                                            color="error"
                                                            x-small
                                                            fab
                                                            v-if="ACTION != 'View'"
                                                            :disabled="!form.vendor_id"
                                                            @click="modifyItem(2)"
                                                        >
                                                        <v-icon>mdi-minus</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <template v-slot:[`item.item_name`]="{ item,index }">
                                                        <div  class="d-flex">
                                                            <v-tooltip bottom
                                                            v-if="findDesignateVendor(item)"

                                                            >
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-icon class="pr-1"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        color="red"
                                                                        >mdi-alert
                                                                    </v-icon>
                                                                </template>
                                                                <span>This item does not have on vendor you select.</span>
                                                            </v-tooltip>
                                                        <v-autocomplete
                                                            v-model="item.admin_item_id"
                                                            :items="selection.admin_items"
                                                            :disabled="ACTION == 'View'"
                                                            item-value="admin_item_id"
                                                            item-text="item_name"
                                                            dense
                                                            outlined
                                                            hide-details
                                                            @change="appendItem(item,index)"
                                                        >
                                                        </v-autocomplete>
                                                        </div>
                                                        <!-- <span v-if="!!selection.admin_items.find(e=>e.admin_item_id == item.admin_item_id)">
                                                            {{item.item_name}}
                                                        </span> -->
                                                    </template>
                                                    <template v-slot:[`item.pending_po_count`]="{ item,index }">
                                                        <v-menu
                                                        open-on-hover
                                                        bottom
                                                        offset-y
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                            color="#8B9701"
                                                            dark
                                                            icon
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            <v-icon>mdi-alert</v-icon>
                                                        </v-btn>
                                                        </template>

                                                        <v-simple-table dark>
                                                            <template v-slot:default>
                                                            <thead>
                                                                <tr>
                                                                <th class="text-left">
                                                                    PO#
                                                                </th>
                                                                <th class="text-left">
                                                                    Pending
                                                                </th>
                                                                <th class="text-left">
                                                                    Date
                                                                </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr
                                                                v-for="(item,index) in item.pending_po"
                                                                :key="index"
                                                                >
                                                                <td>{{ item.po_num }}</td>
                                                                <td>{{ item.pending_qty }}</td>
                                                                <td>{{ item.created_at }}</td>
                                                                </tr>
                                                            </tbody>
                                                            </template>
                                                        </v-simple-table>
                                                    </v-menu>
                                                    </template>
                                                    <template v-slot:[`item.receive_qty`]="{ item,index }">
                                                        <v-text-field
                                                            v-model="item.receive_qty"
                                                            class="centered-input"
                                                            outlined
                                                            dense
                                                            type="number"
                                                            :max="item.quantity - item.received_qty"
                                                            :min="0"
                                                            @keypress="numberandDotOnly"
                                                            @change="()=>{
                                                                quantityReceive(item);
                                                                item.pending_qty = (item.quantity - item.receive_qty) - item.received_qty
                                                            }"
                                                            :disabled="ACTION == 'View'"
                                                        >
                                                        </v-text-field>
                                                    </template>
                                                    <template v-slot:[`item.received_qty`]="{ item,index }">
                                                        <v-text-field
                                                            v-model="item.received_qty"
                                                            v-if="ACTION !== 'View'"
                                                            class="centered-input"
                                                            background-color="#CFD8DC"
                                                            outlined
                                                            dense
                                                            type="number"
                                                            disabled
                                                        >
                                                        </v-text-field>
                                                        <span v-else>{{item.received_qty ? item.received_qty : 0}}</span>
                                                    </template>
                                                    <template v-slot:[`item.pending_qty`]="{ item,index }">
                                                        <v-text-field
                                                            v-model="item.pending_qty"
                                                            v-if="ACTION !== 'View'"
                                                            class="centered-input"
                                                            outlined
                                                            dense
                                                            disabled
                                                            background-color="#CFD8DC"
                                                        >
                                                        </v-text-field>
                                                        <span v-else>{{item.pending_qty ? item.pending_qty : 0}}</span>
                                                    </template>
                                                    <template v-slot:[`item.canceled_qty`]="{ item,index }">
                                                        <v-text-field
                                                            v-model="item.canceled_qty"
                                                            class="centered-input"
                                                            outlined
                                                            dense
                                                            disabled
                                                            background-color="#CFD8DC"
                                                            type="number"
                                                            @keypress="numberandDotOnly"
                                                        >
                                                        </v-text-field>
                                                    </template>
                                                    <template v-slot:[`item.foc`]="{ item,index }">
                                                    <v-checkbox v-model="item.foc"
                                                            :disabled="ACTION == 'View'"
                                                            hide-details
                                                            dense
                                                            :true-value="1"
                                                            :false-value="0"
                                                            @change="()=>{
                                                                changeAmountBasedOnFoc(item);
                                                            }"
                                                    >
                                                    </v-checkbox>
                                                    </template>
                                                    <template v-slot:[`item.uom_id`]="{ item }">
                                                        <v-autocomplete
                                                            v-model="item.uom_id"
                                                            :items="GET_ADMIN_UOM_SELECTION"
                                                            :disabled="ACTION == 'View'"
                                                            item-value="value"
                                                            item-text="text"
                                                            dense
                                                            outlined
                                                            hide-details
                                                        >
                                                        </v-autocomplete>
                                                    </template>
                                                    <template v-slot:[`item.priority`]="{ item,index }">
                                                        <v-checkbox
                                                            v-model="item.priority"
                                                            :value="item.receive_qty > 0 ? 0 : 1"
                                                            :disabled="ACTION == 'View'"
                                                            :true-value="0"
                                                            :false-value="1 || null"
                                                            hide-details
                                                            dense
                                                        >
                                                        </v-checkbox>
                                                    </template>
                                                    <template v-slot:[`item.quantity`]="{ item }">
                                                        <v-text-field
                                                            v-model="item.quantity"
                                                            class="centered-input"
                                                            outlined
                                                            dense
                                                            :disabled="ACTION == 'View'"
                                                            @keypress="numberandDotOnly"
                                                            @change="updatePrice(item)"
                                                        >
                                                        </v-text-field>
                                                    </template>
                                                    <template v-slot:[`item.total_original_price`]="{ item }">
                                                        <v-text-field
                                                            v-model="item.total_original_price"
                                                            v-if="form.status === 1 && ACTION !== 'View'"
                                                            outlined
                                                            dense
                                                            class="right-input"
                                                            :disabled="ACTION == 'View' || item.foc === 1 || item.service == 1"
                                                            @input="updateOriginalPrice(item); item.total_original_price == 0 ? item.foc = 1 : item.foc = 0"
                                                            @keypress="numberandDotOnly"
                                                        >
                                                        </v-text-field>
                                                        <span v-else>{{formatCurrencyPHPwithoutRoundingUp(item.total_original_price) | currency}}</span>
                                                    </template>
                                                    <template v-slot:[`item.total_amount`]="{ item }">
                                                        <v-text-field
                                                            v-model="item.total_amount"
                                                            v-if="ACTION == 'Submit'"
                                                            class="right-input"
                                                            readonly
                                                            background-color="#CFD8DC"
                                                            outlined
                                                            dense
                                                            :disabled="ACTION == 'View' || item.foc === 1"
                                                        >
                                                        </v-text-field>
                                                        <span v-else>{{formatCurrencyPHPwithoutRoundingUp(item.total_amount) | currency}}</span>
                                                    </template>
                                                    <template v-slot:[`item.item_amount_to_pay`]="{ item }">
                                                        <v-text-field
                                                            :value="changeAmountToPay(item)"
                                                            v-if="ACTION !== 'View'"
                                                            background-color="#CFD8DC"
                                                            outlined
                                                            dense
                                                            readonly
                                                            class="right-input"
                                                            :disabled="ACTION == 'View'"
                                                        >
                                                        </v-text-field>
                                                        <span>{{formatCurrencyPHPwithoutRoundingUp(item.item_amount_to_pay) | currency }}</span>
                                                    </template>
                                                    <template v-slot:[`item.item_paid_amount`]="{ item }">
                                                        <v-text-field
                                                            :value="thousandSeprator(item.item_paid_amount)"
                                                            v-if="ACTION !== 'View'"
                                                            background-color="#CFD8DC"
                                                            outlined
                                                            dense
                                                            readonly
                                                            class="right-input"
                                                            :disabled="ACTION == 'View' || item.foc === 1"
                                                        >
                                                        </v-text-field>
                                                        <span v-else>{{item.item_paid_amount | currency}}</span>
                                                    </template>
                                                    <template  v-slot:[`item.original_price`]="{ item }">
                                                        <v-text-field
                                                            :value=" item.original_price ? formatCurrencyPHPwithoutRoundingUp(item.original_price) : ''"
                                                            v-if="ACTION === 'Submit'"
                                                            outlined
                                                            dense
                                                            readonly
                                                            class='right-input'
                                                            background-color="#CFD8DC"
                                                            :disabled="ACTION == 'View' || item.foc === 1"
                                                        >
                                                        </v-text-field>
                                                        <span v-else>{{formatCurrencyPHPwithoutRoundingUp(item.original_price) | currency}}</span>

                                                    </template>
                                                    <template v-slot:[`item.ids_of_emp`]="{ item,index }">
                                                        <v-autocomplete
                                                            v-model="item.ids_of_emp"
                                                            @input="limitSelection(index, item)"
                                                            outlined
                                                            dense
                                                            label="Accountable"
                                                            :items="addGeneralAccountability"
                                                            multiple
                                                            :clearable="!ACTION == 'View'"
                                                            chips
                                                            deletable-chips
                                                            small-chips
                                                            :readonly="ACTION == 'View'"
                                                        >
                                                        </v-autocomplete>
                                                    </template>
                                                    <template v-slot:[`item.item_warehouse_id`]="{ item, index }">
                                                        <v-autocomplete
                                                            v-model="item.item_warehouse_id"
                                                            :items="warehouse_selections"
                                                            item-text="text"
                                                            item-value="value"
                                                            outlined
                                                            dense
                                                            label="Inventory"
                                                            disabled
                                                        >
                                                        </v-autocomplete>
                                                    </template>
                                                    <template v-slot:[`item.button`]="{ item, index }">
                                                        <v-btn
                                                            color="error"
                                                            small
                                                            icon
                                                            :disabled="ACTION == 'View'"
                                                            @click="modifyItem(3, item.admin_item_id)"
                                                            :class="[4,1].includes(form.status) ? 'mb-3' : ''"
                                                        >
                                                            <v-icon>
                                                                mdi-delete
                                                            </v-icon>
                                                        </v-btn>

                                                    </template>
                                                </v-data-table>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col lg="5" v-if="ACTION == 'Add'">
                                                <b>Item Not Found: </b> <span style="color:red">{{ errors.item_not_found.map(e=>e.item_name).join(", ") }}</span>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                    <v-card-actions class="justify-content-end border-top">
                                        <span class="font-weight-bold">Total : {{  ACTION == 'View' ? form.total_amount : total | currency }}</span>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col lg="12" v-if="errors.item_not_found.length > 0">
                        <table class="table-bordered" style="font-size: 16px">
                            <tr>
                                <th class="px-3" colspan="2">Item Not found</th>
                            </tr>
                            <tr>
                                <td class="px-3"><b>Item name</b></td>
                                <td class="px-3"><b>Quantity</b></td>
                            </tr>
                            <tr v-for="(e, i) in errors.item_not_found" :key="i">
                                <td class="px-4" style="color: red">{{ e.item_name }}</td>
                                <td class="px-4" style="color: red">{{ e.quantity }}</td>
                            </tr>
                        </table>
                    </v-col>
                </v-row>
                <v-card class="mt-3" v-if="ACTION =='Update' && userAccess.comments">
                    <v-card-text>
                    <v-container fluid class="pb-0">
                        <v-row pa-0>
                        <v-col cols="12">
                            <v-textarea outlined height="130" label="Comments" dense v-model="form.comments" :auto-grow="false" no-resize :disabled="disabled_view"></v-textarea>
                        </v-col>
                        </v-row>
                    </v-container>
                    </v-card-text>
                </v-card>

            </v-container>
        <v-container fluid class="pb-0" v-if="form.typeOf == 'PRF'">
          <v-row class="pa-2 pb-0">
          <v-col cols="12" lg="3">
            <!-- <v-row>
                <v-col cols="12">
                  <v-text-field v-model="form.po_num" label="PO Number" outlined dense readonly></v-text-field>
                </v-col>
            </v-row> -->
            <v-row>
              <v-col cols="12">
                  <v-autocomplete
                    v-model="form.vendor_id"
                    :items="GET_ADMIN_SUPPLIER_SELECTION"
                    item-text="text"
                    item-value="value"
                    auto-select-first
                    outlined
                    required
                    label="Supplier"
                    :disabled="disabled_view"
                    dense
                  ></v-autocomplete>
                </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                  <v-autocomplete
                    v-model="form.location_id"
                    :items="GET_ADMIN_DELIVERY_ADDRESSES"
                    :disabled="disabled_view"
                    label="Shipped To"
                    auto-select-first
                    outlined
                    required
                    dense
                    clearable
                  ></v-autocomplete>
                </v-col>
            </v-row>
            <v-row>
                  <v-col cols="12">
                    <v-textarea outlined height="260"  label="Remarks" v-model="form.remarks" :auto-grow="false" no-resize :disabled="disabled_view"></v-textarea>
                  </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" lg="9">
          <v-row class="pl-1">
            <v-col cols="12" >
          </v-col>
          </v-row>
        </v-col>
      </v-row>
        </v-container>
      </v-card-text>
      <v-card-text v-else>
        <v-row>
            <v-col lg="12">
                <v-card elevation="0">
                    <v-card-text class="d-flex justify-content-center align-items-center">
                        <v-progress-linear
                            indeterminate
                            color="primary"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
      </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="text-center">
          <v-row class="ma-1">
            <v-col cols="12" >
              <v-row >
                <!-- <v-col cols="12" :sm="ACTION != 'View'?6:4"   v-if="PURCHASE_ORDER.STATUS != 3">
              <v-btn
              v-if="ACTION == 'View' && PURCHASE_ORDER.STATUS != 2"
              color="error"
                class="float-center"
                @click="reject()"
              ><span>REJECT</span>
              </v-btn>
            </v-col> -->
            <v-col lg="1">
                <span >
                    <v-btn text color="orange" small :loading="loading"  v-if="ACTION == 'View' && PO_RECEIVING_DIALOG !== true" @click="nextItem(1)">
                        <v-icon >
                            mdi-arrow-left
                        </v-icon>
                    </v-btn>
                </span>
            </v-col>
                <v-col cols="3" v-if="['Submit','Update'].includes(ACTION)" :sm="ACTION != 'View'?10:6">
                    <v-btn
                        color="orange"
                        :loading="loading"
                        class="float-center"
                        @click="createOrUpdatePurchaseOrder()"
                    >
                            <span>SAVE</span>
                    </v-btn>
                </v-col>
                <v-col v-if="ACTION == 'View' && PO_RECEIVING_DIALOG !== true">
                    <v-btn
                            small
                            class="float-center"
                            color="orange"
                            :loading="loading"
                            v-if="![1, 4, 6, 7, 9, 10].includes(form.status) && userAccess.includes('approve')"
                            @click="confirmOveride()"
                        >
                            <span v-if="![1, 4, 6, 9].includes(form.status)">{{`${STATUS.ADMIN_PURCHASE_ORDER_STATUS.find(e => e.value === form.status)?.text} Approve`}}</span>
                    </v-btn>
                    <v-btn
                            small
                            class="float-center"
                            color="orange"
                            :loading="loading"
                            v-if="[1,9].includes(form.status)"
                            @click="confirmOveride()"
                        >
                            <span v-if="[1].includes(form.status)">{{`Confirm`}}</span>
                            <span v-if="[9].includes(form.status)">{{`Approve`}}</span>
                    </v-btn>

                    <v-btn
                            small
                            color="orange"
                            class="float-center"
                            :loading="print_loading"
                            @click="renderPDF()"
                        ><span>Print</span>
                    </v-btn>
                    <!-- <v-btn
                            v-if="[4].includes(form.status)"
                            :loading="loading"
                            small
                            color="red"
                            class="float-center"
                            @click="updateStatus(form.status, 1)"
                        ><span>Cancel</span>
                    </v-btn> -->
                </v-col>
                <v-col lg="1">
                    <v-btn text color="orange" small :loading="loading" @keydown="handleKeydown" v-if="ACTION == 'View' && PO_RECEIVING_DIALOG !== true" @click="nextItem(2)">
                        <v-icon>
                            mdi-arrow-right
                        </v-icon>
                    </v-btn>
                </v-col>
          </v-row>
            </v-col>
          </v-row>
        </v-card-actions>
        <PurchaseOrderTemplateAlignToGsheet></PurchaseOrderTemplateAlignToGsheet>
            <v-dialog dark v-model="action_history.dialog" width="60%" scrollable>
                <v-card flat rounded="">
                    <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                    <span>Cancel, Reset and Revert Logs</span>
                        <v-row class="ma-0">
                            <v-col cols="pull-right-10 pa-2">
                            <v-btn
                                text
                                icon
                                small
                                color="gray"
                                class="float-right"
                                @click="() => {
                                    action_history.dialog = !action_history.dialog
                                }"
                            >
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text class="pt-4">
                        <v-data-table
                        :headers="action_history.headers"
                        :items="action_history.data"
                        :loading="action_history.loading"
                        disable-sort
                        hide-default-footer
                        dark
                        >
                        <template v-slot:[`item.action_type`]="{item}">
                            {{STATUS.PO_ACTION_TYPE.find(e => e.value == item.action_type)?.text}}
                        </template>
                        <template v-slot:[`item.action_date`]="{item}">
                            {{item.action_date}}
                        </template>
                        </v-data-table>
                    </v-card-text>
                    <!-- <v-divider></v-divider>
                    <v-card-actions class="text-center pa-0">
                        <v-row class="ma-1">
                            <v-col cols="12" >

                            </v-col>
                        </v-row>
                    </v-card-actions> -->
                </v-card>
            </v-dialog>
            <BatchAdditionCopyPasteDialogComponent :dialog="batchAddition.dialog" @closeDialog="retrieveData" :headers="['Item Name','Quantity']"></BatchAdditionCopyPasteDialogComponent>
            <ConfirmOverrideComponent
                :approve_override_dialog="override.override_dialog"
                :departments_allowed="override.override_departments_allowed"
                :heading="''"
                @closeConfirmOverride="closeConfirmOverride"
            />
        </v-card>
    </v-form>
</template>
<script>
    import { mapGetters } from "vuex";
    import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
    import PurchaseOrderTemplateAlignToGsheet from '@/views/main/layouts/PrintTemplate/PurchaseOrderTemplateAlignToGsheet.vue'
    import EditCurrentItemsViewComponent from '@/views/main/layouts/dialogs/Admin/EditCurrentItemsViewComponent.vue';
    import BatchAdditionCopyPasteDialogComponent from "../Operations/General/batchAdditionCopyPastComponent.vue";
    import Swal from 'sweetalert2'
    import PurchaseOrderTableComponent from "../../../modules/Admin/PurchaseOrder/PurchaseOrderTableComponent.vue";
    import FileUploadComponentVue from '@/views/main/Shared/FileUploadComponent.vue'
    import BaseFileViewerComponent from "@/views/main/Shared/BaseFilesViewerComponent.vue"
    import BaseCameraCaptureVue from "@/views/main/modules/HR/Base/BaseCameraCapture.vue"
    import ConfirmOverrideComponent from "@/views/main/Utils/ConfirmOverrideComponent.vue";
    import Decimal from 'decimal.js';
    import SectionHeading from '@/views/main/Components/SectionHeading.vue';

    export default {
        mixins: [
            SharedFunctionsComponentVue,
            PurchaseOrderTableComponent
        ],
        inject: ['theme'],
        data() {
            return {
                file_index: '',
                dataTable:{
                    headers:[
                        { text:'Item Name',value:'item_name',width:'13%' },
                        { text:'UOM', align:'center', value:'uom_id',width:'7%' },
                        { text:'Quantity', align:'center', value:'quantity',width:'7%' },
                        { text:'Receive Qty', align:'center', value:'receive_qty',width:'7%' },
                        { text:'Received Qty', align:'center', value:'received_qty',width:'7%' },
                        { text:'Pending Qty', align:'center', value:'pending_qty',width:'7%' },
                        { text:'Canceled Qty', align:'center', value:'canceled_qty',width:'7%' },
                        { text:'SRP',align:'end', value:'original_price',width:'10%' },
                        { text:'Final Price',align:'end', value:'total_original_price',width:'10%' },
                        { text:'Total Amount', align:'end', value:'total_amount',width:'10%' },
                        { text:'Amount to pay', align:'end', value:'item_amount_to_pay',width:'10%' },
                        { text:'Paid Amount', align:'end', value:'item_paid_amount',width:'10%' },
                        { text:'FOC',value:'foc',width:'2%' },
                        { text:'Priority',value:'priority',width:'2%' },
                        // { text:'Remarks',value:'remarks',width:'20%' },
                        { text: 'Accountability', value: 'ids_of_emp', width: '15%'},
                        { text:'Key Datas',value:'key_datas',width:'7%' },
                        { text:'Pending PO/s',value:'pending_po_count',width:'10%' },
                        { text: 'Inventory', value: 'item_warehouse_id', width: '15%'},
                        { text:'Action',align:'center',value:'button',width:'15%' },
                    ]
                },
                form: {
                    index:0,
                    pending_qty: 0,
                    canceled_qty: 0,
                    // receive_qty: 0,
                    payment_method:0,
                    typeOf:'Supplier',
                    location: "",
                    supplier: "",
                    item_list:[
                        { value:'', total_amount:'', receive_qty: '', received_qty: '',  uom_id:'', moq: null, vendor:'', ids_of_emp: []}
                    ],
                    selected:false,
                    select_all:false,
                    po_id_tag:'',
                    po_id:'',
                    po_num:'',
                    remarks:'',
                    status:1,
                    supplier_items:[],
                    total_amount:0,
                    comments:'',
                    file:[],
                    fileViewer:[],
                    vendor_address_id: '',
                    vendor_payment_item_id: '',
                    shipping_via_id: '',
                    expected_date: this.$dayjs().startOf('day').format('YYYY-MM-DD'),
                    vendor_id: '',
                    location_id: '',
                    amount_to_pay:'',
                    company_id: null,
                    store_branch_id: null,
                    cash: true,
                    type: 0,
                    vat_percentage: 0,
                    admin_warehouse_id: '',
                    received_date: '',
                    is_overdue: 0,
                    rush: 0,
                    number_of_days: 7,
                    po_num:'',
                    payment_type_id:'',
                    contact_person: '',
                    contact_number: '',
                    template_id: '',
                    customer_request: 0,
                    customer_id: null,

                    billable: 0,
                    po_requestor: null,
                },
                selection:{
                    admin_items:[],
                    payment_method:[
                        { text:'Payment First', value:1},
                        { text:'Receive first', value: 2},
                        { text:'Partial payment', value: 3},
                    ],
                    po_item_template: []
                },
                index:0,
                default:{},
                loading:false,
                print_loading: false,
                disabled_view: false,
                URL:process.env.VUE_APP_API_HOST,
                userAccess:[],
                noImage:"images/noimage.png",
                error_password:'',
                batchAddition:{
                    dialog:false,
                    data:[]
                },
                errors:{
                    item_not_found:[]
                },
                received: [],
                uploadedFiles: null,
                compiledFiles: [],
                action_history: {
                    dialog: false,
                    headers:[
                        { text:'ACTION',value:'action_type', width:'12%' },
                        { text:'REASON',value:'reason', width:'40%' },
                        { text:'DONE BY',value:'user_id', width:'8%' },
                        { text:'ACTION DATE',value:'action_date', width:'8%' },
                    ],
                    data: [],
                },
                warehouse_selections: [],
                admin_warehouse_dropdown: [],
                max_date: this.$dayjs().format('YYYY-MM-DD'),
                images: null,
                trash_item_ids: [],
                old_ids: [],
                uploadedImages: null,
                keys:{
                    action:0,
                    page:1
                },
                total: 0,
                clonedStores: [],
                clonedLocations: [],
                viewUploadFiles: {
                    attachments: []
                },
                override: {
                    override_dialog: false,
                    errors: [],
                    override_departments_allowed: ['admin'],
                    override_authenticated: false,
                },
                apply_loading: false,
                conflict_item:false
            };
        },
        components: {
            PurchaseOrderTemplateAlignToGsheet,
            EditCurrentItemsViewComponent,
            BatchAdditionCopyPasteDialogComponent,
            FileUploadComponentVue,
            BaseFileViewerComponent,
            BaseCameraCaptureVue,
            ConfirmOverrideComponent,
            SectionHeading,
        },
        computed: {
            ...mapGetters([
                "ACTION",
                'GET_ADMIN_SUPPLIER_SELECTION',
                'PURCHASE_ORDER',
                "DIALOGS",
                'GET_TERM_SELECTION',
                'GET_CONTACT_PERSON_SELECTION',
                'SELECTED',
                'STATUS',
                'USERACCOUNT_LOAD',
                'GET_COMPANY_SELECTION',
                'GET_PV_STORE_BRANCHES_SELECTION',
                'GET_EMPLOYEE_DATA',
                'GET_SHIPPING_VIA',
                'GET_ADM_WAREHOUSE_CODE_DROPDOWN',
                'DATA_TABLE',
                'PO_RECEIVING_DIALOG',
                'GET_PV_COMPANIES_SELECTION',
                'GET_PAYMENT_TYPES',
                'PO_ITEMS_TEMPLATE',
                'GET_CUSTOMERS_LIST_SELECTION',
                'DIALOGS',
                'GET_ADMIN_UOM_SELECTION',
                'GET_ADMIN_DELIVERY_ADDRESSES',
            ]),
            imageFluid(){
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': return "card-img-top img-fluid"
                    case 'sm': return "card-img-top img-fluid"
                    case 'md': return "card-img-top"
                    case 'lg': return "card-img-top"
                    case 'xl': return "card-img-top"
                }
            },
            dialogwidth(){
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': return "100%"
                    case 'sm': return "100%"
                    case 'md': return "50%"
                    case 'lg': return "20%"
                    case 'xl': return "20%"
                }
            },
            edititemdialogwidth(){
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': return "100%"
                    case 'sm': return "100%"
                    case 'md': return "50%"
                    case 'lg': return "40%"
                    case 'xl': return "40%"
                }
            },
            // totalAmount() {
            //     return this.form.item_list.reduce((total, item) => total + parseFloat(item.total_amount), 0)
            // },
            branchItems(){
                let items = this.clonedStores.filter(e=>e.company_id == this.form.company_id)
                if (!this.form.company_id) return this.clonedStores
                if (!this.form.store_branch_id) this.form.store_branch_id = items[0]?.value
                return items
            },
            // locationItems(){
            //     let items = this.clonedLocations.filter(e=>e.company_id == this.form.company_id)
            //     if (!this.form.company_id) return this.clonedLocations
            //     if (!this.form.location_id) this.form.location_id = items[0].value
            //     return items
            // },
            addGeneralAccountability(){
                let employee_data = [];
                employee_data = this.GET_EMPLOYEE_DATA;
                employee_data.unshift({value: 0, text: 'GENERAL ACCOUNTABILITY'});
                return employee_data;
            }
        },
        beforeDestroy() {
            document.removeEventListener('keydown', this.handleKeydown);
        },
        mounted() {
            document.addEventListener('keydown', this.handleKeydown);
            this.default = this.form
            this.$store.dispatch("getPvCompanies");
            this.$store.dispatch("getShippingVia");
            this.$store.dispatch('adminPurchaseOrderGet',{url:"getPoItemReceived"})
                .then(response=>{this.received = response});
            this.$store.dispatch('getEmployeeDataDropdown');
            this.$store.dispatch('getAdminWarehouseCodeDropdown');
            this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id).then(response => {
                this.userAccess = response.map(e=>e.actions_code);
            })
            this.$store.dispatch('getPaymentTypes');
            this.getBranches();
            this.$store.dispatch('getAllCustomers');
        },
        methods: {
            limitSelection(index, value) {
                if (value.ids_of_emp.length > value.quantity) {
                    this.form.item_list[index].ids_of_emp.pop();
                }
            },
            renderPDF(){
                // this.$store.commit('PRINT_PURCHASE_ORDER_DETAIL',{});
                // this.$store.commit('PRINT_PURCHASE_ORDER_DETAIL',this.PURCHASE_ORDER.STATUS);
                this.$store.commit('PRINT_PURCHASE_ORDER', true);
            },
            async createOrUpdatePurchaseOrder(){
                let trash = this.form.item_list.map(e => e.id)
                this.trash_item_ids = this.old_ids.filter(e => !trash.includes(e))
                Swal.fire({
                    title:'Warning',
                    text: this.ACTION === 'View' ? 'Are you sure you want to Update?': 'Are you sure you want to '+this.ACTION+'?',
                    showCancelButton:true,
                    reverseButtons:true,
                    icon:'warning'
                }).then(async action=>{
                    if(action.isConfirmed){
                        let checker = [
                            [this.form.item_list.length == 0,'- Please Enter items'],
                            [this.form.item_list.some(e=>!e.quantity || e.quantity == 0),'Please Check the Item/s'],
                            [!this.form.shipping_via_id,'- Please Select Shipping Via'],
                            [!this.form.location_id,'- Please Select Shipped to'],
                            [!this.form.expected_date,'- Please Enter Expected Date'],
                            [!this.form.number_of_days || this.form.number_of_days == 0,'- Please Enter Valid Number of Days'],
                            [!this.form.payment_method,'- Please Select Payment Method'],
                            [!this.form.company_id,'- Please Select Company'],
                            [this.ACTION == 'Submit' && this.form.payment_method == 3 && !this.form.amount_to_pay,'Please Enter Amount to Pay'],
                            // [this.form.item_list.some(e => e.moq !== null) && this.form.item_list.some(e => e.quantity < e.moq), 'Some quantity doesn`t meet the supplier`s MOQ'],
                        ]

                        if((!this.uploadedFiles || this.uploadedFiles.length <= 0)  && !this.images && this.form.rush == 1){
                            checker = checker.concat([[this.ACTION == 'Submit',' Please Upload or Capture File']]);
                        }

                        if(checker.find(e=>e[0] == true)){
                            Swal.fire('Error!',`- ${checker.find(e=>e[0] == true)[1]}`,'error')
                            return
                        }
                        let checkForItemWithNoPrice = {
                            isConfirmed:false
                        }

                        if(this.form.item_list.some(e=>e.total_amount == 0)){
                            checkForItemWithNoPrice = await Swal.fire({
                                    title:'Warning!',
                                    text:'- System detected items with 0 price, do you want to mark it as FOC ?',
                                    showCancelButton:true,
                                    icon:'warning',
                                    reverseButtons:true
                                })
                        }

                        if(this.form.item_list.some(e=>e.total_amount == 0)){
                            if(checkForItemWithNoPrice.isConfirmed){
                                this.form.item_list = this.form.item_list.filter(e=>{
                                    if(e.total_amount == 0){
                                        e.foc = 1
                                    }

                                    return e
                                })
                            }else{
                                Swal.fire({
                                    title:'Info!',
                                    text:'- Please include price for all the items',
                                    icon:'info'
                                })
                                return
                            }
                        }




                        this.form.amount_to_pay = this.form.payment_method == 3 ? this.recomputeAmountToPay(this.form.amount_to_pay, this.total) : this.total;
                        this.$store.dispatch('adminPurchaseOrderPost',{
                            url:!this.form.id ? 'purchaseorder' : 'purchaseorder/edit',
                            ...this.form,
                            item_list: this.form.item_list.map(e=> {
                                e.pending_po = ''
                                return e;
                            }),
                            total_amount:this.total,
                            file: this.uploadedFiles,
                            image: this.uploadedImages,
                            trash_item_ids:  this.trash_item_ids,
                            is_overdue: this.form.expected_date >= this.$dayjs().format('YYYY-MM-DD') ? 0 : 1
                        }).then(response=>{
                            Swal.fire(response.title, response.text, response.icon)
                            if(response.icon == 'success'){
                                this.$store.commit("DIALOG", false);
                                this.resetFields();
                                this.$store.dispatch('ACTION','DONE')
                            }
                        }).catch(e=>{
                            console.log(e)
                            Swal.fire('Error','','error')
                        });
                    }
                })

                const supplier_swal_text = this.form.vendor_id ? "" : "Supplier";
                const location_swal_text = this.form.location_id ? "" : "Location";
                const array_text = [supplier_swal_text,location_swal_text];
                const filtered = array_text.filter((el)=> {
                        return el;
                    });

                let swal_obj = {
                    title:'',
                    text:'',
                    allowOutsideClick:false,
                }
                if(this.form.selected.length== 0 && this.ACTION == "Submit"){
                    swal_obj.text = "No item selected"
                    this.$store.dispatch('SwalAlertWarning',swal_obj)
                }else if(this.form.item_list.length== 0){
                    swal_obj.title = "items table is empty"
                    this.$store.dispatch('SwalAlertWarning',swal_obj)
                }else{
                    if (this.form.vendor_id == '' || this.form.location_id == ''){
                        swal_obj.text = `Fill Required Fields (${filtered})`
                        this.$store.dispatch('SwalAlertWarning',swal_obj)
                    }else{
                        let formData = new FormData()
                        for(let i = 0; i < this.form.file.length; i++){
                            formData.append('file[]',this.form.file[i]);
                        }
                        const config = {
                                headers: {
                                'content-type': 'multipart/form-data,application/json'
                            }
                        }
                        formData.append('params',JSON.stringify(this.form))
                        let payload = {
                            params:formData,
                            config:config,
                            }
                        if([2,3].includes(this.form.status) || this.ACTION == 'Update' ){
                            let swal_obj = {
                                title:'',
                                text:`Are you sure you want to ${this.ACTION == 'Update'?'Update':'Approve'}?`,
                                icon:'question',
                                confirmButtonText:this.ACTION == 'Update'?'Update':'Approved',
                                cancelButtonText:'Cancel',
                                allowOutsideClick:false,
                            }
                            this.$store.dispatch('SwalAlertQuestion',swal_obj).then(e=>{
                                if(e.isConfirmed){
                                    this.$store.dispatch(this.ACTION,payload);
                                }
                            })
                        }else{
                            // this.$store.dispatch('adminPurchaseOrderPost',payload);
                        }
                    }
                }
            },
            closeDialog() {
                //   if(this.ACTION == "Update" || this.ACTION == "View"){
                this.resetFields();
                //   }
                this.$refs.closeDialog.$emit('clear-files');
                this.$store.commit("DIALOG", false);
                this.$store.commit("PO_RECEIVING_DIALOG", false);
                this.$store.commit("ACTION", '');
                this.$store.commit('COMPANY_IMAGE', null);
            },
            resetFields(){
                this.form =  {
                    receive_qty:0,
                    payment_method:0,
                        typeOf:'Supplier',
                        location: "",
                        supplier: "",
                        item_list:[
                            { id:0,value:'', total_amount:'',uom_id:'', moq: null, vendor:'', item_vendor_price_list_id: ''}
                        ],
                        selected:false,
                        select_all:false,
                        po_id_tag:'',
                        po_id:'',
                        po_num:'',
                        remarks:'',
                        status:1,
                        supplier_items:[],
                        total_amount:0,
                        comments:'',
                        file:[],
                        fileViewer:[],
                        vendor_address_id: '',
                        vendor_payment_item_id: '',
                        shipping_via_id: '',
                        expected_date: this.$dayjs().startOf('day').format('YYYY-MM-DD'),
                        vendor_id: '',
                        location_id: '',
                        amount_to_pay:'',
                        company_id: null,
                        store_branch_id: null,
                        type: 0,
                        received_date: '',
                        number_of_days: 7,
                        po_num:'',
                        payment_type_id:'',
                        contact_person: '',
                        contact_number: '',
                        template_id: '',
                        po_requestor: null,
                        billable: 0,
                }
                this.action_history = {
                    dialog: false,
                    data: [],
                    loading: false,
                }
                this.errors.item_not_found = []
                this.trash_item_ids = [];
                this.old_ids = [];
                this.uploadedFiles = null;
                this.selection.admin_items = []
                this.$store.commit("DIALOG", false);
                this.$store.commit('SELECTED',null);
                this.$store.commit('RESET',true);
                this.getAllPurchaseOrder();
                this.resetItemSelection();
                this.uploadedImages = null;
                this.images = null;
                this.total = 0;
                this.compiledFiles = [];
                this.viewUploadFiles = {
                    attachments: []
                },
                this.apply_loading = false;
            },
            async retrieveData(data){
                if(data.length >  0){
                    data = data.map(e=>{
                        let obj = {
                            item_name:e[0],
                            quantity:e[1],
                        }
                        e = obj
                        return e
                    })

                    await this.$store.dispatch('adminPurchaseOrderPost',{
                        vendor_id:this.form.vendor_id,
                        url:'check-item-pricelist',
                        admin_items:data.map(e=>e.item_name),
                    }).then(response=>{
                        this.errors.item_not_found = []
                        data = data.filter(e=>{
                            let item = response.find(el=>el.item_name.toUpperCase() == e.item_name.toUpperCase())
                            if(!!item){

                                item.quantity = e.quantity
                                item.total_amount = item.total_amount * item.quantity

                                if(!this.selection.admin_items.find(e=>e.admin_item_id == item.admin_item_id)){
                                    this.selection.admin_items.push(item)
                                }

                                return item
                            }else{
                                this.errors.item_not_found.push(e)
                            }
                        })

                        this.form.item_list = data.map(e=>{
                            return response.find(el=>el.item_name.toUpperCase() == e.item_name.toUpperCase())
                        })
                        this.$forceUpdate()

                        if(this.errors.item_not_found.length > 0){
                            Swal.fire('Some item/s are not available on the selection','- Please check the items in items module','warning');
                        }
                    })
                }
                this.batchAddition.dialog = false
            },
            reject(){
                Swal.fire({
                title: 'Are you sure you want to reject? why?',
                input: 'textarea',
                icon: 'question',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCancelButton: true,
                confirmButtonText: 'Reject',
                confirmButtonColor:'#ff5252',
                preConfirm: (login) => {
                    this.form.status = 3;
                    this.form.rejected_remarks = login
                },
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.createOrUpdatePurchaseOrder();
                    }
                })
            },
            modifyItem(action, id){
                this.getAllPurchaseOrder()
                if([2,3].includes(action)){
                    if(action == 2){
                        this.form.item_list.pop()
                    }
                    else{
                        const index = this.form.item_list.findIndex(item => item.admin_item_id === id);
                        if (index !== -1) {
                            this.form.item_list.splice(index, 1);
                        }
                    }
                    this.total = 0;
                    this.sumOfItems();
                }else if(action == 4){
                    Swal.fire({
                        icon: "warning",
                        text: "Are you sure you want to clear items?",
                        cancelButtonText: "Cancel",
                        reverseButtons: true,
                        showCancelButton: true,
                        allowOutsideClick: false,
                        cancelButtonColor: "#grey",
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            this.form.item_list = [{ id:0, value:'', total_amount:'',uom_id:'',vendor:'', item_vendor_price_list_id: '',}]
                        }
                    });
                } else{
                    this.addItems()
                }
                this.$forceUpdate()
            },
            async appendItem(item,index){
                await this.$store.dispatch('getPRFAdminItems',{
                    vendor_id:this.form.vendor_id,
                    admin_item_id:item.admin_item_id
                }).then(response=>{
                    this.form.item_list[index] = response[0]
                    this.sumOfItems();
                    this.$forceUpdate()
                })
            },

            updatePrice(item){
                let quantity = Decimal(item.quantity)
                let price = Decimal(item.total_original_price)

                if(item.moq !== null && (item.quantity % item.moq !== 0)){
                    Swal.fire(`Quantity doesn't meet supplier MOQ`, `Quantity should divisible by ${item.moq}`, 'warning')
                }
                item.total_amount = this.formatCurrencyPHPwithoutRoundingUp(quantity.times(price))
                this.sumOfItems();
            },
            updateOriginalPrice(item){
                let quantity = Decimal(item.quantity)
                let price = Decimal(item.total_original_price)

                item.total_amount = this.formatCurrencyPHPwithoutRoundingUp(quantity.times(price))
                this.sumOfItems();
            },
            async updateStatus(status, cancel){

                let received = 0
                    if(this.form.item_list.every(e => e.quantity == (parseInt(e.receive_qty) + parseInt(e.received_qty))) || this.form.item_list.every(e => e.quantity == e.receive_qty)){
                        received = 1
                    }

                    if(status === 4 && cancel == 0){
                        let checker = [
                            [!this.form.dr_number,' Please enter DR#'],
                            [!this.form.received_date,' Please Enter Received Date'],
                            [!this.viewUploadFiles.attachments.some(e => e.upload_type == 2) && !this.images,' Please Upload or Capture File'],
                            // [!this.form.warehouse_id,' Please Select Warehouse']
                        ]

                        if(checker.find(e=>e[0] == true)){
                            Swal.fire('Error!',`- ${checker.find(e=>e[0] == true)[1]}`,'error')
                            return
                        }
                    }

                    if(this.form.item_list.every(e => e.paid == 1) && this.form.item_list.every(e => e.received_qty == null) && cancel == 1){
                         Swal.fire({
                            title:'Cannot Cancel',
                            text:'Due to some items are already paid',
                            icon:'warning',
                        })
                    }else if(this.form.payment_method === 1 && this.form.status === 4 && this.form.item_list.every(e => e.receive_qty == 0) && cancel == 0){
                        Swal.fire({
                            title:'Insuficient quantity to receive',
                            text:'',
                            icon:'warning',
                        })
                    }else{
                        Swal.fire({
                            title: cancel === 1 ? 'Warning' : 'Question',
                            text: cancel === 1 && this.form.item_list.some(e => e.item_paid_amount > 0) ? '- Are you sure you want to cancel?'
                                : `- Are you sure you want to update status?`,
                            icon: cancel === 1 ? 'warning' : 'question',
                            showCancelButton:true,
                            reverseButtons:true
                        }).then(action=>{
                            if(action.isConfirmed){
                                if([4].includes(this.form.status) && this.form.item_list.every(e => e.receive_qty == 0) && cancel === 0){
                                    Swal.fire('Warning!', '- You cannot procceed without receive qty!', 'warning')
                                }else{
                                    this.$store.dispatch('adminPurchaseOrderPost',{
                                        url:'update-status',
                                        id: this.form.id,
                                        // item_list: this.form.item_list,
                                        item_list: this.form.item_list.map(e=> {
                                            e.pending_po = ''
                                            return e;
                                        }),
                                        cancel: cancel,
                                        status:parseInt(status),
                                        received: received,
                                        received_date: this.form.received_date,
                                        dr_number: this.form.dr_number,
                                        file: this.uploadedFiles,
                                        image: this.uploadedImages
                                    }).then(response=>{
                                        if(response.blocked ==1){
                                            Swal.fire('Cannot Approve','Amount is out of range','warning')
                                        }else{
                                            Swal.fire('Success!','','success')
                                            if(this.SELECTED.autonext === 0){
                                                this.nextItem(2);
                                            }else{
                                                this.closeDialog();
                                            }
                                        }

                                    }).catch(e=>{
                                        if (e.response.status === 406)
                                            return Swal.fire({
                                                title: 'Error',
                                                text: 'You are not authorized to confirm this purchase order.',
                                                icon: 'error',
                                            });

                                        console.log(e)
                                        Swal.fire('Error','','error')
                                    })
                                }
                            }
                        })
                    }
            },
            checkQuantityReceived(){
                let checker = true
                this.form.item_list.forEach(e=>{
                    if(parseInt(e.quantity) != parseInt(e.receive_qty)){
                        checker = false
                    }
                })
                return checker
            },
            resetItemSelection(){
                this.selection.admin_items.forEach(e=>{
                    e.itemDisabled = false
                })
            },
            formattedHeaders(headers){
                if([7].includes(this.form.status)){
                    headers = this.dataTable.headers.filter(header => !['item_amount_to_pay', 'pending_qty', 'priority', 'receive_qty', 'item_warehouse_id'].includes(header.value))
                    return headers
                }else if([10].includes(this.form.status)){
                    headers = this.dataTable.headers.filter(header => !['pending_qty', 'received_qty', 'priority', 'item_amount_to_pay', 'item_warehouse_id'].includes(header.value))
                    return headers
                }else if([4].includes(this.form.status)){
                    headers = this.dataTable.headers.filter(header => !['canceled_qty', 'item_amount_to_pay', 'original_price', 'total_original_price', 'total_amount', 'item_paid_amount','ids_of_emp'].includes(header.value))
                    return headers
                }else if([6].includes(this.form.status)){
                    headers = this.dataTable.headers.filter(header => !['canceled_qty', 'pending_qty', 'receive_qty', 'item_warehouse_id'].includes(header.value))
                    return headers
                }else if([9].includes(this.form.status)){
                    headers = this.dataTable.headers.filter(header => !['canceled_qty', 'pending_qty',  'receive_qty', 'quantity', 'received_qty', 'item_warehouse_id', 'original_price', 'total_original_price', ].includes(header.value))
                    return headers
                }else{
                    headers = this.dataTable.headers.filter(header => !['receive_qty', 'pending_qty', 'received_qty', 'item_paid_amount', 'canceled_qty', 'item_amount_to_pay', 'item_warehouse_id'].includes(header.value))
                    return headers
                }
            },
            recomputeAmountToPay(amount, total){
                if(this.form.type == 0){
                    return amount;
                }else{
                    let percentage = amount / 100;
                    let newAmount = percentage * total
                    return newAmount;
                }
            },
            percentageComputation(amount){
                let total = this.form.item_list.length > 0 ? this.form.item_list.map(e => this.formatCurrencyPHPwithoutRoundingUp(e.total_amount)).reduce((accumulator, currentValue) => accumulator + currentValue, 0) : 0
                let percentage = (amount / total) * 100
                if(amount && this.form.type == 0){
                    return Math.round(percentage);
                }
                else if(amount && this.form.type == 1){
                    let cash = ( amount * total) / 100
                    return this.thousandSeparator(cash);
                }else{
                    return 0
                }
            },
            changeAmountToPay(item){
                if(item.item_amount_to_pay){
                    return this.thousandSeprator(item.item_amount_to_pay)
                }else{
                    if(this.form.payment_method === 3){
                       return this.thousandSeprator(0)
                    }else if(item.foc === 0){
                        return this.thousandSeprator(item.receive_qty * item.original_price)
                    }else{
                        return this.thousandSeprator(0)
                    }
                }

            },
            thousandSeparator(amount) {
                if (amount !== '' && amount !== undefined && amount !== null && !isNaN(amount)) {
                    let formattedAmount = this.formatCurrencyPHPwithoutRoundingUp(amount);
                    formattedAmount = formattedAmount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                    return formattedAmount;
                } else {
                    return amount;
                }
            },
            formatAmount(amount){
                let raw_amount = amount;
                return this.thousandSeparator(raw_amount)

            },
             cashComputationFromPercentage(amount_to_pay, type){
                let total = this.form.item_list.length > 0 ? this.form.item_list.map(e => this.formatCurrencyPHPwithoutRoundingUp(e.total_amount)).reduce((accumulator, currentValue) => accumulator + currentValue, 0) : 0
                if(amount_to_pay && type == 1){
                    let percentage = (amount_to_pay / total) * 100
                    let cash = percentage * total / 100
                    return this.thousandSeparator(cash);
                }else if(amount_to_pay){
                    let cash =  amount_to_pay * total / 100
                    return this.thousandSeparator(cash);
                }else{
                    return 0
                }
            },
            cashOrPercentage(){
                let total = this.form.item_list.length > 0 ? this.form.item_list.map(e => this.formatCurrencyPHPwithoutRoundingUp(e.total_amount)).reduce((accumulator, currentValue) => accumulator + currentValue, 0) : 0
                if(this.form.type == 1){
                    this.form.amount_to_pay = this.form.amount_to_pay ? Math.round((this.form.amount_to_pay  / total) * 100) : 0
                }else{
                    this.form.amount_to_pay = this.form.amount_to_pay ? ((this.form.amount_to_pay / 100  ) * total).toFixed(2) : 0
                }
                this.$forceUpdate();
            },
            quantityReceive(item){
                // if(this.form.payment_method === 1){
                //     item.receive_qty = item.quantity
                // }
                if(item.receive_qty > item.quantity){
                    item.receive_qty = item.quantity - item.received_qty
                }else if(item.received_qty > 0){
                    if(parseFloat(item.receive_qty) > (parseFloat(item.quantity) - parseFloat(item.received_qty))){
                        item.receive_qty = item.quantity - item.received_qty
                    }else{
                        item.receive_qty = item.receive_qty
                    }
                }
            },
            uploadedData(data) {
                if (!data) {
                    this.uploadedFiles = null;
                } else {
                    if(this.viewUploadFiles.attachments.length > 0){

                        data.attachments.forEach(newAttachment => {
                            let duplicateFound = false;
                            // Loop through the existing attachments
                            for (let i = 0; i < this.viewUploadFiles.attachments.length; i++) {
                                if (this.viewUploadFiles.attachments[i].name === newAttachment.name) {
                                    this.viewUploadFiles.attachments[i] = newAttachment;
                                    duplicateFound = true;
                                    Swal.fire('Duplicated Files','','warning');
                                    break;
                                }
                            }
                            if (!duplicateFound) {
                                this.viewUploadFiles.attachments.push(newAttachment);
                            }
                        });
                        // this.viewUploadFiles.attachments.push(data.attachments[0]);
                    } else {
                        this.viewUploadFiles = data;
                    }
                    this.uploadedFiles = this.viewUploadFiles;
                }
            },
            clearFileUpload(confirm){
                if (confirm) {
                    this.uploadedFiles = null
                }
                this.uploadedFiles = null
                this.$refs.fileUpload.reset()
            },
            async processFiles(files){
                // this.uploadedFiles.attachments = []
                // files.forEach(e=>{
                //     this.uploadedFiles.attachments.push(
                //         e.localFileState
                //     )
                // })

                this.viewUploadFiles.attachments = this.viewUploadFiles.attachments.filter(compiledFile => {
                    return files.some(file => compiledFile.name === file.localFileState.name);
                });

                files = files.filter(file => {
                    return this.viewUploadFiles.attachments.some(compiledFile => compiledFile.name === file.localFileState.name);
                });

                let file_type_check = this.file_index === '' ? '2' : '1'
                let item_id = this.file_index
                this.compiledFiles = []
                files.forEach(e=>{
                    if(e.localFileState.upload_type === undefined){
                        e.localFileState.upload_type = file_type_check
                    }
                    if(e.localFileState.item_id === undefined){
                        e.localFileState.item_id = item_id
                    }
                    this.compiledFiles.push(
                        e.localFileState
                    )});
                this.file_index = ''
                let fileData = []
                for(let i = 0; i < this.compiledFiles?.length; i++) {
                    const element = this.compiledFiles[i]
                    let reader = new FileReader()
                    reader.readAsDataURL(element)
                    await new Promise((resolve) => {
                        reader.onloadend = function () {
                            fileData.push({
                                name: element.name,
                                type: element.type,
                                data: reader.result.split(',')[1],
                                upload_type: element.upload_type,
                                item_id: element.item_id,
                            })
                            resolve()
                        }
                    })
                }
                this.uploadedFiles = fileData
            },
            getImages(dataUrls) {
                this.images = dataUrls.map(dataUrl => {
                    try {
                        if (!dataUrl.startsWith('data:image/jpeg;base64,')) {
                            throw new Error('Invalid data URL format');
                        }

                        const base64String = dataUrl.split(',')[1];
                        const byteString = atob(base64String);
                        const arrayBuffer = new ArrayBuffer(byteString.length);
                        const uint8Array = new Uint8Array(arrayBuffer);

                        for (let i = 0; i < byteString.length; i++) {
                            uint8Array[i] = byteString.charCodeAt(i);
                        }

                        const blob = new Blob([uint8Array], { type: 'image/jpeg' });
                        const timestamp = new Date().toISOString().replace(/[:.-]/g, '');
                        const filename = `captured-image-${timestamp}.jpg`;
                        const file = new File([blob], filename, { type: 'image/jpeg' });
                        return file;
                    } catch (error) {
                        console.error('Failed to convert base64 to file:', error);
                        return null;
                    }
                }).filter(file => file !== null);
                this.uploadedImages = []
                this.images.forEach(e=>{
                    e.upload_type = 3,
                    this.uploadedImages.push(e)
                })
                let fileData = []
                for (let i = 0; i < this.uploadedImages?.length; i++) {
                    const element = this.uploadedImages[i]
                    let reader = new FileReader()
                    reader.readAsDataURL(element)
                    new Promise((resolve)=>{
                        reader.onloadend = function () {
                            fileData.push({
                                name: element.name,
                                type: element.type,
                                data: reader.result.split(',')[1],
                                upload_type: element.upload_type,
                            })
                            resolve()
                        }
                    })
                }
                this.uploadedImages = fileData
            },

            clearCameraCapture(){
                this.$refs.cameraCapture.reset();
                this.images = null;
            },

            closeCameraCapture(){
                this.fileUploadDefective = false;
                this.clearCameraCapture()
                this.clearFileUpload(confirm)
                this.$refs.cameraCapture.reset();
                this.$refs.fileUpload.reset();
            },
            nextItem(action){
                const cacheAutonext = this.SELECTED.autonext;

                if(action == 1 && this.SELECTED.autonext === 0){
                    if(this.index > 0){
                        this.index -= 1
                        this.$store.commit('SELECTED',{...this.DATA_TABLE.items[this.index], autonext: cacheAutonext})
                    }else{
                        this.index = this.DATA_TABLE.items.length - 1
                        let payload = this.DATA_TABLE.payload
                        this.keys.page == 1 ? 1 : this.keys.page -= 1
                        payload.page = this.keys.page
                        this.$store.dispatch('adminPurchaseOrderGet', payload).then((response) => {
                            if(response.data.data.length != 0){
                                this.$store.commit('DATA_TABLE_ITEMS',response.data.data)
                                this.$store.commit('SELECTED',{...this.DATA_TABLE.items[this.DATA_TABLE.items.length - 1], autonext: cacheAutonext})
                            }else{
                                this.resetFields()
                            }

                        }).catch((e)=>{
                            this.loaded = true;
                        });
                    }
                }else if (action == 2 && this.SELECTED.autonext === 0){

                    if(this.index < this.DATA_TABLE.items.length - 1){
                        this.index += 1
                        this.$store.commit('SELECTED',{...this.DATA_TABLE.items[this.index], autonext: cacheAutonext})
                    }else{
                        this.index = 0
                        let payload = this.DATA_TABLE.payload
                        this.keys.page = parseInt(this.keys.page) + 1
                        payload.page = this.keys.page
                        this.$store.dispatch('adminPurchaseOrderGet', payload).then((response) => {
                            if(response.data.data.length != 0){
                                this.$store.commit('DATA_TABLE_ITEMS',response.data.data)
                                this.$store.commit('SELECTED',{...this.DATA_TABLE.items[0], autonext: cacheAutonext})
                            }else{
                                this.resetFields()
                            }

                        this.$store.commit('NEW_ENTER_BILL',false);
                        }).catch((e)=>{
                            this.loaded = true;
                        });
                    }
                }
            },
            handleKeydown(event){

                if((event.key == 'ArrowLeft' || this.keys.action == 1) && this.ACTION == 'View'){
                    if(this.index > 0){
                        this.index -= 1
                        this.$store.commit('SELECTED',this.DATA_TABLE.items[this.index])
                    }else{
                        this.index = this.DATA_TABLE.items.length - 1
                        let payload = this.DATA_TABLE.payload
                        this.keys.page == 1 ? 1 : this.keys.page -= 1
                        payload.page = this.keys.page
                        this.$store.dispatch('adminPurchaseOrderGet', payload).then((response) => {
                            if(response.data.data.length != 0){
                                this.$store.commit('DATA_TABLE_ITEMS',response.data.data)
                                this.$store.commit('SELECTED',this.DATA_TABLE.items[this.DATA_TABLE.items.length - 1])
                            }
                        }).catch((e)=>{
                            this.loaded = true;
                        });
                    }
                }else if ((event.key == 'ArrowRight' || this.keys.action == 2) && this.ACTION == 'View'){
                    if(this.index < this.DATA_TABLE.items.length - 1){
                        this.index += 1
                        this.$store.commit('SELECTED',this.DATA_TABLE.items[this.index])
                    }else{
                        this.index = 0
                        let payload = this.DATA_TABLE.payload
                        this.keys.page = parseInt(this.keys.page) + 1
                        payload.page = this.keys.page
                        this.$store.dispatch('adminPurchaseOrderGet', payload).then((response) => {
                            if(response.data.data.length != 0){
                                this.$store.commit('DATA_TABLE_ITEMS',response.data.data)
                                this.$store.commit('SELECTED',this.DATA_TABLE.items[0])
                            }
                        this.$store.commit('NEW_ENTER_BILL',false);
                        }).catch((e)=>{
                            this.loaded = true;
                        });
                    }
                }else if(event.key == 'Escape' && !this.batchAddition.dialog){
                    this.closeDialog();
                }
            },
            sumOfItems(){
                let array = this.form.item_list.filter(e => e.total_original_price > 0)
                this.total = array.reduce((total, item) => total + this.formatCurrencyPHPwithoutRoundingUp(item.total_amount), 0)
            },
            async getBranches(){
                try{
                    this.clonedStores = await this.getStoreBranches()
                } catch (error) {
                    console.error(error)
                }
            },
            // async getLocations(){
            //     try{
            //         this.clonedLocations = await this.getAdminLocations()
            //     } catch (error) {
            //         console.error(error)
            //     }
            // },
            insertCompany(storeId){
                if (!storeId) return
                let store = this.clonedStores.find(e=>e.value == storeId)
                this.form.company_id = this.GET_PV_COMPANIES_SELECTION.find(e=>e.value == store.company_id).value
                // let location = this.clonedLocations.find(e=>e.store_branch_id == storeId)
                // this.form.location_id = this.GET_ADMIN_DELIVERY_ADDRESSES.find(e=>e.store_branch_id == location.store_branch_id).value
            },
            insertStore(){
                this.form.store_branch_id = this.branchItems[0]?.value
                // this.form.location_id = this.locationItems[0].value
            },
            async closeConfirmOverride(user_id, authenticated = false) {
                if (authenticated) {
                    document.activeElement.blur();
                    this.override.override_dialog = false;
                    this.updateStatus(this.form.status, 0);
                }else{
                    this.override.override_dialog = false;
                }
            },
            confirmOveride(){
                if(this.override.override_authenticated == false && this.form.status === 2 && [1,3].includes(this.form.payment_method) && this.form.vendor_payment_item_id){
                    this.override.override_dialog = true;
                }else if(this.override.override_authenticated == false && this.form.status === 2 && this.form.item_list.some(e => e.quantity % e.moq !== 0)){
                    this.override.override_dialog = true;
                }else{
                    this.updateStatus(this.form.status, 0);
                }
            },
            async imageLoader(imagePath){
                let image = await this.loadImage(imagePath);
                this.$store.commit('COMPANY_IMAGE', image);
            },
            async getItemTemplateDetails(id){
                if(id){
                    this.apply_loading = true
                    await this.$store.dispatch('adminPurchaseOrderGet', {
                        url: `get-selected-item-template-detail/${id}`
                    }).then(async (response) => {
                        if(response.length > 0){
                            let data = response;
                            await this.$store.dispatch('adminPurchaseOrderPost',{
                                vendor_id:this.form.vendor_id,
                                url:'check-item-pricelist',
                                admin_items:data.map(e=>e.item_name),
                            }).then(response=>{
                                this.errors.item_not_found = []
                                data = data.filter(e=>{
                                    let item = response.find(el=>el.item_name.toUpperCase() == e.item_name.toUpperCase())
                                    if(!!item){

                                        item.quantity = e.quantity
                                        item.total_amount = item.total_amount * item.quantity

                                        if(!this.selection.admin_items.find(e=>e.admin_item_id == item.admin_item_id)){
                                            this.selection.admin_items.push(item)
                                        }

                                        return item
                                    }else{
                                        this.errors.item_not_found.push(e)
                                    }
                                })

                                let list = data.map(e=>{
                                    return response.find(el=>el.item_name.toUpperCase() == e.item_name.toUpperCase())
                                })
                                if(this.form.item_list.length == 1 && this.form.item_list.map(e => e.admin_item_id == 0)){
                                    this.form.item_list = list;
                                }else{
                                    this.form.item_list = this.form.item_list.concat(list)
                                }

                                this.$forceUpdate()

                                if(this.errors.item_not_found.length > 0){
                                    Swal.fire('Some item/s are not available on the selection','- Please check the items in items module','warning');
                                }
                            })
                        }
                        this.apply_loading = false
                    });
                }
            },
            addItems(){
                this.form.item_list.push({
                    id: null,
                    value:'',
                    total_amount:'',
                    uom_id:'',
                    vendor:'',
                    moq: null,
                    item_vendor_price_list_id: '',
                })
            },
            getAllItemsSelection(vendor_id){
                this.selection.admin_items = []
                 this.$store.dispatch('getPRFAdminItems',{
                    vendor_id:0,
                }).then(async response=>{
                     this.selection.admin_items = await response
                })
            },
            findDesignateVendor(item){
                let i = item;
                let find = this.selection.admin_items.find(e=>e.admin_item_id == i.admin_item_id);
                if(find && find.vendor_id != this.form.vendor_id){
                    this.conflict_item = true;
                    return true;
                }
            },
            itemRowBackground(item){
                if(item.changed){
                    return 'red lighten-4';
                }
                else if(item.moq !== null && (item.quantity % item.moq !== 0)){
                    return 'amber lighten-4';
                }
            },
            onInput(value) {
                const selectedItem = this.GET_CUSTOMERS_LIST_SELECTION.find(item => item.text === value);
                if (selectedItem) {
                    this.form.po_requestor = {
                        value: selectedItem.value,
                        text: selectedItem.text
                    };
                } else {
                    this.form.po_requestor = value;
                }
            },
            getPOActionHistory(){
                this.action_history.dialog = true;
                this.action_history.data = [];
                this.action_history.loading = true;
                this.$store.dispatch('adminPurchaseOrderGet', {
                    url:'get-po-action-history',
                    po_id: this.form.id ? this.form.id : null,
                }).then((response)=>{
                    this.action_history.data = response;
                    this.action_history.loading = false;
                });
            },
            changeAmountBasedOnFoc(item){
                if(item.foc == 1){
                    item.total_amount = '0'
                    item.total_original_price = 0
                }else{
                    item.total_original_price = item.original_price
                    this.updatePrice(item);
                }
                this.sumOfItems();
            },
        },
        watch: {
            'form.company_id': {
                handler(val) {
                    if (!val) {
                        this.form.store_branch_id = null
                        return;
                    }

                    const storeBranchIds = this.GET_PV_STORE_BRANCHES_SELECTION
                        .filter(selection => selection.company_id == val)
                        .map(selection => selection.value);

                    if (!storeBranchIds.includes(this.form.store_branch_id))
                        this.form.store_branch_id = storeBranchIds[0];

                    // const locationIds = this.GET_PV_STORE_BRANCHES_SELECTION
                    //     .filter(selection => selection.company_id == val)
                    //     .map(selection => selection.value);

                    // if (!locationIds.includes(this.form.store_branch_id))
                    //     this.form.location_id = locationIds[0];
                },
            },
            'form.store_branch_id':{
                handler(val){
                    if (!val) {
                        this.form.company_id = null
                        return;
                    }

                    const storeBranchCompanyId = this.GET_PV_STORE_BRANCHES_SELECTION
                        .filter(selection => selection.value == val)
                        .shift().company_id;
                    this.form.company_id = storeBranchCompanyId;
                },
            },
            'form.selected':{
                handler(item){
                    if(this.ACTION == 'Create'){
                        this.form.total_amount = 0
                        if(item.length > 0){

                            let sum = 0;
                            item.forEach(e=>{
                                let split = e.split(':')
                                sum+= parseInt(split[5]);
                            })
                            this.form.total_amount = sum;
                            this.form.select_all = false
                            let tmp = false
                            this.form.vendor_id_items.forEach((e,x)=>{
                                if(e.item_from_prf == item.prf_item_id){
                                    this.form.vendor_id_items.splice(x,1);
                                    tmp = true
                                }
                            });
                        }
                        else{
                            this.form.select_all = false
                        }
                    }
                }
            },
            'form.typeOf':{
                handler(val){
                    this.form.vendor_id_items = []
                }
            },
            'form.vendor_id': {
                async handler(newVal, oldVal) {
                    if(newVal){
                        await this.$store.dispatch("getContactPersonSelection", newVal);
                        await this.$store.dispatch("getTermSelection", newVal);
                        this.$store.dispatch('getPRFAdminItems',{
                            vendor_id:newVal
                        }).then(response=> {
                            this.selection.admin_items = response
                            this.form.location_id = this.ACTION == 'Submit' ? this.GET_ADMIN_DELIVERY_ADDRESSES.find(item => true)?.value : this.form.location_id
                            this.form.shipping_via_id = this.ACTION == 'Submit' ? this.GET_SHIPPING_VIA.find(item => true)?.value : this.form.shipping_via_id;
                            this.form.payment_type_id = this.ACTION == 'Submit' ? this.GET_PAYMENT_TYPES.find(item => true)?.id : this.form.payment_type_id;
                            if(!this.form.contact_person){
                                this.form.contact_person = this.USERACCOUNT_LOAD.name.toString()
                                let company = this.GET_ADMIN_DELIVERY_ADDRESSES.find(e => e.value == this.form.location_id)
                                this.form.contact_number = company?.contact_number
                            }
                            this.form.vendor_address_id = this.ACTION == 'Submit' ? this.GET_CONTACT_PERSON_SELECTION.find(item => true)?.value : this.form.vendor_address_id;
                            this.form.vendor_payment_item_id = this.ACTION == 'Submit' ? this.GET_TERM_SELECTION.find(item => true)?.value : this.form.vendor_payment_item_id;

                            if(this.ACTION !== 'View' && (oldVal !== undefined && oldVal !== '')){
                                if(newVal !== oldVal){
                                    this.form.item_list = [
                                        { value:'', total_amount:'', receive_qty: '', received_qty: '',  uom_id:'', vendor:'', item_vendor_price_list_id: '',}
                                    ]
                                }
                            }
                        })
                    }
                },
                immediate: true
            },
            'form.item_list':{
                handler(val) {
                    this.sumOfItems();
                }
            },
            'form.po_id': {
                handler(val) {
                    if (val) {
                    this.clearFileUpload(true);
                        this.uploadedFiles = {
                            attachments: [],
                        }
                    }
                },
                deep: true
            },
            SELECTED:{
                async handler(val){
                    this.form.type = 0
                    this.loading = true
                    this.print_loading = true
                    this.$store.dispatch("getPurchaseOrderItemsTemplateSelection", {url: 'get-po-items-template-selection'});
                    this.$store.dispatch("getPaymentVoucherBranchesSelection", {company_id: null});
                    if(!!val && this.ACTION !== 'Submit'){
                        await this.$store.dispatch('adminPurchaseOrderGet',{
                            url:`getPurchaseOrderForPrint/${this.PO_RECEIVING_DIALOG ? val.po_id : val.id}`,
                        }).then(response=>{
                            // hidden foc issue but the total will be affected
                            // response.item_list = response.item_list.filter(e=>e.total_amount = parseInt(e.total_amount))
                            if(!this.DIALOGS){
                                return
                            }else if(this.ACTION != 'Submit'){
                                this.form = response
                            }

                            if(val.hasOwnProperty('selected_index')){
                                this.index = val.selected_index
                                this.keys.page = this.DATA_TABLE.option.page ?? 1
                            }

                            this.warehouse_selections = response.item_list[0].item_warehouse_id
                            // this.form.item_list.forEach(e =>{ //Auto Select of Item Warehouse default C8
                            //    e.item_warehouse_id.forEach(i =>{
                            //     if(i.default == 1){
                            //         e.item_warehouse_id = i.value
                            //     }
                            //    })
                            // })
                            this.old_ids = response.item_list.map(e => e.id)
                            this.imageLoader(response.image_path)
                            this.$store.commit('PURCHASE_ORDER_DATA', response)
                            this.$store.dispatch('systemGet', {url:'get-system-theme'}).then((res)=>{
                                if(res.length > 0){
                                    this.$store.commit('SYSTEM_THEME', res[0])
                                    this.print_loading = false
                                }
                            })
                        }).catch(e=>{
                            console.log(e)
                        })
                    }
                    // this.cashOrPercentage(this.form.amount_to_pay, this.form.type)
                    setTimeout(()=>{
                        this.loading = false
                    },1000)

                    this.$forceUpdate();
                },
                deep:true,
                immediate:true
            },
            // totalAmount:{
            //     handler(val){
            //         if (val) {
            //             let vatAmount = val * (parseFloat(this.form.vat_percentage) / 100)
            //             // console.log(vatAmount)
            //             this.$store.commit('PO_VAT', vatAmount)
            //         }
            //     },
            //     immediate: true
            // }
            GET_ADM_WAREHOUSE_CODE_DROPDOWN:{
                handler(val){
                    this.admin_warehouse_dropdown = val
                }
            },
            'form.admin_warehouse_id':{
                handler(val){
                    if (val) {
                        this.form.item_list.forEach(item => {
                            item.item_warehouse_id = val;
                        });
                    }
                },
            },
            'form.total_amount':{
                handler(val){
                    if (val && this.form.type == 1 && this.form.payment_method === 3) {
                        //Fix the amount to pay on Partial Payment on viewing or editing of PO
                        this.form.amount_to_pay = ((this.form.amount_to_pay/val) * 100 ).toFixed(2)
                    }
                },
            },
            'form.vendor_payment_item_id':{
                handler(val){
                    if(val !== null && this.form.vendor_id && this.ACTION == 'Submit'){
                       this.form.payment_method = 2;
                    }else if(this.ACTION == 'Submit'){
                        this.form.vendor_payment_item_id = ''
                    }
                }
            },
            USERACCOUNT_LOAD:{
                handler(val){
                    if(!!val){
                        this.form.contact_person = val.name
                    }
                }
            },
            ACTION:{
                handler(val){
                    if(val == 'Submit'){
                        // this.form.po_num = this.PURCHASE_ORDER.LAST_PO_NUM
                    }
                }
            },
            'PO_ITEMS_TEMPLATE.TEMPLATE_SELECTION':{
                handler(val){
                    if(!!val){
                        this.selection.po_item_template = val
                    }
                }
            },
            'form.billable':{
                handler(val){
                    if(val == 0){
                        this.form.po_requestor = null;
                    }
                }
            }
        },
    };
</script>
<style scoped>
    .red-text {
      color: red;
    }
    .card{
        overflow: hidden;
    }
    div.ribbon-wrap{
        width: 150px;
        height: 40px;
        position: absolute;
        top: -3px;
        left: -44px;
        pointer-events: none;
        z-index: 1;
    }
    .ribbon{
        width: 190px;
        font-size: 0.700rem;
        text-align: center;
        padding: 1px 0;
        background: #FF6B6B;
        color: #fff;
        position: absolute;
        transform: rotate(-40deg);
        right: -17px;
        top: 29%;
    }
    .resize-icon .v-icon {
        font-size: 10px; /* Adjust size as needed */
      }
</style>
