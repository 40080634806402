<template>
<div>
    <v-app id="load_bar" v-if="!loaded">
        <v-text-field color="info" loading disabled></v-text-field>
    </v-app>
    <v-app id="view_client_modal">
        <v-dialog v-model="packing_list_items_dialog" persistent max-width="98%" scrollable>
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Packing List Details</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="clearPackingListItems(); $emit('closeView');">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text>
                    <v-row class="mt-6">
                        <v-container fluid>
                            <v-row class="">
                                <v-col sm="12" md="8" class="text-left text-dark ml-4 mb-4">
                                    <v-row class="ml-2">
                                        <v-col sm="12" md="8" class="p-0">Packing List No: {{ packing_list.packing_list_num }}</v-col>
                                        <!-- <v-col class="col-sm-12 col-md-4 p-0">Issuer: {{ packing_list.issuer_name }}</v-col> -->
                                        <v-col sm="12" md="4" class="p-0">Issuer: {{ packing_list.prepared_by }}</v-col>
                                    </v-row>
                                    <v-row class="ml-2">
                                        <v-col sm="12" md="8" class="p-0">Checker Form No: {{ packing_list.checker_form_id }}</v-col>
                                        <v-col sm="12" md="4" class="p-0">Date Issued: {{ packing_list.created_at | formatDateTime }}</v-col>
                                    </v-row>
                                    <v-row class="ml-2">
                                        <v-col sm="12" md="8" class="p-0">Customer: {{ packing_list.customer_name }}</v-col>
                                    </v-row>
                                    <!-- <v-col class="row ml-2">
                                        <v-col class="col-sm-12 col-md-8 p-0">Checked By: {{ packing_list.checked_by }}</v-col>
                                        <v-col class="col-sm-12 col-md-4 p-0">Prepared By: {{ packing_list.prepared_by }}</v-col>
                                        <v-col class="col-sm-12 col-md-8 p-0">Noted By: {{ packing_list.noted_by }}</v-col>
                                        <v-col class="col-sm-12 col-md-4 p-0">Prepared By: {{ packing_list.prepared_by_2 }}</v-col>
                                    </v-col> -->
                                    <v-row class="ml-2">
                                        <v-col sm="12" md="8" class="p-0 mr-2">Remarks: {{ packing_list.remarks }}</v-col>
                                        <v-col sm="12" md="4" class="p-0">PL Date: {{ packing_list.packing_list_date | formatDate }}</v-col>
                                    </v-row>
                                    <v-row class="ml-2">
                                        <v-col sm="12" md="4" class="p-0">PO#: {{ packing_list.customer_po_num }}</v-col>
                                        <v-col sm="12" md="4" class="p-0" v-if="packing_list.convert_date != null">Convert Date: {{ packing_list.convert_date | formatDateTime }}</v-col>
                                    </v-row>
                                    <v-row class="ml-2">
                                        <v-col sm="12" md="4" class="p-0">No. of Boxes: {{ num_of_box }}</v-col>
                                        <v-col sm="12" md="4" class="p-0" v-if="packing_list.convert_date != null">Convert Date: {{ packing_list.convert_date | formatDateTime }}</v-col>
                                    </v-row>
                                    <v-row class="ml-4 my-3" v-if="itemTypeDiscounts.length > 0  && (packing_list.promo_id == '' || packing_list.promo_id == null) && (packing_list.special != 1 || (packing_list.special==1 && item_type != 'PT'))">
                                        <v-col sm="12" md="8" class="">
                                            <v-row class="p-0">
                                                <v-col sm="12" md="6" class="py-0 mt-2" v-for="(disc, index ) in itemTypeDiscounts" :key="index">
                                                    <span>{{ disc }}</span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col sm="12" md="4" class="p-0"></v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-row>

                    <v-data-table
                        :headers="packingListItemsHeaders"
                        :items="packingListItems"
                        :items-per-page="500"
                        hide-default-footer
                        class="w-100"
                        v-if="is_stockcard=='0'"
                    >
                        <template v-slot:[`item.foc`]="{ item }">
                            <v-tooltip :disabled="!item.foc" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <td
                                        class="side-borders-white px-0"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <span v-if="item.foc=='1'">Yes</span>
                                        <span v-else-if="item.foc=='0'">No</span>
                                    </td>
                                </template>
                                <span>{{ item.foc_remarks }}</span>
                            </v-tooltip>
                        </template>

                        <template v-slot:[`item.display_disc_price`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.display_disc_price | currency }}
                            </span>
                        </template>

                        <template v-slot:[`item.display_srp`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.display_srp | currency }}
                            </span>
                        </template>

                        <template v-slot:[`item.display_final_price`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.display_final_price | currency }}
                            </span>
                        </template>

                        <template v-slot:[`item.display_total_amount`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.display_total_amount | currency }}
                            </span>
                        </template>

                        <template v-slot:[`item.served_amount`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.served_amount | currency }}
                            </span>
                        </template>
                    </v-data-table>

                    <v-data-table
                        :headers="packingListItemsHeaders_SC"
                        :items="packingListItems"
                        :items-per-page="500"
                        hide-default-footer
                        class="w-100"
                        v-else
                    >
                    </v-data-table >

                    <v-row class="m-0">
                        <v-container fluid>
                            <v-row>
                                <v-col sm="12" md="8" class="text-left">
                                    <div class="card-body py-4 d-flex">
                                        <span class="m-1"><v-btn @click="convertToStockCard();" v-if="is_stockcard == 1 && pending_stock_card" :disabled="!userAccess.create || (packing_list.status != 5 && packing_list.status != 1)">CONVERT TO SC</v-btn></span>
                                        <span class="m-1"><v-btn @click="print_no_price = false; checkPrintOverride();" v-if="(!is_preparation && !unconfirmed) && is_stockcard == 0" :disabled="disablePLPrintButtons('with_price')">Print</v-btn></span>
                                        <!-- <span class="m-1"><v-btn @click="checkCustomerPrintNoPrice()" v-if="!unconfirmed && is_stockcard == '0'" :disabled="disablePLPrintButtons('without_price')">Print No Price</v-btn></span> -->
                                        <span class="m-1"><v-btn @click="print_no_price = true; checkPrintOverride()" v-if="!unconfirmed && is_stockcard == '0'" :disabled="disablePLPrintButtons('without_price')">Print No Price</v-btn></span>
                                        <span class="m-1"><v-btn @click="print_no_price = false; printForEmail('download')" v-if="!unconfirmed && is_stockcard == '0'" :disabled="disablePLPrintButtons('with_price')">Print For Email</v-btn></span>
                                    </div>
                                </v-col>
                                <v-col sm="12" md="4" class="pull-right text-right text-dark" v-if="is_stockcard==0">
                                    Total Amount: &#x20b1;{{ packing_list.total_amount | currency }}
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-row>
                    <div class="border-top">
                        <div class="card-body py-4 d-flex justify-content-center">
                            <!-- <span class="m-1" v-if="(!unconfirmed && packing_list.status != 5 && packing_list.status != 3 && packing_list.status != 1) && is_stockcard == '0'"><v-btn @click="updatePackingListStatus(5)">Submit</v-btn></span> -->
                            <span class="m-1" v-if="(userAccess.confirm && packing_list.status == 5 && packing_list.status != 3 && packing_list.printed == 1) && is_stockcard == 0"><v-btn @click="updatePackingListStatus(1)">Confirm</v-btn></span>
                            <span class="m-1" v-if="(packing_list.status == 5 && packing_list.status != 3 && packing_list.printed == 1) && is_stockcard == 0"><v-btn @click="updatePackingListStatus(1)">Confirm</v-btn></span>
                        </div>
                    </div>

                </v-card-text>

                <v-container fluid style="display:none;">  <!-- PRINT FOR DELIVERY -->
                    <div id="div_logo" >
                        <div style="font-size: 12px; line-height: 1.9">
                            <!-- <p v-if="service==0 || service=='0'"> -->
                            <p>
                                <span style="font-size: 18px; font-weight: bold;">
                                    INGCOPH Traders Inc.
                                </span>
                                <br>420 Del Monte Ave, Brgy. Siena, Quezon City
                                <br>Cell No: +63 935 713 2293 / +63 917 170 0979 / +63 919 078 0016
                                <br>Tel. No.: +63 2 8727 1771 / +63 2 8535 9968 / +63 2 7091 4539
                                <br>
                            </p>
                            <!-- <p v-else>
                                <span style="font-size: 18px; font-weight: bold;">
                                    INGCOPH Traders Inc.
                                </span>
                                <br>420 Del Monte Ave, Brgy. Siena, Quezon City
                                <br>Cell No.: +63 935 713 2293 / +63 917 170 0979 / +63 919 078 0016
                                <br>Tel. No.: +63 2 8727 1771 / +63 2 8535 9968 / +63 2 7091 4539
                                <br>Service Mobile / Viber No.: +63 919 078 0019 / +63 927 414 5539
                                <br>Spare Parts Mobile / Viber No.: +63 995 095 3153
                            </p> -->
                        </div>
                    </div>
                    <div id="div_packing_list_num" style="line-height: 1.7">
                        <div style="border-top: 1px dashed #CFCFCF; padding: 2px; padding-bottom: -4px; text-align: center; border-bottom: none;">&nbsp;</div>
                        <p>
                            <!-- <span style="font-size: 18px; font-weight: bold;">Packing List</span> -->
                            <br><b><span style="font-size: 18px">PL# {{ packing_list.packing_list_num }}</span></b>
                            <br><b><span style="font-size: 12px">SO# {{ packing_list.order_num }}</span></b>
                            <br><b><span style="font-size: 12px">CF# {{ packing_list.checker_form_id }}</span></b>
                        </p>
                    </div>

                    <div>
                        <table id="customer_details">
                            <tbody>
                            <tr>
                                <td>Customer:</td>
                                <td>{{ packing_list.customer_name | uppercase }} </td>
                                <td>Date:</td>
                                <td>{{ packing_list.packing_list_date | formatDate }} </td>
                            </tr>
                            <tr>
                                <td>Address:</td>
                                <td>{{ packing_list.shipping_address_text | uppercase }}</td>
                                <td>Terms:</td>
                                <td v-if="packing_list.promo_id != '' && packing_list.terms == 0">Cash</td>
                                <td v-else>{{ packing_list.terms }} days</td>
                            </tr>
                            <tr>
                                <td>Remarks:</td>
                                <td>{{ packing_list.remarks }}</td>
                                <td>PO:</td>
                                <td>{{ packing_list.customer_po_num }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div>
                        <table id="items_details">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>QTY</th>
                                    <th>UNITS</th>
                                    <th>MODEL</th>
                                    <th>DESCRIPTION</th>
                                    <th>UNIT PRICE</th>
                                    <th>TOTAL</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index ) in packingListItems" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.quantity }}</td>
                                    <td>{{ item.item.item_uom_text }}</td>
                                    <td>{{ item.item.model }}</td>
                                    <td>{{ item.item.name }}</td>
                                    <td v-if="item.total_amount>0">{{ item.display_srp | currency }}</td><td v-else>FOC</td>
                                    <td v-if="item.total_amount>0">{{ item.quantity * item.display_srp | currency }}</td><td v-else>FOC</td>
                                </tr>
                                <tr>
                                    <td colspan="7">***** NOTHING FOLLOWS *****</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div>
                        <table id="items_details_without_price">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>QTY</th>
                                    <th>UNITS</th>
                                    <th>MODEL</th>
                                    <th>DESCRIPTION</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index ) in packingListItems" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.quantity }}</td>
                                    <td>{{ item.item.item_uom_text }}</td>
                                    <td>{{ item.item.model }}</td>
                                    <td>{{ item.item.name }}</td>
                                </tr>
                                <tr>
                                    <td colspan="5">***** NOTHING FOLLOWS *****</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div>
                        <table id="total_details">
                            <tbody>
                                <tr>
                                    <td>
                                        <p>{{ (itemTypeDiscounts[0] != null)?itemTypeDiscounts[0]: '' }}</p>
                                    </td>
                                    <td>
                                        <p>GROSS SALES:</p>
                                    </td>
                                    <td>
                                        <p>PHP</p>
                                    </td>
                                    <td>
                                        <p>{{ packing_list.gross_amount | currency }}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                       <p>{{ (itemTypeDiscounts[1] != null)?itemTypeDiscounts[1]: '' }}</p>
                                    </td>
                                    <td>
                                        <p>Less - Discount:</p>
                                    </td>
                                    <td>
                                        <p></p>
                                    </td>
                                    <td>
                                        <p>{{ packing_list.discount_amount | currency }}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                       <p>{{ (itemTypeDiscounts[2] != null)?itemTypeDiscounts[2]: '' }}</p>
                                    </td>
                                    <td>
                                        <p>TOTAL SALES:</p>
                                    </td>
                                    <td>
                                        <p>PHP</p>
                                    </td>
                                    <td>
                                        <p>{{ packing_list.display_total_amount | currency }}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                       <p>{{ (itemTypeDiscounts[2] != null)?itemTypeDiscounts[2]: '' }}</p>
                                    </td>
                                    <td>
                                        <p>No. of Boxes:</p>
                                    </td>
                                    <td>
                                        <p>{{ num_of_box }}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <table id="num_of_items">
                            <tbody>
                                <tr>
                                    <td><span>Note: Invoice to follow</span></td>
                                    <td><span>No. of Items: {{ this.no_of_items }}</span></td>
                                    <td><span>No. of Warranty: {{ this.no_of_warranties }}</span></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><span>No. of Boxes: {{ this.num_of_box }}</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div id="div_received_note"  style="font-size: 10px; line-height: 1.0">
                        <p style="font-size: 12px;">Received the above goods and<br>services in good order & condition</p>
                        <p style="font-size: 10px;"></p>
                        <p style="font-size: 22px;">_____________</p>
                        <p style="font-size: 10px;"><i>Signature over printed name / date</i></p>
                    </div>
                    <div>
                        <table id="footer">
                            <tbody>
                                <tr>
                                    <td>Issued By</td>
                                    <td colspan="2">Noted by</td>
                                </tr>
                                <tr>
                                    <td><br><br>{{ this.packing_list.prepared_by }}</td>
                                    <!-- <td><br><br>{{ this.packing_list.checked_by }}</td> -->
                                    <!-- <td><br><br>Aileen Medidas</td> -->
                                    <td><br><br></td>
                                    <!-- <td><br><br>{{ this.packing_list.noted_by }}</td> -->
                                    <!-- <td><br><br>Ace De Leon</td> -->
                                    <td><br><br></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </v-container>

                <v-container fluid style="display:none;"> <!-- PRINT FOR EMAIL -->
                    <div id="for_email_div_packing_list_num" style="line-height: 1.7">
                        <div style="border-top: 1px dashed #CFCFCF; padding: 2px; padding-bottom: -4px; text-align: center; border-bottom: none;">&nbsp;</div>
                        <p>
                            <!-- <span style="font-size: 18px; font-weight: bold;">Packing List</span> -->
                            <br><b><span style="font-size: 18px">PL# {{ packing_list.packing_list_num }}</span></b>
                            <br><b><span style="font-size: 12px">SO# {{ packing_list.order_num }}</span></b>
                            <br><b><span style="font-size: 12px">CF# {{ packing_list.checker_form_id }}</span></b>
                        </p>
                    </div>
                    <div>
                        <table id="for_email_customer_details">
                            <tbody>
                            <tr>
                                <td>Customer:</td>
                                <td>{{ packing_list.customer_name | uppercase }} </td>
                                <td>Date:</td>
                                <td>{{ packing_list.packing_list_date | formatDate }} </td>
                            </tr>
                            <tr>
                                <td>Address:</td>
                                <td>{{ packing_list.shipping_address_text | uppercase }}</td>
                                <td>PO:</td>
                                <td>{{ packing_list.customer_po_num }}</td>
                            </tr>
                            <tr>
                                <td>Remarks:</td>
                                <td>{{ packing_list.remarks }}</td>
                                <td></td>
                                <td></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <table id="for_email_items_details">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>QTY</th>
                                    <th>UNITS</th>
                                    <th>MODEL</th>
                                    <th>DESCRIPTION</th>
                                    <th>UNIT PRICE</th>
                                    <th>TOTAL</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index ) in packingListItems" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.quantity }}</td>
                                    <td>{{ item.item.item_uom_text }}</td>
                                    <td>{{ item.item.model }}</td>
                                    <td>{{ item.item.name }}</td>
                                    <td v-if="item.total_amount>0">{{ item.display_srp | currency }}</td><td v-else>FOC</td>
                                    <td v-if="item.total_amount>0">{{ item.quantity * item.display_srp | currency }}</td><td v-else>FOC</td>
                                </tr>
                                <tr>
                                    <td colspan="7">***** NOTHING FOLLOWS *****</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <table id="for_email_total_details">
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td>
                                        <p>GROSS SALES:</p>
                                    </td>
                                    <td>
                                        <p>PHP</p>
                                    </td>
                                    <td>
                                        <p>{{ packing_list.gross_amount | currency }}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <p>Less - Discount:</p>
                                    </td>
                                    <td></td>
                                    <td>
                                        <p>{{ packing_list.discount_amount | currency }}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <p>TOTAL SALES:</p>
                                    </td>
                                    <td>
                                        <p>PHP</p>
                                    </td>
                                    <td>
                                        <p>{{ packing_list.display_total_amount | currency }}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                       <p>{{ (itemTypeDiscounts[2] != null)?itemTypeDiscounts[2]: '' }}</p>
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <table id="for_email_num_of_items">
                            <tbody>
                                <tr>
                                    <td><span>No. of Items: {{ this.no_of_items }}</span></td>
                                    <td><span>No. of Warranty: {{ this.no_of_warranties }}</span></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td colspan="3"><span><i>Note: THIS IS NOT AN OFFICIAL RECEIPT. FOR PRICE REFERENCE ONLY</i></span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </v-container>

                <stock-card-print-component v-if="stock_card_print" :stock_card="stock_card" :stockCardItems="stockCardItems"></stock-card-print-component>

            </v-card>
        </v-dialog>

        <v-dialog v-model="override_print_dialog" persistent max-width="30%" scrollable>
            <v-card>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">Confirm Override Print</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="override_print_dialog = false; override_username = ''; override_user_password = '';">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text>
                    <div class="">
                        <v-col sm="12" md="12" class="">
                            <label for="override_user_password" class="float-left control-label col-form-label">Password</label>
                            <v-text-field
                                outlined
                                dense
                                type="password"
                                class="form-control"
                                id="override_user_password"
                                label="Password"
                                v-model="override_user_password"
                                autocomplete="new-password"/>
                            <span v-for="errors in errors.override_user_password" class="text-warning" v-bind:key="errors">{{errors}}</span>
                        </v-col>
                    </div>
                    <div class="border-top">
                        <div class="card-body py-4 d-flex justify-content-center">
                            <v-btn @click="confirmedOverridePrint()">Confirm</v-btn>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="print_no_price_dialog" persistent max-width="30%" scrollable>
            <v-card>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">Confirm Override Print No Price</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="print_no_price_dialog = false; override_username = ''; override_user_password = '';">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text>
                    <v-row class="">
                        <v-col sm="12" md="12" class="">
                            <label for="override_user_password" class="float-left control-label col-form-label">Password</label>
                            <v-text-field
                                label="Password"
                                type="password"
                                class="form-control"
                                id="override_user_password"
                                v-model="override_user_password"
                                autocomplete="new-password"/>
                            <span v-for="errors in errors.override_user_password" class="text-warning" v-bind:key="errors">{{errors}}</span>
                        </v-col>
                    </v-row>
                    <div class="border-top">
                        <div class="card-body py-4 d-flex justify-content-center">
                            <v-btn @click="confirmedOverridePrintNoPrice()">Confirm</v-btn>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>
</div>
</template>
<script>
    // import SharedFunctionsComponent from '../Shared/SharedFunctionsComponent.vue'
    import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
    import SalesFunctionsComponentVue from '@/views/main/Shared/SalesFunctionsComponent.vue';
    import { mapGetters } from 'vuex';
    import swal from 'sweetalert2';
    import $ from 'jquery';
    export default {
        mixins: [SharedFunctionsComponentVue, SalesFunctionsComponentVue],
        components: {
            // StockCardPrintComponent: () => import('../StockCards/StockCardPrintComponent.vue')
        },
        props: [
            'viewPackingListId',
            'is_preparation',
            'unconfirmed',
            'is_stockcard',
            'service'
        ],
        async mounted(){
            // await this.checkAccess('AC', 'unconfirmed_pl', 'confirm')
            // await this.checkAccess('AC', 'unconfirmed_pl', 'print_confirmed')
            // await this.checkAccess('OP', 'stock_card', 'create')
            this.checkAccess();
            if(this.is_preparation){
                this.packingListItemsHeaders = this.packingListPreparationItemsHeaders
            }
        },
        data() {
            return {
                packingListItems: [],
                packingListItemsHeaders: [
                    { text: 'Model', align: 'left', value: 'item.model', width: '15%', sortable: false},
                    { text: 'Name', align: 'left', value: 'item.name', width: '35%', sortable: false},
                    { text: 'Type', align: 'left', value: 'item.item_type_text', sortable: false},
                    { text: 'FOC', align: 'left', value: 'foc', sortable: false},
                    { text: 'SRP', align: 'left', value: 'display_srp', sortable: false},
                    { text: 'Disc Price', align: 'left', value: 'display_disc_price', sortable: false},
                    { text: 'Qty', align: 'left', value: 'quantity', sortable: false},
                    { text: 'UOM', align: 'left', value: 'item.item_uom_text', sortable: false},
                    { text: 'Price', align: 'right', value: 'display_final_price', width: '15%', sortable: false},
                    { text: 'Total', align: 'right', value: 'display_total_amount', width: '15%', sortable: false},
                ],
                packingListPreparationItemsHeaders: [
                    { text: 'Model', align: 'left', value: 'item.model', width: '15%', sortable: false},
                    { text: 'Name', align: 'left', value: 'item.name', width: '35%', sortable: false},
                    { text: 'Type', align: 'left', value: 'item.item_type_text', sortable: false},
                    { text: 'Qty', align: 'left', value: 'quantity', sortable: false},
                    { text: 'UOM', align: 'left', value: 'item.item_uom_text', sortable: false},
                ],
                packingListItemsHeaders_SC: [
                    { text: 'Model', align: 'left', value: 'item.model', width: '15%', sortable: false},
                    { text: 'Name', align: 'left', value: 'item.name', width: '35%', sortable: false},
                    { text: 'Type', align: 'left', value: 'item.item_type_text', sortable: false},
                    { text: 'Qty', align: 'left', value: 'quantity', sortable: false},
                    { text: 'Qty Served', align: 'left', value: 'quantity_served', sortable: false},
                    { text: 'Qty Remaining', align: 'left', value: 'stock_card_qty_remaining', sortable: false},
                    { text: 'UOM', align: 'left', value: 'item.item_uom_text', sortable: false},
                ],

                loaded: true,
                packing_list_items_dialog: false,
                packing_list: '',
                discounts: [],
                itemTypeDiscounts: [],
                itemTypeDiscountsPDF: [],
                type_list: [],
                errors: [],
                print_no_price: false,
                userAccess: {
                    confirm: false,
                    print_confirmed: false,
                    stockcard_view: false,
                },
                printed: 0,
                override_print_dialog: false,
                override_username: '',
                override_user_password: '',
                print_no_price_dialog: false,
                item_type: '',

                stock_card_id: '',
                pending_stock_card: false,
                viewStockCardId: '',
                stock_card: '',
                stockCardItems: [],
                stock_card_print: false,
                renderOnFlight: false,
                num_of_box:0,
            }
        },
        methods: {
            printStockCard(){
                this.viewStockCardId = this.stock_card_id
            },
            checkCustomerPrintNoPrice(){
                if(this.packing_list.customer_print_no_price == 0 || this.packing_list.customer_print_no_price == null){
                    this.print_no_price_dialog = true
                }else{
                    this.print_no_price = true;
                    this.renderOnFlight = true;
                    setTimeout(()=>{
                        this.renderPDF('print');
                    }, 2000);
                }
            },
            checkAccess(){
                this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id).then(response=>{
                    let val = response;
                    this.userAccess.confirm = false;
                    this.userAccess.print_confirmed = false;
                    this.userAccess.stockcard_view = false;
                    if(val != "fail"){
                    val.forEach((e) => {
                        if (e.actions_code == "confirm"){
                            this.userAccess.confirm = true;
                        }
                        if (e.actions_code == "print_confirmed"){
                            this.userAccess.print_confirmed = true;
                        }
                        if(e.actions_code == "stockcard_view"){
                            this.userAccess.stockcard_view = true;
                        }
                    });
                    }
                })
            },
            disablePLPrintButtons(with_or_without_price){
                if (this.packing_list.status == 1) {
                    if (this.userAccess.print_confirmed) {
                        return false;
                    } else {
                        return true;
                    }
                } else if (this.packing_list.status == 5) {
                    if (with_or_without_price == 'with_price') {
                        if (this.userAccess.print_confirmed) {
                            return false;
                        } else {
                            return true;
                        }
                    } else if (with_or_without_price == 'without_price') {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            },
            updatePackingListStatus(newStatus){
                this.errors = []
                swal.fire({
                    title: "",
                    text: "Are you sure you want to update Packing List?",
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        let payload = {
                            id: this.viewPackingListId,
                            params:{
                                status: newStatus
                            }
                        }

                        this.$store.dispatch('updatePackingListStatus',payload).then(response => {
                            let data = response.data

                            if(data.error){
                                swal.fire("", data.error, "warning");
                                return false
                            }
                            this.resetFields()

                            swal.fire("", data.msg, "success");

                            this.packing_list_items_dialog = false
                            this.$emit('closeView', 'updateStatusSuccess')
                        });
                    }
                });
            },
            printNow(pdfAction) {
                var doc = new this.$jspdf('p', 'mm', 'letter');
                var margin_left = 7;
                var margin_right = 9;

                //doc.fromHTML($("#div_logo").get(0), 50,-4,{'width':600});
                doc.fromHTML($("#div_logo").get(0), margin_left,-4,{'width':600});
                doc.fromHTML($("#div_packing_list_num").get(0), 162,4,{'width':400});
                doc.fromHTML($("#div_received_note").get(0), 132,237,{'width':400, halign:'center'});

                let customer_details_top = 37

                doc.autoTable({
                    html: '#customer_details',
                    theme: 'plain',
                    margin: {top: customer_details_top, left: margin_left, right: margin_right},
                    columnStyles: {
                        0: {cellWidth: 20},
                        1: {cellWidth: 133},
                        2: {cellWidth: 14},
                        3: {cellWidth: 23},
                    }
                });

                if(this.print_no_price == false){
                    doc.autoTable({
                        html: '#items_details',
                        startY: doc.autoTable.previous.finalY + 3,
                        margin: {left: margin_left, right: margin_right},
                        theme: 'grid',
                        headStyles: {
                            halign:'center',
                            fillColor: '#ffffff',
                            textColor: '#000000',
                            lineWidth: .2,
                            lineColor: '#000000',
                            fontSize: 9,
                        },
                        columnStyles: {
                            0: {cellWidth: 7, halign:'center',  textColor: '#000000', fontSize: 8},
                            1: {cellWidth: 12, halign:'center', textColor: '#000000', fontSize: 8},
                            2: {cellWidth: 14, halign:'center', textColor: '#000000', fontSize: 8},
                            3: {cellWidth: 31, halign:'center', textColor: '#000000', fontSize: 8},
                            4: {cellWidth: 82, halign:'left', textColor: '#000000', fontSize: 8},
                            5: {cellWidth: 23, halign:'right', textColor: '#000000', fontSize: 8},
                            6: {cellWidth: 25, halign:'right', textColor: '#000000', fontSize: 8}
                        },
                        bodyStyles: {
                            lineColor: '#000000',
                        }
                    });
                }else{

                    doc.autoTable({
                        html: '#items_details_without_price',
                        startY: doc.autoTable.previous.finalY + 3,
                        margin: {left: margin_left, right: margin_right},
                        theme: 'grid',
                        headStyles: {
                            halign:'center',
                            fillColor: '#ffffff',
                            textColor: '#000000',
                            lineWidth: .2,
                            lineColor: '#000000',
                            fontSize: 9,
                        },

                        columnStyles: {
                            0: {cellWidth: 11, halign:'center', textColor: '#000000', fontSize: 8},
                            1: {cellWidth: 17, halign:'center', textColor: '#000000', fontSize: 8},
                            2: {cellWidth: 17, halign:'center', textColor: '#000000', fontSize: 8},
                            3: {cellWidth: 27, halign:'center', textColor: '#000000', fontSize: 8},
                            4: {cellWidth: 118, halign:'left', textColor: '#000000', fontSize: 8},
                        },
                        bodyStyles: {
                            lineColor: '#000000',
                        }
                    });
                }

                if(this.print_no_price == false){
                    doc.autoTable({
                        html: '#total_details',
                        theme: 'plain',
                        startY: this.print.packingListItems[i].length == 24 ? lastY + 3 : 207,
                        margin: {top: 37, left: margin_left, right: margin_right},
                        columnStyles: {
                            0: {cellWidth: 115, textColor: '#000000'},
                            1: {cellWidth: 32, halign:'right', textColor: '#000000'},
                            2: {cellWidth: 11, textColor: '#000000'},
                            3: {cellWidth: 35, halign:'right', textColor: '#000000'},
                        }
                    });
                }

                doc.autoTable({
                    html: '#num_of_items',
                    theme: 'plain',
                    tableWidth: 116,
                    startY: 223,
                    margin: {top: 37, left: margin_left, right: margin_right},
                    columnStyles: {
                        0: {cellWidth: 38, textColor: '#000000'},
                        1: {cellWidth: 34, textColor: '#000000'},
                        2: {cellWidth: 38, textColor: '#000000'},
                    }
                });

                // var footer_y = doc.autoTable.previous.finalY + 1
                // if(this.print_no_price == true){
                //     footer_y = 218
                // }

                var footer_y = 240

                doc.autoTable({
                    html: '#footer',
                    theme: 'grid',
                    tableWidth: 116,
                    startY: footer_y,
                    margin: {left: margin_left, right: margin_right},
                    columnStyles: {
                        0: {cellWidth: 38, halign:'center', textColor: '#000000'},
                        1: {cellWidth: 38, halign:'center', textColor: '#000000'},
                        2: {cellWidth: 38, halign:'center', textColor: '#000000'},
                    },
                    bodyStyles: {
                        lineColor: '#000000',
                    }
                });

                let finalY = doc.lastAutoTable.finalY; // The y position on the page
                doc.setFontSize(7);
                doc.setTextColor('#000000');
                doc.text(181, finalY, "Page 1 of 1")

                let total_print_count = this.packing_list.print_with_price_counter + this.packing_list.print_without_price_counter
                if (this.packing_list.printed == 1 && total_print_count > 0) {
                    doc.setFontSize(6);
                    doc.text(197, finalY, "R-" + total_print_count)
                }

                if(pdfAction == 'download'){
                    // doc.save('PackingList ' + this.packing_list.packing_list_num + '.pdf');
                    doc.save('PL#' + this.packing_list.packing_list_num + ' - ' + this.packing_list.customer_name + '.pdf');
                    this.renderOnFlight = false;
                }else if(pdfAction == 'print'){
                    doc.autoPrint();

                    if (this.packing_list.terms == 0) {
                        swal.fire({
                            title: "",
                            text: "PL terms is CASH. Please make sure payment is made before releasing the items.",
                            icon: "warning",
                            dangerMode: true,
                        }).then(willPrint => {
                            this.triggerPrintedPL(doc);
                            this.renderOnFlight = false;
                        })
                    } else {
                        this.triggerPrintedPL(doc);
                        this.renderOnFlight = false;
                    }
                }
            },
            printForEmail(pdfAction) {
                var doc = new this.$jspdf('p', 'mm', 'letter');
                var margin_left = 7;
                var margin_right = 9;

                doc.fromHTML($("#for_email_div_packing_list_num").get(0), 162, -4, {'width':400});

                let customer_details_top = 28

                doc.autoTable({
                    html: '#for_email_customer_details',
                    theme: 'plain',
                    margin: {top: customer_details_top, left: margin_left, right: margin_right},
                    columnStyles: {
                        0: {cellWidth: 20},
                        1: {cellWidth: 133},
                        2: {cellWidth: 14},
                        3: {cellWidth: 23},
                    }
                });

                doc.autoTable({
                    html: '#for_email_items_details',
                    startY: doc.autoTable.previous.finalY + 3,
                    margin: {left: margin_left, right: margin_right},
                    theme: 'grid',
                    headStyles: {
                        halign:'center',
                        fillColor: '#ffffff',
                        textColor: '#000000',
                        lineWidth: .2,
                        lineColor: '#000000',
                        fontSize: 9,
                    },
                    columnStyles: {
                        0: {cellWidth: 7, halign:'center',  textColor: '#000000', fontSize: 8},
                        1: {cellWidth: 12, halign:'center', textColor: '#000000', fontSize: 8},
                        2: {cellWidth: 14, halign:'center', textColor: '#000000', fontSize: 8},
                        3: {cellWidth: 31, halign:'center', textColor: '#000000', fontSize: 8},
                        4: {cellWidth: 82, halign:'left', textColor: '#000000', fontSize: 8},
                        5: {cellWidth: 23, halign:'right', textColor: '#000000', fontSize: 8},
                        6: {cellWidth: 25, halign:'right', textColor: '#000000', fontSize: 8}
                    },
                    bodyStyles: {
                        lineColor: '#000000',
                    }
                });

                doc.autoTable({
                    html: '#for_email_total_details',
                    theme: 'plain',
                    startY: 207,
                    margin: {top: 37, left: margin_left, right: margin_right},
                    columnStyles: {
                        0: {cellWidth: 115, textColor: '#000000'},
                        1: {cellWidth: 32, halign:'right', textColor: '#000000'},
                        2: {cellWidth: 11, textColor: '#000000'},
                        3: {cellWidth: 35, halign:'right', textColor: '#000000'},
                    }
                });

                doc.autoTable({
                    html: '#for_email_num_of_items',
                    theme: 'plain',
                    tableWidth: 140,
                    startY: 249,
                    margin: {top: 37, left: margin_left, right: margin_right},
                    columnStyles: {
                        0: {cellWidth: 38, textColor: '#000000'},
                        1: {cellWidth: 38, textColor: '#000000'},
                        2: {cellWidth: 66, textColor: '#000000'},
                    }
                });

                let finalY = doc.lastAutoTable.finalY; // The y position on the page
                doc.setFontSize(7);
                doc.setTextColor('#000000');
                doc.text(190, finalY, "Page 1 of 1")

                doc.save('PL#' + this.packing_list.packing_list_num + ' - ' + this.packing_list.customer_name + '.pdf');
                this.renderOnFlight = false;
            },
            renderPDF(pdfAction){
                if(this.is_preparation){
                    this.print_no_price == false
                }

                this.printNow(pdfAction);
            },
            clearPackingListItems(){
                this.resetFields()
                this.packing_list_items_dialog = false
            },
            triggerPrintedPL(doc){
                let payload = {
                    id: this.viewPackingListId,
                    params:{
                        is_stockcard: this.is_stockcard,
                        no_price: this.print_no_price,
                    }
                }
                this.$store.dispatch('updatePLPrinted',payload).then(response => {
                    let data = response.data

                    if(data.error){
                        swal.fire("", data.error, "warning");
                        return false
                    }

                    window.open(doc.output('bloburl'), '_blank');
                    doc = null

                    if(this.service != 1) {
                        this.printStockCard()
                    }

                    this.resetFields()

                    swal.fire("", data.msg, "success");

                    this.packing_list_items_dialog = false
                    this.$emit('closeView', 'updateStatusSuccess')
                });
            },
            resetFields(){
                this.packingListItems = []
                this.packing_list = ''
                this.discounts = []
                this.itemTypeDiscounts = []
                this.printed = false
                this.override_print_dialog = false
                this.item_type = ''
                this.renderOnFlight = false;
            },
            getItemTypes(){
                this.$store.dispatch('getItemTypes').then(async response => {
                    this.type_list = this.GET_ITEM_TYPES
                    await this.getItemTypeDiscounts()
                    await this.getItemTypeDiscountsPDF()
                });
            },
            getItemTypeDiscounts(){
                this.itemTypeDiscounts = []

                if(this.packingListItems.length > 0) {
                    this.item_type = this.packingListItems[0].item.item_type_text
                }

                if(this.type_list.length > 0){
                    this.type_list.forEach((type)=>{
                        var name = type.text
                        var str = type.text + ': '
                        var isEmpty = true;
                        if(this.discounts.length > 0){
                            this.discounts.forEach((discount, index)=>{
                                if(discount[name.toLowerCase()] != null && discount[name.toLowerCase()] != '' && discount[name.toLowerCase()] > 0){
                                    if(index > 0){
                                        str = str + ' / '
                                    }
                                    str = str + discount[name.toLowerCase()].toString()
                                    isEmpty = false
                                }
                            })
                            if(isEmpty){
                                str = ''
                            }else{
                                if(this.packingListItems[0].item.item_type_text == type.text){
                                    this.itemTypeDiscounts.push(str)
                                }
                            }
                        }
                    })
                }
            },
            getItemTypeDiscountsPDF(){
                this.itemTypeDiscountsPDF = []
                if(this.type_list.length > 0){
                    this.type_list.forEach((type)=>{
                        var name = type.text
                        var str = '('+type.text+') LESS: '
                        var isEmpty = true;
                        if(this.discounts.length > 0){
                            this.discounts.forEach((discount, index)=>{
                                if(discount[name.toLowerCase()] != null && discount[name.toLowerCase()] != '' && discount[name.toLowerCase()] > 0){
                                    if(index > 0){
                                        str = str + ' / '
                                    }
                                    str = str + discount[name.toLowerCase()].toString() + '%'
                                    isEmpty = false
                                }
                            })
                            if(isEmpty){
                                str = ''
                            }else{
                                if(this.packingListItems[0].item.item_type_text == type.text){
                                    this.itemTypeDiscountsPDF.push(str)
                                }
                            }
                        }
                    })
                }
            },
            checkPrintOverride(){
                if (this.print_no_price) {
                    if (this.print_without_price_counter > 0) {
                        this.override_print_dialog = true
                    } else {
                        this.renderPDF('print')
                    }
                } else {
                    if (this.print_with_price_counter > 0) {
                        this.override_print_dialog = true
                    } else {
                        this.renderPDF('print')
                    }
                }

                // if(this.packing_list.printed){
                //     this.override_print_dialog = true
                // }else{
                //     this.renderPDF('print');
                // }
            },
            confirmedOverridePrint(){
                // if(this.override_username == '' || this.override_user_password == ''){
                if(this.override_user_password == ''){
                    //this.override_user_id = -1
                    this.override_print_dialog = false
                }else{
                    let payload = {
                        departments_allowed:          ['AC', 'EX', 'IT'],
                        override_username:            this.override_username,
                        override_user_password:       this.override_user_password,
                    }
                    this.$store.dispatch('confirmOverridePrintPL',payload).then(response => {
                        let data = this.GET_CONFIRM_OVERRIDE_PRINT_PL

                        // this.override_username = ''
                        this.override_user_password = ''

                        if(data.error){
                            //this.override_user_id = -1
                            swal.fire("", data.error, "warning");
                            return false
                        }

                        //this.override_user_id = data.user_id //get user id after entering username password
                        this.override_print_dialog = false
                        this.renderOnFlight = true;
                        setTimeout(()=>{
                            this.renderPDF('print');
                        }, 2000);
                    }).catch(error => {
                        //this.override_user_id = -1

                    });
                }
            },
            confirmedOverridePrintNoPrice(){
                // if(this.override_username == '' || this.override_user_password == ''){
                if(this.override_user_password == ''){
                    //this.override_user_id = -1
                    this.print_no_price_dialog = false
                }else{
                    let payload = {
                        departments_allowed:          ['AC', 'EX', 'IT'],
                        override_username:            this.override_username,
                        override_user_password:       this.override_user_password,
                    }
                    this.$store.dispatch('confirmOverridePrintPL',payload).then(response => {
                        let data = response.data

                        // this.override_username = ''
                        this.override_user_password = ''

                        if(data.error){
                            //this.override_user_id = -1
                            swal.fire("", data.error, "warning");
                            return false
                        }

                        //this.override_user_id = data.user_id //get user id after entering username password
                        this.print_no_price_dialog = false
                        this.print_no_price = true;
                        this.renderOnFlight = true;
                        setTimeout(()=>{
                            this.renderPDF('print');
                        }, 2000);
                    }).catch(error => {
                        //this.override_user_id = -1

                    });
                }
            },
            convertToStockCard(){
                this.errors = []
                swal.fire({
                    title: "",
                    text: "Are you sure you want to create SC?",
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch('convertToStockCard',this.viewPackingListId).then(response => {
                            let data = response.data

                            if(data.error){
                                swal.fire("", data.error, "warning");
                                return false
                            }
                            this.resetFields()

                            swal.fire("", data.msg, "success");

                            this.packing_list_items_dialog = false
                            this.$emit('closeView', 'convertSuccess')
                        });
                    }
                });
            },
        },
        watch: {
            viewPackingListId: function(val){
                if(val != -1){

                    if(this.viewPackingListId != null && this.viewPackingListId > 0){

                        this.$store.dispatch('viewPackingList',this.viewPackingListId).then(async response => {
                            let data = this.GET_VIEW_PACKING_LIST

                            if(data.error){
                                swal.fire("", data.error, "warning");
                                return false
                            }

                            this.packing_list = data.packing_list
                            this.packingListItems = data.packing_list.packing_list_items
                            this.num_of_box = data.num_of_box

                            if(data.packing_list.discounts != ''){
                                this.discounts = JSON.parse(data.packing_list.discounts)
                                if(this.discounts != null){
                                    this.discounts = this.discounts[0].discounts
                                }
                            }

                            this.stock_card_id = data.stock_card_id
                            this.pending_stock_card = data.pending_stock_card

                            this.packing_list_items_dialog = true

                            await this.getItemTypes()
                        }).catch(error => {
                            if(error.response.status == 422){
                                this.errors = error.response.data.errors
                            }
                        });
                    }
                }
            },
            viewStockCardId: function(val){
                if(val != -1){
                    if(this.viewStockCardId != null && this.viewStockCardId > 0){

                        this.$store.dispatch('viewStockCard',this.viewStockCardId).then(response => {
                            let data = this.GET_VIEW_STOCK_CARD

                            if(data.error){
                                swal.fire("", data.error, "warning");
                                return false
                            }

                            this.stock_card            = data.stock_card
                            this.stockCardItems        = data.stock_card.stock_card_items

                            this.stock_card_print      = true
                        }).catch(error => {

                        });
                    }
                }
            },
            stock_card_print: function(val) {
                if (val) {
                    setTimeout(() => {
                        this.stock_card_print = false
                        this.viewStockCardId = -1
                    }, 1000);
                }
            }
        },
        computed: {
            ...mapGetters([
                'USER_ACCESS',
                'GET_ITEM_TYPES',
                'GET_VIEW_PACKING_LIST',
                'GET_VIEW_STOCK_CARD',
                'GET_CONFIRM_OVERRIDE_PRINT_PL',
            ]),
            no_of_items:function(){
                if(this.packingListItems != null && this.packingListItems.length > 0){
                    var count = 0;
                    this.packingListItems.forEach((item)=>{
                        count += item.quantity
                    })
                    return this.thousandSeprator(count)
                }else{
                    return 0
                }
            },
            no_of_warranties:function(){
                if(this.packingListItems != null && this.packingListItems.length > 0){
                    var count = 0;
                    this.packingListItems.forEach((item)=>{
                        if(item.view_item.type_id==1){
                            count += item.quantity
                        }
                    })
                    return this.thousandSeprator(count)
                }else{
                    return 0
                }
            }
        }

    };

</script>
<style>
    .v-avatar{
        margin: 15px 50px !important;
    }
    #client_modal_card{
        min-height: 160px !important;
    }
</style>
