<template>
  <div class="page-wrapper">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card-title>
            <h4 class="page-title ma-0">Item Journal</h4>
          </v-card-title>
        </v-col>
        <v-col cols="12" class="d-flex no-block align-items-center ma-2">
          <div class="btn-toolbar">
            <v-btn
              @click="
                resetSelections();
                toggleDialog(`warehouse`);
              "
            >
              <i class="fas fa-plus mr-1"></i>Create Item Journal
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <!-- ITEM SELECTION DIALOG -->
      <v-dialog
        v-model="itemSelection.dialog"
        scrollable
        persistent
        max-width="970px"
        style="z-index: 80"
      >
        <v-card>
          <v-row class="m-0">
            <v-col>
              <v-card-title>
                <span
                  class="text-lg font-semibold"
                  v-text="
                    `${
                      editedItemJournal.cycle_count
                        ? editedItemJournal.cycle_count
                            .cycle_count_form_number + ` Short/Excess Items`
                        : `No Cycle Count Reference Found`
                    }`
                  "
                ></span>
              </v-card-title>
            </v-col>

            <v-col cols="pull-right-10 p-2">
              <v-btn
                text
                icon
                color="gray"
                class="float-right"
                @click="toggleDialog(`itemSelection`)"
              >
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-progress-linear
            v-if="itemSelection.loading"
            indeterminate
            color="green"
          ></v-progress-linear>
          <v-row dense no-gutters tag="div">
            <v-col sm="4"> </v-col>
            <v-col sm="4"> </v-col>
            <v-col sm="4">
              <v-row
                dense
                no-gutters
                tag="form"
                class="d-flex"
                @submit.prevent="
                  resetItemSelection();
                  getItemSelection();
                "
              >
                <v-text-field
                  dense
                  autocomplete="off"
                  v-model="itemSelection.datatable.search"
                  label="Search Item Name/Model"
                  single-line
                  hide-details
                >
                </v-text-field>
                <v-icon
                  @click="
                    resetItemSelection();
                    getItemSelection();
                  "
                  >mdi-magnify</v-icon
                >
              </v-row>
            </v-col>
          </v-row>

          <v-card-text
            style="max-height: 460px; border: 1px solid #e0e0e0; padding: 0"
          >
            <v-data-table
              v-model="itemSelection.selected"
              :headers="itemSelection.datatable.headers"
              :items="itemSelection.data"
              :options.sync="itemSelection.datatable.pagination.options"
              :items-per-page="
                itemSelection.datatable.pagination.perPageDefault
              "
              :footer-props="{
                showFirstLastPage: true,
                itemsPerPageOptions: [5, 10, 25, 250, 500, 1000],
              }"
              :server-items-length="itemSelection.datatable.pagination.total"
              :single-select="false"
              item-key="id"
              show-select
              dense
              style="border: 1px solid #e0e0e0; margin: 0"
            >
              <template v-slot:item.status="{ item }">
                <span
                  :class="`badge ${Lodash.get(
                    ENUMS.CYCLE_COUNT_DETAIL_STATUS_CLASS,
                    item.status,
                    'badge-cycle-default'
                  )}`"
                  v-text="
                    Lodash.get(
                      ENUMS.CYCLE_COUNT_DETAIL_DESCRIPTION,
                      item.status,
                      'STATUS NOT FOUND'
                    )
                  "
                ></span>
              </template>
            </v-data-table>
          </v-card-text>

          <v-card-actions class="d-flex justify-end">
            <v-btn small @click="addSelectedItem(itemSelection.selected)">
              <i class="fas fa-plus mr-1"></i>
              Add Selected Item/s To Item Journal
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- WAREHOUSE PICKER DIALOG -->
      <v-dialog
        v-model="warehouse.dialog"
        scrollable
        persistent
        max-width="770px"
        style="z-index: 70"
      >
        <v-card>
          <v-progress-linear
            v-if="loading.state"
            indeterminate
            color="green"
          ></v-progress-linear>
          <v-row class="m-0">
            <v-col>
              <v-card-title>
                <span
                  class="text-lg font-semibold"
                  v-text="`Create Item Journal`"
                ></span>
              </v-card-title>
            </v-col>
            <v-col cols="pull-right-10 p-2">
              <v-btn
                text
                icon
                color="gray"
                class="float-right"
                @click="toggleDialog(`warehouse`)"
              >
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-card-text
            style="max-height: 460px; border: 1px solid #e0e0e0; padding: 0"
          >
            <v-row
              v-if="loading.state"
              tag="div"
              align="center"
              no-gutters
              class="px-2"
              v-text="loading.title"
            >
            </v-row>
            <v-row
              v-if="!loading.state"
              tag="div"
              align="center"
              no-gutters
              class="px-2"
            >
              <v-col class="d-flex pr-2" cols="6">
                <v-row dense no-gutters tag="div">
                  <v-col dense tag="div" sm="12" class="d-flex justify-start">
                    <div class="font-weight-medium" v-text="`Warehouse`"></div>
                  </v-col>
                  <v-col dense tag="div" sm="12">
                    <v-autocomplete
                      hide-details
                      class="item-journal-text-sm"
                      :items="warehouse.selection"
                      :loading="loading.warehouse_selection"
                      :disabled="loading.warehouse_selection"
                      v-model="warehouse.value"
                      dense
                      outlined
                      flat
                      auto-select-first
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>

              <v-col class="d-flex pr-2" cols="6">
                <v-row dense no-gutters tag="div">
                  <v-col dense tag="div" sm="12" class="d-flex justify-start">
                    <div
                      class="font-weight-medium"
                      v-text="`Cycle count that has items of not adequate`"
                    ></div>
                  </v-col>
                  <v-col dense tag="div" sm="12">
                    <v-autocomplete
                      :disabled="!warehouse.value || loading.state"
                      hide-details
                      class="item-journal-text-sm"
                      :items="warehouseCycleCount.selection"
                      v-model="warehouseCycleCount.value"
                      dense
                      outlined
                      flat
                      auto-select-first
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="d-flex justify-end">
            <v-btn
              small
              :disabled="
                !warehouse.value || !warehouseCycleCount.value || loading.state
              "
              @click="
                createItemJournalFor(warehouse.value, warehouseCycleCount.value)
              "
            >
              <i class="fas fa-plus mr-1"></i>
              Create
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- ITEM JOURNAL DIALOG -->
      <v-dialog
        v-model="editedItemJournal.dialog"
        scrollable
        persistent
        max-width="1366px"
        style="z-index: 70"
      >
        <div
          v-if="itemSelection.dialog"
          style="
            background-color: rgba(47, 47, 47, 0.5);
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 60;
          "
        ></div>
        <v-card>
          <v-progress-linear
            v-if="editedItemJournal.details.loading"
            indeterminate
            color="green"
          ></v-progress-linear>
          <v-row class="m-0">
            <v-col>
              <v-card-title>
                <span
                  class="text-lg font-semibold"
                  v-text="`${editedItemJournal.item_journal_form_number || ``}`"
                ></span>
              </v-card-title>
            </v-col>
            <v-col cols="pull-right-10 p-2">
              <v-btn
                text
                icon
                color="gray"
                class="float-right"
                @click="toggleDialog(`editedItemJournal`)"
              >
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-card-text
            style="max-height: 545px; border: 1px solid #e0e0e0; padding: 10px"
          >
            <v-row tag="div" align="center" no-gutters>
              <v-col dense cols="6">
                <span v-text="`Warehouse :`"></span>&nbsp;
                <v-progress-circular
                  v-if="loading.state"
                  size="16"
                  indeterminate
                  color="gray"
                ></v-progress-circular>
                <span
                  v-if="!loading.state"
                  class="font-weight-bold"
                  v-text="
                    `${
                      editedItemJournal.warehouse
                        ? editedItemJournal.warehouse.name
                        : ``
                    }`
                  "
                ></span>
              </v-col>
              <v-col dense cols="6">
                <span v-text="`Item Journal Date :`"></span>&nbsp;
                <v-progress-circular
                  v-if="loading.state"
                  size="16"
                  indeterminate
                  color="gray"
                ></v-progress-circular>
                <span
                  v-if="!loading.state"
                  class="font-weight-bold"
                  v-text="`${this.$dayjs(editedItemJournal.date).format(`lll`)}`"
                ></span>
              </v-col>
            </v-row>

            <v-row tag="div" align="center" no-gutters>
              <v-col dense cols="6">
                <span v-text="`Reference :`"></span>&nbsp;
                <v-progress-circular
                  v-if="loading.state"
                  size="16"
                  indeterminate
                  color="gray"
                ></v-progress-circular>
                <span
                  v-if="!loading.state"
                  class="font-weight-bold"
                  v-text="
                    `${
                      editedItemJournal.cycle_count
                        ? editedItemJournal.cycle_count.cycle_count_form_number
                        : ``
                    }`
                  "
                ></span>
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-row dense no-gutters tag="div">
              <v-col sm="4"> </v-col>
              <v-col sm="4"> </v-col>
              <v-col sm="4">
                <v-row
                  dense
                  no-gutters
                  tag="form"
                  class="d-flex"
                  @submit.prevent="getItemJournalDetails()"
                >
                  <v-text-field
                    dense
                    autocomplete="off"
                    v-model="editedItemJournal.details.datatable.search"
                    label="Search Item Name/Model"
                    single-line
                    hide-details
                  >
                  </v-text-field>
                  <v-icon @click="getItemJournalDetails()">mdi-magnify</v-icon>
                </v-row>
              </v-col>
            </v-row>
            <v-data-table
              :headers="editedItemJournal.details.datatable.headers"
              :items="editedItemJournal.details.datatable.data"
              :options.sync="
                editedItemJournal.details.datatable.pagination.options
              "
              :items-per-page="
                editedItemJournal.details.datatable.pagination.perPageDefault
              "
              :footer-props="{
                showFirstLastPage: true,
                itemsPerPageOptions: [5, 10, 25, 250, 500, 1000],
              }"
              :server-items-length="
                editedItemJournal.details.datatable.pagination.total
              "
              dense
              style="border: 1px solid #e0e0e0; margin: 0"
            >
              <template v-slot:item.system_inventory="{ item }">
                <span>{{ thousandSeprator(item.system_inventory) }}</span>
              </template>
              <template v-slot:item.actual_inventory="{ item }">
                <span>{{ thousandSeprator(item.actual_inventory) }}</span>
              </template>
              <template v-slot:item.cycle_count_status="{ item }">
                <span
                  :class="`badge ${Lodash.get(
                    ENUMS.CYCLE_COUNT_DETAIL_STATUS_CLASS,
                    item.cycle_count_status,
                    'badge-cycle-default'
                  )}`"
                  v-text="
                    Lodash.get(
                      ENUMS.CYCLE_COUNT_DETAIL_DESCRIPTION,
                      item.cycle_count_status,
                      'NOT FOUND'
                    )
                  "
                ></span>
              </template>
              <template v-slot:item.quantity="{ item }">
                <input
                  class="form-control float-right item-journal-font"
                  style="
                    text-align: right;
                    outline: none;
                    cursor: pointer;
                    margin: 1px 0px 1px !important;
                    height: 1.5rem !important;
                    padding: 0 5px !important;
                  "
                  min="0"
                  :disabled="
                    editedItemJournal.details.loading ||
                    editedItemJournal.status === 2
                  "
                  v-model="item.quantity"
                  @click="$event.target.focus()"
                  @focus="$event.target.select()"
                />
              </template>
              <template v-slot:item.subtract="{ item }">
                <select
                  :disabled="
                    editedItemJournal.details.loading ||
                    editedItemJournal.status === 2
                  "
                  v-model="item.subtract"
                  class="form-control item-journal-font"
                  style="
                    cursor: pointer;
                    margin: 1px 0px 1px !important;
                    height: 1.5rem !important;
                    padding: 0 !important;
                  "
                >
                  <option :value="true" v-text="`TRUE`"></option>
                  <option :value="false" v-text="`FALSE`"></option>
                </select>
              </template>
              <template v-slot:item.remarks="{ item }">
                <input
                  :disabled="
                    editedItemJournal.details.loading ||
                    editedItemJournal.status === 2
                  "
                  @click="$event.target.focus()"
                  @focus="$event.target.select()"
                  class="form-control item-journal-font"
                  style="
                    text-align: left;
                    outline: none;
                    margin: 1px 0px 1px !important;
                    height: 1.5rem !important;
                    padding: 0 5px !important;
                  "
                  type="text"
                  v-model="item.remarks"
                />
              </template>
              <template
                v-if="
                  [0, 1].indexOf(editedItemJournal.status) >= 0 &&
                  !editedItemJournal.details.loading
                "
                v-slot:item.action="{ item }"
              >
                <v-icon
                  small
                  color="red"
                  @click="removeItemJournalDetails(item)"
                  >mdi-delete</v-icon
                >
              </template>
              <template v-slot:header.cycle_count_status>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on"> STATUS </span>
                  </template>
                  <span>CYCLE COUNT STATUS</span>
                </v-tooltip>
              </template>
              <template v-slot:header.actual_inventory>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on"> AI </span>
                  </template>
                  <span>ACTUAL INVENTORY</span>
                </v-tooltip>
              </template>
              <template v-slot:header.system_inventory>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on"> SI </span>
                  </template>
                  <span>SYSTEM INVENTORY</span>
                </v-tooltip>
              </template>
            </v-data-table>
            <!-- <v-row v-if="[0, 1].indexOf(editedItemJournal.status) >= 0" dense no-gutters tag="div" class="pt-1">
                            <v-btn small @click="pickItems()" :disabled="editedItemJournal.details.loading" class="mr-1"><i class="fas fa-plus mr-1"></i>Add Item/s</v-btn>
                            <v-btn small @click="removeItemJournalDetails()" :disabled="editedItemJournal.details.loading" class="mr-1"><i class="fas fa-trash mr-1"></i>Clear Item/s</v-btn>
                        </v-row> -->
          </v-card-text>

          <v-card-actions class="d-flex justify-space-between">
            <div>
              <v-btn
                small
                v-if="editedItemJournal.status === 0"
                :disabled="editedItemJournal.details.loading"
                @click="approve(editedItemJournal)"
                class="d-flex items-center ml-1"
              >
                <v-icon small color="white">mdi-clipboard-check</v-icon
                ><span>&nbsp;Approve</span>
              </v-btn>
              <v-btn
                small
                v-if="[1].indexOf(editedItemJournal.status) >= 0"
                :disabled="
                  editedItemJournal.details.loading ||
                  !editedItemJournal.details.datatable.data.length
                "
                @click="complete()"
                class="d-flex items-center ml-1"
              >
                <v-icon small color="white">mdi-check</v-icon
                ><span>&nbsp;Complete Item Journal</span>
              </v-btn>
            </div>
            <div>
              <v-btn
                small
                v-if="[0, 1].indexOf(editedItemJournal.status) >= 0"
                :disabled="
                  editedItemJournal.details.loading ||
                  !editedItemJournal.details.datatable.data.length
                "
                @click="saveCurrentPage()"
                class="d-flex items-center ml-1"
              >
                <v-icon small color="white">mdi-save</v-icon
                ><span>&nbsp;Save Current Page</span>
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- ITEM JOURNAL LIST -->
      <v-row no-gutters dense tag="div" class="mt-3">
        <v-card class="w-100">
          <v-progress-linear
            v-if="loading.state"
            indeterminate
            color="green"
          ></v-progress-linear>
          <v-row dense no-gutters tag="div" class="px-2 pt-2">
            <v-col sm="3"> </v-col>
            <v-col sm="3"> </v-col>
            <v-col sm="3">
              <v-autocomplete
                v-model="filters.status.selected"
                :items="filters.status.selection"
                dense
                hide-details
                multiple
                outlined
                auto-select-first
                placeholder="Filter Status"
                class="text-md text-gray-700 mr-2"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip x-small v-if="index <= 1">
                    <span v-text="item.text"></span>
                  </v-chip>
                  <span
                    v-if="index === 2"
                    class="caption"
                    v-text="
                      `${filters.status.selected.length - 2} more selected...`
                    "
                  ></span>
                </template>
                <template v-slot:prepend-item>
                  <v-row
                    align="center"
                    dense
                    no-gutters
                    tag="div"
                    class="d-flex flex-grow px-3 pb-1"
                  >
                    <div>
                      <v-icon
                        @click="toggleFilterSelection(filters.status)"
                        :color="
                          filters.status.selected.length > 0
                            ? 'indigo darken-4'
                            : ''
                        "
                      >
                        {{
                          filterSelectionIcon(
                            filters.status.selection,
                            filters.status.selected
                          )
                        }}
                      </v-icon>
                    </div>
                    <div>
                      <input
                        @input="searchFilterSelection(filters.status)"
                        v-model="filters.status.search"
                        type="text"
                        placeholder="Search Status"
                        style="
                          text-align: left;
                          font-size: 0.8rem !important;
                          outline: none;
                          height: 1.5rem !important;
                        "
                        class="form-control"
                      />
                    </div>
                  </v-row>
                  <v-divider class="m-0 p-0"></v-divider>
                </template>
                <template v-slot:item="{ active, item, attrs, on }">
                  <v-list-item
                    dense
                    v-on="on"
                    v-bind="attrs"
                    #default="{ active }"
                  >
                    <v-row dense no-gutters align="center">
                      <input class="mr-1" type="checkbox" :checked="active" />
                      <div class="caption" v-text="item.text"></div>
                    </v-row>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col sm="3" class="mt-2">
              <v-row
                dense
                no-gutters
                tag="form"
                class="d-flex"
                @submit.prevent="list()"
              >
                <v-text-field
                  dense
                  autocomplete="off"
                  v-model="itemJournal.datatable.search"
                  label="Search IJF Number"
                  single-line
                  hide-details
                >
                </v-text-field>
                <v-icon @click="list()">mdi-magnify</v-icon>
              </v-row>
            </v-col>
          </v-row>

          <v-row no-gutters dense tag="div" class="mt-2 px-2">
            <v-col>
              <v-data-table
                :headers="itemJournal.datatable.headers"
                :items="itemJournal.datatable.data"
                :options.sync="itemJournal.datatable.pagination.options"
                :items-per-page="10"
                :footer-props="{
                  showFirstLastPage: true,
                  itemsPerPageOptions: [5, 10, 25],
                }"
                :server-items-length="itemJournal.datatable.pagination.total"
              >
                <template v-slot:item.status="{ item }">
                  <span
                    :class="`badge ${Lodash.get(
                      ENUMS.ITEM_JOURNAL_STATUS_CLASS,
                      item.status,
                      'badge-cycle-default'
                    )}`"
                    v-text="
                      Lodash.get(
                        ENUMS.ITEM_JOURNAL_STATUS_DESCRIPTION,
                        item.status,
                        'STATUS NOT FOUND'
                      )
                    "
                  >
                  </span>
                </template>
                <template v-slot:item.action="{ item }">
                  <span class="text-nowrap">
                    <v-btn text icon color="orange">
                      <v-icon class="btn-action" small @click="view(item)"
                        >mdi-eye</v-icon
                      >
                    </v-btn>
                    <v-btn text icon color="red">
                      <v-icon class="btn-action" small @click="destroy(item)"
                        >mdi-delete</v-icon
                      >
                    </v-btn>
                  </span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
import { mapGetters } from "vuex";
import swal from "sweetalert2";
export default {
  mixins: [SharedFunctionsComponentVue],
  name: "ItemJournalComponent",

  async mounted() {
    let that = this;

    await that.getWarehouses();
    await that.list();
    this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
    that.filters.status.selection = that.filters.status.data;
  },
  computed: {
    ...mapGetters([
      "GET_ALL_WAREHOUSES",
      "GET_ITEMS_NOT_ADEQUATE",
      "GET_ITEM_JOURNAL",
      "GET_POST_ITEM_JOURNAL",
      "GET_DETAILS_ITEM_JOURNAL",
      "GET_CYCLE_COUNT_DETAILS",
    ]),
  },
  data() {
    return {
      loading: {
        title: "Please wait",
        state: false,
        warehouse_selection: true,
      },

      warehouse: {
        dialog: false,
        selection: [
          {
            value: null,
            text: "NONE SELECTED",
          },
        ],
        value: null,
      },

      warehouseCycleCount: {
        selection: [
          {
            value: null,
            text: "NONE SELECTED",
          },
        ],
        value: null,
      },

      itemSelection: {
        loading: false,
        dialog: false,
        datatable: {
          search: "",
          headers: [
            { text: "MODEL", value: "item.model", sortable: false },
            { text: "NAME", value: "item.name", sortable: false },
            { text: "TYPE", value: "item.item_type_text", sortable: false },
            { text: "UOM", value: "item.item_uom_text", sortable: false },
            { text: "CYCLE COUNT STATUS", value: "status", sortable: false },
          ],
          pagination: {
            options: {},
            total: 0,
            perPageDefault: 10,
          },
        },
        data: [],
        selected: [],
      },

      editedIndex: -1,
      editedItemJournal: {
        id: null,
        cycle_count_id: null,
        cycle_count: null,
        dialog: false,
        item_journal_form_number: null,
        warehouse: null,
        status: null,
        date: null,
        details: {
          loading: false,
          datatable: {
            search: "",
            headers: [
              { text: "MODEL", value: "item.model", sortable: false },
              { text: "NAME", value: "item.name", sortable: false },
              { text: "TYPE", value: "item.item_type_text", sortable: false },
              { text: "UOM", value: "item.item_uom_text", sortable: false },
              {
                text: "CYCLE COUNT STATUS",
                value: "cycle_count_status",
                sortable: false,
              },
              { text: "SI", value: "system_inventory", sortable: false },
              {
                text: "ACTUAL INVENTORY",
                value: "actual_inventory",
                sortable: false,
              },
              {
                text: "QUANTITY",
                value: "quantity",
                align: "right",
                sortable: false,
              },
              // {text: 'SUBTRACT', value: 'subtract', align: 'left', sortable: false},
              {
                text: "REMARKS",
                value: "remarks",
                align: "left",
                sortable: false,
              },
              {
                text: "ACTIONS",
                align: "center",
                value: "action",
                sortable: false,
              },
            ],
            data: [],
            pagination: {
              options: {},
              total: 0,
              perPageDefault: 10,
            },
          },
        },
      },

      itemJournal: {
        datatable: {
          search: "",
          headers: [
            {
              text: "IJF NUMBER",
              align: "left",
              value: "item_journal_form_number",
              sortable: false,
            },
            {
              text: "REFERENCE",
              align: "left",
              value: "cycle_count.cycle_count_form_number",
              sortable: false,
            },
            {
              text: "WAREHOUSE",
              align: "left",
              value: "warehouse.name",
              sortable: false,
            },
            { text: "DATE", align: "left", value: "date", sortable: false },
            { text: "STATUS", align: "left", value: "status", sortable: false },
            {
              text: "CREATED BY",
              align: "left",
              value: "user.name",
              sortable: false,
            },
            {
              text: "ACTIONS",
              align: "center",
              value: "action",
              sortable: false,
            },
          ],
          data: [],
          pagination: {
            options: {},
            total: 0,
            perPageDefault: 10,
          },
        },
        id: "",
      },

      filters: {
        status: {
          search: "",
          data: [
            { text: "FOR APPROVAL", value: 0 },
            { text: "APPROVED", value: 1 },
            { text: "COMPLETED", value: 2 },
          ],
          selection: [],
          selected: [0, 1],
        },
      },

      errors: [],
    };
  },

  methods: {
    selectedAllFilterModuleOptions(selection, selected) {
      return selected.length === selection.length;
    },

    selectedSomeFilterModuleOptions(selection, selected) {
      return (
        selected.length > 0 &&
        !this.selectedAllFilterModuleOptions(selection, selected)
      );
    },

    filterSelectionIcon(selection, selected) {
      if (this.selectedAllFilterModuleOptions(selection, selected))
        return "mdi-close-box";
      if (this.selectedSomeFilterModuleOptions(selection, selected))
        return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },

    toggleFilterSelection(filter) {
      this.$nextTick(() => {
        if (
          this.selectedAllFilterModuleOptions(filter.selection, filter.selected)
        ) {
          filter.selected = [];
        } else {
          filter.selected = filter.selection.map((filter) => filter.value);
        }
      });
    },

    searchFilterSelection(filter) {
      if (!filter.search) {
        filter.selection = filter.data;
      }

      filter.selection = filter.data.filter((data) => {
        return (
          data.text.toLowerCase().indexOf(filter.search.toLowerCase()) > -1
        );
      });
    },

    async getWarehouses() {
      let payload = {
        no_virtual: true,
      };

      await this.$store
        .dispatch("getAllWarehouses", payload)
        .then((response) => {
          this.warehouse.selection = [
            {
              value: null,
              text: "NONE SELECTED",
            },
          ].concat(
            this.GET_ALL_WAREHOUSES.reduce((selection, warehouse) => {
              selection.push({
                value: warehouse.value,
                text: warehouse.name,
              });

              return selection;
            }, [])
          );
        });
      this.loading.warehouse_selection = false;
    },

    getWarehouseCycleCountThatHasItemsOfNotAdequate() {
      let that = this;

      if (_.isNull(that.warehouse.value)) {
        return false;
      }

      that.toggleLoading();

      that.warehouseCycleCount.value = null;
      let payload = {
        warehouse_id: [that.warehouse.value],
      };
      this.$store.dispatch("itemsNotAdequate", payload).then((response) => {
        that.warehouseCycleCount.selection = [
          {
            value: null,
            text: "NONE SELECTED",
          },
        ].concat(
          this.GET_ITEMS_NOT_ADEQUATE.cycle_counts.reduce(
            (selection, cycleCount) => {
              selection.push({
                value: cycleCount.id,
                text: cycleCount.cycle_count_form_number,
              });

              return selection;
            },
            []
          )
        );

        that.toggleLoading();
      });
    },

    toggleLoading() {
      this.loading.state = !this.loading.state;
    },

    resetSelections() {
      this.warehouse.value = null;
      this.warehouseCycleCount.value = null;
    },

    toggleDialog(dialogable = null) {
      let that = this;

      if (
        that.loading.state ||
        !dialogable ||
        that.editedItemJournal.details.loading
      ) {
        return false;
      }

      dialogable = dialogable.split(".");

      if (dialogable.length === 1) {
        that[dialogable].dialog = !that[dialogable].dialog;
      } else {
        let path = that;

        dialogable.forEach((i) => {
          path = path[i];
        });

        path.dialog = !path.dialog;
      }
    },

    resetEdited() {
      let that = this;

      that.editedIndex = -1;

      that.editedItemJournal = Object.assign(
        {},
        {
          id: null,
          cycle_count_id: null,
          cycle_count: null,
          dialog: that.editedItemJournal.dialog,
          item_journal_form_number: null,
          warehouse: null,
          status: null,
          date: null,
          details: {
            loading: false,
            datatable: {
              search: "",
              headers: that.editedItemJournal.details.datatable.headers,
              data: [],
              pagination: {
                options: {},
                total: 0,
                perPageDefault: 10,
              },
            },
          },
        }
      );
    },

    list(page = 1, wait = false) {
      let that = this;

      that.itemJournal.datatable.pagination.options.page = page;

      if (that.loading.state) {
        return false;
      }

      that.toggleLoading();
      let payload = {
        page: page,
        itemsPerPage:
          that.itemJournal.datatable.pagination.options.itemsPerPage ||
          that.itemJournal.datatable.pagination.perPageDefault,
        ijfNumber: that.itemJournal.datatable.search,
        status: that.filters.status.selected,
        id: that.itemJournal.id,
      };

      let promise = new Promise((resolve) => {
        that.$store.dispatch("getItemJournal", payload).then((response) => {
          resolve(response);
        });
      });

      if (wait) {
        that.toggleLoading();
        return promise;
      } else {
        promise.then((response) => {
          that.itemJournal.datatable.data =
            that.GET_ITEM_JOURNAL.item_journals.data;
          that.itemJournal.datatable.pagination.total =
            that.GET_ITEM_JOURNAL.item_journals.total;
          that.toggleLoading();
        });
      }
    },

    async createItemJournalFor(warehouseId, cycleCountId) {
      let that = this;

      that.toggleLoading();

      let exist = false;
      let payload = {
        id: [cycleCountId],
        status: [0, 1, 2],
      };
      await that.$store.dispatch("getItemJournal", payload).then((response) => {
        exist = that.GET_ITEM_JOURNAL.item_journals.total == 0 ? false : true;
      });

      if (exist) {
        swal.fire("Warning", "Cycle Count has Existing journal", "warning");
        that.toggleLoading();
        return false;
      }
      let payload_post = {
        cycle_count_id: cycleCountId,
        warehouse_id: warehouseId,
      };

      await new Promise((resolve, reject) => {
        this.$store
          .dispatch("postItemJournal", payload_post)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      })
        .then(async (resolved) => {
          that.toggleLoading();
          that.toggleDialog(`warehouse`);
          that.resetSelections();

          let createdItemJournal = resolved.data.item_journal;

          that.itemJournal.datatable.search =
            createdItemJournal.item_journal_form_number.replace(" ", "");
          that.filters.status.selected = [createdItemJournal.status];

          await that.list(1, true).then((response) => {
            that.itemJournal.datatable.data = response.data.item_journals.data;
            that.itemJournal.datatable.pagination.total =
              response.data.item_journals.total;

            that.view(createdItemJournal);
          });
        })
        .catch((error) => {
          that.toggleLoading();
          that.toggleDialog(`warehouse`);
          that.resetSelections();
          that.list();
          that.alertWarnings(
            error.response.data.message,
            error.response.data.errors
          );
        });
      that.addSelectedItem(1);
    },

    view(itemJournal) {
      let that = this;

      that.resetEdited();
      let view = _.find(
        that.itemJournal.datatable.data,
        (item) => itemJournal.id === item.id
      );

      that.editedIndex = that.itemJournal.datatable.data.indexOf(view);
      that.toggleDialog(`editedItemJournal`);
      that.editedItemJournal = Object.assign(
        {},
        {
          ...that.editedItemJournal,
          id: view.id,
          cycle_count_id: view.cycle_count_id,
          cycle_count: view.cycle_count,
          item_journal_form_number: view.item_journal_form_number,
          warehouse: view.warehouse,
          status: view.status,
          date: view.date,
        }
      );

      that.getItemJournalDetails(1);
    },

    approve(itemJournal) {
      swal
        .fire({
          title: `Confirm Item Journal Approve : ${itemJournal.item_journal_form_number}`,
          text: ``,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then((confirm) => {
          if (confirm) {
            let that = this;

            if (that.loading.state || that.editedItemJournal.details.loading) {
              return false;
            }

            that.editedItemJournal.details.loading = true;
            this.$store
              .dispatch("approveItemJournal", itemJournal.id)
              .then((response) => {
                swal.fire({
                  title: `Item Journal Approved : ${itemJournal.item_journal_form_number}`,
                  text: ``,
                });
                that.editedItemJournal.details.loading = false;
                that.toggleDialog(`editedItemJournal`);
                that.resetEdited();
                that.list();
              })
              .catch((error) => {
                that.editedItemJournal.details.loading = false;
                that.alertWarnings(
                  error.response.data.message,
                  error.response.data.errors
                );
              });
          }
        });
    },

    complete() {
      let that = this;

      swal
        .fire({
          title: `Complete : ${that.editedItemJournal.item_journal_form_number}`,
          text: ``,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then((confirm) => {
          if (confirm) {
            that.editedItemJournal.details.loading = true;

            this.$store
              .dispatch("completeItemJournal", that.editedItemJournal.id)
              .then((response) => {
                swal.fire({
                  title: `Item Journal Completed : ${response.data.item_journal.item_journal_form_number}`,
                  text: `${response.data.item_journal.warehouse.name} Inventory Adjusted.`,
                });

                that.editedItemJournal.details.loading = false;
                that.toggleDialog(`editedItemJournal`);
                that.list();
              })
              .catch((error) => {
                that.editedItemJournal.details.loading = false;
                that.alertWarnings(
                  error.response.data.message,
                  error.response.data.errors
                );
              });
          }
        });
    },

    destroy(itemJournal) {
      swal
        .fire({
          title: "Confirm Delete",
          text: `${itemJournal.item_journal_form_number}`,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then((confirm) => {
          if (confirm) {
            let that = this;

            if (that.loading.state) {
              return false;
            }

            that.toggleLoading();
            this.$store
              .dispatch("destroyItemJournal", itemJournal.id)
              .then((response) => {
                swal.fire({
                  title: `Item Journal Deleted : ${itemJournal.item_journal_form_number}`,
                });
                that.toggleLoading();
                that.errors = [];
                that.list();
              })
              .catch((error) => {
                that.toggleLoading();
                that.errors = error.response.data.errors;
                that.alertWarnings(error.response.data.message, that.errors);
              });
          }
        });
    },

    getItemJournalDetails(page = 1) {
      let that = this;

      that.editedItemJournal.details.datatable.pagination.options.page = page;

      if (
        that.editedItemJournal.details.loading ||
        that.editedItemJournal.id === null
      ) {
        return false;
      }

      that.editedItemJournal.details.loading = true;
      let payload = {
        id: that.editedItemJournal.id,
        params: {
          page: page,
          itemsPerPage:
            that.editedItemJournal.details.datatable.pagination.options
              .itemsPerPage ||
            that.editedItemJournal.details.datatable.pagination.perPageDefault,
          search: that.editedItemJournal.details.datatable.search,
        },
      };

      this.$store.dispatch("detailsItemJournal", payload).then((response) => {
        that.editedItemJournal.details.datatable.data =
          this.GET_DETAILS_ITEM_JOURNAL.item_journal_details.data;
        that.editedItemJournal.details.datatable.pagination.total =
          this.GET_DETAILS_ITEM_JOURNAL.item_journal_details.total;
        that.editedItemJournal.details.loading = false;
        that.editedItemJournal.details.datatable.data.forEach(
          (element, index) => {
            this.GET_DETAILS_ITEM_JOURNAL.cycle_count_details.forEach((e) => {
              if (element.item_id == e.item_id) {
                element.actual_inventory = e.actual_inventory;
                element.system_inventory = e.system_inventory;
                element.variance =
                  parseInt(e.actual_inventory) - parseInt(e.system_inventory);
              }
            });
          }
        );
      });
    },

    removeItemJournalDetails(detail = null) {
      let that = this;

      if (
        that.editedItemJournal.details.loading ||
        that.editedItemJournal.id === null
      ) {
        return false;
      }

      let warningText = detail
        ? `Remove ${detail.item.name}`
        : `Remove ${that.editedItemJournal.item_journal_form_number} items.`;

      swal
        .fire({
          title: `Confirm`,
          text: warningText,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then(async (confirm) => {
          if (confirm) {
            await new Promise((resolve, reject) => {
              this.$store
                .dispatch("canEditItemJournal")
                .then((response) => {
                  resolve(response);
                })
                .catch((error) => reject(error));
            })
              .then((resolved) => {
                that.editedItemJournal.details.loading = true;
                let payload = {
                  id: that.editedItemJournal.id,
                  params: {
                    id: detail ? [detail.id] : [],
                  },
                };

                this.$store
                  .dispatch("clearDetailsItemJournal", payload)
                  .then((response) => {
                    that.editedItemJournal.details.loading = false;
                    that.getItemJournalDetails();
                  });
              })
              .catch((error) => {
                that.alertWarnings(
                  error.response.data.message,
                  error.response.data.errors
                );
              });
          }
        });
    },

    async saveCurrentPage() {
      let that = this;
      let services = [];

      that.editedItemJournal.details.loading = true;

      await new Promise((resolve, reject) => {
        this.$store
          .dispatch("canEditItemJournal")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      })
        .then((resolved) => {
          that.editedItemJournal.details.datatable.data.forEach((detail) => {
            services.push(
              new Promise((resolve, reject) => {
                let payload = {
                  id: detail.id,
                  params: {
                    quantity: detail.quantity,
                    subtract: detail.subtract,
                    remarks: detail.remarks,
                    item: {
                      model: detail.item.model,
                      name: detail.item.name,
                    },
                  },
                };
                this.$store
                  .dispatch("saveDetailsItemJournal", payload)
                  .then((response) => {
                    resolve(response);
                  })
                  .catch((error) => {
                    reject(error);
                  });
              })
            );
          });
        })
        .catch((error) => {
          that.alertWarnings(
            error.response.data.message,
            error.response.data.errors
          );
        });

      if (services.length) {
        await Promise.allSettled(services).then((values) => {
          let failed = values
            .filter((result) => result.status === "rejected")
            .map((result) => result.reason);

          if (failed.length) {
            //Get First Failed
            let error = failed[0];

            that.alertWarnings(
              error.response.data.message,
              error.response.data.errors
            );
          } else {
            swal.fire({
              title: `Current Page Saved`,
              text: `Success`,
              icon: "info",
              dangerMode: false,
            });
          }
        });

        that.editedItemJournal.details.loading = false;
      } else {
        that.editedItemJournal.details.loading = false;
      }
    },

    pickItems() {
      let that = this;

      that.resetItemSelection();
      that.toggleDialog(`itemSelection`);
      that.getItemSelection();
    },

    resetItemSelection() {
      let that = this;
      that.itemSelection.data = [];
      that.itemSelection.selected = [];
    },

    getItemSelection(page = 1) {
      let that = this;

      that.itemSelection.datatable.pagination.options.page = page;

      if (
        that.itemSelection.loading ||
        _.isNull(that.editedItemJournal.cycle_count_id)
      ) {
        return false;
      }

      that.itemSelection.loading = true;
      let payload = {
        id: that.editedItemJournal.cycle_count_id,
        params: {
          page: page,
          itemsPerPage:
            that.itemSelection.datatable.pagination.options.itemsPerPage ||
            that.itemSelection.datatable.pagination.perPageDefault,
          search: that.itemSelection.datatable.search,
          status: [0, 1],
        },
      };

      this.$store
        .dispatch("showCycleCountDetails", payload)
        .then((response) => {
          that.itemSelection.data =
            this.GET_CYCLE_COUNT_DETAILS.cycle_count_details.data;
          that.itemSelection.datatable.pagination.total =
            this.GET_CYCLE_COUNT_DETAILS.cycle_count_details.total;
          that.itemSelection.loading = false;
        });
    },

    async addSelectedItem(selected) {
      let that = this;
      let payload = {
        id: that.editedItemJournal.cycle_count_id,
        params: {},
      };

      await this.$store
        .dispatch("showCycleCountDetails", payload)
        .then((response) => {
          selected = this.GET_CYCLE_COUNT_DETAILS.cycle_count_details.data.map(
            (e) => {
              if (e.status != 1) {
                return { item_id: e.item_id, cycle_count_status: e.status };
              }
            }
          );
        });

      // [{item_id:1,cycle_count_status:0}]
      selected = _.compact(selected);
      that.editedItemJournal.details.loading = true;

      await new Promise((resolve, reject) => {
        this.$store
          .dispatch("canEditItemJournal")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      })
        .then((resolved) => {
          let payload = {
            id: that.editedItemJournal.id,
            params: {
              selected: selected,
            },
          };
          this.$store
            .dispatch("updateItemJournal", payload)
            .then((response) => {
              that.editedItemJournal.details.loading = false;
              that.getItemJournalDetails();
            })
            .catch((error) => {
              that.editedItemJournal.details.loading = false;
              that.alertWarnings(
                error.response.data.message,
                error.response.data.errors
              );
            });

          that.itemSelection.dialog = false;
          that.resetItemSelection();
        })
        .catch((error) => {
          that.editedItemJournal.details.loading = false;
          that.itemSelection.dialog = false;
          that.resetItemSelection();
          that.alertWarnings(
            error.response.data.message,
            error.response.data.errors
          );
        });
    },

    alertWarnings(message, errors) {
      let that = this;
      let errorMessages = [];

      Object.keys(errors).forEach((key) => {
        if (key === "authorization") {
          //Close Recent Dialog || All Dialog
        }

        errors[key].forEach((message) => {
          errorMessages.push(`- ${message}`);
        });
      });

      swal.fire({
        title: message,
        text: errorMessages.join("\n"),
        icon: "warning",
        dangerMode: true,
      });
    },

    pressQuantity(event) {
      let charCode = event.which ? event.which : event.keyCode;

      if (charCode < 48 || charCode > 57) {
        event.preventDefault();
      } else {
        return true;
      }
    },

    blurQuantity(list, index, key) {
      let quantity = list[index][key];

      list[index][key] =
        !isNaN(quantity) && quantity && Math.abs(parseInt(quantity)) > 0
          ? Math.abs(parseInt(quantity))
          : 0;
    },
  },

  watch: {
    "itemJournal.datatable.pagination.options": {
      handler() {
        this.list(this.itemJournal.datatable.pagination.options.page);
      },
      deep: true,
    },
    "editedItemJournal.details.datatable.pagination.options": {
      handler() {
        this.getItemJournalDetails(
          this.editedItemJournal.details.datatable.pagination.options.page
        );
      },
      deep: true,
    },
    "itemSelection.datatable.pagination.options": {
      handler() {
        let that = this;
        that.itemSelection.data = [];
        that.getItemSelection(
          that.itemSelection.datatable.pagination.options.page
        );
      },
      deep: true,
    },
    "warehouse.value": {
      handler() {
        let that = this;
        that.getWarehouseCycleCountThatHasItemsOfNotAdequate();
      },
    },
  },
};
</script>

<style scoped>
.item-journal-font {
  font-size: 0.8rem !important;
}

.item-journal-text-sm {
  font-size: 1rem !important;
  line-height: 1rem;
}
</style>
