<template>
    <v-form ref="form" v-model="valid">
        <!-- Table Functions -->
        <v-card class="px-3 rounded-0">
            <v-card-subtitle>
                <v-row>
                    <v-col cols="3">
                        <v-btn v-if="userAccess.includes('create')" class="m-3" @click="$store.commit('SERVICE_PAYMENT_REQUEST_ACTION', 'create'); toggleDialog('open')">
                            <v-icon>mdi-plus</v-icon>Create
                        </v-btn>
                    </v-col>
                        <v-spacer/>
                    <v-col cols="2" class="text-right my-auto pl-2">
                        <v-btn v-if="userAccess.includes('export')" :loading="exportLoad" :disabled="disableExport" @click="getAllTransactions('1')">
                            EXPORT
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="ml-0">
                    <v-col lg="2">
                        <v-autocomplete
                            v-model="filters.types"
                            :items="GET_SERVICE_PAYMENT_REQUEST_TYPE"
                            label="Types"
                            no-data-text="No Types Found!"
                            hide-details="auto"
                            outlined
                            dense
                            auto-select-first
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col lg="2">
                        <v-autocomplete
                            v-model="filters.vendor_id"
                            :items="GET_ADMIN_SUPPLIER_SELECTION"
                            label="Vendor"
                            outlined
                            dense
                            auto-select-first
                            hide-details
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col lg="2">
                        <v-autocomplete
                            v-model="filters.company_id"
                            :items="GET_PV_COMPANIES_SELECTION"
                            label="Company"
                            outlined
                            dense
                            auto-select-first
                            hide-details
                            clearable
                            @change="insertStore(1)"
                        ></v-autocomplete>
                    </v-col>
                    <v-col lg="2">
                        <v-autocomplete
                            v-model="filters.store_branches_id"
                            :items="branchItemsForFilter"
                            label="Store Branch"
                            outlined
                            dense
                            auto-select-first
                            hide-details
                            clearable
                            no-data-text="No Stores Found!"
                            @change="insertCompany(filters.store_branches_id, 1)"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="1" class="pl-2">
                        <v-btn @click="getAllTransactions()">
                            FILTER
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="ml-0">
                    <v-col v-if="byPassFilter" lg="2">
                        <v-autocomplete
                            v-model="filters.departments"
                            :items="GET_DEPARTMENT_ACCOUNTING_LOAD"
                            label="Department/s"
                            outlined
                            dense
                            multiple
                            small-chips
                            deletable-chips
                            auto-select-first
                            hide-details
                            clearable
                            no-data-text="No Departments Found!"
                        ></v-autocomplete>
                    </v-col>
                    <v-col lg="2">
                        <v-autocomplete
                            v-model="filters.prepared_by"
                            :items="GET_PREPARED_BY"
                            label="Prepared By"
                            outlined
                            dense
                            auto-select-first
                            clearable
                            no-data-text="No Users Found!"
                        ></v-autocomplete>
                    </v-col>
                    <v-col lg="2">
                        <v-menu
                            v-model="menu.date_from"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_from"
                                    prepend-inner-icon="mdi-calendar"
                                    outlined
                                    dense
                                    readonly
                                    v-on="on"
                                    style="display: block"
                                    background-color="white"
                                    label="Date From"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_from" @input="menu.date_from = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col lg="2">
                        <v-menu
                            v-model="menu.date_to"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_to"
                                    prepend-inner-icon="mdi-calendar"
                                    outlined
                                    dense
                                    readonly
                                    v-on="on"
                                    style="display: block"
                                    background-color="white"
                                    label="Date To"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_to" @input="menu.date_to = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
                <v-row class="ml-0">
                    <v-col lg="2">
                        <v-autocomplete
                            v-model="filters.company_classification_id"
                            :items="GET_COMPANY_CLASSIFICATION_SELECTION"
                            label="Classification"
                            outlined
                            dense
                        ></v-autocomplete>
                    </v-col>
                    <v-spacer/>
                    <v-col cols="3" class="pull-right">
                        <v-text-field
                            v-model="search"
                            placeholder="Search by Transaction #/ Remarks"
                            append-icon="mdi-magnify"
                            @keydown.enter="getAllTransactions()"
                            @click:append="getAllTransactions()"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card-subtitle>
            <!-- Table -->
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-data-table
                            :headers="mainHeaders"
                            :items="mainItems"
                            :options.sync="options"
                            :server-items-length="totalItems"
                            :items-per-page="10"
                            :loading="tableLoad"
                            :footer-props="{
                                itemsPerPageOptions: itemsPerPageOptions
                            }"
                            dense
                        >
                            <!-- <template v-slot:[`item.service_payment_request_type_id`]="{ item }">
                                <span>
                                    {{ GET_SERVICE_PAYMENT_REQUEST_TYPE.find(e=>e.value == item.service_payment_request_type_id)?.text }}
                                </span>
                            </template> -->
                            <!-- <template v-slot:[`item.company_name`]="{ item }">
                                <span>
                                    {{ truncateText(item.company_name, 10) }}
                                </span>
                            </template>
                            <template v-slot:[`item.store_branch_name`]="{ item }">
                                <span>
                                    {{ truncateText(item.store_branch_name, 10) }}
                                </span>
                            </template>
                            <template v-slot:[`item.vendor_name`]="{ item }">
                                <span>
                                    {{ truncateText(item.vendor_name, 10) }}
                                </span>
                            </template> -->
                            <template v-slot:[`item.total_amount`]="{ item }">
                                <span>
                                    &#x20b1;{{ item.total_amount | currency }}
                                </span>
                            </template>
                            <template v-slot:[`item.created_at`]="{ item }">
                                <span>{{ item.created_at | formatDate }}</span>
                            </template>
                            <!-- <template v-slot:[`item.remarks`]="{ item }">
                                <span>{{ truncateText(item.remarks, 10) }}</span>
                            </template> -->
                            <template v-slot:[`item.status`]="{ item }">
                                <a  class="ml-1 badge text-white" :style="`background-color:${STATUS.SERVICE_PAYMENT_REQUEST_STATUS.find(e=>e.value === item.status)?.color}`">
                                    {{STATUS.SERVICE_PAYMENT_REQUEST_STATUS.find(e=>e.value === item.status)?.text}}
                                </a>
                            </template>
                            <template v-slot:[`item.action`]="{ item }">
                                <v-btn v-if="userAccess.includes('view')" text icon color="orange" @click="viewEdit('view', item.id)">
                                    <v-icon small>mdi-eye</v-icon>
                                </v-btn>
                                <v-btn v-if="userAccess.includes('edit')  && item.status === 1" text icon color="primary" @click="viewEdit('edit', item.id)">
                                    <v-icon small>mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn v-if="userAccess.includes('delete') && item.status === 1" text icon color="error" @click="destroy(item.id)">
                                    <v-icon small>mdi-delete</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
            <!-- Dialog -->
            <v-dialog v-model="mainDialog" persistent scrollable width="100%">
                <v-card  v-if="!dialogLoader" elevation="0" class="pa-0 m-0 overflow-hidden">
                    <v-card-title>
                        <v-container class="pa-0">
                            <v-row class="m-0">
                                <v-col>
                                    <span class="headline">
                                        {{
                                            SERVICE_PAYMENT_REQUEST.ACTION.charAt(0).toUpperCase() +
                                            SERVICE_PAYMENT_REQUEST.ACTION.slice(1)
                                        }} SPR
                                        {{ recordNumber }}
                                        {{ classificationText ? '(' + classificationText + ')' : '' }}
                                    </span>
                                </v-col>
                                <v-col cols="pull-right-1">
                                    <v-btn text icon color="gray" class="float-right" @click="toggleDialog('close');$refs.clearFiles.$emit('clear-files');">
                                        <v-icon>mdi-close-circle</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-card elevation="0" dense>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-card elevation="0" dense>
                                                <v-card-text>
                                                    <v-row>
                                                        <v-col :cols="byPassFilter ? 3: 6">
                                                            <v-autocomplete
                                                                v-model="form.service_payment_request_type_id"
                                                                :items="GET_SERVICE_PAYMENT_REQUEST_TYPE"
                                                                :loading="!GET_SERVICE_PAYMENT_REQUEST_TYPE"
                                                                :readonly="isDisabled()"
                                                                :rules="required"
                                                                auto-select-first
                                                                outlined
                                                                dense
                                                                @change="defaultSPRData(form.service_payment_request_type_id)"
                                                            >
                                                                <template v-slot:label>
                                                                    <span>Service Payment Request Type <span style="color: red;">*</span></span>
                                                                </template>
                                                            </v-autocomplete>
                                                        </v-col>
                                                        <v-col v-if="byPassFilter" cols="3">
                                                            <v-autocomplete
                                                                v-model="form.department_id"
                                                                :items="filterDepartmentByType"
                                                                :disabled="!form.service_payment_request_type_id"
                                                                :rules="byPassFilter ? required : []"
                                                                :readonly="isDisabled()"
                                                                auto-select-first
                                                                outlined
                                                                dense
                                                            >
                                                                <template v-slot:label>
                                                                    <span>Department <span style="color: red;">*</span></span>
                                                                </template>
                                                            </v-autocomplete>
                                                        </v-col>
                                                        <v-spacer/>
                                                        <v-col cols="2">
                                                            <v-text-field
                                                                v-model="vendorDetails.billing_address_id"
                                                                :readonly="isDisabled()"
                                                                label="Billing Address"
                                                                outlined
                                                                dense
                                                            ></v-text-field>
                                                            <!-- :rules="required" -->
                                                        </v-col>
                                                        <v-col cols="3">
                                                            <v-menu
                                                                v-model="menu.bill_due"
                                                                :close-on-content-click="false"
                                                                transition="scale-transition"
                                                                max-width="290"
                                                                :disabled="isDisabled()"
                                                            >
                                                                <template v-slot:activator="{ on }">
                                                                    <v-text-field
                                                                        v-model="form.bill_due"
                                                                        v-on="on"
                                                                        :readonly="isDisabled()"
                                                                        :rules="required"
                                                                        append-icon="mdi-calendar"
                                                                        readonly
                                                                        outlined
                                                                        dense
                                                                    >
                                                                        <template v-slot:label>
                                                                            <span>Bill Due <span style="color: red;">*</span></span>
                                                                        </template>
                                                                    </v-text-field>
                                                                </template>
                                                                    <v-date-picker v-model="form.bill_due"  @input="menu.bill_due = false"/>
                                                            </v-menu>
                                                        </v-col>
                                                            <!-- :rules="required" -->
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="6">
                                                            <v-autocomplete
                                                                v-model="form.vendor_id"
                                                                :items="GET_ADMIN_SUPPLIER_SELECTION"
                                                                :readonly="isDisabled()"
                                                                :rules="required"
                                                                label="Select Vendor"
                                                                auto-select-first
                                                                outlined
                                                                dense
                                                                @change="getAddressOfVendor()"
                                                            >
                                                                <template v-slot:label>
                                                                    <span>Select Vendor <span style="color: red;">*</span></span>
                                                                </template>
                                                            </v-autocomplete>
                                                        </v-col>
                                                        <v-spacer/>
                                                        <v-col cols="2">
                                                            <v-text-field
                                                                v-model="vendorDetails.contact_person"
                                                                :readonly="isDisabled()"
                                                                label="Contact Person"
                                                                outlined
                                                                dense
                                                            ></v-text-field>
                                                        </v-col>
                                                            <!-- :rules="required" -->
                                                        <v-col cols="3">
                                                            <v-text-field
                                                                v-model="vendorDetails.contact_number"
                                                                :readonly="isDisabled()"
                                                                label="Contact Number"
                                                                outlined
                                                                dense
                                                            ></v-text-field>
                                                        </v-col>
                                                            <!-- :rules="required" -->
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="3">
                                                            <v-autocomplete
                                                                v-model="form.company_id"
                                                                :readonly="isDisabled()"
                                                                :rules="required"
                                                                :items="GET_PV_COMPANIES_SELECTION"
                                                                label="Select Company"
                                                                no-data-text="No Companies Found!"
                                                                auto-select-first
                                                                outlined
                                                                dense
                                                                @change="insertStore(2)"
                                                            >
                                                                <template v-slot:label>
                                                                    <span>Select Company <span style="color: red;">*</span></span>
                                                                </template>
                                                            </v-autocomplete>
                                                        </v-col>
                                                        <v-col cols="3">
                                                            <v-autocomplete
                                                                v-model="form.store_branches_id"
                                                                :readonly="isDisabled()"
                                                                :rules="required"
                                                                :items="branchItems"
                                                                label="Select Branch"
                                                                no-data-text="No Branches Found!"
                                                                auto-select-first
                                                                outlined
                                                                dense
                                                                @change="insertCompany(form.store_branches_id, 2)"
                                                            >
                                                                <template v-slot:label>
                                                                    <span>Select Branch <span style="color: red;">*</span></span>
                                                                </template>
                                                            </v-autocomplete>
                                                        </v-col>
                                                        <v-spacer/>
                                                        <v-col cols="2">
                                                            <v-autocomplete
                                                                v-model="form.terms"
                                                                :items="termItems"
                                                                :readonly="isDisabled()"
                                                                auto-select-first
                                                                item-text="terms"
                                                                item-value="terms"
                                                                label="Terms"
                                                                outlined
                                                                dense
                                                            ></v-autocomplete>
                                                        </v-col>
                                                            <!-- :rules="required" -->
                                                        <v-col cols="3">
                                                            <v-menu
                                                                :close-on-content-click="false"
                                                                transition="scale-transition"
                                                                max-width="290"
                                                            >
                                                                <template v-slot:activator="{ on }">
                                                                    <v-text-field
                                                                        v-model="form.created_at"
                                                                        v-on="on"
                                                                        append-icon="mdi-calendar"
                                                                        label="Date Issued"
                                                                        disabled
                                                                        clearable
                                                                        readonly
                                                                        outlined
                                                                        dense
                                                                    ></v-text-field>
                                                                </template>
                                                                    <v-date-picker v-model="form.created_at" />
                                                            </v-menu>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="6">
                                                            <v-textarea
                                                                v-model="form.remarks"
                                                                :readonly="isDisabled()"
                                                                :rules="required"
                                                                label="Remarks"
                                                                rows="2"
                                                                auto-grow
                                                                outlined
                                                                dense
                                                            >
                                                                <template v-slot:label>
                                                                    <span>Remarks <span style="color: red;">*</span></span>
                                                                </template>
                                                            </v-textarea>
                                                        </v-col>

                                                        <v-spacer></v-spacer>
                                                        <v-col cols="3">
                                                            <v-autocomplete
                                                                v-model="form.payment_type_id"
                                                                :items="GET_PAYMENT_TYPES"
                                                                :readonly="isDisabled()"
                                                                auto-select-first
                                                                label="Mode of Payment (optional)"
                                                                outlined
                                                                item-text="name"
                                                                item-value="id"
                                                                dense
                                                            ></v-autocomplete>
                                                        </v-col>

                                                        <v-col cols="2" class="d-flex justify-content-end">
                                                            <BaseCameraCaptureVue
                                                                v-if="SERVICE_PAYMENT_REQUEST.ACTION != 'view'"
                                                                ref="cameraCapture"
                                                                @capturedImages="getImages"
                                                            ></BaseCameraCaptureVue>
                                                            <v-badge
                                                                v-if="!!images && images.length > 0"
                                                                :content="'+'+(images.length).toString()"
                                                                color="teal darken-1"
                                                                offset-x="20"
                                                                offset-y="60"
                                                                bottom
                                                                bordered
                                                            ></v-badge>
                                                            <FileUploadComponentVue
                                                                v-if="SERVICE_PAYMENT_REQUEST.ACTION != 'view'"
                                                                style="display:inline-block; transform: translateY(-10px);"
                                                                ref="fileUpload"
                                                                id="attachment"
                                                                @uploadedData="uploadedData"
                                                                @reset="clearFileUpload"
                                                            />
                                                            <BaseFilesViewerComponentVue
                                                                :access="userAccess.includes('file_view')"
                                                                :payloadIds="[]"
                                                                :payloadId="form.id ? form.id : null"
                                                                :getFilesPath="'getServicePaymentRequestFiles'"
                                                                :module="'accounting'"
                                                                :folder="'service_payment_request_files'"
                                                                :deleteFilesPath="form.status === 1 ? 'deleteServicePaymentRequestFiles' : ''"
                                                                :localFiles="uploadedFiles"
                                                                :returnData="true"
                                                                @files="processFiles"
                                                                @return="insertUpload"
                                                                ref="clearFiles"
                                                            />
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-spacer/>
                                                        <v-col cols="1">
                                                            <v-autocomplete
                                                                v-model="fillCoaField"
                                                                v-show="autoFill.length > 0"
                                                                :items="GET_CHART_OF_ACCOUNTS_SELECTION"
                                                                auto-select-first
                                                                placeholder="CoA"
                                                                item-value="id"
                                                                item-text="account_name"
                                                                class="small-font"
                                                                outlined
                                                                dense
                                                            ></v-autocomplete>
                                                        </v-col>
                                                        <!-- <v-col cols="1">
                                                            <v-autocomplete
                                                                v-model="fillDepartmentField"
                                                                v-show="autoFill.length > 0"
                                                                :items="GET_DEPARTMENT_ACCOUNTING_LOAD"
                                                                placeholder="Department"
                                                                class="small-font"
                                                                outlined
                                                                dense
                                                            />
                                                        </v-col>
                                                        <v-col cols="1">
                                                            <v-autocomplete
                                                                v-model="fillJobField"
                                                                v-show="autoFill.length > 0"
                                                                :items="GET_ACCOUNTING_JOB_SELECTION"
                                                                placeholder="Job"
                                                                class="small-font"
                                                                item-text="text"
                                                                item-value="value"
                                                                outlined
                                                                dense
                                                            />
                                                        </v-col> -->
                                                        <v-col cols="1" class="d-flex justify-center align-center">
                                                            <v-btn small v-show="autoFill.length > 0" @click="massUpdateFields()">
                                                                Mass Update
                                                            </v-btn>
                                                        </v-col>
                                                        <v-col cols="1" class="text-right" v-show="!isDisabled()">
                                                            <v-tooltip top>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn v-on="on" fab dark small color="success" style="height: 20px; width: 20px;" @click="addParticular()">
                                                                        <v-icon dark small style="font-size: 12px;">
                                                                            mdi-plus
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                    <span>Add</span>
                                                            </v-tooltip>
                                                            <v-tooltip top>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn v-on="on" fab dark small color="error" class="ml-1" style="height: 20px; width: 20px;" @click="removeParticular()">
                                                                        <v-icon dark small style="font-size: 12px;">
                                                                            mdi-minus
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                    <span>Delete</span>
                                                            </v-tooltip>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <v-simple-table fixed-header dense class="overflow-y-auto" style="border: 1px solid #e0e0e0; max-height: 283px;">
                                                                <template v-slot:default>
                                                                    <thead>
                                                                        <tr>
                                                                            <th width="3%" class="text-left" v-if="SERVICE_PAYMENT_REQUEST.ACTION != 'view'">
                                                                                <v-row class="d-flex justify-space-between align-content-center pt-2">
                                                                                    <v-tooltip top>
                                                                                        <template v-slot:activator="{on,attrs}">
                                                                                            <div v-bind="attrs" v-on="on">
                                                                                                <v-checkbox
                                                                                                    v-if="byPassFilter"
                                                                                                    v-model="selectAllCheckbox"
                                                                                                    style="transform: translateX(28px);"
                                                                                                    :true-value="1"
                                                                                                    :false-value="0"
                                                                                                    @change="selectAll(selectAllCheckbox)"
                                                                                                ></v-checkbox>
                                                                                            </div>
                                                                                        </template>
                                                                                            <span>Select All</span>
                                                                                    </v-tooltip>
                                                                                </v-row>
                                                                            </th>
                                                                            <th v-if="byPassFilter" class="text-left" width="15%">
                                                                                <v-row class="d-flex justify-space-between">
                                                                                    <div class="pl-4">
                                                                                        COA (OPTIONAL)
                                                                                    </div>
                                                                                    <v-tooltip top v-if="SERVICE_PAYMENT_REQUEST.ACTION != 'view'">
                                                                                        <template v-slot:activator="{on,attrs}">
                                                                                            <div v-bind="attrs" v-on="on">
                                                                                                <v-icon class="pr-6" @click="clearDropdown('coa_id')">mdi-close</v-icon>
                                                                                            </div>
                                                                                        </template>
                                                                                            <span>Clear CoAs</span>
                                                                                    </v-tooltip>
                                                                                </v-row>
                                                                            </th>
                                                                            <th class="text-left" width="30%">PARTICULARS <span style="color: red;">*</span></th>
                                                                            <th class="text-right" width="8%">AMOUNT <span style="color: red;">*</span></th>
                                                                            <th class="text-left" width="8%">RECOGNITION MONTH <span style="color: red;">*</span></th>
                                                                            <!-- <th class="text-left" width="15%">
                                                                                <v-row class="d-flex justify-space-between pt-2">
                                                                                    <div class="pl-4">
                                                                                        DEPARTMENT
                                                                                    </div>
                                                                                    <v-tooltip top v-if="SERVICE_PAYMENT_REQUEST.ACTION != 'view'">
                                                                                        <template v-slot:activator="{on,attrs}">
                                                                                            <div v-bind="attrs" v-on="on">
                                                                                                <v-icon class="pr-6" @click="clearDropdown('department_id')">mdi-close</v-icon>
                                                                                            </div>
                                                                                        </template>
                                                                                            <span>Clear Departments</span>
                                                                                    </v-tooltip>
                                                                                </v-row>
                                                                            </th>
                                                                            <th class="text-left" width="23%">
                                                                                <v-row class="d-flex justify-space-between pt-2">
                                                                                    <div class="pl-4">
                                                                                        JOB
                                                                                    </div>
                                                                                    <v-tooltip top v-if="SERVICE_PAYMENT_REQUEST.ACTION != 'view'">
                                                                                        <template v-slot:activator="{on,attrs}">
                                                                                            <div v-bind="attrs" v-on="on">
                                                                                                <v-icon class="pr-6" @click="clearDropdown('job_id')">mdi-close</v-icon>
                                                                                            </div>
                                                                                        </template>
                                                                                            <span>Clear Jobs</span>
                                                                                    </v-tooltip>
                                                                                </v-row>
                                                                            </th> -->
                                                                            <th class="text-center" width="3%"/>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody class="particulars-container">
                                                                        <tr v-for="(item, index) in form.service_payment_request_items" :key="item.id"
                                                                            class="font-weight-medium text-gray py-2 particulars-container-row">
                                                                            <td v-if="SERVICE_PAYMENT_REQUEST.ACTION != 'view'">
                                                                                <v-row class="d-flex justify-center align-center">
                                                                                    <v-checkbox
                                                                                        v-if="byPassFilter"
                                                                                        v-model="item.autoFill"
                                                                                        :true-value="1"
                                                                                        :false-value="0"
                                                                                        class="p-0 ma-0 pt-4"
                                                                                        @change="handleCheckboxChange(index, item.autoFill)"
                                                                                    ></v-checkbox>
                                                                                </v-row>
                                                                            </td>
                                                                            <td v-if="byPassFilter">
                                                                                <v-autocomplete
                                                                                    v-model="item.coa_id"
                                                                                    :items="GET_CHART_OF_ACCOUNTS_SELECTION"
                                                                                    :loading="GET_CHART_OF_ACCOUNTS_SELECTION.length == 0"
                                                                                    :readonly="isDisabled()"
                                                                                    id="coa_id"
                                                                                    class="my-1 text-left small-font"
                                                                                    item-value="id"
                                                                                    item-text="account_name"
                                                                                    auto-select-first
                                                                                    outlined
                                                                                    dense
                                                                                ></v-autocomplete>
                                                                            </td>
                                                                            <td>
                                                                                <v-textarea
                                                                                    v-model="item.particulars"
                                                                                    :readonly="isDisabled()"
                                                                                    :rules="required"
                                                                                    id="particular"
                                                                                    class="small-font"
                                                                                    @paste="(event) => onPaste(event, index, 'particulars')"
                                                                                    rows="1"
                                                                                    auto-grow
                                                                                    outlined
                                                                                    dense
                                                                                    @keydown="newLine($event, false, false, index, 'particular', addParticular)"
                                                                                ></v-textarea>
                                                                            </td>
                                                                            <td>
                                                                                <v-text-field
                                                                                    v-model="item.amount"
                                                                                    :readonly="isDisabled()"
                                                                                    :rules="required"
                                                                                    id="amount"
                                                                                    type="number"
                                                                                    placeholder="0.00"
                                                                                    class="right-align-text small-font"
                                                                                    dense
                                                                                    outlined
                                                                                    hide-spin-buttons
                                                                                    @keydown.native="newLine($event, false, false, index, 'amount', addParticular, false)"
                                                                                    @paste="(event) => onPaste(event, index, 'amount')"
                                                                                    @input="reComputeTotal()"
                                                                                >
                                                                                    <template v-slot:prepend-inner>
                                                                                        <v-icon small>mdi-currency-php</v-icon>
                                                                                    </template>
                                                                                </v-text-field>
                                                                            </td>
                                                                            <td>
                                                                                <v-menu
                                                                                    v-model="item.menu"
                                                                                    :close-on-content-click="false"
                                                                                    :disabled="isDisabled()"
                                                                                    transition="scale-transition"
                                                                                    max-width="290"
                                                                                    nudge-bottom="35"
                                                                                >
                                                                                    <template v-slot:activator="{ on }">
                                                                                        <v-text-field
                                                                                            v-model="item.recognition_month"
                                                                                            v-on="on"
                                                                                            :readonly="isDisabled()"
                                                                                            :rules="required"
                                                                                            id="recognition_month"
                                                                                            class="small-font"
                                                                                            append-icon="mdi-calendar"
                                                                                            readonly
                                                                                            outlined
                                                                                            dense
                                                                                            clearable
                                                                                            :clearable="SERVICE_PAYMENT_REQUEST.ACTION != 'view'"
                                                                                            @paste="(event) => validateRecognitionMonth(event, form.service_payment_request_items, index)"
                                                                                            @keydown="newLine($event, false, false, index, 'recognition_month', addParticular)"
                                                                                        ></v-text-field>
                                                                                    </template>
                                                                                        <v-date-picker v-model="item.recognition_month"  @input="item.menu = false" type="month"></v-date-picker>
                                                                                </v-menu>
                                                                            </td>
                                                                            <!-- <td>
                                                                                <v-autocomplete
                                                                                    v-model="item.department_id"
                                                                                    :items="GET_DEPARTMENT_ACCOUNTING_LOAD"
                                                                                    :loading="GET_DEPARTMENT_ACCOUNTING_LOAD.length == 0"
                                                                                    :readonly="isDisabled()"
                                                                                    :rules="required"
                                                                                    class="small-font"
                                                                                    outlined
                                                                                    dense
                                                                                    @keydown="newLine($event, true, true, index, 'coa_id', addParticular)"
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <v-autocomplete
                                                                                    v-model="item.job_id"
                                                                                    :items="GET_ACCOUNTING_JOB_SELECTION"
                                                                                    :readonly="isDisabled()"
                                                                                    :rules="required"
                                                                                    class="my-1 text-left small-font"
                                                                                    id="job_id"
                                                                                    item-text="text"
                                                                                    item-value="value"
                                                                                    outlined
                                                                                    dense
                                                                                    @keydown="newLine($event, true, true, index, 'coa_id', addParticular)"
                                                                                />
                                                                            </td> -->
                                                                            <td class="text-center" v-show="!isDisabled()">
                                                                                <v-tooltip top>
                                                                                    <template v-slot:activator="{ on }">
                                                                                        <v-btn v-on="on" fab dark x-small color="error" style="height: 20px; width: 20px;" @click="removeItem(index)">
                                                                                            <v-icon small>
                                                                                                mdi-minus
                                                                                            </v-icon>
                                                                                        </v-btn>
                                                                                    </template>
                                                                                        <span>Remove</span>
                                                                                </v-tooltip>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td v-if="!isDisabled()"></td >
                                                                            <td class="d-flex align-items-center" colspan="2">
                                                                                <span><b style="font-size:15px">Total:</b></span>
                                                                                <v-spacer/>
                                                                                <span><b>{{ form.total_amount | currency }}</b></span></td>
                                                                            <td></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </template>
                                                            </v-simple-table>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                            <v-container>
                                                <v-row class="py-2">
                                                    <v-col class="mt-5">
                                                        <span>Required fields are marked with <span style="color: red;">*</span></span>
                                                    </v-col>
                                                    <v-col v-if="form.status == 5 && userAccess.includes('approve')" class="text-center">
                                                        <v-btn
                                                            :loading="btnLoad"
                                                            @click="commitTransaction('Revise','update')"
                                                            color="info"
                                                        >
                                                            Revise
                                                        </v-btn>
                                                    </v-col>
                                                    <v-col class="text-center">
                                                        <v-btn :disabled="!validToProceed" :loading="btnLoad" v-if="SERVICE_PAYMENT_REQUEST.ACTION !== 'view'"
                                                            @click="commitTransaction(SERVICE_PAYMENT_REQUEST.ACTION, SERVICE_PAYMENT_REQUEST.ACTION == 'create' ? 'create' : 'update')"
                                                        >
                                                            {{ SERVICE_PAYMENT_REQUEST.ACTION == 'create' ? 'Create' : 'Update' }}
                                                        </v-btn>
                                                        <v-btn
                                                            v-else-if="form.status == 1 &&
                                                            form.prepared_by == USERACCOUNT_LOAD.id"
                                                            :loading="btnLoad"
                                                            @click="commitTransaction('Submit for Approval', 'update')"
                                                        >
                                                            SUBMIT FOR APPROVAL
                                                        </v-btn>
                                                        <v-btn
                                                            v-else-if="[5,3].includes(form.status) &&
                                                            userAccess.includes('approve') &&
                                                            !approve_flag"
                                                            :loading="btnLoad"
                                                            @click="commitTransaction('Approve','update')"
                                                        >
                                                            {{ special_approve == true ? 'SPECIAL APPROVE' : 'APPROVE' }}
                                                        </v-btn>
                                                    </v-col>
                                                    <v-spacer/>
                                                </v-row>
                                            </v-container>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-container>
                    </v-card-title>
                </v-card>
                <v-card v-else elevation="0" class="pa-0 m-0 overflow-hidden" height="90px">
                    <v-row>
                        <v-col class="text-center justify-content-between align-content-between">
                            <v-progress-circular
                                :size="80"
                                class="mt-4"
                                color="primary"
                                indeterminate
                            ></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-card>
            </v-dialog>
            <v-dialog  v-model="progressDialog" max-width="500" height="100" persistent>
                <v-card flat>
                    <v-card-title>
                        <p class="font-weight-medium"  style="font-size:12px">Saving Data...</p>
                    </v-card-title>
                    <v-card-text class="w-100">
                        <v-row class="w-100 mx-auto">
                            <v-col
                                cols="12"
                            >
                                <v-progress-linear
                                    class="mx-auto"
                                    :value="chunksPercentage"
                                    height="15"
                                >
                                    <strong>{{ chunksPercentage }}%</strong>
                                </v-progress-linear>
                                <p class="font-weight-light align-right"  style="font-size:10px">{{ itemSave  }} / {{ toProcessFiles.length }} are saved</p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-card>
    </v-form>
</template>
<script>
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue'
import FileUploadComponentVue from '@/views/main/Shared/FileUploadComponent.vue'
import BaseFilesViewerComponentVue from '@/views/main/Shared/BaseFilesViewerComponent.vue'
import BaseCameraCaptureVue from "@/views/main/modules/HR/Base/BaseCameraCapture.vue"
export default {
    mixins: [SharedFunctionsComponentVue],
    props: ['userAccess'],
    data(){
        return{
            valid: false,
            mainDialog: false,
            mainHeaders: [
                { text: 'SPR #', value:'service_payment_request_number', sortable: false },
                { text: 'Type', value:'type_name', sortable: false },
                { text: 'Company', value:'company_name', sortable: false },
                { text: 'Store Branch', value:'store_branch_name', sortable: false },
                { text: 'Vendor', value:'vendor_name', sortable: false },
                { text: 'Remarks', value:'remarks', align:'left', sortable: false },
                { text: 'Prepared By', value:'user_by_name', sortable: false },
                { text: 'Approved By', value:'approved_by_name', align:'left', sortable: false },
                { text: 'Total Amount', value:'total_amount', align: 'right', sortable: false },
                { text: 'Transact Date', value:'created_at', align:'center', sortable: false },
                { text: 'Status', value:'status', align:'center', sortable: false },
                // { text: "Cancel Remarks", value: "cancel_reason", sortable: false },
                { text: 'Actions', value:'action', align: 'center', sortable: false },
            ],
            mainItems: [],
            options: {},
            totalItems: 0,
            tableLoad: false,
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            itemsPerPage: 10,
            search: '',
            dialogLoader: false,
            form:{
                id: null,
                service_payment_request_number: null,
                service_payment_request_type_id: null,
                vendor_id: null,
                company_id: null,
                store_branches_id: null,
                bill_due: null,
                terms: null,
                total_amount: null,
                remarks: null,
                status: 1,
                department_id: null,
                created_at: this.$dayjs().format('YYYY-MM-DD'),
                service_payment_request_items: [],
                payment_type_id:'',
                special_approved_by: null
            },
            service_payment_request_items_trash: [],
            btnLoad: false,
            transaction_types: [],
            vendorDetails:{
                billing_address_id: null,
                contact_number: '',
                contact_person: ''
            },
            branchesByCompany: [],
            filters:{
                company_id: null,
                store_branches_id: null,
                vendor_id: null,
                departments: [],
                types: null,
                date_from: this.$dayjs().startOf('month').format('YYYY-MM-DD'),
                date_to:  this.$dayjs().endOf('month').format('YYYY-MM-DD'),
                prepared_by: null,
                company_classification_id: null,
            },
            clonedStores: [],
            stores: [],
            required: [ v => !!v || 'This field is required' ],
            result: [],
            autoFill: [],
            fillCoaField: null,
            // fillDepartmentField: null,
            // fillJobField: null,
            selectAllCheckbox: null,
            termItems: [],

            //variables for file upload
            viewUploadFiles: {
                attachments: []
            },
            uploadedFiles: [],
            compiledFiles: [],
            exportLoad: false,
            disableExport: false,
            menu: {
                recognition_month: false,
                date_from: false,
                date_to: false,
                bill_due: false
            },
            fetchStatus: false,
            filterDepartmentByType: [],
            mode_of_payment_items: [],
            chunksPercentage: 0,
            itemSave: 0,
            progressDialog: false,
            saveFiles: [],
            uploadedImages: [],
            images: [],
            toProcessFiles: [],
            dispatchCoa: false,
            special_approve: false,
            approve_flag: false,
            approvers: []
        }
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS',
            'USERACCOUNT_LOAD',
            'GET_CHART_OF_ACCOUNTS_SELECTION',
            'GET_DEPARTMENT_ACCOUNTING_LOAD',
            'GET_TRANSACTION_TYPE_SELECTION',
            'SERVICE_PAYMENT_REQUEST',
            'GET_ADMIN_SUPPLIER_SELECTION',
            'GET_PV_COMPANIES_SELECTION',
            'GET_PV_STORE_BRANCHES_SELECTION',
            'GET_COMPANY_SELECTION',
            'STATUS',
            'GET_SERVICE_PAYMENT_REQUEST_TYPE',
            'GET_ACCOUNTING_JOB_SELECTION',
            'SELECTED_TAB',
            'GET_TRANSACT_TYPES_SELECTION',
            'SELECTED',
            'GET_PREPARED_BY',
            'GET_PAYMENT_TYPES',
            'GET_COMPANY_CLASSIFICATION_SELECTION'
        ]),
        getDataTableHeaders() {
            const headers = [
                { text: 'SPR #', value:'service_payment_request_number', sortable: false },
                { text: 'User', value:'prepared_by', sortable: false },
                { text: 'Company', value:'company_name', sortable: false },
                { text: 'Store Branch', value:'store_branch_name', sortable: false },
                { text: 'Vendor', value:'vendor_name', sortable: false },
                { text: 'Total Amount', value:'total_amount', align: 'right', sortable: false },
                { text: 'Transact Date', value:'created_at', align:'right', sortable: false },
                { text: 'Status', value:'status', align:'right', sortable: false },
                { text: "Cancel Remarks", value: "cancel_reason", sortable: false },
                { text: 'Actions', value:'action', align: 'center', sortable: false },
            ];

            if (![0, 1].includes(this.SELECTED_TAB))
                return headers.filter(header => header.value != 'cancel_reason');

            return headers;
        },
        companyItems(){
            return this.GET_PV_COMPANIES_SELECTION.filter(e=>e.company_classification_id == this.form.company_classification_id)
        },
        branchItems(){
            let items = this.clonedStores.filter(e=>e.company_id == this.form.company_id)
            if (!this.form.company_id) return this.clonedStores
            if (!this.form.store_branches_id) this.form.store_branches_id = items[0].value
            return items
        },
        recordNumber(){
            return this.form.service_payment_request_number ? '# : ' + this.form.service_payment_request_number : ''
        },
        byPassFilter(){
            if (
                this.USERACCOUNT_LOAD.president == 1 ||
                this.USERACCOUNT_LOAD.super_admin == 1 ||
                this.USERACCOUNT_LOAD.manager == 1 ||
                this.USERACCOUNT_LOAD.department_id == 4
            ) {
                return true
            } else {
                return false
            }
        },
        validToProceed(){
            return this.valid
        },
        classificationText() {
            let classificationId = this.branchItems.find(e => e.value == this.form.store_branches_id)?.company_classification_id
                ?? this.GET_PV_COMPANIES_SELECTION.find(e => e.value == this.form.company_id)?.company_classification_id;
            return classificationId
                ? this.GET_COMPANY_CLASSIFICATION_SELECTION.find(el => el.value == classificationId)?.text ?? ''
                : '';
        },
        branchItemsForFilter(){
            let items = this.clonedStores.filter(e=>e.company_id == this.filters.company_id)
            if (!this.filters.company_id) return this.clonedStores
            if (!this.filters.store_branches_id) this.filters.store_branches_id = items[0].value
            return items
        }
    },
    components:{
        FileUploadComponentVue,
        BaseFilesViewerComponentVue,
        BaseCameraCaptureVue
    },
    mounted(){
        document.addEventListener('keydown', this.handleKeydown)
        this.getBranches()
        this.$store.dispatch('getAccountingJobSelection')
        this.$store.dispatch('getPaymentTypes');
    },
    beforeDestroy(){
        document.removeEventListener('keydown', this.handleKeydown)
    },
    methods:{
        onPaste(evt, index, paste){
            evt.preventDefault();
            let pastedText = evt.clipboardData.getData("Text");
            if (pastedText.includes("\n")) {
                this.result = [] // para sa tuloy tuloy na pag paste ng hindi nabubura ang laman -> for future purposes
                let rows = pastedText.split("\r\n");
                for (let y = 0; y < rows.length; y++) {
                    let cells = rows[y].split("\t");
                    if (cells[0] !== '') {
                        this.result.push({
                            autoFill: 0,
                            coa_id: null,
                            particulars: cells[0],
                            amount: cells[1].replace("," , ""),
                            recognition_month: this.$dayjs().format('YYYY-MM'),
                        });
                    }
                }
                this.form.service_payment_request_items = this.result;
            } else {
                // Swal.fire('', 'Pasted Data format is not supported!', 'warning')
                this.form.service_payment_request_items[index][paste] = pastedText;
                return
            }

        },
        handleKeydown(event){
            if(!this.dialogLoader) this.closeDialogByEsc(event, this.toggleDialog)
        },
        toggleDialog(action = 'close'){
            switch(action){
                case 'open':
                    this.valid = false
                    this.mainDialog = true
                    if (this.form.service_payment_request_items.length == 0) this.addParticular()
                    break;
                case 'close':
                    this.clearFields()
                    this.mainDialog = false
                    break;
            }
        },
        addParticular(){
            this.form.service_payment_request_items.push({
                autoFill: 0,
                coa_id: null,
                particulars: null,
                amount: null,
                recognition_month: this.$dayjs().format('YYYY-MM'),
            })
        },
        removeParticular(){
            if (this.form.service_payment_request_items.length > 1) this.form.service_payment_request_items.pop()
            this.reComputeTotal()
        },
        removeItem(index){
            if (this.form.service_payment_request_items.length != 1) {
                this.service_payment_request_items_trash.push(this.form.service_payment_request_items[index])
                this.form.service_payment_request_items.splice(index, 1)
            }
            this.reComputeTotal()
        },
        clearFields(){
            this.$refs.form.resetValidation()
            this.form = {
                id: null,
                service_payment_request_number: null,
                service_payment_request_type_id: null,
                vendor_id: null,
                company_id: null,
                store_branches_id: null,
                bill_due: null,
                terms: null,
                total_amount: null,
                remarks: null,
                status: 1,
                department_id: null,
                created_at: this.$dayjs().format('YYYY-MM-DD'),
                service_payment_request_items: [],
                payment_type_id:'',
                special_approved_by: null
            }
            this.vendorDetails = {
                billing_address_id: null,
                contact_number: '',
                contact_person: ''
            }
            this.service_payment_request_items_trash = []
            this.stores = []
            this.autoFill = []
            this.selectAllCheckbox = null
            this.fillCoaField = null
            this.uploadedFiles = []
            this.saveFiles = []
            this.viewUploadFiles = {
                attachments: []
            }
            this.saveFiles = []
            this.uploadedImages = []
            this.images = []
            this.toProcessFiles = []
            this.dispatchCoa = false
            this.special_approve = false
        },
        async commitTransaction(action, url){
            this.reComputeTotal()
            let createEnterBill = false
            let allowEnterBill = false
            let allowProceed = true
            let newStatus = this.form.status
            this.btnLoad = true
            Swal.fire({
                title: '',
                text: `Are you sure you want to ${action} ?`,
                icon: 'warning',
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                allowOutsideClick: false,
                confirmButtonColor: '#397373',
                cancelButtonColor: 'grey',
                confirmButtonColor: 'Confirm'
            }).then(async (result) => {
                if (!result.isConfirmed) {
                    this.btnLoad = false
                    allowProceed = false
                    return
                }

                if(action === 'Approve') {
                    let validUser = true;
                    await Swal.fire({
                        title: "Checking if you are authorized...",
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                            this.$store.dispatch('servicePaymentRequestGet', {url: `check-user-authorized/${this.form.id}`, access: this.userAccess.includes('approve')}).then(() => {
                                Swal.close();
                            }).catch(error => {
                                Swal.close();
                                if (error.response.status == 406)
                                    Swal.fire({
                                        title: 'Error',
                                        text: 'You are not authorized to confirm this service payment request.',
                                        icon: 'error',
                                    });
                                else Swal.fire('Error','','error');
                                validUser = false;
                                allowProceed = false
                            });
                        }
                    });

                    if (this.special_approve) {
                        await Swal.fire({
                            title: `SPR # ${this.form.service_payment_request_number} can be approved by the following: `,
                            html: this.approvers.map(approver=>`${approver.user_name}<br>`).join(''),
                            icon: 'info',
                            showConfirmButton: true,
                            showCancelButton: true,
                            reverseButtons: true,
                            allowOutsideClick: false,
                            confirmButtonColor: '#397373',
                            cancelButtonColor: 'grey',
                            confirmButtonText: 'Proceed',
                            cancelButtonText: 'Cancel'
                        }).then((result)=>{
                            if (!result.isConfirmed) {
                                this.btnLoad = false
                                allowProceed = false
                                return
                            }
                            newStatus = 3
                        })
                    } else {
                        allowEnterBill = true
                        createEnterBill = true
                        newStatus = 4
                    }

                    // if (validUser && allowEnterBill && this.USERACCOUNT_LOAD.department_id == 4) {
                    //     await Swal.fire({
                    //         title: '',
                    //         text: `Do you also want to create a Enter Bill for this Service Payment Request?`,
                    //         icon: 'question',
                    //         showConfirmButton: true,
                    //         showCancelButton: true,
                    //         reverseButtons: true,
                    //         allowOutsideClick: false,
                    //         confirmButtonColor: '#397373',
                    //         cancelButtonColor: 'grey',
                    //         confirmButtonText: 'Yes',
                    //         cancelButtonText: 'No'
                    //     }).then((result)=>{
                    //         if (result.isConfirmed) {
                    //             createEnterBill = true
                    //         }
                    //     })
                    // }

                }

                if (action === 'Submit for Approval') {
                    newStatus = 5
                }
                
                if (action === 'Revise') {
                    newStatus = 1
                }

                if (allowProceed) {
                    this.saveSPR(url, action, createEnterBill, newStatus)
                }
            })
        },
        checkRequiredFields(){
            let emptyFields = []
            let required = {
                'vendor_id': 'Vendor',
                'service_payment_request_type_id': 'Transaction Type',
                'company_id': 'Company',
                'store_branches_id': 'Store Branch',
                // 'bill_due': 'Bill Due',
                // 'terms': 'Terms',
                // 'remarks': 'Remarks'
            }
            // let requiredVendorDetails = {
            //     'billing_address_id': 'Billing Address',
            //     'contact_number': 'Contact Number',
            //     'contact_person': 'Contact Person'
            // }
            let requiredParticulars = {
                // 'coa_id': 'COA',
                'particulars': 'Particular',
                'amount': 'Amount',
                'recognition_month': 'Recognition Month',
                // 'department_id': 'Department'
            }
            Object.keys(required).forEach(e=>{
                if (!this.form[e]) {
                    emptyFields.push(required[e])
                }
            })
            // Object.keys(requiredVendorDetails).forEach(e=>{
            //     if (!this.vendorDetails[e]) {
            //         emptyFields.push(requiredVendorDetails[e])
            //     }
            // })
            let rowCount = 1
            this.form.service_payment_request_items.forEach(item => {
                Object.entries(item).forEach(([key, value]) => {
                    if (
                        key != 'coa_id' &&
                        key != 'deleted_at' &&
                        key != 'paid' &&
                        key != 'autoFill' &&
                        key != 'department_id' &&
                        key != 'job_id' &&
                        (value == null || value == '')
                    ) {
                        emptyFields.push(`Row ${rowCount}: ${requiredParticulars[key]}`)
                    }
                })
                rowCount++
            })
            if (emptyFields.length > 0) {
                Swal.fire({
                    title: 'Please fill the following fields: ',
                    html: emptyFields.map(field => `${field}<br>`).join(''),
                    icon: 'warning'
                })
                return false
            }
            return true
        },
        getAllTransactions(for_export = 0){
            if (this.fetchStatus) return
            this.fetchStatus = true
            if(!for_export){
                this.tableLoad = true
                this.mainItems = []
                this.disableExport = true
            } else {
                this.exportLoad = true
            }
            let payload = {
                company_id: this.filters.company_id,
                store_branches_id: this.filters.store_branches_id,
                vendor_id: this.filters.vendor_id,
                types: this.filters.types,
                filtered_departments: this.filters.departments,
                search: this.search,
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                url: 'index',
                for_export: for_export,
                status: this.SELECTED_TAB,
                date_range:[this.filters.date_from,this.filters.date_to],
                approver: this.userAccess.some(a => ['approve', 'viewer'].includes(a)),
                prepared_by: this.filters.prepared_by,
                company_classification_id: this.filters.company_classification_id,
            }
            this.$store.dispatch('servicePaymentRequestGet', payload).then(response=>{
                if (for_export == 1) {
                    this.exportSPR(response.data)
                } else {
                    this.mainItems = response.data.data.data
                    if (this.mainItems.length > 0) this.disableExport = false
                    this.totalItems = response.data.data.total
                    this.tableLoad = false
                    this.fetchStatus = false
                }
            })
        },
        viewEdit(action, id){
            this.dialogLoader = true
            this.action = this.$store.commit('SERVICE_PAYMENT_REQUEST_ACTION', action)
            this.toggleDialog('open')
            let payload = {
                id: id,
                url: 'view'
            }
            this.$store.dispatch('servicePaymentRequestPost', payload).then(response=>{
                this.approvers = response.data.approvers
                this.form = response.data.data
                this.form.total_amount = parseFloat(this.form.total_amount).toFixed(2)
                this.form.created_at = this.$dayjs(this.form.created_at).format('YYYY-MM-DD')
                this.form.bill_due = this.$dayjs(this.form.bill_due).format('YYYY-MM-DD')
                this.getAddressOfVendor()
                this.form.service_payment_request_items.forEach(e=>{
                    e.recognition_month = this.$dayjs(e.recognition_month).format('YYYY-MM')
                    e.amount = parseFloat(e.amount)
                })
                this.dialogLoader = false

                if (this.form.status == 5) {
                    if (this.approvers.some(e => parseFloat(e.range) < parseFloat(this.form.total_amount))) {
                        // this.special_approve = true
                    }
                } else if (this.form.status == 3) {
                    if (
                        (this.USERACCOUNT_LOAD.president != 1 ||
                        this.USERACCOUNT_LOAD.super_admin != 1) &&
                        this.approvers.some(e => e.user_id != this.USERACCOUNT_LOAD.id)
                    ) {
                        this.approve_flag = false // temporary change until special approve is implemented again
                    }
                } else {
                    this.special_approve = false
                    this.approve_flag = false
                }
            })
        },
        isDisabled(){
            switch(this.SERVICE_PAYMENT_REQUEST.ACTION){
                case 'view':
                    return true
            }
        },
        destroy(id){
            Swal.fire({
                title: '',
                text: 'Are you sure you want to delete Service Payment Request?',
                icon: 'warning',
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                allowOutsideClick: false,
                confirmButtonColor: '#397373',
                cancelButtonColor: 'grey',
                confirmButtonColor: 'Confirm'
            }).then((result) => {
                if (result.isConfirmed) {
                    let payload = {
                        id : id,
                        url : 'destroy'
                    }
                    this.$store.dispatch('servicePaymentRequestPost', payload).then(response => {
                        Swal.fire('', 'Service Payment Request Deleted!', 'success')
                        this.getAllTransactions();
                    }).catch(error => {
                        if (error.response.status == 422) {
                            Swal.fire('', 'Error!', 'error')
                            console.log(error)
                        }
                        this.getAllTransactions()
                    })
                }
            })
        },
        filterTransactionTypes(){
            this.$store.dispatch('getTransactionTypeSelection').then(response => {
                let data = response
                if (this.USERACCOUNT_LOAD.super_admin || this.USERACCOUNT_LOAD.department_id == 4) {
                    this.transaction_types = data
                } else {
                    this.transaction_types = data.filter(e=>e.department_id == this.USERACCOUNT_LOAD.department_id)
                }
            })
        },
        getAddressOfVendor(){
            this.$store.dispatch('getAddressByVendor',{ id: this.form.vendor_id }).then(response=>{
                let address = response.data
                if (address[0].terms != null) {
                    this.termItems = address
                    this.form.terms = address[0].terms
                }
                if (address.length > 0) {
                    this.vendorDetails.billing_address_id = address[0].address
                    this.vendorDetails.contact_number = address[0].contact_number
                    this.vendorDetails.contact_person = address[0].contact_person
                } else {
                    this.vendorDetails.billing_address_id = null
                    this.vendorDetails.contact_number = ''
                    this.vendorDetails.contact_person = ''
                }
                // this.setBillDue()
            })
        },
        async getBranches(){
            try{
                this.clonedStores = await this.getStoreBranches()
            } catch (error) {
                console.error(error)
            }
        },
        insertCompany(storeId){
            if (!storeId) return
            let store = this.clonedStores.find(e=>e.value == storeId)
            this.form.company_id = this.GET_PV_COMPANIES_SELECTION.find(e=>e.value == store.company_id).value
        },
        insertStore(){
            this.form.store_branches_id = this.branchItems[0].value
        },
        defaultSPRData(typeId){
            let trash = []
            let id = this.form.id
            if (this.SERVICE_PAYMENT_REQUEST.ACTION == 'edit' && this.form.service_payment_request_items.length > 0) {
                trash = this.form.service_payment_request_items
            }
            this.service_payment_request_items_trash = trash
            let payload = {
                typeId: typeId,
                url:'default',
            }
            this.$store.dispatch('servicePaymentRequestTypePost',payload).then((response)=>{
                let data = response.data.data
                if (data) {
                    this.form = data
                    this.form.id = id
                    this.form.service_payment_request_type_id = data.type_id
                    this.form.created_at = this.$dayjs(this.form.created_at).format('YYYY-MM-DD')
                    if (this.form.vendor_id) this.getAddressOfVendor()
                    if (this.form.service_payment_request_items.length > 0) {
                        this.form.service_payment_request_items.forEach(e=>{
                            e.recognition_month = this.$dayjs(e.recognition_month).format('YYYY-MM')
                            e.amount = e.amount ? parseFloat(e.amount).toFixed(2) : null
                        })
                    } else {
                        this.addParticular()
                    }
                }
            })
        },
        setBillDue(){
            if(this.form.terms != null && this.form.created_at != '' && this.form.terms != undefined){
                let computedDueDate = new Date(this.form.created_at)
                if(computedDueDate){
                    computedDueDate.setDate(computedDueDate.getDate() + parseInt(this.form.terms));
                    this.form.bill_due = computedDueDate.toISOString().substr(0, 10)
                }else{
                    this.form.bill_due = this.form.created_at
                }
            }else{
                this.form.bill_due = null
            }
        },
        massUpdateFields(){
            if (this.fillCoaField) {
                this.form.service_payment_request_items.map((e,i) => {
                    if (this.autoFill.includes(i)) {
                        if(!e.coa_id){
                            e.coa_id = this.fillCoaField
                        }
                        // e.department_id = this.fillDepartmentField
                        // e.job_id = this.fillJobField
                    }
                })
            }
        },
        selectAll(status){
            if (status) {
                this.form.service_payment_request_items.map((e,i) => {
                    this.handleCheckboxChange(i, status)
                })
            } else {
                this.form.service_payment_request_items.map((e,i) => {
                    e.autoFill = 0
                })
                this.autoFill = []
            }
        },
        handleCheckboxChange(selected_index,status){
            if (status) {
                if (!this.autoFill.includes(selected_index)) {
                    this.autoFill.push(selected_index)
                    this.form.service_payment_request_items.map((e,i) => {
                        if (this.autoFill.includes(i)) {
                            e.autoFill = 1
                        }
                    })
                }
            } else {
                let index = this.autoFill.indexOf(selected_index)
                if (index > -1) this.autoFill.splice(index, 1)
            }
        },
        clearDropdown(field){
            this.form.service_payment_request_items.forEach(e => {
                e[field] = null
            })
            this.$forceUpdate()
        },
        uploadedData(data) {
            let dataToProcess = {
                attachments: []
            }
            if(this.viewUploadFiles.attachments != null && this.viewUploadFiles.attachments.length > 0){
                data.attachments.forEach(newAttachment => {
                    let isDuplicate = this.viewUploadFiles.attachments.some(e=>{
                        return e.name.concat(`.${e.extension}`) === newAttachment.name
                    })
                    if (isDuplicate) {
                        Swal.fire('Duplicate Files Detected!', '','warning')
                        return
                    } else {
                        dataToProcess.attachments.push(newAttachment)
                    }
                });
            } else {
                dataToProcess.attachments = data.attachments
            }
            this.uploadedFiles = dataToProcess
        },
        getImages(dataUrls) {
            this.images = dataUrls.map(dataUrl => {
                try {
                    if (!dataUrl.startsWith('data:image/jpeg;base64,')) {
                        throw new Error('Invalid data URL format');
                    }

                    const base64String = dataUrl.split(',')[1];
                    const byteString = atob(base64String);
                    const arrayBuffer = new ArrayBuffer(byteString.length);
                    const uint8Array = new Uint8Array(arrayBuffer);

                    for (let i = 0; i < byteString.length; i++) {
                        uint8Array[i] = byteString.charCodeAt(i);
                    }

                    const blob = new Blob([uint8Array], { type: 'image/jpeg' });
                    const timestamp = new Date().toISOString().replace(/[:.-]/g, '');
                    const filename = `captured-image-${timestamp}.jpg`;
                    const file = new File([blob], filename, { type: 'image/jpeg' });
                    return file;
                } catch (error) {
                    console.error('Failed to convert base64 to file:', error);
                    return null;
                }
            }).filter(file => file !== null);
            this.uploadedImages = []
            this.images.forEach(e=>{
                e.upload_type = 2,
                this.uploadedImages.push(e)
            })
            let fileData = []
            for (let i = 0; i < this.uploadedImages.length; i++) {
                const element = this.uploadedImages[i]
                let reader = new FileReader()
                reader.readAsDataURL(element)
                new Promise((resolve)=>{
                    reader.onloadend = function () {
                        fileData.push({
                            name: element.name,
                            type: element.type,
                            data: reader.result.split(',')[1],
                            upload_type: element.upload_type,
                        })
                        resolve()
                    }
                })
            }
            this.uploadedImages = fileData
        },
        clearFileUpload(confirm){
            if (confirm) {
                this.uploadedFiles = []
            }
            this.uploadedFiles = []
            this.$refs.fileUpload.reset()
        },
        async processFiles(files){
            // this.viewUploadFiles.attachments = this.viewUploadFiles.attachments.filter(compiledFile => {
            //     return files.some(file => compiledFile.name === file.localFileState.name);
            // });

            // files = files.filter(file => {
            //     return this.viewUploadFiles.attachments.some(compiledFile => compiledFile.name === file.localFileState.name);
            // });

            let file_type_check = this.file_index === '' ? '2' : '1'
            let item_id = this.file_index
            this.compiledFiles = []
            files.forEach(e=>{
                if(e.localFileState.upload_type === undefined){
                    e.localFileState.upload_type = file_type_check
                }
                if(e.localFileState.item_id === undefined){
                    e.localFileState.item_id = item_id
                }
                this.compiledFiles.push(
                    e.localFileState
                )});
            this.file_index = ''
            let fileData = []
            for(let i = 0; i < this.compiledFiles.length; i++) {
                const element = this.compiledFiles[i]
                let reader = new FileReader()
                reader.readAsDataURL(element)
                await new Promise((resolve) => {
                    reader.onloadend = function () {
                        fileData.push({
                            name: element.name,
                            type: element.type,
                            data: reader.result.split(',')[1],
                            upload_type: element.upload_type,
                            item_id: element.item_id,
                        })
                        resolve()
                    }
                })
            }
            // this.uploadedFiles = fileData
            this.saveFiles = fileData
        },
        async exportSPR(exportData){
            let detailsTable = []

            exportData.forEach(e=>{
                e.status = this.STATUS.SERVICE_PAYMENT_REQUEST_STATUS.find(el=>el.value == e.status).text
                e.total_amount = parseFloat(e.total_amount)
                e.created_at = this.$dayjs(e.created_at).format('YYYY-MM-DD')
                e.service_payment_request_type_id = this.GET_SERVICE_PAYMENT_REQUEST_TYPE.find(d=>d.value == e.service_payment_request_type_id).text
                e.service_payment_request_items.forEach(v=>{
                    v.amount = parseFloat(v.amount)
                    v.paid = v.paid == 1 ? 'Yes' : 'No'
                    detailsTable.push(v)
                })
            })
            let sprHeaderAndData = {
                ['SERVICE PAYMENT REQUESTS']: {
                    headers:[
                        { header: 'SPR #', key:'service_payment_request_number', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Type', key:'service_payment_request_type_id', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'User', key:'user_by_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Company', key:'company_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Store Branch', key:'store_branch_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Vendor', key:'vendor_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Total Amount', key:'total_amount', align: 'right', width: 20, style: { numFmt: '#,##0.00', alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Transact Date', key:'created_at', align:'right', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Status', key:'status', align:'right', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                    ],
                    data: []
                },
                ['SERVICE PAYMENT REQUESTS (DETAILED)']: {
                    headers:[
                        { header: 'SPR #', key:'service_payment_request_number', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Type', key:'service_payment_request_type_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Company', key:'company_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Store Branch', key:'store_branch_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Vendor', key:'vendor_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Chart of Account', key:'account_name', align:'right', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Particulars', key:'particulars', align:'right', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Amount', key:'amount', align:'right', width: 20, style: { numFmt: '#,##0.00', alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Recognition Month', key:'recognition_month', align:'right', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Paid', key:'paid', align:'right', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                    ],
                    data: []
                },
            }
            sprHeaderAndData['SERVICE PAYMENT REQUESTS'].data = exportData
            sprHeaderAndData['SERVICE PAYMENT REQUESTS (DETAILED)'].data = detailsTable
            await this.exportExcel(sprHeaderAndData, 'SERVICE PAYMENT REQUESTS', 'F69F1A')
            this.exportLoad = false
        },
        insertUpload(file){
            this.viewUploadFiles.attachments = file
        },
        reComputeTotal() {
            let computedTotalAmount = this.form.service_payment_request_items.reduce((total, item) => {
                return total + parseFloat(item.amount || 0)
            }, 0)
            this.form.total_amount = computedTotalAmount
        },
        saveSPR(url, action, createEnterBill, status = 1){
            this.dialogLoader = true
            this.form.status = status
            let payload = {
                form: this.form,
                trash: this.service_payment_request_items_trash,
                url: url,
                action:action
            }
            this.$store.dispatch('servicePaymentRequestPost', payload).then(response => {
                if (createEnterBill) {
                    this.createEnterBill(
                        this.form.service_payment_request_number,
                        this.form.id,
                        this.form.company_id,
                        this.form.store_branches_id,
                        this.GET_TRANSACT_TYPES_SELECTION.find(e=>e.model_name == 'ServicePaymentRequest').value,
                        this.form.vendor_id,
                        this.form.bill_due,
                        this.form.total_amount,
                        this.form.service_payment_request_items
                    )
                }
                this.saveFileDetails(response.data.spr_id)
            }).catch(err=>{
                console.error(err)
                Swal.fire('Error', err.response.data.message, 'error');
                this.form.status = 1
                this.btnLoad = false
                this.dialogLoader = false
            })
        },
        async saveFileDetails(sprId) {
            this.mainDialog = false
            let chunkedFiles = this.chunkArray(this.saveFiles, 1)
            let chunkedImages = this.chunkArray(this.uploadedImages, 1)
            await this.saveData(chunkedFiles, chunkedImages, sprId);
        },
        chunkArray(array, chunkSize) {
            const chunks = [];
            for (let i = 0; i < array.length; i += chunkSize) {
                chunks.push(array.slice(i, i + chunkSize));
            }
            return chunks;
        },
        async saveData(chunkedFiles, chunkedImages, sprId) {
            this.toProcessFiles = [...chunkedFiles, ...chunkedImages]
            let count = 0;
            this.progressDialog = true;
            const chunksLength = this.toProcessFiles.length;
            if (this.toProcessFiles.length != 0) {
                for (const chunk of this.toProcessFiles) {
                    this.chunksPercentage = Math.round((count / chunksLength) * 100);
                    let payload = {
                        file: chunk,
                        spr_id: sprId,
                        url:'process-files'
                    }
                    await this.$store.dispatch('servicePaymentRequestPost', payload).then(response => {
                        if (response.data.message == 'success') {
                            this.itemSave += chunk.length
                            count ++;
                            if (this.itemSave == this.toProcessFiles.length) {
                                this.chunksPercentage = 100;
                                this.progressDialog = false;
                                this.itemSave = 0;
                            }
                        } else {
                            Swal.fire('Error','- Error in saving file details', 'error');
                            this.progressDialog = false;
                            this.itemSave = 0;
                        }
                    });
                }
            }
            Swal.fire('Success','- Service Payment Request saved!','success');
            this.$store.dispatch('getServicePaymentRequestType',{filter:1})
            this.toggleDialog('close');
            this.getAllTransactions()
            this.progressDialog = false;
            this.btnLoad = false
            this.dialogLoader = false
        },
        getImages(dataUrls) {
            this.images = dataUrls.map(dataUrl => {
                try {
                    if (!dataUrl.startsWith('data:image/jpeg;base64,')) {
                        throw new Error('Invalid data URL format');
                    }

                    const base64String = dataUrl.split(',')[1];
                    const byteString = atob(base64String);
                    const arrayBuffer = new ArrayBuffer(byteString.length);
                    const uint8Array = new Uint8Array(arrayBuffer);

                    for (let i = 0; i < byteString.length; i++) {
                        uint8Array[i] = byteString.charCodeAt(i);
                    }

                    const blob = new Blob([uint8Array], { type: 'image/jpeg' });
                    const timestamp = new Date().toISOString().replace(/[:.-]/g, '');
                    const filename = `captured-image-${timestamp}.jpg`;
                    const file = new File([blob], filename, { type: 'image/jpeg' });
                    return file;
                } catch (error) {
                    console.error('Failed to convert base64 to file:', error);
                    return null;
                }
            }).filter(file => file !== null);
            this.uploadedImages = []
            this.images.forEach(e=>{
                e.upload_type = 2,
                this.uploadedImages.push(e)
            })
            let fileData = []
            for (let i = 0; i < this.uploadedImages.length; i++) {
                const element = this.uploadedImages[i]
                let reader = new FileReader()
                reader.readAsDataURL(element)
                new Promise((resolve)=>{
                    reader.onloadend = function () {
                        fileData.push({
                            name: element.name,
                            type: element.type,
                            data: reader.result.split(',')[1],
                            upload_type: element.upload_type,
                        })
                        resolve()
                    }
                })
            }
            this.uploadedImages = fileData
        },
        clearCameraCapture(){
            this.$refs.cameraCapture.reset();
            this.images = [];
        },
    },
    watch:{
        userAccess:{
            handler(val){
                if (val.length > 0) {
                    this.getAllTransactions()
                }
            },
            deep: true,
            immediate: true
        },
        search:{
            handler(val){
                if(val){
                    this.options.page = 1
                }
            },
            immediate: true
        },
        options:{
            handler(val){
                if (val) {
                    if (this.userAccess.length > 0) {
                        this.getAllTransactions()
                    }
                }
            },
            deep:true
        },
        'form.company_id':{
            handler(oldVal, newVal){
                if (oldVal != newVal && !!this.form.company_id) {
                    this.dispatchCoa = true
                }
                if (!this.form.company_id) {
                    this.form.store_branches_id = null
                }
            },
            immediate: true
        },
        'form.store_branches_id':{
            handler(oldVal, newVal){
                if (oldVal != newVal && !!this.form.store_branches_id) {
                    this.dispatchCoa = true
                }
                if (!this.form.store_branches_id) {
                    this.form.company_id = null
                }
            },
            immediate: true
        },
        'form.service_payment_request_type_id':{
            handler(val){
                if (val) {
                    this.filterDepartmentByType = []
                    this.filterDepartmentByType = this.GET_SERVICE_PAYMENT_REQUEST_TYPE.find(e=>e.value == val).departments
                    // if (this.filterDepartmentByType.length > 0) this.form.department_id = this.filterDepartmentByType[0].value
                }
            },
            immediate: true
        },
        'dispatchCoa':{
            handler(val){
                if (val) {
                    if (this.SERVICE_PAYMENT_REQUEST.ACTION == 'view') {
                        this.dispatchCoa = false
                        return
                    }
                    let payload = {
                        company_id: this.form.company_id,
                        store_branch_id: this.form.store_branches_id,
                    }
                    this.$store.dispatch('getCOASelection',payload)
                    this.dispatchCoa = false
                }
            },
            immediate: true
        },
        'filters.company_id':{
            handler(val){
                if (!val) {
                    this.filters.store_branches_id = null
                }
            },
            immediate: true
        },
        'filters.store_branches_id':{
            handler(val){
                if (!val) {
                    this.filters.company_id = null
                }
            },
            immediate: true
        },
    }
}
</script>
<style scoped>
.small-font {
    font-size: 14px;
}
::v-deep .right-align-text input {
    text-align: right;
}
</style>
