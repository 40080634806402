<template>
    <v-dialog v-model="techProfileDialog" persistent max-width="60%" scrollable>
        <v-card class="pb-4">
            <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Technician Profile</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeTechProfileDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider class="mt-0" />
                <v-container fluid style="background-color: white; overflow-y: scroll" class="ma-0 pa-0">
                    <v-col>
                        <v-card-text>
                            <v-layout class="mx-0 pb-4 px-4">
                                <v-flex lg8>
                                    <div>Level: <span style="color:blue">{{ level }}</span></div>
                                    <div>Name: <span style="color:blue">{{ name }}</span></div>
                                    <div>Specialties: <span style="color:blue">{{ specialties }}</span></div>
                                    <div>Skills: <span style="color:blue">{{ skills }}</span></div>
                                </v-flex>
                                <v-spacer />
                                <v-flex shrink>
                                    <div style="border:1px solid blue">
                                        <img width="150px" height="150px" :src="image" />
                                    </div>
                                </v-flex>
                            </v-layout>

                            <v-layout row class="mx-0 px-4 mb-2">
                                <v-flex lg4 class="pr-2">
                                    <v-card
                                    class="total-cards"
                                    elevation="2"
                                    >
                                        <v-card-text
                                            class="d-flex justify-content-start align-items-center bg-warning">
                                            <div class="card-icon total-amount">
                                                <v-icon style="font-size:40px">mdi-account</v-icon>
                                            </div>
                                            <div class="card-details">
                                                <p class="ma-0 pa-0">Total Repaired</p>
                                                <b style="color:black">&#x20b1;{{accomplishedRepairForms.length}}</b>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                    <!-- <div class="info-box mb-3">
                                        <span class="info-box-icon bg-warning elevation-1"><v-icon class="mdi-account"></v-icon></span>
                                        <div class="info-box-content">
                                            <span class="info-box-text">Total Repaired</span>
                                            <span class="info-box-number">{{ accomplishedRepairForms.length }}</span>
                                        </div>
                                    </div> -->
                                </v-flex>
                                <v-flex lg4 class="pr-2">
                                    <v-card
                                    class="total-cards"
                                    elevation="2"
                                    >
                                        <v-card-text
                                            class="d-flex justify-content-start align-items-center bg-warning">
                                            <div class="card-icon total-amount">
                                                <v-icon style="font-size:40px">mdi-account</v-icon>
                                            </div>
                                            <div class="card-details">
                                                <p class="ma-0 pa-0">Total Re-Estimate</p>
                                                <b style="color:black">&#x20b1;{{reEstimate.length}}</b>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                    <!-- <div class="info-box mb-3">
                                        <span class="info-box-icon bg-warning elevation-1"><i class="mdi-account"></i></span>
                                        <div class="info-box-content">
                                            <span class="info-box-text">Total Re-Estimate</span>
                                            <span class="info-box-number">{{ reEstimate.length }}</span>
                                        </div>
                                    </div> -->
                                </v-flex>
                            </v-layout>

                            <v-card class="mx-4 pa-4 pb-4">
                                <div>Accomplished Repair: <span style="color:blue">{{ accomplishedRepairForms.length }}</span></div>
                                <v-data-table
                                    :headers="headers"
                                    :items="accomplishedRepairForms"
                                >

                                </v-data-table>
                            </v-card>

                            <v-card class="mx-4 pa-4 pb-4">
                                <div>Re-Estimate: <span style="color:blue">{{ reEstimate.length }}</span></div>
                                <v-data-table
                                    :headers="headers"
                                    :items="reEstimate"
                                >

                                </v-data-table>
                            </v-card>
                        </v-card-text>
                    </v-col>
                </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
    data() {
        return {
            technician: null,

            headers: [
                { text: 'RF#', value: 'rf_number' },
                { text: 'Model', value: 'sc_repair_form_item.model' },
                { text: 'Name', value: 'sc_repair_form_item.product_name' },
                { text: 'Category', value: 'sc_repair_form_item.category' },
                { text: 'Serial', value: 'sc_repair_form_item.serial' },
                { text: 'Status', value: 'status' },
                { text: 'Defect', value: 'sc_repair_form_item.defect_description' },
                { text: 'Repair Completed', value: 'sc_repair_form_item.repair_completed_at' },
            ],
            accomplishedRepairForms: [],
            techProfileDialog: false,

            name: '',
            specialties: '',
            skills: '',
            image: '/images/noimage.png',

            reEstimate: [],
            level: '',
        }
    },
    props: ['id'],
    computed:{
        ...mapGetters([
            'GET_TECHNICIAN_PROFILES'
        ])
    },
    watch: {
        id() {
            if(!!this.id) {
                this.getTechnicianProfiles(this.id);
            }
        },
        technician() {
            if(!!this.technician) {
                const { name, specialties, skills, sc_technician_images, sc_repair_forms, level } = this.technician;

                this.level = level;
                this.name = name;
                this.specialties = JSON.parse(specialties).toString().replaceAll(',', ', ');
                this.skills = skills;

                this.accomplishedRepairForms = sc_repair_forms;

                if(!!sc_technician_images && sc_technician_images.length > 0) {
                    this.image = this.technician.sc_technician_images[0].path;
                }

                if(sc_repair_forms.length > 0) {
                    this.reEstimate = _.compact(sc_repair_forms).filter(x=>x.sc_rf_movements_count > 1);
                }
            }
        }
    },
    methods: {
        clearFields() {
            this.technician = null;
            this.accomplishedRepairForms = [];
            this.reEstimate = [];

            this.level = '';
            this.name = '';
            this.specialties = '';
            this.skills = '';
        },
        showTechProfileDialog() {
            this.techProfileDialog = true;
        },
        closeTechProfileDialog() {
            this.clearFields();
            this.$emit('closeTechProfile', true);
            this.techProfileDialog = false;
        },
        getTechnicianProfiles(id) {
            this.$store.dispatch('getTechnicianProfiles',id).then(response=>{
                this.technician = this.GET_TECHNICIAN_PROFILES;
            });
        }
    }
}
</script>

<style>

</style>
