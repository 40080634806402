<template>
    <div>
        <!-- Add Location Dialog -->
        <v-dialog v-model="locationDialog" persistent max-width="55%">
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <h4 v-if="action == 'edit'" class="headline">Edit Location</h4>
                            <h4 v-else-if="action == 'view'" class="headline">View Location</h4>
                            <h4 v-else class="headline">Add Location</h4>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeLocationDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider class="mt-0" />

                <v-row class="ma-2 px-2">
                    <span class="pl-1 font-weight-bold">Details</span>
                    <v-row class="w-100 pt-4 px-4">
                        <v-col>
                            <v-text-field
                                v-model="name"
                                :readonly="action == 'view'"
                                :rules="rules.default"
                                label="Name"
                                dense
                                outlined
                            >
                                <template v-if="action != 'view'" #append>
                                    <span style="color:red">*</span>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="code"
                                :readonly="action == 'view'"
                                :rules="rules.default"
                                label="Code"
                                dense
                                outlined
                            >
                            <template v-if="action != 'view'" #append>
                                <span style="color:red">*</span>
                            </template>
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="contactNumber"
                                :readonly="action == 'view'"
                                label="Contact Number"
                                dense
                                outlined
                                >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="w-100 px-4">
                        <v-col cols="12">
                            <v-textarea
                                :readonly="action == 'view'"
                                v-model="address"
                                height="10"
                                label="Address"
                                outlined
                                dense
                                >
                            </v-textarea>
                        </v-col>
                    </v-row>
                </v-row>
                <v-divider class="mb-0 pb-0"/>
                <v-row class="d-flex flex-column ma-2 px-2">
                    <span class="font-weight-bold pl-1">Warehouses</span>
                    <v-row class="w-100 ma-1 pt-2 pr-1">
                        <v-col cols="12" md="6" class="pa-0">
                            <v-row class="d-flex align-center ma-0 pa-0 pr-1">
                                <label for="default" class="mr-4">Default:</label>
                                <v-autocomplete
                                    v-model="locationWarehouse.default_warehouse_id"
                                    :items="warehouses"
                                    :readonly="action == 'view'"
                                    :rules="rules.default"
                                    item-text="text"
                                    item-value="value"
                                    auto-select-first
                                    dense
                                    outlined
                                    id="default"
                                >
                                    <template v-if="action != 'view'" #append>
                                    <span style="color:red" class="ml-2">*</span>
                                    </template>
                                </v-autocomplete>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="6" class="pa-0 pl-1">
                            <v-row class="d-flex align-center ma-0 pa-0">
                                <label for="reserve" class="mr-1">Reserve:</label>
                                <v-autocomplete
                                    v-model="locationWarehouse.reserve_warehouse_id"
                                    :items="warehouses"
                                    :readonly="action == 'view'"
                                    :rules="rules.default"
                                    item-text="text"
                                    item-value="value"
                                    auto-select-first
                                    dense
                                    outlined
                                    id="reserve"
                                >
                                    <template v-if="action != 'view'" #append>
                                        <span style="color:red" class="ml-2">*</span>
                                    </template>
                                </v-autocomplete>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="w-100 ma-1 pr-1">
                        <v-col cols="12" md="6" class="pa-0 pr-1">
                            <v-row class="d-flex align-center ma-0 pa-0">
                                <label for="repair" class="mr-6">Repair:</label>
                                <v-autocomplete
                                    v-model="locationWarehouse.repair_warehouse_id"
                                    :items="warehouses"
                                    :readonly="action == 'view'"
                                    :rules="rules.default"
                                    item-text="text"
                                    item-value="value"
                                    auto-select-first
                                    dense
                                    outlined
                                    id="repair"
                                >
                                    <template v-if="action != 'view'" #append>
                                        <span style="color:red" class="ml-2">*</span>
                                    </template>
                                </v-autocomplete>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="6" class="pa-0 pl-1">
                            <v-row class="d-flex align-center ma-0 pa-0">
                                    <label for="baklas" class="mr-4">Baklas:</label>
                                    <v-autocomplete
                                        v-model="locationWarehouse.baklas_warehouse_id"
                                        :items="warehouses"
                                        :readonly="action == 'view'"
                                        :rules="rules.default"
                                        item-text="text"
                                        item-value="value"
                                        auto-select-first
                                        dense
                                        outlined
                                        id="baklas"
                                    >
                                        <template v-if="action != 'view'" #append>
                                            <span style="color:red" class="ml-2">*</span>
                                        </template>
                                    </v-autocomplete>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="w-100 ma-1 pr-1">
                        <v-col cols="12" md="6" class="pa-0 pr-1">
                            <v-row class="d-flex align-center ma-0 pa-0">
                                <label for="defective" class="mr-1">Defective:</label>
                                <v-autocomplete
                                    v-model="locationWarehouse.defective_warehouse_id"
                                    :items="warehouses"
                                    :readonly="action == 'view'"
                                    :rules="rules.default"
                                    item-text="text"
                                    item-value="value"
                                    auto-select-first
                                    dense
                                    outlined
                                    id="defective"
                                >
                                    <template v-if="action != 'view'" #append>
                                        <span style="color:red" class="ml-2">*</span>
                                    </template>
                                </v-autocomplete>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="6" class="pa-0 pl-1">
                            <v-row class="d-flex align-center ma-0 pa-0">
                                <label for="disposal" class="mr-1">Disposal:</label>
                                <v-autocomplete
                                    v-model="locationWarehouse.disposal_warehouse_id"
                                    :items="warehouses"
                                    :readonly="action == 'view'"
                                    :rules="rules.default"
                                    item-text="text"
                                    item-value="value"
                                    auto-select-first
                                    dense
                                    outlined
                                    id="disposal"
                                >
                                    <template v-if="action != 'view'" #append>
                                        <span style="color:red" class="ml-2">*</span>
                                    </template>
                                </v-autocomplete>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-row>
                <v-row class="d-flex justify-end ma-0 px-0">
                    <v-btn v-if="action == 'edit'" @click="update()" class="ma-4 px-3">Update</v-btn>
                    <v-btn v-if="action == 'add'" @click="submit()" class="ma-4 px-3">Submit</v-btn>
                </v-row>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
export default {
    data() {
        return {
            warehouses: [],
            code: '',
            name: '',
            address: '',
            contactNumber: '',
            scLocation: null,
            scLocationWarehouse: null,
            locationWarehouse: {
                default_warehouse_id:null,
                reserve_warehouse_id:null,
                repair_warehouse_id:null,
                defective_warehouse_id:null,
                baklas_warehouse_id:null,
                disposal_warehouse_id:null,
            },
            rules: {
                default: [
                v => !!v || 'This is required',
                ],
            },
        }
    },
    computed: {
        ...mapGetters([
            'GET_ALL_WAREHOUSES',
            'GET_SHOW_LOCATION',
        ]),
        locationDialog() {
            return this.$store.state.service.locationDialog;
        },
        action() {
            return this.$store.state.service.scLocation.action;
        },
        scLocationId() {
            return this.$store.state.service.scLocation.id;
        },
        scLocationWarehouses() {
            return this.$store.state.service.scLocationWarehouses
        }
    },
    watch: {
        async scLocationId() {
            if(!!this.scLocationId) {
                await this.getWarehouses()
                await this.getScLocation();
            }
        },
        scLocationWarehouses() {
            if (this.scLocationWarehouses) {
                this.code = this.scLocationWarehouses.code;
                this.name = this.scLocationWarehouses.name;
                this.address = this.scLocationWarehouses.address;
                this.contactNumber = this.scLocationWarehouses.contact_number;
                this.locationWarehouse.default_warehouse_id = this.scLocationWarehouses.default_warehouse_id;
                this.locationWarehouse.repair_warehouse_id = this.scLocationWarehouses.repair_warehouse_id;
                this.locationWarehouse.reserve_warehouse_id = this.scLocationWarehouses.reserve_warehouse_id;
                this.locationWarehouse.defective_warehouse_id = this.scLocationWarehouses.defective_warehouse_id;
                this.locationWarehouse.baklas_warehouse_id = this.scLocationWarehouses.baklas_warehouse_id;
                this.locationWarehouse.disposal_warehouse_id = this.scLocationWarehouses.disposal_warehouse_id;
            }
        }
    },
    methods: {
        getWarehouses(){
            let payload = {
                service: 1,
                disposal: 1,
            }
            this.$store.dispatch('getAllWarehouses',payload).then(response => {
                this.warehouses = this.GET_ALL_WAREHOUSES
            });
        },
        // showLocationDialog() {
        //     this.getWarehouses();
        //     this.$store.commit('scLocationShowAddEditDialog');
        //     this.$store.commit('scLocationAddAction');
        // },
        closeLocationDialog() {
            this.$store.commit('scLocationCloseAddEditDialog');
            this.$store.commit('scLocationClearAction');
            this.$store.commit('scLocationClearId');
            this.clearFields();
        },
        clearFields() {
            this.locationWarehouse.default_warehouse_id = null;
            this.locationWarehouse.repair_warehouse_id = null;
            this.locationWarehouse.reserve_warehouse_id = null;
            this.locationWarehouse.defective_warehouse_id = null;
            this.locationWarehouse.baklas_warehouse_id = null;
            this.code = '';
            this.name = '';
            this.address = '';
            this.contactNumber = '';
            this.$store.commit('setLocationWarehouses', {});
        },
        getScLocation() {
            this.$store.dispatch('getLocation',this.scLocationId).then(response=>{
                this.scLocation = this.GET_SHOW_LOCATION.sc_location;
                this.scLocationWarehouse = this.GET_SHOW_LOCATION.sc_location_warehouse;
            });
        },
        submit() {
            if(!this.requiredFields(this.locationWarehouse)) {
                swal.fire({
                    title: 'Are you sure you want to Create Location?',
                    text: 'Make sure that all information are correct',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then((result)=>{
                    if(result.isConfirmed) {
                        let payload = {
                            warehouses: this.locationWarehouse,
                            code: this.code,
                            name: this.name,
                            address: this.address,
                            contactNumber: this.contactNumber
                        }
                        this.$store.dispatch('createLocation',payload).then(response=>{
                            if(!!response.data.msg) {
                                this.closeLocationDialog();
                                this.clearFields();
                                this.$store.commit('scLocationCreateLocationTrue');
                                swal.fire("", response.data.msg, "success").then(ok=>{
                                    if(ok) {
                                        this.$store.commit('scLocationCreateLocationFalse');
                                    }
                                });
                            }
                        });
                    }
                });
            }
        },
        update() {
            if(!this.requiredFields(this.locationWarehouse)) {
                swal.fire({
                    title: 'Are you sure you want to Update Location?',
                    text: 'Make sure that all information are correct',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then((result)=>{
                    if(result.isConfirmed) {
                        let payload = {
                            scLocationId: this.scLocationId,
                            warehouses: this.locationWarehouse,
                            code: this.code,
                            name: this.name,
                            address: this.address,
                            contactNumber: this.contactNumber
                        }
                        this.$store.dispatch('updateLocation',payload).then(response=>{
                            if(!!response.data.msg) {
                                this.closeLocationDialog();
                                this.clearFields();
                                this.$store.commit('scLocationCreateLocationTrue');
                                swal.fire("", response.data.msg, "success").then(ok=>{
                                    if(ok) {
                                        this.$store.commit('scLocationCreateLocationFalse');
                                    }
                                });
                            }
                        });
                    }
                });
            }
        },
        requiredFields(data) {
            let warning = '';
            if (!data.default_warehouse_id) warning += 'Please select a Default Warehouse.<br>';
            if (!data.reserve_warehouse_id) warning += 'Please select a Reserve Warehouse.<br>';
            if (!data.repair_warehouse_id) warning += 'Please select a Repair Warehouse.<br>';
            if (!data.defective_warehouse_id) warning += 'Please select a Defective Warehouse.<br>';
            if (!data.baklas_warehouse_id) warning += 'Please select a Baklas Warehouse.<br>';
            if (!data.disposal_warehouse_id) warning += 'Please select a Disposal Warehouse.<br>';

            if (!this.code) warning += 'Please ensure that the empty field for the code is filled in.<br>';
            if (!this.name) warning += 'Please ensure that the empty field for the name is filled in.<br>';
            
            if (warning !== '') return swal.fire({
                title: 'Please Fill out Information:',
                html: warning,
                icon: "warning",
            })
        },
    },
    mounted() {
        this.getWarehouses()
    }
}
</script>

<style>

</style>
