<template>
    <v-form ref="form" v-model="valid" >
        <v-card>
            <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                <span class="text-h5">Leave Balance</span>
                <v-row class="m-0">
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                            text
                            icon
                            small
                            color="gray"
                            class="float-right"
                            @click="closeDialog()"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-col cols="4">
                    <v-text-field
                        v-model="form.employee_full_name"
                        outlined
                        readonly
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-container class="pa-0" fluid>
                    <v-data-table
                        :headers="datatable.headers"
						:items="datatable.items"
                        :options.sync="datatable.options"
                        :loading="loading.table"
						:multi-sort="true"
						:disable-sort="true"
						loading-text="Loading... Please wait"
						fixed-header
                    >
                        <!-- <template v-slot:[`item.action`]="{ item }">
                            <v-btn text icon color="orange">
                                <v-icon
                                class="btn-action"
                                small
                                @click="viewLeaveBalance(item)"
                                >mdi-eye</v-icon
                                >
                            </v-btn>
                            <v-btn text icon color="blue" v-if="userAccess.includes('manual_edit_leave_balance')">
                                <v-icon
                                class="btn-action"
                                small
                                @click="editLeaveBalance(item)"
                                >mdi-pencil</v-icon
                            >
                            </v-btn>
                        </template> -->
                        <template v-slot:item="{ item, index }">
                            <tr v-if="item.editActive == false">
                                <!-- Date -->
                                <td> {{ item.leave_type_name }} </td>
                                <!-- Day -->
                                <td v-if="form.pro_rated === null"> {{ parseFloat(item.default) }} </td>
                                <!--  -->
                                <td> {{ parseFloat(item.balance) }} </td>
                                <!-- Hours Worked -->
                                <td> {{ parseFloat(item.used) }} </td>

                                <!-- <td v-if="actions != 'View'"  class="d-flex justify-content-center align-items-center">
                                    <v-tooltip bottom color="blue">
                                        <template v-slot:activator="{ on, attrs }" >
                                            <v-btn
                                                icon
                                                small
                                                @click="item.editActive = true"
                                                color="blue"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>mdi-pencil</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Edit Data</span>
                                    </v-tooltip>
                                </td> -->
                            </tr>
                            <tr v-if="item.editActive == true">
                                <td>
                                    <v-text-field
                                        v-model="item.leave_type_name"
                                        outlined
                                        readonly
                                        dense
                                    >
                                    </v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                        v-model="item.balance"
                                        outlined
                                        dense
                                    >
                                    </v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                        v-model="item.used"
                                        outlined
                                        readonly
                                        dense
                                    >
                                    </v-text-field>
                                </td>

                                <!-- <td v-if="actions != 'View'"  class="d-flex justify-content-center align-items-center">
                                    <v-tooltip  bottom color="green">
                                        <template v-slot:activator="{ on, attrs }" >
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                icon
                                                small
                                                @click="item.editActive = false"
                                                color="green"
                                            >
                                                <v-icon>mdi-check-bold</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Save Data</span>
                                    </v-tooltip>
                                </td> -->
                            </tr>
                        </template>
                    </v-data-table>
                </v-container>
            </v-card-text>

            <v-card-actions
                class="text-center"
                style="border-top: 1px solid #dee2e6"
            >
                <v-row class="ma-1 pb-2">
                    <v-col cols="12" v-if="actions != 'View'">
                        <v-btn
                            @click="
                                actions == 'Submit'? addUpdateLeaveBalance('add') : addUpdateLeaveBalance('update')"
                            :disabled="disabled_view"
                        >
                            <span>{{ actions }}</span>
                        </v-btn>
                        <span class="pr-12">&nbsp;</span>
                    </v-col>

                    <v-col cols="12" class="ml-5" v-if="actions == 'View'">
                        <v-btn
                            v-if="GET_HR_LEAVES_DATA_VIEW.status == 1 && (userAccess.includes('approve_leave_request') || USERACCOUNT_LOAD.manager == 1)"
                            @click="changeLeaveRequestStatus('approve')"
                        >
                            <span>Approve</span>
                        </v-btn>
                        <v-btn
                            v-if="GET_HR_LEAVES_DATA_VIEW.status == 2 && (userAccess.includes('confirm_leave_request') || USERACCOUNT_LOAD.manager == 1)"
                            @click="changeLeaveRequestStatus('confirm')"
                        >
                            <span>Confirm</span>
                        </v-btn>
                        <span class="pr-12">&nbsp;</span>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-form>
</template>
<script>
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
export default {
    mixins: [SharedFunctionsComponentVue],
    data() {
        return {
            valid: false,
            overlay: false,
            disabled_view: false,
            errors: {
                required: [(v) => !!v || "Field is required"],
                number: [
                    (v) => !!v || "Cost is required",
                    (v) => !isNaN(v) || "Input must be a number",
                ],
            },
            inclusive_date_menu:false,
            form: {},
            leave_type_selection:[],
            selection:{
                leave_type: [],
                employee_lists: [],
            },
            component_dispatches: [
                'getSelectionDepartmentRole',
                'getLeaveTypeSelection',
                'getEmployeeDataDropdown',
            ],
            userAccess: [],
            loading:{
                button: false
            },

            datatable:{
                headers: [
                    { text: "Leave Type", align: "left", value: "leave_type_name"},
                    { text: "Default", align: "left", value: "default"},
                    { text: "Balance", align: "left", value: "balance"},
                    { text: "Used", align: "left", value: "used"},
                    // { text: "Action", align: "center", value: "action" },
                ],
                items: [],
                itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
                itemsPerPage: 10,
			    options: {},
                totalItems: 0
            },
            loading:{
                table: false
            }
        };
    },
    computed: {
        ...mapGetters([
            "ACTION",
            'GET_DEPARTMENT_ROLE_LOAD',
			"GET_HR_LEAVES_DATA_VIEW",
            "GET_HR_LEAVES_NEW_DATA",
            "DIALOGS",
            "GET_LEAVE_TYPE_SELECTION",
            "GET_HR_DISPATCH",
            "GET_EMPLOYEE_DATA",
            "USER_ACCESS",
            "USERACCOUNT_LOAD"
        ]),
        actions() {
            let index = this.datatable.headers.findIndex(item => item.value === 'default');
            // let index = this.datatable.headers.findIndex(item => item.value === 'action');

            if (this.ACTION == "Submit") {
                this.disabled_view = false;
                this.isViewBtn = false;
                return "Submit";
            } else if (this.ACTION == "Update") {
                this.disabled_view = false;
                this.isViewBtn = true;
                this.getLeaveBalances();
                if (index == -1) {
                    this.datatable.headers.push(
                        { text: 'Action', align: 'left', value: 'action' }
                    );
                }
                return "Update";
            } else if (this.ACTION == "View") {
                this.disabled_view = true;
                this.isViewBtn = true;
                this.getLeaveBalances();
                if(this.GET_HR_LEAVES_DATA_VIEW.pro_rated  !== null){
                    if (index !== -1) {
                        this.datatable.headers.splice(index, 1);
                    }
                }else{
                    console.log(index)
                    if (index == -1) {
                        this.datatable.headers.splice(1,
                            0,
                            { text: 'Default', width: '15%', align: 'left', value: 'default' }
                        );
                    }
                }
                    // if (index !== -1) {
                    //     this.datatable.headers.splice(index, 1);
                    // }
                return "View";
            }
        },
        dateRangeText () {
            return this.form.inclusive_date.join(' - ')
        },
    },
    components:{
    },
    created() {
        this.initialForm();
    },
    mounted() {
        this.getAllDropdowns();
    },
    methods: {
        initialForm() {
            let currentDate = this.$dayjs();

            // Set the first element to the start of the day
            let startOfDay = currentDate.clone().startOf('day');

            // Set the second element to the end of the day
            let endOfDay = currentDate.clone().endOf('day');
            this.valid = true;
            this.form = {
                leave_type_id:'',
                inclusive_date: [],
                // inclusive_date: [startOfDay.toDate(), endOfDay.toDate()],
                total_days: '',
                reason: '',
                others: '',
                isOthers: false,
                with_pay: 0
            };
        },
        height(width) {1
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "100%";
                case "sm":
                    return "100%";
                case "md":
                    return width;
                case "lg":
                    return width;
                case "xl":
                    return width;
            }
        },
        closeDialog() {
            this.$store.commit("DIALOG", false);
            this.$store.commit("HR_LEAVES_DATA_VIEW", {});
        },
        addUpdateLeaveBalance(action = 'add') {
            this.$refs.form.validate();
            let payload = {
                id: this.form.id,
                leaves: this.datatable.items
            }
            this.$store.dispatch(this.GET_HR_DISPATCH[action], payload).then(response=>{
                Swal.fire(response.icon.toUpperCase(),response.message,response.icon);
                this.$store.commit("DIALOG", false);
            })
        },
        async getLeaveBalances() {
            this.datatable.items = this.GET_HR_LEAVES_DATA_VIEW.leaves.data;
            this.form.id = this.GET_HR_LEAVES_DATA_VIEW.id;
            this.form.pro_rated = this.GET_HR_LEAVES_DATA_VIEW.pro_rated;
            this.form.employee_full_name = this.GET_HR_LEAVES_DATA_VIEW.leaves.employee_full_name;
        },
        getAllDropdowns(){
            this.component_dispatches.forEach(e =>{
                this.$store.dispatch(e);
            });
        },
        uploadedData(data) {
            let that = this;
            if (!data) {
                this.form.leaveFiles = null;
                return false
            }
            this.form.leaveFiles = [];

            data.attachments.forEach((file, index)=>{

                const blobFile = new Blob([file], { type: file.type });
                var reader  = new FileReader();
                reader.readAsDataURL(blobFile);
                reader.onloadend = function () {
                    that.form.leaveFiles.push(reader.result)
                }
            })
            // this.form.files = data;
        },
        changeLeaveRequestStatus(status){
            Swal.fire({
                text: `Are you sure you want to ${status}?`,
                icon:'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(async(result) => {
                if(result.isConfirmed) {
                    let payload = {
                        id: this.GET_HR_LEAVES_DATA_VIEW.id,
                        status: status
                    }
                    this.$store.dispatch('changeLeaveRequestStatus', payload).then((res)=>{
                        Swal.fire(
                            "Success",
                            "",
                            "success"
                        )
                        this.$store.commit('DIALOG', false);
                    }).catch((err)=>{
                        console.log(err)
                        Swal.fire(
                            "Error",
                            "",
                            "error"
                        )
                        this.$store.commit('DIALOG', false);
                    })
                }
            })
        },
    },
    watch: {
        // GET_HR_SETTINGS_NEW_DATA_TRIGGER: {
        //     handler(val) {
        //         if (val) {
        //             this.closeDialog();
        //             this.$store.commit("DIALOG", false);
        //             this.$store.commit("HR_SETTINGS_NEW_DATA_TRIGGER", false);
        //             this.initialForm();
        //         }
        //     },
        // },
        DIALOGS: {
            handler(val) {
                if (val) {
                    this.initialForm();
                }
            },
        },
        'form.inclusive_date':{
            deep:true,
            handler(val){
                if(val.length == 2){

                    let startDate = this.$dayjs(this.form.inclusive_date[0]);
                    let endDate = this.$dayjs(this.form.inclusive_date[1]);

                    if (startDate.isValid() && endDate.isValid()) {
                        const hoursDifference = endDate.diff(startDate, 'hour', true);
                        const daysDifference = hoursDifference / 24;
                        this.form.total_days = daysDifference.toFixed(2);
                    }

                }
                if(val[0] > val[1]){
                    Swal.fire("Leave Date Range Not Valid","","warning")
                    this.form.inclusive_date = [];
                    this.form.total_days = 0;
                }
            }
        },
        GET_LEAVE_TYPE_SELECTION:{
            handler(val){
                this.selection.leave_type = val
            }
        },
        GET_EMPLOYEE_DATA:{
            handler(val){
                this.selection.employee_lists = val
            }
        },
        'form.isOthers' :{
            handler(val){
                if(val){
                    this.form.leave_type_id = '';
                    this.form.others = '';
                }else{
                    this.form.leave_type_id = this.GET_HR_LEAVES_DATA_VIEW.leave_type_id;
                }
            }
        },
        USER_ACCESS:{
            handler(val){
                this.userAccess = val.map(e=>e.actions_code);
            }
        },
    },
};
</script>
<style scoped>
.camera {
    display: block;
    width: 300px;
    margin: 0 auto;
    background-color: black;
}

.picture {
    display: block;
    width: 335px;
    height: 335px;
}
canvas {
    display: block;
    width: 100%;
    height: 100%;
}
.upload-file {
    font-size: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 6px;
}
</style>
