<template>
    <span>
        <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }" >
                    <v-btn
                        style="opacity:1;"
                        v-bind="attrs"
                        v-on="on"
                        @click="showFilesViewerDialog()"
                    ><v-icon>mdi-file-document-multiple-outline</v-icon>
                    </v-btn>
            </template>
            <span>View Files</span>
        </v-tooltip>
        <v-dialog v-model="filesViewerDialog" persistent max-width="50%">
            <v-card>
                <v-row class="m-0">
                    <v-card-title>
                        <v-btn text icon color="gray" class="float-right" @click="closeFilesViewerDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-card-title>
                </v-row>
                <v-divider class="my-0" />
                <v-data-table
                    class="pa-4"
                    :headers="filesHeader"
                    :items="files"
                    :loading="loading"
                    :no-data-text="noDataText"
                >
                <template v-slot:item.action="{ item }">
                    <td class="px-0">
                        <span class="text-nonwrap">
                            <v-btn text icon color="orange">
                                <v-icon class="btn-action" small @click="showFile(item); newImagePath()">mdi-eye</v-icon>
                            </v-btn>
                            <v-btn text icon color="blue">
                                <v-icon class="btn-action" small @click="downloadFile(item)">mdi-download</v-icon>
                            </v-btn>
                            <!-- <v-btn text icon color="red">
                                <v-icon class="btn-action" small @click="deleteFile(item)">mdi-delete</v-icon>
                            </v-btn> -->
                        </span>
                    </td>
                </template>
                </v-data-table>
            </v-card>
        </v-dialog>
        <v-dialog v-model="viewFile" persistent max-width="80%">
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">{{ itemName }}</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeViewFile()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider class="my-0" />

                <div class="iframe-container d-flex justify-center align-center">
                    {{ itemPath }}
                    <v-img :src="sourceFrame" class="responsive-iframe"/>
                </div>
            </v-card>
        </v-dialog>
    </span>
</template>
<script>
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
export default {
    data() {
        return {
            filesHeader: [
                { text: 'Name', value: 'name' },
                { text: 'Uploaded By', value: 'uploaded_by_name' },
                { text: 'Date', value: 'created_at' },
                { text: 'Action', value: 'action' },
            ],
            files: [],
            id:'',
            loading: true,
            noDataText: '',
            viewFile: false,
            itemPath: '',
            itemName: '',
            withDelete: false,
            folder_name:'',
            sourceFrame:'',
        }
    },
    watch: {
        files :{
            handler(val){
                if(val.length > 0) {
                    this.loading = false;
                } else {
                    this.loading = false,
                    this.noDataText = 'No Uploaded Files';
                }
            }
        },
        // GET_HR_OTHER_TABS_DIALOG_VIEW:{
        //     deep:true,
        //     handler(val){
        //         // this.id = val.id
        //     }
        // },
    },
    computed: {
        ...mapGetters([
            'GET_HR_OTHER_TABS_DIALOG_VIEW',
        ]),
        filesViewerDialog() {
            return this.$store.state.accounting.files.filesViewerDialog;
        },
    },
    mounted(){
        this.id = this.GET_HR_OTHER_TABS_DIALOG_VIEW.id
    },
    methods: {
        clearFields() {
            this.loading = true;
            this.noDataText = '';
        },
        getFiles(){
            let payload = {
                id : this.id
            }
            this.$store.dispatch('getManpowerRequestfiles',payload).then(response=>{
                this.files = response;
            });
        },
        showFilesViewerDialog() {
            this.$store.commit('filesShow');
            this.getFiles()
            this.$emit('openFilesViewer', true);
            this.folder_name = 'manpower_request_files'
        },
        closeFilesViewerDialog() {
            this.$store.commit('filesClose');
            this.clearFields();
            this.$emit('closeFilesViewer', true);
        },
        date(item) {
            if(!!item) {
                return this.$dayjs(item.created_at).format('YYYY-MM-DD, hh:mm:ss a');
            }
            return '';
        },
        showFile(file) {
            this.viewFile = true;
            this.itemPath = file.path;
            this.itemName = file.name
            this.$store.commit('filesClose');
        },
        downloadFile(file) {
            swal.fire({
                text: 'Are you sure you want to download?',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(async(result) => {
                if(result.isConfirmed) {

                    let uri = ''
                    file.name = file.name.replaceAll(' ','%20')
                    file.name = file.name.replaceAll('#','%23')
                    uri = `${process.env.VUE_APP_API_HOST}/img/hr?image_name=${file.name}&folder_name=${this.folder_name}`

                    fetch(uri)
                    .then(resp => resp.blob())
                    .then(blobobject => {
                        const blob = window.URL.createObjectURL(blobobject);
                        const anchor = document.createElement('a');
                        anchor.style.display = 'none';
                        anchor.href = blob;
                        anchor.download = file.name;
                        document.body.appendChild(anchor);
                        anchor.click();
                        window.URL.revokeObjectURL(blob);
                    })
                    .catch(() => console.log('An error in downloadin the file'));
                        }
                    });
        },
        deleteFile(file) {
            swal.fire({
                text: 'Are you sure you want to delete?',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then((result) => {
                if(result.isConfirmed) {
                    let payload = {
                        fileId: file.id
                    }
                    this.$store.dispatch('deleteManpowerRequestFile',payload).then(response=>{
                        this.getFiles();
                        swal.fire("", response.data.msg, "success");
                    }).catch(e => {
                        swal.fire("", e.data.error, "error");
                    })
                }
            });
        },
        closeViewFile() {
            this.itemPath = "";
            this.viewFile = false;
            this.id = "";
            this.$store.commit('filesShow');
        },
        async newImagePath(path){
            let uri = ''
            this.itemName = this.itemName.replaceAll(' ','%20')
            this.itemName = this.itemName.replaceAll('#','%23')
            // if(!!this.rfId){
            //     uri = `${process.env.VUE_APP_API_HOST}/img/service?image_name=${this.itemName}`
            // }
            // else{
            //     uri = `${process.env.VUE_APP_API_HOST}/img/accounting?image_name=${this.itemName}&folder_name=${this.folder_name}`
            // }
            uri = `${process.env.VUE_APP_API_HOST}/img/hr?image_name=${this.itemName}&folder_name=${this.folder_name}`
            fetch(uri)
            .then(resp => resp.blob())
            .then(blobobject => {
                const blob = window.URL.createObjectURL(blobobject);
                this.sourceFrame = blob;
            })
        }
    }
}
</script>
<style scoped>
    .iframe-container {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    }
    .responsive-iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 80%;
        height: 80%;
        margin: 20px auto;
    }
    .iframe img{
        margin: 0 auto !important;
    }
</style>
