
import ServiceDelivery from "@/views/main/modules/Services/Views/Delivery.vue";
import repository from "@/api/credentials";

const routes = 
        [
            {
              path: "/services/delivery/:id",
              name: "servicedelivery",
              component: ServiceDelivery,
              beforeEnter:(to,from,next)=>{
                repository.checkAccessUser(to.params.id).then((response)=>{
                    if(response.data == 'fail'){
                        next('/no-access')
                        }else{
                        next()
                    }
                }).catch(()=>{

                })

          },
              meta:{
                   requiresAuth:true
                 }
          }
      ]


export default routes;
