<template>
    <div class="w-100">
        <v-app id="my_vue_app">
            <v-card>
                <v-row no-gutters class="m-2">
                    <v-col cols="12" sm="12" md="3" class="p-1">
                        <v-autocomplete
                            v-model="filters.vehicle_id"
                            :items="list.vehicle_list"
                            label="Vehicle"
                            outlined
                            hide-details
                            persistent-placeholder
                            auto-select-first
                            cache-items
                            allow-overflow
                            @input="filter()"
                        >
                            <template v-slot:selection="{ item }">
                                <span class="dropdown-selection">
                                    {{ item.text }}
                                </span>
                            </template>
                            <template v-slot:item="{ item }">
                                <span class="dropdown-items">
                                    {{ item.text }}
                                </span>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="6" sm="4" md="1" class="p-1">
                        <v-menu
                            v-model="filters.menu_date_from"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.delivery_dates[0]"
                                    readonly
                                    hide-details
                                    outlined
                                    v-on="on"
                                    label="Date From"
                                >
                                </v-text-field>
                            </template>
                            <v-date-picker v-model="filters.delivery_dates[0]" @input="filters.menu_date_from = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="6" sm="4" md="1" class="p-1">
                        <v-menu
                            v-model="filters.menu_date_to"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.delivery_dates[1]"
                                    readonly
                                    hide-details
                                    outlined
                                    v-on="on"
                                    label="Date To"
                                >
                                </v-text-field>
                            </template>
                            <v-date-picker v-model="filters.delivery_dates[1]" @input="filters.menu_date_to = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <v-row no-gutters class="mx-2">
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <v-sheet
                            tile
                            height="54"
                            class="d-flex"
                        >
                            <v-btn
                                icon
                                class="mx-1"
                                @click="$refs.calendar.prev()"
                            >
                                <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>
                            <v-btn
                                icon
                                class="ml-1 mr-4"
                                @click="$refs.calendar.next()"
                            >
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>
                            <v-btn
                                @click="setToday"
                            >
                                Today
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-toolbar-title v-if="$refs.calendar">
                                {{ currentDay }}
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-menu bottom right>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on">
                                        <span>{{ calendar.calendar_view.value| uppercase }}</span>
                                        <v-icon right>mdi-menu-down</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item v-for="calendar_view in calendar.calendar_view.list" :key="calendar_view" @click="calendar.calendar_view.value = calendar_view">
                                        <v-list-item-title>{{ calendar_view | capitalize }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-sheet>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <v-sheet>
                            <v-calendar
                                ref="calendar"
                                v-model="calendar.value"
                                :weekdays="calendar.week_view.value"
                                :type="calendar.calendar_view.value"
                                :events="calendar.events"
                                :event-overlap-mode="calendar.overlap_mode"
                                :event-overlap-threshold="30"
                                :event-color="getEventColor"
                                :categories="calendar.categories"
                                category-show-all
                                category-days="7"
                                event-name="event"
                                color="primary"
                                dark
                                @change="getEvents"
                                @click:event="showDeliveries"
                                @click:more="viewDay"
                                @click:date="viewDay"
                            >
                                <template v-slot:event="{ event }">
                                    {{ event.event }}
                                </template>
                            </v-calendar>
                        </v-sheet>
                    </v-col>
                </v-row>
                <v-dialog :width="$vuetify.breakpoint.mobile ? '100%' : '90%'" v-model="view_deliveries.show" scrollable>
                    <v-card height="100%">
                        <v-row no-gutters>
                            <v-col cols="10">
                                <v-card-title>
                                    {{ `${view_deliveries.parameters.delivery_date} Deliveries of ${view_deliveries.parameters.vehicle_name}` }}
                                </v-card-title>
                            </v-col>
                            <v-col cols="2" class="pull-right">
                                <v-btn text icon color="gray" class="float-right" @click="toggle(false)">
                                    <v-icon>mdi-close-circle</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-divider class="ma-1 pa-0"></v-divider>
                        <v-row no-gutters>
                            <!-- <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="px-2">
                                <div class="info-box m-0">
                                    <span class="info-box-icon bg-success elevation-1"><i class="fas fa-shopping-cart"></i></span>
                                    <div class="info-box-content">
                                        <span class="info-box-text">SO Total Amount</span>
                                        <span class="info-box-number">&#x20b1;{{ soTotalAmount | currency }}</span>
                                    </div>
                                </div>
                            </v-col> -->
                            <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="px-2">
                                <v-card
                                    class="total-cards"
                                    elevation="2"
                                >
                                    <v-card-text
                                        class="d-flex justify-content-start align-items-center">
                                        <div class="card-icon pt-3 pb-3 pl-2 pr-2 rounded" style="background-color: #28a745;">
                                            <v-icon class="text-white" style="font-size:40px">mdi-cart</v-icon>
                                        </div>
                                        <div class="card-details ml-2">
                                            <p class="mb-0">Total Item CBM</p>
                                            <b style="color:black">{{totalItemCbm | currency}}</b>
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="px-2">
                                <v-card
                                    class="total-cards"
                                    elevation="2"
                                >
                                    <v-card-text
                                        class="d-flex justify-content-start align-items-center">
                                        <div class="card-icon pt-3 pb-3 pl-2 pr-2 rounded" style="background-color: #28a745;">
                                            <v-icon class="text-white" style="font-size:40px">mdi-cart</v-icon>
                                        </div>
                                        <div class="card-details ml-2">
                                            <p class="mb-0">Total Estimate CBM</p>
                                            <b style="color:black">{{totalEstimateCbm | currency}}</b>
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="px-2">
                                <v-card
                                    class="total-cards"
                                    elevation="2"
                                >
                                    <v-card-text
                                        class="d-flex justify-content-start align-items-center">
                                        <div class="card-icon pt-3 pb-3 pl-2 pr-2 rounded" style="background-color: #28a745;">
                                            <v-icon class="text-white" style="font-size:40px">mdi-cart</v-icon>
                                        </div>
                                        <div class="card-details ml-2">
                                            <p class="mb-0">Total Weight (kgs)</p>
                                            <b style="color:black">{{totalWeight | currency}}</b>
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-spacer></v-spacer>
                        </v-row>
                        <v-divider class="ma-1 pa-0"></v-divider>
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                <v-data-table
                                    :headers="view_deliveries.headers"
                                    :items="view_deliveries.list"
                                    :loading="view_deliveries.loading"
                                    :options.sync="view_deliveries.options"
                                    :server-items-length="view_deliveries.total_records"
                                    :items-per-page="view_deliveries.items_per_page"
                                    :footer-props="{
                                        itemsPerPageOptions: view_deliveries.items_per_page_options
                                    }"
                                    :disable-sort="$vuetify.breakpoint.mobile"
                                    fixed-header
                                >
                                    <template v-slot:[`item.so_total_amount`]="{ item }">
                                        {{ item.so_total_amount | currency }}
                                    </template>
                                    <template v-slot:[`item.total_cbm`]="{ item }">
                                        {{ item.total_cbm | currency }}
                                    </template>
                                    <template v-slot:[`item.total_estimate_cbm`]="{ item }">
                                        {{ item.total_estimate_cbm | currency }}
                                    </template>
                                    <template v-slot:[`item.total_weight`]="{ item }">
                                        {{ item.total_weight | currency }}
                                    </template>
                                    <template v-slot:[`item.vehicle_cbm`]="{ item }">
                                        {{ item.vehicle_cbm | currency }}
                                    </template>
                                    <template v-slot:[`item.vehicle_weight`]="{ item }">
                                        {{ item.vehicle_weight | currency }}
                                    </template>
                                    <template v-slot:[`item.order_num`]="{ item }">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{on, attrs}">
                                                <span v-bind="attrs" v-on="on">{{ item.order_num }}</span>
                                            </template>
                                            <center>{{ item.so_created_at }}</center>
                                        </v-tooltip>
                                    </template>
                                    <template v-slot:[`item.in_wpf`]="{ item }">
                                        <span class="badge badge-primary" @click="viewDetails('wpf', item.in_wpf)" v-if="item.in_wpf != null">Yes</span>
                                        <span class="badge badge-danger" v-else>No</span>
                                    </template>
                                    <template v-slot:[`item.in_cf`]="{ item }">
                                        <span class="badge badge-primary" @click="viewDetails('cf', item.in_cf)" v-if="item.in_cf != null">Yes</span>
                                        <span class="badge badge-danger" v-else>No</span>
                                    </template>
                                    <template v-slot:[`item.in_pl`]="{ item }">
                                        <span class="badge badge-primary" @click="viewDetails('pl', item.in_pl)" v-if="item.in_pl != null">Yes</span>
                                        <span class="badge badge-danger" v-else>No</span>
                                    </template>
                                    <template v-slot:[`item.in_dispatch`]="{ item }">
                                        <span class="badge badge-primary" @click="viewDetails('dispatch', item.in_dispatch)" v-if="item.in_dispatch != null">Yes</span>
                                        <span class="badge badge-danger" v-else>No</span>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    text
                                                    icon
                                                    color="orange"
                                                >
                                                    <v-icon class="btn-action" small @click="viewSalesOrder(item.sales_order_id)">mdi-eye</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>View SO</span>
                                        </v-tooltip>
                                    </template>
                                    <template v-slot:[`item.status`]="{ item }">
                                        <span class="badge badge-saved" v-if="item.status == 0">{{ 'saved' }}</span>
                                        <span class="badge badge-for-sales" v-if="item.status == 1">{{ 'for sales' }}</span>
                                        <span class="badge badge-for-accounting" v-if="item.status == 2">{{ 'for accounting' }}</span>
                                        <span class="badge badge-approved" v-else-if="item.status == 3">{{ 'approved' }}</span>
                                        <span class="badge badge-completed" v-else-if="item.status == 4">{{ 'completed' }}</span>
                                        <span class="badge badge-warning" v-else-if="item.status == 5">{{ 'rejected' }}</span>
                                        <span class="badge badge-danger" v-else-if="item.status == 6">{{ 'canceled' }}</span>
                                        <span class="badge badge-dark" v-else-if="item.status == 7">{{ 'deleted' }}</span>
                                        <span class="badge badge-for-scheduling" v-else-if="item.status == 16">{{ 'for scheduling' }}</span>
                                        <span class="badge badge-purple" v-else-if="item.status == 8">{{ 'converted' }}</span>
                                        <span class="badge badge-exec-approved" v-else-if="item.status == 9">{{ 'exec approved' }}</span>
                                        <span class="badge badge-pending" v-else-if="item.status == 10">{{ 'partial' }}</span>
                                        <span class="badge badge-for-exec" v-else-if="item.status == 11">{{ 'for exec approval' }}</span>
                                        <span class="badge badge-warning" v-else-if="item.status == 12">{{ 'stockcard' }}</span>
                                        <span class="badge badge-submitted" v-else-if="item.status == 17">{{ 'waiting for stocks' }}</span>
                                        <span class="badge badge-preparing" v-else-if="item.status == 18">{{ 'preparing' }}</span>
                                        <span class="badge badge-prepared" v-else-if="item.status == 19">{{ 'prepared' }}</span>
                                        <span class="badge badge-checking" v-else-if="item.status == 20">{{ 'checking' }}</span>
                                        <span class="badge badge-checked" v-else-if="item.status == 21">{{ 'checked' }}</span>
                                        <span class="badge badge-invoiced" v-else-if="item.status == 22">{{ 'invoiced' }}</span>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-dialog>
                <SalesOrdersViewComponentVue
                    :viewSalesOrderId="view_deliveries.sales_order_id"
                    :service="0"
                    @closeView="closeView"
                ></SalesOrdersViewComponentVue>
            </v-card>
        </v-app>
    </div>
</template>

<script>
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue'
import SalesOrdersViewComponentVue from '../../Sales/SalesOrdersNew/SalesOrdersViewComponent.vue'
export default {
    mixins: [
        SharedFunctionsComponentVue,
    ],
    components: {
        SalesOrdersViewComponentVue,
    },
    props: [
        'service',
    ],
    data(){
        return {
            filters: {
                vehicle_id: null,
                delivery_dates: [
                    this.$dayjs().startOf('year').format('YYYY-MM-DD'),
                    this.$dayjs().endOf('month').format('YYYY-MM-DD'),
                ],
                menu_date_from: false,
                menu_date_to: false,
                general: null,
            },
            list: {
                vehicle_list: [],
            },
            calendar: {
                events: [],
                colors: [],
                value: null,
                start_date: this.$dayjs().startOf('month').format('YYYY-MM-DD HH:mm'),
                end_date: this.$dayjs().endOf('month').format('YYYY-MM-DD HH:mm'),
                overlap_mode: 'stack',
                calendar_view: {
                    value: 'week',
                    list: ['month', 'week', 'day', 'category'],
                },
                week_view: {
                    value: [0, 1, 2, 3, 4, 5, 6],
                    list: [
                        { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
                        { text: 'Mon - Sat', value: [1, 2, 3, 4, 5, 6] },
                        { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
                    ],
                },
                categories: [
                    'Vehicle',
                    'CBM',
                    'REM',
                ]
            },
            view_deliveries: {
                show: false,
                loading: false,
                parameters: {
                    vehicle_id: null,
                    delivery_date: null,
                    vehicle_name: null,
                },
                headers: [
                    { text: 'SO#', align: 'start', width: '5%', sortable: false, value: 'order_num' },
                    // { text: 'PO#', align: 'start', width: '10%', sortable: false, value: 'customer_po_num' },
                    { text: 'Remarks', align: 'start', sortable: false, value: 'remarks' },
                    { text: 'Comments', align: 'start', sortable: false, value: 'delivery_comment' },
                    { text: 'Delivery Date', align: 'start', width: '15%', sortable: false, value: 'delivery_date' },
                    { text: 'Vehicle', align: 'start', width: '15%', sortable: false, value: 'vehicle_name' },
                    { text: 'Vehicle CBM', align: 'end', width: '5%', sortable: false, value: 'vehicle_cbm' },
                    { text: 'Item CBM', align: 'end', width: '5%', sortable: false, value: 'total_cbm' },
                    { text: 'Estimate CBM', align: 'end', width: '5%', sortable: false, value: 'total_estimate_cbm' },
                    { text: 'Vehicle Weight', align: 'end', width: '5%', sortable: false, value: 'vehicle_weight' },
                    { text: 'Item Weight', align: 'end', width: '5%', sortable: false, value: 'total_weight' },
                    // { text: 'SO Amount', align: 'end', width: '7%', sortable: false, value: 'so_total_amount' },
                    { text: 'In Dispatch', align: 'start', width: '3%', sortable: false, value: 'in_dispatch' },
                    { text: 'Status', align: 'center', width: '7%', sortable: false, value: 'status' },
                    { text: 'Actions', align: 'center', width: '7%', sortable: false, value: 'actions' },
                ],
                list: [],
                options: {},
                total_records: 0,
                items_per_page: 10,
                items_per_page_options: [10, 20, 50, 100],
                sales_order_id: '',
            }
        }
    },
    methods: {
        viewSalesOrder(id){
            this.view_deliveries.sales_order_id = id
        },
        closeView(){
            this.view_deliveries.sales_order_id = ''
        },
        filter(){
            if (this.$refs.calendar) {
                this.$refs.calendar.getEvents
            }
        },
        async showDeliveries(event){
            let item = event.event

            this.toggle(true)

            this.view_deliveries.parameters.delivery_date = item.date
            this.view_deliveries.parameters.vehicle_id = item.vehicle_id
            this.view_deliveries.parameters.vehicle_name = item.name

            await this.getDeliveries()

            this.view_deliveries.parameters.delivery_date = this.$dayjs(item.delivery_date).format('YYYY-MM-DD')
        },
        async getDeliveries(){
            this.view_deliveries.loading = true
            let payload = {
                page: this.view_deliveries.options.page,
                itemsPerPage: this.view_deliveries.options.itemsPerPage,
                delivery_date: this.view_deliveries.parameters.delivery_date,
                vehicle_id: this.view_deliveries.parameters.vehicle_id,
                service: this.service,
                url:'vehicle-schedule/get-vehicles'
            }

            await this.$store.dispatch('getDelivery',payload).then(response => {
                this.view_deliveries.list = response.data.vehicles.data
                this.view_deliveries.total_records = response.data.vehicles.total
                this.view_deliveries.loading = false
            }).catch(error => {
                this.view_deliveries.loading = false
            })
        },
        getEventColor(event){
            return event.color
        },
        getEvents({ start, end }){
            this.calendar.start_date = new Date(Date.parse(`${start.date}T00:00:00.00+08:00`.toLocaleString('en-US', 'Asia/Manila')))
            this.calendar.end_date = new Date(Date.parse(`${end.date}T23:59:59.00+08:00`.toLocaleString('en-US', 'Asia/Manila')))

            this.refreshCalendarData(this.calendar.start_date, this.calendar.end_date)
        },
        refreshCalendarData(start, end){
            let payload = {
                min: start,
                max: end,
                vehicle_id: this.filters.vehicle_id,
                service: this.service,
                url:'vehicle-schedule/scheduled/all'
            }
            this.$store.dispatch('getDelivery',payload).then(response => {
                this.calendar.events = response.data.scheduled_vehicles
                this.calendar.colors = response.data.event_colors
            })
        },
        viewDay({ date }){
            this.calendar.value = date
            this.calendar.calendar_view.value = 'day'
        },
        setToday(){
            this.calendar.value = ''
        },
        toggle(show = false){
            if (show == false) {
                this.view_deliveries.list = []
            }
            this.view_deliveries.show = show
        },
        autoRefresh(){
            setInterval(() => {
                if (this.$refs.calendar) {
                    this.refreshCalendarData(this.calendar.start_date, this.calendar.end_date)
                }
            }, 5000)
        },
        getVehicles(){
            let payload = {
                delivery: 1,
                url: 'getVehicles',
            }
            this.$store.dispatch('getDelivery',payload).then(response => {
                this.list.vehicle_list = response.data
            })
        },
    },
    mounted(){
        this.getVehicles()
        this.setToday()
        this.autoRefresh()
        this.getDeliveries()
    },
    computed: {
        currentDay(){
            return this.calendar.value === undefined || this.calendar.value === null || this.calendar.value === ''
                    ? this.$dayjs().format('MMMM D, YYYY')
                    : this.$dayjs(this.calendar.value).format('MMMM D, YYYY')
        },
        // soTotalAmount(){
        //     return this.view_deliveries.list.reduce((subtotal, current) => parseFloat(subtotal) + parseFloat(current.so_total_amount), 0)
        // },
        totalItemCbm(){
            return this.view_deliveries.list.reduce((subtotal, current) => parseFloat(subtotal) + parseFloat(current.total_cbm), 0)
        },
        totalEstimateCbm(){
            return this.view_deliveries.list.reduce((subtotal, current) => parseFloat(subtotal) + parseFloat(current.total_estimate_cbm), 0)
        },
        totalWeight(){
            return this.view_deliveries.list.reduce((subtotal, current) => parseFloat(subtotal) + parseFloat(current.total_weight), 0)
        },
    },
}
</script>
<style scoped>
    .dropdown-items, .dropdown-selection {
        font-size: 13px;
    }
</style>
