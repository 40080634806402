<template>
    <div class="page-wrapper">
        <div class="mt-4 pa-2">
            <h4 class="page-title">Online Monitoring</h4>
        </div>
        <v-container class="ma-0 pa-4">
            <v-app id="item-tabs" class="ma-0">
                <v-tabs 
                    id="group-tab" 
                    color="cyan" 
                    dark 
                    slider-color="cyan" 
                    background-color="#424242" 
                    class="col-12" 
                    show-arrows>
                        <v-tab v-for="(tab,i) in tabs" 
                            :key="i" 
                            @click="getTabId(tab.id)"
                        >
                            {{ tab.name }}
                        </v-tab> 
                        <v-spacer></v-spacer>
                        <v-tab-item v-for="(tab,i) in tabs" :key="i"  >
                            <div v-if="tab.id == 3">
                                <OnlineTrackingConfigComponent :shop_config="tab.shop_config"></OnlineTrackingConfigComponent>
                            </div>
                            <div v-else-if="tab.id == 4">
                                <OnlineTiktokComponent :shop_config="tab.shop_config"></OnlineTiktokComponent>
                            </div>
                            <div v-else>
                                <OnlineTrackingComponent :model_list="model_list" :shop_config="tab.shop_config" :python_file="tab.python_file" :file_name="tab.name" :tab_id="tab_id"></OnlineTrackingComponent>
                            </div>
                        </v-tab-item>
                </v-tabs>
            </v-app>
        </v-container>
    </div>
</template>

<script>
import OnlineTrackingComponent from './OnlineTrackingComponent.vue';
import OnlineTrackingConfigComponent from './OnlineTrackingConfigComponent.vue';
import OnlineTiktokComponent from './OnlineTiktokComponent.vue';
import swal from 'sweetalert2';
export default {
        data(){
            return {
                tab_id: 0, 
                service:this.$router.currentRoute.meta.service,
                tabs:[
                    {
                        name:"Lazada",
                        shop_config:"lazada_config",
                        python_file:'scrape_lazada',
                        id:'2'
                    },
                    {
                        name:"Shopee",
                        shop_config:"shopee_config",
                        python_file:'scrape_shopee',
                        id:'1'
                    },
                    {
                        name:"Tiktok",
                        shop_config:'',
                        url:'',
                        id:'4'
                    },
                    {
                        name:"Config",
                        shop_config:'',
                        url:'',
                        id:'3'
                    },
                   
                ],
                model_list:[],
            }
        },
        methods: {
            getTabId(id){
                this.tab_id = id
            },
            transpose(data) {
                return data.map((_, colIndex) => data.map((row) => row[colIndex]));
            }
        },
        components:{
            OnlineTrackingComponent,
            OnlineTrackingConfigComponent,
            OnlineTiktokComponent
        },
        mounted(){
            this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
            let payload = {
                url:'storeItemListInJson'
            }
            this.$store.dispatch('getWebScraping',payload).then(e=>{
                this.model_list = e.data
            })  
        }
    };
</script>

<style>

</style>