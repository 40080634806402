<template>
    <div class="tabs-body-color">
        <v-container fluid class="pa-0">
            <v-row class="pt-4 pl-2" v-if="userAccess.create">
                <v-col cols="6" class="d-flex no-block align-items-center">
                    <div class="btn-toolbar pl-1">
                        <v-btn class="ml-1 mb-2" @click="AddDialog()">
                            ADD +
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <AccountsPayableTableComponent></AccountsPayableTableComponent>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import AccountsPayableTableComponent from "./AccountsPayableTableComponent.vue";
import { mapGetters } from "vuex";
export default {
    components: {
        AccountsPayableTableComponent,
    },
    data() {
        return {
            userAccess: {
                create: false,
            },
        };
    },
    computed: {
        ...mapGetters(["USER_ACCESS"]),
 
    },
    mounted() {

    },
    methods: {
        AddDialog(){
            this.$store.commit('ACTION','Submit')
            this.$store.commit('DIALOG',true)
            
        }
    },
    watch: {
        USER_ACCESS: {
            handler(val) {
                if (val != "fail") {
                    this.userAccess.create = false;
                    val.forEach((e) => {
                        if (e.actions_code == "create") {
                            this.userAccess.create = true;
                        }
                    });
                }
            },
        },
    },
};
</script>
