<template>
<div class="page-wrapper">

  <div class="container bg-ingco-alt">
    <div class="row">
      <div class="col-12 d-flex no-block align-items-center">
      <h4 class="page-title">Change Password</h4>
      </div>
    </div>
    <form class="form-horizontal" action="/users" method="post" v-on:submit.prevent="onSubmit">
        <v-card class="pa-5">
            <div class="row">
            <div class="col-sm-12 col-md-6">
                <!-- <label for="current_password" class="float-left control-label col-form-label">Current Password</label> -->
                <v-text-field
                    :append-icon="password.isShowCurrent ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="password.isShowCurrent = !password.isShowCurrent"
                    :type="password.isShowCurrent ? 'text' : 'password'"
                    id="current_password" 
                    v-model="current_password" 
                    outlined
                    dense
                    label="Current Password"
                    counter
                />
                <span v-for="errors in errors.current_password" class="text-warning" v-bind:key="errors">{{errors}}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <!-- <label for="new_password" class="float-left control-label col-form-label">New Password</label> -->
                <v-text-field 
                    :append-icon="password.isShowNew ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="password.isShowNew = !password.isShowNew"
                    :type="password.isShowNew ? 'text' : 'password'"
                    outlined
                    dense
                    id="new_password" 
                    label="New Password" 
                    v-model="new_password"
                    counter
                />
                <span v-for="errors in errors.new_password" class="text-warning" v-bind:key="errors">{{errors}}</span>
            </div>
            <div class="col-sm-12 col-md-6">
                <!-- <label for="new_password_confirmation" class="float-left control-label col-form-label">Confirm New Password</label> -->
                <v-text-field 
                :append-icon="password.isShowConfirmed ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="password.isShowConfirmed = !password.isShowConfirmed"
                :type="password.isShowConfirmed ? 'text' : 'password'"
                outlined
                dense
                id="new_password_confirmation" 
                label="Confirm New Password" 
                v-model="new_password_confirmation" 
                counter
                />
                <span v-for="errors in errors.new_password_confirmation" class="text-warning" v-bind:key="errors">{{errors}}</span>
            </div>
        </div>
        <div class="border-top mt-4">
            <div class="row mt-1">
                <div class="col-sm-12">
                    <v-btn  v-on:click="updatePassword()">Update</v-btn>
                </div>
            </div>
        </div>
        </v-card>
    </form>

  </div>

</div>
</template>

<script>
import swal from 'sweetalert2';
    export default {
        data(){
            return {
                errors: [],
                current_password: '',
                new_password: '',
                new_password_confirmation: '',
                password:{
                    isShowCurrent: false,
                    isShowNew: false,
                    isShowConfirmed: false
                }
                   
            }
        },

        methods: {
            updatePassword(){
                swal.fire({
                title: "",
                text: "Are you sure you want to Change Password?",
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.errors = []
                        let payload = {
                            current_password:          this.current_password,
                            new_password:              this.new_password,
                            new_password_confirmation: this.new_password_confirmation,
                        }
                        this.$store.dispatch('postChangePassword',payload).then(response => {
                            this.resetFields();
                            swal.fire("", "Password Update Successful!", "success");
                        }).catch(error => {
                            if(error.response.status == 422){
                                this.errors = error.response.data.errors
                                this.resetFields();
                            }
                        });
                    }
                });
            },
            resetFields(){
                this.current_password           = ''
                this.new_password               = ''
                this.new_password_confirmation  = ''
            }

        },
    };
</script>
<style>
    .col{
        padding: 2px !important;
    }

    .card-body{
        padding: 3px !important;
    }
</style>