<script>
import { mapGetters } from 'vuex';
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";

export default {
    mixins: [SharedFunctionsComponentVue],
    data() {
        return {
            data: Math.random(),

            paperWidth: 215.9,
            paperHeight: 279.4,
            margin: 10,

            headerStartY: undefined,
            headerEndY: undefined,
            headerWidth: undefined,
            headerHeight: undefined,

            footerStartY: undefined,
            footerEndY: undefined,
            footerWidth: undefined,
            footerHeight: undefined,

            printData: undefined,
        };
    },
    computed:{
        ...mapGetters([
            "PURCHASE_ORDER"
        ]),
    },
    methods: {
        drawEnterBillPrintHeader(doc, headerData) {
            // const host = window.location.origin;
            if (this.PURCHASE_ORDER.COMPANY_IMAGE) {
                let aspectRatio = this.getAspectRatio(this.PURCHASE_ORDER.COMPANY_IMAGE.naturalWidth, this.PURCHASE_ORDER.COMPANY_IMAGE.naturalHeight);
    
                let imageWidth = 36;
                let imageHeight = 10;
    
                switch(aspectRatio){
                    case '1:1':
                        imageWidth = 14.111;
                        imageHeight = 14.111;
                        break;
                    case '4:3':
                        imageWidth = 21.166;
                        imageHeight = 14.111;
                        break;
                    case '3:2':
                        imageWidth = 17.6389;
                        imageHeight = 14.111;
                        break;
                    case '16:9':
                        imageWidth = 28.222;
                        imageHeight = 14.111;
                        break;
                    default:
                        imageWidth = 42.333;
                        imageHeight = 10.5833;
                }
    
                doc.addImage(
                    // `${host}/images/ingcoph-logo.png`,
                    headerData.image_path,
                    headerData.ext.toUpperCase() == 'JPG' ? 'JPEG' : headerData.ext.toUpperCase(),
                    (this.margin + (this.paperWidth - (this.margin * 2)) / 4) - (imageWidth / 2),
                    this.margin / 2,
                    imageWidth,
                    imageHeight,
                );
            }

            const titleContainerWidth = 70;
            const titleContainerHeight = 8;
            const titleContainerX = (this.paperWidth / 2) + (((this.paperWidth / 2) - 5) / 2) - (titleContainerWidth / 2);
            const titleContainerY = this.margin;
            doc.setDrawColor('#000000');
            doc.setFillColor('#fde047');
            doc.setFont("GILL", "normal");
            doc.roundedRect(
                titleContainerX,
                titleContainerY,
                titleContainerWidth,
                titleContainerHeight,
                2,
                2,
                'FD',
            );

            const title = 'Enter Bill';
            const titleFontSize = 15;
            const titleX = titleContainerX;
            const titleY = titleContainerY;
            doc.setFont(
                'GILL',
                'bold',
            );
            doc.setFontSize(titleFontSize);
            doc.text(
                title,
                titleX + 23,
                titleY + 6,
            );

            doc.autoTable({
                startY: this.margin + titleContainerHeight + 5,
                margin: {
                    bottom: this.margin,
                    top: this.margin,
                    left: this.margin,
                    right: this.margin,
                },
                theme: 'grid',
                columnStyles: {
                    0 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 6},
                    1 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 6},
                    2 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 6},
                    3 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 6},
                    4 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 6},
                    5 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 6},
                },
                bodyStyles: {
                    lineColor: [0, 0, 0],
                    textColor: [0, 0, 0],
                    fontSize: 8,
                    font: 'GILL',
                },
                body: [
                    [
                        {content: `EB #: ${headerData.id ? headerData.id : ''}`, colSpan: 3},
                        {content: `Date: ${headerData.created_at ? headerData.created_at : ''}`, colSpan: 3},
                    ],
                    [
                        {content: `Transact Type: ${headerData.transact_type ? headerData.transact_type : ''}`, colSpan: 3},
                        {content: `Vendor: ${headerData.vendor ? headerData.vendor : ''}`, colSpan: 3},
                    ],
                    [
                        {content: `Company: ${headerData.company ? headerData.company : ''}`, colSpan: 3},
                        {content: `Store Branch: ${headerData.store_branch ? headerData.store_branch : ''}`, colSpan: 3},
                    ],
                    [
                        {content: `Bill Due: ${headerData.bill_date ? headerData.bill_date : ''}`, colSpan: 3},
                        {content: `Amount Due: ${headerData.amount_due ? this.currencyWithoutSign(headerData.amount_due) : ''}`, colSpan: 3},
                    ],
                ],
            });

            this.headerStartY = this.margin;
            this.headerEndY = doc.lastAutoTable.finalY;
            this.headerWidth = this.paperWidth - (this.margin * 2);
            this.headerHeight = this.headerEndY - this.headerStartY;

            return this.headerEndY;
        },

        drawEnterBillPrintFooter(doc, footerData) {
            const footerHeight = 30;

            this.footerStartY = footerHeight;
            this.footerEndY = this.footerStartY + footerHeight;
            this.footerWidth = this.paperWidth - (this.margin * 2);
            this.footerHeight = footerHeight;

            doc.setFontSize(7);
            doc.setFont("GILL", "normal");
            doc.text(
                `Page ${doc.internal.getNumberOfPages()}`,
                this.margin,
                272,
            );

            return this.footerEndY;
        },

        drawEnterBillPrintContent(doc, contentData) {
            doc.autoTable({
                startY: this.headerEndY,
                margin: {
                    top: this.headerEndY,
                    bottom: this.footerHeight + this.margin,
                    left: this.margin,
                    right: this.margin,
                },
                theme: 'grid',
                columnStyles: {
                    0: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    1: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    2: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    3: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    4: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    5: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    6: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    7: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    8: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    9: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    10: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    11: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                },
                headStyles: {
                    halign: 'center',
                    lineColor: [0, 0, 0],
                    fillColor: [254, 249, 195],
                    textColor: [0, 0, 0],
                    fontStyle: 'italic',
                    fontSize: 8,
                    font: 'GILL',
                },
                head: [
                    [
                        {content: 'Line #'},
                        {content: 'Doc #'},
                        {content: 'Item Name', colSpan: 2},
                        {content: 'Balance', colSpan: 2},
                        {content: 'Paid Amount', colSpan: 2},
                        {content: 'Pending Amount', colSpan: 2},
                        {content: 'Amount To Pay', colSpan: 2},
                    ],
                ],
                bodyStyles: {
                    halign: 'center',
                    lineColor: [0, 0, 0],
                    textColor: [0, 0, 0],
                    fontSize: 8,
                    font: 'GILL',
                },
                body: [
                    ...contentData.items.map((item, index) => [
                        {content: index + 1},
                        {content: item.transact_num ? item.transact_num : ''},
                        {content: item.item_name ? item.item_name : '', colSpan: 2},
                        {
                            content: item.balance ? this.thousandSeprator(item.balance) : '',
                            colSpan: 2,
                            styles: {halign: 'right'},
                        },
                        {
                            content: item.paid_amount ? this.thousandSeprator(item.paid_amount) : '',
                            colSpan: 2,
                            styles: {halign: 'right'},
                        },
                        {
                            content: item.pending_amount ? this.thousandSeprator(item.pending_amount) : '',
                            colSpan: 2,
                            styles: {halign: 'right'},
                        },
                        {
                            content: item.amount_to_pay ? this.thousandSeprator(item.amount_to_pay) : '',
                            colSpan: 2,
                            styles: {halign: 'right'},
                        },
                    ]),

                    // content footer
                    [
                        {content: 'OVERALLS >>', colSpan: 4, styles: {fontStyle: 'bold', valign: 'middle', halign: 'right'}},
                        {
                            content: this.printData.header.amount_due ? this.currencyWithoutSign(this.printData.header.amount_due)  : '0.00',
                            colSpan: 2,
                            styles: {valign: 'middle', halign: 'right'},
                        },
                        {
                            content: this.printData.header.amount_due ? this.currencyWithoutSign(this.printData.header.amount_due)  : '0.00',
                            colSpan: 2,
                            styles: {valign: 'middle', halign: 'right'},
                        },
                        {
                            content: this.printData.header.amount_due ? this.currencyWithoutSign(this.printData.header.amount_due)  : '0.00',
                            colSpan: 2,
                            styles: {valign: 'middle', halign: 'right'},
                        },
                        {
                            content: this.printData.header.amount_due ? this.currencyWithoutSign(this.printData.header.amount_due)  : '0.00',
                            colSpan: 2,
                            styles: {valign: 'middle', halign: 'right'},
                        },
                    ],
                ],
                didDrawCell: (data) => {
                    if (data.section === 'head') {
                        doc.rect(
                            data.cell.x,
                            data.cell.y,
                            data.cell.width,
                            data.cell.height,
                        );
                    }
                },
                didDrawPage: (data) => {
                    if (doc.getNumberOfPages() > 1) {
                        this.drawEnterBillPrintHeader(doc, this.printData.header);
                        this.drawEnterBillPrintFooter(doc, this.printData.footer);
                    }
                },
            });

            doc.autoTable({
                startY: doc.lastAutoTable.finalY,
                margin: {
                    bottom: this.margin,
                    top: this.margin,
                    left: (this.paperWidth / 4) + (this.margin / 2),
                    right: (this.paperWidth / 4) + (this.margin / 2),
                },
                theme: 'plain',
                columnStyles: {
                    0 : {cellWidth: ((this.paperWidth / 2) - (this.margin)) / 2},
                    1 : {cellWidth: ((this.paperWidth / 2) - (this.margin)) / 2},
                },
                bodyStyles: {
                    lineColor: [0, 0, 0],
                    textColor: [0, 0, 0],
                    fontSize: 8,
                    halign: 'center',
                    font: 'GILL',
                },
                body: [
                    [
                        `Prepared by\n\n\n\n${contentData.prepared_by}`,
                        'Checked by',
                    ],
                ],
                // didDrawCell: (data) => {
                //     delete contentData.items;

                //     const attrs = Object.keys(contentData);

                //     doc.autoTable({
                //         startY: data.cell.y,
                //         margin: {
                //             top: data.cell.y,
                //             bottom: this.paperHeight - (data.cell.y + data.cell.height),
                //             left: data.cell.x,
                //             right: this.paperWidth - (data.cell.x + data.cell.width),
                //         },
                //         theme: 'plain',
                //         bodyStyles: {
                //             minCellHeight: 22,
                //             valign: 'end',
                //             halign: 'left',
                //             lineColor: [0, 0, 0],
                //             textColor: [0, 0, 0],
                //             fontSize: 8,
                //         },
                //         body: [
                //             [{
                //                 content: `\n${contentData[attrs[data.column.index]] ?? ''}`,
                //             }],
                //         ],
                //     });
                // },
            });
        },

        printEnterBill(eb) {
            const doc = new this.$jspdf({
                orientation: 'portrait',
                unit: 'mm',
                format: 'letter',
            });

            this.printData = eb;

            this.drawEnterBillPrintHeader(doc, this.printData.header);
            this.drawEnterBillPrintFooter(doc, this.printData.footer);
            this.drawEnterBillPrintContent(doc, this.printData.content);

            doc.autoPrint();
            window.open(doc.output("bloburl"), "_blank");
        },
    },
    watch: {
        'data': {
            handler(val) {
                // this.printEnterBill();
            },
            immediate: true,
        }
    },
}
</script>