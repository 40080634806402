
import NavigationDrawer from "@/views/main/SystemSettings/NavigationDrawer.vue"
import GeneralSettings from "@/views/main/SystemSettings/GeneralSettingsComponent.vue"
import EmailSettings from "@/views/main/SystemSettings/Email/EmailSettingsMainGroupComponent.vue"
import repository from "@/api/credentials";
const routes = [{
                path: "/system-settings/navigation-drawer/:id",
                name: "navigationdrawer",
                component: NavigationDrawer,
                beforeEnter:(to,from,next)=>{
                  repository.checkAccessUser(to.params.id).then((response)=>{
                      if(response.data == 'fail'){
                          next('/no-access')
                          }else{
                          next()
                      }
                  }).catch(()=>{

                  })
                },
                meta:{
                    requiresAuth:true,
                    title:'Navigation Settings'
                }
            },
            {
                path: "/system-settings/general-settings/:id",
                name: "generalsettings",
                component: GeneralSettings,
                beforeEnter:(to,from,next)=>{
                  repository.checkAccessUser(to.params.id).then((response)=>{
                      if(response.data == 'fail'){
                          next('/no-access')
                          }else{
                          next()
                      }
                  }).catch(()=>{

                  })
                },
                meta:{
                    requiresAuth:true,
                    title:'General Settings'
                }
            },
            {
                path: "/system-settings/email-settings/:id",
                name: "emailsettings",
                component: EmailSettings,
                beforeEnter:(to,from,next)=>{
                  repository.checkAccessUser(to.params.id).then((response)=>{
                      if(response.data == 'fail'){
                          next('/no-access')
                          }else{
                          next()
                      }
                  }).catch(()=>{

                  })
                },
                meta:{
                    requiresAuth:true,
                    title:'Email Settings'
                }
            },

        ]
export default routes;
