<template>
    <v-container fluid >
        <h3 class="page-title">ITEMS</h3>
        <v-container class="ma-0">
            <v-app id="item-tabs">
                <v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" background-color="#424242" dark show-arrows>
                    <v-tabs-slider color="cyan"></v-tabs-slider>
                    <v-tab ripple v-for="(item, i) in items" :key="item" @click="tabs(i)" :style="i == 4 ? 'margin-left: auto;' : ''">
                        {{ item }}
                    </v-tab>
                </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item v-for="(table, index) in adminitem" :key="index">
                    <keep-alive>
                        <component :is="table.components" :isActive_tab="table.status_text" :service="table.service"/>
                    </keep-alive>
                </v-tab-item>
            </v-tabs-items>
            </v-app>
        </v-container>
        <DialogComponent :cp="components" :scrollable="dialog_scrollable" :width="width(dialog_width)" :retainfocus="false"></DialogComponent>
    </v-container>
</template>

<script>
import DialogComponent from '@/views/main/layouts/dialogs/Dialog.vue';
import ItemsComponent from '@/views/main/modules/Admin/Items/ItemsComponent.vue';
import AdminItemUomComponent from '@/views/main/modules/Admin/Items/AdminItemUomComponent.vue';
import ItemViewComponent from '@/views/main/layouts/dialogs/Admin/ItemViewComponent.vue';
import AdminItemUomViewComponent from '@/views/main/layouts/dialogs/Admin/AdminItemUomViewComponent.vue';
import { mapGetters } from 'vuex';
export default {
    components:{
        DialogComponent,
        ItemViewComponent,
        ItemsComponent,
        AdminItemUomComponent,
        AdminItemUomViewComponent     
    },
    data() {
        return {
        form: {
            code: '',
            name: '',
            sub_class:[],
            sub_class_trash:[],
            },
            components:'',
            items: ["ALL", "REGULAR", "SERVICES", "INACTIVE", "UOM"],
            adminitem: [
                    {
                    components: ItemsComponent,
                    status_text: 1,
                    dialog: ItemViewComponent,
                    dialog_width: "80%",
                    scrollable:true,
                    dispatch:{
                            get:'getAllAdminItem',
                            add:'AdminCreateAdminItem',
                            update:'AdminUpdateAdminItem',
                            delete:'AdminDeleteAdminItem'
                            },
                    },
                    {
                    components: ItemsComponent,
                    status_text: 1,
                    service: 0,
                    dialog: ItemViewComponent,
                    dialog_width: "80%",
                    scrollable:true,
                    dispatch:{
                            get:'getAllAdminItem',
                            add:'AdminCreateAdminItem',
                            update:'AdminUpdateAdminItem',
                            delete:'AdminDeleteAdminItem'
                            },
                    },
                    {
                    components: ItemsComponent,
                    status_text: 1,
                    service: 1,
                    dialog: ItemViewComponent,
                    dialog_width: "80%",
                    scrollable:true,
                    dispatch:{
                            get:'getAllAdminItem',
                            add:'AdminCreateAdminItem',
                            update:'AdminUpdateAdminItem',
                            delete:'AdminDeleteAdminItem'
                            },
                    },
                    {
                    components: ItemsComponent,
                    status_text: 0,
                    dialog: ItemViewComponent,
                    dialog_width: "80%",
                    scrollable:true,
                    dispatch:{
                            get:'getAllAdminItem',
                            add:'AdminCreateAdminItem',
                            update:'AdminUpdateAdminItem',
                            delete:'AdminDeleteAdminItem'
                            },
                    },
                    {
                    components: AdminItemUomComponent,
                    status_text: 0,
                    dialog: AdminItemUomViewComponent,
                    dialog_width: "25%",
                    scrollable:true,
                    dispatch:{
                            get:'getAllAdminItemUom',
                            add:'AdminCreateAdminItemUom',
                            update:'AdminUpdateAdminItemUom',
                            delete:'AdminDeleteAdminItemUom'
                            },
                    },
            ],
            
            dialog_width: "",
            loaded: false,
            options: {},
            itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
            itemsPerPage: 10,
            tab: null,
            dialog_scrollable:true,
        };
    },
    computed:{
        ...mapGetters(['GET_NEW_ADMIN_OTHER_TABLE','GET_NEW_ADMIN_ASSETS_TABLE'])
    },
    mounted(){
        this.dropDowns()
        this.tabs(0);
    },
    methods:{
    dropDowns(){
        this.$store.dispatch('getDepartment')
        this.$store.dispatch("getAdminSupplierSelection");
        this.$store.dispatch("getAdminClassificationSelection");
        this.$store.dispatch('getAdminItemUomSelection');
        this.$store.dispatch("getAdminBrandSelection");
        // this.$store.dispatch("getAdminCategories");
    },
    tabs(key) {
        this.components = this.adminitem[key].dialog;
        this.dialog_width = this.adminitem[key].dialog_width;
        this.dialog_scrollable = this.adminitem[key].scrollable;
        this.$store.commit('ADMIN_ITEM_SERVICE',this.adminitem[key].service)
        this.$store.commit('ADMIN_ITEM_STATUS',this.adminitem[key].status_text)
        this.$store.commit('ADMIN_ITEM_DISPATCH',this.adminitem[key].dispatch)
    },
    width(width) {
        switch (this.$vuetify.breakpoint.name) {
        case 'xs': return "100%"
        case 'sm': return "100%"
        case 'md': return width
        case 'lg': return width
        case 'xl': return width
        }
    },
    },
    watch:{}
};
</script>
<style>
#group-tab {
    padding: 0 !important;
}
</style>