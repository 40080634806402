import ItemFoc from '@/views/main/modules/Services/ItemsAutoFocComponent.vue'
import repository from '@/api/credentials'

const routes = [{
    path: "/services/itemfoc/:id",
    name: 'itemfoc',
    component: ItemFoc,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'ItemFoc',
    },
}]

export default routes;
