<template>
    <v-container>
        <v-row>
            <v-col cols="12" class="my-2 ml-2">
                <v-btn @click="viewAddDialog()">
                    <v-icon>
                        mdi-plus
                    </v-icon>
                    Add
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-dialog v-model="dialog" max-width="35%" persistent>
                <v-card>
                    <v-row class="ma-0 pa-0">
                        <v-col>
                            <v-card-title>
                                <span class="headline">New Shop</span>
                            </v-card-title>
                        </v-col>
                        <v-col cols="ma-2">
                            <v-btn text icon color="gray" class="float-right"
                                @click="dialog = false; resetFields(); $emit('closeModal');">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-divider class="ma-0 pa-0"></v-divider>
                    <v-card-text class=" mx-0">
                        <v-tabs
                            v-model="tab"
                            class="ma-0 pa-0"
                        >
                            <v-tab>
                                Lazada
                            </v-tab>
                            <v-tab>
                                Shopee
                            </v-tab>
                            <v-tab>
                                Template
                            </v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tab">
                            <v-tab-item v-for="(shop,i) in shops" :key="i" >
                                <v-card>
                                    <v-card-text v-if="i != 2" class="ma-0 pa-0 mt-2">
                                        <v-row class="ma-0">
                                            <v-col cols="4">
                                                <embed :src="tab === 1 ? logo_helper_images[4] : logo_helper_images[1]" width="150px" height="80px"/>
                                            </v-col>
                                            <v-spacer></v-spacer>
                                            <v-col class="text-right mr-4">
                                                <FileUploadComponent ref="fileUpload" @uploadedData="uploadedData" @reset="clearFileUpload" />
                                            </v-col>
                                        </v-row>
                                            <v-text-field
                                                v-model="shop.name"
                                                dense
                                                outlined
                                                hide-details
                                                class="my-2"
                                                label="Name"
                                                >
                                            </v-text-field>
                                            <v-text-field
                                                v-model="shop.shop_url_name"
                                                dense
                                                outlined
                                                hide-details
                                                class="my-2"
                                                label="Shop URL Name"
                                                >
                                                <template #append-outer>
                                                    <v-tooltip top color="white">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon color="primary"
                                                            v-bind="attrs"
                                                            v-on="on">
                                                                mdi-help-circle
                                                            </v-icon>
                                                        </template>
                                                        <v-img :src="tab === 1 ? logo_helper_images[5] : logo_helper_images[2]" width="1000px"></v-img>
                                                    </v-tooltip>
                                                </template>
                                            </v-text-field>
                                    </v-card-text>
                                    <v-card-text v-else class="ma-0 pa-0 mt-2">
                                        <v-row class="ma-2">
                                            <v-col cols="7">
                                                <FileUploadComponent ref="fileUpload" @uploadedData="uploadedData" @reset="clearFileUpload" />  Upload Discounting File (.csv)
                                            </v-col>
                                            <v-col cols="5" class="text-right mt-2">
                                                <v-btn color="orange" small :href="getTemplateFile()">Download Template</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-divider class="ma-0 pa-0"></v-divider>
                                    <v-card-actions class="d-flex justify-end ml-0 pl-0">
                                        <v-btn color="secondary" @click="dialog=false; resetFields()">Cancel</v-btn>
                                        <v-btn v-if="i !=2 " @click="saveData(shop.url,i)">Submit</v-btn>
                                        <v-btn v-else @click="uploadFile()">Submit</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>
        <OnlineTrackingTableComponent ref="tableList"></OnlineTrackingTableComponent>
    </v-container>
</template>

<script>
import swal from 'sweetalert2';
import FileUploadComponent from '@/views/main/Shared/FileUploadComponent.vue';
import OnlineTrackingTableComponent from './OnlineTrackingTableComponent.vue';
import { mapGetters } from 'vuex';
export default {
        data(){
            return {
                overlay: false,
                overlay_message:'Loading Please Wait...',
                date_today:this.$dayjs().format('YYYY-M-D'),
                dialog:false,
                tab:'',
                uploadedFiles:null,
                shops:[
                    {
                        name:'',
                        shop_url_name:'',
                        url:'storeLazadaList',
                    },
                    {
                        name:'',
                        shop_url_name:'',
                        url:'storeShopeeList',
                    },
                    {
                        template:null,
                        url:'saveTemplate',
                    }
                ],
                image:null,
                logo_helper_images: [],
            }
        },
        methods: {
            viewAddDialog(){
                this.dialog = true
            },
            uploadedData(data) {
                if (!data) {
                    this.uploadedFiles = null;
                }
                this.uploadedFiles = data;
                var file = this.uploadedFiles.attachments[0]
                var reader = new FileReader()
                new Promise((resolve, reject) => {
                    reader.onloadend = async function () {
                        resolve( reader.result   );
                    }
                }).then(response=>{
                    this.image = response;
                });

                reader.readAsDataURL(file)
            },
            clearFileUpload(confirm) {
                if (confirm) {
                    this.uploadedFiles = null;
                }
                this.uploadedFiles = null;
            },
            saveData(url,i){
                if(this.uploadedFiles == null){
                    swal.fire('Please upload an image','','warning')
                    return false
                }
                this.shops[i].src = this.image
                let payload = {
                    shop:this.shops[i],
                    url:url
                }
                this.$store.dispatch('postWebScraping',payload).then(response=>{
                    let data = response.data
                    swal.fire(data.message,'',data.icon)
                    this.$refs.tableList.getAllShopList()
                    if(data.icon == 'success'){
                        this.dialog = false
                    }
                    this.resetFields()
                });
            },
            resetFields(){
                this.shops = [
                    {
                        name:'',
                        shop_url_name:'',
                        src: '',
                        banner_src:'',
                        url:'storeLazadaList',
                    },
                    {
                        name:'',
                        shop_url_name:'',
                        src:'',
                        banner_src:'',
                        url:'storeShopeeList',
                    },
                    {
                        template:null,
                        url:'saveTemplate',
                    }
                ]
                this.clearFileUpload()
            },
            uploadFile(){
                const data = new FormData();
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
                if(!!this.uploadedFiles && this.uploadedFiles.attachments.length > 0) {
                    let files = this.uploadedFiles.attachments;

                    for(let i=0;i < _.compact(files).length;i++) {
                        data.append('files[]', files[i]);
                    }
                }
                let payload = {
                    params:data,
                    config: config,
                    url:'storeConfigFile'
                }
                this.$store.dispatch('postConfigWebScraping',payload).then(response=>{
                    let data = response.data
                    swal.fire(data.message,'',data.icon)
                    if(data.icon == 'success'){
                        this.dialog = false
                    }
                    this.resetFields()
                })
            },
            getTemplateFile(){
                return process.env.VUE_APP_API_HOST+'/web_scraping/ingco_discounting.csv'
            },
        },
        mounted(){
            // this.getData();
        },
        components:{
            FileUploadComponent,
            OnlineTrackingTableComponent
        },
        computed: {
            ...mapGetters([
                'GET_ONLINE_MONITORING_IMAGES'
            ])
        },
        watch: {
            GET_ONLINE_MONITORING_IMAGES: {
                handler(val){
                    this.logo_helper_images = val
                }
            }
        }
};
</script>

<style>

</style>