<template>
    <v-card>
        <v-card-title class="d-flex justify-content-between pa-0">
				<h4 class="mx-3">asdasdasdasdasdasdasdasdasasdasdasdsa</h4>
				<v-btn text @click="closeDialog()" fab><v-icon>mdi-close-circle</v-icon></v-btn>
		</v-card-title>
    </v-card>
</template>

<script>
export default {
    mounted() {
      // this.$store.dispatch('getAutoFocSp', {code: 'SP'})
    }
}
</script>

<style>

</style>