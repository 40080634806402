<template>
    <v-container :class="isSoa || isWir? 'ma-0 pa-0' : 'tabs-body-color ma-0 pa-0'">
        <ReportsCardsComponentVue :totals="totals" :cards="cards" style="width:100%;" :setClass="'ma-0 pa-0'" v-if="origin == 2"></ReportsCardsComponentVue>

        <!-- NEW CREDIT MEMO -->
        <v-row class="ma-0 pa-0" v-if="origin == 1">
            <v-col cols="12" class="d-flex no-block align-items-center">
                <div class="btn-toolbar my-2">
                    <v-btn v-if="userAccess.create && !isSoa && !isWir && !tab_name" @click="showNewCreditMemoDialog()">
                        <v-icon>mdi-plus</v-icon>New
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        <v-btn v-if="userAccess.create_cm_soa && tab_name && origin == 2" class="my-3" @click="showNewCreditMemoChinaDialog()">
            <v-icon>mdi-plus</v-icon>New
        </v-btn>
       <v-row v-if="isSoa" :class="origin == 1 ? 'mb-2 mr-2' : 'ma-0 pa-0'">
            <v-col class="d-flex justify-content-end" >
                <v-btn class="d-flex justify-content-end" @click="showNewCreditMemoDialog(); triggerFunctionsInMounted()">
                    <v-icon>mdi-plus</v-icon>Create CM for payment
                 </v-btn>
            </v-col>
       </v-row>
       <v-row v-if="isWir" :class="origin == 1 ? 'mb-2 mr-2' : 'ma-0 pa-0'">
            <v-col class="d-flex justify-content-end" >
                <v-btn class="d-flex justify-content-end" @click="showNewCreditMemoDialog(); triggerFunctionsInMounted()">
                    <v-icon>mdi-plus</v-icon>Create CM
                 </v-btn>
            </v-col>
       </v-row>

        <!-- CREDIT MEMO TYPE SELECTION -->
        <v-dialog v-model="creditMemoType.dialog" scrollable persistent max-width="30%" style="z-index: 30">
            <v-card>
                <v-progress-linear v-if="loading.state" indeterminate color="green"></v-progress-linear>
                <v-row class="m-0">
                    <v-col cols="8">
                        <v-card-title>
                            <span class="text-lg font-semibold" v-text="`Create Credit Memo For`"></span>
                        </v-card-title>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="2">
                        <v-btn text icon color="gray" class="float-right" @click="closeCreditMemoTypeDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-card-text style="max-height: 460px; border: 1px solid #e0e0e0; padding: 0">
                    <v-row v-if="loading.state" tag="div" align="center" no-gutters class="px-2" v-text="loading.title">
                    </v-row>
                    <v-row v-if="!loading.state" tag="div" align="center" no-gutters class="px-2 my-2">
                        <v-col class="d-flex pr-2" cols="10">
                            <v-row dense no-gutters tag="div">
                                <v-col dense tag="div" sm="12" class="d-flex justify-start">
                                </v-col>
                                <!-- <v-col dense tag="div" sm="12" class="my-1">
                                    <v-autocomplete
                                        hide-details
                                        item-text="text"
                                        item-value="value"
                                        :items="origins.selection"
                                        v-model="origins.value"
                                        dense
                                        label="Origin"
                                        outlined
                                        flat
                                    ></v-autocomplete>
                                </v-col> -->
                                <v-col dense tag="div" sm="12" class="my-1">
                                    <v-autocomplete hide-details item-text="text" item-value="value"
                                        :items="GET_CREDIT_MEMO_TYPES" v-model="creditMemoType.value" dense
                                        label="Credit Memo Type" outlined flat auto-select-first @change="selectedCMType()"></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions class="d-flex justify-end">
                    <v-btn small :disabled="!creditMemoType.value || loading.state" @click="editedIndex = -1; !tab_name ? dialog = true : chinaDialog = true; closeCreditMemoTypeDialog();">
                        <v-icon class="m-0">mdi-plus</v-icon>
                        Create
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- REJECT CREDIT MEMO -->
        <v-dialog v-model="rejection_dialog" scrollable persistent max-width="30%">
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Rejection Remarks</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeRejectDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider class="mt-0" />
                <v-card-text class="mt-3">
                    <v-row class="mt-3">
                        <v-text-field v-model="rejection_remarks" outlined dense label="Remarks"
                            class="d-inline-block" />
                    </v-row>
                </v-card-text>
                <v-divider />

                <div style="text-align: center" class="pb-4">
                    <v-btn @click="rejectCreditMemo()">Submit</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <!-- CREATE/EDIT CREDIT MEMO -->
        <v-dialog v-model="dialog" scrollable persistent height="500" max-width="50%"
            style="z-index: 50; position: relative">
            <!-- <div v-if="packingListItem.dialog" style="background-color: rgba(47,47,47,0.5); position:absolute; width:100%; height:100%; z-index: 60;"></div> -->
            <form>
                <v-card>
                    <v-row class="m-0">
                        <v-col cols="8">
                            <v-card-title>
                                <span v-if="editedIndex != null" class="text-lg font-semibold" v-text="editedIndex < 0 ? `Create Credit Memo | ${creditMemoTypeText}`: (editedIndex === 1 ? `View` : `Edit`) + ` Credit Memo : ${editedCreditMemo.credit_memo_num} | ${editedCreditMemo.credit_memo_type}`"></span>
                                <span v-if="editedIndex === null" class="text-lg font-semibold"
                                    v-text="editedCreditMemo.credit_memo_num"></span>
                            </v-card-title>
                        </v-col>
                        <v-col cols="pull-right 4">
                            <v-btn text icon color="gray" class="float-right" @click="closeDialog()">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-progress-linear v-if="loading.state" indeterminate color="green"></v-progress-linear>
                    <v-card-text style="border: 1px solid #e0e0e0">
                        <div class="card-body">
                            <v-row dense no-gutters class="d-flex flex-row mr-2 mb-1">
                                <v-col dense cols="2">
                                    <v-autocomplete hide-details item-text="text" item-value="value"
                                        :items="origins.selection" v-model="origins.value" dense label="Origin" outlined
                                        flat readonly @input="selectOrigin()"
                                        :disabled="editedIndex === 1">
                                    </v-autocomplete>
                                </v-col>
                                <v-col dense cols="2" v-if="origin == 1">
                                    <v-autocomplete
                                        v-model="classifications.value"
                                        :items="classifications.selection"
                                        :disabled="editedIndex === 1"
                                        label="Classification"
                                        dense
                                        hide-details
                                        outlined
                                        flat
                                        @input="selectOrigin()"
                                    >
                                    </v-autocomplete>
                                </v-col>
                                <v-col dense cols="2" class="ml-1" v-if="origin == 1 && addEditDialogAction == 'edit'">
                                    <v-btn
                                        @click="triggerChangeTypeDialog(true)"
                                    >
                                        Change Type
                                    </v-btn>
                                </v-col>
                                <!-- <v-col>
                                    <v-btn v-if="!!repairFormId && origin == 2"  @click="printTechnicalReport()">Print Technical Report</v-btn>
                                </v-col> -->
                                <v-col dense cols="3" v-if="origin == 2 && editedCreditMemo.type == 9">
                                    <v-autocomplete hide-details item-text="text" item-value="value"
                                        :items="container.selection" v-model="container.value" dense label="Container#"
                                        outlined flat auto-select-first :disabled="editedIndex === 1"></v-autocomplete>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col dense cols="6" class="d-flex justify-end align-center mb-1 pa-0">
                                    <!-- <v-row class="d-flex justify-end align-center mb-1 pa-0"> -->
                                        <FileUploadComponent v-if="addEditDialogAction == 'new' || addEditDialogAction == 'edit'" ref="fileUpload" @uploadedData="uploadedData" @reset="clearFileUpload" />
                                        <v-divider v-if="addEditDialogAction == 'new' || addEditDialogAction == 'edit'" class='mx-2' vertical></v-divider>
                                        <BaseCameraCapture v-if="addEditDialogAction == 'new' || addEditDialogAction == 'edit'" ref="cameraCapture" @capturedImages="getImages"></BaseCameraCapture>
                                        <v-btn class="mx-2" v-if="repairFormId"
                                            @click="after_sales_dialog.state = true"><!--redirect('/service/inquiry?rf_id=' + repairFormId)-->
                                            After Sales Details
                                        </v-btn>
                                        <BaseFileViewer :creditMemoId="creditMemoId" :rfId="repairFormId" :containerDiscrepancyId="containerDiscrepancyId"
                                                :delete="addEditDialogAction == 'edit'" />
                                    <!-- </v-row>
                                    <v-row v-if="!!repairFormId" class="d-flex justify-end align-center pa-0">
                                        <v-btn  @click="printRrDetails()">Receving Report</v-btn>
                                        <v-btn  @click="printTechnicalReport()">Technical Report</v-btn>
                                    </v-row> -->
                                </v-col>

                            </v-row>
                            <v-row tag="div" align="start" class="m-1">
                                <v-col dense cols="6">
                                    <v-row dense no-gutters tag="div" class="mt-2">
                                        <v-col dense tag="div" cols="12" class="d-flex flex-column">
                                            <v-autocomplete outlined dense :disabled="editedIndex === 1 || isSoa"
                                                :items="GET_CUSTOMERS_LIST_SELECTION" v-model="customer.value"
                                                item-value="value" item-text="text" label="Customer"
                                                class="d-inline-block" @change="onSelectCustomer()"></v-autocomplete>
                                        </v-col>
                                        <v-col dense tag="div" cols="12" class="d-flex flex-column mt-2 mb-1">
                                            <v-autocomplete outlined dense :disabled="editedIndex === 1 || isSoa"
                                                :items="customer.billing_address_list"
                                                v-model="editedCreditMemo.billing_address_id" item-value="value"
                                                item-text="text" label="Billing Address" class="d-inline-block"
                                                @change="onSelectBillingAddress()"></v-autocomplete>
                                        </v-col>
                                        <v-col dense tag="div" cols="12">
                                            <v-row dense no-gutters tag="div">
                                                <v-col dense tag="div" cols="6">
                                                    <v-text-field outlined dense label="Contact Person" class="w-100"
                                                        v-model="editedCreditMemo.billing_contact_person"
                                                        :disabled="editedIndex === 1" />
                                                </v-col>
                                                <v-col dense tag="div" cols="6">
                                                    <v-text-field v-model="editedCreditMemo.billing_contact_number"
                                                        outlined dense label="Contact Number" class="w-100"
                                                        :disabled="editedIndex === 1" />
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col dense cols="6">
                                    <v-row dense no-gutters tag="div" class="">
                                        <v-col dense tag="div" cols="12">
                                            <v-row dense no-gutters tag="div">
                                                <v-col dense tag="div" cols="3"></v-col>
                                                <v-col dense tag="div" cols="3">
                                                    <v-menu :disabled="loading.state || editedIndex === 1"
                                                        :close-on-content-click="false" :nudge-right="40"
                                                        v-model="soaPicker" transition="scale-transition"
                                                        min-width="290px">
                                                        <template v-slot:activator="{ on }">
                                                            <v-text-field outlined dense class="w-100 mt-2 mb-1"
                                                                label="SOA Month" type="text"
                                                                placeholder="Select Year-Month"
                                                                v-model="editedCreditMemo.soa_month_year" readonly
                                                                v-on="on" />
                                                        </template>
                                                        <v-date-picker
                                                            type="month"
                                                            v-model="editedCreditMemo.soa_month_year"
                                                            @input="formatDate('soa_month_year', editedCreditMemo.soa_month_year)"
                                                        ></v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                                <v-col dense tag="div" cols="6">
                                                    <v-menu :disabled="loading.state || editedIndex === 1"
                                                        :close-on-content-click="false" :nudge-right="40"
                                                        v-model="datePicker" transition="scale-transition"
                                                        min-width="290px">
                                                        <template v-slot:activator="{ on }">
                                                            <v-text-field outlined dense class="w-100 mt-2 mb-1"
                                                                label="Date Prepared" type="text"
                                                                v-model="editedCreditMemo.credit_memo_date_long"
                                                                readonly v-on="on" />
                                                        </template>
                                                        <v-date-picker v-model="editedCreditMemo.credit_memo_date"
                                                            @input="formatDate('credit_memo_date', editedCreditMemo.credit_memo_date)"
                                                        ></v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col dense tag="div" cols="12">
                                            <v-row dense no-gutters tag="div">
                                                <v-col dense tag="div" cols="3"></v-col>
                                                <v-col dense tag="div" cols="9">
                                                    <v-text-field outlined dense label="Reference PLs" class="w-100"
                                                        v-model="editedCreditMemo.packing_list_nums"
                                                        :disabled="editedIndex === 1" />
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col dense tag="div" cols="12">
                                            <v-row dense no-gutters tag="div">
                                                <v-col dense tag="div" cols="3"></v-col>
                                                <v-col dense tag="div" cols="9">
                                                    <v-text-field outlined dense label="Dealer Ref#" class="w-100"
                                                        v-model="editedCreditMemo.customer_ref_no"
                                                        :disabled="editedIndex === 1" />
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-row tag="div" align="start" class="mx-1 mt-3 mb-2">
                                <v-col dense cols="6">
                                    <v-textarea outlined no-resize dense class="w-100 mt-2 mb-1" label="Remarks"
                                        v-model="editedCreditMemo.remarks" :disabled="editedIndex === 1">
                                    </v-textarea>
                                </v-col>
                                <v-col dense tag="div" cols="6">
                                    <v-row v-if="this.status.items_with_qty_and_price.includes(this.editedCreditMemo.type)" dense no-gutters tag="div">
                                        <v-col dense tag="div" cols="3"></v-col>
                                        <v-col dense tag="div" cols="9" class="d-flex flex-column mb-2">
                                            <v-autocomplete outlined dense :items="GET_ALL_WAREHOUSES"
                                                v-model="warehouse.value" item-value="value" item-text="text"
                                                label="Warehouse" class="d-inline-block" :disabled="editedIndex === 1"
                                                @input="onSelectWarehouse()"></v-autocomplete>
                                        </v-col>
                                    </v-row>
                                    <v-row dense no-gutters tag="div" v-if="cmStatusText == 'pending'">
                                        <v-col dense tag="div" cols="3"></v-col>
                                        <v-col dense tag="div" cols="9" class="d-flex flex-column mb-2">
                                            <v-text-field
                                            dense
                                            outlined
                                            label="Credit Note Number"
                                            v-model="service_cm.credit_note_num"
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row dense no-gutters tag="div" v-if="cmStatusText == 'pending'">
                                        <v-col dense tag="div" cols="3"></v-col>
                                        <v-col dense tag="div" cols="9" class="d-flex flex-column mb-2">
                                            <v-text-field
                                            dense
                                            outlined
                                            label="Container Number"
                                            v-model="service_cm.container_num"
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>


                            <v-row tag="div" align="start" class="mx-1"
                                v-if="editedCreditMemo.rejection_remarks != null">
                                <v-col dense cols="8">
                                    <label for="customer_id" style="color: red"
                                        class="float-left control-label col-form-label">Rejection Remarks</label>
                                    <textarea v-model="editedCreditMemo.rejection_remarks"
                                        class="form-control d-inline-block" style="resize: vertical" readonly>
                                    </textarea>
                                </v-col>
                            </v-row>


                            <!-- CM TYPE 1/2/5/8/9 - RETURN/DEFECTIVE ITEMS (PT/HT) / QTY ADJUSTMENT / IN-WARRANTY RF / INTERNATIONAL DELIVERY DISCREPANCY REPORT -->
                            <v-simple-table v-if="status.items_with_qty_and_price.includes(editedCreditMemo.type)" fixed-header dense class="dynamic_list_table" style="margin-top: 5px; border: 1px solid #e0e0e0; max-height: 400px; overflow-y: auto;">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-center" width="20%">ITEM MODEL</th>
                                            <th class="text-center">ITEM NAME</th>
                                            <th class="text-center" width="8%">UOM</th>
                                            <th class="text-center" width="8%">QTY</th>
                                            <th class="text-center" width="15%" v-if="origin == 1">UNIT PRICE</th>
                                            <th class="text-center" width="15%" v-if="origin == 2">PRICE (USD)</th>
                                            <th class="text-center" width="15%" v-if="origin == 1">TOTAL AMOUNT</th>
                                            <th class="text-center" width="15%" v-else-if="origin == 2">TOTAL COST</th>
                                            <th class="text-right" width="9%" v-if="editedIndex < 1">
                                                <v-btn fab class="mr-1" dark small color="primary"
                                                    style="height: 20px; width: 20px" @click="addLine()"
                                                    v-if="editedIndex < 1">
                                                    <v-icon dark small style="font-size: 12px">
                                                        mdi-plus
                                                    </v-icon>
                                                </v-btn>
                                                <v-btn fab dark small color="primary" style="height: 20px; width: 20px"
                                                    @click="removeLine()" v-if="editedIndex < 1">
                                                    <v-icon dark small style="font-size: 12px">
                                                        mdi-minus
                                                    </v-icon>
                                                </v-btn>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in editedCreditMemoItems.data" :key="item.id"
                                            class="font-weight-medium text-gray">
                                            <td v-if="editedIndex != 1">
                                                <v-autocomplete outlined dense :disabled="editedIndex === 1"
                                                    :items="items.selection" v-model="item.item_id" item-value="value"
                                                    item-text="text" class="mt-1" label="Model"
                                                    style="d-inline-block small"
                                                    @input="onSelectItem(item.item_id, index)"></v-autocomplete>
                                            </td>
                                            <td v-else>
                                                <v-text-field type="text" outlined dense class="w-100 mt-2 mb-1"
                                                    label="Item Model" v-model="item.item_model" disabled />
                                            </td>
                                            <td>
                                                <v-text-field outlined dense class="w-100 mt-2 mb-1" label="Name"
                                                    type="text" v-model="item.item_name" disabled />
                                            </td>
                                            <td>
                                                <v-text-field outlined dense class="w-100 mt-2 mb-1" label="UOM"
                                                    v-model="item.item_uom" disabled />
                                            </td>
                                            <td>
                                                <v-text-field outlined dense class="w-100 mt-2 mb-1 text-center"
                                                    type="text" v-model="item.quantity_cm" @keypress="numberOnly"
                                                    @blur="computeLineTotal(index)" :disabled="editedIndex === 1" />
                                            </td>
                                            <td v-if="origin == 1">
                                                <v-text-field type="text" outlined dense
                                                    class="w-100 mt-2 mb-1 text-right" placeholder="PHP"
                                                    v-model="item.item_amount" @keypress="decimalOnly"
                                                    @blur="formatNumber(item.item_amount, index, 'item_amount')"
                                                    :disabled="editedIndex === 1" />
                                            </td>
                                            <td v-if="origin == 2">
                                                <v-text-field type="text" outlined dense
                                                    class="w-100 mt-2 mb-1 text-right" placeholder="USD"
                                                    v-model="item.item_cost" @keypress="decimalOnly"
                                                    @blur="formatNumber(item.item_cost, index, 'item_cost')"
                                                    :disabled="editedIndex === 1" />
                                            </td>
                                            <td v-if="origin == 1">
                                                <v-text-field outlined dense class="w-100 mt-2 mb-1 text-right"
                                                    placeholder="Total Amount" type="text" v-model="item.total_amount"
                                                    disabled />
                                            </td>
                                            <td v-else-if="origin == 2">
                                                <v-text-field outlined dense class="w-100 mt-2 mb-1 text-right"
                                                    placeholder="Total Cost" type="text" v-model="item.total_cost"
                                                    disabled />
                                            </td>
                                            <td class="text-center" v-if="editedIndex < 1">
                                                <v-btn fab dark small color="primary" style="height: 20px; width: 20px"
                                                    @click="removeLine(index)" v-if="editedIndex < 1">
                                                    <v-icon dark small style="font-size: 12px">
                                                        mdi-minus
                                                    </v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>

                            <!-- CM TYPE 3 - PRICE ADJUSTMENT -->
                            <v-simple-table v-else-if="status.items_with_price_only.includes(editedCreditMemo.type)" fixed-header dense class="dynamic_list_table" style="margin-top: 5px; border: 1px solid #e0e0e0; max-height: 400px; overflow-y: auto;">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-center" width="20%">ITEM MODEL</th>
                                            <th class="text-center">ITEM NAME</th>
                                            <th class="text-center" width="8%">UOM</th>
                                            <th class="text-center" width="15%">AMOUNT</th>
                                            <th class="text-right" width="9%" v-if="editedIndex < 1">
                                                <v-btn fab dark small class="mr-1" color="primary"
                                                    style="height: 20px; width: 20px" @click="addLine()"
                                                    v-if="editedIndex < 1">
                                                    <v-icon dark small style="font-size: 12px">
                                                        mdi-plus
                                                    </v-icon>
                                                </v-btn>
                                                <v-btn fab dark small color="primary" style="height: 20px; width: 20px"
                                                    @click="removeLine()" v-if="editedIndex < 1">
                                                    <v-icon dark small style="font-size: 12px">
                                                        mdi-minus
                                                    </v-icon>
                                                </v-btn>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in editedCreditMemoItems.data" :key="item.id"
                                            class="font-weight-medium text-gray">
                                            <td v-if="editedIndex != 1">
                                                <v-autocomplete outlined dense :disabled="editedIndex === 1"
                                                    :items="items.selection" v-model="item.item_id" item-value="value"
                                                    item-text="text" label="Model" class="my-2"
                                                    style="d-inline-block small"
                                                    @input="onSelectItem(item.item_id, index)"></v-autocomplete>
                                            </td>
                                            <td v-else>
                                                <v-text-field type="text" outlined dense label="Model"
                                                    v-model="item.item_model" class="small" disabled />
                                            </td>
                                            <td>
                                                <v-text-field type="text" outlined dense label="Name"
                                                    v-model="item.item_name" class="small" disabled />
                                            </td>
                                            <td>
                                                <v-text-field type="text" outlined dense label="Uom"
                                                    v-model="item.item_uom" class="text-center small" disabled />
                                            </td>
                                            <td>
                                                <v-text-field type="text" outlined dense label="Amount"
                                                    v-model="item.item_amount" class="text-right"
                                                    @keypress="decimalOnly"
                                                    @blur="formatNumber(item.item_amount, index, 'item_amount')"
                                                    :disabled="editedIndex === 1" />
                                            </td>
                                            <td class="text-center" v-if="editedIndex < 1">
                                                <v-btn fab dark small color="primary" style="height: 20px; width: 20px"
                                                    @click="removeLine(index)" v-if="editedIndex < 1">
                                                    <v-icon dark small style="font-size: 12px">
                                                        mdi-minus
                                                    </v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>

                            <!-- CM TYPE 4/6/7 - GENERAL ADJUSTMENT / CASH DISCOUNT / BIR 2307 / CANCEL SOA BALANCE-->
                            <v-simple-table v-else-if="status.generic.includes(editedCreditMemo.type)" fixed-header dense class="overflow-y-auto" style="border: 1px solid #e0e0e0; max-height: 283px; overflow-y: auto;">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">PARTICULARS</th>
                                            <th class="text-right" width="18%">AMOUNT</th>
                                            <th class="text-right" width="9%" v-if="editedIndex < 1">
                                                <v-btn fab dark small color="primary" style="height: 20px; width: 20px"
                                                    @click="addLine()" v-if="editedIndex < 1">
                                                    <v-icon dark small style="font-size: 12px">
                                                        mdi-plus
                                                    </v-icon>
                                                </v-btn>
                                                <v-btn fab dark small color="primary" class="ml-1"
                                                    style="height: 20px; width: 20px" @click="removeLine()"
                                                    v-if="editedIndex < 1">
                                                    <v-icon dark small style="font-size: 12px">
                                                        mdi-minus
                                                    </v-icon>
                                                </v-btn>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in editedCreditMemoItems.data" :key="item.id"
                                            class="font-weight-medium text-gray py-2">
                                            <td>
                                                <v-text-field class="my-1 text-left" type="text" outlined dense
                                                    v-model="item.remarks"
                                                    @click="$event.target.focus()" @focus="$event.target.select()"
                                                    :disabled="loading.state || editedIndex === 1" />
                                            </td>
                                            <td>
                                                <v-text-field class="my-1 text-right w-100" type="text" outlined dense
                                                    v-model="item.total_amount"
                                                    @click="$event.target.focus()" @focus="$event.target.select()"
                                                    @keypress="decimalOnly"
                                                    @blur="formatNumber(item.total_amount, index, 'total_amount')"
                                                    :disabled="loading.state || editedIndex === 1" />
                                            </td>
                                            <td class="text-right" v-if="editedIndex < 1">
                                                <v-btn fab dark small color="primary" style="height: 20px; width: 20px"
                                                    @click="removeLine(index)" v-if="editedIndex < 1">
                                                    <v-icon dark small style="font-size: 12px">
                                                        mdi-minus
                                                    </v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>

                            <v-row class="m-0 mt-1">
                                <v-spacer></v-spacer>
                                <v-col sm="12" md="4" class="pull-right text-right">
                                    <h5>
                                        <span v-if="origin == 1">
                                            Total Amount: &#x20b1;{{ this.credit_memo_total_amount | currency }}
                                        </span>
                                        <span v-else-if="origin == 2">
                                            <br />
                                            Total Cost: &#x24;{{ this.credit_memo_total_cost | currency }}
                                        </span>
                                    </h5>
                                </v-col>
                            </v-row>
                            <div class="border-top">
                                <v-row class="mt-3" v-if="origin == 1">
                                    <div class="col-sm-12 col-md-5 text-left">
                                        <v-btn v-if="editedIndex === 1 && (editedCreditMemo.status === 1 || editedCreditMemo.status === 3) && (userAccess.approve_acctg || userAccess.approve_exec)"
                                            :disabled="loading.state || editedCreditMemoItems.data.length == 0"
                                            @click="showRejectDialog(1)"
                                            color="error"
                                            class="mr-1"
                                        >
                                            REJECT
                                        </v-btn>
                                    </div>
                                    <v-col sm="12" md="7" class="text-right mt-3">
                                        <v-btn v-if="editedIndex === -1" @click="saveNewCreditMemo()" :loading="loading.state" class="mr-2 mb-6">
                                            <v-icon class="mr-1">mdi-plus</v-icon>
                                            Save CM
                                        </v-btn>
                                        <v-btn v-if="editedIndex === 0" @click="updateCreditMemo(editedCreditMemo.id)"
                                            class="mr-2 mb-6">
                                            <v-icon class="mr-1">mdi-plus</v-icon>
                                            Update CM
                                        </v-btn>
                                        <v-btn v-if="editedIndex === 1 && editedCreditMemo.status === 0" :disabled="loading.state || editedCreditMemoItems.data.length == 0" @click="approveCreditMemo(1)" class="mr-2 mb-6">
                                            Submit For Approval
                                        </v-btn>
                                        <v-btn v-if="editedIndex === 1 && (editedCreditMemo.status === 1 || editedCreditMemo.status == 2) && userAccess.approve_acctg"
                                            :disabled="loading.state || editedCreditMemoItems.data.length == 0"
                                            @click="approveCreditMemo(3)"
                                            class="mr-2 mb-6"
                                        >
                                            Approval (Accounting)
                                        </v-btn>
                                        <!-- <v-btn v-if="editedIndex === 1 && editedCreditMemo.status === 2 && userAccess.approve_exec"
                                            :disabled="loading.state || editedCreditMemoItems.data.length == 0"
                                            @click="approveCreditMemo(3)"
                                            class="mr-2 mb-6"
                                        >
                                            Approval (Exec)
                                        </v-btn> -->
                                        <v-btn v-if="editedIndex === 1 && editedCreditMemo.status === 3"
                                            :disabled="loading.state || editedCreditMemoItems.data.length == 0"
                                            @click="printCreditMemo()"
                                            class="mr-2 mb-6"
                                        >
                                            <v-icon class="mr-1">mdi-plus</v-icon>
                                            Print CM
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-3" v-else-if="origin == 2">
                                    <div class="col-sm-12 col-md-5 text-left">
                                        <v-btn v-if="editedIndex === 1 && (editedCreditMemo.status === 1 || editedCreditMemo.status === 3) && (userAccess.approve_acctg || userAccess.approve_exec)"
                                            :disabled="loading.state || editedCreditMemoItems.data.length == 0"
                                            @click="showRejectDialog(1)"
                                            color="error"
                                            class="mr-1"
                                        >
                                            REJECT
                                        </v-btn>
                                    </div>
                                    <v-col sm="12" md="7" class="text-right mt-3">
                                        <v-btn v-if="editedIndex === -1" @click="saveNewCreditMemo()" class="mr-2 mb-6">
                                            <v-icon class="mr-1">mdi-plus</v-icon>
                                            Save CM
                                        </v-btn>
                                        <v-btn v-if="cmStatusText == 'pending'" :disabled="loading.state || editedCreditMemoItems.data.length == 0" @click="approveCreditMemo(1)" class="mr-2 mb-6">
                                            Submit For Approval
                                        </v-btn>
                                        <v-btn v-if="editedIndex === 1 && editedCreditMemo.status === 3"
                                            :disabled="loading.state || editedCreditMemoItems.data.length == 0"
                                            @click="printCreditMemo()"
                                            class="mr-2 mb-6"
                                        >
                                            <v-icon class="mr-1">mdi-plus</v-icon>
                                            Print CM
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </form>
        </v-dialog>
        <v-dialog v-model="email_technical_report_dialog" width="30%">
            <v-card>
                <v-row class="m-0">
                    <v-col cols="8">
                        <v-card-title>
                            Technical Report
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right 4">
                        <v-btn text icon color="gray" class="float-right" @click="email_technical_report_dialog = false">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text v-if="loading.state">
                    <div  style="text-align:center">
                        <v-progress-circular
                            indeterminate
                            color="primary"
                            :size="50"
                        />
                    </div>
                </v-card-text>
                <v-card-text v-else>
                    <v-row>
                        <v-col>
                            <v-menu
                                v-model="menu.date_from"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="filters.date_from"
                                        prepend-inner-icon="mdi-calendar"
                                        outlined
                                        dense
                                        readonly
                                        v-on="on"
                                        style="display: block"
                                        background-color="white"
                                        label="Date From"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="filters.date_from" @click:month="menu.date_from = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col>
                            <v-menu
                                v-model="menu.date_to"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="filters.date_to"
                                        prepend-inner-icon="mdi-calendar"
                                        outlined
                                        dense
                                        readonly
                                        v-on="on"
                                        style="display: block"
                                        background-color="white"
                                        label="Date To"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="filters.date_to" @click:month="menu.date_to = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <!-- <v-row>
                        <v-col>
                            <v-text-field
                            outlined
                            dense
                            hide-details
                            label="Subject of Email"
                            v-model="email.email_subject">

                            </v-text-field>
                        </v-col>
                    </v-row> -->
                </v-card-text>
                <v-card-actions class="d-flex justify-end">
                    <v-btn v-if="!loading.state" :disabled="!!!filters.date_to" small @click="sendTechnicalReportEmail('download')">
                        Download PDF
                    </v-btn>
                    <v-btn v-if="!loading.state" :disabled="!!!filters.date_to" small @click="sendTechnicalReportEmail('email')">
                        Send Mail
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- CREDIT MEMO LIST -->
        <v-row v-if="!isSoa && !isWir && !tab_name && !fromReports" class="mt-1">
            <v-col class="col-12">
                <v-progress-linear v-if="loading.state" indeterminate color="green"></v-progress-linear>
                <v-card>
                    <v-container fluid class="px-3 py-0">
                        <ReportsCardsComponentVue :totals="totals" :cards="cards" style="width:100%;" :setClass="'ma-0 pa-0'" v-if="origin == 1"></ReportsCardsComponentVue>
                        <v-row class="mx-auto my-0 align-items-center">
                            <v-col sm="12" md="2" class="px-3 pt-2 pb-0 d-flex flex-column mt-1">
                                <v-autocomplete outlined dense :items="customer.selection" auto-select-first
                                    v-model="customer.search" item-value="value" label="Customer" item-text="text"
                                    class="d-inline-block">
                                </v-autocomplete>
                            </v-col>
                            <v-col lg="2" sm="12" md="2" class="px-3 pt-2 pb-0 mt-1" v-if="origin != 2">
                                <v-menu v-model="creditMemoList.datatable.filters.soa_month.menu"
                                    :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                                    min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="creditMemoList.datatable.filters.soa_month.values" prepend-inner-icon="mdi-calendar" outlined dense readonly label="SOA Month From" clearable v-on="on" style="display: block"></v-text-field>
                                    </template>
                                    <v-date-picker type="month" v-model="creditMemoList.datatable.filters.soa_month.values" @click:month="creditMemoList.datatable.filters.soa_month.menu = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col lg="2" sm="12" md="2" class="px-3 pt-2 pb-0 mt-1" v-if="origin != 2">
                                <v-menu v-model="creditMemoList.datatable.filters.soa_month_to.menu"
                                    :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                                    min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="creditMemoList.datatable.filters.soa_month_to.values" prepend-inner-icon="mdi-calendar" outlined dense readonly label="SOA Month To" clearable v-on="on" style="display: block"></v-text-field>
                                    </template>
                                    <v-date-picker type="month" v-model="creditMemoList.datatable.filters.soa_month_to.values" @click:month="creditMemoList.datatable.filters.soa_month_to.menu = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col v-if="origin == 2" sm="12" md="2" class="px-3 pt-2 pb-0 d-flex flex-column">
                                <v-autocomplete outlined dense :items="GET_CREDIT_MEMO_TYPES"
                                    v-model="creditMemoList.datatable.filters.cm_type" item-value="value" label="CM Type" item-text="text"
                                    class="d-inline-block mt-1"
                                    clearable
                                    auto-select-first>
                                </v-autocomplete>
                            </v-col>
                            <v-col sm="12" md="1" class="d-flex align-items-center ml-2 mb-1 pt-2 pb-0">
                                <v-btn class="mr-2" @click="getAllCreditMemos()">Filter</v-btn>
                                <v-btn class="mr-2" @click="getAllCreditMemos(1)">Excel</v-btn>
                                <!-- <v-btn v-if="origin==2" @click="showEmailTechnicalReportDialog()">Technical Report</v-btn>                 -->
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="12" md="4" class="text-right pt-2 pb-0" align-self="start" v-if="origin == 1">
                                <a href="#" class="badge badge-all mr-2" @click="status_id = -1; getAllCreditMemos();">all</a>
                                <a href="#" class="badge badge-saved mr-2" @click="status_id = 0;  getAllCreditMemos();">saved</a>
                                <a href="#" class="badge badge-for-accounting mr-2" @click="status_id = 1; getAllCreditMemos();">for accounting</a>
                                <!-- <a href="#" class="badge badge-for-exec mr-2" @click="status_id = 2; getAllCreditMemos();">for exec</a> -->
                                <a href="#" class="badge badge-approved mr-2" @click="status_id = 3; getAllCreditMemos();">approved</a>
                                <a href="#" class="badge badge-completed mr-2" @click="status_id = 4; getAllCreditMemos();">completed</a>
                                <!-- <a href="#" class="badge badge-rejected" @click="creditMemoList.datatable.status_id=5; getAllCreditMemos()">rejected</a> -->
                                <a href="#" class="badge badge-canceled mr-2" @click="status_id = 6; getAllCreditMemos();">canceled</a>
                                <a href="#" class="badge badge-canceled mr-2" @click="status_id = 10; getAllCreditMemos();">cancel soa balance</a>
                                <!-- <a href="#" class="badge badge-deleted" @click="creditMemoList.datatable.status_id=7; getAllCreditMemos()">deleted</a> -->
                            </v-col>
                            <v-col cols="12" md="5" class="text-right pt-2 pb-0" align-self="start" v-else-if="origin == 2 && status_text == ''">
                                <a href="#" class="badge badge-all mr-2" @click="status_id = -1; getAllCreditMemos();">all</a>
                                <a href="#" class="badge badge-saved mr-2" @click="status_id = 8; getAllCreditMemos();">confirmed</a>
                                <a href="#" class="badge badge-pending mr-2" @click="status_id = 8; getAllCreditMemos();">pending</a>
                                <a href="#" class="badge badge-approved mr-2" @click="status_id = 9; getAllCreditMemos();">approved</a>
                                <!-- <a href="#" class="badge badge-canceled mr-2" @click="status_id = 9; getAllCreditMemos();">canceled</a> -->
                            </v-col>
                        </v-row>
                        <v-row v-if="origin == 1" class="mx-auto align-items-center ma-0">
                            <v-col m="12" md="3" class="px-3 d-flex flex-column mt-1">
                                <v-autocomplete outlined dense :items="GET_CREDIT_MEMO_TYPES"
                                    v-model="creditMemoList.datatable.filters.cm_type" item-value="value" label="CM Type" item-text="text"
                                    class="d-inline-block mt-1"
                                    clearable
                                    auto-select-first>
                                </v-autocomplete>
                            </v-col>
                            <v-spacer/>
                        </v-row>
                    </v-container>
                    <v-container fluid class="p-3 pt-0">
                        <v-row class="mx-auto my-0 align-items-center">
                            <v-col cols="2" class="mx-auto">
                                <v-switch
                                    v-model="showWithoutSoa"
                                    :true-value="1"
                                    :false-value="0"
                                    class="pl-1"
                                    color="orange"
                                    label="No SOA Tagged"
                                    @change="getAllCreditMemos()"
                                ></v-switch>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col sm="12" md="4" class="mr-3 align-items-end">
                                <v-text-field v-model="creditMemoList.datatable.search" label="Search CM#/Remarks/Ref#/RF#"
                                    single-line hide-details append-icon="mdi-magnify"
                                    @keydown.enter="getAllCreditMemos()"
                                    @click:append="getAllCreditMemos()"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-col cols="12">
                            <v-data-table dense v-model="dataTable" :search="creditMemoList.datatable.search"
                                :headers="tableHeaders" :items="creditMemoList.data"
                                :loading="!creditMemoList.datatable.loaded" :options.sync="pagination.options"
                                :server-items-length="creditMemoList.datatable.total" :show-select="showCheckbox"
                                @input="getSelectedID" class="w-100">
                                <template v-slot:[`item.status`]="{ item }">
                                    <span class="badge badge-saved" v-if="item.status_text == 'saved'">{{ item.status_text }}</span>
                                    <span class="badge badge-for-accounting" v-if="item.status_text == 'for accounting'">{{ item.status_text }}</span>
                                    <span class="badge badge-for-exec" v-else-if="item.status_text == 'for exec'">{{ item.status_text }}</span>
                                    <span class="badge badge-approved" v-else-if="item.status_text == 'approved'">{{ item.status_text }}</span>
                                    <span class="badge badge-completed" v-else-if="item.status_text == 'completed'">{{ item.status_text }}</span>
                                    <span class="badge badge-rejected" v-else-if="item.status_text == 'rejected'">{{ item.status_text }}</span>
                                    <span class="badge badge-canceled" v-else-if="item.status_text == 'canceled'">{{ item.status_text }}</span>
                                    <span class="badge badge-pending" v-else-if="item.status_text == 'pending'">{{ item.status_text }}</span>
                                    <span class="badge badge-saved" v-else-if="item.status_text == 'confirmed'">{{ item.status_text }}</span>
                                    <span class="badge badge-dark" v-else-if="item.status_text == 'deleted'">{{ item.status_text }}</span>
                                    <span class="badge badge-dark" v-else-if="item.status_text == 'cancel soa balance'">{{ item.status_text }}</span>
                                </template>
                                <template v-slot:[`item.total_amount`]="{ item }">
                                    <span class="text-nowrap">
                                        &#x20b1;{{ item.total_amount | currency }}
                                    </span>
                                </template>
                                <template v-slot:[`item.total_cost`]="{ item }">
                                    <span class="text-nowrap">
                                        &#x24;{{ item.total_cost | currency }}
                                    </span>
                                </template>
                                <template v-slot:[`item.printed`]="{ item }">
                                    <span class="badge bg-primary" v-if="item.printed">Yes</span>
                                    <span class="badge bg-danger" v-else>No</span>
                                </template>
                                <template v-slot:[`item.action`]="{ item }">
                                    <span class="text-nowrap">
                                        <v-btn text icon color="orange" v-if="userAccess.view">
                                            <v-icon class="btn-action" small
                                                @click="viewCreditMemo(item)">mdi-eye</v-icon>
                                        </v-btn>
                                        <v-btn text icon color="blue" v-if="userAccess.edit && item.status === 0">
                                            <v-icon class="btn-action" small
                                                @click="editCreditMemo(item)">mdi-pencil</v-icon>
                                        </v-btn>
                                        <!-- <v-btn text icon color="red" v-if="userAccess.delete">
                                                <v-icon class="btn-action" small @click="deleteCreditMemo(item.id)">fas fa-trash-alt</v-icon>
                                            </v-btn> -->
                                        <v-btn text icon color="red" v-if="userAccess.cancel && item.status !== 6">
                                            <v-icon class="btn-action" small
                                                @click="cancelCreditMemo(item.id)">mdi-cancel</v-icon>
                                        </v-btn>
                                    </span>
                                </template>
                            </v-data-table>
                            <div v-if="convertBtn" class="table-footer-prepend d-flex pl-2 align-center"
                                style="margin-top: -52px; height: 58px">
                                <v-btn :loading="btn_loader" v-if="status_id == 0 && userAccess.view"
                                    @click="updateCMStatus(1)">SUBMIT FOR APPROVAL</v-btn>
                                <v-btn :loading="btn_loader" v-if="(status_id == 1 || status_id == 2) && userAccess.view"
                                    @click="updateCMStatus(3)">APPROVAL (ACCOUNTING)</v-btn>
                                <!-- <v-btn :loading="btn_loader" v-if="status_id == 2 && userAccess.view"
                                    @click="updateCMStatus(3)">APPROVAL (EXEC)</v-btn> -->
                                <v-btn :loading="btn_loader" v-if="status_id == 3 && userAccess.view"
                                    @click="printCM()">PRINT CM</v-btn>
                            </div>
                        </v-col>
                    </v-container>

                    <v-container fluid style="display: none" v-if="!fromReports">
                        <div id="ctrl_num_label_print" style="font-weight: bold">
                            Control No.:
                        </div>
                        <div id="ctrl_num_value_print" style="font-weight: bold">
                            {{ "CM# " + print.credit_memo_num }}
                        </div>

                        <div>
                            <table id="headers_print">
                                <tbody>
                                    <tr>
                                        <td>SOA Month:</td>
                                        <td>{{ print.soa_month_year }}</td>
                                        <td colspan="2" style="font-weight: bold">Date Prepared</td>
                                        <td colspan="2">{{ print.credit_memo_date }}</td>
                                    </tr>
                                    <tr>
                                        <td style="font-weight: bold">Customer:</td>
                                        <td>{{ print.customer_name }}</td>
                                        <td colspan="2" style="font-weight: bold">Ref PLs:</td>
                                        <td colspan="2">{{ print.packing_list_nums }}</td>
                                    </tr>
                                    <tr>
                                        <td style="font-weight: bold">Address:</td>
                                        <td>{{ print.billing_address_text }}</td>
                                        <td colspan="2" style="font-weight: bold">
                                            Dealers Ref No:
                                        </td>
                                        <td colspan="2">{{ print.customer_ref_no }}</td>
                                    </tr>
                                    <tr>
                                        <td style="font-weight: bold">Contact Person:</td>
                                        <td>{{ print.billing_contact_person }}</td>
                                        <td colspan="2" style="font-weight: bold">
                                            Contact Number:
                                        </td>
                                        <td colspan="2">{{ print.billing_contact_number }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div>
                            <table id="items_print">
                                <thead>
                                    <tr>
                                        <td>ITEM CODE</td>
                                        <td>DESCRIPTION</td>
                                        <td>QTY</td>
                                        <td>UOM</td>
                                        <td>UNIT COST</td>
                                        <td>ITEM TOTAL</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in print.credit_memo_items" :key="index">
                                        <td>{{ item.model }}</td>
                                        <td>{{ item.name }}</td>
                                        <td>{{ item.quantity_cm }}</td>
                                        <td>{{ item.item_uom_text }}</td>
                                        <td v-if="item.item_id != null">
                                            {{ item.item_amount | currency }}
                                        </td>
                                        <td v-else>{{}}</td>
                                        <td v-if="item.item_id != null">
                                            {{ item.item_total | currency }}
                                        </td>
                                        <td v-else>{{}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div>
                            <table id="totals_print">
                                <tbody>
                                    <tr>
                                        <td style="font-weight: bold">Remarks</td>
                                        <td>{{ print.remarks }}<br /></td>
                                        <td colspan="2" style="font-weight: bold">AMOUNT FOR CM</td>
                                        <td colspan="2">{{ print.total_amount | currency }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div>
                            <table id="signatories_print">
                                <tbody>
                                    <tr>
                                        <td>Processed by:</td>
                                        <td><br /><br />{{ print.created_by_name }}</td>
                                        <td colspan="2">Checked by:</td>
                                        <td colspan="2">
                                            <br /><br />{{ print.acct_approved_by_name }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Approved by:</td>
                                        <td><br /><br />{{ print.exec_approved_by_name }}</td>
                                        <td colspan="2">Received by:</td>
                                        <td colspan="2"><br /><br />{{}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div id="footer_1_print" style="font-size: 10px">
                            <span style="margin-right: 16px"><i>WHITE - ACCTG COPY</i></span>
                        </div>

                        <div id="footer_2_print" style="font-size: 10px">
                            <span><i>PINK - CUSTOMER COPY</i></span>
                        </div>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="chinaDialog" max-width="60%" scrollable>
            <v-card>
                    <v-row class="m-0">
                        <v-col cols="8">
                            <v-card-title>
                                {{ addEditDialogAction == 'new' ? 'Create Credit Memo China' : 'View Credit Memo China | CMC ID: '+ editedCreditMemoChinaId }}
                            </v-card-title>
                        </v-col>
                        <v-col cols="pull-right 4">
                            <v-btn text icon color="gray" class="float-right" @click="closeChinaDialog()">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card-text style="max-height:720px; width:100%; margin-bottom:10px; overflow-y:auto; overflow-x:hidden">
                        <v-row>
                            <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                                <v-menu
                                    v-model="cm_china.menu_start"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    min-width="290px"
                                    :disabled="editedIndex === 1"
                                >
                                    <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="cm_china.start_date"
                                        readonly
                                        label="Start Day"
                                        append-icon="mdi-calendar-clock"
                                        v-on="on"
                                        :disabled="editedIndex === 1"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="cm_china.start_date" @input="cm_china.menu_start = false">
                                    </v-date-picker>
                                </v-menu>
                                <span v-for="errors in errors.due_date" class="text-warning" v-bind:key="errors">{{errors}}</span>
                            </v-col>
                            <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                                <v-menu
                                    v-model="cm_china.menu_end"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    min-width="290px"
                                    :disabled="editedIndex === 1"
                                >
                                    <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="cm_china.end_date"
                                        readonly
                                        label="End Day"
                                        append-icon="mdi-calendar-clock"
                                        v-on="on"
                                        :disabled="editedIndex === 1"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="cm_china.end_date" @input="cm_china.menu_end = false"  :disabled="editedIndex === 1">
                                    </v-date-picker>
                                </v-menu>
                                <span v-for="errors in errors.due_date" class="text-warning" v-bind:key="errors">{{errors}}</span>
                            </v-col>
                            <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                                <v-btn class="mt-3" @click="generateCreditMemoChina()" :disabled="editedIndex === 1" >Generate CM</v-btn>
                            </v-col>

                            <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="text-right">
                                <v-btn class="mt-3 mr-1" @click="sendTechnicalReportEmail('download')">
                                    Export
                                </v-btn>
                                <v-btn class="mt-3" @click="printCreditMemoChina('print')" v-if="addEditDialogAction=='view'">Print</v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="d-flex justify-content-end align-item-center mb-1">
                                <div class="d-flex align-center">
                                    <FileUploadComponent v-if="(addEditDialogAction=='view' && cm_china.confirm_date !== null) && (userAccess.confirm_cm_soa) && cm_china.approved_date === null || cm_china.approved_date !== null" class="pr-3"  ref="fileUpload" @uploadedData="uploadedData" @reset="clearFileUpload" />
                                    <BaseFileViewer v-if="(addEditDialogAction=='view' && cm_china.confirm_date !== null) && (userAccess.confirm_cm_soa) && cm_china.approved_date === null || cm_china.approved_date !== null"  class="mx-2 mt-1" :creditMemoChinaId="editedCreditMemoChinaId"
                                    :delete="userAccess.delete_file" />
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                                <v-textarea v-model="cm_china.remarks" label="Remarks" dense rows="2"  :disabled="editedIndex === 1"></v-textarea>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col class="text-right" cols="3" v-if="cm_china.confirm_date !== null">
                                <v-menu
                                    v-model="menu.credit_note_date"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            v-model="cm_china.credit_note_date"
                                            prepend-inner-icon="mdi-calendar"
                                            outlined
                                            dense
                                            readonly
                                            v-on="on"
                                            style="display: block"
                                            background-color="white"
                                            label="Credit Note Date"
                                            :disabled="cm_china.approved_date !== null"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="cm_china.credit_note_date" @click:month="menu.credit_note_date = false"></v-date-picker>
                                </v-menu>
                                <v-text-field
                                dense
                                outlined
                                label="Credit Note Number"
                                v-model="cm_china.credit_note_num"
                                :disabled="cm_china.approved_date !== null"
                                >
                                </v-text-field>
                                <v-text-field
                                dense
                                outlined
                                label="Container Number"
                                v-model="cm_china.container_num"
                                :disabled="cm_china.approved_date !== null"
                                >
                                </v-text-field>
                                <v-text-field
                                dense
                                outlined
                                label="Approve Dollar"
                                type="number"
                                v-model="cm_china.approved_dollar"
                                prepend-inner-icon="mdi-currency-usd"
                                :disabled="cm_china.approved_date !== null"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="pa-2">
                            <v-col cols="12">
                                <v-card max-height="500px" style="overflow: auto;">
                                    <v-card-text>
                                        <v-data-table
                                        :headers="cm_china.table.headers"
                                        :items="cm_china.table.items"
                                        max-height="400px">
                                        <template v-slot:[`item.total_amount`]="{ item }">
                                            <span class="text-nowrap">
                                                &#x20b1;{{ item.total_amount | currency }}
                                            </span>
                                        </template>
                                        <template v-slot:[`item.total_cost`]="{ item }">
                                            <span class="text-nowrap">
                                                &#x24;{{ item.total_cost | currency }}
                                            </span>
                                        </template>
                                        <template v-slot:[`item.action`]="{ item,index }">
                                            <span class="text-nowrap">
                                                <v-btn text icon color="orange" v-if="userAccess.view  || fromReports">
                                                    <v-icon class="btn-action" small
                                                        @click="viewCreditMemo(item)">mdi-eye</v-icon>
                                                </v-btn>
                                                <v-btn text icon color="red" v-if="cm_china.confirm_date === null">
                                                    <v-icon class="btn-action" small
                                                        @click="removeCreditMemo(item.id,index)">mdi-delete</v-icon>
                                                </v-btn>
                                            </span>
                                        </template>
                                        </v-data-table>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="text-right">
                                <span>Total Amount : &#x20b1;{{ credit_memo_china_total_amount | currency }}</span><br/>
                                <span>Total Cost : &#x24;{{ credit_memo_china_total_cost | currency }}</span>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="border-top">
                        <div class="card-body py-4 d-flex justify-content-center">
                            <v-btn @click="addCreditMemoChina()" v-if="addEditDialogAction=='new'">Submit</v-btn>
                            <v-btn @click="updateCreditMemoChina()" v-else-if="addEditDialogAction=='edit'">Update</v-btn>
                            <v-btn @click="confirmCreditMemoChina()" v-else-if="addEditDialogAction=='view' && cm_china.confirm_date === null && userAccess.confirm_cm_soa">Confirm</v-btn>
                            <v-btn @click="approveCreditMemoChina()" v-else-if="(addEditDialogAction=='view' && cm_china.confirm_date !== null) && (userAccess.confirm_cm_soa) && cm_china.approved_date === null || cm_china.approved_date !== null">Approve All CM</v-btn>
                        </div>
                    </v-card-actions>
            </v-card>
        </v-dialog>

        <v-card class="pa-4" v-if="origin == 2 && tab_name">
            <v-row>
                <v-spacer></v-spacer>
                <v-col class="pa-2 text-right" cols="3">
                    <v-btn class="mt-3 mr-1" @click="exportCreditMemoChina()">
                        Export
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-spacer></v-spacer>
                <v-col class="pa-2 text-right" cols="3">
                    <v-text-field
                        v-model="cm_china.main_table.search"
                        single-line
                        hide-details
                        label="Search Container #/Credit Note #/CMC ID"
                        append-icon="mdi-magnify"
                        @keydown.enter="getAllCreditMemoChina()"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pa-2">
                    <v-data-table
                    :headers="cm_china.main_table.headers"
                    :items="cm_china.main_table.items"
                    :loading="cm_china.main_table.loading"
                    :options.sync="cmc_options"
                    :server-items-length="totalCreditMemoChina"
                    :items-per-page="10"
                    dense>
                    <template v-slot:[`item.total_amount`]="{ item }">
                        <span class="text-nowrap">
                            &#x20b1;{{ item.total_amount | currency }}
                        </span>
                    </template>
                    <template v-slot:[`item.created_at`]="{ item }">
                        <span class="text-nowrap">
                            {{ item.created_at | formatDate }}
                        </span>
                    </template>
                    <template v-slot:[`item.total_cost`]="{ item }">
                        <span class="text-nowrap">
                            &#x24;{{ item.total_cost | currency }}
                        </span>
                    </template>
                    <template v-slot:[`item.action`]="{ item,index }">
                        <span class="text-nowrap">
                            <v-btn text icon color="orange" v-if="userAccess.view">
                                <v-icon class="btn-action" small
                                    @click="viewCreditMemoChina(index,1,'view',item.id)">mdi-eye</v-icon>
                            </v-btn>
                            <v-btn text icon color="primary" v-if="userAccess.edit && item.confirm_date === null">
                                <v-icon class="btn-action" small
                                    @click="viewCreditMemoChina(index,0,'edit',item.id)">mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn text icon color="red" v-if="userAccess.disapprove_cm_soa">
                                <v-icon class="btn-action" small
                                    @click="disapproveCreditMemoChina(item.id)">mdi-cancel</v-icon>
                            </v-btn>
                        </span>
                    </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card>
        <!-- PRINT CREDIT MEMO -->
        <CreditMemoPrintComponentVue :credit_memo="editedCreditMemo"
            :credit_memo_items="editedCreditMemoItems.print_data" @getAllCreditMemos="getAllCreditMemos"
            @closeDialog="closeDialog" v-if="print_credit_memo"></CreditMemoPrintComponentVue>

        <PrintTechnicalReportPdfVue></PrintTechnicalReportPdfVue>
        <PrintRepairFormComponent></PrintRepairFormComponent>
        <PrintReceivingReportComponent></PrintReceivingReportComponent>
        <CreditMemoChinaPrintComponentVue :creditMemoChinaPrintData="creditMemoChinaPrintData" v-if="print_credit_memo_china" @getBase64CmChinaSoaPrint="getBase64CmChinaSoaPrint" @clearCmChinaPrintData="clearCmChinaPrintData"> </CreditMemoChinaPrintComponentVue>
        <div style="display:none;">
            <table id="tech_report">
                <tbody>
                    <tr>
                        <td colspan="2">Tech Report No:<br/></td>
                    </tr>
                    <tr>
                        <td>Model No:</td>
                        <td>Dealer:</td>
                    </tr>
                    <tr>
                        <td>Serial No:</td>
                        <td>Customer:</td>
                    </tr>
                    <tr>
                        <td>Date Purchased:</td>
                        <td>Sold On-Line:</td>
                    </tr>
                    <tr>
                        <td>Date Received:</td>
                        <td>Repair Form No:</td>
                    </tr>
                    <tr>
                        <td>Warranty:</td>
                        <td>Technician:</td>
                    </tr>
                    <tr>
                        <td colspan="2">Complaint:</td>
                    </tr>
                    <tr>
                        <td colspan="2">Symptom Tested:</td>
                    </tr>
                </tbody>
            </table>
            <table id="tech_diagnosis">
                <tbody>
                    <tr>
                        <td>Tech Diagnosis:</td>
                    </tr>
                </tbody>
            </table>
            <!-- <table id="parts_needed"> -->
            <div v-for="(n, x) in cm_china_rfs" :key="x">
            <table :id="'parts_needed_'+x">
                <tbody class="tBody">
                    <tr>
                        <td colspan="4">Parts Needed</td>
                    </tr>
                    <tr>
                        <td>SP Name</td>
                        <td>SP No</td>
                        <td>Description</td>
                        <td>Qty</td>
                    </tr>

                    <tr v-for="(p,index) in n.parts_needed" :key='index'>
                        <td> {{ p.name }}</td>
                        <td> {{ p.part_num }}</td>
                        <td> {{ p.description }}</td>
                        <td> {{ p.serve_qty }}</td>
                    </tr>

                    <tr v-for="s in remaining_row" :key="s">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                        </tbody>
                    </table>

                    <table :id="'signatories_'+x">
                        <tbody>
                            <tr>
                                <td>Prepared by: <br/></td>
                                <td>Diagnosed by: <br/></td>
                                <td>Approved by: <br/></td>
                            </tr>
                            <tr>
                                <td><u>{{ n.prepared_by }}</u><br/>Repair Admin</td>
                                <td>{{ n.assigned_technician }}<br/>Repair Technician</td>
                                <td>Alfredo Joey Santos<br/>Service Manager</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                    <table id="recommendation">
                        <tbody>
                            <tr>
                                <td>Recommendation:</td>
                            </tr>
                        </tbody>
                    </table>
      </div>
        <v-dialog v-model="loading_dialog" max-width="15%" persistent >
            <v-card class="ma-0 pa-0">
                <v-row class="ma-0 pa-0">
                    <v-col class="d-flex justify-content-center align-item-center">
                        <v-card-text class="text-center">
                            <h3 class="mt-2">Loading Please Wait</h3>
                            <h5 class="mt-2">{{ export_count.text }}</h5>
                            <v-progress-linear
                                color="light-green darken-4"
                                height="10"
                                :value="export_count.progress"
                                :buffer-value="export_count.progress"
                                stream
                                striped
                                :indeterminate="export_count.isIntermediate"
                            ></v-progress-linear>
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <!-- Change CM Type Dialog -->
        <v-dialog v-model="change_type.dialog" scrollable persistent max-width="30%" style="z-index: 30">
            <v-card>
                <v-progress-linear v-if="loading.state" indeterminate color="green"></v-progress-linear>
                <v-row class="m-0">
                    <v-col cols="8">
                        <v-card-title>
                            <span class="text-lg font-semibold"> Change Credit Memo Type</span>
                        </v-card-title>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="2">
                        <v-btn text icon color="gray" class="float-right" @click="triggerChangeTypeDialog(false)">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-card-text style="max-height: 460px; border: 1px solid #e0e0e0; padding: 0">
                    <v-row v-if="loading.state" tag="div" align="center" no-gutters class="px-2" v-text="loading.title">
                    </v-row>
                    <v-row v-if="!loading.state" tag="div" align="center" no-gutters class="px-2 my-2">
                        <v-col class="d-flex pr-2" cols="10">
                            <v-row dense no-gutters tag="div">
                                <v-col dense tag="div" sm="12" class="d-flex justify-start">
                                </v-col>
                                <!-- <v-col dense tag="div" sm="12" class="my-1">
                                    <v-autocomplete
                                        hide-details
                                        item-text="text"
                                        item-value="value"
                                        :items="origins.selection"
                                        v-model="origins.value"
                                        dense
                                        label="Origin"
                                        outlined
                                        flat
                                    ></v-autocomplete>
                                </v-col> -->
                                <v-col dense tag="div" sm="12" class="my-1">
                                    <v-autocomplete hide-details item-text="text" item-value="value"
                                        :items="GET_CREDIT_MEMO_TYPES" v-model="change_type.new_type" dense
                                        label="Credit Memo Type" outlined flat auto-select-first @change="selectedCMType(); getWarehouses()"></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions class="d-flex justify-end">
                    <v-btn small :disabled="!change_type.new_type || loading.state" @click="submitNewCMType();">
                        Submit
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="after_sales_dialog.state" max-width="400" persistent>
            <v-card>
                <v-card-title class="pr-1 pb-2">
                    <v-col cols="8">
                        <label class="text-lg font-semibold">After Sales Details</label>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="2">
                        <v-btn text icon color="gray" class="float-right" @click="after_sales_dialog.state = false">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-card-title>
                <v-divider class="ma-0 mb-2" />
                <v-card-text>
                    <v-row class="d-flex flex-column align-center w-100 mx-auto px-4">
                        <v-col cols="12" class="mt-2" >
                          <v-btn class="w-100" @click="printRfDetails()">Repair Form</v-btn>
                        </v-col>
                        <v-col cols="12" class="mt-2">
                            <v-btn class="w-100" @click="printRrDetails()">Receiving Report</v-btn>
                        </v-col>
                        <v-col cols="12" class="mt-2">
                           <v-btn class="w-100" @click="printTechnicalReport()">Technical Report</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>

</template>

<script>
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
import BaseFileViewer from "../Base/BaseFilesViewer.vue";
import FileUploadComponent from "@/views/main/Shared/FileUploadComponent.vue";
import CreditMemoPrintComponentVue from "./CreditMemoPrintComponent.vue";
import BaseCameraCapture from "../../Services/Base/BaseCameraCapture.vue";
import PrintTechnicalReportPdfVue from '../../Services/PrintTechnicalReportPdf.vue';
import PrintRepairFormComponent from "../../Services/PrintRepairFormComponent.vue";
import PrintReceivingReportComponent from "../../Services/Views/PrintReceivingReportComponent.vue";
import CreditMemoChinaPrintComponentVue from './CreditMemoChinaPrintComponent.vue';
import ReportsCardsComponentVue from '@/views/main/layouts/Reports/ReportsCardsComponent.vue';
import { mapGetters } from "vuex";
import swal from "sweetalert2";
import Swal from 'sweetalert2';

export default {
    mixins: [
        SharedFunctionsComponentVue
    ],
    components: {
        CreditMemoPrintComponentVue,
        BaseFileViewer,
        FileUploadComponent,
        BaseCameraCapture,
        PrintTechnicalReportPdfVue,
        CreditMemoChinaPrintComponentVue,
        ReportsCardsComponentVue,
        PrintRepairFormComponent,
        PrintReceivingReportComponent,
    },
    props: [
        'status_text',
        'print',
        'origin',
        'refreshData',
        'isSoa',
        'soaSelectedCustomer',
        'soaSelectedType',
        'soaId',
        'balanceAmounts',
        'soaRemarks',
        'selected_date',
        'isWir',
        'wir_id',
        'warehouse_id',
        'tab_name',
        'selected_type',
        'cm_date_range',
        'fromReports',
    ],
    async mounted() {
        if(!this.fromReports){
            if(!this.isSoa && !this.isWir && this.origin != 2){
                this.getAllCreditMemos();
                this.checkAccess();
            }
            if(this.origin == 2){
                this.getAllCreditMemoChina();
                this.checkAccess();
            }
            // this.fromWir()
        }
        this.assignCreditMemoType()
        this.$store.dispatch('creditMemoClassificationDropdown')
        this.getCreditMemoTypeStatuses();
    },
    data() {
        return {
            rejection_dialog: false,
            rejection_remarks: "",
            reject_id: "",

            loading: {
                title: "Please wait",
                state: false,
                customer_selection: true,
                container_selection: true,
                customer_address_selection: true,
                warehouse_selection: true,
                item_selection: true,
            },

            pagination: {
                options: {},
            },

            soaPicker: false,
            datePicker: false,

            print_credit_memo: false,

            creditMemoType: {
                value: null,
                dialog: false,
                data: [],
            },

            origin_local: [
                { value: 1, text: "RETURN/DEFECTIVE ITEMS" },
                { value: 2, text: "QTY ADJUSTMENT" },
                { value: 3, text: "PRICE ADJUSTMENT" },
                { value: 4, text: "GENERAL ADJUSTMENT" },
                { value: 6, text: 'CASH DISCOUNT' },
                { value: 7, text: 'BIR 2307' },
                { value: 11, text: 'FINANCE CHARGE' },
                { value: 12, text: 'INCENTIVE' },
                { value: 5, text: 'DEFECTIVE ITEMS (SERVICE)' },
                { value: 10, text: 'CANCEL SOA BALANCE' },
            ],

            origin_china: [
                { value: 8, text: "IN-WARRANTY RF" },
                { value: 9, text: "INTERNATIONAL DELIVERY DISCREPANCY REPORT (IDDR)" },
            ],

            editedIndex: -1,
            editedCreditMemo: {
                id: null,
                type: null,
                origin: 0,
                container_id: null,
                credit_memo_num: null,
                warehouse_id: null,
                warehouse_name: null,
                customer_id: null,
                customer_name: null,
                billing_address_id: null,
                billing_address_text: null,
                billing_contact_person: null,
                billing_contact_number: null,
                credit_memo_date: null,
                credit_memo_date_long: null,
                total_amount: null,
                total_cost: null,
                remarks: null,
                customer_ref_no: null,
                packing_list_nums: null,
                soa_id: null,
                soa_month_year: null,
                soa_month: null,
                soa_year: null,
                status: null,
                printed: null,
                created_by_name: null,
                acct_approved_by_name: null,
                exec_approved_by_name: null,
                credit_memo_type:null,
                credit_memo_classification_id: 0,
            },

            editedCreditMemoItems: {
                datatable: {
                    status_id: -1,
                    search: "",
                    headers: [
                        { text: "ITEM MODEL", value: "item_model" },
                        { text: "ITEM NAME", value: "item_name" },
                        { text: "UOM", value: "item_uom" },
                        { text: "PLs#", value: "packing_list_nums" },
                        { text: "CM QTY", value: "quantity_cm" },
                        { text: "AMOUNT", align: "right", value: "amount" },
                        { text: "TOTAL", align: "right", value: "total_amount" },
                    ],
                },
                data: [],
                print_data: [],
            },

            customer: {
                selection: [],
                billing_address_list: [],
                value: null,
                text: null,
                search: "",
            },

            warehouse: {
                selection: [],
                value: null,
                text: null,
                search: "",
            },

            items: {
                selection: [],
                value: null,
                text: null,
                search: "",
            },

            soa: {
                selection: [],
                value: null,
            },

            origins: {
                selection: [
                    { text: "Local", value: 1 },
                    { text: "China", value: 2 },
                ],
                value: 1,
            },

            classifications: {
                selection: [],
                value: 0,
            },

            container: {
                selection: [],
                value: null,
            },

            creditMemoList: {
                datatable: {
                    total: 0,
                    search: "",
                    filters: {
                        soa_month: {
                            // values: this.$dayjs().format('YYYY-MM'),
                            values: null,
                            menu: false,
                        },
                        soa_month_to:{
                            values: null,
                            menu: false,
                        },
                        cm_type:''
                    },
                    headers_local: [
                        { text: "CM#", value: "credit_memo_num", align: "center" },
                        { text: "CM DATE", value: "credit_memo_date", align: "center" },
                        { text: "CM Type", value: "credit_memo_type_by_name", align: "center" },
                        { text: "CUSTOMER", value: "customer_company_name", align: "center" },
                        { text: "PL REFS#", value: "packing_list_nums", align: "center" },
                        { text: "DEALER REFS#", value: "customer_ref_no", align: "center" },
                        { text: "RF#", value: "rf_number", align: "center" },
                        { text: "SOA ID", value: "soa_id", align: "center" },
                        { text: "STATUS", value: "status", align: "center" },
                        { text: "PROCESSED BY", value: "created_by_name", align: "center" },
                        { text: "ACCT APPROVED BY", value: "acct_approved_by_name", align: "center" },
                        // { text: "EXEC APPROVED BY", value: "exec_approved_by_name", align: "center" },
                        { text: "SOA MONTH", align: "center", value: "soa_month_year" },
                        { text: "TOTAL CM (PHP)", align: "right", value: "total_amount" },
                        { text: "PRINTED", align: "center", value: "printed" },
                        { text: "ACTIONS", align: "center", value: "action" },
                    ],
                    headers_china: [
                        { text: "CM#", value: "credit_memo_num", align: "center" },
                        { text: "CM DATE", value: "credit_memo_date", align: "center" },
                        { text: "CM TYPE", value: "credit_memo_type_by_name", align: "center" },
                        { text: "RF#", value: "repair_form_number", align: "center" },
                        { text: "CUSTOMER", value: "customer_company_name", align: "center" },
                        { text: "STATUS", value: "status", align: "center" },
                        { text: "OLD CM#", value: "old_credit_memo_num", align: "center" },
                        { text: "PROCESSED BY", value: "created_by_name", align: "center" },
                        { text: "APPROVED BY", value: "acct_approved_by_name", align: "center" },
                        { text: "SOA MONTH", align: "center", value: "soa_month_year" },
                        { text: "TOTAL CM (PHP)", align: "right", value: "total_amount" },
                        { text: "TOTAL CM (USD)", align: "right", value: "total_cost" },
                        { text: "PRINTED", align: "center", value: "printed" },
                        { text: "ACTIONS", align: "center", value: "action" },
                    ],
                    loaded: false,
                },
                data: [],
            },

            dialog: false,
            errors: [],
            data: [],
            selected: [],

            userAccess: {
                view: false,
                create: false,
                edit: false,
                delete: false,
                cancel: false,
                approve_acctg: false,
                approve_exec: false,
                create_cm_soa: false,
                confirm_cm_soa: false,
                disapprove_cm_soa: false,
                delete_file: false,
            },

            creditMemoId: "",
            uploadedFiles: null,
            addEditDialogAction: "",
            repairFormId: null,
            containerDiscrepancyId: null,
            showWithoutSoa: 0,

            selected_id: [],
            status_id: -1,
            dataTable: [],
            btn_loader: false,
            convertBtn: false,
            showCheckbox: true,

            // status: {
            //     items_with_qty_and_price: [1,2,5,8,9],
            //     items_with_price_only: [3],
            //     generic: [3,4,6,7,10,11,12]
            // },
            status: {
                items_with_qty_and_price: [],
                items_with_price_only: [],
                generic: []
            },
            images:null,
            creditMemoTypeText:'',
            creditMemoTypeService:0,
            cmStatusText:'',
            service_cm:{
                credit_note_num:'',
                container_num:''
            },
            email_technical_report_dialog:false,
            filters:{
                date_from:'',
                date_to:''
            },
            menu:{
                date_from:'',
                date_to:'',
                credit_note_date:''
            },
            sc_repair_forms:[],
            parts_needed:[],
            isVisibleTd: true,
            email:{
                email_subject:'',
            },
            chinaDialog:false,
            cm_china:{
                start_date:'',
                end_date:'',
                menu_start:false,
                menu_end: false,
                confirm_date: null,
                confirm_by: null,
                table:{
                    headers:[
                        { text: "CM#", value: "credit_memo_num", align: "center" },
                        { text: "CM DATE", value: "credit_memo_date", align: "center" },
                        { text: "CUSTOMER", value: "company_name", align: "center" },
                        { text: "PL REFS#", value: "packing_list_nums", align: "center" },
                        { text: "DEALER REFS#", value: "customer_ref_no", align: "center" },
                        { text: "TOTAL CM (PHP)", value: "total_amount", align: "right" },
                        { text: "TOTAL CM (USD)", value: "total_cost", align: "right" },
                        { text: "ATTACHMENTS", value: "files_count", sortable: false, align: "center" },
                        { text: "ACTION", value: "action", sortable: false, align: "right" },
                    ],
                    items:[]
                },
                main_table:{
                    headers:[
                        { text: "CMC ID", value: "id", align: "center" },
                        { text: "CMC DATE", value: "created_at", align: "center" },
                        { text: "START DATE", value: "date_start", align: "center" },
                        { text: "END DATE", value: "date_end", align: "center" },
                        { text: "CONFIRM BY", value: "confirm_by", align: "start" },
                        { text: "CONFIRM DATE", value: "confirm_date", align: "center" },
                        { text: "APPROVED DATE", value: "approved_date", align: "center" },
                        { text: "CREDIT NOTE #", value: "credit_note_num", align: "center" },
                        { text: "CONTAINER #", value: "container_num", align: "center" },
                        { text: "REMARKS", value: "remarks", align: "right" },
                        { text: "TOTAL CM (PHP)", value: "total_amount", align: "right" },
                        { text: "TOTAL CM (USD)", value: "total_cost", align: "right" },
                        { text: "ACTION", value: "action", sortable: false, align: "center" },
                    ],
                    items:[],
                    loading:true,
                    search:''
                },
                remarks:'',
                credit_memo_date:'',
                credit_note_num:null,
                container_num:null,
                approved_date:null,
                approved_dollar:0,
                credit_note_date:null,
            },
            creditMemoLocalOptions:[],
            cmPageCountLocal:1,
            cm_items_array_local:[],
            creditMemoChinaOptions:[],
            cmPageCountChina:1,
            cm_items_array_china:[],
            creditMemoChinaPrintData:{},
            print_credit_memo_china:false,
            base64Images:[],
            remaining_row: 0,
            cm_china_rfs:[],
            loading_dialog:false,
            base64OutputCmChina:null,
            cards: [],
            totals: {},
            cmc_options:{},
            totalCreditMemoChina:0,
            editedCreditMemoChinaId:0,
            export_count:{
                current:0,
                total:0,
                text:'',
                progress:0,
                isIntermediate:false
            },
            change_type:{
                dialog: false,
                new_type: null
            },
            after_sales_dialog:{
                state: false,

            },
            print_repair_form: false,
            receivingReportId: null,
            print_rr_details: false,
        };
    },
    methods: {
        printTechnicalReport(){
            this.$forceUpdate();
            this.$nextTick(() => {
                if (this.repairFormId) {
                    this.$store.commit('GET_PRINT_PDF_ID',  this.repairFormId)
                }
            });
        },
        async printCM() {
            this.btn_loader = true;
            await swal
                .fire({
                    title: "",
                    text: "Are you sure you want to Print selected Credit Memo?",
                    icon: 'question',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    allowOutsideClick: false,
                    confirmButtonColor: "#397373",
                    cancelButtonColor: "grey",
                    confirmButtonText: "Confirm",
                    showCloseButton: true,
                })
                .then(async (result) => {
                    if (result.isConfirmed) {
                        let payload = {
                            selected_id: this.selected_id,
                        };
                        this.$store
                            .dispatch("getCMDetails", payload)
                            .then(async (response) => {
                                let data = this.GET_CM_DETAILS;
                                if (data != "") {
                                    for (const index in data.data) {
                                        await new Promise((resolve) => {
                                            let month = data.data[index].selected_month;
                                            if (month < 10) {
                                                month = "0" + month;
                                            }
                                            let id = data.data[index].id;
                                            this.print.credit_memo_num =
                                                data.data[index].credit_memo_num;
                                            this.print.soa_month_year =
                                                data.data[index].selected_year + "-" + month;
                                            this.print.credit_memo_date = this.$dayjs(
                                                data.data[index].credit_memo_date
                                            ).format("dddd, MMMM DD, YYYY");
                                            this.print.customer_name =
                                                data.data[index].customer_company_name;
                                            this.print.packing_list_nums =
                                                data.data[index].packing_list_nums;
                                            this.print.billing_address_text =
                                                data.data[index].billing_address_text;
                                            this.print.customer_ref_no =
                                                data.data[index].customer_ref_no;
                                            this.print.billing_contact_person =
                                                data.data[index].billing_contact_person;
                                            this.print.billing_contact_number =
                                                data.data[index].billing_contact_number;
                                            this.print.credit_memo_items = data.data[index].cm_items;
                                            this.print.remarks = data.data[index].remarks;
                                            this.print.total_amount = data.data[index].total_amount;
                                            this.print.created_by_name =
                                                data.data[index].created_by_name;
                                            this.print.acct_approved_by_name =
                                                data.data[index].acct_approved_by_name;
                                            this.print.exec_approved_by_name =
                                                data.data[index].exec_approved_by_name;

                                            this.$store
                                                .dispatch("creditMemoPrint", id)
                                                .then((response) => {
                                                    if (response) {
                                                        this.printDetails();
                                                        resolve();
                                                    }
                                                });
                                        });
                                    }
                                    swal.fire(
                                        "Selected Credit Memo Printing Success",
                                        "",
                                        "success"
                                    );
                                    this.dataTable = [];
                                    this.btn_loader = false;
                                }
                            });
                    }
                    this.btn_loader = false;
                });
        },

        printDetails() {
            let doc = new this.$jspdf("p", "mm", "letter");

            let ingcoPhLogo = new Image();
            ingcoPhLogo.src = "/images/ingcoph-logo.png";
            doc.addImage(ingcoPhLogo, "png", 4, 3, 40, 15);

            let creditMemoLogo = new Image();
            creditMemoLogo.src = "/images/credit-memo-logo.png";
            doc.addImage(creditMemoLogo, "png", 118, 6, 85, 8);

            doc.text(134, 15, "Control No: CM# " + this.print.credit_memo_num);

            doc.autoTable({
                html: "#headers_print",
                theme: "grid",
                margin: { top: 25, left: 4, right: 11 },
                columnStyles: {
                    0: {
                        cellWidth: 30,
                        halign: "left",
                        textColor: "#000000",
                        fontSize: 8,
                    },
                    1: {
                        cellWidth: 69,
                        halign: "left",
                        textColor: "#000000",
                        fontSize: 8,
                    },
                    2: {
                        cellWidth: 15,
                        halign: "left",
                        textColor: "#000000",
                        fontSize: 8,
                    },
                    3: {
                        cellWidth: 15,
                        halign: "left",
                        textColor: "#000000",
                        fontSize: 8,
                    },
                    4: {
                        cellWidth: 31,
                        halign: "left",
                        textColor: "#000000",
                        fontSize: 8,
                    },
                    5: {
                        cellWidth: 41,
                        halign: "left",
                        textColor: "#000000",
                        fontSize: 8,
                    },
                },
                bodyStyles: {
                    lineColor: "#000000",
                },
            });

            doc.autoTable({
                html: "#items_print",
                theme: "grid",
                margin: { left: 4, right: 11 },
                startY: doc.autoTable.previous.finalY,
                headStyles: {
                    halign: "center",
                    fillColor: "#ffffff",
                    textColor: "#000000",
                    lineColor: "#000000",
                    lineWidth: 0.1,
                    fontSize: 8,
                },
                columnStyles: {
                    0: {
                        cellWidth: 30,
                        halign: "center",
                        textColor: "#000000",
                        fontSize: 8,
                    },
                    1: {
                        cellWidth: 69,
                        halign: "center",
                        textColor: "#000000",
                        fontSize: 8,
                    },
                    2: {
                        cellWidth: 15,
                        halign: "center",
                        textColor: "#000000",
                        fontSize: 8,
                    },
                    3: {
                        cellWidth: 15,
                        halign: "center",
                        textColor: "#000000",
                        fontSize: 8,
                    },
                    4: {
                        cellWidth: 31,
                        halign: "right",
                        textColor: "#000000",
                        fontSize: 8,
                    },
                    5: {
                        cellWidth: 41,
                        halign: "right",
                        textColor: "#000000",
                        fontSize: 8,
                    },
                },
                bodyStyles: {
                    lineColor: "#000000",
                },
            });

            doc.autoTable({
                html: "#totals_print",
                theme: "grid",
                margin: { left: 4, right: 11 },
                startY: doc.autoTable.previous.finalY,
                columnStyles: {
                    0: {
                        cellWidth: 30,
                        halign: "left",
                        textColor: "#000000",
                        fontSize: 8,
                    },
                    1: {
                        cellWidth: 69,
                        halign: "left",
                        textColor: "#000000",
                        fontSize: 8,
                    },
                    2: {
                        cellWidth: 15,
                        halign: "left",
                        textColor: "#000000",
                        fontSize: 8,
                    },
                    3: {
                        cellWidth: 15,
                        halign: "left",
                        textColor: "#000000",
                        fontSize: 8,
                    },
                    4: {
                        cellWidth: 31,
                        halign: "right",
                        textColor: "#000000",
                        fontSize: 8,
                    },
                    5: {
                        cellWidth: 41,
                        halign: "right",
                        textColor: "#000000",
                        fontSize: 8,
                    },
                },
                bodyStyles: {
                    lineColor: "#000000",
                },
            });

            doc.autoTable({
                html: "#signatories_print",
                theme: "grid",
                margin: { left: 4, right: 11 },
                startY: doc.autoTable.previous.finalY,
                columnStyles: {
                    0: {
                        cellWidth: 30,
                        halign: "left",
                        textColor: "#000000",
                        fontSize: 8,
                    },
                    1: {
                        cellWidth: 69,
                        halign: "center",
                        textColor: "#000000",
                        fontSize: 8,
                    },
                    2: {
                        cellWidth: 15,
                        halign: "left",
                        textColor: "#000000",
                        fontSize: 8,
                    },
                    3: {
                        cellWidth: 15,
                        halign: "left",
                        textColor: "#000000",
                        fontSize: 8,
                    },
                    4: {
                        cellWidth: 31,
                        halign: "center",
                        textColor: "#000000",
                        fontSize: 8,
                    },
                    5: {
                        cellWidth: 41,
                        halign: "left",
                        textColor: "#000000",
                        fontSize: 8,
                    },
                },
                bodyStyles: {
                    lineColor: "#000000",
                },
            });

            let footerY = doc.autoTable.previous.finalY + 3;
            doc
                .text(4, footerY, "WHITE - ACCTG COPY")
                .setFontSize(8)
                .setFont(undefined, "italic");
            doc
                .text(43, footerY, "PINK - CUSTOMER COPY")
                .setFontSize(8)
                .setFont(undefined, "italic");

            doc.autoPrint();
            window.open(doc.output("bloburl"), "_blank");
        },
        async updateCMStatus(newStatus) {
            this.btn_loader = true;
            let ids = this.selected_id;
            let length = this.selected_id.length - 1;

            await swal
                .fire({
                    title: "",
                    text: "Are you sure you want to Approve selected Credit Memo?",
                    icon: 'question',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    allowOutsideClick: false,
                    confirmButtonColor: "#397373",
                    cancelButtonColor: "grey",
                    confirmButtonText: "Confirm",
                    showCloseButton: true,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        ids.forEach(async (id, index) => {
                            let payload = {
                                id: id,
                                params: {
                                    new_status: newStatus,
                                },
                            };

                            await this.$store
                                .dispatch("approveCreditMemo", payload)
                                .then((response) => {
                                    if (response.data.status == "success" && length == index) {
                                        swal.fire('', response.data.error ? response.data.error : response.data.msg, response.data.status)
                                        this.btn_loader = false;
                                        this.convertBtn = false;
                                        this.getAllCreditMemos();
                                    }
                                })
                                .catch((error) => {
                                    swal.fire("An Error Occured", error, "error");
                                    this.btn_loader = false;
                                });
                        });
                    } else {
                        this.btn_loader = false;
                    }
                });
        },
        getSelectedID(val) {
            this.selected_id = val.map(function (e) {
                return e.id;
            });

            if (this.status_id == -1) {
                swal.fire(
                    "Filter status first",
                    "required for multiple conversion",
                    "warning"
                );
                const index = this.dataTable.indexOf(this.dataTable);
                this.dataTable.splice(index, 1);
                this.convertBtn = false;
                this.showCheckbox = false;
                return false;
            } else {
                if (this.dataTable.length > 0) {
                    this.convertBtn = true;
                } else {
                    this.convertBtn = false;
                }
            }
        },
        rejectCreditMemo() {
            if (this.rejection_remarks == "" || this.rejection_remarks.length < 5) {
                swal.fire(
                    "Remarks cannot be blank",
                    "put a valid remarks more than 5 letters",
                    "error"
                );
                return false;
            } else {
                let payload = {
                    id: this.reject_id,
                    remarks: this.rejection_remarks,
                };

                this.$store.dispatch("rejectCreditMemo", payload).then((response) => {
                    if (response.data.status == "success") {
                        this.rejection_dialog = false;
                        this.rejection_remarks = "";
                        this.approveCreditMemo(0);
                    } else {
                        swal.fire("Error Notification", response.data.msg, "error");
                    }
                });
            }
        },
        showRejectDialog() {
            this.rejection_dialog = true;
            this.dialog = false;
        },
        closeRejectDialog() {
            this.rejection_dialog = false;
            this.dialog = true;
        },
        checkAccess() {
            let val = this.USER_ACCESS;
            this.userAccess.view = false;
            this.userAccess.create = false;
            this.userAccess.edit = false;
            this.userAccess.delete = false;
            this.userAccess.cancel = false;
            this.userAccess.approve_acctg = false;
            this.userAccess.approve_exec = false;
            this.userAccess.create_cm_soa = false;
            this.userAccess.confirm_cm_soa = false;
            this.userAccess.disapprove_cm_soa = false;
            this.userAccess.delete_file = false;
            if (val != "fail") {
                val.forEach((e) => {
                    if (e.actions_code == "view") {
                        this.userAccess.view = true;
                    }
                    if (e.actions_code == "create") {
                        this.userAccess.create = true;
                    }
                    if (e.actions_code == "edit") {
                        this.userAccess.edit = true;
                    }
                    if (e.actions_code == "delete") {
                        this.userAccess.delete = true;
                    }
                    if (e.actions_code == "cancel") {
                        this.userAccess.cancel = true;
                    }
                    if (e.actions_code == "approve_acctg") {
                        this.userAccess.approve_acctg = true;
                    }
                    if (e.actions_code == "approve_exec") {
                        this.userAccess.approve_exec = true;
                    }
                    if (e.actions_code == "create_cm_soa") {
                        this.userAccess.create_cm_soa = true;
                    }
                    if (e.actions_code == "confirm_cm_soa") {
                        this.userAccess.confirm_cm_soa = true;
                    }
                    if (e.actions_code == "disapprove_cm_soa") {
                        this.userAccess.disapprove_cm_soa = true;
                    }
                    if (e.actions_code == "delete_file") {
                        this.userAccess.delete_file = true;
                    }
                });
            }
        },
        redirect(url) {
            window.location.assign(url);
        },
        async onSelectCustomer() {
            if (this.customer.value !== null) {
                this.editedCreditMemo.customer_id = this.customer.value;

                this.customer.billing_address_list = [];

                this.getCustomerBillingAddresses(this.editedCreditMemo.customer_id);
            }
        },
        onSelectBillingAddress() {
            if(this.customer.billing_address_list.length > 0){
                let index = this.editedCreditMemo.billing_address_id;
                this.editedCreditMemo.billing_address_text =
                    this.customer.billing_address_list[index].text;
                this.editedCreditMemo.billing_contact_person =
                    this.customer.billing_address_list[index].contact_person;
                this.editedCreditMemo.billing_contact_number =
                this.customer.billing_address_list[index].contact_number;
            }
        },
        onSelectItem(item_id, index) {
            let item_details = this.items.selection.filter((q) => {
                return q.value == item_id;
            });

            this.editedCreditMemoItems.data[index].item_name = item_details[0].name;
            this.editedCreditMemoItems.data[index].item_uom = item_details[0].uom;
        },
        onSelectWarehouse() {
            if (this.warehouse.value !== null) {
                this.editedCreditMemo.warehouse_id = this.warehouse.value;
            }
        },
        async getAllCreditMemos(for_export = 0) {
            this.creditMemoList.datatable.loaded = false;
            this.creditMemoList.data = [];

            let payload = {
                customer_filter: this.customer.search,
                status_text: this.status_text,
                status_id: this.status_id,
                soa_month_filter:this.creditMemoList.datatable.filters.soa_month.values,
                search: this.creditMemoList.datatable.search,
                pagination: this.pagination,
                origin: this.origin,
                credit_memo_type:this.creditMemoList.datatable.filters.cm_type,
                selected_type:this.selected_type,
                cm_date_range:this.cm_date_range,
                for_export:for_export,
                soa_month_filter_to: this.creditMemoList.datatable.filters.soa_month_to.values,
                show_without_soa: this.showWithoutSoa,
            };
            await this.$store
                .dispatch("getAllCM", payload)
                .then(async (response) => {
                    this.creditMemoList.data = !!for_export ? this.GET_ALL_CM.credit_memos : this.GET_ALL_CM.credit_memos.data;
                    this.creditMemoList.credit_memo_item_details = !!for_export ? this.GET_ALL_CM.credit_memo_item_details : null
                    this.creditMemoList.datatable.total = this.GET_ALL_CM.credit_memos.total;
                    this.creditMemoList.datatable.loaded = true;
                    this.dataTable = [];
                    this.showCheckbox = true;

                    await this.$store.dispatch('getCreditMemoTypesCardTotal', payload)

                    if(!!for_export){
                        await this.exportCreditMemoToExcel();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        viewCreditMemo(item) {
            this.creditMemoTypeService = item.credit_memo_types.service
            this.getWarehouses()
            this.reject_id = item.id;
            this.creditMemoId = item.id;
            this.editedIndex = 1;

            this.$store
                .dispatch("showCM", item.id)
                .then((response) => {
                    let credit_memo = this.GET_SHOW_CM.credit_memo;
                    let credit_memo_items = this.GET_SHOW_CM.credit_memo_items;

                    this.repairFormId = credit_memo.sc_repair_form_id;
                    this.receivingReportId = this.GET_SHOW_CM.receiving_report_id;
                    this.containerDiscrepancyId = credit_memo.container_discrepancy_id;

                    this.editedCreditMemo = {
                        ...Object.assign(
                            {},
                            {
                                id: credit_memo.id,
                                type: credit_memo.credit_memo_type,
                                origin: credit_memo.origins,
                                container_id: credit_memo.container_id,
                                credit_memo_num: credit_memo.credit_memo_num,
                                warehouse_id: credit_memo.warehouse_id,
                                warehouse_name: credit_memo.warehouse_name,
                                customer_id: credit_memo.customer_id,
                                customer_name: credit_memo.customer_company_name,
                                billing_address_id: credit_memo.billing_address_id,
                                billing_address_text: credit_memo.billing_address_text,
                                billing_contact_person: credit_memo.billing_contact_person,
                                billing_contact_number: credit_memo.billing_contact_number,
                                credit_memo_date: credit_memo.credit_memo_date,
                                credit_memo_date_long: this.$dayjs(
                                    credit_memo.credit_memo_date
                                ).format("dddd, MMMM DD, YYYY"),
                                total_amount: this.getTwoDecimals(credit_memo.total_amount),
                                total_cost: this.getTwoDecimals(credit_memo.total_cost),
                                remarks: credit_memo.remarks,
                                rejection_remarks: credit_memo.rejection_remarks,
                                customer_ref_no: credit_memo.customer_ref_no,
                                packing_list_nums: credit_memo.packing_list_nums,
                                soa_id: credit_memo.soa_id,
                                soa_month_year: credit_memo.soa_month_year,
                                soa_month: credit_memo.selected_month,
                                soa_year: credit_memo.selected_year,
                                status: credit_memo.status,
                                printed: credit_memo.printed,
                                created_by_name: credit_memo.created_by_name,
                                acct_approved_by_name: credit_memo.acct_approved_by_name,
                                exec_approved_by_name: credit_memo.exec_approved_by_name,
                                credit_memo_type: credit_memo.credit_memo_type_by_name,
                            }
                        ),
                    };
                    credit_memo_items.forEach((d, index) => {
                        this.editedCreditMemoItems.data.push(
                            Object.assign(
                                {},
                                {
                                    id: d.id,
                                    credit_memo_id: d.credit_memo_id,
                                    item_id: d.item_id,
                                    item_model: d.item_model,
                                    item_name: d.item_name,
                                    item_uom: d.item_uom,
                                    quantity_cm: d.quantity_cm,
                                    item_amount: this.thousandSeprator(d.item_amount),
                                    item_cost: this.thousandSeprator(d.item_cost),
                                    total_amount: this.thousandSeprator(d.total_amount),
                                    total_cost: this.thousandSeprator(d.total_cost),
                                    remarks: d.remarks,
                                }
                            )
                        );
                    });

                    this.origins.value = credit_memo.origin;
                    this.customer.value = credit_memo.customer_id;
                    this.warehouse.value = credit_memo.warehouse_id;
                    this.classifications.value = credit_memo.credit_memo_classification_id
                    this.cmStatusText = credit_memo.status_text
                    this.service_cm.credit_note_num = credit_memo.credit_note_num
                    this.service_cm.container_num = credit_memo.container_num
                    this.getCustomerBillingAddresses(credit_memo.customer_id);
                    this.getItems();

                    this.dialog = true;
                })
                .catch((error) => {
                    swal.fire("An Error Occured", error, "error");
                });
        },
        editCreditMemo(item) {
            this.creditMemoTypeService = item.credit_memo_types.service
            this.getWarehouses()
            this.creditMemoId = item.id;
            this.addEditDialogAction = "edit";
            this.editedIndex = 0;

            this.$store
                .dispatch("showCM", item.id)
                .then((response) => {
                    let credit_memo = this.GET_SHOW_CM.credit_memo;
                    let credit_memo_items = this.GET_SHOW_CM.credit_memo_items;

                    this.repairFormId = credit_memo.sc_repair_form_id;
                    this.receivingReportId = this.GET_SHOW_CM.receiving_report_id;

                    this.editedCreditMemo = {
                        ...Object.assign(
                            {},
                            {
                                id: credit_memo.id,
                                type: credit_memo.credit_memo_type,
                                origin: credit_memo.origin,
                                container_id: credit_memo.container_id,
                                credit_memo_num: credit_memo.credit_memo_num,
                                warehouse_id: credit_memo.warehouse_id,
                                warehouse_name: credit_memo.warehouse_name,
                                customer_id: credit_memo.customer_id,
                                customer_name: credit_memo.customer_company_name,
                                billing_address_id: credit_memo.billing_address_id,
                                billing_address_text: credit_memo.billing_address_text,
                                billing_contact_person: credit_memo.billing_contact_person,
                                billing_contact_number: credit_memo.billing_contact_number,
                                credit_memo_date: credit_memo.credit_memo_date,
                                credit_memo_date_long: this.$dayjs(
                                    credit_memo.credit_memo_date
                                ).format("dddd, MMMM DD, YYYY"),
                                total_amount: credit_memo.total_amount,
                                remarks: credit_memo.remarks,
                                rejection_remarks: credit_memo.rejection_remarks,
                                customer_ref_no: credit_memo.customer_ref_no,
                                packing_list_nums: credit_memo.packing_list_nums,
                                soa_id: credit_memo.soa_id,
                                soa_month_year: credit_memo.soa_month_year,
                                soa_month: credit_memo.selected_month,
                                soa_year: credit_memo.selected_year,
                                status: credit_memo.status,
                                printed: credit_memo.printed,
                                created_by_name: credit_memo.created_by_name,
                                acct_approved_by_name: credit_memo.acct_approved_by_name,
                                exec_approved_by_name: credit_memo.exec_approved_by_name,
                                credit_memo_type: credit_memo.credit_memo_type_by_name,
                            }
                        ),
                    };

                    credit_memo_items.forEach((d, index) => {
                        this.editedCreditMemoItems.data.push(
                            Object.assign(
                                {},
                                {
                                    id: d.id,
                                    credit_memo_id: d.credit_memo_id,
                                    item_id: d.item_id,
                                    item_model: d.item_model,
                                    item_name: d.item_name,
                                    item_uom: d.item_uom,
                                    quantity_cm: d.quantity_cm,
                                    item_amount: this.thousandSeprator(d.item_amount),
                                    item_cost: this.thousandSeprator(d.item_cost),
                                    total_amount: this.thousandSeprator(d.total_amount),
                                    total_cost: this.thousandSeprator(d.total_cost),
                                    remarks: d.remarks,
                                }
                            )
                        );
                    });

                    this.origins.value = credit_memo.origin
                    this.customer.value = credit_memo.customer_id;
                    this.warehouse.value = credit_memo.warehouse_id;
                    this.classifications.value = credit_memo.credit_memo_classification_id
                    this.getCustomerBillingAddresses(credit_memo.customer_id);
                    this.getItems();

                    this.dialog = true;
                })
                .catch((error) => {
                    console.log(error)
                    swal.fire("An Error Occured", error, "error");
                });
        },
        saveNewCreditMemo() {
            this.loading.state = true;
            if (this.editedCreditMemoItems.data.length == 0) {
                swal.fire("", "No Items Found in Credit Memo", "warning");
                this.loading.state = false;
            } else if (this.status.items_with_qty_and_price.includes(this.editedCreditMemo.type) && this.editedCreditMemo.warehouse_id === null) {
                swal.fire("", "Please select a warehouse", "warning");
                this.loading.state = false;
            } else if (this.uploadedFiles == null && this.images == null) {
                swal
                    .fire({
                        title: "Please Upload Images or Files",
                        icon: "warning",
                    })
                    .then((confirm) => {
                        this.loading.state = false;
                    });
            } else if (this.origins.value == 2 && this.container.value == null) {
                swal
                    .fire({
                        title: "Please Select Container if Origin is International",
                        icon: "warning",
                    })
                    .then((confirm) => {
                        this.loading.state = false;
                    });
            } else if (this.classifications.value == 0) {
                swal
                    .fire({
                        title: "Please Select a Classification!",
                        icon: "warning",
                    })
                    .then((confirm) => {
                        this.loading.state = false;
                    });
            } else {
                this.editedCreditMemo.origin = this.origins.value;
                this.editedCreditMemo.container_id = this.container.value;
                this.editedCreditMemo.credit_memo_classification_id = this.classifications.value

                const data = new FormData();

                const config = {
                    headers: {
                        "content-type": "multipart/form-data",
                    },
                };

                if (!!this.uploadedFiles && this.uploadedFiles.attachments.length > 0) {
                    let files = this.uploadedFiles.attachments;

                    for (let i = 0; i < _.compact(files).length; i++) {
                        data.append("files[]", files[i]);
                    }
                }

                if(!!this.images && this.images.length > 0) {
                    for(let i=0;i < _.compact(this.images).length;i++) {
                        data.append('images[]', _.compact(this.images)[i]);
                    }
                }

                data.append("credit_memo", JSON.stringify(this.editedCreditMemo));

                for (
                    let i = 0;
                    i < _.compact(this.editedCreditMemoItems.data).length;
                    i++
                ) {
                    data.append(
                        "credit_memo_items[]",
                        JSON.stringify(this.editedCreditMemoItems.data[i])
                    );
                }
                let payload = {
                    params: data,

                    config: config,
                };
                if(this.isSoa){
                    data.append('fromSoa',true)
                    data.append('soa_id',this.soaId)
                    data.append('selected_date_from_soa',this.selected_date)
                }
                if(this.isWir){
                    data.append('wir_id',this.wir_id)
                }

                this.$store.dispatch("postCM", payload).then((response) => {
                    if(this.isSoa){
                        this.$store.commit('custPaymentDetailSuccessTrue');
                    }
                    if (response.data.error) {
                        swal
                            .fire("", response.data.error, response.data.status)
                            .then((confirm) => {
                                if(this.isSoa){
                                    this.$store.commit('custPaymentDetailSuccessFalse');
                                }
                                this.loading.state = false;
                            });
                    } else {
                        swal
                            .fire("", response.data.msg, response.data.status)
                            .then((confirm) => {
                                if(this.isSoa){
                                    this.$store.commit('custPaymentDetailSuccessFalse');
                                }
                                if(this.isWir){
                                    this.$emit('closeDialog')
                                }
                                this.loading.state = false;
                            });
                        this.dialog = false;
                        this.resetEditedCreditMemo();
                        this.clearFileUpload();
                        this.closeDialog();
                        this.clearCameraCapture();
                        if(!this.isSoa && !this.isWir && origin != 2 && origin != 2){
                            this.getAllCreditMemos();
                        }


                    }
                });
            }
        },
        updateCreditMemo(id) {
            let status = this.GET_CREDIT_MEMO_TYPES.find(e => e.value == this.editedCreditMemo.type).status

            if (status == 1) {
                let hasWarehouse = this.GET_ALL_WAREHOUSES.find(e => e.value == this.editedCreditMemo.warehouse_id);
                if (!hasWarehouse) {
                    swal.fire('', '-You need to select warehouse.', 'info');
                    return;
                }
            }
            this.loading.state = true;
            if (this.editedCreditMemoItems.data.length == 0) {
                swal.fire("", "No Items Found in Credit Memo", warning);
                this.loading.state = false;
            } else if (this.status.items_with_qty_and_price.includes(this.editedCreditMemo.type) && this.editedCreditMemo.warehouse_id === null) {
                swal.fire("", "Please select a warehouse", "warning");
                this.loading.state = false;
            } else {
                this.editedCreditMemo.origin = this.origins.value;
                this.editedCreditMemo.container_id = this.container.value;
                this.editedCreditMemo.credit_memo_classification_id = this.classifications.value

                const data = new FormData();

                const config = {
                    headers: {
                        "content-type": "multipart/form-data",
                    },
                };

                if (!!this.uploadedFiles && this.uploadedFiles.attachments.length > 0) {
                    let files = this.uploadedFiles.attachments;

                    for (let i = 0; i < _.compact(files).length; i++) {
                        data.append("files[]", files[i]);
                    }
                }

                if(!!this.images && this.images.length > 0) {
                    for(let i=0;i < _.compact(this.images).length;i++) {
                        data.append('images[]', _.compact(this.images)[i]);
                    }
                }

                data.append("id", JSON.stringify(id));
                data.append("credit_memo", JSON.stringify(this.editedCreditMemo));
                data.append('repairFormId',this.repairFormId);

                for (
                    let i = 0;
                    i < _.compact(this.editedCreditMemoItems.data).length;
                    i++
                ) {
                    data.append(
                        "credit_memo_items[]",
                        JSON.stringify(this.editedCreditMemoItems.data[i])
                    );
                }
                let payload = {
                    params: data,
                    config: config,
                };

                this.$store.dispatch("updateCM", payload).then((response) => {
                    if (response.data.error) {
                        swal.fire("", response.data.error, response.data.status);
                        this.loading.state = false;
                    } else {
                        swal.fire("", response.data.msg, response.data.status);
                        this.dialog = false;
                        this.loading.state = false;
                        this.resetEditedCreditMemo();
                        this.getAllCreditMemos();
                        this.clearFileUpload();
                        this.clearCameraCapture();
                        this.closeDialog();
                        this.repairFormId = null;
                    }
                });
            }
        },
        deleteCreditMemo(id) {
            this.loading.state = true;
            swal
                .fire({
                    title: "",
                    text: "Are you sure you want to Delete?",
                    icon: 'question',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    allowOutsideClick: false,
                    confirmButtonColor: "#397373",
                    cancelButtonColor: "grey",
                    confirmButtonText: "Confirm",
                    showCloseButton: true,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.$store
                            .dispatch("deleteCM", id)
                            .then((response) => {
                                if (response.data.status == "success") {
                                    this.getAllCreditMemos();
                                }
                                swal.fire("", response.data.msg, response.data.status);
                                this.loading.state = false;
                            })
                            .catch((error) => {
                                swal.fire("An Error Occured", error, "error");
                                this.loading.state = false;
                            });
                    } else {
                        this.loading.state = false;
                    }
                });
        },
        cancelCreditMemo(id) {
            this.loading.state = true;

            swal.fire({
                title: "",
                text: "Are you sure you want to Cancel?",
                icon: 'question',
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                allowOutsideClick: false,
                confirmButtonColor: "#397373",
                cancelButtonColor: "grey",
                confirmButtonText: "Confirm",
                showCloseButton: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch("cancelCM", id).then((response) => {
                        if (response.data.status == "success") {
                            this.getAllCreditMemos()
                        }
                        swal.fire("", response.data.msg, response.data.status)
                        this.loading.state = false
                    }).catch((error) => {
                        swal.fire("An Error Occured", error, "error")
                        this.loading.state = false
                    })
                } else {
                    this.loading.state = false
                }
            });
        },
        approveCreditMemo(new_status) {
            if(this.origin == 2){
                new_status = 3 // Approved
                if(this.service_cm.credit_note_num.length < 1 && this.service_cm.container_num < 1){
                    swal.fire('Unprocessable','Please input credit note # and container #','warning')
                    return false
                }
            }
            this.loading.state = true;
            let payload = {
                id: this.editedCreditMemo.id,
                params: {
                    new_status: new_status,
                    credit_note_num: this.service_cm.credit_note_num,
                    container_num:this.service_cm.container_num
                },
            };
            this.$store
                .dispatch("approveCreditMemo", payload)
                .then((response) => {
                    if (response.data.status == "success") {
                        this.getAllCreditMemos();
                    }
                    swal.fire("", response.data.msg, response.data.status);
                    this.resetEditedCreditMemo();
                    this.dialog = false;
                    this.loading.state = false;
                })
                .catch((error) => {
                    swal.fire("An Error Occured", error, "error");
                    this.loading.state = false;
                });
        },
        printCreditMemo() {
            this.print_credit_memo = true;
        },
        addLine() {
            this.editedCreditMemoItems.data.push(
                Object.assign(
                    {},
                    {
                        id: null,
                        credit_memo_id: null,
                        item_id: null,
                        item_model: null,
                        item_name: null,
                        item_item_uom: null,
                        quantity_cm: null,
                        item_amount: null,
                        item_cost: null,
                        total_amount: !!this.balanceAmounts ? this.balanceAmounts : null,
                        total_cost: !!this.balanceAmounts ? this.balanceAmounts : null,
                        remarks: !!this.soaRemarks ? this.soaRemarks : null
                    }
                )
            );
        },
        removeLine(index = null) {
            if (index === null) {
                this.editedCreditMemoItems.data.pop();
            } else {
                this.editedCreditMemoItems.data.splice(index, 1);
            }
        },
        formatDate(date_field, date_string) {
            if (date_field == "credit_memo_date") {
                this.editedCreditMemo.credit_memo_date_long = this.$dayjs(
                    date_string
                ).format("dddd, MMMM DD, YYYY");
                this.datePicker = false;
            }
            if (date_field == "soa_month_year") {
                this.editedCreditMemo.soa_month_year =
                    this.$dayjs(date_string).format("YYYY-MM");
                this.editedCreditMemo.soa_month = this.$dayjs(date_string).format("M");
                this.editedCreditMemo.soa_year = this.$dayjs(date_string).format("YYYY");
                this.soaPicker = false;
            }
        },
        formatNumber(number, index, field) {
            this.editedCreditMemoItems.data[index][field] =
                this.thousandSeprator(number);

            this.computeLineTotal(index);
        },
        computeLineTotal(index) {
            let has_quantity =
                this.editedCreditMemoItems.data[index].quantity_cm != null &&
                this.editedCreditMemoItems.data[index].quantity_cm != "";
            let has_item_amount =
                this.editedCreditMemoItems.data[index].item_amount != null &&
                this.editedCreditMemoItems.data[index].item_amount != "";
            let has_item_cost =
                this.editedCreditMemoItems.data[index].item_cost != null &&
                this.editedCreditMemoItems.data[index].item_cost != "";

            if (this.status.items_with_qty_and_price.includes(this.editedCreditMemo.type)) {
                if (has_quantity && has_item_amount) {
                    this.editedCreditMemoItems.data[index].total_amount =
                        this.thousandSeprator(this.editedCreditMemoItems.data[index].quantity_cm * parseFloat(this.editedCreditMemoItems.data[index].item_amount.replace(/,/g, "")));
                }

                if (has_quantity && has_item_cost) {
                    this.editedCreditMemoItems.data[index].total_cost =
                        this.thousandSeprator(this.editedCreditMemoItems.data[index].quantity_cm * parseFloat(this.editedCreditMemoItems.data[index].item_cost.replace(/,/g, "")));
                }
            } else if (this.status.items_with_price_only.includes(this.editedCreditMemo.type)) {
                this.editedCreditMemoItems.data[index].total_amount =
                    this.thousandSeprator(parseFloat(this.editedCreditMemoItems.data[index].item_amount.replace(/,/g, "")));
            } else if (this.status.generic.includes(this.editedCreditMemo.type)) {
            }
        },
        resetEditedCreditMemo() {
            this.creditMemoId = "";
            this.editedCreditMemo = {
                ...Object.assign(
                    {},
                    {
                        id: null,
                        type: null,
                        origin: 0,
                        container_id: null,
                        credit_memo_num: null,
                        warehouse_id: null,
                        warehouse_name: null,
                        customer_id: null,
                        customer_name: null,
                        billing_address_id: null,
                        billing_address_text: null,
                        billing_contact_person: null,
                        billing_contact_number: null,
                        credit_memo_date: null,
                        credit_memo_date_long: null,
                        total_amount: null,
                        remarks: null,
                        customer_ref_no: null,
                        packing_list_nums: null,
                        soa_id: null,
                        soa_month_year: null,
                        soa_month: null,
                        soa_year: null,
                        status: null,
                        printed: null,
                        created_by_name: null,
                        acct_approved_by_name: null,
                        exec_approved_by_name: null,
                        credit_memo_classification_id: 0,
                    }
                ),
            };

            this.editedCreditMemoItems = {
                ...Object.assign({}, {
                    datatable: {
                        search: "",
                    },
                    data: [],
                }),
            };
            this.service_cm.container_num = ''
            this.service_cm.credit_note_num = ''
            this.customer.value = null;
            this.warehouse.value = null;
            this.classifications.value = 0;
            this.images = null
            this.repairFormId = null
        },
        // getCustomers(){
        //     this.$store.dispatch('getAllCustomers').then((response)=>{ this.customer.selection = this.GET_CUSTOMERS_LIST_SELECTION })
        // },
        getWarehouses(){
            // let payload = {
            //     no_virtual: true
            // }
            this.$store.dispatch('getAllWarehouses', {
                service: this.creditMemoTypeService,
            })
        },
        getClassifications() {
            let payload = {
                origin: this.origin,
            }

            this.$store.dispatch('creditMemoClassificationDropdown', payload);
        },
        getItems() {
            let payload = {
                credit_memo: true,
                pt: true,
                ht: true,
                sp: true,
                url: 'getItemsCm',
                prefix: 'dropdown',
                mutation: 'ITEMS',
            };
            this.$store.dispatch("dropdownsApiGet", payload).then((response) => {
                this.items.selection = this.GET_ITEMS;
            });
        },
        getSoas() {
            this.$store
                .dispatch("getCustomerSoas", this.editedCreditMemo.customer_id)
                .then((response) => {
                    this.soa.selection = this.GET_CUSTOMER_SOAS;
                });
        },
        getContainers() {
            this.$store
                .dispatch("getAllContainers", {
                    origin: this.origins.value,
                })
                .then((response) => {
                    this.container.selection = this.GET_ALL_CONTAINERS;
                });
        },
        getCustomerBillingAddresses(customer_id) {
            let payload = {
                id: customer_id,
                params: {
                    type: "billing",
                },
            };
            this.$store.dispatch("getCustomerAddress", payload).then((response) => {
                let data = this.GET_CUSTOMER_ADDRESSES;

                this.customer.billing_address_list = data;

                if (this.editedIndex < 0) {
                    this.editedCreditMemo.billing_address_id = 0;
                    this.editedCreditMemo.billing_address_text = data[0].text;
                    this.editedCreditMemo.billing_contact_person = data[0].contact_person;
                    this.editedCreditMemo.billing_contact_number = data[0].contact_number;

                    this.getSoas();
                }
            });
        },
        closeDialog() {
            if(this.chinaDialog && this.addEditDialogAction == 'new'){
                this.addEditDialogAction = 'new'
            }
            else if(this.chinaDialog && this.addEditDialogAction == 'edit'){
                this.addEditDialogAction = 'edit'
            }
            else{
                this.addEditDialogAction = "";
            }
            this.resetEditedCreditMemo();
            this.dialog = false;
        },
        resetCreditMemoType() {
            this.creditMemoType.value = null;
        },
        closeCreditMemoTypeDialog() {
            this.editedCreditMemo.type = this.creditMemoType.value;
            this.resetCreditMemoType();
            this.getItems();
            this.getWarehouses()
            this.creditMemoType.dialog = false;
        },
        uploadedData(data) {
            if (!data) {
                this.uploadedFiles = null;
            }
            this.uploadedFiles = data;
        },
        clearFileUpload(confirm) {
            if (confirm) {
                this.uploadedFiles = null;
            }
            this.uploadedFiles = null;
            this.$refs.fileUpload.reset();
        },
        showNewCreditMemoDialog() {
            this.addEditDialogAction = "new";
            this.origins.value = this.origin
            this.creditMemoType.dialog = true;
            this.$store.dispatch('getCreditMemoTypes',{origin:this.origin})
            this.getCreditMemoTypeStatuses();
        },
        selectOrigin() {
            if (this.origins.value == 2) {
                this.getContainers();
            }
        },
        async assignCreditMemoType() {
            this.getWarehouses();
            this.creditMemoType.data = []
            if (this.origin == 1) {
                this.origin_local.forEach(e => {
                    this.creditMemoType.data.push(e)
                })
                if(this.isSoa || this.isWir){
                    // this.creditMemoType.value = this.soaSelectedType // remove static type
                    this.customer.value = this.soaSelectedCustomer
                    this.warehouse.value = !!this.warehouse_id ? this.warehouse_id : 0
                    this.$store.dispatch('getCreditMemoTypes',{origin:1})
                    this.onSelectCustomer();
                    this.onSelectBillingAddress();
                    this.onSelectWarehouse();
                    if(this.isWir && !!this.wir_id){
                        this.$store.dispatch('warehouseReturnGet',{
                            wir_id: this.wir_id,
                            customer_id: this.soaSelectedCustomer,
                            url:'get-reference-pl'
                        }).then(response=>{
                            let data = [];
                            response.data.forEach(e=>{
                                e.forEach(x=>{
                                    data.push(x.packing_list_num);
                                })
                            })
                            data = _.uniq(data)
                            let pl_string = data.join(', ')
                            this.editedCreditMemo.packing_list_nums = "PL# "+ pl_string
                        })
                    }
                }
            } else if (this.origin == 2) {
                this.origin_china.forEach(e => {
                    this.creditMemoType.data.push(e)
                })
            }
        },
        triggerFunctionsInMounted(){
            if(!this.isSoa && !this.isWir && this.origin != 2){
                this.getAllCreditMemos();
                this.checkAccess();
            }
                this.assignCreditMemoType()
        },
        selectedCMType(){
            if(this.isSoa){
                this.soaRemarks = this.creditMemoType.value == 10 ? `Cancel out balance of SOA # ${this.soaId}` : null
                this.editedCreditMemo.remarks = this.soaRemarks
            }

            this.GET_CREDIT_MEMO_TYPES.filter(e=>{
                if(e.value == this.creditMemoType.value){
                    this.creditMemoTypeText = e.name
                    this.creditMemoTypeService = e.service
                }
            })

            if (this.change_type.new_type) {
                this.GET_CREDIT_MEMO_TYPES.filter(e=>{
                    if(e.value == this.change_type.new_type){
                        this.creditMemoTypeService = e.service
                    }
                })
            }
        },
        // fromWir(){
        //     if(localStorage.getItem('warehouse_return') != null){
        //         this.creditMemoType.value = 4
        //         this.addEditDialogAction = "new";
        //         this.origins.value = this.origin
        //         this.creditMemoType.dialog = true;
        //         let warehouse_item_return = JSON.parse(localStorage.getItem('warehouse_return'))
        //         this.customer.value = warehouse_item_return.customer_id
        //         this.editedCreditMemo.billing_address_id = 0
        //     }
        // },
        getImages(images) {
            this.images = images;
        },
        clearCameraCapture() {
            this.$refs.cameraCapture.reset();
            this.images = null;
        },
        showEmailTechnicalReportDialog(){
            this.email_technical_report_dialog = true
        },
        async sendTechnicalReportEmail(type){
            let base64Strings = []
            let file_name = ''
            let soa_file_name = "CMC# "+this.editedCreditMemoChinaId+'.pdf';
            let payload = {
                date_range:[this.filters.date_from,this.filters.date_to],
                cmc_id: this.editedCreditMemoChinaId
            }
            this.loading_dialog = true
            await this.printCreditMemoChina('print')
            await this.$store.dispatch('sendTechnicalReportEmail',payload).then( async res=>{
                this.cm_china_rfs = res.data
                let rf_ids = res.data.map(e=> {
                    return e.id
                })
                this.export_count.total = res.data.length;
                for (let s in res.data){
                    this.export_count.current++;
                    this.export_count.text = `Exporting ${this.export_count.current} of ${ this.export_count.total }`
                    this.export_count.progress = (this.export_count.current / this.export_count.total) * 100
                    this.sc_repair_forms = res.data[s]
                    file_name =  res.data[s].credit_memo_num
                    this.parts_needed = this.sc_repair_forms.parts_needed
                    if( this.parts_needed.length <= 7){
                        this.remaining_row = 7
                        this.remaining_row = this.remaining_row - this.parts_needed.length
                    }
                    let doc = await new this.$jspdf('p', 'mm', 'letter');
                    let ingcoPhLogo = new Image();
                    ingcoPhLogo.src = '/images/ingcoph-logo.png';

                    // Header
                    doc.setFillColor('white')
                    doc.rect(6, 6, 201, 18);
                    doc.line(52, 6, 52, 24);
                    doc.addImage(ingcoPhLogo, 'png', 8, 8, 40, 15);
                    doc.setFont(undefined, 'bold').setTextColor('red').setFontSize(24).text(80,18,"T e c h n i c a l  R e p o r t").setFont(undefined, 'normal').setTextColor('black')

                    // First Column Lines
                    doc.line(25, 30, 40, 30).setFontSize(7).text(25,29,this.sc_repair_forms.rf_number == null ? '' : this.sc_repair_forms.rf_number);
                    doc.line(35, 39, 100, 39).setFontSize(8).text(35,38,this.sc_repair_forms.model == null ? '': this.sc_repair_forms.model);
                    doc.line(35, 45, 100, 45).setFontSize(8).text(35,44,this.sc_repair_forms.serial == null ? '' : this.sc_repair_forms.serial);
                    doc.line(35, 51, 100, 51).setFontSize(8).text(35,50,this.sc_repair_forms.purchase_date == null ? '' : this.sc_repair_forms.purchase_date);
                    doc.line(35, 57, 100, 57).setFontSize(8).text(35,56,this.sc_repair_forms.delivered_at == null ? '' : this.sc_repair_forms.delivered_at);
                    doc.line(35, 64, 100, 64).setFontSize(8).text(35,63,this.sc_repair_forms.warranty == null ? '' : this.sc_repair_forms.warranty);
                    doc.line(35, 70, 207, 70).setFontSize(8).text(35,69,this.sc_repair_forms.complaint == null ? '' : this.sc_repair_forms.complaint);
                    doc.line(35, 76, 207, 76).setFontSize(8).text(35,75,this.sc_repair_forms.symptom_upon_tested == null ? '' : this.sc_repair_forms.symptom_upon_tested);

                    // Second Column Lines
                    doc.line(129, 39, 207, 39).setFontSize(8).text(129,38,this.sc_repair_forms.dealer == null ? '' : this.sc_repair_forms.dealer);
                    doc.line(129, 45, 207, 45).setFontSize(8).text(129,44,this.sc_repair_forms.company_name == null ? '' : this.sc_repair_forms.company_name);
                    doc.line(129, 51, 207, 51).setFontSize(8).text(129,50,this.sc_repair_forms.store_name == null ? '' : this.sc_repair_forms.store_name);
                    doc.line(129, 57, 207, 57).setFontSize(8).text(129,56,this.sc_repair_forms.rf_number == null ? '' : this.sc_repair_forms.rf_number);
                    doc.line(129, 64, 207, 64).setFontSize(8).text(129,63,this.sc_repair_forms.assigned_technician == null ? '' : this.sc_repair_forms.assigned_technician);

                    doc.autoTable({
                        html: '#tech_report',
                        theme: 'plain',
                        margin: {top: 25, left: 4, right: 11},
                        columnStyles: {
                            0: { halign:'left', textColor: '#000000', fontSize: 7},
                            1: { halign:'left', textColor: '#000000', fontSize: 7},
                        },
                    });

                    //Image Container
                    let imageContainerHeight = 95

                    doc.setFillColor('white')
                    let x = 15;
                    let y = 85;
                    for (let i in this.sc_repair_forms.sc_repair_form_files){
                        let supporting_img = new Image();
                        supporting_img = `${process.env.VUE_APP_API_HOST}/img/service?image_name=${this.sc_repair_forms.sc_repair_form_files[i].name}`

                         await fetch(supporting_img)
                        .then(resp => resp.blob())
                        .then(async blobobject => {
                            const blob = window.URL.createObjectURL(blobobject);
                            const anchor = document.createElement('a');
                            anchor.style.display = 'none';
                            anchor.href = blob;
                            document.body.appendChild(anchor);
                            await this.blobToBase64(blobobject,this.sc_repair_forms.sc_repair_form_files[i].name,file_name)
                        })

                        doc.addImage(supporting_img, 'JPEG', x, y,  35, 35 );
                        x=x+35;
                        if(i == 4){
                        y=y+35;
                        x=15;
                        }
                    }

                    for (let i in this.sc_repair_forms.wir_files){
                        let supporting_img = new Image();
                        supporting_img = `${process.env.VUE_APP_API_HOST}/img/warehousereturn?image_name=${this.sc_repair_forms.wir_files[i].name}&folder_name=warehousereturn`

                         await fetch(supporting_img)
                        .then(resp => resp.blob())
                        .then(async blobobject => {
                            const blob = window.URL.createObjectURL(blobobject);
                            const anchor = document.createElement('a');
                            anchor.style.display = 'none';
                            anchor.href = blob;
                            document.body.appendChild(anchor);
                            await this.blobToBase64(blobobject,this.sc_repair_forms.wir_files[i].name,file_name)
                        })

                        doc.addImage(supporting_img, 'JPEG', x, y,  35, 35 );
                        x=x+35;
                        if(i == 4){
                        y=y+35;
                        x=15;
                        }
                    }

                    for (let i in this.sc_repair_forms.credit_memo_files){
                        let supporting_img = new Image();
                        // let cm_file_name = this.sc_repair_forms.credit_memo_files[i].name.replace("#", "%23")

                        supporting_img = `${process.env.VUE_APP_API_HOST}/img/accounting?image_name=${this.sc_repair_forms.credit_memo_files[i].name.replace(/\#/g, "%23")}&folder_name=credit_memo_uploads`
                         await fetch(supporting_img)
                        .then(resp => resp.blob())
                        .then(async blobobject => {
                            const blob = window.URL.createObjectURL(blobobject);
                            const anchor = document.createElement('a');
                            anchor.style.display = 'none';
                            anchor.href = blob;
                            document.body.appendChild(anchor);
                            await this.blobToBase64(blobobject,this.sc_repair_forms.credit_memo_files[i].name,file_name)
                        })

                        doc.addImage(supporting_img, 'JPEG', x, y,  35, 35 );
                        x=x+35;
                        if(i == 4){
                        y=y+35;
                        x=15;
                        }
                    }


                    doc.rect(6, doc.autoTable.previous.finalY, 201, imageContainerHeight);

                    // Tech Diagnosis
                    doc.line(35, doc.autoTable.previous.finalY + imageContainerHeight + 5, 207, doc.autoTable.previous.finalY + imageContainerHeight + 5).setFontSize(8).text(35,doc.autoTable.previous.finalY + imageContainerHeight + 4, this.sc_repair_forms.tech_diagnosis == null ? '' : this.sc_repair_forms.tech_diagnosis);
                    doc.autoTable({
                        html: '#tech_diagnosis',
                        theme: 'plain',
                        startY:doc.autoTable.previous.finalY + imageContainerHeight,
                        margin: {top: 25, left: 4, right: 11},
                        columnStyles: {
                            0: { halign:'left', textColor: '#000000', fontSize: 7},
                        },
                    });

                    // Parts Needed
                    doc.autoTable({
                        html: '#parts_needed_'+s,
                        theme: 'grid',
                        startY:doc.autoTable.previous.finalY + 2,
                        margin: {top: 25, left: 6, right: 9},
                        columnStyles: {
                            0: { halign:'center', textColor: '#000000', fontSize: 7,cellWidth: 40,},
                            1: { halign:'center', textColor: '#000000', fontSize: 7,cellWidth: 40,},
                            2: { halign:'center', textColor: '#000000', fontSize: 7},
                            3: { halign:'center', textColor: '#000000', fontSize: 7,cellWidth: 30,},
                        },

                    });

                    //Recommendation
                    doc.line(35, doc.autoTable.previous.finalY + 5, 207, doc.autoTable.previous.finalY + 5).setFontSize(8).text(35,doc.autoTable.previous.finalY + 4,this.sc_repair_forms.recommendation === null ? '' : this.sc_repair_forms.recommendation);
                    doc.autoTable({
                        html: '#recommendation',
                        theme: 'plain',
                        startY:doc.autoTable.previous.finalY,
                        margin: {top: 25, left: 4, right: 11},
                        columnStyles: {
                            0: { halign:'left', textColor: '#000000', fontSize: 7, },
                        },
                    });

                    // Signatories
                    doc.autoTable({
                        html: '#signatories_'+s,
                        theme: 'plain',
                        startY:doc.autoTable.previous.finalY + 1,
                        margin: {top: 23, left: 4, right: 11},
                        columnStyles: {
                            0: { halign:'left', textColor: '#000000', fontSize: 7},
                            1: { halign:'left', textColor: '#000000', fontSize: 7},
                            2: { halign:'left', textColor: '#000000', fontSize: 7},
                        },
                        willDrawCell: function (data) {
                            var rows = data.table.body;
                            if (data.row.index === rows.length - 1) {
                                rows[1].cells[0].styles.halign = 'center'
                                rows[1].cells[1].styles.halign = 'center'
                                rows[1].cells[2].styles.halign = 'center'
                            }
                        },
                    });

                    base64Strings.push(
                        {base64:doc.output('datauristring', file_name + '.pdf'), file:file_name}
                    )
                }
                this.export_count.text = `Zipping File... For a moment`
                this.export_count.isIntermediate = true
                let soa_base_64 = {base64:this.base64OutputCmChina, file:'Statement Of Account'}
                if(type == 'download'){
                    let zip = new this.$jszip();
                    let pdfOptions = { base64: true,compression: "DEFLATE", compressionOptions: { level: 9 } }
                    base64Strings.forEach((x,i)=>{
                        let pdf = zip.folder(`CM# ${x.file}`);
                        for (let i = 0; i< 5;i++){
                            this.base64Images.forEach(y=>{
                                if(y.cm_num == x.file){
                                    pdf.file(y.file_name,y.base64.split(',')[1], pdfOptions);
                                }
                            })
                        }
                        pdf.file("CM#"+x.file+".pdf",x.base64.split(',')[1], pdfOptions);
                    });
                    zip.file(soa_file_name,this.base64OutputCmChina.split(',')[1], pdfOptions)

                    await zip.generateAsync({ type: "base64" }).then(async function (content) {
                        let link = document.createElement('a')
                        link.download = `Technical Report`;
                        link.href="data:application/zip;base64," + content;
                        link.click();
                            swal.fire('Download Complete','','success')
                        });
                        this.loading_dialog = false
                        this.export_count = {
                            text:'',
                            current:0,
                            total:0,
                            progress:0,
                            isIntermediate:false
                        }
                }
                else if(type == 'email'){
                    let email_payload = {
                        rf_ids:rf_ids,
                        base64Strings:base64Strings,
                        url:'send-email-technical-report',
                        subject:this.email.email_subject,
                    }
                    await this.$store.dispatch('postPL',email_payload).then(response=>{
                        if(response.status == 200){
                            this.loading.state = false;
                            swal.fire('Email Sent Successfully','','success')
                        }
                        else{
                            swal.fire('Oops something went wrong','','error')
                        }
                    })
                }
                // this.email_technical_report_dialog = false;
                this.getAllCreditMemos();
            })
        },
        showNewCreditMemoChinaDialog(){
            this.addEditDialogAction = "new";
            this.editedIndex = 0
            this.origins.value = this.origin
            // this.creditMemoType.dialog = true;
            this.chinaDialog = true
            this.$store.dispatch('getCreditMemoTypes',{origin:this.origin})
            this.getCreditMemoTypeStatuses();
            // this.generateCreditMemoChina();
        },
        generateCreditMemoChina(){
            if(this.cm_china.start_date == '' || this.cm_china.end_date == ''){
                swal.fire('Please Select Start and End Date','','warning')
                return false
            }
            let payload = {
                date_range: [this.cm_china.start_date,this.cm_china.end_date],
            }
            this.$store.dispatch('generateCreditMemoChina',payload).then(response=>{
                this.cm_china.table.items = response.data
            })
        },
        addCreditMemoChina(){
            let payload = {
                items: this.cm_china.table.items,
                remarks:this.cm_china.remarks,
                total_amount: this.credit_memo_china_total_amount,
                total_cost: this.credit_memo_china_total_cost,
                date_start: this.cm_china.start_date,
                date_end: this.cm_china.end_date,
            }
            this.$store.dispatch('addCreditMemoChina',payload).then(response=>{
                swal.fire(response.data.msg,'',response.data.icon)
                this.closeChinaDialog()
                this.getAllCreditMemoChina();
            })
        },
        closeChinaDialog(){
            this.chinaDialog = false;
            this.cm_china.remarks = ''
            this.cm_china.start_date = ''
            this.cm_china.end_date = ''
            this.cm_china.confirm_date = null
            this.cm_china.confirm_by = null
            this.cm_china.table.items = []
            this.creditMemoType.value = 0
            this.editedCreditMemoChinaId = 0
        },
        getAllCreditMemoChina(){
            this.cm_china.main_table.loading = true;
            this.cm_china.main_table.items = []
            let payload = {
                page: this.cmc_options.page,
                itemsPerPage: this.cmc_options.itemsPerPage,
                cm_date_range:this.cm_date_range,
                search:this.cm_china.main_table.search,
            }
            this.$store.dispatch('getAllCreditMemoChina',payload).then(response=>{
                let data = response.data
                this.cm_china.main_table.items = data.data.data
                this.totalCreditMemoChina = data.data.total
                this.cm_china.main_table.loading = false;
            })
        },
        async viewCreditMemoChina(index,editedIndex,action,id){
            this.cm_items_array_local = []
            this.cm_items_array_china = []
            this.creditMemoLocalOptions = []
            this.creditMemoChinaOptions = []
            this.creditMemoChinaPrintData = {}

            await this.$store.dispatch('showCmChina',id).then(response=>{
                let data = response.data

                this.cm_china.start_date = data.credit_memo_china.date_start
                this.cm_china.end_date = data.credit_memo_china.date_end
                this.cm_china.confirm_date = data.credit_memo_china.confirm_date
                this.cm_china.confirm_by = data.credit_memo_china.confirm_by
                this.cm_china.remarks = data.credit_memo_china.remarks;
                this.cm_china.credit_note_num = data.credit_memo_china.credit_note_num;
                this.cm_china.container_num = data.credit_memo_china.container_num;
                this.cm_china.approved_date = data.credit_memo_china.approved_date;
                this.cm_china.approved_dollar = data.credit_memo_china.approved_dollar;
                this.cm_china.credit_note_date = data.credit_memo_china.credit_note_date;
                this.cm_china.table.items = data.credit_memo_china_items;
                // this.editedCreditMemo.type = data.credit_memo_china.credit_memo_type_id

                this.cm_china.credit_memo_date = this.$dayjs(data.credit_memo_china.created_at).format("dddd, MMMM DD, YYYY")

                this.editedCreditMemoChinaId = id
                this.editedIndex = editedIndex;
                this.addEditDialogAction = action;
                // this.cm_china.table.items = this.cm_china.main_table.items[index].credit_memo_china_items;
                this.chinaDialog = true

                let itemsPerPageCounterLocal = 1
                let itemsPerPageCounterChina = 1
                let num_chars_per_page = 0
                let current_char_count = 0
                let item_count = 0
                let remarks_length_per_line = 52
                let num_of_items_per_page = 20

                num_chars_per_page = num_of_items_per_page * remarks_length_per_line

                let local_items = this.cm_china.table.items.filter(e=>{if(e.credit_memo_type == 24){return e}})
                let china_items = this.cm_china.table.items.filter(e=>{if(e.credit_memo_type != 24){return e}})

                if (local_items.length > 0) {
                    local_items.forEach(e => {
                        current_char_count = current_char_count + e.remarks.length
                        item_count++

                        if (current_char_count > num_chars_per_page || item_count == num_of_items_per_page) {
                            itemsPerPageCounterLocal++
                            current_char_count = 0
                            item_count = 0
                        }

                        this.creditMemoLocalOptions.push({
                            pageNum:itemsPerPageCounterLocal,
                            cm_items:e
                        })
                    })
                    this.cmPageCountLocal = itemsPerPageCounterLocal;
                    for (let i = 1; i <= this.cmPageCountLocal; i++) {
                        let page = []
                        this.creditMemoLocalOptions.forEach(e=>{
                            if(e.pageNum == i){
                                page.push(e.cm_items)
                            }
                        })
                        this.cm_items_array_local.push(page)
                    }
                }

                if (china_items.length > 0) {
                    china_items.forEach(e => {
                        current_char_count = current_char_count + e.remarks.length
                        item_count++

                        if (current_char_count > num_chars_per_page || item_count == num_of_items_per_page) {
                            itemsPerPageCounterChina++
                            current_char_count = 0
                            item_count = 0
                        }

                        this.creditMemoChinaOptions.push({
                            pageNum:itemsPerPageCounterChina,
                            cm_items:e
                        })
                    })

                    this.cmPageCountChina = itemsPerPageCounterChina;

                    for (let i = 1; i <= this.cmPageCountChina; i++) {
                        let page = []
                        this.creditMemoChinaOptions.forEach(e=>{
                            if(e.pageNum == i){
                                page.push(e.cm_items)
                            }
                        })
                        this.cm_items_array_china.push(page)
                    }
                }

                let total_cost_local_cm = 0
                let total_cost_china_cm = 0

                local_items.forEach((element) => {
                    total_cost_local_cm +=
                        element.total_cost == null || element.total_cost == ""
                            ? 0
                            : parseFloat(element.total_cost.replace(/\,/g, ""));
                });

                china_items.forEach((element) => {
                    total_cost_china_cm +=
                        element.total_cost == null || element.total_cost == ""
                            ? 0
                            : parseFloat(element.total_cost.replace(/\,/g, ""));
                });

                Object.assign(this.creditMemoChinaPrintData,{
                    cmPageCountLocal: this.cmPageCountLocal,
                    cmPageCountChina: this.cmPageCountChina,
                    editedCreditMemoChinaId: this.editedCreditMemoChinaId,
                    items:this.cm_china.table.items,
                    credit_memo_date:this.cm_china.credit_memo_date,
                    cm_items_array_local:this.cm_items_array_local,
                    cm_items_array_china:this.cm_items_array_china,
                    pdfAction:'print',
                    total_amount: this.credit_memo_china_total_amount,
                    total_cost: this.credit_memo_china_total_cost,
                    total_cost_china: total_cost_china_cm,
                    total_cost_local: total_cost_local_cm,
                });
            })
        },
        updateCreditMemoChina(){
            let payload = {
                items: this.cm_china.table.items,
                remarks:this.cm_china.remarks,
                total_amount: this.credit_memo_china_total_amount,
                total_cost: this.credit_memo_china_total_cost,
                id: this.editedCreditMemoChinaId,
                date_start: this.cm_china.start_date,
                date_end: this.cm_china.end_date,
            }
            this.$store.dispatch('updateCreditMemoChina',payload).then(response=>{
                swal.fire(response.data.msg,'',response.data.icon)
                this.closeChinaDialog()
                this.getAllCreditMemoChina();
            })
        },
        confirmCreditMemoChina() {
            swal.fire({
                    title: "",
                    text: "Are you sure you want to Confirm selected Credit Memo China?",
                    icon: 'question',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    allowOutsideClick: false,
                    confirmButtonColor: "#397373",
                    cancelButtonColor: "grey",
                    confirmButtonText: "Confirm",
                    showCloseButton: true,
                })
                .then((result) => {
                    if(result.isConfirmed){
                        let payload = {
                            id: this.editedCreditMemoChinaId,
                        }
                        this.$store.dispatch('confirmCreditMemoChina',payload).then(response=>{
                            swal.fire(response.data.msg,'',response.data.icon)
                            this.closeChinaDialog()
                            this.getAllCreditMemoChina();
                        })
                    }
                });
        },
        printCreditMemoChina(pdfAction){
            this.print_credit_memo_china = true
        },
        async blobToBase64(blob,i,cm_num) {
            await new Promise((resolve, _) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.readAsDataURL(blob)
            }).then(async result=>{
                this.base64Images.push({'base64':result,'file_name':`img_name_${i}`,'cm_num':cm_num})
            })
            return false
        },
        removeCreditMemo(id,index){
            let currentIndex = this.cm_china.table.items.findIndex(f => f.id === id)
            if (currentIndex !== -1) {
                this.cm_china.table.items.splice(currentIndex, 1)
            }
        },
        getCreditMemoTypeStatuses(){
            this.$store.dispatch('getCreditMemoTypeStatus').then(response=>{
                this.status = response.data
            })
        },
        approveCreditMemoChina() {
            if (this.cm_china.credit_note_num === null && this.cm_china.container_num === null){
                swal.fire('Unprocessable','Please input Credit Note # or Container #','warning')
                return false
            }
            if (!!this.cm_china.credit_note_num && !!this.cm_china.container_num){

                if (this.uploadedFiles == null && this.images == null) {
                    swal.fire({
                        title: "Please Upload Images or Files",
                        icon: "warning",
                    });
                    return false
                }

                if (this.cm_china.approved_dollar <= 0) {
                    swal.fire({
                        title: "Please Input Approve Dollar Amount",
                        icon: "warning",
                    });
                    return false
                }

                if (this.cm_china.approved_dollar == null) {
                    swal.fire({
                        title: "Please Input Credit Note Date",
                        icon: "warning",
                    });
                    return false
                }
            }
            swal.fire({
                    title: "",
                    text: "Are you sure you want to Approve selected Credit Memo China?",
                    icon: 'question',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    allowOutsideClick: false,
                    confirmButtonColor: "#397373",
                    cancelButtonColor: "grey",
                    confirmButtonText: "Confirm",
                    showCloseButton: true,
                })
                .then((result) => {
                    if(result.isConfirmed){

                        const data = new FormData();

                        const config = {
                            headers: {
                                "content-type": "multipart/form-data",
                            },
                        };

                        if (!!this.uploadedFiles && this.uploadedFiles.attachments.length > 0) {
                            let files = this.uploadedFiles.attachments;

                            for (let i = 0; i < _.compact(files).length; i++) {
                                data.append("files[]", files[i]);
                            }
                        }

                        data.append('id', this.editedCreditMemoChinaId)
                        data.append('credit_note_num', this.cm_china.credit_note_num)
                        data.append('container_num', this.cm_china.container_num)
                        data.append('approved_dollar', this.cm_china.approved_dollar)
                        data.append('credit_note_date', this.cm_china.credit_note_date)


                        let payload = {
                            data: data,
                            config: config
                        }

                        this.$store.dispatch('approveCreditMemoChina',payload).then(response=>{
                            swal.fire(response.data.msg,'',response.data.icon)
                            this.closeChinaDialog()
                            this.getAllCreditMemoChina();
                        })
                    }
                });
        },
        getBase64CmChinaSoaPrint(base64){
            this.base64OutputCmChina = base64
        },
        clearCmChinaPrintData(){
            this.creditMemoChinaPrintData = {}

            this.print_credit_memo_china = false
        },
        disapproveCreditMemoChina(id){
            swal.fire({
                title: "",
                text: "Are you sure you want to Disapprove Selected Credit Memo China?",
                icon: 'question',
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                allowOutsideClick: false,
                confirmButtonColor: "#397373",
                cancelButtonColor: "grey",
                confirmButtonText: "Yes",
                showCloseButton: true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('disapprovedCreditMemoChina',id).then(response=>{
                        swal.fire(response.data.msg,'',response.data.icon)
                        this.closeChinaDialog()
                        this.getAllCreditMemoChina();
                    })
                }
            })
        },
        exportCreditMemoToExcel(){
            swal.showLoading();
            let that = this
            const workbook = new this.$exceljs.Workbook();

            const worksheet = workbook.addWorksheet('Raw Data')
            worksheet.columns = [
                { header: "CM#", key: "credit_memo_num", align: "center" },
                { header: "CM DATE", key: "credit_memo_date", align: "center" },
                { header: "CM Type", key: "credit_memo_type_by_name", align: "center" },
                { header: "CUSTOMER", key: "customer_company_name", align: "center" },
                { header: "PL REFS#", key: "packing_list_nums", align: "center" },
                { header: "DEALER REFS#", key: "customer_ref_no", align: "center" },
                { header: "STATUS", key: "status_text", align: "center" },
                { header: "PROCESSED BY", key: "created_by_name", align: "center" },
                { header: "ACCT APPROVED BY", key: "acct_approved_by_name", align: "center" },
                // { header: "EXEC APPROVED BY", key: "exec_approved_by_name", align: "center" },
                { header: "SOA MONTH", align: "center", key: "soa_month_year" },
                { header: this.origin == 1 ? "TOTAL AMOUNT (PHP)" : "TOTAL COST (USD)", align: "right", key: this.origin == 1 ? "total_amount" : "total_cost", style: { numFmt: '#,##0.00' } },
                { header: "PRINTED", align: "center", key: "printed" },
            ]
            this.creditMemoList.data.forEach(e=>{
                e.total_cost = Number (e.total_cost)
                e.total_amount = Number (e.total_amount)
                e.printed = e.printed == 0 ? 'NO' : 'YES'
                e.soa_month_year = this.formatMonthSoa(e.soa_month_year)
                worksheet.addRow(e);
            })
            worksheet.getRow(`0${1}`).font = { bold: true }

            const credit_memo_item_details = workbook.addWorksheet('Item Details')
            credit_memo_item_details.columns = [
                { header: 'CM#', key: 'credit_memo_num', align: 'left' },
                { header: 'MODEL', key: 'model', align: 'left' },
                { header: 'NAME', key: 'name', align: 'left' },
                { header: 'UOM', key: 'uom', align: 'left' },
                { header: 'TYPE', key: 'type', align: 'left' },
                { header: 'CATEGORY', key: 'category', align: 'left' },
                { header: 'REMARKS', key: 'remarks', align: 'left' },
                { header: 'QUANTITY', key: 'quantity', align: 'right', style: { numFmt: '#,##0' } },
                { header: 'ITEM COST (USD)', key: 'item_cost', align: 'right', style: { numFmt: '#,##0.00' } },
                { header: 'TOTAL COST (USD)', key: 'item_total_cost', align: 'right', style: { numFmt: '#,##0.00' } },
            ]
            this.creditMemoList.credit_memo_item_details.forEach(e => {
                e.cm_total_cost = Number (e.cm_total_cost)
                e.item_cost = Number (e.item_cost)
                e.item_total_cost = Number(e.item_total_cost)
                credit_memo_item_details.addRow(e)
            })
            credit_memo_item_details.getRow(`0${1}`).font = { bold: true }

            that.loaded = true
            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                this.$filesaver.saveAs(blob, `Credit Memo ${this.origin == 1 ? 'Local' : 'China'} Export.xlsx`);
            });
            swal.close()
            swal.fire({
                icon: 'success',
                title: 'Export Successfully!',
                allowOutsideClick: false,
            });
            // that.credit_memo_china.loading = false
        },
        formatMonthSoa(value) {
            if (value) {
                return this.$dayjs(String(value)).format('MMM YYYY')
            }
        },
        async exportCreditMemoChina(){
            swal.showLoading();
            let that = this
            const workbook = new this.$exceljs.Workbook();
            const worksheet = workbook.addWorksheet('Raw Data')
            worksheet.columns = [
                { header: "CMC ID", key: "cmc_id", align: "center" },
                { header: "Amount (USD)", key: "total_cost", align: "center", style: { numFmt: '#,##0.00' }  },
                { header: "Status", key: "status", align: "center" },
                { header: "Credit Note Date", key: "credit_note_date", align: "center" },
                { header: "Credit Note Num", key: "credit_note_num", align: "center" },
                { header: "Container Num", key: "container_num", align: "center" },
                { header: "Approved Dollar", key: "approved_dollar", align: "center", style: { numFmt: '#,##0.00' }},
            ]
            await this.$store.dispatch('exportCreditMemoChina',{}).then(response=>{
                let data = response.data
                let count = 1;
                data.forEach(e=>{
                    let company_name = worksheet.getRow(`${count}`)
                    company_name.values = [e.name,'','','','','','']
                    company_name.font = { bold: true }
                    // worksheet.getRow(`${count+1}`).fill = {
                    //     type: 'pattern',
                    //     pattern:'solid',
                    //     fgColor:{argb:'F08080'},
                    // };
                    let sub_header = worksheet.getRow(count+1)
                    sub_header.font = { bold: true }
                    sub_header.values = ['CMC ID', 'Amount (USD)', 'Status', 'Credit Note Date', 'Credit Note Num', 'Container Num', 'Approved Dollar']
                    count += 2;
                    e.cmc.forEach(x=>{
                        x.total_cost = Number (x.total_cost)
                        x.approved_dollar = Number (x.approved_dollar)
                        let cmc_id = worksheet.getCell(`A${count}`);
                        cmc_id.value = x.cmc_id
                        let total_cost = worksheet.getCell(`B${count}`);
                        total_cost.value = x.total_cost
                        let status = worksheet.getCell(`C${count}`);
                        status.value = x.status
                        let credit_note_date = worksheet.getCell(`D${count}`);
                        credit_note_date.value = x.credit_note_date
                        let credit_note_num = worksheet.getCell(`E${count}`);
                        credit_note_num.value = x.credit_note_num
                        let container_num = worksheet.getCell(`F${count}`);
                        container_num.value = x.container_num
                        let approved_dollar = worksheet.getCell(`G${count}`);
                        approved_dollar.value = x.approved_dollar

                        // worksheet.getRow(`${count}`).border = {
                        //     top: {style:'thin'},
                        // };
                        worksheet.addRow(x);
                    })
                    count += (e.cmc.length > 0 ? e.cmc.length+2 : 1);
                })
            })
            // this.creditMemoList.data.forEach(e=>{
            //     e.total_cost = Number (e.total_cost)
            //     e.total_amount = Number (e.total_amount)
            //     e.printed = e.printed == 0 ? 'NO' : 'YES'
            //     e.soa_month_year = this.formatMonthSoa(e.soa_month_year)
            //     worksheet.addRow(e);
            // })
            worksheet.getRow(`0${1}`).font = { bold: true }
            that.loaded = true
            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                this.$filesaver.saveAs(blob, `Credit Memo ${this.origin == 1 ? 'Local' : 'China'} Export.xlsx`);
            });
            swal.close()
            swal.fire({
                icon: 'success',
                title: 'Export Successfully!',
                allowOutsideClick: false,
            });
        },
        triggerChangeTypeDialog(value){
            this.change_type.dialog = value;
            this.change_type.new_type = null;
        },
        submitNewCMType(){
            let old_type = this.GET_CREDIT_MEMO_TYPES.find(el => el.value == this.editedCreditMemo.type);
            let new_type = this.GET_CREDIT_MEMO_TYPES.find(el => el.value == this.change_type.new_type);
            let that = this
            function changeStatusNow(){
                that.editedCreditMemo.type = that.change_type.new_type;
                that.editedCreditMemo.credit_memo_type = new_type.name;

                that.triggerChangeTypeDialog(false);
            }
            function showPrompSwal(toChange){
                swal.fire({
                    title: "Are you sure you want to change Credit Memo Type?",
                    text: "Credit Memo Items will be cleared",
                    icon: 'question',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    allowOutsideClick: false,
                    confirmButtonColor: "#397373",
                    cancelButtonColor: "grey",
                    confirmButtonText: "Confirm",
                    showCloseButton: true,
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        changeStatusNow();
                        if(toChange == 1){
                            that.editedCreditMemoItems.data = that.editedCreditMemoItems.data.map(item => ({
                                ...item,
                                item_id: null,
                                quantity_cm: null,
                                item_amount: 0,
                                total_amount: 0,
                                item_name: null,
                                item_uom: null,
                            }));
                        }else{
                            that.editedCreditMemoItems.data = that.editedCreditMemoItems.data.map(item => ({
                                ...item,
                                remarks: null,
                                total_amount: 0,
                                item_amount: 0,
                            }));
                        }
                    }
                })
            }
            if(old_type.status != new_type.status){
                if(new_type.status == 3 && old_type.status != 3){
                    showPrompSwal(1);
                }
                else if (new_type.status != 3 && old_type.status == 3){
                    showPrompSwal(2);
                }
                else{
                    changeStatusNow();
                }
            }else{
                changeStatusNow();
                this.triggerChangeTypeDialog(false);
            }
        },
        printRrDetails() {
            this.$forceUpdate();
            this.$nextTick(() => {
                if (this.receivingReportId) {
                    this.$store.commit('PRINT_RR_ID', this.receivingReportId);
                }
            })
        },
        printRfDetails(){
            this.$forceUpdate();
            this.$nextTick(() => {
                if (this.repairFormId) {
                    this.$store.commit('FROM_MODULE', 'CM');
                    this.$store.commit('PRINT_RF_ID', this.repairFormId);
                }
            })
        }
    },
    watch: {
        origin: {
            handler() {
                let payload = {
                    origin:this.origin
                }
                this.$store.dispatch('getCreditMemoTypes',payload)
                this.assignCreditMemoType()
                this.getAllCreditMemos()
            },
        },
        dialog: function () {
            if (this.dialog) {
                if (this.origins.value == 2) {
                    this.getContainers();
                }

                this.getSoas();
                if (this.editedIndex < 0) {
                    this.addLine();
                    this.editedCreditMemo.credit_memo_date = this.$dayjs();
                    this.formatDate(
                        "credit_memo_date",
                        this.editedCreditMemo.credit_memo_date
                    );
                    this.formatDate("soa_month_year", this.$dayjs());
                }
            }
        },
        USER_ACCESS: function () {
            this.checkAccess();
        },
        print_credit_memo: function (val) {
            if (val) {
                this.loading.state = true;
                this.editedCreditMemoItems.print_data = this.editedCreditMemoItems.data;

                // while (this.editedCreditMemoItems.print_data.length < 4) {
                //     this.editedCreditMemoItems.print_data.push(Object.assign({},{
                //         id: null,
                //         credit_memo_id: null,
                //         item_id: null,
                //         item_model: null,
                //         item_name: null,
                //         item_uom: null,
                //         quantity_cm: null,
                //         item_amount: null,
                //         total_amount: null,
                //     }))
                // }

                this.loading.state = false;

                setTimeout(() => {
                    this.print_credit_memo = false;
                }, 1000);
            }
        },
        print_credit_memo_china: function (val){
            if (val) {
                this.loading.state = true;
                this.creditMemoChinaPrintData = this.creditMemoChinaPrintData

                this.loading.state = false;

                setTimeout(() => {
                    this.print_credit_memo_china = false;
                    this.closeChinaDialog();
                    swal.fire("Print Successfully","","success")
                }, 1000);
            }
        },
        "pagination.options": {
            handler() {
                this.getAllCreditMemos();
            },
            deep: true,
        },
        GET_ALL_CONTAINERS: {
            handler(val) {
                this.loading.container_selection = false;
            },
        },
        GET_CUSTOMERS_LIST_SELECTION: {
            handler(val) {
                this.customer.selection = val
                this.loading.customer_selection = false;
            },
            immediate: true
        },
        GET_CUSTOMER_ADDRESSES: {
            handler(val) {
                this.loading.customer_address_selection = false;
            },
        },
        GET_ALL_WAREHOUSES: {
            handler(val) {
                this.loading.warehouse_selection = false;
            },
        },
        GET_ITEMS: {
            handler(val) {
                this.loading.item_selection = false;
            },
        },
        GET_CREDIT_MEMO_TYPE_STATUS: {
            handler(val) {
                this.status = val;
            },
        },
        'GET_CREDIT_MEMO_CLASSIFICATION': {
            handler(val) {
                this.classifications.selection = val
            }
        },
        'refreshData': {
            handler(val) {
                if (val) {
                    this.getAllCreditMemos()
                }
            }
        },
        'GET_ALL_CREDIT_MEMO_TYPES_CARD_TOTAL':{
            handler(val) {
                if (val) {
                    this.cards = []
                    val.forEach((e,i)=>{
                        this.cards.push({
                            title: e.code, icon: i % 2 == 0 ? 'mdi-wallet' : 'mdi-cash-multiple', icon_class:'total-amount-booked', md:3, prefix: this.origin == 2 ? '&#36; ' : '&#x20b1; ', suffix: '', filter: 'currency', value: e.value, id:e.id
                        })
                        // this.cards = this.cards.filter(e=>{
                        //     return e.id == this.selected_type
                        // })
                        Object.assign(this.totals,{
                            [e.value]: e.total
                        })
                   })
                }
            }
        },
        selected_type:{
            handler(val){
                if(this.origin == 2){
                    this.cards = [];
                    this.GET_ALL_CREDIT_MEMO_TYPES_CARD_TOTAL.forEach(e=>{
                        this.cards.push({
                            title: e.code, icon: 'mdi-cash', icon_class: 'total-amount-booked', md:3, prefix: '&#36; ', suffix: '', filter: 'currency', value: e.value, id:e.id
                        })
                        Object.assign(this.totals,{
                            [e.value]: e.total
                        })
                    })
                    if(val != 0 ){
                        this.cards = this.cards.filter(e=>{
                            return e.id == val || e.id == 0
                        })
                    }
                }
            }
        },
        cmc_options: {
            handler() {
                this.getAllCreditMemoChina()
            },
            deep: true,
        },
        // 'editedCreditMemoItems.data': {
        //     deep: true,
        //     handler(){
        //         if (this.editedCreditMemoItems.data.length > 0) {
        //             this.editedCreditMemoItems.data.forEach((item) => {
        //                 if (item.total_amount != null && item.total_amount != '') {
        //                     item.display_total_amount = this.getTwoDecimals(item.total_amount)
        //                 } else {
        //                     item.display_total_amount = ''
        //                 }
        //             })
        //         }
        //         this.$forceUpdate()
        //     }
        // }
    },
    computed: {
        credit_memo_total_amount() {
            let total = 0.0;
            this.editedCreditMemoItems.data.forEach((element) => {
                total +=
                    element.total_amount == null || element.total_amount == ""
                        ? 0
                        : parseFloat(element.total_amount.replace(/\,/g, ""));
            });
            return total;
        },
        credit_memo_total_cost() {
            let total = 0.0;
            this.editedCreditMemoItems.data.forEach((element) => {
                total +=
                    element.total_cost == null || element.total_cost == ""
                        ? 0
                        : parseFloat(element.total_cost.replace(/\,/g, ""));
            });
            return total;
        },
        ...mapGetters([
            "GET_CM_DETAILS",
            "GET_ALL_CM",
            "GET_SHOW_CM",
            "GET_CUSTOMERS_LIST_SELECTION",
            "GET_ALL_WAREHOUSES",
            "GET_CUSTOMER_SOAS",
            "GET_CUSTOMER_ADDRESSES",
            "USER_ACCESS",
            "GET_ITEMS",
            "GET_ALL_CONTAINERS",
            "GET_CREDIT_MEMO_TYPES",
            "GET_CREDIT_MEMO_TYPE_STATUS",
            "WIR_SELECTED",
            'GET_CREDIT_MEMO_CLASSIFICATION',
            'GET_ALL_CREDIT_MEMO_TYPES_CARD_TOTAL',
        ]),
        credit_memo_china_total_amount() {
            let total = 0.0;
            this.cm_china.table.items.forEach((element) => {
                total +=
                    element.total_amount == null || element.total_amount == ""
                        ? 0
                        : parseFloat(element.total_amount.replace(/\,/g, ""));
            });
            return total;
        },
        credit_memo_china_total_cost() {
            let total = 0.0;
            this.cm_china.table.items.forEach((element) => {
                total +=
                    element.total_cost == null || element.total_cost == ""
                        ? 0
                        : parseFloat(element.total_cost.replace(/\,/g, ""));
            });
            return total;
        },
        tableHeaders() {
            return this.origin == 1 ? this.creditMemoList.datatable.headers_local : this.creditMemoList.datatable.headers_china
        },
    },
};
</script>
