<template>
    <div class="tabs-body-color">
        <v-container fluid>
            <v-row class="pt-2">
                <v-col cols="6" class="d-flex no-block align-items-center">
                    <div class="btn-toolbar">
                        <v-btn class="ml-1 mb-2" @click="AddDialog()" v-if="!isActive_tab.includes('item')">
                            <v-icon>mdi-plus</v-icon>Add
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <ServiceTesterCheckListTableComponent  v-if="isActive_tab == isActive_tab"></ServiceTesterCheckListTableComponent>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import ServiceTesterCheckListTableComponent from "@/views/main/modules/Services/Views/ServiceTesterComponent/ServiceTesterCheckListTableComponent.vue";
import Swal from 'sweetalert2'
import { mapGetters } from 'vuex';
export default {

    data(){
        return{
            
        }
    },

    props:["isActive_tab"],

    components: {
        ServiceTesterCheckListTableComponent,
    },

    computed: {
        ...mapGetters([
            'TESTER_CHECK_LISTS',
            'GET_ALL_LIST_TEMPLATE',
            'GET_REFRESH',
            'GET_TABS',
            'GET_HR_TAB_IS_ACTIVE'
        ]),
    },

    methods: {
        AddDialog(){
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", "Add");
        },
    },

    watch: {
        'isActive_tab':{
            handler(val){
                this.$store.commit('REFRESH', true)
            }
        }
    }
}
</script>