<template>
    <v-app>
        <v-dialog
            v-model="showDialog"
            persistent
            max-width="98%"
            scrollable
        >
            <v-card>
                <v-card-title>
                    <v-row class="m-0">
                        <span class="headline">Mass Printing PLs</span>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right" @click="$emit('closeMassPrinting');">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-divider class="pa-0 ma-0"></v-divider>
                <v-card-text class="pa-0">
                    <v-row class="m-0">
                        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                            <v-autocomplete
                                v-model="parameters.customer_id"
                                :items="customers"
                                :disabled="loading"
                                label="Customer"
                                persistent-placeholder
                                auto-select-first
                                hide-details
                                dense
                                outlined
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                            <v-menu
                                v-model="parameters.menu_packing_list_start_date"
                                :close-on-content-click="true"
                                :nudge-right="30"
                                :disabled="loading"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="parameters.packing_list_dates[0]"
                                        v-bind="attrs"
                                        v-on="on"
                                        :disabled="loading"
                                        label="PL Start Date"
                                        prepend-inner-icon="mdi-calendar"
                                        readonly
                                        outlined
                                        dense
                                        hide-details
                                        persistent-placeholder
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="parameters.packing_list_dates[0]"
                                    no-title
                                    @input="parameters.menu_packing_list_start_date = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                            <v-menu
                                v-model="parameters.menu_packing_list_end_date"
                                :close-on-content-click="true"
                                :nudge-right="30"
                                :disabled="loading"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="parameters.packing_list_dates[1]"
                                        v-bind="attrs"
                                        v-on="on"
                                        :disabled="loading"
                                        label="PL End Date"
                                        prepend-inner-icon="mdi-calendar"
                                        readonly
                                        outlined
                                        dense
                                        hide-details
                                        persistent-placeholder
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="parameters.packing_list_dates[1]"
                                    no-title
                                    @input="parameters.menu_packing_list_end_date = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" sm="12" md="1" lg="1" xl="1">
                            <v-autocomplete
                                v-model="parameters.print_status.value"
                                :items="parameters.print_status.list"
                                :disabled="loading"
                                label="Print Status"
                                persistent-placeholder
                                auto-select-first
                                hide-details
                                dense
                                outlined
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="1" lg="1" xl="1">
                            <v-btn
                                :disabled="loading || generating_print"
                                @click="getAllPackingLists()"
                            >
                                Filter
                            </v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="12" sm="12" md="2" lg="2" xl="2" class="d-flex justify-content-end">
                            <v-checkbox
                                v-model="parameters.show_selected_only"
                                :disabled="selected_packing_lists.length == 0 || loading || generating_print"
                                :true-value="true"
                                :false-value="false"
                                label="Show Selected"
                                hide-details
                                @change="clearDatatableSearch()"
                            >
                            </v-checkbox>
                        </v-col>
                    </v-row>
                    <v-divider class="pa-0 ma-0"></v-divider>
                    <v-row class="m-0">
                        <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                            <v-autocomplete
                                v-model="parameters.print_types.value"
                                :items="parameters.print_types.list"
                                :disabled="loading"
                                label="Print Type"
                                persistent-placeholder
                                auto-select-first
                                hide-details
                                dense
                                outlined
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                            <v-autocomplete
                                v-model="parameters.print_options.value"
                                :items="parameters.print_options.list"
                                :disabled="loading"
                                label="Print Options"
                                persistent-placeholder
                                auto-select-first
                                hide-details
                                dense
                                outlined
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="2" lg="2" xl="2" v-if="parameters.print_types.value == 2">
                            <v-autocomplete
                                v-model="parameters.consolidation.value"
                                :items="parameters.consolidation.list"
                                :disabled="loading"
                                label="PDF Consolidation"
                                persistent-placeholder
                                auto-select-first
                                hide-details
                                dense
                                outlined
                                readonly
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                            <v-btn
                                class="ml-2"
                                :disabled="selected_packing_lists.length == 0 || loading || generating_print"
                                :loading="generating_print"
                                @click="massPrint(print, parameters.print_options.value)"
                            >
                                Print
                            </v-btn>
                            <v-btn
                                class="ml-2"
                                :disabled="selected_packing_lists.length == 0 || loading || generating_print"
                                :loading="generating_print"
                                @click="massPrint(print, -1)"
                            >
                                Email
                            </v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                            <v-text-field
                                v-model="parameters.search_datatable"
                                label="Search PL#"
                                single-line
                                hide-details
                                clearable
                                append-icon="mdi-icon"
                                @keydown.enter="getAllPackingLists()"
                                @click:append="getAllPackingLists()"
                                @click:clear="clearDatatableSearch()"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-divider class="pa-0 ma-0"></v-divider>
                    <v-row class="m-0">
                        <v-col>
                            <v-data-table
                                v-model="selected_packing_lists"
                                :headers="packingListHeaders"
                                :items="packingLists"
                                :search="parameters.search_datatable"
                                :options.sync="options"
                                :server-items-length="totalPackingLists"
                                :items-per-page="10"
                                :loading="loading"
                                :show-select="customer_is_selected"
                                height="530"
                                fixed-header
                                @update:options="getAllPackingLists()"
                            >
                                <template v-slot:[`item.packing_list_date`]="{ item }">
                                    <span class="text-nowrap">
                                        {{ item.packing_list_date | formatDate }}
                                    </span>
                                </template>

                                <template v-slot:[`item.customer_name`]="{ item }">
                                    <span style="font-size:10px !important;">
                                        {{ item.customer_name  }}
                                    </span>
                                </template>

                                <template v-slot:[`item.served_amount`]="{ item }">
                                    <span class="text-nowrap">
                                        &#x20b1;{{ item.served_amount | currency }}
                                    </span>
                                </template>

                                <template v-slot:[`item.stock_card`]="{ item }">
                                    <span class="badge badge-primary" v-if="item.stock_card">Yes</span>
                                    <span class="badge badge-danger" v-else>No</span>
                                </template>

                                <template v-slot:[`item.total_amount`]="{ item }">
                                    <span class="text-nowrap">
                                        &#x20b1;{{ item.total_amount | currency }}
                                    </span>
                                </template>

                                <template v-slot:[`item.created_at`]="{ item }">
                                    <span class="text-nowrap">
                                        {{ item.created_at | formatDateTime }}
                                    </span>
                                </template>

                                <template v-slot:[`item.promo_id`]="{ item }">
                                    <span class="badge badge-primary" v-if="item.promo_id != null">Yes</span>
                                    <span class="badge badge-danger" v-else>No</span>
                                </template>

                                <template v-slot:[`item.printed`]="{ item }">
                                    <span class="badge badge-primary" v-if="item.printed">Yes</span>
                                    <span class="badge badge-danger" v-else>No</span>
                                </template>

                                <template v-slot:[`item.status_text`]="{ item }">
                                    <span class="badge badge-saved" v-if="item.status_text=='pending'">{{ item.status_text }}</span>
                                    <span class="badge badge-submitted" v-if="item.status_text=='submitted'">{{ item.status_text }}</span>
                                    <span class="badge badge-purple" v-else-if="item.status_text=='confirmed'">{{ item.status_text }}</span>
                                    <span class="badge badge-warning" v-else-if="item.status_text=='rejected'">{{ item.status_text }}</span>
                                    <span class="badge badge-danger" v-else-if="item.status_text=='canceled'">{{ item.status_text }}</span>
                                    <span class="badge badge-dark" v-else-if="item.status_text=='deleted'">{{ item.status_text }}</span>
                                    <span class="badge badge-success" v-else-if="item.status_text=='completed'">{{ item.status_text }}</span>
                                </template>

                                <template v-slot:[`item.action`]="{ item }">
                                    <span class="text-nowrap">
                                        <v-btn
                                            :disabled="loading"
                                            color="orange"
                                            text
                                            icon
                                            @click="viewPackingList(item.id)"
                                        >
                                            <v-icon class="btn-action" small>fas fa-eye</v-icon>
                                        </v-btn>
                                    </span>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <PackingListsViewComponentVue
            :viewPackingListId="viewPackingListId"
            :service="service"
            @closeView="closeView"
        ></PackingListsViewComponentVue>
    </v-app>
</template>
<script>

import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue'
import PackingListsViewComponentVue from './PackingListsViewComponent.vue'
import swal from 'sweetalert2'
export default {
    mixins: [
    SharedFunctionsComponentVue,
    ],
    components: {
        PackingListsViewComponentVue
    },
    props: [
        'showDialog',
        'customers',
        'service',
    ],
    data(){
        return {
            parameters: {
                mass_printing: true,
                customer_id: null,
                menu_packing_list_start_date: false,
                menu_packing_list_end_date: false,
                show_selected_only: false,
                search_datatable: null,
                packing_list_dates: [
                    this.$dayjs().format('YYYY-MM-DD'),
                    this.$dayjs().format('YYYY-MM-DD'),
                ],
                consolidation: {
                    list: [
                        { text: 'Multiple PL per PDF', value: 1 },
                        { text: 'One PL per PDF', value: 2 },
                    ],
                    value: 1,
                },
                print_types: {
                    list: [
                        { text: 'Paper', value: 1 },
                        { text: 'PDF', value: 2 },
                    ],
                    value: 1,
                },
                print_options: {
                    list: [
                        { text: 'Print w/ Price', value: 1 },
                        { text: 'Print w/o Price', value: 2 },
                        { text: 'Print Email', value: 3 },
                    ],
                    value: 1,
                },
                print_status: {
                    list: [
                        { text: 'All', value: -1 },
                        { text: 'Yes', value: 1 },
                        { text: 'No', value: 0 },
                    ],
                    value: 0,
                }
            },

            loading: false,
            generating_print: false,
            options: {},
            totalPackingLists: 0,
            selected_packing_lists: [],
            packingLists: [],
            packingListHeaders: [
                { text: 'PL Num', align: 'left', value: 'packing_list_num', },
                { text: 'CF Num', align: 'left', value: 'checker_form_id', },
                { text: 'SO Num', align: 'left', value: 'order_num', },
                { text: 'Warehouse', align: 'left', value: 'warehouse_code', },
                { text: 'PL Date', align: 'left', value: 'packing_list_date', },
                { text: 'Customer', align: 'left', value: 'customer_name', },
                { text: 'Salesman', align: 'left', value: 'salesman_name', },
                { text: 'SC', align: 'center', value: 'stock_card' },
                { text: 'Total Amount', align: 'right', value: 'total_amount', },
                { text: 'Status', align: 'left', value: 'status_text', },
                { text: 'Date Issued', align: 'left', value: 'created_at', },
                { text: 'Issuer', align: 'left', value: 'prepared_by', },
                { text: 'Promo', align: 'left', value: 'promo_id', },
                { text: 'Printed', align: 'left', value: 'printed', },
                { text: 'Actions', align: 'center', value: 'action', sortable: false },
            ],

            viewPackingListId: null,

            print: {
                conversions: {
                    point_per_inch: 1 / 72,
                    point_per_pixel: 1 / 0.75,
                    space_after_line: 0.1
                },
                options: {
                    orientation: 'p',
                    unit: 'in',
                    format: 'letter',
                    putOnlyUsedFonts: false,
                },
                margins: {
                    top: 0.58,
                    bottom: 0.58,
                    left: 0.25,
                    right: 0.25
                },
                font_name: 'Helvetica',
                file_name: 'pdf',
            }
        }
    },
    methods: {
        clearDatatableSearch(){
            this.parameters.search_datatable = null
            this.getAllPackingLists()
        },
        getAllPackingLists(){
            this.loading = true
            let payload = {
                page:                   this.options.page,
                itemsPerPage:           this.options.itemsPerPage,
                sortBy:                 this.options.sortBy,
                sortDesc:               this.options.sortDesc,
                customer_id:            this.parameters.customer_id,
                service:                this.service,
                packing_list_dates:     this.parameters.packing_list_dates,
                mass_printing:          this.parameters.mass_printing,
                show_selected_only:     this.parameters.show_selected_only,
                selected_packing_lists: this.selected_packing_lists.map(m => m.id),
                search_datatable:       this.parameters.search_datatable,
                print_status:           this.parameters.print_status.value,
                url:                    'getAll'
            }

            this.$store.dispatch('getPL',payload).then(response => {
                this.packingLists = response.data.data.data
                this.totalPackingLists = response.data.data.total
                this.loading = false
            })
        },
        massPrint(print, print_options) {
            this.generating_print = true
            let payload = {
                selected_packing_lists: this.selected_packing_lists.map(m => m.id),
                print_status:           this.parameters.print_status.value,
                url:                    'mass-print'
            }
            this.$store.dispatch('postPL',payload).then(response => {
                this.print.file_name = this.customers.filter(f => f.value == this.parameters.customer_id)[0].text

                var business_info = response.data.business_info
                var packing_lists = response.data.packing_lists
                var packing_lists_count = packing_lists.length

                var pl = new this.$jspdf(print.options)

                var last_y = 0
                var next_y = 0

                var item_columns = null
                var item_columnStyles = null
                var item_body = null

                packing_lists.forEach((data, index) => {
                    pl.setLineHeightFactor(1.9)
                    pl.setFont(print.font_name, 'bold', 'bold')
                    if (this.parameters.print_options.value != 3) {
                        pl.setFontSize(13.5)
                        pl.text(business_info.name, print.margins.left, print.margins.top)

                        last_y = print.margins.top
                        next_y = last_y + (pl.getFontSize() * print.conversions.point_per_inch) + print.conversions.space_after_line

                        pl.setFont(print.font_name, 'normal', 'normal')
                        pl.setFontSize(9)
                        pl.text([business_info.address, business_info.contact_1, business_info.contact_2], print.margins.left, next_y)
                    } else {
                        next_y = print.margins.top
                    }

                    var doc_num_margin_left = 6.38
                    pl.setFont(print.font_name, 'bold', 'bold')
                    pl.setFontSize(13.5)
                    pl.text('PL# ' + data.packing_list_num, doc_num_margin_left, next_y)
                    last_y = next_y
                    next_y = last_y + (pl.getFontSize() * print.conversions.point_per_inch) + print.conversions.space_after_line
                    pl.setFontSize(9)
                    pl.text('SO# ' + data.sales_order_num, doc_num_margin_left, next_y)
                    last_y = next_y
                    next_y = last_y + (pl.getFontSize() * print.conversions.point_per_inch) + print.conversions.space_after_line
                    pl.text('CF# ' + data.checker_form_num, doc_num_margin_left, next_y)

                    last_y = next_y
                    next_y = last_y + (pl.getFontSize() * print.conversions.point_per_inch) + print.conversions.space_after_line
                    pl.autoTable({
                        theme: 'plain',
                        margin: { top: next_y, left: print.margins.left },
                        columnStyles: {
                            first_header: { cellWidth: 0.79 },
                            first_data: { cellWidth: 5.24 },
                            second_header: { cellWidth: 0.55 },
                            second_data: { cellWidth: 0.91 }
                        },
                        body: [
                            {
                                first_header: 'Customer:',
                                first_data: data.customer_name,
                                second_header: 'Date:',
                                second_data: data.packing_list_date,
                            },
                            {
                                first_header: 'Address:',
                                first_data: data.shipping_address_text,
                                second_header: this.parameters.print_options.value != 3 ? 'Terms:' : 'PO:',
                                second_data: this.parameters.print_options.value != 3 ? (data.terms == 0 ? 'CASH' : data.terms + ' days') : data.customer_po_num,
                            },
                            {
                                first_header: 'Remarks:',
                                first_data: data.remarks,
                                second_header: this.parameters.print_options.value != 3 ? 'PO:' : '',
                                second_data: this.parameters.print_options.value != 3 ? data.customer_po_num : '',
                            },
                        ],
                    })

                    if (print_options == 2) {
                        item_columns = [
                            { header: '#', dataKey: 'line_no' },
                            { header: 'QTY', dataKey: 'quantity' },
                            { header: 'UNITS', dataKey: 'uom' },
                            { header: 'MODEL', dataKey: 'model' },
                            { header: 'DESCRIPTION', dataKey: 'description' },
                        ]
                        item_columnStyles = {
                            line_no: { cellWidth: 0.28, halign:'center', fontSize: 8 },
                            quantity: { cellWidth: 0.47, halign:'center', fontSize: 8 },
                            uom: { cellWidth: 0.55, halign:'center', fontSize: 8 },
                            model: { cellWidth: 1.22, halign:'center', fontSize: 8 },
                            description: { cellWidth: 5.13, halign:'left', fontSize: 8 },
                        }
                        item_body = data.packing_list_items.map((e, i) => {
                            return Object.assign({
                                line_no: i + 1,
                                quantity: e.quantity,
                                uom: e.item.item_uom_text,
                                model: e.item.model,
                                description: e.item.name,
                            }, {})
                        })
                    } else {
                        item_columns = [
                            { header: '#', dataKey: 'line_no' },
                            { header: 'QTY', dataKey: 'quantity' },
                            { header: 'UNITS', dataKey: 'uom' },
                            { header: 'MODEL', dataKey: 'model' },
                            { header: 'DESCRIPTION', dataKey: 'description' },
                            { header: 'UNIT PRICE', dataKey: 'unit_price' },
                            { header: 'TOTAL', dataKey: 'total_amount' },
                        ]
                        item_columnStyles = {
                            line_no: { cellWidth: 0.28, halign:'center', fontSize: 8 },
                            quantity: { cellWidth: 0.47, halign:'center', fontSize: 8 },
                            uom: { cellWidth: 0.55, halign:'center', fontSize: 8 },
                            model: { cellWidth: 1.22, halign:'center', fontSize: 8 },
                            description: { cellWidth: 3.23, halign:'left', fontSize: 8 },
                            unit_price: { cellWidth: 0.91, halign:'right', fontSize: 8 },
                            total_amount: { cellWidth: 0.99, halign:'right', fontSize: 8 },
                        }
                        item_body = data.packing_list_items.map((e, i) => {
                            return Object.assign({
                                line_no: i + 1,
                                quantity: e.quantity,
                                uom: e.item.item_uom_text,
                                model: e.item.model,
                                description: e.item.name,
                                unit_price: e.foc ? 'FOC' : this.getTwoDecimals(e.srp),
                                total_amount: e.foc ? 'FOC' : this.getTwoDecimals(e.quantity * e.srp)
                            }, {})
                        })
                    }

                    last_y = next_y
                    next_y = pl.autoTable.previous.finalY + print.conversions.space_after_line
                    pl.autoTable({
                        theme: 'grid',
                        startY: next_y,
                        margin: { left: print.margins.left },
                        styles: {
                            lineWidth: 0.0078,
                            textColor: '#000000',
                            lineColor: '#000000',
                            fillColor: '#ffffff',
                            halign: 'center',
                            // overflow: 'ellipsize',
                        },
                        columns: item_columns,
                        columnStyles: item_columnStyles,
                        body: item_body,
                    })


                    if (this.parameters.print_options.value != 2) {
                        next_y = 8.15
                        pl.autoTable({
                            theme: 'plain',
                            startY: next_y,
                            margin: { left: print.margins.left },
                            styles: {
                                textColor: '#000000',
                            },
                            columnStyles: {
                                discounting: { cellWidth: 4.53 },
                                label_total: { cellWidth: 1.26, halign: 'right' },
                                currency: { cellWidth: 0.43 },
                                amount: { cellWidth: 1.38, halign: 'right' },
                            },
                            body: [
                                {
                                    discounting: '',
                                    label_total: 'GROSS SALES:',
                                    currency: 'PHP',
                                    amount: this.getTwoDecimals(data.gross_amount),
                                },
                                {
                                    discounting: '',
                                    label_total: 'Less - Discount:',
                                    currency: '',
                                    amount: this.getTwoDecimals(data.discount_amount),
                                },
                                {
                                    discounting: '',
                                    label_total: 'TOTAL SALES:',
                                    currency: 'PHP',
                                    amount: this.getTwoDecimals(data.total_amount),
                                },
                            ],
                        })
                    }

                    next_y = 8.78
                    pl.autoTable({
                        theme: 'plain',
                        startY: next_y,
                        margin: { left: print.margins.left },
                        styles: {
                            textColor: '#000000',
                        },
                        columnStyles: {
                            note_and_no_of_boxes: { cellWidth: 1.5 },
                            no_of_items: { cellWidth: 1.34 },
                            no_of_warranty: { cellWidth: 1.5 },
                            blank: { cellWidth: 2 },
                        },
                        body: [
                            {
                                note_and_no_of_boxes: 'Note: Invoice to follow',
                                no_of_items: "No. of Items: " + (
                                    data.packing_list_items.length == 0
                                        ? '0'
                                        : this.thousandSeprator(data.packing_list_items.reduce((subtotal, current) => {
                                            return subtotal += current.quantity
                                        }, 0))),
                                no_of_warranty: "No. of Warranty: " + (
                                    data.packing_list_items.length == 0
                                        ? '0'
                                        : this.thousandSeprator(data.packing_list_items.reduce((subtotal, current) => {
                                            if (current.item.type_id == 1) {
                                                return subtotal += current.quantity
                                            } else {
                                                return subtotal += 0
                                            }
                                        }, 0))),
                                blank: '',
                            },
                            {
                                note_and_no_of_boxes: "No. of Boxes: " + (
                                    data.packing_list_items.length == 0
                                        ? '0'
                                        : this.thousandSeprator(typeof data.num_of_boxes == undefined || data.num_of_boxes == null ? 0 : data.num_of_boxes)),
                                no_of_items: '',
                                no_of_warranty: '',
                                blank: '',
                            },
                        ],
                    })

                    var received_note_margin_left = 5.2
                    var received_note_margin_top = 9.48
                    pl.setLineHeightFactor(1)
                    pl.setFont(print.font_name, 'normal', 'normal')
                    pl.setFontSize(9)
                    pl.text('Received the above goods and', received_note_margin_left, received_note_margin_top)
                    last_y = received_note_margin_top
                    next_y = last_y + (pl.getFontSize() * print.conversions.point_per_inch)
                    pl.text('services in good order & condition', received_note_margin_left, next_y)
                    last_y = received_note_margin_top
                    next_y = last_y + (pl.getFontSize() * print.conversions.point_per_inch) + print.conversions.space_after_line
                    pl.setFontSize(7.5)
                    pl.text('', received_note_margin_left, next_y)
                    last_y = next_y
                    next_y = last_y + (pl.getFontSize() * print.conversions.point_per_inch) + (print.conversions.space_after_line * 3)
                    pl.setFontSize(16.5)
                    pl.text('_____________', received_note_margin_left, next_y)
                    last_y = next_y
                    next_y = last_y + (pl.getFontSize() * print.conversions.point_per_inch)
                    pl.setFontSize(7.5)
                    pl.setFont(print.font_name, 'italic', 'normal')
                    pl.text('Signature over printed name / date', received_note_margin_left, next_y)


                    last_y = next_y
                    next_y = pl.autoTable.previous.finalY + print.conversions.space_after_line
                    pl.autoTable({
                        theme: 'grid',
                        startY: next_y,
                        margin: { left: print.margins.left },
                        tableWidth: 4.57,
                        styles: {
                            lineWidth: 0.0078,
                            textColor: '#000000',
                            lineColor: '#000000',
                            fillColor: '#ffffff',
                            halign: 'center',
                            // overflow: 'ellipsize',
                        },
                        columnStyles: {
                            issued_by: { cellWidth: 1.5 },
                            noted_by: { cellWidth: 1.5 },
                            noted_by2: { cellWidth: 1.5 },
                        },
                        body: [
                            [
                                { content: 'Issued By' },
                                { content: 'Noted By', colspan: 2 },
                            ],
                            [
                                { content: data.prepared_by, styles: { cellPadding: { top: 0.4 } } },
                                { content: '', colspan: 2 },
                            ]
                        ],
                    })

                    if (index !== (packing_lists_count - 1)) {
                        pl.addPage()
                    }
                });

                switch (print_options) {
                    case -1:
                        let payload = {
                            customer_id: this.parameters.customer_id,
                            pdf: pl.output('datauristring', this.print.file_name + '.pdf'),
                            file_name: this.print.file_name + '.pdf',
                            url: 'send-email'
                        }
                        this.$store.dispatch('postPL',payload).then(response => {
                            swal.fire({
                                title: 'Email Sent Successfully'
                            })
                            this.generating_print = false
                        })
                        break
                    case 1:
                        window.open(pl.output('bloburl'), '_blank')
                        this.generating_print = false
                        break
                    case 2:
                        pl.save(this.print.file_name)
                        this.generating_print = false
                        break
                    default:
                        window.open(pl.output('bloburl'), '_blank')
                        this.generating_print = false
                        break
                }
            })

        },
        viewPackingList(packing_list_id) {
            this.viewPackingListId = packing_list_id
        },
        closeView(){
            this.viewPackingListId = null
        },
    },
    computed: {
        customer_is_selected() {
            return this.packingLists.map(m => m.customer_id).filter((e, i, a) => { return a.indexOf(e) === i }).length === 1
        }
    },
    watch: {
        'showDialog': {
            handler(){
                if (this.showDialog) {
                    this.packingLists = []
                    this.getAllPackingLists()
                }
            },
        },
    }
}
</script>
