<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card>
      <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
        <span class="text-h5">ENTER BILL</span>
        <v-row class="m-0">
          <v-col cols="pull-right-10 p-2">
            <v-btn
              text
              icon
              small
              color="gray"
              class="float-right"
              @click="closeDialog()">
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text style="height: 700px" class="overflow-auto">
        <v-container fluid>
          <v-row no-gutters>
            <v-col cols="12" sm="12" md="6" lg="6">
              <v-row no-gutters>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.ref_num"
                    dense
                    outlined
                    readonly
                    label="Ref. No."
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="form.supplier"
                    :items="GET_ADMIN_SUPPLIER_SELECTION"
                    item-text="text"
                    item-value="value"
                    auto-select-first
                    outlined
                    required
                    @change="supplierAddress()"
                    label="Vendor"
                    :readonly="disabled_view"
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="form.address"
                    :items="all_addresses"
                    item-text="text"
                    item-value="value"
                    auto-select-first
                    outlined
                    required
                    @change="supplierAddress()"
                    label="Address"
                    :readonly="disabled_view"
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="form.terms"
                    :items="terms"
                    item-text="terms"
                    item-value="id"
                    auto-select-first
                    outlined
                    required
                    label="Terms"
                    :readonly="disabled_view"
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6">
              <v-row no-gutters>
                <v-col cols="12">
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="form.date"
                        :disabled="disabled_view"
                        append-icon="mdi-calendar-clock"
                        v-on="on"
                        label="Date"
                        outlined
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.date"
                      @input="menu = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="form.bill_due_date"
                        readonly
                        append-icon="mdi-calendar-clock"
                        v-on="on"
                        label="Bill Due"
                        outlined
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.bill_due_date"
                      @input="menu2 = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.amount_due"
                    dense
                    outlined
                    label="Amount Due"
                    readonly
                  >
                    <template #prepend-inner>
                      <span>&#8369;</span>
                    </template>
                    <!-- <template v-slot:value="{ value }">
                                      {{ value.toFixed(2) }}
                                    </template> -->
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12" lg="12">
                        <v-row no-gutters class="pa-2">
                          <v-col cols="12" lg="4" sm="12" md="12" class="pr-1">
                          <v-autocomplete
                            v-model="form.po_num"
                            :items="GET_ADMIN_PO_NUM_SELECTION"
                            @change="findPRF()"
                            dense
                            allow-overflow
                            outlined
                            item-text="text"
                            item-value="value"
                            auto-select-first
                            required
                            label="PO #"
                            :readonly="disabled_view"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" lg="8" sm="12" md="12">
                          <v-text-field dense outlined v-model="search" label="Search">
                            <template #append>
                                <v-icon>mdi-magnify</v-icon>
                            </template>
                          </v-text-field>
                        </v-col>
                        </v-row>
                          <v-card>
                              <v-card-text>
                                <v-data-table
                                v-model="selected"
                                :headers="pf_headers"
                                :items="GET_PRF_SELECTION"
                                :single-select="false"
                                :search="search"
                                item-key="prf_id"
                                :show-select="isSelectable"
                                :items-per-page="10"
                                :loading="!loading"
                                loader-height="2"
                                :loading-text="loading_text"
                              >

                            <template v-slot:[`item.amount`]="{ item }">
                              {{ item.amount | currency }}
                            </template>
                            </v-data-table>
                                <!-- <v-simple-table fixed-header height="480px" dense>
                                  <thead>
                                    <tr>
                                      <th width="10%"><v-checkbox dense></v-checkbox></th>
                                      <th width="25%">PRF#</th>
                                      <th width="25%">Item Name</th>
                                      <th width="15%">Amount</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                  <tr v-for="(item,i) in GET_PRF_SELECTION" :key="i" class="border-bottom">
                                    <td class="text-caption"><v-checkbox dense></v-checkbox></td>
                                    <td class="text-caption">{{ item.text }}</td>
                                    <td class="text-caption">{{ item.description }}</td>
                                    <td class="text-caption">{{ item.amount | currency }}</td>
                                  </tr>
                                  </tbody>
                                </v-simple-table> -->
                              </v-card-text>
                          </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                  <!-- <v-btn small @click="additem()">ADD ITEM</v-btn> -->
                  <!-- <v-simple-table fixed-header height="480px" dense>
                    <thead>
                      <tr>
                        <th width="15%">PO#</th>
                        <th width="25%">PRF#</th>
                        <th width="15%">Amount</th>
                        <th width="15%">Balance Amount</th>
                        <th width="25%">Amount to Pay</th>
                        <th width="5%">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border-bottom">
                        <td>
                          <v-autocomplete
                            v-model="form.po_num"
                            :items="GET_ADMIN_PO_NUM_SELECTION"
                            @change="findPRF()"
                            dense
                            allow-overflow
                            outlined
                            item-text="text"
                            item-value="value"
                            auto-select-first
                            required
                            :readonly="disabled_view"
                          ></v-autocomplete>
                        </td>
                        <td>
                          <v-autocomplete
                            v-model="form.prf_item_id"
                            :items="GET_PRF_SELECTION"
                            dense
                            allow-overflow
                            outlined
                            item-text="text"
                            item-value="value"
                            auto-select-first
                            required
                            :readonly="disabled_view"
                          ></v-autocomplete>
                        </td>
                        <td>{{ getprfamount() | currency }}</td>
                        <td>-</td>
                        <td>
                          <v-text-field
                            v-model="form.amount_to_pay"
                            @keyup="thousandSeparator()"
                            dense
                            outlined
                            :rules="amount_to_pay_rules"
                            :readonly="disabled_view"
                          >
                          </v-text-field>
                        </td>
                        <td>
                          <v-btn text icon color="green" @click="additem()"  :disabled="disabled_view"
                            ><v-icon>mdi-content-save</v-icon></v-btn
                          >
                        </td>
                      </tr>
                      <tr v-for="(item, i) in form.items" :key="i">
                        <td class="bg-dark text-white">{{ item.po_text }}</td>
                        <td class="bg-dark text-white">
                          {{ item.prf_item_text }}
                        </td>
                        <td class="bg-dark text-white">
                          {{ item.po_amount | currency }}
                        </td>
                        <td class="bg-dark text-white">
                          {{ (item.balance_amount) | currency }}
                        </td>
                        <td class="bg-dark text-white">
                          {{ item.prf_amount_to_pay | currency }}
                        </td>
                        <td class="bg-dark text-white">
                          <v-btn
                            text
                            icon
                            small
                            color="red"
                            @click="removeItem(i)"
                            :disabled="disabled_view">
                            <v-icon small>mdi-delete</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table> -->
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider ></v-divider>
      <v-card-actions class="text-center">
        <v-row class="ma-1" v-if="actions != 'View'">
          <v-col cols="12">
            <v-row>
              <v-col cols="12">
                <v-btn class="float-center" @click="createEnterBill()">
                  <span >{{ actions }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="ma-1" v-else-if="form.status == 1">
          <v-col cols="12">
            <v-row>
              <v-col cols="12">
                <v-btn class="float-center" @click="updateStatus(form.enter_bill_id, 2)">
                  <span>CONFIRM</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import swal from "sweetalert2"
import { mapGetters } from "vuex";
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";

export default {
  mixins: [SharedFunctionsComponentVue],
  inject: ["theme"],
  data() {
    return {
      valid: false,
      form: {
        enter_bill_id: '',
        ref_num: "",
        supplier: "",
        address: "",
        date: this.$dayjs().format("YYYY-MM-DD"),
        terms: "",
        amount_due: "",
        bill_due_date: this.$dayjs().format("YYYY-MM-DD"),
        po_num: "",
        amount_to_pay: "",
        prf_item_id: "",
        items: [],
        status: ''
      },
      all_addresses: [],
      terms: [],
      address_loading: false,
      menu: false,
      menu2:false,
      disabled_view: false,
      createupdate:'',
      amount_to_pay_rules:[
        v => (this.getprfamount() >= parseInt(this.form.amount_to_pay.replaceAll(',',''))) || 'Amount is invalid'
      ],
      pf_headers:[
          { text: "PRF #", align: "left", value: "prf_num",sortable:false },
          { text: "Item Name", align: "left", value: "description",sortable:false },
          { text: "Amount", align: "left", value: "po_amount",sortable:false }
      ],
      search:'',
      selected:[],
      isSelectable:true,
      loading:false,
      loading_text:'Loading... Please Wait'
    };
  },
  computed: {
    ...mapGetters([
      "ACTION",
      "DIALOGS",
      "GET_ADMIN_SUPPLIER_SELECTION",
      "GET_PRF_SELECTION",
      "USER_ACCESS",
      "GET_ENTER_BILL_REF_NUM",
      "GET_ADMIN_PO_NUM_SELECTION",
      "GET_ENTER_BILL_SUCCESS",
      "GET_EDIT_ENTER_BILL_TABLE",
      "GET_EDIT_ENTER_BILL_ITEMS"
    ]),
    actions() {
      if (this.ACTION == "Submit") {
      Object.assign(this.$data, this.$options.data.call(this));
        this.form.ref_num = this.GET_ENTER_BILL_REF_NUM;
        this.createupdate = 'CreateEnterBill'
        this.isSelectable = true
        return "Create";
      } else if (this.ACTION == "Update") {
        this.getEditItems()
        this.disabled_view = true
        this.createupdate = 'UpdateEnterBill'
        this.isSelectable = false
        return "Update";
      } else if (this.ACTION == "View") {
        this.getEditItems()
        this.disabled_view = true
        this.isSelectable = false
        return "View";
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    getEditItems(){
      this.loading = false
      this.form =  {
        enter_bill_id:this.GET_EDIT_ENTER_BILL_TABLE.id,
        ref_num: this.GET_EDIT_ENTER_BILL_TABLE.ref_num,
        supplier: this.GET_EDIT_ENTER_BILL_TABLE.vendor_id,
        address: this.GET_EDIT_ENTER_BILL_TABLE.vendor_address,
        date: this.$dayjs(this.GET_EDIT_ENTER_BILL_TABLE.created_at).format("YYYY-MM-DD"),
        terms: this.GET_EDIT_ENTER_BILL_TABLE.terms_id,
        amount_due: this.thousandSeprator(this.GET_EDIT_ENTER_BILL_TABLE.amount_due),
        bill_due_date: this.$dayjs(this.GET_EDIT_ENTER_BILL_TABLE.bill_date).format("YYYY-MM-DD"),
        po_num: this.GET_EDIT_ENTER_BILL_ITEMS[0].po_num,
        amount_to_pay: "",
        prf_item_id: "",
        items: this.GET_EDIT_ENTER_BILL_ITEMS,
        status: this.GET_EDIT_ENTER_BILL_TABLE.status
      }
      this.form.po_num
      this.supplierAddress()
    },
    additem() {
      let item = this.form.items.find((e) => e.prf_item_id == this.form.prf_item_id);
      let find = item ? this.form.items.find((e) => e.prf_item_id == this.form.prf_item_id): "";
        if(this.getprfamount() >= parseInt(this.form.amount_to_pay.replaceAll(",","")))
        {
        let balance_amount = this.getprfamount() - parseInt(this.form.amount_to_pay.replaceAll(",",""));
      // if (!find && this.actions != 'Submit') {
        this.form.items.push({
          po_num: this.form.po_num,
          po_text: this.GET_ADMIN_PO_NUM_SELECTION.find((e) => e.value == this.form.po_num).text,
          prf_item_id: this.GET_PRF_SELECTION.find((e) => e.value == this.form.prf_item_id).value,
          prf_item_text: this.GET_PRF_SELECTION.find(
            (e) => e.value == this.form.prf_item_id
          ).text,
          po_amount: this.getprfamount(),
          balance_amount:balance_amount,
          prf_amount_to_pay: this.form.amount_to_pay,
        });
        // this.form.po_num = ''
        // this.form.amount_to_pay = "";
      // }
        }
    },
    closeDialog() {
      // Object.assign(this.$data, this.$options.data.call(this));
      this.$store.commit("ENTER_BILL_DIALOG", false);
      this.$store.commit("PRF_SELECTION",[]);
      this.form.supplier = '';
      this.$store.commit("ADMIN_PO_NUM_SELECTION",[]);
      this.form.amount_due = "";
    },
    supplierAddress() {
      this.all_addresses = [];
      this.address_loading = true;
      this.$store.dispatch("showvendors", this.form.supplier).then((data) => {
        this.all_addresses = data.all_shipping_addresses;
        this.terms = data.payments;
        this.address_loading = false;
      });
      this.$store.dispatch("getadminPurchaseOrderNumSelection", {
        id: this.form.supplier,
      });
    },
    viewPo(index) {},
    removeItem(index) {
      this.form.items.splice(index, 1);
    },
    getprfamount() {
      if (this.GET_PRF_SELECTION.find((e) => e.value == this.form.prf_item_id) ) {
        return this.GET_PRF_SELECTION.find((e) => e.value == this.form.prf_item_id).amount;
      } else {
        return 0;
      }
    },
    thousandSeparator() {
      this.form.amount_to_pay = this.form.amount_to_pay.replace(",", "");
      this.form.amount_to_pay = this.thousandSeprator(this.form.amount_to_pay);
    },
    createEnterBill() {
        const supplier_swal_text = this.form.supplier ? "" : ' <span class="text-caption badge badge-warning">Vendor</span> ';
        const address_swal_text = this.form.address ? "" : ' <span class="text-caption badge badge-warning">Address</span> ';
        const terms_swal_text = this.form.terms ? "" : ' <span class="text-caption badge badge-warning">Terms</span> ';
        const array_text = [supplier_swal_text, address_swal_text,terms_swal_text];
        const filtered = array_text.filter(function (el) {
          return el.replace(",", "");
        });
      if (this.form.items.length == 0) {
        this.$store.dispatch("SwalAlertWarning", {
          text: "No item found selected",
        });
        return false;
      }
      else if(!this.form.supplier || !this.form.address || !this.form.terms){
        this.$store.dispatch("SwalAlertWarning", {
          html: `Fill Required Fields ( ${filtered} )`,
          allowOutsideClick: false
        })
        return false;
      }

      this.$store.dispatch(this.createupdate, this.form);


    },
    findPRF() {
      this.loading = false
      this.$store.commit("PRF_SELECTION",[]);
      this.$store.dispatch("getfindPRFfromPO", { id: this.form.po_num });
    },
    updateStatus(id, status) {
      let payload = {
        enter_bill_id: id,
        status: status
      }
      swal.fire({
          icon: 'question',
          text: "Are you sure you want to Confirm?",
          cancelButtonText: "Cancel",
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonColor: "#397373",
          cancelButtonColor: "#397373",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$store.dispatch('UpdateEnterBill', payload)
          }
        });
    }
  },
  watch: {
    "form.items": {
      handler(val) {
        let sum = 0;
        val.forEach((e) => {
          sum += parseInt(e.po_amount);
        });
        this.form.amount_due = this.thousandSeprator(sum);
        this.loading = true
        // this.form.amount_due = sum
      },
    },
    GET_PRF_SELECTION:{
      handler(val){
        if(val.length > 0){
          this.loading = true
        }
      }
    },
    GET_ENTER_BILL_SUCCESS: {
      handler(val) {
        if (val) {
          this.$store.commit('ACTION','');
          this.$store.dispatch("SwalAlertSuccess", {
            text: "Enter Bill Save",
          });
          this.closeDialog()

          Object.assign(this.$data, this.$options.data.call(this));
        }
      },
    },
    GET_EDIT_ENTER_BILL_ITEMS:{
      handler(val){
        this.$store.commit("PRF_SELECTION",val);
      }
    },
    selected:{
        handler(val){
            this.form.items = []
            val.forEach(e=>{
                this.form.items.push({
                    po_num: e.po_num,
                    prf_num: e.prf_num,
                    prf_item_id: e.prf_id,
                    po_amount: e.po_amount,
                });
            })
        }
    }
  },
};
</script>
  <style scoped>
.red-text {
  color: red;
}
</style>
