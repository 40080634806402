<template>
    <v-dialog v-model="isDialogState" grey lighten-5  transition="dialog-bottom-transition" :max-width="width" persistent>
        <v-card flat>
            <v-card-title>
                <v-row>
                    <v-col>
                        <span class="text-lg font-semibold">{{ title }}</span> 
                    </v-col>
                    <v-col class="d-flex justify-end">
                        <v-btn text icon color="gray" class="float-right" @click="$emit('close-dialog', false)">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-divider class="mt-0"></v-divider>
            <v-card-text class="pt-4">
                <slot></slot>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props:{
        title: {
            type: String,
            default: () => ''
        },
        width: {
            type: String,
            default: () => ''
        },
        dialogState: {
            type: Boolean,
            default: () => false,
        },
    },
    data() {
        return {
            isDialogState: false, 
        }
    },
    methods: {
            // 
    },
    watch: {
        'dialogState': {
            handler(val) {
                this.isDialogState = val;
            }
        }
    }
}
</script>

<style>

</style>