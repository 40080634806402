<template>
    <v-container class="ma-0 pa-2">
      <v-container class="ma-0 pa-1">
        <v-app id="item-tabs" class="ma-0">
          <v-tabs v-model="tab" id="group-tab">
            <v-tab ripple>All </v-tab>
            <v-tab ripple>Saved </v-tab>
            <v-tab ripple>For CM </v-tab>
            <v-tab ripple>Approved</v-tab>
            <v-tab ripple>Cancelled</v-tab>

            <v-tabs-items v-model="tab" touchless>
                <!-- ALL CONTAINERS TAB -->
                <v-tab-item>
                    <warehouse-return :status="tab"></warehouse-return>
                </v-tab-item>

                <!-- SAVED CONTAINERS TAB -->
                <v-tab-item>
                    <warehouse-return  :status="tab"></warehouse-return>
                </v-tab-item>

                <!-- RECEIVE CONTAINERS TAB -->
                <v-tab-item>
                    <warehouse-return  :status="tab"></warehouse-return>
                </v-tab-item>

                <v-tab-item>
                    <warehouse-return  :status="tab"></warehouse-return>
                </v-tab-item>

                <v-tab-item>
                    <warehouse-return  :status="tab"></warehouse-return>
                </v-tab-item>

            </v-tabs-items>
          </v-tabs>
        </v-app>
      </v-container>
    </v-container>
  </template>
  <script>
    import { computed } from 'vue'
    import WarehouseReturn from './WarehouseReturnComponent.vue'
    import { mapGetters } from 'vuex';

  export default {
      props:['radioGroup'],
      components: {
        WarehouseReturn,
    },
    data() {
      return {
        tab: null,
      };
    },
    created() {
        this.getStatusFromUrl();
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
        this.$store.dispatch('getWarehouseItemStocks');
        this.$store.dispatch('getAllWarehouses',{})
        this.$store.dispatch('checkAccessUser',this.$router.currentRoute.params.id);
    },
    computed:{
        ...mapGetters([
                'WIR_STATUS',
            ]),
        },
    methods: {
        getStatusFromUrl() {
            if (document.location.href.includes('search-model=')) {
                let status = document.location.href.split('status=');
                status = status[1].split('&');
                this.tab = parseInt(status[0]);
            }
        },
    },
    watch:{
        tab(val){
            this.$store.commit('WIR_STATUS',val)
        }
    }
  };
  </script>
  <style>
  #group-tab {
    padding: 0 !important;
  }
  .tab-bg {
    background-color: gray;
  }
  .small-radio i {
    font-size: 19px;
  }
  .small-radio label {
    font-size: 14px;
    padding-left: 0px;
    margin-left: -4px;
  }
  .small-radio .v-radio {
    padding: 0px;
  }
  .small-radio [class*='__ripple'] {
    left: 0;
  }
  </style>
