<template>
    <v-container fluid class="pa-3">
        <h3 class="page-title">Checker Form</h3>
        <v-container class="ma-0">
            <v-app id="item-tabs">
                <v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" background-color="#424242" dark show-arrows>
                    <v-tab>CHECKING</v-tab>
                    <v-tab>PRINTED</v-tab>
                    <v-tab>CONFIRMED</v-tab>
                    <v-tab>UNSERVED PL</v-tab>
                    <v-tab>ALL</v-tab>
                    <v-spacer></v-spacer>
                    <v-tab v-if="userAccess.includes('cf_transmittal')">TRANSMITTAL</v-tab>

                    <v-tabs-items v-model="tab" touchless>
                        <!-- CHECKING TAB -->
                        <v-tab-item>
                            <CheckerFormComponentVue :tab_name="'is_checking'"></CheckerFormComponentVue>
                        </v-tab-item>

                        <!-- PRINTED TAB -->
                        <v-tab-item>
                            <CheckerFormComponentVue :tab_name="'is_printed'"></CheckerFormComponentVue>
                        </v-tab-item>

                        <!-- CONFIRMED TAB -->
                        <v-tab-item>
                            <CheckerFormComponentVue :tab_name="'is_confirmed'"></CheckerFormComponentVue>
                        </v-tab-item>

                        <!-- CONVERTED TAB -->
                        <v-tab-item>
                            <CheckerFormComponentVue :tab_name="'unserved_pl'"></CheckerFormComponentVue>
                        </v-tab-item>

                        <!-- ALL TAB -->
                        <v-tab-item>
                            <CheckerFormComponentVue :tab_name="'is_all'"></CheckerFormComponentVue>
                        </v-tab-item>

                        <!-- TRANSMITTAL TAB -->
                        <v-tab-item>
                            <CheckerFormComponentVue :tab_name="'is_transmittal'"></CheckerFormComponentVue>
                        </v-tab-item>
                    </v-tabs-items>
                </v-tabs>
            </v-app>
        </v-container>
    </v-container>
</template>

<script>
import CheckerFormComponentVue from './CheckerFormComponent.vue'
import { mapGetters } from 'vuex';

export default {
    components: {
        CheckerFormComponentVue
    },
    data() {
        return {
            tab: null,
            userAccess: []
        }
    },
    mounted() {
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
        this.$store.dispatch('getAllCustomers')
        this.$store.dispatch('getAllSelectUsers')
        this.$store.dispatch('getAllWarehouses', {})
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        this.getSearchFromUrl()
    },
    methods: {
        getSearchFromUrl() {
            if (Object.keys(this.$route.query).length != 0) {
                Object.keys(this.$route.query).forEach( e => {
                    if (e == 'cfsChecked') {
                        this.tab = 3
                    } else if (e == 'notConverted') {
                        this.tab = 0
                    } else if (e == 'cfPreparedVsPreparing') {
                        this.tab = 4
                    }
                })
            }
        },
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS'
        ])
    },
    watch: {
        USER_ACCESS:{
            handler(val){
                if(val != "fail"){
                    this.userAccess = val.map(e=>e.actions_code);
                }
            }
        },
    }
}
</script>
<style>
    #group-tab{
        padding: 0 !important;
    }
</style>
