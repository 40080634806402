<template>
    <v-card v-if=!loading style="overflow: visible" class="pa-4" color="black">
        <v-row no-gutters class="mt-2 mb-1">
            <v-col cols="6" sm="4" md="4" lg="2" xl="2">
                <v-card height="100" class="m-1">
                    <v-card-title>{{ target_achievement_ytd | percentTwoDecimals }}</v-card-title>
                    <v-card-text>Sales Target % (YTD)</v-card-text>
                </v-card>
            </v-col>
            <v-col cols="6" sm="4" md="4" lg="2" xl="2">
                <v-card height="100" class="m-1">
                    <v-card-title>{{ target_achievement_mtd | percentTwoDecimals }}</v-card-title>
                    <v-card-text>Sales Target % (MTD)</v-card-text>
                </v-card>
            </v-col>
            <v-col cols="6" sm="4" md="4" lg="2" xl="2">
                <v-card height="100" class="m-1">
                    <v-card-title>{{ today_orders | currency }}</v-card-title>
                    <v-card-text>SOs Today</v-card-text>
                </v-card>
            </v-col>
            <v-col cols="6" sm="4" md="4" lg="2" xl="2">
                <v-card height="100" class="m-1">
                    <v-card-title>{{ today_sales | currency }}</v-card-title>
                    <v-card-text>PLs Today</v-card-text>
                </v-card>
            </v-col>
            <v-col cols="6" sm="4" md="4" lg="2" xl="2">
                <v-card height="100" class="m-1">
                    <v-card-title>{{ new_customer_with_orders | currency }}</v-card-title>
                    <v-card-text>New Customer with Orders</v-card-text>
                </v-card>
            </v-col>
            <v-col cols="6" sm="4" md="4" lg="2" xl="2">
                <v-card height="100" class="m-1">
                    <v-card-title>{{ potential_sales | currency }}</v-card-title>
                    <v-card-text>Potential Sales</v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row no-gutters class="my-1">
            <v-col cols="12" sm="12" md="12" lg="4" xl="4">
                <v-card height="400" class="m-1 p-1">
                    <v-card-title>Sales By Type (Amount)
                        <v-spacer></v-spacer>
                        <div class="text-right">
                            <v-btn text color="primary" v-text="'More >>'"></v-btn>
                        </div>
                    </v-card-title>
                    <apexchart
                        v-if="sales_by_type.loaded"
                        ref="sales_by_type"
                        type="bar"
                        height="328"
                        :series="chartSeries_SalesByType"
                        :options="chartOptions_SalesByType"
                    >
                    </apexchart>
                </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="4" xl="4">
                <v-card height="400" class="m-1 p-1">
                    <v-card-title>Sales By Category (Amount)
                        <v-spacer></v-spacer>
                        <div class="text-right">
                            <v-btn text color="primary" v-text="'More >>'"></v-btn>
                        </div>
                    </v-card-title>
                    <apexchart
                        v-if="sales_by_category.loaded"
                        ref="sales_by_category"
                        type="bar"
                        height="328"
                        :series="chartSeries_SalesByCategory"
                        :options="chartOptions_SalesByCategory"
                    >
                    </apexchart>
                </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="4" xl="4">
                <v-card height="400" class="m-1 p-1">
                    <v-card-title>Sales By Channel (Amount)
                        <v-spacer></v-spacer>
                        <div class="text-right">
                            <v-btn text color="primary" v-text="'More >>'"></v-btn>
                        </div>
                    </v-card-title>
                    <apexchart
                        v-if="sales_by_channel.loaded"
                        ref="sales_by_channel"
                        type="pie"
                        height="328"
                        :series="chartSeries_SalesByChannel"
                        :options="chartOptions_SalesByChannel"
                    >
                    </apexchart>
                </v-card>
            </v-col>
        </v-row>
        <v-row no-gutters class="my-1">
            <v-col cols="12" sm="12" md="12" lg="4" xl="4">
                <v-card height="400" class="m-1 p-1">
                    <v-card-title>Sales By Region (Amount)
                        <v-spacer></v-spacer>
                        <div class="text-right">
                            <v-btn text color="primary" v-text="'More >>'"></v-btn>
                        </div>
                    </v-card-title>
                    <apexchart
                        v-if="sales_by_region.loaded"
                        ref="sales_by_region"
                        type="pie"
                        height="328"
                        :series="chartSeries_SalesByRegion"
                        :options="chartOptions_SalesByRegion"
                    >
                    </apexchart>
                </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="8" xl="8">
                <v-card height="400" class="m-1 p-1">
                    <v-card-title>DMS
                        <v-spacer></v-spacer>
                        <div class="text-right">
                            <v-btn text color="primary" v-text="'More >>'"></v-btn>
                        </div>
                    </v-card-title>
                    <v-row no-gutters class="mx-1">
                        <v-col cols="4">
                            <div class="text-center">{{ chartLabel_DmsMetric1 }}</div>
                            <apexchart
                                v-if="dms.loaded"
                                ref="dms_metric1"
                                type="radialBar"
                                height="328"
                                :series="chartSeries_DmsMetric1"
                                :options="chartOptions_DmsMetric1"
                            >
                            </apexchart>
                        </v-col>
                        <v-col cols="4">
                            <div class="text-center">{{ chartLabel_DmsMetric2 }}</div>
                            <apexchart
                                v-if="dms.loaded"
                                ref="dms_metric2"
                                type="radialBar"
                                height="328"
                                :series="chartSeries_DmsMetric2"
                                :options="chartOptions_DmsMetric2"
                            >
                            </apexchart>
                        </v-col>
                        <v-col cols="4">
                            <div class="text-center">{{ chartLabel_DmsMetric3 }}</div>
                            <apexchart
                                v-if="dms.loaded"
                                ref="dms_metric3"
                                type="radialBar"
                                height="328"
                                :series="chartSeries_DmsMetric3"
                                :options="chartOptions_DmsMetric3"
                            >
                            </apexchart>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
// import SharedFunctionsComponent from '../Shared/SharedFunctionsComponent.vue'
import SharedFunctionsComponentVue from '../../Shared/SharedFunctionsComponent.vue'
export default {
    mixins: [
        SharedFunctionsComponentVue,
    ],
    components: {

    },
    props: [],
    data(){
        return {
            loading:true,
            target_achievement_ytd: null,
            target_achievement_mtd: null,
            today_orders: null,
            today_sales: null,
            new_customer_with_orders: null,
            potential_sales: null,

            sales_by_type: {
                loaded: false,
                showAs: 'Amount',
                showAs_options: ['Amount', 'Quantity'],
            },
            chartSeries_SalesByType: [],
            chartOptions_SalesByType: {
                chart: {
                    id: 'sales_by_type',
                    height: '328',
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        endingShape: 'rounded',
                    }
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    show: true,
                    colors: ['transparent'],
                },
                xaxis: {
                    categories: ['PT', 'HT', 'SP'],
                },
                yaxis: {
                    labels: {
                        formatter: function(val) {
                            return val.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                        }
                    }
                },
                fill: {
                    opacity: 1,
                },
                tooltip: {
                    y: {
                        formatter: function(val) {
                            return val.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                        }
                    }
                }
            },

            sales_by_category: {
                loaded: false,
                showAs: 'Amount',
                showAs_options: ['amount', 'quantity'],
            },
            chartSeries_SalesByCategory: [],
            chartOptions_SalesByCategory: {
                chart: {
                    id: 'sales_by_category',
                    height: '328',
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        endingShape: 'rounded',
                    }
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    show: true,
                    colors: ['transparent'],
                },
                xaxis: {
                    categories: [],
                },
                yaxis: {
                    labels: {
                        formatter: function(val) {
                            return val.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                        }
                    }
                },
                fill: {
                    opacity: 1,
                },
                tooltip: {
                    y: {
                        formatter: function(val) {
                            return val.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                        }
                    }
                }
            },

            sales_by_channel: {
                loaded: false,
                showAs: 'Amount',
                showAs_options: ['amount', 'quantity'],
            },
            chartSeries_SalesByChannel: [],
            chartOptions_SalesByChannel: {
                chart: {
                    id: 'sales_by_channel',
                    height: '328',
                },
                plotOptions: {
                    pie: {
                        size: 328,
                    }
                },
                labels: [],
                dataLabels: {
                    enabled: true,
                    formatter: function(val) {
                        return val.toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + '%'
                    }
                },
                legend: {
                    show: true,
                },
                tooltip: {
                    y: {
                        formatter: function(val) {
                            return val.toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                        }
                    }
                }
            },

            sales_by_region: {
                loaded: false,
                showAs: 'Amount',
                showAs_options: ['amount', 'quantity'],
            },
            chartSeries_SalesByRegion: [],
            chartOptions_SalesByRegion: {
                chart: {
                    id: 'sales_by_region',
                    height: '328',
                },
                plotOptions: {
                    pie: {
                        size: 328,
                    }
                },
                labels: [],
                dataLabels: {
                    enabled: true,
                    formatter: function(val) {
                        return val.toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + '%'
                    }
                },
                legend: {
                    show: true
                },
                tooltip: {
                    y: {
                        formatter: function(val) {
                            return val.toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                        }
                    }
                }
            },

            dms: {
                loaded: false,
                showAs: 'Amount',
                showAs_options: ['amount', 'quantity'],
            },
            chartLabel_DmsMetric1: 'Confirmed Orders',
            chartSeries_DmsMetric1: [25],
            chartOptions_DmsMetric1: {
                chart: {
                    id: 'dms_metric1',
                    height: '328',
                },
                plotOptions: {
                    radialBar: {
                        startAngle: -90,
                        endAngle: 90,
                        track: {
                            background: '#333',
                            startAngle: -90,
                            endAngle: 90,
                        },
                        dataLabels: {
                            name: {
                                show: false,
                            },
                            value: {
                                show: true,
                                fontSize: '20px',
                                offsetY: 0,
                            }
                        }
                    }
                },
                fill: {
                    type: 'solid',
                    gradient: {
                        stops: [0, 100],
                    }
                },
                stroke: {
                    lineCap: 'butt',
                }
            },
            chartLabel_DmsMetric2: 'Sales',
            chartSeries_DmsMetric2: [50],
            chartOptions_DmsMetric2: {
                chart: {
                    id: 'dms_metric2',
                    height: '328',
                },
                plotOptions: {
                    radialBar: {
                        startAngle: -90,
                        endAngle: 90,
                        track: {
                            background: '#333',
                            startAngle: -90,
                            endAngle: 90,
                        },
                        dataLabels: {
                            name: {
                                show: false,
                            },
                            value: {
                                show: true,
                                fontSize: '20px',
                                offsetY: 0,
                            }
                        }
                    }
                },
                fill: {
                    type: 'solid',
                    gradient: {
                        stops: [0, 100],
                    }
                },
                stroke: {
                    lineCap: 'butt',
                }
            },
            chartLabel_DmsMetric3: 'Productive Calls',
            chartSeries_DmsMetric3: [75],
            chartOptions_DmsMetric3: {
                chart: {
                    id: 'dms_metric3',
                    height: '328',
                },
                plotOptions: {
                    radialBar: {
                        startAngle: -90,
                        endAngle: 90,
                        track: {
                            background: '#333',
                            startAngle: -90,
                            endAngle: 90,
                        },
                        dataLabels: {
                            name: {
                                show: false,
                            },
                            value: {
                                show: true,
                                fontSize: '20px',
                                offsetY: 0,
                            }
                        }
                    }
                },
                fill: {
                    type: 'solid',
                    gradient: {
                        stops: [0, 100],
                    }
                },
                stroke: {
                    lineCap: 'butt',
                }
            },

        }
    },
    methods: {
        getTotals(){
            let payload = {
                url: 'sales/dashboard/getTotals'
            }
            this.$store.dispatch('getSalesDb',payload).then(response => {
                this.target_achievement_ytd = response.data.target_achievement_ytd
                this.target_achievement_mtd = response.data.target_achievement_mtd
                this.today_orders = response.data.today_orders
                this.today_sales = response.data.today_sales
                this.new_customer_with_orders = response.data.new_customer_with_orders
                this.potential_sales = response.data.potential_sales
            })
        },
        getSalesByType(){
            this.sales_by_type.loaded = true
            let payload = {
                url: 'sales/dashboard/getSalesByType'
            }
            this.$store.dispatch('getSalesDb',payload).then(response => {
                this.chartSeries_SalesByType = [{
                    name: 'Amount',
                    data: response.data.series
                }]

                this.chartOptions_SalesByType = {...this.chartOptions_SalesByType, ...{
                    xaxis: {
                        categories: response.data.categories
                    }
                }}
            })
        },
        getSalesByCategory(){
            this.sales_by_category.loaded = true
            let payload = {
                url: 'sales/dashboard/getSalesByCategory'
            }
            this.$store.dispatch('getSalesDb',payload).then(response => {
                this.chartSeries_SalesByCategory = [{
                    name: 'Amount',
                    data: response.data.series
                }]

                this.chartOptions_SalesByCategory = {...this.chartOptions_SalesByCategory, ...{
                    xaxis: {
                        categories: response.data.categories
                    }
                }}
            })
        },
        getSalesByChannel(){
            this.sales_by_channel.loaded = true
            let payload = {
                url: 'sales/dashboard/getSalesByChannel'
            }
            this.$store.dispatch('getSalesDb',payload).then(response => {
                this.chartSeries_SalesByChannel = response.data.series

                this.chartOptions_SalesByChannel = {...this.chartOptions_SalesByChannel, ...{
                    labels: response.data.categories
                }}
            })
        },
        getSalesByRegion(){
            this.sales_by_region.loaded = true
            let payload = {
                url:'sales/dashboard/getSalesByRegion'
            }
            this.$store.dispatch('getSalesDb',payload).then(response => {
                this.chartSeries_SalesByRegion = response.data.series

                this.chartOptions_SalesByRegion = {...this.chartOptions_SalesByRegion, ...{
                    labels: response.data.categories
                }}
            })
        },
        getDms(){
            this.dms.loaded = true
            let payload = {
                url:'sales/dashboard/getDms'
            }
            this.$store.dispatch('getSalesDb',payload).then(response => {
                this.chartLabel_DmsMetric1 = response.data.label_metric1
                this.chartSeries_DmsMetric1 = response.data.series_metric1

                this.chartLabel_DmsMetric2 = response.data.label_metric2
                this.chartSeries_DmsMetric2 = response.data.series_metric2

                this.chartLabel_DmsMetric3 = response.data.label_metric3
                this.chartSeries_DmsMetric3 = response.data.series_metric3
            })
        },
    },
    async mounted(){
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
        this.loading = true;
         await Promise.all([
            this.$store.dispatch('getTotalsDb',{url: 'sales/dashboard/getTotals'}),
            this.$store.dispatch('getSalesByType',{url: 'sales/dashboard/getSalesByType'}),
            this.$store.dispatch('getSalesByCategory',{url: 'sales/dashboard/getSalesByCategory'}),
            this.$store.dispatch('getSalesByChannel',{url: 'sales/dashboard/getSalesByChannel'}),
            this.$store.dispatch('getSalesByRegion',{url:'sales/dashboard/getSalesByRegion'}),
            this.$store.dispatch('getDms',{url:'sales/dashboard/getDms'}),    
        ]).finally(() => {
            this.loading = false
        })
    },
    created(){

    },
    computed: {
        ...mapGetters([
            'GET_TOTALS_DB',
            'GET_SALES_BY_REGION_DB',
            'GET_SALES_BY_TYPE',
            'GET_SALES_BY_CATEGORY',
            'GET_SALES_BY_CHANNEL',
            'GET_SALES_BY_DMS',
        ])
    },
    watch: {
        GET_TOTALS_DB:{
            handler(response){
                this.target_achievement_ytd = response.target_achievement_ytd
                this.target_achievement_mtd = response.target_achievement_mtd
                this.today_orders = response.today_orders
                this.today_sales = response.today_sales
                this.new_customer_with_orders = response.new_customer_with_orders
                this.potential_sales = response.potential_sales
            }
        },
        GET_SALES_BY_REGION_DB:{
            handler(response){
                this.sales_by_region.loaded = true
                this.chartSeries_SalesByRegion = response.series

                this.chartOptions_SalesByRegion = {...this.chartOptions_SalesByRegion, ...{
                    labels: response.categories
                }}
            }
        },
        GET_SALES_BY_TYPE:{
            handler(response){
                this.sales_by_type.loaded = true
                this.chartSeries_SalesByType = [{
                    name: 'Amount',
                    data: response.series
                }]

                this.chartOptions_SalesByType = {...this.chartOptions_SalesByType, ...{
                    xaxis: {
                        categories: response.categories
                    }
                }}
            }
        },
        GET_SALES_BY_CATEGORY:{
            handler(response){
                this.sales_by_category.loaded = true
            
                this.chartSeries_SalesByCategory = [{
                    name: 'Amount',
                    data: response.series
                }]

                this.chartOptions_SalesByCategory = {...this.chartOptions_SalesByCategory, ...{
                    xaxis: {
                        categories: response.categories
                    }
                }}
            }
        },
        GET_SALES_BY_CHANNEL:{
            handler(response){
                this.sales_by_channel.loaded = true
                this.chartSeries_SalesByChannel = response.series
                this.chartOptions_SalesByChannel = {...this.chartOptions_SalesByChannel, ...{
                    labels: response.categories
                }}
            }
        },
        GET_SALES_BY_DMS:{
            handler(response){
                this.dms.loaded = true
                this.chartLabel_DmsMetric1 = response.label_metric1
                this.chartSeries_DmsMetric1 = response.series_metric1

                this.chartLabel_DmsMetric2 = response.label_metric2
                this.chartSeries_DmsMetric2 = response.series_metric2

                this.chartLabel_DmsMetric3 = response.label_metric3
                this.chartSeries_DmsMetric3 = response.series_metric3
            }
        }
    },
}
</script>
<style scoped>
    /* .v-card__title {
        font-size: 1.1rem;
    } */
</style>
