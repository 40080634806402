<template>
    <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
            <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                <span class="text-h5">{{actions + ' Official Business Form' }}</span>
                <v-row class="m-0">
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                            text
                            icon
                            small
                            color="gray"
                            class="float-right"
                            @click="closeDialog()"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-container class="pb-0 my-2" fluid>
                    <v-row>
                        <v-col cols="12" sm="4" class="pa-0 pr-1">
                            <v-autocomplete
                                v-model="form.employee_id"
                                :items="GET_EMPLOYEE_DATA"
                                :readonly="disabled_view"
                                :disabled="disabled_view"
                                :rules="errors.required"
                                :loading="loading.employee_dropdown"
                                item-disabled="isDisabled"
                                label="Employee Name"
                                item-text="full_name"
                                item-value="value"
                                auto-select-first
                                outlined
                                required
                                dense
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="4">
                            <v-menu
                                v-model="form.date_of_filing_menu"
                                transition="scale-transition"
                                ref="menu"
                                min-width="auto"
                                max-width="290px"
                                offset-y
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="form.date_of_filing"
                                        :disabled="disabled_view"
                                        :rules="errors.required"
                                        v-on="on"
                                        required
                                        label="Date Of Filing"
                                        outlined
                                        dense
                                        clearable
                                    ></v-text-field>
                                </template>

                                <v-date-picker
                                    v-model="form.date_of_filing"
                                    :rules="errors.required"
                                    required
                                    no-title
                                    scrollable
                                >
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-card class=" pa-0 overflow-auto" style="max-height:60vh;" elevation="0">
                                <v-data-table
                                    :headers="datatable.headers"
                                    :items="datatable.items"
                                    :loading="loading.datatable"
                                    disable-sort
                                    dense
                                >
                                    <template v-slot:header.action>
                                        <v-row class="d-flex justify-content-end">
                                            <v-spacer></v-spacer>
                                            <span class="mt-1">Action</span>
                                            <v-col cols="4">
                                                <v-btn
                                                    v-if="actions !='View'"
                                                    fab dark small color="primary"
                                                    style="height: 20px; width: 20px"
                                                    @click="addOBRecord()"
                                                >
                                                    <v-icon dark small style="font-size: 12px">mdi-plus</v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-spacer></v-spacer>
                                        </v-row>
                                    </template>
                                    <!-- <template v-slot:[`item.action`]="{ item, index }">
                                        <v-tooltip bottom color="#B80000">
                                            <template v-slot:activator="{ on, attrs }" >
                                                <v-btn
                                                    v-if="actions != 'View'"
                                                    @click="removeOBRecord(item.id, index)"
                                                    icon
                                                    small
                                                    color="#B80000"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    <v-icon>mdi-minus-circle</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Remove Employee</span>
                                        </v-tooltip>
                                    </template> -->
                                    <template v-slot:item="{ item, index }">
                                        <tr v-if="item.editActive == false">
                                            <td> {{ item.ob_date }} </td>
                                            <td> {{ item.time_in }} </td>
                                            <td> {{ item.time_out }} </td>
                                            <td> {{ item.destination }} </td>
                                            <td> {{ item.purpose }} </td>
                                            <td class="pt-1" v-if="actions == 'View'">
                                                <v-text-field
                                                    v-model="item.reject_reason"
                                                    :disabled="item.status != 1"
                                                    :rules="errors.required"
                                                    dense
                                                    outlined
                                                ></v-text-field>
                                            </td>
                                            <td v-if="actions != 'Create'"> <span :class="`badge badge-${badgeStatus(item.status)}`">{{ badgeStatus(item.status) }}</span> </td>
                                            <td v-if="actions != 'View'" class="d-flex justify-content-center">
                                                <v-tooltip bottom color="#337CCF" v-if="item.status != 2">
                                                    <template v-slot:activator="{ on, attrs }" >
                                                        <v-btn
                                                            icon
                                                            small
                                                            @click="item.editActive = true"
                                                            color="#337CCF"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            <v-icon>mdi-calendar-edit</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Edit Record</span>
                                                </v-tooltip>

                                                <v-tooltip bottom class>
                                                    <template v-slot:activator="{ on, attrs }" >
                                                        <v-btn
                                                            icon
                                                            small
                                                            @click="viewRecordFiles(item.uploadedFile)"
                                                            color=""
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            <v-icon>mdi-file-clock-outline</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>View Record Proof</span>
                                                </v-tooltip>

                                                <v-tooltip bottom color="red" v-if="item.status != 2">
                                                    <template v-slot:activator="{ on, attrs }" >
                                                        <v-btn
                                                            icon
                                                            small
                                                            @click="deleteRecord(index, item.id)"
                                                            color="red"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            <v-icon>mdi-delete</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Delete Record</span>
                                                </v-tooltip>
                                            </td>
                                            <td v-else>
                                                <v-tooltip bottom color="#337CCF" v-if="(item.approved_by == null && item.rejected_by == null) && userAccess.includes('approve_ob_record_item')">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                            icon
                                                            small
                                                            @click="changeRecordItemStatus(item, 'approve')"
                                                            color="#337CCF"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            <v-icon>mdi-check-bold</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Approve Record</span>
                                                </v-tooltip>
                                                <v-tooltip bottom class>
                                                    <template v-slot:activator="{ on, attrs }" >
                                                        <v-btn
                                                            icon
                                                            small
                                                            @click="viewRecordFiles(item.uploadedFile)"
                                                            color=""
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            <v-icon>mdi-file-clock-outline</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>View Record Proof</span>
                                                </v-tooltip>
                                                <v-tooltip bottom color="#9e3733" v-if="(item.approved_by == null && item.rejected_by == null) && userAccess.includes('reject_ob_record_item')">
                                                    <template v-slot:activator="{ on, attrs }" >
                                                        <v-btn
                                                            icon
                                                            small
                                                            @click="changeRecordItemStatus(item, 'reject')"
                                                            color="#9e3733"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            <v-icon>mdi-close-thick</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Reject Record</span>
                                                </v-tooltip>
                                            </td>
                                        </tr>
                                        <tr v-if="item.editActive == true">
                                            <td class="pt-1">
                                                <v-menu
                                                    v-model="item.ob_date_menu"
                                                    transition="scale-transition"
                                                    ref="menu"
                                                    min-width="auto"
                                                    max-width="290px"
                                                    offset-y
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            v-model="item.ob_date"
                                                            :disabled="disabled_view"
                                                            :rules="errors.required"
                                                            readonly
                                                            v-on="on"
                                                            required
                                                            outlined
                                                            dense
                                                            clearable
                                                        ></v-text-field>
                                                    </template>

                                                    <v-date-picker
                                                        v-model="item.ob_date"
                                                        :rules="errors.required"
                                                        :max="currentDate"
                                                        @click:date="validateOBDate(item)"
                                                        required
                                                        no-title
                                                        scrollable
                                                    >
                                                    </v-date-picker>
                                                </v-menu>
                                            </td>
                                            <td class="pt-1">
                                                <v-menu
                                                    v-model="item.time_in_menu"
                                                    :disabled="disabled_view"
                                                    :close-on-content-click="false"
                                                    transition="scale-transition"
                                                    offset-y
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            v-model="item.time_in"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            :disabled="disabled_view"
                                                            :rules="errors.required"
                                                            append-icon="mdi-alarm"
                                                            clearable
                                                            readonly
                                                            outlined
                                                            dense
                                                            flat
                                                        ></v-text-field>
                                                    </template>
                                                    <v-time-picker v-model="item.time_in" format="24hr" scrollable @click:minute="item.time_in_menu = false" ></v-time-picker>
                                                </v-menu>
                                            </td>
                                            <td class="pt-1">
                                                <v-menu
                                                    v-model="item.time_out_menu"
                                                    :disabled="disabled_view"
                                                    :close-on-content-click="false"
                                                    transition="scale-transition"
                                                    offset-y
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            v-model="item.time_out"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            :disabled="disabled_view"
                                                            :rules="errors.required"
                                                            append-icon="mdi-alarm"
                                                            clearable
                                                            readonly
                                                            outlined
                                                            dense
                                                            flat
                                                        ></v-text-field>
                                                    </template>
                                                    <v-time-picker v-model="item.time_out" format="24hr" scrollable @click:minute="item.time_out_menu = false" ></v-time-picker>
                                                </v-menu>
                                            </td>
                                            <td class="pt-1">
                                                <v-text-field
                                                    v-model="item.destination"
                                                    :disabled="disabled_view"
                                                    :rules="errors.required"
                                                    dense
                                                    outlined
                                                ></v-text-field>
                                            </td>
                                            <td class="pt-1">
                                                <v-text-field
                                                    v-model="item.purpose"
                                                    :disabled="disabled_view"
                                                    :rules="errors.required"
                                                    dense
                                                    outlined
                                                ></v-text-field>
                                            </td>
                                            <td class="pt-1" v-if="actions == 'View'">
                                                <v-text-field
                                                    v-model="item.reject_reason"
                                                    :disabled="item.status != 1"
                                                    :rules="errors.required"
                                                    dense
                                                    outlined
                                                ></v-text-field>
                                            </td>

                                            <td v-if="actions != 'Create'"> <span :class="`badge badge-${badgeStatus(item.status)}`">{{ badgeStatus(item.status) }}</span> </td>
                                            <td v-if="actions != 'View'">
                                                <v-tooltip  bottom color="blue" v-if="item.status != 2">
                                                    <template v-slot:activator="{ on, attrs }" >
                                                        <v-btn
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            icon
                                                            small
                                                            @click="validateInput(item)"
                                                            color="blue"
                                                        >
                                                            <v-icon>mdi-check-circle</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Save Record</span>
                                                </v-tooltip>

                                                <input type="file" @change="handleFileUpload(item, $event)" style="display: none;" ref="fileInput" accept=".jpg,.jpeg,.png" />
                                                <v-tooltip bottom color="#7383e3">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                            icon
                                                            small
                                                            @click="$refs.fileInput.click()"
                                                            color="#7383e3"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            <v-icon>mdi-upload</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Upload Proof</span>
                                                </v-tooltip>

                                                <v-tooltip bottom color="red" v-if="item.status != 2">
                                                    <template v-slot:activator="{ on, attrs }" >
                                                        <v-btn
                                                            icon
                                                            small
                                                            @click="deleteRecord(index, item.id)"
                                                            color="red"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            <v-icon>mdi-delete</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Delete Record</span>
                                                </v-tooltip>
                                            </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions
                class="text-center"
                style="border-top: 1px solid #dee2e6"
            >
                <v-row class="ma-1 pb-2">
                    <v-col cols="12" v-if="actions != 'View'">
                        <v-btn
                            :loading="loading.button"
                            @click="addUpdateOBRecord()"
                        >
                            <span>Save</span>
                        </v-btn>
                        <span class="pr-12">&nbsp;</span>
                    </v-col>
                    <v-col cols="12" v-if="actions == 'View'">
                        <!-- <v-btn
                            v-if="GET_TIMEKEEPING_RECORD_VIEW.status == 1 && (userAccess.includes('approve_ob_record') || USERACCOUNT_LOAD.manager == 1)"
                            :loading="loading.button"
                            @click="changeOBRecordStatusMain('approve')"
                        >
                            <span>Approve</span>
                        </v-btn> -->
                        <v-btn
                            v-if="
                            GET_TIMEKEEPING_RECORD_VIEW.status == 1 &&
                             (userAccess.includes('confirm_ob_record') || USERACCOUNT_LOAD.manager == 1)
                             && GET_TIMEKEEPING_RECORD_VIEW.pending_count == 0
                             "
                            :loading="loading.button"
                            @click="changeOBRecordStatusMain('confirm')"
                        >
                            <span>Confirm</span>
                        </v-btn>
                        <span class="pr-12">&nbsp;</span>
                    </v-col>
                </v-row>
            </v-card-actions>
            <BaseFileViewer :ob_record_uploads="ob_record_uploads"></BaseFileViewer>
            <ConfirmOverrideComponentVue
                :approve_override_dialog="override.override_dialog"
                :departments_allowed="override.override_departments_allowed"
                :heading="override.headers"
                @closeConfirmOverride="closeConfirmOverride"
            ></ConfirmOverrideComponentVue>
        </v-card>
    </v-form>
</template>
<script>
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue'
import HRTimekeepingFunctionsComponent from '@/views/main/Shared/HRTimekeepingFunctionsComponent.vue'
import BaseFileViewer from "@/views/main/layouts/dialogs/HR/Timekeeping/EmployeeOBRecordFilesViewer.vue";
import ConfirmOverrideComponentVue from "@/views/main/Utils/ConfirmOverrideComponent.vue";

import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'

export default {
    mixins: [
        SharedFunctionsComponentVue,
        HRTimekeepingFunctionsComponent
    ],
    components: {
		BaseFileViewer,
        ConfirmOverrideComponentVue,
    },
    data(){
        return{
            valid: false,
            overlay: false,
            disabled_view: false,
            errors: {
                required: [(v) => !!v || "Field is required"],
                number: [
                    (v) => !!v || "Field is required",
                    (v) => !isNaN(v) || "Input must be a number",
                ],
            },
            form: {
                employee_id: null,
                date_of_filing: this.$dayjs().format('YYYY-MM-DD'),
                date_of_filing_menu: false,
                ob_record_items: [],
                ob_record_item_trash_ids: [],
            },
            datatable:{
                headers: [
                    { text: 'Date', align: 'start', width:'10%', value: 'ob_date'},
                    { text: 'Time In', align: 'start', width:'10%', value: 'time_in'},
                    { text: 'Time Out', align: 'start', width:'10%', value: 'time_out'},
                    { text: 'Destination', align: 'start', width:'18%', value: 'destination'},
                    { text: 'Purpose', align: 'start', width:'18%', value: 'purpose'},
                    { text: 'Status', align: 'start', width:'8%', value: 'status'},
                    { text: 'Action',  align: 'center', width:'6%', value: 'action'},
                ],
                items: [],
                filtered_items: [],
                options: {},
                totalItems: 0,
                expanded: [],
            },
            loading:{
                button: false,
                datatable: false
            },
            notIncludedDates: [],

            trash_ids:{
                record_details: []
            },
            ob_record_uploads:[],
            component_dispatches: [
                'getHolidays',
                'getHourTypes'
            ],
            userAccess: [],
            timekeeping_record_id: '',
            override: {
                override_dialog: false,
                errors: [],
                override_departments_allowed: ["HR", "IT"],
                override_authenticated: false,
            },
            legends: [
                { text: "Inactive", color: "#f44336", text_color: "white" },
                { text: "Manual Add", color: "#a9dcb5", text_color: 'black' },
                { text: "Deleted Record", color: "#fda1a1", text_color: 'black' },
                { text: "Incomplete Logs", color: "#6c99fa", text_color: 'white' },
                { text: "Rest Day", color: "#28ffe2", text_color: 'black' },
                { text: "No Record/Absent", color: "#c990ff", text_color: 'black' },
                { text: "Leave w/pay", color: "#eead8e", text_color: "black" },
                { text: "Leave wo/pay", color: "#ff92d5", text_color: "black" },
            ],
            flexiManagerScheduleID: '',
            checked_department_ids: [],
            currentDate: this.$dayjs().format('YYYY-MM-DD')
        }
    },
    computed:{
        ...mapGetters([
            'GET_EMPLOYEE_BIO_TIME',
            'GET_SELECTED_DATE_RANGE',
            'GET_BIOMETRICS_DATA',
            'ACTION',
            'GET_TIMEKEEPING_RECORD_VIEW',
            'DIALOGS',
            'GET_HR_DISPATCH',
            'GET_HOLIDAYS_SELECTION',
            'GET_HOUR_TYPE_SELECTION',
            'USER_ACCESS',
            'USERACCOUNT_LOAD',
            'GET_EMPLOYEE_SCHEDULE_TYPE_SELECTION',
            'GET_EMPLOYEE_DATA'
        ]),
        actions() {
            this.resetHeaders()
            let index = this.datatable.headers.findIndex(item => item.value === 'status');
            let reject_index = this.datatable.headers.findIndex(item => item.value === 'reject_reason');

            if (this.ACTION == "Submit") {
                this.disabled_view = false;
                if (index !== -1) {
                    this.datatable.headers.splice(index, 1);
                }
                if (reject_index !== -1) {
                    this.datatable.headers.splice(reject_index, 1);
                }
                return "Create";
            } else if (this.ACTION == "Update") {
                this.disabled_view = false;
                if (index == -1) {
                    this.datatable.headers.push(
                        { text: 'Status', align: 'center', value: 'status' }
                    );
                }
                if (reject_index !== -1) {
                    this.datatable.headers.splice(reject_index, 1);
                }
                this.getTimekeepingRecordData();
                return "Update";
            } else if (this.ACTION == "View") {
                this.disabled_view = true;
                if (index == -1) {
                    this.datatable.headers.push(6,0,
                        { text: 'Status', align: 'center', value: 'status' }
                    );
                }
                if(reject_index == -1){
                    this.datatable.headers.splice(5,0,
                    { text: 'Reject Reason', align: 'start', width:'18%', value: 'reject_reason'}
                    )
                }
                this.getTimekeepingRecordData();
                return "View";
            }
        },
    },
    mounted(){
        this.getAllDropdowns();
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        this.checkDepartmentAccess()
    },
    methods:{
        async checkDepartmentAccess(){
            let payload ={
                access: this.USER_ACCESS.map(e => e.actions_code)
            }
            await this.$store.dispatch('checkDepartmentAccess', payload).then(async response=>{
                this.checked_department_ids = response;
                await this.getEmployeeDropdown();
            })

        },
        async getEmployeeDropdown(){
            let payload = {
                access_department_ids: this.checked_department_ids,
            }
            await this.$store.dispatch('getEmployeeDataDropdown', payload).then((res)=>{
                this.loading.employee_dropdown = false;
            })
        },
        getAllDropdowns(){
            this.component_dispatches.forEach((e)=>{
                this.$store.dispatch(e)
            })
        },
        closeDialog(){
            this.$store.commit('DIALOG', false);
            this.$store.commit('TIMEKEEPING_RECORD_VIEW', {});
            this.form.record_trash_ids = [];

        },
        resetHeaders(){
            this.datatable.headers = [
                { text: 'Date', align: 'start', width:'10%', value: 'ob_date'},
                { text: 'Time In', align: 'start', width:'10%', value: 'time_in'},
                { text: 'Time Out', align: 'start', width:'10%', value: 'time_out'},
                { text: 'Destination', align: 'start', width:'18%', value: 'destination'},
                { text: 'Purpose', align: 'start', width:'18%', value: 'purpose'},
                { text: 'Status', align: 'start', width:'8%', value: 'status'},
                { text: 'Action',  align: 'center', width:'6%', value: 'action'},
            ]
        },
        getBiometricDetails(){
            this.form.bio_period = this.dateWithFormat(this.GET_TIMEKEEPING_RECORD_VIEW.timekeeping_period_start, "MMMM DD, YYYY") + ' - ' + this.dateWithFormat(this.GET_TIMEKEEPING_RECORD_VIEW.timekeeping_period_end, "MMMM DD, YYYY")
        },
        loadItemsDetails(){
        },
        allowedDates(val){
            return !this.notIncludedDates.includes(val);
        },
        deleteRecord(index, id = null){
            Swal.fire({
                title: "Delete",
                text: `Are you sure you want to delete ?`,
                icon: 'question',
                showConfirmButton:true,
                confirmButtonText:'Confirm',
                showCancelButton:true,
                showCloseButton:true,
                confirmButtonColor: '397373',
                cancelButtonColor:'grey',
                reverseButtons:true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.datatable.items.splice(index, 1);
                    if(id != null){
                        this.form.ob_record_item_trash_ids.push(id)
                    }
                }
            });
        },
        validateInput(item){
            const ob_date_swal_text = item.ob_date ? '' : 'OB Date';
            const time_in_swal_text = item.time_in ? '' : 'Time In';
            const time_out_swal_text = item.time_out ? '' : 'Time Out';
            const destination_swal_text = item.destination ? '' : 'Destination';
            const purpose_swal_text = item.purpose ? '' : 'Purpose';
            const uploadedFile_swal_text = item.uploadedFile ? '' : 'File Attachment';
            const array_text =
            [
                ob_date_swal_text,
                time_in_swal_text,
                time_out_swal_text,
                destination_swal_text,
                purpose_swal_text,
                uploadedFile_swal_text,
            ]
            const filtered = array_text.filter(function (el) {
                return el.replace(',', '');
            })
            if(item.ob_date && item.time_in && item.time_out && item.destination && item.purpose && item.uploadedFile){
                Swal.fire(
                    "Success",
                    "",
                    "success"
                )
                this.$set(item, 'editActive', false)
            }else{
                Swal.fire({

                    text: `Fill Required Fields (${filtered})`,
                    title:"",
                    icon:"warning"
                })
                this.$set(item, 'editActive', true)
            }
        },
        badgeStatus(status){
            switch(status){
                case 1:
                    return 'pending'
                case 2:
                    return 'rejected'
                case 3:
                    return 'approved'
            }
        },
        getCurrentIndexDates(data){
            let dateRange = this.getDatesFromRange(this.GET_TIMEKEEPING_RECORD_VIEW.timekeeping_period_start, this.GET_TIMEKEEPING_RECORD_VIEW.timekeeping_period_end, "array");
            this.notIncludedDates = data.map(e=>{
                if(dateRange.includes(e.date_punched)){
                    return e.date_punched
                }
            })
        },
        validateFields(){
            this.$refs.form.validate();
            let validations = [
                { validate:this.form.employee_id == null , message: 'Employee Name' },
                { validate:this.form.date_of_filing == null , message: 'Date Of Filing' },
                { validate:this.datatable.items.length == 0, message: 'OB Form Date Items' },
                { validate:this.datatable.items.filter(e => e.ob_date == null).length > 0 ,message: 'OB Date'},
                { validate:this.datatable.items.filter(e => e.time_in == null).length > 0 ,message: 'Time In'},
                { validate:this.datatable.items.filter(e => e.time_out == null).length > 0 ,message: 'Time Out'},
                { validate:this.datatable.items.filter(e => e.destination == '').length > 0 ,message: 'Destination'},
                { validate:this.datatable.items.filter(e => !e.uploadedFile).length > 0 ,message: 'File Attachment'},
                { validate:this.datatable.items.filter(e => e.purpose == '').length > 0 ,message: 'Purpose'},
            ]

            if(validations.map(e=>e.validate).includes(true)){
                Swal.fire(
                    'Warning!',
                    validations[validations.map(e=>e.validate).indexOf(true)].message+' is required',
                    'warning')
            }

            return validations.map(e=>e.validate).includes(true)
        },
        addUpdateOBRecord(){
            this.loading.button = true;
            if(this.validateFields()){
                this.loading.button = false
                return false
            }
            let payload = {
                ...this.form,
                ob_record_items: this.datatable.items,
                url: this.GET_HR_DISPATCH.update
                // record_detail_trash_ids: this.trash_ids.record_details
            }
            this.$store.dispatch('hrUrlPost', payload).then((res)=>{
                this.loading.button = false;
                Swal.fire(
                    res.message,
                    "",
                    res.icon
                )
                this.closeDialog();
                this.$store.commit('TIMEKEEPING_NEW_DATA', true)
                this.$store.commit('BIOMETRICS_SUMMARY_DIALOG', false);
            }).catch((err)=>{
                this.loading.button = false;
                console.log(err)
                this.$store.commit('BIOMETRICS_SUMMARY_DIALOG', false);
                this.closeDialog();
            })
        },
        handleFileUpload(item, event) {
            const file = event.target.files[0];
            let that = this
            const blobFile = new Blob([file], { type: file.type });

            var reader  = new FileReader();
            reader.readAsDataURL(blobFile);
            reader.onloadend = function () {
                that.$set(item, 'uploadedFile', reader.result);
            }
        },
        getTimekeepingRecordData(){
            if(this.ACTION !== 'Submit'){
                if(_.isEmpty(this.GET_TIMEKEEPING_RECORD_VIEW)){
                }else{
                    this.form.id                = this.GET_TIMEKEEPING_RECORD_VIEW.id
                    this.form.employee_id       = parseInt(this.GET_TIMEKEEPING_RECORD_VIEW.employee_id);
                    this.form.date_of_filing    = this.GET_TIMEKEEPING_RECORD_VIEW.date_of_filing;
                    this.form.pending_count     = this.GET_TIMEKEEPING_RECORD_VIEW.pending_count;
                    this.datatable.items        = this.GET_TIMEKEEPING_RECORD_VIEW.employee_ob_record_items;
                    this.loading.datatable      = false;
                }
            }
        },
        resetFields(){
            this.trash_ids.record_details = [];
        },
        changeRecordItemStatus(item, status){
            Swal.fire({
                text: `Are you sure you want to ${status}?`,
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(async(result) => {
                if(result.isConfirmed) {
                    if(status == 'approve'){
                        let payload = {
                            id: item.id,
                            status: status,
                            url: 'changeOBRecordStatus'
                        }
                        this.$store.dispatch('hrUrlPost', payload).then((res)=>{
                            this.$store.commit('TIMEKEEPING_NEW_DATA', true)
                            this.$set(item, 'approved_by', res.approved_by);
                            this.$set(item, 'status', res.status);
                            this.$set(this.form, 'pending_count', res.pending_count)
                            Swal.fire(
                                "Success",
                                "",
                                "success"
                            )
                        }).catch((err)=>{
                            console.log(err)
                            Swal.fire(
                                "Error",
                                "",
                                "error"
                            )
                        })
                    }else{
                        if(!item.reject_reason){
                            this.$refs.form.validate();
                            Swal.fire(
                                "Cannot Reject Record!",
                                "Please Input Reject Reason!",
                                "warning"
                            )
                            return false;
                        }
                        let payload = {
                            id: item.id,
                            status: status,
                            url: 'changeOBRecordStatus',
                            reject_reason: item.reject_reason
                        }
                        this.$store.dispatch('hrUrlPost', payload).then((res)=>{
                            this.$store.commit('TIMEKEEPING_NEW_DATA', true)
                            this.$set(item, 'rejected_by', res.rejected_by);
                            this.$set(item, 'reject_reason', res.reject_reason);
                            this.$set(item, 'status', res.status);
                            this.$set(this.form, 'pending_count', res.pending_count)
                            Swal.fire(
                                "Success",
                                "",
                                "success"
                            )
                        }).catch((err)=>{
                            console.log(err)
                            Swal.fire(
                                "Error",
                                "",
                                "error"
                            )
                        })
                    }
                }
            })
            // }
        },
        addOBRecord(){
            this.datatable.items.push({
                ob_date: null,
                ob_date_menu: false,
                time_in: null,
                time_in_menu: false,
                time_out: null,
                time_out_menu: false,
                destination: '',
                purpose: '',
                editActive: true
            });
        },
        removeOBRecord(id, index){
            this.datatable.items.splice(index);
            this.form.ob_record_item_trash_ids.push(id);
        },
        changeOBRecordStatusMain(status){
            Swal.fire({
                text: `Are you sure you want to ${status}?`,
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(async(result) => {
                if(result.isConfirmed) {
                    let payload = {
                        id: this.GET_TIMEKEEPING_RECORD_VIEW.id,
                        status: status,
                        url: 'changeOBRecordStatusMain'
                    }
                    this.$store.dispatch('hrUrlPost', payload).then((res)=>{
                        Swal.fire(
                            "Success",
                            "",
                            "success"
                        )
                    }).catch((err)=>{
                        console.log(err)
                        Swal.fire(
                            "Error",
                            "",
                            "error"
                        )
                    })
                }
            })
        },
        viewRecordFiles(items){
            this.ob_record_uploads = items;
            this.$store.commit('filesShow');
        },
        async computeTimekeepingRecordSummary(datas, hourClassificationArray){
            datas.forEach((x, i)=>{
                let absentCount = x.data.filter((x) => x.absent == 'Y').length;
                let timekeepingDetails = hourClassificationArray.filter((e)=> e.employee_id == x.employee_id);
                x.total_days_worked = 0;
                x.total_late_in_minutes = 0;

                const startMoment = this.$dayjs(this.GET_TIMEKEEPING_RECORD_VIEW.timekeeping_period_start);
                const endMoment = this.$dayjs(this.GET_TIMEKEEPING_RECORD_VIEW.timekeeping_period_end);

                const daysDifference = endMoment.diff(startMoment, 'day') + 1;

                let rd_count = x.data.filter((x) => x.rest_day == "Y").length;
                // x.total_no_of_days = daysDifference - rd_count;
                x.total_no_of_days = 13;

                x.total_days_worked = x.total_no_of_days - absentCount;

                x.data.forEach((l)=>{
                    if(l.late_in_mins){
                        x.total_late_in_minutes = x.total_late_in_minutes + parseInt(l.late_in_mins)
                    }
                })

                // timekeepingDetails.forEach((r)=>{
                //     if(r.hours_classification.day_worked > 0){
                //         x.total_days_worked = x.total_days_worked + 1;
                //     }else{
                //         x.total_days_worked += r.hours_classification.day_worked;
                //     }
                // })
            })
            return datas
        },
        checkStatusForGenerate(){
            if(this.GET_TIMEKEEPING_RECORD_VIEW.printed == 1){
                this.override.override_dialog = true
            }else{
                this.generateFileMakerExcel()
            }
        },
        closeConfirmOverride(user_id, authenticated = false) {
            if (authenticated) {
                this.generateFileMakerExcel();
            }

            this.override.override_dialog = false;
        },
        filterTable(for_filter = 0){
            let filteredBiometricData = this.datatable.items.map((e) => {
                return {
                    ...e,
                    biometricdata: e.biometricdata.filter((x) => x.manual == 1),
                };
            });
            // Filter this.datatable.items as well
            filteredBiometricData = filteredBiometricData.filter((e) => {
                return e.biometricdata.length > 0; // Filter out elements with no valid biometric data
            });
            if(for_filter == 1){
                return filteredBiometricData
            }
            this.datatable.items = filteredBiometricData;
        },
        removeEmployee(item_id, i, employee_id){
            Swal.fire({
                title: "Delete",
                text: `Are you sure you want to remove employee ?`,
                icon: "warning",
                showConfirmButton:true,
                confirmButtonText:'Confirm',
                showCancelButton:true,
                showCloseButton:true,
                confirmButtonColor: '397373',
                cancelButtonColor:'grey',
                reverseButtons:true,
            }).then((action)=>{
                if(action.isConfirmed){
                    this.datatable.items.splice(i,1);
                    this.form.record_trash_ids.push({
                        record_detail_id: item_id,
                        employee_id: employee_id,
                    });
                }
            })
        },
        checkLeaveData(item, w_pay){
            if(_.isEmpty(item.leave)){
                return false
            }else{
                if(w_pay == 1){
                    return item.leave.days_with_pay > 0 && item.isRestDay == 0 ? true : false
                }else{
                    return item.leave.days_with_pay == 0 ? true : false
                }
            }
        },
        checkLeaveRecordType(record){
            if(record.leave.days_with_pay > 0){
                switch(record.leave.leave_type_name){
                    case 'Half Day':
                        return 'Leave HD Paid'
                    case 'Undertime':
                        return 'Leave UT Paid'
                    case 'Maternity Leave':
                        return 'Maternity Leave'
                    case 'Solo Parent Leave':
                        return 'Solo Parent Leave'
                    default:
                        return 'Leave with Pay'
                }
            }else{
                return 'Leave w/o Pay'
            }
        },
        initialForm(){
            this.form = {
                employee_id: null,
                date_of_filing: this.$dayjs().format('YYYY-MM-DD'),
                date_of_filing_menu: false,
                ob_record_items: [],
                ob_record_item_trash_ids: [],
            }
            this.datatable.items = [];
            this.addOBRecord();
        },
        validateOBDate(item){
            let existing_dates = this.datatable.items.filter((x)=> x.ob_date == item.ob_date);
            if(existing_dates.length > 1){
                Swal.fire(
                    "Date already selected!",
                    "Please choose other dates.",
                    "warning"
                )
                this.$set(item, 'ob_date', null);
                return false
            }
        }
    },
    watch:{
        GET_BIOMETRICS_DATA:{
            handler(val){
                this.datatable.items = val
            },
            deep: true
        },
        "datatable.items":{
            deep: true,
            handler(val){
            }
        },
        DIALOGS:{
            handler(val){
                if(!val){
                    this.resetFields();
                }else{
                    this.initialForm();
                }
            }
        },
        USER_ACCESS:{
            handler(val){
                this.userAccess = val.map(e=>e.actions_code);
            }
        },
    }
}
</script>
<style>
.show_with_manual{
    background-color: #1198b9;
}
.highlight_manual{
    background-color: #a9dcb5;
}
.deleted_item{
    background-color: #fda1a1;
}
.with_no_time_out{
    background-color: #6c99fa;
}
.rest_day_schedule{
    background-color: #28ffe2;
}
.no_record{
    background-color: #c990ff;
}
.leave_w_pay{
    background-color: #eead8e;
}
.leave_wo_pay{
    background-color: #ff92d5;
}
</style>
