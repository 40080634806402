<template>
    <div>
        <v-app id="load_bar" v-if="!loaded">
            <v-text-field color="info" loading disabled></v-text-field>
        </v-app>
        <v-app id="view_client_modal">
            <v-dialog v-model="stock_card_items_dialog" persistent max-width="98%" scrollable>
                <v-card>
                    <v-row class="m-0">
                        <v-card-title>
                            <span class="headline">Stock Card Details</span>
                        </v-card-title>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right" @click="clearStockCardItems(); $emit('closeView');">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card-text>
                        <v-row class="m-0">
                            <v-container fluid>
                                <v-row>
                                    <div class="col-sm-12 col-md-8 text-left text-dark">
                                        <div class="row ml-2">
                                            <div class="col-sm-12 col-md-8 p-0">
                                                SC#: {{ stock_card.stock_card_num }}
                                            </div>
                                        </div>
                                        <div class="row ml-2">
                                            <div class="col-sm-12 col-md-8 p-0">
                                                PL#: {{ stock_card.packing_list_num }}
                                            </div>
                                        </div>
                                        <div class="row ml-2">
                                            <div class="col-sm-12 col-md-8 p-0">
                                                SO#: {{ stock_card.sales_order_num }}
                                            </div>
                                        </div>
                                        <div class="row ml-2">
                                            <div class="col-sm-12 col-md-8 p-0">
                                                Customer: {{ stock_card.customer_name }}
                                            </div>
                                        </div>
                                        <div class="row ml-2">
                                            <div class="col-sm-12 col-md-8 p-0">
                                                Warehouse: {{ stock_card.warehouse_name }}
                                            </div>
                                        </div>
                                        <div class="row ml-2">
                                            <div class="col-sm-12 col-md-8 p-0">
                                                No. of Boxes: {{ stock_card.number_of_boxes }}
                                            </div>
                                        </div>
                                        <div class="row ml-2">
                                            <div class="col-sm-12 col-md-8 p-0">
                                                Preparer: {{ stock_card.preparer_name }}
                                            </div>
                                        </div>
                                        <div class="row ml-2">
                                            <div class="col-sm-12 col-md-8 p-0">
                                                Preparer Start Date: {{ stock_card.preparer_start_date }}
                                            </div>
                                        </div>
                                        <div class="row ml-2">
                                            <div class="col-sm-12 col-md-8 p-0">
                                                Preparer End Date: {{ stock_card.preparer_end_date }}
                                            </div>
                                        </div>
                                        <div class="row ml-2">
                                            <div class="col-sm-12 col-md-8 p-0">
                                                Preparer Duration: {{ stock_card.preparer_durations }}
                                            </div>
                                        </div>
                                        <div class="row ml-2">
                                            <div class="col-sm-12 col-md-8 p-0">
                                                Checker: {{ stock_card.checker_name }}
                                            </div>
                                        </div>
                                        <div class="row ml-2">
                                            <div class="col-sm-12 col-md-8 p-0">
                                                Checker Start Date: {{ stock_card.checker_start_date }}
                                            </div>
                                        </div>
                                        <div class="row ml-2">
                                            <div class="col-sm-12 col-md-8 p-0">
                                                Checker End Date: {{ stock_card.checker_end_date }}
                                            </div>
                                        </div>
                                        <div class="row ml-2">
                                            <div class="col-sm-12 col-md-8 p-0">
                                                Checker Duration: {{ stock_card.checker_durations }}
                                            </div>
                                        </div>
                                    </div>
                                </v-row>
                            </v-container>
                        </v-row>

                        <v-data-table :headers="stockCardItemsHeaders" :items="stockCardItems" :items-per-page="500"
                            hide-default-footer class="w-100">
                            <template v-slot:[`item.packing_list_qty`]="{ item }">
                                {{ thousandSeprator(item.packing_list_qty) }}
                            </template>
                            <template v-slot:[`item.packing_list_qty_served`]="{ item }">
                                {{ thousandSeprator(stock_card.status == 0 ? item.packing_list_qty_served : item.packing_list_qty - item.pending_pl_qty + item.quantity_served) }}
                            </template>
                            <template v-slot:[`item.packing_list_qty_remaining`]="{ item }">
                                <!-- {{ thousandSeprator(stock_card.status == 0 ? item.pending_pl_qty : item.packing_list_qty_remaining ) }} -->
                                <!-- {{ thousandSeprator(item.packing_list_qty - item.packing_list_qty_served - item.quantity_served)}} -->
                                {{ thousandSeprator(stock_card.status == 0 ? item.packing_list_qty_remaining : item.pending_pl_qty - item.quantity_served)}}
                            </template>
                            <template v-slot:[`item.quantity_served`]="{ item }">
                                {{ thousandSeprator(item.quantity_served) }}
                            </template>
                        </v-data-table>

                        <v-row class="m-0">
                            <v-container fluid>
                                <v-row>
                                    <div class="col-sm-12 col-md-8 text-left">
                                        <div class="card-body py-4 d-flex">
                                            <span class="m-1">
                                                <v-btn v-if="enableButton" @click="print_no_price = true; stock_card_print = true;">Print SC
                                                </v-btn>
                                            </span>
                                        </div>
                                    </div>
                                </v-row>
                            </v-container>
                        </v-row>
                        <div class="border-top">
                            <div class="card-body py-4 d-flex justify-content-center" v-if="stock_card.status == 0">
                                <span class="m-1">
                                    <v-btn @click="updateStockCardStatus(1);" :loading="button_loading">Confirm</v-btn>
                                </span>
                            </div>
                        </div>
                    </v-card-text>

                    <stock-card-print-component v-if="stock_card_print" :stock_card="stock_card" :stockCardId="this.viewStockCardId"
                        :stockCardItems="stockCardItems" :companyDetails="companyDetails"></stock-card-print-component>
                </v-card>
            </v-dialog>
        </v-app>
    </div>
</template>
<script>
import SharedFunctionsComponent from "@/views/main/Shared/SharedFunctionsComponent.vue";
import swal from "sweetalert2";
import { mapGetters } from "vuex";
export default {
    mixins: [SharedFunctionsComponent],
    props:{
        viewStockCardId: {
            default: () => 0,
            type: Number,
        },
        enableButton: {
            default: () => true,
            type: Boolean,
        },
    },
    components: {
        StockCardPrintComponent: () =>
            import("@/views/main/modules/Operations/StockCards/StockCardPrintComponent.vue"),
    },
    data() {
        return {
            stockCardItems: [],

            stockCardItemsHeaders: [
                {
                    text: "Model",
                    align: "left",
                    value: "model",
                    width: "15%",
                    sortable: false,
                },
                {
                    text: "Name",
                    align: "left",
                    value: "name",
                    width: "35%",
                    sortable: false,
                },
                {
                    text: "Type",
                    align: "left",
                    value: "item_type_text",
                    sortable: false,
                },
                { text: "UOM", align: "left", value: "item_uom_text", sortable: false },
                {
                    text: "PL Qty",
                    align: "center",
                    value: "packing_list_qty",
                    sortable: false,
                },
                {
                    text: "PL Qty Served",
                    align: "center",
                    value: "packing_list_qty_served",
                    sortable: false,
                },
                {
                    text: "PL Qty Remaining",
                    align: "center",
                    value: "packing_list_qty_remaining",
                    sortable: false,
                },
                {
                    text: "Qty to Ship",
                    align: "center",
                    value: "quantity_served",
                    sortable: false,
                },
            ],

            loaded: true,
            stock_card_items_dialog: false,
            stock_card: "",
            discounts: [],
            itemTypeDiscounts: [],
            itemTypeDiscountsPDF: [],
            type_list: [],
            errors: [],

            stock_card_print: false,
            button_loading:false,
            companyDetails:{},
        };
    },
    methods: {
        updateStockCardStatus(newStatus) {

            let warning = '';


            if (!this.stock_card.preparer_name) warning += 'Must have a preparer.<br>';
            if (!this.stock_card.preparer_start_date) warning += 'Must have a preparer start date & time.<br>';
            if (!this.stock_card.preparer_end_date) warning += 'Must have a preparer end date & time.<br>';
            if (!this.stock_card.preparer_durations) warning += 'Must have a preparer duration.<br>';

            if (!this.stock_card.checker_name) warning += 'Must have a checker.<br>';
            if (!this.stock_card.checker_start_date) warning += 'Must have a checker start date & time.<br>';
            if (!this.stock_card.checker_end_date) warning += 'Must have a checker end date & time.<br>';
            if (!this.stock_card.checker_durations) warning += 'Must have a checker duration.<br>';
            if (this.stock_card.number_of_boxes == 0) warning += 'The number of boxes must not be zero.<br>';

            if (warning !== '') return swal.fire({
                title: 'Must meet the following before confirming:',
                html: warning,
                icon: "warning",
            });

            this.errors = [];
            this.button_loading = true
            swal.fire({
                title: "",
                text: "Are you sure you want to update Stock Card?",
                icon: 'question',
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                allowOutsideClick: false,
                confirmButtonColor: '#397373',
                cancelButtonColor: 'grey',
                confirmButtonText: 'Confirm',
                showCloseButton: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    let params = {
                        url: "inventory/updateStockCardStatus/" + this.viewStockCardId,
                        status: newStatus,
                    };
                    this.$store.dispatch("StockCardsPost", params).then((response) => {
                        let data = response.data;

                        if (data.error) {
                            swal.fire("", data.error, "warning");
                            return false;
                        }
                        this.resetFields();
                        swal.fire("", data.msg, "success");
                        this.stock_card_items_dialog = false;
                        this.$emit("closeView", "updateStatusSuccess");
                        this.button_loading = false
                    });
                }
                else{
                    this.button_loading = false
                }
            });
        },
        clearStockCardItems() {
            this.resetFields();
            this.stock_card_items_dialog = false;
            this.$emit("closeView", "updateStatusSuccess");
        },
        resetFields() {
            this.stockCardItems = [];
            this.stock_card = "";
            this.discounts = [];
            this.itemTypeDiscounts = [];
            this.itemTypeDiscountsPDF = [];
            this.printed = false;
            this.override_print_dialog = false;
        },
        getItemTypes() {
            this.$store.dispatch('getItemTypes').then((response) => {
                this.type_list = response.data;
            });
        },
    },
    watch: {
        viewStockCardId: function (val) {
            if (val != -1) {
                if (this.viewStockCardId != null && this.viewStockCardId > 0) {
                    let parameter = {
                        from_module: 'stock card',
                        url: "inventory/viewStockCard/" + this.viewStockCardId,
                    }
                    this.$store.dispatch("StockCardsGet", parameter).then((response) => {
                        let data = response.data;

                        if (data.error) {
                            swal.fire("", data.error, "warning");
                            return false;
                        }

                        this.stock_card = data.stock_card;
                        this.stockCardItems = data.stock_card.stock_card_items;
                        this.getItemTypes();
                        this.stock_card_items_dialog = true;
                    }).catch((error) => { });
                }
            }
        },
        stock_card_print: function (val) {
            if (val) {
                setTimeout(() => {
                    this.stock_card_print = false;
                }, 1000);
            }
        },
        GET_SYSTEM: {
            handler(val){
                if(Object.keys(val).length > 0) {
                    switch (val.code) {
                        case 'ING':
                            this.getCompanyDetails(['Sales'], 'ING').then( result => {
                                this.companyDetails = result[0];
                            })
                            break;
                        case 'WAD':
                            this.getCompanyDetails(['Sales'], 'WAD').then(result => {
                                this.companyDetails = result[0];
                            })
                            break;
                    }
                }
            }
        },
    },
    computed: {
        no_of_items: function () {
            if (this.stockCardItems != null && this.stockCardItems.length > 0) {
                var count = 0;
                this.stockCardItems.forEach((item) => {
                    count += item.quantity_served;
                });
                return this.thousandSeprator(count);
            } else {
                return 0;
            }
        },
        no_of_warranties: function () {
            if (this.stockCardItems != null && this.stockCardItems.length > 0) {
                var count = 0;
                this.stockCardItems.forEach((item) => {
                    if (item.view_item.type_id == 1) {
                        count += item.quantity_served;
                    }
                });
                return this.thousandSeprator(count);
            } else {
                return 0;
            }
        },
        ...mapGetters([
            'GET_SYSTEM',
        ])
    },
    mounted() {
        this.getSystemPlatform();
    }
};
</script>
<style>
.v-avatar {
    margin: 15px 50px !important;
}

#client_modal_card {
    min-height: 160px !important;
}
</style>
