
import ServiceStockTransfer from "@/views/main/modules/Operations/StockTransferNew/InventoryTransferComponent.vue";
import repository from "@/api/credentials";


const routes = 
        [{

              path: "/services/service-stock-transfer/:id",
              name: "servicestocktransfer",
              component: ServiceStockTransfer,
              beforeEnter:(to,from,next)=>{
                repository.checkAccessUser(to.params.id).then((response)=>{
                    if(response.data == 'fail'){
                        next('/no-access')
                        }else{
                        next()
                    }
                }).catch(()=>{

                })

          },
              meta:{
                   requiresAuth:true,
                   service:1,
                 }
          }
      ]


export default routes;
