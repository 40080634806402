<template>
	<v-form ref="form" v-model="valid" lazy-validation>
	<v-card>
		<v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom mt-5">
			<h5>{{dialog_title}}</h5>
		<v-row class="m-0">
			<v-col cols="pull-right-10 p-2">
			<v-btn
				text
				icon
				small
				color="gray"
				class="float-right"
				@click="closeDialog()"
			>
				<v-icon>mdi-close-circle</v-icon>
			</v-btn>
			</v-col>
		</v-row>
		</v-card-title>
		<v-container class="overflow-auto">
			<v-row class="mt-2 mb-2 pl-6">
				<h6>Customer Details</h6>
			</v-row>
			<v-row class="ma-2">
				<v-col cols="6">
					<v-text-field
						v-model="maps.customer_name"
						dense
						outlined
						hide-details
						label="Customer Name"
						:disabled="isDisabled">
					</v-text-field>
				</v-col>
				<v-col cols="6">
					<v-text-field
						v-model="maps.customer_code"
						dense
						outlined
						hide-details
						label="Customer Code"
						:disabled="isDisabled">
					</v-text-field>
				</v-col>
			</v-row>
			<v-row class="ma-2">
				<v-col cols="6">
					<v-autocomplete
						:items="GET_CUSTOMERS_LIST_SELECTION"
						:loading="loading.customer_selection"
						:disabled="loading.customer_selection || isDisabled"
						v-model="customer.selected_id"
						outlined
						dense
                        auto-select-first
						label="Related Customer"
						@change="selectCustomer()"
						>
					</v-autocomplete>
				</v-col>
				<v-col cols="6">
					<v-autocomplete
						:items="GET_CLASSIFICATIONS_SELECTION"
						v-model="customer.selected_classifications"
						outlined
						dense
						label="Customer Classification"
						>
					</v-autocomplete>
				</v-col>
			</v-row>
			<v-row class="mt-2 mb-2 pl-6">
				<h6>Location Details</h6>
			</v-row>
			<v-row class="ma-2">
				<v-col cols="12">
					<v-textarea
						v-model="maps.location.address"
						dense
						outlined
						auto-grow
						rows="2"
						label="Address"
						:disabled="isDisabled"
					></v-textarea>
				</v-col>
			</v-row>
			<v-row class="ma-2">
				<v-col cols="3">
					<v-text-field
						v-model="maps.location.longitude"
						dense
						outlined
						hide-details
						label="Longitude"
						:disabled="isDisabled">
					</v-text-field>
				</v-col>
				<v-col cols="3">
					<v-text-field
						v-model="maps.location.latitude"
						dense
						outlined
						hide-details
						label="Latitude"
						:disabled="isDisabled">
					</v-text-field>
				</v-col>
				<v-col cols="6">
					<v-text-field
						v-model="maps.location.data"
						dense
						outlined
						hide-details
						label="Data"
						:disabled="isDisabled">
					</v-text-field>
				</v-col>
			</v-row>
		</v-container>
		<v-card-title class="text-center border">
		<v-row class="ma-1">
			<v-col cols="12">
			<v-btn v-if="actions != 'View'"
				class="float-center"
				@click="actions == 'Submit' ? addNewCustomerMap() : updateCustomerMap()"
			>
				<span>{{ actions }}</span>
			</v-btn>
			</v-col>
		</v-row>
		</v-card-title>
	</v-card>
	</v-form>
</template>
	<script>
	import buttons from "../../Buttons/SubdialogButton.vue";
	import buttons_components from "../../../../../assets/js/components-js.js";
	import { mapGetters } from "vuex";
	import Swal from "sweetalert2";

	export default {
		mixins: [buttons_components],
		components: { buttons, buttons_components },
		data() {
			return {
				valid: true,
				buttons: buttons_components.buttons,
				maps:{
					customer_name:null,
					customer_code:null,
					location:{
						longitude:0,
						latitude:0,
						data:null,
						address:null
					},
				},
				customer:{
					selected_id:null,
					selected_classifications:null
				},
				selected_customer_map_id:0,
				dialog_title:null,
				isDisabled:false,

				loading:{
					customer_selection	: true
				}
			};
		},
		mounted(){
            this.$store.dispatch('getAllCustomers')
            this.$store.dispatch('getAllcustomerClassificationSelection')
		},
		methods:{
			closeDialog(){
				this.$store.commit("DIALOG", false);
				this.customer.selected_id = null
				this.customer.selected_classifications = null
			},
			selectCustomer(){
				let data = this.GET_CUSTOMERS_LIST_SELECTION
				data.forEach(e=>{
					if(e.value == this.customer.selected_id){
						this.customer.selected_classifications = e.classification_id
					}
				})
			},
			addNewCustomerMap(){
				let payload = {
					customer_name:		this.maps.customer_name,
					customer_code:		this.maps.customer_code,
					customer_id:		this.customer.selected_id,
					classification_id:	this.customer.selected_classifications,
					latitude:			this.maps.location.latitude,
					longitude:			this.maps.location.longitude,
					data_location:		this.maps.location.data,
					address:			this.maps.location.address,
					url:				'addCustomerMap',
					method:				'Create',
				}
				this.$store.dispatch('postCustomerMaps',payload).then((response=>{
					this.$store.commit('DIALOG', false);
					Swal.fire({
						title: 'Success',
						icon: 'success',
						timer: 1500,
					});
				}))
			},
			updateCustomerMap(){
				let payload = {
					customer_name:		this.maps.customer_name,
					customer_code:		this.maps.customer_code,
					customer_id:		this.customer.selected_id,
					classification_id:	this.customer.selected_classifications,
					latitude:			this.maps.location.latitude,
					longitude:			this.maps.location.longitude,
					data_location:		this.maps.location.data,
					address:		    this.maps.location.address,
					url:				'addCustomerMap',
					method:				'Edit',
					id:					this.selected_customer_map_id
				}
				this.$store.dispatch('postCustomerMaps',payload).then((response=>{
					this.$store.commit('DIALOG', false);
					Swal.fire({
						title: 'Success',
						icon: 'success',
						timer: 1500,
					});
				}))
			},
			resetFields(){
				this.maps = {
					customer_name:null,
					customer_code:null,
					location:{
						longitude:null,
						latitude:null,
						data:null,
						address:null
					},
				}
				this.customer = {
					selected_id:null,
					selected_classifications:null
				}
			}
		},
		computed: {
			...mapGetters([
				"ACTION",
				"DIALOGS",
				"USERACCOUNT_LOAD",
				"GET_CLASSIFICATIONS_SELECTION",
				"GET_CUSTOMERS_LIST_SELECTION",
				"GET_NEW_MARKER",
				"SELECTED_CUSTOMER_MAPS"
			]),
			actions() {
				if (this.ACTION == "Submit") {
					this.resetFields()
					this.dialog_title = "Add Map Marker"
					this.isDisabled = false
					return "Submit";
				}
				else if (this.ACTION == "Update"){
					this.isDisabled = false
					this.maps = {
						customer_name:this.SELECTED_CUSTOMER_MAPS.customer_name,
						customer_code:this.SELECTED_CUSTOMER_MAPS.customer_code,
						location:{
							longitude:Number(this.SELECTED_CUSTOMER_MAPS.longitude),
							latitude:Number(this.SELECTED_CUSTOMER_MAPS.latitude),
							data:this.SELECTED_CUSTOMER_MAPS.data_location,
							address:this.SELECTED_CUSTOMER_MAPS.address
						},
					}
					this.customer = {
						selected_id:this.SELECTED_CUSTOMER_MAPS.customer_id,
						selected_classifications:this.SELECTED_CUSTOMER_MAPS.classification_id
					}
					this.selected_customer_map_id = this.SELECTED_CUSTOMER_MAPS.id
					this.dialog_title = "Edit Map Marker"
					return "Update"
				}
				else if(this.ACTION == "View"){
					this.isDisabled = true
					this.maps = {
						customer_name:this.SELECTED_CUSTOMER_MAPS.customer_name,
						customer_code:this.SELECTED_CUSTOMER_MAPS.customer_code,
						location:{
							longitude:Number(this.SELECTED_CUSTOMER_MAPS.longitude),
							latitude:Number(this.SELECTED_CUSTOMER_MAPS.latitude),
							data:this.SELECTED_CUSTOMER_MAPS.data_location,
							address:this.SELECTED_CUSTOMER_MAPS.address
						},
					}
					this.customer = {
						selected_id:this.SELECTED_CUSTOMER_MAPS.customer_id,
						selected_classifications:this.SELECTED_CUSTOMER_MAPS.classification_id
					}
					this.selected_customer_map_id = this.SELECTED_CUSTOMER_MAPS.id
					this.dialog_title = "Customer Code: "+this.SELECTED_CUSTOMER_MAPS.customer_code
					return "View"
				}
			},
		},
		watch: {
			DIALOGS: {
				handler(val) {
					if (val) {
					}
				},
			},
			GET_NEW_CUSTOMER_MAPS: {
				handler(val) {
					if(val){
						this.$store.dispatch('getAllClassification')
						this.$store.commit('NEW_CUSTOMER_MAPS', false)
					}
				}
			},
			'GET_CUSTOMERS_LIST_SELECTION':{
				handler(val){
					this.loading.customer_selection = false
				},
            }
		},
	};
	</script>
	<style lang="scss">
	div ::v-deep .v-label.v-label--active.theme--light {
	left: 0 !important;
	right: auto !important;
	}
	div ::v-deep .v-input__slot fieldset legend {
	margin-left: 10px !important;
	width: auto;
	}
	</style>
