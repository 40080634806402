<template>
    <div class="page-wrapper">
        <div class="row">
            <div class="col-12 d-flex no-block align-items-center mt-2 mb-2">
                <div class="btn-toolbar">
                    <v-btn v-on:click="resetSelections();resetEdited();toggleDialog(`warehouse`);">
                        <i class="fas fa-plus mr-1"></i>Create Template
                    </v-btn>
                </div>
            </div>
        </div>

        <!-- ITEM SELECTION DIALOG -->
        <v-dialog v-model="itemSelection.dialog" scrollable persistent max-width="1280px" style="z-index: 80;">
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="text-lg font-semibold" v-text="`Add Items to Template`"></span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="toggleDialog(`itemSelection`)">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-progress-linear
                    v-if="itemSelection.loading"
                    indeterminate
                    color="green"
                ></v-progress-linear>
                <v-row dense no-gutters tag="div" class="pl-4">
                    <v-col sm="3">
                        <v-autocomplete
                            v-model="itemSelection.datatable.filters.uom_id.selected"
                            :items="itemSelection.datatable.filters.uom_id.selection"
                            dense
                            hide-details
                            multiple
                            placeholder="Filter UOM"
                            class="text-md text-gray-700"
                            style="font-size: 0.8rem !important">
                            <template v-slot:selection="{ item, index }">
                                <v-chip x-small v-if="index <= 1">
                                    <span v-text="item.text"></span>
                                </v-chip>
                                <span v-if="index === 2" class="caption" v-text="`+ ${itemSelection.datatable.filters.uom_id.selected.length - 2} more` "></span>
                            </template>
                            <template v-slot:prepend-item>
                                <v-row align="center" dense no-gutters tag="div" class="d-flex px-3 pb-1">
                                    <div>
                                        <v-icon
                                            @click="toggleFilterSelection(itemSelection.datatable.filters.uom_id)"
                                            :color="itemSelection.datatable.filters.uom_id.selected.length > 0 ? 'indigo darken-4' : ''">
                                            {{ filterSelectionIcon(itemSelection.datatable.filters.uom_id.selection, itemSelection.datatable.filters.uom_id.selected) }}
                                        </v-icon>
                                    </div>
                                    <div>
                                        <input
                                            @input="searchFilterSelection(itemSelection.datatable.filters.uom_id)"
                                            v-model="itemSelection.datatable.filters.uom_id.search"
                                            type="text"
                                            placeholder="Search UOM"
                                            style="text-align: left; font-size: .8rem !important; outline: none; height: 1.5rem !important;"
                                            class="form-control" />
                                    </div>
                                </v-row>
                                <v-divider class="m-0 p-0"></v-divider>
                            </template>
                            <template v-slot:item="{ active, item, attrs, on }">
                                <v-list-item dense v-on="on" v-bind="attrs" #default="{ active }">
                                    <v-row dense no-gutters align="center">
                                        <input class="mr-1" type="checkbox" :checked="active" />
                                        <div class="caption" v-text="item.text"></div>
                                    </v-row>
                                </v-list-item>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col sm="3">
                        <v-autocomplete
                            v-model="itemSelection.datatable.filters.category_id.selected"
                            :items="itemSelection.datatable.filters.category_id.selection"
                            dense
                            hide-details
                            multiple
                            placeholder="Filter Category"
                            class="text-md text-gray-700"
                            style="font-size: 0.8rem !important">
                            <template v-slot:selection="{ item, index }">
                                <v-chip x-small v-if="index <= 0">
                                    <span v-text="item.text"></span>
                                </v-chip>
                                <span v-if="index === 1" class="caption" v-text="`+ ${itemSelection.datatable.filters.category_id.selected.length - 1} more` "></span>
                            </template>
                            <template v-slot:prepend-item>
                                <v-row align="center" dense no-gutters tag="div" class="d-flex px-3 pb-1">
                                    <div>
                                        <v-icon
                                            @click="toggleFilterSelection(itemSelection.datatable.filters.category_id)"
                                            :color="itemSelection.datatable.filters.category_id.selected.length > 0 ? 'indigo darken-4' : ''">
                                            {{ filterSelectionIcon(itemSelection.datatable.filters.category_id.selection, itemSelection.datatable.filters.category_id.selected) }}
                                        </v-icon>
                                    </div>
                                    <div>
                                        <input
                                            @input="searchFilterSelection(itemSelection.datatable.filters.category_id)"
                                            v-model="itemSelection.datatable.filters.category_id.search"
                                            type="text"
                                            placeholder="Search Category"
                                            style="text-align: left; font-size: .8rem !important; outline: none; height: 1.5rem !important;"
                                            class="form-control" />
                                    </div>
                                </v-row>
                                <v-divider class="m-0 p-0"></v-divider>
                            </template>
                            <template v-slot:item="{ active, item, attrs, on }">
                                <v-list-item dense v-on="on" v-bind="attrs" #default="{ active }">
                                    <v-row dense no-gutters align="center">
                                        <input class="mr-1" type="checkbox" :checked="active" />
                                        <div class="caption" v-text="item.text"></div>
                                    </v-row>
                                </v-list-item>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col sm="3">
                        <v-autocomplete
                            v-model="itemSelection.datatable.filters.type_id.selected"
                            :items="itemSelection.datatable.filters.type_id.selection"
                            dense
                            hide-details
                            multiple
                            placeholder="Filter Type"
                            class="text-md text-gray-700"
                            style="font-size: 0.8rem !important">
                            <template v-slot:selection="{ item, index }">
                                <v-chip x-small v-if="index <= 2">
                                    <span v-text="item.text"></span>
                                </v-chip>
                            </template>
                            <template v-slot:prepend-item>
                                <v-row align="center" dense no-gutters tag="div" class="d-flex px-3 pb-1">
                                    <div>
                                        <v-icon
                                            @click="toggleFilterSelection(itemSelection.datatable.filters.type_id)"
                                            :color="itemSelection.datatable.filters.type_id.selected.length > 0 ? 'indigo darken-4' : ''">
                                            {{ filterSelectionIcon(itemSelection.datatable.filters.type_id.selection, itemSelection.datatable.filters.type_id.selected) }}
                                        </v-icon>
                                    </div>
                                    <div>
                                        <input
                                            @input="searchFilterSelection(itemSelection.datatable.filters.type_id)"
                                            v-model="itemSelection.datatable.filters.type_id.search"
                                            type="text"
                                            placeholder="Search Type"
                                            style="text-align: left; font-size: .8rem !important; outline: none; height: 1.5rem !important;"
                                            class="form-control" />
                                    </div>
                                </v-row>
                                <v-divider class="m-0 p-0"></v-divider>
                            </template>
                            <template v-slot:item="{ active, item, attrs, on }">
                                <v-list-item dense v-on="on" v-bind="attrs" #default="{ active }">
                                    <v-row dense no-gutters align="center">
                                        <input class="mr-1" type="checkbox" :checked="active" />
                                        <div class="caption" v-text="item.text"></div>
                                    </v-row>
                                </v-list-item>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col sm="3">
                        <v-row dense no-gutters tag="form" class="d-flex" @submit.prevent="resetItemSelection();getItemSelection()">
                            <v-text-field
                                dense
                                autocomplete="off"
                                v-model="itemSelection.datatable.search"
                                label="Search Name/Model"
                                single-line
                                hide-details>
                            </v-text-field>
                            <v-icon @click="resetItemSelection();getItemSelection()">mdi-magnify</v-icon>
                        </v-row>
                    </v-col>
                </v-row>

                <v-card-text style="max-height: 460px; border:1px solid #e0e0e0; padding: 0;">
                    <v-data-table
                        v-model="itemSelection.selected"
                        :headers="itemSelection.datatable.headers"
                        :items="itemSelection.data"
                        :options.sync="itemSelection.datatable.pagination.options"
                        :items-per-page="itemSelection.datatable.pagination.perPageDefault"
                        :footer-props="{
                            showFirstLastPage: true,
                            itemsPerPageOptions: [5, 10, 25, 250, 500, 1000]
                        }"
                        :server-items-length="itemSelection.datatable.pagination.total"
                        :single-select="false"
                        item-key="id"
                        show-select
                        dense
                        style="border:1px solid #e0e0e0; margin: 0;">
                    </v-data-table>
                </v-card-text>

                <v-card-actions class="d-flex justify-end">
                    <v-btn
                        small
                        v-on:click="addAllFilterResult()">
                        <i class="fas fa-plus mr-1"></i>
                        Add All Filter Result
                    </v-btn>
                    <v-btn
                        small
                        v-on:click="addSelectedItem(itemSelection.selected)">
                        <i class="fas fa-plus mr-1"></i>
                        Add Selected
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- WAREHOUSE PICKER DIALOG -->
        <v-dialog v-model="warehouse.dialog" scrollable persistent max-width="770px" style="z-index: 70;">
            <v-card>
                <v-progress-linear
                    v-if="loading.state"
                    indeterminate
                    color="green"
                ></v-progress-linear>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="text-lg font-semibold" v-text="`Create Item Template`"></span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="toggleDialog(`warehouse`);resetEdited();errors = [];">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-card-text style="max-height: 460px; border:1px solid #e0e0e0; padding: 0;">
                    <v-row v-if="loading.state" tag="div" align="center" no-gutters class="px-2" v-text="loading.title">
                    </v-row>
                    <v-row v-if="!loading.state" tag="div" align="center" no-gutters class="px-2">
                        <v-col class="d-flex pr-2" cols="6">
                            <v-row dense no-gutters tag="div">
                                <v-col dense tag="div" sm="12" class="d-flex justify-space-between">
                                    <div class="font-weight-bold" v-text="`Warehouse`"></div>
                                </v-col>
                                <v-col dense tag="div" sm="12">
                                    <v-autocomplete
                                        hide-details
                                        item-text="text"
                                        item-value="value"
                                        :items="warehouse.selection"
                                        v-model="warehouse.value"
                                        dense
                                        outlined
                                        flat
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col class="d-flex pr-2" cols="6">
                            <v-row dense no-gutters tag="div">
                                <v-col dense tag="div" sm="12" class="d-flex justify-space-between">
                                    <div class="font-weight-bold" v-text="`Template Name`"></div>
                                </v-col>
                                <v-col dense tag="div" sm="12" class="ml-2">
                                    <input
                                        style="border:1px solid #e0e0e0;"
                                        type="text"
                                        class="form-control"
                                        placeholder="Template Name"
                                        v-model="editedItemTemplate.name">
                                </v-col>
                            </v-row >
                        </v-col>

                        <v-col cols="12">
                            <div v-for="error in errors" class="red--text mx-1" v-bind:key="error" v-text="error"></div>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions class="d-flex justify-end">
                    <v-btn
                        small
                        :disabled="loading.state"
                        v-on:click="createItemTemplateFor(warehouse.value, editedItemTemplate.name)">
                        <i class="fas fa-plus mr-1"></i>
                        Create
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- ITEM TEMPLATE DIALOG -->
        <v-dialog v-model="editedItemTemplate.dialog" scrollable persistent max-width="970px" style="z-index: 50; position:relative;">
            <div v-if="itemSelection.dialog" style="background-color: rgba(47,47,47,0.5); position:absolute; width:100%; height:100%; z-index: 60;"></div>
            <form>
                <v-card>
                    <v-row class="m-0">
                        <v-card-title>
                            <span class="text-lg font-semibold" v-text="`${editedItemTemplate.template_number || ``}`"></span>
                        </v-card-title>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right" @click="toggleDialog(`editedItemTemplate`); resetEdited();">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-progress-linear
                        v-if="loading.state || editedItemTemplate.details.loading"
                        indeterminate
                        color="green"
                    ></v-progress-linear>
                    <v-card-text style="max-height: 760px; border:1px solid #e0e0e0; padding: 10px;">
                        <v-row tag="div" align="center" no-gutters>
                            <v-col class="d-flex pr-2" cols="3">
                                <v-row dense no-gutters tag="div">
                                    <v-col dense tag="div" sm="12" class="d-flex justify-space-between">
                                        <div class="font-weight-bold" v-text="`Warehouse`"></div>
                                    </v-col>
                                    <v-col dense tag="div" sm="12">
                                        <v-autocomplete
                                            :disabled="true"
                                            hide-details
                                            item-text="text"
                                            item-value="value"
                                            :items="warehouse.selection"
                                            v-model="warehouse.value"
                                            dense
                                            outlined
                                            flat
                                        ></v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-col>

                            <v-col class="d-flex pr-2 ml-3" cols="6">
                                <v-row dense no-gutters tag="div">
                                    <v-col dense tag="div" sm="12" class="d-flex justify-space-between">
                                        <div class="font-weight-bold" v-text="`Template Name`"></div>
                                    </v-col>
                                    <v-col dense tag="div" sm="12">
                                        <input
                                            :disabled="loading.state || viewOnly"
                                            style="border:1px solid #e0e0e0;"
                                            type="text"
                                            class="form-control"
                                            placeholder="Template Name"
                                            v-model="editedItemTemplate.name">
                                    </v-col>
                                </v-row >
                            </v-col>
                        </v-row>

                        <v-divider></v-divider>

                        <v-data-table
                            :headers="editedItemTemplate.details.datatable.headers"
                            :items="editedItemTemplate.details.datatable.data"
                            :options.sync="editedItemTemplate.details.datatable.pagination.options"
                            :items-per-page="editedItemTemplate.details.datatable.pagination.perPageDefault"
                            :footer-props="{
                                    showFirstLastPage: true,
                                    itemsPerPageOptions: [5, 10, 25, 250, 500]
                                }"
                            :server-items-length="editedItemTemplate.details.datatable.pagination.total"
                            dense
                            style="border:1px solid #e0e0e0; margin: 0;">
                            <template v-slot:item.action="{item}">
                                <v-icon small color="red" @click="removeItemTemplateDetails(item)">mdi-delete</v-icon>
                            </template>
                        </v-data-table>
                        <v-row dense no-gutters tag="div" class="pt-1">
                            <v-btn
                                v-if="editedIndex >= 0 && editedIndex != null && !viewOnly"
                                small
                                v-on:click="pickItems()"
                                :disabled="loading.state || editedItemTemplate.details.loading"
                                :loading="loading.state || editedItemTemplate.details.loading"
                                class="mr-1">
                                <i class="fas fa-plus mr-1"></i>Add Item/s
                            </v-btn>
                            <v-btn
                                v-if="editedIndex >= 0 && editedIndex != null && !viewOnly"
                                small
                                v-on:click="removeItemTemplateDetails()"
                                :disabled="loading.state || editedItemTemplate.details.loading"
                                :loading="loading.state || editedItemTemplate.details.loading"
                                class="mr-1">
                                <i class="fas fa-trash mr-1"></i>Clear Item/s
                            </v-btn>
                        </v-row>
                    </v-card-text>

                    <v-card-actions class="d-flex justify-space-between">
                        <div></div>
                        <div>
                            <v-btn
                                v-if="editedIndex >= 0 && editedIndex != null && !viewOnly"
                                :disabled="loading.state || editedItemTemplate.details.loading"
                                :loading="loading.state || editedItemTemplate.details.loading"
                                small
                                v-on:click="update()">
                                <i class="fas fa-save mr-1"></i>Save
                            </v-btn>
                        </div>
                    </v-card-actions>
                </v-card>
            </form>
        </v-dialog>

        <!-- ITEM TEMPLATE LIST -->
        <v-row no-gutters dense tag="div">
            <v-card class="w-100">
                <v-progress-linear
                    v-if="loading.state"
                    indeterminate
                    color="green"
                ></v-progress-linear>
                <v-row dense no-gutters tag="div" class="px-2 pt-2">
                    <v-col sm="3">
                    </v-col>
                    <v-col sm="3">
                    </v-col>
                    <v-col sm="3">
                    </v-col>
                    <v-col sm="3">
                        <v-row dense no-gutters tag="form" class="d-flex" @submit.prevent="list()">
                            <v-text-field
                                dense
                                autocomplete="off"
                                v-model="itemTemplate.datatable.search"
                                label="Search Template Number"
                                single-line
                                hide-details>
                            </v-text-field>
                            <v-icon @click="list()">mdi-magnify</v-icon>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row no-gutters dense tag="div" class="mt-2 px-2">
                    <v-col>
                        <v-data-table
                            :headers="itemTemplate.datatable.headers"
                            :items="itemTemplate.datatable.data"
                            :options.sync="itemTemplate.datatable.pagination.options"
                            :items-per-page="10"
                            :footer-props="{
                                showFirstLastPage: true,
                                itemsPerPageOptions: [5, 10, 25]
                            }"
                            :server-items-length="itemTemplate.datatable.pagination.total">
                            <template v-slot:item.action="{ item }">
                                <span class="text-nowrap">
                                    <v-btn text icon color="orange">
                                        <v-icon class="btn-action" small @click="view(item,true)">mdi-eye</v-icon>
                                    </v-btn>
                                    <v-btn text icon color="blue">
                                        <v-icon class="btn-action" small @click="view(item,false)">mdi-pencil</v-icon>
                                    </v-btn>
                                    <v-btn text icon color="red">
                                        <v-icon class="btn-action" small @click="destroy(item)">mdi-delete</v-icon>
                                    </v-btn>
                                </span>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card>
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
export default {
    name: "ItemTemplateComponent",

    mounted() {
        let that = this;

        that.getWarehouses();
        that.getItemTypes();
        that.getItemUom();
        that.getItemCategories();
        that.list();

    },
    computed:{
        ...mapGetters([
            'GET_ALL_WAREHOUSES',
            'GET_ITEM_TYPES',
            'GET_ITEM_UOM',
            'GET_ALL_ITEM_CATEGORIES',
            'GET_ITEM_TEMPLATE',
            'GET_ITEM_TEMPLATE_SELECTION',
            'GET_ITEM_TEMPLATE_DETAILS',
        ])
    },
    data() {
        return {
            errors: [],
            loading: {
                title: 'Please wait',
                state: false
            },
            warehouse: {
                dialog: false,
                selection: [{
                    value: null,
                    text: 'NONE SELECTED'
                }],
                value: null
            },
            viewOnly:false,
            itemSelection: {
                loading: false,
                dialog: false,
                datatable : {
                    search: '',
                    filters: {
                        type_id : {
                            search: '',
                            data: [],
                            selection: [],
                            selected: []
                        },
                        uom_id : {
                            search: '',
                            data: [],
                            selection: [],
                            selected: []
                        },
                        category_id : {
                            search: '',
                            data: [],
                            selection: [],
                            selected: []
                        },
                    },
                    headers: [
                        {text: 'MODEL', value: 'model', sortable: false},
                        {text: 'NAME', value: 'name', sortable: false},
                        {text: 'CATEGORY', value: 'item_category_text', sortable: false},
                        {text: 'TYPE', value: 'item_type_text', sortable: false},
                        {text: 'UOM', value: 'item_uom_text', sortable: false}
                    ],
                    pagination : {
                        options: {},
                        total : 0,
                        perPageDefault: 10
                    },
                },
                data : [],
                selected : []
            },
            itemTemplate: {
                datatable: {
                    search: '',
                    headers: [
                        { text: 'TEMPLATE NUMBER',  align: 'left',value: 'template_number', sortable: false},
                        { text: 'WAREHOUSE',  align: 'left',value: 'warehouse.name', sortable: false},
                        { text: 'TEMPLATE NAME',  align: 'left',value: 'name', sortable: false},
                        { text: 'ACTIONS', align: 'center', value: 'action', sortable: false },
                    ],
                    data: [],
                    pagination : {
                        options: {},
                        total : 0,
                        perPageDefault: 10
                    },
                }
            },
            editedIndex: -1,
            editedItemTemplate:{
                dialog: false,
                id: null,
                template_number: null,
                warehouse_id: null,
                warehouse: null,
                name: null,
                details: {
                    loading: false,
                    datatable : {
                        search: '',
                        headers: [
                            {text: 'MODEL', value: 'item.model', sortable: false},
                            {text: 'NAME', value: 'item.name', sortable: false},
                            {text: 'CATEGORY', value: 'item.item_category_text', sortable: false},
                            {text: 'TYPE', value: 'item.item_type_text', sortable: false},
                            {text: 'UOM', value: 'item.item_uom_text', sortable: false},
                            {text: 'ACTIONS', align: 'center', value: 'action', sortable: false },
                        ],
                        data : [],
                        pagination : {
                            options: {},
                            total : 0,
                            perPageDefault: 10
                        }
                    }
                }
            }
        }
    },

    methods: {
        selectedAllFilterModuleOptions(selection, selected) {
            return selected.length === selection.length;
        },

        selectedSomeFilterModuleOptions(selection, selected) {
            return selected.length > 0 && !this.selectedAllFilterModuleOptions(selection, selected);
        },

        filterSelectionIcon(selection, selected){
            if (this.selectedAllFilterModuleOptions(selection, selected)) return 'mdi-close-box'
            if (this.selectedSomeFilterModuleOptions(selection, selected)) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },

        toggleFilterSelection(filter) {
            this.$nextTick(() => {
                if (this.selectedAllFilterModuleOptions(filter.selection, filter.selected)) {
                    filter.selected = [];
                } else {
                    filter.selected = filter.selection.map(filter => filter.value);
                }
            })
        },

        searchFilterSelection(filter){
            if (!filter.search){
                filter.selection = filter.data;
            }

            filter.selection = filter.data.filter(data => {
                return data.text.toLowerCase().indexOf(filter.search.toLowerCase()) > -1
            })
        },

        getWarehouses(){
            let payload = {
                no_virtual:true
            }
            this.$store.dispatch('getAllWarehouses',payload).then((response)=>{
                this.warehouse.selection = [{
                    value: null,
                    text: 'NONE SELECTED'
                }].concat(this.GET_ALL_WAREHOUSES.reduce((selection, warehouse)=>{
                    selection.push({
                        value: warehouse.value,
                        text: warehouse.text
                    });

                    return selection;
                },[]));
            });
        },

        getItemTypes(){
            let that = this;
            that.$store.dispatch('getItemTypes').then((response)=>{
                let selection = that.GET_ITEM_TYPES.reduce((selection, filterItem)=>{
                    selection.push({
                        value: filterItem.value,
                        text: filterItem.text
                    });

                    return selection;
                },[]);

                that.itemSelection.datatable.filters.type_id.data = selection;
                that.itemSelection.datatable.filters.type_id.selection = selection;
                that.itemSelection.datatable.filters.type_id.selected = selection.filter(selection => ['HT','PT','SP'].indexOf(selection.text) >= 0).map(selection => selection.value);
            });
        },

        getItemUom(){
            let that = this;
            that.$store.dispatch('getItemUom').then((response)=>{
                let selection = that.GET_ITEM_UOM.data.reduce((selection, filterItem)=>{
                    selection.push({
                        value: filterItem.value,
                        text: filterItem.text
                    });

                    return selection;
                },[]);

                that.itemSelection.datatable.filters.uom_id.data = selection;
                that.itemSelection.datatable.filters.uom_id.selection = selection;
                that.itemSelection.datatable.filters.uom_id.selected = [];
            });
        },

        getItemCategories(){
            let that = this;
            that.$store.dispatch('getAllItemCategories').then((response)=>{
                let selection = that.GET_ALL_ITEM_CATEGORIES.reduce((selection, filterItem)=>{
                    selection.push({
                        value: filterItem.id,
                        text: filterItem.name
                    });

                    return selection;
                },[]);

                that.itemSelection.datatable.filters.category_id.data = selection;
                that.itemSelection.datatable.filters.category_id.selection = selection;
                that.itemSelection.datatable.filters.category_id.selected = [];
            });
        },

        toggleLoading(){
            this.loading.state = !this.loading.state;
        },

        resetSelections(){
            this.warehouse.value = null;
        },

        toggleDialog(dialogable = null){
            let that = this;

            if (that.loading.state || !dialogable || that.editedItemTemplate.details.loading) {
                return false;
            }

            dialogable = dialogable.split('.');

            if(dialogable.length === 1 ){
                that[dialogable].dialog = !that[dialogable].dialog;
            } else {

                let path = that;

                dialogable.forEach(i => {
                    path = path[i];
                });

                path.dialog = !path.dialog;
            }
        },

        resetEdited() {
            let that = this;

            if (that.loading.state || that.editedItemTemplate.details.loading) {
                return false;
            }

            that.editedIndex = -1;

            that.editedItemTemplate = Object.assign({},{
                dialog: that.editedItemTemplate.dialog,
                id: null,
                template_number: null,
                warehouse_id: null,
                warehouse: null,
                name: null,
                details: {
                    loading: false,
                    datatable : {
                        search: '',
                        headers: that.editedItemTemplate.details.datatable.headers,
                        data : [],
                        pagination : {
                            options: {},
                            total : 0,
                            perPageDefault: 10
                        }
                    }
                }
            });
        },

        list(page = 1, wait = false){
            let that = this;

            that.itemTemplate.datatable.pagination.options.page = page;

            if (that.loading.state) {
                return false;
            }

            that.toggleLoading();
            let payload = {
                    page: page,
                    itemsPerPage: that.itemTemplate.datatable.pagination.options.itemsPerPage
                        || that.itemTemplate.datatable.pagination.perPageDefault,
                    tempNumber: that.itemTemplate.datatable.search,
                }
                let promise = new Promise(resolve => {
                that.$store.dispatch('getItemTemplate',payload).then((response)=>{
                })
                .then((response)=>{resolve(response);});
            });

            if (wait) {
                that.toggleLoading();
                return promise;
            } else {
                promise.then((response)=>{
                    that.itemTemplate.datatable.data = that.GET_ITEM_TEMPLATE.item_templates.data;
                    that.itemTemplate.datatable.pagination.total = that.GET_ITEM_TEMPLATE.item_templates.total;
                    that.toggleLoading();
                });
            }

        },

        pickItems() {
            let that = this;

            that.resetItemSelection();
            that.toggleDialog(`itemSelection`);
            that.getItemSelection();
        },

        resetItemSelection(){
            let that = this;
            that.itemSelection.data = [];
            that.itemSelection.selected = [];
        },

        getItemSelection(page = 1){
            let that = this;

            that.itemSelection.datatable.pagination.options.page = page;

            if (that.itemSelection.loading || _.isNull(that.editedItemTemplate.warehouse_id)) {
                return false;
            }

            that.itemSelection.loading = true;
            let payload = {
                page: page,
                itemsPerPage: that.itemSelection.datatable.pagination.options.itemsPerPage
                    || that.itemSelection.datatable.pagination.perPageDefault,
                search: that.itemSelection.datatable.search,
                warehouse_id: [that.editedItemTemplate.warehouse_id],
                type_id: that.itemSelection.datatable.filters.type_id.selected,
                uom_id: that.itemSelection.datatable.filters.uom_id.selected,
                category_id: that.itemSelection.datatable.filters.category_id.selected
            }
            that.$store.dispatch('itemTemplateSelection',payload).then((response) => {
                that.itemSelection.data = that.GET_ITEM_TEMPLATE_SELECTION.item_selection.data;
                that.itemSelection.datatable.pagination.total = that.GET_ITEM_TEMPLATE_SELECTION.item_selection.total;
                that.itemSelection.loading = false;
            });
        },

        addAllFilterResult(){
            let that = this;

            that.itemSelection.dialog = false;

            that.editedItemTemplate.details.loading = true;

            new Promise((resolve, reject) => {
                that.$store.dispatch('itemTemplateCanEdit').then(response=>{resolve(response)}).catch(error => reject(error));
            }).then(resolved => {
                let payload = {
                    id:that.editedItemTemplate.id,
                    params:{
                        update_detail: true,
                        filter: Object.assign({},{
                            search: that.itemSelection.datatable.search,
                            warehouse_id: [that.editedItemTemplate.warehouse_id],
                            type_id: that.itemSelection.datatable.filters.type_id.selected,
                            uom_id: that.itemSelection.datatable.filters.uom_id.selected,
                            category_id: that.itemSelection.datatable.filters.category_id.selected
                        })
                    }
                }
                that.$store.dispatch('itemTemplateUpdate',payload).then(response => {
                    that.editedItemTemplate.details.loading = false;
                    that.getItemTemplateDetails();
                }).catch(error => {
                    that.editedItemTemplate.details.loading = false;
                    that.alertWarnings(error.response.data.message, error.response.data.errors);
                });

                that.resetItemSelection();
            }).catch(error => {
                that.editedItemTemplate.details.loading = false;
                that.resetItemSelection();
                that.alertWarnings(error.response.data.message, error.response.data.errors);
            });
        },

        addSelectedItem(selected){
            let that = this;

            if(!selected.length){
                swal.fire({
                    title: `No Item Selected`,
                    text: ``,
                    icon: "info",
                    dangerMode: false
                });

                return false;
            }

            that.itemSelection.dialog = false;

            that.editedItemTemplate.details.loading = true;

            new Promise((resolve, reject) => {
                that.$store.dispatch('itemTemplateCanEdit').then(response=>{resolve(response)}).catch(error => reject(error));
            }).then(resolved => {
                let payload = {
                    id:that.editedItemTemplate.id,
                    params:{
                        update_detail: true,
                        selected: selected.map(item => {return {item_id : item.id}})
                    }
                }
                that.$store.dispatch('itemTemplateUpdate',payload).then(response => {
                    that.editedItemTemplate.details.loading = false;
                    that.getItemTemplateDetails();
                }).catch(error => {
                    that.editedItemTemplate.details.loading = false;
                    that.alertWarnings(error.response.data.message, error.response.data.errors);
                });

                that.resetItemSelection();
            }).catch(error => {
                that.editedItemTemplate.details.loading = false;
                that.resetItemSelection();
                that.alertWarnings(error.response.data.message, error.response.data.errors);
            });
        },

        async createItemTemplateFor(warehouseId, templateName){
            let that = this;

            that.toggleLoading();

            await new Promise((resolve, reject) => {
                let payload = {
                    warehouse_id: warehouseId,
                    name: templateName
                }
                this.$store.dispatch('postItemTemplate',payload).then(response=>{
                    resolve(response);
                }).catch(error => reject(error));
            }).then(async resolved => {
                that.toggleLoading();
                that.toggleDialog(`warehouse`);
                that.resetSelections();
                that.errors = [];

                let createdItemTemplate = resolved.data.item_template;

                that.itemTemplate.datatable.search = createdItemTemplate.template_number.replace(' ', '');

                await that.list(1, true).then((response)=>{
                    that.itemTemplate.datatable.data = response.data.item_templates.data;
                    that.itemTemplate.datatable.pagination.total = response.data.item_templates.total;

                    that.view(createdItemTemplate);
                });
            }).catch(error => {
                that.toggleLoading();
                that.list();
                that.alertWarnings(error.response.data.message, error.response.data.errors, false);
            });
        },

        update(){
            let that = this;

            if(!that.editedItemTemplate.id){
                return false;
            }

            that.toggleLoading();

            new Promise((resolve, reject) => {
                that.$store.dispatch('itemTemplateCanEdit').then(response =>{resolve(response)}).catch(error => reject(error));
            }).then(resolved => {
                let payload = {
                    id: editedItemTemplate.id,
                    params:{
                        name:that.editedItemTemplate.name
                    }
                }
                that.$store.dispatch('itemTemplateUpdate',payload).then(response => {
                    that.toggleLoading();
                    let itemTemplate = _.find(that.itemTemplate.datatable.data, item => that.editedItemTemplate.id === item.id);
                    itemTemplate.name = that.editedItemTemplate.name;

                    swal.fire({
                        text: `${that.editedItemTemplate.template_number} Updated`,
                        icon: "info",
                        dangerMode: false
                    });
                }).catch(error => {
                    that.toggleLoading();
                    that.alertWarnings(error.response.data.message, error.response.data.errors);
                });
            }).catch(error => {
                that.toggleLoading();
                that.alertWarnings(error.response.data.message, error.response.data.errors);
            });

        },

        view(itemTemplate,viewOnly){
            let that = this;

            that.viewOnly = viewOnly

            that.resetEdited();
            let view = _.find(that.itemTemplate.datatable.data, item => itemTemplate.id === item.id);

            that.editedIndex = that.itemTemplate.datatable.data.indexOf(view);
            that.toggleDialog(`editedItemTemplate`);

            that.warehouse.value = view.warehouse_id;

            that.editedItemTemplate = Object.assign({},{
                ...that.editedItemTemplate,
                id: view.id,
                template_number: view.template_number,
                warehouse_id : view.warehouse_id,
                warehouse : view.warehouse,
                name: view.name
            });

            that.getItemTemplateDetails(1);
        },

        destroy(itemTemplate){
            swal.fire({
                title: "Confirm Delete",
                text: `${itemTemplate.template_number}`,
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((confirm) => {
                if(confirm){
                    let that = this;

                    if (that.loading.state || that.editedItemTemplate.details.loading) {
                        return false;
                    }

                    that.toggleLoading();
                    this.$store.dispatch('destroyItemTemplate',itemTemplate.id).then((response) => {
                        swal.fire({
                            text: `${itemTemplate.template_number} Deleted`,
                            icon: "info",
                            dangerMode: false
                        });
                        that.toggleLoading();
                        that.errors = [];
                        that.list();
                    }).catch((error)=>{
                        that.toggleLoading();
                        that.errors = error.response.data.errors;
                        that.alertWarnings(error.response.data.message, that.errors);
                    });
                }
            });
        },

        getItemTemplateDetails(page = 1){
            let that = this;

            that.editedItemTemplate.details.datatable.pagination.options.page = page;

            if (that.editedItemTemplate.details.loading || that.editedItemTemplate.id === null) {
                return false;
            }

            that.editedItemTemplate.details.loading = true;
            let payload = {
                id: that.editedItemTemplate.id,
                params:{
                    page: page,
                    itemsPerPage: that.editedItemTemplate.details.datatable.pagination.options.itemsPerPage
                        || that.editedItemTemplate.details.datatable.pagination.perPageDefault,
                    search: that.editedItemTemplate.details.datatable.search
                }
            }
            this.$store.dispatch('itemTemplateDetails',payload).then((response) => {
                that.editedItemTemplate.details.datatable.data = this.GET_ITEM_TEMPLATE_DETAILS.item_template_details.data;
                that.editedItemTemplate.details.datatable.pagination.total = this.GET_ITEM_TEMPLATE_DETAILS.item_template_details.total;
                that.editedItemTemplate.details.loading = false;
            });
        },

        removeItemTemplateDetails(detail = null){
            let that = this;

            if (that.editedItemTemplate.details.loading || that.editedItemTemplate.id === null) {
                return false;
            }

            let warningText = detail
                ? `Remove ${detail.item.name}`
                : `Remove ${that.editedItemTemplate.template_number} items.`;

            swal.fire({
                title: `Confirm`,
                text: warningText,
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then(async (confirm) => {
                if (confirm) {
                    await new Promise((resolve, reject) => {
                        that.$store.dispatch('itemTemplateCanEdit').then(response=>{resolve(response)}).catch(error => reject(error));
                    }).then(resolved => {
                        that.editedItemTemplate.details.loading = true;
                        let payload = {
                            id: that.editedItemTemplate.id,
                            params:{
                                id: detail ? [detail.id] : []
                            }
                        }
                        this.$store.dispatch('itemTemplateClearDetails',payload).then((response) => {
                            that.editedItemTemplate.details.loading = false;
                            that.getItemTemplateDetails();
                        });
                    }).catch(error => {
                        that.alertWarnings(error.response.data.message, error.response.data.errors);
                    });
                }
            });
        },

        alertWarnings(message, errors, alert = true){
            let that = this;
            let errorMessages = [];

            Object.keys(errors).forEach((key) => {

                errors[key].forEach(message => {
                    errorMessages.push(`- ${message}`);
                })
            });

            that.errors = errorMessages;

            if(alert){
                swal.fire({
                    title: message,
                    text: errorMessages.join("\n"),
                    icon: "warning",
                    dangerMode: true
                });
            }
        },
    },

    watch: {
        'itemTemplate.datatable.pagination.options': {
            handler() {
                this.list(this.itemTemplate.datatable.pagination.options.page)
            },
            deep: true,
        },
        'editedItemTemplate.details.datatable.pagination.options': {
            handler() {
                this.getItemTemplateDetails(this.editedItemTemplate.details.datatable.pagination.options.page)
            },
            deep: true,
        },
        'itemSelection.datatable.pagination.options': {
            handler() {
                let that = this;
                that.itemSelection.data = [];
                that.getItemSelection(that.itemSelection.datatable.pagination.options.page)
            },
            deep: true,
        }
    }
}
</script>

<style scoped>

</style>
