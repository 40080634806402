<template>
    <v-container fluid>
        <ComponentHeader class="ma-n4" :button="true" title='Bank Transaction Report' type='Accounting'></ComponentHeader>
        <v-row>
            <v-app id="my_vue_app" style="width:100%" class="mx-4 bg-bluish-gray pa-3 px-5">
                <v-row dense class="my-2">
                    <v-col sm="12" md="6">
                        <v-autocomplete
                            :items="GET_CUSTOMERS_SELECTION"
                            :loading="!GET_CUSTOMERS_SELECTION"
                            :disabled="!GET_CUSTOMERS_SELECTION"
                            v-model="filters.customers"
                            label="Select Customers"
                            @change="onSelectCustomers"
                            class="d-inline-block w-100"
                            outlined
                            dense
                            multiple
                            background-color="white"
                            small-chips
                            hide-details
                            clearable
                            deletable-chips
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-col sm="12" md="2">
                        <v-autocomplete
                            dense
                            outlined
                            :items="banks.selection"
                            v-model="banks.selected"
                            item-value="id"
                            item-text="name"
                            label="Banks"
                            background-color="white"
                            multiple
                            small-chips
                            hide-details
                            clearable
                            deletable-chips
                        />
                    </v-col>
                    <v-col sm="12" md="4" class="text-right">
                        <v-btn small class="ml-1" @click="resetFields()">Clear</v-btn>
                        <v-btn small class="ml-1" @click="renderPDF()">Print</v-btn>
                        <v-btn small class="ml-1" @click="exportToExcel()">Excel</v-btn>
                        <v-btn small class="ml-1" @click="getAllBankTransaction()">Filter</v-btn>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col sm="12" md="2" class="d-flex flex-row">
                        <v-menu
                            v-model="menu.date_from"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_from"
                                    prepend-inner-icon="mdi-calendar"
                                    outlined
                                    dense
                                    readonly
                                    v-on="on"
                                    style="display: block"
                                    background-color="white"
                                    label="Date From"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_from" @click:month="menu.date_from = false" :max="minDate"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col sm="12" md="2" class="d-flex flex-row">
                        <v-menu
                            v-model="menu.date_to"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_to"
                                    prepend-inner-icon="mdi-calendar"
                                    outlined
                                    dense
                                    readonly
                                    v-on="on"
                                    style="display: block"
                                    background-color="white"
                                    label="Date To"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_to" @click:month="menu.date_to = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-app>
        </v-row>
        <v-row class="w-100 pa-2 bg-bluish-gray ml-0">
            <v-card :class="'w-100 mt-2'">
                <v-card-text>
                    <div>
                        <v-data-table
                            :headers="transaction.headers"
                            :items="transaction.items"
                            :loading="transaction.loading"
                            :items-per-page="15"
                            item-key="period"
                            dense
                        >
                        <template v-slot:[`item.net_amount`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.net_amount | currency }}
                            </span>
                        </template>
                        </v-data-table>
                    </div>
                </v-card-text>
            </v-card>
        </v-row>
        <div style="display:none;">
            <div v-for="(data,key,index) in export_data" :key="index">
                <table :id='"export_details"+index'>
                    <thead>
                        <tr>
                            <th colspan="4" class="text-left">{{ key }}</th>
                        </tr>
                        <tr>
                            <th>DATE</th>
                            <th>CUSTOMER</th>
                            <th>AMOUNT</th>
                            <th>REMARKS</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(d, index) in data" :key="index">
                            <td>{{ d.payment_date | formatDate }}</td>
                            <td>{{ d.customer_name}}</td>
                            <td>{{ thousandSeprator(d.net_amount) | currency}} </td>
                            <td>{{ d.remarks}}</td>
                        </tr>
                        <tr>
                            <td colspan="2">Total Amount</td>
                            <td> {{ export_data[key].total | currency }}</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div >
                <table id="footer">
                    <tbody>
                        <tr>
                            <td>PREPARED BY: <br/><br/> </td>
                            <td>NOTED BY: <br/><br/></td>
                            <td>NOTED BY: <br/><br/></td>

                        </tr>
                        <tr>
                            <td>{{ USERACCOUNT_LOAD.name | uppercase }}</td>
                            <td>JESSICA REAGO DEMETRIO</td>
                            <td>STEVEN ANDREW S. YANG</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue';
export default {
    data() {
        return {
            payments:{
                selected: '',
                selection: [],
            },
            transaction: {
                headers: [
                    { text: 'Date', width: '10%', value: 'payment_date',align:'center' },
                    { text: 'Bank', width: '15%', value: 'bank_name',align:'center' },
                    { text: 'Payment Type', width: '10%', value: 'payment_type_name',align:'center' },
                    { text: 'Customer', width: '20%', value: 'customer_name',align:'center' },
                    { text: 'Amount', width: '15%', value: 'net_amount',align:'end'  },
                    { text: 'Remarks', width: '15%', value: 'remarks',align:'center' },
                ],
                items: [],
                expanded: [],
                loading: false,
                with_old_data:[],
            },
            customers_list: [],
            menu: {
                date_from: false,
                date_to: false,
            },
            filters: {
                date_from: this.$dayjs().startOf('week').add(1, 'day').format('YYYY-MM-DD'),
                date_to:  this.$dayjs().endOf('week').add(-1, 'day').format('YYYY-MM-DD'),
                customers: [],
            },
            banks:{
                selection:[],
                selected:''
            },
            minDate: this.$dayjs().format('YYYY-MM-DD'),
            export_data:[],
        }
    },
    mixins: [SharedFunctionsComponent],
    components: {
        ComponentHeader
    },
    mounted() {
        this.$store.dispatch('getAllcustomersSelection')
        this.$store.dispatch('getAllcustomerClassificationSelection')
        this.getBanks();
    },
    computed: {
        ...mapGetters([
            'GET_CUSTOMERS_SELECTION',
            'GET_CLASSIFICATIONS_SELECTION',
            'USERACCOUNT_LOAD'
        ]),
    },
    methods: {
        getBanks() {
            this.$store.dispatch('getBankSelection', {
                type: 'AR',
            }).then(response=>{
                this.banks.selection = response
            })
        },
        resetFields() {
            this.filters.customers = [];
            this.banks.selected = '';
            this.getAllBankTransaction();
        },
        getCustomers() {
            this.customers_list = this.GET_CUSTOMERS_SELECTION
        },
        getAllBankTransaction() {
            this.transaction.loading = true
            let payload = {
                date_range:[this.filters.date_from,this.filters.date_to],
                bank_id:this.banks.selected,
                customer_ids:this.filters.customers,
                url:'getAllBankTransactionReport'
            }
            this.$store.dispatch('reportsPost',payload).then(response=>{
                this.transaction.items = response.data
                this.transaction.items.forEach(e=>{
                    e.net_amount = parseFloat(e.net_amount)
                })
                this.export_data = _.groupBy(this.transaction.items, 'bank_name');

                for (let [key, value] of Object.entries(this.export_data)) {
                    Object.assign(this.export_data[key],{
                        total: _.sumBy(value,'net_amount')
                    })
                }
                this.transaction.loading = false
            })
        },
        onSelectCustomers(items) {
            this.filters.customers = items
        },
        renderPDF(){
            var doc = new this.$jspdf({
                orientation: 'p',
                unit: 'mm',
                format: 'letter'
            });

            let left = 7;
            let top = 5;
            const imgWidth = 40;
            const imgHeight = 12;
            let host = window.location.origin;

            for (let i = 0; i < Object.keys(this.export_data).length; i++){
                let title = Object.keys(this.export_data)[i] + ' ( ' + this.$dayjs(this.filters.date_from).format('MM/DD/YYYY') + ' - ' + this.$dayjs(this.filters.date_to).format('MM/DD/YYYY') + ' )'
                doc.addImage(`${host}/images/ingcoph-logo.png`, 'PNG', left, top, imgWidth, imgHeight);
                doc.setTextColor('red').setFont(undefined,'normal').setFontSize(8).text(7,24,title).setTextColor('black')

                doc.autoTable({
                    html: '#export_details'+i,
                    theme: 'grid',
                    margin: {top: 25, left: 6, right: 3},
                    headStyles: {
                        halign:'center',
                        fontSize: 9,
                        fillColor: 'yellow',
                        textColor: '#000000',
                    },
                    columnStyles: {
                        0: {cellWidth: 30,halign:'center', fontSize: 8, textColor: '#000000'},
                        1: {cellWidth: 71,halign:'center', fontSize: 8, textColor: '#000000'},
                        2: {cellWidth: 30,halign:'right', fontSize: 8, textColor: '#000000'},
                        3: {cellWidth: 71,halign:'left', fontSize: 8, textColor: '#000000'},
                    },
                    willDrawCell: function (data) {
                        var rows = data.table.body;
                        if (data.row.index === rows.length - 1) {
                            doc.setFont(undefined,'bold');
                        }
                    },
                });
                doc.autoTable({
                        html: '#footer',
                        theme: 'plain',
                        startY: doc.internal.pageSize.height - 35,
                        margin: {top: 2, left: 3, right: 0},
                        columnStyles: {
                            0: {halign:'center',fontSize: 8, textColor: '#000000'},
                            1: {halign:'center',fontSize: 8, textColor: '#000000'},
                            2: {halign:'center',fontSize: 8, textColor: '#000000'},
                        },
                        bodyStyles: {
                            lineColor: '#000000',
                        }
                    });
                if(i < (Object.keys(this.export_data).length - 1)){
                    doc.addPage();
                }
            }

            let pageCount = doc.internal.getNumberOfPages();

            for(let i = 0; i < pageCount; i++) {
                doc.setPage(i);
                let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber;
                doc.setFontSize(9).setFont(undefined, 'normal').text('Page: ' + pageCurrent + ' of ' + pageCount, doc.internal.pageSize.width - 25,15 );
            }
            doc.autoPrint();
            window.open(doc.output('bloburl'), '_blank');
        },
        async exportToExcel(){
            let that = this
            that.transaction.loading
            const workbook = new this.$exceljs.Workbook();

            const worksheet = workbook.addWorksheet('Raw Data')
            worksheet.columns = [
                {header:'Date',key:'payment_date'},
                {header:'Bank',key:'bank_name'},
                {header:'Customer',key:'customer_name'},
                {header:'Amount',key:'net_amount'},
                {header:'remarks',key:'remarks'},
            ]
            this.transaction.items.forEach(e=>{
                worksheet.addRow(e);
            })
            worksheet.getRow(`0${1}`).font = { bold: true }
            worksheet.getColumn('C').numFmt = '#,##0.00';
            that.loaded = true
            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                this.$filesaver.saveAs(blob, `Bank Transaction Report ${that.filters.date_from}-${that.filters.date_to}.xlsx`);
            });
        }
    },
}
</script>

<style>

</style>
