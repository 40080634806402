<template>
  <div class="w-100">
    <v-app id="my_vue_app">
      <v-card>
        <v-row class="d-flex justify-end px-6 ma-4">
            <v-col cols="3">
                <v-row>
                <v-col cols="11">
                    <v-text-field
                        id="searchBar"
                        v-model="filter.search"
                        label="Search"
                        single-line
                        hide-details
                        @change="searchInput()"
                        @keydown.enter="searchInput()"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="1" class="pt-5">
                    <v-icon @click="searchInput()">mdi-magnify</v-icon>
                </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-container fluid>
          <v-data-table
            :headers="headers"
            :items="itemsTable"
            :options.sync="options"
            :server-items-length="totalItems"
            :items-per-page="10"
            :loading="loaded"
            loading-text="Loading... Please wait"
            fixed-header
          >
            <template v-slot:[`item.action`]="{ item }">
              <v-btn text icon color="orange" v-if="userAccess.view">
                <v-icon
                  class="btn-action"
                  small
                  @click="viewEditClassifications(item,'View')"
                  >mdi-eye</v-icon
                >
              </v-btn>
              <v-btn text icon color="blue" v-if="userAccess.edit">
                <v-icon
                  class="btn-action"
                  small
                  @click="viewEditClassifications(item,'Update')"
                  >mdi-square-edit-outline</v-icon
                >
              </v-btn>
              <v-btn text icon color="red" v-if="userAccess.delete">
                <v-icon
                  class="btn-action"
                  small
                  @click="deleteClassification(item.id)"
                  >mdi-delete</v-icon
                >
              </v-btn>
            </template>
          </v-data-table>
        </v-container>
      </v-card>
    </v-app>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { mapGetters } from 'vuex';
export default {
    props:{
        refresh_table:{
            default:()=>false,
            type:Boolean
        }
    },
  data() {
    return {
      loaded: false,
      totalItems: 0,
      options: {},
      items: [],
      filter:{
        search: ''
      },
      itemsPerPageOptions: [5, 10, 15, 50, 100],
      itemsPerPage: 10,
      search: "",
      headers: [
        { text: "Code", align: "left", value: "code" },
        { text: "Name", align: "left", value: "name" },
        { text: "Action", align: "center", value: "action" },
      ],
      userAccess: {
        view: false,
        create: false,
        edit: false,
        delete: false,
      },
    };
  },
  computed:{
    ...mapGetters([
    'USER_ACCESS',
    'GET_NEW_ADMIN_OTHER_TABLE',
    'GET_ADMIN_TAB_IS_ACTIVE',
    'GET_ADMIN_DISPATCH',
    'GET_ADMIN_CLASSIFICATION_TABLE',
    'GET_ADMIN_LOCATION_TABLE',
    'GET_ADMIN_BRAND_TABLE',
    'GET_ADMIN_GETTERS',
    'GET_STATUS_TABLE',
    'GET_ADMIN_CATEGORY_TABLE']),
    itemsTable(){
        if(this.GET_ADMIN_TAB_IS_ACTIVE == 'classification'){
          this.totalItems= this.$store.state.adminOtherTabs.classification_table_total;
          return this.GET_ADMIN_CLASSIFICATION_TABLE
        }
        else if(this.GET_ADMIN_TAB_IS_ACTIVE == 'location'){
          this.totalItems= this.$store.state.adminOtherTabs.location_table_total;
          return this.GET_ADMIN_LOCATION_TABLE
        }
        else if(this.GET_ADMIN_TAB_IS_ACTIVE == 'brand'){
          this.totalItems= this.$store.state.adminOtherTabs.brand_table_total;
          return this.GET_ADMIN_BRAND_TABLE
        }
        else if(this.GET_ADMIN_TAB_IS_ACTIVE == 'category'){
          this.totalItems= this.$store.state.adminOtherTabs.category_table_total;
          return this.GET_ADMIN_CATEGORY_TABLE
        }
        else if(this.GET_ADMIN_TAB_IS_ACTIVE == 'status'){
          this.totalItems= this.$store.state.SystemSettings.status_table_total;
          return this.GET_STATUS_TABLE
        }

    }
  },
  mounted() {
    this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
  },
  methods: {
    getAdminOtherTable() {
      this.loaded = true;
      let payload = {
        page: this.options.page,
        itemsPerPage: this.options.itemsPerPage,
        sortBy: this.options.sortBy,
        sortDesc: this.options.sortDesc,
        ...this.filter
      };
      this.$store
        .dispatch(this.GET_ADMIN_DISPATCH.get,payload)
        .then((response) => {
          this.loaded = false;

        })
        .catch((e) => {
          swal.fire({
            title: "Error",
            icon: "error",
            timer: 1500,
            allowOutsideClick: false,
          });
          this.loaded = false;
        });
    },
    viewEditClassifications(item,action) {
      this.$store.commit("EDIT_ADMIN_CLASSIFICATION", item);
      this.$store.commit("DIALOG", true);
      this.$store.commit("ACTION", action);
    },
    deleteClassification(id) {
      swal
        .fire({
          icon: 'question',
          text: "Are you sure you want to Delete?",
          cancelButtonText: "Cancel",
          showCancelButton: true,
          allowOutsideClick: false,
          cancelButtonColor: "grey",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$store
              .dispatch(this.GET_ADMIN_DISPATCH.delete, id)
              .then((success) => {});
          }
        });
    },
    searchInput(){
        this.options.page = 1;
        this.getAdminOtherTable();
    }
  },
  watch:{
    options: {
      handler() {
        this.getAdminOtherTable();
      },
      deep: true,
    },
    GET_NEW_ADMIN_OTHER_TABLE:{
        handler(val){
            if(val){
              this.getAdminOtherTable()
            }
        },
        immediate: true
    },
    USER_ACCESS: {
      handler(val) {

        if(val != "fail"){
           this.userAccess.edit = false;
           this.userAccess.delete = false;
           this.userAccess.view = false;
           this.userAccess.create = false;
        val.forEach(e => {
          if (e.actions_code == 'edit') {
            this.userAccess.edit = true;
          } else if (e.actions_code == 'delete') {
            this.userAccess.delete = true;
          } else if (e.actions_code == 'view') {
            this.userAccess.view = true;
          } else if (e.actions_code == 'create') {
            this.userAccess.create = true;
          }
        });
        }
      }
    },
    refresh_table:{
        handler(val){
        }
    }
  }
};
</script>
