<template>
    <div class="w-100">
        <v-app id="my_vue_app">
            <v-card>
                <v-card-title>
                    <v-tooltip  bottom color="green">
                        <template v-slot:activator="{ on, attrs }" >
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                @click="triggerDialog(true)"
                                text
                                icon
                                small
                            >
                                <v-icon size="24" center color="green">
                                    mdi-cloud-upload
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Import Positions</span>
                    </v-tooltip>

                    <v-dialog v-model="uploadPositionPlantillaDialog" max-width="50%" persistent>
                        <v-card style="font-size: smaller !important; overflow: hidden;" class="pa-5">
                            <v-card-title class="pa-0 border-bottom">
                                <v-row class="m-0">
                                    <v-col cols="pull-right-10 p-2">
                                        <v-btn
                                            text
                                            icon
                                            small
                                            color="gray"
                                            class="float-right"
                                            @click="triggerDialog(false)"
                                        >
                                            <v-icon>mdi-close-circle</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-card-title style="font-size: 24px; justify-content: center;">
                                <v-row>
                                    <v-spacer></v-spacer>
                                    <v-col class="text-center" cols="4">
                                        Upload Positions
                                        <v-icon size="44" left color="green">
                                            mdi-microsoft-excel
                                        </v-icon>
                                    </v-col>
                                    <v-col class="text-end" cols="4">
                                        <v-tooltip bottom color="red" v-if="checkPositions()">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    @click="removeAllNotFound()"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    class="mr-2"
                                                    text
                                                    icon
                                                >
                                                    <v-icon color="red">
                                                        mdi-account-multiple-remove
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                        <span>
                                            Remove all not found
                                        </span>
                                        </v-tooltip>
                                        <v-tooltip bottom color="blue">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn text icon color="blue"
                                                    @click="downloadImportTemplate()"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    large
                                                >
                                                    <v-icon
                                                        color="blue"
                                                    >mdi-download-box
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Download Template</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-divider :thickness="50"></v-divider>
                            <v-row  @dragover="dragover" @dragleave="dragleave" @drop="drop" class="dropzone-container overflow-hidden" v-if="batchAdditionItems.length == 0 && !loading.circular">
                                <v-col cols="12" class="text-center">
                                    <v-icon style="font-size:50px;" :class="have_img?'d-none':'d-block'">mdi-upload</v-icon>
                                    <p :class="have_img?'d-none':'d-block'"><span>Drag & Drop <p >or <label for="upload-file" style="color:blue;cursor:pointer;">browse</label> </p></span></p>
                                    <input type="file" id="upload-file" class="d-none" @change="ImportedExcel"
                                    ref="file"
                                    accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                                </v-col>
                            </v-row>
                            <v-row v-if="loading.circular">
                                <v-col cols="12" class="text-center">
                                <v-progress-circular
                                :size="50"
                                color="primary"
                                indeterminate
                                ></v-progress-circular>
                                <p>{{loading_text}}</p>
                                </v-col>
                            </v-row>
                            <v-row v-if="batchAdditionItems.length > 0">
                                <v-col>
                                    <v-simple-table>
                                        <thead class="position-sticky">
                                            <tr>
                                                <th class="text-start" width="22%">
                                                    POSITION
                                                </th>
                                                <th class="text-start" width="10%">
                                                    DEPARTMENT
                                                </th>
                                                <th class="text-start" width="10%">
                                                    REQ. MANPOWER
                                                </th>
                                                <th class="text-start" width="5%">
                                                    <span class="d-flex justify-content-center align-items-center mb-4">
                                                        <v-switch
                                                            v-if="checkPositions()"
                                                            v-model="showNotFound"
                                                            @change="filterBatchItems()"
                                                            :true-value="1"
                                                            :false-value="0"
                                                            class="mr-2"
                                                            label="Not Found"
                                                            hide-details
                                                        >
                                                        </v-switch>
                                                    </span>
                                                </th>
                                            </tr>
                                        </thead>
                                    </v-simple-table>
                                    <v-card class="pa-0 overflow-auto" style="height:50vh;">
                                        <v-simple-table>
                                            <template v-slot:default>
                                                <tbody>

                                                    <tr v-for="(item, i) in batchAdditionItems" :key="i">
                                                        <td width="15%" class="text-start" :style="`background-color:${item.found ? '' : '#ff5338'};`">
                                                            <span>{{ item.position_name }}</span>
                                                        </td>
                                                        <td width="10%" class="text-start" :style="`background-color:${item.found ? '' : '#ff5338'};`">
                                                            <span>{{ item.department_name }}</span>
                                                        </td>
                                                        <td width="10%" class="text-start" :style="`background-color:${item.found ? '' : '#ff5338'};`">
                                                            <span>{{ item.req_manpower }}</span>
                                                        </td>
                                                        <!-- <td width="10%" class="text-start" :style="`background-color:${item.found ? '' : '#ff5338'};`">
                                                            <v-tooltip bottom color="#B80000" v-if="!item.found">
                                                                <template v-slot:activator="{ on, attrs }" >
                                                                    <v-btn
                                                                        @click="removePosition(item)"
                                                                        color="#B80000"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        icon
                                                                        small
                                                                    >
                                                                        <v-icon>mdi-minus-circle</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Remove Position</span>
                                                            </v-tooltip>
                                                        </td> -->
                                                        <td width="0%" class="d-none"></td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="text-center" v-if="batchAdditionItems.length > 0">
                                    <v-btn color="#ffa500" @click="submitImport()" :disabled="checkPositions()" :loading="loading.button">
                                        <span style="color:black;">
                                            Submit
                                        </span>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-dialog>
                    <v-container>
                        <v-row class="mb-2">
                            <v-spacer></v-spacer>
                            <div class="row col-sm-12 col-md-4 mr-3 align-items-end">
                                <v-text-field
                                    id="searchBar"
                                    v-model="filter.search"
                                    label="Search"
                                    single-line
                                    hide-details
                                    @change="searchInput()"
                                >
                                </v-text-field>
                                <v-icon @click="searchInput()">mdi-magnify</v-icon>
                            </div>
                        </v-row>
                    </v-container>

                </v-card-title>
                <v-container fluid>
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        :search="search"
                        :options.sync="options"
                        :server-items-length="totalItems"
                        :items-per-page="10"
                        :loading="!loaded"
                        :footer-props="{
                            itemsPerPageOptions: itemsPerPageOptions
                        }"
                        loading-text="Loading... Please wait"
                        fixed-header
                    >
                        <template v-slot:[`item.action`]="{ item }">
                            <v-btn text icon color="orange" v-if="userAccess.view">
                            <v-icon
                                class="btn-action"
                                small
                                @click="viewPlantilla(item)"
                                >mdi-eye</v-icon
                            >
                            </v-btn>
                            <v-btn text icon color="blue" v-if="userAccess.edit">
                            <v-icon
                                class="btn-action"
                                small
                                @click="editPlantilla(item)"
                                >mdi-pencil</v-icon
                            >
                            </v-btn>
                            <v-btn text icon color="red" v-if="userAccess.delete">
                            <v-icon
                                class="btn-action"
                                small
                                @click="deletePlantilla(item)"
                                >mdi-delete</v-icon
                            >
                            </v-btn>
                        </template>
                        <template v-slot:[`item.current_manpower`]="{item}">
                            <span :class="`${item.current_manpower > item.required_manpower ? 'red': 'green'}--text`">{{ item.current_manpower }}</span>
                        </template>
                        <template v-slot:[`item.total_manpower`]="{item}">
                            <span :class="`${item.total_manpower > item.total_required_manpower ? 'red': 'green'}--text`">{{ item.total_manpower }}</span>
                        </template>
                    </v-data-table>
                </v-container>
            </v-card>
        </v-app>
    </div>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from 'vuex';
import * as XLSX from 'xlsx';

export default {
    data(){
        return {
            loaded: false,
            totalItems: 0,
            options: {},
            items: [],
            itemsPerPageOptions: [5, 10, 15, 50, 100],
            itemsPerPage: 10,
            search: "",
            filter:{
                search:""
            },

            headers: [
                { text: 'Department', align: 'left',value:'department_name' },
                { text: 'Total Required Manpower', align: 'left', value: 'total_required_manpower'},
                { text: 'Total Manpower', align: 'left', value: 'total_manpower'},
                { text: "Action", align: "center", value: "action" },
            ],
            userAccess: {
                view: false,
                create: false,
                edit: false,
                delete: false,
            },
            isDepartmentView:0,
            uploadPositionPlantillaDialog: false,
            batchAdditionItems: [],
            batchAdditionItemsTemp: [],
            loading_text: '',
            isDragging: false,
            have_img:	false,
            loading: {
                button: false,
                circular: false,
            },
            component_dispatches:[
				'getEmployeeJobTitleSelection',
			],
            showNotFound: 0
        }
    },
    mounted(){
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        this.getSearchFromUrl();
        this.getAllDropdowns();
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS',
            'GET_HR_PLANTILLA_NEW_DATA',
            'DIALOGS',
            'USERACCOUNT_LOAD',
            'GET_HR_TAB_IS_ACTIVE',
            'GET_HR_DISPATCH',
            'GET_JOB_TITLE_SELECTION'
        ]),
    },
    components:{
    },
    methods:{
        getHRPlantilla(filter = null){
            this.items = [];
            this.loaded = false;
            let payload = {
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                search: this.filter.search,
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                filter: filter,
            };
            this.$store.dispatch(this.GET_HR_DISPATCH.get, payload).then((response) => {
                this.items = response.data.data;
                this.loaded = true;

                this.totalItems = response.total;
            });
        },
        searchInput(){
            this.options.page = 1
            this.getHRPlantilla({ query: this.search });
        },
        viewPlantilla(item){
            let payload = {
                department_id : item.department_id,
                isDepartmentView: this.isDepartmentView
            }
            let items = {}
                this.$store.dispatch('ShowHrPlantilla',payload).then(response=>{
                    items = response
                    this.$store.commit("HR_OTHER_TABS_DIALOG_VIEW", items);
                    this.$store.commit("DIALOG", true);
                    this.$store.commit("ACTION", "View");
                })
        },
        editPlantilla(item){
            let payload = {
                id : item.id,
                department_id : item.department_id,
                isDepartmentView: this.isDepartmentView
            }
            let items = {}
                this.$store.dispatch('ShowHrPlantilla',payload).then(response=>{
                items = response
                    this.$store.commit("HR_OTHER_TABS_DIALOG_VIEW", items);
                    this.$store.commit("DIALOG", true);
                    this.$store.commit("ACTION", "Update");
                })
        },
        getSearchFromUrl() {
            if (Object.keys(this.$route.query).length != 0) {
                Object.keys(this.$route.query).forEach(e=>{
                if(e != 'for_approval')
                this.filter.search = atob(this.$route.query[e]);
                })
                this.searchInput();
            }
        },
        deletePlantilla(item){
            Swal.fire({
                icon: 'question',
                text: "Are you sure you want to Delete?",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#397373",
                cancelButtonColor: "#397373",
            })
            .then((result) => {
                if (result.isConfirmed) {
                    let payload = {
                        payload : item,
                    }
                    this.$store.dispatch(this.GET_HR_DISPATCH.delete, payload).then((success) => {
                        Swal.fire("Success!", "Employee Data deleted", "success");
                    });
                }
            });
        },
        downloadImportTemplate(){
            let data = [{
                ['POSITION']: 'Chairman',
                ['DEPARTMENT']: 'Executive',
                ['Req. Manpower']: 5,
            }];

            let wb = XLSX.utils.book_new();
            let ws = XLSX.utils.json_to_sheet(data);
            XLSX.utils.book_append_sheet(wb, ws, 'Positions');
            let excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            let blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            let url = window.URL.createObjectURL(blob);
            let link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Position Import Template.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        dragover(e) {
            e.preventDefault();
            this.isDragging = true;
        },
        dragleave() {
            this.isDragging = false;
        },
        drop(e) {
            e.preventDefault();
            this.$refs.file.files = e.dataTransfer.files;
            this.ImportedExcel();
            this.isDragging = false;
        },
        ImportedExcel(event) {
            this.loading.circular = true;
            let that = this;
            that.file = that.$refs.file.files[0];
            let fileReader = new FileReader();
            setTimeout((el)=>{
                fileReader.readAsArrayBuffer(that.file);
                that.loading_text = 'Transferring data please wait...'
                fileReader.onload = (e) => {

                that.arrayBuffer = fileReader.result;
                let data = new Uint8Array(that.arrayBuffer);
                let arr = new Array();

                for (let i = 0; i != data.length; ++i)
                    arr[i] = String.fromCharCode(data[i]);
                    let bstr = arr.join("");

                    let workbook = XLSX.read(bstr, { type: "binary" });

                    let sheetName = '';
                    // let first_sheet_name = workbook.SheetNames[1];

                    let worksheet = workbook.Sheets[workbook.SheetNames[0]];
                    let arraylist = XLSX.utils.sheet_to_json(worksheet, { raw: true });

                    that.filelist = [];
                    let tmp_arr = [];
                    let tmp_obj = {};
                    let tmp_obj_arr = [];
                    arraylist.forEach((e,i)=>{
                        that.batchAdditionItems.push({
                            position_name: e['POSITION'],
                            department_name: e['DEPARTMENT'],
                            req_manpower: e['Req. Manpower'],
                            found: this.GET_JOB_TITLE_SELECTION.find(x => x.text == e['POSITION']) ? 1 : 0
                        });
                    });
                        that.batchAdditionItemsTemp = that.batchAdditionItems;
                        this.loading.circular = false;
                        that.loading_text = 'Loading....Please Wait'
                    }

				},2000)
            clearInterval();
        },
        submitImport(){
            this.loading.button = true;
            let payload = {
                url: 'importPositionPlantillaFromExcel',
                items: this.batchAdditionItems
            }
            this.$store.dispatch('hrUrlPost', payload).then((res)=>{
                this.loading.button = false;
                this.getHRPlantilla();
                this.triggerDialog(false);
                if(res.position_not_found.length > 0){
                    Swal.fire({
                        title: 'Following Positions Not Found!',
                        html: `<div class="swal-scrollable-content">${res.position_not_found.join('<br> ')}</div>`,
                        icon: 'info',
                        allowOutsideClick: false,
                        footer: 'Other positions are saved!'
                    });
                    return false
                }else{
                    Swal.fire(res.icon.toUpperCase(),res.message,res.icon);
                }
            }).catch((error)=>{
                this.loading.button = false;
                console.log(error)
            })
        },
        triggerDialog(value){
            if(value){
                this.batchAdditionItems = [];
            }
            this.uploadPositionPlantillaDialog = value;
        },
        getAllDropdowns(){
			this.component_dispatches.forEach(el=>{
				this.$store.dispatch(el)
			})
        },
        checkPositions(){
            let notFoundCount = 0;
            if(this.batchAdditionItems.length > 0){
                notFoundCount = this.batchAdditionItems.filter(e => !e.found).length;
            }
            return notFoundCount == 0 ? false : true;
        },
        filterBatchItems(){
            if(this.showNotFound){
                this.batchAdditionItems = this.batchAdditionItems.filter(e => !e.found);
            }else{
                this.batchAdditionItems = this.batchAdditionItemsTemp;
            }
        },
        removeAllNotFound(){
            let filteredBatch = this.batchAdditionItemsTemp.filter(x => x.found);
            Swal.fire({
                icon: 'warning',
                text: "All Not Found Positions will be removed.",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#397373",
                cancelButtonColor: "#397373",
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.notFoundCount = 0;
                    this.batchAdditionItems = filteredBatch;
                    this.batchAdditionItemsTemp = filteredBatch;
                }
            });
        }
    },
    watch:{
        options: {
            handler() {
                this.getHRPlantilla();
            },
            deep: true,
        },
        USER_ACCESS: {
            handler(val) {
                if(val != "fail"){
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                this.userAccess.create = false;
                    val.forEach(e => {
                        if (e.actions_code == 'edit') {
                            this.userAccess.edit = true;
                        } else if (e.actions_code == 'delete') {
                            this.userAccess.delete = true;
                        } else if (e.actions_code == 'view') {
                            this.userAccess.view = true;
                        } else if (e.actions_code == 'create') {
                            this.userAccess.create = true;
                        }
                    });
                }
            }
        },
        GET_HR_PLANTILLA_NEW_DATA:{
            handler(val){
                if(val){
                    this.getHRPlantilla();
                    this.$store.commit('HR_PLANTILLA_NEW_DATA',false)
                }
            }
        },
        DIALOGS:{
            handler(val){

            }
        },

    }
}
</script>
<style scoped>
.selected {
    opacity: 100%;
}

.non-selected {
    opacity: 40%;
}
</style>
