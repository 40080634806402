<template>
    <v-dialog
        ref="dialog"
        v-model="dateDialog"
        :return-value.sync="date"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="date"
                :label="dateLabel || 'Pick Date'"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                dense
                clearable
            />
        </template>
        <v-date-picker
          v-model="date"
          scrollable
        >
          <v-spacer></v-spacer>
          <!-- <v-btn
            text
            color="primary"
            @click="closeDateDialog()"
          >
            Cancel
          </v-btn> -->
          <v-btn
            text
            color="primary"
            @click="$refs.dialog.save(date)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>
</template>

<script>

export default {
    data() {
        return {
            dateDialog: false,
            date: '',
        }
    },
    props: ['dateLabel'],
    watch: {
        date() {
            this.$emit('date', this.date);
        }
    },
    methods: {
        closeDateDialog() {
            this.dateDialog = false;
            this.date = '';
        }
    }
}
</script>

<style>

</style>
