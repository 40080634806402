	<template>
		<v-container fluid >
			<h3 class="page-title">Stocks Inquiry</h3>
			<v-container class="ma-0">
					<v-app id="item-tabs">
						<v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" background-color="#424242" dark show-arrows>
							<v-tabs-slider color="cyan"></v-tabs-slider>
								<v-tab ripple v-for="(item, i) in stocks_inquiry_component" :key="i" @click="tabs(i)" v-if="tabAccess(item)">
									{{ item.tab_name }}
								</v-tab>
						</v-tabs>
						<v-tabs-items v-model="tab">
							<v-tab-item v-for="(table, index) in stocks_inquiry_component" :key="index" v-if="tabAccess(table)">
								<keep-alive>
									<component :is="table.components"  :isActive_tab="table.status_text">
									</component>
								</keep-alive>
							</v-tab-item>
						</v-tabs-items>
					</v-app>
			</v-container>
			<DialogComponent :cp="components" :scrollable="dialog_scrollable" :width="height(dialog_width)" :retain-focus="false"></DialogComponent>
		</v-container>
	</template>

<script>
	import DialogComponent from '@/views/main/layouts/dialogs/Dialog.vue';
    import PusherFunctionComponentVue from '@/views/main/Shared/PusherFunctionComponent.vue';
    import StocksInquiryComponent from "@/views/main/modules/Sales/StocksInquiry/StocksInquiryComponent.vue"
    import StocksInquiryComponentView from '@/views/main/layouts/dialogs/Sales/StocksInquiry/StocksInquiryComponentView.vue'

	import { mapGetters } from 'vuex';
	export default {
		mixins:[PusherFunctionComponentVue],
		components:{
			DialogComponent,
			StocksInquiryComponent,
			StocksInquiryComponentView,
		},
        mixins:[PusherFunctionComponentVue],
		data() {
			return {
				components:'',
				stocks_inquiry_component: [
						{
                        tab_name:"Inquiry",
						components: StocksInquiryComponent,
                        access:'stocks_inquiry',
						status_text: "stocks_inquiry",
						dialog: StocksInquiryComponentView,
						dialog_title:'Stocks Inquiry',
						dialog_width: "65%",
						scrollable:true,
						dispatch:{
                                    get:'getStocksInquiryTable',
                                },
						},
				],
				dialog_width: "",
				loaded: false,
				options: {},
				itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
				itemsPerPage: 10,
				tab: null,
				dialog_scrollable:true,
			};
		},
		computed:{
			...mapGetters(['USERACCOUNT_LOAD','USER_ACCESS'])
		},
		mounted(){
			this.tabs(0)
            this.callPusher()

            this.$store.dispatch('checkAccessUser',this.$router.currentRoute.params.id);
		},
		methods:{
		tabs(key) {
			this.components = this.stocks_inquiry_component[key].dialog;
			this.dialog_width = this.stocks_inquiry_component[key].dialog_width;
			this.dialog_scrollable = this.stocks_inquiry_component[key].scrollable;


			this.$store.commit('STOCKS_INQUIRY_ACTIVE_TAB_DISPATCH',this.stocks_inquiry_component[key].status_text)
			this.$store.commit('STOCKS_INQUIRY_DISPATCH',this.stocks_inquiry_component[key].dispatch)
		},
		getSearchFromUrl() {
            if (Object.keys(this.$route.query).length != 0) {
                Object.keys(this.$route.query).forEach(e=>{
					if(e == 'for_approval'){
						this.tab = this.items.length - 1
						this.tabs(this.tab)
					}
                })
            }
        },
		height(width) {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs': return "100%"
				case 'sm': return "100%"
				case 'md': return width
				case 'lg': return width
				case 'xl': return width
			}
		},
        tabAccess(item){
            if(this.USER_ACCESS != "fail"){
                if(this.USER_ACCESS.find(e=>e.actions_code == item.access)){
                    if(this.USER_ACCESS.find(e=>e.actions_code == item.access).actions_code == item.access)
                    {
                        return true
                    }
                }
            }
        }
	},
	watch:{
		USERACCOUNT_LOAD:{
			handler(val){
			}
		},

	}

	};
	</script>
	<style>
	#group-tab {
		padding: 0 !important;
	}
	</style>

