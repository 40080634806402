<template>
<div class="w-100">
    <v-app id="load_bar" v-if="!loaded">
        <v-text-field color="info" loading disabled></v-text-field>
    </v-app>

    <v-app id="my_vue_app">

        <v-card v-show="loaded">
            <v-card-title>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    label="Search"
                    single-line
                    hide-details
                >
                </v-text-field>
                <v-icon @click="searchInput()">mdi-magnify</v-icon>
            </v-card-title>

            <v-container fluid>
                <v-data-table
                    :headers="headers"
                    :items="promos"
                    :search="search"
                    :options.sync="options"
                    :server-items-length="totalPromos"
                    :items-per-page="10"
                    :footer-props="{
                        itemsPerPageOptions: itemsPerPageOptions
                    }"
                    class="w-100"
                >

                <template v-slot:[`item.created_at`]="{ item }">
                    <span class="text-nowrap">
                        {{ item.created_at | formatDate }}
                    </span>
                </template>

                <template v-slot:[`item.active`]="{ item }">
                    <span class="badge badge-primary" v-if="item.active">Yes</span>
                    <span class="badge badge-danger" v-else>No</span>
                </template>



                <template v-slot:[`item.action`]="{ item }">
                    <span class="text-nowrap">
                        <v-btn text icon color="orange" v-if="userAccess.view">
                            <v-icon class="btn-action" small @click="viewPromo(item.id)">mdi-eye</v-icon>
                        </v-btn>
                        <v-btn text icon color="blue" v-if="userAccess.edit">
                            <v-icon class="btn-action" small @click="editPromo(item.id)">mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn text icon color="red" v-if="userAccess.delete">
                            <v-icon class="btn-action" small @click="deletePromo(item.id)">mdi-delete</v-icon>
                        </v-btn>
                    </span>
                </template>

                </v-data-table>
            </v-container>
        </v-card>
    </v-app>

  <!-- VIEW MODAL-->
  <PromosViewComponentVue :viewPromoId="viewPromoId" @closeView="closeView"></PromosViewComponentVue>

</div>
</template>

<script>
// import PromosViewComponent from './PromosViewComponent.vue'
import PromosViewComponentVue from './PromosViewComponent.vue';
import swal from 'sweetalert2';
export default {
    components: {
        PromosViewComponentVue
    },
    props: [
        'promosActionResult',
    ],
    data () {
        return {
            actionResult : {
                model : 'Promos',
                action : ''
            },
            loaded: false,
            totalPromos: 0,
            options: {},
            promos: [],
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            itemsPerPage: 10,
            search: '',
            awaitingSearch: false,
            headers: [
                { text: 'Name', align: 'left', value: 'name', },
                { text: 'Code', align: 'left', value: 'code', },
                { text: 'Start Date', align: 'left', value: 'date_start', },
                { text: 'End Date', align: 'left', value: 'date_end', },
                { text: 'Active', align: 'left', value: 'active', },
                { text: 'Created By', align: 'left', value: 'user_name', },
                { text: 'Date Created', align: 'left', value: 'created_at', },
                { text: 'Actions', align: 'center', value: 'action', sortable: false },
            ],

            viewPromoId: '',

            json_data: [],
            json_meta: [
                [
                    {
                        'key': 'charset',
                        'value': 'utf-8'
                    }
                ]
            ],
            userAccess: {
                view: false,
                edit: false,
                delete: false,
            },
        }
    },
    methods:{
        getAllPromos(){
            this.loaded = false
            let payload = {
                page:           this.options.page,
                itemsPerPage:   this.options.itemsPerPage,
                sortBy:         this.options.sortBy,
                sortDesc:       this.options.sortDesc,
                search:         this.search,
                url:            'getAll'
            }
            this.$store.dispatch('getPromos',payload).then(response => {
                this.promos        = response.data.data
                this.loaded              = true
                this.totalPromos   = response.data.total
            })
        },
        viewPromo(id){
            this.viewPromoId = id
        },
        editPromo(id){
            this.$emit('showEditModal', id);
        },
        deletePromo(id){
            //console.log('delete: ' + id)
            swal.fire({
                title: "",
                text: "Are you sure you want to Delete?",
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    let payload = {
                        url:    'promos/'+id
                    }

                    this.$store.dispatch('deletePromos',payload).then(response => {
                        //console.log(response)
                        this.getAllPromos()

                        swal.fire(response.data, {icon: "success",});
                    }).catch(error => {
                        // console.log(error)
                        swal.fire("An Error Occured", error, "error");
                    })
                }
            });
        },
        closeView(action = false){
            this.viewPromoId = 0
            /*if(action == 'convertSuccess' || action == 'updateStatusSuccess'){
                this.getAllPromos()
            }*/
        },
        searchInput(){
            this.getAllPromos({ query: this.search });
        },
        addKeyListener(){
            window.addEventListener("keyup", function(e) {
                if(e.keyCode == 13){
                    this.searchInput();
                    $("input").blur();

                }
            }.bind(this));
        },
        checkAccess(){
            this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id).then(response=>{
                let val = response;
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                if(val != "fail"){
                val.forEach((e) => {
                    if (e.actions_code == "edit"){
                        this.userAccess.edit = true;
                    }
                    if (e.actions_code == "view"){
                        this.userAccess.view = true;
                    }
                    if (e.actions_code == "delete"){
                        this.userAccess.delete = true;
                    }
                });
                }
            })
        }
    },
    mounted: function (){
        this.addKeyListener()
        this.loaded = false
        this.checkAccess()
        this.loaded = true
    },
    watch: {
        promosActionResult: function(val){
            if(this.promosActionResult=='success'){
                this.getAllPromos()
            }
        },
        options: {
            handler () {
                this.getAllPromos()
            },
            deep: true,
        },
    }
};
</script>
