<template>
    <div class="w-100">
        <v-app id="my_vue_app">
            <v-card>
                <v-card-text style="overflow:auto;">
                    <v-card-title class="p-0">
                        <v-container class="p-0 pl-4" fluid>


                        </v-container>
                    </v-card-title>
                    <v-container fluid>
                        <v-row>
                            <v-col>
                                <v-data-table
                                    :headers="headers"
                                    :items="items"
                                    :search="ap_setting_filter.search"
                                    :options.sync="options"
                                    :server-items-length="totalItems"
                                    :items-per-page="10"
                                    :loading="!loaded"
                                    :footer-props="{
                                        itemsPerPageOptions: itemsPerPageOptions,
                                    }"
                                    loading-text="Loading... Please wait"
                                    fixed-header
                                >
                                <template v-slot:[`item.color_code`]="{ item }">
                                    <v-chip small :color="item.color_code">{{item.color_code}}</v-chip>
                                </template>
                                    <template v-slot:[`item.action`]="{ item }">
                                        <v-btn text icon color="orange" v-if="userAccess.view">
                                            <v-icon
                                            class="btn-action"
                                            small
                                            @click="ViewChequeSettings(item,'View')"
                                            >mdi-eye</v-icon>
                                        </v-btn>
                                        <v-btn text icon color="blue"
                                            v-if="userAccess.edit"
                                        >
                                            <v-icon
                                            class="btn-action"
                                            small
                                            @click="EditChequeSetting(item,'Update')"
                                            >mdi-square-edit-outline</v-icon
                                            >
                                        </v-btn>
                                        <v-btn text icon color="red"
                                            v-if="userAccess.delete && userAccess.edit"
                                        >
                                            <v-icon
                                            class="btn-action"
                                            small
                                            @click="DeleteChequeSetting(item)"
                                            >mdi-delete</v-icon
                                            >
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-app>
    </div>
</template>
<script>
import swal from "sweetalert2";
import { mapGetters } from 'vuex';
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
export default {
    mixins: [
        SharedFunctionsComponentVue
    ],
    data() {
        return {
            formattedTransactDate: [],
            transact_date: false,
            items: [],
            export: [],
            loaded: true,
            totalItems: 0,
            options: {},
            itemsPerPageOptions: [5, 10, 15, 50, 100],
            itemsPerPage: 10,
            status_text:'',
            status:0,
            viewJournalVoucherId:'',
            moments:(date)=>{
                return this.$dayjs(date).format('YYYY-MM-DD')
            },
            headers:[
                { text: "Days", align: "left", sortable:false, value:'day' },
                { text: "Color", align: "left", sortable:false, value:'color_code' },
                { text: "Action", align: "center", value: "action",sortable:false},
            ],
            userAccess: {
                view: false,
                create: false,
                edit: false,
                delete: false,
            },
            statuses:[
                {status:'pending',badge_color:'gray'},
                {status:'approved',badge_color:'success'},
                {status:'rejected',badge_color:'danger'},
                {status:'cancelled',badge_color:'danger'},
                //{status:'hold',badge_color:'gray'},
                //{status:'signing',badge_color:'primary'},
                //{status:'signed',badge_color:'success'},
                //{status:'releasing',badge_color:'warning'},
                //{status:'released',badge_color:'info'},
            ],
            ap_setting_filter:{
                search:''
            },
            clonedStores:[]
        }
    },

    computed:{
        ...mapGetters([
            'USER_ACCESS',
            'STATUS',
            'GET_NEW_CHEQUE_SETTINGS'
        ]),
    },
    mounted() {
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        this.getAllChequeSettings();
    },
    methods: {
        getAllChequeSettings() {
            this.loaded = false
            this.items = []
            let payload = {
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                search:this.ap_setting_filter.search,
                filters: this.ap_setting_filter,
            }
            this.$store.dispatch('getAllChequeSettings',payload).then(response=>{
                this.items = response.data.data
                this.totalItems = response.total
                this.loaded = true
                this.$store.commit('NEW_CHEQUE_SETTINGS',false);
            })
        },
        ViewChequeSettings(item,action) {
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", action);
            this.$store.commit("EDIT_CHEQUE_SETTING", item);
        },
        EditChequeSetting(item,action) {
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", action);
            this.$store.commit("EDIT_CHEQUE_SETTING", item);
        },
        DeleteChequeSetting(item) {
            swal
            .fire({
                icon: 'question',
                text: "Are you sure you want to Delete?",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#397373",
                cancelButtonColor: "#397373",
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('deleteChequeSetting',item.id)
                }
            });
        },
    },
    watch: {
        GET_NEW_CHEQUE_SETTINGS:{
            handler(val){
                if(val){
                    console.log(val)
                    this.getAllChequeSettings();
                }
            }
        },
        options: {
            handler() {
                this.getAllChequeSettings();
            },
            deep: true,
        },
        USER_ACCESS: {
            handler(val) {
                if (val != "fail") {
                    this.userAccess.edit = false;
                    this.userAccess.delete = false;
                    this.userAccess.view = false;
                    this.userAccess.create = false;
                    val.forEach((e) => {
                        if (e.actions_code == "edit") {
                            this.userAccess.edit = true;
                        } else if (e.actions_code == "delete") {
                            this.userAccess.delete = true;
                        } else if (e.actions_code == "view") {
                            this.userAccess.view = true;
                        } else if (e.actions_code == "create") {
                            this.userAccess.create = true;
                        }
                    });
                }
            },
            immediate: true,
            deep: true
        },
    }
};
</script>