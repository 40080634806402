<template>

    <div class="d-flex flex-column">
        <v-progress-circular
            :size="100"
            :width="3"
            color="orange"
            indeterminate
        ></v-progress-circular>
        <span class="d-flex flex-row"><h4 v-for="(char, index) in loadingChars" :key="index" :style="charStyle(index)">{{ char }}</h4></span>
    </div>

  </template>

  <script>
  export default {
    data() {
      return {
        loadingChars: ['L', 'o', 'a', 'd', 'i', 'n', 'g','.','.','.'],
      };
    },
    methods: {
      charStyle(index) {
        const delay = index * 0.1; // Adjust this value for desired animation speed
        return {
          animation: `fade-in 1s ${delay}s infinite`,
        };
      },
    },
  };
  </script>

  <style>
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .loading-text {
    display: flex;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  </style>
