<template>
  <v-dialog v-model="requestNewSparePartsDialog" persistent max-width="50%">
      <v-card class="pa-4">
          <v-row class="m-0">
                <v-col>
                    <v-card-title>
                        <span class="headline">Request New Spare Parts</span>
                    </v-card-title>
                </v-col>
                <v-col cols="pull-right-10 p-2">
                    <v-btn
                        text
                        icon
                        color="gray"
                        class="float-right"
                        @click="closeRequestNewSparePartsDialog()"
                    >
                    <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-divider />

            <div>
                 <v-checkbox v-model="from_other_unit" label="Parts From other unit" class="ma-0"></v-checkbox>
            </div>
            <v-layout row class="px-4">
                <v-flex lg2 class="pr-2">
                    <div>Qty: <span class="red--text">*</span></div>
                    <div>
                        <v-text-field v-model="itemQty" outlined dense :min="1" type="number" @focus="$event.target.select()" />
                    </div>
                </v-flex>
                <v-flex lg5 class="pr-2">
                    <div>Model / Part No.: <span class="red--text">*</span></div>
                    <div>
                        <v-autocomplete
                            v-if="!from_other_unit"
                            dense
                            outlined
                            v-model="selectedItemModel"
                            :items="lists"
                            :loading="ItemSelectionLoading"
                            :disabled="ItemSelectionLoading"
                            item-value="id"
                            item-text="model"
                            placeholder="Select Spare Parts"
                            auto-select-first
                            clearable
                        />
                    </div>
                    <div>
                        <v-text-field v-model="otherParts.model" label="Model" outlined dense @change="selectedItem()" v-if="from_other_unit"></v-text-field>
                    </div>
                </v-flex>
                <v-flex lg2 class="mt-6">
                    <div></div>
                    <div>
                        <v-btn @click="addSpItem()">Add</v-btn>
                        <v-tooltip bottom color="#f69f1a">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    @click="openReasons()"
                                    fab
                                    text
                                    small
                                    class="mr-4"
                                    color="blue"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon medium>mdi-text-box-outline</v-icon>
                                </v-btn>

                            </template>
                            <span>Reason</span>
                        </v-tooltip>
                    </div>
                </v-flex>
            </v-layout>

            <v-data-table
                :headers="requestedItemHeaders"
                :items="requestedItems"
            >
                <template v-slot:item.action="{ item }">
                    <td class="px-0">
                        <v-btn text icon color="red">
                            <v-icon class="btn-action" small @click="deleteSpItem(item)">mdi-delete</v-icon>
                        </v-btn>
                    </td>
                </template>
            </v-data-table>

            <v-divider class="mt-0" />

            <div style="text-align:center">
                <v-btn @click="submitRequestNewSp()">Submit</v-btn>
            </div>

            <v-dialog v-model="reasonDialog" persistent max-width="50%">
                    <v-card class="pa-2">
                        <v-row class="m-0">
                            <v-col>
                                <v-card-title>
                                    <span class="headline">Reason for Requote</span>
                                </v-card-title>
                            </v-col>
                            <v-col cols="pull-right-10 p-2">
                                <v-btn
                                    text
                                    icon
                                    color="gray"
                                    class="float-right"
                                    @click="closeReasons()"
                                >
                                <v-icon>mdi-close-circle</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-divider />
                        <v-textarea v-model="reasonSpareParts" label="Reason for Requote" placeholder="Reason for Requote" dense outlined></v-textarea>
        
                        <div style="text-align:center">
                            <v-tooltip bottom color="#f69f1a">
                                <template v-slot:activator="{ on, attrs }">
                                    <!-- @click="refreshPartsOrder()" -->
                                    <v-btn
                                        @click="submitReasons()"
                                        fab
                                        text
                                        small
                                        class="mr-4"
                                        color="blue"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon>mdi-plus-box-outline</v-icon>
                                    </v-btn>
        
                                </template>
                                <span>Submit Reason</span>
                            </v-tooltip>
                        </div>
                    </v-card>
                </v-dialog>
      </v-card>
    <ConfirmOverrideComponent
        :approve_override_dialog="override.override_dialog"
        :departments_allowed="override.override_departments_allowed"
        :heading="''"
        @closeConfirmOverride="closeConfirmOverride"
    ></ConfirmOverrideComponent>

  </v-dialog>
</template>

<script>
import _ from 'lodash';
import ConfirmOverrideComponent from '@/views/main/Utils/ConfirmOverrideComponent.vue';
import swal from 'sweetalert2'

export default {
    components:{
        ConfirmOverrideComponent
    },
    data() {
        return {
            requestedItemHeaders: [
                { text: 'Request Qty', value: 'request_qty'},
                { text: 'Item Model', value: 'model'},
                { text: 'Part Num', value: 'part_num'},
                { text: 'Item Name', value: 'name'},
                { text: 'Category', value: 'category'},
                { text: 'Action', value: 'action'},
            ],
            requestedItems: [],
            itemQty: 1,
            selectedItemModel: '',
            spLists: [],
            lists: [],
            partNumSuggestion: false,
            ItemSelectionLoading:true,
            from_other_unit:false,
            otherParts:{
                model:'',
                name:'',
                quantity:1,
                counter:0
            },
            override: {
                override_dialog: false,
                errors: [],
                override_departments_allowed: ['SR'],
                override_authenticated: false,
            },
            reasonDialog: false,
            reasonSpareParts: '',
        }
    },
    props: ['itemToSearch', 'rfPartsOrderItems', 'rfId', 'rfPartsOrderId', 'itemWarrantyType','rf'],
    // props:{
    //     itemToSearchs:{
    //         default:null,
    //         type:Number
    //     },
    //     rfPartsOrderItems:'',
    //     rfId:'',
    //     rfPartsOrderId:'',
    //     itemWarrantyType:'',
    //     rf:{}
    // },
    watch: {
        itemToSearch() {
            if(!!this.itemToSearch) {
                this.getModelSpareParts();
            }
        },
        spLists() {
            if(this.spLists.length > 0 && this.rfPartsOrderItems.length > 0) {
                let poiIds = this.rfPartsOrderItems.map(x=>x.item_id);
                if(poiIds.length > 0) {
                    let pois = []
                    let spIds = _.compact(this.spLists).map(x=>x.id);
                    // let filtered_sp_ids = _.difference(spIds, poiIds);
                    poiIds.forEach(function(v) {
                        pois.push({id: v})
                    });
                    let filtered_spLists = _.differenceBy(_.compact(this.spLists), pois, 'id');
                    // this.lists = filtered_spLists;
                    this.lists = this.spLists;
                }
            } else {
                this.lists = this.spLists;
            }
        },
        partNumSuggestion() {
            this.getModelSpareParts();
        }
    },
    computed: {
        requestNewSparePartsDialog() {
            return this.$store.state.service.requestNewSparePartsDialog;
        },
        checkRequestedItems() {
            if(this.requestedItems.length > 0) {
                return false;
            }
            return true;
        }
    },
    methods: {
        closeRequestNewSparePartsDialog() {
            this.lists = [];
            this.itemQty = 1;
            this.selectedItemModel = "";
            this.requestedItems = [];
            this.$emit('closeRequestNewSp', true);
            this.$store.commit('closeRequestNewSparePartsDialog');
            this.reasonSpareParts = '';
        },
        selectedItem() {

        },
        async addSpItem() {
            let selectedItem = null
            if(this.from_other_unit){
                await this.$store.dispatch('getItemsByModel',{
                    items:[this.otherParts.model],
                }).then(response=>{

                    if(!!response.data[0].item_id){
                        selectedItem = Object.assign({},{
                            ...response.data[0],
                            category_name:response.data[0].category,
                            id:response.data[0].item_id
                        })
                        this.otherParts.counter++
                    }else{
                        return false
                    };
                })
            }else{
                selectedItem = _.compact(this.lists).find(x=> x.id == this.selectedItemModel);
            }

            if(!!selectedItem) {

                let existingModel = this.requestedItems.find(x=>x.model == selectedItem.model.split(' / ')[0]);

                if(!!existingModel) {
                    let totalRequestQty = parseInt(existingModel.request_qty) + parseInt(this.itemQty)
                        existingModel.request_qty = totalRequestQty;
                } else {
                    this.requestedItems.push({
                        id: _.random(1, 999999999999),
                        item_id: selectedItem.id,
                        request_qty: this.itemQty,
                        part_num: selectedItem.part_num,
                        model: selectedItem.model.split(' / ')[0],
                        name: selectedItem.name,
                        category: selectedItem.category_name
                    })
                }

                this.selectedItemModel = "";
                this.itemQty = 1;
            }else{
                console.log(selectedItem)
                swal.fire('Error!','- Item not found','error')
            }
        },
        getModelSpareParts() {
            this.ItemSelectionLoading = true
            this.$store.dispatch('servicePost',{
                itemId: this.itemToSearch,
                partNumSuggestion: this.partNumSuggestion,
                url: 'getAllTechSpItems'
            }).then(response => {
                this.spLists = response.data;
                if(this.spLists.length > 0) {
                    this.spLists.forEach(x=>x.model = `${x.model} / ${x.part_num}`);
                }
                this.lists = this.spLists
                this.ItemSelectionLoading = false
            });
        },
        submitRequestNewSp() {
            let selected = _.compact(this.requestedItems).length;
            let filtered = _.uniqBy(_.compact(this.requestedItems), 'item_id').length;

            if(selected == 0) {
                swal.fire({
                    title: 'Select Spare Parts',
                    text: 'Make sure that all informations are correct',
                    icon: 'warning',
                    dangerMode: true,
                })
            } else if (!this.reasonSpareParts) {
                swal.fire('','- Please input a reason','info').then(confirm=>{
                    this.openReasons()
                });
            } else {
                if(!this.requiredFields()) {
                    if(this.otherParts.counter > 0 ){
                        // if out warranty,. no need for override
                        if(this.itemWarrantyType == 'Out-Warranty'){

                            let payload ={
                                rfId: this.rfId,
                                rfPartsOrderId: this.rfPartsOrderId,
                                itemWarrantyType: this.itemWarrantyType,
                                spItems: _.compact(this.requestedItems),
                                url : 'requestNewSpareParts'
                            }

                            this.$store.dispatch('servicePost',payload).then(response => {
                                if(response.data.msg) {
                                    this.closeRequestNewSparePartsDialog();
                                    this.$emit('successRequestNewSp', true);
                                    swal.fire("", response.data.msg, "success");
                                }
                            }).catch(e => {
                                swal.fire("", e.data.error, "error");
                            });
                        }else{
                            this.override.override_dialog = true
                        }

                    }else{
                        swal.fire({
                        title: 'Are you sure you want to submit?',
                        text: 'Make sure that all informations are correct',
                        icon: 'question',
                        showConfirmButton:true,
                        showCancelButton  :  true,
                        reverseButtons:true,
                        allowOutsideClick :  false,
                        confirmButtonColor: '#397373',
                        cancelButtonColor : 'grey',
                        confirmButtonText:'Confirm',
                        showCloseButton:true,
                    }).then((result) => {
                        if(result.isConfirmed) {
                            let payload ={
                                rfId: this.rfId,
                                rfPartsOrderId: this.rfPartsOrderId,
                                itemWarrantyType: this.itemWarrantyType,
                                spItems: _.compact(this.requestedItems),
                                url : 'requestNewSpareParts'
                            }

                            this.$store.dispatch('servicePost',payload).then(response => {
                                if(response.data.msg) {
                                    this.closeRequestNewSparePartsDialog();
                                    this.$emit('successRequestNewSp', true);
                                    swal.fire("", response.data.msg, "success");
                                }
                            }).catch(e => {
                                swal.fire("", e.data.error, "error");
                            });
                        }
                    });
                    }
                }
            }
        },
        deleteSpItem(sp) {
            this.requestedItems = this.requestedItems.filter(x => x.id != sp.id)
        },
        closeConfirmOverride(user_id, authenticated = false){
                if(authenticated){
                    swal.fire({
                        title: 'Are you sure you want to submit?',
                        text: 'Make sure that all informations are correct',
                        icon: 'question',
                        showConfirmButton:true,
                        showCancelButton  :  true,
                        reverseButtons:true,
                        allowOutsideClick :  false,
                        confirmButtonColor: '#397373',
                        cancelButtonColor : 'grey',
                        confirmButtonText:'Confirm',
                        showCloseButton:true,
                    }).then((result) => {
                        if(result.isConfirmed) {
                            let payload ={
                                rfId: this.rfId,
                                rfPartsOrderId: this.rfPartsOrderId,
                                itemWarrantyType: this.itemWarrantyType,
                                spItems: _.compact(this.requestedItems),
                                url : 'requestNewSpareParts'
                            }

                            this.$store.dispatch('servicePost',payload).then(response => {
                                if(response.data.msg) {
                                    this.closeRequestNewSparePartsDialog();
                                    this.$emit('successRequestNewSp', true);
                                    swal.fire("", response.data.msg, "success");
                                }
                            }).catch(e => {
                                swal.fire("", e.data.error, "error");
                            });
                        }
                    });
                }
                document.activeElement.blur();
                this.override.override_dialog = false;
                this.override_confirm = true;
                this.add_edit_statementOfAccount_dialog = false;
                this.otherParts.counter = 0
        },

        openReasons() {
            this.reasonDialog = true;
        },

        closeReasons() {
            this.reasonDialog = false;
        },

        submitReasons() {
            if(!this.reasonSpareParts) {
                swal.fire('','- Please input a reason','info')
            } else {
                swal.fire({
                    title: '- Are you sure you want to submit reason?',
                    text: 'Click Ok To Proceed',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                }).then(async confirm => {
                    if(confirm.isConfirmed) {
                        await this.$store.dispatch('servicePost', {
                            rfPartsOrderId: this.rfPartsOrderId,
                            reason: this.reasonSpareParts,
                            url: 'reasonForRequote'
                        }).then(response => {
                            swal.fire('Success!','','success')
                            this.closeReasons();
                        }).catch(err=> {
                            swal.fire('Error!','','error')
                            console.error(err)
                        })
                    }
                })
            } 
        },
        requiredFields() {
            let warning = '';
            if(this.requestedItems.length == 0) {
                warning += 'Please select Requested Items.<br>';
            }

            if (warning !== '') return swal.fire({
                title: 'Please Fill out Information:',
                html: warning,
                icon: "warning",
            })
        },
    }
}
</script>

<style>

</style>
