<template>
    <v-container class="pa-2 ma-0">
        <h4 class="page-title">Stock Card</h4>
        <v-container class="ma-0 pa-1">
            <v-app id="item-tabs" class="mt-5">
                <v-tabs
                    v-model="tab"
                    id="group-tab"
                    color="cyan"
                    slider-color="cyan"
                    background-color="#424242"
                    dark
                    class="col-12"
                    show-arrows
                >
                    <v-tab class="ml-3" ripple v-if="userAccess.view">Packing Lists</v-tab>
                    <v-tab class="ml-3" ripple>Stock Cards</v-tab>

                    <v-tabs-items v-model="tab" touchless>
                        <!-- STOCK CARD PL TAB -->
                        <v-tab-item v-if="userAccess.view">
                            <packing-lists-component
                                :is_preparation="0"
                                :is_accounting="0"
                                :is_stockcard="1"
                            ></packing-lists-component>
                        </v-tab-item>

                        <!-- ALL STOCK CARD TAB -->
                        <v-tab-item>
                            <stock-cards-component></stock-cards-component>
                        </v-tab-item>
                    </v-tabs-items>
                </v-tabs>
            </v-app>
        </v-container>
    </v-container>
</template>

<script>
import PackingListsComponent from "@/views/main/modules/Sales/PackingList/PackingListsComponent.vue";
import StockCardsComponent from "@/views/main/modules/Operations/StockCards/StockCardComponent.vue";
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            userAccess: {
                view: "",
            },
            tab: null,
        };
    },
    computed: {
        ...mapGetters([
            "USER_ACCESS"
        ]),
    },
    components: {
        PackingListsComponent,
        StockCardsComponent,
    },
    methods: {
        async checkAccess() {
            this.$store.dispatch("checkAccessUser", this.$router.currentRoute.params.id).then((response) => {
                if (response != "fail") {
                    this.userAccess.view = false;
                    response.forEach((e) => {
                        if (e.actions_code == "view") {
                            this.userAccess.view = true;
                        }
                    });
                }
            });
        },
    },
    mounted() {
        this.checkAccess();
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
        this.$store.dispatch("getAllWarehouses", {});
    },
    // watch:{
    //     USER_ACCESS: {
    //   handler(val) {
    //       if(val != "fail"){
    //        this.userAccess.view = false;
    //     val.forEach(e => {
    //         if (e.actions_code == 'view') {
    //         this.userAccess.view = true;
    //       }
    //     });
    //     }
    //   },
    // },
    // }
};
</script>
<style>
#group-tab {
    padding: 0 !important;
}
</style>
