<template>
    <div class="page-wrapper">
        <div class="row p-2">
            <div class="col-12 d-flex no-block align-items-center">
                <h4 class="page-title">Technician Manager</h4>
            </div>
        </div>
        <div class="container">
            <v-tabs v-model="tab" id="group-tab" color="cyan" dark slider-color="cyan" background-color="#424242" class="col-12 pb-0" show-arrows>
                <v-tab ripple>Loads</v-tab>
                <v-tab ripple>Technicians</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab" touchless style="background-color:unset">
                <v-tab-item>
                    <TechnicianLoadsComponentVue />
                </v-tab-item>
                <v-tab-item>
                    <v-layout row class="mx-0">
                        <v-flex shrink>
                            <AddTechnicianComponentVue />
                        </v-flex>

                        <v-spacer />

                        <v-flex shrink>
                            <v-switch
                                v-model="tableView"
                                label="Table View"
                                color="primary"
                            >
                                <template v-slot:label>
                                    <span style="color:white">Table View</span>
                                </template>
                            </v-switch>
                        </v-flex>
                    </v-layout>

                    <TechnicianTableComponentVue v-if="tableView" />
                    <TechnicianCardComponentVue v-else />
                </v-tab-item>
            </v-tabs-items>
        </div>
    </div>
</template>

<script>
import TechnicianLoadsComponentVue from './TechnicianManagerComponent/TechnicianLoadsComponent.vue';
import AddTechnicianComponentVue from '../Views/TechnicianManagerComponent/AddTechnicianComponent.vue';
import TechnicianCardComponentVue from '../Views/TechnicianManagerComponent/TechnicianCardComponent.vue';
import TechnicianTableComponentVue from '../Views/TechnicianManagerComponent/TechnicianTableComponent.vue';

export default {
    data() {
        return {
            tab: null,
            tableView: false
        }
    },
    mounted() {
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
    },
    components: {
        TechnicianLoadsComponentVue,
        AddTechnicianComponentVue,
        TechnicianTableComponentVue,
        TechnicianCardComponentVue
    },
    methods: {
    }
}
</script>

<style>

</style>
