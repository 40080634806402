<template>
    <v-form ref="form" v-model="valid" lazy-validation>
        <!-- Table -->
        <v-card class="px-3 rounded-0">
            <v-card-subtitle>
                <v-row>
                    <v-col lg="3">
                        <v-btn v-if="userAccess.includes('create')" class="m-3"
                            @click="()=>{
                                scheduleDialog = !scheduleDialog
                                $store.commit('RECURRING_BILL_ACTION', 'ADD')
                            }"
                        >
                            <v-icon>mdi-plus</v-icon>Add
                        </v-btn>
                    </v-col>
                    <v-spacer/>
                    <v-col lg="1" class="d-flex align-items-center">
                        <span :class="`badge ml-1 text-white ${is_active === -1 ? 'bg-dark' : 'bg-secondary'}`" style="cursor:pointer" @click="is_active = -1;getAllSchedules(); ">all</span>
                        <span :class="`badge ml-1 text-white ${is_active === 1 ? 'bg-primary' : 'bg-secondary'}`" style="cursor:pointer" @click="is_active = 1;getAllSchedules(); ">Active</span>
                        <span :class="`badge ml-1 text-white ${is_active === 0 ? 'bg-danger' : 'bg-secondary'}`" style="cursor:pointer" @click="is_active = 0;getAllSchedules(); ">Inactive</span>
                    </v-col>
                </v-row>
                <v-row class="ml-0">
                    <v-col lg="2">
                        <v-autocomplete
                            v-model="filters.prepared_by"
                            :items="GET_PREPARED_BY"
                            label="Prepared By"
                            outlined
                            dense
                            auto-select-first
                            hide-details
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col lg="2">
                        <v-autocomplete
                            v-model="filters.company_id"
                            :items="GET_PV_COMPANIES_SELECTION"
                            label="Company"
                            outlined
                            dense
                            auto-select-first
                            hide-details
                            clearable
                            @change="insertStore(1)"
                        ></v-autocomplete>
                    </v-col>
                    <v-col lg="2">
                        <v-autocomplete
                            v-model="filters.store_branches_id"
                            :items="branchItemsForFilter"
                            label="Store Branch"
                            no-data-text="Please Select a Company"
                            outlined
                            dense
                            auto-select-first
                            hide-details
                            clearable
                            @change="insertCompany(filters.store_branches_id, 1)"
                        ></v-autocomplete>
                    </v-col>
                    <v-col lg="2">
                        <v-autocomplete
                            v-model="filters.vendor_id"
                            :items="GET_ADMIN_SUPPLIER_SELECTION"
                            label="Vendor"
                            outlined
                            dense
                            auto-select-first
                            hide-details
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="1" class="my-auto pl-2">
                        <v-btn @click="getAllSchedules()">
                            FILTER
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-spacer/>
                    <v-col lg="3" class="pull-right">
                        <v-text-field
                            v-model="search"
                            label="Search by Account No."
                            append-icon="mdi-magnify"
                            @keydown.enter="getAllSchedules()"
                            @click:append="getAllSchedules()"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card-subtitle>
            <v-card-text>
                <v-row>
                    <v-col lg="12">
                        <v-data-table
                            :headers="schedulesHeader"
                            :items="schedules"
                            :options.sync="options"
                            :server-items-length="totalScheds"
                            :items-per-page="10"
                            :loading="tableLoad"
                            :footer-props="{
                                itemsPerPageOptions: itemsPerPageOptions
                            }"
                            dense
                        >
                            <template v-slot:[`item.amount`]="{ item }">
                                <span>
                                    &#x20b1;{{ item.amount | currency }}
                                </span>
                            </template>
                            <template v-slot:[`item.frequency_parameter`]="{ item }">
                                <span>
                                    {{ formatFrequency(item.frequency_parameter, item.frequency_type_id) }}
                                </span>
                            </template>
                            <template v-slot:[`item.billing_period`]="{ item }">
                                <span>
                                    {{ formatDueDate(item.billing_period) }}
                                </span>
                            </template>
                            <template v-slot:[`item.created_at`]="{ item }">
                                <span>
                                    {{ item.created_at | formatDate }}
                                </span>
                            </template>
                            <template v-slot:[`item.active`]="{ item }">
                                <span class="badge badge-primary" v-if="item.active">Yes</span>
                                <span class="badge badge-danger" v-else>No</span>
                            </template>
                            <template v-slot:[`item.action`]="{ item }">
                                <v-btn v-if="userAccess.includes('view')" text icon color="orange" @click="viewEdit('VIEW',item.id)">
                                    <v-icon small>mdi-eye</v-icon>
                                </v-btn>
                                <v-btn v-if="userAccess.includes('edit')" text icon color="blue" @click="viewEdit('EDIT',item.id)">
                                    <v-icon small>mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn v-if="userAccess.includes('delete')" text icon color="red" @click="destroy(item.id)">
                                    <v-icon small>mdi-delete</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
            <!-- Dialog -->
            <v-dialog v-model="scheduleDialog" persistent scrollable width="60%">
                <v-card elevation="0" class="pa-0 m-0 overflow-hidden">
                    <v-card-title v-if="!dialogLoader">
                        <v-container class="pa-0">
                            <v-row class="m-0">
                                <v-col>
                                    <span class="headline">Add Schedule</span>
                                </v-col>
                                <v-col cols="pull-right-1">
                                    <v-btn text icon color="gray" class="float-right"
                                        @click="()=>{
                                            scheduleDialog = !scheduleDialog
                                            clearFields()
                                        }"
                                    >
                                        <v-icon>mdi-close-circle</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-card elevation="0" dense>
                                <v-card-subtitle>
                                    <v-row>
                                        <v-col lg="3">
                                            <strong>Frequency:</strong>
                                        </v-col>
                                    </v-row>
                                </v-card-subtitle>
                                <v-card-text class="mt-3">
                                    <v-row>
                                        <v-col cols="2">
                                            <v-autocomplete
                                                v-model="form.frequency_type_id"
                                                :items="GET_FREQUENCY_SELECTION"
                                                :readonly="isDisabled()"
                                                :rules="required"
                                                label="Frequencies"
                                                outlined
                                                dense
                                                @change="resetFrequency()"
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="2" v-show="form.frequency_type_id == 5">
                                            <v-autocomplete
                                                v-model="form.frequency_parameter"
                                                :items="days"
                                                item-text="text"
                                                item-value="value"
                                                append-icon="mdi-calendar"
                                                label="Day"
                                                dense
                                                outlined
                                                :readonly="isDisabled()"
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="2" v-show="[6,9].includes(form.frequency_type_id)">
                                            <v-autocomplete
                                                v-model="form.frequency_parameter"
                                                :items="dates"
                                                append-icon="mdi-calendar"
                                                label="Date"
                                                dense
                                                outlined
                                                :readonly="isDisabled()"
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="2" v-show="[2].includes(form.frequency_type_id)">
                                            <v-menu
                                                :close-on-content-click="true"
                                                transition="scale-transition"
                                                max-width="290"
                                                :disabled="isDisabled()"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        v-model="form.frequency_parameter"
                                                        v-on="on"
                                                        :readonly="isDisabled()"
                                                        dense
                                                        readonly
                                                        append-icon="mdi-calendar"
                                                        label="Billing Date"
                                                        outlined
                                                    ></v-text-field>
                                                </template>
                                                    <v-date-picker v-model="form.frequency_parameter" :min="minDate" />
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="2" v-show="[8].includes(form.frequency_type_id)">
                                            <v-autocomplete
                                                v-model="form.first_half_month"
                                                :items="months"
                                                append-icon="mdi-calendar"
                                                label="Month"
                                                dense
                                                outlined
                                                :readonly="isDisabled()"
                                                @change="datesByMonth(form.first_half_month)"
                                                @input="insertAnnualDate()"
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="2" v-show="[8].includes(form.frequency_type_id)">
                                            <v-autocomplete
                                                v-model="form.first_half_date"
                                                :items="dates"
                                                append-icon="mdi-calendar"
                                                label="Date"
                                                dense
                                                outlined
                                                :readonly="isDisabled()"
                                                @input="insertAnnualDate()"
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col v-show="[10].includes(form.frequency_type_id)" cols="2">
                                            <v-menu
                                                v-model="firstHalfMenu"
                                                :close-on-content-click="false"
                                                :close-on-click="true"
                                                :disabled="isDisabled()"
                                                nudge-bottom="45"
                                                transition="slide-y-transition"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        v-model="form.first_half"
                                                        v-on="on"
                                                        :readonly="isDisabled()"
                                                        dense
                                                        readonly
                                                        append-icon="mdi-calendar"
                                                        label="1st Half"
                                                        outlined
                                                    ></v-text-field>
                                                </template>
                                                    <v-card min-width="300" min-height="60">
                                                        <v-container class="pa-5">
                                                            <v-row class="justify-center align-center">
                                                                <v-col cols="6">
                                                                    <v-autocomplete
                                                                        v-model="form.first_half_month"
                                                                        :items="months.filter(e => [1,2,3,4,6,6].includes(e.value))"
                                                                        append-icon="mdi-calendar"
                                                                        label="Month"
                                                                        dense
                                                                        outlined
                                                                        :readonly="isDisabled()"
                                                                        @change="datesByMonth(form.first_half_month)"
                                                                    ></v-autocomplete>
                                                                </v-col>
                                                                <v-col cols="6">
                                                                    <v-autocomplete
                                                                        v-model="form.first_half_date"
                                                                        :items="dates"
                                                                        append-icon="mdi-calendar"
                                                                        label="Date"
                                                                        dense
                                                                        outlined
                                                                        :readonly="!form.first_half_month || isDisabled()"
                                                                        @change="insertSemiAnnualData(1)"
                                                                    ></v-autocomplete>
                                                                </v-col>
                                                            </v-row>
                                                        </v-container>
                                                    </v-card>
                                            </v-menu>
                                        </v-col>
                                        <v-col v-show="[10].includes(form.frequency_type_id)" cols="2">
                                            <v-menu
                                                v-model="secondHalfMenu"
                                                :close-on-content-click="false"
                                                :close-on-click="true"
                                                :disabled="isDisabled()"
                                                nudge-bottom="45"
                                                transition="slide-y-transition"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        v-model="form.second_half"
                                                        v-on="on"
                                                        :readonly="isDisabled()"
                                                        dense
                                                        readonly
                                                        append-icon="mdi-calendar"
                                                        label="2nd Half"
                                                        outlined
                                                    ></v-text-field>
                                                </template>
                                                    <v-card min-width="300" min-height="60">
                                                        <v-container class="pa-5">
                                                            <v-row class="justify-center align-center">
                                                                <v-col cols="6">
                                                                    <v-autocomplete
                                                                        v-model="form.second_half_month"
                                                                        :items="months.filter(e => [7,8,9,10,11,12].includes(e.value))"
                                                                        append-icon="mdi-calendar"
                                                                        label="Month"
                                                                        dense
                                                                        outlined
                                                                        :readonly="isDisabled()"
                                                                        @change="datesByMonth(form.second_half_month)"
                                                                    ></v-autocomplete>
                                                                </v-col>
                                                                <v-col cols="6">
                                                                    <v-autocomplete
                                                                        v-model="form.second_half_date"
                                                                        :items="dates"
                                                                        append-icon="mdi-calendar"
                                                                        label="Date"
                                                                        dense
                                                                        outlined
                                                                        :readonly="!form.second_half_month || isDisabled()"
                                                                        @change="insertSemiAnnualData(2)"
                                                                    ></v-autocomplete>
                                                                </v-col>
                                                            </v-row>
                                                        </v-container>
                                                    </v-card>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="2" v-show="form.frequency_type_id == 1">
                                            <v-menu
                                                v-model="menu.durationFrom"
                                                :close-on-content-click="false"
                                                :close-on-click="true"
                                                :disabled="isDisabled()"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        v-model="form.first_half"
                                                        v-on="on"
                                                        :readonly="isDisabled()"
                                                        dense
                                                        readonly
                                                        append-icon="mdi-calendar"
                                                        label="From"
                                                        outlined
                                                    ></v-text-field>
                                                </template>
                                                    <v-date-picker v-model="form.first_half" @input="menu.durationFrom = false" type="month" :min="minDate" @change="insertDuration()" />
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="2" v-show="form.frequency_type_id == 1">
                                            <v-menu
                                                v-model="menu.durationTo"
                                                :close-on-content-click="false"
                                                :close-on-click="true"
                                                :disabled="isDisabled()"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        v-model="form.second_half"
                                                        v-on="on"
                                                        :readonly="isDisabled()"
                                                        dense
                                                        readonly
                                                        append-icon="mdi-calendar"
                                                        label="To"
                                                        outlined
                                                    ></v-text-field>
                                                </template>
                                                    <v-date-picker v-model="form.second_half" @input="menu.durationTo = false" type="month" :min="minDate" @change="insertDuration()" />
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="2" v-show="form.frequency_type_id == 1">
                                            <v-autocomplete
                                                v-model="form.frequency_parameter"
                                                :items="dates"
                                                label="Bill Date"
                                                dense
                                                outlined
                                                :readonly="isDisabled()"
                                                @change="insertDuration()"
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="2" v-show="![1,3,10].includes(form.frequency_type_id)">
                                            <v-menu
                                                v-model="timeMenu"
                                                :close-on-content-click="false"
                                                :disabled="isDisabled()"
                                                transition="scale-transition"
                                                max-width="290"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        v-model="form.frequency_time"
                                                        v-on="on"
                                                        :readonly="isDisabled()"
                                                        dense
                                                        readonly
                                                        append-icon="mdi-clock-time-four-outline"
                                                        label="Time"
                                                        outlined
                                                    ></v-text-field>
                                                </template>
                                                    <v-time-picker v-model="form.frequency_time" @click:minute="timeMenu = false" scrollable />
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="2">
                                            <v-text-field
                                                v-model="form.billing_period"
                                                :readonly="isDisabled()"
                                                type="number"
                                                dense
                                                label="Due Date (Day/s)"
                                                outlined
                                                hide-spin-buttons
                                            ></v-text-field>
                                        </v-col>
                                            <v-spacer/>
                                        <v-col cols='1' v-if="RECURRING_BILL.ACTION != 'ADD'">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn :color="form.active == 1 ? 'primary' : 'error'" style="transform: translate(40px, -10px);" text fab @click="()=>{
                                                            if(RECURRING_BILL.ACTION == 'EDIT'){
                                                                form.active = form.active == 1 ? 0 : 1
                                                            }
                                                        }"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                        <v-icon >
                                                            mdi-circle-slice-8
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                    <span>{{form.active == 1 ? 'Active' : 'Inactive'}}</span>
                                            </v-tooltip>
                                            <!-- <div style="transform: translate(40px, -10px);">
                                                <v-switch
                                                    v-model="form.active"
                                                    color="info"
                                                    @change="form.active ? form.active = 1 : 0"
                                                    :readonly="isDisabled()"
                                                />
                                                <span>{{ form.active ? 'Active' : 'Inactive' }}</span>
                                            </div> -->
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <v-card elevation="0" dense>
                                <v-card-subtitle>
                                    <v-row>
                                        <v-col lg="3">
                                            <strong>Billing Info:</strong>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4">
                                            <v-autocomplete
                                                v-model="form.vendor_id"
                                                :items="GET_ADMIN_SUPPLIER_SELECTION"
                                                :readonly="isDisabled()"
                                                :rules="required"
                                                :disabled="requiredFirst"
                                                label="Select Vendor"
                                                outlined
                                                dense
                                            ></v-autocomplete>
                                            <v-autocomplete
                                                v-model="form.recurring_bill_type_id"
                                                :items="GET_RB_TYPES"
                                                :readonly="isDisabled()"
                                                :rules="required"
                                                :disabled="requiredFirst"
                                                label="Select Type"
                                                auto-select-first
                                                outlined
                                                dense
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-autocomplete
                                                v-model="form.company_id"
                                                :items="GET_PV_COMPANIES_SELECTION"
                                                :readonly="isDisabled()"
                                                :rules="required"
                                                :disabled="requiredFirst"
                                                label="Select Company"
                                                outlined
                                                dense
                                                @change="insertStore(2)"
                                            ></v-autocomplete>
                                            <v-text-field
                                                v-model="form.account_num"
                                                :readonly="isDisabled()"
                                                :disabled="requiredFirst"
                                                :rules="required"
                                                label="Account Number"
                                                outlined
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-autocomplete
                                                v-model="form.store_branches_id"
                                                :items="branchItems"
                                                :readonly="isDisabled()"
                                                :rules="required"
                                                :disabled="requiredFirst"
                                                label="Select Branch"
                                                no-data-text="No Stores Found!"
                                                outlined
                                                dense
                                                @change="insertCompany(form.store_branches_id, 2)"
                                            ></v-autocomplete>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col lg="3">
                                            <strong>Optional Details:</strong>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4">
                                            <v-autocomplete
                                                v-model="form.employee_id"
                                                :items="GET_EMPLOYEE_DATA"
                                                :readonly="isDisabled()"
                                                :disabled="requiredFirst"
                                                item-value="value"
                                                item-text="full_name"
                                                label="Employee (Optional)"
                                                outlined
                                                dense
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field
                                                v-model="form.particulars"
                                                :readonly="isDisabled()"
                                                :disabled="requiredFirst"
                                                label="Particulars (Optional)"
                                                outlined
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field
                                                v-model="form.address"
                                                :readonly="isDisabled()"
                                                :disabled="requiredFirst"
                                                label="Address (Optional)"
                                                outlined
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row class="mt-5">
                                        <v-spacer/>
                                            <strong class="pt-3 mr-1">Amount: </strong>
                                        <v-col cols="3">
                                            <v-text-field
                                                v-model="form.amount"
                                                :readonly="isDisabled()"
                                                :rules="required"
                                                :disabled="requiredFirst"
                                                placeholder="0.00"
                                                type="number"
                                                outlined
                                                dense
                                                hide-spin-buttons
                                                class="right-align-text"
                                            >
                                                <template v-slot:prepend-inner>
                                                    <v-icon small>mdi-currency-php</v-icon>
                                                </template>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row class="mt-5">
                                        <v-col cols="12">
                                            <v-textarea
                                                v-model="form.remarks"
                                                :readonly="isDisabled()"
                                                :disabled="requiredFirst"
                                                label="Remarks (Optional)"
                                                outlined
                                                dense
                                                auto-grow
                                            ></v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-card-subtitle>
                            </v-card>
                                <v-divider v-if="RECURRING_BILL.ACTION == 'EDIT'"/>
                            <v-container>
                                <v-row v-if="RECURRING_BILL.ACTION == 'ADD'">
                                    <v-col class="text-center">
                                        <v-btn :loading="btnLoad" @click="addSchedule()">
                                            SUBMIT
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row v-if="RECURRING_BILL.ACTION == 'EDIT'">
                                    <v-col class="text-center">
                                        <v-btn :loading="btnLoad" @click="updateSchedule()">
                                            UPDATE
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-container>
                    </v-card-title>
                    <div v-else class="text-center pa-2">
                        <v-progress-circular
                            :size="80"
                            color="primary"
                            indeterminate
                        ></v-progress-circular>
                    </div>
                </v-card>
            </v-dialog>
        </v-card>
    </v-form>
</template>
<script>
import { mapGetters } from 'vuex'
import swal from 'sweetalert2'
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue'
export default {
    mixins:[SharedFunctionsComponent],
    props: ['userAccess'],
    data(){
        return{
            valid: true,
            timeMenu: false,
            scheduleDialog: false,
            schedulesHeader:[
                { text: 'Company', value: 'company_name', sortable: false },
                { text: 'Store Branch', value: 'store_branch_name', sortable: false },
                // { text: 'Employee', value: 'employee_name' },
                // { text: 'Type', value: 'type' },
                { text: 'Vendor', value: 'vendor_name', sortable: false },
                { text: 'Account No.', value: 'account_num', sortable: false },
                { text: 'Amount', value: 'amount', align: 'right', sortable: false },
                { text: 'Frequency', value: 'frequency_parameter', sortable: false },
                { text: 'Due Date', value: 'billing_period', sortable: false },
                { text: 'Prepared By', value:'prepared_by_name', align:'left', sortable: false },
                { text: 'Updated By', value:'updated_by_name', align:'left', sortable: false },
                { text: 'Active', value: 'active', sortable: false },
                { text: 'Actions', value: 'action', align: 'center', sortable: false }
            ],
            tableLoad: false,
            form: {
                id: null,
                company_id: null,
                store_branches_id: null,
                employee_id: null,
                vendor_id: null,
                account_num: '',
                particulars: '',
                remarks: '',
                address: '',
                amount: '',
                // type: null,
                frequency_type_id: 6,
                frequency_parameter: '',
                frequency_time: '',
                billing_period: null,
                first_half: '',
                second_half: '',
                first_half_month: '',
                first_half_date: '',
                second_half_month: '',
                second_half_date: '',
                active: 1,
                recurring_bill_type_id : null
            },
            days: [
                { text: 'Sunday', value: '1' },
                { text: 'Monday', value: '2' },
                { text: 'Tuesday', value: '3' },
                { text: 'Wednesday', value: '4' },
                { text: 'Thursday', value: '5' },
                { text: 'Friday', value: '6' },
                { text: 'Saturday', value: '7' }
            ],
            months: [
                { text: 'January', value: 1 },
                { text: 'February', value: 2 },
                { text: 'March', value: 3 },
                { text: 'April', value: 4 },
                { text: 'May', value: 5 },
                { text: 'June', value: 6 },
                { text: 'July', value: 7 },
                { text: 'August', value: 8 },
                { text: 'September', value: 9 },
                { text: 'October', value: 10 },
                { text: 'November', value: 11 },
                { text: 'December', value: 12 }
            ],
            dates: [],
            schedules: [],
            options: {},
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            itemsPerPage: 10,
            totalScheds: 0,
            filters:{
                prepared_by: null,
                company_id: null,
                store_branches_id: null,
                vendor_id: null
            },
            btnLoad: false,
            minDate: this.$dayjs().format("YYYY-MM-DD"),
            dialogLoader: false,
            firstHalfMenu: false,
            secondHalfMenu: false,
            search:'',
            is_active:-1,
            required: [ v => !!v || 'This field is required' ],
            clonedStores: [],
            menu: {
                frequency_parameter: false,
                durationFrom: false,
                durationTo: false
            }
        }
    },
    computed: {
        ...mapGetters([
            'USER_ACCESS',
            'RECURRING_BILL',
            'GET_PV_COMPANIES_SELECTION',
            'GET_PV_STORE_BRANCHES_SELECTION',
            'GET_EMPLOYEE_DATA',
            'GET_ADMIN_SUPPLIER_SELECTION',
            'GET_FREQUENCY_SELECTION',
            'GET_RB_TYPES',
            'GET_PREPARED_BY'
        ]),
        requiredFirst(){
            let required = []
            if (!this.form.frequency_type_id) return true
            switch (this.form.frequency_type_id) {
                case 1:
                    required = ['first_half', 'second_half']
                        break
                case 5:
                case 6:
                case 9:
                case 2:
                case 9:
                    required = ['frequency_type_id', 'frequency_parameter', 'frequency_time']
                        break
                case 8:
                    required = ['frequency_type_id', 'first_half', 'frequency_parameter', 'frequency_time']
                    break
                case 10:
                    required = ['frequency_type_id', 'first_half', 'second_half']
                    break
                case 4:
                case 7:
                    required = ['frequency_type_id', 'frequency_time']
                    break
                case 3:
                    required = []
                    break
            }
            return required.some(e=>!this.form[e])
        },
        branchItems(){
            let items = this.clonedStores.filter(e=>e.company_id == this.form.company_id)
            if (!this.form.company_id) return this.clonedStores
            if (!this.form.store_branches_id) this.form.store_branches_id = items[0].value
            return items
        },
        branchItemsForFilter(){
            let items = this.clonedStores.filter(e=>e.company_id == this.filters.company_id)
            if (!this.filters.company_id) return this.clonedStores
            if (!this.filters.store_branches_id) this.filters.store_branches_id = items[0].value
            return items
        },
    },
    mounted(){
        this.getAllSchedules()
        this.insertDates(1)
        this.getBranches()
    },
    methods:{
        toggleDialog(action){
            let that = this
            switch(action){
                case 'open':
                    that.scheduleDialog = true
                    break;
                case 'close':
                    that.scheduleDialog = false
                    that.clearFields()
                    break;
            }
        },
        getAllSchedules(){
            this.tableLoad = true
            this.schedules = []
            let payload = {
                prepared_by: this.filters.prepared_by,
                company_id: this.filters.company_id,
                store_branches_id: this.filters.store_branches_id,
                vendor_id: this.filters.vendor_id,
                search: this.search,
                is_active: this.is_active,
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                url: 'getAll',
                schedules: 1
            }
            this.$store.dispatch('recurringBillGet', payload).then(response=>{
                this.schedules = response.data.data.data
                this.totalScheds = response.data.data.total
                this.tableLoad = false
            })
        },
        clearFields(){
            this.$refs.form.resetValidation()
            this.$store.commit('RECURRING_BILL_ACTION', '')
            this.form = {
                id: null,
                company_id: null,
                store_branches_id: null,
                employee_id: null,
                vendor_id: null,
                account_num: '',
                particulars: '',
                remarks: '',
                address: '',
                amount: '',
                // type: null,
                frequency_type_id: null,
                frequency_parameter: '',
                frequency_time: '',
                billing_period: null,
                first_half: '',
                second_half: '',
                first_half_month : '',
                first_half_date : '',
                second_half_month : '',
                second_half_date : '',
                active: 1
            }
            this.getStoreBranches(1)
        },
        addSchedule(){
            if (!this.$refs.form.validate()) {
                if (!this.checkRequiredFields()) return
            } else {
                this.btnLoad = true
                swal.fire({
                    title: "",
                    text: "Are you sure you want to submit schedule?",
                    icon: "question",
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                }).then((result) => {
                    if (result.isConfirmed) {
                        let payload = {
                            form : this.form,
                            url : 'addSchedule'
                        }
                        this.$store.dispatch('recurringBillPost', payload).then(response => {
                            swal.fire('', 'Schedule Added!', 'success')
                            this.getAllSchedules()
                            this.toggleDialog('close')
                            this.btnLoad = false
                        }).catch( error => {
                            if (error.response.status == 422) {
                                swal.fire('', 'Error!', 'error')
                                console.log(error)
                                this.btnLoad = false
                            }
                        })
                    } else {
                        this.btnLoad = false
                    }
                })
            }
        },
        updateSchedule(){
            if (!this.$refs.form.validate()) {
                if (!this.checkRequiredFields()) return
            } else {
                this.btnLoad = true
                swal.fire({
                    title: "",
                    text: "Are you sure you want to update the schedule?",
                    icon: "question",
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                }).then((result) => {
                    if (result.isConfirmed) {
                        let payload = {
                            form : this.form,
                            url : 'updateSchedule'
                        }
                        this.$store.dispatch('recurringBillPost', payload).then(response => {
                            swal.fire('', 'Schedule Updated!', 'success')
                            this.getAllSchedules()
                            this.toggleDialog('close')
                            this.btnLoad = false
                        }).catch( error => {
                            if (error.response.status == 422) {
                                swal.fire('', 'Error!', 'error')
                                console.log(error)
                                this.btnLoad = false
                            }
                        })
                    } else {
                        this.btnLoad = false
                    }
                })
            }
        },
        viewEdit(action,id){
            this.dialogLoader = true
            this.toggleDialog('open')
            this.action = this.$store.commit('RECURRING_BILL_ACTION', action)
            let payload = {
                id: id,
                url: 'getSchedule'
            }
            this.$store.dispatch('recurringBillPost', payload).then(response=>{
                this.form = { ...response.data }
                this.form.amount = parseFloat(this.form.amount)
                this.getStoreBranches(2)
                if (this.form.frequency_type_id == 1) {
                    let params = this.form.frequency_parameter.split(',')
                    this.form.frequency_parameter = params[0]
                    this.form.first_half = params[1]
                    this.form.second_half = params [2]
                }
                if (this.form.frequency_type_id == 8) {
                    let date = this.form.frequency_parameter.split(' ')
                    this.form.first_half_month = this.months.find(e=>e.text == date[0])
                    this.form.first_half_date = date[1].trim()
                }
                if (this.form.frequency_type_id == 10) {
                    let dates = this.form.frequency_parameter.split(',')
                    this.form.first_half = dates[0].trim()
                    this.form.second_half = dates[1].trim()
                }
                this.dialogLoader = false
            })
        },
        destroy(id){
            swal.fire({
                title: "",
                text: "Are you sure you want to delete the Schedule?",
                icon: "question",
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
            }).then((result) => {
                if (result.isConfirmed) {
                    let payload = {
                        id: id,
                        url: 'deleteSchedule'
                    }
                    this.$store.dispatch('recurringBillPost', payload).then(response => {
                        swal.fire("", "Schedule Deleted!", "success")
                        this.getAllSchedules()
                    }).catch(error => {
                        if (error.response.status == 422) {
                            swal.fire("", "Error!", "error");
                            console.log(error)
                        }
                        this.getAllSchedules()
                    })
                }
            })
        },
        isDisabled(){
            switch(this.RECURRING_BILL.ACTION){
                case 'VIEW':
                    return true
            }
        },
        resetFrequency(){
            if (this.RECURRING_BILL.ACTION == 'ADD') {
                this.form.frequency_parameter = ''
                this.form.billing_period = ''
                this.form.frequency_time = ''
                this.form.first_half = '',
                this.form.second_half = '',
                this.form.first_half_month = '',
                this.form.first_half_date = '',
                this.form.second_half_month = '',
                this.form.second_half_date = ''
            }
            this.insertDates(1)
        },
        formatFrequency(param, type){
            let date = ''
            switch(type){
                case 1:
                    date = this.$dayjs().date(param).format('D')
                    return `Every ${date} of the Month`
                case 2:
                    date = this.$dayjs(param).format('MMMM Do YYYY')
                    return `${date}`
                case 3:
                    return 'Every Hour'
                case 4:
                    return `Every Day`
                case 5:
                    let day = this.days.find(e=>e.value == param)
                    return `Every ${day.text}`
                case 6:
                    date = this.$dayjs().date(param).format('D')
                    return `Every ${date} of the Month`
                case 7:
                    return `Every End of the Month`
                case 8:
                    return `Every Year at ${param}`
                case 9:
                    date = this.$dayjs().date(param).format('Do')
                    return `Every Quarter on the ${date}`
                case 10:
                    date = param.split(',')
                    return `Every ${date[0]} & ${date[1]}`
            }
        },
        formatDueDate(period){
            if (period == null) {
                return ''
            } else if (period != 1) {
                return `${period} days after billing`
            } else {
                return `${period} day after billing`
            }
        },
        insertDates(source){
            switch (source) {
                case 1:
                    for (let i = 1; i <= 31; i++) {
                        this.dates.push({ text: i.toString(), value: i.toString() })
                    }
                        break;
                case 2:
                    for (let i = 1; i <= 29; i++) {
                        this.dates.push({ text: i.toString(), value: i.toString() })
                    }
                        break;
                case 3:
                    for (let i = 1; i <= 30; i++) {
                        this.dates.push({ text: i.toString(), value: i.toString() })
                    }
                        break;
            }
        },
        checkRequiredFields(){
            let emptyFields = []
            const required = {
                'frequency_type_id': 'Frequencies',
                'vendor_id': 'Vendor',
                'company_id': 'Company',
                'store_branches_id': 'Store Branch',
                'amount': 'Amount',
                'account_num': 'Account Number'
            }

            switch (this.form.frequency_type_id) {
                case 1:
                    required['frequency_parameter'] = 'Duration'
                case 2:
                    required['frequency_parameter'] = 'Billing Date'
                    required['frequency_time'] = 'Time'
                    break;
                case 4:
                    required['frequency_time'] = 'Time'
                    break;
                case 5:
                    required['frequency_parameter'] = 'Day'
                    required['frequency_time'] = 'Time'
                    break;
                case 6:
                case 9:
                    required['frequency_parameter'] = 'Date'
                    required['frequency_time'] = 'Time'
                    break;
                case 7:
                    required['frequency_time'] = 'Time'
                    break;
                case 8:
                    required['first_half_month'] = 'Month'
                    required['first_half_date'] = 'Date'
                    required['frequency_time'] = 'Time'
                    break;
                case 10:
                    required['first_half'] = '1st Half'
                    required['second_half'] = '2nd Half'
                    break;
            }

            Object.keys(required).forEach(e=>{
                if (!this.form[e]) {
                    emptyFields.push(required[e])
                }
            })

            if (this.form.frequency_type_id == 1) {
                if (!Array.isArray(this.form.frequency_parameter) || this.form.frequency_parameter.length !== 2) {
                    emptyFields.push(required['frequency_parameter']);
                }
            }

            if (this.RECURRING_BILL.ACTION == 'EDIT') {
                if (!this.clonedStores.find(e => e.value == this.form.store_branches_id)) {
                    swal.fire('', "The Store Branch doesn't belong to the current Company.", 'error')
                    return false
                }
            }

            if (emptyFields.length > 0) {
                swal.fire("Please fill the following fields: \n", emptyFields.join(", ") , "warning")
                return false
            }
            return true
        },
        insertSemiAnnualData(half){
            switch(half){
                case 1:
                    this.form.first_half = this.months.find(e => e.value == this.form.first_half_month).text + ' ' + this.$dayjs().date(this.form.first_half_date).format('Do')
                    this.firstHalfMenu = false
                    break;
                case 2:
                    this.form.second_half = this.months.find(e => e.value == this.form.second_half_month).text + ' ' + this.$dayjs().date(this.form.second_half_date).format('Do')
                    this.secondHalfMenu = false
                    break;
            }
            if (this.form.first_half && this.form.second_half) this.form.frequency_parameter = this.form.first_half + ',' + this.form.second_half
        },
        datesByMonth(month){
            switch (month) {
                case 1:
                case 3:
                case 5:
                case 7:
                case 8:
                case 10:
                case 12:
                    this.dates = []
                    this.insertDates(1)
                    break;
                case 2:
                    this.dates = []
                    this.insertDates(2)
                    break;
                case 4:
                case 6:
                case 9:
                case 11:
                    this.dates = []
                    this.insertDates(3)
                    break;
            }
        },
        insertAnnualDate(){
            if (this.form.first_half_month && this.form.first_half_date) {
                this.form.frequency_parameter = this.months.find(e => e.value == this.form.first_half_month).text + ' ' + this.$dayjs().date(this.form.first_half_date).format('DD')
            }
        },
        async getBranches(){
            try{
                this.clonedStores = await this.getStoreBranches()
            } catch (error) {
                console.error(error)
            }
        },
        insertStore(source){
            switch (source) {
                case 1:
                    this.filters.store_branches_id = this.branchItemsForFilter[0].value
                    break;
                case 2:
                    this.form.store_branches_id = this.branchItems[0].value
                    break;
            }
        },
        insertCompany(storeId, source){
            if (!storeId) return
            let store = this.GET_PV_STORE_BRANCHES_SELECTION.find(e=>e.value == storeId)
            switch (source) {
                case 1:
                    this.filters.company_id = this.GET_PV_COMPANIES_SELECTION.find(e=>e.value == store.company_id).value
                    break;
                case 2:
                    this.form.company_id = this.GET_PV_COMPANIES_SELECTION.find(e=>e.value == store.company_id).value
                    break;
            }
        },
        insertDuration(){
            let tmpData = ''
            if (
                this.form.frequency_parameter &&
                this.form.first_half &&
                this.form.second_half
            ) {
                tmpData = this.form.frequency_parameter + ',' + this.form.first_half + ',' + this.form.second_half
                this.form.frequency_parameter = tmpData
            }
        }
    },
    watch:{
        options:{
            handler(){
                this.getAllSchedules()
            },
            deep: true
        },
        GET_RB_TYPES:{
            handler(val){
                if (!val.length) swal.fire('No Types Found!', 'Create in the TYPES tab', 'warning')
            }
        },
        'form.company_id':{
            handler(val){
                if (!val) {
                    this.form.store_branches_id = null
                }
            },
            immediate: true
        },
        'form.store_branches_id':{
            handler(val){
                if (!val) {
                    this.form.company_id = null
                }
            },
            immediate: true
        },
        'filters.company_id':{
            handler(val){
                if (!val) {
                    this.filters.store_branches_id = null
                }
            },
            immediate: true
        },
        'filters.store_branches_id':{
            handler(val){
                if (!val) {
                    this.filters.company_id = null
                }
            },
            immediate: true
        }
    }
}
</script>
<style scoped>
::v-deep .right-align-text input {
    text-align: right;
}
</style>
