<template>
    <div class="px-3 pb-4" style="width:100%">
        <v-card class="pa-2" style="border-radius:0px">
            <v-layout row class="mx-0 px-4 pt-4">
                <v-flex lg3 class="pr-2">
                    <v-autocomplete
                        v-model="selectedCustomers"
                        :items="customers_list"
                        placeholder="Select Customers"
                        multiple
                        dense
                        outlined
                        hide-details
                        auto-select-first
                        return-object
                        class="d-inline-block">
                    </v-autocomplete>
                </v-flex>
                <v-flex lg3>
                    <v-autocomplete
                        v-model="selectedSources"
                        :items="source_list"
                        placeholder="Select Sources"
                        multiple
                        dense
                        outlined
                        hide-details
                        auto-select-first
                        return-object
                        class="d-inline-block">
                    </v-autocomplete>
                </v-flex>
                <v-spacer />
                <v-flex lg3>
                    <div style="text-align:end">
                        <v-btn class="ml-1" @click="resetFields()">Clear</v-btn>
                        <v-btn @click="exportExcel(conversations, exportName)">Export</v-btn>

                        <v-btn class="ml-1" @click="filterData()">Filter</v-btn>
                    </div>
                </v-flex>
            </v-layout>

            <v-layout row class="mx-0 px-4">
                <v-flex lg1>
                    <v-dialog
                        ref="dateStartDialog"
                        v-model="startDateModal"
                        :return-value.sync="startDate"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="startDate"
                            label="Date Start"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="startDate"
                            scrollable
                            :max="minDate"
                        >
                        <v-spacer />
                        <v-btn
                            text
                            color="primary"
                            @click="startDateModal = false"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.dateStartDialog.save(startDate)"
                        >
                            OK
                        </v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-flex>
                <v-flex lg1>
                    <v-dialog
                        ref="endDateDialog"
                        v-model="endDateModal"
                        :return-value.sync="endDate"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="endDate"
                            label="Date End"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :disabled="!startDate"
                        />
                        </template>
                        <v-date-picker
                            v-model="endDate"
                            scrollable
                            :min="startDate"
                            :max="minDate"
                        >
                        <v-spacer />
                        <v-btn
                            text
                            color="primary"
                            @click="endDateModal = false"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.endDateDialog.save(endDate)"
                        >
                            OK
                        </v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-flex>
            </v-layout>

            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="conversations"
                    :options.sync="options"
                    :server-items-length="totalItems"
                    :loading="loading"
                >
                    <template v-slot:item.created_at="{ item }">
                        <td>
                            {{ formatDate(item.created_at) }}
                        </td>
                    </template>

                    <template v-slot:item.action="{ item }">
                        <td>
                            <span class="text-nonwrap">
                                <v-btn text icon color="orange">
                                    <v-icon class="btn-action" small @click="showCc(item)">mdi-eye</v-icon>
                                </v-btn>
                                <!-- <v-btn text icon color="blue">
                                    <v-icon class="btn-action" small @click="downloadFile(item)">fas fa-arrow-circle-down</v-icon>
                                </v-btn>
                                <v-btn v-if="withDelete" text icon color="red">
                                    <v-icon class="btn-action" small @click="deleteFile(item)">fas fa-trash-alt</v-icon>
                                </v-btn> -->
                            </span>
                        </td>
                    </template>

                    <template v-slot:item.status="{ item }">
                        <td>
                            <span v-if="item.status=='open'" class="badge bg-success">open</span>
                            <span v-else-if="item.status=='escalated'" class="badge bg-danger">escalated</span>
                            <span v-else-if="item.status=='closed'" class="badge bg-dark">closed</span>
                        </td>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <CustomerConversationDetailsDialogVue />
    </div>
</template>

<script>
import CustomerConversationDetailsDialogVue from '../CallCenterComponent/CustomerConversationDetailsDialog.vue';
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue';
import { mapGetters } from 'vuex';
export default {
    mixins:[SharedFunctionsComponent],
    data() {
        return {
            headers: [
                { text: 'Ticket#', value: 'ticket_number' },
                { text: 'Company Name', value: 'company_name' },
                { text: 'Customer Name', value: 'customer_name' },
                { text: 'Issue', value: 'issue' },
                { text: 'Status', value: 'status' },
                { text: 'Source', value: 'source' },
                { text: 'Created By', value: 'created_by' },
                { text: 'Processed By', value: 'processed_by' },
                { text: 'Escalated By', value: 'escalated_by' },
                { text: 'Finished By', value: 'finished_by' },
                { text: 'Date', value: 'created_at' },
                { text: 'Action', value: 'action' },
            ],
            conversations: [],
            options: {},
            totalItems: 0,
            loading: false,
            page: 1,
            itemsPerPage: 10,
            selectedStatus: 0,

            customers_list: [],
            selectedCustomers: [],

            source_list: [],
            selectedSources: [],

            startDateModal: false,
            endDateModal: false,
            startDate: '',
            endDate: '',
            minDate: this.$dayjs().format('YYYY-MM-DD'),
            excelHeadersAndData: {
                Sheet1: {
                    headers:[
                        {header:'Ticket#', key:'ticket_number', width:15},
                        {header:'Company Name', key:'company_name', width:22},
                        {header:'Customer Name', key:'customer_name', width:22},
                        {header:'Issue', key:'issue', width:22},
                        {header:'Status', key:'status', width:20},
                        {header:'Source', key:'source', width:20},
                        {header:'Created By', key:'created_by', width:22},
                        {header:'Processed By', key:'processed_by', width:22},
                        {header:'Escalated By', key:'escalated_by', width:22},
                        {header:'Finished By', key:'finished_by', width:22},
                        {header:'Date', key:'created_at', width:15},
                    ],
                    data:[]
                }
            },
            exportLoading: false,
            exportName: `Call Center Conversations - ${this.$dayjs('YYYY-MM-DD')}`
        }
    },
    async mounted() {
        await this.getCustomerConversations();
        await this.getCustomers();
        await this.getAllConversationSources();
    },
    components: {
        CustomerConversationDetailsDialogVue
    },
    props: ['status'],
    computed: {
        ...mapGetters([
            'GET_CUSTOMERS_LIST_SELECTION',
            'GET_ALL_CONVERSATION_SOURCES',
            'GET_CUSTOMER_CONVERSATIONS',
        ]),
        successAddEdit() {
            return this.$store.state.service.callCenter.success;
        }
    },
    watch: {
        selectedStatus() {
            this.getCustomerConversations();
        },
        options(data) {
            this.loading = true;
            this.page = data.page;
            this.getCustomerConversations();
        },
        status() {
            this.selectedStatus = this.status;
        },
        successAddEdit() {
            if(!!this.successAddEdit) {
                this.getCustomerConversations();
            }
        },
        conversations() {
            if(!!this.conversations.length > 0) {
                this.loading = false;
            }
        },
        startDate() {
            if(!!this.startDate) {
                this.endDate = this.minDate;
            }
        },
    },
    methods: {
        getCustomers(){
            this.$store.dispatch('getAllCustomers').then(response => {
                this.customers_list = this.GET_CUSTOMERS_LIST_SELECTION
                this.customers_list.push({
                    value: 'all',
                    text: 'Select All'
                })
            });
        },
        resetFields() {
            this.startDate = '';
            this.endDate = '';
            this.selectedCustomers = [];
            this.sources = [];
            this.getCustomerConversations();
        },
        getCustomerConversations() {
            let payload = {
                status: this.status,
                page: this.page,
                itemsPerPage: this.itemsPerPage,
                startDate: this.startDate,
                endDate: this.endDate,
                customers: this.selectedCustomers,
                sources: this.selectedSources,
            }
            this.$store.dispatch('getCustomerConversations',payload).then(response=>{
                this.conversations = response.data;
                this.totalItems = response.total;
                this.conversations.forEach( e => e.created_at = this.formatDate(e.created_at))
                this.excelHeadersAndData.Sheet1.data = this.conversations;
            });
        },
        showCc(item) {
            this.$store.commit('setCcId', item.id);
            this.$store.commit('showCcDialog');
        },
        formatDate(item) {
            if(!!item) {
                return this.$dayjs(item.created_at).format('YYYY-MM-DD, hh:mm:ss a');
            }

            return '';
        },
        exportCustomerConversations(){

        },
        getAllConversationSources() {
            this.$store.dispatch('getAllConversationSources').then(response=>{
                let datas = [];
                datas = this.GET_ALL_CONVERSATION_SOURCES;
                let container = []
                datas.forEach(e => {
                    container.push({
                        text: e.name,
                        value: e.id
                    })
                });
                this.source_list = container;
            });
        },
        filterData() {
            this.getCustomerConversations();
        },
        startDownload() {
            this.exportLoading = true;
        },
        finishDownload() {
            this.exportLoading = false;
        },
    }
}
</script>

<style>

</style>
