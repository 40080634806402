import { render, staticRenderFns } from "./CreditBillViewComponent.vue?vue&type=template&id=380d33aa&scoped=true"
import script from "./CreditBillViewComponent.vue?vue&type=script&lang=js"
export * from "./CreditBillViewComponent.vue?vue&type=script&lang=js"
import style0 from "./CreditBillViewComponent.vue?vue&type=style&index=0&id=380d33aa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "380d33aa",
  null
  
)

export default component.exports