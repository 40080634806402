<template>
    <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
            <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                <span class="text-h5">{{GET_HR_DIALOG_TITLE}}</span>
                <v-row class="m-0">
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                            text
                            icon
                            small
                            color="gray"
                            class="float-right"
                            @click="closeDialog()"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-container class="pb-0 my-2" fluid>
                    <v-row>
                        <v-col cols="12" sm="3" class="pa-0 pr-1" v-if="!disabled_view">
                            <v-autocomplete
                                v-model="form.schedule_type"
                                :items="dropdowns.schedule_type"
                                :rules="errors.required"
                                :readonly="disabled_view"
                                item-disabled="isDisabled"
                                label="Schedule Type"
                                item-value="value"
                                item-text="text"
                                auto-select-first
                                outlined
                                required
                                dense
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="3" class="pa-0 pr-1" v-else>
                            <v-text-field
                                v-model="form.schedule_type_name"
                                label="Schedule Type"
                                readonly
                                outlined
                                required
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-spacer>
                        </v-spacer>
                        <!-- <v-col cols="12" sm="4" class="pa-0 pr-1">
                            <v-menu
                                v-model="form.effectivity_date_menu"
                                :close-on-content-click="false"
                                ref="menu"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="dateRangeText"
                                        v-on="on"
                                        :disabled="disabled_view"
                                        label="Effectivity Date (YYYY-MM-DD)"
                                        outlined
                                        dense
                                    ></v-text-field>
                                </template>

                                <v-date-picker
                                    v-model="form.effectivity_date"
                                    no-title
                                    scrollable
                                    range
                                >
                                </v-date-picker>
                            </v-menu>
                        </v-col> -->
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="3" class="pa-0 pr-1">
                            <v-text-field
                                v-model="form.name"
                                :readonly="disabled_view"
                                label="Schedule Template Name"
                                :rules="errors.required"
                                outlined
                                required
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="12" sm="3" class="pa-0 pr-1">
                            <v-text-field
                                v-model="form.break_duration"
                                :readonly="disabled_view"
                                :rules="errors.required"
                                label="Break Duration (Hours)"
                                type="number"
                                min="0"
                                outlined
                                required
                                dense
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-container fluid class="pa-0 ma-0">
                            <v-card class="pa-0">
                                <v-card-title class="pr-0 pt-1 pr-1 pb-0 border-bottom"  v-if="!disabled_view">
                                    <v-row class="m-0">
                                        <v-col cols="pull-right-10">

                                        </v-col>
                                    </v-row>
                                </v-card-title>
                                <v-card-text>
                                    <v-simple-table height="300px">
                                        <thead>
                                            <tr>
                                                <th>Day</th>
                                                <th v-if="![2].includes(form.schedule_type)">Time In</th>
                                                <th v-if="![2].includes(form.schedule_type)">Time Out</th>
                                                <th v-if="[2].includes(form.schedule_type)">Work Hours</th>
                                                <th>Rest Day</th>
                                                <!-- <th v-if="!disabled_view">
                                                    <v-btn
                                                        fab
                                                        dark
                                                        small
                                                        color="primary"
                                                        style="height: 20px; width: 20px"
                                                        @click="addWorkDays()"
                                                        class="float-right"
                                                    >
                                                        <v-icon dark small style="font-size: 12px"
                                                        >mdi-plus</v-icon
                                                        >
                                                    </v-btn>
                                                </th> -->
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="(
                                                data, i
                                                ) in form.workDays"
                                                :key="i"
                                            >
                                                <td v-if="!disabled_view">
                                                    <v-autocomplete
                                                        v-model="data.day"
                                                        :items="dropdowns.days"
                                                        :readonly="disabled_view"
                                                        item-disabled="isDisabled"
                                                        item-text="name"
                                                        item-value="name"
                                                        auto-select-first
                                                        outlined
                                                        required
                                                        dense
                                                    ></v-autocomplete>
                                                </td>

                                                <td v-else>
                                                    <v-text-field
                                                        v-model="data.day"
                                                        outlined
                                                        readonly
                                                        required
                                                        dense
                                                    ></v-text-field>
                                                </td>

                                                <td v-if="![2].includes(form.schedule_type)">
                                                    <v-menu
                                                        v-model="data.time_in_menu"
                                                        :disabled="disabled_view"
                                                        :close-on-content-click="false"
                                                        transition="scale-transition"
                                                        offset-y
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                v-model="data.time_in"
                                                                :readonly="disabled_view"
                                                                v-mask="'##:##'"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                outlined
                                                                dense
                                                                flat
                                                            ></v-text-field>
                                                        </template>
                                                        <v-time-picker format="24hr" scrollable @click:minute="data.time_in_menu = false, applyToAllSwal('time_in', data.time_in)" v-model="data.time_in"></v-time-picker>
                                                    </v-menu>
                                                </td>

                                                <td v-if="![2].includes(form.schedule_type)">
                                                    <v-menu
                                                        v-model="data.time_out_menu"
                                                        :close-on-content-click="false"
                                                        :disabled="disabled_view"
                                                        transition="scale-transition"
                                                        offset-y
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                v-model="data.time_out"
                                                                :readonly="disabled_view"
                                                                v-mask="'##:##'"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                outlined
                                                                dense
                                                                flat
                                                            ></v-text-field>
                                                        </template>
                                                        <v-time-picker format="24hr" scrollable :min="data.time_in" @click:minute="data.time_out_menu = false, applyToAllSwal('time_out', data.time_out)" v-model="data.time_out"></v-time-picker>
                                                    </v-menu>
                                                </td>

                                                <td v-if="[2].includes(form.schedule_type)">
                                                    <v-text-field
                                                        v-model="data.hours_to_work_day"
                                                        :readonly="disabled_view"
                                                        type="number"
                                                        min="0"
                                                        outlined
                                                        required
                                                        dense
                                                    ></v-text-field>
                                                </td>

                                                <td>
                                                    <v-checkbox
                                                        v-model="data.isRestDay"
                                                        :disabled="disabled_view"
                                                        :true-value="1"
                                                        :false-value="0"
                                                        color="green"
                                                        icon
                                                    >
                                                    </v-checkbox>
                                                </td>

                                                <!-- <td v-if="!disabled_view">
                                                    <v-btn
                                                        @click="deleteWorkDays(i, data.id)"
                                                        color="red"
                                                        icon
                                                        dark
                                                    >
                                                        <v-icon dark >mdi-delete</v-icon>
                                                    </v-btn>
                                                </td> -->
                                            </tr>
                                        </tbody>
                                    </v-simple-table>
                                </v-card-text>
                            </v-card>
                        </v-container>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions
                class="text-center"
                style="border-top: 1px solid #dee2e6"
            >
                <v-row class="ma-1 pb-2">
                    <v-col cols="12" v-if="actions != 'View'">
                        <v-btn
                            @click="
                                actions == 'Submit'? addScheduleTemplate() : updateScheduleTemplate()"
                            :disabled="disabled_view"
                            :loading="loading.button"
                        >
                            <span>{{ actions }}</span>
                        </v-btn>
                        <span class="pr-12">&nbsp;</span>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-form>
</template>
<script>
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
    mixins: [SharedFunctionsComponentVue],
    data() {
        return {
            valid: false,
            overlay: false,
            disabled_view: false,
            errors: {
                required: [(v) => !!v || "Field is required"],
                number: [
                    (v) => !!v || "Field is required",
                    (v) => !isNaN(v) || "Input must be a number",
                ],
            },
            form: {},
            dropdowns: {
                schedule_type: [],
                days:[]
            },
            component_dispatches:[
		        'getEmployeeScheduleType'
			],
            loading:{
                positions: false,
                department: false,
                button: false
            },
            selected_days: [
                {id:1, text:'emoji_asdasdasdasdas'}
            ]
        };
    },
    computed: {
        ...mapGetters([
            "ACTION",
            "GET_HR_DIALOG_TITLE",
            'GET_EMPLOYEE_SCHEDULE_TYPE_SELECTION',
            "DIALOGS",
            "GET_HR_DISPATCH",
            "GET_HR_OTHER_TABS_DIALOG_VIEW"
        ]),
        actions() {
            if (this.ACTION == "Submit") {
                this.disabled_view = false;
                this.isViewBtn = false;
                return "Submit";
            } else if (this.ACTION == "Update") {
                this.disabled_view = false;
                this.isViewBtn = true;
                this.getScheduleTemplateData();
                return "Update";
            } else if (this.ACTION == "View") {
                this.disabled_view = true;
                this.isViewBtn = true;
                this.getScheduleTemplateData();
                return "View";
            }
        },
        dateRangeText () {
            return this.form.effectivity_date.join(' ~ ')
        },
    },
    created() {
        this.initialForm();
    },
    mounted() {
        this.getAllDropdowns();
    },
    methods: {
        getAllDropdowns(){
            this.loading.positions = true
            this.loading.department = true
            this.component_dispatches.forEach(el=>{
                this.$store.dispatch(el)
            })
            this.resetDaysSelection();
        },
        initialForm() {
            this.form = {
                schedule_type:'',
                workDays: [],
                effectivity_date: [],
                effectivity_date_menu: false,
                temporary: 0,
                schedule_detail_trash_ids: [],
                break_duration:0
            };

            for (let i = 0; i < 7; i++) {
                const currentDay = this.$dayjs().day(i + 1).format('dddd');
                this.form.workDays.push({
                    time_in: null,
                    time_out: null,
                    day: currentDay,
                });
            }
        },
        height(width) {1
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "100%";
                case "sm":
                    return "100%";
                case "md":
                    return width;
                case "lg":
                    return width;z
                case "xl":
                    return width;
            }
        },
        closeDialog() {
            this.$store.commit("DIALOG", false);
        },
        addScheduleTemplate() {
            this.loading.button = true;

            if(this.form.workDays.length !== 7){
                Swal.fire(
                    "7 days must be assigned!",
                    "",
                    "warning"
                )
                this.loading.button = false;
                return false
            }else{
                this.addTemplateNow();
            }
        },
        addTemplateNow(){
            this.$refs.form.validate();
            let rest_day_checker = this.form.workDays.find(x=> x.isRestDay == 1);

            let schedule_type_swal_text = this.form.schedule_type ? '' : 'Schedule Type';
            let schedule_name_swal_text = this.form.name ? '' : 'Schedule Template Name';
            let break_duration_swal_text = this.form.break_duration ? '' : 'Break Duration';
            let rest_day_swal_text = rest_day_checker ? '' : 'Rest Day';

            const array_text =
            [
                schedule_type_swal_text,
                schedule_name_swal_text,
                break_duration_swal_text,
                rest_day_swal_text
            ]
            const filtered = array_text.filter(function (el) {
                return el.replace(',', '');
            })

            if (this.form.schedule_type &&
                this.form.name &&
                this.form.break_duration &&
                rest_day_checker
            ) {
                this.$store.dispatch(this.GET_HR_DISPATCH.add,this.form).then(response=>{
                    Swal.fire(response.icon.toUpperCase(),response.message,response.icon);
                    this.$store.commit("DIALOG", false);
                    this.loading.button = false;
                })
            }
            else {
                Swal.fire({
                    text: `Fill Required Fields (${filtered})`,
                    icon: 'warning',
                    allowOutsideClick: false,
                });
                    this.loading.button = false;
            }

        },
        updateScheduleTemplate() {
            let has_rest_day                            = this.form.workDays.some(val => val.isRestDay == 1);
            if(this.form.workDays.length !== 7){
                Swal.fire(
                    "7 days must be assigned!",
                    "",
                    "warning"
                )
                this.loading.button = false;
                return false
            }
            else if (!has_rest_day){
                Swal.fire(
                    "Please select a rest day!",
                    "",
                    "warning"
                )
                this.loading.button = false;
                return false
            }
            else{
                this.$store.dispatch(this.GET_HR_DISPATCH.add, this.form).then((response) => {
                    Swal.fire(response.icon.toUpperCase(),response.message,response.icon);
                    this.closeDialog();
                });
            }
        },
        addWorkDays(){
            if(this.form.workDays.length < 7){
                this.form.workDays.push(
                    {
                        time_in: null,
                        time_out: null,
                        day: '',
                    }
                )
            }
        },
        deleteWorkDays(index,item_id = -1){
            this.form.workDays.splice(index,1);
            this.form.schedule_detail_trash_ids.push(item_id)
        },
        resetDaysSelection(){
            this.dropdowns.days = this.$dayjs.weekdays(true).map(day => ({ name: day, isDisabled: false }));
        },
        getScheduleTemplateData(){
            this.form.id                    = this.GET_HR_OTHER_TABS_DIALOG_VIEW.id
            this.form.name                  = this.GET_HR_OTHER_TABS_DIALOG_VIEW.name
            this.form.schedule_type         = this.GET_HR_OTHER_TABS_DIALOG_VIEW.schedule_type
            this.form.break_duration        = this.GET_HR_OTHER_TABS_DIALOG_VIEW.break_duration
            this.form.schedule_type_name    = this.GET_HR_OTHER_TABS_DIALOG_VIEW.schedule_type_name
            this.form.workDays              = this.GET_HR_OTHER_TABS_DIALOG_VIEW.work_schedule_template_details;
        },
        applyToAllSwal(key, data){
            if(this.ACTION == 'Submit' || this.ACTION == 'Update'){
                if(data){
                    Swal.fire({
                        icon: "question",
                        text: `Do you want to apply ${key.replace("_", " ")} to all remaining days?`,
                        cancelButtonText: "Cancel",
                        showCancelButton: true,
                        allowOutsideClick: false,
                        reverseButtons:true,
                        confirmButtonColor: "#397373",
                        cancelButtonColor: "grey",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.form.workDays.forEach((e) => {
                                if(e[key] == null){
                                    e[key] = data;
                                }
                            });
                        }
                    });
                }
            }
        }
    },
    watch: {
        DIALOGS: {
            handler(val) {
                if (val) {
                    this.initialForm();
                }
            },
        },
        GET_EMPLOYEE_SCHEDULE_TYPE_SELECTION:{
            handler(val){
                this.dropdowns.schedule_type = val
            }
        },
        'form':{
            deep:true,
            handler(val){
                // disable selected day
                let selected_days = []
                this.resetDaysSelection()

                if(val.workDays){
                    selected_days = val.workDays.map(x=> x.day)
                    selected_days.forEach(async x=>{
                        await this.dropdowns.days.forEach(async el=>{
                            if(x == el.name){
                                el.isDisabled = true
                            }
                        })
                    })
                }
                // close effectivity date menu
                // if(val.effectivity_date.length == 2){
                //     this.form.effectivity_date_menu = false
                //     if(val.effectivity_date[0] > val.effectivity_date[1]){
                //         Swal.fire("Effectivity Range Not Valid","","warning")
                //         this.form.effectivity_date = [];
                //     }
                // }
            }
        }
    },
};
</script>
<style scoped>
.modal_title {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: white;
}
</style>
