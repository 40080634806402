<template>
    <v-dialog
        v-model="dialog"
        :width="width"
        persistent
    >
        <v-card>
            <v-card-title class="d-flex justify-space-between">
                Batch Addition
             <v-icon @click="closeDialog()">mdi-close</v-icon>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col lg="5">
                        <v-text-field
                            dense
                            outlined
                            small
                            color="primary"
                            label="Model"
                            @paste="onPaste"
                            class="bg-error"
                            v-if="!batchAddition.pasted"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col lg="5">
                        <v-text-field
                            dense
                            outlined
                            small
                            color="primary"
                            label="Quantity"
                            @paste="onPaste"
                            class="bg-error"
                            v-if="!batchAddition.pasted"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row v-for="(item,index) in batchAddition.items" :key="index" >
                    <!-- <v-col lg="6" v-if="batchAddition.pasted" :class="item.error.class "> -->
                    <v-col lg="5" v-for="(column,i) in batchAddition.columns" :key="i">
                        <v-text-field
                            dense
                            outlined
                            small
                            v-if="batchAddition.pasted"
                            v-model="item[column]" placeholder="Paste Model and Quantity">
                        </v-text-field>
                    </v-col>
                    <!-- <v-col lg="4" v-if="batchAddition.pasted" :class="item.error.class "> -->
                    <v-col lg="2" v-if="batchAddition.pasted">
                        <v-icon
                            class="ml-2"
                            color="red"
                            v-if="batchAddition.pasted"
                            @click="removeItem(index)"
                        >
                        mdi-minus-circle
                    </v-icon>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn small @click="addData()"><v-icon>mdi-plus</v-icon> Submit</v-btn>
                <v-btn small @click="clearItem()"><v-icon>mdi-delete-forever</v-icon> Clear</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import swal from 'sweetalert2'
import { props } from 'qrcode.vue'
export default {
    props:{
        uniqueItem: [],
        dialog:{
            default:()=>{
                return false
            },
            type:Boolean
        },
        width:{
            default:()=>{
                return 500
            },
            type:Number
        },
        additionalData:{
            default:()=>{
                return {}
            },
            type:Object
        },
        columns:{
            default:()=>{
                return[
                    {
                        model:'String',
                        required:true
                    },
                    {
                        quantity:'Number',
                        required:true
                    },
                ]
            }
        }
    },
    data(){
        return{
            batchAddition:{
                items:[],
                pasted:false,
                columns:[]
            }
        }
    },
    mounted(){
        this.createItemObject()
    },
    methods:{
        async onPaste(evt) {
            let data = []
            let errors = null
            var pastedText = evt.clipboardData.getData('Text');

            if(!pastedText){
                return false;
            }
            else{
                var rows = pastedText.split("\r\n")
                if(this.columns.length > 0 ){
                    rows = rows.map(e=>e.split("\t"))
                }
                rows.forEach(e=>{
                    if(Array.isArray(e)){
                        let cleaner = []
                        e.forEach((x,i)=>{
                            cleaner.push([this.batchAddition.columns[i],x])
                        })
                        // console.log(Object.fromEntries(cleaner))
                        data.push(Object.fromEntries(cleaner))
                    }else{
                        data.push({[this.batchAddition.columns[0]]:e})
                    }
                })
                data.pop()
                this.uniqueItem = _.uniqWith(data, (arrVal, othVal) => arrVal.model === othVal.model)
                this.batchAddition.items = this.uniqueItem

            }
            this.batchAddition.pasted = true
        },
        addData(){
            if(this.batchAddition.items.some(e=>e.error.error)){
                swal.fire('Warning!','Please check your inputs','warning')
                return false
            }
            this.$store.dispatch('checkModel')

            this.$emit('addData',this.batchAddition.items)
            this.clearItem()
        },
        clearItem(){
            this.batchAddition.items = [];
            this.batchAddition.pasted = false
        },
        removeItem(index){
            this.batchAddition.items.splice(index, 1);
        },
        createItemObject(){
            let obj = {}
            this.columns.forEach(e=>{
                let attributes = Object.keys(e)
                obj[attributes.map(e=>e!='required')[0]] = ''
                this.batchAddition.columns.push(attributes.filter(e=>[e]!='required')[0])
            })
            this.batchAddition.items.push(obj)
        },
        validatePastedValue(data){
            let errors = []
            data.forEach(d=>{
                let attributes = Object.keys(d)
                attributes.forEach(a => {
                    this.columns.find(c=>c.hasOwnProperty(a)).required == true ? errors.push({column:d,error:true}) : ''
                });
            })
            return errors
        },
        closeDialog(){
            this.$emit("closeDialog", false) ;
        }
    }
}
</script>

<style>

</style>
