<template>
    <div class="w-100">
        <v-app id="my_vue_app">
            <v-card>
                <v-card-title>
                <v-container>
                    <!-- <v-row>
                        <v-col class="d-flex justify-end mr-3">
                            <a
                            v-for="(contract, index ) in contracts" :key="index"
                            :class="`ml-1 badge badge-${contract.text.toLowerCase()} ${contract.value == selected_value ? `selected` : `non-selected`}`" @click="selectContract(contract)"
                            >
                            {{ contract.text.toLowerCase() }}
                            </a>
                        </v-col>
                    </v-row> -->
                    <v-row>
                        <v-col cols="12" lg="4" md="2" sm="2">
                            <v-autocomplete
                                v-model="filter.department_id"
                                @change="getLeaveRequests()"
                                :items="dropdown.department_list"
                                item-text="text"
                                item-value="value"
                                label="Department"
                                auto-select-first
                                deletable-chips
                                small-chips
                                clearable
                                outlined
                                required
                                multiple
                                dense
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="12" sm="12" md="3" class="d-flex justify-content-end align-items-center">
                            <a
                                v-for="(status, index ) in statuses_list" :key="index"
                                :class="`ml-1 badge badge-${status.text} ${status.value == selected_status ? `selected` : `non-selected`}`" @click="selectedStatus(status)"
                                >
                            {{ status.text ? status.text.replace('-'," ") : null  }}
                            </a>
                        </v-col>
                    </v-row>
                    <v-row class="mb-2">
                        <v-spacer></v-spacer>
                        <div class="row col-sm-12 col-md-4 mr-3 align-items-end">
                            <v-text-field
                                id="searchBar"
                                v-model="filter.search"
                                label="Search Employee Name/ID"
                                single-line
                                hide-details
                                @change="searchInput()"
                            >
                            </v-text-field>
                            <v-icon @click="searchInput()">mdi-magnify</v-icon>
                        </div>
                    </v-row>
                </v-container>

                </v-card-title>
                <v-container fluid>
                    <v-data-table
                        :headers="datatable.headers"
                        :items="datatable.items"
                        :search="search"
                        :options.sync="datatable.options"
                        :server-items-length="datatable.totalItems"
                        :items-per-page="10"
                        :footer-props="{
                            itemsPerPageOptions: datatable.itemsPerPageOptions
                        }"
                        :loading="loading.table"
                        loading-text="Loading... Please wait"
                        fixed-header
                        dense
                    >
                        <template v-slot:[`item.action`]="{ item }">
                            <v-btn text icon color="orange" v-if="userAccess.view">
                            <v-icon
                                class="btn-action"
                                small
                                @click="viewLeaveRequest(item)"
                                >mdi-eye</v-icon
                            >
                            </v-btn>
                            <v-btn text icon color="blue" v-if="userAccess.edit && (![3,4,5].includes(item.status))">
                            <v-icon
                                class="btn-action"
                                small
                                @click="editLeaveRequest(item)"
                                >mdi-pencil</v-icon
                            >
                            </v-btn>
                            <v-btn text icon color="red" v-if="userAccess.delete && item.status != 3">
                            <v-icon
                                class="btn-action"
                                small
                                @click="deleteLeaveRequest(item)"
                                >mdi-delete</v-icon
                            >
                            </v-btn>
                        </template>
                        <template v-slot:[`item.status`]="{item}">
                            <span :class="`badge badge-${checkStatus(item.status)}`">{{ checkStatus(item.status) }}</span>
                        </template>
                    </v-data-table>
                    </v-container>
            </v-card>
        </v-app>
    </div>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from 'vuex';
export default {
    data(){
        return {
            loaded: false,
            search: "",
            filter:{
                search:"",
                department_id: []
            },
            datatable:{
                headers: [
                    { text: 'Name', align: 'left',value:'full_name' },
                    { text: 'Date Filed', align: 'left',value:'date_filed' },
                    { text: 'Approved Date', align: 'left',value:'approved_date' },
                    { text: 'Approved By', align: 'left',value:'approved_by_name' },
                    { text: 'Confirmed By', align: 'left',value:'confirmed_by_name' },
                    { text: 'Confirmed Date', align: 'left',value:'confirmed_date' },
                    { text: 'Leave Type', align: 'left', value: 'leave_type_name'},
                    { text: 'Status', align: 'left', value: 'status'},
                    { text: "Action", align: "center", value: "action" },
                ],
                items: [],
                options: {},
                totalItems: 0,
                itemsPerPageOptions: [5, 10, 15, 50, 100],
                itemsPerPage: 10,
            },
            loading:{
                table: false
            },
            userAccess: {
                view: false,
                create: false,
                edit: false,
                delete: false,
            },
            contracts:[],
            selected_contract:'',
            selected_value:0,
            isManager: 0,
            statuses_list: [
                {
                    text: 'all',
                    value: 0
                },
                {
                    text: 'saved',
                    value: 1
                },
                {
                    text: 'approved',
                    value: 2
                },
                {
                    text: 'confirmed',
                    value: 3
                },
                {
                    text: 'rejected',
                    value: 4
                },
                {
                    text: 'cancelled',
                    value: 5
                },
            ],
            selected_status: 0,
            hr_id: '',
            dropdown:{
                department_list: []
            },
            checked_department_ids: []
        }
    },
    async mounted(){
        this.$store.dispatch('getDepartment')
        this.$store.dispatch("checkDepartmentAccess");
        this.getAllLeaveTypes();
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        this.getSearchFromUrl()
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS',
            'GET_HR_LEAVES_NEW_DATA',
            'GET_STATUS_SELECTION',
            'GET_LEAVE_TYPE_SELECTION',
            'DIALOGS',
            'USERACCOUNT_LOAD',
            'GET_HR_TAB_IS_ACTIVE',
            'GET_HR_DISPATCH',
            'GET_LIST_DEPARTMENT',
            'GET_DEPARTMENT_ACCESS',
        ])
    },
    components:{
    },
    methods:{
        selectContract(data){
            this.selected_value = data.value
            this.selected_contract = data.value;
            this.getLeaveRequests()
        },
        async getAllLeaveTypes(){
            await this.$store.dispatch('getLeaveTypeSelection').then(response=>{
                // this.contracts.unshift({text:'All',value:0, selected: false})
            });
        },
        async getLeaveRequests(filter = null){
            await this.checkDepartmentAccess()
            this.datatable.items = [];
            this.loading.table = true;
            let payload = {
                page: this.datatable.options.page,
                itemsPerPage: this.datatable.options.itemsPerPage,
                search: this.filter.search,
                sortBy: this.datatable.options.sortBy,
                sortDesc: this.datatable.options.sortDesc,
                user_id: this.USERACCOUNT_LOAD.id,
                contract_type: this.selected_contract,
                filter: filter,
                active_tab:this.GET_HR_TAB_IS_ACTIVE,
                selected_status : this.selected_status,
                department_ids: this.GET_DEPARTMENT_ACCESS,
                department_id: this.filter.department_id,
            };
            await this.$store.dispatch(this.GET_HR_DISPATCH.get, payload).then(response => {
                this.datatable.items = response.data.data;
                this.datatable.totalItems = response.data.total;
                this.loading.table = false;
            }).catch((err)=>{
                this.loading.table = true;
                Swal.fire(
                    "Network Error",
                    "",
                    "error"
                )
                console.log(err)
            });
        },
        searchInput(){
            this.getLeaveRequests({ query: this.search });
        },
        async viewLeaveRequest(item){
            this.$store.commit('HR_EMPLOYEE_LEAVE_WITH_PAY', [])
            let items = {}
            await this.$store.dispatch(this.GET_HR_DISPATCH.show,item.id).then(response =>{
                items = response
                this.$store.commit("HR_LEAVES_DATA_VIEW", items);
                this.$store.commit("DIALOG", true);
                this.$store.commit("ACTION", "View");
            })
        },
        async editLeaveRequest(item){
            this.$store.commit('HR_EMPLOYEE_LEAVE_WITH_PAY', [])
            let items = {}
            await this.$store.dispatch(this.GET_HR_DISPATCH.show,item.id).then(response =>{
                items = response
                this.$store.commit("HR_LEAVES_DATA_VIEW", items);
                this.$store.commit("DIALOG", true);
                this.$store.commit("ACTION", "Update");
            })
        },
        getSearchFromUrl() {
            if (Object.keys(this.$route.query).length != 0) {
                Object.keys(this.$route.query).forEach(e=>{
                if(e != 'for_approval')
                this.filter.search = atob(this.$route.query[e]);
                })
                this.searchInput();
            }
        },
        deleteLeaveRequest(item){
            Swal.fire({
                icon: 'question',
                text: "Are you sure you want to Delete?",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#397373",
                cancelButtonColor: "#397373",
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch(this.GET_HR_DISPATCH.delete, item.id).then((success) => {
                        Swal.fire(
                            success,
                            "",
                            success.toLowerCase()
                        );
                    });
                }
            });
        },
        checkStatus(status){
            if(status){
                let name = this.statuses_list.find((x) => x.value == status).text;
                return name;
            }
            return ''
        },
        selectedStatus(data){
            this.selected_status = data.value
            this.getLeaveRequests()
        },
        async checkDepartmentAccess(){
            let payload ={
                access: this.USER_ACCESS.map(e => e.actions_code)
            }
            await this.$store.dispatch('checkDepartmentAccess', payload).then(response=>{
                this.checked_department_ids = response;
            })
        },
        checkDepartmentForEdit(){
            if(this.USERACCOUNT_LOAD.department_id == this.hr_id){
                return true
            }
            return false
        }
    },
    watch:{
        'datatable.options': {
            handler() {
                this.getLeaveRequests();
            },
            deep: true,
        },
        USER_ACCESS: {
            handler(val) {
                if(val != "fail"){
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                this.userAccess.create = false;
                    val.forEach(e => {
                        if (e.actions_code == 'edit') {
                            this.userAccess.edit = true;
                        } else if (e.actions_code == 'delete') {
                            this.userAccess.delete = true;
                        } else if (e.actions_code == 'view') {
                            this.userAccess.view = true;
                        } else if (e.actions_code == 'create') {
                            this.userAccess.create = true;
                        }
                    });
                }
            }
        },
        GET_HR_LEAVES_NEW_DATA:{
            handler(val){
                if(val){
                    this.getLeaveRequests();
                    this.$store.commit('HR_LEAVES_NEW_DATA', false);
                }
            }
        },
        GET_LEAVE_TYPE_SELECTION:{
            handler(val){
                this.contracts = val
            }
        },
        DIALOGS:{
            handler(val){
                if(!val){
                    this.getAllLeaveTypes();
                }
                if(val){
                    this.contracts.shift({text:'All',value:0, selected: false})
                }
            }
        },
        GET_LIST_DEPARTMENT: {
            handler(val) {
                this.hr_id = val.find(e => e.code == 'HR-A').value
                this.dropdown.department_list = val
                if(this.USERACCOUNT_LOAD.department_id !== this.hr_id){
                    val = val.filter(item => this.checked_department_ids.includes(item.value) || this.USERACCOUNT_LOAD.department_id === item.value);
                   this.dropdown.department_list = val
                }
            },
            deep: true
        },
    }
}
</script>
<style scoped>
.selected {
    opacity: 100%;
}

.non-selected {
    opacity: 40%;
}
</style>
