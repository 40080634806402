<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card>
      <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
        <span class="text-h5">REPAIR REQUEST FORM</span>
        <v-row class="m-0">
          <v-col cols="pull-right-10 p-2">
            <v-btn
              text
              icon
              small
              color="gray"
              class="float-right"
              @click="closeDialog()"
            >
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="overflow-hidden">
        <v-container dense>
          <v-row>
            <v-col cols="12" sm="4">
              <v-row dense>
                <v-col cols="12">
                  <v-card class="pa-0">
                    <v-card-text>
                      <v-row dense>
                        <!-- <v-col cols="12" sm="12" md="12">
                          <v-autocomplete
                            v-model="form.department_id"
                            :items="GET_LIST_DEPARTMENT"
                            item-text="text"
                            item-value="value"
                            outlined
                            dense
                            label="Depatment"
                            :readonly="disabled_view"
                          ></v-autocomplete>
                        </v-col> -->
                        <v-col cols="12" sm="12" md="12">
                          <v-autocomplete
                            v-model="form.location_id"
                            :items="GET_ADMIN_LOCATION"
                            item-text="text"
                            item-value="value"
                            outlined
                            dense
                            label="Location"
                            :readonly="disabled_view"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-autocomplete
                            v-model="form.priority"
                            :items="priority"
                            outlined
                            dense
                            label="Priority"
                            :readonly="disabled_view"
                          ></v-autocomplete>
                        </v-col>
                        <!-- <v-col cols="12" sm="12" md="12">
                          <v-autocomplete
                            v-model="form.repair_type"
                            :items="GET_ADMIN_REPAIR_TYPE_SELECTION"
                            outlined
                            dense
                            label="Repair Type"
                            @change="ViewOthersDialog"
                            :readonly="disabled_view"
                          ></v-autocomplete>
                        </v-col> -->
                        <v-col cols="12" sm="12" md="12">
                          <v-autocomplete
                            v-model="form.repair_type"
                            :items="GET_ADMIN_REPAIR_TYPE_SELECTION"
                            outlined
                            dense
                            label="Repair Type"
                            :readonly="disabled_view"
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                      <!-- <v-row dense v-if="others_text_save && form.others_text">
                        <v-col cols="12 ml-5">
                          <span>Details: {{ form.others_text }}</span>
                        </v-col>
                      </v-row> -->
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="mt-5" dense>
                <v-col cols="12">
                  <v-card>
                    <v-card-text>
                      <v-textarea
                        v-model="form.remarks"
                        outlined
                        :auto-grow="false"
                        no-resize
                        height="119"
                        label="Remarks"
                        :readonly="disabled_view"
                      ></v-textarea>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="8">
              <v-card class="pa-2">
                <v-card-title class="pr-0 pt-0 pr-1 pb-0 border-bottom">
                  <v-row class="m-0">
                    <v-col cols="pull-right-10" v-if="!disabled_view">
                      <v-btn
                        icon
                        text
                        color="primary"
                        dark
                        class="float-right"
                        style="height: 20px; width: 20px"
                        @click="additems"
                      >
                        <v-icon small>mdi-plus-circle</v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-text>
                  <v-container
                    bg
                    fill-height
                    grid-list-md
                    text-sm-center
                    v-if="GET_RRF_ITEMS_LOADED"
                  >
                    <v-layout row wrap align-center>
                      <v-flex>
                        <v-progress-circular
                          :size="70"
                          color="primary"
                          indeterminate
                        >
                        </v-progress-circular>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <v-simple-table
                    fixed-header
                    height="300px"
                    v-if="!GET_RRF_ITEMS_LOADED"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Job Description</th>
                          <th class="text-left">Vendor Details</th>
                          <th class="text-left">Quoted Price</th>
                          <th class="text-left" width="2%">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, i) in form.itemsList" :key="i">
                          <td>
                            <v-text-field
                              v-model="item.job_description"
                              outlined
                              dense
                              :readonly="disabled_view"
                            ></v-text-field>
                          </td>
                          <td>
                            <v-autocomplete
                              v-model="item.vendor_id"
                              :items="GET_ADMIN_SUPPLIER_SELECTION"
                              dense
                              outlined
                              item-text="text"
                              item-value="value"
                              :readonly="disabled_view"
                            ></v-autocomplete>
                            <!-- <v-text-field
                              v-model="item.vendor_details"
                              outlined
                              dense
                              :readonly="disabled_view"
                            ></v-text-field> -->
                          </td>
                          <td>
                            <v-text-field
                              v-model="item.quoted_price"
                              outlined
                              dense
                              @change="totalCost()"
                              :readonly="disabled_view"
                            ></v-text-field>
                          </td>
                          <td>
                            <v-btn icon text color="red" @click="removeitems(i)" :disabled="disabled_view"
                              ><v-icon small>mdi-delete</v-icon></v-btn
                            >
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="text-center">
        <v-row class="ma-1">
          <v-col
            cols="12"
            :sm="actions == 'View' && GET_EDIT_RRF.status != 3 ? 6 : 12"
          >
            <v-btn
              v-if="actions == 'View'"
              :loading="!rrfPrintData"
              @click="printRepairRequestForm(rrfPrintData)"
            >Print</v-btn>
            <v-btn
              v-if="actions != 'View'"
              class="float-center"
              @click="createOrUpdateAccountability()"
              :disabled="disabled_view"
            >
              <span>{{ actions }}</span>
            </v-btn>
          </v-col>
          <v-col
            v-if="actions == 'View' && GET_EDIT_RRF.status != 3"
            cols="12"
            :sm="actions == 'View' ? 6 : 12"
          >
            <v-btn class="float-center" @click="createOrUpdateAccountability()">
              <span>Approve</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      <!-- <v-dialog v-model="others_dialog" width="20%" persistent>
        <v-card>
          <v-card-title class="pr-0 pt-0 pr-1 pb-0 border-bottom">
            <v-row class="m-0">
              <v-col cols="pull-right-10">
                <v-btn
                  text
                  icon
                  small
                  color="gray"
                  class="float-right"
                  style="height: 20px; width: 20px"
                  @click="CloseOthersDialog()"
                >
                  <v-icon small>mdi-close-circle</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-container>
            <v-row dense>
              <v-col>
                <v-textarea
                  v-model="form.others_text"
                  outlined
                  :auto-grow="false"
                  no-resize
                  height="65"
                  label="Others"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="12">
                <v-btn class="float-right" x-small @click="OthersTextSave()"
                  >Save</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog> -->
      <RepairRequestFormComponent></RepairRequestFormComponent>
      </v-dialog>
      <!-- <RepairRequestFormComponent></RepairRequestFormComponent> -->
    </v-card>
  </v-form>
</template>
<script>
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
import QrcodeVue from "qrcode.vue";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
// import RepairRequestFormComponent from "@/views/main/layouts/PrintTemplate/RepairRequestFormComponent.vue";
import AdminRepairRequestFormComponent from "@/views/main/layouts/PrintTemplate/AdminRepairRequestFormComponent.vue";
import ItemsViewComponent from "@/views/main/layouts/dialogs/itemsViewComponent.vue";
import images from "../../../../../assets/ingcoph_bg/ingcoph_logo_alt.jpg";
export default {
  mixins: [SharedFunctionsComponentVue, AdminRepairRequestFormComponent],
  data() {
    return {
      valid: true,
      dialog: false,
      form: {
        department_id: "",
        location_id: "",
        priority: "",
        repair_type: "",
        itemsList: [],
        itemsTrash: [],
        remarks: "",
        status: "",
        others_text: "",
        total_cost:0,
      },
      priority: ["URGENT", "NOT URGENT"],
      disabled_view: false,
      action: "",
      userAccess: {
        approve: false,
        reject: false,
      },
      // others_dialog: false,
      // others_text_save: false,
      others_dialog: false,
      others_text_save: false,
      rrfPrintData: {},
    };
  },
  components: {
    QrcodeVue,
    // RepairRequestFormComponent,
    ItemsViewComponent,
  },
  computed: {
    ...mapGetters([
      "ACTION",
      "DIALOGS",
      "GET_ADMIN_LOCATION",
      "GET_RRF_DISPATCH",
      "GET_LIST_DEPARTMENT",
      "GET_RRF_DISPATCH_STATUS",
      "USER_ACCESS",
      "GET_ADMIN_REPAIR_TYPE_SELECTION",
      "GET_RRF_ITEMS_LIST",
      "GET_EDIT_RRF",
      "GET_NEW_RRF_TABLE",
      "GET_RRF_ITEMS_LOADED",
      "GET_ADMIN_SUPPLIER_SELECTION",
    ]),
    actions() {
      if (this.ACTION == "Submit") {
        this.disabled_view = false;
        if (this.form.itemsList.length == 0) {
          this.additems();
        }

        this.action = this.GET_RRF_DISPATCH.add;
        return "Submit";

      } else if (this.ACTION == "Update") {
        this.disabled_view = false;
        if (this.form.itemsList.length == 0) {
          this.additems();
        }

        this.form.status = 1;
        this.getEditRRF();
        this.action = this.GET_RRF_DISPATCH.update;
        return "Update";

      } else if (this.ACTION == "View") {
        this.getEditRRF();
        this.action = this.GET_RRF_DISPATCH.update;
        this.disabled_view = true;

        if (this.GET_EDIT_RRF.status == 1) {
          this.form.status = 2;

        } else if (this.GET_EDIT_RRF.status == 2) {
          this.form.status = 3;
        }
        // if (this.GET_EDIT_RRF.others_text) {
        //   this.others_text_save = true;
        // }
        return "View";
      }
    },
  },
  mounted() {
    this.$store.dispatch("repairTypeSelection");
  },
  methods: {
    additems() {
      this.form.itemsList.push({
        id: "",
        job_description: "",
        vendor_id: undefined,
        quoted_price: 0,
      });
    },
    async getEditRRF() {
      this.form.itemsList = [];
      this.form.department_id = this.GET_EDIT_RRF.department_id;
      this.form.location_id = this.GET_EDIT_RRF.location_id;
      this.form.priority = this.GET_EDIT_RRF.priorities;
      this.form.repair_type = this.GET_EDIT_RRF.repairtype_id;
      this.form.itemsList = this.GET_RRF_ITEMS_LIST;
      this.form.remarks = this.GET_EDIT_RRF.remarks;
      this.form.id = this.GET_EDIT_RRF.id;
      this.form.total_cost = this.GET_EDIT_RRF.total_cost;
      this.form.others_text = this.GET_EDIT_RRF.others_text;
      // if (this.GET_EDIT_RRF.others_text) {
      //   this.others_text_save = true;
      // }
    },
    removeitems(i) {
      this.form.itemsTrash.push({
        id: this.form.itemsList[i].id,
      });
      this.form.itemsList.splice(i, 1);
      if (this.form.itemsList.length == 0) {
        this.additems();
      }
    },
    async createOrUpdateAccountability() {
      const emptyFields = this.form.itemsList.some(item => {
        return !item.job_description || !item.vendor_id || !item.quoted_price;
      });

      const emptyFormFields = !this.form.location_id ||
      !this.form.priority || !this.form.repair_type || !this.form.remarks;

      if (emptyFields || emptyFormFields) {
        Swal.fire("Error!", "Empty Fields Detected!", "error");
      } else {
        this.$store.dispatch(this.action, this.form);
      }
    },
    closeDialog() {
      this.$store.commit("EDIT_RRF", {});
      this.$store.commit("RRF_ITEMS_LIST", []);
      this.$store.commit("DIALOG", false);

      this.resetFields();
    },
    resetFields() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    renderPDF() {
      this.$store.commit("RRF_PRINT", true);
    },
    // OthersTextSave() {
    //   if (this.form.others_text) {
    //     this.others_text_save = true;
    //     this.others_dialog = false;
    //   }
    // },
    // CloseOthersDialog() {
    //   this.others_dialog = false;
    //   if (this.form.others_text == "") {
    //     this.others_text_save = false;
    //     this.form.repair_type = "";
    //   }
    // },
    // ViewOthersDialog(event) {
    //   let find = this.GET_ADMIN_REPAIR_TYPE_SELECTION.find(
    //     (e) => e.value == event
    //   );
    //   let others = find ? find.others : "";
    //   if (others == 1) {
    //     this.others_dialog = true;
    //   } else {
    //     this.others_text_save = false;
    //     this.form.others_text = "";
    //   }
    // },
    totalCost(){
      let sum = 0;
      this.form.itemsList.forEach(e=>{
          sum += parseInt(e.quoted_price);
      })
      this.form.total_cost = sum
    }
  },
  watch: {
    'GET_EDIT_RRF': {
      handler(val) {
        if (val.id) this.$store.dispatch('rrfPrintData', {id: val.id}).then(response => {
          this.rrfPrintData = response.data;
        });
      },
      immediate: true,
      deep: true,
    },
    DIALOGS: {
      handler(val) {
        if (!val) {
          this.resetFields();
        }
      },
    },
    GET_NEW_RRF_TABLE: {
      handler(val) {
        if (val) {
          this.$store.commit("DIALOG", false);
          this.resetFields()
          Swal.fire("Success!", "Repair Request Save", "success");
          // this.$store.commit("NEW_RRF_TABLE", false);
        }
      },
    },
    USER_ACCESS: {
      handler(val) {
        if (val != "fail") {
          this.userAccess.approve = false;
          this.userAccess.reject = false;
          val.forEach((e) => {
            if (e.actions_code == "approve") {
              this.userAccess.approve = true;
            } else if (e.actions_code == "reject") {
              this.userAccess.reject = true;
            }
          });
        }
      },
    },
  },
};
</script>
<style scoped>
.red-text {
  color: red;
}
</style>