<template>
      <div class="w-100">
        <v-card>
            <v-container class="w-100">
            <v-tabs
                v-model="tab.main"
                id="group-tab"
            >
                <v-tab ripple>All</v-tab>
                <v-tab ripple>Saved</v-tab>
                <v-tab ripple>Approved</v-tab>
                <v-tab ripple>Confirmed</v-tab>
                <v-tab ripple>Cancelled</v-tab>

                <v-tabs-items v-model="tab.main" style="background-color:unset" class="">
                    <v-tab-item>
                        <SampleFormTable :tab_status="'all'"></SampleFormTable>
                    </v-tab-item>

                    <v-tab-item>
                        <SampleFormTable :tab_status="'saved'"></SampleFormTable>
                    </v-tab-item>

                    <v-tab-item>
                        <SampleFormTable :tab_status="'approved'"></SampleFormTable>
                    </v-tab-item>

                    <v-tab-item>
                        <SampleFormTable :tab_status="'confirmed'"></SampleFormTable>
                    </v-tab-item>

                    <v-tab-item>
                        <SampleFormTable :tab_status="'cancelled'"></SampleFormTable>
                    </v-tab-item>
                </v-tabs-items>
            </v-tabs>
            </v-container>
        </v-card>
      </div>
  </template>
  <script>
  import { mapGetters } from "vuex";
  import SampleFormTable from "@/views/main/modules/Operations/SampleForm/SampleFormComponent/SampleFormTableComponent.vue";
  export default {
    components: {
       SampleFormTable,
    },
    data() {
      return {
        tab: {
            main: null,
        }
      };
    },
    mounted() {
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
        this.$store.dispatch('checkAccessUser',this.$router.currentRoute.params.id);
    },
    computed:{
        ...mapGetters([
            'ACCESS_SAMPLE_FORM'
        ]),
    },
    methods: {

    },
    watch:{

    }
  };
  </script>
  <style>

  </style>
