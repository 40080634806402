<template>
    <div>
        <div class="mb-1 mt-2">
            <div class="row">
                <div class="col-12 d-flex no-block align-items-center">
                    <div class="btn-toolbar">
                        <v-btn data-modal-id="drFormModal" v-on:click="showDrForm()">
                            <i class="fas fa-plus pr-2"></i>Add
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>

        <!-- Add Delivery Receipt Dialog -->
        <v-dialog v-model="addDrDialog" persistent max-width="98%" scrollable>
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Add Delivery Receipt</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeAddDrDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider class="mt-0" />

                <v-card-text>
                    <div v-if="status == 'defective'" class="pa-4">
                        <div class="pb-2">
                            <div style="display:inline-block">
                                <v-autocomplete
                                    v-model="selectedScLocation"
                                    :items="scLocations"
                                    :rules="rules.default"
                                    item-text="name"
                                    item-value="id"
                                    label="Select Location"
                                    auto-select-first
                                    dense
                                    outlined
                                >
                                    <template v-slot:prepend>
                                        <span style="color:red">*</span>
                                    </template>
                                </v-autocomplete>
                                />
                            </div>
                        </div>

                        <div>
                            <div><span style="font-weight:bold">Name: </span><span>{{ scLocationName }}</span></div>
                            <div><span style="font-weight:bold">Contact Number: </span><span>{{ scLocationContactNumber }}</span></div>
                            <div><span style="font-weight:bold">Address: </span><span>{{ scLocationAddress }}</span></div>
                        </div>

                        <v-divider />
                    </div>

                    <v-layout v-else row class="pa-4">
                        <v-flex lg4 class="mr-4">
                            <div>
                                <v-autocomplete
                                    v-model="selectedCustomer"
                                    :items="customerList"
                                    :isDisabled="disableCustomerSelection"
                                    :rules="rules.default"
                                    item-text="company_name"
                                    item-value="id"
                                    label="Select Customer"
                                    auto-select-first
                                    dense
                                    outlined
                                >
                                    <template #prepend-inner>
                                        <span style="color:red">*</span>
                                    </template>
                                </v-autocomplete>
                            </div>
                        </v-flex>
                        <v-flex lg4 v-if="selectedCustomer == 275" class="mr-4">
                            <div>
                                <v-autocomplete
                                    v-model="selectedScCustomer"
                                    :items="scCustomers"
                                    :isDisabled="disableCustomerSelection"
                                    :rules="rules.default"
                                    item-text="end_user_name"
                                    item-value="id"
                                    label="Select Customer"
                                    auto-select-first
                                    dense
                                    outlined
                                >
                                    <template #prepend-inner>
                                        <span style="color:red">*</span>
                                    </template>
                            </v-autocomplete>
                            </div>
                        </v-flex>
                        <v-flex lg2 v-if="selectedCustomer == 275">
                            <v-checkbox v-model="carryIn" label="Carry In" dense class="pt-4" />
                        </v-flex>
                    </v-layout>
                    <v-layout row class="pa-4">
                        <v-flex lg4 class="pr-4">
                            <div>
                                <v-autocomplete
                                    v-model="vehicleId"
                                    :items="vehicles"
                                    :rules="rules.default"
                                    item-text="name"
                                    item-value="id"
                                    label="Select Vehicle"
                                    auto-select-first
                                    dense
                                    outlined
                                >
                                        <template #prepend-inner>
                                            <span style="color:red">*</span>
                                        </template>
                                </v-autocomplete>
                            </div>
                        </v-flex>

                        <v-flex lg8>
                            <div style="display:inline-block" class="mr-3">
                                <div>
                                    <v-text-field v-model="driverName" :rules="rules.default" label="Driver" dense outlined>
                                        <template #append>
                                            <span style="color:red">*</span>
                                        </template>
                                    </v-text-field>
                                </div>
                            </div>

                            <div style="display:inline-block" class="mr-3">
                                <div>
                                    <v-text-field v-model="helperName" dense outlined label="Helper 1">
                                    </v-text-field>
                                </div>
                            </div>
                            <div style="display:inline-block" class="mr-3">

                                <div>
                                    <v-text-field v-model="helperName2" dense outlined label="Helper 2">
                                    </v-text-field>
                                </div>
                            </div>
                            <div style="display:inline-block" class="mr-3">
                                <div>
                                    <v-text-field v-model="helperName3" dense outlined label="Helper 3">
                                    </v-text-field>
                                </div>
                            </div>
                        </v-flex>
                    </v-layout>

                    <v-layout row class="px-4">
                        <v-flex lg3>
                            <div class="mr-3">
                                <v-text-field v-model="guardName" :rules="rules.default" label="Guard Name" dense outlined>
                                    <template #append>
                                        <span style="color:red">*</span>
                                    </template>
                                </v-text-field>
                            </div>
                        </v-flex>
                        <v-flex lg3>
                            <div class="mr-3">
                                <v-text-field v-model="remarks" dense outlined label="Remarks"></v-text-field>
                            </div>

                        </v-flex>
                        <v-flex lg2>
                            <div class="mr-3">
                                <v-text-field v-model="number_of_boxes" dense outlined label="Number of boxes"></v-text-field>
                            </div>
                        </v-flex>
                    </v-layout>

                    <v-card class="pa-4 mt-4">
                        <div>
                            <label for="">Selected Count: {{ selected.length }}</label>
                        </div>
                        <v-data-table
                            :headers="tableHeaders()"
                            :items="repairForms"
                            show-select
                            v-model="selected"
                        >
                        <template v-slot:item.serial="{ item }">
                            {{ !!item.replacement_repair_form_id ? item.replacement_serial_number : item.serial }}
                        </template>
                        <template v-slot:item.created_at="{ item }">
                            {{ repairFormDate(item) }}
                        </template>
                        <template v-slot:header.data-table-select="{ header }">
                            <v-simple-checkbox style="display:none"></v-simple-checkbox>
                        </template>
                        </v-data-table>
                    </v-card>
                </v-card-text>

                <v-divider />

                <div style="text-align:center" class="pb-4">
                    <v-btn @click="createDeliveryReceipt()">Submit</v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
export default {
    data() {
        return {
            addDrDialog: false,
            selectedCustomer: '',
            customerList: [],
            rfHeaders: [
                { text: 'RF#', value: 'rf_number' },
                { text: 'Model', value: 'model' },
                { text: 'Name', value: 'product_name' },
                { text: 'Category', value: 'category' },
                { text: 'Serial', value: 'serial' },
                { text: 'Repair Status', value: 'repair_status' },
                { text: 'Date', value: 'created_at' },
                { text: 'PL#', value: 'packing_list_num' },
                { text: 'status', value: 'status' },

            ],
            rfEndUserHeaders: [
                { text: 'RF#', value: 'rf_number' },
                { text: 'Customer Name', value: 'end_user_name' },
                { text: 'Model', value: 'model' },
                { text: 'Name', value: 'product_name' },
                { text: 'Category', value: 'category' },
                { text: 'Serial', value: 'serial' },
                { text: 'Repair Status', value: 'repair_status' },
                { text: 'Date', value: 'created_at' },
                { text: 'PL#', value: 'packing_list_num' },
                { text: 'status', value: 'status' },

            ],
            repairForms: [],
            selected: [],
            remarks:'',
            number_of_boxes:0,
            receivingReports: [],
            selectedRr: '',
            disableCustomerSelection: false,
            status: 'pending',
            defRepairForms: [],
            driverName: '',
            helperName: '',
            helperName2: '',
            helperName3: '',
            vehicles: [],
            vehicleId: '',
            scCustomers: [],
            selectedScCustomer: '',
            scLocations: [],
            selectedScLocation: '',
            scLocationName: '',
            scLocationContactNumber: '',
            scLocationAddress: '',
            selectedScLocation: '',
            carryIn: false,
            vehicleName: '',
            guardName: '',
            rules: {
                default: [
                v => !!v || 'This is required',
                ],
            },
        }
    },
    mounted: function() {
        this.getCustomers();
        this.getVehicles();
    },
    props: ['drStatus'],
    watch: {
        status() {
            if(this.status == 'defective') {
                this.status = 'defective';
                this.getallDefRfs();
                this.getAllLocations();
            } else {
                this.status = 'pending';
                this.disableCustomerSelection = false;
                this.scLocationName = '';
                this.scLocationContactNumber = '';
                this.scLocationAddress = '';
                this.selectedScLocation = '';
            }
        },
        receivingReports() {
            if(this.receivingReports.length == 0) {
                this.repairForms = [];
            }
        },
        selectedCustomer() {

            this.receivingReports = [];
            if(!!this.selectedCustomer && this.status == 'pending') {
                // this.getReceivingReports();
                this.getallReadyForDeliveryRfs();
            }

            if(this.selectedCustomer == 275) {
                this.getScCustomers();
            }
        },
        selectedRr() {
            let rr = _.compact(this.receivingReports).find(x=>x.id == this.selectedRr);

            if(!!rr) {
                this.repairForms = rr.sc_repair_forms;
            }
        },
        selectedScCustomer() {
            if(!!this.selectedScCustomer) {
                this.getallReadyForDeliveryRfs();
            }
        },
        scLocations() {
            if(this.scLocations.length > 0) {
                let defaultWarehouse = this.scLocations.find(x=>x.default == 1);

                this.selectedScLocation = defaultWarehouse.id;
            }
        },
        selectedScLocation() {
            if(!!this.selectedScLocation) {
                let scLocation = this.scLocations.find(x=>x.id == this.selectedScLocation);

                const { code, name, contact_number, address } = scLocation

                this.scLocationName = name;
                this.scLocationContactNumber = contact_number;
                this.scLocationAddress = address;
            }
        },
        vehicleId() {
            if(!!this.vehicleId) {
                let vehicle = this.vehicles.find(x=>x.value == this.vehicleId);

                if(!!vehicle) {
                    this.vehicleName = vehicle.name;
                }
            }
        },
        carryIn() {
            if(!!this.carryIn) {
                let vehicle = this.vehicles.find(x=>x.name == 'Pick-up');

                this.vehicleId = vehicle.value;
            } else {
                this.vehicleId = '';
            }
        }
    },
    computed: {
        ...mapGetters([
            'GET_VEHICLES_LIST',
            'GET_SERVICE_ALL_CUSTOMERS',
            'GET_ALL_READY_FOR_DELIVERY_RFS',
            'GET_ALL_DEF_RFS',
            'GET_RECEIVING_REPORTS',
            'GET_SERVICE_ALL_LOCATIONS',
            'GET_CUSTOMERS',
        ]),
        checkStatus() {
            if(this.status == 'defective' && !this.selectedScLocation) {
                return true;
            }

            if(this.status == 'pending' && !this.selectedCustomer) {
                return true;
            }

            return false;
        },
        checkDriver() {
            if(this.carryIn) {
                return false;
            } else if(this.vehicleName == 'Pick-up') {
                return false;
            }

            return true;
        }
    },
    methods: {
        tableHeaders() {
            if(this.selectedCustomer == 275) {
                return this.rfEndUserHeaders;
            }

            return this.rfHeaders;
        },
        showDrForm() {
            this.addDrDialog = true;
            this.status = this.drStatus;
            this.getVehicles();
        },
        getVehicles(){
            this.$store.dispatch("getVehicles").then(response => {
                this.vehicles = this.GET_VEHICLES_LIST
            });
        },
        closeAddDrDialog() {
            this.addDrDialog = false;
            this.selectedCustomer = '';
            this.selectedRr = '';
            this.selected = [];
            this.status = 'pending';
            this.disableCustomerSelection = false;
            this.driverName = "";
            this.helperName = "";
            this.helperName2 = "";
            this.helperName3 = "";
            this.vehicleId = "";
            this.scLocationName = '';
            this.scLocationContactNumber = '';
            this.scLocationAddress = '';
            this.selectedScLocation = '';
            this.carryIn = false;
            this.vehicleName = '';
            this.number_of_boxes = '';
        },
        getCustomers() {
            this.$store.dispatch('getServiceAllCustomers').then(response => {
                this.customerList = this.GET_SERVICE_ALL_CUSTOMERS
            });
        },
        getallReadyForDeliveryRfs() {
            let payload = {
                customerId: this.selectedCustomer,
                scCustomerId: this.selectedScCustomer
            }
            this.$store.dispatch('getallReadyForDeliveryRfs',payload).then(response=>{
                this.repairForms = this.GET_ALL_READY_FOR_DELIVERY_RFS;
            });
        },
        getallDefRfs() {
            this.$store.dispatch('getallDefRfs').then(response=>{
                this.repairForms = this.GET_ALL_DEF_RFS;
            });
        },
        getReceivingReports() {
            this.$store.dispatch('getReceivingReports',this.selectedCustomer).then(response=>{
                this.receivingReports = this.GET_RECEIVING_REPORTS;
            });
        },
        getAllLocations() {
            this.$store.dispatch('serviceGetAllLocations').then(response=>{
                this.scLocations = this.GET_SERVICE_ALL_LOCATIONS;
            });
        },
        repairFormDate(rf) {
            return this.$dayjs(rf.created_at).format('YYYY-MM-DD, hh:mm:ss a');
        },
        createDeliveryReceipt() {
            if(!this.requiredFields()) {
                if(this.selected.length == 0) {
                    swal.fire('','Please select repair forms', 'warning');
                } else if(this.selected.length > 20) {
                    swal.fire('','Select only 20 repair forms', 'warning');
                } else {
                    swal.fire({
                        title: 'Are you sure you want to submit?',
                        text: 'Make sure that all information are correct',
                        icon: 'question',
                        dangerMode: true,
                        showConfirmButton:true,
                        showCancelButton  :  true,
                        reverseButtons:true,
                        allowOutsideClick :  false,
                        confirmButtonColor: '#397373',
                        cancelButtonColor : 'grey',
                        confirmButtonText:'Confirm',
                        showCloseButton:true,
                    }).then((result)=>{
                        if(result.isConfirmed) {
                            let helper = [];

                            if(!!this.helperName) {
                                helper.push(this.helperName);
                            } if(!!this.helperName2) {
                                helper.push(this.helperName2);
                            } if(!!this.helperName3) {
                                helper.push(this.helperName3);
                            }
                            let payload = {
                                customerId: this.selectedCustomer,
                                scLocationId: this.selectedScLocation,
                                scCustomerId: this.selectedScCustomer,
                                repairForms: this.selected,
                                status: this.status,
                                driverName: this.driverName,
                                vehicleId: this.vehicleId,
                                helperName: helper.toString(),
                                guardName: this.guardName,
                                remarks:this.remarks,
                                number_of_boxes:this.number_of_boxes
                            }
                            this.$store.dispatch('createDeliveryReceipt',payload).then(response=>{
                                if(!!response.data.msg) {
                                    this.closeAddDrDialog();
                                    this.$emit('successCreateDr', true);
                                    swal.fire("", response.data.msg, "success").then(ok=>{
                                        if(ok) {
                                            this.$emit('successCreateDr', false);
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            }
        },
        getScCustomers() {
            this.$store.dispatch('getScCustomers').then(response=>{
                this.scCustomers = response.data;
            });
        },
        requiredFields() {
            let warning = '';
            if (!this.selectedCustomer) warning += 'Please select a Customer.<br>';
            if (!this.vehicleId) warning += 'Please select a Vehicle.<br>';
            if (!this.driverName) warning += 'Please ensure that the empty field for the driver is filled in.<br>';
            if (!this.guardName) warning += 'Please ensure that the empty field for the guard name is filled in.<br>';
            
            if (this.selectedCustomer == 275) {
                if (!this.selectedScCustomer) warning += 'Please select a Customer.<br>';
            }
            
            if (this.status == 'defective') {
                if (!this.selectedScLocation) warning += 'Please select a Location.<br>';
            }
            if (warning !== '') return swal.fire({
                title: 'Please Fill out Information:',
                html: warning,
                icon: "warning",
            })
        },
    }
}
</script>

<style>

</style>
