<template>
    <v-btn
            v-ripple
            class="mx-auto text-white text-center custom-lg-btn btn-multiline"
            v-on="$listeners"
        >
        <v-icon>{{button_icon}}</v-icon>
          <span class="text-wrap">
            {{ button_title }}
          </span>
    </v-btn>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: ['button_title', 'button_icon', 'button_route'],
  methods: {},
}
</script>
<style>
.btn-multiline > span {
  width: 100%
}
</style>