<template>
    <v-container fluid>
        <ComponentHeader
            class="ma-n4"
            :button="true"
            title='PL vs SOA'
            type='Accounting'
        >
        </ComponentHeader>
            <v-row>
                <v-app id="my_vue_app" style="width:100%" class="mx-4 bg-bluish-gray pa-3 px-5">
                    <v-row class="p-2">
                        <v-col cols="4">
                            <v-autocomplete
                                v-model="customers"
                                :items="GET_CUSTOMERS_SELECTION"
                                label="Select Customer"
                                dense
                                outlined
                                clearable
                                auto-select-first
                                background-color="white"
                            />
                        </v-col>
                        <v-col cols="4">
                            <v-autocomplete
                                v-model="mother_customers"
                                :items="GET_AFFLIATE_CUSTOMER"
                                label="Select Mother Customer"
                                dense
                                outlined
                                clearable
                                auto-select-first
                                background-color="white"
                            />
                        </v-col>

                        <v-spacer />
                        <v-col sm="12" md="4" class="text-right">
                            <v-btn small class="ml-1" @click="resetFields()">Clear</v-btn>
                            <v-btn small class="ml-1" @click="exportToExcel()">Excel</v-btn>
                            <v-btn small class="ml-1" @click="getPlvsSoa()">Filter</v-btn>
                        </v-col>
                    </v-row>

                    <v-row class="p-2">
                        <v-col cols="2">
                            <v-autocomplete
                                v-model="selectedCluster"
                                :items="GET_CLUSTER_SELECTION"
                                item-value="value"
                                item-text="text"
                                label="Cluster"
                                dense
                                outlined
                                clearable
                                auto-select-first
                                background-color="white"
                            />
                        </v-col>
                        <v-col cols="2">
                            <v-autocomplete
                                v-model="selectedSubCluster"
                                :items="GET_SUB_CLUSTER_SELECTION"
                                item-value="value"
                                item-text="text"
                                label="Sub Cluster"
                                dense
                                outlined
                                clearable
                                multiple
                                small-chips
                                deletable-chips
                                background-color="white"
                            />
                        </v-col>
                        <v-col sm="12" md="2" class="d-flex flex-row">
                            <v-menu
                                ref="menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="selectedRange.from"
                                        v-on="on"
                                        label="Date From"
                                        dense
                                        outlined
                                        hide-details
                                        readonly
                                        background-color="white"
                                    />
                                </template>
                                <v-date-picker
                                    v-model="selectedRange.from"
                                    type="month"
                                    no-title
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col sm="12" md="2" class="d-flex flex-row">
                            <v-menu
                                ref="menus"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="selectedRange.to"
                                        v-on="on"
                                        label="Date To"
                                        dense
                                        outlined
                                        hide-details
                                        readonly
                                        background-color="white"
                                    />
                                </template>
                                <v-date-picker
                                    v-model="selectedRange.to"
                                    type="month"
                                    no-title
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-app>
            </v-row>

            <v-row>
                <v-app id="my_vue_app" style="width:100%" class="mx-4 bg-bluish-gray px-5">
                    <v-row dense class="my-2">
                        <v-col cols="12">
                            <v-card>
                                <v-card-text v-if="dataTable.loading">
                                    <v-col lg="12" class="d-flex justify-content-center align-items-center">
                                        <LoadingComponentVue class="my-2"></LoadingComponentVue>
                                    </v-col>
                                </v-card-text>

                                <v-card-text v-else>
                                    <v-data-table
                                        :headers="dataTable.headers"
                                        :items="dataTable.items"
                                        :options.sync="dataTable.options"
                                        :footer-props="{
                                            itemsPerPageOptions: itemsPerPageOptions,
                                        }"
                                        :server-items-length="dataTable.totalItems"
                                        dense
                                    >

                                    <template v-slot:[`item.action`]="{ item }">
                                        <v-btn icon text @click="viewCustomer('View',item)" color="orange" small>
                                            <v-icon small >mdi-eye</v-icon>
                                        </v-btn>
                                    </template>

                                    <template v-slot:[`item.pl_total_amount`]="{ item }">
                                        <span> &#x20b1;{{ item.pl_total_amount | currency }} </span>
                                    </template>

                                    <template v-slot:[`item.soa_total_amount`]="{ item }">
                                        <span> &#x20b1;{{ item.soa_total_amount | currency }} </span>
                                    </template>

                                    <template v-slot:[`item.match`]="{ item }">
                                        <v-chip small color="success" v-if="item.pl_total_amount == item.soa_total_amount">
                                            {{ 'Yes' }}
                                        </v-chip>
                                        <v-chip small color="red" v-else>
                                            {{ 'No' }}
                                        </v-chip>
                                    </template>

                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-app>
            </v-row>

            <v-dialog v-model="plSoaDialog" max-width="50%" persistent>
                <v-card>
                    <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                        <span class="headline"> View PL vs SOA </span>
                            <v-row class="m-0">
                                <v-col cols="pull-right-10 p-2">
                                    <v-btn
                                    text
                                    icon
                                    small
                                    color="gray"
                                    class="float-right"
                                    @click="() => {
                                        plSoaDialog = !plSoaDialog
                                    }"
                                    >
                                    <v-icon>mdi-close-circle</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                    </v-card-title>

                    <v-card-text class="text-dark">
                        <div class="ma-4">
                            <v-row class="px-4">
                                <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="d-flex justify-start text-left">
                                    Customer#: {{ displayCustomer }}
                                    <br>
                                    Salesman: {{ displaySalesman }}
                                </v-col>
                                <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="d-flex justify-end text-right">
                                    Total PL Amount: &#x20b1;{{ displayTotalPlAmount | currency }}
                                    <br>
                                    Total SOA Amount: &#x20b1;{{ displayTotalSoaAmount | currency }}
                                </v-col>
                            </v-row>
                        </div>

                        <div class="ma-4">
                            <v-data-table
                                :headers="dataTableItem.headers"
                                :items="dataTableItem.items"
                                :options.sync="dataTableItem.options"
                                :server-items-length="dataTableItem.totalItems"
                                :loading="dataTableItem.loading"
                                dense
                            >
                                <template v-slot:[`item.pl_amount`]="{ item }">
                                    <span> &#x20b1;{{ item.pl_amount | currency }} </span>
                                </template>

                                <template v-slot:[`item.soa_amount`]="{ item }">
                                    <span> &#x20b1;{{ item.soa_amount | currency }} </span>
                                </template>
                            </v-data-table>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>
    </v-container>
</template>

<script>
import SharedFunctionsComponent from "@/views/main/Shared/SharedFunctionsComponent.vue";
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
import LoadingComponentVue from '@/views/main/modules/Services/Views/TechnicianManagerComponent/LoadingComponent.vue';
import { mapGetters } from 'vuex';
import swal from "sweetalert2";

export default {
    mixins: [
        SharedFunctionsComponent,
    ],
    components:{
        ComponentHeader,
        LoadingComponentVue
    },
    data() {
        return {
            customers: [],
            mother_customers: [],
            selectedCluster: [],
            selectedSubCluster: [],
            itemsPerPageOptions: [ 5, 10, 25, 50, -1],
            dataTable: {
                headers: [
                    { text: 'Customer', sortable: false, align: 'left', value: 'customer_name' },
                    { text: `Salesman`, sortable: false, align: 'left', value: 'salesman' },
                    { text: `Total PL Amount`, sortable: false, align: 'left', value: 'pl_total_amount' },
                    { text: `Total SOA Amount`, sortable: false, align: 'left', value: 'soa_total_amount' },
                    { text: `Match`, sortable: false, align: 'left', value: 'match' },
                    { text: `Action`, sortable: false, align: 'left', value: 'action' },
                ],
                items: [],
                totalItems: 0,
                loading: false,
                options: {},
            },
            dataTableItem: {
                headers: [
                    { text: 'PL#', sortable: false, align: 'left', value: 'pl_num' },
                    { text: `PL Amount`, sortable: false, align: 'end', value: 'pl_amount' },
                    { text: `SOA Amount`, sortable: false, align: 'end', value: 'soa_amount' },
                    { text: `SOA ID`, sortable: false, align: 'center', value: 'soa_id' },
                ],
                items: [],
                totalItems: 0,
                loading: false,
                options: {},
            },
            selectedRange: {
                from: this.$dayjs().startOf('month').format('YYYY-MM'),
                to: this.$dayjs().endOf('month').format('YYYY-MM')
            },
            plSoaDialog: false,
            displayCustomer: '',
            displaySalesman: '',
            displayTotalPlAmount: '',
            displayTotalSoaAmount: '',
            summary: [],
            detailed: [],
        }
    },
    methods: {
        getPlvsSoa() {
            let info = '';
            if (this.selectedRange.from != this.selectedRange.to) info += 'Your date filter is supposed to be for only one month.<br>';

            if (info !== '') {
                return swal.fire({
                    title: 'Must meet the following before confirming:',
                    html: info,
                    icon: "info",
                })
            }

            this.dataTable.items = []
            this.dataTable.loading = true
            let payload = {
                url:                        'pl-vs-soa',
                page:                       this.dataTable.options.page,
                itemsPerPage:               this.dataTable.options.itemsPerPage,
                customer_id:                this.customers,
                is_mother_customer:         this.mother_customers,
                cluster:                    this.selectedCluster,
                sub_cluster:                this.selectedSubCluster,
                date_from:                  this.selectedRange.from,
                date_to:                    this.selectedRange.to,
            }
            this.$store.dispatch('reports', payload).then(response => {
                this.dataTable.items                = response.data
                this.dataTable.totalItems           = response.total
                this.dataTable.loading              = false
            })
        },

        exportToExcel() {
            swal.fire({
                title:                      'Please wait...',
                allowOutsideClick:          false,
            })
            swal.showLoading();

            let payload = {
                url:                        'pl-vs-soa-export',
                customer_id:                this.customers,
                is_mother_customer:         this.mother_customers,
                cluster:                    this.selectedCluster,
                sub_cluster:                this.selectedSubCluster,
                date_from:                  this.selectedRange.from,
                date_to:                    this.selectedRange.to,
            }
            this.$store.dispatch('reports', payload).then(response => {
                this.summary = response.data.summary
                this.detailed = response.data.detailed

                const workbook = new this.$exceljs.Workbook()

                const summary = workbook.addWorksheet('Summary');
                summary.columns = [
                    { header: 'CUSTOMER', key: 'customer_name', width: 60 },
                    { header: 'SALESMAN', key: 'salesman', width: 40 },
                    { header: 'TOTAL PL AMOUNT', key: 'pl_total_amount', style: { numFmt: '#,##0.00' }, width: 25 },
                    { header: 'TOTAL SOA AMOUNT', key: 'soa_total_amount', style: { numFmt: '#,##0.00' }, width: 25 },
                    { header: 'MATCH', key: 'match', width: 15 },
                ];

                const headerRow = summary.getRow(1);
                headerRow.font = { bold: true }
                for (let c=1;c<=summary.columns.length;c++) {
                    let currentCell = headerRow.getCell(c);
                    currentCell.fill = {
                        type: 'pattern',
                        pattern:'solid',
                        fgColor:{argb:'F69F1A'},
                    }
                }

                this.summary.forEach(e => {
                    e.pl_total_amount = e.pl_total_amount == null ? 0 : parseFloat(e.pl_total_amount)
                    e.soa_total_amount = e.soa_total_amount  == null ? 0 : parseFloat(e.soa_total_amount)
                    e.match = e.pl_total_amount == e.soa_total_amount ? 'Yes' : 'No'
                    const worksheetRow = summary.addRow(e);
                    const firstCell = worksheetRow.getCell(5);
                    firstCell.alignment = { vertical: 'middle', horizontal: 'center' };
                });

                const detailed = workbook.addWorksheet('Detailed');
                detailed.columns = [
                    { header: 'CUSTOMER', key: 'customer_name', width: 60 },
                    { header: 'SALESMAN', key: 'salesman', width: 40 },
                    { header: 'PL #', key: 'pl_num', width: 15 },
                    { header: 'PL AMOUNT', key: 'pl_amount', style: { numFmt: '#,##0.00' }, width: 25 },
                    { header: 'SOA AMOUNT', key: 'soa_amount', style: { numFmt: '#,##0.00' }, width: 25 },
                    { header: 'SOA ID', key: 'soa_id', width: 15 },
                    { header: 'MATCH', key: 'match', width: 15 },
                ];

                const headerDetailedRow = detailed.getRow(1);
                headerDetailedRow.font = { bold: true }
                for (let c=1;c<=detailed.columns.length;c++) {
                    let currentCell = headerDetailedRow.getCell(c);
                    currentCell.fill = {
                        type: 'pattern',
                        pattern:'solid',
                        fgColor:{argb:'F69F1A'},
                    }
                }

                this.detailed.forEach(e => {
                    e.pl_amount = e.pl_amount == null ? 0 : parseFloat(e.pl_amount)
                    e.soa_amount = e.soa_amount  == null ? 0 : parseFloat(e.soa_amount)
                    e.match = e.pl_amount == e.soa_amount ? 'Yes' : 'No'
                    const worksheetRow = detailed.addRow(e);
                    const firstCell = worksheetRow.getCell(7);
                    firstCell.alignment = { vertical: 'middle', horizontal: 'center' };
                });

                workbook.xlsx.writeBuffer().then((data) => {
                    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                    this.$filesaver.saveAs(blob, `PL vs SOA.xlsx`);
                    swal.close();
                });

            }).catch(e => {
                swal.fire("Error!", '', "error");
                swal.close();
            })
        },

        resetFields() {
            this.customers                  = []
            this.mother_customers           = []
            this.selectedCluster            = []
            this.selectedSubCluster         = []
        },

        viewCustomer(action = 'View', item) {
            this.plSoaDialog = true
            this.displayCustomer            = item.customer_name
            this.displaySalesman            = item.salesman
            this.displayTotalPlAmount       = item.pl_total_amount
            this.displayTotalSoaAmount      = item.soa_total_amount
            this.dataTableItem.items        = []
            this.dataTableItem.loading      = true

            let payload = {
                url:                        'pl-vs-soa-item',
                customer_id:                item.id,
                date_from:                  this.selectedRange.from,
                date_to:                    this.selectedRange.to,
            }
            this.$store.dispatch('reports', payload).then(response => {
                this.dataTableItem.items            = response.data
                this.dataTableItem.totalItems       = response.total
                this.dataTableItem.loading          = false
            })
        }
    },
    computed:{
        ...mapGetters([
            "GET_CUSTOMERS_SELECTION",
            "GET_AFFLIATE_CUSTOMER",
            "GET_CLUSTER_SELECTION",
            "GET_SUB_CLUSTER_SELECTION",
        ]),
    },
    mounted() {
        this.$store.dispatch('getAllcustomersSelection')
        this.$store.dispatch('getAffliatecustomer')
        this.$store.dispatch('getClusterSelection')
    },
    watch: {
        'selectedCluster': {
            handler(val) {
                this.$store.dispatch("getSubClusterSelection", {cluster_ids: [val]});
            },
            immediate: true,
            deep: true,
        },

        'selectedRange.from': {
            handler(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.$refs.menu.isActive = false;
                }
            }
        },

        'selectedRange.to': {
            handler(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.$refs.menus.isActive = false;
                }
            }
        },
    },
}
</script>

<style>

</style>
