<template>
    <v-form ref="form" v-model="valid">
        <!-- Functions -->
        <v-card class="px-3 rounded-0">
            <v-card-subtitle>
                <v-row class="pb-2">
                    <v-col v-if="RECURRING_BILL.TAB != 'SUMMARY'" lg="3">
                        <v-btn v-if="userAccess.includes('create')" class="m-3" @click="mode()">
                            <v-icon>mdi-plus</v-icon>Create
                        </v-btn>
                    </v-col>
                        <v-spacer/>
                    <v-col lg="2" class="text-right my-auto pl-2">
                        <v-btn v-if="userAccess.includes('export')" :loading="exportLoad" :disabled="disableExport" @click="getAllRecords('1')">
                            EXPORT
                        </v-btn>
                    </v-col>
                    <!-- <v-col lg="1" class="d-flex align-items-center">
                        <span class="badge bg-primary ml-1 text-white" style="cursor:pointer" @click="chipStatus('1')">pending</span>
                        <span class="badge bg-primary ml-1 text-white" style="cursor:pointer" @click="chipStatus('2')">for payment</span>
                        <span class="badge bg-success ml-1 text-white" style="cursor:pointer" @click="chipStatus('3')">paid</span>
                        <span class="badge bg-warning ml-1 text-dark" style="cursor:pointer" @click="chipStatus('3')">partial</span>
                    </v-col> -->
                </v-row>
                <v-row class="ml-0">
                    <v-col lg="2">
                        <v-autocomplete
                            v-model="filters.prepared_by"
                            :items="GET_PREPARED_BY"
                            label="Prepared By"
                            outlined
                            dense
                            auto-select-first
                            hide-details
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col lg="2">
                        <v-autocomplete
                            v-model="filters.vendor_id"
                            :items="GET_ADMIN_SUPPLIER_SELECTION"
                            label="Vendor"
                            outlined
                            dense
                            auto-select-first
                            hide-details
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col lg="2">
                        <v-autocomplete
                            v-model="filters.company_id"
                            :items="GET_PV_COMPANIES_SELECTION"
                            label="Company"
                            outlined
                            dense
                            auto-select-first
                            hide-details
                            clearable
                            @change="insertStore(1)"
                        ></v-autocomplete>
                    </v-col>
                    <v-col lg="2">
                        <v-autocomplete
                            v-model="filters.store_branches_id"
                            :items="branchItemsForFilter"
                            label="Branch"
                            no-data-text="No Stores Found"
                            outlined
                            dense
                            auto-select-first
                            hide-details
                            clearable
                            @change="insertCompany(filters.store_branches_id, 1)"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="1" class="pl-2">
                        <v-btn @click="getAllRecords()">
                            FILTER
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="ml-0">
                    <v-col lg="2">
                        <v-autocomplete
                            v-model="filters.company_classification_id"
                            :items="GET_COMPANY_CLASSIFICATION_SELECTION"
                            label="Classification"
                            outlined
                            dense
                            auto-select-first
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col lg="2">
                        <v-menu
                            v-model="menu.date_from"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_from"
                                    prepend-inner-icon="mdi-calendar"
                                    outlined
                                    dense
                                    readonly
                                    v-on="on"
                                    style="display: block"
                                    background-color="white"
                                    label="Date From"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_from" @input="menu.date_from = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col lg="2">
                        <v-menu
                            v-model="menu.date_to"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_to"
                                    prepend-inner-icon="mdi-calendar"
                                    outlined
                                    dense
                                    readonly
                                    v-on="on"
                                    style="display: block"
                                    background-color="white"
                                    label="Date To"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_to" @input="menu.date_to = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
                <v-row>
                    <v-spacer/>
                    <v-col lg="3" class="pull-right">
                        <v-text-field
                            v-model="search"
                            :label="RECURRING_BILL.TAB != 'SUMMARY' ? 'Search by Transaction No.': 'Search by Account No.'"
                            append-icon="mdi-magnify"
                            @keydown.enter="getAllRecords()"
                            @click:append="getAllRecords()"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card-subtitle>
            <!-- Table -->
            <v-card-text>
                <v-row>
                    <v-col lg="12">
                        <v-data-table
                            :headers="getDataTableHeaders"
                            :items="records"
                            :options.sync="options"
                            :server-items-length="totalRecords"
                            :items-per-page="10"
                            :loading="tableLoad"
                            dense
                            :footer-props="{
                                itemsPerPageOptions: itemsPerPageOptions
                            }"
                        >
                        <template v-slot:[`item.vendor_name`]="{ item }">
                            <span>
                                {{ item.vendor_name ? truncateText(item.vendor_name, 25) : 'N/A' }}
                            </span>
                        </template>
                        <template v-slot:[`item.account_num`]="{ item }">
                            <span>
                                {{ item.account_num ? truncateText(item.account_num, 25) : 'N/A' }}
                            </span>
                        </template>
                        <template v-slot:[`item.reference_num`]="{ item }">
                            <span>
                                {{ item.reference_num ? truncateText(item.reference_num, 25) : 'N/A' }}
                            </span>
                        </template>
                        <template v-slot:[`item.amount`]="{ item }">
                            <span>
                                &#x20b1;{{ item.amount | currency }}
                            </span>
                        </template>
                        <template v-slot:[`item.status`]="{ item }">
                            <a  class="ml-1 badge text-white" :style="`background-color:${ STATUS.RECURRING_BILL_STATUS.find(e=>e.value === item.status)?.color }`">
                                {{ STATUS.RECURRING_BILL_STATUS.find(e=>e.value === item.status)?.text }}
                            </a>
                        </template>
                        <template v-slot:[`item.action`]="{ item }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" v-if="userAccess.includes('view')" text icon color="orange" @click="mode('view',item.id)">
                                        <v-icon small>mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                                <span>View Recurring Bill</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" v-if="userAccess.includes('edit') && item.status == 1" text icon color="blue" @click="mode('edit',item.id)">
                                        <v-icon small>mdi-pencil</v-icon>
                                    </v-btn>
                                </template>
                                <span>Edit Recurring Bill</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" v-if="userAccess.includes('delete') && [1,2,4].includes(item.status) " text icon color="red" @click="destroy(item.id)">
                                        <v-icon small>mdi-delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>Delete Recurring Bill</span>
                            </v-tooltip>
                        </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
            <!-- Dialog -->
            <v-dialog v-model="recordDialog" persistent scrollable :width="RECURRING_BILL.TAB != 'FOR PDC' ? '80%' : '80%'">
                <v-card elevation="0" :class="dialogLoader ? 'overflow-hidden' : 'overflow-auto'" max-width="100%" ref="scrollContent">
                    <v-card-title v-if="!dialogLoader">
                        <v-container class="pa-0">
                            <v-row class="m-0">
                                <v-col>
                                    <span class="headline">
                                        {{
                                            RECURRING_BILL.ACTION.charAt(0).toUpperCase() +
                                            RECURRING_BILL.ACTION.slice(1)
                                        }} RB
                                        {{ recordNumber }}
                                        {{ classificationText ? '(' + classificationText + ')' : '' }}
                                    </span>
                                </v-col>
                                <v-col cols="pull-right-1">
                                    <v-btn text icon color="gray" class="float-right" @click="toggleDialog('close')">
                                        <v-icon>mdi-close-circle</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-card elevation="0" dense>
                                <v-card-text>
                                    <v-row class="pb-2">
                                            <div v-if="RECURRING_BILL.ACTION != 'view'" style="display:inline-block; transform: translate(0px,-10px);">
                                                <FileUploadComponent
                                                    class="mr-2"
                                                    id="attachment"
                                                    @uploadedData="uploadedData"
                                                    ref="fileUpload"
                                                    @reset="clearFileUpload"
                                                />
                                            </div>
                                            <BaseFileViewerComponent
                                                :access="true"
                                                :payloadId="form.id ? form.id : null"
                                                :payloadIds="[]"
                                                :getFilesPath="'getRecordFiles'"
                                                :deleteFilesPath="form.status == 1 ? 'deleteRecordFiles' : ''"
                                                :module="'admin'"
                                                :folder="'recurring_bill_uploads'"
                                                :localFiles="files"
                                                @files="processFiles"
                                            />
                                            <v-spacer/>
                                            <v-col cols="2" class="d-flex justify-end">
                                                <v-checkbox
                                                    v-model="form.contract"
                                                    :true-value='1'
                                                    :false-value='0'
                                                    hide-details
                                                >
                                                    <template v-slot:label>
                                                        <span style="cursor: pointer;">Contract</span>
                                                    </template>
                                                </v-checkbox>
                                            </v-col>
                                    </v-row>
                                    <v-row class="pb-2">
                                        <strong>Recurring Bill Details</strong>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4">
                                            <v-autocomplete
                                                v-model="form.vendor_id"
                                                :items="GET_ADMIN_SUPPLIER_SELECTION"
                                                :readonly="isDisabled()"
                                                :rules="required"
                                                class="required-field"
                                                auto-select-first
                                                outlined
                                                dense
                                            >
                                                <template v-slot:label>
                                                    <span>Select Vendor <span style="color: red;">*</span></span>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-autocomplete
                                                v-model="form.company_id"
                                                :items="GET_PV_COMPANIES_SELECTION"
                                                :readonly="isDisabled()"
                                                :rules="required"
                                                outlined
                                                dense
                                                @change="insertStore(2)"
                                            >
                                                <template v-slot:label>
                                                    <span>Select Company <span style="color: red;">*</span></span>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-autocomplete
                                                v-model="form.store_branches_id"
                                                :items="branchItems"
                                                :readonly="isDisabled()"
                                                :rules="required"
                                                no-data-text="No Branches Found!"
                                                outlined
                                                dense
                                                @change="insertCompany(form.store_branches_id, 2)"
                                            >
                                                <template v-slot:label>
                                                    <span>Select Branch <span style="color: red;">*</span></span>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4">
                                            <v-autocomplete
                                                v-model="form.recurring_bill_type_id"
                                                :items="GET_RB_TYPES"
                                                :readonly="isDisabled()"
                                                :rules="required"
                                                auto-select-first
                                                outlined
                                                dense
                                            >
                                                <template v-slot:label>
                                                    <span>Select Type <span style="color: red;">*</span></span>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field
                                                v-model="form.account_num"
                                                :readonly="isDisabled()"
                                                :rules="required"
                                                outlined
                                                dense
                                            >
                                                <template v-slot:label>
                                                    <span>Account Number <span style="color: red;">*</span></span>
                                                </template>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row class="py-2">
                                        <strong>Optional Details</strong>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4">
                                            <v-autocomplete
                                                v-model="form.employee_id"
                                                :items="GET_EMPLOYEE_DATA"
                                                :readonly="isDisabled()"
                                                :disabled="requiredFirst"
                                                item-value="value"
                                                item-text="full_name"
                                                label="Employee (Optional)"
                                                auto-select-first
                                                outlined
                                                dense
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field
                                                v-model="form.reference_num"
                                                :readonly="isDisabled()"
                                                :disabled="requiredFirst"
                                                label="Reference Number (Optional)"
                                                outlined
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field
                                                v-model="form.address"
                                                :readonly="isDisabled()"
                                                :disabled="requiredFirst"
                                                label="Address (Optional)"
                                                outlined
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="4" v-show="!issue_pdc">
                                            <v-menu
                                                :close-on-content-click="true"
                                                transition="scale-transition"
                                                max-width="290"
                                                :disabled="isDisabled()"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        v-model="form.bill_date"
                                                        v-on="on"
                                                        :readonly="!form.frequency_type_id"
                                                        :disabled="requiredFirst"
                                                        :rules="!issue_pdc ? required : []"
                                                        dense
                                                        readonly
                                                        append-icon="mdi-calendar"
                                                        label="Billing Date"
                                                        outlined
                                                    ></v-text-field>
                                                </template>
                                                    <v-date-picker v-model="form.bill_date"></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="4" v-show="!issue_pdc">
                                            <v-menu
                                                :close-on-content-click="true"
                                                transition="scale-transition"
                                                max-width="290"
                                                :disabled="isDisabled()"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        v-model="form.due_date"
                                                        v-on="on"
                                                        :readonly="isDisabled()"
                                                        :disabled="requiredFirst"
                                                        :rules="!issue_pdc ? required : []"
                                                        dense
                                                        readonly
                                                        append-icon="mdi-calendar"
                                                        label="Due Date"
                                                        outlined
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker v-model="form.due_date"></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                    <v-row v-show="!issue_pdc">
                                        <v-spacer/>
                                        <v-row align="center">
                                            <v-col class="text-end mr-5">
                                                <strong>Amount: </strong>
                                            </v-col>
                                        </v-row>
                                        <v-col cols="3">
                                            <v-text-field
                                                v-model="form.amount"
                                                :readonly="isDisabled()"
                                                :disabled="requiredFirst"
                                                :rules="!issue_pdc ? required : []"
                                                placeholder="0.00"
                                                type="number"
                                                outlined
                                                dense
                                                hide-spin-buttons
                                                class="right-align-text"
                                                @keypress="decimalOnly"
                                            >
                                                <template v-slot:prepend-inner>
                                                    <v-icon small>mdi-currency-php</v-icon>
                                                </template>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-textarea
                                                v-model="form.remarks"
                                                :readonly="isDisabled()"
                                                :disabled="requiredFirst"
                                                label="Remarks (Optional)"
                                                outlined
                                                dense
                                                auto-grow
                                            ></v-textarea>
                                        </v-col>
                                    </v-row>
                                    <!-- <v-row v-if="RECURRING_BILL.ACTION == 'create'" class="pb-2">
                                        <v-col cols="3">
                                            <v-checkbox
                                                v-model="issue_pdc"
                                                :disabled="requiredFirst"
                                                hide-details
                                            >
                                                <template v-slot:label>
                                                    <span style="cursor: pointer;">Issue Payment</span>
                                                </template>
                                            </v-checkbox>
                                        </v-col>
                                    </v-row> -->
                                    <v-row v-if="RECURRING_BILL.ACTION == 'create'" class="py-2">
                                        <v-col lg="3">
                                            <v-btn @click="batchAddition()"
                                                v-if="RECURRING_BILL.ACTION != 'view'"
                                                small
                                                :disabled="requiredFirst"
                                            >
                                                <v-icon>mdi-plus</v-icon>Batch Addition
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="RECURRING_BILL.ACTION == 'create' && issue_pdc">
                                        <v-col cols="12">
                                            <v-simple-table fixed-header dense class="overflow-y-auto" style="border: 1px solid #e0e0e0; max-height: 283px;">
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th class="text-left" width="16%">NUMBER OF PAYMENTS</th>
                                                            <th class="text-left" width="20%">START MONTH</th>
                                                            <th class="text-left" width="20%">BILLING DATE</th>
                                                            <th class="text-left" width="20%">DUE DATE</th>
                                                            <th class="text-right">PAYMENT AMOUNT</th>
                                                            <th class="text-right"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr class="font-weight-medium text-gray particulars-container-row">
                                                            <td>
                                                                <!-- :rules="issue_pdc ? required : []" -->
                                                                <v-text-field
                                                                    v-model="payment_data.count"
                                                                    :disabled="requiredFirst"
                                                                    :rules="[]"
                                                                    class="center-align-text"
                                                                    type="number"
                                                                    hide-spin-buttons
                                                                    @keypress="countingNumberOnly"
                                                                ></v-text-field>
                                                            </td>
                                                            <td>
                                                                <v-menu
                                                                    :close-on-content-click="true"
                                                                    transition="scale-transition"
                                                                    max-width="290"
                                                                >
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-text-field
                                                                            v-model="payment_data.start_month"
                                                                            v-on="on"
                                                                            :readonly="isDisabled()"
                                                                            :disabled="requiredFirst"
                                                                            :rules="[]"
                                                                            readonly
                                                                            class="center-align-text"
                                                                            append-icon="mdi-calendar"
                                                                        ></v-text-field>
                                                                    </template>
                                                                        <v-date-picker v-model="payment_data.start_month" :min="minDate" type="month"></v-date-picker>
                                                                </v-menu>
                                                            </td>
                                                            <td>
                                                                <v-autocomplete
                                                                    v-model="payment_data.bill_date"
                                                                    :items="dates"
                                                                    :readonly="isDisabled()"
                                                                    :prefix="'Every'"
                                                                    :disabled="requiredFirst"
                                                                    :rules="[]"
                                                                    class="center-align-text"
                                                                    auto-select-first
                                                                ></v-autocomplete>
                                                            </td>
                                                            <td>
                                                                <v-text-field
                                                                    v-model="payment_data.due_date"
                                                                    :readonly="isDisabled()"
                                                                    :disabled="requiredFirst"
                                                                    :suffix="'days after billing'"
                                                                    :rules="[]"
                                                                    type="number"
                                                                    class="center-align-text"
                                                                    hide-spin-buttons
                                                                    @keypress="countingNumberOnly"
                                                                ></v-text-field>
                                                            </td>
                                                            <td>
                                                                <v-text-field
                                                                    v-model="payment_data.amount"
                                                                    :disabled="requiredFirst"
                                                                    class="right-align-text"
                                                                    type="number"
                                                                    hide-spin-buttons
                                                                    @keypress="decimalOnly"
                                                                >
                                                                    <template v-slot:prepend-inner>
                                                                        <v-icon>mdi-currency-php</v-icon>
                                                                    </template>
                                                                </v-text-field>
                                                            </td>
                                                            <td>
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{on}">
                                                                        <v-btn :disabled="checkPaymentData" v-on="on" icon color="success" @click="generatePayments()">
                                                                            <v-icon large>mdi-cash</v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                        <span>Generate Payment Entries</span>
                                                                </v-tooltip>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="payment_detail_table && form.recurring_bill_details.length > 0">
                                        <v-col class="py-2">
                                            <strong>
                                                Payment Details:
                                            </strong>
                                        </v-col>
                                        <v-col class="text-right" v-if="RECURRING_BILL.ACTION == 'create'">
                                            <v-btn :disabled="checkParticulars" small @click="setParticularsByDates()">Set Particulars</v-btn>
                                            <v-btn small @click="clearPayments()">Clear</v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="payment_detail_table && form.recurring_bill_details.length > 0">
                                        <v-col cols="12">
                                            <v-simple-table fixed-header dense class="overflow-y-auto" style="border: 1px solid #e0e0e0; max-height: 283px;">
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th class="text-center" width="10%">PAYMENT #</th>
                                                            <th class="text-left" width="45%">PARTICULARS (OPTIONAL)</th>
                                                            <th class="text-left" width="15%">BILLING DATE</th>
                                                            <th class="text-left" width="15%">DUE DATE</th>
                                                            <th class="text-right" width="15%">AMOUNT</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="particulars-container">
                                                        <tr v-for="(item, index) in form.recurring_bill_details" :key="item.id"
                                                            class="font-weight-medium text-gray particulars-container-row"
                                                        >
                                                            <td class="text-center">
                                                                <span>{{ index + 1 }}</span>
                                                            </td>
                                                            <td>
                                                                <v-textarea
                                                                    v-model="item.particulars"
                                                                    :readonly="isDisabled()"
                                                                    class="small-font my-1"
                                                                    rows="1"
                                                                    outlined
                                                                    dense
                                                                    auto-grow
                                                                ></v-textarea>
                                                                    <!-- :rules="issue_pdc ? required : []" -->
                                                            </td>
                                                            <td>
                                                                <v-menu
                                                                    :close-on-content-click="true"
                                                                    :disabled="isDisabled()"
                                                                    transition="scale-transition"
                                                                    max-width="290"
                                                                >
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-text-field
                                                                            v-model="item.bill_date"
                                                                            v-on="on"
                                                                            :rules="required"
                                                                            class="small-font"
                                                                            append-icon="mdi-calendar"
                                                                            readonly
                                                                            outlined
                                                                            dense
                                                                        ></v-text-field>
                                                                    </template>
                                                                        <v-date-picker v-model="item.bill_date" :min="minDate"></v-date-picker>
                                                                </v-menu>
                                                            </td>
                                                            <td>
                                                                <v-menu
                                                                    :close-on-content-click="true"
                                                                    :disabled="isDisabled()"
                                                                    transition="scale-transition"
                                                                    max-width="290"
                                                                >
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-text-field
                                                                            v-model="item.due_date"
                                                                            v-on="on"
                                                                            :rules="required"
                                                                            class="small-font"
                                                                            append-icon="mdi-calendar"
                                                                            readonly
                                                                            outlined
                                                                            dense
                                                                        />
                                                                    </template>
                                                                        <v-date-picker v-model="item.due_date" :min="minDate"></v-date-picker>
                                                                </v-menu>
                                                            </td>
                                                            <td>
                                                                <v-text-field
                                                                    v-model="item.amount"
                                                                    :readonly="isDisabled()"
                                                                    :rules="required"
                                                                    class="right-align-text small-font"
                                                                    placeholder="0.00"
                                                                    type="number"
                                                                    hide-spin-buttons
                                                                    outlined
                                                                    dense
                                                                    @keypress="decimalOnly"
                                                                >
                                                                    <template v-slot:prepend-inner>
                                                                        <v-icon small>mdi-currency-php</v-icon>
                                                                    </template>
                                                                </v-text-field>
                                                            </td>
                                                            <td class="text-center" v-if="RECURRING_BILL.ACTION == 'create'">
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-btn v-on="on" fab dark x-small color="error" style="height: 20px; width: 20px;" @click="removeItem(index)">
                                                                            <v-icon small>
                                                                                mdi-minus
                                                                            </v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                        <span>Remove</span>
                                                                </v-tooltip>
                                                            </td>
                                                            <td class="text-center" v-else>
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-icon v-on="on" :color="item.cleared == 1 ? 'success' : 'error'">
                                                                            mdi-circle
                                                                        </v-icon>
                                                                    </template>
                                                                        {{ item.cleared == 1 ? 'Cleared' : 'Uncleared' }}
                                                                </v-tooltip>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                                <v-divider v-if="RECURRING_BILL.ACTION == 'edit'"/>
                        </v-container>
                        <v-row class="my-2">
                            <v-col class="mt-5">
                                <span style="font-size: 12px;">Required fields are marked with <span style="color: red;">*</span></span>
                            </v-col>
                            <v-col class="text-center">
                                <v-btn :loading="btnLoad"
                                    :disabled="!validToProceed"
                                    v-if="RECURRING_BILL.ACTION != 'view'"
                                    @click="commitRecord(RECURRING_BILL.ACTION, RECURRING_BILL.ACTION == 'create' ? 'addRecord' : 'updateRecord')"
                                >
                                    {{ RECURRING_BILL.ACTION == 'create' ? 'Create' : 'Update' }}
                                </v-btn>
                                <v-btn :loading="btnLoad"
                                    :disabled="!validToProceed"
                                    v-if="RECURRING_BILL.ACTION == 'view' && form.status == 1"
                                    @click="updateRecordStatus()"
                                >
                                    APPROVE
                                </v-btn>
                            </v-col>
                            <v-spacer/>
                        </v-row>
                    </v-card-title>
                    <div v-else class="text-center pa-2">
                        <v-progress-circular
                            :size="80"
                            color="primary"
                            indeterminate
                        ></v-progress-circular>
                    </div>
                </v-card>
            </v-dialog>
            <!-- Batch Addition -->
            <v-dialog v-model="batchAdditionDialog" persistent max-width="60%">
                <v-card>
                    <v-row class="m-0">
                        <v-col>
                            <v-card-title>
                                <span class="headline">Batch Addition</span>
                            </v-card-title>
                        </v-col>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn
                                text
                                icon
                                color="gray"
                                class="float-right"
                                @click="closeBatchAddition()"
                            >
                            <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row class="m-0">
                        <v-col>
                            <span class="ml-5">Please Paste Data.
                                <v-tooltip right>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            color="#F69F1A"
                                            dark
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            mdi-file-question
                                        </v-icon>
                                    </template>
                                    <span><img src="/images/rb-ba.png"/></span>
                                </v-tooltip>
                            </span>
                        </v-col>
                    </v-row>
                    <v-card-text class="ma-0">
                        <v-card class="pa-1 ma-1" :height="text_area_fields.length < 13 ? '' : '600px' " style="overflow-y:auto; overflow-x: hidden;" elevation="0">
                            <v-row>
                                <v-col class="text-right pb-5 pr-4 pt-1 mt-3">
                                    <v-btn
                                        dark
                                        x-small
                                        fab
                                        color="success"
                                        @click="manualAddition()"
                                    >
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row class="mx-0 pa-0 ma-0" v-for="(text_area_field,i) in text_area_fields" :key="i">
                                <v-text-field
                                    v-model="text_area_fields[i].particulars"
                                    dense
                                    outlined
                                    label="Particulars"
                                    class="pr-1"
                                    rows="1"
                                    @paste="onPaste"
                                    :autofocus="batchAdditionDialog"
                                    @click="$event.target.focus()"
                                />
                                <v-text-field
                                    v-model="text_area_fields[i].bill_date"
                                    dense
                                    outlined
                                    label="Billing Date"
                                    class="pr-1"
                                    rows="1"
                                    @paste="onPaste"
                                    :autofocus="batchAdditionDialog"
                                    @click="$event.target.focus()"
                                />
                                <v-text-field
                                    v-model="text_area_fields[i].due_date"
                                    dense
                                    outlined
                                    label="Due Date"
                                    class="pr-1"
                                    rows="1"
                                    @paste="onPaste"
                                    :autofocus="batchAdditionDialog"
                                    @click="$event.target.focus()"
                                />
                                <v-text-field
                                    v-model="text_area_fields[i].amount"
                                    dense
                                    outlined
                                    label="Amount"
                                    class="pr-1 right-align-text"
                                    rows="1"
                                    @keypress="decimalOnly"
                                    @paste="onPaste"
                                    :autofocus="batchAdditionDialog"
                                    :prefix="'₱'"
                                    @click="$event.target.focus()"
                                />
                                <v-btn
                                    dark
                                    x-small
                                    fab
                                    color="error"
                                    @click="clearItem(i)"
                                >
                                    <v-icon dark small>mdi-minus</v-icon>
                                </v-btn>
                            </v-row>
                        </v-card>
                        <v-row>
                            <div class="col-12">
                                <v-btn class="float-right ml-2" :disabled="batchSubmit" @click="submitBatchAddition()">
                                    Submit
                                </v-btn>
                                <v-btn
                                    class="float-right"
                                    dark
                                    color="secondary"
                                    @click="clearBatchAdditionFields()"
                                >
                                    Clear
                                </v-btn>
                            </div>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-card>
    </v-form>
</template>
<script>
import { mapGetters } from 'vuex'
import swal from 'sweetalert2'
import BaseFileViewerComponent from "@/views/main/Shared/BaseFilesViewerComponent.vue"
import FileUploadComponent from '@/views/main/Shared/FileUploadComponent.vue'
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue'
export default {
    mixins:[SharedFunctionsComponent],
    props: ['userAccess'],
    components: {
        BaseFileViewerComponent,
        FileUploadComponent,
    },
    data(){
        return{
            valid: false,
            tableLoad: false,
            recordsHeader:[
                { text: 'Transaction #', value: 'transaction_number', sortable: false },
                { text: 'Vendor', value: 'vendor_name', sortable: false },
                { text: 'Company', value: 'company_name', sortable: false },
                { text: 'Branch', value: 'store_branch_name', sortable: false },
                { text: 'Account No.', value: 'account_num', sortable: false, divider: true },
                { text: 'Billing Date', value: 'bill_date', sortable: false },
                { text: 'Due Date', value: 'due_date', sortable: false },
                { text: 'Reference No.', value: 'reference_num', sortable: false },
                { text: 'Amount', value: 'amount', align: 'right', sortable: false },
                { text: 'Prepared By', value:'prepared_by_name', align:'left', sortable: false },
                { text: 'Updated By', value:'updated_by_name', align:'left', sortable: false },
                { text: 'Status', value:'status', align:'center', sortable: false },
                { text: "Cancel Remarks", value: "cancel_reason", sortable: false },
                { text: 'Actions', value: 'action', align: 'center' , sortable: false }
            ],
            records:[],
            options: {},
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            itemsPerPage: 10,
            totalRecords: 0,
            form:{
                id: null,
                company_id: null,
                store_branches_id: null,
                employee_id: null,
                vendor_id: null,
                account_num: '',
                amount: null,
                bill_date: '',
                due_date: '',
                reference_num: '',
                remarks: '',
                address: '',
                recurring_bill_type_id: null,
                status: 1,
                paid: 0,
                contract: 0,
                recurring_bill_details: []
            },
            recordDialog: false,
            btnLoad: false,
            dialogLoader: false,
            search: '',
            filters:{
                prepared_by: null,
                company_id: null,
                store_branches_id: null,
                vendor_id: null,
                date_from: this.$dayjs().startOf('month').format('YYYY-MM-DD'),
                date_to:  this.$dayjs().endOf('month').format('YYYY-MM-DD'),
                recurring_bill_type_id: null,
                company_classification_id: null
            },
            issue_pdc: true,
            clonedStores: [],
            payment_data: {
                count: null,
                start_month: null,
                bill_date: null,
                due_date: null,
                amount: null
            },
            dates: [],
            files: null,
            months: [
                { text: 'January', value: 1 },
                { text: 'February', value: 2 },
                { text: 'March', value: 3 },
                { text: 'April', value: 4 },
                { text: 'May', value: 5 },
                { text: 'June', value: 6 },
                { text: 'July', value: 7 },
                { text: 'August', value: 8 },
                { text: 'September', value: 9 },
                { text: 'October', value: 10 },
                { text: 'November', value: 11 },
                { text: 'December', value: 12 }
            ],
            // chip_status: null,
            payment_detail_table: false,
            payment_details: [],
            required: [ v => !!v || 'This field is required' ],
            batchAdditionDialog: false,
            text_area_fields: [],
            text_area_field: {
                particulars: "",
                bill_date: "",
                due_date: "",
                amount: ""
            },
            isDone: true,
            menu: {
                date_from: false,
                date_to: false,
            },
            exportLoad: false,
            disableExport: false,
            fetchStatus: false
        }
    },
    computed: {
        ...mapGetters([
            'USER_ACCESS',
            'RECURRING_BILL',
            'GET_PV_COMPANIES_SELECTION',
            'GET_PV_STORE_BRANCHES_SELECTION',
            'GET_EMPLOYEE_DATA',
            'GET_ADMIN_SUPPLIER_SELECTION',
            'SELECTED_TAB',
            'GET_RB_BOOKLETS',
            'STATUS',
            'GET_RB_TYPES',
            'GET_VENDOR_PAYMENT_ITEM',
            'GET_PREPARED_BY',
            'GET_COMPANY_CLASSIFICATION_SELECTION'
        ]),
        getDataTableHeaders() {
            let headers = []
            headers = [
                { text: 'Transaction #', value: 'transaction_number', sortable: false },
                { text: 'Vendor', value: 'vendor_name', sortable: false },
                { text: 'Company', value: 'company_name', sortable: false },
                { text: 'Branch', value: 'store_branch_name', sortable: false },
                { text: 'Account No.', value: 'account_num', sortable: false },
                { text: 'Billing Date', value: 'bill_date', sortable: false },
                { text: 'Due Date', value: 'due_date', sortable: false },
                { text: 'Reference No.', value: 'reference_num', sortable: false },
                { text: 'Amount', value: 'amount', align: 'right', sortable: false },
                { text: 'Prepared By', value:'prepared_by_name', align:'left', sortable: false },
                { text: 'Updated By', value:'updated_by_name', align:'left', sortable: false },
                { text: 'Status', value:'status', align:'right', sortable: false },
                { text: "Cancel Remarks", value: "cancel_reason", sortable: false },
                { text: 'Actions', value: 'action', align: 'center' , sortable: false }
            ];

            if (this.SELECTED_TAB == 5) {
                headers = [
                    { text: 'Company', value: 'company_name', sortable: false },
                    { text: 'Branch', value: 'store_branch_name', sortable: false },
                    { text: 'Vendor', value: 'vendor_name', sortable: false },
                    { text: 'Account #', value: 'account_num', sortable: false, divider: true },
                    { text: 'JAN', value: 0, sortable: false },
                    { text: 'FEB', value: 1, sortable: false },
                    { text: 'MAR', value: 2, sortable: false },
                    { text: 'APR', value: 3, sortable: false },
                    { text: 'MAY', value: 4, sortable: false },
                    { text: 'JUN', value: 5, sortable: false },
                    { text: 'JUL', value: 6, sortable: false },
                    { text: 'AUG', value: 7, sortable: false },
                    { text: 'SEP', value: 8, sortable: false },
                    { text: 'OCT', value: 9, sortable: false },
                    { text: 'NOV', value: 10, sortable: false },
                    { text: 'DEC', value: 11, sortable: false },
                ]
            }
            return headers;
        },
        minDate() {
            return this.$dayjs().format('YYYY-MM-DD');
        },
        requiredFirst(){
            let required = [
                'vendor_id',
                'company_id',
                'store_branches_id',
                'recurring_bill_type_id',
                'account_num'
            ]
            return required.some(e=>!this.form[e])
        },
        checkPaymentData(){
            let required = ['count', 'start_month', 'bill_date', 'due_date']
            return required.some(e=>!this.payment_data[e])
        },
        branchItems(){
            let items = this.clonedStores.filter(e=>e.company_id == this.form.company_id)
            if (!this.form.company_id) return this.clonedStores
            if (!this.form.store_branches_id) this.form.store_branches_id = items[0].value
            return items
        },
        batchSubmit() {
            return this.text_area_fields.some(field => {
                return !field.particulars || !field.bill_date || !field.due_date || !field.amount;
            });
        },
        checkParticulars(){
            return this.form.recurring_bill_details.some(e=>{
                return !e.bill_date || !e.due_date
            })
        },
        recordNumber(){
            return this.form.transaction_number ? '# : ' + this.form.transaction_number : ''
        },
        validToProceed(){
            return this.valid
        },
        classificationText() {
            let classificationId = this.branchItems.find(e => e.value == this.form.store_branches_id)?.company_classification_id
                ?? this.GET_PV_COMPANIES_SELECTION.find(e => e.value == this.form.company_id)?.company_classification_id;
            return classificationId
                ? this.GET_COMPANY_CLASSIFICATION_SELECTION.find(el => el.value == classificationId)?.text ?? ''
                : '';
        },
        branchItemsForFilter(){
            let items = this.clonedStores.filter(e=>e.company_id == this.filters.company_id)
            if (!this.filters.company_id) return this.clonedStores
            if (!this.filters.store_branches_id) this.filters.store_branches_id = items[0].value
            return items
        },
    },
    mounted(){
        this.getAllRecords()
        this.getBranches()
        this.insertDates(1)
        document.addEventListener('keydown', this.handleKeyDown)
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.handleKeydown);
    },
    methods:{
        getAllRecords(for_export = 0){
            if (this.fetchStatus) return
            this.fetchStatus = true
            if (!for_export) {
                this.tableLoad = true
                this.records = []
                this.disableExport = true
            } else {
                this.exportLoad = true
            }
            let payload = {
                prepared_by: this.filters.prepared_by,
                company_id: this.filters.company_id,
                store_branches_id: this.filters.store_branches_id,
                vendor_id: this.filters.vendor_id,
                search: this.search,
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                url: 'getAll',
                records: 1,
                tab: this.RECURRING_BILL.TAB,
                date_range:[this.filters.date_from,this.filters.date_to],
                type: this.filters.recurring_bill_type_id,
                for_export: for_export,
                company_classification_id: this.filters.company_classification_id
            }
            this.$store.dispatch('recurringBillGet', payload).then(response=>{
                if (for_export == 1) {
                    this.exportRB(response.data)
                } else {
                    this.records = response.data.data.data
                    if (this.records.length > 0 ) this.disableExport = false
                    this.totalRecords = response.data.data.total
                    this.tableLoad = false
                }
                this.fetchStatus = false
            })
        },
        toggleDialog(action = 'close'){
            switch(action){
                case 'open':
                    this.recordDialog = true
                    this.$store.dispatch('getRecurringBillTypes')
                    break;
                case 'close':
                    document.removeEventListener('keydown', this.handleKeydown);
                    this.recordDialog = false
                    this.getAllRecords()
                    this.clearFields()
                    break;
            }
        },
        mode(action = 'create', id = null){
            this.$store.commit('RECURRING_BILL_ACTION', action)
            if (!id) {
                this.toggleDialog('open')
            } else {
                this.dialogLoader = true
                this.toggleDialog('open')
                if (this.RECURRING_BILL.ACTION != 'create') this.payment_detail_table = true
                this.$store.dispatch('recurringBillPost', { id: id, url: 'getRecord' }).then(response=>{
                    this.form = { ...response.data }
                    this.form.amount = parseFloat(this.form.amount).toFixed(2)
                    if (this.form.recurring_bill_details.length > 0) {
                        this.form.recurring_bill_details.forEach(e=>{
                            e.amount = parseFloat(e.amount).toFixed(2)
                        })
                        this.issue_pdc = true
                    }
                    this.dialogLoader = false
                })
            }
        },
        isDisabled(){
            switch(this.RECURRING_BILL.ACTION){
                case 'view':
                    return true
            }
        },
        clearFields(){
            this.$refs.form.resetValidation()
            this.$store.commit('RECURRING_BILL_ACTION', '')
            this.form = {
                id: null,
                company_id: null,
                store_branches_id: null,
                employee_id: null,
                vendor_id: null,
                account_num: '',
                remarks: '',
                address: '',
                amount: null,
                frequency_type_id: null,
                bill_date: '',
                due_date: '',
                reference_num: '',
                recurring_bill_type_id: null,
                status: 1,
                paid: 0,
                contract: 0,
                recurring_bill_details: [],
            }
            this.payment_data = {
                count: null,
                bill_date: null,
                due_date: null,
                amount: null
            }
            this.issue_pdc = true,
            this.files = null
            this.payment_detail_table = false
            this.$forceUpdate()
        },
        commitRecord(action, url){
            // if (!this.$refs.form.validate()) {
            //     if (!this.checkRequiredFields()) return
            //     if (this.form.recurring_bill_details.length > 0) {
            //         if (!this.checkPaymentDetails()) return
            //     }
            // } else {
            // }
            if (!this.payment_detail_table) {
                swal.fire("", '- No Payment Entries Found!', 'error')
                return
            }
            this.btnLoad = true
            swal.fire({
                title: "",
                text: `- Are you sure you want to ${action} record?`,
                icon: "question",
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
            }).then((result) => {
                if (result.isConfirmed) {
                    let payload = {
                        form : this.form,
                        details: this.form.recurring_bill_details,
                        file: this.files,
                        url : url
                    }
                    this.$store.dispatch('recurringBillPost', payload).then(() => {
                        swal.fire('', '- Record Saved!', 'success')
                        this.toggleDialog('close')
                        this.btnLoad = false
                    }).catch( error => {
                        if (error.response.status == 422) {
                            swal.fire('', '- Error!', 'error')
                            console.log(error)
                            this.btnLoad = false
                        }
                    })
                } else {
                    this.btnLoad = false
                }
            })
        },
        destroy(id){
            swal.fire({
                title: `- Are you sure you want to <strong><u style="color: #f44336;">DELETE</u></strong> ?`,
                text: "",
                icon: "question",
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                footer: 'This action is irreversible!',
            }).then((result) => {
                if (result.isConfirmed) {
                    let payload = {
                        id: id,
                        url: 'deleteRecord'
                    }
                    this.$store.dispatch('recurringBillPost', payload).then(response => {
                        swal.fire("", "- Record Deleted!", "success")
                        this.getAllRecords()
                    }).catch(error => {
                        if (error.response.status == 422) {
                            swal.fire("", "- Record not Deleted!", "error")
                            console.log(error)
                        }
                    })
                }
            })
        },
        checkRequiredFields(){
            let emptyFields = []
            let required = {
                'company_id': 'Company',
                'store_branches_id': 'Store Branch',
                'vendor_id': 'Vendor',
                'recurring_bill_type_id': 'Type',
                'bill_date': 'Billing Date',
                'due_date': 'Due Date',
                'amount': 'Amount'
            }
            // 'account_num': 'Account Number',
            // 'reference_num': 'Reference Number',
            // 'remarks': 'Remarks',

            if (this.issue_pdc && this.RECURRING_BILL.ACTION == 'create') {
                delete required['bill_date']
                delete required['due_date']
                delete required['amount']

                let requiredPaymentData = {
                    'count': 'Number of Payments',
                    'start_month': 'Start Month',
                    'bill_date': 'Billing Date',
                    'due_date': 'Due Date',
                    // 'amount': 'Amount per Month'
                }
                Object.keys(requiredPaymentData).forEach(e=>{
                    if (!this.payment_data[e]) {
                        emptyFields.push(requiredPaymentData[e])
                    }
                })
            }

            Object.keys(required).forEach(e=>{
                if (!this.form[e]) {
                    emptyFields.push(required[e])
                }
            })

            if (emptyFields.length > 0) {
                swal.fire({
                    title: 'Please fill the following fields: ',
                    html: emptyFields.map(field => `${field}<br>`).join(''),
                    icon: 'warning'
                })
                return false
            }
            return true
        },
        checkPaymentDetails(){
            let emptyFields = []
            if (this.form.recurring_bill_details.length > 0) {
                let requiredPDC = {
                    'bill_date': '',
                    'due_date': '',
                    'amount': ''
                }
                // 'particulars': '',
                Object.keys(requiredPDC).forEach(e=>{
                    this.form.recurring_bill_details.forEach(x=>{
                        if (!x[e]) {
                            emptyFields.push(requiredPDC[e])
                        }
                    })
                })
                if (emptyFields.length > 0) {
                    swal.fire("Incomplete Data!", '- You cannot proceed with incomplete payment details.', 'error')
                } else {
                    return true
                }
            }
        },
        async getBranches(){
            try{
                this.clonedStores = await this.getStoreBranches()
            } catch (error) {
                console.error(error)
            }
        },
        getBookletsSelection(bank_id){
            this.$store.dispatch('getBookletsSelection', bank_id).then((response)=>{
                this.form.recurring_bill_details[0].booklet_id = response.length > 0 ? response[0].id : ''
                if (!this.form.recurring_bill_details[0].booklet_id) {
                    this.form.recurring_bill_details[0].cheque_num = ''
                    swal.fire('', 'No Booklet Found', 'warning');
                    return false;
                }
                this.getNextChequeNumber()
            })
        },
        getNextChequeNumber(){
            this.$store.dispatch('getNextChequeNumber', { booklet_id: this.form.recurring_bill_details[0].booklet_id }).then(response => {
                this.form.recurring_bill_details[0].cheque_num = response.data
            })
        },
        uploadedData(data){
            if (!data) {
                this.files = null
            }
            this.files = data
        },
        clearFileUpload(confirm){
            if (confirm) {
                this.files = null
            }
            this.files = null
            this.$refs.fileUpload.reset()
        },
        countingNumberOnly ($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            let inputValue = $event.target.value;
            if ((inputValue === '' && keyCode === 48) || keyCode === 45 || keyCode === 190 || keyCode === 110 || keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
        },
        insertDates(source){
            switch (source) {
                case 1:
                    for (let i = 1; i <= 31; i++) {
                        this.dates.push({ text: this.suffix(i).toString(), value: i.toString() })
                    }
                        break;
                case 2:
                    for (let i = 1; i <= 29; i++) {
                        this.dates.push({ text: this.suffix(i).toString(), value: i.toString() })
                    }
                        break;
                case 3:
                    for (let i = 1; i <= 30; i++) {
                        this.dates.push({ text: this.suffix(i).toString(), value: i.toString() })
                    }
                        break;
            }
        },
        datesByMonth(month){
            switch (month) {
                case 1:
                case 3:
                case 5:
                case 7:
                case 8:
                case 10:
                case 12:
                    this.dates = []
                    this.insertDates(1)
                    break;
                case 2:
                    this.dates = []
                    this.insertDates(2)
                    break;
                case 4:
                case 6:
                case 9:
                case 11:
                    this.dates = []
                    this.insertDates(3)
                    break;
            }
        },
        suffix(num){
            const suffixes = ["st", "nd", "rd"];
            const suffix = suffixes[(num - 1) % 10] || "th"
            return num + suffix;
        },
        async processFiles(files){
            this.files.attachments = []
            files.forEach(e=>{
                this.files.attachments.push(
                    e.localFileState
                )
            })
            let fileData = []
            for(let i = 0; i < this.files.attachments.length; i++) {
                const element = this.files.attachments[i]
                let reader = new FileReader()
                reader.readAsDataURL(element)
                await new Promise((resolve) => {
                    reader.onloadend = function () {
                        fileData.push({
                            name: element.name,
                            type: element.type,
                            data: reader.result.split(',')[1]
                        })
                        resolve()
                    }
                })
            }
            this.files = fileData
        },
        insertStore(source){
            switch (source) {
                case 1:
                    this.filters.store_branches_id = this.branchItemsForFilter[0].value
                    break;
                case 2:
                    this.form.store_branches_id = this.branchItems[0].value
                    break;
            }
        },
        insertCompany(storeId, source){
            if (!storeId) return
            let store = this.GET_PV_STORE_BRANCHES_SELECTION.find(e=>e.value == storeId)
            switch (source) {
                case 1:
                    this.filters.company_id = this.GET_PV_COMPANIES_SELECTION.find(e=>e.value == store.company_id).value
                    break;
                case 2:
                    this.form.company_id = this.GET_PV_COMPANIES_SELECTION.find(e=>e.value == store.company_id).value
                    break;
            }
        },
        // chipStatus(status){
        //     this.chip_status = status
        //     this.options.page = 1
        //     this.getAllRecords()
        // },
        async generatePayments(){
            this.payment_detail_table = true
            this.form.recurring_bill_details = [] // for resetting payment entries

            let startMonth = this.$dayjs(this.payment_data.start_month, 'YYYY-MM')

            for (let i = 0; i < this.payment_data.count; i++) {
                let billingDate = this.$dayjs(`${startMonth.format('YYYY-MM')}-${this.payment_data.bill_date.toString()}`);
                let dueDate = billingDate.add(parseInt(this.payment_data.due_date), 'day');
                this.form.recurring_bill_details.push({
                    particulars: '',
                    bill_date: billingDate.format('YYYY-MM-DD'),
                    due_date: dueDate.format('YYYY-MM-DD'),
                    amount: this.payment_data.amount
                })
                startMonth = startMonth.add(1,'month')
            }
        },
        updateRecordStatus(){
            this.btnLoad = true
            swal.fire({
                title: "",
                text: `- Are you sure you want to approve this record for payment?`,
                icon: "question",
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('recurringBillPost', { id: this.form.id, url: 'updateRecordStatus' }).then(() => {
                        swal.fire('', '- Record Approved!', 'success')
                        this.toggleDialog('close')
                        this.btnLoad = false
                    }).catch( error => {
                        this.btnLoad = false;
                        if (error.response.status === 406)
                            return swal.fire({
                                title: 'Error',
                                text: 'You are not authorized to approve this recurring bill.',
                                icon: 'error',
                            });
                        if (error.response.status == 422) {
                            swal.fire('', '- Error!', 'error')
                            console.log(error)
                            this.btnLoad = false
                        }
                    })
                } else {
                    this.btnLoad = false
                }
            })
        },
        handleKeyDown(event){
            if (this.recordDialog && !this.dialogLoader && !this.batchAdditionDialog) this.closeDialogByEsc(event, this.toggleDialog)
        },
        batchAddition() {
            this.isDone = true;
            this.batchAdditionDialog = true;
            this.text_area_fields = [];

            if (this.text_area_fields.length < 1) {
                this.text_area_fields.push({
                    particulars: "",
                    bill_date: "",
                    due_date: "",
                });
            }
        },
        closeBatchAddition() {
            this.text_area_fields = []
            this.batchAdditionDialog = false;
        },
        manualAddition() {
            this.text_area_fields.push({
                particulars: "",
                bill_date: "",
                due_date: "",
                amount: ""
            });
        },
        clearItem(i) {
            this.text_area_fields = this.text_area_fields.filter((e, index) => {
                if (i != index) {
                    return e
                }
            });
            if (this.text_area_fields.length == 0) this.manualAddition()
        },
        submitBatchAddition() {
            swal.fire({
                title: '',
                text: '- Do you wish to proceed?',
                icon: 'question',
                reverseButtons: true,
                showCancelButton: true
            }).then(result=>{
                if(result.isConfirmed){
                    this.text_area_fields.forEach(e=>{
                        let newItem = {
                            amount: parseFloat(e.amount).toFixed(2),
                            bill_date: this.$dayjs(e.bill_date).format('YYYY-MM-DD'),
                            due_date: this.$dayjs(e.due_date).format('YYYY-MM-DD'),
                            particulars: e.particulars,
                        }
                        this.form.recurring_bill_details.push(newItem)
                    })
                    this.payment_detail_table = true
                    this.closeBatchAddition()
                } else {
                    return
                }
            })
        },
        onPaste(evt){
            let pastedText = evt.clipboardData.getData("Text");

            if (pastedText.search(/[\r\n][\n]/g)) {
                this.result = [] // para sa tuloy tuloy na pag paste ng hindi nabubura ang laman -> for future purposes
                let rows = pastedText.split("\r\n");
                for (let y = 0; y < rows.length; y++) {
                    let cells = rows[y].split("\t");
                    if (cells[0] !== '') {
                        this.result.push({
                            particulars: cells[0],
                            bill_date: cells[1],
                            due_date: cells[2],
                            amount: cells[3],
                        });
                    }
                }
            } else {
                swal.fire('', 'Pasted Data format is not supported!', 'warning')
                return
            }

            this.isDone = false;
            this.closeBatchAddition();
            setTimeout(this.myFunction, 1);
        },
        myFunction() {
            this.text_area_fields = this.result
            this.batchAdditionDialog = true;
        },
        clearBatchAdditionFields() {
            this.isDone = true;
            this.text_area_fields = [];

            if (this.text_area_fields.length < 1) {
                this.text_area_fields.push({
                    particulars: "",
                    bill_date: "",
                    due_date: "",
                    amount: ""
                });
            }
        },
        removeItem(index){
            this.form.recurring_bill_details.splice(index, 1)
            if (this.form.recurring_bill_details.length == 0) {
                this.form.recurring_bill_details.push({
                    particulars: '',
                    bill_date: this.$dayjs().format('YYYY-MM-DD'),
                    due_date: this.$dayjs().format('YYYY-MM-DD'),
                    amount: 0.00
                })
            }
            this.payment_data.count = this.form.recurring_bill_details.length
        },
        clearPayments(){
            this.form.recurring_bill_details = [{
                particulars: "",
                bill_date: "",
                due_date: "",
                amount: ""
            }]
            this.payment_data.count = this.form.recurring_bill_details.length
        },
        setParticularsByDates(){
            this.form.recurring_bill_details.forEach(e=>{
                e.particulars = `${this.$dayjs(e.bill_date).format('MMM D YYYY')} - ${this.$dayjs(e.due_date).format('MMM D YYYY')}`
            })
        },
        async exportRB(exportData){
            let detailsTable = []

            exportData.forEach(e=>{
                e.type_name = this.GET_RB_TYPES.find(t => t.value == e.recurring_bill_type_id)?.text
                e.status = this.STATUS.RECURRING_BILL_STATUS.find(el=>el.value == e.status)?.text
                e.amount = parseFloat(e.amount)
                e.recurring_bill_details.forEach(v=>{
                    v.transaction_number = e.transaction_number
                    v.type_name = e.type_name
                    v.company_name = e.company_name
                    v.store_branch_name = e.store_branch_name
                    v.vendor_name = e.vendor_name
                    v.account_num = e.account_num
                    v.amount = parseFloat(v.amount)
                    v.cleared = v.cleared == 1 ? 'Yes' : 'No'
                    detailsTable.push(v)
                })
            })
            let rbHeaderAndData = {
                ['RECURRING BILL']: {
                    headers:[
                        { header: 'RB #', key:'transaction_number', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Type', key:'type_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Company', key:'company_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Store Branch', key:'store_branch_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Vendor', key:'vendor_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Account Number', key:'account_num', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Employee', key:'employee_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Reference Number', key:'reference_num', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Address', key:'address', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Remarks', key:'remarks', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Prepared By', key:'prepared_by_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Status', key:'status_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Total Amount', key:'amount', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                    ],
                    data: []
                },
                ['RECURRING BILL (DETAILED)']: {
                    headers:[
                        { header: 'RB #', key:'transaction_number', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Type', key:'type_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Company', key:'company_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Store Branch', key:'store_branch_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Vendor', key:'vendor_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Account Number', key:'account_num', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Particulars', key:'particulars', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Bill Date', key:'bill_date', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Due Date', key:'due_date', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Amount', key:'amount', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Cleared', key:'cleared', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                    ],
                    data: []
                },
            }
            rbHeaderAndData['RECURRING BILL'].data = exportData
            rbHeaderAndData['RECURRING BILL (DETAILED)'].data = detailsTable
            await this.exportExcel(rbHeaderAndData, 'RECURRING BILL', 'F69F1A')
            this.exportLoad = false
        }
    },
    watch:{
        options:{
            handler(){
                this.getAllRecords()
            },
            deep: true
        },
        SELECTED_TAB:{
            handler(val){
                this.options.page = 1
                this.getAllRecords()
            }
        },
        GET_RB_TYPES:{
            handler(val){
                if (!val.length) swal.fire('No Types Found!', 'Create in the TYPES tab', 'warning')
            }
        },
        search:{
            handler(val){
                if (val) {
                    // this.chipStatus(null)
                    this.options.page = 1
                }
            },
            immediate: true
        },
        'issue_pdc':{
            handler(val){
                if (val == true) {
                    this.form.bill_date = null
                    this.form.due_date = null
                    this.form.amount = null
                } else {
                    this.payment_data = {
                        count: null,
                        start_month: null,
                        bill_date: null,
                        due_date: null,
                        amount: null
                    }
                    this.payment_detail_table = false
                }
            },
            immediate: true
        },
        'payment_data.start_month': {
            handler(val){
                if (val) {
                    let [, month] = val.split('-')
                    let monthValue = parseInt(month)
                    this.datesByMonth(monthValue)
                }
            },
            immediate: true
        },
        'form.company_id':{
            handler(val){
                if (!val) {
                    this.form.store_branches_id = null
                }
            },
            immediate: true
        },
        'form.store_branches_id':{
            handler(val){
                if (!val) {
                    this.form.company_id = null
                }
            },
            immediate: true
        },
        'form.id': {
            handler(val) {
                if (val) {
                // this.clearFileUpload(true);
                    this.files = {
                        attachments: [],
                    }
                }
            },
            deep: true
        },
        'form.vendor_id':{
            handler(val){
                if (val) {
                    this.$store.dispatch('getVendorPaymentItems', val)
                }
            },
            immediate: true
        },
        'filters.company_id':{
            handler(val){
                if (!val) {
                    this.filters.store_branches_id = null
                }
            },
            immediate: true
        },
        'filters.store_branches_id':{
            handler(val){
                if (!val) {
                    this.filters.company_id = null
                }
            },
            immediate: true
        },
    }
}
</script>

<style scoped>
::v-deep .right-align-text input {
    text-align: right;
}
::v-deep .center-align-text input {
    text-align: center;
}
.small-font {
    font-size: 13px;
}
</style>
