<template>
    <v-container>
        <ComponentHeader
            :button="true"
            :title="'Hakot'"
            :type="'Inventory'"
        ></ComponentHeader>
        <v-container class="bg-bluish-gray" fluid>
            <v-card>
                <v-card-text>
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="12" lg="10" xl="10">
                            <v-row class="my-2" style="gap: 6px" no-gutters>
                                <v-col cols="12" sm="12" md="12" lg="4" xl="4">
                                    <v-select
                                        v-model="filters.report_type"
                                        :items="dropdowns.report_types"
                                        label="Select Report Type"
                                        dense
                                        outlined
                                        hide-details
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="3" xl="3" class="d-flex justify-space-around">
                                    <v-checkbox
                                        v-model="filters.include_image"
                                        :true-value="1"
                                        :false-value="0"
                                        label="Include Image"
                                        dense
                                        hide-details
                                    ></v-checkbox>
                                    <v-checkbox
                                        v-if="[2].includes(filters.report_type)"
                                        v-model="filters.service"
                                        :true-value="1"
                                        :false-value="0"
                                        label="Service"
                                        dense
                                        hide-details
                                        @change="getWarehouses()"
                                    ></v-checkbox>
                                </v-col>
                                <v-spacer></v-spacer>
                            </v-row>
                            <v-row class="my-2" style="gap: 6px" no-gutters>
                                <v-col cols="12" sm="12" md="12" lg="5" xl="5">
                                    <v-autocomplete
                                        v-model="filters.item_types"
                                        :items="GET_ITEM_TYPES"
                                        label="Select Item Type"
                                        auto-select-first
                                        hide-details
                                        clearable
                                        dense
                                        outlined
                                        deletable-chips
                                        small-chips
                                        multiple
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="5" xl="5">
                                    <v-autocomplete
                                        v-model="filters.item_categories"
                                        :items="GET_ALL_ITEM_CATEGORIES"
                                        label="Select Item Category"
                                        item-value="id"
                                        item-text="name"
                                        auto-select-first
                                        hide-details
                                        clearable
                                        dense
                                        outlined
                                        deletable-chips
                                        small-chips
                                        multiple
                                    ></v-autocomplete>
                                </v-col>
                                <v-spacer></v-spacer>
                            </v-row>
                            <v-row class="my-2" style="gap: 6px" no-gutters v-if="[2].includes(filters.report_type)">
                                <v-col cols="12" sm="12" md="12" lg="2" xl="2">
                                    <v-select
                                        v-model="filters.warehouse_type"
                                        :items="dropdowns.warehouse_types"
                                        label="Warehouse Type"
                                        dense
                                        outlined
                                        hide-details
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="9" xl="9" v-if="filters.warehouse_type == -1">
                                    <v-autocomplete
                                        v-model="filters.warehouses"
                                        :items="GET_ALL_WAREHOUSES"
                                        label="Warehouses"
                                        auto-select-first
                                        hide-details
                                        clearable
                                        dense
                                        outlined
                                        deletable-chips
                                        small-chips
                                        multiple
                                    ></v-autocomplete>
                                </v-col>
                                <v-spacer></v-spacer>
                            </v-row>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="2" xl="2">
                            <v-row no-gutters>
                                <v-col cols="12" class="d-flex justify-content-end" style="gap: 7px">
                                    <!-- <v-btn :loading="!loaded" style="margin-right: 3px" small @click="resetFieldInput()">Clear</v-btn> -->
                                    <!-- <v-btn small @click="filter">Filter</v-btn> -->
                                    <v-btn :loading="filters.loading" small @click="exportToExcel">Export</v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-container>
    </v-container>
</template>

<script>
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
import { mapGetters } from 'vuex';
import SharedFunctionsComponent from "@/views/main/Shared/SharedFunctionsComponent.vue";
import swal from 'sweetalert2';

export default {
    mixins: [
        SharedFunctionsComponent
    ],
    components: {
        ComponentHeader,
    },
    data() {
        return {
            dropdowns: {
                report_types: [
                    { value: 1, text: 'Hakot (Replenishment)' },
                    { value: 2, text: 'Hakot (Rush)' },
                    // { value: 3, text: 'Hakot (Old)' },
                ],
                warehouse_types: [
                    { value: 'actual', text: 'Actual' },
                    { value: 'virtual', text: 'Virtual' },
                    { value: -1, text: 'Custom' },
                ],
            },
            filters: {
                report_type: 1,
                item_types: [],
                item_categories: [],
                warehouse_type: 'actual',
                warehouses: [],
                service: 0,
                include_image: 0,
            },
            loading: {
                export: false,
            },
        }
    },
    computed:{
        ...mapGetters([
            'GET_ITEM_TYPES',
            'GET_ALL_ITEM_CATEGORIES',
            'GET_ALL_WAREHOUSES',
        ]),
    },
    methods: {
        async exportToExcel() {
            let exportData = {}
            let fileName = ''
            swal.fire({
                title:                      'Please wait...',
                allowOutsideClick:          false,
            })
            swal.showLoading();
            this.loading.export = true

            if(this.filters.report_type == 1) {
                await this.$store.dispatch('inventoryGet', {
                    filters: this.filters,
                    url: 'operations-report/hakotReplenishment',
                }).then(async response => {
                    fileName = 'HAKOT REPLENISHMENT'
                    exportData = {
                        [fileName]: {
                            headers: [
                                { header: 'IMAGE', key: '', width: 22, style: { alignment: { vertical: 'middle', horizontal: 'center' } }, include: this.filters.include_image },
                                { header: 'MATERIAL CODE', key: 'model', width: 22, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                                { header: 'MATERIAL NAME', key: 'name', width: 30, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                                { header: 'UOM', key: 'uom', style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                                { header: 'TYPE', key: 'type', style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                                { header: 'CATEGORY', key: 'category', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                                { header: 'INVENTORY', key: 'default_warehouse_stocks', width: 19, style: { numFmt: '#,##0', alignment: { vertical: 'middle', horizontal: 'center' } }, formatAs: 'int' },
                                { header: 'FIRST MONTH OUT', key: 'total_sales_first', width: 19, style: { numFmt: '#,##0', alignment: { vertical: 'middle', horizontal: 'center' } }, formatAs: 'int' },
                                { header: 'SECOND MONTH OUT', key: 'total_sales_second', width: 19, style: { numFmt: '#,##0', alignment: { vertical: 'middle', horizontal: 'center' } }, formatAs: 'int' },
                                { header: 'TOTAL OUT', key: 'total_sales_two_months', width: 19, style: { numFmt: '#,##0', alignment: { vertical: 'middle', horizontal: 'center' } }, formatAs: 'int' },
                                { header: 'DAYS', key: 'num_days', style: { numFmt: '#,##0', alignment: { vertical: 'middle', horizontal: 'center' } }, formatAs: 'int' },
                                { header: 'DAILY CONSUMPTION', key: 'daily_consumption', width: 19, style: { numFmt: '#,##0.00', alignment: { vertical: 'middle', horizontal: 'center' } }, formatAs: 'float' },
                                { header: 'MB', key: 'master_box_qty', style: { numFmt: '#,##0', alignment: { vertical: 'middle', horizontal: 'center' } }, formatAs: 'int' },
                                { header: 'INVENTORY IN MB', key: 'default_warehouse_stocks_in_mb', width: 19, style: { numFmt: '#,##0', alignment: { vertical: 'middle', horizontal: 'center' } }, formatAs: 'int' },
                                { header: 'REMAINING DAYS', key: 'remaining_days', width: 19, style: { numFmt: '#,##0.00', alignment: { vertical: 'middle', horizontal: 'center' } }, formatAs: 'float' },
                                { header: 'REMARKS', key: 'remarks', width: 19, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                            ],
                            data: response.data,
                            hasImages: this.filters.include_image,
                            options: {
                                views: [
                                    { state: 'frozen', xSplit: 2, ySplit: 1 }
                                ],
                            },
                        },
                    }
                }).catch(error => {
                    console.log(error)
                })
            } else if (this.filters.report_type == 2) {
                await this.$store.dispatch('inventoryGet', {
                    filters: this.filters,
                    url: 'operations-report/hakotRush',
                }).then(async response => {
                    fileName = 'HAKOT RUSH'
                    exportData = {
                        [fileName]: {
                            headers: [
                                { header: 'IMAGE', key: '', width: 22, style: { alignment: { vertical: 'middle', horizontal: 'center' } }, include: this.filters.include_image },
                                { header: 'MATERIAL CODE', key: 'model', width: 22, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                                { header: 'MATERIAL NAME', key: 'name', width: 30, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                                { header: 'UOM', key: 'uom', style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                                { header: 'TYPE', key: 'type', style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                                { header: 'CATEGORY', key: 'category', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                                { header: 'INVENTORY', key: 'default_warehouse_stocks', width: 19, style: { numFmt: '#,##0', alignment: { vertical: 'middle', horizontal: 'center' } }, formatAs: 'int' },
                                { header: 'DATE TODAY', key: 'date_today', width: 19, style: { numFmt: 'm/d/yyyy', alignment: { vertical: 'middle', horizontal: 'center' } }, formatAs: 'date' },
                                { header: 'DELIVERY DATE', key: 'delivery_date', width: 19, style: { numFmt: 'm/d/yyyy', alignment: { vertical: 'middle', horizontal: 'center' } }, formatAs: 'date' },
                                { header: 'DAY DIFFERENCE', key: 'day_difference', width: 19, style: { numFmt: '#,##0', alignment: { vertical: 'middle', horizontal: 'center' } }, formatAs: 'int' },
                                { header: 'QTY ORDER', key: 'qty_order', width: 19, style: { numFmt: '#,##0', alignment: { vertical: 'middle', horizontal: 'center' } }, formatAs: 'int' },
                                { header: 'REMARKS', key: 'remarks', width: 19, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                            ],
                            data: response.data,
                            hasImages: this.filters.include_image,
                            options: {
                                views: [
                                    { state: 'frozen', xSplit: 2, ySplit: 1 }
                                ],
                            },
                        },
                    }
                }).catch(error => {
                    console.log(error)
                })
            }

            await this.exportExcel(exportData, fileName, '', true)
            swal.close();
            this.loading.export = false
        },

        setDefaultValues() {
            Object.assign(this.filters, {
                item_types: [1 ,2],
            })
        },

        getWarehouses() {
            this.$store.dispatch('getAllWarehouses', { service: this.filters.service })

            this.$forceUpdate()
        },
    },
    async mounted() {
        this.$store.dispatch('getItemTypes')
        this.$store.dispatch('getAllItemCategories')
        this.getWarehouses()

        this.setDefaultValues()
    },
}
</script>