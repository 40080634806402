<template>
    <div class="tabs-body-color">
        <v-container fluid>
            <v-row class="pt-2">
                <v-col cols="6" class="d-flex no-block align-items-center">
                    <div class="btn-toolbar">
                        <v-btn class="ml-1 mb-2" @click="AddDialog()" v-if="this.GET_HR_TAB_IS_ACTIVE != 'for_approval'">
                            <v-icon>mdi-plus</v-icon>Add
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-row>
              <HRPlantillaTableComponentVue v-if="isActive_tab == 'plantilla_list'"></HRPlantillaTableComponentVue>
            </v-row>
        </v-container>
    
    </div>
</template>
<script>
import HRPlantillaTableComponentVue from '@/views/main/modules/HR/Plantilla/HRPlantillaTableComponent.vue';

import { mapGetters } from 'vuex';
export default {
    components: {
        HRPlantillaTableComponentVue
    },
    props:["isActive_tab"],
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters(['GET_HR_TAB_IS_ACTIVE'])
    },
    mounted() {
    },
    methods: {
        AddDialog(){
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", "Submit");
        },
    },
    
};
</script>
