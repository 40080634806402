<template>
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field v-model="search"   
        @keydown.enter="searchData()"
        @click:append="searchData()" 
        label="Search" 
        single-line hide-details> </v-text-field>
        <v-icon>fas fa-search</v-icon>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :options.sync="options"
        :server-items-length="totalItems"
        :items-per-page="itemsPerPage"
        class="w-100"
        :loading="!loaded"
        loading-text="Loading... Please wait"
      >
        <template v-slot:[`item.active`]="{ item }">
          <span class="badge bg-primary text-white" v-if="item.active">Yes</span>
          <span class="badge bg-danger text-white" v-else>No</span>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-btn text icon color="orange" v-if="userAccess.view" >
              <v-icon class="btn-action" small @click="viewdialogs(item)">mdi-eye</v-icon>
          </v-btn>
          <v-btn text icon color="blue" v-if="userAccess.edit" >
              <v-icon class="btn-action" small @click="editdialogs(item)">mdi-pencil</v-icon>
          </v-btn>
          <v-btn text icon color="red" v-if="userAccess.delete" >
              <v-icon class="btn-action" small @click="deleteDepartment(item.id)">mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </template>
  <script>
  import Swal from 'sweetalert2'
  import { mapGetters } from 'vuex'
  // import buttons from '../../../layouts/Buttons/DialogButton.vue'
  // import buttons_components from '../../../../../assets/js/components-js.js'
  export default {
    // components: { buttons, buttons_components },
    data() {
      return {
        // buttons: buttons_components.buttons,
        loaded: false,
        departments: [],
        itemsPerPageOptions: [5, 10, 15],
        itemsPerPage: 5,
        search: '',
        headers: [
          { text: 'Name', align: 'left', value: 'name',sortable:false },
          { text: 'Code', align: 'left', value: 'code',sortable:false },
          { text: 'Active', align: 'left', value: 'active',sortable:false },
          { text: 'Actions', align: 'center', value: 'action', sortable: false },
        ],
        viewDepartmentId: '',
        json_data: [],
        json_meta: [
          [
            {
              key: 'charset',
              value: 'utf-8'
            },
          ],
        ],
        userAccess: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
        selected: {},
        options:{},
        totalItems:0,
        items:[],
        loaded:false,
      };
    },
    mounted() {
      this.getAllData();
    },
    methods: {
      getAllData(){
        this.items = [];
        this.totalItems = 0
        this.loaded = false
        let payload = {
          page: this.options.page,
          itemsPerPage:this.options.itemsPerPage,
          search: this.search,
        }
        this.$store.dispatch('getAllAccountingDepartment',payload).then(response=>{
          this.items = response.data.data;
          this.totalItems = response.total
          this.loaded = true
          this.$store.commit('NEW_ACCOUNTING_DEPARTMENT',false)
        })
      },
      viewdialogs(item) {
        this.dialog = true;
        this.selected = item
        this.$store.commit("EDIT_ACCOUNTING_DEPARTMENT", item);
        this.$store.commit("DIALOG", true);
        this.$store.commit("ACTION", "View");
      },
      editdialogs(item) {
        this.dialog = true;
        this.selected = item
        this.$store.commit('EDIT_ACCOUNTING_DEPARTMENT', item)
        this.$store.commit('DIALOG', true)
        this.$store.commit('ACTION', 'Update')
        this.action = 'edit'
      },
      deleteDepartment(id) {
        Swal.fire({
          icon: 'question',
          text: 'Are you sure you want to Delete?',
          cancelButtonText: 'Cancel',
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonColor: '#397373',
          cancelButtonColor: '#397373'
        }).then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('deleteAccountingDepartment', id).then(success => {
              Swal.fire({
                text: 'Delete Department Successful!',
                icon: 'success'
              });
            })
          }
        });
      },
      searchData(){
        this.options.page = 1;
        this.getAllData()
      }
    },
    computed: {
      ...mapGetters([
        'DIALOGS',
        'GET_ALL_DEPARTMENT',
        'GET_EDIT_ACCOUNTING_DEPARTMENT',
        'DIALOGS',
        'ACTION',
        'USER_ACCESS',
        'GET_NEW_ACCOUNTING_DEPARTMENT',
        'GET_DEPARMENT_LOADED',
      ]),
    },
    watch: {
      USER_ACCESS: {
        handler(val) {
          if(val != "fail"){
             this.userAccess.edit = false;
             this.userAccess.delete = false;
             this.userAccess.view = false;
             this.userAccess.create = false;
          val.forEach(e => {
            if (e.actions_code == 'edit') {
              this.userAccess.edit = true;
            } else if (e.actions_code == 'delete') {
              this.userAccess.delete = true;
            } else if (e.actions_code == 'view') {
              this.userAccess.view = true;
            } else if (e.actions_code == 'create') {
              this.userAccess.create = true;
            }
          });
          }
        }
      },
      GET_NEW_ACCOUNTING_DEPARTMENT:{
        handler(val){
          if(val){
            this.getAllData()
          }
        }
      },
      options:{
        handler(val){
            this.getAllData()
        }
      }
    },
  };
  </script>
  