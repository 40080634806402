<template>
    <v-dialog v-model="rfEstDialog" persistent max-width="98%" scrollable>
        <v-card class="pb-4">
            <v-row class="m-0">
                <v-col>
                    <v-card-title>
                        <span class="headline">Estimation</span>
                    </v-card-title>
                </v-col>
                <v-col cols="pull-right-10 p-2">
                    <v-btn text icon color="gray" class="float-right" @click="closerfEstDialog()">
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class="mt-0" />
            <v-container fluid style="background-color: white; overflow-y: scroll" class="ma-0 pa-0">
                <v-col>
                    <v-card-text>
                        <div class="ma-4">
                            <v-layout row class="px-6">
                                <v-flex lg4>
                                    <div>RF#: <span>{{ rfNumber }}</span></div>
                                    <div>Item Model: <span>{{ itemModel }}</span></div>
                                    <div>Item Name: <span>{{ itemProductName }}</span></div>
                                    <div>Serial: <span>{{ itemSerialNumber }}</span></div>
                                </v-flex>
                                <v-flex lg4>
                                    <div>Physical Condition: <span>{{ itemUnitCondition }}</span></div>
                                    <div>Other Physical Condition: <span>{{ itemOtherUnitCondition }}</span></div>
                                    <div>Complaint / Symptom: <span>{{ itemDefectDescription }}</span></div>
                                    <div>Remarks: <span>{{ itemDefectRemarks }}</span></div>
                                    <div v-if="!!itemDiagnosisRejectReason">Diagnosis Reject Reason: <span>{{ itemDiagnosisRejectReason }}</span></div>
                                </v-flex>
                                <v-flex lg4>
                                    <div>Warranty Type: <span>{{ itemWarrantyType }}</span></div>
                                    <div>Status: <span>{{ rfStatus }}</span></div>
                                    <div>Purchase Date: <span>{{ itemPurchaseDate }}</span></div>
                                </v-flex>
                            </v-layout>
                            <v-layout row class="mx-1">
                                <v-flex lg4 class="pa-2">
                                    <v-card class="pa-4">
                                        <div>
                                            <div>
                                                <BaseFilesViewerVue :itemId="itemId" buttonName="Exploded Views" />
                                            </div>
                                            <v-layout>
                                                <v-flex lg4 class="mt-2">
                                                    Recommendation:
                                                </v-flex>
                                                <v-flex>
                                                    <v-autocomplete
                                                        v-model="itemRecommendation"
                                                        :items="itemRecommendationList"
                                                        auto-select-first
                                                        outlined
                                                        dense
                                                    />
                                                </v-flex>
                                            </v-layout>
                                            <v-divider class="mt-0" />
                                            <div v-if="itemRecommendation == 'For-Repair'">
                                                <div>
                                                    <v-checkbox v-model="from_other_unit" label="Parts From other unit" class="ma-0"></v-checkbox>
                                                </div>
                                                <v-layout>
                                                    <v-flex lg2 class="pr-2">
                                                        <div>Qty:</div>
                                                        <div>
                                                            <v-text-field v-model="recommendedItemQty" outlined dense :min="1" type="number" @focus="$event.target.select()" />
                                                        </div>
                                                    </v-flex>
                                                    <v-flex lg5 class="pr-2">
                                                        <div>Model / Part No.:</div>
                                                        <div>
                                                            <v-autocomplete
                                                                v-if="!from_other_unit"
                                                                placeholder="Select Spare Parts"
                                                                :disabled="ItemSelectionLoading"
                                                                :loading="ItemSelectionLoading"
                                                                v-model="selectedItemModel"
                                                                :items="itemModelList"
                                                                auto-select-first
                                                                item-text="text"
                                                                item-value="id"
                                                                outlined
                                                                dense
                                                            />
                                                        </div>
                                                        <div>
                                                            <v-text-field v-model="otherParts.model" label="Model" outlined dense @change="selectedItem()" v-if="from_other_unit"></v-text-field>
                                                        </div>
                                                    </v-flex>
                                                    <v-flex lg2 class="mt-6">
                                                        <div></div>
                                                        <div>
                                                            <v-btn @click="addSpItem()">Add</v-btn>
                                                        </div>
                                                    </v-flex>
                                                </v-layout>

                                                <v-layout v-if="!!recommendedItemModel" class="pa-2" row>
                                                    <v-flex lg12>
                                                        <div>Item Name: <span>{{ selectedItemName }}</span></div>
                                                        <div>Part Number: <span>{{ selectedItemPartNum }}</span></div>
                                                        <div>Category: <span>{{ selectedItemCategory }}</span></div>
                                                    </v-flex>
                                                </v-layout>

                                                <v-divider />

                                                <!-- <v-layout v-if="attach" row class="pa-2">
                                                    <v-flex lg3 class="mr-4">
                                                        <v-autocomplete v-model="selectedAttach" :items="['Camera', 'Upload']" outlined dense></v-autocomplete>
                                                    </v-flex>
                                                    <v-flex v-if="selectedAttach == 'Camera'"  lg2 class="mr-4">
                                                        <div style="text-align:center">
                                                            <BaseCameraCaptureVue v-if="selectedAttach == 'Camera'" @capturedImages="getImages" />
                                                        </div>
                                                    </v-flex>

                                                    <v-flex v-if="selectedAttach == 'Camera'" lg3 class="pt-1">
                                                        <div style="text-align:center">
                                                            Image Count: {{ !!images ? images.length : 0 }}
                                                        </div>
                                                    </v-flex>

                                                    <v-flex v-if="selectedAttach == 'Upload'">
                                                        <FileUploadComponentVue @uploadedData="uploadedData" />
                                                    </v-flex>
                                                </v-layout> -->
                                            </div>

                                            <div class="pa-2">
                                                <div class="py-2">
                                                    <!-- <v-checkbox v-model="attach" label="Attach Documents" class="ma-0 pa-0 pt-2" /> -->
                                                    <span>Attach Documents via Camera or Upload</span><span v-if="(this.images == null && this.uploadedFiles == null) && ((this.itemWarrantyType == 'Dealer-Stock' || this.itemWarrantyType == 'In-Warranty') && (this.itemRecommendation == 'For-Repair' || this.itemRecommendation == 'For-Replacement'))" style="color:red">  *</span>
                                                </div>

                                                <div class="py-2 mt-2">
                                                    <v-layout row class="mx-0">
                                                        <v-flex lg2 class="mr-2">
                                                            <BaseCameraCaptureVue ref="cameraCapture" @capturedImages="getImages" />
                                                        </v-flex>
                                                        <v-flex v-if="!!images && images.length > 0" lg3 class="mr-2 mt-1 ">
                                                            <span>Images Count: {{ images.length }}</span>
                                                        </v-flex>
                                                        <v-flex v-if="!!images && images.length > 0">
                                                            <v-btn @click="clearCameraCapture()">Clear</v-btn>
                                                        </v-flex>
                                                    </v-layout>
                                                </div>

                                                <div class="py-2 mt-3">
                                                    <v-layout row class="mx-0">
                                                        <v-flex :class="{'mr-2': true, 'lg3': !!uploadedFiles && uploadedFiles.attachments.length > 1, 'lg6': !!uploadedFiles && uploadedFiles.attachments.length == 1}">
                                                            <FileUploadComponentVue ref="fileUpload" @uploadedData="uploadedData" @reset="clearFileUpload"/>
                                                        </v-flex>
                                                        <v-flex v-if="!!uploadedFiles && uploadedFiles.attachments.length > 0" lg3 class="mr-2 mt-3">
                                                            <span>Upload Count: {{ uploadedFiles.attachments.length }}</span>
                                                        </v-flex>
                                                        <!-- <v-flex v-if="!!uploadedFiles && uploadedFiles.attachments.length > 0">
                                                            <v-btn @click="clearFileUpload()" class="mt-1">Clear</v-btn>
                                                        </v-flex> -->
                                                    </v-layout>
                                                </div>

                                                <v-divider />

                                                <div class="py-2">
                                                    <v-layout row class="mx-0">
                                                        <v-flex lg6 class="pr-2">
                                                            <div class="mt-2">Crate Number:</div>
                                                            <div>
                                                                <v-text-field v-model="crateNumber" type="number" :min="0" outlined dense hide-details @focus="$event.target.select()"/>
                                                            </div>
                                                        </v-flex>
                                                        <v-flex lg6>
                                                            <div class="mt-2">Location Number:</div>
                                                            <div>
                                                                <v-text-field v-model="locationNumber" type="number" :min="0" outlined dense hide-details @focus="$event.target.select()"/>
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>
                                                </div>
                                            </div>
                                        </div>
                                    </v-card>
                                </v-flex>

                                <!-- Selected Parts Table -->
                                <v-flex :class="{ 'pa-2': true }">
                                    <v-card class="pa-2 mb-2">
                                        <div class="pa-2">Requested <span v-if="itemRecommendation == 'For-Replacement'">Item</span><span v-else>Spare Parts</span></div>
                                        <div class="pb-4">
                                            <v-data-table
                                                :headers="itemHeaders()"
                                                :items="recommendedItemLists"
                                                :items-per-page="recommendedItemLists.length"
                                            >
                                                <template v-if="rfStatus == 'UAS'" v-slot:[`item.model`]="{ item }">
                                                    <td>
                                                        <v-autocomplete
                                                            v-model="replacementSp"
                                                            v-if="!!item.edit"
                                                            placeholder="Select Parts Compatibility"
                                                            background-color="grey lighten-2"
                                                            :loading="partsCompatibilityLoading"
                                                            :items="partsCompatibilityItems"
                                                            no-data-text="No Parts Compatible Assigned"
                                                            auto-select-first
                                                            item-text="model"
                                                            item-value="id"
                                                            hide-details
                                                            outlined
                                                            dense
                                                            @change="changeParts(item)"
                                                            >
                                                        </v-autocomplete>
                                                        <span v-if="!item.edit">{{ item.model }}</span>
                                                    </td>
                                                </template>

                                                <template v-if="rfStatus == 'UAS'" v-slot:[`item.action`]="{ item }">
                                                    <td class="px-0">
                                                        <v-tooltip bottom color="#f69f1a">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    color="primary"
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    text
                                                                    icon
                                                                >
                                                                    <v-icon class="btn-action" small @click="editRecommendedItem(item)">mdi-pencil</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Replace Spare Part</span>
                                                        </v-tooltip>
                                                        <v-tooltip bottom color="#f69f1a">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    v-if="item.changed == true"
                                                                    color="#f69f1a"
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    icon
                                                                    text
                                                                >
                                                                    <v-icon class="btn-action" dark small @click="revertChanges(item)">mdi-sync</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Revert To Original</span>
                                                        </v-tooltip>
                                                        <v-tooltip bottom color="#f69f1a">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    color="red"
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    text
                                                                    icon
                                                                >
                                                                    <v-icon class="btn-action" small @click="deleteSpItem(item)">mdi-delete</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Delete</span>
                                                        </v-tooltip>
                                                    </td>
                                                </template>
                                            </v-data-table>
                                        </div>
                                    </v-card>
                                    <v-card>
                                        <v-layout class="pa-4">
                                            <v-flex lg6 class="pr-2">
                                                <div>
                                                    Symptom as Tested: <span style="color:red">*</span>
                                                </div>
                                                <div>
                                                    <v-textarea v-model="itemSymptomUponTested" :rules="rules.default" dense outlined />
                                                </div>
                                            </v-flex>
                                            <v-flex lg6 class="pr-2">
                                                <div>
                                                    Tech Diagnosis: <span style="color:red">*</span>
                                                </div>
                                                <div>
                                                    <v-textarea v-model="itemTechDiagnosis" :rules="rules.default" dense outlined />
                                                </div>
                                            </v-flex>
                                            <v-flex lg6 class="pr-2">
                                                <div>
                                                    Recommendation: <span style="color:red">*</span>
                                                </div>
                                                <div>
                                                    <v-textarea v-model="itemNewRecommendation" :rules="rules.default" dense outlined />
                                                </div>
                                            </v-flex>
                                            <v-flex v-if="itemRecommendation != 'For-Repair'" lg6>
                                                <div>
                                                    Job Done: <span style="color:red">*</span>
                                                </div>
                                                <div>
                                                    <v-textarea v-model="itemJobDone" :rules="rules.default" dense outlined />
                                                </div>
                                            </v-flex>
                                        </v-layout>
                                    </v-card>
                                </v-flex>
                            </v-layout>
                        </div>
                        <v-divider />
                        <div class="ma-4">
                            <v-layout class="mx-0">
                                <v-flex>
                                    <div style="text-align:center">
                                        <v-btn :disabled="showPrintEstimation()" :loading="submitOnFlight" @click="submit()">Submit</v-btn>
                                    </div>
                                </v-flex>
                                <!-- <v-flex lg6>
                                    <div class="pt-2" style="text-align:center">
                                        <v-btn :loading="submitOnFlight" @click="submit">Submit</v-btn>
                                    </div>
                                </v-flex> -->
                            </v-layout>
                        </div>
                    </v-card-text>
                </v-col>
            </v-container>
        </v-card>

        <!-- For Estimation Form Print -->
        <v-container fluid style="display:none">
            <div id="est_logo" >
                <div style="font-size: 12px; line-height: 1.9">
                    <p>
                        <span style="font-size: 18px; font-weight: bold;">Estimation Form</span>
                        <br>RF#: {{ rfNumber }}
                        <br>Company Name: {{ companyName }}
                        <br>Customer Name: {{ customerName }}
                        <br>Warranty Type: {{ itemWarrantyType }}
                    </p>
                </div>
            </div>
             <div id="est_headers" >
                <div style="font-size: 12px; line-height: 1.9">
                    <p>
                        <br>Model: {{ itemModel }}
                        <br>Name: {{ itemProductName }}
                        <br>Serial Number: {{ itemSerialNumber }}
                        <br>Complaint / Symptom: {{ itemDefectDescription }}
                    </p>
                </div>
            </div>

            <div>
                <table id="est_items">
                    <tbody>
                        <tr>
                            <td>Qty</td>
                            <td>Model</td>
                            <td>Name</td>
                            <td>Category</td>
                            <td>Part Number</td>
                        </tr>
                        <tr v-for="sp in recommendedItemLists" :key="sp.id">
                            <td>{{ sp.requested_qty || '' }}</td>
                            <td>{{ sp.model.split(' / ')[0] || '' }}</td>
                            <td>{{ sp.name || '' }}</td>
                            <td>{{ sp.category || '' }}</td>
                            <td>{{ sp.model.split(' / ')[1] || '' }}</td>
                        </tr>
                        <tr>
                            <td colspan="5">Total: {{ recommendedItemLists.length }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div>
                <table id="est_footer">
                    <tbody>
                        <tr>
                            <td>Date:</td>
                            <td>{{ dateToday }}</td>
                            <td>Received By:</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Technician:</td>
                            <td>{{ technicianName }}</td>
                            <td>Prepared By:</td>
                            <td>{{ preparedBy }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </v-container>
        <ConfirmOverrideComponent
            :approve_override_dialog="override.override_dialog"
            :departments_allowed="override.override_departments_allowed"
            :heading="''"
            @closeConfirmOverride="closeConfirmOverride"
        ></ConfirmOverrideComponent>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import FileUploadComponentVue from '../../Shared/FileUploadComponent.vue';
import BaseFilesViewerVue from '../Accounting/Base/BaseFilesViewer.vue';
import BaseCameraCaptureVue from './Base/BaseCameraCapture.vue';
import swal from 'sweetalert2';
import ConfirmOverrideComponent from '../../Utils/ConfirmOverrideComponent.vue';
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue'
export default {
    mixins:[SharedFunctionsComponentVue],
    data(){
        return {
            ureItemHeaders: [
                { text: 'Qty', value: 'requested_qty' },
                // { text: 'Selling Price', value: 'selling_price' },
                { text: 'SP Model/Part No', value: 'model' },
                { text: 'Name', value: 'name' },
                { text: 'Action', value: 'action' },
            ],
            wfpItemHeaders: [
                { text: 'Qty', value: 'requested_qty', width: '10%'},
                { text: 'Item Model', value: 'model', width: '20%'},
                { text: 'Item Name', value: 'name', width: '30%' },
                // { text: 'Category', value: 'category' },
            ],
            itemPartNumList: [],
            itemModelList: [],
            recommendedItemLists: [],
            itemRecommendation: 'For-Repair',
            itemPurchaseDate: '',
            rfId: '',
            rfItemId: '',
            otherParts:{
                model:'',
                name:'',
                quantity:1,
                counter:0
            },
            rfStatus: '',
            rfNumber: '',
            itemWarrantyType: '',
            itemModel: '',
            itemProductName: '',
            itemSerialNumber: '',
            itemDefectDescription: '',
            itemDefectRemarks: '',
            itemUnitCondition: '',
            itemOtherUnitCondition: '',
            itemAccessories: '',
            itemOtherAccessory: '',
            itemSymptomUponTested: '',
            itemDiagnosisRejectReason: '',
            recommendedItemPartNum: '',
            recommendedItemModel: '',
            recommendedItemQty: 1,
            itemQty: 1,
            selectedItemModel: '',
            selectedItemName: '',
            selectedItemPartNum: '',
            selectedSellingPrice: '',
            selectedItemCategory: '',
            rfEstimation: null,
            rfEstItems: [],
            itemId: '',
            itemJobDone: '',
            attach: false,
            selectedAttach: 'Camera',
            images: null,
            uploadedFiles: null,
            submitOnFlight: false,
            customerSpecial: false,
            crateNumber: 0,
            locationNumber: 0,

            technicianName: '',
            companyName: '',
            customerName: '',
            preparedBy: '',

            loading:{
                dropdowns:true,
            },

            itemRecommendationList: ['For-Repair', 'For-Replacement', 'No-Problem-Found', 'Repair-w/o-Parts', 'Return-w/o-Repair'],
            partNumSuggestion: false,
            from_other_unit:false,
            dateToday: this.$dayjs().format('YYYY-MM-DD, hh:mm:ss a'),
            itemTechDiagnosis: null,
            itemNewRecommendation: null,
            override: {
                override_dialog: false,
                errors: [],
                override_departments_allowed: ['SR'],
                override_authenticated: false,
            },
            selected:{},
            ItemSelectionLoading:true,
            spLists: [],
            lists: [],
            partsCompatibility: false,
            partsCompatibilityItems: [],
            partsCompatibilityLoading: false,
            replacementSp: '',
            rules: {
                default: [
                v => !!v || 'This is required',
                ],
            },
        }
    },
    components: {
        BaseCameraCaptureVue,
        FileUploadComponentVue,
        BaseFilesViewerVue,
        ConfirmOverrideComponent
    },
    mounted: function() {
        this.getModelSpareParts()
    },
    props: ['rf', 'status', 'itemToSearch'],
    // props: ['itemToSearch', 'rfPartsOrderItems', 'rfId', 'rfPartsOrderId', 'itemWarrantyType','rf'],

    watch: {
        rf() {
            if(!!this.rf) {
                if(this.status == "WFP") {
                    this.getRfEstimation(this.rf.id);
                }
                let rf = this.rf;
                let rf_item = rf.sc_repair_form_item;
                if(!!rf_item.purchase_date) {
                    this.itemPurchaseDate = this.$dayjs(rf_item.purchase_date).format('YYYY-MM-DD, hh:mm:ss a');
                }
                this.rfId = rf.id;
                this.rfItemId = this.rf.sc_repair_form_item.id;
                this.itemId = this.rf.sc_repair_form_item.item_id;
                this.rfStatus = rf.status;
                this.rfNumber = rf.rf_number;
                this.itemWarrantyType = rf_item.warranty_type;
                this.itemModel = rf_item.model;
                this.itemProductName = rf_item.product_name;
                this.itemSerialNumber = rf_item.serial;
                this.itemDefectDescription = rf_item.defect_description;
                this.itemDefectRemarks = rf_item.defect_remarks;
                this.itemUnitCondition = rf_item.unit_condition;
                this.itemOtherUnitCondition = rf_item.other_unit_condition;
                this.itemOtherAccessory = rf_item.other_accessory;
                this.itemAccessories = rf_item.accessories;
                this.itemDiagnosisRejectReason = rf_item.diagnosis_reject_reason;

                this.preparedBy = rf.prepared_by;

                if(!!rf.sc_receiving_report.customer) {
                    let { id, company_name, special } = rf.sc_receiving_report.customer;

                    this.customerSpecial = !!special;
                    this.companyName = company_name;

                    if(id == 275) {
                        let { first_name, last_name } = rf.sc_receiving_report.sc_customer;
                        this.customerName = `${first_name} ${last_name}`;
                    }
                }

                if(!!rf.assigned_technician) {
                    this.technicianName = rf.assigned_technician
                }
            }
        },
        itemRecommendation(){
            if(this.itemRecommendation == 'For-Replacement') {
                let rf_item = this.rf.sc_repair_form_item;
                let same_item = [];

                same_item.push({
                    id: _.random(1,99999),
                    item_id: rf_item.item_id,
                    requested_qty: 1,
                    model: rf_item.model,
                    name: rf_item.product_name,
                    category: rf_item.category,
                    sp_number: !!rf_item.sp_number ? rf_item.sp_number : null
                });

                this.recommendedItemLists = _.compact(same_item);

                this.recommendedItemModel = '';
                this.recommendedItemQty = 1;
                this.itemQty = 1;
                this.itemSymptomUponTested = '';
            } else if(this.itemRecommendation == 'No-Problem-Found' || this.itemRecommendation == 'Repair-w/o-Parts' || this.itemRecommendation == 'Return-w/o-Repair') {
                this.recommendedItemLists = [];
                this.clearCameraCapture();
                this.$refs.fileUpload.reset();
                this.uploadedFiles = null;
            } else {
                this.recommendedItemLists = [];
            }
        },
        rfEstItems() {
            if(this.rfEstItems.length > 0) {
                this.recommendedItemLists = this.rfEstItems;
            } else {
                this.recommendedItemLists = [];
            }
        },
        selectedAttach() {
            if(this.selectedAttach == 'Camera') {
                this.uploadedFiles = null;
            } else if(this.selectedAttach == 'Upload') {
                this.images = null;
            }
        },
        itemId() {
            if(!!this.itemId) {
                this.getAllSpItems();
            }
        },
        itemWarrantyType() {
            if(this.itemWarrantyType == 'Out-Warranty') {
                this.itemRecommendationList = this.itemRecommendationList.filter(x=>x != 'For-Replacement');
            }
            // else if (this.itemWarrantyType == 'Dealer-Stock') {
            //     this.itemRecommendationList = 'For-Replacement'
            // }
            else {
                this.itemRecommendationList = ['For-Repair','For-Replacement', 'No-Problem-Found', 'Repair-w/o-Parts', 'Return-w/o-Repair']
            }
        },
        partNumSuggestion() {
            this.getAllSpItems();
        },
        itemToSearch() {
            if(!!this.itemToSearch) {
                this.getModelSpareParts();
            }
        },
    },
    computed: {
        ...mapGetters([
            'GET_ALL_TECH_SP_ITEMS_POST',
            'GET_RF_ESTIMATION',
        ]),
        rfEstDialog() {
            return this.$store.state.service.scEstDialog;
        },
        checkRecommendedItemLists() {
            if(this.recommendedItemLists.length > 0) {
                return false;
            }

            if(this.itemRecommendation == 'No-Problem-Found' || this.itemRecommendation == 'Repair-w/o-Parts' || this.itemRecommendation == 'Return-w/o-Repair') {
                return false;
            }

            return true;
        },
        checkIfNotRepair() {
            if(this.itemRecommendation == 'For-Repair') {
                return false;
            } else {
                return true;
            }
        },
    },
    methods: {
        closerfEstDialog() {
            this.$store.commit('closeScEstDialog');
            this.recommendedItemLists = [];
            this.rfEstItems = [];
            this.itemJobDone = "";
            this.itemRecommendation = "For-Repair";
            this.images = null;
            this.attach = false;
            this.selectedAttach = "Camera";
            this.clearCameraCapture();
            this.$refs.fileUpload.reset();
            this.uploadedFiles = null;
            this.crateNumber = '';
            this.locationNumber = '';
        },
        getAllSpItems() {
            let payload = {
                itemId: this.itemId,
                partNumSuggestion: this.partNumSuggestion
            }
            this.loading.dropdowns = true
            this.$store.dispatch('getAllTechSpItemsPost',payload).then(response => {

                this.itemModelList = this.GET_ALL_TECH_SP_ITEMS_POST;
                this.itemModelList.forEach(x=>x.model = `${x.model} / ${x.name}`);
                this.loading.dropdowns = false
            });
        },
        selectedItem() {
            if(!!this.recommendedItemModel) {
                this.selected = this.itemModelList.find(x => x.id == this.recommendedItemModel);
            }
        },
        async addSpItem() {
            let object = {}
            let selectedItem = null
            if(this.from_other_unit){
                await this.$store.dispatch('getItemsByModel',{
                    items:[this.otherParts.model],
                }).then(response=>{

                    if(!!response.data[0].item_id){
                        this.selected.item_id = response.data[0].item_id
                        this.selectedItemModel = `${response.data[0].model} / ${response.data[0].name}`;
                        this.selectedItemName = response.data[0].name;
                        this.selectedItemPartNum = response.data[0].part_num;
                        this.selectedSellingPrice = response.data[0].selling_price;
                        this.selectedItemCategory = response.data[0].category;

                        selectedItem = Object.assign({},{
                            ...response.data[0],
                            category_name:response.data[0].category,
                            id:response.data[0].item_id
                        })
                        this.otherParts.counter++

                        response.data[0].model = response.data[0].model+' / '+ response.data[0].name
                        object ={
                            requested_qty: this.recommendedItemQty,
                            ...response.data[0]
                        }
                    }else{
                        return false
                    };
                })
            }else{
                selectedItem = _.compact(this.itemModelList).find(x=> x.id == this.selectedItemModel);
            }

            if(!!selectedItem) {

                let existingModel = this.recommendedItemLists.find(x=>x.model == selectedItem.model.split(' / ')[0]);

                if(!!existingModel) {
                    let totalRequestQty = parseInt(existingModel.requested_qty) + parseInt(this.recommendedItemQty)
                        existingModel.requested_qty = totalRequestQty;
                } else {
                    this.recommendedItemLists.push({
                        id: selectedItem.id,
                        item_id: selectedItem.item_id,
                        requested_qty: this.recommendedItemQty,
                        part_num: selectedItem.part_num,
                        model: selectedItem.model.split(' / ')[0],
                        name: selectedItem.name,
                        category: selectedItem.category_name,
                        selling_price: selectedItem.selling_price,
                        sp_number: !!selectedItem.sp_number ? selectedItem.sp_number : null,
                        edit: false,
                        original_requested_sp: {
                            id: selectedItem.id,
                            item_id: selectedItem.item_id,
                            part_num: selectedItem.part_num,
                            model: selectedItem.model.split(' / ')[0],
                            name: selectedItem.name,
                            category: selectedItem.category_name,
                            selling_price: selectedItem.selling_price,
                            sp_number: !!selectedItem.sp_number ? selectedItem.sp_number : null,
                        }
                    })
                }

                this.selectedItemModel = "";
                this.recommendedItemQty = 1;
            } else if(!!this.recommendedItemModel) {
                this.recommendedItemLists = this.addOrUpdateToArray(this.recommendedItemLists,object,'requested_qty')

                this.recommendedItemModel = "";
                this.recommendedItemQty = 1;
                this.otherParts.model = ''

             } else{
                swal.fire('Error!','- Item not found','error')
            }
        },
        deleteSpItem(sp){
            this.recommendedItemLists = this.recommendedItemLists.filter(x => x.id != sp.id);
            this.otherParts.counter--
        },
        submit() {
            this.recommendedItemLists = this.recommendedItemLists.map( e => {
                return {
                    id:e.id,
                    item_id:e.item_id,
                    requested_qty:e.requested_qty,
                    part_num:e.part_num,
                    model:e.model,
                    name:e.name,
                    category:e.category,
                    selling_price:e.selling_price,
                    sp_number: !!e.sp_number ? e.sp_number : null
                }
            })
            this.submitOnFlight = true;

            let selected = _.compact(this.recommendedItemLists).length;
            let filtered = _.uniqBy(_.compact(this.recommendedItemLists), 'id').length;

            if(filtered != selected) {
                swal.fire({
                    title: 'Check Duplicate Model',
                    text: 'Make sure that all informations are correct',
                    icon: 'warning',
                    dangerMode: true,
                }).then(confirm=>{
                    this.submitOnFlight = false;
                })

            } else if(
                (this.images == null && this.uploadedFiles == null) && ((this.itemWarrantyType == 'Dealer-Stock' || this.itemWarrantyType == 'In-Warranty') && (this.itemRecommendation == 'For-Repair' || this.itemRecommendation == 'For-Replacement'))) {
                swal.fire({
                    title: 'Please Capture or Upload Image',
                    icon: 'warning',
                    dangerMode: true,
                }).then(confirm=>{
                    this.submitOnFlight = false;
                })
            } else {
                if(!this.requiredFields()) {
                    swal.fire({
                        title: 'Are you sure you want to Submit?',
                        text: 'Make sure that all informations are correct',
                        icon: 'question',
                        showConfirmButton:true,
                        showCancelButton  :  true,
                        reverseButtons:true,
                        allowOutsideClick :  false,
                        confirmButtonColor: '#397373',
                        cancelButtonColor : 'grey',
                        confirmButtonText:'Confirm',
                        showCloseButton:true,
                    }).then(async (result) => {
                        if(result.isConfirmed) {
                            if(this.otherParts.counter > 0 ){
                                if(this.itemWarrantyType ='Out-Warranty'){
                                    this.createRrAndRfEstimation()
                                }else{
                                    this.override.override_dialog = true
                                }
                            }else{
                                this.createRrAndRfEstimation();
                            }
                        } else {
                            this.submitOnFlight = false;
                        }
                    });
                } 
            }
        },
        createRrAndRfEstimation(filePaths) {

            const data = new FormData();

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            this.recommendedItemLists.forEach(e=>{
                if(this.itemRecommendation != 'For-Replacement'){
                    e.item_id = e.id
                }
            })

            if(!!this.uploadedFiles && this.uploadedFiles.attachments.length > 0) {
                let files = this.uploadedFiles.attachments;

                for(let i=0;i < _.compact(files).length;i++) {
                    data.append('files[]', files[i]);
                }
            }

            if(!!this.images && this.images.length > 0) {
                for(let i=0;i < _.compact(this.images).length;i++) {
                    data.append('images[]', _.compact(this.images)[i]);
                }
            }
            let total_srp = this.sumArraybyAttribute(this.recommendedItemLists,'selling_price')

            data.append('auto_foc', (40 / 100) * parseInt(this.rf.sc_repair_form_item.item.selling_price) < total_srp ? 0 : 1)

            data.append('rfId', this.rfId);
            data.append('rfItemId', this.rfItemId);
            data.append('item_id', this.itemId);

            data.append('itemSymptomUponTested', this.itemSymptomUponTested);
            data.append('itemJobDone', this.itemJobDone);
            data.append('itemRecommendation', this.itemRecommendation)
            data.append('itemWarrantyType', this.itemWarrantyType);
            data.append('customerSpecial', this.customerSpecial);
            data.append('crateNumber', this.crateNumber);
            data.append('locationNumber', this.locationNumber);

            data.append('itemTechDiagnosis', this.itemTechDiagnosis);
            data.append('itemNewRecommendation', this.itemNewRecommendation);

            // data.append('payload', JSON.stringify({
            //     rfId: this.rfId,
            //     rfItemId: this.rfItemId,
            //     item_id: this.itemId,
            //     spItems: _.compact(this.recommendedItemLists),
            //     itemSymptomUponTested: this.itemSymptomUponTested,
            //     itemJobDone: this.itemJobDone,
            //     itemRecommendation: this.itemRecommendation,
            //     itemWarrantyType: this.itemWarrantyType,
            //     images: _.compact(this.images)
            // }))

            this.recommendedItemLists.forEach(x=>x.model = x.model.split(' / ')[0]);

            for(let i=0;i < _.compact(this.recommendedItemLists).length;i++){
                data.append('spItems[]', JSON.stringify(this.recommendedItemLists[i]));
            }
            let payload = {
                params:data,
                config:config,
            }

            this.$store.dispatch('createRfItemEstimation',payload).then(response => {
                if(response.data.error) {
                    swal.fire('', response.data.error,'warning').then(confirm=>{
                        this.submitOnFlight = false;
                    });
                } else {
                    swal.fire("", response.data.msg, "success").then(confirm=>{
                        this.submitOnFlight = false;
                    });
                    this.closerfEstDialog();
                    this.$emit('rfEstimationSuccess', true);
                    this.recommendedItemLists = [];
                    this.itemSymptomUponTested = "";
                    this.itemTechDiagnosis = "";
                    this.itemNewRecommendation = "";
                    this.itemJobDone = "";
                    this.itemRecommendation = "For-Repair";
                }
            }).catch(e => {
                console.log(e)
                swal.fire("", e.response.data.error, "error");
                this.submitOnFlight = false;
            });
        },
        itemHeaders() {
            if(this.rfStatus != 'UAS' || this.itemRecommendation == "For-Replacement") {
                return this.wfpItemHeaders;
            }
            return this.ureItemHeaders;
        },
        getRfEstimation(rfId){
            this.$store.dispatch('getRfEstimation',rfId).then(response => {
                if(!!this.GET_RF_ESTIMATION) {
                    this.rfEstimation = this.GET_RF_ESTIMATION;
                    this.rfEstItems = this.GET_RF_ESTIMATION.sc_rf_est_items;
                }
            });
        },
        getImages(images) {
            this.images = images;
        },
        uploadedData(data) {
            if(!data) {
                this.uploadedFiles = null;
            }

            this.uploadedFiles = data;
        },
        clearFileUpload(confirm) {
            if(confirm) {
                this.uploadedFiles = null;
            }
            // this.$refs.fileUpload.reset();
        },
        clearCameraCapture() {
            this.$refs.cameraCapture.reset();
            this.images = null;
        },
        showPrintEstimation() {
            if(this.recommendedItemLists.length > 0 && (this.itemRecommendation == 'For-Repair' || this.itemRecommendation == 'For-Replacement')) {
                return false
            }

            if (this.itemRecommendation == 'No-Problem-Found' || this.itemRecommendation == 'Repair-w/o-Parts' || this.itemRecommendation == 'Return-w/o-Repair') {
                return false
            }

            return true;
        },
        closeConfirmOverride(user_id, authenticated = false){
                if(authenticated){
                    this.createRrAndRfEstimation();
                }
                document.activeElement.blur();
                this.override.override_dialog = false;
                this.override_confirm = true;
                this.add_edit_statementOfAccount_dialog = false;
        },
        getModelSpareParts() {
            this.ItemSelectionLoading = true
            this.$store.dispatch('servicePost',{
                itemId: this.itemToSearch,
                partNumSuggestion: this.partNumSuggestion,
                url: 'getAllTechSpItems'
            }).then(response => {
                this.spLists = response.data;
                if(this.spLists.length > 0) {
                    this.spLists.forEach(x=>x.model = `${x.model} / ${x.part_num}`);
                }
                this.lists = this.spLists
                this.ItemSelectionLoading = false
            });
        },
        getPartsCompatibility(id) {
            this.partsCompatibilityLoading = true;
            this.$store.dispatch('getPartsCompatibility', {item_id:id}).then( response => {
                this.partsCompatibilityItems = response.data;
                this.partsCompatibilityLoading = false;
            }).catch( e => {
                console.log(e);
                this.partsCompatibilityLoading = false;
            })
        },
        editRecommendedItem(item) {
            item.edit = !item.edit;
            this.getPartsCompatibility(item.id)
        },
        changeParts(data) {
            swal.fire({
                title: 'Want to edit this requested spare part?',
                icon: 'question',
                showCancelButton: true,
                showConfirmButton: true,
                showCloseButton: true,
                confirmButtonText: 'Yes',
                confirmButtonColor: '397373',
                cancelButtonColor: 'grey',
                reverseButtons: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        let pc = this.partsCompatibilityItems.find(e => e.id === this.replacementSp)
                        Object.assign(data, pc, {edit:false, changed:true},);
                    } else {
                        data.edit = false;
                        this.replacementSp = '';
                    }
                });
        },
        revertChanges(data) {
            swal.fire({
                title: 'Want to revert the original requested part?',
                icon: 'question',
                showCancelButton: true,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Yes',
                confirmButtonColor: '397373',
                cancelButtonColor: 'grey',
                reverseButtons: true,
                }).then( result => {
                    if (result.isConfirmed) {
                        Object.assign(data, data.original_requested_sp, {edit:false, changed: false});
                        this.replacementSp = '';
                    } else {
                        this.replacementSp = '';
                    }
                });
        },

        requiredFields() {
            let warning = '';
            if (!this.itemSymptomUponTested) warning += 'Please ensure that the empty field for the symptom as tested is filled in.<br>';
            if (!this.itemTechDiagnosis) warning += 'Please ensure that the empty field for the tech diagnosis is filled in.<br>';
            if (!this.itemNewRecommendation) warning += 'Please ensure that the empty field for the recommendation is filled in.<br>';

            if(this.itemRecommendation == 'For-Repair') {
                if (this.recommendedItemLists.length == 0) {
                    warning += 'Please select Included Items.<br>';
                }
            }

            if(this.itemRecommendation != 'For-Repair') {
                if (!this.itemJobDone) warning += 'Please ensure that the empty field for the job done is filled in.<br>';
            }

            if (warning !== '') return swal.fire({
                title: 'Please Fill out Information:',
                html: warning,
                icon: "warning",
            }).then(confirm=>{
                this.submitOnFlight = false;
            });
        },
    }
}
</script>
