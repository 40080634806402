<template>
    <v-container fluid >
        <h3 class="page-title">Sample Form</h3>
        <v-container class="ma-0">
                <v-app id="item-tabs">
                    <v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" background-color="#424242" dark show-arrows>
                        <v-tabs-slider color="cyan"></v-tabs-slider>
                            <v-tab ripple v-for="(item, i) in items" :key="i" @click="tabs(i)" v-if="tabAccess(item)">
                                {{ item.text }}
                            </v-tab>
                            <v-spacer>
                            </v-spacer>
                            <!-- <v-tab @click="tabs(4)">
                                Logs
                            </v-tab> -->
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                        <v-tab-item v-for="(table, index) in sample_form_component" :key="index" v-if="tabAccess(table)">
                            <keep-alive>
                                <component :is="table.components" :sample_form_component="sample_form_component" :isActive_tab="table.status_text">
                                </component>
                            </keep-alive>
                        </v-tab-item>
                    </v-tabs-items>
                </v-app>
        </v-container>
        <DialogComponent :cp="components" :scrollable="dialog_scrollable" :width="height(dialog_width)" :retain-focus="false"></DialogComponent>
    </v-container>
  </template>
<script>
import { mapGetters } from "vuex";

import DialogComponent from '@/views/main/layouts/dialogs/Dialog.vue';
import SampleFormComponent from '@/views/main/modules/Operations/SampleForm/SampleFormComponent.vue'
import SampleFormDialog from '@/views/main/layouts/dialogs/Operations/SampleForm/SampleFormDialogComponent.vue'
import ReturnFormDialog from '@/views/main/layouts/dialogs/Operations/SampleForm/ReturnFormDialogComponent.vue'
import SampleFormLogsDialogComponent from '@/views/main/layouts/dialogs/Operations/SampleForm/SampleFormLogsDialogComponent.vue'

export default {
    components: {
        SampleFormComponent,
        DialogComponent,
        SampleFormLogsDialogComponent
    },
    data() {
      return {
            components:'',
            main: null,
            items: [
                    {text:"Inventory", access:"virtual_inventory_view"},
                    {text:"Sample Form", access:"issue_sample_form"},
                    {text:"Return", access:"return_sample_form"},
                    // {text:"Logs", access:"sample_form_logs"},
                ],
            sample_form_component: [
                {
                components: SampleFormComponent,
                access:"virtual_inventory_view",
                status_text: "inventory_list",
                dialog: SampleFormDialog,
                dialog_title:'Inventory List',
                dialog_width: "55%",
                scrollable:true,
                dispatch:{get:'getInventorySampleForm',
                        },
                },
                {
                components: SampleFormComponent,
                access:"issue_sample_form",
                status_text: "sample_form_list",
                dialog: SampleFormDialog,
                dialog_title:'Sample Form',
                dialog_width: "80%",
                scrollable:true,
                dispatch:{
                            get:'getAllSampleForm',
                            add:'addNewSampleForm',
                            changestatus:'changeStatusSampleForm'
                            },
                },
                {
                components: SampleFormComponent,
                access:"return_sample_form",
                status_text: "return_list",
                dialog: ReturnFormDialog,
                dialog_title:'Return Form',
                dialog_width: "80%",
                scrollable:true,
                dispatch:{
                            get:'getInventoryReturnForms',
                            upsert:'addUpdateReturnForm',
                            changestatus:'changeStatusReturnForm'
                        },
                },
                {
                components: SampleFormComponent,
                access:"sample_form_logs",
                status_text: "sf_logs",
                dialog: SampleFormLogsDialogComponent,
                dialog_title:'Sample Form Log',
                dialog_width: "80%",
                scrollable:true,
                dispatch:{
                            get:'getInventoryReturnForms',
                            upsert:'addUpdateReturnForm',
                            changestatus:'changeStatusReturnForm'
                        },
                },
            ],
            tab: null,
            dialog_width: "",
            dialog_scrollable:true,
      };
    },
    mounted() {
        this.$store.dispatch('checkAccessUser',this.$router.currentRoute.params.id);
        this.tabs(0)
    },
    computed:{
        ...mapGetters([
            'ACCESS_SAMPLE_FORM',
            'USER_ACCESS'
        ]),
    },
    methods: {
        tabs(key) {
            this.components = this.sample_form_component[key].dialog;
			this.dialog_width = this.sample_form_component[key].dialog_width;
			this.dialog_scrollable = this.sample_form_component[key].scrollable;

			this.$store.commit('SF_DIALOG_TITLE',this.sample_form_component[key].dialog_title)
			this.$store.commit('SF_ACTIVE_TAB',this.sample_form_component[key].status_text)
			this.$store.commit('SF_DISPATCH',this.sample_form_component[key].dispatch)
		},
        height(width) {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs': return "100%"
				case 'sm': return "100%"
				case 'md': return width
				case 'lg': return width
				case 'xl': return width
			}
		},
        tabAccess(item){
        if(this.USER_ACCESS != "fail"){
            if(this.USER_ACCESS.find(e=>e.actions_code == item.access)){
                if(this.USER_ACCESS.find(e=>e.actions_code == item.access).actions_code == item.access)
                {
                    return true
                }

            }
            if(item.access == 'employee_for_approval'){
                return true
            }
        }
    }
    },
    watch:{

    }
  };
  </script>
  <style>
	#group-tab {
		padding: 0 !important;
	}
  </style>
