import CreditMemo from "@/views/main/modules/Accounting/CreditMemo/CreditMemoGroupComponent.vue"
import repository from "@/api/credentials";

const routes = [{
    path: "/accounting/credit-memo-china/:id",
    name: "acctcreditchina",
    component: CreditMemo,
    props: {
        origin: 2,
    },
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Credit Memo (China)',
    }
}]

export default routes;
