<template>
    <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
            <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                <span class="text-h5">IMPORT EXCEL FILE</span>
                <v-row class="m-0">
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                            text
                            icon
                            small
                            color="gray"
                            class="float-right"
                            @click="closeImportDialog()"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
        <v-card-text style="height: 70px;">
            <v-container fluid class="pb-0">
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-file-input
                            v-model="form.import_excel"
                            label="Import Excel File"
                            dense
                            outlined
                            accept=".xlsx"
                            form-control
                        ></v-file-input>
                    </v-col>
                    <v-col>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon size="32" color="#f69f1a" v-on="on" @click="downloadCoAImportTemplate()" style="cursor: pointer;">mdi-download</v-icon>
                            </template>
                            <span>Import Template</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="text-center">
                <v-row class="ma-1 xy-scroll">
                    <v-col cols="12" >
                        <v-row >
                            <v-col cols="12" sm="12">
                                <v-btn
                                    class="float-center"
                                    @click="ImportExcel()"
                                    :disabled="!form.import_excel"
                                    :loading="importing"
                                >
                                    IMPORT
                                    <!-- <span v-if="!importing">IMPORT</span>
                                    <v-progress-circular
                                        v-else
                                        indeterminate
                                        color="primary"
                                        size="24"
                                    ></v-progress-circular> -->
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-form>
</template>
<script>
import Swal from "sweetalert2";
import * as XLSX from 'xlsx';
import { mapGetters } from "vuex";
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
export default {
    mixins: [
        SharedFunctionsComponentVue
    ],
    inject: ['theme'],
    data() {
        return {
            overlay:false,
            valid:true,
            form: {
                code:'',
                name:'',
                account_type_id:'',
                parent_id:0,
                import_excel:null
            },
            headers:[
                { text: "Child Account Code", align: "left", value: "id",sortable:false },
                { text: "Child Account", align: "left", value: "created_at",sortable:false },
                { text: "Account Type", align: "left", value: "user.name",sortable:false },
            ],
            loading:false,
            action:'',
            disabled_view: false,
            URL:process.env.VUE_APP_API_HOST,
            noImage:"images/noimage.png",
            child_dialog:false,
            action:'',
            importing: false,
        };
    },
    components: {},
    computed: {
        ...mapGetters([
            "ACTION",
            "DIALOGS",
            "USER_ACCESS",
            "GET_ACCOUNT_TYPES_SELECTION",
            "GET_CHART_OF_ACCOUNTS_DISPATCH",
            "GET_CHART_OF_ACCOUNT_PARENT_ID",
            "GET_NEW_CHART_OF_ACCOUNTS_TABLE",
            "GET_COA_IMPORT_DIALOG",
            "GET_PV_COMPANIES_SELECTION",
            "GET_PV_STORE_BRANCHES_SELECTION",
        ]),
        actions() {
            if (this.ACTION == "Submit") {
                this.disabled_view = false;
                this.action = this.GET_CHART_OF_ACCOUNTS_DISPATCH.add
                return "Create";
            } else if (this.ACTION == "Update") {
                this.disabled_view = false;
                this.action = this.GET_CHART_OF_ACCOUNTS_DISPATCH.update
                return 'Update';
            } else if (this.ACTION == "View") {
                this.disabled_view = true;
                return "View";
            }
        },
    },
    mounted() {},
    methods: {
        closeImportDialog(){
            this.$store.commit('COA_IMPORT_DIALOG',false);
            this.resetfields();
            this.$store.commit("ACTION", '');
        },
        createOrUpdate(){
            this.form.parent_id = this.GET_CHART_OF_ACCOUNT_PARENT_ID
            this.$store.dispatch(this.action,this.form);
        },
        resetfields(){
            this.form.import_excel = null;
        },
        ImportExcel() {
            // if (!this.form.import_excel) {
            //     Swal.fire('', "No File Inserted", "error");
            //     return;
            // }
            this.importing = true;
            const fileReader = new FileReader();
            const config = {
                headers: {
                "content-type": "multipart/form-data",
                },
            };

            fileReader.onload = (e) => {
                try {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: 'array' });

                    const worksheetName = 'COA';
                    const worksheet = workbook.Sheets[worksheetName];
                    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                    const headers = jsonData[0];
                    const dataRows = jsonData.slice(1);

                    if (workbook.SheetNames[0] == 'COA') {
                        const headerConstants = {
                            MOTHER_ACCOUNT_CODE: 'Mother Account Code',
                            MOTHER_ACCOUNT: 'Mother Account',
                            CHILD_ACCOUNT_CODE: 'Child Account Code',
                            CHILD_ACCOUNT: 'Child Account',
                            CONTRA_ACCOUNT_CODE: 'Contra Account Code',
                            CONTRA_ACCOUNT: 'Contra Account',
                            ACCOUNT_TYPE: 'Account Type',
                            FUNDAMENTAL: 'Fundamental',
                            ACCOUNT_GROUP: 'Account Group',
                            COMPANIES: 'Companies',
                            BRANCHES: 'Branches',
                        };

                        const motherAccountCodeIndex = headers.indexOf(headerConstants.MOTHER_ACCOUNT_CODE);
                        const motherAccountIndex = headers.indexOf(headerConstants.MOTHER_ACCOUNT);
                        const childAccountCodeIndex = headers.indexOf(headerConstants.CHILD_ACCOUNT_CODE);
                        const childAccountIndex = headers.indexOf(headerConstants.CHILD_ACCOUNT);
                        const contraAccountCodeIndex = headers.indexOf(headerConstants.CONTRA_ACCOUNT_CODE);
                        const contraAccountIndex = headers.indexOf(headerConstants.CONTRA_ACCOUNT);
                        const accountTypeIndex = headers.indexOf(headerConstants.ACCOUNT_TYPE);
                        const fundamentalIndex = headers.indexOf(headerConstants.FUNDAMENTAL);
                        const accountGroupIndex = headers.indexOf(headerConstants.ACCOUNT_GROUP);
                        const companiesIndex = headers.indexOf(headerConstants.COMPANIES);
                        const branchesIndex = headers.indexOf(headerConstants.BRANCHES);


                        const items = dataRows.map((row) => ({
                            mother_account_code: row[motherAccountCodeIndex],
                            mother_account: row[motherAccountIndex],
                            child_account_code: row[childAccountCodeIndex],
                            child_account: row[childAccountIndex],
                            contra_account_code: row[contraAccountCodeIndex],
                            contra_account: row[contraAccountIndex],
                            account_type: row[accountTypeIndex],
                            fundamental: row[fundamentalIndex],
                            account_group: row[accountGroupIndex],
                            companies: row[companiesIndex],
                            branches: row[branchesIndex],
                        }));

                        this.$store.dispatch('coaImport', { data: items }, config)
                            .then(() => {
                                this.importing = false;
                            })
                            .catch((error) => {
                                console.error('Error importing Excel:', error);
                                this.importing = false;
                            });

                    } else {
                        this.importing = false;
                        Swal.fire('', "Sheet Name is incorrect!", "warning");
                    }

                } catch (error) {
                    console.error('Error parsing Excel file:', error);
                    this.importing = false;
                }
            };

            fileReader.readAsArrayBuffer(this.form.import_excel);
        },
        async downloadCoAImportTemplate(){
            // this.loading_new = true;
                try{

                    const workbook = new this.$exceljs.Workbook();

                    const sheetNames = [
                        'COA',
                        'Company (Guide)',
                        'Store Branch (Guide)',
                    ];

                    sheetNames.forEach((sheetName) => {
                        const worksheet = workbook.addWorksheet(sheetName);
                        if (sheetName === 'COA') {
                            const headerRow = worksheet.addRow([
                                'Child Account Code',
                                'Child Account',
                                'Contra Account Code',
                                'Contra Account',
                                'Account Type',
                                'Fundamental',
                                'Companies',
                                'Store Branches',
                            ]);

                            headerRow.eachCell((cell) => {
                                cell.font = { bold: true, color: { argb: 'FFFFFFFF' }, underline: true };
                            });
                            for (let i = 1; i <= 8; i++) {
                                headerRow.getCell(i).fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFED7D31' },
                                };
                            }

                        }
                        if (sheetName === 'Company (Guide)') {
                            const headerRow = worksheet.addRow(['', 'USE THIS'],[
                                'CODE',
                                'NAME'
                            ]);
                            headerRow.getCell(2).font = { italic: true, color: { argb: 'FFFF0000' } };

                            let companies = this.GET_PV_COMPANIES_SELECTION;
                            companies.forEach((company) => {
                                worksheet.addRow([company.code,company.text]);
                            });
                        } else if (sheetName === 'Store Branch (Guide)') {
                            const headerRow = worksheet.addRow(['','','USE THIS',''],[
                                'COMPANY CODE',
                                'COMPANY NAME',
                                'STORE BRANCH CODE',
                                'STORE BRANCH NAME'
                            ]);
                            headerRow.getCell(3).font = { italic: true, color: { argb: 'FFFF0000' } };

                            let storeBranches = this.GET_PV_STORE_BRANCHES_SELECTION;
                            storeBranches.forEach((storeBranch) => {
                                let companyData = this.GET_PV_COMPANIES_SELECTION.find(e=>e.value == storeBranch.company_id)
                                if (companyData != undefined) {
                                    worksheet.addRow([
                                        companyData.code,
                                        companyData.text,
                                        storeBranch.code,
                                        storeBranch.text
                                    ]);
                                }
                            });
                        }
                    });

                    const buffer = await workbook.xlsx.writeBuffer();
                    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = 'COA (Template).xlsx';

                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(link.href);
                    this.loading_new = false;
                } catch (error) {
                    console.error(error);
                    Swal.fire('', 'Error downloading the file', 'error');
                }
        },
    },
    watch: {
        GET_NEW_CHART_OF_ACCOUNTS_TABLE:{
            handler(val){
                if(val){
                    Swal.fire({
                        title: val.message.title,
                        text: val.message.msg,
                        icon: val.message.icon,
                        confirmButtonText: 'OK'
                    });
                    this.$store.dispatch('getAllChartOfAccounts');
                    this.closeImportDialog();
                }
            }
        },
    },
};
</script>
<style scoped>
.xy-scroll {
    overflow-x: hidden;
    overflow-y: hidden;
}
</style>
