<template>
    <v-container fluid>
        <v-card-title>
            <v-row>
                <v-col class="d-flex no-block align-items-center">
                    <h4 class="page-title">REPORT MODULE</h4>
                </v-col>
            </v-row>
        </v-card-title>
        <v-container>
            <v-row>
                <v-tabs v-model="tab" color="cyan" dark slider-color="cyan" background-color="#424242" show-arrows>
                    <v-tabs-slider color="cyan"></v-tabs-slider>
                    <v-tab ripple v-for="(item, i) in items" :key="item" @click="tabs(i)">
                        <span> {{ item }}</span>
                    </v-tab>
                </v-tabs>
            </v-row>
        </v-container>
        <v-container class="ma-0 pa-0">
            <v-tabs-items v-model="tab">
                <v-tab-item v-for="(table, index) in component_report" :key="index">
                    <keep-alive>
                        <component :is="table.components" :component_report="component_report"></component>
                    </keep-alive>
                </v-tab-item>
            </v-tabs-items>
        </v-container>
        <dialogs :cp="components" :scrollable="true" :width="dialog_width"></dialogs>
    </v-container>
</template>

<script>
import report from './tables/ReportTableComponent.vue'
import reportCategory from './tables/ReportCategoryTableComponent.vue'
import Dialogs from '../../layouts/dialogs/Dialog.vue'
import ReportModuleComponent from '../../layouts/dialogs/Masterdata/ReportModuleComponent.vue'
import ReportCategoryComponent from '../../layouts/dialogs/Masterdata/ReportCategoryComponent.vue'

export default {
    components: {
        report,
        reportCategory,
        Dialogs,
        ReportModuleComponent,
        ReportCategoryComponent
    },
    data() {
        return {
            components: '',
            report: ReportModuleComponent,
            reportCategory: ReportCategoryComponent,

            tab: null,
            items: [
                'REPORT',
                'REPORT CATEGORY',
            ],
            component_report: [
                {
                    components: report,
                },
                {
                    components: reportCategory,
                },
            ],
            dialogType: '',
            dialog_width: ''
        };
    },
    mounted() {
        this.tabs(0);
        this.$store.commit('LINK_ACTIVE', window.location.pathname.split("/")[3])
    },
    methods: {
        tabs(key) {
            if (key == 0) {
                this.components = this.report;
                let width = '100%'
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs', 'sm':
                        width = '100%'
                        break
                    case 'md', 'lg', 'xl':
                        width = '40%'
                        break
                    default:
                        break
                }

                this.dialog_width = width
            } else if (key == 1) {
                this.components = this.reportCategory;
                let width = '100%'
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs', 'sm':
                        width = '100%'
                        break
                    case 'md', 'lg', 'xl':
                        width = '40%'
                        break
                    default:
                        break
                }

                this.dialog_width = width
            }
        },
    },

    watch: {
        'tab': {
            handler(val) {
                if(val == 0) {
                    this.$store.commit('REFRESH_REPORT', true)
                } else {
                    this.$store.commit('REFRESH_CATEGORY_REPORT', true)
                }
            }
        }
    }

}
</script>

<style>

</style>