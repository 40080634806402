<template>
    <div class="w-100">
      <v-app id="my_vue_app">
        <v-card>
          <v-card-title>
              <v-container fluid>
                <v-row>
                  <v-col cols="12" sm="8" class="d-flex justify-content-start">
                    <v-row>
                     
                   <v-col cols="12" lg="8" class="d-flex justify-content-between">
                    <v-container fluid class="d-flex justify-content-between">
                      <!-- <v-autocomplete
                      v-model="vendor"
                      :items="GET_ADMIN_SUPPLIER_SELECTION"
                      item-text="text"
                      item-value="value"
                      auto-select-first
                      outlined
                      label="Vendor"
                      required
                      small-chips
                      deletable-chips
                      multiple
                      dense
                    ></v-autocomplete>
                    <v-autocomplete
                      v-model="category_id"
                      :items="GET_ADMIN_CLASSIFICATION_SELECTION"
                      :search-input.sync="search_classification"
                      item-text="text"
                      item-value="value"
                      auto-select-first
                      outlined
                      label="Category"
                      required
                      dense
                    ></v-autocomplete>
                    <v-autocomplete
                      v-model="sub_category_id"
                      :items="subClass"
                      item-text="text"
                      item-value="value"
                      auto-select-first
                      outlined
                      label="Sub Category"
                      required
                      dense
                    ></v-autocomplete> -->
                  </v-container>
                  </v-col>
              
                  <!-- <v-col cols="12" md="1">
                    <v-btn class="mt-3" @click="getAllAdminFasItem()">FILTER</v-btn>
                  </v-col> -->
                  </v-row>
                </v-col>
                <v-col cols="12" md="4">
                  <div class="row col-md-12 align-items-end">
                  <v-text-field
                      id="searchBar"
                      v-model="filter.search"
                      label="Search"
                      single-line
                      hide-details
                      @change="searchInput()"
                      @keydown.enter="searchInput()"
                  >
                  </v-text-field>
                  <v-icon @click="searchInput()">mdi-magnify</v-icon>
                </div>
                </v-col>
                </v-row>
     
            </v-container>
          </v-card-title>
          <v-container fluid>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :options.sync="options"
              :server-items-length="totalItems"
              :items-per-page="10"
              :loading="!loaded"
              :footer-props="{
                      itemsPerPageOptions: itemsPerPageOptions,
              }"
              loading-text="Loading... Please wait"
              fixed-header
            >
            <!-- <template v-slot:[`item.uom_id`]="{ item }">
                <span>{{GET_UOM_SELECTION.find(e=>e.value == item.uom_id).text}}</span>
            </template> -->
            <!-- <template v-slot:[`item.price`]="{ item }">
                <span>&#x20b1;{{item.price | currency}}</span>
            </template> -->

              <template v-slot:[`item.action`]="{ item }">
                <v-btn text icon color="orange" v-if="userAccess.view">
                  <v-icon class="btn-action" small @click="ViewItem(item,'View')"
                    >mdi-eye</v-icon
                  >
                </v-btn>
                <v-btn text icon color="blue" v-if="userAccess.edit">
                  <v-icon class="btn-action" small @click="ViewItem(item,'Update')"
                    >mdi-square-edit-outline</v-icon
                  >
                </v-btn>
                <v-btn text icon color="red" v-if="userAccess.delete">
                  <v-icon class="btn-action" small @click="DeleteItem(item.id)"
                    >mdi-delete</v-icon
                  >
                </v-btn>
              </template>
            </v-data-table>
          </v-container>
        </v-card>
      </v-app>
    </div>
  </template>
  <script>
  import swal from "sweetalert2";
  import { mapGetters } from 'vuex';
  export default {
    data() {
      return {
        loaded: false,
        vendor:[],
        category_id:'',
        sub_category_id:'',
        totalItems: 0,
        options: {},
        items: [],
        itemsPerPageOptions: [5, 10, 15, 50, 100],
        itemsPerPage: 10,
        search: "",
        filter:{
          search:"",
          location:['headoffice','stores']
        },
        form:{},
        url:process.env.VUE_APP_API_HOST,
        awaitingSearch: false,
        search_classification:'',
        subClass:[],
        headers: [
          // { text: 'Image', align: 'left',value:'image_path' },
          { text: "Code", align: "left", value: "code" },
          { text: "Name", align: "left", value: "name" },
          // { text: "UOM", align: "left", value: "uom_id" },
          // { text: "Quantity", align: "left", value: "quantity" },
          // { text: "Price", align: "left", value: "price" },
          // { text: "Remarks", align: "left", value: "remarks" },
          { text: "Action", align: "center", value: "action",sortable:false},
        ],
        userAccess: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      };
    },
    computed:{
      ...mapGetters(['USER_ACCESS','GET_ADMIN_CLASSIFICATION_SELECTION','GET_ADMIN_FASITEM_DISPATCH','GET_UOM_SELECTION','GET_NEW_ADMIN_FASITEM','GET_ADMIN_FASITEM_STATUS'])
    },
    mounted() {
      this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
    },
    methods: {
      getAllAdminFasItem(filter = null) {
        this.items = [];
        this.loaded = false;
        let payload = {
          page: this.options.page,
          vendor:this.vendor,
          category_id:this.category_id,
          sub_category_id:this.sub_category_id,
          itemsPerPage: this.options.itemsPerPage,
          search: this.filter.search,
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          location:this.filter.location,
          active:this.GET_ADMIN_FASITEM_STATUS,
          filter: filter,
        };
        
        this.$store.dispatch(this.GET_ADMIN_FASITEM_DISPATCH.get, payload).then((response) => {
          this.items = response.data.data.data;
          this.loaded = true;
          this.totalItems = response.data.data.total;
        }).catch((e)=>{
          this.loaded = true;
        });
      },
      searchInput(){
          this.getAllAdminFasItem({ query: this.search });
      },  
      ViewItem(item,action) {
        this.$store.commit('ADD_FAS_TYPE','from_add');
        this.$store.commit("EDIT_ADMIN_FASITEMS", item);
        this.$store.commit("DIALOG", true);
        this.$store.commit("ACTION", action);
      },
      DeleteItem(id) {
        swal
          .fire({
            icon: 'question',
            text: "Are you sure you want to Delete?",
            cancelButtonText: "Cancel",
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonColor: "#397373",
            cancelButtonColor: "#397373",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$store
                .dispatch(this.GET_ADMIN_FASITEM_DISPATCH.delete, id)
                .then((success) => {});
            }
          });
      },
    },
    watch: {
      search_classification: {
      handler(val) {
        this.GET_ADMIN_CLASSIFICATION_SELECTION.filter((e) => {
          if (e.text == val) {
            this.subClass = e.sub_class;
          }
        });
      },
    },
      options: {
        handler() {
          this.getAllAdminFasItem();
        },
        deep: true,
      },
      GET_NEW_ADMIN_FASITEM:{
          handler(val){
              if(val){
                this.getAllAdminFasItem();
              }
          }
      },  
      USER_ACCESS: {
        handler(val) {
          if (val != "fail") {
            this.userAccess.edit = false;
            this.userAccess.delete = false;
            this.userAccess.view = false;
            this.userAccess.create = false;
            val.forEach((e) => {
              if (e.actions_code == "edit") {
                this.userAccess.edit = true;
              } else if (e.actions_code == "delete") {
                this.userAccess.delete = true;
              } else if (e.actions_code == "view") {
                this.userAccess.view = true;
              } else if (e.actions_code == "create") {
                this.userAccess.create = true;
              }
            });
          }
        },
      },
    },
  };
  </script>