<template>
<div class="w-100">
    <v-app id="load_bar" v-if="!loaded">
        <v-text-field color="info" loading disabled></v-text-field>
    </v-app>

    <v-row v-if="loaded" class="pa-4 ">
        <v-col cols="6" class="d-flex no-block align-items-center">
            <v-spacer />
        </v-col>
        <v-col cols="6" align="right">
            <v-btn :loading="exportLoading" class="mr-1 mb-2" @click="exportStockcardsToExel()">Export</v-btn>
        </v-col>
    </v-row>

    <v-app id="my_vue_app">
        <v-card v-show="loaded">
            <span class="pa-3">
                <v-row class="d-flex justify-end align-center mr-3">
                    <a href="#" class="ml-1 badge badge-all" @click="selected_status = -1; getAllStockCards();">all</a>
                    <a href="#" class="ml-1 badge badge-preparing" @click="selected_status = 0; getAllStockCards();"> preparing </a>
                    <a href="#" style="color: white" class="ml-1 badge badge-primary" @click="selected_status = 1; getAllStockCards();"> prepared </a>
                    <!-- <a href="#" style="color: white" class="ml-1 badge badge-warning" @click="selected_status = 2; getAllStockCards();"> rejected </a> -->
                    <a href="#" style="color: white" class="ml-1 badge badge-danger" @click="selected_status = 3; getAllStockCards();"> canceled </a>
                    <!-- <a href="#" style="color: white" class="ml-1 badge badge-dark" @click="selected_status = 4; getAllStockCards();"> deleted </a> -->
                    <a href="#" style="color: white" class="ml-1 badge badge-success" @click="selected_status = 5; getAllStockCards();"> completed </a>
                </v-row>
            </span>

            <v-row class="pl-3 mx-0" dense>
                <v-col cols="3">
                    <v-autocomplete
                        :items="GET_CUSTOMERS_LIST_SELECTION"
                        outlined
                        dense
                        item-value="value"
                        item-text="text"
                        v-model="customer_id"
                        label="Customer"
                        clearable
                        auto-select-first
                    >
                    </v-autocomplete>
                </v-col>
                <div class="col-sm-12 col-md-1">
                    <v-btn @click="getAllStockCards()">Filter</v-btn>
                </div>
                <v-spacer></v-spacer>
                <v-col sm="12" md="12" lg="4" class="d-flex align-center">
                    <v-col class="mr-2">
                        <v-text-field
                            v-model="search"
                            label="Search PL# / SC#/ SO#"
                            clearable
                            single-line
                            hide-details
                            @keydown.enter="searchInput()"
                            @click:clear="search='';searchInput()"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col class="mr=3">
                        <v-text-field
                            v-model="searchItem"
                            label="Search Item Model / Name"
                            append-outer-icon="mdi-magnify"
                            single-line
                            hide-details
                            clearable
                            @keydown.enter="searchInput()"
                            @click:append-outer="searchInput()"
                            @click:clear="searchItem='';searchInput()"
                        >
                        </v-text-field>
                    </v-col>
                </v-col>
            </v-row>

            <v-row class="pl-3 mx-0" dense>
                <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                    <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="date_range[0]"
                                v-on="on"
                                label="Date From"
                                append-icon="mdi-calendar-clock"
                                dense
                                outlined
                                hide-details
                                readonly
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="date_range[0]" @input="menu = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                    <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="date_range[1]"
                                v-on="on"
                                label="Date To"
                                append-icon="mdi-calendar-clock"
                                dense
                                outlined
                                hide-details
                                readonly
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="date_range[1]" @input="menu2 = false"></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>

            <v-container fluid>
                <v-data-table
                    :headers="headers"
                    :items="stockCards"
                    :search="search"
                    :options.sync="options"
                    :server-items-length="totalStockCards"
                    :items-per-page="10"
                    :footer-props="{
                        itemsPerPageOptions: itemsPerPageOptions
                    }"
                    class="w-100"
                >

                <template v-slot:[`item.customer_name`]="{ item }">
                    <span style="font-size:10px !important;">
                        {{ item.customer_name  }}
                    </span>
                </template>

                <template v-slot:[`item.total_amount`]="{ item }">
                    <span class="text-nowrap">
                        &#x20b1;{{ item.total_amount | currency }}
                    </span>
                </template>

                <template v-slot:[`item.created_at`]="{ item }">
                    <span class="text-nowrap">
                        {{ item.created_at | formatDateTime }}
                    </span>
                </template>

                <template v-slot:[`item.printed`]="{ item }">
                    <span class="badge badge-primary" v-if="item.printed">Yes</span>
                    <span class="badge badge-danger" v-else>No</span>
                </template>

                <template v-slot:[`item.status_text`]="{ item }">
                    <span class="badge badge-preparing" v-if="item.status_text=='preparing'">{{ item.status_text }}</span>
                    <span class="badge badge-primary" v-else-if="item.status_text=='prepared'">{{ item.status_text }}</span>
                    <span class="badge badge-warning" v-else-if="item.status_text=='rejected'">{{ item.status_text }}</span>
                    <span class="badge badge-danger" v-else-if="item.status_text=='canceled'">{{ item.status_text }}</span>
                    <span class="badge badge-dark" v-else-if="item.status_text=='deleted'">{{ item.status_text }}</span>
                    <span class="badge badge-success" v-else-if="item.status_text=='completed'">{{ item.status_text }}</span>
                </template>

                <template v-slot:[`item.action`]="{ item }">
                    <span class="text-nowrap" >
        <!-- <buttons
          v-if="userAccess.view"
          :action="buttons.actions.view"
          :button_icon="buttons.icons.view"
          :color="buttons.colors.view"
          :button_text="buttons.type.text"
          :icon="buttons.type.icon"
          @click="viewStockCard(item.id)"
        ></buttons>
        <buttons
          v-if="userAccess.edit && item.status == 0"
          :action="buttons.actions.edit"
          :button_icon="buttons.icons.edit"
          :color="buttons.colors.edit"
          :button_text="buttons.type.text"
          :icon="buttons.type.icon"
          @click="editStockCard(item.id)"
        ></buttons>
        <buttons
          v-if="userAccess.delete"
          :action="buttons.actions.delete"
          :button_icon="buttons.icons.delete"
          :color="buttons.colors.delete"
          :button_text="buttons.type.text"
          :icon="buttons.type.icon"
          @click="deleteStockCard(item.id)"
        ></buttons> -->
        <v-btn text icon color="orange" v-if="userAccess.view" >
            <v-icon class="btn-action" small @click="viewStockCard(item.id)">mdi-eye</v-icon>
        </v-btn>
        <v-btn text icon color="blue" v-if="userAccess.edit && item.status == 0" >
            <v-icon class="btn-action" small @click="editStockCard(item.id)">mdi-pencil</v-icon>
        </v-btn>
        <v-btn text icon color="red" v-if="userAccess.delete && ![1, 3, 5].includes(item.status) ">
            <v-icon class="btn-action" small @click="deleteStockCard(item.id)">mdi-cancel</v-icon>
        </v-btn>
                    </span>
                </template>

                </v-data-table>
            </v-container>
        </v-card>
    </v-app>
  <!-- VIEW MODAL-->
  <stockcardviewcomponent  :viewStockCardId="viewStockCardId" @closeView="closeView"></stockcardviewcomponent >
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import buttons from "@/views/main/layouts/Buttons/DialogButton.vue";
import buttons_components from "@/assets/js/components-js";
import stockcardviewcomponent from '@/views/main/modules/Operations/StockCards/StockCardViewComponent.vue'
import swal from 'sweetalert2'

export default {

    data () {
        return {
            actionResult : {
                model : 'StockCards',
                action : ''
            },
            // buttons: buttons_components.buttons,
            loaded: false,
            totalStockCards: 0,
            options: {},
            stockCards: [],
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            itemsPerPage: 10,
            search: '',
            awaitingSearch: false,
            headers: [
                { text: 'SC#', align: 'left', value: 'stock_card_num', },
                { text: 'Warehouse', align: 'left', value: 'warehouse_name' },
                { text: 'PL#', align: 'left', value: 'packing_list_num', },
                { text: 'SO#', align: 'left', value: 'order_num', },
                { text: 'Customer', align: 'left', value: 'customer_name', },
                { text: 'Status', align: 'left', value: 'status_text', },
                { text: 'Date Issued', align: 'left', value: 'created_at', },
                { text: 'Issuer', align: 'left', value: 'issuer_name', },
                { text: 'Date Completed', align: 'left', value: 'complete_date', },
                { text: 'Completed By', align: 'left', value: 'completed_by_name', },
                { text: 'Printed', align: 'left', value: 'printed', },
                { text: 'Actions', align: 'center', value: 'action', sortable: false },
            ],

            viewStockCardId: '',

            json_data: [],
            json_meta: [
                [
                    {
                        'key': 'charset',
                        'value': 'utf-8'
                    }
                ]
            ],
            userAccess: {
                view: false,
                edit: false,
                delete: false,
            },
            customer_id: '',
            exportLoading: false,
            selected_status: -1,
            searchItem:'',
            date_range: [
                this.$dayjs().startOf("year").format("YYYY-MM-DD"),
                this.$dayjs().endOf("month").format("YYYY-MM-DD"),
            ],
            menu: false,
            menu2: false,
        }
    },
    components: {
        // buttons,
        stockcardviewcomponent,
        // buttons_components,
    },
    props: [
        'stockCardsActionResult',
        'unconfirmed',
    ],
    computed:{
        ...mapGetters(['USER_ACCESS','GET_CUSTOMERS_LIST_SELECTION'])
    },
    methods:{
        getAllStockCards(){
            this.loaded = false
            let  params = {
                    method:'post',
                    url:'inventory/stockCards/getAll',
                    page:           this.options.page,
                    itemsPerPage:   this.options.itemsPerPage,
                    sortBy:         this.options.sortBy,
                    sortDesc:       this.options.sortDesc,
                    search:         this.search,
                    unconfirmed:    this.unconfirmed,
                    customer_id:    this.customer_id,
                    status_id:    this.selected_status,
                    searchItem:     this.searchItem,
                    date_range:     this.date_range,
                }
            this.$store.dispatch('StockCardsGet',params).then(response => {
                this.stockCards        = response.data.data
                this.loaded            = true
                this.totalStockCards   = response.data.total
            })
        },
        viewStockCard(id){
            this.viewStockCardId = id
            this.$store.dispatch('getAllwarehousepersonnelPreparer');
            this.$store.dispatch('getAllwarehousepersonnelChecker');
        },
        editStockCard(id){
            this.$emit('showEditModal', id);
            this.$store.dispatch('getAllwarehousepersonnelPreparer');
            this.$store.dispatch('getAllwarehousepersonnelChecker');
        },
        deleteStockCard(id){
            //console.log('delete: ' + id)
            swal.fire({
                title: "",
                text: "Are you sure you want to Cancel?",
                icon: 'question',
                showConfirmButton:true,
                showCancelButton:true,
                reverseButtons:true
            })
            .then((willDelete) => {
                if (willDelete.isConfirmed) {
                    let  params = {
                    url:`inventory/stockCards/${id}`
                }
                 this.$store.dispatch('StockCardsDelete',params).then(response => {
                 this.getAllStockCards()
                 swal.fire(response.data, {icon: "success",});
            }).catch(error => {
                        // console.log(error)
                        swal.fire("An Error Occured", error, "error");
                    })

                }
            });
        },
        closeView(action = false){
            this.viewStockCardId = 0
            if(action == 'convertSuccess' || action == 'updateStatusSuccess'){
                this.getAllStockCards()
            }
        },
        searchInput(){
            this.getAllStockCards({ page:this.options.page = 1, query: this.search });
        },
        addKeyListener(){
            window.addEventListener("keyup", function(e) {
                if(e.keyCode == 13){
                    this.searchInput();
                    $("input").blur();

                }
            }.bind(this));
        },
        exportStockcardsToExel() {
            this.exportLoading = true;
            let params = {
                status:this.selected_status,
                url: "inventory/stock-cards/exports"
            }

            this.$store.dispatch('StockCardsGet',params).then(response=>{
                let workbook = new this.$exceljs.Workbook()
                let worksheet = workbook.addWorksheet('Stock Cards')
                worksheet.columns = [
                        { header: 'SC Num', key: 'stock_card_num' },
                        { header: 'Status', key: 'status_text' },
                        { header: 'Company', key: 'company', width:60 },
                        { header: 'Model', key: 'model', width:16 },
                        { header: 'Name', key: 'name', width:34 },
                        { header: 'UOM', key: 'uom' },
                        { header: 'Category', key: 'category', width:18 },
                        { header: 'Type', key: 'type' },
                        { header: 'Remarks', key: 'remarks' , width:24},
                        { header: 'PL Num', key: 'pl_num' },
                        { header: 'PL Date', key: 'pl_date', width:12},
                        { header: 'SO#', key: 'so#' },
                        { header: 'SO Date', key: 'so_date', width:12 },
                        { header: 'Qty', key: 'qty' },
                        { header: 'Qty SERVED', key: 'qty_served', width:12},
                        { header: 'Qty REMAINING', key: 'qty_remaining', width:16},
                    ];

                let headerRow = worksheet.getRow(1);
                    headerRow.font = { bold:true }
                    for (let c=1;c<=worksheet.columns.length;c++) {
                        let currentCell = headerRow.getCell(c);
                        currentCell.fill = {
                            type: 'pattern',
                            pattern:'solid',
                            fgColor:{argb:'F69F1A'},
                        }
                    }
                worksheet.addRows(response.data)
                workbook.xlsx.writeBuffer().then((data) => {
                    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                    this.$filesaver.saveAs(blob, 'Stock Cards.xlsx');
                    this.exportLoading = false;
                })

            }).catch(error => {
                console.log(error)
                this.exportLoading = false;
            });
        }
    },
    mounted: function (){
        this.$store.dispatch('getAllCustomers')
        // this.addKeyListener()
        $('input').attr('autocomplete','off');
    },
    watch: {
        USER_ACCESS: {
         handler(val) {
          if(val != "fail"){
           this.userAccess.view = false;
           this.userAccess.edit = false;
           this.userAccess.view = false;
             val.forEach(e => {
            if (e.actions_code == 'view') {
                 this.userAccess.view = true;
            }else if(e.actions_code == 'edit'){
                this.userAccess.edit = true;
            }else if(e.actions_code == 'delete'){
                this.userAccess.delete = true;
            }
        });
        }
      },
    },
        stockCardsActionResult: function(val){
            if(this.stockCardsActionResult=='success'){
                this.getAllStockCards()
            }
        },
        options: {
            handler () {
                this.getAllStockCards()
            },
            deep: true,
        },
        'customer_id':{
            handler(val){
                this.getAllStockCards();
            }
        }
        /*search: {
            handler () {
            if (!this.awaitingSearch) {
                setTimeout(() => {
                    this.getAllStockCards({ query: this.search });
                    this.awaitingSearch = false;
                }, 2000); // 1 sec delay
            }
                this.awaitingSearch = true;
            },
            deep: true,
        },*/
    }
};
</script>
