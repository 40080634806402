<template>
    <v-card>
        <v-card-text>
            <v-row no-gutters>
                <v-col cols="2">

                    <v-autocomplete
                        v-model="dataTable.filters.department_id"
                        label="Department"
                        :items="GET_LIST_DEPARTMENT"
                        item-text="text"
                        item-value="value"
                        outlined
                        required
                        dense
                    ></v-autocomplete>
                </v-col>
                <v-col cols="2">
                    <v-autocomplete
                        v-model="dataTable.filters.employee_data_id"
                        label="Employee"
                        :items="employeeSelectionData"
                        item-text="text"
                        item-value="value"
                        outlined
                        required
                        dense
                    ></v-autocomplete>
                </v-col>
                <v-col cols="2">
                    <v-menu
                        v-model="dataTable.filters.menu_month_date_from"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="dataTable.filters.request_date_from"
                                prepend-inner-icon="mdi-calendar"
                                outlined
                                dense
                                readonly
                                v-on="on"
                                style="display: block"
                                background-color="white"
                                label="Date From"
                                clearable
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="dataTable.filters.request_date_from"
                            @input="dataTable.filters.menu_month_date_from = false"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="2">
                    <v-menu
                        v-model="dataTable.filters.menu_month_date_to"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="dataTable.filters.request_date_to"
                                prepend-inner-icon="mdi-calendar"
                                outlined
                                dense
                                readonly
                                v-on="on"
                                style="display: block"
                                background-color="white"
                                label="Date To"
                                clearable
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="dataTable.filters.request_date_to"
                            @input="dataTable.filters.menu_month_date_to = false"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="2" class="pt-2">
                    <v-text-field
                        v-model="dataTable.search_key"
                        label="Search Doc #"
                        dense
                        clearable
                        append-icon="mdi-magnify"
                        @click:append="clearDataTableSearchKey()"
                        @keydown.enter="clearDataTableSearchKey()"
                        >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-data-table
                :headers="dataTable.headers"
                :items="GET_ADMIN_ASSET_TURNOVERS"
                :options.sync="dataTable.options"
                :server-items-length="dataTable.total_items"
                :loading="dataTable.loading"
                disable-sort
                @update:page="getAssetTurnovers()"
                @update:items-per-page="getAssetTurnovers()"
            >
                <template v-slot:[`item.status`]="{ item }">
                    <a
                        class="ml-1 badge text-white"
                        :style="`background-color:${STATUS.ADMIN_ASSET_TURNOVER_STATUS.find(e => e.value === item.status)?.color}`"
                    >{{ STATUS.ADMIN_ASSET_TURNOVER_STATUS.find(e => e.value === item.status)?.text }}</a>
                </template>
                <template v-slot:[`item.ldrf`]="{ item }" >
                    <v-badge dot :color="item.ldrf == 0 ? '#ef5350' : 'success'"></v-badge>
                </template>
                <template v-slot:[`item.repairable`]="{ item }" >
                    <v-badge dot :color="item.repairable == 0 ? '#ef5350' : 'success'"></v-badge>
                </template>
                <template v-slot:[`item.created_at`]="{ item }">
                    {{ formattedDate(new Date(item.created_at)) }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-btn
                        v-if="accessCodes.includes('asset_turnover_view')"
                        color="orange"
                        text
                        icon
                        @click="viewOrUpdateAssetTurnover(item.id)"
                    ><v-icon small>mdi-eye</v-icon></v-btn>
                    <v-btn
                        v-if="accessCodes.includes('asset_turnover_edit') && item.status == 1"
                        color="blue"
                        text
                        icon
                        @click="viewOrUpdateAssetTurnover(item.id, true)"
                    ><v-icon small>mdi-square-edit-outline</v-icon></v-btn>
                    <v-btn
                        v-if="accessCodes.includes('asset_turnover_delete') && item.status == 1"
                        color="red"
                        text
                        icon
                        @click="deleteAssetTurnover(item.id)"
                    ><v-icon small>mdi-delete</v-icon></v-btn>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";

export default {
    mixins: [SharedFunctionsComponentVue],

    data() {
        return {
            accessCodes: [],

            employee_selection_data: [{
                'value': 0,
                'text': 'GENERAL ACCOUNTABILITY',
                'department_id': 0,
            }],

            dataTable: {
                loading: false,
                total_items: 0,
                search_key: '',

                headers: [
                    { text: 'Doc #', value: 'id', align: 'center' },
                    { text: 'Department', value: 'department', align: 'center' },
                    // { text: 'Employee', value: 'employee' },
                    { text: 'LDRF', value: 'ldrf', align: 'center' },
                    { text: 'Repairable', value: 'repairable', align: 'center' },
                    { text: 'Asset Count', value: 'asset_count', align: 'center' },
                    { text: 'Status', value: 'status', align: 'center' },
                    { text: 'Date Created', value: 'created_at', align: 'center' },
                    { text: 'Actions', value: 'actions', align: 'center' },
                ],
                items: [],
                filters: {
                    department_id: undefined,
                    employee_data_id: undefined,
                    menu1:false,
                    menu2:false,
                    menu_month_date_from: false,
                    menu_month_date_to: false,
                    request_date_from: this.$dayjs().startOf('year').format('YYYY-MM-DD'),
                    request_date_to: this.$dayjs().endOf('month').format('YYYY-MM-DD'),
                },
                options: {},
            },
        };
    },

    mounted() {
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        .then(() => {
            this.accessCodes = this.USER_ACCESS.map(access => access.actions_code);
        });
        this.getAssetTurnovers();
        this.$store.dispatch('getEmployeeDataDropdown')
    },

    computed: {
        ...mapGetters([
            'USER_ACCESS',
            'STATUS',
            'GET_ADMIN_ASSET_TURNOVER_DATA_TABLE_RELOAD',
            'GET_ADMIN_ASSET_TURNOVERS',
            "GET_EMPLOYEE_DATA_SELECTION",
            "GET_LIST_DEPARTMENT",
            "GET_ADMIN_ASSET_TURNOVER_EXPORT",
        ]),
        employeeSelectionData(){
            return this.GET_EMPLOYEE_DATA_SELECTION.concat([{ 'value': 0, 'text': 'GENERAL ACCOUNTABILITY', 'department_id': 0 }])
        }
    },

    methods: {
        viewOrUpdateAssetTurnover(id, update = false) {
            this.$store.commit('ADMIN_ASSET_TURNOVER_VIEW_ID', id);
            this.$store.commit('DIALOG', true);
            this.$store.commit('ACTION', update ? 'Update' : 'View');
        },
        clearDataTableSearchKey() {
            this.dataTable.options.page = 1;
            this.getAssetTurnovers();
        },
        deleteAssetTurnover(id) {
            swal.fire({
                title: 'Warning!',
                text: 'Are you sure you want to delete this asset turn-over?',
                icon: 'question',
                cancelButtonText: "Cancel",
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#397373",
                cancelButtonColor: "grey",
                reverseButtons: true,
                showLoaderOnConfirm: true,

                preConfirm: async () => {
                    return this.$store.dispatch('adminAssetTurnoverDelete', {url: id}).then(() => {
                        this.$store.commit('ADMIN_ASSET_TURNOVER_DATA_TABLE_RELOAD', true);

                        swal.fire({
                            title: 'Delete Success',
                            icon: 'success',
                        });
                    }).catch(error => {
                        console.log(error);
                        swal.fire({
                            title: 'Delete Failed',
                            icon: 'error',
                        });
                    });
                },
            });
        },
        getAssetTurnovers() {
            this.$store.commit('ADMIN_ASSET_TURNOVERS', []);
            const payload = {
                url: 'get-admin-asset-turnovers',
                search_key: this.dataTable.search_key,
                ...this.dataTable.filters,
                ...this.dataTable.options,
            };

            this.dataTable.loading = true;
            this.$store.dispatch('adminAssetTurnoverGet', payload).then(response => {
                this.$store.commit('ADMIN_ASSET_TURNOVERS', response.data.data.data);
                this.dataTable.loading = false;
                this.dataTable.total_items = response.data.data.total;
            });
        },
        reloadDataTable() {
            this.dataTable.options.page = 1;
            this.getAssetTurnovers();
        },
        exportTurnover() {
            const payload = {
                search_key: this.dataTable.search_key,
                ...this.dataTable.filters,
            };

            this.$store.dispatch('exportTurnOvers', payload).then(response => {
                const exportSheets = {
                    'SUMMARY': {
                        headers: [
                            { header: "DOC #", key: 'id' },
                            { header: "DEPARTMENT", key: 'department' },
                            { header: "LDRF", key: 'ldrf' },
                            { header: "REPAIRABLE", key: 'repairable' },
                            { header: "ASSET COUNT", key: 'asset_count', style: { numFmt: '#,##0' }, formatAs: 'int' },
                            { header: "STATUS", key: 'status' },
                            { header: "DATE CREATED", key: 'created_at' },
                        ],
                        data: [
                            ...response.summary.map(item => {
                                item.created_at = item.created_at ? this.formatDate(item.created_at) : '';
                                item.ldrf = item.ldrf ? 'YES' : 'NO';
                                item.repairable = item.repairable ? 'YES' : 'NO';
                                item.status = this.STATUS.ADMIN_ASSET_TURNOVER_STATUS.find(e => e.value == item.status)?.text;

                                return item;
                            }),
                        ],
                    },
                    'DETAILED': {
                        headers: [
                            { header: "DOC #", key: 'id' },
                            { header: "DEPARTMENT", key: 'department' },
                            { header: "LDRF", key: 'ldrf' },
                            { header: "REPAIRABLE", key: 'repairable' },
                            { header: "ASSET COUNT", key: 'asset_count' },
                            { header: "STATUS", key: 'status' },
                            { header: "DATE CREATED", key: 'created_at' },
                            { header: "ACCOUNTABILITY #", key: 'accountability_num' },
                            { header: "ASSET CODE", key: 'asset_code' },
                            { header: "ASSET NAME", key: 'item_name' },
                            { header: "WAREHOUSE", key: 'warehouse' },
                            { header: "CONDITION", key: 'condition' },
                        ],
                        data: [
                            ...response.detailed.map(item => {
                                item.created_at = item.created_at ? this.formatDate(item.created_at) : '';
                                item.ldrf = item.ldrf ? 'YES' : 'NO';
                                item.repairable = item.repairable ? 'YES' : 'NO';
                                item.status = this.STATUS.ADMIN_ASSET_TURNOVER_STATUS.find(e => e.value == item.status)?.text;

                                return item;
                            }),
                        ],
                    },
                };
                this.exportExcel(exportSheets, `TURN OVER`,'F69F1A');
            });
        },
    },

    watch: {
        // 'GET_EMPLOYEE_DATA_SELECTION':{
        //     handler(val){
        //         console.log(val)
        //         this.employee_selection_data = val.concat([{ 'value': 0, 'text': 'GENERAL ACCOUNTABILITY', 'department_id': 0 }])
        //     }
        // },
        'GET_ADMIN_ASSET_TURNOVER_DATA_TABLE_RELOAD': {
            handler(val) {
                if (!val) return;

                this.getAssetTurnovers();
                this.$store.commit('ADMIN_ASSET_TURNOVER_DATA_TABLE_RELOAD', false);
            },
            immediate: true,
        },
        'GET_ADMIN_ASSET_TURNOVER_EXPORT': {
            handler(val) {
                if (!val) return;

                this.exportTurnover();
                this.$store.commit('ADMIN_ASSET_TURNOVER_EXPORT', false);
            },
            immediate: true,
        },
        'dataTable.filters.department_id': {
            handler(val) {
                this.dataTable.options.page = 1;
                this.dataTable.filters.employee_data_id = undefined;
                this.employee_selection_data = [{ 'value': 0, 'text': 'GENERAL ACCOUNTABILITY', 'department_id': 0 }];
                this.GET_EMPLOYEE_DATA_SELECTION.filter(e => {
                    if (e.department_id == val) this.employee_selection_data.push(e);
                })

                this.getAssetTurnovers();
            },
        },
        'dataTable.filters.employee_data_id': {
            handler() {
                this.dataTable.options.page = 1;
                this.getAssetTurnovers();
            },
        },
        'dataTable.filters.request_date_from': {
            handler() {
                this.dataTable.options.page = 1;
                this.getAssetTurnovers();
            },
        },
        'dataTable.filters.request_date_to': {
            handler() {
                this.dataTable.options.page = 1;
                this.getAssetTurnovers();
            },
        },
        'dataTable.search_key': {
            handler() {
                this.dataTable.options.page = 1;
                this.getAssetTurnovers();
            },
        },
    },
}
</script>
