<template>
  <v-card>
    <v-card-title>
      {{ action_title + ' ' + 'Cheque Voucher' }}
      <v-spacer></v-spacer>
      <v-btn
        text
        icon
        small
        color="gray"
        @click="closeDialog()"
      ><v-icon>mdi-close-circle</v-icon></v-btn>
    </v-card-title>
    <v-card-text class="py-6">
      <div
        v-if="!loadedChequeVoucher"
        class="d-flex"
      >
        <v-progress-circular
          class="m-auto"
          :size="70"
          :width="7"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <div
        v-if="loadedChequeVoucher"
        class="flexed-container d-flex flex-column"
      >
        <div v-if="GET_CV_ACTION === 'replace'">
          <v-text-field
            dense
            outlined
            v-model="reference_number"
            label="Reference #"
            readonly
            append-icon=""
            :loading="!reference_number"
          />
        </div>
        <div class="flexed-container d-flex">
          <div class="flexed-container d-flex flex-column">
            <v-autocomplete
              dense
              outlined
              :items="GET_BANK_SELECTION"
              v-model="form.bank_id"
              item-value="id"
              item-text="name"
              label="Bank:"
              :rules="GET_CV_LOAD ? rules.default : []"
              :readonly="GET_CV_ACTION === 'view'"
              :loading="((GET_CV_ACTION == 'update' || GET_CV_ACTION == 'view') && !form.bank_id) || GET_BANK_SELECTION.length == 0"
              @change="getBookletsSelection(form.bank_id)"
              />
              <!-- :disabled="GET_CV_ACTION === 'update'" -->
            <v-autocomplete
              v-model="form.booklet_id"
              :items="filterBooklet"
              dense
              outlined
              item-value="id"
              item-text="booklet_number"
              label="Booklet"
              :rules="GET_CV_LOAD ? rules.default : []"
              disabled
              :readonly="GET_CV_ACTION === 'view'"
              :loading="(GET_CV_ACTION == 'update' || GET_CV_ACTION == 'view') && !filterBooklet.length <= 0 && !form.booklet_id"
              @change="selectBooklet"
              no-data-text="No Booklets Found, Assign Booklet to this Bank"
              :error="filterBooklet.length == 0"
            />
            <v-text-field
              v-model="form.cheque_number"
              dense outlined
              label="Cheque Number"
              :rules="GET_CV_LOAD ? rules.default : []"
              :loading="
                gettingChequeNumber ||
                (GET_CV_ACTION == 'update' || GET_CV_ACTION == 'view') && !form.cheque_number
              "
            />
            <v-text-field
              v-if="(GET_CV_ACTION !== 'create' ) && (GET_CV_ACTION !== 'replace')"
              v-model="form.cheque_voucher_number"
              dense outlined
              label="Cheque Voucher Number"
              :rules="GET_CV_LOAD ? rules.default : []"
              :disabled="GET_CV_ACTION !== 'view'"
              readonly
              :loading="(GET_CV_ACTION == 'update' || GET_CV_ACTION == 'view') && !form.cheque_voucher_number"
              ></v-text-field>
            </div>
          <div class="flexed-container d-flex flex-column">
            <!-- <v-dialog
              ref="paymentDateDialog"
              v-model="paymentDateModal"
              :return-value.sync="form.cheque_date"
              persistent
              width="300px"
              :disabled="GET_CV_ACTION === 'view'"
            >
              <template v-slot:activator="{ on, attrs }">

              :clearable="GET_CV_ACTION !== 'view'"
              </template>
              <v-date-picker
                v-model="form.cheque_date"
                scrollable
              >
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="paymentDateModal = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.paymentDateDialog.save(form.cheque_date)"
              >
                OK
              </v-btn>
              </v-date-picker>
            </v-dialog> -->
            <!-- <v-text-field
              v-model="form.cheque_date"
              dense outlined
              label="Cheque Date"
              class="hide-text-field-details"
              :rules="GET_CV_LOAD ? rules.default : []"
              :loading="(GET_CV_ACTION == 'update' || GET_CV_ACTION == 'view') && !form.cheque_date"
            ></v-text-field> -->
            <v-menu
                :close-on-content-click="true"
                :nudge-right="80"
                :nudge-bottom="42"
                transition="scale-transition"
                max-width="290"
                >
                <template v-slot:activator="{ on }">
                    <v-text-field
                        v-model="form.cheque_date"
                        dense
                        readonly
                        append-icon="mdi-calendar"
                        v-on="on"
                        label="Cheque Date"
                        outlined
                    ></v-text-field>
                </template>
                    <v-date-picker v-model="form.cheque_date">
                    </v-date-picker>
            </v-menu>
            <v-autocomplete
              dense
              outlined
              :items="GET_ADMIN_SUPPLIER_SELECTION"
              v-model="form.vendor_id"
              item-value="value"
              item-text="text"
              label="Vendor:"
              :disabled="GET_DISABLE_VENDOR"
              :rules="GET_CV_LOAD ? rules.default : []"
              readonly
              :loading="
                (GET_ADMIN_SUPPLIER_SELECTION.length <= 0) ||
                (GET_CV_ACTION == 'update' || GET_CV_ACTION == 'view') && !form.vendor_id
              "
            />
            <v-autocomplete
              dense
              outlined
              :items="GET_VENDOR_PAYMENT_ITEM"
              v-model="form.vendor_payment_item_id"
              item-value="id"
              item-text="name"
              label="Payee:"
              :rules="GET_CV_LOAD ? rules.default : []"
              :loading="
                (!readOnlyPayee && GET_VENDOR_PAYMENT_ITEM.length <= 0) ||
                (GET_CV_ACTION == 'update' || GET_CV_ACTION == 'view') && !form.vendor_payment_item_id
              "
            />
            <v-text-field
              v-if="(GET_CV_ACTION !== 'create' ) && (GET_CV_ACTION !== 'replace')"
              v-model="form.amount"
              dense
              outlined
              label="Amount"
              type="number"
              hide-spin-buttons
              :rules="GET_CV_LOAD ? rules.default : []"
              readonly
              :loading="(GET_CV_ACTION !== 'create' && !form.amount)"
            >
              <template v-slot:prepend-inner>
                <v-icon small>mdi-currency-php</v-icon>
              </template>
            </v-text-field>
          </div>
        </div>
        <div class="flexed-container d-flex flex-column flex-fill">
          <v-text-field
            v-if="(GET_CV_ACTION === 'create') || (GET_CV_ACTION === 'replace')"
            v-model="form.amount"
            dense
            @input="checkValue"
            outlined
            label="Amount"
            type="number"
            hide-spin-buttons
            :rules="GET_CV_LOAD ? rules.default : []"
          >
            <template v-slot:prepend-inner>
              <!-- &#x20B1; -->
              <v-icon small>mdi-currency-php</v-icon>
            </template>
            <!-- <template v-slot:default>
              <v-icon small>mdi-currency-php</v-icon>
            </template> -->
          </v-text-field>
          <v-textarea
            v-model="form.remarks"
            outlined
            dense
            label="Remarks:"
            :rules="GET_CV_LOAD ? rules.default : []"
            ></v-textarea>
            <!-- :loading="(GET_CV_ACTION == 'update' || GET_CV_ACTION == 'view') && !form.remarks" -->
        </div>
        <div class="flexed-container d-flex flex-column flex-fill">

        </div>
        <div
          v-if="GET_CV_ACTION === 'create'"
          class="flexed-container d-flex"
        >
          <v-btn
            :disabled="!isFormValid"
            @click="createChequeVoucher"
          >{{action_title}}</v-btn>
          <v-btn v-if="!GET_DISABLE_VENDOR"
            color="secondary"
            @click="resetForm"
          >Clear Fields</v-btn>
        </div>
        <div
          v-if="GET_CV_ACTION === 'replace'"
          class="flexed-container text-center"
        >
          <v-btn
            :disabled="!isFormValid"
            @click="replaceChequeVoucher"
          >{{action_title}}</v-btn>
        </div>
        <div
          v-if="SELECTED.status == 5 && !SELECTED.cleared_date"
          class="flexed-container text-center"
        >
          <v-btn
            @click="checked_dialog = true"
          >Mark as cleared</v-btn>
        </div>
        <div
          v-if="GET_CV_ACTION === 'view'"
          class="flexed-container text-center"
        >
          <v-btn
          :disabled="!isFormValid"
          @click="printCV"
          >Print</v-btn>
        </div>
      </div>
      <!-- <v-divider v-if="GET_CV_ACTIONWithTable"/>
      <v-data-table
        v-if="GET_CV_ACTIONWithTable"
        class="p-2 bg-light border"
        :headers="dataTable.headers"
        :items="dataTable.items"
        :server-items-length="dataTable.totalItems"
        :options.sync="dataTable.options"
        :loading="!loadedChequeVouchers"
        no-data-text="No entries found."
      >
        <template v-slot:[`item.printed`]="{ item }">
          <span :class="`badge badge-${statuses.printed[item.printed].badge}`">{{ statuses.printed[item.printed].text }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn text icon color="orange" @click="viewChequeVoucher(item.id)">
            <v-icon class="btn-action" small>mdi-eye</v-icon>
          </v-btn>
          <v-btn text icon color="blue">
            <v-icon class="btn-action" small>mdi-pencil</v-icon>
          </v-btn>
          <v-btn text icon color="red">
            <v-icon class="btn-action" small>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table> -->
      <v-dialog
        v-model="checked_dialog"
        persistent
        width="500px"
      >
        <v-card>
            <v-card-title>
              {{ 'Assign Cleared Date' }}
              <v-spacer></v-spacer>
              <v-btn
                text
                icon
                small
                color="gray"
                @click="closeCheckedDialog()"
              ><v-icon>mdi-close-circle</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <v-menu
                  v-model="menu.cleared_date"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  max-width="280"
                  >
                  <template v-slot:activator="{ on }">
                      <v-text-field
                          v-model="form.cleared_date"
                          dense
                          :disabled="SELECTED.cleared_date"
                          append-icon="mdi-calendar-clock"
                          v-on="on"
                          label="Cleared Date"
                          outlined
                      ></v-text-field>
                  </template>
                  <v-date-picker v-model="form.cleared_date" @input="menu.cleared_date = false" :disabled="SELECTED.cleared_date">
                  </v-date-picker>
                </v-menu>
            </v-card-text>
          <v-card-actions class="d-flex justify-center align-center">
            <v-btn @click="updateCheckedDate" :disabled="!form.cleared_date || SELECTED.cleared_date">Submit</v-btn>
          </v-card-actions>
          <ConfirmOverrideComponentVue
            :approve_override_dialog="override.override_dialog"
            :departments_allowed="override.override_departments_allowed"
            :heading="''"
            @closeConfirmOverride="closeConfirmOverride"
        ></ConfirmOverrideComponentVue>
        <v-dialog v-model="dialog_reason" width="25%">
            <v-card>
              <v-card-text>
                <v-container fluid class="pa-5">
                  <v-row>
                    <v-col >
                        <p class="text-lg-h6">Reason for delayed clearing cheques</p>
                    </v-col>
                  </v-row>
                  <v-row>
                      <v-col>
                        <v-textarea outlined auto-grow rows="5" v-model="form.reason_text"></v-textarea>
                      </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions class="d-flex justify-content-center">
                    <v-btn @click="cancelReasons()">Cancel</v-btn>
                    <v-btn color="success" @click="submitReason()">Comfirm</v-btn>
              </v-card-actions>
            </v-card>
        </v-dialog>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import PusherFunctionComponent from '@/views/main/Shared/PusherFunctionComponent.vue';
import ConfirmOverrideComponentVue from '@/views/main/Utils/ConfirmOverrideComponent.vue';
import swal from 'sweetalert2';


export default {
  created() {
    this.callPusher();
    this.connectWebSocket();
    this.getSelections();
  },

  props: [
    'vendor_id',
    'showCVReplace',
    'vpdId',
    'pvId',
    'forReplaceVendorId',
    'balanceAmounts'
  ],

  data() {
    return {
      checked_dialog: false,
      // cleared_date: this.$dayjs().format('YYYYY-MM-DD'),
      cleared_date: null,
      // disableVendorId: false,
      gettingChequeNumber: false,
      isFormValid: false,
      loadedChequeVoucher: true,
      loadedChequeVouchers: false,
      paymentDateModal: false,
      pulsingBooklet: false,
      readOnlyBooklet: true,
      readonlyChequeNumber: true,
      readOnlyPayee: true,

      prevoiusSelectedBooklet: 0,

      minDate: this.$dayjs().format('YYYY-MM-DD'),

      dataTable: {
        totalItems: 0,
        headers: [
          { text:'Booklet #', value:'booklet_number', sortable: false},
          { text:'CV #', value:'cheque_voucher_number', sortable: false},
          { text:'Bank', value:'bank', sortable: false},
          { text:'Vendor', value:'vendor', sortable: false},
          { text:'Payee', value:'payee', sortable: false},
          { text:'Printed', value:'printed', sortable: false, align: 'center'},
          { text:'Cheque Date', value:'cheque_date', sortable: false},
          { text:'', value:'actions', sortable: false, align: 'center'},
        ],
        items: [],
        itemsPerPageOptions: [5, 10, 15, 50, 100],
        options: {
          page: 1,
          itemsPerPage: 10,
        },
      },
      form: {
        bank_id: null,
        booklet_id: null,
        vendor_id: null,
        vendor_payment_item_id: null,
        cheque_number: '',
        cheque_date: '',
        amount: 0,
        remarks: '',
        cleared_date: this.$dayjs().format('YYYY-MM-DD'),
        status: 0,
        reason_text:''
      },
      rules: {
        default: [
          v => !!v || 'This is required',
        ],
      },
      statuses: {
        printed: [
          { badge: 'gray', text: 'No' },
          { badge: 'success', text: 'Yes' },
        ],
      },
      action_title:'',
      reference_number:null,
      menu:{
        cleared_date: false
      },
      override: {
          override_dialog: false,
          errors: [],
          override_departments_allowed: ['AC'],
          override_authenticated: false,
      },
      override_confirm:false,
      cancelReason:false,
      dialog_reason:false,
    };
  },
  components:{ConfirmOverrideComponentVue},
  computed: {
    ...mapGetters([
      'GET_BANK_SELECTION',
      'GET_ADMIN_SUPPLIER_SELECTION',
      'GET_VENDOR_PAYMENT_ITEM',
      'GET_BOOKLETS_SELECTION',
      'GET_CV_LOAD',
      'GET_CV_ACTION',
      'GET_FORM_DATA',
      'GET_DISABLE_VENDOR',
      'SELECTED',
      'GET_SELECTED_TAB',
      'GET_RELOAD_CHEQUE_VOUCHER'
    ]),
    filterBooklet() {
      let filteredBooklet = this.GET_BOOKLETS_SELECTION.filter(bookletNum => {
        return bookletNum.bank_id == this.form.bank_id;
      });

      return filteredBooklet;
    },
    // formattedDate() {
    //   if (this.form.cheque_date) {
    //     const options = { year: 'numeric', month: 'short', day: 'numeric' };
    //     return this.selectedDate.toLocaleDateString('en-US', options);
    //   } else {
    //     return '';
    //   }
    // },
    // toCurrency() {
    //   return (currency, value) => {
    //     console.log('called');
    //     return new Intl.NumberFormat('en-US', {
    //       style: 'currency',
    //       currency: currency,
    //     }).format(parseFloat(value));
    //   };
    // },
  },

  watch: {
    'vendor_id': {
      handler(val) {
        if (val !== null && val !== undefined) {
          this.form.vendor_id = this.GET_FORM_DATA.vendor_id;
        }
      },
      immediate: true,
    },
    // '$store.state.chequevoucher.autoFill': {
    //   deep: true,
    //   handler(val) {
    //     this.disableVendorId = val.vendor_id;
    //   },
    //   immediate: true,
    // },
    '$store.state.chequevoucher.form': {
      deep: true,
      handler() {
        this.form = this.GET_FORM_DATA;
        this.form.amount = parseFloat(this.GET_FORM_DATA.beginning_amount).toFixed(2)
        this.form.cleared_date = this.$dayjs().format('YYYY-MM-DD')
      },
    },
    'form': {
      handler(val) {
        this.validateForm();
      },
      deep: true,
    },
    // 'form.bank_id': {
    //   handler(newVal) {
    //     if (newVal !== null && newVal !== undefined) {
    //       this.$store.dispatch("getBookletsSelection");
    //       this.readOnlyBooklet = false;
    //     }
    //   },
    // },
    'form.vendor_id': {
      handler(newVal) {
        if (newVal !== null && newVal !== undefined) {
          this.readOnlyPayee = false;
          this.$store.commit("VENDOR_PAYMENT_ITEM", []);
          this.$store.dispatch("getVendorPaymentItems", newVal);
        }
      },
      immediate: true,
    },
    GET_CV_ACTION: {
      handler (val) {
        if (val) {
          let title = String(val)
          this.action_title = title.charAt(0).toUpperCase() + title.slice(1);
          this.form.vendor_id = this.GET_FORM_DATA.vendor_id
        }
      },
    immediate: true
    },
    vpdId: {
      handler(val) {
        if (val) {
          this.getLastCVDetails();
          this.form.vendor_id = this.forReplaceVendorId;
        }
      },
      immediate:true,
    },
  },

  mixins: [
    PusherFunctionComponent,
  ],

  methods: {
    checkValue(value){
        if(parseFloat(value) > parseFloat(this.balanceAmounts)){
            this.form.amount = parseFloat(this.balanceAmounts).toFixed(2)
            swal.fire('Total Amount Exceeds to Balance Amount','','warning');
        };
    },
    connectWebSocket(){
      window.Echo.channel('booklet-channel').listen('BookletSelection', (event) => {
        this.$store.commit('BOOKLETS_SELECTION', event.booklets_selection);
      })
    },
    createChequeVoucher() {
      this.$store.dispatch('createChequeVoucher', this.form).then(response => {
        this.resetForm();
        this.$store.commit('DIALOG', false);
        this.$store.commit('IS_DIALOG_VISIBLE', false);
        swal.fire({
          icon: "success",
          title: "Success!",
          text: "Cheque voucher create successfully.",
        });
        this.$store.state.chequevoucher.lastCreatedChequeVoucher = response.data;
        this.$store.commit('CV_TABLE', true);
      });
    },
    getSelections(){
      this.$store.dispatch("getBankSelection",{international:0});
      this.$store.dispatch("getAdminSupplierSelection");
    },
    getNextChequeNumber() {
      // console.log('this.form.booklet_id', this.form.booklet_id);
      this.gettingChequeNumber = true;
      this.readonlyChequeNumber = true;
      let payload = {
        booklet_id: this.form.booklet_id,
      }
      this.$store.dispatch('getNextChequeNumber', payload).then(response => {
        this.form.cheque_number = response.data.cheque_number;
        this.gettingChequeNumber = false;
        this.readonlyChequeNumber = false;
      });
    },
    pulseBooklet() {
      // this.$store.dispatch('pulseBooklet', this.form.booklet_id).then(response => {
      //   // console.log('pulsing booklet'); // Do not delete.
      //   if (this.pulsingBooklet && this.form.booklet_id !== null) {
      //     setTimeout(() => {
      //       this.pulseBooklet();
      //     }, 10000);
      //   } else this.pulsingBooklet = false;
      // });
    },
    resetForm() {
      this.$store.state.chequevoucher.autoFill = {};
      Object.assign(this.$data, this.$options.data.call(this))
    },
    selectBooklet() {
      let payload = {
        previous_selected: this.prevoiusSelectedBooklet,
        current_selected: this.form.booklet_id,
      }
      this.$store.dispatch('selectBooklet', payload).then(response => {
        if (response.data !== this.prevoiusSelectedBooklet || this.form.booklet_id === null || this.form.booklet_id === undefined) {
          this.prevoiusSelectedBooklet = response.data;
          if (this.form.booklet_id !== null) {
            if (!this.pulsingBooklet) {
              this.pulsingBooklet = true;
              setTimeout(() => {
                this.pulseBooklet();
              }, 10000);
            }
            if (this.form.booklet_id !== null && this.form.booklet_id !== undefined) this.getNextChequeNumber();
          } else this.pulsingBooklet = false;
          return;
        }
        this.form.booklet_id = response.data;
        swal.fire({
          icon: "error",
          title: "Oops...",
          text: "The booklet number you selected is being used by another user.",
        });
      });
    },
    updateCheckedDate(){
      if(this.SELECTED.settings && this.SELECTED.settings.age > 3 && !this.override_confirm && !this.cancelReason){
        swal.fire({
          text:'The cheque voucher issued on '+ this.$dayjs(this.SELECTED.cheque_date).format('LL') + ' has not been cleared for over 3 days. Please contact your department head for overriding',
          icon:'warning',
          showCancelButton:true,
          reverseButtons: true,
          confirmButtonColor: '#397373',
          confirmButtonText:'Ok',
        }).then((res)=>{
          if(res.isConfirmed){
            this.override.override_dialog = true
          }else{
            this.override.override_dialog = false
          }
        })
        return false;
      }
      if(this.cancelReason){
        this.enterReason();
        return false;
      }
       if(this.SELECTED.settings && this.SELECTED.settings.age > 3 && !this.form.reason_text){
        swal.fire({
          text:'Please enter reason.',
          icon:'warning',
        });
        this.override.override_confirm = true
      }else{
        swal.fire({
          title: "",
          text: "Are you sure you want to assign cleared date?",
          icon: "warning",
          showConfirmButton: true,
          showCancelButton: true,
          reverseButtons: true,
          allowOutsideClick: false,
          confirmButtonColor: '#397373',
          cancelButtonColor: 'grey',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          showCloseButton: true,
      })
      .then((result) => {
          if (result.isConfirmed) {
            let payload = {
              id: this.SELECTED.id,
              cleared_date: this.form.cleared_date,
              reason_text:this.form.reason_text
            }
            this.override_confirm = false;
            this.cancelReason = false;
            this.$store.dispatch('updateClearedDate',payload).then(response => {
              swal.fire("Updated Successfully!", '', "success");
              this.closeDialog();
            });
          }
      });
      }

    },
    updateChequeVoucher() {
      this.$store.dispatch('updateChequeVoucher', this.form).then(response => {
        this.resetForm();
        this.$store.commit('DIALOG', false);
        swal.fire({
          icon: "success",
          title: "Success!",
          text: "Cheque voucher update successfully.",
        });
        this.$store.commit('CV_TABLE', true);
      });
    },
    validateForm() {
      this.isFormValid = (
        this.form.bank_id !== null &&
        this.form.booklet_id !== null &&
        this.form.vendor_id !== null &&
        this.form.vendor_payment_item_id !== null &&
        this.form.cheque_number !== '' &&
        this.form.cheque_date !== '' &&
        this.form.amount > 0 &&
        this.form.remarks !== ''
      );
    },
    viewChequeVoucher(id) {
      this.$store.commit('CV_ACTION', 'view');
      this.loadedChequeVoucher = false;
      this.$store.dispatch('getChequeVoucher', id).then(response => {
        this.loadedChequeVoucher = true,
        this.form = response.data[0];
        this.form.cleared_date = this.$dayjs().format('YYYY-MM-DD')
      });
    },
    printCV() {
      let doc = new this.$jspdf('p', 'mm', 'letter');
      doc.autoPrint();
      window.open(doc.output('bloburl'), '_blank');
    },
    closeDialog() {
      this.resetForm();
      this.cancelReason = false
      this.override_confirm = false
      if (this.GET_CV_ACTION !== 'replace') {
        this.$store.commit('DIALOG', false);
        this.$store.commit('IS_DIALOG_VISIBLE', false);
        this.$store.commit('DISABLE_VENDOR', false);
        this.$store.commit('FORM_DATA', {});
        this.$store.commit('SELECTED',{});
      } else {
        this.closeCVReplace();
      }
    },
    closeCheckedDialog(){
      this.checked_dialog = false;
    },
    closeCVReplace() {
      this.$emit('close');
      this.$store.commit('DISABLE_VENDOR', false)
    },
    getLastCVDetails() {
      let payload = {
          id: this.vpdId
      }
      this.$store.dispatch('getLastCVDetails', payload).then(response=>{
        this.reference_number = response.data.reference_number;
        this.form.vendor_payment_item_id = response.data.payee;
      })
    },
    replaceChequeVoucher() {
      swal.fire({
        title: "",
        text: "Are you sure you want to replace the cheque voucher?",
        icon: 'question',
        showConfirmButton: true,
        showCancelButton: true,
        reverseButtons: true,
        allowOutsideClick: false,
        confirmButtonColor: '#397373',
        cancelButtonColor: 'grey',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        showCloseButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          // this.form.cheque_date = this.form.cheque_date + ' ' + this.$dayjs().format('HH:mm:ss');
          let payload = {
            pv: this.pvId,
            vpd: this.vpdId,
            form: this.form,
          }
          this.$store.dispatch('replaceChequeVoucher', payload).then(response => {
            let data = response.data;
            this.resetForm();
            this.closeDialog();
            this.$emit('closeMainDialog');
            if (data.error) {
              swal.fire("", data.error, "warning")
              return false
            }
            swal.fire("", response.data.msg, "success").then(e => {
                this.$store.state.accounting.paymentVoucher.addEditPaymentVoucher = true;
            });
          });
        }
      })
    },
    getBookletsSelection(bank_id){
      this.$store.dispatch('getBookletsSelection', bank_id).then((response)=>{
          this.form.booklet_id = response.length > 0 ? response[0].id : ''
          if (!this.form.booklet_id) {
              this.form.cheque_number = ''
              swal.fire('', 'No Booklet Found', 'warning')
              return false
            }
            this.getNextChequeNumber()
      })
    },
    // getNextChequeNumber(){
    //   this.$store.dispatch('getNextChequeNumber', { booklet_id: this.form.booklet_id }).then(response => {
    //       this.form.cheque_number = response.data.cheque_number
    //   })
    // },
    async closeConfirmOverride(user_id, authenticated = false) {
                if (authenticated) {
                    document.activeElement.blur();
                    this.override.override_dialog = false;
                    this.override_confirm = true;
                    this.enterReason()
                } else {
                    this.override.override_dialog = false;
                };
            },
        enterReason(){
          this.dialog_reason = true
        },
        submitReason(){
          this.cancelReason = false;
          this.updateCheckedDate()
        },
        cancelReasons(){
          this.dialog_reason = false
          this.cancelReason = true;
        }
  },
}
</script>

<style scoped>
  .flexed-container {
    gap: .5rem;
  }
  .flexed-container > * {
    flex: 1;
  }
  .flexed-container .v-btn {
    padding: .5rem;
    flex: unset;
  }
  .flexed-container .v-btn:first-child {
    margin-left: auto;
  }
</style>