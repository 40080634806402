<template>
    <div>
        <AddEditCustomerPaymentDetailComponentVue :addEdit="true" :origin="'editSoa'" :perPL="false" :statuses="status" :customer="selectedCustomer" :paymentType="selectedPaymentTypes"/>
        <v-app>
            <v-card>
                <v-container>
                    <v-row class="px-4 mt-1">
                        <v-col cols="3">
                            <v-autocomplete
                                :items="GET_CUSTOMERS_LIST_SELECTION"
                                v-model="selectedCustomer"
                                item-value="value"
                                auto-select-first
                                item-text="text"
                                label="Customer"
                                outlined
                                small
                                dense
                            />
                        </v-col>
                        <v-col cols="1">
                            <v-btn @click="searchCustomerPayment()">Filter</v-btn>
                        </v-col>
                        <v-spacer />
                        <v-flex align-self-start>
                            <div style="text-align:end; gap:7px;" class="d-flex justify-end">
                                <span class="badge bg-light" style="cursor:pointer" @click="filterByStatus(-1)">all</span>
                                <span class="badge white--text bg-secondary" style="cursor:pointer" @click="filterByStatus(0)">saved</span>
                                <span class="badge white--text bg-danger" style="cursor:pointer" @click="filterByStatus(3)">rejected</span>
                                <span class="badge white--text bg-dark" style="cursor:pointer" @click="filterByStatus(5)">bounced</span>
                                <span class="badge white--text bg-danger" style="cursor:pointer" @click="filterByStatus(6)">cancelled</span>
                                <span class="badge white--text bg-primary" style="cursor:pointer" @click="filterByStatus(7)">confirmed</span>
                                <span class="badge bg-warning" style="cursor:pointer" @click="filterByStatus(8)">exhausted</span>
                                <span class="badge white--text bg-success" style="cursor:pointer" @click="filterByStatus(9)">allocated</span>
                                <span class="badge badge-warning" style="cursor:pointer" @click="filterByStatus(10)">partial</span>
                                <span class="badge white--text badge-info" style="cursor:pointer" @click="filterByStatus(11)">for confirmation</span>
                            </div>
                        </v-flex>
                    </v-row>
                </v-container>
                <v-container>
                    <v-row class="px-4">
                        <v-col cols="3">
                            <v-autocomplete
                                v-model="selectedPaymentTypes"
                                :items="paymentTypeLists"
                                label="Payment Type"
                                auto-select-first
                                item-text="name"
                                item-value="id"
                                deletable-chips
                                multiple
                                outlined
                                dense
                                chips
                                clearable
                                @input="getCustomerPaymentDetails"
                            >
                                <template v-slot:selection="data">
                                    <v-chip
                                        v-bind="data.attrs"
                                        :input-value="data.selected"
                                        close
                                        dense
                                        small
                                        @click="data.select"
                                        @click:close="remove(data.item.id)"
                                    >
                                    {{ data.item.name }}
                                    </v-chip>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <!-- <v-col cols="1">
                            <v-btn text @click="getCustomerPaymentDetails()">Filter</v-btn>
                        </v-col> -->
                        <v-spacer></v-spacer>
                    </v-row>
                </v-container>
                <v-card-title>
                    <v-layout row class="mx-3 mb-2">
                        <v-spacer />
                        <v-flex lg2>
                            <div style="text-align:end">
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    label="Ref#, Chq/Acct/Card#"
                                    single-line
                                    hide-details
                                    clearable
                                    @keydown.enter="searchCustomerPayment()"
                                    @click:append="searchCustomerPayment()"
                                    @click:clear="clearFilter(); searchCustomerPayment()"
                                />
                            </div>
                        </v-flex>
                    </v-layout>
                </v-card-title>

                <v-card-text>
                    <v-data-table
                        :headers="headers"
                        :items="customerPayments"
                        :search="search"
                        :server-items-length="totalCustomerPayments"
                        :options.sync="options"
                        :items-per-page="10"
                        :loading="loaded.datatable"
                        disable-sort
                    >
                        <template v-slot:[`item.gross_amount`]="{ item }">
                            &#x20b1;{{ item.gross_amount | currency }}
                        </template>

                        <template v-slot:[`item.wht_amount`]="{ item }">
                            &#x20b1;{{ item.wht_amount | currency }}
                        </template>

                        <template v-slot:[`item.other_adj_amount`]="{ item }">
                            &#x20b1;{{ item.other_adj_amount | currency }}
                        </template>

                        <template v-slot:[`item.net_amount`]="{ item }">
                            &#x20b1;{{ item.net_amount | currency }}
                        </template>

                        <template v-slot:[`item.total_amount`]="{ item }">
                            &#x20b1;{{ item.total_amount | currency }}
                        </template>

                        <template v-slot:[`item.remaining_amount`]="{ item }">
                            &#x20b1;{{ item.remaining_amount | currency }}
                        </template>

                        <template v-slot:[`item.allocated_amount`]="{ item }">
                            &#x20b1;{{ item.allocated_amount | currency }}
                        </template>

                        <template v-slot:[`item.payment_type_name`]="{ item }">
                            <span class="badge white--text bg-success" v-if="item.payment_type_name=='Cash'">{{ item.payment_type_name }}</span>
                            <span class="badge white--text  bg-bluish-gray " v-else-if="item.payment_type_name=='Cheque'">{{ item.payment_type_name }}</span>
                            <span class="badge bg-warning" v-else-if="item.payment_type_name=='Deposit'">{{ item.payment_type_name }}</span>
                            <span class="badge white--text  bg-info " v-else-if="item.payment_type_name=='Online'">{{ item.payment_type_name }}</span>
                            <span class="badge white--text  bg-info " v-else-if="item.payment_type_name=='Gcash'">{{ item.payment_type_name }}</span>
                            <span class="badge bg-primary" v-else-if="item.payment_type_name=='Credit/Debit Card'">{{ item.payment_type_name }}</span>
                            <span class="badge bg-dark text-white" v-else-if="item.payment_type_name=='Journal Voucher'">{{ item.payment_type_name }}</span>
                        </template>

                        <template v-slot:[`item.expected_deposit_date`]="{ item }">
                            <span v-if="item.payment_type_name=='Cheque'" class="text-nowrap">
                                    {{ item.expected_deposit_date }}
                            </span>
                        </template>

                        <template v-slot:[`item.status`]="{ item }">
                            <span class="badge white--text bg-secondary" v-if="item.status==0">saved</span>
                            <span class="badge white--text bg-info" v-else-if="item.status==1">submitted</span>
                            <span class="badge white--text bg-purple" v-else-if="item.status==2">approved</span>
                            <span class="badge white--text bg-danger" v-if="item.status==3">rejected</span>
                            <span class="badge white--text bg-dark" v-else-if="item.status==4">returned</span>
                            <span class="badge white--text bg-dark" v-else-if="item.status==5">bounced</span>
                            <span class="badge white--text bg-danger" v-else-if="item.status==6">cancelled</span>
                            <span class="badge white--text bg-primary" v-else-if="item.status==7">confirmed</span>
                            <span class="badge bg-warning" v-else-if="item.status==8">exhausted</span>
                            <span class="badge white--text bg-success" v-else-if="item.status==9">allocated</span>
                            <span class="badge badge-warning" v-else-if="item.status == 10">partial</span>
                            <span class="badge white--text badge-info" v-else-if="item.status == 11">for confirmation</span>
                        </template>

                        <template v-slot:[`item.action`]="{ item }">
                            <v-btn text icon color="orange">
                                <v-icon class="btn-action" small @click="viewCustomerPayment(item.id)" v-if="userAccess.view">mdi-eye</v-icon>
                            </v-btn>
                            <v-btn  text icon color="blue">
                                <v-icon class="btn-action" small @click="editCustomerPayment(item.id)" v-if="userAccess.edit">mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn v-if="item.status == 0 || item.status == 1" text icon color="red">
                                <v-icon class="btn-action" small @click="deleteCustomerPayment(item.id)" v-if="userAccess.delete">mdi-delete</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-app>
        <ViewCustomerPaymentDetailComponentVue :view="true" :isUnidentified="isUnidentified" :user_access="userAccess"/>
    </div>
</template>

<script>

import AddEditCustomerPaymentDetailComponentVue from '../PaymentsComponent/AddEditCustomerPaymentDetailComponent.vue';
import ViewCustomerPaymentDetailComponentVue from '../PaymentsComponent/ViewCustomerPaymentDetailComponent.vue';
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
export default {
    data () {
        return {
            customerPayments: [],
            search: '',
            headers: [
                { text: 'Reference#', value: 'reference_number' },
                { text: 'Cheque Account#', value: 'cheque_account_number' },
                { text: 'Date', value: 'payment_date' },
                { text: 'Customer Name', value: 'customer_name' },
                { text: 'Payment Type', value: 'payment_type_name' },
                { text: 'Bank', value: 'bank_name' },
                { text: 'Gross Amount', align: 'end', value: 'gross_amount' },
                // { text: 'WHT Amount', align: 'end', value: 'wht_amount' },
                // { text: 'ADJ Amount', align: 'end', value: 'other_adj_amount' },
                { text: 'Net Amount', align: 'end', value: 'net_amount' },
                { text: 'Remaining Amount', align: 'end', value: 'remaining_amount' },
                { text: 'Status', align: 'center', value: 'status' },
                { text: 'Cheque Date', value: 'cheque_date' },
                { text: 'Created By', value: 'created_by' },
                { text: 'Actions', align: 'center', value: 'action' },
            ],
            loading: false,
            page: 1,
            itemsPerPage: 20,
            options: {},
            totalCustomerPayments: 0,
            status: -1,
            customerList: [],
            selectedCustomer: '',
            userAccess: {
                create  : false,
                edit    : false,
                view    : false,
                delete  : false,
                cancel_payment: false,
                confirm_payment: false,
                reject_payment: false,
                edit_payment: false,
            },
            loadview:{
                customer_selection: true
            },
            paymentTypeLists:[],
            selectedPaymentTypes: [],
            loaded:{
                datatable: false
            }
        }
    },
    components: {
        AddEditCustomerPaymentDetailComponentVue,
        ViewCustomerPaymentDetailComponentVue
    },
    props: ['isUnidentified'],
    async mounted() {
        await this.getCustomerPaymentDetails();
        await this.getPaymentTypes();
        // this.checkAccess();
    },
    watch: {
        customerPayments() {
            if(this.customerPayments.length > 0) {
                this.loading = false;
            } else {
                this.loading = false;
            }
            this.$store.commit('clearPaymentsMenu');
        },
        USER_ACCESS:{
            handler(val){
                this.userAccess.create = false;
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                this.cancel_payment = false;
                this.confirm_payment = false;
                this.reject_payment = false;
                this.edit_payment = false;

                if(val != "fail"){
                    val.forEach((e) => {
                        if (e.actions_code == "create"){
                            this.userAccess.create = true;
                        }
                        else if (e.actions_code == "edit"){
                            this.userAccess.edit = true;
                        }
                        else if (e.actions_code == "view"){
                            this.userAccess.view = true;
                        }
                        else if (e.actions_code == "delete"){
                            this.userAccess.delete = true;
                        }
                    });
                }
            }
        },
        successAddEdit() {
            if(!!this.successAddEdit) {
                this.getCustomerPaymentDetails();
            }
        },
        options: {
            handler(val) {
                this.loading = true;
                this.page = val.page;
                this.getCustomerPaymentDetails();
            },
            deep: true,
        },
        menu: {
            handler(val){
                if(val == 'details') {
                    this.loading = true;
                    // this.status = 10;
                    this.customerPayments = [];
                    this.isUnidentified = false
                    this.getCustomerPaymentDetails();
                }
            }
        },
        selectedCustomer() {
            if(!!this.selectedCustomer) {
                this.getCustomerPaymentDetails();
            } else {
                this.selectedCustomer = '';
            }
        },
        'GET_CUSTOMERS_LIST_SELECTION':{
            handler(val){
                this.loadview.customer_selection = false
            }
        },
    },
    computed: {
        ...mapGetters([
            'GET_CUSTOMER_PAYMENT_DETAILS',
            'GET_CUSTOMERS_LIST_SELECTION',
            'USER_ACCESS',
            'GET_PAYMENT_TYPES'
        ]),
        successAddEdit() {
            return this.$store.state.accounting.customerPaymentDetail.successAddEdit;
        },
        menu() {
           return this.$store.state.accounting.payments.menu;
        }
    },
    methods:{
        searchCustomerPayment() {
            this.options.page = 1;
            this.selectedCustomer = '';
            this.getCustomerPaymentDetails();
        },
        filterByStatus(status) {
            this.status = status;
            this.options.page = 1
            this.customerPayments = [];
            this.loading = true;
            this.getCustomerPaymentDetails();
        },
        clearFilter() {
            this.selectedCustomer = '';
            this.search = '';
            this.status = -1;
            this.selectedPaymentTypes = [];
            this.getCustomerPaymentDetails();
        },
        async getCustomerPaymentDetails() {
            this.customerPayments = [];
            this.loaded.datatable = true;
            let payload = {
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                status: this.status,
                customerId: this.selectedCustomer,
                search: this.search,
                isUnidentified:this.isUnidentified,
                filterTypes:this.selectedPaymentTypes
            }
            await this.$store.dispatch('getCustomerPaymentDetails',payload).then(response=>{
                this.loaded.datatable = false;
                this.customerPayments = this.GET_CUSTOMER_PAYMENT_DETAILS.data;
                this.totalCustomerPayments = this.GET_CUSTOMER_PAYMENT_DETAILS.total;
            });
        },
        viewCustomerPayment(id) {
            this.$store.commit('custPaymentDetailSetId', id);
            this.$store.commit('custPaymentDetailShow');
        },
        editCustomerPayment(id) {
            this.$store.commit('custPaymentDetailSetId', id);
            this.$store.commit('custPaymentDetailEditDialogAction');
            this.$store.commit('custPaymentDetailShowAddEditDialog');
        },
        deleteCustomerPayment(id) {
            swal.fire({
                title: 'Are you sure you want to Update Customer Payment?',
                text: 'Make sure that all information are correct',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(result=>{
                if(result.isConfirmed) {
                    this.$store.dispatch('deleteCustomerPaymentDetail',id).then(response=>{
                        if(!!response.data.error) {
                            swal.fire('', response.data.error, 'error');
                        } else {
                            swal.fire('', response.data.msg, 'success');
                            this.getCustomerPaymentDetails();
                        }
                    });
                }
            });
        },
        async getPaymentTypes(){
            await this.$store.dispatch('getPaymentTypes').then(response=>{
                this.paymentTypeLists = this.GET_PAYMENT_TYPES
            })
        },
        remove(id) {
            this.selectedPaymentTypes = this.selectedPaymentTypes.filter( e => e != id);
            this.getCustomerPaymentDetails();
        },
    }
};
</script>
