
import ServiceSettings from "@/views/main/modules/Services/Views/Settings.vue";
import repository from "@/api/credentials";

const routes = 
        [{
              path: "/services/settings/:id",
              name: "servicesettings",
              component: ServiceSettings,
              beforeEnter:(to,from,next)=>{
                repository.checkAccessUser(to.params.id).then((response)=>{
                    if(response.data == 'fail'){
                        next('/no-access')
                        }else{
                        next()
                    }
                }).catch(()=>{

                })

          },
              meta:{
                   requiresAuth:true
                 }
          }]
  

  

export default routes;
