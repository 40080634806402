import AssetManagementReportComponent from '@/views/main/modules/Reports/AdminReports/AssetManagementReportComponent'

const routes = [{
    path: 'admin/reports/asset-management',
    name: 'assetmanagementreport',
    component: AssetManagementReportComponent,
    meta: {
        requiresAuth: true,
        title: 'Asset Management Report',
    }
},
]

export default routes;
