<template>
    <v-card>
        <v-card-text>
            <v-row>
                <v-col lg="3">
                    <v-btn
                        small
                        @click="()=>{
                            dialog.value = true
                            dialog.action = 'Add'
                        }"
                    >
                    <v-icon>mdi-plus</v-icon> Add Equipments
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col lg="12">
                    <v-data-table
                    :items="dataTable.items"
                    :headers="dataTable.headers"
                    :options.sync="dataTable.options"
                    :items-per-page="dataTable.total"
                    dense>
                    <template v-slot:[`item.action`]="{ item }">
                        <v-btn text icon color="blue">
                          <v-icon
                            class="btn-action"
                            small
                            @click="view(item,'Update')"
                            >mdi-square-edit-outline</v-icon
                          >
                        </v-btn>
                        <v-btn text icon color="red"
                        >
                          <v-icon
                            class="btn-action"
                            small
                            @click="dialog.action = 'Delete';form.id = item.id;modifyEquipment('delete-equipment')"
                            >mdi-delete</v-icon
                          >
                        </v-btn>
                      </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>
        <v-dialog
            v-model="dialog.value"
            width="600px"
        >
            <v-card>
                <v-card-title class="d-flex justify-content-between">
                    <span>{{dialog.action}} Equipments</span>
                    <v-btn
                    text
                    icon
                        @click="()=>{
                           dialog.value = false
                           dialog.action = ''
                           dialog.selected = ''
                        }"
                    >
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col lg="12">
                            <v-text-field
                                v-model="form.name"
                                label="Name"
                                dense
                                outlined
                                :disabled="dialog.action == 'View'"
                            >

                            </v-text-field>

                        </v-col>
                        <v-col lg="12">
                            <v-text-field
                                v-model="form.description"
                                label="Description"
                                dense
                                outlined
                                :disabled="dialog.action == 'View'"
                            >

                            </v-text-field>

                        </v-col>
                        <v-col lg="12">
                            <v-text-field
                                v-model="form.code"
                                label="Description"
                                dense
                                outlined
                                :disabled="dialog.action == 'View'"
                            >

                            </v-text-field>

                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="d-flex justify-content-center align-items-center">
                    <v-btn @click="modifyEquipment('job-order-equipment')"
                        small
                        color="orange"
                    >
                        {{dialog.action}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import Swal from 'sweetalert2'
export default {
    data(){
        return{
            form:{
                name:'',
                code:'',
                description:''
            },
            dataTable:{
                items:[],
                headers:[
                    { text:'Name',value:'name' },
                    { text:'Code',value:'code' },
                    { text:'Description',value:'description' },
                    { text:'Action',value:'action' },
                ],
                options:{},
                total:0,
                loading:false
            },
            dialog:{
                value:false,
                action:'',
                selected:''
            }
        }
    },
    methods:{
        async list(){
            this.dataTable.loading = true
            this.dataTable.items = []
            await this.$store.dispatch('jobOrderGet',{
                url:'job-order-equipments',
                ...this.filters,
                // ...this.dataTable.options
            }).then(response=>{

                this.dataTable.items = response.data.data.data
                this.dataTable.total = response.data.data.total
            }).catch(err=>{
                console.log(err)
                Swal.fire('Error!','','error')

            })
        },
        modifyEquipment(url){
            Swal.fire({
                title:this.dialog.action,
                text:`- Are you sure you want to ${this.dialog.action}?`,
                icon: 'question',
                showCancelButton:true,
                reverseButtons:true,
            }).then(action=>{
                if(action.isConfirmed){
                    this.$store.dispatch('jobOrderPost',{
                        url:url,
                        ...this.form
                    }).then(response=>{
                        this.$store.commit('NEW_JO_TABLE', true);
                        this.$store.commit("JO_NUM", '');
                        Swal.fire('Success!','','success')
                        this.resetField()
                    }).catch(err=>{
                        console.log(err)
                        Swal.fire('Error!','','error')
                        // this.resetField()
                    })
                }
            })

        },
        view(item,action){
            this.dialog.action = action
            this.$store.dispatch('jobOrderGet',{
                url:'view-job-order-equipment/'+ item.id,
            }).then(response=>{
                this.form = response.data
                this.dialog.value = true
            }).catch(err=>{
                Swal.fire('Error!','','error')
            })
        },
        resetField(){
            this.form = {
                name:'',
                code:'',
                description:''
            }
            this.dialog.value = false
            this.dialog.action = ''
            this.list()
        }
    },
    watch:{
        'dataTable.options':{
            handler(val){
                this.list()
            }
        }
    }
}
</script>

<style>

</style>
