<template>
    <div style="border:unset">
        <v-card>
            <v-row>
                <v-spacer />
                <v-col cols="12" lg="4" xl="3" class="d-flex justify-end ma-2">
                    <v-btn
                        small
                        :loading="exportLoading"
                        @click="exportSpNumber"
                    >
                        <v-icon small>mdi-printer-pos-wrench</v-icon> 
                        <span class="ml-1">Export</span>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-spacer />
                <v-col cols="12" lg="4" xl="3" class="mr-4">
                    <v-text-field
                        placeholder="Model/Part #/SP #"
                        label="Search Model/Part #/SP #"
                        append-icon="mdi-magnify"
                        v-model="search"
                        clearable
                        dense
                        @click:append="searchInput()"
                        @keydown.enter="searchInput()"
                        @click:clear="search='';searchInput()"
                        >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-card-text>
                <v-data-table
                    :search="search"
                    :items="dataTable.items"
                    :loading="dataTable.loading"
                    :headers="dataTable.headers"
                    :server-items-length="dataTable.totalItems"
                    :options.sync="dataTable.options"
                    :footer-props="{
                        itemsPerPageOptions: dataTable.itemsPerPageOptions,
                    }"
                    dense
                >
                    <template v-slot:[`item.image_path`]="{ item }">
                        <v-img class="img-thumbnail" width="50" height="50" :src="checkImagePath(item)" @error="checkImage(item)" eager contain></v-img>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue';

export default {
    mixins:[SharedFunctionsComponent],
    data() {
        return {
            search: '',
            dataTable: {
                items: [],
                headers: [
                    { text: 'Image', align: 'left', value: 'image_path', sortable: false },
                    { text: 'Model(Unit)', align: 'left', value: 'model', sortable: false },
                    { text: 'Part #', align: 'left', value: 'part_num', sortable: false },
                    { text: 'Item Name', align: 'left', value: 'name', sortable: false },
                    { text: 'SP #', align: 'left', value: 'sp_number_text', sortable: false },
                ],
                loading: false,
                totalItems: 0,
                options: {},
                itemsPerPageOptions: [ 5, 10, 25, 50, 100, 200, 500, 1000 ],
            },
            exportLoading: false,
        }
    },
    components: {

    },
    methods: {
        getAll() {
            this.dataTable.loading = true;
            this.dataTable.items = [];
            let payload = {
                search: this.search,
                page: this.dataTable.options.page,
                itemsPerPage: this.dataTable.options.itemsPerPage,
                url: "get-sp-number",
            } 
            this.$store.dispatch('itemsGet', payload).then(response => {
                let items = response.data.data; 
                this.dataTable.items = items.data;
                this.dataTable.totalItems = items.total;
                this.dataTable.loading = false;
            })
        },
        checkImagePath(item) {
            return item.image_path == null
                ? process.env.VUE_APP_API_HOST + "/images/items/no-image.png"
                : process.env.VUE_APP_API_HOST + "/" + item.image_path;
            },
        checkImage(item) {
            item.image_path = process.env.VUE_APP_API_HOST + "/images/items/no-image.png";
            return item.image_path;
        },
        searchInput() {
            this.dataTable.options.page = 1
            this.getAll();
        },
        async exportSpNumber() {
            this.exportLoading = true;
            let exportHeadersAndData = {
                ['SP Numbers']: {
                    headers: [
                        {header:'MODEL(UNIT)', width: 20, key:'model', style: { alignment: { vertical: 'middle', horizontal: 'center' } }},
                        {header:'PART NUMBER', width: 20,key:'part_num', style: { alignment: { vertical: 'middle', horizontal: 'center' } }},
                        {header:'ITEM NAME', width: 30,key:'name', style: { alignment: { vertical: 'middle', horizontal: 'center' } }},
                        {header:'SP NUMBER', width: 25,key:'sp_number_text', style: { alignment: { vertical: 'middle', horizontal: 'center' } }}, 
                    ],
                    data: []
                }
            }
            let payload = {
                search: this.search,
                type: 'export',
                url: 'get-sp-number'
            }
            await this.$store.dispatch('itemsGet', payload).then(response=>{
                exportHeadersAndData['SP Numbers'].data = response.data;
                this.exportExcel(exportHeadersAndData, 'SP Numbers', 'F69F1A');
            });
            
            this.exportLoading = false;
        }
    },
    watch: {
        'dataTable.options': {
            handler(val) {
                this.getAll()
            },
            deep: true,
        }
    },
    mounted() {
        this.getAll();
    },

}
</script>

<style>

</style>