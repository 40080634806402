<template>
    <v-card style="border-radius: 0px;" class="ma-0 pa-0 h-100" flat>
        <v-card-title>
            <v-row class="mx-2 mb-1 d-flex justify-content-center align-items-center">
                <v-col >
                    <v-img src="@/assets/ingcoph_logo/ingcoph_logo.jpg" :aspect-ratio="16/9" width="200px" contain></v-img>
                </v-col>
                <v-col class="d-flex justify-content-center align-items-center">
                    <h2>{{ date | formatDateWithDay }}</h2>
                </v-col>
                <v-col  cols="4" class="ma-0 pa-0 d-flex justify-content-center align-items-center">
                    <v-row>
                        <v-col>
                            <v-switch
                                v-model="toggleView"
                                inset
                                color="primary"
                            >
                                <template v-slot:label>
                                    <v-icon large :color="toggleView ? 'primary' : 'gray'">
                                        {{url_meta.toggle_icon}}
                                    </v-icon>
                                </template>
                            </v-switch>
                        </v-col>
                    </v-row>
                    <v-dialog
                        ref="dialog"
                        v-model="modal"
                        :return-value.sync="date"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            class='ml-4'
                            outlined
                            dense
                            v-model="date"
                            label="Select Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="date"
                            scrollable
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="modal = false"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(date)"
                        >
                            OK
                        </v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text>
            <v-row class="mx-2">
                <v-col class="text-center mx-2">
                    <v-card elevation="5" class="ma-0 pa-2 rounded-lg text-center">
                        <v-card-text class="ma-0 pa-0">
                            <h2 style="color: black;">Repairs</h2>
                        </v-card-text>
                        <v-divider class="ma-0 pa-0"></v-divider>
                        <v-card-text class="ma-1 pa-1">
                            <h1 style="color: black;">{{!!items.repaired_today ? thousandSeprator(items.repaired_today) : 0}} / {{!!items.repair_today ? thousandSeprator(items.repair_today) : 0}}</h1>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col class="text-center mx-2">
                    <v-card elevation="5" class="ma-0 pa-2 rounded-lg text-center">
                        <v-card-text class="ma-0 pa-0">
                            <h2 style="color: black;">Average Repair per Day (3 Months)</h2>
                        </v-card-text>
                        <v-divider class="ma-0 pa-0"></v-divider>
                        <v-card-text class="ma-1 pa-1">
                            <h1 style="color: black;" >{{ !!items.average ? thousandSeprator(items.average) : 0 }}</h1>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col class="text-center mx-2">
                    <v-card elevation="5" class="ma-0 pa-2 rounded-lg text-center">
                        <v-card-text class="ma-0 pa-0">
                            <h2 style="color: black;">Total Pending Repairs</h2>
                        </v-card-text>
                        <v-divider class="ma-0 pa-0"></v-divider>
                        <v-card-text class="ma-1 pa-1">
                            <h1 style="color: black;" >{{!!items.total_pending_repairs ? thousandSeprator(items.total_pending_repairs) : 0}}</h1>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-card  elevation="5" class="ma-5 pa-3 rounded-lg">
                        <v-row v-if="!toggleView">
                            <v-col>
                                <v-data-table
                                :items="table_items"
                                :headers="headers"
                                style="font-size: large;"
                                hide-default-footer
                                hide-default-header
                                >
                                    <template v-slot:header="{ props: { headers } }">
                                        <thead>
                                            <tr>
                                                <th v-for="h in headers" :class="h.text == 'Technician' ? '' : 'text-right'">
                                                    <b><span style="color:black; font-size:22px">{{h.text}}</span></b>
                                                </th>
                                            </tr>
                                        </thead>
                                    </template>
                                    <template v-slot:[`item.name`]="{ item }">
                                        <span style="font-weight: bold; font-size: 20px;">{{ item.name }}</span>
                                    </template>
                                    <template
                                        v-for="column in rf_statuses"
                                        v-slot:[`item.statuses.${column}`]="{ item }"
                                    >
                                        <slot :name="item.statuses[column]" :item="item.statuses[column]">
                                            <span :style="item.statuses[column] > 0 ? 'color:red' : 'color:black'" :v-bind="item" class="text-h5">
                                                {{ thousandSeprator(item.statuses[column]) }}
                                            </span>
                                        </slot>
                                    </template>

                                    <template v-slot:body.append="{headers}">
                                        <tr>
                                            <td v-for="(header,i) in headers" :key="i" :class="i>0?'text-end' : 'text-start' ">
                                                <span style="font-size: 20px; font-weight: bold;" class="text-h5">{{ thousandSeprator(summations[i]) }}</span>
                                            </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col cols="4" v-for="(tech,i) in items.technicians" :key=i>
                                <v-card
                                    outlined
                                    class="rounded-lg"
                                >
                                    <v-list-item class="mt-2">
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h5 mb-1">
                                                <b>{{tech.name}}</b>
                                                <v-divider class="my-1 pa-0"></v-divider>
                                            </v-list-item-title>
                                            <div class="text-black align-item-center">

                                                <span class="text-center"><b>PENDING REPAIRS: <span style="font-size: larger;" >{{tech.pending_repair}}</span></b></span>
                                            </div>
                                            <div class="text-black">
                                                <span class="text-center"><b>AVERAGE REPAIRS: <span style="font-size: larger;" >{{tech.avg_repair}} / day</span></b></span>
                                            </div>

                                            <v-card-text class="ma-0 pa-0" style="font-size: 12px;">
                                                <v-divider class="my-1 pa-0"></v-divider>
                                                <v-row class="d-flex justify-content-center align-item-center ma-2">
                                                    <v-col cols="3" v-for="(stats,y) in tech.statuses" :key="y" class="text-center">
                                                        <span style="font-size: small;">
                                                            <span style="font-weight: bold;" class="upTextsize">{{ y }}</span>
                                                            <v-divider light class="pa-1 ma-0"></v-divider>
                                                            <span :style="stats > 0 ? 'color:red' : 'color:black'"  class="upTextsize"><b>{{stats}}</b></span>
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                                <!-- <b>Specialties</b>: <i>{{ specialties(tech) }}</i> <br/>
                                                <b>Skills</b>: <i>{{ tech.skills }} </i> -->
                                            </v-card-text>
                                        </v-list-item-content>
                                        <v-list-item-avatar
                                            tile
                                            size="200"
                                            rounded
                                        >
                                            <v-img
                                                style="border-radius: 10%;"
                                                class="elevation-6"
                                                alt=""
                                                :src="tech.base64img"
                                            ></v-img>
                                        </v-list-item-avatar>
                                    </v-list-item>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue'
import PusherFunctionComponent from '@/views/main/Shared/PusherFunctionComponent.vue';
export default {
    mixins: [SharedFunctionsComponentVue,PusherFunctionComponent],
    data(){
        return {
            items:[],
            modal:false,
            date:this.$dayjs().format('YYYY-MM-DD'),
            toggleView:true,
            url_meta:{
                status_color:this.$router.currentRoute.meta.status_color,
                statuses:this.$router.currentRoute.meta.statuses,
                queueing_type:this.$router.currentRoute.meta.title,
                url:this.$router.currentRoute.meta.url,
                realtime_event:this.$router.currentRoute.meta.realtime_event,
                toggle_icon:this.$router.currentRoute.meta.toggle_icon,
                type_id:this.$router.currentRoute.meta.type_id,
            },
            test:0,
            isChangeColor:false,
            // rf_statuses:["TA", "UAS", "QUO", "FCA", "FTA", "WCM", "WFP", "PIN", "WFR", "RET", "REP", "RFD", "DEL", "COL", "DEF", "PP"],
            rf_statuses:["UAS","PIN","REP","QUO"],
            headers:[
                { text: 'TECHNICIAN', align: 'end', value: 'name', class:'upTextsize'},
            ],
            table_items:[],
            summations:['Total']
        }
    },
    mounted(){
        this.callPusher();
        this.getAllItems();
        this.ServerPusher();
        this.checkUrlParams();
    },
    methods:{
        async getAllItems(){
            await this.$store.dispatch('getqueue',{
                url:this.url_meta.url,
                date:this.date,
            }).then(response=>{
                this.items = response.data
                let sum = this.items.technicians.map(e=>{
                    return e.statuses
                })
                this.headers = []
                this.headers.push({ text: 'Technician', align: 'left', value: 'name', class:'upTextsize'})
                this.rf_statuses.forEach(e=>{
                    this.summations.push(_.sumBy(sum, e));
                    this.headers.push({ text: e, align: 'end', value: 'statuses.'+e, class:'upTextsize'})
                })
                this.table_items = this.items.technicians
            })
        },
        ServerPusher(){
            window.Echo.channel('service-channel').listen(this.url_meta.realtime_event,(e)=>{
                if(e.data['origin'] == 'save'){
                    this.items.repair_today += e.data['repair_today']
                    this.items.total_pending_repairs += e.data['pending']
                }
                if('statuses' in e.data){
                    this.items.technicians.forEach((x,i)=>{
                        x.statuses = e.data['statuses'][i]
                    })
                }
                if("technician_name" in e.data){
                    this.items.technicians.forEach(x=>{
                        if(x.name == e.data['technician_name']){
                            if(e.data['origin'] == 'assign_tech'){
                                x.pending_repair += e.data['pending_repair']
                            }
                            else if(e.data['origin'] == 'repair_done'){
                                x.pending_repair -= e.data['pending_repair']
                                this.items.total_pending_repairs -= e.data['pending_repair']
                                this.items.repaired_today += e.data['pending_repair']
                                // this.items.total_pending_repairs -= e.data['pending_repair']
                            }
                        }
                    })
                }
            })
        },
        specialties(tech) {
            if(!!tech) {
                return JSON.parse(tech.specialties).toString().replaceAll(',', ', ');
            }
            return '';
        },
        checkUrlParams(){
            if(Object.keys(this.$route.query).length > 0){
                this.toggleView = Number (this.$route.query.view)
            }
        },
    },
    watch:{
        date:{
            handler(){
                this.getAllItems();
            }
        },
    }
}
</script>

<style scoped>
.ul-listing {
    column-count: auto;
    column-gap: normal;
    column-fill: auto;
    height:77vh;
}
.large-spacing{
    column-width: 9.03rem;
}
.small-spacing{
    column-width: 8.5rem;
}
.toggle-list{
    max-height: 500px;
    column-count: auto;
    flex-flow: column wrap;
    list-style-type: disc;
    list-style-position: inside;
}
.upTextsize{
    font-size: 20px;
}

th{
    font-size: 100px;
}
</style>
