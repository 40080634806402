<template>
  <v-container fluid>
        <h3 class="page-title"> Defective </h3>
        <v-container class="ma-0">
            <v-app id="item-tabs">
                <v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" background-color="#424242" :loaded="loaded" dark show-arrows>
					<v-tabs-slider color="cyan" :loaded="loaded"></v-tabs-slider>
                    <v-tab v-for="(item, index) in items" :key="item" @click="tabs(index)" :loaded="loaded">
                        {{ item }}
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item  v-for="(items,index) in defective_component" :key="index">
                        <keep-alive>
                            <DefectiveComponent :is="items.components" :rfStatus="items.tabStatus" :defective_component="defective_component" :isActive_tab="items.status_text" :tab="index" :loaded="loaded"></DefectiveComponent>
                        </keep-alive>
                    </v-tab-item>
                </v-tabs-items>
            </v-app>
        </v-container>
        <DialogComponent :cp="components"  :scrollable="dialog_scrollable" :width="height(dialog_width)" :retain-focus="false" :loaded="loaded"></DialogComponent>
    </v-container>
</template>

<script>
import DialogComponent from '@/views/main/layouts/dialogs/Dialog.vue';

import DefectiveComponent from "@/views/main/modules/Services/Views/DefectiveComponent/DefectiveComponent.vue";
import DefectiveDtaViewComponent from '@/views/main/layouts/dialogs/Service/Defective/DefectiveDtaViewComponent.vue';
import DefectiveDcaViewComponent from '@/views/main/layouts/dialogs/Service/Defective/DefectiveDcaViewComponent.vue';
import { mapGetters } from 'vuex';
export default {
    components: {
        DialogComponent,
        DefectiveComponent,
        DefectiveDtaViewComponent,
        DefectiveDcaViewComponent,
    },

    data(){
        return {
            components:'',
            items: ["DTA", "DCA"],
            defective_component:[
                {
                components: DefectiveComponent,
                status_text: "DTA",
                dialog: DefectiveDtaViewComponent,
                dialog_title: 'DTA',
                dialog_width: "55%",
                tabStatus: 'DTA',
                scrollable:true,
                dispatch:{
                        get: 'getDefective', 
                        update: ['approveDTA','pullOutDTA'],
                    },
                },
                
                {
                components: DefectiveComponent,
                status_text: "DCA",
                dialog: DefectiveDcaViewComponent,
                dialog_title: 'DCA',
                dialog_width: "55%",
                tabStatus: 'DCA',
                scrollable:true,
                dispatch:{
                        get: 'getDefective',
                        update: ['confirmDCA','confirmRepair','reAssessmentDTA'],
                    },
                },
            ],

            dialog_width: "",
            loaded: false,
            options: {},
            itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
            itemsPerPage: 10,
            tab: null,
            dialog_scrollable:true,
        }
    },

    methods: {
        changeTab(index){
            this.$store.commit('DEFECTIVE_TAB', index)
        },

        tabs(key) {
			this.components = this.defective_component[key].dialog;
			this.dialog_width = this.defective_component[key].dialog_width;
			this.dialog_scrollable = this.defective_component[key].scrollable;
			
            this.$store.commit('REFRESH_DEFECTIVE', true)
			this.$store.commit('DEFECTIVE_DIALOG_TITLE',this.defective_component[key].dialog_title)
			this.$store.commit('DEFECTIVE_TAB',this.defective_component[key].status_text)
			this.$store.commit('DEFECTIVE_DISPATCH',this.defective_component[key].dispatch)
            
			// this.$store.commit('FOC_TAB',this.foc_component[key].getters)
		},

        height(width) {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs': return "100%"
				case 'sm': return "100%"
				case 'md': return width
				case 'lg': return width
				case 'xl': return width
			}
		},
    },

    computed: {
        ...mapGetters([
            'GET_DEFECTIVE',
            'GET_DEFECTIVE_TAB',
            'GET_DEFECTIVE_DISPATCH',
            'GET_DEFECTIVE_DIALOG_TITLE',
            'GET_REFRESH_DEFECTIVE',
        ])
    },

    mounted() {
        this.tabs(0)
    },


}
</script>

<style scoped>
#group-tab {
		padding: 0 !important;
	}
</style>