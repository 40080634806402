<template>
    <div class="w-100">
        <v-app id="my_vue_app">
            <v-card>
                <v-card-title>
                    <v-container>
                        <v-row>
                            <v-col cols="2">
                                <v-autocomplete
                                    v-model="filter.department_ids"
                                    @change="getDeptEmployeeSchedule({ query: search })"
                                    :items="dropdowns.departments_lists"
                                    label="Department"
                                    auto-select-first
                                    small-chips
                                    clearable
                                    multiple
                                    outlined
                                    required
                                    dense
                                >
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="mb-2">
                            <v-spacer></v-spacer>
                            <div class="row col-sm-12 col-md-4 mr-3 align-items-end">
                                <v-text-field
                                    id="searchBar"
                                    v-model="filter.search"
                                    label="Search Department Name"
                                    single-line
                                    hide-details
                                    @change="searchInput()"
                                >
                                </v-text-field>
                                <v-icon @click="searchInput()">mdi-magnify</v-icon>
                            </div>
                        </v-row>
                    </v-container>
                </v-card-title>
                <v-container fluid>
                    <v-data-table
                        :headers="datatable.headers"
                        :items="datatable.items"
                        :search="search"
                        :options.sync="datatable.options"
                        :server-items-length="datatable.totalItems"
                        :items-per-page="10"
                        :loading="loading.datatable"
                        :footer-props="{
                            itemsPerPageOptions: datatable.itemsPerPageOptions
                        }"
                        dense
                        loading-text="Loading... Please wait"
                        fixed-header
                    >
                        <template v-slot:[`item.action`]="{ item }">
                                <v-tooltip bottom color="#f69f1a">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn text icon color="orange"
                                                v-if="userAccess.view"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                            <v-icon

                                                class="btn-action"
                                                small
                                                @click="viewDepartmentSchedule(item)"
                                            >mdi-eye</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>View Department Schedule</span>
                                </v-tooltip>
                            <v-tooltip bottom color="blue">
                                <template v-slot:activator="{ on, attrs }">
                                        <v-btn text icon color="blue"
                                            v-if="userAccess.edit"
                                            v-bind="attrs"
                                            v-on="on"
                                         >
                                            <v-icon
                                                class="btn-action"
                                                small
                                                @click="editDepartmentSchedule(item)"
                                                >mdi-pencil</v-icon
                                            >
                                        </v-btn>
                                </template>
                                <span>Edit Department Schedule</span>
                            </v-tooltip>
                            <!-- <v-tooltip bottom color="red">
                                <template v-slot:activator="{ on, attrs }">
                                        <v-btn text icon color="red"
                                            v-if="userAccess.delete"
                                            v-bind="attrs"
                                            v-on="on"
                                         >
                                            <v-icon
                                                class="btn-action"
                                                small
                                                @click="deleteDepartmentSchedule(item.id)"
                                                >mdi-delete</v-icon
                                            >
                                        </v-btn>
                                </template>
                                <span>Delete Department Schedule</span>
                            </v-tooltip> -->
                        </template>
                        <template v-slot:[`item.status`]="{item}">
                            <span :class="`badge badge-${item.status}`">{{ item.status }}</span>
                        </template>
                    </v-data-table>
                </v-container>
            </v-card>
        </v-app>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2';
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
export default {
    mixins:[SharedFunctionsComponentVue],
    data(){
        return {
            loaded: false,
            search: "",
            filter:{
                search:"",
                dialog: false,
                department_ids: [],
                year: this.$dayjs().year(),
                date_from_menu: false,
                date_to_menu: false,
                effectivity_date: []
            },

            datatable:{
                headers:
                    [
                        { text: 'Department Name', align: 'start', value: 'name'},
                        { text: 'Schedule Count', align: 'start', value: 'schedule_count'},
                        { text: "Action", align: "center", value: "action" }
                    ],
                items:[],
                options:{},
                totalItems: 0,
                itemsPerPageOptions: [5, 10, 15, 50, 100],
                itemsPerPage: 10,
            },

            loading:{
                datatable: false,
                button: {
                    filter: false,
                    biometrics: false
                }
            },

            userAccess: {
                view: false,
                create: false,
                edit: false,
                delete: false,
            },

            dropdowns:{
                departments_lists: [],
                schedule_types: []
            },

            selected_status: '',
            isDepartmentView: 0,
            component_dispatches:[
                'getDepartment',
                'getEmployeeScheduleType'
			],
            hr_user_department_access: []
        }
    },
    mounted(){
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        this.getSearchFromUrl()
        this.getAllDropdowns();
        this.getYearRange('YEAR_RANGE');
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS',
            'GET_TIMEKEEPING_NEW_DATA',
            'USERACCOUNT_LOAD',
            'GET_HR_TAB_IS_ACTIVE',
            'GET_HR_DISPATCH',
            'GET_SELECTED_DATE_RANGE',
            'GET_LIST_DEPARTMENT',
            'GET_HR_USER_DEPARTMENTS',
            'DIALOG',
            'GET_EMPLOYEE_SCHEDULE_TYPE_SELECTION',
            'GET_YEAR_RANGE'
        ]),
        dateRangeText () {
            if(this.filter.effectivity_date.length == 2){
                return this.filter.effectivity_date.join(' ~ ')
            }
        },
    },
    components:{
    },
    methods:{
        selectedStatus(data){
            this.selected_status = data.value
            this.getDeptEmployeeSchedule({ query: this.search })
        },
        async getDeptEmployeeSchedule(filter = null){
            this.datatable.items = [];
            this.loading.datatable = true;
            let payload = {
                page            : this.datatable.options.page,
                itemsPerPage    : this.datatable.options.itemsPerPage,
                sortBy          : this.datatable.options.sortBy,
                sortDesc        : this.datatable.options.sortDesc,
                status          : this.selected_status,
                filter          : filter,
                ...this.filter
            };
            await this.$store.dispatch(this.GET_HR_DISPATCH.get, payload).then((response) => {
                this.datatable.items                    = response.data.data;
                this.datatable.totalItems               = response.data.total;
                this.loading.datatable                  = false;
            }).catch((err)=>{
                Swal.fire({
                    title: 'Error',
                    text: 'Network Error',
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
                console.log(err)
            });
        },
        searchInput(){
            this.datatable.options.page = 1
            this.getDeptEmployeeSchedule({ query: this.search });
        },
        async viewDepartmentSchedule(item){
            await this.$store.dispatch(this.GET_HR_DISPATCH.show, item).then((response) => {
                this.$store.commit("HR_OTHER_TABS_DIALOG_VIEW", response);
                this.$store.commit("DIALOG", true);
                this.$store.commit("ACTION", "View");
            })
        },
        async editDepartmentSchedule(item){
            await this.$store.dispatch(this.GET_HR_DISPATCH.show, item).then((response) => {
                this.$store.commit("HR_OTHER_TABS_DIALOG_VIEW", response);
                this.$store.commit("DIALOG", true);
                this.$store.commit("ACTION", "Update");
            })
        },
        getSearchFromUrl() {
            if (Object.keys(this.$route.query).length != 0) {
                Object.keys(this.$route.query).forEach(e=>{
                if(e != 'for_approval')
                    this.filter.search = atob(this.$route.query[e]);
                })
                this.searchInput();
            }
        },
        extractBiometricsData(){
            this.loading.button.biometrics = true;
            let period = [this.GET_SELECTED_DATE_RANGE.from, this.GET_SELECTED_DATE_RANGE.to]

            // let period = this.getDatesFromRange(this.GET_SELECTED_DATE_RANGE.from, this.GET_SELECTED_DATE_RANGE.to, "array");
            let payload = {
                period: period,
                filter: this.filter
            }
            this.$store.dispatch('getBiometricsData', payload).then((res)=>{
                this.$store.commit('BIOMETRICS_SUMMARY_DIALOG', true)
                this.loading.button.biometrics = false;
                this.resetFilters()
            }).catch((err)=>{
                Swal.fire(
                    "Error",
                    "",
                    "error"
                )
                this.loading.button.biometrics = false;
                this.filter.dialog = false;
                this.resetFilters()
                console.log(err)
            })
        },
        viewEmployeeBioTimeData(data){
            // let period = this.getDatesFromRange(this.GET_SELECTED_DATE_RANGE.from, this.GET_SELECTED_DATE_RANGE.to, "array");
            let period = [this.GET_SELECTED_DATE_RANGE.from, this.GET_SELECTED_DATE_RANGE.to]
            let payload = {
                emp_code: data.emp_code,
                period: period
            }
            this.$store.dispatch('viewEmployeeBioTime', payload).then((res)=>{
                if(res.biometricdata.length != 0){
                    this.$store.commit('EMPLOYEE_BIOMETRICS_DIALOG', true)
                }else{
                    Swal.fire({
                        title: "No Biometrics for the selected period.",
                        text: "",
                        icon: "warning"
                    })
                    return false;
                }
            }).catch((err)=>{
                Swal.fire(
                    "Error",
                    "",
                    "error"
                );
                console.log(err)
            })
        },
        closeEmployeeFilterDialog(){
            this.filter.dialog = false;
            this.resetFilters()
        },
        openEmployeeFilterDialog(){
            this.loading.button.filter = true;
            this.filter.dialog = true;
            this.loading.button.filter = false;
        },
        getAllDropdowns(){
            this.component_dispatches.forEach(el=>{
                this.$store.dispatch(el)
            })
        },
        resetFilters(){
            Object.assign(this.filter, {
                dialog: false,
                department_ids:[],
            })
        },
        deleteEmployeeSchedule(id) {
			Swal.fire({
				icon: 'question',
				text: "Are you sure you want to delete employee schedule?",
				cancelButtonText: "Cancel",
				showCancelButton: true,
				allowOutsideClick: false,
				confirmButtonColor: "#397373",
				cancelButtonColor: "#397373",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store
                    .dispatch(this.GET_HR_DISPATCH.delete, id)
                    .then((res) => {
                        Swal.fire(res.icon.toUpperCase(),res.message,res.icon);
                        this.getDeptEmployeeSchedule({ query: this.search });
                    });
                }
            });
		},
        closeDateRange(clear = 0){
            if(clear == 1){
                this.filter.date_from_menu = false;
                this.filter.effectivity_date = [];
                this.getDeptEmployeeSchedule({ query: this.search })
            }else{
                if(this.filter.effectivity_date[0] > this.filter.effectivity_date[1]){
                    this.filter.date_from_menu = false;
                    this.filter.effectivity_date = [];
                    Swal.fire(
                        "Date Range invalid!",
                        "",
                        "warning"
                    )
                    return false
                }
                if(this.filter.effectivity_date.length == 2){
                    this.filter.date_from_menu = false;
                    this.getDeptEmployeeSchedule({ query: this.search })
                }
            }
        }
    },
    watch:{
        'datatable.options': {
            handler() {
                this.getDeptEmployeeSchedule({ query: this.search });
            },
            deep: true,
        },
        GET_TIMEKEEPING_NEW_DATA:{
            handler(val){
                if(val && this.GET_HR_TAB_IS_ACTIVE == 'department_schedule'){
                    this.getDeptEmployeeSchedule({ query: this.search });
                    this.$store.commit('TIMEKEEPING_NEW_DATA', false)
                }
            }
        },
        USER_ACCESS: {
            handler(val) {
                if(val != "fail"){
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                this.userAccess.create = false;
                    val.forEach(e => {
                        if (e.actions_code == 'edit') {
                            this.userAccess.edit = true;
                        } else if (e.actions_code == 'delete') {
                            this.userAccess.delete = true;
                        } else if (e.actions_code == 'view') {
                            this.userAccess.view = true;
                        } else if (e.actions_code == 'create') {
                            this.userAccess.create = true;
                        }
                    });
                }
            }
        },
        GET_LIST_DEPARTMENT:{
            handler(val){
                this.dropdowns.departments_lists = val
            }
        },
        GET_HR_USER_DEPARTMENTS:{
            handler(val){
                this.hr_user_department_access = val;
                let filtered_departments = this.dropdowns.departments_lists.filter(x=> this.hr_user_department_access.includes(x.value) )
                this.dropdowns.departments_lists = filtered_departments
            }
        },
        DIALOGS:{
            handler(val){
                if(val){
                    this.$store.commit("HR_OTHER_TABS_DIALOG_VIEW", {});
                }
            }
        },
        GET_EMPLOYEE_SCHEDULE_TYPE_SELECTION:{
            handler(val){
                this.dropdowns.schedule_types = val
            }
        },
        GET_HR_TAB_IS_ACTIVE:{
            handler(val){
                if(val  == 'department_schedule'){
                    this.getDeptEmployeeSchedule({ query: this.search });
                }
            }
        }
    }
}
</script>
<style scoped>
.selected {
    opacity: 100%;
}

.non-selected {
    opacity: 40%;
}

.custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
