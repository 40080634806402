<template>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
          <span class="text-h5">Department Information</span>
          <v-row class="m-0">
            <v-col cols="pull-right-10 p-2">
              <v-btn text icon small color="gray" class="float-right" @click="closeDialog()">
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-container fluid class="py-5">
            <v-row>
              <v-col>
                <v-text-field
                  label="Department Name"
                  required
                  v-model="form.name"
                  outlined
                  dense
                  :disabled="disable_view"
                  :rules="errors.name"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Code"
                  v-model="form.code"
                  outlined
                  dense
                  :disabled="disable_view"
                  :rules="errors.code"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-switch v-model="form.active" label="active" color="primary" input-value="1" :disabled="disable_view"></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="text-center">
          <v-row class="ma-1" v-if="actions != 'View'">
            <v-col cols="12">
              <v-btn class="float-center" @click="actions == 'Submit' ? addDepartment() : UpdateDepartment()" :disabled="disable_view">
                <span>{{ actions }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </template>
  <script>
  import Swal from 'sweetalert2'
  import { mapGetters } from 'vuex'
  export default {
    props: ['action'],
    data() {
      return {
        valid: true,
        form: {
          name: null,
          code: null,
          manager: [],
          active: 1,
          locations: [],
          type:'',
        },
        errors: {
          name: [v => !!v || 'Name is Required'],
          code: [v => !!v || 'Code is Required'],
        },
        item: [],
        trash: [],
        types:['dept','accounting','role'],
        disable_view:false,
      };
    },
    computed: {
      ...mapGetters(['GET_ALL_MANAGERS', 'DIALOGS', 'GET_EDIT_ACCOUNTING_DEPARTMENT', 'ACTION', 'GET_BIO_METRIC_LOCATION_SELECTION','USERACCOUNT_LOAD','GET_NEW_ACCOUNTING_DEPARTMENT']),
      actions() {
        if (this.ACTION == 'Submit') {
          this.disable_view = false;
          this.form = {
            name: null,
            code: null,
            manager: [],
            active: 1,
          };
          return 'Submit'
        } else if (this.ACTION == 'Update') {
          this.disable_view = false;
          this.getAllEditDepartment();
          return 'Update'
        }else if (this.ACTION == 'View') {
          this.disable_view = true;
          this.getAllEditDepartment();
          return 'View'
        }
      },
    },
    mounted() {
  
    },
    methods: {
      closeDialog() {
        this.$store.commit('DIALOG', false);
        this.resetFields()
      },
      getAllEditDepartment(){
          this.form = {
            name: this.GET_EDIT_ACCOUNTING_DEPARTMENT.name,
            code: this.GET_EDIT_ACCOUNTING_DEPARTMENT.code,
            manager: this.GET_EDIT_ACCOUNTING_DEPARTMENT.user_access,
            active: this.GET_EDIT_ACCOUNTING_DEPARTMENT.active,
            locations: this.GET_EDIT_ACCOUNTING_DEPARTMENT.locations,
            type: this.GET_EDIT_ACCOUNTING_DEPARTMENT.type,
          };
          this.item = this.GET_EDIT_ACCOUNTING_DEPARTMENT;
      },
      addDepartment() {
        this.$refs.form.validate();
        const code_swal_text = this.form.code ? '' : 'Code'
        const name_swal_text = this.form.name ? '' : 'Department Name'
        const array_text = [name_swal_text, code_swal_text];
        const filtered = array_text.filter(function (el) {
          return el.replace(',', '')
        });
        Object.assign(this.form,{active:1});
        if (this.form.name && this.form.code) {
          this.$store.dispatch('createAccountingDepartment', this.form).then(success => {
            this.resetFields();
            this.$store.commit('DIALOG', false)
            Swal.fire({
              title: 'Success',
              icon: 'success',
              timer: 1500,
              showConfirmButton: false,
            });
          }).catch(e=>{
            Swal.fire({
              text: 'Name/Code has already been taken',
              icon: 'warning',
            });
          })
        } else {
          Swal.fire({
            text: `Fill Required Fields (${filtered})`,
            icon: 'warning',
            allowOutsideClick: false,
          });
        }
      },
      UpdateDepartment() {
        this.$refs.form.validate();
        this.$store
          .dispatch('updateAccountingDepartment', {
            id: this.item.id,
            payload: this.form,
          })
          .then(success => {
            this.$store.commit('DIALOG', false);
            Swal.fire({
              title: 'Update Success',
              icon: 'success',
              timer: 1500,
              showConfirmButton: false,
            });
          })
        this.resetFields();
      },
      resetFields() {
        Object.assign(this.$data, this.$options.data.call(this))
        this.$store.commit('EDIT_ACCOUNTING_DEPARTMENT',{});
      },
      remove(item) {
        this.form.manager.splice(this.form.manager.indexOf(item), 1);
        this.trash.push(item);
      }
    },
    watch:{
        GET_NEW_ACCOUNTING_DEPARTMENT:{
            handler(val){
                if(val){
                    this.closeDialog()
                }
            }
        }
    }
  };
  </script>
  