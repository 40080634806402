<template>
    <div class="w-100">
        <v-app id="my_vue_app">
            <v-card>
                <v-card-title>
                    <v-container>
                        <v-row dense no-gutters class="mb-2">
                            <v-col cols="12" lg="4" md="2" sm="2">
                                <v-autocomplete  v-model="filter.department_id" :items="dropdown.department_list" item-text="text" item-value="value" label="Department" auto-select-first outlined required dense></v-autocomplete>
                            </v-col>
                            <v-col class="ml-3" cols="12" lg="4" md="2" sm="2">
                                <v-btn
                                @click="getOvertimeRecord()"
                                >
                                    Filter
                                </v-btn>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="12" sm="12" md="3" class="d-flex justify-content-end align-items-center">
                                <a v-for="(status, index ) in statuses" :key="index" :class="`ml-1 badge badge-${status.text} ${status.value == selected_status ? `selected` : `non-selected`}`" @click="selectedStatus(status)">
                                    {{ status.text ? status.text.replace('-'," ") : null  }}
                                </a>
                            </v-col>
                        </v-row>
                        <v-row dense class="pl-3">
                            <div class="col-sm-12 col-md-2" id="date_from">
                                <v-menu v-model="filter.menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            v-model="filter.date_from"
                                            readonly
                                            densed
                                            append-icon="mdi-calendar-clock"
                                            label="Date From"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filter.date_from" @input="filter.menu1 = false"></v-date-picker>
                                </v-menu>
                            </div>
                            <div class="col-sm-12 col-md-2" id="date_to">
                                <v-menu
                                v-model="filter.menu2"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                min-width="290px"
                                >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                    v-model="filter.date_to"
                                    readonly
                                    densed
                                    append-icon="mdi-calendar-clock"
                                    label="Date to"
                                    v-on="on">
                                    </v-text-field>
                                </template>
                                    <v-date-picker v-model="filter.date_to" @input="filter.menu2 = false"></v-date-picker>
                                </v-menu>
                            </div>
                            <v-spacer></v-spacer>
                            <div class="row col-sm-12 col-md-3 mr-3 align-items-end">
                                <v-text-field
                                    id="searchBar"
                                    v-model="filter.search"
                                    label="Search Employee Name/ID"
                                    single-line
                                    hide-details
                                    @change="getOvertimeRecord()"
                                >
                                </v-text-field>
                                <v-icon @click="getOvertimeRecord()">mdi-magnify</v-icon>
                            </div>
                        </v-row>
                    </v-container>
                </v-card-title>
                <v-container fluid>
                    <v-data-table
                        :headers="headers_hr"
                        :items="GET_OVERTIME_POV.data"
                        :search="search"
                        :options.sync="options"
                        :items-per-page="10"
                        :server-items-length="totalItems"
                        :loading="!loaded"
                        :footer-props="{
                            itemsPerPageOptions: itemsPerPageOptions
                        }"
                        loading-text="Loading... Please wait"
                        fixed-header
                        dense
                    >
                        <template v-slot:[`item.action`]="{ item }">
                            <v-btn text icon color="orange" v-if="userAccess.view">
                                <v-icon class="btn-action" small @click="viewOvertime(item)">mdi-eye</v-icon>
                            </v-btn>
                            <v-btn text icon color="blue" v-if="(userAccess.edit && item.status != 3)">
                                <v-icon class="btn-action" small @click="editOvertime(item)">mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-container>
            </v-card>
        </v-app>
    </div>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            loaded: false,
            totalItems: 0,
            options: {},
            items: [],
            itemsPerPageOptions: [5, 10, 15, 50, 100],
            itemsPerPage: 10,
            search: "",
            filter: {
                search: "",
                department_id: "",
                menu1: false,
                menu2: false,
                date_from: this.$dayjs().startOf('month').format('YYYY-MM-DD'),
                date_to: this.$dayjs().endOf('month').format('YYYY-MM-DD'),

            },

            headers_hr:[
                { text: 'Employee Name', align: 'left', value: 'full_name' },
                { text: 'Department', align: 'left', value: 'department_name' },
                { text: 'Position', align: 'left', value: 'position_name' },
                { text: 'OT Count', align: 'center', value: 'ot_count' },
                { text: "Action", align: "center", value: "action" },
            ],
            userAccess: {
                view: false,
                create: false,
                edit: false,
                delete: false,
            },
            isDepartmentView: 0,

            statuses: [{
                    text: 'all',
                    value: 0
                },
                {
                    text: 'saved',
                    value: 1
                },
                {
                    text: 'partial',
                    value: 2
                },
                {
                    text: 'completed',
                    value: 3
                }
            ],
            selected_status: 0,
            user_department: "",
            hr_id: "",
            checked_department_ids: [],
            dropdown:{
                department_list: []
            },


        }
    },
    mounted() {
        this.$store.dispatch("getDepartment");
        // this.$store.dispatch("checkDepartmentAccess");
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id);
    },
    computed: {
        ...mapGetters([
            'USER_ACCESS',
            'GET_HR_OVERTIME_NEW_DATA',
            'DIALOGS',
            'USERACCOUNT_LOAD',
            'GET_HR_TAB_IS_ACTIVE',
            'GET_LIST_DEPARTMENT',
            'GET_HR_OVERTIME_NEW_DATA',
            'GET_HR_DISPATCH',
            'GET_OVERTIME_POV'
        ]),
    },
    components: {},
    methods: {
        async getOvertimeRecord() {
            this.loaded = false;
            this.items = [];
            let payload ={
                access: this.USER_ACCESS.map(e => e.actions_code)
            }
            await this.$store.dispatch('checkDepartmentAccess', payload).then(response=>{
                this.checked_department_ids = response;
                // this.$store.commit('DEPARTMENT_ACCESS', this.checked_department_ids)
                    let payload ={
                        ids: this.checked_department_ids
                    }
                   this.$store.dispatch('getDepartment', payload).then((response) => {

                    let payload = {
                        page: this.options.page,
                        itemsPerPage: this.options.itemsPerPage,
                        search: this.filter.search,
                        department_id: this.filter.department_id,
                        date_from: this.filter.date_from,
                        date_to: this.filter.date_to,
                        selected_status: this.selected_status,
                        department_ids: this.checked_department_ids
                    };
                    this.$store.dispatch(this.GET_HR_DISPATCH.get, payload).then((response) => {
                        this.items = Array.from(Object.values(response.data))
                        this.loaded = true;
                        this.totalItems = response.total;
                        this.user_department = this.USERACCOUNT_LOAD.department_id;
                    });
                });
            })
        },
        viewOvertime(item) {
            item.department_ids = this.checked_department_ids
            item.date_range     = [this.filter.date_from, this.filter.date_to];
            let payload = {
                id: item.id,
                date_range: [this.filter.date_from, this.filter.date_to]
            }
            this.$store.dispatch(this.GET_HR_DISPATCH.show, payload).then((res) => {
                this.$store.commit("HR_OTHER_TABS_DIALOG_VIEW", res);
                this.$store.commit("DIALOG", true);
                this.$store.commit("ACTION", "View");
            })
        },
        editOvertime(item) {
            item.date_range     = [this.filter.date_from, this.filter.date_to];
            let payload = {
                id: item.id,
                date_range: [this.filter.date_from, this.filter.date_to]
            }
            this.$store.dispatch(this.GET_HR_DISPATCH.show, payload).then((res) => {
                this.$store.commit("HR_OTHER_TABS_DIALOG_VIEW", res);
                this.$store.commit("DIALOG", true);
                this.$store.commit("ACTION", "Update");
            })
        },
        resetfields() {
            this.form.employee_id = "",
            this.form.ot_date = this.$dayjs().format('YYYY-MM-DD'),
            this.form.ot_in = '18:00',
            this.form.ot_out = "",
            this.form.ot_hours = "",
            this.form.reason = "",
            this.OTFiles = []
        },
        selectedStatus(data) {
            this.selected_status = data.value
            this.getOvertimeRecord()
        },
        checkDepartmentForEdit(){
            if(this.USERACCOUNT_LOAD.department_id == this.hr_id || this.USERACCOUNT_LOAD.super_admin == 1){
                return true
            }
            return false
        }
    },
    watch: {
        options: {
            handler() {
                this.getOvertimeRecord();
            },
            deep: true,
        },
        USER_ACCESS: {
            handler(val) {
                if (val != "fail") {
                    this.userAccess.edit = false;
                    this.userAccess.delete = false;
                    this.userAccess.view = false;
                    this.userAccess.create = false;
                    val.forEach(e => {
                        if (e.actions_code == 'edit') {
                            this.userAccess.edit = true;
                        } else if (e.actions_code == 'delete') {
                            this.userAccess.delete = true;
                        } else if (e.actions_code == 'view') {
                            this.userAccess.view = true;
                        } else if (e.actions_code == 'create') {
                            this.userAccess.create = true;
                        }
                    });
                    // this.getOvertimeRecord();
                }
            }
        },
        GET_HR_OVERTIME_NEW_DATA: {
            handler(val) {
                if (val) {
                    this.getOvertimeRecord();
                    this.$store.commit('HR_OVERTIME_NEW_DATA', false)
                }
            }
        },
        GET_LIST_DEPARTMENT: {
            handler(val) {
                this.hr_id = val.find(e => e.code == 'HR-A').value
                this.dropdown.department_list = val
                if(this.USERACCOUNT_LOAD.department_id !== this.hr_id){
                    val = val.filter(item => this.checked_department_ids.includes(item.value));
                   this.dropdown.department_list = val
                }
            },
            deep: true
        },
        GET_HR_TAB_IS_ACTIVE:{
            handler(val){
                this.getOvertimeRecord();
            },
            deep: true
        }
    }
}
</script>
<style scoped>
.selected {
    opacity: 100%;
}

.non-selected {
    opacity: 40%;
}
</style>
