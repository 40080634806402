<template>
    <v-container fluid class="pa-3">
        <h3 class="page-title">Logistics Basket</h3>
        <v-container class="ma-0">
            <v-app id="item-tabs" class="row mx-auto mt-5">
                <v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" background-color="#424242" dark class="col-12" show-arrows>
                    <v-tab>Scheduling</v-tab>
                    <v-tab>Calendar</v-tab>
                    <v-tab>Vehicle</v-tab>

                    <v-tabs-items v-model="tab" touchless>
<!-- 
                        <v-tab-item>
                            <v-tabs v-model="tab_scheduling" color="cyan" slider-color="cyan" class="col-12 p-0" show-arrows>
                                <v-tab>Rush</v-tab>
                                <v-tab>Regular</v-tab>
                                <v-tab>All</v-tab>
                                <v-tabs-items v-model="tab_scheduling" touchless>
                                    <v-tab-item>
                                        <LogisticsBasketComponent :tab="'scheduling'" :rush="1" :service="service"></LogisticsBasketComponent>
                                    </v-tab-item>
                                    <v-tab-item>
                                        <LogisticsBasketComponent :tab="'scheduling'" :rush="0" :service="service"></LogisticsBasketComponent>
                                    </v-tab-item>
                                    <v-tab-item>
                                        <LogisticsBasketComponent :tab="'scheduling'" :rush="null" :service="service"></LogisticsBasketComponent>
                                    </v-tab-item>
                                </v-tabs-items>
                            </v-tabs>
                        </v-tab-item> -->
                        <v-tab-item>
                            <v-container fluid>
                                <v-row class="pt-2">
                                    <v-col cols="12" class="d-flex text-align-start ml-4">
                                        <div class="btn-toolbar" v-if="userAccess.create">
                                            <LogisticsBasketAddComponent :service="service"></LogisticsBasketAddComponent>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-tabs v-model="tab_scheduling" color="cyan" slider-color="cyan" class="col-12 p-0" show-arrows>
                                <v-tab>Rush</v-tab>
                                <v-tab>Regular</v-tab>
                                <v-tab>All</v-tab>
                                <v-tabs-items v-model="tab_scheduling" touchless>
                                    <v-tab-item>
                                        <LogisticsBasketTableComponent :tab="tab_scheduling" :rush="1" :service="service"></LogisticsBasketTableComponent>
                                    </v-tab-item>
                                    <v-tab-item>
                                        <LogisticsBasketTableComponent :tab="tab_scheduling" :rush="0" :service="service"></LogisticsBasketTableComponent>
                                    </v-tab-item>
                                    <v-tab-item>
                                        <LogisticsBasketTableComponent :tab="tab_scheduling" :rush="null" :service="service"></LogisticsBasketTableComponent>
                                    </v-tab-item>
                                </v-tabs-items>
                            </v-tabs>
                        </v-tab-item>
                        <v-tab-item>
                            <LogisticsBasketComponent :tab="'scheduled'" :rush="null" :service="service"></LogisticsBasketComponent>
                        </v-tab-item>
                        <v-tab-item>
                            <VehicleScheduleComponentVue :service="service"></VehicleScheduleComponentVue>
                        </v-tab-item>
                    </v-tabs-items>
                </v-tabs>
            </v-app>
        </v-container>
        <LogisticsBasketViewComponent></LogisticsBasketViewComponent>
        <LogisticsBasketEditComponent></LogisticsBasketEditComponent>
    </v-container>
</template>
<script>

import LogisticsBasketComponent from './LogisticsBasketComponent.vue'
import VehicleScheduleComponentVue from './VehicleScheduleComponent.vue'
import LogisticsBasketAddComponent from './LogisticsBasketAddComponent.vue'
import LogisticsBasketTableComponent from './LogisticsBasketTableComponent.vue'
import LogisticsBasketViewComponent from './LogisticsBasketViewComponent.vue'
import LogisticsBasketEditComponent from './LogisticsBasketEditComponent.vue'

import { mapGetters } from 'vuex'

export default {
    components: {
        LogisticsBasketComponent,
        VehicleScheduleComponentVue,
        LogisticsBasketAddComponent,
        LogisticsBasketTableComponent,
        LogisticsBasketViewComponent,
        LogisticsBasketEditComponent,

    },
    data() {
        return {
            tab: null,
            tab_scheduling: null,
            service: this.$router.currentRoute.meta.service,
            userAccess: {
                view: false,
                create: false,
            },
        }
    },
    mounted(){
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
        this.$store.dispatch('getAllCustomers')
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
    },

    watch: {
        USER_ACCESS: {
            handler(val) {
                if (val != "fail") {
                    this.userAccess.view = false;
                    this.userAccess.create = false;
                    val.forEach((e) => {
                        if (e.actions_code == "create") {
                            this.userAccess.create = true;
                        }
                        if (e.actions_code == "view") {
                            this.userAccess.view = true;
                        }
                    });
                }
            },
            immediate: true,
        },
    },

    computed: {
        ...mapGetters([
            'USER_ACCESS',
        ])
    },
}
</script>
<style>
    #group-tab{
        padding: 0 !important;
    }
</style>
