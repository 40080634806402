import { render, staticRenderFns } from "./ItemsTableComponent.vue?vue&type=template&id=5db451a7&scoped=true"
import script from "./ItemsTableComponent.vue?vue&type=script&lang=js"
export * from "./ItemsTableComponent.vue?vue&type=script&lang=js"
import style0 from "./ItemsTableComponent.vue?vue&type=style&index=0&id=5db451a7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5db451a7",
  null
  
)

export default component.exports