<template>
    <div class="pb-4" style="width:100%">
        <v-card class="pa-2 pt-1" style="border-radius:0px">
            <v-card-title class="mx-3">
                <v-row class="w-100">
                    <v-spacer/>
                    <v-col lg="1">
                        <!-- <v-btn
                            class="float-right"
                            @click="getAllRfs(); options.page = 1"
                        >
                            filter
                        </v-btn> -->
                        <v-tooltip bottom color="#f69f1a">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    class=" float-right"
                                    color="#f69f1a"
                                    :loading="loading"
                                    fab
                                    text
                                    small
                                    outlined
                                    @click="getAllRfs()"
                                >
                                    <v-icon>mdi-sync</v-icon>
                                </v-btn>

                            </template>
                            <span>Filter</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
                <v-row class="w-100">
                    <v-spacer />
                    <v-col cols="12" lg="2" class="pa-0 mr-2">
                        <v-text-field
                            v-model="search"
                            label="Search RF#"
                            single-line
                            hide-details
                            clearable
                            @keydown.enter="getAllRfs(); options.page = 1"
                            @click:clear="search='';getAllRfs()"
                        />
                    </v-col>
                    <v-col cols=12 lg="2" class="pa-0 mr=3">
                        <v-text-field
                            v-model="searchItem"
                            label="Search Item Model / Name"
                            append-outer-icon="mdi-magnify"
                            single-line
                            hide-details
                            clearable
                            @keydown.enter="getAllRfs(); options.page = 1"
                            @click:append-outer="getAllRfs(); options.page = 1"
                            @click:clear="searchItem='';getAllRfs()"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <div>
                    <label for="">{{ rfStatus }} Count: </label>
                    <span style="font-weight:bold">{{ totalItems }}</span>
                </div>
                <v-data-table
                    :headers="headers"
                    :items="rfLists"
                    :loading="loading"
                    :no-data-text="noDataText"
                    :search="search"
                    :options.sync="options"
                    :server-items-length="totalItems"
                >
                <template v-slot:item.created_at="{ item }">
                    <td class="px-0">{{ agingDate(item.created_at) }}</td>
                </template>

                <template v-slot:item.date="{ item }">
                    <td>{{ date(item) }}</td>
                </template>

                <template v-slot:item.ready_for_qa="{ item }">
                    <v-chip :color="item.ready_for_qa == 1 ? 'success' : 'error'" small>
                        {{ item.ready_for_qa == 1 ? 'Yes' : 'No' }}
                    </v-chip>
                </template>

                <template v-slot:item.action="{ item }">
                    <td>
                        <span class="text-nonwrap">
                            <v-btn v-if="rfStatus == 'UAS'" text icon color="orange">
                                <v-icon class="btn-action" small @click="showRfEstDialog(item)">mdi-eye</v-icon>
                            </v-btn>

                            <v-btn v-if="rfStatus == 'WFP' || rfStatus == 'WFR' || rfStatus == 'PIN' || rfStatus == 'REP' || rfStatus == 'RET'" text icon color="orange">
                                <v-icon class="btn-action" small @click="showTechRfEstDialog(item)">mdi-eye</v-icon>
                            </v-btn>

                            <!-- <v-btn text icon color="blue">
                                <v-icon class="btn-action" small @click="editRepairFormDetails(item)">fas fa-edit</v-icon>
                            </v-btn>
                            <v-btn v-if="!item.sc_rrf_id" text icon color="red">
                                <v-icon class="btn-action" small @click="deleteRepairForm(item)">fas fa-trash-alt</v-icon>
                            </v-btn> -->
                        </span>
                    </td>
                </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <RfEstimationDialogComponentVue v-if="rfStatus == 'UAS'" :rf="rf" :status="rfStatus" @rfEstimationSuccess="rfEstimationSuccess" />
        <TechRfEstDialogComponentVue
            v-if="rfStatus == 'WFP' || rfStatus == 'WFR' || rfStatus == 'PIN' || rfStatus == 'REP' || rfStatus == 'RET'"
            @successAddNewEstimation="successAddNewEstimation"
            @successRequestNewSp="successRequestNewSp"
            @successStartRepair="successStartRepair"
            @successRepair="successRepair"
            :status="rfStatus"
            :warehousesCode="warehousesCode"
            :rf="rf"
        />
    </div>
</template>

<script>
import { mapMutations, mapGetters, mapState } from 'vuex';
import TechRfEstDialogComponentVue from './TechRfEstDialogComponent.vue';
import RfEstimationDialogComponentVue from './RfEstimationDialogComponent.vue';
import ServiceFunctionsComponentVue from '../../Shared/ServiceFunctionsComponent.vue';

export default {
    mixins: [ServiceFunctionsComponentVue],
    data() {
        return {
            repairFormHeaders: [
                { text: 'RF#', value: 'rf_number' },
                { text: 'RD#', value: 'sc_receiving_report.rr_number' },
                { text: 'Item Model', value: 'sc_repair_form_item.model' },
                { text: 'Item Name', value: 'sc_repair_form_item.product_name' },
                { text: 'Serial No', value: 'sc_repair_form_item.serial' },
                { text: 'Complaint/Symptom', value: 'sc_repair_form_item.defect_description' },
                { text: 'Status', value: 'status' },
                { text: 'Warranty Type', value: 'sc_repair_form_item.warranty_type' },
                { text: 'Technician', value: 'technician_name' },
                // { text: 'Ready For QA', value: 'ready_for_qa', align: 'center'},
                { text: 'Days', value: 'created_at' },
                { text: 'Date', value: 'date' },
                { text: 'Action', value: 'action' },
            ],
            pinRepairFormPHeaders: [
                { text: 'RF#', value: 'rf_number' },
                { text: 'RD#', value: 'sc_receiving_report.rr_number' },
                { text: 'Item Model', value: 'sc_repair_form_item.model' },
                { text: 'Item Name', value: 'sc_repair_form_item.product_name' },
                { text: 'Serial No', value: 'sc_repair_form_item.serial' },
                { text: 'Complaint/Symptom', value: 'sc_repair_form_item.defect_description' },
                { text: 'Status', value: 'status' },
                { text: 'Warranty Type', value: 'sc_repair_form_item.warranty_type' },
                { text: 'Technician', value: 'technician_name' },
                { text: 'Ready For QA', value: 'ready_for_qa', align: 'center'},
                { text: 'Days', value: 'created_at' },
                { text: 'Date', value: 'date' },
                { text: 'Action', value: 'action' },
            ],
            search: '',
            loading: true,
            noDataText: '',
            rfLists: [],
            // rfStatus: "",
            rfEstDialog: false,
            rf: null,
            page: 1,
            itemsPerPage: 10,
            options: {},
            totalItems: 0,
            warehousesCode: [],
            searchItem:'',
        }
    },
    components: {
        RfEstimationDialogComponentVue,
        TechRfEstDialogComponentVue
    },
    mounted() {
        let url = new URLSearchParams(window.location.search);
        this.search = url.get("rf_number") != null ? url.get("rf_number").toString() : ''
        this.getWarehouses()
    },
    computed:{
        ...mapGetters([
            'GET_ALL_TECH_RFS',
            'GET_SERVICE_WAREHOUSES',
        ]),
        headers() {
            if(this.rfStatus == 'PIN') {
               return this.pinRepairFormPHeaders
            } else {
               return this.repairFormHeaders
            }
        },
    },
    props: ['rfStatus'],
    watch: {
        async rfStatus() {
            this.rfStatus = this.rfStatus;
            await this.getAllRfs();
        },
        'options':{
            handler(val){
                this.getAllRfs()
            }
        },
    },
    methods: {
        async getAllRfs() {
            this.loading = true
            this.rfLists = []
                let payload = {
                    page:               this.options.page ?? 1,
                    itemsPerPage:       this.options.itemsPerPage ?? 10,
                    status:             this.rfStatus,
                    search:             this.search,
                    searchItem:         this.searchItem
                }
            await this.$store.dispatch('getAllTechRfs',payload).then(response => {
                if(!!response.data) {
                    this.rfLists = response.data.rfs.data;
                    this.totalItems = response.data.rfs.total;
                }
                this.loading = false
            });
        },
        date(item) {
            if(!!item) {
                return this.$dayjs(item.created_at).format('YYYY-MM-DD, hh:mm:ss');
            }
            return '';
        },
        showRfEstDialog(rf) {
            this.$store.commit('showScEstDialog');
            this.rf = rf;
        },
        rfEstimationSuccess(confirm) {
            if(confirm) {
                this.getAllRfs();
            }
        },
        showTechRfEstDialog(rf) {
            this.$store.commit('showTechRfEstDialog');
            this.rf = rf;
            let payload = {
                itemId: rf.sc_repair_form_item.item_id,
            }
            this.$store.dispatch('getSpecificListSelection',payload)
        },
        showRepairFormCompletedDialog(rf) {
            this.$store.commit('showRepairFormCompletedDialog');
            this.rf = rf;
        },
        successRepair(confirm) {
            if(confirm){
                this.getAllRfs();
            }
        },
        successStartRepair(confirm) {
            if(confirm){
                this.getAllRfs();
            }
        },
        successRequestNewSp(confirm) {
            if(confirm){
                this.getAllRfs();
            }
        },
        successAddNewEstimation(confirm) {
            if (confirm) {
                this.getAllRfs();
            }
        },
        getWarehouses() {
            this.$store.dispatch('serviceGetWarehouses').then(response => {
                this.warehousesCode = this.GET_SERVICE_WAREHOUSES.data;
            });
        },
    }
}
</script>

<style>

</style>
