<template>
    <v-container fluid>
        <h3 class="page-title">CASH ADVANCE</h3>
        <v-container class="ma-0">
            <v-app id="item-tabs">
                <v-tabs
                    v-model="tab"
                    id="group-tab"
                    color="cyan"
                    slider-color="cyan"
                    background-color="#424242"
                    dark
                    show-arrows
                >
                    <v-tabs-slider color="cyan"></v-tabs-slider>
                    <v-tab
                        ripple
                        v-for="(item, i) in filteredTabsList"
                        :key="i"
                        @click="tabs(i)"
                    >
                        {{ item.text }}
                    </v-tab>
                    <v-spacer></v-spacer>
                    <!-- <v-tab
                        ripple
                        @click="tabs('other')"
                    >
                        Others
                    </v-tab> -->
                </v-tabs>
            </v-app>
            <v-btn class="my-2" @click="open_dialog('Add')">
                <v-icon>mdi-plus</v-icon> Add
            </v-btn>
            <component :is="main_components"></component>
        </v-container>
        <DialogComponent
            :cp="components"
            :scrollable="dialog_scrollable"
            :width="width(dialog_width)"
            :retainfocus="false"
        ></DialogComponent>
    </v-container>
</template>

<script>
import DialogComponent from "@/views/main/layouts/dialogs/Dialog.vue";
import { mapGetters } from "vuex";
import CashAdvanceComponent from '@/views/main/modules/Accounting/CashAdvance/CashAdvanceComponent.vue';
import CashAdvanceViewComponent from '@/views/main/modules/Accounting/CashAdvance/CashAdvanceViewComponent.vue'

export default {
    components: {
        DialogComponent,
        CashAdvanceComponent,
        CashAdvanceViewComponent
    },
    data() {
        return {
            components: "",
            main_components: "",
            dialog_width: "",
            tab: 0,
            dialog_scrollable: true,
            userAccess: [],
            cash_advance_tabs_items: {
                components: CashAdvanceComponent,
                dialog: CashAdvanceViewComponent,
                dialog_width: "50%",
                scrollable: true,
                dispatch: {
                    get: "cardBankGet",
                    post: 'testPost'
                },
            },
            // other_tabs_items: {
            //     components: OtherComponent,
            //     dialog: TestViewComponent,
            //     // dialog_title: 'Testasd Dialog',
            //     // status_text: "ALL",
            //     dialog_width: "100%",
            //     scrollable: true,
            //     dispatch: {
            //         get: 'testGet',
            //         post: 'testPost'
            //     },
            // },
        };
    },
    computed: {
        ...mapGetters([
            'SELECTED_TAB',
            'STATUS',
            'ACTION',
            'USER_ACCESS',
            'USERACCOUNT_LOAD'
        ]),
        filteredTabsList() {
            let check_with_access = this.STATUS.CASH_ADVANCE_STATUS.filter(tab => this.userAccess.includes(tab.accessName));
            let check_without_access = this.STATUS.CASH_ADVANCE_STATUS.filter(e => !e.accessName);
            let combinedTabs = [...check_with_access,...check_without_access];
            combinedTabs.sort((a, b) => a.value - b.value);
            if(this.USERACCOUNT_LOAD.president != 1 || this.USERACCOUNT_LOAD.super_admin != 1){
                return combinedTabs;
            } else if(this.USERACCOUNT_LOAD.manager != 1 ){
                return combinedTabs;
            } else {
                return this.STATUS.CASH_ADVANCE_STATUS;
            }
        }
    },
    mounted() {
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id);
        this.tabs(0);
    },
    methods: {
        open_dialog(action){
            this.$store.commit('ACTION',action);
            this.$store.commit("DIALOG",true);
        },
        tabs(key) {
            if(this.STATUS.CASH_ADVANCE_STATUS.find(e => e.value == key)){
                this.$store.commit('COMPONENT_INFO',this.cash_advance_tabs_items.dispatch);
                this.$store.commit('SELECTED_TAB',key);
                this.main_components = this.cash_advance_tabs_items.components;
                this.components = this.cash_advance_tabs_items.dialog;
                this.dialog_width = this.cash_advance_tabs_items.dialog_width;
                this.dialog_scrollable = this.cash_advance_tabs_items.scrollable;
            }
            //  else if(key == 'other'){
            //     this.main_components = this.other_tabs_items.components;
            //     this.components = this.other_tabs_items.dialog;
            //     this.dialog_width = this.other_tabs_items.dialog_width;
            //     this.dialog_scrollable = this.other_tabs_items.scrollable;
            //     // let payload = {
            //     //     url: 'asdad',
            //     //     status: 'resrt',
            //     // }
            //     // this.$store.dispatch(this.other_tabs_items.dispatch.get,payload);
            // }
        },
        width(width) {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return "100%";
                case 'sm': return "100%";
                case 'md': return "100%";
                case 'lg': return "100%";
                case 'xl': return width;
            }
        },
    },
    watch:{
        USER_ACCESS:{
            handler(val){
            if(val != "fail") this.userAccess = val.map(e=>e.actions_code);
            }
        },
    }
};
</script>
<style>
#group-tab {
    padding: 0 !important;
}
</style>
