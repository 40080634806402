<template>
    <v-dialog v-model="dialog" width="600px" persistent>
        <v-card>
            <v-card-title class="d-flex justify-content-between">
                <p>Batch Update</p>
                <v-btn
                    text
                    icon
                    small
                    color="gray"
                    class="float-right"
                    @click="()=>{
                        $emit('closeDialog', [])
                    }"
                >
                    <v-icon >mdi-close-circle</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-subtitle>
                <v-row>
                    <v-col v-if="data.length <= 0" lg="10" class="d-flex justify-content-center">
                        <span v-for="(item,index) in headers" :key="index">
                            <v-text-field
                                v-model="pasted"
                                rows="1"
                                small
                                clearable
                                color="primary"
                                class="mx-2"
                                :label="item"
                                @click="$event.target.focus()"
                                @focus="dataFromExcel = null"
                                @paste="onPaste"
                            ></v-text-field>
                        </span>


                    </v-col>
                    <v-col v-else>
                        <span class="items-count">Item count: {{data.length}}</span>
                    </v-col>
                    <v-col lg="2" class="d-flex justify-content-end align-items-center ml-auto">
                        <v-btn
                            small
                            color="red"
                            @click="()=>{
                                data = []
                                pasted = ''
                            }"
                        >
                            clear
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-subtitle>
            <v-card-text style="overflow-y:scroll;max-height:60vh" class="p-3">
                <v-row v-for="(item,index) in data"  :key="index">
                    <v-col v-for="(el,i) in item" :key="i">
                        <v-text-field  :value="el" outlined hide-details>

                        </v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="p-3 d-flex align-items-center justify-content-center">
                <v-btn
                    @click="$emit('closeDialog', data); data = []"
                    color="orange"
                >
                    submit
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props:{
        dialog:{
            default:false,
            type:Boolean
        },
        headers:{
            default:()=>['Paste Data'],
            type:Array
        }
    },
    data(){
        return{
            data:[],
            pasted:''
        }
    },
    mounted(){
        this.data = []
    },
    methods:{
        onPaste(evt) {
            let pastedText = evt.clipboardData.getData("Text");
            let rows = pastedText.split("\r\n");
            this.data = []
            for (let y in rows) {

                if (y != rows.length) {
                    let cells = rows[y].split("\t");
                    this.data.push(cells);
                }
            }
            // this.data = this.data.pop();
            this.$forceUpdate();
        },
    },
    watch:{
        'dialog':{
            handler(val){
                if(!!val){
                    this.data = []
                    this.pasted = ''
                }
            }
        }
    }
}
</script>

<style>
.items-count {
    margin-left: 1rem;
    color: #000;
    font-size: 1.2rem !important;
    font-weight: 500 !important;
}
</style>
