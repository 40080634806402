<template>
    <v-form ref="form" v-model="valid">
        <!-- Functions -->
        <v-card class="px-3 rounded-0">
            <v-card-subtitle>
                <v-row>
                    <v-col cols="3">
                        <v-btn v-if="userAccess.includes('create')" class="m-3" @click="mode()">
                            <v-icon>mdi-plus</v-icon>Create
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="ml-0">
                    <v-col lg="2">
                        <v-autocomplete
                            v-model="filters.company_id"
                            :items="GET_PV_COMPANIES_SELECTION"
                            label="Company"
                            outlined
                            dense
                            auto-select-first
                            hide-details
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col lg="2">
                        <v-autocomplete 
                            v-model="filters.store_branch_id"
                            :items="clonedStores"
                            label="Store Branch"
                            outlined
                            dense
                            auto-select-first
                            hide-details
                            clearable
                            no-data-text="No Stores Found!"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="1" class="pl-2">
                        <v-btn @click="()=>{
                                options.page = 1
                                getAllStoreCashInTemplates()
                            }"
                        >
                            FILTER
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-spacer />
                    <v-col cols="3" class="pull-right">
                        <v-text-field
                            v-model="search"
                            label="Search by Company/ Store Branch"
                            append-icon="mdi-magnify"
                            @keydown.enter="getAllStoreCashInTemplates()"
                            @click:append="getAllStoreCashInTemplates()"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card-subtitle>
            <!-- Table -->
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-data-table
                            :headers="mainHeaders"
                            :items="mainItems"
                            :options.sync="options"
                            :server-items-length="totalItems"
                            :items-per-page="10"
                            :loading="tableLoad"
                            :footer-props="{
                                itemsPerPageOptions: itemsPerPageOptions
                            }"
                            dense
                        >
                            <template v-slot:[`item.company_name`]="{ item }"> 
                                {{ item.company_name != '' ? item.company_name : 'ALL' }}
                            </template>
                            <template v-slot:[`item.store_branch_name`]="{ item }">
                                {{ item.store_branch_name != '' ? item.store_branch_name : 'ALL' }}
                            </template>
                            <template v-slot:[`item.action`]="{ item }">
                                <v-btn v-if="userAccess.includes('view')" text icon color="orange" @click="mode('view', item.id)">
                                    <v-icon small>mdi-eye</v-icon>
                                </v-btn>
                                <v-btn v-if="userAccess.includes('edit')" text icon color="primary" @click="mode('edit', item.id)">
                                    <v-icon small>mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn v-if="userAccess.includes('delete')" text icon color="error" @click="destroy(item.id)">
                                    <v-icon small>mdi-delete</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
            <!-- Dialog -->
            <v-dialog v-model="mainDialog" persistent scrollable width="50%">
                <v-card elevation="0" class="pa-0 m-0 overflow-hidden">
                    <v-card-title v-if="!dialogLoader">
                        <v-container class="pa-0">
                            <v-row class="m-0">
                                <v-col>
                                    <span class="headline">{{ STORE_CASH_IN.ACTION.charAt(0).toUpperCase() + STORE_CASH_IN.ACTION.slice(1) }} Template</span>
                                </v-col>
                                <v-col cols="pull-right-1">
                                    <v-btn text icon color="gray" class="float-right" @click="toggleDialog('close')">
                                        <v-icon>mdi-close-circle</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-card elevation="0" dense>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-card elevation="0" dense>
                                                <v-card-text>
                                                    <v-row>
                                                        <v-col cols="6">
                                                            <v-autocomplete
                                                                v-model="form.company_id"
                                                                :readonly="isDisabled()"
                                                                :items="GET_PV_COMPANIES_SELECTION"
                                                                :loading="GET_PV_COMPANIES_SELECTION.length == 0"
                                                                label="Select Company"
                                                                auto-select-first
                                                                outlined
                                                                dense
                                                            ></v-autocomplete>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-autocomplete
                                                                v-model="form.store_branch_id"
                                                                :readonly="isDisabled()"
                                                                :items="branchItems"
                                                                label="Select Branch"
                                                                no-data-text="No Stores Found!"
                                                                auto-select-first
                                                                outlined
                                                                dense
                                                                @change="insertCompany(form.store_branch_id)"
                                                            ></v-autocomplete>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-container fluid class="pa-0 ma-0">
                                                            <v-card class="py-5 my-2" elevation="0" max-height="500px" style="overflow-y: auto">
                                                                <v-card-subtitle class="p-0">
                                                                    <span class="float-right mx-2">
                                                                        <v-btn fab dark small color="primary"
                                                                            style="height: 20px; width: 20px"
                                                                            @click="addTemplateItems()"
                                                                            :disabled="isDisabled()">
                                                                            <v-icon dark small
                                                                                style="font-size: 12px">mdi-plus</v-icon>
                                                                        </v-btn>
                                                                    </span>
                                                                    <span class="font-weight-bold">Template Items</span>
                                                                </v-card-subtitle>
                                                                <v-card  class="overflow-auto pa-0" height="400" elevation="0">
                                                                    <v-simple-table>
                                                                        <thead class="position-sticky">
                                                                            <tr>
                                                                                <th width="30%" class="text-center">Criteria <span style="color: red;">*</span></th>
                                                                                <th class="text-center">CoA <span style="color: red;">*</span></th>
                                                                                <th width="15%" class="text-center">Type</th>
                                                                                <th width="20px" v-if="STORE_CASH_IN.ACTION != 'view'">Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr v-for="(data,i) in form.store_cash_in_detail_template_items" :key="i">
                                                                            <td class="pa-2 center-align-text">
                                                                                <v-text-field 
                                                                                    v-if="STORE_CASH_IN.ACTION != 'view'" 
                                                                                    v-model="data.name" 
                                                                                    :rules="errors.required"
                                                                                    :disabled="isDisabled()"
                                                                                    hide-details="auto"
                                                                                    outlined 
                                                                                    dense 
                                                                                ></v-text-field>
                                                                                <div v-else class="text-center">
                                                                                    <span>{{data.name}}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td class="pa-2 center-align-text">
                                                                                <v-autocomplete
                                                                                    v-if="STORE_CASH_IN.ACTION != 'view'" 
                                                                                    v-model="data.coa_id" 
                                                                                    :items="GET_CHART_OF_ACCOUNTS_SELECTION"
                                                                                    :disabled="isDisabled()"
                                                                                    :rules="errors.required"
                                                                                    hide-details="auto"
                                                                                    item-value="id"
                                                                                    item-text="account_name"
                                                                                    outlined 
                                                                                    dense 
                                                                                ></v-autocomplete>
                                                                                <div v-else class="text-center">
                                                                                    <span>{{GET_CHART_OF_ACCOUNTS_SELECTION.find(e=>e.id == data.coa_id).account_name}}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td class="pa-2">
                                                                                <v-icon
                                                                                    v-if="STORE_CASH_IN.ACTION != 'view'"
                                                                                    style="transform: translateX(55px);"
                                                                                    :color="data.plus_minus == '+' ? '#5AB55E' : '#FF6060'"
                                                                                    @click="()=>{
                                                                                        data.plus_minus = data.plus_minus == '+' ? '-' : '+'
                                                                                    }" 
                                                                                >
                                                                                    {{ data.plus_minus == '+' ? 'mdi-plus-box' : 'mdi-minus-box'  }}
                                                                                </v-icon>
                                                                                <v-icon 
                                                                                    v-else
                                                                                    style="transform: translateX(55px)"
                                                                                    :color="data.plus_minus == '+' ? '#5AB55E' : '#FF6060'"
                                                                                >
                                                                                    {{ data.plus_minus == '+' ? 'mdi-plus-box' : 'mdi-minus-box'  }}
                                                                                </v-icon>
                                                                            </td>
                                                                            <td v-if="STORE_CASH_IN.ACTION != 'view'">
                                                                                <v-btn text icon color="red" @click="deleteTemplateItems(i)" :disabled="isDisabled()">
                                                                                    <v-icon class="btn-action" small>mdi-delete</v-icon>
                                                                                </v-btn>
                                                                            </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </v-simple-table>
                                                                </v-card>
                                                            </v-card>
                                                        </v-container>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                            <v-container>
                                                <v-row class="py-2">
                                                    <v-col class="mt-5">
                                                        <span>Required fields are marked with <span style="color: red;">*</span></span>
                                                    </v-col>
                                                    <v-col class="text-center">
                                                        <v-btn :disabled="!validToProceed" :loading="btnLoad" 
                                                            v-if="STORE_CASH_IN.ACTION != 'view'"
                                                            @click="commitStoreCashInTemplate(STORE_CASH_IN.ACTION, STORE_CASH_IN.ACTION == 'create' ? 'addStoreCashInTemplate' : 'updateStoreCashInTemplate')"
                                                        >
                                                            {{ STORE_CASH_IN.ACTION == 'create' ? 'Create' : 'Update' }}
                                                        </v-btn>
                                                    </v-col>
                                                    <v-spacer/>
                                                </v-row>
                                            </v-container>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-container>
                    </v-card-title>
                    <div v-else class="text-center pa-2">
                        <v-progress-circular
                            :size="80"
                            color="primary"
                            indeterminate
                        />
                    </div>
                </v-card>
            </v-dialog>
        </v-card>
    </v-form>
</template>
<script>
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue'
export default {
    mixins: [SharedFunctionsComponentVue],
    props: ['userAccess'],
    data(){
        return{
            valid: false,
            mainDialog: false,
            mainHeaders: [
                { text: 'Company', value:'company_name', sortable: false },
                { text: 'Store Branch', value:'store_branch_name', sortable: false },
                { text: 'Actions', value:'action', align: 'center', sortable: false }
            ],
            mainItems: [],
            options: {},
            totalItems: 0,
            tableLoad: false,
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            itemsPerPage: 10,
            search: '',
            dialogLoader: false,
            form:{
                id: null,
                company_id: null,
                store_branch_id: null,
                store_cash_in_detail_template_items: [],
            },
            detail_template_item_trash: [],
            btnLoad: false,
            filters:{
                company_id: null,
                store_branch_id: null
            },
            clonedStores: [],
            errors:{
                required: [ (v) => !!v || 'This field is required']
            },
        }
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS',
            'USERACCOUNT_LOAD',
            'GET_CHART_OF_ACCOUNTS_SELECTION',
            'STORE_CASH_IN',
            'GET_PV_COMPANIES_SELECTION',
            'GET_PV_STORE_BRANCHES_SELECTION',
            'STATUS',
            'SELECTED_TAB'
        ]),
        branchItems(){
            let items = this.clonedStores.filter(e=>e.company_id == this.form.company_id)
            if (!this.form.company_id) return this.clonedStores
            // if (!this.form.store_branch_id) this.form.store_branch_id = items[0].value
            return items
        },
        validToProceed(){
            return this.valid
        },
    },
    mounted(){
        this.getAllStoreCashInTemplates()
        this.getBranches()
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.handleKeydown);
    },
    methods:{
        toggleDialog(action = 'close'){
            switch(action){
                case 'open':
                    this.valid = false
                    this.mainDialog = true
                    document.addEventListener('keydown', this.handleKeyDown)
                    if(this.form.store_cash_in_detail_template_items.length == 0) this.addTemplateItems()
                    break;
                case 'close':
                    this.mainDialog = false
                    this.clearFields()
                    this.getAllStoreCashInTemplates()
                    document.removeEventListener('keydown', this.handleKeydown);
                    break;
            }
        },
        clearFields(){
            this.$refs.form.resetValidation()
            this.form = {
                id: null,
                company_id: null,
                store_branch_id: null,
                store_cash_in_detail_template_items: []
            }
            this.detail_template_item_trash = []
            this.$forceUpdate()
        },
        commitStoreCashInTemplate(action, url){
            this.btnLoad = true
                Swal.fire({
                    title: '',
                    text: `- Are you sure you want to ${action} this template?`,
                    icon: 'question',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    allowOutsideClick: false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor: 'grey',
                }).then(async (result) => {
                    if (!result.isConfirmed) {
                        this.btnLoad = false
                        return
                    }
                    this.dialogLoader = true
                    let payload = {
                        form: this.form,
                        trash: this.detail_template_item_trash,
                        url: url
                    }
                    this.$store.dispatch('transactionJournalPost', payload).then(response => {
                        Swal.fire('', response.data.message, response.data.status);
                        this.getAllStoreCashInTemplates();
                        this.toggleDialog('close');
                        this.dialogLoader = false
                        this.btnLoad = false
                    }).catch(error => {
                        Swal.fire('', error.response.data.message, error.response.data.status);
                        this.dialogLoader = false
                        this.btnLoad = false
                    })
                })
        },
        getAllStoreCashInTemplates(){
            this.tableLoad = true
            this.mainItems = []
            let payload = {
                company_id: this.filters.company_id,
                store_branch_id: this.filters.store_branch_id,
                search: this.search,
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                tab: this.STORE_CASH_IN.TAB.tab_name,
                url: 'getAllStoreCashInTemplates'
            }
            this.$store.dispatch('storeCashInGet', payload).then(response=>{
                let data = response.data.data
                this.mainItems = data.data
                this.totalItems = data.total
                this.tableLoad = false
            })
        },
        mode(action = 'create', id = null){
            this.$store.commit('STORE_CASH_IN_ACTION', action)
            if (!id) {
                this.clearFields()
                this.toggleDialog('open')
            } else {
                this.dialogLoader = true
                this.btnLoad = true
                this.toggleDialog('open')
                this.$store.dispatch('storeCashInPost', {id: id, url: 'getStoreCashInTemplate'}).then(response=>{
                    this.getStoreBranches(2)
                    this.form = { ...response.data }
                    this.dialogLoader = false
                    this.btnLoad = false
                })
            }
        },
        isDisabled(){
            switch(this.STORE_CASH_IN.ACTION){
                case 'view':
                    return true
            }
        },
        destroy(id){
            Swal.fire({
                title: '',
                text: '- Are you sure you want to delete template?',
                icon: 'warning',
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                allowOutsideClick: false,
                confirmButtonColor: '#397373',
                cancelButtonColor: 'grey',
                confirmButtonColor: 'Confirm'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('transactionJournalPost', { id: id, url: 'deleteStoreCashInTemplate' }).then(response => {
                        Swal.fire('', response.data.message, response.data.status);
                        this.getAllStoreCashInTemplates();
                    }).catch(error => {
                        Swal.fire('', error.response.data.message, error.response.data.status);
                        console.log(error)
                    })
                }
            })
        },
        async getBranches(){
            try{
                this.clonedStores = await this.getStoreBranches()
            } catch (error) {
                console.error(error)
            }
        },
        insertCompany(storeId){
            if (!storeId) return
            let store = this.clonedStores.find(e=>e.value == storeId)
            this.form.company_id = this.GET_PV_COMPANIES_SELECTION.find(e=>e.value == store.company_id).value
        },
        // insertStore(){
        //     this.form.store_branch_id = this.branchItems[0].value
        // },
        handleKeyDown(event){
            if (this.mainDialog && !this.dialogLoader) this.closeDialogByEsc(event, this.toggleDialog)
        },
        addTemplateItems(){
            this.form.store_cash_in_detail_template_items.push({
                name: '',
                coa_id: null,
                plus_minus: '+'
            })
        },
        deleteTemplateItems(i){
            this.detail_template_item_trash.push(this.form.store_cash_in_detail_template_items[i])
            this.form.store_cash_in_detail_template_items.splice(i,1)
            if (this.form.store_cash_in_detail_template_items.length == 0) {
                this.addTemplateItems()
            }
        },
    },
    watch:{
        options:{
            handler(){
                this.getAllStoreCashInTemplates()
            },
            deep:true
        },
        SELECTED_TAB:{
            handler(){
                this.options.page = 1
                this.getAllStoreCashInTemplates()
            }
        },
        search:{
            handler(val){
                if (val) {
                    this.options.page = 1
                }
            },
            immediate: true
        },
        'form.company_id':{
            handler(val){
                if (!val) {
                    this.form.store_branch_id = null
                }
            },
            immediate: true
        },
        'form.store_branch_id':{
            handler(val){
                if (!val) {
                    this.form.company_id = null
                }
            },
            immediate: true
        }
    }
}
</script>
<style scoped>
.small-font {
    font-size: 14px;
}
::v-deep .right-align-text input {
    text-align: right;
}
::v-deep .center-align-text input {
    text-align: center;
}
</style>