<template>
    <div>
        <div class="page-wrapper ma-1">
            <div class="row p-2">
                <div class="col-12 d-flex no-block align-items-center">
                    <h4 class="page-title">Call Center</h4>
                </div>
            </div>

            <div class="container">
                <v-layout row class="mx-0 px-3">
                    <v-flex>
                        <AddCustomerConversationDialogVue />
                    </v-flex>
                    <v-flex>
                        <div style="text-align:end">
                            <v-btn @click="redirect('/service/inquiry')">Inquiry</v-btn>
                        </div>
                    </v-flex>
                </v-layout>
                <div id="item-tabs" class="row mx-auto mt-4">
                    <v-tabs v-model="tab" id="group-tab" color="cyan" dark slider-color="cyan" class="col-12 pb-0" show-arrows>
                        <v-tab class="ml-3" ripple @click="selectedMenu(0)">All</v-tab>
                        <v-tab ripple @click="selectedMenu(1)">Open</v-tab>
                        <v-tab ripple @click="selectedMenu(2)">Escalated</v-tab>
                        <v-tab ripple @click="selectedMenu(3)">Closed</v-tab>
                    </v-tabs>

                    <CallCenterTableComponentVue :status="menu" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CallCenterTableComponentVue from '../Views/CallCenterComponent/CallCenterTableComponent.vue';
import AddCustomerConversationDialogVue from '../Views/CallCenterComponent/AddCustomerConversationDialog.vue';

export default {
    data() {
        return {
            tab: null,
            menu: 0
        }
    },
    mounted(){
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
    },
    components: {
        CallCenterTableComponentVue,
        AddCustomerConversationDialogVue
    },
    methods: {
        selectedMenu(tab) {
            this.menu = tab;
        },
        redirect(url) {
            window.open(window.location.origin+url);
        }
    }
}
</script>

<style>

</style>
