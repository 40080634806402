<template>
<div class="tabs-body-color">

    <div class="container">

        <!-- <div class="row">
            <div class="col-12 d-flex no-block align-center">
                <div class="btn-toolbar">
                    <v-btn data-modal-id="addUomModal" v-on:click="showAddUom()">
                        <i class="fas fa-plus"></i>Add
                    </v-btn>
                </div>
            </div>
        </div> -->
        <div class="row">
            <div class="col-12 d-flex no-block align-center">
                <div class="btn-toolbar">
                    <div class="row my-1">
                        <div class="">
                            <div class="btn-toolbar">
                                <v-btn class="" @click="showAddUom()" v-if="userAccess.includes('uom_create')">
                                    <v-icon>mdi-plus</v-icon>
                                    Add
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <!-- Uom Table -->
            <UomTableComponentVue :userAccess="userAccess" :itemUomActionResult="action" @showEditModal="showEditModal"></UomTableComponentVue>
        </div>
    </div>

    <v-app id="addEditUomModal">
        <v-dialog v-model="add_edit_uom_dialog" persistent max-width="500" scrollable>
            <v-card>
                <v-row class="m-0">
                    <v-col cols="11">
                        <v-card-title>
                            <span class="headline">UOM Information</span>
                        </v-card-title>
                    </v-col>
                    
                    <v-col cols="1" class="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="add_edit_uom_dialog = false; resetFields(); $emit('closeModal');">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider class="ma-0 mb-3"></v-divider>
                <v-card-text>
                    <form class="form-horizontal px-4" action="/itemUom" method="post" v-on:submit.prevent="onSubmit">
                        <div class="card-body">
                            <v-row >
                                <v-col cols="12">
                                    <label for="name" class="float-left control-label col-form-label font-weight-medium">Name <span v-if="action != 'View'" class="red--text">*</span></label>
                                    <input type="text" class="form-control" id="name" v-model="name" :readonly="action == 'View'">
                                    <span v-for="errors in errors.name" class="text-warning" v-bind:key="errors">{{errors}}</span>
                                </v-col>
                                <v-col cols="12">
                                    <label for="description" class="float-left control-label col-form-label font-weight-medium">Code <span v-if="action != 'View'" class="red--text">*</span></label>
                                    <input type="text" class="form-control" id="description" v-model="code" :readonly="action == 'View'">
                                    <span v-for="errors in errors.code" class="text-warning" v-bind:key="errors">{{errors}}</span>
                                </v-col>
                                <!-- <v-col cols="12">
                                    <label for="description" class="float-left control-label col-form-label font-weight-medium">Description</label>
                                    <input type="text" class="form-control" id="description" v-model="description" :readonly="action == 'View'">
                                    <span v-for="errors in errors.description" class="text-warning" v-bind:key="errors">{{errors}}</span>
                                </v-col> -->
                            </v-row>
                        </div>

                        <div class="border-top">
                            <div class="card-body py-4 d-flex justify-content-center">
                                <v-btn  v-on:click="addNewUom()" v-if="action=='Add'" :loading="loading">Submit</v-btn>
                                <v-btn  v-on:click="editUom()" v-else-if="action=='Edit'" :loading="loading">Update</v-btn>
                            </div>
                        </div>
                    </form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>
    
</div>
</template>

<script>
    import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
    import UomTableComponentVue from '../tables/UomTableComponent.vue';
    import swal from 'sweetalert2'
import { mapGetters } from 'vuex';
    export default {
        mixins: [SharedFunctionsComponentVue],
        data(){
            return {
                editUomId: '',
                add_edit_uom_dialog: false,
                name: '',
                code: '',
                description: '',
                errors: [],
                action: '',
                actionResult : {
                    model : 'Uom',
                    action : ''
                },
                userAccess: [],
                loading: false,
            }
        },
        components: {
            UomTableComponentVue,
        },
        computed:{
            ...mapGetters([
                'GET_SHOW_ITEM_UOM',
                'USER_ACCESS'
            ])
        },
        async mounted() {
        await this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)

        },
        methods: {
            showAddUom(){
                this.add_edit_uom_dialog = true
                this.action = 'Add'
            },
            addNewUom(){
                this.errors = []
                this.loading = true;
                let payload = {
                    name:           this.name,
                    code:           this.code,
                }
                this.$store.dispatch('storeItemUom',payload).then(response => {
                    this.resetFields()
                    this.add_edit_uom_dialog = false
                    this.$store.dispatch('getAllItemUom');
                    this.action = 'success'
                    swal.fire("", "Add Uom Successful!", "success");
                    this.loading = false;
                }).catch(error => {
                    swal.fire("", "Add Uom Failed!", "error");
                    this.errors = error.response.data.errors;
                    this.loading = false;
                });
            },
            showEditModal(data){
                this.action = data.action
                this.editUomId = data.id

                if(this.editUomId != null && this.editUomId > 0){
                    
                    this.$store.dispatch('showItemUom',this.editUomId).then(response => {
                        let data = this.GET_SHOW_ITEM_UOM
                        this.name                   = data.name
                        this.code                   = data.code
                        this.description            = data.description
                        this.add_edit_uom_dialog    = true
                    }).catch(error => {

                    });
                }
            },
            editUom(){
                this.errors = []
                swal.fire({
                    text: `Are you sure you want to proceed?`,
                    icon:'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then(async(result) => {
                    if(result.isConfirmed) {
                        this.loading = true;
                        let payload = {
                            name:           this.name,
                            code:           this.code,
                            id:             this.editUomId,
                        }
                        
                        this.$store.dispatch('updateItemUom',payload).then(response => {
                            this.resetFields()
                            this.add_edit_uom_dialog = false
                            this.editUomId           = 0
                            this.action               = 'success'
                            swal.fire("", "Edit Uom Successful!", "success");
                            this.$store.dispatch('getAllItemUom');
                            this.loading = false;
                        }).catch(error => {
                            swal.fire("", "Edit Uom Failed!", "error");
                            this.errors = error.response.data.errors;
                            this.action = 'Edit'
                            this.loading = false;
                        });
                    }
                });
            },
            resetFields(){
                this.errors              = []
                this.name                = ''
                this.code                = ''
            },

        },
        watch: {
            USER_ACCESS:{
                handler(val){
                    this.userAccess = val.map(e=>e.actions_code);
                }
            },
        }
    };
</script>
<style>
    .v-text-field.v-text-field--enclosed .v-text-field__details{
        display: none;
    }
</style>