<template>
    <v-layout class="d-flex flex-column">
        <v-flex class="d-flex flex-column justify-space-between align-center">
            <v-slider
                v-model="width"
                class="align-self-stretch"
                min="600"
                max="1200"
                step="1"
            ></v-slider>
            <v-img
                :aspect-ratio="16/2"
                :width="width"
                src="/images/store_flow_file_upload_format.png"
            ></v-img>
        </v-flex>
        <v-flex class="d-flex justify-end mt-2">
            <v-btn
                :loading="loading"
                dense
                @click="downloadFileFormat"
            >
                <v-icon
                    color="success"
                    dense
                >
                    mdi-cloud-download
                </v-icon>
                <span class="ml-1">Download</span>
            </v-btn>
        </v-flex>
    </v-layout>
</template>

<script>
export default {
    data: () => ({
        width: 600,
        loading:false,
    }),
    methods: {
        downloadFileFormat() {
            this.loading = true;
            const workbook = new this.$exceljs.Workbook();
            const worksheet = workbook.addWorksheet('File Format')

            worksheet.columns = [
            {header:'MOVEMENT DATE', width:'18'},
            {header:'STORE NAME', width:'25'},
            {header:'PRODUCT NUMBER', width:'20'},
            {header:'PRODUCT NAME', width:'25'},
            {header:'BRAND', width:'15'},
            {header:'UNIT', width:'10'},
            {header:'INBOUND/OUTBOUND NUMBER', width:'30'},
            {header:'BUSINESS NUMBER', width:'20'},
            {header:'BUSINESS TYPE', width:'18'},
            {header:'UOM' , width:'8'},
            {header:'QTY BEFORE MOVEMENT', width:'25'},
            {header:'QTY OF MOVEMENT', width:'20'},
            {header:'QTY AFTER MOVEMENT', width:'22'},
            {header:'CLIENT NAME', width:'15'},
            {header:'REMARKS', width:'10'},
        ]

            let headerRow = worksheet.getRow(1)
            headerRow.font = { bold:true,color: { argb: 'FFFF0000' } }

            worksheet.addRow()
            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                this.$filesaver.saveAs(blob, 'File Format.xlsx');
                this.loading = false;
            })

        }
    }
}
</script>

<style>

</style>