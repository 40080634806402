<script>
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";

export default {
    mixins: [SharedFunctionsComponentVue],
	data() {
		return {
			data: Math.random(),

			paperWidth: 215.9,
            paperHeight: 279.4,
            margin: 10,

            headerStartY: undefined,
            headerEndY: undefined,
            headerWidth: undefined,
            headerHeight: undefined,

            footerStartY: undefined,
            footerEndY: undefined,
            footerWidth: undefined,
            footerHeight: undefined,
		};
	},

	methods: {
		drawRepairRequestFormPrintHeader(doc, headerData) {
            const host = window.location.origin;

            const imageWidth = 36;
            const imageHeight = 10;
            doc.addImage(
                `${host}/images/ingcoph-logo.png`,
                'PNG',
                (this.margin + (this.paperWidth - (this.margin * 2)) / 4) - (imageWidth / 2),
                this.margin,
                imageWidth,
                imageHeight,
            );

            const titleContainerWidth = 70;
            const titleContainerHeight = 8;
            const titleContainerX = (this.paperWidth / 2) + (((this.paperWidth / 2) - 5) / 2) - (titleContainerWidth / 2);
            const titleContainerY = this.margin;
            doc.setDrawColor('#000000');
            doc.setFillColor('#d1d5db');
            doc.roundedRect(
                titleContainerX,
                titleContainerY,
                titleContainerWidth,
                titleContainerHeight,
                2,
                2,
                'FD',
            );

            const title = 'REPAIR REQUEST FORM';
            const titleFontSize = 15;
            const titleX = titleContainerX;
            const titleY = titleContainerY;
            doc.setTextColor('#000000');
            doc.setFont(
                'Helvetica',
                'bold',
            );
            doc.setFontSize(titleFontSize);
            doc.text(
                title,
                titleX + 3,
                titleY + 6,
            );

            doc.autoTable({
                startY: this.margin + titleContainerHeight + 5,
                margin: {
                    bottom: this.margin,
                    top: this.margin,
                    left: this.margin,
                    right: this.margin,
                },
                theme: 'grid',
                columnStyles: {
                    0 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 2},
                    1 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 2},
                },
                bodyStyles: {
                    lineColor: [0, 0, 0],
                    textColor: [0, 0, 0],
                    fontSize: 8,
                },
                body: [
                    [
                        {content: 'REQUESTOR DETAILS', colSpan: 2, styles: {halign: 'center', fillColor: [209, 213, 219], fontStyle: 'bold'}},
                    ],
                    [
                        `Requestor: ${headerData.requestor}`,
                        `Date Prepared: ${headerData.date_prepared}`,
                    ],
                    [
                        `Department: ${headerData.department}`,
                        `Priority: `,
                    ],
                    [
                        `Location: ${headerData.location}`,
                        ``,
                    ],
                    [
                        {content: 'REPAIR TYPES', colSpan: 2, styles: {halign: 'center', fillColor: [209, 213, 219], fontStyle: 'bold'}},
                    ],
                ],
                didDrawCell: (data) => {
                    if (data.row.index == 2 && data.column.index === 1) {
                        doc.setDrawColor('#000000');
                        doc.setFillColor(headerData.priorities == 'URGENT' ? '#000000' : '#FFFFFF');
                        doc.rect(data.cell.x + 15, data.cell.y + 2, 2.5, 2.5, 'FD');
                        doc.setFont('Helvetica','bold');
                        doc.text('URGENT', data.cell.x + 19, data.cell.y + 4.1);
                    }
                    if (data.row.index == 3 && data.column.index === 1) {
                        doc.setDrawColor('#000000');
                        doc.setFillColor(headerData.priorities == 'NOT URGENT' ? '#000000' : '#FFFFFF');
                        doc.rect(data.cell.x + 15, data.cell.y + 2, 2.5, 2.5, 'FD');
                        doc.setFont('Helvetica','bold');
                        doc.text('NOT URGENT', data.cell.x + 19, data.cell.y + 4.1);
                    }
                },
            });

            const headerCheckBoxesFontSize = 9;

            doc.setTextColor('#000000');
            doc.setFontSize(headerCheckBoxesFontSize);
            doc.setFont('Courier','normal');
            doc.setDrawColor('#000000');

            let currentTypeMaxY = 0;

            headerData.repair_type_categories.map((category, index) => {
                const incrementY = 5;

                const currentCategoryX = (((this.paperWidth / 2) - this.margin) * index);
                doc.rect(
                    currentCategoryX + this.margin + 2,
                    doc.lastAutoTable.finalY + 2,
                    2.5,
                    2.5,
                );
                doc.text(
                    category.type.toUpperCase(),
                    currentCategoryX + this.margin + 6,
                    doc.lastAutoTable.finalY + 5,
                );

                let currentTypeX = currentCategoryX + this.margin;
                let currentTypeY = doc.lastAutoTable.finalY;
                let lastTypeLength = this.margin + 8;
                category.repair_types.filter(type => type.type == category.type).map((type) => {
                    if (!type.vertical) {
                        currentTypeX = currentCategoryX + this.margin;
                        currentTypeY += incrementY;
                    } else currentTypeX += (lastTypeLength * 1.9) + 7;

                    doc.setFillColor(type.id == headerData.repairtype_id ? '#000000' : '#FFFFFF');
                    doc.rect(currentTypeX + 8, currentTypeY + 2, 2.5, 2.5, 'FD');
                    doc.text(type.name, currentTypeX + 12, currentTypeY + 5);

                    lastTypeLength = type.name.length;
                    currentTypeMaxY = currentTypeY > currentTypeMaxY ? currentTypeY : currentTypeMaxY;
                });
            });

            // doc.rect(this.margin + 2, doc.lastAutoTable.finalY + 2, 2.5, 2.5);
            // doc.text('REPAIR/MAINTENANCE', this.margin + 6, doc.lastAutoTable.finalY + 5);

            // doc.rect(this.margin + 8, doc.lastAutoTable.finalY + 7, 2.5, 2.5);
            // doc.text('Vehicle', this.margin + 12, doc.lastAutoTable.finalY + 10);

            // doc.rect(this.margin + 8, doc.lastAutoTable.finalY + 12, 2.5, 2.5);
            // doc.text('Equipment', this.margin + 12, doc.lastAutoTable.finalY + 15);

            // doc.rect(this.margin + 8, doc.lastAutoTable.finalY + 17, 2.5, 2.5);
            // doc.text('Office Area', this.margin + 12, doc.lastAutoTable.finalY + 20);
            // doc.rect(this.margin + 32, doc.lastAutoTable.finalY + 17, 2.5, 2.5);
            // doc.text('Store', this.margin + 36, doc.lastAutoTable.finalY + 20);
            // doc.rect(this.margin + 47.5, doc.lastAutoTable.finalY + 17, 2.5, 2.5);
            // doc.text('Staff House', this.margin + 51.5, doc.lastAutoTable.finalY + 20);

            // doc.rect(this.margin + 8, doc.lastAutoTable.finalY + 22, 2.5, 2.5);
            // doc.text('Others', this.margin + 12, doc.lastAutoTable.finalY + 25);
            // doc.line(this.margin + 25, doc.lastAutoTable.finalY + 25, this.margin + 68, doc.lastAutoTable.finalY + 25);

            // doc.rect((this.paperWidth / 2) + 2, doc.lastAutoTable.finalY + 2, 2.5, 2.5);
            // doc.text('INSTALLATION (pls. provide details)', (this.paperWidth / 2) + 6, doc.lastAutoTable.finalY + 5);

            // doc.rect((this.paperWidth / 2) + 2, doc.lastAutoTable.finalY + 7, 2.5, 2.5);
            // doc.text('Rentals (pls. provide details)', (this.paperWidth / 2) + 6, doc.lastAutoTable.finalY + 10);

            // doc.rect((this.paperWidth / 2) + 2, doc.lastAutoTable.finalY + 12, 2.5, 2.5);
            // doc.text('Others (pls. provide details)', (this.paperWidth / 2) + 6, doc.lastAutoTable.finalY + 15);

            // doc.line((this.paperWidth / 2) + 6, doc.lastAutoTable.finalY + 20, this.paperWidth - (this.margin * 1.5), doc.lastAutoTable.finalY + 20);

            // doc.line((this.paperWidth / 2) + 6, doc.lastAutoTable.finalY + 25, this.paperWidth - (this.margin * 1.5), doc.lastAutoTable.finalY + 25);

            const headerCheckBoxesContainerHeight = (currentTypeMaxY - doc.lastAutoTable.finalY) + 8;

            doc.rect(this.margin, doc.lastAutoTable.finalY, (this.paperWidth - (this.margin * 2)), headerCheckBoxesContainerHeight);
            doc.line(this.paperWidth / 2, doc.lastAutoTable.finalY, this.paperWidth / 2, doc.lastAutoTable.finalY + headerCheckBoxesContainerHeight);

            this.headerStartY = this.margin;
            this.headerEndY = doc.lastAutoTable.finalY + headerCheckBoxesContainerHeight;
            this.headerWidth = this.paperWidth - (this.margin * 2);
            this.headerHeight = this.headerEndY - this.headerStartY;

            return this.headerEndY;
        },

        drawRepairRequestFormPrintFooter(doc, footerData) {
            const footerHeight = 30;

            doc.autoTable({
                startY: this.paperHeight - (this.margin + footerHeight),
                margin: {
                    bottom: this.margin,
                    top: this.margin,
                    left: this.margin,
                    right: this.margin,
                },
                theme: 'grid',
                columnStyles: {
                    0 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 4},
                    1 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 4},
                    2 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 4},
                    3 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 4},
                },
                bodyStyles: {
                    minCellHeight: 13,
                    lineColor: [0, 0, 0],
                    textColor: [0, 0, 0],
                    fontSize: 8,
                },
                body: [
                    [
                        {content: 'Remarks', colSpan: 4},
                    ],
                    [
                        'Requestor',
                        'Endorsed by',
                        'Noted by',
                        'Approved by',
                    ],
                ],
                didDrawCell: (data) => {
                    const keys = Object.keys(footerData);

                    doc.autoTable({
                        startY: data.cell.y,
                        margin: {
                            top: data.cell.y,
                            bottom: this.paperHeight - (data.cell.y + data.cell.height),
                            left: data.cell.x,
                            right: this.paperWidth - (data.cell.x + data.cell.width),
                        },
                        theme: 'plain',
                        bodyStyles: {
                            minCellHeight: data.row.index === 1 && data.column.index === 2 ? 13 : 12,
                            valign: 'end',
                            halign: 'center',
                            lineColor: [0, 0, 0],
                            textColor: [0, 0, 0],
                            fontSize: 8,
                        },
                        body: [
                            [{
                                content: `\n${
                                    data.row.index === 0
                                    ? footerData[keys[0]]
                                    : footerData[keys[1 + (1 * data.column.index)]]
                                }`,
                                styles: data.row.index === 0
                                    ? {
                                        valign: 'middle',
                                        halign: 'center',
                                        fontStyle: 'italic',
                                    } : {},
                            }],
                        ],
                    });
                },
            });

            this.footerStartY = doc.previousAutoTable.settings.startY;
            this.footerEndY = doc.previousAutoTable.finalY;
            this.footerWidth = this.paperWidth - (this.margin * 2);
            this.footerHeight = footerHeight;

            doc.setFont("Gill", "italic");
            doc.setFontSize(7);
            doc.text(
                'ADM-010-v01-2021',
                this.margin,
                doc.previousAutoTable.finalY + 3,
            );

            doc.setFont("Helvetica", "normal");
            doc.text(
                `Page ${doc.internal.getNumberOfPages()}`,
                this.margin,
                272,
            );

            doc.setFont("Helvetica", "bold");
            doc.setFontSize(14);
            doc.setTextColor('#ef4444');
            doc.text(
                'RRF NO.',
                135,
                doc.previousAutoTable.finalY + 6,
            );

            doc.setTextColor('#000000');
            doc.text(
                footerData.rrf_number,
                158,
                doc.previousAutoTable.finalY + 6,
            );

            return this.footerEndY;
        },

        drawRepairRequestFormPrintContent(doc, rrf, contentData) {
            doc.autoTable({
                startY: this.headerEndY,
                margin: {
                    top: this.headerEndY,
                    bottom: this.footerHeight + this.margin,
                    left: this.margin,
                    right: this.margin,
                },
                theme: 'grid',
                columnStyles: {
                    0: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    1: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    2: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    3: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    4: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    5: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    6: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    7: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    8: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    9: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    10: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    11: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                },
                headStyles: {
                    halign: 'center',
                    lineColor: [0, 0, 0],
                    fillColor: [209, 213, 219],
                    textColor: [0, 0, 0],
                    fontStyle: 'bold',
                    fontSize: 8,
                },
                head: [
                    [
                        {content: 'Line No.'},
                        {content: 'JOB DESCRIPTION', colSpan: 5},
                        {content: 'VENDOR DETAILS', colSpan: 3},
                        {content: 'QUOTED PRICE', colSpan: 3},
                    ],
                ],
                bodyStyles: {
                    halign: 'center',
                    lineColor: [0, 0, 0],
                    textColor: [0, 0, 0],
                    fontSize: 8,
                },
                body: [
                    ...contentData.map((content, index) => {
                        return [
                            {content: (index + 1)},
                            {content: content.job_description, colSpan: 5},
                            {content: content.vendor, colSpan: 3},
                            {
                                content: this.toCurrency(null, content.quote_price, 2) + '  -',
                                colSpan: 3,
                                styles: {halign: 'right', font: 'courier'},
                            },
                        ];
                    }),
                ],
                didDrawCell: (data) => {
                    if (data.section === 'head') {
                        doc.rect(
                            data.cell.x,
                            data.cell.y,
                            data.cell.width,
                            data.cell.height,
                        );
                    }
                },
                didDrawPage: (data) => {
                    if (doc.getNumberOfPages() > 1) {
                        this.drawRepairRequestFormPrintHeader(doc, rrf.header);
                        this.drawRepairRequestFormPrintFooter(doc, rrf.footer);
                    }
                },
            });
        },

        printRepairRequestForm(rrf) {
            const doc = new this.$jspdf({
                orientation: 'portrait',
                unit: 'mm',
                format: 'letter',
            });

            this.drawRepairRequestFormPrintHeader(doc, rrf.header);
            this.drawRepairRequestFormPrintFooter(doc, rrf.footer);
            this.drawRepairRequestFormPrintContent(doc, rrf, rrf.content);

            doc.autoPrint();
            window.open(doc.output("bloburl"), "_blank");
        },
	},

	watch: {
        'data': {
            handler(val) {
                // this.printRepairRequestForm();
            },
            immediate: true,
        }
    },
}
</script>