<template>
  <v-container class="pa-0 ma-0 white">
    <v-col>
        <v-row class="ma-3">
            <v-col sm="6" md="2" id="date_from">
                <v-menu
                    v-model="datePicker.menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="filters.date_from"
                            v-on="on"
                            label="Date From"
                            append-icon="mdi-calendar-clock"
                            dense
                            outlined
                            hide-details
                            readonly
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="filters.date_from" @input="datePicker.menu1 = false"></v-date-picker>
                </v-menu>
            </v-col>
            <v-col sm="6" md="2" id="date_to">
                <v-menu
                    v-model="datePicker.menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="filters.date_to"
                            v-on="on"
                            label="Date To"
                            append-icon="mdi-calendar-clock"
                            dense
                            outlined
                            hide-details
                            readonly
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="filters.date_to"  @input="datePicker.menu2 = false"></v-date-picker>
                </v-menu>
            </v-col>
                    <v-spacer></v-spacer>
                    <v-btn class="mr-2" @click="getAllNewUnit(); dataTable.options.page = 1" >
                        Filter
                    </v-btn>
                    <v-btn class="mr-2" @click="getAllNewUnit(1)" >
                        Export
                    </v-btn>
                    <v-btn class="mr-2" @click="resetFields()" >
                        Reset
                    </v-btn>
                </v-row>
                <v-row class="ma-3 d-flex justify-content-end">
                    <v-col sm="12" md="2" class="mr-2">
                        <v-text-field
                            v-model="filters.search"
                            @keydown.enter="getAllNewUnit(); dataTable.options.page = 1"
                            label="Search Ref# / Remarks "
                            hide-details
                            clearable
                        ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="2" class="mr-2">
                        <v-text-field
                            v-model="filters.search_item"
                            @keydown.enter="getAllNewUnit(); dataTable.options.page = 1"
                            @click:append="getAllNewUnit(); dataTable.options.page = 1"
                            label="Search Item Model / Name"
                            hide-details
                            clearable
                            append-icon="mdi-magnify"
                        ></v-text-field>
                    </v-col>
                </v-row>

        <v-data-table
            :headers="dataTable.headers"
            :items="dataTable.items.list"
            :loading="dataTable.loading"
            :options.sync="dataTable.options"
            :server-items-length="dataTable.items.count"
            :items-per-page="10"
            class="w-100"
            @update:page="getAllNewUnit()"
            @update:items-per-page="getAllNewUnit()"
        >

            <template v-slot:[`item.status_text`]="{ item }">
                <span class="badge bg-success text-white" v-if="item.status_text == 'Confirmed'">Confirmed</span>
                <span class="badge bg-warning text-white" v-if="item.status_text == 'Saved'">Saved</span>
                <span class="badge bg-info text-white" v-if="item.status_text == 'For Approval'">For Approval</span>
            </template>

            <template v-slot:[`item.processed_date`]="{ item }">
                <span class="text-nowrap">
                    {{ item.processed_date | formatDateTime }}
                </span>
            </template>

            <template v-slot:item.actions="{ item }">
                <v-btn text icon color="orange" v-if="userAccess.view">
                    <v-icon class="btn-action" small @click="viewAdjustment(item)">mdi-eye</v-icon>
                </v-btn>
                <v-btn text icon color="blue" v-if="userAccess.update && item.status == 0">
                    <v-icon class="btn-action" small @click="updateBaklasUnit(item)">mdi-pencil</v-icon>
                </v-btn>
                <v-btn text icon color="red" v-if="userAccess.delete && item.status == 0">
                    <v-icon class="btn-action" small @click="deleteAdBaklasUnit(item.id)">mdi-delete</v-icon>
                </v-btn>
            </template>

        </v-data-table>
    </v-col>

    <v-dialog v-model="baklasDetailsDialog" persistent max-width="1200px">
            <v-card>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">New Unit Details</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeBaklasDetailsDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider />

                <v-layout row class="mx-4 pa-3" >
                    <v-flex lg4>
                        <div>Reference Number: <span>{{ details.reference_num }}</span></div>
                        <div>Status: <span>{{ details.status_text }}</span></div>
                    </v-flex>
                    <v-flex lg4>
                        <div>Created At: <span>{{ details.processed_date }}</span></div>
                        <div>Created By: <span>{{ details.processed_by }}</span></div>
                    </v-flex>
                    <v-flex lg4>
                        <div>Warehouse: <span>{{ details.warehouse_name }}</span></div>
                        <div>Remarks: <span>{{ details.remarks }}</span></div>
                    </v-flex>
                </v-layout>

                <v-divider />

                <v-card-text>
                    <v-card class="pa-4">
                        <v-data-table
                            :headers="dataTablePreview.headers"
                            :items="dataTablePreview.items.list"
                            :loading="dataTablePreview.loading"
                            :options.sync="dataTablePreview.options"
                            :server-items-length="dataTablePreview.items.count"
                            class="w-100"
                            @update:page="getAllNewUnit()"
                            @update:items-per-page="getAllNewUnit()"
                        >

                        <template v-slot:[`item.created_at`]="{ item }">
                            <span class="text-nowrap">
                                {{ item.created_at | formatDateTime }}
                            </span>
                        </template>

                        </v-data-table>
                    </v-card>
                </v-card-text>
                <v-card-actions class="d-flex justify-content-center align-center py-5">
                    <v-btn v-if="userAccess.approve && status == 0" @click="approvedBaklasNewUnit(1,'Approve',details)">
                        For Approval
                    </v-btn>
                    <v-btn v-if="userAccess.confirm && status == 1" @click="approvedBaklasNewUnit(2,'Confirm',details)">
                        Confirm
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
</v-container>
</template>

<script>
import swal from 'sweetalert2';
import { mapGetters } from 'vuex';
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
import batchAddtionDialog from "@/views/main/layouts/dialogs/Service/BaklasAddUnitDialog.vue";

export default {
    mixins: [SharedFunctionsComponentVue],
    data(){
        return {
            components:batchAddtionDialog,
            baklasDetailsDialog: false,
            details:{
                id: '',
                reference_num: '',
                status_text: '',
                processed_date: '',
                processed_by: '',
                warehouse_name: '',
                remarks: ''
            },
            dataTable:{
                items: {
                    list: [],
                    count: 0,
                    selected: 0,
                },
                headers: [
                    { text : 'Ref#',        align : 'start',   value : 'reference_num',     sortable:false},
                    { text : 'Warehouse',   align : 'start',   value : 'warehouse_name',    sortable:false},
                    { text : 'Remarks',     align : 'start',   value : 'remarks',           sortable:false},
                    { text : 'Processed By',align : 'start',   value : 'processed_by',      sortable:false},
                    { text : 'Approved By',align : 'start',   value : 'approved_by',      sortable:false},
                    { text : 'Status',      align : 'start',   value : 'status_text',       sortable:true},
                    { text : 'Process Date',align : 'start',   value : 'processed_date',    sortable:false},
                    { text : 'Actions',     align : 'center',  value : 'actions',           sortable:false},
                ],
                options:{

                },
                loading: false,
            },
            dataTablePreview:{
                items: {
                    list: [],
                    count: 0,
                    selected: 0,
                },
                headers: [
                    { text : 'Model',       align : 'start',   value : 'model',     sortable:false},
                    { text : 'Name',        align : 'start',   value : 'name',    sortable:false},
                    // { text : 'Description',     align : 'start',   value : 'description',  sortable:false},
                    { text : 'Quantity',      align : 'start',   value : 'quantity',       sortable:true},
                ],
                options:{

                },
                loading: false,
            },
            filters: {
                date_from: this.$dayjs().startOf('year').format('YYYY-MM-DD'),
                date_to: this.$dayjs().endOf('year').format('YYYY-MM-DD'),
                search: null,
                search_item: null,
            },
            datePicker:{
                menu1: false,
                menu2: false,

                filter:{
                    request_date_from: null,
                    request_date_to: null,
                }
            },
            searchFilter:{
                warehouse_id: null,
                search_item: null,
                search: null,
            },
            userAccess: {
                view: false,
                create: false,
                update: false,
                delete: false,
                confirm: false,
                approve: false,
            },
            status: 0,
        }
    },
    computed: {
        ...mapGetters([
            'GET_WAREHOUSE_LIST',
            'GET_ALL_USER',
            'USER_ACCESS',
            'GET_ADD_UNIT',
        ]),
    },
    mounted(){
         this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id);
         this.$store.dispatch('getAllWarehouses');
         this.$store.dispatch('getAllUsersSelect');
         this.getAllNewUnit()
    },
    methods:{
        getAllNewUnit(for_export = 0){
            let payload = {
                page: this.dataTable.options.page,
                itemsPerPage: this.dataTable.options.itemsPerPage,
                date_from: this.filters.date_from,
                date_to: this.filters.date_to,
                search: this.filters.search,
                search_item: this.filters.search_item,
                for_export: for_export
            }
            if(for_export){
                swal.fire(
                    {
                        text:'Exporting Files Please wait.',
                        allowOutsideClick:false
                    }
                )
                swal.showLoading()
                const rawDataSheetName = `New_Unit_${this.filters.date_from}-${this.filters.date_to}`
                let exportHeadersAndData = {
                    [rawDataSheetName]: {
                        headers:[
                            { header: 'REF#', key: 'reference_num', width: 15, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: true, raw_data: true },
                            { header: 'MODEL', key: 'model', width: 15, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: true, raw_data: true },
                            { header: 'QUANTITY', key: 'quantity', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: '#,##0' }, pricelist: false, raw_data: true },
                            { header: 'WAREHOUSE', key: 'warehouse_name', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: false, raw_data: true },
                            { header: 'REMARKS', key: 'remarks', width: 50, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: true, raw_data: true },
                            { header: 'PROCESSED BY', key: 'processed_by', width: 20,style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: false, raw_data: true },
                            { header: 'APPROVED BY', key: 'approved_by', width: 20,style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: false, raw_data: true },
                            { header: 'STATUS', key: 'status_text',width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: true, raw_data: true },
                            { header: 'PROCESS DATE', key: 'processed_date', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: false, raw_data: true },
                        ],
                        data:[]
                    }
                }
                this.$store.dispatch('getAllBaklasAddNewUnit', payload).then(response => {
                    let data = response.data
                    data.forEach((e,i) => {
                        e.quantity = Number(e.quantity)
                        e.processed_data = this.formatDateandTime(e.processed_date)
                    });
                    exportHeadersAndData[rawDataSheetName].data = data;
                    this.exportExcel(exportHeadersAndData, `New_Unit_${this.filters.date_from}-${this.filters.date_to}`)
                    swal.close()
                }).catch(e => {
                    swal.fire('Error !', '', 'error')
                    console.log(e)
                })
            }else{
                this.dataTable.loading = true
                this.$store.dispatch('getAllBaklasAddNewUnit', payload).then(response =>  {
                    this.dataTable.items.list = response.data.data.data
                    this.dataTable.items.count = response.data.data.total
                    this.dataTable.loading = false
                });
            }
        },
        viewAdjustment(item){
            this.dataTablePreview.loading = true
            this.details = item
            this.status = item.status
            let payload = {
                baklas_add_unit_id: item.id
            }
            this.$store.dispatch('viewAllItemsBaklasNewUnit', payload).then(response =>  {
                this.dataTablePreview.items.list = response.data.data.data
                this.dataTablePreview.items.count = response.data.total
                this.baklasDetailsDialog = true
                this.dataTablePreview.loading = false
            });
        },
        closeBaklasDetailsDialog(){
            this.baklasDetailsDialog = false
            this.getAllNewUnit()
        },
        approvedBaklasNewUnit(status,action,details){
            swal.fire({
                title: 'Are you sure you want to '+ action +' New Unit?',
                text: 'Make sure that all information are correct',
                icon: 'question',
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                allowOutsideClick: false,
                confirmButtonColor: '#397373',
                cancelButtonColor: 'grey',
                confirmButtonText: 'Confirm',

            }).then((result) => {
                if (result.isConfirmed) {
                    let payload = {
                        status: status,
                        baklas_add_unit_id: details.id,
                        approved_by: details.processed_by
                    }
                    this.$store.dispatch('approvedBaklasNewUnit', payload)
                        .then(response => {
                            let data = response.data
                            if (data.error) {
                                swal.fire(
                                    '',
                                    data.error,
                                    'question'
                                )
                                return false
                            }
                            this.closeBaklasDetailsDialog()
                            this.action = 'success'
                            swal.fire({
                                icon: 'success',
                                title: '',
                                text: response.data.msg,
                            })
                        });
                }
            });
        },
        updateBaklasUnit(item){
            this.$store.commit("ADD_UNIT_DIALOG", {
                action: {
                    type: 'update',
                    button_text: 'Update',
                    title_text: 'Update Baklas New Unit'
                },
                data:item,
                dialogs:{
                    add_unit_dialog: true,
                }
            });
        },
        deleteAdBaklasUnit(id){
            swal.fire({
                title: 'Are you sure you want to Delete New Unit?',
                text: 'Make sure that all information are correct',
                icon: 'question',
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                allowOutsideClick: false,
                confirmButtonColor: '#397373',
                cancelButtonColor: 'grey',
                confirmButtonText: 'Confirm',

            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('deleteBaklasNewUnit', id)
                        .then(response => {
                            let data = response.data
                            if (data.error) {
                                swal.fire(
                                    '',
                                    data.error,
                                    'question'
                                )
                                return false
                            }
                            this.closeBaklasDetailsDialog()
                            this.action = 'success'
                            swal.fire({
                                icon: 'success',
                                title: '',
                                text: response.data.msg,
                            })
                        });
                }
            });
        },
        resetFields(){
            this.filters = {
                date_from: this.$dayjs().startOf('year').format('YYYY-MM-DD'),
                date_to: this.$dayjs().endOf('month').format('YYYY-MM-DD'),
                search: null,
                search_item: null,
            }
            this.getAllNewUnit()
        },
    },
    watch:{
        USER_ACCESS:{
            handler(val){
                this.userAccess.create = false;
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                this.userAccess.confirm = false;
                this.userAccess.approve = false;

                if (val != "fail") {
                    val.forEach((e) => {
                        if (e.actions_code == "create") {
                            this.userAccess.create = true;
                        }
                        if (e.actions_code == "update") {
                            this.userAccess.update = true;
                        }
                        if (e.actions_code == "view") {
                            this.userAccess.view = true;
                        }
                        if (e.actions_code == "delete") {
                            this.userAccess.delete = true;
                        }
                        if (e.actions_code == "confirm") {
                            this.userAccess.confirm = true;
                        }
                        if (e.actions_code == "approve") {
                            this.userAccess.approve = true;
                        }
                    });
                }
            }
        },
        GET_ADD_UNIT:{
            handler(val){
                if(val.reload_table){
                    this.getAllNewUnit()
                }
            }
        },
        'dataTable.options':{
            handler(val){
                if(val.itemsPerPage < 0){
                    val.itemsPerPage = this.dataTable.items.count;
                }
                this.getAllNewUnit()
            }
        }
    }
}
</script>

<style>

</style>
