<template>
    <div class="w-100">
        <v-app id="my_vue_app">
            <v-card>
                <v-card-title>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="4">
                                <v-tooltip bottom :color="GET_FOR_APPROVAL_COUNT > 0 ? 'red' : ''">
                                    <template v-slot:activator="{ on, attrs }">
                                        <span
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-btn
                                                :disabled="GET_FOR_APPROVAL_COUNT > 0 ? true : false"
                                                :color="GET_FOR_APPROVAL_COUNT > 0 ? 'red' : ''"
                                                :loading="loading.button.filter"
                                                @click="openEmployeeFilterDialog()"
                                                class="ma-2"
                                                text
                                                fab
                                            >
                                            <v-icon>
                                                mdi-timetable
                                            </v-icon>
                                                <template v-slot:loader>
                                                    <span class="custom-loader">
                                                    <v-icon light>mdi-cached</v-icon>
                                                    </span>
                                                </template>
                                            </v-btn>
                                        </span>
                                    </template>
                                    <span> {{ GET_FOR_APPROVAL_COUNT > 0 ? 'There are schedules for approval' : 'Show Biometrics Filter'}}</span>
                                </v-tooltip>
                            </v-col>
                            <v-spacer/>
                            <v-col cols="12" sm="12" md="3" class="d-flex justify-content-end align-items-center">
                                <a
                                    v-for="(status, index ) in status_list" :key="index"
                                    :class="`ml-1 badge badge-${status.text} ${status.value == selected_status ? `selected` : `non-selected`}`" @click="selectedStatus(status)"
                                    >
                                {{ status.text ? status.text.replace('-'," ") : null  }}
                                </a>
                            </v-col>
                        </v-row>
                        <v-row class="mb-2">
                            <v-col cols="3">
                                <v-autocomplete
                                    v-model="filter.timekeeping_periods"
                                    @change="getTimekeepingRecords({ query: search })"
                                    :items="dropdowns.timekeeping_periods"
                                    item-text="period"
                                    item-value="period"
                                    label="Timekeeping Period"
                                    auto-select-first
                                    clearable
                                    outlined
                                    required
                                    dense
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="3">
                                <v-autocomplete
                                    v-model="filter.generated_by"
                                    @change="getTimekeepingRecords({ query: search })"
                                    :items="dropdowns.hr_employee_lists"
                                    item-text="name"
                                    item-value="id"
                                    label="Generated By"
                                    auto-select-first
                                    deletable-chips
                                    small-chips
                                    clearable
                                    multiple
                                    outlined
                                    required
                                    dense
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-spacer></v-spacer>
                        </v-row>
                        <v-row>
                            <v-spacer></v-spacer>
                            <v-col class="row col-sm-12 col-md-4 mr-3 align-items-end">
                                <v-text-field
                                    id="searchBar"
                                    v-model="filter.search"
                                    label="Search Timekeeping #"
                                    single-line
                                    hide-details
                                    @change="searchInput()"
                                >
                                </v-text-field>
                                <v-icon @click="searchInput()">mdi-magnify</v-icon>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-title>
                <v-container fluid>
                    <v-data-table
                        :headers="datatable.headers"
                        :items="datatable.items"
                        :options.sync="datatable.options"
                        :server-items-length="datatable.totalItems"
                        :items-per-page="10"
                        :loading="loading.datatable"
                        :footer-props="{
                            itemsPerPageOptions: datatable.itemsPerPageOptions
                        }"
                        dense
                        loading-text="Loading... Please wait"
                        fixed-header
                    >
                        <template v-slot:[`item.action`]="{ item }">
                            <v-tooltip bottom color="#f69f1a">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn text icon color="orange"
                                            v-if="userAccess.view"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                        <v-icon

                                            class="btn-action"
                                            small
                                            @click="viewTimekeepingRecords(item)"
                                        >mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                                <span>View Timekeeping Record</span>
                            </v-tooltip>
                            <v-tooltip bottom color="blue">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn text icon color="blue"
                                        v-if="userAccess.edit && item.status != 3"
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                        <v-icon
                                            class="btn-action"
                                            small
                                            @click="editTimekeepingRecords(item)"
                                            >mdi-pencil</v-icon
                                        >
                                    </v-btn>
                                </template>
                                <span>Edit Timekeeping Record</span>
                            </v-tooltip>
                            <v-tooltip bottom color="red">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn text icon color="red"
                                        v-if="userAccess.delete && item.status != 3"
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                        <v-icon
                                            class="btn-action"
                                            small
                                            @click="deletTimekeepingRecords(item.id)"
                                            >mdi-delete</v-icon
                                        >
                                    </v-btn>
                                </template>
                                <span>Delete Timekeeping Record</span>
                            </v-tooltip>
                        </template>
                        <template v-slot:[`item.status`]="{item}">
                            <span :class="`badge badge-${checkStatus(item.status)}`">{{ checkStatus(item.status) }}</span>
                        </template>
                    </v-data-table>
                </v-container>
            </v-card>
            <EmployeeBiometricsDataComponentViewVue/>
            <BiometricsSummaryComponentViewVue/>
            <v-dialog v-model="filter.dialog" scrollable persistent max-width="50%" style="z-index: 30" :retain-focus="false">
                <v-card v-if="filter.dialog">
                    <v-row class="m-0">
                        <v-col cols="8">
                            <v-card-title>
                                <span class="text-lg font-semibold" v-text="`Extract Data From Biometrics`"></span>
                            </v-card-title>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="2">
                            <v-btn text icon color="gray" class="float-right" @click="closeEmployeeFilterDialog()">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-card-text style="max-height: 460px; border: 1px solid #e0e0e0; padding: 0">
                        <v-row dense no-gutters>
                            <v-spacer></v-spacer>
                            <v-col dense cols="3" class="my-1 px-2">
                                <v-menu
                                    v-model="selectedRange.from_menu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    min-width="290"
                                    offset-y
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            v-model="selectedRange.from"
                                            v-on="on"
                                            label="Start Date"
                                            class="text-field-width-year mt-4"
                                            append-icon="mdi-calendar-clock"
                                            solo
                                            dense
                                            readonly
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="selectedRange.from" :max="currentDate"  @change="selectedRange.from_menu = false, validateRange()" color="#f69f1a">
                                    </v-date-picker>
                                </v-menu>

                            </v-col>
                            <v-col dense cols="3" class="my-1 px-2">
                                <v-menu
                                    v-model="selectedRange.to_menu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    min-width="290px"
                                    offset-y
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            v-model="selectedRange.to"
                                            solo
                                            dense
                                            readonly
                                            append-icon="mdi-calendar-clock"
                                            v-on="on"
                                            label="End Date"
                                            class="text-field-width-year mt-4"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="selectedRange.to" :max="currentDate"  @change="selectedRange.to_menu = false, validateRange()" color="#f69f1a">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row dense no-gutters>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-checkbox
                                    v-model="filter.with_inactive"
                                    :true-value="1"
                                    :false-value="0"
                                    label="With Inactive"
                                >
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row dense no-gutters>
                            <v-col dense cols="12" class="my-1 px-2">
                                <v-autocomplete
                                    v-model="filter.department_ids"
                                    :items="dropdowns.departments_lists"
                                    item-text="text"
                                    item-value="value"
                                    label="Department"
                                    auto-select-first
                                    hide-details
                                    small-chips
                                    clearable
                                    outlined
                                    multiple
                                    chips
                                    dense
                                    flat>
                                </v-autocomplete>
                            </v-col>
                            <v-col dense cols="12" class="my-1 px-2">
                                <v-autocomplete
                                    v-model="filter.store_branch_ids"
                                    :disabled="hasStoresInList()"
                                    :items="dropdowns.store_branches"
                                    item-text="text"
                                    item-value="value"
                                    label="Store Branch"
                                    auto-select-first
                                    hide-details
                                    small-chips
                                    clearable
                                    outlined
                                    multiple
                                    chips
                                    dense
                                    flat>
                                </v-autocomplete>
                            </v-col>
                            <!-- <v-col cols="12">
                                <v-autocomplete
                                    v-model="filter.areas"
                                    :items="dropdowns.location_lists"
                                    item-text="text"
                                    item-value="value"
                                    label="Areas"
                                    auto-select-first
                                    hide-details
                                    small-chips
                                    clearable
                                    outlined
                                    chips
                                    multiple
                                    dense
                                    deletable-chips
                                    return-object
                                    flat>
                                </v-autocomplete>
                            </v-col> -->
                            <v-col dense cols="12" class="my-1 px-2">
                                <v-autocomplete
                                    v-model="filter.contract_type_ids"
                                    :items="dropdowns.contract_types"
                                    item-text="text"
                                    item-value="value"
                                    label="Contract Type"
                                    auto-select-first
                                    hide-details
                                    small-chips
                                    clearable
                                    outlined
                                    multiple
                                    chips
                                    dense
                                    flat>
                                </v-autocomplete>
                            </v-col>
                            <v-col dense cols="12" class="my-1 px-2">
                                <v-autocomplete
                                    v-model="filter.client_ids"
                                    :items="GET_CLIENT_LIST_SELECTION"
                                    :disabled="checkContractType(['Agency', 'Absorbed'])"
                                    item-text="text"
                                    item-value="value"
                                    label="Agency"
                                    auto-select-first
                                    hide-details
                                    small-chips
                                    clearable
                                    outlined
                                    multiple
                                    chips
                                    dense
                                    flat>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12">
                                <v-card elevation="0">
                                    <v-card-title>
                                        <v-row>
                                            <v-col cols="1" class="pa-0">
                                                <v-tooltip bottom color="blue">
                                                    <template v-slot:activator="{attrs, on}">
                                                        <v-btn
                                                            @click="checkBiometricApiStatus()"
                                                            :loading="loading.biometric_status"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            color="blue"
                                                            text
                                                            fab
                                                        >
                                                            <v-icon>mdi-sync</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Refresh</span>
                                                </v-tooltip>
                                            </v-col>
                                            <v-spacer></v-spacer>
                                            <v-col cols="4">
                                                <v-text-field
                                                    v-model="search"
                                                    append-icon="mdi-magnify"
                                                    label="Search"
                                                    single-line
                                                    hide-details
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-data-table
                                            v-model="filter.areas"
                                            @toggle-select-all="selectAllAreas($event, bool = !bool )"
                                            :headers="dropdowns.datatable.area_headers"
                                            :items="dropdowns.datatable.area_items"
                                            :search="search"
                                            :items-per-page="10"
                                            :expanded.sync="dropdowns.datatable.area_items_expanded"
                                            :loading="loading.biometric_status"
                                            item-key="value"
                                            selectable-key="isSelectable"
                                            loading-text="Loading... Please wait"
                                            show-expand
                                            fixed-header
                                            dense
                                        >
                                            <template v-slot:[`item.status`]="{item}">
                                                <v-chip
                                                    v-if="item.status == 'partial'"
                                                    color="#d8a120"
                                                    x-small
                                                    text-color="white"
                                                >
                                                    partial
                                                </v-chip>
                                                <v-chip
                                                    v-else
                                                    :color="
                                                    item.status == true ? 'green accent-3': 'red darken-1'
                                                    "
                                                    x-small
                                                    text-color="white"
                                                >
                                                    {{ item.status == true ?'Online':'Offline'  }}
                                                </v-chip>
                                            </template>
                                            <template #item.data-table-expand="{ item, expand, isExpanded }">
                                                <td v-if="item.port_details.length > 1" class="text-start">
                                                    <v-btn
                                                        icon
                                                        class="v-data-table__expand-icon"
                                                        :class="{'v-data-table__expand-icon--active' : isExpanded}"
                                                        @click="expand(!isExpanded)"
                                                    >
                                                        <v-icon>mdi-chevron-down</v-icon>
                                                    </v-btn>
                                                </td>
                                            </template>
                                            <template v-slot:expanded-item="{ headers, item }">
                                                <td :colspan="headers.length" >
                                                    <v-data-table
                                                        :headers="dropdowns.datatable.port_headers"
                                                        :items="item.port_details"
                                                        fixed-header
                                                        hide-default-footer
                                                    >
                                                    <template v-slot:[`item.status`]="{item: childItem}">
                                                        <v-chip
                                                            :color="
                                                            childItem.status == true? 'green accent-3': 'red darken-1'
                                                            "
                                                            x-small
                                                            text-color="white"
                                                        >
                                                            {{ childItem.status == true ?'Online':'Offline'  }}
                                                        </v-chip>
                                                    </template>
                                                    </v-data-table>
                                                </td>
                                            </template>
                                        </v-data-table>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions class="d-flex justify-center">
                        <v-tooltip bottom color="blue">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    :loading="loading.button.biometrics"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="extractBiometricsData()"
                                    color="blue"
                                    icon
                                >
                                    <v-icon>
                                        mdi-table-eye
                                    </v-icon>
                                    <template v-slot:loader>
                                        <span class="custom-loader">
                                        <v-icon light>mdi-cached</v-icon>
                                        </span>
                                    </template>
                                </v-btn>
                            </template>
                            <span>Extract Biometrics Data</span>
                        </v-tooltip>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="loading.dialog" width="500" persistent>
                <v-card>
                    <v-card-title >
                        <span style="font-size:12px">
                            {{ loading.text }}
                        </span>
                    </v-card-title>
                    <v-card-text>
                        <v-progress-linear
                            v-model="loading.value"
                            :buffer-value="loading.value"
                            color="green"
                            stream
                        >
                        </v-progress-linear>
                        {{Math.ceil(loading.value)}}%
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-app>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2';
import EmployeeBiometricsDataComponentViewVue from '@/views/main/layouts/dialogs/HR/Timekeeping/EmployeeBiometricsDataComponentView.vue';
import BiometricsSummaryComponentViewVue from '@/views/main/layouts/dialogs/HR/Timekeeping/BiometricsSummaryComponent.vue';
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
export default {
    mixins:[SharedFunctionsComponentVue],
    data(){
        return {
            loaded: false,
            search: "",
            filter:{
                search:"",
                with_inactive: 0,
                dialog: false,
                department_ids: [],
                contract_type_ids: [],
                areas: [],
                host_ids: [],
                client_ids: [],
            },

            datatable:{
                headers:
                    [
                        { text: 'Timekeeping #', align: 'start', value: 'timekeeping_num'},
                        { text: 'Timekeeping Period', align: 'start', value: 'timekeeping_period'},
                        { text: 'Generated By', align: 'start', value: 'generated_by_name'},
                        // { text: 'Department/s', align: 'start',value:'departments' },
                        { text: 'Approved By', align: 'start', value: 'approved_by_name'},
                        { text: 'Approved Date', align: 'start', value: 'approved_date'},
                        { text: 'Confirmed By', align: 'start', value: 'confirmed_by_name'},
                        { text: 'Confirmed Date', align: 'start', value: 'confirmed_date'},
                        { text: 'Remarks', align: 'start',value:'remarks' },
                        { text: 'Status', align: 'start',value:'status' },
                        { text: "Action", align: "center", value: "action" }
                    ],
                items:[],
                options:{},
                totalItems: 0,
                itemsPerPageOptions: [5, 10, 15, 50, 100],
                itemsPerPage: 10,
            },

            loading:{
                datatable: false,
                button: {
                    filter: false,
                    biometrics: false
                },
                biometric_status: false,


                dialog: false,
                bufferValue: 100,
                value: 0,
                text: 'Extracting Biometrics Data: ',
            },

            userAccess: {
                view: false,
                create: false,
                edit: false,
                delete: false,
                show_all_timekeeping_record: false
            },

            dropdowns:{
                departments_lists: [],
                location_lists: [],
                datatable: {
                    area_headers: [
                        { text: 'Area', align: 'start', value: 'text'},
                        { text: 'Status', align: 'start', value: 'status'},
                    ],
                    area_items: [],
                    area_items_expanded: [],

                    port_headers: [
                        { text: 'Port', align: 'start', value: 'ports'},
                        { text: 'Status', align: 'start', value: 'status'},
                    ],
                    port_items: []
                },
                timekeeping_periods: [],
                store_branches: [],
                contract_types: [],
            },

            selected_status: '',
            isDepartmentView: 0,
            component_dispatches:[
                'getDepartment',
                'getBioMetricLocationSelection',
                'getTimekeepingPeriods',
                'getAllSelectUsers',
                'getStoreBranchesSelectionSelection',
                'getEmployeeContractTypeSelection',
                'getClientListSelection',
			],
            hr_user_department_access: [],
            status_list: [
                {
                    text: 'all',
                    value: 0
                },
                {
                    text: 'saved',
                    value: 1
                },
                {
                    text: 'approved',
                    value: 2
                },
                {
                    text: 'confirmed',
                    value: 3
                },
            ],
            areas:[],
            selected_status: 0,
            form:{
                api_list: []
            },
            search: '',
            openCount: 0,
            bioStatusLoaded: true,
            bool: false,
            selectedRange:{
                from: this.$dayjs().startOf('week').format('YYYY-MM-DD'),
                to: this.$dayjs().endOf('week').format('YYYY-MM-DD'),
                from_menu: false,
                to_menu: false,
            },
            currentDate: this.$dayjs().subtract(1, 'd').format('YYYY-MM-DD')
        }
    },
    async mounted(){
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        this.getSearchFromUrl();
        await this.getAllDropdowns();
        await this.checkBiometricApiStatus();
        await this.setPayrollPeriod();
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS',
            'GET_TIMEKEEPING_NEW_DATA',
            'USERACCOUNT_LOAD',
            'GET_HR_TAB_IS_ACTIVE',
            'GET_HR_DISPATCH',
            'GET_SELECTED_DATE_RANGE',
            'GET_LIST_DEPARTMENT',
            'GET_HR_USER_DEPARTMENTS',
            'GET_TIMEKEEPING_RECORD_EMPLOYEE_ID',
            'GET_BIO_METRIC_LOCATION_SELECTION',
            'GET_BIO_API_SETTINGS',
            'GET_HR_USER_LOCATION_ITEMS',
            'GET_TIMEKEEPING_PERIOD_SELECTION',
            'GET_ALL_USERS_SELECT',
            'GET_FOR_APPROVAL_COUNT',
            'GET_STORE_BRANCHES_SELECTION',
            'GET_CONTRACT_TYPE_SELECTION',
            'GET_CLIENT_LIST_SELECTION',
        ]),
    },
    components:{
        EmployeeBiometricsDataComponentViewVue,
        BiometricsSummaryComponentViewVue
    },
    methods:{
        checkStatus(status){
            let name = this.status_list.find((x) => x.value == status).text;
            return name;
        },
        selectedStatus(data){
            this.selected_status = data.value
            this.getTimekeepingRecords({ query: this.search })
        },
        async getTimekeepingRecords(filter = null){
            this.datatable.items = [];
            this.loading.datatable = true;
            let payload = {
                page                    : this.datatable.options.page,
                itemsPerPage            : this.datatable.options.itemsPerPage,
                search                  : this.filter.search,
                sortBy                  : this.datatable.options.sortBy,
                sortDesc                : this.datatable.options.sortDesc,
                status                  : this.selected_status,
                filter                  : filter,
                selected_status         : this.selected_status,
                timekeeping_periods     : this.filter.timekeeping_periods,
                generated_by            : this.filter.generated_by,
                show_all_timekeeping_record  : this.userAccess.show_all_timekeeping_record
            };
            await this.$store.dispatch(this.GET_HR_DISPATCH.get, payload).then((response) => {
                this.datatable.items                    = response.data.data;
                this.datatable.totalItems               = response.data.total;
                this.loading.datatable                  = false;
                this.$store.commit('DIALOG', false);
            }).catch((err)=>{
                Swal.fire({
                    title: 'Error',
                    text: 'Network Error',
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
                console.log(err)
            });
        },
        searchInput(){
            this.datatable.options.page = 1
            this.getTimekeepingRecords({ query: this.search });
        },
        viewTimekeepingRecords(item){
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", "View");
            this.$store.dispatch('getTimekeepingRecordEmployeeID', {
                id: item.id
            }).then((res)=>{
                let payload = {
                    employee_ids: this.GET_TIMEKEEPING_RECORD_EMPLOYEE_ID,
                    id: item.id
                }
                this.$store.dispatch('showTimekeepingRecordDetails', payload).then((response)=>{
                    this.$store.commit("TIMEKEEPING_RECORD_VIEW", response);
                })
            }).catch((err)=>{
                console.log(err);
                Swal.fire(
                    "Network Error",
                    "",
                    "error"
                )
            })
            // this.$store.commit("TIMEKEEPING_RECORD_VIEW", item);
        },
        editTimekeepingRecords(item){
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", "Update");
            this.$store.dispatch('getTimekeepingRecordEmployeeID', {
                id: item.id
            }).then((res)=>{
                let payload = {
                    employee_ids: this.GET_TIMEKEEPING_RECORD_EMPLOYEE_ID,
                    id: item.id
                }
                this.$store.dispatch('showTimekeepingRecordDetails', payload).then((response)=>{
                    this.$store.commit("TIMEKEEPING_RECORD_VIEW", response);
                })

            }).catch((err)=>{
                console.log(err);
                Swal.fire(
                    "Network Error",
                    "",
                    "error"
                )
            })
        },
        getSearchFromUrl() {
            if (Object.keys(this.$route.query).length != 0) {
                Object.keys(this.$route.query).forEach(e=>{
                if(e != 'for_approval')
                    this.filter.search = atob(this.$route.query[e]);
                })
                this.searchInput();
            }
        },
        async extractBiometricsData(){
            this.loading.value = 0;
            this.loading.button.biometrics = true;
            let period = [this.selectedRange.from, this.selectedRange.to]
            // let period = this.getDatesFromRange(this.GET_SELECTED_DATE_RANGE.from, this.GET_SELECTED_DATE_RANGE.to, "array");
            this.filter.host_ids = [];

            if (this.filter.areas) {
                this.filter.areas.forEach((area) => {
                    const areaHostIds = area.port_details.filter((x) => x.status == true).map(det => det.host_id);
                    this.filter.host_ids = [...new Set([...this.filter.host_ids, ...areaHostIds])];
                });
            }

            let payload = {
                period: period,
                filter: this.filter
            }
            this.loading.dialog = true;

            await this.$store.dispatch('getEmployeeIDSBio', payload).then(async (res)=>{
                // this.loading.button.biometrics = false;
                let employee_ids = res;
                let biometrics_data = [];
                let promises = [];
                let chunkSize = 50;

                let totalChunks = Math.ceil(employee_ids.length / chunkSize);

                for (let chunk of Array.from({ length: totalChunks }, (_, index) => index)) {
                    let start = chunk * chunkSize;
                    let end = start + chunkSize;
                    let chunkData = employee_ids.slice(start, end);
                    payload.employee_ids = chunkData;

                    try {
                        let response = await this.$store.dispatch('getBiometricsData', payload);
                        // Update the loading progress
                        this.loading.value = ((chunk + 1) / totalChunks) * 100;

                        // Ensure the new response is added to the end of the biometrics_data
                        biometrics_data = biometrics_data.concat(response);

                        // Update the store with the latest biometrics_data
                        this.$store.commit('BIOMETRICS_DATA', biometrics_data);
                    } catch (err) {
                        Swal.fire("Error", "", "error");
                        this.loading.button.biometrics = false;
                        this.resetFilters();
                        console.log(err);
                        break; // Break the loop on error if needed
                    }
                }


                Promise.all(promises)
                    .then(() => {
                        // Executed after all promises are resolved
                        this.$store.commit('BIOMETRICS_SUMMARY_DIALOG', true);
                        this.loading.button.biometrics = false;
                        this.resetFilters();
                        this.loading.dialog = false;
                    })
                    .catch((error) => {
                        console.error(error);
                    });

                // this.loading.button.biometrics = false;
            })
        },
        viewEmployeeBioTimeData(data){
            // let period = this.getDatesFromRange(this.GET_SELECTED_DATE_RANGE.from, this.GET_SELECTED_DATE_RANGE.to, "array");
            let period = [this.GET_SELECTED_DATE_RANGE.from, this.GET_SELECTED_DATE_RANGE.to]
            let payload = {
                emp_code: data.emp_code,
                period: period
            }
            this.$store.dispatch('viewEmployeeBioTime', payload).then((res)=>{
                if(res.biometricdata.length != 0){
                    this.$store.commit('EMPLOYEE_BIOMETRICS_DIALOG', true)
                }else{
                    Swal.fire({
                        title: "No Biometrics for the selected period.",
                        text: "",
                        icon: "warning"
                    })
                    return false;
                }
            }).catch((err)=>{
                Swal.fire(
                    "Error",
                    "",
                    "error"
                );
                console.log(err)
            })
        },
        closeEmployeeFilterDialog(){
            this.filter.dialog = false;
            this.resetFilters()
        },
        async openEmployeeFilterDialog(){
            // let payload = {
            //     timekeeping_period_start: this.GET_SELECTED_DATE_RANGE.from,
            //     timekeeping_period_end: this.GET_SELECTED_DATE_RANGE.to
            // }
            // this.$store.dispatch('checkExistingTimekeepingRecord',payload).then((res)=>{
            //     if(res == 1){
            //         Swal.fire(
            //             "Existing Timekeeping Record Found for selected Period!",
            //             "",
            //             "warning"
            //         )
            //     }else{
                    this.setPayrollPeriod();
                    this.loading.button.filter = true;
                    this.filter.dialog = true;
                    this.loading.button.filter = false;
                    this.checkBiometricApiStatus();
                    this.validateRange();
            //     }
            // }).catch((err)=>{
            //     Swal.fire(
            //         "Error",
            //         "",
            //         "error"
            //     );
            //     console.log(err)
            // })
        },
        async getAllDropdowns(){
            await this.component_dispatches.forEach(el=>{
                this.$store.dispatch(el)
            })
        },
        resetFilters(){
            Object.assign(this.filter, {
                // dialog: false,
                department_ids:[],
                areas: [],
                port: null,
                host_ids: [],
                with_inactive: 0,
                client_ids: [],
                contract_type_ids: [],
            })
        },
        deletTimekeepingRecords(id){
            Swal.fire({
                icon: 'question',
                text: "Are you sure you want to Delete?",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#397373",
                cancelButtonColor: "#397373",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch(this.GET_HR_DISPATCH.delete, id).then((success) => {
                        Swal.fire(
                            success,
                            "",
                            success.toLowerCase()
                        );
                    });
                }
            });
        },
        async setDefaultLocationBasedHRAccess(){
            let filtered_selection = [];
            await this.GET_HR_USER_DEPARTMENTS.forEach((e, i) => {
                this.GET_HR_USER_LOCATION_ITEMS.forEach((p) =>{

                    let filteredAreas = this.GET_LIST_DEPARTMENT.find((x) => x.value == e).areas;

                    // Check if the array already exists in filtered_selection
                    filteredAreas.forEach((area) => {
                        if(area.value === p){
                            if (!filtered_selection.find((x)=> x.value === area.value)) {
                                filtered_selection.push(area);
                            }
                        }
                    });
                })
            });
            this.dropdowns.datatable.area_items = filtered_selection;
            this.bioStatusLoaded = false
        },
        async checkBiometricApiStatus(){
            this.loading.biometric_status = true;
            await this.$store.dispatch('getBioApiSettings').then(async (res)=>{

                // Get Biometrics Host API IDS
                let bio_api_ids = this.dropdowns.datatable.area_items
                .map((e) => e.port_details.map((innerObj) => innerObj.host_id))
                .flat();

                let uniqueBioApiIds = [...new Set(bio_api_ids)];

                let bio_api_settings = this.GET_BIO_API_SETTINGS.filter((x) => uniqueBioApiIds.includes(x.id))

                this.dropdowns.datatable.area_items.forEach((x)=>{
                    x.status = false
                    x.isSelectable = false
                    if(x.port_details.length > 1){
                        x.status = 'partial'
                    }

                    bio_api_settings.forEach(async (e) => {
                        let that = e;
                        let self = this
                        await e.port_a_p_i.forEach(async el=>{
                            let protocol = e.protocol == 1? "https" : "http";
                            let port = el.ports;
                            let port_detail = x.port_details.find((pr)=> pr.host_id == that.id)

                            if(port_detail){
                                port_detail.status = false;
                            }
                            await fetch(`${protocol}://${e.host}:${port}`,{method:'GET',mode:'no-cors'}).then(async response => {
                                if(!response.ok){
                                    if(port_detail){
                                        if(port_detail.host_id == that.id){
                                            port_detail.status = true;
                                        }
                                    }
                                    let temp = this.dropdowns.datatable.area_items;
                                    this.dropdowns.datatable.area_items = [];
                                    this.dropdowns.datatable.area_items = temp

                                    // If more port in one location check if has down port
                                    if(x.port_details.length > 1){
                                        let allTrue = x.port_details.every(obj => obj.status === true);
                                        x.status = allTrue ? allTrue : 'partial'
                                        x.isSelectable = true;

                                    }else{
                                        if(x.port_details.find((p)=> p.host_id == that.id)){
                                            x.status = true;
                                            x.isSelectable = true;
                                        }
                                    }
                                    this.loading.biometric_status = false;
                                }else{
                                    if(x.port_details.find((p)=> p.host_id == that.id)){
                                        x.port_details.find((p)=> p.host_id == that.id).status = false;
                                        x.port_details.find((p)=> p.host_id == that.id).isSelectable = false;
                                    }
                                }
                            }).catch(error => {
                                console.log(error)
                                if(x.port_details.find((p)=> p.host_id == that.id)){
                                    x.status = false
                                    x.isSelectable = false;
                                }
                                this.loading.biometric_status = false;
                            });
                        })
                        e.protocol = e.protocol == "https" || e.protocol == 1? 1 : 0;
                    });
                })
                let no_down_port = this.dropdowns.datatable.area_items.every(e => e.status === true);
                if(this.openCount == 0){
                    if(!no_down_port){
                        Swal.fire(
                            "There are offline port/s detected on the server.",
                            "Please contact IT Administrator.",
                            "warning"
                        )
                    }
                }
                this.loading.biometric_status = false;
                this.openCount = 1;
            })
        },
        selectAllAreas(item, val){
            this.dropdowns.datatable.area_items.forEach((e)=>{
                if(e.isSelectable){
                    e.selected = val
                }
            })
        },
        toggleSelection(item) {
            this.$set(item, 'selected', !item.selected);
        },
        resetCheckboxes(){
            this.dropdowns.datatable.area_items.forEach(item => {
                item.selected = false;
            });
        },
        setPayrollPeriod(){
            let currentDate = this.$dayjs();

            let startOfMonth = currentDate.clone().startOf('month');

            // if (currentDate.date() <= 15) {
                this.selectedRange.from = startOfMonth.format('YYYY-MM-DD');
                let endOfMonth15th = startOfMonth.clone().date(15);
                this.selectedRange.to = endOfMonth15th.isAfter(currentDate) ? currentDate.clone().subtract(1, 'day').format('YYYY-MM-DD') : endOfMonth15th.format('YYYY-MM-DD');
            // } else {
            //     this.selectedRange.from = startOfMonth.clone().date(16).format('YYYY-MM-DD');
                let endOfMonth = startOfMonth.clone().endOf('month');
                this.selectedRange.to = endOfMonth.isAfter(currentDate) ? currentDate.clone().subtract(1, 'day').format('YYYY-MM-DD') : endOfMonth.format('YYYY-MM-DD');
                // this.selectedRange.to =  endOfMonth.format('YYYY-MM-DD');
            // }
        },
        validateRange(){
            if(this.selectedRange.from && this.selectedRange.to){
                if(this.selectedRange.from > this.selectedRange.to){
                    Swal.fire(
                        "Date Range Not Valid!",
                        "",
                        "warning"
                    )
                    this.setPayrollPeriod()
                    return false
                }
            }
            this.$store.commit('SELECTED_DATE_RANGE', this.selectedRange);
        },
        hasStoresInList(){
            return !this.dropdowns.departments_lists.map((e)=> e.code).includes('ST')
        },
        checkContractType(value){
            if (this.filter.contract_type_ids && this.filter.contract_type_ids.length > 0) {
                for (let val of value) {
                    let obj = this.GET_CONTRACT_TYPE_SELECTION.find((e) => e.text == val);
                    if (obj) {
                        if (this.filter.contract_type_ids.includes(obj.value)) {
                            return false;
                        }
                    }
                }
            }
            return true
        }
    },
    watch:{
        'datatable.options': {
            handler() {
                this.getTimekeepingRecords({ query: this.search });
            },
            deep: true,
        },
        GET_TIMEKEEPING_NEW_DATA:{
            handler(val){
                if(val){
                    this.getTimekeepingRecords({ query: this.search });
                    this.$store.commit('TIMEKEEPING_NEW_DATA', false)
                }
            }
        },
        USER_ACCESS: {
            handler(val) {
                if(val != "fail"){
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                this.userAccess.create = false;
                this.userAccess.show_all_timekeeping_record = false;
                    val.forEach(e => {
                        if (e.actions_code == 'edit') {
                            this.userAccess.edit = true;
                        } else if (e.actions_code == 'delete') {
                            this.userAccess.delete = true;
                        } else if (e.actions_code == 'view') {
                            this.userAccess.view = true;
                        } else if (e.actions_code == 'create') {
                            this.userAccess.create = true;
                        } else if (e.actions_code == 'show_all_timekeeping_record') {
                            this.userAccess.show_all_timekeeping_record = true;
                        }
                    });
                }
            }
        },
        GET_LIST_DEPARTMENT:{
            handler(val){
                this.dropdowns.departments_lists = val
            }
        },
        GET_BIO_METRIC_LOCATION_SELECTION:{
            handler(val){
                this.dropdowns.location_lists = val
            }
        },
        GET_HR_USER_DEPARTMENTS:{
            handler(val){
                this.hr_user_department_access = val;
                let filtered_departments = this.dropdowns.departments_lists.filter(x=> this.hr_user_department_access.includes(x.value) )
                this.dropdowns.departments_lists = filtered_departments

                this.setDefaultLocationBasedHRAccess();
            }
        },
        'filter.department_ids':{
            handler(val){
                let filtered_selection = [];
                if (val.length) {
                    val.forEach((e, i) => {
                        let filteredAreas = this.GET_LIST_DEPARTMENT.find((x) => x.value == e).areas;

                        // Check if the array already exists in filtered_selection
                        filteredAreas.forEach((area) => {
                            if (!filtered_selection.find((x)=> x.value === area.value)) {
                                filtered_selection.push(area);
                            }
                        });
                    });
                    this.dropdowns.location_lists = filtered_selection;
                }else{
                    this.setDefaultLocationBasedHRAccess();
                }
            }
        },
        'filter.dialog':{
            handler(val){
                if(!val){
                    // this.checkBiometricApiStatus()
                    this.openCount = 0;
                    this.resetCheckboxes()
                }
            }
        },
        'dropdowns.datatable.area_items':{
            deep: true,
            handler(val){
                if(val.length > 0){
                    this.filter.areas = val.filter((x) => x.selected == true)
                }
            }
        },
        GET_TIMEKEEPING_PERIOD_SELECTION:{
            handler(val){
                this.dropdowns.timekeeping_periods = val
            }
        },
        GET_ALL_USERS_SELECT:{
            handler(val){
                let hr_id = this.GET_LIST_DEPARTMENT.find((e) => e.code == 'HR-A').value;
                this.dropdowns.hr_employee_lists = val.filter((x)=> x.department_id == hr_id);
            }
        },
        GET_STORE_BRANCHES_SELECTION:{
            handler(val){
                this.dropdowns.store_branches = val;
            }
        },
        GET_HR_TAB_IS_ACTIVE:{
            handler(val){
                if(val == 'biometrics_data'){
                    if(this.GET_FOR_APPROVAL_COUNT > 0){
                        Swal.fire(
                            "There are still schedule for approval!",
                            "Please approve pending schedule changes.",
                            "warning"
                        )
                    }
                    this.getTimekeepingRecords({ query: this.search });
                }
            }
        },
        GET_CONTRACT_TYPE_SELECTION:{
            handler(val){
                this.dropdowns.contract_types = val;
            }
        }
    }
}
</script>
<style scoped>
    .selected {
        opacity: 100%;
    }

    .non-selected {
        opacity: 40%;
    }

    .custom-loader {
        animation: loader 1s infinite;
        display: flex;
    }
    @-moz-keyframes loader {
        from {
        transform: rotate(0);
        }
        to {
        transform: rotate(360deg);
        }
    }
    @-webkit-keyframes loader {
        from {
        transform: rotate(0);
        }
        to {
        transform: rotate(360deg);
        }
    }
    @-o-keyframes loader {
        from {
        transform: rotate(0);
        }
        to {
        transform: rotate(360deg);
        }
    }
    @keyframes loader {
        from {
        transform: rotate(0);
        }
        to {
        transform: rotate(360deg);
        }
    }


</style>
