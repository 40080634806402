<template >
    <v-form>
        <!-- Table -->
      <v-container fluid style="display:none;">
        <div>
            <table id="headers_of_lv">
                <thead>
                    <tr>
                        <!-- <td>No. </td> -->
                        <td>ITEM CLASSIFICATION</td>
                        <td>ITEM NAME</td>
                        <td>PARTICULARS</td>
                        <td>KEEPER/USER</td>
                        <td>POSITION</td>
                        <td>SIGNATURE</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in (GET_LOW_VALUE_ASSET_PRINT_DETAIL.admin_accountabilityitem.length <= 6 ? [...GET_LOW_VALUE_ASSET_PRINT_DETAIL.admin_accountabilityitem, ...new Array(6 - GET_LOW_VALUE_ASSET_PRINT_DETAIL.admin_accountabilityitem.length).fill({})] : GET_LOW_VALUE_ASSET_PRINT_DETAIL.admin_accountabilityitem)" :key="index">
                        <template v-if="index === GET_LOW_VALUE_ASSET_PRINT_DETAIL.admin_accountabilityitem.length">
                            <td colspan="6" class="text-center">**NOTHING FOLLOWS**</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </template>
                        <template>
                            <td v-if="item.code">{{item.code.split('-')[0]}}</td>
                            <td v-else></td>
                            <td v-if="item.name">{{item.name}}</td>
                            <td v-else></td>
                            <td v-if="item.code">{{item.code}}</td>
                            <td v-else></td>
                            <td v-if="item.keeper">{{item.keeper}}</td>
                            <td v-else></td>
                            <td v-if="item.position">{{item.position}}</td>
                            <td v-else></td>
                            <td>{{''}}</td>
                        </template>
                    </tr>
                    <tr ref="nothing_follows" v-if="GET_LOW_VALUE_ASSET_PRINT_DETAIL.admin_accountabilityitem.length > 6">
                        <td colspan="8">**NOTHING FOLLOWS**</td>
                    </tr>
                </tbody>
            </table>
            <table id="notes">
                <tbody>
                    <tr>
                        <td colspan="8">
                            By signing, I/we acknowledge the receipt of the following items listed above mentioned that endorsed,
                            entrust or transferred to my custody. Also, I agree to keep the same in good working condition and notify the Management should the property been damaged,
                            lost or stolen, likewise return the same in good working condition. If I am found negligent in my responsibility to safeguard the listed fixed asset's and deemed to be damaged or lost,
                            I authorize the Company to withhold/demand the value of the lost or damaged property from my salary to compensate the cost of the same.
                            I have read and understood the procedure which covers the issuance of the fixed asset's and hereby abide and comply with the same policy.
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
      </v-container>
    </v-form>

  </template>
  <script>
  import { mapGetters } from 'vuex';
  import GILL from '@/assets/fonts/Gil_normal';
  import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
  export default {
    mixins:[GILL,SharedFunctionsComponentVue],
    data() {
        return {
            base64Image: null,
            info: {
                logo : "",
                name : "",
                deptLoc : "",
                reference_as : "",
                prepared_by : "",
                checked_by : "",
                noted_by : "",
                asNo : "",
                prepared_by_data : "",
                checked_by_data : "",
                noted_by_data : "",
                prepared_by_position : "",
                checked_by_position : "",
                noted_by_position : "",
                signature_name : "",
                acknowledgement_text : "",
                sheetVersion : "",

            },
            lvaa_data: [],
            lastY: 0
        }
    },
    computed:{
        ...mapGetters(['GET_LOW_VALUE_ASSET_PRINT','GET_LOW_VALUE_ASSET_PRINT_DETAIL','PURCHASE_ORDER'])
    },
    mounted(){
    },
    methods: {
        async renderLVAA(){
            let doc = await new this.$jspdf({
                orientation: 'p',
                unit: 'mm',
                format: 'letter'
            });

             // Insertion of Image and Title
            let left = 10;
            let top = 3;
            const imgWidth = 40;
            const imgHeight = 10;

            let length = 0;
            length = this.GET_LOW_VALUE_ASSET_PRINT_DETAIL.admin_accountabilityitem.length;
               
            // let host = window.location.origin;
            // doc.addImage(`${host}/images/ingcoph-logo.png`, 'PNG', left, top, imgWidth, imgHeight);

            try {
                let aspectRatio = this.getAspectRatio(this.base64Image.naturalWidth, this.base64Image.naturalHeight);
                let image_width = 0;
                let image_height = 0;

                switch(aspectRatio){
                    case '1:1':
                        image_width = 25;
                        image_height = 11;
                    break;
                    case '4:3':
                        image_width = 60;
                        image_height = 40;
                    break;
                    case '3:2':
                        image_width = 50;
                        image_height = 40;
                    break;
                    case '16:9':
                        image_width = 80;
                        image_height = 40;
                    break;
                    default:
                        image_width = 80;
                        image_height = 10;
                }

                doc.addImage(this.printData.image_path, 'PNG', left, top, image_width, image_height);
            } catch (error){
                console.log(error)
                console.error('image is not found for print.');
            }

            // accountability logo right
            var text = "LOW VALUE ASSET ACCOUNTABILITY FORM";
            doc.setFillColor(192,192,192);
            doc.setDrawColor(0);
            doc.setLineWidth(0.5);
            doc.roundedRect(115, 5, 87, 8, 2, 2, 'FD');
            doc.setTextColor(0,0,0);
            doc.setFontSize(10).setFont('GILL','bold').text(117, 10, text);

            // types of acoountability box
            doc.setDrawColor(0, 0, 0);
            doc.setLineWidth(0.1);
            doc.rect(10, 15, 195, 39, 'S'); // entire box
            doc.setFontSize(9).setFont('GILL','normal').text(118.5, 19,'Type of Accountability');
            doc.rect(120, 22, 4, 4, this.GET_LOW_VALUE_ASSET_PRINT_DETAIL.transfer_asset ? 'F' : 'S');
            doc.setFontSize(9).setFont('GILL','normal').text(125.5, 25,`Transfer Accountability`);
            doc.rect(120, 28.5, 4, 4, this.GET_LOW_VALUE_ASSET_PRINT_DETAIL.new_asset ? 'F' : 'S');
            doc.setFontSize(9).setFont('GILL','normal').text(126,31,`New AS`);
            doc.rect(120, 34.5, 4, 4,'S');
            doc.setFontSize(9).setFont('GILL','normal').text(126,37.5,`Re-issue AS`);
            doc.setFontSize(7).setFont('GILL','normal').text(170,24, this.GET_LOW_VALUE_ASSET_PRINT_DETAIL.previous_num);
            doc.line( 170, 25, 205, 25);
            doc.line(170,31,205,31);
            doc.line(170,37.5,205,37.5);

            // line separation lines on Type of Accountability box
            doc.line(115.2,15,115.2,40);
            doc.line(10, 27, 115.2, 27);


            // for the label of name and department
            doc.setFontSize(9).setFont('GILL','normal').text(11,19,'Requesting Department:');
            doc.setFontSize(9).setFont('GILL','normal').text(45,24, this.GET_LOW_VALUE_ASSET_PRINT_DETAIL.department_name ? this.GET_LOW_VALUE_ASSET_PRINT_DETAIL.department_name : '');
            doc.setFontSize(9).setFont('GILL','normal').text(11,30,'Reference Document:');
            doc.setFontSize(9).setFont('GILL','normal').text(45,36, this.GET_LOW_VALUE_ASSET_PRINT_DETAIL.ref_doc ? this.GET_LOW_VALUE_ASSET_PRINT_DETAIL.ref_doc : '');

            doc.autoTable({
                html: '#headers_of_lv',
                theme: 'grid',
                startY: 40,
                margin: {left: 10, right: 10.9},
                headStyles: {
                    fillColor: '#bfbfbf',
                    lineWidth: .2,
                    lineColor: '#000000',
                    fontStyle: 'bold',
                    fontSize: 9,
                    overflow: 'linebreak',
                    halign: 'center',
                    valign: 'middle'
                },
                columnStyles: {
                    0: {halign: 'center',cellWidth:25},
                    1: {halign: 'center',},
                    2: {halign: 'center', overflow: 'ellipsize'},
                    3: {halign: 'center', },
                    4: {halign: 'center', },
                    5: {halign: 'center', overflow: 'ellipsize'},
                    6: {halign: 'center', },
                },
                bodyStyles: {
                    lineColor: [0, 0, 0],
                    fontStyle: 'normal',
                    fontSize: 8
                },
                styles: {
                    font: "GILL",
                    halign: 'center',
                    textColor: '#000000',
                    halign: 'center',
                },
                didParseCell: function (data) {
                if(data.row.index === length){
                    data.cell.styles.textColor = [255, 0, 0];
                }
            }
            });

            this.lastY = doc.lastAutoTable.finalY

            //Assignatories manual
            doc.setFont("GILL", 'normal').setFontSize(9).text(13,this.lastY + 3, "Prepred By")

            const preparedByText = this.GET_LOW_VALUE_ASSET_PRINT_DETAIL.prepared_by;
            let fontSize;

            // Set font size based on the length of the text
            if (preparedByText.length > 22) {
                    fontSize = 8;
            } else if (preparedByText.length > 20) {
                    fontSize = 9;
            } else if (preparedByText.length > 18) {
                    fontSize = 10;
            } else {
                    fontSize = 11;
            }
            doc.setFont("GILL", 'normal').setFontSize(fontSize).text(16,this.lastY + 10, preparedByText)
            doc.setFont("GILL", 'normal').setFontSize(9).text(20,this.lastY + 17, "Admin Assistant")
            doc.setFont("GILL", 'normal').setFontSize(9).text(52,this.lastY + 3, "Approved By")
            doc.setFont("GILL", 'normal').setFontSize(9).text(64,this.lastY + 17, "Admin Officer")
            doc.setFont("GILL", 'normal').setFontSize(9).text(102,this.lastY + 3, "Noted By")
            doc.setFont("GILL", 'normal').setFontSize(9).text(116,this.lastY + 17, "Admin Manager")
            doc.setFont("GILL", 'normal').setFontSize(11).text(116,this.lastY + 10, "N. CARPIO")
            // doc.setFont("GILL", 'normal').setFontSize(9).text(152,this.lastY + 4, "LVAS No.")
            doc.setTextColor(255, 0, 0); // set Fornt color to red
            doc.setFont("GILL", 'bold').setFontSize(13).text(160,this.lastY + 9.7, "PO No - " + this.GET_LOW_VALUE_ASSET_PRINT_DETAIL.po_num ?? '')
            doc.setFont("GILL", 'bold').setFontSize(13).text(160,this.lastY + 13.7, "LVAS No - " + this.GET_LOW_VALUE_ASSET_PRINT_DETAIL.accountability_num ?? '')
            doc.setTextColor(0, 0, 0); // reset Font color to black
            doc.setDrawColor(0, 0, 0);

            //box for assignatories
            // doc.line(10, this.lastY + 45, 205, this.lastY);
            doc.line(10, this.lastY + 18, 205, this.lastY + 18);

            //separation lines
            doc.line(50 , this.lastY, 50, this.lastY + 18);
            doc.line(100 , this.lastY, 100, this.lastY + 18);
            doc.line(150 , this.lastY, 150, this.lastY + 18);

            //two line of remarks assignatories
            doc.line(10, this.lastY, 10, this.lastY + 25);
            doc.line(205, this.lastY, 205, this.lastY + 25);

            doc.autoTable({
                html: '#notes',
                theme: 'grid',
                startY: this.lastY + 25,
                margin: {left: 10, right: 10.9},
                bodyStyles: {
                    lineColor: [0, 0, 0],
                    fontStyle: 'normal',
                },
                styles: {
                    font: "GILL",
                    halign: 'left',
                    textColor: '#000000',
                    fontSize:"8",
                },
            });

            //version
            doc.setFont("GILL", 'normal').setFontSize(7).text(11, this.lastY + 47, "ADM-019-v01-2023")
            doc.setFont("GILL", 'normal').setFontSize(7).text(174.8, this.lastY + 47, "	1 – Requestor  2 – Admin")
            doc.autoPrint();
            // doc.save('Accountability Sheet.pdf');
            window.open(doc.output('bloburl'), '_blank');
        },
        getAddressFromLocation(location){
            let trim = location.split('-')
            let address = trim[1].trim();
            return address
        }
    },
    watch:{
        GET_LOW_VALUE_ASSET_PRINT:{
            handler(val){
                if(val){
                    this.printData = this.GET_LOW_VALUE_ASSET_PRINT_DETAIL
                    this.base64Image = this.PURCHASE_ORDER.COMPANY_IMAGE
                    this.renderLVAA();
                    this.$store.commit('LOW_VALUE_ASSET_PRINT',false);
               }
            },
            deep:true
        }
    }
  }
  </script>
  <style>
  </style>