<template>
    <v-container class="mt-4">
        <v-row>
            <v-col cols="6" v-for="(item,i) in items" :key="i">
                <v-card
                    class="mx-2 rounded-xl"
                >
                    <v-img
                        class="white--text align-end"
                        height="150px"
                        :src=" i == 'lazada' ? logo_helper_images[0] : logo_helper_images[3] "
                    >
                    </v-img>
                    <v-card-text class="text--primary">
                        <v-data-table 
                            :headers="headers"
                            :items="item">
                            <template v-slot:[`item.src`]="{ item }">
                                <v-img  class="my-1" :src="item.src" max-width="75px"></v-img>
                            </template>
                            <template v-slot:[`item.action`]="{ item }">
                                <span class="text-nowrap">
                                    <!-- <v-btn x-small fab icon text><v-icon color="orange">mdi-eye</v-icon></v-btn> -->
                                    <!-- <v-btn x-small fab icon text><v-icon color="primary">mdi-pencil</v-icon></v-btn> -->
                                    <v-btn x-small fab icon text @click="deleteShop(item.shop_name,i)"><v-icon color="red">mdi-delete</v-icon></v-btn>
                                </span>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>           
            </v-col>    
        </v-row>
    </v-container>
</template>

<script>
import swal from 'sweetalert2'
import { mapGetters } from 'vuex'
export default {
    data(){
        return{
            headers:[
                { text: 'Image', align: 'left', value: 'src', },
                { text: 'Name', align: 'left', value: 'title', },
                { text: 'Shop Name', align: 'left', value: 'shop_name', },
                { text: 'Action', align: 'center', value: 'action', },
            ],
            items:[],
            logo_helper_images:[]
        }
    },
    mounted(){
        this.getAllShopList()
        this.getImagesHelper()
    },
    methods:{
        getAllShopList(){
            let payload = {
                url: 'getAllShopList'
            }
            this.$store.dispatch('getWebScraping',payload).then(response=>{
                this.items = response.data
            })
        },
        deleteShop(shop_name,shop){
            if(shop == 'lazada'){
                shop = 'lazada_config'
            }
            else{
                shop = 'shopee_config'
            }

            swal.fire({
                    title: "",
                    text: "Are you sure you want to Delete?",
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        let payload = {
                            url: 'deleteShop',
                            shop_name:shop_name,
                            shop:shop,
                        }
                        this.$store.dispatch('postWebScraping',payload).then(response=>{
                            swal.fire(response.data.message,'',response.data.icon)
                            this.getAllShopList()
                        })
                    }  
                })
           
        },
        getImagesHelper(){
            let payload = {
            url:'getHelpersImage'
        }
        this.$store.dispatch('getWebScraping',payload).then(e=>{
            this.$store.commit('ONLINE_MONITORING_IMAGES', e.data);
        })  
        }
    },
    computed: {
        ...mapGetters([
            'GET_ONLINE_MONITORING_IMAGES'
        ])
    },
    watch: {
        GET_ONLINE_MONITORING_IMAGES: {
            handler(val){
                this.logo_helper_images = val
            }
        }
    }
}
</script>

<style>

</style>