<template>
    <v-container fluid class="tabs-body-color">
        <v-row class="pa-2">
            <v-col v-if="userAccess.includes('import')" class="btn-toolbar">
                <v-btn class="" @click="uploadFileDialog = true">
                    <v-icon class="btn-action" color="#F69F1A" dense>
                        mdi-cloud-upload
                    </v-icon> 
                    <span class="ml-1">import</span>
                </v-btn>
            </v-col>
            <v-col v-if="userAccess.includes('export')" class="btn-toolbar d-flex justify-end">
                <v-btn @click="openFilterExportDialog">
                    <v-icon class="btn-action" color="success" dense>
                        mdi-cloud-download
                    </v-icon> 
                    <span class="ml-1">export</span>
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <StoreFlowTableComponent :headers="table.headers"/>
        </v-row>
        <v-dialog v-model="uploadFileDialog" persistent width="35%">
            <v-card class="pb-4">
                <v-card-title class="d-flex justify-content-between">
                    <span class="text-lg font-semibold">Upload File</span>
                    <v-btn text icon color="gray" class="float-right" @click="closeUploadFileDialog">
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider class="ma-0" />
                <v-card-text class="mx-auto col-lg-10">
                    <v-row class="d-flex flex-column flex-lg-row justify-space-between align-baseline mt-4 px-xl-2 pb-1">
                        <v-col class="d-flex align-center">
                            <v-checkbox
                                label="Delete Existing Data?"
                                v-bind:false-value="0"
                                v-bind:true-value="1"
                                v-model="deleteData"
                                hide-details
                                color="red"
                                @change="confirmIfDelete()"
                            ></v-checkbox>
                        </v-col>
                        <v-col class="d-flex justify-lg-end align-center ml-1">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                    style="cursor:pointer"
                                    color="#F69F1A"
                                    v-bind="attrs"
                                    v-on="on"
                                    dark
                                    @click="viewFileDialog = true"
                                    >
                                    mdi-file-question
                                    </v-icon>
                                </template>
                                <span>View</span>
                            </v-tooltip>
                            <label class="ml-1">Follow File Format</label>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="d-flex flex-column col-lg-10 mx-auto">
                    <v-col class="mx-auto" cols="12">
                        <v-file-input 
                            label="Attach Excel File" 
                            v-model="fileInput" 
                            accept=".xls, .xlsx" 
                            outlined
                            dense 
                            @change="handleExlFile"
                        >
                            <template v-slot:selection="{ text }">
                                <v-chip
                                    color="#f69f1a"
                                    small
                                    label
                                >
                                    {{ text }}
                                </v-chip>
                            </template>
                        </v-file-input>
                    </v-col>
                    <v-dialog v-model="loading.import" max-width="500" height="35" persistent>
                        <v-card class="pa-0">
                            <v-card-title class="py-0">
                                <p class="font-weight-medium"  style="font-size:12px">Collecting Data...</p>
                            </v-card-title>
                            <v-card-text>
                                <v-progress-linear
                                    indeterminate
                                    :size="15"
                                    rounded
                                >
                                </v-progress-linear>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="fileDetailsDialog" persistent>
            <v-card class="mx-auto"  flat>
                <v-card-title class="d-flex justify-space-between">
                    <span class="text-lg font-semibold">File Details</span>
                    <v-btn text icon color="gray" class="float-right" @click="fileDetailsDialog = false; closeUploadFileDialog()">
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider class="mt-0"/>
                <v-card-text>
                    <v-data-table
                        :items="table.items"
                        :footer-props="{
                            itemsPerPageOptions:[5, 10, 15, 50, 100]
                        }"
                        :headers="table.headers"
                        :items-per-page="15"
                        class="mt-2"
                        disable-sort
                        dense
                    >
                        <template v-slot:[`header.movement_date`]="{ header }">
                            <v-tooltip top color="#F69F1A">
                                <template v-slot:activator="{ on, attrs }">
                                    <span
                                        v-bind="attrs"
                                        v-on="on"
                                        style="cursor:pointer"
                                    >
                                        MOV. DATE
                                    </span>
                                </template>
                                <span>MOVEMENT DATE</span>
                            </v-tooltip>
                        </template>
                        <template v-slot:[`header.inbound_outbound_number`]="{ header }">
                            <v-tooltip top color="#F69F1A">
                                <template v-slot:activator="{ on, attrs }">
                                    <span
                                        v-bind="attrs"
                                        v-on="on"
                                        style="cursor:pointer"
                                    >
                                        I/O BOUND NO.
                                    </span>
                                </template>
                                <span>INBOUND/OUTBOUND NO.</span>
                            </v-tooltip>
                        </template>
                        <template v-slot:[`header.business_type`]="{ header }">
                            <v-tooltip top color="#F69F1A">
                                <template v-slot:activator="{ on, attrs }">
                                    <span
                                        v-bind="attrs"
                                        v-on="on"
                                        style="cursor:pointer"
                                    >
                                        B. TYPE
                                    </span>
                                </template>
                                <span>BUSINESS TYPE</span>
                            </v-tooltip>
                        </template>
                        <template v-slot:[`header.qty_before_movement`]="{ header }">
                            <v-tooltip top color="#F69F1A">
                                <template v-slot:activator="{ on, attrs }">
                                    <span
                                        v-bind="attrs"
                                        v-on="on"
                                        style="cursor:pointer"
                                    >
                                    QBM 
                                    </span>
                                </template>
                                <span>QTY BEFORE MOVEMENT</span>
                            </v-tooltip>
                        </template>
                        <template v-slot:[`header.qty_of_movement`]="{ header }">
                            <v-tooltip top color="#F69F1A">
                                <template v-slot:activator="{ on, attrs }">
                                    <span
                                        v-bind="attrs"
                                        v-on="on"
                                        style="cursor:pointer"
                                    >
                                    QOM 
                                    </span>
                                </template>
                                <span>QTY OF MOVEMENT</span>
                            </v-tooltip>
                        </template>
                        <template v-slot:[`header.qty_after_movement`]="{ header }">
                            <v-tooltip top color="#F69F1A">
                                <template v-slot:activator="{ on, attrs }">
                                    <span
                                        v-bind="attrs"
                                        v-on="on"
                                        style="cursor:pointer"
                                    >
                                    QAM 
                                    </span>
                                </template>
                                <span>QTY AFTER MOVEMENT</span>
                            </v-tooltip>
                        </template>
                        <template v-slot:[`header.client_name`]="{ header }">
                            <v-tooltip top color="#F69F1A">
                                <template v-slot:activator="{ on, attrs }">
                                    <span
                                        v-bind="attrs"
                                        v-on="on"
                                        style="cursor:pointer"
                                    >
                                    C. NAME 
                                    </span>
                                </template>
                                <span>CLIENT NAME</span>
                            </v-tooltip>
                        </template>
                        <!-- <template v-slot:[`item.movement_date`] = "{ item }">
                            {{ item.movement_date | formatDateYMD }}
                        </template> -->
                    </v-data-table>
                    <v-dialog  v-model="progressDialog" max-width="500" height="100" persistent>
                        <v-card flat>
                            <v-card-title>
                                <p class="font-weight-medium"  style="font-size:12px">Saving Data...</p>
                            </v-card-title>
                            <v-card-text class="w-100">
                                <v-row class="w-100 mx-auto">
                                    <v-col
                                        cols="12"
                                    >
                                        <v-progress-linear
                                            class="mx-auto"
                                            v-model="chunksPercentage"
                                            height="15"
                                        >
                                            <strong>{{ chunksPercentage }}%</strong>
                                        </v-progress-linear>
                                        <p class="font-weight-light align-right"  style="font-size:10px">{{ itemSave  }} / {{ table.items.length }} are saved</p>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                </v-card-text>
                <v-card-actions class="d-flex justify-center align-center ma-0">
                    <v-btn class="btn-toolbar px-4" :loading="GET_LOADING" @click="saveFileDetails">
                        <v-icon class="btn-action" color="success" dense>
                            mdi-content-save
                        </v-icon> 
                        <span class="ml-1">Save</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <store-flow-dialog-component
            :title="'File Format'"
            :dialogState="viewFileDialog" 
            :width="'60%'"
            @close-dialog="closeViewFileDialog"
        >
            <ViewFileDialog />
        </store-flow-dialog-component>
        <store-flow-dialog-component 
            :title="'Filter Details To Export'"
            :dialogState="GET_FILTER_DIALOG" 
            :width="'35%'"
            @close-dialog="closeFilterDialog"
        >
            <StoreFlowFilterDialog/>
        </store-flow-dialog-component>
    </v-container>
</template>

<script>
import StoreFlowTableComponent from '@/views/main/modules/Stores_/StoreFlow/StoreFlowTableComponent.vue';
import StoreFlowDialogComponent from '@/views/main/modules/Stores_/StoreFlow/StoreFlowDialogComponent.vue';
import StoreFlowFilterDialog from '@/views/main/modules/Stores_/StoreFlow/StoreFlowDialogs/StoreFlowFilterDialog.vue';
import ViewFileDialog from '@/views/main/modules/Stores_/StoreFlow/StoreFlowDialogs/ViewFileDialog.vue';
import * as XLSX from "xlsx";
import swal from "sweetalert2";
import { mapGetters } from 'vuex';
export default {
    components: {
        StoreFlowTableComponent, 
        StoreFlowDialogComponent,
        StoreFlowFilterDialog,
        ViewFileDialog
    },
    props: {
        tabName: {
            type:String,
            default: () => ''
        },
    },
    data() {
        return {
            uploadFileDialog: false,
            fileDetailsDialog: false,
            progressDialog: false,
            fileInput: null,
            table: {
                items:[],
                headers: [
                    { text: 'MOVEMENT DATE', align: 'start', value: 'movement_date', width:'7%'},
                    { text: 'STORE NAME', align: 'start', value: 'store_name' },
                    { text: 'PRODUCT NO.', align: 'start', value: 'product_number' },
                    { text: 'PRODUCT NAME', align: 'start', value: 'product_name'},
                    { text: 'BRAND', align: 'start', value: 'brand' },
                    { text: 'UNIT', align: 'start', value: 'unit' },
                    { text: 'INBOUND/OUTBOUND NO.', align: 'start', value: 'inbound_outbound_number', width: '10%' },
                    { text: 'BUSINESS NO.', align: 'start', value: 'business_number'},
                    { text: 'BUSINESS TYPE', align: 'start', value: 'business_type', width:'8%'},
                    { text: 'UOM', align: 'start', value: 'uom' },
                    { text: 'QTY BEFORE MOVEMENT', align: 'start', value: 'qty_before_movement' },
                    { text: 'QTY OF MOVEMENT', align: 'start', value: 'qty_of_movement' },
                    { text: 'QTY AFTER MOVEMENT', align: 'start', value: 'qty_after_movement' },
                    { text: 'CLIENT NAME', align: 'start', value: 'client_name', width:'8%' },
                    { text: 'REMARKS', align: 'start', value: 'remarks' },
                ],
            },
            userAccess:[],
            deleteData: 0,
            loading: {
                import: false
            },
            chunksPercentage: 0,
            itemSave:0,
            viewFileDialog: false,
        }
    },
    computed: {
        ...mapGetters([
            'GET_STORE_FLOWS',
            'GET_LOADING',
            'USER_ACCESS',
            'GET_FILTER_DIALOG'
        ])
    },
    methods: {
        handleExlFile(event) {
            if (event) {
                this.loading.import = true;
                const fileReader = new FileReader();
                fileReader.readAsArrayBuffer(event)
                fileReader.onload = (e) => {
                    let data = e.target.result;
                    let workbook = XLSX.read(data, { type:'array', cellDates:true});
                    let workSheetName = workbook.SheetNames[0];
                    let worksheet = workbook.Sheets[workSheetName];
                    const range = XLSX.utils.sheet_to_json(worksheet, {defval:"", raw:false}); 
                    let headers = Object.keys(range[0])
                    const validated = this.validateFileFormat(headers)
                    if (validated) {
                        for (let r = 0; r < range.length; r++) {
                            let record  = {};
                            headers.forEach( e => {
                                let header = e.replaceAll(/[-/\s]/g, '_').toLowerCase();
                                record[header] = range[r][e]
                            })
                            this.table.items.push(record);
                        }
                        this.loading.import = false;
                        this.fileDetailsDialog = true;
                    } else {
                        swal.fire("Info.", "-Follow the required file header format.", 'info');
                        this.fileInput = null;
                        this.loading.import = false;
                    }
                }
            }
        },
        closeUploadFileDialog() {
            this.uploadFileDialog = false;
            this.table.items = [],
            this.fileInput = null;
            this.deleteData = 0;
        },
        async saveFileDetails() {
            this.$store.commit('LOADING', true)
            let chunkSize = 2000;
            let chunks = this.chunkArray(this.table.items, chunkSize)
            
            if (this.deleteData == 1){
                await this.$store.dispatch('storeFlowPost', {isDeleteData: this.deleteData, url:'store-flows-destroy'}).then( async res => {
                    if (res.data.message == 'success') {
                        await this.saveData(chunks);
                        this.deleteData = 0;
                    } else {
                        swal.fire('' , '- Error in deleting data', 'error')
                        this.deleteData = 0;
                    }
                })
            } else {
                await this.saveData(chunks);
            }
        },
        async saveData(chunks) {
            let count = 0;
            this.progressDialog = true;
            const chunksLength = chunks.length;
            for (const chunk of chunks) {
                this.chunksPercentage = Math.round((count / chunksLength) * 100);
                let payload = {
                    details: chunk,
                    url:'save-store-flows'
                }
                await this.$store.dispatch('storeFlowPost', payload).then(response => {
                    if (response.data.message == 'success') {
                        this.itemSave += chunk.length
                        count ++;
                        if (this.itemSave == this.table.items.length) {
                            this.chunksPercentage = 100;
                            this.progressDialog = false;
                            swal.fire('Sucess','-File Details successfully saved!','success');
                            this.$store.commit('RELOAD', true);
                            this.$store.commit('LOADING', false)
                            this.fileDetailsDialog = false;
                            this.itemSave = 0;
                            this.closeUploadFileDialog();
                        }
                    } else {
                        swal.fire('Error','- Error in saving file details', 'error');
                        this.progressDialog = false;
                        console.log(response.data.message);
                        this.$store.commit('LOADING', false);
                        this.fileDetailsDialog = false;
                        this.table.items = [];
                        this.fileInput = null;
                        this.itemSave = 0;
                    }
                });
            }
        },
        validateFileFormat(fileHeaders) {
            let headers = ['MOVEMENT DATE', 'STORE NAME', 'PRODUCT NUMBER', 'PRODUCT NAME', 'BRAND', 'UNIT', 'INBOUND/OUTBOUND NUMBER', 'BUSINESS NUMBER', 'BUSINESS TYPE','UOM', 'QTY BEFORE MOVEMENT', 'QTY OF MOVEMENT', 'QTY AFTER MOVEMENT','CLIENT NAME', 'REMARKS'];
            if (headers.length !== fileHeaders.length) {
                return false;
            }

            const sortedHeaders = headers.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
            const sortedfileHeaders = fileHeaders.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))

            for (let header = 0; header < sortedHeaders.length; header++) {
                if (sortedHeaders[header] !== sortedfileHeaders[header]) {
                    return false;
                }
            }    

            return true;
        },
        openFilterExportDialog() {
            this.$store.commit('FILTER_DIALOG', true)
        },
        closeFilterDialog(data) {
            this.$store.commit('FILTER_DIALOG', data);
        },
        confirmIfDelete() {
            if (this.deleteData == 1) {
                swal.fire('', '- Existing Data will be removed permanently.', 'info');
            }
        },
        chunkArray(array, chunkSize) {
            const chunks = [];
            for (let i = 0; i < array.length; i += chunkSize) {
                chunks.push(array.slice(i, i + chunkSize));
            }
            return chunks;
        },
        closeViewFileDialog() {
            this.viewFileDialog = false;
        }
    },
    watch: {
        'USER_ACCESS': {
            handler(val) {
                if (val != 'fail'){
                    this.userAccess = val.map( e => e.actions_code)
                }
            }
        },
    },
    mounted() {
        this.$store.dispatch('checkAccessUser',this.$router.currentRoute.params.id)
    }

}
</script>
