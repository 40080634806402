<template>
    <v-container fluid>
        <ComponentHeader :button="true" title='Customer Pending Orders By SO' type='Sales'></ComponentHeader>
        <v-dialog v-model="loading.dialog" width="500">
            <v-card>
                <v-card-title>
                    <span style="font-size:12px">
                        Processing: {{ loading.text }}
                    </span>
                </v-card-title>
                <v-card-text>
                    <v-progress-linear v-model="loading.value" :buffer-value="loading.bufferValue" color="green"></v-progress-linear>{{ Math.ceil(loading.value) }}%
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-container fluid class="bg-bluish-gray">
            <v-text-field v-if="!loaded" color="info" loading disabled></v-text-field>
            <v-row class="pa-2">
                <v-col sm="12" md="6">
                    <v-autocomplete
                        v-model="selectedCustomers"
                        :items="customers_list"
                        :loading="loading.customer_selection"
                        :disabled="loading.customer_selection"
                        label="Select Customers"
                        background-color="white"
                        chips
                        deletable-chips
                        outlined
                        dense
                        multiple
                    ></v-autocomplete>
                </v-col>
                <v-col sm="12" md="3">
                    <v-autocomplete
                        v-model="salesman_id"
                        :items="salesman_list"
                        :loading="loading.salesman_selection"
                        :disabled="loading.salesman_selection"
                        label="Select Salesman"
                        background-color="white"
                        outlined
                        dense
                    ></v-autocomplete>
                </v-col>
                <v-col sm="12" md="3" class="text-right">
                    <v-btn small class="mr-2" @click="resetFields()" :loading="!loaded">Clear</v-btn>
                    <v-btn small class="mr-2" :loading="!loaded" @click="exportCustomerPendingOrdersBySOToExcel()">Excel</v-btn>
                    <v-btn small :loading="!loaded" @click="getCustomersWithPendingOrdersBySO()">Filter</v-btn>
                </v-col>
            </v-row>
            <v-row class="pa-2">
                <v-col sm="12" md="6">
                    <v-autocomplete
                        v-model="selectedWarehouses"
                        :items="warehouse_list"
                        label="Select Warehouses"
                        background-color="white"
                        outlined
                        dense
                        return-object
                        multiple
                        chips
                        deletable-chips
                    ></v-autocomplete>
                </v-col>
                <v-col sm="12" md="3">
                    <v-autocomplete
                        v-model="classification_id"
                        :items="classification_list"
                        label="Select Classification"
                        background-color="white"
                        outlined
                        dense
                    ></v-autocomplete>
                </v-col>

            </v-row>
            <v-row class="pa-2">
                <!-- <v-col sm="12" md="4">
                    <v-col class="col-sm-12 col-md-4">
                        <v-checkbox
                            v-model="withStocks"
                            label="With Stocks"
                            color="white"
                            input-value="1"
                            hide-details
                        ></v-checkbox>
                    </v-col>
                    <v-col sm="12" md="4">
                        <v-checkbox
                            v-model="withoutStocks"
                            label="Without Stocks"
                            color="white"
                            input-value="1"
                            hide-details
                        ></v-checkbox>
                    </v-col>
                </v-col> -->
                <v-col sm="6" md="2" id="date_from">
                    <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="date_from"
                                v-on="on"
                                append-icon="mdi-calendar-clock"
                                label="Date From"
                                readonly
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="date_from" @input="menu2 = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col sm="6" md="2" id="date_to">
                    <v-menu
                        v-model="menu3"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="date_to"
                                v-on="on"
                                append-icon="mdi-calendar-clock"
                                label="Date To"
                                readonly
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="date_to" @input="menu3 = false"></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>

            <v-container class="w-100" v-if="customersList.length > 0">
                <v-card v-show="loaded">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" sm="6" md="3">
                                <div class="info-box mb-3">
                                    <span class="info-box-icon bg-warning elevation-1"><i class="fas fa-users"></i></span>
                                    <div class="info-box-content">
                                        <span class="info-box-text">Customers</span>
                                        <span class="info-box-number">{{ customer_count }}</span>
                                    </div>
                                </div>
                            </v-col>
                            <div class="clearfix hidden-md-up"></div>
                        </v-row>

                        <v-container v-for="(customer, i) in customersList" :key="i" v-show="loaded">
                            <v-row>
                                <v-col cols="12" class="d-flex no-block align-items-center">
                                    <h6 class="report-title">
                                        {{ customer.company_name }} ({{ customer.classification }}) - PHP{{ customer.customer_total_amount | currency }} (PENDING)
                                    </h6>
                                </v-col>
                            </v-row>

                            <v-app id="my_vue_app">
                                <v-card>
                                    <v-container fluid>
                                        <v-data-table
                                            :headers="customerSOheaders"
                                            :items="customer.sales_orders"
                                            :search="search"
                                            :items-per-page="10"
                                            :expanded.sync="expanded[i]"
                                            class="w-100"
                                            show-expand
                                            dense
                                            @item-expanded="loadDetails"
                                        >
                                            <template v-slot:[`item.created_at`]="{ item }">
                                                <span class="text-nowrap">
                                                    {{ item.created_at | formatDate }}
                                                </span>
                                            </template>

                                            <template v-slot:[`item.total_amount`]="{ item }">
                                                <span class="text-nowrap">
                                                    {{ item.total_amount | currency }}
                                                </span>
                                            </template>

                                            <template v-slot:[`item.pending_total_amount`]="{ item }">
                                                <span class="text-nowrap">
                                                    {{ item.pending_total_amount | currency }}
                                                </span>
                                            </template>

                                            <template v-slot:expanded-item="{ headers, item }">
                                                <td :colspan="headers.length" class="p-0">
                                                    <v-simple-table dense dark>
                                                        <template v-slot:default>
                                                            <span>
                                                                <thead>
                                                                    <td>Model</td>
                                                                    <td>Name</td>
                                                                    <td>
                                                                        <center>Order</center>
                                                                    </td>
                                                                    <td>
                                                                        <center>Pending</center>
                                                                    </td>
                                                                    <td class="text-right">Amount</td>
                                                                    <td class="text-right">Total</td>
                                                                    <td v-for="(wh, w) in filtered_warehouses" :key="w">
                                                                        <center><strong>{{ wh.code }}</strong></center>
                                                                    </td> <!-- add foreach-->
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="(order, m) in item.salesOrderItems" :key="m">
                                                                        <td>{{ order.item.model }}</td>
                                                                        <td>{{ order.item.name }}</td>
                                                                        <td>
                                                                            <center>{{ order.quantity }}</center>
                                                                        </td>
                                                                        <td>
                                                                            <center>{{ order.quantity -
                                                                                order.quantity_served -
                                                                                order.quantity_canceled }}</center>
                                                                        </td>
                                                                        <td class="text-right">{{
                                                                            getTwoDecimals(order.amount) }}</td>
                                                                        <td class="text-right">{{
                                                                            getTwoDecimals((order.quantity -
                                                                                order.quantity_served - order.quantity_canceled)
                                                                                * order.amount) }}</td>
                                                                        <td v-for="(wh, w) in filtered_warehouses" :key="w">
                                                                            <center>{{ order[wh.code] }}</center>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </span>
                                                        </template>
                                                    </v-simple-table>
                                                </td>
                                            </template>
                                        </v-data-table>
                                    </v-container>
                                </v-card>
                            </v-app>
                        </v-container>
                    </v-container>
                </v-card>
            </v-container>
        </v-container>
    </v-container>
</template>

<script>
import swal from 'sweetalert2'
import { mapGetters } from "vuex";
import SharedFunctionsComponent from "@/views/main/Shared/SharedFunctionsComponent";
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'

export default {
    mixins: [SharedFunctionsComponent],
    data() {
        return {
            expanded: [],
            errors: [],
            customers_list: [],
            salesman_id: '',
            salesman_list: [],
            warehouse_list: [],
            classification_id: '',
            classification_list: [],
            loaded: true,

            date_from: this.$dayjs().startOf('month').format('YYYY-MM-DD'),
            date_to: this.$dayjs().endOf('month').format('YYYY-MM-DD'),

            customer_count: '',
            customersList: [],

            row: null,
            menu2: false,
            menu3: false,

            totalSalesReport: 0,
            options: {},
            salesList: [],
            warehouseList: [],
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            itemsPerPage: 10,
            search: '',
            awaitingSearch: false,
            customerSOheaders: [
                { text: 'SO#', align: 'left', width: '5%', value: 'order_num', },
                { text: 'SO Date', align: 'left', width: '7%', value: 'created_at', },
                { text: 'Remarks', align: 'left', value: 'remarks', },
                { text: 'SO Total', align: 'right', width: '10%', value: 'total_amount', },
                { text: 'Pending Total', align: 'right', width: '10%', value: 'pending_total_amount', },
            ],
            loading: {
                text: '',
                bufferValue: 100,
                value: 0,
                dialog: false,

                customer_selection: true,
                salesman_selection: true
            },

            withStocks: true,
            withoutStocks: true,
            selectedWarehouses: [],
            selectedCustomers: [],
        }
    },
    components: {
        ComponentHeader
    },
    mounted() {
        this.getCustomers()
        this.getSalesmanList()
        this.getActualWarehouseCodes()
        this.getWarehouses()
        this.getCustomerClassification()
        // this.$store.dispatch("getAllcustomerClassificationSelection");
        // this.$store.dispatch("getWarehouse");
        // this.$store.dispatch("getAllsalesmanSelection");
    },
    methods: {
        onSelectWarehouses(items) {
            this.selectedWarehouses = items
        },
        onSelectCustomers(items) {
            this.selectedCustomers = items
        },
        loadDetails(item) {
            let data = {
                customer_id: item.item.customer_id,
                id: item.item.id,
                withStocks: this.withStocks,
                withoutStocks: this.withoutStocks,
                selectedWarehouses: this.selectedWarehouses,
                url: 'getCustomerSalesOrderItemDetails'
            }

            if (item.value) {
                this.$store.dispatch('reports', data).then(response => {
                    var data = response.data
                    item.item.salesOrderItems = data.salesOrderItems
                    this.$forceUpdate()
                })
            }
        },
        getActualWarehouseCodes() {
            this.$store.dispatch('getActualWarehouseCodes').then(response => {
                this.warehouseList = response
            });
        },
        async getCustomers() {
            await this.$store.dispatch("getAllcustomersSelection").then(response => {
                this.customers_list = this.GET_CUSTOMERS_SELECTION
                this.customers_list.push({
                    value: 'all',
                    text: 'Select All'
                })
                this.loading.customer_selection = false
            });

        },
        getCustomerClassification() {
            this.$store.dispatch('getCustomerClassificationsList').then(response => {
                this.classification_list = response.data
            });
        },
        getWarehouses() {
            this.$store.dispatch('getAllWarehouses').then(response => {
                this.warehouse_list = response.data
            });
        },
        async getSalesmanList() {
            await this.$store.dispatch("getSalesmanList").then(response => {
                this.salesman_list = response
                this.loading.salesman_selection = false
            })
        },
        getCustomersWithPendingOrdersBySO() {
            this.loaded = false
            this.expanded = [];

            if (this.date_from == '' || this.date_to == '') {
                swal("", 'Date From and Date To cannot be empty', "warning");
                this.loaded = true
                return false
            }
            let data = {
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                salesman_id: this.salesman_id,
                classification_id: this.classification_id,
                date_from: this.date_from,
                date_to: this.date_to,
                withStocks: this.withStocks,
                withoutStocks: this.withoutStocks,
                selectedWarehouses: this.selectedWarehouses,
                selectedCustomers: this.selectedCustomers,
                url: 'getCustomersWithPendingOrdersBySO'
            }
            this.$store.dispatch('reports', data).then(response => {
                let data = response.data

                this.customersList = data.customersList
                this.customer_count = data.customersList.length
                this.loaded = true
            });
        },
        async exportCustomerPendingOrdersBySOToExcel() {
            let that = this
            let workbook = new this.$exceljs.Workbook();
            let beginningMonthAndYear = this.$dayjs(that.date_from)
            let endMonthAndYear = this.$dayjs(that.date_to)
            let loopThruMonths = true
            let worksheet_so = workbook.addWorksheet('Pending Sales Orders');
            that.loading.bufferValue = endMonthAndYear.diff(beginningMonthAndYear, 'month')
            let counter = 0;

            that.loaded = false;
            that.loading.dialog = true
            let warehouse_item_stocks = []

            let defaultHeaders = [
                { header: 'Customers', key: 'company_name' },
                { header: 'SO#', key: 'order_num' },
                { header: 'SO Date', key: 'created_at' },
                { header: 'Remarks', key: 'remarks' },
                { header: 'Model', key: 'item_model' },
                { header: 'Name', key: 'item_name' },
                { header: 'UOM', key: 'item_uom' },
                { header: 'Type', key: 'item_type' },
                { header: 'Category', key: 'item_category' },
                { header: 'Orders', key: 'quantity', style: { numFmt: '#,##0' } },
                { header: 'Pending', key: 'quantity_pending', style: { numFmt: '#,##0' } },
                { header: 'Disc Price', key: 'amount', style: { numFmt: '#,##0.00' } },
            ]
            let data = {
                types: [1, 2],
                active_warehouse: 1,
                warehouses: that.selectedWarehouses.map(e => {
                    return e.value
                }),
                url: '/items/warehouse-stocks/inline'
            }
            await this.$store.dispatch('getWarehouseItemsInline', data).then(response => {
                warehouse_item_stocks = response.data
                let warehouse_codes = _.uniqBy(response.data, 'warehouse_id');
                warehouse_codes = warehouse_codes.map(e => {
                    return { header: e.warehouse_code, key: e.warehouse_code, style: { numFmt: '#,##0' } }
                })
                defaultHeaders = defaultHeaders.concat(warehouse_codes)
                worksheet_so.columns = defaultHeaders
            }).catch(e => {
                console.log(e)
            })

            while (loopThruMonths) {
                let date_from = ''
                let date_to = ''
                that.loading.value = (counter / that.loading.bufferValue) * 100
                counter++

                if (beginningMonthAndYear.format('YYYY-MM-DD') == that.date_from) {
                    date_from = beginningMonthAndYear.format('YYYY-MM-DD')
                    date_to = beginningMonthAndYear.endOf('month').format('YYYY-MM-DD')
                }

                else if (beginningMonthAndYear.format('YYYY-MM') == endMonthAndYear.format('YYYY-MM')) {
                    date_from = endMonthAndYear.startOf('month').format('YYYY-MM-DD')
                    date_to = this.$dayjs(that.date_to).format('YYYY-MM-DD')
                }

                else {
                    date_from = beginningMonthAndYear.startOf('month').format('YYYY-MM-DD')
                    date_to = beginningMonthAndYear.endOf('month').format('YYYY-MM-DD')
                }

                that.loading.text = beginningMonthAndYear.startOf('month').format('YYYY-MM')
                let data = {
                    salesman_id: that.salesman_id,
                    date_from: date_from,
                    date_to: date_to,
                    withStocks: that.withStocks,
                    classifications: that.classification_id,
                    // selectedWarehouses: JSON.stringify(that.selectedWarehouses),
                    selectedCustomers: this.selectedCustomers,
                    url: 'exportCustomerPendingOrdersBySOToFastExcel'
                }
                await this.$store.dispatch('reports', data).then(response => {
                    response.data.data.forEach(e => {
                        e.quantity_pending = e.quantity - (e.quantity_served + e.quantity_canceled)
                        e.amount = parseFloat(e.amount)

                        warehouse_item_stocks.forEach(element => {
                            if (e.item_id == element.item_id) {
                                e[element.warehouse_code] = parseInt(element.item_quantity)
                            }
                        })

                        worksheet_so.addRow(e)
                    });
                })

                if (beginningMonthAndYear.format('YYYY-MM') == endMonthAndYear.format('YYYY-MM')) {
                    loopThruMonths = false
                }
                beginningMonthAndYear = beginningMonthAndYear.add(1, 'month')
            }

            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                this.$filesaver.saveAs(blob, `Customer-Pending-orders-by-SO${that.date_from}-${that.date_to}.xlsx`);
            });

            that.loaded = true;
            that.loading.dialog = false
            that.loading.value = 0
            swal.fire('Success!', '', 'success')
        },
        thousandSeprator(amount) {
            if (amount !== '' || amount !== undefined || amount !== 0 || amount !== '0' || amount !== null) {
                return amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            } else {
                return amount;
            }
        },
        resetFields() {
            this.errors = []
            this.salesman_id = ''
            this.classification_id = ''
            this.confirmed = true
            this.unconfirmed = false
            this.date_from = ''
            this.date_to = ''
            this.sales_total_amount = ''
            this.customer_count = ''
            this.confirmed_count = ''
            this.unconfirmed_count = ''
            this.withStocks = true
            this.withoutStocks = true
            this.expanded = []
            this.selectedWarehouses = []
            this.selectedCustomers = []
            this.date_from = this.$dayjs().startOf('month').format('YYYY-MM-DD')
            this.date_to = this.$dayjs().endOf('month').format('YYYY-MM-DD')
            this.customersList = []
        }
    },
    computed: {
        filtered_warehouses: function () {
            if (this.selectedWarehouses != null && this.selectedWarehouses.length > 0) {
                let wh_ids = this.selectedWarehouses.map(wh => wh.value);
                return this.warehouseList.filter(item => {
                    return wh_ids.includes(item.id);
                })
            } else {
                return this.warehouseList
            }
        },
        ...mapGetters([
            "GET_SALESMAN_SELECTION",
            "GET_CUSTOMERS_SELECTION",
            "GET_WAREHOUSE_LIST",
            "GET_CLASSIFICATIONS_SELECTION",
        ]),
    },
    watch: {
        // GET_SALESMAN_SELECTION: {
        //   handler(val) {
        //     this.salesman_list = val;
        //   },
        // },
        // GET_CUSTOMERS_SELECTION: {
        //   handler(val) {
        //     this.customers_list = val;
        //     this.customers_list.push({
        //               value: 'all',
        //               text: 'Select All'
        //     })
        //   },
        // },
        // GET_WAREHOUSE_LIST: {
        //   handler(val) {
        //     this.warehouse_list = val.map((e) => {
        //       return { text: e.name, value: e.id };
        //     });
        //   },
        // },
        // GET_CLASSIFICATIONS_SELECTION: {
        //   handler(val) {
        //     this.classification_list = val;
        //   },
        // },
    },
};
</script>
<style>
.col {
    padding: 2px !important;
}

.card-body {
    padding: 3px !important;
}
</style>
