<script>
export default {
    methods: {
        breakpointName() {
            let name = this.$vuetify.breakpoint.name;

            return name;
        }
    }
}
</script>
