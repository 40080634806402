var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100"},[_c('v-app',{attrs:{"id":"my_vue_app"}},[_c('v-card',[_c('v-card-title',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-content-start",attrs:{"cols":"12","sm":"8"}}),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"row col-md-12 align-items-end"},[_c('v-text-field',{attrs:{"id":"searchBar","label":"Search by CEF","single-line":"","hide-details":""},on:{"change":function($event){return _vm.searchInput()}},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}}),_c('v-icon',{on:{"click":function($event){return _vm.searchInput()}}},[_vm._v("mdi-magnify")])],1)])],1)],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"options":_vm.options,"server-items-length":_vm.totalItems,"items-per-page":10,"loading":!_vm.loaded,"footer-props":{
                            itemsPerPageOptions: _vm.itemsPerPageOptions,
                        },"loading-text":"Loading... Please wait","fixed-header":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.total_cost`,fn:function({item}){return [_c('span',[_vm._v(_vm._s(_vm._f("currency")(item.total_cost)))])]}},{key:`item.status`,fn:function({ item }){return [(item.status == 1)?_c('a',{staticClass:"ml-1 badge badge-primary text-white"},[_vm._v("pending ")]):_vm._e(),(item.status == 2)?_c('a',{staticClass:"ml-1 badge badge-success text-white"},[_vm._v("approved ")]):_vm._e()]}},{key:`item.action`,fn:function({ item }){return [(_vm.userAccess.view)?_c('v-btn',{attrs:{"text":"","icon":"","color":"orange"}},[_c('v-icon',{staticClass:"btn-action",attrs:{"small":""},on:{"click":function($event){return _vm.ViewCEForm(item,'View')}}},[_vm._v("mdi-eye")])],1):_vm._e(),(
                                    _vm.userAccess.edit && 
                                    item.status != 3 && 
                                    _vm.userAccess.edit && 
                                    item.status != 2
                                    )?_c('v-btn',{attrs:{"text":"","icon":"","color":"blue"}},[_c('v-icon',{staticClass:"btn-action",attrs:{"small":""},on:{"click":function($event){return _vm.ViewCEForm(item,'Update')}}},[_vm._v("mdi-square-edit-outline")])],1):_vm._e(),(
                                    _vm.userAccess.delete && 
                                    item.status != 3  && 
                                    _vm.userAccess.edit && 
                                    item.status != 2
                                    )?_c('v-btn',{attrs:{"text":"","icon":"","color":"red"}},[_c('v-icon',{staticClass:"btn-action",attrs:{"small":""},on:{"click":function($event){return _vm.deleteCEF(item.id)}}},[_vm._v("mdi-delete")])],1):_vm._e()]}}],null,true)})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }