<template>
    <v-card class="py-2" height="750" style="overflow-y: hidden;overflow-x: hidden" :loading="!loaded">

        <v-row class="pa-4">
            <input id="fileUploadByDrawer" type="file" @change="getFileByDrawer($event)"></input>
            <v-col cols="5" class="modules">
                <v-treeview :active.sync="active" :items="GET_NAVIGATION_DRAWER" :item-children="items.children"
                    :open.sync="open" activatable color="warning" open-on-click transition dense hoverable rounded>
                    <template v-slot:prepend="{ item }">
                        <v-icon v-if="item.children">
                            {{ item.icon }}
                        </v-icon>
                    </template>

                    <template v-slot:append="{ item }">
                        <v-btn text icon v-if="item.children" @click="downloadByDrawer(item)">
                            <v-icon color="red">mdi-cloud-download</v-icon>
                        </v-btn>
                        <v-btn text icon v-if="item.children" @click="uploadByDrawer(item)">
                            <v-icon color="success">mdi-cloud-upload</v-icon>
                        </v-btn>
                        <v-btn text icon v-if="item.children" @click="EditNavigation(item)">
                            <v-icon color="primary">mdi-square-edit-outline</v-icon>
                        </v-btn>
                        <v-btn text icon v-if="item.children" @click="DeleteNavigation(item.id)">
                            <v-icon color="red">mdi-trash-can</v-icon>
                        </v-btn>

                    </template>
                </v-treeview>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col>


                <v-container v-if="active.length > 0" class="d-flex justify-content-between">
                    <span>



                    </span>

                    <v-btn text icon @click="addActions">
                        <v-icon>mdi-plus-circle</v-icon>
                    </v-btn>
                </v-container>


                <v-scroll-y-transition mode="out-in">

                    <div v-if="open.length == 0" class="text-h6" style="display:absolute;text-align: center;">
                        Select Navigation Link
                    </div>

                    <v-card v-else class="pt-6" height="680" style="overflow-y: scroll;overflow-x: hidden" elevation="2">

                        <v-container fluid>
                            <v-row>
                                <v-col>
                                    <v-simple-table density="compact">
                                        <template v-slot:default>
                                            <tbody>
                                                <tr class="text-lg-h6">
                                                  <th width="35%">Action</th>
                                                  <th width="35%">Code</th>
                                                  <th width="30%">
                                                  <v-container class="cute-space">
                                                    <v-btn text icon small  @click="addActionsSaveAll()">
                                                      <v-icon color="primary" title="Save All">mdi-content-save-all</v-icon>
                                                    </v-btn>
                                                    <v-btn small  @click="download" class="mx-2" icon text>
                                                      <v-icon color="red" title="Download Contents">mdi-cloud-download</v-icon>
                                                    </v-btn>
                                                    <input id="fileUpload" type="file" @change="getFile($event)"></input>
                                                    <v-btn small  @click="upload" id="import" icon text>
                                                        <v-icon color="success" title="Upload Contents">mdi-cloud-upload</v-icon>
                                                    </v-btn>
                                                  </v-container>


                                                  <v-container class="d-flex justify-content-between align-items-center cute-space">
                                                    <div class="justify-content-between align-items-center">
                                                        <v-checkbox v-model="selectAll" @change="toggleAllCheckboxes()"></v-checkbox>
                                                    </div>
                                                    <div class="justify-content-between text-center align-items-center">
                                                        <!-- Save button -->
                                                        <v-btn text icon @click="addActionsSaveAll()" color="primary" :disabled="saveButtonDisabled">
                                                          <v-icon>mdi-content-save</v-icon>
                                                        </v-btn>

                                                    </div>
                                                    <div :disabled="!isSelected" class="justify-content-between text-center align-items-center">
                                                        <!-- Delete button -->
                                                        <v-btn text icon @click="addActionsDeleteAll()">
                                                            <v-icon color="red">mdi-trash-can</v-icon>
                                                        </v-btn>
                                                    </div>
                                                </v-container>

                                                </th>


                                                </tr>


                                                <tr v-for="(actions, index) in tmp_arr" :key="index">
                                                    <td class="pa-1 ma-0" width="50%">
                                                        <v-text-field v-model="actions.actions" outlined dense
                                                            @keydown="isChanged(index)"
                                                            :disabled="actions.disable_text">{{}}</v-text-field>
                                                    </td>
                                                    <td class="pa-1 ma-0" width="50%">
                                                        <v-text-field v-model="actions.code" outlined dense
                                                            @keydown="isChanged(index)"
                                                            :disabled="actions.disable_text">{{}}</v-text-field>
                                                    </td>
                                                    <td>
                                                    <v-container class="d-flex justify-content-between align-items-center cute-space">


                                                      <v-checkbox v-model="actions.selected" @change="handleChildCheckboxChange(index)"></v-checkbox> <!--Child Checkbox-->


                                                      <div :class="actions.save_icon" :key="refresh_on_change">
                                                          <v-btn text icon v-if="!actions.isSaved"
                                                              @click="actions.action == 'add' ? actions_save(actions,index) : addSaveAction(actions,index)">
                                                              <v-icon :color="actions.isSaved == 'true'?  '' : 'primary' ">mdi-content-save</v-icon>

                                                       </v-btn>

                                                          <v-btn v-else text icon>
                                                            <v-icon>
                                                              mdi-content-save
                                                            </v-icon>
                                                          </v-btn>

                                                        </div>

                                                      <!-- <td :class="actions.save_icon?'pa-0 ma-0 d-none':'pa-0 ma-0'" width="10%"><v-btn text icon @click="edit_access(index)"><v-icon color="primary">mdi-square-edit-outline</v-icon></v-btn></td> -->
                                                      <div class="text-center">
                                                          <v-btn text icon
                                                              @click="actions.action == 'add' ? remove_action(actions.id, index) : remove_tmp_action(index)">
                                                              <v-icon color="red">mdi-trash-can</v-icon>
                                                          </v-btn>
                                                        </div>
                                                    </v-container>
                                                  </td>

                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-scroll-y-transition>
            </v-col>
        </v-row>

    </v-card>

</template>
<script>
import { mapGetters } from "vuex";
// import buttons from '@/views/main/layouts/Buttons/DialogButton.vue'
// import buttons_components from '@/assets/js/components-js';
import Swal from "sweetalert2";
export default {
  data: () => ({
    active: [],
    avatar: null,
    saveButtonDisabled: true,
    open: [],
    navigation_link: [],
    actions: {},
    tmp_arr: [],
    save_icon: [],
    trash: [],
    index: [],
    tmp_boolean: "true",
    refresh_on_change: "initial",
    selectedModule: {
      parent: 0,
      chilld: 0,
    },
    loaded: false,
    filename: "",
    selectAll: false,
    selectedDrawer: undefined,
    currentMatchAction: [],
  }),

  computed: {
    items() {
      return [
        {
          name: "",
          children: [],
        },
      ];
    },
    ...mapGetters([
      "GET_NAVIGATION_DRAWER",
      "USER_ACCESS",
      "GET_NEW_NAVIGATION_DRAWER",
      'GET_UPLOADED_BY_DRAWER',
    ]),

    isSelected() {
      let dataste = this.tmp_arr.some(
        (item) => item.isSaved === false && item.selected === true
      );
    },
  },
  mounted() {
    this.getAllNavigation();
    this.$store.dispatch(
      "checkAccessUser",
      this.$router.currentRoute.params.id
    );
  },
  methods: {
    toggleAllCheckboxes() {
      const allSelected = this.tmp_arr.every((item) => item.selected);
      this.selectAll = !allSelected;
      this.tmp_arr.forEach((item) => {
        item.selected = this.selectAll;
      });
    },
    handleChildCheckboxChange() {
      const anyUnchecked = this.tmp_arr.some((item) => !item.selected);
      this.selectAll = !anyUnchecked;
    },

    async actions_save(actions, index) {
      this.tmp_arr[index].isSaved = await new Promise((resolve) => {
        this.$store.dispatch("updateNavigationDrawerAction", actions);
        resolve(true);
        this.tmp_arr[index].isSaved = false;
      });

      const otherSelectedNotSaved = this.tmp_arr.some(
        (item, i) => i !== index && item.selected && !item.isSaved
      );
      this.updateSaveButtonColor(!otherSelectedNotSaved);

      this.refresh_on_change = "rerender" + new Date().getTime();
      this.tmp_arr[index].selected = false;
    },
    selected() {
      const id = this.active[0];
      if (this.open.length == 0) {
        this.tmp_arr = [];
      }

      // this.tmp_arr = this.tmp_arr.filter(el => el.id != '');

      if (this.tmp_boolean == "true") {
        this.tmp_arr = [];
        this.selecteds(id).then((res) => {
          res.forEach((e, i) => {
            res[i].save_icon = false;
            res[i].disable_text = false;
            res[i].action = "add";
            res[i].isSaved = true;
          });
          this.tmp_arr = res;
        });
      }
    },
    async selecteds(id) {
      let that = this;
      that.navigation_link = [];

      await that.GET_NAVIGATION_DRAWER.forEach((e, index) => {
        that.index.push(index);
        let nav_link = e.children.map((el) => el.id);
        if (nav_link.includes(id)) {
          e.children.forEach((element) => {
            if (element.id === id) {
              this.filename = element.name;
              that.navigation_link = element.actions;
            }
          });
        }
      });
      return that.navigation_link;
    },
    addActionsSaveAll() {
      let selectedItems = this.tmp_arr.filter((item) => item.selected);

      this.$store
        .dispatch("SaveallNavlinkaction", { params: selectedItems })
        .then((response) => {
          this.currentMatchAction = [];
          Swal.fire({
            title: "Success",
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          });

          this.tmp_boolean = "true";
        })
        .catch((error) => {
          console.error("Error saving items:", error);
        });

      this.refresh_on_change = "rerender" + new Date().getTime();
      selectedItems.forEach((item) => {
        item.isSaved = true;
        item.selectedItems = false;
      });
      this.updateSaveButtonColor(true);
      this.selectAll = false
      this.tmp_arr.forEach((item) => {
        item.selected = this.selectAll;

      });

    },

    updateSaveButtonColor(switchme) {
      this.saveButtonDisabled = switchme;
    },

    addActionsDeleteAll() {
      const selectedItems = this.tmp_arr.filter((item) => item.selected);

      Swal.fire({
        text: "Are you sure you want to delete all selected items?",
        icon: 'question',
        confirmButtonText: "OK",
        showCancelButton: true,
        reverseButtons: true,
        allowOutsideClick: false,

      }).then((res) => {
        if (res.isConfirmed) {
          selectedItems.forEach((item) => {
            this.$store.dispatch("deleteActions", item.id); // pass the id to deleteActions
            const index = this.tmp_arr.indexOf(item);
            if (index > -1) {
              this.tmp_arr.splice(index, 1);
            }
          });
        }
      });
      this.selectAll = false
    },
    addActions(){
        this.tmp_boolean = 'false'

        this.tmp_arr.push({
          action:'edit',
          id:'',
          access_type: 2,
          actions: '',
          code: '',
          created_at:'',
          disable_text:false,
          menu: this.active[0],
          updated_at: '',
          isSaved: false
        })

        this.updateSaveButtonColor(false);

      },
    addSaveAction(item, index) {
      this.$store.dispatch("addNavlinkaction", item).then((response) => {
        Swal.fire({
          title: "Success",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        this.tmp_boolean = "true";
        this.tmp_arr[index].isSaved = true;
        this.tmp_arr[index].selected = false;
      });
      this.updateSaveButtonColor(true);
    },




    remove_action(item, index) {
      Swal.fire({
        text: "Are you sure you want to delete?",
        icon: "question",
        confirmButtonText: "OK",
        showCancelButton: true,
        reverseButtons: true,
        allowOutsideClick: false,
      }).then((res) => {
        if (res.isConfirmed) {
          this.$store.dispatch("deleteActions", item);
          this.tmp_arr.splice(index, 1);
        }
      });
    },
    remove_tmp_action(index) {
      Swal.fire({
        text: "Are you sure you want to delete?",
        icon: "question",
        confirmButtonText: "OK",
        showCancelButton: true,
        reverseButtons: true,
        allowOutsideClick: false,
      }).then((res) => {
        if (res.isConfirmed) {
          this.tmp_arr.splice(index, 1);
          this.tmp_boolean = "true";
        }
      });
    },
    EditNavigation(item) {
      this.$store.commit("EDIT_NAVIGATION_DRAWER", item);
      this.$store.commit("ACTION", "Update");
      this.$store.commit("DIALOG", true);
    },
    DeleteNavigation(item) {
      Swal.fire({
        text: "Are you sure you want to delete?",
        icon: "question",
        confirmButtonText: "OK",
        showCancelButton: true,
        reverseButtons: true,
        allowOutsideClick: false,
      }).then((res) => {
        if (res.isConfirmed) {
          this.$store.dispatch("DeleteNavigationDrawer", item);
          this.getAllNavigation();
        }
      });
    },
    getAllNavigation() {
      this.$store.dispatch("NavigationDrawerList");
    },
    isChanged(index) {
      this.tmp_arr[index].isSaved = false;
      this.tmp_arr[index].selected = true;
      this.updateSaveButtonColor(false);
    },
    download() {
      let text = JSON.stringify(this.tmp_arr);
      let filename = this.filename + ".json";
      let element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:application/json;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();
      document.body.removeChild(element);
    },
    downloadByDrawer(item) {
      const filename = `${item.name}.json`;
      let batch = [];

      item.children.forEach(el => {
        batch.push(el);
      });

      let element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:application/json;charset=utf-8," + encodeURIComponent(JSON.stringify(batch))
      );
      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();
      document.body.removeChild(element);
    },
    upload() {
      document.getElementById("fileUpload").click();
    },
    uploadByDrawer(item) {
      this.selectedDrawer = item;
      document.getElementById("fileUploadByDrawer").click();
    },
    async getFile(val) {
  let a = val.target.files[0];

  const reader = new FileReader();
  reader.readAsText(a); // Read file as text, assuming it contains JSON data
  reader.onload = async () => {
    let jsonData = JSON.parse(reader.result);
    let newData = [];
    let existingCodes = this.tmp_arr.map(item => item.code); // Extract existing codes

    // Filter out new data that doesn't already exist
    jsonData.forEach(element => {
      if (!existingCodes.includes(element.code)) {
        newData.push(element);
      }
    });

    if (newData.length === 0) {
      Swal.fire("Error!", "No new values found", "error");
      return false;
    }
    this.updateSaveButtonColor(false);
    newData.forEach(element => {
      this.tmp_arr.push({
        action: "edit",
        id: "",
        access_type: 2,
        actions: element.actions,
        code: element.code,
        created_at: "",
        disable_text: false,
        menu: this.active[0],
        updated_at: "",
        isSaved: false,
        selected: true,
      });

    });


  };
},
  async getFileByDrawer(val) {
    let a = val.target.files[0];
    const reader = new FileReader();
    reader.readAsText(a);
    reader.onload = async () => {
      let jsonData = JSON.parse(reader.result);
      let childrenToAdd = [];
      const moduleNames = this.selectedDrawer.children.map(el => el.name);

      let nextHierarchy = this.selectedDrawer.children.map(el => el.hierarchy);
      nextHierarchy = nextHierarchy.reduce((max, current) => (current > max ? current : max), nextHierarchy[0]);

      jsonData.forEach(element => {
        if (!moduleNames.includes(element.name)) {
          nextHierarchy++;

          element.actions.forEach(el => {
            el.id = undefined;
          });

          element.id = undefined;
          element.hierarchy = nextHierarchy;
          childrenToAdd.push(element);
        }
      });

      this.$store.commit('UPLOADED_BY_DRAWER', {
        id: this.selectedDrawer.id,
        children: childrenToAdd,
      });
      this.EditNavigation(this.selectedDrawer);
    };
  },
  },
  watch: {
    active: {
      handler(val) {
        if (!!this.selectedModule.parent && !!val[0]) {
          this.selected();

          // if (this.GET_UPLOADED_BY_DRAWER) {
          //   const match = this.GET_UPLOADED_BY_DRAWER.children.find(el => el.id == this.active[0]);
          //   if (match) {
          //     this.currentMatchAction = match.actions;
          //     match.actions.forEach(element => {
          //       this.addActions();
          //       this.tmp_arr[this.tmp_arr.length - 1].actions = element.actions;
          //       this.tmp_arr[this.tmp_arr.length - 1].code = element.code;
          //     });
          //   }
          // }
        }
      },
    },
    tmp_arr: {
      handler(val) {
        console.log('tmp_arr', val);
      }
    },
    open: {
      handler(val) {
        if (!!val[0]) {
          this.selectedModule.parent = val[0];
        }
      },
    },
    GET_NAVIGATION_DRAWER: {
      handler(val) {
        this.loaded = true;
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.modules {
  height: 745px;
  max-height: 745px;
  overflow-y: scroll;
}
#fileUpload,
#fileUploadByDrawer {
  display: none;
}
.danger {
  color: red;
}
.cute-space {
  padding: 0px !important;
}
</style>
