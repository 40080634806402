<template>
    <v-container fluid>
        <ComponentHeader
            class="ma-n4"
            :button="true"
            :title="'Container Report'"
            :type="'Inventory'"
        ></ComponentHeader>
        <v-row>
            <v-app id="my_vue_app" style="width:100%" class="ml-3 mr-3 bg-bluish-gray pa-3">
                <div class="row">
                    <div class="col-md-2 col-sm-12  ">
                        <v-autocomplete
                            :items="filters.po_id.selection"
                            v-model="filters.po_id.selected"
                            :loading="loading.po_id_selection"
                            :disabled="loading.po_id_selection"
                            label="PO #"
                            @change="selectedPo"
                            class="d-inline-block w-100"
                            dense
                            outlined
                            background-color="white"
                            return-object
                            multiple
                            small-chips
                            deletable-chips
                        >
                        </v-autocomplete>
                    </div>
                    <div class="col-md-2 col-sm-12">
                        <v-autocomplete
                            :items="filters.status.selection"
                            v-model="filters.status.selected"
                            label="Select Status"
                            @select="selectedStatus"
                            class="d-inline-block w-100"
                            dense
                            outlined
                            background-color="white"
                            small-chips
                            deletable-chips
                        >
                        </v-autocomplete>
                    </div>
                    <div class="col-md-2 col-sm-12">
                        <v-autocomplete
                            :items="filters.filter_date.selection"
                            v-model="filters.filter_date.selected"
                            item-value="value"
                            item-text="text"
                            class="d-inline-block w-100"
                            label="Filter Date by"
                            dense
                            outlined
                            background-color="white"
                            return-object
                            >
                        </v-autocomplete>
                    </div>
                    <div class="col-md-3"></div>
                    <div class="col-sm-12 col-md-3 text-right float-right">
                        <v-btn  small class="ml-1" @click="resetFields()" :loading="!loaded">Clear</v-btn>
                        <v-btn  small class="ml-1" @click="exportToExcel()" :loading="!loaded">Excel</v-btn>
                        <v-btn small class="ml-1" @click="list()" :loading="!loaded">Filter</v-btn>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2 col-sm-12">
                        <v-autocomplete
                            :items="filters.types.selection"
                            :loading="loading.item_type_selection"
                            :disabled="loading.item_type_selection"
                            v-model="filters.types.selected"
                            @change="selectedTypes"
                            label="Discount Type"
                            class="d-inline-block w-100"
                            dense
                            outlined
                            background-color="white"
                            return-object
                            multiple
                            small-chips
                            deletable-chips
                            >
                        </v-autocomplete>
                    </div>
                    <div class="col-md-2 col-sm-12">
                        <v-autocomplete
                            :items="filters.categories.selection"
                            :loading="loading.item_category_selection"
                            :disabled="loading.item_category_selection"
                            v-model="filters.categories.selected"
                            @change="selectedCategories"
                            label="Categories"
                            class="d-inline-block"
                            dense
                            outlined
                            background-color="white"
                            return-object
                            multiple
                            small-chips
                            deletable-chips
                        >
                        </v-autocomplete>
                    </div>
                    <div class="col-md-2 col-sm-12">
                        <v-autocomplete
                            :items="filters.items.selection"
                            :loading="loading.item_selection"
                            :disabled="loading.item_selection"
                            v-model="filters.items.selected"
                            @change="selectedItems"
                            label="Select Item Model"
                            class="d-inline-block"
                            dense
                            outlined
                            background-color="white"
                            return-object
                            multiple
                            small-chips
                            deletable-chips
                            >
                        </v-autocomplete>
                    </div>
                    <div class="col-md-1"></div>
                </div>
                <div class="row">
                    <!-- <div class="col-md-2">
                        <label class="float-left control-label col-form-label">Grouping</label>
                        <v-autocomplete
                            :items="filters.grouping.selection"
                            v-model="filters.grouping.selected"
                            item-value="value"
                            option-text="text"
                            class="d-inline-block"
                            placeholder="Select Grouping"
                        >

                        </v-autocomplete>
                    </div> -->
                    <div class="col-sm-4 col-md-2" id="date_from">
                        <v-menu
                            v-model="filters.date.menu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date.date_from"
                                    readonly
                                    append-icon="mdi-calendar-clock"
                                    v-on="on"
                                    dense
                                    outlined
                                    background-color="white"
                                    label="Date From"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date.date_from" @input="filters.date.menu2 = false"></v-date-picker>
                        </v-menu>
                    </div>

                    <div class="col-sm-4 col-md-2" id="date_to">
                        <v-menu
                            v-model="filters.date.menu3"
                            :close-on-content-click = "false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                            z-index="100"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date.date_to"
                                    readonly
                                    append-icon="mdi-calendar-clock"
                                    v-on="on"
                                    dense
                                    outlined
                                    background-color="white"
                                    label="Date To"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date.date_to" @input="filters.date.menu3 = false"  style="z-index:100"></v-date-picker>
                        </v-menu>
                    </div>
                </div>
                <div class="container bg-white mt-3" >
                    <div class="row p-40 bg-dark">
                        <v-data-table
                            :items="dataTable.items"
                            :headers="dataTable.headers"
                            :options.sync="options"
                            class="w-100"
                            :items-per-page="10"
                            :server-items-length="dataTable.pagination.totalItems "
                        >
                        </v-data-table>
                    </div>
                </div>
            </v-app>
            <v-app id="load_bar" v-if="!loaded" style="width:100%" class="ml-3 mr-3">
                <v-text-field color="info" loading disabled></v-text-field>
            </v-app>
        </v-row>

    </v-container>

</template>

<script>
    import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
    import { mapGetters } from 'vuex'
    import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
    import swal from 'sweetalert2';

    export default {
        data(){
            return {
                loaded:true,
                filters:{
                    filter:1,
                    date:{
                        date_from:this.$dayjs().clone().startOf('month').format('YYYY-MM-DD'),
                        date_to:this.$dayjs().clone().endOf('month').format('YYYY-MM-DD'),
                        menu2: false,
                        menu3: false,
                        errors:[],
                    },
                    status:{
                        selected:[],
                        selection: [
                            { text: 'Pending', value: 1 },
                            { text: 'Receive', value: 2 },
                            { text: 'Approved', value: 3 },
                        ],
                    },
                    items:{
                        selected:[],
                        selection:[],
                    },
                    categories:{
                        selected:[],
                        selection:[],
                    },
                    types:{
                        selected:[],
                        selection:[],
                    },
                    po_id: {
                        selected:[],
                        selection:[]
                    },
                    filter_date: {
                        selection: [
                            {text:'Created Date',value:1},
                            {text:'Received Date',value:2},
                            {text:'Approved Date',value:3},
                        ],
                        selected: 1,
                    }
                },
                dataTable:{
                    headers: [
                        {text:'Container#',align: "left",value:'container_num'},
                        {text:'PO#',align: "left",value:'po_number'},
                        {text:'Model',align: "left",value:'model'},
                        {text:'Name',align: "left",value:'name'},
                        {text:'UOM',align: "left",value:'uom'},
                        {text:'Type',align: "left",value:'type'},
                        {text:'Category',align: "left",value:'categories'},
                        {text:'Received',align: "left",value:'received_date'},
                        {text:'Confirm',align: "left",value:'confirm_date'},
                        {text:'Created',align: "left",value:'confirm_date'},
                        {text:'Created By',align: "left",value:'user_name'},
                    ],
                    items:[],
                    pagination:{
                        options:{
                            itemsPerPage:10,
                            page:{},
                        },
                        totalItems:0,
                    }
                },
                options:{},
                loading:{
                        po_id_selection         : true,
                        item_type_selection     : true,
                        item_category_selection : true,
                        item_selection          : true

                }
            }
        },
        components: {
            SharedFunctionsComponentVue,ComponentHeader
        },

        mounted(){
            this.$store.dispatch('getAllitemSelectionDefault')
            this.$store.dispatch('getItemTypes')
            this.$store.dispatch('getItemCategories')

            this.getPoNumber()
        },
        computed:{
            ...mapGetters([
                'GET_ITEM_SELECTION_DEFAULT',
                'GET_ITEM_CATEGORIES',
                'GET_ITEM_TYPES',
            ])
        },
        methods: {
            selectedCategories(val){
                this.filters.categories.selected = val
            },

            selectedTypes(val){
                this.filters.types.selected = val
            },

            selectedStatus(val){
                this.filters.status.selected = val
            },

            selectedItems(val){
                this.filters.items.selected = val
            },

            getPoNumber() {
                this.$store.dispatch('reports',{
                  url:'procurement/purchase-order/po-numbers'
                }).then(response => {
                    this.loading.po_id_selection = false
                    this.filters.po_id.selection = response.data.data

                })

            },

            selectedPo(val) {
                this.filters.po_id.selected = val
            },

            async list(page = 1){
                let that = this

                that.loaded = false

                await this.$store.dispatch('reportsPost', {
                    date_from :     that.filters.date.date_from,
                    date_to :       that.filters.date.date_to,
                    categories :    that.filters.categories.selected.map((e)=>{
                                return e.value;
                    }),
                    types:          that.filters.types.selected.map((e)=>{
                                return e.value;
                    }),
                    po_id:          that.filters.po_id.selected,
                    status:         that.filters.status.selected,
                    page:           this.options.page,
                    filter_date :   that.filters.filter_date.selected,
                    itemsPerPage:   this.options.itemsPerPage,
                    items:          this.filters.items.selected.map((e)=>{
                                return e.value;
                    }),
                    url:'container-per-item/list'
                }).then(response => {
                    console.log(response)
                    that.dataTable.items = response.data.data
                    that.dataTable.pagination.totalItems = response.data.pageCount
                    that.loaded = true
                }).catch(e => {
                    swal.fire('Error !', '', 'error')
                    console.log(e)
                    that.loaded = true
                })
            },

            async exportToExcel(){
                let that = this

                const workbook = new this.$exceljs.Workbook();

                that.loaded = false

                await this.$store.dispatch('reportsPost', {
                    date_from :     that.filters.date.date_from,
                    date_to :       that.filters.date.date_to,
                    categories :    that.filters.categories.selected.map((e)=>{
                                return e.value;
                    }),
                    types:           that.filters.types.selected.map((e)=>{
                                return e.value;
                    }),
                    po_id: that.filters.po_id.selected.map((e)=>{
                                return e.value;
                    }),
                    status: that.filters.status.selected,
                    url:'container-per-item/list'

                }).then(response => {
                    const worksheet = workbook.addWorksheet('Raw Data')
                    worksheet.columns = [
                        {header:'Container#',key:'container_num'},
                        {header:'PO#',key:'po_number'},
                        {header:'Model',key:'model'},
                        {header:'Name',key:'name'},
                        {header:'UOM',key:'uom'},
                        {header:'Type',key:'type'},
                        {header:'Category',key:'categories'},
                        {header:'Received',key:'received_date'},
                        {header:'Confirm',key:'confirm_date'},
                        {header:'Created',key:'confirm_date'},
                        {header:'Created By',key:'user_name'},
                    ]

                    response.data.data.forEach(e => {
                        worksheet.addRow(e);
                    });
                }).catch(e => {
                    swal.fire('Error !', '', 'error')
                    console.log(e)
                    that.loaded = true
                })

                that.loaded = true
                workbook.xlsx.writeBuffer().then((data) => {
                    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                    this.$filesaver.saveAs(blob, `Containers Report ${that.filters.date.date_from}-${that.filters.date.date_to}.xlsx`);
                });
            },



            thousandSeprator(amount) {
                if (amount !== '' || amount !== undefined || amount !== 0 || amount !== '0' || amount !== null) {
                    return amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                } else {
                    return amount;
                }
            },

            resetFields(){
                let that = this
                that.filters.status.selected = []
                that.filters.items.selected = []
                that.filters.categories.selected = []
                that.filters.types.selected = that.filters.types.selected.filter(e=>{return e.value != 3})
                that.filters.date.date_from = this.$dayjs().clone().startOf('month').format('YYYY-MM-DD')
                that.filters.date.date_to = this.$dayjs().clone().endOf('month').format('YYYY-MM-DD')
                that.filters.filter_date.selected = ''
                that.filters.po_id.selected = []
            }
        },
        watch:{
            options: {
                handler() {
                    this.list();
                },
            },
            'GET_ITEM_SELECTION_DEFAULT':{
                handler(val){
                    this.filters.items.selection = val
                    this.loading.item_selection = false
                }
            },
            'GET_ITEM_CATEGORIES':{
                handler(val){
                    this.filters.categories.selection = val
                    this.loading.item_category_selection = false
                }
            },
            'GET_ITEM_TYPES':{
              handler(val){
                this.filters.types.selection = val.data
                this.loading.item_type_selection = false
              }
            }

        }
    };
</script>
<style>
    .col{
        padding: 2px !important;
    }

    .card-body{
        padding: 3px !important;
    }
</style>
