import StoreBranchesAccessComponent from "@/views/main/modules/Accounting/StoreBranchesAccess/StoreBranchesAccessComponent.vue";
import StoreBranchesAccessViewComponent from '@/views/main/layouts/dialogs/Accounting/StoreBranchesAccessViewComponent.vue'
import department from "@/store/modules/MasterData/department";
let settings = {
    tab_item: [
        {
            tab_name: 'ACCESS',
            children: {
                components: StoreBranchesAccessComponent,
                dialog:{
                    components:StoreBranchesAccessViewComponent,
                    title:'STORE / BRANCHES USER ACCESS',
                    width:''
                },
                dispatch: {
                    get:{
                        _action:'getAllData',
                        url:'accounting/get-all-store-branches-data'
                    },
                    post:{
                        _action:'post',
                        url:'accounting/store-branches-data'
                    },
                    put:{
                        _action:'put',
                        url:'accounting/store-branches-data'
                    },
                    delete:{
                        _action:'delete',
                        url:'accounting/store-branches-data'
                    },
                    show:{
                        _action:'show',
                        url:'accounting/store-branches-data-items'
                    },
                },
                color:{

                },
                data_table: {
                    items:[],
                    total_items:1,
                    headers: [{ text: "Name", align: "left", value: "name", sortable:false },
                    { text: "Company", align: "left", value: "company_id", sortable:false },
                    // { text: "CLASS", align: "left", value: "classification_code" },
                    // { text: "UOM", align: "left", value: "uom_code" },
                    { text: "Action", align: "center", value: "action", sortable:false},
                    
                ],
                options: {},
                filters:(options,filter)=> {
                   return {
                    page: options.page,
                    itemsPerPage:options.itemsPerPage,
                    search: filter.search,
                    department_id:filter.department_id,
                    sortBy:options.sortBy,
                    sortDesc:options.sortDesc,
                   }
                },
                itemsPerPageOptions: [5, 10, 15, 50, 100],
                itemsPerPage: 10,
            
                }
            }
        },
    ]
}
export default settings;