<template>
    <v-card>
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col>
                      <v-card-title class="d-flex justify-content-end pa-1">
                        <v-btn small @click="optimize()">
                           CLEAR CACHE
                        </v-btn>
                       </v-card-title>
                       <v-card class="pt-2">
                        <v-card-text style="height:340px; overflow: auto;" class="bg-dark">
                            <pre style="font-size:9px; color:greenyellow;" >{{response_text }}</pre>
                        </v-card-text>
                       </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import swal from 'sweetalert2';
import { mapGetters } from 'vuex'
  export default {
    data () {
      return {
        response_text:''
      }
    },
    computed:{
       
    },
    created(){
 
    },
    mounted(){
     
    },
    methods:{
      optimize(){
        this.response_text = ''
        this.$store.dispatch('optimizeServer').then(response=>{
            this.response_text = response
        })
      }

    },
    watch:{
 
    }
  }
</script>
