<template>
    <div class="tabs-body-color">
        <v-container fluid>
            <v-row class="pt-2">
                <!-- StatementOfAccounts Table -->
                <StatementOfAccountsTableComponentVue
                    ref="childTable"
                    :date_from="dateFrom"
                    :date_to="dateTo"
                    :week_count="weekCount"
                    :filtered_month="filtered_month"
                    :filtered_year="filtered_year"
                    :status_value="status_value"
                    :status_text="status_text"
                    :months_selection="months_selection"
                    :statementOfAccountsActionResult="action"
                    @showEditModal="showEditModal"
                    @showEditModalBalance="showEditModalBalance"
                ></StatementOfAccountsTableComponentVue>
            </v-row>

            <v-app id="addEditStatementOfAccountModal">
                <v-dialog v-model="add_edit_statementOfAccount_dialog" persistent max-width="80%" scrollable>
                    <v-card>
                        <v-row class="m-0">
                            <v-card-title>
                                <span class="headline">
                                    Statement Of Account Information | SOA# {{ editStatementOfAccountId }}
                                </span>
                            </v-card-title>
                            <v-col cols="pull-right-10 p-2">
                                <v-btn
                                    class="float-right"
                                    color="gray"
                                    icon
                                    text
                                    @click="closeDialog()"
                                >
                                    <v-icon>mdi-close-circle</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-card-text>
                            <v-form
                                action="/statementOfAccounts"
                                class="form-horizontal"
                                method="post"
                                @submit.prevent="onSubmit"
                            >
                                <v-container fluid class="card-body">
                                    <v-row class="d-flex align-items-center">
                                        <v-col cols="4" sm="12" md="4" class="d-flex flex-column">
                                            <v-autocomplete
                                                v-model="customer_id"
                                                :items="GET_CUSTOMERS_LIST_SELECTION"
                                                :disabled="action == 'Edit'"
                                                label="Customer"
                                                dense
                                                outlined
                                                hide-details
                                                return-object
                                                auto-select-first
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                                            <v-checkbox
                                                v-model="super_product"
                                                :true-value="1"
                                                :false-value="0"
                                                class="ma-0 py-2"
                                                label="Super Product"
                                                hide-details
                                            ></v-checkbox>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                                            <v-menu
                                                v-model="date_menu"
                                                :close-on-content-click="false"
                                                :return-value.sync="selected_date"
                                                ref="date_menu"
                                                min-width="290px"
                                                transition="scale-transition"
                                                offset-y
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="selected_date_formatted"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        :disabled="disableFields()"
                                                        label="SOA Month"
                                                        prepend-icon="mdi-calendar"
                                                        dense
                                                        hide-details
                                                        readonly
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker v-model="selected_date" type="month" no-title scrollable>
                                                    <v-spacer></v-spacer>
                                                    <v-btn
                                                        color="primary"
                                                        text
                                                        @click="date_menu = false"
                                                    >
                                                        Cancel
                                                    </v-btn>
                                                    <v-btn
                                                        color="primary"
                                                        text
                                                        @click="$refs.date_menu.save(selected_date); changeDateFormat()"
                                                    >
                                                        OK
                                                    </v-btn>
                                                </v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="2" lg="2" xl="2" id="due_date">
                                            <v-menu
                                                v-model="menu2"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                min-width="290px"
                                                transition="scale-transition"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        v-model="due_date_formatted"
                                                        v-on="on"
                                                        :disabled="disableFields()"
                                                        append-icon="mdi-calendar-clock"
                                                        label="Due Month"
                                                        dense
                                                        hide-details
                                                        readonly
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker v-model="due_date" @input="menu2 = false" type="month"></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="4" sm="12" md="2">
                                            <label for="terms" class="float-left control-label col-form-label">Terms</label>
                                            <div class="input-group mb-10">
                                                <input
                                                    v-model="terms"
                                                    type="text"
                                                    aria-label="%"
                                                    aria-describedby="basic-addon2"
                                                    class="form-control"
                                                    placeholder="0"
                                                    disabled
                                                    @keypress="numberOnly"
                                                >
                                                    <div class="input-group-append">
                                                        <span class="input-group-text" id="basic-addon2">days</span>
                                                    </div>
                                                </input>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                                            <v-menu
                                                v-model="menu4"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                min-width="290px"
                                                transition="scale-transition"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        v-model="start_date"
                                                        v-on="on"
                                                        :disabled="action == 'Add' || soaStatus == 0 ? false : true"
                                                        append-icon="mdi-calendar-clock"
                                                        label="Start Day"
                                                        readonly
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker v-model="start_date" @input="menu2 = false"></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                                            <v-menu
                                                v-model="menu5"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                min-width="290px"
                                                transition="scale-transition"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        v-model="end_date"
                                                        v-on="on"
                                                        :disabled="action == 'Add' || soaStatus == 0 ? false : true"
                                                        append-icon="mdi-calendar-clock"
                                                        label="End Day"
                                                        readonly
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker v-model="end_date" @input="menu2 = false"></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col>
                                            <v-btn
                                                :disabled="action == 'Add' || soaStatus == 0 ? false : true"
                                                class="mt-5"
                                                @click="generate();"
                                            >
                                                Generate
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" sm="12" md="4" class="mb-2">
                                            <v-textarea
                                                v-model="remarks"
                                                :disabled="disableFields()"
                                                class="p-0"
                                                rows="2"
                                                label="Remarks"
                                                dense
                                                no-resize
                                            ></v-textarea>
                                        </v-col>
                                    </v-row>
                                    <v-card :style="soaItemCardTableStyle()" class="pa-4 mb-4">
                                        <v-row>
                                            <v-col cols="12">
                                                <v-data-table
                                                    :headers="headers"
                                                    :items="soa_items"
                                                    class="w-100"
                                                    item-key="packing_list_id"
                                                    disable-pagination
                                                    hide-default-footer
                                                    show-expand
                                                    single-expand
                                                    @item-expanded="loadDetails"
                                                >
                                                    <template v-slot:[`item.packing_list_num`]="{ item }">
                                                        <span class="text-nowrap">
                                                            <v-text-field
                                                                v-model='item.packing_list_num'
                                                                type="text"
                                                                class="mt-1 text-center small"
                                                                label="PL #"
                                                                placeholder="0"
                                                                dense
                                                                outlined
                                                                filled
                                                                readonly
                                                            />
                                                        </span>
                                                    </template>
                                                    <template v-slot:[`item.sales_date`]="{ item }">
                                                        <span class="text-nowrap">
                                                            <v-text-field
                                                                v-model="item.sales_date"
                                                                type="text"
                                                                class="mt-1 text-center small"
                                                                label="PL Date"
                                                                placeholder="0"
                                                                dense
                                                                outlined
                                                                filled
                                                                readonly
                                                            />
                                                        </span>
                                                    </template>
                                                    <template v-slot:[`item.sales_total`]="{ item, index }">
                                                        <span class="text-nowrap">
                                                            <v-text-field
                                                                v-if="item.override_amount == null || item.override_amount == 0"
                                                                v-model="item.sales_total"
                                                                class="mt-1 small"
                                                                label="PL Total"
                                                                dense
                                                                outlined
                                                                hide-details
                                                                filled
                                                                reverse
                                                                readonly
                                                                @click="showOverrideAmountDialog(index)"
                                                            />
                                                            <v-text-field
                                                                v-else
                                                                v-model="item.override_amount"
                                                                type="text"
                                                                class="mt-1 small bg-ingco"
                                                                label="PL Total"
                                                                dense
                                                                outlined
                                                                hide-details
                                                                filled
                                                                reverse
                                                                readonly
                                                                @click="showOverrideAmountDialog(index)"
                                                            />
                                                        </span>
                                                    </template>
                                                    <template v-slot:[`item.remarks`]="{ item }">
                                                        <span class="text-nowrap">
                                                            <v-text-field
                                                                v-model="item.remarks"
                                                                type="text"
                                                                class="mt-1 text-center small"
                                                                label="Remarks"
                                                                dense
                                                                outlined
                                                                hide-details
                                                                filled
                                                            />
                                                        </span>
                                                    </template>
                                                    <template v-slot:[`item.actions`]="{ index }">
                                                        <v-btn
                                                            :disabled="disableFields()"
                                                            color="primary"
                                                            style="height:20px; width:20px;"
                                                            fab
                                                            dark
                                                            small
                                                            @click="removeOrderByIndex(index,'soa_items')"
                                                        >
                                                            <v-icon dark small style="font-size:12px">
                                                                mdi-minus
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <template v-slot:expanded-item="{ headers }">
                                                        <td :colspan="headers.length" class="p-0">
                                                            <v-data-table
                                                                :headers="expansionHeaders"
                                                                :items="expandedItems"
                                                                :loading="loading"
                                                                dark
                                                                dense
                                                                fixed-header
                                                                hide-default-footer
                                                            >
                                                                <template v-slot:[`item.amount`]="{ item }">
                                                                    <span class="text-nowrap">
                                                                        &#x20b1;{{ item.amount | currency }}
                                                                    </span>
                                                                </template>
                                                                <template v-slot:[`item.total_amount`]="{ item }">
                                                                    <span class="text-nowrap">
                                                                        &#x20b1;{{ item.total_amount | currency }}
                                                                    </span>
                                                                </template>
                                                                <template v-slot:[`item.foc`]="{ item }">
                                                                    <span class="text-nowrap">
                                                                        {{ item.foc == 0 ? 'No' : 'Yes' }}
                                                                    </span>
                                                                </template>
                                                            </v-data-table>
                                                        </td>
                                                    </template>
                                                </v-data-table>
                                                <v-data-table
                                                    :headers="adminTransactionDataTable.headers"
                                                    :items="adminTransactionDataTable.items"
                                                    class="w-100"
                                                    item-key="transaction_number"
                                                    disable-pagination
                                                    hide-default-footer
                                                    show-expand
                                                    single-expand
                                                    @item-expanded="loadDetails"
                                                >
                                                    <template v-slot:[`item.transaction_number`]="{ item }">
                                                        <span class="text-nowrap">
                                                            <v-text-field
                                                                v-model='item.transaction_number'
                                                                type="text"
                                                                class="mt-1 text-center small"
                                                                label="Transaction #"
                                                                placeholder="0"
                                                                dense
                                                                outlined
                                                                filled
                                                                readonly
                                                            />
                                                        </span>
                                                    </template>
                                                    <template v-slot:[`item.transaction_date`]="{ item }">
                                                        <span class="text-nowrap">
                                                            <v-text-field
                                                                v-model="item.transaction_date"
                                                                type="text"
                                                                class="mt-1 text-center small"
                                                                label="Transaction Date"
                                                                placeholder="0"
                                                                dense
                                                                outlined
                                                                filled
                                                                readonly
                                                            />
                                                        </span>
                                                    </template>
                                                    <template v-slot:[`item.transaction_total`]="{ item, index }">
                                                        <span class="text-nowrap">
                                                            <v-text-field
                                                                v-if="item.override_amount == null || item.override_amount == 0"
                                                                v-model="item.transaction_total"
                                                                class="mt-1 small"
                                                                label="Transact Total"
                                                                dense
                                                                outlined
                                                                hide-details
                                                                filled
                                                                reverse
                                                                readonly
                                                                @click="showOverrideAmountDialog(index,item)"
                                                            />
                                                            <v-text-field
                                                                v-else
                                                                v-model="item.override_amount"
                                                                type="text"
                                                                class="mt-1 small bg-ingco"
                                                                label="Transact Total"
                                                                dense
                                                                outlined
                                                                hide-details
                                                                filled
                                                                reverse
                                                                readonly
                                                                @click="showOverrideAmountDialog(index,item)"
                                                            />
                                                        </span>
                                                    </template>
                                                    <template v-slot:[`item.remarks`]="{ item }">
                                                        <span class="text-nowrap">
                                                            <v-text-field
                                                                v-model="item.remarks"
                                                                type="text"
                                                                class="mt-1 text-center small"
                                                                label="Remarks"
                                                                dense
                                                                outlined
                                                                hide-details
                                                                filled
                                                            />
                                                        </span>
                                                    </template>
                                                    <template v-slot:[`item.actions`]="{ index }">
                                                        <v-btn
                                                            :disabled="disableFields()"
                                                            color="primary"
                                                            style="height:20px; width:20px;"
                                                            fab
                                                            dark
                                                            small
                                                            @click="removeOrderByIndex(index,'adminTransactionDataTable.items')"
                                                        >
                                                            <v-icon dark small style="font-size:12px">
                                                                mdi-minus
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <template v-slot:expanded-item="{ headers }">
                                                        <td :colspan="headers.length" class="p-0">
                                                            <v-data-table
                                                                :headers="expansionHeaders"
                                                                :items="expandedItems"
                                                                :loading="loading"
                                                                dark
                                                                dense
                                                                fixed-header
                                                                hide-default-footer
                                                            >
                                                                <template v-slot:[`item.amount`]="{ item }">
                                                                    <span class="text-nowrap">
                                                                        &#x20b1;{{ item.amount | currency }}
                                                                    </span>
                                                                </template>
                                                                <template v-slot:[`item.total_amount`]="{ item }">
                                                                    <span class="text-nowrap">
                                                                        &#x20b1;{{ item.total_amount | currency }}
                                                                    </span>
                                                                </template>
                                                                <template v-slot:[`item.foc`]="{ item }">
                                                                    <span class="text-nowrap">
                                                                        {{ item.foc == 0 ? 'No' : 'Yes' }}
                                                                    </span>
                                                                </template>
                                                            </v-data-table>
                                                        </td>
                                                    </template>
                                                </v-data-table>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                    <v-card v-if="showPayment('AP')" class="pa-4 mb-4">
                                        <h6>Allocated Payments </h6>
                                        <v-data-table
                                            :headers="appliedPaymentHeaders"
                                            :items="customerPaymentAllocations"
                                        >
                                            <template v-slot:[`item.allocated_amount`]="{ item }">
                                                <span class="text-nowrap">
                                                    &#x20b1;{{ item.allocated_amount | currency }}
                                                </span>
                                            </template>
                                            <template v-slot:[`item.remaining_amount`]="{ item }">
                                                <span class="text-nowrap">
                                                    &#x20b1;{{ item.remaining_amount | currency }}
                                                </span>
                                            </template>
                                            <template v-slot:[`item.created_at`]="{ item }">
                                                <span class="text-nowrap">
                                                    {{ date(item.created_at) }}
                                                </span>
                                            </template>
                                            <template v-slot:[`item.status`]="{ item }">
                                                <span class="badge badge-primary" v-if="item.status == 7">confirmed</span>
                                                <span class="badge badge-warning" v-else-if="item.status == 8">exhausted</span>
                                                <span class="badge badge-danger" v-else-if="item.status == 6">cancelled</span>
                                                <span class="badge badge-purple" v-else-if="item.status == 5">bounce</span>
                                                <span class="badge badge-success" v-else-if="item.status == 9">allocated</span>
                                                <span class="badge badge-warning" v-else-if="item.status == 10">partial</span>
                                                <span class="badge white--text badge-info" v-else-if="item.status == 11">for confirmation</span>
                                                <span class="badge white--text bg-danger" v-else-if="item.status == 3">rejected</span>
                                                <span class="badge white--text bg-secondary" v-else-if="item.status == 0">saved</span>
                                                <span class="badge white--text badge-dark" v-else-if="item.status == 12">replaced</span>
                                            </template>
                                            <template v-slot:[`item.actions`]="{ item }">
                                                <v-btn
                                                    v-if="item.status != 6 && item.status != 3"
                                                    color="orange"
                                                    icon
                                                    small
                                                    text
                                                    @click="viewCustomerPayment(item.id)"
                                                >
                                                    <v-icon>mdi-eye</v-icon>
                                                </v-btn>
                                            </template>
                                        </v-data-table>
                                    </v-card>
                                    <v-card v-if="showPayment('P')" class="pa-4">
                                        <div>Payments</div>
                                        <div>
                                            <v-row class="pa-4">
                                                <v-col cols="6">
                                                    <AddEditCustomerPaymentDetailComponentVue
                                                        :customerId="customer_id"
                                                        :fromSoa="true"
                                                        :addEdit="true"
                                                        :balanceAmounts="balanceAmount"
                                                        :soa_id="editStatementOfAccountId"
                                                        :hasRemaining="hasRemaining"
                                                        :origin="'editSoa'"
                                                    />
                                                </v-col>
                                                <v-col cols="6" class="d-flex justify-content-end">
                                                    <CreditMemoComponentVue
                                                        :isSoa="true"
                                                        :origin="1"
                                                        :soaSelectedCustomer="customer_id"
                                                        :soaId="editStatementOfAccountId"
                                                        :soaSelectedType="10"
                                                        :balanceAmounts="balanceAmount"
                                                        :soaRemarks="`Cancel out balance of SOA # ${editStatementOfAccountId}`"
                                                        :selected_date="selected_date"
                                                    />
                                                </v-col>
                                            </v-row>
                                        </div>
                                        <div>
                                            <v-data-table
                                                :headers="paymentHeaders"
                                                :items="paymentItems"
                                                :expanded-sync="paymentsExpansion.expanded"
                                                :loading="paymentsExpansion.loading"
                                                item-key="reference_number"
                                                dense
                                                show-expand
                                                single-expand
                                                @item-expanded="getSoaByPaymentDetailId"
                                            >
                                                <template v-slot:[`item.gross_amount`]="{ item }">
                                                    <span class="text-nowrap">
                                                        &#x20b1;{{ item.gross_amount | currency }}
                                                    </span>
                                                </template>
                                                <template v-slot:[`item.wht_amount`]="{ item }">
                                                    <span class="text-nowrap">
                                                        &#x20b1;{{ item.wht_amount | currency }}
                                                    </span>
                                                </template>
                                                <template v-slot:[`item.other_adj_amount`]="{ item }">
                                                    <span class="text-nowrap">
                                                        &#x20b1;{{ item.other_adj_amount | currency }}
                                                    </span>
                                                </template>
                                                <template v-slot:[`item.remaining_amount`]="{ item }">
                                                    <span class="text-nowrap">
                                                        &#x20b1;{{ item.remaining_amount | currency }}
                                                    </span>
                                                </template>
                                                <template v-slot:[`item.created_at`]="{ item }">
                                                    <span class="text-nowrap">
                                                        {{ date(item.created_at) }}
                                                    </span>
                                                </template>
                                                <template v-slot:[`item.allocate_amount`]="{ item }">
                                                    <v-checkbox
                                                        v-model="item.checked"
                                                        :disabled="item.remaining_amount == 0 && !item.checked || total_amount == 0 && !item.checked"
                                                        style="margin-top:0 !important; padding-top:0 !important;display:none"
                                                        dense
                                                        hide-details
                                                        @change="enableCustomerPaymentAllocation(item)"
                                                    />
                                                    <span class="text-nowrap">
                                                        <input
                                                            v-model="item.allocate_amount"
                                                            :min="0"
                                                            :max="item.remaining_amount"
                                                            :readonly="true"
                                                            type="text"
                                                            dense
                                                            outlined
                                                            style="
                                                                text-align:right;
                                                                cursor:pointer;
                                                                margin:1px 0px 1px !important;
                                                                height: 1.5rem !important;
                                                                padding:0 5px !important;
                                                                border: 1px solid black;
                                                                border-radius: 6px;
                                                                display:none
                                                            "
                                                            @keypress="decimalOnly"
                                                            @focus="$event.target.select()"
                                                        />
                                                    </span>
                                                </template>
                                                <template v-slot:[`item.status`]="{ item }">
                                                    <span class="badge badge-primary" v-if="item.status == 7">confirmed</span>
                                                    <span class="badge badge-warning" v-else-if="item.status == 8">exhausted</span>
                                                    <span class="badge badge-danger" v-else-if="item.status == 6">cancelled</span>
                                                    <span class="badge badge-purple" v-else-if="item.status == 5">bounce</span>
                                                    <span class="badge badge-success" v-else-if="item.status == 9">allocated</span>
                                                    <span class="badge badge-warning" v-else-if="item.status == 10">partial</span>
                                                    <span class="badge white--text bg-info" v-else-if="item.status == 11">for confirmation</span>
                                                    <span class="badge white--text bg-danger" v-else-if="item.status == 3">rejected</span>
                                                    <span class="badge white--text bg-secondary" v-else-if="item.status == 0">saved</span>
                                                    <span class="badge white--text badge-dark" v-else-if="item.status == 12">replaced</span>
                                                </template>
                                                <template v-slot:[`item.actions`]="{ item }">
                                                    <v-btn
                                                        v-if="(item.status != 12 && item.status != 6) && item.status != 0"
                                                        color="orange"
                                                        icon
                                                        text
                                                        x-small
                                                        @click="viewCustomerPayment(item.id)"
                                                    >
                                                        <v-icon>mdi-eye</v-icon>
                                                    </v-btn>
                                                    <v-btn
                                                        v-if="(item.status == 0 || item.status == 11) && userAccess.edit_payment"
                                                        color="blue"
                                                        icon
                                                        text
                                                        x-small
                                                        @click="editCustomerPayment(item.id)"
                                                    >
                                                        <v-icon>mdi-pencil</v-icon>
                                                    </v-btn>
                                                    <v-btn
                                                        v-if="item.status == 10"
                                                        color="green"
                                                        icon
                                                        text
                                                        x-small
                                                        @click="allocateRemainingBalance(item)"
                                                    >
                                                        <v-icon>mdi-cash-plus</v-icon>
                                                    </v-btn>
                                                    <!-- <v-btn v-if="item.status!=6" text x-small icon color="red" @click="cancelPayment(item.id)"> -->
                                                    <v-btn
                                                        v-if="(item.status != 12 && item.status != 10 && item.status != 0) && item.status != 6 && item.status != 3 && userAccess.cancel_payment"
                                                        color="red"
                                                        icon
                                                        text
                                                        x-small
                                                        @click="cancelPayment(item.id)"
                                                    >
                                                        <v-icon>mdi-cancel</v-icon>
                                                    </v-btn>
                                                </template>
                                                <template v-slot:expanded-item="{ headers }">
                                                    <td :colspan="headers.length">
                                                        <v-data-table
                                                            :headers="paymentsExpansion.headers"
                                                            :items="paymentsExpansion.items"
                                                            :items-per-page="10"
                                                            class="w-100"
                                                            item-key="id"
                                                            dense
                                                        >
                                                            <template v-slot:[`item.terms`]="{ item }">
                                                                <span class="text-nowrap" v-if="item.terms == 0">
                                                                    {{ 'CASH' }}
                                                                </span>
                                                                <span class="text-nowrap" v-else>
                                                                    {{ item.terms }}
                                                                </span>
                                                            </template>
                                                            <template v-slot:[`item.gross_amount`]="{ item }">
                                                                <span class="text-nowrap">
                                                                    &#x20b1;{{ item.gross_amount | currency }}
                                                                </span>
                                                            </template>
                                                            <template v-slot:[`item.discount_amount`]="{ item }">
                                                                <span class="text-nowrap">
                                                                    &#x20b1;{{ item.discount_amount | currency }}
                                                                </span>
                                                            </template>
                                                            <template v-slot:[`item.total_amount`]="{ item }">
                                                                <span class="text-nowrap">
                                                                    &#x20b1;{{ item.total_amount | currency }}
                                                                </span>
                                                            </template>
                                                            <template v-slot:[`item.paid_amount`]="{ item }">
                                                                <span class="text-nowrap">
                                                                    &#x20b1;{{ item.paid_amount | currency }}
                                                                </span>
                                                            </template>
                                                            <template v-slot:[`item.balance_amount`]="{ item }">
                                                                <span class="text-nowrap">
                                                                    &#x20b1;{{ item.balance_amount | currency }}
                                                                </span>
                                                            </template>
                                                            <template v-slot:[`item.addition_amount`]="{ item }">
                                                                <span class="text-nowrap">
                                                                    &#x20b1;{{ item.addition_amount | currency }}
                                                                </span>
                                                            </template>
                                                            <template v-slot:[`item.applied_amount`]="{ item }">
                                                                <span class="text-nowrap">
                                                                    &#x20b1;{{ item.applied_amount | currency }}
                                                                </span>
                                                            </template>
                                                            <template v-slot:[`item.deduction_amount`]="{ item }">
                                                                <span class="text-nowrap">
                                                                    &#x20b1;{{ item.deduction_amount | currency }}
                                                                </span>
                                                            </template>
                                                            <template v-if="(filtered_month == 0)" v-slot:[`item.selected_month`]="{ item }">
                                                                <span class="text-nowrap">
                                                                    {{ soa_month(item.selected_month, item.selected_year) | formatMonthSoa }}
                                                                </span>
                                                            </template>
                                                            <template v-slot:[`item.created_at`]="{ item }">
                                                                <span class="text-nowrap">
                                                                    {{ item.created_at | formatDateTime }}
                                                                </span>
                                                            </template>
                                                            <template v-slot:[`item.due_date`]="{ item }">
                                                                <span class="text-nowrap">
                                                                    {{ item.due_date | formatMonthSoa }}
                                                                </span>
                                                            </template>
                                                            <template v-slot:[`item.age`]="{ item }">
                                                                <span class="text-nowrap">
                                                                    {{ agingDate(item.due_date) }}
                                                                </span>
                                                            </template>
                                                            <template v-slot:[`item.status`]="{ item }">
                                                                <span v-if="item.status == 0" class="badge white--text bg-secondary">saved</span>
                                                                <span v-if="item.status == 5" class="badge white--text bg-primary">confirmed</span>
                                                                <span v-else-if="item.status == 6" class="badge bg-warning">partial</span>
                                                                <span v-else-if="item.status == 7" class="badge white--text bg-success">paid</span>
                                                                <span v-else-if="item.status == 8" class="badge white--text bg-danger">overdue</span>
                                                                <span v-else-if="item.status == 9" class="badge bg-warning">partial</span>
                                                                <span v-else-if="item.status == 11" class="badge white--text bg-info">for confirmation</span>
                                                                <span v-else-if="item.status == 3" class="badge white--text bg-info">reject</span>
                                                                <span v-else-if="item.status == 0" class="badge white--text bg-secondary">saved</span>
                                                                <span v-else-if="item.status == 12" class="badge white--text bg-dark">replaced</span>
                                                            </template>
                                                            <template v-slot:[`item.payment_methods`]="{ item }">
                                                                <span v-if="checkIfCombi(item.payment_methods)">
                                                                    <span class="badge badge-info">combi</span>
                                                                </span>
                                                                <span v-else>
                                                                    <span class="badge badge-success" v-if="item.payment_methods == '1'">cash</span>
                                                                    <span class="badge badge-purple" v-else-if="item.payment_methods == '2'">cheque</span>
                                                                    <span class="badge badge-warning" v-else-if="item.payment_methods == '3'">deposit</span>
                                                                </span>
                                                            </template>
                                                        </v-data-table>
                                                    </td>
                                                </template>
                                            </v-data-table>
                                        </div>
                                    </v-card>
                                    <br>
                                    <v-row class="d-flex flex-row align-items-center ml-1">
                                        <v-col sm="12" md="4">
                                            <v-textarea
                                                v-model="addition_remarks"
                                                :disabled="disableFields()"
                                                class="p-0"
                                                label="DM Remarks"
                                                rows="2"
                                                dense
                                                outlined
                                                hide-details
                                                no-resize
                                            ></v-textarea>
                                        </v-col>
                                        <v-col sm="12" md="2">
                                            <v-text-field
                                                v-model="addition_amount_text"
                                                id="addition_amount"
                                                type="text"
                                                autocomplete="off"
                                                class="ml-1"
                                                label="DM Amount"
                                                dense
                                                outlined
                                                hide-details
                                                filled
                                                readonly
                                                @keypress="decimalOnly"
                                            />
                                        </v-col>
                                        <v-col sm="12" md="2">
                                            <v-btn class="mr-1" @click="viewDebitMemos()">View DM</v-btn>
                                            <v-btn @click="updateDebitMemos()">Update DM</v-btn>
                                        </v-col>

                                        <v-spacer />
                                        <v-col sm="12" md="2" class="d-flex justify-end mt-2 mb-3">
                                            <v-text-field
                                                v-model="discount_amount"
                                                label="Discount Amount"
                                                hide-details
                                                dense
                                                outlined
                                                type="text"
                                                @keypress="numberandDotOnly"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row class=" d-flex flex-row align-items-center ml-1">
                                        <v-col sm="12" md="4">
                                            <v-textarea
                                                v-model="deduction_remarks"
                                                :disabled="disableFields()"
                                                class="p-0"
                                                label="CM Remarks"
                                                rows="2"
                                                dense
                                                outlined
                                                hide-details
                                                no-resize
                                            ></v-textarea>
                                        </v-col>
                                        <v-col sm="12" md="2">
                                            <v-text-field
                                                v-model="deduction_amount_text"
                                                type="text"
                                                id="deduction_amount"
                                                autocomplete="off"
                                                class="ml-1"
                                                label="CM Amount"
                                                dense
                                                outlined
                                                hide-details
                                                filled
                                                readonly
                                                @keypress="decimalOnly"
                                            />
                                        </v-col>
                                        <v-col sm="12" md="2">
                                            <v-btn class="mr-1" @click="viewCreditMemos()">View CM</v-btn>
                                            <v-btn @click="updateCreditMemos()">Update CM</v-btn>
                                        </v-col>
                                    </v-row>

                                    <v-row class="mb-1">
                                        <v-col sm="12" md="12" class="pull-right text-right text-dark">
                                            Bad Debt Amount: &#x20b1;{{ bad_debt_amount | currency }}
                                        </v-col>
                                    </v-row>

                                    <v-row class="mb-1">
                                        <v-col sm="12" md="12" class="pull-right text-right text-dark">
                                            <span v-if="soaStatus == 0">
                                                Total Amount: &#x20b1;{{ thousandSeprator(computeGrandTotal) | currency }}
                                            </span>
                                            <span v-else>
                                                Total Amount: &#x20b1;{{ thousandSeprator(parseFloat(gross_total_amount) - parseFloat(deduction_amount) + parseFloat(addition_amount)) | currency }}
                                            </span>
                                        </v-col>
                                    </v-row>

                                    <v-row class="mb-1">
                                        <v-col sm="12" md="12" class="pull-right text-right text-dark">
                                            Paid Amount: &#x20b1;{{ allocatedAmount | currency }}
                                        </v-col>
                                    </v-row>

                                    <v-row class="mb-1">
                                        <v-col sm="12" md="12" class="pull-right text-right text-dark">
                                            <span v-if="soaStatus == 0">
                                                Balance Amount: &#x20b1;{{ thousandSeprator(balanceAmount) | currency }}
                                            </span>
                                            <span v-else>
                                                Balance Amount: &#x20b1;{{ thousandSeprator(parseFloat(raw_total_amount) - parseFloat(allocatedAmount)) | currency }}
                                            </span>
                                        </v-col>
                                    </v-row>

                                    <!-- <v-row class="mb-1">
                                        <v-col sm="12" md="12" class="pull-right text-right text-dark">
                                            Balance Amount: &#x20b1;{{ balanceAmount | currency }}
                                        </v-col>
                                    </v-row> -->

                                    <!-- <v-row class="mb-1">
                                        <v-col sm="12" md="12" class="pull-right text-right text-dark">
                                            Total Amount: &#x20b1;{{ computeGrandTotal | currency }}
                                        </v-col>
                                    </v-row> -->
                                </v-container>
                                <v-card-actions class="border-top">
                                    <div class="card-body py-4 d-flex justify-content-center">
                                        <v-btn @click="addNewStatementOfAccount()" v-if="action == 'Add'">Submit</v-btn>
                                        <v-btn @click="editStatementOfAccount()" v-else-if="action == 'Edit'">Update</v-btn>
                                    </div>
                                </v-card-actions>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="override_amount_dialog" persistent max-width="30%" scrollable>
                    <v-card>
                        <v-row class="m-0">
                            <v-col cols="pull-right-10 p-2">
                                <v-btn text icon color="gray" class="float-right" @click="override_amount_dialog = false;">
                                    <v-icon>mdi-close-circle</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-card-text>
                            <v-row class="my-2">
                                <v-col sm="12" md="6" class="m-auto text-center">
                                    <v-text-field
                                        type="text"
                                        outlined
                                        dense
                                        class="mt-2"
                                        id="override_amount"
                                        label="Override Amount"
                                        v-model="override_amount"
                                        @keypress="decimalOnly"
                                        autocomplete="off"
                                    />
                                </v-col>
                            </v-row>
                            <div class="border-top mt-2">
                                <div class="card-body py-4 d-flex justify-content-center">
                                    <v-btn :disabled="disableFields()" @click="confirmOverrideAmount()">Confirm</v-btn>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="approve_override_dialog" persistent max-width="50%" scrollable>
                    <v-card>
                        <v-row class="m-0">
                            <v-card-title>
                                <span class="headline">Confirm Override Amounts</span>
                            </v-card-title>
                            <v-col cols="pull-right-10 p-2">
                                <v-btn text icon color="gray" class="float-right"
                                    @click="approve_override_dialog = false; override_username = ''; override_user_password = ''; override_user_id = -1;">
                                    <v-icon>mdi-close-circle</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-card-text>
                            <v-row class="">
                                <v-col sm="12" md="6" class="">
                                    <label for="override_username"
                                        class="float-left control-label col-form-label">Username</label>
                                    <v-text-field outlined dense label="Username" type="text" class="form-control"
                                        id="override_username" placeholder="Username" v-model="override_username"
                                        autocomplete="new-password" />
                                    <span v-for="errors in errors.override_username" class="text-warning"
                                        v-bind:key="errors">{{ errors }}</span>
                                </v-col>
                                <v-col sm="12" md="6" class="">
                                    <label for="override_user_password"
                                        class="float-left control-label col-form-label">Password</label>
                                    <v-text-field outlined dense label="Password" type="password" class="form-control"
                                        id="override_user_password" placeholder="Password" v-model="override_user_password"
                                        autocomplete="new-password" />
                                    <span v-for="errors in errors.override_user_password" class="text-warning"
                                        v-bind:key="errors">{{ errors }}</span>
                                </v-col>
                            </v-row>
                            <div class="border-top">
                                <div class="card-body py-4 d-flex justify-content-center">
                                    <v-btn @click="approveOverrideAmounts()">Confirm</v-btn>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="view_debit_memos" max-width="40%" scrollable>
                    <v-card>
                        <v-row class="m-0">
                            <v-col class="pa-2">
                                <h5>Debit Memo</h5>
                            </v-col>
                            <v-col cols="pull-right-10 p-2">
                                <v-btn text icon color="gray" class="float-right" @click="view_debit_memos = false">
                                    <v-icon>mdi-close-circle</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-card-text class="p-0">
                            <v-data-table :headers="debit_memo_header" :items="debit_memos" class="w-100" disable-sort>
                                <template v-slot:[`item.total_amount`]="{ item }">
                                    <span class="text-nowrap">
                                        &#x20b1;{{ item.total_amount | currency }}
                                    </span>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="view_credit_memos" max-width="40%" scrollable>
                    <v-card>
                        <v-row class="m-0">
                            <v-col class="pa-2">
                                <h5>Credit Memo</h5>
                            </v-col>
                            <v-col cols="pull-right-10 p-2">
                                <v-btn text icon color="gray" class="float-right" @click="view_credit_memos = false">
                                    <v-icon>mdi-close-circle</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-card-text class="p-0">
                            <v-card max-height="500px" style="overflow: auto;">
                                <v-card-text>
                                    <v-data-table :headers="credit_memo_header" :items="credit_memos" class="w-100" disable-sort>
                                        <template v-slot:[`item.total_amount`]="{ item }">
                                            <span class="text-nowrap">
                                                &#x20b1;{{ item.total_amount | currency }}
                                            </span>
                                        </template>
                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            <ViewCustomerPaymentDetailComponentVue
                @closeViewPayment="closeViewPayment"
                :customerIds="customer_id"
                :fromSoa="true"
                :addEdit="true"
                :balanceAmounts="balanceAmount"
                :soa_id="editStatementOfAccountId"
                :view="true"
                :user_access="userAccess"
                :origin="'editSoa'"
            ></ViewCustomerPaymentDetailComponentVue>
            </v-app>
            <ConfirmOverrideComponentVue
                :approve_override_dialog="override.override_dialog"
                :departments_allowed="override.override_departments_allowed"
                :heading="''"
                @closeConfirmOverride="closeConfirmOverride"
            ></ConfirmOverrideComponentVue>
        </v-container>
    </div>
</template>

<script>
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
import StatementOfAccountsTableComponentVue from './StatementOfAccountsTableComponent.vue';
import AddEditCustomerPaymentDetailComponentVue from '../Payments/PaymentsComponent/AddEditCustomerPaymentDetailComponent.vue';
import ViewCustomerPaymentDetailComponentVue from '../Payments/PaymentsComponent/ViewCustomerPaymentDetailComponent.vue';
import CreditMemoComponentVue from '../CreditMemo/CreditMemoComponent.vue';
import ConfirmOverrideComponentVue from '@/views/main/Utils/ConfirmOverrideComponent.vue';
// import AddEditCustomerPaymentDetailComponent from '../Accounting/Views/PaymentsComponent/AddEditCustomerPaymentDetailComponent.vue';
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';

export default {
    mixins: [
        SharedFunctionsComponentVue
    ],
    components: {
        StatementOfAccountsTableComponentVue,
        AddEditCustomerPaymentDetailComponentVue,
        ViewCustomerPaymentDetailComponentVue,
        CreditMemoComponentVue,
        ConfirmOverrideComponentVue
    },
    mounted() {
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id);
        this.$store.dispatch('getAllCustomers')
    },
    props: [
        'status_value',
        'filtered_year',
        'filtered_month',
        'status_text',
        'months_selection',
        'date_from',
        'date_to',
        'week_count',
    ],
    data() {
        return {
            override_username: '',
            override_user_password: '',
            override_user_id: -1,
            override_amount: '',
            override_index: '',
            override_amount_dialog: false,
            approve_override_dialog: false,
            selected_item:null,

            editStatementOfAccountId: '',
            add_edit_statementOfAccount_dialog: false,
            customer_id: '',
            // cash_pl: 0,
            super_product: 0,
            packingOrderList: [],
            selected_date: '',
            customers_list: [],
            payment_method_list: [
                { text: 'Cash', value: 0 },
                { text: 'Check', value: 1 },
                { text: 'Deposit', value: 2 },
            ],
            contact: '',
            due_date: '',
            orig_due_date: '',
            terms: '',
            remarks: '',
            override_by: '',
            soa_items: [],
            soa_item: {
                packing_list_id: '',
                packing_list_num: '',
                sales_date: '',
                sales_total: '',
                remarks: '',
                bank: '',
                override_amount: '',
            },
            paid_amount: '',
            total_amount: '',
            payment_method: 0,
            payment_date: '',
            addition_amount: '',
            deduction_amount: '',
            addition_remarks: '',
            deduction_remarks: '',
            bad_debt_amount: '',
            discount_amount: '',
            errors: [],
            action: '',
            actionResult: {
                model: 'StatementOfAccount',
                action: ''
            },
            loaded: false,
            date_menu: false,
            date_modal: false,
            menu2: false,
            menu3: false,
            menu4: false,
            menu5: false,
            select_all: 0,
            max_items_count: 999,
            userAccess: {
                create: false,
                cancel_payment: false,
                confirm_payment: false,
                reject_payment: false,
                edit_payment: false,
            },

            debit_memos: [],
            view_debit_memos: false,
            debit_memo_header: [
                { text: 'DM#', align: 'center', value: 'debit_memo_num', width: '13%' },
                { text: 'DM Date', align: 'center', value: 'debit_memo_date', width: '13%' },
                { text: 'Remarks', align: 'left', value: 'remarks' },
                { text: 'Processed By', align: 'left', value: 'created_by_name', width: '14%' },
                { text: 'Total Amount', align: 'right', value: 'total_amount', width: '14%' },
            ],

            credit_memos: [],
            view_credit_memos: false,
            credit_memo_header: [
                { text: 'CM#', align: 'center', value: 'credit_memo_num', width: '13%' },
                { text: 'CM Date', align: 'center', value: 'credit_memo_date', width: '13%' },
                { text: 'Remarks', align: 'left', value: 'remarks' },
                { text: 'Processed By', align: 'left', value: 'created_by_name', width: '14%' },
                { text: 'Total Amount', align: 'right', value: 'total_amount', width: '14%' },
            ],
            isCashPayment: false,

            soaStatus: 0,
            paymentHeaders: [
                { text: 'Ref#', width: '84px', value: 'reference_number' },
                // { text: 'Customer', value: 'customer_name' },
                { text: 'Account Number', value: 'check_details' },
                { text: 'Bank', value: 'bank_name' },
                { text: 'Payment type', value: 'payment_type_name' },
                { text: 'Payment Name', value: 'cheque_name' },
                { text: 'Payment Date', value: 'payment_date' },
                { text: 'Gross Amount', value: 'gross_amount' },
                { text: 'WHT Amount', value: 'wht_amount' },
                { text: 'Allocate WHT', value: 'allocate_wht' },
                { text: 'ADJ Amount', value: 'other_adj_amount' },
                { text: 'Remaining Amount', value: 'remaining_amount' },
                // { text: 'Allocate Amount', value: 'allocate_amount' },
                { text: 'Created At', value: 'created_at' },
                { text: 'Status', value: 'status' },
                { text: 'Actions', value: 'actions' },
            ],
            paymentItems: [],
            expansionHeaders: [],
            plItems: [],
            headers: [
                { text: 'PL #', value: 'packing_list_num', width: '15%', align: 'center' },
                { text: 'PL Date', value: 'sales_date', width: '15%', align: 'center' },
                { text: 'PL Total', value: 'sales_total', width: '15%', align: 'center' },
                { text: 'Remarks', value: 'remarks', width: '55%', align: 'center' },
                { text: 'Action', value: 'actions', width: '10%', align: 'center' },
            ],
            expanded: [],
            expandedItems: [],
            loading: false,
            balanceAmount: 0,
            paymentsExpansion: {
                loading: false,
                expanded: [],
                items: [],
                headers: [
                    { text: 'SOA Id', align: 'left', value: 'id', },
                    { text: 'Soa Month', align: 'left', value: 'selected_month', },
                    { text: 'Due Month', align: 'left', value: 'due_date', },
                    { text: 'Gross Amount', align: 'left', value: 'gross_amount', },
                    { text: 'DM Amount', align: 'left', value: 'addition_amount', },
                    { text: 'CM Amount', align: 'left', value: 'deduction_amount', },
                    { text: 'Net Amount', align: 'left', value: 'total_amount', },
                    { text: 'Balance Amount', align: 'left', value: 'balance_amount', },
                    { text: 'Applied Amount', align: 'left', value: 'applied_amount', },
                    { text: 'Status', align: 'left', value: 'status', },
                    { text: 'Terms', align: 'left', value: 'terms', },
                    { text: 'Age (Day)', align: 'left', value: 'age', },
                ]
            },
            appliedPaymentHeaders: [
                { text: 'Ref #', value: 'reference_number', align: 'left' },
                { text: 'Account Number', value: 'check_details' },
                { text: 'Payment Type', value: 'payment_type_name', align: 'left' },
                { text: 'P-Type Date', value: 'payment_type_date', align: 'left' },
                { text: 'Bank', value: 'bank_name', align: 'left' },
                { text: 'Allocated Amount', value: 'allocated_amount', align: 'right' },
                { text: 'Remaining Amount', value: 'remaining_amount', align: 'right' },
                { text: 'Created At', value: 'created_at' },
                { text: 'Status', value: 'status' },
                { text: 'Actions', value: 'actions' },
            ],
            customerPaymentAllocations: [],
            hasRemaining: false,
            selected_date_formatted: '',
            due_date_formatted: '',
            raw_total_amount: '',
            override: {
                override_dialog: false,
                errors: [],
                override_departments_allowed: ['AC'],
                override_authenticated: false,
            },
            override_confirm: false,
            selected_payment_id: false,
            start_date: '',
            end_date: '',
            selected_cpd_id: null,

            dateFrom: null,
            dateTo: null,
            weekCount: '',
            adminTransactionDataTable:{
                headers:[
                    { text: 'Transaction #', value: 'transaction_number', width: '15%', align: 'center' },
                    { text: 'Transaction Date', value: 'transaction_date', width: '15%', align: 'center' },
                    { text: 'Transaction Total', value: 'transaction_total', width: '15%', align: 'center' },
                    { text: 'Remarks', value: 'remarks', width: '55%', align: 'center' },
                    { text: 'Action', value: 'actions', width: '10%', align: 'center' },
                ],
                items:[]
            },
            hasSoaBalanceChanged: false,
            gross_total_amount: ''
        }
    },
    methods: {
        reloadTable(dateFrom, dateTo, mode, weekCount) {
            this.dateFrom = dateFrom
            this.dateTo = dateTo

            this.$refs.childTable.filterByStatus(this.dateFrom, this.dateTo, mode, weekCount);
        },
        confirmOverrideAmount() {
            if(!!this.selected_item){
                this.adminTransactionDataTable.items[this.override_index].override_amount = 0

                var override_amount = this.override_amount.replace(/,/g, '');
                this.adminTransactionDataTable.items[this.override_index].override_amount = this.thousandSeprator((Math.round(override_amount * 100) / 100).toFixed(2))
                var orig = this.adminTransactionDataTable.items[this.override_index].transaction_total
                this.adminTransactionDataTable.items[this.override_index].transaction_total = this.adminTransactionDataTable.items[this.override_index].override_amount
                this.adminTransactionDataTable.items[this.override_index].transaction_total = orig
            }else{
                this.soa_items[this.override_index].override_amount = 0

                var override_amount = this.override_amount.replace(/,/g, '');
                this.soa_items[this.override_index].override_amount = this.thousandSeprator((Math.round(override_amount * 100) / 100).toFixed(2))
                var orig = this.soa_items[this.override_index].sales_total
                this.soa_items[this.override_index].sales_total = this.soa_items[this.override_index].override_amount
                this.soa_items[this.override_index].sales_total = orig
            }


            this.override_amount = ''
            this.override_index = ''

            this.override_amount_dialog = false
        },
        showOverrideAmountDialog(index,item = null) {
            this.override_amount = ''
            this.selected_item = item
            if(this.adminTransactionDataTable.items[index].transaction_number != null || this.adminTransactionDataTable.items[index].transaction_number != ''){
                this.override_index = index
                this.override_amount_dialog = true
                return
            } else {
                swal.fire("", "Nothing to override", "warning");
            }

            if (this.soa_items[index].packing_list_id != null || this.soa_items[index].packing_list_id != '') {
                this.override_index = index
                this.override_amount_dialog = true
                return
            } else {
                swal.fire("", "Nothing to override", "warning");
            }
        },
        removeOrderByIndex(index,variable) {
            if(variable == 'soa_items'){
                this.soa_items.splice(index, 1)
            } else {
                this.adminTransactionDataTable.items.splice(index, 1)
            }
        },
        addItem() {
            if (this.soa_items.length < this.max_items_count) {
                this.soa_items.push({
                    packing_list_id: '',
                    packing_list_num: '',
                    sales_date: '',
                    sales_total: '',
                    remarks: '',
                    bank: '',
                    override_amount: null,
                })
            }
        },
        addItem_admin() {
            if (this.adminTransactionDataTable.items.length < this.max_items_count) {
                this.adminTransactionDataTable.items.push({
                    transaction_number: '',
                    transaction_date: '',
                    transaction_total: '',
                    transaction_id: '',
                    transaction_type_id: '',
                    transaction_type: '',
                    remarks: '',
                    override_amount: null,
                })
            }
        },
        showAddStatementOfAccount() {
            this.add_edit_statementOfAccount_dialog = true
            this.action = 'Add'
        },
        addNewStatementOfAccount() {
            this.errors = []
            let payload = {
                customer_id: this.customer_id.value,
                // cash_pl:            this.cash_pl,
                super_product: this.super_product,
                due_date: this.due_date,
                contact: this.contact,
                terms: this.terms,
                remarks: this.remarks,
                soa_items: this.soa_items,
                admin_transactions: this.adminTransactionDataTable.items,
                override_by: this.override_by,
                selected_date: this.selected_date,
                paid_amount: this.paid_amount,
                discount_amount: this.discount_amount,
                total_amount: this.total_amount,
                payment_method: this.payment_method,
                payment_date: this.payment_date,
                addition_amount: this.addition_amount,
                deduction_amount: this.deduction_amount,
                addition_remarks: this.addition_remarks,
                deduction_remarks: this.deduction_remarks,
                debit_memos: this.debit_memos,
                credit_memos: this.credit_memos,
                cash: this.isCashPayment,
            }

            this.$store.dispatch('postStatementOfAccounts', payload).then(response => {
                let data = response.data
                if (data.error) {
                    swal.fire("", data.error, "warning");
                    return false
                }
                this.resetFields()
                this.add_edit_statementOfAccount_dialog = false
                this.action = 'success'
                swal.fire("", response.data.msg, "success").then((value) => {

                });
            }).catch(error => {
                if (error.response.status == 422) {
                    this.errors = error.response.data.errors
                }
            });
        },
        showEditModalBalance(value) {
            this.balanceAmount = value;
        },
        showEditModal(value, show_swal = true) {
            if(show_swal){
                swal.fire({
                    title: 'Loading Please wait...',
                    icon: 'info',
                    allowOutsideClick: false,
                });
                swal.showLoading();
                this.action = 'Edit'
                this.editStatementOfAccountId = value
            }
            if (this.editStatementOfAccountId != null && this.editStatementOfAccountId > 0) {


                this.$store.dispatch('getSoa', this.editStatementOfAccountId).then(response => {

                    this.soa_items = [];
                    let data = this.GET_SOA
                    this.customer_id = data.soa.customer_id
                    // this.cash_pl                = data.soa.cash_pl
                    this.terms = data.soa.terms
                    this.remarks = data.soa.remarks
                    this.due_date = data.soa.due_date
                    this.contact = data.soa.contact
                    this.paid_amount = data.soa.paid_amount
                    this.total_amount = data.soa.total_amount
                    this.payment_method = data.soa.payment_method
                    this.payment_date = data.soa.payment_date
                    this.addition_amount = data.soa.addition_amount
                    this.deduction_amount = data.soa.deduction_amount
                    this.addition_remarks = data.soa.addition_remarks
                    this.deduction_remarks = data.soa.deduction_remarks
                    this.discount_amount = this.thousandSeprator(this.getTwoDecimals(parseFloat(data.soa.discount_amount)))
                    this.debit_memos = data.debit_memos
                    this.credit_memos = data.credit_memos
                    this.isCashPayment = data.soa.cash
                    this.soaStatus = data.soa.status
                    this.bad_debt_amount = data.soa.bad_debt_amount

                    this.raw_total_amount = data.soa.total_amount
                    this.gross_total_amount = data.soa.gross_amount

                    if (this.due_date != null) {
                        this.due_date = new Date(this.due_date).toISOString().substr(0, 7)
                    }
                    if (data.soa.selected_year != null && data.soa.selected_month != null) {
                        // if(data.soa.selected_month.toString().length == 1){
                        //     data.soa_selected_month = '0' + data.soa_selected_month.toString().padStart(2,'0')
                        // }
                        this.selected_date = data.soa.selected_year + '-' + data.soa.selected_month.toString().padStart(2, '0');
                    }
                    this.selected_date_formatted = this.formatMonthSoa(this.selected_date);
                    this.due_date_formatted = this.formatMonthSoa(this.due_date);
                    let packingList = data.soa.statement_of_account_items
                    if (packingList != null && packingList.length > 0) {
                        packingList.forEach((item, index) => {
                            this.addItem()

                            this.soa_items[index].packing_list_id = item.packing_list_id
                            this.soa_items[index].packing_list_num = item.packing_list_num
                            this.soa_items[index].sales_date = item.sales_date
                            this.soa_items[index].sales_total = this.thousandSeprator((Math.round(item.sales_total * 100) / 100).toFixed(2))
                            this.soa_items[index].override_amount = item.override_amount == null ? null : this.thousandSeprator((Math.round(item.override_amount * 100) / 100).toFixed(2))
                            this.soa_items[index].remarks = item.remarks
                            this.soa_items[index].bank = item.bank
                        })
                    }
                    let admin_soa = data.soa.statement_of_account_items_admins
                    if (admin_soa != null && admin_soa.length > 0) {
                        admin_soa.forEach((item, index) => {
                            this.addItem_admin()
                            this.adminTransactionDataTable.items[index].transaction_number = item.transaction_number
                            this.adminTransactionDataTable.items[index].transaction_date = item.sales_date
                            this.adminTransactionDataTable.items[index].transaction_total = this.thousandSeprator((Math.round(item.sales_total * 100) / 100).toFixed(2))
                            this.adminTransactionDataTable.items[index].remarks = item.remarks
                            this.adminTransactionDataTable.items[index].override_amount = item.override_amount == null ? null : this.thousandSeprator((Math.round(item.override_amount * 100) / 100).toFixed(2))
                            this.adminTransactionDataTable.items[index].transaction_id = item.transaction_id
                            this.adminTransactionDataTable.items[index].transaction_type_id = item.transaction_type_id
                            this.adminTransactionDataTable.items[index].transaction_type = item.transaction_type_id
                        })
                    }

                    this.add_edit_statementOfAccount_dialog = true
                    swal.close()
                }).catch(error => {
                    swal.fire({
                        title: 'Error Occured',
                        icon: 'warning',
                        allowOutsideClick: false,
                    });
                });
            }
        },
        editStatementOfAccount() {
            this.errors = []
            if(this.hasSoaBalanceChanged){
                swal.fire({
                    title: "There are changes on SOA Information!",
                    text: "Please view the SOA again!",
                    icon: "warning",
                    allowOutsideClick: false
                }).then(result=>{
                    if(result.isConfirmed){
                        this.closeDialog();
                        this.action = 'success'
                    }
                })
                return false;
            }
            let balance_amount = parseFloat(this.raw_total_amount) - parseFloat(this.allocatedAmount);
            let payload = {
                id: this.editStatementOfAccountId,
                params: {
                    customer_id: this.customer_id,
                    // cash_pl:            this.cash_pl,
                    super_product: this.super_product,
                    due_date: this.due_date,
                    contact: this.contact,
                    terms: this.terms,
                    remarks: this.remarks,
                    soa_items: this.soa_items,
                    soa_items_admin: this.adminTransactionDataTable.items,
                    override_by: this.override_by,
                    selected_date: this.selected_date,
                    paid_amount: this.paid_amount,
                    discount_amount: this.discount_amount,
                    total_amount: this.total_amount,
                    payment_method: this.payment_method,
                    payment_date: this.payment_date,
                    addition_amount: this.addition_amount,
                    deduction_amount: this.deduction_amount,
                    addition_remarks: this.addition_remarks,
                    deduction_remarks: this.deduction_remarks,
                    debit_memos: this.debit_memos,
                    credit_memos: this.credit_memos,
                    cash: this.isCashPayment,
                    payments: this.collectedPayments,
                    balance_amount: this.soaStatus == 0 ? this.computeGrandTotal : balance_amount,
                    saved_payments: this.savedPayments
                }
            }

            this.$store.dispatch('updateSoa', payload).then(response => {
                // if(this.computeGrandTotal <= 0){
                //     let payload_status = {
                //         id:this.editStatementOfAccountId,
                //         params: {
                //             status: 7
                //         }
                //     }
                //     this.$store.dispatch('updateSoaStatus',payload_status)
                // }
                let data = response.data
                if (data.error) {
                    swal.fire("", data.error, "warning");
                    return false
                }
                this.resetFields()
                this.add_edit_statementOfAccount_dialog = false
                this.editStatementOfAccountId = 0
                this.action = 'success'
                swal.fire("", response.data.msg, "success").then((value) => {

                });
            }).catch(error => {
                if (error.response.status == 422) {
                    this.errors = error.response.data.errors
                }
                this.action = 'Edit'
            });
        },
        async getPLByCustomer(payload) {

            await this.$store.dispatch('getPLByCustomer', payload).then(response => {
                    let data = this.GET_PL_BY_CUSTOMER
                    this.soa_items = data.packingLists
                    this.due_date = data.due_date_plus_terms
                    this.orig_due_date = data.due_date
                    this.terms = data.terms
                    this.selected_date_formatted = this.formatMonthSoa(this.selected_date);
                    this.due_date_formatted = this.formatMonthSoa(this.due_date);
                    this.soa_items.forEach((item, index) => {
                        if (item.sales_total != null && item.sales_total != '') {
                            this.soa_items[index].sales_total = this.thousandSeprator((Math.round(item.sales_total * 100) / 100).toFixed(2))
                        }
                    })
                })

                await this.getDebitMemoByCustomer()
                await this.getCreditMemoByCustomer()


            return
        },
        async getDebitMemoByCustomer() {
            if (this.customer_id != null && this.customer_id != '' && this.selected_date != null && this.selected_date != '') {
                let payload = {
                    id: this.action == 'Add' ? this.customer_id.value : this.customer_id,
                    params: {
                        selected_date: this.selected_date,
                        soa_id: this.action == 'Add' ? null : this.editStatementOfAccountId,
                        id: this.action == 'Add' ? this.customer_id.value : this.customer_id,
                    }
                }

                this.$store.dispatch('getDebitMemoByCustomer', payload).then(response => {
                    let data = this.GET_DEBIT_MEMO_BY_CUSTOMER
                    this.debit_memos = data.debit_memos
                    this.addition_amount = data.total_debit_memo_amount
                })
            }
        },
        viewDebitMemos() {
            this.view_debit_memos = true
        },
        updateDebitMemos() {
            this.getDebitMemoByCustomer()
        },
        async getCreditMemoByCustomer() {
            if (this.customer_id != null && this.customer_id != '' && this.selected_date != null && this.selected_date != '') {
                let payload = {
                    id: this.action == 'Add' ? this.customer_id.value : this.customer_id,
                    params: {
                        selected_date: this.selected_date,
                        soa_id: this.action == 'Add' ? null : this.editStatementOfAccountId,
                        id: this.action == 'Add' ? this.customer_id.value : this.customer_id,
                    }
                }

                this.$store.dispatch('getCreditMemoByCustomer', payload).then(response => {
                    let data = this.GET_CREDIT_MEMO_BY_CUSTOMER
                    this.credit_memos = data.credit_memos
                    this.deduction_amount = data.total_credit_memo_amount
                })
            }
        },
        viewCreditMemos() {
            this.view_credit_memos = true
        },
        updateCreditMemos() {
            this.getCreditMemoByCustomer()
        },
        resetFields() {
            this.errors = []
            this.customer_id = ''
            this.selected_date = ''
            this.contact = ''
            this.due_date = ''
            this.orig_due_date = ''
            this.terms = ''
            this.remarks = ''
            this.packingOrderList = []
            this.soa_items = []
            this.adminTransactionDataTable.items = []
            this.override_user_id = -1
            this.override_amount = ''
            this.override_index = ''
            this.override_username = ''
            this.override_user_password = ''
            this.paid_amount = ''
            this.total_amount = ''
            this.payment_method = ''
            this.payment_date = ''
            this.addition_amount = ''
            this.deduction_amount = ''
            this.addition_remarks = ''
            this.deduction_remarks = ''
            this.discount_amount = ''
            this.soaStatus = 0
            // this.cash_pl                = 0
            this.super_product = 0
            this.paymentItems = []
            this.selected_date_formatted = ''
            this.due_date_formatted = ''
            this.customerPaymentAllocations = []
            this.start_date = []
            this.end_date = []
            this.hasSoaBalanceChanged = false;
        },
        disableFields() {
            if (this.action == 'Edit') {
                let checker = [1, 2, 3, 4, 5, 6, 7];
                if (checker.includes(this.soaStatus)) {
                    return true;
                }
            }

            return false;
        },
        soaItemCardTableStyle() {
            if (this.soa_items.length > 10 || this.adminTransactionDataTable.items.length > 10) {
                return 'overflow:auto;height:500px';
            }

            return '';
        },
        showPayment(card) {
            let checker = []
            if (card == 'P') {
                checker = [5, 6, 9, 8];
            }
            else {
                checker = [6, 9, 8];
            }

            if (checker.includes(this.soaStatus)) {
                return true;
            }

            return false;
        },
        enableCustomerPaymentAllocation(item) {
            item.checked = true;
            if (!!item.checked) {
                if (parseFloat(item.remaining_amount) > parseFloat(this.computeGrandTotal.replaceAll(',', '')).toFixed(2)) {
                    this.$store.dispatch('getCustPaymentAllocationLimit', {id: item.id, soa_id: this.editStatementOfAccountId}).then(response=>{
                        item.allocate_amount = response.data
                        // item.allocate_amount = parseFloat(this.computeGrandTotal.replaceAll(',', '')).toFixed(2)
                    })
                }
                else if (this.total_amount > 0) {
                    item.allocate_amount = item.remaining_amount;
                } else {
                    item.allocate_amount = 0;
                }
            } else {
                item.allocate_amount = 0;
                item.allocate_wht = 0;
                item.wht_checked = false;
            }
        },
        getCustomerPaymentList() {
            this.getViewCustomerPaymentDetail()
            let payload = {
                customer_id: this.customer_id,
                soa_id: this.editStatementOfAccountId
            }
            this.$store.dispatch('getCustomerPaymentList', payload).then(response => {
                let data = this.GET_CUSTOMER_PAYMENT_LIST
                let modified = [];
                if (data.length > 0) {
                    data.forEach(x => {
                        modified.push(
                            Object.assign({
                                allocate_amount: '0',
                                checked: false,
                                allocate_wht: '0',
                                wht_checked: false,
                                counted: false
                            }, x)
                        )
                    });
                }

                this.paymentItems = modified;
                this.hasRemaining = false
                this.paymentItems.forEach(e => {
                    if (e.status == 5 || e.status == 10 || e.status == 6 || e.status == 11 || e.status == 3 || e.status == 0 || e.status == 12) {
                        e.checked = false
                        if (e.status == 10) {
                            this.hasRemaining = true
                        }
                    }
                    else {
                        this.enableCustomerPaymentAllocation(e)
                        this.hasRemaining = false
                    }
                })
            });
        },
        loadDetails(item) {
            this.loading = true
            this.expandedItems = []

            if(item.item.hasOwnProperty('transaction_type')){
                this.expansionHeaders = [
                    { text: 'Name', value: 'name' },
                    { text: 'Quantity', value: 'quantity' },
                    { text: 'Amount', value: 'amount' },
                    { text: 'Total Amount', value: 'total_amount' },
                    { text: 'FOC', value: 'foc' }]
                this.$store.dispatch("jobOrderGet", {
                    url:'soaGetAdminTransactionsById',
                    ...item.item
                }).then(response => {
                    this.expandedItems = response
                    this.loading = false
                })
            }else{
                this.expansionHeaders = [{ text: 'Model', value: 'model' },
                    { text: 'Name', value: 'name' },
                    { text: 'Uom', value: 'code' },
                    { text: 'Quantity', value: 'quantity' },
                    { text: 'Amount', value: 'amount' },
                    { text: 'Total Amount', value: 'total_amount' },
                    { text: 'FOC', value: 'foc' }]

                let payload = {
                    id: item.item.packing_list_id
                }
                this.$store.dispatch("soaGetPlById", payload).then(response => {
                    this.expandedItems = response.data
                    this.loading = false
                })
            }


        },
        viewCustomerPayment(id) {
            this.$store.commit('custPaymentDetailSetId', id);
            this.$store.commit('custPaymentDetailShow');
        },
        date(item) {
            if (!!item) {
                return this.$dayjs(item.created_at).format('YYYY-MM-DD');
            }
            return '';
        },
        getSoaByPaymentDetailId(item) {
            if (item.item.status != 9) {
                let payload = {
                    id: item.item.id,
                }
                this.$store.dispatch('getSoaByPaymentDetailId', payload).then(response => {
                    this.paymentsExpansion.items = response.data
                })
            }

        },
        soa_month(month, year) {
            this.months_selection.forEach((e, i) => {
                if (e.value == month) {
                    month = e.value
                }
            })
            return `${year}-${month}`;
        },
        agingDate(due_date) {
            if (!!due_date) {
                let startDay = this.$dayjs(due_date);

                let endDay = this.$dayjs();

                let days = this.$dayjs(endDay).diff(this.$dayjs(startDay), 'day');
                if (days <= 0) {
                    days = 0
                    return days
                }
                else {
                    return days
                }
            }
            return '';
        },
        allocateRemainingBalance(item) {
            swal.fire({
                title: "",
                text: "Are you sure you want to allocate remaining amount?",
                icon: 'question',
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                allowOutsideClick: false,
                confirmButtonColor: '#397373',
                cancelButtonColor: 'grey',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCloseButton: true,
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        let payload = {
                            id: item.id,
                            soa_id: this.editStatementOfAccountId
                        }
                        this.$store.dispatch('allocateRemainingBalance', payload).then(response => {
                            let data = response.data
                            if (data.error) {
                                swal.fire("", data.error, "warning");
                                return false
                            }
                            swal.fire("", response.data.msg, "success").then((value) => {
                                this.getCustomerPaymentList();
                            });

                        })
                    }
                });
        },
        getViewCustomerPaymentDetail() {
            let payload = {
                id: this.editStatementOfAccountId
            }
            this.$store.dispatch('getPaymentAllocationBySoaId', payload).then(response => {
                this.customerPaymentAllocations = response.data;

                this.customerPaymentAllocations.forEach(x => {
                    let payment_type_date = ''
                    if (x.payment_type_id == 1) {
                        payment_type_date = x.payment_date
                    }
                    else if (x.payment_type_id == 2) {
                        payment_type_date = x.cheque_date
                    }
                    else if (x.payment_type_id == 3) {
                        payment_type_date = x.account_date === null ? x.payment_date : x.account_date
                    }
                    else if (x.payment_type_id == 4) {
                        payment_type_date = x.online_date
                    }
                    else if (x.payment_type_id == 5) {
                        payment_type_date = x.card_date
                    }
                    Object.assign(x, {
                        payment_type_date: payment_type_date
                    });
                })

            });
        },
        formatMonthSoa(value) {
            if (value) {
                return this.$dayjs(String(value)).format('MMM YYYY')
            }
        },
        cancelPayment(id) {
            swal.fire({
                title: "",
                text: "Are you sure you want cancel this payment?",
                icon: 'question',
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                allowOutsideClick: false,
                confirmButtonColor: '#397373',
                cancelButtonColor: 'grey',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCloseButton: true,
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.selected_payment_id = id
                        // this.selected_cpd_id = cpd_id
                        this.override.override_dialog = true;
                    }
                })
        },
        async closeConfirmOverride(user_id, authenticated = false) {
            if (authenticated) {
                document.activeElement.blur();
                this.override.override_dialog = false;
                this.override_confirm = true;
                this.add_edit_statementOfAccount_dialog = false;
                swal.fire({
                    title: 'Cancel Reason Remarks',
                    input: 'text',
                    inputLabel: 'Enter Reason',
                    showConfirmButton: true,
                    confirmButtonColor: '#397373',
                    confirmButtonText: 'Confirm',
                    inputValidator: (value) => {
                        if (!value) {
                            return 'You need to write something!'
                        }
                    },
                    allowOutsideClick: () => {
                        const popup = swal.getPopup()
                        popup.classList.remove('swal2-show')
                        setTimeout(() => {
                            popup.classList.add('animate__animated', 'animate__headShake')
                        })
                        setTimeout(() => {
                            popup.classList.remove('animate__animated', 'animate__headShake')
                        }, 500)
                        return false
                    }
                }).then((result) => {
                    if (result.isConfirmed && result.value != '') {
                        let payload = {
                            id: this.selected_payment_id,
                            cancel_reason: result.value,
                            soa_id: this.editStatementOfAccountId,
                            cpd_id: this.selected_payment_id
                        }
                        this.$store.dispatch('cancelCustomerPaymentAllocations', payload).then(response => {
                            swal.fire(response.data.msg, '', response.data.status)
                            this.viewStatementOfAccountId = 0
                        })

                        // let payload = {
                        //     id: this.selected_payment_id,
                        //     cancel_reason:result.value,
                        //     soa_id:this.editStatementOfAccountId
                        // }
                        // this.$store.dispatch('cancelSelectedPayment',payload).then(response => {
                        //     swal.fire(response.data.msg,'',response.data.status)
                        //     this.resetFields();
                        //     // this.getCustomerPaymentList();
                        // })
                    }
                })

            } else {
                this.override.override_dialog = false;
            }
        },
        changeDateFormat() {
            this.selected_date_formatted = this.formatMonthSoa(this.selected_date);
            this.getTermsByCustomer()
        },
        getTermsByCustomer() {
            let payload = {
                id: this.action == 'Add' ? this.customer_id.value : this.customer_id,
                selected_date: this.selected_date
            }
            this.$store.dispatch('getTermsByCustomer', payload).then(response => {
                this.due_date_formatted = this.formatMonthSoa(response.data.due_date);
                this.terms = response.data.terms
            })
        },
        editCustomerPayment(id) {
            this.$store.commit('custPaymentDetailSetId', id);
            this.$store.commit('custPaymentDetailEditDialogAction');
            this.$store.commit('custPaymentDetailShowAddEditDialog');
        },
        async generate(){
            if(this.customer_id != null && this.customer_id != '' && this.selected_date != null && this.selected_date != ''){
                let payload = {
                    id: this.action == 'Add' ? this.customer_id.value : this.customer_id,
                    params: {
                        customer_id: this.action == 'Add' ? this.customer_id.value : this.customer_id,
                        selected_date: this.selected_date,
                        date: [this.start_date, this.end_date],
                        // cash_pl:                 this.cash_pl,
                        super_product: this.super_product,
                        check_existing: this.action == 'Add' ? 'yes' : null
                    }
                }

                await this.getPLByCustomer(payload)
                await this.getAdminTransations(payload)
            }

        },
        async getAdminTransations(payload){
            payload.url = 'get-admin-soa-transactions'
            await this.$store.dispatch('jobOrderGet',{
                url:payload.url,
                check_existing: 'yes',
                ...payload.params
            }).then(response=>{
                this.adminTransactionDataTable.items = response.map(e=>{
                    e.transaction_total = this.thousandSeprator((Math.round(e.transaction_total * 100) / 100).toFixed(2))
                    return e
                })
            })
            return
        },
        closeViewPayment(value){
            this.getCurrentBalance(value);
            this.paymentItems = []
            this.getCustomerPaymentList();
            this.updateCreditMemos();
            this.showEditModal(value, false);
        },
        getCurrentBalance(soa_id){
            let balance_amount = this.raw_total_amount - this.allocatedAmount;
            let payload = {
                soa_id: soa_id,
                balance_amount: balance_amount
            }
            this.$store.dispatch('getSOACurrentBalance', payload).then(response=>{
                this.hasSoaBalanceChanged = response.data;
            })
        },
        closeDialog(){
            this.add_edit_statementOfAccount_dialog = false;
            this.resetFields();
            this.$emit('closeModal');
        }
    },
    watch: {
        'date_from': {
            handler(val) {
                this.dateFrom = val
            },
            deep: true,
        },
        'date_to': {
            handler(val) {
                this.dateTo = val
            },
            deep: true,
        },
        'week_count': {
            handler(val) {
                this.weekCount = val
            },
            deep: true,
        },
        add_edit_statementOfAccount_dialog: function () {
            if (this.add_edit_statementOfAccount_dialog == true) {
                if (this.action == 'Add') {

                    this.packingOrderList = []
                    this.loaded = true
                }
            }
        },
        customer_id: function (value) {
            if (this.add_edit_statementOfAccount_dialog == true) {
                this.loaded = false
                if (value > 0) {
                    if (this.action == 'Add') {
                        this.getPLByCustomer()
                    } else if (this.action == 'Edit' && this.soaStatus != 0) {
                        this.getCustomerPaymentList()
                    }

                    this.loaded = true

                }
            }
        },
        terms: function (value) {
            if (value != '' && this.orig_due_date != '') {
                var computedDueDate = new Date(this.orig_due_date)

                if (computedDueDate != null) {
                    computedDueDate.setDate(computedDueDate.getDate() + parseInt(value));
                    this.due_date = computedDueDate.toISOString().substr(0, 7)
                } else {
                    this.due_date = ''
                }
            }
        },
        successAddEditPayment() {
            if (!!this.successAddEditPayment) {
                this.paymentItems = []
                this.getCustomerPaymentList();
                this.updateCreditMemos();
            }
        },
        USER_ACCESS: {
            handler(val) {
                this.userAccess.create = false;
                if (val != "fail") {
                    val.forEach((e) => {
                        if (e.actions_code == "create") {
                            this.userAccess.create = true;
                        }
                        if (e.actions_code == "cancel_payment") {
                            this.userAccess.cancel_payment = true
                        }
                        if (e.actions_code == "reject_payment") {
                            this.userAccess.reject_payment = true
                        }
                        if (e.actions_code == "confirm_payment") {
                            this.userAccess.confirm_payment = true
                        }
                        if (e.actions_code == "edit_payment") {
                            this.userAccess.edit_payment = true
                        }
                    });
                }
            }
        },
    },
    computed: {
        ...mapGetters([
            'USER_ACCESS',
            'GET_SOA',
            'GET_CUSTOMER_LIST_RAW',
            'GET_PL_BY_CUSTOMER',
            'GET_DEBIT_MEMO_BY_CUSTOMER',
            'GET_CREDIT_MEMO_BY_CUSTOMER',
            'GET_CUSTOMER_PAYMENT_LIST',
            'GET_CUSTOMERS_LIST_SELECTION',
            'GET_BANKS',
            'GET_ALL_PAYMENT_TYPES',
            'GET_ACCOUNTING_USERS',
        ]),
        addition_amount_text() {
            return this.getTwoDecimals(this.addition_amount)
        },
        deduction_amount_text() {
            return this.getTwoDecimals(this.deduction_amount)
        },
        computeGrandTotal() {
            var grand_total = 0;
            var discount_amount = 0;
            if (this.soa_items != null && this.soa_items.length > 0) {
                this.soa_items.forEach(function (item) {
                    if (item.override_amount != null) {
                        grand_total += parseFloat(item.override_amount.replace(/,/g, ''));
                    } else {
                        grand_total += parseFloat(item.sales_total.replace(/,/g, ''));
                    }
                });
            }

            if (this.adminTransactionDataTable.items != null && this.adminTransactionDataTable.items.length > 0) {
                this.adminTransactionDataTable.items.forEach(function (item) {
                    grand_total += parseFloat(item.transaction_total.replace(/,/g, ''));
                });
            }

            if (this.addition_amount != '') {
                grand_total += parseFloat(this.addition_amount.replace(/,/g, ''));
            }

            if (this.deduction_amount != '') {
                grand_total -= parseFloat(this.deduction_amount.replace(/,/g, ''));
            }

            if (!!this.isCashPayment) {
                discount_amount = grand_total * 0.05
                grand_total = grand_total - discount_amount
            }

            if (this.action == 'Edit') {
                if (parseFloat(this.paid_amount) > 0) {
                    grand_total = parseFloat(grand_total.toFixed(2)) - parseFloat(this.paid_amount) - parseFloat(this.bad_debt_amount);
                }

                if (this.allocatedAmount > 0 || this.allocatedWht > 0) {
                    grand_total = parseFloat(grand_total.toFixed(2)) - this.allocatedAmount - this.allocatedWht;
                }

                if (this.bad_debt_amount != '') {
                    grand_total = parseFloat(grand_total.toFixed(2)) - parseFloat(this.bad_debt_amount.replace(/,/g, ''));
                }
            }

            this.discount_amount = discount_amount
            this.total_amount = grand_total
            this.balanceAmount = this.thousandSeprator(grand_total)
            return this.thousandSeprator(grand_total)

        },
        successAddEditPayment() {
            return this.$store.state.accounting.customerPaymentDetail.successAddEdit;
        },
        allocatedAmount() {
            let totalCheckedPayment = 0

            let checkedPayments = this.paymentItems.filter(x => x.checked);
            totalCheckedPayment = _.sum(checkedPayments.map(x => parseFloat(x.allocate_amount)));
            return totalCheckedPayment + parseFloat(this.paid_amount);
        },
        allocatedWht() {
            let totalWht = 0
            let checkedWhtPayments = this.paymentItems.filter(x => x.wht_checked);
            totalWht = _.sum(checkedWhtPayments.map(x => parseFloat(x.allocate_wht)));
            return totalWht;
        },
        collectedPayments() {
            return this.paymentItems.filter(x => x.checked && x.allocate_amount > 0);
        },
        savedPayments() {
            return this.paymentItems.filter(x => x.status == 0);
        }
    }
};
</script>
