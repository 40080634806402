<template>
    <div class="tabs-body-color">
        <v-container fluid>
            <v-row class="pt-2">
                <v-col class="d-flex justify-space-between">
                    <v-row class="pt-2">
                        <v-col cols="6" class="d-flex no-block align-items-center">
                            <!-- <div class="btn-toolbar">
                                <v-tooltip bottom color="#f69f1a">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            v-if="isActive_tab != 'SO'"
                                            @click="AddDialog()"
                                            fab
                                            text
                                            small
                                            class="mx-3"
                                            color="#f69f1a"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                        <v-icon>mdi-plus</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Add</span>
                                </v-tooltip>
                            </div> -->
                        </v-col>
                    </v-row>
                    <v-tooltip bottom color="#f69f1a">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                @click="exportStagingAreaDetails(GET_SPARE_PARTS_AREA_TAB)"
                                fab
                                text
                                small
                                class="mr-4"
                                color="#f69f1a"
                                :loading="exportLoading"
                                v-bind="attrs"
                                v-on="on"
                            >
                            <v-icon>mdi-cloud-download</v-icon>
                            </v-btn>
                        </template>
                        <span>Export</span>
                    </v-tooltip>
                </v-col>
            </v-row>
            <v-row>
                <SparePartsStagingAreaTableComponent :isActive_tab="isActive_tab" v-if="isActive_tab == isActive_tab"></SparePartsStagingAreaTableComponent>
            </v-row>
        </v-container>

        <v-dialog v-model="addSalesQuotationDialog" persistent max-width="98%" scrollable>
            <v-card>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">{{ "Spare Parts Quotation" }}</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeDialog(); resetFields(); $emit('closeModal');">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-container fluid style="background-color: white; overflow-y: scroll">
                        <v-col>
                            <v-card-text v-if="loaded">
                                <form class="form-horizontal" action="/salesquotations" method="post" @submit.prevent="onSubmit">
                                    <div class="card-body">
                                        <v-row class="m-0 text-dark" v-if="action == 'Edit'">
                                            <v-col cols="12" class="text-left">
                                                <v-row>
                                                    <v-col sm="12" md="9" class="p-0"></v-col>
                                                    <v-col sm="12" md="3" class="p-0">{{ `${service == 1 ? "Spare Parts" : "Sales"} Quotation No: ${this.quote_num}` }}</v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col sm="12" md="9" class="p-0"></v-col>
                                                    <v-col sm="12" md="3" class="p-0">Issuer: {{ this.issuer_name }}</v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col sm="12" md="9" class="p-0"></v-col>
                                                    <v-col sm="12" md="3" class="p-0">Date Issued: {{ this.created_at | formatDateTime }}</v-col>
                                                </v-row>
                                                <div class="row">
                                                    <v-col sm="12" md="9" class="p-0"></v-col>
                                                    <v-col sm="12" md="3" class="p-0">Promo: {{ this.promo.name }}</v-col>
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col sm="2" class="d-flex">
                                                <!-- <label for="customer_id" class="float-left control-label col-form-label">Promo</label> -->
                                                <v-autocomplete
                                                    v-model="promo_id"
                                                    :items="promo_list"
                                                    :disabled="action == 'Edit'"
                                                    dense
                                                    outlined
                                                    hide-details
                                                    auto-select-first
                                                    item-value="value"
                                                    item-text="text"
                                                    label="Select Promo"
                                                    class="d-inline-block small"
                                                ></v-autocomplete>
                                                <span v-for="errors in errors.promo_id" class="text-warning" :key="errors">{{ errors }}</span>
                                            </v-col>
                                            <v-col sm="2" class="d-flex">
                                                <!-- <label for="customer_id" class="control-label col-form-label">Delivery Method</label> -->
                                                <v-autocomplete
                                                    v-model="delivery_method"
                                                    :items="delivery_method_list"
                                                    :disabled="service == 1"
                                                    dense
                                                    outlined
                                                    hide-details
                                                    auto-select-first
                                                    item-value="value"
                                                    item-text="text"
                                                    label="Select Delivery Method"
                                                    class="d-inline-block small"
                                                ></v-autocomplete>
                                                <span v-for="errors in errors.promo_id" class="text-warning" :key="errors">{{ errors }}</span>
                                            </v-col>
                                            <v-col sm="12" md="4" class="d-flex align-items-center" style="gap: 10px">
                                                <v-tooltip bottom color="#f69f1a">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn @click="showFileUpload()"
                                                            fab
                                                            text
                                                            small
                                                            color="#f69f1a"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            <v-icon>mdi-attachment</v-icon>
                                                            <span style="color:red"> * </span>
                                                        </v-btn>
                                                    </template>
                                                    <span>File Upload</span>
                                                </v-tooltip>
                                                <!-- <v-switch v-model="stock_card" :disabled="service == 1" hide-details dense
                                                    label="Stock Card" color="primary" input-value="1" class="m-0 d-flex align-items-center"></v-switch>
                                                <v-switch v-model="has_promo" :disabled="service == 1" hide-details dense
                                                    label="Has Promo" color="primary" :true-value="1" :false-value="0" class="m-0 d-flex align-items-center"></v-switch> -->
                                            </v-col>
                                        </v-row>
                                        <v-row class="mb-2">
                                            <v-col sm="4" class="d-flex">
                                                <v-autocomplete
                                                    v-model="customer_id"
                                                    :items="filtered_customers_list"
                                                    :disabled="service == 1"
                                                    dense
                                                    outlined
                                                    hide-details
                                                    auto-select-first
                                                    item-value="value"
                                                    item-text="text"
                                                    label="Select Customer"
                                                    class="d-inline-block small">
                                                    <template v-slot:selection="data">
                                                        <v-chip x-small rounded left :color="data.item.color"></v-chip>
                                                        <span class="ml-2">{{ data.item.text }}</span>
                                                    </template>
                                                    <template v-slot:item="data">
                                                        <v-tooltip color="black" bottom>
                                                            <template #activator="{ on }">
                                                                <v-chip v-on="on" x-small rounded left :color="data.item.color"></v-chip>
                                                                <span class="ml-2">{{ data.item.text }}</span>
                                                            </template>
                                                            <span>{{ data.item.description }}</span>
                                                        </v-tooltip>
                                                    </template>
                                                </v-autocomplete>
                                                <span v-for="errors in errors.customer_id" class="text-warning"
                                                    :key="errors">{{ errors }}</span>
                                            </v-col>
                                            <v-col sm="4" class="d-flex">
                                                <v-autocomplete v-model="customer.shipping_address"
                                                    :items="shipping_address_list" :disabled="service == 1" dense
                                                    outlined hide-details auto-select-first
                                                    label="Select Address" color="#b3d4fc" class="w-100 small"
                                                    id="shipping_address_text"></v-autocomplete>
                                                <span v-for="errors in errors.shipping_address" class="text-warning" :key="errors">{{ errors }}</span>
                                            </v-col>
                                            <v-col sm="2" class="d-flex">
                                                <v-autocomplete
                                                    v-model="salesman_id"
                                                    :items="salesman_list"
                                                    :disabled="service == 1"
                                                    dense
                                                    outlined
                                                    hide-details
                                                    auto-select-first
                                                    item-value="value"
                                                    item-text="text"
                                                    label="Select Salesman"
                                                    class="d-inline-block"
                                                ></v-autocomplete>
                                                <span v-for="errors in errors.salesman_id" class="text-warning" :key="errors">{{ errors }}</span>
                                            </v-col>
                                            <v-col sm="12" md="2">
                                                <v-text-field
                                                    v-model="terms"
                                                    :readonly="promo_id != ''"
                                                    dense
                                                    outlined
                                                    hide-details
                                                    label="Terms"
                                                    aria-label="%"
                                                    aria-describedby="basic-addon2"
                                                    suffix="days"
                                                    @keypress="numberOnly"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </div>

                                    <v-row>
                                        <v-col sm="12" md="5">
                                            <v-row class="ml-2 mt-2" v-if="customer != '' && customer.discounts.length > 0 && promo_id == null">
                                                <v-col sm="12" md="8">
                                                    <v-row class="p-0 text-dark">
                                                        <v-col sm="12" md="6" class="py-0"
                                                            v-for="(disc, index) in itemTypeDiscounts" :key="index"
                                                            v-show="orderList[0] != null && disc.length > 0 && disc.substring(0, 2) == orderList[0].type"
                                                        >
                                                            <span>{{ disc }}</span>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-spacer></v-spacer>
                                            </v-row>
                                        </v-col>
                                        <v-col sm="6" md="3">
                                            <v-textarea v-model="notes" label="Notes" rows="2" class="p-0"></v-textarea>
                                        </v-col>
                                        <v-col sm="6" md="1">
                                            <v-textarea v-model="customer_po_num" :disabled="service == 1" label="PO#" no-resize rows="2" class="p-0"></v-textarea>
                                        </v-col>
                                        <v-col sm="6" md="3">
                                            <v-textarea v-model="remarks" label="Remarks" no-resize rows="2" class="p-0"></v-textarea>
                                        </v-col>
                                    </v-row>

                                    <v-card outlined class="p-2">
                                        <v-row>
                                            <v-col sm="12" md="12">
                                                <div class="float-left ma-4">
                                                    <v-btn dark small color="bg-primary"  @click="batch_addition.dialog = true">Batch Addition</v-btn>
                                                    <!-- <v-btn dark small color="bg-primary" :disabled="disableBatchAddition() || orderList.lenght == 0" @click="overrideAmounts()">Override amounts</v-btn> -->
                                                </div>
                                                <div class="float-right ma-4" >
                                                    <v-btn fab dark small color="primary" style="height: 20px; width: 20px" @click="addOrderItem()">
                                                        <v-icon dark small style="font-size: 12px">mdi-plus</v-icon>
                                                    </v-btn>
                                                    <v-btn fab dark small color="primary" style="height: 20px; width: 20px" @click="removeOrderItem()">
                                                        <v-icon dark small style="font-size: 12px">mdi-minus</v-icon>
                                                    </v-btn>
                                                </div>
                                            </v-col>
                                            <!-- EDIT this  -->
                                            <!-- <v-col sm="12" md="12" v-if="!hasPromo()" class="d-flex">
                                                <v-checkbox v-model="override_srp.promo_type" true-value="1" false-value="0" class="mx-3" label="Promo">

                                                </v-checkbox>
                                                <v-checkbox v-model="override_srp.promo_type" true-value="2" false-value="0" label="Special Approval">

                                                </v-checkbox>
                                            </v-col> -->

                                            <v-col sm="12" md="12">
                                                <v-simple-table dense class="dynamic_list_table">
                                                    <template v-slot:default>
                                                        <thead>
                                                            <tr>
                                                                <th class="text-center mx-2" width="12%">Model</th>
                                                                <th class="text-center mx-2" width="22%">Name</th>
                                                                <th class="text-center mx-2" width="9%">MOQ</th>
                                                                <th class="text-center mx-2" width="9%">Order Qty</th>
                                                                <th class="text-center mx-2" v-if="stock_card == 1">SC Qty</th>
                                                                <th class="text-center mx-2" width="9%">UOM</th>
                                                                <th class="text-center mx-2">SRP</th>
                                                                <th class="text-center mx-2">Disc. Price</th>
                                                                <th class="text-center mx-2" width="1%">FOC</th>
                                                                <th class="text-center mx-2">Total</th>
                                                                <th class="text-center mx-2" width="7%">Type</th>
                                                                <th class="text-center mx-2" width="1%"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(order_item, i) in orderList" :key="i" :class="`${order_item.color ? order_item.color : ''}`" v-if="!rerender">
                                                                <td v-if="(action == 'Add' || orderList[i].item_id == '' || orderList[i].is_editable) && !orderList[i].is_bundle">
                                                                    <v-autocomplete
                                                                        v-model="orderList[i].item_id"
                                                                        :items="filtered_items_list"
                                                                        :disabled="service == 1"
                                                                        dense
                                                                        outlined
                                                                        hide-details
                                                                        auto-select-first
                                                                        placeholder="Select Model"
                                                                        @input="selectModel(i, orderList[i].item_id, true); checkItemStocks(orderList[i], i);"
                                                                        @keydown.tab="getItemIdSingle($event.target.value)"
                                                                    ></v-autocomplete>
                                                                </td>
                                                                <td v-else>
                                                                    <v-text-field
                                                                        v-model="orderList[i].item.model"
                                                                        dense
                                                                        outlined
                                                                        hide-details
                                                                        readonly
                                                                        class="centered-input"
                                                                        background-color="grey lighten-2"
                                                                    ></v-text-field>
                                                                </td>
                                                                <td>
                                                                    <v-text-field
                                                                        v-model="orderList[i].item.name"
                                                                        dense
                                                                        outlined
                                                                        hide-details
                                                                        readonly
                                                                        background-color="grey lighten-2"
                                                                    ></v-text-field>
                                                                </td>
                                                                <td>
                                                                    <v-text-field
                                                                        v-model="orderList[i].item.moq"
                                                                        dense
                                                                        outlined
                                                                        hide-details
                                                                        readonly
                                                                        class="centered-input"
                                                                        background-color="grey lighten-2"
                                                                    ></v-text-field>
                                                                </td>
                                                                <td>
                                                                    <v-text-field
                                                                        v-model="orderList[i].quantity"
                                                                        :disabled="orderList[i].is_bundle || service == 1"
                                                                        dense
                                                                        outlined
                                                                        hide-details
                                                                        class="centered-input"
                                                                        @keypress="numberOnly"
                                                                        @input="changeQuantity(i)"
                                                                        @blur="checkMoq(i)"
                                                                        @focus="$event.target.select()"
                                                                        @keydown="checkItemStocks(orderList[i], i)"
                                                                    ></v-text-field>
                                                                </td>
                                                                <td v-if="stock_card == 1">
                                                                    <v-text-field
                                                                        v-model="orderList[i].sc_quantity"
                                                                        dense
                                                                        outlined
                                                                        hide-details
                                                                        class="centered-input"
                                                                        @keypress="numberOnly"
                                                                        @input="changeQuantitySC(i)"
                                                                        @blur="checkMoqSC(i)"
                                                                        @focus="$event.target.select()"
                                                                    ></v-text-field>
                                                                </td>
                                                                <td>
                                                                    <v-text-field
                                                                        v-model="orderList[i].item.item_uom_text"
                                                                        dense
                                                                        outlined
                                                                        hide-details
                                                                        readonly
                                                                        class="centered-input"
                                                                        background-color="grey lighten-2"
                                                                    ></v-text-field>
                                                                </td>
                                                                <td>
                                                                {{override_srp.enable}}
                                                                    <v-text-field
                                                                        v-if="!orderList[i].foc"
                                                                        v-model="orderList[i].item.display_srp"
                                                                        dense
                                                                        outlined
                                                                        hide-details
                                                                        :readonly="override_srp.enabled ? false :true"
                                                                        class="right-input"
                                                                        @change="recomputeDiscAmount(i)"
                                                                        :background-color="override_srp.enabled ? '' :'grey lighten-2'"
                                                                    ></v-text-field>
                                                                    <v-text-field
                                                                        v-else
                                                                        v-model="foc_price"
                                                                        dense
                                                                        outlined
                                                                        hide-details
                                                                        :readonly="override_srp.enabled ? false :true"
                                                                        class="right-input"
                                                                        :background-color="override_srp.enabled ? '' :'grey lighten-2'"
                                                                        @change="recomputeDiscAmount(i)"
                                                                    ></v-text-field>
                                                                </td>
                                                                <td v-if="orderList[i].override_amount == null || orderList[i].override_amount == 0">
                                                                    <v-text-field
                                                                        v-if="!orderList[i].foc"
                                                                        v-model="orderList[i].display_disc_price"
                                                                        dense
                                                                        outlined
                                                                        hide-details
                                                                        :readonly="override_srp.enabled ? false :true"
                                                                        class="right-input"
                                                                        :background-color="override_srp.enabled ? '' :'grey lighten-2'"
                                                                        @change="recomputeDiscAmount(i,1)"

                                                                    ></v-text-field>
                                                                    <!-- @click="showOverrideAmountDialog(i)" -->
                                                                    <v-text-field
                                                                        v-else
                                                                        v-model="foc_price"
                                                                        dense
                                                                        outlined
                                                                        hide-details
                                                                        :readonly="override_srp.enabled ? false :true"
                                                                        class="right-input"
                                                                        :background-color="override_srp.enabled ? '' :'grey lighten-2'"
                                                                        @change="recomputeDiscAmount(i,1)"
                                                                    ></v-text-field>
                                                                </td>
                                                                <td v-else>
                                                                    <v-text-field
                                                                        v-model="orderList[i].display_override_amount"
                                                                        dense
                                                                        outlined
                                                                        hide-details
                                                                        readonly
                                                                        class="right-input bg-ingco"
                                                                        background-color="grey lighten-2"
                                                                        @click="showOverrideAmountDialog(i)"
                                                                    ></v-text-field>
                                                                </td>
                                                                <v-tooltip :disabled="!orderList[i].foc_remarks" bottom>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <td v-bind="attrs" v-on="on">
                                                                            <v-checkbox
                                                                                v-model="orderList[i].foc"
                                                                                color="primary"
                                                                                input-value="1"
                                                                                hide-details
                                                                                class="ma-0 pa-0"
                                                                                @change="changeFoc(i)"
                                                                            ></v-checkbox>
                                                                        </td>
                                                                    </template>
                                                                    <span>{{ orderList[i].foc_remarks }}</span>
                                                                </v-tooltip>
                                                                <td>
                                                                    <v-text-field
                                                                        v-model="orderList[i].display_total_amount"
                                                                        dense
                                                                        outlined
                                                                        hide-details
                                                                        readonly
                                                                        class="right-input"
                                                                        background-color="grey lighten-2"
                                                                    ></v-text-field>
                                                                </td>
                                                                <td>
                                                                    <v-text-field
                                                                        v-model="orderList[i].type"
                                                                        dense
                                                                        outlined
                                                                        hide-details
                                                                        readonly
                                                                        class="centered-input"
                                                                        background-color="grey lighten-2"
                                                                    ></v-text-field>
                                                                </td>
                                                                <td>
                                                                    <v-btn v-if="service == 0" fab dark small color="primary"
                                                                        style="height: 20px; width: 20px"
                                                                        @click="removeOrderByIndex(i)">
                                                                        <v-icon dark small style="font-size: 12px">
                                                                            mdi-minus
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                            </v-col>
                                            <v-col lg="12" class="d-flex justify-content-center align-items-center"  v-if="rerender">
                                                <v-progress-circular
                                                    :width="12"
                                                    :size="100"
                                                    color="primary"
                                                    indeterminate
                                                ></v-progress-circular>
                                            </v-col>
                                        </v-row>
                                    </v-card>

                                    <v-row class="m-2">
                                        <v-container fluid>
                                            <v-row>
                                                <v-col sm="12" md="8" class="text-left">
                                                    <v-row class="ml-2"></v-row>
                                                    <v-row class="ml-2"></v-row>
                                                </v-col>
                                                <v-col sm="12" md="4" class="pull-right text-right">
                                                    <h5>Total Amount: &#x20b1;{{ this.sales_total_amount | currency }}</h5>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-row>

                                    <v-col sm="12" md="12">
                                        <table class="table-bordered" style="font-size: 16px">
                                            <tr v-for="(e, i) in batch_addition.errors" :key="i">
                                                <th class="px-3">{{ e.title }}</th>
                                                <td class="px-4" style="color: red">{{ e.data }}</td>
                                            </tr>
                                        </table>
                                    </v-col>

                                    <div class="border-top">
                                        <div class="card-body py-4 d-flex justify-content-center">
                                            <v-btn @click="checkForFoc(action)" v-if="action == 'Add'" :loading="loading.submit">Submit</v-btn>
                                            <v-btn @click="checkForFoc(action)"
                                                v-else-if="action == 'Edit'">Update</v-btn>
                                        </div>
                                    </div>
                                </form>
                            </v-card-text>
                            <v-card-text v-else-if="loaded == false">
                                <div class="text-center">
                                    <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                                </div>
                            </v-card-text>
                        </v-col>
                    </v-container>
            </v-card>
        </v-dialog>

        <v-dialog v-model="batch_addition.dialog" persistent max-width="30%" scrollable>
            <v-card>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">Copy Model and Qty From Excel</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeBatchAdditionDialog(); dataFromExcel = '';">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text class="p-4">
                    <v-row>
                        <v-textarea
                            v-model="dataFromExcel"
                            :autofocus="batch_addition.dialog"
                            rows="1"
                            small
                            clearable
                            color="primary"
                            label="Paste From Excel"
                            @click="$event.target.focus()"
                            @focus="dataFromExcel = null"
                            @paste="onPaste"
                        ></v-textarea>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="itemSubsDialog" width="30%" persistent>
                <v-card>
                    <v-row class="m-0">
                        <v-card-title>
                            <span class="headline">New Models For {{ new_item_model }} </span>
                        </v-card-title>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right"
                                @click="itemSubsDialog = false">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card-text class="p-4">
                        <v-row>
                            <v-autocomplete
                                v-model="itemSubstitution"
                                :items="item_substitution"
                                item-value="id"
                                item-text="model"
                                label="Item Substitution"
                                outlined
                                dense
                                auto-select-first
                            >
                            </v-autocomplete>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="text-center">
                        <v-row class="ma-1">
                            <v-col cols="12">
                                <v-btn class="float-center" @click="submitSubstitution()">
                                    <span>Submit</span>
                                </v-btn>
                            </v-col>
                        </v-row>
				    </v-card-actions>
                </v-card>
        </v-dialog>

        <v-dialog v-model="batchSubstitution.dialog" width="60%" persistent>
            <v-card>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline"> New Models </span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right"
                            @click="batchSubstitution.dialog = false">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text class="p-4">
                    <v-simple-table fixed-header>
                        <thead class="position-sticky">
                            <tr>
                                <th class="text-center">MODEL</th>
                                <th class="text-center">SRP</th>
                                <th class="text-center">MOQ</th>
                                <th class="text-center" width="10%">QTY</th>
                                <th class="text-center">NEW ITEM</th>
                                <th class="text-center">NEW ITEM SRP</th>
                                <th class="text-center">NEW ITEM MOQ</th>
                                <th class="text-center">ACTION</th>
                            </tr>
                        </thead>
                        <tbody >
                            <tr v-for="(data, i) in batchSubstitution.list" :key="i">
                                <td class="pa-1">
                                    <v-text-field
                                        v-model="data.itemDetails.model"
                                        class="centered-input"
                                        outlined
                                        dense
                                        hide-details
                                        readonly
                                    >
                                    </v-text-field>
                                </td>
                                <td v-if="data.subsitutionSelection.length != 0" class="pa-1">
                                    <v-text-field
                                        v-model="data.itemDetails.selling_price"
                                        class="centered-input"
                                        outlined
                                        dense
                                        hide-details
                                        readonly
                                        @keypress="numberOnly"
                                    >
                                    </v-text-field>
                                </td>
                                <td v-if="data.subsitutionSelection.length != 0" class="pa-1">
                                    <v-text-field
                                        v-model="data.itemDetails.moq"
                                        class="centered-input"
                                        outlined
                                        dense
                                        hide-details
                                        readonly
                                        @keypress="numberOnly"
                                    >
                                    </v-text-field>
                                </td>
                                <td v-if="data.subsitutionSelection.length != 0" class="pa-1">
                                    <v-text-field
                                        v-model="data.qty"
                                        @blur="checkItemMoq(i, data)"
                                        :class="{
                                            notInModel: data.error,
                                        }"
                                        outlined
                                        dense
                                        hide-details
                                        class="centered-input"
                                    >
                                    </v-text-field>
                                </td>
                                <td v-if="data.subsitutionSelection.length != 0" class="pa-1 pt-2">
                                    <v-autocomplete
                                        v-model="data.item_sub_id"
                                        :items="data.subsitutionSelection"
                                        @change="checkItemMoq(i, data)"
                                        item-value="id"
                                        item-text="model"
                                        outlined
                                        dense
                                        auto-select-first
                                    >
                                    </v-autocomplete>
                                </td>
                                <td v-if="data.subsitutionSelection.length != 0" class="pa-1">
                                    <v-text-field
                                        :value="getSubItemDetails(data.item_sub_id, data.subsitutionSelection, 'selling_price')"
                                        outlined
                                        dense
                                        hide-details
                                        readonly
                                        class="centered-input"
                                        @keypress="numberOnly"
                                    >
                                    </v-text-field>
                                </td>
                                <td v-if="data.subsitutionSelection.length != 0" class="pa-1">
                                    <v-text-field
                                        :value="getSubItemDetails(data.item_sub_id, data.subsitutionSelection, 'moq')"
                                        outlined
                                        dense
                                        hide-details
                                        readonly
                                        class="centered-input"
                                        @keypress="numberOnly"
                                    >
                                    </v-text-field>
                                </td>
                                <td v-if="data.subsitutionSelection.length != 0" class="pa-1">
                                    <v-btn
                                        text
                                        icon
                                        small
                                        color="primary"
                                        class="float-right"
                                        @click="deleteItem(i)"
                                    >
                                        <v-icon dark small>mdi-minus-circle</v-icon>
                                    </v-btn>
                                </td>
                                <td class="text-center" colspan="7" v-else>
                                    <span><i>No new models found.</i></span>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-card-text>
                <v-card-actions class="text-center">
                    <v-row class="ma-1">
                        <v-col cols="12">
                            <v-btn class="float-center" @click="submitBatchSubstitution()">
                                <span>Submit</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="fileUploadSalesQuotation" persistent max-width="20%">
            <v-card class="pa-4">
                <v-btn
                text
                icon
                color="gray"
                class="float-right"
                @click="closeCameraCapture()"
                >
                <v-icon>mdi-close-circle</v-icon>
                </v-btn>
                <div class="py-2">
                    <span>Attach Documents via Camera or Upload</span>
                    <span style="color:red">*</span>
                    <v-divider/>
                </div>

                <div class="py-2 mt-2">
                    <v-layout row class="mx-0">
                        <v-flex lg2 class="mr-2">
                            <BaseCameraCaptureVue ref="cameraCapture" @capturedImages="getImages" />
                        </v-flex>
                        <div class="mt-2">
                            <v-chip
                                v-if="!!images && images.length > 0"
                                color="#f69f1a"
                                outlined
                                close
                                @click:close="clearCameraCapture()"
                            >
                                <span class="mr-2">Images Count: {{ images.length }}</span>
                            </v-chip>
                        </div>
                    </v-layout>
                </div>

                <div class="py-2 mt-3">
                    <v-layout row class="mx-0">
                        <v-flex :class="{'mr-2': true, 'lg3': !!uploadedFiles && uploadedFiles.length > 1, 'lg6': !!uploadedFiles && uploadedFiles.length == 1}">
                            <FileUploadComponentVue ref="fileUpload" @uploadedData="uploadedData" @reset="clearFileUpload"/>
                        </v-flex>
                        <v-chip
                            v-if="!!uploadedFiles && uploadedFiles.length > 0"
                            color="#f69f1a"
                            outlined
                            close
                            @click:close="clearFileUpload()"
                        >
                            <span class="mr-2">Upload Count: {{ uploadedFiles.length }}</span>
                        </v-chip>
                    </v-layout>
                </div>
                <div class="py-2 mt-3">
                    <v-card-actions class="d-flex justify-content-end">
                        <v-btn  @click="closeCameraCapture()">Submit</v-btn>
                    </v-card-actions>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import SparePartsStagingAreaTableComponent from "@/views/main/modules/Services/Views/SparePartsStagingAreaComponent/SparePartsStagingAreaTableComponent.vue";
// import SalesQuotationsComponent from '@/views/main/modules/Sales/SalesQuotations/SalesQuotationsComponent.vue'
import SharedFunctionsComponent from "@/views/main/Shared/SharedFunctionsComponent.vue";
import SalesFunctionsComponent from "@/views/main/Shared/SalesFunctionsComponent.vue";
import PusherFunctionComponent from '@/views/main/Shared/PusherFunctionComponent2.vue';
import BaseCameraCaptureVue from '@/views/main/modules/Services/Base/BaseCameraCapture.vue';
import FileUploadComponentVue from '@/views/main/Shared/FileUploadComponent.vue';
import Swal from 'sweetalert2'
import { mapGetters } from 'vuex';
import service from '@/store/modules/service';

export default {

    data(){
        return{
            exportLoading: false,
            exportSQheader: [
                { header:'QUOTE NUM', key: 'quote_num', width: 15},
                { header:'CUSTOMER', key: 'company_name', width: 45},
                { header:'REMARKS', key: 'remarks', width: 40 },
                { header:'SQ TOTAL AMOUNT', key: 'sq_total', width: 20},
                { header:'SQ STATUS', key: 'status_text', width: 20},
                { header:'SQ ITEM MODEL', key: 'model', width: 25},
                { header:'SQ ITEM NAME', key: 'name', width: 30},
                { header:'SQ ITEM TYPE', key: 'type', width: 15},
                { header:'SQ ITEM FOC', key: 'foc_text', width: 15},
                { header:'SQ ITEM SRP', key: 'srp', width: 15 },
                { header:'SQ ITEM DISC PRICE', key: 'disc_price', width: 23},
                { header:'SQ ITEM QTY', key: 'quantity', width: 15 },
                { header:'SQ ITEM UOM', key: 'uom', width: 15 },
                { header:'SQ ITEM PRICE', key: 'final_price', width: 17},
                { header:'SQ ITEM TOTAL', key: 'sqi_total_amount', width: 20},
                { header:'ISSUER', key: 'issuer_name', width: 20},
                { header:'DATE ISSUED', key: 'created_at', width: 20},
            ],
            exportSOheader: [
                { header:'ORDER NUM', key: 'order_num', width: 15},
                { header:'QUOTE NUM', key: 'quote_num', width: 15},
                { header:'CUSTOMER', key: 'company_name', width: 45},
                { header:'REMARKS', key: 'remarks', width: 40 },
                { header:'SO TOTAL AMOUNT', key: 'so_total_amount', width: 20},
                { header:'SO STATUS', key: 'status_text', width: 20},
                { header:'SO ITEM MODEL', key: 'model', width: 25},
                { header:'SO ITEM NAME', key: 'name', width: 30},
                { header:'SO ITEM TYPE', key: 'type', width: 15},
                { header:'SO ITEM FOC', key: 'foc_text', width: 15},
                { header:'SO ITEM SRP', key: 'srp', width: 15 },
                { header:'SO ITEM DISC PRICE', key: 'disc_price', width: 23},
                { header:'SO ITEM QTY', key: 'quantity', width: 15 },
                { header:'SO ITEM QTY SERVED', key: 'quantity_served', width: 15 },
                { header:'SO ITEM QTY CANCELLED', key: 'quantity_canceled', width: 15 },
                { header:'SO ITEM QTY PREPARED', key: 'prepared_quantity', width: 15 },
                { header:'SO ITEM UOM', key: 'uom', width: 15 },
                { header:'SO ITEM PRICE', key: 'final_price', width: 17},
                { header:'SO ITEM TOTAL', key: 'soi_total_amount', width: 20},
                { header:'ISSUER', key: 'issuer', width: 20},
                { header:'DATE ISSUED', key: 'date_issued', width: 20},
            ],
            addSalesQuotationDialog: false,
            batch_addition: {
                dialog: false,
                data: [],
                excel_item_id: null,
                errors: [],
            },
            dataFromExcel: '',
            fromShowEditModal: false,
            stock_card: 0,
            promo_name: '',
            promo_id: null,
            promo: {
                id: '',
                name: '',
                code: '',
            },
            customer_promo: {
                discount_rate: '',
                cash_price: '',
            },
            override_username: '',
            override_user_password: '',
            override_user_id: -1,
            override_amount: '',
            override_index: '',
            editSalesQuotationId: '',
            add_edit_salesquotation_dialog: false,
            override_amount_dialog: false,
            approve_override_dialog: false,
            quote_num: '',
            quote_date: '',
            customer_id: '',
            salesman_id: '',
            user_id: '',
            remarks: '',
            discounts: '',
            terms: '',
            orig_terms: '',
            convert_date: '',
            status: '',
            created_at: '',
            issuer_name: '',
            foc_price: "0.00",
            delivery_method: 2,

            tempOrderList: [],
            orderList: [],
            order: {
                sc_quantity: '',
                quantity: '',
                item_id: '',
                foc: '',
                disc_price: '',
                total_amount: '',
                amount: '',
                type: '',
                item: {
                    id: '',
                    model: '',
                    name: '',
                    description: '',
                    type_id: '',
                    category_id: '',
                    uom_id: '',
                    item_uom_text: '',
                    barcode: '',
                    selling_price: '',
                    sp_price: '',
                    srp: '',
                    override_amount: '',
                    moq: '',
                    brand_id: '',
                    original_item_id:0
                },
                is_bundle: false,
                promos: [],
                selected_promo: '',
                promo_items_id: '',
                promo_amount: '',
                backend_promo: '',
            },
            customer: {
                shipping_address: 0,
                billing_address: 0,
                salesman_name: '',
                remarks: '',
                terms: '',
                discounts: [],
                is_sp: '',
                is_vip: '',
                override_moq: 0,
            },
            discount: {
                pt: '',
                ht: '',
                sp: '',
            },
            itemTypeDiscounts: [],
            max_discounts_count: 999,
            max_order_items_count: 999,
            shipping_address_list: [],
            customers_list: [],
            users_list: [],
            salesman_list: [],
            items_list: [],
            type_list: [],
            promo_list: [],
            shortlist_item_ids: [],
            shortlist_customer_ids: [],
            delivery_method_list: [],
            errors: [],
            action: '',
            actionResult: {
                model: "Sales Quotation",
                action: '',
            },
            loaded: false,
            userAccess: {
                create: false,
            },
            customer_po_num: '',
            departments_allowed: [],
            focRemarks: '',
            showFocRemarks: false,
            orderListIndex: '',
            override: {
                override_dialog: false,
                errors: [],
                override_departments_allowed: ["SM", "EX", "IT"],
                override_authenticated: false,
                headers:'FOC',
                from:0
            },
            isSalesman: false,
            salesmanTitle: '',
            salesmanChildrenIds: [],
            userChildrenIds: [],
            salesmanDetails: null,
            isSalesCoordinator: false,
            currentSalesmanId: '',
            currentSalesmanPositionId: '',
            itemWithNoStock: [
                {
                    model: '',
                    suggested_items: [],
                },
            ],
            showOutOfStockDialog: false,
            selectedSQ: {
                data: {
                    suggested_items: [],
                },
                index: 0,
                selected: null,
            },
            itemToReplace: [],
            items_suggestion: {
                model: '',
                id: [],
                suggested_items: [],
                selected: [],
            },
            filterFrom_table: [],

            notes: null,
            alreadySubstitutedItem:false,
            exclusive_items:[],

            has_promo: 0,
            itemSubsDialog: false,
            itemSubstitution: '',
            batchSubstitution:{
                dialog: false,
                list: []
            },

            loading: {
                submit: false,
                export: false,
                items:false
            },
            override_srp:{
                title:'SRP',
                promo_type:0,
                enabled:false
            },
            rerender:false,
            fileUploadSalesQuotation: false,
            images: null,
            uploadedFiles: null,
            itemWarrantyType: '',
        }
    },

    props:["isActive_tab", "service", "tab_name"],
    mixins: [
        SharedFunctionsComponent,
        SalesFunctionsComponent,
        PusherFunctionComponent
    ],
    components: {
        SparePartsStagingAreaTableComponent,
        // SalesQuotationsComponent,
        BaseCameraCaptureVue,
        FileUploadComponentVue,

    },

    computed: {
        ...mapGetters([
            'GET_SPARE_PARTS_STAGING_AREA',
            'GET_REFRESH_SP_AREA',
            'GET_SPARE_PARTS_AREA_TAB',
            'GET_SP_AREA_DISPATCH',
            'GET_SP_AREA_DIALOG_TITLE',
            'GET_FILTER_DATE',
            'GET_SEARCH_DATA',
            "GET_CURRENT_SALESMAN",
            "USER_ACCESS",
            "USERACCOUNT_LOAD",
        ]),
    },
    methods: {
        showFileUpload() {
            this.fileUploadSalesQuotation = true;
        },

        closeCameraCapture(){
            this.fileUploadSalesQuotation = false;
        },

        getImages(images) {
            this.images = images;
        },

        uploadedData(data) {
            let that = this
            this.uploadedFiles = [];
            if(!!data) {
                data.attachments.forEach(e => {
                    const file = e;
                    const blobFile = new Blob([file], { type: file.type });

                    var reader  = new FileReader();
                    reader.readAsDataURL(blobFile);
                    reader.onloadend = function () {
                        that.uploadedFiles.push(reader.result)
                    }
                })
            }
        },

        clearCameraCapture(){
            this.images = null;
        },

        clearFileUpload() {
            this.uploadedFiles = null;
        },

        AddDialog(){
            this.addSalesQuotationDialog = true;
            this.action = "Add";
        },

        closeDialog() {
            this.addSalesQuotationDialog = false;
            this.clearCameraCapture();
            this.clearFileUpload()
            this.$store.commit('REFRESH_SP_AREA', true)
        },

        closeBatchAdditionDialog() {
            this.batch_addition.data = [];
            this.batch_addition.dialog = false;
        },

        checkItemMoq(index, data){
            let qty = parseInt(data.qty);
            let moq = data.subsitutionSelection.find((e)=> e.id == data.item_sub_id).moq;

            let arr = [];
            if(qty % moq == 0){
                this.batchSubstitution.list[index].error = false;
            }
            else{
                var upperQty = qty;
                var lowerQty = qty;
                while (upperQty % moq > 0) {
                    upperQty++;
                }
                while (lowerQty % moq > 0) {
                    lowerQty--;
                }
                var suggestion = "";
                if (lowerQty == 0) {
                    suggestion = "\n Suggestion: " + upperQty ?? 0;
                } else {
                    suggestion = "\n Suggestion: " + upperQty ?? 0 + " or " + lowerQty ?? 0;
                }
                swal.fire(
                    "",
                    "Quantity should be divisible by " + moq ?? 0 + suggestion ?? 0,
                    "warning"
                );
                qty = "";
                data.error = true
                this.batchSubstitution.list[index].error = true;
            }

            this.batchSubstitution.list[index].qty = qty;
            arr = this.batchSubstitution.list;
            this.batchSubstitution.list = [];
            this.batchSubstitution.list = arr;

        },

        onPaste(evt) {
            this.batch_addition.data = [];
            var pastedText = evt.clipboardData.getData("Text");
            var rows = pastedText.split("\r\n");
            for (var y in rows) {
                if (y != rows.length - 1) {
                    var cells = rows[y].split("\t");
                    this.batch_addition.data.push(
                        Object.assign({}, {
                            model: cells[0],
                            qty: cells[1],
                            // foc: cells[2],
                            id: '',
                            skip: false,
                        })
                    );
                }
            }

            this.getItemId();
            this.closeBatchAdditionDialog();
        },

        addKeyListener() {
            window.addEventListener("keyup", function (e) {
                var keypressed = String.fromCharCode(e.keyCode);
                if (e.ctrlKey && e.keyCode == 32) {
                    this.addOrderItem();
                    $("input").blur();
                } else if (keypressed == "-") {
                    this.removeOrderItem();
                    $("input").blur();
                }
            }.bind(this));
        },

        submitSubstitution(){
            this.selectModel(this.index, this.itemSubstitution, false);
            this.itemSubsDialog = false ;
            this.resetItemSubs();
        },

        resetItemSubs(){
            this.index = 0;
            this.itemSubstitution = '';
        },

        hasPromo() {
            return this.promo_id !== null && this.promo_id !== undefined && this.promo_id !== "";
        },

        addNewSalesQuotation() {
            if (this.action == "Add") {
                this.proceedSaveQuotation();
            } else if (this.action == "Edit") {
                this.proceedUpdateQuotation();
            }
        },

        async submitBatchSubstitution(){
            for await (let item of this.batchSubstitution.list) {
                if(item.item_sub_id){
                    await new Promise(async (resolve) => {
                        if(this.batchSubstitution.list.length > 1){
                            this.addOrderItem();
                        }
                        this.orderListLength =
                            this.orderList.length > 0 ? this.orderList.length : 0;
                        this.counter = this.orderListLength - 1;

                        this.orderList[this.counter].quantity = item.qty;
                        // this.orderList[this.counter].foc = item.foc

                        await this.selectModelForBatchAddition(
                            this.counter,
                            item.item_sub_id,
                            true
                        ).then((resolved) => {
                            resolve(resolved);
                        });
                    });
                }
            }

            this.batchSubstitution.dialog = false;
        },

        // async showEditModal(value) {
        //     this.itemWithNoStock = [];
        //     this.fromShowEditModal = true;
        //     this.action = "Edit";
        //     this.editSalesQuotationId = value;
        //     this.getCustomers(true);
        //     this.getUsers();
        //     this.getSalesmanList();
        //     this.getItems();
        //     this.getItemsNotInPromo();
        //     this.getItemTypes();
        //     this.getPromos();
        //     // this.getCustomersNotInPromo();
        //     this.delivery_method_list = [
        //         // {'value' : 0, 'text' : 'none'},
        //         { value: 1, text: "pick-up" },
        //         { value: 2, text: "delivery" },
        //     ];

        //     if (this.editSalesQuotationId != null && this.editSalesQuotationId > 0) {
        //         this.$store.dispatch("salesQuotations", this.editSalesQuotationId).then((response) => {
        //             let data = response.data;

        //             if (data.error) {
        //                 Swal.fire('', data.error, "warning");
        //                 return false;
        //             }

        //             let sales_quotation = data.sales_quotation;
        //             let orderList = data.sales_quotation.sales_quotation_items;

        //             this.created_date = new Date(
        //                 Date.parse(
        //                     new Date(sales_quotation.created_at).toLocaleString("en-US", {
        //                         timeZone: "Asia/Manila",
        //                     })
        //                 )
        //             );

        //             this.customer.is_sp = sales_quotation.special;
        //             this.customer.override_moq = sales_quotation.override_moq;

        //             this.customer_promo = data.sales_quotation.customer_promo;

        //             if (sales_quotation.discounts != '') {
        //                 this.customer.discounts = JSON.parse(sales_quotation.discounts);
        //             }

        //             if (orderList != null && orderList.length > 0) {
        //                 orderList.forEach((order, index) => {
        //                     this.addOrderItem();

        //                     this.orderList[index].id = order.id;
        //                     this.orderList[index].quantity = order.quantity;
        //                     this.orderList[index].sc_quantity = order.sc_quantity;

        //                     this.orderList[index].item.srp = order.srp;
        //                     this.orderList[index].disc_price = order.disc_price;

        //                     this.orderList[index].item.sp_price = order.item.sp_price;
        //                     this.orderList[index].item.selling_price = order.item.selling_price;

        //                     this.orderList[index].foc = order.foc;

        //                     this.orderList[index].promos = order.promos;

        //                     this.orderList[index].orig_sp_price = order.item.sp_price;
        //                     this.orderList[index].orig_selling_price = order.item.selling_price;

        //                     this.orderList[index].is_bundle = order.is_bundle;
        //                     this.orderList[index].backend_promo = order.backend_promo;

        //                     this.edit = true;

        //                     this.orderList[index].item_id = order.item_id;

        //                     this.orderList[index].override_amount = order.override_amount;
        //                     this.orderList[index].is_editable = true;
        //                     this.orderList[index].foc_remarks = order.foc_remarks;
        //                     this.orderList[index].suggested_items = order.item.suggested_items;
        //                     this.orderList[index].suggested_items_length = order.item.suggested_items.length;
        //                     this.orderList[index].total_stock_available = order.item.total_stocks
        //                     this.orderList[index].index = index;
        //                     this.orderList[index].color = '';

        //                     this.selectModel(index, order.item_id, false, order);
        //                 });
        //             }

        //             this.quote_num = sales_quotation.quote_num;
        //             this.customer_id = sales_quotation.customer_id;
        //             this.salesman_id = sales_quotation.salesman_id;
        //             this.customer.shipping_address = sales_quotation.shipping_address_id;
        //             this.created_at = sales_quotation.created_at;
        //             this.issuer_name = sales_quotation.issuer_name;
        //             this.remarks = sales_quotation.remarks;
        //             this.override_user_id = sales_quotation.override_by;
        //             this.promo_id = sales_quotation.promo_id;
        //             this.promo_name = sales_quotation.promo_name;
        //             this.customer_po_num = sales_quotation.customer_po_num;
        //             this.delivery_method = sales_quotation.delivery_method;
        //             this.stock_card = sales_quotation.stock_card;
        //             this.notes = sales_quotation.notes;
        //             this.has_promo = sales_quotation.has_promo

        //             this.addSalesQuotationDialog = true;
        //         }).catch((error) => {
        //             console.log(error);
        //         });
        //     }
        // },

        exportStagingAreaDetails(tab) {
            this.exportLoading = true;
            if (tab == 'SQ') {
                let payload = {
                    filteredDate: this.GET_FILTER_DATE,
                    search: this.GET_SEARCH_DATA,
                    url:'export-sales-quotations-details'
                }
                this.$store.dispatch('serviceGet', payload).then( res => {
                    this.exportToExcel(res.data, tab);
                    this.exportLoading = false;
                }).catch(e => {
                    console.error(e);
                    this.exportLoading = false;
                })
            } else {
                let payload = {
                    filteredDate: this.GET_FILTER_DATE,
                    search: this.GET_SEARCH_DATA,
                    url:'export-sales-orders-details'
                }
                this.$store.dispatch('serviceGet', payload).then( res => {
                    this.exportToExcel(res.data, tab);
                    this.exportLoading = false;
                }).catch(e => {
                    console.error(e);
                    this.exportLoading = false;
                })
            }

        },

        async checkItemStocks(val, i) {
            await this.$store.dispatch(`TotalStocks`, val.item_id).then((response) => {
                val.quantity == '' ? (val.quantity = 0) : '';

                if(parseInt(response.data) == 0){
                    this.orderList[i].color = "bg-info";
                    if(!this.alreadySubstitutedItem && (this.promo_id == null || this.promo_id == '')){
                        let payload = {
                            item_id:val.item_id
                        }
                        this.$store.dispatch('getSubstitutionItemModel',payload).then(res=>{
                            let data = res.data

                            if(data){
                                this.selectModel(i, data.old_item_id, true);
                                this.orderList[i].original_item_id = data.item_id
                                swal.fire('','Some item has been replaced with new model(s).','warning');
                                this.alreadySubstitutedItem = true
                            }
                            else{
                                this.orderList[i].color = "bg-danger";
                            }
                        })
                    }
                }
                else if (parseInt(response.data) < parseInt(val.quantity)) {
                    this.orderList[i].color = "bg-danger";
                } else {
                    this.orderList[i].color = '';
                }
                this.$forceUpdate();
            });
        },

        changeQuantitySC(key) {
            let qty = 0;

            qty = parseInt(this.orderList[key].quantity);
            if (parseInt(this.orderList[key].sc_quantity) > qty) {
                this.orderList[key].sc_quantity = qty;
            }
        },

        checkOrderTableHeight() {
            if (this.action == "Add") {
                this.proceedSaveQuotation();
            } else if (this.action == "Edit") {
                this.proceedUpdateQuotation();
            }
        },

        async proceedSaveQuotation() {
            if(this.images == null && this.uploadedFiles == null) {
                Swal.fire('', '- Please Upload Images or Files', "warning");
            } else {
                await this.promo500k()
                this.loading.submit = true;
                this.errors = [];
                // var shipping_address_text = $("shipping_address_text").parent().text();
                let shipping_address_text = ''
                this.shipping_address_list.forEach(e => {
                    if (e.value == this.customer.shipping_address) {
                        shipping_address_text = e.text
                    }
                })
                this.checkOrderTableHeightAddItem();
                let unique_key = "id" + Math.random().toString(16).slice(2);
                let parameters = {
                    images: this.images,
                    uploadedFiles: this.uploadedFiles,
                    customer_id: this.customer_id,
                    salesman_id: this.salesman_id,
                    remarks: this.remarks,
                    orderList: this.orderList,
                    shipping_address_index: this.customer.shipping_address,
                    shipping_address_text: shipping_address_text,
                    discounts: this.customer.discounts,
                    terms: this.terms,
                    override_by: this.override_user_id,
                    promo_id: this.promo_id,
                    customer_po_num: this.customer_po_num,
                    delivery_method: this.delivery_method,
                    stock_card: this.stock_card,
                    notes: this.notes,
                    has_promo: this.has_promo,
                    unique_key: unique_key,
                    override_moq: this.customer.override_moq,
                    override_amount_approval:this.override_srp.promo_type > 0 ? 1 : 0
                };
                await this.$store.dispatch("storeSalesQuotations", parameters).then((response) => {
                    let data = response.data;

                    if(data.error){
                        Swal.fire('', data.error, "warning");
                        this.loading.submit = false
                        return false;
                    }
                    window.Echo.channel('smod-channel').listen('SalesQuoataionMessage',(e)=>{
                        let data = e.data.find(el => el.userID == this.USERACCOUNT_LOAD.id);
                        let check_unique_id = (e.data[0].unique_key.toString().replace(/"/g,'') == unique_key)

                        if(check_unique_id && data && (data.message.msg || data.message.error) ){
                            if(data.message.error){
                                Swal.fire('', data.message.error, "warning");
                                this.loading.submit = false
                                return false;
                            }
                            this.$store.commit('REFRESH_SP_AREA', true)
                            this.resetFields();
                            this.closeDialog();
                            this.loading.submit = false
                            this.action = "success_"; //use this to avoid double getAllSalesQuotations
                            Swal.fire({
                                text: data.message.msg ,
                                icon: "success",
                            }).then((value) => {

                            });
                        }
                    })
                }).catch((error) => {
                    console.log(error)
                    if (error.response.status == 422) {
                        this.errors = error.response.data.errors;

                    }
                    if(error.response.status == 406){
                        console.log(error.response)
                        let items = ''
                        error.response.data.items.forEach(e=>{
                            items += `<li>${e}</li> `
                        })

                        Swal.fire({
                            title:'Cannot input items due to no container arrive',
                            html:`<b>Items</b><br><ul> ${items} </ul> `,
                            icon:'error'
                        })
                    }
                    this.loading.submit = false
                });
            }
        },

        recomputeDiscAmount(index,action = 0){
            let discount = 0;
            if(action == 0){
                let disc = []
                discount = this.orderList[index].item.display_srp;
                this.itemTypeDiscounts.forEach(e => {
                    e = e.split(':');
                    if(e[0] == this.orderList[index].type){
                        disc = e[1].split('/');
                    }
                });

                disc.forEach(e=>{
                    discount = parseInt(discount) - (parseInt(discount) * (parseInt(e)/100))
                })

                this.orderList[index].display_disc_price = discount
                this.orderList[index].disc_price = discount
                this.orderList[index].item.srp = this.orderList[index].item.display_srp

            }else if(action == 1){
                discount = this.orderList[index].display_disc_price
                this.orderList[index].disc_price = discount
            }


            this.orderList[index].total_amount =  this.orderList[index].disc_price * this.orderList[index].quantity
            this.orderList[index].amount = discount

            this.$forceUpdate();
        },

        checkForFoc(action) {
            let container = this.orderList.map((x) => x.foc);

            let focChecker = container.includes(true);
            // let itemsChecker = this.orderList.every(x=>{
            //     return !!x.foc;
            // });

            if (!!focChecker) {
                this.override.override_dialog = true;
            } else {
                if (action == "Edit") {
                    this.editSalesQuotation();
                } else {
                    this.addNewSalesQuotation();
                }
            }
        },

        resetFields() {
            this.errors = [];
            this.quote_num = '';
            this.quote_date = '';
            this.customer_id = '';
            this.salesman_id = '';
            this.user_id = '';
            this.remarks = '';
            this.customer.discounts = [];
            this.discounts = '';
            this.terms = '';
            this.convert_date = '';
            this.status = '';
            this.shipping_address_list = [];
            this.orderList = [];
            this.itemTypeDiscounts = [];
            this.type_list = [];
            this.override_user_id = -1;
            this.override_amount = '';
            this.override_index = '';
            this.override_username = '';
            this.override_user_password = '';
            this.promo_list = [];
            this.promo_id = null;
            this.shortlist_item_ids = [];
            this.shortlist_customer_ids = [];
            this.promo = '';
            this.customer_promo = '';
            this.customer_po_num = '';
            this.stock_card = 0;
            this.delivery_method = 2;
            this.delivery_method_list = [];
            this.tempOrderList = [];
            this.batch_addition.errors = [];
            this.focRemarks = '';
            this.orderListIndex = '';
            this.showFocRemarks = false;
            this.notes = null;
            this.exclusive_items = [];
            this.has_promo = 0;
            this.loading.submit = false;
            this.loading.export = false;
            this.with_errors = false;
            this.override_srp.promo_type = '';
        },

        exportToExcel(data, tab) {
            const workbook = new this.$exceljs.Workbook()
            const worksheet = workbook.addWorksheet('Spare Parts Staging Area(SQ)')

            worksheet.columns = tab == 'SQ' ? this.exportSQheader : this.exportSOheader;
            let row = worksheet.getRow(1);
            row.font = { bold: true };
            for (let i=1;i<=worksheet.columns.length;i++)  {
                let cell = row.getCell(i);
                cell.fill = { type: 'pattern', pattern:'solid', fgColor:{argb:'F69F1A' } };
                cell.alignment = { vertical: 'middle', horizontal: 'center' };
            }
            if (data.length > 0) {
                data.forEach(record => {
                    worksheet.addRow(record)
                });
            }

            workbook.xlsx.writeBuffer().then((d) => {
                const blob = new Blob([d], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                this.$filesaver.saveAs(blob, `${tab == 'SQ' ? 'Spare Parts Staging Area(SQ).xlsx' : 'Spare Parts Staging Area(SO).xlsx'}`);
            })
        }
    },

    watch: {
        addSalesQuotationDialog: function () {
            if (this.addSalesQuotationDialog == true) {
                this.loaded = false;
                if (this.action == "Add") {
                    this.orderList = [];
                    this.getCustomers(true);
                    this.getUsers();
                    this.getSalesmanList();
                    this.getItems();
                    this.getItemsNotInPromo();
                    this.getItemTypes();
                    this.getPromos();
                    // this.getCustomersNotInPromo();
                    this.delivery_method_list = [
                        // {'value' : 0, 'text' : 'none'},
                        { value: 1, text: "pick-up" },
                        { value: 2, text: "delivery" },
                    ];
                    this.created_date = new Date(Date.parse(new Date().toLocaleString("en-US", "Asia/Manila")));
                    // this.created_date = new Date()
                }

                if (this.orderList.length == 0) {
                    this.addOrderItem();
                }

                this.loaded = true;
            }

            if (this.action == "Add") {
                if (!!this.isSalesman) {
                    if (!!this.currentSalesmanId) {
                        this.salesman_id = this.currentSalesmanId;
                    }
                }
            }
        },
        customer_id: function (value) {
            if (this.addSalesQuotationDialog == true) {
                this.loaded = false;
                if (value > 0) {
                    this.$store.dispatch("showCustomers", value).then((response) => {
                        let data = response.data;
                        this.shipping_address_list = data.all_shipping_addresses;
                        if (this.shipping_address_list.length > 0) {
                            this.customer.shipping_address = this.shipping_address_list[0].value
                        } else {
                            this.customer.shipping_address = ''
                        }

                        this.salesman_id = data.salesman_id;
                        this.terms = data.terms;
                        this.customer.is_sp = data.special;
                        this.orig_terms = data.terms;
                        this.customer.override_moq = data.override_moq

                        if (data.discounts != null && data.discounts != '') {
                            this.customer.discounts = JSON.parse(data.discounts);
                        }

                        if (this.fromShowEditModal == false && this.orderList != null && this.orderList.length > 0) {
                            this.orderList.forEach((order, index) => {
                                this.selectModel(index, order.item_id, false);
                            });
                        } else {
                            this.fromShowEditModal = false;
                        }

                        if (this.action == "Add") {
                            this.orderList = [];
                            this.addOrderItem();
                            this.getCustomerPromoInfo();
                        }

                        this.loaded = true;
                    }).catch((error) => {

                    });
                }
            }
        },
        promo_id: async function (value) {
            if (value != null && value > 0) {
                this.loaded = false;
                await this.$store.dispatch("getPromoShortlists", value).then((response) => {
                    let data = response.data;

                    this.shortlist_item_ids = data.item_ids;
                    this.shortlist_customer_ids = data.customer_ids;

                    if (this.action == "Add") {
                        this.orderList = [];
                        this.addOrderItem();
                        this.customer_id = '';
                        this.customer.shipping_address = ''
                        this.salesman_id = ''
                        this.terms = ''
                        this.override_srp.promo_type = 0
                    }
                }).catch((error) => {

                });
                this.loaded = true;
            }
        },
        'override_srp.promo_type':{
            handler(val){
                this.rerender = true
                if(val == 2){
                    this.override_srp.enabled = true
                }else if (val == 1){
                    this.override_srp.enabled = false
                    this.orderList.forEach((e,i)=>{
                        e.item.display_srp = e.item.selling_price
                        e.disc_price = e.item.sp_price
                       e.total_amount = e.disc_price * e.quantity
                    })
                }else{
                    this.override_srp.enabled = false
                        this.orderList.forEach((e,i)=>{
                        e.item.display_srp = e.item.selling_price
                        this.recomputeDiscAmount(i,0)
                    })
                }
                setTimeout(()=>{
                  this.rerender = false
                },1000)
            },
            deep:true
        },
        orderList: {
            deep: true,
            handler(val) {
                if (this.orderList.length > 0) {
                    this.orderList.forEach((order, index) => {
                        if (order.item.srp != null && order.item.srp != '') {
                            order.item.display_srp = this.getTwoDecimals(order.item.srp);
                        } else {
                            order.item.display_srp = '';
                        }

                        if (order.disc_price != null && order.disc_price != '') {
                            order.display_disc_price = this.getTwoDecimals(order.disc_price);
                        } else {
                            order.display_disc_price = '';
                        }

                        if (order.total_amount != null && order.total_amount != '') {
                            order.display_total_amount = this.getTwoDecimals(order.total_amount);
                        } else {
                            order.display_total_amount = '';
                        }

                        if (order.override_amount != null && order.override_amount != '') {
                            order.display_override_amount = this.getTwoDecimals(order.override_amount);
                        } else {
                            order.display_override_amount = '';
                        }
                    });
                }
                this.$forceUpdate();
            },
        },
        salesmanDetails() {
            if (!!this.salesmanDetails) {
                this.isSalesman = this.salesmanDetails.isSalesman;
                this.salesmanTitle = this.salesmanDetails.salesmanPositionTitle;
                this.salesmanChildrenIds = this.salesmanDetails.salesmanChildrenIds;
                this.userChildrenIds = this.salesmanDetails.userChildrenIds;

                if (!!this.salesmanDetails.salesman) {
                    this.currentSalesmanId = this.salesmanDetails.salesman.id;
                    this.isSalesCoordinator = !!this.salesmanDetails.salesman.sales_coordinator;
                    this.currentSalesmanPositionId = this.salesmanDetails.salesman.salesman_position_id;
                }
            }
        },
        USER_ACCESS: {
            handler(val) {
                if (val != "fail") {
                    this.userAccess.create = false;
                    val.forEach((e) => {
                        if (e.actions_code == "create") {
                            this.userAccess.create = true;
                        }
                    });
                }
            },
        },

        'isActive_tab':{
            handler(val){
                this.$store.commit('REFRESH_SP_AREA', true)
            }
        },
    },

    mounted() {
        this.addKeyListener();
        this.callPusher();
    },
}
</script>
