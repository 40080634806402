<template>
    <v-card class="d-flex justify-content-center flex-column">
        <v-card-title class="d-flex justify-content-between align-items-center">
            <span> {{ ACTION }} Administrative Approver</span>
            <v-btn text icon small color="gray" class="float-right" @click="() => {
                $store.commit('APPROVER_DIALOG', false);
                resetField();
            }">
            <v-icon>mdi-close-circle</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text class="pt-6">
            <v-row class="p-2">
                <v-col cols="12" class="d-flex">
                    <v-radio-group
                        :loading="!form.department_id"
                        v-model="type_selected"
                        class="mt-0"
                        row
                        mandatory
                        hide-details
                        :disabled="USERACCOUNT_LOAD.super_admin !== 1 || USERACCOUNT_LOAD.president !== 1 || ACTION ==='View'"
                    >
                        <v-radio label="Admin" value="Admin"></v-radio>
                        <v-radio label="Accounting" value="Accounting"></v-radio>
                        <v-radio :disabled="!USERACCOUNT_LOAD.super_admin == 1 || !USERACCOUNT_LOAD.president == 1" label="Exec" value="Exec"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-row class="p-2">
                <v-col>
                    <v-autocomplete
                        :disabled="ACTION === 'View'"
                        outlined
                        dense
                        label="Job Title"
                        v-model="form.job_title_id"
                        :loading="isLoadingJobTitle"
                        :items="mappedJobTitle"
                    ></v-autocomplete>
                </v-col>
            </v-row>
            <v-row class="p-2">
                <v-col>
                    <v-autocomplete
                        :disabled="ACTION === 'View' || !form.job_title_id"
                        outlined
                        dense
                        label="Name"
                        v-model="form.fullname"
                        :loading="isLoading && loading"
                        :items="mappedItems"
                        :multiple = "ACTION !== 'Edit'"
                        required
                        no-data-text="No users found"
                    >
                    <template v-slot:selection="{ item, index }" v-if="ACTION === 'Add'">
                        <v-chip :key="item.value"
                                :close="true"
                                @click:close="removeChip(index)"
                                small
                        >
                            {{ item.text }}
                        </v-chip>
                    </template>
                </v-autocomplete>
                </v-col>
            </v-row>
            <v-row class="p-2">
                <v-col cols="6">
                    <v-text-field
                        :disabled="ACTION === 'View'"
                        outlined
                        dense
                        label="Division"
                        v-model="form.division"
                        required
                        :loading="ACTION !=='Add' && !form.division"
                    ></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field
                        :disabled="ACTION === 'View'"
                        outlined
                        dense
                        label="Range"
                        v-model="form.range"
                        required
                        :loading="ACTION !=='Add' && !form.range"
                        prepend-inner-icon="mdi-currency-php"
                        type="number"
                        class="right-align-text"
                        hide-spin-buttons
                    >
                </v-text-field>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-content-center p-3">
            <v-btn @click="modifyAdministrativeApprover('modify-administrative-approver')" v-if="ACTION !== 'View'">
                Submit
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import swal from "sweetalert2";
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
export default {
    mixins: [
        SharedFunctionsComponentVue
    ],
    data(){
        return {
            form: {
                id: '',
                title_id: '',
                fullname: '',
                division: '',
                range: '',
                job_title_id: '',
                department_id: ''
            },
            loading: true,
            type_selected: ''
        }
    },
    computed: {
        ...mapGetters([
            "ACTION",
            "APPROVER_DIALOG",
            "SELECTED",
            "GET_ALL_JOB_TITLE",
            "GET_ALL_USER_LIST",
            "USERACCOUNT_LOAD",
            "GET_DEPARTMENT_FOR_ADMINISTRATIVE"
        ]),
        mappedJobTitle() {
            if(this.ACTION === 'View'){
                return this.GET_ALL_JOB_TITLE.map(item => ({
                    value: item.value,
                    text: item.text.toUpperCase(),
                }));
            }else{
                if(this.USERACCOUNT_LOAD.president === 1 || this.USERACCOUNT_LOAD.super_admin === 1){
                    if(this.ACTION === 'Add' || this.ACTION === 'Edit'){
                        let id = this.GET_DEPARTMENT_FOR_ADMINISTRATIVE.find(item => item.text === this.type_selected);
                        if (id) {
                            let filterPerDeptId = this.GET_ALL_JOB_TITLE.filter(item => item.department_id === id.value).map(item => ({
                                value: item.value,
                                text: item.text.toUpperCase()
                            }));
                            return filterPerDeptId;
                        } else {
                            return this.GET_ALL_JOB_TITLE.map(item => ({
                                value: item.value,
                                text: item.text.toUpperCase()
                            }));
                        }
                    }else{
                        return this.GET_ALL_JOB_TITLE.filter(item => item.department_id === this.USERACCOUNT_LOAD.department_id).map(item => ({
                            value: item.value,
                            text: item.text.toUpperCase()
                        }));
                    }
                }
                else{
                    return this.GET_ALL_JOB_TITLE.filter(item => item.department_id === this.USERACCOUNT_LOAD.department_id).map(item => ({
                        value: item.value,
                        text: item.text.toUpperCase()
                    }));
                }  
            }
            // console.log(this.GET_ALL_JOB_TITLE);
            // return this.GET_ALL_JOB_TITLE.map(item => ({
            //     value: item.value,
            //     text: item.text.toUpperCase(),
            // }));
        },
        mappedItems() {
            return this.GET_ALL_USER_LIST.map(item => ({
                value: item.value,
                text: item.text.toUpperCase(),
            }));
        },
        isLoading(){
            return  (this.ACTION === 'View' && !this.form.fullname) || 
                    (this.ACTION === 'Edit' && !this.form.fullname) || 
                    (this.ACTION === 'Add' && this.mappedItems.length === 0)
        },
        isLoadingJobTitle(){
            return  (this.ACTION === 'View' && !this.form.job_title_id) || 
                    (this.ACTION === 'Edit' && !this.form.job_title_id) || 
                    (this.ACTION === 'Add' && this.mappedJobTitle.length === 0)
        },
    },
    mounted() {
        this.$store.dispatch("getAllUsersList");
        this.$store.dispatch("getDepartmentForAdministrative");
        document.addEventListener('keydown', this.handleKeydown);
    },
    beforeDestroy(){
        document.removeEventListener('keydown', this.handleKeydown);
    },
    methods: {
        removeChip(index) {
            this.form.fullname.splice(index, 1);
        },
        resetField(){
            this.form = {
                id: '',
                title_id: '',
                fullname: '',
                division: '',
                range: '',
                job_title_id: '', 
                department_id: ''
            },
            this.loading = true,
            this.type_selected = ''
            this.$store.commit('ACTION', '');
            this.$store.commit('APPROVER_DIALOG', false);
        },
        async fetchAdministrativeApprover(){
            await this.$store.dispatch("EnterAdministrativeApproverGet", {
                url: 'get-administrative-approver/' + this.SELECTED,
                }, this.loading = true)
                .then(response => {
                if(!this.APPROVER_DIALOG){
                    this.loading = false;
                    return;
                }else{
                    this.form.id = response.data.id,
                    this.form.job_title_id = response.data.job_title_id,
                    this.form.title_id = response.data.title_id,
                    this.form.fullname = response.data.user,
                    this.form.division = response.data.division,
                    this.form.department_id = response.data.department_id
                    this.form.range =  response.data.range;
                }
            });
            let id = this.GET_DEPARTMENT_FOR_ADMINISTRATIVE.find(item => item.value === this.form.department_id);
            this.type_selected = id ? id.text : null;
        },
        modifyAdministrativeApprover(url){
            let id = this.GET_DEPARTMENT_FOR_ADMINISTRATIVE.find(item => item.text === this.type_selected);
            this.form.department_id = id.value;
            if (this.form.division == '' || this.form.range == '' || this.form.fullname == '' ) {
                swal.fire('Required all fields','','error');
            }
            else{
                if(isNaN(parseInt(this.form.range)))
                {
                    swal.fire('Range must be a valid number', '', 'error');
                }
                else{
                    let modifiedUrl = this.ACTION === 'Edit' ? `${url}/${this.form.id}` : url;
                    swal.fire({
                        title: "",
                        text: `Are you sure you want to ${this.ACTION} ?`,
                        icon: "warning",
                        showConfirmButton: true,
                        showCancelButton: true,
                        reverseButtons: true,
                        allowOutsideClick: false,
                        confirmButtonColor: '#397373',
                        cancelButtonColor: 'grey',
                        confirmButtonText: 'Confirm',
                        showCloseButton: true,
                        }).then((actions) =>{
                            if (actions.isConfirmed) {
                                this.$store.dispatch("EnterAdministrativeApproverPost", {
                                    url: modifiedUrl,
                                    ...this.form,
                                }).then(response=>{
                                    this.resetField();
                                    this.$store.dispatch("getAllRange");
                                    swal.fire('Success', '', 'success');
                                    this.$store.commit('SELECTED',{});
                                    this.$store.commit('APPROVER_DIALOG', false);
                                }).catch(err=>{
                                    console.error(err);
                                    swal.fire('Error!','','error');
                                });
                            }
                        }).catch(error => {
                            console.error(error);
                            swal.fire('Error!','','error');
                        });
    
                }
            }
        },
        handleKeydown(event){
            this.closeDialogByEsc(event, this.resetField)
        },
        resetFormValues() {
            Object.keys(this.form).forEach(key => {
                this.form[key] = null;
            });
        },
    },   
    watch: {
        USERACCOUNT_LOAD: {
            handler(val){
                this.$store.dispatch("getAllJobTitle",{id: val.department_id});
                if(val.super_admin === 1 || val.president === 1){
                    this.type_selected = 'Exec'
                }
                else{
                    if(val.department_id === 4){
                        this.type_selected = 'Accounting'
                    }else if(val.department_id === 1){
                        this.type_selected = 'Admin'
                    }
                }
            },
            immediate: true
        },
        'form': {
            handler(val){
            },
            deep: true
        },
        'ACTION': {
            handler(val) {
                if (['View','Edit'].includes(val)){
                    this.fetchAdministrativeApprover();
                } else {
                    this.resetFormValues();
                    return
                }
            },
            immediate: true,
            deep:true
        },
        'form.job_title_id': {
            handler(newVal, oldVal){
                if (this.form.job_title_id === null){
                    this.form.fullname = ''
                }
                if (this.ACTION === 'Add'){
                    if(oldVal != newVal)
                    {
                        this.form.fullname = ''
                    }
                }
                this.$store.dispatch("getAllUsersList", {
                                    id: newVal

                                }, this.loading = true
                                ).then(response=>{
                                    if(response.length === 0)
                                    {
                                        this.loading = false;
                                    }
                                    else{
                                        this.loading = false;
                                    }
                                });
            },
            deep: true
        },
        'form.fullname': {
            handler(val){
                this.loading = false;
            }
        },
        type_selected: {
            handler(val){
                if(this.ACTION === 'Edit' && val){
                    let tes = this.GET_DEPARTMENT_FOR_ADMINISTRATIVE.find(item => item.text ===  this.type_selected)
                    if (tes.value !== this.form.department_id){
                        this.form.fullname = ''
                    }
                }
            }
        }
    }
}
</script>
<style scoped>
::v-deep .right-align-text input {
    text-align: right;
}
</style>