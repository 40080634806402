<script>
import { mapGetters } from 'vuex';
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";

export default {
    mixins: [SharedFunctionsComponentVue],

    data() {
        return {
            data: Math.random(),

            paperWidth: 215.9,
            paperHeight: 279.4,
            margin: 10,

            headerStartY: undefined,
            headerEndY: undefined,
            headerWidth: undefined,
            headerHeight: undefined,

            footerStartY: undefined,
            footerEndY: undefined,
            footerWidth: undefined,
            footerHeight: undefined,

            printData: undefined,
        };
    },

    computed: {
        ...mapGetters([
            'GET_PV_COMPANIES_SELECTION',
            'GET_PV_STORE_BRANCHES_SELECTION',
            'GET_SELECTED_ADMIN_TRANSMITTAL',
            'GET_TRANSMITTAL_TRANSACTION_TYPES',
            'GET_TRANSMITTAL_PURCHASE_ORDER_DROPDOWN',
            'GET_TRANSMITTAL_JOB_ORDER_DROPDOWN',
            'GET_TRANSMITTAL_ENTER_BILL_DROPDOWN',
        ]),
    },

    methods: {
        drawAdminTransmittalPrintHeader(doc, headerData) {
            const host = window.location.origin;

            const imageWidth = 36;
            const imageHeight = 10;
            doc.addImage(
                `${host}/images/ingcoph-logo.png`,
                // headerData.image_path,
                'PNG',
                // headerData.ext.toUpperCase() == 'JPG' ? 'JPEG' : headerData.ext.toUpperCase(),
                (this.margin + (this.paperWidth - (this.margin * 2)) / 4) - (imageWidth / 2),
                this.margin,
                imageWidth,
                imageHeight,
            );

            const titleContainerWidth = 70;
            const titleContainerHeight = 8;
            const titleContainerX = (this.paperWidth / 2) + (((this.paperWidth / 2) - 5) / 2) - (titleContainerWidth / 2);
            const titleContainerY = this.margin;
            // doc.setDrawColor('#000000');
            // doc.setFillColor('#fde047');
            // doc.roundedRect(
            //     titleContainerX,
            //     titleContainerY,
            //     titleContainerWidth,
            //     titleContainerHeight,
            //     2,
            //     2,
            //     'FD',
            // );

            const title = 'Admin Transmittal';
            const titleFontSize = 20;
            const titleX = titleContainerX;
            const titleY = titleContainerY;
            doc.setFont(
                'Helvetica',
                'bold',
            );
            doc.setFontSize(titleFontSize);
            // doc.setTextColor('#f59e0b');
            doc.text(
                title,
                titleX,
                titleY + 8,
            );

            doc.autoTable({
                startY: this.margin + titleContainerHeight + 5,
                margin: {
                    bottom: this.margin,
                    top: this.margin,
                    left: this.margin,
                    right: this.margin,
                },
                theme: 'plain',
                columnStyles: {
                    0 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 12},
                    1 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 12},
                    2 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 12},
                    3 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 12},
                    4 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 12},
                    5 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 12},
                    6 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 12},
                    7 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 12},
                    8 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 12},
                    9 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 12},
                    10 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 12},
                    11 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 12},
                },
                bodyStyles: {
                    lineColor: [0, 0, 0],
                    textColor: [0, 0, 0],
                    fontSize: 10,
                    cellPadding: 1,
                },
                body: [
                    // ['', `Date: ${headerData.created_at}`],
                    [
                        {content: 'Date Transmitted:', colSpan: 2, styles: { fontStyle: 'bold'}},
                        {content: headerData.date_transmitted ?? '', colSpan: 2},
                        {content: '', colSpan: 2},
                        {content: 'Transmittal #', colSpan: 4, styles: { fontStyle: 'bold', halign: 'right'}},
                        {content: headerData.transmittal_number ?? '', colSpan: 2},
                    ],
                    [
                        {content: 'Date Received:', colSpan: 2, styles: { fontStyle: 'bold'}},
                        {content: headerData.date_received ?? '', colSpan: 2},
                        {content: '', colSpan: 2},
                        {content: 'Receiving Department:', colSpan: 4, styles: { fontStyle: 'bold', halign: 'right'}},
                        {content: headerData.department ?? '', colSpan: 2},
                    ],
                    [
                        {content: 'Remarks:', colSpan: 2, styles: { fontStyle: 'bold'}},
                        {content: headerData.remarks ?? '', colSpan: 2},
                        {content: '', colSpan: 2},
                        {content: 'Received By:', colSpan: 4, styles: { fontStyle: 'bold', halign: 'right'}},
                        {content: headerData.receiver ?? '', colSpan: 2},
                    ],
                    // [
                    //     `Requestor: ${headerData.requestor ? headerData.requestor : ''}`,
                    //     `Name: ${headerData.vendor_name ? headerData.vendor_name : ''}`,
                    // ],
                    // [
                    //     // `Department: ${headerData.department ? headerData.department : ''}`,
                    //     `Contact Person: ${headerData.contact_person ? headerData.contact_person : ''}`,
                    //     `Contact Person: ${headerData.vendor_contact_person ? headerData.vendor_contact_person : ''}`,
                    // ],
                    // [
                    //     `Location: ${headerData.location ? headerData.location : ''}`,
                    //     `Contact No.: ${headerData.vendor_contact_number ? headerData.vendor_contact_number : ''}`,
                    // ],
                    // [
                    //     `Contact No.: ${headerData.contact_number ? headerData.contact_number : ''}`,
                    //     `Payment Terms: ${headerData.payment_term ? headerData.payment_term : ''}`,
                    // ],
                    // [
                    //     `Date Needed: ${headerData.date_needed ? headerData.date_needed : ''}`,
                    //     `Checks Payable To: ${headerData.payable_to ? headerData.payable_to : ''}`,
                    // ],
                    // [
                    //     {content: '', colSpan: 2, styles: {fillColor: [254, 215, 170]}},
                    // ],
                ],
                // didDrawCell: (data) => {
                //     doc.rect(
                //         data.cell.x,
                //         data.cell.y,
                //         data.cell.width,
                //         data.cell.height,
                //     );
                // },
            });

            this.headerStartY = this.margin;
            this.headerEndY = doc.lastAutoTable.finalY;
            this.headerWidth = this.paperWidth - (this.margin * 2);
            this.headerHeight = this.headerEndY - this.headerStartY;

            return this.headerEndY;
        },

        drawAdminTransmittalPrintFooter(doc, footerData) {
            const footerHeight = 17;

            doc.autoTable({
                startY: this.paperHeight - (this.margin + footerHeight),
                margin: {
                    bottom: this.margin,
                    top: this.margin,
                    left: this.margin,
                    right: this.margin,
                },
                theme: 'grid',
                columnStyles: {
                    0 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 3},
                    1 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 3},
                    2 : {cellWidth: (this.paperWidth - (this.margin * 2)) / 3},
                },
                bodyStyles: {
                    minCellHeight: 13,
                    lineColor: [0, 0, 0],
                    textColor: [0, 0, 0],
                    fontSize: 8,
                },
                body: [
                    [
                        'Processed by',
                        'Approved by',
                        {
                            content: 'Transmittal #',
                            styles: {
                                valign: 'middle',
                                halign: 'left',
                                fontSize: 12,
                                fontStyle: 'bold',
                            },
                        },
                    ],
                ],
                didDrawCell: (data) => {
                    let cellText = '';

                    if (data.column.index == 0) cellText = `\n${footerData.processor ?? ''}`;
                    else if (data.column.index == 1) cellText = footerData.approved_by ?? '';
                    else if (data.column.index == 2) cellText = footerData.transmittal_number ?? '';

                    doc.autoTable({
                        startY: data.cell.y,
                        margin: {
                            top: data.cell.y,
                            bottom: this.paperHeight - (data.cell.y + data.cell.height),
                            left: data.cell.x,
                            right: this.paperWidth - (data.cell.x + data.cell.width),
                        },
                        theme: 'plain',
                        bodyStyles: {
                            minCellHeight: 13,
                            valign: 'end',
                            halign: 'center',
                            lineColor: [0, 0, 0],
                            textColor: [0, 0, 0],
                            fontSize: 8,
                        },
                        body: [
                            [{
                                content: cellText,
                                styles: data.column.index === 2
                                    ? {
                                        valign: 'middle',
                                        halign: 'right',
                                        textColor: [255, 0, 0],
                                        fontSize: 10,
                                        fontStyle: 'bold',
                                    } : {},
                            }],
                        ],
                    });
                },
            });

            this.footerStartY = doc.previousAutoTable.settings.startY;
            this.footerEndY = doc.previousAutoTable.finalY;
            this.footerWidth = this.paperWidth - (this.margin * 2);
            this.footerHeight = footerHeight;

            doc.setFont("Gill", "italic");
            doc.setFontSize(7);
            // doc.text(
            //     'ADM-010-v01-2021',
            //     this.margin,
            //     doc.previousAutoTable.finalY + 3,
            // );

            // doc.setFont("Helvetica", "normal");
            // doc.text(
            //     `Page ${doc.internal.getNumberOfPages()}`,
            //     this.margin,
            //     272,
            // );

            doc.setFont("Gill", "italic");
            // doc.text(
            //     '1 - Requestor 2 - Accounting 3 - Admin',
            //     170,
            //     doc.previousAutoTable.finalY + 3,
            // );

            return this.footerEndY;
        },

        drawAdminTransmittalPrintContent(doc, contentData) {
            this.GET_TRANSMITTAL_TRANSACTION_TYPES.forEach((type, index) => {
                doc.autoTable({
                    startY: index == 0 ? this.headerEndY + 5 : doc.lastAutoTable.finalY + 5,
                    margin: {
                        top: this.headerEndY + 5,
                        bottom: this.footerHeight + this.margin + 5,
                        left: this.margin,
                        right: this.margin,
                    },
                    theme: 'grid',
                    columnStyles: {
                        0: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                        1: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                        2: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                        3: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                        4: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                        5: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                        6: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                        7: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                        8: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                        9: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                        10: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                        11: {cellWidth: ((this.paperWidth - (this.margin * 2)) / 12)},
                    },
                    headStyles: {
                        halign: 'center',
                        lineColor: [0, 0, 0],
                        fillColor: [255, 255, 255],
                        textColor: [0, 0, 0],
                        fontSize: 8,
                    },
                    head: [
                        [
                            {content: type.title, colSpan: 12, styles: {
                                fontSize: 10,
                                // fillColor: [255, 255, 255],
                                halign: 'left',
                                fontStyle: 'bold',
                            }},
                        ],
                        [
                            {content: 'Company', colSpan: 3},
                            {content: 'Store Branch', colSpan: 3},
                            {content: '#', colSpan: 6},
                        ],
                    ],
                    bodyStyles: {
                        lineColor: [0, 0, 0],
                        textColor: [0, 0, 0],
                        fontSize: 8,
                    },
                    body: [
                        ...contentData[type.item].map(item => [
                            {
                                content: this.GET_PV_COMPANIES_SELECTION.find(company => company.value == item.company_id)?.text ?? '',
                                colSpan: 3,
                            },
                            {
                                content: this.GET_PV_STORE_BRANCHES_SELECTION.find(company => company.value == item.store_branch_id)?.text ?? '',
                                colSpan: 3,
                            },
                            {
                                content: this.transactionItems(type.model_name)
                                    .filter(el => item.transaction_ids.includes(el.value))
                                    .map(el => el.text).join(', '),
                                colSpan: 6,
                            },
                        ]),
                    ],
                    didDrawCell: (data) => {
                        if (data.section === 'head' && data.row.index == 0) return;
                        if (data.section === 'head') {
                            doc.rect(
                                data.cell.x,
                                data.cell.y,
                                data.cell.width,
                                data.cell.height,
                            );
                        }
                    },
                    didDrawPage: (data) => {
                        if (doc.getNumberOfPages() > 1) {
                            this.drawAdminTransmittalPrintHeader(doc, this.printData.header);
                            this.drawAdminTransmittalPrintFooter(doc, this.printData.footer);
                        }
                    },
                });

                let pageInfo = doc.internal.getCurrentPageInfo();
                for (let i = 0; i < pageInfo.pageNumber; i++) {
                    doc.setPage(i + 1);
                    // doc.text(`Page ${i + 1} out of ${pageInfo.pageNumber}`, 18, paperHeight - 18);
                    doc.setTextColor('black');
                    doc.setFont("Helvetica", "normal");
                    doc.text(
                        `Page ${i + 1} out of ${pageInfo.pageNumber}`,
                        this.paperWidth - (this.margin + 20),
                        272,
                    );
                }
            });
        },

        printAdminTransmittal(jo) {
            const doc = new this.$jspdf({
                orientation: 'portrait',
                unit: 'mm',
                format: 'letter',
            });

            this.printData = jo;

            this.drawAdminTransmittalPrintHeader(doc, this.printData.header);
            this.drawAdminTransmittalPrintFooter(doc, this.printData.footer);
            this.drawAdminTransmittalPrintContent(doc, this.printData.content);

            doc.autoPrint();
            window.open(doc.output("bloburl"), "_blank");
        },

        transactionItems(model_name) {
            let data = []

            switch (model_name) {
                case 'AdminTransmittalPurchaseOrder':
                    data = this.GET_TRANSMITTAL_PURCHASE_ORDER_DROPDOWN
                    break;
                case 'AdminTransmittalJobOrder':
                    data = this.GET_TRANSMITTAL_JOB_ORDER_DROPDOWN
                    break;
                case 'AdminTransmittalEnterBill':
                    data = this.GET_TRANSMITTAL_ENTER_BILL_DROPDOWN
                    break;
            }

            return data;
        },
    },
    watch: {
        'data': {
            handler(val) {
                // this.printAdminTransmittal();
            },
            immediate: true,
        }
    },
}
</script>