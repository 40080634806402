<template>
        <v-card elevation=0>
          <v-card-text class="p-2 m-0">
              <v-row class="p-2">
                <v-col cols="2" class="pt-3">
                  <v-autocomplete
                    v-model="vendor"
                    label="Vendor"
                    auto-select-first
                    outlined
                    dense
                    :items="GET_ADMIN_SUPPLIER_SELECTION"
                    class="mx-2"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="2" class="pt-3">
                  <v-menu
                    :close-on-content-click="true"
                    :nudge-right="40"
                    transition="scale-transition"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="request_date_from"
                            prepend-inner-icon="mdi-calendar"
                            outlined
                            dense
                            readonly
                            v-on="on"
                            style="display: block"
                            background-color="white"
                            label="Date From"
                            clearable
                            class="mx-2"
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="request_date_from"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="2" class="pt-3">
                  <v-menu
                    :close-on-content-click="true"
                    :nudge-right="40"
                    transition="scale-transition"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="request_date_to"
                            prepend-inner-icon="mdi-calendar"
                            outlined
                            dense
                            readonly
                            v-on="on"
                            style="display: block"
                            background-color="white"
                            label="Date To"
                            clearable
                            class="mx-2"
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="request_date_to"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-spacer></v-spacer>
                <v-col lg="3" md="4" class="d-flex justify-content-end align-items-end mr-3">
                  <v-text-field
                        id="searchBar"
                        v-model="filter.search"
                        label="Search by JO/Requestor"
                        single-line
                        hide-details
                        append-icon="mdi-magnify"
                        @keydown.enter="searchInput()"
                        @click:append="searchInput()"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
                <v-row dense class="p-2">
                    <v-col lg="2">
                        <v-autocomplete
                            :items="GET_COMPANY_SELECTION"
                            v-model="filter.company_id"
                            class="mx-2"
                            label="Company"
                            outlined
                            dense
                            hide-details
                            clearable
                            auto-select-first
                            multiple
                            >

                        </v-autocomplete>
                    </v-col>
                    <v-col lg="2">
                        <v-autocomplete
                            :items="GET_PV_STORE_BRANCHES_SELECTION"
                            v-model="filter.store_branch_id"
                            class="mx-2"
                            label="Store Branch"
                            outlined
                            dense
                            hide-details
                            clearable
                            auto-select-first
                            multiple
                            >

                        </v-autocomplete>
                    </v-col>
                    <v-col lg="2">
                        <v-autocomplete
                            v-model="filter.preparer"
                            :items="preparer_selection"
                            class="mx-2"
                            label="Prepared By"
                            item-text="name"
                            item-value="id"
                            outlined
                            dense
                            hide-details
                            clearable
                            auto-select-first
                            multiple
                            chips
                            deletable-chips
                            small-chips
                            >

                        </v-autocomplete>
                    </v-col>
                </v-row>
              <v-row class="pb-5">
                <v-col lg="3" md="4" class="mt-5">
                  <!-- <v-btn class="ml-5 mb-2" @click="AddDialog()" small> Job Order </v-btn> -->
                </v-col>
                <v-spacer></v-spacer>
                <!-- <v-col lg="1" class="d-flex justify-content-center align-items-end">
                  <v-btn @click="searchInput()" icon >
                    <v-icon >mdi-magnify</v-icon>
                  </v-btn>
                </v-col> -->
            </v-row>
              <v-col lg="12">
                <v-data-table
                  v-model="selectedItems"
                  :headers="getDataTableHeaders"
                  :items="items"
                  :search="filter.search"
                  :options.sync="options"
                  :server-items-length="totalItems"
                  :items-per-page="10"
                  :loading="!loaded"
                  :show-select="[1, 3, 4, 5, 6, 8].includes(SELECTED_TAB)"
                  @item-expanded="getVehicleDetails"
                  single-expand
                  show-expand
                  disable-sort
                  :footer-props="{
                    itemsPerPageOptions: itemsPerPageOptions,
                  }"
                  loading-text="Loading... Please wait"
                  fixed-header
                >
                <template v-slot:[`item.data-table-expand`]="{ item, expand, isExpanded }">
                    <td class="text-start" v-if="item.vehicle_id != 0">
                        <v-tooltip left>
                            <template #activator="{ on: onTooltip }">
                                <v-btn
                                    v-on="{ ...onTooltip }"
                                    :class="{'v-data-table__expand-icon--active': isExpanded}"
                                    class="v-data-table__expand-icon"
                                    color="black"
                                    text
                                    icon
                                    x-small
                                    @click="expand(!isExpanded)"
                                >
                                    <v-icon>mdi-chevron-down</v-icon>
                                </v-btn>
                            </template>
                            <span>View Vehicle Items</span>
                        </v-tooltip>
                    </td>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="p-0">
                        <v-data-table
                            :headers="data_table.headers.expanded"
                            :items="data_table.items.expanded"
                            :loading="data_table.loading.expanded"
                            dark
                            dense
                            hide-default-footer
                            fixed-header
                            disable-sort
                        >
                        <template v-slot:[`item.requested_date`]="{item}">
                            <span>{{ item.requested_date | formatDate }}</span>
                        </template>
                        <template v-slot:[`item.total_amount`]="{item}">
                            <span>{{ item.total_amount | currency }}</span>
                        </template>
						<template v-slot:[`item.action`]="{ item }">
							<v-tooltip top >
								<template v-slot:activator="{ on }">
								<v-btn
								v-on="on"
								text
								icon
								color="orange"
								>
									<v-icon
									class="btn-action"
									small
									@click="viewJODetails(item)"
									>mdi-eye</v-icon>
								</v-btn>
								</template>
								<span>View Details</span>
							</v-tooltip>
						</template>
                        </v-data-table>
                    </td>
                </template>
                <template v-slot:[`item.created_at`]="{item}">
                  <span>{{ item.created_at | formatDate }}</span>
                </template>
                <template v-slot:[`item.total_cost`]="{item}">
                  <span>&#x20b1;{{ item.total_cost | currency }}</span>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <a  class="ml-1 badge text-white" :style="`background-color:${STATUS.ADMIN_JOB_ORDER_STATUS.find(e=>e.value === item.status)?.color}`"
                  >
                    {{STATUS.ADMIN_JOB_ORDER_STATUS.find(e=>e.value === item.status)?.text}}
                  </a>
                </template>
                  <template v-slot:[`item.action`]="{ item }">
                    <v-tooltip top v-if="userAccess.view">
                        <template v-slot:activator="{ on }">
                          <v-btn
                          v-on="on"
                          text
                          icon
                          color="orange"
                          >
                            <v-icon
                              class="btn-action"
                              small
                              @click="ViewJOForm(item,'View')"
                              >mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span>View JO</span>
                    </v-tooltip>
                    <v-tooltip top
                      v-if="
                        (userAccess.edit && [1].includes(item.status)) ||
                        (
                          ([2, 3, 6, 8].includes(item.status)) &&
                          (USERACCOUNT_LOAD.manager || USERACCOUNT_LOAD.super_admin || USERACCOUNT_LOAD.president)
                        )
                      ">
                      <template v-slot:activator="{ on }">
                        <v-btn text icon color="blue"
                          v-on="on"

                        >
                          <v-icon
                            class="btn-action"
                            small
                            @click="ViewJOForm(item,'Update')"
                            >mdi-square-edit-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Edit JO</span>
                    </v-tooltip>
                    <v-tooltip top
                        v-if="
                            userAccess.delete &&
                            (
                              [1, 3, 8].includes(item.status) ||
                              ((item.payment_method == 2 && item.status == 6))
                            )
                          "
                      >
                      <template v-slot:activator="{ on }">
                        <v-btn text icon color="red"
                          v-on="on"
                        >
                          <v-icon
                            class="btn-action"
                            small
                            @click="deleteJO(item)"
                            >mdi-cancel</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Cancel or Delete JO</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:footer >
                    <div style="margin: 0px 0px -45px 15px; padding-top: 8px">
                      <v-btn
                        color="success"
                        dark
                        float
                        @click="massApproval"
                        :disabled="![1, 3, 4, 5, 6, 8].includes(SELECTED_TAB) || !loaded"
                      >Approve</v-btn>
                    </div>
                  </template>
                </v-data-table>
              </v-col>
          </v-card-text>
          <v-dialog v-model="DIALOGS" persistent>
            <JobOrderViewComponent></JobOrderViewComponent>
          </v-dialog>
          <v-dialog v-model="massApprove.dialog" width="400">
              <v-card>
                  <v-card-title>
                    Updating: {{massApprove.currentPO}}
                  </v-card-title>
                  <v-card-text class="d-flex justify-content-center align-items-center">

                      <v-progress-linear
                      v-model="massApprove.value"
                      :buffer-value="100"
                      color="success"
                      :rounded="true"
                  ></v-progress-linear>
                  </v-card-text>
              </v-card>

          </v-dialog>
          <ConfirmOverrideComponent
              :approve_override_dialog="override.override_dialog"
              :departments_allowed="override.override_departments_allowed"
              :heading="override.heading"
              @closeConfirmOverride="closeConfirmOverride"
          />
        </v-card>

  </template>
  <script>
  import JobOrderViewComponent from "@/views/main/layouts/dialogs/Admin/JobOrderViewComponent.vue";
  import ConfirmOverrideComponent from "@/views/main/Utils/ConfirmOverrideComponent.vue";
  import swal from "sweetalert2";
  import { mapGetters } from 'vuex';
  export default {
    components:{
      JobOrderViewComponent,
      ConfirmOverrideComponent,
    },
    data() {
      return {
        massApprove:{
            value:0,
            currentPO:0,
            dialog:false
        },
        selectedItems:[],
        loaded: false,
        totalItems: 0,
        options: {},
        items: [],
        itemsPerPageOptions: [5, 10, 15, 50, 100],
        itemsPerPage: 10,
        filter: {
          search: "",
          status:0,
          company_id:'',
          store_branch_id:'',
          preparer:''
        },
        request_date_from: this.$dayjs().startOf('year').format('YYYY-MM-DD'),
        request_date_to: this.$dayjs().endOf('month').format('YYYY-MM-DD'),
        vendor: null,
        awaitingSearch: false,
        data_table:{
            headers:{
                main:[
                    { text: "JO #", align: "left", value: "jo_number" },
                    { text: "Requested Date", align: "center", value: "created_at" },
                    { text: "Requested By", align: "left", value: "full_name" },
                    { text: "Vendor Name", align: "left", value: "company_name" },
                    { text: "Total Amount", align: "right", value: "total_amount" },
                    { text: "Cancel Remarks", value: "cancel_reason" },
                    { text: "Status", align: "center", value: "status" },
                    { text: "Action", align: "center", value: "action"},
                ],
                expanded:[
                    { text: "REF. DOC", align: "left", value: "jo_number" },
                    { text: "PLATE#", align: "center", value: "plate_number" },
                    { text: "SERVICE PROVIDER", align: "center", value: "company_name" },
                    { text: "DESCRIPTION", align: "left", value: "description" },
                    { text: "PARTICULARS", align: "left", value: "service_names" },
                    { text: "TOTAL AMOUNT", align: "right", value: "total_amount" },
                    { text: "DATE", value: "requested_date" },
                    { text: "ACTION", value: "action" },
                ],
            },
            items:{
                main:[],
                expanded:[],
                main_total: 0,
                expanded_total: 0,
            },
            loading:{
                main: false,
                expanded: false,
            },
            options: {},
            itemsPerPageOptions: [5, 10, 15, 50, 100],
            itemsPerPage: 10,
        },
        count:0,
        userAccess: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
        selected_id: undefined,
        override: {
            override_dialog: false,
            heading: '',
            reset: 0,
            delete: 0,
            errors: [],
            override_departments_allowed: ['admin'],
            override_authenticated: false,
        },
        preparer_selection: [],
      };
    },
    computed:{
      ...mapGetters([
        "USER_ACCESS",
        "GET_JO_DISPATCH",
        "GET_NEW_JO_TABLE",
        "GET_JO_DISPATCH_STATUS",
        "STATUS",
        'DIALOGS',
        'SELECTED_TAB',
        'GET_ADMIN_SUPPLIER_SELECTION',
        'GET_COMPANY_SELECTION',
        'GET_PV_STORE_BRANCHES_SELECTION',
        'GET_ALL_USERS_SELECT',
        'GET_EXPORT_JOB_ORDERS',
        'GET_JOB_ORDERS',
        'USERACCOUNT_LOAD',
      ]),
      getDataTableHeaders() {
        const headers = [
          { text: "JO #", align: "left", value: "jo_number" },
          { text: "Requested Date", align: "center", value: "created_at" },
          { text: "Requested By", align: "left", value: "full_name" },
          { text: "Vendor Name", align: "left", value: "company_name" },
          { text: "Total Amount", align: "right", value: "total_cost" },
          { text: "Cancel Remarks", value: "cancel_reason" },
          { text: "Status", align: "center", value: "status" },
          { text: "Action", align: "center", value: "action",sortable:false},
        ];
        if (![0, 3, 8, 6, 9].includes(this.SELECTED_TAB))
          return headers.filter(header => header.value != 'cancel_reason');
        return headers;
      },
    },
    mounted() {
      this.$store.dispatch(
        'checkAccessUser',
        this.$router.currentRoute.params.id
      );
      this.$store.dispatch('getAdminSupplierSelection');
      this.$store.dispatch("getDepartment");
      this.$store.dispatch("getAdminLocationSelection");
      this.$store.dispatch("repairTypeSelection");
      this.$store.dispatch("getPvCompanies", { follow_user_access: 1 });
      this.$store.dispatch('getPaymentVoucherBranchesSelection', { follow_user_access: 1 });
      this.$store.dispatch('getAdminItemUomSelection');
      this.$store.dispatch("getCompanySelection");
      this.$store.dispatch("getPaymentVoucherBranchesSelection");
      this.$store.dispatch("getAllSelectUsers", {
            department_id: 1
        });
    },
    methods: {
      async closeConfirmOverride(user_id, authenticated = false) {
          if (authenticated) {
              document.activeElement.blur();
              this.override.override_dialog = false;
              this.override_confirm = true;
              swal.fire({
                  title: `${this.override.reset
                      ? 'Reset'
                      : this.override.delete
                          ? 'Delete'
                          : 'Cancel'} Reason Remarks`,
                  input: 'text',
                  inputLabel: 'Enter Reason',
                  showConfirmButton:true,
                  confirmButtonColor: '#397373',
                  confirmButtonText:'Confirm',
                  inputValidator: (value) => {
                      if (!value) {
                          return 'You need to write something!'
                      }
                  },
                  allowOutsideClick: () => {
                      const popup = swal.getPopup()
                      popup.classList.remove('swal2-show')
                      setTimeout(() => {
                          popup.classList.add('animate__animated', 'animate__headShake')
                      })
                      setTimeout(() => {
                          popup.classList.remove('animate__animated', 'animate__headShake')
                      }, 500)
                      return false
                  }
              }).then((result) => {
                  if (result.isConfirmed && result.value != '') {
                      let payload = {
                          admin_job_order_id: this.selected_id,
                          cancel_reason: result.value,
                          reset: this.override.reset,
                          delete: this.override.delete,
                          url: "cancel-job-order"
                      }
                      this.$store.dispatch('jobOrderPost',payload).then((response) => {
                          swal.fire(response.title,response.msg,response.status)
                          this.getAllJO()
                      })
                  }
              })

          } else {
              this.override.override_dialog = false;
          }
      },
      async massApproval(){
        swal.fire({
          title:"Approve all!",
          text:'Are you sure you want to approve selected Item/s ?',
          icon:'question',
          showCancelButton:true,
          reverseButtons:true
        }).then(async action=>{
          if(action.isConfirmed){
            let success = []
            this.loaded = false;
            this.massApprove.dialog = true
            for (let i in this.selectedItems) {

              const currentItem = this.items.find(item => item.id == this.selectedItems[i].id);

                this.massApprove.currentPO = this.selectedItems[i].id
                this.massApprove.value += (100 / this.selectedItems.length)
              try {

                let payload = {
                  url: 'update-job-order-status',
                  form: {
                    id: currentItem.id,
                    status: undefined,
                  },
                };

                // saved -> admin
                if (currentItem.status == 1) payload.form.status = 3;

                // admin -> exec
                else if (currentItem.status == 3) payload.form.status = 8;

                // 50/50 exec -> for payment
                else if (currentItem.payment_method == 1 && currentItem.status == 8) payload.form.status = 2;
                // 50/50 partial -> on going
                else if (currentItem.payment_method == 1 && currentItem.status == 5) payload.form.status = 6;
                // 50/50 on going -> for payment
                else if (currentItem.payment_method == 1 && currentItem.status == 6) {
                  payload.form.accomplished = 1;
                  payload.form.status = 2;
                }

                // SBP exec -> on going
                else if (currentItem.payment_method == 2 && currentItem.status == 8) payload.form.status = 6;
                // SBP on going -> for payment
                else if (currentItem.payment_method == 2 && currentItem.status == 6) {
                  payload.form.accomplished = 1;
                  payload.form.status = 2;
                }

                // PBS exec -> for payment
                else if (currentItem.payment_method == 3 && currentItem.status == 8) payload.form.status = 2;
                // PBS paid -> on going
                else if (currentItem.payment_method == 3 && currentItem.status == 4) payload.form.status = 6;
                // PBS on going -> completed
                else if (currentItem.payment_method == 3 && currentItem.status == 6) {
                  payload.form.accomplished = 1;
                  payload.form.status = 7;
                }

                await this.$store.dispatch('jobOrderPost', payload).then(response => {
                  success.push(this.selectedItems[i].id)
                }).catch(e => {
                  throw e; // Rethrow the error to trigger the catch block
                });
              } catch (err) {
                console.log(err);

                let continueExecution = undefined;

                if (err.response.status === 406) {
                  continueExecution = await swal.fire({
                    title: `An error occurred in ${this.selectedItems[i].id}`,
                    text: `- ${err.response.data.error} Do you want to continue?`,
                    icon: 'error',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No'
                  });
                } else {
                  continueExecution = await swal.fire({
                    title: `An error occurred in ${this.selectedItems[i].id}`,
                    text: '- Do you want to continue?',
                    icon: 'error',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No'
                  });
                }
                if (!continueExecution.isConfirmed) {
                  break; // Exit the loop if the user chooses not to continue
                }
              }
            }
            this.massApprove.value = 0
            this.loaded = true;
            swal.fire('Done!',`- ${success}`,'success')
            this.selectedItems = []
            this.massApprove.dialog = false
            this.getAllJO()
          }
        })
      },
      getAllJO(filter = null) {
        this.items = [];
        this.loaded = false;
        let payload = {
          url: 'getAllJO',
          page:         this.options.page,
          itemsPerPage: this.options.itemsPerPage,
          sortBy:       this.options.sortBy,
          sortDesc:     this.options.sortDesc,
          request_date_from: this.request_date_from,
          request_date_to: this.request_date_to,
          vendor: this.vendor,
          ...this.filter
        };
        this.$store.dispatch('jobOrderGet', payload)
        .then((response) => {
            this.items = response.data.data;
            this.loaded = true;
            this.totalItems = response.data.total;
          }).catch((e)=>{
          this.loaded = true;
        });
      },
      searchInput(){
        this.options.page = 1
        this.getAllJO({ query: this.search });
      },
      ViewJOForm(item, action) {
        // this.$store.dispatch("getJOItems", { id: item.id });
        this.$store.commit("EDIT_JO", item);
        this.$store.commit("DIALOG", true);
        this.$store.commit("ACTION", action);
      },
	  viewJODetails(item){
		this.$store.commit("EDIT_JO", item);
        this.$store.commit("DIALOG", true);
        this.$store.commit("ACTION", 'View');
	  },
      deleteJO(item) {
        if ((item.payment_method == 2 && item.status == 6)) {
          this.selected_id = item.id;
          this.override.reset = 0;
          this.override.delete = 0;
          this.override.heading = '(Cancel JO)';
          this.override.override_dialog = true;

          return;
        }
        swal.fire({
            title: `Are you sure you want to <strong><u style="color: #f44336;">CANCEL</u></strong> or <strong><u style="color: #f44336;">DELETE</u></strong> ${item.status != 1 ? 'or <strong><u style="color: #f44336;">RESET</u></strong> ?!' : '?!'}`,
            icon: 'question',
            showConfirmButton: item.status != 1 ? true : false,
            confirmButtonColor: '#397373',
            confirmButtonText: 'Reset JO',
            showDenyButton: true,
            denyButtonText: 'Delete JO',
            showCancelButton: true,
            cancelButtonText: 'Cancel JO',
            reverseButtons: true,
            allowOutsideClick: false,
            footer: 'This action is irreversible!',
            html: `
                <span id="closeButton" style="position: absolute; top: 5px; right: 15px; font-size: 1.5em; cursor: pointer;">&times;</span>
            `,
            didOpen: () => {
                const closeButton = document.getElementById('closeButton');
                closeButton.addEventListener('click', () => {
                    swal.close();
                });
            }
        }).then(result => {
            if (result.isConfirmed || result.isDenied || result.dismiss === swal.DismissReason.cancel) {
                this.selected_id = item.id;
                this.override.reset = result.isConfirmed ? 1 : 0;
                this.override.delete = result.isDenied ? 1 : 0;
                this.override.heading = result.isConfirmed
                    ? '(Reset JO)'
                    : result.isDenied
                        ? '(Delete JO)'
                        : '(Cancel JO)';
                this.override.override_dialog = true;
            }
        });
        // swal
        //   .fire({
        //     icon: 'question',
        //     text: "- Are you sure you want to Delete?",
        //     cancelButtonText: "Cancel",
        //     showCancelButton: true,
        //     allowOutsideClick: false,
        //     confirmButtonColor: "#397373",
        //     cancelButtonColor: "#397373",
        //     reverseButtons:true
        //   })
        //     .then((result) => {
        //       if (result.isConfirmed) {
        //         this.$store
        //         .dispatch('jobOrderPost', {
        //           url: `DeleteJO/${id}`,
        //           id: id
        //         }).then(response => {
        //           swal.fire('Deleted Successfully!', '', 'success')
        //         }).catch(err=>{
        //           swal.fire('Error','','error')
        //         });
        //       }
        //     });
      },
      AddDialog() {
        this.$store.dispatch('getLastJONum');
        // this.$store.dispatch('getAllRRFNum');
        this.$store.commit("ADMIN_ITEMS_LIST_SECTION", true);
        this.$store.commit("DIALOG", true);
        this.$store.commit("ACTION", "Submit");
      },
      exportDataTable() {
            const payload = {
              request_date_from: this.request_date_from,
              request_date_to: this.request_date_to,
              vendor: this.vendor,
              ...this.filter
            };

            this.$store.dispatch('exportJobOrders', payload).then(response =>{
                const workbook = new this.$exceljs.Workbook();

                const summary = workbook.addWorksheet('SUMMARY');
                summary.columns = [
                    {header:'JO #', key:'jo_number'},
                    {header:'REQUESTED BY', key:'requestor'},
                    {header:'COMPANY', key:'company'},
                    {header:'STORE_BRANCH', key:'store_branch'},
                    {header:'DEPARTMENT', key:'department'},
                    {header:'LOCATION', key:'location'},
                    {header:'VENDOR', key:'vendor'},
                    {header:'PAYMENT TERMS', key:'payment_terms'},
                    {header:'CONTACT PERSON', key:'contact_person'},
                    {header:'CHECKS PAYABLE TO', key:'checks_payable_to'},
                    {header:'DATE NEEDED', key:'date_needed'},
                    {header:'WORK DURATION', key:'work_duration'},
                    {header:'CLIENT CONTACT PERSON', key:'client_contact_person'},
                    {header:'CLIENT CONTACT NUMBER', key:'client_contact_number'},
                    {header:'PAYMENT METHOD', key:'payment_method'},
                    {header:'SERVICE TYPE', key:'service_type'},
                    {header:'SERVICE PARTICULAR', key:'service_particular'},
                    {header:'OTHER SERVICE TYPE', key:'other_service_type'},
                    {header:'OTHER SERVICE PARTICULAR', key:'other_service_particular'},
                    {header:'TOTAL AMOUNT', key:'total_amount', style:{numFmt: '#,##0.00'}},
                    {header:'STATUS', key:'status'},
                    {header:'REMARKS', key:'remarks'},
                    {header:'CANCEL REMARKS', key:'cancel_remarks'},
                ]

                let headerRow = summary.getRow(1);
                headerRow.eachCell((cell) => {
                    cell.font = { bold: true };
                    cell.alignment = { vertical: 'middle', horizontal: 'center' };
                });

                response.data.summary.forEach(item=>{
                    item.date_needed = this.$dayjs(item.date_needed).format('YYYY-MM-DD');
                    item.payment_method = this.GET_JOB_ORDERS.JO_PAYMENT_METHOD.find(el => el.value == item.payment_method)?.text;
                    item.status = this.STATUS.ADMIN_JOB_ORDER_STATUS.find(e => e.value === item.status)?.text;
                    item.total_amount = parseFloat(item.total_amount);
                    summary.addRow(item);
                })

                const detailed = workbook.addWorksheet('DETAILED');
                detailed.columns = [
                    {header:'JO #', key:'jo_number'},
                    {header:'REQUESTED BY', key:'requestor'},
                    {header:'COMPANY', key:'company'},
                    {header:'STORE_BRANCH', key:'store_branch'},
                    {header:'DEPARTMENT', key:'department'},
                    {header:'LOCATION', key:'location'},
                    {header:'VENDOR', key:'vendor'},
                    {header:'PAYMENT TERMS', key:'payment_terms'},
                    {header:'CONTACT PERSON', key:'contact_person'},
                    {header:'CHECKS PAYABLE TO', key:'checks_payable_to'},
                    {header:'DATE NEEDED', key:'date_needed'},
                    {header:'WORK DURATION', key:'work_duration'},
                    {header:'CLIENT CONTACT PERSON', key:'client_contact_person'},
                    {header:'CLIENT CONTACT NUMBER', key:'client_contact_number'},
                    {header:'PAYMENT METHOD', key:'payment_method'},
                    {header:'SERVICE TYPE', key:'service_type'},
                    {header:'SERVICE PARTICULAR', key:'service_particular'},
                    {header:'OTHER SERVICE TYPE', key:'other_service_type'},
                    {header:'OTHER SERVICE PARTICULAR', key:'other_service_particular'},
                    {header:'DETAIL TYPE', key:'detail_type'},
                    {header:'DETAIL NAME', key:'detail_name'},
                    {header:'PRICE', key:'price', style:{numFmt: '#,##0.00'}},
                    {header:'QTY', key:'quantity', style:{numFmt: '#,##0'}},
                    {header:'AMOUNT', key:'detail_total_amount', style:{numFmt: '#,##0.00'}},
                    {header:'DIMENSION', key:'dimension'},
                    {header:'CBM', key:'cbm', style:{numFmt: '#,##0.00'}},
                    // {header:'TOTAL AMOUNT', key:'total_amount', style:{numFmt: '#,##0.00'}},
                    {header:'STATUS', key:'status'},
                    {header:'REMARKS', key:'remarks'},
                    {header:'CANCEL REMARKS', key:'cancel_remarks'},
                    // {header:'CANCEL REMARKS', key:'cancel_remarks'},
                ]

                headerRow = detailed.getRow(1);
                headerRow.eachCell((cell) => {
                    cell.font = { bold: true };
                    cell.alignment = { vertical: 'middle', horizontal: 'center' };
                });

                response.data.detailed.forEach(item=>{
                    item.date_needed = this.$dayjs(item.date_needed).format('YYYY-MM-DD');
                    item.payment_method = this.GET_JOB_ORDERS.JO_PAYMENT_METHOD.find(el => el.value == item.payment_method)?.text;
                    item.status = this.STATUS.ADMIN_JOB_ORDER_STATUS.find(e => e.value === item.status)?.text;
                    item.price = parseFloat(item.price);
                    item.quantity = parseInt(item.quantity);
                    item.detail_total_amount = parseFloat(item.detail_total_amount);
                    item.cbm = parseFloat(item.cbm ?? 0);
                    // item.total_amount = parseFloat(item.total_amount);
                    detailed.addRow(item);
                })

                let exportName = `Job Order`;
                workbook.xlsx.writeBuffer().then((data) => {
                    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                    this.$filesaver.saveAs(blob, `${exportName}.xlsx`);
                });

                this.$store.commit('EXPORT_JOB_ORDERS', false);
            }).catch(err =>{
                console.log(err);
            })
        },
        async getVehicleDetails(item){
            let index = _.findIndex(this.items, item.item);
            this.data_table.items.expanded = [];
            this.data_table.loading.expanded = true;
            if(this.items[index].vehicle_id){
                await this.$store.dispatch('jobOrderGet', {
                    url: `get-vehicle-details/${this.items[index].vehicle_id}`,
                }).then((response) => {
                    this.data_table.items.expanded = response;
                    this.data_table.loading.expanded = false;
                })
            }
        }
    },
    watch: {
      GET_NEW_JO_TABLE:{
        handler(val){
          if(val){
            this.getAllJO();
          }
        }
      },
      'filter.company_id':{
        handler(val){
            this.getAllJO()
        },
        deep:true
      },
      'filter.preparer':{
        handler(val){
            this.getAllJO()
        },
        deep:true
      },
      'filter.store_branch_id':{
        handler(val){
            this.getAllJO()
        },
        deep:true
      },
      'DIALOGS':{
            handler(val){
                if(!val.search){
                    this.getAllJO()
                }
            }
        },

      options: {
        handler() {
          this.getAllJO();
        },
        deep: true,
      },
      USER_ACCESS: {
        handler(val) {
          if (val != "fail") {
            this.userAccess.edit = false;
            this.userAccess.delete = false;
            this.userAccess.view = false;
            this.userAccess.create = false;
            val.forEach((e) => {
              if (e.actions_code == "edit") {
                this.userAccess.edit = true;
              } else if (e.actions_code == "delete") {
                this.userAccess.delete = true;
              } else if (e.actions_code == "view") {
                this.userAccess.view = true;
              } else if (e.actions_code == "create") {
                this.userAccess.create = true;
              }
            });
          }
        },
      },
      'SELECTED_TAB':{
        handler(val){
          this.filter.status = val
          this.getAllJO()
        }
      },
      'vendor': {
        handler() {
          this.getAllJO();
        }
      },
      'request_date_from': {
        handler() {
          this.getAllJO();
        }
      },
      'request_date_to': {
        handler() {
          this.getAllJO();
        }
      },
      GET_EXPORT_JOB_ORDERS: {
        handler(val) {
          if (!val) return;
          this.exportDataTable();
        },
      },
      GET_ALL_USERS_SELECT:{
            handler(val){
                if(val){
                  this.preparer_selection = val.filter(e => e.department_id == 1);
                }
            }
        },
    },
  };
  </script>
