<template>
  <div>
      <div class="page-wrapper ma-1">
          <div class="row p-2">
              <div class="col-12 d-flex no-block align-items-center">
                  <h4 class="page-title">Cannibalize</h4>
                  <add-baklas-dialog @successCreate="successCreate" />
              </div>
          </div>

          <div class="container-fluid">
              <div id="item-tabs" class="row mx-auto">
                    <v-tabs id="group-tab" color="cyan" dark slider-color="cyan" background-color="#424242" class="col-12 pb-0" show-arrows v-model="tab">
                        <v-tab ripple >New</v-tab>
                        <v-tab ripple >Available</v-tab>
                        <v-tab ripple >Consumed</v-tab>
                        <v-tab ripple >disposed</v-tab>
                        <v-tab ripple >All</v-tab>
                        <v-tab ripple >Source</v-tab>
                        <v-tab ripple >Add Unit</v-tab>
                    </v-tabs>
                    <add-unit-baklas-component v-if="tab > 5"></add-unit-baklas-component>
                    <baklas-table-component :baklasCreateSuccess="baklasCreateSuccess" :tab="tabs[tab]" v-if="tab <= 4"/>
                    <v-container fluid v-if="tab > 4 && tab < 6" style="width:100%;padding:0px">
                        <v-card>
                            <v-card-text>
                                <v-row>
                                    <v-col lg12 class="d-flex justify-content-end">
                                        <v-btn @click="toggleDialog('baklasSource'); baklasSource.dataTable.action = 0" small v-if="userAccess.includes('add_source')">
                                            <v-icon>mdi-plus</v-icon> Add
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-data-table
                                            :items="baklasSource.dataTable.items"
                                            :headers="baklasSource.dataTable.headers"
                                            :loading="!baklasSource.dataTable.loaded"
                                        >
                                            <template v-slot:[`item.actions`]="{ item }">
                                                <span class="text-nowrap">
                                                    <!-- <v-btn text icon color="orange">
                                                        <v-icon class="btn-action" small @click="viewUser(item.id,item.head_id)">mdi-eye</v-icon>
                                                    </v-btn> -->

                                                    <v-btn text icon color="blue">
                                                        <v-icon class="btn-action" small  @click="view(item); baklasSource.dataTable.action = 1" v-if="userAccess.includes('edit_source')">mdi-pencil</v-icon>
                                                    </v-btn>
                                                    <v-btn text icon color="red">
                                                        <v-icon class="btn-action" small  @click="deleteList(item.id,item.name)" v-if="userAccess.includes('delete_source')">mdi-delete</v-icon>
                                                    </v-btn>
                                                </span>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-container>
              </div>
          </div>
      </div>
      <v-dialog v-model="baklasSource.dialog" width="400px">
            <v-card height="350px">
                <v-card-title class="d-flex justify-content-between">
                    <span> Add Source</span>
                    <span>
                        <v-btn small text fab @click="toggleDialog('baklasSource')">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </span>
                </v-card-title>
                <v-card-text>
                    <v-text-field v-model="baklasSource.form.source" label="Source" outlined dense/>
                    <v-textarea v-model="baklasSource.form.description" label="Desciption" outlined dense class="py-3"/>
                    <v-btn color="success" class="float-right" @click="submit()" v-if="baklasSource.dataTable.action == 0">
                        <v-icon>mdi-plus</v-icon> Submit
                    </v-btn>
                    <v-btn color="success" class="float-right" @click="update(baklasSource.form.id)" v-else>
                       <v-icon>mdi-content-save</v-icon> Save
                    </v-btn>
                </v-card-text>
            </v-card>

      </v-dialog>
  </div>
</template>

<script>
import BaklasTableComponent from './BaklasComponent/BaklasTableComponent.vue';
import AddUnitBaklasComponent from './BaklasComponent/AddUnitBaklasComponent.vue';
import AddBaklasDialog from './BaklasComponent/AddBaklasDialogComponent.vue';
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            baklasCreateSuccess: false,
            tab: 0,
            baklasSource:{
                form:{
                    source:'',
                    description:'',
                    id:null
                },
                dataTable:{
                    items:[],
                    headers:[
                        { text:'Source',value:'source' },
                        { text:'Description',value:'description' },
                        { text:'Action',value:'actions' },
                    ],
                    loaded:true,
                    action:''
                },
                dialog:false
            },
            tabs:[
                'new',
                'available',
                'consumed',
                'disposed',
                'all',
            ],
            userAccess:[]
        }
    },
    components: {
        BaklasTableComponent,
        AddBaklasDialog,
        AddUnitBaklasComponent
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS'
        ])
    },
    watch: {
        tab(val){
            if(val == 5){
                this.list()
            }
        },
        USER_ACCESS: {
            handler(val) {
                if(val != "fail"){
                    this.userAccess = val.map(e=>e.actions_code);
                }
            },
        },
    },
    mounted(){
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id);
    },
    methods: {
        successCreate(success) {
            if(!!success) {
                this.baklasCreateSuccess = true;
            } else {
                this.baklasCreateSuccess = false;
            }
        },
        toggleDialog(dialog){
            let that = this

            if(that[dialog].dialog == undefined){
                return false
            }

            that[dialog].dialog = !that[dialog].dialog

        },
        list(){
            this.baklasSource.dataTable.loaded = false
            this.$store.dispatch('serviceGet',{
                url:'baklas-source'
            }).then(response=>{
                this.baklasSource.dataTable.items = response.data
                this.baklasSource.dataTable.loaded = true
            })
        },
        async submit(){
            await this.$store.dispatch('servicePost',Object.assign(this.baklasSource.form,{
                url:'create-baklas-source',
            })).then(response=>{
                Swal.fire('Success',response.data.source,'success')
                this.resetFields()
                this.list()
                this.toggleDialog('baklasSource')
            }).catch(err=>{
                console.log(err)
            })
        },
        deleteList(id){
            Swal.fire({
                title:'Warning',
                text:'- Are you sure you want to delete?',
                icon: 'question',
                showCancelButton:true,
                reverseButtons:true
            }).then(action=>{
                if(action.isConfirmed){
                    this.$store.dispatch('serviceGet',{
                        url:'delete-baklas-source/'+id,
                    }).then(response=>{
                        Swal.fire('Success',response.data,'success')
                        this.resetFields()
                        this.list()
                    }).catch(err=>{
                        console.log(err)
                    })
                }
            })
        },
        update(id){
            Swal.fire({
                title:'Warning',
                text:'- Are you sure you want to Update?',
                icon: 'question',
                showCancelButton:true,
                reverseButtons:true
            }).then(action=>{
                if(action.isConfirmed){
                    this.$store.dispatch('serviceGet',Object.assign(this.baklasSource.form,{
                        url:'update-baklas-source/'+id,
                    })).then(response=>{
                        Swal.fire('Success',response.data,'success')
                        this.toggleDialog('baklasSource')
                        this.resetFields()
                        this.list()
                    }).catch(err=>{
                        console.log(err)
                    })
                }
            })

        },
        resetFields(){
            this.baklasSource = {
                form:{
                    source:'',
                    description:''
                },
                dataTable:{
                    items:[],
                    headers:[
                        { text:'Source',value:'source' },
                        { text:'Description',value:'description' },
                        { text:'Action',value:'actions' },
                    ],
                    action:'',
                    loaded:true
                },
                dialog:false
            }
        },
        view(item){
            this.toggleDialog('baklasSource')
            this.baklasSource.form = item
        }
    }
}
</script>

<style>

</style>
