	<template>
		<v-form ref="form" v-model="valid" lazy-validation>
			<v-card>
				<v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
				<span class="text-h5">{{GET_HR_DIALOG_TITLE}}</span>
					<v-row class="m-0">
						<v-col cols="pull-right-10 p-2">
						<v-btn text icon small color="gray" class="float-right" @click="closeDialog()">
							<v-icon>mdi-close-circle</v-icon>
						</v-btn>
						</v-col>
					</v-row>
				</v-card-title>
				<v-card-text>
					<v-container fluid class="py-5">
						<v-row >
							<v-col cols="3">
								<v-switch
								v-model="form.with_manual"
								:disabled="disabled_view"
								:rules="errors.with_manual"
                                :true-value="1"
                                :false-value="0"
								required
								label="With Manual"
								outlined
								dense
								></v-switch>
							</v-col>
							<v-col cols="3">
								<v-switch
								v-model="form.with_pay"
								:disabled="disabled_view"
								:rules="errors.with_pay"
                                :true-value="1"
                                :false-value="0"
								required
								label="With Pay"
								outlined
								dense
								></v-switch>
							</v-col>
							<v-col cols="3">
								<v-switch
								v-model="form.default"
								:disabled="disabled_view"
								:rules="errors.default"
                                :true-value="1"
                                :false-value="0"
								required
								label="Default"
								outlined
								dense
								></v-switch>
							</v-col>
							<v-col cols="3">
								<v-switch
								v-model="form.approvable"
								:disabled="disabled_view"
								:rules="errors.approvable"
                                :true-value="1"
                                :false-value="0"
								required
								label="Approvable"
								outlined
								dense
								></v-switch>
							</v-col>
						</v-row>
						<v-row >
							<v-col cols="6">
								<v-text-field
								v-model="form.code"
								:disabled="actions != 'Submit'"
								:rules="errors.code"
								required
								label="Code"
								outlined
								dense
								></v-text-field>
							</v-col>
                            <v-col cols="6">
                                <!-- <v-color-picker v-model="form.color"></v-color-picker> -->
                                <v-text-field
                                    v-model="form.color"
                                    :rules="errors.color"
                                    :disabled="disabled_view"
                                    label="Color"
                                    required
                                    outlined
                                    dense
                                >
                                    <template #append>
                                    <v-btn :disabled="disabled_view" small text icon @click="openColorPicker()">
                                        <v-icon>mdi-select-color</v-icon>
                                    </v-btn>
                                    </template>
                                </v-text-field>
                            </v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-text-field
								v-model="form.name"
								:disabled="disabled_view"
								:rules="errors.name"
								required
								label="Name"
								outlined
								dense
								></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-text-field
								v-model="form.leave_type_balance"
								:disabled="disabled_view"
								required
								label="Leave Type Balance"
								outlined
								dense
								type="number"
								></v-text-field>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions class="text-center">
				<v-row class="ma-1">
					<v-col cols="12" v-if="actions != 'View' ">
						<v-btn class="float-center" @click="actions == 'Submit' ? addLeaveType() : updateLeaveType()" :loading="loading.button" :disabled="disabled_view">
							<span>{{ actions }}</span>
						</v-btn>
					</v-col>
				</v-row>
				</v-card-actions>
			</v-card>
            <v-dialog v-model="colorPickerDialog" width="20%">
                <v-card>
                    <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                    <span class="text-h5">Choose Color</span>
                    <v-row class="m-0">
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon small color="gray" class="float-right" @click="closeColorPicker()">
                        <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                    </v-row>
                </v-card-title>
                    <v-card-text>
                    <v-row class="mt-4">
                        <v-col
                        class="d-flex justify-center"
                        >
                        <v-color-picker v-model="form.color"></v-color-picker>
                        </v-col>
                    </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
		</v-form>
	</template>
	<script>
	import Swal from 'sweetalert2'
 	import 'vue-organization-chart/dist/orgchart.css'
	import { mapGetters } from 'vuex'
	export default {
		data() {
			return {
				valid: false,
				form: {
					code: '',
					name: '',
					leave_type_balance:0,
                    with_pay: 0,
                    color: '',
                    approvable: 0
				},
				showDetails: false,
				item: '',
				loaded: false,
				errors: {
					code: [v => !!v || 'The code is required'],
					name: [v => !!v || 'The name is required'],
					department: [v => !!v || 'The department is required'],
					superior: [v => !!v || 'The superior is required'],
				},
				disabled_view:false,
				ds:{},
				superior_selection: [],
                colorPickerDialog: false,
                loading:{
                    button: false
                },
			}
		},
		computed: {
		...mapGetters(['ACTION',
			'DIALOGS',
			'USERACCOUNT_LOAD',
			'GET_HR_OTHER_TABS_DIALOG_VIEW',
			'GET_HR_LEAVES_NEW_DATA',
			'GET_DEPARTMENT_ROLE_LOAD',
			'GET_JOB_TITLE_SELECTION',
			'GET_ORG_CHART_DATA',
			'GET_HR_DISPATCH',
			'GET_HR_DIALOG_TITLE'
		]),
		actions()
			{
				if (this.ACTION == 'Submit') {
				this.disabled_view = false;
				return 'Submit'
				} else if (this.ACTION == 'Update') {
				this.disabled_view = false;

					this.getAllLeaveType();

				return 'Update'
				}
				else if (this.ACTION == 'View') {
				this.disabled_view = true;

					this.getAllLeaveType();
				return 'View'
				}

			},
		},
		mounted(){
			this.$store.dispatch('getSelectionDepartmentRole');
		},
		components: {
		},
    methods: {
		filterSuperior(){
			let filteredJobs = this.GET_JOB_TITLE_SELECTION.filter(obj=> obj.department_id === this.form.department_id || obj.value === 1 )
			this.superior_selection = filteredJobs
		},
		closeDialog() {
			this.$store.commit('DIALOG', false);
			this.$store.commit('ACTION', '')
			this.resetfields();
		},
		getAllLeaveType(){
            this.form = {
                id: this.GET_HR_OTHER_TABS_DIALOG_VIEW.id,
                code: this.GET_HR_OTHER_TABS_DIALOG_VIEW.code,
                name: this.GET_HR_OTHER_TABS_DIALOG_VIEW.name,
                color: this.GET_HR_OTHER_TABS_DIALOG_VIEW.color ?? '#ffffff',
                leave_type_balance: this.GET_HR_OTHER_TABS_DIALOG_VIEW.leave_type_balance,
                with_manual: this.GET_HR_OTHER_TABS_DIALOG_VIEW.with_manual,
                with_pay: this.GET_HR_OTHER_TABS_DIALOG_VIEW.with_pay,
                default: this.GET_HR_OTHER_TABS_DIALOG_VIEW.default,
                approvable: this.GET_HR_OTHER_TABS_DIALOG_VIEW.approvable,
            }
			// this.form.id 						= this.GET_HR_OTHER_TABS_DIALOG_VIEW.id
			// this.form.code  					= this.GET_HR_OTHER_TABS_DIALOG_VIEW.code
			// this.form.name  					= this.GET_HR_OTHER_TABS_DIALOG_VIEW.name
			// this.form.leave_type_balance    	= this.GET_HR_OTHER_TABS_DIALOG_VIEW.leave_type_balance
			// this.form.with_manual    	        = this.GET_HR_OTHER_TABS_DIALOG_VIEW.with_manual
			// this.form.with_pay    	            = this.GET_HR_OTHER_TABS_DIALOG_VIEW.with_pay
			// this.form.default    	            = this.GET_HR_OTHER_TABS_DIALOG_VIEW.default
			// this.form.color    	                = this.GET_HR_OTHER_TABS_DIALOG_VIEW.color
		},
		addLeaveType() {
			this.$refs.form.validate()
			const code_swal_text = this.form.code ? '' : 'Code';
			const name_swal_text = this.form.name ? '' : 'Name';
			// const balance_swal_text = this.form.leave_type_balance ? '' : 'Leave Type Balance';

			const array_text = [code_swal_text, name_swal_text]
			const filtered = array_text.filter(function (el) {
				return el.replace(',', '');
			})
			if (this.form.code && this.form.name) {
                this.loading.button = true;
				this.$store.dispatch(this.GET_HR_DISPATCH.add, this.form).then((res)=>{
                    Swal.fire(
                        res.message,
                        "",
                        res.icon
                    )
                    this.loading.button = false;
					this.$store.commit("DIALOG", false);
                }).catch((error)=>{
                    Swal.fire(
                        "Error",
                        "",
                        "error"
                    )
                    this.loading.button = false;
                    console.log(error)
                });
			} else {
				Swal.fire({
					text: `Fill Required Fields (${filtered})`,
					icon: 'warning',
					allowOutsideClick: false,
				});
			}
		},
		updateLeaveType() {
			this.$refs.form.validate();

			const code_swal_text = this.form.code ? '' : 'Code';
			const name_swal_text = this.form.name ? '' : 'Name';
			// const balance_swal_text = this.form.leave_type_balance ? '' : 'Leave Type Balance';
			const array_text = [code_swal_text, name_swal_text]

			const filtered = array_text.filter(function (el) {
				return el.replace(',', '');
			})

			if (this.form.code && this.form.name) {
                this.loading.button = true;
                this.$store.dispatch(this.GET_HR_DISPATCH.update,
                    {
                        id: this.form.id,
                        payload: this.form,
				    }).then((res)=>{
                        Swal.fire(
                            res.message,
                            "",
                            res.icon
                        )
                        this.loading.button = false;
                        this.$store.commit("DIALOG", false);
                    }).catch((error)=>{
                        Swal.fire(
                            "Error",
                            "",
                            "error"
                        )
                        console.log(error)
                        this.loading.button = false;
                    });
			} else {
				Swal.fire({
					text: `Fill Required Fields (${filtered})`,
					icon: 'warning',
					allowOutsideClick: false,
				});
			}
		},
		resetfields() {
			this.form = {
				code: '',
				name: '',
				leave_type_balance:0,
                with_pay: 0,
                color: '',
                approvable: 0,
			};
		},
        openColorPicker(){
            this.colorPickerDialog = true
        },
        closeColorPicker(){
            this.colorPickerDialog = false
        }
	},
	watch: {
		DIALOGS:{
			handler(val){
				if(!val){
					this.resetfields()
				}
			}
		},
		GET_HR_LEAVES_NEW_DATA: {
			handler(val) {
			this.resetfields()
			this.$store.commit('DIALOG', false);
			Swal.fire({
				title: 'Success',
				icon: 'success',
				timer: 1500,
			});
			this.$store.commit("HR_NEW_DATA_TRIGGER", false);
			}
		},
	},
};
</script>
