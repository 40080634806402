<template>
  <v-card class="d-flex justify-content-center flex-column" elevation="0">
        <v-card-title class="d-flex justify-content-between align-items-center">
            <span> {{ ACTION }} {{SELECTED.trasact_number}} Items</span>
            <v-btn text icon small color="gray" class="float-right" @click="() => {
                $store.commit('WAITING_FOR_RECEIVE_DIALOG', false);
                $store.commit('ACTION', false);
                $store.commit('SELECTED',{});
                $store.commit('GET_MODULE_NAME','');
            }">
            <v-icon>mdi-close-circle</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text class="d-flex flex-column">
            <div class="data-table-container">
                <v-row>
                    <v-col class="d-flex justify-end pa-4">
                        <BaseFileViewerComponent
                            :access="true"
                            :payloadIds="[]"
                            :payloadId="SELECTED.pv_id ? SELECTED.pv_id : null"
                            :getFilesPath="'getPaymentBillFiles'"
                            :deleteFilesPath="false"
                            :module="'accounting'"
                            :folder="'paybills'"
                        ></BaseFileViewerComponent>
                    </v-col>
                </v-row>
                <v-data-table
                    :headers="allHeader"
                    :items="form.items"
                    :loading="loading"
                    :show-expand="this.MODULE_NAME == 'Admin Job Order'"
                    :single-expand="this.MODULE_NAME == 'Admin Job Order'"
                    hide-default-footer
                >
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length + 1" class="ma-0 p-0" v-if="item.materials">
                            <v-data-table
                                :headers="subHeader"
                                :items="item.materials"
                                class="text-center"
                                hide-default-footer
                                dark
                                disable-pagination
                            >
                                <template v-slot:[`item.price`]="{ item }">
                                    <span class="text-nowrap">
                                        &#x20b1;{{ item.price | currency }}
                                    </span>
                                </template>
                            </v-data-table>
                        </td>
                    </template>
                    <template v-slot:[`item.recognition_month`]="{ item }">
                        <span class="text-nowrap">
                            {{ item.recognition_month | formatDateYM }}
                        </span>
                    </template>
                    <template v-slot:[`item.image_path`]="{ item }">
                        <div class="image-container" style="padding: 4px;">
                            <v-img
                                class="img-thumbnail centered-image"
                                width="80"
                                height="100"
                                :src="checkImagePath(item)"
                                @error="checkImage(item)"
                                cover></v-img>
                        </div>
                    </template>
                    <template v-slot:[`item.total_amount`]="{ item }">
                        <span class="text-nowrap">
                            &#x20b1;{{ item.total_amount | currency }}
                        </span>
                    </template>
                    <template v-slot:[`item.amount`]="{ item }">
                        <span class="text-nowrap">
                            &#x20b1;{{ item.amount | currency }}
                        </span>
                    </template>
                    <template v-slot:[`item.item_amount_to_pay`]="{ item }">
                        <span class="text-nowrap">
                            &#x20b1;{{ item.item_amount_to_pay | currency }}
                        </span>
                    </template>
                    <template v-slot:[`item.item_paid_amount`]="{ item }">
                        <span class="text-nowrap">
                            &#x20b1;{{ item.item_paid_amount | currency }}
                        </span>
                    </template>
                    <template v-slot:[`item.receive_paid_amount`]="{ item }">
                        <span class="text-nowrap">
                            &#x20b1;{{ item.receive_paid_amount | currency }}
                        </span>
                    </template>
                    <template v-slot:[`item.price`]="{ item }">
                        <span class="text-nowrap">
                            &#x20b1;{{ item.price | currency }}
                        </span>
                    </template>
                    <template v-slot:[`item.paid`]="{ item }">
                        <span class="badge bg-primary text-white" v-if="item.paid">Yes</span>
                        <span class="badge bg-danger text-white" v-else>No</span>
                    </template>
                </v-data-table>
            </div>
            <!-- <div class="d-flex justify-content-center pt-4" v-if="!loading">
                <v-btn :disabled="isDisabled">
                    Confirm
                </v-btn>
            </div> -->
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseFileViewerComponent from "@/views/main/Shared/BaseFilesViewerComponent.vue"

export default {
    components: {
        BaseFileViewerComponent,
    },
    data(){
        return{
            form: {
                items:[],
            },
            ListItemsHeaders: [
                // THIS IS FOR PURCHASE ORDER
                // { text: 'Item Name', value: 'item_name', align: 'left', sortable: false},
                // { text: 'Image', value: 'image_path', align: 'center', sortable: false },
                // { text: 'Price', value: 'price', align: 'right', sortable: false },
                // { text: 'Paid', value: 'paid', align: 'right', sortable: false },
                // { text: 'Total Amount', value: 'total_amount', align: 'right', sortable: false },

                // THIS IS FOR PURCHASE ORDER
            ],
            loading: false,
            allHeader: [],
            subHeader: [
                { text: 'Material Name', value: 'material_name', align: 'left', sortable: false},
                { text: 'UOM', value: 'uom', align: 'center', sortable: false },
                { text: 'Price', value: 'price', align: 'right', sortable: false },
                { text: 'Quantity', value: 'quantity', align: 'center', sortable: false },
                { text: 'Total Amount', value: 'total_amount', align: 'right', sortable: false },
            ],
            compiledFiles: [],
            uploadedFiles: [],
            viewUploadFiles: {
                attachments: []
            },
        }
    },
    mounted(){
        this.list();
    },
    computed: {
        ...mapGetters([
            "ACTION",
            "SELECTED",
            "MODULE_NAME",
        ]),
        // isDisabled(){
        //     return this.form.items.some(item => item.paid === 0)
        // },
    },
    methods: {
        async processFiles(files){
            files.forEach(e=>{
                this.compiledFiles.push(e.localFileState)
            })
            let fileData = []
            for (let i = 0; i < this.compiledFiles.length; i++) {
                let element = this.compiledFiles[i]
                let reader = new FileReader()
                reader.readAsDataURL(element)
                await new Promise((resolve) => {
                    reader.onloadend = function () {
                        fileData.push({
                            name: element.name,
                            type: element.type,
                            data: reader.result.split(',')[1],
                            upload_type: 1,
                        })
                        resolve()
                    }
                })
            }
            this.saveFiles = fileData
        },
        // asdas
        checkImagePath(item) {
        return item.image_path == null
            ? process.env.VUE_APP_API_HOST + "/images/items/no-image.png"
            : process.env.VUE_APP_API_HOST + "/" + item.image_path;
        },
        checkImage(item) {
            item.image_path = "images/items/no-image.png";
            return item.image_path;
        },
        async list(){
            console.log('sel',this.SELECTED);
            this.form.items = [];
            this.loading = true;
            await this.$store.dispatch('getWFRListItems', {
            id: this.SELECTED.id,
            module_name: this.MODULE_NAME
            }).then(response => {
                this.form.items = response.data
                // .map(item => (
                //     {
                //         id:item.id,
                //         item_quantity: item.item_quantity,
                //         // item_pending_qty: item.item_pending_qty || 0,
                //         item_pending_qty: item.item_quantity - item.receive_qty || 0,
                //         total_amount: item.total_amount,
                //         item_amount_to_pay: item.item_amount_to_pay,
                //         item_paid_amount: item.item_paid_amount,
                //         receive_qty: item.receive_qty || 0,
                //         receive_paid_amount: item.receive_paid_amount,
                //         item_name: item.item_name,
                //         image_path: item.image_path,
                //         price: item.price,
                //         paid: item.paid
                //     }
                // ))
                this.loading = false;
            }).catch(error => {
                console.error(error)
                this.loading = false;
            });
        }
    },
    watch: {
        SELECTED:{
            handler(val){
                let module_name = this.MODULE_NAME;
                switch(module_name) {
                    case 'Admin Purchase Order':
                        this.allHeader = this.ListItemsHeaders.concat([
                            { text: 'Item Name', value: 'particulars', align: 'left', sortable: false},
                            { text: 'Image', value: 'image_path', align: 'center', sortable: false },
                            { text: 'Price', value: 'price', align: 'right', sortable: false },
                            { text: 'Paid', value: 'paid', align: 'right', sortable: false },
                            { text: 'Total Amount', value: 'total_amount', align: 'right', sortable: false },
                            { text: 'Item Amount to Pay', value: 'item_amount_to_pay', align: 'right', sortable: false },
                            { text: 'Item Paid Amount', value: 'item_paid_amount', align: 'right', sortable: false },
                            { text: 'Receive Paid Amount', value: 'receive_paid_amount', align: 'right', sortable: false },
                            { text: 'Item Quantity', value: 'item_quantity', align: 'center', sortable: false },
                            { text: 'Receive Quantity', value: 'receive_qty', align: 'center', sortable: false },
                            { text: 'Item Pending Quantity', value: 'item_pending_qty', align: 'center', sortable: false },
                        ])
                        break;
                    case 'Admin Job Order':
                        this.allHeader = this.ListItemsHeaders.concat([
                            { text: 'Service', value: 'particulars', align: 'center', sortable: false },
                            { text: 'Total Amount', value: 'total_amount', align: 'right', sortable: false },
                            { text: 'Quantity', value: 'quantity', align: 'center', sortable: false },
                        ])
                        break;
                    case 'Transaction Journal':
                        this.allHeader = this.ListItemsHeaders.concat([
                            { text: 'COA', value: 'coa', align: 'center', sortable: false},
                            { text: 'Particular', value: 'particulars', align: 'center', sortable: false },
                            { text: 'Amount', value: 'amount', align: 'center', sortable: false },
                            { text: 'Recognition Month', value: 'recognition_month', align: 'center', sortable: false },
                            { text: 'Department', value: 'department', align: 'center', sortable: false },
                            { text: 'Job', value: 'job', align: 'center', sortable: false },
                        ])
                        break;
                    case 'Service Payment Request':
                        this.allHeader = this.ListItemsHeaders.concat([
                            { text: 'COA', value: 'coa', align: 'center', sortable: false},
                            { text: 'Particular', value: 'particulars', align: 'center', sortable: false },
                            { text: 'Amount', value: 'amount', align: 'center', sortable: false },
                            { text: 'Recognition Month', value: 'recognition_month', align: 'center', sortable: false },
                        ])
                        break;
                    case 'Recurring Bill':
                        this.allHeader = this.ListItemsHeaders.concat([
                            // { text: 'COA', value: 'coa', align: 'center', sortable: false},
                            { text: 'Particular', value: 'particulars', align: 'center', sortable: false },
                            { text: 'Amount', value: 'amount', align: 'center', sortable: false },
                            { text: 'Bill Date', value: 'bill_date', align: 'center', sortable: false },
                            { text: 'Due Date', value: 'due_date', align: 'center', sortable: false },
                        ])
                        break;
                }

                if(Object.keys(val).length != 0){
                    this.list();
                }

            },
            immediate: true
        }
    }
}
</script>
<style scoped>
    .data-table-container {
        max-height: 550px;
        overflow-y: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;
        padding: 0%;
        margin: 0%;
    }
    .data-table-container::-webkit-scrollbar {
        display: none;
    }
</style>
