<template>
    <div class="tabs-body-color">
        <v-container fluid>
            <v-row class="pt-2">
                <v-col cols="6" class="d-flex no-block align-items-center">
                    <div class="btn-toolbar">
                        <v-btn class="ml-1 mb-2" @click="AddDialog()" v-if="GET_HR_TAB_IS_ACTIVE != 'applicant_list' && GET_HR_TAB_IS_ACTIVE !='privacy_settings' && GET_HR_TAB_IS_ACTIVE !='req_for_approval' && GET_HR_TAB_IS_ACTIVE !='shortlisted_applicant_list'">
                            <v-icon>mdi-plus</v-icon>Add
                        </v-btn>
                    </div>
                </v-col>
                <v-spacer></v-spacer>
                 <v-col cols="3" class="d-flex justify-content-end">
                    <div class="btn-toolbar">
                        <v-btn class="ml-1 mb-2"
                            @click="ExportToExcel()"
                            :loading="loading.button"
                        >
                        <v-icon>mdi-file-excel</v-icon>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-row>
              <ManpowerRequestTableComponentVue v-if="isActive_tab == 'manpower_request'"></ManpowerRequestTableComponentVue>
              <ApplicantListTableComponentVue v-if="isActive_tab == 'applicant_list' || isActive_tab == 'req_for_approval' || isActive_tab == 'shortlisted_applicant_list'"></ApplicantListTableComponentVue>
              <PrivacySettingsTableComponent v-if="isActive_tab == 'privacy_settings'"></PrivacySettingsTableComponent>
              <EmailMessageTableComponent v-if="isActive_tab == 'email_message'"></EmailMessageTableComponent>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import ManpowerRequestTableComponentVue from '@/views/main/modules/HR/RecruitmentSystem/ManpowerRequestTableComponent.vue';
import ApplicantListTableComponentVue from '@/views/main/modules/HR/RecruitmentSystem/ApplicantListTableComponent.vue';
import PrivacySettingsTableComponent from '@/views/main/modules/HR/RecruitmentSystem/PrivacySettingsTableComponent.vue'
import EmailMessageTableComponent from '@/views/main/modules/HR/RecruitmentSystem/EmailMessageTableComponent.vue'

import swal from 'sweetalert2'

import { mapGetters } from 'vuex';
export default {
    components: {
        ManpowerRequestTableComponentVue,
        ApplicantListTableComponentVue,
        PrivacySettingsTableComponent,
        EmailMessageTableComponent
    },
    props:["isActive_tab","export_file_name"],
    data() {
        return {
            loading:{
                button: false
            }
        }
    },
    computed: {
        ...mapGetters(['GET_HR_TAB_IS_ACTIVE','GET_HR_DISPATCH','GET_EXPORT_FILTERS','GET_EXPORT_HEADERS'])
    },
    mounted() {
    },
    methods: {
        AddDialog(){
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", "Submit");
        },
        async ExportToExcel(){
            this.loading.button = true;
            swal.fire('Exporting Data')
            swal.showLoading()
            let payload = {
                ...this.GET_EXPORT_FILTERS,
                export: 1,
                active_tab:this.GET_HR_TAB_IS_ACTIVE
            }
            await this.$store.dispatch(this.GET_HR_DISPATCH.get,payload).then(async response=>{
                swal.close()
                this.loading.button = false;
                let data = response.data
                    const workbook = new this.$exceljs.Workbook()
                    const rawDataSheetName = `${this.export_file_name} ${this.$dayjs().format('YYYY-MM-DD')}`
                    let worksheet = workbook.addWorksheet(rawDataSheetName)

                    worksheet.columns = this.GET_EXPORT_HEADERS
                    worksheet.getRow(1).font = { bold: true };
                    worksheet.getRow(1).height = 30;
                    let borderStyles = {
                        top: { style: "thin" },
                        left: { style: "thin" },
                        bottom: { style: "thin" },
                        right: { style: "thin" }
                    };
                    worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
                        row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
                            cell.border = borderStyles;
                        });
                    });
                    data.forEach(e => {
                        worksheet.addRow(e);
                    })

                    workbook.xlsx.writeBuffer().then((data) => {
                        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheetcharset=utf-8' })
                        const filename = `${this.export_file_name}.xlsx`
                        this.$filesaver.saveAs(blob, filename)
                    })
            }).catch((err)=>{
                swal.fire({
                    title: 'Error',
                    text: 'Network Error',
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
                console.log(err)
                this.loading.button = false;
                swal.close()
            })
        }
    },

};
</script>
