<template>
<div class="tabs-body-color">

    <div class="container">
        <!-- <div class="row">
            <div class="col-12 d-flex no-block align-center">
                <div class="btn-toolbar">
                    <v-btn data-modal-id="addSizeModal" v-on:click="showAddSize()">
                        <i class="fas fa-plus"></i>Add
                    </v-btn>
                </div>
            </div>
        </div> -->
        <div class="row">
            <div class="col-12 d-flex no-block align-center">
                <div class="btn-toolbar">
                    <div class="row my-1">
                        <div class="">
                            <div class="btn-toolbar">
                                <v-btn class="" @click="showAddSize()" v-if="userAccess.includes('sizes_create')">
                                    <v-icon>mdi-plus</v-icon>
                                    Add
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <!-- Brands Table -->
            <SizesTableComponentVue :userAccess="userAccess" :itemSizesActionResult="action" @showEditModal="showEditModal"></SizesTableComponentVue>
        </div>
    </div>

    <v-app id="addEditSizeModal">
        <v-dialog v-model="add_edit_size_dialog" persistent max-width="500" scrollable>
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Size Information</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="add_edit_size_dialog = false; resetFields(); $emit('closeModal');">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-card-text>
                    <form class="form-horizontal px-4" action="/itemSizes" method="post" v-on:submit.prevent="onSubmit">
                        <div class="card-body">
                            <v-row>
                                <v-col cols="12">
                                    <label for="name" class="float-left control-label col-form-label font-weight-medium">Name <span v-if="action != 'View'" class="red--text">*</span></label>
                                    <input type="text" class="form-control" id="name" v-model="name" :readonly="action == 'View'">
                                    <span v-for="errors in errors.name" class="text-warning" v-bind:key="errors">{{errors}}</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <label for="code" class="float-left control-label col-form-label font-weight-medium">Code <span v-if="action != 'View'" class="red--text">*</span></label>
                                    <input type="text" class="form-control" id="code" v-model="code" :readonly="action == 'View'">
                                    <span v-for="errors in errors.code" class="text-warning" v-bind:key="errors">{{errors}}</span>
                                </v-col>
                            </v-row>
                            <!-- <v-row>
                                <v-col cols="12">
                                    <label for="description" class="float-left control-label col-form-label font-weight-medium">Description</label>
                                    <input type="text" class="form-control" id="description" v-model="description" :readonly="action == 'View'">
                                </v-col>
                            </v-row> -->
                        </div>

                        <div class="border-top">
                            <div class="card-body py-4 d-flex justify-content-center">
                                <v-btn  v-on:click="addNewSize()" v-if="action=='Add'" :loading="loading">Submit</v-btn>
                                <v-btn  v-on:click="editSize()" v-else-if="action=='Edit'" :loading="loading">Update</v-btn>
                            </div>
                        </div>
                    </form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

</div>
</template>

<script>
import SizesTableComponentVue from '../tables/SizesTableComponent.vue';
import swal from 'sweetalert2';
import { mapGetters } from 'vuex';
    export default {
        data(){
            return {
                editSizeId: '',
                add_edit_size_dialog: false,
                code: '',
                name: '',
                description: '',
                errors: [],
                action: '',
                actionResult : {
                    model : 'Size',
                    action : ''
                },
                userAccess: [],
                loading: false,
            }
        },
        components: {
            SizesTableComponentVue
        },  
        computed:{
            ...mapGetters([
                'GET_SHOW_ITEM_SIZES',
                'GET_UPDATE_ITEM_SIZES',
                'USER_ACCESS'
            ])
        },
        async mounted() {
        await this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)

        },
        methods: {
            showAddSize(){
                this.add_edit_size_dialog = true
                this.action = 'Add'
            },
            addNewSize(){
                this.errors = []
                this.loading = true;
                let payload = {
                    code:           this.code,
                    name:           this.name,
                    description:     this.description
                }
                this.$store.dispatch('storeItemSizes',payload).then(response => {
                    this.resetFields()
                    this.add_edit_size_dialog = false
                    this.action = 'success'
                    swal.fire("", "Item Size Created Succesfully", "success");
                    this.loading = false;
                }).catch(error => {
                    swal.fire("", "Item Size Created Failed", "error");
                    this.errors = error.response.data.errors;
                    this.loading = false;
                });
            },
            showEditModal(data){
                this.action = data.action
                this.editSizeId = data.id
                if(this.editSizeId != null && this.editSizeId > 0){   
                    this.$store.dispatch('showItemSizes',this.editSizeId).then(response => {
                        let data = this.GET_SHOW_ITEM_SIZES
                        this.code                   = data.code
                        this.name                   = data.name
                        this.description            = data.description
                        this.add_edit_size_dialog   = true
                    }).catch(error => {
                        swal.fire("", "Failed to load", "error");
                    });
                }
            },
            editSize(){
                this.errors = []
                this.loading = true;
                let payload = {
                    id:             this.editSizeId,
                    code:           this.code,
                    name:           this.name,
                    description:     this.description
                }
                this.$store.dispatch('updateItemSizes',payload).then(response => {
                    this.resetFields()
                    this.add_edit_size_dialog = false
                    this.editSizeId           = 0
                    this.action               = 'success'
                    swal.fire("",response.data.msg, "success");
                    this.loading = false;
                }).catch(error => {
                    swal.fire("",error.response.data.message, "error");
                    this.errors = error.response.data.errors;
                    this.action = 'Edit';
                    this.loading = false;
                });
            },
            resetFields(){
                this.errors              = []
                this.code                = ''
                this.name                = ''
                this.description         = ''
            },

        },
        watch: {
            USER_ACCESS:{
                handler(val){
                    this.userAccess = val.map(e=>e.actions_code);
                }
            },
        }
    };
</script>
<style>
    .v-text-field.v-text-field--enclosed .v-text-field__details{
        display: none;
    }
</style>
