<template>
    <div class="tabs-body-color">
        <v-container fluid class="pa-0">
            <v-row class="pt-4 pl-2" v-if="userAccess.create">
                <v-col cols="12" class="d-flex no-block align-items-center">
                    <div class="w-100 d-flex pl-0 mr-4">
                        <v-btn class="ml-1 mb-2" @click="AddDialog()">
                            <v-icon>mdi-plus</v-icon>Add
                        </v-btn>
                        <v-btn class="ml-1 mb-2 ml-auto" @click="exportAccountability()">
                            <v-icon>mdi-file-export</v-icon>Export
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <AccountabilityLowValueAssetsTableComponent v-if="GET_ADMIN_TAB_IS_ACTIVE == isActive_tab"></AccountabilityLowValueAssetsTableComponent>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
import AccountabilityLowValueAssetsTableComponent from '@/views/main/modules/Admin/AccountabilityLowValueAssets/AccountabilityLowValueAssetsTableComponent.vue';
import { mapGetters } from 'vuex'
import Swal from "sweetalert2";
export default {
    mixins: [SharedFunctionsComponentVue],
    components: {
        AccountabilityLowValueAssetsTableComponent,
    },
    props:["isActive_tab"],
    data() {
        return {
            userAccess:{
                create:false
            }
        }
    },
    computed: {
        ...mapGetters(['USER_ACCESS','GET_ADMIN_TAB_IS_ACTIVE','GET_ADMIN_ITEMS_COLLECTIONS','SELECTED'])
    },
    mounted() {
        this.$store.dispatch('getPvCompanies');
        this.$store.dispatch('getStoreBranchesWithoutAccess');
        this.$store.dispatch('getAdminLocationSelection');
    },
    methods: {
        exportAccountability() {
            this.$store.dispatch('exportAccountability',{
                export_type: 3, ...this.SELECTED
            }).then(response => {
                const exportSheets = {
                    'SUMMARY': {
                        headers: [
                            { header: "ACCOUNTABILITY #",  key: 'accountability_num',  width:10, style:{alignment: {horizontal: 'center'}} },
                            { header: "DEPARTMENT", key: 'department',  width:35, style:{alignment: {horizontal: 'center'}}},
                            { header: "LOCATION", key: 'location',  width:35, style:{alignment: {horizontal: 'center'}}},
                            { header: "PREPARED BY", key: 'prepared_by',  width:25, style:{alignment: {horizontal: 'center'}} },
                            { header: "UPDATED BY", key: 'updated_by',  width:25, style:{alignment: {horizontal: 'center'}} },
                            { header: "STATUS", key: 'status',  width:12, style:{alignment: {horizontal: 'center'}} },
                            { header: "FILED", key: 'filed',  width:12, style:{alignment: {horizontal: 'center'}} },
                            { header: "FILED DATE", key: 'filed_date',  width:25, style:{alignment: {horizontal: 'center'}} },
                        ],
                        data: [
                            ...response.summary.map(e =>{
                                e.filed_date = e.filed_date ? this.$dayjs(e.filed_date).format('YYYY-MM-DD') : '';
                                return e
                            })
                        ],
                    },
                    'DETAILED': {
                        headers: [
                            { header: "ACCOUNTABILITY #", key: 'accountability_num', width: 12, style:{alignment: {horizontal: 'center'}} },
                            { header: "DEPARTMENT", key: 'department',  width:35, style:{alignment: {horizontal: 'center'}}},
                            { header: "LOCATION", key: 'location', width: 35,  style:{alignment: {horizontal: 'center'}} },
                            { header: "PREPARED BY", key: 'prepared_by', width: 25,  style:{alignment: {horizontal: 'center'}} },
                            { header: "UPDATED BY", key: 'updated_by',  width:25, style:{alignment: {horizontal: 'center'}} },
                            { header: "STATUS", key: 'status',  width:12, style:{alignment: {horizontal: 'center'}} },
                            { header: "FILED", key: 'filed',  width:12, style:{alignment: {horizontal: 'center'}} },
                            { header: "FILED DATE", key: 'filed_date',  width:25, style:{alignment: {horizontal: 'center'}} },
                            { header: "ASSET CODE", key: 'asset_code', width: 18,  style:{alignment: {horizontal: 'center'}} },
                            { header: "ASSET NAME", key: 'item_name', width: 25,  style:{alignment: {horizontal: 'center'}} },
                            { header: "PREVIOUS ACCOUNTABILITY #", key: 'previous_accountability_num', width: 28, style:{alignment: {horizontal: 'center'}} },
                        ],
                        data: [
                            ...response.detailed.map(e =>{
                                e.filed_date = e.filed_date ? this.$dayjs(e.filed_date).format('YYYY-MM-DD') : '';
                                return e
                            })
                        ],
                    },
                };
                this.exportExcel(exportSheets, `LOW VALUE ACCOUNTABILITY SHEET`,'F69F1A');
            });
        },
        AddDialog(){
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", "Submit");
        }
    },
    watch:{
        USER_ACCESS:{
            handler(val){
                if(val != "fail"){
                    this.userAccess.create = false;
                    val.forEach(e => {
                        if (e.actions_code == 'create') {
                            this.userAccess.create = true;
                        }
                    });
                }
            }
        },
        SELECTED:{
            handler(val){
                if(val){
                }
            },
            deep: true,
            immediate: true
        }
    }
};
</script>
