
import SalesDashboard from '@/views/main/modules/Sales/sales-dashboard.vue'
import repository from '@/api/credentials'

const routes = [{
    path: '/sales/dashboard/:id',
    name: 'salesdashboard',
    props: true,
    component: SalesDashboard,
    // beforeEnter: (to, from, next) => {
    //     repository.checkAccessUser(to.params.id).then((response) => {
    //         if (response.data == 'fail') {
    //             next('/no-access')
    //         } else {
    //             next()
    //         }
    //     }).catch(() => {

    //     })
    // },
    meta: {
        requiresAuth: true,
        title: 'Sales Dashboard',
    },
}];

export default routes;
