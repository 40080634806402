import { render, staticRenderFns } from "./RepairFormDetailsDialog.vue?vue&type=template&id=3578abc4&scoped=true"
import script from "./RepairFormDetailsDialog.vue?vue&type=script&lang=js"
export * from "./RepairFormDetailsDialog.vue?vue&type=script&lang=js"
import style0 from "./RepairFormDetailsDialog.vue?vue&type=style&index=0&id=3578abc4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3578abc4",
  null
  
)

export default component.exports