<template>
    <v-row class="tabs-body-color ma-0 pa-0">
        <v-btn class="my-3" @click="openAddEditDialog()" v-if="userAccess.create_type">
            Add
        </v-btn>
        <v-container class="ma-0 pa-0">
            <v-dialog v-model="credit_memo.dialog" persistent width="30%">
                <v-card>
                    <v-row class="m-0">
                        <v-col>
                            <v-card-title>
                                <span class="text-lg font-semibold">{{ `${action.charAt(0).toUpperCase() + action.slice(1)} Credit Memo Type` }}</span>
                            </v-card-title>
                        </v-col>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right" @click="() => {
                                credit_memo.dialog = !credit_memo.dialog
                            }">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-text-field
                                v-model="credit_memo.data.code"
                                outlined
                                dense
                                hide-details
                                label="Code"
                                aria-required
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                v-model="credit_memo.data.name"
                                outlined
                                dense
                                hide-details
                                label="Name"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <!-- <v-col>
                                <v-autocomplete
                                v-model="credit_memo.data.origin"
                                :items="credit_memo.origin"
                                outlined
                                dense
                                hide-details
                                label="Origin"
                                >
                                </v-autocomplete>
                            </v-col> -->
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-autocomplete
                                v-model="credit_memo.data.status"
                                :items="credit_memo.status"
                                outlined
                                dense
                                hide-details
                                label="Status"
                                >
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-checkbox
                                    v-model="credit_memo.data.service"
                                    :true-value="1"
                                    :false-value="0"
                                    label="Aftersales"
                                ></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-end">
                        <v-btn v-if="action == 'add'" @click="saveNewCreditMemoType()">
                            Submit
                        </v-btn>
                        <v-btn v-else-if="action == 'edit'" @click="updateCreditMemoType()">
                            Update
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <CreditMemoTypeTableComponent ref="refreshTable" @showEditDialog="showEditDialog" :origin="origin"></CreditMemoTypeTableComponent>
        </v-container>
    </v-row>
</template>

<script>
import swal from 'sweetalert2';
import CreditMemoTypeTableComponent from '../CreditMemoType/CreditMemoTypeTableComponent.vue'
import { mapGetters } from 'vuex';
export default {
    props:['origin'],
    data() {
        return {
            credit_memo:{
                dialog:false,
                data:{
                    code:'',
                    name:'',
                    origin:'',
                    status:'',
                    service:0,
                },
                origin:[
                    {value:1, text:'Origin'},
                    {value:2, text:'International'},
                ],
                status:[
                    {value:1, text:'WITH QTY AND PRICE'},
                    {value:2, text:'WITH PRICE ONLY'},
                    {value:3, text:'GENERIC'},
                ]
            },
            action:'',
            editedCreditMemoTypeId:'',
            userAccess:{
                create_type:false
            },
            duplicate: [],
        }
    },
    methods:{
        openAddEditDialog(){
            this.action = 'add'
            this.resetFields(this.credit_memo.data)
            this.credit_memo.dialog = true;
        },
        saveNewCreditMemoType(){
            this.credit_memo.data.origin = this.origin
            if(!this.isCompleteData(this.credit_memo.data)){
                if(this.duplicate.map(x=> x.code).includes(this.credit_memo.data.code.toUpperCase())) {
                    return swal.fire('Duplicate Data!','- The data already exists.','error')
                } else {
                    let payload = {
                        data:this.credit_memo.data,
                        url:'saveNewCreditMemoType'
                    }
                    this.$store.dispatch('postCreditMemoType',payload).then(response=>{
                        swal.fire(response.data.msg,'',response.data.icon)
                        this.resetFields(this.credit_memo.data)
                        this.$refs.refreshTable.getAllCreditMemoType();
                    })
                }
            }
        },
        updateCreditMemoType(){
            if (!this.isCompleteData(this.credit_memo.data)) {
                if(this.duplicate.filter(e=> e.id != this.credit_memo.data.id && e.origin == this.credit_memo.data.origin).map(x=> x.code).includes(this.credit_memo.data.code.toUpperCase())) {
                    return swal.fire('Duplicate Data!','- The data already exists.','error')
                } else {
                    let payload = {
                        data:this.credit_memo.data,
                        id:this.editedCreditMemoTypeId,
                        url:'updateCreditMemoType'
                    }
                    this.$store.dispatch('postCreditMemoType',payload).then(response=>{
                        swal.fire(response.data.msg,'',response.data.icon)
                        this.resetFields(this.credit_memo.data)
                        this.$refs.refreshTable.getAllCreditMemoType();
                    })
                }
            }

        },
        resetFields(object){
            for (const key in object) {
                let val = ''
                if (typeof object[key] == 'string') val = '';
                if (typeof object[key] == 'boolean') val = false;
                if (typeof object[key] == 'object') val = [];
                if (typeof object[key] == 'number') val = 0;
                object[key] = val
            }
            this.credit_memo.dialog = false;
        },
        isCompleteData(data){
            let warning = '';

            if (!data.name) warning += 'Please input a name.<br>';
            if (!data.code) warning += 'Please input a code.<br>';
            if (!data.status) warning += 'Please input a status.<br>';

            if (warning !== '') return swal.fire({
                title: 'Please Fill out Information:',
                html: warning,
                icon: "warning",
            });
        },
        showEditDialog(id){
            this.action = 'edit'
            this.editedCreditMemoTypeId = id
            if(id != null && id > 0){
                let payload = {
                    id:id,
                    url:'viewCreditMemoType'
                }
                this.$store.dispatch('getCreditMemoType',payload).then(response=>{
                    this.credit_memo.data = response.data
                    this.credit_memo.dialog=true
                })
            }
        },
        checkDuplicateCmType() {
            let payload = {
                code:this.credit_memo.data.code,
                url:'checkDuplicateCmType'
            }
            this.$store.dispatch('postCreditMemoType',payload).then(response=>{
                this.duplicate = response.data
                // this.duplicate = response.data.map(e=> e.code.toUpperCase())
            })
        }
    },
    components:{
        CreditMemoTypeTableComponent
    },
    mounted(){
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id);
        this.checkDuplicateCmType()
    },
    watch:{
        USER_ACCESS:{
            handler(val){
                this.userAccess.create_type = false;
                if (val != "fail") {
                    val.forEach((e) => {
                        if (e.actions_code == "create_type") {
                            this.userAccess.create_type = true;
                        }
                    });
                }
            }
        }
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS'
        ])
    }
}
</script>

<style>

</style>
