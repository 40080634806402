<script>
import { mapGetters } from "vuex";
import swal from "sweetalert2";
export default {
    data() {
        return {

        };
    },
    methods: {
        reComputeRecord(record, recordDetails){
            // if(!recordDetails.uploadedFile && recordDetails.manual == 1 && recordDetails.deleted_record != 1){
            //     swal.fire(
            //         "Please Upload a Proof File!",
            //         "",
            //         "warning"
            //     )
            //     return false;
            // }
            // Employee Schedule for that day
            let employee_schedule_day = record.schedule.employee_schedule_details.find((x)=> x.day == recordDetails.day);
            let timeIn = this.$dayjs(recordDetails.time_in, 'HH:mm');
            let timeOut = this.$dayjs(recordDetails.time_out, 'HH:mm');
            let scheduleTimeIn = this.$dayjs(recordDetails.schedule_time_in, 'HH:mm');
            let scheduleTimeOut = this.$dayjs(recordDetails.schedule_time_out, 'HH:mm');

            let newTime = {
                hours_worked: 0,
                late_in_hours: 0,
            }

                // Computing Hours Worked
                // newTime.hours_worked = this.computeHours(recordDetails.time_in, recordDetails.schedule_time_out);
                // let hours_worked_subtracted = ( newTime.hours_worked - record.schedule.break_duration );
                // newTime.hours_worked = hours_worked_subtracted < 0 ? 0 : hours_worked_subtracted;

                // // Computing Late in Hours
                // if (timeIn.isAfter(scheduleTimeIn)) {
                //     timeIn.subtract(1, 'minute');
                // }
                // newTime.late_in_hours = timeIn.isAfter(scheduleTimeIn) ? this.computeHours(recordDetails.schedule_time_in, recordDetails.time_in,).toFixed(2) : 0;

            // Computing Late in Hours
            if (timeIn.isAfter(scheduleTimeIn)) {
                timeIn.subtract(1, 'minute');
            }
            newTime.late_in_hours = timeIn.isAfter(scheduleTimeIn) ? this.computeHours(recordDetails.schedule_time_in, recordDetails.time_in, 'HH:mm').toFixed(3) : 0;

            if (newTime.late_in_hours > 0 && this.$dayjs(recordDetails.time_out).subtract(1, 'minute').isSameOrAfter(this.$dayjs(recordDetails.schedule_time_out))) {

                let diffInMinutes = this.$dayjs(recordDetails.schedule_time_out, 'HH:mm').diff(this.$dayjs(recordDetails.time_in, 'HH:mm'), 'minute');
                let diffInHours = diffInMinutes / 60;
                let hoursWorked = diffInHours - record.schedule.break_duration;
                newTime.hours_worked = Math.max(0, parseFloat(hoursWorked.toFixed(2)));
            } else {
                // if(timeIn.subtract(1, 'minute').isSameOrBefore(scheduleTimeIn) && timeOut.isSameOrAfter(scheduleTimeOut)){
                //     newTime.hours_worked = 8;
                // }
                // else
                if (timeIn.subtract(1, 'minute').isSameOrBefore(scheduleTimeIn) && timeOut.isBefore(scheduleTimeOut)){
                    newTime.hours_worked = this.computeHours(recordDetails.time_in, recordDetails.time_out, 'HH:mm') - record.schedule.break_duration;
                    newTime.hours_worked = Math.max(0, parseFloat(newTime.hours_worked.toFixed(2)));
                }
                else{
                    if(timeOut.isBefore(scheduleTimeIn)){
                        let currentDate = this.$dayjs().format('YYYY-MM-DD');
                        let newTimeIn = this.$dayjs(`${currentDate} ${recordDetails.time_in}`);
                        let newTimeOut = this.$dayjs(`${currentDate} ${recordDetails.time_out}`);
                        newTimeOut.add(1, 'day');
                        let diffInMinutes = newTimeOut.diff(newTimeIn, 'minute');
                        let diffInHours = diffInMinutes / 60;
                        let hoursWorked = Math.max(0, parseFloat((diffInHours - record.schedule.break_duration).toFixed(2)));
                        newTime.hours_worked = hoursWorked;
                    }else{
                        newTime.hours_worked = this.computeHours(recordDetails.time_in, scheduleTimeOut.isSameOrBefore(timeOut) ? recordDetails.schedule_time_out : recordDetails.time_out, 'HH:mm') - record.schedule.break_duration;
                        newTime.hours_worked = Math.max(0, parseFloat(newTime.hours_worked.toFixed(2)));
                    }
                }
            }

            // const time_in_swal_text 			    = recordDetails.time_in ? '' : 'Time In' ;
            // const time_out_swal_text 			    = recordDetails.time_out ? '' : 'Time Out';
            const date_swal_text 			        = recordDetails.date ? '' : 'Date';

            const array_text =
            [
                // time_in_swal_text,
                // time_out_swal_text,
                date_swal_text,
            ]
            const filtered = array_text.filter(function (el) {
                return el.replace(',', '');
            })
            if(recordDetails.date){
                this.$set(recordDetails, 'hours_worked', newTime.hours_worked);
                this.$set(recordDetails, 'late_in_hours', newTime.late_in_hours);
                this.$set(recordDetails, 'editActive', false);
                swal.fire(
                    "Record Saved!",
                    "",
                    "success"
                )
            }else {
                swal.fire({
                    text: `Fill Required Fields (${filtered})`,
                    icon: 'warning',
                    allowOutsideClick: false,
                });
                this.loading.button = false;
            }
            let completelogs = record.biometricdata.filter((e)=> e.isRestDay == 0).every((x) => x.time_in && x.time_out);
            if(completelogs){
                this.$set(record, 'inCompleteLog', 0);
            }
        },
        reComputeTimekeepingRecord(record, recordDetails){
            // if(recordDetails.manual == 1){
            //     if(!recordDetails.uploadedFile.length > 0 || record.uploadedFile == ''){
            //         swal.fire(
            //             "Please Upload a Proof File!",
            //             "",
            //             "warning"
            //         )
            //         return false;
            //     }
            // }
            // Employee Schedule for that day
            let timeIn = this.$dayjs(recordDetails.time_in, 'HH:mm');
            let timeOut = this.$dayjs(recordDetails.time_out, 'HH:mm');
            let scheduleTimeIn = this.$dayjs(recordDetails.schedule_time_in, 'HH:mm');
            let scheduleTimeOut = this.$dayjs(recordDetails.schedule_time_out, 'HH:mm');
            let newTime = {
                hours_worked: 0,
                late_in_hours: 0,
            }
                // Computing Late in Hours
                if (timeIn.isAfter(scheduleTimeIn)) {
                    timeIn.subtract(1, 'minute');
                }
                newTime.late_in_hours = timeIn.isAfter(scheduleTimeIn) ? this.computeHours(recordDetails.schedule_time_in, recordDetails.time_in, 'HH:mm').toFixed(2) : 0;

                if (newTime.late_in_hours > 0 && this.$dayjs(recordDetails.time_out).subtract(1, 'minute').isSameOrAfter(this.$dayjs(recordDetails.schedule_time_out))) {
                    let diffInMinutes = this.$dayjs(recordDetails.schedule_time_out, 'HH:mm').diff(this.$dayjs(recordDetails.time_in, 'HH:mm'), 'minute');
                    let diffInHours = diffInMinutes / 60;
                    let hoursWorked = diffInHours - record.schedule.break_duration;
                    newTime.hours_worked = Math.max(0, parseFloat(hoursWorked.toFixed(2)));
                } else {
                    // if(timeIn.subtract(1, 'minute').isSameOrBefore(scheduleTimeIn) && timeOut.isSameOrAfter(scheduleTimeOut)){
                    //     newTime.hours_worked = 8.00;
                    // }else{
                    // newTime.hours_worked = this.computeHours(recordDetails.time_in, scheduleTimeOut.isSameOrBefore(timeOut) ? recordDetails.schedule_time_out : recordDetails.time_out) - recordDetails.break_duration;
                    newTime.hours_worked = this.computeHours(recordDetails.time_in, recordDetails.time_out, 'HH:mm') - recordDetails.break_duration;
                    newTime.hours_worked = Math.max(0, parseFloat(newTime.hours_worked.toFixed(2)));
                    // }
                }

            const time_in_swal_text 			    = recordDetails.time_in ? '' : 'Time In' ;
            const time_out_swal_text 			    = recordDetails.time_out ? '' : 'Time Out';
            const date_swal_text 			        = recordDetails.date_punched ? '' : 'Date';

            const array_text =
            [
                time_in_swal_text,
                time_out_swal_text,
                date_swal_text,
            ]
            const filtered = array_text.filter(function (el) {
                return el.replace(',', '');
            })
            if(recordDetails.time_in && recordDetails.time_out && recordDetails.time_out && recordDetails.date_punched){
                this.$set(recordDetails, 'hours_worked', newTime.hours_worked);
                this.$set(recordDetails, 'late_in_hours', newTime.late_in_hours);
                this.$set(recordDetails, 'editActive', false);
                swal.fire(
                    "Record Saved!",
                    "",
                    "success"
                )
            }else {
                swal.fire({
                    text: `Fill Required Fields (${filtered})`,
                    icon: 'warning',
                    allowOutsideClick: false,
                });
                this.loading.button = false;
            }
        },

        // Function to compute hours between two time values
        computeHours(time_from, time_to, timeFormat){
            let timeIn = this.$dayjs(time_from, timeFormat);
            let timeOut = this.$dayjs(time_to, timeFormat);

            let duration = this.$dayjs.duration(timeOut.diff(timeIn));
            let hoursWorked = duration.asMinutes() / 60; // Calculate hours with decimal minutes

            return hoursWorked;
        }

        // computeHours(time_from, time_to){
        //     let timeFormat = 'HH:mm';
        //     let timeIn = this.$dayjs(time_from, timeFormat);
        //     let timeOut = this.$dayjs(time_to, timeFormat);

        //     let duration = this.$dayjs.duration(timeOut.diff(timeIn));
        //     let hoursWorked = duration.asMinutes() / 60; // Calculate hours with decimal minutes

        //     return  hoursWorked;
        // }
    },
    mounted() {
    },
    computed: {
        ...mapGetters([]),
    },
};
</script>
