<template>
    <div class="w-100">
        <v-app id="my_vue_app">
            <v-card>
                <v-card-title>
                    <v-container fluid>
                        <v-row class="ml-0 mb-4" v-if="!['unaccounted','lvas_unaccounted'].includes(GET_ADMIN_TAB_IS_ACTIVE)">
                            <v-col cols="12">
                                <v-tabs v-model="summary_tab" color="cyan" slider-color="cyan" class="col-12 p-0" show-arrows>
                                    <v-tab
                                        v-for="(item) in admin_category"
                                        :key="item.value"
                                        ripple
                                        @click="()=>{
                                            filter.admin_category_id = item.value;
                                        }"
                                    >
                                        {{ item.text }}
                                    </v-tab>
                                </v-tabs>
                            </v-col>
                        </v-row>
                        <v-row class="pl-2">
                            <v-col cols="2" class="d-flex justify-content-start" v-if="GET_ADMIN_TAB_IS_ACTIVE == 'unaccounted'">
                                <v-autocomplete
                                    v-model="filter.category_type"
                                    :items="GET_ADMIN_CATEGORY_SELECTION"
                                    item-text="text"
                                    item-value="value"
                                    auto-select-first
                                    outlined
                                    label="Category Type"
                                    required
                                    dense
                                    clearable
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="2" class="d-flex justify-content-start">
                                <v-autocomplete
                                    v-model="filter.warehouse"
                                    :items="GET_ADM_WAREHOUSE_CODE_DROPDOWN"
                                    item-text="text"
                                    item-value="value"
                                    auto-select-first
                                    outlined
                                    label="Inventory"
                                    required
                                    dense
                                    clearable
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="2" class="d-flex justify-content-start">
                                <v-autocomplete
                                    v-model="filter.department_id"
                                    :items="GET_LIST_DEPARTMENT"
                                    item-text="text"
                                    item-value="value"
                                    auto-select-first
                                    outlined
                                    label="Department"
                                    required
                                    dense
                                    clearable
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="2" class="d-flex justify-content-start" v-if="GET_ADMIN_TAB_IS_ACTIVE == 'unaccounted'">
                                <v-autocomplete
                                    v-model="filter.classification"
                                    :items="GET_ADMIN_CLASSIFICATION_SELECTION"
                                    item-text="text"
                                    item-value="value"
                                    auto-select-first
                                    outlined
                                    label="Classification"
                                    required
                                    dense
                                    clearable
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="2" v-if="GET_ADMIN_TAB_IS_ACTIVE == 'accountability'" class="d-flex justify-content-start">
                                <v-autocomplete
                                    v-model="filter.employee_data_id"
                                    label="Accounted Employee"
                                    :items="employeeSelectionData"
                                    item-text="text"
                                    item-value="value"
                                    outlined
                                    required
                                    dense
                                    clearable
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="2">
                                <v-autocomplete
                                    v-model="filter.user_id"
                                    :items="preparer_selection"
                                    label="Prepared By / Updated By"
                                    item-text="name"
                                    item-value="id"
                                    outlined
                                    dense
                                    hide-details
                                    clearable
                                    auto-select-first
                                    multiple
                                    chips
                                    deletable-chips
                                    small-chips
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="2">
                                <v-btn @click="searchInput()">
                                    FILTER
                                </v-btn>
                            </v-col>
                            <!-- <v-col cols="2" v-if="GET_ADMIN_TAB_IS_ACTIVE == 'accountability'">
                                <v-autocomplete
                                    v-model="filter.admin_category_id"
                                    :items="adminCategory"
                                    item-text="text"
                                    item-value="value"
                                    label="Item Category"
                                    auto-select-first
                                    outlined
                                    dense
                                    clearable
                                ></v-autocomplete>
                            </v-col> -->
                            <v-spacer></v-spacer>
                        </v-row>
                        <v-row>
                            <v-spacer></v-spacer>
                            <v-col cols="3" md="3">
                                <div class="row col-md-12 align-items-end">
                                    <v-text-field
                                        id="searchBar"
                                        v-model="filter.search"
                                        label="Search AS # / ITEM NAME / ASSET TAG"
                                        single-line
                                        hide-details
                                        @keypress.enter="searchInput()"
                                        @change="searchInput()"
                                    >
                                    </v-text-field>
                                        <v-icon @click="searchInput()">mdi-magnify</v-icon>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col lg="2" class="mx-3" v-if="GET_ADMIN_TAB_IS_ACTIVE == 'unaccounted'">
                                <v-checkbox
                                    v-model="filter.summary"
                                    label="Summary"
                                    :true-value="1"
                                    :flase-value="0"
                                    hide-details
                                >

                                </v-checkbox>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-title>
                <v-container fluid>
                    <v-data-table
                        v-model="selected"
                        :headers="headers"
                        :items="items"
                        :search="search"
                        :single-select="singleSelect"
                        :options.sync="options"
                        item-key="id"
                        :show-select="showMultiSelect"
                        :server-items-length="totalItems"
                        :items-per-page="10"
                        :loading="!loaded"
                        :footer-props="{
                            itemsPerPageOptions: itemsPerPageOptions,
                        }"
                        loading-text="Loading... Please wait"
                        fixed-header
                        disable-sort
                    >
                        <template v-slot:[`item.price`]="{ item }">
                            <span>
                                &#x20b1;{{ item.price | currency }}
                            </span>
                        </template>
                        <template v-slot:[`item.image_path`]="{ item }">
                            <v-img :src="url+'/'+item.image_path" class="img-thumbnail" width="50" height="50" eager contain></v-img>
                        </template>
                        <template v-slot:[`item.full_name`]="{ item }">
                            <span v-if="item.emp_id == 0">GENERAL ACCOUNTABILITY</span>
                            <span v-else>{{ item.full_name }}</span>
                        </template>
                        <template v-slot:[`item.unaccounted`]="{ item }">
                            <span>{{ thousandSeprator(item.total_stocks - item.accounted) }}</span>
                            <!-- <span>{{ item }}</span> -->
                        </template>
                        <template v-slot:[`item.accounted`]="{ item }">
                            <!-- <span>{{ item }}</span> -->
                            <span>{{ thousandSeprator(item.accounted) }}</span>
                        </template>
                        <template v-slot:[`item.total_stocks`]="{ item }">
                            <span>{{ thousandSeprator(item.total_stocks) }}</span>
                        </template>
                        <template v-slot:[`item.admin_category_id`]="{ item }">
                           <a  class="ml-1 badge text-white" :style="`background-color:${category.find(e=>e.value === item.admin_category_id)?.color}`">{{category.find(e=>e.value === item.admin_category_id)?.text}}</a>
                        </template>
                        <template v-slot:[`item.filed`]="{ item }">
                           <a  class="ml-1 badge text-white" :style="`background-color:${filed.find(e=>e.value === item.filed)?.color}`">{{filed.find(e=>e.value === item.filed)?.text}}</a>
                        </template>
                        <template v-slot:[`item.printed`]="{ item }">
                        <a  class="ml-1 badge text-white" :style="`background-color:${STATUS.AS_SUMMARY_STATUS.find(e=>e.value === item.printed)?.color}`"
                        >
                            {{STATUS.AS_SUMMARY_STATUS.find(e=>e.value === item.printed)?.text}}
                        </a>
                        </template>
                        <template v-slot:[`item.filed_date`]="{ item }">
                           <span>{{formatDateandTime(item.filed_date)}}</span>
                        </template>
                        <template v-slot:[`item.action`]="{ item }">
                            <v-btn text icon color="orange" v-if="userAccess.view">
                                <v-icon class="btn-action" small @click="ViewAssets(item, 'View')">mdi-eye</v-icon>
                            </v-btn>
                            <v-btn text icon color="blue" v-if="userAccess.edit  && item.filed != 1">
                                <v-icon class="btn-action" small @click="ViewAssets(item, 'Update')">mdi-square-edit-outline</v-icon>
                            </v-btn>
                            <v-btn text icon color="red" v-if="userAccess.delete">
                                <v-icon class="btn-action" small @click="deleteAccountability(item.id)">mdi-delete</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:[`item.description`]="{ item }">
                                <div class="paragraph">{{ item.description }}</div>
                        </template>
                    </v-data-table>
                </v-container>
            </v-card>
        </v-app>
    </div>
</template>
<script>
import swal from "sweetalert2";
import { mapGetters } from 'vuex';
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
export default {
    mixins:[SharedFunctionsComponentVue],
    data() {
        return {
            summary_tab: 0,
            admin_category:[],
            singleSelect: false,
            selected: [],
            location_list:['headoffice','stores'],
            itemsCollection:[],
            url:process.env.VUE_APP_API_HOST,
            loaded: false,
            totalItems: 0,
            options: {},
            items: [],
            itemsPerPageOptions: [5, 10, 15, 50, 100],
            itemsPerPage: 10,
            search: "",
            filter:{
                search:"",
                location:[],
                employee_data_id:'',
                category_type: '',
                warehouse: '',
                classification: '',
                admin_category_id: '',
                summary:0,
                department_id: '',
                user_id: '',
            },
            awaitingSearch: false,
            userAccess: {
                view: false,
                create: false,
                edit: false,
                delete: false,
            },
            category:[
                {text:'ASSET', color: '#78909c', value: 2},
                {text:'LOW VALUE ASSET', color: '#ffb74d', value: 3}
            ],
            filed:[
                {text:'YES', color: '#25842d', value: 1},
                {text:'NO', color: '#c62828', value: 0}
            ],
            dataTable:[
                { text: "Accountability No.", align: "left", value: "accountability_num" },
                { text: "Full Name", align: "left", value: "full_name" },
                { text: "Location", align: "left", value: "location" },
                { text: "Action", align: "center", value: "action",sortable:false},
            ],
            preparer_selection: [],
        };
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS',
            'GET_NEW_ADMIN_ASSETS_TABLE',
            'GET_ADMIN_DISPATCH',
            'GET_ASSETS_DATA',
            'GET_NEW_ADMIN_OTHER_ACCOUNTABILITY_TABLE',
            'GET_ADMIN_TAB_IS_ACTIVE',
            'GET_ADMIN_DATA_TABLE_HEADERS',
            'GET_ADMIN_ITEMS_COLLECTIONS',
            'GET_EMPLOYEE_DATA_SELECTION',
            'GET_ADMIN_CATEGORY_SELECTION',
            'GET_ADM_WAREHOUSE_CODE_DROPDOWN',
            'GET_ADMIN_CLASSIFICATION_SELECTION',
            'GET_INBOUND_OUTBOUND_TYPE',
            'DIALOGS',
            'STATUS',
            'GET_LIST_DEPARTMENT',
            'GET_ALL_USERS_SELECT',
            'GET_EMPLOYEE_DATA'
        ]),
        headers(){
            if(this.filter.summary == 1){
               return [
                    { text: "Name", align: "left", value: "item_name" },
                    { text: "Classification", align: "center", value: "classification" },
                    { text: "Sub-Class", align: "center", value: "sub_classification" },
                    { text: "Inventory", align: "center", value: "total_stocks" },
                    { text: "Unaccounted", align: "center", value: "unaccounted" },
                    { text: "Accounted", align: "center", value: "accounted" },
                ]
            }else{
                return this.GET_ADMIN_DATA_TABLE_HEADERS;
            }
        },
        employeeSelectionData(){
            return this.GET_EMPLOYEE_DATA.concat([{ 'value': 0, 'text': 'GENERAL ACCOUNTABILITY', 'department_id': 0 }])
        },
        adminCategory(){
            return this.GET_ADMIN_CATEGORY_SELECTION.filter(e => e.value == 2 || e.value == 3).map((e) => {
                if(e.text == 'FIXED ASSET'){
                return { text: 'ASSETS', value: e.value };
                } else {
                return { text: e.text, value: e.value };
                }
            });
        },
        showMultiSelect(){
            if(this.GET_ADMIN_TAB_IS_ACTIVE=='unaccounted' || this.GET_ADMIN_TAB_IS_ACTIVE=='lvas_unaccounted'){
                if(this.filter.summary){
                    return false
                }else{
                    return true
                }
            }else{
                false
            }
        }
    },
    mounted() {
        this.getAllAccountability();
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        this.$store.dispatch('getEmployeeDataDropdown')
        this.$store.dispatch('getAdminCategories')
        this.$store.dispatch("getAllSelectUsers", {
            department_id: 1
        });
    },
    methods: {
        async getAllAccountability(filter = null) {
            this.items = []
            // this.$store.dispatch('adminDropdownGet', {
            //     url: 'get-low-value-assets-selection',
            //     mutaion: 'LOW_VALUE_ASSETS_SELECTION'
            // }).then((response)=>{
            //     this.$store.commit('LOW_VALUE_ASSETS_SELECTION', response.data)
            // })
            this.items = [];
            this.loaded = false;
            let payload = {
                search:this.search,
                accountability:this.GET_ADMIN_TAB_IS_ACTIVE,
                printed:1,
                ...this.filter,
                ...this.options,
                has_inventory:1,
            };
            if(this.filter.summary){
                    this.loaded = false;
                    this.selected = []
                    await this.$store.dispatch('adminAccountabilityGet', {
                        search:this.search,
                        accountability:'accountability',
                        url:'admin-accountability-summary',
                        ...this.filter,
                        ...this.options
                    }).then((response) => {
                        this.items = response.data;
                        this.loaded = true;
                        this.totalItems = response.total;
                    }).catch((e)=>{
                        this.loaded = true;
                    });
                }else{
                    this.$store.dispatch(this.GET_ADMIN_DISPATCH.get, payload).then((response) => {
                        this.items = response.data.data;
                        this.loaded = true;
                        this.totalItems = response.data.total;
                    }).catch((e)=>{
                        this.loaded = true;
                    });
                }


        },
        searchInput(){
            this.getAllAccountability({ query: this.search });
        },
        ViewAssets(item, action) {
             this.$store.dispatch('accountabilityGet', {
                    url: 'admin-accountability-view',
                    accountability_num: item.accountability_num,
                    item_category: item.item_category,
                    admin_category_id: item.admin_category_id,
                    with_asset_id: item.with_assets_id
                }).then((response) => {
                    if(response.length > 0){
                        this.$store.commit("EDIT_ADMIN_ACCOUNTABILITY_DETAILS", response[0]);
                    }else{
                        this.$store.commit("EDIT_ADMIN_ACCOUNTABILITY_DETAILS", {
                            accountability_num: '',
                            admin_accountabilityitem: [],
                            department: '',
                            department_name: '',
                            emp_id: '',
                            full_name: '',
                            id: '',
                            item_category: '',
                            location: '',
                            location_id: '',
                            vendor_id: ''
                        });
                    }
                    this.$store.commit("DIALOG", true);
                    this.$store.commit("ACTION", action);
                }).catch((e)=>{
                    swal.fire("Error !", "", "error");
                });
        },
        deleteAccountability(id) {
            swal.fire({
                icon: "warning",
                text: "Are you sure you want to Delete?",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#397373",
                cancelButtonColor: "grey",
                reverseButtons:true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.$store
                    .dispatch(this.GET_ADMIN_DISPATCH.delete, id)
                    .then((success) => {});
                }
            });
        },
    },
    watch: {
        DIALOGS:{
            handler(val){
                if(!val){
                    this.getAllAccountability();
                    this.selected = [];
                }
            },
            deep: true
        },
        selected:{
            handler(val){
                this.itemsCollection = []
                val.find(e=>{
                    this.itemsCollection.push(
                        {
                            asset_code:e.asset_code,
                            item_id:e.item_id,
                        }
                    )
                })
                this.$store.commit('ADMIN_ITEMS_COLLECTIONS',this.itemsCollection);
            }
        },
        GET_NEW_ADMIN_OTHER_ACCOUNTABILITY_TABLE:{
            handler(val){
                if(val){
                    this.getAllAccountability();
                }
            }
        },
        options: {
            handler() {
                this.getAllAccountability();
            },
            deep: true,
        },
        GET_NEW_ADMIN_ASSETS_TABLE:{
            handler(val){
                if(val){
                    this.getAllAccountability();
                }
            }
        },
        USER_ACCESS: {
            handler(val) {
                if (val != "fail") {
                    this.userAccess.edit = false;
                    this.userAccess.delete = false;
                    this.userAccess.view = false;
                    this.userAccess.create = false;
                    val.forEach((e) => {
                        if (e.actions_code == "edit") {
                            this.userAccess.edit = true;
                        } else if (e.actions_code == "delete") {
                            this.userAccess.delete = true;
                        } else if (e.actions_code == "view") {
                            this.userAccess.view = true;
                        } else if (e.actions_code == "create") {
                            this.userAccess.create = true;
                        }
                    });
                }
            },
        },
        GET_INBOUND_OUTBOUND_TYPE:{
            handler(val){
                if(val.length > 0){
                    this.$store.commit('ADMIN_DATA_TABLE_HEADERS',this.dataTable);
                    this.items = [];
                    this.loaded = false;
                    let payload = {
                        search:this.search,
                        accountability:'accountability',
                        ...this.filter,
                        ...this.options
                    };

                    this.$store.dispatch('getAllAccountability', payload).then((response) => {
                        this.items = response.data.data;
                        this.loaded = true;
                        this.totalItems = response.data.total;
                    }).catch((e)=>{
                        this.loaded = true;
                    });
                }
            },
            deep: true,
        },
        filter:{
            async handler(val){
                this.getAllAccountability()
            },
            deep:true
        },
        GET_ADMIN_CATEGORY_SELECTION:{
            handler(val){
                this.admin_category = val.filter(e => e.consumable != 1);
                this.admin_category.unshift({text: 'ALL', value: 0});
            },
            immediate: true
        },
    },
};
</script>
<style scoped>
.paragraph {
    white-space: pre !important;
}
</style>
