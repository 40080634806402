<template>
    <v-container>
        <ComponentHeader
            :button="true"
            :title="'SO Movement'"
        ></ComponentHeader>
        <v-container class="bg-bluish-gray" fluid>
            <v-card>
                <v-card-text>
                    <v-row class="p-1">
                        <v-col sm="3" md="3">
                            <v-autocomplete
                                v-model="filters.customer_id"
                                :items="dropdowns.customers"
                                label="Select Customer"
                                dense
                                outlined
                                hide-details
                                clearable
                                auto-select-first
                            ></v-autocomplete>
                        </v-col>
                        <v-col sm="3" md="2">
                            <v-autocomplete
                                v-model="filters.salesman"
                                :items="dropdowns.salesman"
                                label="Select Salesman"
                                dense
                                outlined
                                hide-details
                                clearable
                                auto-select-first
                            ></v-autocomplete>
                        </v-col>
                        <v-col sm="3" md="2">
                            <v-autocomplete
                                v-model="filters.issuer"
                                :items="dropdowns.issuer"
                                item-text="name"
                                item-value="id"
                                label="Select Issuer"
                                auto-select-first
                                hide-details
                                clearable
                                dense
                                outlined
                                deletable-chips
                                small-chips
                                multiple
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-spacer />
                        <v-col sm="12" md="4" class="text-right">
                            <!-- <v-btn small class="ml-1" @click="resetFields()">Clear</v-btn> -->
                            <v-btn small class="ml-1" :loading="loading.export" @click="exportToExcel()">Excel</v-btn>
                            <!-- <v-btn small class="ml-1" @click="getSalesIncentives()">Filter</v-btn> -->
                        </v-col>
                    </v-row>
                    <v-row class="p-1">
                        <v-col sm="3" md="3">
                            <v-autocomplete
                                v-model="filters.statuses"
                                :items="dropdowns.statuses"
                                label="Select Status"
                                auto-select-first
                                hide-details
                                clearable
                                dense
                                outlined
                                deletable-chips
                                small-chips
                                multiple
                            >
                                <template v-slot:selection="{ item, index }">
                                    <v-chip
                                        :color="item.color"
                                        :text-color="item.textColor"
                                        @click:close="removeChip(index)"
                                        class="my-1"
                                        x-small
                                        close
                                    >
                                        {{ item.text }}
                                    </v-chip>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col sm="3" md="2">
                            <v-autocomplete
                                v-model="filters.item_type"
                                :items="dropdowns.item_type"
                                label="Select Item Type"
                                auto-select-first
                                hide-details
                                clearable
                                dense
                                outlined
                                deletable-chips
                                small-chips
                                multiple
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col sm="3" md="2">
                            <v-checkbox
                                v-model="filters.include_image"
                                :true-value="1"
                                :false-value="0"
                                label="Include Image"
                                dense
                                hide-details
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row class="p-1">
                        <v-col sm="3" md="2">
                            <v-menu
                                v-model="filters.menu2"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="filters.dates[0]"
                                        v-on="on"
                                        label="Date From"
                                        append-icon="mdi-calendar-clock"
                                        dense
                                        outlined
                                        hide-details
                                        readonly
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="filters.dates[0]" @input="filters.menu2 = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col sm="3" md="2">
                            <v-menu
                                v-model="filters.menu3"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="filters.dates[1]"
                                        v-on="on"
                                        label="Date To"
                                        append-icon="mdi-calendar-clock"
                                        dense
                                        outlined
                                        hide-details
                                        readonly
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="filters.dates[1]" @input="filters.menu3 = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-container>
    </v-container>
</template>

<script>
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
import SharedFunctionsComponent from "@/views/main/Shared/SharedFunctionsComponent.vue";
import swal from 'sweetalert2';

export default {
    mixins: [
        SharedFunctionsComponent
    ],
    components: {
        ComponentHeader,
    },
    data() {
        return {
            dropdowns: {
                statuses: [
                    { id: 0, text: 'Saved', color: 'badge-saved', textColor: 'white' },
                    { id: 11, text: 'For Exec Approval', color: 'badge-for-exec', textColor: 'white' },
                    { id: 1, text: 'For Sales', color: 'badge-for-sales', textColor: 'white' },
                    { id: 2, text: 'For Accounting', color: 'badge-for-accounting', textColor: 'white' },
                    { id: 9, text: 'Exec Approved', color: 'badge-exec-approved', textColor: 'white' },
                    { id: 3, text: 'Approved', color: 'badge-approved', textColor: 'white' },
                    { id: 16, text: 'For Scheduling', color: 'badge-for-scheduling', textColor: 'black' },
                    { id: 8, text: 'Converted', color: 'badge-converted', textColor: 'white' },
                    { id: 10, text: 'Partial', color: 'badge-partial', textColor: 'white' },
                    { id: 4, text: 'Completed', color: 'badge-completed', textColor: 'white' },
                    { id: 12, text: 'Stockcard', color: '#FFAB00', textColor: 'black' },
                    { id: 6, text: 'Canceled', color: '#C62828', textColor: 'white' },
                    { id: 17, text: 'Waiting for stocks', color: 'badge-submitted', textColor: 'white' },
                    { id: 18, text: 'Preparing', color: 'badge-preparing', textColor: 'white' },
                    { id: 19, text: 'Prepared', color: 'badge-prepared', textColor: 'white' },
                    { id: 20, text: 'Checking', color: 'badge-checking', textColor: 'white' },
                    { id: 21, text: 'Checked', color: 'badge-checked', textColor: 'white' },
                    { id: 22, text: 'Invoiced', color: 'badge-invoiced', textColor: 'white' },
                ],
                item_type: [],
                customers: [],
                salesman: [],
                issuer: [],
            },
            filters: {
                dates: [
                    this.$dayjs().startOf("month").format("YYYY-MM-DD"),
                    this.$dayjs().endOf("month").format("YYYY-MM-DD"),
                ],
                statuses: null,
                item_type: null,
                menu2: false,
                menu3: false,
                include_image: 0,
                customer_id: null,
                salesman: null,
                issuer: null,
                store: true,
            },
            loading: {
                export: false,
            },
        }
    },
    methods: {
        async exportToExcel() {
            let exportData = {}
            let fileName = ''
            swal.fire({
                title:                      'Please wait...',
                allowOutsideClick:          false,
            })
            swal.showLoading();
            this.loading.export = true

            await this.$store.dispatch('reports', {
                filters: this.filters,
                url: 'get-so-movement',
            }).then(async response => {
                fileName = `SO Movement ${this.filters.dates[0]} - ${this.filters.dates[1]}`
                exportData = {
                    ['Summary']: {
                        headers: [
                            { header: 'SO#', key: 'order_num', style: { numFmt: '@' } },
                            { header: 'SQ#', key: 'quote_num', style: { numFmt: '@' } },
                            { header: 'CUSTOMER', key: 'company_name' },
                            { header: 'CREATED BY', key: 'created_by' },
                            { header: 'REMARKS', key: 'remarks' },
                            { header: 'STATUS', key: 'status' },
                            { header: 'TOTAL AMOUNT', key: 'total_amount', style: { numFmt: '#,##0.00' }, formatAs: 'float' },
                            { header: 'DISCOUNT AMOUNT', key: 'discount_amount', style: { numFmt: '#,##0.00' }, formatAs: 'float' },
                            { header: 'GROSS AMOUNT', key: 'gross_amount', style: { numFmt: '#,##0' }, formatAs: 'float' },
                            { header: 'DELIVERY DATE', key: 'delivery_date', formatAs: 'date' },
                            { header: 'MOVEMENT DATE', key: 'movement_date', formatAs: 'datetime' },
                        ],
                        data: response.data.summary,
                    },
                    ['Detailed']: {
                        headers: [
                            { header: 'IMAGE', key: '', width: 22, style: { alignment: { vertical: 'middle', horizontal: 'center' } }, include: this.filters.include_image },
                            { header: 'SO#', key: 'order_num', style: { numFmt: '@' } },
                            { header: 'SQ#', key: 'quote_num', style: { numFmt: '@' } },
                            { header: 'MODEL', key: 'model' },
                            { header: 'NAME', key: 'name' },
                            { header: 'FOC', key: 'foc' },
                            { header: 'QTY', key: 'quantity', formatAs: 'int' },
                            { header: 'SRP', key: 'srp', style: { numFmt: '#,##0.00' }, formatAs: 'float' },
                            { header: 'DISC PRICE', key: 'disc_price', style: { numFmt: '#,##0.00' }, formatAs: 'float' },
                            { header: 'TOTAL AMOUNT', key: 'item_total', style: { numFmt: '#,##0.00' }, formatAs: 'float' },
                            { header: 'UOM', key: 'uom' },
                            { header: 'TYPE', key: 'type' },
                            { header: 'CATEGORY', key: 'category' },
                        ],
                        data: response.data.detailed,
                        hasImages: this.filters.include_image,
                        options: {
                            views: [
                                { state: 'frozen', xSplit: 2, ySplit: 1 }
                            ],
                        },
                    }
                }

                await this.exportExcel(exportData, fileName, 'F69F1A', true)
            }).catch(error => {
                console.log(error)
            })

            swal.close();
            this.loading.export = false
        },

        removeChip(i) {
            this.filters.statuses = this.filters.statuses.filter((e, index) => {
                if (i != index) {
                    return e
                }
            });
        },
    },
    async mounted() {
        this.$store.dispatch('getItemTypes').then((response)=>{
            this.dropdowns.item_type = response.data
        })
        this.$store.dispatch('getCustomers',{}).then(response => {
            this.dropdowns.customers = response.data
        })
        this.$store.dispatch("getAllsalesmanSelection", {}).then(response => {
            this.dropdowns.salesman = response
        })
        this.$store.dispatch("getAllUsers").then(response => {
            this.dropdowns.issuer = response.data
        });
    },
}
</script>