<template>
    <v-form ref="form" v-model="valid" lazy-validation>
        <!-- Functions -->
        <v-card class="px-3 rounded-0">
            <v-card-subtitle>
                <v-row>
                    <v-col cols="3">
                        <v-btn v-if="userAccess.includes('create')" class="m-3" @click="$store.commit('TRANSACTION_JOURNAL_ACTION', 'create'); toggleDialog('open');">
                            <v-icon>mdi-plus</v-icon>Create
                        </v-btn>
                    </v-col>
                    <v-spacer/>
                    <v-col cols="3" class="pull-right">
                        <v-text-field
                            v-model="search"
                            label="Search"
                            append-icon="mdi-magnify"
                            @keydown.enter="getAllTransactionTypes()"
                            @click:append="getAllTransactionTypes()"
                        />
                    </v-col>
                </v-row>
            </v-card-subtitle>
            <!-- Table -->
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-data-table
                            :headers="mainHeaders"
                            :items="mainItems"
                            :options.sync="options"
                            :server-items-length="totalItems"
                            :items-per-page="10"
                            :loading="tableLoad"
                            :footer-props="{
                                itemsPerPageOptions: itemsPerPageOptions
                            }"
                            dense
                        >
                            <template v-slot:[`item.department_id`]="{ item }">
                                <a class="ml-1 badge text-white" :style="'background-color:#329ef4'">
                                    {{ GET_DEPARTMENT_ACCOUNTING_LOAD.find(e=>e.value === item.department_id)?.text }}
                                </a>
                            </template>
                            <template v-slot:[`item.action`]="{ item }">
                                <v-btn v-if="userAccess.includes('view')" text icon color="orange" @click="viewEdit('view', item.id)">
                                    <v-icon small>mdi-eye</v-icon>
                                </v-btn>
                                <v-btn v-if="userAccess.includes('edit')" text icon color="primary" @click="viewEdit('edit', item.id)">
                                    <v-icon small>mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn v-if="userAccess.includes('delete')" text icon color="error" @click="destroy(item.id)">
                                    <v-icon small>mdi-delete</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
            <!-- Dialog -->
            <v-dialog v-model="mainDialog" persistent scrollable width="30%">
                <v-card elevation="0" class="pa-0 m-0 overflow-hidden">
                    <v-card-title v-if="!dialogLoader">
                        <v-container class="pa-0">
                            <v-row class="m-0">
                                <v-col>
                                    <span class="headline">{{ TRANSACTION_JOURNAL.ACTION.charAt(0).toUpperCase() + TRANSACTION_JOURNAL.ACTION.slice(1) }} Type</span>
                                </v-col>
                                <v-col cols="pull-right-1">
                                    <v-btn text icon color="gray" class="float-right" @click="toggleDialog('close')">
                                        <v-icon>mdi-close-circle</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-card elevation="0" dense>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-card elevation="0" dense>
                                                <v-card-text>
                                                    <v-row>
                                                        <v-col :cols="(USERACCOUNT_LOAD.super_admin == 1) || (USERACCOUNT_LOAD.department_id == 4) ? 6 : 12">
                                                            <v-text-field
                                                                v-model="form.name"
                                                                :disabled="isDisabled()"
                                                                :rules="required"
                                                                label="Name"
                                                                outlined
                                                                dense
                                                                />
                                                            </v-col>
                                                            <v-col cols="6" v-if="(USERACCOUNT_LOAD.super_admin == 1) || (USERACCOUNT_LOAD.department_id == 4)">
                                                                <v-autocomplete
                                                                v-model="form.department_id"
                                                                :items="GET_DEPARTMENT_ACCOUNTING_LOAD"
                                                                :disabled="isDisabled()"
                                                                :rules="required"
                                                                label="Department"
                                                                outlined
                                                                dense
                                                            />
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                            <v-container class="mt-5">
                                                <v-row>
                                                    <v-col class="text-center">
                                                        <v-btn :loading="btnLoad" 
                                                            v-if="TRANSACTION_JOURNAL.ACTION != 'view'"
                                                            @click="commitTransaction(TRANSACTION_JOURNAL.ACTION == 'create' ? 'submit' : 'update')"
                                                        >
                                                            {{ TRANSACTION_JOURNAL.ACTION == 'create' ? 'Create' : 'Update' }}
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-container>
                    </v-card-title>
                    <div v-else class="text-center pa-2">
                        <v-progress-circular
                            :size="80"
                            color="primary"
                            indeterminate
                        />
                    </div>
                </v-card>
            </v-dialog>
        </v-card>
    </v-form>
</template>
<script>
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue';
export default {
    mixins:[SharedFunctionsComponent],
    data(){
        return{
            valid: true,
            userAccess :[],
            mainDialog: false,
            mainHeaders: [],
            mainItems: [],
            options: {},
            totalItems: 0,
            tableLoad: false,
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            itemsPerPage: 10,
            search: '',
            dialogLoader: false,
            form:{
                id: null,
                name: '',
                department_id: null
            },
            btnLoad: false,
            required: [ v => !!v || 'This field is required' ]
        }
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS',
            'GET_DEPARTMENT_ACCOUNTING_LOAD',
            'TRANSACTION_JOURNAL',
            'USERACCOUNT_LOAD'
        ])
    },
    mounted(){
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        this.getAllTransactionTypes()
        this.alterTable()
        document.addEventListener('keydown', this.handleKeyDown)
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.handleKeydown);
    },
    methods:{
        toggleDialog(action = 'close'){
            switch(action){
                case 'open':
                    this.mainDialog = true
                    this.checkUserCredentials()
                    break;
                case 'close':
                    this.mainDialog = false
                    this.resetFields()
                    this.getAllTransactionTypes()
                    break;
            }
        },
        resetFields(){
            this.$refs.form.resetValidation()
            this.$store.commit('TRANSACTION_JOURNAL_ACTION', '')
            this.form = {
                id: null,
                name: '',
                department_id: null   
            }
        },
        checkRequiredFields(){
            let emptyFields = []
            let required = {
                'name': 'Name',
                'department_id': 'Department'
            }
            Object.keys(required).forEach(e=>{
                if (!this.form[e]) {
                    emptyFields.push(required[e])
                }
            })
            if (emptyFields.length > 0) {
                Swal.fire('Please fill the following fields: \n', emptyFields.join(', '), 'warning')
                return false
            }
            return true
        },
        commitTransaction(action){
            if(!this.$refs.form.validate()){
                if (!this.checkRequiredFields()) return
            } else {
                let url = ''
                let msg = ''
                switch(action){
                    case 'submit':
                        url = 'addTransactionType'
                        msg = 'submitted'
                        break;
                    case 'update':
                        url = 'updateTransactionType'
                        msg = 'updated'
                        break;
                }
                this.btnLoad = true
                Swal.fire({
                    title: '',
                    text: `Are you sure you want to ${action} transaction type?`,
                    icon: 'warning',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    allowOutSideClick: false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor: 'grey',
                    confirmButtonColor: 'Confirm'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch('transactionJournalPost', { form: this.form, url: url }).then(response => {
                            Swal.fire('', `Transaction Type ${msg}!`, 'success')
                            this.getAllTransactionTypes()
                            this.toggleDialog('close')
                            this.btnLoad = false
                        }).catch(error => {
                            if (error.response.status == 422) {
                                Swal.fire('', 'Error!', 'error')
                                console.log(error)
                                this.btnLoad = false
                            }
                        })
                    } else {
                        this.btnLoad = false
                    }
                })
            }
        },
        getAllTransactionTypes(){
            this.tableLoad = true
            this.mainItems = []
            let payload = {
                search: this.search,
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                url: 'getAllTransactionTypes'
            }
            this.$store.dispatch('transactionJournalGet', payload).then(response=>{
                this.mainItems = response.data.data.data
                this.totalItems = response.data.data.total
                this.tableLoad = false
            })
        },
        viewEdit(action, id){
            this.dialogLoader = true
            this.toggleDialog('open')
            this.action = this.$store.commit('TRANSACTION_JOURNAL_ACTION', action)
            this.$store.dispatch('transactionJournalPost', { id: id, url: 'getTransactionType' }).then(response=>{
                this.form = { ...response.data }
                this.dialogLoader = false
            })
        },
        isDisabled(){
            switch(this.TRANSACTION_JOURNAL.ACTION){
                case 'view':
                    return true
            }
        },
        destroy(id){
            Swal.fire({
                title: '',
                text: 'Are you sure you want to delete transaction type?',
                icon: 'warning',
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                allowOutSideClick: false,
                confirmButtonColor: '#397373',
                cancelButtonColor: 'grey',
                confirmButtonColor: 'Confirm'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('transactionJournalPost', { id: id, url: 'deleteTransactionType' }).then(response => {
                        Swal.fire('', 'Transaction Type Deleted!', 'success')
                        this.getAllTransactionTypes();
                    }).catch(error => {
                        if (error.response.status == 422) {
                            Swal.fire('', 'Error!', 'error')
                            console.log(error)
                        }
                        this.getAllTransactionTypes()
                    })
                }
            })
        },
        checkUserCredentials(){
            if (!this.USERACCOUNT_LOAD.super_admin && this.USERACCOUNT_LOAD.department_id != 4) this.form.department_id = this.USERACCOUNT_LOAD.department_id
        },
        alterTable(){
            if (this.USERACCOUNT_LOAD.super_admin == 1 || this.USERACCOUNT_LOAD.department_id == 4) {
                this.mainHeaders = [
                    { text: 'Name', value:'name', sortable: false },
                    { text: 'Department', value:'department_id', align: 'center', sortable: false },
                    { text: 'Actions', value:'action', align: 'center', sortable: false }
                ]
            } else {
                this.mainHeaders = [
                    { text: 'Name', value:'name', sortable: false },
                    { text: 'Actions', value:'action', align: 'center', sortable: false }
                ]
            }
        },
        handleKeyDown(event){
            if (this.mainDialog && !this.dialogLoader) this.closeDialogByEsc(event, this.toggleDialog)
        }
    },
    watch:{
        USER_ACCESS:{
            handler(val){
                if (val != 'fail') {
                    this.userAccess = val.map(e=>e.actions_code)
                }
            }
        },
        options:{
            handler(){
                this.getAllTransactionTypes()
            },
            deep: true
        }
    }
}
</script>
<style scoped>

</style>