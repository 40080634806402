<template>
  <v-card class="bg-color" style="border-radius:0px;">
    <v-row class="pa-10">
        <v-col class="">
            <h5>Item Location</h5>
            <div class="d-flex justify-space-between">
                <v-row class="mr-4">
                    <v-btn
                        small
                        class="ml-3 mt-3 mr-1"
                        color="primary"
                        @click="assignLocation()"
                    >
                        Assign Location
                    </v-btn>
                    <v-btn
                        small
                        class="mt-3"
                        color="primary"
                        @click="batchAddition()"
                    >
                        Batch Addition
                    </v-btn>
                </v-row>
                <v-col md="3" class="d-flex justify-space-between">
                    <v-text-field
                        v-model="search"
                        label="Search Model / Name"
                        hide-details
                        clearable
                        outlined
                        dense
                        append-icon="mdi-magnify"
                        @click:append="getLocation()"
                        @click:clear="getLocation()"
                        @keydown.enter="getLocation()"
                    />
                </v-col>
                <v-col md="3" class="d-flex justify-space-between">
                    <v-autocomplete 
                        v-model="filterWarehouse"
                        outlined
                        dense
                        label="Select Warehouse"
                        clearable
                        :items="GET_ALL_WAREHOUSES"
                        item-value="value"
                        auto-select-first
                        item-text="text"
                        @change="getLocation()"
                    />
                </v-col>
            </div>

            <v-data-table
                v-model="selected"
                :items="location"
                :headers="locationHeaders"
                :options.sync="options"
                :search="search"
                :server-items-length="totalItems"
                dense
                :loading="!loaded"
                show-select
                >

                <template v-slot:[`item.image_path`]="{ item }">
                    <v-img class="img-thumbnail" width="50" height="50" :src="checkImagePath(item)" @error="checkImage(item)" eager contain></v-img>
                </template>

                <!-- <template v-slot:[`item.action`]="{ item }">
                    <span class="text-nowrap">
                        <v-btn text icon color="red">
                            <v-icon class="btn-action" @click="assignLocation(item)" small>mdi-delete</v-icon>
                        </v-btn>
                    </span>
                </template> -->

            </v-data-table>

        </v-col>
    </v-row>
    <v-dialog v-model="assignLocationDialog" persistent max-width="60%" scrollable>
        <v-card>

            <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                <span class="headline">Assign Location</span>
                <v-row class="m-0">
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                        text
                        icon
                        small
                        color="gray"
                        class="float-right"
                        @click="closeAssignLocationDialog()"
                        >
                        <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-container fluid style="background-color: white; overflow-y: auto">
                <v-card-text>
                    <v-row class="d-flex align-center pr-5 pl-5">
                        <v-col cols="6">
                            <v-text-field
                                outlined
                                label="Assign Location"
                                v-model="itemLocation"
                                dense
                            >
                            </v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <v-autocomplete 
                                v-model="assignWarehouse"
                                outlined
                                dense
                                label="Select Warehouse"
                                clearable
                                :items="GET_ALL_WAREHOUSES"
                                item-value="value"
                                auto-select-first
                                item-text="text"
                            />
                        </v-col>
                    </v-row>

                    <v-row class="pa-5">
                        <v-col md="12">

                            <v-data-table
                                :headers="assignlocationHeaders"
                                :items="selected"
                            >
                                <template v-slot:[`item.image_path`]="{ item }">
                                    <v-img class="img-thumbnail" width="50" height="50" :src="checkImagePath(item)" @error="checkImage(item)" eager contain></v-img>
                                </template>
    
                                <template v-slot:[`item.action`]="{ item }">
                                    <span class="text-nowrap">
                                        <v-btn text icon color="red">
                                            <v-icon class="btn-action" @click="removeLocation(item)" small>mdi-delete</v-icon>
                                        </v-btn>
                                    </span>
                                </template>

                            </v-data-table>
                            
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-row class="text-right">
                        <v-col>
                            <v-btn
                                @click="submitSpLocation()"
                                small
                                color="#f69f1a"
                            >
                                <v-icon>mdi-map-marker-plus-outline</v-icon>
                                Submit
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-container>

        </v-card>
    </v-dialog>

    <v-dialog v-model="batchAdditionDialog" persistent max-width="30%">
        <v-card>
            <v-row class="m-0">
                <v-col>
                    <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                        <span class="headline">Batch Addition</span>
                        <v-row class="m-0">
                            <v-col cols="pull-right-10 p-2">
                                <v-btn
                                text
                                icon
                                small
                                color="gray"
                                class="float-right"
                                @click="closeBatchAdditionDialog()"
                                >
                                <v-icon>mdi-close-circle</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                </v-col>
            </v-row>

            <v-card-text class="ma-0">
                <v-row class="m-0 pa-0">
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="getAllWarehouses"
                            :items="GET_ALL_WAREHOUSES"
                            label="Select Warehouses"
                            outlined
                            dense
                            clearable
                            item-value="value"
                            auto-select-first
                            item-text="text"
                            multiple
                            small-chips
                        />
                    </v-col>
                </v-row>
                <v-card class="pa-1 ma-1" elevation="0">
                    <div class="ma-0" style="max-height:500px; width:100%; margin-bottom:10px; overflow-y:auto; overflow-x:hidden">
                        <v-row v-if="!showModelNotFound">
                            <v-col class="text-right pb-5 pr-4 pt-1 mt-3">
                                <v-btn
                                    dark
                                    x-small
                                    fab
                                    color="primary"
                                    @click="manualAddition()"
                                >
                                    <v-icon>
                                        mdi-plus
                                    </v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <div v-if="!showModelNotFound">
                            <v-row class="mx-0 pa-0 ma-0" v-for="(text_area_field,i) in text_area_fields" :key="i">
                                <v-text-field
                                    v-model="text_area_fields[i].dataFromExcel"
                                    dense
                                    outlined
                                    label="Model"
                                    :background-color="text_area_fields[i].error ? '#f55246' :'none'"
                                    class="pr-1"
                                    rows="1"
                                    @paste="onPaste"
                                    :autofocus="batchAdditionDialog"
                                    @click="$event.target.focus()"
                                >
                                </v-text-field>
                                    
                                <v-text-field
                                    v-model="text_area_fields[i].dataFromExcel2"
                                    dense
                                    outlined
                                    label="Item Location"
                                    :background-color="text_area_fields[i].error ? '#f55246' :'none'"
                                    class="pr-1"
                                    rows="1"
                                    @paste="onPaste"
                                    :autofocus="batchAdditionDialog"
                                    @click="$event.target.focus()"
                                >
                                </v-text-field>
        
                                <v-btn
                                    dark
                                    x-small
                                    fab
                                    color="red"
                                    @click="clearItem(i)"
                                >
                                    <v-icon
                                    dark
                                    small
                                    >
                                    mdi-minus
                                    </v-icon>
                                </v-btn>
                            </v-row>
                        </div>
                        <div v-if="showModelNotFound">
                            <v-row class="mx-0 pa-0 ma-0" v-for="(ShowNotInModel, i) in  ShowNotInModels" :key="i">
                                <v-text-field
                                    v-model="ShowNotInModels[i].e.dataFromExcel"
                                    class="pr-1"
                                    :autofocus="batchAdditionDialog"
                                    :background-color="ShowNotInModels[i].e.error ? '#f55246' :'none'"
                                    rows="1"
                                    outlined
                                    dense
                                    label="Model"
                                    @paste="onPaste"
                                    @change="getAllData()"
                                    @click="$event.target.focus()"
                                >
                                </v-text-field>
                                <v-text-field
                                    v-model="ShowNotInModels[i].e.dataFromExcel2"
                                    :autofocus="batchAdditionDialog"
                                    class="pr-1"
                                    rows="1"
                                    outlined
                                    :background-color="ShowNotInModels[i].e.error ? '#f55246' :'none'"
                                    dense
                                    label="Item Location"
                                    @paste="onPaste"
                                    @change="getAllData()"
                                    @click="$event.target.focus()"
                                >
                                </v-text-field>
                                <v-btn
                                    dark
                                    x-small
                                    fab
                                    color="red"
                                    @click="deleteNotInModel(ShowNotInModels[i].e.dataFromExcel, i)"
                                >
                                    <v-icon
                                        dark
                                        small
                                    >
                                    mdi-minus
                                    </v-icon>
                                </v-btn>
                            </v-row>
                        </div>
                    </div>
                </v-card>

                <v-row class="pa-0 mt-2">
                    <v-col cols="6">
                        <v-checkbox 
                            dense 
                            v-model="showModelNotFound"
                            label="Model not found in the system"
                            @click="showNotInModel()"
                        />
                    </v-col>
                    <v-col cols="6">
                        <v-btn
                            class="float-right ml-2"
                            @click="submitBatchAddition()"
                            color="#f69f1a"
                            :disabled="isDisabledButton"
                        >
                            <v-icon small>mdi-subdirectory-arrow-right</v-icon>
                            Submit
                        </v-btn>
    
                        <v-btn
                            class="float-right"
                            dark
                            color="gray"
                            @click="clearBatchAdditionFields()"
                        >
                            Clear
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
  </v-card>

</template>

<script>
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2'

export default {
    data(){
        return{
            assignLocationDialog: false,
            batchAdditionDialog: false,
            search: '',
            locationHeaders: [
                { text: 'Image', align: 'left', value: 'image_path' },
                { text: 'Model', value: 'model'},
                { text: 'Name', value: 'name' },
                { text: 'UOM', value: 'uom' },
                { text: 'Baklas Qty', value: 'baklas_qty' },
                { text: 'Part#', value: 'part_num'},
                { text: 'Location', value: 'item_location', align: 'center'},
                { text: 'Actions',align: 'center', value: 'action', sortable: false}
            ],

            assignlocationHeaders: [
                { text: 'Image', align: 'left', value: 'image_path' },
                { text: 'Model', value: 'model'},
                { text: 'Name', value: 'name' },
                { text: 'UOM', value: 'uom' },
                { text: 'Baklas Qty', value: 'baklas_qty' },
                { text: 'Part#', value: 'part_num'},
                { text: 'Location', value: 'item_location', align: 'center'},
                { text: 'Actions',align: 'center', value: 'action', sortable: false}
            ],

            options: {},
            loaded: false,
            totalItems: 0,
            location: [],
            selected: [],
            itemLocation: '',
            text_area_fields: [],
            text_area_field: {
                dataFromExcel: "",
                dataFromExcel2: "",
            },
            result: [],
            getAllWarehouses: [],
            assignWarehouse: [],
            quantity: 0,
            quantityBatchAddition: 0,
            showModelNotFound: false,
            ShowNotInModels: [],
            ShowNotInModel: {
                dataFromExcel: '',
                dataFromExcel2: ''
            },
            listOfNotInModels: [],
            batchItemLocation: '',
            isDisabledButton: true,
            errorResult: false,
            filterWarehouse: 1,
        }
    },
    components: {

    },
    computed: {
        ...mapGetters([
            'GET_ALL_SPARE_PARTSS',
            'GET_ALL_WAREHOUSES',
            'GET_ITEMS_BY_MODEL',
        ]),
    },
    methods: {  
        async getLocation() {
            this.loaded = false
            this.location = [];
            let payload = {
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                search: this.search,
                filter_warehouse: this.filterWarehouse,
                forLocation: 1,
            }
            await this.$store.dispatch('getAllSpareParts',payload).then(response=> {
                this.loaded = true
                this.location = response.data.data.data;
                this.totalItems = response.data.totalItems
            })
        },

        assignLocation() {
            this.assignLocationDialog = true;
        },

        closeAssignLocationDialog() {
            this.clearAssignLocationFields()
            this.assignLocationDialog = false;
        },

        removeLocation(item) {
            let remove_selected = this.selected.filter((x) => x.id != item.id)
            this.selected = remove_selected
        },

        checkImagePath(item) {
            this.$store.dispatch('checkImagePath', item);
        },

        checkImage(item) {
            item.image_path = "/images/items/no-image.png";
            return item.image_path;
        },

        submitSpLocation() {
            Swal.fire({
                // title: 'Are you sure you want to submit location?',
                icon: 'question',
                text: 'Are you sure you want to submit location?',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(confirm=>{
                if(confirm.isConfirmed){
                    let payload = {
                        selectedSp: this.selected,
                        itemLocation: this.itemLocation,
                        assignWarehouse: this.assignWarehouse,
                    }
                    this.$store.dispatch('submitSpLocation', payload).then(response => {
                        Swal.fire('Success!',response.data.message[0],'success')
                        this.getLocation()
                        this.closeAssignLocationDialog()
                    }).catch(err => {
                        console.log(err)
                        Swal.fire('Error!','','error')
                    })
                }
            })
        },

        clearAssignLocationFields() {
            this.itemLocation           = ''
            this.selected               = []
            this.assignWarehouse        = []
            this.quantity               = 0
        },

        batchAddition() {
            this.batchAdditionDialog = true;
            this.showModelNotFound = false;
            this.text_area_fields = [];

            if (this.text_area_fields.length < 1) {
                this.text_area_fields.push({
                    dataFromExcel: "",
                    dataFromExcel2: "",
                    error: false,
                });
            }
        },

        closeBatchAdditionDialog() {
            this.text_area_fields = []
            this.ShowNotInModels = []
            this.batchAdditionDialog = false;
            this.isDisabledButton = true;
        },

        manualAddition() {
            this.text_area_fields.push({
                dataFromExcel: "",
                dataFromExcel2: "",
                error: false,
            });        
        },

        onPaste(evt) {
            if (this.getAllWarehouses.length == 0) {
                Swal.fire('Info!','- Must select warehouses first','info')
                return false;
            }

            var pastedText = evt.clipboardData.getData('Text');

            if (!pastedText) {
                return false;
            } else {
                this.result = []
                var rows = pastedText.split("\r\n")
                for (let y = 0; y < rows.length; y++) {
                    let cells = rows[y].split("\t");
                    if (cells[0] !== '') {
                        this.result.push({
                            dataFromExcel: cells[0],
                            dataFromExcel2: cells[1],
                            error: false,
                        });
                    }
                }
                this.getAllData()
            }
        },

        myFunction() {
            this.text_area_fields = this.result
            this.batchAdditionDialog = true;
        },

        clearItem(i) {
            this.text_area_fields = this.text_area_fields.filter((e, index) => {
                if (i != index) {
                    return e
                }
            });
        },

        clearBatchAdditionFields() {
            this.text_area_fields = [];
            this.result = [];
            this.ShowNotInModels = [];
            this.isDisabledButton = true;

            if (this.text_area_fields.length < 1) {
                this.text_area_fields.push({
                    dataFromExcel: "",
                    dataFromExcel2: "",
                    error: false,
                });
            }
        },

        submitBatchAddition() {
            if(this.getAllWarehouses.length == 0) {
                Swal.fire('Info!','- Please select warehouse','info')
            } else {
                Swal.fire({
                    title:'Add',
                    text:'- Are you sure you want to Submit?',
                    icon: 'question',
                    reverseButtons: true,
                    showCancelButton:true,
                }).then(action=>{
                    if(action.isConfirmed){
                        let payload = {
                            modelLocation: this.text_area_fields,
                            getAllWarehouses: this.getAllWarehouses,
                        }
                        this.$store.dispatch('submitBatchLocation', payload).then(response => {
                            Swal.fire('Success!',response.data.message[0],'success')
                            this.getLocation()
                            this.closeBatchAdditionDialog()
                        }).catch(err => {
                            console.log(err)
                            Swal.fire('Error!','','error')
                        })
                    }
                })
            }
        },

        showNotInModel() {
            let that = this
            if (this.ShowNotInModels.length == 0) {
                if (this.showModelNotFound == true) {
                    this.result.filter(function (e, i) {
                        if (e.error == true) {
                            that.ShowNotInModels.push({ e, i });
                        }
                    });
                } 
            } 
        },

        getAllData() {
            let payload = {
                items: this.result.map(e => { return e.dataFromExcel.toUpperCase() }),
                forItemLocation: true,
                warehouse_id: this.getAllWarehouses
            }
            this.$store.dispatch('getItemsByModel', payload).then(response => {
                let items = response.data
                response.data = response.data.map(e => { return e.model })
                let lowerCaseResponseModel = [];

                response.data.forEach(e => {
                    lowerCaseResponseModel.push(e.toLowerCase());
                })

                this.result.forEach((e, i) => {
                    if (!response.data.includes(e.dataFromExcel) && !lowerCaseResponseModel.includes(e.dataFromExcel.toLowerCase())) {
                        this.errorResult =  this.result[i].error = true;
                    }
                    else {
                        let item_location = items.find(q => q.model == e.dataFromExcel).item_location
                        e.dataFromExcel2 = item_location == null ? '' + e.dataFromExcel2 : (item_location == e.dataFromExcel2 ? item_location : item_location + '/' + e.dataFromExcel2)
                        this.errorResult = this.result[i].error = false;
                    }
                })

                if (this.result.length > 0) {
                    this.isDisabledButton = false;
                } else {
                    this.isDisabledButton = true;
                }
    
                if (this.errorResult == true) {
                    this.isDisabledButton = true;
                } else {
                    this.isDisabledButton = false;
                }
            })

            this.closeBatchAdditionDialog();
            setTimeout(this.myFunction, 1);
        },

        deleteNotInModel(deleteModel, i) {
            let that = this;
            let tempArr = [];

            let temp = that.text_area_fields.filter(e => {
                if (e.dataFromExcel == deleteModel) {

                }
                else {
                    this.listOfNotInModels.pop();
                    return e;
                }
            })
            this.ShowNotInModels.forEach((e, index) => {
                if (i != index) {
                    tempArr.push(e);
                }
            })
            this.ShowNotInModels = tempArr;
            that.text_area_fields = temp;
        },
    },
    mounted() {
        this.getLocation();
        this.$store.dispatch('getAllWarehouses')
    },
    watch: {
        options: {
            handler(val){
                this.getLocation();
            },
            deep: true,
        },

        // showModelNotFound: {
        //     handler(val) {
        //         if(val == true) {
        //            this.result.filter(x=> x.error == true)
        //         } else {

        //         }
        //     }
        // }
    },
}
</script>

<style scoped>

</style>