<template>
    <div class="tabs-body-color">
        <v-container fluid class="pa-0">
            <v-row class="pt-4 pl-2" v-if="userAccess.create">
                <v-col cols="6" class="d-flex no-block align-items-center">
                    <div class="btn-toolbar pl-0">
                        <v-btn class="ml-1 mb-2" @click="AddDialog()">
                           + ADD PURCHASE BASKET
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-row>
              <FasBasketTableComponent v-if="GET_ADMIN_FASBASKET_STATUS.tab_name == isActive_tab"></FasBasketTableComponent>
            </v-row>
        </v-container>
    
    </div>
</template>
<script>

import FasBasketTableComponent from '@/views/main/modules/Admin/FASBasket/FasBasketTableComponent.vue';
import { mapGetters } from 'vuex'
export default {
    components: {
        FasBasketTableComponent,
    },
    props:["isActive_tab"],
    data() {
        return {
            userAccess:{
                create:false,
                create_po:false
            },
            add_type:''
        }
    },
    computed: {
        ...mapGetters(['USER_ACCESS','GET_ADMIN_FASBASKET_STATUS','DIALOGS'])
    },
    mounted() {
   
    },
    methods: {
        AddDialog(type){
            this.$store.commit('ADMIN_ITEMS_LIST_SECTION',true);
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", "Submit");
        }
    },
    watch:{
        USER_ACCESS:{
            handler(val){
            if(val != "fail"){
            this.userAccess.create = false;
            this.userAccess.create_po = false;
            val.forEach(e => {
                if (e.actions_code == 'create') {
                    this.userAccess.create = true;
                }
              });
            }
          }
        },
    }
};
</script>
