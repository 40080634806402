import PaymentTransmittal from "@/views/main/modules/Accounting/PaymentTransmittal/PaymentTransmittalGroupComponent.vue"
import repository from "@/api/credentials";

const routes = [{
    path: "/accounting/payment-transmittal/:id",
    name: "acctpaymenttransmittal",
    component: PaymentTransmittal,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Payment Transmittal',
    }
}]

export default routes;
