<template>
    <v-container fluid>
      <v-card-title>
        <v-row>
          <v-col md="16" class="d-flex no-block align-items-center">
            <h4 class="page-title">AP Settings</h4>
          </v-col>
        </v-row>
      </v-card-title>
      <v-container>
        <v-row>
          <template>
            <v-tabs v-model="tab" color="cyan" dark slider-color="cyan" show-arrows background-color="#424242"
              class="mx-2">
                  <v-tabs-slider color="cyan"></v-tabs-slider>
                      <v-tab ripple v-for="(item, i) in company_component" :key="item" class="ml-3" @click="tabs(i)">
                      {{ item.tab_name }}
                      </v-tab>
            </v-tabs>
          </template>
        </v-row>
      </v-container>
      <v-container class="ma-0 pa-2">
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(table, index) in company_component" :key="index">
            <keep-alive>
              <component :is="table.components" :cp_text="table.status_text" :company_component="company_component">
              </component>
            </keep-alive>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
      <dialogs :cp="components" :scrollable="true" :width="dialog_width" :retainfocus="false"></dialogs>
    </v-container>
  </template>
  <script>
  import AccountsPayableComponent from "./AccountsPayableComponent.vue";
  import Dialogs from "@/views/main/layouts/dialogs/Dialog.vue";
  import AccountsPayableViewComponent from "@/views/main/layouts/dialogs/Masterdata/Settings/AccountingAP/AccountsPayableViewComponent.vue";
  
  
  export default {
    components: {
      AccountsPayableComponent,
      Dialogs,
      AccountsPayableViewComponent,
    },
    data() {
      return {
        components: "",
        dialog_width: "",
        tab: 0,
        company_component: [
          {
            tab_name:'Cheque Settings',
            components: AccountsPayableComponent,
            status_text: "is_active",
            dialog: AccountsPayableViewComponent,
            dialog_width: "40%",
          },
        ],
      };
    },
    mounted() {
      this.tabs(0);
      this.$store.dispatch('getPVPrintTemplate');
      this.$store.dispatch('getAllSelectUsers');
      this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
    },
    methods: {
      tabs(key) {
        this.components = this.company_component[key].dialog;
        this.dialog_width = this.company_component[key].dialog_width;
        this.$store.commit('ACTIVE_TAB',this.company_component[key].status_text)
      },
    },
  };
  </script>
  