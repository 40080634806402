Add/Edit Customer Payment Detail Component


<template>
    <v-container>
        <v-row class="ma-0 pa-0">
            <v-col v-if="origin == 'viewPayment'">
                <v-btn color="primary" @click="custPaymentDetailShowAddEditDialog(); replaceActive()">
                    Replace
                </v-btn>
            </v-col>
            <v-col v-if="origin == 'editSoa' || perPL" :class="!!perPL ? 'd-flex justify-content-center align-items-center' : ''">
                <!-- <v-btn :disabled="hasRemaining" @click="custPaymentDetailShowAddEditDialog()"> -->
                <v-btn @click="custPaymentDetailShowAddEditDialog()">
                    <v-icon>mdi-plus</v-icon>
                    <span></span>
                    Add Payment
                </v-btn>

            </v-col>

            <v-col v-if="perPL == false" class="d-flex justify-content-end">
                <v-btn @click="exportPayments(statuses, customer, paymentType)">
                    <span>Export </span>
                </v-btn>
            </v-col>
        </v-row>

        <v-dialog v-model="addEditCustPaymentDialog" scrollable persistent max-width="65%" retain-focus>
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">{{ `${dialogAction == 'edit' ? 'Edit' : 'Add'} Customer Payment Detail` }}</span>
                        </v-card-title>
                    </v-col>

                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="custPaymentDetailCloseAddEditDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider class="mt-0" />

                <v-card-text class="px-4 pb-2">
                    <!-- <v-row :class="hide_upload?'pb-2 p-2 d-flex align-items-center':'pb-2 p-2 d-none align-items-center'"> -->
                    <v-row class="pb-2 p-2 d-flex align-items-center">
                        <v-col dense cols="5" class="text-left">
                            <div style="display: flex; float:left;" class="pa-2">
                                <FileUploadComponentVue ref="fileUpload" @uploadedData="uploadedData" @reset="clearFileUpload"/>
                                <v-divider class='mx-2' vertical></v-divider>
                                <BaseCameraCapture ref="cameraCapture" @capturedImages="getImages"></BaseCameraCapture>
                                <!-- <v-btn class="mx-2" v-if="repairFormId"
                                    @click="redirect('/service/inquiry?rf_id=' + repairFormId)">
                                    View Repair Form
                                </v-btn> -->
                                <BaseFilesViewerVue class="mt-1 mx-2" :customerPaymentDetailId="customerPaymentDetailId" :delete="true" @openFilesViewer="openFilesViewer" @closeFilesViewer="closeFilesViewer"/>
                            </div>
                        </v-col>
                        <!-- <div style="display:inline-block" class="pr-2">
                            Upload:
                        </div>
                        <div style="display:inline-block">

                        </div>

                        <div style="display:inline-block" class="ml-2">

                        </div> -->
                    </v-row>
                    <v-layout row class="mx-0">
                        <v-flex lg6 class="pr-2">
                            <v-card class="pa-2 mb-2">
                                <div class="pb-2" v-if="dialogAction == 'edit'">
                                    <v-text-field
                                        v-model="referenceNumber"
                                        label="Reference#"
                                        disabled
                                        outlined
                                        dense
                                    >
                                    </v-text-field>
                                </div>
                                <div class="pb-2">
                                    <div>
                                        <v-dialog
                                            ref="paymentDateDialog"
                                            v-model="paymentDateModal"
                                            :return-value.sync="paymentDate"
                                            persistent
                                            width="300px"
                                        >

                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="paymentDate"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    :disabled="isChequeDateEditOnly"
                                                    :rules="rules.default"
                                                    class="hide-text-field-details"
                                                    readonly
                                                    clearable
                                                    dense
                                                    outlined
                                                >
                                                    <template v-slot:label>
                                                        <label>Payment Date: <span class="red--text">*</span></label>
                                                    </template>
                                                </v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="paymentDate"
                                                scrollable
                                                :max="minDate"
                                                :disabled="isChequeDateEditOnly"
                                            >
                                            <v-spacer />
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="paymentDateModal = false"
                                            >
                                                Cancel
                                            </v-btn>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="$refs.paymentDateDialog.save(paymentDate)"
                                            >
                                                OK
                                            </v-btn>
                                            </v-date-picker>
                                        </v-dialog>
                                    </div>
                                </div>
                                <div class="">
                                    <div>
                                        <v-autocomplete
                                            v-model="selectedCustomer"
                                            :items="customerList"
                                            :disabled="!!fromSoa"
                                            :loading="loading.customer_selection"
                                            :rules="rules.default"
                                            item-text="text"
                                            item-value="value"
                                            auto-select-first
                                            dense
                                            outlined
                                        >
                                            <template v-slot:label>
                                                <label>Customer: <span class="red--text">*</span></label>
                                            </template>
                                        </v-autocomplete>
                                    </div>
                                </div>
                                <v-row class="pa-0 ma-0">
                                    <v-col>
                                        <v-autocomplete
                                            v-model="selectedPaymentType"
                                            :items="paymentTypeLists"
                                            :disabled="isChequeDateEditOnly"
                                            :rules="rules.default"
                                            item-text="name"
                                            item-value="id"
                                            auto-select-first
                                            dense
                                            outlined
                                        >
                                            <template v-slot:label>
                                                <label>Payment Type: <span class="red--text">*</span></label>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col v-if="paymentTypeName == 'Deposit'">
                                        <v-autocomplete
                                            dense
                                            outlined
                                            label="Deposit Type"
                                            :items="[{text:'Cash',value:1},{text:'Cheque',value:2}]"
                                            v-model="selectedDepositType"
                                            :disabled="isChequeDateEditOnly"

                                        />
                                    </v-col>
                                </v-row>
                                <div class="pb-2">
                                    <v-text-field
                                        v-model="acknowledgmentReceipt"
                                        :disabled="isChequeDateEditOnly"
                                        :rules="rules.default"
                                        dense
                                        outlined
                                    >
                                        <template v-slot:label>
                                            <label>Acknowledgement Receipt: <span class="red--text">*</span></label>
                                        </template>
                                    </v-text-field>
                                </div>
                                <div class="pb-2">
                                    <div>
                                        <v-textarea
                                        v-model="remarks"
                                        dense
                                        no-resize
                                        outlined
                                        :disabled="isChequeDateEditOnly"
                                        >
                                            <template v-slot:label>
                                                <label>Remarks: <span class="red--text">*</span></label>
                                            </template>
                                        </v-textarea>
                                    </div>
                                </div>
                            </v-card>

                            <!-- <v-card v-if="paymentTypeName == 'Cash'" class="pa-2">
                                <div style="text-align:end">Total Amount: ₱ {{ totalCashAmount | currency }}</div>
                            </v-card> -->
                        </v-flex>
                        <v-flex lg6>
                            <v-card v-if="paymentTypeName == 'Cash' || selectedDepositType == 1" class="pa-2">
                                <div v-if="selectedDepositType == 1">
                                    <v-autocomplete
                                        v-model="selectedBank"
                                        :items="banks"
                                        :disabled="isChequeDateEditOnly"
                                        :rules="rules.default"
                                        item-text="name"
                                        item-value="id"
                                        auto-select-first
                                        dense
                                        outlined
                                    >
                                        <template v-slot:label>
                                            <label>Bank: <span class="red--text">*</span></label>
                                        </template>
                                    </v-autocomplete>
                                </div>
                                <div>
                                    Cash Denomination Details:
                                </div>
                                <v-divider />
                                <v-layout row class="mx-0">
                                    <v-spacer />
                                    <v-flex lg3>
                                        <div style="text-align:end">
                                            Denomination
                                        </div>
                                    </v-flex>
                                    <v-flex lg1>
                                    </v-flex>
                                    <v-flex lg2>
                                        <div style="text-align:end">
                                            Quantity
                                        </div>
                                    </v-flex>
                                    <v-flex lg1>
                                    </v-flex>
                                    <v-flex lg3>
                                        <div style="text-align:end">
                                            Amount
                                        </div>
                                    </v-flex>
                                </v-layout>
                                <v-layout v-for="(cashDenomination, index) in cashDenominations" row :key="cashDenomination.id" class="mx-0 py-2">
                                    <v-spacer />
                                    <v-flex lg3>
                                        <div style="text-align:end" class="mt-2">
                                            {{ `${cashDenomination.value}` }}
                                        </div>
                                    </v-flex>
                                    <v-flex lg1>
                                        <div style="text-align:center" class="mt-2">
                                            x
                                        </div>
                                    </v-flex>
                                    <v-flex lg2>
                                        <div style="text-align:end">
                                            <v-text-field
                                                :ref="`c${index}`"
                                                type="number"
                                                dense
                                                outlined
                                                :min="0"
                                                v-model="cashDenomination.quantity"
                                                @change="getDenominationAmount(cashDenomination)"
                                                @focus="$event.target.select()"
                                            />
                                        </div>
                                    </v-flex>
                                    <v-flex lg1>
                                        <div style="text-align:center" class="mt-2">
                                            =
                                        </div>
                                    </v-flex>
                                    <v-flex lg3 class="mr-2">
                                        <div style="text-align:end" class="mt-2">
                                            {{ `${parseFloat(cashDenomination.value) * cashDenomination.quantity}` | currency }}
                                        </div>
                                    </v-flex>
                                </v-layout>

                                <v-divider />

                                <div style="text-align:end" class="pa-2">Amount: ₱ <span>{{ totalAmount | currency }}</span></div>
                            </v-card>

                            <v-card v-else-if="paymentTypeName != 'Cash' && !!selectedPaymentType && (paymentTypeName != 'Online' && paymentTypeName != 'Gcash') && paymentTypeName != 'Credit/Debit Card' || (paymentTypeName == 'Deposit' && selectedDepositType == 2)" class="pa-2">
                                <div>
                                    {{ `${['Cheque', 'Online', 'Gcash', 'Journal Voucher'].includes(paymentTypeName) ? paymentTypeName : '' } Details:` }}
                                </div>
                                <v-divider />

                                <div v-if="!['Cash', 'Journal Voucher'].includes(paymentTypeName)" class="pb-2">

                                    <div>
                                        <v-autocomplete
                                            dense
                                            outlined
                                            label="Bank: *"
                                            :items="banks"
                                            v-model="selectedBank"
                                            item-value="id"
                                            item-text="name"
                                            :disabled="isChequeDateEditOnly"
                                            auto-select-first
                                        >
                                            <template v-slot:label>
                                                <label>Bank: <span class="red--text">*</span></label>
                                            </template>
                                        </v-autocomplete>
                                    </div>
                                </div>

                                <div class="pb-2">
                                    <div>
                                        <v-text-field
                                            v-model="accountNumber"
                                            :disabled="isChequeDateEditOnly"
                                            :rules="rules.default"
                                            dense
                                            outlined
                                        >
                                            <template v-slot:label>
                                                <label v-if="['Journal Voucher'].includes(paymentTypeName)">APV#</label>
                                                <label v-else>Cheque/Account number <span class="red--text">*</span></label>
                                            </template>
                                        </v-text-field>
                                    </div>
                                </div>

                                <div class="pb-2">
                                    <v-layout row class="mx-0 my-1">
                                        <v-flex lg7 class="pr-2" v-if="!['Journal Voucher'].includes(paymentTypeName)">
                                            <div>
                                                <v-text-field
                                                    v-model="accountName"
                                                    dense
                                                    outlined
                                                    :disabled="isChequeDateEditOnly"
                                                >
                                                    <template v-slot:label>
                                                        <label>Cheque/Account Name <span class="red--text">*</span></label>
                                                    </template>
                                                </v-text-field>
                                            </div>
                                        </v-flex>
                                        <v-flex :lg5="!['Journal Voucher'].includes(paymentTypeName)">
                                            <div>
                                            </div>
                                            <div>
                                                <v-dialog
                                                    ref="accountDateDialog"
                                                    v-model="accountDateModal"
                                                    :return-value.sync="accountDate"
                                                    persistent
                                                    width="290px"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            v-model="accountDate"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            :rules="rules.default"
                                                            class="hide-text-field-details"
                                                            append-icon="mdi-calendar-month"
                                                            readonly
                                                            clearable
                                                            dense
                                                            outlined
                                                        >
                                                            <template v-slot:label>
                                                                <label v-if="['Journal Voucher'].includes(paymentTypeName)">APV Date</label>
                                                                <label v-else>Cheque/Account Date <span class="red--text">*</span></label>
                                                            </template>
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="accountDate"
                                                        scrollable
                                                    >
                                                        <v-spacer />
                                                        <v-btn
                                                            text
                                                            color="primary"
                                                            @click="accountDateModal = false"
                                                        >
                                                            Cancel
                                                        </v-btn>
                                                        <v-btn
                                                            text
                                                            color="primary"
                                                            @click="$refs.accountDateDialog.save(accountDate)"
                                                        >
                                                            OK
                                                        </v-btn>
                                                    </v-date-picker>
                                                </v-dialog>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </div>

                                <div class="pb-2" v-if="!['Journal Voucher'].includes(paymentTypeName)">
                                    <div>
                                        <v-textarea
                                        v-model="accountDescription"
                                        dense
                                        no-resize
                                        label="Description"
                                        outlined
                                        :disabled="isChequeDateEditOnly" />
                                    </div>
                                </div>

                                <div class="pb-2" style="text-align:end">
                                    <div style="display:inline-block">
                                        <v-text-field
                                            type="text"
                                            :min="0"
                                            v-model="totalAmount"
                                            dense
                                            outlined
                                            style="
                                                text-align:right;
                                                padding:0 5px !important;
                                            "
                                            @keypress="decimalOnly"
                                            @keypress.enter="getTotalAmount()"
                                            @change="addToAllocateSOADefault(totalAmount)"
                                            @focus="$event.target.select()"
                                            :disabled="isChequeDateEditOnly"
                                            :rules="rules.default"
                                        >
                                            <template v-slot:label>
                                                <label><span class="red--text">*</span>Amount: ₱</label>
                                            </template>
                                        </v-text-field>
                                    </div>
                                </div>

                            </v-card>
                            <v-card v-else-if="paymentTypeName == 'Online' || paymentTypeName == 'Gcash'" class="pa-2">
                                <div>
                                    {{ `${paymentTypeName == 'Online' || paymentTypeName == 'Gcash' ? paymentTypeName : '' } Details:` }}
                                </div>
                                <v-divider />
                                <div v-if="paymentTypeName != 'Cash'" class="pb-2">
                                    <div>
                                        <v-autocomplete
                                            dense
                                            outlined
                                            :items="banks"
                                            v-model="selectedBank"
                                            item-value="id"
                                            item-text="name"
                                            :disabled="isChequeDateEditOnly"
                                            auto-select-first
                                        >
                                            <template v-slot:label>
                                                <label>Bank: <span class="red--text">*</span></label>
                                            </template>
                                        </v-autocomplete>
                                    </div>
                                </div>

                                <div class="pb-2">
                                    <div class="mb-1">
                                        <v-text-field
                                            v-model="accountNumber"
                                            dense
                                            outlined
                                            :disabled="isChequeDateEditOnly"
                                        >
                                            <template v-slot:label>
                                                <label>Account number <span class="red--text">*</span></label>
                                            </template>
                                        </v-text-field>
                                    </div>
                                    <div>
                                        <v-text-field
                                            v-model="transactionNumber"
                                            :disabled="isChequeDateEditOnly"
                                            :rules="rules.default"
                                            dense
                                            outlined
                                        >
                                            <template v-slot:label>
                                                <label>Transaction Number <span class="red--text">*</span></label>
                                            </template>
                                        </v-text-field>
                                    </div>
                                </div>

                                <div class="pb-2">
                                    <v-layout row class="mx-0 my-1">
                                        <v-flex lg5>
                                            <div>
                                            </div>
                                            <div>
                                                <v-dialog
                                                    ref="accountDateDialog"
                                                    v-model="accountDateModal"
                                                    :return-value.sync="accountDate"
                                                    persistent
                                                    width="290px"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="online_date"
                                                        readonly
                                                        dense
                                                        outlined
                                                        class="hide-text-field-details"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        clearable
                                                        :disabled="isChequeDateEditOnly"
                                                    >
                                                        <template v-slot:label>
                                                            <label>Date <span class="red--text">*</span></label>
                                                        </template>
                                                    </v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="online_date"
                                                        scrollable
                                                        :disabled="isChequeDateEditOnly"
                                                    >
                                                    <v-spacer />
                                                    <v-btn
                                                        text
                                                        color="primary"
                                                        @click="accountDateModal = false"
                                                    >
                                                        Cancel
                                                    </v-btn>
                                                    <v-btn
                                                        text
                                                        color="primary"
                                                        @click="$refs.accountDateDialog.save(accountDate)"
                                                    >
                                                        OK
                                                    </v-btn>
                                                    </v-date-picker>
                                                </v-dialog>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </div>

                                <div class="pb-2">
                                    <div>
                                        <v-textarea
                                        v-model="OnlineDescription"
                                        dense
                                        no-resize
                                        label="Description"
                                        outlined
                                        :disabled="isChequeDateEditOnly"/>
                                    </div>
                                </div>

                                <div class="pb-2" style="text-align:end">
                                    <div style="display:inline-block">
                                        <v-text-field
                                            type="text"
                                            :min="0"
                                            v-model="totalAmount"
                                            dense
                                            outlined
                                            style="
                                                text-align:right;
                                                padding:0 5px !important;
                                            "
                                            @keypress="decimalOnly"
                                            @keypress.enter="getTotalAmount()"
                                            @change="addToAllocateSOADefault(totalAmount)"
                                            @focus="$event.target.select()"
                                            :disabled="isChequeDateEditOnly"
                                        >
                                            <template v-slot:label>
                                                <label><span class="red--text">*</span>Amount: ₱</label>
                                            </template>
                                        </v-text-field>
                                    </div>
                                </div>
                            </v-card>

                            <v-card v-else-if="paymentTypeName == 'Credit/Debit Card'" class="pa-2">
                                <div>
                                    {{ `${paymentTypeName == 'Credit/Debit Card' ? paymentTypeName : '' } Details:` }}
                                </div>
                                <v-divider />
                                <div class="pb-2">
                                    <div class="mb-1">
                                        <v-autocomplete
                                            dense
                                            outlined
                                            :items="banks"
                                            v-model="selectedBank"
                                            item-value="id"
                                            item-text="name"
                                            :disabled="isChequeDateEditOnly"
                                            auto-select-first
                                        >
                                            <template v-slot:label>
                                                <label>Bank: <span class="red--text">*</span></label>
                                            </template>
                                        </v-autocomplete>
                                    </div>
                                    <div>
                                        <v-text-field
                                            v-model="card_name"
                                            :disabled="isChequeDateEditOnly"
                                            :rules="rules.default"
                                            dense
                                            outlined
                                        >
                                            <template v-slot:label>
                                                <label>Card Name <span class="red--text">*</span></label>
                                            </template>
                                        </v-text-field>
                                    </div>
                                </div>
                                <div class="pb-2">
                                    <div>
                                        <v-text-field
                                            v-model="card_number"
                                            :disabled="isChequeDateEditOnly"
                                            :rules="rules.default"
                                            dense
                                            outlined
                                        >
                                            <template v-slot:label>
                                                <label>Card Number <span class="red--text">*</span></label>
                                            </template>
                                        </v-text-field>
                                    </div>
                                </div>
                                <div class="pb-2">
                                    <div>
                                        <v-text-field
                                            v-model="approval_code"
                                            :disabled="isChequeDateEditOnly"
                                            :rules="rules.default"
                                            dense
                                            outlined
                                        >
                                            <template v-slot:label>
                                                <label>Approval Code <span class="red--text">*</span></label>
                                            </template>
                                        </v-text-field>
                                    </div>
                                </div>
                                <div class="pb-2">
                                    <v-layout row class="mx-0 my-1">
                                        <v-flex lg5>
                                            <div>
                                            </div>
                                            <div>
                                                <v-dialog
                                                    ref="accountDateDialog"
                                                    v-model="accountDateModal"
                                                    :return-value.sync="accountDate"
                                                    persistent
                                                    width="290px"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="card_date"
                                                        readonly
                                                        dense
                                                        outlined
                                                        class="hide-text-field-details"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        clearable
                                                        :disabled="isChequeDateEditOnly"
                                                    >
                                                        <template v-slot:label>
                                                            <label>Date <span class="red--text">*</span></label>
                                                        </template>
                                                    </v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="card_date"
                                                        scrollable
                                                        :disabled="isChequeDateEditOnly"
                                                    >
                                                    <v-spacer />
                                                    <v-btn
                                                        text
                                                        color="primary"
                                                        @click="accountDateModal = false"
                                                    >
                                                        Cancel
                                                    </v-btn>
                                                    <v-btn
                                                        text
                                                        color="primary"
                                                        @click="$refs.accountDateDialog.save(accountDate)"
                                                    >
                                                        OK
                                                    </v-btn>
                                                    </v-date-picker>
                                                </v-dialog>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </div>

                                <div class="pb-2" style="text-align:end">
                                    <div style="display:inline-block">
                                        <v-text-field
                                            type="text"
                                            :min="0"
                                            v-model="totalAmount"
                                            dense
                                            outlined
                                            style="
                                                text-align:right;
                                                padding:0 5px !important;
                                            "
                                            @keypress="decimalOnly"
                                            @keypress.enter="getTotalAmount()"
                                            @change="addToAllocateSOADefault(totalAmount)"
                                            @focus="$event.target.select()"
                                            :disabled="isChequeDateEditOnly"
                                        >
                                            <template v-slot:label>
                                                <label><span class="red--text">*</span>Amount: ₱</label>
                                            </template>
                                        </v-text-field>
                                    </div>
                                </div>
                            </v-card>
                        </v-flex>
                    </v-layout>

                    <v-divider />

                    <v-row  class="py-4">
                        <v-col class="ml-4" cols="8">
                            <v-card class="pa-2">
                            <!-- <v-card class="pa-2" v-if="userAccess.includes('mass_allocate_payment_soa')"> -->
                                <v-card-title>
                                    <v-row>
                                        <v-col cols="6">
                                            <h6>Allocate To Which SOA: </h6>
                                        </v-col>
                                        <v-spacer></v-spacer>
                                        <v-col cols="2" class="text-right" v-if="!fromSoa">
                                            <v-btn
                                                @click="addSoaToAllocate()"
                                                :disabled="totalAmount == 0 || (totalToAllocate() == convertTextToNumber(totalAmount).toFixed(2)) ? true : false"
                                                style="height: 20px; width: 20px"
                                                color="primary"
                                                small
                                                fab
                                            >
                                                <span>
                                                    <v-icon small style="font-size: 12px">mdi-plus</v-icon>
                                                </span>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-title>
                                <!-- <span class="float-right">

                                </span> -->
                                <v-data-table
                                    :headers="paymentAllocationsHeader"
                                    :items="toAllocateSoa"
                                    :loading="loading.confirmationSoa"
                                    disable-sort
                                >
                                    <template v-slot:[`item.soa_id`]="{ item }">
                                        <span class="text-nowrap">
                                            <v-autocomplete
                                                v-if="item.confirmed == 0"
                                                v-model="item.soa_id"
                                                :items="GET_ALL_STATE.statement_of_account_selection"
                                                :disabled="loading.soa_selection || item.confirmed == 1 ? true: false"
                                                :loading="loading.soa_selection"
                                                :item-disabled="checkSoaSelected"
                                                @change="setBalanceAmount(item, 'change')"
                                                item-text="soa_with_balance"
                                                item-value="id"
                                                auto-select-first
                                                outlined
                                                dense
                                            ></v-autocomplete>
                                            <span v-else>
                                                {{ GET_ALL_STATE.statement_of_account_selection.find(e=> e.id == item.soa_id)?.soa_with_balance || ''  }}
                                            </span>
                                        </span>
                                    </template>
                                    <template v-slot:[`item.allocated_amount`]="{ item }">
                                        <span class="text-nowrap">
                                            <v-text-field
                                                v-if="item.confirmed == 0"
                                                v-model.trim="item.allocated_amount"
                                                @blur="formatAllocate(item)"
                                                :disabled="item.confirmed == 1"
                                                class="right-align-text"
                                                outlined
                                                dense
                                            >
                                                <template v-slot:prepend-inner>
                                                    <div class="mt-1">
                                                        &#x20b1;
                                                    </div>
                                                </template>
                                            </v-text-field>
                                            <span v-else>
                                                {{ item.allocated_amount }}
                                            </span>
                                        </span>
                                    </template>
                                    <template v-slot:[`item.balance_amount`]="{ item }">
                                        <span class="text-nowrap">
                                                &#x20b1; {{ item.balance_amount}}
                                        </span>
                                    </template>
                                    <template v-slot:[`item.remaining_amount`]="{ item }">
                                        <span class="text-nowrap">
                                            &#x20b1; {{ item.remaining_amount}}
                                        </span>
                                    </template>
                                    <template v-slot:[`item.action`]="{ item, index }">
                                        <span class="text-nowrap">
                                            <v-col cols="2" class="text-right">
                                                <v-btn
                                                    v-if="item.confirmed == 0"
                                                    @click="removeAllocateSoa(item, index)"
                                                    :disabled="toAllocateSoa.length == 0 || item.confirmed == 1 ? true : false"
                                                    color="red"
                                                    small
                                                    text
                                                    icon
                                                >
                                                    <v-icon small>mdi-delete</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </span>
                                    </template>
                                    <template v-slot:footer.prepend>
                                        <span>
                                            <h6>Total Allocated Amount: &#x20b1; {{thousandSeprator(totalToAllocate())}} </h6>
                                        </span>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </v-col>
                        <v-spacer></v-spacer>
                        <div style="text-align:end" class="py-4 d-flex flex-column align-content-end mr-2 align-items-end">
                            <div class="pb-2 float-right">
                                <v-text-field
                                    type="text"
                                    :min="0"
                                    :max="totalAmount"
                                    v-model="whtAmount"
                                    dense
                                    outlined
                                    label="WHT Amount: ₱"
                                    style="
                                        width: 175px;
                                        padding:0 5px !important;
                                    "
                                    :disabled="totalAmount == 0"
                                    @keypress="decimalOnly"
                                    @keypress.enter="getWhtAmount()"
                                    @focus="$event.target.select()"
                                    @change="formatAmount(whtAmount, 'whtAmount')"
                                />
                            </div>
                            <div class="pb-2 float-right">
                                <v-text-field
                                    type="text"
                                    :min="0"
                                    :max="totalAmount"
                                    v-model="otherAdjAmount"
                                    dense
                                    outlined
                                    label="Other Adj Amount: ₱"
                                    style="
                                        text-align:right;
                                        width: 175px;
                                        padding:0 5px !important;
                                    "
                                    :disabled="totalAmount == 0"
                                    @keypress="decimalOnly"
                                    @keypress.enter="getOtherAdjAmount()"
                                    @focus="$event.target.select()"
                                    @change="formatAmount(otherAdjAmount, 'otherAdjAmount')"
                                />
                            </div>
                            <div class="pb-2" v-if="fromSoa">
                                Balance Amount: ₱ {{ balanceAmounts | currency}}
                            </div>
                            <div>
                                Net Amount: ₱ {{ netAmount | currency }}
                            </div>
                            <div>
                                Remaining Amount For Allocation: ₱ {{ remainingAmountForAllocation() | currency }}
                            </div>
                        </div>
                    </v-row>
                </v-card-text>

                <v-divider class="mt-0" />

                <div style="text-align:center" class="pb-4" >
                    <v-btn v-if="dialogAction == 'edit'" @click="update_override.dialog = true">Update</v-btn>
                    <v-btn v-else :loading="submitOnFlight" @click="submit()">Submit</v-btn>
                </div>
            </v-card>
        </v-dialog>
        <v-dialog v-model="replaceCheckDialog" width="25%" persistent>
            <v-card>
                <v-row class="m-0">
                    <v-col cols="10">
                        <v-card-title>
                            <span class="headline">Replace Reason</span>
                        </v-card-title>
                    </v-col>
                </v-row>

                <v-card-text>
                    <v-row class="mt-2">
                        <v-col cols="12">
                            <v-textarea
                            rows="2"
                            dense
                            outlined
                            v-model="replaceCheck.reason"
                            label="Reason"
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="d-flex justify-end">
                    <v-btn @click="submitReplaceCheck(1)">
                        Submit
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="moveDateDialog" width="25%" persistent>
            <v-card>
                <v-row class="m-0">
                    <v-col cols="10">
                        <v-card-title>
                            <span class="headline">Move Date Reason</span>
                        </v-card-title>
                    </v-col>
                </v-row>
                <v-card-text>
                    <v-row class="mt-2">
                        <v-col cols="12">
                            <v-textarea
                            rows="2"
                            dense
                            outlined
                            v-model="move_date_reason"
                            label="Reason"
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="d-flex justify-end">
                    <v-btn @click="moveChequeDate(1)">
                        Submit
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <ConfirmOverrideComponent
            :approve_override_dialog="update_override.dialog"
            :departments_allowed="update_override.departments_allowed"
            :heading="update_override.heading"
            @closeConfirmOverride="updatePaymentConfirmOverride"
        ></ConfirmOverrideComponent>
    </v-container>
</template>

<script>
import ConfirmOverrideComponent from '@/views/main/Utils/ConfirmOverrideComponent.vue';
import FileUploadComponentVue from '../../../../Shared/FileUploadComponent.vue'
import BaseFilesViewerVue from '../../Base/BaseFilesViewer.vue';
import BaseCameraCapture from '../../../Services/Base/BaseCameraCapture.vue';
import swal from 'sweetalert2';
import SharedFunctionsComponent from "@/views/main/Shared/SharedFunctionsComponent.vue";
import { mapGetters } from 'vuex'
export default {
    mixins:[SharedFunctionsComponent],
    data() {
        return {
            customerList: [],
            selectedCustomer: '',
            paymentTypeLists: [],
            banks: [],
            cashDenominations: [],
            submitOnFlight: false,
            updateOnFlight: false,
            uploadedFiles: null,
            minDate: this.$dayjs().format('YYYY-MM-DD'),

            accountDateModal: false,
            selectedPaymentType: '',
            selectedBank: '',
            selectedChequeBank: '',
            paymentTypeName: '',
            remarks: '',
            chequeAccountNumber: '',

            chequeName: '',
            chequeDescription: '',
            totalAmount: 0,
            chequeAmount: 0,
            chequeDate: '',
            customerPaymentDetail: null,

            whtAmount: 0,
            otherAdjAmount: 0,

            paymentDateModal: false,
            paymentDate: '',

            accountNumber: '',
            accountName: '',
            accountDate: '',
            accountDescription: '',

            online_date:'',
            transactionNumber:'',
            OnlineDescription:'',
            userAccess: [],

            hide_upload:false,
            loading:{
                customer_selection          : true,
                payment_type_selection      : true,
                banks_selection             : true,
                soa_selection               : false,
                confirmationSoa             : false,
            },
            expectedDepositDate: '',
            card_name:'',
            card_number:'',
            approval_code:'',
            card_date:'',
            images:null,
            generated_soa_id:'',
            replaceCheckDialog:false,
            replaceCheck:{
                date:'',
                date_dialog:false,
                reason:''
            },
            new_cpd_id:null,
            moveDateDialog:false,
            move_date_reason:-1,
            selectedDepositType:0,
            acknowledgmentReceipt: '',
            update_override: {
                dialog: false,
                heading: 'Update Payment',
                departments_allowed: ['AC', 'EX', 'IT'],
                authenticated: false,
            },
            tableHeaders: [
                { text: 'Reference#', value: 'reference_number' },
                { text: 'Cheque Account#', value: 'cheque_account_number' },
                { text: 'Date', value: 'payment_date' },
                { text: 'Customer Name', value: 'customer_name' },
                { text: 'Payment Type', value: 'payment_type_name' },
                { text: 'Bank', value: 'bank_name' },
                { text: 'Gross Amount', align: 'end', value: 'gross_amount', style: { numFmt: '#,##0.00' } },
                // { text: 'WHT Amount', align: 'end', value: 'wht_amount' },
                // { text: 'ADJ Amount', align: 'end', value: 'other_adj_amount' },
                { text: 'Net Amount', align: 'end', value: 'net_amount', style: { numFmt: '#,##0.00' } },
                { text: 'Remaining Amount', align: 'end', value: 'remaining_amount', style: { numFmt: '#,##0.00' } },
                { text: 'Status', align: 'center', value: 'status' },
                { text: 'Cheque Date', value: 'cheque_date' },
                { text: 'Created By', value: 'created_by' },
            ],
            paymentAllocationsHeader: [
                { text: 'SOA ID', value: 'soa_id' },
                { text: 'Balance Amount', align:'end', value: 'balance_amount' },
                { text: 'Allocated Amount', align:'end', value: 'allocated_amount' },
                { text: 'Remaining Amount', align:'end', value: 'remaining_amount' },
                { text: 'Action', align:'end', value: 'action' },
            ],
            toAllocateSoa: [],
            referenceNumber: '',
            customer_payment_allocation_trash_ids: [],
            rules: {
                default: [
                v => !!v || 'This is required',
                ],
            },
        }
    },
    async mounted() {
        await this.getPaymentTypes();
        await this.getAllCustomer();
        this.checkAccess();
    },
    components: {
        FileUploadComponentVue,
        BaseFilesViewerVue,
        BaseCameraCapture,
        ConfirmOverrideComponent
    },
    props: ['addEdit', 'customerId', 'fromSoa','balanceAmounts','soa_id','origin','hasRemaining','perPL','soa_payload','cpd_id', 'statuses', 'customer','paymentType'],
    computed: {
        ...mapGetters([
            'GET_PAYMENT_TYPES',
            'GET_BANKS',
            'GET_CASH_DENOMINATIONS',
            'GET_CUSTOMER_PAYMENT_DETAIL',
            'GET_CUSTOMERS_LIST_SELECTION',
            'USER_ACCESS',
            'GET_ALL_STATE',
            'USERACCOUNT_LOAD',
        ]),
        checkPaymentType() {
            if(this.paymentTypeName != 'Cash' && this.paymentTypeName != 'Credit/Debit Card' && this.selectedDepositType == 2) {
                return true;
            }

            return false;
        },
        requireCheque() {
            if(this.paymentTypeName == 'Cheque' || this.selectedDepositType == 2) {
                return true;
            }

            return false;
        },
        requireOnline(){
            if(this.paymentTypeName == 'Online' || this.paymentTypeName == 'Gcash') {
                return true;
            }

            return false;
        },
        requireCustomer(){
            if(!!this.fromSoa) {
                return true;
            }
            // this.selectedCustomer = 0;
            return false;
        },
        requireCard(){
            if(this.paymentTypeName == 'Credit/Debit Card') {
                return true;
            }

            return false;
        },
        addEditCustPaymentDialog() {
            this.hide_upload = false;
            if(this.dialogAction == 'edit'){
                return this.$store.state.accounting.customerPaymentDetail.addEditCustPaymentDialog;
            }
            if(this.dialogAction == 'add'){
                return this.$store.state.accounting.customerPaymentDetail.addEditCustPaymentDialog;
            }
        },
        dialogAction() {
            return this.$store.state.accounting.customerPaymentDetail.dialogAction;
        },
        customerPaymentDetailId() {
            return this.$store.state.accounting.customerPaymentDetail.customerPaymentDetailId;
        },
        grossAmount() {
            if(this.convertTextToNumber(this.totalAmount) > 0) {
                let amount = this.convertTextToNumber(this.totalAmount) + this.convertTextToNumber(this.whtAmount);

                return this.getTwoDecimals(amount);
            }

            return this.getTwoDecimals(0);
        },
        netAmount() {
            if(this.convertTextToNumber(this.totalAmount) > 0) {
                let amount = this.convertTextToNumber(this.grossAmount) - this.convertTextToNumber(this.whtAmount) - this.convertTextToNumber(this.otherAdjAmount);

                return this.getTwoDecimals(amount);
            }

            return this.getTwoDecimals(0);
        },
        isChequeDateEditOnly(){
            return this.$store.state.accounting.custPaymentDetailEditChequeDate;
        },
        isReplaceCheque(){
            return this.$store.state.accounting.custPaymentDetailReplaceCheque;
        }
    },
    watch: {
        selectedPaymentType() {
            if(!!this.selectedPaymentType) {

                let paymentType = this.paymentTypeLists.find(x=>x.id == this.selectedPaymentType);

                this.paymentTypeName = paymentType.name;

                let payload = {
                    payment_type_id: this.selectedPaymentType,
                    url: `getBanksByPaymentType`
                }
                this.$store.dispatch('banksGet',payload).then(response=>{
                    this.banks = response.data;
                });

            }
        },
        paymentTypeName() {
            if(this.paymentTypeName == 'Cash') {
                this.hide_upload = true;
                if(this.dialogAction == 'add') {
                    this.getCashDenominations();
                }
            }
            else if(this.paymentTypeName == 'Credit/Debit Card'){
                if(this.dialogAction == 'add') {
                    this.hide_upload = true;
                }
            }
            else {
                if(this.dialogAction == 'add') {
                    this.hide_upload = true;
                    this.clearCashDenominations();
                }
            }
        },
        selectedDepositType(){
            if(this.paymentTypeName == 'Deposit' && this.selectedDepositType == 1){
                if(this.dialogAction == 'add') {
                    this.hide_upload = false;
                    this.getCashDenominations();
                }
            }
            else {
                if(this.dialogAction == 'add') {
                    this.hide_upload = true;
                    this.clearCashDenominations();
                }
            }
        },
        customerPaymentDetail() {
            if(!!this.customerPaymentDetail) {
                const {
                    payment_date,
                    customer_id,
                    payment_type_id,
                    remarks,
                    cash_denomination_breakdown,
                    bank_id,
                    cheque_account_number,
                    cheque_name,
                    cheque_date,
                    cheque_description,
                    account_number,
                    account_name,
                    account_date,
                    account_description,
                    total_amount,
                    wht_amount,
                    other_adj_amount,
                    acknowledgment_receipt,
                    reference_number,
                } = this.customerPaymentDetail;
                this.paymentDate = payment_date;
                this.referenceNumber = reference_number;
                this.selectedCustomer = customer_id;
                this.selectedPaymentType = payment_type_id;
                this.cashDenominations = JSON.parse(cash_denomination_breakdown);
                this.selectedBank = bank_id;
                this.acknowledgmentReceipt = acknowledgment_receipt;
                let paymentType = this.paymentTypeLists.find(x=>x.id == payment_type_id);

                let paymentTypeName = !!paymentType ? paymentType.name : '';

                if(!!paymentTypeName) {
                    if(paymentTypeName == 'Cheque') {
                        this.accountNumber = cheque_account_number;
                        this.accountName = cheque_name;
                        this.accountDate = cheque_date;
                        this.accountDescription = cheque_description;
                    } else if(paymentTypeName != 'Cash' || paymentTypeName != 'Cheque') {
                        this.accountNumber = account_number;
                        this.accountName = account_name;
                        this.accountDate = account_date;
                        this.accountDescription = account_description;
                    }
                }

                this.remarks = remarks;

                this.totalAmount = this.getTwoDecimals(total_amount);
                this.whtAmount = this.getTwoDecimals(wht_amount);
                this.otherAdjAmount = this.getTwoDecimals(other_adj_amount);
            }
        },
        dialogAction() {
            if(this.dialogAction == 'edit' && !!this.customerPaymentDetailId) {
                this.getCustomerPaymentDetail();
            }
        },
        'GET_CUSTOMERS_LIST_SELECTION':{
            handler(val){
                this.loading.customer_selection = false
            }
        },
        'GET_PAYMENT_TYPES':{
            handler(val){
                this.loading.payment_type_selection = false
            }
        },
        'GET_BANKS':{
            handler(val){
                this.loading.banks_selection = false
            }
        },
        accountDate:{
            handler(val){
                let dt = new Date(val); // get date
                let business_days = 2;

                let newDate = this.addBusinessDays(dt, business_days);
                let month = '' + (newDate.getMonth() + 1)
                let days = '' + newDate.getDate()
                let years = newDate.getFullYear()

                let formattedNewDate = [years,month,days].join('-');
                this.expectedDepositDate = formattedNewDate
            }
        },
        selectedCustomer:{
            handler(val){
                if(val == 275){
                    let end_user_payments_list = []
                    this.paymentTypeLists.forEach(e=>{
                        if(e.id == 2){
                        }else{
                            end_user_payments_list.push(e)
                        }
                    })
                    this.paymentTypeLists = end_user_payments_list
                }
                this.getSoaSelection();

                if (this.origin != 'viewPayment') {
                    this.getToAllocateSoa();
                }
            }
        },
    },
    methods: {
        clearFields() {
            this.selectedPaymentType = '';
            this.paymentTypeName = '';
            this.remarks = '';
            this.submitOnFlight = false;
            this.chequeName = '';
            this.chequeDate = '';
            this.selectedChequeBank = '';
            this.chequeAccountNumber = '';
            this.chequeDescription = '';
            this.chequeAmount = 0;
            this.totalAmount = 0;
            this.uploadedFiles = null;
            this.selectedCustomer = '';
            this.selectedBank = '';
            this.otherAdjAmount = 0;
            this.whtAmount = 0;
            this.paymentDate = '';
            this.chequeNumber = '';
            this.acknowledgmentReceipt = '';
            this.accountNumber = '';
            this.accountName = '';
            this.accountDate = '';
            this.accountDescription = '';

            this.$store.commit('custPaymentDetailClearDialogAction');
            this.$store.commit('custPaymentDetailClearId');

            this.generated_soa_id = 0
            this.replaceCheckDialog = false,
            this.replaceCheck = {
                date:'',
                date_dialog:false,
                reason:''
            },
            this.new_cpd_id = null,
            this.moveDateDialog = false,
            this.move_date_reason = -1,
            this.selectedDepositType = 0
            this.referenceNumber = ''
            this.toAllocateSoa = [];
            this.customer_payment_allocation_trash_ids = []
        },
        checkAccess(){
            this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id).then(response=>{
                let val = response;
                this.userAccess = val.map(e=>e.actions_code);
                // this.userAccess.create = false;
                // this.userAccess.edit = false;
                // this.userAccess.delete = false;
                // this.userAccess.view = false;
                // if(val != "fail"){
                //     val.forEach((e) => {
                //         if (e.actions_code == "create"){
                //             this.userAccess.create = true;
                //         }
                //         else if (e.actions_code == "edit"){
                //             this.userAccess.edit = true;
                //         }
                //         else if (e.actions_code == "view"){
                //             this.userAccess.view = true;
                //         }
                //         else if (e.actions_code == "delete"){
                //             this.userAccess.delete = true;
                //         }
                //     });
                // }
            })
        },
        custPaymentDetailShowAddEditDialog() {
            if(this.fromSoa || this.origin == 'viewPayment') {
                this.selectedCustomer = this.customerId;
                if(this.origin == 'viewPayment'){
                    this.$store.commit('custPaymentDetailShowAddEditDialog');
                }
            }
            if (this.origin == 'editSoa' || this.perPL){
                this.$store.commit('custPaymentDetailEditChequeDate',false);
                this.$store.commit('custPaymentReplaceCheque',false);
                this.$store.commit('custPaymentDetailShowAddEditDialog');
            }
            this.toAllocateSoa = [];
        },
        custPaymentDetailCloseAddEditDialog() {
            this.hide_upload = false;
            this.$store.commit('custPaymentDetailCloseAddEditDialog');
            this.clearFields();
            this.clearCashDenominations();
            this.clearCameraCapture();

        },
        async getAllCustomer(){
              await this.$store.dispatch('getAllCustomers').then((response)=>{ this.customerList= this.GET_CUSTOMERS_LIST_SELECTION })
        },
        async getPaymentTypes(){
            await this.$store.dispatch('getPaymentTypes').then(response=>{
                this.paymentTypeLists = this.GET_PAYMENT_TYPES
            })
        },
        getCashDenominations() {
            this.$store.dispatch('getCashDenominations').then(response=>{
                let data = this.GET_CASH_DENOMINATIONS;
                let modified = [];

                if(data.length > 0) {
                    data.forEach(x=>{
                        modified.push(
                            Object.assign({
                                quantity: 0,
                                amount: 0
                            }, x)
                        )
                    });
                }

                this.cashDenominations = modified;
            })
        },
        clearCashDenominations() {
            this.cashDenominations.forEach(x=>{
                x.quantity = 0;
                x.amount = 0;
            });

            this.totalAmount = 0;
        },
        getDenominationAmount(data) {
            let cashDenomination = this.cashDenominations.find(x=>x.id == data.id);
            cashDenomination.amount = parseFloat(data.value) * parseInt(data.quantity);

            let totalAmount = 0
            totalAmount = this.cashDenominations.map(x=>{
                return parseFloat(x.value) * parseInt(x.quantity);
            });
            this.addToAllocateSOADefault(_.sum(totalAmount));
            this.totalAmount = this.getTwoDecimals(_.sum(totalAmount));
        },
        uploadedData(data) {
            if(!data) {
                this.uploadedFiles = null;
            }

            this.uploadedFiles = data;
        },
        clearFileUpload(confirm) {
            if(confirm) {
                this.uploadedFiles = null;
            }
            this.$refs.fileUpload.reset();
        },
        getChequeAmount() {
            this.totalAmount = this.chequeAmount;
        },
        async submit() {
            this.submitOnFlight = true;

            let message = !!this.perPL ? 'Statement Of Account Created' : '';
            if((this.uploadedFiles == null && this.hide_upload && this.images == null) || (this.selectedDepositType == 2 && this.uploadedFiles == null && this.hide_upload && this.images == null)) {
                swal.fire('', 'Please Upload Payment Images', 'warning').then(x=>{
                    this.submitOnFlight = false;
                });
            } else if(!this.requiredFields()) {
                if(this.paymentTypeName == 'Cheque' && !!this.selectedCustomer){
                    swal.fire({
                    title: 'Allocate Cheque?',
                    text: 'Do you want to allocate this cheque in Customers Credit Limit?',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Yes',
                    cancelButtonText:'No',
                    showCloseButton:true,
                    }).then(async (result)=>{
                        if(result.isConfirmed) {
                            if(this.perPL){
                                await this.createStatementOfAccount();
                            }
                            const data = new FormData();
                            const config = {
                                headers: {
                                    'content-type': 'multipart/form-data'
                                }
                            }

                            if(!!this.uploadedFiles && this.uploadedFiles.attachments.length > 0) {
                                let files = this.uploadedFiles.attachments;

                                for(let i=0;i < _.compact(files).length;i++) {
                                    data.append('files[]', files[i]);
                                }
                            }

                            if(!!this.images && this.images.length > 0) {
                                for(let i=0;i < _.compact(this.images).length;i++) {
                                    data.append('images[]', _.compact(this.images)[i]);
                                }
                            }

                            data.append('paymentDate', this.paymentDate);
                            data.append('customerId', this.selectedCustomer);
                            data.append('paymentTypeId', this.selectedPaymentType);
                            data.append('paymentTypeName', this.paymentTypeName);
                            data.append('remarks', this.remarks);
                            data.append('acknowledgmentReceipt', this.acknowledgmentReceipt);

                            data.append('bankId', this.selectedBank)

                            if(this.paymentTypeName == 'Cheque') {
                                data.append('chequeAccountNumber', this.accountNumber);
                                data.append('chequeName', this.accountName);
                                data.append('chequeDate', this.accountDate);
                                data.append('chequeDescription', this.accountDescription);
                            } else if(this.paymentTypeName == 'Deposit') {
                                data.append('accountNumber', this.accountNumber);
                                data.append('accountName', this.accountName);
                                data.append('accountDate', this.accountDate);
                                data.append('accountDescription', this.accountDescription);
                                data.append('deposit_type_id', this.selectedDepositType);
                            }
                            else if(this.paymentTypeName == 'Online' || this.paymentTypeName == 'Gcash') {
                                data.append('accountNumber', this.accountNumber);
                                data.append('transaction_number', this.transactionNumber);
                                data.append('online_date', this.online_date);
                                data.append('OnlineDescription', this.OnlineDescription);
                            }
                            else if(this.paymentTypeName == 'Credit/Debit Card') {
                                data.append('card_name', this.card_name);
                                data.append('card_number', this.card_number);
                                data.append('card_date', this.card_date);
                                data.append('approval_code', this.approval_code);
                            }

                            data.append('cashDenominations', JSON.stringify(this.cashDenominations));
                            data.append('totalAmount', this.convertTextToNumber(this.totalAmount));
                            data.append('grossAmount', this.convertTextToNumber(this.grossAmount));
                            data.append('whtAmount', this.convertTextToNumber(this.whtAmount));
                            data.append('otherAdjAmount', this.convertTextToNumber(this.otherAdjAmount));
                            data.append('netAmount', this.convertTextToNumber(this.totalAmount));
                            // data.append('netAmount', this.convertTextToNumber(this.netAmount));
                            data.append('fromSoa', this.fromSoa);
                            data.append('expected_deposit_date',this.expectedDepositDate);
                            if(this.convertTextToNumber(this.netAmount) > this.convertTextToNumber(this.balanceAmounts)){
                                let limit = this.netAmount - this.getTwoDecimals(this.balanceAmounts);
                                data.append('allocatedAmount', this.convertTextToNumber(String(limit)));
                            }
                            else{
                                data.append('allocatedAmount', this.convertTextToNumber(this.totalAmount));
                            }

                            data.append('deposit', 1);
                            data.append('mode', 'Allocate');
                            data.append('soa_id', this.soa_id);
                            data.append('generated_soa_id',this.generated_soa_id)
                            data.append('is_per_pl',this.perPL);
                            data.append('toAllocateSoa', JSON.stringify(this.toAllocateSoa));

                            let payload = {
                                params:data,
                                config: config
                            }

                            await this.$store.dispatch('createCustomerPaymentDetail',payload).then(response=>{
                                if(response.data.error) {
                                    swal.fire('',response.data.error,'error').then(confirm=>{
                                        this.submitOnFlight = false;
                                    });
                                } else {
                                    this.replaceCheque(response,message);
                                }
                            });

                        }
                        else{
                            swal.fire({
                            title: 'Are you sure you want to Add Customer Payment?',
                            text: 'Make sure that all information are correct',
                            icon: 'question',
                            showConfirmButton:true,
                            showCancelButton  :  true,
                            reverseButtons:true,
                            allowOutsideClick :  false,
                            confirmButtonColor: '#397373',
                            cancelButtonColor : 'grey',
                            confirmButtonText:'Confirm',
                            showCloseButton:true,
                            }).then(async (result)=>{
                                if(result.isConfirmed) {
                                    if(this.perPL){
                                        await this.createStatementOfAccount();
                                    }
                                    const data = new FormData();

                                    const config = {
                                        headers: {
                                            'content-type': 'multipart/form-data'
                                        }
                                    }

                                    if(!!this.uploadedFiles && this.uploadedFiles.attachments.length > 0) {
                                        let files = this.uploadedFiles.attachments;

                                        for(let i=0;i < _.compact(files).length;i++) {
                                            data.append('files[]', files[i]);
                                        }
                                    }

                                    if(!!this.images && this.images.length > 0) {
                                        for(let i=0;i < _.compact(this.images).length;i++) {
                                            data.append('images[]', _.compact(this.images)[i]);
                                        }
                                    }

                                    data.append('paymentDate', this.paymentDate);
                                    data.append('customerId', this.selectedCustomer);
                                    data.append('paymentTypeId', this.selectedPaymentType);
                                    data.append('paymentTypeName', this.paymentTypeName);
                                    data.append('remarks', this.remarks);
                                    data.append('acknowledgmentReceipt', this.acknowledgmentReceipt);

                                    data.append('bankId', this.selectedBank)

                                    if(this.paymentTypeName == 'Cheque') {
                                        data.append('chequeAccountNumber', this.accountNumber);
                                        data.append('chequeName', this.accountName);
                                        data.append('chequeDate', this.accountDate);
                                        data.append('chequeDescription', this.accountDescription);
                                    } else if(this.paymentTypeName == 'Deposit') {
                                        data.append('accountNumber', this.accountNumber);
                                        data.append('accountName', this.accountName);
                                        data.append('accountDate', this.accountDate);
                                        data.append('accountDescription', this.accountDescription);
                                        data.append('deposit_type_id', this.selectedDepositType);
                                    }
                                    else if(this.paymentTypeName == 'Online' || this.paymentTypeName == 'Gcash') {
                                        data.append('accountNumber', this.accountNumber);
                                        data.append('transaction_number', this.transactionNumber);
                                        data.append('online_date', this.online_date);
                                        data.append('OnlineDescription', this.OnlineDescription);
                                    }
                                    else if(this.paymentTypeName == 'Credit/Debit Card') {
                                        data.append('card_name', this.card_name);
                                        data.append('card_number', this.card_number);
                                        data.append('card_date', this.card_date);
                                        data.append('approval_code', this.approval_code);
                                    }

                                    data.append('cashDenominations', JSON.stringify(this.cashDenominations));
                                    data.append('totalAmount', this.convertTextToNumber(this.totalAmount));
                                    data.append('grossAmount', this.convertTextToNumber(this.grossAmount));
                                    data.append('whtAmount', this.convertTextToNumber(this.whtAmount));
                                    data.append('otherAdjAmount', this.convertTextToNumber(this.otherAdjAmount));
                                    // data.append('netAmount', this.convertTextToNumber(this.netAmount));
                                    data.append('netAmount', this.convertTextToNumber(this.totalAmount));
                                    data.append('fromSoa', this.fromSoa);
                                    data.append('expected_deposit_date',this.expectedDepositDate);
                                    // data.append('deposit', 0);
                                    data.append('soa_id', this.soa_id);
                                    data.append('generated_soa_id',this.generated_soa_id)
                                    data.append('is_per_pl',this.perPL);
                                    data.append('toAllocateSoa', JSON.stringify(this.toAllocateSoa));

                                    if(this.convertTextToNumber(this.netAmount) > this.convertTextToNumber(this.balanceAmounts)){
                                        let limit = this.netAmount - this.getTwoDecimals(this.balanceAmounts);
                                        data.append('allocatedAmount', this.convertTextToNumber(String(limit)));
                                    }
                                    else{
                                        data.append('allocatedAmount', this.convertTextToNumber(this.totalAmount));
                                    }
                                    let payload = {
                                        params:data,
                                        config: config
                                    }

                                    await this.$store.dispatch('createCustomerPaymentDetail',payload).then(response=>{
                                        if(response.data.error) {
                                            swal.fire('',response.data.error,'error').then(confirm=>{
                                                this.submitOnFlight = false;
                                            });
                                        } else {
                                            this.replaceCheque(response,message);
                                        }
                                    });
                                }
                                this.submitOnFlight = false;

                            });
                        }
                    })
                }
                else{
                if(!this.requiredFields()) {
                    swal.fire({
                    title: 'Are you sure you want to Add Customer Payment?',
                    text: 'Make sure that all information are correct',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then(async (result)=>{
                    if(result.isConfirmed) {
                        if(this.perPL){
                            await this.createStatementOfAccount();
                        }
                        const data = new FormData();

                        const config = {
                            headers: {
                                'content-type': 'multipart/form-data'
                            }
                        }

                        if(!!this.uploadedFiles && this.uploadedFiles.attachments.length > 0) {
                            let files = this.uploadedFiles.attachments;

                            for(let i=0;i < _.compact(files).length;i++) {
                                data.append('files[]', files[i]);
                            }
                        }

                        if(!!this.images && this.images.length > 0) {
                            for(let i=0;i < _.compact(this.images).length;i++) {
                                data.append('images[]', _.compact(this.images)[i]);
                            }
                        }

                        data.append('paymentDate', this.paymentDate);
                        data.append('customerId', this.selectedCustomer);
                        data.append('paymentTypeId', this.selectedPaymentType);
                        data.append('paymentTypeName', this.paymentTypeName);
                        data.append('remarks', this.remarks);
                        data.append('acknowledgmentReceipt', this.acknowledgmentReceipt);

                        data.append('bankId', this.selectedBank)

                        if(this.paymentTypeName == 'Cheque') {
                            data.append('chequeAccountNumber', this.accountNumber);
                            data.append('chequeName', this.accountName);
                            data.append('chequeDate', this.accountDate);
                            data.append('chequeDescription', this.accountDescription);
                        } else if(this.paymentTypeName == 'Deposit') {
                            data.append('accountNumber', this.accountNumber);
                            data.append('accountName', this.accountName);
                            data.append('accountDate', this.accountDate);
                            data.append('accountDescription', this.accountDescription);
                            data.append('deposit_type_id', this.selectedDepositType);
                        }
                        else if(this.paymentTypeName == 'Online' || this.paymentTypeName == 'Gcash') {
                            data.append('accountNumber', this.accountNumber);
                            data.append('transaction_number', this.transactionNumber);
                            data.append('online_date', this.online_date);
                            data.append('OnlineDescription', this.OnlineDescription);
                        }
                        else if(this.paymentTypeName == 'Credit/Debit Card') {
                            data.append('card_name', this.card_name);
                            data.append('card_number', this.card_number);
                            data.append('card_date', this.card_date);
                            data.append('approval_code', this.approval_code);
                        }

                        data.append('cashDenominations', JSON.stringify(this.cashDenominations));
                        data.append('totalAmount', this.convertTextToNumber(this.totalAmount));
                        data.append('grossAmount', this.convertTextToNumber(this.grossAmount));
                        data.append('whtAmount', this.convertTextToNumber(this.whtAmount));
                        data.append('otherAdjAmount', this.convertTextToNumber(this.otherAdjAmount));
                        data.append('netAmount', this.convertTextToNumber(this.totalAmount));
                        data.append('fromSoa', this.fromSoa);
                        data.append('expected_deposit_date',this.expectedDepositDate);
                        data.append('soa_id', this.soa_id);
                        data.append('generated_soa_id',this.generated_soa_id)
                        data.append('is_per_pl',this.perPL);

                        if(this.convertTextToNumber(this.netAmount) > this.convertTextToNumber(this.balanceAmounts)){
                            let limit = this.netAmount - this.getTwoDecimals(this.balanceAmounts);
                            data.append('allocatedAmount', this.convertTextToNumber(String(limit)));
                        }
                        else{
                            data.append('allocatedAmount', this.convertTextToNumber(this.totalAmount));
                        }
                        data.append('toAllocateSoa', JSON.stringify(this.toAllocateSoa));

                        let payload = {
                            params:data,
                            config: config
                        }

                        await this.$store.dispatch('createCustomerPaymentDetail',payload).then(response=>{
                            if(response.data.error) {
                                swal.fire('',response.data.error,'error').then(confirm=>{
                                    this.submitOnFlight = false;
                                });
                            } else {
                                this.replaceCheque(response,message);
                            }
                        });
                    }
                    this.submitOnFlight = false;

                });
                }
                }
            }
        },
        getCustomerPaymentDetail() {
            this.$store.dispatch('getCustomerPaymentDetail',this.customerPaymentDetailId).then(response=>{
                this.customerPaymentDetail  = this.GET_CUSTOMER_PAYMENT_DETAIL;
                this.selectedDepositType    = this.customerPaymentDetail.deposit_type_id
            });
        },
        update() {
            this.updateOnFlight = true;
            if(this.move_date_reason === -1 && this.isChequeDateEditOnly){
                this.move_date_reason = null
                this.moveDateDialog = true
            }
            else{
                this.moveDateDialog = false
                if(!this.requiredFields()) {
                    swal.fire({
                        title: 'Are you sure you want to Update Customer Payment?',
                        text: 'Make sure that all information are correct',
                        icon: 'question',
                        showConfirmButton:true,
                        showCancelButton  :  true,
                        reverseButtons:true,
                        allowOutsideClick :  false,
                        confirmButtonColor: '#397373',
                        cancelButtonColor : 'grey',
                        confirmButtonText:'Confirm',
                        showCloseButton:true,
                    }).then((result)=>{
                        if(result.isConfirmed) {
                            const data = new FormData();

                            const config = {
                                headers: {
                                    'content-type': 'multipart/form-data'
                                }
                            }

                            if(!!this.uploadedFiles && this.uploadedFiles.attachments.length > 0) {
                                let files = this.uploadedFiles.attachments;

                                for(let i=0;i < _.compact(files).length;i++) {
                                    data.append('files[]', files[i]);
                                }
                            }

                            data.append('customerPaymentDetailId', this.customerPaymentDetailId);

                            data.append('paymentDate', this.paymentDate);
                            data.append('customerId', this.selectedCustomer);
                            data.append('paymentTypeId', this.selectedPaymentType);
                            data.append('paymentTypeName', this.paymentTypeName);
                            data.append('remarks', this.remarks);
                            data.append('acknowledgmentReceipt', this.acknowledgmentReceipt);

                            data.append('bankId', this.selectedBank)

                            if(this.paymentTypeName == 'Cheque') {
                                data.append('chequeAccountNumber', this.accountNumber);
                                data.append('chequeName', this.accountName);
                                data.append('chequeDate', this.accountDate);
                                data.append('chequeDescription', this.accountDescription);
                            } else if(this.paymentTypeName == 'Online' || this.paymentTypeName == 'Gcash') {
                                data.append('accountNumber', this.accountNumber);
                                data.append('transaction_number', this.transactionNumber);
                                data.append('online_date', this.online_date);
                                data.append('OnlineDescription', this.OnlineDescription);
                            } else if(this.paymentTypeName != 'Cheque' || this.paymentTypeName != 'Cash') {
                                data.append('accountNumber', this.accountNumber);
                                data.append('accountName', this.accountName);
                                data.append('accountDate', this.accountDate);
                                data.append('accountDescription', this.accountDescription);
                            }

                            data.append('cashDenominations', JSON.stringify(this.cashDenominations));
                            data.append('totalAmount', this.convertTextToNumber(this.totalAmount));
                            data.append('grossAmount', this.convertTextToNumber(this.grossAmount));
                            data.append('whtAmount', this.convertTextToNumber(this.whtAmount));
                            data.append('otherAdjAmount', this.convertTextToNumber(this.otherAdjAmount));
                            data.append('netAmount', this.convertTextToNumber(this.netAmount));
                            data.append('move_date_reason',this.move_date_reason);

                            data.append('toAllocateSoa', JSON.stringify(this.toAllocateSoa));
                            data.append('customer_payment_allocation_trash_ids', JSON.stringify(this.customer_payment_allocation_trash_ids))
                            let payload = {
                                params:data,
                                config:config,
                            }

                            this.$store.dispatch('updateCustomerPaymentDetail',payload).then(response=>{
                                if(response.data.error) {
                                    swal.fire('',response.data.error,'error').then(confirm=>{
                                        this.updateOnFlight = false;
                                    });
                                } else {
                                    this.replaceCheque(response,"");
                                }
                            });
                        }
                        this.updateOnFlight = false;
                    });
                } else {
                    swal.fire('','Please Fill Required Fields','warning').then(confirm=>{
                        this.updateOnFlight = false;
                    });
                }
            }

        },
        decimalOnly ($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                $event.preventDefault();
            }
        },
        thousandSeprator (amount) {
            if (amount !== '' || amount !== undefined || amount !== 0 || amount !== '0' || amount !== null) {
                return amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            } else {
                return amount;
            }
        },
        getTwoDecimals (amount) {
            var formatted_amount = ''
            if(isNaN(amount)){
                if(amount != null && amount != ''){
                    formatted_amount = parseFloat(amount.replace(/,/g, ''))
                    formatted_amount = Math.trunc(formatted_amount * Math.pow(10, 3)) / Math.pow(10, 3)
                }
            }else{
                formatted_amount = Math.trunc(amount * Math.pow(10, 3)) / Math.pow(10, 3)
            }

            if (formatted_amount == '') {
                return 0.00
            }
            return this.thousandSeprator(formatted_amount.toFixed(2))
        },
        getTotalAmount() {
            this.totalAmount = this.getTwoDecimals(this.balanceAmounts - this.totalAmount);
            this.addToAllocateSOADefault();
        },
        getWhtAmount() {
            if(parseFloat(this.whtAmount) >= this.convertTextToNumber(this.totalAmount)) {
                this.whtAmount = this.getTwoDecimals(this.totalAmount);
            } else {
                this.whtAmount = this.getTwoDecimals(this.whtAmount);
            }
        },
        getOtherAdjAmount(){
            let balanceOfTotalAndWht = this.convertTextToNumber(this.totalAmount) - this.convertTextToNumber(this.whtAmount);

            if(parseFloat(this.otherAdjAmount) >= balanceOfTotalAndWht) {
                this.otherAdjAmount = this.getTwoDecimals(balanceOfTotalAndWht);
            } else {
                this.otherAdjAmount = this.getTwoDecimals(this.otherAdjAmount);
            }
        },
        convertTextToNumber(amount) {
            if(!!amount) {
                let amt = amount.replaceAll(',','');

                return parseFloat(amt);
            }
            return parseFloat(0);
        },
        openFilesViewer() {
            if(this.addEdit) {
                this.$store.commit('custPaymentDetailCloseAddEditDialog');
            }
        },
        closeFilesViewer() {
            if(this.addEdit) {
                this.$store.commit('custPaymentDetailShowAddEditDialog');
            }
        },
        addBusinessDays(originalDate, numDaysToAdd) {
                const Sunday = 0;
                const Saturday = 6;
                let daysRemaining = numDaysToAdd;

                const newDate = originalDate;

                while (daysRemaining > 0) {
                newDate.setDate(newDate.getDate() + 1);
                    if (newDate.getDay() !== 0 && newDate.getDay() !== 6) {
                    // skip sunday & saturday
                    daysRemaining--;
                    }
                }

                return newDate;
        },
        replaceCheque(response,message){
            if(this.isReplaceCheque){
                this.new_cpd_id = response.data.new_id
                this.replaceCheckDialog = true
            }
            else{
                this.custPaymentDetailCloseAddEditDialog();
                this.$store.commit('custPaymentDetailSuccessTrue');
                this.clearCameraCapture()
                this.uploadedFiles = null;
                this.$refs.fileUpload.reset();
                swal.fire(message, response.data.msg, "success").then(ok=>{
                    if(ok) {
                        this.$store.commit('custPaymentDetailSuccessFalse');
                        this.$emit('closeDialog');
                        this.submitOnFlight = false;
                    }
                })
            }
        },
        submitReplaceCheck(replace = null){
            let payload = {
                id: 0,
                cancel_reason:this.replaceCheck.reason,
                soa_id:this.soa_id,
                bounce_date:this.replaceCheck.date,
                isReplace:replace,
                new_cpd_id:this.new_cpd_id,
                cpd_id:this.cpd_id,
            }
            this.$store.dispatch('cancelCustomerPaymentAllocations',payload).then(response => {
                swal.fire(response.data.msg,'',response.data.status)

                this.$store.commit('custPaymentDetailCloseAddEditDialog');
                this.$store.commit('custPaymentDetailSuccessTrue');
                this.$store.commit('custPaymentDetailClose');
                this.custPaymentDetailCloseAddEditDialog();
                swal.fire("", response.data.msg, "success").then((value) => {
                    this.replaceCheckDialog = false
                    this.custPaymentDetailCloseAddEditDialog();
                    this.$store.commit('custPaymentDetailSuccessTrue');
                    this.clearCameraCapture()
                    this.uploadedFiles = null;
                    this.$refs.fileUpload.reset();
                    swal.fire('Replace Check Successfully', response.data.msg, "success").then(ok=>{
                        if(ok) {
                            this.$store.commit('custPaymentDetailSuccessFalse');
                            this.$emit('closeDialog');
                            this.$store.commit('custPaymentDetailClose');
                            this.submitOnFlight = false;
                        }
                    })
                    this.$store.commit('custPaymentDetailReplaceCheque',false);
                });
            })
        },
        moveChequeDate(){
            if(this.isChequeDateEditOnly){
                this.update_override.dialog = true;
            }
        },
        // replaceCheque(new_id){
        //     if(this.origin == "viewPayment"){
        //         let p = {
        //             id:this.customerPaymentDetailId,
        //             mode: 'Replace',
        //             new_id: new_id
        //         }
        //         this.$store.dispatch('updateChequeDate',p).then(response=>{
        //             swal.fire("", response.data.msg, "success").then(ok=>{
        //                 if(ok) {
        //                     this.$store.commit('custPaymentDetailSuccessFalse');
        //                     this.submitOnFlight = false;
        //                 }
        //             })
        //         })
        //     }
        // },
        getImages(images) {
            this.images = images;
        },
        clearCameraCapture() {
            this.$refs.cameraCapture.reset();
            this.images = null;
        },
        async createStatementOfAccount(){
            let soa_items = []
            soa_items.push(
                {
                    packing_list_id: this.soa_payload.id,
                    packing_list_num: this.soa_payload.packing_list_num,
                    sales_date: this.soa_payload.packing_list_date,
                    sales_total: this.soa_payload.total_amount,
                    remarks: null,
                    bank: null,
                    override_amount: null,
                }
            )
            let discount_amount = 0
            let selected_date = this.soa_payload.packing_list_date.substring(0,7)
            let due_date = this.$dayjs(this.soa_payload.packing_list_date).add(this.soa_payload.terms, 'day').format('YYYY-MM');

            let payload = {
                customer_id:        275,
                super_product:      0,
                due_date:           due_date,
                contact:            '',
                terms:              this.soa_payload.terms,
                remarks:            '',
                soa_items:          soa_items,
                override_by:        '',
                selected_date:      selected_date,
                paid_amount:        0.00,
                discount_amount:    discount_amount,
                total_amount:       this.soa_payload.total_amount,
                payment_method:     this.selectedPaymentType,
                payment_date:       this.paymentDate,
                addition_amount:    0.00,
                deduction_amount:   0.00,
                addition_remarks:   '',
                deduction_remarks:  '',
                debit_memos:        [],
                credit_memos:       [],
                cash:               false,
                is_per_pl:          true,
            }
            await this.$store.dispatch('postStatementOfAccounts',payload).then(response => {
                this.generated_soa_id = response.data.soa_id
            });

        },
        replaceActive(){
            this.$store.commit('custPaymentDetailReplaceCheque',true);
        },
        updatePaymentConfirmOverride(user_id, authenticated = false) {
            this.update_override.dialog = false
            if (authenticated) {
                this.update_override.authenticated = true
                this.update();
            }
        },

        exportPayments(status, customer, paymentType) {
            this.$store.dispatch('exportPaymentDetails', {
                status: status,
                customer: customer,
                payment_type: paymentType,
            }).then(response => {
                let data = response.data

                data.forEach((e,i) => {
                    e.gross_amount = parseFloat(e.gross_amount)
                    e.net_amount = parseFloat(e.net_amount)
                    e.remaining_amount = parseFloat(e.remaining_amount)
                    if(e.status == 0) {
                        return e.status = 'saved'
                    } else if(e.status == 3) {
                        return e.status = 'rejected'
                    } else if(e.status == 5) {
                        return e.status = 'bounced'
                    } else if(e.status == 6) {
                        return e.status = 'cancelled'
                    } else if(e.status == 7) {
                        return e.status = 'confirmed'
                    } else if(e.status == 8) {
                        return e.status = 'exhausted'
                    } else if(e.status == 9) {
                        return e.status = 'allocated'
                    } else if(e.status == 10) {
                        return e.status = 'partial'
                    } else if(e.status == 11) {
                        return e.status = 'for confirmation'
                    }
                })

                const sheetName = 'Payment'
                let exportHeadersAndData = {
                    [sheetName]: {
                        headers: this.tableHeaders.map( e=>{
                            e.header = e.text
                            e.key = e.value
                            e.style = e.style ?? ''
                            return e;
                        }),
                        data: data
                    }
                }
                this.exportExcel(exportHeadersAndData, `Payment ${this.$dayjs().format('YYYY-MM-DD')}`,'F69F1A' );
            })
        },
        formatDate(date) {
            if(!!date) {
                return this.$dayjs(date).format('YYYY-MM-DD, hh:mm:ss a');
            }
            return '';
        },
        getSoaSelection(){
            this.loading.soa_selection = true;
            let payload = {
                editPackingListId: this.editPackingListId,
                url: 'getAllStatementOfAccountSelection',
                prefix: 'dropdown',
                mutation: 'MUTATE_STATEMENT_OF_ACCOUNT_SELECTION',
                customer_id: this.selectedCustomer,
            }
            this.$store.dispatch('dropdownsApiGet',payload).then(response => {
                this.loading.soa_selection = false;
            });
        },
        checkSoaSelected(item){
            let existingTypes = this.toAllocateSoa.map(e=> e.soa_id)
            if(existingTypes.includes(item.id) || item.balance_amount == 0){
                return true
            }
            return false
        },
        async getToAllocateSoa(){
            if(this.selectedCustomer){
                this.loading.confirmationSoa = true;
                this.toAllocateSoa = [];
                let payload = {
                    customer_id: this.selectedCustomer,
                    customer_payment_detail_id: this.customerPaymentDetailId
                }
                await this.$store.dispatch('getToAllocateSoa', payload).then(response=>{
                    this.toAllocateSoa = response
                    this.loading.confirmationSoa = false;
                    if(this.fromSoa){
                        let soaAlreadyAllocate = this.toAllocateSoa.map(e=> e.soa_id).indexOf(this.soa_id);
                        if(soaAlreadyAllocate == -1){
                            this.addToAllocateSOADefault(this.totalAmount ? this.totalAmount.replace(/,/g, '') : 0)
                        }
                    }
                })
            }
        },
        formatAllocate(item) {
            if (this.toAllocateSoa.length > 0) {
                let total_table_input = this.toAllocateSoa.reduce(
                    (sum, e) => sum + parseFloat(e.allocated_amount.replace(/,/g, '')),
                    0
                );
                let parsed_total = this.totalAmount == 0 ? 0 : parseFloat(this.totalAmount.replace(/,/g, ''));
                let selected_soa = this.GET_ALL_STATE.statement_of_account_selection.find(x => x.id == item.soa_id);

                if (selected_soa) {
                    if (!item.allocated_amount) {
                        item.allocated_amount = "0.00";
                    } else {
                        let allocated_amount_float = parseFloat(item.allocated_amount.replace(/,/g, ''));
                        let remaining_to_allocate = parsed_total - (total_table_input - allocated_amount_float);

                        if (allocated_amount_float > selected_soa.balance_amount) {
                            if (this.toAllocateSoa.length > 1 && selected_soa.balance_amount > allocated_amount_float) {
                                swal.fire(
                                    "Allocated amount must not be greater than balance amount!",
                                    "Allocated amount will be changed to balance amount!",
                                    "warning"
                                );
                                item.allocated_amount = this.thousandSeprator(parseFloat(selected_soa.balance_amount).toFixed(2));
                            }else{
                                if (remaining_to_allocate > selected_soa.balance_amount) {
                                    item.allocated_amount = this.thousandSeprator(parseFloat(selected_soa.balance_amount).toFixed(2));
                                }
                                else {
                                    swal.fire(
                                        "Total Amount to allocate exceeded!",
                                        "Allocated amount will be changed to the remaining amount to allocate!",
                                        "warning"
                                    );
                                    item.allocated_amount = this.thousandSeprator(remaining_to_allocate.toFixed(2));
                                }
                            }
                        }
                        else if (allocated_amount_float > remaining_to_allocate) {
                            swal.fire(
                                "Total Amount to allocate exceeded!",
                                "Allocated amount will be changed to the remaining amount to allocate!",
                                "warning"
                            );
                            item.allocated_amount = this.thousandSeprator(remaining_to_allocate.toFixed(2));
                        }
                        else {
                            item.allocated_amount = this.thousandSeprator(allocated_amount_float.toFixed(2));
                        }
                    }
                    this.setBalanceAmount(item, 'blur');
                }
            }
        },
        addSoaToAllocate(soa_id = null, allocated_amount = '0.00'){
            this.toAllocateSoa.push({
                id                      : null,
                soa_id                  : soa_id,
                allocated_amount        : allocated_amount,
                orig_allocated_amount   : "0.00",
                allocated_wht           : '0.00',
                balance_amount          : '0.00',
                remaining_amount        : '0.00',
                created_at              : this.$dayjs().format('YYYY-MM-DD, hh:mm:ss a'),
                allocated_by_name       : this.USERACCOUNT_LOAD.name,
                allocated_by            : this.USERACCOUNT_LOAD.id,
                confirmed               : 0
            })
        },
        setBalanceAmount(item, event) {
            let selected_soa = this.GET_ALL_STATE.statement_of_account_selection.find(x => x.id == item.soa_id);

            if (selected_soa) {
                if(event == 'change'){
                    this.setAllocateByBalance(item);
                }
                let balance_amount = 0
                let allocated_amount_formatted = parseFloat(item.allocated_amount.replace(/,/g,''));
                if(this.dialogAction == 'edit'){
                    let grossAmount = parseFloat(selected_soa.balance_amount);
                    // let grossAmount = parseFloat(selected_soa.gross_amount.replace(/,/g, ''));
                    let paidAmount = parseFloat(selected_soa.paid_amount.replace(/,/g, ''));
                    let origAllocatedAmount = parseFloat(item.orig_allocated_amount.replace(/,/g, ''));
                    let netAmount = paidAmount - origAllocatedAmount < 0 ? 0 : paidAmount - origAllocatedAmount;

                    balance_amount = grossAmount - netAmount;
                }else{
                    balance_amount = parseFloat(selected_soa.balance_amount.replace(/,/g, ''));
                }
                item.balance_amount = this.thousandSeprator(balance_amount.toFixed(2));
                item.remaining_amount = balance_amount < allocated_amount_formatted ? 0 : this.thousandSeprator((balance_amount - allocated_amount_formatted).toFixed(2));
                this.$forceUpdate();
            }
        },
        addToAllocateSOADefault(totalAmount) {
            let number = parseFloat(totalAmount).toFixed(2).replace(/,/g,'');
            this.totalAmount = this.thousandSeprator(number);

            if(this.toAllocateSoa.length == 0){
                this.addSoaToAllocate(this.soa_id, this.totalAmount);
                this.formatAllocate(this.toAllocateSoa[0]);
            }else{
                let soa_index = this.toAllocateSoa.map(e=> e.soa_id).indexOf(this.soa_id);
                if(soa_index != -1){
                    this.toAllocateSoa[soa_index].allocated_amount = this.totalAmount;
                    this.formatAllocate(this.toAllocateSoa[0]);
                }
            }
        },
        totalToAllocate() {
            return this.toAllocateSoa.reduce((sum, e) => sum + parseFloat(e.allocated_amount.replace(/,/g, '')), 0).toFixed(2);
        },
        formatAmount(value, modelName) {
            if (!value) {
                this[modelName] = "0.00";
            } else {
                let number = parseFloat(value.replace(/,/g, ''));
                this[modelName] = this.thousandSeprator(number.toFixed(2));
            }
        },
        setAllocateByBalance(item){
            let total_table_input = this.toAllocateSoa.reduce(
                (sum, e) => sum + parseFloat(e.allocated_amount.replace(/,/g, '')),
                0
            );
            let parsed_total = this.totalAmount == 0 ? 0 : parseFloat(this.totalAmount.replace(/,/g, ''));
            let selected_soa = this.GET_ALL_STATE.statement_of_account_selection.find(x => x.id == item.soa_id);

            if (selected_soa) {
                // if (!item.allocated_amount) {
                //     item.allocated_amount = "0.00";
                // } else {
                let allocated_amount_float = parseFloat(item.allocated_amount.replace(/,/g, ''));
                let remaining_to_allocate = parsed_total - (total_table_input - allocated_amount_float);

                if (remaining_to_allocate > selected_soa.balance_amount) {
                    item.allocated_amount = this.thousandSeprator(parseFloat(selected_soa.balance_amount).toFixed(2));
                }
                else {
                    item.allocated_amount = this.thousandSeprator(remaining_to_allocate.toFixed(2));
                    // item.allocated_amount = this.thousandSeprator(allocated_amount_float.toFixed(2));
                }
                // }
            }
        },
        removeAllocateSoa(item, index){
            if(this.toAllocateSoa.length > 0){
                swal.fire({
                    title: "Delete",
                    text: `Are you sure you want to remove allocation?`,
                    icon: 'question',
                    showConfirmButton:true,
                    confirmButtonText:'Confirm',
                    showCancelButton:true,
                    showCloseButton:true,
                    confirmButtonColor: '397373',
                    cancelButtonColor:'grey',
                    reverseButtons:true,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.toAllocateSoa.splice(index, 1);
                        if(item.id){
                            this.customer_payment_allocation_trash_ids.push(item.id)
                        }
                    }
                });
            }
        },
        remainingAmountForAllocation(){
            let remainingAmountForAllocate = this.convertTextToNumber(this.totalAmount).toFixed(2) - this.totalToAllocate();
            return remainingAmountForAllocate < 0 ? 0 : remainingAmountForAllocate
        },
        requiredFields() {
            let warning = '';
            if(this.requireCheque) {
                if (!this.accountNumber) warning += 'Please ensure that the empty field for the account number is filled in.<br>';
                if (!this.accountDate) warning += 'Please ensure that the empty field for the account date name is filled in.<br>';
            }

            if(this.requireOnline) {
                if (!this.transactionNumber) warning += 'Please ensure that the empty field for the transaction number is filled in.<br>';
            }

            if(this.requireCard) {
                if (!this.card_name) warning += 'Please ensure that the empty field for the card name is filled in.<br>';
                if (!this.card_number) warning += 'Please ensure that the empty field for the card number is filled in.<br>';
                if (!this.approval_code) warning += 'Please ensure that the empty field for the approval code is filled in.<br>';
            }

            if(this.requireCustomer) {
                if (!this.selectedCustomer) warning += 'Please select a Customer.<br>';
            }

            if (!this.paymentDate) warning += 'Please ensure that the empty field for the payment date is filled in.<br>';
            if (!this.totalAmount) warning += 'Please ensure that the empty field for the total amount is filled in.<br>';
            if (!this.acknowledgmentReceipt) warning += 'Please ensure that the empty field for the acknowledgment receipt is filled in.<br>';

            if (!this.selectedPaymentType) warning += 'Please select a Payment Type.<br>';
            if(this.paymentTypeName == 'Cash' || this.selectedDepositType == 1) {
                if(this.selectedDepositType == 1) {
                    if (!this.selectedBank) warning += 'Please select a Bank.<br>';
                }
            } else if(this.paymentTypeName != 'Cash' && !!this.selectedPaymentType && (this.paymentTypeName != 'Online' && this.paymentTypeName != 'Gcash') && this.paymentTypeName != 'Credit/Debit Card' || (this.paymentTypeName == 'Deposit' && this.selectedDepositType == 2)) {
                if(!['Cash', 'Journal Voucher'].includes(this.paymentTypeName)) {
                    if (!this.selectedBank) warning += 'Please select a Bank.<br>';
                }
            } else if(this.paymentTypeName == 'Online' || this.paymentTypeName == 'Gcash') {
                if(this.paymentTypeName != 'Cash') {
                    if (!this.selectedBank) warning += 'Please select a Bank.<br>';
                }
            } else if(this.paymentTypeName == 'Credit/Debit Card') {
                if (!this.selectedBank) warning += 'Please select a Bank.<br>';
            }

            if (warning !== '') return swal.fire({
                title: 'Please Fill out Information:',
                html: warning,
                icon: "warning",
            }).then(confirm=>{
                this.submitOnFlight = false;
                this.updateOnFlight = false;
            });
        },
    },
    // watch:{
    //     'USER_ACCESS':{
    //         handler(val){
    //             this.userAccess.create = false;
    //             this.userAccess.edit = false;
    //             this.userAccess.delete = false;
    //             this.userAccess.view = false;
    //             if(val != "fail"){
    //             val.forEach((e) => {
    //                 if (e.actions_code == "create"){
    //                     this.userAccess.create = true;
    //                 }
    //                 else if (e.actions_code == "edit"){
    //                     this.userAccess.edit = true;
    //                 }
    //                 else if (e.actions_code == "view"){
    //                     this.userAccess.view = true;
    //                 }
    //                 else if (e.actions_code == "delete"){
    //                     this.userAccess.delete = true;
    //                 }
    //             });
    //             }

    //         }
    //     },
    // }
}
</script>

<style lang="scss" scoped>
    .hide-text-field-details {
        .v-text-field__details {
            display: none;
        }
    }
    ::v-deep .right-align-text input {
        text-align: right;
    }
</style>


