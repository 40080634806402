<template>
    <v-container fluid>
        <ComponentHeader :button="true" class="ma-n4" title='Booked Vs Invoiced Orders' type='Sales'></ComponentHeader>
        <v-card>
            <v-card-text class="bg-bluish-gray">
                <v-row class="mx-auto" dense>
                    <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <v-autocomplete
                            v-model="filters.mother_customers"
                            :items="GET_AFFLIATE_CUSTOMER"
                            :disabled="filters.customers.length > 0"
                            label="Select Mother Customer"
                            background-color="white"
                            dense
                            outlined
                            hide-details
                            clearable
                            multiple
                            small-chips
                            deletable-chips
                            auto-select-first
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <v-autocomplete
                            v-model="filters.customers"
                            :items="GET_CUSTOMERS_SELECTION"
                            :disabled="filters.mother_customers.length > 0"
                            label="Select Customer"
                            background-color="white"
                            dense
                            outlined
                            hide-details
                            clearable
                            multiple
                            small-chips
                            deletable-chips
                            auto-select-first
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <v-autocomplete
                            v-model="filters.salesman"
                            :items="GET_SALESMAN_SELECTION"
                            label="Select Salesman"
                            background-color="white"
                            dense
                            outlined
                            hide-details
                            clearable
                            multiple
                            small-chips
                            deletable-chips
                            auto-select-first
                        ></v-autocomplete>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="12" sm="12" md="2" lg="3" xl="3" class="d-flex justify-content-end" style="gap: 10px">
                        <v-btn :loading="!loaded" small @click="list(1);">Export</v-btn>
                        <v-btn :loading="!loaded" small style="margin-right: 3px" @click="list()">Filter</v-btn>
                    </v-col>
                </v-row>
                <v-row class="mx-auto" dense>
                    <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <v-autocomplete
                            v-model="filters.customer_category"
                            :items="GET_CUSTOMER_CATEGORY_SELECTION"
                            label="Select Customer Category"
                            background-color="white"
                            dense
                            outlined
                            hide-details
                            clearable
                            multiple
                            small-chips
                            deletable-chips
                            auto-select-first
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <v-autocomplete
                            v-model="filters.customer_classification"
                            :items="GET_CLASSIFICATIONS_SELECTION"
                            label="Select Customer Classification"
                            background-color="white"
                            dense
                            outlined
                            hide-details
                            clearable
                            multiple
                            small-chips
                            deletable-chips
                            auto-select-first
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <v-autocomplete
                            v-model="filters.customer_cluster"
                            :items="dropdowns.clusters"
                            label="Select Customer Cluster"
                            background-color="white"
                            dense
                            outlined
                            hide-details
                            clearable
                            multiple
                            small-chips
                            deletable-chips
                            auto-select-first
                        ></v-autocomplete>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <v-row class="mx-auto" dense>
                    <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                        <v-autocomplete
                            v-model="filters.item"
                            :items="GET_ITEM_SELECTION"
                            label="Item Model"
                            background-color="white"
                            dense
                            outlined
                            hide-details
                            clearable
                            multiple
                            small-chips
                            deletable-chips
                            auto-select-first
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                        <v-select
                            v-model="filters.sort_option"
                            :items="sort_option"
                            label="Sort By"
                            background-color="white"
                            dense
                            outlined
                            hide-details
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="1" lg="1" xl="1">
                        <v-select
                            v-model="filters.sort_direction"
                            :items="sort_direction"
                            label="Sort Direction"
                            background-color="white"
                            dense
                            outlined
                            hide-details
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                        <v-select
                            v-model="filters.has_booked_orders"
                            :items="has_booked_orders"
                            label="Booked Orders"
                            background-color="white"
                            dense
                            outlined
                            hide-details
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                        <v-select
                            v-model="filters.sales_aftersales"
                            :items="sales_aftersales"
                            label="Sales/Aftersales"
                            background-color="white"
                            dense
                            outlined
                            hide-details
                        ></v-select>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <v-row class="mx-auto my-auto" dense>
                    <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="mx-3">
                        <v-menu
                            v-model="filters.date_from_menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_from"
                                    v-on="on"
                                    label="Date From"
                                    append-icon="mdi-calendar-clock"
                                    readonly
                                    hide-details
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_from" @input="filters.date_from_menu = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="mx-3">
                        <v-menu
                            v-model="filters.date_to_menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_to"
                                    v-on="on"
                                    label="Date To"
                                    append-icon="mdi-calendar-clock"
                                    readonly
                                    hide-details
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_to" @input="filters.date_to_menu = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="1" lg="1" xl="1" class="d-flex align-items-center">
                        <v-text-field
                            v-model.number="filters.fulfillment_rate_range[0]"
                            label="FR From"
                            type="number"
                            append-icon="mdi-percent"
                            class="bg-white"
                            dense
                            outlined
                            hide-details
                            hide-spin-buttons
                            @keypress="decimalOnly"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="1" lg="1" xl="1" class="d-flex align-items-center">
                        <v-text-field
                            v-model.number="filters.fulfillment_rate_range[1]"
                            label="FR To"
                            type="number"
                            append-icon="mdi-percent"
                            class="bg-white"
                            dense
                            outlined
                            hide-details
                            hide-spin-buttons
                            @keypress="decimalOnly"
                        ></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="12" sm="12" md="2" lg="2" xl="2" class="d-flex justify-content-end align-items-center">
                        <v-menu
                            v-model="invoiced_total_amount_breakdown.dialog"
                            :close-on-content-click="false"
                            offset-overflow
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    :disabled="!loaded"
                                    small
                                    @click="invoiced_total_amount_breakdown.dialog = true"
                                >
                                    Fulfillment Rate Share
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>
                                    Fulfillment Rate on Total Sales: {{ getTwoDecimals(this.totals.total_sales) }}
                                </v-card-title>
                                <v-card-text>
                                    <v-data-table
                                        :headers="invoiced_total_amount_breakdown.headers"
                                        :items="invoiced_total_amount_breakdown.list"
                                        :server-items-length="invoiced_total_amount_breakdown.list.length"
                                        disable-sort
                                        disable-pagination
                                        hide-default-footer
                                    >
                                        <template v-slot:[`item.total_amount`]="{ item }">
                                            {{ item.total_amount | currency }}
                                        </template>
                                        <template v-slot:[`item.fulfillment_rate`]="{ item }">
                                            {{ item.fulfillment_rate | percentTwoDecimals }}
                                        </template>
                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-menu>
                    </v-col>
                </v-row>
                <ReportsCardsComponentVue
                    :setClass="'mx-auto my-1'"
                    :totals="totals"
                    :cards="cards"
                    style="width:100%;"
                ></ReportsCardsComponentVue>
                <ReportsCardsComponentVue
                    :setClass="'mx-auto my-1'"
                    :totals="totals"
                    :cards="cards2"
                    style="width:100%;"
                ></ReportsCardsComponentVue>
                <ReportsCardsComponentVue
                    :setClass="'mx-auto my-1'"
                    :totals="totals"
                    :cards="cards3"
                    style="width:100%;"
                ></ReportsCardsComponentVue>
                <v-row class="mx-auto" dense>
                    <v-col cols="12">
                        <v-data-table
                            :headers="dataTable_main.headers"
                            :items="dataTable_main.items"
                            :server-items-length="dataTable_main.total"
                            :loading="dataTable_main.loading"
                            :options.sync="dataTable_main.options"
                            :expanded.sync="dataTable_main.selected"
                            item-key="customer_id"
                            dense
                            hide-default-footer
                            disable-sort
                            disable-pagination
                            show-expand
                            @item-expanded="expandData"
                        >
                            <template #expanded-item="{ headers, item }">
                                <td :colspan="headers.length">
                                    <v-data-table
                                        :headers="dataTable_main_expanded.headers"
                                        :items="dataTable_main_expanded.items"
                                        :server-items-length="dataTable_main_expanded.items.length"
                                        :loading="dataTable_main_expanded.loading"
                                        :options.sync="dataTable_main_expanded.options"
                                        :footer-props="dataTable_main_expanded.options"
                                        dense
                                        dark
                                        hide-default-footer
                                        disable-sort
                                        disable-pagination
                                    >
                                        <template v-slot:[`item.booked_total`]="{ item }">
                                            <span>{{ item.booked_total | currency }}</span>
                                        </template>
                                        <template v-slot:[`item.invoiced_total`]="{ item }">
                                            <span>{{ item.invoiced_total | currency }}</span>
                                        </template>
                                        <template v-slot:[`item.invoiced_printed_total`]="{ item }">
                                            <span>{{ item.invoiced_printed_total | currency }}</span>
                                        </template>
                                        <template v-slot:[`item.invoiced_not_printed_total`]="{ item }">
                                            <span>{{ item.invoiced_not_printed_total | currency }}</span>
                                        </template>
                                        <template v-slot:[`item.not_invoiced_total`]="{ item }">
                                            <span>{{ item.not_invoiced_total | currency }}</span>
                                        </template>
                                        <template v-slot:[`item.canceled_total`]="{ item }">
                                            <span>{{ item.canceled_total | currency }}</span>
                                        </template>
                                        <template v-slot:[`item.previous_invoiced_total`]="{ item }">
                                            <span>{{ item.previous_invoiced_total | currency }}</span>
                                        </template>
                                        <template v-slot:[`item.previous_invoiced_printed_total`]="{ item }">
                                            <span>{{ item.previous_invoiced_printed_total | currency }}</span>
                                        </template>
                                        <template v-slot:[`item.previous_invoiced_not_printed_total`]="{ item }">
                                            <span>{{ item.previous_invoiced_not_printed_total | currency }}</span>
                                        </template>
                                        <template v-slot:[`item.fulfillment_rate`]="{ item }">
                                            <span>{{ item.fulfillment_rate | percentTwoDecimals }}</span>
                                        </template>
                                        <template v-slot:[`item.status`]="{ item }">
                                            <span class="badge badge-saved" v-if="item.status == 0">saved</span>
                                            <span class="badge badge-for-sales" v-if="item.status == 1">for sales</span>
                                            <span class="badge badge-for-accounting" v-if="item.status == 2">for accounting</span>
                                            <span class="badge badge-approved" v-else-if="item.status == 3">approved</span>
                                            <span class="badge badge-completed" v-else-if="item.status == 4">completed</span>
                                            <span class="badge badge-warning" v-else-if="item.status == 5">rejected</span>
                                            <span class="badge badge-danger" v-else-if="item.status == 6">canceled</span>
                                            <span class="badge badge-dark" v-else-if="item.status == 7">deleted</span>
                                            <span class="badge badge-purple" v-else-if="item.status == 8">converted</span>
                                            <span class="badge badge-exec-approved" v-else-if="item.status == 9">exec approved</span>
                                            <span class="badge badge-pending" v-else-if="item.status == 10">partial</span>
                                            <span class="badge badge-for-exec" v-else-if="item.status == 11">for exec approval</span>
                                            <span class="badge badge-warning" v-else-if="item.status == 12">stockcard</span>
                                            <!-- <span class="badge badge-for-credit-limit" v-else-if="item.status == 14">for credit limit</span> -->
                                            <!-- <span class="badge badge-for-credit-terms" v-else-if="item.status == 15">for credit terms</span> -->
                                            <span class="badge badge-for-scheduling" v-else-if="item.status == 16">for scheduling</span>
                                            <span class="badge badge-submitted" v-else-if="item.status == 17">waiting for stocks</span>
                                            <span class="badge badge-preparing" v-else-if="item.status == 18">preparing</span>
                                            <span class="badge badge-prepared" v-else-if="item.status == 19">prepared</span>
                                            <span class="badge badge-checking" v-else-if="item.status == 20">checking</span>
                                            <span class="badge badge-checked" v-else-if="item.status == 21">checked</span>
                                            <span class="badge badge-invoiced" v-else-if="item.status == 22">invoiced</span>
                                        </template>
                                        <template v-slot:[`item.actions`]="{ item }">
                                            <span class="text-nowrap">
                                                <v-btn text icon color="orange">
                                                    <v-icon class="btn-action" small @click="viewSalesOrder(item.sales_order_id)">mdi-eye</v-icon>
                                                </v-btn>
                                            </span>
                                        </template>
                                    </v-data-table>
                                </td>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <span class="text-nowrap">
                                    <v-btn text icon color="orange">
                                        <v-icon class="btn-action" small @click="viewSODetails(item.customer_id)">mdi-eye</v-icon>
                                    </v-btn>
                                </span>
                            </template>
                            <template v-slot:[`item.booked_total`]="{ item }">
                                <span>{{ item.booked_total | currency }}</span>
                            </template>
                            <template v-slot:[`item.invoiced_total`]="{ item }">
                                <span>{{ item.invoiced_total | currency }}</span>
                            </template>
                            <template v-slot:[`item.invoiced_printed_total`]="{ item }">
                                <span>{{ item.invoiced_printed_total | currency }}</span>
                            </template>
                            <template v-slot:[`item.invoiced_not_printed_total`]="{ item }">
                                <span>{{ item.invoiced_not_printed_total | currency }}</span>
                            </template>
                            <template v-slot:[`item.not_invoiced_total`]="{ item }">
                                <span>{{ item.not_invoiced_total | currency }}</span>
                            </template>
                            <template v-slot:[`item.canceled_total`]="{ item }">
                                <span>{{ item.canceled_total | currency }}</span>
                            </template>
                            <template v-slot:[`item.previous_invoiced_total`]="{ item }">
                                <span>{{ item.previous_invoiced_total | currency }}</span>
                            </template>
                            <template v-slot:[`item.previous_invoiced_printed_total`]="{ item }">
                                <span>{{ item.previous_invoiced_printed_total | currency }}</span>
                            </template>
                            <template v-slot:[`item.previous_invoiced_not_printed_total`]="{ item }">
                                <span>{{ item.previous_invoiced_not_printed_total | currency }}</span>
                            </template>
                            <template v-slot:[`item.fulfillment_rate`]="{ item }">
                                <span>{{ item.fulfillment_rate | percentTwoDecimals }}</span>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-dialog v-model="dialog.view_so" persistent max-width="100%" scrollable>
            <v-card>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">Sales Order Details</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeDialog()">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3" class="ml-2">
                        <v-card class="total-cards" elevation="2">
                            <v-card-text class="d-flex justify-content-start align-items-center">
                                <div class="card-icon total-amount">
                                    <v-icon class="text-white" style="font-size: 40px">mdi-cart</v-icon>
                                </div>
                                <div class="card-details">
                                    <p>Booked Total Amount</p>
                                    <b style="color: black">&#x20b1;{{ view_total_booked | currency }}</b>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3" class="ml-2">
                        <v-card class="total-cards" elevation="2">
                            <v-card-text class="d-flex justify-content-start align-items-center">
                                <div class="card-icon total-amount">
                                    <v-icon class="text-white" style="font-size: 40px">mdi-cart</v-icon>
                                </div>
                                <div class="card-details">
                                    <p>Invoiced Total Amount</p>
                                    <b style="color: black">&#x20b1;{{ view_total_invoiced | currency }}</b>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="text-right mr-3">
                        <a href="#" class="badge badge-all ml-2" @click="status_id = -1; viewSODetails(customer_id, -1);">all</a>
                        <a href="#" class="badge badge-saved ml-2" @click="status_id = 0; viewSODetails(customer_id, 0);">saved</a>
                        <a href="#" class="badge badge-for-exec ml-2" @click="status_id = 11; viewSODetails(customer_id, 11);">for exec approval</a>
                        <a href="#" class="badge badge-for-sales ml-2" @click="status_id = 1; viewSODetails(customer_id, 1);">for sales</a>
                        <a href="#" class="badge badge-for-accounting ml-2" @click="status_id = 2; viewSODetails(customer_id, 2);">for accounting</a>
                        <a href="#" class="badge white--text badge-info ml-2" @click="status_id = 9; viewSODetails(customer_id, 9);">exec approved</a>
                        <a href="#" class="badge badge-approved ml-2" @click="status_id = 3; viewSODetails(customer_id, 3);">approved</a>
                        <a href="#" class="badge badge-converted ml-2" @click="status_id = 8; viewSODetails(customer_id, 8);">converted</a>
                        <a href="#" class="badge badge-partial ml-2" @click="status_id = 10; viewSODetails(customer_id, 10);">partial</a>
                        <a href="#" class="badge badge-completed ml-2" @click="status_id = 4; viewSODetails(customer_id, 4);">completed</a>
                        <a href="#" class="badge white--text badge-warning ml-2" @click="status_id = 12; viewSODetails(customer_id, 12);">stockcard</a>
                        <a href="#" class="badge white--text badge-danger ml-2" @click="status_id = 6; viewSODetails(customer_id, 6);">canceled</a>
                        <a href="#" class="badge white--text badge-warning ml-2" @click="status_id = 16; viewSODetails(customer_id, 16);">for scheduling</a>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-card-text>
                    <v-data-table
                        :headers="view_headers"
                        :items="view_items"
                        :loading="!loaded"
                        class="w-100"
                    >
                        <template v-slot:[`item.booked_total`]="{ item }">
                            <span class="text-nowrap">{{ item.booked_total | currency }}</span>
                        </template>
                        <template v-slot:[`item.invoiced_total`]="{ item }">
                            <span class="text-nowrap">{{ item.invoiced_total | currency }}</span>
                        </template>
                        <template v-slot:[`item.not_invoiced_total`]="{ item }">
                            <span class="text-nowrap">{{ item.not_invoiced_total | currency }}</span>
                        </template>
                        <template v-slot:[`item.canceled_total`]="{ item }">
                            <span class="text-nowrap">{{ item.canceled_total | currency }}</span>
                        </template>
                        <template v-slot:[`item.fulfillment_rate`]="{ item }">
                            <span class="text-nowrap">{{ item.fulfillment_rate | percentTwoDecimals }}</span>
                        </template>
                        <template v-slot:[`item.status`]="{ item }">
                            <span class="badge badge-saved" v-if="item.status == 0">saved</span>
                            <span class="badge badge-for-sales" v-if="item.status == 1">for sales</span>
                            <span class="badge badge-for-accounting" v-if="item.status == 2">for accounting</span>
                            <span class="badge badge-approved" v-else-if="item.status == 3">approved</span>
                            <span class="badge badge-completed" v-else-if="item.status == 4">completed</span>
                            <span class="badge badge-warning" v-else-if="item.status == 5">rejected</span>
                            <span class="badge badge-danger" v-else-if="item.status == 6">canceled</span>
                            <span class="badge badge-dark" v-else-if="item.status == 7">deleted</span>
                            <span class="badge badge-purple" v-else-if="item.status == 8">converted</span>
                            <span class="badge badge-exec-approved" v-else-if="item.status == 9">exec approved</span>
                            <span class="badge badge-pending" v-else-if="item.status == 10">partial</span>
                            <span class="badge badge-for-exec" v-else-if="item.status == 11">for exec approval</span>
                            <span class="badge badge-warning" v-else-if="item.status == 12">stockcard</span>
                            <!-- <span class="badge badge-for-credit-limit" v-else-if="item.status == 14">for credit limit</span> -->
                            <!-- <span class="badge badge-for-credit-terms" v-else-if="item.status == 15">for credit terms</span> -->
                            <span class="badge badge-for-scheduling" v-else-if="item.status == 16">for scheduling</span>
                            <span class="badge badge-submitted" v-else-if="item.status == 17">waiting for stocks</span>
                            <span class="badge badge-preparing" v-else-if="item.status == 18">preparing</span>
                            <span class="badge badge-prepared" v-else-if="item.status == 19">prepared</span>
                            <span class="badge badge-checking" v-else-if="item.status == 20">checking</span>
                            <span class="badge badge-checked" v-else-if="item.status == 21">checked</span>
                            <span class="badge badge-invoiced" v-else-if="item.status == 22">invoiced</span>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <span class="text-nowrap">
                                <v-btn text icon color="orange">
                                    <v-icon class="btn-action" small @click="viewSalesOrder(item.sales_order_id)">mdi-eye</v-icon>
                                </v-btn>
                            </span>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
        <SalesOrdersViewComponent :viewSalesOrderId="viewSalesOrderId" :re_so="false" :service="0" @closeView="closeView"></SalesOrdersViewComponent>
    </v-container>
</template>

<script>
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue'
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
import ReportBodyComponent from '@/views/main/layouts/Reports/ReportsBodyComponent.vue'
import SalesOrdersViewComponent from '@/views/main/modules/Sales/SalesOrdersNew/SalesOrdersViewComponent';
import { mapGetters } from 'vuex';
import ReportsCardsComponentVue from '@/views/main/layouts/Reports/ReportsCardsComponent.vue'

export default {
    mixins: [
        SharedFunctionsComponent
    ],
    props: {
        fromDms: {
            default: () => 0,
            type: Number,
        },
    },
    components: {
        ReportBodyComponent,
        ComponentHeader,
        ReportsCardsComponentVue,
        SalesOrdersViewComponent,
    },
    data() {
        return {
            loaded: true,

            filters: {
                mother_customers: [],
                customers: [],
                salesman: [],
                customer_category: [],
                customer_classification: [],
                customer_cluster: [],
                date_from: this.$dayjs().startOf('month').format('YYYY-MM-DD'),
                date_to: this.$dayjs().endOf('month').format('YYYY-MM-DD'),
                date_from_menu: false,
                date_to_menu: false,
                search: null,
                sort_option: 'company_name',
                sort_direction: 'asc',
                has_booked_orders: 1,
                sales_aftersales: 0,
                fulfillment_rate_range: [0, 100],
            },

            dataTable_main: {
                headers: [
                    { text: 'CUSTOMER', align: 'start', value: 'company_name' },
                    { text: 'SALESMAN', align: 'start', value: 'salesman' },
                    { text: 'SO COUNT', align: 'center', value: 'booked_orders_count' },
                    { text: 'LATEST SO DATE', align: 'end', value: 'latest_so_date' },
                    { text: 'BOOKED TOTAL (C)', align: 'end', value: 'booked_total' },
                    { text: 'INVOICED TOTAL (C)', align: 'end', value: 'invoiced_total' },
                    { text: 'INVOICED TOTAL - PRINTED (C)', align: 'end', value: 'invoiced_printed_total' },
                    { text: 'INVOICED TOTAL - NOT PRINTED (C)', align: 'end', value: 'invoiced_not_printed_total' },
                    { text: 'NOT INVOICED TOTAL (C)', align: 'end', value: 'not_invoiced_total' },
                    { text: 'CANCELED TOTAL (C)', align: 'end', value: 'canceled_total' },
                    { text: 'INVOICED TOTAL (P)', align: 'end', value: 'previous_invoiced_total' },
                    { text: 'INVOICED TOTAL - PRINTED (P)', align: 'end', value: 'previous_invoiced_printed_total' },
                    { text: 'INVOICED TOTAL - NOT PRINTED (P)', align: 'end', value: 'previous_invoiced_not_printed_total' },
                    { text: 'FULFILLMENT RATE', align: 'end', value: 'fulfillment_rate' },
                    // { text: 'ACTIONS', align: 'center', value: 'actions' },
                ],
                items: [],
                selected: [],
                total: 0,
                options: {},
                loading: false,
            },

            dataTable_main_expanded: {
                headers: [
                    { text: 'SO DATE', value: 'created_at', align: 'center' },
                    { text: 'SO#', value: 'order_num', align: 'center' },
                    { text: 'STATUS', value: 'status', align: 'center' },
                    { text: 'BOOKED TOTAL (C)', align: 'end', value: 'booked_total' },
                    { text: 'INVOICED TOTAL (C)', align: 'end', value: 'invoiced_total' },
                    { text: 'INVOICED TOTAL - PRINTED (C)', align: 'end', value: 'invoiced_printed_total' },
                    { text: 'INVOICED TOTAL - NOT PRINTED (C)', align: 'end', value: 'invoiced_not_printed_total' },
                    { text: 'NOT INVOICED TOTAL (C)', align: 'end', value: 'not_invoiced_total' },
                    { text: 'CANCELED TOTAL (C)', align: 'end', value: 'canceled_total' },
                    { text: 'INVOICED TOTAL (P)', align: 'end', value: 'previous_invoiced_total' },
                    { text: 'INVOICED TOTAL - PRINTED (P)', align: 'end', value: 'previous_invoiced_printed_total' },
                    { text: 'INVOICED TOTAL - NOT PRINTED (P)', align: 'end', value: 'previous_invoiced_not_printed_total' },
                    { text: 'FULFILLMENT RATE', align: 'end', value: 'fulfillment_rate' },
                    { text: 'ACTIONS', align: 'center', value: 'actions' },
                ],
                items: [],
                total: 0,
                options: {
                    itemsPerPage: 10,
                    itemsPerPageOptions: [10, 20, 50, 100, -1],
                },
                loading: false,
            },

            view_options:{},
            view_headers: [
                { text: 'CUSTOMER', align: 'start', value: 'company_name', },
                { text: 'SO#', align: 'start', value: 'order_num', },
                { text: 'SO DATE', align: 'start', value: 'created_at', },
                { text: 'CREATED BY', align: 'start', value: 'created_by', },
                { text: 'REMARKS', align: 'start', value: 'remarks', },
                { text: 'STATUS', align: 'start', value: 'status', },
                { text: 'BOOKED TOTAL', align: 'end', value: 'booked_total', },
                { text: 'INVOICED TOTAL', align: 'end', value: 'invoiced_total', },
                { text: 'NOT INVOICED TOTAL', align: 'end', value: 'not_invoiced_total', },
                { text: 'CANCELED TOTAL', align: 'end', value: 'canceled_total', },
                { text: 'FULFILLMENT RATE', align: 'end', value: 'fulfillment_rate', },
                { text: 'ACTIONS', align: 'center', value: 'actions', },
            ],

            view_items: [],
            view_total_booked: 0,
            view_total_invoiced: 0,

            customer_id: null,
            status_id: -1,
            viewSalesOrderId: 0,

            cards: [
                { title: 'Booked Total (C)', icon: 'mdi-cart', icon_class: 'total-amount-booked', cols: 6, sm: 6, md: 3, lg: 3, xl: 3, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'total_booked_total' },
                { title: 'Invoiced Total (C)', icon: 'mdi-cart', icon_class: 'total-amount', cols: 6, sm: 6, md: 3, lg: 3, xl: 3, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'total_current_invoiced_total' },
                { title: 'Not Invoiced Total (C)', icon: 'mdi-cart', icon_class: 'total-customer', cols: 6, sm: 6, md: 3, lg: 3, xl: 3, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'total_current_not_invoiced_total' },
                { title: 'FR (C)', icon: 'mdi-cart', icon_class: 'total-customer', cols: 6, sm: 6, md: 3, lg: 3, xl: 3, prefix: '', suffix: '', filter: 'percentTwoDecimals', value: 'fulfillment_rate' },
            ],

            cards2: [
                { title: 'Canceled Total (C)', icon: 'mdi-cart', icon_class: 'total-customer', cols: 6, sm: 6, md: 4, lg: 4, xl: 4, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'total_current_canceled_total' },
                { title: 'Invoiced Total (P)', icon: 'mdi-cart', icon_class: 'total-customer', cols: 6, sm: 6, md: 4, lg: 4, xl: 4, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'total_previous_invoiced' },
                { title: 'Total Sales (C)', icon: 'mdi-cart', icon_class: 'total-customer', cols: 6, sm: 6, md: 4, lg: 4, xl: 4, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'total_sales' },
            ],

            cards3: [
                { title: 'Invoiced Total - Printed (C)', icon: 'mdi-cart', icon_class: 'total-amount', cols: 6, sm: 6, md: 3, lg: 3, xl: 3, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'total_current_invoiced_printed_total' },
                { title: 'Invoiced Total - Not Printed (C)', icon: 'mdi-cart', icon_class: 'total-amount', cols: 6, sm: 6, md: 3, lg: 3, xl: 3, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'total_current_invoiced_not_printed_total' },
                { title: 'Invoiced Total - Printed (P)', icon: 'mdi-cart', icon_class: 'total-customer', cols: 6, sm: 6, md: 3, lg: 3, xl: 3, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'total_previous_invoiced_printed' },
                { title: 'Invoiced Total - Not Printed (P)', icon: 'mdi-cart', icon_class: 'total-customer', cols: 6, sm: 6, md: 3, lg: 3, xl: 3, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'total_previous_invoiced_not_printed' },
            ],

            totals: {
                total_booked_total: 0,
                total_current_invoiced_total: 0,
                total_current_invoiced_printed_total: 0,
                total_current_invoiced_not_printed_total: 0,
                total_current_not_invoiced_total: 0,
                total_current_canceled_total: 0,
                total_previous_invoiced: 0,
                total_previous_invoiced_printed: 0,
                total_previous_invoiced_not_printed: 0,
                total_sales: 0,
                fulfillment_rate: 0,
            },

            dialog: {
                view_so: false,
            },

            sort_option: [
                { text: 'Customer', value: 'company_name' },
                { text: 'Fulfillment Rate', value: 'fulfillment_rate' },
                { text: 'Booked Orders Count', value: 'booked_orders_count' },
                { text: 'Latest SO Date', value: 'latest_so_date' },
                { text: 'Booked Total', value: 'booked_total' },
                { text: 'Invoiced Total', value: 'invoiced_total' },
            ],
            sort_direction: [
                { text: 'Smallest/Oldest', value: 'asc' },
                { text: 'Largest/Newest', value: 'desc' },
            ],
            has_booked_orders: [
                { text: 'Include All', value: -1 },
                { text: 'Orders with Amount', value: 1 },
                { text: 'Orders without Amount', value: 0 },
            ],
            sales_aftersales: [
                { text: 'All', value: -1 },
                { text: 'Sales', value: 0 },
                { text: 'Aftersales', value: 1 },
            ],
            invoiced_total_amount_breakdown: {
                dialog: false,
                list: [],
                headers: [
                    { text: 'Month', value: 'formatted_date' },
                    { text: 'Invoiced Total', align: 'end', value: 'total_amount' },
                    { text: 'FR Share', align: 'end', value: 'fulfillment_rate' },
                ],
            },

            dropdowns: {
                clusters: [],
            },
        }
    },
    mounted() {
        this.$store.dispatch('itemselection')
        this.$store.dispatch('getAllcustomersSelection', { include_mother: 0 })
        this.$store.dispatch('getAllsalesmanSelection')
        this.$store.dispatch('getAffliatecustomer')
        this.$store.dispatch('getallcustomerCategory')
        this.$store.dispatch('getAllcustomerClassificationSelection')
        this.$store.dispatch('getClusterSelection')
        // this.getSalesman()
    },
    computed: {
        ...mapGetters([
            'GET_AFFLIATE_CUSTOMER',
            'GET_CUSTOMERS_SELECTION',
            'GET_SALESMAN_SELECTION',
            'GET_CUSTOMER_CATEGORY_SELECTION',
            'GET_CLASSIFICATIONS_SELECTION',
            'GET_CLUSTER_SELECTION',
            'GET_ITEM_SELECTION',
            'USERACCOUNT_LOAD',
            'SALES_ORDERS',
            'PACKING_LISTS'
        ]),
    },
    methods: {
        viewSalesOrder(id) {
            this.viewSalesOrderId = id
        },
        closeView() {
            this.viewSalesOrderId = 0
        },
        closeDialog() {
            this.dialog.view_so = false
            this.view_items = []
            this.$emit('closeView');
        },
        // getSalesman(){
        //     if(this.fromDms){
        //         this.$store.dispatch('getAllsalesmanSelectionFilter',{
        //             reports_dms: true,
        //             salesmanHeadId: this.USERACCOUNT_LOAD.id
        //         })
        //         this.$store.dispatch('getAllcustomersSelectionFilter',{
        //             reports_dms: true,
        //             salesmanHeadId: this.USERACCOUNT_LOAD.id
        //         })
        //     }else{
        //         this.$store.dispatch('getAllsalesmanSelection')
        //         this.$store.dispatch('getAllcustomersSelection')
        //     }

        // },
        async list(exportToExcel = 0) {
            this.loaded = false
            this.dataTable_main.loading = true
            this.dataTable_main.items = []

            this.totals.total_booked_total = 0.00
            this.totals.total_current_invoiced_total = 0.00
            this.totals.total_current_invoiced_printed_total = 0.00
            this.totals.total_current_invoiced_not_printed_total = 0.00
            this.totals.total_current_not_invoiced_total = 0.00
            this.totals.total_current_canceled_total = 0.00
            this.totals.total_previous_invoiced = 0.00
            this.totals.total_previous_invoiced_printed = 0.00
            this.totals.total_previous_invoiced_not_printed = 0.00
            this.totals.fulfillment_rate = 0.00
            this.totals.total_sales = 0.00
            this.invoiced_total_amount_breakdown.list = []

            await this.$store.dispatch('salesReportsPost', {
                item_id: this.filters.item,
                date_from: this.filters.date_from,
                date_to: this.filters.date_to,
                url: 'getBookedVsInvoicedOrders',
                mother_customer: this.filters.mother_customers,
                customer_id: this.filters.customers,
                salesman_id: this.filters.salesman,
                customer_category_id: this.filters.customer_category,
                customer_classification_id: this.filters.customer_classification,
                customer_cluster_id: this.filters.customer_cluster,
                has_booked_orders: this.filters.has_booked_orders,
                sales_aftersales: this.filters.sales_aftersales,
                fulfillment_rate_range: this.filters.fulfillment_rate_range,
                statuses: this.SALES_ORDERS.STATUS,
                export: exportToExcel == 1 ? 1 : 0,
            }).then(response => {
                let data = response.data
                this.dataTable_main.total = data.current.length

                if (exportToExcel == 1) {
                    const workbook = new this.$exceljs.Workbook()
                    const current = workbook.addWorksheet(`Current`)
                    const previous = workbook.addWorksheet(`Prev PL`)

                    let columns = [
                        { header: 'SO#', key: 'order_num' },
                        { header: 'REMARKS', key: 'remarks' },
                        { header: 'CUSTOMER', key: 'company_name' },
                        { header: 'SALESMAN', key: 'salesman' },
                        { header: 'SO DATE', key: 'created_at' },
                        { header: 'PL DATE', key: 'pl_date' },
                        { header: 'PREP DATE', key: 'prepare_date' },
                        { header: 'DEL DATE', key: 'delivery_date' },
                        { header: 'STATUS', key: 'status' },
                        { header: 'BOOKED TOTAL', style: { numFmt: '#,##0.00' }, key: 'booked_total' },
                        { header: 'INVOICED TOTAL', style: { numFmt: '#,##0.00' }, key: 'invoiced_total' },
                        { header: 'INVOICED TOTAL - PRINTED', style: { numFmt: '#,##0.00' }, key: 'invoiced_printed_total' },
                        { header: 'INVOICED TOTAL - NOT PRINTED', style: { numFmt: '#,##0.00' }, key: 'invoiced_not_printed_total' },
                        { header: 'NOT INVOICED TOTAL', style: { numFmt: '#,##0.00' }, key: 'not_invoiced_total' },
                        { header: 'CANCELED TOTAL', style: { numFmt: '#,##0.00' }, key: 'canceled_total' },
                        { header: 'FULFILLMENT RATE', style: { numFmt: '0.00%' }, key: 'fulfillment_rate' },
                    ]

                    current.columns = columns
                    previous.columns = columns.filter(e => {
                        return !['booked_total', 'not_invoiced_total', 'canceled_total', 'fulfillment_rate'].includes(e.key)
                    })

                    current.addRows(data.current)
                    previous.addRows(data.previous)

                    workbook.xlsx.writeBuffer().then((data) => {
                        const blob = new Blob([data], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
                        });
                        this.$filesaver.saveAs(
                            blob,
                            `Booked Vs Invoiced ${this.filters.date_from} - ${this.filters.date_to}.xlsx`
                        );
                    });
                } else {
                    this.dataTable_main.items = data.current

                    this.totals.total_booked_total = data.booked_total
                    this.totals.total_current_invoiced_total = data.current_invoiced_total
                    this.totals.total_current_invoiced_printed_total = data.current_invoiced_printed_total
                    this.totals.total_current_invoiced_not_printed_total = data.current_invoiced_not_printed_total
                    this.totals.total_current_not_invoiced_total = data.current_not_invoiced_total
                    this.totals.total_current_canceled_total = data.current_canceled_total
                    this.totals.fulfillment_rate = data.current_invoiced_total / data.booked_total
                    this.totals.total_previous_invoiced = data.previous_invoiced_total
                    this.totals.total_previous_invoiced_printed = data.previous_invoiced_printed_total
                    this.totals.total_previous_invoiced_not_printed = data.previous_invoiced_not_printed_total
                    this.totals.total_sales = this.totals.total_current_invoiced_total + this.totals.total_previous_invoiced

                    new Promise((resolve) => {
                        let data_union = data.previous
                        let grouped = Object.groupBy(data_union, ({ so_date }) => ([null, 'Invalid Date'].includes(so_date) ? '0000-00-00' : so_date).substr(0, 7))

                        Object.assign(grouped, {
                            [this.filters.date_from]: data.current
                        })

                        Object.keys(grouped).sort().forEach((e, i) => {
                            if (e != '0000-00') {
                                let total_amount = grouped[e].reduce((a, v) => a + parseFloat(v.invoiced_total), 0)
                                this.invoiced_total_amount_breakdown.list.push(
                                    Object.assign({}, {
                                        raw_date: e,
                                        formatted_date: this.$dayjs(e).format('MMMM YYYY'),
                                        total_amount: total_amount,
                                        fulfillment_rate: total_amount / this.totals.total_sales,
                                    })
                                )
                            }
                        })

                        resolve(true)
                    })

                    if (this.filters.sort_option !== null) {
                        if (this.filters.sort_direction == 'desc') {
                            switch (this.filters.sort_option) {
                                case 'company_name':
                                    this.dataTable_main.items = this.dataTable_main.items.sort(function (a, b) {
                                        return b.company_name.localeCompare(a.company_name)
                                    })
                                    break
                                case 'fulfillment_rate':
                                    this.dataTable_main.items = this.itedataTable_main.itemsms.sort(function (a, b) {
                                        return b.fulfillment_rate - a.fulfillment_rate
                                    })
                                    break
                                case 'booked_orders_count':
                                    this.dataTable_main.items = this.dataTable_main.items.sort(function (a, b) {
                                        return b.booked_orders_count - a.booked_orders_count
                                    })
                                    break
                                case 'latest_so_date':
                                    this.dataTable_main.items = this.dataTable_main.items.sort(function (a, b) {
                                        return b.latest_so_date.localeCompare(a.latest_so_date)
                                    })
                                case 'booked_total':
                                    this.dataTable_main.items = this.dataTable_main.items.sort(function (a, b) {
                                        return b.booked_total - a.booked_total
                                    })
                                case 'invoiced_total':
                                    this.dataTable_main.items = this.dataTable_main.items.sort(function (a, b) {
                                        return b.invoiced_total - a.invoiced_total
                                    })
                            }
                        } else {
                            switch (this.filters.sort_option) {
                                case 'company_name':
                                    this.dataTable_main.items = this.dataTable_main.items.sort(function (a, b) {
                                        return a.company_name.localeCompare(b.company_name)
                                    })
                                    break
                                case 'fulfillment_rate':
                                    this.dataTable_main.items = this.dataTable_main.items.sort(function (a, b) {
                                        return a.fulfillment_rate - b.fulfillment_rate
                                    })
                                    break
                                case 'booked_orders_count':
                                    this.dataTable_main.items = this.dataTable_main.items.sort(function (a, b) {
                                        return a.booked_orders_count - b.booked_orders_count
                                    })
                                    break
                                case 'latest_so_date':
                                    this.dataTable_main.items = this.dataTable_main.items.sort(function (a, b) {
                                        return a.latest_so_date.localeCompare(b.latest_so_date)
                                    })
                                case 'booked_total':
                                    this.dataTable_main.items = this.dataTable_main.items.sort(function (a, b) {
                                        return a.booked_total - b.booked_total
                                    })
                                case 'invoiced_total':
                                    this.dataTable_main.items = this.dataTable_main.items.sort(function (a, b) {
                                        return a.invoiced_total - b.invoiced_total
                                    })
                            }
                        }
                    }
                }
            })

            this.dataTable_main.loading = false
            this.loaded = true
        },
        async viewSODetails(customer_id, status_id = -1) {
            this.customer_id = customer_id
            this.status_id = status_id

            await this.$store.dispatch("salesReportsPost", {
                customer_id: [this.customer_id],
                date_from: this.filters.date_from,
                date_to: this.filters.date_to,
                status_filter: this.status_id,
                has_booked_orders: this.filters.has_booked_orders,
                sales_aftersales: this.filters.sales_aftersales,
                fulfillment_rate_range: this.filters.fulfillment_rate_range,
                details: 1,
                url: "getBookedVsInvoicedOrders",
            }).then(response => {
                this.view_items = response.data.current
                this.view_total_booked = response.data.booked_total;
                this.view_total_invoiced = response.data.current_invoiced_total;
                this.dialog.view_so = true;
            });
            this.dialog.view_so = true;
        },
        expandData(item) {
            this.dataTable_main_expanded.items = []

            if (typeof item != 'undefined') {
                let data = item.item
                this.customer_id = data.customer_id
            }

            this.$store.dispatch("salesReportsPost", {
                expand_pagination: this.dataTable_main_expanded.options,
                customer_id: [this.customer_id],
                date_from: this.filters.date_from,
                date_to: this.filters.date_to,
                status_filter: this.status_id,
                sales_aftersales: this.filters.sales_aftersales,
                details: 1,
                url: "getBookedVsInvoicedOrders",
            }).then(response => {
                this.dataTable_main_expanded.items = response.data.current
            });
        },
    },
    watch: {
        'filters.fulfillment_rate_range': {
            handler(val) {
                if (val[0] == '') {
                    this.filters.fulfillment_rate_range[0] = 0
                }

                if (val[1] == '') {
                    this.filters.fulfillment_rate_range[1] = 100
                }

                this.$forceUpdate()
            },
            deep: true,
        },
        'GET_CLUSTER_SELECTION': {
            handler(val) {
                this.dropdowns.clusters = JSON.parse(JSON.stringify(val))
                this.dropdowns.clusters.push({
                    text: 'NO CLUSTER',
                    value: 0,
                })
            },
            deep: true,
        },
    },
};
</script>
<style scoped>
.col {
    padding: 2px !important;
}

.card-body {
    padding: 3px !important;
}
.card-icon {
    height: 70px;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }

  .total-amount {
    background-color: #28a745;
  }

  .total-amount-booked {
    background-color: #1976d2;
  }

  .total-customer {
    background-color: #ffc107;
  }

  .card-details {
    height: 70px;
    padding-left: 9px;
    padding-top: 20px;
    line-height: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  .total-cards {
    margin: 4px;
  }

  label {
    margin-bottom: 0px !important;
  }

  ::v-deep .v-label{
    margin-bottom: 0px !important;
  }

  ::v-deep .v-input--selection-controls{
    margin-top: 10px !important;
    padding-top: 0px !important;
  }
</style>
