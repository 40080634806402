<template>
    <div class="tabs-body-color">
        <v-container fluid>
            <v-row class="pt-2">
                <v-col cols="6" class="d-flex no-block align-items-center">
                    <div class="btn-toolbar">
                        <!-- <v-btn class="ml-1 mb-2" @click="AddDialog()" v-if="this.USERACCOUNT_LOAD.super_admin == 1 || this.USERACCOUNT_LOAD.president == 1 || this.USERACCOUNT_LOAD.department_id == 5">
                            <v-icon>mdi-plus</v-icon>Add
                        </v-btn> -->
                    </div>
                </v-col>
            </v-row>
            <v-row>
              <HRSettingsTableComponent v-if="isActive_tab == 'hrSettings'"></HRSettingsTableComponent>
            </v-row>
        </v-container>
    
    </div>
</template>
<script>

import HRSettingsTableComponent from '@/views/main/modules/HR/Settings/HRSettingsTableComponent.vue';
import { mapGetters } from 'vuex';
export default {
    components: {
        HRSettingsTableComponent,
    },
    props:["isActive_tab"],
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters(['GET_HR_TAB_IS_ACTIVE','USERACCOUNT_LOAD'])
    },
    mounted() {
    },
    methods: {
        AddDialog(){
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", "Submit");
        },
    },
    
};
</script>
