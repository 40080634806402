<template>
    <v-container>
        <v-card >
            <v-card-text>
                <v-row>
                    <v-col lg12 class="d-flex justify-content-end">
                        <v-btn small @click="toggleDialog('CHECK_LIST_TEMPLATE', 'add')">
                            <v-icon>mdi-plus</v-icon> Add {{ TESTER_CHECK_LISTS.TAB }}
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col lg12 class="my-3">
                        <TesterCheckListTableComponent :tab="TESTER_CHECK_LISTS.TAB"></TesterCheckListTableComponent>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col lg12>
                        <v-dialog v-model="TESTER_CHECK_LISTS.DIALOGS.CHECK_LIST_TEMPLATE.VALUE" :retain-focus="false" persistent width="500" >
                            <v-card>
                                <v-card-title class="d-flex justify-content-between px-0">
                                    <h4 class="mx-3">{{ actions }} List Template</h4>
                                    <v-btn text @click="toggleDialog('CHECK_LIST_TEMPLATE')" fab><v-icon>mdi-close-circle</v-icon></v-btn>
                                </v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col lg="6">
                                            <v-text-field v-model="checList.fillables.name" label="Title" outlined dense v-if="[1,0].includes(TESTER_CHECK_LISTS.TAB)">

                                            </v-text-field>
                                        </v-col>
                                        <v-col lg="6">
                                            <v-text-field v-model="checList.fillables.code" label="Code" outlined dense v-if="[1,0].includes(TESTER_CHECK_LISTS.TAB)">

                                            </v-text-field>
                                        </v-col>
                                        <v-col lg="12">
                                            <v-text-field v-model="checList.fillables.description" label="Description" outlined dense v-if="[1,0].includes(TESTER_CHECK_LISTS.TAB)">

                                            </v-text-field>
                                        </v-col>

                                        <v-col lg="12" v-if="[2].includes(TESTER_CHECK_LISTS.TAB)">
                                            <v-text-field
                                                v-model="checList.placeholder"
                                                label="List"
                                                append-icon="mdi-plus"
                                                @keypress.enter="updateList(checList.placeholder,1)"
                                                @click:append="updateList(checList.placeholder,1)"
                                                outlined dense
                                                >

                                            </v-text-field>
                                        </v-col>
                                        <v-col>
                                           <v-card class="w-100" height="300" style="overflow-y: scroll;">
                                                <v-card-text >
                                                    <v-col class="min" cols="12">
                                                        <v-chip v-for="(item,index) in checList.list" :key="index" class="my-2 mr-2" label @click:close="updateList('',2,index)" close color="#F69F1A" outlined >{{item.name}}</v-chip>
                                                    </v-col>
                                                </v-card-text>
                                           </v-card>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-actions class="d-flex justify-content-end">
                                    <v-btn small @click="updateList('',3)"><v-icon>mdi-delete</v-icon>Clear All</v-btn>
                                    <v-btn small @click="actions == 'Add' ? submit() : update()" ><v-icon>mdi-plus</v-icon>{{ actions }}</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <TesterCheckListViewComponentVue></TesterCheckListViewComponentVue>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import TesterCheckListTableComponent from './TesterCheckListTableComponent.vue';
import Swal from 'sweetalert2';
import TesterCheckListViewComponentVue from './TesterCheckListViewComponent.vue';
export default {
    props:{
        tab:
        {
            default:()=>1,
            type:Number
        }
    },
    components:{
        TesterCheckListTableComponent,
        TesterCheckListViewComponentVue
    },
    computed:{
        ...mapGetters([
            'TESTER_CHECK_LISTS',
            'GET_REFRESH',
            'GET_CHECK_LIST',
            'GET_TABS',
        ]),

        actions(){
            // console.log(this.TESTER_CHECK_LISTS)
            if(this.GET_CHECK_LIST == 'add'){
                this.getData()
                this.clearList()
                return 'Add'
            }
            else if(this.GET_CHECK_LIST == 'edit'){
                this.getData()
                return 'Edit'
            }
        }
    },
    data:()=>({
        checList:{
            fillables:{
                name:'',
                description:'',
                code:''
            },
            list:[],
            placeholder:'',
            loaded: true
        },
        urls:['add-tester-lists','add-list','qa-check-list-add']
    }),
    methods:{
        toggleDialog(dialog, action){
            if(!this.TESTER_CHECK_LISTS.DIALOGS[dialog]){
                return false

            }

            if(!this.TESTER_CHECK_LISTS.DIALOGS[dialog].VALUE){
                Object.assign(this.$data, this.$options.data.call(this));
                this.clearList()

            }

            this.$store.commit(this.TESTER_CHECK_LISTS.DIALOGS[dialog].mutations,!this.TESTER_CHECK_LISTS.DIALOGS[dialog].VALUE)
            this.$store.commit('GET_CHECK_LIST_ACTION', action)
            this.clearList()
        },

        updateList(name,action = '',index = 0){

            if(!action){
                Swal.fire('Error!',' - Check List id not found','error')
                return false
            }

            this.checList.placeholder = ''

            switch(action){
                case 1:
                    if(!name){
                        return
                    }
                    this.checList.list.push({name:name});
                    break;
                case 2:
                    this.checList.list = this.checList.list.filter((e,i)=>i!=index);
                    break;
                case 3:
                    this.checList.list = [];
                    break;
            }
        },

        async submit(){
            Swal.fire({
                title:'Add',
                text:'- Are you sure you want to Submit?',
                icon: 'question',
                showCancelButton:true,
                reverseButtons:true
            }).then(action=>{
                if(action.isConfirmed){
                    this.$store.dispatch('servicePost',{
                        url:this.urls[this.TESTER_CHECK_LISTS.TAB],
                        ...this.checList
                    }).then(response=>{
                        Swal.fire('Success!','','success')
                        this.toggleDialog('CHECK_LIST_TEMPLATE')
                    }).catch(err=>{
                        Swal.fire('Error!',err.response.data.message,'error')
                    })
                }
            })
        },
        getData(){
            this.checList.fillables = this.TESTER_CHECK_LISTS.SELECTED
            this.checList.loaded = true
        },
        update(){
            Swal.fire({
                title:'Update',
                text:'- Are you sure you want to Update?',
                icon: 'question',
                showCancelButton:true
            }).then(action=>{
                if(action.isConfirmed){
                    this.$store.dispatch('servicePost',{
                        url:'update-tester-lists',
                        fillables: this.checList.fillables,
                        list: this.checList.list
                    }).then(response=>{

                        Swal.fire('Success!','','success')
                        this.toggleDialog('CHECK_LIST_TEMPLATE')
                    }).catch(err=>{
                        Swal.fire('Error!',err.response.data.message,'error')
                    })
                }
            })
        },
        clearList(){
            this.checList ={
            fillables:{
                name:'',
                description:'',
                code:''
            },
            list:[],
            placeholder:'',
            loaded:true,
            }
        }
    },
    watch:{
        'TESTER_CHECK_LISTS.ACTION':{
            handler(val){
                if(val == 'add' && this.TESTER_CHECK_LISTS.TAB == 2){
                    this.store.dispatch('serviceGet',{
                        url:'get-check-lists'
                    }).then(response=>{
                        this.checList.list = response.data
                    })
                }
            }
        },
        deep:true
    }


}
</script>

<style>

</style>
