<template>
    <v-form ref="form" v-model="valid" lazy-validation>
        <v-card @dragover="dragover" @dragleave="dragleave" @drop="drop" class="dropzone-container no-gutters" @paste="copyImageToClipboard">
            <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                    <span class="text-h5">{{ dialog_title }}</span>
                <v-row class="m-0">
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                            color="gray"
                            class="float-right"
                            icon
                            text
                            small
                            @click="closeDialog()"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-container  style="overflow-x: hidden;overflow-y: scroll;">
                <v-row class="no-gutters">
                    <v-col cols="12" sm="4">
                        <v-card>
                            <v-row class="d-flex justify-content-end">
                                <div class="float-right pr-2">
                                    <CameraLayout :disabled="ACTION === 'View'"></CameraLayout>
                                </div>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="text-center">
                                    <v-container  :class="have_img && !GET_OPEN_CAMERA?'position-relative fluid d-flex justify-content-center':'d-none'">
                                        <img
                                            id="image"
                                            class="img-thumbnail text-center" width="50%" height="50%"  eager contain
                                        />
                                        <div  :class="'position-relative fluid d-flex justify-content-end'">
                                            <v-btn
                                                :class="disabled_view?'d-none position-absolute':'position-absolute'"
                                                color="white"
                                                icon
                                                text
                                                small
                                                @click="clearImage()"
                                            >
                                                <v-icon color="black">mdi-close-circle</v-icon>
                                            </v-btn>
                                        </div>
                                    </v-container>
                                    <v-icon style="font-size:50px;" :class="have_img || GET_OPEN_CAMERA?'d-none':'d-block'">mdi-upload</v-icon>
                                    <p :class="have_img || GET_OPEN_CAMERA?'d-none':'d-block'"><span>Drag & Drop <p >or <label for="upload-file" style="color:blue;cursor:pointer;">browse</label> </p></span></p>
                                    <input type="file" id="upload-file" class="d-none" @change="onChange"
                                    ref="file"
                                    accept=".jpg,.jpeg,.png">
                                </v-col>
                            </v-row>
                            <v-row class="no-gutters px-2">
                                <v-col cols="12">
                                    <v-col cols="12">
                                        <v-radio-group
                                            v-model="form.consume_type"
                                            label="Consume Type *"
                                            dense
                                            row
                                            @change="form.consume_type == 2 ? form.service = 1 : isService"
                                        >
                                            <v-radio
                                                v-for="n in consume_types"
                                                :key="n.value"
                                                :label="n.text"
                                                :value="n.value"
                                            ></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                    <v-radio-group
                                        v-model="form.item_category"
                                        v-if="form.consume_type !== 2"
                                        label="Item Category *"
                                        dense
                                        row
                                    >
                                        <v-radio
                                            v-for="n in item_category"
                                            :key="n.value"
                                            :label="n.text"
                                            :value="n.value"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row class="no-gutters">
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="form.item_name"
                                        :disabled="disabled_view"
                                        :rules="required"
                                        label="Item Name *"
                                        dense
                                        outlined
                                        @keypress="forceUpperCaseText($event)"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="no-gutters" v-if="form.consume_type !== 2">
                                <v-col cols="12">
                                    <v-autocomplete
                                        v-model="form.admin_classification_id"
                                        :items="GET_ADMIN_CLASSIFICATION_SELECTION"
                                        :disabled="disabled_view"
                                        :rules="required"
                                        :search-input.sync="search_classification"
                                        item-text="text"
                                        item-value="value"
                                        label="Classification *"
                                        auto-select-first
                                        dense
                                        outlined
                                        required
                                        @change="changeCategory"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                    <v-autocomplete
                                        v-model="form.admin_classification_sub_id"
                                        :items="subClass"
                                        :disabled="disabled_view"
                                        item-text="text"
                                        item-value="value"
                                        label="Sub Classification (Optional)"
                                        auto-select-first
                                        dense
                                        outlined
                                        required
                                    ></v-autocomplete>
                                </v-col>
                                <v-col>
                                    <v-autocomplete
                                        v-model="form.admin_category_id"
                                        :items="GET_ADMIN_CATEGORY_SELECTION"
                                        item-text="text"
                                        item-value="value"
                                        label="Category"
                                        auto-select-first
                                        dense
                                        outlined
                                        required
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row class="no-gutters" v-if="form.item_category == 1 && form.consume_type !== 2">
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="form.model_number"
                                        :disabled="disabled_view"
                                        label="Model Number (Optional)"
                                        dense
                                        outlined
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <span v-if="form.consume_type !== 2">
                                <v-row class="no-gutters" >
                                    <v-col cols="12">
                                        <v-combobox
                                            v-model="form.brand_id"
                                            :items="GET_ADMIN_BRAND"
                                            :disabled="disabled_view"
                                            item-text="text"
                                            item-value="value"
                                            label="Brand (Optional)"
                                            auto-select-first
                                            clearable
                                            dense
                                            outlined
                                            @change="checkBrandValue"
                                        ></v-combobox>
                                    </v-col>
                                </v-row>
                                <v-row class="no-gutters">
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="form.life_span"
                                            :disabled="disabled_view"
                                            label="Life Span (Optional)"
                                            suffix="Day(s)"
                                            auto-select-first
                                            dense
                                            outlined
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </span>
                            <v-row class="no-gutters">
                                <v-col cols="12">
                                    <v-textarea v-model="form.remarks" outlined rows="4" no-resize label="Remarks *" :disabled="disabled_view" :rules="required"></v-textarea>
                                </v-col>
                            </v-row>
                            <v-row class="no-gutters" v-if="form.consume_type !== 2" >
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="form.key_data1"
                                        :disabled="disabled_view"
                                        label="Key Data 1 (Optional)"
                                        auto-select-first
                                        dense
                                        outlined
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="form.key_data2"
                                        :disabled="disabled_view"
                                        label="Key Data 2 (Optional)"
                                        auto-select-first
                                        dense
                                        outlined
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="no-gutters">
                                <v-col cols="12">
                                    <v-autocomplete
                                        v-model="form.service"
                                        :items="[
                                            { value: 0, text: 'Regular' },
                                            { value: 1, text: 'Service' },
                                        ]"
                                        item-text="text"
                                        item-value="value"
                                        label="Item Type"
                                        auto-select-first
                                        dense
                                        outlined
                                        required
                                        readonly
                                    >
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row class="no-gutters" v-if="form.consume_type !== 2">
                                <v-col cols="3" class="px-1">
                                    <v-checkbox
                                        v-model="form.required_serial"
                                        :disabled="disabled_view"
                                        class="m-0 ml-auto p-0"
                                        label="Required Serial"
                                        >
                                    </v-checkbox>
                                </v-col>
                                <v-spacer></v-spacer>
                                <!-- <v-col cols="3" class="d-flex align-end justify-end px-1">
                                    <v-checkbox
                                        v-model="form.is_labor"
                                        :disabled="disabled_view"
                                        class="m-0 ml-auto p-0"
                                        label="Label"
                                    >
                                    </v-checkbox>
                                </v-col> -->
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="8" class="pl-1">
                        <!-- bg fill-height grid-list-md text-xs-center  -->
                        <v-container>
                            <v-layout row wrap align-center>
                                <v-flex>
                                    <v-container fluid>
                                        <v-flex>
                                            <v-row class="pa-0 no-gutters">
                                                <v-container fluid>
                                                    <v-row class="pa-2">
                                                        <v-card width="100%">
                                                            <v-container class="pa-2">
                                                                <v-row class="d-flex justify-content-end pa-0">
                                                                    <v-btn icon text @click="addVendorsDialog()" :disabled="disabled_view">
                                                                        <v-icon color="primary">mdi-plus-circle</v-icon>
                                                                    </v-btn>
                                                                </v-row>
                                                            </v-container>
                                                            <v-simple-table
                                                                fixed-header
                                                                height="250px"
                                                                width="100%"
                                                            >
                                                                <thead>
                                                                <tr>
                                                                    <th  style="font-size:12px;" class="text-center">VENDOR NAME</th>
                                                                    <!-- <th  style="font-size:12px;" class="text-center">PARTICULARS</th> -->
                                                                    <th  style="font-size:12px;" class="text-center">UOM</th>
                                                                    <th  style="font-size:12px;" class="text-center">MOQ</th>
                                                                    <th  style="font-size:12px;" class="text-center">PRICE</th>
                                                                    <th  style="font-size:12px;" class="text-center">ACTION</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="(vendors,i) in form.vendor" :key="i">
                                                                        <td><v-autocomplete
                                                                            v-model="vendors.vendor_id"
                                                                            :items="GET_ADMIN_SUPPLIER_SELECTION"
                                                                            :disabled="disabled_view"
                                                                            item-text="text"
                                                                            item-value="value"
                                                                            auto-select-first
                                                                            outlined
                                                                            required
                                                                            dense
                                                                            ></v-autocomplete>
                                                                        </td>
                                                                        <!-- <td>
                                                                            <v-text-field outlined dense v-model="vendors.particulars" :disabled="disabled_view"></v-text-field>
                                                                        </td> -->
                                                                        <td>
                                                                            <v-autocomplete
                                                                                v-model="vendors.uom_id"
                                                                                :items="GET_ADMIN_UOM_SELECTION"
                                                                                :disabled="disabled_view"
                                                                                item-text="text"
                                                                                item-value="value"
                                                                                auto-select-first
                                                                                dense
                                                                                outlined
                                                                                required
                                                                            >
                                                                            </v-autocomplete>
                                                                        </td>
                                                                        <td>
                                                                            <v-text-field
                                                                                v-model="vendors.moq"
                                                                                :disabled="disabled_view"
                                                                                label="MOQ"
                                                                                dense
                                                                                outlined
                                                                                @keypress="countingNumberOnly"
                                                                            >
                                                                            </v-text-field>
                                                                        </td>
                                                                        <td>
                                                                            <v-text-field
                                                                                v-model="vendors.price"
                                                                                dense
                                                                                outlined
                                                                                @input="changeCategory"
                                                                            >
                                                                            </v-text-field>
                                                                        </td>
                                                                        <td class="text-center">
                                                                            <v-btn icon text  @click="removeVendor(i)" :disabled="disabled_view">
                                                                                <v-icon small color="red">mdi-minus-circle</v-icon>
                                                                            </v-btn>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </v-simple-table>
                                                        </v-card>
                                                    </v-row>
                                                    <hr data-content="Price History" class="hr-text ma-7">
                                                    <v-row class="pa-2">
                                                        <v-card width="100%">
                                                            <v-container fluid>
                                                                <v-row class="pa-2">
                                                                </v-row>
                                                            </v-container>
                                                            <v-simple-table
                                                                fixed-header
                                                                height="250px"
                                                            >
                                                                <thead>
                                                                    <tr>
                                                                        <th  style="font-size:12px;" class="text-center">VENDOR</th>
                                                                        <th  style="font-size:12px;" class="text-center">ORIGIN</th>
                                                                        <th  style="font-size:12px;" class="text-center">CHANGED DATE</th>
                                                                        <th  style="font-size:12px;" class="text-center">UPDATED BY</th>
                                                                        <!-- <th  style="font-size:12px;" class="text-center">QTY</th> -->
                                                                        <th  style="font-size:12px;" class="text-center">PRICE</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="(vendors,i) in price_history" :key="i">
                                                                        <td class="text-center">
                                                                            <span>{{ GET_ADMIN_SUPPLIER_SELECTION.find(e=>e.value == vendors.vendor_id)?.text }}</span>
                                                                        </td>
                                                                        <td class="text-center">
                                                                            {{ vendors.admin_po_num ? `PO(${vendors.admin_po_num})` : '' }}
                                                                            </td>
                                                                        <td class="text-center">
                                                                        <span>
                                                                            {{ vendors.discard_at ? formattedDate(new Date(vendors.discard_at)) : '' }}
                                                                            {{ vendors.discard_at ? formattedTime(new Date(vendors.discard_at)) : '' }}
                                                                        </span>
                                                                        </td>
                                                                        <td class="text-center">
                                                                            <span>
                                                                                {{ vendors.updated_by }}
                                                                            </span>
                                                                        </td>
                                                                        <td class="text-center">
                                                                        <span>{{ vendors.price | currency}}</span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </v-simple-table>
                                                        </v-card>
                                                    </v-row>
                                                </v-container>
                                            </v-row>
                                        </v-flex>
                                    </v-container>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions class="text-center">
                <v-row class="ma-1">
                    <v-col cols="12" >
                        <v-btn
                            v-if="actions != 'View'"
                            :disabled="disabled_view || form.vendor.length == 0"
                            class="float-center"
                            @click="createOrUpdateAccountability()"
                        >
                            <span>{{ actions }}</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-form>
</template>
<script>
    import Swal from "sweetalert2";
    import { mapGetters } from "vuex";
    // import images from '../../../../../assets/ingcoph_bg/ingcoph_logo_alt.jpg'
    import CameraLayout from '@/views/main/layouts/Camera/CameraComponent.vue';
    import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent'
    export default {
        mixins: [SharedFunctionsComponent],
        data() {
            return {
                valid:true,
                // vendor:[],
                dialog_title:"",
                form: {
                    id:'',
                    vendor:[],
                    model_number: "",
                    // serial_number: "",
                    item_name: "",
                    brand_id: "",
                    uom_id: "",
                    admin_classification_id:"",
                    admin_classification_sub_id:"",
                    // sub_asset_num:"",
                    quantity:0,
                    price: 0,
                    priceFormatted: '',
                    image_path:null,
                    remarks:'',
                    life_span:365,
                    item_trash:[],
                    key_data1: '',
                    key_data2: '',
                    is_labor: false,
                    item_category: undefined,
                    consume_type: undefined,
                    admin_category_id: '',
                    service: 0,
                    required_serial: 0,
                },
                vendors: {
                    vendor_id: null,
                    particulars: '',
                    uom_id: null,
                    price: '',
                    moq: 1,
                },
                price_history:[],
                search_classification:'',
                subClass:[],
                disabled_view: false,
                action:'',
                isDragging: false,
                files: [],
                have_img:false,
                duplicateVendorCollections:[],
                addVendorsDialogVisible: false,
                vendorDialogAction: '',
                cachedVendors: undefined,
                item_category: [
                    { value: 1, text: 'Asset' },
                    { value: 2, text: 'Consumable' },
                ],
                consume_types: [
                    { value: 1, text: 'Goods' },
                    { value: 2, text: 'Services' },
                ],
                required: [ v => !!v || 'This field is required' ],
            };
        },
        components: {
            CameraLayout
        },
        computed: {
            ...mapGetters([
                "ACTION",
                "DIALOGS",
                "GET_ADMIN_ITEM_DISPATCH",
                "GET_LIST_DEPARTMENT",
                "GET_ADMIN_UOM_SELECTION",
                "GET_ADMIN_CLASSIFICATION_SELECTION",
                "GET_ADMIN_SUPPLIER_SELECTION",
                "GET_EDIT_ADMIN_ITEMS",
                "GET_NEW_ADMIN_ITEM",
                "GET_ADMIN_BRAND",
                "GET_CAMERA_CAPTURE",
                "GET_OPEN_CAMERA",
                "GET_ASSET_NUM",
                "GET_ADMIN_CATEGORY_SELECTION",
                "GET_ADMIN_ITEM_SERVICE",
                "ITEMS_DIALOG",
                "SELECTED"
            ]),
            actions() {
                if(this.ITEMS_DIALOG){
                    this.disabled_view = false;
                    this.dialog_title = 'CREATE ITEM'
                    this.action = 'AdminCreateAdminItem'
                    return "Submit";
                }
                if (this.ACTION == "Submit") {
                    this.disabled_view = false;
                    this.dialog_title = 'CREATE ITEM'
                    this.action = this.GET_ADMIN_ITEM_DISPATCH.add
                    return "Submit";
                } else if (this.ACTION == "Update") {
                    this.disabled_view = false;
                    this.action = this.GET_ADMIN_ITEM_DISPATCH.update;
                    this.dialog_title = 'UPDATE ITEM'
                    this.getEditAdminItems()
                    return "Update";
                } else if (this.ACTION == "View") {
                    this.dialog_title = 'VIEW ITEM'
                    this.getEditAdminItems()
                    this.disabled_view = true;
                    return "View";
                }
            },
            formattedPrice() {
                return this.thousandSeparator(this.vendors.price);
            }
        },
        mounted() {},
        methods: {
            // amount(i){
            //     let num = this.form.vendor[i].price.replace(",","");
            //     this.form.vendor[i].price =  this.thousandSeprator(num)
            // },
            formatPrice() {
                this.form.priceFormatted = this.thousandSeparator(this.form.price);
            },
            thousandSeparator(num) {
                const currencyFormatter = Intl.NumberFormat('en-PH', {
                    style: 'currency',
                    currency: 'PHP'
                });
                const formatted = currencyFormatter.format(num);

                return formatted;
            },
            addVendorsDialog(){
                // this.vendorDialogAction = 'Add'
                // this.addVendorsDialogVisible = true;
                this.form.vendor.push({
                    vendor_id: null,
                    particulars: '',
                    uom_id: null,
                    price: '',
                    moq: 1,
                });
            },
            closeAddVendorsDialog(){
                this.vendors.vendor_id = '';
                this.vendors.particulars = '';
                // this.vendors.brand = '';
                this.vendors.price = '';
                this.vendors.uom_id = '';
                this.vendors.moq = '';
                this.vendorDialogAction = '';
                this.disabled_view = false;
                this.addVendorsDialogVisible = false;
                this.cachedVendors = undefined;
            },
            // addVendors(){
            //     this.form.vendor.push({
            //         // id:'',
            //         vendor_id:'',
            //         particulars:'',
            //         brand_id:'',
            //         // quantity:0,
            //         price:'',
            //         uom_id:''
            //     });
            // },
            addVendors() {
                let checker = [

                    [!this.vendors.price,' Please Enter Price', 'Price is required'],
                    [!this.vendors.uom_id,' Please Select UOM', 'Unit of Measurement (UOM) is required'],
                    [!this.vendors.moq,' Please Enter MOQ', 'Minimum Order Quantity (MOQ) is required'],
                ]

                if(checker.find(e=>e[0] == true)){
                    Swal.fire(`${checker.find(e=>e[0] == true)[1]}`,`- ${checker.find(e=>e[0] == true)[2]}`,'warning')
                    return
                }
                if (this.vendorDialogAction === 'Update') {
                    const indexToUpdate = this.findIndexById(this.vendors.id);

                    if (indexToUpdate !== -1) {
                        this.form.vendor[indexToUpdate] = {
                            id: this.vendors.id,
                            vendor_id: this.vendors.vendor_id,
                            particulars: this.vendors.particulars,
                            // brand: this.vendors.brand,
                            quantity: 0,
                            price: this.vendors.price,
                            uom_id: this.vendors.uom_id,
                            moq: this.vendors.moq,
                        };
                    }
                } else {
                    this.form.vendor.push({
                        id: '',
                        vendor_id: this.vendors.vendor_id,
                        particulars: this.vendors.particulars,
                        // brand: this.vendors.brand,
                        quantity: 0,
                        price: this.vendors.price,
                        uom_id: this.vendors.uom_id,
                        moq: this.vendors.moq,
                    });

                }
                this.closeAddVendorsDialog();
            },
            findIndexById(id) {
                return this.form.vendor.findIndex(entry => entry.id === id);
            },
            removeVendor(i){
                this.form.item_trash.push({ id:this.form.vendor[i].id,});
                this.form.vendor.splice(i,1);
            },
            async getEditAdminItems() {
                if (Object.keys(this.GET_EDIT_ADMIN_ITEMS).length !== 0) {
                    this.form.id = await this.GET_EDIT_ADMIN_ITEMS.id;
                    this.form.vendor = await this.GET_EDIT_ADMIN_ITEMS.item_vendor_price_lists;
                    this.form.vendor.forEach(element => {
                        element.price = parseFloat(element.price).toFixed(2);
                    });
                    this.cachedVendors = this.GET_EDIT_ADMIN_ITEMS.item_vendor_price_lists;
                    this.form.model_number = this.GET_EDIT_ADMIN_ITEMS.model_number;
                    // this.form.serial_number = this.GET_EDIT_ADMIN_ITEMS.serial_number;
                    this.form.item_name = await this.GET_EDIT_ADMIN_ITEMS.item_name;
                    this.form.brand_id = this.GET_ADMIN_BRAND.filter(brand => brand.value == this.GET_EDIT_ADMIN_ITEMS.brand_id)[0];
                    this.form.admin_classification_id = await this.GET_EDIT_ADMIN_ITEMS.admin_classification_id;
                    this.form.admin_classification_sub_id = await this.GET_EDIT_ADMIN_ITEMS.admin_classification_sub_id;
                    this.form.admin_category_id = await this.GET_EDIT_ADMIN_ITEMS.admin_category_id;
                    // this.form.sub_asset_num = await this.GET_EDIT_ADMIN_ITEMS.sub_asset_num;
                    this.form.quantity = await this.GET_EDIT_ADMIN_ITEMS.quantity;
                    this.form.price = await this.GET_EDIT_ADMIN_ITEMS.price;
                    this.form.image_path = await '';
                    this.form.remarks = await this.GET_EDIT_ADMIN_ITEMS.remarks;
                    this.form.key_data1 = await this.GET_EDIT_ADMIN_ITEMS.key_data1;
                    this.form.key_data2 = await this.GET_EDIT_ADMIN_ITEMS.key_data2;
                    this.form.is_labor = await this.GET_EDIT_ADMIN_ITEMS.is_labor;
                    this.form.required_serial = await this.GET_EDIT_ADMIN_ITEMS.required_serial;
                    this.form.item_category = await this.GET_EDIT_ADMIN_ITEMS.item_category;
                    this.form.consume_type = await this.GET_EDIT_ADMIN_ITEMS.consume_type;
                    this.form.life_span = await this.GET_EDIT_ADMIN_ITEMS.life_span;
                    this.form.service = await this.GET_EDIT_ADMIN_ITEMS.service;
                    this.price_history = this.GET_EDIT_ADMIN_ITEMS.price_history;
                    if (!this.GET_EDIT_ADMIN_ITEMS.image_path) {
                        this.clearImage()
                    } else {
                        this.have_img = true;
                        $('#image').attr('src', process.env.VUE_APP_API_HOST + '/' + this.GET_EDIT_ADMIN_ITEMS.image_path);
                    }

                    this.formatPrice();
                }
            },
        onChange() {
            this.files = [...this.$refs.file.files];
            let self = this;
            if (this.$refs.file.files && this.$refs.file.files[0]) {
                var reader = new FileReader();
                reader.onload = function (e) {
                    if(e.target.result){
                        self.have_img = true;
                        self.form.image_path = e.target.result;
                        $('#image').attr('src', e.target.result);
                    }
                };
                reader.readAsDataURL(this.$refs.file.files[0]);
            }
        },
        dragover(e) {
            e.preventDefault();
            this.isDragging = true;
        },
        dragleave() {
            this.isDragging = false;
        },
        drop(e) {
            e.preventDefault();
            this.$refs.file.files = e.dataTransfer.files;
            this.onChange();
            this.isDragging = false;
        },
        async createOrUpdateAccountability(){
            const item_category_swal_text = this.form.item_category ? "" : "Item Category";
            const consume_type_swal_text = this.form.consume_type ? "" : "Consume Type";
            const item_swal_text = this.form.item_name ? "" : "Item Name";
            const category_swal_text = this.form.admin_classification_id ? "" : "Classification";
            const remarks_swal_text = this.form.remarks ? "" : "Remarks";
            const service_swal_text = this.form.service ? "" : "Item Type";
            // const key_data1_swal_text = this.form.key_data1 ? "" : "Key Data 1";
            // const key_data2_swal_text = this.form.key_data2 ? "" : "Key Data 2";
            const array_text = [
                item_category_swal_text,
                consume_type_swal_text,
                item_swal_text,
                category_swal_text,
                remarks_swal_text,
                service_swal_text,
                // key_data1_swal_text,
                // key_data2_swal_text
            ];
            const filtered = array_text.filter(function (el) {
                return el;
            });
            if(this.$refs.form.validate()){
                // if (
                //     this.form.item_name &&
                //     (this.form.brand_id || this.search_admin_brand) &&
                //     this.form.admin_classification_id &&
                //     this.form.consume_type
                // ) {

                // }
                // if (!this.form.brand_id) this.form.brand = this.search_admin_brand;
                if (this.form.brand_id && typeof this.form.brand_id == 'string') {
                    const swalState = await Swal.fire({
                        title: 'Do you want to add a new brand on the system?',
                        icon: 'question',
                        showCancelButton:true,
                        reverseButtons:true,
                        confirmButtonColor: '#397373',
                        cancelButtonColor: 'grey',
                    });

                    if (!swalState.isConfirmed) return;
                }

                if (this.cachedVendors) {
                    this.form.vendor.forEach(vendor => {
                        const match = this.cachedVendors.filter(temp => vendor.id === temp.id)[0];
                        if (match && parseFloat(match.old_price) != parseFloat(match.price)){
                            vendor.unchanged = 0;
                        }
                    });
                }

                this.$store.dispatch(this.action,this.form).then(response => {
                    this.$store.dispatch('getAdminBrandSelection');
                    this.$store.dispatch('getAllAdminItem');
                    if(this.ITEMS_DIALOG){
                        this.$store.commit('PAYLOAD',{
                            item_name: response.data.item_name,
                            admin_item_id: response.data.id,
                            index: this.SELECTED.index
                        })
                        Swal.fire({
                            title: `Create Item Success`,
                            icon: "success",
                        });
                    } else {
                        Swal.fire({
                            title: `${this.ACTION == 'Submit' ? 'Create' : 'Update'} Item Success`,
                            icon: "success",
                        });
                    }
                    this.closeDialog();
                }).catch(err=>{
                    console.log(err.response.data.message)
                    Swal.fire('Error!',err.response.data.message,'error')
                });
            } else {
                Swal.fire({
                    html: `Fill Required Fields <br> (${filtered})`,
                    icon: "warning",
                    allowOutsideClick: false,
                });
            }
        },
        viewVendor(index) {
            this.vendorDialogAction = 'View'
            this.disabled_view = true;
            const selectedVendor = this.form.vendor[index];

            this.vendors.vendor_id = selectedVendor.vendor_id;
            this.vendors.particulars = selectedVendor.particulars;
            // this.vendors.brand = selectedVendor.brand;
            this.vendors.uom_id = selectedVendor.uom_id;
            this.vendors.price = selectedVendor.price;
            this.vendors.moq = selectedVendor.moq;

            this.addVendorsDialogVisible = true;
        },
        editVendor(index) {
            this.vendorDialogAction = "Update"
            this.disabled_view = false;
            const selectedVendor = this.form.vendor[index];

            this.vendors.id = selectedVendor.id;
            this.vendors.vendor_id = selectedVendor.vendor_id;
            this.vendors.particulars = selectedVendor.particulars;
            // this.vendors.brand = selectedVendor.brand;
            this.vendors.uom_id = selectedVendor.uom_id;
            this.vendors.price = selectedVendor.price;
            this.vendors.moq = selectedVendor.moq;

            this.addVendorsDialogVisible = true;
        },
        closeDialog() {
            this.$refs.form.resetValidation()
            this.$store.commit("ACTION", '');
            this.$store.commit("EDIT_ADMIN_ITEMS", {});
            this.resetFields();
            this.clearImage()
            this.$store.commit("ASSET_NUM", '');
            this.$store.commit("NEW_ADMIN_ITEM", true);
            if(this.ITEMS_DIALOG){
                this.$store.commit('ITEMS_DIALOG',false);
            } else {
                this.$store.commit("DIALOG", false);
            }
        },
        resetFields(){
            this.price_history = [];
            this.form = {
                id:'',
                vendor:[],
                model_number: "",
                // serial_number: "",
                item_name: "",
                uom_id: "",
                admin_classification_id:"",
                admin_classification_sub_id:"",
                // sub_asset_num:"",
                quantity:0,
                price:'',
                image_path:null,
                remarks:'',
                life_span:365,
                item_trash:[],
                is_labor: false,
                consume_type: undefined,
                item_category: undefined,
            }
            this.vendorDialogAction = '';
        },
        clearImage(){
            this.$refs.file.value = '';
            this.have_img = false;
            this.$store.commit('OPEN_CAMERA',false);
            $('#image').attr('src', '');
        },
        checkBrandValue(value) {
            if (typeof value !== 'string') return;

            const match = this.GET_ADMIN_BRAND.filter(brand => brand.text.toLowerCase() === value.toLowerCase());
            if (match.length > 0) this.form.brand_id = match[0];
        },
        changeCategory(){
            if(this.form.vendor.length > 0 && this.form.item_category == 1){
                if(this.form.vendor.some(e => e.price >= 5000) || this.form.admin_classification_id == 10){
                    this.form.admin_category_id = 2
                }else {
                    this.form.admin_category_id = 3
                }
            }
        },
        async copyImageToClipboard(event) {
            try {
                let self = this;
                const clipboardItems = event.clipboardData.items;
                    for (let item of clipboardItems) {
                        if (item.type.startsWith('image/')) {
                        const blob = item.getAsFile();
                        if (blob) {
                        var reader = new FileReader();
                        reader.onload = function (e) {
                            if(e.target.result){
                                self.have_img = true;
                                self.form.image_path = e.target.result;
                                $('#image').attr('src', e.target.result);
                            }
                        };
                        reader.readAsDataURL(blob);
                    }
                        }
                    }
            } catch (error) {
                console.error('Error Clipboard: ', error);
            }
        },
        isService(){
            this.form.service = 0
            this.form = {
                id:'',
                vendor:[],
                model_number: "",
                item_name: "",
                uom_id: "",
                admin_classification_id:"",
                admin_classification_sub_id:"",
                quantity:0,
                price:'',
                image_path:null,
                remarks:'',
                life_span:365,
                item_trash:[],
                is_labor: false,
                item_category: undefined,
            }
        },
    },

        watch: {
            GET_NEW_ADMIN_ITEM:{
            handler(val){
                if(val && this.ACTION != ''){
                    Swal.fire("Success!", "Item Saved", "success");
                    this.resetFields();
                    this.clearImage();
                    // this.$store.commit("DIALOG", false);
                    this.$store.commit('NEW_ADMIN_ITEM',false);
                }
            }
        },
        ITEMS_DIALOG:{
            handler(val){
                if(val){
                    this.$store.dispatch('getDepartment')
                    this.$store.dispatch("getAdminSupplierSelection");
                    this.$store.dispatch("getAdminClassificationSelection");
                    this.$store.dispatch('getAdminItemUomSelection');
                    this.$store.dispatch("getAdminBrandSelection");
                    this.$store.dispatch("getAdminCategories");
                }
            },
            immediate: true
        },
        SELECTED:{
            handler(val){
                if(val){
                    this.form.item_name = val.item_name
                }
            },
            immediate: true
        },
            // GET_ASSET_NUM: {
            //     handler() {
            //         this.form.sub_asset_num = this.$store.getters.GET_ASSET_NUM;
            //     },
            //     immediate: true,
            // },
            search_classification: {
                handler(val) {
                    this.GET_ADMIN_CLASSIFICATION_SELECTION.filter((e) => {
                        if (e.text == val) {
                            this.subClass = e.sub_class;
                        }
                    });
                },
            },
            DIALOGS: {
                handler(val) {
                    if (this.ACTION != 'Submit') return;
                    this.form.service = this.GET_ADMIN_ITEM_SERVICE ?? 0;
                },
            },
            GET_OPEN_CAMERA:{
                handler(val){
                    if(val){
                        this.have_img = true;
                    }else{
                        this.have_img = false;
                    }
                }
            },
            GET_CAMERA_CAPTURE:
            {
                handler(val){
                    if(val){

                    let canvas = document.createElement('canvas');
                    canvas.width = 1080;
                    canvas.height = 1080;
                    const ctx = canvas.getContext("2d");
                    ctx.imageSmooththingEnabled = true;
                    ctx.imageSmooththingQuality = "high";
                    ctx.drawImage(document.querySelector('video'),0,0,canvas.width, canvas.height);
                    $("#image").attr('src',canvas.toDataURL().toString());
                    this.form.image_path = canvas.toDataURL().toString();
                    this.have_img = true;
                    this.$store.commit('CAMERA_CAPTURE',false);
                    }
                },
            },
            'form.item_category':{
                async handler(val){
                    if(val){
                        await this.$store.dispatch('getAdminCategories', {
                            consumable: val == 2 ? 1 : 0,
                        }).then((reponse) =>{
                            this.form.admin_category_id = this.GET_ADMIN_CATEGORY_SELECTION[0]?.value
                        })
                    }
                }
            },
            'form.vendor':{
                handler(val){
                    if(val){
                    if(val.length > 0 && this.form.item_category == 1){
                        if(val.some(e => e.price >= 5000) || this.form.admin_classification_id == 10){
                            this.form.admin_category_id = 2
                        }else if(val.some(e => e.price < 5000 && this.form.admin_classification_id != 10)){
                            this.form.admin_category_id = 3
                        }
                    }
                    }
                }
            }
        }

    };
</script>
<style>

hr.hr-text {
    position: relative;
    border: none;
    height: 1px;
    background: #bcbcbc;
}

hr.hr-text::before {
    content: attr(data-content);
    display: inline-block;
    background: #ffffff;
    font-weight: bold;
    font-size: 0.85rem;
    color: #656565;
    border-radius: 20rem;
    padding: 0.1rem 0.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

</style>
