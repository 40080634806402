<template>
    <v-card width="1000px" class="d-flex justify-content-center">
        <v-card-title class="d-flex justify-content-between align-items-center">
            <span>{{ ACTION }} Repair Type</span>
            <v-btn
                text
                icon
                small
                color="gray"
                class="float-right"
                @click="() => {
                    $store.commit('DIALOG', false);
                    $store.commit('ACTION', false);
                    resetField();
                }">
                <v-icon>mdi-close-circle</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text class="pt-6">
            <v-row>
                <v-col cols="4" sm="4">
                    <v-text-field
                        :disabled="ACTION === 'View'"
                        outlined
                        dense
                        label="Code"
                        v-model="form.code"
                        required
                        :loading="ACTION !=='Add' && !form.code"
                    ></v-text-field>
                </v-col>
                <v-col cols="4" sm="4">
                    <v-text-field
                        :disabled="ACTION === 'View'"
                        outlined
                        dense
                        label="Name"
                        v-model="form.name"
                        :loading="ACTION !=='Add' && !form.name"
                        required
                    ></v-text-field>
                </v-col>
                <v-col cols="4" sm="4">
                    <v-select
                        :disabled="ACTION === 'View'"
                        outlined
                        dense
                        label="Type"
                        v-model="form.type"
                        :loading="ACTION !=='Add' && !form.type"
                        :items="typeOptions"
                        required
                    ></v-select>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-content-center p-2">
            <v-btn @click="modifyRepairType('modify-repair-type')" v-if="ACTION !== 'View'">Submit</v-btn>
        </v-card-actions>
    </v-card>
</template>
  
<script>
import swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            form: {
                code: '',
                name: '',
                type: '',
                others: '0',
            },
            typeOptions: ['Repair / Maintenance', 'INSTALLATION'],
        }
    },
    computed: {
        ...mapGetters(["ACTION", "DIALOG", "SELECTED","REPAIR_TYPE"]),
    },    
    methods: {
        isFieldEmpty(field) {
            return this.ACTION !== 'View' && this.form[field] === '';
        },
        resetField() {
            this.form = {
                code: '',
                name: '',
                type: '',
                others: '0',
            };
        },
        async fetchRepairType() {
            await this.$store.dispatch("EnterRepairTypeGet", {
                url: 'get-repair-type/' + this.SELECTED,
                }).then(response=>{
                    this.form.code = response.data.code,
                    this.form.name = response.data.name,
                    this.form.type = response.data.type,
                    this.form.others = response.data.others;
                });
        },
        modifyRepairType(url) {
            if(this.form.code == '' || this.form.name == '' || this.form.type == '')
            {
                swal.fire('Required all fields', '', 'error');
            }
            else {
                let modifiedUrl = this.ACTION === 'Edit' ? `${url}/${this.SELECTED}` : url;
                swal.fire({
                    title: "",
                    text: `Are you sure you want to ${this.ACTION} ?`,
                    icon: 'question',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    allowOutsideClick: false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor: 'grey',
                    confirmButtonText: 'Confirm',
                    showCloseButton: true,
                    }).then((actions) =>{
                        if (actions.isConfirmed) {
                            this.$store.dispatch("EnterRepairTypePost", {
                                url: modifiedUrl,
                                ...this.form,
                            }).then(response=>{
                                this.resetField();
                                swal.fire('Success', '', 'success');
                                this.$store.commit('SELECTED',{});
                                this.$store.commit('DIALOG', false);
                            }).catch(err=>{
                                console.error(err);
                                swal.fire('Error!','','error');
                            });
                        }
                    }).catch(error => {
                        console.error(error);
                        swal.fire('Error!','','error');
                    });
            }  
        }
    },
    watch: {
        'form':{
            handler(val){
            },
            deep:true
        },
        'ACTION': {
            async handler(val) {
                if (['View','Edit'].includes(val)) {
                    this.fetchRepairType();
                }
            },
            deep: true
        },
        'form.type': {
        handler(newVal) {
                this.form.type = newVal;
            },
            deep: true 
        }
    },
    mounted() {
        if (['View', 'Edit'].includes(this.ACTION)) {
            this.fetchRepairType();
        };
        console.log(this.REPAIR_TYPE);
    }
}
</script>

<style>

</style>
