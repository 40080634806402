<template>
  <div class="page-wrapper">
    <v-app id="editWarehouseStocksModal">
      <v-dialog
        v-model="add_warehouse_item_stocks_dialog"
        persistent
        max-width="60%"
        scrollable
      >
        <v-card>
          <v-row class="m-0">
            <v-col>
              <v-card-title>
                <span class="subtitle-2">{{
                  item.model + " - " + item.name
                }}</span>
              </v-card-title>
            </v-col>

            <v-col cols="pull-right-10 p-2">
              <v-btn
                text
                icon
                color="gray"
                class="float-right"
                @click="
                  add_warehouse_item_stocks_dialog = false;
                  resetFields();
                  $emit('closeWarehouseStocksModal');
                "
              >
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-card-text>
            <form
              class="form-horizontal"
              action="/warehouse_stocks"
              method="post"
              v-on:submit.prevent="onSubmit"
            >
              <div class="card-body">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <th width="70%">Warehouse</th>
                      <th width="15%" class="text-center">Current Stock</th>
                      <th
                        width="15%"
                        class="text-center"
                        v-if="is_manager || is_super_admin"
                      >
                        Adjustment
                      </th>
                      <th width="15%" class="text-center" v-if="is_super_admin">
                        subtract?
                      </th>
                    </thead>
                    <tbody>
                      <tr v-for="(item_stock, i) in item_stocks" :key="i">
                        <input
                          type="hidden"
                          :id="'item_warehouse_id' + i"
                          v-model="item_stock.warehouse_id"
                        />
                        <td>{{ item_stock.warehouse_name }}</td>
                        <td>
                          <input
                            type="text"
                            class="form-control text-center"
                            :id="'item_stock_qty' + i"
                            placeholder="0"
                            v-model="item_stock.quantity"
                            @keypress="numberOnly"
                            readonly
                          />
                        </td>
                        <td v-if="is_manager || is_super_admin">
                          <input
                            type="text"
                            class="form-control text-center"
                            :id="'add_item_stock_qty' + i"
                            placeholder="0"
                            v-model="item_stock.adjustment"
                            @keypress="numberOnly"
                            @keyup="checkAdjustment(i)"
                          />
                        </td>
                        <td v-if="is_super_admin">
                          <v-switch
                            v-model="item_stock.subtract"
                            label=""
                            color="primary"
                            input-value="1"
                            @change="checkAdjustment(i)"
                          ></v-switch>
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td class="text-center">Total: {{ total_quantity }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>

              <div class="border-top" v-if="is_manager || is_super_admin">
                <div class="card-body py-4 d-flex justify-content-center">
                  <v-btn
                    :loading="submitOnFlight"
                    v-on:click="saveWarehouseItemStocks()"
                    >Submit</v-btn
                  >
                </div>
              </div>
            </form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
import { mapGetters } from "vuex";
import swal from "sweetalert2";
export default {
  mixins: [SharedFunctionsComponentVue],
  props: ["warehouse_item_id"],
  data() {
    return {
      add_warehouse_item_stocks_dialog: false,
      item_stocks: [],
      item_stock: {
        warehouse_name: "",
        warehouse_id: "",
        quantity: "",
      },
      item: {
        model: "",
        name: "",
      },

      errors: [],
      actionResult: {
        model: "Warehouse Stocks",
        action: "",
      },
      is_manager: 0,
      is_super_admin: 0,
      submitOnFlight: false,
    };
  },
  mounted() {
    // this.userGetManagerAdmin()
  },
  methods: {
    userGetManagerAdmin() {
      this.$store.dispatch("userGetManagerAdmin").then((response) => {
        var data = response.data;
        this.is_manager = data.manager;
        this.is_super_admin = data.super_admin;
      });
    },
    checkAdjustment(key) {
      if (
        this.item_stocks[key].subtract != null &&
        this.item_stocks[key].subtract == 1
      ) {
        if (
          this.item_stocks[key].adjustment != null &&
          this.item_stocks[key].adjustment != ""
        ) {
          var total =
            parseInt(this.item_stocks[key].quantity) -
            parseInt(this.item_stocks[key].adjustment);
          if (total < 0) {
            swal.fire("", "Adjustment cannot be greater than stock", "warning");
            this.item_stocks[key].adjustment = "";
          }
        }
      }
    },
    saveWarehouseItemStocks() {
      this.submitOnFlight = true;
      this.errors = [];
      let payload = {
        item_id: this.warehouse_item_id,
        item_stocks: this.item_stocks,
      };
      this.$store
        .dispatch("postWarehouseItemStocks", payload)
        .then((response) => {
          this.add_warehouse_item_stocks_dialog = false;
          this.action = "success";
          this.resetFields();
          swal.fire("", response.data.msg, "success");
          this.$emit("closeWarehouseStocksModal", "success");
          this.submitOnFlight = false;
        })
        .catch((error) => {
          swal.fire("", error.response.data.errors, "success");
        });
    },
    resetFields() {
      this.errors = [];
      this.item = "";
      this.item_stocks = [];
    },
  },
  watch: {
    warehouse_item_id: function (val) {
      if (val != null && val > 0) {
        this.loaded = false;
        let payload = {
          id: val,
          from: "sales",
        };
        this.$store
          .dispatch("getAllWarehouseItemStocks", payload)
          .then((response) => {
            var data = this.GET_WAREHOUSE_ITEM_STOCK;
            this.item = data.item;
            this.item_stocks = data.item_stocks;
            this.loaded = true;
            this.add_warehouse_item_stocks_dialog = true;
          });
      }
    },
  },
  computed: {
    // a computed getter
    ...mapGetters(["GET_WAREHOUSE_ITEM_STOCK"]),
    total_quantity: function () {
      var total = 0;
      if (this.item_stocks.length > 0) {
        this.item_stocks.forEach((item_stock, index) => {
          if (item_stock.quantity != "") {
            total += parseInt(item_stock.quantity);
          }
        });
      }
      return this.thousandSeprator(total);
    },
  },
};
</script>
