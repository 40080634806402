<template>
    <v-container fluid>
        <ComponentHeader class="ma-n4" :button="true" title='Credit Memo China Discrepancy Report' type='Accounting'></ComponentHeader>
        <v-row>
            <v-app id="my_vue_app" style="width:100%" class="mx-4 bg-bluish-gray pa-3 px-5">
                <v-row dense class="my-2">
                    <v-col sm="12" md="2" class="d-flex flex-row">
                        <v-menu
                            v-model="menu.date_from"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_from"
                                    prepend-inner-icon="mdi-calendar"
                                    outlined
                                    dense
                                    readonly
                                    v-on="on"
                                    style="display: block"
                                    background-color="white"
                                    label="Date From"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_from" @click:month="menu.date_from = false" :max="minDate"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col sm="12" md="2" class="d-flex flex-row">
                        <v-menu
                            v-model="menu.date_to"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_to"
                                    prepend-inner-icon="mdi-calendar"
                                    outlined
                                    dense
                                    readonly
                                    v-on="on"
                                    style="display: block"
                                    background-color="white"
                                    label="Date To"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_to" @click:month="menu.date_to = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col sm="12" md="4" class="text-right">
                        <v-btn small class="ml-1" @click="resetFields()">Clear</v-btn>
                        <v-btn small class="ml-1" @click="getAllCreditMemoChinaDiscrepancyReport(1)">Excel</v-btn>
                        <v-btn small class="ml-1" @click="getAllCreditMemoChinaDiscrepancyReport(0)">Filter</v-btn>
                    </v-col>
                </v-row>
                <v-row dense>

                </v-row>
            </v-app>
        </v-row>
        <v-row class="w-100 pa-2 bg-bluish-gray ml-0">
            <v-card :class="'w-100 mt-2'">
            <ReportsCardsComponentVue :totals="totals" :cards="cards" style="width:100%;"></ReportsCardsComponentVue>
                <v-card-text>
                    <div>
                        <v-data-table
                            :headers="credit_memo_china.headers"
                            :items="credit_memo_china.items"
                            :loading="credit_memo_china.loading"
                            :options.sync="options"
                            :server-items-length="totalCreditMemoChina"
                            :items-per-page="10"
                            dense
                        >
                        <template v-slot:[`item.total_amount`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.total_amount | currency }}
                            </span>
                        </template>
                        <template v-slot:[`item.total_cost`]="{ item }">
                            <span class="text-nowrap">
                                &#x24;{{ item.total_cost | currency }}
                            </span>
                        </template>
                        <template v-slot:[`item.discrepancy`]="{ item }">
                            <span class="text-nowrap">
                                &#x24;{{ item.discrepancy | currency }}
                            </span>
                        </template>
                        <template v-slot:[`item.approved_dollar`]="{ item }">
                            <span class="text-nowrap">
                                &#x24;{{ item.approved_dollar | currency }}
                            </span>
                        </template>
                        <template v-slot:[`item.created_at`]="{ item }">
                            <span class="text-nowrap">
                               {{ item.created_at | formatDate }}
                            </span>
                        </template>
                        <template v-slot:[`item.confirm_date`]="{ item }">
                            <span class="text-nowrap">
                               {{ item.confirm_date | formatDate }}
                            </span>
                        </template>
                        <template v-slot:[`item.date_start`]="{ item }">
                            <span class="text-nowrap">
                               {{ item.date_start | formatDate }}
                            </span>
                        </template>
                        <template v-slot:[`item.date_end`]="{ item }">
                            <span class="text-nowrap">
                               {{ item.date_end | formatDate }}
                            </span>
                        </template>
                        <template v-slot:[`item.action`]="{ item,index }">
                            <v-btn text icon color="orange">
                                <v-icon class="btn-action" small
                                    @click="viewCreditMemoChina(index,1,'view',item.id)">mdi-eye</v-icon>
                            </v-btn>
                        </template>
                        </v-data-table>
                    </div>
                </v-card-text>
            </v-card>
        </v-row>
        <CreditMemoComponentVue ref="credit_memo" :fromReports="true"></CreditMemoComponentVue>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue';
import swal from 'sweetalert2';
import ReportsCardsComponentVue from '@/views/main/layouts/Reports/ReportsCardsComponent.vue';
import CreditMemoComponentVue from '../../Accounting/CreditMemo/CreditMemoComponent.vue';
export default {
    data() {
        return {
            payments:{
                selected: '',
                selection: [],
            },
            credit_memo_china:{
                headers:[
                    { text: "CMC ID", value: "id", align: "center" },
                    { text: "CMC DATE", value: "created_at", align: "center" },
                    { text: "START DATE", value: "date_start", align: "center" },
                    { text: "END DATE", value: "date_end", align: "center" },
                    { text: "CONFIRM BY", value: "confirm_by", align: "start" },
                    { text: "CONFIRM DATE", value: "confirm_date", align: "center" },
                    { text: "APPROVED DATE", value: "approved_date", align: "center" },
                    { text: "CREDIT NOTE #", value: "credit_note_num", align: "center" },
                    { text: "CONTAINER #", value: "container_num", align: "center" },
                    // { text: "REMARKS", value: "remarks", align: "right" },
                    { text: "TOTAL CM (USD)", value: "total_cost", align: "center" },
                    { text: "APPROVED DOLLAR (USD)", value: "approved_dollar", align: "center" },
                    { text: "DISCREPANCY", value: "discrepancy", sortable: false, align: "center" },
                    { text: "ACTION", value: "action", sortable: false, align: "center" },
                ],
                items:[],
                loading:false,
                search:''
            },
            customers_list: [],
            menu: {
                date_from: false,
                date_to: false,
            },
            filters: {
                date_from: this.$dayjs().startOf('month').format('YYYY-MM-DD'),
                date_to:  this.$dayjs().endOf('month').format('YYYY-MM-DD'),
                customers: [],
            },
            banks:{
                selection:[],
                selected:''
            },
            minDate: this.$dayjs().format('YYYY-MM-DD'),
            export_data:[],
            cards: [
                { title: 'Total CM Amount (USD)', icon: 'mdi-cash', icon_class: 'total-amount', sm: 6, md: 3, lg: 3, prefix: '&#x24; ', suffix: '', filter: 'currency', value: 'total_cm_usd' },
                { title: 'Total Approved Dollar (USD)', icon: 'mdi-cash', icon_class: 'total-amount-booked', sm: 6, md: 3, lg: 3, prefix: '&#x24; ', suffix: '', filter: 'currency', value: 'total_cm_approved_dollar' },
                { title: 'Total Discrepancy (USD)', icon: 'mdi-cash', icon_class: 'total-target', sm: 6, md: 3, lg: 3, prefix: '&#x24; ', suffix: '', filter: 'currency', value: 'total_cm_discrepancy' },
            ],
            totals: {
                total_cm_usd: 0,
                total_cm_discrepancy: 0,
                total_cm_approved_dollar: 0,
            },
            options:{},
            totalCreditMemoChina:0,
            initialLoad: true
        }
    },
    mixins: [SharedFunctionsComponent],
    components: {
        ComponentHeader,
        ReportsCardsComponentVue,
        CreditMemoComponentVue
    },
    mounted() {
        this.$store.dispatch('getAllcustomersSelection')
    },
    computed: {
        ...mapGetters([
            'GET_CUSTOMERS_SELECTION',
            'GET_CLASSIFICATIONS_SELECTION',
            'USERACCOUNT_LOAD'
        ]),
    },
    methods: {
        resetFields() {
            Object.assign(this.$data, this.$options.data.call(this));
        },
        async getAllCreditMemoChinaDiscrepancyReport(for_export = 0) {
            swal.fire({
                title: 'Filtering Please wait...',
                icon: 'info',
                allowOutsideClick: false,
            });
            swal.showLoading();
            let payload = {
                date_range:[this.filters.date_from,this.filters.date_to],
                for_export:for_export,
                url:'getAllCreditMemoChinaDiscrepancyReport',
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
            }
            await this.$store.dispatch('reportsPost',payload).then(async response=>{
                let data = response.data.data
                this.credit_memo_china.items = !!for_export ? data : data.data
                this.totalCreditMemoChina = data.total
                this.totals = response.data
                swal.close()
                swal.fire({
                    icon: 'success',
                    title: 'Filtering Successfully!',
                    allowOutsideClick: false,
                })
                if(for_export){
                    await this.exportToExcel();
                }

            })
        },
        renderPDF(){

        },
        async exportToExcel(){
            swal.fire({
                title: 'Exporting File Please wait...',
                icon: 'info',
                allowOutsideClick: false,
            });
            swal.showLoading();
            let that = this
            that.credit_memo_china.loading = true
            const workbook = new this.$exceljs.Workbook();
            const worksheet = workbook.addWorksheet('Raw Data')
            worksheet.columns = [
                { header: "CMC ID", key: "id" },
                { header: "CMC DATE", key: "created_at" },
                { header: "START DATE", key: "date_start" },
                { header: "END DATE", key: "date_end" },
                { header: "CONFIRM BY", key: "confirm_by", align: "start" },
                { header: "CONFIRM DATE", key: "confirm_date" },
                { header: "APPROVED DATE", key: "approved_date" },
                { header: "CREDIT NOTE #", key: "credit_note_num" },
                { header: "CONTAINER #", key: "container_num" },
                { header: "REMARKS", key: "remarks", align: "right" },
                { header: "TOTAL CM (USD)", key: "total_cost", style: { numFmt: '#,##0.00' } },
                { header: "APPROVED DOLLAR (USD)", key: "approved_dollar", style: { numFmt: '#,##0.00' } },
                { header: "DISCREPANCY", key: "discrepancy", sortable: false, style: { numFmt: '#,##0.00' } },
            ]
            this.credit_memo_china.items.forEach(e=>{
                e.approved_dollar = Number (e.approved_dollar)
                e.total_cost = Number (e.total_cost)
                worksheet.addRow(e);
            })
            worksheet.getRow(`0${1}`).font = { bold: true }
            that.loaded = true
            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                this.$filesaver.saveAs(blob, `Credit Memo China Discrepancy Report ${that.filters.date_from}-${that.filters.date_to}.xlsx`);
            });
            swal.close()
            swal.fire({
                icon: 'success',
                title: 'Export Successfully!',
                allowOutsideClick: false,
            });
            that.credit_memo_china.loading = false
        },
        formatMonthSoa(value) {
            if (value) {
                return this.$dayjs(String(value)).format('MMM YYYY')
            }
        },
        viewCreditMemoChina(index,editedIndex,action,id){
            this.$refs.credit_memo.viewCreditMemoChina(index,editedIndex,action,id)
        }
    },
    watch:{
        'options':{
            handler() {
                if(!this.initialLoad){
                    this.getAllCreditMemoChinaDiscrepancyReport(0);
                }else{
                    this.initialLoad = false
                }
            },
            deep: true,
        }
    }
}
</script>

<style scoped>

</style>
