<template>
    <v-container fluid>
        <ComponentHeader class="ma-n4" :button="true" title='Cheque Deposit Report' type='Accounting'></ComponentHeader>
        <v-row>
            <v-app id="my_vue_app" style="width:100%" class="mx-4 bg-bluish-gray pa-3 px-5">
                <v-row dense class="my-2">
                    <v-col sm="12" md="6">
                        <v-autocomplete
                            :items="GET_CUSTOMERS_SELECTION"
                            :loading="!GET_CUSTOMERS_SELECTION"
                            :disabled="!GET_CUSTOMERS_SELECTION"
                            v-model="filters.customers"
                            label="Select Customers"
                            @change="onSelectCustomers"
                            class="d-inline-block w-100"
                            outlined
                            dense
                            multiple
                            background-color="white"
                            small-chips
                            hide-details
                            clearable
                            deletable-chips
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-col sm="12" md="2">
                        <v-autocomplete
                            dense
                            outlined
                            :items="banks.selection"
                            v-model="banks.selected"
                            item-value="id"
                            item-text="name"
                            label="Banks"
                            background-color="white"
                            multiple
                            small-chips
                            hide-details
                            clearable
                            deletable-chips
                        />
                    </v-col>
                    <v-col sm="12" md="4" class="text-right">
                        <v-btn small class="ml-1" @click="resetFields()">Clear</v-btn>
                        <v-btn small class="ml-1" @click="renderPDF()">Print</v-btn>
                        <v-btn small class="ml-1" @click="exportToExcel()">Excel</v-btn>
                        <v-btn small class="ml-1" @click="getAllChequeDeposit()">Filter</v-btn>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col sm="12" md="1" class="d-flex flex-row">
                        <v-autocomplete
                            :items="week_range"
                            outlined
                            dense
                            v-model="week.start"
                            label="Week"
                            color="white"
                            background-color="white"
                            @change="selectWeek(); getAllChequeDeposit()"
                            >
                        </v-autocomplete>
                    </v-col>
                    <v-col sm="12" md="2" class="d-flex flex-row">
                        <v-menu
                            v-model="menu.date_from"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_from"
                                    prepend-inner-icon="mdi-calendar"
                                    outlined
                                    dense
                                    readonly
                                    v-on="on"
                                    style="display: block"
                                    background-color="white"
                                    label="Date From"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_from" @click:month="menu.date_from = false" :max="minDate" @change="getAllChequeDeposit()"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col sm="12" md="2" class="d-flex flex-row">
                        <v-menu
                            v-model="menu.date_to"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_to"
                                    prepend-inner-icon="mdi-calendar"
                                    outlined
                                    dense
                                    readonly
                                    v-on="on"
                                    style="display: block"
                                    background-color="white"
                                    label="Date To"
                                    disabled
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_to" @click:month="menu.date_to = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-app>
        </v-row>
        <v-row class="w-100 pa-2 bg-bluish-gray ml-0" v-if="filters.date_from >= firstDayOfTheWeek">
            <v-card v-for="data in collection.items" :key="data.period" :class="data.cheque_dates.length != 0? 'w-100 mt-2' : 'w-100'">
                <v-card-text v-if="data.cheque_dates.length != 0">
                    <div>
                        <v-card-title>
                            <b><span>{{ data.period | formatDateWithDay}} |  PHP {{ thousandSeprator(data.total) | currency }}</span></b>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-data-table
                            :headers="collection.headers"
                            :items="data.cheque_dates"
                            :loading="collection.loading"
                            :items-per-page="15"
                            item-key="period"
                            dense
                        >
                        <template v-slot:[`item.net_amount`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.net_amount | currency }}
                            </span>
                        </template>
                        </v-data-table>
                    </div>
                </v-card-text>
            </v-card>
        </v-row>
        <v-row class="w-100 pa-2 bg-bluish-gray ml-0" v-else>
            <v-card class='w-100 mt-2' v-if="collection.with_old_data.length != 0">
                <v-card-text>
                    <div>
                        <v-card-title>
                            <b><span>{{ filters.date_from | formatDateWithDay }} &nbsp; </span> <span>- {{firstDayOfTheWeek | formatDateWithDay}} |  PHP {{ thousandSeprator(old_data_total) | currency }}</span></b>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-data-table
                            :headers="collection.headers"
                            :items="collection.with_old_data"
                            :loading="collection.loading"
                            :items-per-page="15"
                            item-key="period"
                            dense
                        >
                        <template v-slot:[`item.net_amount`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.net_amount | currency }}
                            </span>
                        </template>
                        </v-data-table>
                    </div>
                </v-card-text>
            </v-card>
            <v-card v-for="data in collection.items" :key="data.period" :class="data.period > firstDayOfTheWeek && data.cheque_dates.length != 0? 'w-100 mt-2' : 'w-100'">
                <v-card-text v-if="data.cheque_dates.length != 0 && data.period > firstDayOfTheWeek">
                    <div>
                        <v-card-title>
                            <b><span>{{ data.period | formatDateWithDay }} |  PHP {{ thousandSeprator(data.total) | currency }}</span></b>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-data-table
                            :headers="collection.headers"
                            :items="data.cheque_dates"
                            :loading="collection.loading"
                            :items-per-page="15"
                            item-key="period"
                            dense
                        >
                        <template v-slot:[`item.net_amount`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.net_amount | currency }}
                            </span>
                        </template>
                        </v-data-table>
                    </div>
                </v-card-text>
            </v-card>

        </v-row>
        <div style="display:none" v-for="(data, index) in collection.items" :key="index">
                <table :id='"normal_details_"+index' v-if="data.cheque_dates.length != 0">
                    <thead>
                        <tr>
                            <th colspan="4" class="text-left">{{ data.period | formatDateWithDay}}</th>
                        </tr>
                        <tr>
                            <th>COMPANY</th>
                            <th>AMOUNT</th>
                            <th>CHEQUE NUMBER</th>
                            <th>DATED</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(d, index) in data.cheque_dates" :key="index">
                            <td>{{ d.company_name }}</td>
                            <td>{{ thousandSeprator(d.net_amount) | currency}} </td>
                            <td>{{ d.cheque_account_number}}</td>
                            <td>{{ d.cheque_date}}</td>
                        </tr>
                        <tr>
                            <td colspan="4">PHP {{ thousandSeprator(data.total) | currency }}</td>
                        </tr>
                    </tbody>

                </table>
        </div>
        <div style="display:none">
            <table id='with_old_data'>
                <thead>
                    <tr>
                        <th colspan="4" class="text-left">
                            {{ filters.date_from | formatDateWithDay }} - {{firstDayOfTheWeek | formatDateWithDay}}
                        </th>
                    </tr>
                    <tr>
                        <th>COMPANY</th>
                        <th>AMOUNT</th>
                        <th>CHEQUE NUMBER</th>
                        <th>DATED</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(d, index) in collection.with_old_data" :key="index">
                        <td>{{ d.company_name }}</td>
                        <td>{{ thousandSeprator(d.net_amount) | currency}} </td>
                        <td>{{ d.cheque_account_number}}</td>
                        <td>{{ d.cheque_date}}</td>
                    </tr>
                    <tr>
                        <td colspan="4">PHP {{ thousandSeprator(old_data_total) | currency }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div style="display:none">
            <table id='totals'>
                <tbody>
                    <tr>
                        <td><b>Total Amount</b></td>
                        <td colspan="3">PHP {{thousandSeprator(cheque_total_amount) | currency}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue';
export default {
    data() {
        return {
            payments:{
                selected: '',
                selection: [],
            },
            collection: {
                headers: [
                    { text: 'Company', width: '15%', value: 'company_name' },
                    { text: 'Bank', width: '15%', value: 'bank_name' },
                    { text: 'Cheque Number', width: '15%', value: 'cheque_account_number' },
                    { text: 'Dated', width: '15%', value: 'cheque_date' },
                    { text: 'Amount', width: '15%', value: 'net_amount' },
                ],
                items: [],
                expanded: [],
                loading: false,
                with_old_data:[],
            },
            customers_list: [],
            customer_classifications_list: [],
            menu: {
                date_from: false,
                date_to: false,
            },
            filters: {
                date_from: this.$dayjs().startOf('week').add(1, 'day').format('YYYY-MM-DD'),
                date_to:  this.$dayjs().endOf('week').add(-1, 'day').format('YYYY-MM-DD'),
                customers: [],
                customer_classifications: [],
            },
            banks:{
                selection:[],
                selected:''
            },
            week_range:[],
            week:{
                start:this.$dayjs().week(),
            },
            minDate: this.$dayjs().format('YYYY-MM-DD'),
            firstDayOfTheWeek:this.$dayjs().startOf('week').add(1, 'day').format('YYYY-MM-DD'),
            cheque_total_amount:0,
            old_data_total:0,
        }
    },
    mixins: [SharedFunctionsComponent],
    components: {
        ComponentHeader
    },
    mounted() {
        this.$store.dispatch('getAllcustomersSelection')
        this.$store.dispatch('getAllcustomerClassificationSelection')
        this.getBanks();
        this.getNumberOfWeeks();
    },
    computed: {
        ...mapGetters([
            'GET_CUSTOMERS_SELECTION',
            'GET_CLASSIFICATIONS_SELECTION',
            'USERACCOUNT_LOAD'
        ]),
    },
    methods: {
        getBanks() {
            this.$store.dispatch('getBanks', {
                type: 'AR',
            }).then(response=>{
                this.banks.selection = response.data
            })
        },
        resetFields() {
            this.filters.customers = [];
            this.banks.selected = '';
            this.getAllChequeDeposit();
        },
        getCustomers() {
            this.customers_list = this.GET_CUSTOMERS_SELECTION
        },
        getAllChequeDeposit() {
            this.cheque_total_amount = 0
            this.old_data_total = 0
            this.collection.loading = true
            let payload = {
                date_range:[this.filters.date_from,this.filters.date_to],
                bank_id:this.banks.selected,
                customer_ids:this.filters.customers,
                url:'getAllChequeDepositReport'
            }
            let old_data = []
            this.$store.dispatch('reportsPost',payload).then(response=>{
                this.collection.items = response.data.data
                this.collection.items.forEach(e=>{
                    if(e.period <= this.firstDayOfTheWeek){
                        if(e.cheque_dates.length != 0){
                            old_data.push(e.cheque_dates);
                        }
                    }
                    e.cheque_dates.forEach(x=>{
                        this.cheque_total_amount += Number(x.net_amount)
                        x.cheque_date = this.$dayjs(x.cheque_date).format('MM/DD/YYYY')
                    })

                })
                this.collection.with_old_data = old_data.flat()
                this.collection.with_old_data.forEach(e=>{
                    this.old_data_total += Number(e.net_amount)
                })
                this.collection.loading = false
            })

        },
        onSelectCustomers(items) {
            this.filters.customers = items
        },
        renderPDF(){
            var doc = new this.$jspdf({
                orientation: 'p',
                unit: 'mm',
                format: 'letter'
            });
            let left = 7;
            let top = 5;
            const imgWidth = 40;
            const imgHeight = 12;
            let host = window.location.origin;
            doc.addImage(`${host}/images/ingcoph-logo.png`, 'PNG', left, top, imgWidth, imgHeight);

            if(this.filters.date_from >= this.firstDayOfTheWeek){
                for (let i = 0; i<this.collection.items.length; i++){
                    if(this.collection.items[i].cheque_dates.length != 0){
                        doc.autoTable({
                            html: '#normal_details_'+i,
                            theme: 'grid',
                            margin: {top: 17, left: 6, right: 6},
                            headStyles: {
                                halign:'center',
                                fontSize: 9,
                                fillColor: 'yellow',
                                textColor: '#000000',
                            },
                            columnStyles: {
                                0: {cellWidth: 85,halign:'center', fontSize: 8, textColor: '#000000'},
                                1: {cellWidth: 40,halign:'center', fontSize: 8, textColor: '#000000'},
                                2: {cellWidth: 40,halign:'center', fontSize: 8, textColor: '#000000'},
                                3: {cellWidth: 40,halign:'center', fontSize: 8, textColor: '#000000'},
                            },
                        });
                    }
                }
            }
            else{
                doc.autoTable({
                    html: '#with_old_data',
                    theme: 'grid',
                    margin: {top: 15, left: 6, right: 6},
                    headStyles: {
                        halign:'center',
                        fontSize: 9 ,
                        fillColor: 'yellow',
                        textColor: '#000000',
                    },
                    columnStyles: {
                        0: {cellWidth: 85,halign:'center', fontSize: 8, textColor: '#000000'},
                        1: {cellWidth: 40,halign:'center', fontSize: 8, textColor: '#000000'},
                        2: {cellWidth: 40,halign:'center', fontSize: 8, textColor: '#000000'},
                        3: {cellWidth: 40,halign:'center', fontSize: 8, textColor: '#000000'},
                    },
                });
                for (let i = 0; i<this.collection.items.length; i++){
                    if(this.collection.items[i].cheque_dates.length != 0 && this.collection.items[i].period > this.firstDayOfTheWeek){
                        doc.autoTable({
                            html: '#normal_details_'+i,
                            theme: 'grid',
                            margin: {top: 15, left: 6, right: 6},
                            headStyles: {
                                halign:'center',
                                fontSize: 9,
                                fillColor: 'yellow',
                                textColor: '#000000',
                            },
                            columnStyles: {
                                0: {cellWidth: 85,halign:'center', fontSize: 8, textColor: '#000000'},
                                1: {cellWidth: 40,halign:'center', fontSize: 8, textColor: '#000000'},
                                2: {cellWidth: 40,halign:'center', fontSize: 8, textColor: '#000000'},
                                3: {cellWidth: 40,halign:'center', fontSize: 8, textColor: '#000000'},
                            },
                        });
                    }
                }
            }
            doc.autoTable({
                html: '#totals',
                theme: 'grid',
                margin: {top: 15, left: 6, right: 6},
                columnStyles: {
                    0: {cellWidth: 85,halign:'center', fontSize: 9, textColor: '#000000',fontStyle:'bold'},
                    1: {cellWidth: 40,halign:'left', fontSize: 10, textColor: '#000000',fontStyle:'bold'},
                    2: {cellWidth: 40,halign:'left', fontSize: 10, textColor: '#000000',fontStyle:'bold'},
                    3: {cellWidth: 40,halign:'left', fontSize: 10, textColor: '#000000',fontStyle:'bold'},
                },
            });
            let pageCount = doc.internal.getNumberOfPages();
            for(let i = 0; i < pageCount; i++) {
                doc.setPage(i);
                let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber;
                doc.setFontSize(9).setFont(undefined, 'normal').text('Page: ' + pageCurrent + ' of ' + pageCount, doc.internal.pageSize.width - 25,15 );
            }
            doc.autoPrint();
            window.open(doc.output('bloburl'), '_blank');
        },
        getNumberOfWeeks() {
            this.week_range = [];
            for (let i = 1; i <= this.$dayjs().weeksInYear(); i++) {
                this.week_range.push(i)
            }
        },
        selectWeek(){
            let today = this.$dayjs().week(this.week.start);
            this.filters.date_from = today.startOf('week').add(1, 'day').format('YYYY-MM-DD')
            this.firstDayOfTheWeek = today.startOf('week').add(1, 'day').format('YYYY-MM-DD')
            this.filters.date_to = today.endOf('week').add(-1, 'day').format('YYYY-MM-DD')
        },
        async exportToExcel(){
            let that = this
            that.collection.loading
            const workbook = new this.$exceljs.Workbook();

            const worksheet = workbook.addWorksheet('Raw Data')
            worksheet.columns = [
                {header:'Company',key:'company_name'},
                {header:'Amount',key:'net_amount'},
                {header:'Cheque Number',key:'cheque_account_number'},
                {header:'Dated',key:'cheque_date'},
            ]
            this.collection.items.forEach(e=>{
                if(e.cheque_dates.length != 0){
                    e.cheque_dates.forEach(x=>{
                        worksheet.addRow(x);
                    })
                }
            })
            worksheet.getRow(`0${1}`).font = { bold: true }
            worksheet.getColumn('B').numFmt = '#,##0.00';
            that.loaded = true
            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                this.$filesaver.saveAs(blob, `Cheque Deposit Report ${that.filters.date_from}-${that.filters.date_to}.xlsx`);
            });
        }
    },
}
</script>

<style>

</style>
