<template>
    <div class="w-100">
        <v-app id="my_vue_app">
            <v-card class="p-2">
                <v-card-text>
                    <v-row dense>
                        <v-col sm="8" md="2" class="d-flex justify-content-end align-items-start">
                            <v-autocomplete
                                v-model="filter.vendor_id"
                                :items="GET_ADMIN_SUPPLIER_SELECTION"
                                item-text="text"
                                item-value="value"
                                auto-select-first
                                label="Vendor"
                                dense
                                hide-details
                                outlined
                                clearable
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col>
                            <!-- First v-menu with v-text-field -->
                            <v-menu
                                v-model="filter.menu_month_date_from"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="filter.date_from"
                                        v-on="on"
                                        dense
                                        outlined
                                        hide-details
                                        readonly
                                        clearable
                                        append-icon="mdi-calendar-clock"
                                        label="Date From"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="filter.date_from" @input="filter.menu_month_date_from = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col>
                            <!-- Second v-menu with v-text-field -->
                            <v-menu
                            v-model="filter.menu_month_date_to"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filter.date_to"
                                    v-on="on"
                                    dense
                                    outlined
                                    hide-details
                                    readonly
                                    clearable
                                    append-icon="mdi-calendar-clock"
                                    label="Date To"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filter.date_to" @input="filter.menu_month_date_to = false"></v-date-picker>
                        </v-menu>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="12" md="3" class="d-flex justify-content-end align-items-center">
                            <v-text-field
                                id="searchBar"
                                v-model="filter.search"
                                label="Search PO #"
                                dense
                                append-icon="mdi-magnify"
                                @click:append="searchInput()"
                                @keydown.enter="searchInput()"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col lg="2">
                            <v-autocomplete
                                :items="PURCHASE_ORDER.PAYMENT_METHOD"
                                v-model="filter.payment_method"
                                label="Payment Method"
                                outlined
                                dense
                                hide-details
                                clearable
                                auto-select-first
                                >

                            </v-autocomplete>
                        </v-col>
                        <v-col lg="2">
                            <v-autocomplete
                                :items="GET_COMPANY_SELECTION"
                                v-model="filter.company_id"
                                label="Company"
                                outlined
                                dense
                                hide-details
                                clearable
                                auto-select-first
                                multiple
                                chips
                                deletable-chips
                                small-chips
                                >

                            </v-autocomplete>
                        </v-col>
                        <v-col lg="2">
                            <v-autocomplete
                                :items="GET_PV_STORE_BRANCHES_SELECTION"
                                v-model="filter.store_branch_id"
                                label="Store Branch"
                                outlined
                                dense
                                hide-details
                                clearable
                                auto-select-first
                                multiple
                                chips
                                deletable-chips
                                small-chips
                                >

                            </v-autocomplete>
                        </v-col>
                        <v-col lg="2">
                            <v-autocomplete
                                v-model="filter.preparer"
                                :items="preparer_selection"
                                label="Prepared By"
                                item-text="name"
                                item-value="id"
                                outlined
                                dense
                                hide-details
                                clearable
                                auto-select-first
                                multiple
                                chips
                                deletable-chips
                                small-chips
                                >

                            </v-autocomplete>
                        </v-col>
                        <v-spacer/>
                        <v-col class="d-flex justify-end align-center mr-3">
                        <v-tooltip left color="#f69f1a">
                                <template v-slot:activator="{ on, attrs }" >
                                    <span v-on="on" v-bind="attrs">
                                        <v-switch
                                            v-model="filter.autonext"
                                            color="#ec2f2f"
                                            :true-value="1"
                                            :false-value="0"
                                            on-icon="mdi-close-circle"
                                            off-icon="mdi-check-circle"
                                            dense
                                        ></v-switch>
                                    </span>
                                </template>
                                <span>Disable Auto Next</span>
                            </v-tooltip>
                            <a :class="`ml-1 badge badge-all ${filter.selected_rush === null ? 'selected' : 'non-selected'}`" @click="filter.selected_rush = null; getAllPurchaseOrder();">all</a>
                            <a :class="`ml-1 badge badge-full ${filter.selected_rush === 1 ? 'selected' : 'non-selected'}`" @click="filter.selected_rush = 1; getAllPurchaseOrder();">rush</a>
                            <a :class="`ml-1 badge badge-none ${filter.selected_rush === 0 ? 'selected' : 'non-selected'}`" @click="filter.selected_rush = 0; getAllPurchaseOrder();">not rush</a>
                        </v-col>
                    </v-row>
                <v-row >
                    <v-col lg="12">
                        <v-data-table
                            v-model="selectedItems"
                            ref="dTable"
                            :headers="headers"
                            :items="items"
                            :item-class="itemRowBackground"
                            :search="search"
                            :options.sync="options"
                            :server-items-length="totalItems"
                            :items-per-page="10"
                            :loading="loaded"
                            :footer-props="{
                                    itemsPerPageOptions: itemsPerPageOptions,
                            }"
                            item-key="id"
                            :show-select="[1,2,3].includes(SELECTED_TAB)"
                            :show-expand="[0, 1, 2, 3, 4, 6, 7].includes(SELECTED_TAB)"
                            loading-text="Loading... Please wait"
                            fixed-header
                            dense
                            disable-sort
                            >
                             <template v-slot:[`item.data-table-expand`]="{ item, expand, isExpanded }">
                            <td class="text-start">
                                <v-tooltip left>
                                    <template #activator="{ on: onTooltip }">
                                        <v-btn
                                            v-on="{ ...onTooltip }"
                                            :class="{'v-data-table__expand-icon--active': isExpanded}"
                                            class="v-data-table__expand-icon"
                                            color="black"
                                            text
                                            icon
                                            x-small
                                            @click="expand(!isExpanded)"
                                        >
                                            <v-icon>mdi-chevron-down</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>View DR Items</span>
                                </v-tooltip>
                            </td>
                        </template>

                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length" class="ma-0 pa-0">
                                <v-col cols="12" lg-4 md-4 class="dark-background ma-0 pa-0" v-if="[2, 3].includes(SELECTED_TAB)">
                                    <v-switch
                                        v-model="expanded_headers.price_change_checkbox"
                                        :true-value="1" 
                                        :false-value="0"
                                        :color="expanded_headers.price_change_checkbox == 1 ? '#f69f1a' : 'red'"
                                        label="Price Changed Only"
                                        class="ma-0 pa-0"
                                    >
                                        <template #label>
                                            <span :style="{ color: '#ffffff'}">
                                                Price Changed Only
                                            </span>
                                        </template>
                                    </v-switch>
                                </v-col>
                                <v-data-table
                                    :headers="[2, 3].includes(SELECTED_TAB) ? expanded_headers.po_items : expanded_headers.dr_items"
                                    :items="[2, 3].includes(SELECTED_TAB) ? changeItems(item.po_items) : item.dr_items"
                                    :loading="expanded_loading"
                                    :item-class="expandedItemRowBackground"
                                    no-data-text="No Price Change"
                                    dark
                                    dense
                                    hide-default-footer
                                    fixed-header
                                    disable-sort

                                >
                                    <template #footer>
                                        <v-row class="pa-4 justify-end" v-if="[2, 3].includes(SELECTED_TAB)">
                                            <v-col>
                                                <v-chip
                                                    color="grey darken-1"
                                                    class="ma-2"
                                                    >
                                                    <span :style="{ color: '#ffffff'}">
                                                        Price Changed
                                                    </span>
                                                </v-chip>
                                            </v-col>
                                        </v-row>
                                    </template>
                                    <template #[`item.button`]="{ item, index }">
                                        <v-btn
                                            color="#f69f1a"
                                            small
                                            icon
                                            @click="showFile(item, index)"
                                        >
                                            <v-icon>
                                                mdi-file-image-outline
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <template #[`item.original_price`]="{ item, index }">
                                        {{item.original_price | currency}}
                                    </template>
                                    <template #[`item.total_original_price`]="{ item, index }">
                                        {{ item.total_original_price | currency }}
                                    </template>
                                </v-data-table>
                            </td>
                        </template>
                            <template #[`item.status`]="{ item }">
                                <a  class="ml-1 badge text-white" :style="`background-color:${STATUS.ADMIN_PURCHASE_ORDER_STATUS.find(e=>e.value === item.status)?.color}`">{{STATUS.ADMIN_PURCHASE_ORDER_STATUS.find(e=>e.value === item.status)?.text}}</a>
                            </template>
                            <template #[`item.created_at`]="{ item }" >
                                <span>{{ moments(item.created_at) }}</span>
                            </template>
                            <template #[`item.total_amount`]="{ item }">
                                <span>&#8369; {{ parseFloat(item.total_amount) | currency }}</span>
                            </template>
                            <template #[`item.amount_to_pay`]="{ item }">
                                <span>&#8369; {{ parseFloat(item.amount_to_pay) | currency }}</span>
                            </template>
                            <template #[`item.action`]="{ item,index }">
                                <v-tooltip top>
                                    <template #activator="{ on }">
                                        <v-btn v-on="on" text icon color="orange" v-if="userAccess.includes('view')" :disabled="loaded">
                                            <v-icon class="btn-action" small @click="ViewEditPO(item,'View',index)">mdi-eye</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>View PO</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template #activator="{ on }">
                                        <v-btn v-on="on" text icon color="blue" v-if="userAccess.includes('edit') && item.status == 1 || restrictions(item.status)" :disabled="loaded">
                                            <v-icon class="btn-action" small @click="ViewEditPO(item,'Update',index)">mdi-square-edit-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Edit PO</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template #activator="{ on }">
                                        <v-btn v-on="on" text icon color="red" v-if="userAccess.includes(('delete' || 'cancel' || 'reset')) && [1,2,3,4,6].includes(item.status) && approver_capacity && item.total_paid_amount == null">
                                            <v-icon class="btn-action" small @click="removePO(item)">mdi-cancel</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Delete, Cancel, Reset PO</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template #activator="{ on }">
                                        <v-btn v-on="on" text icon color="red" v-if="userAccess.includes(('revert')) && [4,6].includes(item.status) && approver_capacity">
                                            <v-icon class="btn-action" small @click="revertPO(item)">mdi-arrow-u-left-top-bold</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Revert Received Items</span>
                                </v-tooltip>
                            </template>
                            <template #[`item.payment_method`]="{ item }" >
                               <span :class="`badge ${!PURCHASE_ORDER.PAYMENT_METHOD.find(e=> e.value == item.payment_method) ? 'badge-danger' : PURCHASE_ORDER.PAYMENT_METHOD.find(e=> e.value == item.payment_method).class}`">
                                    {{ !PURCHASE_ORDER.PAYMENT_METHOD.find(e=> e.value == item.payment_method) ? 'Error payment method' : PURCHASE_ORDER.PAYMENT_METHOD.find(e=> e.value == item.payment_method).text }}</span>
                            </template>
                            <template #[`item.amount_due`]="{ item }" >
                                <span>&#8369; {{ item.amount_due | currency }}</span>
                            </template>
                            <template #[`item.rush`]="{ item }" >
                                <v-badge dot offset-x="-10" :color="item.rush == 0 ? '#ef5350' : 'success'"></v-badge>
                            </template>
                            <template #[`item.remarks`]="{ item }" >
                                <span>{{ item.remarks ? truncateText(item.remarks, 20) : 'N/A'}}</span>
                            </template>
                            <template #footer >
                                <div style="margin: 0px 0px -45px 15px; padding-top: 8px">
                                    <v-btn
                                        color="success"
                                        dark
                                        float
                                        @click="massApproval"
                                        :disabled="![1,2,3].includes(SELECTED_TAB) || loaded"
                                    >
                                            Approve
                                    </v-btn>
                                </div>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
            </v-card>
            <v-dialog v-model="massApprove.dialog" width="400">
                <v-card>
                    <v-card-title>
                     Updating: {{massApprove.currentPO}}
                    </v-card-title>
                    <v-card-text class="d-flex justify-content-center align-items-center">

                        <v-progress-linear
                        v-model="massApprove.value"
                        :buffer-value="100"
                        color="success"
                        :rounded="true"
                    ></v-progress-linear>
                    </v-card-text>
                </v-card>

            </v-dialog>
            <BaseFileViewerComponent
                :payloadId="po_file_item.file_id ? po_file_item.file_id : null"
                :payloadIds="[]"
                :getFilesPath="'getAdminPOFiles'"
                :module="'admin'"
                :folder="'po_file_uploads'"
                :localFiles="false"
            />
            <ConfirmOverrideComponent
                :approve_override_dialog="override.override_dialog"
                :departments_allowed="override.override_departments_allowed"
                :heading="override.heading"
                @closeConfirmOverride="closeConfirmOverride"
            />
        </v-app>
    </div>
</template>
<script>
import swal from "sweetalert2";
import { mapGetters } from 'vuex';
import BaseFileViewerComponent from "@/views/main/Shared/BaseFilesViewerComponent.vue"
import ConfirmOverrideComponent from "@/views/main/Utils/ConfirmOverrideComponent.vue";
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
export default {
    mixins: [
        SharedFunctionsComponentVue
    ],
    data() {
      return {
        massApprove:{
            value:0,
            currentPO:0,
            dialog:false
        },

        selectedItems:[],
        loaded: false,
        btn_loading: false,
        expanded_loading: false,
        totalItems: 0,
        options: {},
        items: [],
        export_items: [],
        itemsPerPageOptions: [5, 10, 15, 50, 100],
        itemsPerPage: 10,
        search: "",
        filter:{
            search:"",
            location:['headoffice','stores'],
            menu_month_date_from:false,
            menu_month_date_to:false,
            date_from:this.$dayjs().startOf('year').format('YYYY-MM-DD'),
            date_to:this.$dayjs().endOf('month').format('YYYY-MM-DD'),
            vendor_id:'',
            payment_method:'',
            autonext: false,
            selected_rush: null,
            company_id:'',
            store_branch_id:'',
            preparer: '',
        },
        for_payment:[],
        refresher:false,
        moments:(date)=>{
            return this.$dayjs(date).format('YYYY-MM-DD')
        },
        awaitingSearch: false,
        headers: [],
        po_headers:[
            { text: "PO #", align: "left", value: "po_num" },
            { text: "Date", align: "left", value: "created_at" },
            { text: "Prepared By", align: "left", value: "user_name" },
            { text: "Supplier", align: "left", value: "vendor_name" },
            { text: "Shipped To", align: "left", value: "location" },
            { text: "Status", align: "left", value: "status"},
            { text: "Rush", align: "left", value: "rush"},
            { text: "Remarks", align: "left", value: "remarks"},
            { text: "Cancel/Revert Remarks", value: "reason" },
            { text: "Update By", align: "left", value: "updated_by"},
            { text: "Amount", align: "left",value:"total_amount"},
            { text: "Amount to Pay", align: "left",value:"amount_to_pay"},
            { text: "Payment Method", align: "left", value: "payment_method"},
            { text: "Action", align: "center", value: "action"},
        ],
        expanded_headers: {
            dr_items:
            [
                { text: "DR#", align: "left", value: "dr_number" },
                { text: "Item Name", align: "left", value: "admin_item_name" },
                { text: "Received Qty", align: "left", value: "received_qty" },
                { text: "Received By", align: "left", value: "received_by" },
                { text: "Received Date", align: "left", value: "receive_date" },
                { text: "Action", align: "left", value: "button" },
            ],
            po_items: 
            [
                { text: "Item Name", align: "left", value: "item_name" },
                { text: "Uom", align: "left", value: "uom" },
                { text: "Quantity", align: "center", value: "quantity" },
                { text: "Previous Price", align: "right", value: "original_price" },
                { text: "Final Price", align: "right", value: "total_original_price" },
            ],

            price_change_checkbox: 1,
        },
        count:0,
        userAccess: [],
        po_file_item:{},
        dr_items: [],
        selected_id: '',
        selected_type: '',
        override: {
            override_dialog: false,
            heading: '',
            reset: null,
            delete: null,
            revert: null,
            errors: [],
            override_departments_allowed: ['admin'],
            override_authenticated: false,
        },
        preparer_selection: [],
        approver_capacity: [],
      }
    },
    components:{
        BaseFileViewerComponent,
        ConfirmOverrideComponent
    },
    computed:{
      ...mapGetters([
        'USER_ACCESS',
        'STATUS',
        // 'GET_NEW_ADMIN_ASSETS_TABLE',
        // 'GET_PURCHASE_ORDER_DISPATCH',
        // 'GET_ASSETS_DATA',
        // 'GET_NEW_PURCHASE_ORDER_TABLE',
        // 'GET_PURCHASE_ORDER_DISPATCH_STATUS',
        'PURCHASE_ORDER',
        // 'GET_PURCHASE_ORDER_DISPATCH_REFRESH','GET_ENTER_BILL_SUCCESS',
        'DIALOGS',
        'SELECTED_TAB',
        'SELECTED',
        'RESET',
        'ACTION',
        'GET_ADMIN_SUPPLIER_SELECTION',
        'USERACCOUNT_LOAD',
        'GET_COMPANY_SELECTION',
        'GET_PV_STORE_BRANCHES_SELECTION',
        'GET_ALL_USERS_SELECT',
        'PO_RECEIVING_DIALOG'
    ]),
    },
    mounted() {
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id);
        // this.$store.dispatch("getAdminSupplierSelection");
        this.$store.dispatch("getCompanySelection");
        this.$store.dispatch("getPaymentVoucherBranchesSelection");
        this.$store.dispatch("getAllSelectUsers", {
            department_id: 1
        });
        this.$store.dispatch("adminPurchaseOrderGet",{
            url: 'administrative-approver-capacity'
        }).then((response)=>{
            this.approver_capacity = response;
        });
      this.getSearchFromUrl()
    },
    methods: {
        async getAllPurchaseOrder(excel = null) {
            if(excel === 1){
                this.btn_loading = true
                let payload = {
                    page: this.options.page,
                    itemsPerPage: this.options.itemsPerPage,
                    sortBy: this.options.sortBy,
                    sortDesc: this.options.sortDesc,
                    status:this.PURCHASE_ORDER.STATUS,
                    url:'exportPurchaseOrder',
                    ...this.filter
                }
                await this.$store.dispatch('adminPurchaseOrderGet', payload).then((response) => {
                    this.export_items = response
                    this.$store.commit("EXCEL_LOADING", false);
                })
                const workbook = new this.$exceljs.Workbook();

                const worksheet = workbook.addWorksheet('PO SUMMARY');
                worksheet.columns = [
                    {header:'PO#', width: 15, key:'po_num'},
                    {header:'PAYMENT TYPE', width: 15, key:'payment_type'},
                    {header:'SUPPLIER', width: 35, key:'vendor_name'},
                    {header:'TERMS', width: 15, key:'payment_terms'},
                    {header:'VENDOR CONTACT PERSON', width: 30, key:'vendor_contact_person'},
                    {header:'PAYMENT METHOD', width: 18, key:'payment_method'},
                    {header:'SHIPPED TO', width: 35, key:'location'},
                    {header:'SHIPPING VIA', width: 15, key:'shipping_via'},
                    {header:'SHIPPED TO CONTACT PERSON', width: 30, key:'po_contact_person'},
                    {header:'SHIPPED TO CONTACT#', width: 25, key:'po_contact_number'},
                    {header:'DATE PREPARED', width: 15, key:'created_at'},
                    {header:'EXPECTED DATE', width: 15, key:'expected_date'},
                    {header:'COMPANY', width: 50, key:'company_name'},
                    {header:'BRANCH', width: 50, key:'store_branch'},
                    {header:'REMARKS', width: 50, key:'remarks'},
                    {header:'PREPARED BY', width: 20, key:'user_name'},
                    {header:'RUSH', width: 15, key:'rush'},
                    // {header:'ITEM NAME', width: 50, key:'name'},
                    // {header:'UOM', width: 15, key:'uom'},
                    {header:'AMOUNT', width: 25, key:'total_amount', style:{numFmt: '#,##0.00'}},
                    {header:'STATUS', width: 14, key:'status'},
                    {header:'CONFIRMED BY', width: 17, key:'confirmed_by'},
                    {header:'CONFIRMED DATE', width: 18, key:'confirmed_date'},
                    {header:'APPROVED BY', width: 17, key:'approved_by'},
                    {header:'APPROVED DATE', width: 18, key:'admin_approved_date'},
                    {header:'EXEC APPROVED BY', width: 17, key:'exec_approved_by'},
                    {header:'EXEC APPROVED DATE', width: 19, key:'exec_approved_date'},
                ]
                let headerRow = worksheet.getRow(1);
                headerRow.eachCell((cell) => {
                    cell.font = { bold: true };
                    cell.alignment = { vertical: 'middle', horizontal: 'center' };
                });

                this.export_items.forEach(item=>{
                    item.total_amount = item.total_amount ? parseFloat(item.total_amount) : 0
                    item.expected_date = this.formatToExcelDate(item.expected_date)
                    item.rush = item.rush == 1 ? 'RUSH' : 'NOT RUSH'
                    item.status = this.STATUS.ADMIN_PURCHASE_ORDER_STATUS.find(e=>e.value === item.status)?.text
                    item.payment_method = this.PURCHASE_ORDER.PAYMENT_METHOD.find(e=>e.value === item.payment_method)?.text
                    item.created_at = this.formatToExcelDate(item.created_at)
                    item.confirmed_date = item.confirmed_date ? this.formatToExcelDate(item.confirmed_date) : ''
                    item.admin_approved_date = item.admin_approved_date ? this.formatToExcelDate(item.admin_approved_date) : ''
                    item.exec_approved_date = item.exec_approved_date ? this.formatToExcelDate(item.exec_approved_date) : ''
                    item.exec_approved_by = item.exec_approved_by
                    worksheet.addRow(item)
                })
                worksheet.eachRow((row) => {
                    row.eachCell((cell, colNumber) => {
                        cell.alignment = { vertical: 'middle', horizontal: colNumber === 18 ? 'right' : 'center' };
                    });
                });

                const worksheet2 = workbook.addWorksheet('PO DETAILED');
                worksheet2.columns = [
                    {header:'PO#', width: 15, key:'po_num'},
                    {header:'PAYMENT TYPE', width: 15, key:'payment_type'},
                    {header:'SUPPLIER', width: 35, key:'vendor_name'},
                    {header:'TERMS', width: 15, key:'payment_terms'},
                    {header:'VENDOR CONTACT PERSON', width: 30, key:'vendor_contact_person'},
                    // {header:'PAYMENT METHOD', width: 18, key:'payment_method'},
                    {header:'SHIPPED TO', width: 35, key:'location'},
                    {header:'SHIPPING VIA', width: 15, key:'shipping_via'},
                    {header:'SHIPPED TO CONTACT PERSON', width: 30, key:'po_contact_person'},
                    {header:'SHIPPED TO CONTACT#', width: 25, key:'po_contact_number'},
                    {header:'DATE PREPARED', width: 15, key:'created_at'},
                    {header:'EXPECTED DATE', width: 15, key:'expected_date'},
                    {header:'COMPANY', width: 50, key:'company_name'},
                    {header:'BRANCH', width: 50, key:'store_branch'},
                    {header:'REMARKS', width: 50, key:'remarks'},
                    {header:'PREPARED BY', width: 20, key:'user_name'},
                    {header:'RUSH', width: 15, key:'rush'},
                    {header:'ITEM NAME', width: 50, key:'name'},
                    {header:'UOM', width: 15, key:'uom'},
                    {header:'AMOUNT', width: 25, key:'total_amount', style:{numFmt: '#,##0.00'}},
                    // {header:'STATUS', width: 14, key:'status'},
                    {header:'CONFIRMED BY', width: 17, key:'confirmed_by'},
                    {header:'CONFIRMED DATE', width: 18, key:'confirmed_date'},
                    {header:'APPROVED BY', width: 17, key:'approved_by'},
                    {header:'APPROVED DATE', width: 18, key:'admin_approved_date'},
                    {header:'EXEC APPROVED BY', width: 17, key:'exec_approved_by'},
                    {header:'EXEC APPROVED DATE', width: 19, key:'exec_approved_date'},
                ]
                let headerRow2 = worksheet2.getRow(1);
                headerRow2.eachCell((cell) => {
                    cell.font = { bold: true };
                    cell.alignment = { vertical: 'middle', horizontal: 'center' };
                });

                this.export_items.forEach(item=>{
                    item.total_amount = parseFloat(item.total_amount)
                    item.expected_date = this.formatToExcelDate(item.expected_date)
                    item.rush = item.rush == 1 ? 'RUSH' : 'NOT RUSH'
                    item.status = this.STATUS.ADMIN_PURCHASE_ORDER_STATUS.find(e=>e.value === item.status)?.text
                    item.payment_method = this.PURCHASE_ORDER.PAYMENT_METHOD.find(e=>e.value === item.payment_method)?.text
                    item.confirmed_by = item.confirmed_by
                    item.approved_by = item.approved_by
                    item.exec_approved_by = item.exec_approved_by
                    item.created_at = this.formatToExcelDate(item.created_at)
                    item.confirmed_date = item.confirmed_date ? this.formatToExcelDate(item.confirmed_date) : ''
                    item.admin_approved_date = item.admin_approved_date ? this.formatToExcelDate(item.admin_approved_date) : ''
                    item.exec_approved_date = item.exec_approved_date ? this.formatToExcelDate(item.exec_approved_date) : ''
                    // worksheet2.addRow(item)
                    item.admin_po_items.forEach(admin_item => {
                        admin_item.expected_date = this.formatToExcelDate(item.expected_date)
                        admin_item.total_amount = parseFloat(admin_item.total_amount)
                        admin_item.user_name = item.user_name
                        admin_item.vendor_name = item.vendor_name
                        admin_item.location = item.location
                        admin_item.status = item.status
                        admin_item.name = admin_item.name
                        admin_item.remarks = admin_item.remarks
                        admin_item.payment_method = item.payment_method
                        admin_item.po_num = item.po_num
                        admin_item.rush = item.rush == 1 ? 'RUSH' : 'NOT RUSH'
                        admin_item.payment_type = item.payment_type
                        admin_item.payment_terms = item.payment_terms
                        admin_item.shipping_via = item.shipping_via
                        admin_item.po_contact_person = item.po_contact_person
                        admin_item.po_contact_number = item.po_contact_number
                        admin_item.company_name = item.company_name
                        admin_item.store_branch = item.store_branch
                        admin_item.vendor_contact_person = item.vendor_contact_person
                        admin_item.uom = admin_item.uom ? admin_item.uom : 'N/A'
                        admin_item.created_at = this.formatToExcelDate(item.created_at)
                        admin_item.confirmed_by = item.confirmed_by
                        admin_item.confirmed_date = item.confirmed_date ? this.formatToExcelDate(item.confirmed_date) : ''
                        admin_item.approved_by = item.approved_by
                        admin_item.admin_approved_date = item.admin_approved_date ? this.formatToExcelDate(item.admin_approved_date) : ''
                        admin_item.exec_approved_by = item.exec_approved_by
                        admin_item.exec_approved_date = item.exec_approved_date ? this.formatToExcelDate(item.exec_approved_date) : ''
                        worksheet2.addRow(admin_item)
                    })
                })
                worksheet2.eachRow((row) => {
                    row.eachCell((cell, colNumber) => {
                        cell.alignment = { vertical: 'middle', horizontal: colNumber === 19 ? 'right' : 'center' };
                    });
                });

                let exportName = `Admin Purchase Order`
                workbook.xlsx.writeBuffer().then((data) => {
                    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                    this.$filesaver.saveAs(blob, `${exportName}.xlsx`);
                });
                this.btn_loading = false
            }else{
                if(this.$refs.dTable){
                    for (let i = 0; i < this.items.length; i += 1) {
                        const item = this.items ? this.items[i] : null;
                        this.$set(this.$refs.dTable.expanded, item.id, false);
                    }
                }

                this.headers = this.po_headers
                this.items = [];
                this.loaded = true;

                if (![0, 2, 3, 10].includes(this.SELECTED_TAB)) {
                    this.headers = this.headers.filter(header => header.value != 'reason');
                }

                let payload = {
                    page: this.options.page ?? 1,
                    itemsPerPage: this.options.itemsPerPage ?? 10,
                    sortBy: this.options.sortBy,
                    sortDesc: this.options.sortDesc,
                    ...this.filter,
                    status:this.SELECTED_TAB == 0 ? '' : [this.SELECTED_TAB],
                    selected_tab: this.SELECTED_TAB,
                    url:'getAllPurchaseOrder'
                };
                this.$store.commit('DATA_TABLE_PAYLOAD',payload)
                this.$store.dispatch('adminPurchaseOrderGet', payload).then((response) => {
                this.items = response.data.data;
                this.loaded = false;
                this.totalItems = response.data.total;
                // this.$store.commit("NEW_PURCHASE_ORDER_TABLE", false);
                this.$store.commit('NEW_ENTER_BILL',false);
                }).catch((e)=>{
                    this.loaded = false;
                });
            }
        },
        searchInput(){
            this.options.page = 1
            this.getAllPurchaseOrder({ query: this.search });
        },
        async ViewEditPO(item,action,index = 0) {
            item.autonext = this.filter.autonext ? this.filter.autonext : 0;
            item.selected_index = index
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", action);
            this.$store.commit('SELECTED',item)
            this.$store.commit('DATA_TABLE_ITEMS',this.items)
            // this.$store.commit('DATA_TABLE_OPTIONS',this.options)
            // this.$store.commit('DATA_TABLE_FILTERS',this.filter)
        },
        async removePO(item) {
            await this.$store.dispatch('adminPurchaseOrderGet', {
                url: 'check-if-has-enter-bill',
                id: item.id,
                model: 'AdminPurchaseOrder',
            }).then((res)=>{
                if(res.id){
                    swal.fire('Action cannot be done!', 'This PO has existing Enter Bill', 'warning')
                    return 0;
                }
                this.$store.dispatch('adminPurchaseOrderGet',{
                    url:`getPurchaseOrderForPrint/${this.PO_RECEIVING_DIALOG ? item.po_id : item.id}`,
                }).then(response=>{
                    let po_items = response.item_list
                    if(po_items.some(e => e.item_paid_amount > 0)){
                        swal.fire('Action cannot be done', 'Due to PO has paid item/s', 'warning')
                        return 0;
                    }else{
                        swal.fire({
                            title: `Are you sure you want to <strong><u style="color: #f44336;">CANCEL</u></strong> ${this.approverCheck(item).includes('reset') ? 'or <strong><u style="color: #f44336;">RESET</u></strong> ?!' : '?!'}`,
                            icon: 'question',
                            showConfirmButton: this.approverCheck(item).includes('reset') ? true : false,
                            confirmButtonColor: '#397373',
                            confirmButtonText: 'Reset PO',
                            // showDenyButton: this.approverCheck(item).includes('delete') ? true : false,
                            // denyButtonText: 'Delete PO',
                            showCancelButton: this.approverCheck(item).includes('cancel') ? true : false,
                            cancelButtonText: 'Cancel PO',
                            reverseButtons: true,
                            allowOutsideClick: false,
                            footer: 'This action is irreversible!',
                            html: `
                                <span id="closeButton" style="position: absolute; top: 5px; right: 15px; font-size: 1.5em; cursor: pointer;">&times;</span>
                            `,
                            didOpen: () => {
                                const closeButton = document.getElementById('closeButton');
                                closeButton.addEventListener('click', () => {
                                    swal.close();
                                });
                            }
                        }).then(result => {
                            if (result.isConfirmed || result.isDenied || result.dismiss === swal.DismissReason.cancel) {
                                this.selected_id = item.id;
                                this.override.reset = result.isConfirmed ? 1 : 0;
                                // this.override.delete = result.isDenied ? 1 : 0;
                                this.override.heading = result.isConfirmed
                                    ? '(Reset PO)'
                                    // : result.isDenied
                                    //     ? '(Delete PO)'
                                        : '(Cancel PO)';
                                this.override.override_dialog = true;
                            }
                        });
                    }
                })
            })
        },
        async massApproval(){
            swal.fire({
                title:"Approve all!",
                text:'Are you sure you want to approve selected Item/s ?',
                icon:'question',
                showCancelButton:true,
                reverseButtons:true
            }).then(async action=>{
                if(action.isConfirmed){
                    let success = []
                    this.loaded = false;
                    this.massApprove.dialog = true
                    for (let i in this.selectedItems) {

                            this.massApprove.currentPO = this.selectedItems[i].id
                            this.massApprove.value += (100 / this.selectedItems.length)
                        try {

                            await this.$store.dispatch('adminPurchaseOrderPost', {
                                url: 'update-status',
                                id: this.selectedItems[i].id,
                                status: parseInt(this.selectedItems[i].status)
                            }).then(response => {
                               success.push(this.selectedItems[i].id)
                            }).catch(e => {
                                throw e; // Rethrow the error to trigger the catch block
                            });
                        } catch (err) {
                            console.log(err);
                            let continueExecution = undefined;
                            if (err.response.status === 406) {
                                continueExecution = await swal.fire({
                                    title: `An error occurred in ${this.selectedItems[i].id}`,
                                    text: '- You are not authorized to confirm this purchase order. Do you want to continue?',
                                    icon: 'error',
                                    showCancelButton: true,
                                    confirmButtonText: 'Yes',
                                    cancelButtonText: 'No'
                                });
                            } else {
                                continueExecution = await swal.fire({
                                    title: `An error occurred in ${this.selectedItems[i].id}`,
                                    text: '- Do you want to continue?',
                                    icon: 'error',
                                    showCancelButton: true,
                                    confirmButtonText: 'Yes',
                                    cancelButtonText: 'No'
                                });
                            }

                            if (!continueExecution.isConfirmed) {
                                break; // Exit the loop if the user chooses not to continue
                            }
                        }
                    }
                    this.massApprove.value = 0
                    this.loaded = true;
                    swal.fire('Approved!',`- ${success}`,'success')
                    this.selectedItems = []
                    this.massApprove.dialog = false
                    this.getAllPurchaseOrder()
                }
            })
        },
        showFile(item, index){
            this.$store.commit('VIEW', item);
        },
        itemRowBackground(item){
            if(item.is_overdue == 1 && this.SELECTED_TAB !== 11){
                return 'overdue';
            }
        },
        // getDrItems(id, isExpanded){
        //     if(id && isExpanded == false){
        //         this.dr_items = [];
        //         this.expanded_loading = true;
        //         this.$store.dispatch('adminPurchaseOrderGet', {
        //             url: 'get-dr-items-po/' + id,
        //         }).then(response => {
        //             this.dr_items = response
        //             this.expanded_loading = false;
        //         })
        //     }
        // }
        async closeConfirmOverride(user_id, authenticated = false) {
            if (authenticated) {
                document.activeElement.blur();
                this.override.override_dialog = false;
                this.override_confirm = true;
                swal.fire({
                    title: `${this.override.reset
                        ? 'Reset'
                        : this.override.revert
                            ? 'Revert'
                            : 'Cancel'} Reason Remarks`,
                    input: 'text',
                    inputLabel: 'Enter Reason',
                    showConfirmButton:true,
                    confirmButtonColor: '#397373',
                    confirmButtonText:'Confirm',
                    inputValidator: (value) => {
                        if (!value) {
                            return 'You need to write something!'
                        }
                    },
                    allowOutsideClick: () => {
                        const popup = swal.getPopup()
                        popup.classList.remove('swal2-show')
                        setTimeout(() => {
                            popup.classList.add('animate__animated', 'animate__headShake')
                        })
                        setTimeout(() => {
                            popup.classList.remove('animate__animated', 'animate__headShake')
                        }, 500)
                        return false
                    }
                }).then((result) => {
                    if (result.isConfirmed && result.value != '') {
                        let payload = {
                            id: this.selected_id,
                            reason: result.value,
                            reset: this.override.reset,
                            delete: this.override.delete,
                            revert: this.override.revert,
                            url: "purchaseorder/delete/${id}"
                        }
                        this.$store.dispatch('adminPurchaseOrderPost',payload).then((response) => {
                            swal.fire(response.title,response.msg,response.status)
                            this.getAllPurchaseOrder()
                            this.override.revert = null;
                            this.override.delete = null;
                            this.override.revert= null;
                        })
                    }
                })

            } else {
                this.override.override_dialog = false;
            }
        },
        restrictions(status){
            if((status == 2 || status == 3) && (this.USERACCOUNT_LOAD.manager == 1 || this.USERACCOUNT_LOAD.department_id == 6)){
                return true
            }else{
                return false
            }
        },
        async getBranches(){
            try{
                this.clonedStores = await this.getStoreBranches()
            } catch (error) {
                console.error(error)
            }
        },
        approverCheck(item){
            let checker = []
            if(this.userAccess.includes('reset') && item.status !== 1 && parseFloat(this.approver_capacity.range) > parseFloat(item.total_amount)){
                checker.push('reset');
            }
            if(this.userAccess.includes('cancel') && parseFloat(this.approver_capacity.range) > parseFloat(item.total_amount)){
                checker.push('cancel');
            }
            if(this.userAccess.includes('delete')){
                checker.push('delete');
            }
            if(this.userAccess.includes('revert') && parseFloat(this.approver_capacity.range) > parseFloat(item.total_amount)){
                checker.push('revert');
            }
            return checker;
        },
        async revertPO(item){
            this.override.revert = 1
            await this.$store.dispatch('adminPurchaseOrderGet',{
                url:`getPurchaseOrderForPrint/${this.PO_RECEIVING_DIALOG ? item.po_id : item.id}`,
            }).then(response=>{

                let po_items = response.item_list
                if(po_items.some(e => e.received_qty == 0) || po_items.some(e => e.item_paid_amount > 0)){
                    swal.fire('Revert is not applicable!', `${po_items.some(e => e.item_paid_amount > 0)
                    ? 'Due to PO has paid item/s' : 'There is no revertible stock'}`, 'warning')
                }else if(po_items.some(e => e.received_qty > 0) && po_items.some(e => e.item_paid_amount == 0)){
                    swal.fire({
                        title: `Are you sure you want to <strong><u style="color: #f44336;">Revert</u></strong>?`,
                        icon: "warning",
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                        showCancelButton: true,
                        allowOutsideClick: false,
                        reverseButtons: true,
                        confirmButtonColor: "#397373",
                        cancelButtonColor: "grey",
                        footer: 'This action is irreversible! And will revert the current received stocks',

                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            this.selected_id = item.id
                            this.override.override_dialog = true;
                        }else{
                            this.override.revert = 0
                        }
                    });
                }
            });
        },
        getSearchFromUrl() {
            this.filter.search = '';
            if (Object.keys(this.$route.query).length != 0) {
                this.filter.search = atob(this.$route.query.search);
                this.searchInput();
            }
        },
        changeItems(po_items){
            let items = po_items;
            if(this.expanded_headers.price_change_checkbox == 1 && items){
                let changedPriceItems = items.filter(e => e.original_price != e.total_original_price)
                return changedPriceItems;

            }else{
                return items;
            }
        },
        expandedItemRowBackground(item){
            if(item.original_price != item.total_original_price && this.expanded_headers.price_change_checkbox == 0){
                return 'grey darken-1';
            }
        },
    },
    watch: {
        options: {
            handler() {
                this.getAllPurchaseOrder();
            },
            deep: true,
        },
        USER_ACCESS: {
            handler(val) {
                if (val != "fail") {
                    this.userAccess = val.map(e=>e.actions_code)
                }
            },
            deep:true
        },
        SELECTED_TAB:{
            handler(val){
                this.options.page = 1
                this.selectedItems = []
                this.getAllPurchaseOrder()
            }
        },
        SELECTED:{
            handler(val){
                if(!val){
                    this.getAllPurchaseOrder()
                }
            },
        },
        "PURCHASE_ORDER.EXPORT":{
            handler(val){
                if(val){
                    this.getAllPurchaseOrder(1)
                }
                this.$store.commit("EXPORT_PURCHASE_ORDER", false);
            }
        },
        'PURCHASE_ORDER.REFRESH':{
            handler(val){
                if(val === true){
                    this.getAllPurchaseOrder()
                }
		        this.$store.commit('PURCHASE_ORDER_DISPATCH_REFRESH', false)
            }
        },
        'filter':{
            handler(val){
                if(!val.search){
                    this.getAllPurchaseOrder()
                }
            },
            deep:true
        },
        GET_ALL_USERS_SELECT:{
            handler(val){
                if(!!val){
                    this.preparer_selection = val.filter(e => e.department_id == 1);
                }
            }
        },
        '$route.query.search':{
            handler(val){
                if(val){
                    this.getSearchFromUrl();
                }
            }
        },
        'expanded_headers.price_change_checkbox':{
            handler(val){
                this.changeItems();
            }
        },
    }
}
</script>
<style>
    .overdue{
        background-color: #fecaca ;
    }
    .dark-background {
        background-color: #1e1e1e; /* or any dark color you prefer */
        color: #1e1e1e; /* to ensure the text is visible on the dark background */
        padding: 0px;
        margin: 0px;
    }
</style>
