@@ -0,0 +1,337 @@
<template>
    <v-container fluid>
        <v-card elevation="0">
            <v-card-title>
                <v-row>
                    <v-col class="text-left pl-1" v-if="GET_TABS == 'item'">
                        <v-row class="pt-3 pl-9">
                            <v-tooltip top color="#f69f1a">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        @click="openSelectedItem()"
                                        fab
                                        text
                                        small
                                        class="mr-4"
                                        color="#f69f1a"
                                        v-bind="attrs"
                                        v-on="on"
                                        :disabled="!selectAllItems && selected.length == 0 "
                                    >
                                        <v-icon>mdi-form-select</v-icon>
                                    </v-btn>
                                </template>
                                <span>Selected Item</span>
                            </v-tooltip> 
                            <v-checkbox
                                class="pr-2"
                                v-model="selectAllItems"
                                label="Select All"
                                :true-value="true"
                                :false-value="false"
                            >
                            </v-checkbox>
                        </v-row>
                    </v-col>

                    <v-col v-if="GET_TABS == 'item'" class="text-left pl-2">
                        <v-row class="pt-3 pr-5">
                            <v-spacer/>
                            <v-spacer/>
                            <v-autocomplete
                                :items="item_type_selection"
                                v-model="filterTypes"
                                label="Item Types"
                                dense
                                outlined
                                append-icon="mdi-magnify"
                                clearable
                                @change="list()"
                                style="width: 70px"
                                auto-select-first
                                class="pr-1"
                                >
                            </v-autocomplete>
                            <v-autocomplete
                                :items="item_category_selection"
                                v-model="filterCategories"
                                label="Item Categories"
                                dense
                                outlined
                                append-icon="mdi-magnify"
                                clearable
                                style="width: 150px"
                                auto-select-first
                                @change="list()"
                                class="pr-1"
                                >
                            </v-autocomplete>
                            <v-text-field
                                label="Search Model"
                                dense
                                outlined
                                v-model="search"
                                append-icon="mdi-magnify"
                                @click:append="list()"
                                @keypress.enter="list()"
                                style="width: 200px"
                                clearable
                                >
                            </v-text-field>
                        </v-row>
                    </v-col>

                    <v-col v-if="['checklist','template'].includes(this.GET_TABS)" >
                        <v-row class="pt-3 pr-5">
                            <v-col lg="6" class="text-left" >
                                <v-text-field
                                    v-if="['checklist','template'].includes(this.GET_TABS)"
                                    label="Search Name"
                                    dense
                                    outlined
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    @click:append="list()"
                                    @keypress.enter="list()"
                                    clearable
                                    style="width: 300px"
                                    class="pl-5"
                                    >
                                </v-text-field>
                            </v-col>

                            <v-col lg="6" class="text-right">
                                <v-btn
                                    v-if="this.GET_TABS == 'checklist'"
                                    dark
                                    small
                                    color="primary" 
                                    @click="batchAddition()"
                                >
                                    Batch Addition
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-data-table
                    v-model="selected"
                    :items="dataTable.items"
                    :headers="itemHeaders"
                    :options.sync="dataTable.options"
                    :server-items-length="dataTable.totalItems"
                    :loading="!dataTable.loaded"
                    :show-select="GET_TABS === 'item' ? true : false"
                    dense
                    item-key="item_id"
                >   

                    <template v-slot:header.data-table-select="{ header }">
                        <v-simple-checkbox style="display: none;"></v-simple-checkbox>
                    </template>

                    <template v-slot:item.type_id="{ item }">
                        <v-chip small :color="itemTypeColor(item)" text-color="white" >
                            {{ item.type_id == 1 ? 'PT' : 'HT' }}
                        </v-chip>
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <v-btn icon text @click="view('View', item )" color="orange" small>
                            <v-icon small >mdi-eye</v-icon>
                        </v-btn>
                        <v-btn v-if="!selected?.length > 0 && !selectAllItems == true" icon text @click="edit('Edit', item)" color="blue" small>
                            <v-icon small>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon text @click="deleteItem(item)" color="red" small>
                            <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                    </template>
                   
                </v-data-table>
            </v-card-text>
        </v-card>

        <!-- BATCH ADDITION DIALOG -->
        <v-dialog v-model="batchAdditionDialog" persistent max-width="60%">
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Batch Addition</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                            text
                            icon
                            color="gray"
                            class="float-right"
                            @click="closeBatchAddition()"
                        >
                        <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                
                <v-card-text class="ma-0">
                    <v-card class="pa-1 ma-1" :height="text_area_fields.length < 13 ? '' : '600px' " style="overflow-y:auto; overflow-x: hidden;" elevation="0">
                        <v-row>
                            <v-col class="text-right pb-5 pr-4 pt-1 mt-3">
                                <v-btn
                                    dark
                                    x-small
                                    fab
                                    color="primary"
                                    @click="manualAddition()"
                                >
                                    <v-icon>
                                        mdi-plus
                                    </v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        
                        <v-row class="mx-0 pa-0 ma-0" v-for="(text_area_field,i) in text_area_fields" :key="i">
                            <v-text-field
                                v-model="text_area_fields[i].dataFromExcel"
                                dense
                                outlined
                                label="Name"
                                class="pr-1"
                                rows="1"
                                @paste="onPaste"
                                :autofocus="batchAdditionDialog"
                                @click="$event.target.focus()"
                                >
                            </v-text-field>
                            
                            <v-text-field
                                v-model="text_area_fields[i].dataFromExcel2"
                                dense
                                outlined
                                label="Code"
                                class="pr-1"
                                rows="1"
                                @paste="onPaste"
                                :autofocus="batchAdditionDialog"
                                @click="$event.target.focus()"
                            >
                            </v-text-field>

                            <v-text-field
                                v-model="text_area_fields[i].dataFromExcel3"
                                dense
                                outlined
                                label="Description"
                                class="pr-1"
                                rows="1"
                                @paste="onPaste"
                                :autofocus="batchAdditionDialog"
                                @click="$event.target.focus()"
                            >
                            </v-text-field>

                            <v-btn
                                dark
                                x-small
                                fab
                                color="red"
                                @click="clearItem(i)"
                            >
                                <v-icon
                                dark
                                small
                                >
                                mdi-minus
                                </v-icon>
                            </v-btn>
                        </v-row>
                    </v-card>

                    <v-row>
                        <div class="col-12">
                            <v-btn
                                class="float-right ml-2"
                                @click="submitBatchAddition()"
                            >
                                Submit
                            </v-btn>

                            <v-btn
                                class="float-right"
                                dark
                                color="secondary"
                                @click="clearBatchAdditionFields()"
                            >
                                Clear
                            </v-btn>
                        </div>
                    </v-row>
                </v-card-text>

            </v-card>
        </v-dialog>

        <!-- SELECTED ITEM DIALOG -->
        <v-dialog v-model="selectedItemDialog" persistent max-width="55%">
            <v-card>
                <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                    <span class="headline">Selected Item</span>
                    <v-row class="m-0">
                        <v-col cols="pull-right-10 p-2">
                            <v-btn
                                text
                                icon
                                color="gray"
                                class="float-right"
                                @click="closeSelectedItem()"
                            >
                            <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-title>

                <v-card-text class="ma-1 pa-1">
                    <v-card>
                        <v-card-title>
                            <v-row>
                                <v-col lg="3" class="d-flex justify-content-center text-align-center">
                                    <span style="color:rgb(118, 122, 1); font-size:12px" class="mt-3"> SELECTED</span>
                                </v-col>
                                <v-spacer />
                                <v-col lg="5" class="d-flex justify-content-center text-align-center">
                                    <v-text-field
                                        label="Search Model"
                                        class="mt-3 mr-3"
                                        dense
                                        v-model="filterSearch"
                                        append-icon="mdi-magnify"
                                        >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-card-text>
                            <v-card class="pa-1 ma-1" max-height="400px" style="overflow-y:auto" elevation="0">
                                <v-row class="ma-1" style="1">
                                    <v-col lg='3' v-for="(select,index) in filterSelected" :key="index">
                                        <div class="column-content">
                                            <v-chip
                                                class="w-100"
                                                color="#CC6600"
                                                label
                                                text-color="white"
                                                style="cursor:pointer"
                                                @click="selectedItem(select)"
                                            >
                                                {{ select.model }}
                                            </v-chip>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-divider />

                            <v-row style="1">
                                <v-col lg6 class="ml-1 mr-1">
                                    <v-autocomplete
                                        v-model="checklist.fillables.template_id"
                                        :items="GET_ALL_LIST_TEMPLATE"
                                        item-value="id"
                                        item-text="name"
                                        label="Select Template"
                                        auto-select-first
                                        dense
                                        outlined
                                        clearable
                                        :value="this.GET_ALL_LIST_TEMPLATE.find(e=>e.id)?.name"
                                        @change="getTemplate()" 
                                        >   
                                    </v-autocomplete>
                                </v-col>

                                <v-col lg6 class="ml-1 mr-1">
                                    <v-autocomplete
                                        v-model="checklist.check_list_templates"
                                        :items="GET_CHECK_LISTS_SELECTION"
                                        item-value="id"
                                        item-text="name"
                                        label="Check List"
                                        append-icon="mdi-plus"
                                        auto-select-first
                                        outlined
                                        dense
                                        multiple 
                                        small-chips
                                        >
                                        <template v-slot:selection="{ attrs, item }">
                                            <v-chip
                                            v-bind="attrs"
                                            small
                                            dark
                                            color="#397373"
                                            >
                                                <strong>{{ item.name }}</strong>
                                            </v-chip>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col class="d-flex justify-content-center">
                                    <v-tooltip bottom color="#f69f1a">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                @click="submitUpdate()"
                                                fab
                                                text
                                                small
                                                class="mr-4"
                                                color="#f69f1a"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>mdi-check-outline</v-icon>
                                            </v-btn>
            
                                        </template>
                                        <span>Update Item Checklist</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-card-text>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2'
export default {

    data:()=>({
        dataTable:{
            items:[],
            selectedAllItems: [],
            loaded:true,
            totalItems:0,
            options:{},
            headers: [
                { text:'Model',value:'model' },
                { text:'Name',value:'item_name' },
                { text:'Item Type',value:'type_id' },
                { text:'Category',value:'category' },
                { text:'Actions',value:'actions' },
            ],
            templateHeaders:[
                { text:'Name',value:'name' },
                { text:'Code',value:'code' },
                { text:'Description',value:'description'},
                { text:'Actions',value:'actions' },
            ],
            listHeaders:[
                { text:'Name',value:'name' },
                { text:'Code',value:'code' },
                { text:'Description',value:'description'},
                { text:'Actions',value:'actions' },
            ],
        },
        route:{
            list:{
                dispatch_name:'itemsGet',
                url:'item-with-check-list'
            },
            view:{
                dispatch_name:'itemsGet',
                url:'item-with-check-list'
            },
            delete:{
                dispatch_name:'itemsPost',
                url:'delete-item-check-list'
            }
        },
        batchAdditionDialog: false,
        text_area_fields: [],
        text_area_field: {
            dataFromExcel: "",
            dataFromExcel2: "",
            dataFromExcel3: "",
        },
        isDone: true,
        dataFromExcel: "",
        selected: [],
        filterSelected: [],
        selectedItems: [],
        selectedItemDialog: false,
        checklist: {
            fillables:{
                template_id: '',
            },
            check_list_templates:[],
            check_list_trash:[],
            template_lists: [],
        },
        activeTabs: '',
        filterTypes: "",
        filterCategories: "",
        item_type_selection: [],
        item_category_selection: [],
        selectAllItems: false,
        result: [],
        search: '',
        filterSearch: '',
    }),

    computed: {
        ...mapGetters([
            'TESTER_CHECK_LISTS',
            'GET_REFRESH',
            'GET_CHECK_LIST_TABLE',
            'GET_CHECK_LIST',
            'GET_TABS',
            'GET_CHECKLIST_DISPATCH',
            'GET_CHECKLIST_DIALOG_TITLE',
            'DIALOG',
            'GET_CHECKLIST_DIALOG_VIEW',
            'ACTION',
            'GET_ALL_LIST_TEMPLATE',
            'GET_CHECK_LISTS_SELECTION',
            'GET_ITEM_TYPES',
            'GET_ITEM_CATEGORIES',
        ]),

        itemHeaders(){
            if(this.GET_TABS =='item'){
                this.$store.commit('REFRESH', true)

                return this.dataTable.headers
            } else if (this.GET_TABS == 'template') {
                this.$store.commit('REFRESH', true)

                return this.dataTable.templateHeaders
            } else if (this.GET_TABS == 'checklist') {
                this.$store.commit('REFRESH', true)

                return this.dataTable.listHeaders
            } else {
                return this.dataTable.headers
            }
        },

    },
    mounted(){
        this.$store.dispatch('getListTemplate',{code:'SR'})
        this.$store.dispatch('getLists')
        this.$store.dispatch('getItemTypes').then((response)=>{
          this.item_type_selection = response.data.filter(e => {return e.text != 'SP'})
        })
        this.$store.dispatch('getItemCategories').then((response)=>{
          this.item_category_selection = response.data.filter(e => {return e.code != 'SP'})
        })

    },
    methods: {

        async list(){
            this.dataTable.loaded = false,
            this.dataTable.items = [],
            await this.$store.dispatch(this.GET_CHECKLIST_DISPATCH.get[0],{
                url:this.GET_CHECKLIST_DISPATCH.get[1],
                page:this.dataTable.options.page,
                itemsPerPage:this.dataTable.options.itemsPerPage,
                activeTab: this.GET_TABS, 
                filterTypes: this.filterTypes,
                search: this.search,
                filterCategories: this.filterCategories,
                selectAll: 1,
                ...this.filters
            }).then(response =>{
                this.dataTable.loaded = true
                this.$store.commit('REFRESH', false)
                this.dataTable.selectedAllItems = response.data.items
                this.dataTable.items = response.data.data.data
                this.dataTable.totalItems = response.data.data.total
            }) .catch( err =>{
                console.log(err)
                Swal.fire('Error!','- an error occured','error')
            })

        },

        view(action = 'View', item){
            this.$store.commit('CHECKLIST_DIALOG_VIEW', item)
            this.$store.commit('DIALOG', true)
            this.$store.commit('ACTION', action)
        },

        edit(action = 'Edit', item){
            this.$store.commit('CHECKLIST_DIALOG_VIEW', item)
            this.$store.commit('DIALOG', true)
            this.$store.commit('ACTION', action)
        },

        deleteItem(item){
            this.activeTabs = this.GET_TABS;

            Swal.fire({
                title:'Warning',
                text:`- Are you sure you want to remove ${this.GET_CHECKLIST_DIALOG_TITLE.toLowerCase()}?`,
                icon: 'question',
                showCancelButton:true,
                reverseButtons:true
            }).then(action=>{
                if(action.isConfirmed){
                    this.$store.dispatch(this.GET_CHECKLIST_DISPATCH.delete[0],{
                        url: this.GET_CHECKLIST_DISPATCH.delete[1],
                        item_id:item.item_id,
                        id: item.id,
                        template_id: item,
                    }).then(response=>{
                        Swal.fire('Success!','','success')
                        this.$store.commit('TAB',this.activeTabs)
                        this.list()
                        this.$store.commit('REFRESH', true)
                    }).catch(err=>{
                        Swal.fire('Error!','','error')
                        console.log(err)
                    })
                }
            })
        },

        batchAddition() {
            this.isDone = true;
            this.batchAdditionDialog = true;
            this.text_area_fields = [];

            if (this.text_area_fields.length < 1) {
                this.text_area_fields.push({
                    dataFromExcel: "",
                    dataFromExcel2: "",
                    dataFromExcel3: "",
                });
            }
        },

        closeBatchAddition() {
            this.text_area_fields = []
            this.batchAdditionDialog = false;
        },

        submitBatchAddition() {
            Swal.fire({
                title:'Add',
                text:'- Are you sure you want to Submit?',
                icon: 'question',
                reverseButtons: true,
                showCancelButton:true,
            }).then(action=>{
                if(action.isConfirmed){
                    this.$store.dispatch(this.GET_CHECKLIST_DISPATCH.add[0],{
                        url:this.GET_CHECKLIST_DISPATCH.add[1],
                        fillables: this.text_area_fields,
                        batchAddition: 1,
                    }).then(response=>{
                        Swal.fire('Success!','','success')
                        this.list()
                        this.closeBatchAddition()                        
                        this.$store.commit('REFRESH', true)
                    }).catch(err=>{
                        console.log(err)
                        Swal.fire('Error!',err.response.data.message,'error')
                    })
                }
            })
        },
        
        manualAddition() {
            this.text_area_fields.push({
                dataFromExcel: "",
                dataFromExcel2: "",
                dataFromExcel3: "",
            });        
        },

        myFunction() {
            this.text_area_fields = this.result
            this.batchAdditionDialog = true;
        },

        onPaste(evt) {
            var pastedText = evt.clipboardData.getData("Text");

            if (!pastedText) {
                return false;
            } else {
                this.result = [] // para sa tuloy tuloy na pag paste ng hindi nabubura ang laman -> for future purposes
                var rows = pastedText.split("\r\n");
                for (let y = 0; y < rows.length; y++) {
                    let cells = rows[y].split("\t");
                    if (cells[0] !== '') {
                        this.result.push({
                            dataFromExcel: cells[0],
                            dataFromExcel2: cells[1],
                            dataFromExcel3: cells[2],
                        });
                    }
                }
            }
            
            this.isDone = false;
            this.closeBatchAddition();
            setTimeout(this.myFunction, 1);
        },

        clearBatchAdditionFields() {
            this.isDone = true;
            this.text_area_fields = [];

            if (this.text_area_fields.length < 1) {
                this.text_area_fields.push({
                    dataFromExcel: "",
                    dataFromExcel2: "",
                    dataFromExcel3: "",
                });
            }

        },

        clearItem(i) {
            this.text_area_fields = this.text_area_fields.filter((e, index) => {
                if (i != index) {
                    return e
                }
            });
        },

        openSelectedItem() {
            this.selectedItemDialog = true;
        },

        closeSelectedItem() {
            this.selected = [];
            this.selectAllItems = false;
            this.search = ''
            this.checklist.check_list_trash = [];
            this.checklist.check_list_templates = [];
            this.checklist.template_lists = [];
            this.selectedItemDialog = false;
			this.checklist.fillables.template_id = '';
        },

        selectedItem(select) {
            if(!this.selected.find(e => select.item_id == e.item_id)){
                this.selected.push(select)
            } else {
                this.selected = this.selected.filter(e=>{
                    if(e.item_id != select.item_id) {
                        return e
                    }
                })
            }
        },
        
        submitUpdate() {
            this.activeTabs = this.GET_TABS;

            Swal.fire({
                title:'Update',
                text:'- Are you sure you want to Update?',
                icon: 'question',
                showCancelButton:true,
                reverseButtons: true,
            }).then(action=>{
                if(action.isConfirmed){
                    this.$store.dispatch(this.GET_CHECKLIST_DISPATCH.update[0],{
                        url:this.GET_CHECKLIST_DISPATCH.update[1],
                        check_list: this.checklist.check_list_templates,
                        selectItems: this.selected,
                    }).then(response=>{
                        Swal.fire('Success!','','success')
                        this.$store.commit('TAB',this.activeTabs)
                        this.list()
                        this.closeSelectedItem()
                        this.$store.commit('REFRESH', true)
                    }).catch(err=>{
                        Swal.fire('Error!',err.response.data.message,'error')
                    })
                }
            })
        },

        getTemplate(){
            this.checklist.template_lists             = this.GET_ALL_LIST_TEMPLATE.find(e=>{if(e.id == this.checklist.fillables.template_id){return e.check_list_templates}});
            this.checklist.check_list_templates       = this.checklist.template_lists.check_list_templates
        },

        itemTypeColor(item) {
            if(item.type_id == 1){
                return 'blue'
            } else {
                return 'red'
            }
        },
      
    },

    watch: {
        
        'dataTable.options':{
            handler(val){
                this.list()
            }
        },

        GET_REFRESH:{
            handler(val){
                if(val){
                    this.list()
                }

            }
        },

        route:{
            handler(val){
                
            }
        },
        
        'selectAllItems': {
            handler(val){
                this.selected = val ? this.dataTable.selectedAllItems : [] 
                this.selectedItems = this.selected; 
            }
        },

        'filterSearch': {
            handler(val) {
                if(val == ''){
                    this.filterSelected = this.selected
                } else {
                    this.filterSelected = this.filterSelected.filter((select) =>
                        select.model == val
                    );
                }
            }
        },

        'selected': {
            handler(val) {
                this.filterSelected = val
            }
        },
    },

}
</script>

<style scoped>
.v-input--selection-controls {
    margin-top: 0px;
    padding-top: 0px;
}

.column-content {
    padding: 5px;
    border: 1px solid #ccc;
    background-color: #f0f0f0;
}
</style>