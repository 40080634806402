import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';

import './registerServiceWorker'
import store from './store';
import vuetify from './plugins/vuetify';

import 'bootstrap/dist/css/bootstrap.css';
import VueCurrencyFilter from 'vue-currency-filter';
window.$ = window.jQuery = require('jquery');
import { VueMaskDirective } from 'v-mask';
import DatetimePicker from 'vuetify-datetime-picker';
import converter  from 'currency-to-words'
import {QueryBuilder} from '@/views/main/Shared/QueryBuilder.js'
import VuetifyMoney from "vuetify-money";

import dayjs from './plugins/dayjs'
import exceljs from './plugins/exceljs'
import filesaver from './plugins/filesaver'
import jszip from './plugins/jszip'
import jspdf from './plugins/jspdf'

Vue.use({
  install: function (Vue) {
    Vue.prototype.$dayjs = dayjs
    Vue.prototype.$exceljs = exceljs
    Vue.prototype.$filesaver = filesaver
    Vue.prototype.$jszip = jszip
    Vue.prototype.$jspdf = jspdf
  }
})


// window.Pusher = require('pusher-js');
// window.Pusher = Pusher;
Vue.use(VuetifyMoney)
Vue.use(DatetimePicker);
Vue.use(converter);
Vue.use(VueCurrencyFilter, {
    symbol: '',
    thousandsSeparator: ',',
    fractionCount: 2,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: true
})
Vue.directive('mask', VueMaskDirective);

Vue.config.productionTip = false;

Vue.use({
  install: function(Vue, options){
      Vue.prototype.$jQuery = require('jquery'); // you'll have jQuery anywhere in your vue project
      }
  });

Vue.filter('str_limit', function (value, size) {
    if (!value) return '';
    value = value.toString();

    if (value.length <= size) {
        return value;
    }
    return value.substr(0, size) + '...';
});

Vue.filter('formatDate', function (value) {
  if (value) {
    return dayjs(String(value)).format('MM/DD/YYYY')
  }
});

Vue.filter('formatDateTime', function (value) {
  if (value) {
    return dayjs(String(value)).format('MM/DD/YYYY hh:mm A')
  }
});

Vue.filter('formatDateTime24hours', function (value) {
  if (value) {
    return dayjs(String(value)).format('MM/DD/YYYY HH:mm')
  }
});

Vue.filter('formatMonth', function (value) {
  if (value) {
    return dayjs(String(value)).format('MM-YYYY')
  }
});

Vue.filter('formatMonthSoa', function (value) {
  if (value) {
    return dayjs(String(value)).format('MMM YYYY')
  }
});

Vue.filter('thousandSeprator', function (value) {
  if (value !== '' || value !== undefined || value !== 0 || value !== '0' || value !== null) {
      return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  } else {
      return value;
  }
});

Vue.filter('capitalize', function (value) {
  if (!value) return '';
  return value.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
});

Vue.filter('uppercase', function (value) {
  if (!value) return '';
  return value.toUpperCase()
})

Vue.filter('toWords', function (value) {
  if (!value) return '';
  return converter(value,'peso','cent');
})

Vue.filter('percentTwoDecimals', function (value, decimal_places = 2) {
    if (!value) {
        value = 0
    }

    value = value * 100
    value = Math.round(value * Math.pow(10, decimal_places)) / Math.pow(10, decimal_places)
    value = value + '%'
    return value
})

Vue.filter('formatDateWithDay', function (value) {
  if (value) {
    return dayjs(String(value)).format('dddd (MMMM D, YYYY)');
  }
});

Vue.filter('formatLongDate', function (value) {
  if (value) {
    return this.$dayjs(String(value)).format('MMMM D, YYYY');
  }
});

Vue.filter('formatDateYMD', function (value) {
  if (value) {
    return dayjs(String(value)).format('YYYY-MM-DD');
  }
});

Vue.filter('formatDateYM', function (value) {
  if (value) {
    return dayjs(String(value)).format('YYYY-MM');
  }
});

Object.defineProperty(Vue.prototype, 'ENUMS', { value: window.ENUMS });
Object.defineProperty(Vue.prototype, 'Lodash', { value: window._ });
Vue.prototype.$queryBuilder = new QueryBuilder();
import CKEditor from '@ckeditor/ckeditor5-vue2';
Vue.use(CKEditor);

Vue.use
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  VueCurrencyFilter,
  jQuery,
  VueMaskDirective,
  DatetimePicker,
  VuetifyMoney,
}).$mount('#app');
