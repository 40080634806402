<template>
    <div class="w-100">
        <v-app id="my_vue_app">
            <v-card>
                <v-card-title>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" sm="10" class="d-flex justify-content-start">
                                <v-row>
                                    <v-col cols="12" lg="10" class="d-flex justify-content-between">
                                        <v-container fluid class="d-flex justify-content-between">
                                            <v-row>
                                                <v-col>
                                                    <v-autocomplete
                                                        v-model="vendor"
                                                        :items="GET_ADMIN_SUPPLIER_SELECTION"
                                                        item-text="text"
                                                        item-value="value"
                                                        auto-select-first
                                                        outlined
                                                        label="Vendor"
                                                        required
                                                        small-chips
                                                        deletable-chips
                                                        multiple
                                                        dense
                                                        clearable
                                                    ></v-autocomplete>
                                            </v-col>
                                            <v-col>
                                                <v-autocomplete
                                                    v-model="admin_classification_id"
                                                    :items="GET_ADMIN_CLASSIFICATION_SELECTION"
                                                    :search-input.sync="search_classification"
                                                    item-text="text"
                                                    item-value="value"
                                                    auto-select-first
                                                    outlined
                                                    label="Classification"
                                                    required
                                                    dense
                                                    clearable
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col>
                                                <v-autocomplete
                                                    v-model="admin_classification_sub_id"
                                                    :items="subClass"
                                                    item-text="text"
                                                    item-value="value"
                                                    auto-select-first
                                                    outlined
                                                    label="Sub Classification"
                                                    required
                                                    dense
                                                    no-data-text="Select a Classification First"
                                                    clearable
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col>
                                                    <v-autocomplete
                                                    v-model="admin_category_id"
                                                    :items="GET_ADMIN_CATEGORY_SELECTION"
                                                    item-text="text"
                                                    item-value="value"
                                                    auto-select-first
                                                    outlined
                                                    label="Category"
                                                    required
                                                    dense
                                                    clearable
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col v-if="service == null">
                                                    <v-autocomplete
                                                    v-model="service_by_filter"
                                                    :items="[
                                                        { value: 0, text: 'Regular' },
                                                        { value: 1, text: 'Service' },
                                                    ]"
                                                    item-text="text"
                                                    item-value="value"
                                                    auto-select-first
                                                    outlined
                                                    label="Item Type"
                                                    required
                                                    dense
                                                    clearable
                                                ></v-autocomplete>
                                            </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-col>
                                    <!-- <v-col cols="12" md="1">
                                        <v-btn class="mt-3" @click="getAllAdminItem()">FILTER</v-btn>
                                    </v-col> -->
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="2">
                                <div class="row col-md-12 align-items-end">
                                    <v-text-field
                                        id="searchBar"
                                        v-model="filter.search"
                                        label="Search"
                                        single-line
                                        hide-details
                                        @change="searchInput()"
                                    >
                                    </v-text-field>
                                        <v-icon @click="searchInput()">mdi-magnify</v-icon>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-title>
                <v-container fluid>
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        :search="search"
                        :options.sync="options"
                        :server-items-length="totalItems"
                        :items-per-page="10"
                        :loading="!loaded"
                        :footer-props="{
                                itemsPerPageOptions: itemsPerPageOptions,
                        }"
                        loading-text="Loading... Please wait"
                        fixed-header
                        disable-sort
                    >
                        <template v-slot:[`item.is_tagged`]="{ item }">
                            <span :class="'badge badge-'+tagged[item.is_tagged ? item.is_tagged : 0].badge_color">{{tagged[item.is_tagged ? item.is_tagged : 0].text}}</span>
                        </template>
                        <!-- <template v-slot:[`item.classification_id`]="{ item }">
                            <span>{{classificationText(item.classification_id)}}</span>
                        </template> -->
                        <!-- <template v-slot:[`item.uom_id`]="{ item }">
                            <span>{{GET_ADMIN_UOM_SELECTION.find(e=>e.value == item.uom_id).text}}</span>
                        </template> -->
                        <!-- <template v-slot:[`item.price`]="{ item }">
                            <span>&#x20b1;{{item.price | currency}}</span>
                        </template> -->
                        <template v-slot:[`item.model_number`]="{item}">
                            <span>{{item.model_number ? item.model_number : 'N/A'}}</span>
                        </template>
                        <template v-slot:[`item.remarks`]="{item}">
                            <span>{{item.remarks ? truncateText(item.remarks, 50) : 'N/A'}}</span>
                        </template>
                        <template v-slot:[`item.image_path`]="{ item }">
                            <v-img class="img-thumbnail" width="50" height="50" :src="item.image_path == ''?url+'/'+'images/noimage.png':url+'/'+item.image_path" eager contain></v-img>
                        </template>
                        <template v-slot:[`item.action`]="{ item }">
                            <v-btn text icon color="orange" v-if="userAccess.view">
                                <v-icon class="btn-action" small @click="ViewEditItem(item,'View')">
                                    mdi-eye
                                </v-icon>
                            </v-btn>
                            <v-btn text icon color="blue" v-if="userAccess.edit">
                                <v-icon class="btn-action" small @click="ViewEditItem(item,'Update')">
                                    mdi-square-edit-outline
                                </v-icon>
                            </v-btn>
                            <v-btn text icon :color="GET_ADMIN_ITEM_STATUS == 1 ? 'red' : 'success'" v-if="userAccess.delete">
                                <v-icon class="btn-action" @click="DeleteItem(item.id, item.has_pending_transactions, GET_ADMIN_ITEM_STATUS)">
                                    {{ GET_ADMIN_ITEM_STATUS == 1 ? 'mdi-toggle-switch-off' : 'mdi-toggle-switch' }}
                                </v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-container>
            </v-card>
        </v-app>
    </div>
</template>
<script>
import swal from "sweetalert2";
import { mapGetters } from 'vuex';
import SharedFunctionsComponent from "@/views/main/Shared/SharedFunctionsComponent.vue";

export default {
    mixins:[SharedFunctionsComponent],
    props:["service"],
    data() {
        return {
            service_by_filter: undefined,
            loaded: false,
            vendor:[],
            admin_classification_id:'',
            admin_classification_sub_id:'',
            admin_category_id:'',
            totalItems: 0,
            options: {},
            items: [],
            itemsPerPageOptions: [5, 10, 15, 50, 100],
            itemsPerPage: 10,
            search: "",
            filter:{
                search:"",
                location:['headoffice','stores']
            },
            form:{},
            url:process.env.VUE_APP_API_HOST,
            awaitingSearch: false,
            search_classification:'',
            subClass:[],
            headers: [
                { text: 'Image', align: 'left',value:'image_path' },
                { text: "Name", align: "left", value: "item_name" },
                { text: "Classification", align: "left", value: "classification" },
                // { text: "UOM", align: "left", value: "uom_id" },
                // { text: "Quantity", align: "left", value: "quantity" },
                // { text: "Price", align: "left", value: "price" },
                // { text: "Serial Number", align: "left", value: "serial_number" },
                { text: "Model Number", align: "left", value: "model_number" },
                { text: "Remarks", align: "left", value: "remarks" },
                { text: "Sub Classification", align: "center", value: "sub_classification", sortable: false },
                { text: "Action", align: "center", value: "action",sortable:false},
            ],
            userAccess: {
                view: false,
                create: false,
                edit: false,
                delete: false,
            },
            tagged:[
                {text:'no',badge_color:'gray'},
                {text:'yes',badge_color:'success'},
            ],
        };
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS',
            'GET_ADMIN_CLASSIFICATION_SELECTION',
            'GET_ADMIN_ITEM_DISPATCH',
            'GET_ADMIN_UOM_SELECTION',
            'GET_NEW_ADMIN_ITEM',
            'GET_ADMIN_ITEM_STATUS',
            'GET_ADMIN_SUPPLIER_SELECTION',
            "GET_EXPORT_ITEMS",
            "GET_EXPORT_ITEMS_WITH_PRICE",
            'GET_ADMIN_CATEGORY_SELECTION',
        ])
    },
    mounted() {
        this.getAllAdminItem();
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        this.getSearchFromUrl()
    },
    methods: {
        getAllAdminItem(filter = null) {
            this.items = [];
            this.loaded = false;
            let payload = {
                service: this.service,
                service_by_filter: this.service_by_filter,
                page: this.options.page,
                vendor:this.vendor,
                admin_classification_id:this.admin_classification_id,
                admin_classification_sub_id:this.admin_classification_sub_id,
                admin_category_id:this.admin_category_id,
                itemsPerPage: this.options.itemsPerPage,
                search: this.filter.search,
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                location:this.filter.location,
                active:this.GET_ADMIN_ITEM_STATUS,
                filter: filter,
            };
            this.$store.dispatch(this.GET_ADMIN_ITEM_DISPATCH.get, payload).then((response) => {
                this.items = response.data.data.data;
                this.loaded = true;
                this.totalItems = response.data.data.total;
                this.$store.commit('NEW_ADMIN_ITEM',false);
            }).catch((e)=>{
                this.loaded = true;
            });
        },
        searchInput(){
            this.getAllAdminItem({ query: this.search });
        },
        ViewEditItem(item,action) {
            item.item_vendor_price_lists.forEach(e=>{
                e.old_price = e.price
            })
            this.$store.commit("EDIT_ADMIN_ITEMS", item);
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", action);
        },
        DeleteItem(id, has_pending_transactions, action) {
            let actionName = ''
            switch(action){
                case 1:
                    actionName = 'Deactivate'
                        break;
                case 0:
                    actionName = 'Activate'
                        break;
            }
            swal.fire({
                icon: "warning",
                text: `Are you sure you want to ${actionName} this Item?`,
                cancelButtonText: "Cancel",
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#397373",
                cancelButtonColor: "#397373",
            })
            .then(async result => {
                if (result.isConfirmed) {
                    if (!has_pending_transactions) {
                        return this.$store.dispatch(this.GET_ADMIN_ITEM_DISPATCH.delete, id).then((response) => {
                            this.getAllAdminItem()
                            swal.fire({
                                icon: 'success',
                                title: `${actionName} Item Success`,
                            });
                        });
                    }
                    swal.fire({
                        icon: "question",
                        title: "Item Has Pending PO's/JO's",
                        text: "- Do You want to remove the item on all pending PO's/JO's?",
                        confirmButtonText: "Remove Items in JO's/PO's",
                        denyButtonText: `${actionName} Item Only`,
                        cancelButtonText: "Cancel",
                        showCancelButton: true,
                        showDenyButton: true,
                        allowOutsideClick: false,
                        confirmButtonColor: "#397373",
                        denyButtonColor: "#397373"
                    }).then(result => {
                        if (result.isConfirmed) {
                            let payload = {
                                url: `delete-has-pending-transactions/${id}`,
                                id: id,
                            };
                            this.$store.dispatch('adminItemDelete', payload).then((response) => {
                                this.getAllAdminItem()
                                swal.fire({
                                    icon: 'success',
                                    title: `${actionName} Item Success`,
                                });
                            });
                        } else if (result.isDenied) {
                            this.$store.dispatch(this.GET_ADMIN_ITEM_DISPATCH.delete, id).then((response) => {
                                this.getAllAdminItem()
                                swal.fire({
                                    icon: 'success',
                                    title: `${actionName} Item Success`,
                                });
                            });
                        } else {
                            this.getAllAdminItem()
                            return
                        }
                    });
                }
            });
        },
        getSearchFromUrl() {
            if (Object.keys(this.$route.query).length != 0) {
                Object.keys(this.$route.query).forEach(e=>{this.filter.search = atob(this.$route.query[e]);})
                this.searchInput();
            }
        },
        // classificationText(id){
        //     let classification = this.GET_ADMIN_CLASSIFICATION_SELECTION.find(e=>e.value == id)
        //     return classification ? classification.text : ''
        // },
        exportItems(with_price = undefined) {
            const payload = {
                url: 'export-items',
                with_price: with_price,
                vendors: this.vendor.length > 0 ? [...this.vendor] : undefined,
                admin_classification_id: this.admin_classification_id,
                admin_classification_sub_id: this.admin_classification_sub_id,
                admin_category_id: this.admin_category_id,
                active: this.GET_ADMIN_ITEM_STATUS,
            };

            this.$store.dispatch('adminItemGet', payload).then(response => {
                const workbook = new this.$exceljs.Workbook();

                const sheet = workbook.addWorksheet('ITEMS');
                sheet.columns = [
                    {header:'ITEM CATEGORY', key:'item_category', width: 18, style:{alignment: {horizontal: 'center'}}},
                    {header:'CONSUME TYPE', key:'consume_type', width: 19, style:{alignment: {horizontal: 'center'}}},
                    {header:'ITEM NAME', key:'item_name', width: 30, style:{alignment: {horizontal: 'center'}}},
                    {header:'CLASSIFICATION', key:'classification', width: 25, style:{alignment: {horizontal: 'center'}}},
                    {header:'SUB CLASSIFICATION', key:'sub_classification', width: 25, style:{alignment: {horizontal: 'center'}}},
                    {header:'BRAND', key:'brand', width: 25, style:{alignment: {horizontal: 'center'}}},
                    {header:'MODEL NUMBER', key:'model_number', width: 17, style:{alignment: {horizontal: 'center'}}},
                    {header:'LIFE SPAN', key:'life_span', width: 15, style:{alignment: {horizontal: 'center', numFmt: '#,###'}}},
                    {header:'REMARKS', key:'remarks', width: 25, style:{alignment: {horizontal: 'center'}}},
                    {header:'KEY DATA 1', key:'key_data1', width: 25, style:{alignment: {horizontal: 'center'}}},
                    {header:'KEY DATA 2', key:'key_data2', width: 25, style:{alignment: {horizontal: 'center'}}},
                    {header:'TAGGED', key:'tagged', width: 10, style:{alignment: {horizontal: 'center'}}},
                    with_price ? {header:'PRICE', key:'price', width: 15, style:{numFmt: '#,##0.00', alignment: {horizontal: 'right'}}} : {},
                    with_price ? {header:'VENDOR', key:'vendor', width: 30, style:{alignment: {horizontal: 'center'}}} : {},
                    with_price ? {header:'CATEGORY', key:'category', width: 30, style:{alignment: {horizontal: 'center'}}} : {},
                ]

                let headerRow = sheet.getRow(1);
                headerRow.eachCell((cell) => {
                    cell.font = { bold: true };
                    cell.alignment = { vertical: 'middle', horizontal: 'center' };
                });

                response.data.forEach(item=>{
                    item.item_category = item.item_category == null ? '' : item.item_category == 1 ? 'Asset' : 'Consumable';
                    item.consume_type = item.consume_type == null || item.consume_type == 0
                        ? ''
                        : item.consume_type == 1 ? 'Goods' : 'Services';
                    item.tagged = item.tagged == 1 ? 'Yes' : 'No';
                    item.price = parseFloat(item.price ?? 0);
                    sheet.addRow(item);
                })

                let exportName = `Items`;
                workbook.xlsx.writeBuffer().then((data) => {
                    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                    this.$filesaver.saveAs(blob, `${exportName}.xlsx`);
                });
            });
        },
    },
    watch: {
        vendor: {
            handler() {
                this.getAllAdminItem();
            },
        },
        admin_classification_id: {
            handler() {
                this.getAllAdminItem();
            },
        },
        admin_classification_sub_id: {
            handler() {
                this.getAllAdminItem();
            },
        },
        admin_category_id: {
            handler() {
                this.getAllAdminItem();
            },
        },
        service_by_filter: {
            handler() {
                this.getAllAdminItem();
            },
        },
        search_classification: {
            handler(val) {
                this.GET_ADMIN_CLASSIFICATION_SELECTION.filter((e) => {
                    if (e.text == val) {
                        this.subClass = e.sub_class;
                    }
                });
            },
        },
        options: {
            handler() {
                this.getAllAdminItem();
            },
            deep: true,
        },
        GET_NEW_ADMIN_ITEM:{
            handler(val){
                if(val){
                    this.getAllAdminItem();
                }
            }
        },
        USER_ACCESS: {
            handler(val) {
                if (val != "fail") {
                    this.userAccess.edit = false;
                    this.userAccess.delete = false;
                    this.userAccess.view = false;
                    this.userAccess.create = false;
                    val.forEach((e) => {
                        if (e.actions_code == "edit") {
                            this.userAccess.edit = true;
                        } else if (e.actions_code == "delete") {
                            this.userAccess.delete = true;
                        } else if (e.actions_code == "view") {
                            this.userAccess.view = true;
                        } else if (e.actions_code == "create") {
                            this.userAccess.create = true;
                        }
                    });
                }
            },
        },
        GET_EXPORT_ITEMS: {
            handler(val) {
                if (!val) return;
                this.exportItems();
                this.$store.commit("EXPORT_ITEMS", false);

            },
        },
        GET_EXPORT_ITEMS_WITH_PRICE: {
            handler(val) {
                if (!val) return;
                this.exportItems(1);
                this.$store.commit("EXPORT_ITEMS_WITH_PRICE", false);
            },
        },
    },
};
</script>