<template>
    <div>
        <div class="pl-4">
            <div class="row">
                <div class="col-12 d-flex no-block align-items-center">
                    <div class="btn-toolbar">
                        <v-btn data-modal-id="drFormModal" v-on:click="showBaklasDialog()" v-if="userAccess.create">
                            Add
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>

        <!-- Add baklas Dialog -->
        <v-dialog v-model="addBaklasDialog" persistent max-width="98%" scrollable>
            <v-card>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">New Cannibalize</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeAddBaklasDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row class="p-3">
                    <v-col lg="3">
                            Select Warehouse: <span style="color:red">*</span>
                            <v-autocomplete
                                v-model="selectedWarehouse"
                                :items="warehouses"
                                :rules="rules.default"
                                item-text="name"
                                item-value="id"
                                placeholder="Select Warehouse"
                                auto-select-first
                                dense
                                outlined
                            />
                    </v-col>
                    <v-col lg="3">
                        Select Item: <span style="color:red">*</span>
                        <v-autocomplete
                            v-model="selectedItem"
                            :items="itemLists"
                            :rules="rules.default"
                            item-text="item_model"
                            item-value="item_id"
                            placeholder="Select Item"
                            auto-select-first
                            dense
                            outlined
                            @change="getAllSparePartsOfItem()"
                        />
                    </v-col>
                    <!-- <v-col lg="1">
                        <v-divider
                            vertical
                        ></v-divider>
                    </v-col> -->
                    <v-col lg="3">
                        Serial Number:
                        <v-text-field v-model="serialNumber" outlined dense label="Serial Number" />
                    </v-col>
                    <v-col lg="3">
                        Source: <span style="color:red">*</span>
                        <v-autocomplete
                            v-model="selectedSource"
                            :items="sourceSelection"
                            :rules="rules.default"
                            item-text="source"
                            item-value="id"
                            label="Select Source"
                            auto-select-first
                            dense
                            outlined
                            chips
                        >

                        </v-autocomplete>
                    </v-col>
                </v-row>

                <v-divider class="mt-0" />

                <v-card-text class="pa-5">

                    <v-layout row>
                        <v-flex lg6 class="pr-2">
                            <v-card class="p-2">
                                <v-card-title class="d-flex align-center justify-content-between">
                                    <span> Spare Parts List <span style="color:red">*</span></span>
                                    <v-checkbox label="Select All" class="m-0" v-model="spareParts.dataTable.select_all" v-if="spareParts.dataTable.items.length > 0">
                                    </v-checkbox>
                                    <!-- <v-checkbox label="Select Available" class="m-0" v-model="spareParts.dataTable.select_available" v-if="spareParts.dataTable.items.length > 0">
                                    </v-checkbox> -->
                                    <v-radio-group  v-if="spareParts.dataTable.items.length > 0" v-model="spareParts.dataTable.select_available" class="m-0" row>
                                        <v-radio
                                            v-for="el in ['Defective','Available']"
                                            color="primary"
                                            class="ml-2"
                                            :key="el"
                                            :label="`Set ${el}`"
                                            :value="el"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-card-title>
                                <div>
                                    <v-data-table
                                        :headers="spareParts.dataTable.headers"
                                        :items="spareParts.dataTable.items"
                                        :items-per-page="spareParts.dataTable.items.length"
                                    >
                                        <template v-slot:[`item.transfer_qty`]="{ item }">
                                            <v-checkbox
                                                hide-details
                                                dense
                                                v-model="item.checked"
                                                style="margin-top:0 !important; padding-top:0 !important;display:inline-block"
                                                @change="enableTransferQty(item)"
                                            />
                                            <input
                                                type="number"
                                                :min="0"
                                                :max="item.original_qty"
                                                v-model="item.transfer_qty"
                                                dense
                                                outlined
                                                :disabled="!item.checked"
                                                style="
                                                    text-align:right;
                                                    cursor:pointer;
                                                    margin:1px 0px 1px !important;
                                                    height: 1.5rem !important;
                                                    padding:0 5px !important;
                                                    border: 1px solid black;
                                                    border-radius: 6px;
                                                    display:inline-block
                                                "
                                                @blur="getMaxQty(item)"
                                            />
                                        </template>
                                    </v-data-table>
                                </div>
                            </v-card>
                        </v-flex>

                        <v-flex lg6  class="pr-2">
                            <v-card class="p-2">
                                <v-card-title class="d-flex align-items-start">
                                    Ready For Cannibalize Items
                                </v-card-title>
                                <v-layout row class="mx-0">
                                    <v-flex>

                                    </v-flex>
                                    <v-spacer />
                                    <v-flex lg3>
                                        <v-text-field
                                            v-model="search"
                                            append-icon="mdi-magnify"
                                            label="Item Model"
                                            single-line
                                            hide-details
                                            clearable
                                        />
                                    </v-flex>
                                </v-layout>
                                <div>
                                    <v-data-table
                                        :headers="itemHeaders"
                                        :items="itemLists"
                                        v-model="selectedItems"
                                        :search="search"
                                    >
                                        <!-- <template v-slot:header.data-table-select="{ header }">
                                            <v-simple-checkbox style="display:none"></v-simple-checkbox>
                                        </template>
                                        <template v-slot:item.data-table-select="{ item, isSelected, select }">
                                            <v-simple-checkbox
                                                ref="checkBox"
                                                :value="isSelected"
                                                :readonly="warehouseItemStocksChecker(item)"
                                                :disabled="warehouseItemStocksChecker(item)"
                                                @input="select($event)"
                                            ></v-simple-checkbox>
                                        </template> -->
                                    </v-data-table>
                                </div>
                            </v-card>
                        </v-flex>
                    </v-layout>
                </v-card-text>

                <v-divider class="mt-0" />

                <div style="text-align:center" class="pb-4">
                    <v-btn @click="showConfirmationDialog()">Confirm</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="confirmationDialog" persistent max-width="30%" scrollable max-height="500px">
            <v-card style="max-height:500px;" >
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">Cannibalize Details</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeConfirmationDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>


                <v-divider class="mt-0" />
                <v-card-text class="px-4" style="max-height:500px;">
                    <div>Item: {{ selectedItemModel }}</div>
                    <div>Serial Number: {{ serialNumber}}</div>
                    <v-data-table
                        :headers="getHeaders()"
                        :items="selectedSpareParts"
                        :hide-default-footer="true"
                        :items-per-page="selectedSpareParts.length"
                    >

                    </v-data-table>
                </v-card-text>
                <v-divider class="mt-0" />

                <div style="text-align:center" class="pb-4">
                    <v-btn :loading="submitOnFlight" @click="submit()">Submit</v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import swal from 'sweetalert2';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            itemHeaders: [
                { text: 'Item Model', value: 'item_model' },
                { text: 'Item Name', value: 'item_name' },
                { text: 'Stocks', value: 'item_stocks' },
            ],
            sparePartHeaders: [
                { text: 'Model', value: 'model' },
                { text: 'Name', value: 'name' },
                { text: 'Original Qty', value: 'original_qty' },
                { text: 'Set Defective Qty', value: 'transfer_qty' }
            ],
            warehouseItemStocks: [],
            selectedItems: [],
            selectedWarehouse: '',
            selectedWarehouseName: '',
            serialNumber: '',
            warehouseItems: [],
            selectedItem: '',
            selectedItemModel: '',
            sourceSelection:[],
            selectedSource:'',

            itemLists: [],
            warehouses: [],
            search: '',
            items: [],
            // spareParts: [],
            showAvailableItems: false,

            confirmationDialog: false,
            selectedSpareParts: [],

            submitOnFlight: false,
            userAccess: {
                view: false,
                edit: false,
                delete: false,
                create: false,
            },
            spareParts:{
                dataTable:{
                    items:[],
                    headers:[
                        { text: 'Model', value: 'model' },
                        { text: 'Name', value: 'name' },
                        { text: 'Original Qty', value: 'original_qty' },
                        { text: 'Set Defective Qty', value: 'transfer_qty' }
                    ],
                    select_all:false,
                    select_available:'Defective'
                }
            },
            rules: {
                default: [
                v => !!v || 'This is required',
                ],
            },
        }
    },
     mounted() {
        this.getWarehouses();
        this.getWarehouseItemStocks();
        this.getItemWithSpareParts();
        this.$store.dispatch("checkAccessUser", this.$router.currentRoute.params.id);
    },
    computed: {
        addBaklasDialog() {
            return this.$store.state.service.addBaklasDialog;
        },
        ...mapGetters([
            'USER_ACCESS',
        ]),
    },
    props: [],
    watch: {
        warehouses() {
            if(this.warehouses.length > 0) {
                let dmd = this.warehouses.find(x=>x.code == 'DM-D');

                if(!!dmd) {
                    this.selectedWarehouse = dmd.id;
                    this.selectedWarehouseName = dmd.code;
                }
            }
        },
        selectedWarehouse() {
            if(!!this.selectedWarehouse) {
                this.getWarehouseItemStocks();
            }
        },
        selectedItem() {
           if(!!this.selectedItem) {
               let item = this.itemLists.find(x=>x.item_id == this.selectedItem);
               this.selectedItemModel = item.item_model;
               this.getAllSparePartsOfItem();
           }
        },
        USER_ACCESS: {
            handler(val) {
                if(val != "fail"){
                    this.userAccess.update = false;
                    this.userAccess.delete = false;
                    this.userAccess.view = false;
                    this.userAccess.create = false;

                val.forEach(e => {
                    if (e.actions_code == 'update') {
                        this.userAccess.update = true;
                    } else if (e.actions_code == 'delete') {
                        this.userAccess.delete = true;
                    } else if (e.actions_code == 'view') {
                        this.userAccess.view = true;
                    } else if (e.actions_code == 'create') {
                        this.userAccess.create = true;
                    }
                });
                }
            },
        },
        'spareParts.dataTable.select_all':{
            handler(val){
                this.spareParts.dataTable.items = this.spareParts.dataTable.items.map(e=>{
                    e.checked = val ? true : false
                    e.transfer_qty = !!val ? e.original_qty : 0;
                    return e
                })
            }
        },
        'spareParts.dataTable.select_available':{
            handler(val){
                this.spareParts.dataTable.headers.pop()
                this.spareParts.dataTable.headers.push(val == 'Available' ? {value:'transfer_qty',text:'Set Available Qty'} : {value:'transfer_qty',text:'Set Defective Qty'})
            }
        },
        'dataTableSelected': {
            handler(val){
                console.log(val)
                if(val.length > 0 ){
                this.spareParts.dataTable.items .forEach(x=>{
                        if(!!val.find(e=>e.id == x.id)){
                            x.checked = true
                            x.transfer_qty = x.original_qty
                        }else{
                            x.checked = false
                            x.transfer_qty = 0
                        }
                    })
                }else{
                    this.spareParts.dataTable.items  =  this.spareParts.dataTable.items .map(e=>{
                        e.checked = false
                        return e
                    })
                }
            }
        }
    },
    methods: {
        getItemWithSpareParts() {
            this.$store.dispatch('getItemWithSpareParts').then(response => {
                this.items = response.data
            })
        },
        getAllSparePartsOfItem() {
            this.spareParts.dataTable.items = []

            let payload = {
                warehouseId: this.selectedWarehouse,
                itemId: this.selectedItem
            }

            this.$store.dispatch('getAllSparePartsOfItem',payload).then(response => {
                let data = response.data;
                let modified = [];
                if(data.length > 0) {
                    data.forEach(x=>{
                        modified.push(
                            Object.assign({
                                transfer_qty: 0,
                                checked: false
                            }, x)
                        )
                    });
                }
                this.spareParts.dataTable.items = modified;
            })
        },
        warehouseItemStocksChecker(item) {
            if(item.item_stocks > 0) {
                return false;
            }

            return true;
        },
        clearFields() {
            Object.assign(this.$data, this.$options.data.call(this));
        },
        showBaklasDialog() {
            this.getWarehouses();
            this.getWarehouseItemStocks();
            this.$store.commit('showAddBaklasDialog');

        },
        closeAddBaklasDialog() {
            this.clearFields();
            this.$store.commit('closeAddBaklasDialog');
        },
        getWarehouses() {
            this.$store.dispatch('serviceGet',{
                url:'baklas-source'
            }).then(response=>{
                this.sourceSelection = response.data
            })

            this.$store.dispatch('getBaklasWarehouse').then(response => {
                this.warehouses = response.data
            })
        },
        async getWarehouseItemStocks() {
            this.spareParts.dataTable.items = []

            let payload = {
                warehouseId: this.selectedWarehouse
            }
            await this.$store.dispatch('getWarehouseItemStocksBaklas', payload ).then(response =>{
                this.itemLists = response.data
            })
        },
        showConfirmationDialog() {
            let items = []

            items = this.spareParts.dataTable.items.filter(e=>{
                if(e.checked) {
                    return e;
                }
                // if(this.spareParts.dataTable.select_available){
                //     if(e.checked){
                //         return e
                //     }
                // }else{
                //     if(!e.checked){
                //         return e
                //     }
                // }
            })


            this.selectedSpareParts = items

            if(!this.requiredFields()) {
                let zeroQty = this.selectedSpareParts.filter( e => {
                    if (e.transfer_qty == undefined) {
                        return e;
                    }
                })

                if (zeroQty.length > 0) {
                    swal.fire("Warning", "-Parts with NO qty. isn't valid", "warning" );
                } else {
                    if(this.selectedSpareParts.length > 0) {
                        this.confirmationDialog = true;
                    } else {

                        swal.fire('','Please Select And Input Transfer Qty','warning');
                    }
                }
            }
        },
        closeConfirmationDialog() {
            this.selectedSpareParts = [];
            this.confirmationDialog = false;
        },
        submit() {
            this.submitOnFlight = true;

            swal.fire({
                title: `Are you sure you want to create baklas item and set selected as '${this.spareParts.dataTable.select_available}' spare parts?`,
                text: 'Confirm to proceed',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then((result)=>{
                if(result.isConfirmed) {
                    let payload = {
                        warehouseId: this.selectedWarehouse,
                        itemId: this.selectedItem,
                        spareParts: _.compact(this.spareParts.dataTable.items),
                        serialNumber: this.serialNumber,
                        source_id: this.selectedSource,
                        isAvailable: this.spareParts.dataTable.select_available === 'Available'
                    }
                    this.$store.dispatch('createBaklas', payload).then(response => {
                        if(response.data.error) {
                            swal.fire('', response.data.error,'error').then(confirm=>{
                                this.submitOnFlight = false;
                            });
                        } else {
                            this.closeConfirmationDialog();
                            this.closeAddBaklasDialog();
                            this.$emit('successCreate', true);
                            swal.fire('', response.data.msg,'success').then(confirm=>{
                                if(confirm) {
                                    this.$emit('successCreate', false)
                                }
                                this.submitOnFlight = false;
                            });
                        }
                    })
                } else {
                    this.submitOnFlight = false;
                }
            });
        },
        getMaxQty(item) {
            let selectedItem = this.spareParts.dataTable.items.find(x=>x.id == item.id);
            if(!!selectedItem) {
                if(item.transfer_qty > item.original_qty) {
                    item.transfer_qty = item.original_qty;
                }
            }
        },
        enableTransferQty(item) {
            if(!!item.checked) {
                item.checked = item.checked;
                item.transfer_qty = item.original_qty
            } else {
                item.checked = item.checked;
                item.transfer_qty = 0;
            }
        },
        getHeaders() {
            let headerText = this.spareParts.dataTable.select_available
            return [
                { text: 'Model', value: 'model' },
                { text: headerText + ' Qty', value: 'transfer_qty' },
            ];
        },
        requiredFields() {
            let warning = '';
            if (!this.selectedWarehouse) warning += 'Please select a Warehouse.<br>';
            if (!this.selectedItem) warning += 'Please select a Item.<br>';
            if (!this.selectedSource) warning += 'Please select a Source.<br>';

            if (warning !== '') return swal.fire({
                title: 'Please Fill out Information:',
                html: warning,
                icon: "warning",
            });
        },
    }
}
</script>

<style>

</style>
