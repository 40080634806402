<template>
    <v-dialog v-model="editDialog" persistent max-width="98%" scrollable>
        <v-card>
            <v-row class="m-0">
                <v-col>
                    <v-card-title>
                        <span class="headline">Edit Repair Form</span>
                    </v-card-title>
                </v-col>
                <v-col cols="pull-right-10 p-2">
                    <v-btn text icon color="gray" class="float-right" @click="closeRfDialog()">
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class="my-0" />
            <v-container fluid style="background-color: white; overflow-y: scroll">
                <v-col>
                    <v-card-text>
                        <div class="pa-4">
                            <v-layout row>
                                <v-flex xl4 lg4 md4 sm4 xs4>
                                    <v-card class="pa-2">
                                        <div class="pa-0">
                                            <h6>Customer Information</h6>
                                        </div>
                                        <v-layout row class="ma-3">
                                            <v-flex lg3 class="mt-2">
                                                Select Dealer:
                                            </v-flex>
                                            <v-flex lg9>
                                                <v-autocomplete
                                                    dense
                                                    outlined
                                                    :items="GET_SERVICE_ALL_CUSTOMERS"
                                                    v-model="selectedDealer"
                                                    item-value="id"
                                                    item-text="company_name"
                                                    placeholder="Select Dealer"
                                                    auto-select-first
                                                    :isDisabled="true"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-card class="py-2 mt-2">
                                            <v-layout row class="ma-3">
                                                <v-flex lg4>
                                                    <label style="color:gray">Company Name:</label>
                                                </v-flex>
                                                <v-flex lg8>
                                                    {{ dealer }}
                                                </v-flex>
                                            </v-layout>
                                            <v-layout row class="ma-3">
                                                <v-flex lg4>
                                                    <label style="color:gray">Name:</label>
                                                </v-flex>
                                                <v-flex lg8>
                                                    {{ dealerName }}
                                                </v-flex>
                                            </v-layout>
                                            <v-layout row class="ma-3">
                                                <v-flex lg4>
                                                    <label style="color:gray">Address:</label>
                                                </v-flex>
                                                <v-flex lg8>
                                                    {{ dealerAddress }}
                                                </v-flex>
                                            </v-layout>
                                            <v-layout row class="ma-3">
                                                <v-flex lg4>
                                                    <label style="color:gray">Tel Number:</label>
                                                </v-flex>
                                                <v-flex lg8>
                                                    {{ dealerTelNumber }}
                                                </v-flex>
                                            </v-layout>
                                            <v-layout row class="ma-3">
                                                <v-flex lg4>
                                                    <label style="color:gray">Fax Number:</label>
                                                </v-flex>
                                                <v-flex lg8>
                                                    {{ dealerFaxNumber }}
                                                </v-flex>
                                            </v-layout>
                                            <v-layout row class="ma-3">
                                                <v-flex lg4>
                                                    <label style="color:gray">Mobile Number:</label>
                                                </v-flex>
                                                <v-flex lg8>
                                                    {{ dealerMobileNumber }}
                                                </v-flex>
                                            </v-layout>
                                            <v-layout row class="ma-3">
                                                <v-flex lg4>
                                                    <label style="color:gray">Email:</label>
                                                </v-flex>
                                                <v-flex lg8>
                                                    {{ dealerEmail }}
                                                </v-flex>
                                            </v-layout>
                                        </v-card>

                                        <div v-if="endUser()" class="pt-2">
                                            End user
                                        </div>

                                        <v-card v-if="endUser()" class="pa-2 mt-2">
                                            <div>
                                                <span>First Name: </span>
                                            </div>
                                            <div>
                                                <v-text-field
                                                    v-model="customerFirstname"
                                                    outlined
                                                    dense
                                                    readonly
                                                />
                                            </div>
                                            <div class="mt-2">
                                                <span>Middle Name: </span>
                                            </div>
                                            <div>
                                                <v-text-field
                                                    v-model="customerMiddlename"
                                                    outlined
                                                    dense
                                                />
                                            </div>
                                            <div class="mt-2">
                                                <span>Last Name: </span>
                                            </div>
                                            <div>
                                                <v-text-field
                                                    v-model="customerlastName"
                                                    outlined
                                                    dense
                                                    readonly
                                                />
                                            </div>
                                            <div class="mt-2">
                                                <span>Address: </span>
                                            </div>
                                            <div>
                                                <v-text-field
                                                    v-model="customerAddress"
                                                    outlined
                                                    dense
                                                    readonly
                                                />
                                            </div>
                                            <div class="mt-2">
                                                <span>Contact Number: </span>
                                            </div>
                                            <div>
                                                <v-text-field
                                                    v-model="customerContactNumber"
                                                    outlined
                                                    dense
                                                    readonly
                                                />
                                            </div>
                                            <div class="mt-2">
                                                <span>Email: </span>
                                            </div>
                                            <div>
                                                <v-text-field
                                                    v-model="customerEmail"
                                                    outlined
                                                    dense
                                                    readonly
                                                />
                                            </div>
                                        </v-card>
                                    </v-card>
                                </v-flex>

                                <v-flex xl8 lg8 md8 sm8 xs8>
                                    <v-card class="pa-2 mr-4" width="100%" style="overflow: auto">
                                        <v-flex row class="mb-4 mt-1">
                                            <v-spacer />
                                            <v-flex lg2 class="mr-2">
                                                <div style="display:inline-block" class="mr-2">
                                                    <base-camera-capture ref="cameraCapture" @capturedImages="getImages" />
                                                </div>
                                                <div style="display:inline-block" >
                                                    {{ !!images ? `images(${images.length})` : '' }}
                                                </div>
                                            </v-flex>
                                            <v-flex lg2 class="mr-2">
                                                <!-- <div>
                                                    <label style="color:gray">Upload Warranty:</label>
                                                </div> -->
                                                <div>
                                                    <v-file-input
                                                        ref="wImages"
                                                        v-model="warrantyImages"
                                                        prepend-icon="mdi-camera"
                                                        accept="image/png, image/jpeg, image/bmp"
                                                        outlined
                                                        multiple
                                                        dense
                                                    />
                                                </div>
                                            </v-flex>

                                            <v-flex lg2>
                                                <service-files-viewer :delete="true" :rfId="rfId" :rfNumber="rfNumber" />
                                            </v-flex>
                                        </v-flex>

                                        <v-divider class="mt-0" />

                                        <v-flex row class="ma-0">
                                            <v-flex lg4 class="pr-2">
                                                <div>
                                                    <label style="color:gray">Item Model:</label>
                                                </div>
                                                <div>
                                                    <v-autocomplete
                                                        dense
                                                        outlined
                                                        v-model="itemModel"
                                                        :items="GET_ITEMS_EXCLUDE_SP"
                                                        item-value="id"
                                                        item-text="name"
                                                        placeholder="Select Model"
                                                        auto-select-first
                                                        @input="selectedItem()"
                                                        disabled
                                                    />
                                                </div>
                                            </v-flex>

                                            <v-flex lg5 class="pr-2">
                                                <div>
                                                    <label style="color:gray">Item Name:</label>
                                                </div>

                                                <div>
                                                    <v-text-field v-model="itemProductName" outlined dense disabled></v-text-field>
                                                </div>
                                            </v-flex>

                                            <v-flex lg3>
                                                <div>
                                                    <label style="color:gray">Category:</label>
                                                </div>
                                                <div>
                                                    <v-text-field v-model="itemCategory" outlined dense disabled></v-text-field>
                                                </div>
                                            </v-flex>
                                        </v-flex>

                                        <v-flex row class="ma-0 mt-3">
                                            <v-flex lg4 class="pr-2">
                                                <div>
                                                    <label style="color:gray">Serial No:</label>
                                                </div>
                                                <div>
                                                    <v-text-field v-model="itemSerial" disabled outlined dense></v-text-field>
                                                </div>
                                            </v-flex>

                                            <v-flex lg3 class="pr-2">
                                                <div>
                                                    <label style="color:gray">Warranty Type: <span v-if="checkWarrantyType" style="color:red">*</span></label>
                                                </div>
                                                <div>
                                                    <v-autocomplete
                                                        v-model="itemWarrantyType"
                                                        :items="['In-Warranty','Out-Warranty', 'Dealer-Stock']"
                                                        :rules="rules.default"
                                                        auto-select-first
                                                        dense
                                                        outlined
                                                    />
                                                </div>
                                            </v-flex>

                                            <v-flex v-if="checkWarrantyType" lg2 class="pr-2">
                                                <div>
                                                    <label style="color:gray">Warranty Document: <span style="color:red">*</span></label>
                                                </div>
                                                <div>
                                                    <v-autocomplete
                                                        v-model="itemWarrantyDocument"
                                                        :items="['Receipt','Warranty Card', 'Receipt & Warranty Card']"
                                                        :rules="rules.default"
                                                        auto-select-first
                                                        clearable
                                                        dense
                                                        outlined
                                                    />
                                                </div>
                                            </v-flex>

                                            <v-spacer />

                                            <v-flex v-if="checkWarrantyType" lg2>
                                                <div>
                                                    <label style="color:gray">Purchase Date: <span style="color:red">*</span></label>
                                                </div>
                                                <div>
                                                    <v-dialog
                                                        ref="dateDialog"
                                                        v-model="dateModal"
                                                        :return-value.sync="itemPurchaseDate"
                                                        persistent
                                                        width="290px"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            v-model="itemPurchaseDate"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            :rules="rules.default"
                                                            readonly
                                                            clearable
                                                            dense
                                                            outlined
                                                        />
                                                        </template>
                                                        <v-date-picker
                                                            v-model="itemPurchaseDate"
                                                            scrollable
                                                            :max="maxDate"
                                                        >
                                                        <v-spacer></v-spacer>
                                                        <v-btn
                                                            text
                                                            color="primary"
                                                            @click="dateModal = false"
                                                            :disabled="!dealersList"
                                                        >
                                                            Cancel
                                                        </v-btn>
                                                        <v-btn
                                                            text
                                                            color="primary"
                                                            @click="$refs.dateDialog.save(itemPurchaseDate)"
                                                            :disabled="!dealersList"
                                                        >
                                                            OK
                                                        </v-btn>
                                                        </v-date-picker>
                                                    </v-dialog>
                                                </div>
                                            </v-flex>
                                        </v-flex>

                                        <v-divider />

                                        <div>
                                            <label style="color:gray">Included Items:</label>
                                        </div>
                                        <v-flex row class="pl-4 mb-5">
                                            <v-flex lg3>
                                                <v-checkbox
                                                    v-model="includedItems"
                                                    label="Packaging"
                                                    value="Packaging"
                                                    disabled
                                                />
                                            </v-flex>
                                            <v-flex lg3>
                                                <v-checkbox
                                                    v-model="includedItems"
                                                    label="Warranty Docs"
                                                    value="Warranty Docs"
                                                    disabled
                                                />
                                            </v-flex>
                                            <v-flex lg3>
                                                <v-checkbox
                                                    v-model="includedItems"
                                                    label="Manual"
                                                    value="Manual"
                                                    disabled
                                                />
                                            </v-flex>
                                            <v-flex lg3>
                                                <v-checkbox
                                                    v-model="includedItems"
                                                    label="Accessories"
                                                    value="Accessories"
                                                    disabled
                                                />
                                            </v-flex>
                                            <v-flex lg12>
                                                <v-textarea
                                                    v-model="otherIncludedItems"
                                                    label="Other included items:"
                                                    disabled
                                                    outlined
                                                    dense
                                                ></v-textarea>
                                            </v-flex>
                                        </v-flex>

                                        <v-divider />

                                        <div>
                                            <label style="color:gray">Physical Condition:</label>
                                        </div>
                                        <v-flex row class="pl-4 mb-5">
                                            <v-flex lg3>
                                                <v-checkbox
                                                    v-model="physicalCondition"
                                                    label="Dents"
                                                    value="Dents"
                                                    disabled
                                                />
                                            </v-flex>
                                            <v-flex lg3>
                                                <v-checkbox
                                                    v-model="physicalCondition"
                                                    label="Scratches"
                                                    value="Scratches"
                                                    disabled
                                                />
                                            </v-flex>
                                            <v-flex lg3>
                                                <v-checkbox
                                                    v-model="physicalCondition"
                                                    label="Faded Paint"
                                                    value="Faded Paint"
                                                    disabled
                                                />
                                            </v-flex>
                                            <v-flex lg3>
                                                <v-checkbox
                                                    v-model="physicalCondition"
                                                    label="Crack/Broken"
                                                    value="Crack/Broken"
                                                    disabled
                                                />
                                            </v-flex>
                                            <v-flex lg12>
                                                <v-textarea
                                                    v-model="otherPhysicalCondition"
                                                    label="Other physical condition:"
                                                    outlined
                                                    dense
                                                    disabled
                                            ></v-textarea>
                                            </v-flex>
                                        </v-flex>

                                        <v-divider />

                                        <div>
                                            <label style="color:gray">Complaint / Symptom:</label>
                                        </div>
                                        <v-flex row class="pl-4 my-2">
                                            <v-flex lg6 class="pr-2">
                                                <v-textarea
                                                    v-model="defectDescription"
                                                    disabled
                                                    outlined
                                                    dense
                                                ></v-textarea>
                                            </v-flex>
                                            <v-flex lg6>
                                                <v-textarea
                                                    v-model="defectRemarks"
                                                    label="Remarks:"
                                                    outlined
                                                    dense
                                                ></v-textarea>
                                            </v-flex>
                                        </v-flex>
                                    </v-card>
                                </v-flex>
                            </v-layout>
                        </div>
                    </v-card-text>
                </v-col>
            </v-container>
            <v-divider class=""/>

            <div class="py-4" style="text-align:center">
                <v-btn :loading="submitLoader" @click="updateRepairForm()" class="mx-2">Update</v-btn>
                <v-btn v-if="this.backjob == 1" :loading="submitLoader" @click="showTechnicians()">Assign Tech</v-btn>
            </div>
        </v-card>
        <v-dialog v-model="technicianDilaog" width="1500px" class="p-2">
            <v-card color="#EBEBEB">
                <v-card-title>
                    <v-row class="m-0">
                        <v-col cols="pull-right-10">
                            <span>Assign Technician</span>
                            <v-btn
                                text
                                icon
                                small
                                color="gray"
                                class="float-right"
                                @click="technicianDilaog = false"
                            >
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <TechnicianCardComponentVue :selection="true" @success="success" :rfId="rfId"></TechnicianCardComponentVue>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>
import ServiceFilesViewer from './Base/BaseFilesViewer.vue';
import BaseCameraCapture from './Base/BaseCameraCapture.vue';
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
import _ from 'lodash'
import TechnicianCardComponentVue from './Views/TechnicianManagerComponent/TechnicianCardComponent.vue';
export default {
    data() {
        return {
            editDialog: false,
            dealersList: [],
            selectedDealer: '',
            dealerName: '',
            dealer: '',
            dealerAddress: '',
            dealerTelNumber: '',
            dealerMobileNumber: '',
            dealerFaxNumber: '',
            dealerAddress: '',
            dealerEmail: '',
            customerFirstname: '',
            customerMiddlename: '',
            customerlastName: '',
            customerAddress: '',
            customerContactNumber: '',
            customerEmail: '',
            itemPurchaseDate: '',
            itemWarrantyType: '',
            itemWarrantyDocument: '',
            itemImages: null,
            itemModel: '',
            itemProductName: '',
            itemCategory: '',
            itemSerial: '',
            itemWarrantyDocument: '',
            warrantyImages: [],
            includedItems: [],
            otherIncludedItems: '',
            physicalCondition: [],
            otherPhysicalCondition: '',
            defectDescription: '',
            defectRemarks: '',
            dateModal: false,
            repairForm: null,
            submitLoader: false,
            maxDate: this.$dayjs().format('YYYY-MM-DD'),
            rfId: '',
            rfNumber: '',
            images: null,
            technicianDilaog:false,
            hasUpload:false,
            backjob: 0,
            rules: {
                default: [
                v => !!v || 'This is required',
                ],
            },
        }
    },
    components: {
        ServiceFilesViewer,
        BaseCameraCapture,
        TechnicianCardComponentVue
    },
    props: ['showEditDialog', 'rf'],
    computed: {
        ...mapGetters([
            'GET_ITEMS_EXCLUDE_SP',
            'GET_SERVICE_ALL_CUSTOMERS',
        ]),
        checkWarrantyType() {
            if(this.itemWarrantyType == 'In-Warranty') {
                return true;
            }
            return false;
        },
    },
    watch: {
        showEditDialog() {
            if(this.showEditDialog) {
                this.editDialog = true;
            }
        },
         rf() {
            if(!!this.rf) {
                this.repairForm = this.rf;
                this.rfId = this.rf.id;
                this.rfNumber = this.rf.rf_number;
                this.backjob = this.rf.backjob
                if(!!this.repairForm) {
                    let telNumber = [];
                    let mobileNumber = [];
                    let fullName = [];
                    let customer = this.repairForm.sc_receiving_report.customer;
                    this.selectedDealer = this.repairForm.sc_receiving_report.customer_id;
                    this.dealer = customer.company_name;
                if(!!customer.first_name) {
                    fullName.push(customer.first_name)
                }
                if(!!customer.middle_name) {
                    fullName.push(customer.middle_name)
                }
                if(!!customer.last_name) {
                    fullName.push(customer.last_name)
                }

                if(!!customer.tel_1) {
                    telNumber.push(customer.tel_1)
                }
                if(!!customer.tel_2) {
                    telNumber.push(customer.tel_2)
                }
                if(!!customer.tel_3) {
                    telNumber.push(customer.tel_3)
                }

                if(!!customer.mobile_1) {
                    mobileNumber.push(customer.mobile_1)
                }
                if(!!customer.mobile_2) {
                    mobileNumber.push(customer.mobile_2)
                }
                if(!!customer.mobile_2) {
                    mobileNumber.push(customer.mobile_2)
                }
                this.dealerTelNumber = telNumber.toString().replaceAll(',',' / ');
                this.dealerMobileNumber = mobileNumber.toString().replaceAll(',', ' / ');
                this.dealerFaxNumber = customer.fax;
                // this.dealerAddress = JSON.parse(customer.shipping_addresses)[0].address;
                this.dealerAddress = customer.all_shipping_addresses[0].text;
                this.dealerEmail = customer.email

                if(!!this.repairForm.sc_receiving_report.sc_customer) {
                    let scCustomer = this.repairForm.sc_receiving_report.sc_customer;
                    this.customerFirstname = scCustomer.first_name;
                    this.customerMiddlename = scCustomer.middle_name;
                    this.customerlastName = scCustomer.last_name;
                    this.customerAddress = scCustomer.address;
                    this.customerContactNumber = scCustomer.contact_number;
                    this.customerEmail = scCustomer.email;
                }

                this.itemModel = this.repairForm.sc_repair_form_item.item_id;

                if(this.GET_ITEMS_EXCLUDE_SP.length > 0) {
                    this.itemProductName = this.GET_ITEMS_EXCLUDE_SP.find(x => x.id == this.itemModel).name;
                    this.itemCategory = this.GET_ITEMS_EXCLUDE_SP.find(x => x.id == this.itemModel).item_category.name;
                }

                this.itemPurchaseDate = this.repairForm.sc_repair_form_item.purchase_date;
                this.itemSerial = this.repairForm.sc_repair_form_item.serial;
                this.itemWarrantyType = this.repairForm.sc_repair_form_item.warranty_type;
                this.itemWarrantyDocument = this.repairForm.sc_repair_form_item.warranty;
                this.includedItems = this.repairForm.sc_repair_form_item.accessories.split(',');
                this.physicalCondition = this.repairForm.sc_repair_form_item.unit_condition.split(',')
                this.otherIncludedItems = this.repairForm.sc_repair_form_item.other_accessory;
                this.otherPhysicalCondition = this.repairForm.sc_repair_form_item.other_unit_condition;
                this.defectDescription = this.repairForm.sc_repair_form_item.defect_description;
                this.defectRemarks = this.repairForm.sc_repair_form_item.defect_remarks;
                }
            }
        },
        selectedDealer() {
            // if(!!this.selectedDealer) {
            //     let dealer = this.dealersList.find((dealer) => dealer.id == this.selectedDealer);
            //     let fullName = [];
            //     let telNumber = [];
            //     let mobileNumber = [];
            //     let faxNumber = [];

            //     this.dealer = dealer.company_name;
            //     if(!!dealer.first_name) {
            //         fullName.push(dealer.first_name);
            //     }
            //     if(!!dealer.middle_name) {
            //         fullName.push(dealer.middle_name);
            //     }
            //     if(!!dealer.last_name) {
            //         fullName.push(dealer.last_name);
            //     }
            //     this.dealerName = fullName.toString().replaceAll(',', ' ');

            //     if(!!dealer.tel_1) {
            //         telNumber.push(dealer.tel_1);
            //     }
            //     if(!!dealer.tel_2) {
            //         telNumber.push(dealer.tel_2);
            //     }
            //     if(!!dealer.tel_3) {
            //         telNumber.push(dealer.tel_3);
            //     }
            //     this.dealerTelNumber = telNumber.toString().replaceAll(',', ' / ');

            //     if(!!dealer.mobile_1) {
            //         mobileNumber.push(dealer.mobile_1);
            //     }
            //     if(!!dealer.mobile_2) {
            //         mobileNumber.push(dealer.mobile_2);
            //     }
            //     if(!!dealer.mobile_3) {
            //         mobileNumber.push(dealer.mobile_3);
            //     }
            //     this.dealerMobileNumber = mobileNumber.toString().replaceAll(',', ' / ');
            //     this.dealerFaxNumber = dealer.fax;
            // }
        },
        'GET_SERVICE_ALL_CUSTOMERS':{
            handler(val){
                this.dealersList = val.data
            }
        },
        'GET_ITEMS_EXCLUDE_SP':{
            handler(val){
                this.GET_ITEMS_EXCLUDE_SP = val
            }
        },
        warrantyImages:{
            handler(val){
                if(val.length > 0){
                    this.hasUpload = true
                }
            }
        }
    },
    methods: {
        closeRfDialog() {
            this.editDialog = false;
            this.$emit('closeEditDialog');
            this.submitLoader = false;
            this.$refs.wImages.reset();
            this.warrantyImages = [];
            this.images = null;
            this.rfNumber = '';
            this.technicianDilaog = false
            this.clearCameraCapture();
        },
        updateRepairForm() {
            if(!this.requiredFields()) {
                this.submitData();
            }
        },
        submitData() {
            swal.fire({
                title: 'Are you sure you want to update?',
                text: 'Make sure that all information are correct',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then((result)=> {
                if(result.isConfirmed) {
                    this.submitLoader = true;

                    const data = new FormData();

                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }

                    if(_.compact(this.warrantyImages).length > 0) {
                        for(let i=0; i < _.compact(this.warrantyImages).length; i++) {
                            data.append('warrantyImages[]', this.warrantyImages[i]);
                        }
                    }

                    if(!!this.images && this.images.length > 0) {
                        for(let i=0;i < _.compact(this.images).length;i++) {
                            data.append('images[]', _.compact(this.images)[i]);
                        }
                    }

                    data.append('repairFormId', this.repairForm.id);
                    data.append('purchaseDate', this.itemPurchaseDate);
                    data.append('serial', this.itemSerial);
                    data.append('warrantyType', this.itemWarrantyType);
                    data.append('warrantyDocument', this.itemWarrantyDocument);
                    data.append('defectDescription', this.defectDescription);
                    data.append('defectRemarks', this.defectRemarks);
                    data.append('category', this.itemCategory);
                    data.append('otherUnitCondition', this.otherPhysicalCondition);
                    data.append('otherAccessory', this.otherIncludedItems);
                    data.append('accessories', _.compact(this.includedItems).toString());
                    data.append('unitCondition', _.compact(this.physicalCondition).toString());
                    data.append('hasUpload',this.hasUpload);

                    if(!!this.repairForm) {
                        let payload = {
                            params: data,
                            config: config
                        }
                        this.$store.dispatch('editRepairForm',payload).then(response => {
                            if(!!response.data.msg) {
                                swal.fire("", response.data.msg, "success");
                                this.repairForm = null;
                                this.submitLoader = true;
                                this.closeRfDialog();
                            }
                        }).catch(e => {
                            swal.fire("", e.data.error, "error");
                        });;
                    }
                }
            });
        },
        endUser() {
            if(!!this.rf) {
                if(this.rf.sc_receiving_report.customer_id == 275) {
                    return true;
                }
            }

            return false;
        },
        selectedItem(){
            if(!!this.itemModel) {
                this.itemProductName = this.GET_ITEMS_EXCLUDE_SP.find(x => x.id == this.itemModel).name;
                this.itemCategory = this.GET_ITEMS_EXCLUDE_SP.find(x => x.id == this.itemModel).item_category.name;
            }
        },
        minDate() {
            return this.$dayjs().subtract(6, 'month').format('YYYY-MM-DD');
        },
        getImages(images) {
            this.images = images;
        },
        clearCameraCapture() {
            this.$refs.cameraCapture.reset();
            this.images = null;
        },
        showTechnicians(){
            this.technicianDilaog = true
        },
        success(success){
            if(success ){
                this.technicianDilaog = false
            }else{
                console.log('error')
            }

        },
        requiredFields() {
            let warning = '';
            if (!this.itemWarrantyType) warning += 'Please select a Warranty Type.<br>';

            if(this.checkWarrantyType) {
                if (!this.itemWarrantyDocument) warning += 'Please select a Warranty Document.<br>';
                if (!this.itemPurchaseDate) warning += 'Please select a Purchase Date.<br>';
            }
            if (warning !== '') return swal.fire({
                title: 'Please Fill out Information:',
                html: warning,
                icon: "warning",
            });
        },
    }
}
</script>

<style>

</style>
