	<template>
		<v-container fluid >
			<h3 class="page-title">Web Mail</h3>
			<v-container class="ma-0">
					<v-app id="item-tabs">
						<v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" background-color="#424242" dark show-arrows>
							<v-tabs-slider color="cyan"></v-tabs-slider>
								<v-tab ripple v-for="(item, i) in items" :key="item" @click="tabs(i)">
									{{ item }}
								</v-tab>
						</v-tabs>
						<v-tabs-items v-model="tab">
							<v-tab-item v-for="(table, index) in webmail_component" :key="index">
								<keep-alive>
									<component :is="table.components" :webmail_component="webmail_component" :isActive_tab="table.status_text">
									</component>
								</keep-alive>
							</v-tab-item>
						</v-tabs-items>
					</v-app>
			</v-container>
			<DialogComponent :cp="components" :scrollable="dialog_scrollable" :width="height(dialog_width)" :retain-focus="false"></DialogComponent>
		</v-container>
	</template>

	<script>
	import DialogComponent from '@/views/main/layouts/dialogs/Dialog.vue';
	import WebMailComponent from '@/views/main/modules/WebMail/WebMailComponent.vue';

	import EmployeeDataStatusDialogComponent from '@/views/main/layouts/dialogs/HR/EmployeeData/EmployeeDataStatusViewComponent.vue';
	import WebmailViewComponentVue from '@/views/main/layouts/dialogs/WebMail/WebmailViewComponent.vue'
	import WebMailLogsViewComponent from '@/views/main/layouts/dialogs/WebMail/WebMailLogsViewComponent.vue'
	import { mapGetters } from 'vuex';
	export default {
		components:{
			DialogComponent,
			WebMailComponent,

			WebmailViewComponentVue,
			EmployeeDataStatusDialogComponent,
			WebMailLogsViewComponent
		},
		data() {
			return {
				components:'',
				items: [
						"EMAIL ACCOUNTS",
						"EMAIL LOGS"
						],
				webmail_component: [
					{
					components: WebMailComponent,
					status_text: "emailAccountsList",
					dialog: WebmailViewComponentVue,
					dialog_title:'New Email Account',
					dialog_width: "40%",
					scrollable:true,
					dispatch:{get:'getEmail',
								add:'addEmail',
								update:'updateEmail',
								delete:'deleteEmail',
								show:'showEmail'},
					},
					{
					components: WebMailComponent,
					status_text: "emailLogs",
					dialog: WebMailLogsViewComponent,
					dialog_title:'Web Mail Log',
					dialog_width: "50%",
					scrollable:true,
					dispatch:{get:'getEmployeeEmailsWithLogs',
								// add:'addEmail',
								// update:'updateEmail',
								// delete:'deleteEmail',
								show:'showEmail'
								},
					},

				],
				dialog_width: "",
				loaded: false,
				tab: null,
				dialog_scrollable:true,
			};
		},
		computed:{
			...mapGetters(['USERACCOUNT_LOAD'])
		},
		mounted(){
			this.tabs(0)
		},
		methods:{
		tabs(key) {
			this.components = this.webmail_component[key].dialog;
			this.dialog_width = this.webmail_component[key].dialog_width;
			this.dialog_scrollable = this.webmail_component[key].scrollable;

			this.$store.commit('WEBMAIL_DIALOG_TITLE',this.webmail_component[key].dialog_title)
			this.$store.commit('WEBMAIL_TAB_IS_ACTIVE',this.webmail_component[key].status_text)
			this.$store.commit('WEBMAIL_DISPATCH',this.webmail_component[key].dispatch)
		},
		getSearchFromUrl() {
            if (Object.keys(this.$route.query).length != 0) {
                Object.keys(this.$route.query).forEach(e=>{
					if(e == 'for_approval'){
						this.tab = this.items.length - 1
						this.tabs(this.tab)
					}
                })
            }
        },
		height(width) {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs': return "100%"
				case 'sm': return "100%"
				case 'md': return "75%"
				case 'lg': return width
				case 'xl': return width
			}
		},
	},
	watch:{
		USERACCOUNT_LOAD:{
			handler(val){
				// if(val.manager == 1 || val.super_admin == 1 || val.president == 1){
				// 	let approve_text = 'FOR APPROVAL'

				// 	if (!this.items.includes(approve_text)) {
				// 	this.items.push('FOR APPROVAL');
				// 	this.getSearchFromUrl();
				// 	}
				// }
			}
		}
	}

	};
	</script>
	<style>
	#group-tab {
		padding: 0 !important;
	}
	</style>
