<template>
    <v-container fluid class="pt-0">
        <v-row class="w-100 d-flex justify-end ma-0 my-2">
            <v-col class="d-flex justify-end pa-0" cols="1">
                <v-btn @click="exportSpItemsFOC()" :loading="exportLoading">Export</v-btn>
            </v-col>
        </v-row>
        <v-card elevation="0" class="pa-3">
            <v-col class="d-flex justify-end align-end">
                <!-- <a
                v-for="(auto_foc, index ) in auto_focs" :key="index"
                :class="`ml-1 badge badge-${auto_foc.text} ${auto_foc.value == selected_value ? `selected` : `non-selected`}`" @click="selectFOC(auto_foc)"
                >
                {{ auto_foc.text ? auto_foc.text : null  }}
                </a> -->
                <a :class="`ml-1 badge badge-all ${selected_foc === -1 ? 'selected' : 'non-selected'}`" @click="selected_foc = -1; selectFOC(-1);">all</a>
                <a :class="`ml-1 badge badge-yes ${selected_foc === 1 ? 'selected' : 'non-selected'}`" @click="selectFOC(1);">auto foc</a>
                <a :class="`ml-1 badge badge-no ${selected_foc === 0 ? 'selected' : 'non-selected'}`" @click="selectFOC(0);">none foc</a>
            </v-col>
            <v-card-title>
                <v-row class="mx-auto">
                    <v-spacer></v-spacer>
                    <v-col sm="12" md="12" lg="3" class="mx-1">
                        <v-text-field
                            v-model="search"
                            label="Search Model/Name/Part#"
                            single-line
                            hide-details
                            append-icon="mdi-magnify"
                            @keydown.enter="searchInput()"
                            @click:append="searchInput()"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-data-table
                    :items="dataTable.items"
                    :headers="dataTable.itemsHeaders"
                    :options.sync="dataTable.options"
                    :search="search"
                    :server-items-length="dataTable.totalItems"
                    :loading="!dataTable.loaded"
                    dense
                >
                    <template v-slot:[`item.action`]="{ item }">
                        <v-btn icon text @click="editSpItem(item)" color="blue" small>
                                <v-icon small>mdi-square-edit-outline</v-icon>
                            </v-btn>
                            <!-- {{ item }} -->
                        <!-- <v-btn icon text @click="deleteSpItem(item)" color="red" small>
                                <v-icon small>mdi-delete</v-icon>
                        </v-btn> -->
                    </template>
                    <template v-slot:[`item.auto_foc`]="{item}">
                        <v-icon :color="`${item.auto_foc == 1 ? 'green':'red'}`">
                            {{ item.auto_foc == 1 ? 'mdi-check-bold': 'mdi-minus-circle-outline' }}
                        </v-icon>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2'
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue';
export default {
    mixins:[SharedFunctionsComponent],
    data() {
        return {
            search: "",
            dataTable: {
                itemsHeaders: [
                    { text: 'Model', align: 'left', value: 'model', },
                    { text: 'Part#', align: 'left', value: 'part_num', },
                    { text: 'Name', align: 'left', value: 'name', },
                    { text: 'Category', align: 'left', value: 'item_category_text', },
                    { text: 'UOM', align: 'left', value: 'item_uom_text', sortable: false },
                    { text: 'SRP', align: 'right', value: 'selling_price', sortable: false },
                    { text: 'Foc', align: 'right', value: 'auto_foc', sortable: false },
                    { text: 'Actions', align: 'center', value: 'action', sortable: false },
                ],
                loaded:false,
                options:{},
                totalItems:0,
                items: [],
            },
            focs: {
                is_foc: -1,
            },
            selected_value: 0,
            selected_foc: -1,
            exportLoading: false,
        }
    },

    computed: {
        ...mapGetters([
            'GET_AUTO_FOC_SP',
            'GET_FOC_TAB',
            'GET_FOC_DISPATCH',
            'GET_FOC_DIALOG_TITLE',
            'ACTION',

        ]),

    },

    mounted() {
        this.getAutoFocSp()
    },

    methods: {
        selectFOC(auto_foc){
            // this.selected_value = data.value
            this.selected_foc = auto_foc;
            // if (this.foc[key] === auto_foc) {
            //     this.foc[key] = null;
            // } else {
            //     this.foc[key] = auto_foc;
            // }
            // if (this.foc.auto_foc === null) {
            //     this.foc.all = -1;
            // } else {
            //     this.foc.all = 0;
            // }
            this.dataTable.options.page = 1;
            this.getAutoFocSp()
        },

        async getAutoFocSp() {
            this.dataTable.loaded = false;
            this.dataTable.items = [];
            let payload = {
                page                        : this.dataTable.options.page,
                itemsPerPage                : this.dataTable.options.itemsPerPage,
                search                      : this.search,
                auto_foc                    : this.selected_foc,
            }
            await this.$store.dispatch(this.GET_FOC_DISPATCH.get, payload).then((response) => {
                this.dataTable.loaded                   = true;
                this.dataTable.items                    = response.data.data;
                this.dataTable.totalItems               = response.data.total;
            });
        },

        editSpItem(item){
            Swal.fire({
                title: 'Warning',
                text: `- Are you sure you want to ${item.auto_foc == 1 ? 'remove' : 'update'} auto foc?`,
                icon: 'question',
                showCancelButton:true,
                reverseButtons: true,
            }).then(action=>{
                if(action.isConfirmed){
                    let payload = {
                        id: item.id,
                        auto_foc: item.auto_foc == 0 ? 1 : 0,
                    }
                    this.$store.dispatch(this.GET_FOC_DISPATCH.update, payload).then(response=>{
                            Swal.fire('Success!','','success')
                            this.getAutoFocSp()
                        }).catch(err=>{
                            Swal.fire('Error!','','error')
                            console.log(err)
                        })
                    }
                })

        },

        // deleteSpItem(item){
        //     Swal.fire({
        //         title:'Warning',
        //         text:`- Are you sure you want to remove foc?`,
        //         icon:'warning',
        //         showCancelButton:true,
        //         reverseButtons:true
        //     }).then(action=>{
        //         if(action.isConfirmed){
        //             let payload = {
        //                 id: item.id,
        //                 auto_foc: 0,
        //             }
        //             this.$store.dispatch(this.GET_FOC_DISPATCH.delete, payload).then(response=>{
        //                     Swal.fire('Success!','','success')
        //                     this.getAutoFocSp()
        //                 }).catch(err=>{
        //                     Swal.fire('Error!','','error')
        //                     console.log(err)
        //                 })
        //         }
        //     })
        // },

        searchInput() {
            this.getAutoFocSp({
                search: this.search,
                page: this.dataTable.options.page = 1,
                itemsPerPage: this.dataTable.options.itemsPerPage,
            });
        },

        async exportSpItemsFOC() {
            this.exportLoading = true;
            let exportHeadersAndData = {
                ['Spare Parts FOC']: {
                    headers: [
                        {header:'MODEL(UNIT)', width: 20, key:'model', style: { alignment: { vertical: 'middle', horizontal: 'center' } }},
                        {header:'PART NUMBER', width: 20,key:'part_num', style: { alignment: { vertical: 'middle', horizontal: 'center' } }},
                        {header:'ITEM NAME', width: 30,key:'name', style: { alignment: { vertical: 'middle', horizontal: 'center' } }},
                        {header:'CATEGORY', width: 25,key:'item_category_text', style: { alignment: { vertical: 'middle', horizontal: 'center' } }},
                        {header:'UOM', width: 15,key:'item_uom_text', style: { alignment: { vertical: 'middle', horizontal: 'center' } }},
                        {header:'SRP', width: 15,key:'selling_price', style: { alignment: { vertical: 'middle', horizontal: 'center' }, numFmt: "#,##0.00"}},
                        {header:'FOC', width: 15,key:'auto_foc_text', style: { alignment: { vertical: 'middle', horizontal: 'center' } }},
                    ],
                    data: []
                }
            }
            let payload = {
                auto_foc: this.selected_foc,
                is_export: 1,
            }
            await this.$store.dispatch('getAutoFocSp', payload).then(response=>{
                let data = response
                data.forEach( e => {
                    switch (e.auto_foc) {
                        case 0:
                            e.auto_foc_text = 'No';
                            break;
                        case 1:
                            e.auto_foc_text = 'Yes';
                            break;
                    }
                    e.selling_price = parseFloat(e.selling_price);
                })
                exportHeadersAndData['Spare Parts FOC'].data = data;
                this.exportExcel(exportHeadersAndData, 'Spare Parts FOC', 'F69F1A');
                this.exportLoading = false;
            }).catch( e => {
                console.log(e)
                this.exportLoading = false;
            });
            
        }
    },

    watch: {
        'dataTable.options': {
            handler() {
                this.getAutoFocSp();
            },
            deep: true,
        },

    },
}
</script>

<style scoped>
.selected {
    opacity: 100%;
}

.non-selected {
    opacity: 40%;
}
</style>
