<template>
    <v-card color="#444444">
        <v-card-text>
            <v-row>
                <v-col>
                    <h5 style="color: white;">Required Documents</h5>
                </v-col>
            </v-row>
            <v-row class="d-flex align-center justify-content-center">
                <v-col v-for="(data, i) in items.required_documents" :key="i">
                    <v-chip>{{data.name}}</v-chip>
                </v-col>
            </v-row>
            <v-row>
                <v-col v-if="items.remarks">
                     <v-textarea
                        v-model="items.remarks"
                        background-color="white"
                        color="black"
                        label="Remarks"
                        auto-grow
                        filled
                    ></v-textarea>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
import FileUploadComponent from "@/views/main/Shared/FileUploadComponent.vue";
import BaseCameraCapture from "@/views/main/modules/HR/Base/BaseCameraCapture.vue"
import BaseFileViewer from "@/views/main/modules/HR/Base/BaseFilesViewer.vue";

import { mapGetters } from "vuex";
export default {
    mixins: [SharedFunctionsComponentVue],
    props:['items'],
    data() {
        return {
            valid: false,
            overlay: false,
            disabled_view: false,
            errors: {
                required: [(v) => !!v || "Field is required"],
                number: [
                    (v) => !!v || "Cost is required",
                    (v) => !isNaN(v) || "Input must be a number",
                ],
            },
        };
    },
    computed: {
        ...mapGetters([
            "ACTION",
            "GET_LIST_DEPARTMENT",
            "DIALOGS",
            "GET_HR_DISPATCH",
            'GET_JOB_TITLE_SELECTION',
            "GET_HR_OTHER_TABS_DIALOG_VIEW",
            "USER_ACCESS",
            "USERACCOUNT_LOAD",
            "GET_MANPOWER_REQUEST_REASON_TYPE_SELECTION",
            "GET_EDUCATION_TYPE_SELECTION",
            "GET_EXPERIENCE_TYPE_SELECTION",
            "GET_SPECIAL_REQUIREMENTS_SELECTION",
            "GET_SPECIAL_SKILLS_SELECTION"
        ]),
    },
    components:{
        BaseCameraCapture,
		BaseFileViewer,
		FileUploadComponent,
    },
    mounted() {
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
    },
    methods: {

    },
    watch: {
    },
};
</script>
<style scoped>

</style>
