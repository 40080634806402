<template>
    <div class="tabs-body-color">
        <v-container fluid>
            <v-row class="pt-2">
                <v-col cols="6">
                    <div class="btn-toolbar pl-0">
                        <v-btn class="mb-2" @click="AddDialog()" v-if="userAccess.includes('create') && PURCHASE_ORDER.STATUS != 'FOR PAYMENT'">
                            <v-icon>mdi-plus</v-icon>PURCHASE ORDER
                        </v-btn>

                        <v-btn class="ml-1 mb-2" @click="enterBillDialog()" v-if="PURCHASE_ORDER.STATUS == 'FOR PAYMENT'">
                            ENTER BILL
                        </v-btn>
                    </div>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="6" class="d-flex flex-row-reverse">
                    <v-btn :loading="PURCHASE_ORDER.EXCEL_LOADING" @click="exportExcel()">
                       Export
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
              <PurchaseOrderTableComponent></PurchaseOrderTableComponent>

            </v-row>
        </v-container>
    </div>
</template>
<script>
import PurchaseOrderTableComponent from '@/views/main/modules/Admin/PurchaseOrder/PurchaseOrderTableComponent.vue';
import { mapGetters } from 'vuex'
export default {
    components: {
        PurchaseOrderTableComponent,
    },
    props:["isActive_tab"],
    data() {
        return {
            userAccess:[],
            dialog_width:"",
        }
    },
    computed: {
        ...mapGetters(['USER_ACCESS','GET_PURCHASE_ORDER_DISPATCH_STATUS','PURCHASE_ORDER'])
    },
    mounted() {

    },
    methods: {
        async AddDialog(){
            // await this.$store.dispatch('adminPurchaseOrderGet', {
            //     url: 'get-last-po-num'
            // }).then((response)=>{
            //     this.$store.commit('LAST_PO_NUM', response)
            // });
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", "Submit");
        },
        exportExcel(){
            this.$store.commit("EXPORT_PURCHASE_ORDER", true);
            this.$store.commit("EXCEL_LOADING", true);
        },
        enterBillDialog(){
            this.$store.commit("ENTER_BILL_DIALOG", true);
            this.dialog_width = "50%"
            this.$store.commit("ACTION", "Submit");
        },
        width(width) {
            switch (this.$vuetify.breakpoint.name) {
            case 'xs': return "100%"
            case 'sm': return "100%"
            case 'md': return '100%'
            case 'lg': return '100%'
            case 'xl': return width
            }
        },
    },
    watch:{
        USER_ACCESS:{
            handler(val){
             if(val != "fail")
                this.userAccess = val.map(e=>e.actions_code);
            }
        },
    }
};
</script>
