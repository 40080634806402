
import ServiceStockRequest from "@/views/main/modules/Operations/StockRequest/InventoryRequestComponent";
import repository from "@/api/credentials";

const routes = 
        [{

              path: "/services/service-stock-request/:id",
              name: "servicestockrequest",
              component: ServiceStockRequest,
              beforeEnter:(to,from,next)=>{
                repository.checkAccessUser(to.params.id).then((response)=>{
                    if(response.data == 'fail'){
                        next('/no-access')
                        }else{
                        next()
                    }
                }).catch(()=>{

                })

          },
              meta:{
                  requiresAuth:true
                }
          }]



export default routes;
