<template>
    <v-card>
        <div v-for="(link,i) in links" :key="i" > 
            <v-btn @click="goTo(link.route_name)">
                {{ link.name }}
            </v-btn>
        </div>
    </v-card>
</template>

<script>
export default {
    data(){
        return{
            links:[],
        }
    },
    mounted(){
        this.getAllWarehouseQueueing()
    },
    methods:{
        getAllWarehouseQueueing(){
            this.links = this.$store.state.Queueing.warehouse_queueing;
        },
        goTo(route){
            this.$router.push({
                name:route
            })
        }
    }
}
</script>

<style>

</style>