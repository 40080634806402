<template>
<div class="tabs-body-color">

    <div class="container">
        <!-- <div class="row">
            <div class="col-12 d-flex no-block align-center">
                <div class="btn-toolbar">
                    <v-btn data-modal-id="addCategoryModal" v-on:click="showAddCategory()">
                        <i class="mdi-add"></i>Add
                    </v-btn>
                </div>
            </div>
        </div> -->
        <div class="row">
            <div class="col-12 d-flex no-block align-center">
                <div class="btn-toolbar">
                    <div class="row my-1">
                        <div class="">
                            <div class="btn-toolbar">
                                <v-btn class="" @click="showAddCategory()" v-if="userAccess.includes('categories_create')">
                                    <v-icon>mdi-plus</v-icon>
                                    Add
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <!-- Categories Table -->
            <CategoriesTableComponentVue :userAccess="userAccess" :itemCategoriesActionResult="action" @showEditModal="showEditModal"></CategoriesTableComponentVue>
        </div>
    </div>

    <v-app id="addEditCategoryModal">
        <v-dialog v-model="add_edit_category_dialog" persistent max-width="500" scrollable>
            <v-card>
                <v-row class="m-0">
                    <v-col cols="11">
                        <v-card-title>
                            <span class="headline">Category Information</span>
                        </v-card-title>
                    </v-col>
                   
                    <v-col cols=1 class="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="add_edit_category_dialog = false; resetFields(); $emit('closeModal');">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider class="ma-0 mb-3"></v-divider>
                <v-card-text>
                    <form class="form-horizontal px-4" action="/itemCategories" method="post" v-on:submit.prevent="onSubmit">
                        <v-row class="mb-2">
                            <v-col cols="12">
                                <label for="name" class="float-left control-label col-form-label font-weight-medium">Name <span v-if="action != 'View'" class="red--text">*</span></label>
                                <input type="text" class="form-control" id="name" v-model="form.name" :readonly="action == 'View'">
                                <span v-for="errors in errors.name" class="text-warning" v-bind:key="errors">{{errors}}</span>
                            </v-col>

                            <v-col cols="12">
                                <label for="description" class="float-left control-label col-form-label font-weight-medium">Code <span v-if="action != 'View'" class="red--text">*</span></label>
                                <input type="text" class="form-control" id="description" v-model="form.code" :readonly="action == 'View'">
                                <span v-for="errors in errors.code" class="text-warning" v-bind:key="errors">{{errors}}</span>
                            </v-col>

                            <!-- <v-col cols="12">
                                <label for="description" class="float-left control-label col-form-label font-weight-medium">Description</label>
                                <input type="text" class="form-control" id="description" v-model="form.description" :readonly="action == 'View'">
                                <span v-for="errors in errors.description" class="text-warning" v-bind:key="errors">{{errors}}</span>
                            </v-col> -->

                            <v-col cols="12">
                                <label for="min_moq" class="float-left control-label col-form-label font-weight-medium">Minimum Allowed MOQ</label>
                                <v-col cols="3">
                                    <input type="integer" class="form-control center-input" id="min_moq" @keypress="countingNumberOnly" v-model="form.min_moq" :readonly="action == 'View'">
                                </v-col>
                                <span v-for="errors in errors.min_moq" class="text-warning" v-bind:key="errors">{{errors}}</span>
                            </v-col>
                        </v-row>
                        <div class="border-top mt-2">
                            <div class="card-body py-4 d-flex justify-content-center">
                                <v-btn  v-on:click="addNewCategory()" v-if="action=='Add'" :loading="loading">Submit</v-btn>
                                <v-btn  v-on:click="editCategory()" v-else-if="action=='Edit'" :loading="loading">Update</v-btn>
                            </div>
                        </div>
                    </form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

</div>
</template>

<script>
import CategoriesTableComponentVue from '../tables/CategoriesTableComponent.vue';
import swal from 'sweetalert2'
import { mapGetters } from 'vuex';
    export default {
        data(){
            return {
                editCategoryId: '',
                add_edit_category_dialog: false,
                form: {
                    name: '',
                    code: '',
                    description: '',
                    min_moq: 1,
                },
                errors: [],
                action: '',
                actionResult : {
                    model : 'Category',
                    action : ''
                },
                userAccess: [],
                loading: false,
            }
        },
        components: {
            CategoriesTableComponentVue
        },
        computed:{
            ...mapGetters([
                'GET_SHOW_ITEM_CATEGORIES',
                'USER_ACCESS'
            ])
        },
        async mounted() {
        await this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)

        },  
        methods: {
            showAddCategory(){
                this.add_edit_category_dialog = true
                this.action = 'Add'
            },
            async addNewCategory(){
                this.errors = []
                let payload = {
                    name: this.form.name,
                    code: this.form.code,
                    min_moq: this.form.min_moq
                }
                let validations = [
                    {validate: !this.form.name.trim(), message: 'Name is required!'},
                    {validate: !this.form.code.trim(), message: 'Code is required!'},
                    {validate: this.form.min_moq < 1 , message: 'MOQ cannot be empty!'},
                ]
                let error = validations.find(e => e.validate === true);
                if (error) {
                    swal.fire('Warning!', error.message, 'warning');
                    return;
                }else{
                    this.loading = true;
                    await this.$store.dispatch('storeItemCategories',payload).then(response => {
                        this.add_edit_category_dialog = false
                        this.$store.dispatch('getAllItemCategories');
                        this.action = 'success'
                        swal.fire("", "Add Category Successful!", "success");
                        this.loading = false;
                        this.resetFields()
                    }).catch(error => {
                        swal.fire("", "Add Category Failed!", "error");
                        this.errors = error.response.data.errors;
                        this.loading = false;
                    });
                }
            },
            showEditModal(data){
                this.action = data.action
                this.editCategoryId = data.id
                if(this.editCategoryId != null && this.editCategoryId > 0){
                    this.$store.dispatch('showItemCategories',this.editCategoryId).then(response => {
                        let data = this.GET_SHOW_ITEM_CATEGORIES
                        this.form.name                       = data.name
                        this.form.code                       = data.code
                        this.form.min_moq                    = data.min_allowed_moq
                        this.add_edit_category_dialog        = true
                        
                    }).catch(error => {
                        swal.fire('','Failed to Load','error')
                    });
                }
            },
            editCategory(){
                this.errors = []
                swal.fire({
                    text: `Are you sure you want to proceed?`,
                    icon:'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then(async(result) => {
                    if(result.isConfirmed) {
                        let payload = {
                            id: this.editCategoryId,
                            name: this.form.name,
                            code: this.form.code,
                            min_moq: this.form.min_moq
                        }
                        let validations = [
                            {validate: !this.form.name.trim(), message: 'Name is required!'},
                            {validate: !this.form.code.trim() , message: 'Code is required!'},
                            {validate: this.form.min_moq < 1 , message: 'MOQ cannot be empty!'},
                        ]
                        let error = validations.find(e => e.validate === true);
                        if (error) {
                            swal.fire('Warning!', error.message, 'warning');
                            return;
                        }else{
                            this.loading = true;
                            this.$store.dispatch('updateItemCategories',payload).then(response => {
                                this.resetFields()
                                this.add_edit_category_dialog = false
                                this.editCategoryId           = 0
                                this.action               = 'success'
                                this.$store.dispatch('getAllItemCategories');
                                swal.fire("", "Edit Category Successful!", "success");
                                this.loading = false;
                            }).catch(error => {
                                swal.fire("", "Edit Category Failed!", "success");
                                this.errors = error.response.data.errors;
                                this.action = 'Edit'
                                this.loading = false;
                            });
                        }
                    }
                });
            },
            resetFields(){
                this.errors              = []
                this.form.name           = ''
                this.form.code           = ''
                this.form.min_moq        = 1

            },
            countingNumberOnly ($event) {
                let keyCode = $event.keyCode ? $event.keyCode : $event.which;
                let inputValue = $event.target.value;
                if ((inputValue === '' && keyCode === 48) || keyCode === 45 || keyCode < 48 || keyCode > 57) {
                    $event.preventDefault();
                }
            },

        },
        watch: {
            USER_ACCESS:{
                handler(val){
                    this.userAccess = val.map(e=>e.actions_code);
                }
            },
        }
    };
</script>
<style>
    .v-text-field.v-text-field--enclosed .v-text-field__details{
        display: none;
    }

    .center-input{
        text-align: center;
    }
</style>
