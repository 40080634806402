<template>
    <v-container fluid >
        <h3 class="page-title">FAS PURCHASE ORDER</h3>
        <v-container class="ma-0">
            <v-app id="item-tabs">
                <v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" background-color="#424242" dark show-arrows>
                    <v-tabs-slider color="cyan"></v-tabs-slider>
                    <v-tab ripple v-for="(item, i) in items" :key="i" @click="tabs(i)" v-if="item.view">
                          {{ item.tab_name }}
                   </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item  v-for="(item, i) in items" :key="i">
          <keep-alive>
            <component  :is="fas_purchase_order[i].components" :fas_purchase_order="fas_purchase_order" :isActive_tab="fas_purchase_order[i].status_text">
            </component>
          </keep-alive>
        </v-tab-item>
      </v-tabs-items>
            </v-app>
        </v-container>
        <DialogComponent :cp="components" :scrollable="dialog_scrollable" :width="width(dialog_width)" :retainfocus="false"></DialogComponent>
    </v-container>
  </template>
  
  <script>
  import DialogComponent from '@/views/main/layouts/dialogs/Dialog.vue';
  import FasPOComponent from '@/views/main/modules/Admin/FASPurchaseOrder/FasPOComponent.vue';
  import FasPOViewComponent from '@/views/main/layouts/dialogs/Admin/FasPOViewComponent.vue';
  import { mapGetters } from 'vuex';
  export default {
    components:{
        DialogComponent,
        FasPOViewComponent,
        FasPOComponent,
    },
    data() {
        return {
          form: {
              code: '',
              name: '',
              sub_class:[],
              sub_class_trash:[],
            },
            components:'',
            items: [
                    {tab_name:"ALL APPROVE FAS PR",view:true},
                    {tab_name:"ALL PURCHASE ORDER",view:true},
                    {tab_name:"PENDING PURCHASE ORDER",view:true},
                    {tab_name:"APPROVED PURCHASE ORDER",view:true},
                  ],
            fas_purchase_order: 
                    [
                    {
                      components: FasPOComponent,
                      dialog: FasPOViewComponent,
                      dialog_title:'Purchase Order',
                      dialog_width: "40%",
                      status_text:'APPROVED',
                      scrollable:true,
                      dispatch:{
                                get:'getAllFasRequisition',
                                add:'createFasPO',
                                update:'updateFasPO',
                                delete:'deleteFasPO'
                          },
                        headers: [
                            { text: "FAS #", align: "left", value: "fas_number" },
                            { text: "Full Name", align: "left", value: "full_name" },
                            { text: "Department", align: "left", value: "department_id" },
                            { text: "Location", align: "left", value: "location_id" },
                            { text: "Status", align: "left", value: "status" },
                            { text: "Remarks", align: "left", value: "justification_remarks" },
                            { text: "Action", align: "center", value: "action",sortable:false},
                          ],
                    },
                    {
                      components: FasPOComponent,
                      dialog: FasPOViewComponent,
                      dialog_title:'Purchase Order',
                      dialog_width: "50%",
                      status_text:'ALL',
                      scrollable:true,
                      dispatch:{
                                get:'getAllFasPurchaseOrder',
                                add:'createFasPO',
                                update:'updateFasPO',
                                delete:'deleteFasPO'
                          },
                          headers: [
                            { text: "PO #", align: "left", value: "id" },
                            { text: "FAS REQ#", align: "left", value: "fas_number" },
                            { text: "Full Name", align: "left", value: "full_name" },
                            { text: "Status", align: "left", value: "status" },
                            { text: "Remarks", align: "left", value: "justification_remarks" },
                            { text: "Action", align: "center", value: "action",sortable:false},
                          ],
                    },
                    {
                      components: FasPOComponent,
                      dialog: FasPOViewComponent,
                      dialog_title:'Purchase Order',
                      dialog_width: "50%",
                      status_text:'PENDING',
                      scrollable:true,
                      dispatch:{
                                get:'getAllFasPurchaseOrder',
                                add:'createFasPO',
                                update:'updateFasPO',
                                delete:'deleteFasPO'
                          },
                          headers: [
                            { text: "PO #", align: "left", value: "id" },
                            { text: "FAS REQ#", align: "left", value: "fas_number" },
                            { text: "Full Name", align: "left", value: "full_name" },
                            { text: "Status", align: "left", value: "status" },
                            { text: "Remarks", align: "left", value: "justification_remarks" },
                            { text: "Action", align: "center", value: "action",sortable:false},
                          ],
                    },
                    {
                      components: FasPOComponent,
                      dialog: FasPOViewComponent,
                      dialog_title:'Purchase Requisition',
                      dialog_width: "50%",
                      status_text:'POAPPROVED',
                      scrollable:true,
                      dispatch:{
                                get:'getAllFasPurchaseOrder',
                                add:'createFasPO',
                                update:'updateFasPO',
                                delete:'deleteFasPO'
                          },
                          headers: [
                            { text: "PO #", align: "left", value: "id" },
                            { text: "FAS REQ#", align: "left", value: "fas_number" },
                            { text: "Full Name", align: "left", value: "full_name" },
                            { text: "Status", align: "left", value: "status" },
                            { text: "Remarks", align: "left", value: "remarks" },
                            { text: "Action", align: "center", value: "action",sortable:false},
                          ],
                    },
                  ],
            dialog_width: "",
            loaded: false,
            options: {},
            itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
            itemsPerPage: 10,
            tab: null,
            dialog_scrollable:true,
        };
    },
    computed:{
        ...mapGetters([])
    },
    mounted(){
          this.dropDowns()
          this.tabs(0);
    },
    methods:{
    dropDowns(){
          this.$store.dispatch('getDepartment')
          this.$store.dispatch('getitemUomSelection');
          this.$store.dispatch("getAdminLocationSelection");
    },
    tabs(key) {
      
       this.components = this.fas_purchase_order[key].dialog;
       this.dialog_width = this.fas_purchase_order[key].dialog_width;
       this.dialog_scrollable = this.fas_purchase_order[key].scrollable;
       this.$store.commit('ADMIN_FASPO_TABLE_HEADERS',this.fas_purchase_order[key].headers)
       this.$store.commit('ADMIN_FASPO_STATUS',this.fas_purchase_order[key].status_text)
       this.$store.commit('ADMIN_FASPO_DISPATCH',this.fas_purchase_order[key].dispatch)
    },
    width(width) {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return "100%"
          case 'sm': return "100%"
          case 'md': return width
          case 'lg': return width
          case 'xl': return width
        }
      },
    },
    watch:{
      "refresher":{
        handler(val){
          // if(val){
          //   this.$store.state.purchaseRequest.purchase_request_refresh = true
          // }
       
          // this.refresher = false
        }
      },
      GET_NEW_PURCHASE_REQUEST_TABLE:{
        handler(val){
          if(val){
            this.ServerPusher();
          }
        }
      }
    }
  };
  </script>
  <style>
  #group-tab {
    padding: 0 !important;
  }
  </style>
  