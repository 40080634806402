<template>
    <div>
        <div>
            <v-btn data-modal-id="drFormModal" v-on:click="showCustomerConversationDialog()">
                <i class="fas fa-plus pr-2"></i>Add
            </v-btn>
        </div>

        <!-- Add Customer Conversation Dialog -->
        <v-dialog v-model="addCustomerConversationDialog" persistent max-width="32%">
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Add Customer</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeCustomerConversationDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider class="mt-0" />

                <v-card-text>
                    <div class="pb-6">
                        <div>
                            <v-autocomplete
                                dense
                                outlined
                                :items="sources"
                                v-model="selectedSource"
                                item-value="id"
                                item-text="name"
                                label="Source"
                                auto-select-first
                            >
                            <template #prepend-inner>
                                <span style="color:red">*</span>
                            </template>
                            </v-autocomplete>
                        </div>
                    </div>
                    <div class="pb-6">
                        <div>
                            <v-autocomplete
                                v-model="selectedCustomer"
                                :items="customerList"
                                :rules="rules.default"
                                item-text="company_name"
                                item-value="id"
                                label="Customer"
                                auto-select-first
                                dense
                                outlined
                            >
                            <template #prepend-inner>
                                <span style="color:red">*</span>
                            </template>
                            </v-autocomplete>
                        </div>
                    </div>

                    <div v-if="selectedCustomer == 275">
                        <div>
                            <div style="font-weight:bold">First Name: <span style="color:red">*</span></div>
                            <div>
                                <v-text-field v-model="firstName" dense outlined/>
                            </div>
                        </div>
                        <div>
                            <div style="font-weight:bold">Middle Name:</div>
                            <div>
                                <v-text-field v-model="middleName" dense outlined/>
                            </div>
                        </div>
                        <div>
                            <div style="font-weight:bold">Last Name:</div>
                            <div>
                                <v-text-field v-model="lastName" dense outlined/>
                            </div>
                        </div>
                        <div>
                            <div style="font-weight:bold">Contact Number: <span style="color:red">*</span></div>
                            <div>
                                <v-text-field v-model="contactNumber" dense outlined/>
                            </div>
                        </div>
                        <div>
                            <div style="font-weight:bold">Email:</div>
                            <div>
                                <v-text-field v-model="email" dense outlined/>
                            </div>
                        </div>
                        <div>
                            <div style="font-weight:bold">Address:</div>
                            <div>
                                <v-textarea v-model="address" dense outlined/>
                            </div>
                        </div>
                    </div>
                    <div class="pb-6">
                        <div>
                            <v-autocomplete
                                dense
                                outlined
                                v-model="selectedItemModel"
                                :items="itemList"
                                item-value="id"
                                item-text="model"
                                label="Model"
                                auto-select-first
                            >
                            <template #prepend-inner>
                                <span style="color:red">*</span>
                            </template>
                            </v-autocomplete>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>
                                <v-textarea
                                    v-model="issue"
                                    :rules="rules.default"
                                    label="Concern"
                                    dense
                                    outlined
                                >
                                    <template #prepend-inner>
                                        <span style="color:red">*</span>
                                    </template>
                                </v-textarea>
                            </div>
                        </div>
                    </div>
                </v-card-text>

                <v-divider />

                <div style="text-align:center" class="pb-4">
                    <v-btn @click="createCustomerConversation()">Submit</v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import swal from 'sweetalert2';
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            addCustomerConversationDialog: false,
            selectedCustomer: '',
            customerList: [],
            firstName: '',
            middleName: '',
            lastName: '',
            contactNumber: '',
            email: '',
            address: '',
            customerConversationCustomerIds: [],
            issue: '',
            sources: [],
            selectedSource: '',
            itemList: [],
            selectedItemModel: '',
            addCcOnFlight: false,
            rules: {
                default: [
                v => !!v || 'This is required',
                ],
            },
        }
    },
    async mounted() {
        await this.getCustomers();
        await this.getAllConversationSources();
        await this.getServiceItems();
    },
    computed:{
        ...mapGetters([
            'GET_ALL_CONVERSATION_SOURCES',
            'GET_SERVICE_ALL_CUSTOMERS',
            'GET_SERVICE_ALL_ITEMS',
        ])
    },
    watch: {
    },
    methods: {
        clearFields() {
            this.selectedCustomer = '';
            this.issue = '';
            this.selectedSource = '';
        },
        getAllConversationSources() {
            this.$store.dispatch('getAllConversationSources').then(response=>{
                this.sources = this.GET_ALL_CONVERSATION_SOURCES;
            });
        },
        showCustomerConversationDialog() {
            this.addCustomerConversationDialog = true;
        },
        closeCustomerConversationDialog() {
            this.addCustomerConversationDialog = false;
            this.selectedCustomer = '';
        },
        getCustomers() {
            this.$store.dispatch('getServiceAllCustomers').then(response => {
                this.customerList = this.GET_SERVICE_ALL_CUSTOMERS;
            });
        },
        getServiceItems() {
            this.$store.dispatch('serviceGetAllItems').then(response => {
                this.itemList = this.GET_SERVICE_ALL_ITEMS
            });
        },
        createCustomerConversation() {
            this.addCcOnFlight = true;
            if(!this.requiredFields()) {
                swal.fire({
                    title: 'Are you sure you want to submit?',
                    text: 'Make sure that all information are correct',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then((result)=>{
                    if(result.isConfirmed) {
                        let payload = {
                            customerId: this.selectedCustomer,
                            firstName: this.firstName,
                            middleName: this.middleName,
                            lastName: this.lastName,
                            contactNumber: this.contactNumber,
                            email: this.email,
                            address: this.address,
                            issue: this.issue,
                            sourceId: this.selectedSource,
                            itemId: this.selectedItemModel
                        }
                        this.$store.dispatch('createCustomerConversation',payload).then(response=>{
                            if(response.data.error) {
                                swal.fire("", response.data.error, "error");
                            } else {
                                this.closeCustomerConversationDialog();
                                this.$store.commit('successAddEditCustConversation');
                                this.clearFields();
                                swal.fire("", response.data.msg, "success").then(ok=>{
                                    this.$store.commit('unsuccessAddEditCustConversation');
                                    this.addCcOnFlight = false;
                                });
                            }
                        });
                    }
                });
            } else {
                swal.fire('','Please Fill Required Fields','warning')
            }

        },
        requiredFields() {
            let warning = '';
            if (!this.source) warning += 'Please select a Source.<br>';

            if (warning !== '') return swal.fire({
                title: 'Please Fill out Information:',
                html: warning,
                icon: "warning",
            }).then(confirm=>{
                this.addCcOnFlight = false;
            });
        },
    }
}
</script>

<style>

</style>
