<template>
    <v-container>
        <v-btn @click="openCamera">CAMERA</v-btn>
        <v-dialog v-model="camera_dialog" width="50%">
            <v-card>
                <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                <span class="text-h5">Camera Picture Upload</span>
                <v-row class="m-0">
                    
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                        text
                        icon
                        small
                        color="gray"
                        class="float-right"
                        @click="camera_dialog = false"
                        >
                        <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-container class="picture">
                            <canvas style="display: none;"></canvas>
                        </v-container>
                        <v-row>
                            <v-col class="text-center">
                                <video ref="videos" autoplay class="camera">Steam unavailable</video>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="text-center">
                                <v-btn @click="takePicture">Take Picture</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>    
                </v-card-text>
                <v-divider></v-divider>
            </v-card>
        </v-dialog>
        <v-dialog v-model="image_preview" width="auto">
            <v-card>
                <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                <span class="text-h5">Preview Image</span>
                <v-row class="m-0">
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                        text
                        icon
                        small
                        color="gray"
                        class="float-right"
                        @click="image_preview = false"
                        >
                        <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                </v-card-title>
                <v-card-text>  
                    <v-container>
                        <v-row>
                            <v-file-input
                                ref="inputUploader"
                                type="file"
                                name="files"
                                class="form-control pt-1"
                                v-model="images"
                                :multiple="true"
                            />
                            <v-col class="text-center">
                                <v-img :src="imageTaken" height="360" width="640" style="float:center"></v-img>
                            </v-col>
                        </v-row>
                    </v-container>   
                </v-card-text>
                <v-card-actions class="text-center">
                    <v-row class="ma-0 pa-0">
                        <v-col>
                            <v-btn color="red">
                                Discard
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn>
                                Save
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>

   


</template>

<script>
export default {
    data(){
        return {
            camera_dialog:false,
            imageTaken:'',
            image_preview:false,
        }   
    },
    methods:{
        openCamera(){
            this.camera_dialog = true;
            const picture = document.querySelector('canvas');

            const ctx = picture.getContext("2d")

            ctx.clearRect(0,0,picture.width, picture.height)
        },
        takePicture(){
            this.camera_dialog = false;
            let ratio = (window.innerHeight < window.innerWidth) ? 32 / 16 : 16 / 32;
            const picture = document.querySelector('canvas');
            picture.width = (window.innerWidth < 1280) ? window.innerWidth:1280;
            picture.height = window.innerHeight / ratio;
            const ctx = picture.getContext("2d")
            ctx.imageSmoothingEnabled = true;
            ctx.imageSmoothingQuality = "high";
            ctx.drawImage(document.querySelector("video"),0,0,picture.width, picture.height)
            this.image_preview = true
            const base64Raw = picture.toDataURL().toString();
            this.imageTaken = base64Raw;
        },
        cameraRender(){
            if('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices){ 
                let contraints = {
                    video: {
                        width:{
                        min:640,
                        ideal:1280,
                        max:640
                        },
                        height:{
                        min:360,
                        ideal:720,
                        max:360
                        }
                    }
                }
                navigator.mediaDevices.getUserMedia(contraints).then(stream => {
                const videoPlayer = document.querySelector('video')
                videoPlayer.srcObject = stream
                videoPlayer.play();
                }).catch((e)=>{
                    console.log(e);
                });
            }
        },
    },
    watch:{
        camera_dialog:{
            handler(val){
                if(val){
                    this.cameraRender();
                }
            }
        },
    }
}
</script>

<style>
canvas{
    display: block;
      width: 100%;
      height: 100%;
  }
</style>