<template>
    <div>
        <div class="py-2" v-if="userAccess.create && !(bankType == 'CLOSED' || bankType == 'OB')">
            <v-btn @click="showAddEditBankDialog()">
                <v-icon>mdi-plus</v-icon> Add
            </v-btn>
        </div>

        <v-dialog v-model="addEditBankDialog" persistent max-width="50%">
            <v-card>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">{{ `${dialogAction == 'edit' ? 'Edit' : 'Add'} Bank: ` + name }}</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeAddEditBankDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <div style="max-height: 60vh; overflow-y: auto; padding: 0 24px;">

                <v-divider class="mt-0" />

                <div class="px-2">
                    <div>
                        <span>Owner Name: (Optional)</span>
                        <div>
                            <v-text-field
                                v-model="form.owner_name"
                                dense
                                outlined
                            />
                        </div>
                    </div>

                    <v-row class="px-3 py-2">
                        <v-col>
                            <span>Email: (Optional)</span>
                            <v-text-field
                                v-model="form.email"
                                dense
                                outlined
                                type="email"
                            />
                        </v-col>
                        <v-col>
                            <span>Contact No: (Optional)</span>
                            <v-text-field
                                v-model="form.contact_number"
                                dense
                                outlined
                            />
                        </v-col>
                    </v-row>
                    <!-- <v-row>
                        <v-spacer />
                        <v-col class="d-flex justify-end px-4">
                            <v-checkbox
                                v-model="local"
                                hide-details
                            >
                                <template v-slot:label>
                                    <span style="cursor: pointer;">Local</span>
                                </template>
                            </v-checkbox>
                        </v-col>
                    </v-row> -->

                    <div>
                        <div>Origin: <span style="color:red">*</span></div>
                        <div>
                            <v-select
                                v-model="local"
                                :items="GET_BANK_ORIGIN_SELECTION"
                                :rules="rules.default"
                                auto-select-first
                                dense
                                outlined
                            />
                        </div>
                    </div>

                    <div v-if="byPassFilter">
                        <div>Type: <span style="color:red">*</span></div>
                        <div>
                            <v-autocomplete
                                v-model="type"
                                :items="GET_BANK_TYPES_SELECTION"
                                :rules="rules.default"
                                auto-select-first
                                dense
                                outlined
                                deletable-chips
                                small-chips
                                multiple
                            />
                        </div>
                    </div>

                    <div>
                        <div>Code: <span style="color:red">*</span></div>
                        <div>
                            <v-text-field v-model="code" :rules="rules.default" dense outlined />
                        </div>
                    </div>

                    <div>
                        <div>Name: <span style="color:red">*</span></div>
                        <div>
                            <v-text-field v-model="name" :rules="rules.default" dense outlined />
                        </div>
                    </div>

                    <!-- <div>
                        <div>Account Number: <span style="color:red">*</span></div>
                        <div>
                            <v-text-field v-model="account_number" dense outlined />
                        </div>
                    </div> -->
                    <!-- <span style="color:red">*</span> -->
                    <div>
                        <div>Allowed Payment </div>
                        <div>
                            <v-autocomplete
                            small-chips
                            multiple
                            :items="GET_PAYMENT_TYPES"
                            v-model="payments.selected"
                            item-value="id"
                            item-text="name"
                            auto-select-first
                            dense
                            clearable
                            outlined />
                        </div>
                    </div>
                    <div v-if="byPassFilter">
                        <div>Assigned Users</div>
                        <div>
                            <v-autocomplete
                            v-model="assigned_users"
                            :items="GET_ALL_USERS_SELECT"
                            auto-select-first
                            item-text="name"
                            item-value="id"
                            multiple
                            small-chips
                            deletable-chips
                            dense
                            clearable
                            outlined />
                        </div>
                    </div>
                    <div>
                        <div>Assign Account</div>
                        <div>
                            <v-autocomplete
                            :items="getChildrenChartofAccounts"
                            v-model="coa_assigned"
                            auto-select-first
                            item-value="id"
                            item-text="account_name"
                            dense
                            clearable
                            outlined />
                        </div>
                    </div>
                    <div>
                        <!-- <span style="color:red">*</span> -->
                        <v-container>
                            <v-row>
                            <v-col cols="6">
                            <div>Companies: <span style="color:red">*</span></div>
                            <v-autocomplete
                                :items="GET_COMPANIES_SELECTION"
                                v-model="company_id"
                                item-value="value"
                                item-text="text"
                                auto-select-first
                                dense
                                clearable
                                outlined
                                @change="getStoreBranches()"
                            />
                        </v-col>
                        <!-- <span style="color:red">*</span> -->
                        <v-col cols="6">
                            <div>Stores / Branches: <span style="color:red">*</span></div>
                            <v-autocomplete
                                v-model="branch_id"
                                :items="GET_PV_STORE_BRANCHES_SELECTION"
                                item-value="value"
                                item-text="text"
                                auto-select-first
                                dense
                                clearable
                                outlined
                            />
                        </v-col>
                            </v-row>
                        </v-container>
                    </div>
                    <div>
                        <div>Description:</div>
                        <div>
                            <v-textarea v-model="description" dense outlined />
                        </div>
                    </div>

                    <div>
                        <div>Address: </div>
                        <div>
                            <v-textarea v-model="address" dense outlined />
                        </div>
                    </div>

                    <v-row>
                        <v-col v-if="dialogAction == 'edit'" class="d-flex justify-start px-4">
                            <v-checkbox
                                v-model="closed_account"
                                :true-value='1'
                                :false-value='0'
                                hide-details
                            >
                                <template v-slot:label>
                                    <span style="cursor: pointer;">Closed Account</span>
                                </template>
                            </v-checkbox>
                        </v-col>
                    </v-row>

                    <hr>

                    <div>
                        <div class="d-flex">
                            <div class="flex-fill">Booklets</div>
                            <v-btn
                                small
                                @click="resetNewBookletForm(); openBookletDialog()"
                            >New Booklet</v-btn>
                        </div>
                        <v-row>
                            <v-col cols="12" sm="8" md="4" lg="4" xl="4" class="ml-2">
                                <v-card class="total-cards" elevation="2">
                                    <v-card-text class="d-flex justify-content-start align-items-center">
                                        <div class="card-icon pt-2 pb-2 pl-1 pr-1 rounded bg-warning">
                                            <v-icon class="text-white" style="font-size:40px">mdi-currency-php</v-icon>
                                        </div>
                                        <div class="card-details ml-2">
                                            <p class="mb-0">Total Issued Booklets Amount</p>
                                            <p></p>
                                            <b style="color:black">{{ total_issued_booklet_amount | currency }}</b>
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-data-table
                            :headers="dataTable.headers"
                            :items="dataTable.items"
                            :server-items-length="dataTable.totalItems"
                            :options.sync="dataTable.options"
                            :loading="!loadedBooklets"
                            no-data-text="No entries found."
                            dense
                            show-expand
                        >
                            <template v-slot:[`item.total_issued_amount`]="{ item }">
                                <span class="text-nowrap">
                                    &#x20b1;{{ item.total_issued_amount | currency }}
                                </span>
                            </template>
                            <template v-slot:expanded-item="{ item }">
                                <td :colspan="dataTable.headers.length + 1" class="p-0">
                                    <v-data-table
                                        :headers="dataTable.bookletNumberHeaders"
                                        :items="item.cheque_vouchers"
                                        dark
                                        dense
                                        :items-per-page="5"
                                        fixed-header
                                        disable-sort
                                    >
                                        <template v-slot:[`item.beginning_amount`]="{ item }">
                                            <span class="text-nowrap">
                                                &#x20b1;{{ item.beginning_amount | currency }}
                                            </span>
                                        </template>
                                        <template v-slot:[`item.cheque_date`]="{ item }">
                                            <span class="text-nowrap">
                                                {{ formatDate(item.cheque_date) }}
                                            </span>
                                        </template>
                                        <template v-slot:[`item.printed`]="{ item }" >
                                            <v-badge dot :color="item.printed == 0 ? '#ef5350' : 'success'"></v-badge>
                                        </template>
                                    </v-data-table>
                                </td>
                            </template>
                            <template v-slot:[`item.status`]="{ item }">
                                <v-avatar  size="15" :color="item.status == 1?'red':'green'"></v-avatar>
                            </template>
                            <template v-slot:[`item.actions`]="{ item,index }">
                                <v-btn text icon color="orange" @click="getBookletList(dialogAction == 'add'?index : item.id)">
                                    <v-icon class="btn-action" small>mdi-eye</v-icon>
                                </v-btn>
                                <v-btn text icon color="red" :disabled="item.status || dialogAction == 'add'?true:false" @click="cancelBookletParent(item.id)">
                                    <v-icon class="btn-action" small>mdi-cancel</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                        <v-dialog v-model="bookletListDialog" width="30%" persistent>
                            <v-card>
                                <v-row class="m-0">
                                    <v-card-title>
                                        <span class="headline">Booklet List</span>
                                    </v-card-title>
                                    <v-col cols="pull-right-10 p-2">
                                        <v-btn text icon color="gray" class="float-right" @click="closeBookletListDialog()">
                                            <v-icon>mdi-close-circle</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-card-text>
                                    <v-simple-table
                                        fixed-header
                                        height="300px"
                                    >
                                        <template v-slot:default>
                                        <thead>
                                            <tr>
                                            <th class="text-left">
                                                Booklet #
                                            </th>
                                            <th class="text-center">
                                                Cancelled
                                            </th>
                                            <th class="text-left">
                                                Action
                                            </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                            v-for="(item,i) in booklet_items"
                                            :key="i"
                                            >
                                            <td >{{ item.booklet_cheque_num }}</td>
                                            <td class="text-center"><v-avatar  size="20" :color="item.status == 1?'red':'green'"></v-avatar></td>
                                            <td>
                                                <v-btn text icon color="red" :disabled="item.status || dialogAction == 'add'?true:false" @click="cancelBooklet(item.id)">
                                                     <v-icon class="btn-action" small>mdi-cancel</v-icon>
                                                </v-btn>
                                            </td>
                                            </tr>
                                        </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </div>
                </div>

                <v-divider class="mt-0" />

                </div>

                <div style="text-align:center" class="pb-4">
                    <v-btn v-if="dialogAction == 'edit'" :loading="updateOnFlight" @click="update()">Update</v-btn>
                    <v-btn v-else :loading="submitOnFlight" @click="submit()">Submit</v-btn>
                </div>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="newBookletDialog"
            max-width="32rem"
            persistent
        >
            <v-card>
                <v-card-title>
                    {{ booklet_action === 'create' ? 'Add New Booklet' : '' }}
                    <!-- {{ booklet_action === 'update' ? 'Update Booklet' : '' }} -->
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        icon
                        small
                        color="gray"
                        @click="closeBookletDialog"
                    ><v-icon>mdi-close-circle</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                    <div class="v-flex flex-column" style="gap: .5rem;">
                        <div>
                            <div>New Booklet Number: <span style="color:red">*</span></div>
                            <div>
                                <v-text-field
                                    v-model="booklet_number"
                                    dense outlined
                                />
                            </div>
                        </div>
                        <div>
                            <div>New Booklet Start Number: <span style="color:red">*</span></div>
                            <div>
                                <v-text-field
                                    v-model="booklet_cheque_number_start"
                                    dense outlined
                                    @change="BookletNumberEnd()"
                                />
                            </div>
                        </div>
                        <div>
                            <div>New Booklet End Number: <span style="color:red">*</span></div>
                            <div>
                                <v-text-field
                                    v-model="booklet_cheque_number_end"
                                    dense outlined
                                />
                            </div>
                        </div>
                        <div class="d-flex">
                            <v-btn
                                class="m-auto mt-4"
                                :disabled="validAddBookletForm"
                                @click="addNewBooklet()"
                            >Save</v-btn>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="addEditBankOBDialog" persistent max-width="40%">
            <v-card>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">{{ `${dialogAction == 'edit' ? 'Edit' : 'Add'} Bank: ` + name }}</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeAddEditBankDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <div style="overflow-y: auto; padding: 12px 24px;">

                    <div class="px-2">

                        <v-row class="mt-2">
                            <v-spacer />
                            <v-col class="d-flex justify-end px-4">
                                <v-text-field
                                    v-model="opening_balance"
                                    outlined
                                    dense
                                    hide-details
                                    type="number"
                                    hide-spin-buttons
                                    @keypress="numberAndDotOnly($event, item)"
                                >
                                    <template v-slot:label>
                                        <span style="cursor: pointer;">Opening Balance</span>
                                    </template>
                                </v-text-field>
                            </v-col>

                            <v-col class="d-flex justify-end px-4">
                                <v-menu
                                    v-model="menu.balance_date"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    max-width="290"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            v-model="balance_date"
                                            v-on="on"
                                            append-icon="mdi-calendar"
                                            label="Date"
                                            readonly
                                            outlined
                                            dense
                                            />
                                        </template>
                                        <v-date-picker v-model="balance_date" @input="menu.balance_date = false"/>
                                </v-menu>
                            </v-col>
                            <v-spacer/>
                        </v-row>

                    </div>
                </div>
                <v-divider/>

                <div style="text-align:center" class="pb-4">
                    <v-btn v-if="dialogAction == 'edit'" :loading="updateOnFlight" :disabled="!opening_balance || !balance_date" @click="update()">Update</v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import swal from 'sweetalert2';
import { mapGetters } from 'vuex';
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue'
export default {
    mixins: [SharedFunctionsComponentVue],
    props:['bankType'],
    data() {
        return {
            form:{
                owner_name: '',
                email: '',
                contact_number: '',
            },
            loadedBooklets: false,
            local: 1,
            code: '',
            name: '',
            description: '',
            address: '',
            coa_assigned: null,
            assigned_users: 0,
            booklet_action: '',
            booklet_id: null,
            booklet_number: '',
            booklet_cheque_number_start: '',
            booklet_cheque_number_end: '',
            // account_number: '',
            bankDetails: null,
            submitOnFlight: false,
            updateOnFlight: false,
            newBookletDialog: false,
            company_id:'',
            branch_id:'',
            userAccess:{
                create:false
            },
            payments:{
                selection:[],
                selected:[]
            },
            dataTable: {
                totalItems: 0,
                headers: [
                    { text:'Booklet #', value:'booklet_number', align:"center" , sortable: false},
                    { text:'Cheque Start #', value:'booklet_cheque_number_start', align:"center" , sortable: false},
                    { text:'Cheque End #', value:'booklet_cheque_number_end', align:"center" , sortable: false},
                    { text:'Last Used Cheque #', value:'booklet_cheque_number_last_used', align:"center" , sortable: false},
                    { text:'Total Issued Amount', value:'total_issued_amount', align:"center" , sortable: false},
                    { text:'Cancelled', align:"center" ,value:'status', align:"center" , sortable: false},
                    { text:'Cancelled Booklet',value:'actions', align:"center" , sortable: false},
                    // { text:'Actions', value:'actions', sortable: false, align: 'center'},
                ],
                bookletNumberHeaders: [
                    { text:'Cheque #', value:'cheque_number' , align: 'left', sortable: false},
                    { text:'Cheque Voucher #', value:'cheque_voucher_number', align: 'center', sortable: false},
                    { text:'Created By', value:'created_by', align: 'center', sortable: false},
                    { text:'Payee', value:'payee', align: 'center', sortable: false},
                    { text:'Cheque Date', value:'cheque_date', align: 'center', sortable: false},
                    { text:'Released', value:'printed', align: 'center', sortable: false},
                    { text:'Amount', value:'beginning_amount', align: 'right', sortable: false},
                ],
                items: [],
                itemsPerPageOptions: [5, 10, 15, 50, 100],
                options: {
                    page: 1,
                    itemsPerPage: 5,
                },
            },
            booklet_items:[],
            bookletListDialog:false,
            bank_id:'',
            booklet_item_id:'',
            balance_date: this.$dayjs().format('YYYY-MM-DD'),
            opening_balance: 0,
            closed_account: 0,
            assigned_users: [],
            type: [],
            total_issued_booklet_amount: 0,
            menu: {
                balance_date: false
            },
            rules: {
                default: [
                v => !!v || 'This is required',
                ],
            },
        }
    },
    watch: {
        'dataTable.options':{
            handler(val){
                this.getBooklets(this.bank_id);
            }
        },
        bankId() {
            if(!!this.bankId) {
                this.getBankDetails();
            }
        },
        bankDetails() {
            if(!!this.bankDetails) {
                const bank = this.bankDetails;
                this.local = bank.local;
                this.code = bank.code;
                this.name = bank.name;
                this.description = bank.description;
                this.address = bank.address;
                this.payments.selected = bank.bank_payment_types.map(e =>{ return e.payment_type_id})
                this.coa_assigned = bank.account_id;
                this.company_id = bank.company_id;
                this.branch_id = bank.store_branches_id;
                this.opening_balance = parseFloat(bank.opening_balance).toFixed(2);
                this.balance_date = bank.balance_date;
                this.closed_account = bank.closed_account;
                bank.bank_user_accesses.forEach(e=>{
                    this.assigned_users.push(e.user_id)
                })
                this.form.owner_name = bank.owner_name;
                this.form.contact_number = bank.contact_number;
                this.form.email = bank.email;
                this.type = []
                bank.bank_types.forEach(e=>{
                    this.type.push(e.type)
                })
                // this.account_number = bank.account_number;
            }
        },
        USER_ACCESS:{
            handler(val){
                this.userAccess.create = false;
                if (val != "fail") {
                    val.forEach((e) => {
                        if (e.actions_code == "create") {
                            this.userAccess.create = true;
                        }
                    });
                }
            }
        },
    },
    computed: {
        validAddBookletForm() {
            return (!this.booklet_number || !this.booklet_cheque_number_start || !this.booklet_cheque_number_end);
        },
        addEditBankDialog() {
            return this.$store.state.accounting.bank.addEditBankDialog;
        },
        addEditBankOBDialog() {
            return this.$store.state.accounting.bank.addEditBankOBDialog;
        },
        dialogAction() {
            return this.$store.state.accounting.bank.dialogAction;
        },
        bankId() {
            return this.$store.state.accounting.bank.bankId;
        },
        getChildrenChartofAccounts(){
            this.updateCoas(this.GET_CHART_OF_ACCOUNTS_SELECTION)
            return this.children_coas
        },
        ...mapGetters([
            'USER_ACCESS',
            'USERACCOUNT_LOAD',
            'GET_PAYMENT_TYPES',
            'GET_CHART_OF_ACCOUNTS_SELECTION',
            'GET_COMPANIES_SELECTION',
            'GET_PV_STORE_BRANCHES_SELECTION',
            'GET_ALL_USERS_SELECT',
            'GET_BANK_TYPES_SELECTION',
            'GET_BANK_ORIGIN_SELECTION'
        ]),
        byPassFilter(){
            if (
                this.USERACCOUNT_LOAD.president ||
                this.USERACCOUNT_LOAD.super_admin ||
                this.USERACCOUNT_LOAD.manager
            ) {
                return true
            } else {
                return false
            }
        }
    },
    methods: {
        formatDate(date){
            return this.$dayjs(date).format('YYYY-MM-DD')
        },
        clearFields() {
            this.form = {
                owner_name: '',
                email: '',
                contact_number: '',
            };
            this.local = 1;
            this.code = "";
            this.name = "";
            this.description = "";
            this.address = "";
            this.payments.selected = [];
            this.coa_assigned = null;
            this.company_id = ""
            this.branch_id = ""
            this.assigned_users = []
            this.closed_account = 0
            this.type = []
            this.total_issued_booklet_amount = 0
            // this.account_number = "";
        },
        showAddEditBankDialog() {
            if (this.bankType == 'AR' || this.bankType == 'AP' && (this.dialogAction == 'add')) {
                this.type = [this.bankType]
            }
            this.$store.commit('bankShowAddEditDialog');
        },
        closeAddEditBankDialog() {
            this.$store.commit('bankCloseAddEditDialog');
            this.$store.commit('bankOBCloseAddEditDialog');
            this.$store.commit('bankClearAction');
            this.$store.commit('bankClearId');
            this.clearFields();
            this.dataTable.items = []
        },
        getBankDetails() {
            let payload = {
                url: `getBankDetails/${this.bankId}`
            }
            this.$store.dispatch('banksGet',payload).then(response=>{
                this.bankDetails = response.data;
                this.getBooklets(this.bankId);
            });
        },
        submit() {
            this.submitOnFlight = true;
            if (!this.requiredFields()) {
                swal.fire({
                    title: 'Are you sure you want to Add Bank?',
                    text: 'Make sure that all information are correct',
                    icon: 'question',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    allowOutsideClick: false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor: 'grey',
                    confirmButtonText: 'Confirm',
                    showCloseButton: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        let payload = {
                            local: this.local,
                            code: this.code,
                            name: this.name,
                            description: this.description,
                            address: this.address,
                            url: 'createBank',
                            payments: this.payments.selected,
                            account: this.coa_assigned,
                            company_id:this.company_id,
                            branch_id:this.branch_id,
                            form: this.form,
                            // type: this.type.length > 0 ? this.type : [this.bankType],
                            type: this.type,
                            bookLets:this.dataTable.items,
                            assigned_users:this.assigned_users
                            // account_number: this.account_number
                        };
                        this.$store.dispatch('banksPost', payload).then(response => {
                            if (response.data.error) {
                                swal.fire('', response.data.error, 'error');
                                this.submitOnFlight = false;
                            } else {
                                this.closeAddEditBankDialog();
                                this.clearFields();
                                this.$store.commit('bankSuccessAddEditTrue');
                                this.submitOnFlight = false;
                                swal.fire("", response.data.msg, "success").then(ok => {
                                    if (ok.isConfirmed) {
                                        this.$store.commit('bankSuccessAddEditFalse');
                                    }
                                });
                            }
                        });
                    } else if (result.dismiss === swal.DismissReason.cancel) {
                        this.submitOnFlight = false;
                    }
                });
            }
        },
        update() {
            this.updateOnFlight = true;

            if (!this.requiredFields()) {
                swal.fire({
                    title: 'Are you sure you want to Update Bank?',
                    text: 'Make sure that all information are correct',
                    icon: 'question',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    allowOutsideClick: false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor: 'grey',
                    confirmButtonText: 'Confirm',
                    showCloseButton: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        let payload = {
                            bankId: this.bankId,
                            local: this.local,
                            code: this.code,
                            name: this.name,
                            description: this.description,
                            address: this.address,
                            url: 'updateBank',
                            payments: this.payments.selected,
                            account: this.coa_assigned,
                            company_id:this.company_id,
                            branch_id:this.branch_id,
                            form: this.form,
                            // type: this.type.length > 0 ? this.type : [this.bankType],
                            type: this.type,
                            opening_balance:this.opening_balance,
                            balance_date:this.balance_date,
                            closed_account:this.closed_account,
                            assigned_users:this.assigned_users
                            // account_number: this.account_number
                        };
                        this.$store.dispatch('banksPost', payload).then(response => {
                            if (response.data.status == 'error') {
                                let list = response.data.uncleared_cheque_voucher_numbers.join('<br>');

                                swal.fire({
                                    title: response.data.message,
                                    html: list,
                                    icon: 'error',
                                    customClass: 'swal-max-height'
                                });

                                this.updateOnFlight = false;
                            } else {
                                this.closeAddEditBankDialog();
                                this.clearFields();
                                this.$store.commit('bankSuccessAddEditTrue');
                                this.updateOnFlight = false;
                                swal.fire("", response.data.msg, "success").then(ok => {
                                    if (ok.isConfirmed) {
                                        this.$store.commit('bankSuccessAddEditFalse');
                                    }
                                });
                            }
                        });
                    } else if (result.dismiss === swal.DismissReason.cancel) {
                        this.updateOnFlight = false;
                    }
                });
            } 
        },
        updateCoas(parentCOA) {
            const filteredChildrenCOAS = this.GET_CHART_OF_ACCOUNTS_SELECTION.filter(e => e.parent_account_id !== 0)
            this.children_coas = filteredChildrenCOAS
        },
        openBookletDialog() {
          this.newBookletDialog = true;
          this.booklet_action = 'create';
        },
        closeBookletDialog() {
            this.newBookletDialog = false;
        },
        addNewBooklet() {
            if(this.dialogAction == 'add'){
               let self = this;
                this.dataTable.items.push({
                    bank_id:0,
                    booklet_cheque_number_end:this.booklet_cheque_number_end,
                    booklet_cheque_number_last_used:0,
                    booklet_cheque_number_start:this.booklet_cheque_number_start,
                    booklet_number: this.booklet_number,
                    cancelled_by:0,
                    booklet_items:[],
                })
                for(let x =self.booklet_cheque_number_start; x <= self.booklet_cheque_number_end;x++){
                    this.dataTable.items[this.dataTable.items.length - 1].booklet_items.push({
                                    booklet_cheque_num:x.toString().padStart(this.booklet_cheque_number_start.length,"0"),
                                    status:false,
                                });
                            }

                this.dataTable.totalItems = this.dataTable.items.length
                this.newBookletDialog = false;
                return false;
            }
            let payload = {
                bank_id: this.bankId,
                booklet_number: this.booklet_number,
                booklet_cheque_number_start: this.booklet_cheque_number_start,
                booklet_cheque_number_end: this.booklet_cheque_number_end,
                company_id:this.company_id,
                branch_id:this.branch_id,
            }
            this.$store.dispatch('addBooklet', payload).then(response => {
                this.resetNewBookletForm();
                this.newBookletDialog = false;
                swal.fire({
                    icon: "success",
                    title: "Success!",
                    text: "New booklet is successfully added.",
                });
                this.getBooklets(this.bankId);
            }).catch(error => {
            if (error.response.status === 409) {
                swal.fire({
                    icon: "error",
                    title: "Conflict",
                    text: "Booklet number already exists.",
                });
            } else {
                swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong when adding a new booklet.",
                });
            }
        });
        },
        resetNewBookletForm() {
            this.booklet_number = '';
            this.booklet_cheque_number_start = '';
            this.booklet_cheque_number_end = '';
        },
        getBooklets(bank_id) {
            this.loadedBooklets = false;
            this.dataTable.items = [];
            this.bank_id = bank_id
            let payload = {
                page: this.dataTable.options.page,
                items_per_page: this.dataTable.options.itemsPerPage,
                bank_id: bank_id,
            };
            this.$store.dispatch('getBooklets', payload).then(response => {
                this.loadedBooklets = true,
                this.dataTable.items = response.data.data.data;
                this.dataTable.totalItems = response.data.data.total;
                this.total_issued_booklet_amount = response.data.total_issued_booklet_amount
            });
        },
        getBooklet(id, action) {
            this.newBookletDialog = true;
            this.resetNewBookletForm();
            this.booklet_action = action;
            this.$store.dispatch('getBooklet', id).then(response => {
                this.booklet_id = response.data.booklet_id;
                this.booklet_number = response.data.booklet_number;
                this.booklet_cheque_number_start = response.data.booklet_cheque_number_start;
                this.booklet_cheque_number_end = response.data.booklet_cheque_number_end;
            });
        },
        getStoreBranches(){
            // console.log(this.company_id)
            this.$store.dispatch('getPaymentVoucherBranchesSelection',{company_id:this.company_id});
        },
        BookletNumberEnd(){
            if(this.booklet_cheque_number_start == '' || this.booklet_cheque_number_start == 0){
                this.booklet_cheque_number_end = ''
                return false
            }
            let _plus = parseInt(this.booklet_cheque_number_start) + 100
            let padStart = _plus.toString().padStart(this.booklet_cheque_number_start.length,0);

            this.booklet_cheque_number_end = padStart;
        },
        getBookletList(item){
            if(this.dialogAction == 'add'){
                this.bookletListDialog = true


                        this.booklet_items = this.dataTable.items[item].booklet_items



                return false;
            }
            this.booklet_item_id = item
            this.bookletListDialog = true
            this.booklet_items = [];
            this.$store.dispatch('getBookletList',item).then(response=>{
                this.booklet_items = response.data
            });
        },
        closeBookletListDialog(){
            this.bookletListDialog = false
            this.booklet_items = [];

        },
        cancelBooklet(id){
            this.$store.dispatch('SwalAlertQuestion',{title:`Are you sure you want to cancel this booklet number?`,icon:'question'}).then((res)=>{
                if(res.isConfirmed){
                    this.$store.dispatch('cancelBooklet',id).then(response=>{
                            this.getBookletList(this.booklet_item_id)
                    });
                }
            })

        },
        cancelBookletParent(id){
            this.$store.dispatch('SwalAlertQuestion',{title:`Are you sure you want to cancel this booklet?`,icon:'question'}).then((res)=>{
                if(res.isConfirmed){
                    this.$store.dispatch('cancelBookletParent',id).then(response=>{
                        this.getBooklets(this.bank_id);
                    });
                }
            })

        },
        numberAndDotOnly($event, item) {
            const allowedCharacters = /^[0-9.]*$/;
            const inputChar = $event.key;
            if (!allowedCharacters.test(inputChar)) {
                $event.preventDefault();
                return
            }
            if (inputChar === '.' && item.opening_balance.includes('.')) {
                $event.preventDefault();
            }
        },
        requiredFields() {
            let warning = '';
            if (this.byPassFilter) {
                if (!this.assigned_users) warning += 'Please select assign user/s.<br>';
            }
            if (this.local.length == 0) warning += 'Please select Origin.<br>';
            if (!this.type) warning += 'Please select a Type.<br>';
            if (!this.name) warning += 'Please ensure that the empty field for the name is filled.<br>';
            if (!this.code) warning += 'Please ensure that the empty field for the code is filled.<br>';
            
            if (warning !== '') return swal.fire({
                title: 'Please Fill out Information:',
                html: warning,
                icon: "warning",
            }).then(confirm=>{
                this.updateOnFlight = false;
                this.submitOnFlight = false;
            });
        },
    }
}
</script>

<style>
.swal-max-height {
    height: 70vh;
    overflow: auto;
}
</style>
