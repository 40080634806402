<template>
    <v-container fluid>
        <ComponentHeader
            class="ma-n4"
            :button="true"
            title='Weekly Expected Chqs'
            type='Accounting'
        ></ComponentHeader>
        <v-card>
            <v-card-text class="bg-bluish-gray">
                <v-row no-gutters class="mx-auto">
                    <v-col cols="10">
                        <v-row no-gutters>
                            <v-col cols="2">
                                <v-select
                                    v-model="constants.date_filter"
                                    :items="dropdowns.date_filter"
                                    label="Date Criteria"
                                    placeholder="Date Criteria"
                                    background-color="white"
                                    dense
                                    outlined
                                    hide-details
                                    @change="setDefaultValues()"
                                ></v-select>
                            </v-col>
                            <v-col cols="2" v-if="constants.date_filter == 'base_date'">
                                <v-menu
                                    v-model="menus.base_date"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            v-model="filters.base_date"
                                            v-on="on"
                                            style="display: block"
                                            background-color="white"
                                            prepend-inner-icon="mdi-calendar"
                                            label="Report Date"
                                            dense
                                            outlined
                                            hide-details
                                            readonly
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filters.base_date" @change="menus.base_date = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="2" v-if="constants.date_filter == 'date_range'">
                                <v-menu
                                    v-model="menus.date_from"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            v-model="filters.date_from"
                                            v-on="on"
                                            style="display: block"
                                            background-color="white"
                                            prepend-inner-icon="mdi-calendar"
                                            label="Date From"
                                            dense
                                            outlined
                                            hide-details
                                            readonly
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filters.date_from" @change="menus.date_from = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="2" v-if="constants.date_filter == 'date_range'">
                                <v-menu
                                    v-model="menus.date_to"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            v-model="filters.date_to"
                                            v-on="on"
                                            style="display: block"
                                            background-color="white"
                                            prepend-inner-icon="mdi-calendar"
                                            label="Date To"
                                            dense
                                            outlined
                                            hide-details
                                            readonly
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filters.date_to" @change="menus.date_to = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="2" class="d-flex justify-end" style="gap: 4px">
                        <v-btn @click="list('pdf')">Print</v-btn>
                        <v-btn @click="list('excel')">Excel</v-btn>
                        <v-btn @click="list()">Filter</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card>
            <v-card-text>
                <ReportsCardsComponent :cards="cards" :totals="totals"></ReportsCardsComponent>
                <v-data-table
                    :headers="dataTable.headers"
                    :items="dataTable.items"
                    :loading="dataTable.loading"
                    :expanded.sync="dataTable.expanded"
                    item-key="doc_date"
                    disable-sort
                    disable-pagination
                    hide-default-footer
                    show-expand
                    single-expand
                >
                    <template #item.total_amount="{ item }">
                        {{ item.total_amount | currency }}
                    </template>
                    <template #expanded-item="{ item, headers }">
                        <td :colspan="headers.length">
                            <v-data-table
                                :headers="dataTable.sub_headers"
                                :items="item.items"
                                disable-sort
                                hide-default-footer
                            >
                                <template #item.total_amount="{ item }">
                                    {{ item.total_amount | currency }}
                                </template>
                            </v-data-table>
                        </td>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue'
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
import ReportsCardsComponent from '@/views/main/layouts/Reports/ReportsCardsComponent.vue'

export default {
    mixins: [
        SharedFunctionsComponent,
    ],
    components: {
        ComponentHeader,
        ReportsCardsComponent,
    },
    data() {
        return {
            filters: {
                base_date: null,
                date_from: null,
                date_to: null,
            },
            dataTable: {
                headers: [
                    { text: 'DATE', value: 'doc_date' },
                    { text: 'TOTAL AMOUNT', value: 'total_amount' , align: 'end' },
                ],
                sub_headers: [
                    { text: 'CLIENT / CUSTOMER', value: 'company_name' },
                    { text: 'AMOUNT', value: 'total_amount', align: 'end' },
                    { text: 'BANK', value: 'bank_name', align: 'center' },
                    { text: 'CHECK NUMBER', value: 'doc_number', align: 'center' },
                    { text: 'CHECK DATE', value: 'doc_date', align: 'center' },
                    // { text: 'PAYMENT DATE', value: 'payment_date', align: 'center' },
                ],
                items: [],
                total: 0,
                loading: false,
            },
            exportData: {
                filename: 'Weekly Expected Chqs',
                file_ext: 'xlsx',
                columns: [
                    { header: 'PAYMENT DATE', key: 'payment_date' },
                    { header: 'CUSTOMER', key: 'company_name' },
                    { header: 'AMOUNT', key: 'total_amount', style: { numFmt: '#,##0.00' } },
                    { header: 'BANK', key: 'bank_name' },
                    { header: 'CHECK NUMBER', key: 'doc_number', style: { numFmt: '@' } },
                    { header: 'CHECK DATE', key: 'doc_date' },
                ],
                items: [],
                expanded: [],
                loading: false,
            },
            dropdowns: {
                date_filter: [
                    { text: 'Date Range', value: 'date_range' },
                    { text: 'Single Day', value: 'base_date' },
                ],
            },
            menus: {
                base_date: false,
                date_from: false,
                date_to: false,
            },
            cards: [
                { title: 'Total Amount', icon: 'mdi-cash', icon_class: 'total-amount', sm: 12, md: 6, lg: 2, xl: 2, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'total_amount' },
            ],
            totals: {
                total_amount: 0,
            },
            constants: {
                date_filter: 'date_range',
            },
        }
    },
    async mounted() {
        await this.setDefaultValues()
    },
    computed: {
        filename() {
            switch (this.constants.date_filter) {
                case 'base_date':
                    return this.filters.base_date
                case 'date_range':
                    return this.filters.date_from + ' to ' + this.filters.date_to
            }
        },
        companyImagePath() {
            return `${window.location.origin}/images/ingcoph-logo.png`
        },
    },
    methods: {
        async setDefaultValues() {
            switch (this.constants.date_filter) {
                case 'base_date':
                    this.filters.base_date = this.$dayjs().format('YYYY-MM-DD')
                    this.filters.date_from = null
                    this.filters.date_to = null
                    break
                case 'date_range':
                    this.filters.base_date = null
                    this.filters.date_from = this.$dayjs().startOf('month').format('YYYY-MM-DD')
                    this.filters.date_to = this.$dayjs().endOf('month').format('YYYY-MM-DD')
                    break
            }
        },
        async list(type = 'list') {
            this.dataTable.items = []
            this.dataTable.loading = true

            await this.$store.dispatch('reports', {
                base_date: this.filters.base_date,
                date_from: this.filters.date_from,
                date_to: this.filters.date_to,
                url: 'weekly-expected-chqs',
            }).then(async response => {
                let data = response.data
                let bodyData = Object.groupBy(data.data, ({ doc_date }) => {
                    if (this.constants.date_filter == 'base_date') {
                        return doc_date <= data.following_week[0] ? data.following_week[0] : doc_date;
                    } else if (this.constants.date_filter == 'date_range') {
                        return doc_date
                    }
                })
                bodyData = Object.keys(bodyData).sort().map(m => {
                    return Object.assign({}, {
                        doc_date: this.$dayjs(m).format('M/D/YYYY'),
                        items: bodyData[m],
                        total_amount: bodyData[m].reduce((a, v) => parseFloat(v.total_amount) + a, 0),
                    })
                })

                this.totals.total_amount = 0

                let i = 0
                bodyData.forEach((element) => {
                    for (let e of element.items) {
                        e.lineno = i + 1
                        e.total_amount = parseFloat(e.total_amount)
                        e.docu_date = this.$dayjs(e.doc_date).format('M/D/YYYY')
                        e.doc_date = this.$dayjs(e.doc_date).format('M/D/YYYY')
                        e.payment_date = this.$dayjs(e.payment_date).format('M/D/YYYY')

                        this.totals.total_amount += e.total_amount

                        i++
                    }
                })

                if (type == 'list') {
                    this.dataTable.items = bodyData
                } else if (type == 'excel') {
                    const loadImage = async (url) => {
                        const response = await fetch(url);
                        const blob = await response.blob();
                        return blob;
                    };

                    const workbook = new this.$exceljs.Workbook();
                    const worksheet = workbook.addWorksheet('Data');

                    worksheet.properties.defaultRowHeight = 15;
                    worksheet.columns = [
                        { header: 'NO.', key: 'lineno', width: 10 },
                        { header: 'CLIENT / CUSTOMER', key: 'company_name', width: 60 },
                        { header: 'AMOUNT', key: 'total_amount', width: 40 },
                        { header: 'BANK', key: 'bank_name', width: 40 },
                        { header: 'CHECK NUMBER', key: 'doc_number', width: 20 },
                        { header: 'CHECK DATE', key: 'doc_date', width: 20 }
                    ];

                    const imageBlob = loadImage(this.companyImagePath);
                    const imageId = workbook.addImage({
                        buffer: imageBlob,
                        extension: 'png',
                    });

                    worksheet.addImage(imageId, {
                        tl: { col: 0, row: 0 },
                        ext: { width: 200, height: 70 }
                    });
                    worksheet.getRow(1).height = 55;

                    let grandTotalData1 = 0
                    let grandTotalData2 = 0

                    bodyData.forEach((element, index) => {
                        worksheet.getCell('A1').value = ''
                        worksheet.getCell('B1').value = ''
                        worksheet.getCell('C1').value = ''
                        worksheet.getCell('D1').value = ''
                        worksheet.getCell('E1').value = ''
                        worksheet.getCell('F1').value = ''

                        let tableData = element.items;
                        let tableHeading =
                            index == 0 && this.constants.date_filter == 'base_date' ?
                            this.$dayjs(element.doc_date).format('MMMM') + ' ' + this.$dayjs(data.current_week[1]).format('D') + '-' + this.$dayjs(data.following_week[0]).format('D') + ', ' + this.$dayjs(element.doc_date).format('YYYY') :
                            this.$dayjs(element.doc_date).format('MMMM D, YYYY');

                        let headingRow = worksheet.addRow([tableHeading]);
                        headingRow.font = { bold: true };
                        for (let c=1;c<= 2;c++) {
                            headingRow.getCell(c).fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'f69f1a' }
                            };
                            headingRow.getCell(c).alignment = { vertical: 'middle', horizontal: 'center' };
                        }
                        worksheet.mergeCells(`A${headingRow.number}:B${headingRow.number}`);

                        worksheet.addRow([
                            'NO.',
                            'CLIENT / CUSTOMER',
                            'AMOUNT',
                            'BANK',
                            'CHECK NUMBER',
                            'CHECK DATE'
                        ]);

                        worksheet.getRow(worksheet.lastRow.number).eachCell(cell => {
                            cell.font = { bold: true };
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFF00' }
                            };
                            cell.alignment = { horizontal: 'center' };
                            cell.border = {
                                top: {style:'thin'},
                                left: {style:'thin'},
                                bottom: {style:'thin'},
                                right: {style:'thin'}
                            }
                        });

                        tableData.forEach(rowData => {
                            rowData.total_amount = parseFloat(rowData.total_amount)
                            let row = worksheet.addRow(rowData);
                            row.getCell('total_amount').font = { color: { argb: 'FF0000' } };
                            row.getCell('total_amount').numFmt = '#,##0.00';
                            row.alignment = { vertical: 'middle', horizontal: 'center' };
                            for (let c=1;c<=worksheet.columns.length;c++) {
                                row.getCell(c).border = {
                                    top: {style:'thin'},
                                    left: {style:'thin'},
                                    bottom: {style:'thin'},
                                    right: {style:'thin'}
                                }
                                if(c == 2) {
                                    row.getCell(2).alignment = { vertical: 'middle', horizontal: 'left' };
                                }
                            }
                        });

                        let tableTotal = tableData.reduce((a, v) => { return a + parseFloat(v.total_amount) }, 0);
                        let totalRow = worksheet.addRow(['', '', tableTotal]);
                        totalRow.font = { bold: true };
                        totalRow.getCell('total_amount').fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'f69f1a' }
                        };
                        totalRow.getCell('total_amount').numFmt = '#,##0.00';
                        totalRow.alignment = { vertical: 'middle', horizontal: 'center' };
                        worksheet.addRow([])

                        switch (this.constants.date_filter) {
                            case 'base_date':
                                if (this.$dayjs(element.doc_date).isSameOrBefore(this.$dayjs(data.following_week[0]).add(2, 'day').format('YYYY-MM-DD'))) {
                                    grandTotalData1 += tableTotal
                                }

                                if (this.$dayjs(element.doc_date).isSameOrBefore(this.$dayjs(data.following_week[1]).format('YYYY-MM-DD'))) {
                                    grandTotalData2 += tableTotal
                                }

                                break
                            case 'date_range':
                                grandTotalData1 += tableTotal
                                break
                        }
                    });

                    let grandTotalHeading1 = ''
                    let grandTotalHeading2 = ''

                    switch (this.constants.date_filter) {
                        case 'base_date':
                            grandTotalHeading1 = 'TOTAL: ' + this.$dayjs(data.current_week[1]).format('MMMM').toUpperCase() + ' ' + this.$dayjs(data.current_week[1]).format('D') + '-' + this.$dayjs(data.following_week[0]).add(2, 'day').format('D') + ', ' + this.$dayjs(data.following_week[0]).add(2, 'day').format('YYYY');
                            grandTotalHeading2 = 'TOTAL: ' + this.$dayjs(data.current_week[1]).format('MMMM').toUpperCase() + ' ' + this.$dayjs(data.current_week[1]).format('D') + '-' + this.$dayjs(data.following_week[1]).format('D') + ', ' + this.$dayjs(data.following_week[1]).format('YYYY');
                            break
                        case 'date_range':
                            grandTotalHeading1 = 'TOTAL: ' + this.$dayjs(this.filters.date_from).format('MMMM').toUpperCase() + ' ' + this.$dayjs(this.filters.date_from).format('D') + '-' + this.$dayjs(this.filters.date_to).format('D') + ', ' + this.$dayjs(this.filters.date_to).format('YYYY');
                            break
                    }

                    let first = worksheet.addRow([ '', grandTotalHeading1, grandTotalData1]);
                    const first_number = first['_number'];
                    const cellValue1 = worksheet.getRow(first_number).getCell('B');
                    const total1 = worksheet.getRow(first_number).getCell('C');
                    cellValue1.font = { bold: true };
                    total1.font = { bold: true, color: { argb: 'FF0000' } };
                    total1.numFmt = '#,##0.00';
                    total1.alignment = { vertical: 'middle', horizontal: 'center' }

                    if (this.constants.date_filter == 'base_date') {
                        let second = worksheet.addRow([ '', grandTotalHeading2, grandTotalData2]);
                        const second_number = second['_number'];
                        const cellValue2 = worksheet.getRow(second_number).getCell('B');
                        const total2 = worksheet.getRow(second_number).getCell('C');
                        cellValue2.font = { bold: true };
                        total2.font = { bold: true, color: { argb: 'FF0000' } };
                        total2.numFmt = '#,##0.00';
                        total2.alignment = { vertical: 'middle', horizontal: 'center' }
                    }

                    worksheet.pageSetup = {
                        margins: {
                            left: 0.3,
                            right: 0.3,
                            top: 0.3,
                            bottom: 0.3,
                            header: 0.3,
                            footer: 0.3
                        },
                        orientation: 'portrait',
                        fitToPage: true,
                        fitToHeight: 0,
                        fitToWidth: 1
                    };

                    workbook.xlsx.writeBuffer().then((data) => {
                        const blob = new Blob([data], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
                        });
                        this.$filesaver.saveAs(blob, `${this.exportData.filename} ${this.filename}.${this.exportData.file_ext}`);
                    });

                    this.list()
                } else if (type == 'pdf') {
                    this.renderPDF(bodyData, data)
                    this.list()
                }

                this.dataTable.loading = false
            }).catch(error => {
                console.log(error)
                this.dataTable.loading = false
            })
        },
        renderPDF(bodyData, data) {
            var doc = new this.$jspdf({
                unit: 'mm',
                format: 'letter',
            })

            const margin = {
                top: 18,
                bottom: 7,
                left: 7,
                right: 7
            }

            doc.addImage(this.companyImagePath, 'PNG', 7, 5, 30, 10)

            let grandTotalData1 = 0
            let grandTotalData2 = 0

            bodyData.forEach((element, index) => {
                let tableData = element.items

                tableData.forEach((item) => {
                    item.total_amount_formatted = this.getTwoDecimals(item.total_amount)
                })

                let tableHeading =
                    index == 0 && this.constants.date_filter == 'base_date' ?
                    this.$dayjs(element.doc_date).format('MMMM') + ' ' + this.$dayjs(data.current_week[1]).format('D') + '-' + this.$dayjs(data.following_week[0]).format('D') + ', ' + this.$dayjs(element.doc_date).format('YYYY') :
                    this.$dayjs(element.doc_date).format('MMMM D, YYYY');

                doc.autoTable({
                    theme: 'plain',
                    startY: index == 0 ? margin.top : doc.autoTable.previous.finalY + 7,
                    margin: { left: margin.left, right: margin.right },
                    styles: {
                        fontSize: 6,
                        fontStyle: 'bold',
                        cellPadding: { top: 1, bottom: 1 },
                    },
                    columnStyles: {
                        lineno: { halign: 'center', fillColor: [255, 183, 0], cellWidth: 87 },
                        total_amount: { halign: 'center', textColor: 'red', cellWidth: 19.9 },
                        bank_name: { halign: 'center', cellWidth: 49 },
                        doc_number: { halign: 'center', cellWidth: 29 },
                        doc_date: { halign: 'center', cellWidth: 17 },
                    },
                    columns: [
                        { dataKey: 'lineno', colSpan: 2 },
                        { dataKey: 'total_amount' },
                        { dataKey: 'bank_name' },
                        { dataKey: 'doc_number' },
                        { dataKey: 'doc_date' },
                    ],
                    body: [
                        { lineno: tableHeading },
                    ],
                })

                doc.autoTable({
                    theme: 'grid',
                    startY: doc.autoTable.previous.finalY,
                    margin: { top: margin.top, bottom: margin.bottom, left: margin.left, right: margin.right },
                    styles: {
                        lineWidth: { top: 0.2, right: 0.2, bottom: 0.2, left: 0.2 },
                        lineColor: 'black',
                        valign: 'middle',
                        fontSize: 6,
                    },
                    bodyStyles: {
                        cellPadding: 0.8,
                    },
                    headStyles: {
                        fontStyle: 'bold',
                        fillColor: 'yellow',
                        textColor: 'black',
                        halign: 'center',
                    },
                    columnStyles: {
                        lineno: { halign: 'center', cellWidth: 7 },
                        company_name: { cellWidth: 80 },
                        total_amount_formatted: { halign: 'center', textColor: 'red', cellWidth: 19.9 },
                        bank_name: { halign: 'center', cellWidth: 49 },
                        doc_number: { halign: 'center', cellWidth: 29 },
                        doc_date: { halign: 'center', cellWidth: 17 },
                    },
                    columns: [
                        { header: 'NO.', dataKey: 'lineno' },
                        { header: 'CLIENT / CUSTOMER', dataKey: 'company_name' },
                        { header: 'AMOUNT', dataKey: 'total_amount_formatted' },
                        { header: 'BANK', dataKey: 'bank_name' },
                        { header: 'CHECK NUMBER', dataKey: 'doc_number' },
                        { header: 'CHECK DATE', dataKey: 'doc_date' },
                    ],
                    body: tableData,
                })

                let tableTotal = tableData.reduce((a, v) => { return a + parseFloat(v.total_amount) }, 0);

                doc.autoTable({
                    theme: 'plain',
                    startY: doc.autoTable.previous.finalY,
                    margin: { left: margin.left, right: margin.right },
                    styles: {
                        fontSize: 6,
                        fontStyle: 'bold',
                        cellPadding: { top: 1, bottom: 1 },
                    },
                    columnStyles: {
                        lineno: { halign: 'center', cellWidth: 7 },
                        company_name: { halign: 'center', cellWidth: 80 },
                        total_amount: { halign: 'center', fillColor: [255, 183, 0], textColor: 'red', cellWidth: 19.9 },
                        bank_name: { halign: 'center', cellWidth: 49 },
                        doc_number: { halign: 'center', cellWidth: 29 },
                        doc_date: { halign: 'center', cellWidth: 17 },
                    },
                    columns: [
                        { dataKey: 'lineno' },
                        { dataKey: 'company_name' },
                        { dataKey: 'total_amount' },
                        { dataKey: 'bank_name' },
                        { dataKey: 'doc_number' },
                        { dataKey: 'doc_date' },
                    ],
                    body: [
                        { total_amount: this.getTwoDecimals(tableTotal) },
                    ],
                })

                switch (this.constants.date_filter) {
                    case 'base_date':
                        if (this.$dayjs(element.doc_date).isSameOrBefore(this.$dayjs(data.following_week[0]).add(2, 'day').format('YYYY-MM-DD'))) {
                            grandTotalData1 += tableTotal
                        }

                        if (this.$dayjs(element.doc_date).isSameOrBefore(this.$dayjs(data.following_week[1]).format('YYYY-MM-DD'))) {
                            grandTotalData2 += tableTotal
                        }

                        break
                    case 'date_range':
                        grandTotalData1 += tableTotal
                        break
                }
            })

            let grandTotalHeading1 = ''
            let grandTotalHeading2 = ''
            let totalsBody = []

            switch (this.constants.date_filter) {
                case 'base_date':
                    grandTotalHeading1 = 'TOTAL: ' + this.$dayjs(data.current_week[1]).format('MMMM').toUpperCase() + ' ' + this.$dayjs(data.current_week[1]).format('D') + '-' + this.$dayjs(data.following_week[0]).add(2, 'day').format('D') + ', ' + this.$dayjs(data.following_week[0]).add(2, 'day').format('YYYY');
                    grandTotalHeading2 = 'TOTAL: ' + this.$dayjs(data.current_week[1]).format('MMMM').toUpperCase() + ' ' + this.$dayjs(data.current_week[1]).format('D') + '-' + this.$dayjs(data.following_week[1]).format('D') + ', ' + this.$dayjs(data.following_week[1]).format('YYYY');
                    totalsBody = [
                        { company_name: grandTotalHeading1, total_amount: this.getTwoDecimals(grandTotalData1) },
                        { company_name: grandTotalHeading2, total_amount: this.getTwoDecimals(grandTotalData2) },
                    ]
                    break
                case 'date_range':
                    grandTotalHeading1 = 'TOTAL: ' + this.$dayjs(this.filters.date_from).format('MMMM').toUpperCase() + ' ' + this.$dayjs(this.filters.date_from).format('D') + '-' + this.$dayjs(this.filters.date_to).format('D') + ', ' + this.$dayjs(this.filters.date_to).format('YYYY');
                    totalsBody = [
                        { company_name: grandTotalHeading1, total_amount: this.getTwoDecimals(grandTotalData1) },
                    ]
                    break
            }


            doc.autoTable({
                theme: 'plain',
                startY: doc.autoTable.previous.finalY + 7,
                margin: { left: margin.left, right: margin.right },
                styles: {
                    fontSize: 6,
                    fontStyle: 'bold',
                    cellPadding: { top: 1, bottom: 1 },
                },
                columnStyles: {
                    lineno: { halign: 'center', cellWidth: 7 },
                    company_name: { cellWidth: 80 },
                    total_amount: { halign: 'center', textColor: 'red', cellWidth: 19.9 },
                    bank_name: { halign: 'center', cellWidth: 49 },
                    doc_number: { halign: 'center', cellWidth: 29 },
                    doc_date: { halign: 'center', cellWidth: 17 },
                },
                columns: [
                    { dataKey: 'lineno' },
                    { dataKey: 'company_name' },
                    { dataKey: 'total_amount' },
                    { dataKey: 'bank_name' },
                    { dataKey: 'doc_number' },
                    { dataKey: 'doc_date' },
                ],
                body: totalsBody,
            })

            window.open(doc.output('bloburl'), '_blank')
        },
    },
}
</script>
