<template>
    <span>
        <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }" >
                    <v-btn
                        style="opacity:1;"
                        v-bind="attrs"
                        v-on="on"
                        text
                        @click="showFilesViewerDialog()"
                    ><v-icon>mdi-file-document-multiple-outline</v-icon>
                    </v-btn>
            </template>
            <span>View Files</span>
        </v-tooltip>
        <v-dialog v-model="filesViewerDialog" persistent :width="height('50%')">
            <v-card>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">Files Viewer</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeFilesViewerDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider class="my-0" />

                <v-data-table
                    class="pa-4"
                    :headers="filesHeader"
                    :items="files"
                    :loading="loading"
                    :no-data-text="noDataText"
                >
                <template v-slot:item.action="{ item }">
                    <td class="px-0">
                        <span class="text-nonwrap">
                            <v-btn text icon color="orange">
                                <v-icon class="btn-action" small @click="showFile(item); newImagePath()">mdi-eye</v-icon>
                            </v-btn>
                            <v-btn text icon color="blue">
                                <v-icon class="btn-action" small @click="downloadFile(item)">mdi-download</v-icon>
                            </v-btn>
                            <v-btn v-if="withDelete" text icon color="red">
                                <v-icon class="btn-action" small @click="deleteFile(item)">mdi-delete</v-icon>
                            </v-btn>
                        </span>
                    </td>
                </template>
                <template v-slot:item.created_at="{ item }">
                    <td class="px-0">
                        {{ date(item) }}
                    </td>
                </template>
                </v-data-table>
            </v-card>
        </v-dialog>

        <v-dialog v-model="viewFile" persistent max-width="80%">
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                        <span class="headline">{{ itemName }}</span>
                        </v-card-title>
                    </v-col>

                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeViewFile()">
                        <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider class="my-0" />

                <div class="iframe-container" v-if="type == 'pdf'">
                    <iframe :src="sourceFrame" frameborder="0"  class="pdf-iframe"></iframe>
                </div>

                <v-img v-if="type == 'image' && !loading" :src="sourceFrame" width="300px" contain class="responsive-iframe" />
                <v-progress-linear
                    :active="loading"
                    :indeterminate="loading"
                    absolute
                    bottom
                    color="#f69f1a"
                ></v-progress-linear>
            </v-card>
        </v-dialog>
    </span>
</template>

<script>
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
export default {
    data() {
        return {
            filesHeader: [
                { text: 'Name', value: 'name' },
                { text: 'Uploaded By', value: 'uploaded_by_name' },
                { text: 'Date', value: 'created_at' },
                { text: 'Action', value: 'action' },
            ],
            files: [],
            employee_id:'',
            loading: true,
            noDataText: '',
            viewFile: false,
            itemPath: '',
            itemName: '',
            withDelete: false,
            folder_name:'',
            sourceFrame:'',
            type:'',
        }
    },
    props: ['dispatch_url'],
    watch: {
        files :{
            handler(val){
                if(val.length > 0) {
                    this.loading = false;
                } else {
                    this.loading = false,
                    this.noDataText = 'No Uploaded Files';
                }
            }
        },
        GET_EMPLOYEE_DATA_VIEW:{
            handler(val){
                this.employee_id = val.employee_id
            }
        },
    },
    computed: {
        ...mapGetters([
            'GET_CUSTOMER_PAYMENT_DETAIL_FILE',
            'GET_FILES',
            'GET_EMPLOYEE_DATA_VIEW',
        ]),
        filesViewerDialog() {
            return this.$store.state.accounting.files.filesViewerDialog;
        },
    },
    mounted(){
        this.employee_id = this.GET_EMPLOYEE_DATA_VIEW.id
    },
    methods: {
        clearFields() {
            this.loading = true;
            this.noDataText = '';
        },
        getFiles(){
            this.files = []
            let payload = {
                id : this.GET_EMPLOYEE_DATA_VIEW.id,
                url: this.dispatch_url
            }
            this.$store.dispatch('hrUrlPost',payload).then(response=>{
                this.files = response;
            });
        },
        showFilesViewerDialog() {
            this.$store.commit('filesShow');
            this.getFiles()
            this.$emit('openFilesViewer', true);
            this.folder_name = this.dispatch_url == 'getfiles' ? 'employee_data_uploads' : 'employee_data_temp_uploads'
        },
        closeFilesViewerDialog() {
            this.$store.commit('filesClose');
            this.clearFields();

            this.$emit('closeFilesViewer', true);
        },
        date(item) {
            if(!!item) {
                return this.$dayjs(item.created_at).format('YYYY-MM-DD, hh:mm:ss a');
            }
            return '';
        },
        showFile(file) {
            this.viewFile = true;
            this.itemPath = file.path;
            this.itemName = file.name
            this.$store.commit('filesClose');
        },
        downloadFile(file) {
            swal.fire({
                text: 'Are you sure you want to download?',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(async(result) => {
                if(result.isConfirmed) {
                    swal.fire('Downloading File Please wait.')
                    swal.showLoading()

                    let uri = ''
                    file.name = file.name.replaceAll(' ','%20')
                    file.name = file.name.replaceAll('#','%23')
                    uri = `${process.env.VUE_APP_API_HOST}/img/hr?image_name=${file.name}&folder_name=${this.folder_name}`

                    fetch(uri)
                    .then(resp => resp.blob())
                    .then(blobobject => {
                        const blob = window.URL.createObjectURL(blobobject);
                        const anchor = document.createElement('a');
                        anchor.style.display = 'none';
                        anchor.href = blob;
                        anchor.download = file.name;
                        document.body.appendChild(anchor);
                        anchor.click();
                        window.URL.revokeObjectURL(blob);
                        swal.close()
                    })
                    .catch(() => console.log('An error in downloadin the file'));
                        }
                    });
        },
        deleteFile(file) {
            if(!!this.custPaymentId) {
                swal.fire({
                    text: 'Are you sure you want to delete?',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then((result) => {
                    if(result.isConfirmed) {
                        let payload = {
                            fileId: file.id
                        }
                        this.$store.dispatch('deleteCustomerPaymentDetailFile',payload).then(response=>{
                            this.getCustomerPaymentDetailFiles();
                            swal.fire("", response.data.msg, "success");
                        }).catch(e => {
                            swal.fire("", e.data.error, "error");
                        })
                    }
                });
            }
        },
        closeViewFile() {
            this.itemPath = "";
            this.viewFile = false;
            this.employee_id = "";
            this.$store.commit('filesShow');
        },
        async newImagePath(path){
            let uri = ''
            this.itemName = this.itemName.replaceAll(' ','%20')
            this.itemName = this.itemName.replaceAll('#','%23')
            // if(!!this.rfId){
            //     uri = `${process.env.VUE_APP_API_HOST}/img/service?image_name=${this.itemName}`
            // }
            // else{
            //     uri = `${process.env.VUE_APP_API_HOST}/img/accounting?image_name=${this.itemName}&folder_name=${this.folder_name}`
            // }
            uri = `${process.env.VUE_APP_API_HOST}/img/hr?image_name=${this.itemName}&folder_name=${this.folder_name}`
            fetch(uri)
            .then(resp => resp.blob())
            .then(blobobject => {
                const blob = window.URL.createObjectURL(blobobject);
                if(blobobject.type == "application/pdf"){
                    this.type = 'pdf'
                }
                else{
                    this.type = 'image'
                }
                this.sourceFrame = blob;
            })
        },
        height(width) {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return "100%"
                case 'sm': return "100%"
                case 'md': return width
                case 'lg': return width
                case 'xl': return width
            }
        },
    }
}
</script>

<style scoped>
    .iframe-container {
        position: relative;
        width: 100%;
        padding-bottom: 100%; /* Change the aspect ratio as needed */
    }

    .pdf-iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }


    .responsive-iframe {
        max-width: 50%;
        margin: 20px auto;
    }

    .iframe img{
        margin: 0 auto !important;
    }
</style>
