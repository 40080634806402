<template>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
          <span class="text-h5">{{ dialog_title }}</span>
          <v-row class="m-0">
            <v-col cols="pull-right-10 p-2">
              <v-btn
                text
                icon
                small
                color="gray"
                class="float-right"
                @click="closeDialog()"
              >
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-container  style="overflow-x: hidden;overflow-y: scroll;">
          <v-row class="no-gutters">
            <v-col cols="12" sm="4">
              <v-card>

              <v-row class="no-gutters">
                <v-col cols="12">
                </v-col>
              </v-row>
              <v-row class="no-gutters">
                <v-col cols="12">
                    <v-text-field v-model="form.code" dense outlined label="Code" :disabled="disabled_view"></v-text-field>
                </v-col>
              </v-row>
            <v-row class="no-gutters">
                <v-col cols="12">
                    <v-text-field v-model="form.name" dense outlined label="Item Name" :disabled="disabled_view"></v-text-field>
                </v-col>
              </v-row>
              <v-row class="no-gutters">
                <v-col cols="12">
                    <v-autocomplete
                      v-model="form.category_id"
                      :items="GET_ADMIN_CLASSIFICATION_SELECTION"
                      :search-input.sync="search_classification"
                      item-text="text"
                      item-value="value"
                      auto-select-first
                      outlined
                      label="Category"
                      required
                      :disabled="disabled_view"
                      dense
                    ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row class="no-gutters">
                <v-col cols="12">
                    <v-autocomplete
                      v-model="form.sub_category_id"
                      :items="subClass"
                      item-text="text"
                      item-value="value"
                      auto-select-first
                      outlined
                      label="Sub Category"
                      required
                      :disabled="disabled_view"
                      dense
                    ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row class="no-gutters">
                <v-col cols="12">
                    <v-autocomplete
                      v-model="form.uom_id"
                      :items="GET_UOM_SELECTION"
                      item-text="text"
                      item-value="value"
                      auto-select-first
                      outlined
                      label="UOM"
                      required
                      :disabled="disabled_view"
                      dense
                    ></v-autocomplete>
                </v-col>

              </v-row>
              <v-row class="no-gutters">
                <v-col cols="12">
                  <v-text-field v-model="form.quantity" dense outlined label="QTY" min="0" :disabled="disabled_view">
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card>
            </v-col>
            <v-col cols="12" sm="8" class="pl-1">

             <!-- bg fill-height grid-list-md text-xs-center  -->
               <v-container>
               <v-layout row wrap align-center>
                 <v-flex>
                   <v-container fluid>
                     <v-flex>

                     <v-row class="pa-0 no-gutters">
                        <v-container fluid>
                         <v-row class="pa-2">
                           <v-card width="100%">
                             <v-container class="pa-2">
                             <v-row class="d-flex justify-content-end pa-0">

                           <v-btn icon text @click="addVendors()" :disabled="disabled_view">
                           <v-icon  color="primary">mdi-plus-circle</v-icon>
                           </v-btn>

                          </v-row>
                         </v-container>
                            <v-simple-table
                            fixed-header
                            height="250px"
                            width="100%"
                            >
                             <thead>
                               <tr>
                                   <th  style="font-size:12px;" class="text-center">VENDOR</th>
                                   <th  style="font-size:12px;" class="text-center">PRICE</th>
                                   <th  style="font-size:12px;" class="text-center">ACTION</th>
                               </tr>
                             </thead>
                             <tbody>
                               <tr v-for="(vendors,i) in form.vendor" :key="i">
                                 <td><v-autocomplete
                                       v-model="vendors.vendor_id"
                                       :items="GET_ADMIN_SUPPLIER_SELECTION"
                                       item-text="text"
                                       item-value="value"
                                       auto-select-first
                                       outlined
                                       required
                                       :disabled="disabled_view"
                                       dense
                                     ></v-autocomplete>
                                   </td>
                                     <td>
                                       <v-text-field outlined dense v-model="vendors.price" :disabled="disabled_view" @keypress="amount(i)"></v-text-field>
                                     </td>
                                     <td>
                                       <v-btn icon text  @click="removeVendor(i)" :disabled="disabled_view">
                                         <v-icon small color="red">mdi-minus-circle</v-icon>
                                       </v-btn>
                                     </td>
                               </tr>
                             </tbody>
                            </v-simple-table>


                           </v-card>
                         </v-row>


                        </v-container>
                     </v-row>
                   </v-flex>
                   </v-container>
                 </v-flex>
               </v-layout>
             </v-container>

         </v-col>
            </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions class="text-center">
          <v-row class="ma-1">
            <v-col cols="12" >
              <v-btn
              v-if="actions != 'View'"
                class="float-center"
                @click="createOrUpdateAccountability()"
                :disabled="disabled_view"
              >
                <span>{{ actions }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </template>
      <script>
  import Swal from "sweetalert2";
  import { mapGetters } from "vuex";
  import images from '../../../../../assets/ingcoph_bg/ingcoph_logo_alt.jpg'

  export default {
    data() {
      return {
        loaded:true,
        file:File,
        arrayBuffer:null,
        fileList:null,
        dialog:false,
        valid:true,
        // vendor:[],
        dialog_title:"",
        form: {
          id:'',
          vendor:[],
          name: "",
          code:'',
          uom_id: "",
          category_id:"",
          sub_category_id:"",
          quantity:0,
          price:0,
          image_path:null,
          remarks:'',
          life_span:1,
          item_trash:[],
          file:'',
          batchAdditionItems:[],
        },
        price_history:[],
        search_classification:'',
        subClass:[],
        disabled_view: false,
        action:'',
        isDragging: false,
        files: [],
        have_img:false,
        duplicateVendorCollections:[],
      };
    },
    components: {

    },
    computed: {
      ...mapGetters([
        "ACTION",
        "DIALOGS",
        "GET_ADMIN_FASITEM_DISPATCH",
        "GET_LIST_DEPARTMENT",
        "GET_UOM_SELECTION",
        "GET_ADMIN_CLASSIFICATION_SELECTION",
        "GET_ADMIN_SUPPLIER_SELECTION",
        "GET_EDIT_ADMIN_FASITEMS",
        "GET_NEW_ADMIN_FASITEM",
        "GET_ADMIN_BRAND"
      ]),
      actions() {
        if (this.ACTION == "Submit") {
          this.disabled_view = false;
          this.dialog_title = 'CREATE ITEM'
          this.resetFields();
          this.action = this.GET_ADMIN_FASITEM_DISPATCH.add
          return "Submit";
        } else if (this.ACTION == "Update") {
          this.disabled_view = false;
          this.action = this.GET_ADMIN_FASITEM_DISPATCH.update;
          this.dialog_title = 'UPDATE ITEM'
          this.getEditAdminItems()
          return "Update";
        } else if (this.ACTION == "View") {
          this.dialog_title = 'VIEW ITEM'
          this.getEditAdminItems()
          this.disabled_view = true;
          return "View";
        }
      },
    },
    mounted() {

    },
    methods: {
      amount(i){
        let num = this.form.vendor[i].price.replace(",","");
        this.form.vendor[i].price =  this.thousandSeprator(num)
      },
      thousandSeprator(num){
        const numberFormatter = Intl.NumberFormat('en-US');
        const formatted = numberFormatter.format(num);
        return formatted;
      },
      addVendors(){
        this.form.vendor.push({
          id:'',
          vendor_id:'',
          price:'',
        })
      },
      removeVendor(i){-
        this.form.item_trash.push(
            {
                id:this.form.vendor[i].id,
            }
          );
        this.form.vendor.splice(i,1);
      },
      async getEditAdminItems(){
        if(Object.keys(this.GET_EDIT_ADMIN_FASITEMS).length != 0){
             this.form.vendor = this.GET_EDIT_ADMIN_FASITEMS.admin_fas_items_price
            this.form.id= await this.GET_EDIT_ADMIN_FASITEMS.id;
            this.form.code= await this.GET_EDIT_ADMIN_FASITEMS.code;
            this.form.name= await  this.GET_EDIT_ADMIN_FASITEMS.name;
            this.form.category_id= await this.GET_EDIT_ADMIN_FASITEMS.category_id;
            this.form.sub_category_id= await this.GET_EDIT_ADMIN_FASITEMS.sub_category_id;
            this.form.uom_id= await this.GET_EDIT_ADMIN_FASITEMS.uom_id;
            this.form.quantity = await this.GET_EDIT_ADMIN_FASITEMS.quantity;
            this.price_history = this.GET_EDIT_ADMIN_FASITEMS.price_history
            // this.have_img = true;
          // $('#image').attr('src',process.env.VUE_APP_API_HOST +'/'+this.GET_EDIT_ADMIN_FASITEMS.image_path);
        }
      },
      createOrUpdateAccountability(){

        const item_swal_text = this.form.name ? "" : "Item Name";
        const code_swal_text = this.form.code ? "" : "Code";
        const uom_swal_text = this.form.uom_id ? "" : "UOM";
        const qty_swal_text = this.form.quantity ? "" : "QTY";
        const array_text = [item_swal_text,code_swal_text,uom_swal_text,qty_swal_text];
        const filtered = array_text.filter(function (el) {
            return el;
        });
        if (this.form.name && this.form.code && this.form.uom_id && this.form.quantity)
        {
         this.$store.dispatch(this.action,this.form);
        } else {
        Swal.fire({
          html: `Fill Required Fields <br> (${filtered})`,
          icon: "warning",
          allowOutsideClick: false,
        });
      }
      },
      closeDialog() {

            this.$store.commit("EDIT_ADMIN_ITEMS", {});
            this.resetFields();
          this.$store.commit("DIALOG", false);
          console.log(this.form);
      },
      closeSubdialog(){
          this.dialog = false;
          this.form.batchAdditionItems = [];
      },
      resetFields(){
        this.price_history = [];
        this.form = {
          id:'',
          vendor:[],
          name: "",
          code:'',
          uom_id: "",
          category_id:"",
          sub_category_id:"",
          quantity:0,
          price:0,
          image_path:null,
          remarks:'',
          life_span:1,
          item_trash:[],
          file:''
        }
      },
    },
    watch: {
      GET_NEW_ADMIN_FASITEM:{
        handler(val){
          if(val){
            Swal.fire("Success!", "Item Saved", "success");
            this.resetFields();
            this.$store.commit("DIALOG", false);
            this.$store.commit('NEW_ADMIN_FASITEM',false);
          }

        }
      },
      search_classification: {
      handler(val) {
        this.GET_ADMIN_CLASSIFICATION_SELECTION.filter((e) => {
          if (e.text == val) {
            this.subClass = e.sub_class;
          }
        });
      },
    },
      DIALOGS: {
        handler(val) {


        },
      },
    },
  };
  </script>
  <style scope>
  hr.hr-text {
  position: relative;
    border: none;
    height: 1px;
    background: #bcbcbc;
}

hr.hr-text::before {
    content: attr(data-content);
    display: inline-block;
    background: #ffffff;
    font-weight: bold;
    font-size: 0.85rem;
    color: #656565;
    border-radius: 20rem;
    padding: 0.1rem 0.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>