<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-row class="pa-2 ma-2">
                    <v-col
                        v-for="(item,i) in items"
                        :key="i"
                        cols="3"
                        >
                        <v-card
                            rounded
                            class="ma-2 pa-4 rounded-xl"
                            :loading="item.loading"
                        >
                            <div class="d-flex flex-no-wrap justify-space-between">
                            <div>
                                <span class="text-nowrap">
                                    <v-card-title
                                        class="text-h5"
                                    >
                                    <b>{{ item.title }}</b>
                                    </v-card-title>
                                    <v-card-subtitle style="font-size: 12px;">
                                        <i>{{ item.last_used  == '' ? 'N/A' : item.last_used }}</i>
                                    </v-card-subtitle>
                                </span>

                                <v-card-actions>
                                <v-btn
                                    class="ml-2 mt-5"
                                    outlined
                                    rounded
                                    small
                                    @click="getData(item.shop_name,i)"
                                >
                                    START SCRAPING
                                </v-btn>
                                </v-card-actions>
                            </div>

                            <v-avatar
                                size="125"
                                tile
                            >
                                <v-img :src="item.src"></v-img>
                            </v-avatar>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row class="ma-0 pa-0">
                    <v-col cols="12">
                        <v-card class="mx-5 my-1 rounded-lg">
                            <v-row class="ma-0 pa-2">
                                <v-col cols="6" class="text-left mt-2">
                                   <h4>Data Preview</h4>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="2" class="text-right mt-2">
                                   <v-btn @click="exportPreviewData()">Export</v-btn>
                                </v-col>
                            </v-row>
                            <v-row class="ma-0 pa-2">
                                <v-spacer></v-spacer>
                                <v-col cols="2">
                                    <v-text-field
                                        v-model="search"
                                        label="Search"
                                        dense>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-data-table
                                :items="preview_data"
                                :headers="preview_headers"
                                dense
                                :search="search"
                                :loading="loading"
                                class="elevation-1"
                                :item-class="itemRowBackground"
                            >
                            </v-data-table>
                         </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-overlay :value="overlay">
            <v-row>
                <v-col class="text-center">
                    <h3>{{overlay_message}}</h3>
                    <v-progress-circular
                        indeterminate
                        size="64"
                        >
                    </v-progress-circular>
                </v-col>
            </v-row>
      </v-overlay>
    </v-container>
</template>

<script>
import swal from 'sweetalert2';
import OnlineTrackingTableComponent from './OnlineTrackingTableComponent.vue';
export default {
        data(){
            return {
                overlay: false,
                overlay_message:'Loading Please Wait...',
                date_today:this.$dayjs().format('YYYY-M-D'),
                items: [],
                preview_data:[],
                preview_headers:[],
                search:'',
                loading:false
            }
        },
        methods: {
            getTabId(tab){
                this.tab = tab
            },
            async getData(shop_name,i) {
                this.overlay = true
                await this.updateLastUsed(shop_name,'')
                let payload = {
                    shop_name:shop_name,
                    url:'scrapeShopee',
                    python_file:this.python_file,
                    file_name:this.file_name
                }
                const workbook = new this.$exceljs.Workbook();
                await this.$store.dispatch('getWebScraping',payload).then(response=>{

                    const worksheet = workbook.addWorksheet('Raw Data')
                    worksheet.columns = [
                        {header:'Name #',key:'name'},
                        {header:'Model',key:'model'},
                        {header:'Variation',key:'variations'},
                        {header:`${this.file_name} Price`,key:'shop_price',style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: '#,##0.00' }},
                        {header:'Discounted',key:'raw_discounted',style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: '#,##0.00' }},
                        {header:'Discounted (Min)',key:'raw_discounted_min',style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: '#,##0.00' }},
                        {header:'Discounted (Max)',key:'raw_discounted_max',style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: '#,##0.00' }},
                        {header:'Exclusive Price',key:'raw_exclusive',style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: '#,##0.00' }},
                        {header:'Match',key:'raw_match'},
                        {header:'Url',key:'item_url'},
                    ]
                    response.data.forEach((e,i) => {
                        worksheet.addRow(e);
                    });
                    worksheet.views = [
                                { state: 'frozen', xSplit: 1, ySplit: 1 }
                            ]
                    worksheet.autoFilter = {
                        from: 'A1',
                        to: {
                            row: response.data.length + 1,
                            column: worksheet.columns.length,
                        }
                    }
                    }).catch(e => {
                        swal.fire('An Error Occured, Please Try Again', '', 'error')
                        console.log(e)
                    })
                workbook.xlsx.writeBuffer().then((data) => {
                    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                    this.$filesaver.saveAs(blob, `${this.file_name}: ${shop_name}_${this.date_today}.xlsx`);
                    this.overlay = false
                    this.getPreviewData()
                });
            },
            async shopList(){
                let payload = {
                    shop_config:this.shop_config,
                    url:'getShopList',
                }
                await this.$store.dispatch('getWebScraping',payload).then(response=>{
                    this.items = response.data
                })
            },
            updateLastUsed(shop_name,shop){
                if(this.file_name == 'Lazada'){
                    shop = 'lazada_config'
                }
                else{
                    shop = 'shopee_config'
                }
                let payload = {
                    shop:shop,
                    shop_name:shop_name,
                    url:'updateLastUsed'
                }
                this.$store.dispatch('postWebScraping',payload).then(response=>{
                    this.shopList();
                })
            },
            formatNumber(amount){
                let PHP = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'PHP',
                });

                return PHP.format(amount);

            },
            getPreviewData(){
                this.loading = true
                this.preview_headers = []
                let p = {
                    url:'getPreviewReport',
                    shop_config:this.shop_config,
                    file_name:this.file_name,
                }
                this.$store.dispatch('getWebScraping',p).then(e=>{
                    let model = this.model_list
                    let origins = []
                    this.preview_headers.push(
                        { text: 'Model', width: '15%', value: 'model' },
                        { text: 'Discounted Price', value: 'srp' },
                    )
                    e.data.forEach(el=>{
                        if(!origins.includes(el.origin)){
                            origins.push(el.origin)
                            this.preview_headers.push(
                                { text: el.origin, value: el.origin },
                            )
                        }
                    })
                    let distinct_price = []
                    model.forEach(m=>{
                        distinct_price.push(e.data.filter(d=> d.model == m) ?? {})
                    })
                    distinct_price.forEach(x=>{
                        let a = []
                            if(x.length > 0){
                                x.forEach(mk=>{

                                if(!a.includes(mk.shop_price)){
                                    a.push(mk.shop_price)
                                }
                            })
                            Object.assign(x,{
                                new_shop_price:a.join(', '),
                                model:x[0].model,
                                raw_discounted:"₱ " +this.$options.filters['currency'](x[0].raw_discounted),
                                raw_discounted_max:x[0].raw_discounted_max,
                                raw_discounted_min:x[0].raw_discounted_min
                            })
                        }
                    })
                    let p = []
                    distinct_price.forEach(m=>{
                        origins.forEach(o=>{
                            if(e.data.filter(pl=> pl.model == m.model && pl.origin == o).length > 0){
                                p.push({
                                    model:m.model,
                                    srp:m.raw_discounted,
                                    [o]:m.new_shop_price,
                                })
                            }
                        })
                    })
                    origins.forEach(xy=>{
                        p.forEach(xc=>{
                            if(!xc[xy]){
                                xc[xy] = 0
                            }
                        })
                    })
                    this.preview_data = p

                    const resultTest = {};
                    const result = [];
                    p.forEach((item) => {
                        if (resultTest[item.model]) {
                            let objKeys = Object.keys(item);
                            objKeys.forEach((e,i)=>{
                                if(i >= 2){
                                    const index = resultTest[item.model] -1;
                                    const foundItem = result[index];
                                    const newValue = {
                                        ...foundItem,
                                        [e]: typeof foundItem[e] == 'number' && typeof item[e] == 'number' ? Number(foundItem[e]) + Number(item[e]) : String(foundItem[e]) +','+ String(item[e]),
                                    };
                                    result[index] = newValue;
                                }
                            })
                        } else {
                            resultTest[item.model] = result.length + 1;
                            result.push(item);
                        }
                    });
                   this.preview_data = result
                   this.preview_data.forEach(e=>{
                        let objKeys = Object.keys(e);

                        objKeys.forEach((x,i)=>{
                            if(i>1){
                                let arr =  String(e[x]).split(',')
                                let t = []
                                arr.forEach(s=>{
                                    let a = "₱ " +this.$options.filters['currency'](s)
                                    return t.push(a)
                                })
                                e[x] = t.join(', ')
                            }
                        })
                   })
                   this.loading = false
                })
            },
            async exportPreviewData(){
                const workbook = new this.$exceljs.Workbook();

                let export_headers = [];
                this.preview_headers.forEach((e,i)=>{
                    if(i >= 2){
                        export_headers.push(
                            {header:e.text,key:e.value, width: '5%', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }}}
                        )
                    }
                    else{
                        export_headers.push(
                            {header:e.text,key:e.value}
                        )
                    }
                })
                const worksheet = workbook.addWorksheet('Raw Data')

                worksheet.columns = export_headers

                this.preview_data.forEach((e,i) => {
                    worksheet.addRow(e);
                });
                worksheet.views = [
                    { state: 'frozen', xSplit: 1, ySplit: 1 }
                ]
                worksheet.autoFilter = {
                    from: 'A1',
                    to: {
                        row: this.preview_data.length + 1,
                        column: worksheet.columns.length,
                    }
                }
                workbook.xlsx.writeBuffer().then((data) => {
                    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                    this.$filesaver.saveAs(blob, `${this.file_name}: ${this.date_today}.xlsx`);
                    this.overlay = false
                });
            },
            itemRowBackground(item){
                return ''
            },
        },
        mounted(){
            this.shopList();
            this.getPreviewData();
        },
        props:['shop_config','python_file','file_name','tab_id','model_list'],
        watch: {
            tab_id(val) {
                this.shopList();
                this.getPreviewData();
            },
        },
        components:{
            OnlineTrackingTableComponent
        }
};
</script>

<style>

</style>