<template>
    <div class="w-100">
        <v-app id="my_vue_app">
            <v-card>
                <v-card-title>
                <v-container>
                    <v-row>
                        <v-col class="d-flex justify-end mr-3">
                            <a
                            v-for="(status, index ) in statuses" :key="index"
                            :class="`ml-1 badge badge-${status.text} ${status.value == filter.selected_status ? `selected` : `non-selected`}`" @click="selectedStatus(status)"
                            >
                            {{ status.text ? status.text : null  }}
                            </a>
                        </v-col>
                    </v-row>
                    <v-row class="mb-2">
                        <v-spacer></v-spacer>
                        <div class="row col-sm-12 col-md-4 mr-3 align-items-end">
                            <v-text-field
                                id="searchBar"
                                v-model="filter.search"
                                label="Search"
                                single-line
                                hide-details
                                @change="searchInput()"
                            >
                            </v-text-field>
                            <v-icon @click="searchInput()">mdi-magnify</v-icon>
                        </div>
                    </v-row>
                </v-container>

                </v-card-title>
                <v-container fluid>
                    <v-data-table
                        :headers="datatable.headers"
                        :items="datatable.items"
                        :search="search"
                        :options.sync="datatable.options"
                        :server-items-length="datatable.totalItems"
                        :items-per-page="10"
                        :loading="loading.datatable"
                        :footer-props="{
                            itemsPerPageOptions: datatable.itemsPerPageOptions
                        }"
                        loading-text="Loading... Please wait"
                        fixed-header
                    >
                        <template v-slot:[`item.action`]="{ item }">
                            <v-btn text icon color="orange" v-if="userAccess.view">
                            <v-icon
                                class="btn-action"
                                small
                                @click="viewManpowerRequest(item)"
                                >mdi-eye</v-icon
                            >
                            </v-btn>
                            <v-btn text icon color="blue" v-if="userAccess.edit && item.status == 'pending'">
                            <v-icon
                                class="btn-action"
                                small
                                @click="editManpowerRequest(item)"
                                >mdi-pencil</v-icon
                            >
                            </v-btn>
                            <!-- <v-btn text icon color="red" v-if="userAccess.delete">
                            <v-icon
                                class="btn-action"
                                small
                                @click="deletePlantilla(item)"
                                >mdi-delete</v-icon
                            >
                            </v-btn> -->
                        </template>
                        <template v-slot:[`item.status`]="{item}">
                            <span :class="`badge badge-${item.status}`">{{ item.status }}</span>
                        </template>
                        <template v-slot:[`item.approval_date`]="{item}">
                            <span>{{ item.approval_date ? formatTime(item.approval_date, 'MM/DD/YYYY') : '' }}</span>
                        </template>
                    </v-data-table>
                </v-container>
            </v-card>
        </v-app>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2';
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue';

export default {
    mixins: [SharedFunctionsComponent],
    data(){
        return {
            loaded: false,
            totalItems: 0,
            options: {},
            items: [],
            itemsPerPageOptions: [5, 10, 15, 50, 100],
            itemsPerPage: 10,
            search: "",
            filter:{
                search:""
            },

            datatable:{
                headers:
                    [
                        { text: 'Requestor', align: 'start', value: 'requestor_name'},
                        { text: 'Position', align: 'start',value:'position_name' },
                        { text: 'Department', align: 'start',value:'department_name' },
                        { text: 'Reason', align: 'start',value:'reason_name' },
                        { text: 'Requested No. of Manpower', align: 'center', value: 'no_of_personnel'},
                        { text: 'Status', align: 'start', value: 'status'},
                        { text: 'Approve Date', align: 'start', value: 'approval_date'},
                        { text: "Action", align: "center", value: "action" }
                    ],
                items:[],
                options:{},
                totalItems: 0,
                itemsPerPageOptions: [5, 10, 15, 50, 100],
                itemsPerPage: 10,
            },

            loading:{
                datatable:false
            },

            statuses:[
                {text:'all', value:0},
                {text:'approved', value:1},
                {text:'rejected', value:2},
                {text:'pending', value:3},
            ],

            userAccess: {
                view: false,
                create: false,
                edit: false,
                delete: false,
                approve_reject_manpower_request:false
            },
            selected_status:'',
            isDepartmentView:0
        }
    },
    mounted(){
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        this.getSearchFromUrl()
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS',
            'GET_HR_RECRUITMENT_NEW_DATA',
            'USERACCOUNT_LOAD',
            'GET_HR_TAB_IS_ACTIVE',
            'GET_HR_DISPATCH'
        ]),
    },
    components:{
    },
    methods:{
        selectedStatus(data){
            this.selected_status = data.value
            this.getHRManpowerRequest()
        },
        getHRManpowerRequest(filter = null){
            this.items = [];
            this.loading.datatable = true;
            let payload = {
                page            : this.options.page,
                itemsPerPage    : this.options.itemsPerPage,
                search          : this.filter.search,
                sortBy          : this.options.sortBy,
                sortDesc        : this.options.sortDesc,
                status          : this.selected_status,
                filter          : filter,
            };
            this.$store.dispatch(this.GET_HR_DISPATCH.get, payload).then((response) => {
                this.datatable.items                    = response.data.data;
                this.datatable.totalItems               = response.total;
                this.loading.datatable                  = false;
            }).catch((err)=>{
                Swal.fire({
                    title: 'Error',
                    text: 'Network Error',
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
                console.log(err)
            });
        },
        searchInput(){
            this.datatable.options.page = 1
            this.getHRManpowerRequest({ query: this.search });
        },
        viewManpowerRequest(item){
            this.$store.commit("HR_OTHER_TABS_DIALOG_VIEW", item);
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", "View");
        },
        editManpowerRequest(item){
            this.$store.commit("HR_OTHER_TABS_DIALOG_VIEW", item);
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", "Update");
        },
        getSearchFromUrl() {
            if (Object.keys(this.$route.query).length != 0) {
                Object.keys(this.$route.query).forEach(e=>{
                if(e != 'for_approval')
                    this.filter.search = atob(this.$route.query[e]);
                })
                this.searchInput();
            }
        },
        // deletePlantilla(item){
        // Swal.fire({
        //         icon: "warning",
        //         text: "Are you sure you want to Delete?",
        //         cancelButtonText: "Cancel",
        //         showCancelButton: true,
        //         allowOutsideClick: false,
        //         confirmButtonColor: "#397373",
        //         cancelButtonColor: "#397373",
        //     })
        //     .then((result) => {
        //     if (result.isConfirmed) {
        //         let payload = {
        //             payload : item,
        //         }
        //         this.$store.dispatch(this.GET_HR_DISPATCH.delete, payload).then((success) => {
        //             Swal.fire("Success!", "Employee Data deleted", "success");
        //             });
        //         }
        //     });
        // },
    },
    watch:{
        'datatable.options': {
            handler() {
                this.getHRManpowerRequest();
            },
            deep: true,
        },
        USER_ACCESS: {
            handler(val) {
                if(val != "fail"){
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                this.userAccess.create = false;
                    val.forEach(e => {
                        if (e.actions_code == 'edit') {
                            this.userAccess.edit = true;
                        } else if (e.actions_code == 'delete') {
                            this.userAccess.delete = true;
                        } else if (e.actions_code == 'view') {
                            this.userAccess.view = true;
                        } else if (e.actions_code == 'create') {
                            this.userAccess.create = true;
                        }
                    });
                }
            }
        },
        GET_HR_RECRUITMENT_NEW_DATA:{
            handler(val){
                if(val){
                    if(this.GET_HR_TAB_IS_ACTIVE == 'manpower_request' ){
                        this.getHRManpowerRequest();
                        this.$store.commit('HR_RECRUITMENT_NEW_DATA',false)
                    }
                }
            }
        },
    }
}
</script>
<style scoped>
.selected {
    opacity: 100%;
}

.non-selected {
    opacity: 40%;
}
</style>
