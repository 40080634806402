<template>
	<v-form ref="form" v-model="valid" lazy-validation>
	<v-card>
		<v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom mt-5">
			<h5>{{dialog_title}}</h5>
		<v-row class="m-0">
			<v-col cols="pull-right-10 p-2">
			<v-btn
				text
				icon
				small
				color="gray"
				class="float-right"
				@click="closeDialog()"
			>
				<v-icon>mdi-close-circle</v-icon>
			</v-btn>
			</v-col>
		</v-row>
		</v-card-title>
		<v-container class="overflow-auto">
			<v-row class="mt-2 mb-2 pl-3">
				<h6>Map Marker</h6>
			</v-row>
			<v-row class="ma-2">
				<v-col cols='2'>
					<v-img class="img-thumbnail" width="40" height="40" :src="marker.image_path" eager contain></v-img>
				</v-col>
				<v-col cols='10' >
					<v-autocomplete
						:items="filterClassifications"
						:disabled="marker.isDisabled"
						v-model="marker.classification_id"
						outlined
						dense
						label="Select Classification"
						@change="selectClassification"
						>
					</v-autocomplete>
				</v-col>
			</v-row>
			<v-row class="ma-2">
				<v-col v-if="actions != 'View'">
					<v-file-input
						ref="image" id='file' type="file" label="Image"
						dense
                        :loading="loading"
                        :disabled="loading"
                        show-size
                        truncate-length="50"
                        placeholder="Upload Marker"
                        prepend-icon="mdi-camera"
						@change="onAttach"
                        @click:clear="clearImageInput"
						required
                    ></v-file-input>
				</v-col>
			</v-row>
		</v-container>
		<v-card-title class="text-center border">
		<v-row class="ma-1">
			<v-col cols="12">
			<v-btn v-if="actions != 'View'"
				class="float-center"
				@click="actions == 'Submit' ? addNewMarker() : updateMarker()"
			>
				<span>{{ actions }}</span>
			</v-btn>
			</v-col>
		</v-row>
		</v-card-title>
	</v-card>
	</v-form>
</template>
	<script>
	import buttons from "../../Buttons/SubdialogButton.vue";
	import buttons_components from "../../../../../assets/js/components-js.js";
	import { mapGetters } from "vuex";
	import Swal from "sweetalert2";

	export default {
		mixins: [buttons_components],
		components: { buttons, buttons_components },
		data() {
			return {
				valid: true,
				buttons: buttons_components.buttons,
				selected_marker:0,
				marker:{
					classification_id: null,
					image: null,
					marker_name: null,
					toggle: 1,
					isDisabled:false,
					image_path:null
				},
				loading:false,
				legendClassifications:[],
				dialog_title:null
			};
		},
		mounted(){
			this.$store.dispatch('getAllCustomers')
			this.getLegendsClassifications();
		},
		methods:{
			closeDialog(){
				this.$store.commit("DIALOG", false);
				this.resetFields();
			},
			addNewMarker(){
				const config = {
					headers: {
						'content-type': 'multipart/form-data'
					}
				}
				let data = new FormData();
				data.append('classification_id',this.marker.classification_id)
				data.append('marker',this.marker.marker_name)
				data.append('image',this.marker.image)
				data.append('toggle',this.marker.toggle)
				data.append('url','addMapMarker')
				data.append('method','Create');
				data.append('tab', 'legends')

				this.$store.dispatch('postCustomerMarkers',data).then((response=>{
					this.$store.commit("REFRESH_MARKER", true);
					this.$store.commit('DIALOG', false);
					Swal.fire({
						title: 'Success',
						icon: 'success',
						timer: 1500,
					});
				}))
			},
			updateMarker(){
				const config = {
					headers: {
						'content-type': 'multipart/form-data'
					}
				}
				let data = new FormData();
				data.append('classification_id',this.marker.classification_id)
				data.append('marker',this.marker.marker_name)
				data.append('image',this.marker.image)
				data.append('toggle',this.marker.toggle)
				data.append('url','addMapMarker')
				data.append('method','Edit');
				data.append('id', this.selected_marker);
				data.append('tab', 'legends')

				this.$store.dispatch('postCustomerMarkers',data).then((response=>{
					this.$store.commit("REFRESH_MARKER", true);
					this.$store.commit('DIALOG', false);
					Swal.fire({
						title: 'Success',
						icon: 'success',
						timer: 1500,
					});
				}))

			},
			resetFields(){
				this.marker.classification_id = null
				this.marker.image = null
			},
			onAttach(image){
				let kb = 0
				let mb = 0
				let maxMb = 1;
				if (image) {
					kb = image.size / 1024;
					mb = kb / 1024;
				}
				if (!!image) {
					this.marker.image = image;
				}
			},
			clearImageInput() {
				this.image = '';
			},
			selectClassification(){
				this.GET_CLASSIFICATIONS_DROPDOWN.data.forEach(e=>{
					if (e.value == this.marker.classification_id){
						this.marker.marker_name = e.text+"_MARKER"
					}
				})
			},
			getLegendsClassifications(){
				let payload = {
					url: 'getAllMapMarker',
					method: 'map',
					tab: 'legends'
				}
				this.$store.dispatch('getCustomerMaps',payload).then((response=>{
					this.legendClassifications = response.data
				}))
			}
		},
		computed: {
			...mapGetters([
				"ACTION",
				"DIALOGS",
				"USERACCOUNT_LOAD",
				"GET_CLASSIFICATIONS_DROPDOWN",
				"GET_NEW_MARKER",
				"SELECTED_MARKER"
			]),
			actions() {
				if (this.ACTION == "Submit") {
					// this.resetFields()
					this.dialog_title = "Add Legends"
					this.marker.isDisabled = false
					// this.marker.image_path = false
					this.marker.image_path = null
					this.marker.marker_name = this.SELECTED_MARKER.classification_name + "_MARKER"
					return "Submit";
				}
				else if (this.ACTION == "Update"){
					this.marker = {
						classification_id:Number(this.SELECTED_MARKER.classification_id),
					}
					this.selected_marker = this.SELECTED_MARKER.id
					this.marker.image_path = this.SELECTED_MARKER.marker
					this.marker.marker_name = this.SELECTED_MARKER.classification_name + "_MARKER"
					this.marker.classification_id = Number(this.SELECTED_MARKER.classification_id)
					// this.marker.image = null
					this.dialog_title = "Edit Legend"
					this.marker.isDisabled = true
					return "Update"
				}
				else if (this.ACTION == "View"){
					this.marker = {
						classification_id:Number(this.SELECTED_MARKER.classification_id),
					}
					this.selected_marker = this.SELECTED_MARKER.id
					this.marker.image_path = this.SELECTED_MARKER.marker
					this.marker.marker_name = this.SELECTED_MARKER.classification_name + "_MARKER"
					this.marker.classification_id = Number(this.SELECTED_MARKER.classification_id)
					// this.marker.image = null
					this.dialog_title = "Legends: "+this.SELECTED_MARKER.classification_name
					this.marker.isDisabled = true
					return "View"
				}
			},
			filterClassifications(){
				if(this.ACTION == "Submit"){
					let existClassifications = this.legendClassifications.map(e=>{
						return Number(e.classification_id)
					})
					let temp = []

					this.GET_CLASSIFICATIONS_DROPDOWN.data.forEach(e=>{
						if (!existClassifications.includes(e.value)){
							temp.push(e)
						}
					})
					return temp;
				}
				else{
					this.marker.isDisabled = false
					return this.GET_CLASSIFICATIONS_DROPDOWN.data
				}
			}
		},
		watch: {
			DIALOGS: {
				handler(val) {
					if (val) {
					}
				},
			},
			GET_NEW_MARKER: {
				handler(val) {
					this.$store.commit('DIALOG', false);
					Swal.fire({
						title: 'Success',
						icon: 'success',
						timer: 1500,
					});
					// this.$store.dispatch('getAllClassification')
					this.getLegendsClassifications()
					this.$store.commit('NEW_MARKER', false);
				}
			},
		},
	};

	</script>
	<style lang="scss">
	div ::v-deep .v-label.v-label--active.theme--light {
	left: 0 !important;
	right: auto !important;
	}
	div ::v-deep .v-input__slot fieldset legend {
	margin-left: 10px !important;
	width: auto;
	}
	</style>
