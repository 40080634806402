<template>
    <div class="ma-1">
        <div class="page-wrapper">
            <div class="row p-2">
                <div class="col-12 d-flex no-block align-items-center">
                    <h4 class="page-title">Items</h4>
                </div>
            </div>
            <div class="container">
                <v-tabs v-model="tab" id="group-tab" color="cyan" dark slider-color="cyan" background-color="#424242" class="col-12 pb-0" show-arrows>
                    <v-tab ripple>Items</v-tab>
                    <v-tab ripple>Active Parts</v-tab>
                    <v-tab ripple>Inactive Parts</v-tab>
                    <v-tab ripple>SP #</v-tab>
                    <!-- <v-tab ripple>Spare Parts</v-tab> -->
                </v-tabs>
                <v-tabs-items v-model="tab" touchless style="background-color:unset">
                    <v-tab-item>
                        <!-- <ServiceItemsTableComponentVue :type="1" /> -->
                        <ItemsTableComponentWithSpareParts :isModule="'Items'" />
                    </v-tab-item>
                    <v-tab-item>
                        <ItemsComponentVue ref="itemsComponent"
                        add-sp
                        :is_inactive_items="tab == 1 ? false : true"
                        :is_sp="true"
                        :itemsActionResult="''"
                        ></ItemsComponentVue>
                    </v-tab-item>
                    <v-tab-item>
                    <ItemsComponentVue ref="itemsComponent"
                        add-sp
                        :is_inactive_items="tab == 1 ? false : true"
                        :is_sp="true"
                        :itemsActionResult="''"
                        ></ItemsComponentVue>
                    </v-tab-item>
                    <v-tab-item>
                        <SparePartsNumberTableComponent />
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </div>
        <ItemsComponentVue v-show="false" ref="itemsComponent" add-sp></ItemsComponentVue>
    </div>
</template>

<script>
import ServiceItemsTableComponentVue from '../../Services/Views/ItemComponent/ServiceItemsTableComponent.vue';
import ItemsComponentVue from '../../Sales/Items/tabs/ItemsComponent.vue';
import ItemsTableComponent from '../../Sales/Items/tables/ItemsTableComponent.vue';
import ItemsTableComponentWithSpareParts from '@/views/main/modules/Services/Views/SparePartsComponent/ItemsTableComponent.vue'
import SparePartsNumberTableComponent from '@/views/main/modules/Services/Views/SparePartsNumberComponent/SparePartsNumberTableComponent.vue';
import { mapGetters } from "vuex";
export default {

    data() {
        return {
            tab: null,
            userAccess:{
                add:false,
                create:false
            }
        }
    },
    mounted(){
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
        this.$store.dispatch('getItemCategories');
        this.$store.dispatch('getItemSize');
        this.$store.dispatch('getItemTypes');
        this.$store.dispatch('getItemUom');
        this.$store.dispatch('getItemBrand');
        this.$store.dispatch('getItems')
        this.$store.dispatch('getAllItemCategories');
    },
    components: {
        ServiceItemsTableComponentVue,
        ItemsComponentVue,
        ItemsTableComponent,
        ItemsTableComponentWithSpareParts,
        SparePartsNumberTableComponent
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS'
        ])
    },
    watch:{
        USER_ACCESS: {
            handler(val) {
                val.forEach((e) => {
                    if (e.actions_code == "add") {
                        this.userAccess.add = true;
                    }
                    if (e.actions_code == "create") {
                        this.userAccess.create = true;
                    }
                });
            },
        },
    }
}
</script>

<style>

</style>
