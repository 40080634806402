<template>
    <v-form v-model="valid" @submit.prevent="ACTION == 'Submit' ? createVatType() : updateVatType()">
        <v-card>
            <v-row class="px-3 py-2" dense no-gutters>
                <v-col cols="11">
                    <v-card-title class="pa-0 pl-3">
                        <span class="text-h5">
                            {{ ACTION === 'Submit' ? 'Create' : ACTION }}
                            VAT Type
                        </span>
                    </v-card-title>
                </v-col>
                <v-col cols="1">
                    <v-btn text icon small color="gray" class="float-right" @click="closeDialog()">
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class="pa-0 ma-0"></v-divider>
            <v-row class="px-3 py-2" dense no-gutters>
                <v-col cols="12">
                    <v-text-field
                        v-model="form.code"
                        :rules="readonly ? [] : rules.code"
                        :disabled="readonly"
                        :loading="!loaded"
                        label="Code"
                        dense
                        outlined
                    ></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        v-model="form.name"
                        :rules="readonly ? [] : rules.name"
                        :disabled="readonly"
                        :loading="!loaded"
                        label="Name"
                        dense
                        outlined
                    ></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        v-model="form.percentage"
                        :rules="readonly ? [] : rules.percentage"
                        :disabled="readonly"
                        :loading="!loaded"
                        label="Percentage"
                        dense
                        outlined
                        @keydown="numberOnly"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-divider class="px-0 ma-0" v-if="ACTION !== 'View'"></v-divider>
            <v-card-actions class="text-center" v-if="ACTION !== 'View'">
                <v-row class="ma-1">
                    <v-col cols="12">
                        <v-btn
                            :disabled="!valid || readonly"
                            :loading="readonly"
                            type="submit"
                        >
                            <span>{{ ACTION }}</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-form>
</template>
<script>
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';

import SharedFunctionsComponent from "@/views/main/Shared/SharedFunctionsComponent.vue";

export default {
    mixins: [SharedFunctionsComponent],

    data() {
        return {
            valid: true,
            readonly: false,
            loaded: true,

            form: {
                id: undefined,
                code: undefined,
                name: undefined,
                percentage: undefined,
            },
            rules: {
                code: [
                    value => {
                        if (value) return true;
                        return 'Code is required';
                    },
                ],
                name: [
                    value => {
                        if (value) return true;
                        return 'Name is required';
                    },
                ],
                percentage: [
                    value => {
                        if (value) return true;
                        return 'Invalid percentage format';
                    },
                ],
            }
        }
    },

    computed: {
        ...mapGetters([
            'ACTION',
            'DIALOGS',
            'GET_VAT_TYPE_VIEW_ID',
        ]),
    },

    methods: {
        closeDialog() {
            this.$store.commit('VAT_TYPE_VIEW_ID', undefined);
            this.$store.commit('DIALOG', false)
            
            this.readonly = false;
            this.loaded = true;
            this.form = {
                id: undefined,
                code: undefined,
                name: undefined,
                percentage: undefined,
            };
        },
        createVatType() {
            this.readonly = true;

            const payload = {
                url: 'create-vat-type',
                ...this.form,
            }
            
            this.$store.dispatch('createVatType', payload).then(response => {
                this.$store.commit('RELOAD_DATA_TABLE', true);
                this.closeDialog();

                swal.fire({
                    title: 'Create VAT Type Success',
                    icon: 'success',
                });
            }).catch(error => {
                let title = '';

                if (error.response.status === 400) title = 'Missing Required Data On Create';
                else if (error.response.status === 409) title = 'Code Or Name Already Exists';
                else title = 'Create VAT Type Failed';

                this.readonly = false;

                swal.fire({
                    title: title,
                    icon: 'error',
                });
            });
        },
        updateVatType() {
            this.readonly = true;

            const payload = {
                url: 'update-vat-type',
                ...this.form,
            };
            
            this.$store.dispatch('updateVatType', payload).then(response => {
                this.$store.commit('RELOAD_DATA_TABLE', true);
                this.closeDialog();

                swal.fire({
                    title: 'Update VAT Type Success',
                    icon: 'success',
                });
            }).catch(error => {
                let title = '';

                if (error.response.status === 400) title = 'Missing Required Data On Create';
                else if (error.response.status === 409) title = 'Code Or Name Already Used By Other Instance';
                else title = 'Update VAT Type Failed';

                this.readonly = false;

                swal.fire({
                    title: title,
                    icon: 'error',
                });
            });
        },

        numberOnly(event) {
            const inputValue = event.target.value;
            const decimalIndex = inputValue.indexOf('.');

            // Allow only one dot
            if ([110, 190].includes(event.keyCode) && decimalIndex !== -1) return event.preventDefault();

            // Allow: backspace, delete, tab, escape, enter, period, and decimal point
            if ([46, 8, 9, 27, 13, 110, 190].includes(event.keyCode) ||
                // Allow: Ctrl+A
                (event.keyCode === 65 && event.ctrlKey === true) ||
                // Allow: Ctrl+C
                (event.keyCode === 67 && event.ctrlKey === true) ||
                // Allow: Ctrl+X
                (event.keyCode === 88 && event.ctrlKey === true) ||
                // Allow: home, end, left, right
                (event.keyCode >= 35 && event.keyCode <= 39)) {
                // Let it happen, don't do anything
                return;
            }

            // Ensure that it is a number and stop the keypress
            if ((event.shiftKey || (event.keyCode < 48 || event.keyCode > 57)) &&
                (event.keyCode < 96 || event.keyCode > 105)) return event.preventDefault();
        }
    },

    watch: {
        'ACTION': {
            handler(val) {
                this.readonly = (val === 'View');
            },
            immediate: true,
        },
        'GET_VAT_TYPE_VIEW_ID': {
            handler(val) {
                if (!val) return;

                const payload = {
                    url: 'view-vat-type',
                    id: val,
                };

                this.loaded = false;
                this.$store.dispatch('viewVatType', payload).then(response => {
                    this.form = response;
                    this.loaded = true;
                });
            },
            immediate: true,
        },
    },
}
</script>
