<template>
    <div class="w-100">
        <v-card>
            <v-container class="w-100">
                <v-card-title v-if="tab_status =='confirmed' || tab_status == 'all'">
                    <v-row>
                        <v-col class="d-flex justify-end mr-3">
                            <a
                            v-for="(item, index ) in returns" :key="index"
                            :class="`ml-1 badge badge-${item.text} ${item.value == selected_return_status ? `selected` : `non-selected`}`" @click="selectReturnStatus(item)"
                            >
                            {{ item.text ? item.text : null  }}
                            </a>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-row dense no-gutters tag="div" style="align-items: right">
                    <v-spacer></v-spacer>
                    <v-col cols="12" sm="3" md="3">
                        <v-text-field
                            v-model="search"
                            @change="searchInput()"
                            label="Search SF #"
                            single-line
                            hide-details
                            dense
                            append-icon="mdi-magnify"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-data-table
                        :headers="headers"
                        :items="sample_items"
                        :items-per-page="5"
                        :loading="loading"
                        :multi-sort="true"
                        :disable-sort="true"
                        :options.sync="options"
                        :server-items-length="totalItems"
                        :footer-props="{
                            itemsPerPageOptions: itemsPerPageOptions,
                        }"
                        loading-text="Loading... Please wait"
                        class="elevation-1 w-100"
                        fixed-header
                    >
                        <template v-slot:[`item.action`]="{ item , index}">
                            <v-btn  :loading="loaded.button && loaded.index == index" text icon color="orange" v-if="userAccess.view">
                            <v-icon

                                class="btn-action"
                                small
                                @click="viewSampleForm(item.id,'Approve',index)"
                                >mdi-eye</v-icon
                            >
                            </v-btn>
                            <v-btn  :loading="loaded.button && loaded.index == index" text icon color="blue" v-if="userAccess.update_issue && item.status == 'saved'">
                            <v-icon

                                class="btn-action"
                                small
                                @click="viewSampleForm(item.id,'Update',index)"
                                >mdi-pencil</v-icon
                            >
                            </v-btn>
                            <!-- <v-btn text icon color="red" v-if="userAccess.delete">
                            <v-icon
                                class="btn-action"
                                small
                                @click="deleteSampleForm(item.id)"
                                >mdi-delete</v-icon
                            >
                            </v-btn> -->
                        </template>

                        <template v-slot:[`item.status`]="{ item }">
                            <span :class="`badge badge-${item.status.toLowerCase()}`">{{ item.status }}</span>
                        </template>

                        <template v-slot:[`item.approved_date`]="{ item }">
                            {{ item.approved_date ? new Date(item.approved_date).toLocaleDateString() : null }}
                        </template>

                        <template v-slot:[`item.borrowed_date`]="{ item }">
                            {{ new Date(item.borrowed_date).toLocaleDateString() }}
                        </template>

                        <template v-slot:[`item.confirmed_date`]="{ item }">
                            {{item.confirmed_date ? new Date(item.confirmed_date).toLocaleDateString() :item.confirmed_date}}
                        </template>

                        <template v-slot:[`item.cancel_date`]="{ item }">
                            {{item.cancel_date ? new Date(item.cancel_date).toLocaleDateString() :item.cancel_date}}
                        </template>

                        <template v-slot:[`item.returned`]="{ item }">
                            <span :class="`badge badge-full`" v-if="item.returned == 3">full</span>
                            <span :class="`badge badge-partial`" v-if="item.returned == 2">partial</span>
                            <span :class="`badge badge-none`" v-if="!item.returned">none</span>
                        </template>
                    </v-data-table>
                </v-row>
            </v-container>
        </v-card>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props:['tab_status'],
    data() {
        return {
            headers: [
                { text: 'SF #', value: 'sample_form_num', align: 'start', width: '7%'},
                { text: 'Salesman', value: 'salesman', align: 'start', width: '8%'},
                { text: 'Prepared By', value: 'prepared_by', align: 'start', width: '9%' },
                { text: 'Approved By', value: 'approved_by', align: 'center', width: '9%' },
                { text: 'Approved Date', value: 'approved_date', align: 'center', width: '7%' },
                { text: 'Confirmed By', value: 'confirmed_by', align: 'center', width: '9%' },
                { text: 'Confirm Date', value: 'confirmed_date', align: 'center', width: '7%' },
                { text: 'Cancelled By', value: 'cancel_by', align: 'center', width: '9%' },
                { text: 'Cancel Date', value: 'cancel_date', align: 'center', width: '6%' },
                { text: 'Borrow Date', value: 'borrowed_date', align: 'center', width: '9%' },
                { text: 'Status', value: 'status', align: 'center', width: '6%' },
                { text: 'Returned', value: 'returned', align: 'center', width: '6%' },
                { text: 'Action', value: 'action', align: 'center' },
            ],
            userAccess: {
                view: false,
                update_issue: false,
            },
            loading: false,
            sample_items: [],
            options: {},
            items: [],
            itemsPerPageOptions: [5, 10, 15, 50, 100],
            itemsPerPage: 10,
            search: '',
            options: {},
            totalItems: 0,
            returns:[
                {text:'all', value:-1},
                {text:'full', value:3},
                {text:'partial', value:2},
                {text:'none', value:0},
            ],
            selected_return_status:-1,
            loaded:{
                button:false,
                index: null
            },
        }
    },
    mounted() {
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
    },
    computed: {
        ...mapGetters([
            'USER_ACCESS',
            'REFRESH_TABLE',
            'GET_SF_ACTIVE_TAB'
        ]),
    },
    methods: {
        getAllSampleForm() {
            this.sample_items = [];
            this.loading = true;
            let payload = {
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                search: this.search,
				url: 'getAllSampleForm',
                tab: this.tab_status,
                returned:this.selected_return_status
            }
            this.$store.dispatch('getAllSampleForm', payload).then(response => {
                this.sample_items = response.data.data.data
                this.loading = false
                this.totalItems = response.data.data.total;
            })
        },
        async viewSampleForm(id, action, index = -1) {
            this.loaded.button = true
            this.loaded.index = index;

            await this.$store.dispatch('viewSampleForm', id).then(response => {
                this.$store.commit("DIALOG", true);
                this.$store.commit("ACTION", action);
                this.$store.commit('VIEW_DATA',response.data)
                this.loaded.button = false
            }).catch((err)=>{
                swal.fire({
                    title: 'Error',
                    text: 'Network Error',
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
                console.log(err)
                this.loaded.button = false
            });
        },
        searchInput() {
            this.getAllSampleForm({ query: this.search });
            this.options.page = 1;
        },
        selectReturnStatus(data){
            this.selected_return_status = data.value
            this.getAllSampleForm()
        },
    },
    watch: {
        USER_ACCESS: {
            handler(val) {
                if(val != "fail"){
                    this.userAccess.view = false;
                    this.userAccess.update_issue = false;

                    val.forEach(e => {
                        if (e.actions_code == 'view') {
                            this.userAccess.view = true;
                        } else if (e.actions_code == 'update_issue') {
                            this.userAccess.update_issue = true;
                        }
                    });
                }
            }
        },
        REFRESH_TABLE: {
            handler(val) {
                if(val){
                    this.getAllSampleForm();
                    this.$store.commit('SET_REFRESH_TABLE', false);
                    this.$store.commit('DIALOG',false);
                }
            }
        },
        options: {
            handler(val) {
                this.getAllSampleForm()
            },
            deep: true,
        },
        tab_status:{
            handler(val){
                this.getAllSampleForm()
            }
        }
    }
}
</script>

<style scoped>
.selected {
    opacity: 100%;
}

.non-selected {
    opacity: 40%;
}
</style>
