<template>
<div class="w-100">
    <v-app id="my_vue_app">

        <v-card>
            <v-card-title>
                <v-spacer></v-spacer>
                <v-col cols="12" sm="12" md="12" lg="3" xl="2">  
                    <v-row class="align-items-end">
                        <v-text-field
                            v-model="search"
                            label="Search"
                            single-line
                            hide-details
                        >
                        </v-text-field>
                        <v-icon>mdi-magnify</v-icon>
                    </v-row>  
                </v-col>
            </v-card-title>

            <v-container fluid>
                <v-data-table
                    :headers="headers"
                    :items="itemUom"
                    :search="search"
                    :items-per-page="5"
                    :loading="loaded"
                    class="w-100"
                >

                <template v-slot:[`item.action`]="{ item }">
                    <v-btn text icon color="orange" v-if="userAccess.includes('uom_view')">
                        <v-icon class="btn-action" small @click="viewItemUom(item.id)">mdi-eye</v-icon>
                    </v-btn>
                    <v-btn text icon color="blue" v-if="userAccess.includes('uom_edit')">
                        <v-icon class="btn-action" small @click="editItemUom(item.id)">mdi-square-edit-outline</v-icon>
                    </v-btn>
                    <v-btn text icon color="red" v-if="userAccess.includes('uom_delete')">
                        <v-icon class="btn-action" small @click="deleteItemUom(item.id)">mdi-delete</v-icon>
                    </v-btn>
                </template>

                </v-data-table>
            </v-container>
        </v-card>
    </v-app>

</div>
</template>

<script>
import swal from 'sweetalert2';
import { mapGetters } from 'vuex';

export default {
    components: {

    },
    props: [
        'itemUomActionResult',
        'userAccess'
    ],
    data () {
        return {
            actionResult : {
                model : 'ItemUom',
                action : ''
            },
            loaded: false,
            itemUom: [],
            itemsPerPageOptions: [5, 10 ,15],
            itemsPerPage: 10,
            search: '',
            headers: [
                { text: 'Code', align: 'left', value: 'code', },
                { text: 'Name', align: 'left', value: 'name', },
                // { text: 'Description', align: 'left', value: 'description', },
                { text: 'Actions', align: 'center', value: 'action', sortable: false },
            ],

            json_data: [],
            json_meta: [
                [
                    {
                        'key': 'charset',
                        'value': 'utf-8'
                    }
                ]
            ],

        }
    },
    mounted() {
        this.getAllItemUom()
    },
    computed:{
        ...mapGetters([
            'GET_ALL_ITEM_UOM'
        ])
    },
    methods:{
        getAllItemUom(){
            this.loaded = true;
            this.itemUom = [];
            this.$store.dispatch('getAllItemUom').then(response => {
                this.itemUom = response.data
                this.loaded = false;
            })
        },
        viewItemUom(id){
            this.$emit('showEditModal', { action:'View', id:id });
        },
        editItemUom(id){
            this.$emit('showEditModal', { action:'Edit', id:id });
        },
        deleteItemUom(id){
            swal.fire({
                title: "",
                text: "Are you sure you want to Delete?",
                icon: 'question',
                showConfirmButton:true,
                confirmButtonText:'Confirm',
                showCancelButton:true,
                showCloseButton:true,
                confirmButtonColor: '397373',
                cancelButtonColor:'grey',
                reverseButtons:true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('destroyItemUom',id).then(response => {
                        this.getAllItemUom()
                        swal.fire('','Item Uom Deleted Successfully','success');
                    }).catch(error => {
                        swal.fire('','Item Uom Deleted Failed','error');
                    })
                }
            });
        },
    },
    watch: {
        itemUomActionResult: function(val){
            if(this.itemUomActionResult=='success'){
                this.getAllItemUom()
            }
        }
    }
};
</script>
