<template>
    <v-container class="page-wrapper ma-1">
        <v-row class="p-2">
            <v-col cols="8" class="d-flex no-block align-items-center">
                <h4 class="page-title">Tester Check Lists</h4>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" background-color="#424242" dark class="col-12" show-arrows>
                    <v-tab v-for="(items,index) in tabs" :key="index" @click="changeTab(index)">
                        {{items}}
                    </v-tab>
                </v-tabs>
                    <TesterCheckListComponent :tab="tab"></TesterCheckListComponent>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import TesterCheckListComponent from './Views/TesterCheckListsComponent.vue';
import { mapGetters } from 'vuex';
export default {
    components:{
        TesterCheckListComponent
    },
    data:()=>({
        tabs:[
            'Items',
            'Check List Template',
            'Check List',
        ],
        tab:0
    }),
    methods: {
        changeTab(index){
            this.$store.commit('TAB', index)
        }
    },
    computed: {
        ...mapGetters([
            'TESTER_CHECK_LISTS',
            'GET_ALL_LIST_TEMPLATE',
            'GET_REFRESH',
            'GET_TABS',
        ]),
    },

}
</script>

<style>

</style>
