<template>
    <v-container fluid>
      <h3 class="page-title">INBOUND OUTBOUND</h3>
      <v-container class="ma-0">
        <v-app id="item-tabs">
          <v-tabs
            v-model="tab"
            id="group-tab"
            color="cyan"
            slider-color="cyan"
            background-color="#424242"
            dark
            show-arrows
          >
            <v-tabs-slider color="cyan"></v-tabs-slider>
            <!-- <v-tab
            @click="tabs(0)"
              ripple>
              All
            </v-tab> -->
            <v-tab
              ripple
              v-for="(item, i) in STATUS.BOUND"
              :key="i"
              @click="tabs(item.value)"
              :disabled="!INBOUND_OUTBOUND_BUTTON_LOADING"
            >
              {{ item.text }}
            </v-tab>
          </v-tabs>
          <v-tabs-items >
            <keep-alive>
                <BoundComponent></BoundComponent>
            </keep-alive>
          </v-tabs-items>
        </v-app>
      </v-container>
      <DialogComponent
        :cp="components"
        :scrollable="dialog_scrollable"
        :width="width(dialog_width)"
        :retainfocus="false"
      ></DialogComponent>
  
    </v-container>
  </template>
  <script>
  import DialogComponent from "@/views/main/layouts/dialogs/Dialog.vue";
  import BoundComponent from "@/views/main/modules/Admin/Bound/BoundComponent.vue";
  import BoundViewComponent from "@/views/main/layouts/dialogs/Admin/BoundViewComponent.vue"
  import { mapGetters } from "vuex";
  export default {
    components: {
      DialogComponent,
      BoundComponent,
      BoundViewComponent
    },
    data() {
      return {
        form: {
          code: "",
          name: "",
          sub_class: [],
          sub_class_trash: [],
        },
        components: "",
        items: [
        //   { tab_name: "IN BOUND", view: true, },
        //   { tab_name: "OUT BOUND", view: true },
          // { tab_name: "FOR PAYMENT", view: true},
          // { tab_name: "APPROVED", view: true },
          // { tab_name: "PAID", view: true },
          // { tab_name: "PARTIAL", view: true },
        ],
        bound_tab_items: {
          components: BoundComponent,
          dialog: BoundViewComponent,
          dialog_title: "",
          dialog_width: "100%",
          scrollable: true,
          dispatch: {
            get: "getAllJO",
            add: "CreateJO",
            update: "UpdateJO",
            delete: "DeleteJO",
          },
        },
        dialog_width: "",
        loaded: false,
        options: {},
        itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
        itemsPerPage: 10,
        tab: 0,
        dialog_scrollable: true,
      };
    },
    mounted() {
      this.tabs(1);
      this.dropDowns();
      // this.items.push(...this.STATUS.ADMIN_JOB_ORDER_STATUS.map(status => {
      //       const temp = {
      //         tab_name: status.text,
      //         view: true,
      //       }
      //       return temp;
      //     }));
    },
    computed: {
      ...mapGetters([
              "STATUS",
              "SELECTED_TAB",
              "INBOUND_OUTBOUND_BUTTON_LOADING"
          ]),
    },
    methods: {
      dropDowns() {
        this.$store.dispatch('getInboundOutBoundType',{text:this.findBoundtypeStatus()?this.findBoundtypeStatus().text:''})
      },
      tabs(key) {
        this.components = this.bound_tab_items.dialog;
        // this.dialog_width = this.jo_tabs_items.dialog_width;
        // this.dialog_scrollable = this.jo_tabs_items.scrollable;
        // this.$store.commit("JO_DISPATCH_STATUS", this.$store.commit('SELECTED_TAB',this.STATUS.ADMIN_JOB_ORDER_STATUS[val].value));
        // this.$store.commit("JO_DISPATCH", this.jo_tabs_items.dispatch);
        this.$store.commit('SELECTED_TAB',key)
      },
      width(width) {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return "100%";
          case "sm":
            return "100%";
          case "md":
            return width;
          case "lg":
            return width;
          case "xl":
            return width;
        }
      },
      findBoundtypeStatus(){
          return this.STATUS.BOUND.find(e=>e.value == this.SELECTED_TAB)
      }
    },
    watch: {
  
  
    },
  };
  </script>
    <style>
  #group-tab {
    padding: 0 !important;
  }
  </style>
  