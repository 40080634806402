<template>
    <v-container fluid >
        <h3 class="page-title">ACCOUNTABILITY</h3>
        <v-container class="ma-0">
            <v-app id="item-tabs">
                <v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" background-color="#424242" dark show-arrows>
                    <v-tabs-slider color="cyan"></v-tabs-slider>
                    <v-tab ripple v-for="(item, i) in items" :key="item" @click="tabs(i)" :class="i == 2 ? 'ml-auto' : ''">
                        {{ item }}
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item  v-for="(item, i) in items" :key="i">
                        <keep-alive>
                            <component :is="assetsaccountability_tabs[i].components" :assetsaccountability_tabs="assetsaccountability_tabs" :isActive_tab="assetsaccountability_tabs[i].status_text">
                            </component>
                        </keep-alive>
                    </v-tab-item>
                </v-tabs-items>
            </v-app>
        </v-container>
            <DialogComponent :cp="components" :scrollable="dialog_scrollable" :width="width(dialog_width)" :retainfocus="false"></DialogComponent>
    </v-container>
</template>
<script>
import DialogComponent from '@/views/main/layouts/dialogs/Dialog.vue';
import AssetsAccountabilitysheetComponent from '@/views/main/modules/Admin/Accountability/AssetsAccountabilitysheetComponent.vue';
import AssetConditionComponent from '@/views/main/modules/Admin/AssetCondition/AssetConditionComponent.vue';
import AssetsAccountabilitysheetDialogComponent from '@/views/main/layouts/dialogs/Admin/AssetsAccountabilitysheetViewComponent.vue';
import AssetConditionViewComponent from '@/views/main/layouts/dialogs/Admin/AssetConditionViewComponent.vue';
import { mapGetters } from 'vuex';
export default {
    components:{
        DialogComponent,
        AssetsAccountabilitysheetDialogComponent,
        AssetsAccountabilitysheetComponent,
        AssetConditionComponent,
        AssetConditionViewComponent,
    },
    data() {
        return {
            form: {
                code: '',
                name: '',
                sub_class:[],
                sub_class_trash:[],
            },
            components:'',
            items: ["AS SUMMARY","UNACCOUNTED","CONDITION"],
            assetsaccountability_tabs: [
                {
                    components: AssetsAccountabilitysheetComponent,
                    status_text: "accountability",
                    dialog: AssetsAccountabilitysheetDialogComponent,
                    dialog_title:'Accountability',
                    dialog_width: "70%",
                    scrollable:true,
                    dispatch:{
                        get:'getAllAccountability',
                        add:'AdminCreateAccountability',
                        update:'AdminUpdateAccountability',
                        delete:'AdminDeleteAccountability'
                    },
                    dataTable:[
                        { text: "Accountability No.", align: "left", value: "accountability_num_display" },
                        { text: "Full Name", align: "left", value: "full_name" },
                        { text: "Location", align: "left", value: "location" },
                        { text: "Action", align: "center", value: "action",sortable:false},
                    ]
                },
                {
                    components: AssetsAccountabilitysheetComponent,
                    status_text: "unaccounted",
                    dialog: AssetsAccountabilitysheetDialogComponent,
                    dialog_title:'Accountability',
                    dialog_width: "70%",
                    scrollable:true,
                    dispatch:{
                        get:'getAllAdminAssets',
                        add:'AdminCreateAccountability',
                        update:'AdminUpdateAccountability',
                        delete:'AdminDeleteAccountability'
                    },
                    dataTable:[
                        { text: 'Picture', align: 'left',value:'image_path' },
                        { text: "AS NO.", align: "left", value: "asset_num" },
                        { text: "Description", align: "left", value: "description" },
                        { text: "Brand", align: "left", value: "brand_name" },
                        { text: "Classification", align: "center", value: "classification" },
                        { text: "Sub-Class", align: "center", value: "sub_classification" },
                        { text: "Asset Tagging", align: "center", value: "asset_code" },
                        { text: "Location", align: "center", value: "location_name" },
                        { text: "Department / Site", align: "left", value: "department_name" },
                        // { text: "Employee Name", align: "left", value: "full_name" },
                        { text: "Supplier", align: "left", value: "vendor_name" },
                        { text: "Amount", align: "left", value: "cost" },
                        { text: "Status", align: "left", value: "status_name" },
                        // { text: "Action", align: "center", value: "action",sortable:false},
                    ]
                },
                {
                    components: AssetConditionComponent,
                    // status_text: "unaccounted",
                    dialog: AssetConditionViewComponent,
                    dialog_title:'Asset Condition',
                    dialog_width: "500px",
                    scrollable:true,
                    dispatch:{
                        get:'adminAssetConditionGet',
                        add:'adminAssetConditionPost',
                        update:'adminAssetConditionPut',
                        delete:'adminAssetConditionDelete',
                    },
                    dataTable:[],
                },
            ],
            dialog_width: "",
            loaded: false,
            options: {},
            itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
            itemsPerPage: 10,
            tab: null,
            dialog_scrollable:true,
        };
    },
    computed:{
        ...mapGetters([
            'GET_NEW_ADMIN_OTHER_TABLE',
            'GET_NEW_ADMIN_ASSETS_TABLE'
        ])
    },
    mounted(){
        this.dropDowns()
        this.tabs(0);
    },
    methods:{
        dropDowns(){
            this.$store.dispatch('getDepartment')
            this.$store.dispatch("getAdminSupplierSelection");
            this.$store.dispatch("getAdminClassificationSelection");
            this.$store.dispatch("getAdminLocationSelection");
            this.$store.dispatch("getAdminBrandSelection");
            this.$store.dispatch("getAdminCategories");
            this.$store.dispatch("getStatus");
            // this.$store.dispatch("getEmployeeDetails");
            this.$store.dispatch("getAdminAssetConditionSelection");
        },
        tabs(key) {
            this.components = this.assetsaccountability_tabs[key].dialog;
            this.dialog_width = this.assetsaccountability_tabs[key].dialog_width;
            this.dialog_scrollable = this.assetsaccountability_tabs[key].scrollable;
            this.$store.commit('ADMIN_ITEMS_COLLECTIONS',[]);
            this.$store.commit('ADMIN_TAB_IS_ACTIVE',this.assetsaccountability_tabs[key].status_text)
            this.$store.commit('ADMIN_DATA_TABLE_HEADERS',this.assetsaccountability_tabs[key].dataTable)
            this.$store.commit('ADMIN_DISPATCH',this.assetsaccountability_tabs[key].dispatch)
        },
        width(width) {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return "100%"
                case 'sm': return "100%"
                case 'md': return width
                case 'lg': return width
                case 'xl': return width
            }
        },
    },
    watch:{}
};
</script>
<style>
#group-tab {
    padding: 0 !important;
}
</style>
