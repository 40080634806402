<template>
    <v-card style="border-radius: 0px;" class="ma-0 pa-0 h-100" flat>
        <v-card-title>
            <v-row class="mx-2 mb-1 d-flex justify-content-center align-items-center">
                <v-col >
                    <v-img src="@/assets/ingcoph_logo/ingcoph_logo.jpg" :aspect-ratio="16/9" width="200px" contain></v-img>
                </v-col>
                <v-col class="d-flex justify-content-center align-items-center">
                    <h3>{{ date | formatDateWithDay }}</h3>
                </v-col>
                <v-col  cols="4" class="ma-0 pa-0 d-flex justify-content-center align-items-center">
                    <!-- <v-row>
                        <v-col>
                            <v-switch
                                v-model="toggleView"
                                inset
                                color="primary"
                            >
                                <template v-slot:label>
                                    <v-icon large :color="toggleView ? 'primary' : 'gray'">
                                        {{url_meta.toggle_icon}}
                                    </v-icon>
                                </template>
                            </v-switch>
                        </v-col>
                    </v-row> -->
                    <v-dialog
                        ref="dialog"
                        v-model="modal"
                        :return-value.sync="date"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            class='ml-4'
                            outlined
                            dense
                            v-model="date"
                            label="Select Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="date"
                            scrollable
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="modal = false"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(date)"
                        >
                            OK
                        </v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text>
            <v-row class="mx-2 mb-2">
                <v-col class="text-center mx-1" v-for="(item,i,index) in items" :key="i">
                    <v-card elevation="5" class="ma-0 pa-2 rounded-lg text-center">
                        <v-card-text class="ma-0 pa-0">
                            <h3 style="color: black;">{{i}}</h3>
                        </v-card-text>
                        <v-divider class="ma-0 pa-0"></v-divider>
                        <v-card-text class="d-flex justify-content-center align-item-center">
                            <h1 style="color: black;" >{{ thousandSeprator(item) }}</h1>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="mx-2 mb-2">
                <v-col class="text-center mx-1">
                    <v-card elevation="5" class="ma-0 pa-2 rounded-lg text-center">
                        <v-card-title>
                            <h3>Pending Spare Parts</h3>
                        </v-card-title>
                        <v-data-table
                            :items="pen_table.items"
                            :headers="pen_table.headers"
                            :items-per-page="18"
                            style="font-size: large;"
                            dense
                            hide-default-header
                        >
                            <template v-slot:header="{ props: { headers } }">
                                <thead>
                                    <tr>
                                        <th v-for="h in headers" :key="h">
                                            <b><span style="color:black; font-size:22px">{{h.text}}</span></b>
                                        </th>
                                    </tr>
                                </thead>
                            </template>
                            <template v-slot:[`item.model`]="{ item }">
                                        <span style="font-weight: bold; font-size: 20px;">{{ item.model }}</span>
                                    </template>
                                    <template v-slot:[`item.name`]="{ item }">
                                        <span style="font-weight: bold; font-size: 20px;">{{ item.name }}</span>
                                    </template>
                                    <template v-slot:[`item.warehouse_name`]="{ item }">
                                        <span style="font-weight: bold; font-size: 20px;">{{ item.warehouse_name }}</span>
                                    </template>
                                    <template v-slot:[`item.requested_qty`]="{ item }">
                                        <span style="font-weight: bold; font-size: 20px;">{{ item.requested_qty }}</span>
                                    </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue'
import PusherFunctionComponent from '@/views/main/Shared/PusherFunctionComponent.vue';
export default {
    mixins: [SharedFunctionsComponentVue,PusherFunctionComponent],
    data(){
        return {
            items:[],
            modal:false,
            date:this.$dayjs().format('YYYY-MM-DD'),
            toggleView:true,
            url_meta:{
                status_color:this.$router.currentRoute.meta.status_color,
                statuses:this.$router.currentRoute.meta.statuses,
                queueing_type:this.$router.currentRoute.meta.title,
                url:this.$router.currentRoute.meta.url,
                realtime_event:this.$router.currentRoute.meta.realtime_event,
                toggle_icon:this.$router.currentRoute.meta.toggle_icon,
                type_id:this.$router.currentRoute.meta.type_id,
            },
            summations:['Total'],
            pen_table:{
                items:[],
                headers:[
                    { text: "Model", align: "left", value: "model",width: '15%' },
                    { text: "Name", align: "left", value: "name",width: '20%' },
                    // { text: "Warehouse", align: "left", value: "warehouse_name",width: '15%' },
                    { text: "Qty", align: "left", value: "requested_qty",width: '15%' },
                ]
            }
            // rf_status_text:['Customer Approvals','Waiting for Parts','For Diagnosis','Under Repair','For Dispatch and Delivery']
        }
    },
    mounted(){
        this.callPusher();
        this.getAllItems();
        this.ServerPusher();
        this.getPartsOrderTable();
    },
    methods:{
        async getAllItems(){
            await this.$store.dispatch('getqueue',{
                url:this.url_meta.url,
                date:this.date,
            }).then(response=>{
                this.items = response.data.data
            })
        },
        ServerPusher(){
            window.Echo.channel('service-channel').listen(this.url_meta.realtime_event,(e)=>{
                // this.items = e.data.data
                this.getAllItems();
            })
        },
        async getPartsOrderTable(){
            await this.$store.dispatch('getqueue',{
                url:'getPartsOrderAfterSalesDashboard',
                date:this.date,
            }).then(response=>{
                this.pen_table.items = response.data.pen_table
                let model_group = _.groupBy(this.pen_table.items,'model')
                let new_pen_table = []
                for(const prop in model_group ){
                    let a = _.sumBy(model_group[prop],'requested_qty');
                    new_pen_table.push({name:model_group[prop][0].name,model:model_group[prop][0].model,requested_qty:a})
                }
                this.pen_table.items = new_pen_table
            })
        }
    },
    watch:{
        date:{
            handler(){
                this.getAllItems();
            }
        },
    }
}
</script>

<style scoped>
.ul-listing {
    column-count: auto;
    column-gap: normal;
    column-fill: auto;
    height:77vh;
}
.large-spacing{
    column-width: 9.03rem;
}
.small-spacing{
    column-width: 8.5rem;
}
.toggle-list{
    max-height: 500px;
    column-count: auto;
    flex-flow: column wrap;
    list-style-type: disc;
    list-style-position: inside;
}
.upTextsize{
    font-size: 20px;
}

th{
    font-size: 100px;
}
</style>