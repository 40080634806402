<template>
      <div class="w-100">
        <v-card>
            <v-container class="w-100">
                <v-tabs
                    v-model="tab.main"
                    id="group-tab"
                >
                    <v-tab ripple>All</v-tab>
                    <v-tab ripple>Pending</v-tab>
                    <v-tab ripple>Cancelled</v-tab>
                    <v-tab ripple>Partially Returned</v-tab>
                    <v-tab ripple>Returned</v-tab>

                    <v-tabs-items v-model="tab.main" style="background-color:unset" class="">
                        <v-tab-item>
                            <ReturnedTableComponent :tab_status="-1"></ReturnedTableComponent>
                        </v-tab-item>

                        <v-tab-item>
                            <ReturnedTableComponent :tab_status="0"></ReturnedTableComponent>
                        </v-tab-item>

                        <v-tab-item>
                            <ReturnedTableComponent :tab_status="1"></ReturnedTableComponent>
                        </v-tab-item>

                        <v-tab-item>
                            <ReturnedTableComponent :tab_status="2"></ReturnedTableComponent>
                        </v-tab-item>

                        <v-tab-item>
                            <ReturnedTableComponent :tab_status="3"></ReturnedTableComponent>
                        </v-tab-item>
                    </v-tabs-items>
                </v-tabs>
            </v-container>
        </v-card>
      </div>
  </template>
  <script>
  import { computed } from "vue";
  import { mapGetters } from "vuex";
  import ReturnedTableComponent from "./ReturnedTableComponent.vue";
  export default {
    components: {
       ReturnedTableComponent,
    },
    data() {
      return {
        tab: {
            main: null,
        }
      };
    },
    mounted() {
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
        this.$store.dispatch('checkAccessUser',this.$router.currentRoute.params.id);
    },
    computed:{
        ...mapGetters([
            'ACCESS_SAMPLE_FORM'
        ]),
    },
    methods: {

    },
    watch:{

    }
  };
  </script>
  <style>

  </style>
