<template>
    <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
            <v-card-title class="d-flex justify-content-between pa-0">
                <h4 class="mx-3">{{ actions }} {{ GET_CHECKLIST_DIALOG_TITLE }}</h4>
                <v-btn text @click="closeDialog()" fab><v-icon>mdi-close-circle</v-icon></v-btn>
            </v-card-title>
            <v-divider/>

            <v-card-text>
                <v-row class="pl-3 pr-3">
                    <v-col lg="6">
                        <v-text-field 
                            v-model="checklist.fillables.name" 
                            label="Name" 
                            :readonly="disabled_view" 
                            outlined 
                            dense
                        >
                        </v-text-field>
                    </v-col>

                    <v-col lg="6">
                        <v-text-field 
                            v-model="checklist.fillables.code" 
                            label="Code" 
                            :readonly="disabled_view" 
                            outlined 
                            dense
                        >
                        </v-text-field>
                    </v-col>

                    <v-col lg="12">
                        <v-text-field 
                            v-model="checklist.fillables.description" 
                            label="Description" 
                            :readonly="disabled_view" 
                            outlined 
                            dense
                        >
                        </v-text-field>
                    </v-col>

                    <v-col lg="12">
                        <v-autocomplete
                            :items="GET_ALL_LIST_TEMPLATE"
                            v-if="ACTION.includes('Add')"
                            v-model="checklist.fillables.template_id"
                            item-value="id"
                            item-text="name"
                            label="Import Check List from another Template"
                            dense
                            outlined
                            @change="getTemplate()" 
                            :readonly="ACTION == 'View'"
                            clearable
                        >   
                        </v-autocomplete>
                    </v-col>

                    <v-col lg="12">
                        <v-autocomplete
                            v-if="!ACTION.includes('View')"
                            :items="GET_CHECK_LISTS_SELECTION"
                            v-model="checklist.check_list_templates"
                            item-value="id"
                            item-text="name"
                            label="Check List"
                            append-icon="mdi-plus"
                            outlined 
                            dense
                            multiple
                            :readonly="disabled_view"
                            small-chips
                            :disabled='ACTION == "Add"'
                            >
                            <template v-slot:selection="{ attrs, item, selected,index }">
                                <v-chip
                                    v-bind="attrs"
                                    :input-value="selected"
                                    :close="!disabled_view"
                                    @click:close="removeCheckList(index)"
                                    small
                                    dark
                                    color="#397373"
                                    >
                                    <strong>{{ item.name }}</strong>
                                </v-chip>
                            </template>
                        </v-autocomplete>
                    </v-col>

                    <v-col v-if="ACTION.includes('View') && checklist.check_list_templates?.length">
                        <v-card class="w-100" height="300" style="overflow-y: scroll;">
                            
                            <v-card-title>
                                <h6 class="mx-3"> Check Lists </h6>
                            </v-card-title>

                            <v-card-text>
                                <v-simple-table height="300px">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Code</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr 
                                            v-for="(check_list_template, index) in checklist.check_list_templates" 
                                            :key="index"
                                        >
                                            <td>
                                                <span>
                                                {{ check_list_template.name }}
                                                </span>
                                            </td>

                                            <td>
                                                <span>
                                                {{ check_list_template.code }}
                                                </span>
                                            </td>

                                            <td>
                                                <span>
                                                {{ check_list_template.description }}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions v-if="!ACTION.includes('View')" class="d-flex justify-content-end">
                <v-btn @click="actions == 'Add' ? submit() : update()" small><v-icon>mdi-plus</v-icon>{{ actions }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script>
import Swal from 'sweetalert2'
import { mapGetters } from 'vuex';
export default {
    data(){
        return{
			disabled_view: false,
			valid: false,
			form: {},
            checklist:{
                fillables:{
                    name:'',
                    description:'',
                    code:'',
                    template_id: '',
                },
                placeholder:'',
                loaded: true,
                template_lists: [],
                check_list_templates:[],
                check_list_trash:[]
            },
        }
    },

    computed:{
        ...mapGetters([
            'TESTER_CHECK_LISTS',
            'GET_ALL_LIST_TEMPLATE',
            'GET_REFRESH',
            'GET_TABS',
            'GET_CHECK_LIST',
            'GET_CHECKLIST_DIALOG_TITLE',
            'ACTION',
            'GET_CHECKLIST_DISPATCH',
			'GET_CHECKLIST_DIALOG_VIEW',
            'DIALOGS',
            'GET_CHECK_LISTS_SELECTION',
            
        ]),

        actions(){

            if(this.ACTION == 'Add'){
                this.disabled_view = false;

                return 'Add'
            } else if(this.ACTION == 'Edit'){
			    this.disabled_view = false;

                this.getTemplateData()
                return 'Edit'
            } else if(this.ACTION == 'View'){
			    this.disabled_view = true;

                this.getTemplateData()
                return 'View'
            } else {
                this.ACTION
            }
        }
    },

    mounted(){
        this.$store.dispatch('getListTemplate',{code:'SR'})
        this.$store.dispatch('getLists')
    },

    methods:{

        clearData(){
			this.checklist.fillables.name = '';
			this.checklist.fillables.code = '';
			this.checklist.fillables.description = '';
			this.checklist.fillables.template_id = '';
            this.checklist.check_list_templates =  '';
			this.checklist.check_list_trash = [];

		},

        getTemplateData(){
            this.checklist.fillables.id             = this.GET_CHECKLIST_DIALOG_VIEW.id
            this.checklist.fillables.name           = this.GET_CHECKLIST_DIALOG_VIEW.name
            this.checklist.fillables.code           = this.GET_CHECKLIST_DIALOG_VIEW.code
            this.checklist.fillables.description    = this.GET_CHECKLIST_DIALOG_VIEW.description
            this.checklist.fillables.template_id    = this.GET_CHECKLIST_DIALOG_VIEW.template_id
            this.checklist.check_list_templates     = this.GET_CHECKLIST_DIALOG_VIEW.check_list_templates
        },

        getTemplate(){
            this.checklist.template_lists           = this.GET_ALL_LIST_TEMPLATE.find(e=>{if(e.id == this.checklist.fillables.template_id){return e.check_list_templates}});
            this.checklist.fillables.template_id    = this.checklist.template_lists
            // console.log(this.GET_ALL_LIST_TEMPLATE.find(e=>e.id).name)
            this.checklist.check_list_templates       = this.checklist.template_lists.check_list_templates
        },

        closeDialog() {
            this.clearData()
			this.$store.commit("DIALOG", false);
            this.$store.commit('CHECKLIST_DIALOG_VIEW', {})
		},

        submit(){
            Swal.fire({
                title:'Add',
                text:'- Are you sure you want to Submit?',
                icon: 'question',
                showCancelButton:true
            }).then(action=>{
                if(action.isConfirmed){
                    this.$store.dispatch(this.GET_CHECKLIST_DISPATCH.add[0],{
                        url:this.GET_CHECKLIST_DISPATCH.add[1],
                        fillables: this.checklist.fillables,
                        template: this.checklist.check_list_templates,
                    }).then(response=>{
                        Swal.fire('Success!','','success')
                        this.closeDialog()
                        this.$store.commit('REFRESH', true)
                    }).catch(err=>{
                        Swal.fire('Error!',err.response.data.message,'error')
                    })
                }
            })
        },

        update(){
            Swal.fire({
                title:'Update',
                text:'- Are you sure you want to Update?',
                icon: 'question',
                showCancelButton:true
            }).then(action=>{
                if(action.isConfirmed){
                    this.$store.dispatch(this.GET_CHECKLIST_DISPATCH.update[0],{
                        url:this.GET_CHECKLIST_DISPATCH.update[1],
                        fillables: this.checklist.fillables,
                        id: this.GET_CHECKLIST_DIALOG_VIEW.id,
                        check_list: this.checklist.check_list_templates,
                        trash: this.checklist.check_list_trash
                    }).then(response=>{
                        Swal.fire('Success!','','success')
                        this.closeDialog()
                        this.$store.commit('REFRESH', true)
                    }).catch(err=>{
                        Swal.fire('Error!',err.response.data.message,'error')
                        this.closeDialog()
                    })
                }
            })
        },

        removeCheckList(index){
            this.checklist.check_list_trash.push(this.checklist.check_list_templates[index]['id']);
            this.checklist.check_list_templates.splice(index, 1);
        },

    },
    watch: {
        'checklist.check_list_templates':{
            handler(val){
            }
        },
        DIALOGS:{
            handler(val){
                // this.actions = val
            }
        },


    }
}
</script>

<style scoped>

</style>