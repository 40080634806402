<template>
  <v-container fluid>
    <ComponentHeader
      :button="true"
      :filterd="filters"
      :title="'Sales By Clusters'"
      :type="'Sales'"
    ></ComponentHeader>
    <ReportBodyComponent
      totals
      expand
      trigger
    >
      <template #first-row>
        <v-card-text>
          <v-row>
            <v-col lg="3">
              <v-autocomplete
                v-model="filters.salesman.selected"
                :items="GET_SALESMAN_SELECTION"
                :loading="loading.salesman_selection"
                :disabled="loading.salesman_selection"
                outlined
                dense
                clearable
                background-color="white"
                label="Select Salesman"
              ></v-autocomplete>
            </v-col>
            <v-col lg="3">
              <v-autocomplete
                v-model="filters.customer_clusters.selected"
                :items="GET_CLUSTER_SELECTION"
                :loading="loading.customer_cluster_selection"
                :disabled="loading.customer_cluster_selection"
                outlined
                dense
                clearable
                background-color="white"
                label="Select Cluster"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
      </template>

      <template #second-row>
        <v-card-text>
          <v-row class="d-flex flex-row justify-space-around">
            <v-col lg="3" class="mx-1">
              <label class="float-left control-label col-form-label">Date From</label>
              <v-menu
                v-model="filters.date_from_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="filters.date_from"
                    v-on="on"
                    readonly
                    append-icon="mdi-calendar-clock"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filters.date_from"
                  @input="filters.date_from_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col lg="3" class="mx-1">
              <label class="float-left control-label col-form-label">Date To</label>
              <v-menu
                  v-model="filters.date_to_menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="filters.date_to"
                    v-on="on"
                    readonly
                    append-icon="mdi-calendar-clock"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filters.date_to"
                  @input="filters.date_to_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-card-text>
      </template>
    </ReportBodyComponent>
  </v-container>
</template>

<script>
import ComponentHeader from "@/views/main/layouts/Reports/ReportsHeaderComponent.vue";
import ReportBodyComponent from '@/views/main/layouts/Reports/ReportsBodyComponent.vue'
import { mapGetters } from "vuex";
import getters from "@/store/getters";

export default {
  mixins: [
    getters
  ],
  components: {
    ComponentHeader,
    ReportBodyComponent,
    getters,
  },
  data() {
    return {
      errors: [],
      filters: {
        date_from: this.$dayjs().startOf("month").format("YYYY-MM-DD"),
        date_to: this.$dayjs().endOf("month").format("YYYY-MM-DD"),
        date_from_menu: false,
        date_to_menu: false,
        isResetField: '',
        salesman: {
          selected: [],
        },
        customer_clusters: {
          selected: [],
        },
      },
      headers: [
        { text: 'Cluster', align: 'left', value: 'cluster_name', },
        { text: '# of Customers', align: 'center', value: 'total_customers', },
        { text: 'Total Amount', align: 'right', value: 'total_amount', },
      ],
      expand_headers: [
        { text: 'customer', type:'string'},
        { text: 'salesman', type:'string'},
        { text: 'customer_category', type:'string'},
        { text: 'customer_classification', type:'string'},
        { text: 'cluster', type:'string'},
        { text: 'total_amount', type:'price'},
      ],
      loading: {
        salesman_selection: true,
        customer_cluster_selection: true,
      },
    }
  },
  mounted() {
    this.$store.dispatch('getUserCosting')
    this.$store.dispatch('getAllsalesmanSelection')
    this.$store.dispatch('getClusterSelection')
    this.getFilters()
    this.$store.commit('FILTER_VALUE', this.getFilters())
  },
  computed: {
    ...mapGetters([
      'GET_USER_COSTING',
      'GET_SALESMAN_SELECTION',
      'GET_CLUSTER_SELECTION',
    ]),
  },
  methods: {
    getFilters() {
      let data = {
        salesman_id: this.filters.salesman.selected,
        customer_cluster_id: this.filters.customer_clusters.selected,
        date_from: this.filters.date_from,
        date_to: this.filters.date_to,
        filename: 'Sales By Clusters',
        url: 'sales-by-clusters',
        expand_url:'clusters/getClusterCustomers/detail_id',
        exportToExcel: 1,
        headers: this.headers,
        expand_header: this.expand_headers,
      };
      return data;
    },
    resetFields(reset) {
      this.isResetField = reset
    },
    resetFieldInput() {
      this.filters.date_from = this.$dayjs().startOf('month').format('YYYY-MM-DD')
      this.filters.date_to = this.$dayjs().endOf('month').format('YYYY-MM-DD')
      this.filters.customers.selected = []
      this.filters.salesman.selected = []
    },
  },
  watch: {
    'filters': {
      handler() {
        this.$store.commit('FILTER_VALUE', this.getFilters())
      },
      deep: true
    },
    'isResetField': {
      handler() {
        if (this.isResetField == 0) {
          this.resetFieldInput()
        }
      },
      deep: true
    },
    'GET_SALESMAN_SELECTION': {
      handler(val) {
        this.loading.salesman_selection = false
      },
    },
    'GET_CLUSTER_SELECTION': {
      handler(val) {
        this.loading.customer_cluster_selection = false
      },
    },
  }
};
</script>
