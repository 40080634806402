<template>
    <v-container>
        <v-dialog v-model="credit_memo_type.dialog" width="30%" persistent>
            <v-card>
                <v-row class="m-0">
                    <v-col cols="11">
                        <v-card-title>
                            <span class="text-lg font-semibold" v-text="`Credit Memo Type Details`"></span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="1" class="text-right p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeViewDialog(); $emit('closeView');">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text class="textSize">
                        <span><b>Code: </b> {{ credit_memo_type.viewedCreditMemoType.code }} <br/></span>
                        <span><b>Name: </b>{{ credit_memo_type.viewedCreditMemoType.name }}<br/></span>
                        <span><b>Origin: </b>{{ credit_memo_type.viewedCreditMemoType.origin_name }}<br/></span>
                        <span><b>Status: </b> {{ credit_memo_type.viewedCreditMemoType.status_name }}<br/></span>
                        <span><b>Aftersales: </b> <span v-if="credit_memo_type.viewedCreditMemoType.service" class="badge badge-primary">Yes</span><span v-else class="badge badge-danger">No</span><br/></span>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    props:['viewCreditMemoTypeId'],
    data(){
        return {
            credit_memo_type:{
                dialog:false,
                viewedCreditMemoType:{
                    code:'',
                    name:'',
                    origin_name:'',
                    status_name: '',
                    service: '',
                }
            },
        }
    },
    methods:{
        closeViewDialog(){
            this.credit_memo_type.dialog = false
        }
    },
    watch:{
        viewCreditMemoTypeId:{
            handler(val){
                if(val != -1){
                    if(this.viewCreditMemoTypeId != null && this.viewCreditMemoTypeId > 0){
                        let payload = {
                            id:val,
                            url:'viewCreditMemoType'
                        }
                        this.$store.dispatch('getCreditMemoType',payload).then(response=>{
                            this.credit_memo_type.viewedCreditMemoType = response.data
                            this.credit_memo_type.dialog = true
                        })
                    }
                }
            }
        }
    }
}
</script>

<style>
    .textSize{
        font-size: 1rem;
    }
</style>
