<template>
    <div class="tabs-body-color">
        <v-container fluid class="pa-0">
            <v-row class="pt-4 px-3" v-if="userAccess.create">
                <v-col cols="12">
                    <div class="btn-toolbar pl-0">
                        <v-btn class="ml-1 mb-2" @click="AddDialog()">
                            <v-icon>mdi-plus</v-icon>Add
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="ml-1 mb-2"
                            :loading="GET_EXPORT_ITEMS"
                            @click="exportItems()"
                        >
                            <v-icon>mdi-file-export</v-icon>Export
                        </v-btn>
                        <!-- <v-btn
                            class="ml-1 mb-2"
                            :loading="GET_EXPORT_ITEMS_WITH_PRICE"
                            @click="exportItemsWithPrice()"
                        >
                            <v-icon>mdi-file-export</v-icon>Export With Price
                        </v-btn> -->
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <ItemsTableComponent v-if="GET_ADMIN_ITEM_STATUS == isActive_tab" :service="service"></ItemsTableComponent>
            </v-row>
        </v-container>
    </div>
</template>
<script>

import ItemsTableComponent from '@/views/main/modules/Admin/Items/ItemsTableComponent.vue';
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2';

export default {
    components: {
        ItemsTableComponent,
    },
    props:["isActive_tab", "service"],
    data() {
        return {
            userAccess:{
                create:false
            }
        }
    },
    computed: {
        ...mapGetters(['USER_ACCESS','GET_ADMIN_ITEM_STATUS','GET_EXPORT_ITEMS','GET_EXPORT_ITEMS_WITH_PRICE'])
    },
    mounted() {},
    methods: {
        AddDialog(){
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", "Submit");
            this.$store.dispatch('getAdminBrandSelection');
        },
        exportItems() {
            Swal.fire({
                title: 'Export Items With Price?',
                showConfirmButton: true,
                confirmButtonColor: '#397373',
                confirmButtonText: 'Yes',
                showDenyButton: true,
                denyButtonText: 'No',
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                reverseButtons: true,
                allowOutsideClick: false,
            }).then(result => {
                if (result.isConfirmed) {
                    this.$store.commit("EXPORT_ITEMS_WITH_PRICE", true);
                    return;
                }
                else if (result.isDenied) {
                    this.$store.commit("EXPORT_ITEMS", true);
                    return;
                }
            });
        },
    },
    watch:{
        USER_ACCESS:{
            handler(val){
                if(val != "fail"){
                    this.userAccess.create = false;
                    val.forEach(e => {
                        if (e.actions_code == 'create') {
                            this.userAccess.create = true;
                        }
                    });
                }
            }
        }
    }
};
</script>
