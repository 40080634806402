import SalesByClusters from '@/views/main/modules/Reports/SalesReports/SalesByClustersComponent.vue'
import SalesByCustomer from '@/views/main/modules/Reports/SalesReports/SalesByCustomerComponent.vue'
import SalesByCustomerDetailedComponent from '@/views/main/modules/Reports/SalesReports/SalesByCustomerDetailedComponent'
import SalesByCustomerPackingListComponent from '@/views/main/modules/Reports/SalesReports/SalesByCustomerPackingListComponent'
import SalesByItemsComponent from '@/views/main/modules/Reports/SalesReports/SalesByItemsComponent'
import TopPoorSellingComponent from '@/views/main/modules/Reports/SalesReports/TopPoorSellingComponent'
import PendingStockCardsByPLComponent from '@/views/main/modules/Reports/SalesReports/PendingStockCardsByPLComponent'
import CustomerPendingOrdersBySoComponent from '@/views/main/modules/Reports/SalesReports/CustomerPendingOrdersBySoComponent'
import CustomerPendingOrdersByItemComponent from '@/views/main/modules/Reports/SalesReports/CustomerPendingOrdersByItemComponent'
import PendingOrdersByItemComponent from '@/views/main/modules/Reports/SalesReports/PendingOrdersByItemComponent'
import CustomerSoldUnsolItemsComponent from '@/views/main/modules/Reports/SalesReports/CustomerSoldUnsolItemsComponent'
import NewItemsComponent from '@/views/main/modules/Reports/SalesReports/NewItemsComponent'
import SOConvertedToWPFComponent from '@/views/main/modules/Reports/SalesReports/SOConvertedToWPFComponent'
import SalesOrderPerItemReportComponent from '@/views/main/modules/Reports/SalesReports/SalesOrderPerItemReportComponent'
import BookedVsInvoicedOrdersComponent from '@/views/main/modules/Reports/SalesReports/BookedVsInvoicedOrdersComponent'
import ItemSalesDetailsComponent from '@/views/main/modules/Reports/SalesReports/ItemSalesDetailsComponent'
import ItemSalesDetailsCountComponent from '@/views/main/modules/Reports/SalesReports/ItemSalesDetailsCountComponent'
import SalesByRegionComponent from '@/views/main/modules/Reports/SalesReports/SalesByRegionComponent'
import SalesByCustomerDetailedComparisonComponent from '@/views/main/modules/Reports/SalesReports/SalesByCustomerDetailedComparisonComponent'
import PendingOrderStockAnalysisComponent from '@/views/main/modules/Reports/SalesReports/PendingOrderStockAnalysisComponent'
import SOMovementReportComponent from '@/views/main/modules/Reports/SalesReports/SOMovementReportComponent'
import SQMovementReportComponent from '@/views/main/modules/Reports/SalesReports/SQMovementReportComponent'
import PLMovementReportComponent from '@/views/main/modules/Reports/SalesReports/PLMovementReportComponent'

import repository from "@/api/credentials";

const routes = [{
    path: 'reports/sales/sales-by-customers/:id',
    name: 'sales-by-customers',
    component: SalesByCustomer,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Sales By Customer Report',
    }
},
{
    path: 'reports/sales/sales-by-customer-detailed/:id',
    name: 'sales-by-customer-detailed',
    component: SalesByCustomerDetailedComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Sales By Customer (Detailed) Report',
    }
},
{
    path: 'reports/sales/sales-by-customer-packing-lists/:id',
    name: 'sales-by-customer-packing-lists',
    component: SalesByCustomerPackingListComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Sales By Customer (PL) Report',
    }
},
{
    path: 'reports/sales/sales-by-items/:id',
    name: 'sales-by-items',
    component: SalesByItemsComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Sales By Item Report',
    }
},
{
    path: 'reports/sales/top-poor-selling/:id',
    name: 'top-poor-selling',
    component: TopPoorSellingComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Top/Poor Selling Report',
    }
},
{
    path: 'reports/sales/pending-stock-Card-by-pL/:id',
    name: 'pending-stock-Card-by-pL',
    component: PendingStockCardsByPLComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Pending SC By PL Report',
    }
},
{
    path: 'reports/sales/customer-pending-orders-by-so/:id',
    name: 'customer-pending-orders-by-so',
    component: CustomerPendingOrdersBySoComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Customer Pending Orders By SO Report',
    }
},
{
    path: 'reports/sales/customer-pending-orders-by-items/:id',
    name: 'customer-pending-orders-by-items',
    component: CustomerPendingOrdersByItemComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Customer Pending Orders By Item Report',
    }
},
{
    path: 'reports/sales/pending-orders-by-items/:id',
    name: 'pending-orders-by-items',
    component: PendingOrdersByItemComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Pending Orders By Item Report',
    }
},
{
    path: 'reports/sales/customer-sold-unsold/:id',
    name: 'customer-sold-unsold',
    component: CustomerSoldUnsolItemsComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Customer Sold/Unsold Item Report',
    }
},
{
    path: 'reports/sales/new-item/:id',
    name: 'new-item',
    component: NewItemsComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'New Item Report',
    }
},
{
    path: 'reports/sales/so-converted-to-wpf/:id',
    name: 'so-converted-to-wpf',
    component: SOConvertedToWPFComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'SO Converted To WPF Report',
    }
},
{
    path: 'reports/sales/sales-order-per-items/:id',
    name: 'sales-order-per-items',
    component: SalesOrderPerItemReportComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Sales Order Per Item Report',
    }
},
{
    path: 'reports/sales/booked-vs-invoiced-items/:id',
    name: 'booked-vs-invoiced-items',
    component: BookedVsInvoicedOrdersComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Booked vs Invoiced Orders Report',
    }
},
{
    path: 'reports/sales/item-sales-details/:id',
    name: 'item-sales-details',
    component: ItemSalesDetailsComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Item Sales Detail Report',
    }
},
{
    path: 'reports/sales/sales-by-region/:id',
    name: 'sales-by-region',
    component: SalesByRegionComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Sales By Region',
    }
},
{
    path: 'reports/sales/sales-by-customer-detailed-comparison/:id',
    name: 'sales-by-customer-detailed-comparison',
    component: SalesByCustomerDetailedComparisonComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Sales By Customer (Detailed) Report',
    }
},

{
    path: 'reports/sales/item-sales-details-count/:id',
    name: 'item-sales-details-count',
    component: ItemSalesDetailsCountComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Item Sales Detail Count Report',
    }
},
{
    path: 'reports/sales/sales-by-clusters/:id',
    name: 'sales-by-clusters',
    component: SalesByClusters,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Sales By Clusters Report',
    }
},

{
    path: 'reports/sales/pending-order-stock-analysis/:id',
    name: 'pending-order-stock-analysis',
    component: PendingOrderStockAnalysisComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Pending Order Stock Analysis Report',
    }
},
{
    path: 'reports/sales/so-movement-report/:id',
    name: 'so-movement-report',
    component: SOMovementReportComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'SO Movement Report',
    }
},
{
    path: 'reports/sales/sq-movement-report/:id',
    name: 'sq-movement-report',
    component: SQMovementReportComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'SQ Movement Report',
    }
},
{
    path: 'reports/sales/pl-movement-report/:id',
    name: 'pl-movement-report',
    component: PLMovementReportComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'PL Movement Report',
    }
},
]

export default routes;
