	<template>
		<v-container fluid >
			<h3 class="page-title">Overtime Management</h3>
			<v-container class="ma-0">
					<v-app id="item-tabs">
						<v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" background-color="#424242" dark show-arrows>
							<v-tabs-slider color="cyan"></v-tabs-slider>
								<v-tab ripple v-for="(item, i) in items" :key="i" @click="tabs(i)" v-if="tabAccess(item)">
									{{ item.tab_name }}
								</v-tab>
						</v-tabs>
						<v-tabs-items v-model="tab">
							<v-tab-item v-for="(table, index) in Overtime_component" :key="index" v-if="tabAccess(table)">
								<keep-alive>
									<component :is="table.components" :Overtime_component="Overtime_component" :isActive_tab="table.status_text">
									</component>
								</keep-alive>
							</v-tab-item>
						</v-tabs-items>
					</v-app>
			</v-container>
			<DialogComponent :cp="components" :scrollable="dialog_scrollable" :width="height(dialog_width)" :retain-focus="false"></DialogComponent>
		</v-container>
	</template>

<script>
	import DialogComponent from '@/views/main/layouts/dialogs/Dialog.vue';

	import HROvertimeComponent from '@/views/main/modules/HR/Overtime/HROvertimeComponent.vue'

	import HROvertimeViewComponent from '@/views/main/layouts/dialogs/HR/Overtime/HROvertimeViewComponent.vue'
	import HROvertimeConfirmComponent from '@/views/main/layouts/dialogs/HR/Overtime/HROvertimeViewConfirmComponent.vue'

	import { mapGetters } from 'vuex';
	import PusherFunctionComponent from '@/views/main/Shared/PusherFunctionComponent.vue';
	export default {
		mixins:[PusherFunctionComponent],
		components:{
			DialogComponent,
			HROvertimeComponent,
			HROvertimeViewComponent,
            HROvertimeConfirmComponent
		},
		data() {
			return {
				components:'',
				items: [
                    {tab_name: "Overtime List", access: "overtime_list"},
                    {tab_name: "For Confirm", access: "for_confirmation"},
                ],

				Overtime_component: [
						{
                            components: HROvertimeComponent,
                            access:'overtime_list',
                            status_text: "Overtime_list",
                            dialog: HROvertimeViewComponent,
                            dialog_title:'Overtime',
                            dialog_width: "100%",
                            scrollable:true,
                            dispatch:
                                {
                                    get:'getOvertimeRecord',
                                    add:'addEmployeeOvertime',
                                    update:'updateOvertimeRecord',
                                    delete:'deleteOvertimeRecord',
                                    show: 'showOvertimeRecord'
                                },
						},
						{
                            components: HROvertimeComponent,
                            access:'for_confirmation',
                            status_text: "for_confirmation",
                            dialog: HROvertimeConfirmComponent,
                            dialog_title:'Overtime',
                            dialog_width: "100%",
                            scrollable:true,
                            dispatch:
                                {
                                    get:'getOvertimeRecordHR',
                                    show:'showOvertimeRecordHR',
                                },
						},
				],
				dialog_width: "",
				loaded: false,
				options: {},
				itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
				itemsPerPage: 10,
				tab: null,
				dialog_scrollable:true,
			};
		},
    computed:{
        ...mapGetters(['USERACCOUNT_LOAD', 'USER_ACCESS'])
    },
    async mounted(){
        await this.$store.dispatch('checkAccessUser',this.$router.currentRoute.params.id);
		this.tabs(0)
    },
    methods:{
		tabs(key) {
			this.components = this.Overtime_component[key].dialog;
			this.dialog_width = this.Overtime_component[key].dialog_width;
			this.dialog_scrollable = this.Overtime_component[key].scrollable;

			this.$store.commit('HR_DIALOG_TITLE',this.Overtime_component[key].dialog_title)
			this.$store.commit('HR_TAB_IS_ACTIVE',this.Overtime_component[key].status_text)
			this.$store.commit('HR_DISPATCH',this.Overtime_component[key].dispatch)
			this.$store.commit('HR_GETTERS',this.Overtime_component[key].getters)
		},
		getSearchFromUrl() {
            if (Object.keys(this.$route.query).length != 0) {
                Object.keys(this.$route.query).forEach(e=>{
					if(e == 'for_approval'){
						this.tab = this.items.length - 1
						this.tabs(this.tab)
					}
                })
            }
        },
		height(width) {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs': return "100%"
				case 'sm': return "100%"
				case 'md': return width
				case 'lg': return width
				case 'xl': return width
			}
		},
        tabAccess(item){
            if(this.USER_ACCESS != "fail"){
                if(this.USER_ACCESS.find(e=>e.actions_code == item.access)){
                    if(this.USER_ACCESS.find(e=>e.actions_code == item.access).actions_code == item.access)
                    {
                        return true
                    }
                }
            }
        },
	},
	watch:{
		USERACCOUNT_LOAD:{
			handler(val){
			}
		}
	}

	};
	</script>
	<style>
	#group-tab {
		padding: 0 !important;
	}
	</style>
