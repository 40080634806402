<template>
  <v-container class="ma-0 pa-0">
    <v-data-table
      :headers="dataTable.headers"
      :items="dataTable.items"
      :server-items-length="dataTable.total"
      :options.sync="dataTable.options"
      :loading="loading"
      :footer-props="{
        itemsPerPageOptions: [10, 20, 50, 100],
      }"
      disable-sort
      @update:page="getAllCreditMemoType()"
      @update:items-per-page="getAllCreditMemoType()"
    >
      <template v-slot:[`item.service`]="{ item }">
        <span class="badge badge-primary" v-if="item.service">Yes</span>
        <span class="badge badge-danger" v-else>No</span>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <span class="text-nowrap">
          <v-btn text icon color="orange" v-if="userAccess.view_type">
            <v-icon
              class="btn-action"
              small
              @click="viewCreditMemoType(item.id)"
              >mdi-eye</v-icon
            >
          </v-btn>
          <v-btn text icon color="blue" v-if="userAccess.edit_type">
            <v-icon
              class="btn-action"
              small
              @click="editCreditMemoType(item.id)"
              >mdi-pencil</v-icon
            >
          </v-btn>
          <v-btn text icon color="red" v-if="userAccess.delete_type">
            <v-icon
              class="btn-action"
              small
              @click="deleteCreditMemoType(item.id)"
              >mdi-delete</v-icon
            >
          </v-btn>
        </span>
      </template>
    </v-data-table>
    <CreditMemoTypeViewComponent
      @closeView="closeView"
      :viewCreditMemoTypeId="dataTable.selected"
    ></CreditMemoTypeViewComponent>
  </v-container>
</template>

<script>
import swal from "sweetalert2";
import CreditMemoTypeViewComponent from "./CreditMemoTypeViewComponent.vue";
import { mapGetters } from "vuex";
export default {
  props: ["origin"],
  data() {
    return {
      dataTable: {
        headers: [
          { text: "CODE", value: "code" },
          { text: "NAME", value: "name" },
          { text: "STATUS", value: "status_name" },
          { text: "AFTERSALES", align: "center", value: "service" },
          { text: "ACTION", align: "center", value: "action" },
        ],
        items: [],
        total: 0,
        options: {},
        selected: 0,
      },
      loading: false,
      userAccess: {
        edit_type: false,
        delete_type: false,
        view_type: false,
      },
    };
  },
  components: {
    CreditMemoTypeViewComponent,
  },
  methods: {
    getAllCreditMemoType() {
      this.loading = true;
      this.dataTable.items = [];
      this.dataTable.total = 0;

      let payload = {
        url: "getAllCreditMemoType",
        origin: this.origin,
        page: this.dataTable.options.page,
        itemsPerPage: this.dataTable.options.itemsPerPage,
      };
      this.$store.dispatch("getCreditMemoType", payload).then((response) => {
        this.dataTable.items = response.data.data;
        this.dataTable.total = response.data.total;
        this.loading = false;
      });
    },
    viewCreditMemoType(id) {
      this.dataTable.selected = id;
    },
    closeView() {
      this.dataTable.selected = 0;
    },
    editCreditMemoType(id) {
      this.$emit("showEditDialog", id);
    },
    deleteCreditMemoType(id) {
      swal
        .fire({
          title: "",
          text: "Are you sure you want to Delete?",
          icon: "question",
          showConfirmButton: true,
          showCancelButton: true,
          reverseButtons: true,
          allowOutsideClick: false,
          confirmButtonColor: "#397373",
          cancelButtonColor: "grey",
          confirmButtonText: "Confirm",
          showCloseButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            let payload = {
              id: id,
              url: "deleteCreditMemoType",
            };
            this.$store
              .dispatch("postCreditMemoType", payload)
              .then((response) => {
                swal.fire(response.data.msg, "", response.data.icon);
                this.getAllCreditMemoType();
              });
          }
        });
    },
  },
  mounted() {
    this.getAllCreditMemoType();
  },
  computed: {
    ...mapGetters(["USER_ACCESS"]),
  },
  watch: {
    USER_ACCESS: {
      handler(val) {
        this.userAccess.view_type = false;
        this.userAccess.edit_type = false;
        this.userAccess.delete_type = false;
        if (val != "fail") {
          val.forEach((e) => {
            if (e.actions_code == "view_type") {
              this.userAccess.view_type = true;
            }
            if (e.actions_code == "edit_type") {
              this.userAccess.edit_type = true;
            }
            if (e.actions_code == "delete_type") {
              this.userAccess.delete_type = true;
            }
          });
        }
      },
    },
  },
};
</script>

<style></style>
