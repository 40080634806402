<template>
    <v-dialog :retain-focus="false" v-model="GET_COMPONENT" width="70%" persistent>
        <v-card class="overflow-auto" elevation="0">
            <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                <span class="headline">View Logistic Basket</span>
                <v-row class="m-0">
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                        text
                        icon
                        small
                        color="gray"
                        class="float-right"
                        @click="closeDialog()"
                        >
                        <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-card-text>
                <v-row class="m-0">
                    <v-col cols="12" sm="12" md="8" lg="8" xl="8" class="text-left text-dark">
                        <v-row class="ml-2">
                            <v-col cols="12" sm="12" md="8" lg="8" xl="8" class="p-0">
                                LB #: {{ GET_LOGISTIC_VIEW.series }}
                            </v-col>
                            <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="p-0">
                                Delivery Date: {{ this.delivery_date }}
                            </v-col>
                        </v-row>
                        <v-row class="ml-2">
                            <v-col cols="12" sm="12" md="8" lg="8" xl="8" class="p-0">
                                Vehicle: {{ GET_LOGISTIC_VIEW.vehicle_name }}
                            </v-col>
                            <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="p-0">
                                Target Total Amount: &#8369; {{ GET_LOGISTIC_VIEW.target_total_amount | currency }}
                            </v-col>
                        </v-row>
                        <v-row class="ml-2">
                            <v-col cols="12" sm="12" md="8" lg="8" xl="8" class="p-0">
                                Rush: {{ GET_LOGISTIC_VIEW.rush == 0 ? 'No' : 'Yes' }}
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row class="m-0">
                    <v-col v-if="this.dataTable.totalItems != 0"  :lg="this.stockCard.totalStockCard ? '6' : '12'">
                        <v-card class="px-4 py-2 mb-2" elevation="1">
                            <v-card-title class="m-0 p-0">
                                <span class="headline"> Sales Order </span>
                            </v-card-title>

                            <v-card-text>
                                <v-row class="m-0">
                                    <v-col>
                                        <v-data-table
                                            :items="dataTable.lbItem"
                                            :headers="dataTable.lbItemHeaders"
                                            :options.sync="dataTable.options"
                                            :loading="!dataTable.loaded"
                                            :server-items-length="dataTable.totalItems"
                                            dense
                                        >
                                            <template v-slot:[`item.total_amount`]="{ item }">
                                                <span>
                                                    &#x20b1; {{ item.total_amount | currency }}
                                                </span>
                                            </template>

                                            <template v-slot:[`item.status`]="{ item }">
                                                <span class="badge badge-saved" v-if="item.status == 0">{{ 'saved' }}</span>
                                                <span class="badge badge-for-sales" v-if="item.status == 1">{{ 'for sales' }}</span>
                                                <span class="badge badge-for-accounting" v-if="item.status == 2">{{ 'for accounting' }}</span>
                                                <span class="badge badge-approved" v-else-if="item.status == 3">{{ 'approved' }}</span>
                                                <span class="badge badge-completed" v-else-if="item.status == 4">{{ 'completed' }}</span>
                                                <span class="badge badge-warning" v-else-if="item.status == 5">{{ 'rejected' }}</span>
                                                <span class="badge badge-danger" v-else-if="item.status == 6">{{ 'canceled' }}</span>
                                                <span class="badge badge-dark" v-else-if="item.status == 7">{{ 'deleted' }}</span>
                                                <span class="badge badge-for-scheduling" v-else-if="item.status == 16">{{ 'for scheduling' }}</span>
                                                <span class="badge badge-purple" v-else-if="item.status == 8">{{ 'converted' }}</span>
                                                <span class="badge badge-exec-approved" v-else-if="item.status == 9">{{ 'exec approved' }}</span>
                                                <span class="badge badge-pending" v-else-if="item.status == 10">{{ 'partial' }}</span>
                                                <span class="badge badge-for-exec" v-else-if="item.status == 11">{{ 'for exec approval' }}</span>
                                                <span class="badge badge-warning" v-else-if="item.status == 12">{{ 'stockcard' }}</span>
                                                <span class="badge badge-submitted" v-else-if="item.status == 17">{{ 'waiting for stocks' }}</span>
                                                <span class="badge badge-preparing" v-else-if="item.status == 18">{{ 'preparing' }}</span>
                                                <span class="badge badge-prepared" v-else-if="item.status == 19">{{ 'prepared' }}</span>
                                                <span class="badge badge-checking" v-else-if="item.status == 20">{{ 'checking' }}</span>
                                                <span class="badge badge-checked" v-else-if="item.status == 21">{{ 'checked' }}</span>
                                                <span class="badge badge-invoiced" v-else-if="item.status == 22">{{ 'invoiced' }}</span>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col v-if="this.stockCard.totalStockCard != 0" :lg="this.dataTable.totalItems != 0 ? '6' : '12'">
                        <v-card class="px-4 py-2 mb-2" elevation="1">
                            <v-card-title class="m-0 p-0">
                                <span class="headline"> Stock Card </span>
                            </v-card-title>

                            <v-card-text>
                                <v-row class="m-0">
                                    <v-col>
                                        <v-data-table
                                            :items="stockCard.stockCardItem"
                                            :headers="stockCard.stockCardHeaders"
                                            :options.sync="stockCard.options"
                                            :loading="!stockCard.loaded"
                                            :server-items-length="stockCard.totalStockCard"
                                            dense
                                        >
                                            <template v-slot:[`item.status`]="{ item }">
                                                <span class="badge badge-preparing" v-if="item.status == 0">{{ 'preparing' }}</span>
                                                <span class="badge badge-primary" v-else-if="item.status == 1">{{ 'prepared' }}</span>
                                                <span class="badge badge-warning" v-else-if="item.status == 2">{{ 'rejected' }}</span>
                                                <span class="badge badge-danger" v-else-if="item.status == 3">{{ 'canceled' }}</span>
                                                <span class="badge badge-dark" v-else-if="item.status == 4">{{ 'deleted' }}</span>
                                                <span class="badge badge-success" v-else-if="item.status == 5">{{ 'completed' }}</span>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import swal from 'sweetalert2'
import { mapGetters } from 'vuex'
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";

export default {
    mixins: [SharedFunctionsComponentVue],
    components: {

    },
    props: [

    ],
    data() {
        return {
            delivery_date: null,
            dataTable: {
                lbItemHeaders: [
                    { text: 'SO#', sortable: false, value: 'order_num'},
                    { text: 'Total Amount', sortable: false, value: 'total_amount'},
                    { text: 'Total CBM', sortable: false, value: 'total_cbm'},
                    { text: 'Total Weight', sortable: false, value: 'total_weight'},
                    { text: 'Status', sortable: false, value: 'status'},
                ],
                lbItem: [],
                loaded: false,
                totalItems: 0,
                options: {},
            },
            stockCard: {
                stockCardHeaders: [
                    { text: 'SC#', sortable: false, value: 'stock_card_num'},
                    { text: 'SO#', sortable: false, value: 'order_num'},
                    { text: 'PL#', sortable: false, value: 'packing_list_num'},
                    { text: 'Status', sortable: false, value: 'status'},
                ],
                stockCardItem: [],
                loaded: false,
                options: {},
                totalStockCard: 0,
            },
        }
    },
    computed: {
        ...mapGetters([
            'GET_COMPONENT',
            'GET_LOGISTIC_VIEW',
        ])
    },

    methods: {
        getLogisticBasketItem() {
            this.dataTable.loaded = false;
            this.dataTable.lbItem = [];

            let payload = {
                itemsPerPage            : this.dataTable.options.itemsPerPage,
                page                    : this.dataTable.options.page,
                logistic_basket_id      : this.GET_LOGISTIC_VIEW.logistic_basket_id,
                url                     : 'order-delivery-schedule/getLogisticsItem'
            }
            this.$store.dispatch('getDelivery', payload).then(response => {
                this.dataTable.lbItem               = response.data.data.data;
                this.dataTable.totalItems           = response.data.data.total;
                this.dataTable.loaded               = true
            })
        },


        getLogisticBasketStockCard() {
            this.stockCard.loaded = false;
            this.stockCard.stockCardItem = [];

            let payload = {
                itemsPerPage            : this.stockCard.options.itemsPerPage,
                page                    : this.stockCard.options.page,
                logistic_basket_id      : this.GET_LOGISTIC_VIEW.logistic_basket_id,
                url                     : 'order-delivery-schedule/getLogisticStockCard'
            }
            this.$store.dispatch('getDelivery', payload).then(response => {
                this.stockCard.stockCardItem               = response.data.data.data;
                this.stockCard.totalStockCard              = response.data.data.total;
                this.stockCard.loaded                      = true
            })
        },

        closeDialog() {
            this.$store.commit('COMPONENT', false)
            this.$store.commit('LOGISTIC_VIEW', {})
        },
    },

    watch: {
        GET_LOGISTIC_VIEW: {
            handler(val) {
                this.delivery_date = this.$dayjs(val.delivery_date).format('YYYY-MM-DD, hh:mm:ss ')
                this.getLogisticBasketItem()
                this.getLogisticBasketStockCard()
            }
        },

        'dataTable.options': {
            handler(val) {
                if(!!val) {
                    this.getLogisticBasketItem()
                }
            },
        },

        'stockCard.options': {
            handler(val) {
                if(!!val) {
                    this.getLogisticBasketStockCard()
                }
            },
        },
    },

    mounted(){
        this.getLogisticBasketItem()
        this.getLogisticBasketStockCard()
        this.$store.commit('COMPONENT', false)
    },
}
</script>

<style>

</style>