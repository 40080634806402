<template>
    <div>
        <div class="page-wrapper ma-1">
            <div class="row p-2">
                <div class="col-12 d-flex no-block align-items-center">
                <h4 class="page-title">Parts Transfer</h4>
                </div>
            </div>
            <v-container fluid class="ma-0">
                <v-row>
                    <v-col lg="12">
                        <v-app id="item-tabs" class="ma-0">
                            <v-tabs v-model="tab" id="group-tab" color="cyan" dark slider-color="cyan" background-color="#424242" show-arrows >
                                <v-tab>Transfers</v-tab>
                                <v-tab v-if="userAccess.includes('pt_transmittal')">Transmittal</v-tab>
                                <v-tabs-items v-model="tab" touchless>
                                    <v-tab-item>
                                        <partsTransfersComponent :tab_name="'is_transfer'"></partsTransfersComponent>
                                    </v-tab-item>
                                    
                                    <v-tab-item>
                                        <partsTransfersComponent :tab_name="'is_transmittal'"></partsTransfersComponent>
                                    </v-tab-item>
                                </v-tabs-items>
                            </v-tabs>
                        </v-app>
                    </v-col>
                </v-row>
                <!-- <v-row>
                    <v-col lg="12">
                        <parts-transfers-component/>
                    </v-col>
                </v-row> -->
            </v-container>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PartsTransferTable from './PartsTransferTableComponent.vue';
import partsTransfersComponent from './PartsTransferComponent.vue';
export default {
    components:{
        PartsTransferTable,
        partsTransfersComponent
    },
    data() {
        return{
            tab:0,
            userAccess: []
        }
    },
    computed:{
        ...mapGetters([
            'PARTS_TRANSFER',
            'USER_ACCESS'
        ])
    },
    mounted(){
        this.$store.dispatch('checkAccessUser')
        this.tab = this.PARTS_TRANSFER.TAB
    },
    watch: {
        USER_ACCESS:{
            handler(val){
                if(val != "fail"){
                    this.userAccess = val.map(e=>e.actions_code);
                }
            }
        },
    }
}
</script>

<style>

</style>
