<template>
    <v-container class="ma-0">
            <!-- <v-col cols="6" class="d-flex no-block align-items-center">
                <div class="btn-toolbar">
                    <v-btn class="ml-1 mb-2" @click="AddDialog()">
                        <v-icon>mdi-plus</v-icon>Add Module
                    </v-btn>
                </div>
            </v-col> -->
            <v-app id="item-tabs">
                <v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" show-arrows>
                    <v-tabs-slider color="cyan"></v-tabs-slider>
                        <v-tab ripple v-for="(item, i) in items" :key="i" @click="tabs(i)">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }" >
                                    <span v-on="on"
                                        v-bind="attrs">
                                        {{ item.code }}
                                    </span>
                                </template>
                                <span>{{item.module_name}}</span>
                            </v-tooltip>
                        </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item v-for="(dept_module, index) in items" :key="index">
                        <EmailSettingsEditorComponent
                        :isActive_tab="dept_module.text"
                        :module_id="dept_module.id"
                        :module_html="dept_module.html"
                        :module_to="dept_module.to"
                        :module_cc="dept_module.cc"
                        :module_bcc="dept_module.bcc"
                        :module_email_message_id="dept_module.email_message_id"
                        :module_subject="dept_module.subject"
                        :module_with_attachment="dept_module.with_attachment"
                        />
                    </v-tab-item>
                </v-tabs-items>
            </v-app>
            <v-dialog v-model="new_module" max-width="30%" persistent>
                <v-card>
                    <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                    <span class="text-h5">Email Module</span>
                    <v-row class="m-0">
                        <v-col cols="pull-right-10 p-2">
                        <v-btn text icon small color="gray" class="float-right" @click="closeDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                        </v-col>
                    </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-container fluid class="py-5">
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        v-model="form.code"
                                        required
                                        label="Code"
                                        outlined
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        v-model="form.module_name"
                                        required
                                        label="Name"
                                        outlined
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        v-model="form.mailer_name"
                                        required
                                        label="Mailer Name"
                                        outlined
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="text-center">
                        <v-row class="ma-1">
                            <v-col cols="12">
                                <v-btn class="float-center" @click="addNewModule()">
                                    <span>Submit</span>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-dialog>
    </v-container>
</template>

<script>
	import DialogComponent from '@/views/main/layouts/dialogs/Dialog.vue';
    import EmailSettingsEditorComponent from '@/views/main/SystemSettings/Email/EmailSettingsEditorComponent.vue'
    import ManpowerRequestViewComponentVue from '@/views/main/layouts/dialogs/HR/RecruitmentSystem/ManpowerRequestViewComponent.vue';
	import ApplicantDataViewComponent from '@/views/main/layouts/dialogs/HR/RecruitmentSystem/ApplicantDataViewComponent.vue';


	import { mapGetters } from 'vuex';
import Swal from 'sweetalert2';
	export default {
        props:['isActive_tab'],
		components:{
			DialogComponent,
			ManpowerRequestViewComponentVue,
			ApplicantDataViewComponent,
            EmailSettingsEditorComponent
		},
		data() {
			return {
				components:'',
				items: [
                    // {tab_name:"Personnel Request", access:'personnel_request'},
                    // {tab_name:"Applicant List",access:'applicant_list'},
                    // {tab_name:"(SH) Applicant",access:'applicant_list'},
                    // {tab_name:"(REQ) For Approval", access:'req_for_approval'},
                    // {tab_name:"Privacy Settings", access:'privacy_settings'},
                    // {tab_name:"Email Message", access:'email_message'}
                ],
				dialog_width: "",
				loaded: false,
				options: {},
				itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
				itemsPerPage: 10,
				tab: null,
				dialog_scrollable:true,
                new_module: false,
                form:{}
			};
		},
		computed:{
			...mapGetters(['USERACCOUNT_LOAD','USER_ACCESS','GET_EMAIL_SETTINGS'])
		},
		mounted(){
            this.$store.dispatch('checkAccessUser',this.$router.currentRoute.params.id);
		},
		methods:{
        closeDialog(){
            this.new_module = false
        },
        AddDialog(){
            this.new_module = true
        },
        addNewModule(){
            this.form.department_id = this.isActive_tab
            this.$store.dispatch('addNewEmailModule',this.form).then((res)=>{
                this.new_module = false
                Swal.fire("Success","","success")
                let payload = {
                    department_id: this.isActive_tab
                }
                this.$store.dispatch('getEmailSettings',payload)

            }).catch((err)=>{
                Swal.fire("Network Error", "", "error")
                console.log(err)
            })
        },
		tabs(key) {
		},
		getSearchFromUrl() {
            if (Object.keys(this.$route.query).length != 0) {
                Object.keys(this.$route.query).forEach(e=>{
					if(e == 'for_approval'){
						this.tab = this.items.length - 1
						this.tabs(this.tab)
					}
                })
            }
        },
		height(width) {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs': return "100%"
				case 'sm': return "100%"
				case 'md': return width
				case 'lg': return width
				case 'xl': return width
			}
		},
        tabAccess(item){
            if(this.USER_ACCESS != "fail"){
                if(this.USER_ACCESS.find(e=>e.actions_code == item.access)){
                    if(this.USER_ACCESS.find(e=>e.actions_code == item.access).actions_code == item.access)
                    {
                        return true
                    }
                }
            }
        }
	},
	watch:{
		USERACCOUNT_LOAD:{
			handler(val){
			}
		},
        GET_EMAIL_SETTINGS:{
            handler(val){
                this.items = val;
            }
        }
	}

	};
	</script>
	<style>
	#group-tab {
		padding: 0 !important;
	}
	</style>

