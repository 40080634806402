<template>
    <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
            <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                <span class="headline">{{ ACTION }} Payment Transmittal</span>
                <v-row class="m-0">
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                        text
                        icon
                        small
                        color="gray"
                        class="float-right"
                        @click="closeDialog()"
                        >
                        <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-container v-if="ACTION == 'View'">
                <v-layout row class="d-flex justify-space-between mb-4 px-6">
                    <v-flex class="pa-1">
                        <div>Payment Transmittal#: <span style="color:#000000">{{ GET_PAYMENT_TRANSMITTAL_VIEW.payment_transmittal_num }}</span></div>
                        <div>Date Transmitted: <span style="color:#000000">{{ formatDateandTime(GET_PAYMENT_TRANSMITTAL_VIEW.date_transmitted) }}</span></div>
                    </v-flex>
                    <v-flex>
                        <div>Date From: <span style="color:#000000">{{ GET_PAYMENT_TRANSMITTAL_VIEW.date_from | formatDate }}</span></div>
                        <div>Date To: <span style="color:#000000">{{ GET_PAYMENT_TRANSMITTAL_VIEW.date_to | formatDate }}</span></div>
                    </v-flex>
                    <v-flex v-if="ACTION == 'View'" class="d-flex justify-end pa-1">
                        <v-btn :loading="loadingButton" @click="renderPDF('print')">
                            Print
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-container>


            <v-row no-gutters>
                <v-col cols="10">
                    <v-row no-gutters>
                        <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="px-2">
                            <v-card
                                class="total-cards"
                                elevation="2"
                            >
                                <v-card-text
                                    class="d-flex justify-content-start align-items-center">
                                    <div class="card-icon pt-3 pb-3 pl-2 pr-2 rounded" style="background-color: #28a745;">
                                        <v-icon class="text-white" style="font-size:40px">mdi-cash-multiple</v-icon>
                                    </div>
                                    <div class="card-details ml-2">
                                        <p class="mb-0">Total Gross Amount: </p>
                                        <b class="pt-4" style="color:black">{{ totalGrossAmount | currency}}</b>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="px-2">
                            <v-card
                                class="total-cards"
                                elevation="2"
                            >
                                <v-card-text
                                    class="d-flex justify-content-start align-items-center">
                                    <div class="card-icon pt-3 pb-3 pl-2 pr-2 rounded" style="background-color: #28a745;">
                                        <v-icon class="text-white" style="font-size:40px">mdi-cash-multiple</v-icon>
                                    </div>
                                    <div class="card-details ml-2">
                                        <p class="mb-0">Total Net Amount: </p>
                                        <b class="pt-4" style="color:black">{{ totalNetAmount | currency}}</b>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="px-2">
                            <v-card
                                class="total-cards"
                                elevation="2"
                            >
                                <v-card-text
                                    class="d-flex justify-content-start align-items-center">
                                    <div class="card-icon pt-3 pb-3 pl-2 pr-2 rounded" style="background-color: #28a745;">
                                        <v-icon class="text-white" style="font-size:40px">mdi-cash-multiple</v-icon>
                                    </div>
                                    <div class="card-details ml-2">
                                        <v-tooltip top color="#F69F1A">
                                            <template v-slot:activator="{ on, attrs }">
                                                <span
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    style="cursor:pointer"
                                                >
                                                Total Rem. Amount:
                                                </span>
                                            </template>
                                            <span>TOTAL REMAINING AMOUNT</span>
                                        </v-tooltip>
                                        <b class="pt-4" style="color:black">{{ totalRemainingAmount | currency}}</b>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="2">
                    <v-row class="w-100 mb-4">
                        <v-col v-if="ACTION == 'Add' || ACTION == 'Edit'" cols="12" class="d-flex justify-end pa-3">
                            <v-btn class="mt-2" @click="dialog('open')">
                                <v-icon>mdi-plus</v-icon> Select Payment
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row class="w-100">
                        <v-col v-if="ACTION == 'Edit'" cols="12" >
                            <v-text-field
                                v-model="filters.searchSelected"
                                label="Search Reference#"
                                class="mt-1 mr-2"
                                dense
                                append-icon="mdi-magnify"
                                single-line
                                hide-details
                                @keyup="searchSelected"
                                >
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-col>

            </v-row>

            <v-card-text v-if="ACTION == 'Add' || ACTION == 'View' || ACTION == 'Edit'">
                <v-card elevation="0">
                    <v-card-text max-height="500px">
                        <v-col v-if="viewTable.loading" lg="12" class="d-flex justify-content-center align-items-center">
                            <LoadingComponentVue class="my-2"></LoadingComponentVue>
                        </v-col>
                        <v-data-table
                            v-if="selected.length > 0"
                            :items="selected"
                            :headers="viewTable.viewHeaders"
                            :options.sync="viewTable.options"
                            :loading="viewTable.loading"
                            :footer-props="{
                                itemsPerPageOptions: itemsPerPageOptions,
                            }"
                            disable-sort
                            dense
                        >

                            <template v-slot:[`item.status`]="{ item }">
                                <span class="badge white--text bg-secondary" v-if="item.status==0">saved</span>
                                <span class="badge white--text bg-info" v-else-if="item.status==1">submitted</span>
                                <span class="badge white--text bg-purple" v-else-if="item.status==2">approved</span>
                                <span class="badge white--text bg-danger" v-if="item.status==3">rejected</span>
                                <span class="badge white--text bg-dark" v-else-if="item.status==4">returned</span>
                                <span class="badge white--text bg-dark" v-else-if="item.status==5">bounced</span>
                                <span class="badge white--text bg-danger" v-else-if="item.status==6">cancelled</span>
                                <span class="badge white--text bg-primary" v-else-if="item.status==7">confirmed</span>
                                <span class="badge bg-warning" v-else-if="item.status==8">exhausted</span>
                                <span class="badge white--text bg-success" v-else-if="item.status==9">allocated</span>
                                <span class="badge badge-warning" v-else-if="item.status == 10">partial</span>
                                <span class="badge white--text badge-info" v-else-if="item.status == 11">for confirmation</span>
                            </template>

                            <template v-slot:[`item.gross_amount`]="{ item }">
                                &#x20b1;{{ item.gross_amount | currency }}
                            </template>

                            <template v-slot:[`item.gross_amount`]="{ item }">
                                &#x20b1;{{ item.gross_amount | currency }}
                            </template>

                            <template v-slot:[`item.wht_amount`]="{ item }">
                                &#x20b1;{{ item.wht_amount | currency }}
                            </template>

                            <template v-slot:[`item.other_adj_amount`]="{ item }">
                                &#x20b1;{{ item.other_adj_amount | currency }}
                            </template>

                            <template v-slot:[`item.net_amount`]="{ item }">
                                &#x20b1;{{ item.net_amount | currency }}
                            </template>

                            <template v-slot:[`item.total_amount`]="{ item }">
                                &#x20b1;{{ item.total_amount | currency }}
                            </template>

                            <template v-slot:[`item.remaining_amount`]="{ item }">
                                &#x20b1;{{ item.remaining_amount | currency }}
                            </template>

                            <template v-slot:[`item.allocated_amount`]="{ item }">
                                &#x20b1;{{ item.allocated_amount | currency }}
                            </template>

                            <template v-if="ACTION != 'View'" v-slot:[`item.action`]="{ item, index }">
                                <v-btn icon dark small color="red" class="ml-1"
                                    style="height: 20px; width: 20px" @click="removeItem(index)">
                                    <v-icon>
                                        mdi-minus-circle
                                    </v-icon>
                                </v-btn>
                            </template>

                            <template v-slot:[`item.payment_type_name`]="{ item }">
                                <span class="badge white--text bg-success" v-if="item.payment_type_name=='Cash'">{{ item.payment_type_name }}</span>
                                <span class="badge white--text  bg-bluish-gray " v-else-if="item.payment_type_name=='Cheque'">{{ item.payment_type_name }}</span>
                                <span class="badge bg-warning" v-else-if="item.payment_type_name=='Deposit'">{{ item.payment_type_name }}</span>
                                <span class="badge white--text  bg-info " v-else-if="item.payment_type_name=='Online'">{{ item.payment_type_name }}</span>
                                <span class="badge white--text  bg-info " v-else-if="item.payment_type_name=='Gcash'">{{ item.payment_type_name }}</span>
                                <span class="badge bg-primary" v-else-if="item.payment_type_name=='Credit/Debit Card'">{{ item.payment_type_name }}</span>
                                <span class="badge bg-dark text-white" v-else-if="item.payment_type_name=='Journal Voucher'">{{ item.payment_type_name }}</span>
                            </template>
                        </v-data-table>

                        <v-row v-if="selected.length == 0 && viewTable.loading == false" align="center" justify="center" class="text-center" style="height: 90%; padding-top: 50px;">
                            <v-col cols="12">
                                <h5 class="text-h1">NO DATA AVAILABLE</h5>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-card-text>

            <v-dialog v-model="paymentDialog" persistent max-width="95%">
                <v-card>
                    <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                        <span class="headline">{{ ACTION == 'Edit' ? 'Edit Selected' : 'Select' }} Payment</span>
                        <v-row class="m-0">
                            <v-col cols="pull-right-10 p-2">
                                <v-btn
                                text
                                icon
                                small
                                color="gray"
                                class="float-right"
                                @click="dialog('close')"
                                >
                                <v-icon>mdi-close-circle</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>

                    <v-card-text>
                        <v-card elevation="0">
                            <v-app v-if="ACTION != 'View'" class="pa-1 px-5">
                                <v-row dense class="my-2">
                                    <v-col sm="12" md="4">
                                        <v-autocomplete
                                            v-model="filters.customers"
                                            :items="GET_CUSTOMERS_SELECTION"
                                            :loading="!GET_CUSTOMERS_SELECTION"
                                            :disabled="!GET_CUSTOMERS_SELECTION"
                                            label="Select Customers"
                                            class="d-inline-block w-100"
                                            outlined
                                            dense
                                            multiple
                                            background-color="white"
                                            small-chips
                                            hide-details
                                            clearable
                                            deletable-chips
                                            @change="onSelectCustomers"
                                            >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col sm="12" md="2">
                                        <v-autocomplete
                                            :items="payments.selection"
                                            v-model="payments.selected"
                                            dense
                                            outlined
                                            item-value="id"
                                            item-text="name"
                                            label="Payment Type"
                                            background-color="white"
                                            multiple
                                            small-chips
                                            hide-details
                                            clearable
                                            deletable-chips
                                        />
                                    </v-col>
                                    <v-col sm="12" md="2">
                                            <v-autocomplete
                                                :items="banks.selection"
                                                v-model="banks.selected"
                                                dense
                                                outlined
                                                item-value="id"
                                                item-text="name"
                                                label="Banks"
                                                background-color="white"
                                                multiple
                                                small-chips
                                                hide-details
                                                clearable
                                                deletable-chips
                                            />
                                        </v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col sm="12" md="2" class="d-flex flex-row">
                                        <v-menu
                                                v-model="menu.date_from"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        v-model="filters.date_from"
                                                        prepend-inner-icon="mdi-calendar"
                                                        outlined
                                                        dense
                                                        readonly
                                                        v-on="on"
                                                        style="display: block"
                                                        background-color="white"
                                                        label="Date From"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                v-model="filters.date_from"
                                                @click:date="menu.date_from = false"
                                                >
                                                </v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col sm="12" md="2" class="d-flex flex-row">
                                            <v-menu
                                                v-model="menu.date_to"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        v-model="filters.date_to"
                                                        prepend-inner-icon="mdi-calendar"
                                                        outlined
                                                        dense
                                                        readonly
                                                        v-on="on"
                                                        style="display: block"
                                                        background-color="white"
                                                        label="Date To"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                v-model="filters.date_to"
                                                @click:date="menu.date_to = false"
                                                >
                                                </v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col sm="12" md="2" class="d-flex flex-row">
                                            <v-autocomplete
                                                :items="collection_types.selection"
                                                v-model="collection_types.selected"
                                                dense
                                                outlined
                                                label="Collection Type"
                                                background-color="white"
                                                hide-details
                                                clearable
                                            />
                                        </v-col>
                                        <v-col sm="12" md="2">
                                            <v-btn @click="getCollectionReport()">Filter</v-btn>
                                        </v-col>
                                        <v-spacer />
                                        <v-col cols="2" class="text-right">
                                            <v-text-field
                                                label="Search Reference#"
                                                v-model="filters.search"
                                                append-icon="mdi-magnify"
                                                @click:append="getCollectionReport()"
                                                @keypress.enter="getCollectionReport()"
                                                clearable
                                                style="width: 300px"
                                                >
                                            </v-text-field>
                                        </v-col>
                                </v-row>

                                <v-row dense>
                                    <v-checkbox
                                        v-model="selectAllItems"
                                        label="Select All"
                                        :true-value="true"
                                        :false-value="false"
                                    >
                                    </v-checkbox>
                                </v-row>
                            </v-app>

                            <v-card-text style="overflow-y: auto; max-height: 500px">
                                <v-data-table
                                    v-model="selectedTable"
                                    :items="all_collections"
                                    :headers="dataTable.headers"
                                    :loading="dataTable.loading"
                                    :options.sync="dataTable.options"
                                    :server-items-length="dataTable.totalItems"
                                    :footer-props="{
                                        itemsPerPageOptions: itemsPerPageOptions,
                                    }"
                                    disable-sort
                                    item-key="customer_payment_detail_id"
                                    dense
                                    :expanded.sync="expanded"
                                    show-expand
                                    :show-select="ACTION != 'View'"
                                    @item-selected="checkIfSelected"
                                >
                                    <template v-slot:header.data-table-select="{ header }">
                                        <v-simple-checkbox style="display:none"></v-simple-checkbox>
                                    </template>

                                    <template v-slot:[`item.gross_amount`]="{ item }">
                                        <span class="text-nowrap">
                                            &#x20b1;{{ item.gross_amount | currency }}
                                        </span>
                                    </template>

                                    <template v-slot:[`item.amount`]="{ item }">
                                        <span class="text-nowrap">
                                            &#x20b1;{{ item.amount | currency }}
                                        </span>
                                    </template>

                                    <template v-slot:[`item.age`]="{ item }">
                                        <span v-if="item.age < 0" class="badge bg-danger text-white">{{ item.age }}</span>
                                        <span v-else-if="item.age == 0" class="badge bg-secondary text-white">{{ item.age }}</span>
                                        <span v-else-if="item.age > 0" class="badge bg-success text-white">{{ item.age }}</span>
                                    </template>

                                    <template v-slot:expanded-item="{ headers, item }">
                                        <td :colspan="headers.length" class="p-0">
                                            <v-data-table
                                                v-model="selected"
                                                :items="item.items"
                                                :headers="tableItem.headers"
                                                :options.sync="tableItem.options"
                                                :loading="tableItem.loading"
                                                :footer-props="{
                                                    itemsPerPageOptions: itemsPerPageOptions,
                                                }"
                                                :show-select="true"
                                                disable-sort
                                                dense
                                                dark
                                            >

                                                <template v-slot:header.data-table-select="{ header }">
                                                    <v-simple-checkbox style="display:none"></v-simple-checkbox>
                                                </template>

                                                <template v-slot:[`item.status`]="{ item }">
                                                    <span class="badge white--text bg-secondary" v-if="item.status==0">saved</span>
                                                    <span class="badge white--text bg-info" v-else-if="item.status==1">submitted</span>
                                                    <span class="badge white--text bg-purple" v-else-if="item.status==2">approved</span>
                                                    <span class="badge white--text bg-danger" v-if="item.status==3">rejected</span>
                                                    <span class="badge white--text bg-dark" v-else-if="item.status==4">returned</span>
                                                    <span class="badge white--text bg-dark" v-else-if="item.status==5">bounced</span>
                                                    <span class="badge white--text bg-danger" v-else-if="item.status==6">cancelled</span>
                                                    <span class="badge white--text bg-primary" v-else-if="item.status==7">confirmed</span>
                                                    <span class="badge bg-warning" v-else-if="item.status==8">exhausted</span>
                                                    <span class="badge white--text bg-success" v-else-if="item.status==9">allocated</span>
                                                    <span class="badge badge-warning" v-else-if="item.status == 10">partial</span>
                                                    <span class="badge white--text badge-info" v-else-if="item.status == 11">for confirmation</span>
                                                </template>

                                                <template v-slot:[`item.gross_amount`]="{ item }">
                                                    &#x20b1;{{ item.gross_amount | currency }}
                                                </template>

                                                <template v-slot:[`item.wht_amount`]="{ item }">
                                                    &#x20b1;{{ item.wht_amount | currency }}
                                                </template>

                                                <template v-slot:[`item.other_adj_amount`]="{ item }">
                                                    &#x20b1;{{ item.other_adj_amount | currency }}
                                                </template>

                                                <template v-slot:[`item.net_amount`]="{ item }">
                                                    &#x20b1;{{ item.net_amount | currency }}
                                                </template>

                                                <template v-slot:[`item.total_amount`]="{ item }">
                                                    &#x20b1;{{ item.total_amount | currency }}
                                                </template>

                                                <template v-slot:[`item.remaining_amount`]="{ item }">
                                                    &#x20b1;{{ item.remaining_amount | currency }}
                                                </template>

                                                <template v-slot:[`item.allocated_amount`]="{ item }">
                                                    &#x20b1;{{ item.allocated_amount | currency }}
                                                </template>

                                                <template v-slot:[`item.payment_type_name`]="{ item }">
                                                    <span class="badge white--text bg-success" v-if="item.payment_type_name=='Cash'">{{ item.payment_type_name }}</span>
                                                    <span class="badge white--text  bg-bluish-gray " v-else-if="item.payment_type_name=='Cheque'">{{ item.payment_type_name }}</span>
                                                    <span class="badge bg-warning" v-else-if="item.payment_type_name=='Deposit'">{{ item.payment_type_name }}</span>
                                                    <span class="badge white--text  bg-info " v-else-if="item.payment_type_name=='Online'">{{ item.payment_type_name }}</span>
                                                    <span class="badge white--text  bg-info " v-else-if="item.payment_type_name=='Gcash'">{{ item.payment_type_name }}</span>
                                                    <span class="badge bg-primary" v-else-if="item.payment_type_name=='Credit/Debit Card'">{{ item.payment_type_name }}</span>
                                                    <span class="badge bg-dark text-white" v-else-if="item.payment_type_name=='Journal Voucher'">{{ item.payment_type_name }}</span>
                                                </template>

                                                <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
                                                    <v-simple-checkbox
                                                        :value="isSelected"
                                                        @input="select($event)"
                                                        :disabled="ACTION == 'View'"
                                                        >
                                                    </v-simple-checkbox>
                                                </template>

                                                <template v-slot:[`item.action`]="{ item }">
                                                    <v-btn text icon color="orange">
                                                        <v-icon class="btn-action" small @click="viewCustomerPayment(item.id)">mdi-eye</v-icon>
                                                    </v-btn>
                                                </template>
                                            </v-data-table>
                                        </td>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-card-actions>
                <v-row class="ma-0 pa-0">
                    <v-col class="d-flex justify-content-center align-item-center">
                        <v-container class="text-center">
                            <v-btn v-if="ACTION == 'Add'" :loading="loadingButton" :disabled="disableButton" @click="submitPaymentTransmittal()">
                                Submit
                            </v-btn>

                            <v-btn v-if="ACTION == 'View' && GET_PAYMENT_TRANSMITTAL_VIEW.status == 'saved'" :loading="loadingButton" @click="updateConfirm()">
                                Confirm
                            </v-btn>

                            <v-btn v-if="ACTION == 'Edit'" :loading="loadingButton" @click="updatePaymentTransmittal()">
                                Update
                            </v-btn>
                        </v-container>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
            <div style="display:none;">
                <table id="cash_denominations">
                    <thead>
                        <tr>
                            <th>DENOM</th>
                            <th>QTY</th>
                            <th>AMOUNT</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(breakdown) in cashDenominationBreakdown" :key="breakdown.code">
                            <td>{{ breakdown.value | currency}}</td>
                            <td>{{ breakdown.quantity}} </td>
                            <td>{{ breakdown.amount | currency }}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td>PHP {{ this.collection_total_amount | currency }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style="display:none;">
                <table id="sub_total_details">
                    <thead>
                        <tr>
                            <td colspan="3"> TOTAL </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>CASH</td>
                            <td>OTHERS</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>SUB TOTAL: </td>
                            <td>PHP {{ this.collection_total_amount | currency }}</td>
                            <td>PHP {{ this.collection_total_gross_amount | currency}}</td>
                        </tr>
                        <tr>
                            <td>TOTAL AMOUNT: </td>
                            <td></td>
                            <td>PHP {{ this.grand_total | currency}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style="display: none;">
                <table id="transfer_to_pb_com">
                    <thead>
                        <tr>
                            <th>COMPANY</th>
                            <th>TERMS</th>
                            <th>CASH</th>
                            <th>AMOUNT</th>
                            <th>BANK</th>
                            <th>CHECK NUMBER</th>
                            <th>DATED</th>
                            <th>DUE</th>
                            <th>AGE</th>
                            <th>REMARKS</th>
                        </tr>
                    </thead>
                    <tbody>
                         <tr v-for="(data, index) in transfer_to_pbcom" :key="index">
                            <td> {{ data.company_name }}</td>
                            <td>{{ data.terms }} </td>
                            <td></td>
                            <td>{{ data.gross_amount | currency }}</td>
                            <td>{{ data.bank_name }}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colspan="2"> Total Amount </td>
                            <td></td>
                            <td>0.00</td>
                            <td>{{ total_transfer_to_pbcom | currency }}</td>
                            <td colspan="6"></td>
                        </tr>
                        <!-- <tr>
                            <td colspan="2">AMOUNT: </td>
                            <td colspan="2">PHP {{ thousandSeprator(data.total) | currency }}</td>
                            <td colspan="5"></td>
                        </tr> -->
                    </tbody>
                </table>
            </div>

            <div style="display:none">
                <table id="all_collections">
                    <thead>
                        <tr>
                            <th>COMPANY</th>
                            <th>TERMS</th>
                            <th>CASH</th>
                            <th>AMOUNT</th>
                            <th>CHECK NUMBER</th>
                            <th>DATED</th>
                            <th>DUE</th>
                            <th>AGE</th>
                            <th>REMARKS</th>
                            <th style="display:none">Bank Id</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(data, index) in all_collections" :key="index">
                            <td>{{ data.company_name }}</td>
                            <td>{{ data.terms }} </td>
                            <td>{{ data.amount | currency }}</td>
                            <td>{{ data.gross_amount | currency }}</td>
                            <td v-if="data.control_number == 'CASH'">
                                {{ data.control_number }}
                            </td>
                            <td v-else>
                                {{ !!data.control_number ? data.control_number+' - ' : ''}}
                                {{ data.bank_name }}
                            </td>
                            <td>{{ data.payment_detail_date == '0000-00-00' || data.payment_detail_date == null ? '' : data.payment_detail_date}}</td>
                            <td>{{ data.due_date }}</td>
                            <td>{{ data.age }}</td>
                            <td>{{ data.remarks }}</td>
                            <td>{{ data.bank_id }}</td>
                        </tr>
                        <!-- <tr>
                            <td colspan="2">AMOUNT: </td>
                            <td colspan="2">PHP {{ thousandSeprator(data.total) | currency }}</td>
                            <td colspan="5"></td>
                        </tr> -->
                    </tbody>
                </table>
            </div>
            <div style="display:none;">
                <table id="footer">
                    <tbody>
                        <tr>
                            <td>PREPARED BY: <br/><br/> </td>
                            <td>NOTED BY: <br/><br/></td>
                            <td>NOTED BY: <br/><br/></td>
                            <td>RECEIVED BY: <br/><br/></td>
                        </tr>
                        <tr>
                            <td>{{ USERACCOUNT_LOAD.name | uppercase }}</td>
                            <td>JESSICA REAGO DEMETRIO</td>
                            <td>STEVEN ANDREW YANG</td>
                            <td>JONIE JUNIOR CHUA</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        <ViewCustomerPaymentDetailComponentVue :view="true" :user_access="userAccess"/>
    </v-form>
</template>

<script>
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
import ViewCustomerPaymentDetailComponentVue from '@/views/main/modules/Accounting/Payments/PaymentsComponent/ViewCustomerPaymentDetailComponent.vue';
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue';
import LoadingComponentVue from '@/views/main/modules/Services/Views/TechnicianManagerComponent/LoadingComponent.vue';
export default {
    mixins: [SharedFunctionsComponent],
    data() {
        return {
			valid: false,
            filters: {
                date_from: this.$dayjs().startOf('year').format('YYYY-MM-DD'),
                date_to: this.$dayjs().endOf('month').format('YYYY-MM-DD'),
                customers: [],
                customer_classifications: [],
                search: '',
                searchSelected: '',
            },
            payments:{
                selected: '',
                selection: [],
            },
            banks:{
                selection:[],
                selected:''
            },
            menu: {
                date_from: false,
                date_to: false,
            },
            collection_types:{
                selection:[
                    {'text': 'All','value': 0},
                    {'text': 'Collection Only','value': 1},
                    {'text': 'Transfer To PBCOM','value': 2},
                ],
                selected:0
            },
            dataTable: {
                items: [],
                loading: false,
                options: {},
                headers: [
                    { text: 'Company', value: 'company_name' },
                    { text: 'Payment Type', width: '8%', align: 'center', value: 'payment_name' },
                    { text: 'Terms', width: '8%', align: 'center', value: 'terms' },
                    { text: 'Cash', width: '8%', align: 'end', value: 'amount' },
                    { text: 'Net Amount', width: '8%', align: 'end', value: 'gross_amount' },
                    { text: 'Control Number', width: '8%', align: 'center', value: 'control_number' },
                    { text: 'Dated', width: '8%', align: 'center', value: 'payment_detail_date' },
                    { text: 'Due', width: '8%', align: 'center', value: 'due_date' },
                    { text: 'Age (Days)', width: '5%', align: 'center', value: 'age' },
                    { text: 'Remarks', width: '13%', align: 'start', value: 'remarks' },
                ],
                totalItems: 0,
            },
            itemsPerPageOptions: [5, 10, 25, 50, 100, 250, 500, 1000],
            expanded: [],
            disableButton: true,
            tableItem: {
                items: [],
                loading: false,
                options: {},
                totalItems: 0,
                headers: [
                    { text: 'Reference#', value: 'reference_number' },
                    { text: 'Date', value: 'payment_date' },
                    { text: 'Customer Name', value: 'customer_name' },
                    { text: 'Payment Type', value: 'payment_type_name' },
                    { text: 'Bank', value: 'bank_name' },
                    { text: 'Gross Amount', align: 'end', value: 'gross_amount' },
                    { text: 'Net Amount', align: 'end', value: 'net_amount' },
                    { text: 'Remaining Amount', align: 'end', value: 'remaining_amount' },
                    { text: 'Status', align: 'center', value: 'status' },
                    { text: 'Cheque Date', value: 'cheque_date' },
                    { text: 'Created By', value: 'created_by' },
                    { text: 'Action', align: 'start', value: 'action' },
                ],
            },
            viewTable: {
                loading: false,
                options: {},
                totalItems: 0,
                viewHeaders: [
                    { text: 'Reference#', value: 'reference_number' },
                    { text: 'Date', value: 'payment_date' },
                    { text: 'Customer Name', value: 'customer_name' },
                    { text: 'Payment Type', value: 'payment_type_name' },
                    { text: 'Bank', value: 'bank_name' },
                    { text: 'Gross Amount', align: 'end', value: 'gross_amount' },
                    { text: 'Net Amount', align: 'end', value: 'net_amount' },
                    { text: 'Remaining Amount', align: 'end', value: 'remaining_amount' },
                    { text: 'Status', align: 'center', value: 'status' },
                    { text: 'Cheque Date', value: 'cheque_date' },
                    { text: 'Created By', value: 'created_by' },
                    { text: 'Action', align: 'start', value: 'action' },
                ],
            },
            userAccess: {
                create  : false,
                edit    : false,
                view    : false,
                delete  : false,
                cancel_payment: false,
                confirm_payment: false,
                reject_payment: false,
                edit_payment: false,
            },
            selected: [],
            loadingButton: false,
            selectAllItems: false,
            expandItems: [],
            selectedTable: [],
            collection_total_amount:0,
            collection_total_gross_amount:0,
            grand_total:0,
            total_transfer_to_pbcom:0,
            cashDenominationBreakdown:[],
            all_collections:[],
            transfer_to_pbcom:[],
            paymentDialog: false,
            items: [],
        }
    },

    computed:{
        ...mapGetters([
            'DIALOGS',
            'ACTION',
            'GET_CUSTOMERS_SELECTION',
            'GET_CLASSIFICATIONS_SELECTION',
            'USERACCOUNT_LOAD',
            'GET_PAYMENT_TRANSMITTAL_DISPATCH',
            'USER_ACCESS',
            'GET_PAYMENT_TRANSMITTAL_VIEW',
        ]),

        totalGrossAmount() {
            return this.selected.reduce((subtotal, current) => parseFloat(subtotal) + parseFloat(current.gross_amount), 0)
        },

        totalNetAmount() {
            return this.selected.reduce((subtotal, current) => parseFloat(subtotal) + parseFloat(current.net_amount), 0)
        },

        totalRemainingAmount() {
            return this.selected.reduce((subtotal, current) => parseFloat(subtotal) + parseFloat(current.remaining_amount), 0)
        },

    },

    components: {
        ViewCustomerPaymentDetailComponentVue,
        LoadingComponentVue,
    },

    mounted() {
        this.$store.dispatch('getAllcustomersSelection')
        this.$store.dispatch('getAllcustomerClassificationSelection')
        this.getPaymentTypes()
        this.getBanks()
    },

    methods:{
        closeDialog() {
			this.$store.commit("DIALOG", false);
            this.$store.commit("ACTION", '');
            this.clearFields()
        },


        getCollectionReport() {
            this.dataTable.items = []
            this.viewTable.loading = true
            let data = []
            this.dataTable.loading = true;
            this.collection_total_amount = 0,
            this.collection_total_gross_amount = 0,
            this.grand_total = 0,
            this.cashDenominationBreakdown = []
            this.total_denomination = []
            this.all_collections = []
            this.transfer_to_pbcom = []
            let payload = {
                url: this.GET_PAYMENT_TRANSMITTAL_DISPATCH.get[0],
                page: this.dataTable.options.page,
                itemsPerPage: this.dataTable.options.itemsPerPage,
                date_range:[this.filters.date_from,this.filters.date_to],
                payment_type:this.payments.selected,
                customer_ids:this.filters.customers,
                bank_ids:this.banks.selected,
                search: this.filters.search,
                action: this.ACTION,
                pyt_id: this.ACTION == 'Edit' ? this.GET_PAYMENT_TRANSMITTAL_VIEW.detail.map(e => e.customer_payment_detail_id) : this.ACTION == 'View' ? this.GET_PAYMENT_TRANSMITTAL_VIEW.id : null,
            }
            this.$store.dispatch('paymentTransmittalGet',payload).then(response => {
                data = response.data.data

                let denomination = []
                let total_denomination = []
                let transfer_to_pbcom_bank_ids = [1,2,3,34]

                data.forEach(x => {
                    if(x.length != 0) {
                        x.terms = x.terms == 0 ? 'CASH' : x.terms
                        if(x.payment_type_id == 1){
                            x.control_number = 'CASH'
                            x.amount = x.gross_amount
                            x.gross_amount = 0
                            x.payment_detail_date = x.payment_date
                            denomination.push(JSON.parse(x.cash_denomination_breakdown))
                            this.collection_total_amount += Number (x.amount)
                            x.bank_name = ''
                        }
                        else if(x.payment_type_id == 2){
                            x.control_number = x.cheque_account_number
                            x.payment_detail_date = x.cheque_date
                            x.amount = 0
                        }
                        else if(x.payment_type_id == 3){
                            x.control_number = x.account_number
                            x.payment_detail_date = x.account_date === null ? x.payment_date : x.account_date
                            x.amount = 0
                        }
                        else if(x.payment_type_id == 4){
                            x.control_number = x.online_transaction_number
                            x.payment_detail_date = x.online_date
                            x.amount = 0
                        }
                        else if(x.payment_type_id == 5){
                            x.control_number = x.card_number
                            x.payment_detail_date = x.card_date
                            x.amount = 0
                        }
                        x.age = this.agingDate(x.due_date,x.payment_detail_date)

                        if(x.payment_type_id == 3 || x.payment_type_id == 4){
                            if(transfer_to_pbcom_bank_ids.includes(x.bank_id)){
                                this.transfer_to_pbcom.push(x);
                            }
                        }
                        this.collection_total_gross_amount += Number (x.gross_amount)
                    }
                })

                this.transfer_to_pbcom = _.groupBy(this.transfer_to_pbcom,'bank_name');
                let pb_com = [];
                for (let key in this.transfer_to_pbcom){
                    this.transfer_to_pbcom[key].forEach(e=>{
                        e.gross_amount = Number(e.gross_amount)
                    })
                    let x = _.sumBy(this.transfer_to_pbcom[key],'gross_amount')
                    pb_com.push({'company_name':"TRANSFER TO PBCOM",'gross_amount':x,'bank_name':key});
                }

                this.transfer_to_pbcom = pb_com;
                this.total_transfer_to_pbcom = _.sumBy(this.transfer_to_pbcom,'gross_amount')
                this.grand_total = this.collection_total_amount + this.collection_total_gross_amount
                let code_1000_amount = 0;
                let code_1000_qty = 0;
                let code_500_amount = 0;
                let code_500_qty = 0;
                let code_200_amount = 0;
                let code_200_qty = 0;
                let code_100_amount = 0;
                let code_100_qty = 0;
                let code_50_amount = 0;
                let code_50_qty = 0;
                let code_20_amount = 0;
                let code_20_qty = 0;
                let code_10_amount = 0;
                let code_10_qty = 0;
                let code_5_amount = 0;
                let code_5_qty = 0;
                let code_1_amount = 0;
                let code_1_qty = 0;
                let code_05_amount = 0;
                let code_05_qty = 0;
                let code_025_amount = 0;
                let code_025_qty = 0;
                let code_01_amount = 0;
                let code_01_qty = 0;
                let code_005_amount = 0;
                let code_005_qty = 0;
                let code_001_amount = 0;
                let code_001_qty = 0;
                denomination.forEach(e=>{
                    e.forEach((x,i)=>{
                        if(x.code == '1000'){
                            code_1000_amount += Number(x.amount)
                            code_1000_qty += Number(x.quantity)
                        }
                        else if(x.code == '500'){
                            code_500_amount += Number(x.amount)
                            code_500_qty += Number(x.quantity)
                        }
                        else if(x.code == '200'){
                            code_200_amount += Number(x.amount)
                            code_200_qty += Number(x.quantity)
                        }
                        else if(x.code == '100'){
                            code_100_amount += Number(x.amount)
                            code_100_qty += Number(x.quantity)
                        }
                        else if(x.code == '50'){
                            code_50_amount += Number(x.amount)
                            code_50_qty += Number(x.quantity)
                        }
                        else if(x.code == '20'){
                            code_20_amount += Number(x.amount)
                            code_20_qty += Number(x.quantity)
                        }
                        else if(x.code == '10'){
                            code_10_amount += Number(x.amount)
                            code_10_qty += Number(x.quantity)
                        }
                        else if(x.code == '5'){
                            code_5_amount += Number(x.amount)
                            code_5_qty += Number(x.quantity)
                        }
                        else if(x.code == '1'){
                            code_1_amount += Number(x.amount)
                            code_1_qty += Number(x.quantity)
                        }
                        else if(x.code == '0.5'){
                            code_05_amount += Number(x.amount)
                            code_05_qty += Number(x.quantity)
                        }
                        else if(x.code == '0.25'){
                            code_025_amount += Number(x.amount)
                            code_025_qty += Number(x.quantity)
                        }
                        else if(x.code == '0.1'){
                            code_01_amount += Number(x.amount)
                            code_01_qty += Number(x.quantity)
                        }
                        else if(x.code == '0.05'){
                            code_005_amount += Number(x.amount)
                            code_005_qty += Number(x.quantity)
                        }
                        else if(x.code == '0.01'){
                            code_001_amount += Number(x.amount)
                            code_001_qty += Number(x.quantity)
                        }
                    })
                })

                this.cashDenominationBreakdown.push(
                    {quantity: code_1000_qty, amount: code_1000_amount, id: 1, code: '1000', value: '1000.00'},
                    {quantity: code_500_qty, amount: code_500_amount, id: 2, code: '500', value: '500.00'},
                    {quantity: code_200_qty, amount: code_200_amount, id: 3, code: '200', value: '200.00'},
                    {quantity: code_100_qty, amount: code_100_amount, id: 4, code: '100', value: '100.00'},
                    {quantity: code_50_qty, amount: code_50_amount, id: 5, code: '50', value: '50.00'},
                    {quantity: code_20_qty, amount: code_20_amount, id: 6, code: '20', value: '20.00'},
                    {quantity: code_10_qty, amount: code_10_amount, id: 7, code: '10', value: '10.00'},
                    {quantity: code_5_qty, amount: code_5_amount, id: 8, code: '5', value: '5.00'},
                    {quantity: code_1_qty, amount: code_1_amount, id: 9, code: '1', value: '1.00'},
                    {quantity: code_05_qty, amount: code_05_amount, id: 10, code: '0.5', value: '0.50'},
                    {quantity: code_025_qty, amount: code_025_amount, id: 11, code: '0.25', value: '0.25'},
                    {quantity: code_01_qty, amount: code_01_amount, id: 12, code: '0.1', value: '0.10'},
                    {quantity: code_005_qty, amount: code_005_amount, id: 13, code: '0.05', value: '0.05'},
                    {quantity: code_001_qty, amount: code_001_amount, id: 14, code: '0.01', value: '0.01'},
                )

                data.forEach(e => {
                    if(e.length != 0) {
                        if (this.ACTION == 'View') {
                            this.GET_PAYMENT_TRANSMITTAL_VIEW.detail.forEach(a => {
                                let data = e.items.find(e => e.id == a.customer_payment_detail_id)
                                if(data) {
                                    this.selected = this.selected.concat(data)
                                }
                            })
                            e.age = isNaN(e.age) == true ? 0 : e.age
                            this.all_collections.push(e)
                        } else if (this.ACTION == 'Edit') {
                            this.GET_PAYMENT_TRANSMITTAL_VIEW.detail.forEach(a => {
                                let data = e.items.find(e => e.id == a.customer_payment_detail_id)
                                if(data) {
                                    this.selected = this.selected.concat(data)
                                    this.items = this.selected
                                }
                            })
                            e.amount = Number(e.amount)
                            e.gross_amount = Number(e.gross_amount)
                            this.all_collections.push(e)
                        } else {
                            e.amount = Number(e.amount)
                            e.gross_amount = Number(e.gross_amount)
                            this.expandItems = this.expandItems.concat(e.items)
                            this.all_collections.push(e)
                        }
                    }
                })
                this.all_collections = _.orderBy(this.all_collections, ['classification_id','customer_id'],['asc', 'desc']);
                this.total_denomination = total_denomination;
                this.dataTable.totalItems = response.data.total
                this.dataTable.loading = false
                this.viewTable.loading = false
            })
        },

        getPaymentTypes(){
            this.$store.dispatch('getPaymentTypes').then(response=>{
                this.payments.selection = response.data
            })
        },

        getBanks() {
            this.$store.dispatch('getBankSelection').then(response=>{
                this.banks.selection = response
            })
        },

        onSelectCustomers(items) {
            this.filters.customers = items
        },

        agingDate(due_date,dated) {
            if(!!due_date) {
                let startDay = this.$dayjs(dated);
                let endDay = this.$dayjs(due_date);
                let days =  this.$dayjs(endDay).diff(this.$dayjs(startDay), 'day');
                if(days >= 0){
                    days = 0
                    return days
                }
                else{
                    return days
                }
            }
            return '';
        },


        updateConfirm() {
            this.loadingButton = true;
            swal.fire({
                text: '- Are you sure you want to confirm selected payments?',
                icon: 'question',
                reverseButtons: true,
                showCancelButton: true,
            }).then(action => {
                if(action.isConfirmed) {
                    let payload = {
                        url: this.GET_PAYMENT_TRANSMITTAL_DISPATCH.update[0],
                        selected: this.selected,
                        payment_transmittal_id: this.GET_PAYMENT_TRANSMITTAL_VIEW.id,
                    }
                    this.$store.dispatch('paymentTransmittalPost', payload).then(response => {
                        if(response.data.error) {
                            swal.fire("Error!", response.data.error, "error");
                            this.loadingButton = false;
                            return false;
                        }
                        swal.fire("Success!", "Successfully Confirmed!", "success");
                        this.closeDialog()
                        this.getCollectionReport()
                        this.clearFields()
                        this.$store.commit('PAYMENT_TRANSMITTAL_REFRESH', true);
                        this.loadingButton = false;
                    }).catch(e => {
                        console.log(e)
                        swal.fire("Error!", "An Error Occured", "error");
                        this.loadingButton = false;
                    })
                } else {
                    this.loadingButton = false;
                }
            })
        },

        viewCustomerPayment(id) {
            this.$store.commit('custPaymentDetailSetId', id);
            this.$store.commit('custPaymentDetailShow');
        },

        clearFields() {
            this.selected                   = []
            this.selectedTable              = []
            this.items                      = []
            this.filters.date_from          = this.$dayjs().startOf('year').format('YYYY-MM-DD')
            this.filters.date_to            = this.$dayjs().endOf('month').format('YYYY-MM-DD')
            this.selectAllItems = false
        },

        updatePaymentTransmittal() {
            this.loadingButton = true;
            swal.fire({
                text: '- Are you sure you want to update?',
                icon: 'question',
                reverseButtons: true,
                showCancelButton: true,
            }).then(action => {
                if(action.isConfirmed) {
                    let payload = {
                        url: this.GET_PAYMENT_TRANSMITTAL_DISPATCH.update[1],
                        selected: this.selected,
                        payment_transmittal_id: this.GET_PAYMENT_TRANSMITTAL_VIEW.id,
                        date_from: this.GET_PAYMENT_TRANSMITTAL_VIEW.date_from,
                        date_to: this.GET_PAYMENT_TRANSMITTAL_VIEW.date_to,
                    }
                    this.$store.dispatch('paymentTransmittalPost', payload).then(response => {
                        swal.fire("Success!", "Successfully Update!", "success");
                        this.closeDialog()
                        this.getCollectionReport()
                        this.clearFields()
                        this.$store.commit('PAYMENT_TRANSMITTAL_REFRESH', true);
                        this.loadingButton = false;
                    }).catch(e => {
                        console.log(e)
                        swal.fire("Error!", "An Error Occured", "error");
                        this.loadingButton = false;
                    })
                } else {
                    this.loadingButton = false;
                }
            })
        },

        submitPaymentTransmittal() {
            this.loadingButton = true;
            swal.fire({
                text: '- Are you sure you want to submit?',
                icon: 'question',
                reverseButtons: true,
                showCancelButton: true,
            }).then(action => {
                if(action.isConfirmed) {
                    let payload = {
                        url: this.GET_PAYMENT_TRANSMITTAL_DISPATCH.add[0],
                        selected: this.selected,
                        date_from: this.filters.date_from,
                        date_to: this.filters.date_to,
                    }
                    this.$store.dispatch('paymentTransmittalPost', payload).then(response => {
                        swal.fire("Success!", "Successfully Submitted!", "success");
                        this.closeDialog()
                        this.getCollectionReport()
                        this.clearFields()
                        this.$store.commit('PAYMENT_TRANSMITTAL_REFRESH', true);
                        this.loadingButton = false;
                    }).catch(e => {
                        console.log(e)
                        swal.fire("Error!", "An Error Occured", "error");
                        this.loadingButton = false;
                    })
                } else {
                    this.loadingButton = false;
                }
            })
        },

        checkIfSelected(selected) {
            if(selected) {
                selected.item.items.forEach(x => {
                    let index = this.selected.indexOf(x);
                    if (selected.value) {
                        if (index === -1) {
                            this.selected.push(x);
                        }
                    } else {
                        if (index !== -1) {
                            this.selected.splice(index, 1);
                        }
                    }
                })
            }
        },

        renderPDF(type) {
            this.loadingButton = true
            var doc = new this.$jspdf({
                orientation: 'l',
                unit: 'mm',
                format: [215,330]
            });
            let left = 7;
            let top = 5;
            const imgWidth = 30;
            const imgHeight = 10;
            let host = window.location.origin;
            doc.addImage(`${host}/images/ingcoph-logo.png`, 'PNG', left, top, imgWidth, imgHeight);
            doc.setFont(undefined, 'bold').setFontSize(12).text(40, 13, `(${this.formatDateWithDays(this.GET_PAYMENT_TRANSMITTAL_VIEW.date_from)} - ${this.formatDateWithDays(this.GET_PAYMENT_TRANSMITTAL_VIEW.date_to)})`);
            if(this.collection_types.selected == 0 || this.collection_types.selected == 1){
                doc.autoTable({
                    html: '#all_collections',
                    theme: 'grid',
                    margin: {top: 15, left: 6, right: 6},
                    headStyles: {
                        halign:'center',
                        fontSize: 8,
                        fillColor: 'yellow',
                        textColor: '#000000',
                    },
                    columnStyles: {
                        0: {cellWidth: 70,halign:'center', fontSize: 8, textColor: '#000000'},
                        1: {cellWidth: 15,halign:'center', fontSize: 8, textColor: '#000000'},
                        2: {cellWidth: 20,halign:'center', fontSize: 8, textColor: '#000000'},
                        3: {cellWidth: 20,halign:'center', fontSize: 8, textColor: '#000000'},
                        4: {cellWidth: 65,halign:'center', fontSize: 8, textColor: '#000000'},
                        5: {cellWidth: 20,halign:'center', fontSize: 8, textColor: '#000000'},
                        6: {cellWidth: 20,halign:'center', fontSize: 8, textColor: '#000000'},
                        7: {cellWidth: 10,halign:'center', fontSize: 8, textColor: '#000000'},
                        8: {cellWidth: 80,halign:'left', fontSize: 8, textColor: '#000000'},
                        9: {cellWidth: 15,halign:'left', fontSize: 8, textColor: '#000000'},
                    },
                    willDrawCell: function (data) {
                        var rows = data.table.body;
                        let day = Number(data.row.cells[7].text[0])
                        if(data.row.raw.length == 10){
                            let bank_id = Number(data.row.raw[9].content)
                            if(bank_id == 35){
                                doc.setFillColor('yellow');
                            }
                        }
                        if(day < 0){
                            data.row.cells[7].styles.textColor = 'red';
                        }
                    },
                });
            }
            if(this.collection_types.selected == 0 || this.collection_types.selected == 2){
                if(this.collection_types.selected == 0){
                    doc.addPage()
                }
                doc.addImage(`${host}/images/ingcoph-logo.png`, 'PNG', left, top, imgWidth, imgHeight);
                doc.setFont(undefined, 'bold').setFontSize(12).text(40, 13, `(${this.formatDateWithDays(this.GET_PAYMENT_TRANSMITTAL_VIEW.date_from)} - ${this.formatDateWithDays(this.GET_PAYMENT_TRANSMITTAL_VIEW.date_to)})`);
                doc.autoTable({
                    html: '#transfer_to_pb_com',
                    theme: 'grid',
                    margin: {top: 15, left: 6, right: 6},
                    headStyles: {
                        halign:'center',
                        fontSize: 8,
                        fillColor: 'yellow',
                        textColor: '#000000',
                    },
                    columnStyles: {
                        0: {cellWidth: 60,halign:'center', fontSize: 8, textColor: '#000000'},
                        1: {cellWidth: 15,halign:'center', fontSize: 8, textColor: '#000000'},
                        2: {cellWidth: 20,halign:'center', fontSize: 8, textColor: '#000000'},
                        3: {cellWidth: 30,halign:'center', fontSize: 8, textColor: '#000000'},
                        4: {cellWidth: 65,halign:'center', fontSize: 8, textColor: '#000000'},
                        5: {cellWidth: 20,halign:'center', fontSize: 8, textColor: '#000000'},
                        6: {cellWidth: 20,halign:'center', fontSize: 8, textColor: '#000000'},
                        7: {cellWidth: 10,halign:'center', fontSize: 8, textColor: '#000000'},
                        8: {cellWidth: 10,halign:'left', fontSize: 8, textColor: '#000000'},
                        9: {cellWidth: 70,halign:'left', fontSize: 8, textColor: '#000000'},
                    },
                });
            }
            if(this.collection_types.selected == 0 || this.collection_types.selected == 1){
                doc.addPage()
                doc.addImage(`${host}/images/ingcoph-logo.png`, 'PNG', left, top, imgWidth, imgHeight);
                doc.autoTable({
                    html: '#sub_total_details',
                    theme: 'grid',
                    headStyles: {
                        halign:'center',
                        fontSize: 8,
                        fillColor: 'yellow',
                        textColor: '#000000',
                    },
                    margin: {top: 15, left: 6, right: 6},
                    columnStyles: {
                        0: {cellWidth: 65,halign:'center', fontSize: 8, textColor: '#000000'},
                        1: {cellWidth: 65,halign:'center', fontSize: 8, textColor: '#000000'},
                        2: {cellWidth: 65,halign:'center', fontSize: 8, textColor: '#000000'},
                    }
                });
                doc.autoTable({
                    html: '#cash_denominations',
                    theme: 'grid',
                    headStyles: {
                        halign:'center',
                        fontSize: 8,
                        fillColor: 'yellow',
                        textColor: '#000000',
                    },
                    margin: {top: 8, left:155, right: 6},
                    columnStyles: {
                        0: {cellWidth: 35,halign:'center', fontSize: 8, textColor: '#000000'},
                        1: {cellWidth: 35,halign:'center', fontSize: 8, textColor: '#000000'},
                        2: {cellWidth: 35,halign:'center', fontSize: 8, textColor: '#000000'},
                    }
                });

                doc.autoTable({
                    html: '#footer',
                    theme: 'plain',
                    startY: doc.autoTable.previous.finalY+10,
                    margin: {top: 2, left: 6, right: 6},
                    columnStyles: {
                        0: {cellWidth: 80, halign:'center',fontSize: 8, textColor: '#000000'},
                        1: {cellWidth: 80, halign:'center',fontSize: 8, textColor: '#000000'},
                        2: {cellWidth: 80, halign:'center',fontSize: 8, textColor: '#000000'},
                        3: {cellWidth: 80, halign:'center',fontSize: 8, textColor: '#000000'},
                    },
                    bodyStyles: {
                        lineColor: '#000000',
                    }
                });
            }
            if(this.collection_types.selected == 2){
                doc.autoTable({
                    html: '#footer',
                    theme: 'plain',
                    startY: doc.internal.pageSize.height - 35,
                    margin: {top: 2, left: 6, right: 6},
                    columnStyles: {
                        0: {cellWidth: 80, halign:'center',fontSize: 8, textColor: '#000000'},
                        1: {cellWidth: 80, halign:'center',fontSize: 8, textColor: '#000000'},
                        2: {cellWidth: 80, halign:'center',fontSize: 8, textColor: '#000000'},
                        3: {cellWidth: 80, halign:'center',fontSize: 8, textColor: '#000000'},
                    },
                    bodyStyles: {
                        lineColor: '#000000',
                    }
                });
            }
            let pageCount = doc.internal.getNumberOfPages();
            for(let i = 0; i < pageCount; i++) {
                doc.setPage(i);
                let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber;
                doc.setFontSize(9).setFont(undefined, 'normal').text('Page: ' + pageCurrent + ' of ' + pageCount, doc.internal.pageSize.width - 25,12 );
            }
            doc.autoPrint();
            window.open(doc.output('bloburl'), '_blank');
            this.loadingButton = false
        },
        formatDateWithDays(value){
            if (value) {
                return this.$dayjs(String(value)).format('MMMM D, YYYY');
            }
        },
        dialog(type) {
            if(type == 'open') {
                this.paymentDialog = true;
            } else {
                this.paymentDialog = false;
            }
        },

        removeItem(i) {
            this.selected = this.selected.filter((e, index) => {
                if (i != index) {
                    return e
                }
            });
        },

        searchSelected() {
            if(this.filters.searchSelected == '') {
                if(this.selected.length > 0) {
                    this.selected = [...new Set([...this.items, ...this.selected])]
                }
            } else {
                let test = this.items
                this.selected = test.filter((e) => {
                        return e.reference_number == this.filters.searchSelected
                });
            }
        }
    },

    watch: {
        'selected': {
            handler(val) {
                if(val.length > 0) {
                    this.disableButton = false
                } else {
                    this.disableButton = true
                }
            }
        },

        USER_ACCESS:{
            handler(val){
                this.userAccess.create = false;
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                this.cancel_payment = false;
                this.confirm_payment = false;
                this.reject_payment = false;
                this.edit_payment = false;

                if(val != "fail"){
                    val.forEach((e) => {
                        if (e.actions_code == "create"){
                            this.userAccess.create = true;
                        }
                        else if (e.actions_code == "edit"){
                            this.userAccess.edit = true;
                        }
                        else if (e.actions_code == "view"){
                            this.userAccess.view = true;
                        }
                        else if (e.actions_code == "delete"){
                            this.userAccess.delete = true;
                        }
                    });
                }
            }
        },

        DIALOGS: {
            handler(val){
                if(val){
                    this.getCollectionReport()
                }
            },
            immediate: true,
        },

        'selectAllItems': {
            handler(val) {
                if(val) {
                    this.selected = val ? this.expandItems : []
                    this.selected = this.selected;
                    this.selectedTable = this.all_collections;
                } else {
                    this.selected = []
                    this.selectedTable = []
                }
            }
        },
    },

}
</script>

<style>

</style>