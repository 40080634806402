<template >
    <v-form>
        <!-- Table -->
      <v-container fluid>
        <!-- v-for="(chunkItem,i) in this.arraySlice(data,20) -->
        <div v-for="(chunkItem,i) in this.arraySlice(data,15)">
          <table :id="'item_details_fas_requisition_items-'+i" class="d-none">
            <thead>
                <tr>
                <th class="border-bottom-none" ></th>
                <th colspan="3" >Requestor</th>
                <th colspan="2" >SAO/AIC</th>
                <th colspan="3" >For Admin Use</th>
              </tr>
              <tr>
                <th rowspan="2">Line No.</th>
                <th rowspan="2">Description</th>
                <th rowspan="2">QTY</th>
                <th rowspan="2">UOM</th>
                <th  colspan="2">Approved</th>
                <th rowspan="2">QTY Released</th>
                <th rowspan="2">Unit Cost</th>
                <th rowspan="2">Line Total</th>
              </tr>
              <tr>
                <td>QTY</td>
                <td>UOM</td>
              </tr>

            </thead>
            <tbody>
              <tr v-for="(row,x) in chunkItem" :key="x" class="tdata">
                <td></td>
                <td>{{ row.item_id?.item_name }}</td>
                <td>{{ row.qty }}</td>
                <td>{{ GET_UOM_SELECTION.find(e=>e.value == row.uom_id).text }}</td>
                <td>{{ row.approved_quantity == 0?row.qty:row.approved_quantity }}</td>
                <td>{{ GET_UOM_SELECTION.find(e=>e.value == row.uom_id).text }}</td>
                <td>{{ row.approved_quantity == 0?0:row.approved_quantity}}</td>
                <td>{{ row.approved_quantity == 0?0:row.price }}</td>
                <td>{{ (row.approved_quantity == 0?0:row.approved_quantity * parseInt(row.price)) }}</td>
                <td></td>
              </tr>
              <tr v-for="i in (15-chunkItem.length)" v-if="chunkItem.length < 15" class="tdata">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-container>
    </v-form>

  </template>
  <script charset="utf-8">
  import { mapGetters } from 'vuex';
  import SharedFunctionsComponent from "@/views/main/Shared/SharedFunctionsComponent.vue";
  import GILL from '@/assets/fonts/Gil_normal';
  export default {
    mixins:[SharedFunctionsComponent,GILL],
    data() {
        return {
          printData: null,
          base64Image: null,
          counts:0,
            info: {
                logo : "",
                name : "",
                deptLoc : "",
                typeOfAcct : "",
                transferOfAcct : "",
                newAs : "",
                reference_as : "",
                prepared_by : "",
                checked_by : "",
                noted_by : "",
                asNo : "",
                prepared_by_data : "",
                checked_by_data : "",
                noted_by_data : "",
                prepared_by_position : "",
                checked_by_position : "",
                noted_by_position : "",
                signature_name : "",
                acknowledgement : "",
                acknowledgement_text : "",
                sheetVersion : "",
                sheetCopy : "",

            },
            sheet_data: {
                name:"",
                date:null,
                dept:"",
                location:"",
                dept_loc:"",
            },
            copy: {
                sheet_copy_1: "",
                sheet_copy_2: "",
                sheet_copy_3: "",
            },
            total_amount:0,
            headers: ["ITEM DETAILS", "ITEM CODE/s", "PURCHASE / DEPRECIATED PRICE", "REMARKS"],
            data: [],
            chunkItems:[],
        }
    },
    computed:{
        ...mapGetters([
          'GET_PRINT_FAS_REQUISITION',
          'GET_PRINT_FAS_REQUISITION_DETAIL',
          "GET_UOM_SELECTION",
          "GET_ADMIN_CLASSIFICATION_SELECTION",
          "GET_LIST_DEPARTMENT",
          "GET_ADMIN_LOCATION",
          "PURCHASE_ORDER",
        ]),
    },
    mounted(){
    },
    methods: {
        async renderPDF(){
            let doc = await new this.$jspdf({
                        orientation: 'p',
                        unit: 'mm',
                        format: 'letter'
                    });
                    doc.deletePage(1)
               this.arraySlice(this.data,15).forEach((e,index)=>{ //From SharedFunction
                this.chunkItems = e;

                    doc.addPage();
                    let request_department_title = 'Department';
                    let request_department_text = this.GET_LIST_DEPARTMENT.find(dept=>dept.value == this.GET_PRINT_FAS_REQUISITION_DETAIL.data.department_id)?.text
                    let requested_by_title = 'Requested by';
                    let endorse_by_title = 'Endorsed by';
                    let budget_checked_by_title = 'Budget Checked by'
                    let processed_by_title = 'Processed by'
                    let noted_by_title = 'Noted by'
                    let prepared_name = ''
                    let date_prepared_title = 'Location';
                    let date_needed_title = 'Date';
                    let date_prepared_text = this.GET_ADMIN_LOCATION.find(loc=>loc.value == this.GET_PRINT_FAS_REQUISITION_DETAIL.data.location_id).text;
                    let date_needed_text = this.$dayjs().format('MMMM D, YYYY')
                    let justification_remarks_title = 'Justification / Remarks'
                    let justification_remarks_text = this.GET_PRINT_FAS_REQUISITION_DETAIL.data.justification_remarks
                    let admin_remarks = 'Admin Remarks'
                    let admin_remarks_text = 'asdas'
                    let second_section_title = 'Products/Services';
                    let requestor_title = 'Requestor'
                    let requestor_text = this.GET_PRINT_FAS_REQUISITION_DETAIL.data.full_name
                    let department_head_title= 'Department Head'
                    let items_received_by_title= 'Items Received by'
                    let admin_manager= 'Admin Manager'
                    let admin_manager_text = 'Nicole Cyrel Carpio';
                    let wh_custodian = 'WH Custodian / Admin In-Charge'
                    let requestor_= 'Received by /Date:'
                    let fas_number= this.GET_PRINT_FAS_REQUISITION_DETAIL.data.fas_number
                    this.info.logo = 'ingcoph-logo'
                    let left = 10;
                    let top = 10;
                    const imgWidth = 40;
                    const imgHeight = 12;
                    // let host = window.location.origin;

                    try {
                        let aspectRatio = this.getAspectRatio(this.base64Image.naturalWidth, this.base64Image.naturalHeight);
                        let image_width = 0;
                        let image_height = 0;

                        switch(aspectRatio){
                            case '1:1':
                                image_width = 40;
                                image_height = 15;
                            break;
                            case '4:3':
                                image_width = 60;
                                image_height = 40;
                                break;
                            case '3:2':
                                image_width = 50;
                                image_height = 40;
                                break;
                            case '16:9':
                                image_width = 80;
                                image_height = 40;
                                break;
                            default:
                                image_width = 40;
                                image_height = 10;
                        }

                        doc.addImage(this.printData.image_path, 'PNG', left, top, image_width, image_height);
                    } catch (error){
                        console.log(error)
                        console.error('image is not found for print.');
                    }

                    // doc.addImage(`${host}/images/${this.info.logo}.png`, 'PNG', left, top, imgWidth, imgHeight);
                    doc.setFont("GILL", "bold");
                    var text = "F.A.S. REQUISITION FORM";
                    var textWidth = doc.getTextWidth(text);

                    doc.setFont("GILL");
                    doc.setFontSize(14);
                    doc.setFillColor(192,192,192);

                    doc.roundedRect(118, 10, textWidth + 20 + (index > 0?18:0), 14, 3, 3, 'FD');
                    doc.setTextColor(0,0,0);
                    doc.text(text, 135 , 19);

                    //container box
                    doc.rect(10, 27, 200, 200);

                    //first section
                    doc.rect(10, 27, 70, 14);
                    //first box text
                    doc.setTextColor(0,0,0);
                    doc.setFontSize(12);
                    doc.setFont("GILL", "normal");
                     doc.setFont("GILL");
                    doc.text(request_department_text, 35 , 38);
                    doc.setFontSize(8);
                    doc.text(request_department_title, 12 , 31);

                     //second box text
                    doc.text(date_prepared_title, 82 , 31);
                    doc.text(date_needed_title, 152 , 31);
                    doc.setFont("GILL", "normal");
                    doc.setFont("GILL");
                    doc.setFontSize(10);
                    doc.text(date_prepared_text, 99 , 38);

                    doc.setFont("GILL", "normal");
                    doc.setFont("GILL");
                    doc.setFontSize(10);
                    doc.text(date_needed_text, 166 , 38);
                    doc.rect(80, 27, 70, 14);
                    doc.rect(150, 27, 60, 14);

                    //second section
                    // doc.setFontSize(11);
                    // doc.setFont("GILL", "bold")
                    // doc.text(second_section_title,12, 45);
                    // doc.rect(10, 41, 200, 7);
                    let rect_ = 12;

            let chunks = [];
            let i = 0;
            let n = this.GET_ADMIN_CLASSIFICATION_SELECTION.length;
            let tmp = 1;
            let tmp_1 = 1;
            let tmp_2 = 1;
            let classification_max = 0;
            while (i < n) {
              classification_max = tmp_2++ * tmp_1++ - 5;
              chunks.push({"y":45 + (i > 1?8* tmp++:1),"x":12,data:this.GET_ADMIN_CLASSIFICATION_SELECTION.slice(i, i += 3)})
            }
                doc.setFont("GILL", "normal");
                doc.setFont("GILL");
                chunks.forEach(el => {
                  let a = el.x ;
                  let b = 75
                  el.data.forEach((element,i) => {
                  let find =  this.data.find(e=>{
                      return e.category_id == element.id
                    })

                      if (element.text.length > 44) {
                        doc.setFontSize(7);
                      } else if(element.text.length > 40){
                        doc.setFontSize(8);
                      } else {
                        doc.setFontSize(10);
                      }
                      doc.rect(a+2,  el.y-2, 2, 2, find != undefined && find.category_id == element.id?'F':'S');
                      doc.text(element.text,a+5,el.y + .5);
                      a = a + b
                  });
                });
                $('.tdata').each(function(e){
                  $(this).find('td')[0].innerHTML = e + 1
                 })
                    // // Insert Tables
                    doc.autoTable({
                                html: `#item_details_fas_requisition_items-${index}`,
                                startY: 60 + classification_max,
                                margin: {left: 10, right: 6},
                                theme: 'grid',
                                headStyles: {
                                    halign:'center',
                                    fontSize:8,
                                    fillColor: '#ffffff',
                                    textColor: '#000000',
                                    lineWidth: .1,
                                    lineColor: '#000000',
                                    font: 'GILL',
                                },
                                columnStyles: {
                                  0: {cellWidth: 12, halign:'center',  textColor: '#000000',fontSize:8,},
                                  1: {cellWidth: 60, halign:'left',    textColor: '#000000',fontSize:8,},
                                  2: {cellWidth: 12, halign:'center', textColor: '#000000',fontSize:8,},
                                  3: {cellWidth: 14, halign:'center', textColor: '#000000',fontSize:8,},
                                  4: {cellWidth: 19, halign:'center', textColor: '#000000',fontSize:8,},
                                  5: {cellWidth: 19, halign:'center', textColor: '#000000',fontSize:8,},
                                  6: {cellWidth: 25, halign:'center', textColor: '#000000',fontSize:8,},
                                  7: {cellWidth: 19, halign:'center', textColor: '#000000',fontSize:8,},
                                  8: {cellWidth: 20, halign:'left', textColor: '#000000',fontSize:8,},
                              },
                                bodyStyles: {
                                    lineColor: '#000000',
                                    font: 'GILL',
                                },
                                willDrawCell: function (data) {
                                    var rows = data.table.body;
                                },
                    });


                    let finalY = doc.lastAutoTable.finalY

                    // doc.setDrawColor(0, 0, 0);
                    // doc.rect(10, 182, 139, 25);
                    // doc.rect(149, 182, 61, 25);

                    doc.setFont("GILL", "bold");
                    doc.setFontSize(10);
                    doc.text( requestor_text ? requestor_text : "", 20, 220);
                    doc.text( admin_manager_text, 90, 240);
                    doc.text(admin_remarks_text, 166 , finalY + 9);
                    doc.text(justification_remarks_title, 12 , finalY + 4);
                    doc.text(justification_remarks_text, 40 , finalY + 9);
                    doc.text(admin_remarks, 152 , finalY + 4);
                    doc.setFont("GILL", "normal");
                    // doc.text(fas_number,10,251);
                    doc.setFont("GILL", "bold");
                    doc.setFontSize(8);
                    doc.setTextColor(0,0,0);
                    doc.text( requestor_title, 25, 225);
                    doc.text( requested_by_title, 12 , 210);
                    doc.text( department_head_title, 104, 225);
                    doc.text( wh_custodian, 14, 245);

                    doc.text( requestor_title, 169, 245);

                    doc.text( endorse_by_title, 79 , 210);
                    doc.text( budget_checked_by_title, 152 , 210);

                    doc.text( processed_by_title, 12, 230);
                    doc.text( noted_by_title, 79, 230);
                    doc.text( items_received_by_title, 152, 230);
                    doc.text( admin_manager, 95, 245);


                    doc.setFontSize(12);
                    doc.setFont("GILL", "bold");
                    doc.setTextColor(0, 0, 0);
                    doc.text('FAS NUMBER:',150,251);
                    doc.setTextColor(255, 0, 0);
                    doc.text(fas_number,180,251);

                    doc.rect(10, 227, 67, 20);
                    doc.rect(77, 227, 72, 20);
                    doc.rect(149, 227, 61, 20);

                    doc.rect(10, 207, 67, 20);
                    doc.rect(77, 207, 72, 20);
                    doc.rect(149, 207, 61, 20);

                    doc.setDrawColor(0, 0, 0);
                    doc.line(108, finalY, 108, finalY + 14);

                  })

                    //---------------------------------------------------------------//
                    doc.autoPrint();
                    // doc.save('Accountability Sheet.pdf');
                    window.open(doc.output('bloburl'), '_blank');
        },
    },
    watch:{
        GET_PRINT_FAS_REQUISITION:{
            handler(val){
               if(val){
                this.total_amount = 0;
                this.printData = this.GET_PRINT_FAS_REQUISITION_DETAIL.get_image;
                this.base64Image = this.PURCHASE_ORDER.COMPANY_IMAGE;
                this.data = this.GET_PRINT_FAS_REQUISITION_DETAIL.items_list;
                this.renderPDF()
                this.$store.commit('PRINT_FAS_REQUISITION',false);
               }
            },
            deep:true
        }
    }
  }
  </script>
  <style>
    .checkboxes{
      width:10px;
      height:10px;
      border:1px solid black;
    }
  </style>
