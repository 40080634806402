<template>
    <v-container fluid>
        <v-row class="pa-2">
            <v-col>
                <v-btn
                    v-if="userAccess.includes('create')"
                    small
                    class="mx-3"
                    color="#f69f1a"
                    @click="addDisposal"
                >
                    <v-icon small>mdi-plus</v-icon> 
                    <span>Add</span>
                </v-btn>
                <v-btn
                    small
                    color="#f69f1a"
                    :loading="loading.exportExcel"
                    @click="exportDisposals"
                >
                    <v-icon small>mdi-printer-pos-wrench</v-icon> 
                    <span>Export</span>
                </v-btn>
            </v-col>
            <v-col>
                <div class="d-flex justify-end align-center mr-3">
                    <a :class="`ml-1 badge badge-all ${GET_DISPOSAL_STATUS == '' ? 'selected' : 'non-selected'}`" @click="getStatus();">all</a>
                    <a :class="`ml-1 badge badge-saved ${GET_DISPOSAL_STATUS == 'saved' ? 'selected' : 'non-selected'}`" @click="getStatus('saved');">saved</a>
                    <!-- <a :class="`ml-1 badge badge-pending ${GET_DISPOSAL_STATUS == 'pending' ? 'selected' : 'non-selected'}`" @click="getStatus('pending');">pending</a> -->
                    <a :class="`ml-1 badge badge-approved ${GET_DISPOSAL_STATUS == 'approved' ? 'selected' : 'non-selected'}`" @click="getStatus('approved');">approved</a>
                    <a :class="`ml-1 badge badge-full ${GET_DISPOSAL_STATUS == 'disposed' ? 'selected' : 'non-selected'}`" @click="getStatus('disposed');">disposed</a>
                    <a :class="`ml-1 badge badge-recovery ${GET_DISPOSAL_STATUS == 'recovered' ? 'selected' : 'non-selected'}`" @click="getStatus('recovered')">recovered</a>
                    <a :class="`ml-1 badge badge-cancelled ${GET_DISPOSAL_STATUS == 'canceled' ? 'selected' : 'non-selected'}`" @click="getStatus('canceled')">canceled</a>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <DisposalTableComponent></DisposalTableComponent >  
        </v-row>
        <v-row>
            <v-dialog v-model="addDisposalDialog" scrollable max-width="90%"  persistent>
                <v-card class="pb-2">
                    <v-card-title class="d-flex justify-between">
                        <v-col cols=11 class="text-h5">Add Parts Claim</v-col>
                        <v-col cols=1> 
                            <v-btn
                                text 
                                icon 
                                color="gray" 
                                class="float-right"
                                @click="closeAddDisposal"
                            >
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-card-title>
                    <v-divider class="ma-0 pa-0"/>
                    <v-card-text>
                        <v-container class="pa-0 pt-4">
                            <v-row>
                                <v-col
                                    cols="12"
                                    lg="2"
                                    class="pa-2 pb-0"
                                >
                                    <v-autocomplete
                                        dense
                                        outlined
                                        clearable
                                        item-value="id"
                                        auto-select-first
                                        item-text="name"
                                        :items="scLocations"
                                        v-model="selectedLocation"
                                        @input="getStockTransfers()"
                                        >
                                        <template v-slot:label>
                                            <div class="font-weight-medium">Select Location</div>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col
                                    cols="12"
                                    lg="2"
                                    class="pa-2 pb-0"
                                >
                                    <v-autocomplete
                                        dense
                                        outlined
                                        clearable
                                        auto-select-first
                                        label="Select Stock Transfer#"
                                        return-object
                                        item-text="transfer_num"
                                        :items="stockTransfersList"
                                        v-model="selectedTransfer"
                                        :disabled="!selectedLocation"
                                        :loading="loading.dropdownOnFlight"
                                        @input="getStockTransferItems($event)"
                                        >
                                        <template v-slot:label>
                                            <div class="font-weight-medium">Select Stock Transfer#<span class="ml-1 red--text">*</span></div>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-spacer/>
                                <v-col
                                    cols="12"
                                    lg="3"
                                    class="pa-2 pb-0"
                                >
                                    <v-autocomplete
                                        dense
                                        outlined
                                        clearable
                                        item-value="value"
                                        auto-select-first
                                        label="Origin Warehouse"
                                        item-text="text"
                                        :items="disposalWarehouses"
                                        v-model="selectedOriginWarehouse"
                                        disabled
                                        >
                                        <!-- :loading="loading.dropdownOnFlight" -->
                                        <!-- <template v-slot:label>
                                            <div class="font-weight-medium">Origin Warehouse<span class="ml-1" style="color:#ff0000">*</span></div>
                                        </template> -->
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="12"
                                    lg="4"
                                    class="pa-2 pb-0"
                                >
                                    <v-text-field
                                        v-model="disposalRemarks"
                                        clearable
                                        outlined
                                        dense
                                    >
                                        <template v-slot:label>
                                            <div class="font-weight-medium">Remarks</div>
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="d-flex justify-start">
                                <v-col
                                    cols="12"
                                    lg="3"
                                    class="d-flex justify-start align-center pa-2 pb-0"
                                >
                                    <v-autocomplete
                                        transition="scroll-x-transition"
                                        dense
                                        chips
                                        outlined
                                        small-chips
                                        auto-select-first
                                        return-object
                                        item-text="text"
                                        v-model="selectedItem"
                                        :disabled="!selectedTransfer"
                                        :items="stockTransferItems"
                                        :loading="loading.dropdownOnFlight"
                                        >
                                        <!-- @input="getSelectedItemDetails($event)" -->
                                        <template v-slot:label>
                                            <div class="font-weight-medium">Select Items<span class="ml-1" style="color:#ff0000">*</span></div>
                                        </template>
                                    </v-autocomplete>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                fab 
                                                dark 
                                                small 
                                                v-on="on"
                                                color="primary"
                                                class="ml-1"
                                                style="height: 20px; width: 20px"
                                                @click="getSelectedItemDetails(selectedItem)"
                                            >
                                                <v-icon dark small style="font-size: 12px">mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add Item</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                fab 
                                                dark 
                                                small 
                                                v-on="on"
                                                color="primary"
                                                class="ml-1"
                                                style="height: 20px; width: 20px"
                                                @click="getAllItemsInStockTransfer(selectedItem)"
                                            >
                                                <v-icon dark small style="font-size: 12px">mdi-select-all</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add All Item</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                            <v-divider class="mx-1"></v-divider>
                            <v-row v-if="loading.itemDetailOnFlight" class="w-100">
                                <v-progress-linear
                                    indeterminate
                                    color="primary"
                                ></v-progress-linear>
                            </v-row>
                            <v-row class="mt-4 mx-1">
                                <v-col sm="12" md="12" class="px-0">
                                    <v-simple-table dense outlined class="dynamic_list_table">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-center mx-2" width="10%">Item Model</th>
                                                    <th class="text-center mx-2" width="19%">Item Name</th>
                                                    <th class="text-center mx-2" width="5%">UOM</th>
                                                    <th class="text-center mx-2" width="5%">Type</th>
                                                    <th class="text-center mx-2" width="9%">Category</th>
                                                    <th class="text-center mx-2" width="7%">ST Qty</th>
                                                    <th class="text-center mx-2" width="7%">Disposed Qty</th>
                                                    <th class="text-center mx-2" width="7%">Recovered Qty</th>
                                                    <th class="text-center mx-2" width="9%">On Process Qty</th>
                                                    <th class="text-center mx-2" width="7%">Remaining Qty</th>
                                                    <th class="text-center mx-2" width="5%">Quantity</th>
                                                    <th class="text-center mx-2" width="22%">Remarks</th>
                                                    <th class="text-center mx-2" width="1%"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item,i) in selectedItems" :key="i">
                                                    <!-- <td v-if="!nonContainerItem">
                                                        <v-text-field
                                                            v-model="item.container_num"
                                                            dense
                                                            outlined
                                                            hide-details
                                                            readonly
                                                            background-color="grey lighten-2"
                                                        ></v-text-field>
                                                    </td> -->
                                                    <td>
                                                        <v-text-field
                                                            v-model="item.model"
                                                            dense
                                                            outlined
                                                            hide-details
                                                            readonly
                                                            background-color="grey lighten-2"
                                                        ></v-text-field>
                                                    </td>
                                                    <td>
                                                        <v-text-field
                                                            v-model="item.name"
                                                            dense
                                                            outlined
                                                            hide-details
                                                            readonly
                                                            background-color="grey lighten-2"
                                                        ></v-text-field>
                                                    </td>
                                                    <td>
                                                        <v-text-field
                                                            v-model="item.uom"
                                                            dense
                                                            outlined
                                                            readonly
                                                            hide-details
                                                            class="centered-input"
                                                            background-color="grey lighten-2"
                                                        ></v-text-field>
                                                    </td>
                                                    <td>
                                                        <v-text-field
                                                            v-model="item.type"
                                                            dense
                                                            outlined
                                                            readonly
                                                            hide-details
                                                            class="centered-input"
                                                            background-color="grey lighten-2"
                                                        ></v-text-field>
                                                    </td>
                                                    <td>
                                                        <v-text-field
                                                            v-model="item.category"
                                                            dense
                                                            outlined
                                                            readonly
                                                            hide-details
                                                            background-color="grey lighten-2"
                                                        ></v-text-field>
                                                    </td>
                                                    <td>
                                                        <v-text-field
                                                            v-model="item.original_qty"
                                                            dense
                                                            outlined
                                                            readonly
                                                            hide-details
                                                            class="centered-input"
                                                            background-color="grey lighten-2"
                                                            type="number"
                                                            min="0"
                                                        ></v-text-field>
                                                    </td>
                                                    <td>
                                                        <v-text-field
                                                            v-model="item.total_qty_disposed"
                                                            dense
                                                            outlined
                                                            readonly
                                                            hide-details
                                                            class="centered-input"
                                                            background-color="grey lighten-2"
                                                            type="number"
                                                            min="0"
                                                        ></v-text-field>
                                                    </td>
                                                    <td>
                                                        <v-text-field
                                                            v-model="item.total_qty_recovered"
                                                            dense
                                                            outlined
                                                            readonly
                                                            hide-details
                                                            class="centered-input"
                                                            background-color="grey lighten-2"
                                                            type="number"
                                                            min="0"
                                                        ></v-text-field>
                                                    </td>
                                                    <td>
                                                        <v-text-field
                                                            v-model="item.on_process_qty"
                                                            dense
                                                            outlined
                                                            readonly
                                                            hide-details
                                                            class="centered-input"
                                                            background-color="grey lighten-2"
                                                            type="number"
                                                            min="0"
                                                        ></v-text-field>
                                                    </td>
                                                    <td>
                                                        <v-text-field
                                                            v-model="item.remaining_qty"
                                                            dense
                                                            outlined
                                                            readonly
                                                            hide-details
                                                            class="centered-input"
                                                            background-color="grey lighten-2"
                                                            type="number"
                                                            min="0"
                                                        ></v-text-field>
                                                    </td>
                                                    <td>
                                                        <v-text-field
                                                            v-model="item.quantity"
                                                            dense
                                                            outlined
                                                            hide-details
                                                            class="centered-input"
                                                            background-color="grey lighten-5"
                                                            type="number"
                                                            min="0"
                                                            :max="item.remaining_qty"
                                                        ></v-text-field>
                                                    </td>
                                                    <td>
                                                        <v-text-field
                                                            v-model="item.remarks"
                                                            dense
                                                            outlined
                                                            hide-details
                                                            class="centered-input"
                                                            background-color="grey lighten-5"
                                                        ></v-text-field>
                                                    </td>
                                                    <td>
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                            <v-btn
                                                                fab 
                                                                dark 
                                                                small
                                                                v-on="on"
                                                                color="red" 
                                                                style="height: 20px; width: 20px"
                                                                @click="removeItem(item.id)"
                                                            >
                                                                <v-icon dark small style="font-size: 12px">mdi-minus</v-icon>
                                                            </v-btn>
                                                            </template>
                                                            <span>Remove Item</span>
                                                        </v-tooltip>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider/>
                    <v-card-actions>
                        <v-row class="w-100">   
                            <v-col class="d-flex justify-center">
                                <v-tooltip top color="#f69f1a">
                                    <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                fab
                                                small
                                                dark
                                                v-on="on"
                                                v-bind="attrs"
                                                color="#f69f1a"
                                                :disabled="selectedItems.length == 0"
                                                @click="storeDisposalItems"
                                            >
                                                <v-icon small>mdi-content-save-all</v-icon>
                                            </v-btn>
                                        </template>
                                    <span>Submit</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import DisposalTableComponent from '@/views/main/modules/Services/Views/DisposalComponent/DisposalTableComponent.vue';
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue';
export default {
    mixins:[SharedFunctionsComponent],
    components: {
        DisposalTableComponent,
    },
    props: {
        tabName: {
            type: String,
            default: () => ''
        },

    },
    data() {
        return {
            addDisposalDialog:false,
            disposal_items:[],
            selectedSourceWarehouse:null,
            selectedOriginWarehouse:13,
            selectedContainer:null,
            disposalWarehouses:[],
            containerItems:[],
            selectedItems: [],
            selectedItemLists:[],
            disposalRemarks:'',
            loading: {
                dropdownOnFlight: false,
                submitOnFlight: false,
                exportExcel:false,
                itemDetailOnFlight:false,
            },
            successCreate: false,
            selectedStatus:'',
            exportHeader: [
                { header: 'Disposal Num', key: 'disposal_number', width: 15 },
                { header: 'Stock Transfer Number', key: 'transfer_num', width: 20 },
                { header: 'Item Model', key: 'model', width: 20 },
                { header: 'Item Name', key: 'name', width: 45 },
                { header: 'Item Uom', key: 'uom', width: 10 },
                { header: 'Item Type', key: 'type', width: 10 },
                { header: 'Item Category', key: 'category', width: 12 },
                { header: 'Stock Transfer Qty', key: 'st_original_qty', width: 14 },
                { header: 'Disposed Qty', key: 'disposed_qty', width: 14 },
                { header: 'Recovered Qty', key: 'recovered_qty', width: 14 },
                { header: 'Quantity', key: 'quantity', width: 14 },
                { header: 'Status', key: 'status', width: 10 },
                { header: 'Remarks', key: 'remarks', width: 25 },
                { header: 'Created By', key: 'created_by', width: 15 },
                { header: 'Created Date', key: 'created_at', width: 15 },
                { header: 'Approved By', key: 'approved_by', width: 15 },
                { header: 'Approved Date', key: 'approved_date',width: 15 },
            ],
            nonContainerItem: false,
            isActiveContainer: false,
            userAccess:[],
            selectedTransfer:null,
            stockTransfersList:[],
            stockTransferItems:[],
            selectedItem:{},
            scLocations:[],
            selectedLocation: null,
        }
    },
    computed: {
        ...mapGetters([
            'GET_CONTAINERS_SP',
            'GET_DROPDOWN_LOADING',
            'GET_DISPOSAL_STATUS',
            'GET_DISPOSAL_WAREHOUSES',
            'GET_ALL_ACTIVE_ITEMS',
            'GET_WAREHOUSE_LISTS',
            'USER_ACCESS'
        ]),
    },
    methods: {
        addDisposal() {
            this.addDisposalDialog = true;
        },
        closeAddDisposal() {
            this.addDisposalDialog = false;
            this.selectedItems = [];
            this.selectedContainer = null; 
            this.selectedItemLists = [];
            this.disposalRemarks = '';
            this.selectedSourceWarehouse = null;
            // this.selectedOriginWarehouse = 13;
            this.selectedTransfer = null;
            this.selectedItem = {};
        },
        getContainerItems() {
            this.selectedItems = [];
            this.containerItems = [];
            this.loading.dropdownOnFlight = true
            let payload = {
                container_id: this.selectedContainer,
                stfId: this.selectedTransfer,
                url:'container-items'
            }
            this.$store.dispatch('disposalGet', payload).then( response => {
                this.containerItems = response.data;
                this.loading.dropdownOnFlight = false;
            }).catch( e => {
                console.log(e);
                this.loading.dropdownOnFlight = false;
            })
        },
        getDisposalWarehouses() {
            this.loading.dropdownOnFlight = true;
            this.$store.dispatch('getAllWarehouses', {
                service:1,
                disposal:1
            }).then(response => {
                this.disposalWarehouses = response.data;
                this.loading.dropdownOnFlight = false;
            }).catch(e => {
                console.log(e)
                this.loading.dropdownOnFlight = false;
            })
        },
        removeItem(id) {
            this.selectedItems = this.selectedItems.filter(e => e.id !== id);
        },
        storeDisposalItems() {
            const validationError = this.validateDisposal();
            if (!!validationError) {
                swal.fire("Unprocessable Content", validationError, "warning");
                return;
            } else {
                swal.fire({
                    title: 'Are you sure to declare the item for disposal?',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then(ans => {
                    if (ans.isConfirmed) {
                        this.loading.submitOnFlight = true;
                        this.selectedItems = this.selectedItems.map(e => {
                            if(!e.remarks) {
                                e.remarks = '';
                            }
                            return e;
                        })
                        let payload = {
                            disposal_items: this.selectedItems,
                            // container_id: this.selectedContainer,
                            disposal_remarks:this.disposalRemarks,
                            transferId:this.selectedTransfer.id,
                            disposal_src_wh: this.selectedTransfer.serving_warehouse_id,
                            disposal_dest_wh: this.selectedTransfer.warehouse_id,
                            url:'for-disposal-items'
                        }
            
                        this.$store.dispatch('disposalPost', payload).then(res => {
                            const message = res.data.message;
                            swal.fire("", `-${message}`, message.toLowerCase());
                            if (message == 'Success') {
                                this.$store.dispatch('getDisposalSp');
                                this.closeAddDisposal();
                            }
                        }).catch( e => {
                            console.log(e);
                            swal.fire("Error","-Something went wrong.", "error");
                        });
                    }
                });
            }
        },
        validateDisposal() {
            let message = '';
            if (this.selectedItems.length == 0 || this.selectedTransfer == null){
                message = "-Required Fields can't be empty";
            }
            const invalidQty =  this.selectedItems.map( e => {
                if(!e.quantity || e.quantity == 0 || e.quantity > e.remaining_qty)
                {
                    return e
                }  
            });

            if (_.compact(invalidQty).length > 0) {
                message =  "-Quantity cannot be zero or above remaining qty."
            }
            return message;
        },
        getStatus(status='') {
            this.selectedStatus=status;
            this.$store.commit('DISPOSAL_STATUS', status);
        },
        async exportDisposals() {
            this.loading.exportExcel = true;
            let exportHeadersAndData = {
                Disposals: {
                    headers: this.exportHeader,
                    data:[]
                }
            };
            let payload={
                status: this.GET_DISPOSAL_STATUS,
                url:'disposals-export'
            }
            await this.$store.dispatch('disposalGet', payload).then( async res => {
                let disposals = res.data;
                disposals.forEach( record => {
                    record.created_at = this.formatDate(record.created_at);
                    record.approved_date = this.formatDate(record.approved_date);
                });
                exportHeadersAndData.Disposals.data = disposals;
                await this.exportExcel(exportHeadersAndData, 'Diposals Details', 'F69F1A');
                this.loading.exportExcel = false;
            }).catch( e => {
                swal.fire('Error', '', 'error');
                console.log(e);
                this.loading.exportExcel = false;
            });
        },
        getAllActiveItems(){
            this.loading.dropdownOnFlight = true;
            this.$store.dispatch('getAllActiveItems',).then( res => {
                this.loading.dropdownOnFlight = false;
            }).catch( e => {
                console.log(e)
                this.loading.dropdownOnFlight = false;
            })
        },
        checkIfItemExists(itemId) {
            if (this.selectedItems) {
                let item  = this.selectedItems.find(e => e.id == itemId)
                if (item) {
                    return true;
                }
            } 
            return false;
        },
        getStockTransfers() {
            this.stockTransfersList = [];
            this.loading.dropdownOnFlight = true;
            let payload = {
                locationId: this.selectedLocation,
                url:'disposal-transfers'
            }
            this.$store.dispatch('disposalGet', payload).then( res => {
                this.stockTransfersList = res.data;
                this.loading.dropdownOnFlight = false;
            }).catch( e => {
                console.log(e);
                this.loading.dropdownOnFlight = false;
            })
        },
        getStockTransferItems(stockTransfer) {
            this.stockTransferItems = [];
            this.selectedItems = [];
            if (stockTransfer) {
                this.stockTransferItems = stockTransfer.transfer_items;
            }
        },
        async getSelectedItemDetails(item) {
            if (this.checkIfItemExists(item.id)) {
                swal.fire("", `"${item.model}" is already in the list`, 'info');
                return;
            }
            this.loading.itemDetailOnFlight = true;
            let payload = {
                stockTransferId:this.selectedTransfer.id,
                itemId: item.id,
                url:'disposal-item-details'
            }
            await this.$store.dispatch('disposalGet', payload).then( res => {
                let data = res.data;
                if (data) {
                    data.total_qty_disposed = data.total_qty_disposed ?? 0;
                    data.total_qty_recovered = data.total_qty_recovered ?? 0;
                    data.remaining_qty = item.original_qty - data.total_qty_disposed - data.total_qty_recovered - data.on_process_qty;
                }
                this.selectedItems.push(Object.assign({
                    id: item.id,
                    model: item.model,
                    name: item.name,
                    uom: item.uom,
                    type: item.type,
                    category: item.category,
                    original_qty: item.original_qty,
                    stock_transfer_id: item.stock_transfer_id
                }, data))
                    
            })
            
            this.loading.itemDetailOnFlight = false;
        },
        getLocations() {
            this.$store.dispatch('serviceGetAllLocations').then(response => {
                this.scLocations = response.data;
            })
        },
        getAllItemsInStockTransfer() {
            this.selectedItems = [];
            this.stockTransferItems.forEach( e => {
                this.getSelectedItemDetails(e);
            })
        }

    },
    watch: {
        'USER_ACCESS':{
            handler(val){
                if(val != "fail"){
                    this.userAccess = val.map(e=>e.actions_code);
                }
            }
        },
    },
    mounted(){
        this.$store.dispatch('getContainersSp');
        this.$store.dispatch('getWarehousesLists');
        this.getDisposalWarehouses();
        this.$store.dispatch('checkAccessUser',this.$router.currentRoute.params.id);
        this.getLocations();
        // this.getStockTransfers();
    },
    created(){
        this.getAllActiveItems();
    }
}
</script>

<style>
    .v-enter-active,
    .v-leave-active {
        transition: opacity 0.5s ease;
    }

    .v-enter-from,
    .v-leave-to {
        opacity: 0;
    }
</style>