
import Queue from "@/views/main/modules/Operations/Queue/QueueGroupComponent.vue";
import Queueing from "@/views/main/modules/Operations/Queue/QueueComponent.vue";
import ServiceQueueComponent from "@/views/main/modules/Operations/Queue/ServiceQueueComponent.vue";
import ServiceQueueDashboardComponent from "@/views/main/modules/Operations/Queue/ServiceQueueDashboardComponent.vue";
import DispatchQueueDashboardComponent from "@/views/main/modules/Operations/Queue/DispatchQueueDashboardComponent.vue";
import repository from "@/api/credentials";

const routes = [
    {
        path: "/operations/queue/",
        name: "warehousequeue",
        component: Queue,
        // beforeEnter: (to, from, next) => {
        //     repository.checkAccessUser(to.params.id).then((response) => {
        //         if (response.data == 'fail') {
        //             next('/no-access')
        //         } else {
        //             next()
        //         }
        //     }).catch(() => {

        //     })
        // },
        meta: {
            requiresAuth: true,
            title: 'Queue',
        }
    },
    {
        path: "/operations/queue/dispatch",
        name: "warehousequeue-dispatch",
        component: Queueing,
        // beforeEnter: (to, from, next) => {
        //     repository.checkAccessUser(to.params.id).then((response) => {
        //         if (response.data == 'fail') {
        //             next('/no-access')
        //         } else {
        //             next()
        //         }
        //     }).catch(() => {

        //     })
        // },
        meta: {
            requiresAuth: true,
            title: 'DISPATCH',
            url:'getAllQueueDispatches',
            status_color:['orange','green'],
            statuses:['FOR DISPATCH','ON GOING FOR DELIVERY'],
            realtime_event:'RealtimeDispatch',
            toggle_icon:'mdi-truck'
        }
    },
    {
        path: "/operations/queue/wpf-cf/pt",
        name: "warehousequeue-wpf-cf-pt",
        component: Queueing,
        // beforeEnter: (to, from, next) => {
        //     repository.checkAccessUser(to.params.id).then((response) => {
        //         if (response.data == 'fail') {
        //             next('/no-access')
        //         } else {
        //             next()
        //         }
        //     }).catch(() => {

        //     })
        // },
        meta: {
            requiresAuth: true,
            title: 'WPF-CF',
            url:'getAllQueueWpfCf',
            status_color:['#25a499','#1976d2','purple'],
            statuses:['PREPARING','CHECKING','CHECKED'],
            realtime_event:'RealtimeWarehouseQueueing',
            toggle_icon:'mdi-account-multiple',
            type_id:1,
            type_text:'PT'
        }
    },
    {
        path: "/operations/queue/wpf-cf/ht",
        name: "warehousequeue-wpf-cf-ht",
        component: Queueing,
        // beforeEnter: (to, from, next) => {
        //     repository.checkAccessUser(to.params.id).then((response) => {
        //         if (response.data == 'fail') {
        //             next('/no-access')
        //         } else {
        //             next()
        //         }
        //     }).catch(() => {

        //     })
        // },
        meta: {
            requiresAuth: true,
            title: 'WPF-CF',
            url:'getAllQueueWpfCf',
            status_color:['#25a499','#1976d2','purple'],
            statuses:['PREPARING','CHECKING','CHECKED'],
            realtime_event:'RealtimeWarehouseQueueingHT',
            toggle_icon:'mdi-account-multiple',
            type_id:2,
            type_text:'HT'
        }
    },
    {
        path: "/service/queue/",
        name: "serviceequeue",
        component: ServiceQueueComponent,
        // beforeEnter: (to, from, next) => {
        //     repository.checkAccessUser(to.params.id).then((response) => {
        //         if (response.data == 'fail') {
        //             next('/no-access')
        //         } else {
        //             next()
        //         }
        //     }).catch(() => {

        //     })
        // },
        meta: {
            requiresAuth: true,
            title: 'Aftersales',
            url:'getAllQueueAfterSales',
            status_color:['#25a499','#1976d2','purple'],
            statuses:['PREPARING','CHECKING','CHECKED'],
            realtime_event:'RealtimeServiceQueueing',
            toggle_icon:'mdi-account-multiple',
            type_id:0
        }
    },
    {
        path: "/service/queue/dashboard",
        name: "serviceequeuedashboard",
        component: ServiceQueueDashboardComponent,
        // beforeEnter: (to, from, next) => {
        //     repository.checkAccessUser(to.params.id).then((response) => {
        //         if (response.data == 'fail') {
        //             next('/no-access')
        //         } else {
        //             next()
        //         }
        //     }).catch(() => {

        //     })
        // },
        meta: {
            requiresAuth: true,
            title: 'Aftersales',
            url:'getAllQueueAfterSalesDashboard',
            status_color:['#25a499','#1976d2','purple'],
            statuses:['PREPARING','CHECKING','CHECKED'],
            realtime_event:'RealtimeServiceDashboardQueueing',
            toggle_icon:'mdi-account-multiple',
            type_id:0
        }
    },
    {
        path: "/dispatch/queue/dashboard",
        name: "dispatchqueuedashboard",
        component: DispatchQueueDashboardComponent,
        // beforeEnter: (to, from, next) => {
        //     repository.checkAccessUser(to.params.id).then((response) => {
        //         if (response.data == 'fail') {
        //             next('/no-access')
        //         } else {
        //             next()
        //         }
        //     }).catch(() => {

        //     })
        // },
        meta: {
            requiresAuth: true,
            title: 'Available Vehicles',
            url:'getAllQueueDispatchDashboard',
            realtime_event:'RealtimeDispatchDashboard',
            toggle_icon:'mdi-account-multiple',
        }
    }

]

export default routes;
