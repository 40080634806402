<template>
		<v-form ref="form" v-model="valid" lazy-validation>
			<v-card>
				<v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
				<span class="text-h5">Applicant Data</span>
				<v-row class="m-0">
					<v-col cols="pull-right-10 p-2">
					<v-btn
						text
						icon
						small
						color="gray"
						class="float-right"
						@click="closeDialog()"
					>
						<v-icon>mdi-close-circle</v-icon>
					</v-btn>
					</v-col>
				</v-row>
				</v-card-title>
				<v-container class="overflow-auto" fluid>
					<v-row class="pr-5">
						<v-col cols="12" lg="6">
							<div class="d-flex flex-row mb-1 ml-1">
								<BaseFileViewer v-if="ACTION != 'Submit'" :module="'applicant'" :dispatch="'getApplicantRequirementFiles'" id="fileViewer" class="mx-1 mt-1" :applicant_id="GET_EMPLOYEE_DATA_VIEW.id" />
								<ApplicantRequirements v-if="ACTION != 'Submit' && GET_HR_TAB_IS_ACTIVE =='req_for_approval' || GET_EMPLOYEE_DATA_VIEW.status_id == 7" id="fileViewer" class="mx-1 mt-1" :applicant_id="GET_EMPLOYEE_DATA_VIEW.applicant_id" :delete_url="'deleteApplicationRequirements'" />
							</div>
						</v-col>
                        <v-spacer/>
                        <v-col cols="12" sm="2" class="px-1">
							<v-text-field
								v-model="form.employee_id"
								outlined
								label="Employee ID"
								required
								readonly
								:disabled="
								actions == 'Update' || disabled_view ? true : false"
								:rules="errors.required"
								dense
							></v-text-field>
						</v-col>
					</v-row>
					<v-row class="ma-1">
						<v-col cols="12" sm="2" class="pa-0 pr-1">
							<v-text-field
								v-model="form.last_name"
								:rules="errors.required"
								:disabled="disabled_view"
								label="Last Name"
								required
								outlined
								dense
							></v-text-field>
						</v-col>
						<v-col cols="12" sm="2" class="pa-0 pr-1">
							<v-text-field
								v-model="form.first_name"
								:rules="errors.required"
								:disabled="disabled_view"
								label="First Name"
								outlined
								required
								dense
							></v-text-field>
						</v-col>
						<v-col cols="12" sm="2" class="pa-0 pr-1">
							<v-text-field
								v-model="form.middle_name"
								:rules="errors.required"
								:disabled="disabled_view"
								label="Middle Name"
								outlined
								required
								dense
							></v-text-field>
						</v-col>
						<v-col cols="12" sm="1" class="pa-0 pr-1">
							<v-menu
								v-model="date_of_birth_menu"
								:close-on-content-click="false"
								transition="scale-transition"
								max-width="290px"
								min-width="auto"
								ref="menu"
								offset-y
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-model="form.birth_date"
										:disabled="disabled_view"
										v-mask="dateMask"
										v-on="on"
										label="Date Of Birth"
										outlined
										dense
									></v-text-field>
								</template>

								<v-date-picker
									v-model="form.birth_date"
									@input="$refs.menu.save(form.birth_date),date_of_birth_menu = false"
									no-title
									scrollable
								>
								</v-date-picker>
							</v-menu>
						</v-col>
						<v-col cols="12" sm="2" class="pa-0 pr-1 d-flex">
							<v-autocomplete
								v-model="form.contract_type_id"
								:items="GET_CONTRACT_TYPE_SELECTION"
								:rules="errors.required"
								:disabled="disabled_view"
								label="Contract Type"
								item-text="text"
								item-value="value"
								outlined
								auto-select-first
								dense
								required
							></v-autocomplete>
						</v-col>
						<v-col cols="12" sm="1" class="pa-0 pr-1 d-flex">
							<v-autocomplete
								v-model="form.client_id"
								:items="GET_CLIENT_LIST_SELECTION"
								:rules="errors.required"
								:disabled="(form.contract_type_id == 2 || form.contract_type_id == 3) && disabled_view == false ? false : true"
								outlined
								auto-select-first
								label="Client List"
								item-text="text"
								item-value="value"
								dense
								required
							></v-autocomplete>
						</v-col>
                        <v-col cols="12" sm="2" class="pa-0 pr-1">
							<v-text-field
								v-model="form.applicant_id"
								outlined
								label="Application ID"
								required
								readonly
								:disabled="
								actions == 'Update' || disabled_view ? true : false"
								:rules="errors.required"
								dense
							></v-text-field>
						</v-col>
					</v-row>
					<v-row class="ma-1">
                    <v-col cols="12" sm="2" class="pa-0 pr-1">
								<v-menu
									:close-on-content-click="false"
									:return-value.sync="form.employment_date"
									transition="scale-transition"
									ref="menu"
									max-width="290px"
									min-width="auto"
									offset-y
								>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-model="form.employment_date"
										:disabled="disabled_view"
                                        :rules="errors.required"
										v-mask="'####-##-##'"
										label="Employment Date (YYYY-MM-DD)"
										outlined
										dense
										v-on="on"
									></v-text-field>
								</template>

								<v-date-picker
									v-model="form.employment_date"
									@input="$refs.menu.save(form.employment_date)"
									no-title
									scrollable
								>
								</v-date-picker>
							</v-menu>
						</v-col>
						<v-col cols="12" sm="2" class="pa-0 pr-1 d-flex">
							<v-menu
									:close-on-content-click="false"
									:return-value.sync="form.employment_start_date"
									transition="scale-transition"
									ref="start_menu"
									max-width="290px"
									min-width="auto"
									offset-y
								>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-model="form.employment_start_date"
										:disabled="disabled_view"
                                        :rules="errors.required"
										v-mask="'####-##-##'"
										label="Employee Start Date (YYYY-MM-DD)"
										outlined
										dense
										v-on="on"
									></v-text-field>
								</template>

								<v-date-picker
									v-model="form.employment_start_date"
									@input="$refs.start_menu.save(form.employment_start_date)"
									no-title
									scrollable
								>
								</v-date-picker>
							</v-menu>
						</v-col>


						<v-col cols="12" :sm="2" class="pa-0 pr-1">
							<v-autocomplete
								v-model="form.department_id"
								:items="GET_LIST_DEPARTMENT"
								:rules="errors.required"
								:disabled="disabled_view"
								@input="filterJobsByLevelAndDept()"
								item-text="text"
								item-value="value"
								label="Department"
								outlined
								auto-select-first
								required
								dense
							>
							</v-autocomplete>
						</v-col>
						<v-col cols="12" sm="2" class="pa-0 pr-1">
							<v-autocomplete
								v-model="form.store_branch_id"
								:items="GET_STORE_BRANCHES_SELECTION"
								:rules="errors.required"
								:disabled="disabled_view || selectedItem != 'Stores'"
								auto-select-first
								item-text="text"
								item-value="value"
								label="Store Branch"
								required
								outlined
								@input="filterJobsByLevelAndDept()"
								dense
							>
							</v-autocomplete>
						</v-col>
						<v-col cols="12" sm="2" class="pa-0 pr-1">
							<v-autocomplete
								v-model="form.job_level_id"
								:items="GET_JOB_LEVEL_SELECTION"
								:rules="errors.required"
								:disabled="disabled_view"
								@input="filterJobsByLevelAndDept()"
								label="Job Level"
								item-text="text"
								item-value="value"
								auto-select-first
								outlined
								required
								dense
							></v-autocomplete>
						</v-col>
						<v-col cols="12" sm="2" class="pa-0 pr-1">
							<v-autocomplete
								v-model="form.job_title_id"
								:items="job_title_selection"
								:rules="errors.required"
								:disabled="disabled_view"
								label="Position"
								item-text="text"
								item-value="value"
								@input="filterDepartment()"
								outlined
								auto-select-first
								dense
								required
							></v-autocomplete>

                			<span v-for="(error,i) in errors_position" class="text-danger" style="font-size:12px;" v-bind:key="i"> <v-icon small color="red">mdi-alert-circle</v-icon> {{error}}</span>

						</v-col>

					</v-row>

					<v-row class="mt-0">
						<v-col cols="12" sm="3" class="pa-0 pl-4 pb-3">
							<v-container fluid>
								<v-row >
									<v-col cols="12" sm="12" class="pa-0 pr-1">
										<v-autocomplete
										:items="GET_APPLICATION_STATUS_SELECTION"
										auto-select-first
										item-text="text"
										item-value="value"
										outlined
										label="Application Status"
										required
										:readonly="actions != 'Submit'"
										:rules="errors.required"
										v-model="form.status_id"
										dense
										:disabled="disabled_view"
										></v-autocomplete>
									</v-col>
								</v-row>
								<v-row>
									<v-hover v-model="isHover">
										<template v-slot="{ hover }">
										<v-card>
											<v-container fluid class="picture">
												<canvas class="d-none" ></canvas>
												<embed class="img-fluid w-100 h-100" :src="form.employee_image_preview"/>
												<div id="profile-picture" class="d-none"></div>
											</v-container>
											<v-fade-transition style="opacity:0.5;">
											<v-overlay v-if="hover" absolute :color="hover ? 'white' : ''"  class="d-flex flex-row justify-content-center align-items-center">
													<v-row>
														<v-tooltip bottom>
																<template v-slot:activator="{ on, attrs }" >
															<v-btn
																style="opacity:1;"
																class="ma-2"
																color="blue-grey"
																fab
																v-bind="attrs"
																v-on="on"
																:disabled="disabled_view"
															>
															<label for="upload-file"><span class="upload-file"><v-icon x-large>mdi-progress-upload</v-icon></span></label>
															</v-btn>
															</template>
															<span>Upload</span>
														</v-tooltip>

														<v-tooltip bottom>
															<template v-slot:activator="{ on, attrs }">
																<v-btn
																	class="ma-2"
																	color="blue-grey"
																	fab
																	@click="openCamera"
																	v-bind="attrs"
																	v-on="on"
																	:disabled="disabled_view"
																	>
																	<v-icon x-large>mdi-camera</v-icon>
																</v-btn>
															</template>
															<span>Camera Capture</span>
														</v-tooltip>

														<v-tooltip v-if="isViewBtn" bottom>
															<template v-slot:activator="{ on, attrs }">
																<v-btn
																	class="ma-2"
																	color="blue-grey"
																	fab
																	@click="image_view_dialog = true"
																	v-bind="attrs"
																	v-on="on"
																	:disabled="disabled_view"
																	>
																	<v-icon>mdi-eye</v-icon>
																</v-btn>
															</template>
															<span>View Image</span>
														</v-tooltip>
													</v-row>
												<input  class="d-none" id="upload-file" type="file" ref="fileInput" @change="uploadImage" accept="image/png, image/jpeg"/>
											</v-overlay>
											</v-fade-transition>
										</v-card>
										</template>
									</v-hover>
									<!-- <ImageUploadComponentVue @openCamera="openCamera" @uploadedImage="uploadedImage"></ImageUploadComponentVue> -->
								</v-row>
							</v-container>
						</v-col>
						<v-col cols="12" sm="9" class="pt-0">
							<v-card elevation="2" height="450px" width="99%" class="pa-0">
								<v-card-text class="pa-1">
									<v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" background-color="#424242" dark class="col-12" show-arrows>
										<v-tabs-slider color="cyan"></v-tabs-slider>
										<v-tab ripple v-for="(item, i) in items" :key="i" >
										{{ item }}
										</v-tab>
									</v-tabs>
									<v-tabs-items v-model="tab">

										<!-- Basic Info -->
										<v-tab-item>
											<v-container fluid style="height: 390px;" class="overflow-auto mt-1">
												<v-row>
													<v-col cols="12" sm="3" class="pa-0 pr-1">
														<v-text-field
															auto-select-first
															background-color="white" dense
															item-value="text"
															v-model="form.place_of_birth"
															label="Place of Birth"
															outlined
															:disabled="disabled_view">
														</v-text-field>
													</v-col>
													<v-col cols="12" sm="2" class="pa-0 pr-1">
														<v-autocomplete
														:items="gender_selection"
														auto-select-first
														outlined
														label="Gender"
														item-text="text"
														item-value="text"
														dense
														required
														v-model="form.gender"
														:disabled="disabled_view"
														></v-autocomplete>
													</v-col>
													<v-col cols="12" sm="2" class="pa-0 pr-1">
														<v-text-field
														outlined
														auto-select-first
														label="Age"
														item-text="text"
														item-value="text"
														dense
														readonly
														:rules="errors.age"
														v-mask="age_mask"
														v-model="form.age"
														:disabled="disabled_view"
														></v-text-field>
													</v-col>
													<v-col cols="12" sm="2" class="pa-0 pr-1">
														<v-autocomplete
														:items="GET_MARITAL_STATUS_SELECTION"
														outlined
														auto-select-first
														label="Marital Status"
														item-text="text"
														item-value="value"
														dense
														v-model="form.marital_status_id"
														:disabled="disabled_view"
														></v-autocomplete>
													</v-col>
													<v-col cols="12" sm="3" class="pa-0 pr-1">
														<v-text-field
														v-model="form.gcash_num"
														outlined
														label="GCASH No."
														v-mask="contact_num_mask.mobile"
														dense
														required
														:rules="errors.required"
														:disabled="disabled_view"
														></v-text-field>
													</v-col>
												</v-row>
												<v-row>
													<v-col cols="12" sm="3" class="pa-0 pr-1">
														<v-text-field
														v-model="form.mobile_num[0]"
														v-mask="contact_num_mask.mobile"
														:disabled="disabled_view"
														label="Mobile No."
														outlined
														dense
														></v-text-field>
													</v-col>
													<v-col cols="12" sm="3" class="pa-0 pr-1">
														<v-text-field
														v-model="form.mobile_num[1]"
														:disabled="disabled_view"
														v-mask="contact_num_mask.mobile"
														label="Mobile No. 2"
														outlined
														dense
														></v-text-field>
													</v-col>
													<v-col cols="12" sm="3" class="pa-0 pr-1">
														<v-text-field
														outlined
														label="Landline No."
														v-mask="contact_num_mask.landline"
														dense
														required
														v-model="form.landline_num"
														:disabled="disabled_view"
														>
													</v-text-field>
													</v-col>
													<v-col cols="12" sm="3" class="pa-0 pr-1">
														<v-text-field
														outlined
														label="Email"
														dense
														required
														:rules="errors.emailRules"
														v-model="form.email"
														:disabled="disabled_view"
														></v-text-field>
													</v-col>
												</v-row>
												<v-row>
													<v-col cols="12" sm="3" class="pa-0 pr-1">
														<v-text-field
														outlined
														label="Height"
														dense
														v-mask="height_weight_mask"
														:rules="errors.number"
														v-model="form.height"
														:disabled="disabled_view"
														>
														<template #append >
															<span>cm</span>
														</template>
														</v-text-field>
													</v-col>
													<v-col cols="12" sm="3" class="pa-0 pr-1">
														<v-text-field
														outlined
														label="Weight"
														dense
														v-mask="height_weight_mask"
														:rules="errors.number"
														v-model="form.weight"
														:disabled="disabled_view"
														>
														<template #append>
															<span>kg</span>
														</template>
														</v-text-field>
													</v-col>
													<v-col cols="12" sm="3" class="pa-0 pr-1">
														<v-autocomplete
														:items="GET_RELIGION_SELECTION"
														outlined
														auto-select-first
														label="Religion"
														item-text="text"
														item-value="value"
														dense
														required
														v-model="form.religion_id"
														:disabled="disabled_view"
														></v-autocomplete>
													</v-col>
													<v-col cols="12" sm="3" class="pa-0 pr-1">
														<v-autocomplete
														:items="GET_CITIZENSHIP_SELECTION"
														outlined
														auto-select-first
														label="Citizenship"
														item-text="text"
														item-value="value"
														dense
														required
														v-model="form.citizenship_id"
														:disabled="disabled_view"
														></v-autocomplete>
													</v-col>
												</v-row>
												<v-row>
													<v-col cols="12" sm="3" class="pa-0 pr-1">
														<v-text-field
														outlined
														label="SSS No."
														dense
														v-mask="sss_mask"
														required
														v-model="form.sss_num"
														:disabled="disabled_view"
														>
													</v-text-field>
													</v-col>
													<v-col cols="12" sm="3" class="pa-0 pr-1">
														<v-text-field
														outlined
														label="TIN No."
														dense
														required
														v-mask="tin_mask"
														v-model="form.tin_num"
														:disabled="disabled_view"
														>
														</v-text-field>
													</v-col>
													<v-col cols="12" sm="3" class="pa-0 pr-1">
														<v-text-field
														outlined
														v-mask="philhealth_num_mask"
														label="PhilHealth No."
														dense
														required
														v-model="form.philhealth_num"
														:disabled="disabled_view"
														>
													</v-text-field>
													</v-col>
													<v-col cols="12" sm="3" class="pa-0 pr-1">
														<v-text-field
														outlined
														label="PAGIBIG No."
														dense
														v-mask="pagibig_num_mask"
														required
														v-model="form.pagibig_num"
														:disabled="disabled_view"
														>
													</v-text-field>
													</v-col>
												</v-row>
												<v-row class="pa-0 pa-1">
													<v-card class="bg-ingco-alt py-2 w-100"   outlined tile elevation="0">
														<v-card-subtitle class="p-0">
															<span class="float-right mx-2">
																<v-btn fab dark small color="primary" style="height: 20px; width: 20px"
																	@click="addFamilyMember()" :disabled="disabled_view">
																	<v-icon dark small style="font-size: 12px">mdi-plus</v-icon>
																</v-btn>
															</span>
															<span class="pl-2 text-white">Family Members</span>
														</v-card-subtitle>
														<hr class="m-2 border-white" />
														<v-container fluid>
															<v-row v-for="(family_members, i) in form.employee_family_members"
																	:key="i">
																<v-col sm="3">
																	<v-text-field
																		background-color="white" dense
																		v-model="family_members.name"
																		label="Name"
																		outlined
																		:rules="errors.required"
																		:disabled="disabled_view">
																	</v-text-field>
																</v-col>
																<v-col sm="3">
																	<v-text-field
																		background-color="white" dense
																		v-model="family_members.address"
																		label="Address"
																		outlined
																		:rules="errors.required"
																		:disabled="disabled_view">
																	</v-text-field>
																</v-col>
																<v-col sm="2">
																	<v-autocomplete
																		:items="relationship_selection"
																		item-value="text"
																		item-text="text"
																		background-color="white" dense
																		v-model="family_members.relationship"
																		label="Relationship"
																		outlined
																		auto-select-first
																		:rules="errors.required"
																		:disabled="disabled_view">
																	</v-autocomplete>
																</v-col>
																<v-col sm="4" class="d-flex justify-between">
																	<v-text-field
																	class="pr-2"
																		background-color="white" dense
																		v-model="family_members.occupation"
																		label="Occupation"
																		outlined
																		:rules="errors.required"
																		:disabled="disabled_view">
																	</v-text-field>
																	<div class="pt-2 pr-1">
																		<v-btn fab dark small color="primary"  style="height: 20px; width: 20px"
																			@click="removeFamilyMember(i)" :disabled="disabled_view">
																			<v-icon dark small style="font-size: 12px" >mdi-minus</v-icon>
																		</v-btn>
																	</div>
																</v-col>
															</v-row>
														</v-container>
													</v-card>
												</v-row>
												<v-row class="pa-0 pa-1">
													<v-card class="bg-ingco-alt py-2 w-100" outlined tile elevation="0">
														<v-card-subtitle class="p-0">
															<span class="float-right mx-2">
																<v-btn fab dark small color="primary" style="height: 20px; width: 20px"
																	@click="addEmergency_Contact()" :disabled="disabled_view">
																	<v-icon dark small style="font-size: 12px">mdi-plus</v-icon>
																</v-btn>
															</span>
															<span class="pl-2 text-white">Emergency Contact</span>
														</v-card-subtitle>
														<hr class="m-2 border-white" />
														<v-container fluid>
															<v-row v-for="(emergency_contact, i) in form.employee_emergency_contacts"
																	:key="i">
																<v-col sm="3">
																	<v-text-field
																		background-color="white" dense
																		v-model="emergency_contact.name"
																		label="Name"
																		outlined
																		:rules="errors.required"
																		:disabled="disabled_view">
																	</v-text-field>
																</v-col>
																<v-col sm="3">
																	<v-text-field
																		background-color="white" dense
																		v-model="emergency_contact.address"
																		label="Address"
																		outlined
																		:rules="errors.required"
																		:disabled="disabled_view">
																	</v-text-field>
																</v-col>
																<v-col sm="2">
																	<v-autocomplete
																		:items="relationship_selection"
																		item-text="text"
																		item-value="text"
																		background-color="white" dense
																		v-model="emergency_contact.relationship"
																		label="Relationship"
																		outlined
																		auto-select-first
																		:rules="errors.required"
																		:disabled="disabled_view">
																	</v-autocomplete>
																</v-col>
																<v-col sm="4" class="d-flex justify-between">
																	<v-text-field
																		class="pr-2"
																		background-color="white" dense
																		v-model="emergency_contact.contact_num"
																		label="Contact No."
																		outlined
																		v-mask="'+63(###)###-####'"
																		:rules="errors.required"
																		:disabled="disabled_view">
																	</v-text-field>
																	<div class="pt-2 pr-1">
																		<v-btn fab dark small color="primary" style="height: 20px; width: 20px"
																			@click="removeEmergency_Contact(i)" :disabled="disabled_view">
																			<v-icon dark small style="font-size: 12px">mdi-minus</v-icon>
																		</v-btn>
																	</div>
																</v-col>
																<!-- <v-col sm="1" class="d-flex  align-center justify-content-center">
																	<v-btn fab dark small color="primary" style="height: 20px; width: 20px"
																		@click="removeEmergency_Contact(i)" :disabled="disabled_view">
																		<v-icon dark small style="font-size: 12px">mdi-minus</v-icon>
																	</v-btn>
																</v-col> -->
															</v-row>
														</v-container>
													</v-card>
												</v-row>
											</v-container>
										</v-tab-item>

										<!-- Address -->
										<v-tab-item>
											<v-container fluid style="height: 400px;" class="overflow-auto  mt-1">
												<v-row>
													<v-card class="bg-ingco-alt py-2 w-100" outlined tile elevation="0">
														<v-card-subtitle class="p-0">
															<span class="pl-2 text-white">Permanent Address</span>
														</v-card-subtitle>
														<hr class="m-2 border-white" />
														<v-container fluid>
															<v-row>
																<v-col sm="3">
																	<v-autocomplete :items="GET_ALL_COUNTRIES"
																		auto-select-first
																		background-color="white" dense disabled
																		v-model="form.permanent.country_code"
																		label="Select Country"
																		:rules="errors.required"
																		@input="updateRegions('permanent')"
																		outlined>
																	</v-autocomplete>
																</v-col>
																<v-col sm="3">
																	<v-autocomplete
																		:items="permanent.reg_list"
																		auto-select-first
																		item-text="text"
																		item-value="value"
																		background-color="white" dense
																		v-model="form.permanent.region_code"
																		label="Select Region"
																		:rules="errors.required"
																		@input="updateProvinces('permanent')"
																		outlined
																		:disabled="disabled_view">
																	</v-autocomplete>
																</v-col>
																<v-col sm="3">
																	<v-autocomplete
																		auto-select-first
																		:items="permanent.province_list"
																		item-text="text"
																		item-value="value"
																		background-color="white" dense
																		v-model="form.permanent.province_code"
																		label="Select Province"
																		:rules="errors.required"
																		@input="updateCities('permanent')"
																		outlined
																		:disabled="disabled_view">
																	</v-autocomplete>
																</v-col>
																<v-col sm="3">
																	<v-autocomplete
																		auto-select-first
																		:items="permanent.city_list"
																		item-text="text"
																		item-value="value"
																		background-color="white" dense
																		v-model="form.permanent.city_code"
																		label="Select City"
																		@input="selectRegion('permanent')"
																		outlined
																		:rules="errors.required"
																		:disabled="disabled_view">
																	</v-autocomplete>
																</v-col>
																<v-col cols="12" sm="12" class="pa-0 pr-1">
																	<v-text-field
																	outlined
																	label="Address"
																	background-color="white"
																	required
																	:rules="errors.required"
																	v-model="form.permanent.address"
																	dense
																	:disabled="disabled_view"
																	></v-text-field>
																</v-col>
															</v-row>
													</v-container>
													</v-card>
												</v-row>
												<v-row>
													<v-card class="bg-ingco-alt py-2 w-100 mt-2" outlined tile elevation="0">
														<v-card-subtitle class="p-0">
															<span class="float-right mx-2">
																<!-- <v-btn fab dark small color="primary" style="height: 20px; width: 20px"
																	@click="addCurrentAddress()" :disabled="disabled_view">
																	<v-icon dark small style="font-size: 12px">mdi-plus</v-icon>
																</v-btn>
																<v-btn fab dark small color="primary" style="height: 20px; width: 20px"
																	@click="removeCurrentAddress()" :disabled="disabled_view">
																	<v-icon dark small style="font-size: 12px">mdi-minus</v-icon>
																</v-btn> -->
															</span>
															<span class="pl-2 text-white">Current Address</span>
														</v-card-subtitle>
														<hr class="m-2 border-white" />
														<v-container fluid>
															<v-row>
																<v-col sm="3">
																	<v-autocomplete :items="GET_ALL_COUNTRIES"
																		auto-select-first
																		background-color="white" dense disabled
																		v-model="form.current.country_code"
																		item-text="text"
																		item-value="value"
																		label="Select Country"
																		:rules="errors.required"
																		@input="updateRegions('current')"
																		outlined>
																	</v-autocomplete>
																</v-col>
																<v-col sm="3">
																	<v-autocomplete
																		auto-select-first
																		:items="current.reg_list"
																		background-color="white" dense
																		v-model="form.current.region_code"
																		item-text="text"
																		item-value="value"
																		label="Select Region"
																		:rules="errors.required"
																		@input="updateProvinces('current')"
																		outlined
																		:disabled="disabled_view">
																	</v-autocomplete>
																</v-col>
																<v-col sm="3">
																	<v-autocomplete
																		auto-select-first
																		:items="current.province_list"
																		background-color="white" dense
																		v-model="form.current.province_code"
																		item-text="text"
																		item-value="value"
																		label="Select Province"
																		:rules="errors.required"
																		@input="updateCities('current')"
																		outlined
																		:disabled="disabled_view">
																	</v-autocomplete>
																</v-col>

																<v-col sm="3">
																	<v-autocomplete
																		auto-select-first
																		:items="current.city_list"
																		background-color="white" dense
																		v-model="form.current.city_code"
																		item-text="text"
																		item-value="value"
																		label="Select City"
																		:rules="errors.required"
																		@input="selectRegion('current')"
																		outlined
																		:disabled="disabled_view">
																	</v-autocomplete>
																</v-col>
																<v-col cols="12" sm="12" class="pa-0 pr-1">
																	<v-text-field
																	outlined
																	label="Address"
																	required
																	background-color="white"
																	:rules="errors.required"
																	v-model="form.current.address"
																	dense
																	:disabled="disabled_view"
																	></v-text-field>
																</v-col>
															</v-row>
														</v-container>
													</v-card>
												</v-row>
											</v-container>
										</v-tab-item>

										<!-- Education -->
										<v-tab-item>
											<v-container fluid style="height: 320px;" class="overflow-auto mt-1">
												<v-row>
													<v-card class="bg-ingco-alt py-2 w-100" outlined tile elevation="0">
														<v-card-subtitle class="p-0">
															<span class="float-right mx-2">
																<v-btn fab dark small color="primary" style="height: 20px; width: 20px"
																	@click="addSchoolAttended()" :disabled="disabled_view">
																	<v-icon dark small style="font-size: 12px">mdi-plus</v-icon>
																</v-btn>
																<!-- <v-btn fab dark small color="primary" style="height: 20px; width: 20px"
																	@click="removeSchoolAttended(id = null)" :disabled="disabled_view">
																	<v-icon dark small style="font-size: 12px">mdi-minus</v-icon>
																</v-btn> -->
															</span>
															<span class="pl-2 text-white">Schools Attended</span>
														</v-card-subtitle>
														<hr class="m-2 border-white" />
														<v-container fluid>
															<v-row v-for="(school_attended, i) in form.employee_schools_attendeds"
																	:key="i">
																<v-col sm="3">
																	<v-text-field
																		background-color="white" dense
																		v-model="school_attended.school_attended"
																		label="School Name"
																		:disabled="disabled_view"
																		outlined>
																	</v-text-field>
																</v-col>
																<v-col sm="3">
																	<v-text-field
																		background-color="white" dense
																		v-model="school_attended.field_course"
																		label="Field/Course"
																		outlined
																		:disabled="disabled_view">
																	</v-text-field>
																</v-col>
																<v-col sm="3">
																	<v-text-field
																	label="Year From"
																	background-color="white"
																	append-icon="mdi-calendar-clock"
																	:rules="errors.year"
																	outlined
																	v-mask="'####'"
																	dense
																	v-model="school_attended.year_from"
																	:disabled="disabled_view"
																	></v-text-field>
																</v-col>
																<v-col sm="3" class="d-flex justify-between">
																	<v-text-field
																	label="Year To"
																	class="pr-2"
																	background-color="white"
																	append-icon="mdi-calendar-clock"
																	:rules="errors.year"
																	outlined
																	v-mask="date_from_to_year_mask"
																	dense
																	v-model="school_attended.year_to"
																	:disabled="disabled_view"
																	></v-text-field>
																	<div class="pr-1 pt-2">
																		<v-btn fab dark small color="primary" style="height: 20px; width: 20px"
																			@click="removeSchoolAttended(i)" :disabled="disabled_view">
																			<v-icon dark small style="font-size: 12px">mdi-minus</v-icon>
																		</v-btn>
																	</div>

																</v-col>
																<v-col sm="12">
																	<v-textarea
																		background-color="white" height="100px" dense style="height:50%"
																		v-model="school_attended.degree_earned"
																		label="Degree Earned / Honors Received"
																		outlined
																		:disabled="disabled_view" no-resize >
																	</v-textarea>
																</v-col>
															</v-row>
														</v-container>
													</v-card>
												</v-row>
												<v-row>
													<v-card class="bg-ingco-alt py-2 mt-1 w-100" outlined tile elevation="0">
														<v-card-subtitle class="p-0">
															<span class="float-right mx-2">
																<v-btn fab dark small color="primary" style="height: 20px; width: 20px"
																	@click="addProfessionalReferences()" :disabled="disabled_view">
																	<v-icon dark small style="font-size: 12px">mdi-plus</v-icon>
																</v-btn>
															</span>
															<span class="pl-2 text-white">Professional References</span>
														</v-card-subtitle>
														<hr class="m-2 border-white" />
														<v-container fluid>
															<v-row v-for="(professional_references, i) in form.employee_professional_references"
																	:key="i">
																<v-col sm="3">
																	<v-text-field
																	:rules="errors.required"
																		background-color="white" dense
																		v-model="professional_references.name"
																		label="Name"
																		:disabled="disabled_view"
																		outlined>
																	</v-text-field>
																</v-col>
																<v-col sm="3">
																	<v-text-field
																		:rules="errors.required"
																		background-color="white" dense
																		v-model="professional_references.address"
																		label="Address"
																		outlined
																		:disabled="disabled_view">
																	</v-text-field>
																</v-col>
																<v-col sm="3">
																	<v-text-field
																		background-color="white" dense
																		v-model="professional_references.contact_num"
																		label="Contact No. "
																		outlined
																		v-mask="'+63(###)###-####'"
																		:rules="errors.required"
																		:disabled="disabled_view">
																	</v-text-field>
																</v-col>
																<v-col sm="3" class="d-flex justify-between">
																	<v-text-field
																		:rules="errors.required"
																		class="pr-2"
																		background-color="white" dense
																		v-model="professional_references.occupation"
																		label="Occupation"
																		outlined
																		:disabled="disabled_view">
																	</v-text-field>
																	<div class="pr-1 pt-2">
																		<v-btn fab dark small color="primary" style="height: 20px; width: 20px"
																			@click="removeProfessionalReferences(i)" :disabled="disabled_view">
																			<v-icon dark small style="font-size: 12px">mdi-minus</v-icon>
																		</v-btn>
																	</div>
																</v-col>
															</v-row>
														</v-container>
													</v-card>
												</v-row>
											</v-container>
										</v-tab-item>

										<!-- Employment -->
										<v-tab-item>
											<v-container fluid style="height: 320px;" class="overflow-auto mt-1">
												<v-row>
													<v-card class="bg-ingco-alt py-2 w-100" outlined tile elevation="0">
														<v-card-subtitle class="p-0">
															<span class="float-right mx-2">
																<v-btn fab dark small color="primary" style="height: 20px; width: 20px"
																	@click="addPreviousEmployment()" :disabled="disabled_view">
																	<v-icon dark small style="font-size: 12px">mdi-plus</v-icon>
																</v-btn>
															</span>
															<span class="pl-2 text-white">Previous Employment</span>
														</v-card-subtitle>
														<hr class="m-2 border-white" />
														<v-container fluid>
															<v-row v-for="(previous_employment, i) in form.employee_previous_employments"
																	:key="i">
																<v-col sm="3">
																	<v-text-field
																		background-color="white" dense
																		v-model="previous_employment.prev_employer_name"
																		label="Name of Previous Employer"
																		:disabled="disabled_view"
																		outlined>
																	</v-text-field>
																</v-col>
																<v-col sm="2">
																	<v-text-field
																		background-color="white" dense
																		v-model="previous_employment.previous_employment_position"
																		label="Previous Position"
																		:disabled="disabled_view"
																		outlined>
																	</v-text-field>
																</v-col>
																<v-col sm="2">
																	<v-menu
																		ref="date_from_menu"
																		v-model="previous_employment.date_from_menu"
																		:close-on-content-click="false"
																		:nudge-right="40"
																		transition="scale-transition"
																		offset-y
																		min-width="auto"
																	>
																		<template v-slot:activator="{ on, attrs }">
																			<v-text-field
																			v-model="previous_employment.year_month_from"
																				readonly
																				outlined
																				dense
																				background-color="white"
																				append-icon="mdi-calendar-clock"
																				v-on="on"
																				:disabled="disabled_view"
																				label="Year-Month From"
																			></v-text-field>
																		</template>

																		<v-date-picker
																			v-model="previous_employment.year_month_from"
																			@input="previous_employment.date_from_menu == false"
																			no-title
																			type="month"
																			:max="max_date"
																			scrollable
																		>
																		</v-date-picker>
																	</v-menu>
																</v-col>
																<v-col sm="2">
																<v-menu
																	ref="date_to_menu"
																	v-model="previous_employment.date_to_menu"
																	:close-on-content-click="false"
																	:nudge-right="40"
																	transition="scale-transition"
																	offset-y
																	min-width="auto"
																	>
																		<template v-slot:activator="{ on, attrs }">
																			<v-text-field
																			v-model="previous_employment.year_month_to"
																				readonly
																				outlined
																				dense
																				background-color="white"
																				append-icon="mdi-calendar-clock"
																				v-on="on"
																				:disabled="disabled_view"
																				label="Year-Month To"
																			></v-text-field>
																		</template>
																		<v-date-picker
																			v-model="previous_employment.year_month_to"
																			@input="previous_employment.date_to_menu == false"
																			no-title
																			type="month"
																			:max="max_date"
																			scrollable
																		>
																		</v-date-picker>
																	</v-menu>
																</v-col>
																<v-col sm="3" class="d-flex justify-between">
																	<v-text-field
																		background-color="white" dense
																		class="pr-2"
																		v-model="previous_employment.monthly_salary"
																		label="Monthly Salary"
																		outlined
																		@blur="formatNumber(i)"
																		:disabled="disabled_view">
																		<template #prepend-inner>
																			<span class="mt-1">&#8369;</span>
																		</template>
																	</v-text-field>
																	<div class="pr-1 pt-2">
																		<v-btn fab dark small color="primary" style="height: 20px; width: 20px"
																			@click="removePreviousEmployment(i)" :disabled="disabled_view">
																			<v-icon dark small style="font-size: 12px">mdi-minus</v-icon>
																		</v-btn>
																	</div>
																</v-col>
																<v-col sm="12">
																	<v-textarea
																		background-color="white" height="70px" dense
																		v-model="previous_employment.reason_for_leaving"
																		label="Reason For Leaving"
																		outlined
																		:disabled="disabled_view" no-resize
																	>
																</v-textarea>
																</v-col>
															</v-row>
														</v-container>
													</v-card>
												</v-row>
											</v-container>
										</v-tab-item>
                                        <!-- Others -->
										<v-tab-item>
											<v-container fluid style="height: 390px;" class="overflow-auto mt-1">
												<v-row>
													<v-col cols="12" sm="12" class="pa-0 pr-1">
														<v-textarea
															v-model="form.remarks"
															:disabled="disabled_view"
															background-color="white"
															item-value="text"
															label="Remarks"
															outlined
                                                            dense
                                                            >
														</v-textarea>
														<v-textarea
															v-model="form.back_out_reason"
															:disabled="disabled_view"
															label="Reason for Backing Out"
															background-color="white"
															item-value="text"
                                                            rows="1"
                                                            auto-grow
															outlined
                                                            readonly
                                                            dense
                                                            >
														</v-textarea>
													</v-col>
												</v-row>
											</v-container>
										</v-tab-item>
									</v-tabs-items>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>
				</v-container>

			<v-card-actions class="text-center" style="border-top:1px solid #dee2e6;">
				<v-row class="ma-1">
					<v-col cols="12">
						<v-container fluid class="pa-0">
                            <v-col cols="12" class="d-flex flex-row justify-content-start align-center pl-3 pa-0">
                                <v-switch
                                    v-if="form.status_id == 6  && actions =='Approve'"
                                    v-model="form.isCreateAccount"
                                    false-value="0"
                                    true-value="1"
                                >
                                    <template #label>
                                        <span style="font-size:14px; margin-top:10px;">With Account</span>
                                    </template>
                                </v-switch>
                                <v-switch
                                    v-if="form.status_id == 6  && actions =='Approve'"
                                    v-model="form.withCPanelAcct"
                                    false-value="0"
                                    true-value="1"
                                    class="ml-3"
                                >
                                    <template #label>
                                        <span style="font-size:14px; margin-top:10px;">With Email</span>
                                    </template>
                                </v-switch>
                                <v-row>
                                    <v-col style="margin-right:250px;">
                                        <v-btn
                                                v-if="form.status_id == 6  && actions =='Approve' && GET_HR_TAB_IS_ACTIVE == 'req_for_approval'"
                                                :loading="loading.button"
                                                @click="approveApplicantData()"
                                            >
                                                <span>Approve</span>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
							<span class="pr-12" v-if="actions != 'Submit'">&nbsp;</span>

							<v-btn
								v-if="form.status_id == 1 && actions =='Approve'"
								:loading="loading.button"
								@click="changeApplicantStatus('Shortlisted')"
								color="#ffa602"
                                class="mx-2"
							>
								<span>Shortlisted</span>
							</v-btn>

							<v-btn
								v-if="form.status_id == 1 && actions =='Approve' || form.status_id == 2 && actions =='Approve'"
								:loading="loading.button"
								@click="changeApplicantStatus('Passed')"
								color="#00cbfd"
                                class="mx-2"
							>
								<span>Passed</span>
							</v-btn>

							<v-btn
								v-if="form.status_id == 1 && actions =='Approve' || form.status_id == 2 && actions =='Approve'"
								:loading="loading.button"
								@click="changeApplicantStatus('Failed')"
								color="#f55246"
                                class="mx-2"
							>
								<span>Failed</span>
							</v-btn>

							<v-btn
								v-if="form.status_id == 3 && actions =='Approve'"
								:loading="loading.button"
								@click="changeApplicantStatus('For Requirements')"
								color="#af02ff"
                                class="mx-2"
							>
								<span>For Requirements</span>
							</v-btn>

							<v-btn
								v-if="form.status_id != 5 && actions =='Approve'"
								:loading="loading.button"
								@click="applicant_back_out.dialog = true"
								color="#f55246"
                                class="mx-2"
							>
								<span>Backout</span>
							</v-btn>

							<v-btn
								v-if="actions == 'Update'"
								:loading="loading.button"
								@click="updateApplicantData()"
							>
								<span>{{ actions }}</span>
							</v-btn>

							<span class="pr-12" v-if="actions != 'Submit'">&nbsp;</span>

						</v-container>
					</v-col>
				</v-row>
			</v-card-actions>
			</v-card>

			<v-dialog v-model="image_view_dialog" persistent :width="height('20%')">
				<v-card>
					<v-card-title>
						<v-row class="m-0">
							<v-col cols="pull-right-10">
								<v-btn
									text
									icon
									small
									color="gray"
									class="float-right"
									@click="image_view_dialog = false"
								>
									<v-icon>mdi-close-circle</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</v-card-title>
					<v-card-text>
						<embed :src="form.employee_image_preview" width="100%" height="auto"/>
					</v-card-text>
				</v-card>
			</v-dialog>

			<v-dialog v-model="signature_view_dialog" persistent :width="height('50%')">
				<v-card>
					<v-card-title>
						<span>Employee Signature</span>
						<v-row class="m-0">
							<v-col cols="pull-right-10">
								<v-btn
									text
									icon
									small
									color="gray"
									class="float-right"
									@click="signature_view_dialog = false"
								>
									<v-icon>mdi-close-circle</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</v-card-title>
					<v-card-text>
					<v-container>
						<v-row class="m-0">
							<v-col cols="pull-right-10">
								<v-row>
									<v-col id="box">
										<canvas class="signature-canvas" id="signatureImage" ref="signaturePadCanvas" :style="{width: box_width+'px', height: '200px', backgroundColor: 'white'}" ></canvas>
									</v-col>
									<!-- <v-btn @click="drawSignature()">Draw Signature</v-btn> -->

								</v-row>
								<v-row>
									<v-col class="mt-2">
										<v-btn @click="clearSignature()" class="mr-1">Clear</v-btn>
										<v-btn @click="saveSignature()">Save</v-btn>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-container>
					</v-card-text>
				</v-card>
			</v-dialog>

			<v-dialog v-model="print_ID_view" persistent :width="height('50%')">
				<v-card>
					<v-card-title>
						<span>ID Preview</span>
						<v-row class="m-0">
							<v-col cols="pull-right-10">
								<v-btn
									text
									icon
									small
									color="gray"
									class="float-right"
									@click="print_ID_view = false"
								>
									<v-icon>mdi-close-circle</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</v-card-title>
					<v-card-text>
					<v-container>
						<v-row class="m-0">
							<v-col cols="pull-right-10">
								<v-row>
									<v-col id="box">
										<canvas ref="print_id_canvas"></canvas>
    									<img :src="modifiedImage" />
									</v-col>
									<!-- <v-btn @click="drawSignature()">Draw Signature</v-btn> -->
								</v-row>
								<v-row>
									<v-col class="mt-2">
										<!-- <v-btn @click="clearSignature()" class="mr-1">Clear</v-btn>
										<v-btn @click="saveSignature()">Save</v-btn> -->
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-container>
					</v-card-text>
				</v-card>
			</v-dialog>

			<!-- Dialog For Camera Open -->
			<v-dialog v-model="camera_dialog" :width="height('30%')" persistent>
				<v-card>
					<v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
						<span class="text-h5">Camera Picture Upload</span>
						<v-row class="m-0">
							<v-col cols="pull-right-10 p-2">
								<v-btn
								text
								icon
								small
								color="gray"
								class="float-right"
								@click="close_camera_dialog()"
								>
								<v-icon>mdi-close-circle</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</v-card-title>

					<v-card-text>
						<v-container fluid>
							<v-row>
								<v-col>
									<video ref="videos" autoplay class="camera">Stream unavailable</video>
								</v-col>
							</v-row>
							<v-row>
								<v-col class="text-center">
									<v-btn @click="takePicture"><v-icon>mdi-camera</v-icon></v-btn>
								</v-col>
							</v-row>
						</v-container>
					</v-card-text>

					<v-divider></v-divider>
				</v-card>
			</v-dialog>

            <!-- Back Out Reason Dialog -->
			<v-dialog v-model="applicant_back_out.dialog" :width="height('30%')" persistent>
				<v-card>
                    <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                    <span class="text-h5">Applicant Back Out</span>
                    <v-row class="m-0">
                        <v-col cols="pull-right-10 p-2">
                        <v-btn text icon small color="gray" class="float-right" @click="closeDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                        </v-col>
                    </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-container fluid class="py-5" >
                            <v-row>
                                <v-col cols="12">
                                    <v-textarea
                                        v-model="applicant_back_out.reason"
                                        :rules="errors.required"
                                        label="Reason"
                                        outlined
                                        dense
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider class="pa-0"></v-divider>
                    <v-card-actions class="text-center">
                    <v-row class="ma-1">
                        <v-col cols="12">
                            <v-btn class="float-center" @click="changeApplicantStatus('Backout')" :loading="loading.button">
                                <span>Submit</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                    </v-card-actions>
                </v-card>
			</v-dialog>
		</v-form>
</template>
<script>

	import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
	import SignaturePad from 'signature_pad';
	// import * as tf from '@tensorflow/tfjs';
	// import * as cocoSsd from '@tensorflow-models/coco-ssd';
	// import Tesseract from 'tesseract.js';

	import BaseFileViewer from "@/views/main/layouts/dialogs/HR/RecruitmentSystem/ApplicantBaseFilesViewer.vue";
	import ApplicantRequirements from "@/views/main/layouts/dialogs/HR/RecruitmentSystem/ApplicantRequirementsFileViewer.vue"

	import Swal from "sweetalert2";
	import { mapGetters } from "vuex";

	export default {
		mixins: [SharedFunctionsComponentVue],
	components: {
		BaseFileViewer,
		ApplicantRequirements
    },
	data() {
		return {
			// model: null,
			isHover: false,
			valid: false,
			overlay: false,
			disabled_view: false,
			gender_selection: [
			{text : 'Male', value: 1},
			{text : 'Female', value: 2}
			],
			errors: {
				required: [(v) => !!v || "Name is required"],
				age: [(v) => v < 90 || "Age Limit"],
				year: [
					(v) => v.length < 5  || "4 Maximum length",
					(v) => !isNaN(v) || "Input must be a number",
				],
				number: [
					(v) => !isNaN(v) || "Input must be a number",
				],
				mobile_num: [
					(v) =>  v.length == 11  || " 12 Digits Maximum lenght",
					(v) => !isNaN(v) || "Input must be a number",
				],
				emailRules: [
					(v) => !!v || "Email is required",
					v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
				]
			},
			camera_dialog: false,
			form:{},
			canvas:null,
			video:null,
			upload:true,
			image_view_dialog: false,
			signature_view_dialog: false,
			print_ID_view: false,
			isViewBtn: false,

			permanent:{
				reg_list: [],
				province_list: [],
				city_list: [],
			},
			current:{
				reg_list: [],
				province_list: [],
				city_list: [],
			},

			max_addition_count: 3,

			dateMask: '####-##-##',
			date_from_to_year_mask: '####',
			contact_num_mask:{
				mobile: '+63(###)###-####',
				landline: '(##)###-####'
			},
			tin_mask: '###-###-###-#####',
			sss_mask:'##-#######-#',
			philhealth_num_mask:'##-#########-#',
			pagibig_num_mask:'####-####-####',
			age_mask: '##',
			height_weight_mask: '###',

			job_title_selection :[],
			contract_type_selection :[],
			citizenship_selection:[],
			tab: 0,
			items: ["Basic Info","Address","Education","Employment","Others"],
            max_date: this.$dayjs().subtract(this.$dayjs().utcOffset(), 'minute').format('YYYY-MM-DD'),
			employee_superior_selection: [],
			birth_date_picker:false,
			relationship_selection:[
				{ text: 'Father' },
				{ text: 'Mother' },
				{ text: 'Son' },
				{ text: 'Daughter' },
				{ text: 'Husband' },
				{ text: 'Wife' },
				{ text: 'Brother' },
				{ text: 'Sister' },
				{ text: 'Grandfather' },
				{ text: 'Grandmother' },
				{ text: 'Uncle' },
				{ text: 'Aunt' },
				{ text: 'Nephew' },
				{ text: 'Niece' },
				{ text: 'Cousins' },

			],
			date_of_birth_menu: false,
			emp_id: "",
			uploadedFiles: null,
			uploads_text:[
				{title:'Attachment',action:'attachment'},
				{title:'Camera',action:'camera'},
				{title:'View Files',action:'fileViewer'},
			],
			signature_items:["ID Picture","Signature"],
			signature_tab:0,
			iconColor: 'black',
			signaturePad: null,
			signatureDataUrl:null,
			box_width:0,
			modifiedImage: null,
			backgroundImage: '',
			backgroundColor: [255, 255, 255],
			camera_used_for:'',
			history_headers:[
				 	{ text: 'Position', align: 'left',value:'job_title_name' },
					{ text: 'Department', align: 'left',value:'department_name' },
					{ text: 'Status', align: 'left',value:'status_name' },
					{ text: 'Hired Date', align: 'left',value:'hired_date' },
					{ text: 'Retirement Date', align: 'left', value: 'retirement_date'},
			],
			positionCheckerCount:[],
			errors_position:[],
			component_dispatches:[
				'getDepartment',
                'getApplicationStatusSelection',
				'getEmployeeReligionSelection',
				'getEmployeeMaritalStatusSelection',
				'getEmployeeCitizenshipSelection',
				'getApplicationFormJobTitlesSelection',
				'getEmployeeContractTypeSelection',
				'getJobLevelSelection',
				'getCountries',
				'getRegions',
				'getProvince',
				'getCities',
				'getClientListSelection',
				'getStoreBranchesSelectionSelection',
			] ,
			id_templates : [],
			id_preview:{
				front:'',
				back:''
			},
			loading:{
				button: false
			},
			selectedItem:'',
            applicant_back_out:{
                dialog: false,
                reason: ''
            }
		};
	},
		computed: {
			...mapGetters([
				"ACTION",
				"DIALOGS",
				"GET_EMPLOYEE_DATA_VIEW",
				"GET_STATUS_SELECTION",
				"GET_NEW_EMPLOYEE_DATA",
				"GET_ALL_CITIES",
				"GET_ALL_COUNTRIES",
				"GET_ALL_REGIONS",
				"GET_ALL_PROVINCE",
				"GET_LIST_DEPARTMENT",
				"GET_HR_JOB_TITLES_TABLE",
				"GET_RELIGION_SELECTION",
				"GET_MARITAL_STATUS_SELECTION",
				"GET_CITIZENSHIP_SELECTION",
				"GET_JOB_TITLE_SELECTION",
				"USERACCOUNT_LOAD",
				"GET_HR_TAB_IS_ACTIVE",
				"GET_JOB_LEVEL_SELECTION",
                "GET_APPLICATION_STATUS_SELECTION",
				"GET_CONTRACT_TYPE_SELECTION",
				"GET_STORE_BRANCHES_SELECTION",
				"GET_CLIENT_LIST_SELECTION",
                "GET_APPLICATION_FORM_JOB_TITLE_SELECTION"
			]),
			actions() {
                // if (this.ACTION == 'Submit') {
                //     this.initialForm();
                //     this.counter();
                //     this.countryCode();

                //     this.getIDTemplates();


                //     this.disabled_view = false;
                //     this.isViewBtn = false
                //     return 'Submit'
                // } else
                if (this.ACTION == 'Update') {
                    this.disabled_view = false;
                    this.isViewBtn = true
                    this.getAllApplicantData();

                    return 'Update'
                }
				if (this.ACTION == 'Approve') {
                    this.disabled_view = true;
                    this.isViewBtn = true
                    this.getAllApplicantData();
                    // If status is For Requirements
                    return 'Approve'
                }

			},

		},
		created(){
			this.initialForm();
		},
		mounted() {
			window.addEventListener("keydown", (e) => {
				if (e.code === "Space" && this.camera_dialog == true) {
					this.takePicture();
				}
			});
			this.positionChecker()
			this.getAllDropdowns()

            this.$store.commit('FILE_VIEWER_DISPATCH','getApplicantRequirementFiles');
            this.$store.commit('FILE_VIEWER_MODULE','applicant_data');
            this.$store.commit('FILE_VIEWER_UPLOAD','uploadApplicantNewRequirements');
		},
		methods: {
		getSelectedItem() {
			if (this.form.department_id) {
				this.selectedItem = this.GET_LIST_DEPARTMENT.find(item => item.value === this.form.department_id).text;
			}
		},
		initializeSignaturePad(){
			setTimeout(() => {
				const canvas = this.$refs.signaturePadCanvas;
				const box = document.getElementById("box");
				this.box_width = box.offsetWidth
				canvas.width = this.box_width;
				canvas.height = 200;
				this.signaturePad = new SignaturePad(canvas);
			}, 0);
		},
		clearSignature() {
			this.signaturePad.clear();
			this.signatureDataUrl = null;
		},
		saveSignature() {
			if (this.signaturePad.isEmpty()) {
				Swal.fire({
					text: `Please Draw Signature`,
					icon: 'warning',
					allowOutsideClick: false,
				});
			return;
			}
			this.form.employee_signature = this.signaturePad.toDataURL();
			const canvas = document.getElementById('signatureImage'); // get the canvas element
			const context = canvas.getContext('2d'); // get the 2D context

			canvas.style.backgroundColor = 'transparent';

			this.form.employee_signature =  canvas.toDataURL()

			this.signature_view_dialog = false
		},
		initialForm(){
			this.form = {
				employee_id           	:'',
				employee_image_base64 	:null,
				employee_image_preview	:null,
				first_name            	:'',
				last_name             	:'',
				middle_name           	:'',
				place_of_birth		  	:'',
				birth_date            	: null,
				job_title_name			:'',
				gender                	:'',
				age					  	:'',
				height				  	:'',
				weight				  	:'',
				religion_id			  	:'',
				citizenship_id          :'',
				marital_status_id       :'',
				employee_superior_id	:'',
				mobile_num            	:[],
				landline_num          	:'',
				email                 	:'',
				employment_date       	: this.$dayjs().startOf("month").format("YYYY-MM-DD"),
				employment_start_date   : null,
				sss_num               	:'',
				tin_num               	:'',
				philhealth_num		  	:'',
				pagibig_num			  	:'',
				job_title_id            :'',
				contract_type_id        :'',
				employment_date_menu  	: false,
				status_id			    : null,
				department_id			: null,
				job_level_id			: null,
				employee_signature		: null,

				permanent:{
					country_code: '608',
					region_code: '',
					province_code: '',
					city_code: '',
					address:'',
					text: 'Permanent Address'
				},
				current:{
					country_code: '608',
					region_code: '',
					province_code: '',
					city_code: '',
					address:'',
					text: 'Current Address'
				},
				address_type:{
					permanent:'permanent',
					current:'current'
				},

				employee_family_members : [],
				employee_emergency_contacts : [],
				employee_schools_attendeds :[],

				employee_professional_references: [],
				employee_previous_employments: [],

				family_members_trash 		: [],
				emerg_contact_trash  		: [],
				schools_attended_trash 		: [],
				trainings_attended_trash 	: [],
				prof_references_trash 		: [],
				personal_references_trash 	: [],
				prev_employment_trash 		: [],
				isCreateAccount: 0 ,
				files:null,
			}
            Object.assign(this.applicant_back_out,{
                dialog: false,
                reason: ''
            })
		},
		counter(){
			if (this.form.employee_family_members == 0) {
				this.addFamilyMember()
			}
			if (this.form.employee_emergency_contacts == 0) {
				this.addEmergency_Contact()
			}
			if (this.form.employee_schools_attendeds == 0) {
				this.addSchoolAttended()
			}
			if (this.form.employee_professional_references == 0) {
				this.addProfessionalReferences()
			}
			if (this.form.employee_previous_employments == 0) {
				this.addPreviousEmployment()
			}
		},
		height(width) {
			switch (this.$vuetify.breakpoint.name) {
			case 'xs': return "100%"
			case 'sm': return "100%"
			case 'md': return width
			case 'lg': return width
			case 'xl': return width
			}
		},
		async cameraRender() {
			if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
				const constraints = {
					video: {
						width: { min: 220, ideal: 720, max: 880 },
						height: { min: 420, ideal: 1280, max: 920 }
					}
				};
				try {
					const stream = await navigator.mediaDevices.getUserMedia(constraints);
					const videoPlayer = document.querySelector('video');


					videoPlayer.srcObject = stream;

					navigator.mediaDevices.getUserMedia(constraints)
					.then(stream=>{
						this.$refs.videos.srcObject = stream
					});
					// const videoPlayer = document.querySelector('video');
					// videoPlayer.srcObject = stream;
					// videoPlayer.play();
					// console.log(videoPlayer.srcObject)
					// await this.loadModel()
					// await this.detectLoop(videoPlayer); // Start the detection loop
				} catch (e) {
					console.log(e);
				}
			}
		},
		clearCanvas(){
			const picture = document.querySelector('canvas');
			const ctx = picture.getContext("2d")
			ctx.clearRect(0,0,picture.width, picture.height)
		},
		closeDialog() {
			this.$store.commit("DIALOG", false);
			this.clearCanvas()
			this.upload = true
		},
		uploadImage(event) {
			this.upload = true
			const reader = new FileReader()
			reader.addEventListener('load', () => {
				this.form.employee_image_preview = reader.result
				this.displayImageWithCanvas(reader.result)
			})
			const file = event.target.files[0];
			let url = reader.readAsDataURL(file);
		},
		uploadSignature(event) {
			this.upload = true
			const reader = new FileReader()
			reader.addEventListener('load', () => {
				this.form.employee_signature = reader.result

			})
			const file = event.target.files[0];
			let url = reader.readAsDataURL(file);
		},
		// Methods for checking form fillables that is more than one
		checkFamily_Emerg_ProfessionalIfEmpty(object){
			let hasEmpty = false;
			let text = '';
			if(	Object.values(object.find(e=>e))[0] == ''
				|| Object.values(object.find(e=>e))[1] == ''
				|| Object.values(object.find(e=>e))[2] == ''
				|| Object.values(object.find(e=>e))[3] == ''
			){
				hasEmpty = true
				text = object.find(e=>e).text;
			}
			return hasEmpty ? text: ''
		},
		checkSchool_PreviousIfEmpty(object){
			let hasEmpty = false;
			let text = '';
			if(	Object.values(object.find(e=>e))[0] == ''
				|| Object.values(object.find(e=>e))[1] == ''
				|| Object.values(object.find(e=>e))[2] == ''
				|| Object.values(object.find(e=>e))[3] == ''
				|| Object.values(object.find(e=>e))[4] == ''
				|| Object.values(object.find(e=>e))[5] == ''
			){
				hasEmpty = true
				text = object.find(e=>e).text;
			}
			return hasEmpty ? text: ''
		},
		checkAddressIfEmpty(object){
			let hasEmpty = true;
			let text = object.text;
			if(Object.values(object).every(val => val != ''))
			{
				hasEmpty = false
			}
			return hasEmpty ? text : ''
		},
		checkSchool_PreviousIfEmpty(object){
			let hasEmpty = false;
			let text = '';
			if(	Object.values(object.find(e=>e))[0] == ''
				|| Object.values(object.find(e=>e))[1] == ''
				|| Object.values(object.find(e=>e))[2] == ''
				|| Object.values(object.find(e=>e))[3] == ''
				|| Object.values(object.find(e=>e))[4] == ''
				|| Object.values(object.find(e=>e))[5] == ''
			){
				hasEmpty = true
				text = object.find(e=>e).text;
			}
			return hasEmpty ? text: ''
		},
		updateApplicantData(){
			this.loading.button = true
			if(this.form.employee_schools_attendeds.length != 0 && this.form.employee_previous_employments.length != 0){
				let schools_previous_text = [];

				let schools_previous_arr = [this.form.employee_schools_attendeds,this.form.employee_previous_employments]
				schools_previous_arr.forEach(e=>{
					schools_previous_text.push({ text: this.checkSchool_PreviousIfEmpty(e) ? this.checkSchool_PreviousIfEmpty(e) : ''});
				})

				schools_previous_text.forEach(x=>{
					if(x.text == 'Schools Attended'){
						this.form.school_attendedIsEmpty = 1
					}

					if (x.text == 'Previous Employment'){
						this.form.previous_employmentIsEmpty = 1
					}

					else if(x.text == ''){
						this.form.school_attendedIsEmpty = 0
						this.form.previous_employmentIsEmpty = 0
					}

				})
			}

			let payload = {
				id : this.form.id,
				payload: this.form
			}
			this.$store.dispatch('UpdateHrApplicationList',payload).then(response=>{

				Swal.fire(response.icon.toUpperCase(),response.message,response.icon);

				this.$store.commit("DIALOG", false);
				this.loading.button = false

			}).catch((err)=>{
				console.log(err)
				this.loading.button = false
			});
		},
		approveApplicantData(){
            Swal.fire({
				icon: 'question',
				text: "Are you sure you want to Approve?",
				cancelButtonText: "Cancel",
				showCancelButton: true,
				allowOutsideClick: false,
				confirmButtonColor: "#397373",
				cancelButtonColor: "#397373",
            })
            .then((result) => {
				if (result.isConfirmed) {
                    this.loading.button = true;

                    const first_name_swal_text				 	= this.form.first_name ? '' : 'First Name';
                    const last_name_swal_text 					= this.form.last_name ? '' : 'Last Name';
                    const middle_name_swal_text 				= this.form.middle_name ? '' : 'Middle Name';
                    const birth_date_swal_text 					= this.form.birth_date ? '' : 'Date Of Birth';
                    const email_swal_text 						= this.form.email ? '' : 'Email';
                    const employment_date_swal_text 			= this.form.employment_date ? '' : 'Employment Date';
                    const employee_start_date_swal_text 	    = this.form.employment_start_date ? '' : 'Employee Start Date';
                    const job_title_swal_text 					= this.form.job_title_id ? '' : 'Job Title';
                    const mobile_num_swal_text 					= this.form.mobile_num ? '' : 'Mobile No.';
                    const contract_type_swal_text 				= this.form.contract_type_id ? '' : 'Contract Type';
                    const department_swal_text					= this.form.department_id ? '' : 'Department';
                    // const gcash_swal_text					    = this.form.gcash_num ? '' : 'GCash No.';


                    const array_text =
                    [
                        first_name_swal_text,
                        last_name_swal_text,
                        middle_name_swal_text,
                        birth_date_swal_text,
                        email_swal_text,
                        employment_date_swal_text,
                        employee_start_date_swal_text,
                        job_title_swal_text,
                        mobile_num_swal_text,
                        contract_type_swal_text,
                        department_swal_text,
                        // gcash_swal_text
                    ]
                    const filtered = array_text.filter(function (el) {
                        return el.replace(',', '');
                    })
                    if(this.form.contract_type_id == 3){ // if agency can be approved without requirements
                        if(this.form.client_id){
                            this.$store.dispatch('approveRejectApplicant',this.form).then(response=>{
                                Swal.fire({
                                    title   : response.icon.toUpperCase(),
                                    text    :  response.message,
                                    icon    :  response.icon,
                                    footer  : response.cpanel_message ? response.cpanel_message : response.cpanel_errors
                                });
                                this.$store.commit('HR_APPLICANT_NEW_DATA', true)
                                this.$store.commit('HR_RECRUITMENT_NEW_DATA',false)
                                this.$store.commit("DIALOG", false);
                                this.loading.button = false

                            }).catch((err)=>{
                                console.log(err)
                                this.loading.button = false
                            });
                        }
                        else{
                            Swal.fire({
                                title: 'Warning',
                                text:   'Please select a client for agency.',
                                icon:  'warning',
                                confirmButtonText: 'Okay',
                            })
                            this.loading.button = false;
                        }
                    }
                    else if(this.form.req_all_approved == false){
                        Swal.fire({
                            title: 'Warning',
                            text:   'Please check requirements if all required for employment is approved.',
                            icon:  'warning',
                            confirmButtonText: 'Okay',
                        })
                        this.loading.button = false;
                    }
                    else if (
                        this.form.first_name                       		&&
                        this.form.last_name                        		&&
                        this.form.middle_name                      		&&
                        this.form.birth_date                       		&&
                        this.form.employment_date                  		&&
                        this.form.job_title_id        			   		&&
                        this.form.mobile_num                       		&&
                        this.form.contract_type_id                 		&&
                        this.form.department_id                    		&&
                        this.form.email                                 &&
                        // this.form.gcash_num                             &&
                        this.form.req_all_approved
                        )
                    {
                        this.$store.dispatch('approveRejectApplicant',this.form).then(response=>{
                            Swal.fire({
                                title   : response.icon.toUpperCase(),
                                text    :  response.message,
                                icon    :  response.icon,
                                footer  : response.cpanel_message ? response.cpanel_message : response.cpanel_errors
                            });
                            this.$store.commit("DIALOG", false);
                            this.$store.commit('HR_APPLICANT_NEW_DATA', true)
                            this.$store.commit('HR_RECRUITMENT_NEW_DATA',false)
                            this.loading.button = false

                        }).catch((err)=>{
                            console.log(err)
                            this.loading.button = false
                        });
                    }
                    else {
                        Swal.fire({
                            text: `Fill Required Fields (${filtered})`,
                            icon: 'warning',
                            allowOutsideClick: false,
                        });
                        this.loading.button = false
                        return false
                    }
				}
			});
		},
		async getAllApplicantData(){
			this.form = await this.GET_EMPLOYEE_DATA_VIEW
			let mobile = this.GET_EMPLOYEE_DATA_VIEW.mobile_num
			if(Array.isArray(mobile)){
				this.form.mobile_num = [];
				this.form.mobile_num[0] =  mobile[0].replace('/','');
				this.form.mobile_num[1] =  mobile[1]
			}
			else{
				this.form.mobile_num = [];
				this.form.mobile_num[0] =  mobile.replace('/','');
				this.form.mobile_num[1] = ''
			}

			this.form.employee_superior_id = parseInt(this.GET_EMPLOYEE_DATA_VIEW.employee_superior_id)
			this.GET_STATUS_SELECTION.filter(e=>{
				if(e.text == this.GET_EMPLOYEE_DATA_VIEW.status_id){
					this.form.status = e.value
				}
			})
			let permanent = this.form.employee_addresses.find(obj => obj.address_type === 'permanent')
			let current =this.form.employee_addresses.find(obj => obj.address_type === 'current')
			if( permanent && current){
				this.form.permanent = permanent;
				this.form.current = current;
			}
			else if(current){
				this.form.current = current;
				this.form.permanent = {
					country_code: '608',
					region_code: '',
					province_code: '',
					city_code: '',
					address:'',
					text: 'Permanent Address'
				}
			}
			else{
				this.form.permanent = {
					country_code: '608',
					region_code: '',
					province_code: '',
					city_code: '',
					address:'',
					text: 'Permanent Address'
				}
				this.form.current ={
					country_code: '608',
					region_code: '',
					province_code: '',
					city_code: '',
					address:'',
					text: 'Current Address'
				}
			}
			this.form.address_type = {
				permanent: 'permanent',
				current: 'current'
			}
			this.form.family_members_trash 			= [];
			this.form.emerg_contact_trash  			= [];
			this.form.schools_attended_trash 		= [];
			this.form.trainings_attended_trash 		= [];
			this.form.prof_references_trash 		= [];
			this.form.personal_references_trash 	= [];
			this.form.prev_employment_trash 		= [];
			this.form.employment_date 				= this.GET_EMPLOYEE_DATA_VIEW.employment_date ? this.$dayjs(this.GET_EMPLOYEE_DATA_VIEW.employment_date).format('YYYY-MM-DD') : '';
			this.form.employment_start_date 		= this.GET_EMPLOYEE_DATA_VIEW.employment_start_date ? this.$dayjs(this.GET_EMPLOYEE_DATA_VIEW.employment_start_date).format('YYYY-MM-DD') : '';
			this.form.employee_previous_employments = this.GET_EMPLOYEE_DATA_VIEW.employee_previous_employments

			this.form.employee_image_preview 		= this.GET_EMPLOYEE_DATA_VIEW.employee_image_base64 ? this.GET_EMPLOYEE_DATA_VIEW.employee_image_base64 : process.env.VUE_APP_API_HOST + "/images/blank-picture.png";
            this.form.req_all_approved               = this.GET_EMPLOYEE_DATA_VIEW.req_all_approved;
            this.form.back_out_reason               = this.GET_EMPLOYEE_DATA_VIEW.back_out_reason;
            this.form.real_employee_id               = this.GET_EMPLOYEE_DATA_VIEW.real_employee_id ?? 0;
			this.displayImageWithCanvas(this.form.employee_image_preview)
            this.filterDepartment();
            if(this.form.status_id == 6){
                this.generateEmployeeID();
            }
		},
		displayImageWithCanvas(image){
			let picture = document.querySelector('canvas');
			picture.width = 1080;
			picture.height = 1920;
			let img = document.createElement("img");
			const ctx = picture.getContext("2d")
			ctx.imageSmoothingEnabled = true;
			ctx.imageSmoothingQuality = "high";
			ctx.clearRect(0,0,picture.width, picture.height)
			img.setAttribute('src',image);
			img.setAttribute('id','images');
			$("#profile-picture").html("");
			document.getElementById("profile-picture").append(img)
			ctx.drawImage(document.querySelector("img"),0,0,picture.width, picture.height)
		},
		takePicture(){
			this.camera_dialog = false;
			const picture = document.querySelector('canvas');
			picture.width = 1080;
			picture.height = 1920;
			const ctx = picture.getContext("2d")
			ctx.imageSmoothingEnabled = true;
			ctx.imageSmoothingQuality = "high";
			ctx.drawImage(document.querySelector("video"),0,0,picture.width, picture.height)
			let base64Raw =  picture.toDataURL().toString()
			if(this.camera_used_for == 'IDPicture'){
				this.$store.commit('ID_PICTURE',base64Raw)
				this.form.employee_ID_Picture = base64Raw
				this.clearCanvas()
			}
			else{
				this.form.employee_image_base64 = base64Raw
				this.form.employee_image_preview = base64Raw
				this.isHover = true
			}
		},
		// takePicture(border,canvas){
		// 	this.camera_dialog = false;
		// 	const picture = document.querySelector('canvas');
		// 	picture.width = 1080;
		// 	picture.height = 1920;
		// 	const ctx = picture.getContext("2d");
		// 	ctx.imageSmoothingEnabled = true;
		// 	ctx.imageSmoothingQuality = "high";
		// 	ctx.drawImage(document.querySelector("video"),border[0],border[1],picture.width, picture.height);
		// 	let base64Raw =  picture.toDataURL().toString();
		// 	this.form.employee_image_base64 = base64Raw;
		// 	this.form.employee_image_preview = base64Raw;
		// 	this.isHover = true;

		// 	// // Draw the image in the canvas element based on the border
		// 	// const x = border[0];
		// 	// const y = border[1];
		// 	// const width = border[2];
		// 	// const height = border[3];

		// 	// // Draw a rectangle around the detected object
		// 	// ctx.beginPath();
		// 	// ctx.lineWidth = "6";
		// 	// ctx.strokeStyle = "blue";
		// 	// ctx.rect(x, y, width, height);
		// 	// ctx.stroke();

		// 	// // Draw the image in the rectangle
		// 	// canvas.width = width;
		// 	// canvas.height = height;
		// 	// canvas.getContext('2d').drawImage(document.querySelector('video'), x, y, width, height, 0, 0, width, height);
		// },
		countryCode(){
			this.updateRegions('permanent');
			this.updateRegions('current');
		},
		updateRegions(address_type) {
			if (address_type == "current") {
				if (this.form.current.country_code == "608") {
					this.form.current.region_code= this.GET_ALL_REGIONS.regCode;
					this.form.current.province_code= this.GET_ALL_PROVINCE.provCode;
					this.form.current.city_code= this.GET_ALL_CITIES.citymunCode;
					this.current.reg_list = this.GET_ALL_REGIONS;
					this.current.province_list = this.GET_ALL_PROVINCE;
					this.current.city_list = this.GET_ALL_CITIES;
				} else {
					this.form.current.region_code= '';
					this.form.current.province_code= '';
					this.form.current.city_code= '';
					this.current.reg_list = [];
					this.current.province_list = [];
					this.current.city_list = [];
				}
			} else if (address_type == "permanent") {
				if (this.form.permanent.country_code == "608") {
					this.form.permanent.region_code= this.GET_ALL_REGIONS.regCode;
					this.form.permanent.province_code= this.GET_ALL_PROVINCE.provCode;
					this.form.permanent.city_code= this.GET_ALL_CITIES.citymunCode;
					this.permanent.reg_list = this.GET_ALL_REGIONS;
					this.permanent.province_list = this.GET_ALL_PROVINCE;
					this.permanent.city_list = this.GET_ALL_CITIES;
				} else {
					this.form.permanent.region_code= '';
					this.form.permanent.province_code= '';
					this.form.permanent.city_code= '';
					this.permanent.reg_list = [];
					this.permanent.province_list = [];
					this.permanent.city_list = [];
				}
			}
		},
		updateProvinces(address_type) {
			if (address_type == "current") {
				if (
					this.form.current.region_code != null &&
					this.form.current.region_code != ""
				) {
					var regCode = this.form.current.region_code;
					this.current.province_list = this.GET_ALL_PROVINCE.filter(
						function (province) {
							return province.regCode == regCode;
						}
					);

					if (this.form.current.province_code != "") {
						var selectedProvCode = this.form.current.province_code;
						var result = this.current.province_list.find(
							(province) => {
								return province.value == selectedProvCode;
							}
						);

						if (result == null) {
							this.form.current.province_code = "";
							this.updateCities("current");
						}
					} else if (this.form.current.city_code != "") {

						var selectedCityMunCode = this.form.current.city_code;
						var result = this.current.city_list.find((city) => {
							return city.value == selectedCityMunCode;
						});

						if (result != null) {
							this.form.current.province_code = result.provCode;
							this.updateCities("current");
						}
					}
				} else {
					this.current.province_list = [];
					this.form.current.province_code = "";
					this.updateCities("current");
				}
			} else if (address_type == "permanent") {

				if (
					this.form.permanent.region_code != null &&
					this.form.permanent.region_code != ""
				) {
					var regCode = this.form.permanent.region_code;

						this.permanent.province_list = this.GET_ALL_PROVINCE.filter(
							function (province) {
								return province.regCode == regCode;
							}
						);

					if (this.form.permanent.province_code != "") {
						var selectedProvCode = this.form.permanent.province_code;
						var result = this.permanent.province_list.find(
							(province) => {
								return province.value == selectedProvCode;
							}
						);

						if (result == null) {
							this.form.permanent.province_code = "";
							this.updateCities("permanent");
						}
					} else if (this.form.permanent.city_code != "") {
						var selectedCityMunCode = this.form.permanent.city_code;
						var result = this.permanent.city_list.find((city) => {
							return city.value == selectedCityMunCode;
						});

						if (result != null) {
							this.form.permanent.province_code = result.provCode;
							this.updateCities("permanent");
						}
					}
				} else {
					this.permanent.province_list = [];
					this.form.permanent.province_code = "";
					this.updateCities("current");
				}
			}
		},
		updateCities(address_type) {
			if (address_type == "current") {
				if (
					this.form.current.province_code != null &&
					this.form.current.province_code != ""
				) {
					var provCode = this.form.current.province_code;
					this.current.city_list = this.GET_ALL_CITIES.filter(
						function (city) {
							return city.provCode == provCode;
						}
					);

					var selectedCityCode = this.form.current.city_code;
					var result = this.current.city_list.find((city) => {
						return city.value == selectedCityCode;
					});
					if (result == null) {
						this.form.current.city_code = "";
					}
				} else {
					this.current.city_list = [];
					this.form.current.city_code = "";
				}
			} else if (address_type == "permanent") {
				if (
					this.form.permanent.province_code != null &&
					this.form.permanent.province_code != ""
				) {
					var provCode = this.form.permanent.province_code;
					this.permanent.city_list = this.GET_ALL_CITIES.filter(
						function (city) {
							return city.provCode == provCode;
						}
					);
					var selectedCityCode = this.form.permanent.city_code;
					var result = this.permanent.city_list.find((city) => {
						return city.value == selectedCityCode;
					});
					if (result == null) {
						this.form.permanent.city_code = "";
					}
					if (result == undefined) {
						this.form.permanent.city_code = this.form.permanent.city_code;
					}
				} else {
					this.permanent.city_list = [];
					this.form.permanent.city_code = "";
				}
			}
			this.selectRegion(address_type);
		},
		selectRegion(address_type) {
			if (address_type == "current") {
				if (
					this.form.current.city_code != null &&
					this.form.current.city_code != ""
				) {

					var citymun_code = this.form.current.city_code;
					var selectedCity = this.current.city_list.filter(function (
						city
					) {
						return city.value == citymun_code;
					});

					if (selectedCity.length > 0) {
						this.form.current.region_code = selectedCity[0].regDesc;
						this.updateProvinces("current");
					}
				} else if (
					this.form.current.province_code != null &&
					this.form.current.province_code != ""
				) {
					var prov_code = this.form.current.province_code;
					var selectedProv = this.current.province_list.filter(
						function (prov) {
							return prov.value == prov_code;
						}
					);

					if (selectedProv.length > 0) {
						this.form.current.region_code = selectedProv[0].regCode;
					}
				}
			} else if (address_type == "permanent") {
				if (
					this.form.permanent.city_code != null &&
					this.form.permanent.city_code != ""
				) {
					var citymun_code = this.form.permanent.city_code;
					var selectedCity = this.permanent.city_list.filter(function (
						city
					) {
						return city.value == citymun_code;
					});
					if (selectedCity.length > 0) {
						this.form.permanent.region_code = selectedCity[0].regDesc;
						this.updateProvinces("permanent");
					}
				} else if (
					this.form.permanent.province_code != null &&
					this.form.permanent.province_code != ""
				) {
					var prov_code = this.form.permanent.province_code;
					var selectedProv = this.GET_ALL_PROVINCE.filter(function (prov) {
						return prov.value == prov_code;
					});
					if (selectedProv.length > 0) {
						this.form.permanent.region_code = selectedProv[0].regCode;
					}
				}
			}
		},
		addFamilyMember(){
			this.form.employee_family_members.push({
				name:"",
				address:"",
				relationship:"",
				occupation:"",
				text: "Family Members"
			})
		},
		removeFamilyMember(id){
			let familyMemberData = null
			if (this.form.employee_family_members.length > 0) {
				if(id == null){
					familyMemberData = this.form.employee_family_members.pop();
					this.form.family_members_trash.push(familyMemberData.id);
				}
				else{
					let [removedElement] = this.form.employee_family_members.splice(id, 1);
					familyMemberData = { ...removedElement };
					this.form.family_members_trash.push(familyMemberData.id);
				}
			}
		},
		addEmergency_Contact(){
			if (this.form.employee_emergency_contacts.length < this.max_addition_count) {
				this.form.employee_emergency_contacts.push({
					name:"",
					address:"",
					relationship:"",
					contact_num:"",
					text: "Emergency Contacts"
				});
			}
		},
		removeEmergency_Contact(id){
			let emergencyContactData = null
			if (this.form.employee_emergency_contacts.length > 0) {
				if(id == null){
					emergencyContactData = this.form.employee_emergency_contacts.pop();
					this.form.emerg_contact_trash.push(emergencyContactData);
				}
				else{
					let [removedElement] = this.form.employee_emergency_contacts.splice(id, 1);
					emergencyContactData = { ...removedElement };
					this.form.emerg_contact_trash.push(emergencyContactData.id);
				}
			}
		},
		addSchoolAttended(){
			this.form.employee_schools_attendeds.push({
				school_attended	:"",
				field_course	:"",
				year_from		:this.$dayjs().year().toString(),
				year_to			:this.$dayjs().year().toString(),
				degree_earned	:"",
				text			:"Schools Attended"
			});
		},
		removeSchoolAttended(id){
			let schoolsAttendedData = null
			if (this.form.employee_schools_attendeds.length > 0) {
				if(id == null){
					schoolsAttendedData = this.form.employee_schools_attendeds.pop();
					this.form.schools_attended_trash.push({
						id: schoolsAttendedData.id,
					});
				}
				else{
					let [removedElement] = this.form.employee_schools_attendeds.splice(id, 1);
					schoolsAttendedData = { ...removedElement };
					this.form.schools_attended_trash.push(schoolsAttendedData.id);
				}
			}
		},
		addProfessionalReferences(){
			if (this.form.employee_professional_references.length < this.max_addition_count) {
				this.form.employee_professional_references.push({
					name :"",
					address :"",
					occupation :"",
					contact_num :"",
					text: "Professional References"
				});
			}
		},
		removeProfessionalReferences(id){
			let profReferencesData = null
			if (this.form.employee_professional_references.length > 0) {
				if(id == null){
					profReferencesData = this.form.employee_professional_references.pop();
					this.form.prof_references_trash.push({
						id: profReferencesData.id,
					});
				}
				else{
					let [removedElement] = this.form.employee_professional_references.splice(id, 1);
					profReferencesData = { ...removedElement };
					this.form.prof_references_trash.push(profReferencesData.id);
				}
			}
		},
		addPreviousEmployment(){
			this.form.employee_previous_employments.push({
				prev_employer_name: "",
				year_month_from: "",
				year_month_to: "",
				monthly_salary: "",
				reason_for_leaving: "",
				text: "Previous Employment",
				previous_employment_position:''
			});
		},
		removePreviousEmployment(id){
			let previousEmplData = null
			if (this.form.employee_previous_employments.length > 0) {
				if(id ==null){
					previousEmplData = this.form.employee_previous_employments.pop();
					this.form.prev_employment_trash.push({
						id: previousEmplData.id,
					});
				}
				else{
					let [removedElement] = this.form.employee_previous_employments.splice(id, 1);
					previousEmplData = { ...removedElement };
					this.form.prev_employment_trash.push(previousEmplData.id);
				}
			}
		},
		filterDepartment(){
			let filteredDept = this.GET_JOB_TITLE_SELECTION.filter(obj=> obj.value === this.form.job_title_id)
			filteredDept.forEach(e=>{
				this.form.department_id = e.department_id ? e.department_id : null
				this.form.job_level_id = e.job_level_id ? e.job_level_id : null
			})
		},
		formatNumber(index) {
			let number = this.form.employee_previous_employments[index].monthly_salary.replace(',','');
            this.form.employee_previous_employments[index].monthly_salary = this.thousandSeprator(number);
        },
		getImages(images) {
            this.form.images = images;
        },
        clearCameraCapture() {
            this.$refs.cameraCapture.reset();
            this.images = null;
        },
		uploadedData(data) {
            if (!data) {
                this.form.files = null;
            }
            this.form.files = data;
        },
		openCamera(data){
			this.camera_dialog = data
		},
		openIDPictureCamera(data){
			this.camera_dialog = true
		},
		filterJobsByLevelAndDept(){
			let filteredJobsByLevel = this.GET_APPLICATION_FORM_JOB_TITLE_SELECTION.filter(obj=>
				obj.job_level_id === this.form.job_level_id && obj.department_id === this.form.department_id
			)

			this.job_title_selection = filteredJobsByLevel
			this.getSelectedItem()
		},
		openFilesMenu(item){
			if(item == 'attachment'){
				$("#"+item).find('input').click()
			}else{
				$("#"+item).find('button').click()
			}
		},
		close_camera_dialog(){
			this.camera_dialog = false
		},
		hoverIn() {
            this.iconColor = 'gray';
        },
        hoverOut() {
            this.iconColor = 'black';
        },
		deleteImage(){
			this.form.employee_signature = null
		},
		drawSignature(){
			// this.initializeSignaturePad()
			this.signature_view_dialog = true
			this.initializeSignaturePad()
		},
		printID(){
			this.print_ID_view = true
			setTimeout(() => {
			}, 0);
		},
		positionChecker(){
			this.$store.dispatch('getPositionPlantilla').then(response=>{
				this.positionCheckerCount = response
			})
		},
		getAllDropdowns(){
			this.component_dispatches.forEach(el=>{
				this.$store.dispatch(el)
			})
		},
		changeApplicantStatus(status){
            let that = this;
            if(status == 'Backout'){
                if(!this.applicant_back_out.reason){
                    Swal.fire({
                        text: "",
                        title: "Please Input Backout Reason!",
                        icon: "warning"
                    })
                    return false
                }else{
                    changeStatusNow();
                }
            }else{
                changeStatusNow();
            }
            function changeStatusNow(){
                that.loading.button = true
                let payload = {
                    id : that.form.id,
                    status: status,
                    back_out_reason: that.applicant_back_out.reason
                }
                that.$store.dispatch('updateApplicationStatus',payload).then(response=>{
                    Swal.fire(response.icon.toUpperCase(),response.message,response.icon);
                    that.$store.commit("DIALOG", false);
                    that.loading.button = false
                }).catch(err=>{
                    console.log(err);
                    that.loading.button = false
                })
            }
		},
        generateEmployeeID(){
			let count = this.form.real_employee_id;
			let id = `2020${count.toString().padStart(5, '0')}`;
			// let id = `${currentYear}${count.toString().padStart(5, '0')}`;
			this.form.employee_id = id;
		},
	},
	watch: {
		camera_dialog:{
			handler(val){
				if(val){
				this.cameraRender();
				}
			}
		},
		GET_NEW_EMPLOYEE_DATA:{
			handler(val){
				if(val){
					this.closeDialog();
					Swal.fire({
						title: "Success",
						icon: "success",
						timer: 1500,
						showConfirmButton: false,
					});
					this.$store.commit("DIALOG", false);
					this.$store.commit("NEW_EMPLOYEE_DATA", false);
					this.initialForm();
				}
			}
		},
		DIALOGS:{
			handler(val){
				if(!val){
					this.initialForm();
					this.counter()
					this.job_title_selection = this.GET_APPLICATION_FORM_JOB_TITLE_SELECTION
					this.form.employment_date = this.$dayjs().startOf("month").format("YYYY-MM-DD")
					// this.clearFileUpload()
				}
				if(val){
					this.tab = 0;
					this.signature_tab = 0;
					this.positionChecker();
				}
			}
		},
		GET_APPLICATION_FORM_JOB_TITLE_SELECTION:{
			deep: true,
			handler(val){
				this.job_title_selection = val
			}
		},
		GET_CONTRACT_TYPE_SELECTION:{
			deep: true,
			handler(val){
				this.contract_type_selection = val
			}
		},
		GET_EMPLOYEE_SUPERIOR_SELECTION:{
			deep: true,
			handler(val){
				this.employee_superior_selection = val
			}
		},
		'form.permanent.region_code': {
			handler() {
				// if(this.ACTION == 'Update'){
					this.permanent.reg_list = this.GET_ALL_REGIONS;
					this.updateProvinces('permanent');
					this.updateCities('permanent');
					this.selectRegion('permanent');
				// }
			}
		},
		'form.birth_date': {
			deep: true,
			handler(val) {
				const getAge = this.$dayjs().diff(this.$dayjs(val, 'YYYYMMDD'), 'year')
				this.form.age = getAge
			}
		},
		'form.current.region_code': {
			handler() {
				// if(this.ACTION == 'Update'){
					this.current.reg_list = this.GET_ALL_REGIONS;
					this.updateProvinces('current');
					this.updateCities('current');
					this.selectRegion('current');
				// }
			}
		},
		'form.department_id': {
			deep: true,
			handler(val) {
				if(!val){
					this.job_title_selection = this.GET_APPLICATION_FORM_JOB_TITLE_SELECTION
				}
			}
		},
		'form.job_title_id':{
			deep:true,
			handler(val){
				if(this.ACTION == 'Submit'){
					let checkPositionIfIncludes= this.positionCheckerCount.includes(val)
						if(checkPositionIfIncludes== true){
							this.errors_position[0] = 'Position Selected is Full'
						}
						else{
							this.errors_position = []
						}
				}

			}
		}
	},
};
</script>
<style scoped>

		.camera{
			display: block;
			width: 400px;
			margin:0 auto;
			background-color: black;
		}

		.picture{
			display: block;
			width: 420px;
			height: 400px;
		}
		canvas{
			display: block;
			width: 100%;
			height: 100%;
		}
		.upload-file{
			font-size: 35px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 6px;
		}
		.outlined-dashed {
			border-style: dashed;
		}

		.image_container {
			position: relative;
		}
		.image_container .delete_btn {
			position: absolute;
			top: 10%;
			right: 0%;
			transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);
		}
		canvas {
			border: 1px solid #ccc;
		}

		.signature-canvas {
			width:100%;
			height:200px;
			background-color: white;
		}

</style>
