<template>
    <v-dialog v-model="scPartsOrderDialog" persistent max-width="98%" scrollable>
        <v-card class="pb-4">
              <v-row class="m-0">
                  <v-col>
                      <v-card-title>
                          <span v-if="rfStatus == 'WFP' || rfStatus == 'PP'" class="headline">Requested Parts Details</span>
                          <span v-else-if="rfStatus == 'WFR'" class="headline">Requested Item Details</span>
                          <span v-else-if="rfStatus == 'RET'" class="headline">Return Parts Details</span>
                      </v-card-title>
                  </v-col>
                  <v-col cols="pull-right-10 p-2">
                      <v-btn text icon color="gray" class="float-right" @click="closeScPartsOrderDialog()">
                          <v-icon>mdi-close-circle</v-icon>
                      </v-btn>
                  </v-col>
              </v-row>
              <v-container fluid style="background-color: white; overflow-y: scroll" class="ma-0 pa-0">
                  <v-col>
                      <v-card-text>
                            <v-divider class="mt-0" />
                            <v-row class="ma-4">
                                <!-- <v-layout row class="mx-0"> -->
                                    <v-col lg="3">
                                        <div>
                                            RF#: <span>{{ rfNumber }}</span>
                                        </div>
                                        <div>
                                            RD#: <span>{{ rrNumber }}</span>
                                        </div>
                                        <div>
                                            Date: <span>{{ rrDate }}</span>
                                        </div>
                                        <div>
                                            Status: <span>{{ rfStatus }}</span>
                                        </div>
                                        <div v-if="itemWarrantyType == 'In-Warranty'">
                                            Purchase Date:
                                            <span>{{ itemPurchaseDate }}</span>
                                        </div>
                                        <div>
                                            Warranty Type:
                                            <span :style="checkWarrantyType()">{{ itemWarrantyType }}</span>
                                        </div>
                                        <div>
                                            Repair Status: <span>{{ itemRepairStatus }}</span>
                                        </div>
                                    </v-col>
                                    <v-col lg="3">
                                        <div>
                                            <a :class="`text-checking`" @click="showPOItemInfoDialog()"> PO#: <span >{{ this.p_orders.id}}</span> </a>
                                        </div>
                                        <div>
                                            <a :class="`text-checking`" @click="showEstimationItemInfoDialog()"> Estimation#: <span>{{ this.est.id }}</span> </a>
                                        </div>
                                        <div>
                                            Crate Number: <span>{{ crateNumber }}</span>
                                        </div>
                                        <div>
                                            Location Number: <span>{{ locationNumber }}</span>
                                        </div>
                                        <div>
                                            Transfer#: <span>{{ parts_transfer.series }}</span>
                                        </div>
                                        <div v-if="rfStatus == 'PRQ'">
                                            <a :class="`text-checking`" @click="showFollowUp()"> Follow Up Remarks: <span> {{ displayFollowUpRemarks }} </span> </a>
                                        </div>
                                        <div>
                                            Technician Name: <span>{{ techName }}</span>
                                        </div>
                                    </v-col>
                                    <v-col lg="3">
                                        <div>
                                            Approved By:
                                        </div>
                                        <div>
                                            Approved At:
                                        </div>
                                        <div v-if="rfStatus == 'PRQ'">
                                            <div>
                                                Start Date: <span>{{ displayStartDate | formatDateTime24hours }}</span>
                                            </div>
                                            <div>
                                                End Date: <span>{{ displayEndDate | formatDateTime24hours }}</span>
                                            </div>
                                            <div>
                                                Duration: <span>{{ displayDuration }}</span>
                                            </div>
                                            <div>
                                                Correct Picking Reason: <span>{{ displayPickingReason }}</span>
                                            </div>
                                        </div>
                                        <div v-if="rfStatus == 'CF'">
                                            <div>
                                                Start Date: <span>{{ displayStartDateForChecking | formatDateTime24hours }}</span>
                                            </div>
                                            <div>
                                                End Date: <span>{{ displayEndDateForChecking | formatDateTime24hours }}</span>
                                            </div>
                                            <div>
                                                Duration: <span>{{ displayDurationForChecking }}</span>
                                            </div>
                                            <div>
                                                Correct Picking Reason: <span>{{ displayPickingReasonForChecking }}</span>
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col v-if="['PRQ', 'PP', 'PFO'].includes(rfStatus)" lg="3">
                                        <v-row class="w-100">
                                            <v-spacer></v-spacer>
                                            <v-col cols="12" lg="6">
                                                <v-text-field
                                                    v-model="crate_number.parts_crate_num"
                                                    :append-icon=" ['PRQ'].includes(rfStatus) ? 'mdi-content-save' : ''"
                                                    label="Parts Crate Number"
                                                    outlined
                                                    dense
                                                    :readonly="['PP', 'PFO'].includes(rfStatus) ? true : false"
                                                    @click:append="savePartsCrateNumber()"
                                                    >
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                <!-- </v-layout> -->
                            </v-row>
                            <v-divider class="ma-4 mt-0 pa-0"></v-divider>

                          <!-- Requested Parts / Item -->
                          <v-layout row class="mx-4">
                              <v-flex lg12>
                                  <div class="pb-2 d-flex justify-content-between" >
                                      <span>
                                          <!-- <v-btn @click="showCustomerInfoDialog()">Customer Information</v-btn> -->
                                          <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        @click="showCustomerInfoDialog()"
                                                        fab
                                                        text
                                                        small
                                                        class="mr-4"
                                                        color="blue"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                        <v-icon>mdi-account-question</v-icon>
                                                    </v-btn>

                                                </template>
                                                <span>Customer Information</span>
                                            </v-tooltip>

                                          <!-- <v-btn class="ml-1" @click="showItemInfoDialog()">Item Information</v-btn> -->
                                          <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn @click="showItemInfoDialog()" class="mr-4"
                                                        fab
                                                        text
                                                        small
                                                        color="blue"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                    <v-icon>mdi-wrench</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Item Information</span>
                                            </v-tooltip>
                                          <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn v-if="userAccess.viewPO" @click="showPOItemInfoDialog()" class="mr-4"
                                                        fab
                                                        text
                                                        small
                                                        color="blue"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                    <v-icon>mdi-note-search-outline</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Parts Order Items</span>
                                            </v-tooltip>
                                          <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn v-if="userAccess.viewEst" @click="showEstimationItemInfoDialog()" class="mr-4"
                                                        fab
                                                        text
                                                        small
                                                        color="blue"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                    <v-icon>mdi-note-search</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Estimation Order Items</span>
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn @click="showAutomatedTime()" class="mr-4"
                                                        fab
                                                        text
                                                        small
                                                        color="blue"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        :disabled="disabledAutomatedTime()"
                                                    >
                                                    <v-icon>mdi-clock-outline</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Automated Time</span>
                                            </v-tooltip>

                                      </span>
                                      <span v-if="['WFP'].includes(rfStatus)">
                                          <v-tooltip bottom>
                                              <template v-slot:activator="{ on, attrs }">
                                                  <v-btn @click="revertStatus" text icon>
                                                      <v-icon
                                                          color="red"
                                                          dark
                                                          v-bind="attrs"
                                                          v-on="on"
                                                      >
                                                      mdi-keyboard-return
                                                    </v-icon>
                                                  </v-btn>
                                              </template>
                                              <span>Return To PP</span>
                                            </v-tooltip>
                                      </span>
                                      <span v-if="['PP','PRQ','CF','SPPT','PIN' ].includes(rfStatus)">
                                            <v-tooltip bottom>
                                              <template v-slot:activator="{ on, attrs }">
                                                  <!-- <v-btn @click="editablePrint() ? edit.dialog = true : print()" text icon :loading="loading" :disabled="letPrint()"> -->
                                                    <!-- <v-btn @click="editablePrint() ?  edit.dialog = true : printPDF(rfStatus)" text icon :loading="loading" :disabled="letPrint()"> FOR DISABLING PRINTING IN PARTS MODULE -->
                                                        <v-btn @click="editablePrint() ?  edit.dialog = true : printPDF(rfStatus)" text icon :loading="loading" >
                                                        <v-icon
                                                            color="red"
                                                            dark
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                        mdi-cloud-download
                                                        </v-icon>
                                                    </v-btn>
                                              </template>
                                              <span>Print</span>
                                            </v-tooltip>
                                      </span>
                                  </div>
                                  <v-card class="pa-4">
                                      <v-layout row class="px-4">
                                          <v-flex lg6>
                                              <div v-if="rfStatus != 'RET'">Requested <span>{{ itemRepairStatus == 'For-Replacement' ? 'Item' : 'Spare Parts' }}</span></div>
                                              <div v-if="rfStatus == 'RET'">Return <span>{{ itemRepairStatus == 'For-Replacement' ? 'Item' : 'Spare Parts' }}</span></div>
                                              <div class="pb-2">Requested Date: {{ partsOrderDate }}</div>
                                              <div v-if="!!rfCancelledDate" class="pb-2">Cancelled Date: {{ rfCancelledDate }}</div>
                                              <div class="pb-2">Technician: {{ assignedTechnician }}</div>
                                          </v-flex>
                                          <v-flex lg6>
                                              <!-- <v-btn @click="reloadPartsOrder()">Refresh</v-btn> -->
                                              <!-- <v-btn @click="refreshAutoSearch()">Refresh</v-btn> -->
                                              <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        @click="refreshAutoSearch()"
                                                        fab
                                                        text
                                                        small
                                                        class="mr-4"
                                                        color="blue"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                        <v-icon>mdi-sync</v-icon>
                                                    </v-btn>

                                                </template>
                                                <span>Refresh</span>
                                            </v-tooltip>
                                          </v-flex>
                                      </v-layout>

                                      <div v-if="loading" style="text-align:center">
                                          <v-progress-circular
                                              indeterminate
                                              color="primary"
                                              :size="50"
                                          />
                                      </div>

                                      <!-- :show-select="rfStatus == 'WFP' || rfStatus == 'WFR'" -->

                                      <div v-else>
                                          <v-data-table
                                              :headers="headers"
                                              :items="rfOrderParts"
                                              :items-per-page="rfOrderParts.length"
                                              :show-select="['PRQ', 'PP'].includes(rfStatus) ? true : false"
                                              :loading="loading"
                                              v-model="selected"
                                              @toggle-select-all="selectAllToggle"
                                              @item-selected="checkIfSelected"
                                              >

                                          <template v-slot:header.data-table-select="{ header }">
                                              <v-simple-checkbox style="display:none"></v-simple-checkbox>
                                          </template>
                                          <template v-slot:[`item.unserved_qty`]="{item}">
                                                <v-text-field
                                                    v-if="item.selected"
                                                    v-model="item.unserved_qty"
                                                    class="center-input"
                                                    outlined
                                                    dense
                                                    small
                                                    @keypress="restrictToNumbers"
                                                    @input="checkIfValid(item)"
                                                >
                                                </v-text-field>
                                                <span v-else>{{ item.unserved_qty }}</span>
                                          </template>
                                          <template v-slot:[`item.remaining_qty`]="{ item }">
                                                <span >{{ item.requested_qty }}</span>
                                          </template>

                                          <template v-slot:[`item.availabilty`]="{ item }">
                                              <span>{{ warehouseAvailabilty(item) }}</span>
                                          </template>

                                          <template v-slot:[`item.foc`]="{ item }">
                                              <td>{{ item.foc == 1 ? 'Yes' : 'No' }}</td>
                                          </template>

                                          <template v-slot:[`item.status`]="{ item }">
                                              <td>
                                                  <v-chip
                                                      :color="requestedStatusColor(item)"
                                                      text-color="white"
                                                  >
                                                      {{ item.status }}
                                                  </v-chip>
                                              </td>
                                          </template>

                                          <!-- <template v-slot:item.data-table-select="{ item, isSelected, select }">
                                              <v-checkbox
                                                  :disabled="!!item.stock_transfer || item.requested_qty == item.serve_qty"
                                                  :ripple="false"
                                                  v-model="selected"
                                                  :value="item"
                                                  hide-details
                                              />
                                          </template> -->

                                          <template v-if="['PRQ', 'PP'].includes(rfStatus)" v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
                                            <!-- || item.status != 'for-confirmation' || item.status != 'completed' || item.status != 'for-approval'" -->
                                              <v-simple-checkbox
                                                  v-if="toggleIfSelected(item.id)"
                                                  ref="checkBox"
                                                  :value="isSelected"
                                                  @input="select($event)"
                                                  >
                                                  <!-- :readonly="(item.status == 'for-confirmation' || item.status == 'completed' || item.status == 'for-approval')"
                                                  :disabled="(item.status == 'for-confirmation' || item.status == 'completed' || item.status == 'for-approval')" -->
                                                </v-simple-checkbox>
                                          </template>

                                          <template v-if="rfStatus == 'RET'" v-slot:[`item.action`]="{ item }">
                                              <v-btn v-if="item.status == 'for-confirmation'" @click="confirmReturnItem(item)">Confirm</v-btn>
                                          </template>
                                          </v-data-table>
                                      </div>
                                  </v-card>
                              </v-flex>
                          </v-layout>

                          <v-divider />

                          <v-layout row>

                              <v-flex v-if="rfStatus == 'WFP'" >
                                  <div  style="text-align:center">
                                      <!-- <v-btn :disabled="!showPrintSp" :loading="serveSpOnFlight" @click="serveAndPrint()" class="mx-1" small>Serve And Print Spare Parts List</v-btn> -->
                                      <v-btn :disabled="stocksChecker()" :loading="serveSpOnFlight" @click="proceedPending()" small><v-icon>mdi-truck</v-icon>Transfer</v-btn>
                                  </div>
                              </v-flex>

                              <v-flex v-if="rfStatus == 'RET'" lg12>
                                  <div style="text-align:center">
                                      <!-- <v-btn :disabled="checkReturnedItems()" @click="successReturnedItems()">Confirm Returned Items</v-btn> -->
                                  </div>
                              </v-flex>
                              <v-flex>
                                  <div style="text-align:center">
                                      <v-tooltip top v-if="['PRQ','PP', 'PFO'].includes(rfStatus)">
                                          <template v-slot:activator="{ on, attrs }">
                                                  <span
                                                  class="mx-2"
                                                  dark
                                                  v-bind="attrs"
                                                  v-on="on">
                                                      <v-btn  @click="proceedPending" text icon :disabled="stocksChecker() || partsOrderStatus == 'transfering' || readyToServe == 1" color="success" :loading="loading"  class="mx-2">
                                                          <v-icon style="font-size:40px" >
                                                              mdi-truck
                                                          </v-icon>
                                                      </v-btn>
                                                  </span>
                                          </template>
                                          <span>Create transfer</span>
                                        </v-tooltip>
                                        <v-tooltip top v-if="['PRQ','PP'].includes(rfStatus)">
                                          <template v-slot:activator="{ on, attrs }">
                                                  <span
                                                  dark
                                                  v-bind="attrs"
                                                  v-on="on">
                                                      <v-btn @click="reserveStocks" text icon :disabled="stocksChecker(true)" color="success" :loading="loading">
                                                          <v-icon style="font-size:40px" >
                                                              mdi-inbox-arrow-down
                                                          </v-icon>
                                                      </v-btn>
                                                  </span>
                                          </template>
                                          <span>Reserve Stocks</span>
                                        </v-tooltip>
                                        <v-tooltip top v-if="['PRQ'].includes(rfStatus)">
                                            <template v-slot:activator="{ on, attrs }">
                                                    <span
                                                        class="mx-2"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        dark
                                                    >
                                                        <v-btn
                                                            :loading="loading"
                                                            color="success"
                                                            :disabled="readyToProceed == 1"
                                                            text
                                                            icon
                                                            @click="transferToPFO"
                                                        >
                                                            <v-icon style="font-size:40px" >
                                                                mdi-truck-delivery
                                                            </v-icon>
                                                        </v-btn>
                                                    </span>
                                            </template>
                                            <span>Transfer to PFO</span>
                                        </v-tooltip>
                                        <v-tooltip top v-if="['PFO'].includes(rfStatus)">
                                            <template v-slot:activator="{ on, attrs }">
                                                    <span
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        dark
                                                    >
                                                        <v-btn
                                                            :loading="loading"
                                                            color="success"
                                                            text
                                                            icon
                                                            @click="transferToPRQ"
                                                        >
                                                            <v-icon style="font-size:40px" >
                                                                mdi-arrow-left-circle
                                                            </v-icon>
                                                        </v-btn>
                                                    </span>
                                            </template>
                                            <span>Transfer to PRQ</span>
                                        </v-tooltip>
                                        <v-tooltip top v-if="['SPPT'].includes(rfStatus)">
                                            <template v-slot:activator="{ on, attrs }">
                                                    <span
                                                    class="mx-2"
                                                    dark
                                                    v-bind="attrs"
                                                    v-on="on">
                                                        <v-btn :disabled="readyToServe == 0" :loading="loading" @click="serveAndPrint()" text icon color="success">
                                                            <v-icon style="font-size:40px" >
                                                                mdi-cloud-check
                                                            </v-icon>
                                                        </v-btn>
                                                        <!-- <v-btn @click="proceedPending" text icon :disabled="stocksChecker() || partsOrderStatus == 'transfering'" color="success" :loading="loading"  class="mx-2">
                                                            <v-icon style="font-size:40px" >
                                                                mdi-truck
                                                            </v-icon>
                                                        </v-btn> -->
                                                    </span>
                                            </template>
                                            <span>Serve And Print Item</span>
                                        </v-tooltip>
                                        <!-- <v-tooltip top v-if="['SPPT'].includes(rfStatus)" >
                                            <template v-slot:activator="{ on, attrs }">
                                                    <span
                                                    class="mx-2"
                                                    dark
                                                    v-bind="attrs"
                                                    v-on="on">
                                                        <v-btn :loading="loading" @click="toggleDialog('TOGGLE_ADD_DIALOG',PARTS_TRANSFER.DIALOG.ADD.VALUE)" text icon color="success">
                                                            <v-icon style="font-size:40px" >
                                                                mdi-truck-plus
                                                            </v-icon>
                                                        </v-btn>
                                                    </span>
                                            </template>
                                            <span> Add Parts</span>
                                        </v-tooltip> -->
                                  </div>
                              </v-flex>

                              <!-- <v-flex lg6>
                                  <div style="text-align:center">
                                      <v-btn
                                          v-if="showServeBtn()"
                                          :loading="serveSpOnFlight"
                                          @click="serveItems()">Serve
                                      </v-btn>
                                      <v-btn
                                          v-else-if="selectedWarehouse == 'SC-DMS' && rfStatus == 'WFR'"
                                          :loading="serveItemOnFlight"
                                          @click="showInputSerialNumDialog()">Serve Item
                                      </v-btn>
                                      <v-btn
                                          v-if="checkSelectedDms()"
                                          :loading="stockTransferOnFlight"
                                          @click="createStockTransfer()">Request Stock
                                      </v-btn>

                                      <v-btn v-if="rfStatus == 'RET'" :disabled="checkReturnedItems()" @click="successReturnedItems()">Confirm Returned Items</v-btn>
                                  </div>
                              </v-flex> -->
                          </v-layout>
                          </v-card-text>
                      </v-col>
              </v-container>
          </v-card>

          <!-- Item Information Dialog -->
          <v-dialog v-model="itemInfoDialog" persistent max-width="50%">
              <v-card class="pa-4">
                  <v-row class="m-0">
                      <v-col>
                          <v-card-title>
                              <span class="headline">Item Information</span>
                          </v-card-title>
                      </v-col>
                      <v-col cols="pull-right-10 p-2">
                          <v-btn
                              text
                              icon
                              color="gray"
                              class="float-right"
                              @click="closeItemInfoDialog()"
                          >
                          <v-icon>mdi-close-circle</v-icon>
                          </v-btn>
                      </v-col>
                  </v-row>

                  <v-divider class="mt-0" />

                  <v-layout row class="mx-4">
                      <v-flex lg4>
                          <label style="color: gray">Warranty Type:</label>
                      </v-flex>
                      <v-flex lg8>
                          {{ itemWarrantyType }}
                      </v-flex>
                  </v-layout>

                  <v-layout row class="mx-4">
                      <v-flex lg4>
                          <label style="color: gray">Purchase Date:</label>
                      </v-flex>
                      <v-flex lg8>
                          {{ itemPurchaseDate }}
                      </v-flex>
                  </v-layout>

                  <v-layout row class="mx-4">
                      <v-flex lg4>
                          <label style="color: gray">Item Model:</label>
                      </v-flex>
                      <v-flex lg8>
                          {{ itemModel }}
                      </v-flex>
                  </v-layout>

                  <v-layout row class="mx-4">
                      <v-flex lg4>
                          <label style="color: gray">Item Name:</label>
                      </v-flex>
                      <v-flex lg8>
                          {{ itemProductName }}
                      </v-flex>
                  </v-layout>

                  <v-layout row class="mx-4">
                      <v-flex lg4>
                          <label style="color: gray">Serial No:</label>
                      </v-flex>
                      <v-flex lg8>
                          {{ itemSerial }}
                      </v-flex>
                  </v-layout>

                  <v-layout row class="mx-4">
                      <v-flex lg4>
                          <label style="color: gray">Accessories:</label>
                      </v-flex>
                      <v-flex lg8>
                          {{ itemAccessories }}
                      </v-flex>
                  </v-layout>

                  <v-layout row class="mx-4">
                      <v-flex lg4>
                          <label style="color: gray">Other Accessory:</label>
                      </v-flex>
                      <v-flex lg8>
                          {{ itemOtherAccessory }}
                      </v-flex>
                  </v-layout>

                  <v-layout row class="mx-4">
                      <v-flex lg4>
                          <label style="color: gray">Other Unit Condition:</label>
                      </v-flex>
                      <v-flex lg8>
                          {{ itemOtherUnitCondition }}
                      </v-flex>
                  </v-layout>

                  <v-layout row class="mx-4">
                      <v-flex lg4>
                          <label style="color: gray">Complaint / Symptom:</label>
                      </v-flex>
                      <v-flex lg8>
                          {{ itemDefectDescription }}
                      </v-flex>
                  </v-layout>

                  <v-layout row class="mx-4">
                      <v-flex lg4>
                          <label style="color: gray">Remarks:</label>
                      </v-flex>
                      <v-flex lg8>
                          {{ itemDefectRemarks }}
                      </v-flex>
                  </v-layout>

                  <v-layout row class="mx-4">
                      <v-flex lg4>
                          <label style="color: gray">Assigned Technician:</label>
                      </v-flex>
                      <v-flex lg8>
                          {{ assignedTechnician }}
                      </v-flex>
                  </v-layout>

                  <v-layout row class="mx-4">
                      <v-flex lg4>
                          <label style="color: gray">Symptom Upon Tested:</label>
                      </v-flex>
                      <v-flex lg8>
                          {{ itemSymptomUponTested }}
                      </v-flex>
                  </v-layout>

                  <v-layout row class="mx-4">
                      <v-flex lg4>
                          <label style="color: gray">Reject Reason:</label>
                      </v-flex>
                      <v-flex lg8>
                          {{ rfItemRejectReason }}
                      </v-flex>
                  </v-layout>

                  <v-layout row class="mx-4">
                      <v-flex lg4>
                          <label style="color: gray">Job Done:</label>
                      </v-flex>
                      <v-flex lg8>
                          {{ itemJobDone }}
                      </v-flex>
                  </v-layout>
              </v-card>
          </v-dialog>
          <!-- Item PO Dialog -->
          <v-dialog v-model="POitemInfoDialog" persistent max-width="50%">
              <v-card class="pa-4">
                  <v-row class="m-0">
                      <v-col>
                          <v-card-title>
                              <span class="headline">PO#: {{this.p_orders.id}}</span>
                          </v-card-title>
                      </v-col>
                      <v-col cols="pull-right-10 p-2">
                          <v-btn
                              text
                              icon
                              color="gray"
                              class="float-right"
                              @click="closePOItemInfoDialog()"
                          >
                          <v-icon>mdi-close-circle</v-icon>
                          </v-btn>
                      </v-col>
                  </v-row>

                  <v-divider class="mt-0" />

                        <template>
                                <v-data-table
                                    :headers="POHeaders"
                                    :items="parts_orders"
                                    :items-per-page="5"
                                    class="elevation-1"
                                    dense
                                >
                                <template v-slot:item.selling_price="{ item }">
                                <td>{{ item.selling_price | currency}}</td>
                                </template>
                                <template v-slot:item.total="{ item }">
                                <td>{{ item.total | currency}}</td>
                                </template>
                                <!-- <template  v-slot:item.action="{ item }">
                                    <td class="px-0">
                                        <span class="text-nonwrap">
                                            <v-btn text icon color="orange">
                                                <v-icon class="btn-action" small @click="x">mdi-eye</v-icon>
                                            </v-btn>
                                            <v-btn text icon color="primary">
                                                <v-icon class="btn-action" small @click="x">mdi-pencil-outline</v-icon>
                                            </v-btn>
                                            <v-btn text icon color="red" >
                                                <v-icon class="btn-action" small @click="x">mdi-delete</v-icon>
                                            </v-btn>
                                        </span>
                                    </td>
                                </template> -->
                                </v-data-table>
                        </template>
              </v-card>
          </v-dialog>

          <!-- Item Estimation Dialog -->
          <v-dialog v-model="EstimationitemInfoDialog" persistent max-width="50%">
              <v-card class="pa-4">
                  <v-row class="m-0">
                      <v-col>
                          <v-card-title>
                              <span class="headline">Estimation#: {{this.est.id}}</span>
                          </v-card-title>
                      </v-col>
                      <v-col cols="pull-right-10 p-2">
                          <v-btn
                              text
                              icon
                              color="gray"
                              class="float-right"
                              @click="closeEstimationItemInfoDialog()"
                          >
                          <v-icon>mdi-close-circle</v-icon>
                          </v-btn>
                      </v-col>
                  </v-row>

                  <v-divider class="mt-0" />

                        <template>
                                <v-data-table
                                    :headers="EstHeaders"
                                    :items="estimations"
                                    :items-per-page="5"
                                    class="elevation-1"
                                    dense
                                >
                                 <template v-slot:item.selling_price="{ item }">
                                <td>{{ item.selling_price| currency }}</td>
                                </template>
                                 <template v-slot:item.disc_price="{ item }">
                                <td>{{ item.disc_price | currency }}</td>
                                </template>
                                 <template v-slot:item.total="{ item }">
                                <td>{{ item.total | currency }}</td>
                                </template>
                                </v-data-table>
                        </template>
              </v-card>
          </v-dialog>

          <!-- Customer Information Dialog -->
          <v-dialog v-model="customerInfoDialog" persistent max-width="50%">
              <v-card class="pa-4">
                  <v-row class="m-0">
                  <v-col>
                      <v-card-title>
                          <span class="headline">Customer Information</span>
                      </v-card-title>
                  </v-col>
                  <v-col cols="pull-right-10 p-2">
                      <v-btn
                      text
                      icon
                      color="gray"
                      class="float-right"
                      @click="closeCustomerInfoDialog()"
                      >
                      <v-icon>mdi-close-circle</v-icon>
                      </v-btn>
                  </v-col>
                  </v-row>
                  <v-divider class="mt-0" />
                  <v-layout row class="mx-4">
                  <v-flex lg4>
                      <label style="color: gray">Company Name:</label>
                  </v-flex>
                  <v-flex lg8>
                      {{ companyName }}
                  </v-flex>
                  </v-layout>
                  <v-layout row class="mx-4">
                  <v-flex lg4>
                      <label style="color: gray">Company Number:</label>
                  </v-flex>
                  <v-flex lg8>
                      {{ companyContactNumber }}
                  </v-flex>
                  </v-layout>
                  <v-layout row class="mx-4">
                  <v-flex lg4>
                      <label style="color: gray">Customer Number:</label>
                  </v-flex>
                  <v-flex lg8>
                      {{ contactNumber }}
                  </v-flex>
                  </v-layout>
                  <v-layout row class="mx-4">
                  <v-flex lg4>
                      <label style="color: gray">Customer Name:</label>
                  </v-flex>
                  <v-flex lg8>
                      {{ customerName }}
                  </v-flex>
                  </v-layout>
                  <v-layout row class="mx-4">
                  <v-flex lg4>
                      <label style="color: gray">Address:</label>
                  </v-flex>
                  <v-flex lg8>
                      {{ address }}
                  </v-flex>
                  </v-layout>
                  <v-layout row class="mx-4">
                  <v-flex lg4>
                      <label style="color: gray">Email:</label>
                  </v-flex>
                  <v-flex lg8>
                      {{ email }}
                  </v-flex>
                  </v-layout>
              </v-card>
          </v-dialog>

          <!-- Serial Number Dialog -->
          <v-dialog v-model="serialNumberDialog" persistent max-width="40%">
              <v-card class="pa-4">
                  <v-row class="m-0">
                      <v-col>
                          <v-card-title>
                              <span class="headline">Input New Item Serial Number</span>
                          </v-card-title>
                      </v-col>

                      <v-col cols="pull-right-10 p-2">
                          <v-btn
                              text
                              icon
                              color="gray"
                              class="float-right"
                              @click="closeInputSerialNumDialog()"
                          >
                              <v-icon>mdi-close-circle</v-icon>
                          </v-btn>
                      </v-col>
                  </v-row>

                  <v-divider />

                  <div style="text-align:center">
                      <div style="display:inline-block">Serial Number: </div>
                      <div style="display:inline-block">
                          <v-text-field v-model="newItemSerialNumber" dense outlined></v-text-field>
                      </div>
                  </div>

                  <v-divider />

                  <div style="text-align:center">
                      <v-btn :loading="serveItemOnFlight" @click="serveItemReplacement()">Submit</v-btn>
                  </div>
              </v-card>
          </v-dialog>

          <!-- For Printing Details -->
          <v-container fluid style="display:none">
              <div id="div_sp_logo" >
                  <div style="font-size: 12px; line-height: 1.9">
                      <p>
                          <span style="font-size: 18px; font-weight: bold;">
                              INGCOPH Traders Inc.
                          </span>
                          <br>2 D Arellano St. Bagong Barrio, Caloocan City
                          <br>Cell No.:
                          <br>Tel. No.:
                          <br>Service Mobile / Viber No.: +63 917 625 2286
                          <br>Spare Parts Mobile / Viber No.:
                      </p>
                  </div>
              </div>
              <div id="div_sp_num" style="font-size: 12px; line-height: 1.9">
                  <div style="border-top: 1px dashed #CFCFCF; padding: 2px; padding-bottom: -4px; text-align: center; border-bottom: none;">&nbsp;</div>
                  <p>
                      <span style="font-size: 18px; font-weight: bold;">Spare Parts Serve Form</span>
                      <br>
                      <b>RF#: {{ rfNumber }}</b>
                      <br>
                      <b>Issued: {{ spPrintIssued }}</b>
                  </p>
              </div>

              <div>
                  <table id="sp_item_details">
                      <tbody>
                          <tr>
                              <!-- <td v-if="['CF'].includes(rfStatus)">Availability</td> -->
                              <td v-if="['PP','PRQ','CF'].includes(rfStatus)">Requested Qty</td>
                              <td>Item Model</td>
                              <td>Item Name</td>
                              <td>Stocks</td>
                              <td>Warehouse</td>
                              <td>Location</td>
                              <td>FOC</td>
                              <td>Defective Parts</td>
                              <td>Date/Signature</td>
                          </tr>
                          <tr v-for="sp in filteredOrderParts" :key="sp.id">
                              <!-- <td>{{ sp.requested_qty }}</td> -->
                              <td>{{ sp.model }}</td>
                              <td>{{ sp.name }}</td>
                              <td>{{ sp.stock }}</td>
                              <td>{{ sp.warehouse_code }}</td>
                              <td>{{ sp.item_location }}</td>
                              <td>{{ sp.foc == 1 ? 'Yes' : 'No' }}</td>
                              <td></td>
                              <td></td>
                          </tr>
                          <tr>
                              <td colspan="10">Total: {{ filteredOrderParts.length }}</td>
                          </tr>
                      </tbody>
                  </table>
                  <table id="sp_process_details">
                      <tbody>
                          <tr>
                              <td colspan="4">Processed by: {{ !!orderProcessedBy ? orderProcessedBy.name : '' }}</td>
                              <td colspan="4">Received by: {{ assignedTechnician }}</td>
                          </tr>
                      </tbody>
                  </table>
                  <table id="sp_process_footer">
                      <tbody>
                          <tr>
                              <td colspan="4">Processed by: {{ !!orderProcessedBy ? orderProcessedBy.name : '' }}</td>
                              <td colspan="4">pick by: {{ edit.picker }}</td>
                          </tr>
                      </tbody>
                  </table>
              </div>
          </v-container>
            <v-container fluid style="display:none">
                <HeaderPdf :header="forPrintingDataHeader"></HeaderPdf>
                <BodyPdf :tableContents="forPrintingDataBody"></BodyPdf>
                <FooterPdf v-if="['PRQ', 'PP', 'SPPT', 'PIN'].includes(rfStatus)" :footers="editablePrint() ? forPrintingDataFooterEdit : forPrintingDataFooter"></FooterPdf>
                <FooterPdf v-if="['CF'].includes(rfStatus)" :footers="forPrintingDataFooterCF"></FooterPdf>
            </v-container>
            <v-dialog v-model="edit.dialog" max-width="400px">
              <v-card>
                  <v-row class="d-flex align-center ma-0">
                        <v-col cols="10" class="pl-2">
                            <label class="font-weight-bold" style="font-size:1rem">{{ rfStatus == 'CF' ? 'Checker' : 'Picker' }} Name</label>
                        </v-col>
                      <v-col cols="2" class="pull-right-10 p-2">
                          <v-btn text icon color="gray" class="float-right" @click="edit.dialog = false">
                              <v-icon>mdi-close-circle</v-icon>
                          </v-btn>
                      </v-col>
                  </v-row>
                  <v-card-text>
                        <v-autocomplete
                            v-model="personnelName"
                            :items="pickerOrCheckerPersonnels"
                            auto-select_first
                            outlined
                            dense
                            @input="getPickerOrChecker()"
                        >
                            <template v-slot:label>
                                <label>{{ rfStatus == 'CF' ? 'Checker Name' : 'Picker Name' }}</label>
                            </template>
                        </v-autocomplete>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-content-center ">
                      <!-- <v-btn @click="print" text icon> -->
                      <v-btn @click="printPDF(rfStatus)" text icon :disabled="enablePrint">
                          <v-icon
                              color="red"
                              dark
                          >
                          mdi-cloud-download
                        </v-icon>
                      </v-btn>
                  </v-card-actions>
              </v-card>
          </v-dialog>
          <PartsTransferComponentVue style="display:none"></PartsTransferComponentVue>

          <v-dialog v-model="automatedTimeDialog" persistent max-width="40%">
            <v-card>
                <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                <span class="headline">SET AUTOMATED TIME</span>
                    <v-row class="m-0">
                        <v-col cols="pull-right-10 p-2">
                            <v-btn
                                text
                                icon
                                small
                                color="gray"
                                class="float-right"
                                @click="closeAutomatedTime()"
                            >
                            <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-row class="mt-2">
                        <v-col class="pl-1 pr-1" cols="4">
                            <span class="d-flex justify-space-between"><strong>Correct Picking</strong><v-icon v-if="isVisible" small @click="clearCorrectPicking()">mdi-close</v-icon></span>
                            <v-radio-group inline class="d-flex flex-row" v-model="correction_picking">
                                <v-row>
                                    <v-col class="d-flex align-center justify-start pl-2">
                                    <v-radio value="0"></v-radio><span>No</span>
                                    </v-col>
                                    <v-col class="d-flex align-center justify-start">
                                    <v-radio value="1"></v-radio><span>Yes</span>
                                    </v-col>
                                </v-row>
                                <v-row class="d-flex flex-column pl-2 pr-2"  v-if="ReasonisVisible">
                                    <label class="text-caption">Please specify:</label>
                                    <v-text-field
                                    v-model="correct_picking_reason"
                                    dense
                                    outlined
                                    label="Reason"
                                    ></v-text-field>
                                </v-row>
                            </v-radio-group>
                        </v-col>
                        <v-col class="pr-1" cols="4">
                            <v-text-field
                            v-model="start_date"
                            v-mask="mask_start_date"
                            label="Start Date and Time"
                            outlined
                            dense
                            placeholder="MM-DD-YYYY 24:00"

                            >
                            </v-text-field>
                        </v-col>

                        <v-col class="pr-1" cols="4">
                            <v-text-field
                            v-model="end_date"
                            v-mask="mask_end_date"
                            label="End Date and Time"
                            outlined
                            dense
                            placeholder="MM-DD-YYYY 24:00"
                            >
                            </v-text-field>
                        </v-col>

                    </v-row>
                    <v-row class="text-right">
                        <v-col cols="12">
                            <v-btn
                                @click="submitAutomatedTime()"
                            >
                            <v-icon>
                                mdi-subdirectory-arrow-right
                            </v-icon>
                            Submit
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- FOR FOLLOW UP REMARKS DIALOG -->
        <v-dialog v-model="followUpDialog" width="500px" persistent>
            <v-card>
                <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                    <span class="headline">EDIT FOLLOW UP REMARKS</span>
                    <v-row class="m-0">
                        <v-col cols="pull-right-10 p-2">
                            <v-btn
                            text
                            icon
                            small
                            color="gray"
                            class="float-right"
                            @click="closeShowFollowUp()"
                            >
                            <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-title>

                <v-container fluid style="background-color: white; overflow-y: auto">
                    <v-card-text class="pa-1">
                        <v-textarea
                            v-model="followUpRemarks"
                            outlined
                            class="pt-1"
                            label="Remarks"
                        >
                        </v-textarea>
                    </v-card-text>

                    <v-card-actions>
                        <v-row class="text-right">
                            <v-col>
                                <v-btn color="#f69f1a" small @click="submitFollowUp()">
                                    <v-icon small>mdi-subdirectory-arrow-right</v-icon>
                                    Submit
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-container>
            </v-card>
          </v-dialog>
      </v-dialog>
  </template>

  <script>
  import _ from 'lodash';
  import swal from 'sweetalert2';
  import { mapGetters } from 'vuex';
  import ServiceFunctionsComponentVue from '../../Shared/ServiceFunctionsComponent.vue';
import PartsTransferComponentVue from './PartsTransferComponent.vue';
import SharedFunctionsComponentVue from '../../Shared/SharedFunctionsComponent.vue';
  import BodyPdf from '@/views/main/modules/Services/BasePrint/BodyPdf.vue';
  import HeaderPdf from '@/views/main/modules/Services/BasePrint/HeaderPdf.vue';
  import FooterPdf from '@/views/main/modules/Services/BasePrint/FooterPdf.vue';
import service from '@/store/modules/service';

  export default {
      mixins:[ServiceFunctionsComponentVue,SharedFunctionsComponentVue],
      components:{ PartsTransferComponentVue, BodyPdf, HeaderPdf, FooterPdf},
      data() {
          return {
              loading: true,
              rfNumber: '',
              rrNumber: '',
              rrDate: '',
              rfStatus: '',
              itemWarrantyType: '',
              itemRepairStatus: '',
              assignedTechnician: '',
              rfEstItemsDate: '',
              POHeaders:[
                  { text: "Name", value: "name" },
                  { text: "Model", value: "model" },
                  { text: "Status", value: "status" },
                  { text: "Selling Price", value: "selling_price" },
                  { text: "Total", value: "total" },
                //   { text: 'Action', value: 'action' },
              ],
              EstHeaders:[
                  { text: "Name", value: "name" },
                  { text: "Model", value: "model" },
                  { text: "Requested Qty", value: "requested_qty" },
                  { text: "Selling Price", value: "selling_price" },
                  { text: "Discount Price", value: "disc_price" },
                  { text: "Total", value: "total" },
              ],
              rfEstItemHeaders: [
                  { text: "Qty To Reserve", value: "unserved_qty", align:'center', width: '8%', },
                  { text: "Request Qty", value: "requested_qty", align:'center'},
                  { text: "Serve Qty", value: "serve_qty", align:'center' },
                  { text: "Reserved Qty", value: "reserved_qty", align:'center' },
                  { text: "Remaining Qty", value: "remaining_qty", align:'center' },
                  { text: "Outgoing Qty", value: "transfer_qty", align:'center' },
                  { text: "Item Model", value: "item.model" },
                  { text: "Item Name", value: "item.name" },
                //   { text: "Category", value: "category" },
                //   { text: "Warehouse", value: "warehouse_code" },
                  { text: "Stock", value: "stock", align:'center' },
                  { text: "Location", value: "item_location" },
                //   { text: "Availability", value: "availabilty" },
                //   { text: "SR#", value: "stock_request_num" },
                //   { text: "ST#", value: "stock_transfer_num" },
                  { text: "FOC", value: "foc" },
                  { text: "Status", value: "status" },
              ],
               rfReturnHeaders: [
                  { text: "Return Qty", value: "return_qty" },
                  { text: "Item Model", value: "model" },
                  { text: "Item Name", value: "name" },
                //   { text: "Category", value: "category" },
                //   { text: "SR#", value: "stock_request_num" },
                //   { text: "ST#", value: "stock_transfer_num" },
                  { text: "Status", value: "status" },
              ],
              userAccess:{
                viewPO:false,
                viewEst:false,
              },
              rfOrderParts: [],
              estimations: [],
              parts_orders:[],
              est:[],
              p_orders:[],
              customerInfoDialog: false,
              itemInfoDialog: false,
              POitemInfoDialog: false,
              EstimationitemInfoDialog: false,
              itemModel: "",
              itemProductName: "",
              itemSerial: "",
              itemCategory: "",
              itemDefectDescription: "",
              itemDefectRemarks: "",
              itemAccessories: "",
              itemOtherAccessory: "",
              itemWarrantyType: "",
              itemWarrantyDocument: "",
              itemUnitCondition: "",
              itemOtherUnitCondition: "",
              itemStatus: "",
              itemPurchaseDate: "",
              deliveredBy: "",
              receivedBy: "",
              warehouse: "",
              companyName: '',
              companyContactNumber: '',
              contactNumber: '',
              customerName: '',
              address: '',
              email: '',
              partsOrderId: '',
              partsOrderStatus: '',
              partsOrderTotalAmount: 0,
              partsOrderRfId: '',
              partsOrderRfEstId: '',
              partsOrderFoc: '',
              orderProcessedBy: null,
              partsOrderDate: '',
              orderItems: [],
              requestedItemId: '',
              selected: [],
              selectedWarehouse: '',
              findItems: [],
              showConfirmButton: false,
              filteredSelected: [],
              rfCancelled: false,
              rfCancelledDate: '',
              approvedForReplacement: false,
              approvedForReplacementBy: '',
              approvedForReplacementAt: '',
              serialNumberDialog: false,
              newItemSerialNumber: '',
              serveSpOnFlight: false,
              serveItemOnFlight: false,
              stockTransferOnFlight: false,
              itemForServeListCount: 0,
              rrDateCreated: "",
              spPrintIssued: this.$dayjs().format('YYYY-MM-DD, hh:mm:ss a'),
              showPrintSp: false,
              rfOrderPartsNotCompleted: [],
              itemSymptomUponTested: "",
              itemJobDone: "",
              rfItemRejectReason: '',

              crateNumber: '',
              locationNumber: '',
              rf: null,
              po:null,
              selectedRfId: '',

              showServeButton: false,
              showPrintButton: false,

              filteredOrderParts: [],
              proceedOnFlight: false,
              readyToServe: false,
              showPrintItem: false,
              onGoingTransfer:false,
              edit:{
                  picker:'',
                  dialog:false
              },
              parts_transfer:{
                series:''
              },
              forPrintingDataHeader: {
                form_title: 'Spare Parts',
                header_details: [
                    {id:1, label: 'RF#', value:''},
                    {id:2, label: 'RD#', value:''},
                    {id:3, label: 'Item Model', value:''},
                    {id:4, label: 'Status#', value:''},
                    {id:5, label: 'Issued', value:''},
                    {id:6, label: 'Duration', value:''},
                    {id:7, label: 'Parts Crate #', value:''},
                ],
              },
              forPrintingDataBody: [],
              forPrintingDataFooter: [
                {id:1, label: 'Processed by', value: ''},
                {id:2, label: 'Recieved by', value: ''}
              ],
              forPrintingDataFooterEdit: [
                {id:1, label: 'Processed by', value: ''},
                {id:2, label: 'Pick by', value: ''}
              ],
              forPrintingDataFooterCF: [
                {id:1, label:'Prepared by', value:''},
                {id:2, label:'Checked by', value:''},
                {id:3, label:'Recieved/Double Checked by', value:''},
                {id:4, label:'Date Coded', value:''},
              ],
              enablePrint: true,
              timer: undefined,
              readyToProceed: null,
              automatedTimeDialog: false,
              isVisible: false,
              ReasonisVisible: false,
              correction_picking: null,
              correct_picking_reason: '',
		      start_date: null,
              end_date: null,
              mask_start_date: '##-##-#### ##:##',
              mask_end_date: '##-##-#### ##:##',
              displayStartDate: '',
              displayEndDate: '',
              displayDuration: '',
              displayPickingReason: '',
              displayPicking: null,
              followUpDialog: false,
              followUpRemarks: '',
              displayFollowUpRemarks: '',
              techName: '',
              displayStartDateForChecking: '',
              displayEndDateForChecking: '',
              displayDurationForChecking: '',
              displayPickingReasonForChecking: '',
              personnelName:'',
            //   pickerName: '',
            //   checkerName: '',
              crate_number: {
                parts_crate_num:'',
                loading:false,
              },
          }
      },
    //   components:{
    //     PartsTransferComponentVue
    //   },
      props: ['rfId', 'warehousesCode'],
      async mounted() {
          await this.getCurrentUser();
          this.$store.dispatch('checkAccessUser',this.$router.currentRoute.params.id);
          this.$store.dispatch('getPickerPersonnels', {service: 1});
      },
      watch: {
          rfId() {
              if(!!this.rfId) {
                  this.selectedRfId = this.rfId;
                  this.checkOngoingSR()
              }
          },
          selectedRfId() {
              if(!!this.selectedRfId) {
                  this.getRfDetails();
                  this.getRfPartsOrder();
              }
          },
          rf() {
              this.crateNumber = this.rf.crate_number;
              this.locationNumber = this.rf.location_number;
              this.rfNumber = this.rf.rf_number;
              this.rfStatus = this.rf.status;
              let rr = this.rf.sc_receiving_report;
              let durations = _.last(this.rf.sc_parts_orders).durations;
              this.companyName = rr.customer.company_name;
              this.rfCancelled = this.rf.cancelled;
              this.approvedForReplacement = this.rf.approved_for_replacement;
              this.techName = this.rf.sc_technician?.name;
            //   this.forPrintingDataHeader.header_details?.push({ id:1, label: 'RF#', value:this.rfNumber}, {id:2, label:'Status#', value:this.rfStatus});
                this.forPrintingDataHeader.header_details[0].value = this.rfNumber;
                this.forPrintingDataHeader.header_details[3].value = this.rfStatus;
                this.forPrintingDataHeader.header_details[5].value = durations;
              if(this.approvedForReplacement) {
                  this.approvedForReplacementBy = this.rf.approved_for_replacement_by;
                  this.approvedForReplacementAt = this.$dayjs(this.rf.approved_for_replacement_at).format('YYYY-MM-DD, hh:mm:ss a');
              }

              if(!!this.rf.sc_receiving_report) {
                  this.rrNumber = rr.rr_number;
                  this.rrDate = this.$dayjs(rr.created_at).format('YYYY-MM-DD, hh:mm:ss a');
                //   this.forPrintingDataHeader.header_details?.push({id:3, label:'RD#', value:this.rrNumber}, {id:4, label: 'Issued', value:this.rrDate});
                  this.forPrintingDataHeader.header_details[1].value = this.rrNumber;
                  this.forPrintingDataHeader.header_details[4].value = this.rrDate;
              }

              if(!!this.rf.cancelled) {
                  this.rfCancelledDate = this.$dayjs(this.rf.cancelled_at).format('YYYY-MM-DD, hh:mm:ss a');
              }

              if(!!this.rf.sc_repair_form_item) {
                  let rf_item = this.rf.sc_repair_form_item;
                  this.itemId = rf_item.id;
                  this.itemWarrantyType = rf_item.warranty_type;
                  this.itemRepairStatus = rf_item.repair_status;
                  this.itemPurchaseDate = rf_item.repair_status == "For-Replacement" ? this.$dayjs(rf_item.purchase_date).format('YYYY-MM-DD') : "";
                  this.itemModel = rf_item.model;
                  this.itemProductName = rf_item.name;
                  this.itemSerial = rf_item.serial;
                  this.itemAccessories = rf_item.accessories;
                  this.itemOtherAccessory = rf_item.other_accessory;
                  this.itemOtherUnitCondition = rf_item.other_unit_condition;
                  this.itemDefectDescription = rf_item.defect_description;
                  this.itemSymptomUponTested = rf_item.symptom_upon_tested;
                  this.itemJobDone = rf_item.job_done;
                  this.rfItemRejectReason = rf_item.warranty_reject_reason;
                  this.forPrintingDataHeader.header_details[2].value = rf_item.model;
              }

              if (!!this.rf.sc_technician_id) {
                  this.assignedTechnician = this.rf.sc_technician.name;
                  this.forPrintingDataFooter[1].value =  this.rf.sc_technician.name;
              }

              let contact = [];
              let fullName = [];

              if (rr.customer_id == 275) {
                  if(!!rr.sc_customer) {
                      if(!!rr.sc_customer.first_name) {
                          fullName.push(rr.sc_customer.first_name);
                      } if(!!rr.sc_customer.middle_name) {
                          fullName.push(rr.sc_customer.middle_name);
                      } if(!!rr.sc_customer.last_name) {
                          fullName.push(rr.sc_customer.last_name);
                      }

                      this.contactNumber = rr.sc_customer.contact_number;
                      this.address = rr.sc_customer.address;
                      this.email = rr.sc_customer.email;
                  }
                  this.customerName = fullName.toString().replaceAll(",", " ");

              } else {
                  this.companyContactNumber = contact.toString().replaceAll(",", " ");
                  this.address = JSON.parse(rr.customer.shipping_addresses)[0].address;
                  if (!!rr.customer.mobile_1) {
                      contact.push(rr.customer.mobile_1);
                  }
                  if (!!rr.customer.mobile_2) {
                      contact.push(rr.customer.mobile_2);
                  }
                  if (!!rr.customer.mobile_3) {
                      contact.push(rr.customer.mobile_3);
                  }
                  if (!!rr.customer.tel_1) {
                      contact.push(rr.customer.tel_1);
                  }
                  if (!!rr.customer.tel_2) {
                      contact.push(rr.customer.tel_2);
                  }
                  if (!!rr.customer.tel_3) {
                      contact.push(rr.customer.tel_3);
                  }
                  if (!!rr.customer.first_name) {
                      fullName.push(rr.customer.first_name);
                  }
                  if (!!rr.customer.middle_name) {
                      fullName.push(rr.customer.middle_name);
                  }
                  if (!!rr.customer.last_name) {
                      fullName.push(rr.customer.last_name);
                  }
                  // this.customerName = fullName.toString().replaceAll(",", " ");
                  this.companyContactNumber = contact.toString().replaceAll(",", " / ");
              }
          },
          rfOrderParts() {
              if(this.rfOrderParts.length > 0) {
                  this.loading = false;

                  this.filteredOrderParts = this.rfOrderParts.filter(x=>x.status != 'completed');

                this.forPrintingDataBody = [];
                if(['PIN', 'PP', 'PRQ', 'SPPT', 'PFO'].includes(this.rfStatus))
                {
                    this.filteredOrderParts.forEach( obj => {
                    let filteredOrderPart = new Object();
                        filteredOrderPart.id = obj.id
                        filteredOrderPart.model = obj.model
                        filteredOrderPart.name = obj.name
                        filteredOrderPart.stock = obj.stock
                        filteredOrderPart.request_qty = obj.requested_qty
                        filteredOrderPart.warehouse_code = obj.warehouse_code
                        filteredOrderPart.item_location = obj.item_location
                        filteredOrderPart.foc = obj.foc == 1 ? 'Yes' : 'No'
                        filteredOrderPart.crate_number = ''
                        filteredOrderPart.signature_date = ''

                    this.forPrintingDataBody.push(filteredOrderPart)
                  })
                }else if (['CF'].includes(this.rfStatus))
                {
                    this.filteredOrderParts.forEach( obj => {
                    let filteredOrderPart = new Object();
                        filteredOrderPart.id = obj.id
                        filteredOrderPart.model = obj.model
                        filteredOrderPart.name = obj.name
                        filteredOrderPart.stock = obj.stock
                        filteredOrderPart.request_qty = obj.requested_qty
                        filteredOrderPart.warehouse_code = obj.warehouse_code
                        filteredOrderPart.item_location = obj.item_location
                        filteredOrderPart.foc = obj.foc == 1 ? 'Yes' : 'No'
                        filteredOrderPart.available = '';

                    this.forPrintingDataBody.push(filteredOrderPart)
                    })
                }


                  if(this.rfStatus == 'WFP' && !!this.readyToServe) {
                      this.showPrintSp = true;
                  } else if(this.rfStatus == 'WFR' && !!this.readyToServe) {
                      this.showPrintItem = true;
                  } else {
                      this.showPrintSp = false;
                  }
              }
          },
          orderItems() {
              if(this.orderItems.length > 0) {
                  this.loading = false;
              }
          },
          findItems() {
              if(this.findItems.length > 0) {
                  _.compact(this.findItems).forEach(item=> {
                      let order_item =  _.compact(this.rfOrderParts).find(order=>order.item_id == item.item_id);
                      order_item.warehouse_code = item.warehouse_code;
                      order_item.stock = item.stock;
                  });
              }
          },
          USER_ACCESS:{
            handler(val){
                if(val != "fail"){
           this.userAccess.viewPO = false;
           this.userAccess.viewEst = false;
            val.forEach(e => {
         if (e.actions_code == 'viewPO') {
            this.userAccess.viewPO = true;
          }
         if (e.actions_code == 'viewEst') {
            this.userAccess.viewEst = true;
          }
        });
        }
            }
        },

        'correction_picking':{
            handler(val){
                if(!val.length){
                    this.isVisible = false
                    this.ReasonisVisible = false
                }else{
                    this.isVisible = true
                    if(val == 0){
                        this.ReasonisVisible = true
                        }else{
                        this.ReasonisVisible = false
                    }
                }
            }
        },
        // GET_SC_PARTS_ORDER: {
        //     handler(val) {
        //         if (val) {
        //             this.pickerName = this.GET_SC_PARTS_ORDER.picker_personnel_id;
        //         }
        //     }
        // },
        personnelName: {
            handler(val) {
                if (val) {
                    this.edit.picker = this.GET_PICKER_PERSONNELS.find(e => e.value == this.personnelName)
                    if (this.edit.picker) {
                        if (this.rfStatus == 'CF') {
                            this.forPrintingDataFooterCF[1].value = this.edit.picker.text;
                        } else {
                            this.forPrintingDataFooterEdit[1].value = this.edit.picker.text;
                        }
                        this.enablePrint = false;
                    }
                } else {
                    this.enablePrint = true
                }
            }
        }
      },
      computed: {
          ...mapGetters([
              'GET_RF_DETAILS',
              'GET_RF_PARTS_ORDER',
              'GET_SERVICE_GET_CURRENT_USER',
              'GET_SEARCH_REQUESTED_ITEM',
              'GET_SEARCH_REQUESTED_SPARE_PARTS',
              'GET_SERVE_ITEM_REPLACEMENT',
              'GET_SC_PARTS_ORDER',
              'PARTS_TRANSFER',
              'USER_ACCESS',
              'GET_PICKER_PERSONNELS',
          ]),
          scPartsOrderDialog() {
              return this.$store.state.service.scPartsOrderDialog;
          },
          headers() {
              if(this.rfStatus == 'RET') {
                  return this.rfReturnHeaders;
              }

              if (['PRQ', 'PP'].includes(this.rfStatus)) {
                return this.rfEstItemHeaders;
              }
              return this.rfEstItemHeaders.filter( e => !['unserved_qty', 'reserved_qty'].includes(e.value));
          },
            pickerOrCheckerPersonnels() {
                if (this.rfStatus == 'CF') {
                    return this.GET_PICKER_PERSONNELS.filter( e => e.cf == 1 && e.service == 1);
                } else {
                    return this.GET_PICKER_PERSONNELS.filter( e => e.wpf == 1 && e.service == 1);
                }
            },
      },
      methods: {
          closeScPartsOrderDialog() {
              this.selectedRfId = '';
              this.selected = [];
              this.selectedWarehouse = "";
              this.$emit('closePartsOrderDialog', true);
              this.$store.commit('closeScPartsOrderDialog');
              this.serveSpOnFlight = false;
              this.serveItemOnFlight = false;
              this.stockTransferOnFlight = false;
              this.newItemSerialNumber = "";
              this.showServeButton = false;
              this.showPrintButton = false;
              this.readyToServe = false;
              this.showPrintItem = false;
              this.forPrintingDataHeader.header_details[0].value = '';
              this.forPrintingDataHeader.header_details[1].value = '';
              this.forPrintingDataHeader.header_details[2].value = '';
              this.forPrintingDataHeader.header_details[3].value = '';
              this.forPrintingDataBody = [];
              this.forPrintingDataFooter[1].value = '';
              this.forPrintingDataFooterEdit[1].value = '';
              this.edit.picker = '';
              this.rfOrderParts = [];
            //   this.pickerName = '';
            //   this.crate_number.parts_crate_num = '';
          },
          getRfDetails() {
              this.$store.dispatch('getRfDetails',this.selectedRfId).then(response=>{
                this.rf = this.GET_RF_DETAILS;
                this.po = this.GET_SC_PARTS_ORDER;
                this.p_orders = this.GET_RF_PARTS_ORDER.sc_parts_orders.slice(-1)[0];
                this.est = this.GET_RF_PARTS_ORDER.sc_rf_estimations.slice(-1)[0];

                        //getting all the estimations and all of the est items inside each estimations
                //   this.estimations = this.GET_RF_PARTS_ORDER.sc_rf_estimations.flatMap(estimation => estimation.sc_rf_est_items);

                         //Getting the last estimations and all of the est_items inside it
                  const estimations = this.GET_RF_PARTS_ORDER.sc_rf_estimations.slice(-1)[0]?.sc_rf_est_items || [];
                  this.estimations = estimations.flatMap(item => item)

                        //getting all parts orders and all of the parts order items inside each parts orders
                //   this.parts_orders = this.GET_RF_PARTS_ORDER.sc_parts_orders.flatMap(order => order.sc_parts_order_items);

                        //Getting the last parts_order and all of the parts_order_items inside it
                  const parts_order = this.GET_RF_PARTS_ORDER.sc_parts_orders.slice(-1)[0]?.sc_parts_order_items || [];
                  this.parts_orders = parts_order.flatMap(item => item)
              });
          },
          checkWarrantyType() {
              if (this.itemWarrantyType == "Dealer-Stock") {
                  return "background-color:green;border-radius:6px;padding:6px";
              }

              if (this.itemWarrantyType == "In-Warranty") {
                  return "background-color:violet;border-radius:6px;padding:6px";
              }

              if (this.itemWarrantyType == "No-Warranty-Type") {
                  return "background-color:red;border-radius:6px;padding:6px";
              }

              if (this.itemWarrantyType == "Out-Warranty") {
                  return "background-color:orange;border-radius:6px;padding:6px";
              }
              return "";
          },
          showCustomerInfoDialog() {
              this.customerInfoDialog = true;
          },
          showItemInfoDialog() {
              this.itemInfoDialog = true;
          },
          showPOItemInfoDialog() {
              this.POitemInfoDialog = true;
          },
          showEstimationItemInfoDialog() {
              this.EstimationitemInfoDialog = true;
          },
          closeCustomerInfoDialog() {
              this.customerInfoDialog = false;
          },
          closeItemInfoDialog() {
              this.itemInfoDialog = false;
          },
          closePOItemInfoDialog() {
              this.POitemInfoDialog = false;
          },
          closeEstimationItemInfoDialog() {
              this.EstimationitemInfoDialog = false;
          },
          getRfPartsOrder() {
              this.loading = true
              this.$store.dispatch('getRfPartsOrder',this.selectedRfId).then(async response => {
                  let data = this.GET_RF_PARTS_ORDER;
                  this.readyToProceed = this.GET_RF_PARTS_ORDER.ready_to_proceed;
                  for await (let part of _.last(data.sc_parts_orders).sc_parts_order_items) {
                      await this.$store.dispatch('inventoryGet',{
                          url:'getItemStocks/' + part.item_id,
                          warehouse_id: part.warehouse_id
                      }).then(response => {
                          part.stock = response.data[0].quantity
                          part.item_location = response.data[0].item_location
                      })
                  }

                  if(!!data && data.sc_parts_orders.length > 0) {
                      this.readyToServe = _.last(data.sc_parts_orders).ready_to_serve;
                      this.rfOrderParts = _.last(data.sc_parts_orders).sc_parts_order_items;
                      this.partsOrderId = _.last(data.sc_parts_orders).id;
                      this.partsOrderStatus = _.last(data.sc_parts_orders).status
                      this.parts_transfer = _.last(data.sc_parts_orders).parts_transfer ?? {series:''}
                      this.partsOrderDate = this.$dayjs(_.last(data.sc_parts_orders).created_at).format('YYYY-MM-DD, hh:mm:ss a');
                      this.displayStartDate = this.$dayjs(_.last(data.sc_parts_orders).start_date).format('MM-DD-YYYY hh:mm');
                      this.displayEndDate = this.$dayjs(_.last(data.sc_parts_orders).end_date).format('MM-DD-YYYY hh:mm');
                      this.displayDuration = this.formatDateTimeDuration(this.displayStartDate, this.displayEndDate)
                      this.displayPickingReason = _.last(data.sc_parts_orders).correct_picking_reason;
                      this.followUpRemarks = _.last(data.sc_parts_orders).follow_up_remarks;
                      this.displayFollowUpRemarks = _.last(data.sc_parts_orders).follow_up_remarks;
                      this.displayStartDateForChecking = this.$dayjs(_.last(data.sc_parts_orders).start_date_for_checking).format('MM-DD-YYYY hh:mm');
                      this.displayEndDateForChecking = this.$dayjs(_.last(data.sc_parts_orders).end_date_for_checking).format('MM-DD-YYYY hh:mm');
                      this.displayDurationForChecking = this.formatDateTimeDuration(this.displayStartDateForChecking, this.displayEndDateForChecking);
                      this.displayPickingReasonForChecking = _.last(data.sc_parts_orders).correct_picking_reason_for_checking;
                      this.crateNumber = data.crate_number;
                      this.personnelName = this.rfStatus == 'CF' ? _.last(data.sc_parts_orders).checker_personnel_id : _.last(data.sc_parts_orders).picker_personnel_id
                      this.crate_number.parts_crate_num = _.last(data.sc_parts_orders).crate_number;
                      this.forPrintingDataHeader.header_details[6].value =  this.crate_number.parts_crate_num;
                    }



                  this.loading = false
              }).catch(err=>{
                  console.log(err)
                  this.loading = false
              });
          },
          getCurrentUser() {
              this.$store.dispatch('serviceGetCurrentUser').then((response) => {
                  if (response) {
                      this.orderProcessedBy = this.GET_SERVICE_GET_CURRENT_USER;
                    //New Added
                    this.forPrintingDataFooter[0].value = this.GET_SERVICE_GET_CURRENT_USER?.name;
                    this.forPrintingDataFooterEdit[0].value = this.GET_SERVICE_GET_CURRENT_USER?.name;
                  }
              });
          },
          requestedStatusColor(item) {
              if(item.status == 'pending') {
                  return 'red';
              }

              if(item.status == 'partial' || item.status == 'shipping') {
                  return 'blue';
              }

              if(item.status == 'for-confirmation' || item.status == 'preparing') {
                  return '#6f42c1';
              }

              if(item.status == 'for-return' || item.status == 'for-approval') {
                  return 'orange';
              }

              if(item.status == 'completed' || item.status == 'returned') {
                  return 'green';
              }

              if(item.status == 'completed' || item.status == 'returned') {
                  return 'green';
              }
              if(item.status == 'reserved' ) {
                  return '#146100';
              }
              if(item.status == 'transfered' ) {
                  return '#FDBD03';
              }
              if(item.status == 'partial reserved' ) {
                  return '#d8a120';
              }

              return '';
          },
          getAvailableSpareParts() {
              if(this.itemRepairStatus == 'For-Replacement') {
                  let payload = {
                      rfId: this.selectedRfId,
                      orderItems: _.compact(this.rfOrderParts),
                  }
                  this.$store.dispatch('searchRequestedItem',payload).then(response => {
                      this.findItems = this.GET_SEARCH_REQUESTED_ITEM;
                  })
              } else {
                  let payload = {
                      rfId: this.selectedRfId,
                      orderItems: _.compact(this.rfOrderParts),
                  }
                  this.$store.dispatch('searchRequestedSpareParts',payload).then(response => {
                      this.findItems = this.GET_SEARCH_REQUESTED_SPARE_PARTS;
                  })
              }
          },
          refreshAutoSearch() {
              this.selected = [];
              this.loading = true;
              this.rfOrderParts = [];
              setTimeout(() => {
                  this.getRfPartsOrder();
                  // this.getAvailableSpareParts();
                  this.loading = false;
              }, 2000);
          },
          serveItems() {
              this.serveSpOnFlight = true;

              swal.fire({
                  title: 'Serve Order Requested?',
                  text: 'Make sure that all selected items are for serve',
                  icon: 'question',
                  showConfirmButton:true,
                  showCancelButton  :  true,
                  reverseButtons:true,
                  allowOutsideClick :  false,
                  confirmButtonColor: '#397373',
                  cancelButtonColor : 'grey',
                  confirmButtonText:'Confirm',
                  showCloseButton:true,
              }).then(result=>{
                  if(result.isConfirmed) {
                      let payload = {
                          rfId: this.selectedRfId,
                          items: _.compact(this.selected)
                      }
                      this.getRfPartsOrder();
                  } else {
                      this.serveSpOnFlight = false;
                  }
              });
          },
          createStockTransfer() {
              this.stockTransferOnFlight = true;

              swal.fire({
                  title: 'Request Stock',
                  text: `Transfer`,
                  icon: 'warning',
                  showConfirmButton:true,
                  showCancelButton  :  true,
                  reverseButtons:true,
                  allowOutsideClick :  false,
                  confirmButtonColor: '#397373',
                  cancelButtonColor : 'grey',
                  confirmButtonText:'Confirm',
                  showCloseButton:true,
              }).then((result)=> {
                  if(result.isConfirmed) {
                      let items_for_transfer = [];

                      if(this.selected.length > 0) {
                          _.compact(this.selected).forEach(item => {
                              let requestedQty = 0;
                              if(item.serve_qty >= 1) {
                                  requestedQty = item.requested_qty - item.serve_qty;
                                  if(item.requested_qty > item.stock) {
                                      requestedQty = item.stock
                                  }
                              } else if(item.requested_qty > item.stock) {
                                  requestedQty = item.stock
                              } else {
                                  requestedQty = item.requested_qty;
                              }

                              // items_for_transfer.push({
                              //     active: 1,
                              //     item_id: item.item_id,
                              //     quantity: requestedQty,
                              // });

                              items_for_transfer.push({
                                  item_id: item.item_id,
                                  request_type_quantity: 1,
                                  requested_uom_quantity: requestedQty
                              });
                          });

                          if(this.warehousesCode.length > 0) {
                              let desti_warehouse = this.warehousesCode.filter(x=>x.code == 'SC-DMS')[0];
                              let source_warehouse = this.warehousesCode.filter(x=>x.code == 'DM')[0];

                              if(this.selectedWarehouse == 'DM-B') {
                                  desti_warehouse = this.warehousesCode.filter(x=>x.code == 'SC-DMS')[0];
                                  source_warehouse = this.warehousesCode.filter(x=>x.code == 'DM-B')[0];
                              }

                              let payload = {
                                  warehouse_id: desti_warehouse.id,
                                  initial_serving_warehouse_id: source_warehouse.id,
                                  details: items_for_transfer,
                                  remarks: `For Service And Repair, RF#: ${this.rfNumber}`,
                                  service: 1,
                                  parts_order_items: _.compact(this.selected),
                                  rfId: this.selectedRfId,
                                  parts_module: true
                              }
                              this.$store.dispatch('requestCreate',payload).then(response=>{
                                  swal.fire("", `Stock Request Create Successfully!`, "success").then(confirm=>{
                                      this.stockTransferOnFlight = false;
                                      this.selected = [];
                                      this.rfOrderParts = [];
                                      this.getRfPartsOrder();
                                  });
                              });
                          }

                      }
                  } else {
                      this.stockTransferOnFlight = false;
                  }
              });
          },
          warehouseAvailabilty(item) {
              if(item.status == 'completed' || item.status == 'for-confirmation') {
                  return '';
              }

              if(item.warehouse_code == 'DM' || item.warehouse_code == 'DM-B') {
                  if(item.stock == 0) {
                      return 'Not-Available';
                  } else if(item.stock >= item.requested_qty || item.requested_qty > item.stock) {
                      return 'For Stock Transfer';
                  } else if(!!item.stock_transfer) {
                      return 'Stock Transfer Created';
                  }
              }

              if(item.warehouse_code == 'SC-DMS') {
                  if(item.stock >= item.requested_qty || item.requested_qty > item.stock) {
                      return 'For Serve';
                  }
                  // if(item.serve_qty > 0 && item.serve_qty < item.requested_qty && item.status == 'partial') {
                  //     return 'Partial Served';
                  // }

                  // if((item.serve_qty == item.requested_qty) && (item.status == 'completed')) {
                  //     return 'Served';
                  // }
                  // if(item.serve_qty > 0 && !(item.serve_qty >= item.requested_qty)) {
                  //     if(item.serve_qty < item.requested_qty) {
                  //         return 'Partial Served';
                  //     } else if(item.serve_qty == item.requested_qty) {
                  //         return 'Served';
                  //     }
                  // }
              }

              if(item.warehouse_code == 'DM' && !!item.stock_transfer) {
                  return 'For Approval';
              }

              return 'Not-Available';
          },
          reloadPartsOrder() {
              this.selected = [];
              this.rfOrderParts = [];
              this.loading = true;
              this.getRfPartsOrder();
          },
          checkSelectedDms() {
              let checkSelected = false;
              if(this.selected.length > 0) {
                  checkSelected = this.selected.every(item=>{
                      return item.status == 'for-confirmation';
                  })
              }

              if(this.selectedWarehouse == 'DM' || this.selectedWarehouse == 'DM-B' && !checkSelected) {
                  return true;
              }

              return false;
          },
          selectAllToggle(props) {
              // if(props.items.length > 0) {
              //     props.items.forEach(item => {
              //         if(!item.disabled || item.stock != 0) {
              //             this.selected.push(item);
              //         }
              //     });
              // } else {
              //     this.selected = [];
              // }
          },
          confirmReturnItem(item) {
              swal.fire({
                  title: 'Confirm Items Received?',
                  text: 'Make sure that all returned items are received',
                  icon: 'question',
                  showConfirmButton:true,
                  showCancelButton  :  true,
                  reverseButtons:true,
                  allowOutsideClick :  false,
                  confirmButtonColor: '#397373',
                  cancelButtonColor : 'grey',
                  confirmButtonText:'Confirm',
                  showCloseButton:true,
              }).then((result) => {
                  if(result.isConfirmed) {
                      let payload = {
                          item: item,
                          scPartsOrderItemId: item.id
                      }
                      this.$store.dispatch('confirmItemReturned',payload).then(response=> {
                          if(response.data.msg) {
                              swal.fire("", response.data.msg, "success");
                              this.getRfPartsOrder();
                              this.checkReturnedItems();
                          }
                      });
                  }
              });
          },
          checkReturnedItems() {
              if(this.rfOrderParts.length > 0) {

                  let returnedChecker = this.rfOrderParts.every(x=>{
                      return x.transfer_qty == 0 && x.status != "for-return";
                  });

                  if(returnedChecker) {
                      return false;
                  }
              }

              return true;
          },
          forReturnView() {
              if(this.rfStatus == 'RET') {
              }

              return false;
          },
          successReturnedItems() {
              swal.fire({
                  title: 'All Items Received?',
                  text: 'Make sure that all returned items are received',
                  icon: 'question',
                  showConfirmButton:true,
                  showCancelButton  :  true,
                  reverseButtons:true,
                  allowOutsideClick :  false,
                  confirmButtonColor: '#397373',
                  cancelButtonColor : 'grey',
                  confirmButtonText:'Confirm',
                  showCloseButton:true,
              }).then((result) => {
                  if(result.isConfirmed) {
                      let payload = {
                          rfId: this.selectedRfId,
                      }
                      this.$store.dispatch('successReturnedItems',payload).then(response=> {
                          if(response.data.msg) {
                              this.closeScPartsOrderDialog();
                              this.$emit('successReturn', true);
                              swal.fire("", response.data.msg, "success");
                          }
                      });
                  }
              });
          },
          showInputSerialNumDialog() {
              this.serialNumberDialog = true;
          },
          closeInputSerialNumDialog() {
              this.serialNumberDialog = false;
              this.newItemSerialNumber = "";
          },
          serveItemReplacement() {
              this.serveItemOnFlight = true;

              if(this.approvedForReplacement && !this.newItemSerialNumber) {
                  swal.fire('','Please Input New Item Serial Number To Proceeed', 'warning');
              } else {
                  swal.fire({
                      title: 'Are you sure you want to serve item for replacement?',
                      text: 'Click ok to proceed',
                      icon: 'question',
                      showConfirmButton:true,
                      showCancelButton  :  true,
                      reverseButtons:true,
                      allowOutsideClick :  false,
                      confirmButtonColor: '#397373',
                      cancelButtonColor : 'grey',
                      confirmButtonText:'Confirm',
                      showCloseButton:true,
                  }).then((result) => {
                      if(result.isConfirmed) {
                          let payload = {
                              rfId: this.selectedRfId,
                              newItemSerialNumber: this.newItemSerialNumber,
                          }
                          this.$store.dispatch('serveItemReplacement',payload).then(response=> {
                              if(this.GET_SERVE_ITEM_REPLACEMENT.error) {
                                  swal.fire("", this.GET_SERVE_ITEM_REPLACEMENT.error, "error")
                              } else {
                                  if(this.warehousesCode.length > 0) {
                                      let desti_warehouse = this.warehousesCode.filter(x=>x.code == 'DM-D')[0];
                                      let source_warehouse = this.warehousesCode.filter(x=>x.code == 'SC-DMS')[0];
                                      let payload = {
                                          warehouse_id: desti_warehouse.id,
                                          initial_serving_warehouse_id: source_warehouse.id,
                                          remarks: `For Service And Repair, RF#: ${this.GET_SERVE_ITEM_REPLACEMENT.oldRfNumber}, Defective Item (Replacement) Serial# ${this.itemSerial}`,
                                          service: 1,
                                          rfId: this.GET_SERVE_ITEM_REPLACEMENT.oldRfId,
                                          delivery_module: true,
                                          replacement: true,
                                          replacement_serve: true,
                                      }
                                      this.$store.dispatch('requestCreate',payload).then(resp=>{
                                          swal.fire("", `Stock Request ${resp.data.stock_request_form_number},Stock Transfer Create Successfully!`, "success").then(confirm=>{
                                              this.closeScPartsOrderDialog();
                                              this.$emit('successReturn', true);
                                              swal.fire("", response.data.msg, "success").then(conf=>{
                                                  this.serveItemOnFlight = false;
                                              });
                                          });
                                      });
                                  } else {
                                      swal.fire('','No Warehouses','error').then(confirm=>{
                                          this.serveItemOnFlight = false;
                                      });
                                  }
                              }
                          });
                      } else {
                          this.serveItemOnFlight = false;
                      }
                  });
              }
          },
          serveAndPrint() {
              this.serveSpOnFlight = true;

              swal.fire({
                  title: 'Serve Order And Print Requested Spare Parts?',
                  text: 'Make sure that all information is correct',
                  icon: 'question',
                  showConfirmButton:true,
                  showCancelButton  :  true,
                  reverseButtons:true,
                  allowOutsideClick :  false,
                  confirmButtonColor: '#397373',
                  cancelButtonColor : 'grey',
                  confirmButtonText:'Confirm',
                  showCloseButton:true,
              }).then(result=>{
                  if(result.isConfirmed) {
                      let payload = {
                          rfId: this.selectedRfId,
                          items: _.compact(this.filteredOrderParts),
                          from: 21,
                          to: 22,
                      }
                      this.printSparePartsList();
                  } else {
                      this.serveSpOnFlight = false;
                  }
              });
          },
          printSparePartsList(replacement = false) {
              let doc = new this.$jspdf('p', 'mm', 'letter');
              // doc.fromHTML($("#div_sp_logo").get(0), 7,-4,{'width':600});
              // doc.fromHTML($("#div_sp_num").get(0), 142,-2,{'width':400});

              doc.setFont(undefined, 'bold').setFontSize(13).text(6,8,"INGCOPH Traders Inc.")
              // doc.setFont(undefined, 'normal').setFontSize(9).text(6,13,"420 Del Monte Ave, Brgy. Siena, Quezon City \nCell No.: +63 935 713 2293 / +63 917 170 0979 / +63 919 078 0016 \nTel. No.: +63 2 8727 1771 / +63 2 8535 9968 / +63 2 7091 4539 \nService Mobile / Viber No.: +63 919 078 0019 / +63 927 414 5539 \nSpare Parts Mobile / Viber No.: +63 995 095 3153")
              doc.setFont(undefined, 'normal').setFontSize(9).text(6,13,"2 D Arellano St. Bagong Barrio, Caloocan City \nCell No.: \nTel. No.: \nService Mobile / Viber No.: +63 917 625 2286 \nSpare Parts Mobile / Viber No.:")
              doc.setFont(undefined, 'bold').setFontSize(13).text(162,8,"Spare Parts")
              doc.setFont(undefined, 'bold').setFontSize(9).text(162,13,"\nRF#:"+this.rfNumber+"\n\nRD#: "+this.rrNumber+"\n\nIssued: "+this.rrDate)

              doc.autoTable({
                  html: '#sp_item_details',
                  margin: {left: 7, right: 6},
                  startY: 50,
                  tableLineColor: [189, 195, 199],
                  border:{top:10,right:10,bottom:10,left:10},
                  columnStyles: {

                    // 0: {
                    //     cellRenderer: function (cell, data) {

                    //         if (data.row.index === 1) {
                    //         const squareSize = 10; // Adjust the size of the square as needed
                    //         const cellWidth = cell.width;
                    //         const cellHeight = cell.height;
                    //         const x = cell.x + (cellWidth - squareSize) / 2;
                    //         const y = cell.y + (cellHeight - squareSize) / 2;

                    //         // Draw the square
                    //         doc.rect(x, y, squareSize, squareSize, 'F'); // 'F' means fill the square
                    //         }

                    //         // You can also return the original content of the cell
                    //         return cell;
                    //     },
                    // },
                    0: {cellWidth: 33, halign:'left', textColor: '#000000', fontSize: 8},
                    1: {cellWidth: 43, halign:'left', textColor: '#000000', fontSize: 8},
                    2: {cellWidth: 18, halign:'left', textColor: '#000000', fontSize: 8},
                    3: {cellWidth: 20, halign:'left', textColor: '#000000', fontSize: 8},
                    4: {cellWidth: 20, halign:'left', textColor: '#000000', fontSize: 8},
                    5: {cellWidth: 15, halign:'left', textColor: '#000000', fontSize: 8},
                    6: {cellWidth: 15, halign:'left', textColor: '#000000', fontSize: 8},
                    7: {cellWidth: 33, halign:'left', textColor: '#000000', fontSize: 8},
                    8: {cellWidth: 33, halign:'left', textColor: '#000000', fontSize: 8},
                  }
              });

              doc.autoTable({
                  html: '#sp_process_details',
                  theme: 'grid',
                  startY: 258,
                  margin: {top: 37, left: 7, right: 6},
                  columnStyles: {
                      0: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                      1: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                      2: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                      3: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                      4: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                      5: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                      6: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                      7: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8}
                  }
              });
            //   doc.autoPrint();
            //   window.open(doc.output('bloburl'), '_blank')

            doc.save('SERVE AND PRINT.pdf')

              if(!!replacement) {
                  this.showInputSerialNumDialog();
              } else {
                  // Auto Accept of technician upon printing
                  let payload = {
                      rfId: this.selectedRfId,
                      items: _.compact(this.filteredOrderParts)
                  }
                  this.$store.dispatch('autoServePartsOrder',payload).then(response=>{
                      swal.fire("", response.data.msg, "success");
                      this.serveSpOnFlight = false;
                      this.selected = [];
                      this.showPrintSp = false;
                      this.showPrintItem = false;
                      this.closeScPartsOrderDialog();
                  });
              }
          },

        //   print(){
        //       let doc = new this.$jspdf('p', 'mm', 'letter');
        //       // doc.fromHTML($("#div_sp_logo").get(0), 7,-4,{'width':600});
        //       // doc.fromHTML($("#div_sp_num").get(0), 142,-2,{'width':400});
        //       doc.setFont(undefined, 'bold').setFontSize(13).text(6,8,"INGCOPH Traders Inc.")
        //       doc.setFont(undefined, 'normal').setFontSize(9).text(6,13,"420 Del Monte Ave, Brgy. Siena, Quezon City \nCell No.: +63 935 713 2293 / +63 917 170 0979 / +63 919 078 0016 \nTel. No.: +63 2 8727 1771 / +63 2 8535 9968 / +63 2 7091 4539 \nService Mobile / Viber No.: +63 919 078 0019 / +63 927 414 5539 \nSpare Parts Mobile / Viber No.: +63 995 095 3153")
        //       doc.setFont(undefined, 'bold').setFontSize(13).text(162,8,"Spare Parts")
        //       doc.setFont(undefined, 'bold').setFontSize(9).text(162,13,"\nRF#:"+this.rfNumber+"\n\nRD#: "+this.rrNumber+"\n\nIssued: "+this.rrDate)
        //       doc.setFont(undefined, 'bold').setFontSize(9).text(162,40,"\nStatus#:"+this.rfStatus)


        //       doc.autoTable({
        //           html: '#sp_item_details',
        //           margin: {left: 7, right: 6},
        //           startY: 50,
        //           columnStyles: {
        //               0: {cellWidth: 15, halign:'left', textColor: '#000000', fontSize: 8},
        //               1: {cellWidth: 33, halign:'left', textColor: '#000000', fontSize: 8},
        //               2: {cellWidth: 33, halign:'left', textColor: '#000000', fontSize: 8},
        //               3: {cellWidth: 15, halign:'left', textColor: '#000000', fontSize: 8},
        //               4: {cellWidth: 20, halign:'left', textColor: '#000000', fontSize: 8},
        //               5: {cellWidth: 33, halign:'left', textColor: '#000000', fontSize: 8},
        //               6: {cellWidth: 15, halign:'left', textColor: '#000000', fontSize: 8},
        //               7: {cellWidth: 15, halign:'left', textColor: '#000000', fontSize: 8},
        //               8: {cellWidth: 33, halign:'left', textColor: '#000000', fontSize: 8},
        //           }
        //       });

        //       doc.autoTable({
        //           html: this.editablePrint() ? '#sp_process_footer':'#sp_process_details',
        //           theme: 'grid',
        //           startY: 258,
        //           margin: {top: 37, left: 7, right: 6},
        //           columnStyles: {
        //               0: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
        //               1: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
        //               2: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
        //               3: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
        //               4: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
        //               5: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
        //               6: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
        //               7: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8}
        //           }
        //       });
        //       doc.autoPrint();
        //       window.open(doc.output('bloburl'), '_blank')

        //       this.$store.dispatch('successPrint', {id:this.GET_SC_PARTS_ORDER.id});
        //   },
          showPrintSpList() {
              let serveCheck = _.compact(this.rfOrderParts).every(x=>{
                  return x.transfer_qty == x.requested_qty && x.status == 'for-confirmation' || x.status == 'completed';
              })

              if(serveCheck) {
                  return false;sp_process_footer
              }

              return true;
          },
          stocksChecker(partial = false) {
              let container = [];
              if(this.rfOrderParts.length > 0) {
                  _.compact(this.rfOrderParts).forEach(x=>{
                       if(!["completed",'preparing','reserved','transfered'].includes(x.status)) {
                          container.push(x);
                      }
                  })

                  if(container.length == 0){
                    if(this.rfStatus == 'PP') {
                        return false
                    } else {
                        return true
                    }
                  }

                  let withStocks = false
                  if(!partial){
                        withStocks = container.every(x=>{
                            return x.stock >= x.requested_qty - x.serve_qty
                        })
                    if(withStocks) {
                        return false;
                    }

                  }else{
                      if(container.length == container.filter(x=>x.status != 'reserved' && x.stock >= x.requested_qty - x.serve_qty).length > 0){
                        return true
                      }else{
                        return false
                      }
                  }
              }

              return true;
          },
          proceedPending() {
              this.proceedOnFlight = true;
                const invalidReserveQty = this.selected.filter(e => e.requested_qty != e.reserved_qty + parseInt(e.unserved_qty));
                if (invalidReserveQty.length > 0) {
                    swal.fire('Info!', '-Invalid reserve quantity has found.', 'info')
                    return
                }
            let desti_warehouse = this.warehousesCode.filter(x=>x.code == 'SC-DMS')[0];
            let source_warehouse = this.warehousesCode.filter(x=>x.code == 'DM-R')[0];

              swal.fire({
                  title: 'Are you sure you want to Proceed?',
                  text: 'Click ok to proceed',
                  icon: 'question',
                  showConfirmButton:true,
                  showCancelButton  :  true,
                  reverseButtons:true,
                  allowOutsideClick :  false,
                  confirmButtonColor: '#397373',
                  cancelButtonColor : 'grey',
                  confirmButtonText:'Confirm',
                  showCloseButton:true,
            }).then(async (result) => {
                if(result.isConfirmed) {
                    await this.$store.dispatch('servicePost',{
                        // source_warehouse_id: source_warehouse.id,
                        // destination_warehouse_id: desti_warehouse.id,
                        item_id:this.filteredOrderParts.filter(e=>{
                            if(e.status != 'completed'){
                                e.quantity = e.requested_qty
                                return e
                            }
                        }),
                        total_quantity:this.sumArraybyAttribute(this.filteredOrderParts,'quantity'),
                        sc_repair_form_id:this.rfId,
                        url:'create-transfer',
                        sc_parts_order_id:this.partsOrderId,
                        remarks:'For Repair, RF#: '+ this.rfNumber,
                        for_repair:1
                    }).then(async response=>{
                        await this.$store.dispatch('servicePost',{
                            url:'update-parts-order',
                            id:this.partsOrderId,
                            status:'transfering'
                        })
                        swal.fire('Success!','','success')
                        this.closeScPartsOrderDialog()

                    }).catch(err=>{
                        console.log(err)
                        swal.fire('Error!','','error')
                    })
                }
            });
          },
          showServeBtn() {
              let filtered

              return false;
          },
          async revertStatus() {
              swal.fire({
                  title:'Warning!',
                  text:'- Are you sure you want to update status?',
                  icon: 'question',
                  showCancelButton:true,
                  reverseButtons:true
              }).then(async action=>{
                  if(action.isConfirmed){
                      let status = ''

                      if(this.rfStatus == 'WFP'){

                          if(!!this.onGoingTransfer){

                              this.$store.dispatch('requestCancel',this.onGoingTransfer.transmital.stock_request_id).then((response) => {
                                  swal.fire({
                                      title: "Stock Request Cancelled."
                                  });
                              }).catch((error)=>{
                                  console.log(error)
                                  swal.fire('Error','','error')
                              });
                          }
                          status = 'PP'
                      }else if(this.rfStatus == 'PP'){
                          status = 'WFP'
                      }
                      await this.updateStatus(status,this.rfId)
                      swal.fire('Success!','','success')
                      this.closeScPartsOrderDialog();
                  }
              })
          },

           async checkOngoingSR(){
              let rf ={}
              await this.$store.dispatch('serviceGet',{
                  url:'show/'+this.rfId
              }).then(response=>{
                  this.onGoingTransfer = response.data
              }).catch(err=>{
                  this.onGoingTransfer = false
              })
          },
          reserveStocks(){
            const invalidReserveQty = this.selected.filter(e => e.unserved_qty > (e.requested_qty - e.reserved_qty) || e.unserved_qty <= 0);
            let selectedSp = _.compact(this.selected)
            if (invalidReserveQty.length > 0) {
                swal.fire('Info!', '-Invalid reserve quantity has found.', 'info');
                return
            }
              if(selectedSp == 0) {
                swal.fire('Info!','- Select specific spare parts','info')
                  return false
              }
              if(this.rfOrderParts.filter(e=>e.stock > 0 && !['reserved','transferd'].includes( e.status)).length == 0){
                  swal.fire('Error!','- Nothing to reserve','error')
                  return false
              }
              swal.fire({
                  title:'Warning!',
                  text:'- Stocks will be transfer to DM-R',
                  icon:'warning',
                  showCancelButton:true,
                  reverseButtons:true
              }).then(async action=>{
                  if(action.isConfirmed){
                      await this.$store.dispatch('servicePost',{
                          url:'transfer-stocks',
                        //   items:this.rfOrderParts.filter(e=>e.stock > 0 && !['reserved','transferd'].includes( e.status)),
                          transac_id: this.rf.id,
                          po_id: this.partsOrderId,
                          selectedSp: selectedSp.filter(e=>e.stock > 0 && !['reserved','transferd'].includes( e.status)),
                      }).then(async response=>{
                          swal.fire('Success!','- Stocks are/is reserved','success')
                          this.getRfPartsOrder()
                          this.closeScPartsOrderDialog()

                      }).catch(err=>{
                          console.log(err)
                          swal.fire('Error!','','error')
                      })

                  }
              })
          },

          editablePrint(){
              return  ['PRQ','PP', 'CF'].includes(this.rfStatus)
          },

          letPrint()
          {
            if(this.GET_SC_PARTS_ORDER?.printed >= 1) {
                return true;
            // } else if( this.GET_SC_PARTS_ORDER.printed_at != null){
            //     let lastPrinted = new Date(this.GET_SC_PARTS_ORDER.printed_at);
            //     let dateToday = new Date();
            //     let dateDifference = dateToday - lastPrinted;
            //     if(dateDifference >= 24){
            //         return true;
            //     } else{
            //         return false;
            //     }
            } else {
                return false;
            }
          },
          getPickerOrChecker() {
            this.edit.picker = this.GET_PICKER_PERSONNELS.find(e => e.value == this.personnelName)
            if (this.edit.picker) {
                if (this.rfStatus == 'CF'){
                    this.forPrintingDataFooterCF[1].value = this.edit.picker.text;
                    // this.checkerName = this.personnelName;
                } else {
                    this.forPrintingDataFooterEdit[1].value = this.edit.picker.text;
                    // this.pickerName = this.personnelName;
                }
                this.enablePrint = false;
            }
          },

          printPDF(rfStatus)
            {
                let pdf = new this.$jspdf("p", "mm", "letter"); // paper size in mm. = 215.9 x 279.4
                let pageNumber = pdf.internal.getNumberOfPages();

                let ingcoPhLogo = new Image();
                ingcoPhLogo.src = "/images/ingcoph-logo.png";
                pdf.addImage(ingcoPhLogo, "png", 65, 12.7, 90, 20);

                // pdf.autoTable({
                //     html: '#company_name',
                //     theme: "plain",
                //     margin: { top:12.7, left:12.7},
                //     startY: 12.7,
                //     styles: {
                //         fontSize: 18,
                //         overflow:"hidden",
                //     },
                //     columnStyles: {
                //             0: {cellWidth: 'auto', halign:'left'},
                //         },
                // })

                // pdf.autoTable({
                //     html: '#company_address_and_contacts',
                //     theme: "plain",
                //     margin: {left:12.7},
                //     startY: 18.7,
                //     styles: {
                //         fontSize: 10,
                //         overflow:"hidden",
                //     },
                //     columnStyles: {
                //             0: {cellWidth: 'auto', halign:'left'},
                //         },
                // })

                // For Header
                pdf.autoTable({
                    html: '#form-title',
                    theme:"plain",
                    margin: {top: 0},
                    startY: 37.7,
                    styles: {
                        fontSize:14,
                        overflow:'hidden',
                    },
                    columnStyles: {
                        0: {cellWidth: 'auto', halign:'center'},
                    },
                })

                pdf.autoTable({
                    html: '#header-left',
                    theme:"plain",
                    margin: {left:12.7},
                    startY: 52.7,
                    styles: {
                        fontSize:10,
                        overflow:'hidden',
                    },
                })

                let headerEndPosition = pdf.lastAutoTable.finalY;

                if ($('#header-thirdPart tr').length != 0)
                {

                    pdf.autoTable({
                        html: '#header-thirdPart',
                        theme:"plain",
                        margin: {left: 75},
                        startY: 52.7,
                        styles: {
                            fontSize:10,
                            overflow:'hidden',
                        },
                    })
                }

                if ($('#header-right tr').length != 0)
                {
                    pdf.autoTable({
                        html: '#header-right',
                        theme:"plain",
                        margin: {left: 137},
                        startY: 52.7,
                        styles: {
                            fontSize:10,
                            overflow:'hidden',
                        },
                    })
                }

                // For Body
                if (['CF'].includes(rfStatus))
                {
                    pdf.autoTable({
                    html: '#body-pdf',
                    theme:"grid",
                    styles: {textColor: '#000000', fontSize: 8, lineWidth:0.1, lineColor:"#000000"},
                    headStyles:{textType: "bold", fillColor: "#F7FF00", halign: "center"},
                    footStyles:{textType: "bold", fontSize: 16, fillColor: "#F73302"},
                    margin: {top:50, left: 8, right: 8},
                    startY: headerEndPosition,
                    columnStyles: {
                        0: { cellWidth: 11.9, halign:'left'},
                        1: { cellWidth: 32, halign:'left'},
                        2: { cellWidth: 32, halign:'left'},
                        3: { cellWidth: 14, halign:'left'},
                        4: { cellWidth: 25, halign:'left'},
                        5: { cellWidth: 28, halign:'left'},
                        6: { cellWidth: 26, halign:'left'},
                        7: { cellWidth: 11, halign:'left'},
                        8: { cellWidth: 20, halign:'left'},
                    }
                    });
                } else {
                    pdf.autoTable({
                    html: '#body-pdf',
                    theme:"grid",
                    styles: {textColor: '#000000', fontSize: 8, lineWidth:0.1, lineColor:"#000000"},
                    headStyles:{textType: "bold", fillColor: "#F7FF00", halign: "center"},
                    footStyles:{textType: "bold", fontSize: 16, fillColor: "#F73302"},
                    margin: {top:50, left: 8, right: 8},
                    startY: headerEndPosition,
                    columnStyles: {
                        0: { cellWidth: 11.9, halign:'left'},
                        1: { cellWidth: 28, halign:'left'},
                        2: { cellWidth: 30, halign:'left'},
                        3: { cellWidth: 14, halign:'left'},
                        4: { cellWidth: 17, halign:'left'},
                        5: { cellWidth: 22, halign:'left'},
                        6: { cellWidth: 26, halign:'left'},
                        7: { cellWidth: 11, halign:'left'},
                        8: { cellWidth: 20, halign:'left'},
                        9: { cellWidth: 20, halign:'left'},
                    }
                    });
                }

                let bodyEndPosition = pdf.lastAutoTable.finalY;
                // For Footer
                pdf.autoTable({
                    html: '#footer-left',
                    theme:"plain",
                    margin: {right:107},
                    startY: bodyEndPosition + 80,
                    showHead: 'firstPage',
                    styles: {
                        fontSize:10,
                        overflow:'hidden',
                    },
                })

                pdf.setPage(pageNumber)

                pdf.autoTable({
                    html: '#footer-right',
                    theme:"plain",
                    margin: {left: 137},
                    startY: bodyEndPosition + 80,
                    showHead: 'firstPage',
                    styles: {
                        fontSize:10,
                        overflow:'hidden',
                    },
                })

                pdf.autoPrint();
                window.open(pdf.output('bloburl'), '_blank');
                if (this.personnelName) {
                    this.savePickerOrCheckerPersonnel();
                    this.getRfPartsOrder();
                }
                this.forPrintingDataFooterEdit[1].value = '';
                this.edit.picker = '';
                this.edit.dialog = false;
                if(this.rfStatus == 'PRQ') {
                    // this.closePrint()
                    this.forPrintingDataBody = [];
                }
                this.enablePrint = true;
            },
            transferToPFO() {
                swal.fire({
                    title:'Warning!',
                    text:'- Are you sure to transfer RF status to PFO?',
                    icon: 'question',
                    showCancelButton:true,
                    reverseButtons:true
                }).then(async action=>{
                    if(action.isConfirmed){
                        let payload = {
                            rfId: this.rfId,
                            url: 'transfer-to-pfo'
                        }
                        this.$store.dispatch("servicePost", payload).then( response => {
                            let  res = response.data.message;
                            swal.fire(res, "", res.toLowerCase());
                            this.closeScPartsOrderDialog();
                        }).catch( e => {
                            console.error(e);
                        })
                    }
                })
            },

            showFollowUp() {
                this.followUpRemarks = this.displayFollowUpRemarks;
                this.followUpDialog = true;
            },

            closeShowFollowUp() {
                this.followUpDialog = false;
                this.followUpRemarks = '';
            },

            submitFollowUp() {
                if(!this.followUpRemarks) {
                    swal.fire('Info!','- Please fill up the remarks','info')
                } else {
                    swal.fire({
                        icon: 'question',
                        text: 'Are you sure you want to update folow up remarks?',
                        showConfirmButton:true,
                        showCancelButton  :  true,
                        reverseButtons:true,
                        allowOutsideClick :  false,
                        confirmButtonColor: '#397373',
                        cancelButtonColor : 'grey',
                        confirmButtonText:'Confirm',
                        showCloseButton:true,
                  }).then(result=>{
                      if(result.isConfirmed) {
                        let payload = {
                            rfId: this.rf.id,
                            followUpRemarks: this.followUpRemarks
                        }
                        this.$store.dispatch('submitFollowUpRemarks', payload).then(response => {
                            swal.fire('Success!','','success')
                            this.getRfPartsOrder();
                            this.closeShowFollowUp()
                        }).catch(err => {
                            console.log(err)
                            swal.fire('Error!','','error')
                        })
                      }
                  });
                }
            },

            showAutomatedTime() {
                this.automatedTimeDialog = true;
            },

            closeAutomatedTime() {
                this.automatedTimeDialog = false;
                this.clearFields()
            },

            clearCorrectPicking() {
                this.correction_picking = '';
                this.correct_picking_reason = ''
            },

            clearFields() {
                this.end_date        = null
                this.start_date      = null
                this.correction_picking = '';
                this.correct_picking_reason = ''
            },

            submitAutomatedTime() {
                let start = new this.$dayjs(this.start_date)
                let end = new this.$dayjs(this.end_date)

                let durations = this.formatDateTimeDuration(start, end);
                swal.fire({
                    title: 'Are you sure you want to submit automated time?',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then(confirm=>{
                    if(confirm.isConfirmed){
                    this.$store.dispatch('servicePost',{
                        rfId: this.rf.id,
                        startDate: start.format('YYYY:MM:DD HH:mm'),
                        endDate: end.format('YYYY:MM:DD HH:mm'),
                        duration: durations,
                        pickingCorrection: this.correction_picking,
                        correctPickingReason: this.correct_picking_reason,
                        startDateForChecking: start.format('YYYY:MM:DD HH:mm'),
                        endDateForChecking: end.format('YYYY:MM:DD HH:mm'),
                        durationForChecking: durations,
                        pickingCorrectionForChecking: this.correction_picking,
                        correctPickingReasonForChecking: this.correct_picking_reason,
                        url: 'automated-time'
                    }).then(response=>{
                        swal.fire("Success", '', "success")
                        this.closeAutomatedTime()
                        this.getRfPartsOrder()
                    }).catch(err=>{
                        console.log(err)
                        swal.fire('Failed!','','error')
                        })
                    }
                });
            },

            disabledAutomatedTime() {
                if(this.rfStatus == 'PRQ') {
                    if(this.displayStartDate == 'Invalid Date' && this.displayEndDate == 'Invalid Date' && this.displayDuration == 'Invalid Start/End Date!' && this.displayPickingReason == null) {
                        return false;
                    } else {
                        return true;
                    }
                } else if (this.rfStatus == 'CF') {
                    if(this.displayStartDateForChecking == 'Invalid Date' && this.displayEndDateForChecking == 'Invalid Date' && this.displayDurationForChecking == 'Invalid Start/End Date!' && this.displayPickingReasonForChecking == null) {
                        return false;
                    } else {
                        return true;
                    }
                }
            },

            closePrint(){
                this.forPrintingDataBody = [];
                this.$emit('closePartsOrderDialog', true);
                this.$store.commit('closeScPartsOrderDialog');
            },

            transferToPRQ() {
                swal.fire({
                    title:'Warning!',
                    text:'- Are you sure to transfer RF status to PRQ?',
                    icon: 'question',
                    showCancelButton:true,
                    reverseButtons:true
                }).then(async action=>{
                    if(action.isConfirmed){
                        let payload = {
                            rfId: this.rfId,
                            url: 'transfer-to-prq'
                        }
                        this.$store.dispatch("servicePost", payload).then( response => {
                            let  res = response.data.message;
                            swal.fire(res, "", res.toLowerCase());
                            this.closeScPartsOrderDialog();
                        }).catch( e => {
                            console.error(e);
                        })
                    }
                })
            },
            toggleIfSelected(id) {
                let item = this.rfOrderParts.find( e => e.id == id);
                if (item && item.stock > 0 && item.requested_qty - item.reserved_qty > 0 && ! ['for-confirmation', 'completed', 'for-approval'].includes(item.status)) {
                    return true;
                }
                return false;
            },
            checkIfSelected(selectedItem){
                if(selectedItem) {
                    selectedItem.item.selected = selectedItem.value;
                    if (!selectedItem.value) {
                        selectedItem.item.unserved_qty = selectedItem.item.requested_qty - selectedItem.item.reserved_qty;
                    }
                }
            },
            checkIfValid(item) {
                if (item.unserved_qty > (item.requested_qty - item.reserved_qty)) {
                    swal.fire('', "-Value can't be higher to remaining quantity.", 'warning').then( result => item.unserved_qty = item.requested_qty - item.reserved_qty );
                } else if (item.unserved_qty <= 0) {
                    swal.fire('', "-Value can't be equal or lower to zero.", 'warning').then( result => item.unserved_qty = item.requested_qty - item.reserved_qty );
                }
            },
            restrictToNumbers (event) {
                let keyCode = event.keyCode ? event.keyCode : event.which;
                let inputValue = event.target.value;
                if ((inputValue === '' && keyCode === 48) || keyCode === 45 || keyCode < 48 || keyCode > 57) {
                    event.preventDefault();
                }
            },
            savePickerOrCheckerPersonnel() {
                let payload = {
                    rfStatus: this.rfStatus,
                    partsOrderId: this.partsOrderId,
                    personnel_id: this.personnelName,
                    url: 'picker-checker-personnel'
                }
                this.$store.dispatch('servicePost', payload).then(response => {
                    this.personnelName = response.data.picker_personnel_id;
                    this.edit.picker = this.GET_PICKER_PERSONNELS.find( e => e.value == this.personnelName);
                    this.forPrintingDataFooterEdit[1].value = this.edit.picker.text;
                    this.enablePrint = false
                }).catch(e => {
                    console.log(e)
                })
            },
            savePartsCrateNumber() {
                if (!this.crate_number.parts_crate_num.trim()) {
                    swal.fire("","-Type crate number first", "warning");
                    return;
                }
                swal.fire({
                    title: `Are you sure to save crate number?`,
                    text: '-Confirm to proceed',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                }).then( ans => {
                    if (ans.isConfirmed) {
                        this.crate_number.loading = true;
                        let payload = {
                            partsOrderId:this.partsOrderId,
                            crate_number: this.crate_number.parts_crate_num,
                            url:'parts-crate-number'
                        }
                        this.$store.dispatch('servicePost', payload).then( response => {
                            swal.fire(response.data.message);
                            this.crate_number.parts_crate_num = response.data.crate_number
                            this.crate_number.loading = false;
                        }).catch( e => {
                            console.log(e);
                        })
                    }
                })
            }
      }
  }
  </script>

  <style scoped>
    .center-input input {
        text-align: center;
    }
  </style>
