<template>
  <v-container fluid>
    <h3 class="page-title">Repair Request Form</h3>
    <v-container class="ma-0">
      <v-app id="item-tabs">
        <v-tabs
          v-model="tab"
          id="group-tab"
          color="cyan"
          slider-color="cyan"
          background-color="#424242"
          dark
          show-arrows
        >
          <v-tabs-slider color="cyan"></v-tabs-slider>
          <v-tab
            ripple
            v-for="(item, i) in items"
            :key="i"
            @click="tabs(i)"
          >
            {{ item.tab_name }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(item, i) in items" :key="i">
            <keep-alive>
              <component
                :is="rrf_tabs_items.components"
                :rrf_tabs_items="rrf_tabs_items"
                :isActive_tab="item.tab_name"
              >
              </component>
            </keep-alive>
          </v-tab-item>
        </v-tabs-items>
      </v-app>
    </v-container>
    <DialogComponent
      :cp="components"
      :scrollable="dialog_scrollable"
      :width="width(dialog_width)"
      :retainfocus="false"
    ></DialogComponent>
  </v-container>
</template>
  
<script>
import DialogComponent from "@/views/main/layouts/dialogs/Dialog.vue";
import RepairRequestFormComponent from "@/views/main/modules/Admin/RepairRequestForm/RepairRequestFormComponent.vue";
import RepairRequestFormViewComponent from "@/views/main/layouts/dialogs/Admin/RepairRequestFormViewComponent.vue";
import RepairTypeComponent from "@/views/main/modules/Admin/RepairRequestForm/RepairTypeComponent.vue";
import RepairTypeDialogComponent from './RepairTypeDialogComponent.vue';
import { mapGetters } from "vuex";
export default {
  components: {
    DialogComponent,
    RepairRequestFormViewComponent,
    RepairRequestFormComponent,
    RepairTypeComponent,
    RepairTypeDialogComponent,
  },
  data() {
    return {
      form: {
        code: "",
        name: "",
        sub_class: [],
        sub_class_trash: [],
      },
      components: "",
      items: [
        { tab_name: "ALL", view: true },
        { tab_name: "PENDING", view: true },
        { tab_name: "FOR ADMIN", view: true },
        { tab_name: "APPROVED", view: true },
        { tab_name: "REPAIR TYPE", view: true },
      ],
      rrf_tabs_items: {
        components: RepairRequestFormComponent,
        dialog: RepairRequestFormViewComponent,
        dialog_title: "Purchase Requisition",
        dialog_width: "80%",
        scrollable: true,
        dispatch: {
          get: "getAllRRF",
          add: "CreateRRF",
          update: "UpdateRRF",
          delete: "DeleteRRF",
        },
      },
      dialog_width: "",
      loaded: false,
      options: {},
      itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
      itemsPerPage: 10,
      tab: null,
      dialog_scrollable: true,
    };
  },
  computed: {
    ...mapGetters([
      "GET_RRF_DISPATCH_STATUS",
      "GET_NEW_ADMIN_OTHER_TABLE",
      "GET_NEW_PURCHASE_REQUEST_TABLE",
    ]),
  },
  mounted() {
    this.dropDowns();
    this.tabs(0);
  },
  methods: {
    dropDowns() {
      this.$store.dispatch("getDepartment");
      this.$store.dispatch("getAdminLocationSelection");
      this.$store.dispatch("repairTypeSelection");
      this.$store.dispatch("getAdminClassificationSelection");
    },
    tabs(key) {
      this.components = this.rrf_tabs_items.dialog;
      this.dialog_width = this.rrf_tabs_items.dialog_width;
      this.dialog_scrollable = this.rrf_tabs_items.scrollable;
      if (this.items[key].tab_name === "REPAIR TYPE") {
        this.rrf_tabs_items.components = RepairTypeComponent;
        this.components = RepairTypeDialogComponent;
        this.dialog_width = "50%";
      } else {
        this.rrf_tabs_items.components = RepairRequestFormComponent;
      }
      this.$store.commit("RRF_DISPATCH_STATUS", this.items[key]);
      this.$store.commit("RRF_DISPATCH", this.rrf_tabs_items.dispatch);
    },
    width(width) {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%";
        case "sm":
          return "100%";
        case "md":
          return width;
        case "lg":
          return width;
        case "xl":
          return width;
      }
    },
  },
  watch: {},
};
</script>

<style>
#group-tab {
  padding: 0 !important;
}
</style>
  