<template>
    <div class="tabs-body-color">
        <v-container fluid class="pa-0">
            <v-row class="pt-4 pl-2" v-if="userAccessCodes.includes('asset_condition_create')">
                <v-col cols="6" class="d-flex no-block align-items-center">
                    <div class="btn-toolbar pl-0">
                        <v-btn class="ml-1 mb-2" @click="AddDialog()">
                            <v-icon>mdi-plus</v-icon>Add
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <AssetConditionTableComponent v-if="GET_ADMIN_TAB_IS_ACTIVE == isActive_tab"></AssetConditionTableComponent>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import AssetConditionTableComponent from '@/views/main/modules/Admin/Accountability/AssetConditionTableComponent.vue';
import { mapGetters } from 'vuex'
import Swal from "sweetalert2";
export default {
    components: {
        AssetConditionTableComponent,
    },
    props:["isActive_tab"],
    data() {
        return {
            userAccess:{
                create:false
            }
        }
    },
    computed: {
        ...mapGetters([
            'USER_ACCESS',
            'GET_ADMIN_TAB_IS_ACTIVE',
            'GET_ADMIN_ITEMS_COLLECTIONS',
        ]),
        userAccessCodes() {
            return this.USER_ACCESS.map(access => access.actions_code);
        },
    },
    mounted() {},
    methods: {
        AddDialog(){
            if(this.GET_ADMIN_TAB_IS_ACTIVE == 'unaccounted' && this.GET_ADMIN_ITEMS_COLLECTIONS.length == 0){
                Swal.fire({
                    title:'please select items'
                })
                return false;
            }
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", "Submit");
        }
    },
    watch:{
        USER_ACCESS:{
            handler(val){
                if(val != "fail"){
                    this.userAccess.create = false;
                    val.forEach(e => {
                        if (e.actions_code == 'create') {
                            this.userAccess.create = true;
                        }
                    });
                }
            }
        }
    }
};
</script>
