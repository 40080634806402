<template>
<div class="tabs-body-color">
    <v-container>
        <div class="row">
            <WarehousePreparationTableComponentVue
                :warehousePreparationListsActionResult="action"
                :tab_name="tab_name"
                :print="print"
                :user_access="USER_ACCESS"
                @showEditModal="showEditModal"
            ></WarehousePreparationTableComponentVue>
        </div>
    </v-container>

    <v-app id="addEditWarehousePreparationListModal">
        <v-dialog v-model="add_edit_warehousepreparationlist_dialog" persistent max-width="98%" scrollable>
            <v-card>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">Warehouse Preparation Form</span>
                    </v-card-title>
                    <v-col class="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="add_edit_warehousepreparationlist_dialog = false; resetFields();">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text v-if="loaded==true">
                    <form class="form-horizontal" action="/warehousepreparationlists" method="post" @submit.prevent="onSubmit">
                        <div class="card-body">
                            <div class="row m-0 text-dark" v-if="action=='Edit'">
                                <div class="col-sm-12 col-md-4 text-left">
                                    <div class="d-flex flex-column">
                                        <span>Warehouse Preparation Form No: {{ this.wp_list.id }}</span>
                                        <span>Sales Order No: {{ this.wp_list.sales_order_num }}</span>
                                        <span>Issuer: {{ this.wp_list.issuer_name }}</span>
                                        <span>Date Issued: {{ this.wp_list.created_at | formatDateTime24hours }}</span>
                                        <span>Start Date: {{ this.wp_list.start_date | formatDateTime24hours }}</span>
                                        <span>End Date: {{ this.wp_list.end_date | formatDateTime24hours }}</span>
                                        <span>Durations: {{ this.wp_list.durations }}</span>
                                        <span>Correct Picking Reason: {{ this.wp_list.correct_picking_reason }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-8 text-left">
                                    <div class="row">
                                        <v-spacer></v-spacer>
                                        <div class="col-sm-12 col-md-3">
                                            <v-autocomplete
                                                v-model="preparer_name"
                                                :items="GET_ALL_WAREHOUSE_PERSONNEL_PREPARER"
                                                dense
                                                outlined
                                                item-value="name"
                                                item-text="name"
                                                label="Select Preparer"
                                                class="w-100"
                                                auto-select-first
                                            ></v-autocomplete>
                                        </div>
                                        <div class="col-sm-12 col-md-3">
                                            <v-text-field
                                                v-model="remarks"
                                                dense
                                                outlined
                                                id="remarks"
                                                label="Remarks"
                                            ></v-text-field>
                                        </div>
                                        <div class="col-sm-12 col-md-3">
                                            <v-autocomplete
                                                v-model="wp_list.warehouse_id"
                                                :items="GET_ALL_WAREHOUSES"
                                                dense
                                                outlined
                                                item-value="value"
                                                item-text="text"
                                                label="Select Warehouse"
                                                class="w-100"
                                                auto-select-first
                                                @input="filterWarehouseStocks()"
                                            ></v-autocomplete>
                                        </div>
                                    </div>
                                    <v-row>
                                        <v-spacer></v-spacer>
                                        <div class="col-sm-12 col-md-3">
                                            <span class="d-flex justify-space-between"><strong>Correct Picking</strong><v-icon v-if="isVisible" small @click="clearCorrectPicking()">mdi-close</v-icon></span>
                                            <v-radio-group inline class="d-flex flex-row" v-model="correction_picking">
                                                <v-row>
                                                   <v-col class="d-flex align-center justify-start pl-2">
                                                    <v-radio value="0"></v-radio><span>No</span>
                                                   </v-col>
                                                   <v-col class="d-flex align-center justify-start">
                                                    <v-radio value="1"></v-radio><span>Yes</span>
                                                    </v-col>
                                                </v-row>
                                                <v-row class="d-flex flex-column pl-2 pr-2"  v-if="ReasonisVisible">
                                                    <label class="text-caption">Please specify:</label>
                                                    <v-text-field
                                                    v-model="correct_picking_reason"
                                                    dense
                                                    outlined
                                                    label="Reason"
                                                    ></v-text-field>
                                                </v-row>
                                            </v-radio-group>
                                        </div>
                                        <div class="col-sm-12 col-md-3">
                                            <v-text-field
                                            v-model="start_date"
                                            v-mask="mask_start_date"
                                            label="Start Date and Time"
                                            outlined
                                            dense
                                            placeholder="YYYY-MM-DD 24:00"

                                            >
                                            </v-text-field>

                                        </div>
                                        <div class="col-sm-12 col-md-3">
                                            <v-text-field
                                            v-model="end_date"
                                            v-mask="mask_end_date"
                                            label="End Date and Time"
                                            outlined
                                            dense
                                            placeholder="YYYY-MM-DD 24:00"
                                            >
                                            </v-text-field>

                                        </div>
                                    </v-row>
                                </div>
                            </div>
                        </div>

                        <v-card
                            outlined
                            class="p-2"
                        >
                            <div class="row">
                                <div class="col-sm-12 col-md-12">
                                    <v-simple-table dense
                                        class="dynamic_list_table">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-center mx-2" width="4%">MOQ</th>
                                                    <th class="text-center mx-2" width="4%">Qty to Ship</th>
                                                    <th class="text-center mx-2" width="4%">Qty</th>
                                                    <th class="text-center mx-2" width="4%" v-if="wp_list.status == 6">Partially Reserve</th>
                                                    <th class="text-center mx-2" width="4%" v-if="stock_card">SC Qty</th>
                                                    <th class="text-center mx-2" width="4%">UOM</th>
                                                    <th class="text-center mx-2" width="10%">CATEGORY</th>
                                                    <th class="text-center mx-2" width="10%">Model</th>
                                                    <th class="text-center mx-2" width="26%">Name</th>
                                                    <th class="text-center mx-2" width="5%">Type</th>
                                                    <th class="text-center mx-2" width="5%">Stocks</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="(wpListItem, i) in wpListItems"
                                                    :key="i"
                                                    :class="wpListItems[i].warehouse_stocks == 0 ? 'yellow_row' : ''"
                                                >
                                                    <td>
                                                        <input type="text" class="form-control text-center" v-model="wpListItems[i].moq" readonly>
                                                    </td>
                                                    <td>
                                                        <!-- <input type="text" class="form-control text-center" v-model="wpListItems[i].quantity_served" @keypress="numberOnly"
                                                            @input="changeQuantity(i)" @blur="checkMoq(i)"> -->
                                                        <input type="text" class="form-control text-center" v-model="wpListItems[i].quantity_served" @keypress="numberOnly"
                                                            @input="changeQuantity(i)">
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control text-center" v-model="wpListItems[i].quantity" readonly>
                                                    </td>
                                                    <td v-if="wp_list.status == 6">
                                                        <input type="text" class="form-control text-center" v-model="wpListItems[i].partially_reserve" readonly>
                                                    </td>
                                                    <td v-if="stock_card">
                                                        <input type="text" class="form-control text-center" v-model="wpListItems[i].sc_quantity" readonly>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control text-center small" v-model="wpListItems[i].item_uom_text" readonly>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control text-center small" v-model="wpListItems[i].item_category_text" readonly>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control text-center small" v-model="wpListItems[i].model" readonly>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control text-center small" v-model="wpListItems[i].name" readonly>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control text-center small" v-model="wpListItems[i].item_type_text" readonly>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control text-center small" v-model="wpListItems[i].warehouse_stocks" readonly>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </div>
                            </div>
                        </v-card>

                        <div class="border-top">
                            <div class="card-body py-4 d-flex justify-content-center">
                                <v-btn  v-on:click="addNewWarehousePreparationList()" v-if="action=='Add'">Submit</v-btn>
                                <v-btn  v-on:click="editWarehousePreparationList()" v-else-if="action=='Edit'">Update</v-btn>
                            </div>
                        </div>
                    </form>
                </v-card-text>
                <v-card-text v-else-if="loaded==false">
                    <div class="text-center">
                        <v-progress-circular
                            :size="50"
                            color="primary"
                            indeterminate
                        ></v-progress-circular>
                    </div>
                </v-card-text>

            </v-card>
        </v-dialog>
    </v-app>

</div>
</template>

<script>
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
import WarehousePreparationTableComponentVue from './WarehousePreparationTableComponent.vue';
import {mask} from 'vue-the-mask';
import swal from 'sweetalert2';
import { mapGetters } from 'vuex';


    export default {
        mixins: [SharedFunctionsComponentVue],
        data(){
            return {
                stock_card: false,
                wp_list: '',
                editWarehousePreparationListId: '',
                add_edit_warehousepreparationlist_dialog: false,

                wp_list_num: '',
                wp_list_date: '',

                user_id: '',
                status: '',
                created_at: '',
                issuer_name: '',

                preparer_name:'',
                remarks:'',
                start_date: null,
                end_date: null,
                mask_start_date: '####-##-## ##:##',
                mask_end_date: '####-##-## ##:##',
                personnels_id: null,
                correction_picking: null,
                correct_picking_reason: '',

                wpListItems: [],
                order: {
                    quantity_served: '',
                    quantity: '',
                    item_id: '',
                    type: '',
                    item: {
                        id: '',
                        model: '',
                        name: '',
                        description: '',
                        type_id: '',
                        category_id: '',
                        uom_id: '',
                        item_uom_text: '',
                        selling_price: '',
                        sp_price: '',
                        moq: ''

                    },
                },

                items_list: [],
                type_list: [],
                warehouses_list: [],
                errors: [],
                action: '',
                actionResult : {
                    model : 'Warehouse Preparation Form',
                    action : ''
                },
                loaded: false,

                loading:{
                        warehouse_selection : true
                },
                isVisible: false,
                ReasonisVisible: false
            }
        },
        components: {
            WarehousePreparationTableComponentVue,
        },
        directives:{
            mask
        },
        props: [
            'tab_name',
            'print',
        ],
        methods: {
            filterWarehouseStocks(){
                if(this.wp_list.warehouse_id != null && this.wp_list.warehouse_id != ''){
                    if(this.editWarehousePreparationListId != null && this.editWarehousePreparationListId > 0){
                        let payload = {
                            warehouse_id: this.wp_list.warehouse_id,
                            url: 'viewWarehousePreparationList/'+this.editWarehousePreparationListId,
                        }
                        this.$store.dispatch('getWPF',payload).then(response => {
                            let data = response.data

                            if(data.error){
                                swal.fire("", data.error, "warning");
                                return false
                            }

                            this.wpListItems        = data.wp_list.warehouse_preparation_list_items

                            this.loaded = true

                        }).catch(error => {

                        });
                    }
                }
            },
            checkMoq(key){
                if(this.wpListItems[key].quantity_served != '' && this.wpListItems[key].moq != ''){
                    var moq = parseInt(this.wpListItems[key].moq);
                    if(moq != null && moq > 0){
                        if(this.wpListItems[key].quantity_served % moq > 0){

                            var upperQty = this.wpListItems[key].quantity_served
                            var lowerQty = this.wpListItems[key].quantity_served
                            while(upperQty % moq > 0){
                                upperQty++
                            }
                            while(lowerQty % moq > 0){
                                lowerQty--
                            }
                            var suggestion = ''
                            if(lowerQty == 0){
                                suggestion = "\n Suggestion: "+upperQty
                            }else{
                                suggestion = "\n Suggestion: "+upperQty+" or "+ lowerQty
                            }
                            swal.fire("", "Quantity should be divisible by "+moq+suggestion, "warning");
                            this.wpListItems[key].quantity_served = ''
                        }
                    }
                }
            },
            removeOrderByIndex(index){
                this.wpListItems.splice(index, 1)
            },
            changeQuantity(key){
                let qty = 0

                qty = this.stock_card ? parseInt(this.wpListItems[key].sc_quantity) : parseInt(this.wpListItems[key].quantity)
                if(parseInt(this.wpListItems[key].quantity_served) > qty){
                    this.wpListItems[key].quantity_served = qty
                }
            },
            selectModel(key, model_id, is_editable = false) {
                if(model_id != null && model_id != ''){
                    let payload = {
                        url: 'getItemEditInfo/'+model_id,
                    }
                    this.$store.dispatch('getWPF',payload).then(response => {
                        let data = response.data

                        this.wpListItems[key].item.name          = data.name
                        this.wpListItems[key].item.model         = data.model
                        this.wpListItems[key].item.sp_price      = data.sp_price
                        this.wpListItems[key].item.selling_price = data.selling_price
                        this.wpListItems[key].item.type_id       = data.type_id
                        this.wpListItems[key].type               = data.item_type_text
                        this.wpListItems[key].item.item_uom_text = data.item_uom_text
                        this.wpListItems[key].item.moq           = data.moq

                        if(is_editable){
                            this.wpListItems[key].is_editable = true
                        }

                    }).catch(error => {

                    });
                }
                $("input").blur();
            },

            showAddWarehousePreparationList(){
                this.add_edit_warehousepreparationlist_dialog = true
                this.action                    = 'Add'
            },
            showEditModal(value){
                this.action = 'Edit'
                this.editWarehousePreparationListId = value

                if(this.editWarehousePreparationListId != null && this.editWarehousePreparationListId > 0){
                    let payload = {
                        warehouse_id: this.wp_list.warehouse_id,
                        url: 'viewWarehousePreparationList/'+this.editWarehousePreparationListId
                    }
                    this.$store.dispatch('getWPF',payload).then(response => {
                        let data = response.data

                        if(data.error){
                            swal.fire("", data.error, "warning");
                            return false
                        }

                        this.wp_list            = data.wp_list
                        this.wpListItems        = data.wp_list.warehouse_preparation_list_items
                        this.stock_card         = data.stock_card

                        this.preparer_name      = data.wp_list.preparer_name
                        this.remarks            = data.wp_list.remarks

                        this.loaded = true
                        this.add_edit_warehousepreparationlist_dialog = true
                    }).catch(error => {

                    });
                }
            },

            async editWarehousePreparationList(){
                // .forEach(e => {
                // for(let i in this.wpListItems){
                //    if(this.wpListItems[i].quantity_served < 1 || this.wpListItems[i].quantity_served == undefined){
                //         swal.fire('Error','Unable to proceed quantity serve must be greater than 0.','error')
                //         return false
                //    }
                // }

                // Define start and end dates

                let object = {}
                    object.startTime = new this.$dayjs(this.start_date);
                    object.endTime =  new this.$dayjs(this.end_date);

                    object.durations  = this.formatDateTimeDuration(object.startTime, object.endTime)

                    let payload = {
                            wp_id:          this.wp_list.id,
                            wpListItems:    this.wpListItems,
                            warehouse_id:   this.wp_list.warehouse_id,
                            preparer_name:  this.preparer_name,
                            remarks:        this.remarks,
                            start_date:     object.startTime.format('YYYY:MM:DD HH:mm'),
                            end_date:       object.endTime.format('YYYY:MM:DD HH:mm'),
                            durations:      object.durations,
                            picking_correction: this.correction_picking,
                            correct_picking_reason: this.correct_picking_reason,
                            url:            'warehousePreparationLists/'+this.editWarehousePreparationListId,
                        }
                        this.$store.dispatch('putWPF',payload).then(response => {
                            let data = response.data
                            if(data.error){
                                swal.fire("", data.error, "warning");
                                return false
                            }
                            this.resetFields()
                            this.add_edit_warehousepreparationlist_dialog = false
                            this.editWarehousePreparationListId           = 0
                            this.action = 'success'
                            swal.fire("", "Edit Warehouse Preparation Form Successful!", "success");

                        }).catch(error => {
                            if(error.response.status == 422){
                                this.errors = error.response.data.errors
                            }
                        });
            },
            resetFields(){
                this.preparer_name   = ''
                this.remarks         = ''
                this.errors          = []
                this.wp_list_num     = ''
                this.wp_list_date    = ''
                this.user_id         = ''
                this.status          = ''
                this.created_at      = ''
                this.issuer_name     = ''
                this.wpListItems     = []
                this.wp_list         = []
                this.stock_card      = false
                this.end_date        = null
                this.start_date      = null
                this.correction_picking = '';
                this.correct_picking_reason = ''
            },
            getWarehouses(){
                // this.$store.dispatch('getAllWarehouses',{}).then((response)=>{
                //     this.warehouses_list = response.data
                // });
            },
            clearCorrectPicking(){
                this.correction_picking = '';
                this.correct_picking_reason = ''
            }
        },
        mounted(){
            this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
            this.getWarehouses()
            this.$store.dispatch('getAllwarehousepersonnelPreparer')
            //  $('input').attr('autocomplete','off');
        },
        computed:{
            ...mapGetters([
                'GET_ALL_WAREHOUSES',
                'USER_ACCESS',
                'GET_ALL_WAREHOUSE_PERSONNEL_PREPARER'
            ])
        },
        watch:{
            'correction_picking':{
                handler(val){
                    if(!val.length){
                        this.isVisible = false
                        this.ReasonisVisible = false
                    }else{
                        this.isVisible = true
                        if(val == 0){
                            this.ReasonisVisible = true
                            }else{
                            this.ReasonisVisible = false
                        }
                    }
                }
            }
        }

    };
</script>
