<template>
    <v-card style="border-radius: 0px;" class="ma-0 pa-0 h-100" flat>
        <v-card-title>
            <v-row class="mx-2 mb-1 d-flex justify-content-center align-items-center">
                <v-col >
                    <v-img src="@/assets/ingcoph_logo/ingcoph_logo.jpg" :aspect-ratio="16/9" width="200px" contain></v-img>
                </v-col>
                <v-col class="d-flex justify-content-center align-items-center">
                    <h2>{{ url_meta.title }}</h2>
                </v-col>
                <v-col  cols="4" class="ma-0 pa-0 d-flex justify-content-center align-items-center">
                    <v-dialog
                        ref="dialog"
                        v-model="modal"
                        :return-value.sync="date"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            class='ml-4'
                            outlined
                            dense
                            v-model="date"
                            label="Select Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="date"
                            scrollable
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="modal = false"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(date)"
                        >
                            OK
                        </v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text>
            <v-row class="mx-2 mb-2">
                <v-col class="text-center pa-2" cols="2" v-for="(item,i,index) in items" :key="i">
                    <v-card elevation="5" class="ma-0 rounded-lg text-center" v-if="item.total != 0">
                        <v-card-text class="ma-0 pa-0 border-bottom d-flex justify-content-center align-items-center" style="background-color: #f49e17;">
                            <h3 class="mt-1" style="color: rgb(0, 0, 0);">{{item.code | uppercase}}</h3>
                        </v-card-text>
                        <v-card-text class="d-flex justify-content-center align-item-center">
                            <h1 class="ma-5" style="color: black; font-size: 56px;" >{{ thousandSeprator(item.available) }} / {{thousandSeprator(item.total)}}</h1>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue'
import PusherFunctionComponent from '@/views/main/Shared/PusherFunctionComponent.vue';

export default {
    mixins: [SharedFunctionsComponentVue,PusherFunctionComponent],
    data(){
        return {
            items:[],
            modal:false,
            date:this.$dayjs().format('YYYY-MM-DD'),
            toggleView:true,
            url_meta:{
                url:this.$router.currentRoute.meta.url,
                title:this.$router.currentRoute.meta.title.toUpperCase(),
                realtime_event:this.$router.currentRoute.meta.realtime_event,
            },
        }
    },
    mounted(){
        this.callPusher();
        this.getAllItems();
        this.ServerPusher();
    },
    methods:{
        async getAllItems(){
            await this.$store.dispatch('getqueue',{
                url:this.url_meta.url,
                date:this.date,
            }).then(response=>{
                this.items = response.data
            })
        },
        ServerPusher(){
            window.Echo.channel('ingco-channel').listen(this.url_meta.realtime_event,(e)=>{
                this.manipulateData(e)
            })
        },
        manipulateData(e){
            this.items.forEach(x=>{
                if(x.id == e.data['id']){
                    x.available = e.data['method'] == 'available' ?  x.available += 1 : x.available -= 1
                }
            })
        }
    },
    watch:{
        date:{
            handler(){
                this.getAllItems();
            }
        },
    }
}
</script>

<style scoped>
.ul-listing {
    column-count: auto;
    column-gap: normal;
    column-fill: auto;
    height:77vh;
}
.large-spacing{
    column-width: 9.03rem;
}
.small-spacing{
    column-width: 8.5rem;
}
.toggle-list{
    max-height: 500px;
    column-count: auto;
    flex-flow: column wrap;
    list-style-type: disc;
    list-style-position: inside;
}
.upTextsize{
    font-size: 20px;
}

th{
    font-size: 100px;
}
</style>