<template>
    <div class="pb-4" style="width:100%;border-radius:0px">
        <v-card class="rounded-0" flat>
            <v-container v-if="tab == 0">
                <v-row>
                    <v-col lg="12">
                        <v-row class="mx-0 px-4 mt-2">
                            <v-col sm="12" md="12" lg="2" class="pr-4">
                                <v-dialog
                                    ref="dateStartDialog"
                                    v-model="startDateModal"
                                    :return-value.sync="startDate"
                                    persistent
                                    width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="startDate"
                                        label="Date Start"
                                        readonly
                                        dense
                                        append-icon="mdi-calendar-clock"
                                        class="hide-text-field-details"
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                        clearable
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="startDate"
                                        scrollable
                                        :max="minDate"
                                    >
                                    <v-spacer />
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="startDateModal = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.dateStartDialog.save(startDate)"
                                    >
                                        OK
                                    </v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>
                            <v-col  sm="12" md="12" lg="2">
                                <v-dialog

                                    ref="endDateDialog"
                                    v-model="endDateModal"
                                    :return-value.sync="endDate"
                                    persistent
                                    width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="endDate"
                                        label="Date End"
                                        readonly
                                        dense
                                        append-icon="mdi-calendar-clock"
                                        class="hide-text-field-details"
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                        :disabled="!startDate"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="endDate"
                                        scrollable
                                        :min="startDate"
                                        :max="minDate"
                                    >
                                    <v-spacer />
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="endDateModal = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.endDateDialog.save(endDate)"
                                    >
                                        OK
                                    </v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>
                            <v-col sm="12" md="2">
                                <v-btn class="ml-1" @click="filter(); options.page = 1">Filter</v-btn>
                            </v-col>
                            <v-spacer/>
                            <v-col sm="12" md="12" lg="3" class="d-flex justify-end align-start">
                                <v-btn @click="refreshEstimationStocks()" :loading="loading" fab text v-if="['PP','PRQ','PFO'].includes(rfStatus)"> <v-icon color="blue">mdi-sync</v-icon></v-btn>
                                <v-tooltip  v-if="['PIN'].includes(rfStatus) && userAccess.includes('return_receipt')" bottom>
                                    <template v-slot:activator="{ on }">
                                       <v-btn v-on="on" class="ml-1" @click="returnReceipt.dialog = true; getAllReturnReceipts(); getSystemPlatform()">RRF</v-btn>
                                    </template>
                                    <span>Return Receipt Form</span>
                                </v-tooltip>
                                <v-btn class="ml-1" @click="resetFilter()">Clear</v-btn>
                                <v-menu offset-x>
                                    <template v-slot:activator="{ on: menu, attrs }">
                                        <v-tooltip bottom>
                                            <template
                                                v-slot:activator="{ on: tooltip }"
                                            >
                                                <v-btn
                                                    class="ml-1"
                                                    v-on="{ ...tooltip, ...menu }"
                                                    v-bind="attrs"
                                                >
                                                    Export
                                                </v-btn>
                                            </template>
                                            <span>Export by Warranty Type</span>
                                        </v-tooltip>
                                    </template>
                                    <v-list
                                        color="blue-grey lighten-5"
                                        outlined
                                        dense
                                        tile
                                    >
                                    <!-- @click="selectedOption(item)" -->
                                        <v-list-item
                                            v-for="(item, index) in warrantyType"
                                            :key="index"
                                            light
                                            dense
                                            @click="exportExcels(item)"
                                            >
                                            <v-list-item-title>{{ item }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-col>
                        </v-row>

                        <v-row class="mx-0 px-4 mt-5">
                            <v-spacer />
                            <v-col sm="12" md="12" lg="3">
                                <v-text-field
                                    v-model="search"
                                    append-outer-icon="mdi-magnify"
                                    label="Search RF# / Crate# / Serial# / Item Name / Model"
                                    single-line
                                    hide-details
                                    class="pa-0 ma-0"
                                    clearable
                                    @keypress.enter="getAllRfs(); options.page = 1"
                                    @click:append-outer="getAllRfs(); options.page = 1"
                                    @click:clear="search='';getAllRfs()"
                                />
                            </v-col>
                        </v-row>

                        <v-card-text>

                            <v-layout row class="mx-0 mt-4 mb-2">
                                <v-flex lg1>
                                    <div>
                                        <label for="">{{ rfStatus }} Count: </label>
                                        <span style="font-weight:bold">{{ totalItems }}</span>
                                    </div>
                                </v-flex>
                                <v-spacer />
                                <v-flex shrink>
                                    <div v-if="['PRQ','WFP','WFR', 'PP', 'PFO'].includes(rfStatus)">
                                        <span class="badge bg-light ml-1" style="cursor:pointer" @click="getAllRfs(); ready = 0">all</span>
                                        <span class="badge bg-success ml-1 text-white" style="cursor:pointer" @click="getAllRfs(); ready = 2" >ready</span>
                                        <span class="badge bg-danger ml-1 text-white" style="cursor:pointer" @click="getAllRfs(); ready = 1">not ready</span>
                                    </div>
                                </v-flex>
                            </v-layout>
                            <v-data-table
                                :headers="tableHeaders()"
                                :items="rfLists"
                                :loading="loading"
                                :options.sync="options"
                                :server-items-length="totalItems"
                                :no-data-text="noDataText"
                                :search="search"
                            >
                                <!-- :server-items-length="totalItems" -->
                                <!-- <template v-slot:item.company_name="{ item }">
                                    <td class="px-0">{{ companyName(item) }}</td>
                                </template>

                                <template v-slot:item.customer_name="{ item }">
                                    <td class="px-0">{{ customerName(item) }}</td>
                                </template> -->

                                <template v-slot:item.aging="{ item }">
                                    <td>{{ agingDate(item.created_at) }}</td>
                                </template>

                                <template v-slot:item.proceed_days="{ item }">
                                    <td>{{ agingDate(item.proceed_days) }}</td>
                                </template>

                                <template v-slot:item.created_at="{ item }">
                                    <td>{{ date(item) }}</td>
                                </template>

                                <template v-slot:item.latest_date="{ item }">
                                    <td>{{ dateProceed(item) }}</td>
                                </template>

                                <template v-slot:item.ready_to_serve="{ item }">
                                    <td>
                                        <!-- <span v-if="item.ready_to_serve" class="badge bg-success">{{ item.ready_to_serve }}</span>
                                        <span v-else class="badge bg-danger">{{ item.ready_to_serve }}</span> -->

                                        <v-avatar
                                            :color="item.ready_to_serve == 1 ? 'success' : 'error'"
                                            size="15"
                                        ></v-avatar>

                                    </td>
                                </template>

                                <template v-slot:item.ready_to_proceed="{ item }">
                                    <td>
                                        <!-- <span v-if="item.ready_to_proceed" class="badge badge-success">{{ item.ready_to_proceed }}</span>
                                        <span v-else class="badge badge-danger">{{ item.ready_to_proceed }}</span> -->
                                        <v-avatar
                                        :color="item.ready_to_proceed == 1 ? 'success' : 'error'"
                                        size="15"
                                    ></v-avatar>
                                    </td>
                                </template>

                                <template v-slot:item.picking_correction="{ item }">
                                    <td>
                                        {{ item.picking_correction == null ? '' : (item.picking_correction == 1 ? 'Yes' : 'No' ) }}
                                    </td>
                                </template>

                                <template  v-slot:item.action="{ item }">
                                    <td class="px-0">
                                        <span class="text-nonwrap">
                                            <v-btn text icon color="orange">
                                                <v-icon class="btn-action" small @click="showScPartsOrderDialog(item)">mdi-eye</v-icon>
                                            </v-btn>
                                            <v-btn text icon color="red" v-if="['PRQ','PP'].includes(rfStatus)">
                                                <v-icon class="btn-action" small @click="cancelPending(item)">mdi-cancel</v-icon>
                                            </v-btn>
                                        </span>
                                    </td>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <v-dialog v-model="returnReceipt.dialog" max-width="70%" persistent scrollable>
            <v-card>
                <v-card-title class="ma-0 pt-2 pl-2 pr-0 pb-0">
                    <v-col>
                        <h4>Return Receipts</h4>
                    </v-col>
                    <v-spacer />
                    <v-col class="pt-0 pr-0">
                        <v-btn text icon color="gray" class="float-right pull-right" @click=" closeReturnReceiptDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-card-title>
                <v-divider class="ma-0"/>
                <v-card-text>
                    <v-row class="w-100 mt-4 mb-1">
                        <v-col sm="12" md="12" lg="3" class="pr-2">
                            <v-dialog
                                ref="rrfStartDateDialog"
                                v-model="returnReceipt.dateModal.start"
                                :return-value.sync="returnReceipt.filters.startDate"
                                persistent
                                width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="returnReceipt.filters.startDate"
                                    label="Date Start"
                                    readonly
                                    dense
                                    append-icon="mdi-calendar-clock"
                                    class="hide-text-field-details"
                                    v-bind="attrs"
                                    v-on="on"
                                    outlined
                                    clearable
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="returnReceipt.filters.startDate"
                                    scrollable
                                    :max="minDate"
                                    @change="setEndDate()"
                                >
                                <v-spacer />
                                <v-btn
                                    text
                                    color="primary"
                                    @click="returnReceipt.dateModal.start = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.rrfStartDateDialog.save(returnReceipt.filters.startDate)"
                                >
                                    OK
                                </v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-col>
                        <v-col  sm="12" md="12" lg="3" class="pr-2">
                            <v-dialog
                                ref="rrfEndDateDialog"
                                v-model="returnReceipt.dateModal.end"
                                :return-value.sync="returnReceipt.filters.endDate"
                                persistent
                                width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="returnReceipt.filters.endDate"
                                    label="Date End"
                                    readonly
                                    dense
                                    append-icon="mdi-calendar-clock"
                                    class="hide-text-field-details"
                                    v-bind="attrs"
                                    v-on="on"
                                    outlined
                                    :disabled="!returnReceipt.filters.startDate"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="returnReceipt.filters.endDate"
                                    scrollable
                                    :min="returnReceipt.filters.startDate"
                                    :max="minDate"
                                >
                                <v-spacer />
                                <v-btn
                                    color="primary"
                                    text
                                    @click="returnReceipt.dateModal.end = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.rrfEndDateDialog.save(returnReceipt.filters.endDate)"
                                >
                                    OK
                                </v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-col>
                        <v-col>
                            <v-btn
                                @click="getAllReturnReceipts()"
                            >
                                filter
                            </v-btn>
                        </v-col>
                        <v-spacer/>
                    </v-row>
                    <v-row class="mb-1">
                        <v-spacer/>
                        <v-col cols="12" md="3" class="pa-0 ma-0">
                            <v-text-field
                                v-model="returnReceipt.filters.search"
                                label="Search RRF#"
                                class="pa-0"
                                clearable
                                dense
                                hide-details
                                append-outer-icon="mdi-magnify"
                                single-line
                                @keypress.enter="getAllReturnReceipts(); returnReceipt.dataTable.options.page = 1"
                                @click:append-outer="getAllReturnReceipts(); returnReceipt.dataTable.options.page = 1"
                                @click:clear="returnReceipt.filters.search='';getAllReturnReceipts()"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-data-table
                                :headers="returnReceipt.dataTable.headers"
                                :items="returnReceipt.dataTable.items"
                                :loading="returnReceipt.dataTable.loading"
                                :options.sync="returnReceipt.dataTable.options"
                                :no-data-text="returnReceipt.dataTable.noDataText"
                                :server-items-length="returnReceipt.dataTable.totalItems"
                                :search="returnReceipt.filters.search"
                                dense
                                disable-sort
                                @update:options="getAllReturnReceipts()"
                            >
                                <!-- :server-items-length="returnReceipt.dataTable.totalItems" -->
                                <template #[`item.created_at`]="{ item }">
                                    <span class="text-nowrap">

                                        {{ item.created_at | formatDateTime }}
                                    </span>
                                </template>
                                <template v-slot:[`item.status`]="{ item }">
                                    <div :class="returnReceipt.statuses[item.status]">
                                        {{ item.status }}
                                    </div>
                                </template>
                                <template #[`item.action`] = "{ item }">
                                    <td class="px-0">
                                        <span class="text-nonwrap">
                                           <v-btn color="orange" dense icon text small @click="showOrEditReturnReceipt(item, 'View')">
                                                <v-icon class="btn-action" small>mdi-eye</v-icon>
                                            </v-btn>
                                            <v-btn v-if="['saved'].includes(item.status)" color="blue" dense icon text small @click="showOrEditReturnReceipt(item, 'Edit')">
                                                <v-icon class="btn-action" small>mdi-pencil</v-icon>
                                            </v-btn>
                                            <!-- <v-btn color="red" dense icon text small>
                                                <v-icon class="btn-action" small @click="cancelPending(item)">mdi-cancel</v-icon>
                                            </v-btn> -->
                                        </span>
                                    </td>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-dialog v-model="returnReceipt.actionsDialog.dialog" max-width="65%" persistent scrollable>
                <v-card>
                    <v-card-title class="ma-0 pt-2 pl-2 pr-0 pb-0">
                        <v-col>
                            <span>{{ returnReceipt.action }} Return Receipt</span>
                        </v-col>
                        <v-spacer />
                        <v-col class="pt-0 pr-0">
                            <v-btn text icon color="gray" class="float-right pull-right" @click="returnReceipt.actionsDialog.dialog = false">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-card-title>
                    <v-divider  class="mt-0 mb-2"></v-divider>
                    <v-card-text>
                        <v-row class="w-100 mt-1 mb-1 pa-4 pt-0">
                            <v-col cols="12" md="5" class="pa-2">
                                <v-row>
                                    <span class="font-weight-bold">
                                        RRF#:
                                    </span>
                                    <span class="ml-2">{{ returnReceipt.itemDetails.series }}</span>
                                </v-row>
                                <v-row>
                                    <span class="font-weight-bold">
                                        Status:
                                    </span>
                                    <span class="ml-2">
                                        {{ returnReceipt.itemDetails.status }}
                                    </span>
                                </v-row>
                                <v-row>
                                    <span class="font-weight-bold">
                                        Requested By:
                                    </span>
                                    <span class="ml-2">
                                        {{ returnReceipt.itemDetails.created_by }}
                                    </span>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="5" class="pa-2">
                                <v-row>
                                    <span class="font-weight-bold">
                                        Date:
                                    </span>
                                    <span class="ml-2">{{ returnReceipt.itemDetails.created_at | formatDateTime }}</span>
                                </v-row>
                                <v-row>
                                    <span class="font-weight-bold">
                                        Processed By:
                                    </span>
                                    <span class="ml-2">
                                        {{ returnReceipt.itemDetails.processed_by }}
                                    </span>
                                </v-row>
                                <v-row>
                                    <span class="font-weight-bold">
                                        Received By:
                                    </span>
                                    <span class="ml-2">
                                        {{ returnReceipt.itemDetails.delivered_by }}
                                    </span>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-row class="d-flex justify-end">
                                    <v-tooltip bottom color="#f69f1a">
                                        <template v-slot:activator="{ on, attrs }">
                                           <v-btn
                                               v-bind="attrs"
                                               v-on="on"
                                               :loading="loading"
                                               style="opacity:1;"
                                               color="#f69f1a"
                                               class="ml-4"
                                               icon
                                               text
                                               @click="showUploadFileDialog()"
                                           >
                                                    <v-icon>mdi-attachment</v-icon>
                                                </v-btn>
                                        </template>
                                        <span>Attach File</span>
                                    </v-tooltip>
                                    <BaseFilesViewer :scReturnReceiptId="returnReceipt.itemDetails.id" :delete="true" :buttonColor="`#f69f1a`" class="mr-0 pa-0" @deletedAll="deletedAll"/>
                                    <v-tooltip v-if="returnReceipt.action == 'View' && returnReceipt.itemDetails.status == 'completed'" bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn :loading="loading" icon text @click="printReturnReceipt()">
                                                <v-icon
                                                    color="red"
                                                    dark
                                                    :disabled="returnReceipt.itemDetails.sc_return_receipt_files.length == 0"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    mdi-cloud-download
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Print</span>
                                    </v-tooltip>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-divider class="mt-0 mx-0"></v-divider>
                        <v-row class="w-100">
                            <v-col cols="12">
                                <v-simple-table dense outlined class="dynamic_list_table">
                                    <thead>
                                        <tr>
                                            <th class="text-center mx-2" width="10%">Item Model</th>
                                            <th class="text-center mx-2" width="19%">Item Name</th>
                                            <th class="text-center mx-2" width="5%">Quantity</th>
                                            <th class="text-center mx-2" width="22%">Justification</th>
                                            <th class="text-center mx-2" width="14%">Decision</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item,i) in returnReceipt.itemDetails.items" :key="i">
                                            <td class="pr-1">
                                                <v-text-field
                                                    v-model="item.model"
                                                    background-color="grey lighten-2"
                                                    hide-details
                                                    readonly
                                                    dense
                                                    outlined
                                                ></v-text-field>
                                            </td>
                                            <td class="pr-1">
                                                <v-text-field
                                                    v-model="item.name"
                                                    background-color="grey lighten-2"
                                                    hide-details
                                                    readonly
                                                    dense
                                                    outlined
                                                ></v-text-field>
                                            </td>
                                            <td class="pr-1">
                                            <v-text-field
                                                v-model="item.quantity"
                                                background-color="grey lighten-2"
                                                class="centered-input"
                                                hide-details
                                                readonly
                                                dense
                                                outlined
                                            ></v-text-field>
                                            </td>
                                            <td class="pr-1">
                                            <v-text-field
                                                v-model="item.justification"
                                                :readonly="returnReceipt.action == 'View'"
                                                background-color="grey lighten-5"
                                                class="centered-input"
                                                hide-details
                                                dense
                                                outlined
                                            ></v-text-field>
                                            </td>
                                            <td>
                                                <v-autocomplete
                                                    v-model="item.decision"
                                                    :items="returnReceipt.decisions"
                                                    :readonly="returnReceipt.action == 'View'"
                                                    auto-select-first
                                                    hide-details
                                                    dense
                                                    outlined
                                                >
                                                    <template #append >
                                                        <span v-if="returnReceipt.action != 'View'" style="color:red">*</span>
                                                        <span v-else></span>
                                                    </template>
                                                </v-autocomplete>
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-row v-if="returnReceipt.action == 'View'" class="w-100 ma-4 mb-2">
                            <v-col class="d-flex justify-center">
                                <v-tooltip v-if="returnReceipt.itemDetails.status == 'processing'" top color="#f69f1a">
                                    <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                color="#f69f1a"
                                                outlined
                                                fab
                                                @click="replaceParts"
                                                dark
                                                :disabled="returnReceipt.itemDetails.sc_return_receipt_files.length == 0"
                                                small
                                            >
                                                <v-icon small>mdi-truck</v-icon>
                                            </v-btn>
                                        </template>
                                    <span>Replace Parts</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                        <v-row v-if="returnReceipt.action == 'Edit'" class="w-100 ma-4 mb-2">
                            <v-col class="d-flex justify-center">
                                <v-tooltip top color="#f69f1a">
                                    <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                :loading="returnReceipt.loading.update"
                                                color="#f69f1a"
                                                fab
                                                dark
                                                small
                                                @click="updateReturnReceipt"
                                            >
                                                <v-icon small>mdi-content-save-all</v-icon>
                                            </v-btn>
                                        </template>
                                    <span>Update</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
                  <!--For Uploading File-->
                <v-dialog v-model="returnReceipt.uploadFiles.dialog" persistent max-width="20%">
                    <v-card class="pa-4">
                        <v-btn
                        text
                        icon
                        color="gray"
                        class="float-right"
                        @click=" closeUploadFileDialog()"
                        >
                        <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                        <div class="py-2">
                            <!-- <v-checkbox v-model="attach" label="Attach Documents" class="ma-0 pa-0 pt-2" /> -->
                            <span>Attach Documents via Camera or Upload</span><span v-if="(returnReceipt.uploadFiles.images == null && returnReceipt.uploadFiles.attachedFiles == null)" class="red--text ml-1">*</span>
                            <v-divider/>
                        </div>

                        <div class="py-2 mt-2">
                            <v-layout row class="mx-0">
                                <v-flex lg2 class="mr-2">
                                    <BaseCameraCaptureVue ref="cameraCapture" @capturedImages="getImages" />
                                </v-flex>
                                <div class="mt-2">
                                    <v-chip
                                        v-if="!!returnReceipt.uploadFiles.images && returnReceipt.uploadFiles.images.length > 0"
                                        color="#f69f1a"
                                        outlined
                                        close
                                        @click:close="clearCameraCapture()"
                                    >
                                        <span class="mr-2">Images Count: {{ returnReceipt.uploadFiles.images.length }}</span>
                                    </v-chip>
                                </div>
                            </v-layout>
                        </div>

                        <div class="py-2 mt-3">
                            <v-layout row class="mx-0">
                                <v-flex :class="{'mr-2': true, 'lg3': !!returnReceipt.uploadFiles.attachedFiles && returnReceipt.uploadFiles.attachedFiles.attachments.length > 1, 'lg6': !!returnReceipt.uploadFiles.attachedFiles && returnReceipt.uploadFiles.attachedFiles.attachments.length == 1}">
                                    <FileUploadComponentVue ref="fileUpload" @uploadedData="attachedFile" @reset="clearFileAttached"/>
                                </v-flex>
                                <v-flex v-if="!!returnReceipt.uploadFiles.attachedFiles && returnReceipt.uploadFiles.attachedFiles.attachments.length > 0" >
                                    <div class="mt-2">
                                        <v-chip
                                            v-if="!!returnReceipt.uploadFiles.attachedFiles && returnReceipt.uploadFiles.attachedFiles.attachments.length > 0"
                                            color="#f69f1a"
                                            outlined
                                            close
                                            @click:close="clearFileAttached()"
                                        >
                                            <span class="mr-2">Upload Count: {{ returnReceipt.uploadFiles.attachedFiles.attachments.length }}</span>
                                        </v-chip>
                                    </div>
                                </v-flex>
                            </v-layout>
                        </div>
                        <div class="py-2 mt-4">
                            <v-card-actions class="d-flex justify-content-end">
                                <v-btn
                                    style="opacity:1;"
                                    @click="uploadFiles()"
                                >
                                    <span class="ml-2">Submit</span>
                                </v-btn>
                            </v-card-actions>
                        </div>
                    </v-card>
                </v-dialog>
            </v-dialog>
            <v-container fluid style="display:none">
                <table id="return_receipt_header">
                    <tbody>
                        <tr>
                            <td colspan="2">Department:</td>
                            <td colspan="4">{{ 'Spare Parts' }} </td>
                            <td colspan="3">Date:</td>
                            <td colspan="3"> {{ returnReceipt.itemDetails.created_at | formatDateTime }}</td>
                        </tr>
                        <tr>
                            <td colspan="2">Employee Name:</td>
                            <td colspan="4">{{ returnReceipt.itemDetails.processed_by }} </td>
                            <td colspan="3">Requesting Department:</td>
                            <td colspan="3"> {{'Repair Ops' }}</td>
                        </tr>
                        <tr>
                            <td colspan="12">{{ }}</td>
                        </tr>
                    </tbody>
                </table>
            </v-container>
        </v-dialog>
        <PartsOrderDialogComponentVue :rfId="rfId" :rfStatus="rfStatus" @closePartsOrderDialog="closePartsOrderDialog" @successReturn="successReturn" :warehousesCode="warehousesCode"/>
    </div>
</template>

<script>

import PartsOrderDialogComponentVue from './PartsOrderDialogComponent.vue';
import ServiceFunctionsComponentVue from '../../Shared/ServiceFunctionsComponent.vue';
import swal from 'sweetalert2';
import { mapGetters } from 'vuex';
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
import PartsTransferTable from './PartsTransferTableComponent.vue';
import partsTransfersComponent from './PartsTransferComponent.vue';
import FileUploadComponentVue from '@/views/main/Shared/FileUploadComponent.vue';
import BaseCameraCaptureVue from '@/views/main/modules/Services/Base/BaseCameraCapture.vue';
import BaseFilesViewer from '@/views/main/modules/Services/Base/BaseFilesViewer.vue';

export default {
    mixins: [ServiceFunctionsComponentVue,SharedFunctionsComponentVue],
    components: { PartsOrderDialogComponentVue, PartsTransferTable, partsTransfersComponent, FileUploadComponentVue, BaseCameraCaptureVue, BaseFilesViewer},
    props: ['rfStatus'],
    data() {
        return {
            search: '',
            rfs:[],
            readyToProceedHeaders: [
                { text: 'Date Proceeded', value: 'latest_date'},
                { text: 'RF#', value: 'rf_number'},
                { text: 'RD#', value: 'rr_number' },
                { text: 'Company Name', value: 'company_name' },
                { text: 'Customer Name', value: 'first_name' },
                { text: 'Item Model', value: 'model' },
                { text: 'Item Name', value: 'product_name' },
                { text: 'Serial No', value: 'serial' },
                { text: 'Status', value: 'status' },
                // { text: 'Crate#', value: 'crate_number' },
                // { text: 'Loc#', value: 'location_number' },
                // { text: 'Warranty Type', value: 'warranty_type' },
                // { text: 'Technician', value: 'technician_name' },
                { text: 'Days Proceed', value: 'proceed_days' },
                { text: 'Ready To Proceed', value: 'ready_to_proceed',sortable: true,},
                { text: 'Correct Picking', value: 'picking_correction',sortable: true,},
                { text: 'Days', value: 'aging' },
                { text: 'Date', value: 'created_at' },
                { text: 'Action', value: 'action' },
            ],
            readyToServeHeaders: [
                { text: 'RF#', value: 'rf_number' },
                { text: 'RD#', value: 'rr_number' },
                { text: 'Company Name', value: 'company_name' },
                { text: 'Customer Name', value: 'first_name' },
                { text: 'Item Model', value: 'model' },
                { text: 'Item Name', value: 'product_name' },
                { text: 'Serial No', value: 'serial' },
                { text: 'Status', value: 'status' },
                { text: 'Crate#', value: 'crate_number' },
                { text: 'Loc#', value: 'location_number' },
                { text: 'Warranty Type', value: 'warranty_type' },
                { text: 'Technician', value: 'technician_name' },
                { text: 'Ready To Serve', value: 'ready_to_serve' },
                { text: 'Days', value: 'aging' },
                { text: 'Date', value: 'created_at' },
                { text: 'Action', value: 'action' },
            ],
            defaultHeaders: [
                { text: 'RF#', value: 'rf_number' },
                { text: 'RD#', value: 'rr_number' },
                { text: 'Company Name', value: 'company_name' },
                { text: 'Customer Name', value: 'first_name' },
                { text: 'Item Model', value: 'model' },
                { text: 'Item Name', value: 'product_name' },
                { text: 'Serial No', value: 'serial' },
                { text: 'Status', value: 'status' },
                { text: 'Crate#', value: 'crate_number' },
                { text: 'Loc#', value: 'location_number' },
                { text: 'Warranty Type', value: 'warranty_type' },
                { text: 'Technician', value: 'technician_name' },
                { text: 'Days', value: 'aging' },
                { text: 'Date', value: 'created_at' },
                { text: 'Action', value: 'action' },
            ],
            rfLists: [],
            loading: true,
            noDataText: '',
            rf: null,

            customersList: [],
            selectedCustomers: [],
            rfId: '',

            startDateModal: false,
            startDate: '',
            endDateModal: false,
            endDate: '',
            minDate: this.$dayjs().format('YYYY-MM-DD'),

            searchSerial: '',

            repairFormList: [],
            selectedRepairForms: [],

            ready: 0,
            options:{},
            totalItems:0,
            itemsPerPage: 10,
            sort:false,
            tab:0,
            summaryReport: ['Summary(movements)'],
            warehousesCode: [],
            returnReceipt: {
                dialog:false,
                dataTable:{
                    headers:[
                            { text: 'RRF#', value: 'series' },
                            { text: 'RF#', value: 'rf_number' },
                            { text: 'RRF Date', value: 'created_at' },
                            { text: 'Requested By', value: 'created_by' },
                            { text: 'Processed By', value: 'processed_by' },
                            { text: 'Received By', value: 'received_by' },
                            { text: 'Status', value: 'status' },
                            { text: 'Action', value: 'action' },
                        ],
                    items: [],
                    loading: false,
                    options: {},
                    totalItems: 0,
                    noDataText: "No Available Data",
                },
                filters: {
                    search: '',
                    startDate: '',
                    endDate: '',
                    status:'',
                },
                dateModal: {
                    start: false,
                    end: false,
                },
                statuses:{
                    saved: 'badge badge-saved',
                    processing: 'badge badge-approved',
                    completed: 'badge badge-full',
                },
                actionsDialog: {
                    dialog:false,
                },
                action:'',
                itemDetails:{},
                decisions:['For Replacement'],
                selectedDecision:'',
                loading:{
                    update:false,
                },
                uploadFiles: {
                    dialog: false,
                    images:[],
                    attachedFiles:null,
                }
            },
            companyDetails:{},
            userAccess:[],
        }
    },
    mounted: async function() {
        await this.getWarehouses();
        await this.getAllRfs();
        await this.getCustomers();
        this.$store.dispatch('checkAccessUser',this.$router.currentRoute.params.id);
    },
    computed:{
        ...mapGetters([
            'GET_ALL_WFP_RFS',
            'GET_CUSTOMERS_LIST_SELECTION',
            'GET_ALL_READY_RFS',
            'GET_SC_PARTS_ORDER',
            'GET_SERVICE_WAREHOUSES',
            'GET_SYSTEM',
            'USER_ACCESS',
        ]),
        computedRF(){


        return this.rfLists.sort((a,b)=>{
                if(this.sort){
                    return b.ready_to_proceed - a.ready_to_proceed;
                }
                else if(!this.sort) {
                    return a.ready_to_proceed - b.ready_to_proceed;
                }
            })
        },
        warrantyType(){
            if(['PRQ', 'PP', 'PFO', 'CF'].includes(this.rfStatus)){
                return ['In-Warranty', 'Out-Warranty', 'Dealer-Stock', ...this.summaryReport, 'All'];
            } else {
                return ['In-Warranty', 'Out-Warranty', 'Dealer-Stock', 'All']
            }
        }

    },

    watch: {
        rfStatus() {
            this.options.page = 1
            this.options.itemsPerPage = 10
            this.getAllRfs();
            this.ready = 0;
        },
        startDate() {
            if(!!this.startDate) {
                this.endDate = this.minDate;
            } else {
                this.endDate = '';
            }
        },
        ready() {
            this.getAllRfs();
        },
        'options':{
            handler(val){
                if(this.options.itemsPerPage < 0){
                    this.options.itemsPerPage =  this.totalItems;
                }
                if(this.sort){
                    this.sort = false
                }else{
                    this.sort = true
                }
                this.getAllRfs();
            },
            deep:true
        },
        'GET_SYSTEM': {
            handler(val) {
                if(Object.keys(val).length > 0) {
                    switch (val.code) {
                        case 'ING':
                            this.getCompanyDetails(['Service'], 'ING').then( result => {
                                this.companyDetails = result[0];
                            });
                            break;
                            case 'WAD':
                                this.getCompanyDetails(['Service'], 'WAD').then( result => {
                                    this.companyDetails = result[0];
                            });
                            break;
                    }
                }
            }
        },
        USER_ACCESS: {
            handler(val) {
                if (val != 'fail'){
                    this.userAccess = val.map(e => e.actions_code)
                }
            }
        }
    },
    methods: {
        resetFilter() {
            this.selectedCustomers = [];
            this.selectedRepairForms = [];
            this.startDate = '';
            this.endDate = '';
            this.search = '';
            this.searchSerial = '';
            this.ready = 0;
            this.getAllRfs();
        },
        async exportToExcel(item){
            this.loading = true
            let payload = {
                status: [this.rfStatus],
                url:'exportPartsRfs',
                warrantyType: item,
            }
            let exportHeadersAndData = {
                [this.rfStatus]: {
                    headers: [
                        { header: 'RF#', width: 20, key: 'rf_number' },
                        { header: 'Warranty', width: 18, key: 'item_warranty_type' },
                        { header: 'Date Proceeded', width: 18, key: 'latest_date' },
                        { header: 'Parts Model', width: 16, key: 'parts_model' },
                        { header: 'Part Number', width: 16, key: 'part_num' },
                        { header: 'Parts Name', width: 16, key: 'parts_name' },
                        { header: 'Parts Requested Qty', width: 20, key: 'parts_requested_qty' },
                        { header: 'Parts Serve Qty', width: 16, key: 'parts_serve_qty' },
                        { header: 'Parts Returned Qty', width: 18, key: 'parts_returned_qty' },
                        { header: 'Parts FOC', width: 16, key: 'parts_foc' },
                        { header: 'Parts Selling Price', width: 16, key: 'parts_selling_price', style: { numFmt: '#,##0.00' } },
                        { header: 'Parts Total', width: 16, key: 'parts_total', style: { numFmt: '#,##0.00' } },
                        { header: 'Parts Status', width: 16, key: 'parts_status' },
                        { header: 'Parts Date', width: 18, key: 'parts_created_at' },
                        { header: 'RD#', width: 10, key: 'rr_number' },
                        { header: 'Company', width: 16, key: 'company_name' },
                        { header: 'Item Model', width: 16, key: 'item_model' },
                        { header: 'Item Name', width: 16, key: 'item_name' },
                        { header: 'Serial No', width: 16, key: 'item_serial' },
                        { header: 'RF Status', width: 16, key: 'rf_status' },
                        { header: 'PO No', width: 12, key: 'po_num' },
                        { header: 'Estimation No', width: 16, key: 'estimation_num' },
                        { header: 'Crate No', width: 12, key: 'rf_crate_number' },
                        { header: 'Location No', width: 16, key: 'rf_location_number' },
                        { header: 'Technician', width: 16, key: 'technician_name' },
                        { header: 'RF Date', width: 16, key: 'rf_created_at' },
                    ],
                    data: []
                }
            }

            await this.$store.dispatch('servicePost',payload).then(response => {
                let data = response.data;
                data.forEach( e => {
                    e.latest_date = this.formatDate(e.latest_date)
                    e.parts_foc = e.parts_foc = 1 ? 'Yes' : 'No',
                    e.parts_selling_price = e.parts_selling_price ? parseFloat(e.parts_selling_price) : '',
                    e.parts_total = e.parts_total ? parseFloat(e.parts_total) : '',
                    e.parts_created_at = this.formatDate(e.parts_created_at),
                    e.rf_created_at = this.formatDate(e.rf_created_at)
                })
                exportHeadersAndData[this.rfStatus].data = data;
            });
            this.exportExcel(exportHeadersAndData, `Parts ${this.rfStatus}`,'F69F1A')
            this.loading = false;
        },
        tableHeaders() {
            if(this.rfStatus == 'PRQ' || this.rfStatus == 'PP' || this.rfStatus == 'PFO') {
                return this.readyToProceedHeaders;
            } else if(this.rfStatus == 'WFP' || this.rfStatus == 'WFR') {
                return this.readyToServeHeaders;
            }
            return this.defaultHeaders;
        },
        filter() {
            this.getAllRfs();
        },
        async getAllRfs() {
            this.loading = true
            let data = []
            this.rfLists = []
            if(this.rfStatus == "COMPLETE" || this.rfStatus == "INCOMPLETE") {

                await this.$store.dispatch('getAllWfpRfs',this.rfStatus).then(response => {
                    this.data = this.GET_ALL_WFP_RFS;
                });
            } else {
                let payload = {
                    status: this.rfStatus,
                    selectedCustomers: this.selectedCustomers,
                    selectedRepairForms: this.selectedRepairForms,
                    startDate: this.startDate,
                    endDate: this.endDate,
                    search: this.search,
                    searchSerial: this.searchSerial,
                    ready: this.ready,
                    itemsPerPage: this.options.itemsPerPage,
                    page: this.options.page,
                }

                await this.$store.dispatch('getAllReadyRfs',payload).then(async response => {
                    this.totalItems = response.data.data.total
                    this.loading = false
                    this.rfLists = response.data.data.data
                });


            }
        },

        checkIfRfIsReady(){

        },

        showScPartsOrderDialog(rf) {
            this.$store.commit('showScPartsOrderDialog');
            this.rfId = rf.id;
            this.getScPartsOrder(this.rfId);
        },

        cancelPending(item) {
            swal.fire({
                icon: 'question',
                title: 'Cancel',
                text: ' - Are you sure you want to cancel?',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(result => {
                if (result.isConfirmed) {
                    let payload = {
                        id: item.id,
                        rf_number: item.rf_number
                    }
                    this.$store.dispatch('cancelPending',payload).then(response => {
                        this.getAllRfs()
                        swal.fire(response.data)
                    }).catch(e => {
                        swal.fire('Error!',e.response.data.message,'error')
                    })
                }
            })
        },
        refreshEstimationStocks(){
            this.loading = true
            this.$store.dispatch('serviceGet',{
                url:'refresh-estimation-stocks'
            }).then(response=>{
                this.getAllRfs()
                this.loading = false
                swal.fire('Success','','success')
            }).catch(err=>{
                this.loading = false
                swal.fire('Error','','error')
            })
        },
        successReturn(confirm) {
            if(confirm) {
                this.getAllRfs();
            }
        },
        closePartsOrderDialog(confirm) {
            if(confirm) {
                this.rfId = '';
                this.getAllRfs();
            }
        },
        date(item) {
            if(!!item) {
                return this.$dayjs(item.created_at).format('YYYY-MM-DD, hh:mm:ss');
            }
            return '';
        },
        dateProceed(item) {
            if (!!item) {
                return item.latest_date ? this.$dayjs(item.latest_date).format('YYYY-MM-DD, hh:mm:ss') : '';
            }
            return '';
        },
        companyName(item){
            if(!!item) {
                return item.sc_receiving_report.customer.company_name;
            }

            return '';
        },
        customerName(item) {
            if(!!item) {
                if(item.sc_receiving_report.customer_id == 275) {
                    let firstName = item.sc_receiving_report.sc_customer.first_name;
                    let lastName = item.sc_receiving_report.sc_customer.last_name;

                    return `${firstName} ${lastName}`;
                }
            }

            return '';
        },
        onSelectCustomers (items) {
            this.selectedCustomers = items
        },
        async getCustomers(){
            await this.$store.dispatch('getAllCustomers').then(response => {
                this.customersList = this.GET_CUSTOMERS_LIST_SELECTION;
            });
        },
        onSelectRfs(items) {
            this.selectedRepairForms = items
        },
        getScPartsOrder(item)
        {
            let payload = {
                id: item
            }
            this.$store.dispatch('getScPartsOrder', payload);
        },
        async exportPartsStatusesReport(){
            this.loading = true
            let data = null;
            let requested_parts = [];
            let pending_parts = [];
            let completed_parts = [];
            let prqCount = 0;
            let ppCount = 0;
            let pfoCount = 0;
            let cfCount = 0;
            let exportColumns = [
                { header: 'RF#', width: 15, key: 'rf_number' },
                { header: 'Parts Model', width: 20, key: 'parts_model' },
                { header: 'Parts Name', width: 35, key: 'parts_name' },
            ];

            if (this.startDate == '' || this.endDate == ''){
                swal.fire('Oops', '-Indicate the start and end date', 'warning')
                this.loading = false
                return;
            }

            let payload = {
                start_date: this.startDate,
                end_date: this.endDate,
                url:'parts-statuses-summary-report'
            }

            await this.$store.dispatch('serviceGet', payload).then( res => {
                data = res.data;
                data.rfs_with_requested_parts.forEach(e => {
                    requested_parts = [...requested_parts, ...e.parts_order_items]
                });
                data.rfs_with_pending_parts.forEach(e => {
                    pending_parts = [...pending_parts, ...e.parts_order_items]
                });
                data.rfs_with_completed_parts.forEach(e => {
                    completed_parts = [...completed_parts, e]
                });

                prqCount = data.prq_rfs_counts;
                ppCount = data.rfs_with_pending_parts.filter(e => e.rf_status == 'PP').length;
                pfoCount = data.rfs_with_pending_parts.filter(e => e.rf_status == 'PFO').length;
                cfCount = data.completed_rfs;

            }).catch( err => {
                console.log(err);
            })

            let workbook = new this.$exceljs.Workbook()
            let worksheet = workbook.addWorksheet('Summary')

            const docFont = {
                    name: 'Arial',
                    color: { rgb: '000000' },
                    family: 2,
                    size: 10,
                    bold: true
                }
            const textAlignment = {
                horizontal: 'left'
            }


            for (let letter of ['B', 'C']){
                for(let num of [2,3,4,5,6,7,8]){
                    if(letter == 'B'){
                        worksheet.mergeCells(`A${num}:${letter + num}`)
                    } else {
                        worksheet.getCell(`${letter + num}`).alignment = textAlignment
                    }
                    worksheet.getCell(`${letter + num}`).font = docFont
                }
            }

            worksheet.mergeCells('A1:F1')
            worksheet.getColumn('A').width = 30
            worksheet.getCell('A1').value = `Summary Report From ${this.startDate} to ${this.endDate}`
            worksheet.getCell('A1').font = {size:14, bold:true}
            worksheet.getCell('A1').alignment = { horizontal:'center'}
            worksheet.getCell('A1').fill = {type: 'pattern', pattern:'solid', fgColor:{argb:'F69F1A'}}

            worksheet.getCell('B2').value = "Total Number of RFs moved in PRQ:"
            worksheet.getCell('B3').value = "Total Number of RFs moved in PP:"
            worksheet.getCell('B4').value = "Total Number of RFs moved in PFO:"
            worksheet.getCell('B5').value = "Total Number of RFs moved in CF:"
            worksheet.getCell('B6').value = "Total Requested Spare Parts:"
            worksheet.getCell('B7').value = "Total Pending Spare Parts:"
            worksheet.getCell('B8').value = "Total Completed Spare Parts:"

            worksheet.getCell('C2').value = prqCount
            worksheet.getCell('C3').value = ppCount
            worksheet.getCell('C4').value = pfoCount
            worksheet.getCell('C5').value = cfCount
            worksheet.getCell('C6').value = requested_parts.reduce((sum, e) => { return sum +(e.parts_served_qty + e.parts_requested_qty)}, 0)
            worksheet.getCell('C7').value = pending_parts.reduce((sum, e) => { return sum +  e.parts_requested_qty}, 0)
            worksheet.getCell('C8').value = completed_parts.reduce((sum, e) => { return sum + e.quantity }, 0)

            let worksheet2 = workbook.addWorksheet('Requested Parts')
            let worksheet3 = workbook.addWorksheet('Pending Parts')
            let worksheet4 = workbook.addWorksheet('Completed Parts')

            if(data != null) {
                let worksheets = [worksheet2, worksheet3, worksheet4]
                let parts = [requested_parts,pending_parts,completed_parts]
                const worksheetLength = worksheets.length
                for (let ws=0; ws < worksheetLength; ws++){
                    if (ws == 2) {
                        worksheets[ws].columns = [...exportColumns, ...[
                            { header: 'Parts Completed Qty', width: 20, key: 'quantity' },
                            { header: 'Create Number', width: 20, key: 'crate_number' },
                            { header: 'Picker Name', width: 20, key: 'picker_name' },
                            { header: 'Checker Name', width: 20, key: 'checker_name' }
                        ]]
                    } else {
                        worksheets[ws].columns = [...exportColumns, ...[
                            { header: 'Parts Requested Qty', width: 20, key: 'parts_requested_qty' },
                            { header: 'Parts Serve Qty', width: 20, key: 'parts_served_qty' },
                        ]]
                    }
                    parts[ws].forEach(e=>{
                        worksheets[ws].addRow(e)
                    })
                    let headerLength = worksheets[ws].columns.length;
                    let headerRow = worksheets[ws].getRow(1);
                    headerRow.font = { bold:true }
                    for (let c=1;c<=headerLength;c++) {
                        let currentCell = headerRow.getCell(c);
                        currentCell.fill = {
                            type: 'pattern',
                            pattern:'solid',
                            fgColor:{argb:'F69F1A'},
                        }
                    }
                }
                await workbook.xlsx.writeBuffer().then((data) => {
                    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                    this.$filesaver.saveAs(blob, `Parts Summary Report.xlsx`);
                    this.loading = false
                }).catch(e=>{
                    console.log(e)
                    this.loading = false
                });
            } else {
                this.loading = false;
            }
        },
        exportExcels(item){
            if(item == 'Summary(movements)'){
                this.exportPartsStatusesReport();
            } else {
                this.exportToExcel(item);
            }
        },
        getWarehouses() {
            this.$store.dispatch('serviceGetWarehouses').then(response => {
                this.warehousesCode = this.GET_SERVICE_WAREHOUSES.data;
            });
        },
        getAllReturnReceipts() {
            this.returnReceipt.dataTable.loading = true;
            this.returnReceipt.dataTable.items = [];
            let payload = {
                filters: this.returnReceipt.filters,
                options: this.returnReceipt.dataTable.options,
                url: 'sc-return-receipts',
            }
            this.$store.dispatch('serviceGet',payload).then( response => {
                this.returnReceipt.dataTable.items = response.data.data;
                this.returnReceipt.dataTable.totalItems = response.data.total;
                this.returnReceipt.dataTable.loading = false;
            }).catch( e => {
                console.log(e);
                this.returnReceipt.dataTable.loading = false;
            })
        },
        showOrEditReturnReceipt(item, action) {
            this.returnReceipt.action = action;
            this.returnReceipt.actionsDialog.dialog = true;
            this.returnReceipt.itemDetails = item;
            console.log(this.returnReceipt.itemDetails);
        },
        updateReturnReceipt() {
            swal.fire({
                icon: 'question',
                text: ' - Are you sure you want to proceed?',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
            }).then(result => {
                if (result.isConfirmed) {
                    this.returnReceipt.loading.update = true;
                    let payload = {
                        returnReceipt: this.returnReceipt.itemDetails,
                        url:`sc-return-receipts/${this.returnReceipt.itemDetails.id}`
                    }
                    this.$store.dispatch('servicePut', payload).then( response => {
                        swal.fire(response.data);
                        this.returnReceipt.actionsDialog.dialog = false;
                        this.returnReceipt.loading.update = false;
                        this.getAllReturnReceipts();
                    }).catch( e => {
                        console.log(e);
                        this.returnReceipt.loading.update = false;
                    })
                }
            })
        },
        replaceParts() {
            swal.fire({
                icon: 'question',
                text: ' - Are you sure you want to replace the parts?',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
            }).then(result => {
                if (result.isConfirmed) {
                    this.$store.dispatch('servicePost',  {url:`sc-return-receipts/replace-parts/${this.returnReceipt.itemDetails.id}`}).then(response => {
                        swal.fire(response.data);
                        this.returnReceipt.actionsDialog.dialog = false;
                        this.printReturnReceipt();
                        this.getAllReturnReceipts();
                    }).catch(e => {
                        console.log(e)
                    })
                }
            })
        },
        closeReturnReceiptDialog() {
            this.returnReceipt.dialog = false;
            this.returnReceipt.filters = {
                search: '',
                startDate: '',
                endDate: '',
                status:'',
            }
            this.returnReceipt.uploadFiles = {
                    dialog: false,
                    images:[],
                    attachedFiles:null,
            }
        },
        printReturnReceipt() {
            let doc = new this.$jspdf("p", "mm", "letter");
            doc.setFont("courier", 'bold').setFontSize(13).text(8,10,`${this.companyDetails.company_name}`)
            doc.setFont("courier", 'normal').setFontSize(9).text(8,14,`${this.companyDetails.address} `)

            doc.setFont("Gill", "bold");
            var text = "RETURN RECEIPT FORM";
            doc.setFontSize(16);
            // doc.setFillColor(246, 159, 26);
            doc.setFillColor(this.GET_SYSTEM.title_color);
            doc.roundedRect(
                127, // start x of rectangle
                8, // start y of rectangle
                80, // width
                12, // height
                3,
                3,
                "FD" // style
            )
            doc.setTextColor(0, 0, 0);
            doc.text(text, 131, 16);
            doc.setFont('Gill', 'bold').setFontSize(13).text(151,26,"Control No: RRF# - " + this.returnReceipt.itemDetails.series);
            doc.autoTable({
                html: '#return_receipt_header',
                theme: 'grid',
                startY: 29,
                margin: {top: 37, left: 8, right: 8},
                columnStyles: {
                    0: {halign:'left', textColor: '#000000', fontSize: 8},
                    1: {halign:'left', textColor: '#000000', fontSize: 8},
                    2: {halign:'left', textColor: '#000000', fontSize: 8},
                    3: {halign:'left', textColor: '#000000', fontSize: 8},
                },
                styles: {
                    fontSize: 8,
                    lineWidth: 0.1,
                    lineColor: 'black',
                }
            });
            doc.autoTable({
                body: this.returnReceipt.itemDetails.items,
                columns: [
                    { header: "Part Number", dataKey: "model" },
                    { header: "Qty", dataKey: "quantity" },
                    { header: "Justification", dataKey: "justification" },
                    { header: "Decision", dataKey: "decision" },
                ],
                headStyles: {
                    fillColor: this.GET_SYSTEM.theme_color,
                    textColor: "black",
                    textType: "bold",
                },
                margin: {top: 37, left: 8, right: 8},
                startY: doc.lastAutoTable.finalY,
                styles: {
                    fontSize: 8,
                    lineWidth: 0.1,
                    lineColor: 'black',
                    halign: 'center',
                    valign: 'middle'
                },
                // showHead: 'firstPage',
                theme: "grid",
            });

            let lastTableEndPos = doc.lastAutoTable.finalY
            // Submitted By portion
            doc.setDrawColor(0, 0, 0)
            doc.setFont('Gill');
            doc.setFontSize(10);
            doc.rect(8,lastTableEndPos, 98, 7, 'S')
            doc.text(`Submitted by: `, 9, lastTableEndPos + 4, "left");

            // Noted By portion
            doc.rect(8, lastTableEndPos + 7, 98, 14, 'S')
            doc.text('Admin-Repair', 49, lastTableEndPos + 10);
            doc.text('Noted by:', 9, lastTableEndPos + 19);

            doc.rect(8, lastTableEndPos + 21, 98, 5, 'S')
            doc.text('Immidiate Superior', 45, lastTableEndPos + 25);

            doc.rect(106,lastTableEndPos, 102, 13, 'S')
            doc.rect(106,lastTableEndPos + 13, 102, 13, 'S')
            doc.text(`Acknowledge by: `, 107, lastTableEndPos + 17, "left");
            doc.text('Requesting Personnel:', 148, lastTableEndPos + 25);
            doc.setFontSize(12);
            doc.text('Attachments:', 8, lastTableEndPos + 31);
            //Image Container
            let imageContainerHeight = 50
                doc.setFillColor('white')
                let x = 15;
                // let y = 85;
                let y = lastTableEndPos + 38;

                this.returnReceipt.itemDetails.sc_return_receipt_files.forEach((e,i)=>{
                    let supporting_img = new Image();
                    supporting_img = `${process.env.VUE_APP_API_HOST}/img/service/sc_return_receipt?image_name=${e.name}`
                    fetch(supporting_img)
                    .then(resp => resp.blob())
                    .then(blobobject => {
                        const blob = window.URL.createObjectURL(blobobject);
                        const anchor = document.createElement('a');
                        anchor.style.display = 'none';
                        anchor.href = blob;
                        document.body.appendChild(anchor);
                        window.URL.revokeObjectURL(blob);
                    })
                    doc.addImage(supporting_img, 'JPEG', x, y,  35, 35 );
                    x=x+35;
                    if((i + 1) % 5 == 0){
                        imageContainerHeight += 43
                        y=y+35;
                        x=15;
                    }
                })
            doc.rect(8, lastTableEndPos + 33, 200, imageContainerHeight);

            for (let page = 1; page <= doc.internal.getNumberOfPages(); page++) {
                doc.setPage(page);
                doc.setTextColor("black");
                doc.setFontSize(7);
                doc.text(
                `Page ${page} of ${doc.internal.getNumberOfPages()}`,
                191,
                doc.internal.pageSize.height - 7
                );
            }

            doc.autoPrint();
            window.open(doc.output('bloburl'), '_blank');
        },
        getImages(images) {
            this.returnReceipt.uploadFiles.images = images;
        },
        clearCameraCapture(){
            this.$refs.cameraCapture.reset();
            this.images = null;
        },
        attachedFile(data) {
            if(!data) {
                this.returnReceipt.uploadFiles.attachedFiles = null;
            }

            this.returnReceipt.uploadFiles.attachedFiles = data;
        },
        showUploadFileDialog() {
            this.returnReceipt.uploadFiles.dialog = true;
        },
        clearFileAttached() {
            this.returnReceipt.uploadFiles.attachedFiles = null;
            this.$refs.fileUpload.reset();
        },
        closeUploadFileDialog(){
            this.returnReceipt.uploadFiles.dialog = false;
            this.clearCameraCapture()
            this.clearFileAttached(confirm)
        },
        uploadFiles() {
            const data = new FormData();
            // this.images == null && this.uploadedFiles == null
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            if(!!this.returnReceipt.uploadFiles.attachedFiles && this.returnReceipt.uploadFiles.attachedFiles.attachments.length > 0) {
                let files = this.returnReceipt.uploadFiles.attachedFiles.attachments;

                for(let i=0;i < _.compact(files).length;i++) {
                    data.append('files[]', files[i]);
                }
            }

            if(!!this.returnReceipt.uploadFiles.images && this.returnReceipt.uploadFiles.images.length > 0) {
                for(let i=0;i < _.compact(this.returnReceipt.uploadFiles.images).length;i++) {
                    data.append('images[]', _.compact(this.returnReceipt.uploadFiles.images)[i]);
                }
            }
            data.append('rrfId', this.returnReceipt.itemDetails.id);

            if (!this.returnReceipt.uploadFiles.attachedFiles && this.returnReceipt.uploadFiles.images.length == 0) {
                swal.fire("Error" ,'- No file found', 'info')
            } else {
                swal.fire({
                    title: 'Are you sure you want to Capture or Upload Image?',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then(confirm=>{
                    if(confirm.isConfirmed){
                    let payload = {
                        rrfId: this.returnReceipt.itemDetails.id,
                        params: data,
                        config: config,
                    }
                    this.$store.dispatch('uploadScReturnReceipFile', payload).then(response=>{
                        this.closeUploadFileDialog();
                        this.returnReceipt.uploadFiles.dialog = false;
                        this.returnReceipt.actionsDialog.dialog = false;
                        this.getAllReturnReceipts();
                        swal.fire("Success", '', "success");

                    }).catch(err=>{
                        console.log(err)
                        swal.fire('Failed!','','error')
                        this.loading = false
                        })
                    }
                });
            }
        },
        deletedAll() {
            this.getAllReturnReceipts();
        },
        setEndDate() {
            return this.returnReceipt.filters.endDate = this.minDate;
        }
    }
}
</script>

<style>

</style>
