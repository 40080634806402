import Reports from '@/views/main/modules/Reports/ReportsComponent.vue'
import repository from "@/api/credentials";

const routes = [{
    path: "/reports/sales/:id",
    name: "reportssales",
    component: Reports,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Sales Reports',
        type: 'sales'
    },
},
{
    path: "/reports/inventory/:id",
    name: "reportinventory",
    component: Reports,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Inventory Reports',
        type: 'inventory'
    },
},
{
    path: "/reports/accounting/:id",
    name: "reportaccounting",
    component: Reports,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Accounting Reports',
        type: 'accounting'
    }
},{
    path: "/reports/admin/:id",
    name: "reportadmin",
    component: Reports,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Admin Reports',
        type: 'admin'
    }
},
{
    path: "/reports/logistics/:id",
    name: "reportlogistics",
    component: Reports,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Logistics Reports',
        type: 'logistics'
    }
},
{
    path: "/reports/aftersales/:id",
    name: "reportaftersales",
    component: Reports,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Aftersales Reports',
        type: 'aftersales'
    }
},
]

export default routes;
