<template>
    <div class="w-100">
        <v-app id="my_vue_app">
            <v-card>
                <v-card-title>
                    Timekeeping Settings
                </v-card-title>
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-row>
                                <v-col cols="12" sm="5" v-if="userAccess.extract_time_schedule">
                                    <v-menu
                                        v-model="extract_time_schedule_menu"
                                        ref="menu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        :return-value.sync="form.extract_time_schedule"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="form.extract_time_schedule"
                                                label="Extract Time Schedule"
                                                prepend-icon="mdi-clock-time-four-outline"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-time-picker
                                            v-if="extract_time_schedule_menu"
                                            v-model="form.extract_time_schedule"
                                            @click:minute="$refs.menu.save(form.extract_time_schedule)"
                                            full-width
                                            format="24"
                                        ></v-time-picker>
                                    </v-menu>
                                    <!-- <v-text-field
                                        v-model="form.extract_time_schedule"
                                        :loading="!form.extract_time_schedule"
                                        label="Schedule Extraction Time"
                                        outlined
                                        dense
                                    >
                                    </v-text-field> -->
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="12" sm="6">
                            <v-row>
                                <v-col cols="12" sm="5" v-if="userAccess.max_allowable_ot_hours_per_cut_off">
                                    <v-text-field
                                        v-model="form.max_allowable_ot_hours_per_cut_off"
                                        label="Max Allowable OT Hours Per Cut-Off"
                                        type="number"
                                        min="0"
                                        outlined
                                        dense
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="5" v-if="userAccess.max_allowable_ot_hours_per_day">
                                    <v-text-field
                                        v-model="form.max_allowable_ot_hours_per_day"
                                        label="Max Allowable OT Hours Per Day"
                                        type="number"
                                        min="0"
                                        readonly
                                        outlined
                                        dense
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="5" v-if="userAccess.ot_allowed_number_of_days">
                                    <v-text-field
                                        v-model="form.ot_allowed_number_of_days"
                                        label="OT Allowed Number Of Days"
                                        type="number"
                                        min="0"
                                        outlined
                                        dense
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-app>
    </div>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from 'vuex';
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
export default {
    mixins: [SharedFunctionsComponentVue],
    data(){
        return {
            loaded: false,
            totalItems: 0,
            options: {},
            search: "",
            filter:{
                search:""
            },
            active_bread_crumb:'status',
            userAccess: {
                view: false,
                extract_time_schedule: false
            },
            tab: null,
            items:[],
            settings_form:[],
            job_title_selection :[],
            form:{
                extract_time_schedule: '',
                max_allowable_ot_hours_per_cut_off: 0,
                max_allowable_ot_hours_per_day: 0,
                ot_allowed_number_of_days: 0
            },
            extract_time_schedule_menu: false,
            monthsArray: [],
        }
    },
    mounted(){
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        this.$store.dispatch('getSelectionDepartmentRole');
        this.$store.dispatch('getEmployeeJobTitleSelection');
        this.$store.dispatch("getStatus");
        this.getAllHrSettings();
        this.getFilters()
        const months = this.$dayjs.months();

        this.monthsArray = months.map((month, index) => {
            return {
                name: month,
                id: index + 1
            }
        });
        // this.getSettings()
        this.getYearRange('YEAR_RANGE');
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS',
            'GET_NEW_EMPLOYEE_DATA',
            'GET_STATUS_SELECTION',
            'DIALOGS',
            'USERACCOUNT_LOAD',
            'GET_JOB_TITLE_SELECTION',
            'GET_DEPARTMENT_ROLE_LOAD',
            'GET_HR_SETTINGS_NEW_DATA_TRIGGER',
            'GET_HR_SETTINGS',
            'GET_YEAR_RANGE'
        ])
    },
    methods:{
        getAllHrSettings(filter = null){
            this.loaded = false;
            let payload = {
                user_id: this.USERACCOUNT_LOAD.id,
                filter: filter,
                department_id: this.USERACCOUNT_LOAD.department_id,
                type: 'timekeeping'
            };
            this.$store.dispatch("GetHrSettings", payload)
        },
        getFilters(){
            let filters = {
                settings: this.form,
                type: 'timekeeping'
            }
            this.$store.commit('FORM_SETTINGS',filters);
        },
    },
    watch:{
        USER_ACCESS: {
            handler(val) {
                if(val != "fail"){
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                this.userAccess.create = false;
                    val.forEach(e => {
                        if (e.actions_code == 'edit') {
                            this.userAccess.edit = true;
                        } else if (e.actions_code == 'delete') {
                            this.userAccess.delete = true;
                        } else if (e.actions_code == 'view') {
                            this.userAccess.view = true;
                        } else if (e.actions_code == 'create') {
                            this.userAccess.create = true;
                        } else if (e.actions_code == 'extract_time_schedule') {
                            this.userAccess.extract_time_schedule = true;
                        } else if (e.actions_code == 'max_allowable_ot_hours_per_cut_off') {
                            this.userAccess.max_allowable_ot_hours_per_cut_off = true;
                        } else if (e.actions_code == 'max_allowable_ot_hours_per_day') {
                            this.userAccess.max_allowable_ot_hours_per_day = true;
                        } else if (e.actions_code == 'ot_allowed_number_of_days') {
                            this.userAccess.ot_allowed_number_of_days = true;
                        }
                    });
                }
            }
        },
        GET_JOB_TITLE_SELECTION:{
			deep: true,
			handler(val){
				this.job_title_selection = val
			}
		},
        GET_HR_SETTINGS_NEW_DATA_TRIGGER:{
            handler(val){
                if(val){
                    this.$store.commit("HR_SETTINGS_NEW_DATA_TRIGGER", false);
                    this.getAllHrSettings();
                }
            }
        },
        GET_HR_SETTINGS:{
            handler(val){
                this.items = val

                val.forEach(e=>{
                    Object.keys(this.form).filter(el=>{
                        if(el == e.parameter){
                            this.form[el] = e.value
                        }
                    });
                })
            }
        },
        items:{
            handler(val){
                this.getFilters()
            },
            deep:true
        }
    }
}
</script>
<style scoped>
.selected {
    opacity: 100%;
}

.non-selected {
    opacity: 40%;
}
.v-breadcrumbs__item{
    cursor: pointer;
}
</style>
