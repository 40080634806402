<template>
    <v-form ref="form" v-model="valid">
        <v-card v-if="!dialogLoader">
            <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                    <span class="text-h5">
                        {{ ACTION }} Credit Bill 
                        <!-- {{ '# ' + form.transaction_number ?? '' }} {{ classificationText ? '(' + classificationText + ')' : '' }} -->
                    </span>
                <v-row class="m-0">
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                            text
                            icon
                            small
                            color="gray"
                            class="float-right"
                            @click="closeDialog()"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card elevation="0" dense>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-card elevation="0" dense>
                                <v-card-text>
                                    <v-row class="pa-0 ma-0">
                                        <v-col cols="6">
                                            <span>
                                                <strong>
                                                    Details: 
                                                </strong>
                                            </span>
                                        </v-col>
                                        <v-spacer/>
                                        <v-col cols="5">
                                            <span>
                                                <strong>
                                                    Company Details:
                                                </strong>
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <v-row class="pa-0 ma-0">
                                        <v-col cols="2">
                                            <v-autocomplete
                                                v-model="form.bank_id"
                                                :items="bank.banks"
                                                :readonly="isDisabled()"
                                                :rules="required"
                                                item-value="id"
                                                item-text="name"
                                                outlined
                                                dense
                                                @change="bankCards"
                                            >
                                                <template v-slot:label>
                                                    <span>Bank <span style="color: red;">*</span></span>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col cols="2">
                                            <v-autocomplete
                                                v-model="form.bank_card_id"
                                                :items="bank.cards"
                                                :readonly="isDisabled()"
                                                :rules="required"
                                                outlined
                                                dense
                                                @change="banks"
                                            >
                                                <template v-slot:label>
                                                    <span>Cards <span style="color: red;">*</span></span>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col cols="2">
                                            <v-menu
                                                v-model="menu.bill_due"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                max-width="290"
                                                :disabled="isDisabled()"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        v-model="form.bill_due"
                                                        v-on="on"
                                                        :readonly="isDisabled()"
                                                        :rules="required"
                                                        append-icon="mdi-calendar"
                                                        readonly
                                                        outlined
                                                        dense
                                                    >
                                                        <template v-slot:label>
                                                            <span>Bill Due <span style="color: red;">*</span></span>
                                                        </template>
                                                    </v-text-field>
                                                </template>
                                                    <v-date-picker v-model="form.bill_due"  @input="menu.bill_due = false"/>
                                            </v-menu>
                                        </v-col>
                                        <v-spacer/>
                                        <v-col cols="5">
                                            <v-autocomplete
                                                v-model="form.vendor_id"
                                                :items="GET_ADMIN_SUPPLIER_SELECTION"
                                                :readonly="isDisabled()"
                                                :rules="required"
                                                label="Select Vendor"
                                                auto-select-first
                                                outlined
                                                dense
                                            >
                                                <template v-slot:label>
                                                    <span>Select Vendor <span style="color: red;">*</span></span>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                    <v-row class="pa-0 ma-0">
                                        <v-col cols="6">
                                            <v-textarea
                                                v-model="form.remarks"
                                                :readonly="isDisabled()"
                                                :rules="required"
                                                rows="2"
                                                auto-grow
                                                outlined
                                                dense
                                            >
                                                <template v-slot:label>
                                                    <span>Remarks <span style="color: red;">*</span></span>
                                                </template>
                                            </v-textarea>
                                        </v-col>
                                        <v-spacer/>
                                        <v-col cols="3">
                                            <v-autocomplete
                                                v-model="form.company_id"
                                                :readonly="isDisabled()"
                                                :items="getAll.companies"
                                                :rules="required"
                                                label="Select Company"
                                                no-data-text="No Companies Found!"
                                                auto-select-first
                                                outlined
                                                dense
                                                @change="storesSelect"
                                            >
                                                <template v-slot:label>
                                                    <span>Select Company <span style="color: red;">*</span></span>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col cols="2">
                                            <v-autocomplete
                                                v-model="form.store_branch_id"
                                                :readonly="isDisabled()"
                                                :rules="required"
                                                :items="getAll.branches"
                                                label="Select Branch"
                                                no-data-text="No Branches Found!"
                                                auto-select-first
                                                outlined
                                                dense
                                                @change="companies"
                                            >
                                                <template v-slot:label>
                                                    <span>Select Branch <span style="color: red;">*</span></span>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                    <v-row class="pa-0 ma-0">
                                        <span>
                                            <strong>
                                                Transaction/s:
                                            </strong>
                                        </span>
                                    </v-row>
                                    <v-row class="pa-0 ma-0">
                                        <v-spacer/>
                                        <v-col cols="1">
                                            <v-autocomplete
                                                v-model="fillDepartmentField"
                                                v-show="autoFill.length > 0"
                                                :items="GET_DEPARTMENT_ACCOUNTING_LOAD"
                                                placeholder="Department"
                                                class="small-font"
                                                outlined
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="1">
                                            <v-autocomplete
                                                v-model="fillJobField"
                                                v-show="autoFill.length > 0"
                                                :items="GET_ACCOUNTING_JOB_SELECTION"
                                                placeholder="Job"
                                                class="small-font"
                                                item-text="text"
                                                item-value="value"
                                                outlined
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="1" class="d-flex justify-center align-center">
                                            <v-btn small v-show="autoFill.length > 0" @click="massUpdateFields()">
                                                Mass Update
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="1" class="text-right" v-show="!isDisabled()">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" :disabled="fillDetailsFirst" fab dark small color="success" style="height: 20px; width: 20px;" @click="addParticular()">
                                                        <v-icon dark small style="font-size: 12px;">
                                                            mdi-plus
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                    <span>Add</span>
                                            </v-tooltip>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" :disabled="fillDetailsFirst" fab dark small color="error" class="ml-1" style="height: 20px; width: 20px;" @click="removeParticular()">
                                                        <v-icon dark small style="font-size: 12px;">
                                                            mdi-minus
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                    <span>Delete</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                    <v-row class="pa-0 ma-0">
                                        <v-col cols="12">
                                            <!-- <v-card style="overflow:auto; max-height: 250px;" class="pa-4 mb-4">
                                                <v-data-table
                                                    v-model="form.selected_payments"
                                                    :headers="payments.headers"
                                                    :items="payments.items"
                                                    :loading="payments.loading"
                                                    :show-select="ACTION == 'Submit'"
                                                    no-data-text="No transactions yet"
                                                    item-key="vendor_payment_detail_id"
                                                    disable-sort
                                                >
                                                    <template v-slot:[`item.created_at`]="{ item }">
                                                        <span class="text-nowrap">
                                                            {{ item.created_at | formatDate }}
                                                        </span>
                                                    </template>
                                                    <template v-slot:[`item.status`]="{ item }">
                                                        <span class="badge badge-purple" v-if="item.status==5">bounce</span>
                                                        <span class="badge badge-dark" v-else-if="item.status==3">rejected</span>
                                                        <span class="badge badge-danger" v-else-if="item.status==6">cancelled</span>
                                                        <span class="badge badge-primary" v-else-if="item.status==7">confirmed</span>
                                                        <span class="badge badge-warning" v-else-if="item.status==8">exhausted</span>
                                                        <span class="badge badge-success" v-else-if="item.status==9">allocated</span>
                                                        <span class="badge badge-warning" v-else-if="item.status==10">partial</span>
                                                        <span class="badge badge-info" v-else-if="item.status==11">replaced</span>
                                                    </template>
                                                    <template v-slot:[`item.actions`]="{ item }">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn v-on="on" text x-small icon color="orange" @click="viewPayment(item.id)">
                                                                    <v-icon>mdi-eye</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>View</span>
                                                        </v-tooltip>
                                                    </template>
                                                </v-data-table>
                                            </v-card>  -->
                                            <v-simple-table fixed-header dense class="overflow-y-auto" style="border: 1px solid #e0e0e0; max-height: 283px;">
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th width="3%" class="text-left" v-if="ACTION != 'View'">
                                                                <v-row class="d-flex justify-space-between align-content-center pt-2">
                                                                    <v-tooltip top>
                                                                        <template v-slot:activator="{on,attrs}">
                                                                            <div v-bind="attrs" v-on="on">
                                                                                <v-checkbox
                                                                                    v-model="selectAllCheckbox"
                                                                                    :disabled="fillDetailsFirst"
                                                                                    style="transform: translateX(16px);"
                                                                                    :true-value="1"
                                                                                    :false-value="0"
                                                                                    @change="selectAll(selectAllCheckbox)"
                                                                                ></v-checkbox>
                                                                            </div>
                                                                        </template>
                                                                            <span>Select All</span>
                                                                    </v-tooltip>
                                                                </v-row>
                                                            </th>
                                                            <!-- <th class="text-left" width="15%">CREDIT PAYMENTS <span style="color: red;">*</span></th> -->
                                                            <th class="text-left" width="15%">
                                                                <v-row class="d-flex justify-space-between pt-2">
                                                                    <div class="pl-4">
                                                                        CREDIT PAYMENTS
                                                                    </div>
                                                                    <v-tooltip top v-if="ACTION != 'View'">
                                                                        <template v-slot:activator="{on,attrs}">
                                                                            <div v-bind="attrs" v-on="on">
                                                                                <v-icon color="success" class="pr-6" @click="getVendorPayments()">mdi-refresh</v-icon>
                                                                            </div>
                                                                        </template>
                                                                            <span>Find Transactions</span>
                                                                    </v-tooltip>
                                                                </v-row>
                                                            </th>
                                                            <th class="text-left" width="23%">PARTICULARS <span style="color: red;">*</span></th>
                                                            <th class="text-right" width="10%">AMOUNT <span style="color: red;">*</span></th>
                                                            <th class="text-left" width="8%">RECOGNITION MONTH <span style="color: red;">*</span></th>
                                                            <th class="text-left" width="15%">
                                                                <v-row class="d-flex justify-space-between pt-2">
                                                                    <div class="pl-4">
                                                                        DEPARTMENT
                                                                    </div>
                                                                    <v-tooltip top v-if="ACTION != 'View'">
                                                                        <template v-slot:activator="{on,attrs}">
                                                                            <div v-bind="attrs" v-on="on">
                                                                                <v-icon class="pr-6" @click="clearDropdown('department_id')">mdi-close</v-icon>
                                                                            </div>
                                                                        </template>
                                                                            <span>Clear Departments</span>
                                                                    </v-tooltip>
                                                                </v-row>
                                                            </th>
                                                            <th class="text-left" width="20%">
                                                                <v-row class="d-flex justify-space-between pt-2">
                                                                    <div class="pl-4">
                                                                        JOB
                                                                    </div>
                                                                    <v-tooltip top v-if="ACTION != 'View'">
                                                                        <template v-slot:activator="{on,attrs}">
                                                                            <div v-bind="attrs" v-on="on">
                                                                                <v-icon class="pr-6" @click="clearDropdown('job_id')">mdi-close</v-icon>
                                                                            </div>
                                                                        </template>
                                                                            <span>Clear Jobs</span>
                                                                    </v-tooltip>
                                                                </v-row>
                                                            </th>
                                                            <th class="text-center" width="3%"/>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="particulars-container">
                                                        <tr v-for="(item, index) in form.credit_bill_items" :key="item.id"
                                                            class="font-weight-medium text-gray py-2 particulars-container-row">
                                                            <td v-if="ACTION != 'View'">
                                                                <v-row class="d-flex justify-center align-center">
                                                                    <v-checkbox
                                                                        v-model="item.autoFill"
                                                                        :disabled="fillDetailsFirst"
                                                                        :true-value="1"
                                                                        :false-value="0"
                                                                        class="p-0 ma-0 pt-4"
                                                                        @change="handleCheckboxChange(index, item.autoFill)"
                                                                    ></v-checkbox>
                                                                </v-row>
                                                            </td>
                                                            <td>
                                                                <v-autocomplete
                                                                    v-model="item.vendor_payment_detail_id"
                                                                    :items="payments.items"
                                                                    :readonly="isDisabled()"
                                                                    :disabled="fillDetailsFirst"
                                                                    :loading="payments.items.length == 0"
                                                                    :rules="required"
                                                                    id="coa_id"
                                                                    class="my-1 text-left small-font"
                                                                    item-value="vendor_payment_detail_id"
                                                                    item-text="reference_number"
                                                                    auto-select-first
                                                                    outlined
                                                                    dense
                                                                    @input="insertVPDAmount(item.vendor_payment_detail_id, index)"
                                                                ></v-autocomplete>
                                                            </td>
                                                            <td>
                                                                <v-textarea
                                                                    v-model="item.particulars"
                                                                    :readonly="isDisabled()"
                                                                    :disabled="fillDetailsFirst"
                                                                    :rules="required"
                                                                    id="particular"
                                                                    class="small-font"
                                                                    @paste="(event) => onPaste(event, index, 'particulars')"
                                                                    rows="1"
                                                                    auto-grow
                                                                    outlined
                                                                    dense
                                                                    @keydown="newLine($event, false, false, index, 'particular', addParticular)"
                                                                ></v-textarea>
                                                            </td>
                                                            <td>
                                                                <v-text-field
                                                                    v-model="item.amount"
                                                                    :readonly="isDisabled()"
                                                                    :rules="required"
                                                                    :disabled="!isDisabled()"
                                                                    id="amount"
                                                                    type="number"
                                                                    placeholder="0.00"
                                                                    class="right-align-text small-font"
                                                                    dense
                                                                    outlined
                                                                    hide-spin-buttons
                                                                    @keydown.native="newLine($event, false, false, index, 'amount', addParticular, false)"
                                                                    @paste="(event) => onPaste(event, index, 'amount')"
                                                                    @input="reComputeTotal()"
                                                                >
                                                                    <template v-slot:prepend-inner>
                                                                        <v-icon small>mdi-currency-php</v-icon>
                                                                    </template>
                                                                </v-text-field>
                                                            </td>
                                                            <td>
                                                                <v-menu
                                                                    v-model="item.menu"
                                                                    :close-on-content-click="false"
                                                                    :disabled="isDisabled() || fillDetailsFirst"
                                                                    transition="scale-transition"
                                                                    max-width="290"
                                                                    nudge-bottom="35"
                                                                >
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-text-field
                                                                            v-model="item.recognition_month"
                                                                            v-on="on"
                                                                            :readonly="isDisabled()"
                                                                            :disabled="fillDetailsFirst"
                                                                            :rules="required"
                                                                            id="recognition_month"
                                                                            class="small-font"
                                                                            append-icon="mdi-calendar"
                                                                            readonly
                                                                            outlined
                                                                            dense
                                                                            clearable
                                                                            :clearable="ACTION != 'View'"
                                                                            @paste="(event) => validateRecognitionMonth(event, form.credit_bill_items, index)"
                                                                            @keydown="newLine($event, false, false, index, 'recognition_month', addParticular)"
                                                                        ></v-text-field>
                                                                    </template>
                                                                        <v-date-picker v-model="item.recognition_month"  @input="item.menu = false" type="month"></v-date-picker>
                                                                </v-menu>
                                                            </td>
                                                            <td>
                                                                <v-autocomplete
                                                                    v-model="item.department_id"
                                                                    :items="GET_DEPARTMENT_ACCOUNTING_LOAD"
                                                                    :readonly="isDisabled()"
                                                                    :disabled="fillDetailsFirst"
                                                                    :rules="required"
                                                                    class="small-font"
                                                                    outlined
                                                                    dense
                                                                    @keydown="newLine($event, true, true, index, 'coa_id', addParticular)"
                                                                />
                                                            </td>
                                                            <td>
                                                                <v-combobox
                                                                    v-model="item.job_id"
                                                                    :items="GET_ACCOUNTING_JOB_SELECTION"
                                                                    :readonly="isDisabled()"
                                                                    :disabled="fillDetailsFirst"
                                                                    :rules="required"
                                                                    class="my-1 text-left small-font"
                                                                    id="job_id"
                                                                    outlined
                                                                    dense
                                                                    @keydown="newLine($event, true, true, index, 'coa_id', addParticular)"
                                                                />
                                                            </td>
                                                            <td class="text-center" v-show="!isDisabled()">
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-btn v-on="on" :disabled="fillDetailsFirst" fab dark x-small color="error" style="height: 20px; width: 20px;" @click="removeItem(index)">
                                                                            <v-icon small>
                                                                                mdi-minus
                                                                            </v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                        <span>Remove</span>
                                                                </v-tooltip>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td v-if="!isDisabled()"></td >
                                                            <td class="d-flex align-items-center" colspan="2">
                                                                <span><b style="font-size:15px">Total:</b></span>
                                                                <v-spacer/>
                                                                <span><b>{{ form.total_amount | currency }}</b></span></td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-card-actions class="text-center d-flex border-top justify-content-center">
                <v-col v-if="USER_ACCESS.some(e=>e.actions_code == 'approve') && form.status == 2" class="text-center">
                    <v-btn
                        :loading="btnLoad"
                        class="float-center"
                        color="info"
                        @click="updateStatus(false)"
                    >
                        Revise
                    </v-btn>
                </v-col>
                <v-col cols="text-center">
                    <v-btn
                        v-if="ACTION != 'View'"
                        :disabled="!validToProceed"
                        :loading="btnLoad"
                        class="float-center"
                        @click="submitOrUpdate()"
                    >
                        {{ ACTION }}
                    </v-btn>
                    <v-btn
                        v-else-if="form.status == 1 && form.prepared_by == USERACCOUNT_LOAD.id"
                        :loading="btnLoad"
                        class="float-center"
                        @click="updateStatus(true)"
                    >
                        Submit For Approval
                    </v-btn>
                    <v-btn
                        v-else-if="USER_ACCESS.some(e=>e.actions_code == 'approve') && form.status == 2"
                        :loading="btnLoad"
                        class="float-center"
                        @click="updateStatus(true)"
                    >
                        Approve
                    </v-btn>
                </v-col>
            </v-card-actions>
        </v-card>
        <v-card v-else class="ma-0 pa-2">
            <v-card-text class="text-center">
                <v-progress-circular
                    :size="80"
                    :width="7"
                    color="primary"
                    indeterminate
                ></v-progress-circular>
            </v-card-text>
        </v-card>
    </v-form>
</template>
<script>
    import swal from 'sweetalert2'
    import { mapGetters } from 'vuex'
    import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue'
    export default {
        mixins: [
            SharedFunctionsComponent
        ],
        data() {
            return {
                valid: false,
                form: {
                    id: null,
                    transaction_number: null,
                    bank_id: null,
                    bank_card_id: null,
                    vendor_id: null, 
                    company_id: null, 
                    store_branch_id: null, 
                    remarks: '',
                    bill_due: this.$dayjs().format('YYYY-MM-DD'),
                    total_amount: 0,
                    prepared_by: null,
                    approved_by: null,
                    status: 1,
                    credit_bill_items: [],
                    credit_bill_item_trash: [],
                },
                dialogLoader: false,
                dispatchAction: '',
                required: [ v => !!v || 'This field is required' ],
                btnLoad: false,
                payments: {
                    // headers: [
                    //     { text: 'Ref #', value: 'reference_number' },
                    //     { text: 'Card Name', value: 'card_name' },
                    //     { text: 'Card Number', value: 'card_number' },
                    //     { text: 'Payment Date', value: 'payment_date', align: 'center' },
                    //     { text: 'Total Amount', value: 'total_amount', align: 'end' },
                    //     { text: 'Created At', value: 'created_at', align: 'center' },
                    //     // { text: 'Status', value: 'status', align: 'center' },
                    //     // { text: 'Actions', value: 'actions', align: 'center'},
                    // ],
                    items: [],
                    loading: false,
                },
                getAll: {
                    companies: [],
                    branches: [],
                },
                bank: {
                    banks: [],
                    cards: [],
                },
                menu: {
                    bill_due: false
                },
                selectAllCheckbox: null,
                autoFill: [],
                fillCoaField: null,
                fillDepartmentField: null,
                fillJobField: null,
            }
        },
        computed: {
            ...mapGetters([
                'ACTION',
                'DIALOGS',
                'USER_ACCESS',
                'GET_CREDIT_BILL_DISPATCH',
                'GET_EDIT_CREDIT_BILL',
                'GET_BANK_SELECTION',
                'GET_BANK_CARD_SELECTION',
                'GET_TRANSACT_TYPES_SELECTION',
                'GET_PV_COMPANIES_SELECTION',
                'GET_PV_STORE_BRANCHES_SELECTION',
                'USERACCOUNT_LOAD',
                'GET_DEPARTMENT_ACCOUNTING_LOAD',
                'GET_ACCOUNTING_JOB_SELECTION',
                'GET_ADMIN_SUPPLIER_SELECTION'
            ]),
            validToProceed(){
                return this.valid
            },
            classificationText() {
                let classificationId = this.GET_PV_STORE_BRANCHES_SELECTION.find(e => e.value == this.form.store_branches_id)?.company_classification_id
                    ?? this.GET_PV_COMPANIES_SELECTION.find(e => e.value == this.form.company_id)?.company_classification_id;
                return classificationId
                    ? this.GET_COMPANY_CLASSIFICATION_SELECTION.find(el => el.value == classificationId)?.text ?? ''
                    : '';
            },
            fillDetailsFirst() {
                if (
                    !this.form.bank_id ||
                    !this.form.bank_card_id ||
                    !this.form.bill_due ||
                    !this.form.vendor_id ||
                    !this.form.company_id ||
                    !this.form.store_branch_id ||
                    !this.form.remarks
                ) {
                    return true
                } else {
                    return false
                }
            }
        },
        mounted() {

        },
        methods: {
            closeDialog() {
                this.$refs.form.resetValidation()
                this.$store.commit('ACTION', '')
                if (this.ACTION == '') {
                    this.dialogLoader = true
                    this.$store.commit('DIALOG', false)
                    this.$store.commit('EDIT_CREDIT_BILL', {})
                    this.$store.commit('NEW_CREDIT_BILL', true)
                    this.resetFields()
                }
            },
            isDisabled() {
                switch(this.ACTION) {
                    case 'View':
                        return true
                }
            },
            resetFields() {
                Object.assign(this.$data, this.$options.data.call(this))
            },
            showItem() {
                Object.assign(this.form, this.GET_EDIT_CREDIT_BILL)
                this.form.credit_bill_items.forEach(e=>{
                    e.amount = parseFloat(e.amount).toFixed(2)
                    e.recognition_month = e.recognition_month != null ? this.$dayjs(e.recognition_month).format('YYYY-MM') : this.$dayjs().format('YYYY-MM')
                    e.job_id = this.GET_ACCOUNTING_JOB_SELECTION.find(element => element.value == e.job_id) != null ?
                        this.GET_ACCOUNTING_JOB_SELECTION.find(element=> element.value == e.job_id) :
                        e.job_id;
                })
                this.dialogLoader = false
            },
            reComputeTotal() {
                let computedTotalAmount = this.form.credit_bill_items.reduce((total, item) => {
                    return total + parseFloat(item.amount || 0)
                }, 0)
                this.form.total_amount = computedTotalAmount
            },
            submitOrUpdate() {
                this.btnLoad = true
                swal.fire({
                    title: '',
                    text: `- Are you sure you want to ${this.ACTION} Credit Bill ?`,
                    icon: 'warning',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    allowOutsideClick: false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor: 'grey',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.dialogLoader = true
                        let payload = {
                            id: this.form.id ?? null,
                            form: this.form,
                            url: this.GET_CREDIT_BILL_DISPATCH.url
                        }
                        this.$store.dispatch(this.dispatchAction, payload).then(response => {
                            swal.fire('', response.data.message, response.data.status)
                            this.btnLoad = false
                            this.dialogLoader = false
                            this.closeDialog()
                        }).catch(error => {
                            swal.fire('', error.response.data.message, error.response.data.status)
                            this.btnLoad = false
                            this.dialogLoader = false
                            this.closeDialog()
                        })
                    } else {
                        this.btnLoad = false
                        this.dialogLoader = false
                    }
                })
            },
            getBankData() {
                this.bank.cards = this.GET_BANK_CARD_SELECTION
                this.bank.banks = this.GET_BANK_SELECTION
            },
            getCompanyData() {
                this.getAll.companies = this.GET_PV_COMPANIES_SELECTION
                this.getAll.branches = this.GET_PV_STORE_BRANCHES_SELECTION
            },
            banks(val) {
                if (val) {
                    const query = this.$queryBuilder
                    let banks = this.GET_BANK_SELECTION
                    let bank_cards = this.GET_BANK_CARD_SELECTION
                    let find = query.table(banks).where('id', val).first()
                    if (find) {
                        this.bank.banks = query.table(banks).where('id', find.id).get()
                        this.form.bank_id = query.table(banks).where('id', find.id).first('id')
                        this.bankCards(this.form.bank_id)
                    } else {
                        this.form.bank_id = null
                        this.bank.banks = query.table(banks).get()
                        this.bank.cards = query.table(bank_cards).get()
                    }
                }
            },
            bankCards(val) {
                const query = this.$queryBuilder
                let banks = this.GET_BANK_SELECTION
                let bank_cards = this.GET_BANK_CARD_SELECTION
                if (val) {
                    let find = query.table(bank_cards).where('bank_id', val).first()
                    if (find) {
                        this.bank.cards = query.table(bank_cards).where('bank_id', val).get()
                        this.bank.banks = query.table(banks).where('id', val).get()
                        this.form.bank_card_id = query.table(bank_cards).where('bank_id', val).first('value')
                    }
                } else {
                    this.form.bank_card_id = null
                    this.bank.banks = query.table(banks).get()
                    this.bank.cards = query.table(bank_cards).get()
                }
            },
            getVendorPayments(){
                this.payments.items = []
                // this.payments.loading = true
                let payload = {
                    bank_id: this.form.bank_id,
                    bank_card_id: this.form.bank_card_id,
                    vendor_id: this.form.vendor_id,
                    company_id: this.form.company_id,
                    store_branch_id: this.form.store_branch_id,
                    action: this.ACTION,
                    url: 'getVendorCardPayments'
                }
                this.$store.dispatch('getVendorCardPayments', payload).then(response => {
                    this.payments.items = response.data
                    // this.payments.loading = false
                })
            },
            updateStatus(proceed) {
                this.btnLoad = true
                let new_status = this.form.status
                swal.fire({
                    title: '',
                    text: `- Are you sure you want to Update Credit Bill ?`,
                    icon: 'warning',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    allowOutsideClick: false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor: 'grey',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.dialogLoader = true
                        if (new_status == 1) {
                            this.form.status = 2
                        } else if (new_status == 2 && proceed) {
                            this.form.status = 3
                            await swal.fire({
                                title: '',
                                text: `- Do you also want to create a Enter Bill for this Credit Bill?`,
                                icon: 'question',
                                showConfirmButton: true,
                                showCancelButton: true,
                                reverseButtons: true,
                                allowOutsideClick: false,
                                confirmButtonColor: '#397373',
                                cancelButtonColor: 'grey',
                                confirmButtonText: 'Yes',
                                cancelButtonText: 'No'
                            }).then((result)=>{
                                if (result.isConfirmed) {
                                    this.createEnterBill(
                                        this.form.transaction_number,
                                        this.form.id,
                                        this.GET_EDIT_CREDIT_BILL.company_id,
                                        this.GET_EDIT_CREDIT_BILL.store_branch_id,
                                        this.GET_TRANSACT_TYPES_SELECTION.find(e=>e.model_name == 'CreditBill').value,
                                        this.GET_EDIT_CREDIT_BILL.vendor_id,
                                        this.GET_EDIT_CREDIT_BILL.bill_due,
                                        parseFloat(this.form.total_amount),
                                        [ { id: this.form.id, amount: this.form.total_amount } ]
                                    )
                                }
                            })
                        } else {
                            this.form.status = 1
                        }
                        let payload = {
                            id: this.form.id ?? null,
                            form: this.form,
                            url: this.GET_CREDIT_BILL_DISPATCH.url
                        }
                        this.$store.dispatch(this.dispatchAction, payload).then(response => {
                            swal.fire('', response.data.message, response.data.status)
                            this.btnLoad = false
                            this.dialogLoader = false
                            this.closeDialog()
                        }).catch(error => {
                            swal.fire('', error.response.data.message, error.response.data.status)
                            this.btnLoad = false
                            this.dialogLoader = false
                            this.closeDialog()
                        })
                    } else {
                        this.btnLoad = false
                        this.dialogLoader = false
                    }
                })
            },
            selectAll(status){
                if (status) {
                    this.form.credit_bill_items.map((e,i) => {
                        this.handleCheckboxChange(i, status)
                    })
                } else {
                    this.form.credit_bill_items.map((e,i) => {
                        e.autoFill = 0
                    })
                    this.autoFill = []
                }
            },
            handleCheckboxChange(selected_index,status){
                if (status) {
                    if (!this.autoFill.includes(selected_index)) {
                        this.autoFill.push(selected_index)
                        this.form.credit_bill_items.map((e,i) => {
                            if (this.autoFill.includes(i)) {
                                e.autoFill = 1
                            }
                        })
                    }
                } else {
                    let index = this.autoFill.indexOf(selected_index)
                    if (index > -1) this.autoFill.splice(index, 1)
                }
            },
            onPaste(evt, index, paste){
                evt.preventDefault();
                let pastedText = evt.clipboardData.getData("Text");
                if (pastedText.includes("\n")) {
                    this.result = [] // para sa tuloy tuloy na pag paste ng hindi nabubura ang laman -> for future purposes
                    let rows = pastedText.split("\r\n");
                    for (let y = 0; y < rows.length; y++) {
                        let cells = rows[y].split("\t");
                        if (cells[0] !== '') {
                            this.result.push({
                                autoFill: 0,
                                vendor_payment_detail_id: null,
                                particulars: cells[0],
                                amount: cells[1].replace("," , ""),
                                recognition_month: this.$dayjs().format('YYYY-MM'),
                                department_id: null,
                                job_id: null,
                            });
                        }
                    }
                    this.form.credit_bill_items = this.result;
                } else {
                    this.form.credit_bill_items[index][paste] = pastedText;
                    return
                }
            },
            clearDropdown(field){
                this.form.credit_bill_items.forEach(e => {
                    e[field] = null
                })
                this.$forceUpdate()
            },
            addParticular(){
                this.form.credit_bill_items.push({
                    autoFill: 0,
                    vendor_payment_detail_id: null,
                    particulars: null,
                    amount: null,
                    recognition_month: this.$dayjs().format('YYYY-MM'),
                    department_id: null,
                    job_id: null,
                })
            },
            removeParticular(){
            if (this.form.credit_bill_items.length > 1) this.form.credit_bill_items.pop()
                this.reComputeTotal()
            },
            removeItem(index){
                if (this.form.credit_bill_items.length != 1) {
                    this.form.credit_bill_item_trash.push(this.form.credit_bill_items[index])
                    this.form.credit_bill_items.splice(index, 1)
                }
                this.reComputeTotal()
            },
            massUpdateFields(){
                if (this.fillCoaField) {
                    this.form.credit_bill_items.map((e,i) => {
                        if (this.autoFill.includes(i)) {
                            if(!e.department_id){
                                e.department_id = this.fillDepartmentField
                            }
                            if(!e.job_id){
                                e.job_id = this.fillJobField
                            }
                        }
                    })
                }
            },
            companies(val){
                if(val){
                    const query = this.$queryBuilder
                    let store = this.GET_PV_STORE_BRANCHES_SELECTION
                    let company = this.GET_PV_COMPANIES_SELECTION
                    let find = query.table(store).where('value',val).first();
                    if(find){
                        this.getAll.companies = query.table(company).where('value',find.company_id).get();
                        this.form.company_id = query.table(company).where('value',find.company_id).first('value')
                        if (!this.form.store_branch_id) this.storesSelect(this.form.company_id)
                    }else{
                        this.form.company_id = null
                        this.getAll.companies = query.table(company).get();
                        this.getAll.branches = query.table(store).get();
                    }
                }
            },
            storesSelect(val){
                const query = this.$queryBuilder
                let store = this.GET_PV_STORE_BRANCHES_SELECTION
                let company = this.GET_PV_COMPANIES_SELECTION
                if(val){
                    let find = query.table(company).where('value',val).first();
                    if(find){
                        this.getAll.branches = query.table(store).where('company_id',find.value).get();
                        this.getAll.companies = query.table(company).where('value',find.value).get();
                        this.form.store_branch_id = query.table(store).where('company_id',find.value).first('value')
                    }
                }else{
                    this.form.store_branch_id = null
                    this.getAll.companies = query.table(company).get();
                    this.getAll.branches = query.table(store).get();
                }
            },
            insertVPDAmount(vpd_id, index) {
                let vpd = this.payments.items.find(e=>e.vendor_payment_detail_id == vpd_id)
                this.form.credit_bill_items[index].amount = vpd.total_amount
                this.reComputeTotal()
            },
            validateFields() {
                if (
                    this.form.bank_id &&
                    this.form.bank_card_id &&
                    this.form.bill_due &&
                    this.form.vendor_id &&
                    this.form.company_id &&
                    this.form.store_branch_id &&
                    this.form.remarks
                ) {
                    this.getVendorPayments()
                }
            } 
        },
        watch: {
            DIALOGS: {
                handler(val) {
                    if (val) {
                        this.getBankData()
                        this.getCompanyData()
                        if (this.form.credit_bill_items.length == 0) this.addParticular()
                        if (this.ACTION == 'Submit') {
                            this.dialogLoader = false
                            this.dispatchAction = this.GET_CREDIT_BILL_DISPATCH.store
                        } else if ((this.ACTION == 'View' || this.ACTION == 'Update')  && [1,2].includes(this.form.status)) {
                            this.dialogLoader = true
                            this.dispatchAction = this.GET_CREDIT_BILL_DISPATCH.update
                            this.showItem()
                            this.getVendorPayments()
                            return this.form.status == 1 ? 'Submit for Approval' : 'Approve'
                        } else {
                            this.dialogLoader = true
                            this.showItem()
                            this.getVendorPayments()
                        }
                    }
                },
                deep: true,
                immediate: true
            },
        }
    }
</script>
<style scoped>
.small-font {
    font-size: 14px;
}
::v-deep .right-align-text input {
    text-align: right;
}
</style>