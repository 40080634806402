<template>
  <div class="tabs-body-color">
        <v-card class="m-10 px-3">
            <v-card-text>
                <v-row class="pt-5 pb-5">
                  <v-col lg="1" class="text-left">
                        <v-btn
                            @click="addWarehousePersonnel()"
                        >
                            Add
                        </v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col lg="3">
                      <v-text-field
                            v-model="search"
                            label="Search Warehouse Personnel"
                            single-line
                            hide-details
                            append-icon="mdi-magnify"
                            @click:append="searchInput()"
                            @keydown.enter="searchInput()"
                            clearable
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col lg="12">
                        <v-data-table
                            :items="this.warehouse_personnel"
                            :headers="allPersonnel"
                            :loading="loading"
                            dense
                        >
                        <template v-slot:[`item.wpf`]="{ item }">
                          <span class="badge bg-primary text-white" v-if="item.wpf">Yes</span>
                          <span class="badge bg-danger text-white" v-else>No</span>
                        </template>

                        <template v-slot:[`item.cf`]="{ item }">
                          <span class="badge bg-primary text-white" v-if="item.cf">Yes</span>
                          <span class="badge bg-danger text-white" v-else>No</span>
                        </template>

                        <template v-slot:[`item.service`]="{ item }">
                          <span class="badge bg-primary text-white" v-if="item.service">Yes</span>
                          <span class="badge bg-danger text-white" v-else>No</span>
                        </template>

                        <template v-slot:[`item.action`]="{ item }">
                          <v-btn text icon color="blue" :disabled="!disabled">
                              <v-icon class="btn-action" small @click="editdialogs(item.id)">mdi-pencil</v-icon>
                          </v-btn>
                          <v-btn text icon color="red" :disabled="!disabled">
                              <v-icon class="btn-action"  small @click="deleteCustomer(item.id)">mdi-delete</v-icon>
                          </v-btn>
                        </template>

                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
       
        <v-dialog max-width="600px" v-model="WAREHOUSE_PERSONNEL.TRIGGER" persistent>
          <warehouse-personnel-add-component ref="update"></warehouse-personnel-add-component>
        </v-dialog>
  </div>
</template>
<script>
import WarehousePersonnelAddComponent from './WarehousePersonnelAddComponent.vue'
import { mapGetters } from "vuex";
import swal from 'sweetalert2';

export default {
    components:{
      WarehousePersonnelAddComponent
    },
  data() {
    return{
      allPersonnel: [
          { text: 'Name', align: 'start', value: 'name'},
          { text: 'WPF', align: 'start', value: 'wpf', },
          { text: 'CF', align: 'start', value: 'cf', },
          { text: 'Service', align: 'start', value: 'service', },
          { text: "Actions", align: "center", value: "action", sortable: false }
      ],
      search: '',
      warehouse_personnel: [],
      userAccess: {
        edit: false,
        delete: false,
      },
      loading: true,
      disabled: false,
    }
  },
  computed:{
    ...mapGetters([
      'GET_WAREHOUSE_PERSONNEL_ADD_DIALOG',
      'GET_ALL_WAREHOUSE_PERSONNEL',
      'USER_ACCESS',
      'WAREHOUSE_PERSONNEL',
      'GET_RELOAD_WAREHOUSE_PERSONNEL',
      'WAREHOUSE_PERSONNEL_GENERIC'
    ])
  },
  mounted(){
    this.searchInput()
    this.checkAccess()
  },
  methods:{
    addWarehousePersonnel(){
      this.$store.commit('WAREHOUSE_PERSONNEL_DIALOG', {
        TRIGGER: true,
        ACTION: 'add',
      });
      this.$store.commit('RELOAD_WAREHOUSE_PERSONNEL', true)
    },
    searchInput(){
      this.loading = true
      let payload = {
        search: this.search
      }
      this.$store.dispatch('getAllWarehousepersonnel', payload).then(response => {
          this.warehouse_personnel = response.data
          this.loading = false
      })
    },
    async editdialogs(id){
      let payload = {
        id: id
      }
     await this.$store.dispatch('getAllWarehousepersonnel', payload).then(response => {
        this.$store.commit('SELECTED_PERSONEL',response.data[0])
          // this.$refs.update.getSelected();
          this.$store.commit('WAREHOUSE_PERSONNEL_DIALOG', {
            TRIGGER: true,
            ACTION: 'update',
          });
      })
    },
    deleteCustomer(id){
      swal.fire({
          text: 'Are you sure you want to delete?',
          icon: 'question',
          showConfirmButton:true,
          showCancelButton  :  true,
          reverseButtons:true,
          allowOutsideClick :  false,
          confirmButtonColor: '#397373',
          cancelButtonColor : 'grey',
          confirmButtonText:'Confirm',
      }).then((result)=>{
          if(result.isConfirmed) {
            let payload = {
              id: id
            }
            this.$store.dispatch('deleteWarehousePersonnel', payload).then(response => {
                swal.fire('',response.data.msg,response.data.status)
                this.$store.commit('RELOAD_WAREHOUSE_PERSONNEL', true)
            })
          }
      });
    },
    checkAccess() {
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id).then(response => {
            let val = response
            val.forEach(e => {
                if (e.actions_code == 'edit') {
                    this.userAccess.edit = true;
                    this.disabled = true
                }
                if (e.actions_code == 'delete') {
                    this.userAccess.delete = true;
                    this.disabled = true
                }
            });
        });
    },
  },
  watch:{
    GET_RELOAD_WAREHOUSE_PERSONNEL:{
      handler(val){
        if(val){
          this.searchInput()
        }
        this.$store.commit('RELOAD_WAREHOUSE_PERSONNEL', false)
      }
    }
  }
 
};
</script>

