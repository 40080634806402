
import ServiceInquiry from "@/views/main/modules/Services/Views/Inquiry.vue";
import repository from "@/api/credentials";

const routes = 
        [{
              path: "/services/inquiry/:id",
              name: "serviceinquiry",
              component: ServiceInquiry,
              beforeEnter:(to,from,next)=>{
                repository.checkAccessUser(to.params.id).then((response)=>{
                    if(response.data == 'fail'){
                        next('/no-access')
                        }else{
                        next()
                    }
                }).catch(()=>{

                })

          },
              meta:{
                   requiresAuth:true
                 }
          }]
  

export default routes;
