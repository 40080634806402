<script>
  import Echo from 'laravel-echo';
    export default {
        methods: {
            callPusher(){
                window.Pusher = require('pusher-js');
                window.Pusher = Pusher;

                window.Echo = new Echo({
                    broadcaster: 'pusher',
                    cluster: 'mt1',
                    key: process.env.VUE_APP_WEBSOCKETS_KEY,
                    wsHost: process.env.VUE_APP_WEBSOCKETS_SERVER,
                    wsPort: 6001,
                    wssPort: 6001,
                    forceTLS: false,
                    disableStats: false
                });
            }
        }
    }
</script>