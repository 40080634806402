<template>
    <v-card elevation=0>
      <v-card-text class="p-2 m-0">
          <v-row class="p-2">
            <v-col cols="2" class="pt-6 pl-2">
              <v-autocomplete
                v-model="bound_type"
                label="Transaction Type"
                outlined
                dense
                auto-select-first
                :items="GET_INBOUND_OUTBOUND_TYPE"
              ></v-autocomplete>
            </v-col>
            <v-col cols="2" class="pt-6 pl-2" v-if="SELECTED_TAB == 2">
              <v-autocomplete
                  v-model="customer_id"
                  :items="customers"
                  item-text="text"
                  item-value="value"
                  auto-select-first
                  outlined
                  label="Customer"
                  required
                  dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="1" class="pt-6 pl-2">
              <v-autocomplete
                  v-model="filter.released_by"
                  :items="prepared_by_users"
                  item-text="text"
                  item-value="value"
                  auto-select-first
                  outlined
                  label="Released By"
                  required
                  dense
              ></v-autocomplete>
            </v-col>
            <v-col lg="3" md="4" class="pt-6 pl-2">
              <v-btn @click="searchInput()">FILTER</v-btn>
            </v-col>
            <!-- <v-col cols="2" class="pt-6">
              <v-menu
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                    <v-text-field
                        v-model="request_date_from"
                        prepend-inner-icon="mdi-calendar"
                        outlined
                        dense
                        readonly
                        v-on="on"
                        style="display: block"
                        background-color="white"
                        label="Date From"
                        clearable
                    ></v-text-field>
                </template>
                <v-date-picker v-model="request_date_from"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2" class="pt-6">
              <v-menu
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                    <v-text-field
                        v-model="request_date_to"
                        prepend-inner-icon="mdi-calendar"
                        outlined
                        dense
                        readonly
                        v-on="on"
                        style="display: block"
                        background-color="white"
                        label="Date To"
                        clearable
                    ></v-text-field>
                </template>
                <v-date-picker v-model="request_date_to"></v-date-picker>
              </v-menu>
            </v-col> -->
            <v-spacer></v-spacer>
            <v-col lg="3" md="4" class="d-flex justify-content-end align-items-end mr-3">
              <v-text-field
                    id="searchBar"
                    v-model="filter.search"
                    label="Search by Transaction #"
                    single-line
                    hide-details
                    append-icon="mdi-magnify"
                    @change="()=>{
                      searchInput()
                    }"
                    @keypress.enter="searchInput()"
                    @click:append="searchInput()"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="pb-5">
            <v-col lg="3" md="4" class="mt-5">
              <!-- <v-btn class="ml-5 mb-2" @click="AddDialog()" small> Job Order </v-btn> -->
            </v-col>
            <v-spacer></v-spacer>
            <!-- <v-col lg="1" class="d-flex justify-content-center align-items-end">
              <v-btn @click="searchInput()" icon >
                <v-icon >mdi-magnify</v-icon>
              </v-btn>
            </v-col> -->
        </v-row>
          <v-col lg="12">
            <v-data-table
              :headers="headers"
              :items="items"
              :search="filter.search"
              :options.sync="options"
              :server-items-length="totalItems"
              :items-per-page="10"
              :loading="!loaded"
              disable-sort
              :footer-props="{
                itemsPerPageOptions: itemsPerPageOptions,
              }"
              loading-text="Loading... Please wait"
              fixed-header
            >
            <template v-slot:[`item.created_at`]="{ item }">
                <span>{{item.created_at | formatDate}}</span>
            </template>
            <template v-slot:[`item.action`]="{ item }">
                    <v-btn
                        v-if="userAccess.includes('view')"
                        color="orange"
                        text
                        icon
                        @click="vieworupdateData(item,'View')"
                    ><v-icon small>mdi-eye</v-icon></v-btn>
                </template>
            </v-data-table>
          </v-col>
      </v-card-text>
    </v-card>

</template>
<script>
import BoundViewComponent from "@/views/main/layouts/dialogs/Admin/BoundViewComponent.vue";
import swal from "sweetalert2";
import { mapGetters } from 'vuex';
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue';
export default {
	mixins:[SharedFunctionsComponent],
	components:{
	BoundViewComponent,
	},
	data() {
	return {
		selectedItems:[],
		loaded: false,
		totalItems: 0,
	options: {},
		items: [],
		itemsPerPageOptions: [5, 10, 15, 50, 100],
		itemsPerPage: 10,
		filter: {
		search: "",
		status:0,
		released_by: '',
		},
		bound_type: null,
		customer_id: null,
		awaitingSearch: false,
		headers: [
		{ text: "Transaction #", align: "left", value: "transaction_number",width: "10%" },
		{ text: "Transaction Type", align: "left", value: "type_name",width: "15%" },
		{ text: "Released By", align: "left", value: "created_by" },
		{ text: "Released Date", align: "left", value: "created_at" },
		{ text: "Requestor", align: "center", value: "customer" },
		{ text: "Remarks", align: "center", value: "remarks",width: "40%" },
		{ text: "Action", align: "center", value: "action",sortable:false}
		],
		count:0,
		userAccess: [],
		customers: [],
		prepared_by_users: [],
	};
	},
	computed:{
	...mapGetters([
		'USER_ACCESS',
		'STATUS',
		'DIALOGS',
		'SELECTED_TAB',
		'GET_INBOUND_OUTBOUND_TYPE',
	]),
	},
	mounted() {
		this.$store.dispatch(
			'checkAccessUser',
			this.$router.currentRoute.params.id
		);
		this.$store.dispatch('getAllCustomers').then(response =>{
			this.customers = response;
		});
		let payload = {
			url: 'getPrepraredByInInboundOutbound',
			prefix: 'dropdown',
		}
		this.$store.dispatch('dropdownsApiGet',payload).then(response => {
			this.prepared_by_users = response;
		});
	},
	methods: {
		async getAllInboundOutbound(filter = null) {
			this.$store.commit('INBOUND_OUTBOUND_BUTTON_LOADING',false);
			this.items = [];
			this.loaded = false;
			let findTab = this.STATUS.BOUND.find(e=>e.value == this.SELECTED_TAB);
			let payload = {
			page:         this.options.page,
			itemsPerPage: this.options.itemsPerPage,
			search:       this.filter.search,
			sortBy:       this.options.sortBy,
			sortDesc:     this.options.sortDesc,
			status:       this.filter.status,
			bound_type: this.bound_type,
			customer_id: this.customer_id,
			released_by: this.filter.released_by,
			selected_tab:findTab?findTab.text:'',
			url: 'getAllInboundOutbound',
			};
			await this.$store.dispatch('adminInboundOutboundGet', payload)
			.then((response) => {
				this.items = response.data.data;
				this.loaded = true;
				this.totalItems = response.data.total;
				this.$store.commit('INBOUND_OUTBOUND_BUTTON_LOADING',true);
			}).catch((e)=>{
			this.loaded = true;
			});
			this.$store.commit('INBOUND_OUTBOUND_BUTTON_LOADING',true);
		},
		searchInput(){
			this.options.page = 1
			this.getAllInboundOutbound({ query: this.search });
		},
		vieworupdateData(item,action){
			this.$store.commit('DIALOG',true)
			this.$store.commit('ACTION',action);
			this.$store.commit('BOUND_EDIT_ITEM',item);
			let payload = {
			id:item.id,
			url:'adminInboutOutboundShow'
			}
			this.$store.dispatch('adminInboundOutboundShowItems',payload).then((res) =>{
				this.imageLoader(res.adr_data?.image_path)
				
			});
		},
		deleteData(item){

		},
		findBoundtypeStatus(){
					return this.STATUS.BOUND.find(e=>e.value == this.SELECTED_TAB)
			},
		async imageLoader(imagePath){
			let image = await this.loadImage(imagePath);
			this.$store.commit('COMPANY_IMAGE', image);
		},
	},
	watch: {
		GET_NEW_JO_TABLE:{
			handler(val){
			if(val){
				this.getAllInboundOutbound();
			}
			}
		},
		'DIALOGS':{
				handler(val){
					if(!val){
						this.getAllInboundOutbound()
					}
				}
			},
		options: {
			handler() {
			this.getAllInboundOutbound();
			},
			deep: true,
		},
		USER_ACCESS: {
			handler(val) {
				if (val != "fail") {
					this.userAccess = val.map(e=>e.actions_code)
				}
			},
		},
		'SELECTED_TAB':{
			handler(val){
			this.bound_type = null
			this.filter.status = val
			this.getAllInboundOutbound()
			this.$store.dispatch('getInboundOutBoundType',{text:this.findBoundtypeStatus()?this.findBoundtypeStatus().text:''})
			}
		},
	},
};
</script>
