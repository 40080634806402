<template>
    <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
            <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                <span class="text-h5">{{ GET_HR_DIALOG_TITLE }}</span>
                <v-row class="m-0">
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                            text
                            icon
                            small
                            color="gray"
                            class="float-right"
                            @click="closeDialog()"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-container class="pb-0 mt-1" fluid>
                    <v-row class="pa-0">
						<v-col cols="12" sm="3">
							<div class="d-flex flex-row mb-1 ml-1">
								<FileUploadComponent id="attachment" @uploadedData="uploadedData" ref="fileUpload" @reset="clearFileUpload"/>
								<BaseCameraCapture class="ml-1" ref="cameraUpload" id="camera" @capturedImages="getImages" @reset="clearCamera"></BaseCameraCapture>
								<BaseFileViewer v-if="ACTION != 'Submit'" id="fileViewer" class="mx-1 mt-1"/>
							</div>
						</v-col>
                        <v-col cols="12" sm="9" class="pa-0" >
                            <v-row class="ma-0 d-flex justify-end">

                                <v-col cols="2" class="mt-2">
                                    <v-menu
                                        ref="menu"
                                        v-model="date_menu.date_submitted_menu"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                label="Date Submitted"
                                                outlined
                                                dense
                                                v-model="form.date_submitted"
                                                :disabled="disabled_view"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>

                                        <v-date-picker
                                            v-model="form.date_submitted"
                                            no-title
                                            scrollable
                                        >
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="4" class="mt-2" v-if="form.tenure == 0">
                                    <v-menu
                                        ref="menu"
                                        v-model="date_menu.temporary_period_menu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                label="Temporary Period (YYYY-MM-DD)"
                                                outlined
                                                dense
                                                v-model="dateRangeText"
                                                :disabled="disabled_view"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>

                                        <v-date-picker
                                            v-model="form.temporary_period"
                                            no-title
                                            scrollable
                                            range
                                        >
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="2">
                                     <v-switch
                                        v-model="form.tenure"
                                        :true-value="1"
                                        :false-value="0"
                                        :readonly="disabled_view"
                                        dense
                                        label="Tenure"
                                        class="pa-0"
                                    >
                                    </v-switch>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- <v-col cols="12" sm="2" class="pa-0 pr-1 d-flex justify-center">
                            <v-switch
                                v-model="toggles.temporary"
                                :true-value="1"
                                :false-value="0"
                                :readonly="disabled_view"
                                dense
                                label="Temporary"
                                class="pa-0"
                            >
                            </v-switch>
                        </v-col>  -->
					</v-row>
                    <v-row>
                        <v-col cols="12" sm="3" class="pa-0 pr-1">
                            <v-autocomplete
                                v-model="form.job_title_id"
                                :items="dropdown.list.positions"
                                :rules="errors.required"
                                :readonly="disabled_view"
                                item-disabled="isDisabled"
                                item-value="value"
                                item-text="text"
                                label="Position"
                                auto-select-first
                                outlined
                                required
                                dense
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="3" class="pa-0 pr-1">
                            <v-autocomplete
                                v-model="form.request_reason_type_id"
                                :items="dropdown.list.request_reason"
                                :rules="errors.required"
                                :readonly="disabled_view"
                                label="Reason for Request"
                                item-disabled="isDisabled"
                                item-value="value"
                                item-text="text"
                                auto-select-first
                                outlined
                                required
                                dense
                            >
                            <template v-slot:append>
                                <v-tooltip bottom color="#444444" v-if="form.request_reason_type_id">
                                        <template v-slot:activator="{ on, attrs }" >
                                            <v-icon  v-bind="attrs" v-on="on" class="pull-right cursor-pointer" color="blue" small>mdi-information-outline</v-icon>
                                        </template>
                                    <RequirementsPreviewComponentVue :items="getSelectedItem"></RequirementsPreviewComponentVue>
                                </v-tooltip>
                            </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="3" class="pa-0 pr-1">
                            <v-text-field
                                v-model="form.no_of_personnel"
                                :readonly="disabled_view"
                                :rules="errors.required"
                                label="No Of Personnel Needed"
                                type="number"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="3" class="pa-0 pr-1">
                            <v-menu
								v-model="date_menu.date_required"
								:close-on-content-click="false"
								ref="menu"
								transition="scale-transition"
								offset-y
								max-width="290px"
								min-width="auto"
							>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="form.date_required"
                                        :disabled="disabled_view"
                                        :rules="errors.required"
                                        label="Date Required (YYYY-MM-DD)"
                                        v-mask="dateMask"
                                        outlined
                                        dense
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="form.date_required"
                                    @input="$refs.menu.save(form.date_required),date_menu.date_required = false"
                                    scrollable
                                    no-title
                                >
                                </v-date-picker>
							</v-menu>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="3" class="pa-0 pr-1 d-flex justify-center">
                            <v-autocomplete
                                v-model="form.education_type_id"
                                :items="dropdown.list.education_type"
                                :rules="errors.required"
                                :readonly="disabled_view"
                                item-disabled="isDisabled"
                                item-value="value"
                                item-text="text"
                                label="Type of Education"
                                auto-select-first
                                outlined
                                required
                                dense
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="3" class="pa-0 pr-1 d-flex justify-center">
                            <v-autocomplete
                                v-model="form.experience_type_id"
                                :items="dropdown.list.experience_type"
                                :rules="errors.required"
                                :readonly="disabled_view"
                                label="Type of Experience"
                                item-text="text"
                                item-value="value"
                                item-disabled="isDisabled"
                                auto-select-first
                                outlined
                                required
                                dense
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="3" class="pa-0 pr-1 d-flex justify-center">
                            <v-autocomplete
                                v-model="form.special_skill_ids"
                                :items="dropdown.list.special_skills"
                                :rules="errors.required"
                                :readonly="disabled_view"
                                label="Special Skills"
                                item-text="text"
                                item-value="value"
                                item-disabled="isDisabled"
                                auto-select-first
                                small-chips
                                clearable
                                multiple
                                outlined
                                required
                                dense
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="3" class="pa-0 pr-1 d-flex justify-center">
                            <v-autocomplete
                                v-model="form.special_fitness_ids"
                                :items="dropdown.list.special_fitness"
                                :rules="errors.required"
                                :readonly="disabled_view"
                                label="Special Fitness Requirements"
                                item-text="text"
                                item-value="value"
                                item-disabled="isDisabled"
                                auto-select-first
                                small-chips
                                clearable
                                multiple
                                outlined
                                required
                                dense
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row>
                         <v-col cols="12" sm="12" class="pa-0 pr-1">
                            <v-textarea
                                v-model="form.job_summary"
                                :readonly="disabled_view"
                                :height="100"
                                :rules="errors.required"
                                label="Job Summary"
                                no-resize
                                outlined
                                dense
                            ></v-textarea>
                        </v-col>
                         <v-col cols="12" sm="12" class="pa-0 pr-1">
                            <v-textarea
                                v-model="form.remarks"
                                :readonly="disabled_view"
                                :height="70"
                                label="Remarks"
                                no-resize
                                outlined
                                dense
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions
                class="text-center"
                style="border-top: 1px solid #dee2e6"
            >
                <v-row class="ma-1 pb-2" v-if="actions != 'View'">
                    <v-col cols="12">
                        <v-btn
                            @click="addManpowerRequest()"
                            :disabled="disabled_view"
                        >
                            <span>{{ actions }}</span>
                        </v-btn>
                        <span class="pr-12">&nbsp;</span>
                    </v-col>
                </v-row>

                <v-row class="ma-1 pb-2" v-if="(actions == 'View' && form.status =='pending' && userAccess.approve_reject_manpower_request) || ( actions == 'View' && form.status =='pending' && USERACCOUNT_LOAD.president == 1)">
                    <v-col cols="6">
                        <v-btn
                            @click="approve(1)"
                            color="blue"
                        >
                            <span>Approve</span>
                        </v-btn>
                        <span class="pr-12">&nbsp;</span>
                    </v-col>
                    <v-col cols="6">
                        <v-btn
                            @click="approve(0)"
                            color="red"
                        >
                            <span>Reject</span>
                        </v-btn>
                        <span class="pr-12">&nbsp;</span>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-form>
</template>
<script>
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
import FileUploadComponent from "@/views/main/Shared/FileUploadComponent.vue";
import BaseCameraCapture from "@/views/main/modules/HR/Base/BaseCameraCapture.vue"
import BaseFileViewer from "@/views/main/layouts/dialogs/HR/RecruitmentSystem/Base/ManpowerRequestFilesViewer.vue";
import RequirementsPreviewComponentVue from '@/views/main/layouts/dialogs/HR/RecruitmentSystem/RequirementsPreview/RequirementsPreviewComponent.vue';
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
export default {
    mixins: [SharedFunctionsComponentVue],
    data() {
        return {
            valid: false,
            overlay: false,
            disabled_view: false,
            errors: {
                required: [(v) => !!v || "Field is required"],
                number: [
                    (v) => !!v || "Cost is required",
                    (v) => !isNaN(v) || "Input must be a number",
                ],
            },
            form: {},
            dropdown:{
                list :{
                    positions           :[],
                    request_reason      :[],
                    education_type      :[],
                    experience_type     :[],
                    special_fitness     :[],
                    special_skills      :[],
                }
            },
            toggles:{
                temporary: 0,
            },
            userAccess: {
                view: false,
                create: false,
                edit: false,
                delete: false,
                approve_reject_manpower_request:false
            },
            component_dispatches:[
                'getDepartment',
                'getEmployeeJobTitleSelection',
                'getManpowerRequestReasonTypeSelection',
                'getEducationTypeSelection',
                'getExperienceTypeSelection',
                'getSpecialRequirementsSelection',
                'getSpecialSkillsSelectionSelection'
            ],
            date_menu :{
                date_required_menu      : false,
                temporary_period_menu   : false,
                date_submitted_menu   : false,
            },
			dateMask: '####-##-##',
        };
    },
    computed: {
        ...mapGetters([
            "ACTION",
            "GET_LIST_DEPARTMENT",
            "DIALOGS",
            "GET_HR_DISPATCH",
            'GET_JOB_TITLE_SELECTION',
            "GET_HR_OTHER_TABS_DIALOG_VIEW",
            "USER_ACCESS",
            "USERACCOUNT_LOAD",
            "GET_MANPOWER_REQUEST_REASON_TYPE_SELECTION",
            "GET_EDUCATION_TYPE_SELECTION",
            "GET_EXPERIENCE_TYPE_SELECTION",
            "GET_SPECIAL_REQUIREMENTS_SELECTION",
            "GET_SPECIAL_SKILLS_SELECTION",
            "GET_HR_DIALOG_TITLE"
        ]),
        actions() {
            if (this.ACTION == "Submit") {
                this.disabled_view = false;
                this.isViewBtn = false;
                return "Submit";
            } else if (this.ACTION == "Update") {
                this.disabled_view = false;
                this.isViewBtn = true;
                this.getManpowerRequestData();
                return "Update";
            } else if (this.ACTION == "View") {
                this.disabled_view = true;
                this.isViewBtn = true;
                this.getManpowerRequestData();
                return "View";
            }
        },
        dateRangeText () {
            return this.form.temporary_period.join(' ~ ')
        },
        getSelectedItem() {
            if (this.form.request_reason_type_id) {
                const selectedItem = this.dropdown.list.request_reason.find(item => item.value === this.form.request_reason_type_id);
                return selectedItem;
            }
            return null;
        }
    },
    components:{
        BaseCameraCapture,
		BaseFileViewer,
		FileUploadComponent,
        RequirementsPreviewComponentVue
    },
    created() {
        this.initialForm();
    },
    mounted() {
        this.getAllDropdowns();
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
    },
    methods: {
        uploadedData(data){
            if (!data)
            {
                this.form.uploadedFiles = null;
            }
            this.form.uploadedFiles = data;
        },
        getImages(images){
            this.form.images = images;
        },
        getAllDropdowns(){
            this.component_dispatches.forEach(el=>{
                this.$store.dispatch(el);
            });
        },
        initialForm() {
            this.form = {
                department_id               : this.USERACCOUNT_LOAD.department_id,
                request_reason_type_id      : '',
                requestor_id                : this.USERACCOUNT_LOAD.id,
                education_type_id           : '',
                experience_type_id          : '',
                special_fitness_ids     : [],
                special_skill_ids           : [],
                tenure                      : 1,
                no_of_personnel             : 0,
                job_summary                 : '',
                date_required               : null,
                temporary_period            : [],
                remarks                     : '',
                date_submitted              : this.$dayjs().format('YYYY-MM-DD'),
            };
        },
        height(width) {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "100%";
                case "sm":
                    return "100%";
                case "md":
                    return width;
                case "lg":
                    return width;
                case "xl":
                    return width;
            }
        },
        closeDialog() {
            this.$store.commit("DIALOG", false);
        },
        async addManpowerRequest() {
            this.$refs.form.validate();
            const data = new FormData();
            const position_swal_text        = this.form.job_title_id ? '' : 'Position';
            const request_reason_swal_text  = this.form.request_reason_type_id ? '' : 'Request Reason';
            const no_of_personnel           = this.form.no_of_personnel ? '' : 'No. Of Personnel';
            const date_required_swal_text   = this.form.date_required ? '' : 'Date Required';
            const education_type_swal_text   = this.form.education_type_id ? '' : 'Type Of Education';
            const experience_type_swal_text   = this.form.experience_type_id ? '' : 'Type Of Experience';
            const special_skills_swal_text   = this.form.special_skill_ids ? '' : 'Special Skills';
            const special_fitness_swal_text   = this.form.special_fitness_ids ? '' : 'Special Fitness';
            const job_summary_swal_text   = this.form.job_summary ? '' : 'Job Summary';
            const date_submitted_swal_text   = this.form.date_submitted ? '' : 'Date Submitted';
            const array_text =
            [
                position_swal_text,
                no_of_personnel,
                request_reason_swal_text,
                date_required_swal_text,
                education_type_swal_text,
                experience_type_swal_text,
                special_skills_swal_text,
                special_fitness_swal_text,
                job_summary_swal_text,
                date_submitted_swal_text,
            ]
            const filtered = array_text.filter(function (el) {
                return el.replace(',', '');
            })
            if (this.form.job_title_id && this.form.no_of_personnel && this.form.date_submitted ) {
                await this.$store.dispatch(this.GET_HR_DISPATCH.add,this.form).then(response=>{
                    Swal.fire(response.icon.toUpperCase(),response.message,response.icon);
                        if (!!this.form.uploadedFiles && this.form.uploadedFiles.attachments.length > 0) {
                                let files = this.form.uploadedFiles.attachments;
                            for (let i = 0; i < _.compact(files).length; i++) {
                                data.append("files[]", files[i]);
                            }
                            data.append('id',response.data.id);
                            const config = {
                                headers: {
                                    "content-type": "multipart/form-data",
                                },
                            };
                            let payload = {
                                params: data,
                                config: config,
                            };
                            this.$store.dispatch('uploadManpowerRequestfiles',payload)
                        }
                    this.$store.commit("DIALOG", false);
                })
            } else {
                Swal.fire({
                    text: `Fill Required Fields (${filtered})`,
                    icon: 'warning',
                    allowOutsideClick: false,
                });
            }
        },
        updateManpowerRequest() {
            let payload ={
                id : this.form.id,
                payload:this.form
            }
            this.$store.dispatch(this.GET_HR_DISPATCH.update, payload).then((response) => {
				Swal.fire(response.icon.toUpperCase(),response.message,response.icon);
				this.closeDialog();
			});
        },
        async getManpowerRequestData() {
            if(this.GET_HR_OTHER_TABS_DIALOG_VIEW.tenure == 0){
                var dateArray = [this.GET_HR_OTHER_TABS_DIALOG_VIEW.temporary_period_from.split(" ")[0], this.GET_HR_OTHER_TABS_DIALOG_VIEW.temporary_period_to.split(" ")[0]];
            }
            this.form.id                            = this.GET_HR_OTHER_TABS_DIALOG_VIEW.id;
            this.form.job_title_id                  = this.GET_HR_OTHER_TABS_DIALOG_VIEW.job_title_id;
            this.form.education_type_id             = this.GET_HR_OTHER_TABS_DIALOG_VIEW.education_type_id;
            this.form.experience_type_id            = this.GET_HR_OTHER_TABS_DIALOG_VIEW.experience_type_id;
            this.form.request_reason_type_id        = this.GET_HR_OTHER_TABS_DIALOG_VIEW.request_reason_type_id;
            this.form.tenure                        = this.GET_HR_OTHER_TABS_DIALOG_VIEW.tenure;
            this.form.date_required                 = this.GET_HR_OTHER_TABS_DIALOG_VIEW.date_required.split(" ")[0] ?? null;
            this.form.no_of_personnel               = this.GET_HR_OTHER_TABS_DIALOG_VIEW.no_of_personnel;
            this.form.special_fitness_ids           = this.GET_HR_OTHER_TABS_DIALOG_VIEW.special_fitness_ids.replace(/\s/g, '').split(',').map(Number);
            this.form.special_skill_ids             = this.GET_HR_OTHER_TABS_DIALOG_VIEW.special_skill_ids.replace(/\s/g, '').split(',').map(Number);
            this.form.job_summary                   = this.GET_HR_OTHER_TABS_DIALOG_VIEW.job_summary;
            this.form.temporary_period              = this.GET_HR_OTHER_TABS_DIALOG_VIEW.tenure == 0 ? dateArray :[]
            this.form.status                        = this.GET_HR_OTHER_TABS_DIALOG_VIEW.status
            this.form.remarks                       = this.GET_HR_OTHER_TABS_DIALOG_VIEW.remarks
            this.form.date_submitted                = this.GET_HR_OTHER_TABS_DIALOG_VIEW.date_submitted
        },
        approve(isApproved){
            let payload = {
                approved: isApproved,
                id: this.form.id,
                job_title_id: this.form.job_title_id
            }
            this.$store.dispatch('approveRejectManpowerRequest',payload).then(response=>{
				Swal.fire(response.icon.toUpperCase(),response.message,response.icon);
                this.closeDialog()
            })
        },
        filterJobsByDept(){
            let filteredJobs = this.GET_JOB_TITLE_SELECTION.filter(obj=>
				 obj.department_id === this.USERACCOUNT_LOAD.department_id
			)
			this.dropdown.list.positions = filteredJobs
		},
        clearFileUpload(confirm) {
            if (confirm) {
                this.form.files = null;
            }
            this.form.files = null;
            this.$refs.fileUpload.reset();
        },
        clearCamera(confirm){
            if (confirm) {
                this.form.images = null;
            }
            this.form.images = null;
            this.$refs.cameraUpload.reset();
        }
    },
    watch: {
        DIALOGS: {
            handler(val) {
                if (val) {
                    this.initialForm();
                    this.clearFileUpload()
                    this.clearCamera()
                }
            },
        },
        USER_ACCESS: {
            handler(val) {
                if(val != "fail"){
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                this.userAccess.create = false;
                this.userAccess.approve_reject_manpower_request = false;
                    val.forEach(e => {
                        if (e.actions_code == 'edit') {
                            this.userAccess.edit = true;
                        } else if (e.actions_code == 'delete') {
                            this.userAccess.delete = true;
                        } else if (e.actions_code == 'view') {
                            this.userAccess.view = true;
                        } else if (e.actions_code == 'create') {
                            this.userAccess.create = true;
                        } else if (e.actions_code == 'approve_reject_manpower_request') {
                            this.userAccess.approve_reject_manpower_request = true;
                        }
                    });
                }
            }
        },
        GET_MANPOWER_REQUEST_REASON_TYPE_SELECTION:{
            handler(val){
                this.dropdown.list.request_reason = val
            }
        },
        GET_JOB_TITLE_SELECTION:{
            handler(val){
                this.dropdown.list.positions = val
            }
        },
        GET_EXPERIENCE_TYPE_SELECTION:{
            handler(val){
                this.dropdown.list.experience_type = val
            }
        },
        GET_EDUCATION_TYPE_SELECTION:{
            handler(val){
                this.dropdown.list.education_type = val
            }
        },
        GET_SPECIAL_REQUIREMENTS_SELECTION:{
            handler(val){
                this.dropdown.list.special_fitness = val
            }
        },
        GET_SPECIAL_SKILLS_SELECTION:{
            handler(val){
                this.dropdown.list.special_skills = val
                this.filterJobsByDept()
            }
        },
        'form.temporary_period':{
            handler(val){
                if(val){
                    if(val.length == 2){
                        this.date_menu.temporary_period_menu = false
                    }
                    if(val[0] > val[1]){
                        Swal.fire("Period Range Not Valid","","warning")
                        this.form.temporary_period = [];
                    }
                }
            }
        },
        'form.tenure':{
            handler(val){
                if(val == 1){
                    this.form.temporary_period = [];
                }
            }
        }
    },
};
</script>
