<template>
    <v-dialog v-model="sparePartsDialog" persistent max-width="60%">
        <v-card class="pb-4">
            <v-row class="m-0">
                <v-card-title>
                    <span class="headline">Spare Parts</span>
                </v-card-title>
                <v-col cols="pull-right-10 p-2">
                    <v-btn text icon color="gray" class="float-right" @click="closeSparePartsDialog()">
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class="mt-0" />
            <div class="ma-4">
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
        }
    },
    computed: {
        sparePartsDialog() {
            return this.$store.state.service.sparePartsDialog;
        }
    },
    methods: {
        closeSparePartsDialog() {
            this.$store.commit('closeSparePartsDialog');
        }
    },
}
</script>

<style>

</style>
