<template>

        <v-container>
        <v-btn  :class="dnone?'d-none':''"  icon text @click="openCamera" :disabled="disabled">
             <v-icon size="30">mdi-camera</v-icon>
        </v-btn>
        <v-container  :class="!dnone?'d-none':'position-relative fluid d-flex justify-content-center'">
            <video class="text-center" style="border:1px solid black;" :width="cameraSize" :height="cameraSize" ></video>
            <div  class="position-relative fluid d-flex justify-content-end">
            <v-btn
                text
                icon
                small
                color="white"
                class="position-absolute"
                @click="closeCamera"
                >
                <v-icon color="white">mdi-close</v-icon>
                </v-btn>
            </div>
            <div  class="position-absolute mb-4 text-center h-50 w-50" style="bottom:0;">
            <v-btn
                text
                icon
                small
                color="white"
                class="position-absolute pr-4" style="bottom:0;">
                     <v-icon color="white" size="30" @click="CameraCapture">mdi-camera</v-icon>
                </v-btn>
            </div>
        </v-container>

    </v-container>


</template>
<script>
import {mapGetters} from 'vuex';
    export default {
        name:'Camera',
        data(){
            return{
                dnone:false
            }
        },
        props: ['disabled'],
        computed:{
            ...mapGetters(['GET_OPEN_CAMERA']),
            cameraSize() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return "100%"
                case 'sm': return "100%"
                case 'md': return "100%"
                case 'lg': return "70%"
                case 'xl': return "60%"
            }

        },
        },
        methods:{
        CameraAttr(){
        const videoPlayer = document.querySelector("video");
          let attr = {
              videoPlayer: videoPlayer,
              constraints:{ video : {
                  width:{
                      min:400,
                      ideal:400,
                      max:400
                  },
                  height:{
                      min:400,
                      ideal:400,
                      max:400
                  }
              }}
          }
          return attr;
        },
            openCamera(){
                this.dnone = false


                this.$store.dispatch('cameraInit',this.CameraAttr());
                this.dnone = true

            },
            CameraCapture(){
                this.$store.commit('CAMERA_CAPTURE',true);
                this.dnone = false
                this.$store.commit('OPEN_CAMERA',false);
            },
            closeCamera(){
                this.$store.dispatch('cameraStop',this.CameraAttr());
                this.dnone = false;
            }
        },
        watch:{
            GET_OPEN_CAMERA:{
                handler(val){
                    if(!val){
                        this.dnone = false
                    }
                }
            },
            disabled: {
                handler(val){
                    this.dnone = false
                },
                immediate: true
            }
        }
    }
</script>
