<template>
  <div>
      <div class="page-wrapper ma-1">
          <div class="row p-2">
              <div class="col-12 d-flex no-block align-items-center">
                  <h4 class="page-title">Delivery Receipt</h4>
              </div>
          </div>
          <div class="container">
              <div id="item-tabs" class="row mx-auto">
                  <v-tabs id="group-tab" color="cyan" dark slider-color="cyan"  background-color="#424242" class="col-12 pb-0" show-arrows>
                      <v-tab ripple @click="drStatusAction('pending')">Pending</v-tab>
                      <v-tab ripple @click="drStatusAction('in-transit')">In-Transit</v-tab>
                      <v-tab ripple @click="drStatusAction('delivered')">Delivered</v-tab>
                      <!-- <v-tab ripple @click="drStatusAction('defective')" style="color:#f49e17">Defective</v-tab>
                      <v-tab ripple @click="drStatusAction('def-in-transit')" style="color:#f49e17">In-Transit</v-tab>
                      <v-tab ripple @click="drStatusAction('def-delivered')" style="color:#f49e17">Delivered</v-tab> -->
                  </v-tabs>
                  <AddDeliveryDialogComponentVue v-if="drStatus == 'pending' || drStatus == 'defective'" :drStatus="drStatus" @successCreateDr="successCreateDr" />
                  <DeliveryTableComponentVue :drStatus="drStatus" :createDrSuccess="createDrSuccess" />
              </div>
          </div>
      </div>
  </div>
</template>

<script>

import DeliveryTableComponentVue from './DeliveryComponent/DeliveryTableComponent.vue';
import AddDeliveryDialogComponentVue from './DeliveryComponent/AddDeliveryDialogComponent.vue';


export default {
    data() {
        return {
            drStatus: 'pending',
            createDrSuccess: false,
        }
    },
    mounted(){
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
    },
    components: {
        DeliveryTableComponentVue,
        AddDeliveryDialogComponentVue
    },
    methods: {
        drStatusAction(status) {
            this.drStatus = status;
        },
        successCreateDr(confirm) {
            this.createDrSuccess = confirm;
        },
    }
}
</script>

<style>

</style>
