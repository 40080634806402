<template>
    <v-container fluid class="pa-3">
        <ComponentHeader
            :button="true"
            :title="'Items Based on Stock Range'"
            :type="'Inventory'"
        ></ComponentHeader>
        <v-container class="bg-bluish-gray default-container ma-0">
            <v-row no-gutters class="pb-1">
                <v-col sm="12" md="2">
                    <v-autocomplete
                        v-model="filters.item_type_ids"
                        :items="dropdowns.item_types.list"
                        :loading="loading.item_types"
                        :disabled="loading.item_types"
                        class="d-inline-block w-100 small"
                        label="Item Type"
                        background-color="white"
                        clearable
                        multiple
                        small-chips
                        deletable-chips
                        outlined
                        dense
                        auto-select-first
                        hide-details
                    ></v-autocomplete>
                </v-col>
                <v-col sm="12" md="3">
                    <v-autocomplete
                        v-model="filters.item_category_ids"
                        :items="dropdowns.item_categories.list"
                        :loading="loading.item_categories"
                        :disabled="loading.item_categories"
                        class="d-inline-block w-100 small"
                        label="Category"
                        background-color="white"
                        clearable
                        multiple
                        small-chips
                        deletable-chips
                        outlined
                        dense
                        auto-select-first
                        hide-details
                    ></v-autocomplete>
                </v-col>
                <!-- <v-col sm="12" md="2">
                    <v-autocomplete
                        v-model="filters.item_id"
                        :items="GET_ITEM_SELECTION_DEFAULT"
                        class="d-inline-block w-100 small"
                        item-text="text"
                        item-value="value"
                        label="Model"
                        background-color="white"
                        clearable
                        multiple
                        small-chips
                        deletable-chips
                        outlined
                        dense
                        auto-select-first
                        hide-details
                    ></v-autocomplete>
                </v-col> -->
                <v-col sm="12" md="2">
                    <v-autocomplete
                        v-model="filters.active"
                        :items="dropdowns.active.list"
                        class="d-inline-block w-100 small"
                        label="Active Type"
                        background-color="white"
                        outlined
                        dense
                        auto-select-first
                        hide-details
                    ></v-autocomplete>
                </v-col>

                <v-spacer></v-spacer>
                <v-col sm="12" md="3" class="text-right">
                    <v-btn class="mr-1" @click="resetFields()">Clear</v-btn>
                    <v-btn @click="getItemWithStocks(1)">Export</v-btn>
                    <v-btn class="ml-1" @click="clearUrlParams(); getItemWithStocks()">Filter</v-btn>
                </v-col>
            </v-row>
            <v-row no-gutters class="pb-1">
                 <v-col sm="12" md="1" v-if="filters.with_stock_range">
                    <v-text-field
                        v-model="filters.actual_stocks_range[0]"
                        class="d-inline-block w-100 small"
                        background-color="white"
                        label="Stocks Min"
                        type="number"
                        min="0"
                        hide-details
                        outlined
                        dense
                    ></v-text-field>
                </v-col>
                <v-col sm="12" md="1" v-if="filters.with_stock_range">
                    <v-text-field
                        v-model="filters.actual_stocks_range[1]"
                        class="d-inline-block w-100 small"
                        background-color="white"
                        label="Stocks Max"
                        type="number"
                        min="0"
                        hide-details
                        outlined
                        dense
                    ></v-text-field>
                </v-col>
                <v-col sm="12" md="1">
                    <v-checkbox
                        v-model="filters.with_stock_range"
                        :true-value="1"
                        :false-value="0"
                        color="white"
                        hide-details
                        dense
                    >
                    <template v-slot:label>
                            <span style="color:white">Stock Range</span>
                        </template>
                    </v-checkbox>
                </v-col>
                <v-col sm="12" md="1">
                    <v-checkbox
                        v-model="filters.has_images"
                        :true-value="1"
                        :false-value="0"
                        color="white"
                        hide-details
                        dense
                    >
                        <template v-slot:label>
                            <span style="color:white">Images</span>
                        </template>
                    </v-checkbox>
                </v-col>
            </v-row>
            <v-row dense no-gutters>
                <v-col cols="12">
                    <v-card>
                        <v-container fluid>
                            <v-data-table
                                :headers="datatable.headers"
                                :items="datatable.items.list"
                                :options.sync="datatable.options"
                                :server-items-length="datatable.items.count"
                                :loading="toggles.loaded"
                                :footer-props="{
                                    showFirstLastPage: true
                                }"
                                class="w-100"
                                dense
                                disable-sort
                            >
                                <!-- @update:page="getItemWithStocks()"
                                @update:items-per-page="getItemWithStocks()" -->
                                <template v-slot:item.image_path="{ item }">
                                    <v-img class="img-thumbnail" width="50" height="50" :src="checkImagePath(item.image_path)" eager contain></v-img>
                                </template>
                                <template v-slot:item.srp="{ item }">
                                    <span class="text-nowrap">
                                        {{ thousandSeprator(item.srp) }}
                                    </span>
                                </template>
                                <template v-slot:item.total_stocks="{ item }">
                                    <span class="text-nowrap">
                                        {{ thousandSeprator(item.total_stocks) }}
                                    </span>
                                </template>
                                <template v-slot:item.sub_image_path="{ item }">
                                    <v-img v-if="item.sub_image_path && item.substitute_models" class="img-thumbnail mx-auto" width="50" height="50" :src="checkImagePath(item.sub_image_path)" eager contain></v-img>
                                </template>
                                <template v-slot:item.action="{ item }">
                                    <v-btn text icon color="orange">
                                        <v-icon class="btn-action" small @click="viewItem(item.id)">mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </v-container>
                    </v-card>
                    <ItemsViewComponent
                        :viewItemId="datatable.items.selected"
                        @closeView="closeView"
                    ></ItemsViewComponent>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>
<script>
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent'
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
import ItemsViewComponent from "@/views/main/layouts/dialogs/Sales/Items/ItemsViewComponent.vue"
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'

export default {
    mixins: [
        SharedFunctionsComponent
    ],
    components: {
        ComponentHeader,
        ItemsViewComponent
    },
    data: () => ({
        urlParams: {
            type_ids: [],
            category_ids: [],
            active: [],
            item_ids: [],
        },
        defaults: {
            item_type_ids: [],
            item_category_ids: [],
            item_ids: [],
            active: -1,
            for_export: 0,
            actual_stocks_range: [],
            has_images: 1
        },
        toggles: {
            loaded: false,
        },
        loading: {
            item_types: true,
            item_categories: true,
            initialLoad: true,
        },
        filters: {},
        dropdowns: {
            active: {
                list: [
                    { value: -1, text: 'All' },
                    { value: 1, text: 'Active' },
                    { value: 0, text: 'Inactive' },
                ],
            },
            item_types: {
                list: [],
            },
            item_categories: {
                list: [],
            },
        },
        datatable: {
            items: {
                list: [],
                count: 0,
                selected: 0,
            },
            headers: [
                { text: 'IMAGE', align: 'start', value: 'image_path', },
                { text: 'MODEL', align: 'start', value: 'model', },
                { text: 'NAME', align: 'start', value: 'name', },
                { text: 'UOM', align: 'start', value: 'uom' },
                { text: 'TYPE', align: 'start', value: 'type' },
                { text: 'CATEGORY', align: 'start', value: 'category', },
                { text: 'ACTIVE', align: 'center', value: 'active', },
                { text: 'SRP', align: 'end', value: 'srp', },
                { text: 'ACTUAL STOCKS', align: 'end', value: 'total_stocks', },
                { text: 'NEW MODEL', align: 'center', value: 'substitute_models', },
                { text: 'NEW MODEL IMAGE', align: 'center', value: 'sub_image_path', },
                // { text: 'ARRIVED CONTAINERS', align: 'start', value: 'arrived_containers', width: '7%'},
                { text: 'INCOMING CONTAINERS', align: 'start', value: 'incoming_containers', },
                {text: 'ACTION', align: 'center', value: 'action' },
            ],
            options: {},
        },
    }),
    async mounted(){
        this.resetFields()
        await this.storeUrlParams()
        await this.loadDropdowns()
        await this.setDefaultValues()
        // await this.getItemWithStocks()
        await this.$store.dispatch('getAllitemSelectionDefault')
    },
    computed: {
        ...mapGetters([
            'GET_ITEM_TYPES',
            'GET_ITEM_CATEGORIES',
            'GET_ITEM_SELECTION_DEFAULT',
        ])
    },
    methods: {
        viewItem(item_id) {
            this.datatable.items.selected = item_id
        },
        closeView() {
            this.datatable.items.selected = 0
        },
        async storeUrlParams() {
            const params = new URLSearchParams(window.location.search)

            Object.assign(this.urlParams, {
                item_ids: params.get('item_ids') == null ? this.defaults.item_ids : JSON.parse(params.get('item_ids')),
                type_ids: params.get('type_id') == null ? this.defaults.item_type_ids : JSON.parse(params.get('type_id')),
                category_ids: params.get('category_id') == null ? this.defaults.item_category_ids : JSON.parse(params.get('category_id')),
                active: params.get('active') == null ? this.defaults.active : JSON.parse(params.get('active')),
            })

            Object.assign(this.filters, {
                item_type_ids: this.urlParams.type_ids,
                item_category_ids: this.urlParams.category_ids,
                item_id: this.urlParams.item_ids,
                active: this.urlParams.active,
            })
        },
        async loadDropdowns() {
            await this.$store.dispatch('getItemTypes')
            await this.$store.dispatch('getItemCategories')
        },
        async setDefaultValues() {
            Object.assign(this.filters, {
                actual_stocks_range: [0, 0],
                item_type_ids:
                    this.dropdowns.item_types.list.filter(f => {
                        return [1, 2].includes(f.value)
                    }).map(m => parseInt(m.value))
            })
        },
        clearUrlParams(){
            Object.assign(this.urlParams, {
                type_ids: [],
                category_ids: [],
            })
        },
        async getItemWithStocks(for_export = 0) {
            let item_base64_arr = [];
            this.toggles.loaded = true
            this.filters.for_export = for_export
            this.datatable.items.list = []
            let currentDate = this.$dayjs().format('YYYY-MM-DD')
            let that = this;
            let payload = {
                pagination: this.datatable.options,
                filters: this.filters,
                url: 'itemsBasedOnStockRange',
            }

            await this.$store.dispatch('reports', payload).then(async response => {
                if (for_export == 0) {
                    let data = response.data.data
                    this.datatable.items.list = data.data
                    this.datatable.items.count = data.total

                } else {
                    if(this.filters.has_images == 1){
                        let base64_payload = {
                            url: 'getItemBase64',
                            filters: this.filters,
                        }
                        await this.$store.dispatch('reports',base64_payload).then(async response =>{
                            item_base64_arr = response.data
                        }).catch((err)=>{
                            Swal.fire(
                                'Network Error',
                                'error'
                            )
                            console.log(err);
                        });
                    }
                    let data = response.data
                    const workbook = new this.$exceljs.Workbook()
                    const rawDataSheetName = `Items ${this.$dayjs().format('YYYY-MM-DD')}`
                    let worksheet = workbook.addWorksheet(rawDataSheetName)

                    const columns = [
                        { header: 'IMAGE', key: '', width: 22, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                        { header: 'MODEL', key: 'model', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                        { header: 'NAME', key: 'name', width: 30, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                        { header: 'UOM', key: 'uom', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                        { header: 'TYPE', key: 'type', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                        { header: 'CATEGORY', key: 'category', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                        { header: 'ACTIVE', key: 'active', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                        { header: 'SRP', key: 'srp', width: 18, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: '#,##0.00' } },
                        { header: 'ACTUAL STOCKS', key: 'total_stocks', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: '#,##0' } },
                        { header: 'NEW MODEL', key: 'substitute_models', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                        { header: 'ARRIVED CONTAINERS', key: 'arrived_containers', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                        { header: 'INCOMING CONTAINERS', key: 'incoming_containers', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                    ]

                    worksheet.columns = columns

                    let pos = 1
                    if (this.filters.has_images != 1) {
                        worksheet.spliceColumns(1, 1)
                    }
                    for await (const item of data) {
                        item.srp = parseFloat(item.srp)
                        item.total_stocks = parseInt(item.total_stocks)
                        if(this.filters.has_images == 1){
                            item.base64img              = item_base64_arr.find((e)=> e.model == item.model).base64img;
                        }
                        worksheet.addRow(item)
                        if (this.filters.has_images === 1) {
                            await this.addImageToWorksheet(workbook, worksheet, item, pos);
                        }
                        pos++
                    }

                    workbook.xlsx.writeBuffer().then((data) => {
                        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheetcharset=utf-8' })
                        const filename = `Items with Stocks as of ${currentDate}.xlsx`
                        this.$filesaver.saveAs(blob, filename)
                    })
                }

                this.toggles.loaded = false
            })
        },
        resetFields(){
            this.filters = JSON.parse(JSON.stringify(this.defaults))
        },
        // validateStockRange(){
        //     if((this.filters.actual_stocks_range[0] > this.filters.actual_stocks_range[1]) || (this.filters.actual_stocks_range[1] < this.filters.actual_stocks_range[0])){
        //         Swal.fire("Stocks Range Not Valid","","warning")
        //         this.filters.actual_stocks_range = [0, 0];
        //     }
        // },
        resetStockRange(){
            this.filters.actual_stocks_range = [0, 0];
        }
    },
    watch: {
        'GET_ITEM_TYPES': {
            handler(val) {
                this.dropdowns.item_types.list = val
                this.loading.item_types = false
            }
        },
        'GET_ITEM_CATEGORIES': {
            handler(val) {
                this.dropdowns.item_categories.list = val
                this.loading.item_categories = false
            }
        },
        'filters.with_stock_range': {
            handler(val){
                if(val == 0){
                }
            }
        },
        'filters.actual_stocks_range': {
            handler(val){
                if((val[0] > val[1]) || (val[1] < val[0])){
                    Swal.fire(
                        "Stocks Range Not Valid",
                        "",
                        "warning"
                    ).then((confirm)=>{
                        if(confirm.isConfirmed){
                            this.resetStockRange();
                        }
                    })
                }
            }
        },
        'datatable.options': {
            handler(val){
                if(!this.loading.initialLoad){
                    this.getItemWithStocks();
                }else{
                    this.loading.initialLoad = false
                }
            }
        }
    }
}
</script>
<style>
    .card-body{
        padding: 3px !important
    }
</style>
