<template>
    <div>
        <v-card class="pa-4">
            <div class="pb-4">
                Files Viewer
            </div>
            <v-layout class="mx-4">
                <v-flex shrink>
                    <v-checkbox v-model="batchDownload"/>
                </v-flex>
                <v-flex>
                    <div>Batch Download</div>
                </v-flex>
            </v-layout>
            <v-data-table
                class="pa-4"
                :headers="filesHeader"
                :items="GET_RF_DETAILS.sc_repair_form_files"
                :no-data-text="'No Upload Files'"
                :show-select="batchDownload"
                v-model="selectedImages"

            >
                <template v-slot:item.action="{ item }">
                    <td class="px-0">
                        <span class="text-nonwrap">
                            <v-btn text icon color="orange">
                                <v-icon class="btn-action" small @click="showFile(item); newImagePath() ">mdi-eye</v-icon>
                            </v-btn>
                            <v-btn v-if="!batchDownload" text icon color="blue">
                                <v-icon class="btn-action" small @click="downloadFile(item)">mdi-download</v-icon>
                            </v-btn>
                            <v-btn text icon color="red">
                                <v-icon class="btn-action" small @click="deleteFile(item)">mdi-delete</v-icon>
                            </v-btn>
                        </span>
                    </td>
                </template>

                <template v-slot:item.created_at="{ item }">
                    <td class="px-0">
                        {{ date(item) }}
                    </td>
                </template>
            </v-data-table>

            <div v-if="batchDownload" class="pb-4">
                <v-divider class="mt-0" />
                <div style="text-align:center">
                    <v-btn @click="downloadSelectedImages()">Download</v-btn>
                </div>
            </div>
        </v-card>

        <v-dialog v-model="viewFile" persistent max-width="50%">
            <v-card class="pa-4">
                <v-row>
                    <v-col class="d-flex justify-space-between">
                        <v-card-title><span class="headline">{{ itemName }}</span></v-card-title>
                        <v-btn text icon @click="closeViewFile()"><v-icon>mdi-close-circle</v-icon></v-btn>
                    </v-col>
                </v-row>
                <v-divider/>
                <v-row>
                    <v-col>
                        <v-card-text>
                            <pinch-scroll-zoom
                                ref="zoomer"
                                :width="300"
                                :height="400"
                                :scale="scale"
                                @scaling="scalingHandler"
                                style="width:100%; height:600px; overflow:auto;"
                            >
                                <img :src="sourceFrame" />
                            </pinch-scroll-zoom>
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>

        <div style="display:none">
            <span v-for="(img,index) in selectedImages" :key="img.name">
                <img :id="`img${index}`" :src="sourceFrame" alt="" style="cursor:move">
            </span>
        </div>
    </div>
</template>

<script>
// import PinchScrollZoom, { PinchScrollZoomEmitData } from "@coddicat/vue-pinch-scroll-zoom";
import { map } from 'rxjs';
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
import PinchScrollZoom, { PinchScrollZoomEmitData } from "@coddicat/vue-pinch-scroll-zoom";
export default {
    data() {
        return {
            filesHeader: [
                { text: 'Name', value: 'name' },
                { text: 'Uploaded By', value: 'uploaded_by' },
                { text: 'Date', value: 'created_at' },
                { text: 'Action', value: 'action' },
            ],
            // files: [],
            repairFormId: '',
            loading: true,
            noDataText: '',
            viewFile: false,
            itemPath: '',
            itemName: '',
            withDelete: false,
            scale: 2,
            batchDownload: false,
            selectedImages: [],
            rf_number: '',
            origin: process.env.VUE_APP_API_HOST,
            downloadOnFlight: false,
            sourceFrame: '',
        }
    },
    components: {
        PinchScrollZoom
    },
    props: ['rfNumber', 'rfId','delete', 'itemId', 'buttonName','stockAdjustmentId'],
    watch: {
        'GET_RF_DETAILS': {
            handler(val){
                if(val > 0) {
                    this.loading = false;
                } else {
                    this.loading = true;
                }
            }
        },
        rfNumber() {
            if(!!this.rfNumber) {
                this.rf_number = this.rfNumber;
            }
        },
        batchDownload() {
            if(this.batchDownload) {
                // this.showSelect = true;
            }
        }
    },
    computed: {
        ...mapGetters([
            'GET_REPAIR_FORM_FILES',
            'GET_ITEM_WITH_SP_FILES',
            'GET_RF_DETAILS',
            'GET_ALL_PROCESS_RFS'
        ]),


    },
    mounted(){

    },
    methods: {
        getRfDetails() {
            this.$store.dispatch('getRfDetails',this.GET_RF_DETAILS.id)
        },
        clearFields() {
            this.loading = true;
            this.files = [];
            this.repairFormId = '';
            this.noDataText = '';
            this.withDelete = false;
            this.rf_number = '';
            this.batchDownload = false;
            this.downloadOnFlight = false;
            this.selectedImages = [];
        },

        date(item) {
            if(!!item) {
                return this.$dayjs(item.created_at).format('YYYY-MM-DD, hh:mm:ss a');
            }

            return '';
        },
        showFile(item) {
            this.viewFile = true;
            this.itemPath = item.path
            this.itemName = item.name
        },
        downloadFile(item) {
            swal.fire({
                text: 'Are you sure you want to download?',
                icon: 'question',
                dangerMode: true,
                buttons: true,
                showCancelButton:true,
            }).then(async(result) => {
                if(result.isConfirmed) {
                    let uri = `${process.env.VUE_APP_API_HOST}/img/service?image_name=${item.name}`
                    fetch(uri)
                    .then(resp => resp.blob())
                    .then(blobobject => {
                        const blob = window.URL.createObjectURL(blobobject);
                        const anchor = document.createElement('a');
                        anchor.style.display = 'none';
                        anchor.href = blob;
                        anchor.download = item.name;
                        document.body.appendChild(anchor);
                        anchor.click();
                        window.URL.revokeObjectURL(blob);
                    })
                    .catch(() => console.log('An error in downloading the file sorry'));
                        }
                    });
        },
        deleteFile(item) {
            swal.fire({
                text: 'Are you sure you want to delete?',
                icon: 'question',
                dangerMode: true,
                buttons: true,
                showCancelButton:true,
            }).then((confirm) => {
                if(confirm.isConfirmed) {
                    let payload = {
                        fileId: item.id
                    }
                    this.$store.dispatch('serviceDeleteFile',payload).then(response=>{
                        swal.fire("", response.data.msg, "success");
                        this.getRfDetails()
                    }).catch(e => {
                        swal.fire("", e.data.error, "error");
                    })
                }
            });
        },
        closeViewFile() {
            this.reset();
            this.viewFile = false;

        },
        scalingHandler(e) {
            // console.log(e);
        },
        reset() {
            this.$refs.zoomer.setData({
                scale: 1,
                originX: 0,
                originY: 0,
                translateX: 0,
                translateY: 0
            });
        },
        downloadSelectedImages() {
            this.downloadOnFlight = true;

            let zip = new this.$jszip();
            let dateToday = this.$dayjs().format('YYYY-MM-DD');
            let name = !!this.rfNumber ? this.rfNumber : 'Images'
            let vm = this;

            if(this.selectedImages == 0) {
                swal.fire('', 'Please select images', 'warning').then(confirm=>{
                    this.downloadOnFlight = false;
                });
            } else {
                this.selectedImages.forEach((x,i)=>{
                    let path = this.origin+x.path;
                    let imgId = `img${i}`;

                    let img = document.getElementById(imgId);

                    let canvas = document.createElement("canvas")
                    let ctx = canvas.getContext("2d");
                    canvas.height = img.height;
                    canvas.width = img.width;
                    ctx.drawImage(img, 0, 0);

                    let dataURL = canvas.toDataURL("image/jpg");

                    let base64Img = dataURL.replace(/^data:image\/(png|jpg);base64,/, "")
                    zip.file(x.name, base64Img, { base64: true });
                });

                zip.generateAsync({type:"base64"})
                    .then(async function(content) {
                    // see FileSaver.js
                    // this.$filesaver.saveAs(content, `china-defective-report-images-${dateToday}.zip`);
                    // location.href="data:application/zip;base64," + content;

                    let link = document.createElement('a')
                    link.download = `${name}-${dateToday}`;
                    link.href="data:application/zip;base64," + content;
                    link.click();

                    vm.downloadOnFlight = false;
                });
            }
        },
        async newImagePath(path){
            let uri = `${process.env.VUE_APP_API_HOST}/img/service?image_name=${this.itemName}`
            fetch(uri)
            .then(resp => resp.blob())
            .then(blobobject => {
                const blob = window.URL.createObjectURL(blobobject);
                this.sourceFrame = blob;
            })
        }
    }
}
</script>

<style scoped>
    .iframe-container {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    }

    .responsive-iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }

    .v-input--selection-controls {
    margin-top: 0px;
    padding-top: 0px;
    }
</style>
