<template>
    <div class="tabs-body-color">
        <v-container fluid class="pa-0">
            <v-row class="pt-4 pl-2" v-if="userAccess.create && isActive_tab != 'accountability-high' && isActive_tab != 'accountability-low' " >
                <v-col cols="12" class="d-flex no-block align-items-center">
                    <div class="w-100 d-flex pl-0 mr-4">
                        <v-btn class="ml-1 mb-2" @click="AddDialog()" v-if="isActive_tab != 'assetslist-basket' && isActive_tab != 'assetslist'" >
                            <v-icon>mdi-plus</v-icon>Add
                        </v-btn>
                        <v-btn class="ml-1 mb-2 ml-auto" @click="exportAssets()">
                            <v-icon>mdi-file-export</v-icon>Export
                        </v-btn>
                        <!-- <v-btn class="ml-1 mb-2" @click="batchAdditionDialog()">
                            <v-icon>mdi-plus</v-icon>Batch Addition
                        </v-btn> -->
                    </div>
                </v-col>
                <v-col>
                    <AssetManagementBatchAddComponent :trigger_dialog="batch_addition" @closeDialog="closeDialog"></AssetManagementBatchAddComponent>
                </v-col>
            </v-row>
            <v-row>
                <AdminAssetManagementTableComponent v-if="(isActive_tab == 'assetslist' || isActive_tab == 'assetslist-basket' || isActive_tab == 'accountability-high' || isActive_tab == 'accountability-low' || isActive_tab == 'fixed-assets' || isActive_tab == 'low-value-assets') && GET_ADMIN_TAB_IS_ACTIVE == isActive_tab" :refresh_table="refresh_table"></AdminAssetManagementTableComponent>
                <AdminAssetManagementOtherTableComponent v-if="(isActive_tab == 'classification' || isActive_tab == 'location' || isActive_tab == 'brand' || isActive_tab == 'status' || isActive_tab == 'category') && GET_ADMIN_TAB_IS_ACTIVE == isActive_tab"> </AdminAssetManagementOtherTableComponent>
                <AssetManagementBatchAdditionComponent v-if="GET_ASSET_MANAGEMENT_BATCH_DIALOG"/>
                <!-- <AdminAssetsManagementAccountabilityComponent v-if="isActive_tab == 'accountability'"></AdminAssetsManagementAccountabilityComponent> -->
            </v-row>
        </v-container>
        <!-- <v-dialog v-if="isActive_tab == 'assetslist'" v-model="dialog" persistent :width="width" scrollable>
            <v-card>
                <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                    <span class="text-h5">Select Purchase Order</span>
                    <v-row class="m-0">
                        <v-col cols="pull-right-10 p-2">
                            <v-btn
                            text
                            icon
                            small
                            color="gray"
                            class="float-right"
                            @click="closeDialog()"
                            >
                            <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="poitems"
                    :search="search"
                    :options.sync="options"
                    :server-items-length="totalItems"
                    :items-per-page="5"
                    :loading="!loaded"
                    :footer-props="{
                            itemsPerPageOptions: itemsPerPageOptions,
                    }"
                    loading-text="Loading... Please wait"
                    fixed-header
                >
                <template v-slot:[`item.status`]="{ item }">
                <a  class="ml-1 badge badge-saved text-white" v-if="item.status == 1">saved</a>
                <a  class="ml-1 badge badge-warning text-white" v-if="item.status == 2">admin</a>
                <a  class="ml-1 badge badge-success text-white" v-if="item.status == 3">exec</a>
                <a  class="ml-1 badge badge-danger text-white" v-if="item.status == 4">close</a>
                </template>
                <template v-slot:[`item.created_at`]="{ item }">
                    <span>{{ moments(item.created_at) }}</span>
                </template>
                <template v-slot:[`item.action`]="{ item,index }">
                    <v-menu >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn x-small color="success" :class="'btn-selected-item-'+index"  :disabled="item.status == 4"  v-bind="attrs"
                            v-on="on" @click="getAllAdminItems(item,index)">
                                select
                            </v-btn>
                        </template>

                        <v-card >
                            <v-card-text style="height:200px;overflow-y:auto;" >


                                    <v-alert
                                    border="left"
                                    colored-border
                                    color="orange"
                                    elevation="2"
                                    v-for="(item, index) in item_list" :key="index"
                                    >
                                    <v-container fluid>
                                        <v-row>
                                            <v-col>
                                                <img :src="URL+'/'+(item.image_path?item.image_path:item.other_item_image_path)" class="image-fluid fluid" width="50%"/>
                                            </v-col>
                                            <v-col>

                                            <p class="text-h6">{{ item.item_name }}</p>
                                            <p>&#x20b1;{{ item.price | currency }}</p>
                                            <v-btn color="orange" small @click="selectedItem(index)">Select item</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>




                                    </v-alert>

                            </v-card-text>
                        </v-card>
                        <v-list>
                            <v-list-item-group
                            v-model="selectedItemprice"
                            color="primary"
                        >
                        <template v-for="(item, index) in item_list">
                                <v-divider
                                v-if="item.divider"
                                :key="index"
                                :inset="item.inset"
                                ></v-divider>
                                <v-list-item @click="selectedItem(index)">
                                <v-list-item-avatar size="50">
                                    <v-img :src="URL+'/'+(item.image_path?item.image_path:item.other_item_image_path)"></v-img>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title v-html="item.item_name">
                                    </v-list-item-title>
                                    <v-list-item-subtitle v-html="item.price"></v-list-item-subtitle>
                                </v-list-item-content>
                                </v-list-item>
                            </template>
                            </v-list-item-group>
                            </v-list>
                        </v-menu>
                        </template>
                        </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog> -->

    </div>
</template>
<script>

import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
import AssetManagementBatchAddComponent from "@/views/main/modules/Admin/AssetManagement/AssetManagementBatchAdditionComponent.vue";
import AdminAssetManagementTableComponent from '@/views/main/modules/Admin/AssetManagement/AssetManagementTableComponent.vue';
import AdminAssetManagementOtherTableComponent from '@/views/main/modules/Admin/AssetManagement/AssetManagementOtherTableComponent.vue';
import AdminAssetsManagementAccountabilityComponent from '@/views/main/modules/Admin/AssetManagement/AssetsManagementAccountabilityComponent.vue';
import ItemsViewComponent from '@/views/main/layouts/dialogs/itemsViewComponent.vue';
import AssetManagementBatchAdditionComponent from '@/views/main/modules/Admin/AssetManagement/AssetManagementBatchAddition.vue'
import { mapGetters } from 'vuex'
export default {
    inject: ['theme'],
    components: {
        AdminAssetManagementTableComponent,
        AdminAssetManagementOtherTableComponent,
        ItemsViewComponent,
        AdminAssetsManagementAccountabilityComponent,
        AssetManagementBatchAdditionComponent,
        AssetManagementBatchAddComponent
    },
    mixins: [SharedFunctionsComponentVue],
    props:["isActive_tab"],
    data() {
        return {
            batch_dialog: false,
            loading:false,
            itemsTreeview:[],
            vendor:'',
            dialog:false,
            active: [],
            searchItems:'',
            avatar: null,
            open: [],
            userAccess:{
                create:false
            },
            selectedItemprice:-1,
            totalItems: 0,
            options: {},
            poitems: [],
            itemsPerPageOptions: [5, 10, 15, 50, 100],
            itemsPerPage: 10,
            search: "",
            filter:{
            search:"",
            location:['headoffice','stores']
            },
            refresh_table:false,
            item_list:[],
            moments:(date)=>{
                return this.$dayjs(date).format('YYYY-MM-DD')
            },
            refresher:false,
            awaitingSearch: false,
            headers: [
                { text: "PO #", align: "left", value: "id",sortable:false },
                { text: "Date", align: "left", value: "created_at",sortable:false },
                { text: "Prepared By", align: "left", value: "user.name" ,sortable:false},
                { text: "Supplier", align: "left", value: "vendor_name",sortable:false },
                { text: "Location", align: "left", value: "location.name",sortable:false },
                { text: "Status", align: "left", value: "status",sortable:false },
                { text: "Action", align: "center", value: "action",sortable:false},
            ],
            loaded:false,
            URL:process.env.VUE_APP_API_HOST,
            batch_addition:false
        }
    },
    computed: {
        selected () {
        if (!this.active.length) return undefined

        const id = this.active[0]

        return id;
        },
            items () {
            return [
            {
                name: '',
                children: [],
            },
            ]
        },
        ...mapGetters([
            'USER_ACCESS',
            "ACTION",
            "DIALOGS",
            "GET_ADMIN_SUPPLIER_SELECTION",
            "GET_ADMIN_CLASSIFICATION_SELECTION",
            "GET_ADMIN_LOCATION",
            "GET_STATUS_SELECTION",
            "GET_LIST_DEPARTMENT",
            "GET_ADMIN_CLASSIFICATION_COUNT",
            "GET_EDIT_ADMIN_ASSETS_DETAILS",
            "GET_NEW_ADMIN_ASSETS_TABLE",
            "GET_ADMIN_DISPATCH",
            "GET_EMPLOYEE_DATA_SELECTION",
            "GET_ADMIN_TABLE_COUNT",
            "GET_CLASSIFICATION_TREE_VIEW",
            "GET_ADMIN_TAB_IS_ACTIVE",
            "GET_ASSET_MANAGEMENT_BATCH_DIALOG",
            "SELECTED"
        ]),
        width() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return "100%"
                case 'sm': return "100%"
                case 'md': return "50%"
                case 'lg': return "50%"
                case 'xl': return "50%"
            }

        },
    },
    mounted() {
        this.$store.dispatch('getLists');
        this.$store.dispatch('getPvCompanies');
        this.$store.dispatch('getStoreBranchesWithoutAccess');
        this.$store.dispatch('getDepartment')
        this.$store.dispatch("getAdminClassificationSelection");
        this.$store.dispatch("getAdminLocationSelection");
        this.$store.dispatch("getAdminCategories");
        this.$store.dispatch('getAdminItemUomSelection');
        this.$store.dispatch('getAdminSupplierSelection');
        this.$store.dispatch('getAdminWarehouseCodeDropdown');
        this.$store.dispatch('getAdminSupplierSelection');
        this.$store.dispatch('getCOASelection');
        this.$store.dispatch("getAdminAssetConditionSelection");
        this.$store.dispatch('getEmployeeDataDropdown');
        this.$store.dispatch('getAdminLocationSelection');
        this.$store.dispatch('getEmployeeDataDropdown');
    },
    methods: {
        exportAssets() {
            this.$store.commit('EXPORT_ASSETS', true);
        },
        batchAdditionDialog(){
            this.$store.commit('ASSET_MANAGEMENT_BATCH_DIALOG', true);
        },
        closeDialog(){
            this.dialog = false
            this.refresh_table = true
            this.batch_addition = false
        },
        async AddDialog(){
            if(['assetslist-basket','assetslist'].includes(this.isActive_tab)){
                this.refresh_table = false
                this.batch_addition = true
            }else{
                await this.$store.dispatch('getAdminBrandSelection')
                // await this.$store.dispatch('getWarehouseItemSelections')

                this.$store.commit("DIALOG", true);
                this.$store.commit("ACTION", "Submit");
            }
        },
        getAllPurchaseOrder(filter = null) {
            this.poitems = [];
            this.loaded = false;
            let payload = {
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                search: this.filter.search,
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                location:this.filter.location,
                status:'APPROVED'
            };
                this.$store.dispatch('getAllPurchaseOrder', payload).then((response) => {
                this.poitems = response.data.data;
                this.loaded = true;
                this.totalItems = response.data.total;
                this.$store.commit("NEW_PURCHASE_ORDER_TABLE", false);
            }).catch((e)=>{
                this.loaded = true;
            });
        },
        getAllAdminItems(po_number,index){
            this.loading = false;
            this.item_list = []
            let self = this
            let payload = {
                id:this.selected?this.selected:0,
                vendor_id:this.vendor,
                search:this.searchItems,
                po_number:po_number.id,
                assetsListModule:this.assetsListModule,
            }
            this.$store.dispatch("getPONumberAssetsSelectionn",{id:this.vendor});
            this.$store.dispatch('getAdminItems',payload).then((response)=>{
            response.forEach(e=>{
                e.divider = true
                e.inset = true
                e.item_name = `${e.item_name?e.item_name:e.description}`;
                e.price = e.price;
                self.item_list.push(e)
            })
        })
    },
    selectedItem(index){
        this.$store.commit('SELECTED_ADMIN_ITEMS',this.item_list[index]);
        this.$store.commit("DIALOG", true);
        this.$store.commit("ACTION", "Submit");
    },
    },
    watch:{
        USER_ACCESS:{
            handler(val){
                if(val != "fail"){
                        this.userAccess.create = false;
                            val.forEach(e => {
                        if (e.actions_code == 'create') {
                            this.userAccess.create = true;
                        }
                        });
                    }
                }
            },
        options: {
            handler() {
            this.getAllPurchaseOrder();
            },
            deep: true,
        },
    }
};
</script>
<style>
.card-img-top {
    width: 100%;
    height: 5vw;
    background-size:cover;
}</style>
