<template>
    <div class="w-100">
        <v-app id="my_vue_app">
            <v-card>
                <v-card-title>
                    <v-container>
                        <v-row>
                            <v-spacer/>
                            <v-col cols="12" sm="12" md="3" class="d-flex justify-content-end align-items-center">
                                <a
                                    v-for="(status, index ) in status_list" :key="index"
                                    :class="`ml-1 badge badge-${status.text} ${status.value == selected_status ? `selected` : `non-selected`}`" @click="selectedStatus(status)"
                                    >
                                {{ status.text ? status.text.replace('-'," ") : null  }}
                                </a>
                            </v-col>
                        </v-row>
                        <v-row class="mb-2">
                            <v-col cols="3">
                                <v-autocomplete
                                    v-model="filter.department_ids"
                                    @change="getOBRecordsTable({ query: search })"
                                    :items="GET_LIST_DEPARTMENT"
                                    label="Department"
                                    auto-select-first
                                    deletable-chips
                                    small-chips
                                    clearable
                                    multiple
                                    outlined
                                    required
                                    dense
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-spacer></v-spacer>
                        </v-row>
                        <v-row>
                            <v-spacer></v-spacer>
                            <v-col class="row col-sm-12 col-md-4 mr-3 align-items-end">
                                <v-text-field
                                    id="searchBar"
                                    v-model="filter.search"
                                    label="Search Timekeeping #"
                                    single-line
                                    hide-details
                                    @change="searchInput()"
                                >
                                </v-text-field>
                                <v-icon @click="searchInput()">mdi-magnify</v-icon>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-title>
                <v-container fluid>
                    <v-data-table
                        :headers="datatable.headers"
                        :items="datatable.items"
                        :options.sync="datatable.options"
                        :server-items-length="datatable.totalItems"
                        :items-per-page="10"
                        :loading="loading.datatable"
                        :footer-props="{
                            itemsPerPageOptions: datatable.itemsPerPageOptions
                        }"
                        dense
                        loading-text="Loading... Please wait"
                        fixed-header
                    >
                        <template v-slot:[`item.action`]="{ item }">
                            <v-tooltip bottom color="#f69f1a">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn text icon color="orange"
                                            v-if="userAccess.view"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                        <v-icon

                                            class="btn-action"
                                            small
                                            @click="viewTimekeepingRecords(item)"
                                        >mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                                <span>View Offical Business Record</span>
                            </v-tooltip>
                            <v-tooltip bottom color="blue">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn text icon color="blue"
                                        v-if="userAccess.edit && item.status != 2"
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                        <v-icon
                                            class="btn-action"
                                            small
                                            @click="editTimekeepingRecords(item)"
                                            >mdi-pencil</v-icon
                                        >
                                    </v-btn>
                                </template>
                                <span>Edit Offical Business Record</span>
                            </v-tooltip>
                            <v-tooltip bottom color="red">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn text icon color="red"
                                        v-if="userAccess.delete && item.status != 2"
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                        <v-icon
                                            class="btn-action"
                                            small
                                            @click="deleteOBRecord(item.id)"
                                            >mdi-delete</v-icon
                                        >
                                    </v-btn>
                                </template>
                                <span>Delete Offical Business Record</span>
                            </v-tooltip>
                        </template>
                        <template v-slot:[`item.status`]="{item}">
                            <span :class="`badge badge-${checkStatus(item.status)}`">{{ checkStatus(item.status) }}</span>
                        </template>
                    </v-data-table>
                </v-container>
            </v-card>
            <EmployeeBiometricsDataComponentViewVue/>
            <BiometricsSummaryComponentViewVue/>
            <v-dialog v-model="loading.dialog" width="500" persistent>
                <v-card>
                    <v-card-title >
                        <span style="font-size:12px">
                            {{ loading.text }}
                        </span>
                    </v-card-title>
                    <v-card-text>
                        <v-progress-linear
                            v-model="loading.value"
                            :buffer-value="loading.value"
                            color="green"
                            stream
                        >
                        </v-progress-linear>
                        {{Math.ceil(loading.value)}}%
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-app>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2';
import EmployeeBiometricsDataComponentViewVue from '@/views/main/layouts/dialogs/HR/Timekeeping/EmployeeBiometricsDataComponentView.vue';
import BiometricsSummaryComponentViewVue from '@/views/main/layouts/dialogs/HR/Timekeeping/BiometricsSummaryComponent.vue';
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
export default {
    mixins:[SharedFunctionsComponentVue],
    data(){
        return {
            loaded: false,
            search: "",
            filter:{
                search:"",
                dialog: false,
                department_ids: [],
                areas: [],
                host_ids: [],
                with_inactive: 0
            },

            datatable:{
                headers:
                    [
                        { text: 'Employee Name', align: 'start', value: 'employee_name'},
                        { text: 'Department', align: 'start',value: 'department_name' },
                        { text: 'Date Filed', align: 'start', value: 'date_of_filing'},
                        { text: 'Created By', align: 'start', value: 'created_by_name'},
                        { text: 'Pending Count', align: 'end', value: 'pending_count'},
                        { text: 'Approved Count', align: 'end', value: 'approved_count'},
                        { text: 'Rejected Count', align: 'end', value: 'rejected_count'},
                        { text: 'Status', align: 'center', value: 'status'},
                        { text: "Action", align: "center", value: "action" }
                    ],
                items:[],
                options:{},
                totalItems: 0,
                itemsPerPageOptions: [5, 10, 15, 50, 100],
                itemsPerPage: 10,
            },

            loading:{
                datatable: false,
                button: {
                    filter: false,
                    biometrics: false
                },
                biometric_status: false,


                dialog: false,
                bufferValue: 100,
                value: 0,
                text: 'Extracting Biometrics Data: ',
            },

            userAccess: {
                view: false,
                create: false,
                edit: false,
                delete: false,
            },

            dropdowns:{
                departments_lists: [],
                location_lists: [],
                datatable: {
                    area_headers: [
                        { text: 'Area', align: 'start', value: 'text'},
                        { text: 'Status', align: 'start', value: 'status'},
                    ],
                    area_items: [],
                    area_items_expanded: [],

                    port_headers: [
                        { text: 'Port', align: 'start', value: 'ports'},
                        { text: 'Status', align: 'start', value: 'status'},
                    ],
                    port_items: []
                },
                timekeeping_periods: [],
                store_branches: []
            },

            selected_status: '',
            isDepartmentView: 0,
            component_dispatches:[
                'getDepartment',
			],
            hr_user_department_access: [],
            status_list: [
                {
                    text: 'all',
                    value: 0
                },
                {
                    text: 'saved',
                    value: 1
                },
                // {
                //     text: 'approved',
                //     value: 2
                // },
                {
                    text: 'confirmed',
                    value: 2
                },
            ],
            areas:[],
            selected_status: 0,
            form:{
                api_list: []
            },
            search: '',
            openCount: 0,
            bioStatusLoaded: true,
            bool: false,
            selectedRange:{
                from: this.$dayjs().startOf('week').format('YYYY-MM-DD'),
                to: this.$dayjs().endOf('week').format('YYYY-MM-DD'),
                from_menu: false,
                to_menu: false,
            },
            currentDate: this.$dayjs().subtract(1, 'd').format('YYYY-MM-DD'),
            checked_department_ids: []
        }
    },
    async mounted(){
        await this.getDepartmentAccess();
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        this.getSearchFromUrl();
        await this.getAllDropdowns();
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS',
            'GET_TIMEKEEPING_NEW_DATA',
            'USERACCOUNT_LOAD',
            'GET_HR_TAB_IS_ACTIVE',
            'GET_HR_DISPATCH',
            'GET_SELECTED_DATE_RANGE',
            'GET_LIST_DEPARTMENT',
        ]),
    },
    components:{
        EmployeeBiometricsDataComponentViewVue,
        BiometricsSummaryComponentViewVue
    },
    methods:{
        async getDepartmentAccess(){
            let payload ={
                access: this.USER_ACCESS.map(e => e.actions_code)
            }
            await this.$store.dispatch('checkDepartmentAccess', payload).then(response=>{
                this.checked_department_ids = response;
            })
        },
        checkStatus(status){
            let name = this.status_list.find((x) => x.value == status).text;
            return name;
        },
        selectedStatus(data){
            this.selected_status = data.value
            this.getOBRecordsTable({ query: this.search })
        },
        async getOBRecordsTable(filter = null){
            if(this.GET_HR_TAB_IS_ACTIVE == 'ob_module'){
                this.datatable.items = [];
                this.loading.datatable = true;
                let payload = {
                    page                : this.datatable.options.page,
                    itemsPerPage        : this.datatable.options.itemsPerPage,
                    search              : this.filter.search,
                    sortBy              : this.datatable.options.sortBy,
                    sortDesc            : this.datatable.options.sortDesc,
                    status              : this.selected_status,
                    filter              : filter,
                    selected_status     : this.selected_status,
                    department_id       : this.filter.department_ids,
                    department_ids      : this.checked_department_ids,
                    url                 : this.GET_HR_DISPATCH.get
                };
                await this.$store.dispatch('hrUrlGet', payload).then((response) => {
                    this.datatable.items                    = response.data.data;
                    this.datatable.totalItems               = response.data.total;
                    this.loading.datatable                  = false;
                }).catch((err)=>{
                    Swal.fire({
                        title: 'Error',
                        text: 'Network Error',
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                    console.log(err)
                });
            }
        },
        searchInput(){
            this.datatable.options.page = 1
            this.getOBRecordsTable({ query: this.search });
        },
        viewTimekeepingRecords(item){
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", "View");
            let payload = {
                id: item.id,
                url: 'showOBRecordDetails'
            }
            this.$store.dispatch('hrUrlPost', payload).then((response)=>{
                response.pending_count = item.pending_count
                response.approved_count = item.approved_count
                response.rejected_count = item.rejected_count
                this.$store.commit("TIMEKEEPING_RECORD_VIEW", response);
            }).catch((err)=>{
                console.log(err);
                Swal.fire(
                    "Network Error",
                    "",
                    "error"
                )
            })
            // this.$store.commit("TIMEKEEPING_RECORD_VIEW", item);
        },
        editTimekeepingRecords(item){
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", "Update");

            let payload = {
                id: item.id,
                url: 'showOBRecordDetails'
            }
            this.$store.dispatch('hrUrlPost', payload).then((response)=>{
                this.$store.commit("TIMEKEEPING_RECORD_VIEW", response);
            }).catch((err)=>{
                console.log(err);
                Swal.fire(
                    "Network Error",
                    "",
                    "error"
                )
            })
        },
        getSearchFromUrl() {
            if (Object.keys(this.$route.query).length != 0) {
                Object.keys(this.$route.query).forEach(e=>{
                if(e != 'for_approval')
                    this.filter.search = atob(this.$route.query[e]);
                })
                this.searchInput();
            }
        },
        viewEmployeeBioTimeData(data){
            // let period = this.getDatesFromRange(this.GET_SELECTED_DATE_RANGE.from, this.GET_SELECTED_DATE_RANGE.to, "array");
            let period = [this.GET_SELECTED_DATE_RANGE.from, this.GET_SELECTED_DATE_RANGE.to]
            let payload = {
                emp_code: data.emp_code,
                period: period
            }
            this.$store.dispatch('viewEmployeeBioTime', payload).then((res)=>{
                if(res.biometricdata.length != 0){
                    this.$store.commit('EMPLOYEE_BIOMETRICS_DIALOG', true)
                }else{
                    Swal.fire({
                        title: "No Biometrics for the selected period.",
                        text: "",
                        icon: "warning"
                    })
                    return false;
                }
            }).catch((err)=>{
                Swal.fire(
                    "Error",
                    "",
                    "error"
                );
                console.log(err)
            })
        },
        closeEmployeeFilterDialog(){
            this.filter.dialog = false;
            this.resetFilters()
        },
        async openEmployeeFilterDialog(){
            // let payload = {
            //     timekeeping_period_start: this.GET_SELECTED_DATE_RANGE.from,
            //     timekeeping_period_end: this.GET_SELECTED_DATE_RANGE.to
            // }
            // this.$store.dispatch('checkExistingTimekeepingRecord',payload).then((res)=>{
            //     if(res == 1){
            //         Swal.fire(
            //             "Existing Timekeeping Record Found for selected Period!",
            //             "",
            //             "warning"
            //         )
            //     }else{
                    this.setPayrollPeriod()
                    this.loading.button.filter = true;
                    this.filter.dialog = true;
                    this.loading.button.filter = false;
            //     }
            // }).catch((err)=>{
            //     Swal.fire(
            //         "Error",
            //         "",
            //         "error"
            //     );
            //     console.log(err)
            // })
        },
        async getAllDropdowns(){
            await this.component_dispatches.forEach(el=>{
                this.$store.dispatch(el)
            })
        },
        resetFilters(){
            Object.assign(this.filter, {
                // dialog: false,
                department_ids:[],
                areas: [],
                port: null,
                host_ids: [],
                with_inactive: 0
            })
        },
        deleteOBRecord(id){
            Swal.fire({
                icon: 'question',
                text: "Are you sure you want to Delete?",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#397373",
                cancelButtonColor: "#397373",
            }).then((result) => {
                if (result.isConfirmed) {
                    let payload = {
                        id: id,
                        url: this.GET_HR_DISPATCH.delete,
                    }
                    this.$store.dispatch('hrUrlDelete', payload).then((success) => {
                        Swal.fire(
                            success,
                            "",
                            success.toLowerCase()
                        );
                    });
                }
            });
        },
        toggleSelection(item) {
            this.$set(item, 'selected', !item.selected);
        },
        resetCheckboxes(){
            this.dropdowns.datatable.area_items.forEach(item => {
                item.selected = false;
            });
        },
        setPayrollPeriod(){
            let currentDate = this.$dayjs();

            let startOfMonth = currentDate.clone().startOf('month');

            // if (currentDate.date() <= 15) {
                this.selectedRange.from = startOfMonth.format('YYYY-MM-DD');
                let endOfMonth15th = startOfMonth.clone().date(15);
                this.selectedRange.to = endOfMonth15th.isAfter(currentDate) ? currentDate.clone().subtract(1, 'day').format('YYYY-MM-DD') : endOfMonth15th.format('YYYY-MM-DD');
            // } else {
            //     this.selectedRange.from = startOfMonth.clone().date(16).format('YYYY-MM-DD');
                let endOfMonth = startOfMonth.clone().endOf('month');
                this.selectedRange.to = endOfMonth.isAfter(currentDate) ? currentDate.clone().subtract(1, 'day').format('YYYY-MM-DD') : endOfMonth.format('YYYY-MM-DD');
                // this.selectedRange.to =  endOfMonth.format('YYYY-MM-DD');
            // }
        },
        validateRange(){
            if(this.selectedRange.from && this.selectedRange.to){
                if(this.selectedRange.from > this.selectedRange.to){
                    Swal.fire(
                        "Date Range Not Valid!",
                        "",
                        "warning"
                    )
                    this.setPayrollPeriod()
                    return false
                }
            }
            this.$store.commit('SELECTED_DATE_RANGE', this.selectedRange);
        },
        hasStoresInList(){
            return !this.dropdowns.departments_lists.map((e)=> e.code).includes('ST')
        }
    },
    watch:{
        'datatable.options': {
            handler() {
                this.getOBRecordsTable({ query: this.search });
            },
            deep: true,
        },
        GET_TIMEKEEPING_NEW_DATA:{
            handler(val){
                if(val){
                    this.getOBRecordsTable({ query: this.search });
                    this.$store.commit('TIMEKEEPING_NEW_DATA', false)
                }
            }
        },
        USER_ACCESS: {
            handler(val) {
                if(val != "fail"){
                    this.userAccess.edit = false;
                    this.userAccess.delete = false;
                    this.userAccess.view = false;
                    this.userAccess.create = false;
                    val.forEach(e => {
                        if (e.actions_code == 'edit') {
                            this.userAccess.edit = true;
                        } else if (e.actions_code == 'delete') {
                            this.userAccess.delete = true;
                        } else if (e.actions_code == 'view') {
                            this.userAccess.view = true;
                        } else if (e.actions_code == 'create') {
                            this.userAccess.create = true;
                        }
                    });
                }
            }
        },
        GET_LIST_DEPARTMENT:{
            handler(val){
                this.dropdowns.departments_lists = val
            }
        },
        GET_BIO_METRIC_LOCATION_SELECTION:{
            handler(val){
                this.dropdowns.location_lists = val
            }
        },
        GET_HR_USER_DEPARTMENTS:{
            handler(val){
                this.hr_user_department_access = val;
                let filtered_departments = this.dropdowns.departments_lists.filter(x=> this.hr_user_department_access.includes(x.value) )
                this.dropdowns.departments_lists = filtered_departments
            }
        },
        'filter.department_ids':{
            handler(val){
                let filtered_selection = [];
                if (val.length) {
                    val.forEach((e, i) => {
                        let filteredAreas = this.GET_LIST_DEPARTMENT.find((x) => x.value == e).areas;

                        // Check if the array already exists in filtered_selection
                        filteredAreas.forEach((area) => {
                            if (!filtered_selection.find((x)=> x.value === area.value)) {
                                filtered_selection.push(area);
                            }
                        });
                    });
                    this.dropdowns.location_lists = filtered_selection;
                }else{
                }
            }
        },
        'filter.dialog':{
            handler(val){
                if(!val){
                    this.openCount = 0;
                    this.resetCheckboxes()
                }
            }
        },
        'dropdowns.datatable.area_items':{
            deep: true,
            handler(val){
                if(val.length > 0){
                    this.filter.areas = val.filter((x) => x.selected == true)
                }
            }
        },
        GET_TIMEKEEPING_PERIOD_SELECTION:{
            handler(val){
                this.dropdowns.timekeeping_periods = val
            }
        },
        GET_ALL_USERS_SELECT:{
            handler(val){
                let hr_id = this.GET_LIST_DEPARTMENT.find((e) => e.code == 'HR-A').value;
                this.dropdowns.hr_employee_lists = val.filter((x)=> x.department_id == hr_id);
            }
        },
        GET_STORE_BRANCHES_SELECTION:{
            handler(val){
                this.dropdowns.store_branches = val;
            }
        },
        GET_HR_TAB_IS_ACTIVE:{
            handler(val){
                this.getOBRecordsTable({ query: this.search });
            }
        }
    }
}
</script>
<style scoped>
    .selected {
        opacity: 100%;
    }

    .non-selected {
        opacity: 40%;
    }

    .custom-loader {
        animation: loader 1s infinite;
        display: flex;
    }
    @-moz-keyframes loader {
        from {
        transform: rotate(0);
        }
        to {
        transform: rotate(360deg);
        }
    }
    @-webkit-keyframes loader {
        from {
        transform: rotate(0);
        }
        to {
        transform: rotate(360deg);
        }
    }
    @-o-keyframes loader {
        from {
        transform: rotate(0);
        }
        to {
        transform: rotate(360deg);
        }
    }
    @keyframes loader {
        from {
        transform: rotate(0);
        }
        to {
        transform: rotate(360deg);
        }
    }


</style>
