<template>
    <v-form ref="form" v-model="valid" lazy-validation>
        <!-- Functions -->
        <v-card class="px-3 rounded-0">
            <v-card-subtitle>
                <v-row class="ml-0">
                    <v-col cols="10">
                        <v-tabs v-model="summary_tab" color="cyan" slider-color="cyan" class="col-12 p-0" show-arrows>
                            <v-tab
                                v-for="(item) in rb_types"
                                :key="item.value"
                                ripple
                                @click="()=>{
                                    filters.recurring_bill_type_id = item.value;
                                    getRBSummary()
                                }"
                            >
                                {{ item.text }}
                            </v-tab>
                        </v-tabs>
                    </v-col>
                    <v-spacer/>
                    <v-col cols="2" class="d-flex align-items-center justify-content-end"> 
                        <span class="badge ml-1 text-white" 
                            :style="{ backgroundColor: filters.opeb == 0 ? '#1e1e1c' : '#6c757d', cursor: 'pointer' }" 
                            @click="chipStatus(0)">ALL</span>
                        <span class="badge ml-1 text-white" 
                            :style="{ backgroundColor: filters.opeb == 1 ? '#007bff' : '#6c757d', cursor: 'pointer' }" 
                            @click="chipStatus(1)">OPEB</span>
                        <span class="badge ml-1 text-white" 
                            :style="{ backgroundColor: filters.opeb == 2 ? '#28a745' : '#6c757d', cursor: 'pointer' }" 
                            @click="chipStatus(2)">PROCESSED</span>
                    </v-col>
                </v-row>
                <v-row class="ml-0">
                    <v-col lg="2" v-if="summary_tab == 0">
                        <v-autocomplete
                            v-model="filters.recurring_bill_type_id"
                            :items="GET_RB_TYPES"
                            label="Types"
                            outlined
                            dense
                            auto-select-first
                            hide-details
                            clearable
                        />
                    </v-col>
                    <v-col lg="2">
                        <v-autocomplete
                            v-model="filters.vendor_id"
                            :items="GET_ADMIN_SUPPLIER_SELECTION"
                            label="Vendor"
                            outlined
                            dense
                            auto-select-first
                            hide-details
                            clearable
                        />
                    </v-col>
                    <v-col lg="2">
                        <v-autocomplete
                            v-model="filters.company_id"
                            :items="GET_PV_COMPANIES_SELECTION"
                            label="Company"
                            outlined
                            dense
                            auto-select-first
                            hide-details
                            clearable
                            @change="insertStore(1)"
                        />
                    </v-col>
                    <v-col lg="2">
                        <v-autocomplete
                            v-model="filters.store_branches_id"
                            :items="branchItemsForFilter"
                            label="Branch"
                            no-data-text="No Stores Found"
                            outlined
                            dense
                            auto-select-first
                            hide-details
                            clearable
                            @change="insertCompany(filters.store_branches_id, 1)"
                        />
                    </v-col>
                    <v-col cols="1" class="pl-2">
                        <v-btn @click="getRBSummary()">
                            FILTER
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="ml-0">
                    <v-col lg="2">
                        <v-autocomplete
                            v-model="filters.paid"
                            :items="paid_status_items"
                            label="Paid/ Unpaid"
                            outlined
                            dense
                            auto-select-first
                            hide-details
                            clearable
                        />
                    </v-col>
                    <v-col lg="2">
                        <v-autocomplete
                            v-model="filters.year"
                            :items="year_items"
                            label="Year"
                            outlined
                            dense
                            auto-select-first
                            hide-details
                        />
                    </v-col>
                    <v-col lg="4">
                        <v-autocomplete
                            v-model="filters.month"
                            :items="month_items"
                            label="Month"
                            outlined
                            dense
                            auto-select_first
                            hide_details
                            multiple
                            clearable
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-spacer/>
                    <v-col cols="3" class="pull-right">
                        <v-text-field
                            v-model="search"
                            label="Search by Account Number"
                            append-icon="mdi-magnify"
                            @keydown.enter="getRBSummary()"
                            @click:append="getRBSummary()"
                        />
                    </v-col>
                </v-row>
            </v-card-subtitle>
            <!-- Table -->
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-data-table
                            :headers="mainHeaders"
                            :items="mainItems"
                            :options.sync="options"
                            :server-items-length="totalItems"
                            :items-per-page="10"
                            :loading="tableLoad"
                            :footer-props="{
                                itemsPerPageOptions: itemsPerPageOptions
                            }"
                            dense
                        >
                        <template v-slot:[`item.jan`]="{ item }">
                            <span v-if="item.jan == 0">
                                -
                            </span>
                            <span v-else-if="item.jan == 'OPEB'">
                                {{ item.jan }}
                            </span>
                            <span v-else>
                                {{ item.jan | currency}}
                            </span>
                        </template>
                        <template v-slot:[`item.feb`]="{ item }">
                            <span v-if="item.feb == 0">
                                -
                            </span>
                            <span v-else-if="item.feb == 'OPEB'">
                                {{ item.feb }}
                            </span>
                            <span v-else>
                                {{ item.feb | currency}}
                            </span>
                        </template>
                        <template v-slot:[`item.mar`]="{ item }">
                            <span v-if="item.mar == 0">
                                -
                            </span>
                            <span v-else-if="item.mar == 'OPEB'">
                                {{ item.mar }}
                            </span>
                            <span v-else>
                                {{ item.mar | currency}}
                            </span>
                        </template>
                        <template v-slot:[`item.apr`]="{ item }">
                            <span v-if="item.apr == 0">
                                -
                            </span>
                            <span v-else-if="item.apr == 'OPEB'">
                                {{ item.apr }}
                            </span>
                            <span v-else>
                                {{ item.apr | currency}}
                            </span>
                        </template>
                        <template v-slot:[`item.may`]="{ item }">
                            <span v-if="item.may == 0">
                                -
                            </span>
                            <span v-else-if="item.may == 'OPEB'">
                                {{ item.may }}
                            </span>
                            <span v-else>
                                {{ item.may | currency}}
                            </span>
                        </template>
                        <template v-slot:[`item.jun`]="{ item }">
                            <span v-if="item.jun == 0">
                                -
                            </span>
                            <span v-else-if="item.jun == 'OPEB'">
                                {{ item.jun }}
                            </span>
                            <span v-else>
                                {{ item.jun | currency}}
                            </span>
                        </template>
                        <template v-slot:[`item.jul`]="{ item }">
                            <span v-if="item.jul == 0">
                                -
                            </span>
                            <span v-else-if="item.jul == 'OPEB'">
                                {{ item.jul }}
                            </span>
                            <span v-else>
                                {{ item.jul | currency}}
                            </span>
                        </template>
                        <template v-slot:[`item.aug`]="{ item }">
                            <span v-if="item.aug == 0">
                                -
                            </span>
                            <span v-else-if="item.aug == 'OPEB'">
                                {{ item.aug }}
                            </span>
                            <span v-else>
                                {{ item.aug | currency}}
                            </span>
                        </template>
                        <template v-slot:[`item.sep`]="{ item }">
                            <span v-if="item.sep == 0">
                                -
                            </span>
                            <span v-else-if="item.sep == 'OPEB'">
                                {{ item.sep }}
                            </span>
                            <span v-else>
                                {{ item.sep | currency}}
                            </span>
                        </template>
                        <template v-slot:[`item.oct`]="{ item }">
                            <span v-if="item.oct == 0">
                                -
                            </span>
                            <span v-else-if="item.oct == 'OPEB'">
                                {{ item.oct }}
                            </span>
                            <span v-else>
                                {{ item.oct | currency}}
                            </span>
                        </template>
                        <template v-slot:[`item.nov`]="{ item }">
                            <span v-if="item.nov == 0">
                                -
                            </span>
                            <span v-else-if="item.nov == 'OPEB'">
                                {{ item.nov }}
                            </span>
                            <span v-else>
                                {{ item.nov | currency}}
                            </span>
                        </template>
                        <template v-slot:[`item.dec`]="{ item }">
                            <span v-if="item.dec == 0">
                                -
                            </span>
                            <span v-else-if="item.dec == 'OPEB'">
                                {{ item.dec }}
                            </span>
                            <span v-else>
                                {{ item.dec | currency}}
                            </span>
                        </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-form>
</template>
<script>
import { mapGetters } from 'vuex'
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue'
export default {
    mixins: [SharedFunctionsComponentVue],
    props: ['userAccess'],
    data(){
        return{
            valid: true,
            mainHeaders: [
                { text: 'Company', value: 'company_name', sortable: false },
                { text: 'Branch', value: 'store_branch_name', sortable: false },
                { text: 'Vendor', value: 'vendor_name', sortable: false },
                { text: 'Account #', value: 'account_num', sortable: false, divider: true },
                { text: 'JAN', value: 'jan', sortable: false, align: 'center' },
                { text: 'FEB', value: 'feb', sortable: false, align: 'center' },
                { text: 'MAR', value: 'mar', sortable: false, align: 'center' },
                { text: 'APR', value: 'apr', sortable: false, align: 'center' },
                { text: 'MAY', value: 'may', sortable: false, align: 'center' },
                { text: 'JUN', value: 'jun', sortable: false, align: 'center' },
                { text: 'JUL', value: 'jul', sortable: false, align: 'center' },
                { text: 'AUG', value: 'aug', sortable: false, align: 'center' },
                { text: 'SEP', value: 'sep', sortable: false, align: 'center' },
                { text: 'OCT', value: 'oct', sortable: false, align: 'center' },
                { text: 'NOV', value: 'nov', sortable: false, align: 'center' },
                { text: 'DEC', value: 'dec', sortable: false, align: 'center' },
            ],
            mainItems: [],
            options: {},
            totalItems: 0,
            tableLoad: false,
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            itemsPerPage: 10,
            search: '',
            summary_tab: null,
            filters:{
                company_id: null,
                store_branches_id: null,
                vendor_id: null,
                recurring_bill_type_id: null,
                year: '2024',
                opeb: 0,
                month: []
            },
            paid_status_items:[
                { text:'ALL', value:-1 },
                { text:'PAID', value:1 },
                { text:'UNPAID', value:0 },
            ],
            year_items:[
                { text:'2024', value:'2024' },
            ],
            month_items:[
                { text: 'JAN', value: 'jan' },
                { text: 'FEB', value: 'feb' },
                { text: 'MAR', value: 'mar' },
                { text: 'APR', value: 'apr' },
                { text: 'MAY', value: 'may' },
                { text: 'JUN', value: 'jun' },
                { text: 'JUL', value: 'jul' },
                { text: 'AUG', value: 'aug' },
                { text: 'SEP', value: 'sep' },
                { text: 'OCT', value: 'oct' },
                { text: 'NOV', value: 'nov' },
                { text: 'DEC', value: 'dec' },
            ],
            rb_types:[],
            clonedStores: []
        }
    },
    computed:{
        ...mapGetters([
            'RECURRING_BILL',
            'GET_CHART_OF_ACCOUNTS_SELECTION',
            'GET_RB_TYPES',
            'GET_ADMIN_SUPPLIER_SELECTION',
            'GET_PV_COMPANIES_SELECTION',
            'GET_PV_STORE_BRANCHES_SELECTION'
        ]),
        branchItemsForFilter(){
            let items = this.clonedStores.filter(e=>e.company_id == this.filters.company_id)
            if (!this.filters.company_id) return this.clonedStores
            if (!this.filters.store_branches_id) this.filters.store_branches_id = items[0].value
            return items
        },
    },
    mounted(){
        this.getBranches()
    },
    beforeDestroy() {
        
    },
    methods:{
        getRBSummary(){
            if (this.summary_tab == 0 && this.filters.recurring_bill_type_id == 0) return
            this.tableLoad = true
            this.mainItems = []
            let payload = {
                company_id: this.filters.company_id,
                store_branches_id: this.filters.store_branches_id,
                vendor_id: this.filters.vendor_id,
                search: this.search,
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                url: 'getAll',
                summary: 1,
                year:this.filters.year,
                type: this.filters.recurring_bill_type_id,
                paid: this.filters.paid,
                month: this.filters.month,
                opeb: this.filters.opeb
            }
            this.$store.dispatch('recurringBillGet', payload).then(response=>{
                let data = response.data.data
                this.mainItems = data.data
                this.totalItems = data.total
                this.tableLoad = false
                this.updateHeaders()
            })
        },
        chipStatus(val){
            this.filters.opeb = val
            this.getRBSummary()
        },
        updateHeaders(){
            let baseHeaders = [
                { text: 'Company', value: 'company_name', sortable: false },
                { text: 'Branch', value: 'store_branch_name', sortable: false },
                { text: 'Vendor', value: 'vendor_name', sortable: false },
                { text: 'Account #', value: 'account_num', sortable: false, divider: true }
            ];

            let monthHeaders = []
            let filteredMonths = []

            switch (this.filters.opeb) {
                case 1:
                    filteredMonths = this.month_items.filter(e => 
                        this.mainItems.some(el => el[e.value] === 'OPEB')
                    ).map(i => i.value)
                    break;
                case 2:
                    filteredMonths = this.month_items.filter(e => 
                        this.mainItems.some(el => el[e.value] !== 0 && el[e.value] !== 'OPEB')
                    ).map(item => item.value);
                    break;
                default:
                        filteredMonths = this.month_items.filter(e => 
                            this.mainItems
                        ).map(item => item.value);
                    break;
            }

            monthHeaders = filteredMonths.length > 0
                ? filteredMonths.map(e=>{
                    return this.month_items.find(el=> el.value === e)
                }).map(v=>({
                    text: v.text.toUpperCase(),
                    value: v.value,
                    sortable: false,
                    align: 'center'
                }))
                : filteredMonths.map(e => ({
                    text: e.text.toUpperCase(),
                    value: e.value,
                    sortable: false,
                    align: 'center'
                }));

            this.mainHeaders = [...baseHeaders, ...monthHeaders];
        },
        async getBranches(){
            try{
                this.clonedStores = await this.getStoreBranches()
            } catch (error) {
                console.error(error)
            }
        },
        insertStore(source){
            switch (source) {
                case 1:
                    this.filters.store_branches_id = this.branchItemsForFilter[0].value
                    break;
                case 2:
                    this.form.store_branches_id = this.branchItems[0].value
                    break;
            }
        },
        insertCompany(storeId, source){
            if (!storeId) return
            let store = this.GET_PV_STORE_BRANCHES_SELECTION.find(e=>e.value == storeId)
            switch (source) {
                case 1:
                    this.filters.company_id = this.GET_PV_COMPANIES_SELECTION.find(e=>e.value == store.company_id).value
                    break;
                case 2:
                    this.form.company_id = this.GET_PV_COMPANIES_SELECTION.find(e=>e.value == store.company_id).value
                    break;
            }
        },
    },
    watch:{
        options:{
            handler(){
                this.getRBSummary()
            },
            deep:true
        },
        GET_RB_TYPES:{
            handler(val){
                if (val) {
                    this.rb_types = [
                        { value: 0, text: 'ALL' },
                        ...val.map(e => ({ value: e.value, text: e.text }))
                    ];
                }
            },
            immediate: true
        },
        'filters.company_id':{
            handler(val){
                if (!val) {
                    this.filters.store_branches_id = null
                }
            }
        },
        'filters.store_branches_id':{
            handler(val){
                if (!val) {
                    this.filters.company_id = null
                }
            }
        }
    }
}
</script>
<style scoped>

</style>