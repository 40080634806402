<template>
  <div>
    <table id="body-pdf">
      <thead v-if="!isContentEmpty">
        <tr>
          <td v-for="(header,index) in headersOfTable" :key="index" >{{ header.toUpperCase() }}</td>
        </tr>
      </thead>
      <tbody v-if="!isContentEmpty">
        <tr v-for="(content,index) in tableContents" :key="index">
            <td v-for="(header, index) in headers" :key="index">{{ content[header.toLowerCase()] }}</td>
        </tr>
        <tr>
            <td :colspan="headersOfTable.length ?? 0">Total: {{ tableContents.length }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr v-if="isPropertiesTheSame">
          <td :colspan="headersOfTable.length ?? 0">
            {{ isPropertiesTheSame }}
          </td>
        </tr>
        <tr v-if="isContentEmpty">
          <td colspan="10">
             CONTENT IS EMPTY
          </td>
        </tr>
      </tfoot>
    </table>
    <div id="reason-pdf" >
        <span> {{ remarks.title }}: {{ remarks.value }}</span>
    </div>
  </div>
</template>
<script>
// SAMPLE DATA FORMAT TO HAVE IN USING THIS COMPONENT

// contentsOfTable: [{
//           model: 'AG0806-2',
//           name: 'Brush',
//           qty: '2',
//           location: 'ws'
//       },
//       {
//           model: 'AG0806-2',
//           name: 'Brush',
//           qty: '5',
//           location: 'wf',
//       },
//       {
//           model: 'AG0806-2',
//           name: 'Brush',
//           qty: '4',
//           location: 'wh',
//       },
//       {
//           model: 'AG0806-2',
//           name: 'Brush',
//           qty: '4',
//           location: 'ws',
//       }],

// WAY TO RENDER IT TO PDF
// YOU HAVE TO INCLUDE THE FOLLOWING INTO YOUR FUNCTION

// let headerEndPosition = pdf.lastAutoTable.finalY;
// pdf.autoTable({
//   html: '#body-pdf',
//   theme:"grid",
//   styles: {textColor: '#000000', fontSize: 8, lineWidth:0.1, lineColor:"#000000"},
//   headStyles:{textType: "bold", fillColor: "#F7FF00", halign: "center"},
//   footStyles:{textType: "bold", fontSize: 16, fillColor: "#FF0000"},
//   margin: {top:50, left: 12.7, right: 12.7},
//   startY: headerEndPosition,
//   columnStyles: {
//       0: { cellWidth: 'auto', halign:'left'},
//       1: { cellWidth: 'auto', halign:'left'},
//       2: { cellWidth: 'auto', halign:'left'},
//       3: { cellWidth: 'auto', halign:'left'},
//       4: { cellWidth: 'auto', halign:'left'},
//       5: { cellWidth: 'auto', halign:'left'},
//       6: { cellWidth: 'auto', halign:'left'},
//   }
// });
export default {
  props: {
    tableContents:{ 
      type: Array,
      default: () => [],
    },
    remarks:{
      type: Object,
      default: () => {
          return {
            value:'',
            title:'',
          }
      },
    }
  },
  computed: {
      headers() {
        let element = this.tableContents[0];
        if(this.tableContents.length > 0)
        {
          let headers = Object.keys(element);
          for (const header of headers)
            {
              for (const content of this.tableContents){
                if(!content[header])
                {
                  content[header] = "";
                }
              }
            }
          return headers;
        }    
      },
      headersOfTable() {
        let element = this.tableContents[0];
        let headers = Object.keys(element);
        let headersOfTable = headers.map(header => header.replaceAll('_', ' '))
        return headersOfTable;
      },
      isContentEmpty() {
        return this.tableContents.length === 0;
      },
      isPropertiesTheSame() {
        const firstObj  = this.tableContents[0];
        for (let obj=1; obj < this.tableContents.length; obj++)
        {
          const currObj = this.tableContents[obj];
          const firstObjKeys =  Object.keys(firstObj);
          const currObjKeys = Object.keys(currObj);

          if (firstObjKeys.length !=  currObjKeys.length)
          {
            console.error('ERROR: Differences on objects properties causes missing fields.')
            return 'ERROR: Missing fields due of objects properties unsimilarity.';
          }
          const notIncludesInObj1 = currObjKeys.filter(key => !firstObjKeys.includes(key));
          const notIncludesInObj2 = firstObjKeys.filter(key => !currObjKeys.includes(key));
          
          if (JSON.stringify(notIncludesInObj1) != JSON.stringify(notIncludesInObj2) )
          {
            console.error('ERROR: Differences on objects properties  causes missing fields.');
            return 'ERROR: Missing fields due of objects properties unsimilarity.';
          } 
        }
      }
    },
}
</script>

<style scoped>
</style>