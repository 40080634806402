import { render, staticRenderFns } from "./JournalVoucherManagerViewComponent.vue?vue&type=template&id=57f2a701&scoped=true"
import script from "./JournalVoucherManagerViewComponent.vue?vue&type=script&lang=js"
export * from "./JournalVoucherManagerViewComponent.vue?vue&type=script&lang=js"
import style0 from "./JournalVoucherManagerViewComponent.vue?vue&type=style&index=0&id=57f2a701&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57f2a701",
  null
  
)

export default component.exports