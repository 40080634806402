<template>
    <div class="pa-4">
        <v-card class="pa-4">
            <v-layout row class="mx-0">
                <v-flex v-for="rf in rfLists" :key="rf.id" lg2 class="pa-2">
                    <v-col lg="12" sm="12" >
                        <v-card
                            class="total-cards"
                            elevation="2"
                        >
                            <v-card-text class="d-flex justify-content-start align-items-center">
                                <div class="card-icon total-amount bg-warning mr-1" style="padding: 6px 2px 6px 2px; border-radius: 4px;">
                                    <v-icon style="font-size:40px">mdi-tools</v-icon>

                                </div>
                                <div class="card-details">
                                    <span class="info-box-text">{{ rf.title }}</span>
                                    <span class="info-box-number" style="font-weight: bold;">{{ rf.value }}</span>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-flex>
            </v-layout>
            <v-layout row class="mx-0">
                <v-flex lg6 class="pr-2">
                    <v-card class="pa-4">
                        <div>
                            <!-- <h6 class="text-gray-dark">Less Than 30 Days Repair Items</h6> -->
                             <v-autocomplete :items="filterOption"
                                    v-model="selectedOption"
                                    item-value="value"
                                    item-text="text"
                                    class="d-inline-block small"
                            ></v-autocomplete>
                        </div>

                        <div v-if="selectedOption == 1">
                            <apexchart
                                ref="serviceDashboardBarGraph"
                                :options="less30RepairBarOptions"
                                type="bar"
                                :series="less30RepairBarseries"
                            >
                            </apexchart>
                        </div>
                        <div v-if="selectedOption == 2">
                            <apexchart
                                ref="serviceDashboardBarGraph"
                                :options="greater30RepairBarOptions"
                                type="bar"
                                :series="greater30RepairBarseries"
                            >
                            </apexchart>
                        </div>

                        <v-layout row class="mx-0">
                            <v-spacer />
                            <v-flex shrink>
                                <v-btn v-if="selectedOption == 1" text @click="itemStatus('<=30')">See All <i class="fas fa-arrow-circle-right"></i></v-btn>
                                <v-btn v-if="selectedOption == 2" text @click="itemStatus('>=30')">See All <i class="fas fa-arrow-circle-right"></i></v-btn>
                            </v-flex>
                        </v-layout>
                    </v-card>
                </v-flex>

                <v-flex lg6>
                    <v-card class="pa-4" height="100%">
                        <div>
                            <h6 class="text-gray-dark">Repair Form Status</h6>
                        </div>

                        <div>
                            <div id="chart">
                                <apexchart
                                    type="pie"
                                    width="610"
                                    :options="chartOptions"
                                    :series="series"
                                ></apexchart>
                            </div>
                        </div>

                    </v-card>
                </v-flex>
            </v-layout>
        </v-card>

        <RepairItemsDialog :itemStatus="status" @closeDialog="closeRepairItemDialog" />
    </div>
</template>

<script>
import RepairItemsDialog from '../Views/DashboardComponent/RepairItemsDialogComponent.vue'
import { mapGetters } from 'vuex'
export default {
    data(){
        return {
            series: [],
            chartOptions: {
                    colors: [
                            '#fc0303',
                            '#fc6b03',
                            '#fca503',
                            '#f8fc03',
                            '#313552',
                            '#adfc03',
                            '#03fca5',
                            '#03b1fc',
                            '#219F94',
                            '#b103fc',
                            '#03fc28',
                            '#E8E8A6',
                            '#1A1A40',
                            '#270082',
                            '#fc035a'],
                        fill: {
                            colors: [
                                '#fc0303',
                                '#fc6b03',
                                '#fca503',
                                '#f8fc03',
                                '#313552',
                                '#adfc03',
                                '#03fca5',
                                '#03b1fc',
                                '#219F94',
                                '#b103fc',
                                '#03fc28',
                                '#E8E8A6',
                                '#1A1A40',
                                '#270082',
                                '#fc035a'],
                            },
                        markers: {
                            colors: [
                                '#fc0303',
                                '#fc6b03',
                                '#fca503',
                                '#f8fc03',
                                '#313552',
                                '#adfc03',
                                '#03fca5',
                                '#03b1fc',
                                '#219F94',
                                '#b103fc',
                                '#03fc28',
                                '#E8E8A6',
                                '#1A1A40',
                                '#270082',
                                '#fc035a'],
                            },
                chart: {
                width: 500,
                type: 'pie',
                },
                labels: [
                    'FOR REPAIR',
                    'UNDER REPAIR',
                    'QUOTATION',
                    'FOR CUSTOMER APPROVAL',
                    'FOR TECHNICAL APPROVAL',
                    'WAITING FOR CM',
                    'WAITING FOR PARTS',
                    'PARTS INSTALLATION',
                    'WAITING FOR REPLACEMENT',
                    'RETURNED',
                    'REPAIRED',
                    'READY FOR DELIVERY',
                    'DELIVERED',
                    'COLLECTED',
                    'DEFECTIVE'
                ],
                responsive: [{
                breakpoint: 500,
                options: {
                    chart: {
                    width: 500
                    },
                },
                legend:{
                    position: "bottom",
                    fontSize: "32px"
                }
                }]
            },
            selectedOption:1,
            filterOption:
                [
                    { value: 1, text: 'Less Than 30 Days Repair Items' },
                    { value: 2, text: 'Greater Than 30 Days Repair Items' },
                ],
            lessThan30Count: 0,
            greatedThan30Count: 0,
            status: '',
            repairItemsHeaders: [
                { text: 'RF#', value: 'rf_number' },
                { text: 'Model', value: 'model' },
                { text: 'Category', value: 'category' },
                { text: 'Repair Days', value: 'days' },
                { text: 'Defect', value: 'defect_description' },
            ],
            less30RepairItems: [],
            greater30RepairItems: [],
            less30RepairBarseries: [{
                data: []
            }],
            less30RepairBarOptions: {
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: true,
                    }
                },
            },
            greater30RepairBarseries: [{
                data: []
            }],
            greater30RepairBarOptions: {
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: true,
                    }
                },
            },
            less30RepairItemsBarData: [],
            greater30RepairItemsBarData: [],

            underRepairCount: 0,
            technicalApprovalCount: 0,
            rfdCount: 0,
            repairedCount: 0,

            rfLists: []
        }
    },
    components: {
        RepairItemsDialog
    },
    async mounted() {
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
        await this.lessThan30RepairItems();
        await this.greaterThan30RepairItems();
        await this.getAllTodaysReport();
        await this.getRFStatus();
    },
    computed:{
        ...mapGetters([
            'GET_RF_STATUS',
            'GET_ALL_TODAYS_REPORT',
        ])
    },
    watch: {
        less30RepairItems() {
            if(this.less30RepairItems.length > 0) {
                this.less30RepairBarseries[0].data = this.less30RepairItems.map(x=>{
                    return x.days;
                });

                this.less30RepairBarOptions = Object.assign({
                    xaxis: {
                        categories: _.compact(this.less30RepairItems.map(x=>{return x.model;})),
                        labels: {
                            formatter: (val) => {
                                return `${val} days`;
                            }
                        }
                    },
                }, this.less30RepairBarOptions);
            }
        },
        greater30RepairItems() {
            if(this.greater30RepairItems.length > 0) {
                this.greater30RepairBarseries[0].data = this.greater30RepairItems.map(x=>{
                    return x.days;
                });

                this.greater30RepairBarOptions = Object.assign({
                    xaxis: {
                        categories: _.compact(this.greater30RepairItems.map(x=>{return x.model;})),
                        labels: {
                            formatter: (val) => {
                                return `${val} days`;
                            }
                        }
                    },
                }, this.greater30RepairBarOptions);
            }
        }
    },
    methods:{
        getRFStatus() {

            this.$store.dispatch('getRFStatus').then(response=>{
                let data = this.GET_RF_STATUS
                this.series.push(parseFloat(data[0].FOR))
                this.series.push(parseFloat(data[0].UAS))
                this.series.push(parseFloat(data[0].QUO))
                this.series.push(parseFloat(data[0].FCA))
                this.series.push(parseFloat(data[0].FTA))
                this.series.push(parseFloat(data[0].WCM))
                this.series.push(parseFloat(data[0].WFP))
                this.series.push(parseFloat(data[0].PIN))
                this.series.push(parseFloat(data[0].WFR))
                this.series.push(parseFloat(data[0].RET))
                this.series.push(parseFloat(data[0].REP))
                this.series.push(parseFloat(data[0].RFD))
                this.series.push(parseFloat(data[0].DEL))
                this.series.push(parseFloat(data[0].COL))
                this.series.push(parseFloat(data[0].DEF))

                this.rfLists.push({
                    title: 'TA',
                    value: parseFloat(data[0].FOR)
                })
                this.rfLists.push({
                    title: 'UAS',
                    value: parseFloat(data[0].UAS)
                })
                this.rfLists.push({
                    title: 'QUO',
                    value: parseFloat(data[0].QUO)
                })
                this.rfLists.push({
                    title: 'FCA',
                    value: parseFloat(data[0].FCA)
                })
                this.rfLists.push({
                    title: 'FTA',
                    value: parseFloat(data[0].FTA)
                })
                this.rfLists.push({
                    title: 'WCM',
                    value: parseFloat(data[0].WCM)
                })
                this.rfLists.push({
                    title: 'WFP',
                    value: parseFloat(data[0].WFP)
                })
                this.rfLists.push({
                    title: 'PIN',
                    value: parseFloat(data[0].PIN)
                })
                this.rfLists.push({
                    title: 'WFR',
                    value: parseFloat(data[0].WFR)
                })
                this.rfLists.push({
                    title: 'RET',
                    value: parseFloat(data[0].RET)
                })
                this.rfLists.push({
                    title: 'REP',
                    value: parseFloat(data[0].REP)
                })
                this.rfLists.push({
                    title: 'RFD',
                    value: parseFloat(data[0].RFD)
                })
                this.rfLists.push({
                    title: 'DEL',
                    value: parseFloat(data[0].DEL)
                })
                this.rfLists.push({
                    title: 'COL',
                    value: parseFloat(data[0].COL)
                })
                this.rfLists.push({
                    title: 'DEF',
                    value: parseFloat(data[0].DEF)
                })

                this.chartOptions={
                    colors: [
                            '#fc0303',
                            '#fc6b03',
                            '#fca503',
                            '#f8fc03',
                            '#313552',
                            '#adfc03',
                            '#03fca5',
                            '#03b1fc',
                            '#219F94',
                            '#b103fc',
                            '#03fc28',
                            '#E8E8A6',
                            '#1A1A40',
                            '#270082',
                            '#fc035a'],
                        fill: {
                            colors: [
                                '#fc0303',
                                '#fc6b03',
                                '#fca503',
                                '#f8fc03',
                                '#313552',
                                '#adfc03',
                                '#03fca5',
                                '#03b1fc',
                                '#219F94',
                                '#b103fc',
                                '#03fc28',
                                '#E8E8A6',
                                '#1A1A40',
                                '#270082',
                                '#fc035a'],
                        },
                        markers: {
                            colors: [
                                '#fc0303',
                                '#fc6b03',
                                '#fca503',
                                '#f8fc03',
                                '#313552',
                                '#adfc03',
                                '#03fca5',
                                '#03b1fc',
                                '#219F94',
                                '#b103fc',
                                '#03fc28',
                                '#E8E8A6',
                                '#1A1A40',
                                '#270082',
                                '#fc035a'],
                        },}
            });
        },
        clearField() {
            this.lessThan30Count = 0;
            this.greatedThan30Count = 0;
            this.status = '';
        },
        itemStatus(status) {
            this.status = status;
            this.$store.commit('showScEstDialog');
        },
        closeRepairItemDialog() {
            this.status = '';
        },
        lessThan30RepairItems() {
            let payload = {
                status: '<=30'
            }
            this.$store.dispatch('seeAllChartRepairItems',payload).then(response=>{
                this.less30RepairItems = response.data;
            });
        },
        greaterThan30RepairItems() {
            let payload = {
                status: '>=30'
            }
            this.$store.dispatch('seeAllChartRepairItems',payload).then(response=>{
                this.greater30RepairItems = response.data;
            });
        },
        getAllTodaysReport() {
            this.$store.dispatch('getAllTodaysReport').then(response=>{
                this.underRepairCount = this.GET_ALL_TODAYS_REPORT.under_repair_count;
                this.technicalApprovalCount = this.GET_ALL_TODAYS_REPORT.fta_count;
                this.repairedCount = this.GET_ALL_TODAYS_REPORT.repaired_count;
                this.rfdCount = this.GET_ALL_TODAYS_REPORT.rfd_count;
            });
        }
    }
}
</script>

<style scoped>

</style>
