<template>
  <div>
      <v-btn @click="showBaseImageViewerDialog()">View Image</v-btn>
      <v-dialog v-model="baseImageViewerDialog" persistent max-width="60%">
          <v-card>
              <v-row class="m-0">
                <v-col>
                    <v-card-title>
                        <span class="headline">Image Viewer</span>
                    </v-card-title>
                </v-col>
                <v-col cols="pull-right-10 p-2">
                    <v-btn text icon color="gray" class="float-right" @click="closeBaseImageViewerDialog()">
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class="my-0" />

            <vue-flux
                :options="vfOptions"
                :images="images"
                :transitions="vfTransitions"
                :captions="vfCaptions"
                ref="slider"
            >

                <template v-slot:preloader>
                    <flux-preloader />
                </template>

                <template v-slot:caption>
                    <flux-caption />
                </template>

                <template v-slot:controls>
                    <flux-controls />
                </template>

                <template v-slot:pagination>
                    <flux-pagination />
                </template>

                <template v-slot:index>
                    <flux-index />
                </template>
            </vue-flux>
          </v-card>
      </v-dialog>
  </div>
</template>

<script>
// Author: Jimson Lomio, 12/29/2021
// https://github.com/ragnarlotus/vue-flux

import {
   VueFlux,
   FluxCaption,
   FluxControls,
   FluxIndex,
   FluxPagination,
   FluxPreloader,
} from 'vue-flux';

export default {
    data() {
        return {
            vfOptions: {
                autoplay: true
            },
            vfTransitions: [ 'fade', 'cube', 'book', 'wave' ],
            vfCaptions: [
                'Caption for image 1',
                'Caption for image 2',
                'Caption for image 3',
            ],
        }
    },
    components: {
        VueFlux,
        FluxCaption,
        FluxControls,
        FluxIndex,
        FluxPagination,
        FluxPreloader
    },
    props: ['images'],
    computed: {
        baseImageViewerDialog() {
            return this.$store.state.service.baseImageViewerDialog;
        }
    },
    methods: {
        showBaseImageViewerDialog() {
            this.$store.commit('showBaseImageViewerDialog');
        },
        closeBaseImageViewerDialog() {
            this.$store.commit('closeBaseImageViewerDialog');
        }
    }
}
</script>

<style>

</style>
