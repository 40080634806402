<template>
    <v-container class="ma-0 pa-0">
        <v-data-table
        :headers="debit_memo.headers"
        :items="debit_memo.items"
        :loading="loading">
        <template v-slot:[`item.action`]="{ item }">
            <span class="text-nowrap">
                <v-btn text icon color="orange" v-if="userAccess.view_type">
                    <v-icon class="btn-action" small
                        @click="viewDebitMemoType(item.id)">mdi-eye</v-icon>
                </v-btn>
                <v-btn text icon color="blue" v-if="userAccess.edit_type">
                    <v-icon class="btn-action" small
                        @click="editDebitMemoType(item.id)">mdi-pencil</v-icon>
                </v-btn>
                <v-btn text icon color="red" v-if="userAccess.delete_type">
                    <v-icon class="btn-action" small @click="deleteDebitMemoType(item.id)">mdi-delete</v-icon>
                </v-btn>
            </span>
        </template>
        </v-data-table>
        <DebitMemoTypeViewComponent @closeView='closeView' :viewDebitMemoTypeId="debit_memo.viewDebitMemoTypeId"></DebitMemoTypeViewComponent>
    </v-container>
</template>

<script>
import swal from 'sweetalert2';
import DebitMemoTypeViewComponent from './DebitMemoTypeViewComponent.vue';
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            debit_memo:{
                headers: [
                    { text: "CODE", value: "code" },
                    { text: "NAME", value: "name" },
                    { text: "ACTION", align:'center', value: "action" },
                ],
                items:[],
                viewDebitMemoTypeId:''
            }, 
            loading:false,
            userAccess:{
                edit_type:false,
                delete_type:false,
                view_type:false
            }   
        }
    },
    components:{
        DebitMemoTypeViewComponent
    },
    methods:{
        getAllDebitMemoType(){
            this.loading = true
            this.debit_memo.items = []
            let payload = {
                url:'getAllDebitMemoType',
            }
            this.$store.dispatch('getDebitMemoType',payload).then(response=>{
               this.debit_memo.items = response.data
               this.loading = false
            })
        },
        viewDebitMemoType(id){
            this.debit_memo.viewDebitMemoTypeId = id
        },
        closeView(){
            this.debit_memo.viewDebitMemoTypeId = 0
        },
        editDebitMemoType(id){
            this.$emit('showEditDialog',id)
        },
        deleteDebitMemoType(id){
            swal.fire({
                title: "",
                text: "Are you sure you want to Delete?",
                icon: 'question',
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                allowOutsideClick: false,
                confirmButtonColor: '#397373',
                cancelButtonColor: 'grey',
                confirmButtonText: 'Confirm',
                showCloseButton: true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    let payload = {
                        id:id,
                        url:'deleteDebitMemoType'
                    }
                    this.$store.dispatch('postDebitMemoType',payload).then(response=>{
                        swal.fire(response.data.msg,'',response.data.icon)
                        this.getAllDebitMemoType();
                    })
                }
            });
        }
    },
    mounted(){
        this.getAllDebitMemoType();
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS'
        ])
    },
    watch:{
        USER_ACCESS:{
            handler(val){
                this.userAccess.view_type = false;
                this.userAccess.edit_type = false;
                this.userAccess.delete_type = false;
                if (val != "fail") {
                    val.forEach((e) => {
                        if (e.actions_code == "view_type") {
                            this.userAccess.view_type = true;
                        }
                        if (e.actions_code == "edit_type") {
                            this.userAccess.edit_type = true;
                        }
                        if (e.actions_code == "delete_type") {
                            this.userAccess.delete_type = true;
                        }
                    });
                }
            }
        }
    }
}
</script>

<style>

</style>