<template>
    <div>
        <v-btn class="mt-1" text outlined @click="showCamera()"><v-icon>mdi-camera</v-icon></v-btn>
        <v-dialog v-model="baseCameraCaptureDialog" persistent max-width="40%">
            <v-card class="pb-4">
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">Documents Capture</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeCamera()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider class="mt-0" />

                <div style="width:100%" class="px-4">
                    <!-- default :width="450" :height="337.5" -->
                    <video ref="camera" width="100%" height="auto" class="video" autoplay></video>
                </div>

                <!-- default :width="450"" -->
                <canvas id="photoTaken" ref="canvas" :width="1280" :height="720" style="display:none"></canvas>

                <v-row no-gutters class="mt-5">
                    <v-col cols="6.5" class="text-end pa-0">
                        <v-btn @click="captureCamera()">Capture</v-btn>
                    </v-col>
                    <v-col cols="5" class="text-end mr-4">
                        <v-btn @click="toggleCamera" icon text>
                            <v-icon color="blue" >
                            mdi-camera-flip-outline
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider />

                <v-layout row class="px-4">
                    <v-flex v-for="(image, index) in images" :key="index" lg4 class="pa-1">
                        <div class="image_container">
                            <v-img :src="image"/>

                            <v-btn text icon :color="iconColor" small class="delete_btn" @click="deleteImage(image, index)" @mouseover="hoverIn()"  @mouseleave="hoverOut()">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>

                            <v-btn text icon :color="iconColor" small class="download_btn" @click="downloadImage(image)" @mouseover="hoverIn()"  @mouseleave="hoverOut()">
                                <v-icon>fas fa-arrow-circle-down</v-icon>
                            </v-btn>
                        </div>
                    </v-flex>
                </v-layout>

                <v-divider v-if="images.length > 0" />

                <div v-if="images.length > 0" style="text-align:center">
                    <v-btn @click="submit()">Submit</v-btn>
                </div>

                <a id="downloadImage" ref="dImage" style="display:none" download="service-image.jpg"></a>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
// Author: Jimson Lomio, 12/28/2021
// https://codepen.io/ditarahma08/pen/GRRxZLW
// https://stackoverflow.com/questions/14011021/how-to-download-a-base64-encoded-image
export default {
    data() {
        return {
            isCameraOpen: true,
            images: [],
            iconColor: 'gray',
            useFrontCamera: false,
            currentStream: null,
        }
    },
    watch: {
        images() {
            if(this.images.length > 0) {
                this.$emit('capturedImages', this.images);
            }
        },
        GET_NEW_EMPLOYEE_DATA:{
            handler(val){
                if(val){
                    this.images = []
                }
            }
        }
    },
    computed: {
        baseCameraCaptureDialog() {
            return this.$store.state.service.baseCameraCaptureDialog;
        },
        ...mapGetters(["GET_NEW_EMPLOYEE_DATA",])
    },
    methods: {
        showCamera() {
            this.$store.commit('showBaseCameraCaptureDialog');
            this.startCamera();
        },
        closeCamera() {
            this.$store.commit('closeBaseCameraCaptureDialog');
            this.stopCamera();
            // this.images = [];
        },
        startCamera() {
            const constraints = {
                audio: false,
                video: {
                    facingMode: this.useFrontCamera ? 'user' : 'environment',
                },
            };
            // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#constraints

            navigator.mediaDevices
                .getUserMedia(constraints)
                .then((stream) => {
                    this.currentStream = stream;
                    this.$refs.camera.srcObject = stream;
                    this.isCameraOpen = true;
                })
                .catch((err) => {
                    console.error("Error accessing the camera: ", err);
                });
        },
        toggleCamera() {
            if (this.currentStream) {
                this.currentStream.getTracks().forEach((track) => {
                    track.stop();
                });
            }
            this.useFrontCamera = !this.useFrontCamera;
            this.startCamera();
        },
        stopCamera() {
            let tracks = this.$refs.camera.srcObject.getTracks();

			tracks.forEach(track => {
				track.stop();
			});
        },
        captureCamera() {
            const context = this.$refs.canvas.getContext('2d');
            context.drawImage(this.$refs.camera, 0, 0, 1280, 720);
            this.images.unshift(this.$refs.canvas.toDataURL('image/jpeg'))
        },
        deleteImage(image, index) {
            this.images.splice(index, 1);
            this.$emit('capturedImages', this.images);
        },
        hoverIn() {
            this.iconColor = 'white';
        },
        hoverOut() {
            this.iconColor = 'gray';
        },
        submit() {
            this.$emit('capturedImages', this.images);
            this.$store.commit('closeBaseCameraCaptureDialog');

        },
        downloadImage(image) {
            const a = this.$refs.dImage;
            const new_image = image.replace("image/jpeg", "image/octet-stream");
            a.setAttribute("href", new_image);
            a.click();
        },
        reset() {
            this.images = [];
        }
    }
}
</script>

<style lang="scss" scoped>
    .image_container {
        position: relative;
    }

    .image_container .delete_btn {
        position: absolute;
        top: 10%;
        right: 0%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
    }

    .image_container .download_btn {
        position: absolute;
        top: 10%;
        left: 10%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
    }
    .video {
        transform: scaleX(-1);
    }
</style>
