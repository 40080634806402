<template>
    <v-card>
        <v-app-bar dark class="bg-ingco" height="50" elevation="4">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" color="black"></v-app-bar-nav-icon>
            <router-link :to="USERACCOUNT_LOAD.department_id == 2?{ name: 'targetSales' }:{name:'home'}" class="text-decoration-none text-caption">Home</router-link>
            <v-spacer></v-spacer>
            <!-- <v-menu offset-y transition="slide-x-transition" :close-on-click="closeOnClick"
                :close-on-content-click="closeOnClick">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" background-color="transparent">

                        <v-badge color="red" content="5" overlap>
                            <v-icon color="black">mdi-bell-outline</v-icon>
                        </v-badge>
                    </v-btn>
                </template>
                <v-card width="400">
                    <v-card-subtitle class="border-bottom pa-2 bg-black text-white">Notification</v-card-subtitle>
                    <v-card class="overflow-auto pa-0" height="500" flat>
                        <v-list three-line height="500">
                            <template v-for="(items) in GET_NOTIFICATION_LOAD" dense>
                                <v-list-item :key="items.id" class="border-bottom">
                                    <v-list-item-avatar>
                                        <v-img src="../../../assets/ingcoph_bg/ingcoph_logo_alt.jpg"></v-img>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title v-html="items.name"></v-list-item-title>
                                        <v-list-item-subtitle v-html="items.data"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-card>
                    <v-card-subtitle class="border-top bg-black">
                        <v-container class="justify-content-between">
                            <v-row>
                                <v-col cols="3">
                                    <v-card elevation="1">
                                        <v-icon size="19">mdi-format-list-bulleted</v-icon>
                                        <span class="text-caption"> SEE ALL </span>
                                    </v-card>
                                </v-col>
                                <v-col cols="5">
                                    <v-card elevation="1" class="cursor-pointer">
                                        <v-icon size="19"> mdi-notification-clear-all </v-icon>
                                        <span class="text-caption">
                                            MARK ALL ASS READ
                                        </span>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-subtitle>
                </v-card>
            </v-menu> -->
        <v-app-bar-title class="pa-0 ma-0">
                <v-menu offset-y :close-on-click="closeOnClick" :close-on-content-click="closeOnClick">
                    <template v-slot:activator="{ on, attrs }">
                        <v-list-item class="pa-0 ma-0 py-1">
                            <v-avatar size="34" color="gold" class="pa-0 ma-2">
                                <v-img src="../../../assets/ingcoph_bg/ingcoph_logo_alt.jpg">
                                </v-img>
                            </v-avatar>
                            
                            <span class="text-caption"> {{ USERACCOUNT_LOAD.name }} </span>
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-chevron-down</v-icon>
                            </v-btn>
                        </v-list-item>
                    </template>
                    <v-list dense>
                        <v-list-item link>
                            <v-icon size="16">mdi-account</v-icon>
                            <v-list-item-title> My Profile </v-list-item-title>
                        </v-list-item>
                        <v-list-item link @click="changePassword">
                            <v-icon size="16">mdi-lock</v-icon>
                            <v-list-item-title> Change Password </v-list-item-title>
                        </v-list-item>
                        <v-list-item link @click="logout">
                            <v-icon size="16">mdi-logout</v-icon>
                            <v-list-item-title> Logout </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-app-bar-title>
        </v-app-bar>
        <sidenav :drawers="drawer"></sidenav>
    </v-card>

</template>
<script>
import { mapGetters } from "vuex";
import sidenav from "./sidenav.vue";
export default {
    data() {
        return {
            drawer: false,
            group: false,
            closeOnClick: false
        };
    },
    components: { sidenav },
    computed: {
        ...mapGetters(["USERACCOUNT_LOAD", "GET_NOTIFICATION_LOAD"]),
    },
    methods: {
        logout() {
            localStorage.setItem("last_visit", window.location.pathname);
            this.$store.dispatch("logout").then(() => {
                this.$store.commit('USERACCOUNT_LOAD', {})
                this.$router.push({ name: "login" });
            });
        },
        changePassword(){
            this.$router.push({
                name:'change-password'
            })
        }
    },
};
</script>
<style scoped>
a {
    color: rgb(60, 54, 54) !important;
}

.cursor-pointer {
    cursor: pointer;
}
</style>
