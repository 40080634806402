<template>
    <v-card >
        <v-card-title>
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                        v-model="channel_log"
                        :items="log_channel_items"
                        dense
                        outlined
                        @change="getLogs()"
                        >

                        </v-autocomplete>
                    </v-col>
                    </v-row>
            </v-container>
        </v-card-title>
        <v-card-text style="height:500px; overflow: auto;" class="bg-dark">
            <v-container>
                <v-row>
                    
                    <v-col cols="12">
                   
                            <v-row v-for="(item,i) in server_log_text" :key="i">
                                <v-col cols="12" class="text-white pa-9 border-bottom"  >{{ item }}</v-col>
                            </v-row>
                        
                        <!-- <span class="text-white">{{ server_log_text }}</span> -->
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
  export default {
    data () {
      return {
        server_log_text:[],
        channel_log:'single',
        log_channel_items:[]
      }
    },
    computed:{
    
    },
    created(){
       
    },
    mounted(){
        this.getChannelLogs();
        this.getLogs();
    },
    methods:{
        getChannelLogs(){
            this.$store.dispatch('getChannelLogs').then(res=>{
                this.log_channel_items = res
            })
        },
        getLogs(){
            this.server_log_text = '';
            this.$store.dispatch('getChannelLogsByFile',this.channel_log).then(res=>{
                this.server_log_text = res
            })
        }
    },
    watch:{
        
    }
  }
</script>
