<template>
  <span>
        <div style="display:inline-block">
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <span
                        v-bind="attrs"
                        v-on="on"
                    >
                    <v-file-input
                        v-model="files"
                        @change="onChange"
                        @click:clear="reset();$emit('reset', true);"
                        :hide-input="hideInput"
                        :multiple="true"
                        :rules="rules"
                        ref="inputUploader"
                        type="file"
                        name="files"
                        class="form-control pt-1"
                    />
                    </span>
                </template>
                <span>File Upload</span>
            </v-tooltip>
        </div>
        <!-- <v-dialog v-model="dialog.file_preview" persistent width="50%">
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                        <span class="headline">Preview Files</span>
                        </v-card-title>
                    </v-col>

                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeFilePreview()">
                        <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider class="my-0" />
                    <v-window v-model="onboarding" show-arrows>
                        <template v-slot:prev="{ on, attrs }">
                            <v-btn
                                @click="prev"
                                text
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>
                        </template>

                        <template v-slot:next="{ on, attrs }">
                            <v-btn
                                @click="next"
                                v-bind="attrs"
                                v-on="on"
                                text
                            >
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>
                        </template>

                        <v-window-item v-for="(preview, index) in GET_FILE_PREVIEWS" :key="`card-${index}`">
                            <v-card color="transparent" height="500">
                                <v-row class="fill-height" align="center" justify="center">
                                    <v-card-text class="text-center d-flex justify-center">
                                        <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }" >
                                                    <v-btn
                                                        @click="removeItem(index)"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        style="top: 20px; right: 10px; z-index: 100;"
                                                        class="position-absolute"
                                                        icon
                                                        text
                                                        small
                                                    >
                                                        <v-icon color="red">mdi-delete-circle-outline</v-icon>
                                                    </v-btn>
                                                </template>
                                            <span>Remove File </span>
                                        </v-tooltip>

                                        <template v-if="preview.type == 'image'">
                                            <v-img :src="preview.data" contain width="500" height="500" class="responsive-iframe" />
                                        </template>
                                        <template v-if="preview.type == 'pdf'">
                                            <iframe :src="preview.data" :width="700" :height="500" />
                                        </template>
                                        <template v-else>
                                            <span>{{preview.name}}</span>
                                        </template>
                                    </v-card-text>
                                </v-row>
                            </v-card>
                        </v-window-item>
                    </v-window>

                <v-card-actions class="text-center" style="border-top:1px solid #dee2e6;">
                    <v-row class="ma-1 pb-2">
                        <v-col v-if="action !='Create'">
                            <v-btn
                                @click="submit()"
                            >
                                <span>Submit</span>
                            </v-btn>
                        </v-col>
                        <v-col v-if="action =='Create'">
                            <v-btn
                                @click="closePreviewWithFile()"
                            >
                                <span>Okay</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog> -->
  </span>
</template>

<script>
import swal from 'sweetalert2'
import { mapGetters } from 'vuex'
export default {
    props:{
        hideInput: {
            default: true,
            type: Boolean,
        },
        parent_id:{
            default:null,
            type: Number,
        },
        action:{
            default:null,
            type: String,
        }
    },
    data() {
        return {
            rules: [
                v => !!v || 'File is required',
                v => (v && v.length > 0) || 'File is required',
            ],
            name: '',
            files: '',
            success: '',
            attachments: [],
            files: null,
            dialog:{
                file_preview:false
            },
            previews: [],
            onboarding: 0
        }
    },
    watch: {

    },
    computed:{
        isImage() {
            const imageExtensions = ['jpg', 'jpeg', 'png'];
            return (file) => {
                const fileName = file.name;
                const fileExtension = fileName.split('.').pop().toLowerCase();
                return imageExtensions.includes(fileExtension);
            };
        },
        ...mapGetters([
            'GET_FILE_PREVIEWS'
        ])
    },
    methods: {
        removeItem(index) {
            this.previews.splice(index, 1);
            this.$store.commit('FILE_PREVIEWS',this.previews);
            if(this.previews.length == 0){
                this.dialog.file_preview = false
            }
        },
        next(){
            this.onboarding = this.onboarding + 1 === this.previews.length ? 0 : this.onboarding + 1
        },
        prev(){
            this.onboarding = this.onboarding - 1 < 0 ? this.previews.length - 1 : this.onboarding - 1
        },
        closeFilePreview(){
            this.dialog.file_preview = false;
            // this.$store.commit('FILE_PREVIEWS',[]);
            this.reset();
        },
        clearAttachments() {
            this.attachments = [];
            this.files = null;
        },
        onChange(e) {
            let selectedFiles = [];
            // this.previews = [];
            this.attachments = [];
            // this.dialog.file_preview = true;
            if(!e){
                this.dialog.file_preview = false;
            }
            if(e) {
                selectedFiles = e;
            }

            let fileTypes = ['jpg','jpeg','png','csv','txt','xlx','xls','xlsx','pdf','zip'];


            if(selectedFiles.length == 0) {
                return false;
            } else {
                for(let i=0;i<selectedFiles.length;i++) {
                    this.attachments.push(selectedFiles[i])
                    const file = selectedFiles[i];
                    const reader = new FileReader();
                    if (file.type.startsWith('image/')) {
                    // For images, convert to base64 for preview
                    reader.onload = (e) => {
                        this.previews.push({ name: file.name ,type: 'image', data: e.target.result });
                    };
                    reader.readAsDataURL(file);
                    } else if (file.type === 'application/pdf') {
                    // For PDF files, show using iframe
                        this.previews.push({ name: file.name ,type: 'pdf', data: URL.createObjectURL(file) });
                    }
                    else{
                        this.previews.push({ name: file.name});
                    }
                }

                let fileSizeChecker = _.compact(this.attachments).every(x=> {
                    return x.size < 8192000;
                });

                let fileTypeChecker = _.compact(this.attachments).every(x=> {
                    let fileName = x.name;
                    let fileNameLength = fileName.split('.').length;
                    let ext = fileName.split('.')[fileNameLength - 1];
                    return fileTypes.includes(ext);
                });

                // Emit Data
                if(!fileSizeChecker) {
                    swal.fire("", 'Each File size not more than 8MB', "warning").then(confirm=>{
                        if(confirm) {
                            this.attachments = [];
                            this.reset();
                        }
                    });

                    this.$emit('uploadedData', false);
                } else if(!fileTypeChecker) {
                    swal.fire("", 'Each File must be jpg, jpeg, png, csv, txt, xlx, xls, xlsx, pdf and zip type', "warning").then(confirm=>{
                        if(confirm) {
                            this.attachments = [];
                            this.reset();
                        }
                    });

                    this.$emit('uploadedData', false);
                } else {
                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }

                    this.$emit('uploadedData', {
                        attachments: this.attachments,
                        config: config,
                        preview : this.previews
                    });
                    if(this.action == 'Create'){
                        this.$store.commit('FILE_PREVIEWS',this.previews);
                    }
                }

            }
        },
        reset() {
            this.$refs.inputUploader.reset();
            this.clearAttachments();
            this.previews = [];
            this.attachments = [];
            this.dialog.file_preview = false
        },
        closePreviewWithFile(){
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            this.$emit('uploadedData', {
                attachments: this.attachments,
                config: config,
                preview:this.previews
            });
            this.dialog.file_preview = false;
            // this.reset();
        }
    }
}
</script>

<style>
</style>
