<template>
<div>
    <v-app id="load_bar" v-if="!loaded">
        <v-text-field color="info" loading disabled></v-text-field>
    </v-app>
    <v-app id="view_client_modal">
        <v-dialog v-model="outbound_dialog" persistent max-width="98%" scrollable>
            <v-card>
                <v-row class="m-0">
                    <v-col cols="pull-right-10 p-2">
                    <v-card-title>
                        <span class="headline">Outbound Details</span>
                    </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="clearOutboundItems(); $emit('closeView');">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text>
                    <v-row class="m-0">
                        <v-container fluid>
                            <v-row>
                                <div class="col-sm-12 col-md-6 text-left text-dark">
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">Doc Num: {{ outbound.doc_num }}</div>
                                        <div class="col-sm-12 col-md-4 p-0">Issuer: {{ outbound.user_name }}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">Outbound: {{ outbound.outbound_num }}</div>
                                        <div class="col-sm-12 col-md-4 p-0">Date Issued: {{ outbound.created_at | formatDateTime }}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">Warehouse: {{ outbound.warehouse_name }}</div>
                                        <div class="col-sm-12 col-md-4 p-0">Status: {{ outbound.status_text }}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">Remarks: {{ outbound.remarks }}</div>
                                        <div class="col-sm-12 col-md-4 p-0">PO Number: {{outbound.po_number}}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">Broker: {{ outbound.broker }}</div>
                                        <div class="col-sm-12 col-md-4 p-0"></div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 pull-right text-right">
                                    <v-btn class="ma-1" @click="exportToExcel()">Export to Excel</v-btn>
                                    <v-btn class="ma-1" @click="renderPDF('download')">Download PDF</v-btn>
                                </div>
                            </v-row>
                        </v-container>
                    </v-row>

                    <v-data-table
                        :headers="outboundItemsHeaders"
                        :items="outboundItems"
                        :items-per-page="500"
                        hide-default-footer
                        class="w-100">
                        <template v-slot:item="{ item }">
                            <tr style="pointer-events: none;"
                                :class="{'inactive-container-item': item.item.active === 0,
                                    'searched-container-item': item.model.includes(searchText) || item.name.includes(searchText),
                                    'over-receive-container-item': item.received_type == 1 ?
                                        item.quantity_master_box > item.quantity_received :
                                        item.quantity_master_box > ( item.quantity_received / item.quantity_master_box_conv ),
                                    'under-receive-container-item': item.received_type == 1 ?
                                        item.quantity_master_box < item.quantity_received :
                                        item.quantity_master_box < (item.quantity_received / item.quantity_master_box_conv)}" :key="item.id">

                                <td v-text="item.model"></td>
                                <td v-text="item.name"></td>
                                <td v-text="item.item_uom_text"></td>
                                <td v-text="item.item_type_text"></td>
                                <td class="text-center" v-text="item.quantity"></td>
                                <td class="text-center" v-text="item.quantity_master_box"></td>
                                <td class="text-center" v-text="item.received_type == 1 ? item.quantity_received : item.quantity_received / item.quantity_master_box_conv "></td>
                            </tr>
                        </template>
                    </v-data-table >

                    <div class="border-top">
                        <div class="card-body py-4 d-flex justify-content-center">
                            <span class="m-1">
                                <v-btn
                                    :loading="loading_approve"
                                    @click="approveOutbound()"
                                    v-if="userAccess.approve &&
                                            outbound.status_text=='pending' &&
                                            !is_receiving &&
                                            outbound.received_date !== null"
                                >
                                    Approve
                                </v-btn>
                            </span>
                            <span class="m-1">
                                <v-btn
                                    :loading="loading_reject"
                                    color="error"
                                    @click="rejectOutbound()"
                                    v-if="userAccess.reject &&
                                            outbound.status_text=='pending' &&
                                            !is_receiving"
                                >
                                    Reject
                                </v-btn>
                            </span>
                            <span class="m-1">
                                <v-btn
                                    :loading="loading_receive"
                                    @click="receiveOutbound()"
                                    v-if="userAccess.receive &&
                                            outbound.status_text=='pending' &&
                                            is_receiving &&
                                            outbound.received_date === null"
                                >
                                    Receive
                                </v-btn>
                            </span>
                        </div>
                    </div>
                </v-card-text>

            </v-card>
        </v-dialog>

        <v-container fluid style="display:none;">
            <div>
                <table id="outbound_details">
                    <tbody>
                        <tr>
                            <td>Warehouse:</td>
                            <td>{{ outbound.warehouse_name }}</td>
                            <td>Outbound:</td>
                            <td>{{ outbound.outbound_num }}</td>
                        </tr>
                        <tr>
                            <td>Remarks:</td>
                            <td>{{ outbound.remarks }}</td>
                            <td>Issuer:</td>
                            <td>{{ outbound.user_name }}</td>
                        </tr>
                        <tr>
                            <td>Doc Num</td>
                            <td>{{ outbound.doc_num }}</td>
                            <td>Date Issued:</td>
                            <td>{{ outbound.created_at | formatDate }}</td>
                        </tr>
                        <tr>
                            <td>Broker</td>
                            <td>{{ outbound.broker }}</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <table id="items_details">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Model</th>
                            <th>Name</th>
                            <th>UOM</th>
                            <th>Type</th>
                            <th>Quantity</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(outboundItem, index ) in outboundItems" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td>{{ outboundItem.model }}</td>
                            <td>{{ outboundItem.name }}</td>
                            <td>{{ outboundItem.item_uom_text }}</td>
                            <td>{{ outboundItem.item_type_text }}</td>
                            <td>{{ outboundItem.quantity }}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td>***** NOTHING FOLLOWS *****</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </v-container>
    </v-app>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import swal from 'sweetalert2'

export default {
  props: [
    'viewOutboundId',
    'searchText',
    'is_receiving',
    'user_access'
  ],
  mounted () {
    this.checkAccess();
  },
  data () {
    return {
      outboundItems: [],
      outboundItemsHeaders: [
        { text: 'Model', align: 'left', value: 'model', width: '20%' },
        { text: 'Name', align: 'left', value: 'name', width: '45%' },
        { text: 'UOM', align: 'left', value: 'item_uom_text' },
        { text: 'Type', align: 'left', value: 'item_type_text' },
        { text: 'Quantity UOM', align: 'center', value: 'quantity' },
        { text: 'Quantity MB', align: 'center', value: 'quantity_master_box' },
        { text: 'Quantity Received (MB)', align: 'center', value: 'quantity_received' }
      ],

      loaded: true,
      outbound_dialog: false,
      outbound: {
        doc_num: '',
        outbound_num: '',
        warehouse_id: '',
        warehouse_name: '',
        remarks: '',
        user_name: '',
        created_at: '',
        status: '',
        status_text: '',
        broker: '',
        po_number: ''
      },

      errors: [],
      userAccess: {
        approve: false,
        reject: false,
        receive: false
      },

                loading_approve: false,
                loading_reject: false,
                loading_receive: false,
            }
        },
        computed:{
            ...mapGetters([
                    'RECEIVE_OUTBOUND',
                    'APPROVE_OUTBOUND',
                    'REJECT_OUTBOUND',
                    'EXPORT_OUTBOUND',
                    'GET_ALL_OUTBOUND',
                    'USER_ACCESS'
                    ]),
        },
        methods: {
            checkAccess(){
                // this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id).then(response=>{
                    let val = this.user_access;
                    this.userAccess.approve = false;
                    this.userAccess.reject = false;
                    this.userAccess.receive = false;
                    if(val != "fail"){
                    val.forEach((e) => {
                        if (e.actions_code == "approve"){
                            this.userAccess.approve = true;
                        }
                        if (e.actions_code == "reject"){
                            this.userAccess.reject = true;
                        }
                        if (e.actions_code == "receive"){
                            this.userAccess.receive = true;
                        }
                    });
                    }
                // })
            },
            toggleLoadingButtons(val=false){
                this.loading_approve = val
                this.loading_reject = val
                this.loading_receive = val
            },
            approveOutbound(){
                this.toggleLoadingButtons(true)
                let inactiveItems = this.outboundItems.filter(
                    item => item.item.active === 0
                ).map(
                    item => {
                        return `- ${item.model} : ${item.name}`;
                    }
      );

      let impartialReceiveItems = this.outboundItems.filter(
        item => item.quantity_master_box != item.quantity_received
      ).map(
        item => {
          return `- ${item.model} : ${item.name}`
        }
      )

      let confirmInactive = `<br/><br/>Confirming approval will activate these inactive items.<br/><br/>${inactiveItems.join('<br/>')}`

                let impartialReceive = `<br/><br/>Confirming approval will receive partially received items.<br/><br/>${impartialReceiveItems.join('<br/>')}`

                let confirmApproval = 'Are you sure you want to Approve?';

      swal.fire({
        title: '',
        html: confirmApproval + (inactiveItems.length ? confirmInactive : '') + (impartialReceiveItems.length ? impartialReceive : ''),
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonText: 'Ok',
        showCancelButton: true,
        showCloseButton: true,
          confirmButtonColor: '397373',
        cancelButtonColor: 'grey',
        reverseButtons: true

                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch('approveOutbound',this.viewOutboundId).then(response => {
                            let data = this.APPROVE_OUTBOUND

                            if(data.error){
                                swal.fire("", 'Approve Outbound Failed', "warning");
                                this.toggleLoadingButtons(false)
                                return false
                            }
                            this.resetFields()
                            this.$emit('changeOutboundStatus')

                            this.outbound_dialog = false

                            swal.fire("", "Approve Outbound Succesfful", "success");
                            this.toggleLoadingButtons(false)
                        }).catch(error => {
                            swal.fire("An Error Occured", error, "error");
                            this.toggleLoadingButtons(false)
                        })
                    } else {
                        this.toggleLoadingButtons(false)
                    }
                });
            },
            rejectOutbound(){
                this.toggleLoadingButtons(true)
                swal.fire({
                    title: "",
                    text: "Are you sure you want to Reject?",
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton:true,
                    showCloseButton:true,
                    confirmButtonText:'Reject',
                    reverseButtons:true,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch('rejectOutbound',this.viewOutboundId).then(response => {
                            let data = this.REJECT_OUTBOUND
                            if(data.error){
                                swal.fire("", "Reject Outbound Failed", "warning");
                                this.toggleLoadingButtons(false)
                                return false
                            }
                            this.resetFields()
                            this.$emit('changeOutboundStatus')

                            this.outbound_dialog = false

                            swal.fire("", "Outbound Rejected", "success");
                            this.toggleLoadingButtons(false)
                        }).catch(error => {
                            swal.fire("An Error Occured", error, "error");
                            this.toggleLoadingButtons(false)
                        })
                    } else {
                        this.toggleLoadingButtons(false)
                    }
                });
            },
            receiveOutbound(){
                this.toggleLoadingButtons(true)
                swal.fire({
                    title: "",
                    text: "Are you sure you want to Receive? This will non-amendable afterwards.",
                    icon: "warning",
                    showConfirmButton:true,
                    showCloseButton:true,
                    confirmButtonText:'Confirm',
                    confirmButtonColor: '#397373',
                    showCancelButton:true,
                    reverseButtons:true,
                    cancelButtonColor : 'grey',

                })
                .then((result) => {
                    if (result.isConfirmed) {

                        this.$store.dispatch('receiveOutbound',this.viewOutboundId).then(response => {
                            let data = this.RECEIVE_OUTBOUND
                            if(data.error){
                                swal.fire("", "Outbound Receive Failed", "warning");
                                this.toggleLoadingButtons(false)
                                return false
                            }
                            this.resetFields()

                            this.outbound_dialog = false

                            swal.fire("", "Outbound Receive Successful", "success");
                            this.toggleLoadingButtons(false)
                        }).catch(error => {
                            swal.fire("An Error Occured", error, "error");
                            this.toggleLoadingButtons(false)
                        })
                    } else {
                        this.toggleLoadingButtons(false)
                    }
                });
            },
            clearOutboundItems(){
                this.resetFields()
                this.outbound_dialog = false
            },
            async exportToExcel(){
                let that = this

                const workbook = new this.$exceljs.Workbook();
                let headerStyle = {
                style:{font:{bold: true, name: 'Comic Sans MS'}},

      }
      that.loaded = false
      const payload = {
                    outbound_id : this.viewOutboundId
                }
                await this.$store.dispatch('exportOutbound',payload).then(response => {
                    const worksheet = workbook.addWorksheet('Raw Data')
                    worksheet.columns = [
                        {header:'OUTBOUND #',key:'outbound_num'},
                        {header:'DATE RECEIVED',key:'date_received'},
                        {header:'MODEL',key:'model'},
                        {header:'UOM',key:'uom'},
                        {header:'TYPE',key:'type'},
                        {header:'CATEGORY',key:'category'},
                        {header:'SRP',key:'srp'},
                    ]

                    this.EXPORT_OUTBOUND.forEach((e,i) => {
                        worksheet.addRow(e);
                        // SRP
                        worksheet.getCell(`G${i + 2}`).value = parseFloat(e.srp)
                        // worksheet.getCell(`G${i + 2}`).numFmt = `₱#,##0.00`
                    });
                    worksheet.getRow(`0${1}`).font = { bold: true }
                    worksheet.getColumn('G').numFmt = '₱#,##0'
                    worksheet.getColumn('G').alignment = {horizontal: 'right' };
                    }).catch(e => {
                        swal.fire('Error !', '', 'error')
                        console.log(e)
                        that.loaded = true
                    })
                    that.loaded = true

                    workbook.xlsx.writeBuffer().then((data) => {
                        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                        this.$filesaver.saveAs(blob, `${this.outbound.outbound_num}.xlsx`);
                    });
    },
    renderPDF (pdfAction) {
      var doc = new this.$jspdf('p', 'mm', 'letter')

                doc.setFontSize(14)
                doc.text(6, 15, 'Outbound Details')

                doc.autoTable({
                    html: '#outbound_details',
                    theme: 'plain',
                    margin: {top: 20, left: 6, right: 6},
                    columnStyles: {
                        0: {cellWidth: 25},
                        1: {cellWidth: 120},
                        2: {cellWidth: 26},
                        3: {cellWidth: 30},
                    }
                });

                doc.autoTable({
                    html: '#items_details',
                    startY: doc.autoTable.previous.finalY + 3,
                    margin: {left: 6, right: 6},
                    theme: 'grid',
                    headStyles: {
                        halign:'center',
                        fillColor: '#ffffff',
                        textColor: '#000000',
                        lineWidth: .2,
                        lineColor: '#000000',
                    },
                    styles: {
                        fontSize: 8
                    },
                    columnStyles: {
                        0: {cellWidth: 11, halign:'center', fontSize: 8, textColor: '#000000'},
                        1: {cellWidth: 27, halign:'center', fontSize: 7, textColor: '#000000'},
                        2: {halign:'center', fontSize: 7, textColor: '#000000'},
                        3: {cellWidth: 17, halign:'center', fontSize: 8, textColor: '#000000'},
                        4: {cellWidth: 11, halign:'center', fontSize: 8, textColor: '#000000'},
                        5: {cellWidth: 27, halign:'center', fontSize: 8, textColor: '#000000'}
                    },
                    bodyStyles: {
                        lineColor: '#000000',
                    }
                });

                if(pdfAction == 'download'){
                    doc.save('Outbound ' + this.outbound.doc_num + '.pdf');
                }else if(pdfAction == 'print'){
                    doc.autoPrint();
                    window.open(doc.output('bloburl'), '_blank');
                }

            },
            resetFields(){
                this.outboundItems = []
                this.outbound    = {
                    doc_num: '',
                    outbound_num: '',
                    warehouse_id: '',
                    warehouse_name: '',
                    remarks: '',
                    user_name: '',
                    created_at: '',
                    status: '',
                    status_text: '',
                    broker: '',
                }
            },

        },
        watch: {
            viewOutboundId: function(val){
                this.errors = []

                if(val != -1){
                    if (this.viewOutboundId != null && this.viewOutboundId > 0) {

                        this.$store.dispatch('viewOutbound',this.viewOutboundId).then(response => {
                            let data = response.data

                            this.outbound.doc_num                        = data.doc_num
                            this.outbound.outbound_num                  = data.outbound_num
                            this.outbound.warehouse_id                   = data.warehouse_id
                            this.outbound.warehouse_name                 = data.warehouse_name
                            this.outbound.remarks                        = data.remarks
                            this.outbound.user_name                      = data.user_name
                            this.outbound.created_at                     = data.created_at
                            this.outbound.status                         = data.status
                            this.outbound.status_text                    = data.status_text
                            this.outbound.received_by                    = data.received_by
                            this.outbound.received_date                  = data.received_date
                            this.outbound.broker                         = data.broker
                            this.outboundItems                           = data.outbound_items
                            this.outbound.po_number                      = data.po_number,
                            this.receive_type                             = data.receive_type
                            this.outbound_dialog                         = true
                        }).catch(error => {
                            if(error.response.status == 422){
                                this.errors = error.response.data.errors
                            }
                        });
                    }
                }

            },
            USER_ACCESS:{
                handler(val){
                    val.forEach((e) => {
                        if (e.actions_code == "approve"){
                            this.userAccess.approve = true;
                        }
                        if (e.actions_code == "reject"){
                            this.userAccess.reject = true;
                        }
                        if (e.actions_code == "receive"){
                            this.userAccess.receive = true;
                        }
                    });
                }
            }
        }
      }
    </script>
<style>
.v-avatar {
  margin: 15px 50px !important;
}
#client_modal_card {
  min-height: 160px !important;
}
</style>
