	<template>
		<v-form ref="form" v-model="valid" lazy-validation>
			<v-card>
				<v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
					<v-row :class="`m-0 ${ $vuetify.breakpoint.name == 'xs' ||  $vuetify.breakpoint.name == 'sm' ||  $vuetify.breakpoint.name == 'md' ? 'd-flex flex-column-reverse' : ''}`">
						<v-col :class="`p-2 ${  $vuetify.breakpoint.name == 'xs' ||  $vuetify.breakpoint.name  == 'sm' ||  $vuetify.breakpoint.name == 'md'  ?  'd-flex justify-center' :''}`" cols="12" sm="12" md="12" lg="11" xl="11">
							<span class="text-h5">{{ actions }} WEBMAIL ACCOUNT</span>
						</v-col>
						<v-col :class="`p-2 pull-right ${ $vuetify.breakpoint.name == 'xs' ||  $vuetify.breakpoint.name  == 'sm' ? 'pull-right' :''}`" cols="12" sm="12" md="12" lg="1" xl="1">
							<v-btn text icon small color="gray" class="float-right" @click="closeDialog()">
								<v-icon>mdi-close-circle</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-card-title>
				<v-card-text class="pa-2 pb-0">
					<v-container fluid class="py-5 pa-2">
						<v-row class="pa-2">
							<v-col v-if="ACTION == 'Submit'">
								<v-autocomplete
									v-model="form.employee_id"
									:items="dropdowns.employee_list"
									:disabled="disabled_view"
									item-text="text"
									item-value="value"
									label="Employee"
									auto-select-first
									outlined
									required
									dense
								></v-autocomplete>
							</v-col>
							<v-col>
								<v-text-field
									v-model="form.email"
									:disabled="disabled_view"
									label="Email Account"
									outlined
									required
									dense
								></v-text-field>
							</v-col>
							<v-col v-if="ACTION == 'Submit'">
								<v-autocomplete
									v-model="form.department"
									:items="dropdowns.department_list"
									:disabled="disabled_view"
									item-text="text"
									item-value="name"
									label="Department"
									auto-select-first
									outlined
									required
									dense
								></v-autocomplete>
							</v-col>
						</v-row>				
						<v-row v-if="ACTION == 'Update'" class="pa-2">													<!-- Security Section for Webmail Settings -->
							<v-col>
								<v-row>
									<v-col cols="12" sm="12" md="12" lg="12" xl="12">
										<h5 class="mt-4 pl-3">Security</h5>
										<v-divider></v-divider>
									</v-col>
								</v-row>
								<v-row no-gutters>
									<v-col cols="12" sm="12" md="12" lg="12" xl="12">
										<v-text-field
											v-model="form.password"
											:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
											:type="showPassword ? 'text' : 'password'"
											:disabled="disabled_view"
											@click:append="showPassword = !showPassword"
											label="New Password"
											dense
											outlined
										></v-text-field>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
						<v-row class="pa-2"> 																			<!-- Storage Section for Webmail Settings -->
							<v-col>
								<v-row>
									<v-col cols="12" sm="12" md="12" lg="12" xl="12">
										<h5 class="mt-4 pl-3">Storage</h5>
										<v-divider></v-divider>
									</v-col>
								</v-row>
								<v-row no-gutters>
									<v-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="GET_WEBMAIL_VIEW.diskquota != 'unlimited' && ACTION != 'Submit'">
										<h6>Current Storage Usage</h6>
									</v-col>
									<v-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="GET_WEBMAIL_VIEW.diskquota != 'unlimited' && ACTION != 'Submit'">
										<h2 style="font-size:15px;"> {{form.bar_used}} / {{form.bar_quota}} / {{form.bar_value}} %</h2>
									</v-col>
									<v-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="GET_WEBMAIL_VIEW.diskquota != 'unlimited' && ACTION != 'Submit'">
										<v-progress-linear 
											:color="form.bar_value <= 60 ?'light-green' :'deep-orange'"
											:value="form.bar_value" 
											height="20" 
											rounded
										/>
									</v-col>
									<v-col cols="8" sm="8" md="5" lg="5" xl="5" class="mt-3">
										<v-text-field
											v-model="form.edit_pop_quota"
											:disabled="disabled_view || quotaIsDisabled"
											label="Allocated Storage Space"
											append-icon="mdi-magnify"
											class="pa-1"
											type="number"
											outlined
											dense
										>
											<template v-slot:append>
												<span class="mt-1">MB</span>
											</template>
										</v-text-field>
									</v-col>

									<v-spacer></v-spacer>
									<v-col cols="12" sm="12" md="5" lg="5" xl="5" class="mt-1">
										<v-switch
											v-model="form.unlimited_quota"
											:disabled="disabled_view"
											:true-value="1"
											:false-value="0"
											label="Unlimited Storage"
											@change="toggleUnlimitedStorage()"
										></v-switch>
									</v-col>
								</v-row>
							</v-col>
						</v-row>			
						<v-row class="pa-2" v-if="ACTION != 'Submit'">													<!-- Restrictions Section for Webmail Settings -->	
							<v-col>
								<v-row>
									<v-col cols="12" sm="12" md="12" lg="12" xl="12">
										<h5 class="mt-4 pl-3" v-if="ACTION != 'Submit'">Restrictions</h5>
										<v-divider v-if="ACTION != 'Submit'"></v-divider>					
									</v-col>
								</v-row>
								<v-row no-gutters class="d-flex justify-content-center">
									<v-col cols="12" sm="4" md="4" lg="4" xl="4">
										<v-switch
											v-model="form.suspend_incoming"
											:true-value="0"
											:false-value="1"
											:disabled="disabled_view"
											label="Incoming Mail"
											class="pa-0"
										>
										</v-switch>
									</v-col>
									<v-col cols="12" sm="4" md="4" lg="4" xl="4">
										<v-switch
											v-model="form.suspend_outgoing"
											:disabled="disabled_view"
											:true-value="0"
											:false-value="1"
											label="Outcoming Mail"
											class="pa-0"
										>
										</v-switch>
									</v-col>
									<v-col cols="12" sm="4" md="4" lg="4" xl="4">
										<v-switch
											v-model="form.suspend_login"
											:true-value="0"
											:false-value="1"
											:disabled="disabled_view"
											label="Logging In"
											class="pa-0"
										>
										</v-switch>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions class="text-center">
				<v-row class="ma-1">
					<v-col cols="12" v-if="actions != 'VIEW' ">
						<v-btn :loading="loading.button" class="float-center" @click="actions == 'CREATE' ? addEmailAccount() : updateEmailAccount()" :disabled="disabled_view">
							<span>{{ actions }}</span>
						</v-btn>
					</v-col>
				</v-row>
				</v-card-actions>
			</v-card>
		</v-form>
	</template>
	<script>
	import Swal from 'sweetalert2'
	import { mapGetters } from 'vuex'
	export default {
	data() {
		return {
			valid: false,
			form: {},
			disabled_view:false,
			quotaIsDisabled: false,
			showPassword: false,
			loading:{
				button:false
			},
			breakpoint : this.$vuetify.breakpoint.name,
			dropdowns:{
				employee_list:[],
				department_list:[]
			},
			component_dispatches:[
				"getDepartment",
				"getEmployeeDataDropdown"
			]
		}
	},
	computed: {
		...mapGetters(['ACTION',
			'DIALOGS',
			'USERACCOUNT_LOAD',
			'GET_WEBMAIL_VIEW',
			'GET_WEBMAIL_NEW_DATA_TRIGGER',
			'GET_WEBMAIL_TAB_IS_ACTIVE',
			'GET_WEBMAIL_DIALOG_TITLE',
			'GET_WEBMAIL_DISPATCH',
			'GET_LIST_DEPARTMENT',
			'GET_EMPLOYEE_DATA'
		]),
		actions()
		{
			if (this.ACTION == 'Submit') {
				this.disabled_view = false;
				this.quotaIsDisabled = false;
				return 'CREATE'
			} 
			else if (this.ACTION == 'Update') {
			this.disabled_view = false;
				this.getWebEmail();
			return 'UPDATE'
			}
			else if (this.ACTION == 'View'){
			this.disabled_view = true;
				this.getWebEmail();
			return 'VIEW'
			}
		},
	},
	mounted(){   
		this.getAllDropdowns()
	},
	components: {
		},
	methods: {
		// convertToMegabytes() {
		// 	// Convert input value to megabytes (MB)
		// 	this.form.edit_pop_quota *= 1024;
		// },
		getAllDropdowns(){
			this.component_dispatches.forEach(el=>{
				this.$store.dispatch(el)
			})
		},
		closeDialog() {
			this.$store.commit('WEBMAIL_VIEW', [])
			this.$store.commit('DIALOG', false);
			this.$store.commit('ACTION', '')
			this.resetfields();
		},
		async getWebEmail(){
			this.form.email  						= this.GET_WEBMAIL_VIEW.email
			this.form.suspend_login    				= this.GET_WEBMAIL_VIEW.suspended_login == null ? 0 : this.GET_WEBMAIL_VIEW.suspended_login
			this.form.suspend_incoming    			= this.GET_WEBMAIL_VIEW.suspended_incoming
			this.form.suspend_outgoing    			= this.GET_WEBMAIL_VIEW.suspended_outgoing

			// Computating of Disk Used Vs Quota Assigned
			if(this.GET_WEBMAIL_VIEW.diskquota != 'unlimited'){
				this.form.unlimited_quota			= 0
				this.form.edit_pop_quota 			= parseInt(this.GET_WEBMAIL_VIEW.diskquota)
				this.form.bar_quota    				= this.GET_WEBMAIL_VIEW.humandiskquota
				this.form.bar_used    				= this.GET_WEBMAIL_VIEW.humandiskused
				
				let quotaValue = parseFloat(this.form.bar_quota);
				let usedValue = parseFloat(this.form.bar_used);
				let quotaString = this.form.bar_quota.toLowerCase();
				let valueString = this.form.bar_used.toLowerCase();
	
				let bar_quota;
				if (quotaString.includes('gb')) {
					bar_quota = quotaValue * 1024 * 1024 * 1024;
				} else if (quotaString.includes('mb')) {
					bar_quota = quotaValue * 1024 * 1024; 
				} else if (quotaString.includes('kb')) {
					bar_quota = quotaValue * 1024; 
				}
	
				let bar_used;
				if (valueString.includes('gb')) {
					bar_used = usedValue * 1024 * 1024 * 1024; 
				} else if (valueString.includes('mb')) {
					bar_used = usedValue * 1024 * 1024; 
				} else if (valueString.includes('kb')) {
					bar_used = usedValue * 1024; 
				}
				let percentage = (bar_used / bar_quota) * 100;
				this.form.bar_value = isNaN(percentage.toFixed(2)) ? 0 : percentage.toFixed(2) ;
				this.form.edit_pop_quota = parseInt(this.GET_WEBMAIL_VIEW.diskquota)

			}
			if(this.GET_WEBMAIL_VIEW.diskquota == 'unlimited'){
				this.form.unlimited_quota = 1;
			}
		},
		addEmailAccount() {
			this.$refs.form.validate()

			this.form.edit_pop_quota = this.form.unlimited_quota || this.form.unlimited_quota == 1  ? 0 : this.form.edit_pop_quota;

			this.loading.button = true;
			let email_swal_text = this.form.email ? '' : 'Email';
			let array_text = [email_swal_text]
			let filtered = array_text.filter(function (el) {
				return el.replace(',', '');
			})
			if (this.form.email) {
					this.$store.dispatch(this.GET_WEBMAIL_DISPATCH.add,this.form).then(response=>{
						this.loading.button = false;
						Swal.fire({
							text: response.data.message,
							icon: response.data.icon,
							allowOutsideClick: false,
						}).then((result) => {
							if (result.isConfirmed) {
									this.closeDialog()
								}
							});
					});
			} else {
				Swal.fire({
					text: `Fill Required Fields (${filtered})`,
					icon: 'warning',
					allowOutsideClick: false,
				});
			}
		},
		updateEmailAccount() {
			this.$refs.form.validate();
			this.form.edit_pop_quota = this.form.unlimited_quota || this.form.unlimited_quota == 1  ? 0 : this.form.edit_pop_quota;

			this.loading.button = true;
			let email_swal_text = this.form.email ? '' : 'Email';
			let array_text = [email_swal_text]
			let filtered = array_text.filter(function (el) {
				return el.replace(',', '');
			})
			if (this.form.email) {
				this.$store.dispatch(this.GET_WEBMAIL_DISPATCH.update, {
					id: this.form.email,
					payload: this.form,
				}).then(response=>{
					this.loading.button = false;
					Swal.fire(response.data.icon.toUpperCase(),response.data.message,response.data.icon).then(async(result) => {
						if(result.isConfirmed) {
							this.closeDialog()
						}
					});
				});
			} else {
				Swal.fire({
					text: `Fill Required Fields (${filtered})`,
					icon: 'warning',
					allowOutsideClick: false,
				});
			}
		},
		resetfields() {
			this.form = {
				email:'',
				department:'',
				suspend_login:'',
				suspend_incoming:'',
				suspend_outgoing:'',
				unlimited_quota: 0,
				edit_pop_quota : 0
			};
		},
		toggleUnlimitedStorage(){
			if(this.form.unlimited_quota) this.quotaIsDisabled = true
			else this.quotaIsDisabled = false
		}
	},
	watch: {
		DIALOGS:{
			handler(val){
				if(!val){
					this.resetfields()
				}
			}
		},
		'form.edit_pop_quota':{
			deep:true,
			handler(val){
				if(val != 0){
					this.form.unlimited_quota = false
				}
			}
		},
		GET_LIST_DEPARTMENT:{
			deep:true,
			handler(val){
					this.dropdowns.department_list = val
			}
		},
		GET_EMPLOYEE_DATA:{
			deep:true,
			handler(val){
				this.dropdowns.employee_list = val
			}
		}
	},
};
</script>
	