<template>
    <v-container class="container">
        <v-row class="ma-2">
            <v-col class="d-flex no-block align-items-center">
                <h4 class="page-title">HR Users</h4>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="mx-4 mb-2" v-if="userAccess.create">
                <v-btn  @click="showAddDialog()">
                    <span>
                        <v-icon>mdi-plus</v-icon>
                        Add
                    </span>
                </v-btn>
            </v-col>
        </v-row>
        <v-dialog v-model="dialog.add_edit_dialog" persistent max-width="70%" scrollable class="pa-4">
            <v-card>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">Manage User</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="dialog.add_edit_dialog = false; resetFields();">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-spacer></v-spacer>
                <v-card-text v-if="dialog.loaded">
                    <v-row class="mx-0 mt-2">
                        <v-col class="col-12">
                            <v-card class="mx-2 pa-4">
                                <v-autocomplete
                                    v-model="selection.users.selected"
                                    :items="action == 'add' ? filterUserSelection : selection.users.items"
                                    item-value="value"
                                    item-text="text"
                                    dense
                                    outlined
                                    label="Select User"
                                    clearable
                                    :disabled="action == 'edit' ? true : false"
                                    @change="removeSelectedUser(selection.users.selected)"
                                >
                                <template #append >
                                    <span style="color:red"><v-icon>mdi-menu-down</v-icon> *</span>
                                </template>
                                </v-autocomplete>
                                <v-autocomplete
                                    class="mt-2"
                                    :items="action == 'add' ? selection.head.items : selection.users.items"
                                    v-model="selection.head.selected"
                                    item-value="value"
                                    item-text="text"
                                    dense
                                    outlined
                                    label="Assign Head"
                                    clearable
                                    :disabled="!selection.users.selected"
                                    hide-details
                                >
                                <template #append >
                                    <span style="color:red"><v-icon>mdi-menu-down</v-icon> *</span>
                                </template>
                                </v-autocomplete>
                                <v-row class="mx-0 pt-1 pb-1">
                                    <v-col class="col-12">
                                        <v-autocomplete
                                            v-model="selection.departments.job_levels"
                                            :items="selection.job_levels.items"
                                            class="mt-2"
                                            item-text="text"
                                            item-value="value"
                                            label="Job Level"
                                            auto-select-first
                                            hide-details
                                            small-chips
                                            clearable
                                            outlined
                                            chips
                                            multiple
                                            dense
                                            deletable-chips
                                            return-object
                                            flat>
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row class="mx-0 mt-0">
                                    <v-col class="col-12">
                                        <v-autocomplete class="mt-2"
                                            style="max-height:200px; overflow-y: auto;"
                                            :items="action == 'add' && hasValue == 0? selection.departments.items : editFilterDepartment"
                                            v-model="selection.departments.selected"
                                            item-value="value"
                                            item-text="text"
                                            dense
                                            outlined
                                            label="Assign Department"
                                            clearable
                                            :disabled="!selection.users.selected"
                                            return-object
                                            multiple
                                            small-chips
                                            @change="triggerSelectAll()"
                                            deletable-chips
                                            @click:clear="clearFields('add_Department')"
                                        >
                                        <template #append >
                                            <span style="color:red"><v-icon>mdi-menu-down</v-icon> *</span>
                                        </template>
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row class="mx-0 mt-0">
                                    <v-col class="col-12">
                                        <v-autocomplete
                                            v-model="selection.departments.locations"
                                            :items="dropdown.location_lists"
                                            :disabled="selection.departments.selected.length == 0"
                                            class="mt-2"
                                            item-text="text"
                                            item-value="value"
                                            label="Location"
                                            auto-select-first
                                            hide-details
                                            small-chips
                                            clearable
                                            outlined
                                            chips
                                            multiple
                                            dense
                                            deletable-chips
                                            return-object
                                            flat>
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>

                                <v-checkbox
                                    class=""
                                    v-model="isTeamLeader"
                                    :label="'Team Leader'"
                                    color="blue"
                                    hide-details
                                >
                                </v-checkbox>
                                <v-card-actions class="d-flex justify-end mt-2">
                                    <v-btn
                                        small
                                        @click="action == 'add' ? addToList() : editList()"
                                        :disabled="!selection.departments.selected"
                                    >
                                    Add
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                            <v-alert v-if="alert.isSuccessfullyAdded"
                                dense
                                text
                                class="mt-2 mx-2"
                                :type="alert.type"
                                transition="scale-transition">
                                    {{alert.msg}}
                            </v-alert>
                            <v-card v-if="action == 'add'" class="mt-2 mx-2" style="overflow-y: auto">
                                <v-card-subtitle>Department's List
                                    <v-btn v-if="action == 'add' && hasValue == 1" text small color="red" @click="clearFields('clear_list');">Clear</v-btn>
                                    <v-row>
                                        <v-col>
                                            <v-text-field v-model="search" dense label="Search" class="mb-2" style="float:right"></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-card-subtitle>
                                <v-data-table
                                    :headers="headers"
                                    :items="departmentLists"
                                    :search="search"
                                    :items-per-page="10"
                                    dense
                                    class="w-100"
                                >
                                    <template v-slot:[`item.locations`]="{ item }">
                                        <v-chip v-for="(data, i) in item.locations" :key="i" x-small>
                                            {{ data.text }}
                                        </v-chip>
                                    </template>
                                </v-data-table>
                            </v-card>
                            <v-card v-else class="mt-2 mx-2" style="overflow-y: auto">
                                <v-card-subtitle>Department's List
                                    <v-row>
                                        <v-col>
                                            <v-text-field v-model="search" dense label="Search" class="mb-2" style="float:right"></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-card-subtitle>
                                <v-data-table
                                    :headers="headers"
                                    :items="departmentLists"
                                    :search="search"
                                    :items-per-page="10"
                                    dense
                                    class="w-100"
                                >
                                    <template v-slot:item="{ item, index }">
                                        <tr v-if="item.editActive == false">
                                            <td>
                                                <span> {{ item.text }}</span>
                                            </td>
                                            <td>
                                                <v-chip v-for="(data, i) in item.locations" :key="i" x-small>
                                                    {{ data.text }}
                                                </v-chip>
                                            </td>
                                            <td>
                                                <v-btn text icon color="blue" >
                                                    <v-icon class="btn-action" small @click="item.editActive = true">mdi-pencil</v-icon>
                                                </v-btn>
                                                <v-btn text icon color="red" >
                                                    <v-icon class="btn-action" small @click="deleteDepartment(item.value)">mdi-delete</v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                        <tr v-if="item.editActive == true">
                                            <td>
                                                <span> {{ item.text }}</span>
                                            </td>
                                            <td>
                                                <v-row no-gutters>
                                                    <v-col>
                                                        <v-autocomplete
                                                            v-model="item.locations"
                                                            :items="filterLocationByDepartment(item.value)"
                                                            item-text="text"
                                                            item-value="value"
                                                            auto-select-first
                                                            hide-details
                                                            small-chips
                                                            clearable
                                                            outlined
                                                            chips
                                                            multiple
                                                            dense
                                                            deletable-chips
                                                            return-object
                                                            flat>
                                                        </v-autocomplete>
                                                    </v-col>
                                                </v-row>
                                            </td>
                                            <td>
                                                <v-row no-gutters>
                                                    <v-col>
                                                        <v-autocomplete
                                                            v-model="item.job_levels"
                                                            :items="selection.job_levels.items"
                                                            item-text="text"
                                                            item-value="value"
                                                            auto-select-first
                                                            hide-details
                                                            small-chips
                                                            clearable
                                                            outlined
                                                            chips
                                                            multiple
                                                            dense
                                                            deletable-chips
                                                            return-object
                                                            flat>
                                                        </v-autocomplete>
                                                    </v-col>
                                                </v-row>
                                            </td>

                                            <td class="d-flex justify-content-center align-items-center">
                                                <v-btn
                                                    icon
                                                    small
                                                    @click="item.editActive = false"
                                                    color="blue"
                                                >
                                                    <v-icon>mdi-check-bold</v-icon>
                                                </v-btn>

                                                <v-btn text icon color="red" >
                                                    <v-icon class="btn-action" small @click="deleteDepartment(item.value)">mdi-delete</v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </template>
                                    <!-- <template v-slot:[`item.locations`]="{ item }">
                                        <v-chip v-for="(data, i) in item.locations" :key="i" x-small>
                                            {{ data.text }}
                                        </v-chip>
                                    </template> -->
                                    <!-- <template v-slot:[`item.action`]="{ item }">
                                        <v-btn text icon color="blue" >
                                            <v-icon class="btn-action" small @click="item.editActive = true">mdi-pencil</v-icon>
                                        </v-btn>
                                        <v-btn text icon color="red" >
                                            <v-icon class="btn-action" small @click="deleteDepartment(item.value)">mdi-delete</v-icon>
                                        </v-btn>
                                    </template> -->
                                </v-data-table>
                            </v-card>
                        </v-col>
                        <!-- <v-col class="col-6">
                            <v-card class="mx-2 pa-4">
                                <v-autocomplete
                                    :items="action == 'add' ? selection.head.items : selection.users.items"
                                    v-model="selection.head.selected"
                                    item-value="value"
                                    item-text="text"
                                    dense
                                    outlined
                                    label="Assign Head"
                                    clearable
                                    :disabled="!selection.users.selected"
                                >
                                <template #append >
                                    <span style="color:red"><v-icon>mdi-menu-down</v-icon> *</span>
                                </template>
                                </v-autocomplete>
                            </v-card>
                        </v-col> -->
                    </v-row>
                </v-card-text>
                <v-card-text v-else>
                    <div class="text-center">
                        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                    </div>
                </v-card-text>
                <v-card-actions class="d-flex justify-end">
                    <v-btn
                        class="pr-2"
                        color="secondary"
                        text
                        @click="dialog.add_edit_dialog = false; resetFields();"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        class="pr-2"
                        :disabled="!selection.head.selected || !selection.users.selected"
                        :loading="loading.button"
                        @click="action == 'add' ? addNewData() : updateData()"
                    >
                        {{action == "add" ? "Submit" : "Update"}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-row class="mt-5">
            <v-col class="col-12">
                <v-card class="mx-4">
                    <v-row class="pt-2 pl-5">
                        <v-col class="col-2">
                            <v-autocomplete
                                :items="selection.users.items"
                                v-model="searchUserSelection"
                                item-value="value"
                                item-text="text"
                                dense
                                outlined
                                label="Select User"
                                clearable
                                @click:clear="clearFields('user')"
                            />
                        </v-col>
                        <v-col class="col-2">
                            <v-autocomplete
                                :items="selection.users.items"
                                v-model="searchHeadSelection"
                                item-value="value"
                                item-text="text"
                                dense
                                outlined
                                label="Select Head"
                                clearable
                                @click:clear="clearFields('head')"
                            />
                        </v-col>
                        <v-col>
                            <v-btn text @click="clearFilter()">Clear</v-btn>
                            <v-btn class="mx-4" style="float:right" @click="exportToExcel()">Export</v-btn>
                        </v-col>
                    </v-row>
                    <v-row class="pl-5 pb-5">
                        <v-col class="col-4">
                            <v-autocomplete
                                :items="selection.departments.items"
                                v-model="searchDepartmentSelection"
                                item-value="value"
                                item-text="text"
                                dense
                                outlined
                                label="Select Departments"
                                clearable
                                @click:clear="clearFields('Department')"
                                />
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="mx-4 pt-5">
                    <v-data-table
                        v-model="dataTable"
                        :headers="hrUserList.datatable.headers"
                        :items="hrUserList.data"
                        :loading="!hrUserList.datatable.loaded"
                        :options.sync="options"
                        :items-per-page="itemsPerPage"
                        :server-items-length="hrUserList.datatable.total"
                        class="w-100"
                    >
                    <template v-slot:[`item.head_name`]="{ item }">
                        <span class="text-nowrap">
                            {{ item.head_name == null ? "N/A" : item.head_name}}
                        </span>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                        <span class="text-nowrap">
                            <v-btn text icon color="orange"  v-if="userAccess.view">
                                <v-icon class="btn-action" small @click="viewUser(item.id,item.head_id)">mdi-eye</v-icon>
                            </v-btn>
                            <v-btn text icon color="blue" v-if="userAccess.edit">
                                <v-icon class="btn-action" small  @click="editUser(item.id,item.head_id, item.hr_user_id)">mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn text icon color="red" v-if="userAccess.delete">
                                <v-icon class="btn-action" small  @click="deleteData(item.id,item.name, item.hr_user_id)">mdi-delete</v-icon>
                            </v-btn>
                        </span>
                    </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="dialog.viewUsers" persistent max-width="75%" scrollable class="pa-4">
            <v-card>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">Information</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="dialog.viewUsers = false; resetFields();">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-spacer></v-spacer>
                <v-card-text v-if="dialog.loaded">
                    <v-row class="mt-1 ml-2">
                        <v-col>
                            <span>
                                <span class="font-weight-bold">Name:</span>
                                {{selectedUser.name}}
                            </span>
                        </v-col>
                    </v-row>
                    <v-row class="mt-1 ml-2">
                        <v-col>
                            <span>
                                <span class="font-weight-bold">Position:</span>
                                {{selectedUser.job_title}}
                            </span>
                        </v-col>
                    </v-row>
                    <v-row class="mx-0 mt-3">
                        <v-card class="mt-2 mx-2 w-100">
                            <v-card-subtitle>Member's List Total: {{hrManagementMembers.length > 0 ? hrManagementMembers.length : 0}} </v-card-subtitle>
                            <v-chip v-for="(item,i) in hrManagementMembers" :key=i
                                class="ml-2 mr-2 mt-1 mb-1"
                                :color="memberChipColor"
                                text-color="white"
                                small
                                >
                                {{item}}
                            </v-chip>
                        </v-card>
                    </v-row>
                    <v-row class="mx-0 mt-2">
                        <v-card class="mt-2 mx-2 w-100 mb-2">
                            <v-card-subtitle>Department's List Total: {{acctManagement.length}} </v-card-subtitle>
                                <v-row class="mx-2">
                                    <v-col>
                                        <v-text-field v-model="chipSearch" dense label="Search" class="mb-2" style="float:left"></v-text-field>
                                    </v-col>
                                </v-row>
                                <div class="pa-2" style="column-count:4">
                                    <div v-for="(item,i) in searching" :key=i>
                                        {{item}}
                                    </div>
                                </div>
                        </v-card>
                    </v-row>
                </v-card-text>
                <v-card-text v-else>
                    <div class="text-center">
                        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import swal from 'sweetalert2';
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
           action:'',
           dialog:{
                add_edit_dialog:false,
                viewUsers:false,
                loaded:false
           },
           selection:{
                users:{
                    items:[],
                    selected:'',
                },
                head:{
                    items:[],
                    selected:'',
                },
                departments:{
                    items:[],
                    selected: [],
                    locations: [],
                    job_levels: []
                },
                job_levels:{
                    items:[],
                    selected: [],
                }
           },
           headers:[
                {text: 'Departments',value:'text'},
                {text: 'Locations',value:'locations'},
                // {text: 'Job Level',value:'job_levels'},
           ],
           action_header: { text: "Action", align: "center", width:"10%", value: "action", sortable: false },
           dataTable:[],
           hrUserList:{
                datatable:{
                    search:'',
                    headers: [
                        {text: 'USER', value: 'name'},
                        {text: 'POSITION', value: 'job_title'},
                        {text: 'HEAD', value: 'head_name'},
                        {text: 'ACTIONS', align: 'center', value: 'action'},
                    ],
                    loaded:true,
                    total:0,
                },
                data:[],
                existingUser:[],
           },
            options:{},
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            itemsPerPage: 10,
            viewUserAccess:[],
            acctManagement:[],
            selectedUser:[],
            departmentLists:[],
            hrManagementMembers:[],
            memberChipColor:"green",
            searchUserSelection:'',
            searchDepartmentSelection:'',
            searchHeadSelection:'',
            userAccess:{
                view:false,
                edit:false,
                create:false,
                delete:false
            },
            searchInArrayField:'',
            search:'',
            chipSearch:'',
            hasValue:0,
            alert:{
                isSuccessfullyAdded:false,
                msg:'',
                type:'',
            },
            displayHeaderOnce:false,
            isTeamLeader:0,
            component_dispatches:[
                'getDepartment',
                'getJobLevelSelection'
			],
            hr_user_id: '',
            dropdown:{
                location_lists: []
            },
            loading:{
                button: false
            }
        }
    },
    async mounted() {
        await this.getAllData()
        this.$store.dispatch('getBioMetricLocationSelection');
        this.getHRUsers()
        this.getAllDropdowns();
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
    },
    methods:{
         getAllDropdowns(){
            this.component_dispatches.forEach(el=>{
                this.$store.dispatch(el)
            })
        },
        showAddDialog(){
            this.dialog.add_edit_dialog = true
            this.action = 'add'
            this.dialog.loaded = true
            this.getHRUsers()
        },
        getHRUsers(){
            this.$store.dispatch('getHrUsers').then(response=>{
                this.selection.users.items = response
            })
        },
        removeSelectedUser(user_id){
           this.selection.head.items = this.selection.users.items.filter(e=>{
                return e.value != user_id
           })
           this.selection.head.items.unshift({
                value:0,
                text:"N/A",
           })
        },
        addNewData(){
            this.loading.button = true;
            let payload = {
                user_id: this.selection.users.selected,
                department_id: this.departmentLists.map(e=>{return e.value}),
                locations: this.departmentLists.map(e=>{return e.locations.map(x => {return x.value})}),
                job_levels: this.departmentLists.map(e=>{return e.job_levels.map(x => {return x.value})}),
                head_id: this.selection.head.selected,
                team_head: this.isTeamLeader,
            }
            this.$store.dispatch('addHRUsers', payload).then(response=>{
                swal.fire(
                    response.icon.toUpperCase(),
                    response.msg,
                    response.icon
                )

                if(response.icon == 'success'){
                    this.loading.button = false;
                    this.dialog.add_edit_dialog = false
                }
                this.resetFields();
                this.getAllData();
            }).catch(err=>{
                swal.fire(
                    "Error",
                    "",
                    "error"
                )
                console.log(err)
                this.loading.button = false;
            })
        },
        resetFields(){
            this.selection.users.selected = ''
            this.selection.departments.selected = ''
            this.selection.head.selected = ''
            this.departmentLists = []
        },
        getAllData(){
            this.hrUserList.datatable.loaded = false
            this.hrUserList.data = [];
            let payload = {
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                user_id: this.searchUserSelection,
                department_id: this.searchDepartmentSelection,
                head_id: this.searchHeadSelection,
            }
            this.$store.dispatch('getHRUsersTable', payload).then(response => {
                this.hrUserList.data = response.data.data
                this.hrUserList.datatable.loaded = true
                this.hrUserList.datatable.total = response.data.total
                this.hrUserList.existingUser = response.all_user
            }).catch(error => {
                console.log(error)
            })
        },
        viewUser(id,head_id){
            this.dialog.viewUsers = true;
            this.dialog.loaded = false;
            let payload = {
                head_id: id,
                active_id: head_id,
            }
            this.$store.dispatch('viewUsers',payload).then(response=>{
                let data = response.under_user

                this.acctManagement = data.map(e=>{
                    return e.department_name;
                })
                this.acctManagement = _.uniqBy(this.acctManagement)
                this.hrManagementMembers = data.map(e=>{
                    return e.name;
                })
            })
            let user_payload = {
                head_id: id,
            }
            this.$store.dispatch('getSelectedUser',user_payload).then(response=>{
                this.selectedUser = response[0];
                this.hrManagementMembers = this.hrManagementMembers.filter(e=>{
                    return e != this.selectedUser.name;
                })
                this.hrManagementMembers = _.uniqBy(this.hrManagementMembers)

                if(this.hrManagementMembers.length == 0){
                    this.hrManagementMembers.length = 0;
                    this.memberChipColor = "grey"
                    this.hrManagementMembers = ["You dont have any members yet"]
                }
                else{
                    this.memberChipColor = "green"
                }
                this.dialog.loaded = true;
            })
        },
        removeChip(item){
            if(!!item){
                this.departmentLists = this.departmentLists.filter(e=>{
                    return e != item
                })
            }
            if(this.action == "edit"){
                this.selection.departments.selected = ''
                this.editFilterDepartment.push(item)
            }
            else{
                this.selection.departments.items.unshift(item)
            }
        },
        editUser(id,head_id, hr_user_id){
            this.hr_user_id = hr_user_id;
            if(!this.displayHeaderOnce){
                this.headers.splice(3, 0, this.action_header)
                this.displayHeaderOnce = true;
            }
            this.showAddDialog();
            this.action = 'edit'
            this.selection.users.selected = id
            this.selection.head.selected = head_id
            this.dialog.loaded = false
            let payload = {
                head_id: id,
                active_id: head_id,
                method:'edit'
            }
            this.$store.dispatch('viewUsers',payload).then(response=>{
                let data = response.under_user
                this.isTeamLeader = data[0].team_head;
                this.departmentLists = data.map(e=>{
                    return {
                        'text':e.department_name,'value':e.department_id,
                        'locations': e.locations,
                        'job_levels': e.job_levels,
                        'editActive': e.editActive,
                    }
                })
                this.selection.departments.job_levels = data[0].job_levels
                this.dialog.loaded = true
            })
        },
        updateData(){
            this.loading.button = true;
            let payload = {
                id: this.hr_user_id,
                user_id: this.selection.users.selected,
                department_id: this.departmentLists.map(e=>{return e.value}),
                locations: this.departmentLists.map(e=>{return e.locations.map(x => {return x.value})}),
                job_levels: this.selection.departments.job_levels,
                // job_levels: this.departmentLists.map(e=>{return e.job_levels.map(x => {return x.value})}),
                head_id: this.selection.head.selected,
                team_head: this.isTeamLeader,
            }
            this.$store.dispatch('updateHrUser',payload).then(response=>{
                swal.fire(
                    response.icon.toUpperCase(),
                    response.msg,
                    response.icon
                )
                if(response.icon == 'success'){
                    this.loading.button = false;
                    this.dialog.add_edit_dialog = false
                }
                this.resetFields();
                this.getAllData();
            }).catch(err=>{
                swal.fire(
                    "Error",
                    "",
                    "error"
                )
                console.log(err)
                this.loading.button = false;
            })
        },
        deleteData(id,name, hr_user_id){
            swal.fire({
                title: "Delete",
                text: `Are you sure you want to delete ${name}'s access ?`,
                icon: 'question',
                showConfirmButton:true,
                confirmButtonText:'Confirm',
                showCancelButton:true,
                showCloseButton:true,
                confirmButtonColor: '397373',
                cancelButtonColor:'grey',
                reverseButtons:true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    let payload = {
                        user_id: id,
                        id: hr_user_id
                    }
                    this.$store.dispatch('deleteHrUser',payload).then(response => {
                        this.getAllData();
                        swal.fire('',"Delete Successful!","success");
                    }).catch(error => {
                        swal.fire("An Error Occured", error, "error");
                    })
                }
            });
        },
        clearFields(dropdown) {
            if(dropdown == "user"){
                this.searchUserSelection = ''
            }
            else if(dropdown == "Department"){
                this.searchDepartmentSelection = ''
            }
            else if(dropdown == "head"){
                this.searchHeadSelection = ''
            }
            else if(dropdown == "add_Department"){
                this.selection.departments.items.unshift({
                    value: 'all',
                    text: 'SELECT ALL'
                })
                this.selection.departments.selected = []
            }
            else if(dropdown == "clear_list"){
                this.hasValue = 0
                this.departmentLists = []
            }
            this.getAllData();
        },
        clearFilter(){
            this.searchUserSelection = ''
            this.searchDepartmentSelection = ''
            this.searchHeadSelection = ''
            this.getAllData()
        },
        triggerSelectAll(){
            let temp_id = this.selection.departments.selected.map(e=>e.value);
            if(temp_id.includes('all')){
                this.selection.departments.items.shift();
                if(this.departmentLists.length == 0){
                    this.selection.departments.selected = this.selection.departments.items
                }
                else{
                    this.selection.departments.selected = this.editFilterDepartment
                }
            }
        },
        deleteDepartment(id){
            this.departmentLists= this.departmentLists.filter(e=> e.value != id);
        },
        addToList(){
            if(this.departmentLists.length == 0){
                this.selection.departments.selected.locations = [];

                this.selection.departments.selected.forEach((e)=>{
                    e.locations = this.selection.departments.locations
                    e.job_levels = this.selection.departments.job_levels

                    e.editActive = false
                })
                this.departmentLists = this.selection.departments.selected
                this.selection.departments.items = this.editFilterDepartment
                this.hasValue = 1
            }
            else{
                this.selection.departments.selected.forEach((e)=>{
                    e.locations = this.selection.departments.locations
                    e.job_levels = this.selection.departments.job_levels
                    e.editActive = false
                })
                this.departmentLists.push(...this.selection.departments.selected)
            }
            if(this.selection.departments.selected.length > 0){
                this.triggerAlert('Added Successfully','success');
            }
            else{
                this.triggerAlert('Please select department','warning');
            }
            this.selection.departments.selected = []
            this.selection.departments.locations = []
            // this.selection.departments.job_levels = []
        },
        editList(){
            this.selection.departments.selected.forEach((e)=>{
                e.locations = this.selection.departments.locations
                e.job_levels = this.selection.departments.job_levels
                e.editActive = false
            })
            this.departmentLists.push(...this.selection.departments.selected);
            this.departmentLists = _.uniqBy(this.departmentLists);
            this.triggerAlert();
            this.selection.departments.locations = []
            // this.selection.departments.job_levels = []
        },
        triggerAlert(msg,type){
            this.alert.isSuccessfullyAdded = true
            this.alert.msg = msg
            this.alert.type = type
            setTimeout(() => {
                this.alert.isSuccessfullyAdded = false;
            }, 2000);
        },
        async exportToExcel(){
            let that = this
            const workbook = new this.$exceljs.Workbook();
            let temp = [];
            return new Promise((resolve, reject) => {
                this.hrUserList.data.forEach(async (e,i)=>{
                    this.hrUserList.datatable.loaded = false;
                    let payload = {
                        head_id: e.id,
                        active_id: e.head_id,
                        // url: 'viewUsers'
                    }
                    await this.$store.dispatch('viewUsers',payload).then(response=>{
                        temp.push(...response.under_user)
                    })
                    if(this.hrUserList.data.length - 1 == i) resolve()
                })
            }).then(res=>{
                const worksheet = workbook.addWorksheet('Raw Data')
                // let filterUserAndId = temp.map(e=>{
                //     return {name:e.name,id:e.id};
                // })

                // filterUserAndId = filterUserAndId.map(JSON.stringify);
                // let uniqueSet = new Set(filterUserAndId);
                // let uniqueArray = Array.from(uniqueSet).map(JSON.parse);

                // let columns = uniqueArray.map(e=>{
                //     return {header:e.name,key:e.id}
                // })

                worksheet.columns = [
                    {header:'USER',key:'name'},
                    {header:'HEAD',key:'head_name'},
                    {header:'DEPARTMENT NAME',key:'department_name'},
                ]
                temp.forEach(e => {
                    worksheet.addRow(e);
                });
                worksheet.getRow(`0${1}`).font = { bold: true }
                this.hrUserList.datatable.loaded = true;

                workbook.xlsx.writeBuffer().then((data) => {
                    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                    this.$filesaver.saveAs(blob, `HR Users.xlsx`);
                });
            })

        },
        filterLocationByDepartment(department_id){
            let filtered_selection = [];
            let filteredAreas = this.GET_LIST_DEPARTMENT.find((x) => x.value == department_id).areas;
            // Check if the array already exists in filtered_selection
            filteredAreas.forEach((area) => {
                if (!filtered_selection.find((x)=> x.value === area.value)) {
                    filtered_selection.push(area);
                }
            });
            return filtered_selection
        },
        getJobLevelName(data){
            let job_level_details = this.GET_JOB_LEVEL_SELECTION.find((x) => x.value == data.value);
            if(job_level_details){
                return job_level_details.text;
            }
            return ''
        }
    },
    computed:{
        filterUserSelection(){
            let temp = []
            this.selection.users.items.forEach(e=>{
                if (!this.hrUserList.existingUser.includes(e.value)){
                    temp.push(e)
                }
            })
            return temp;
        },
        editFilterDepartment(){
            let existingDepartment = this.departmentLists.map(e=>{
                return Number(e.value)
            })
            let temp = []
            this.selection.departments.items.forEach(e=>{
                if (!existingDepartment.includes(e.value)){
                    temp.push(e)
                }
            })
            return temp;
        },
        searching () {
            if (!this.chipSearch) return this.acctManagement
            const search = this.chipSearch.toLowerCase()
            return this.acctManagement.filter(item => {
                const text = item.toLowerCase()
                return text.indexOf(search) > -1
            })
        },
        ...mapGetters([
            'USER_ACCESS',
            'GET_LIST_DEPARTMENT',
            'GET_BIO_METRIC_LOCATION_SELECTION',
            'GET_JOB_LEVEL_SELECTION'
        ]),
    },
    watch:{
        options: {
            handler() {
                this.getAllData()
            },
            deep: true,
        },
        searchUserSelection() {
            if(!!this.searchUserSelection) {
                this.getAllData();
            } else {
                this.searchUserSelection = '';
            }
        },
        searchDepartmentSelection() {
            if(!!this.searchDepartmentSelection) {
                this.getAllData();
            } else {
                this.searchDepartmentSelection = '';
            }
        },
        searchHeadSelection() {
            if(!!this.searchHeadSelection) {
                this.getAllData();
            } else {
                this.searchHeadSelection = '';
            }
        },
        GET_LIST_DEPARTMENT:{
            handler(val){
                // this.loading.Department_selection = false
                this.selection.departments.items = val
            },
        },
        GET_JOB_LEVEL_SELECTION:{
            handler(val){
                this.selection.job_levels.items = val
            }
        },
        USER_ACCESS:{
            handler(val){
                this.userAccess.create = false;
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                if (val != "fail") {
                    val.forEach((e) => {
                        if (e.actions_code == "create") {
                            this.userAccess.create = true;
                        }
                        if (e.actions_code == "edit") {
                            this.userAccess.edit = true;
                        }
                        if (e.actions_code == "view") {
                            this.userAccess.view = true;
                        }
                        if (e.actions_code == "delete") {
                            this.userAccess.delete = true;
                        }
                    });
                }
            }
        },
        GET_BIO_METRIC_LOCATION_SELECTION:{
            handler(val){
                this.dropdown.location_lists = val;
            }
        },
        'selection.departments.selected':{
            handler(val){
                if (val.length) {
                    let filtered_selection = [];

                    val.forEach((e, i) => {
                        let filteredAreas = this.GET_LIST_DEPARTMENT.find((x) => x.value == e.value).areas;

                        // Check if the array already exists in filtered_selection
                        filteredAreas.forEach((area) => {
                            if (!filtered_selection.find((x)=> x.value === area.value)) {
                                filtered_selection.push(area);
                            }
                        });
                    });
                    this.dropdown.location_lists = filtered_selection
                }else{
                    this.dropdown.location_lists = this.GET_BIO_METRIC_LOCATION_SELECTION;
                    this.selection.departments.locations = [];
                }
            },
        }
    }
};
</script>
