<template>
    <div class="pb-4 ml-0" style="width:100%;border-radius:0px">
        <v-card class="pa-2">
            <v-container>
                <v-row class="mb-4">
                    <v-spacer />
                    <v-col>
                        <v-btn
                            class="float-right mx-1"
                            @click="exportToExel()"
                        >
                            export
                        </v-btn>
                        <v-btn
                            class="float-right"
                            @click="getAllRfs(); options.page = 1"
                        >
                            filter
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="ml-2">
                    <v-spacer />
                    <v-col sm="12" md="12" lg='2' class="mr-2">
                        <v-text-field
                            v-model="search"
                            label="Search RF#"
                            single-line
                            hide-details
                            clearable
                            class="pa-0 ma-0"
                            @keydown.enter="getAllRfs(); options.page = 1"
                            @click:clear="search='';getAllRfs()"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col sm="12" md="12" lg='2' class="mr-3">
                        <v-text-field
                            v-model="searchItem"
                            label="Search Item Name / Model"
                            single-line
                            hide-details
                            clearable
                            class="pa-0 ma-0"
                            append-outer-icon="mdi-magnify"
                            @keydown.enter="getAllRfs(); options.page = 1"
                            @click:append-outer="searchInput()"
                            @click:clear="searchItem='';getAllRfs()"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            <v-card-text>
                <div>
                    <label for="">{{ rfStatus }} Count: </label>
                    <span style="font-weight:bold">{{ totalItems }}</span>
                </div>
                <v-data-table
                    :headers="repairFormHeaders()"
                    :items="rfLists"
                    :loading="loading"
                    :options.sync="options"
                    :server-items-length="totalItems"
                    :items-per-page="options.itemsPerPage"
                    :footer-props="{
                        itemsPerPageOptions: options.itemsPerPageOptions
                    }"
                    :no-data-text="noDataText"
                >
                <template v-slot:item.date="{ item }">
                    <td>{{ date(item) }}</td>
                </template>

                <template v-slot:item.printed="{ item }">
                    <td>{{ printedRf(item) }}</td>
                </template>

                <template v-slot:item.date="{ item }">
                    <td>{{ date(item) }}</td>
                </template>

                <template v-slot:item.created_at="{ item }">
                    <td>{{ agingDate(item.created_at) }}</td>
                </template>

                <template  v-slot:item.action="{ item }">
                    <td class="px-0">
                        <span class="text-nonwrap">
                            <v-btn v-if="rfStatus == 'TA' || rfStatus == 'UAS' || rfStatus == 'WA'"  text icon color="orange">
                                <v-icon class="btn-action" small @click="showRepairFormDetails(item)">mdi-eye</v-icon>
                            </v-btn>

                            <v-btn v-if="rfStatus == 'QUO' || rfStatus == 'FCA' || rfStatus == 'FTA' || rfStatus == 'WCM' || rfStatus == 'RET'" text icon color="orange">
                                <v-icon class="btn-action" small @click="showRfWithEstimation(item)">mdi-eye</v-icon>
                            </v-btn>

                            <v-btn v-if="rfStatus == 'PP' || rfStatus == 'WFP' || rfStatus == 'WFR' || rfStatus == 'PIN' || rfStatus == 'REP' || rfStatus == 'RFD' || rfStatus == 'DEL' || rfStatus == 'COL' || rfStatus == 'DEF' || rfStatus == 'REC'" text icon color="orange">
                                <v-icon class="btn-action" small @click="showRepairFormDetailsDialog(item)">mdi-eye</v-icon>
                            </v-btn>

                            <v-btn v-if="rfStatus == 'PP'" text icon color="red">
                                <v-icon class="btn-action" small @click="cancelPending(item)">mdi-cancel</v-icon>
                            </v-btn>

                            <!-- <v-btn text icon color="blue">
                                <v-icon class="btn-action" small @click="editRepairFormDetails(item)">fas fa-edit</v-icon>
                            </v-btn> -->
                            <!-- <v-btn v-if="!item.sc_rrf_id" text icon color="red">
                                <v-icon class="btn-action" small @click="deleteRepairForm(item)">fas fa-trash-alt</v-icon>
                            </v-btn> -->
                        </span>
                    </td>
                </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <!-- Repair Form Details Dialog -->
        <v-dialog v-model="rfDetailsPlainDialog" persistent max-width="60%" scrollable>
            <v-card class="pb-1">
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">Repair Form Details</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeRfDetailsPlainDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider class="mt-0" />
                <v-container fluid style="background-color: white; overflow-y: scroll">
                    <v-col>
                        <v-card-text>
                            <div class="ma-4">
                                <v-layout row class="mx-0">
                                    <v-flex v-if="rfStatus == 'TA'" lg12 class="mb-4">
                                        <v-card class="pa-4">
                                            <assign-technician
                                                :rfId="rfId"
                                                :itemId="itemId"
                                                :closeRfDetailsPlainDialog="closeRfDetailsPlainDialog"
                                                @successAssignTech="successAssignTech"
                                            />
                                        </v-card>
                                    </v-flex>

                                    <v-flex lg12>
                                        <v-layout row class="mx-0 px-1 pb-2">
                                            <v-spacer />
                                            <v-flex lg4>
                                                <v-card class="pa-2 mr-2 mb-2">
                                                    <div style="text-align:center">
                                                        <span style="color:gray;font-weight:bold">RF#: </span>
                                                        <span>{{ rfNumber }}</span>
                                                    </div>
                                                </v-card>
                                            </v-flex>

                                            <v-flex lg4>
                                                <v-card class="pa-2 mr-2 mb-2">
                                                    <div style="text-align:center">
                                                        <span style="color:gray;font-weight:bold">RD#: </span>
                                                        <span>{{ rrNumber }}</span>
                                                    </div>
                                                </v-card>
                                            </v-flex>

                                            <v-flex lg4>
                                                <v-card class="pa-2 mr-2 mb-2">
                                                    <div style="text-align:center">
                                                        <span style="color:gray;font-weight:bold">Date: </span>
                                                        <span>{{ rrDate }}</span>
                                                    </div>
                                                </v-card>
                                            </v-flex>

                                            <v-flex lg3>
                                                <v-card class="pa-2 mr-2">
                                                    <div style="text-align:center">
                                                        <span style="color:gray;font-weight:bold">Status: </span>
                                                        <span>{{ rfStatus }}</span>
                                                    </div>
                                                </v-card>
                                            </v-flex>

                                            <v-flex lg3>
                                                <v-card class="pa-2 mr-2">
                                                    <div style="text-align:center">
                                                        <span style="color:gray;font-weight:bold">Old Warranty Type: </span>
                                                        <span>{{ oldWarrantyType == null ? 'None' : oldWarrantyType }}</span>
                                                    </div>

                                                </v-card>
                                            </v-flex>

                                            <v-flex lg3>
                                                <v-card class="pa-2 mr-2">
                                                    <div style="text-align:center">
                                                        <span style="color:gray;font-weight:bold">Customer Satisfaction: </span>
                                                        <span>{{ emoji }}</span>
                                                    </div>
                                                </v-card>
                                            </v-flex>

                                            <v-flex lg3>
                                                <v-card class="pa-2 mr-2">
                                                    <div style="text-align:center">
                                                        <span style="color:gray;font-weight:bold">Total Price: </span>
                                                        <span>{{ totalAmount }}</span>
                                                    </div>
                                                </v-card>
                                            </v-flex>
                                        </v-layout>
                                        <!-- File Viewer for WA -->
                                        <v-layout v-if="rfStatus == 'WA'" row class="mx-0 px-1 pb-2">
                                            <v-flex lg12 class="pr-2">
                                                <BaseFileViewerWA :delete="true" :rfId="rfId" :rfNumber="rfNumber" />
                                            </v-flex>
                                        </v-layout>

                                        <v-layout row class="mx-1">
                                            <v-flex lg6 class="pr-2">
                                                <v-card class="pa-4">
                                                    <div class="pb-4">
                                                        Customer Information
                                                    </div>
                                                    <v-layout row class="mx-4">
                                                        <v-flex lg4>
                                                            <label style="color:gray">Company Name:</label>
                                                        </v-flex>
                                                        <v-flex lg8>
                                                            {{ companyName }}
                                                        </v-flex>
                                                    </v-layout>
                                                    <v-layout row class="mx-4">
                                                        <v-flex lg4>
                                                            <label style="color:gray">Company Number:</label>
                                                        </v-flex>
                                                        <v-flex lg8>
                                                            {{ companyContactNumber }}
                                                        </v-flex>
                                                    </v-layout>
                                                    <v-layout row class="mx-4">
                                                        <v-flex lg4>
                                                            <label style="color:gray">Customer Number:</label>
                                                        </v-flex>
                                                        <v-flex lg8>
                                                            {{ contactNumber }}
                                                        </v-flex>
                                                    </v-layout>
                                                    <v-layout row class="mx-4">
                                                        <v-flex lg4>
                                                            <label style="color:gray">Customer Name:</label>
                                                        </v-flex>
                                                        <v-flex lg8>
                                                            {{ customerName }}
                                                        </v-flex>
                                                    </v-layout>
                                                    <v-layout row class="mx-4">
                                                        <v-flex lg4>
                                                            <label style="color:gray">Address:</label>
                                                        </v-flex>
                                                        <v-flex lg8>
                                                            {{ address }}
                                                        </v-flex>
                                                    </v-layout>
                                                    <v-layout row class="mx-4">
                                                        <v-flex lg4>
                                                            <label style="color:gray">Email:</label>
                                                        </v-flex>
                                                        <v-flex lg8>
                                                            {{ email }}
                                                        </v-flex>
                                                    </v-layout>
                                                </v-card>
                                            </v-flex>

                                            <v-flex lg6>
                                                <v-card class="pa-4">
                                                    <div class="pb-4">
                                                        Item Information
                                                    </div>
                                                    <v-layout row class="mx-4">
                                                        <v-flex lg4>
                                                            <label style="color:gray">Warranty Type:</label>
                                                        </v-flex>
                                                        <v-flex lg8>
                                                            {{ itemWarrantyType }}
                                                        </v-flex>
                                                    </v-layout>
                                                    <v-layout row class="mx-4">
                                                        <v-flex lg4>
                                                            <label style="color:gray">Warranty Document:</label>
                                                        </v-flex>
                                                        <v-flex lg8>
                                                            {{ itemWarrantyDocument }}
                                                        </v-flex>
                                                    </v-layout>
                                                    <v-layout row class="mx-4">
                                                        <v-flex lg4>
                                                            <label style="color:gray">Purchase Date:</label>
                                                        </v-flex>
                                                        <v-flex lg8>
                                                            {{ itemPurchaseDate }}
                                                        </v-flex>
                                                    </v-layout>
                                                    <v-layout row class="mx-4">
                                                        <v-flex lg4>
                                                            <label style="color:gray">Item Model:</label>
                                                        </v-flex>
                                                        <v-flex lg8>
                                                            {{ itemModel }}
                                                        </v-flex>
                                                    </v-layout>
                                                    <v-layout row class="mx-4">
                                                        <v-flex lg4>
                                                            <label style="color:gray">Item Name:</label>
                                                        </v-flex>
                                                        <v-flex lg8>
                                                            {{ itemProductName }}
                                                        </v-flex>
                                                    </v-layout>
                                                    <v-layout row class="mx-4">
                                                        <v-flex lg4>
                                                            <label style="color:gray">Serial No:</label>
                                                        </v-flex>
                                                        <v-flex lg8>
                                                            {{ itemSerial }}
                                                        </v-flex>
                                                    </v-layout>

                                                    <v-layout row class="mx-4">
                                                        <v-flex lg4>
                                                            <label style="color:gray">Accessories:</label>
                                                        </v-flex>
                                                        <v-flex lg8>
                                                            {{ itemAccessories }}
                                                        </v-flex>
                                                    </v-layout>
                                                    <v-layout row class="mx-4">
                                                        <v-flex lg4>
                                                            <label style="color:gray">Other Accessory:</label>
                                                        </v-flex>
                                                        <v-flex lg8>
                                                            {{ itemOtherAccessory }}
                                                        </v-flex>
                                                    </v-layout>
                                                    <v-layout row class="mx-4">
                                                        <v-flex lg4>
                                                            <label style="color:gray">Other Unit Condition:</label>
                                                        </v-flex>
                                                        <v-flex lg8>
                                                            {{ itemOtherUnitCondition }}
                                                        </v-flex>
                                                    </v-layout>
                                                    <v-layout row class="mx-4">
                                                        <v-flex lg4>
                                                            <label style="color:gray">Complaint / Symptom:</label>
                                                        </v-flex>
                                                        <v-flex lg8>
                                                            {{ itemDefectDescription }}
                                                        </v-flex>
                                                    </v-layout>
                                                    <v-layout row class="mx-4">
                                                        <v-flex lg4>
                                                            <label style="color:gray">Remarks:</label>
                                                        </v-flex>
                                                        <v-flex lg8>
                                                            {{ itemDefectRemarks }}
                                                        </v-flex>
                                                    </v-layout>

                                                    <v-layout v-if="rfStatus != 'TA'" row class="mx-4">
                                                        <v-flex lg4>
                                                            <label style="color:gray">Assigned Technician:</label>
                                                        </v-flex>
                                                        <v-flex lg8>
                                                            {{ assignedTechnician }}
                                                        </v-flex>
                                                    </v-layout>
                                                </v-card>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                            </div>
                        </v-card-text>
                    </v-col>
                </v-container>
                <v-divider />
                    <v-layout v-if="rfStatus == 'UAS'" class="mb-6 mx-8" row>
                        <v-flex lg12 class="pb-4">
                            <div class="pt-2">
                                <v-btn @click="printRepairForm()" :loading="loading">Prints</v-btn>
                            </div>
                        </v-flex>
                    </v-layout>


            <v-layout row class="mx-1">
                <v-flex lg12 class="pr-2">
                    <v-card-actions  v-if="rfStatus == 'WA'" class="d-flex justify-space-between" >

                        <v-layout class="mb-6 mx-8" row>
                            <v-flex lg12 class="pr-2 pb-4" style="text-align: center;">
                                <div v-show="warrantyUploader">
                                    <div style="display: inline-block">
                                        Upload Warranty Document: <span style="color:red">*</span>
                                    </div>
                                    <div style="display: inline-block" class="mr-2">
                                        <FileUploadComponent ref="fileUpload" @uploadedData="uploadedData" @reset="clearFileUpload" />
                                    </div>
                                    <div style="display: inline-block">
                                        Purchase Date: <span style="color:red">*</span>
                                    </div>
                                    <div style="display: inline-block">
                                        <v-dialog
                                            ref="purchaseDateDialog"
                                            v-model="purchaseDateDialog"
                                            :return-value.sync="purchaseDate"
                                            persistent
                                            width="290px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="purchaseDate"
                                                readonly
                                                dense outlined
                                                class="hide-text-field-details"
                                                v-bind="attrs"
                                                v-on="on"
                                                clearable
                                            ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="purchaseDate"
                                                scrollable
                                                :max="maxDate"
                                                >
                                            <v-spacer />
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="purchaseDateDialog = false"
                                            >
                                                Cancel
                                            </v-btn>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="$refs.purchaseDateDialog.save(purchaseDate)"
                                            >
                                                OK
                                            </v-btn>
                                            </v-date-picker>
                                        </v-dialog>
                                    </div>
                                </div>
                            </v-flex>

                            <v-flex lg12 style="text-align: center;">
                                <v-layout row >
                                    <v-flex lg4>
                                        <div style="text-align:end">
                                            <v-autocomplete
                                                v-model="itemWarrantyTypeUpdater"
                                                :items="warrantyTypeItems()"
                                                outlined
                                                dense
                                            />
                                        </div>
                                    </v-flex>
                                    <v-flex lg8>
                                        <v-btn v-if="itemRepairStatus == 'For-Replacement'" :loading="confirmChangeWarrantyOnFlight" color="red" @click="confirmChangeWarranty()">Re-Diagnose</v-btn>
                                        <v-btn v-else :loading="confirmChangeWarrantyOnFlight" @click="confirmChangeWarranty()" :disabled="loading">Update Warranty Type</v-btn>
                                        <v-tooltip bottom>
                                            <template  v-slot:activator="{ on, attrs }" >
                                                <v-btn
                                                    @click="cancelChangeWarranty()"
                                                    style="opacity:1;"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    text
                                                    icon
                                                    outlined
                                                    :loading="loading"
                                                    color="red"
                                                >
                                                    <v-icon>mdi-close-circle</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Cancel Change Warranty</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template  v-slot:activator="{ on, attrs }" >
                                                <v-btn
                                                    @click="approveWA()"
                                                    style="opacity:1;"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    text
                                                    icon
                                                    outlined
                                                    :loading="loading"
                                                    color="green"
                                                >
                                                    <v-icon>mdi-check</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Approve</span>
                                        </v-tooltip>
                                    </v-flex>
                                </v-layout>
                            </v-flex>

                        </v-layout>
                    </v-card-actions>
                </v-flex>
            </v-layout>
                <!-- </div> -->

                    <!-- For Printing Details -->
                    <v-container fluid style="display:none">
                        <div id="div_rf_logo" >
                            <div style="font-size: 12px; line-height: 1.9">
                                <p>
                                    <span style="font-size: 18px; font-weight: bold;">
                                        INGCOPH Traders Inc.
                                    </span>
                                    <br>2 D Arellano St. Bagong Barrio, Caloocan City
                                    <!-- <br>Cell No.: +63 935 713 2293 / +63 917 170 0979 / +63 919 078 0016
                                    <br>Tel. No.: +63 2 8727 1771 / +63 2 8535 9968 / +63 2 7091 4539 -->
                                    <br>Service Mobile / Viber No.: +63 917 625 2286
                                </p>
                            </div>
                        </div>
                        <div id="div_rf_num" style="font-size: 12px; line-height: 1.9">
                            <div style="border-top: 1px dashed #CFCFCF; padding: 2px; padding-bottom: -4px; text-align: center; border-bottom: none;">&nbsp;</div>
                            <p>
                                <span style="font-size: 18px; font-weight: bold;">Repair Form</span>
                                <br>
                                <b>RF#: {{ rfNumber }}</b>
                                <br>
                                <b>Pullout#: {{ pulloutNumber }}, RD#: {{ rrNumber }}</b>
                                <br>
                                <b>Issued: {{ rrDate }}</b>
                            </p>
                        </div>

                        <div>
                            <table id="rf_details">
                                <tbody>
                                    <tr>
                                        <td colspan="3">Item Model: {{ itemModel }}</td>
                                        <td colspan="2">Serial No: {{ itemSerial }}</td>
                                        <td colspan="3">Category: {{ itemCategory }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="8">Item Name: {{ itemProductName }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="3">Warranty: {{ itemWarrantyType }}</td>
                                        <td colspan="2">Purchase Date: {{ itemPurchaseDate }}</td>
                                        <td colspan="3">Document: {{ itemWarrantyDocument }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="5">Company: {{ companyName }}</td>
                                        <td colspan="3">Contact No: {{ companyContactNumber }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="5">Customer: {{ customerName }}</td>
                                        <td colspan="3">Contact No: {{ contactNumber }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="8">Address: {{ address }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="8">Complaint/Symptom: {{ itemDefectDescription }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="8">Remarks: {{ itemDefectRemarks }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="8">Physical Condition: {{ itemUnitCondition }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="8">Description: {{ itemOtherUnitCondition }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="8">Included Items: {{ itemAccessories }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="8">Details: {{ itemOtherAccessory }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="5">Assigned Technician: {{ assignedTechnician }}</td>
                                        <td colspan="3">Begin Repair Date: {{  }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="8">Symptoms as Tested: {{  }}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <div v-if="rfStatus == 'UAS'" id="rf_estimate_title" style="font-size:12px">
                                Estimate Information
                            </div>
                            <table v-if="rfStatus == 'UAS'" id="rf_estimation">
                                <tbody>
                                   <tr>
                                       <td colspan="5">Recommeded part/s for Replacement:</td>
                                       <td colspan="3">Diagnosis Date:</td>
                                   </tr>
                                   <tr>
                                       <td colspan="8"></td>
                                   </tr>
                                   <tr>
                                       <td colspan="8"></td>
                                   </tr>
                                   <tr>
                                        <td colspan="1">Quotation No: {{  }}</td>
                                        <td colspan="1">Quotation Date: {{  }}</td>
                                        <td colspan="1">Total Amount: {{  }}</td>
                                        <td colspan="1">Approve Date: {{  }}</td>
                                        <td colspan="1">Coordinator: {{  }}</td>
                                        <td colspan="3">Remarks: {{  }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="3"></td>
                                    </tr>
                                    <tr>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="3"></td>
                                    </tr>
                                </tbody>
                            </table>

                            <div v-if="rfStatus == 'UAS'" id="rf_unit_movements_title" style="font-size:12px">
                                Unit Status
                            </div>
                            <table v-if="rfStatus == 'UAS'" id="rf_unit_movements">
                                <tbody>
                                   <tr>
                                        <td colspan="1">Status: {{  }}</td>
                                        <td colspan="1">WFR/WFC: {{  }}</td>
                                        <td colspan="1">FCA: {{  }}</td>
                                        <td colspan="1">PFR: {{  }}</td>
                                        <td colspan="1">RET: {{  }}</td>
                                        <td colspan="1">WFP: {{  }}</td>
                                        <td colspan="1">PIS: {{  }}</td>
                                        <td colspan="1">RFD: {{  }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="1">Date</td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                    </tr>
                                    <tr>
                                        <td colspan="1">Employee</td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                    </tr>
                                </tbody>
                            </table>

                            <div v-if="rfStatus == 'UAS'" id="rf_repair_info_title" style="font-size:12px">
                                Repair Information
                            </div>
                            <table v-if="rfStatus == 'UAS'" id="rf_repair_info">
                                <tbody>
                                   <tr>
                                        <td colspan="5">Job Done: {{  }}</td>
                                        <td colspan="1">Section: {{  }}</td>
                                        <td colspan="1">Defect: {{  }}</td>
                                        <td colspan="1">Repair: {{  }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="5"></td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                    </tr>
                                    <tr>
                                        <td colspan="5">QA Technician: </td>
                                        <td colspan="3">Repair Completion Date: </td>
                                    </tr>
                                </tbody>
                            </table>

                            <div v-if="rfStatus == 'UAS'" id="rf_releasing_info_title" style="font-size:12px">
                                Releasing Information
                            </div>
                            <table v-if="rfStatus == 'UAS'" id="rf_releasing_info">
                                <tbody>
                                    <tr>
                                        <td colspan="3">Delivery Date: </td>
                                        <td colspan="2">Released By: </td>
                                        <td colspan="3">Delivery Mode: </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3">Collection Date: </td>
                                        <td colspan="2">Confirmed By: </td>
                                        <td colspan="3"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </v-container>
                <!-- </div> -->
            </v-card>
        </v-dialog>

        <edit-repair-form-dialog
            :showEditDialog="showEditDialog"
            :rf="rf"
            @closeEditDialog="closeEditDialog"
        />

        <v-dialog v-model="showOverridePrint" persistent max-width="30%" scrollable>
            <v-card>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">Confirm Override Print</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeOverrideDialog(); override_username = ''; override_user_password = '';">
                            <v-icon>mdi-plus-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text>
                    <div class="row">
                        <!-- <div class="col-sm-12 col-md-6">
                            <label for="override_username" class="float-left control-label col-form-label">Username</label>
                            <input type="text" class="form-control" id="override_username" placeholder="Username" v-model="override_username" autocomplete="new-password">
                            <span v-for="errors in errors.override_username" class="text-warning" v-bind:key="errors">{{errors}}</span>
                        </div> -->
                        <div class="col-sm-12 col-md-12">
                            <label for="override_user_password" class="float-left control-label col-form-label">Password</label>
                            <input type="password" class="form-control" id="override_user_password" placeholder="Password" v-model="overrideUserPassword" autocomplete="new-password">
                            <!-- <span v-for="errors in errors.override_user_password" class="text-warning" v-bind:key="errors">{{errors}}</span> -->
                        </div>
                    </div>
                    <div class="border-top">
                        <div class="card-body py-4 d-flex justify-content-center">
                            <v-btn  v-on:click="confirmedOverridePrint()">Confirm</v-btn>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <RfDetailsDialogWithEst v-if="rfStatus == 'QUO' || rfStatus == 'FCA' || rfStatus == 'FTA' || rfStatus == 'WCM' || rfStatus == 'RET'" :rf="rf" @closeRfDialogWithEst="closeRfDialogWithEst" :warehousesCode="warehousesCode" />
        <repair-form-details-dialog
            v-if="
            rfStatus == 'PP' ||
            rfStatus == 'WFP' ||
            rfStatus == 'WFR' ||
            rfStatus == 'PIN' ||
            rfStatus == 'REP' ||
            rfStatus == 'RFD' ||
            rfStatus == 'DEL'||
            rfStatus == 'COL' ||
            rfStatus == 'REC' ||
            rfStatus == 'DEF'"
            :rf="rf"
            :status="rfStatus"
            @successConfirm="rfSuccessConfirm"

        />

    </div>
</template>

<script>
import EditRepairFormDialog from './EditRepairFormDialogComponent.vue';
import AssignTechnician from './AssignTechnicianComponent.vue';
import RfDetailsDialogWithEst from './RfDetailsDialogWithEstComponent.vue';
import RepairFormDetailsDialog from './Views/RepairFormViewComponent/RepairFormDetailsDialog.vue';
import BaseFilesViewer from './Base/BaseFilesViewer.vue';
// import BaseUploadWarranty from "@/views/main/modules/Services/Base/BaseUploadWarranty.vue";
import FileUploadComponent from '@/views/main/Shared/FileUploadComponent.vue'
import ServiceFunctions from '../../Shared/ServiceFunctionsComponent.vue';
import BaseFileViewerWA from './Base/BaseFileViewerWA.vue';
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
export default {
    mixins: [ServiceFunctions, SharedFunctionsComponentVue],
    data() {
        return {
            defaultTableHeaders: [
                { text: 'RF#', value: 'rf_number' },
                { text: 'RD#', value: 'sc_receiving_report.rr_number' },
                { text: 'Item Model', value: 'sc_repair_form_item.model' },
                { text: 'Item Name', value: 'sc_repair_form_item.product_name' },
                { text: 'Serial#', value: 'sc_repair_form_item.serial' },
                { text: 'Complaint/Symptom', value: 'sc_repair_form_item.defect_description' },
                { text: 'Warranty Type', value: 'sc_repair_form_item.warranty_type' },
                { text: 'Status', value: 'status' },
                { text: 'Crate#', value: 'crate_number' },
                { text: 'Loc#', value: 'location_number' },
                { text: 'Date', value: 'date' },
                { text: 'Days', value: 'created_at' },
                { text: 'Action', value: 'action' },
            ],
            withActionTableHeaders: [
                { text: 'RF#', value: 'rf_number' },
                { text: 'RD#', value: 'sc_receiving_report.rr_number' },
                { text: 'Item Model', value: 'sc_repair_form_item.model' },
                { text: 'Item Name', value: 'sc_repair_form_item.product_name' },
                { text: 'Serial#', value: 'sc_repair_form_item.serial' },
                { text: 'Complaint/Symptom', value: 'sc_repair_form_item.defect_description' },
                { text: 'Warranty Type', value: 'sc_repair_form_item.warranty_type' },
                { text: 'Status', value: 'status' },
                { text: 'Crate#', value: 'crate_number' },
                { text: 'Loc#', value: 'location_number' },
                { text: 'Date', value: 'date' },
                { text: 'Days', value: 'created_at' },
                { text: 'Action', value: 'action' },
            ],
            withTechnicianTableHeaders: [
                { text: 'RF#', value: 'rf_number' },
                { text: 'RD#', value: 'sc_receiving_report.rr_number' },
                { text: 'Item Model', value: 'sc_repair_form_item.model' },
                { text: 'Item Name', value: 'sc_repair_form_item.product_name' },
                { text: 'Serial No', value: 'sc_repair_form_item.serial' },
                { text: 'Complaint/Symptom', value: 'sc_repair_form_item.defect_description' },
                { text: 'Warranty Type', value: 'sc_repair_form_item.warranty_type' },
                { text: 'Status', value: 'status' },
                { text: 'Crate#', value: 'crate_number' },
                { text: 'Loc#', value: 'location_number' },
                { text: 'Printed', value: 'printed' },
                { text: 'Technician', value: 'sc_technician.name' },
                { text: 'Date', value: 'date' },
                { text: 'Days', value: 'created_at' },
                { text: 'Action', value: 'action' },
            ],
            withEstimationTableHeaders: [
                { text: 'RF#', value: 'rf_number' },
                { text: 'RD#', value: 'sc_receiving_report.rr_number' },
                { text: 'Item Model', value: 'sc_repair_form_item.model' },
                { text: 'Item Name', value: 'sc_repair_form_item.product_name' },
                { text: 'Serial No', value: 'sc_repair_form_item.serial' },
                { text: 'Complaint/Symptom', value: 'sc_repair_form_item.defect_description' },
                { text: 'Warranty Type', value: 'sc_repair_form_item.warranty_type' },
                { text: 'Status', value: 'status' },
                { text: 'Crate#', value: 'crate_number' },
                { text: 'Loc#', value: 'location_number' },
                { text: 'Technician', value: 'sc_technician.name' },
                { text: 'Date', value: 'date' },
                { text: 'Days', value: 'created_at' },
                { text: 'Action', value: 'action' },
            ],
            rfLists: [],
            loading: true,
            noDataText: '',
            rfDetailsPlainDialog: false,
            rfDetails: null,
            rfDate: '',
            rrDate: '',
            oldWarrantyType: '',
            emoji: '',
            rfNumber: '',
            rrNumber: '',
            pulloutNumber: '',
            companyName: '',
            companyContactNumber: '',
            customerName: '',
            address: '',
            contactNumber: '',
            email: '',
            itemModel: '',
            itemProductName: '',
            itemSerial: '',
            itemCategory: '',
            itemDefectDescription: '',
            itemDefectRemarks: '',
            itemAccessories: '',
            itemOtherAccessory: '',
            itemWarrantyType: '',
            itemWarrantyDocument: '',
            itemUnitCondition: '',
            itemOtherUnitCondition: '',
            itemStatus: '',
            itemPurchaseDate: '',
            itemPurchaseDates: '',
            purchaseDate: '',
            deliveredBy: '',
            receivedBy: '',
            warehouse: '',
            showEditDialog: false,
            rf: null,
            customerId: '',
            search: '',
            showOverridePrint: false,
            overrideUserPassword: '',
            confirmOverride: false,
            rfId: '',
            closeRfDialog: false,
            assignedTechnician: '',
            itemSymptomUponTested: "",
            itemJobDone: "",
            itemId: '',
            totalItems:0,
            options:{},
            loading:true,
            uploadedFiles: null,
            itemWarrantyTypeUpdater: '',
            purchaseDateDialog: false,
            confirmChangeWarrantyOnFlight: false,
            changeWarrantyType: false,
            uploadWarrantyType: false,
            itemRepairStatus: "",
            maxDate: this.$dayjs().format('YYYY-MM-DD'),
            readOnly: false,
            warrantyUploader: false,
            rfEstItems: [],
            customerDetails: null,
            discounts: [],
            customerDiscounts: [],
            estimateItemTotalPrice: 0,
            rfEstimation: null,
            processedReturnBy: '',
            rfEstItemsDate: "",
            rfEstId: "",
            totalAmount: '',
            warehousesCode: [],
            searchItem: '',
        }
    },
    async mounted() {
        await this.getWarehouses();
        await this.getAllRfs();
        await this.searchFromUrl();
    },
    computed:{
        ...mapGetters([
            'GET_ALL_PROCESS_RFS',
            'GET_RF_DETAILS',
            'GET_CONFIRM_OVERRIDE_PRINT_PL',
            'SC_PROCESSING',
            'GET_SHOW_CUSTOMERS',
            'GET_RF_ESTIMATION',
            'GET_SERVICE_WAREHOUSES',

        ])
    },
    props: ['rfStatus'],
    watch: {
        rfStatus(val) {
            this.getAllRfs();
        },
        rfDetails() {
            this.loading = true
            if(!!this.rfDetails) {
                let rr = this.rfDetails.sc_receiving_report;
                this.rfNumber = this.rfDetails.rf_number;
                this.rrNumber = rr.rr_number;
                this.customerDetails = rr.customer;
                this.rfId = this.rfDetails.id;
                if(!!rr.pullout_number) {
                    this.pulloutNumber = rr.pullout_number;
                }
                this.companyName = rr.customer.company_name;
                this.rrDate = this.$dayjs(rr.created_at).format('YYYY-MM-DD, hh:mm:ss a');
                this.customerId = rr.customer_id;
                this.backjob = this.rfDetails.backjob;
                this.customerDiscounts = JSON.parse(rr.customer.discounts);

                if(!!this.rfDetails.sc_technician_id) {
                    this.assignedTechnician = this.rfDetails.sc_technician.name;
                }

                if(!!this.rfDetails.sc_rf_estimations){
                    let total = this.rfDetails.sc_rf_estimations.find(e=>e.id)
                    this.totalAmount = total ? total.total_amount : 'None';
                }

                let contact = [];
                let fullName = [];

                if(!!this.rfDetails.sc_repair_form_item) {
                    this.itemModel = this.rfDetails.sc_repair_form_item.model;
                    this.itemProductName = this.rfDetails.sc_repair_form_item.product_name;
                    this.itemCategory = this.rfDetails.sc_repair_form_item.category;
                    this.itemDefectDescription = this.rfDetails.sc_repair_form_item.defect_description;
                    this.itemDefectRemarks = this.rfDetails.sc_repair_form_item.defect_remarks;
                    this.itemSerial = this.rfDetails.sc_repair_form_item.serial;
                    this.itemAccessories = this.rfDetails.sc_repair_form_item.accessories;
                    this.itemOtherAccessory = this.rfDetails.sc_repair_form_item.other_accessory;
                    this.itemWarrantyType = this.rfDetails.sc_repair_form_item.warranty_type;
                    this.itemWarranty = this.rfDetails.sc_repair_form_item.warranty;
                    this.itemWarrantyDocument = this.rfDetails.sc_repair_form_item.warranty;
                    this.itemUnitCondition = this.rfDetails.sc_repair_form_item.unit_condition;
                    this.itemOtherUnitCondition = this.rfDetails.sc_repair_form_item.other_unit_condition;
                    this.itemStatus = this.rfDetails.sc_repair_form_item.status;
                    this.itemPurchaseDate = this.rfDetails.sc_repair_form_item.purchase_date;
                    this.itemSymptomUponTested = this.rfDetails.sc_repair_form_item.symptom_upon_tested;
                    this.itemJobDone = this.rfDetails.sc_repair_form_item.job_done;
                    this.itemId = this.rfDetails.sc_repair_form_item.item_id;
                    this.oldWarrantyType = this.rfDetails.sc_repair_form_item.old_warranty_type;
                    this.itemRepairStatus = this.rfDetails.sc_repair_form_item.repair_status;
                    this.itemPurchaseDates = this.$dayjs(this.rfDetails.sc_repair_form_item.purchase_date).format('YYYY-MM-DD');
                    this.changeWarrantyType = !!this.rfDetails.sc_repair_form_item.change_warranty;

                    this.emojiId = this.rfDetails.sc_repair_form_item.emoji_id;
                    let hasEmoji = this.rfDetails.sc_repair_form_item.emoji.find(e=>e.id == this.emojiId);
                    this.emoji = hasEmoji ? hasEmoji.emoji : "None";

                }

                if(rr.customer_id == 275) {
                    if(!!rr.sc_customer) {
                        if(!!rr.sc_customer.first_name) {
                            fullName.push(rr.sc_customer.first_name);
                        } if(!!rr.sc_customer.middle_name) {
                            fullName.push(rr.sc_customer.middle_name);
                        } if(!!rr.sc_customer.last_name) {
                            fullName.push(rr.sc_customer.last_name);
                        }

                        this.contactNumber = rr.sc_customer.contact_number;
                        this.address = rr.sc_customer.address;
                        this.email = rr.sc_customer.email;
                    }
                    this.customerName = fullName.toString().replaceAll(',', ' ');

                } else {
                    this.companyContactNumber = contact.toString().replaceAll(',',' ');
                    this.address = JSON.parse(rr.customer.shipping_addresses)[0].address;
                    if(!!rr.customer.mobile_1) {
                         contact.push(rr.customer.mobile_1);
                    }
                    if(!!rr.customer.mobile_2) {
                        contact.push(rr.customer.mobile_2);
                    }
                    if(!!rr.customer.mobile_3) {
                        contact.push(rr.customer.mobile_3);
                    }
                    if(!!rr.customer.tel_1) {
                        contact.push(rr.customer.tel_1);
                    }
                    if(!!rr.customer.tel_2) {
                        contact.push(rr.customer.tel_2);
                    }
                    if(!!rr.customer.tel_3) {
                        contact.push(rr.customer.tel_3);
                    }
                    // if(!!rr.customer.first_name) {
                    //      fullName.push(rr.customer.first_name);
                    // }
                    // if(!!rr.customer.middle_name) {
                    //     fullName.push(rr.customer.middle_name);
                    // }
                    // if(!!rr.customer.last_name) {
                    //     fullName.push(rr.customer.last_name);
                    // }
                    // this.customerName = fullName.toString().replaceAll(',', ' ');
                    this.companyContactNumber = contact.toString().replaceAll(',', ' / ');
                }
            }
            this.loading = false
        },

        rfEstimation() {
            if(!!this.rfEstimation) {
                this.rfEstId = this.rfEstimation.id;
                this.sqId = this.rfEstimation.sales_quotation_id;
                this.sqNumber = this.rfEstimation.sales_quotation_number;
                this.printedSq = !!this.rfEstimation.printed_sq;
                this.rfEstCancelledDate = this.rfEstimation.cancelled_at;
            }
        },

        'options':{
            handler(val){
                this.getAllRfs()
            }
        },

        'SC_PROCESSING.repairFormDetailsDialog':{
            handler(val){
                if(!val){
                    this.getAllRfs()
                }
            }
        },

        itemWarrantyTypeUpdater() {
            if(this.itemWarrantyTypeUpdater == 'Dealer-Stock') {
                this.warrantyUploader = false;
                this.$refs.fileUpload.reset();
                this.itemWarrantyType = this.itemWarrantyTypeUpdater;
                this.rfEstItems.forEach(x=>{
                    x.foc = true;
                })
            } else if(this.itemWarrantyTypeUpdater == 'In-Warranty') {
                this.warrantyUploader = true;
                this.itemWarrantyType = this.itemWarrantyTypeUpdater;
                // this.rfEstItems.forEach(x=>{
                //     x.foc = false;
                // })
            } else if(this.itemWarrantyTypeUpdater == 'Out-Warranty') {
                this.warrantyUploader = false;
                this.$refs.fileUpload.reset();
                this.itemWarrantyType = this.itemWarrantyTypeUpdater;
                this.rfEstItems.forEach(x=>{
                    x.foc = false;
                })
            } else if(this.itemWarrantyTypeUpdater == 'End-User') {
                this.warrantyUploader = false;
                this.$refs.fileUpload.reset();
                this.itemWarrantyType = this.itemWarrantyTypeUpdater;
                this.rfEstItems.forEach(x=>{
                    x.foc = true;
                })
            }
            // this.getDiscounts();
        },

        itemRepairStatus() {
            if(this.itemRepairStatus == 'For-Replacement') {
                if(this.itemWarrantyType == 'In-Warranty') {
                    this.readOnly = false;
                } else {
                    this.readOnly = true;
                }
            } else {
                // this.completeDetails = false;
                this.readOnly = false;
            }
        },

        async rfEstItems() {
            if (this.rfEstItems.length > 0) {
                // this.getAvailableSpareParts();
                await this.getDiscounts();
                await this.discountDetails();
            }
        },
    },
    components: {
        EditRepairFormDialog,
        AssignTechnician,
        RfDetailsDialogWithEst,
        RepairFormDetailsDialog,
        BaseFilesViewer,
        BaseFileViewerWA,
        // BaseUploadWarranty,
        FileUploadComponent

    },
    methods: {
        cancelChangeWarranty(){
            swal.fire({
                title: 'Are you sure you want to cancel Change Warranty?',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(result=>{
                if(result.isConfirmed){
                    let payload = {
                        rfId: this.rfId,
                    }
                    this.$store.dispatch('cancelChangeWarranty',payload).then(response=>{
                        if(response.data.msg) {
                            swal.fire("", response.data.msg, "success");
                            this.closeRfDetailsPlainDialog();
                            this.getAllRfs();
                        } else {
                            swal.fire("", response.data.error, "error");
                        }
                    }

                    )
                }
            })
        },
        approveWA(){
            swal.fire({
                title: 'Are you sure you want to Approve?',
                // text: `This Repair Form will move to ${this.oldWarrantyType == null ? 'TA' : 'OLD STATUS'}`,
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(result=>{
                if(result.isConfirmed) {
                    let payload = {
                        rfId: this.GET_RF_DETAILS.id,
                    }
                    this.$store.dispatch('approvedWA',payload).then(response=>{
                        if(response.data.msg) {
                            swal.fire("", response.data.msg, "success");
                            this.closeRfDetailsPlainDialog();
                            this.getAllRfs();
                        } else{
                            swal.fire("", response.data.error, "error");
                        }
                    });
                }
            });
        },
        searchFromUrl(){
            let url = new URLSearchParams(window.location.search);
            this.search = url.get("rf_number") != null ? url.get("rf_number").toString() : ''
        },
        async getAllRfs() {
            this.loading = true
            let payload = {
                status: this.rfStatus,
                itemsPerPage:this.options.itemsPerPage,
                page:this.options.page,
                search:this.search,
                searchItem:this.searchItem,
            }

            this.rfLists = []
            await this.$store.dispatch('getAllProcessRfs',payload).then(response => {
                this.rfLists = response.data.rfs.data.filter(e => {
                    e.date = this.$dayjs(e.created_at).format('YYYY-MM-DD, hh:mm:ss')
                    return e
                });
                this.totalItems =  response.data.rfs.total
                this.loading = false
                this.$emit('is-disabled');
            });
        },
        rfStatusAction(status) {
            this.rfStatus = status;
        },
        date(item) {
            if(!!item) {
                return this.$dayjs(item.created_at).format('YYYY-MM-DD, hh:mm:ss');
            }
            return '';
        },
        clearRfDetailsField() {
            this.rfDetails = "";
            this.rfDate = "";
            this.rrDate = "";
            this.rfNumber = "";
            this.rrNumber = "";
            this.companyName = "";
            this.companyContactNumber = "";
            this.customerName = "";
            this.customerContactNumber = "";
            this.address = "";
            this.contactNumber = "";
            this.email = "";
            this.itemModel = "";
            this.itemProductName = "";
            this.itemSerial = "";
            this.itemDefectDescription = "";
            this.deliveredBy = "";
            this.receivedBy = "";
            this.warehouse = "";
            this.emoji = "";
            this.oldWarrantyType = "";
        },
        showRepairFormDetails(rf) {
            this.rfDetailsPlainDialog = true;
            this.getRfDetails(rf.id);
        },
        closeRfDetailsPlainDialog() {
            this.rfDetailsPlainDialog = false;
            this.clearRfDetailsField();
            this.closeRfDialog = true;
            this.itemRepairStatus = "";
            this.itemWarrantyTypeUpdater = '';
            this.estimateItemTotalPrice = 0;
            this.itemWarrantyType = "";

        },
        getRfDetails(id){
            this.loading = true
            this.$store.dispatch('getRfDetails',id).then(response => {
                this.rfDetails = this.GET_RF_DETAILS;
                this.rfId = this.rfDetails.id;
                this.loading = false
            });
        },
        editRepairFormDetails(item) {
            if(!!item) {
                this.rf = item;
                this.showEditDialog = true;
            }
        },
        deleteRepairForm(item) {

        },
        closeEditDialog() {
            this.rf = null;
            this.showEditDialog = false;
            this.getAllRfs();
        },
        printRepairForm(){
            let doc = new this.$jspdf('p', 'mm', 'letter');
            doc.setFont(undefined, 'bold').setFontSize(13).text(6,8,"INGCOPH Traders Inc.")
            // doc.setFont(undefined, 'normal').setFontSize(9).text(6,13,"420 Del Monte Ave, Brgy. Siena, Quezon City\n\nCell No.: +63 935 713 2293 / +63 917 170 0979 / +63 919 078 0016 \n\nTel. No.: +63 2 8727 1771 / +63 2 8535 9968 / +63 2 7091 4539 \n\nService Mobile / Viber No.: +63 919 078 0019 / +63 927 414 5539 \n\nSpare Parts Mobile / Viber No.: \nEmail:Ingcocares@ingco.com.ph+63 995 095 3153")
            doc.setFont(undefined, 'normal').setFontSize(9).text(6,13,"2 D Arellano St. Bagong Barrio, Caloocan City \n\nCell No.:  \n\nTel. No.:  \n\nLandline:7002-3394\n\nSpare Parts Mobile / Viber No.: \nEmail:Ingcocares@ingco.com.ph")
            doc.setFont(undefined, 'bold').setFontSize(13).text(162,8,"Repair Form")
            doc.setFont(undefined, 'bold').setFontSize(9).text(162,13,"\nRF#:"+this.rfNumber+"\n\nPullout#: "+this.pulloutNumber+", RD#:"+this.rrNumber+"\n\nIssued: "+this.rrDate)
            doc.autoTable({
                html: '#rf_details',
                theme: 'grid',
                startY: 50,
                margin: {top: 37, left: 7, right: 6},
                columnStyles: {
                    0: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    1: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    2: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    3: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    4: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    5: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    6: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    7: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8}
                }
            });

            // doc.fromHTML($("#rf_estimate_title").get(0), 7, 134,{'width':400});
            doc.autoTable({
                html: '#rf_estimation',
                theme: 'grid',
                startY: 141,
                margin: {top: 37, left: 7, right: 6},
                columnStyles: {
                    0: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    1: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    2: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    3: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    4: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    5: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    6: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    7: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8}
                }
            });
            // doc.fromHTML($("#rf_unit_movements_title").get(0), 7, 185,{'width':400});
            doc.autoTable({
                html: '#rf_unit_movements',
                theme: 'grid',
                startY: 192,
                margin: {top: 37, left: 7, right: 6},
                columnStyles: {
                    0: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    1: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    2: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    3: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    4: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    5: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    6: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    7: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8}
                }
            });
            // doc.fromHTML($("#rf_repair_info_title").get(0), 7, 215,{'width':400});
            doc.autoTable({
                html: '#rf_repair_info',
                theme: 'grid',
                startY: 221,
                margin: {top: 37, left: 7, right: 6},
                columnStyles: {
                    0: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    1: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    2: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    3: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    4: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    5: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    6: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    7: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8}
                }
            });
            // doc.fromHTML($("#rf_releasing_info_title").get(0), 7, 243,{'width':400});
            doc.autoTable({
                html: '#rf_releasing_info',
                theme: 'grid',
                startY: 250,
                margin: {top: 37, left: 7, right: 6},
                columnStyles: {
                    0: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    1: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    2: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    3: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    4: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    5: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    6: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    7: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8}
                }
            });
            doc.autoPrint();

            if(!!this.rfDetails) {
                if(this.rfDetails.printed) {
                    if(this.confirmOverride ==  false) {
                        this.confirmedOverridePrint(doc);
                    } else {
                        window.open(doc.output('bloburl'), '_blank')
                        this.confirmOverride = false;
                    }
                } else {
                    window.open(doc.output('bloburl'), '_blank');
                    this.confirmOverride = false;
                }
                let payload = {
                    id: this.rfDetails.id,
                    params:{}
                }
                this.$store.dispatch('successRfPrint',payload)
                this.getAllRfs();
                this.getRfDetails(this.rfId);
            }
        },
        confirmedOverridePrint(doc) {
            this.showOverridePrint = true;
            if(!!this.overrideUserPassword) {
                let payload = {
                    departments_allowed: ['SR', 'IT'],
                    override_user_password: this.overrideUserPassword
                }
                this.$store.dispatch('confirmOverridePrintPL',payload).then((response)=> {
                    if(this.GET_CONFIRM_OVERRIDE_PRINT_PL.error) {
                        swal.fire("", this.GET_CONFIRM_OVERRIDE_PRINT_PL.error, "warning").then(()=>{this.confirmOverride = false;})
                        return false;
                    }

                    this.confirmOverride = true;
                    this.showOverridePrint = false;
                    this.overrideUserPassword = "";
                    this.printRepairForm();
                });
            }
        },
        closeOverrideDialog() {
            this.showOverridePrint = false;
        },
        successAssignTech() {
            this.rfDetailsPlainDialog = false;
            this.getAllRfs();
        },
        repairFormHeaders() {
            if(this.rfStatus == "TA") {
                return this.withActionTableHeaders;
            }

            if(this.rfStatus == "UAS" || this.rfStatus == "QUO") {
                return this.withTechnicianTableHeaders;
            }

            if(this.rfStatus == "WFP") {
                return this.withEstimationTableHeaders;
            }

            if(this.rfStatus == 'WCM'){
                return [
                    { text: 'RF#', value: 'rf_number' },
                    { text: 'RD#', value: 'sc_receiving_report.rr_number' },
                    { text: 'CM#', value: 'cm_number' },
                    { text: 'Item Model', value: 'sc_repair_form_item.model' },
                    { text: 'Item Name', value: 'sc_repair_form_item.product_name' },
                    { text: 'Serial No', value: 'sc_repair_form_item.serial' },
                    { text: 'Complaint/Symptom', value: 'sc_repair_form_item.defect_description' },
                    { text: 'Warranty Type', value: 'sc_repair_form_item.warranty_type' },
                    { text: 'Status', value: 'status' },
                    { text: 'Crate#', value: 'crate_number' },
                    { text: 'Loc#', value: 'location_number' },
                    { text: 'Technician', value: 'sc_technician.name' },
                    { text: 'Date', value: 'date' },
                    { text: 'Days', value: 'created_at' },
                    { text: 'Action', value: 'action' },
                ]
            }
            if(this.rfStatus == 'RFD'){
                return [
                    { text: 'RF#', value: 'rf_number' },
                    { text: 'RD#', value: 'sc_receiving_report.rr_number' },
                    { text: 'SO#', value: 'sales_order_num' },
                    { text: 'PL#', value: 'packing_list_num' },
                    { text: 'Item Model', value: 'sc_repair_form_item.model' },
                    { text: 'Item Name', value: 'sc_repair_form_item.product_name' },
                    { text: 'Serial No', value: 'sc_repair_form_item.serial' },
                    { text: 'Complaint/Symptom', value: 'sc_repair_form_item.defect_description' },
                    { text: 'Warranty Type', value: 'sc_repair_form_item.warranty_type' },
                    { text: 'Status', value: 'status' },
                    { text: 'Crate#', value: 'crate_number' },
                    { text: 'Loc#', value: 'location_number' },
                    { text: 'Technician', value: 'sc_technician.name' },
                    { text: 'Date', value: 'date' },
                    { text: 'Days', value: 'created_at' },
                    { text: 'Action', value: 'action' },
                ]
            }
            return this.defaultTableHeaders;
        },
        printedRf(rf) {
            if(rf.printed == 1) {
                return 'Yes';
            }
            return  'No';
        },
        showRfWithEstimation(rf){
            this.rf = rf;
            this.$store.commit('showRfDialogWithEst')
        },
        closeRfDialogWithEst(confirm) {
            if(confirm) {
                this.rf = null;
                this.getAllRfs();
                this.search = '';
            }
        },
        showRepairFormDetailsDialog(rf) {
            this.rf = rf;
            this.$store.commit('showRepairFormDetailsDialog');
        },

        cancelPending(item) {
            swal.fire({
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
                icon: 'warning',
                title: 'Cancel',
                text: ' - Are you sure you want to cancel?'
            }).then(result => {
                if (result.isConfirmed) {
                    let payload = {
                        id: item.id,
                        rf_number: item.rf_number
                    }
                    this.$store.dispatch('cancelPending',payload).then(response => {
                        this.getAllRfs()
                        swal.fire('Canceled!', ` - Order canceled`, 'success')
                    }).catch(e => {
                        swal.fire('Error!',e.response.data.message,'error')
                    })
                }
            })
        },
        async exportToExel(){
            this.loading = true;
            let exportHeadersAndData = {
                [this.rfStatus]: {
                    headers: this.rfStatus == 'PP' ?
                        [
                            { header: 'RF#', key: 'rf_number' },
                            { header: 'RD#', key: 'rr_number' },
                            { header: 'CM#', key: 'cm_number' },
                            { header: 'Item Model', key: 'model' },
                            { header: 'Item Name', key: 'product_name' },
                            { header: 'Serial#', key: 'serial' },
                            { header: 'Complaint/Symptom', key: 'defect_description' },
                            { header: 'Warranty Type', key: 'warranty_type' },
                            { header: 'Status', key: 'status' },
                            { header: 'Crate#', key: 'crate_number' },
                            { header: 'Loc#', key: 'location_number' },
                            { header: 'Date', key: 'status_date' },
                            { header: 'Days', key: 'days' },
                            { header: 'Part Model', key: 'order_model' },
                            { header: 'Part Name', key: 'order_name' },
                            { header: 'Request Qty', key: 'requested_qty' },
                            { header: 'Served Qty', key: 'serve_qty' },
                            { header: 'Transfer Qty', key: 'transfer_qty' },
                            { header: 'Order Item Status', key: 'order_item_status' },
                        ] : [
                            { header: 'RF#', key: 'rf_number' },
                            { header: 'RD#', key: 'rr_number' },
                            { header: 'CM#', key: 'cm_number' },
                            { header: 'Item Model', key: 'model' },
                            { header: 'Item Name', key: 'product_name' },
                            { header: 'Serial#', key: 'serial' },
                            { header: 'Complaint/Symptom', key: 'defect_description' },
                            { header: 'Warranty Type', key: 'warranty_type' },
                            { header: 'Status', key: 'status' },
                            { header: 'Crate#', key: 'crate_number' },
                            { header: 'Loc#', key: 'location_number' },
                            { header: 'Date', key: 'date' },
                            { header: 'Days', key: 'created_at' },
                        ],
                    data: []
                },
            }

            let payload = {
                url : 'repair-forms/processing/exports',
                status:this.rfStatus,
            }

            await this.$store.dispatch('servicePost',payload).then(async response=>{
                exportHeadersAndData[this.rfStatus].data = response.data
                if (this.rfStatus == 'FCA') {
                    exportHeadersAndData = Object.assign(exportHeadersAndData, {
                        Detailed_SQ: {
                            headers:[
                                { header: 'RF#', key: 'rf_number' },
                                { header: 'RD#', key: 'rr_number' },
                                { header: 'CM#', key: 'cm_number' },
                                { header: 'Item Model', key: 'model' },
                                { header: 'Item Name', key: 'product_name' },
                                { header: 'Quote Num', key: 'quote_num' },
                                { header: 'Parts Number', key: 'parts_number' },
                                { header: 'Parts Name', key: 'parts_name' },
                                { header: 'Quantity', key: 'sq_quantity' },
                                { header: 'Unit Price', style: { numFmt: '#,##0.00' }, key: 'unit_price' },
                                { header: 'Serial#', key: 'serial' },
                                { header: 'Complaint/Symptom', key: 'defect_description' },
                                { header: 'Warranty Type', key: 'warranty_type' },
                                { header: 'Status', key: 'status' },
                                { header: 'Crate#', key: 'crate_number' },
                                { header: 'Loc#', key: 'location_number' },
                                { header: 'Date', key: 'date' },
                                { header: 'Days', key: 'created_at' },
                            ],
                            data: []
                        }
                    })
                    let payload = {
                        url : 'repair-forms/processing/exports-detailed',
                        status:this.rfStatus,
                    }
                    await this.$store.dispatch('servicePost',payload).then(response=>{
                        let detailed_data = response.data
                        detailed_data.forEach(e => {
                            e.unit_price = isNaN(parseFloat(e.unit_price)) ? 0 : parseFloat(e.unit_price);
                            e.crate_number = Number(e.crate_number)
                            e.quote_num = Number(e.quote_num)
                        })
                        exportHeadersAndData.Detailed_SQ.data = detailed_data
                    })
                }
                await this.exportExcel(exportHeadersAndData, `Proccessing ${this.rfStatus}`, 'F69F1A')
                this.loading = false;
            })
        },

        rfSuccessConfirm(confirm) {
            if(confirm) {
                this.search = '';
                this.getAllRfs();
            }
        },
        date(item) {
            if(!!item) {
                return this.$dayjs(item.created_at).format('YYYY-MM-DD, hh:mm:ss');
            }

            return '';
        },
        searchInput(){
            this.getAllRfs({
                page:               1,
                itemsPerPage:       this.options.itemsPerPage,
                sortBy:             this.options.sortBy,
                sortDesc:           this.options.sortDesc,
                search:             this.search,
                status:             this.rfStatus,
                searchItem:         this.searchItem
            })
        },

        uploadedData(data) {
            if(!data) {
                this.uploadedFiles = null;
            }

            this.uploadedFiles = data;
        },
        clearFileUpload(confirm) {
            if(confirm) {
                this.uploadedFiles = null;
            }
            // this.$refs.fileUpload.reset();
        },

        updateRfEstItems() {
            this.loading = true
            let payload = {
                rfId: this.rfId,
                itemsForUpdate: this.rfEstItems,
                rfEstimation: this.rfEstimation,
                estimateTotalPrice: this.estimateItemTotalPrice,
                itemWarrantyType: this.itemWarrantyType,
                changeWarrantyType: this.changeWarrantyType
            }
            this.$store.dispatch('updateRfEstimationItems',payload).then(response => {
                if(response.data.error) {
                    swal.fire("", response.data.error, "error");
                } else {
                    swal.fire("", response.data.msg, "success");
                    this.getRfEstimation(this.rfId);
                    // this.closeRfDetailsDialog();
                }
                this.loading = false
            });
        },

        changeItemQty(item) {
            let items = _.compact(this.rfEstItems);
            let origItem = items.find(x=>x.id == item.id);
            origItem.foc = false;

            origItem.total = item.requested_qty * parseInt(item.selling_price);

            let total = 0;
            this.rfEstItems.forEach(x=>
                { total = total + parseInt(x.total) }
            )
            this.estimateItemTotalPrice = total;
        },

        warrantyTypeItems() {
            if(this.itemWarrantyType == 'In-Warranty') {
                return ['In-Warranty','Out-Warranty'];
            }
            // else if (this.backjob == 1) {
            //     return ['Dealer-Stock', 'End-User'];
            // }
            else {
                return ['In-Warranty','Out-Warranty', 'Dealer-Stock'];
            }
        },

        confirmChangeWarranty() {
            this.confirmChangeWarrantyOnFlight = true;

            if(this.itemWarrantyType == 'In-Warranty' && this.uploadedFiles == null) {
                swal.fire('', 'Please Upload Warranty Document', 'warning').then(x=>{
                    this.confirmChangeWarrantyOnFlight = false;
                });
            } else if(!this.purchaseDate && this.itemWarrantyType == 'In-Warranty') {
                 swal.fire('', 'Please Input Purchase Date', 'warning').then(x=>{
                    this.confirmChangeWarrantyOnFlight = false;
                });
            } else {
                const data = new FormData();

                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }

                if(!!this.uploadedFiles && this.uploadedFiles.attachments.length > 0) {
                    let files = this.uploadedFiles.attachments;

                    for(let i=0;i < _.compact(files).length;i++) {
                        data.append('files[]', files[i]);
                    }
                }


                let collectedOrderList = [];

                _.compact(this.rfEstItems).forEach(estitem=> {
                    collectedOrderList.push({
                        quantity: estitem.requested_qty,
                        item_id: estitem.item_id,
                        foc: estitem.foc,
                        disc_price: estitem.discounted_price,
                        total_amount: estitem.total,
                        amount: estitem.total * estitem.requested_qty,
                        type: estitem.item.item_type_text,
                        item: {
                            model: estitem.item.model,
                            name: estitem.item.name,
                            type_id: estitem.item.type_id,
                            item_uom_text: estitem.item.item_uom_text,
                            selling_price: estitem.selling_price,
                            srp: estitem.selling_price,
                            display_srp: estitem.selling_price,
                            moq: estitem.item.name,
                            name: estitem.item.name,
                        },
                        is_editable: true,
                        limit: 212,
                        display_disc_price: estitem.discounted_price,
                        display_total_amount: estitem.discounted_price,
                        orig_selling_price: estitem.selling_price,
                        sc_rf_est_item_id: estitem.id,
                        additional: estitem.additional,
                        additional_qty: estitem.additional_qty,
                        new_estimate: estitem.new_estimate,
                    })
                })

                let remarks = `RF# ${this.rfDetails.rf_number} | ${this.itemModel} | ${this.itemSerial}`
                if (this.customerId === 275) {
                    remarks = remarks + ` | ${this.customerName}`
                }

                let obj = JSON.stringify({
                    updatedWarrantyItems: this.rfEstItems.map(e=>{
                        e.total = e.selling_price * e.requested_qty
                        return e
                    }),
                    orderList: collectedOrderList,
                    discounts: JSON.parse(this.customerDetails.discounts)
                });

                data.append('rfId', this.rfId)
                data.append('newWarrantyType', this.itemWarrantyType)
                data.append('itemRepairStatus', this.itemRepairStatus)
                data.append('itemPurchaseDate', this.purchaseDate)
                data.append('changeWarrantyType', this.changeWarrantyType);

                data.append('customer_id', this.customerDetails.id)
                data.append('salesman_id', 5)
                data.append('shipping_address_index', 0)
                data.append('shipping_address_text', this.customerDetails.all_shipping_addresses[0].text)
                data.append('discounts', JSON.parse(this.customerDetails.discounts))
                data.append('terms', this.customerDetails.terms)
                data.append('stock_card', 0)
                data.append('remarks', remarks)
                // data.append('rfEstimationId', this.rfId)
                data.append('customer_id', this.customerId);

                data.append('data', obj);

                swal.fire({
                    title: 'Are you sure you want to Update Warranty Type?',
                    text: 'Click ok to proceed',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then(async result=> {
                    if(result.isConfirmed) {
                        // await this.updateRfEstItems();
                        let payload = {
                            params: data,
                            config: config,
                        }
                        await this.$store.dispatch('confirmChangeWarranty',payload).then(response=>{
                            if(response.data.error) {
                                swal.fire('', response.data.error, 'error');
                            } else {
                                this.$emit('successConfirm', true);
                                this.closeRfDetailsPlainDialog();
                                this.getAllRfs()
                                swal.fire("", response.data.msg, "success");
                            }
                        });
                    }

                    this.confirmChangeWarrantyOnFlight = false;
                });
            }
        },

        getDiscounts() {
            let total = 0;
            let discountedPrice = 0;
            let ingcoDiscounts = [];
            _.compact(this.rfEstItems).forEach((estitem) => {
                if(this.customerDiscounts.length > 0) {
                    _.compact(this.customerDiscounts).forEach(cd=>{
                        if(cd.brand_name == "INGCO") {
                            ingcoDiscounts = cd.discounts;
                        }
                    });
                    // if(ingcoDiscounts.length > 0) {
                    //     _.compact(ingcoDiscounts).forEach((ingcoDiscount, index)=>{
                    //         if(estitem.item.type_id == 1) {
                    //             if(ingcoDiscount.pt == 0 || ingcoDiscount.pt == null) {
                    //                 return;
                    //             }

                    //             if(!!this.customerSpecial) {
                    //                 discountedPrice = 0;
                    //             } else {
                    //                 if(index == 0) {
                    //                     discountedPrice = (1 - (parseInt(ingcoDiscount.pt) / 100)) * (parseInt(estitem.selling_price));
                    //                 } else {
                    //                     discountedPrice = (1 - (parseInt(ingcoDiscount.pt) / 100)) * discountedPrice;
                    //                 }
                    //             }

                    //         } else if(estitem.item.type_id == 2) {
                    //             if(ingcoDiscount.ht == 0 || ingcoDiscount.ht == null) {
                    //                 return;
                    //             }

                    //             if(index == 0) {
                    //                 discountedPrice = (1 - (parseInt(ingcoDiscount.ht) / 100)) * (parseInt(estitem.selling_price));
                    //             } else {
                    //                 discountedPrice = (1 - (parseInt(ingcoDiscount.ht) / 100)) * discountedPrice;
                    //             }
                    //         } else if(estitem.item.type_id == 3) {
                    //             if(ingcoDiscount.sp == 0 || ingcoDiscount.sp == null) {
                    //                 return;
                    //             }
                    //             // discountedPrice = (parseInt(estitem.selling_price) * estitem.requested_qty) * (parseInt(ingcoDiscount.sp) / 100);

                    //             if(index == 0) {
                    //                 discountedPrice = (1 - (parseInt(ingcoDiscount.sp) / 100)) * (parseInt(estitem.selling_price));
                    //             } else {
                    //                 discountedPrice = (1 - (parseInt(ingcoDiscount.sp) / 100)) * discountedPrice;
                    //             }
                    //         }
                    //     });
                    // }

                    // _.merge(estitem, {
                    //     discounted_price: discountedPrice
                    // })

                    let estItemTotal = 0;
                    if(estitem.item.foc) {
                        estitem.total = 0;
                    } else if(estitem.discounted_price > 0) {
                        estItemTotal = estitem.total = estitem.discounted_price * estitem.requested_qty;
                        total += estItemTotal;
                    } else {
                        estItemTotal = estitem.selling_price * estitem.requested_qty
                        total += estItemTotal;
                        estitem.total = estitem.selling_price * estitem.requested_qty;
                    }
                }
            });
            this.estimateItemTotalPrice = total;
        },

        discountDetails() {
            if(_.compact(this.customerDiscounts).length > 0) {
                let cDiscDetails = "";
                let cDiscount = [];
                let estItem = this.rfEstItems[0];
                let discounts = _.compact(this.customerDiscounts)[0].discounts;
                discounts.forEach(d=>{
                    if(estItem.item.type_id == 1) {
                        if(d.pt == 0) {
                            return;
                        }
                        cDiscount.push(d.pt)
                    } else {
                        if(d.sp == 0) {
                            return;
                        }
                        cDiscount.push(d.sp)
                    }
                });

                if(estItem.item.type_id == 1) {
                    cDiscDetails = `PT: ${cDiscount.toString().replaceAll(',', '/').toString()}`;
                } else {
                    cDiscDetails = `SP: ${cDiscount.toString().replaceAll(',', '/').toString()}`;
                }

                this.customerDiscount = cDiscDetails;
            }
        },

        getCustomer() {
            this.$store.dispatch('showCustomers',this.customerId).then(response=>{
                this.customerDetails = this.GET_SHOW_CUSTOMERS;
            });
        },

        async getRfEstimation() {
            this.loading = true
            await this.$store.dispatch('getRfEstimation',this.rfId).then((response) => {
                this.cm_status = response.data.cm_status
                const isEmpty = Object.keys(this.GET_RF_ESTIMATION).length === 0;

                if(response.data.sc_rf_est_items.find(e=>e.id).item.auto_foc == 1 && this.itemWarrantyType == 'In-Warranty'){
                    swal.fire('Info!','- This RF is indicated as AUTO FOC and by pass FTA','info')
                }

                if(!isEmpty) {
                    this.rfEstimation = this.GET_RF_ESTIMATION;

                    this.salesQuotationNumber = this.GET_RF_ESTIMATION.sales_quotation_number;

                    // Handle Parts Return
                    if(!!this.partsReturn) {
                        let estItems = [];
                        estItems = this.GET_RF_ESTIMATION.sc_rf_est_items;

                        if(estItems.length > 0) {
                            this.rfEstItems = estItems.filter(x=>{
                                return x.return == 0;
                            })

                            this.returnSpItems = estItems.filter(x=>{
                                return x.return == 1;
                            })
                        }

                        if(this.rfEstItems.length == 0) {
                            this.loading = false;
                        }

                        this.processedReturnBy = this.GET_RF_ESTIMATION.processedBy;
                    } else {
                        this.rfEstItems = this.GET_RF_ESTIMATION.sc_rf_est_items;
                    }

                    this.rfEstItemsDate = this.$dayjs(this.GET_RF_ESTIMATION.created_at).format("MM-DD-YYYY, hh:mm:ss a");
                }

                // this.loading = false
            });
        },
        getWarehouses() {
            this.$store.dispatch('serviceGetWarehouses').then(response => {
                this.warehousesCode = this.GET_SERVICE_WAREHOUSES.data;
            });
        },
    }
}
</script>

<style scoped>

.v-input--selection-controls {
margin-top: 0px;
padding-top: 0px;
}
</style>
