<template>
    <v-container class="tabs-body-color p-0">
        <v-card elevation="0" rounded="0" class="px-2">
            <v-card-title class="d-flex justify-content-between align-items-center">
                <span>
                    {{ ACTION }}
                    Cash Advance
                    {{ classificationText ? '(' + classificationText + ')' : '' }}
                </span>
                <v-btn text icon small color="gray" class="float-right" @click="() => {
                    this.resetField();
                    this.closeDialog();
                }">
                    <v-icon>mdi-close-circle</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-form ref="form" v-model="isValid">
                    <v-row class="px-2">
                        <v-col cols="4">
                            <v-text-field
                                v-model="form.doc_num"
                                :readonly="this.ACTION == 'View'"
                                :rules="[rules.required]"
                                label="CAF #"
                                dense
                                outlined
                                required
                            />
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col class="d-flex justify-content-end">
                            <BaseCameraCaptureVue
                                v-if="form.status == 1"
                                ref="cameraCapture"
                                @capturedImages="getImages"
                            ></BaseCameraCaptureVue>
                            <v-badge
                                v-if="!!images && images.length > 0"
                                :content="'+'+(images.length).toString()"
                                color="teal darken-1"
                                offset-x="20"
                                offset-y="50"
                                bottom
                                bordered
                            ></v-badge>
                            <FileUploadComponentVue
                                v-if="form.status == 1"
                                style="display:inline-block; transform: translateY(-10px);"
                                ref="fileUpload"
                                id="attachment"
                                @uploadedData="uploadedData"
                                @reset="clearFileUpload"
                            ></FileUploadComponentVue>
                            <BaseFileViewerComponent
                                :access="true"
                                :payloadIds="[]"
                                :payloadId="form.id ? form.id : null"
                                :getFilesPath="'cashAdvanceGetFiles'"
                                :deleteFilesPath="form.status == 1 ? 'cashAdvanceDeleteFiles' : ''"
                                :module="'accounting'"
                                :folder="'cash_advance_files'"
                                :returnData="true"
                                :localFiles="uploadedFiles"
                                @files="processFiles"
                                @return="insertUpload"
                                ref="clearFiles"
                            ></BaseFileViewerComponent>
                        </v-col>
                    </v-row>
                    <v-row class="px-2">
                        <v-col>
                            <v-autocomplete
                                v-model="form.employee_id"
                                :items="GET_EMPLOYEE_DATA"
                                :readonly="this.ACTION == 'View'"
                                :rules="[rules.required]"
                                label="Employee Name"
                                dense
                                outlined
                                required
                            />
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="form.company_name"
                                readonly
                                :rules="[rules.required]"
                                label="Company"
                                dense
                                outlined
                                required
                            />
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="form.store_branch_name"
                                readonly
                                :rules="[rules.required]"
                                label="Branch"
                                dense
                                outlined
                                required
                            />
                        </v-col>
                    </v-row>
                    <v-row class="px-2 pb-5">
                        <v-col cols="4">
                            <v-text-field
                                v-model="form.department_name"
                                readonly
                                :rules="[rules.required]"
                                label="Department"
                                dense
                                outlined
                                required
                            />
                        </v-col>
                        <v-col cols="4">
                            <v-text-field
                                v-model="form.payable_in"
                                :readonly="this.ACTION == 'View'"
                                :rules="[rules.required, rules.positiveNumber]"
                                label="Payable in how many cut off"
                                hide-spin-buttons
                                dense
                                outlined
                                required
                                type="number"
                            />
                        </v-col>
                        <v-col cols="3">
                            <v-text-field
                                v-model="form.total_amount"
                                :readonly="this.ACTION == 'View'"
                                :rules="[rules.required, rules.positiveNumber]"
                                label="Amount"
                                hide-spin-buttons
                                dense
                                outlined
                                required
                                type="number"
                            />
                        </v-col>
                        <v-col cols="1" class="d-flex justify-center align-center">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon><v-icon color="orange" v-on="on" @click="generateItems()" :disabled="!form.payable_in || !form.total_amount || disabled">mdi-file-plus</v-icon></v-btn>
                                </template>
                                <span>Generate</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-form>
                <v-card elevation="0" dense>
                    <v-card-text class="ma-0 p-0">
                        <v-form ref="itemsForm" v-model="isItemsFormValid">
                            <v-col cols="12">
                                <v-simple-table fixed-header dense class="overflow-y-auto" style="border: 1px solid #e0e0e0; max-height: 400px;">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-center" width="10%"></th>
                                                <th class="text-center" width="40%">PAYABLE IN DATE</th>
                                                <th class="text-center" width="40%">PAYMENT AMOUNT</th>
                                                <th class="text-center" width="10%">ACTION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="font-weight-medium text-gray particulars-container-row" v-for="(item, index) in form.cash_advance_items" :key="index">
                                                <td class="text-left">
                                                    {{ index + 1 }}
                                                </td>
                                                <td  style="height: 50px;">
                                                    <v-row class="d-flex justify-center">
                                                        <v-col cols="6">
                                                            <v-menu
                                                                v-model="item.menu"
                                                                :close-on-content-click="false"
                                                                transition="scale-transition"
                                                                max-width="290"
                                                            >
                                                                <template v-slot:activator="{ on }">
                                                                    <v-text-field
                                                                        v-model="item.payable_in_date"
                                                                        v-on="on"
                                                                        :rules="[rules.required]"
                                                                        class="right-align-text"
                                                                        append-icon="mdi-calendar"
                                                                        hide-details
                                                                    />
                                                                </template>
                                                                <v-date-picker v-model="item.payable_in_date" :min="currentDate" @input="item.menu = 0"/>
                                                            </v-menu>
                                                        </v-col>
                                                    </v-row>
                                                </td>
                                                <td>
                                                    <v-row class="d-flex justify-center">
                                                        <v-col cols="6">
                                                            <v-text-field
                                                                class="right-align-text"
                                                                type="number"
                                                                v-model="item.amount"
                                                                hide-spin-buttons
                                                                :rules="[rules.required, rules.positiveNumber]"
                                                                hide-details
                                                            >
                                                                <template v-slot:prepend-inner>
                                                                    <v-icon>mdi-currency-php</v-icon>
                                                                </template>
                                                            </v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                </td>
                                                <td class="text-center">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn v-on="on" fab dark x-small color="error" :disabled="disabled" style="height: 20px; width: 20px;" @click="removeItem(index)" >
                                                                <v-icon small>
                                                                    mdi-minus
                                                                </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Remove</span>
                                                    </v-tooltip>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                                <v-row class="pt-5">
                                    <v-col cols="12" class="d-flex align-end justify-end pr-4">
                                        <span>Total: &#x20b1;{{ sumCashAdvanceItem | currency }}</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-card-text>
            <v-card-actions class="d-flex justify-center align-center">
                <v-btn @click="submit()" v-if="this.ACTION != 'View'">SUBMIT</v-btn>
                <v-btn @click="approveCashAdvance(2)" v-if="this.ACTION == 'View' && this.form.status == 1">APPROVE</v-btn>
            </v-card-actions>
        </v-card>
        <v-dialog  v-model="progressDialog" max-width="500" height="100" persistent>
            <v-card flat>
                <v-card-title>
                    <p class="font-weight-medium"  style="font-size:12px">Saving Data...</p>
                </v-card-title>
                <v-card-text class="w-100">
                    <v-row class="w-100 mx-auto">
                        <v-col
                            cols="12"
                        >
                            <v-progress-linear
                                class="mx-auto"
                                v-model="chunksPercentage"
                                height="15"
                            >
                                <strong>{{ chunksPercentage }}%</strong>
                            </v-progress-linear>
                            <p class="font-weight-light align-right"  style="font-size:10px">{{ itemSave  }} / {{ toProcessFiles.length }} are saved</p>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import swal from "sweetalert2";
import FileUploadComponentVue from '@/views/main/Shared/FileUploadComponent.vue'
import BaseFileViewerComponent from "@/views/main/Shared/BaseFilesViewerComponent.vue"
import BaseCameraCaptureVue from "@/views/main/modules/HR/Base/BaseCameraCapture.vue"
    export default {
    components: {
        FileUploadComponentVue,
        BaseFileViewerComponent,
        BaseCameraCaptureVue
    },
    data() {
        return {
            isValid: false,
            isItemsFormValid: false,
            form: {
                payable_in: 0,
                doc_num: '',
                employee_id: '',
                company_id: '',
                store_branch_id: '',
                department_id: '',
                company_name: '',
                store_branch_name: '',
                department_name: '',
                payable_in: '',
                total_amount: '',
                status:'',
                cash_advance_items: [
                    {payable_in_date: '',amount: ''}
                ],
                with_trashed:[]
            },
            rules: {
                required: value => !!value || 'Required.',
                positiveNumber: value => (value > 0) || 'Must be a positive number.',
            },
            userAccess:[],
            loading: false,
            tmp: false,
            disabled: false,
            currentDate: this.$dayjs().format('YYYY-MM-DD'),
            uploadedImages: [],
            images: [],
            compiledFiles: [],
            uploadedFiles: [],
            viewUploadedFiles: [],
            saveFiles: [],
            chunksPercentage: 0,
            itemSave: 0,
            progressDialog: false,
            toProcessFiles: [],
            loadButton: false,
            viewUploadFiles: {
                attachments: []
            },
            menu: {
                payable_in_date: false,
            }
        }
    },
    mounted() {
    },
    computed: {
        ...mapGetters([
            "USER_ACCESS",
            "ACTION",
            "SELECTED",
            "GET_ALL_RANGE",
            "USERACCOUNT_LOAD",
            "DIALOGS",
            "GET_STORE_BRANCHES_WITHOUT_ACCESS",
            "GET_PV_COMPANIES_SELECTION",
            "GET_LIST_DEPARTMENT",
            "GET_EMPLOYEE_DATA",
            "GET_COMPANY_CLASSIFICATION_SELECTION"
        ]),
        checkIfEqual() {
            let totalItemAmount = this.form.cash_advance_items.reduce((sum, item) => {
            return sum + (parseFloat(item.amount) || 0);
            }, 0);
            return parseFloat(totalItemAmount) === parseFloat(this.form.total_amount);
        },
        sumCashAdvanceItem(){
            let totalItemAmount = this.form.cash_advance_items.reduce((sum, item) => {
            return sum + (parseFloat(item.amount) || 0);
            }, 0);

            return totalItemAmount;
        },
        classificationText() {
            let classificationId = this.GET_STORE_BRANCHES_WITHOUT_ACCESS.find(e => e.value == this.form.store_branch_id)?.company_classification_id
                ?? this.GET_PV_COMPANIES_SELECTION.find(e => e.value == this.form.company_id)?.company_classification_id;
            return classificationId
                ? this.GET_COMPANY_CLASSIFICATION_SELECTION.find(el => el.value == classificationId)?.text ?? ''
                : '';
        }
    },
    methods: {
        async saveFileDetails(id){
            let chunkedFiles = this.chunkArray(this.saveFiles, 1)
            let chunkedImages = this.chunkArray(this.uploadedImages, 1)
            await this.saveData(chunkedFiles, chunkedImages, id);
        },
        chunkArray(array, chunkSize) {
            const chunks = [];
            for (let i = 0; i < array.length; i += chunkSize) {
                chunks.push(array.slice(i, i + chunkSize));
            }
            return chunks;
        },
        async saveData(chunkedFiles, chunkedImages, id) {
            this.toProcessFiles = [...chunkedFiles, ...chunkedImages]
            let count = 0;
            this.progressDialog = true;
            const chunksLength = this.toProcessFiles.length;
            if (this.toProcessFiles.length != 0) {
                for (const chunk of this.toProcessFiles) {
                    this.chunksPercentage = Math.round((count / chunksLength) * 100);
                    let payload = {
                        file: chunk,
                        m_id: id,
                        url:'process-files',
                        pm: 'cash_advance',
                        um: 'CashAdvanceUpload',
                        mpath: 'cash_advance_files',
                        mparent: 'accounting'

                    }
                    await this.$store.dispatch('cashAdvanceUpload', payload).then(response => {
                        if (response.data.message == 'success') {
                            this.itemSave += chunk.length
                            count ++;
                            if (this.itemSave == this.toProcessFiles.length) {
                                this.chunksPercentage = 100;
                                this.progressDialog = false;
                                this.loadButton = false
                                this.itemSave = 0;
                            }
                        } else {
                            swal.fire('Error','- Error in saving file details', 'error');
                            this.progressDialog = false;
                            this.itemSave = 0;
                            this.loadButton = false
                        }
                    });
                }
            }
        },
        insertUpload(file){
            if (!!file) this.viewUploadFiles = file
        },
        async processFiles(files){
            files.forEach(e=>{
                this.compiledFiles.push(e.localFileState)
            })
            let fileData = []
            for (let i = 0; i < this.compiledFiles.length; i++) {
                let element = this.compiledFiles[i]
                let reader = new FileReader()
                reader.readAsDataURL(element)
                await new Promise((resolve) => {
                    reader.onloadend = function () {
                        fileData.push({
                            name: element.name,
                            type: element.type,
                            data: reader.result.split(',')[1],
                            upload_type: 1,
                        })
                        resolve()
                    }
                })
            }
            this.saveFiles = fileData
        },
        clearFileUpload(confirm){
            if (confirm) {
                this.uploadedFiles = []
            }
            this.uploadedFiles = []
            this.$refs.fileUpload.reset()
        },
        uploadedData(data){
            let dataToProcess = {
                attachments: []
            }
            if (this.viewUploadedFiles != null && this.viewUploadedFiles.length > 0) {
                data.attachments.forEach(a=>{
                    let isDuplicate = this.viewUploadedFiles.some(e=>{
                        return e.name.concat(`.${e.extension}`) === a.name
                    })
                    if (isDuplicate) {
                        swal.fire('Duplicate Files Detected!', '', 'warning')
                        return
                    } else {
                        dataToProcess.attachments.push(a)
                    }
                })
            } else {
                dataToProcess.attachments = data.attachments
            }
            this.uploadedFiles = dataToProcess
        },
        getImages(dataUrls) {
            this.images = dataUrls.map(dataUrl => {
                try {
                    if (!dataUrl.startsWith('data:image/jpeg;base64,')) {
                        throw new Error('Invalid data URL format');
                    }

                    const base64String = dataUrl.split(',')[1];
                    const byteString = atob(base64String);
                    const arrayBuffer = new ArrayBuffer(byteString.length);
                    const uint8Array = new Uint8Array(arrayBuffer);

                    for (let i = 0; i < byteString.length; i++) {
                        uint8Array[i] = byteString.charCodeAt(i);
                    }

                    const blob = new Blob([uint8Array], { type: 'image/jpeg' });
                    const timestamp = new Date().toISOString().replace(/[:.-]/g, '');
                    const filename = `captured-image-${timestamp}.jpg`;
                    const file = new File([blob], filename, { type: 'image/jpeg' });
                    return file;
                } catch (error) {
                    console.error('Failed to convert base64 to file:', error);
                    return null;
                }
            }).filter(file => file !== null);
            this.uploadedImages = []
            this.images.forEach(e=>{
                e.upload_type = 2,
                this.uploadedImages.push(e)
            })
            let fileData = []
            for (let i = 0; i < this.uploadedImages.length; i++) {
                const element = this.uploadedImages[i]
                let reader = new FileReader()
                reader.readAsDataURL(element)
                new Promise((resolve)=>{
                    reader.onloadend = function () {
                        fileData.push({
                            name: element.name,
                            type: element.type,
                            data: reader.result.split(',')[1],
                            upload_type: element.upload_type,
                        })
                        resolve()
                    }
                })
            }
            this.uploadedImages = fileData
        },
        approveCashAdvance(status){
            if(this.viewUploadFiles.length == 0){
                return swal.fire('No File Uploaded!','','warning');
            }
            swal.fire({
            icon: "question",
            text: "Are you sure you want to Update Status?",
            cancelButtonText: "Cancel",
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonColor: "#397373",
            cancelButtonColor: "grey",
            reverseButtons: true
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch("cashAdvanceGet",{
                        url: "cash-advance-update-status",
                        id: this.form.id,
                        status: status
                    }).then( async (response) => {
                        await this.saveFileDetails(response.data.id);
                        swal.fire(response.data.msg,'',response.data.icon);
                        this.closeDialog();
                        this.resetField();
                    });
                }
            });
        },
        submit(){
            if (this.$refs.form.validate()) {
                if(this.$refs.itemsForm.validate()){
                    if(this.checkIfEqual){
                        swal.fire({
                        icon: "question",
                        text: `Are you sure you want to ${this.ACTION}?`,
                        cancelButtonText: "Cancel",
                        showCancelButton: true,
                        allowOutsideClick: false,
                        confirmButtonColor: "#397373",
                        cancelButtonColor: "grey",
                        reverseButtons: true
                        })
                        .then((result) => {
                            if (result.isConfirmed) {
                                this.$store.dispatch(this.ACTION == 'Add' ? "cashAdvancePost" : "cashAdvancePut",{
                                    url: "cash-advance",
                                    id: this.form.id,
                                    form: this.form
                                }).then(async (response) => {
                                    await this.saveFileDetails(this.form.id);
                                    swal.fire(response.data.msg,'',response.data.icon);
                                    this.resetField();
                                    this.closeDialog();
                                }).catch(error => {
                                    console.error("Error adding Cash Advance:", error)
                                });
                            }
                        });
                    } else {
                        swal.fire('Total Amount is not equal to Total Payment Amount','','error');
                    }
                } else {
                    swal.fire('Required All Field','','error');
                }
            } else {
                swal.fire('Required All Field','','error');
            }
        },
        resetField(){
            Object.assign(this.$data, this.$options.data.call(this));
        },
        generateItems(){
                let remaining = parseFloat(this.form.payable_in) - parseFloat(this.form.cash_advance_items.length)
                for (let i = 1; i <= remaining; i++) {
                    this.form.cash_advance_items.push({payable_in_date: '',amount: ''});
                }
        },
        removeItem(index){
            let dump_index = this.form.cash_advance_items[index];
            this.form.with_trashed.push(dump_index.id);
            this.form.cash_advance_items.splice(index,1);
        },
        closeDialog(){
            this.$refs.clearFiles.$emit('clear-files');
            this.$store.commit('ACTION', 'Add');
            this.$store.commit('SELECTED',{});
            this.$store.commit('DIALOG', false);
            this.tmp = false;
        },
    },
    watch: {
        USER_ACCESS:{
            handler(val){
            if(val != "fail") this.userAccess = val.map(e=>e.actions_code);
            }
        },
        SELECTED:{
            handler(val){
                if(this.ACTION == 'Add'){
                    this.$store.dispatch("cashAdvanceGet",{
                        url: "generated-caf-number",
                    }).then(response => {
                        this.form.doc_num = response.data;
                    });
                }
                this.tmp = true;
                if(this.ACTION != 'Add' && this.tmp){
                    Object.assign(this.form, val);
                    this.form.store_branch_name = this.GET_STORE_BRANCHES_WITHOUT_ACCESS.find(e => e.value == this.form.store_branch_id).text ?? '';
                    this.form.company_name = this.GET_PV_COMPANIES_SELECTION.find(e => e.value == this.form.company_id).text ?? '';
                    this.form.department_name = this.GET_LIST_DEPARTMENT.find(e => e.value == this.form.department_id).text ?? '';
                }
            },
            deep: true,
            immediate: true,
        },
        ACTION:{
            handler(val){
                if(val == 'View'){
                    this.disabled = true;
                } else {
                    this.disabled = false;
                }
            },
            deep: true,
            immediate: true,
        },
        'form.employee_id':{
            handler(val){
                if(this.ACTION == 'Add' && val){
                    this.form.store_branch_id = this.GET_EMPLOYEE_DATA.find(e => e.value == val).store_branch_id ?? '';
                    this.form.company_id = this.GET_EMPLOYEE_DATA.find(e => e.value == val).company_id ?? '';
                    this.form.department_id = this.GET_EMPLOYEE_DATA.find(e => e.value == val).department_id ?? '';
                    this.form.store_branch_name = this.GET_STORE_BRANCHES_WITHOUT_ACCESS.find(e => e.value == this.form.store_branch_id).text ?? '';
                    this.form.company_name = this.GET_PV_COMPANIES_SELECTION.find(e => e.value == this.form.company_id).text ?? '';
                    this.form.department_name = this.GET_LIST_DEPARTMENT.find(e => e.value == this.form.department_id).text ?? '';
                }
            },
        }
    }
}
</script>
<style scoped>
    ::v-deep .right-align-text input {
        text-align: right;
    }
</style>
