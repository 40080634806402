<template>
    <v-container>
        <v-row class="mt-1">
            <v-col cols="12" class="text-right">
                <v-btn :disabled="hasRemaining" @click="vendorPaymentDetailShowAddEditDialog()">
                    <v-icon>mdi-plus</v-icon>
                    Create Payment
                </v-btn>
            </v-col>
        </v-row>

        <v-dialog v-model="addEditVendorPaymentDialog" scrollable persistent max-width="65%">
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">{{ `${dialogAction == 'edit' ? 'Edit' : 'Add'} Payment Detail` }}</span>
                        </v-card-title>
                    </v-col>

                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="vendorPaymentDetailCloseAddEditDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider class="mt-0" />

                <v-card-text class="px-4 pb-2">
                    <v-row :class="hide_upload?'pb-2 p-2 d-flex align-items-center':'pb-2 p-2 d-none align-items-center'" style="max-width: 30%;">
                        <v-col style="max-width: fit-content;">
                            Upload:
                            <FileUploadComponentVue ref="fileUpload" @uploadedData="uploadedData" @reset="clearFileUpload"/>
                        </v-col>
                            <v-divider  class="ml-2" vertical></v-divider>
                        <v-col class="ml-2 pt-2" style="max-width: fit-content;">
                            <BaseCameraCapture @capturedImages="getImages"></BaseCameraCapture>
                        </v-col>
                        <v-col  class="ml-1 pt-2">
                            <BaseFilesViewerVue :vendorPaymentDetailId="vendorPaymentDetailId" :delete="true" @openFilesViewer="openFilesViewer" @closeFilesViewer="closeFilesViewer"/>
                        </v-col>
                    </v-row>
                    <v-layout row class="mx-0">
                        <v-flex lg6 class="pr-2">
                            <v-card class="pa-2 mb-2">
                                <div class="pb-2 d-flex">
                                    <div>
                                        <v-dialog
                                            ref="paymentDateDialog"
                                            v-model="paymentDateModal"
                                            :return-value.sync="paymentDate"
                                            persistent
                                            width="300px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="paymentDate"
                                                readonly
                                                dense outlined
                                                label="Payment Date *"
                                                class="hide-text-field-details"
                                                v-bind="attrs"
                                                v-on="on"
                                                disabled
                                                clearable
                                            ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="paymentDate"
                                                scrollable
                                                :max="minDate"
                                            >
                                            <v-spacer />
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="paymentDateModal = false"
                                            >
                                                Cancel
                                            </v-btn>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="$refs.paymentDateDialog.save(paymentDate)"
                                            >
                                                OK
                                            </v-btn>
                                            </v-date-picker>
                                        </v-dialog>
                                    </div>
                                    <div class="pa-2">
                                        <span class="pa-5 font-weight-bold">Suggested Mode of Payment: {{GET_PAYMENT_TYPES.find(e=>e.id == mode_of_payment.payment_type_id)?GET_PAYMENT_TYPES.find(e=>e.id == mode_of_payment.payment_type_id).name:' N/A'}}</span>
                                    </div>
                                </div>
                                <div class="pb-2">
                                    <div>
                                        <v-autocomplete
                                            dense
                                            outlined
                                            :items="vendorList"
                                            v-model="selectedVendor"
                                            item-value="value"
                                            item-text="text"
                                            label="Vendor: *"
                                            :readonly="!!fromPaymentVoucher || !!fromJournalVoucher"
                                        />
                                    </div>
                                </div>
                                <div class="pb-2">
                                    <div>
                                        <v-autocomplete
                                            dense
                                            outlined
                                            :items="paymentTypeLists"
                                            :disabled="recurringBillDisable"
                                            v-model="selectedPaymentType"
                                            item-value="id"
                                            item-text="name"
                                            label="Payment Type: *"
                                        />
                                    </div>
                                </div>
                                <!-- <div class="pb-2">
                                    <div>
                                        <v-autocomplete
                                            dense
                                            outlined
                                            :items="GET_CHART_OF_ACCOUNTS_SELECTION"
                                            v-model="coa_id"
                                            item-value="id"
                                            item-text="account_name"
                                            label="Contra COA"
                                        />
                                    </div>
                                </div> -->
                                <div class="pb-2">
                                    <div>
                                        <v-textarea
                                        v-model="remarks"
                                        dense
                                        no-resize
                                        outlined
                                        label="Remarks: *"
                                        />
                                    </div>
                                </div>
                            </v-card>

                            <!-- <v-card v-if="paymentTypeName == 'Cash'" class="pa-2">
                                <div style="text-align:end">Total Amount: ₱ {{ totalCashAmount | currency }}</div>
                            </v-card> -->
                        </v-flex>
                        <v-flex lg6>
                            <v-card v-if="paymentTypeName == 'CSH'" class="pa-2">
                                <div>
                                    Cash Denomination Details:
                                </div>
                                <v-divider />
                                <v-layout row class="mx-0">
                                    <v-spacer />
                                    <v-flex lg3>
                                        <div style="text-align:end">
                                            Denomination
                                        </div>
                                    </v-flex>
                                    <v-flex lg1>
                                    </v-flex>
                                    <v-flex lg2>
                                        <div style="text-align:end">
                                            Quantity
                                        </div>
                                    </v-flex>
                                    <v-flex lg1>
                                    </v-flex>
                                    <v-flex lg3>
                                        <div style="text-align:end">
                                            Amount
                                        </div>
                                    </v-flex>
                                </v-layout>
                                <v-layout v-for="(cashDenomination, index) in cashDenominations" row :key="cashDenomination.id" class="mx-0 py-2">
                                    <v-spacer />
                                    <v-flex lg3>
                                        <div style="text-align:end" class="mt-2">
                                            {{ `${cashDenomination.value}` }}
                                        </div>
                                    </v-flex>
                                    <v-flex lg1>
                                        <div style="text-align:center" class="mt-2">
                                            x
                                        </div>
                                    </v-flex>
                                    <v-flex lg2>
                                        <div style="text-align:end">
                                            <v-text-field
                                                :ref="`c${index}`"
                                                type="number"
                                                dense
                                                outlined
                                                :min="0"
                                                v-model="cashDenomination.quantity"
                                                @change="getDenominationAmount(cashDenomination)"
                                                @focus="$event.target.select()"
                                            />
                                        </div>
                                    </v-flex>
                                    <v-flex lg1>
                                        <div style="text-align:center" class="mt-2">
                                            =
                                        </div>
                                    </v-flex>
                                    <v-flex lg3 class="mr-2">
                                        <div style="text-align:end" class="mt-2">
                                            {{ `${parseFloat(cashDenomination.value) * cashDenomination.quantity}` | currency }}
                                        </div>
                                    </v-flex>
                                </v-layout>

                                <v-divider />

                                <div style="text-align:end" class="pa-2">Amount: ₱ <span>{{ totalAmount | currency }}</span></div>
                            </v-card>

                            <v-card v-else-if="paymentTypeName != 'CSH' &&
                                !!selectedPaymentType &&
                                paymentTypeName != 'ONL' &&
                                paymentTypeName != 'CRD'"
                                class="pa-2"
                            >
                                <div class="d-flex">
                                    {{ `${paymentTypeName == 'CHQ' || paymentTypeName == 'ONL' ? paymentTypeName : '' } Details:` }}
                                    <v-spacer></v-spacer>
                                    <!-- <v-btn
                                        v-if="selectedPaymentType === 2"
                                        small
                                        @click="openViewDialog"
                                    >New Cheque Voucher</v-btn> -->
                                </div>
                                <v-divider />

                                <div v-if="paymentTypeName != 'CSH'" class="pb-2">
                                    <div v-if="paymentTypeName == 'CHQ'">
                                    <v-container class="pa-0">
                                        <v-row no-gutters>
                                            <v-col class="pr-1"  cols="12" sm="6" lg="6">
                                                <v-autocomplete
                                                    dense
                                                    outlined
                                                    :items="GET_BANK_SELECTION"
                                                    v-model="selectedBank"
                                                    item-value="id"
                                                    item-text="name"
                                                    label="Bank:"
                                                    />
                                            </v-col>
                                            <v-col cols="12" sm="12" lg="6">
                                                <v-dialog
                                                    ref="paymentDateDialog"
                                                    v-model="chequedateDialog"
                                                    :return-value.sync="accountDate"
                                                    persistent
                                                    width="300px"
                                                    >
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="accountDate"
                                                        readonly
                                                        dense outlined
                                                        label="Cheque Date"
                                                        class="hide-text-field-details"
                                                        v-bind="attrs"
                                                        v-on="on"

                                                    ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="accountDate"
                                                        scrollable
                                                    >
                                                    <v-spacer />
                                                    <v-btn
                                                        text
                                                        color="primary"
                                                        @click="chequedateDialog = false"
                                                    >
                                                        Cancel
                                                    </v-btn>
                                                    <v-btn
                                                        text
                                                        color="primary"
                                                        @click="$refs.paymentDateDialog.save(accountDate)"
                                                    >
                                                        OK
                                                    </v-btn>
                                                    </v-date-picker>
                                                    </v-dialog>

                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col  class="pr-1" cols="12"  sm="12" lg="6">
                                                <v-autocomplete
                                                v-model="booklet_id"
                                                :items="GET_BOOKLETS_SELECTION"
                                                dense
                                                outlined
                                                item-value="id"
                                                item-text="booklet_number"
                                                label="Booklet"
                                                readonly
                                                @change="selectBooklet"
                                                auto-select-first
                                                no-data-text="No Booklets Found, Assign Booklet to this Bank"
                                                />
                                            </v-col>
                                            <v-col cols="12"  sm="12" lg="6">
                                                <v-text-field
                                                    v-model="cheque_number"
                                                    dense outlined
                                                    label="Cheque Number"
                                                    />
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col class="pr-1" cols="12" sm="12" lg="12">
                                             <v-autocomplete
                                                dense
                                                outlined
                                                :items="GET_VENDOR_PAYMENT_ITEM"
                                                v-model="vendor_payment_item_id"
                                                item-value="id"
                                                item-text="name"
                                                label="Payee:"
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                        <!-- <v-autocomplete
                                            dense
                                            outlined
                                            label="Cheque Voucher Num: *"
                                            :items="cvSelection"
                                            v-model="selectedChequeVoucher"
                                            item-value="id"
                                            item-text="cheque_voucher_number"
                                            @change="insertCVDetails()"
                                        /> -->


                                    </div>
                                </div>

                                <div v-if="!['CSH','CHQ'].includes(paymentTypeName)" class="pb-2">
                                    <!-- <div v-if="paymentTypeName == 'Cheque'">
                                        <v-text-field
                                            dense
                                            outlined
                                            label="Bank: *"
                                            v-model="selectedBank"
                                            @change="insertAccNum()"
                                        />
                                    </div> -->
                                    <!-- <div v-else> -->
                                    <div>
                                        <v-autocomplete
                                            dense
                                            outlined
                                            label="Bank: *"
                                            :items="banks"
                                            v-model="selectedBank"
                                            item-value="id"
                                            item-text="name"
                                            @change="insertAccNum()"
                                        />
                                    </div>
                                </div>

                                <!-- <div class="pb-2">
                                    <div>
                                        <v-text-field
                                            label="Cheque/Account number *"
                                            v-model="accountNumber"
                                            dense
                                            outlined />
                                    </div>
                                </div> -->

                                <div class="pb-2" v-if="['DEP'].includes(paymentTypeName)">
                                    <v-layout row class="mx-0 my-1">
                                        <v-flex lg7 class="pr-2">
                                            <div>
                                                <v-text-field
                                                    v-model="accountName"
                                                    dense
                                                    label="Cheque/Account Name *"
                                                    outlined />
                                            </div>
                                        </v-flex>
                                        <v-flex lg5>
                                            <div>
                                            </div>
                                            <div>
                                                <v-dialog
                                                    ref="accountDateDialog"
                                                    v-model="accountDateModal"
                                                    :return-value.sync="accountDate"
                                                    persistent
                                                    width="290px"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="accountDate"
                                                        readonly
                                                        dense
                                                        outlined
                                                        label="Cheque/Account Date *"
                                                        class="hide-text-field-details"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        clearable
                                                    ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="accountDate"
                                                        scrollable
                                                    >
                                                    <v-spacer />
                                                    <v-btn
                                                        text
                                                        color="primary"
                                                        @click="accountDateModal = false"
                                                    >
                                                        Cancel
                                                    </v-btn>
                                                    <v-btn
                                                        text
                                                        color="primary"
                                                        @click="$refs.accountDateDialog.save(accountDate)"
                                                    >
                                                        OK
                                                    </v-btn>
                                                    </v-date-picker>
                                                </v-dialog>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </div>

                                <div class="pb-2" style="text-align:end">
                                    <div style="display:inline-block">
                                        <v-text-field
                                            type="text"
                                            :min="0"
                                            v-model="totalAmount"
                                            :disabled="selectedChequeVoucher != null && paymentTypeName == 'CHQ' "
                                            dense
                                            outlined
                                            @input="checkValue"
                                            label="*Amount"
                                            reverse
                                            class="right-align-text"
                                            :options="options"
                                            @keypress="decimalOnly"
                                            @keypress.enter="getTotalAmount()"
                                            @focus="$event.target.select()"
                                        />
                                    </div>
                                </div>
                            </v-card>

                            <!-- <v-card v-else-if="paymentTypeName == 'ONL'" class="pa-2">
                                <div>
                                    {{ `${paymentTypeName == 'ONL' ? paymentTypeName : '' } Details:` }}
                                </div>
                                <v-divider />
                                <div v-if="paymentTypeName != 'CSH'" class="pb-2">
                                    <div class="mb-1">
                                        <v-autocomplete
                                            dense
                                            outlined
                                            label="Bank: *"
                                            :items="banks"
                                            v-model="selectedBank"
                                            item-value="id"
                                            item-text="name"
                                            @change="insertAccNum()"
                                        />
                                    </div>
                                    <div>
                                        <v-text-field
                                            label="Account Name *"
                                            v-model="card_name"
                                            dense
                                            outlined />
                                            <v-autocomplete
                                            dense
                                            outlined
                                            label="Card Name *"
                                            :items="banksUser"
                                            v-model="selected_name"
                                            item-value="id"
                                            item-text="name"
                                        />
                                        <v-text-field
                                            label="Card Name *"
                                            v-model="card_name"
                                            dense
                                            outlined />
                                    </div>
                                </div>
                                <div class="pb-2">
                                    <div>
                                        <v-text-field
                                            label="Card Number *"
                                            v-model="card_number"
                                            dense
                                            readonly
                                            outlined />
                                    </div>
                                </div>
                                <div class="pb-2">
                                    <div>
                                        <v-text-field
                                            label="Approval Code *"
                                            v-model="approval_code"
                                            dense
                                            outlined />
                                    </div>
                                </div>
                                <div class="pb-2">
                                    <v-layout row class="mx-0 my-1">
                                        <v-flex lg5>
                                            <div>
                                            </div>
                                            <div>
                                                <v-dialog
                                                    ref="accountDateDialog"
                                                    v-model="accountDateModal"
                                                    :return-value.sync="accountDate"
                                                    persistent
                                                    width="290px"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="online_date"
                                                        dense
                                                        outlined
                                                        label="Date *"
                                                        class="hide-text-field-details"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="online_date"
                                                        scrollable
                                                    >
                                                    <v-spacer />
                                                    <v-btn
                                                        text
                                                        color="primary"
                                                        @click="accountDateModal = false"
                                                    >
                                                        Cancel
                                                    </v-btn>
                                                    <v-btn
                                                        text
                                                        color="primary"
                                                        @click="$refs.accountDateDialog.save(accountDate)"
                                                    >
                                                        OK
                                                    </v-btn>
                                                    </v-date-picker>
                                                </v-dialog>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </div>

                                <div class="pb-2" style="text-align:end">
                                    <div style="display:inline-block">
                                        <v-text-field
                                            type="text"
                                            :min="0"
                                            v-model="totalAmount"
                                            dense
                                            outlined
                                            @input="checkValue"
                                            label="*Amount: ₱"
                                            class="right-align-text"
                                            @keypress="decimalOnly"
                                            @keypress.enter="getTotalAmount()"
                                            @focus="$event.target.select()"
                                        />
                                    </div>
                                </div>
                            </v-card> -->

                            <v-card v-else-if="paymentTypeName == 'CRD'" class="pa-2">
                                <div>
                                    {{ `${paymentTypeName == 'CRD' ? paymentTypeName : '' } Details:` }}
                                </div>
                                <v-divider />
                                <div class="pb-2">
                                    <div class="mb-1">
                                        <span>Card Type</span>
                                        <v-radio-group row mandatory v-model="card_type">
                                            <v-radio label="Debit" value="debit"></v-radio>
                                            <v-radio label="Credit" value="credit" :disabled="payment_voucher_type.text === 'CREDIT BILL'"></v-radio>
                                        </v-radio-group>
                                    </div>
                                    <div>
                                        <v-autocomplete
                                            dense
                                            outlined
                                            label="Bank: *"
                                            :items="banks"
                                            v-model="selectedBank"
                                            item-value="id"
                                            item-text="name"
                                            @change="insertAccNum()"
                                        />
                                    </div>
                                    <div>
                                        <v-autocomplete
                                            dense
                                            outlined
                                            label="Card Name *"
                                            :items="banksUser"
                                            v-model="selected_name"
                                            item-value="id"
                                            item-text="name"
                                        />
                                        <!-- <v-text-field
                                            label="Card Name *"
                                            v-model="card_name"
                                            dense
                                            outlined /> -->
                                    </div>
                                </div>
                                <div class="pb-2">
                                    <div>
                                        <v-text-field
                                            label="Card Number *"
                                            v-model="card_number"
                                            dense
                                            readonly
                                            outlined />
                                    </div>
                                </div>
                                <!-- <div class="pb-2">
                                    <div>
                                        <v-text-field
                                            label="Approval Code *"
                                            v-model="approval_code"
                                            dense
                                            outlined />
                                    </div>
                                </div> -->
                                <div class="pb-2">
                                    <v-layout row class="mx-0 my-1">
                                        <v-flex lg5>
                                            <div>
                                            </div>
                                            <div>
                                                <v-dialog
                                                    ref="accountDateDialog"
                                                    v-model="accountDateModal"
                                                    :return-value.sync="accountDate"
                                                    persistent
                                                    disabled
                                                    width="290px"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="card_date"
                                                        readonly
                                                        dense
                                                        outlined
                                                        label="Date *"
                                                        class="hide-text-field-details"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="card_date"
                                                        scrollable
                                                    >
                                                    <v-spacer />
                                                    <v-btn
                                                        text
                                                        color="primary"
                                                        @click="accountDateModal = false"
                                                    >
                                                        Cancel
                                                    </v-btn>
                                                    <v-btn
                                                        text
                                                        color="primary"
                                                        @click="$refs.accountDateDialog.save(accountDate)"
                                                    >
                                                        OK
                                                    </v-btn>
                                                    </v-date-picker>
                                                </v-dialog>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </div>

                                <div class="pb-2" style="text-align:end">
                                    <div style="display:inline-block">
                                        <v-text-field
                                            type="text"
                                            :min="0"
                                            v-model="totalAmount"
                                            dense
                                            outlined
                                            @input="checkValue"
                                            label="*Amount: ₱"
                                            class="right-align-text"
                                            @keypress="decimalOnly"
                                            @keypress.enter="getTotalAmount()"
                                            @focus="$event.target.select()"
                                        />
                                    </div>
                                </div>
                            </v-card>

                            <v-card v-else-if="paymentTypeName == 'ONL'" class="pa-2">
                                <v-autocomplete
                                    dense
                                    outlined
                                    :items="GET_VENDOR_PAYMENT_ITEM"
                                    v-model="vendor_payment_item_id"
                                    item-value="id"
                                    item-text="name"
                                    label="Payee:"
                                    />
                                </v-col>
                                <div class="mb-1">
                                    <v-autocomplete
                                        dense
                                        outlined
                                        label="Bank"
                                        :items="banks"
                                        v-model="selectedBank"
                                        item-value="id"
                                        item-text="name"
                                        @change="insertAccNum()"
                                    />
                                </div>
                                <v-text-field
                                            label="Card Name"
                                            v-model="card_name"
                                            dense
                                            outlined />
                                <v-text-field
                                    v-model="phone_number"
                                    type="text"
                                    dense
                                    outlined
                                    label="Phone Number / Account Number"
                                    v-mask="'####-####-###'"
                                >

                                </v-text-field>
                                <v-divider />
                                <v-checkbox
                                    label="Cash or Denomination"
                                    v-model="cash_or_denomination"
                                    :true-value="1"
                                    :false-value="0"
                                >
                                </v-checkbox>
                                <v-text-field
                                    v-if="!cash_or_denomination"
                                    type="text"
                                    :min="0"
                                    v-model="totalAmount"
                                    dense
                                    outlined
                                    @input="checkValue"
                                    label="*Amount"
                                    reverse
                                    class="right-align-text"
                                />
                                <div v-if="cash_or_denomination">
                                    <div>
                                        Cash Denomination Details:
                                    </div>
                                    <v-divider />
                                    <v-layout row class="mx-0">
                                        <v-spacer />
                                        <v-flex lg3>
                                            <div style="text-align:end">
                                                Denomination
                                            </div>
                                        </v-flex>
                                        <v-flex lg1>
                                        </v-flex>
                                        <v-flex lg2>
                                            <div style="text-align:end">
                                                Quantity
                                            </div>
                                        </v-flex>
                                        <v-flex lg1>
                                        </v-flex>
                                        <v-flex lg3>
                                            <div style="text-align:end">
                                                Amount
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout v-for="(cashDenomination, index) in cashDenominations" row :key="cashDenomination.id" class="mx-0 py-2">
                                        <v-spacer />
                                        <v-flex lg3>
                                            <div style="text-align:end" class="mt-2">
                                                {{ `${cashDenomination.value}` }}
                                            </div>
                                        </v-flex>
                                        <v-flex lg1>
                                            <div style="text-align:center" class="mt-2">
                                                x
                                            </div>
                                        </v-flex>
                                        <v-flex lg2>
                                            <div style="text-align:end">
                                                <v-text-field
                                                    :ref="`c${index}`"
                                                    type="number"
                                                    dense
                                                    outlined
                                                    :min="0"
                                                    v-model="cashDenomination.quantity"
                                                    @change="getDenominationAmount(cashDenomination)"
                                                    @focus="$event.target.select()"
                                                />
                                            </div>
                                        </v-flex>
                                        <v-flex lg1>
                                            <div style="text-align:center" class="mt-2">
                                                =
                                            </div>
                                        </v-flex>
                                        <v-flex lg3 class="mr-2">
                                            <div style="text-align:end" class="mt-2">
                                                {{ `${parseFloat(cashDenomination.value) * cashDenomination.quantity}` | currency }}
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                    <v-divider />

                                    <div style="text-align:end" class="pa-2">Amount: ₱ <span>{{ totalAmount | currency }}</span></div>
                                </div>
                            </v-card>

                        </v-flex>
                    </v-layout>

                    <v-divider />

                    <v-row style="text-align:end" class="py-4 d-flex flex-column align-content-end mr-2 align-items-center">
                        <div class="pb-2 float-right">
                                <v-text-field
                                    type="text"
                                    :min="0"
                                    :max="totalAmount"
                                    v-model="whtAmount"
                                    dense
                                    outlined
                                    label="WHT Amount: ₱"
                                    style="
                                        width: 175px;
                                        padding:0 5px !important;
                                    "
                                    class="right-align-text"
                                    :disabled="totalAmount == 0 || parseInt(totalAmount) == parseInt(balance_amounts) ? true : false"
                                    @keypress="decimalOnly"
                                    @focus="$event.target.select()"
                                >
                                    <!-- <template v-slot:append>
                                        <span style="font-size: 18px; padding-top: 2.2px">.00</span>
                                    </template> -->
                                </v-text-field>
                        </div>
                        <div class="pb-2 float-right">
                                <v-text-field
                                    type="text"
                                    :min="0"
                                    :max="totalAmount"
                                    v-model="otherAdjAmount"
                                    dense
                                    outlined
                                    label="Other Adj Amount: ₱"
                                    style="
                                        text-align:right;
                                        width: 175px;
                                        padding:0 5px !important;
                                    "
                                    class="right-align-text"
                                    :disabled="totalAmount == 0"
                                    @keypress="decimalOnly"
                                    @keypress.enter="getOtherAdjAmount()"
                                    @focus="$event.target.select()"
                                >
                                    <!-- <template v-slot:append>
                                        <span style="font-size: 18px; padding-top: 2.2px">.00</span>
                                    </template> -->
                                </v-text-field>

                        </div>
                        <div class="pb-2">
                            Balance Amount: ₱ {{ balance_amounts | currency}}
                        </div>
                        <div>
                            <!-- Net Amount: ₱ {{ netAmount | currency }} -->
                            Total Amount: ₱ {{ (parseFloat(totalAmount) + parseFloat(whtAmount)) | currency }}
                        </div>
                    </v-row>
                </v-card-text>

                <v-divider class="mt-0" />

                <div style="text-align:center" class="pb-4" >
                    <v-btn v-if="dialogAction == 'edit'" @click="update()">Update</v-btn>
                    <v-btn v-else :loading="submitOnFlight" @click="submit()" :disabled="totalAmount == 0">Submit</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <DialogComponent
            :cp="components"

        ></DialogComponent>

        <v-dialog
            v-model="$store.state.chequevoucher.isDialogVisible"
            width="42rem"
            persistent
        >
            <ChequeVoucherViewComponent
                :vendor_id="vendorId"
            />
        </v-dialog>
    </v-container>
</template>

<script>
import FileUploadComponentVue from '../../../../Shared/FileUploadComponent.vue'
import BaseFilesViewerVue from '../../Base/BaseFilesViewer.vue';
import swal from 'sweetalert2';
import { mapGetters } from 'vuex'
import CameraCaptureComponent from '../../Base/CameraCaptureComponent.vue';
import BaseCameraCapture from '../../../Services/Base/BaseCameraCapture.vue';
import DialogComponent from "@/views/main/layouts/dialogs/Dialog.vue";
import ChequeVoucherViewComponent from '@/views/main/layouts/dialogs/Accounting/ChequeVoucherViewComponent.vue';

export default {
    data() {
        return {
            cv_dialog: false,
            vendorList: [],
            selectedVendor: '',
            paymentTypeLists: [],
            banks: [],
            banksUser:[],
            cashDenominations: [],
            submitOnFlight: false,
            updateOnFlight: false,
            uploadedFiles: null,
            minDate: this.$dayjs().format('YYYY-MM-DD'),

            accountDateModal: false,
            selectedPaymentType: '',
            // coa_id:'',
            selectedBank: '',
            cardBank: '',
            selectedChequeVoucher: null,
            selectedChequeBank: '',
            paymentTypeName: '',
            remarks: '',
            phone_number: '',
            chequeAccountNumber: '',

            chequeName: '',
            chequeDescription: '',
            totalAmount: 0,
            chequeAmount: 0,
            chequeDate: '',
            vendorPaymentDetail: null,

            whtAmount: 0,
            otherAdjAmount: 0,

            paymentDateModal: false,
            paymentDate: this.$dayjs().format('YYYY-MM-DD'),

            accountNumber: '',
            accountName: '',
            accountDate: '',
            accountDescription: '',

            online_date:'',
            transactionNumber:'',
            OnlineDescription:'',
            userAccess: {
                    create  : false,
                    edit    : false,
                    view    : false,
                    delete  : false,
                },

            hide_upload:false,
            loading:{
                vendor_selection          : true,
                payment_type_selection      : true,
                banks_selection             : true
            },
            expectedDepositDate: '',
            card_name:'',
            selected_name: '',
            card_number:'',
            approval_code:'',
            card_date:'',
            images:null,

            components: ChequeVoucherViewComponent,
            chequedateDialog:false,
            dialog_scrollable: true,
            cvSelection: [],
            cvData:[] ,
            bank_id:'',
            booklet_id:'',
            cheque_date:'',
            cheque_number:'',
            vendor_payment_item_id:'',
            gettingChequeNumber:false,
            readonlyChequeNumber:true,
            pulsingBooklet: false,
            options: {
                    prefix: "₱",
                    suffix: "",
                    length: 16,
                    precision: 0
                },
            recurringBillDisable: false,
            booklet_item_id: 0,
            balance_amounts: 0,
            cash_or_denomination: 0,
            card_type: 'debit',
        }
    },
    async mounted() {
        await this.getPaymentTypes();
        this.$store.dispatch('getAdminSupplierSelection')
        this.$store.dispatch('getCOASelection')
        this.$store.dispatch("getBankSelection",{international:0, ap:'AP'});
        // await this.getAllVendor();
        this.checkAccess();
        this.$on('open-dialog', (data) => {
        });
    },
    components: {
        FileUploadComponentVue,
        BaseFilesViewerVue,
        CameraCaptureComponent,
        BaseCameraCapture,
        DialogComponent,
        ChequeVoucherViewComponent,
    },
    props: ['addEdit', 'vendorId', 'fromPaymentVoucher', 'fromJournalVoucher', 'balanceAmounts', 'payment_voucher_id', 'origin', 'hasRemaining', 'payment_method_id','payment_voucher_type', 'paymentTypeIds','mode_of_payment'],
    validations: {
        // accountNumber: {
        //     required: requiredIf('requireCheque')
        // },
        // accountDate: {
        //     required: requiredIf('requireCheque')
        // },
        // paymentDate: {
        //     required
        // },
        // selectedVendor: {
        //     required
        // },
        // selectedVendor: {
        //     required
        // },
        // transactionNumber: {
        //     required: requiredIf('requireOnline')
        // },
        // card_name: {
        //     required: requiredIf('requireCard')
        // },
        // card_number: {
        //     required: requiredIf('requireCard')
        // },
        // // approval_code: {
        // //     required: requiredIf('requireCard')
        // // },
        // selectedPaymentType: {
        //     required
        // },
        // selectedBank: {
        //     required: requiredIf('checkPaymentType')
        // },
        // totalAmount: {
        //     required
        // },
        // phone_number: {
        //     required: requiredIf('requirePhoneNumber')
        // },
        // remarks: {
        //     required
        // }
    },
    computed: {
        ...mapGetters([
            'GET_PAYMENT_TYPES',
            'GET_BANKS',
            'GET_CASH_DENOMINATIONS',
            'GET_CUSTOMER_PAYMENT_DETAIL',
            'GET_ADMIN_SUPPLIER_SELECTION',
            'USER_ACCESS',
            'GET_CHART_OF_ACCOUNTS_SELECTION',
            'GET_CHEQUE_VOUCHERS_SELECTION',
            'GET_BANK_SELECTION',
            'GET_BOOKLETS_SELECTION',
            'GET_VENDOR_PAYMENT_ITEM'
        ]),
        checkPaymentType() {
            if(this.paymentTypeName != 'CSH' &&
                this.paymentTypeName != 'CRD' &&
                this.paymentTypeName != 'ONL'
                ) {
                return true;
            }

            return false;
        },
        requireCheque() {
            if(this.paymentTypeName == 'CHQ') {
                return true;
            }

            return false;
        },
        requireOnline(){
            if(this.paymentTypeName == 'ONL') {
                return true;
            }

            return false;
        },
        requireCard(){
            if(this.paymentTypeName == 'CRD') {
                return true;
            }

            return false;
        },
        requirePhoneNumber() {
            if(this.paymentTypeName == 'ONL') {
                return false;
            }

            return true;
        },
        addEditVendorPaymentDialog() {
            this.hide_upload = false;
            return this.$store.state.accounting.vendorPaymentDetail.addEditVendorPaymentDialog;
        },
        dialogAction() {
            return this.$store.state.accounting.vendorPaymentDetail.dialogAction;
        },
        vendorPaymentDetailId() {
            return this.$store.state.accounting.vendorPaymentDetail.vendorPaymentDetailId;
        },
        grossAmount() {
            if(this.convertTextToNumber(this.totalAmount) > 0) {
                let amount = this.convertTextToNumber(this.totalAmount) + this.convertTextToNumber(this.whtAmount);

                return this.getTwoDecimals(amount);
            }

            return this.getTwoDecimals(0);
        },
        // netAmount() {
        //     if(this.convertTextToNumber(this.totalAmount) > 0) {
        //         let amount = this.convertTextToNumber(this.grossAmount) - this.convertTextToNumber(this.whtAmount) - this.convertTextToNumber(this.otherAdjAmount);

        //         return this.getTwoDecimals(amount);
        //     }

        //     return this.getTwoDecimals(0);
        // },
         filterBooklet() {
    //   let filteredBooklet = this.GET_BOOKLETS_SELECTION.filter(bookletNum => {
    //     return bookletNum.bank_id == this.selectedBank;
    //   });

        this.$store.dispatch('getBookletsSelection',this.selectedBank)

    },
    },
    watch: {
        '$store.state.accounting.vendorPaymentDetailsData':{
            handler(val){
                if(val){
                    this.selectedVendor = val.vendor_id;
                    this.balance_amounts = val.balance_amount;
                    this.$store.dispatch('getVendorPaymentItems',val.vendor_id);
                }
            },
            deep:true,
            immediate:true
        },
        selected_name:{
            handler(val){
                let bankUser = this.banksUser.find((e) => e.id == val)
                this.card_name = bankUser.name;
                this.card_number = bankUser.card_number;
                this.card_date = this.$dayjs(bankUser.card_expiration).format('YYYY-MM');
            },
            deep: true
        },
        'payment_voucher_type':{
            handler(val){
            },
            immediate: true
        },
        '$store.state.chequevoucher.lastCreatedChequeVoucher': {
            handler(val) {
                this.getChequeVouchersSelection();
                if (val !== null) {
                    this.selectedChequeVoucher = this.$store.state.chequevoucher.lastCreatedChequeVoucher;
                    this.$store.state.chequevoucher.lastCreatedChequeVoucher = null;
                }
            },
        },
        selectedPaymentType() {
            this.getChequeVouchersSelection();
            if(!!this.selectedPaymentType) {

                let paymentType = this.paymentTypeLists.find(x=>x.id == this.selectedPaymentType);

                this.paymentTypeName = paymentType.code;

                if(this.paymentTypeName === 'CSH'){
                    this.totalAmount = 0
                }
                else{
                    this.totalAmount = parseFloat(this.balance_amounts).toFixed(2)
                }

                let payload = {
                    payment_type_id: this.selectedPaymentType,
                    withAccountNumber: true,
                    url: `getBanksByPaymentType`
                }
                // this.$store.dispatch('banksGet',payload).then(response=>{
                //     this.banks = response.data.map((element) => {
                //         return {
                //             id: element.id,
                //             orig_name: element.name,
                //             name: element.account_number ? `${element.name} (${element.account_number})` : element.name,
                //             account_number: element.account_number,
                //         };
                //     });
                // });
                this.$store.dispatch('getBankSelection',{type:'AP'}).then(response=>{
                    this.banks = response
                });



            }
        },
        paymentTypeName() {
            if(this.paymentTypeName == 'CSH' || 'ONL') {
                if(this.dialogAction == 'add') {
                    this.hide_upload = false;
                    this.getCashDenominations();
                }
            }
            else if(this.paymentTypeName == 'CRD'){
                if(this.dialogAction == 'add') {
                    this.hide_upload = true;
                }
            }
            // else if (this.paymentTypeName == 'Gcash' || 'Maya') {
            //     if(this.dialogAction == 'add') {
            //         this.hide_upload = false;
            //         this.getCashDenominations();
            //     }
            // }
            else {
                if(this.dialogAction == 'add') {
                    this.hide_upload = true;
                    this.clearCashDenominations();
                }
            }
        },
        vendorPaymentDetail() {
            if(!!this.vendorPaymentDetail) {
                const {
                    payment_date,
                    vendor_id,
                    payment_type_id,
                    remarks,
                    cash_denomination_breakdown,
                    bank_id,
                    cheque_account_number,
                    cheque_name,
                    cheque_date,
                    cheque_description,
                    account_number,
                    account_name,
                    account_date,
                    account_description,
                    total_amount,
                    wht_amount,
                    other_adj_amount,
                } = this.vendorPaymentDetail;

                this.paymentDate = payment_date;
                this.selectedVendor = vendor_id;

                this.selectedPaymentType = payment_type_id;
                this.cashDenominations = JSON.parse(cash_denomination_breakdown);
                this.selectedBank = bank_id;

                let paymentType = this.paymentTypeLists.find(x=>x.id == payment_type_id);

                let paymentTypeName = !!paymentType ? paymentType.name : '';

                if(!!paymentTypeName) {
                    if(paymentTypeName == 'CHQ') {
                        this.accountNumber = cheque_account_number;
                        this.accountName = cheque_name;
                        this.accountDate = cheque_date;
                        this.accountDescription = cheque_description;
                    } else if(paymentTypeName != 'CSH' || paymentTypeName != 'CHQ') {
                        this.accountNumber = account_number;
                        this.accountName = account_name;
                        this.accountDate = account_date;
                        this.accountDescription = account_description;
                    }
                }

                this.remarks = remarks;

                this.totalAmount = this.getTwoDecimals(total_amount);
                this.whtAmount = this.getTwoDecimals(wht_amount);
                this.otherAdjAmount = this.getTwoDecimals(other_adj_amount);
            }
        },
        dialogAction() {
            if(this.dialogAction == 'edit' && !!this.vendorPaymentDetailId) {
                this.getVendorPaymentDetail();
            }
        },
        'GET_ADMIN_SUPPLIER_SELECTION':{
            handler(val){
                this.vendorList = val
                this.loading.vendor_selection = false
            }
        },
        'GET_PAYMENT_TYPES':{
            handler(val){
                this.loading.payment_type_selection = false
            }
        },
        'GET_BANKS':{
            handler(val){
                this.loading.banks_selection = false
            }
        },
        accountDate:{
            handler(val){
                // let arrayOfSelectedDate = val.split("-");
                // console.log(arrayOfSelectedDate[1]);


                // let new_date = new Date();

                // let d = new Date(val);
                // let day = d.getDay();

                // if(day <= 3 || day == 6){
                //     new_date.setDate(val.getDate() + 2)
                // }
                // else if (day == 4){
                //     new_date.setDate(val.getDate() + 4)
                // }
                // else if (day == 5){
                //     new_date.setDate(val.getDate() + 3)
                // }

                // console.log(new_date)
                let dt = new Date(val); // get date
                let business_days = 0;
                let d = new Date(val);
                let day = d.getDay();

                if(day <= 3 || day == 6){
                    business_days = 2
                }
                else if (day == 4){
                    business_days = 4
                }
                else if (day == 5){
                    business_days = 3
                }

                let newDate = this.addBusinessDays(dt, business_days);
                let month = '' + (newDate.getMonth() + 1)
                let days = '' + newDate.getDate()
                let years = newDate.getFullYear()

                let formattedNewDate = [years,month,days].join('-');
                this.expectedDepositDate = formattedNewDate
            }
        },
        selectedVendor:{
            handler(val){
                if(val == 275){
                    // console.log('alert')
                    let end_user_payments_list = []
                    this.paymentTypeLists.forEach(e=>{
                        if(e.id == 2 || e.id == 3){
                        }else{
                            end_user_payments_list.push(e)
                        }
                    })
                    this.paymentTypeLists = end_user_payments_list
                }

                if (val !== null && val !== undefined) {
                    this.readOnlyPayee = false;
                    this.$store.commit("VENDOR_PAYMENT_ITEM", []);
                    this.$store.dispatch("getVendorPaymentItems", val);
                }
            }
        },
        selectedChequeVoucher: {
            handler(val) {
                let selectedChequeVoucherOnDropdown = this.GET_CHEQUE_VOUCHERS_SELECTION.filter(chequeVoucher => {
                    return chequeVoucher.id == this.selectedChequeVoucher;
                })[0]
                if (selectedChequeVoucherOnDropdown) {
                    if (selectedChequeVoucherOnDropdown.amount >= parseFloat(this.balance_amounts.replace(/,/g, ''))) {
                        return this.totalAmount = parseFloat(this.balance_amounts.replace(/,/g, ''));
                    }
                    this.totalAmount = selectedChequeVoucherOnDropdown.amount;
                }
                if (!val) {
                    this.totalAmount = null;
                }
                this.insertCVDetails();
            },
        },
        GET_CHEQUE_VOUCHERS_SELECTION: {
            handler(val) {
                if (val) {
                    this.filterCVWithAmounts();
                }
            }
        },
        totalAmount: {
            handler(val) {
                if (parseInt(val) == parseInt(this.balance_amounts)) {
                    this.whtAmount = 0;
                }
            },
            immediate: true
        },
        'selectedBank': {
            handler(newVal) {
                let payload = {
                    url:'show-user-bank-cards',
                    bank_id: newVal,
                    card_type: this.card_type
                }
                this.$store.dispatch('cardBankGetUser',payload).then((response)=>{
                    this.banksUser = response.data
                });
                if (newVal !== null && newVal !== undefined && this.paymentTypeName == 'CHQ') {
                this.$store.dispatch('getBookletsSelection',newVal).then((response)=>{
                    this.booklet_id = response.length > 0?response[0].id:''
                    if(!this.booklet_id){
                        this.cheque_number = ''
                        swal.fire('', 'No Booklet Found', 'warning');
                        return false;
                    }
                    this.readOnlyBooklet = false;
                    this.getNextChequeNumber()
                })
                }
            },
        },
        'card_type': {
            handler(val) {
                if (val) {
                    this.selectedBank = ''
                    this.selected_name = ''
                    this.card_number = ''
                    this.card_date = ''
                }
            },
            immediate: true
        },
    // 'selectedVendor': {
    //   handler(newVal) {
    //     if (newVal !== null && newVal !== undefined) {
    //       this.readOnlyPayee = false;
    //       this.$store.commit("VENDOR_PAYMENT_ITEM", []);
    //       console.log(newVal)
    //       this.$store.dispatch("getVendorPaymentItems", newVal);
    //     }
    //   },
    //   immediate: true,
    // },
        'balanceAmounts':{
            handler(val){
                if (val) {
                    this.balance_amounts = val
                }
            },
            immediate: true
        },
    },
    methods: {
        checkValue($event){
            if(parseFloat($event) > parseFloat(this.balance_amounts)){
                this.totalAmount = parseFloat(this.balance_amounts).toFixed(2)
                swal.fire('Total Amount Exceeds to Balance Amount','','warning');
            };
        },
        clearFields() {
            this.selectedPaymentType = '';
            this.paymentTypeName = '';
            this.remarks = '';
            this.submitOnFlight = false;
            this.chequeName = '';
            this.chequeDate = '';
            this.selectedChequeBank = '';
            this.chequeAccountNumber = '';
            this.chequeDescription = '';
            this.chequeAmount = 0;
            this.totalAmount = 0;
            this.uploadedFiles = null;
            this.selectedVendor = '';
            this.selectedBank = '';
            this.selectedChequeVoucher = null;
            this.otherAdjAmount = 0;
            this.whtAmount = 0;
            this.paymentDate = this.$dayjs().format('YYYY-MM-DD');
            this.chequeNumber = '';
            this.phone_number = '';

            this.accountNumber = '';
            this.accountName = '';
            this.accountDate = '';
            this.accountDescription = '';
            this.bank_id = ''
            this.booklet_id = ''
            this.cheque_date = ''
            this.cheque_number = ''
            this.cvSelection= []
            this.cvData=[]
            this.vendor_payment_item_id=''
            this.gettingChequeNumber=false
            this.readonlyChequeNumbe=true
            this.pulsingBooklet=false
            this.card_number=''
            this.banksUser=[]

            this.$store.commit('vendorPaymentDetailClearDialogAction');
            this.$store.commit('vendorPaymentDetailClearId');

        },
        checkAccess(){
            this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id).then(response=>{
                let val = response;
                this.userAccess.create = false;
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                if(val != "fail"){
                val.forEach((e) => {
                    if (e.actions_code == "create"){
                        this.userAccess.create = true;
                    }
                    else if (e.actions_code == "edit"){
                        this.userAccess.edit = true;
                    }
                    else if (e.actions_code == "view"){
                        this.userAccess.view = true;
                    }
                    else if (e.actions_code == "delete"){
                        this.userAccess.delete = true;
                    }
                });
                }
            })
        },
        vendorPaymentDetailShowAddEditDialog() {
            if(this.fromPaymentVoucher || this.fromJournalVoucher) {
                this.selectedVendor = this.vendorId;
                this.selectedPaymentType = this.paymentTypeIds[0]
                // this.paymentTypeLists.find(e=>e.name == "Cash").disabled =  true
                // this.selectedPaymentType = this.payment_method_id;
                // console.log(this.selectedPaymentType)
            }
            //if(this.payment_voucher_type.text === 'Recurring Bill'){
            //        this.selectedPaymentType = 2
            //        this.paymentTypeName = 'Cheque'
            //        this.recurringBillDisable = true
            //}
            this.$store.commit('vendorPaymentDetailShowAddEditDialog');
        },
        vendorPaymentDetailCloseAddEditDialog() {
            this.hide_upload = false;
            this.$store.commit('vendorPaymentDetailCloseAddEditDialog');
            this.clearFields();
            this.clearCashDenominations();

        },
        async getAllVendor(){
              await this.$store.dispatch('getAllVendors').then((response)=>{ this.vendorList= this.GET_CUSTOMERS_LIST_SELECTION.data })
        },
         getPaymentTypes(){
             this.$store.dispatch('getPaymentTypes').then(async response=>{
                this.paymentTypeLists = await response.data
                // if (this.balanceAmounts == 0) this.paymentTypeLists.find(e=>e.name == "Cheque").disabled = true
                // if(this.payment_voucher_type.text === 'CREDIT BILL'){
                //     this.paymentTypeLists = this.paymentTypeLists.filter(e=>e.name != "Credit\/Debit Card")
                // }
            })
        },
        async getBanks() {
            await this.$store.dispatch('getBanks', {
                type: 'AP',
            }).then(response=>{
                this.banks = this.GET_BANKS
            })
        },
        getCashDenominations() {
            this.$store.dispatch('getCashDenominations').then(response=>{
                let data = this.GET_CASH_DENOMINATIONS;
                let modified = [];

                if(data.length > 0) {
                    data.forEach(x=>{
                        modified.push(
                            Object.assign({
                                quantity: 0,
                                amount: 0
                            }, x)
                        )
                    });
                }

                this.cashDenominations = modified;
            })
        },
        clearCashDenominations() {
            this.cashDenominations.forEach(x=>{
                x.quantity = 0;
                x.amount = 0;
            });

            this.totalAmount = 0;
        },
        getDenominationAmount(data) {
            let cashDenomination = this.cashDenominations.find(x=>x.id == data.id);
            cashDenomination.amount = parseFloat(data.value) * parseInt(data.quantity);

            let totalAmount = this.cashDenominations.map(x=>{
                return parseFloat(x.value) * parseInt(x.quantity);
            });
            let getTotal = this.getTwoDecimals(_.sum(totalAmount));
            let number = parseFloat(getTotal.replace(/,/g, ''));

            if(number > parseFloat(this.balance_amounts)){
                this.totalAmount = 0
                swal.fire('Total Amount Exceeds to Balance Amount','','warning');
                this.clearCashDenominations();
            }
            else{
                this.totalAmount = number;
            }
        },
        uploadedData(data) {
            if(!data) {
                this.uploadedFiles = null;
            }
            this.uploadedFiles = data;
        },
        clearFileUpload(confirm) {
            if(confirm) {
                this.uploadedFiles = null;
            }
            this.$refs.fileUpload.reset();
        },
        getChequeAmount() {
            this.totalAmount = this.chequeAmount;
        },
        submit() {
            let error = false;
            this.submitOnFlight = true;
            if(this.paymentTypeName == 'CHQ') {
                if( !this.accountDate
                    || !this.remarks
                    || !this.selectedBank
                    || !this.cheque_number
                    || !this.vendor_payment_item_id){
                         error = true
                    }
            }
            else if(this.paymentTypeName == 'DEP') {
                 if(!this.accountName
                //  || !this.card_number
                 || !this.accountDate) {
                    error = true
                 }
                // data.append('accountDescription', this.accountDescription);
            }
            // else if(this.paymentTypeName == 'ONL') {
            //      if(this.selected_name
            //      || this.card_number
            //      || this.online_date){
            //         error = true
            //      }
            //     data.append('OnlineDescription', this.OnlineDescription);
            // }
            else if(this.paymentTypeName == 'CRD') {
                if(!this.selected_name
                || !this.card_number
                || !this.selectedBank
                || !this.card_date){
                    error = true
                }
            }
            else if(this.paymentTypeName == 'ONL') {
                if(!this.phone_number){
                    error = true;
                }
            }
            try {
                if(this.uploadedFiles == null && this.hide_upload && this.images.length == 0) {
                swal.fire('', 'Please Upload Payment Images', 'warning').then(x=>{
                    this.submitOnFlight = false;
                });
            } else if(!error) {
                swal.fire({
                title: 'Are you sure you want to Add Payment?',
                text: 'Make sure that all information are correct',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then((result)=>{
                if(result.isConfirmed) {
                    const data = new FormData();

                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }

                    if(!!this.uploadedFiles && this.uploadedFiles.attachments.length > 0) {
                        let files = this.uploadedFiles.attachments;

                        for(let i=0;i < _.compact(files).length;i++) {
                            data.append('files[]', files[i]);
                        }
                    }

                    if(!!this.images && this.images.length > 0) {
                        for(let i=0;i < _.compact(this.images).length;i++) {
                            data.append('images[]', _.compact(this.images)[i]);
                        }
                    }

                    data.append('paymentDate', this.paymentDate);
                    data.append('paymentVoucherId', this.payment_voucher_id ? this.payment_voucher_id : this.$store.state.accounting.vendorPaymentDetailsData.paymentVoucher_id);
                    data.append('vendorId', this.selectedVendor ? this.selectedVendor : this.$store.state.accounting.vendorPaymentDetailsData.vendor_id);
                    data.append('paymentTypeId', this.selectedPaymentType);
                    data.append('paymentTypeName', this.paymentTypeName);
                    data.append('remarks', this.remarks);

                    data.append('bankId', this.selectedBank)

                    if(this.paymentTypeName == 'CHQ') {
                        data.append('cheque_voucher_id', this.selectedChequeVoucher);
                        data.append('chequeAccountNumber', this.accountNumber);
                        data.append('chequeName', this.accountName);
                        data.append('chequeDate', this.accountDate);
                        data.append('chequeDescription', this.accountDescription);
                        data.append('bank_id',this.bank_id);
                        data.append('booklet_id',this.booklet_id)
                        data.append('cheque_number',this.cheque_number);
                        data.append('vendor_payment_item_id',this.vendor_payment_item_id)
                        data.append('booklet_item_id',this.booklet_item_id)
                    }
                    else if(this.paymentTypeName == 'DEP') {
                        data.append('accountNumber', this.card_name);
                        data.append('transaction_number', this.card_number);
                        data.append('accountDate', this.accountDate);
                        // data.append('accountDescription', this.accountDescription);
                    }
                    else if(this.paymentTypeName == 'ONL') {
                        data.append('accountNumber', this.card_number);
                        data.append('accountName', this.card_name);
                        data.append('online_date', this.online_date);
                        data.append('phone_number', this.phone_number);
                        // data.append('OnlineDescription', this.OnlineDescription);
                    }
                    else if(this.paymentTypeName == 'CRD') {
                        data.append('card_name', this.card_name);
                        data.append('card_number', this.card_number);
                        data.append('card_date', this.card_date + '-01');
                        data.append('approval_code', this.approval_code);
                    }

                    data.append('vendor_payment_item_id',this.vendor_payment_item_id);
                    data.append('cashDenominations', JSON.stringify(this.cashDenominations));
                    data.append('totalAmount', this.convertTextToNumber(this.totalAmount));
                    data.append('grossAmount', this.convertTextToNumber(this.grossAmount));
                    data.append('whtAmount', this.convertTextToNumber(this.whtAmount));
                    data.append('otherAdjAmount', this.convertTextToNumber(this.otherAdjAmount));
                    data.append('netAmount', this.convertTextToNumber(this.totalAmount));
                    data.append('fromPaymentVoucher', this.fromPaymentVoucher);
                    data.append('fromJournalVoucher', this.fromJournalVoucher);
                    data.append('expected_deposit_date',this.expectedDepositDate);

                    // if(this.netAmount > this.balanceAmounts){
                    //     let limit = this.netAmount - this.getTwoDecimals(this.balanceAmounts);
                    //     data.append('allocatedAmount', this.convertTextToNumber(limit));
                    // }
                    // else{
                        data.append('allocatedAmount', this.convertTextToNumber(this.totalAmount));
                    // }

                    let payload = {
                        params:data,
                        config: config,
                    }

                    this.$store.dispatch('createVendorPaymentDetail',payload).then(response=>{
                        if(response.data.error) {
                            swal.fire('',response.data.error,'error').then(confirm=>{
                                this.submitOnFlight = false;
                            });
                        } else {
                            this.replaceCheque();
                            this.vendorPaymentDetailCloseAddEditDialog();
                            this.$store.commit('vendorPaymentDetailSuccessTrue');
                            this.uploadedFiles = null;
                            this.$refs.fileUpload.reset();
                            this.closeMainDialog();
                            swal.fire("", response.data.msg, "success").then(ok=>{
                                if(ok) {
                                    this.$store.commit('vendorPaymentDetailSuccessFalse');
                                    this.submitOnFlight = false;
                                }
                            })
                        }
                    });
                }
                this.submitOnFlight = false;
                }).catch((err)=>{
                    console.error(err)
                    this.submitOnFlight = false;
                });
            } else {
                swal.fire('','Please Fill Required Fields','warning').then(confirm=>{
                    this.submitOnFlight = false;
                });
            }
            } catch (error) {
                this.submitOnFlight = false;
            }
        },
        getVendorPaymentDetail() {
            this.$store.dispatch('getVendorPaymentDetail',this.vendorPaymentDetailId).then(response=>{
                this.vendorPaymentDetail = response.data
            });
        },
        update() {
            this.updateOnFlight = true;
            if(!this.$v.$error) {
                swal.fire({
                    title: 'Are you sure you want to Update Payment?',
                    text: 'Make sure that all information are correct',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then((result)=>{
                    if(result.isConfirmed) {
                        const data = new FormData();

                        const config = {
                            headers: {
                                'content-type': 'multipart/form-data'
                            }
                        }

                        if(!!this.uploadedFiles && this.uploadedFiles.attachments.length > 0) {
                            let files = this.uploadedFiles.attachments;

                            for(let i=0;i < _.compact(files).length;i++) {
                                data.append('files[]', files[i]);
                            }
                        }

                        data.append('vendorPaymentDetailId', this.vendorPaymentDetailId);

                        data.append('paymentDate', this.paymentDate);
                        data.append('vendorId', this.selectedVendor);
                        data.append('paymentTypeId', this.selectedPaymentType);
                        data.append('paymentTypeName', this.paymentTypeName);
                        data.append('remarks', this.remarks);

                        data.append('bankId', this.selectedBank)

                        if(this.paymentTypeName == 'CHQ') {
                            data.append('chequeAccountNumber', this.accountNumber);
                            data.append('chequeName', this.accountName);
                            data.append('chequeDate', this.accountDate);
                            data.append('chequeDescription', this.accountDescription);
                        } else if(this.paymentTypeName != 'CHQ' || this.paymentTypeName != 'CSH') {
                            data.append('accountNumber', this.accountNumber);
                            data.append('accountName', this.accountName);
                            data.append('accountDate', this.accountDate);
                            data.append('accountDescription', this.accountDescription);
                        }

                        data.append('cashDenominations', JSON.stringify(this.cashDenominations));
                        data.append('totalAmount', this.convertTextToNumber(this.totalAmount));
                        data.append('grossAmount', this.convertTextToNumber(this.grossAmount));
                        data.append('whtAmount', this.convertTextToNumber(this.whtAmount));
                        data.append('otherAdjAmount', this.convertTextToNumber(this.otherAdjAmount));
                        data.append('netAmount', this.convertTextToNumber(this.netAmount));

                        let payload = {
                            params:data,
                            config:config,
                        }
                        this.$store.dispatch('updateVendorPaymentDetail',payload).then(response=>{
                            if(response.data.error) {
                                swal.fire('',response.data.error,'error').then(confirm=>{
                                    this.updateOnFlight = false;
                                });
                            } else {
                                this.vendorPaymentDetailCloseAddEditDialog();
                                this.$store.commit('vendorPaymentDetailSuccessTrue');
                                this.uploadedFiles = null;
                                this.$refs.fileUpload.reset();
                                swal.fire("", response.data.msg, "success").then(ok=>{
                                    if(ok) {
                                        this.$store.commit('vendorPaymentDetailSuccessFalse');
                                        this.updateOnFlight = false;
                                    }
                                });
                            }
                        });
                    }
                    this.updateOnFlight = false;
                });
            } else {
                swal.fire('','Please Fill Required Fields','warning').then(confirm=>{
                    this.updateOnFlight = false;
                });
            }
        },
        decimalOnly ($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                $event.preventDefault();
            }
        },
        thousandSeprator (amount) {
            if (amount !== '' || amount !== undefined || amount !== 0 || amount !== '0' || amount !== null) {
                return amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            } else {
                return amount;
            }
        },
        getTwoDecimals (amount) {
            var formatted_amount = ''
            if(isNaN(amount)){
                if(amount != null && amount != ''){
                    formatted_amount = parseFloat(amount.replace(/[,]/g,''))
                    formatted_amount = Math.trunc(formatted_amount * Math.pow(10, 3)) / Math.pow(10, 3)
                }
            }else{
                formatted_amount = Math.trunc(amount * Math.pow(10, 3)) / Math.pow(10, 3)
            }

            if (formatted_amount == '') {
                return 0.00
            }
            return this.thousandSeprator(formatted_amount.toFixed(2))
        },
        getTotalAmount() {
            this.totalAmount = this.getTwoDecimals(this.balance_amounts - this.totalAmount);
        },
        // getWhtAmount($event) {
        //     if(parseFloat(this.whtAmount) >= this.convertTextToNumber(this.totalAmount)) {
        //         this.whtAmount = this.getTwoDecimals(this.totalAmount);
        //     } else {
        //         this.whtAmount = this.getTwoDecimals(this.whtAmount);
        //     }
        // },
        getOtherAdjAmount(){
            let balanceOfTotalAndWht = this.convertTextToNumber(this.totalAmount) - this.convertTextToNumber(this.whtAmount);

            if(parseFloat(this.otherAdjAmount) >= balanceOfTotalAndWht) {
                this.otherAdjAmount = this.getTwoDecimals(balanceOfTotalAndWht);
            } else {
                this.otherAdjAmount = this.getTwoDecimals(this.otherAdjAmount);
            }
        },
        convertTextToNumber(amount) {
            if(!!amount) {
                if (amount.length >= 8) {
                    let amt = amount.replaceAll(/[,]/g,'');

                    return parseFloat(amt);
                } else {
                    return parseFloat(amount);
                }
            }
            return parseFloat(0);
        },
        openFilesViewer() {
            if(this.addEdit) {
                this.$store.commit('vendorPaymentDetailCloseAddEditDialog');
            }
        },
        closeFilesViewer() {
            if(this.addEdit) {
                this.$store.commit('vendorPaymentDetailShowAddEditDialog');
            }
        },
        addBusinessDays(originalDate, numDaysToAdd) {
                const Sunday = 0;
                const Saturday = 6;
                let daysRemaining = numDaysToAdd;

                const newDate = originalDate;

                while (daysRemaining > 0) {
                newDate.setDate(newDate.getDate() + 1);
                    if (newDate.getDay() !== 0 && newDate.getDay() !== 6) {
                    // skip sunday & saturday
                    daysRemaining--;
                    }
                }

                return newDate;
        },
        replaceCheque(){
            if(this.origin == "viewPayment"){
                let p = {
                    id:this.vendorPaymentDetailId,
                    mode: 'Replace'
                }
                this.$store.dispatch('updateChequeDate',p).then(response=>{
                    swal.fire("", response.data.msg, "success").then(ok=>{
                        if(ok) {
                            this.$store.commit('vendorPaymentDetailSuccessFalse');
                            this.submitOnFlight = false;
                        }
                    })
                })
            }
        },
        getImages(images) {
            this.images = images;
        },
        insertAccNum() {
            let bankAccNumber = this.banks.find(e=> e.id === this.selectedBank)
            if (bankAccNumber) {
                this.accountNumber = bankAccNumber.account_number
            } else {
                this.accountNumber = ''
            }
        },

        openViewDialog() {
            // this.$store.commit('DIALOG', true);
            this.$store.commit('IS_DIALOG_VISIBLE', true);
            this.$store.state.chequevoucher.lastCreatedChequeVoucher = true;
            // this.$store.state.chequevoucher.autoFill.vendor_id = true;
            this.$store.commit('DISABLE_VENDOR', true);
            this.$store.state.chequevoucher.form.vendor_id = this.selectedVendor;
            this.$store.state.chequevoucher.chequeVoucherViewAction = 'create';
            // this.$store.state.chequevoucher.chequeVoucherViewActionWithTable = true;
        },
        getChequeVouchersSelection() {
            let payload = {
                vendor_id: this.selectedVendor,
            };
            this.$store.dispatch('getChequeVouchersSelection', payload).then(response => {
                this.cvData = response.data
                if (this.selectedChequeVoucher) {
                    let selectedChequeVoucherOnDropdown = this.GET_CHEQUE_VOUCHERS_SELECTION.filter(chequeVoucher => {
                        return chequeVoucher.id == this.selectedChequeVoucher;
                    })[0];
                    if (selectedChequeVoucherOnDropdown) {
                        if (selectedChequeVoucherOnDropdown.amount >= parseFloat(this.balance_amounts.replace(/,/g, '')))
                            return this.totalAmount = parseFloat(this.balance_amounts.replace(/,/g, ''));
                        this.totalAmount = selectedChequeVoucherOnDropdown.amount;
                    }
                }
            });
        },
        filterCVWithAmounts() {
            let amount = this.GET_CHEQUE_VOUCHERS_SELECTION
            amount.forEach(e => {
                e.amount = parseFloat(e.amount)
            });
            let chequeVoucherWithAmounts = amount.filter(v=>v.amount > 0)
            this.cvSelection = chequeVoucherWithAmounts

            return amount;
        },
        async insertCVDetails() {
            if (this.selectedChequeVoucher != null) {
                this.banks = [];
                await this.getBanks();
                let selectedData = this.cvData.find(e=>e.id == this.selectedChequeVoucher)
                let selectedBankData = this.banks.find(e=>e.id == selectedData.bank_id)
                this.selectedBank = selectedBankData.name
                this.accountName = this.banks.find(e=>e.id == selectedBankData.id).name
                this.accountDate =  selectedData.cheque_date
            }
        },
        closeMainDialog() {
            this.$emit('closeMainDialog');
            this.$store.state.accounting.paymentVoucher.addEditPaymentVoucher = true;
        },
        getNextChequeNumber() {
      // console.log('this.form.booklet_id', this.form.booklet_id);
            this.gettingChequeNumber = true;
            this.readonlyChequeNumber = true;
            let payload = {
                booklet_id: this.booklet_id,
            }
            this.$store.dispatch('getNextChequeNumber', payload).then(response => {
                this.cheque_number = response.data.cheque_number;
                this.booklet_item_id = response.data.booklet_item_id;
                this.gettingChequeNumber = false;
                this.readonlyChequeNumber = false;
            });
    },

    selectBooklet() {
      let payload = {
        previous_selected: this.prevoiusSelectedBooklet,
        current_selected: this.booklet_id,
      }
      this.$store.dispatch('selectBooklet', payload).then(response => {
        if (response.data !== this.prevoiusSelectedBooklet || this.booklet_id === null || this.booklet_id === undefined) {
          this.prevoiusSelectedBooklet = response.data;
          if (this.booklet_id !== null) {
            if (!this.pulsingBooklet) {
              this.pulsingBooklet = true;
              setTimeout(() => {
                this.pulseBooklet();
              }, 10000);
            }
            if (this.booklet_id !== null && this.booklet_id !== undefined) this.getNextChequeNumber();
          } else this.pulsingBooklet = false;
          return;
        }
        this.booklet_id = response.data;
        swal.fire({
          icon: "error",
          title: "Oops...",
          text: "The booklet number you selected is being used by another user.",
        });
      });
    },
    },
    // watch:{
    //     'USER_ACCESS':{
    //         handler(val){
    //             this.userAccess.create = false;
    //             this.userAccess.edit = false;
    //             this.userAccess.delete = false;
    //             this.userAccess.view = false;
    //             if(val != "fail"){
    //             val.forEach((e) => {
    //                 if (e.actions_code == "create"){
    //                     this.userAccess.create = true;
    //                 }
    //                 else if (e.actions_code == "edit"){
    //                     this.userAccess.edit = true;
    //                 }
    //                 else if (e.actions_code == "view"){
    //                     this.userAccess.view = true;
    //                 }
    //                 else if (e.actions_code == "delete"){
    //                     this.userAccess.delete = true;
    //                 }
    //             });
    //             }

    //         }
    //     },
    // }
}
</script>

<style lang="scss">
    .hide-text-field-details {
        .v-text-field__details {
            display: none;
        }
    }
</style>
<style scoped>
    ::v-deep .right-align-text input {
    text-align: right;
}
</style>
