<template>
    <div>
        <v-row class="mt-2 mb-3">
            <v-btn @click="showAddEditPaymentTypeDialog()" v-if="userAccess.create">
                <v-icon>mdi-plus</v-icon>Add
            </v-btn>
        </v-row>

        <v-dialog v-model="addEditPaymentTypeDialog" persistent max-width="35%">
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">{{ `${dialogAction == 'edit' ? 'Edit' : 'Add'} Payment Type` }}</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeAddEditPaymentTypeDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider class="mt-0" />

                <div class="px-2 ml-2">
                    <div class="my-2">
                        <div>
                            <v-text-field
                                v-model="code"
                                :rules="rules.default"
                                label="Code: *"
                                dense
                                outlined
                            />
                        </div>
                    </div>

                    <div>
                        <div>
                            <v-text-field
                                v-model="name"
                                :rules="rules.default"
                                label="Name: *"
                                dense
                                outlined
                            />
                        </div>
                    </div>

                    <div>
                        <v-switch v-model="active" label="Active" />
                    </div>
                </div>

                <v-divider class="mt-0" />

                <div style="text-align:center" class="pb-4">
                    <v-btn v-if="dialogAction == 'edit'" :loading="updateOnFlight" @click="update()">Update</v-btn>
                    <v-btn v-else :loading="submitOnFlight" @click="submit()">Submit</v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';

export default {
    data() {
        return {
            code: '',
            name: '',
            paymentTypeDetails: null,
            submitOnFlight: false,
            updateOnFlight: false,
            active: true,
            userAccess: {
                create  : false,
                edit    : false,
                view    : false,
                delete  : false,
            },
            rules: {
                default: [
                v => !!v || 'This is required',
                ],
            },
        }
    },
    watch: {
        paymentTypeId() {
            if(!!this.paymentTypeId) {
                this.getPaymentType();
            }
        },
        paymentTypeDetails() {
            if(!!this.paymentTypeDetails) {
                const { code, name, active } = this.paymentTypeDetails;
                this.code = code;
                this.name = name;
                this.active = active;
            }
        },
        USER_ACCESS:{
            handler(val){
                this.userAccess.create = false;
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                if(val != "fail"){
                val.forEach((e) => {
                    if (e.actions_code == "create"){
                        this.userAccess.create = true;
                    }
                    else if (e.actions_code == "edit"){
                        this.userAccess.edit = true;
                    }
                    else if (e.actions_code == "view"){
                        this.userAccess.view = true;
                    }
                    else if (e.actions_code == "delete"){
                        this.userAccess.delete = true;
                    }
                });
                }

            }
        },
    },
    computed: {
        ...mapGetters([
            'GET_PAYMENT_TYPE',
            'USER_ACCESS'
        ]),
        addEditPaymentTypeDialog() {
            return this.$store.state.accounting.paymentType.addEditPaymentTypeDialog;
        },
        dialogAction() {
            return this.$store.state.accounting.paymentType.dialogAction;
        },
        paymentTypeId() {
            return this.$store.state.accounting.paymentType.paymentTypeId;
        }
    },
    mounted(){
        this.checkAccess()
    },
    methods: {
        checkAccess(){
            let val = this.USER_ACCESS;
            if(val != "fail"){
                val.forEach((e) => {
                    if (e.actions_code == "create"){
                        this.userAccess.create = true;
                    }
                    else if (e.actions_code == "edit"){
                        this.userAccess.edit = true;
                    }
                    else if (e.actions_code == "view"){
                        this.userAccess.view = true;
                    }
                    else if (e.actions_code == "delete"){
                        this.userAccess.delete = true;
                    }
                });
            }
        },
        clearFields() {
            this.code = "";
            this.name = "";
        },
        showAddEditPaymentTypeDialog() {
            this.$store.commit('paymentTypeShowAddEditDialog');
        },
        closeAddEditPaymentTypeDialog() {
            this.$store.commit('paymentTypeCloseAddEditDialog');
            this.$store.commit('paymentTypeClearAction');
            this.$store.commit('paymentTypeClearId');
        },
        getPaymentType() {
            this.$store.dispatch('getPaymentType',this.paymentTypeId).then(response=>{
                this.paymentTypeDetails = this.GET_PAYMENT_TYPE;
            });
        },
        submit() {
            this.submitOnFlight = true;

            if(!this.requiredFields()) {
                swal.fire({
                    title: 'Are you sure you want to Add Payment Type?',
                    text: 'Make sure that all information are correct',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then((result)=>{
                    if(result.isConfirmed) {
                        let payload = {
                            code: this.code,
                            name: this.name,
                            active: this.active
                        }
                        this.$store.dispatch('createPaymentType',payload).then(response=>{
                            if(response.data.error) {
                                swal.fire('',response.data.error,'error');
                                this.submitOnFlight = false;
                            } else {
                                this.closeAddEditPaymentTypeDialog();
                                this.clearFields();
                                this.$store.commit('paymentTypeSuccessAddEditTrue');
                                this.submitOnFlight = false;
                                swal.fire("", response.data.msg, "success").then(ok=>{
                                    if(ok) {
                                        this.$store.commit('paymentTypeSuccessAddEditFalse');
                                    }
                                });
                            }
                        });
                    }
                });
            }
        },
        update() {
            this.updateOnFlight = true;

            if(!this.requiredFields()) {
                swal.fire({
                    title: 'Are you sure you want to Update Payment Type?',
                    text: 'Make sure that all information are correct',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then((result)=>{
                    if(result.isConfirmed) {
                        let payload = {
                            paymentTypeId: this.paymentTypeId,
                            code: this.code,
                            name: this.name,
                            active: this.active
                        }

                        this.$store.dispatch('updatePaymentType',payload).then(response=>{
                            if(response.data.error) {
                                swal.fire('',response.data.error,'error');
                                this.updateOnFlight = false;
                            } else {
                                this.closeAddEditPaymentTypeDialog();
                                this.clearFields();
                                this.$store.commit('paymentTypeSuccessAddEditTrue');
                                this.updateOnFlight = false;
                                swal.fire("", response.data.msg, "success").then(ok=>{
                                    if(ok) {
                                        this.$store.commit('paymentTypeSuccessAddEditFalse');
                                    }
                                });
                            }
                        });
                    }
                });
            }
        },
        requiredFields() {
            let warning = '';
            if (!this.name) warning += 'Please ensure that the empty field for the name is filled in.<br>';
            if (!this.code) warning += 'Please ensure that the empty field for the code is filled in.<br>';

            if (warning !== '') return swal.fire({
                title: 'Please Fill out Information:',
                html: warning,
                icon: "warning",
            }).then(confirm=>{
                this.submitOnFlight = false;
                this.updateOnFlight = false;
            });
        },
    }
}
</script>

<style>

</style>
