<template>
    <div class="tabs-body-color">
        <v-container fluid class="pa-0 ">
            <v-row class="pl-2" v-if="userAccess.create">
                <!-- <v-col cols="6" class="d-flex no-block align-items-center mt-5">
                    <div class="btn-toolbar pl-0">
                        <v-btn class="ml-1 mb-2" @click="AddDialog()"> <v-icon>mdi-plus</v-icon> Add </v-btn>
                    </div>
                </v-col> -->
                <v-spacer/>
                <v-col cols="6" class="d-flex no-block align-items-center mt-5 justify-content-end">
                    <div class="btn-toolbar pl-0">
                        <v-btn class="mr-4 mb-2 " @click="exportExcel(1)">Export</v-btn>
                    </div>
                </v-col>
            </v-row>
                <v-row>
                    <AdminWarehouseStocksTableComponent></AdminWarehouseStocksTableComponent>
                </v-row>
        </v-container>
    </div>
</template>
<script>
import AdminWarehouseStocksTableComponent from "@/views/main/modules/Admin/AdminWarehouseStocks/AdminWarehouseStocksTableComponent.vue";
import { mapGetters } from "vuex";
export default {
    components: {
        AdminWarehouseStocksTableComponent,
    },
    data() {
        return {
            userAccess: {
            create: false,
            },
        };
    },
    computed: {
    ...mapGetters(["USER_ACCESS"]),
    },
    mounted() {},
    methods: {
        AddDialog() {
            this.$store.commit("ADMIN_ITEMS_LIST_SECTION", true);
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", "Submit");
            // this.$store.dispatch("getadminPurchaseOrderNum");
            // this.$store.dispatch("getAdminWarehouseCodeDropdown");
        },
        exportExcel() {
            this.$store.commit("ADMIN_EXPORT", true);
        },
    },
    watch: {
        USER_ACCESS: {
            handler(val) {
            if (val != "fail") {
                this.userAccess.create = false;
                val.forEach((e) => {
                if (e.actions_code == "create") {
                    this.userAccess.create = true;
                }
                });
            }
            },
        },
    },
};
</script>