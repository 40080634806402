<template>
    <div class="px-3 pb-4" style="width:100%">
        <v-card class="pa-2" style="border-radius:0px" flat>
            <v-card-title>
                <v-layout>
                    <v-spacer />
                    <v-flex lg3>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                            class="pa-0 ma-0"
                            clearable
                        />
                    </v-flex>
                    <!-- <v-flex lg1>
                        <export-repair-forms />
                    </v-flex> -->
                </v-layout>
            </v-card-title>
            <v-card-text>
                <v-data-table
                    :headers="repairFormHeaders"
                    :items="rfLists"
                    :loading="loading"
                    :no-data-text="noDataText"
                    :search="search"
                >
                <template v-slot:item.date="{ item }">
                    <td>{{ date(item) }}</td>
                </template>

                <template v-slot:item.created_at="{ item }">
                    <td>{{ agingDate(item.created_at) }}</td>
                </template>

                <template v-slot:item.action="{ item }">
                    <td>
                        <span class="text-nonwrap">
                            <v-btn v-if="!rfManager" text icon color="orange">
                                <v-icon class="btn-action" small @click="showRepairFormDetails(item)">mdi-eye</v-icon>
                            </v-btn>
                            <v-btn text icon color="blue">
                                <v-icon class="btn-action" small @click="editRepairFormDetails(item)">mdi-pencil</v-icon>
                            </v-btn>
                            <!-- <v-btn v-if="!item.sc_rrf_id" text icon color="red">
                                <v-icon class="btn-action" small @click="deleteRepairForm(item)">fas fa-trash-alt</v-icon>
                            </v-btn> -->
                        </span>
                    </td>
                </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <!-- Repair Form Details Dialog -->
        <v-dialog v-model="rfDetailsDialog" persistent max-width="75%">
            <v-card class="pb-1">
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Repair Form Details</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeRfDetailsDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider class="mt-0" />
                <div class="ma-4">
                    <v-layout row class="mb-2">
                        <v-spacer />
                        <v-flex lg5 class="pl-2 pt-4">
                            <v-card class="pa-2 mr-2">
                                <div>
                                    <span style="color:gray;font-weight:bold">RF#: </span>
                                    <span>{{ rfNumber }}</span>
                                </div>
                                <div>
                                    <span style="color:gray;font-weight:bold">RD#: </span>
                                    <span>{{ rrNumber }}</span>
                                </div>
                                <div>
                                    <span style="color:gray;font-weight:bold">Issued: </span>
                                    <span>{{ rrDate }}</span>
                                </div>
                            </v-card>
                        </v-flex>

                        <v-flex lg1 class="mr-2" @click="showQrDialog()" style="cursor:pointer">
                            <VueQrcode :value="!!rfNumber ? rfNumber : 'rfNumber'" ref="qr" id="qr" :options="{ width: 120, errorCorrectionLevel: 'H' }"></VueQrcode>
                        </v-flex>
                    </v-layout>
                    <v-layout row class="mx-1">
                        <v-flex lg6 class="pr-2">
                            <v-card class="pa-4">
                                <div class="pb-4">
                                    Customer Information
                                </div>
                                <v-layout row class="mx-4">
                                    <v-flex lg4>
                                        <label style="color:gray">Company Name:</label>
                                    </v-flex>
                                    <v-flex lg8>
                                        {{ companyName }}
                                    </v-flex>
                                </v-layout>
                                <v-layout row class="mx-4">
                                    <v-flex lg4>
                                        <label style="color:gray">Company Number:</label>
                                    </v-flex>
                                    <v-flex lg8>
                                        {{ companyContactNumber }}
                                    </v-flex>
                                </v-layout>
                                <v-layout row class="mx-4">
                                    <v-flex lg4>
                                        <label style="color:gray">Customer Number:</label>
                                    </v-flex>
                                    <v-flex lg8>
                                        {{ contactNumber }}
                                    </v-flex>
                                </v-layout>
                                <v-layout row class="mx-4">
                                    <v-flex lg4>
                                        <label style="color:gray">Customer Name:</label>
                                    </v-flex>
                                    <v-flex lg8>
                                        {{ customerName }}
                                    </v-flex>
                                </v-layout>
                                <v-layout row class="mx-4">
                                    <v-flex lg4>
                                        <label style="color:gray">Address:</label>
                                    </v-flex>
                                    <v-flex lg8>
                                        {{ address }}
                                    </v-flex>
                                </v-layout>
                                <v-layout row class="mx-4">
                                    <v-flex lg4>
                                        <label style="color:gray">Email:</label>
                                    </v-flex>
                                    <v-flex lg8>
                                        {{ email }}
                                    </v-flex>
                                </v-layout>
                            </v-card>
                        </v-flex>

                        <v-flex lg6 class="mb-4">
                            <v-card class="pa-4">
                                <div class="pb-4">
                                    Item Information
                                </div>
                                <v-layout row class="mx-4">
                                    <v-flex lg4>
                                        <label style="color:gray">Warranty Type:</label>
                                    </v-flex>
                                    <v-flex lg8>
                                        {{ itemWarrantyType }}
                                    </v-flex>
                                </v-layout>
                                <v-layout row class="mx-4">
                                    <v-flex lg4>
                                        <label style="color:gray">Warranty Document:</label>
                                    </v-flex>
                                    <v-flex lg8>
                                        {{ itemWarrantyDocument }}
                                    </v-flex>
                                </v-layout>
                                <v-layout row class="mx-4">
                                    <v-flex lg4>
                                        <label style="color:gray">Purchase Date:</label>
                                    </v-flex>
                                    <v-flex lg8>
                                        {{ itemPurchaseDate }}
                                    </v-flex>
                                </v-layout>
                                <v-layout row class="mx-4">
                                    <v-flex lg4>
                                        <label style="color:gray">Item Model:</label>
                                    </v-flex>
                                    <v-flex lg8>
                                        {{ itemModel }}
                                    </v-flex>
                                </v-layout>
                                <v-layout row class="mx-4">
                                    <v-flex lg4>
                                        <label style="color:gray">Item Name:</label>
                                    </v-flex>
                                    <v-flex lg8>
                                        {{ itemProductName }}
                                    </v-flex>
                                </v-layout>
                                <v-layout row class="mx-4">
                                    <v-flex lg4>
                                        <label style="color:gray">Serial No:</label>
                                    </v-flex>
                                    <v-flex lg8>
                                        {{ itemSerial }}
                                    </v-flex>
                                </v-layout>

                                <v-layout row class="mx-4">
                                    <v-flex lg4>
                                        <label style="color:gray">Accessories:</label>
                                    </v-flex>
                                    <v-flex lg8>
                                        {{ itemAccessories }}
                                    </v-flex>
                                </v-layout>
                                <v-layout row class="mx-4">
                                    <v-flex lg4>
                                        <label style="color:gray">Other Accessory:</label>
                                    </v-flex>
                                    <v-flex lg8>
                                        {{ itemOtherAccessory }}
                                    </v-flex>
                                </v-layout>
                                <v-layout row class="mx-4">
                                    <v-flex lg4>
                                        <label style="color:gray">Unit Condition:</label>
                                    </v-flex>
                                    <v-flex lg8>
                                        {{ itemUnitCondition }}
                                    </v-flex>
                                </v-layout>
                                <v-layout row class="mx-4">
                                    <v-flex lg4>
                                        <label style="color:gray">Other Unit Condition:</label>
                                    </v-flex>
                                    <v-flex lg8>
                                        {{ itemOtherUnitCondition }}
                                    </v-flex>
                                </v-layout>
                                <v-layout row class="mx-4">
                                    <v-flex lg4>
                                        <label style="color:gray">Complaint / Symptom:</label>
                                    </v-flex>
                                    <v-flex lg8>
                                        {{ itemDefectDescription }}
                                    </v-flex>
                                </v-layout>
                                <v-layout row class="mx-4">
                                    <v-flex lg4>
                                        <label style="color:gray">Remarks:</label>
                                    </v-flex>
                                    <v-flex lg8>
                                        {{ itemDefectRemarks }}
                                    </v-flex>
                                </v-layout>
                            </v-card>
                        </v-flex>
                    </v-layout>
                </div>
                <v-divider />
                <div class="ma-4">
                    <div class="py-3">
                        <v-layout row>
                            <v-flex lg6>
                                <div style="text-align:center">
                                    <!-- <v-btn @click="showOverride()" :loading="loading">Print Qrcode</v-btn> -->
                                    <v-tooltip bottom color="#f69f1a">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                :loading="loading"
                                                @click="showOverride()"
                                                v-bind="attrs"
                                                v-on="on"
                                                color="red"
                                                fab
                                                small
                                                icon
                                                text
                                            >
                                                <v-icon>mdi-qrcode</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Print Qrcode</span>
                                    </v-tooltip>
                                </div>
                            </v-flex>
                            <v-flex lg6>
                                <div style="text-align:center">
                                    <!-- <v-btn :disabled="!printedQrcode" @click="printRepairForm()" :loading="loading">Print Repair Form</v-btn> -->
                                    <v-tooltip bottom color="#f69f1a">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                :disabled="!printedQrcode"
                                                :loading="loading"
                                                @click="printRepairForm()"
                                                v-bind="attrs"
                                                v-on="on"
                                                color="red"
                                                fab
                                                small
                                                icon
                                                text
                                            >
                                                <v-icon>mdi-cloud-download</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Print Repair Form</span>
                                    </v-tooltip>
                                </div>
                            </v-flex>
                        </v-layout>
                    </div>

                            <!-- PRINT OUT TABLE -->
                        <v-container fluid style="display:none">
                            <div>
                                <table id="rf_form">
                                    <tbody>
                                        <tr>
                                            <td colspan="5">Company: {{ companyName }} </td>
                                            <td colspan="3">Contact No: {{ this.companyName == 'END USER' ? contactNumber : companyContactNumber }} </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3">Item Model: {{ itemModel }} </td>
                                            <td colspan="2">Serial No: {{ itemSerial }} </td>
                                            <td colspan="3">Category: {{ itemCategory }} </td>
                                        </tr>
                                        <tr>
                                            <td colspan="8">Item Name: {{ itemProductName }} </td>
                                        </tr>
                                        <tr v-if="this.companyName == 'END USER'">
                                            <td colspan="8">Customer Name : {{ customerName }}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="4">Warranty: </td>
                                            <td colspan="4">Purchase Date: {{ itemPurchaseDate }} </td>
                                        </tr>
                                        <tr>
                                            <td colspan="8">Complaint/Symptom: {{ itemDefectDescription }} </td>
                                        </tr>
                                        <tr>
                                            <td colspan="8">Remarks: {{ itemDefectRemarks }} </td>
                                        </tr>
                                        <tr>
                                            <td colspan="8">Description: {{ itemOtherUnitCondition }} </td>
                                        </tr>
                                        <tr>
                                            <td colspan="8">Included Items: {{ itemAccessories }} </td>
                                        </tr>
                                        <tr>
                                            <td colspan="8">Inside the box: {{ itemOtherAccessory }} </td>
                                        </tr>
                                        <tr>
                                            <td colspan="5">Assigned Technician: {{ assignedTechnician }} </td>
                                            <td colspan="3">Begin Repair Date: </td>
                                        </tr>
                                        <tr>
                                            <td colspan="8">Symptoms as Tested: </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div style="font-size:12px">
                                    Estimate Information
                                </div>
                                <table id="rf_estimation_form">
                                    <tbody>
                                        <tr>
                                            <td colspan="5">Recommended part/s for Replacement: </td>
                                            <td colspan="3">Diagnosis Date: </td>
                                        </tr>
                                        <tr>
                                            <td colspan="8"></td>
                                        </tr>
                                        <tr>
                                            <td colspan="8"></td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div style="font-size:12px">
                                    QA Assessment Information
                                </div>
                                <table id="qa_assessment_info">
                                    <tbody>
                                        <tr>
                                            <td colspan="4">Technician Name: {{ assignedTechnician }} </td>
                                            <td colspan="4">Date:  </td>
                                        </tr>
                                        <tr>
                                            <td colspan="7">Remarks: {{ itemDefectRemarks }} </td>
                                            <td colspan="1">Reject Count: </td>
                                        </tr>
                                        <!-- <tr>
                                            <td colspan="8">Check list: </td>
                                        </tr> -->
                                    </tbody>
                                </table>

                                <div style="font-size:12px">
                                    Log Information
                                </div>
                                <table id="log_info">
                                    <tbody>
                                        <tr>
                                            <td colspan="8">Date: {{ itemPurchaseDate }} </td>
                                        </tr>
                                        <tr>
                                            <td colspan="8">Remarks: </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </v-container>


                    <!-- For Printing Details
                    <v-container fluid style="display:none">
                        <div id="div_rf_logo" >
                            <div style="font-size: 12px; line-height: 1.9">
                                <p>
                                    <span style="font-size: 18px; font-weight: bold;">
                                        INGCOPH Traders Inc.
                                    </span>
                                    <br>2 D Arellano St. Bagong Barrio, Caloocan City
                                    <br>Cell No.:
                                    <br>Tel. No.:
                                    <br>Service Mobile / Viber No.: +63 917 625 2286
                                    <br>Spare Parts Mobile / Viber No.:
                                </p>
                            </div>
                        </div>
                        <div id="div_rf_num" style="font-size: 12px; line-height: 1.9">
                            <div style="border-top: 1px dashed #CFCFCF; padding: 2px; padding-bottom: -4px; text-align: center; border-bottom: none;">&nbsp;</div>
                            <p>
                                <span style="font-size: 18px; font-weight: bold;">Repair Form</span>
                                <br>
                                <b>RF#: {{ rfNumber }}</b>
                                <br>
                                <b>Pullout#: {{ pulloutNumber }}, RD#: {{ rrNumber }}</b>
                                <br>
                                <b>Issued: {{ rrDate }}</b>
                            </p>
                        </div>

                        <div>
                            <table id="rf_details">
                                <tbody>
                                    <tr>
                                        <td colspan="3">Item Model: {{ itemModel }}</td>
                                        <td colspan="2">Serial No: {{ itemSerial }}</td>
                                        <td colspan="3">Category: {{ itemCategory }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="8">Item Name: {{ itemProductName }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="3">Warranty: {{ itemWarrantyType }}</td>
                                        <td colspan="2">Purchase Date: {{ itemPurchaseDate }}</td>
                                        <td colspan="3">Document: {{ itemWarrantyDocument }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="5">Company: {{ companyName }}</td>
                                        <td colspan="3">Contact No: {{ companyContactNumber }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="5">Customer: {{ customerName }}</td>
                                        <td colspan="3">Contact No: {{ contactNumber }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="8">Address: {{ address }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="8">Complaint/Symptom: {{ itemDefectDescription }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="8">Remarks: {{ itemDefectRemarks }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="8">Physical Condition: {{ itemUnitCondition }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="8">Description: {{ itemOtherUnitCondition }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="8">Included Items: {{ itemAccessories }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="8">Details: {{ itemOtherAccessory }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="5">Assigned Technician: {{ assignedTechnician }}</td>
                                        <td colspan="3">Begin Repair Date: {{  }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="8">Symptoms as Tested: {{  }}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <div id="rf_estimate_title" style="font-size:12px">
                                Estimate Information
                            </div>
                            <table id="rf_estimation">
                                <tbody>
                                   <tr>
                                       <td colspan="5">Recommended part/s for Replacement:</td>
                                       <td colspan="3">Diagnosis Date:</td>
                                   </tr>
                                   <tr>
                                       <td colspan="8"></td>
                                   </tr>
                                   <tr>
                                       <td colspan="8"></td>
                                   </tr>
                                   <tr>
                                        <td colspan="1">Quotation No: {{  }}</td>
                                        <td colspan="1">Quotation Date: {{  }}</td>
                                        <td colspan="1">Total Amount: {{  }}</td>
                                        <td colspan="1">Approve Date: {{  }}</td>
                                        <td colspan="1">Coordinator: {{  }}</td>
                                        <td colspan="3">Remarks: {{  }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="3"></td>
                                    </tr>
                                    <tr>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="3"></td>
                                    </tr>
                                </tbody>
                            </table>

                            <div id="rf_unit_movements_title" style="font-size:12px">
                                Unit Status
                            </div>
                            <table id="rf_unit_movements">
                                <tbody>
                                   <tr>
                                        <td colspan="1">Status: {{  }}</td>
                                        <td colspan="1">WFR/WFC: {{  }}</td>
                                        <td colspan="1">FCA: {{  }}</td>
                                        <td colspan="1">PFR: {{  }}</td>
                                        <td colspan="1">RET: {{  }}</td>
                                        <td colspan="1">WFP: {{  }}</td>
                                        <td colspan="1">PIS: {{  }}</td>
                                        <td colspan="1">RFD: {{  }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="1">Date</td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                    </tr>
                                    <tr>
                                        <td colspan="1">Employee</td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                    </tr>
                                </tbody>
                            </table>

                            <div id="rf_repair_info_title" style="font-size:12px">
                                Repair Information
                            </div>
                            <table id="rf_repair_info">
                                <tbody>
                                   <tr>
                                        <td colspan="5">Job Done: {{  }}</td>
                                        <td colspan="1">Section: {{  }}</td>
                                        <td colspan="1">Defect: {{  }}</td>
                                        <td colspan="1">Repair: {{  }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="5"></td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                        <td colspan="1"></td>
                                    </tr>
                                    <tr>
                                        <td colspan="5">QA Technician: </td>
                                        <td colspan="3">Repair Completion Date: </td>
                                    </tr>
                                </tbody>
                            </table>

                            <div id="rf_releasing_info_title" style="font-size:12px">
                                Releasing Information
                            </div>
                            <table id="rf_releasing_info">
                                <tbody>
                                    <tr>
                                        <td colspan="3">Delivery Date: </td>
                                        <td colspan="2">Released By: </td>
                                        <td colspan="3">Delivery Mode: </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3">Collection Date: </td>
                                        <td colspan="2">Confirmed By: </td>
                                        <td colspan="3"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </v-container> -->
                </div>
            </v-card>
        </v-dialog>

        <EditRepairFormDialogComponentVue
            :showEditDialog="showEditDialog"
            :rf="rf"
            @closeEditDialog="closeEditDialog"
        />

        <v-dialog v-model="showOverridePrint" persistent max-width="30%" scrollable>
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Confirm Override Print</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeOverrideDialog(); override_username = ''; override_user_password = '';">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text>
                    <div class="row">
                        <!-- <div class="col-sm-12 col-md-6">
                            <label for="override_username" class="float-left control-label col-form-label">Username</label>
                            <input type="text" class="form-control" id="override_username" placeholder="Username" v-model="override_username" autocomplete="new-password">
                            <span v-for="errors in errors.override_username" class="text-warning" v-bind:key="errors">{{errors}}</span>
                        </div> -->
                        <div class="col-sm-12 col-md-12">
                            <label for="override_user_password" class="float-left control-label col-form-label">Password</label>
                            <input type="password" class="form-control" id="override_user_password" placeholder="Password" v-model="overrideUserPassword" autocomplete="new-password">
                            <!-- <span v-for="errors in errors.override_user_password" class="text-warning" v-bind:key="errors">{{errors}}</span> -->
                        </div>
                    </div>
                    <div class="border-top">
                        <div class="card-body py-4 d-flex justify-content-center">
                            <v-btn  v-on:click="confirmedOverridePrint()">Confirm</v-btn>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <DownloadQrcodeDialogVue :rf="rfDetails" />

        <ConfirmOverrideComponentVue
            :approve_override_dialog="override.override_dialog"
            :departments_allowed="override.override_departments_allowed"
            :heading="'Override'"
            @closeConfirmOverride="closeConfirmOverride"
        ></ConfirmOverrideComponentVue>
    </div>
</template>

<script>
import EditRepairFormDialogComponentVue from './EditRepairFormDialogComponent.vue';
import ExportRepairFormsComponentVue from './ExportRepairFormsComponent.vue';
import DownloadQrcodeDialogVue from './Views/RepairFormViewComponent/DownloadQrcodeDialog.vue';
import VueQrcode from "qrcode.vue";
import ServiceFunctionsComponentVue from '../../Shared/ServiceFunctionsComponent.vue';
import swal from 'sweetalert2';
import { mapGetters } from 'vuex';
import ConfirmOverrideComponentVue from '../../Utils/ConfirmOverrideComponent.vue';

export default {
    mixins: [ServiceFunctionsComponentVue],
    data() {
        return {
            repairFormHeaders: [
                { text: 'RF#', value: 'rf_number' },
                { text: 'RD#', value: 'sc_receiving_report.rr_number' },
                { text: 'Technician', value: 'assigned_technician' },
                { text: 'Item Model', value: 'sc_repair_form_item.model' },
                { text: 'Item Name', value: 'sc_repair_form_item.product_name' },
                { text: 'Serial No', value: 'sc_repair_form_item.serial' },
                { text: 'Complaint/Symptom', value: 'sc_repair_form_item.defect_description' },
                { text: 'Warranty Type', value: 'sc_repair_form_item.warranty_type' },
                { text: 'Date', value: 'date' },
                { text: 'Days', value: 'created_at' },
                { text: 'Action', value: 'action' },
            ],
            rfLists: [],
            loading: true,
            noDataText: '',
            rfStatus: 'TA',
            rfDetailsDialog: false,
            rfDetails: null,
            rfDate: '',
            rrDate: '',
            rfNumber: '',
            rrNumber: '',
            pulloutNumber: '',
            companyName: '',
            companyContactNumber: '',
            customerName: '',
            address: '',
            contactNumber: '',
            email: '',
            itemModel: '',
            itemProductName: '',
            itemSerial: '',
            itemCategory: '',
            itemDefectDescription: '',
            itemDefectRemarks: '',
            itemAccessories: '',
            itemOtherAccessory: '',
            itemWarrantyType: '',
            itemWarrantyDocument: '',
            itemUnitCondition: '',
            itemOtherUnitCondition: '',
            itemStatus: '',
            itemPurchaseDate: '',
            deliveredBy: '',
            receivedBy: '',
            warehouse: '',
            showEditDialog: false,
            rf: null,
            customerId: '',
            search: '',
            showOverridePrint: false,
            overrideUserPassword: '',
            confirmOverride: false,
            rfId: '',
            assignedTechnician: '',
            printedQrcode: false,
            rfWithFiles: false,
            itemSymptomUponTested: "",
            itemJobDone: "",
            override: {
                override_dialog: false,
                errors: [],
                override_departments_allowed: ['SM', 'EX', 'IT'],
                override_authenticated: false,
            },
            repairForms: '',
        }
    },
    async mounted() {
        await this.getAllRfs();
        this.$store.dispatch('getLists')
    },
    props: ['state', 'rfManager'],
    watch: {
        rfLists() {
            if(this.rfLists.length > 0) {
                this.loading = false;
            } else {
                this.loading = false;
                this.noDataText = "No data for Repair Forms";
            }
        },
        rfDetails() {
            this.loading = true
            if(!!this.rfDetails) {
                let rr = this.rfDetails.sc_receiving_report;
                this.rfNumber = this.rfDetails.rf_number;
                this.rrNumber = rr.rr_number;
                this.companyName = rr.customer.company_name;
                this.rrDate = this.$dayjs(rr.created_at).format('YYYY-MM-DD, hh:mm:ss a');
                this.rfDate = this.$dayjs(this.rfDetails.created_at).format('YYYY-MM-DD');
                this.customerId = rr.customer_id;
                // this.rejectCount = this.rfDetails.item_rejected_counter;

                if(!!this.rfDetails.assigned_technician) {
                    this.assignedTechnician = this.rfDetails.assigned_technician;
                }

                if(!!this.rfDetails.printed_qrcode) {
                    this.printedQrcode = true;
                } else {
                    this.printedQrcode = false;
                }

                if(!!rr.pullout_number) {
                    this.pulloutNumber = rr.pullout_number;
                }

                let contact = [];
                let fullName = [];

                if(!!this.rfDetails.sc_repair_form_item) {
                    this.itemModel = this.rfDetails.sc_repair_form_item.model;
                    this.itemProductName = this.rfDetails.sc_repair_form_item.product_name;
                    this.itemCategory = this.rfDetails.sc_repair_form_item.category;
                    this.itemDefectDescription = this.rfDetails.sc_repair_form_item.defect_description;
                    this.itemDefectRemarks = this.rfDetails.sc_repair_form_item.defect_remarks;
                    this.itemSerial = this.rfDetails.sc_repair_form_item.serial;
                    this.itemAccessories = this.rfDetails.sc_repair_form_item.accessories;
                    this.itemOtherAccessory = this.rfDetails.sc_repair_form_item.other_accessory;
                    this.itemWarrantyType = this.rfDetails.sc_repair_form_item.warranty_type;
                    this.itemWarranty = this.rfDetails.sc_repair_form_item.warranty;
                    this.itemWarrantyDocument = this.rfDetails.sc_repair_form_item.warranty;
                    this.itemUnitCondition = this.rfDetails.sc_repair_form_item.unit_condition;
                    this.itemOtherUnitCondition = this.rfDetails.sc_repair_form_item.other_unit_condition;
                    this.itemStatus = this.rfDetails.sc_repair_form_item.status;
                    this.itemPurchaseDate = this.rfDetails.sc_repair_form_item.purchase_date;
                    this.itemSymptomUponTested = this.rfDetails.sc_repair_form_item.symptom_upon_tested;
                    this.itemJobDone = this.rfDetails.sc_repair_form_item.job_done;
                }

                if(rr.customer_id == 275) {
                    if(!!rr.sc_customer) {
                        if(!!rr.sc_customer.first_name) {
                            fullName.push(rr.sc_customer.first_name);
                        } if(!!rr.sc_customer.middle_name) {
                            fullName.push(rr.sc_customer.middle_name);
                        } if(!!rr.sc_customer.last_name) {
                            fullName.push(rr.sc_customer.last_name);
                        }

                        this.contactNumber = rr.sc_customer.contact_number;
                        this.address = rr.sc_customer.address;
                        this.email = rr.sc_customer.email;
                    }

                    this.customerName = fullName.toString().replaceAll(',', ' ');

                } else {
                    this.companyContactNumber = contact.toString().replaceAll(',',' ');
                    this.address = JSON.parse(rr.customer.shipping_addresses)[0].address;
                    if(!!rr.customer.mobile_1) {
                         contact.push(rr.customer.mobile_1);
                    }
                    if(!!rr.customer.mobile_2) {
                        contact.push(rr.customer.mobile_2);
                    }
                    if(!!rr.customer.mobile_3) {
                        contact.push(rr.customer.mobile_3);
                    }
                    if(!!rr.customer.tel_1) {
                        contact.push(rr.customer.tel_1);
                    }
                    if(!!rr.customer.tel_2) {
                        contact.push(rr.customer.tel_2);
                    }
                    if(!!rr.customer.tel_3) {
                        contact.push(rr.customer.tel_3);
                    }
                    // if(!!rr.customer.first_name) {
                    //      fullName.push(rr.customer.first_name);
                    // }
                    // if(!!rr.customer.middle_name) {
                    //     fullName.push(rr.customer.middle_name);
                    // }
                    // if(!!rr.customer.last_name) {
                    //     fullName.push(rr.customer.last_name);
                    // }
                    // this.customerName = fullName.toString().replaceAll(',', ' ');
                    this.companyContactNumber = contact.toString().replaceAll(',', ' / ');
                }
            }
            this.loading = false
        },
        state() {
            this.getAllRfs();
        },
    },
    components: {
        EditRepairFormDialogComponentVue,
        ExportRepairFormsComponentVue,
        VueQrcode,
        DownloadQrcodeDialogVue,
        ConfirmOverrideComponentVue,
    },
    computed:{
        ...mapGetters([
            'GET_ALL_RFS',
            'GET_RF_DETAILS',
            'GET_CONFIRM_OVERRIDE_PRINT_PL',
            'GET_CHECK_LISTS_SELECTION',
        ])
    },
    methods: {
        getAllRfs() {
            let payload = {
                rfManager: this.rfManager
            }
            this.$store.dispatch('getAllRfs',payload).then(response => {

                this.rfLists = this.GET_ALL_RFS;
            });
        },
        rfStatusAction(status) {
            this.rfStatus = status;
        },
        date(item) {
            if(!!item) {
                return this.$dayjs(item.sc_receiving_report.created_at).format('YYYY-MM-DD, hh:mm:ss');
            }
            return '';
        },
        clearRfDetailsField() {
            this.rfDetails = "";
            this.rfDate = "";
            this.rrDate = "";
            this.rfNumber = "";
            this.rrNumber = "";
            this.companyName = "";
            this.companyContactNumber = "";
            this.customerName = "";
            this.customerContactNumber = "";
            this.address = "";
            this.contactNumber = "";
            this.email = "";
            this.itemModel = "";
            this.itemProductName = "";
            this.itemSerial = "";
            this.itemDefectDescription = "";
            this.deliveredBy = "";
            this.receivedBy = "";
            this.warehouse = "";
        },
        showRepairFormDetails(rf) {
            this.rfDetailsDialog = true;
            this.rf = rf
            this.getRfDetails(rf.id);
        },
        closeRfDetailsDialog() {
            this.rfDetailsDialog = false;
            this.clearRfDetailsField();
        },
        async getRfDetails(id){
            this.loading = true
            await this.$store.dispatch('getRfDetails',id).then(response => {
                this.rfDetails = this.GET_RF_DETAILS;
                this.rfId = this.rfDetails.id;
            });
            this.loading = false
        },
        editRepairFormDetails(item) {
            if(!!item) {
                this.rf = item;
                this.showEditDialog = true;
            }
        },
        deleteRepairForm(item) {

        },
        closeEditDialog() {
            this.rf = null;
            this.showEditDialog = false;
            this.getAllRfs();
        },
        printRepairForm(){
            if(this.itemWarrantyType == 'In-Warranty') {
                if(this.rfDetails.sc_repair_form_files.length == 0 && this.rfDetails.backjob == 0) {
                    swal.fire('','Please Upload Warranty Document','warning')
                } else {
                    this.submiData();
                }
            } else {
                this.submiData();
            }
        },
        submiData() {

            let doc = new this.$jspdf("p", "mm", "letter");
            doc.setFont(undefined, 'bold').setFontSize(13).text(175, 13, "Repair Form")
            doc.setFont(undefined, 'bold').setFontSize(13).text(175,13,"\nRF#: "+this.rfNumber)
            // doc.setFont(undefined, 'bold').setFontSize(13).text(135,7.8,"\nIssued#:"+this.rrNumber)
            // doc.setFont(undefined, 'bold').setFontSize(13).text(63,7.8,"\nRD#:"+this.rrDate)
            doc.setFont(undefined, 'bold').setFontSize(13).text(93,7.8,"\nPullout#: "+this.pulloutNumber + "\nIssued#: "+this.rrDate)
            doc.setFont(undefined, "bold").setFontSize(13).text(6, 13, "INGCOPH Traders Inc.\n" + "RD#: "+this.rrNumber);
            // let doc = new this.$jspdf('p', 'mm', 'letter');
            // doc.setFont(undefined, 'bold').setFontSize(13).text(6,8,"INGCOPH Traders Inc.")
            // // doc.setFont(undefined, 'normal').setFontSize(9).text(6,13,"420 Del Monte Ave, Brgy. Siena, Quezon City\nCell No.: +63 935 713 2293 / +63 917 170 0979 / +63 919 078 0016 \nTel. No.: +63 2 8727 1771 / +63 2 8535 9968 / +63 2 7091 4539 \nService Mobile / Viber No.: +63 919 078 0019 / +63 927 414 5539 \nSpare Parts Mobile / Viber No.: \nEmail:Ingcocares@ingco.com.ph+63 995 095 3153")
            // doc.setFont(undefined, 'normal').setFontSize(9).text(6,13,"2 D Arellano St. Bagong Barrio, Caloocan City \nLandline:7002-3394\nSpare Parts Mobile / Viber No.: \nEmail:Ingcocares@ingco.com.ph")
            // doc.setFont(undefined, 'bold').setFontSize(13).text(162,8,"Repair Form")
            // doc.setFont(undefined, 'bold').setFontSize(9).text(162,13,"\nRF#:"+this.rfNumber+"\n\nPullout#: "+this.pulloutNumber+", RD#: "+this.rrNumber+"\n\nIssued: "+this.rrDate)

            doc.autoTable({
                html: '#rf_form',
                theme: 'grid',
                startY: 24,
                margin: {top: 37, left: 7, right: 6},
                columnStyles: {
                    0: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    1: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    2: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    3: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    4: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    5: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    6: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    7: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8}
                }
            });

            let formEndPosition = doc.lastAutoTable.finalY;

            let type = this.itemWarrantyType
            let checkbox = ''
            if (type == 'Out-Warranty') {
                checkbox = 'OUT'
            } else if (type == 'In-Warranty') {
                checkbox = 'IN'
            } else {
                checkbox = 'DS'
            }

            let warranty_type = ['IN', 'OUT', 'DS'];
            let warranty_x = 30;
            let warranty_y = formEndPosition - 52.5;
            warranty_type.forEach((arr, i) => {
                doc.rect(warranty_x, warranty_y, 4, 3, checkbox == arr ? "F" : "S")

                doc.setFont(undefined, 'normal').setFontSize(9).text(arr, warranty_x + 6, warranty_y + 3);

                warranty_x += 25;
            })

            doc.setFont(undefined, 'normal').setFontSize(9).text(7,formEndPosition + 4,"QA Assessment:")
            doc.autoTable({
                html: '#qa_assessment_info',
                theme: 'grid',
                startY: formEndPosition + 5,
                margin: {top: 37, left: 7, right: 6},
                columnStyles: {
                    0: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    1: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    2: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    3: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    4: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    5: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    6: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    7: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8}
                },
                // didParseCell(data) {
                //     if (data.row.index == 2) {
                //     data.cell.styles.minCellHeight = 30;
                //     }
                // }
            });

            let assessmentEndPosition = doc.lastAutoTable.finalY;

            doc.setFont(undefined).setFontSize(9).text(120,formEndPosition + 10.5,'___ ___ / ___ ___ / ___ ___ ___ ___');

            doc.setFont(undefined, 'normal').setFontSize(9).text(7,assessmentEndPosition + 6,"Check list:")

            let check_list = this.GET_CHECK_LISTS_SELECTION.slice();
            let x = 9;
            let y = assessmentEndPosition + 7;

            const checkListLength = check_list.length;
            const checkListHeight = Math.floor((checkListLength / 6) + 1) * 5;

            doc.rect(7,assessmentEndPosition + 7,203,checkListHeight + 2,'S')

            check_list.forEach((arr, i) => {
            doc.rect(x, y + 2, 4, 3);

            // Display arr.name next to the rectangle
            doc.text(arr.name, x + 6, y + 5);

            // Increment the X-coordinate for the next item
            x += 35; // You can adjust this value to control horizontal spacing

            // Start a new line after every 5 items
            if ((i + 1) % 6 === 0) {
                x = 9; // Reset X-coordinate to the initial position
                y += 5; // Move to the next line, you can adjust this value for vertical spacing
            }

            // Set the font size for the text
            doc.setFont(undefined).setFontSize(8);
            });

            const checkListEndPosition = assessmentEndPosition + checkListHeight + 2

            doc.setFont(undefined, 'normal').setFontSize(9).text(7,checkListEndPosition + 13,"Log Information:")
            doc.autoTable({
                html: '#log_info',
                theme: 'grid',
                startY: checkListEndPosition + 14,
                margin: {top: 37, left: 7, right: 6},
                columnStyles: {
                    0: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    1: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    2: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    3: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    4: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    5: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    6: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    7: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8}
                }
            });

            let logInfoEndPosition = doc.lastAutoTable.finalY;

            doc.setFont(undefined, 'normal').setFontSize(9).text(7,logInfoEndPosition + 6,"Estimate Information:")
            doc.autoTable({
                html: '#rf_estimation_form',
                theme: 'grid',
                startY: logInfoEndPosition + 7,
                margin: {top: 37, left: 7, right: 6},
                columnStyles: {
                    0: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    1: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    2: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    3: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    4: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    5: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    6: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    7: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8}
                }
            });

            // doc.autoPrint();
            // window.open(doc.output('bloburl'), '_blank');

            if(!!this.rfDetails) {
                if(this.rfDetails.printed) {
                    if(this.confirmOverride ==  false) {
                        this.confirmedOverridePrint();
                    } else {
                        this.printRfDialog().then(e=>{

                            e ? doc.save(this.rfNumber+'.pdf') : ''
                        })
                    }
                } else {
                    this.printRfDialog().then(e=>{
                        e ? doc.save(this.rfNumber+'.pdf') : ''
                    })
                }
            }


            // let doc = new this.$jspdf('p', 'mm', 'letter');
            // doc.setFont(undefined, 'bold').setFontSize(13).text(6,8,"INGCOPH Traders Inc.")
            // // doc.setFont(undefined, 'normal').setFontSize(9).text(6,13,"420 Del Monte Ave, Brgy. Siena, Quezon City \nCell No.: +63 935 713 2293 / +63 917 170 0979 / +63 919 078 0016 \nTel. No.: +63 2 8727 1771 / +63 2 8535 9968 / +63 2 7091 4539 \nService Mobile / Viber No.: +63 919 078 0019 / +63 927 414 5539 \nSpare Parts Mobile / Viber No.: +63 995 095 3153")
            // doc.setFont(undefined, 'normal').setFontSize(9).text(6,13,"2 D Arellano St. Bagong Barrio, Caloocan City \nCell No.:  \nTel. No.:  \nService Mobile / Viber No.: +63 917 625 2286 \nSpare Parts Mobile / Viber No.: ")
            // doc.setFont(undefined, 'bold').setFontSize(13).text(162,8,"Repair Form")
            // doc.setFont(undefined, 'bold').setFontSize(9).text(162,13,"\nRF#:"+this.rfNumber+"\n\nPullout#: "+this.pulloutNumber+", RD#: "+this.rrNumber+"\n\nIssued: "+this.rrDate)

            // doc.autoTable({
            //     html: '#rf_details',
            //     theme: 'grid',
            //     startY: 37,
            //     margin: {top: 37, left: 7, right: 6},
            //     columnStyles: {
            //         0: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
            //         1: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
            //         2: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
            //         3: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
            //         4: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
            //         5: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
            //         6: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
            //         7: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8}
            //     }
            // });

            // // doc.fromHTML($("#rf_estimate_title").get(0), 7, 134,{'width':400});
            // doc.setFont(undefined, 'normal').setFontSize(9).text(7,140,"Estimate Information")
            // doc.autoTable({
            //     html: '#rf_estimation',
            //     theme: 'grid',
            //     startY: 141,
            //     margin: {top: 37, left: 7, right: 6},
            //     columnStyles: {
            //         0: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
            //         1: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
            //         2: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
            //         3: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
            //         4: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
            //         5: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
            //         6: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
            //         7: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8}
            //     }
            // });
            // doc.setFont(undefined, 'normal').setFontSize(9).text(7,189,"Unit Status")
            // doc.autoTable({
            //     html: '#rf_unit_movements',
            //     theme: 'grid',
            //     startY: 190,
            //     margin: {top: 37, left: 7, right: 6},
            //     columnStyles: {
            //         0: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
            //         1: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
            //         2: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
            //         3: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
            //         4: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
            //         5: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
            //         6: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
            //         7: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8}
            //     }
            // });
            // doc.setFont(undefined, 'normal').setFontSize(9).text(7,218,"Repair Information")
            // doc.autoTable({
            //     html: '#rf_repair_info',
            //     theme: 'grid',
            //     startY: 219,
            //     margin: {top: 37, left: 7, right: 6},
            //     columnStyles: {
            //         0: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
            //         1: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
            //         2: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
            //         3: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
            //         4: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
            //         5: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
            //         6: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
            //         7: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8}
            //     }
            // });
            // doc.setFont(undefined, 'normal').setFontSize(9).text(7,247,"Releasing Information")
            // doc.autoTable({
            //     html: '#rf_releasing_info',
            //     theme: 'grid',
            //     startY: 249,
            //     margin: {top: 37, left: 7, right: 6},
            //     columnStyles: {
            //         0: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
            //         1: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
            //         2: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
            //         3: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
            //         4: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
            //         5: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
            //         6: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
            //         7: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8}
            //     }
            // });
            // doc.autoPrint();


            // if(!!this.rfDetails) {
            //     if(this.rfDetails.printed) {
            //         if(this.confirmOverride ==  false) {
            //             this.confirmedOverridePrint();
            //         } else {
            //             window.open(doc.output('bloburl'), '_blank')
            //             this.getAllRfs();
            //             this.getRfDetails(this.rfId);
            //             this.confirmOverride = false;
            //         }
            //     } else {
            //         swal.fire({
            //             title: 'Are you sure you want to print?',
            //             text: 'It will require override after successfull print',
            //             icon: 'warning',
            //             showConfirmButton:true,
            //             showCancelButton  :  true,
            //             reverseButtons:true,
            //             allowOutsideClick :  false,
            //             confirmButtonColor: '#397373',
            //             cancelButtonColor : 'grey',
            //             confirmButtonText:'Confirm',
            //             showCloseButton:true,
            //         }).then((result)=> {
            //             if(result.isConfirmed) {
            //                 window.open(doc.output('bloburl'), '_blank');
            //                 let payload = {
            //                     id: this.rfDetails.id,
            //                     params:{}
            //                 }
            //                 this.$store.dispatch('successRfPrint',payload)

            //                 let payload_update = {
            //                     rf_id: this.rfDetails.id,
            //                     status: 'TA'
            //                 }
            //                 this.$store.dispatch('updateRepairFormStatus',payload_update).then((response)=>{
            //                     if(response.data.msg) {
            //                         swal.fire("", response.data.msg, "success");
            //                         this.getAllRfs();
            //                         this.getRfDetails(this.rfDetails.id);
            //                         this.rfDetailsDialog = false;
            //                     }
            //                 }).catch((e)=> {
            //                     console.log(e);
            //                 });
            //                 this.getAllRfs();
            //                 this.getRfDetails(this.rfId);
            //                 this.confirmOverride = false;
            //             }
            //         });
            //     }
            // }
        },
        async printRfDialog() {
            let result = false
            await swal.fire({
                title: 'Are you sure you want to print?',
                text: 'It will require override after successfull print',
                icon: 'question',
                showCancelButton:true,
            }).then( async (confirm)=> {
                if(confirm.isConfirmed) {
                        // this.updatedRepairFormStatus();
                    let payload = {
                        id: this.rfDetails.id
                    }
                    this.$store.dispatch('successRfPrint',payload);

                    this.$store.dispatch('servicePost', {
                        rf_id: this.rfDetails.id,
                        status: 'UAS',
                        url:'updateRepairFormStatus'
                    }).then((response)=>{
                        if(response.data.msg) {
                            swal.fire("", response.data.msg, "success");
                            this.getAllRfs();
                            this.getRfDetails(this.rfDetails.id);
                            this.rfDetailsDialog = false;
                            this.printRfOnFlight = false;
                        }
                    }).catch((e)=> {
                        console.log(e);
                    });
                    this.getAllRfs();
                    this.getRfDetails(this.rfId);
                    this.confirmOverride = false;
                    result  = true
                }else{
                    result  = false
                }
            });
            return result
        },
        updatedRepairFormStatus() {
            this.printRfOnFlight = true;
            let Doc = new this.$jspdf('p', 'mm', 'letter');
            window.open(Doc.output('bloburl'), '_blank');

            let payload = {
                id: this.rfDetails.id
            }
            this.$store.dispatch('successRfPrint',payload);

            this.$store.dispatch('servicePost', {
                rf_id: this.rfDetails.id,
                status: 'TA',
                url:'updateRepairFormStatus'
            }).then((response)=>{
                if(response.data.msg) {
                    swal.fire("", response.data.msg, "success");
                    this.getAllRfs();
                    this.getRfDetails(this.rfDetails.id);
                    this.rfDetailsDialog = false;
                    this.printRfOnFlight = false;
                }
            }).catch((e)=> {
                console.log(e);
            });
            this.getAllRfs();
            this.getRfDetails(this.rfId);
            this.confirmOverride = false;
        },
        confirmedOverridePrint() {
            this.showOverridePrint = true;
            if(!!this.overrideUserPassword) {
                let payload = {
                    departments_allowed: ['SR', 'IT' , 'EX'],
                    override_user_password: this.overrideUserPassword
                }
                this.$store.dispatch('confirmOverridePrintPL',payload).then((response)=> {
                    if(this.GET_CONFIRM_OVERRIDE_PRINT_PL.error) {
                        swal.fire("", this.GET_CONFIRM_OVERRIDE_PRINT_PL.error, "warning").then(()=>{this.confirmOverride = false;})
                        return false;
                    }
                    this.confirmOverride = true;
                    this.showOverridePrint = false;
                    this.overrideUserPassword = "";
                    this.printRepairForm();
                });
            }
        },
        closeOverrideDialog() {
            this.showOverridePrint = false;
        },
        doneRepairForm() {
            if(!!this.rfDetails) {
                swal.fire({
                    title: 'Are you sure you want to be done?',
                    text: 'This repair form is ready FOR repair',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then((result)=> {
                    if(result.isConfirmed) {
                        let payload = {
                            rf_id: this.rfDetails.id,
                            status: 'TA'
                        }

                        this.$store.dispatch('updateRepairFormStatus',payload).then((response)=>{
                            if(response.data.msg) {
                                swal.fire("", response.data.msg, "success");
                                this.getAllRfs();
                                this.getRfDetails(this.rfDetails.id);
                                this.rfDetailsDialog = false;
                            }
                        }).catch((e)=> {
                            console.log(e);
                        });
                    }
                });
            }
        },
        showQrDialog() {
            this.$store.commit('showShowQrCodeDialog');
        },
        showOverride() {
            if(!!this.printedQrcode) {
                this.override.override_dialog = true;
            } else {
                this.downloadQrcode();
            }
        },
        closeConfirmOverride(user_id, authenticated = false) {
            if (authenticated) {
                this.override.override_dialog = false;
                this.downloadQrcode();
                this.search = "";
            } else {
                this.override.override_dialog = false;
            }
        },
        downloadQrcode() {
            let pdf = new this.$jspdf('l', 'mm', [25,110]);
            // let pdf = new this.$jspdf('l', 'mm', 'letter');
            const assetTagCanvas = document.querySelector('#qr canvas');
            let image = assetTagCanvas.toDataURL('image/jpeg');

            var width = pdf.internal.pageSize.getWidth();
            var height = pdf.internal.pageSize.getHeight();

            pdf.addImage(image, 'png', 3,3,17,17);

            // pdf.setFontSize(6);
            // pdf.text(`Customer: ${this.customerName}`, 22,5);

            pdf.setFontSize(6);
            pdf.text(`RF#: ${this.rfNumber}`, 22,6);

            pdf.setFontSize(6);
            pdf.text(`Model: ${this.itemModel}`, 22, 10);

            pdf.setFontSize(6);
            pdf.text(`Serial: ${this.itemSerial}`, 50, 6);

            pdf.setFontSize(6);
            pdf.text(`Category: ${this.itemCategory}`, 50, 10);

            pdf.setFontSize(6);
            pdf.text(this.companyName, 22, 14);

            pdf.setFontSize(6);
            pdf.text(this.customerName, 22, 18);


            if(!!this.customerName) {
                pdf.setFontSize(6);
                pdf.text(`Date: ${this.rfDate}`, 50, 18);
            } else {
                pdf.setFontSize(6);
                pdf.text(`Date: ${this.rfDate}`, 22, 18);
            }


            pdf.addImage(image, 'png', 78,3,17,17);

            // open pdf to another window
            pdf.output('dataurlnewwindow');

            // pdf.save(`${this.rfNumber}-Qrcode.pdf`); allow to download pdf
            let payload = {
                rfId: this.rfId
            }
            this.$store.dispatch('updatePrintedQrcode',payload).then((response) => {
                if(response.data.msg) {
                    this.getRfDetails(this.rfId);
                    swal.fire('', response.data.msg, 'success');
                }
            }).catch((e)=> {
                console.log(e)
            })
        }
    }
}
</script>

<style>

</style>
