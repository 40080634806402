<template>
    <v-container fluid class="pa-3">
        <ComponentHeader
            :button="true"
            :title="'Warehouse Stocks vs Item Movement'"
            :type="'Inventory'"
        ></ComponentHeader>
        <v-container class="bg-bluish-gray default-container ma-0">
            <v-row no-gutters class="pb-1">
                <v-col sm="12" md="3">
                    <v-autocomplete
                        v-model="filters.warehouse_ids"
                        :items="dropdowns.warehouses.list"
                        class="d-inline-block w-100 small"
                        label="Warehouse"
                        background-color="white"
                        clearable
                        multiple
                        small-chips
                        deletable-chips
                        outlined
                        dense
                        auto-select-first
                        hide-details
                    ></v-autocomplete>
                </v-col>
                <v-col sm="12" md="2">
                    <v-autocomplete
                        v-model="filters.item_type_ids"
                        :items="dropdowns.item_types.list"
                        :loading="loading.item_types"
                        :disabled="loading.item_types"
                        class="d-inline-block w-100 small"
                        label="Item Type"
                        background-color="white"
                        clearable
                        multiple
                        small-chips
                        deletable-chips
                        outlined
                        dense
                        auto-select-first
                        hide-details
                    ></v-autocomplete>
                </v-col>
                <v-col sm="12" md="2">
                    <v-autocomplete
                        v-model="filters.item_category_ids"
                        :items="dropdowns.item_categories.list"
                        :loading="loading.item_categories"
                        :disabled="loading.item_categories"
                        class="d-inline-block w-100 small"
                        label="Category"
                        background-color="white"
                        clearable
                        multiple
                        small-chips
                        deletable-chips
                        outlined
                        dense
                        auto-select-first
                        hide-details
                    ></v-autocomplete>
                </v-col>
                <v-col sm="12" md="2">
                    <v-autocomplete
                        v-model="filters.item_ids"
                        :items="dropdowns.items.list"
                        :loading="loading.items"
                        :disabled="loading.items"
                        label="Model"
                        background-color="white"
                        item-value="value"
                        item-text="text"
                        placeholder="Select Item"
                        clearable
                        multiple
                        small-chips
                        deletable-chips
                        outlined
                        dense
                        auto-select-first
                        hide-details
                    >
                    </v-autocomplete>
                </v-col>
                <v-spacer></v-spacer>
                <v-col sm="12" md="3" class="text-right">
                    <v-btn class="mr-1" @click="resetFields()">Clear</v-btn>
                    <v-btn @click="getWarehouseStocksVsMovement(1)">Export</v-btn>
                    <v-btn class="ml-1" @click="clearUrlParams(); getWarehouseStocksVsMovement()">Filter</v-btn>
                </v-col>
            </v-row>
            <v-row no-gutters class="pb-1">
                <v-col sm="12" md="2">
                    <v-autocomplete
                        v-model="filters.variance"
                        :items="dropdowns.variance.list"
                        class="d-inline-block w-100 small"
                        label="Variance"
                        background-color="white"
                        auto-select-first
                        outlined
                        dense
                    ></v-autocomplete>
                </v-col>
                 <v-col sm="12" md="2">
                    <v-autocomplete
                        v-model="filters.active"
                        :items="dropdowns.active.list"
                        class="d-inline-block w-100 small"
                        label="Active Type"
                        background-color="white"
                        outlined
                        dense
                        auto-select-first
                        hide-details
                    ></v-autocomplete>
                </v-col>
                <v-col sm="12" md="1">
                    <v-checkbox
                        v-model="filters.has_images"
                        :true-value="1"
                        :false-value="0"
                        hide-details
                        dense
                        label="Images"
                    ></v-checkbox>
                </v-col>
            </v-row>
            <v-row dense no-gutters>
                <v-col cols="12">
                    <v-card>
                        <v-container fluid>
                            <v-data-table
                                :headers="datatable.headers"
                                :items="datatable.items.list"
                                :options.sync="datatable.options"
                                :server-items-length="datatable.items.count"
                                :loading="toggles.loaded"
                                :footer-props="{
                                    showFirstLastPage: true
                                }"
                                class="w-100"
                                dense
                                disable-sort
                                @update:page="getWarehouseStocksVsMovement()"
                                @update:items-per-page="getWarehouseStocksVsMovement()"
                            >
                                <template v-slot:item.image_path="{ item }">
                                    <v-img class="img-thumbnail" width="50" height="50" :src="checkImagePath(item.image_path)" eager contain></v-img>
                                </template>
                                <template v-slot:item.item_movement_balance="{ item }">
                                    <span class="text-nowrap">
                                        {{ thousandSeprator(item.item_movement_balance) }}
                                    </span>
                                </template>
                                <template v-slot:item.warehouse_stocks="{ item }">
                                    <span class="text-nowrap">
                                        {{ thousandSeprator(item.warehouse_stocks) }}
                                    </span>
                                </template>
                                <template v-slot:item.action="{ item }">
                                    <v-btn text icon color="orange">
                                        <v-icon class="btn-action" small @click="viewItem(item.id)">mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </v-container>
                    </v-card>
                    <ItemsViewComponent
                        :viewItemId="datatable.items.selected"
                        @closeView="closeView"
                    ></ItemsViewComponent>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>
<script>
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent'
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
import ItemsViewComponent from "@/views/main/layouts/dialogs/Sales/Items/ItemsViewComponent.vue"
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'

export default {
    mixins: [
        SharedFunctionsComponent
    ],
    components: {
        ComponentHeader,
        ItemsViewComponent
    },
    data: () => ({
        urlParams: {
            type_ids: [],
            category_ids: [],
            active: [],
            item_ids: [],
        },
        defaults: {
            item_type_ids: [],
            item_category_ids: [],
            item_ids: [],
            warehouse_ids: [],
            active: -1,
            for_export: 0,
            // variance: 1,
            // has_images: 1
            variance: -1,
            has_images: 0
        },
        toggles: {
            loaded: false,
        },
        loading: {
            item_types: true,
            item_categories: true,
            warehouses: true,
            items: true,
        },
        filters: {},
        dropdowns: {
            active: {
                list: [
                    { value: -1, text: 'All' },
                    { value: 1, text: 'Active' },
                    { value: 0, text: 'Inactive' },
                ],
            },
            variance: {
                list: [
                    { value: -1, text: 'All' },
                    { value: 1, text: 'With Variance' },
                ],
            },
            item_types: {
                list: [],
            },
            item_categories: {
                list: [],
            },
            warehouses: {
                list: [],
            },
            items: {
                list: [],
            },
        },
        datatable: {
            items: {
                list: [],
                count: 0,
                selected: 0,
            },
            headers: [
                { text: 'WAREHOUSE', align: 'start', value: 'warehouse',},
                { text: 'IMAGE', align: 'start', value: 'image_path',},
                { text: 'MODEL', align: 'start', value: 'model',},
                { text: 'NAME', align: 'start', value: 'name',},
                { text: 'UOM', align: 'start', value: 'uom'},
                { text: 'CATEGORY', align: 'start', value: 'category',},
                { text: 'TYPE', align: 'start', value: 'type'},
                { text: 'STOCKS', align: 'end', value: 'warehouse_stocks',},
                { text: 'BALANCE', align: 'end', value: 'item_movement_balance',},
                {text: 'ACTION', align: 'center', value: 'action',},
            ],
            options: {},
        },
    }),
    async mounted(){
        this.resetFields()
        await this.$store.dispatch('getWarehouseDefault')
        await this.storeUrlParams()
        await this.loadDropdowns()
        await this.setDefaultValues()
        // await this.getWarehouseStocksVsMovement()
        await this.$store.dispatch('itemselection')
    },
    computed: {
        ...mapGetters([
            'GET_ITEM_TYPES',
            'GET_ITEM_CATEGORIES',
            'GET_ITEM_SELECTION',
            'GET_ALL_WAREHOUSES',
            'GET_WAREHOUSE_DEFAULTS'
        ])
    },
    methods: {
        viewItem(item_id) {
            this.datatable.items.selected = item_id
        },
        closeView() {
            this.datatable.items.selected = 0
        },
        async storeUrlParams() {
            const params = new URLSearchParams(window.location.search)

            Object.assign(this.urlParams, {
                item_ids: params.get('item_ids') == null ? this.defaults.item_ids : JSON.parse(params.get('item_ids')),
                type_ids: params.get('type_id') == null ? this.defaults.item_type_ids : JSON.parse(params.get('type_id')),
                category_ids: params.get('category_id') == null ? this.defaults.item_category_ids : JSON.parse(params.get('category_id')),
                active: params.get('active') == null ? this.defaults.active : JSON.parse(params.get('active')),
                warehouse_ids: params.get('warehouse_ids') == null ? this.defaults.warehouse_ids : JSON.parse(params.get('warehouse_ids')),
                variance: params.get('variance') == null ? this.defaults.variance : JSON.parse(params.get('variance')),
                has_images: params.get('has_images') == null ? this.defaults.has_images : JSON.parse(params.get('has_images')),
            })

            Object.assign(this.filters, {
                item_type_ids: this.urlParams.type_ids,
                item_category_ids: this.urlParams.category_ids,
                item_id: this.urlParams.item_ids,
                active: this.urlParams.active,
                warehouse_ids: this.urlParams.warehouse_ids,
                variance: this.urlParams.variance,
                has_images: this.urlParams.has_images,
            })
        },
        async loadDropdowns() {
            await this.$store.dispatch('getItemTypes')
            await this.$store.dispatch('getItemCategories')
            await this.$store.dispatch('getAllWarehouses')
        },
        async setDefaultValues() {
            Object.assign(this.filters, {
                item_type_ids:
                    this.dropdowns.item_types.list.filter(f => {
                        return [1, 2].includes(f.value)
                    }).map(m => parseInt(m.value))
            })
        },
        clearUrlParams(){
            Object.assign(this.urlParams, {
                type_ids: [],
                category_ids: [],
            })
        },
        async getWarehouseStocksVsMovement(for_export = 0) {
            let item_base64_arr = [];
            this.toggles.loaded = true
            this.filters.for_export = for_export
            this.datatable.items.list = []
            let currentDate = this.$dayjs().format('YYYY-MM-DD')


            let payload = {
                pagination: this.datatable.options,
                filters: this.filters,
                url: 'warehouseStocksVsItemMovement',
            }

            if(for_export == 1){
                if(this.filters.warehouse_ids.length > 3 || this.filters.warehouse_ids.length == 0){
                    Swal.fire(
                        "Warning!",
                        "Please select a warehouse! (Maximum of 3)",
                        "warning"
                    );
                    this.toggles.loaded = false;
                    return false;
                }
                else if (this.filters.warehouse_ids.length != 1 && this.filters.item_type_ids.includes(3)){
                    Swal.fire(
                        "Warning!",
                        "Please select a warehouse! (Maximum of 1)",
                        "warning"
                    );
                    this.toggles.loaded = false;
                    return false;
                }
                else{
                    Swal.fire('Exporting File Please Wait')
                    Swal.showLoading()

                    // Get Base64 of all Items
                    if(this.filters.has_images == 1){
                        let base64_payload = {
                            url: 'getItemBase64',
                            filters: this.filters,
                        }
                        await this.$store.dispatch('reports',base64_payload).then(async response =>{
                            item_base64_arr = response.data
                        }).catch((err)=>{
                            Swal.fire(
                                'Network Error',
                                'error'
                            )
                            console.log(err);
                        });
                    }
                }
            }

            await this.$store.dispatch('reports', payload).then(async response => {

                if (for_export == 0) {
                    let data = response.data.data
                    this.datatable.items.list = data.data
                    this.datatable.items.count = data.total

                } else {
                    let data = response.data
                    const workbook = new this.$exceljs.Workbook()
                    const rawDataSheetName = `Items ${this.$dayjs().format('YYYY-MM-DD')}`
                    let worksheet = workbook.addWorksheet(rawDataSheetName)

                    const columns = [
                        { header: 'IMAGE', key: 'image', width: 22, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, },
                        { header: 'WAREHOUSE', key: 'warehouse', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } } },
                        { header: 'MODEL', key: 'model', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } } },
                        { header: 'NAME', key: 'name', width: 40, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } } },
                        { header: 'UOM', key: 'uom', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } } },
                        { header: 'CATEGORY', key: 'category', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } } },
                        { header: 'TYPE', key: 'type', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } } },
                        { header: 'STOCKS', key: 'warehouse_stocks', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false }, numFmt: '#,##0' } },
                        { header: 'BALANCE', key: 'item_movement_balance', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false }, numFmt: '#,##0' } },
                    ]

                    worksheet.columns = columns

                    if (this.filters.has_images != 1) {
                        worksheet.spliceColumns(1, 1)
                    }
                    let pos = 1
                    for await (const item of data) {
                        if(this.filters.has_images == 1){
                            item.base64img              = item_base64_arr.find((e)=> e.model == item.model).base64img;
                        }
                        item.warehouse_stocks       =  parseFloat(item.warehouse_stocks)
                        item.item_movement_balance  = parseInt(item.item_movement_balance)
                        worksheet.addRow(item)
                        if (this.filters.has_images === 1) {
                            await this.addImageToWorksheet(workbook, worksheet, item, pos);
                        }
                        pos++
                    }

                    worksheet.getRow(1).font = { bold: true };
                    // worksheet.getRow(1).height = 30;
                    // let borderStyles = {
                    //     top: { style: "thin" },
                    //     left: { style: "thin" },
                    //     bottom: { style: "thin" },
                    //     right: { style: "thin" }
                    // };

                    // worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
                    //     row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
                    //         cell.border = borderStyles;
                    //     });
                    // });

                    workbook.xlsx.writeBuffer().then((data) => {
                        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheetcharset=utf-8' })
                        const filename = `Warehouse Stocks vs Item Movement as of ${currentDate}.xlsx`
                        this.$filesaver.saveAs(blob, filename)
                    })
                    Swal.close()
                }
                this.toggles.loaded = false
            }).catch((err)=>{
                Swal.fire(
                    'Network Error',
                    "",
                    'error'
                )
                console.log(err);
            })
        },
        resetFields(){
            this.filters = JSON.parse(JSON.stringify(this.defaults))
        },
    },
    watch: {
        'GET_ITEM_TYPES': {
            handler(val) {
                this.dropdowns.item_types.list = val
                this.loading.item_types = false
            }
        },
        'GET_ITEM_CATEGORIES': {
            handler(val) {
                this.dropdowns.item_categories.list = val
                this.loading.item_categories = false
            }
        },
        'GET_ALL_WAREHOUSES': {
            handler(val) {
                this.dropdowns.warehouses.list = val
                this.loading.warehouses = false
            }
        },
        'GET_WAREHOUSE_DEFAULTS': {
            handler(val) {
                this.defaults.warehouse_ids = val
            }
        },
        'GET_ITEM_SELECTION': {
            handler(val) {
                this.dropdowns.items.list = val
                this.loading.items = false
            }
        },
    }
}
</script>
<style>
    .card-body{
        padding: 3px !important
    }
</style>
