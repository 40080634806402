<template>
    <div style="display:none;">
      <table id="tech_report">
          <tbody>
              <tr>
                  <td colspan="2">Tech Report No:<br/></td>
              </tr>
              <tr>
                  <td>Model No:</td>
                  <td>Dealer:</td>
              </tr>
              <tr>
                  <td>Serial No:</td>
                  <td>Customer:</td>
              </tr>
              <tr>
                  <td>Date Purchased:</td>
                  <td>Sold On-Line:</td>
              </tr>
              <tr>
                  <td>Date Received:</td>
                  <td>Repair Form No:</td>
              </tr>
              <tr>
                  <td>Warranty:</td>
                  <td>Technician:</td>
              </tr>
              <tr>
                  <td colspan="2">Complaint:</td>
              </tr>
              <tr>
                  <td colspan="2">Symptom Tested:</td>
              </tr>
          </tbody>
      </table>
      <table id="tech_diagnosis">
          <tbody>
              <tr>
                  <td>Tech Diagnosis:</td>
              </tr>
          </tbody>
      </table>
      <table id="parts_needed">
          <tbody>
              <tr>
                  <td colspan="4">Parts Needed</td>
              </tr>
              <tr>
                  <td v-if="moduleName != 'DTA'">SP Name</td>
                  <td>SP No</td>
                  <td>Description</td>
                  <td v-if="moduleName == 'DTA'">Remarks</td>
                  <td>Qty</td>
              </tr>

              <tr v-for="(x,index) in parts_needed" :key='index'>
                  <td v-if="moduleName != 'DTA'"> {{ x.name }}</td>
                  <td> {{ x.part_num }}</td>
                  <td> {{ x.description }}</td>
                  <td v-if="moduleName == 'DTA'"> {{ x.remarks }}</td>
                  <td> {{ x.serve_qty }}</td>
              </tr>

                <tr v-for="x in remaining_row" :key="x">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
            </tbody>
        </table>
        <table id="recommendation">
            <tbody>
                <tr>
                    <td>Recommendation:</td>
                </tr>
            </tbody>
        </table>
        <table id="signatories">
            <tbody>
                <tr>
                    <td>Prepared by: <br/></td>
                    <td>Diagnosed by: <br/></td>
                    <td>Reviewed By:<br>Kim Capulong<br>Technical Support</td>
                    <td>Approved by: <br>Rena Magdadaro <br> Service Operation Manager</td>
                </tr>
                <tr>
                    <td><u>{{ this.sc_repair_forms.prepared_by }}</u><br/>Repair Admin</td>
                    <td>{{ this.sc_repair_forms.assigned_technician }}<br/>Repair Technician</td>
                </tr>
            </tbody>
        </table>
        </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props:{
        moduleName: String,
        default: () => '',
    },
  data(){
      return{
          sc_repair_forms:[],
          parts_needed:[],
          isVisibleTd: true,
          remaining_row: 0
      }
  },
  computed:{
      ...mapGetters([
          'EXPORT_TECHNICAL_REPORT',
      ])
  },
  methods:{
      async printTechnicalReport(id){
          let payload = {
              id: id,
          }
          this.isVisibleTd = true,
          await this.$store.dispatch("printPdfTechnicalReport",payload).then(async res => {
              this.sc_repair_forms = res.data[0]
              this.parts_needed = this.moduleName == 'DTA' ? this.sc_repair_forms.defective_parts : this.sc_repair_forms.parts_needed
              if( this.parts_needed.length <= 7){
                  this.remaining_row = 7
                  this.remaining_row = this.remaining_row - this.parts_needed.length
              }
              let doc = await new this.$jspdf('p', 'mm', 'letter');
              let ingcoPhLogo = new Image();
              ingcoPhLogo.src = '/images/ingcoph-logo.png';

              // Header
              doc.setFillColor('white')
              doc.rect(6, 6, 201, 18);
              doc.line(52, 6, 52, 24);
              doc.addImage(ingcoPhLogo, 'png', 8, 8, 40, 15);
              doc.setFont(undefined, 'bold').setTextColor('red').setFontSize(24).text(80,18,"T e c h n i c a l  R e p o r t").setFont(undefined, 'normal').setTextColor('black')

              // First Column Lines
              doc.line(25, 30, 40, 30).setFontSize(7).text(25,29,this.sc_repair_forms.rf_number == null ? '' : this.sc_repair_forms.rf_number);
              doc.line(35, 39, 100, 39).setFontSize(8).text(35,38,this.sc_repair_forms.model == null ? '': this.sc_repair_forms.model);
              doc.line(35, 45, 100, 45).setFontSize(8).text(35,44,this.sc_repair_forms.serial == null ? '' : this.sc_repair_forms.serial);
              doc.line(35, 51, 100, 51).setFontSize(8).text(35,50,this.sc_repair_forms.purchase_date == null ? '' : this.sc_repair_forms.purchase_date);
              doc.line(35, 57, 100, 57).setFontSize(8).text(35,56,this.sc_repair_forms.delivered_at == null ? '' : this.sc_repair_forms.delivered_at);
              doc.line(35, 64, 100, 64).setFontSize(8).text(35,63,this.sc_repair_forms.warranty == null ? '' : this.sc_repair_forms.warranty);
              doc.line(35, 70, 207, 70).setFontSize(8).text(35,69,this.sc_repair_forms.complaint == null ? '' : this.sc_repair_forms.complaint);
              doc.line(35, 76, 207, 76).setFontSize(8).text(35,75,this.sc_repair_forms.symptom_upon_tested == null ? '' : this.sc_repair_forms.symptom_upon_tested);

              // Second Column Lines
              doc.line(129, 39, 207, 39).setFontSize(8).text(129,38,this.sc_repair_forms.dealer == null ? '' : this.sc_repair_forms.dealer);
              doc.line(129, 45, 207, 45).setFontSize(8).text(129,44,this.sc_repair_forms.company_name == null ? '' : this.sc_repair_forms.company_name);
              doc.line(129, 51, 207, 51).setFontSize(8).text(129,50,this.sc_repair_forms.store_name == null ? '' : this.sc_repair_forms.store_name);
              doc.line(129, 57, 207, 57).setFontSize(8).text(129,56,this.sc_repair_forms.rf_number == null ? '' : this.sc_repair_forms.rf_number);
              doc.line(129, 64, 207, 64).setFontSize(8).text(129,63,this.sc_repair_forms.assigned_technician == null ? '' : this.sc_repair_forms.assigned_technician);

              doc.autoTable({
                html: '#tech_report',
                    theme: 'plain',
                    margin: {top: 25, left: 4, right: 11},
                    columnStyles: {
                        0: { halign:'left', textColor: '#000000', fontSize: 7},
                        1: { halign:'left', textColor: '#000000', fontSize: 7},
                    },
                });
                //Image Container
                let imageContainerHeight = 50
                doc.setFillColor('white')
                let x = 15;
                let y = 85;

                this.sc_repair_forms.sc_repair_form_files.forEach((e,i)=>{
                    let supporting_img = new Image();
                    supporting_img = `${process.env.VUE_APP_API_HOST}/img/service?image_name=${e.name}`
                    fetch(supporting_img)
                    .then(resp => resp.blob())
                    .then(blobobject => {
                        const blob = window.URL.createObjectURL(blobobject);
                        const anchor = document.createElement('a');
                        anchor.style.display = 'none';
                        anchor.href = blob;
                        document.body.appendChild(anchor);
                        window.URL.revokeObjectURL(blob);
                    })
                    doc.addImage(supporting_img, 'JPEG', x, y,  35, 35 );
                    x=x+35;
                    if((i + 1) % 5 == 0){
                        imageContainerHeight += 35
                        y=y+35;
                        x=15;
                    }
                })
              doc.rect(6, doc.autoTable.previous.finalY, 201, imageContainerHeight);
                    // Tech Diagnosis
                doc.line(35, doc.autoTable.previous.finalY + imageContainerHeight + 5, 207, doc.autoTable.previous.finalY + imageContainerHeight + 5).setFontSize(8).text(35,doc.autoTable.previous.finalY + imageContainerHeight + 4, this.sc_repair_forms.tech_diagnosis == null ? '' : this.sc_repair_forms.tech_diagnosis);
                doc.autoTable({
                    html: '#tech_diagnosis',
                    theme: 'plain',
                    startY:doc.autoTable.previous.finalY + imageContainerHeight,
                    margin: {top: 25, left: 4, right: 11},
                    columnStyles: {
                        0: { halign:'left', textColor: '#000000', fontSize: 7},
                    },
                });

                // Parts Needed
                doc.autoTable({
                    html: '#parts_needed',
                    theme: 'grid',
                    startY:doc.autoTable.previous.finalY + 2,
                    margin: {top: 25, left: 6, right: 9},
                    columnStyles: {
                        0: { halign:'center', textColor: '#000000', fontSize: 7,cellWidth: 40,},
                        1: { halign:'center', textColor: '#000000', fontSize: 7,cellWidth: 50,},
                        2: { halign:'center', textColor: '#000000', fontSize: 7},
                        3: { halign:'center', textColor: '#000000', fontSize: 7,cellWidth: 30,},
                    },

                });
                //Recommendation
                doc.line(35, doc.autoTable.previous.finalY + 5, 207, doc.autoTable.previous.finalY + 5).setFontSize(8).text(35,doc.autoTable.previous.finalY + 4,this.sc_repair_forms.recommendation === null ? '' : this.sc_repair_forms.recommendation);
                doc.autoTable({
                    html: '#recommendation',
                    theme: 'plain',
                    startY:doc.autoTable.previous.finalY,
                    margin: {top: 25, left: 4, right: 11},
                    columnStyles: {
                        0: { halign:'left', textColor: '#000000', fontSize: 7, },
                    },
                });
                // Signatories
                doc.autoTable({
                    html: '#signatories',
                    theme: 'plain',
                    startY:doc.autoTable.previous.finalY + 1,
                    margin: {top: 23, left: 4, right: 11},
                    columnStyles: {
                        0: { halign:'left', textColor: '#000000', fontSize: 7},
                        1: { halign:'left', textColor: '#000000', fontSize: 7},
                        2: { halign:'left', textColor: '#000000', fontSize: 7},
                        3: { halign:'left', textColor: '#000000', fontSize: 7},
                    },
                    willDrawCell: function (data) {
                        var rows = data.table.body;
                        if (data.row.index === rows.length - 1) {
                            rows[1].cells[0].styles.halign = 'center'
                            rows[1].cells[1].styles.halign = 'center'
                            rows[1].cells[2].styles.halign = 'center'
                        }
                    },
                });

                doc.autoPrint();
                    window.open(doc.output('bloburl'), '_blank');
                });
                this.$store.commit('GET_PRINT_PDF_ID', 0);
        },
    },
    computed:{
        rf_id(){
            return this.$store.state.Services.print_pdf_id
        }
    },
    watch:{
        rf_id:{
            handler(val){
                if(val != 0 ){
                    this.printTechnicalReport(val)
                }
            }
        }
      },
}
</script>

<style>

</style>
