<template>
  <v-form ref="form" v-model="valid" >
    <v-card>
      <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
        <span class="text-h5">{{ ACTION === 'Submit' ? 'New' : ACTION }} Asset</span>
        <v-row class="m-0">
          <v-col cols="pull-right-10 p-2">
            <v-btn
              text
              icon
              small
              color="gray"
              class="float-right"
              @click="closeDialog()"
            >
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card cols="8" class="mt-2 mx-2">
        <v-card-text>
          <v-row justify="center">
            <v-col cols="5">
              <v-autocomplete
                :items="selections.item_unassigned.values"
                item-text="text"
                item-value="admin_item_id"
                auto-select-first
                outlined
                label="Item"
                required
                dense
                return-object
                :disabled="ACTION === 'Submit' && quantity === 0"
                @change="updateFormProperties"
              ></v-autocomplete>
            </v-col>
            <v-col cols="1">
                <v-text-field
                  v-if="ACTION === 'Submit'"
                  label="Quantity"
                  v-model="quantity"
                  outlined
                  dense
                  type="number"
                  hide-spin-buttons
                  @change="updateSelection()"

                >
                </v-text-field>

            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-container class="overflow-auto">

        <v-container fluid v-if="form.loaded">
          <v-row>
            <v-col cols="12" sm="3">
                <v-row>
                  <v-col class="pa-0 pr-1 mt-3">
                    <v-img :src="item_image" class="image-fluid" contain></v-img>
                  </v-col>
                </v-row>
              <v-row>
              </v-row>
            </v-col>
            <v-col cols="12" sm="9" class="pt-6">
              <v-row>
                <v-col cols="12" sm="12" class="pa-0 pr-1">
                  <v-text-field
                    v-model="form.asset_codes"
                    readonly
                    outlined
                    label="Asset Tags"
                    required
                    :disabled="
                      actions == 'Update' || disabled_view ? true : false
                    "
                    dense
                  >
                  <template #append>
                    <v-menu offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            text
                            v-bind="attrs"
                            v-on="on"
                          >

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on">mdi-format-list-bulleted-square</v-icon>
                                </template>
                                <span>View Asset Tags</span>
                              </v-tooltip>
                          </v-btn>
                        </template>
                        <v-list style="height:500px;overflow-y: scroll;">
                          <v-list-item
                            v-for="(item, index) in assets"
                            :key="index"
                          >
                            <v-list-item-title>
                                <v-text-field v-model="item.asset_code" outlined>
                                    <template #prepend>
                                        {{item.index}}.
                                    </template>
                                </v-text-field>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    <v-tooltip bottom>
                    </v-tooltip>
                  </template>
                </v-text-field>
                </v-col>

                <!-- <v-col cols="12" sm="3" class="pa-0 pr-1">
                    <v-autocomplete
                        v-model="form.category_id"
                        :items="GET_ADMIN_CLASSIFICATION_SELECTION"
                        item-text="text"
                        item-value="value"
                        auto-select-first
                        outlined
                        readonly
                        :disabled="
                        actions == 'Update' || disabled_view ? true : false
                        "
                        label="Classification"
                        dense

                    ></v-autocomplete>
                </v-col> -->
                <!-- <v-col cols="12" sm="3" class="pa-0 pr-1">
                    <v-text-field
                        v-model="form.cost"
                        outlined
                        label="Cost"
                        dense
                        readonly
                        :disabled="
                        actions == 'Update' || disabled_view ? true : false
                        "
                    >
                        <template #prepend-inner>
                            <span>&#8369;</span>
                        </template>
                    </v-text-field>
                </v-col> -->
              </v-row>
              <!-- <v-row>
                <v-col cols="12" sm="6" class="pa-0 pr-1">
                  <v-text-field
                    v-model="form.prepared_by"
                    outlined
                    label="Prepared By"
                    dense
                    required
                    readonly
                    :disabled="disabled_view"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" class="pa-0 pr-1">
                  <v-autocomplete
                    v-model="form.supplier_id"
                    :items="GET_ADMIN_SUPPLIER_SELECTION"
                    item-text="text"
                    item-value="value"
                    auto-select-first
                    outlined
                    required
                    label="Vendor"
                    readonly
                    :disabled="actions == 'Update'?true:disabled_view"
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3" class="pa-0 pr-1">
                  <v-autocomplete
                    v-model="form.sub_classification_id"
                    item-text="text"
                    item-value="value"
                    auto-select-first
                    :disabled="
                      actions == 'Update' || disabled_view ? true : false
                    "
                    outlined
                    :items="subClass"
                    required
                    readonly
                    label="Sub Classification"
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row> -->
              <!-- <v-row>
                <v-col cols="12" sm="6" class="pa-0 pr-1">
                  <v-autocomplete
                    v-model="form.category_id"
                    :items="GET_ADMIN_CATEGORY_SELECTION"
                    item-text="text"
                    item-value="value"
                    auto-select-first
                    outlined
                    label="Category"
                    required
                    readonly
                    :disabled="disabled_view"
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3" class="pa-0 pr-1">
                  <v-text-field
                    v-model="form.warranty"
                    outlined
                    label="Warranty"
                    dense
                      required
                    suffix="Day(s)"
                    :disabled="disabled_view"
                    @keypress="numberOnly"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" class="pa-0 pr-1">

                  <v-autocomplete
                    v-model="form.status"
                    :items="GET_STATUS_SELECTION"
                    item-text="text"
                    item-value="value"
                    auto-select-first
                    outlined
                    label="Status"
                    required
                    :disabled="disabled_view"
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row> -->
              <!-- <v-row>
                <v-col cols="12" sm="6" class="pa-0 pr-1">
                  <v-autocomplete
                    v-model="form.brand_id"
                    :items="GET_ADMIN_BRAND"
                    outlined
                    label="Brand"
                    dense
                    required
                    :disabled="disabled_view"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" class="pa-0 pr-1">
                  <v-autocomplete
                    v-model="form.location_id"
                    :items="GET_ADMIN_LOCATION"
                    item-text="text"
                    item-value="value"
                    auto-select-first
                    outlined
                    label="Shipped To"
                    required
                    :disabled="disabled_view"
                    :readonly="ACTION == 'Submit'"
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row> -->
              <!-- <v-row>
                <v-col cols="12" sm="6" class="pa-0 pr-1">
                  <v-autocomplete
                    v-model="form.company_id"
                    :items="GET_PV_COMPANIES_SELECTION"
                    item-value="value"
                    item-text="text"
                    outlined
                    label="Select Company"
                    dense
                    required
                    :disabled="disabled_view"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" class="pa-0 pr-1">
                  <v-autocomplete
                    v-model="form.store_branch_id"
                    :items="GET_STORE_BRANCHES_WITHOUT_ACCESS"
                    auto-select-first
                    outlined
                    label="Store Branch"
                    required
                    :disabled="disabled_view"
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row> -->
              <v-row>
                <v-col cols="12" sm="12" class="pa-0 pr-1">
                  <v-text-field
                    v-model="form.serial"
                    outlined
                    label="Serial"
                    dense
                    required
                    :disabled="disabled_view"
                  >{{ tmp_serial }}</v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                    v-model="form.description"
                    height="162"
                    outlined
                    label="Description"
                    :disabled="disabled_view"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="12">
                  <b>Classification: </b> {{ form.classification }}
                </v-col>
                <v-col lg="12">
                  <b>Sub Class: </b> {{ form.classification_sub }}
                </v-col>
                <v-col lg="12">
                  <b>Brand: </b> {{ form.brand }}
                </v-col>
                <v-col lg="12">
                  <b>Location: </b> {{ form.location }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions class="text-center">
        <v-row class="ma-1">
          <v-col cols="12" v-if="actions != 'View'">
            <v-btn
              class="float-center"
              @click="addAssets()"
              :disabled="disabled_view"
            >
              <span>{{ actions }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="confirm_dialog" width="70%" persistent>
      <v-card>
        <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
          <span class="text-h5">Asset Information</span>
          <v-row class="m-0">
            <v-col cols="pull-right-10 p-2">
              <v-btn
                text
                icon
                small
                color="gray"
                class="float-right"
                @click="closeConfirmDialog()"
              >
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-container class="overflow-auto">
          <v-container fluid>
            <v-row>
              <v-col cols="12" lg="3" sm="6">
                <v-card>
                  <v-img :src="item_image" class="image-fluid"></v-img>
                </v-card>
              </v-col>
              <v-col cols="12" lg="9" sm="6">
                <v-container fluid class="d-flex justify-content-between">
                  <span id="asset-details" v-html="'<pre>' + value + '</pre>'">
                    <pre></pre>
                  </span>
                  <v-row class="align-right">
                    <v-col cols="12" class="align-right">
                          <div class="d-flex">
                            <div
                              v-if="print_layout == 1"
                              ref="asset_code_print"
                              class="d-flex flex-column ml-auto mr-8 mt-8"
                              style="
                                width: 115px;
                                font-size: .5rem;
                                font-family: Gill Sans MT;
                                align-items: center;
                                line-height: 1.2;
                                font-weight: 600;
                              "
                            >

                              <span>{{printCompany()}}</span>
                              <span>{{ form.asset_code }}</span>
                              <span>{{ printStoreBranch() }}</span>
                            </div>
                          </div>
                          <qrcode-vue
                            v-if="print_layout == 2"
                            class="float-right"
                            :value="qr_value"
                            size="150"
                            level="H"
                            ref="qrcode"
                          />
                          <v-col cols="12" v-show="false" v-for="(item, i) in finalResult" :key="i">
                              <qrcode-vue
                                  ref="qrcodegenerate"
                                  :value="item.asset_code"
                                  size="150"
                                  level="H"
                                  class="float-right"
                                  id="qrcodegenerate"
                                  :location="item.location_id"
                                  :company_id="item.company_id"
                                  :store_branch_id="item.store_branch_id"
                                  :name="item.name"
                                  :asset_code="item.asset_code"
                              />
                          </v-col>
                    </v-col>
                    <v-col cols="7"></v-col>
                    <v-col cols="5">
                      <v-radio-group
                        v-model="print_layout"
                        dense
                        row
                      >
                        <v-radio
                          v-for="n in print_options"
                          :key="n.value"
                          :label="n.text"
                          :value="n.value"
                        />
                      </v-radio-group>
                    </v-col>
                  </v-row>
            </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions class="text-center">
          <v-row class="ma-1">
            <v-col cols="6">
              <v-btn class="float-center" @click="confirmAddAssets(dispatch)">
                <span>Confirm</span>
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                class="float-center"
                @click="closeConfirmDialog()"
                color="error"
              >
                <span>Cancel</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
      <adminTemplateComponent></adminTemplateComponent>
  </v-form>
</template>
    <script>
import QrcodeVue from "qrcode.vue";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import adminTemplateComponent from '@/views/main/layouts/PrintTemplate/AdminTemplateComponent.vue'
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
export default {
  mixins: [
        SharedFunctionsComponentVue
    ],
  data() {
    return {
        unsign_tag: 0,
        print_options: [
            { value: 1, text: 'Default' },
            { value: 2, text: 'QR Code' },
        ],
        print_layout: 1,
        navigation_link: [],
        tmp_arr:[],
        valid: false,
        overlay: false,
        vendorSelections:[],
        brandSelections:[],
        warehouseItemSelections:[],
        form: {
            classification:'',
            loaded:false,
            asset_num:'',
            asset_code:'',
            emp_id:'', // default 0
            name:'', // item name
            // tag_print_base69:'', // not using
            // qr_code_base69:'',
            supplier_id:'', // vendor ID (from PO)
            item_id:'',
            po_num:'', // PO ID
            category_id:'',
            status:5,
            active:1,
            brand_id:'',
            department_id:'',
            location_id:'',
            serial:'',
            admin_classification_id:'',
            admin_classification_sub_id:'',
            warranty:'',
            cost:'',
            purchase_date:'',
            description:'',
            store_branch_id:'',
            created_at:'',
            updated_at:'',
            accounted:'',
            warehouse_id:'',
            admin_warehouse_item_stocks_id:null,
            company_id:'',
            asset_codes:'',
        },
        assetDetails: {},
        itemSelection:[],
        itemsTreeview:[],
        item: "",
        subClass: [],
        value: ``,
        qr_value: "",
        count: "",
        dispatch: "",
        qr_code_image:"",
        // errors: {
        //   code: [(v) => !!v || "The code is required"],
        //   name: [(v) => !!v || "The name is required"],
        //   description: [(v) => !!v || "The description is required"],
        // },
        disabled_view: false,
        search_classification: "",
        search_sub_class: "",
        search_location: "",
        search_department: "",
        search_supplier: "",
        search_status: "",
        search_brand: "",
        search_employee:"",
        confirm_dialog: false,
        off_computed_to_details: false,
        asset_code_qr:'',
        asset_code:'',
        location_type:false,
        tmp: {},
        errors: {
            asset_code: [(v) => !!v || "Asset Tag is required"],
            asset_type: [(v) => !!v || "Classification is required"],
            sub_asset: [(v) => !!v || "Sub Classification is required"],
            // emp_id: [(v) => !!v || "Employee Name is required"],
            location: [(v) => !!v || "Location is required"],
            status: [(v) => !!v || "Status is required"],
            name: [(v) => !!v || "Name is required"],
            supplier: [(v) => !!v || "Supplier is required"],
            // warranty: [(v) => !!v || "Warranty is required"],
            // department_id: [(v) => !!v || "Department is required"],
            cost: [
            (v) => !!v || "Cost is required",
            (v) => !isNaN(v) || "Input must be a number",
            ],
            serial: [(v) => !!v || "Serial is required"],
            // brand: [(v) => !!v || "Brand is required"],
        },
        qrWidth:280,
        URL:process.env.VUE_APP_API_HOST,
        item_image:'',
        tmp_serial:'',
        tmp_selected_item:false,
        noImage:"images/noimage.png",
        finalResult: [{}],
        quantity: 0,
        new_asset_code: '',
        endAssetTag: 0,
        assetTags:[],
        selections:{
            item_unassigned:{
                loader:false,
                values:[]
            }
        },
        assets:[]
    };
  },
  components: {
    QrcodeVue,
    adminTemplateComponent
  },
  computed: {
    ...mapGetters([
      "ACTION",
      "DIALOGS",
      "GET_ADMIN_SUPPLIER_SELECTION",
      "GET_ADMIN_CLASSIFICATION_SELECTION",
      "GET_ADMIN_LOCATION",
      "GET_ADMIN_BRAND",
      "GET_STATUS_SELECTION",
      "GET_LIST_DEPARTMENT",
      "GET_ADMIN_CLASSIFICATION_COUNT",
      "GET_EDIT_ADMIN_ASSETS_DETAILS",
      "GET_NEW_ADMIN_ASSETS_TABLE",
      "GET_ADMIN_DISPATCH",
      "GET_EMPLOYEE_DATA_SELECTION",
      "GET_ADMIN_TABLE_COUNT",
      "GET_ADMIN_CATEGORY_SELECTION",
      "GET_CLASSIFICATION_TREE_VIEW",
      "GET_SELECTED_ADMIN_ITEMS",
      'GET_ADM_WAREHOUSE_CODE_DROPDOWN',
      'GET_ADMIN_SUPPLIER_SELECTION',
      'GET_WAREHOUSE_ITEM_SELECTION',
      'GET_PV_COMPANIES_SELECTION',
      'GET_STORE_BRANCHES_WITHOUT_ACCESS',
      'ADMIN_ASSET_MANAGEMENT'
    ]),
    actions() {
        // if (this.ACTION == "Submit") {
        //     this.disabled_view = false;
        //     this.dispatch = this.GET_ADMIN_DISPATCH.add;
        //     this.off_computed_to_details = false;
        //     return "Submit";
        // }else if (this.ACTION == "Update") {
        //     this.disabled_view = false;
        //     this.off_computed_to_details = true;
        //     this.dispatch = this.GET_ADMIN_DISPATCH.update;
        //     this.updateFormProperties();
        //     return "Update";
        // } else if (this.ACTION == "View") {
        //     this.disabled_view = true;
        //     // this.updateFormProperties();
        //     return "View";
        // }
        let action = ''
        if (this.ACTION == "Submit") {
            this.disabled_view = false;
            this.dispatch = this.GET_ADMIN_DISPATCH.add;
            this.off_computed_to_details = false;
            action = "Submit";
        }else if (this.ACTION != "Submit") {
            this.disabled_view = false;
            this.off_computed_to_details = true;
            this.dispatch = this.GET_ADMIN_DISPATCH.update;

            // this.updateFormProperties();
            this.updateSelection();
            action = "Update";
        }
        return action
    },
    resizeQr(){
        switch (this.$vuetify.breakpoint.name) {
            case 'xs': return 150
            case 'sm': return 150
            case 'md': return 150
            case 'lg': return 300
            case 'xl': return 260
            case 'xxl': return 350
        }
    },
    width() {
        switch (this.$vuetify.breakpoint.name) {
            case 'xs': return "100%"
            case 'sm': return "100%"
            case 'md': return "50%"
            case 'lg': return "50%"
            case 'xl': return "50%"
        }
    },
  },
  mounted() {
    this.$store.dispatch('getAdminWarehouseCodeDropdown');
    this.$store.dispatch('adminAssetManagementUntagged');

  },
  methods: {
    async updateFormProperties(val) {
        this.form.company_id = this.selections.item_unassigned.values.find(e => e.value == val.value)?.company_id;
        this.form.supplier_id = this.selections.item_unassigned.values.find(e => e.value == val.value)?.supplier_id;
        this.form.admin_classification_id = this.selections.item_unassigned.values.find(e => e.value == val.value)?.admin_classification_id;
        this.form.admin_classification_sub_id = this.selections.item_unassigned.values.find(e => e.value == val.value)?.admin_classification_sub_id;
        this.form.name = this.selections.item_unassigned.values.find(e => e.value == val.value)?.item_name;
        this.form.po_num = this.selections.item_unassigned.values.find(e => e.value == val.value)?.po_num;

        this.form.loaded = false
        this.assets = []

        Object.assign(this.form,{
            ...val
        })

        if(!this.form.admin_item_id){
            return
        }

        await this.$store.dispatch('adminAssetManagementGet', {url:`get-asset-data/${this.form.admin_item_id}`}).then(response => {
                Object.assign(this.form,{
                ...response
            })
            this.form.serial = this.form.classification_code + ' - ' + this.form.text
            this.form.warranty = 7
            this.form.qr_codeBase69 = ''

            let asset_code = this.form.latestAssetTag.asset_code
            if(asset_code){
                asset_code = asset_code.split('-');
                asset_code = asset_code[asset_code.length - 1]
            }else{
                asset_code = '0'
            }

                asset_code = parseInt(asset_code);

                Array.from({ length: this.quantity }, (_, i) => i + 1).forEach((e,i)=>{
                    let asset_num = asset_code + (i + 1)
                    this.form.asset_num = asset_num;
                    this.form.asset_code = this.form.classification_code + '-' + this.$dayjs().format('YYYY') + '-' + asset_num.toString().padStart(4,'00')
                    this.assets.push({...this.form})
                    asset_num = parseInt(asset_num)
                })

                // if quantity is greater than 1 then concat the first and last array
                this.form.asset_code = this.assets[this.assets.length - 1].asset_code
                if(this.assets.length > 1){
                    this.form.asset_codes = this.assets[0].asset_code + ' -> ' + this.assets[this.assets.length - 1].asset_code
                }else{
                    this.form.asset_codes = this.form.asset_code
                }

                this.form.loaded = true
        });

        this.GenerateSerial();
        this.costFormatter();

    },
    // view(){

    //     if(this.GET_EDIT_ADMIN_ASSETS_DETAILS){
    //         this.$store.dispatch('adminAssetManagementGet',{
    //             url:'getWarehouseItemSelections',
    //             admin_warehouse_item_stocks_id:this.ACTION == 'Submit' ? this.form.admin_warehouse_item_stocks_id : this.GET_SELECTED_ADMIN_ITEMS.id,
    //         }).then(response=>{

    //             if(!response.data[0]){
    //                 return
    //             }

    //             this.form = response.data[0]
    //             this.form.status = 5
    //             this.subClass = this.GET_ADMIN_CLASSIFICATION_SELECTION.find(e=>e.value == this.form.admin_classification_id).sub_class.find(el=>el.value == this.form.admin_classification_sub_id) ?? {
    //                 admin_classification_id:'',
    //                 code:'',
    //                 text:'',
    //                 value:'',
    //             }
    //         })
    //     }
    // },
    costFormatter() {
        if (this.form.cost) {
            this.form.cost = parseFloat(this.form.cost).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
        }
    },
    resetWarehouseItems(value) {
      if (!value) {
        this.$store.commit('WAREHOUSE_ITEM_SELECTION', []);
      }
    },
    amount(){
      if(this.form.cost){
        let num = this.form.cost;
        this.form.cost = this.thousandSeprator(num)
      }
    },
    async getSelecteditems(){
        this.$store.dispatch('adminAssetManagementGet',{
            url:'admin-asset-management-details'
        })
    },
    GenerateSerial(){
      let classification = '';
      let subClass='';
      let warehouseItemText = '';

      if(this.GET_ADMIN_CLASSIFICATION_SELECTION.find(e=>e.value == this.form.asset_type) != undefined){
        classification= this.GET_ADMIN_CLASSIFICATION_SELECTION.find(e=>e.value == this.form.asset_type).code;

        if (this.itemSelection.find(e => e.value == this.form.warehouse_items) != undefined) {
          let trim = this.itemSelection.find(e => e.value == this.form.warehouse_items).text;
          warehouseItemText = this.trimWareHouseItemName(trim)
        }

        this.form.serial = (classification + "-" + subClass.replace(" ", "") + "-" + warehouseItemText).toUpperCase();
        this.tmp_serial = (classification + "-" + subClass.replace(" ", "") + "-" + warehouseItemText).toUpperCase();
        this.form.name = warehouseItemText;
      }
    },
    closeDialog() {
      this.resetFields();
      this.tmp = {};
      // this.off_computed_to_details = false;
      this.$store.commit("EDIT_ADMIN_ASSETS_DETAILS", []);
      this.$store.commit('WAREHOUSE_ITEM_SELECTION', []);
      this.$store.commit("DIALOG", false);
      // this.off_computed_to_details = true;
    },
    addAssets() {
        this.$store.commit("DIALOG", false);
        this.confirm_dialog = true;
    },
    generateBatchSerial(e){
        let classification  = '';
        let subClass = '';
        let wareHouseItemText= '';

        classification = this.GET_ADMIN_CLASSIFICATION_SELECTION.find(d=>d.value == e.admin_classification_id).code;
        if(this.GET_ADMIN_CLASSIFICATION_SELECTION.find(d=>d.value == e.admin_classification_id).sub_class.find(el=>el.value == e.admin_classification_sub_id) != undefined){
            subClass = this.GET_ADMIN_CLASSIFICATION_SELECTION.find(d=>d.value == e.admin_classification_id).sub_class.find(el=>el.value == e.admin_classification_sub_id).code;
        }

        return (classification + "-" + subClass.replace(" ", "") + "-" + e.name).toUpperCase();
    },

    UpdateAssets() {
      this.confirm_dialog = true;
    },
    closeConfirmDialog() {
      this.confirm_dialog = false;
      this.$store.commit("DIALOG", true);
      this.finalResult = [{}]
      // this.$store.commit('NEW_ADMIN_ASSETS_TABLE',true);
      // this.confirm_dialog = true;
    },
    async confirmAddAssets(dispatch) {
        let that = this;
        this.qr_code_image = '';

        if(this.print_layout == 2){
        let new_canvas = document.createElement('canvas');
        new_canvas.setAttribute('height', `335px`);
        new_canvas.setAttribute('width', '300px');
        const new_ctx = new_canvas.getContext("2d");

        new_ctx.fillStyle = "white";
        new_ctx.fillRect(0, 0, 300, 335);

        const canvasElements = document.getElementsByTagName("canvas");

        var canvasImages = [];

        let j = 0;
        if(this.print_layout == 2){
            j = 1
        } else {
            j = 0
        }

        for (let i = j; i < canvasElements.length; i++) {
            canvasImages.push(canvasElements[i].toDataURL("image/jpeg"));
        }

        const assetTagCanvas = document.querySelector('#qrcodegenerate canvas');

        let assetTagQr = '';

        if (assetTagCanvas) {
            assetTagQr = assetTagCanvas.toDataURL("image/jpeg");
        }

        let image_asset_code = document.createElement('img');

        if (assetTagQr) {
            await image_asset_code.setAttribute('src', assetTagQr);
            image_asset_code.setAttribute('id', 'qrcode_canvas');
        }

        var doc = new this.$jspdf({
            orientation: 'p',
            unit: 'mm',
            format: 'letter'
        });

        var finalImage = [];

            for (let i = 0; i < canvasImages.length; i++) {
                if (i > 0) {
                    doc.addPage();
                }
                let currentImg = document.createElement('img');
                await currentImg.setAttribute('src', canvasImages[i]);
                new_ctx.clearRect(0, 0, new_canvas.width, new_canvas.height);
                new_ctx.fillStyle = "white";
                new_ctx.fillRect(0, 0, 300, 335);
                new_ctx.drawImage(currentImg, 10, 10, 280, 280);
                if (assetTagQr) {
                    new_ctx.fillStyle = "white";
                    new_ctx.fillRect(215, 245, 90, 90);
                    new_ctx.drawImage(currentImg, 220, 220, 70, 70);
                }
                new_ctx.fillStyle = "black";
                new_ctx.font = "bold 16px arial";
                // let location = this.GET_ADMIN_LOCATION.find(e => e.value === that.$refs.qrcodegenerate[i].$attrs.location).text;
                let location = this.GET_PV_COMPANIES_SELECTION.find(e => e.value === this.form.company_id).text;
                let locationTextWidth = new_ctx.measureText(location).width;
                let locationX = (new_canvas.width - locationTextWidth) / 2;
                new_ctx.fillText(location, locationX, 305);
                new_ctx.font = "bold 16px arial";
                let qrCodeText = that.$refs.qrcodegenerate[i].value;
                let qrCodeTextWidth = new_ctx.measureText(qrCodeText).width;
                let qrCodeX = (new_canvas.width - qrCodeTextWidth) / 2;
                new_ctx.fillText(qrCodeText, qrCodeX, 320);
                let itemName = that.$refs.qrcodegenerate[i].$attrs.name;
                new_ctx.font = "bold 9px arial";
                let itemNameWidth = new_ctx.measureText(itemName).width;
                let itemNameX = (new_canvas.width - itemNameWidth) / 2;
                new_ctx.fillText(itemName, itemNameX, 330);
                doc.addImage(`${new_canvas.toDataURL("image/jpeg")}`, 'PNG', 0, 0, 215, 279);
                finalImage.push(new_canvas.toDataURL("image/jpeg"));
                this.finalResult[i].qr_codeBase69 = new_canvas.toDataURL("image/jpeg");
            }
        }
        Swal.fire({
        title: "",
        text: `Are you sure you want to Submit and Print QR code / Tag?`,
        icon: "warning",
        showConfirmButton: true,
        showCancelButton: true,
        reverseButtons: true,
        allowOutsideClick: false,
        confirmButtonColor: '#397373',
        cancelButtonColor: 'grey',
        confirmButtonText: 'Confirm',
        showCloseButton: true,
        }).then((actions) =>{
            if (actions.isConfirmed) {
                if(this.print_layout == 2){
                doc.autoPrint();
                window.open(doc.output('bloburl'), '_blank');

                for (let i = 0; i < canvasImages.length; i++) {
                    let xhr = new XMLHttpRequest();
                    xhr.responseType = "blob";
                    xhr.onload = function () {
                        let a = document.createElement("a");
                        a.href = window.URL.createObjectURL(xhr.response);
                        a.download = `${that.$refs.qrcodegenerate[i].value}.png`;
                        a.style.display = "none";
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                    };

                    xhr.open("GET", finalImage[i]);
                    xhr.send();
                }
                } else {
                const workbook = new this.$exceljs.Workbook();
                const extra_characters = /[*?:\\/\[\]]/g;
                const worksheetName = this.selections.item_unassigned.values.find(e=>e.value == this.form.value).item_name.replace(extra_characters, '');
                const worksheet = workbook.addWorksheet(worksheetName);

                    worksheet.columns = [
                        { key: 'asset_code', width: 20 },
                        // { key: 'total_amount', width: 20, style: { numFmt: '#,##0.00' } },
                        // { key: 'amount_to_pay', width: 25, style: { numFmt: '#,##0.00' } },
                        // { key: 'vendor_name', width: 50 },
                        // { key: 'location', width: 12 },
                        // { key: 'paid', width: 12 },
                        // { key: 'received', width: 12 },
                        // { key: 'payment_method', width: 20 },
                    ];

                    this.assets.forEach(item => {
                        const row = worksheet.addRow({
                            asset_code: item.asset_code,
                        });

                        row.eachCell((cell) => {
                            cell.alignment = { vertical: 'middle', horizontal: 'center' };
                        });
                    });

                    let exportName = `${this.selections.item_unassigned.values.find(e=>e.value == this.form.value).item_name.replace(extra_characters, '')}`;
                    workbook.xlsx.writeBuffer().then((data) => {
                        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                        this.$filesaver.saveAs(blob, `${exportName}.xlsx`);
                    });
                }

                if(dispatch === 'AdminUpdateAssets'){
                    this.$store.dispatch(dispatch, this.form).then((e) => {});
                    this.closeConfirmDialog()
                    this.closeDialog()
                    this.finalResult = [{}]
                } else {
                this.$store.dispatch("adminAssetsbatchAddition", {
                    form: this.assets,
                    description: this.form.description,
                }).then(response=>{
                    Swal.fire('Batch Addition Asset Management Success!','','success');
                    this.$store.commit('ASSET_MANAGEMENT_BATCH_DIALOG', false);
                    this.$store.commit('NEW_ADMIN_ASSETS_TABLE',true);
                    this.$store.commit("DIALOG", false);
                    this.finalResult = [{}]
                    this.resetFields()

                }).catch(err=>{
                    console.error(err);
                    Swal.fire('Error!','','error');
                });
                }
            }
        }).catch(error => {
            console.error(error);
            Swal.fire('Error!','','error');
        });
    },
    locations(){
      this.GET_ADMIN_LOCATION.filter(e=>{
          let assign_text = ''
          if(e.text == this.search_location){
            this.GET_ADMIN_CLASSIFICATION_COUNT.filter((element) => {
              if(e.asset_code_assign == element.assign_tag && this.search_classification == element.name)
              {
                let str = element.count.toString();
                if(e.asset_code_assign != null){
                  this.location_type = true
                  if(this.ACTION == "Submit")
                  {
                    this.form.asset_code = this.asset_code.split("-")[0]+ "-" + str.padStart(4, "00");
                  }
                }else{
                  if(this.ACTION == "Submit")
                  {
                    this.form.asset_code = this.asset_code;
                  }
                  this.location_type = false
                }
                if(this.ACTION == "Submit")
                {
                    this.asset_code_qr = this.form.asset_code;
                  }
              }
            });
          }
        })

    },
    printCompany() {
        if(this.form.company_id){
            let company = this.GET_PV_COMPANIES_SELECTION.find(company => company.value == this.form.company_id).text
            let trim = company.split(' ');
            let result = trim[0].trim();
            this.value = `
                Asset Tag/s: ${this.form.asset_codes}
                Serial: ${this.form.serial}
                Name: ${this.form.item_name}
                Brand: ${this.form.brand ?? ''}
                Classification: ${this.form.classification}
                Sub Classification: ${this.form.classification_sub}
                Location: ${this.form.location}
                Warranty: ${this.form.warranty}`;
            this.qr_value = this.value
            return result
        }
    },
    printStoreBranch() {
      if(this.form.store_branch_id){
        return this.GET_STORE_BRANCHES_WITHOUT_ACCESS.find(store_branch => store_branch.value == this.form.store_branch_id).text
      }
    },
    renderPDF(){
      this.$store.commit('PRINT_ADMIN_DETAIL',this.form);
      this.$store.commit('PRINT_ADMIN',true);
    },
    resetFields() {
      this.print_layout = 1;
      this.form = {
            loaded:false,
            asset_num:'',
            asset_code:'',
            emp_id:'', // default 0
            name:'', // item name
            // tag_print_base69:'', // not using
            // qr_code_base69:'',
            supplier_id:'', // vendor ID (from PO)
            item_id:'',
            po_num:'', // PO ID
            category_id:'',
            status:20,
            active:1,
            brand_id:'',
            department_id:'',
            location_id:'',
            serial:'',
            admin_classification_id:'',
            admin_classification_sub_id:'',
            warranty:'',
            cost:'',
            purchase_date:'',
            description:'',
            store_branch_id:'',
            created_at:'',
            updated_at:'',
            accounted:'',
            warehouse_id:'',
            admin_warehouse_item_stocks_id:null,
            company_id:'',
            asset_codes:'',
        };
      this.assets = []
      this.selections = {
            item_unassigned:{
                loader:false,
                values:[]
            }
        },
      this.tmp = {};
      this.quantity = 0;
    },
    async updateSelection(){
        this.selections.item_unassigned.values = this.ADMIN_ASSET_MANAGEMENT.ADMIN_UNTAGGED_ASSETS.filter(e=>parseInt(e.untagged_qty) >= this.quantity)
    }
  },
  watch: {
    'unsign_tag':{
        handler(val){
          if(val < this.quantity){
            this.quantity = val
            Swal.fire('',`There are ${val} available untagged assets`,'warning');
          }
        },
        deep: true
    },
    DIALOGS: {
      handler(val) {
        if (val && this.off_computed_to_details) {
          this.updateFormProperties();
        }else{
          if(!this.confirm_dialog){
            this.tmp_selected_item = false
          }

        }
      },
    },
    GET_NEW_ADMIN_ASSETS_TABLE: {
      handler(val) {
        if (val) {
          this.resetFields();
          this.$store.commit('ACTION', '')
          this.confirm_dialog = false;
          Swal.fire("Success!", "Asset Saved", "success");
          this.tmp_selected_item = false
        }
      },
      deep:true
    },
    search_classification: {
      handler(val) {
        this.GenerateSerial();
      },
      deep: true,
    },
    search_sub_class:{
      handler(){
        this.GenerateSerial();
      }
    },
  },
};
</script>
<style scope>
.asset-image {
    width: 100%;
    height: 20vw;
    background-size:cover;
}
</style>
