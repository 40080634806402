import repository from '@/api/modules/Admin/adminAssetManagement'
import store from '@/store';
export default {
	state: {
		export_assets:false,
		print_accountability:false,
		print_admin_detail:{},
		admin_classification_count:'',
		edit_admin_assets_details:'',
		new_admin_assets_table:false,
		admin_table_count:'',
		assets_table:[],
		classificatreeview:[],
		selected_admin_items:{},
		warehouseitemselection:[],
		asset_management_batch_dialog: false,
		print_admin_release_form: false,
		print_admin_release_form_detail: {},
		print_low_value_asset_accountability: false,
		print_low_value_asset_accountabilitydetail: {},
		ADMIN_UNTAGGED_ASSETS: [],
		ADMIN_UNTAGGED_PO_ASSETS: [],
		ADMIN_UNTAGGED_PO_ITEMS_ASSETS: [],
	},
	getters: {
		GET_EXPORT_ASSETS:(state)=> state.export_assets,
		GET_ADMIN_CLASSIFICATION_COUNT:(state)=> state.admin_classification_count,
		GET_ADMIN_TABLE_COUNT:(state) => state.admin_table_count,
		GET_EDIT_ADMIN_ASSETS_DETAILS:(state) => state.edit_admin_assets_details,
		GET_NEW_ADMIN_ASSETS_TABLE:(state)=>state.new_admin_assets_table,
		GET_PRINT_ACCOUNTABILITY:(state)=>state.print_accountability,
		GET_PRINT_ADMIN_ARF:(state)=>state.print_admin_release_form,
		GET_LOW_VALUE_ASSET_PRINT:(state)=>state.print_low_value_asset_accountability,
		GET_PRINT_ADMIN_DETAIL:(state)=>state.print_admin_detail,
		GET_PRINT_ADMIN_ARF_DETAIL:(state)=>state.print_admin_release_form_detail,
		GET_LOW_VALUE_ASSET_PRINT_DETAIL:(state)=>state.print_low_value_asset_accountabilitydetail,
		GET_ASSETS_DATA:(state)=>state.assets_table,
		GET_CLASSIFICATION_TREE_VIEW:(state)=>state.classificatreeview,
		GET_SELECTED_ADMIN_ITEMS:(state)=>state.selected_admin_items,
		GET_WAREHOUSE_ITEM_SELECTION:(state)=>state.warehouseitemselection,
		GET_ASSET_MANAGEMENT_BATCH_DIALOG:  state => state.asset_management_batch_dialog,
        ADMIN_ASSET_MANAGEMENT: state => state
	},
	actions:{
        AdminCreateAssets({commit,state},payload){
            return new Promise((resolve,reject)=>{
				repository.AdminCreateAssets(payload).then((response)=>{
					commit('NEW_ADMIN_ASSETS_TABLE',true);
					if(response.status == 200){

						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
		AdminUpdateAssets({commit,state},payload){
            return new Promise((resolve,reject)=>{
				repository.AdminUpdateAssets(payload).then((response)=>{
					if(response.status == 200){
						commit('NEW_ADMIN_ASSETS_TABLE',true);
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
		AdminDeleteAssets({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.AdminDeleteAssets(payload).then((response)=>{
					if(response.status == 200){
						commit('NEW_ADMIN_ASSETS_TABLE',true);
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
		getAllAdminAssets({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getAllAdminAssets(payload).then((response)=>{

					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
		getAllAdminAssetsList({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getAllAdminAssetsList(payload).then((response)=>{
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
		exportAssets({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.exportAssets(payload).then((response)=>{
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
		getAdminAssetListEmployees({commit},payload){
					return new Promise((resolve,reject)=>{
			repository.getAdminAssetListEmployees(payload).then((response)=>{

				if(response.status == 200){
					resolve(response.data)
				}
			}).catch((error)=>{
			reject(error)
			});
		})
			},
		findAssets({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.findAssets(payload).then((response)=>{
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		classificationTreeView({commit}){
			return new Promise((resolve,reject)=>{
				repository.classificationTreeView().then((response)=>{
					if(response.status == 200){
						commit('CLASSIFICATION_TREE_VIEW',response.data);
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		getAdminItems({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.getAdminItems(payload).then((response)=>{
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		getPRFAdminItems({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.getPRFAdminItems(payload).then((response)=>{
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		getPrfWithAssets({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.getPrfWithAssets(payload).then((response)=>{
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		getWarehouseItemSelections({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.getWarehouseItemSelections(payload).then((response)=>{
					if(response.status == 200){
						commit('WAREHOUSE_ITEM_SELECTION',response.data);
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		adminAssetsbatchAddition({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.adminAssetsbatchAddition(payload).then((response)=>{
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		adminAssetManagementGet({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.adminAssetManagementGet(payload).then((response)=>{
					resolve(response.data)
				}).catch((error)=>{
				    reject(error)
				});
			})
		},
		adminAssetManagementPost({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.adminAssetManagementPost(payload).then((response)=>{
					resolve(response.data)
				}).catch((error)=>{
				    reject(error)
				});
			})
		},
		async adminAssetManagementUntagged({commit},payload){
			return await new Promise((resolve,reject)=>{
				repository.adminAssetManagementUntagged(payload).then((response)=>{
                    commit('ADMIN_UNTAGGED_ASSETS',response.data)
					resolve(response.data)
				}).catch((error)=>{
				    reject(error)
				});
			})
		},
		async adminAssetManagementPoUntagged({commit},payload){
			return await new Promise((resolve,reject)=>{
				repository.adminAssetManagementPoUntagged(payload).then((response)=>{
                    commit('ADMIN_UNTAGGED_PO_ITEMS_ASSETS',response.data)
					resolve(response.data)
				}).catch((error)=>{
				    reject(error)
				});
			})
		},
		async getReceivedAdminPurchaseOrders({commit},payload){
			return await new Promise((resolve,reject)=>{
				repository.getReceivedAdminPurchaseOrders(payload).then((response)=>{
                    commit('ADMIN_UNTAGGED_PO_ASSETS',response.data)
					resolve(response.data)
				}).catch((error)=>{
				    reject(error)
				});
			})
		},
	},

	mutations:{
		PRINT_ADMIN:(state,data)=>{(state.print_accountability = data)},
		EXPORT_ASSETS:(state,data)=>{state.export_assets = data},
		PRINT_ADMIN_ARF:(state,data)=>{state.print_admin_release_form = data},
		PRINT_ADMIN_ARF_DETAIL:(state,data)=>{state.print_admin_release_form_detail = data},
		PRINT_ADMIN_DETAIL:(state,data)=>{state.print_admin_detail = data},
		LOW_VALUE_ASSET_PRINT:(state,data)=>{state.print_low_value_asset_accountability = data},
		LOW_VALUE_ASSET_PRINT_DETAIL:(state,data)=>{state.print_low_value_asset_accountabilitydetail = data},
		ADMIN_CLASSIFICATION_COUNT:(state,data)=>{state.admin_classification_count = data},
		ADMIN_TABLE_COUNT:(state,data)=>{state.admin_table_count = data},
		EDIT_ADMIN_ASSETS_DETAILS:(state,data)=>{state.edit_admin_assets_details = data},
		NEW_ADMIN_ASSETS_TABLE:(state,data)=>{(state.new_admin_assets_table = data)},
		ASSETS_DATA:(state,data)=>{state.assets_table = data},
		CLASSIFICATION_TREE_VIEW:(state,data)=>{state.classificatreeview = data},
		SELECTED_ADMIN_ITEMS:(state,data)=>{
			state.selected_admin_items = data;
		},
		WAREHOUSE_ITEM_SELECTION:(state,data)=>{
            state.warehouseitemselection = data
        },
		ASSET_MANAGEMENT_BATCH_DIALOG:(state,data)=>{
            state.asset_management_batch_dialog = data
        },
        ADMIN_UNTAGGED_ASSETS:(state,data)=>{
            state.ADMIN_UNTAGGED_ASSETS = data
        },
        ADMIN_UNTAGGED_PO_ITEMS_ASSETS:(state,data)=>{
            state.ADMIN_UNTAGGED_PO_ITEMS_ASSETS = data
        },
        ADMIN_UNTAGGED_PO_ASSETS:(state,data)=>{
            state.ADMIN_UNTAGGED_PO_ASSETS = data
        },
	},
}
