<template>
    <v-container fluid>
        <ComponentHeader
            :button="true"
            :title="'Stock Requests'"
            :type="'Inventory'"
        ></ComponentHeader>
        <ReportBodyComponent>
            <template #first-row>
                <v-card-text>
                <v-row >
                    <v-col lg="3">
                        <v-autocomplete
                        multiple chips deletable-chips small-chips
                            v-model="filters.items.selected"
                            :items="GET_ITEM_SELECTION"
                            :loading="loading.item_selection"
                            :disabled="loading.item_selection"
                            outlined
                            dense
                            background-color="white"
                            label="Select Items"
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-col lg="3">
                        <v-autocomplete
                        multiple chips deletable-chips small-chips
                            v-model="filters.categories.selected"
                            :items="GET_ALL_ITEM_CATEGORIES"
                            :loading="loading.category_selection"
                            :disabled="loading.category_selection"
                            outlined
                            dense
                            item-value="id"
                            item-text="name"
                            background-color="white"
                            label="Select Categories"
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-col lg="3">
                        <v-autocomplete
                        multiple chips deletable-chips small-chips
                            v-model="filters.types.selected"
                            :items="GET_ITEM_TYPES"
                            :loading="loading.item_type_selection"
                            :disabled="loading.item_type_selection"
                            outlined
                            dense
                            background-color="white"
                            label="Select Types"
                        >
                        </v-autocomplete>
                    </v-col>
                </v-row>
                </v-card-text>
            </template>
            <template #second-row>
            <v-card-text>
                <v-row>
                    <v-col lg="2">
                            <v-autocomplete
                            multiple chips deletable-chips small-chips
                                v-model="filters.status.selected"
                                :items="filters.status.selection"

                                outlined
                                dense
                                item-value="value"
                                item-text="text"
                                background-color="white"
                                label="Select Status"
                            >
                            </v-autocomplete>
                    </v-col>
                    <v-col lg="2">
                            <v-autocomplete
                                v-model="filters.warehouses.selected"
                                :items="GET_ALL_WAREHOUSES"
                                item-text="text"
                                item-value="value"
                                placeholder='Requesting Warehouse'
                                label="Select Requesting Warehouse"
                                background-color="white"
                                dense
                                outlined
                                deletable-chips
                                small-chips
                                chips
                                multiple
                            >
                            </v-autocomplete>
                    </v-col>
                </v-row>
                </v-card-text>
            </template>
            <template #third-row>
                <v-row class="ml-1 mt-1">
                    <v-col lg="3">
                        <v-menu
                            v-model="filters.date_from_menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_from"
                                    readonly
                                    outlined
                                        dense
                                        background-color="white"
                                        label="Date From"
                                    append-icon="mdi-calendar-clock"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_from" @input="filters.date_from_menu = false"></v-date-picker>
                        </v-menu>
                        <span v-for="errors in errors.date_from" class="text-warning" v-bind:key="errors">{{errors}}</span>
                    </v-col>
                    <v-col lg="3">
                        <v-menu
                            v-model="filters.date_to_menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_to"
                                    readonly
                                    outlined
                                        dense
                                        background-color="white"
                                        label="Date From"
                                    append-icon="mdi-calendar-clock"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_to" @input="filters.date_to_menu = false"></v-date-picker>
                        </v-menu>
                        <span v-for="errors in errors.date_to" class="text-warning" v-bind:key="errors">{{errors}}</span>
                    </v-col>
                </v-row>
            </template>
        </ReportBodyComponent>
    </v-container>
</template>

<script>
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
import ReportBodyComponent from '@/views/main/layouts/Reports/ReportsBodyComponent.vue'
import { mapGetters } from 'vuex';
    export default {
        data(){
            return{
                errors: [],
                filters:{
                    date_from:this.$dayjs().startOf('month').format('YYYY-MM-DD'),
                    date_to: this.$dayjs().endOf('month').format('YYYY-MM-DD'),
                    date_from_menu: false,
                    date_to_menu: false,
                    items:{
                        selection:null,
                        selected:'',
                    },
                    types:{
                        selected:'',
                        selection:null
                    },
                    categories:{
                        selection:null,
                        selected:null
                    },
                    warehouses:{
                        selection:{

                        },
                        selected:null
                    },
                    status:{
                        selection:[
                            {text:'FOR APPROVAL' ,value:0},
                            {text:'APPROVED' ,value:1},
                            {text:'PREPARING' ,value:2},
                            {text:'COMPLETED' ,value:3},
                            {text:'CANCELLED' ,value:4},
                            {text:'PENDING VARIANCE' ,value:5},
                            {text:'READY TO RECEIVE' ,value:6},
                            {text:'READY FOR RECEIVING' ,value:7},
                            {text:'INCOMPLETE' ,value:8},
                        ]
                        ,
                        selected:null
                    },
                },
                selected:{
                    customers:'',
                    salesman:''
                },

                loading:{
                            item_selection      : true,
                            category_selection  : true,
                            item_type_selection : true,
                            warehouse_selection : true

                }
            }
        },
        mounted(){
            // this.$store.dispatch('getAllitemSelectionDefault')
            this.$store.dispatch('itemselection')
            this.$store.dispatch('getAllItemCategories');
            this.$store.dispatch('getItemTypes');
            this.$store.dispatch('getAllWarehouses');
            // this.getStatuses()
            this.getFilters()
        },
        methods:{
            getFilters(){
                let data = {
                    items: this.filters.items.selected,
                    categories: this.filters.categories.selected,
                    types: this.filters.types.selected,
                    date_from: this.filters.date_from,
                    date_to: this.filters.date_to,
                    status: this.filters.status.selected,
                    filename:`Stock Requests ${this.filters.date_from}`,
                    selected_warehouses: this.filters.warehouses.selected,
                    url: 'stock/request',
                    headers: [
                        { text: 'SRF#', value: 'srf_number', sortable: false },
                        { text: 'DATE', value: 'date', sortable:false },
                        { text: 'MODEL', value: 'item_model', sortable: false },
                        { text: 'NAME', value: 'item_name', sortable: false },
                        { text: 'REQ MB(UOM)', value: 'requested_uom_quantity', sortable: false },
                        { text: 'REM MB(UOM)', value: 'remaining_uom_quantity', sortable: false },
                        { text: 'status', value: 'status', sortable: false },
                        { text: 'REMARKS', value: 'remarks', sortable: true },
                    ],
                }
                return data
            },
            getStatuses(){

                let status = ENUMS.STOCK_REQUEST_STATUS
                let description = ENUMS.STOCK_REQUEST_STATUS_DESCRIPTION
                Object.values(status).forEach((e,i) => {
                    let obj = {
                        text : Object.values(description)[i],
                        value : e
                    }
                    this.filters.status.selection.push(obj)
                });

                let incomplete = {
                    text : 'INCOMPLETE',
                    value : 8
                }

                this.filters.status.selection.push(incomplete)
                this.filters.status.selected = this.filters.status.selection.filter(e=>{
                        return e.value == 8
                })

            },
        },

        computed:{
            ...mapGetters([
                'GET_ITEM_SELECTION',
                'GET_ALL_ITEM_CATEGORIES',
                'GET_ITEM_TYPES',
                'GET_ALL_WAREHOUSES'
                // 'GET_BRAND_DISCOUNT'
            ]),
        },

        components:{
            ComponentHeader,
            ReportBodyComponent,
        },

        watch:{
            'filters':{
                handler(){
                    this.$store.commit('FILTER_VALUE',this.getFilters())
                },
                deep:true
            },
            'GET_ITEM_TYPES':{
                handler(val){
                    this.filters.types.selection = val.data
                    this.loading.item_type_selection    = false
                }
            },
            'GET_ALL_ITEM_CATEGORIES':{
                handler(val){
                    this.filters.categories.selection   = val
                    this.loading.category_selection     = false
                }
            },
            'GET_ITEM_SELECTION':{
                handler(val){
                    this.filters.items.selection    = val
                    this.loading.item_selection     = false
                }
            },
            'GET_ALL_WAREHOUSES':{
                handler(val){
                    this.filters.warehouses.selection   = val
                    this.loading.warehouse_selection    = false
                }
            }
        }
    }
</script>
