<template>
    <v-dialog v-model="GET_EMPLOYEE_BIOMETRICS_DIALOG" persistent width="50%" :retain-focus="false">
        <v-card>
            <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                <span class="text-h5">Employee Biometrics Details</span>
                <v-row class="m-0">
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                            text
                            icon
                            small
                            color="gray"
                            class="float-right"
                            @click="closeDialog()"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-container class="pb-0 my-2" fluid>
                    <v-row>
                        <v-col cols="12" sm="4" class="pa-0 pr-1">
                            <v-text-field
                                v-model="form.employee_name"
                                label="Employee Name"
                                readonly
                                outlined
                                required
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-spacer>
                        </v-spacer>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead class="position-sticky">
                                        <tr>
                                            <th width="25%"> Date</th>
                                            <th width="25%"> Time in </th>
                                            <th width="25%"> Time out </th>
                                        </tr>
                                    </thead>
                                </template>
                            </v-simple-table>
                            <v-card class="overflow-auto" style="height:400px;" elevation="0">
                                <v-simple-table>
                                    <tbody>
                                        <tr v-for="(data, i) in form.biometricdata" :key="i">
                                            <td>
                                                <v-text-field
                                                    v-model="data.date"
                                                    outlined
                                                    dense
                                                    readonly
                                                ></v-text-field>
                                            </td>

                                            <td>
                                                <v-text-field
                                                    v-model="data.time_in"
                                                    outlined
                                                    dense
                                                    readonly
                                                ></v-text-field>
                                            </td>

                                            <td>
                                                <v-text-field
                                                    v-model="data.time_out"
                                                    outlined
                                                    dense
                                                    readonly
                                                ></v-text-field>
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions
                class="text-center"
                style="border-top: 1px solid #dee2e6"
            >
                <v-row class="ma-1 pb-2">
                    <v-col cols="12">
                        <!-- <v-btn
                            @click="actions == 'Submit'? addScheduleTemplate() : updateScheduleTemplate()"
                            :disabled="disabled_view"
                            :loading="loading.button"
                        >
                            <span>{{ actions }}</span>
                        </v-btn> -->
                        <span class="pr-12">&nbsp;</span>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    data(){
        return{
            valid: false,
            overlay: false,
            disabled_view: false,
            errors: {
                required: [(v) => !!v || "Field is required"],
                number: [
                    (v) => !!v || "Field is required",
                    (v) => !isNaN(v) || "Input must be a number",
                ],
            },
            form: {},
        }
    },
    computed:{
        ...mapGetters([
            'GET_EMPLOYEE_BIOMETRICS_DIALOG',
            'GET_EMPLOYEE_BIO_TIME'
        ]),
    },
    mounted(){

    },
    methods:{
        closeDialog(){
            this.$store.commit('EMPLOYEE_BIOMETRICS_DIALOG', false);
        },
        getBiometricDetails(){
            this.form = this.GET_EMPLOYEE_BIO_TIME
        }
    },
    watch:{
        GET_EMPLOYEE_BIOMETRICS_DIALOG:{
            handler(val){
                if(val){
                    this.getBiometricDetails()
                }
            }
        }
    }
}
</script>
<style scoped>

</style>
