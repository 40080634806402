<script>
    export default {
        methods: {

            async checkWarehouseUser(){
                return await new Promise((resolve,reject)=>{
                    this.$store.dispatch('warehouseUser',{
                        url: "warehouseUser",
                        method: "get",
                        checkCurrentUser:true
                    }).then(response =>{
                        resolve(response.data.data[0])
                    }).catch(err=>{
                        console.log(err.response.data)
                        reject(err.response.data)
                    })
                })
            }
        }
    }
</script>
