<template>
    <v-container fluid>
        <h3 class="page-title">JOURNAL VOUCHER</h3>
        <v-container class="ma-0">
            <v-app id="item-tabs">
                <v-tabs
                    v-model="tab"
                    id="group-tab"
                    color="cyan"
                    slider-color="cyan"
                    background-color="#424242"
                    dark
                    show-arrows
                >
                    <v-tabs-slider color="cyan"></v-tabs-slider>
                    <v-tab
                        ripple
                        v-for="(item, i) in items"
                        :key="i"
                        @click="tabs(i)"
                    >
                        {{ item.tab_name }}
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item v-for="(item, i) in items" :key="i">
                        <keep-alive>
                            <component
                                :is="journal_voucher_items.components"
                                :journal_voucher_items="
                                    journal_voucher_items
                                "
                                :isActive_tab="item.tab_name"
                            >
                            </component>
                        </keep-alive>
                    </v-tab-item>
                </v-tabs-items>
            </v-app>
        </v-container>
        <DialogComponent
            :cp="components"
            :scrollable="dialog_scrollable"
            :width="width(dialog_width)"
            :retainfocus="false"
        ></DialogComponent>
        <v-dialog v-model="GET_JOURNAL_VOUCHER_DIALOG" :width="width(dialog_width)" :retain-focus="false" :scrollable="false" class="x-scroll">
                <JournalVoucherViewComponent/>
        </v-dialog>
    </v-container>
</template>

<script>
import DialogComponent from "@/views/main/layouts/dialogs/Dialog.vue";
import JournalVoucherComponent from "@/views/main/modules/Accounting/JournalVoucher/JournalVoucherComponent.vue";
import JournalVoucherViewComponent from "@/views/main/layouts/dialogs/Accounting/JournalVoucherViewComponent.vue";
import { mapGetters } from "vuex";

export default {
    components: {
        DialogComponent,
        JournalVoucherComponent,
        JournalVoucherViewComponent,
    },
    data() {
        return {
            form: {
                code: "",
                name: "",
                sub_class: [],
                sub_class_trash: [],
            },
            components: "",
            items: [
                {tab_name:"ALL",view:true},
                {tab_name:"PENDING",view:true},
                {tab_name:"APPROVED",view:true},
                {tab_name:"REJECTED",view:true},
                {tab_name:"CANCELLED",view:true},
            ],
            journal_voucher_items: {
                components: JournalVoucherComponent,
                dialog: JournalVoucherViewComponent,
                dialog_title:'Journal Voucher',
                status_text:"ALL",
                dialog_width: "90%",
                scrollable:false,
            },
            dialog_width: "",
            loaded: false,
            options: {},
            itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
            itemsPerPage: 10,
            tab: null,
            dialog_scrollable: true,
        };
    },
    computed: {
        ...mapGetters([
            'GET_JOURNAL_VOUCHER_DIALOG',
        ]),
    },
    mounted() {
        this.dropDowns();
        this.tabs(0);
    },
    methods: {
        dropDowns() {
            this.$store.dispatch('getAdminSupplierSelection');
            this.$store.dispatch('getCOASelection');
            this.$store.dispatch('getDepartmentListforAccounting');
            this.$store.dispatch('getAccountingJobSelection');
            this.$store.dispatch('getCompanySelection');
            this.$store.dispatch('getPvCompanies');
            this.$store.dispatch('GetPreparedBy',{url: 'get-prepared-by', module: 'JournalVouchers'});
            this.$store.dispatch('getCompanyClassificationDropdown')
        },
        tabs(key) {
            this.components = this.journal_voucher_items.dialog;
            this.dialog_width = this.journal_voucher_items.dialog_width;
            this.dialog_scrollable = this.journal_voucher_items.scrollable;
            this.$store.commit("JOURNAL_VOUCHER_STATUS",this.items[key]);
        },
        width(width) {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return "100%"
                case 'sm': return "100%"
                case 'md': return "100%"
                case 'lg': return "100%"
                case 'xl': return width
            }
        },
    },
    watch: {},
};
</script>
<style>
#group-tab {
    padding: 0 !important;
}

.x-scroll {
    overflow-x: hidden;
}
</style>
