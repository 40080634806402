<template>
    <v-container>
        <v-btn class="mb-4" @click="dialog('Add','')">
            <v-icon>mdi-plus</v-icon>Add
        </v-btn>
        <v-card elevation="0">
            <v-card-title class="d-flex justify-content-end align-items-end p-0 m-0">  
                        <div class="w-25 p-0 m-0 d-flex justify-content-center align-items-center">
                            <v-text-field v-model="filters.search" label="Search" single-line hide-details @keydown.enter="searchRepairType()"> </v-text-field>
                            <v-icon @click="searchRepairType()" class="pt-5">mdi-magnify</v-icon>
                        </div>         
            </v-card-title>
            <v-data-table
                :headers="form.headers"
                :items="form.items"
                :loading="loading"
                :server-items-length="form.totalItems"
                :options.sync="filters.options"
                loading-text="Loading...Please wait"
                class="w-100"
                dense
                :page.sync="form.page"
                :items-per-page.sync="form.itemsPerPage"
                >
                <template v-slot:[`item.action`]="{ item }">
                    <span class="text-nowrap">
                            <template>
                                <v-btn text icon color="orange" @click="dialog('View', item)">
                                    <v-icon class="btn-action" small >mdi-eye
                                    </v-icon>
                                </v-btn>
                            </template>
                            <template>
                                <v-btn text icon color="blue" @click="dialog('Edit', item)">
                                    <v-icon class="btn-action" small >mdi-pencil
                                </v-icon>
                                </v-btn>
                            </template>
                            <template>
                                <v-btn text icon color="red" @click="deleteRepairType(item)">
                                <v-icon class="btn-action" small>mdi-delete
                                </v-icon>
                            </v-btn>
                            </template>
                    </span>
                </template>
            </v-data-table>
        </v-card>
    </v-container>
    
  </template>
  <script>

    import { mapGetters } from 'vuex';
    import RepairTypeDialogComponent from './RepairTypeDialogComponent.vue';
    import swal from "sweetalert2";

    export default {
    components: {
        RepairTypeDialogComponent
    },
    data() {
      return {
        form: {
            items:[],
            headers: [
                { text: 'Code', align: 'left', value: 'code' },
                { text: 'Name', align: 'left', value: 'text' },
                { text: 'Type', align: 'left', value: 'type' },
                { text: 'Actions', align: 'center', value: 'action', sortable: false }
            ],
            page:1,
            itemsPerPage:10,
            totalItems:0,
        },
        filters:{
                search:'',
                option:{}
        },
        search: '',
        userAccess: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
        loading:false,
      };
    },
    mounted() {
    this.$store.dispatch("getAllRepairType");
    this.$store.dispatch(
        "checkAccessUser",
        this.$router.currentRoute.params.id
        );
    this.list();
    },
    computed: {
      ...mapGetters([
        'GET_ALL_REPAIR_TYPE',
        "USER_ACCESS",
        "REPAIR_TYPE",
        "DIALOGS",
        "ACTION",
        "SELECTED",
    ]),
    },
    methods: {
        searchRepairType(){
            this.list();
        },
        async list() {
            this.form.items = [];
            this.loading = true;
            await this.$store.dispatch("getAllRepairType", {
                page: this.form.page,
                itemsPerPage: this.form.itemsPerPage,
                search: this.filters.search,
            }).then(response => {
                this.form.items = response.data.data.data.map(item => ({
                    code: item.code,
                    text: item.text,
                    type: item.type,
                    value: item.value,
                }));
                this.form.totalItems = response.data.total;
            this.loading = false;
            }).catch(error => {
            console.error("Error fetching repair types:", error);
            this.loading = false;
            });
        },
        dialog(action, item){
            this.$store.commit('ACTION',action);
            this.$store.commit('SELECTED',item.value);
            this.$store.commit('DIALOG',true);
            // this.list();
        },
        deleteRepairType(item) {
        swal.fire({
            icon: 'question',
            text: "Are you sure you want to Delete?",
            cancelButtonText: "Cancel",
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonColor: "#397373",
            cancelButtonColor: "#397373",
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch("EnterRepairTypeGet", {
                    url: 'get-repair-type-delete/' + item.value,
                    }).then(response=>{
                        this.list();
                        swal.fire('Success', '', 'success');
                    });
                }
            });
        },
    },
    watch: {
        DIALOGS:{
            handler(val){
                if(!val){
                    this.list();
                }
                // this.list();
            },
            deep: true
        },
        'filters.option': {
            handler(val){
                this.list();
            }
        },
        'form.page'(val) {
        this.list(val, this.form.itemsPerPage);
        },
        'form.itemsPerPage'(val) {
            this.list(this.form.page, val);
        },
    }
  };
  </script>
  