<template>
  <v-card>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card-title
            class="d-flex justify-content-end pa-0 border-bottom border-right"
          >
            <v-btn icon text @click="addAPI()">
              <v-icon color="primary">mdi-plus-circle</v-icon>
            </v-btn>
          </v-card-title>
          <v-simple-table fixed-header height="300px" class="border-right">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left" width="15%">Https</th>
                  <th class="text-left">Host</th>
                  <th class="text-left">Active</th>
                  <th class="text-left">Status</th>
                  <th class="text-left">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in form.api_list" :key="i">
                  <td>
                    <v-switch v-model="item.protocol" dense color="orange darken-1"></v-switch>
                  </td>
                  <td>
                    <v-text-field
                      v-model="item.host"
                      dense
                      outlined
                    ></v-text-field>
                  </td>
                  <td>
                    <v-switch
                      v-model="item.active"
                      dense
                    ></v-switch>
                  </td>
                  <td>
                  <v-chip
                    :color="
                      item.status == true? 'green accent-3': 'red darken-1'
                    "
                    x-small
                    text-color="white"
                  >
                    {{ item.status == true ?'Online':'Offline'  }}
                  </v-chip>
                 
                </td>
                  <td>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          text
                          v-bind="attrs"
                          v-on="on"
                          :disabled="item.id?false:true"
                          @click="viewPorts(i)"
                        >
                          <v-icon color="green">mdi-lan</v-icon>
                        </v-btn>
                      </template>
                      <span>Ports</span>
                    </v-tooltip>

                    <v-btn icon text @click="removeHost(i)">
                      <v-icon color="red">mdi-minus-circle</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-dialog
            v-model="ports_dialog"
            persistent
            width="70%"
            :scrollable="true"
          >
            <v-card height="600px">
              <v-card-title
                class="d-flex justify-content-end pa-0 border-bottom border-right"
              >
                <v-btn icon text @click="closePorts()">
                  <v-icon color="dark">mdi-close-circle</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-container fluid>
                  <v-row>
                    <v-col class="pa-0">
                      <p>
                        <span class="font-weight-bold">HOST:</span>
                        {{ edit_host["host"] }}
                      </p>
                      <p>
                        <span class="font-weight-bold">PROTOCOL:</span>
                        {{ edit_host["protocol"] ? "Https" : "Http" }}
                      </p>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-card height="390px">
                        <v-card-title
                          class="d-flex justify-content-end pa-0 border-bottom border-right"
                        >
                          <v-btn icon text @click="addPort()">
                            <v-icon color="primary">mdi-plus-circle</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text>
                          <v-simple-table fixed-header height="340px">
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left" width="50%">
                                    Location
                                  </th>
                                  <th class="text-left" width="10%">Port</th>
                                  <th class="text-left" width="10%">Status</th>
                                  <th class="text-left">Active</th>
                                  <th class="text-left">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(item, i) in form.port_list"
                                  :key="i"
                                >
                                  <td>
                                    <v-combobox
                                      v-model="item.location"
                                      :items="GET_BIO_METRIC_LOCATION_SELECTION"
                                      multiple
                                      outlined
                                      chips
                                      small-chips
                                      cache-items
                                      deletable-chips
                                      dense
                                      auto-select-first
                                    >
                                      <template
                                        v-slot:selection="{
                                          attrs,
                                          item,
                                          selected,
                                        }"
                                      >
                                        <v-chip
                                          close
                                          v-bind="attrs"
                                          :input-value="selected"
                                          @click:close="removeLocation(item, i)"
                                          small
                                          dark
                                          color="#397373"
                                        >
                                          <strong>{{ item.text?item.text:item }}</strong>
                                        </v-chip>
                                      </template>
                                    </v-combobox>
                                  </td>
                                  <td>
                                    <v-text-field
                                      v-model="item.port"
                                      dense
                                      outlined
                                      maxlength="4"
                                    ></v-text-field>
                                  </td>
                                  <td>
                                    <v-chip
                                      :color="
                                        item.status
                                          ? 'green accent-3'
                                          : 'red darken-1'
                                      "
                                      x-small
                                      text-color="white"
                                    >
                                      {{ item.status ? "Active" : "Down" }}
                                    </v-chip>
                                  </td>
                                  <td>
                                    <v-switch
                                      v-model="item.active"
                                      @change="findActive(i)"
                                      dense
                                    ></v-switch>
                                  </td>
                                  <td>
                                    <v-btn icon text @click="removePort(i)">
                                      <v-icon color="red"
                                        >mdi-minus-circle</v-icon
                                      >
                                    </v-btn>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-container class="pa-5 border-top">
                  <v-row class="d-flex justify-content-center pa-0">
                    <v-btn small dense @click="savePortSettings()">
                      SAVE
                    </v-btn>
                  </v-row>
                </v-container>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-container class="pa-5 border-top">
        <v-row class="d-flex justify-content-end pa-0">
          <v-btn small dense @click="saveBioSettings()"> SAVE </v-btn>
        </v-row>
      </v-container>
    </v-container>
  </v-card>
</template>

<script>
import swal from "sweetalert2";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      form: {
        api_list: [],
        password_trash: [],
        password_expiration: 0,
        port_list: [],
        port_trash: [],
        host_trash:[]
      },
      samples:[],
      disabled: false,
      ports_dialog: false,
      edit_host: {},
      location_trash: [],
    };
  },
  computed: {
    ...mapGetters([
      "GET_BIO_API_SETTINGS",
      "GET_BIO_METRIC_LOCATION_SELECTION",
      "GET_NEW_BIO_PORT_SETTINGS",
      "GET_NEW_BIO_API_SETTINGS"
    ]),
  },
  created() {
    if (this.form.api_list.length == 0) {
      this.addAPI();
    }
  },
  mounted() {
    this.$store.dispatch("getBioApiSettings");
  },
  methods: {
    addAPI() {
      this.form.api_list.push({
        id: "",
        protocol: 0,
        host: "",
        active: 0,
        ports: [],
        status:0
      });
    },
    removeHost(index) {
      this.form.host_trash.push(this.form.api_list[index])
      this.form.api_list.splice(index, 1);
      if (this.form.api_list.length == 0) {
        this.addAPI();
      }
    },
    saveBioSettings() {
      if (this.form.api_list.find((e) => e.host == "")) {
        swal.fire({
          icon: "warning",
          text: "There's have an empty host",
        });
      }
      //  else if (
      //   this.form.api_list.find((e) => e.active == true) == undefined
      // ) {
      //   swal.fire({
      //     icon: "warning",
      //     text: "Please active atleast one on the switch.",
      //   });
      // } 
      else {
        this.$store.dispatch("BioApiSettings", this.form);
        this.$store.dispatch("getBioApiSettings");
      }
    },
    findActive(i) {
      this.form.api_list.find((e, x) => {
        if (x == i) {
          e.active = true;
        } else {
          e.active = false;
        }
      });
    },
    viewPorts(index) {
      this.form.port_list = [];
      this.location_trash = [];
      this.form.port_trash = [];
      this.$store
        .dispatch("getPortSettings", {
          hostID: this.form.api_list[index]["id"],
        })
        .then((response) => {
          response.forEach((e) => {
            e.hostID = this.edit_host["id"];
          });
          this.form.port_list = response;

          if (this.form.port_list.length == 0) {
        this.addPort();
      }
        });
      this.$store.dispatch("getBioMetricLocationSelection");
      this.ports_dialog = true;
      this.edit_host = this.form.api_list[index];
   
    },
    closePorts() {
      this.form.port_list = [];
      this.location_trash = [];
      this.form.port_trash = [];
      this.ports_dialog = false;
    },
    addPort() {
      this.form.port_list.push({
        hostID: this.edit_host["id"],
        id: "",
        location: [],
        port: "",
        status: 1,
        location_trash: [],
      });
    },
    savePortSettings() {
      this.$store.dispatch("BioPortSettings", this.form);
    },
    removePort(index) {
      this.form.port_trash.push(this.form.port_list[index])
      this.form.port_list.splice(index, 1);
      if (this.form.port_list.length == 0) {
        this.addPort();
      }
    },
    removeLocation(item, i) {
      this.location_trash.push(item);
      let index = this.form.port_list[i]["location"].indexOf(item);
      this.form.port_list[i].location_trash = this.location_trash;
      this.form.port_list[i]["location"].splice(index, 1);
    },
  },
  watch: {
    GET_NEW_BIO_API_SETTINGS: {
      handler(val) {
        if (val) {
          Object.assign(this.$data, this.$options.data.call(this));
          swal.fire({
            icon: "success",
            text: "Host Settings Saved!",
          });
          this.$store.commit("NEW_BIO_API_SETTINGS", false);
        }
      },
    },
    GET_BIO_API_SETTINGS: {
      handler(val) {
        this.form.api_list = val;
        if (val.length > 0) {
          val.forEach((e) => {
            let that = e;
            let self = this
            self.form.api_list.find(sm => sm.id == that.id).status = false
              e.port_a_p_i.forEach( el=>{
                let protocol = e.protocol == 1? "https" : "http";
                let port = el.ports;
                   fetch(`${protocol}://${e.host}:${port}`,{method:'GET',mode:'no-cors'}).then(response => {
                      if(!response.ok){
                         self.form.api_list.find(sm => sm.id == that.id).status = true
                         let tmp = self.form.api_list
                         self.form.api_list = []
                         self.form.api_list = tmp
                      }else{
                        self.form.api_list.find(sm => sm.id == that.id).status = false
                      }
                    }).catch(error => {
                   });
              })
              e.protocol = e.protocol == "https" || e.protocol == 1? 1 : 0;
          });
        }
      },
    },
    GET_NEW_BIO_PORT_SETTINGS:{
      handler(val){
        if (val) {
          Object.assign(this.$data, this.$options.data.call(this));
          this.$store.dispatch("getBioApiSettings");
          swal.fire({
            icon: "success",
            text: "Port Settings Saved!",
          });
          
          this.$store.commit("NEW_BIO_PORT_SETTINGS", false);
        }
      }
    },
    // samples:{
    //   handler(val){
    //     console.log(val)
    //     this.form.api_list = []
    //     this.form.api_list = this.GET_BIO_API_SETTINGS
    //     // this.$store.commit('BIO_API_SETTINGS',[])
    //   }
    // }
  },
};
</script>
