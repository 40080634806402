import repository from '@/api/modules/Admin/adminFasRequisition.js'
export default {
	state: {
        admin_fasrequisition_dispatch:{},
        admin_fasrequisition_status:null,
        new_admin_fasrequisition:false,
        edit_admin_fasrequisition:[],
        edit_fas_requisition:{},
        fas_requisition:{},
        fas_requisition_print:false,
        fas_add_types:'',
        admin_fasrequisition_items:[]
	},
	getters: {
      GET_ADMIN_FASREQUISITION_DISPATCH:(state)=> state.admin_fasrequisition_dispatch,
      GET_ADMIN_FASREQUISITION_STATUS:(state)=> state.admin_fasrequisition_status,
      GET_NEW_ADMIN_FASREQUISITION:(state)=> state.new_admin_fasrequisition,
      GET_EDIT_ADMIN_FASREQUISITION:(state)=> state.edit_admin_fasrequisition,
      GET_EDIT_FAS_REQUISITION:(state)=> state.edit_fas_requisition,
      GET_PRINT_FAS_REQUISITION_DETAIL:(state)=>state.fas_requisition,
      GET_PRINT_FAS_REQUISITION:(state)=>state.fas_requisition_print,
      GET_FAS_ADD_TYPES:(state)=>state.fas_add_types,
      GET_ADMIN_FASREQUISITION_ITEMS:(state)=>state.admin_fasrequisition_items
	},
	actions:{
         createFasRequisition({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.createFasRequisition(payload).then((response)=>{
                    commit('NEW_ADMIN_FASREQUISITION',true)
                    resolve(response);
                }).catch((err)=>{
                    reject(err);
                })
            })
         },
         updateFasRequisition({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.updateFasRequisition(payload).then((response)=>{
                    commit('NEW_ADMIN_FASREQUISITION',true)
                    resolve(response);
                }).catch((err)=>{
                    reject(err);
                })
            })
          },
          deleteFasRequisition({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.deleteFasRequisition(payload).then((response)=>{
                    commit('NEW_ADMIN_FASREQUISITION',true)
                    resolve(response);
                }).catch((err)=>{
                    reject(err);
                })
            })
          },
          getAllFasRequisition({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.getAllFasRequisition(payload).then((response)=>{
                    resolve(response);
                }).catch((err)=>{
                    reject(err);
                })
            })
         },
         showFasrequisition({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.showFasrequisition(payload).then((response)=>{
                    commit('EDIT_ADMIN_FASREQUISITION',response.data);
                    resolve(response);
                }).catch((err)=>{
                    reject(err);
                })
            })
         },
         getPreviousData({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.getPreviousData(payload).then((response)=>{
                    resolve(response);
                }).catch((err)=>{
                    reject(err);
                })
            })
         },
         getFasItems({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.getFasItems(payload).then((response)=>{
                    commit('ADMIN_FASREQUISITION_ITEMS',response.data);
                    resolve(response);
                }).catch((err)=>{
                    reject(err);
                })
            })
         },
         approveAllFasRequisition({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.approveAllFasRequisition(payload).then((response)=>{
                    commit('NEW_ADMIN_FASREQUISITION',true);
                    resolve(response);
                }).catch((err)=>{
                    reject(err);
                })
            })
         },
	},
	mutations:{
        ADMIN_FASREQUISITION_DISPATCH:(state,data)=>{
            state.admin_fasrequisition_dispatch = data
        },
        ADMIN_FASREQUISITION_STATUS:(state,data)=>{
            state.admin_fasrequisition_status = data
        },
        NEW_ADMIN_FASREQUISITION:(state,data)=>{
            state.new_admin_fasrequisition = data
        },
        EDIT_ADMIN_FASREQUISITION:(state,data)=>{
            state.edit_admin_fasrequisition = data
        },
        EDIT_FAS_REQUISITION:(state,data)=>{
            state.edit_fas_requisition = data
        },
        PRINT_FAS_REQUISITION_DETAIL:(state,data)=>{
            state.fas_requisition = data
        },
        PRINT_FAS_REQUISITION:(state,data)=>{
            state.fas_requisition_print = data
        },
        FAS_ADD_TYPES:(state,data)=>{
            state.fas_add_types = data
        },
        ADMIN_FASREQUISITION_ITEMS:(state,data)=>{
            state.admin_fasrequisition_items = data
        }
    },
}
