<template>
    <div class="pb-4" style="width:100%">
        <v-card class="pa-2" style="border-radius:0px">
            <div>
                <v-layout row class="mx-0 pb-4 mt-2">
                    <v-flex lg2 class="mr-2">
                        <v-autocomplete
                            dense
                            outlined
                            :items="items"
                            v-model="selectedItem"
                            item-value="value"
                            item-text="text"
                            label="Select Item"
                            auto-select-first
                        />
                    </v-flex>
                    <v-flex lg3 shrink>
                        <div>
                            <!-- <v-btn @click="clearFilter()">Clear</v-btn> -->
                            <!-- <v-btn @click="showExportDialog()">EXPORT</v-btn> -->
                            <!-- <v-btn @click="filterList()">Filter</v-btn> -->
                        </div>
                    </v-flex>
                    <v-spacer />
                    <v-flex shrink>
                         <span v-if="baklasStatus == 'recovery'">
                            <a :class="`ml-1 badge badge-all ${selected_status === -1 ? 'selected' : 'non-selected'}`" @click="selected_status = -1; selectStatus(-1);">all</a>
                            <a :class="`ml-1 badge badge-info ${selected_status === 1 ? 'selected' : 'non-selected'}`" @click="selectStatus(1);">recovery</a>
                            <a :class="`ml-1 badge badge-recovered ${selected_status === 0 ? 'selected' : 'non-selected'}`" @click="selectStatus(0);">recovered</a>
                        </span>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    small
                                    v-on="on"
                                    class="ma-2"
                                    v-bind="attrs"
                                    color="#f69f1a"
                                    :loading="exportLoading"
                                    @click="exportToExcel"
                                >
                                    <v-icon>mdi-printer-pos-wrench</v-icon>
                                    <span class="ml-2">Export</span>
                                </v-btn>
                            </template>
                            <span>Export Baklas</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    small
                                    v-on="on"
                                    class="mr-2"
                                    v-bind="attrs"
                                    color="#f69f1a"
                                    :loading="printLoading"
                                    @click="printBaklasItems"
                                >
                                    <v-icon>mdi-printer</v-icon>
                                    <span class="ml-2">Print</span>
                                </v-btn>
                            </template>
                            <span>Print Baklas Items</span>
                        </v-tooltip>
                    </v-flex>
                    <v-flex shrink>
                        <!-- <div style="text-align:end">
                            <span class="badge badge-primary mr-2"  style="cursor:pointer" @click="getAllBaklas()">All</span> -->
                            <!-- <span class="badge badge-primary  mr-2" style="cursor:pointer" @click="getBaklasWithStatus('new')">New</span>
                            <span class="badge badge-success  mr-2" style="cursor:pointer" @click="getBaklasWithStatus('available')">Available</span>
                            <span class="badge badge-danger  mr-2" style="cursor:pointer" @click="getBaklasWithStatus('consumed')">Consumed</span> -->
                        <!-- </div> -->
                    </v-flex>
                </v-layout>
            </div>
            <div>
                <v-layout row class="mx-0">
                    <v-spacer />
                    <v-flex lg3 class="mr-2 ">
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Baklas#, RF#, Status, Date"
                            hide-details
                            clearable
                            outlined
                            dense
                            @keydown.enter="searchSp()"
                            @click:append="searchSp()"
                        />
                    </v-flex>
                    <v-flex lg3 class="mr-2">
                        <v-text-field
                            v-model="searchSparePart"
                            append-icon="mdi-magnify"
                            label="Spare Part, Part Num"
                            hide-details
                            clearable
                            @keydown.enter="searchSp()"
                            @click:clear="clearSearchSparePart()"
                            outlined
                            dense
                            @click:append="searchSp()"
                        />
                    </v-flex>
                </v-layout>
            </div>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="baklas"
                    :search="search"
                    :loading="loading"
                    :options.sync="options"
                    :server-items-length="totalItems"
                    :items-per-page="itemsPerPage"
                >
                <template v-slot:item.item_model="{ item }">
                    {{ itemModel(item) }}
                </template>

                <template v-slot:item.status="{ item }">

                    <span v-if="item.status == 'new'" class="badge badge-primary">{{ item.status }}</span>
                    <span v-else-if="item.status == 'available'" class="badge badge-success">{{ item.status }}</span>
                    <span v-else-if="item.status == 'consumed'" class="badge badge-danger">{{ item.status }}</span>
                    <span v-else-if="item.status == 'not-available'" class="badge badge-info">{{ item.status }}</span>
                    <span v-else-if="item.status == 'disposed'" class="badge badge-warning text-white">{{ item.status }}</span>
                    <!-- <span v-else-if="item.status == 'recovery'" class="badge badge-recovery text-white">{{ item.status }}</span>
                    <span v-else-if="item.status == 'recovered'" class="badge badge-recovered text-white">{{ item.status }}</span> -->
                </template>

                <template v-slot:item.created_at="{ item }">
                    {{ date(item) }}
                </template>

                <template v-slot:item.action="{ item }">
                    <td class="px-0">
                        <span class="text-nonwrap">
                            <v-btn text icon color="orange">
                                <v-icon class="btn-action" small @click="showBaklasDetailsDialog(item)">mdi-eye</v-icon>
                            </v-btn>
                            <v-btn text icon color="blue" v-if="userAccess.update">
                                <v-icon class="btn-action" small @click="showEditBaklasDialog(item)" >mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn v-if="showBaklasDelete(item) && item.status != 'consumed' && userAccess.delete" text icon color="red" >
                                <v-icon class="btn-action" small @click="deleteBaklas(item)">mdi-trash-can</v-icon>
                            </v-btn>
                        </span>
                    </td>
                </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <!-- <baklas-details-dialog :baklasDetails="baklasDetails" @closeShowDialog="closeShowDialog" @setSuccessfull="setSuccessfull" @successTransfer="successTransfer"/> -->

        <new-baklas-details-dialog :baklasDetails="baklasDetails" @closeShowDialog="closeShowDialog" @setSuccessfull="setSuccessfull" @successTransfer="successTransfer"/>

        <v-dialog v-model="editBaklasDialog" persistent max-width="40%" scrollable>
            <v-card class="pa-4">
                <v-row class="m-0">
                    <v-col>
                        <v-card-title class="px-0">
                            <span class="headline">Edit Baklas</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                            text
                            icon
                            color="gray"
                            class="float-right"
                            @click="closeEditBaklasDialog()"
                        >
                        <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider />

                <div>Serial Number: <span style="color:red">*</span></div>
                <div>
                    <v-text-field v-model="itemSerialNumber" :rules="rules.default" dense outlined></v-text-field>
                </div>

                <v-divider />

                <div style="text-align:center">
                    <v-btn @click="showOverrideTransfer()">Submit</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="exportDialog" persistent max-width="30%">
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Export Options</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeExportDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider />

                <v-layout row class="mx-0">
                    <v-flex lg6>
                        <div style="text-align:center">
                            <v-btn @click="exportExcel(exportBaklasHeadersAndData, exportBaklasName)">
                                Export Baklas
                            </v-btn>
                        </div>
                    </v-flex>
                    <v-flex lg6>
                        <div style="text-align:center">
                            <v-btn @click="exportExcel(exportBaklasItemsHeadersAndData, exportBaklasItemsName)">Export Baklas Items</v-btn>
                        </div>
                    </v-flex>
                </v-layout>

                <v-divider />

                <div class="pb-4"></div>
            </v-card>
        </v-dialog>

        <ConfirmOverrideComponentVue
            :approve_override_dialog="override.override_dialog"
            :departments_allowed="override.override_departments_allowed"
            :heading="'Edit'"
            @closeConfirmOverride="closeConfirmOverride"
        ></ConfirmOverrideComponentVue>

    </div>
</template>

<script>
// import BaklasDetailsDialog from './BaklasDetailsDialogComponent.vue';
import NewBaklasDetailsDialog from './NewBaklasDetailsDialog.vue';
import ShowBaklasDialog from './ShowBaklasDialogComponent.vue';
import ConfirmOverrideComponentVue from '@/views/main/Utils/ConfirmOverrideComponent.vue';

import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue';
export default {
    mixins: [SharedFunctionsComponent],
    data() {
        return {
            headers: [
                { text: 'Baklas#', value: 'baklas_number' },
                { text: 'RF#', value: 'rf_number' },
                { text: 'Source', value: 'source' },
                { text: 'Model', value: 'model' },
                { text: 'Name', value: 'name' },
                { text: 'Category', value: 'category' },
                { text: 'Status', value: 'status' },
                { text: 'Item Status', value: 'item_status' },
                { text: 'Created At', value: 'created_at' },
                { text: 'Action', value: 'action' },
            ],
            baklas: [],
            search: '',
            baklasDetails: null,
            editBaklasDialog: false,
            baklasId: '',
            itemSerialNumber: '',
            itemId: '',
            items: [],
            selectedItem: '',
            selectedItemModel: '',
            loading: true,
            baklasStatus: 'new',
            searchSparePart: '',
            itemsPerPage: 10,
            totalItems: 0,
            options:{},

            override: {
                override_dialog: false,
                errors: [],
                override_departments_allowed: ['SR', 'EX', 'IT'],
                override_authenticated: false,
            },
            exportDialog: false,
            dateToday: this.$dayjs().format('YYYY-MM-DD'),
            exportBaklasHeadersAndData: {
                Sheet1: {
                    headers:[
                        {header:'Baklas#', key:'baklas_number', width:15},
                        {header:'Model', key:'model', width:15},
                        {header:'Serial Number', key:'serial_number', width:15},
                        {header:'Status', key:'status', width:15},
                        {header:'Item Status', key:'item_status', width:15},
                        {header:'Created At', key:'created_at', width:15},
                    ],
                    data:[]
                }
            },
            exportBaklasItemsHeadersAndData: {
                Sheet1: {
                    headers:[
                        {header:'Baklas#', key:'baklas_number', width: 15},
                        {header:'Model', key:'sp_model', width: 20},
                        {header:'Name', key:'sp_name', width: 28},
                        {header:'Original Qty', key:'original_qty', width: 15},
                        {header:'Remaining Qty', key:'remaining_qty', width: 15},
                        {header:'Transferred Qty', key:'transfered_qty', width: 15},
                        {header:'Defective Qty', key:'defective_qty', width: 15},
                        {header:'Transferred By', key:'transferred_by', width: 22},
                    ],
                    data:[]
                }
            },
            exportBaklasLoading: false,
            exportBaklasItemsLoading: false,
            exportBaklasName: `Baklas-${this.$dayjs().format('YYYY-MM-DD')}`,
            exportBaklasItemsName: `Baklas-Items-${this.$dayjs().format('YYYY-MM-DD')}`,
            userAccess: {
                view: false,
                edit: false,
                delete: false,
                assign: false,
            },
            forPrintingDetails: {
                header: {
                    form_title:'BAKLAS ITEMS STOCK REQUEST/TRANSFER',
                    header_details: []
                },
                body: [],
                footer: [],
            },
            isForHardCopy: false,
            exportLoading: false,
            printLoading: false,
            exportHeaders: [
                { header: 'Baklas#', key: 'baklas_number', width: 10 },
                { header: 'RF#', key: 'rf_number', width: 10 },
                { header: 'Source', key: 'source', width: 15 },
                { header: 'Model', key: 'model', width: 15 },
                { header: 'Name', key: 'name', width: 45 },
                { header: 'Category', key: 'category', width: 20 },
                { header: 'Status', key: 'status', width: 10 },
                { header: 'Item Status', key: 'item_status', width: 15 },
                { header: 'Created At', key: 'formatted_created_at', width: 25 },
            ],
            rules: {
                default: [
                v => !!v || 'This is required',
                ],
            },
            // selected_status: -1,
        }
    },
    components: {
        // BaklasDetailsDialog,
        NewBaklasDetailsDialog,
        ShowBaklasDialog,
        ConfirmOverrideComponentVue,
    },
    props: ['baklasCreateSuccess', 'tab'],
    mounted: function() {
        this.getAllPtItems();
        this.$store.dispatch("checkAccessUser", this.$router.currentRoute.params.id);
    },
    computed:{
        ...mapGetters([
            'GET_ITEMS',
            'GET_ALL_BAKLAS',
            'GET_ALL_BAKLAS_ITEMS',
            'USER_ACCESS'
        ])
    },
    created: function() {
        this.getAllBaklas();
    },
    watch: {
        tab() {
            if(!!this.tab){
                this.baklasStatus = this.tab;
            }
        },
        baklas() {
            if(this.baklas.length > 0) {
                this.loading = false;
            } else {
                this.loading = true;
                setTimeout(()=>{
                    this.loading = false;
                    this.noDataText = "No data for Baklas";
                }, 300);
            }
        },
        baklasStatus() {
            this.selectedItem = '';
            // this.baklas = [];
            this.getAllBaklas();
        },
        baklasCreateSuccess() {
            if(!!this.baklasCreateSuccess) {
                this.getAllBaklas();
            }
        },
        selectedItem() {
            if(!!this.selectedItem) {
                let item = this.items.find(x=>x.value == this.selectedItem);

                this.selectedItemModel = item.text;
                this.itemId = this.selectedItem;
                this.getAllBaklas();
            }
        },
        USER_ACCESS: {
            handler(val) {
                if(val != "fail"){
                    this.userAccess.update = false;
                    this.userAccess.delete = false;
                    this.userAccess.view = false;
                    this.userAccess.create = false;

                val.forEach(e => {
                    if (e.actions_code == 'update') {
                        this.userAccess.update = true;
                    } else if (e.actions_code == 'delete') {
                        this.userAccess.delete = true;
                    } else if (e.actions_code == 'view') {
                        this.userAccess.view = true;
                    } else if (e.actions_code == 'create') {
                        this.userAccess.create = true;
                    }
                });
                }
            },
        },
        options: {
            handler (val) {
                if(val.itemsPerPage < 0){
                    val.itemsPerPage = this.totalItems;
                }
                this.getAllBaklas();
            },
            deep: true,
        },
    },
    methods: {
        clearFields() {
            this.search = '';
            this.baklasId = '';
            this.itemSerialNumber = '';
            this.baklasDetails = null;
            this.editBaklasDialog = false;
            this.itemId = '';
            this.selectedItem = '';
        },
        getAllBaklas() {
            this.loading = true
            // this.baklas = [];
            let payload = {
                itemId: this.selectedItem,
                searchItem: this.selectedItemModel,
                searchSp: this.searchSparePart,
                search: this.search,
                baklasStatus: this.baklasStatus,
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                // selectStatus: this.selected_status,
            }
            this.$store.dispatch('getAllBaklas',payload).then(response=>{
                this.baklas = response.data.data.data;
                this.totalItems = response.data.data.total
                this.baklas.forEach(e => e.created_at = this.formatDate(e.created_at))
                this.exportBaklasHeadersAndData.Sheet1.data = this.baklas
                this.loading = false
            });
        },
        getAllBaklasItems() {
            this.$store.dispatch('getAllBaklasItems').then(response=>{
                this.exportBaklasItemsHeadersAndData.Sheet1.data = this.GET_ALL_BAKLAS_ITEMS;
            });
        },
        itemModel(baklas) {
            if(!!baklas) {
                return baklas.warehouse_item_stocks.item.model;
            }

            return '';
        },
        date(baklas){
            if(!!baklas) {
                return this.$dayjs(baklas.created_at).format('YYYY-MM-DD, hh:mm:ss');
            }

            return '';
        },
        showBaklasDetailsDialog(item) {
            this.baklasDetails = item;
            this.$store.commit('showBaklasDetailsDialog');
        },
        showEditBaklasDialog(baklas) {
            this.editBaklasDialog = true;
            this.baklasId = baklas.id;
            this.itemSerialNumber = baklas.serial_number;
            this.itemId = baklas.item_id;
        },
        closeEditBaklasDialog() {
            this.editBaklasDialog = false;
            this.clearFields();
        },
        closeShowDialog() {
            this.baklasDetails = null;
        },
        deleteBaklas(baklas) {
            swal.fire({
                title: 'Are you sure you want to Delete?',
                text: 'Click ok to proceed',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then((result)=>{
                if(result.isConfirmed) {
                    let payload = {
                        baklasId: baklas.id
                    }
                    this.$store.dispatch('deleteBaklas',payload).then(response=>{
                        if(!!response.data.error) {
                            swal.fire("", response.data.error, "error");
                        } else {
                            this.getAllBaklas();
                            swal.fire("", response.data.msg, "success");
                        }
                    });
                }
            });
        },
        submitEditData() {
            if(!this.requiredFields()) {
                swal.fire({
                    title: 'Are you sure you want to Submit?',
                    text: 'Click ok to proceed',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then((result)=>{
                    if(result.isConfirmed) {
                        let payload = {
                            baklasId: this.baklasId,
                            serialNumber: this.itemSerialNumber,
                        }
                        this.$store.dispatch('editBaklas',payload).then(response=>{
                            if(!!response.data.error) {
                                swal.fire("", response.data.error, "error");
                            } else {
                                this.closeEditBaklasDialog();
                                this.getAllBaklas();
                                swal.fire("", response.data.msg, "success");
                            }
                        });
                    }
                });
            } else {
                swal.fire({
                    title: 'Fill Required Fields',
                    icon: 'warning',
                    dangerMode: true
                })
            }
        },
        setSuccessfull(confirm) {
            if(confirm) {
                this.getAllBaklas();
            }
        },
        showBaklasDelete(item) {
            return true;
        },
        successTransfer(confirm) {
            if(confirm) {
                this.getAllBaklas();
            }
        },
        getAllPtItems() {
            let payload = {
                type_ids: [1]
            }
            this.$store.dispatch('getItems',payload).then(response => {
                this.items = this.GET_ITEMS
            });
        },
        filterList() {
            this.getAllBaklas();
        },
        clearFilter() {
            this.selectedItem = '';
            this.selectedItemModel = '';
            this.searchSparePart = '';
            this.baklas = [];
            this.baklasStatus = '';
            this.getAllBaklas();
        },
        getBaklasWithStatus(status) {
            this.baklasStatus = status;
        },
        searchSp() {
            // this.baklas = [];
            this.getAllBaklas();
        },
        clearSearchSparePart() {
            this.searchSparePart = '';
            this.baklas = [];
            this.getAllBaklas();
        },
        showOverrideTransfer() {
            if(!this.requiredFields()) {
                this.override.override_dialog = true;
                this.editBaklasDialog = false;
            }
        },
        closeConfirmOverride(user_id, authenticated = false) {
            if (authenticated) {
                this.override.override_dialog = false;
                this.editBaklasDialog = true;
                this.submitEditData();
            } else {
                this.override.override_dialog = false;
            }
        },
        showExportDialog() {
            this.exportDialog = true;
            this.getAllBaklasItems();
        },
        closeExportDialog() {
            this.exportDialog = false;
        },
        startBaklasDownload() {
            this.exportBaklasLoading = true;
        },
        finishBaklasDownload() {
            this.exportBaklasLoading = false;
        },
        startBaklasItemsDownload() {
            this.exportBaklasItemsLoading = true;
        },
        finishBaklasItemsDownload() {
            this.exportBaklasItemsLoading = false;
        },
        printBaklasItems()
        {
            this.isForHardCopy = true;
            let doc = new this.$jspdf("l", "mm", "letter"); // paper size in mm. = 215.9 x 279.4

            let ingcoPhLogo = new Image();
            ingcoPhLogo.src = "/images/ingcoph-logo.png";
            doc.addImage(ingcoPhLogo, "png", 95, 10, 90, 20);

            // For Header
            doc.setFontSize(14);
            doc.text('Cannibalize Item Details', 138, 38, "center");

            doc.setFontSize(10);


            let tableColumns = this.exportHeaders.map( e => {
                return {
                    header: e.header.toUpperCase(),
                    dataKey: e.key
                }
            })

            let payload = {
                itemId: this.selectedItem,
                searchItem: this.selectedItemModel,
                searchSp: this.searchSparePart,
                search: this.search,
                baklasStatus: this.baklasStatus,
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                selectStatus: this.selected_status,
                isForHardCopy: true
            }
            this.$store.dispatch('getAllBaklas',payload).then(response=>{
                let data = response.data.for_hard_copy
                doc.autoTable(
                        {
                            body: data,
                            columns: tableColumns,
                            headStyles: {
                                fillColor: '#F69F1A',
                                textColor: "black",
                                textType: "bold",
                            },
                            margin: {left: 10, right: 10},
                            startY: 45,
                            styles: {
                                fontSize: 8,
                                lineWidth: 0.1,
                                lineColor: 'black',
                                overflow: 'hidden'
                            },
                            showHead: 'firstPage',
                            theme: "plain",
                        });
                for (let page = 1; page <= doc.internal.getNumberOfPages(); page++) {
                    doc.setPage(page);
                    doc.setTextColor("black");
                    doc.setFontSize(8);
                    doc.text(
                    `Page ${page} of ${doc.internal.getNumberOfPages()}`,
                    10,
                    doc.internal.pageSize.height - 10
                    );
                }

                doc.autoPrint();
                window.open(doc.output('bloburl'), '_blank');
                this.printLoading = false;
                this.isForHardCopy = false
            }).catch( e => {
                console.error(e);
                this.printLoading = false;
                this.isForHardCopy = false
            });
        },

        selectStatus(status) {
           this.selected_status = status
           this.getAllBaklas()
        },

        exportToExcel() {
            this.exportLoading = true;
            let exportHeadersAndData = {
                [this.baklasStatus]: {
                    headers: this.exportHeaders,
                    data:[]
                }
            }
            let payload = {
                itemId: this.selectedItem,
                searchItem: this.selectedItemModel,
                searchSp: this.searchSparePart,
                search: this.search,
                baklasStatus: this.baklasStatus,
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                selectStatus: this.selected_status,
                isForHardCopy: true
            }
            this.$store.dispatch('getAllBaklas',payload).then( async response=>{
                let data = response.data.for_hard_copy;
                exportHeadersAndData[this.baklasStatus].data = data;
                await this.exportExcel(exportHeadersAndData, `Cannibalize ${this.baklasStatus} status`, 'F69F1A')
                this.exportLoading = false;
                this.isForHardCopy = false;
            }).catch( e => {
                console.error(e);
                this.exportLoading = false;
                this.isForHardCopy = false
            });
        },
        requiredFields() {
            let warning = '';
            if (!this.itemSerialNumber) warning += 'Please ensure that the empty field for the serial number is filled in.<br>';

            if (warning !== '') return swal.fire({
                title: 'Please Fill out Information:',
                html: warning,
                icon: "warning",
            });
        },
    }
}
</script>

<style>

</style>
