<template>
    <v-container fluid>
        <ComponentHeader
            :button="true"
            title='Pending Stock Card By PL'
            type='Inventory'
        ></ComponentHeader>

        <v-container fluid class="bg-bluish-gray">
            <v-row class="px-4 py-2">
                <v-col lg="6">
                    <v-autocomplete
                        v-model="customer_id"
                        :items="GET_CUSTOMERS_SELECTION"
                        :loading="loading.customer_selection"
                        :disabled="loading.customer_selection"
                        label="Select Customer"
                        background-color="white"
                        dense
                        outlined
                        hide-details
                        clearable
                        multiple
                        deletable-chips
                        small-chips
                    ></v-autocomplete>
                </v-col>
                <v-col class="text-right">
                    <v-btn small class="ml-1" @click="exportToExcel()">Excel</v-btn>
                    <v-btn small class="ml-1" @click="getPendingSCByPL()">Filter</v-btn>
                </v-col>
            </v-row>
            <v-row class="px-4 pb-3">
                <v-col lg="3" id="date_from" class="mb-2">
                    <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="date_from"
                                v-on="on"
                                label="Date From"
                                background-color="white"
                                append-icon="mdi-calendar-clock"
                                readonly
                                dense
                                outlined
                                hide-details
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="date_from" @input="menu2 = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col lg="3" id="date_to">
                    <v-menu
                        v-model="menu3"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="date_to"
                                v-on="on"
                                label="Date From"
                                background-color="white"
                                append-icon="mdi-calendar-clock"
                                readonly
                                dense
                                outlined
                                hide-details
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="date_to" @input="menu3 = false"></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>

            <div class="w-100">
                <v-card v-if="stockCards.length > 0">
                    <v-container fluid>
                        <v-expansion-panels>
                            <v-expansion-panel v-for="(item, i) in stockCards" :key="i">
                                <v-expansion-panel-header>
                                    <strong>{{ item[0] }}</strong>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-data-table
                                        :headers="headers.pl_headers"
                                        :items="item[1]"
                                        class="w-100"
                                        dense
                                        hide-default-footer
                                        disable-sort
                                    >
                                        <template v-slot:[`item.total_amount_remaining`]="{ item }">
                                            {{ item.total_amount_remaining | currency }}
                                        </template>
                                        <template v-slot:[`item.total_qty`]="{ item }">
                                            {{thousandSeprator(item.total_qty) }}
                                        </template>
                                        <template v-slot:[`item.total_sc_quantity`]="{ item }">
                                            {{thousandSeprator(item.total_sc_quantity) }}
                                        </template>
                                        <template v-slot:[`item.total_served_quantity`]="{ item }">
                                            {{thousandSeprator(item.total_served_quantity) }}
                                        </template>
                                        <template v-slot:[`item.total_cancelled_quantity`]="{ item }">
                                            {{thousandSeprator(item.total_cancelled_quantity) }}
                                        </template>
                                        <template v-slot:[`item.total_remaining_quantity`]="{ item }">
                                            {{thousandSeprator(item.total_remaining_quantity) }}
                                        </template>
                                        <template v-slot:[`item.action`]="{ item }">
                                            <v-btn text icon color="orange">
                                                <v-icon
                                                    class="btn-action"
                                                    small
                                                    @click="()=>{
                                                        viewPlDetailsDialog = !viewPlDetailsDialog
                                                        viewPlDetails(item)
                                                    }"
                                                >
                                                    mdi-eye
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                    </v-data-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-container>
                </v-card>
                <v-app id="load_bar" v-if="!loaded">
                    <v-text-field color="info" loading disabled></v-text-field>
                </v-app>
            </div>

            <v-dialog v-model="viewPlDetailsDialog" width="70%" scrollable>
                <v-form lazy-validation>
                    <v-card>
                        <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                        <span class="text-h5">PL#: {{selected_pl}}</span>
                            <v-row class="m-0">
                                <v-col cols="pull-right-10 p-2">
                                    <v-btn
                                        text
                                        icon
                                        small
                                        color="gray"
                                        class="float-right"
                                        @click="closeDialog()"
                                    >
                                        <v-icon>mdi-close-circle</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-card-text style="height: 600px;">
                            <v-data-table
                                :headers="headers.item_headers"
                                :items="pl_items"
                                :expanded.sync="pl_items.chevron_items"
                                :loading="expandedLoad"
                                item-key="packing_list_item_id"
                                show-expand
                                class="w-100"
                                dense
                                hide-default-footer
                                disable-sort
                            >
                                <template v-slot:expanded-item="{item}">
                                    <td :colspan="13" class="p-0 flex align-text-right">
                                        <v-data-table
                                            :headers="headers.chevron_headers"
                                            :items="item.chevron_items"
                                            dense
                                            dark
                                            hide-default-footer
                                            disable-sort
                                        >
                                            <template v-slot:[`item.quantity_served`]="{ item }">
                                                {{ thousandSeprator(item.quantity_served)}}
                                            </template>
                                            <template v-slot:[`item.number_of_boxes`]="{ item }">
                                                {{ thousandSeprator(item.number_of_boxes)}}
                                            </template>
                                            <template v-slot:[`item.status_text`]="{ item }">
                                                <span class="badge badge-preparing" v-if="item.status_text=='preparing'">{{ item.status_text }}</span>
                                                <span class="badge badge-primary" v-else-if="item.status_text=='prepared'">{{ item.status_text }}</span>
                                                <span class="badge badge-warning" v-else-if="item.status_text=='rejected'">{{ item.status_text }}</span>
                                                <span class="badge badge-danger" v-else-if="item.status_text=='canceled'">{{ item.status_text }}</span>
                                                <span class="badge badge-dark" v-else-if="item.status_text=='deleted'">{{ item.status_text }}</span>
                                                <span class="badge badge-success" v-else-if="item.status_text=='completed'">{{ item.status_text }}</span>
                                            </template>
                                        </v-data-table>
                                    </td>
                                </template>
                                <template v-slot:[`item.amount`]="{ item }">
                                    {{ item.amount | currency }}
                                </template>
                                <template v-slot:[`item.quantity`]="{ item }">
                                    {{ thousandSeprator(item.quantity) }}
                                </template>
                                <template v-slot:[`item.total_sc_qty`]="{ item }">
                                    {{ thousandSeprator(item.total_sc_qty) }}
                                </template>
                                <template v-slot:[`item.quantity_served`]="{ item }">
                                    {{ thousandSeprator(item.quantity_served) }}
                                </template>
                                <template v-slot:[`item.quantity_canceled`]="{ item }">
                                    {{ thousandSeprator(item.quantity_canceled) }}
                                </template>
                                <template v-slot:[`item.pl_remaining_qty`]="{ item }">
                                    {{ thousandSeprator(item.pl_remaining_qty) }}
                                </template>
                                <template v-slot:[`item.pl_remaining_amount`]="{ item }">
                                    {{ item.pl_remaining_amount | currency }}
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-form>
            </v-dialog>
        </v-container>
    </v-container>
</template>
<script>
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
import SharedFunctionsComponent from "@/views/main/Shared/SharedFunctionsComponent.vue";
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';

export default {
    mixins: [
        SharedFunctionsComponent
    ],
    data(){
        return {
            customer_id: [],
            customers_list: [],
            date_from: this.$dayjs().startOf('month').format('YYYY-MM-DD'),
            date_to: this.$dayjs().endOf('month').format('YYYY-MM-DD'),

            loaded: true,
            expandedLoad: false,

            menu2: false,
            menu3: false,

            stockCards: [],

            headers: {
                pl_headers:[
                    { text: 'PL#', align: 'start', value: 'pl_num' },
                    { text: 'PL date', width:'7%', align: 'start', value: 'pl_date' },
                    { text: 'Remarks', align: 'center', value: 'remarks' },
                    { text: 'Total Qty', align: 'center', value: 'total_qty' },
                    { text: 'Total SC Qty', align: 'center', value: 'total_sc_quantity' },
                    { text: 'Total Served', align: 'center', value: 'total_served_quantity' },
                    { text: 'Total Cancelled', align: 'center', value: 'total_cancelled_quantity' },
                    { text: 'Total Remaining', align: 'center', value: 'total_remaining_quantity' },
                    { text: 'Amount of Remaining', align: 'end', value: 'total_amount_remaining' },
                    { text: 'Action', align: 'center', value: 'action', },
                ],
                item_headers:[
                    { text: 'Model', align: 'center', value: 'model' },
                    { text: 'Name', align: 'center', value: 'name' },
                    { text: 'UOM', align: 'center', value: 'uom' },
                    { text: 'Type', align: 'center', value: 'type' },
                    { text: 'Category', align: 'center', value: 'category' },
                    { text: 'PL Qty', align: 'center', value: 'quantity' },
                    // { text: 'SC Qty', align: 'center', value: 'total_sc_qty' },
                    { text: 'PL Qty Served', align: 'center', value: 'quantity_served' },
                    { text: 'PL Qty Cancelled', align: 'center', value: 'quantity_canceled' },
                    { text: 'PL Qty Remaining', align: 'center', value: 'pl_remaining_qty' },
                    { text: 'PL Price', align: 'end', value: 'amount' },
                    { text: 'Amount Remaining', align: 'end', value: 'pl_remaining_amount' },
                ],
                chevron_headers:[
                    { text: 'SC#', align: 'center', value: 'stock_card_num' },
                    { text: 'SC Date', align: 'center', value: 'stock_card_date' },
                    { text: 'Remarks', align: 'center', value: 'remarks' },
                    { text: 'Status', align: 'center', value: 'status_text' },
                    { text: 'Completed Date', align: 'center', value: 'complete_date' },
                    { text: 'Number of Boxes', align: 'center', value: 'number_of_boxes' },
                    { text: 'Qty Served', align: 'center', value: 'sc_quantity_served' },
                ]
            },

            loading:{
                customer_selection      : true
            },

            viewPlDetailsDialog: false,
            selected_pl: '',
            pl_items: [],
            chevron_items: [],
            export_stock_cards: [],
            export_items: [],
            rendered_ids: []
        }
    },
    components: {
        ComponentHeader,
    },
    mounted() {
        this.$store.dispatch('getAllcustomersSelection')
    },
    computed:{
        ...mapGetters([
            'GET_CUSTOMERS_SELECTION',
        ])
    },
    methods: {
        getPendingSCByPL(){
            this.stockCards = []
            this.loaded = false

            let payload = {
                customer:       this.customer_id,
                date_from:      this.date_from,
                date_to:        this.date_to,
                url:            "getPendingSCByPL",
            }

            this.$store.dispatch('reports',payload).then(response => {
                this.rendered_ids = response.data.data
                let arr = this.divideArrayByAttribute(response.data.data, 'company_name', 'object')
                this.stockCards = Object.keys(arr).map((key) => [key, arr[key]])
                this.loaded = true
            }).catch(e=>{
                swal.fire('Error !' ,' - An error occured','error')
                this.loaded = true
            })
        },
        async exportToExcel(){
            this.loaded = true

            let payload = {
                customer:       this.customer_id,
                date_from:      this.date_from,
                date_to:        this.date_to,
                type_of_data:   'excel',
                pl_ids:         this.rendered_ids.map(e=>e.id),
                url:            "getPendingSCByPL"
            }

            await this.$store.dispatch('reports',payload).then(response => {
                this.export_stock_cards = response.data.pl_items
                this.export_items = response.data.data

                let workbook = new this.$exceljs.Workbook();
                let worksheet = workbook.addWorksheet('SUMMARY');
                let worksheet2 =  workbook.addWorksheet('DETAILED');

                worksheet.columns = [
                    { header: 'Customer', width: 38, key: 'company_name'},
                    { header: 'PL#', style: { numFmt: '@' }, key: 'pl_num'},
                    { header: 'PL Date', width: 12, key: 'pl_date' },
                    { header: 'PL Remarks', width: 73, key: 'remarks' },
                    { header: 'Total Qty', key: 'total_qty', style: { numFmt: '#,##0' } },
                    { header: 'Total SC Qty', width: 13, key: 'total_sc_quantity' },
                    { header: 'Total Served', width: 13, key: 'total_served_quantity', style: { numFmt: '#,##0' } },
                    { header: 'Total Cancelled', width: 15, key: 'total_cancelled_quantity', style: { numFmt: '#,##0' } },
                    { header: 'Total Remaining', width: 15, key: 'total_remaining_quantity', style: { numFmt: '#,##0' } },
                    { header: 'Amount of Remaining', width: 20, key: 'total_amount_remaining', style: { numFmt: '#,##0.00' } },
                ];

                worksheet.getRow(1).eachCell((cell, colNumber) => {
                    cell.alignment = { horizontal: 'center' };
                    cell.font = { bold: true };
                });

                worksheet2.columns = [
                    { header: 'Customer', width: 38, key: 'company_name'},
                    { header: 'PL#', key: 'pl_num', style: { numFmt: '@' }, },
                    { header: 'PL Date', width: 12, key: 'pl_date' },
                    { header: 'PL Remarks', width: 73, key: 'pl_remarks' },
                    { header: 'Model', width: 13, key: 'model' },
                    { header: 'Name', width: 45, key: 'name' },
                    { header: 'UOM', key: 'uom' },
                    { header: 'Type', key: 'type' },
                    { header: 'Category', width: 15, key: 'category' },
                    { header: 'PL Qty', key: 'quantity', style: { numFmt: '#,##0' } },
                    { header: 'SC Qty', key: 'total_sc_qty', style: { numFmt: '#,##0' } },
                    { header: 'PL Qty Served', width: 15, key: 'quantity_served', style: { numFmt: '#,##0' } },
                    { header: 'PL Qty Cancelled', width: 16, key: 'quantity_canceled', style: { numFmt: '#,##0' } },
                    { header: 'PL Qty Remaining', width: 16, key: 'pl_remaining_qty', style: { numFmt: '#,##0' } },
                    { header: 'PL Price', width: 12, align: 'rigth', key: 'amount', style: { numFmt: '#,##0.00' } },
                    { header: 'Amount Remaining', width: 18, align: 'rigth', key: 'pl_remaining_amount', style: { numFmt: '#,##0.00' } },
                    { header: 'SC#', style: { numFmt: '@' }, key: 'stock_card_num' },
                    { header: 'SC Date', width: 12, key: 'stock_card_date' },
                    { header: 'SC Remarks', width: 22, key: 'sc_remarks' },
                    { header: 'SC Status', width: 11,  key: 'status' },
                    { header: 'Complete Date', width: 16, key: 'complete_date' },
                    { header: 'Number of Boxes', width: 16, key: 'number_of_boxes', style: { numFmt: '#,##0' } },
                    { header: 'SC Qty Served', width: 15, key: 'sc_quantity_serve', style: { numFmt: '#,##0' } },
                ];

                worksheet2.getRow(1).eachCell((cell, colNumber) => {
                    cell.alignment = { horizontal: 'center' };
                    cell.font = { bold: true };
                });

                this.export_items.forEach(e => {
                    e.pl_num = parseInt(e.pl_num)
                    e.total_qty = parseInt(e.total_qty)
                    e.total_sc_quantity = isNaN(e.total_sc_quantity) ? 0 : Number(e.total_sc_quantity)
                    e.total_served_quantity = parseInt(e.total_served_quantity)
                    e.total_cancelled_quantity = parseInt(e.total_cancelled_quantity)
                    e.total_remaining_quantity = parseInt(e.total_remaining_quantity)
                    e.total_amount_remaining = parseFloat(e.total_amount_remaining)
                    e.pl_date = this.formatDate(e.pl_date)
                    worksheet.addRow(e)
                })

                this.export_stock_cards.forEach(q =>{
                    q.pl_num = parseInt(q.pl_num)
                    q.total_sc_qty = isNaN(q.total_sc_qty) ? 0 : Number(q.total_sc_qty)
                    q.pl_remaining_amount = parseFloat(q.pl_remaining_amount)
                    q.pl_date = this.formatDate(q.pl_date)
                    q.amount = parseFloat(q.amount)
                    q.chevron_items.forEach(chev => {
                        q.stock_card_num = chev.stock_card_num
                        q.stock_card_date = this.formatDate(chev.stock_card_date)
                        q.complete_date = this.formatDate(chev.complete_date)
                        q.sc_quantity_serve = parseInt(chev.sc_quantity_served)
                        q.number_of_boxes = parseInt(chev.number_of_boxes)
                        q.remarks = chev.remarks
                        if(chev.status === 0){
                            q.status = 'preparing'
                        }else if(chev.status === 1){
                            q.status = 'prepared'
                        }else if(chev.status === 2){
                            q.status = 'rejected'
                        }else if(chev.status === 3){
                            q.status = 'canceled'
                        }else if(chev.status === 4){
                            q.status = 'deleted'
                        }else if(chev.status === 5){
                            q.status = 'completed'
                        }
                        worksheet2.addRow(q)
                    })
                })

                workbook.xlsx.writeBuffer().then((data) => {
                    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                    this.$filesaver.saveAs(blob, `Pending Stock Card By PL ${this.date_from}-${this.date_to}.xlsx`);
                });

                this.loaded = true
            }).catch(e=>{
                console.log(e)
                swal.fire('Error !' ,' - An error occured','error')
                this.loaded = true
            })
        },
        async viewPlDetails(item) {
            this.expandedLoad = true
            await this.$store.dispatch('reports', {
                url:          'getPendingSCByPL',
                type_of_data: 'pl_items',
                date_from:    this.date_from,
                date_to:      this.date_to,
                pl_id:        item.id
            }).then(response => {
                this.selected_pl = item.pl_num
                this.pl_items = response.data.pl_items
                this.expandedLoad = false
            }).catch(e => {
                console.log(e)
            })
        },
        closeDialog() {
            this.viewPlDetailsDialog = false
            this.selected_pl = ''
            this.pl_items = []
        },
    },
    watch: {
        'GET_CUSTOMERS_SELECTION':{
            handler(val){
                this.customers_list = val
                this.loading.customer_selection = false
            },
        }
    },
}
</script>
