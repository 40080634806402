<template>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
          <span class="text-h5">PURCHASE BASKET</span>
          <v-row class="m-0">
            <v-col cols="pull-right-10 p-2">
              <v-btn
                text
                icon
                small
                color="gray"
                class="float-right"
                @click="closeDialog()">
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-card-text class="over-flow-auto">
            <v-container fluid v-if="loaded">
              <v-row >
                  <v-container bg fill-height grid-list-md text-sm-center >
                          <v-layout row wrap align-center>
                            <v-flex>
                                <v-progress-circular
                                :size="70"
                                color="primary"
                                indeterminate>
                              </v-progress-circular>
                            </v-flex>
                          </v-layout>
                    </v-container>
                </v-row>
            </v-container>
            <v-container v-else fluid >
              <v-row>
                <v-col cols="12" lg="2" md="2" sm="12">
                  <v-row class="pb-5">
                    <v-card>
                      <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                          <span class="text-caption font-weight-bold">Transaction Type</span>
                        </v-card-title>
                      <v-card-text>
                        <v-radio-group v-model="form.basket_type" row :disabled="ACTION=='Update'">
                          <v-radio ripple label="PRF" value="prf"></v-radio>
                          <v-radio ripple label="FAS" value="fas"></v-radio>
                        </v-radio-group>
                      </v-card-text>
                    </v-card>
                  </v-row> 
                  <v-row>
                    <v-col cols="12" >
                      <v-row >
                          <v-col>
                                <v-autocomplete
                                    v-model="form.company_id"
                                    :items="getAllCompanies"
                                    dense
                                    outlined
                                    hide-details
                                    label="Company"
                                    clearable
                                    auto-select-first
                                    @change="storesSelect"
                                >
                                </v-autocomplete>
                      </v-col>
                      <v-col>
                          <v-autocomplete
                              v-model="form.store_branches_id"
                              :items="clonedStores"
                              dense
                              outlined
                              auto-select-first
                              label="Stores"
                              hide-details
                              clearable
                              @change="companies"
                          >
                          </v-autocomplete>
                      </v-col>
                </v-row>
                      <v-row>
                          <v-col>
                              <v-autocomplete
                                  v-model="form.fas_id"
                                  :items="selection.fas_item_ho"
                                  dense
                                  outlined
                                  hide-details
                                  label="FAS HO #"
                                  clearable
                                  auto-select-first
                                  multiple
                                  chips
                                  small-chips
                                  deletable-chips
                                  :readonly="ACTION === 'View' || disabled_view"
                                  @change="getFasItems()"
                              >
                              </v-autocomplete>
                          </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                              <v-autocomplete
                                  v-model="form.fas_id_store"
                                  :items="selection.fas_item_stores"
                                  dense
                                  outlined
                                  hide-details
                                  label="FAS Store #"
                                  clearable
                                  auto-select-first
                                  multiple
                                  chips
                                  small-chips
                                  deletable-chips
                                  :readonly="ACTION === 'View' || disabled_view"
                                  @change="getFasItems()"
                              >
                              </v-autocomplete>
                          </v-col>
                      </v-row>
                 
                      <v-row>
                       <v-textarea v-model="form.remarks" outlined rows="6" no-resize label="Remarks" :readonly="ACTION === 'View'"></v-textarea>
                      </v-row>
                      <v-row v-if="this.GET_EDIT_FAS_BASKET.status == 5">
                          <v-col cols="12">
                              <span class="font-weight-bold">Reason on rejection: <span class="text-cation font-weight-normal" style="color:#EF9A9A">{{this.form.reject_reason}}</span></span>
                          </v-col>
                      </v-row>
                  </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" lg="2" md="2" v-if="[4,6,7,8,9].includes(this.GET_EDIT_FAS_BASKET.status)">
                    <v-simple-table fixed-header height="360px" max-height="360px">
                      <thead>
                        <tr>
                          <th>PO #</th>
                          <th>STATUS</th>
                          <th>Vendor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item,i) in GET_EDIT_ADMIN_FASBASKET.admin_po_lists" :key="i">
                          <td nowrap><a @click="redirectToAdminPurchaseOrder(item.po_num)" style="color:#1564a3; text-decoration: underline;">{{item.po_num}}</a></td>
                          <td><v-chip x-small class="ml-1 badge text-white" :color="poStatus(item.status).first('color')">{{poStatus(item.status).first('text')}}</v-chip></td>
                          <td>{{ $queryBuilder.table(GET_ADMIN_SUPPLIER_SELECTION).where('value',item.vendor_id).first('text') }}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                </v-col>
                <v-col cols="12" :lg="[4,6,7,8,9].includes(this.GET_EDIT_FAS_BASKET.status)?'8':'10'" :md="[4,6,7,8,9].includes(this.GET_EDIT_FAS_BASKET.status)?'8':'10'">
                    <v-row>
                        <v-col cols="12" class="ml-2 justify-content-between border-bottom border-top pr-2">
                            <!-- <v-btn
                                class="float-end"
                                color="yellow lighten-2"
                                @click="massUpdateItems()"
                                :disabled="disabled_view"
                            >
                            mass update
                            </v-btn>
                            <v-autocomplete
                                class="float-end pr-1"
                                v-model="item_update"
                                :items="GET_ADMIN_SUPPLIER_SELECTION"
                                label="Vendor"
                                auto-select-first
                                outlined
                                dense
                                :readonly="ACTION === 'View' || disabled_view"
                                >
                            </v-autocomplete> -->
                            <v-btn
                                class="float-end"
                                color="orange lighten-2"
                                @click="importPrice()"
                                :disabled="disabled_view  || ACTION == 'Submit'"
                            >
                            Import Price
                            </v-btn>
                            <v-btn
                                class="float-end"
                                color="light-blue darken-4"
                                @click="viewComparison()"
                                :disabled="ACTION == 'Submit'"
                            >
                            View Comparison
                            </v-btn>
                            <v-btn @click="exportItem()" class="float-end" :disabled="ACTION == 'Submit'">
                                EXPORT
                            </v-btn>
                            <!-- <v-text-field 
                            v-if="[8,9].includes(GET_EDIT_FAS_BASKET.status) || !this.disableOnPo()"
                            class="float-start"
                            v-model="form.arf_num" 
                            label="ARF Number (Optional)"
                            dense
                            outlined
                            >
                            </v-text-field>
                            <v-autocomplete
                                    v-if="[8,9].includes(GET_EDIT_FAS_BASKET.status) || !this.disableOnPo()"
                                    class="float-start pl-1"
                                    v-model="form.location_id"
                                    :items="GET_ADMIN_LOCATION"
                                    item-text="text"
                                    item-value="value"
                                    auto-select-first
                                    outlined
                                    label="Location"
                                    required
                                    dense
                          ></v-autocomplete>
                          <v-autocomplete
                                    v-if="[8,9].includes(GET_EDIT_FAS_BASKET.status) || !this.disableOnPo()"
                                    class="float-start pl-1"
                                    v-model="form.department_id"
                                    :items="GET_LIST_DEPARTMENT"
                                    item-text="text"
                                    item-value="value"
                                    auto-select-first
                                    outlined
                                    label="Department"
                                    required
                                    dense
                          ></v-autocomplete> -->
                      </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" class="pa-0">
                  <v-card-text >
                    <v-data-table
                      v-model="dataTable.selectable"
                      :headers="dataTable.headers"
                      :items="dataTable.items"
                      :items-per-page="10"
                      item-key="fas_req_item_id"
                      loading-text="Loading... Please wait"
                      fixed-header
                      :item-style="setRowStyle"
                    >

                      
                      <template v-slot:[`item.moq`]="{ item }">          
                        <v-text-field
                            v-model="item.moq"
                            type="number"
                            min="0"
                            max="999"
                            outlined
                            readonly
                            dense
                            >
                            </v-text-field>
                      </template>
                        <template v-slot:[`item.item_name`]="{ item }">
                                <v-combobox
                                v-model="item.item_id"
                                :items="selection.admin_items"
                                :disabled="disabled_view"
                                item-value="admin_item_id"
                                item-text="item_name"
                                outlined
                                dense
                                auto-select-first
                                class="pa-4">
                              <template #prepend>
                                <v-tooltip top v-if="!item.item_id?.admin_item_id && ['View','Update'].includes(ACTION)">
                                  <template v-slot:activator="{ on }">
                                      <v-icon
                                          v-on="on"
                                          color="red"
                                      > 
                                          mdi-alert-circle-outline 
                                      </v-icon>
                                  </template>
                                  <span>This item is not in the Item List</span>
                                </v-tooltip>
                              </template>
                              </v-combobox>
                        </template>
                        <template v-slot:[`item.qty`]="{ item }">
                            <v-text-field
                            v-model="item.qty"
                            type="number"
                            min="0"
                            max="999"
                            outlined
                            readonly
                            dense
                            >
                            </v-text-field>
                        </template>
                        <template v-slot:[`item.uom_id`]="{ item }">
                          <v-autocomplete
                              v-model="item.uom_id"
                              :items="GET_ADMIN_UOM_SELECTION"
                              auto-select-first
                              :disabled="disabled_view"
                              outlined
                              dense
                            >
                            </v-autocomplete>
                         </template>
                        <template v-slot:[`item.basket_approved_quantity`]="{ item }">
                            <v-text-field
                            v-model="item.basket_approved_quantity"
                            type="number"
                            min="0"
                            max="999"
                            outlined
                            dense
                             @input="changeApprovedQty(item)"
                            @keypress="changeApprovedQty(item)"
                            >
                            </v-text-field>
                        </template>
                        <!-- <template v-slot:[`item.vendor_id`]="{ item }">
                            <v-autocomplete
                                class="float-end pr-1"
                                v-model="item.vendor_id"
                                :items="GET_ADMIN_SUPPLIER_SELECTION"
                                auto-select-first
                                outlined
                                dense
                                :readonly="ACTION === 'View' || disabled_view"
                                @change="findVendorPriceList(item)"
                            >
                            </v-autocomplete>
                        </template>
                        <template v-slot:[`item.price`]="{ item }">
                          <vuetify-money
                                v-model="item.price"
                                outlined
                                required
                                dense
                                placeholder="0.00"
                                masked
                                :disabled="disabled_view"
                                :options="options"
                            ></vuetify-money>
                        </template> -->
                        <template v-slot:[`item.final_qty`]="{ item }">
                            <v-text-field
                            v-model="item.final_qty"
                            type="number"
                            min="0"
                            max="999"
                            outlined
                            :disabled="disabled_view"
                            dense
                            @input="changeQty(item)"
                            @keypress="changeQty(item)"
                            >
                            </v-text-field>
                        </template>
                        <template v-slot:[`item.total_amount`]="{ item }">
                     
                            <vuetify-money
                                v-model="item.total_amount"
                                outlined
                                required
                                dense
                                placeholder="0.00"
                                masked
                                :disabled="disabled_view"
                                :options="options"
                            ></vuetify-money>
                        </template>
                         <template v-slot:[`item.action`]="{ item,index }">
                          <v-btn
                            text
                            icon
                            small
                            color="red"
                            class="float-right"
                            :disabled="![1].includes(GET_EDIT_FAS_BASKET.status) ? true : false || ACTION === 'View' ? true : false"
                            @click="removeItem(index)">
                            <v-icon>mdi-minus-circle</v-icon>
                        </v-btn>
                        <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        text
                                        v-on="on"
                                        icon
                                        small
                                        color="orange"
                                        class="float-right"
                                        :disabled="item.item_id?.admin_item_id ? true : false  || ['View','Submit'].includes(ACTION) ? true : false"
                                        @click="openItemsDialog(item,index)"
                                    >
                                        <v-icon medium>mdi-plus-box</v-icon>
                                    </v-btn>
                                </template>
                                <span>Add Admin Items</span>
                            </v-tooltip>
                         </template>
                         <!-- <template v-slot:footer>
                          <v-container>
                            <v-row>
                              <v-col cols="11" class="text-right font-weight-bold pt-3 pb-3">
                                Total Amount: {{ form.total_amount | currency }}
                              </v-col>
                            </v-row>
                          </v-container>
                           
                          </template> -->
                          <!-- <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                              {{ pageStart }}-{{ pageStop }} of {{ itemsLength }}&nbsp;&nbsp;&nbsp;<v-badge class="" v-if="getAllMoqWarning()"
                                  color="red"
                                  :content="getAllMoqWarning()"></v-badge>
                           </template> -->
                           <template v-slot:footer.prepend>
                            
                            <v-chip class="mr-2" color="orange" text-color="white" label>Item not found: {{getAllItemnotfoundWarning()}}</v-chip>
                            <v-chip class="mr-2" color="yellow" text-color="black" label>MOQ not meet: {{getAllMoqWarning()}}</v-chip>
                            <v-chip @click="showLegendDetails('For Releasing',2)" class="mr-2" color="#D1C4E9" text-color="black" label>For releasing: {{getAllToReceived()}}</v-chip>
                            <!-- <v-chip class="mr-2" color="#CCFF90" text-color="black" label>For admin approval: {{getAllReceived()}}</v-chip> -->
                            <v-chip class="mr-2" color="#CCFF90" text-color="black" label>Complete: {{getAllReceived()}}</v-chip>
                            <v-chip @click="showLegendDetails('For Tagging',1)" class="mr-2" color="#b3e5fc" text-color="black" label>For Tagging: {{getAllAssetsWithoutTag()}}</v-chip>
                            <v-chip @click="showLegendDetails('With Asset',0)" class="mr-2" color="#f4ff81" text-color="black" label>With Asset: {{getAllAssets()}}</v-chip>
                            <!-- <v-chip color="red" text-color="white" label>Out of stocks: {{getAllStocksWarning()}}</v-chip> -->
                            <!-- <v-badge class="" 
                                  color="yellow"
                                  :content="getAllMoqWarning()" ></v-badge>
                                  <v-badge class="ml-6" 
                                  color="red"
                             :content="getAllStocksWarning()"></v-badge> -->
                           </template> 
                  </v-data-table>
                  </v-card-text>
                </v-col>
              </v-row>
                </v-col>
              </v-row>
            </v-container>
        </v-card-text>
        </v-card-text>
              <v-dialog v-model="importDialog" width="30%">
                  <v-card>
                    <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                      <span class="text-h5">IMPORT PRICE BY VENDOR</span>
                      <v-row class="m-0">
                        <v-col cols="pull-right-10 p-2">
                          <v-btn
                            text
                            icon
                            small
                            color="gray"
                            class="float-right"
                            @click="closeImportDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-card-text>
                      <v-container fluid>
                        <v-row>
                          <v-col cols="12" sm="12" md="6" lg="4" xl="4">
                            <v-autocomplete
                              v-model="importVendor.vendor_id"
                              :items="GET_ADMIN_SUPPLIER_SELECTION"
                              label="Vendor"
                              auto-select-first
                              outlined
                              dense
                              :readonly="ACTION === 'View' || disabled_view"
                              >
                          </v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="12" md="6" lg="4" xl="4">
                          <!-- <v-text-field
                            v-model="importVendor.item_name"
                            label="Item Name"
                            outlined
                            dense
                            @paste="onPaste"
                            
                            >
                            </v-text-field> -->
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" lg="4">
                              <!-- <v-simple-table 
                                fixed-header
                                height="300px">
                              <thead>
                                <tr>
                                  <th>Item Name</th>
                                  <th>Price</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(item,i) in importVendor.dataTable.items_from_fas" :key="i" :class="'tr-hover'+item.fas_item_id" 
                                  @click="trHover(item.fas_item_id,'t2')" 
                                  >
                                  <td>{{ item.item_name }}</td>
                                  <td v-if="!price_load">{{ item.price | currency }}</td>
                                  <td v-else><v-progress-linear
                                    color="orange lighten-1"
                                    indeterminate
                                    rounded
                                    height="3"
                                  ></v-progress-linear></td>
                                </tr>
                              </tbody>
                            </v-simple-table> -->
                        
                        
                            <!-- <v-data-table
                              :headers="importVendor.dataTable.headers"
                              :items="dataTable.items"
                              :items-per-page="10"
                              loading-text="Loading... Please wait"
                              :item-class="rowClass"
                              fixed-header
                          >
                            
                          </v-data-table> -->
                          </v-col>
                          <v-col cols="12" lg="12">
                            <v-simple-table 
                                fixed-header
                                height="300px">
                              <thead>
                                <tr>
                                  <th>Item Name</th>
                                  <th>Price</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(item,i) in importVendor.dataTable.items" :key="i" :class="'tr-hover'+item.id" 
                                  @click="trHover(item.id,'t1')" 
                                  >
                                  <td><v-text-field
                                      v-model="item.item_name"
                                      dense
                                      outlined
                                      @paste="onPaste"
                                    >

                                  </v-text-field></td>
                                  <td><vuetify-money
                                      v-model="item.price"
                                      outlined
                                      required
                                      dense
                                      placeholder="0.00"
                                      masked
                                      :disabled="disabled_view"
                                      :options="options"
                                    ></vuetify-money>
                                  </td>
                                </tr>
                              </tbody>
                            </v-simple-table>
                          
                            <!-- <v-data-table
                              :headers="importVendor.dataTable.headers"
                              :items="importVendor.dataTable.items"
                              :items-per-page="10"
                              loading-text="Loading... Please wait"
                              fixed-header
                          >
                            
                          </v-data-table> -->
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-divider/>
                    <v-card-actions>
                        <v-container class="d-flex justify-content-center">
                            <v-btn @click="saveImportPrice()">Save</v-btn>
                        </v-container>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="comparisonDialog" width="100%" persistent scrollable>
                  <v-card>
                    <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                      <span class="text-h5">VENDOR COMPARISON</span>
                      <v-row class="m-0">
                        <v-col cols="pull-right-10 p-2">
                          <v-btn
                            text
                            icon
                            small
                            color="gray"
                            class="float-right"
                            @click="closeComparisonDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-container fluid>
                          <v-row>
                            <v-col cols="12" lg="2" md="3" sm="12">
                              <v-combobox
                                :items="selection.comparison_admin_items"
                                :disabled="disabled_view"
                                item-value="id"
                                item-text="item_name"
                                label="Filter Item"
                                outlined
                                dense
                                multiple
                                deletable-chips
                                chips
                                small-chips
                                @change="comparisonItemFilter"
                                auto-select-first
                                class="pa-4">
                              </v-combobox>
                            </v-col>
                          </v-row>
                          <v-row v-if="comparisonLoaded">
                            <v-col class="position-relative">
                              <div style="overflow-x: auto;">
                                <v-simple-table fixed-header height="800" dense style=" border-collapse: collapse; display: block; overflow-x: auto; width:100%;">
                                  <thead>
                                      <tr>
                                        <th style="font-size:15px;
                                                   border:2px solid #ddd;
                                                   position: sticky;
                                                   left: 0;
                                                   z-index: 10; 
                                                   background-color: white; 
                                                   width: 10%;">
                                                   Item Name
                                        </th>
                                        <th v-for="(item,i) in vendorPriceList" :key="i" nowrap style="font-size:15px; border:2px solid #ddd">
                                          {{item.company_name}}
                                        </th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="(item,i) in vendorPriceListItems" :key="i" @click="trHover(item.id)">
                                        <td style="border:2px solid #ddd; 
                                                  position: sticky; 
                                                  left: 0; 
                                                  z-index: 10; 
                                                  background-color: white;" 
                                            :class="'tr-hover tr-hover-item-'+item.id" nowrap>
                                          {{item.item_name}}
                                        </td>
                                        <td v-for="(items,i) in vendorPriceList" :key="i" :class="'tr-hover tr-hover-vendors-'+item.id" nowrap style="border:2px solid #ddd">
                                          <v-checkbox
                                                style="max-width:160px;"
                                                v-if="getPrice(items,item).first('price')"
                                                v-model="form.canvassing"
                                                :value="canvassingOBJ(items,item)"
                                                :disabled="disabled_view || canvassingCondition(items,item) ? true : false"
                                              >
                                            <template #label>
                                              <span>&#x20b1; {{ getPrice(items,item).first('price') ?? 0 | currency }}</span>
                                            </template>
                                            </v-checkbox>
                                            <span v-else>-</span>
                                        </td>
                                      </tr>
                                  </tbody>
                                </v-simple-table>
                              </div>
                            </v-col>
                          </v-row>
                          <v-row v-else>
                            <v-container bg fill-height grid-list-md text-sm-center >
                              <v-layout row wrap align-center>
                                <v-flex>
                                    <v-progress-circular
                                    :size="70"
                                    color="primary"
                                    indeterminate>
                                  </v-progress-circular>
                                </v-flex>
                              </v-layout>
                            </v-container>
                          </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider/>
                    <v-card-actions>
                        <v-container class="d-flex justify-content-center">
                            <v-btn @click="createOrUpdateFasItemRequest()" v-if="![3,4,5,6,7,8,9].includes(this.GET_EDIT_FAS_BASKET.status)">Save</v-btn>
                        </v-container>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              <v-divider></v-divider>
            <v-card-actions :class="'text-center d-flex border-top'+ actions == 'View' && ![4,5].includes(GET_EDIT_FAS_BASKET.status)?'justify-content-between':'justify-content-center'">
              <v-btn v-if="GET_EDIT_FAS_BASKET.status == 2 && !loaded && ((this.userAccess.reject) || actions == 'Submit' || ACTION == 'Update')"
                class="float-center"
                color="red"
                @click="reject()"
              >
                <span>REJECT</span>
              </v-btn>
              <v-btn v-if="![4,5,6,7].includes(GET_EDIT_FAS_BASKET.status) && !loaded 
                  && ((![4,5,6,7,9].includes(GET_EDIT_FAS_BASKET.status) && 
                  this.userAccess.approve || 
                  (GET_EDIT_FAS_BASKET.basket_type != 'prf' && 
                  GET_EDIT_FAS_BASKET.status == 3)) 
                  || (GET_EDIT_FAS_BASKET.status == 9 
                  && this.getAllToReceived() 
                  && this.userAccess.approve)) 
                  || ['Submit','Update'].includes(actions)"
                  class="float-center"
                  @click="createOrUpdateFasItemRequest()"
                :disabled="GET_EDIT_FAS_BASKET.status == 1 && (GET_EDIT_FAS_BASKET.total_amount == 0 && GET_EDIT_FAS_BASKET.basket_type == 'fas') && actions == 'View' || getAllItemnotfoundWarning() && (GET_EDIT_FAS_BASKET.status == 1 && actions == 'View') ? true : false"
              >
                <span>{{ actions == 'View'?status_text:actions }}</span>
              </v-btn>
            </v-card-actions>
            <AdminReleaseFormTemplateComponentV2/>
        </v-card>
      <v-dialog width="80%" v-model="ITEMS_DIALOG">
          <ItemViewComponent/>
      </v-dialog>
      <v-dialog width="50%" v-model="asset_dialog.dialog" persistent>
        <v-card>
          <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
            <span class="text-h5">{{ legend_text }}</span>
            <v-row class="m-0">
              <v-col cols="pull-right-10 p-2">
                <v-btn
                  text
                  icon
                  small
                  color="gray"
                  class="float-right"
                  @click="asset_dialog.dialog = !asset_dialog.dialog">
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row dense class="pa-2">
              <v-col cols="12" >
                <v-data-table
                  :headers="formattedHeaders(asset_dialog.headers)"
                  :items="fetchItems()"
                  fixed-header
                  hide-default-footer
                >

                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
         <!-- <v-divider></v-divider> -->
          <!-- <v-card-actions class="d-flex justify-content-center p-3">
              <v-btn>
                  <span >{{'Submit'}}</span>
              </v-btn>
          </v-card-actions> -->
        </v-card>
      </v-dialog>
      <v-dialog width="75%" v-model="create_po_dialog.dialog">
        <v-card>
          <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
            <span class="text-h5">{{'Select Item to PO'}}</span>
            <v-row class="m-0">
              <v-col cols="pull-right-10 p-2">
                <v-btn
                  text
                  icon
                  small
                  color="gray"
                  class="float-right"
                  @click="create_po_dialog.dialog = !create_po_dialog.dialog">
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row dense class="pa-2">
              <v-col cols="12" >
                <v-data-table
                  v-model="form.selected_item_to_po"
                  :headers="dataTable.headers"
                  :items="create_po_dialog.items"
                  show-select
                  fixed-header
                  hide-default-footer
                >
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
         <v-divider></v-divider>
          <v-card-actions class="d-flex justify-content-center p-3">
              <v-btn @click="selectedItemToCreatePO">
                <span >{{'Submit'}}</span>
              </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
</template>
<script>
  import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
  import Swal from "sweetalert2";
  import { mapGetters } from "vuex";
  import FasRequisitionComponent from '@/views/main/layouts/PrintTemplate/FasRequisitionComponent.vue'
  import ItemViewComponent from '@/views/main/layouts/dialogs/Admin/ItemViewComponent.vue';
  import AdminReleaseFormTemplateComponentV2 from "@/views/main/layouts/PrintTemplate/AdminReleaseFormTemplateComponentV2.vue";
  export default {
    mixins: [
        SharedFunctionsComponentVue,
    ],
    data() {
      return {
        offset: true,
        valid:true,
        dialog:false,
        form: {
            department_id:'',
            location_id:'',
            remarks:'',
            status:1,
            fas_items:[],
            type:'ho',
            company_id:'',
            store_branches_id:'',
            fas_id:[],
            fas_id_store:[],
            total_amount:0,
            fast_items_trash:[],
            canvassing:[],
            reject_reason:'',
            basket_type:'prf',
            expected_date:'',
            arf_num:'',
            selected_item_to_po: [],
        },
        menu:false,
        employee_selection_data:[{'value':0,'text':'GENERAL ACCOUNTABILITY','department_id':0}],
        search_employee:'',
        search_department:'',
        action:'',
        disabled_view: false,
        errors: {
          emp_id: [(v) => !!v || "Employee Name is required"],
          location: [(v) => !!v || "Location is required"],
        },
        URL:process.env.VUE_APP_API_HOST,
        batchAdditionItems:[],
        collection:[],
        collectionQTY:[],
        loaded:false,
        userAccess: {
          approve:false,
          create_po:false,
          reject:false,
        },
        dataTable:{
          headers:[
              { text: "Ref #", align: "left", value: "fas_number",width:"100",sortable:false },
              { text: "Item Name", align: "right", value: "item_name",width:"150",sortable:false },
              { text: "UOM", align: "right", value: "uom_id",width:"150",sortable:false },
              { text: "Stocks", align: "center", value: "stocks_qty",width:"120",sortable:false },
              { text: "MOQ", align: "center", value: "moq",width:"80",sortable:false },
              { text: "Buffer Stocks", align: "center", value: "buffer_stocks",width:"80",sortable:false },
              { text: "Requested QTY", align: "center", value: "qty",width:"120",sortable:false },
              { text: "Final QTY", align: "center", value: "final_qty",width:"120",sortable:false },
              { text: "Approved QTY", align: "center", value: "basket_approved_quantity",width:"120",sortable:false },
              { text: "Action", align: "center",width:"50", value: "action",sortable:false}
          ],
          items:[],
          options:{},
          itemsPerPageOption:[5,15,20,100],
          totalItem:0,
          itemsPerpage:5,
          search:'',
          selectable:[],
          selectable_previous:[],
          previousData:[],
        },
        selection:{
            admin_items:[],
            vendors:[],
            fas_item_ho:[],
            fas_item_stores:[],
            comparison_admin_items:[]
          },
        item_update:'',
        qty_update:1,
        previousData_dialog:false,
        filter:{
          expected_date:''
        },
        clonedStores:[],
        stores_sync:{},
        getAllCompanies:[],
        fas_item:[],
        options: {
                prefix: "₱",
                suffix: "",
                precision: 2
            },
        itemVendorPriceList:[],
        tmp:false,
        withWarnings:[],
        importDialog:false,
        importVendor:{
          vendor_id:'',
          item_name:'',
          dataTable:{
            items:[],
            items_from_fas:[]
          },
        },
        price_load:false,
        importItems:[],
        comparisonDialog:false,
        vendorPriceList:[],
        vendorPriceListItems:[],
        hoverID:{
            item:0,
            count:0
          },
        comparisonLoaded:false,
        purchaseorder_redirect: '',
        asset_dialog:{
          dialog: false,
          type: 0,
          headers: [
            { text: "Item Name", align: "left", value: "item_name",width:"150",sortable:false },
            { text: "Quantity", align: "left", value: "asset_with_tag_per_quantity",width:"150",sortable:false },
            { text: "Quantity", align: "left", value: "asset_without_tag_per_quantity",width:"150",sortable:false },
            { text: "Approved Quantity", align: "left", value: "basket_approved_quantity",width:"150",sortable:false },
            { text: "Pending Quantity", align: "left", value: "pending_qty",width:"150",sortable:false },
            { text: "Received Quantity", align: "left", value: "received_qty",width:"150",sortable:false },
          ],
          items: []
        },
        create_po_dialog:{
          dialog: false,
          type: 0,
          headers: [
          ],
          items: [],
          selected_item_to_po: [],
          legend_text:''
        }
      }
    },
    components: {
      FasRequisitionComponent,
      ItemViewComponent,
      AdminReleaseFormTemplateComponentV2
    },
    computed: {
      ...mapGetters([
        "ACTION",
        "DIALOGS",
        "USER_ACCESS",
        "GET_ADMIN_LOCATION",
        "GET_EMPLOYEE_DATA_SELECTION",
        "GET_EDIT_ADMIN_FASBASKET",
        "GET_NEW_PURCHASE_REQUEST_TABLE",
        "GET_ADMIN_FASBASKET_DISPATCH",
        "GET_LIST_DEPARTMENT",
        "GET_NEW_ADMIN_FASBASKET",
        "GET_EDIT_FAS_BASKET",
        "GET_ADMIN_FASBASKET_STATUS",
        "GET_PV_COMPANIES_SELECTION",
        "GET_PV_STORE_BRANCHES_SELECTION",
        "GET_ADMIN_FAS_SELECTION",
        "GET_ITEM_PRICE_LIST_BY_VENDOR",
        "GET_ADMIN_SUPPLIER_SELECTION",
        "STATUS",
        "ITEMS_DIALOG",
        "PAYLOAD",
        "GET_ADMIN_UOM_SELECTION",
        "GET_BOUND_EDIT_DATA_ITEMS",
        "GET_PRINT_ADMIN_ARF_DETAIL"
      ]),
      actions() {
        if (this.USER_ACCESS != "fail") {
            this.userAccess.approve = false
            this.USER_ACCESS.forEach((e) => {
             if(e.actions_code == 'approve'){
                this.userAccess.approve = true
              }
              else if(e.actions_code == 'create_po'){
                this.userAccess.create_po = true
              }
              else if(e.actions_code == 'reject'){
                this.userAccess.reject = true
              }
            });
          }
        if (this.ACTION == "Submit") {
          this.disabled_view = false;
          this.loaded = true;
          this.action = this.GET_ADMIN_FASBASKET_DISPATCH.add
          this.form.status = 1
          return "Submit";
        } else if (this.ACTION == "Update") {
          this.disabled_view = false;
          this.showItems()
          this.form.status = 1
          this.action = this.GET_ADMIN_FASBASKET_DISPATCH.update;

          return 'Update';
        } else if (this.ACTION == "View") {
          this.showItems()
          this.action = this.GET_ADMIN_FASBASKET_DISPATCH.update;

          this.disabled_view = true;
          return "View";
        }
      },
      status_text(){
          if(this.GET_ADMIN_FASBASKET_STATUS.tab_name == 'ALL'){
            this.form.status = this.ACTION != 'View'?1:2;
            if(this.GET_EDIT_FAS_BASKET.status == 2){
              this.form.status = 3;
               return 'Approved'
            }
            if (this.GET_EDIT_FAS_BASKET.status == 3){
              if(this.GET_EDIT_FAS_BASKET.basket_type == 'fas'){
                  this.form.status = 4;
                  return 'Create PO'
              }
            }
            else if ([8,9].includes(this.GET_EDIT_FAS_BASKET.status)){
              this.form.status = 6;
              return 'OUT BOUND'
            }
             return 'Submit For Approval'
          }
          if(this.GET_ADMIN_FASBASKET_STATUS.tab_name == 'PENDING'){
            this.form.status = this.ACTION != 'View'?1:2;
            if(this.GET_EDIT_FAS_BASKET.status == 2){
              this.form.status = 3;
               return 'Approved'
            }else if (this.GET_EDIT_FAS_BASKET.status == 3){
              this.form.status = 4;
              return 'Create PO'
            }
             return 'Submit For Approval'
          }
          if(this.GET_ADMIN_FASBASKET_STATUS.tab_name == 'FOR APPROVAL'){
            this.form.status = this.ACTION != 'View'?1:2;
            if(this.GET_EDIT_FAS_BASKET.status == 2){
              this.form.status = 3;
               return 'Approved'
            }else if (this.GET_EDIT_FAS_BASKET.status == 2){
              this.form.status = 4;
              return 'Create PO'
            }
             return 'Submit For Approval'
          }
          if(this.GET_ADMIN_FASBASKET_STATUS.tab_name == 'APPROVED'){
            this.form.status = 4;
            return 'Create PO'
          }
      },
      previous_with_dialog(){
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return "100%"
          case 'sm': return "100%"
          case 'md': return "40%"
          case 'lg': return "40%"
          case 'xl': return "40%"
        }
      }
    },
    mounted() {
      this.getItemsSelection()
      this.getFasRequisitionStores()
      this.getFasRequisition()
      this._purchaseorder()
    },
    beforeMount(){
      this.getBranches()
      
    },
    methods: {
      async _purchaseorder() {
          let sidenav = JSON.parse(localStorage.getItem("side_menu_bar"));
          await sidenav.forEach(async (e) => {
              await e.sidebarlinks.forEach((el) => {
                  if (el.route_name == "adminpurchaseorder") {
                      this.purchaseorder_redirect = el.link_id;
                  }
              });
          });
      },
      getItemsSelection(){
          this.$store.dispatch('getPRFAdminItems',{
                                vendor_id:0,
                                category:this.form.transaction_type
                            }).then(response=> {
                                this.selection.admin_items = response
                                this.loaded = false

                });
          this.$store.dispatch('getAdminItemUomSelection');
        },
        openItemsDialog(item,index){
         

            this.$store.commit('SELECTED',{item_name: item.item_id, index: index}) //change to item_name
            this.$store.commit('ITEMS_DIALOG',true);
        },
        async showItems(){
            if(!this.tmp){
              this.form.id = this.GET_EDIT_FAS_BASKET.id
              this.form.expected_date = this.GET_EDIT_FAS_BASKET.expected_date
              // this.form.department_id = this.GET_EDIT_FAS_BASKET.department_id;
              this.form.fas_id_store = this.GET_EDIT_FAS_BASKET.fas_id_store;
              // this.form.location_id = this.GET_EDIT_FAS_BASKET.location_id;
              this.form.remarks = this.GET_EDIT_FAS_BASKET.remarks;
              this.form.type = this.GET_EDIT_FAS_BASKET.type;
              this.form.company_id = this.GET_EDIT_FAS_BASKET.company_id;
              this.form.store_branches_id = this.GET_EDIT_FAS_BASKET.store_branches_id;
              this.form.type = this.GET_EDIT_FAS_BASKET.type;
              this.form.fas_id = this.GET_EDIT_FAS_BASKET.fas_id;
              this.form.total_amount = this.GET_EDIT_FAS_BASKET.total_amount;
              this.form.reject_reason = this.GET_EDIT_FAS_BASKET.reject_reason;
              this.form.basket_type = this.GET_EDIT_FAS_BASKET.basket_type;
              if(Object.keys(this.GET_EDIT_ADMIN_FASBASKET).length > 0){
                this.dataTable.items = this.GET_EDIT_ADMIN_FASBASKET.basket_items;
                this.GET_EDIT_ADMIN_FASBASKET.fas_requisitions_ho.forEach(e=>{
                  this.selection.fas_item_ho.push(e);
                })
                this.GET_EDIT_ADMIN_FASBASKET.fas_requisitions_store.forEach(e=>{
                  this.selection.fas_item_stores.push(e);
                })
                if(this.dataTable.items.length > 0){
                  this.loaded = false
                }
              }
            }
        },
        removeItem(i){
            this.tmp = true;
            let query = this.$queryBuilder;
            let findById = query.table(this.dataTable.items).whereIndex(i).first('id');
            let findId = query.table(this.dataTable.items).whereIndex(i).first('admin_fpr_id');
            if(findById){
                this.form.fast_items_trash.push(findById)
            }
            this.dataTable.items.splice(i,1);
            let findItem = query.table(this.dataTable.items).where('admin_fpr_id',findId).get();
            if(findItem.length == 0 && this.form.fas_id.length > 0){
                query.table(this.form.fas_id).where(findId).delete()
            }
        },
        async createOrUpdateFasItemRequest(){
            this.tmp = true
            this.form.fas_items = this.dataTable.items
            // if(this.form.batchAdditionItems.find(e=>e.id == '') != undefined && this.form.batchAdditionItems.find(e=>e.id == '').id == ''){
            //   let textArr = this.form.batchAdditionItems.map(e=>e.id == ''?'"'+e.code+'"':'').filter(el=>el != '')
            //   Swal.fire({text:'('+textArr+') Not exists',icon:'error'});
            // } else if (this.form.batchAdditionItems.find(e=>e.price == 0) != undefined && this.form.batchAdditionItems.find(e=>e.price == 0)){
            //   Swal.fire({
            //     html: `Zero price found`,
            //     icon: "warning",
            //     allowOutsideClick: false,
            //   });
            // }
            // else  if(!this.form.department_id && !this.form.location_id){
            if(this.dataTable.items.length == 0){
              Swal.fire({
                html: `Fill Required Fields`,
                icon: "warning",
                allowOutsideClick: false,
              });
            }
            else{
                let moq = this.$queryBuilder.table(this.dataTable.items).where('moq','!=',0).first('moq');
                let final_qty = this.$queryBuilder.table(this.dataTable.items).where('basket_approved_quantity',"<",moq)
                if(final_qty.first('basket_approved_quantity') < moq){
                    Swal.fire({
                      html: `Order quantity of ${final_qty.first('item_name')}
                      does not meet the minimum order quantity (MOQ) of
                        ${final_qty.first('moq')}. Please increase the quantity to at least ${final_qty.first('moq')}.`,
                      icon: "warning",
                      allowOutsideClick: false,
                    });
                    return false;
                }
                // else if(this.getAllStocksWarning()){
                //     Swal.fire({
                //       html: `out of stocks on ${stocks_qty.first('item_name')}.`,
                //       icon: "warning",
                //       allowOutsideClick: false,
                //     });
                //     return false;
                // }
                else if(this.form.status == 4){
                  Swal.fire({
                      icon:'question',
                      text:'are you sure you want to create PO?',
                      showCancelButton:true,
                      reverseButtons:true,
                      confirmButtonColor: '#397373',
                  }).then((res)=>{
                    if(res.isConfirmed){
                      this.$store.dispatch(this.action,this.form);
                    }
                  })
                return false
              }
              if(this.form.status == 6 && this.form.location_id == '' && this.form.department_id == '' ){
              Swal.fire({
                html: `Please fill in both the Location and Department fields.`,
                icon: "warning",
                allowOutsideClick: false,
              });

              return false;
            }
            if(this.form.status == 2 && parseInt(this.GET_EDIT_FAS_BASKET.total_amount) == 0 && !this.comparisonDialog){
              Swal.fire({
                html: `You need to select a vendor before proceeding`,
                icon: "warning",
                allowOutsideClick: false,
              });

              return false;
            }
            if(this.form.status == 3 && this.form.basket_type == 'prf' && this.dataTable.items.length > 0 
            && this.$queryBuilder.table(this.dataTable.items).where('stocks_qty','>',0).first()){
              Swal.fire({
              icon: "warning",
              text: "Do you want to create PO?",
              reverseButtons: true,
              showCancelButton: true,
              allowOutsideClick: false,
              cancelButtonColor: "#grey",
              cancelButtonText: "No",
              confirmButtonText:'Yes'
              })
              .then((result) => {
                  if (result.isConfirmed) {
                    this.create_po_dialog.dialog = true;
                    this.create_po_dialog.items = this.dataTable.items.filter(e => e.stocks_qty > 0)
                  }else{
                    this.$store.dispatch(this.action,this.form);
                  }
              });
              return false
            }else{
              this.$store.dispatch(this.action,this.form);
            }
            if(this.form.status != 3){
              // this.$store.dispatch(this.action,this.form).then(res=>{
              //   if(res.data.id){
              //             let payloads = {
              //               id:res.data.id,
              //               url:'adminInboutOutboundShow'
              //             }
              //             this.$store.dispatch('adminInboundOutboundShowItems',payloads).then((resp=>{
              //                 if(resp){
              //                   this.printARF(resp)
              //                 }
              //             }));
                         
              //           }
              // });
            }
          }
        },
        renderPDF(){
            this.$store.commit('PRINT_FAS_REQUISITION_DETAIL',{});
            this.$store.commit('PRINT_FAS_REQUISITION_DETAIL',{items_list:this.GET_EDIT_ADMIN_FASBASKET,data:this.GET_EDIT_FAS_BASKET});
            this.$store.commit('PRINT_FAS_REQUISITION',true);
        },
        closeDialog() {
            this.$store.commit("ACTION", "Submit");
            if(this.ACTION == "Submit"){
                this.loaded = false;
                this.$store.commit("DIALOG", false);
                this.$store.commit('EDIT_FAS_BASKET',{});
                this.$store.commit('EDIT_ADMIN_FASBASKET',[]);
                this.$store.commit('PAYLOAD',{});
                this.resetFields();
            }
        },
        resetFields(){
            Object.assign(this.$data, this.$options.data.call(this));
        },
        massUpdateItems(){
            this.dataTable.selectable.forEach(e=>{
                  if(this.item_update){
                    e.vendor_id = this.item_update
                  }
            })
            this.item_update = ''
        },
        async pullPreviousData(){
            if(this.dataTable.previousData.length > 0){
                this.dataTable.items = this.dataTable.previousData.filter((v)=>{  v.id = 0; return v });
            }
        },
        getBranches(){
          this.clonedStores = this.GET_PV_STORE_BRANCHES_SELECTION
          this.getAllCompanies = this.GET_PV_COMPANIES_SELECTION;
        },
        insertStore(){
            this.form.store_branches_id = this.clonedStores[0].value
        },
        companies(val){
            this.fas_id = [];
            this.dataTable.items = []
            this.dataTable.selectable = [];
            if(val){
                const query = this.$queryBuilder
                let store = this.GET_PV_STORE_BRANCHES_SELECTION
                let company = this.GET_PV_COMPANIES_SELECTION
                let find = query.table(store).where('value',val).first();
                if(find){
                  this.getAllCompanies = query.table(company).where('value',find.company_id).get();
                  this.form.company_id = query.table(company).where('value',find.company_id).first('value')
                  this.storesSelect(this.form.company_id)
                }else{
                  this.form.company_id = ''
                  this.getAllCompanies = query.table(company).get();
                  this.clonedStores = query.table(store).get();
                }
                this.getFasRequisition();
                this.getFasItems()
            }
        },
        storesSelect(val){
            this.fas_id = [];
            this.dataTable.items = []
            this.dataTable.selectable = [];
            const query   = this.$queryBuilder
            let   store   = this.GET_PV_STORE_BRANCHES_SELECTION
            let   company = this.GET_PV_COMPANIES_SELECTION
            if(val){
                let find = query.table(company).where('value',val).first();
                if(find){
                  this.clonedStores = query.table(store).where('company_id',find.value).get();
                  this.getAllCompanies = query.table(company).where('value',find.value).get();
                  this.form.store_branches_id = query.table(store).where('company_id',find.value).first('value')
                }
            }else{
                this.form.store_branches_id = ''
                this.getAllCompanies = query.table(company).get();
                this.clonedStores = query.table(store).get();
            }
            this.getFasRequisition();
            this.getFasItems()
        },
        getFasItems(){
            this.tmp = true
            let query = this.$queryBuilder;
            let fasIds = [...this.form.fas_id_store,...this.form.fas_id];
            if(this.ACTION == 'Submit'){
                this.dataTable.items = []
            }

            let payload = {
                id:JSON.stringify(fasIds),
                // company_id:this.form.company_id,
                // store_branches_id:this.form.store_branches_id,
                // department_id:this.form.department_id,
                // location_id:this.form.location_id
            }
            // fasIds.forEach(e=>{
                // console.log(e)
                // console.log(query.table(this.dataTable.items).where('admin_fpr_id',"!=",e).whereNull('id').get())
                // query.table(this.dataTable.items).where('admin_fpr_id',"!=",e).whereNull('id').delete()
            query.table(this.dataTable.items).whereNotIn('admin_fpr_id',fasIds).delete()
              // })
            this.$store.dispatch('getFasItems',payload).then((response)=>{
                if(fasIds.length == 0){
                    query.table(this.dataTable.items).delete()
                }
                response.data.forEach(e=>{
                    if(e.basket_approved_quantity % e.moq != 0){
                      // e.final_qty = e.final_qty + e.moq
                    }
                    // e.buffer_stocks = (e.approved_quantity - e.final_qty) > 0 ? (e.approved_quantity - e.final_qty) : 0
                    let exists = query.table(this.dataTable.items).where('fas_req_item_id',e.fas_req_item_id).first()
                    if(!exists){
                      this.dataTable.items.push(e);
                    }
                }) 
                this.fas_item = response.data;
            })
        },
        getFasRequisition(){
            this.$store.dispatch('getFasRequesition',{
                type:'ho',
                company_id:'',
                store_branch_id:'',
                transaction_type:this.form.basket_type
            }).then((res)=>{
                this.selection.fas_item_ho = [...res.data]
                this.loaded = false
            });
        },
        getFasRequisitionStores(){
            this.$store.dispatch('getFasRequesition',{
                type:'stores',
                company_id:'',
                store_branch_id:''
            }).then((res)=>{
              this.selection.fas_item_stores = [...res.data]
            });
        },
      async changeQty(item){
        // let findQty = this.$queryBuilder.table(this.dataTable.items).where('fas_req_item_id',item.fas_req_item_id);
        // if(this.dataTable.items[index].final_qty > findQty.first('approved_quantity') || this.dataTable.items[index].final_qty == 0){          
        //     this.dataTable.items[index].final_qty = await findQty.first('approved_quantity')
        // }
        // else{
        //   if(this.dataTable.items[index].price > 0 && this.dataTable.items[index].final_qty > 0)
        //     {
        // let total_amout = findQty.first()
        item.basket_approved_quantity = item.final_qty
        // item.total_amount = item.price * item.basket_approved_quantity
        //     }
        // }
      },
      async changeApprovedQty(item){
        // let findQty = this.$queryBuilder.table(this.dataTable.items).where('fas_req_item_id',item.fas_req_item_id);
        // if(this.dataTable.items[index].final_qty > findQty.first('approved_quantity') || this.dataTable.items[index].final_qty == 0){          
        //     this.dataTable.items[index].final_qty = await findQty.first('approved_quantity')
        // }
        // else{
        //   if(this.dataTable.items[index].price > 0 && this.dataTable.items[index].final_qty > 0)
        //     {
        // let total_amout = findQty.first()
        // item.total_amount = item.price * item.basket_approved_quantity
        //     }
        // }
      },
      findVendorPriceList(item){
          let payload = {
            vendor_id:item.vendor_id,
            item_id:item.fas_item_id
          }
          this.$store.dispatch('getItemPriceListByVendor',payload).then((response)=>{
            if(response.price > 0){
              item.price = parseFloat(response.price).toFixed(2)
            }else{
              item.price = 0;
            }
          })
      },
      setRowStyle(item){
        let remainder = item.basket_approved_quantity % item.moq;
        let itemReceived = item.received_id
        let itemToReceived = item.to_received_id
        let itemNotFound = typeof item.item_id == "string" ? 1 : 0;
        item.buffer_stocks = parseInt(item.moq - remainder) + parseInt(item.basket_approved_quantity) - item.basket_approved_quantity;
        if(remainder){ 
          return {backgroundColor:'#FFF176'}
        }
        else if(itemReceived){
          return {backgroundColor:'#CCFF90'}
        }
        else if(itemToReceived){
          return {backgroundColor:'#D1C4E9'}
        }else if(item.asset_without_tag_per_quantity > 0){
          return {backgroundColor: '#b3e5fc'}
        }else if(item.asset_with_tag_per_quantity > 0){
          return {backgroundColor: '#f4ff81'}
        }
        // else if(itemNotFound){
        //   return {backgroundColor:'#FF9800'}
        // }
        else{
          return {backgroundColor:'#FFFFFF'}
        }
      },
      getAllMoqWarning(){
        let count = 0;
        this.$queryBuilder.table(this.dataTable.items).get().forEach(e=>{
          let data = this.$queryBuilder.table(this.dataTable.items).where('basket_approved_quantity','%',e.moq)
          .where('fas_req_item_id',e.fas_req_item_id);
          count += parseInt(data.count());
        })
       return count;
      },
      getAllStocksWarning(){
        let count = 0;
        this.$queryBuilder.table(this.dataTable.items).get().forEach(e=>{
          let data = this.$queryBuilder.table(this.dataTable.items).where('stocks_qty','<',e.basket_approved_quantity)
          .where('fas_req_item_id',e.fas_req_item_id);
          count += parseInt(data.count());
          // if(data.count()){
          //   console.log(count)
          //   this.withWarnings.push(data.first('item_name'))
          // }
        })
       return count;
      },
      importPrice(){
        this.importDialog = true; 
        this.importVendor.dataTable.items.push({
          item_name:'',
          price:0
        })
      },
      closeImportDialog(){
        this.importDialog = false;
        this.importVendor.dataTable.items = [];
      },
      rowClass(item){
          // return {backgroundColor:'#FFF176'}
          return 'tr-hover';
      },
      onPaste(evt) {
            this.importItems = []
            try{
              var pastedText = evt.clipboardData.getData('Text');
            if (!pastedText) {
                return false;
            }
            else {
                var rows = pastedText.split("\r\n")
                var cells = '';
              
                if (this.importItems.length > 1) {
                 this.importItems.pop();
                }
                for (var y in rows) {

                    if (y != rows.length - 1) {
                        cells = rows[y].split("\t");
                        if (y == rows.length - 1) {
                         
                          this.importItems.push({
                            id:y,
                            item_name: cells[0].replace(/\s$/, ''),
                            price: parseFloat(cells[2]) ? cells[2].replace(/[,]/g,'') : cells[1],
                        })
                        }
                       this.importItems.push({
                        id:y,
                        item_name: cells[0].replace(/\s$/, ''),
                        price: parseFloat(cells[2]) ? cells[2].replace(/[,]/g,'') : cells[1],
                       })
                    }
                }  
            }
            setTimeout(e=>{
              this.importVendor.dataTable.items = [...this.importItems]
            },1)
            }
            catch(err){
              Swal.fire({
                icon:'warning',
                title:'Format Fail!'
              })
            }
            
           
        },
        findVendorPriceListByVendor(){
          let item_id = this.$queryBuilder.table(this.importVendor.dataTable.items_from_fas).pluck('fas_item_id').get();
          this.price_load = true
              let payload = {
              vendor_id:this.importVendor.vendor_id,
              item_id:JSON.stringify(item_id)
            }
            this.$store.dispatch('getItemPriceListByVendor',payload).then((response)=>{
              // if(response.price > 0){
              //   e.price = parseFloat(response.price).toFixed(2)
              // }else{
              //   e.price = 0;
              // }
              // e.price_load = false;
              this.importVendor.dataTable.items_from_fas.forEach(el=>{
                el.price = this.$queryBuilder.table(response).where('item_id',el.fas_item_id).first('price')
              })
              this.price_load = false
            })
        },
        trHover(item,e){
          if(this.hoverID.item != item){
            this.hoverID.count = 0;
          }
            this.hoverID.count += 1;
          
         
          $('.tr-hover-item-'+item).parent().parent().find('tr').find('td').css({'background-color': '#FFFFFF','color':'#000000'})
          $('.tr-hover-vendors-'+item).parent().parent().find('tr').find('td').css({'background-color': '#FFFFFF'})
          $('.tr-hover-item-'+item).parent().parent().find('tr').find('.tr-hover-item-'+item).css({'background-color': '#81D4FA','color':'#00000'});
          $('.tr-hover-vendors-'+item).parent().parent().find('tr').find('.tr-hover-vendors-'+item).css({'background-color': '#81D4FA','color':'#00000'});
          if(this.hoverID.count == 2 && this.hoverID.item){
            this.hoverID.count = 0
            $('.tr-hover-item-'+item).parent().parent().find('tr').find('td').css({'background-color': '#FFFFFF','color':'#000000'})
            $('.tr-hover-vendors-'+item).parent().parent().find('tr').find('td').css({'background-color': '#FFFFFF'})
          }
          this.hoverID.item = item;
          
        },
        saveImportPrice(){
            let payload = {
               vendor_id:this.importVendor.vendor_id,
               items:this.importVendor.dataTable.items,
               basket_id:this.form.id,
            }
            this.$store.dispatch('saveImportPrice',payload).then((e)=>{
              if(e){
                this.importDialog = false
                this.importVendor.dataTable.items = []
                this.importVendor.vendor_id = ''
              }
            });
         
        },
        viewComparison(){
          this.comparisonDialog = true;
          this.comparisonLoaded = false;
          let item_id = null;
          if(this.form.basket_type == 'prf'){
            let findItemId = this.$queryBuilder.table(this.dataTable.items).pluck('item_id').get()
            item_id = JSON.stringify(this.$queryBuilder.table(findItemId)
            .pluck('admin_item_id')
            .get())
          }else{
            item_id = JSON.stringify(this.$queryBuilder.table(this.dataTable.items)
            .pluck('fas_item_id')
            .get())
          }
          let getAllItems = this.$queryBuilder.table(this.dataTable.items)
          .select('id','item_name')
          .get();
          this.selection.comparison_admin_items = getAllItems
          let payload = {
            item_id: item_id,
            basket_id:this.form.id,
          }
          this.$store.dispatch('getItemBasketCanvassing',payload).then((response)=>{
            this.vendorPriceList = response.data
            this.vendorPriceListItems = this.dataTable.items
            this.getLowestPrice()
            this.comparisonLoaded = true
          })
        },
        closeComparisonDialog(){
          this.comparisonDialog = false;
          this.vendorPriceList = [];
        },
        getPrice(item,attr){
          let obj = this.$queryBuilder.table(item.price_lists).where('fas_basket_item_id',attr.id).where('vendor_id',item.vendor_id);
          return obj
        },
        getLowestPrice(){
          this.form.canvassing = []
          this.vendorPriceList.forEach(e=>{
            e.price_lists.forEach(el=>{
              if(el.lowest_price){
                if(!this.form.canvassing.find(e=>e.fas_basket_item_id == el.fas_basket_item_id)){
                  this.form.canvassing.push(el.lowest_price)
                }
              }
            })
          })
        },
        poStatus(item){
          return this.$queryBuilder.table(this.STATUS.ADMIN_PURCHASE_ORDER_STATUS).where('value',item)
        },
        exportItem(){
          let exportHeadersAndData = {
                ['Vendor 1']: {
                    headers: [
                        { header: 'Item', width: 20, key: 'item_name'},
                        { header: 'UOM', width: 20, key: 'uom_name'},
                        { header: 'Price', width: 18, key: '', formatAs:'float',style:{numFmt:'#,##0.00'} },
                    ],
                    data: this.dataTable.items
                },
            }

            this.exportExcel(exportHeadersAndData, `Vendor Price Lists`,'')
        },
        reject(tmp = false){
          Swal.fire({
            icon:"question",
            text:"Are you sure you want to reject? This action cannot be undone.",
            inputPlaceholder:"Why? ",
            showCancelButton:true,
            reverseButtons:true,
            confirmButtonColor: '#397373',
            input:'text',
            preConfirm: (res)=>{
              if(res == ''){
                Swal.showValidationMessage(`Reason is Required`)
              }
            }
          }).then(res=>{
            if(res.isConfirmed && res.value != ''){
              this.form.reject_reason = res.value;
              this.form.status = 5;
              this.createOrUpdateFasItemRequest();
            }
          })
        },
    addItems(){
        this.dataTable.items.push({
          id:0,
          index:this.dataTable.items.length + 1,
          item_id:'',
          uom_id:'',
          basket_approved_quantity:1
        });
        },
      canvassingCondition(items,item){
        return this.form.canvassing.find(e=> e && e.id != this.getPrice(items,item)
        .first('id') && e.fas_basket_item_id == this.getPrice(items,item)
        .first('fas_basket_item_id'))
      },
      canvassingOBJ(items,item){
        return{
          id:this.getPrice(items,item).first('id'),
          fas_basket_item_id:this.getPrice(items,item).first('fas_basket_item_id'),
          vendor_id:this.getPrice(items,item).first('vendor_id'),
          admin_fas_basket_id:this.getPrice(items,item).first('admin_fas_basket_id')
        }
      },
      companies(val){
        if(val){
          const query = this.$queryBuilder
          let store = this.GET_PV_STORE_BRANCHES_SELECTION
          let company = this.GET_PV_COMPANIES_SELECTION
          let find = query.table(store).where('value',val).first();
          if(find){
            this.getAllCompanies = query.table(company).where('value',find.company_id).get();
            this.form.company_id = query.table(company).where('value',find.company_id).first('value')
            this.storesSelect(this.form.company_id)
          }else{
            this.form.company_id = ''
            this.getAllCompanies = query.table(company).get();
            this.clonedStores = query.table(store).get();
          }
        }
      },
      storesSelect(val){
        const query = this.$queryBuilder
        let store = this.GET_PV_STORE_BRANCHES_SELECTION
        let company = this.GET_PV_COMPANIES_SELECTION
        if(val){
          let find = query.table(company).where('value',val).first();
          if(find){
            this.clonedStores = query.table(store).where('company_id',find.value).get();
            this.getAllCompanies = query.table(company).where('value',find.value).get();
            this.form.store_branches_id = query.table(store).where('company_id',find.value).first('value')
          }
        }else{
          this.form.store_branches_id = ''
          this.getAllCompanies = query.table(company).get();
          this.clonedStores = query.table(store).get();
        }
     
      },
      printARF(resp){
            let payload_for_print = {
              department: this.GET_BOUND_EDIT_DATA_ITEMS.items[0].department ? this.GET_BOUND_EDIT_DATA_ITEMS.items[0].department : 'N/A',
              location: this.GET_BOUND_EDIT_DATA_ITEMS.items[0].location_id ? this.GET_BOUND_EDIT_DATA_ITEMS.items[0].location_id : 'N/A',
              admin_accountabilityitem: resp.items,
              types: 'others'
            }
            this.$store.commit('PRINT_ADMIN_ARF', true);
            this.$store.commit('PRINT_ADMIN_ARF_DETAIL',payload_for_print);
            this.$store.commit('BOUND_EDIT_DATA_ITEMS',[]);
            this.$store.commit('DIALOG',false);
        },
        getAllToReceived(){
          let count = 0;
            this.$queryBuilder.table(this.dataTable.items).get().forEach(e=>{
              if(e.item_id.admin_item_id){
                let data = this.$queryBuilder.table(this.dataTable.items).where('to_received_id',e.id);
                count += parseInt(data.count());
              }
            })
          return count;
        },
        getAllReceived(){
          let count = 0;
            this.$queryBuilder.table(this.dataTable.items).get().forEach(e=>{
              if(e.item_id.admin_item_id){
                let data = this.$queryBuilder.table(this.dataTable.items).where('received_id',e.id);
                count += parseInt(data.count());
              }
            })
          return count;
        },
        getAllForPO(){
          let count = 0;
          this.$queryBuilder.table(this.dataTable.items).get().forEach(e=>{
            if(e.item_id.admin_item_id && e.admin_purchase_order_id != null){
              count += 1;
            }
          })
          return count;
        },
        getAllAssets(){
          let count = 0;
          this.$queryBuilder.table(this.dataTable.items).get().forEach(e=>{
            if(e.asset_with_tag_per_quantity){
              count += 1;
            }
          })
          return count;
        },
        getAllAssetsWithoutTag(){
          let count = 0;
          this.$queryBuilder.table(this.dataTable.items).get().forEach(e=>{
            if(e.asset_without_tag_per_quantity){
              count += 1;
            }
          })
          return count;
        },
        async comparisonItemFilter(item){
          let getId = this.$queryBuilder.table(item).pluck('id').get();
          if(item.length > 0){
            this.vendorPriceListItems = await this.$queryBuilder.table(this.dataTable.items).whereIn('id',getId).get()
            this.getLowestPrice()
          }
          else {
            this.viewComparison()
          }
        },
      getAllItemnotfoundWarning(){
          let count = 0;
          this.$queryBuilder.table(this.dataTable.items).get().forEach(e=>{
            if(typeof e.item_id == "string" || typeof e.item_id == "number"){
              count += 1;
            }
          })
        return count;
      },
      disableOnPo(){
        return this.$queryBuilder.table(this.dataTable.items).where('stocks_qty',0).first() ? true : false;
      },
      async redirectToAdminPurchaseOrder(po_num) { 
        this._purchaseorder()
        if(this.purchaseorder_redirect){
          let routes = this.$router.resolve({
            name: "adminpurchaseorder",
            params: {
              id: this.purchaseorder_redirect,
            },
            query: {
              search: btoa(po_num),
            },
        });
        window.open("#"+routes.resolved.fullPath, "_black");
        this.$route.query.search = '';
        }
       
        
      },
      formattedHeaders(headers){
        if([0].includes(this.asset_dialog.type)){
          headers = this.asset_dialog.headers.filter(header => !['asset_without_tag_per_quantity','basket_approved_quantity'].includes(header.value))
          return headers;
        }else if([1].includes(this.asset_dialog.type)){
          headers = this.asset_dialog.headers.filter(header => !['asset_with_tag_per_quantity','basket_approved_quantity'].includes(header.value))
          return headers;
        }else if ([2].includes(this.asset_dialog.type)){
          headers = this.asset_dialog.headers.filter(header => !['asset_with_tag_per_quantity','asset_without_tag_per_quantity'].includes(header.value))
          return headers;
        }else{
          return headers;
        }
      },
      selectedItemToCreatePO(){
        if(this.form.selected_item_to_po.length == 0){
          Swal.fire({
            title: 'Warning',
            text: 'Please Select Items',
            icon: 'warning',
          })
          return
        }
        let auto_add_items = this.dataTable.items.filter(e => e.stocks_qty == 0)
        this.form.selected_item_to_po = this.form.selected_item_to_po.concat(auto_add_items);
        this.$store.dispatch(this.action,this.form).then(res=>{
          this.create_po_dialog.dialog = false;
          this.form.selected_item_to_po = [];
          auto_add_items = []
          this.$store.commit('DIALOG',false);
          Swal.fire('Success', '', 'success')
        });
      },
      showLegendDetails(...val){
        this.asset_dialog.dialog = !this.asset_dialog.dialog; 
        this.legend_text = val[0]; 
        this.asset_dialog.type = val[1]
      },
      fetchItems(){
        if(this.asset_dialog.type == 2){
            return this.$queryBuilder.table(this.dataTable.items).where('to_received_id','>',0).get();
        }else{
          return this.dataTable.items
        }
      }
    },
    watch: {
      PAYLOAD:{
        handler(val){
            if(val && val.index){
              this.dataTable.items[val.index].item_id = {item_name: val.item_name,admin_item_id: val.admin_item_id}
              this.form.fas_items = this.dataTable.items;
              this.$store.commit('ACTION','Update');
              this.$store.dispatch('updateFasBasket',this.form);
            }
        },
      },
      GET_EDIT_FAS_BASKET:{
        handler(val){
          this.loaded = true
            if(val){
             
            }
        }
      },
      GET_NEW_ADMIN_FASBASKET:{
        handler(val){
          if(val){
            if(Object.keys(this.PAYLOAD).length === 0){
              this.closeDialog();
              Swal.fire("Success!", "Basket Saved", "success");
            }
          }
        }
      },
      DIALOGS: {
        handler(val) {
         
        },
        deep:true
      },
        'form.fas_id':{
        handler(val){
                if(val){
                   
                }
            },
            deep:true,
        },
        'dataTable.items':{
          handler(val){
            if(val){
              this.itemVendorPriceList.push(val)
              this.selection.admin_items.forEach(el=>{
                  el.isDisabled = val.filter(e=>e.item_id.admin_item_id == el.admin_item_id).length == 1 ? true : false
              })
              // this.form.total_amount = this.$queryBuilder.table(val).sum('total_amount');
            }
          },
          deep:true,
        },
    'form.basket_type':{
      handler(val){
        this.loaded = true;
        this.getFasRequisitionStores()
        this.getFasRequisition()
        this.getItemsSelection()
        this.$store.dispatch('getPvCompanies',{});
        this.getBranches()
      },
      deep:true
    },
    GET_BOUND_EDIT_DATA_ITEMS:{
      handler(val){
        if(val){
          
        }
      },
      deep:true
    }
    },
   
  };
</script>