<template>
    <v-container fluid class="pa-3">
            <h3 class="page-title">Transaction Journal</h3>
        <v-container class="m-0">
            <v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" dark background-color="#424242" class="col-12">
                <v-tab
                    v-for="(item, i) in tabs"
                    :key="i"
                    ripple
                    @click="commitTabData(i)"
                >
                    {{ item.tab_name }}
                </v-tab>
                <v-spacer/>
                <v-tab @click="commitTabData(4)">Types</v-tab>
            </v-tabs>
            <TransactionJournalComponent v-if="[0,1,2,3].includes(this.tab)"/>
            <TransactionJournalTypeComponent v-if="[4].includes(this.tab)"/>
        </v-container>
    </v-container>
</template>
<script>
import TransactionJournalComponent from '@/views/main/modules/Accounting/TransactionJournal/TransactionJournalComponent'
import TransactionJournalTypeComponent from '@/views/main/modules/Accounting/TransactionJournal/TransactionJournalTypeComponent'
export default {
    data(){
        return{
            tab:0,
            tabs: [
                { tab_name: 'ALL' },
                { tab_name: 'PENDING' },
                { tab_name: 'APPROVED' },
                // { tab_name: 'PARTIAL' },
                { tab_name: 'PAID' }
            ]
        }
    },
    components:{
        TransactionJournalComponent,
        TransactionJournalTypeComponent
    },
    mounted(){
        this.$store.dispatch('getCOASelection')
        this.$store.dispatch('getDepartmentListforAccounting')
        this.$store.dispatch('getTransactionTypeSelection')
        this.$store.dispatch('getAdminSupplierSelection')
        this.$store.dispatch('getPvCompanies');
        this.$store.dispatch('getPaymentVoucherBranchesSelection')
        this.$store.dispatch('getAccountingJobSelection')
        this.$store.dispatch('getTransactTypesSelection',{
            include_model_name:1
        });
    },
    methods: {
        commitTabData(key){
            this.$store.commit('TRANSACTION_JOURNAL_TAB', this.tabs[key])
        }
    },
    watch: {
        tab:{
            handler(val){
                this.$store.commit('SELECTED_TAB', val)
            }
        }
    }
}
</script>