<template>
    <v-container fluid>
        <h3 class="page-title">QA Tester Check Lists</h3>
        <v-container class="ma-0">
            <v-app id="item-tabs">
                <v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" background-color="#424242" :loaded="loaded" dark show-arrows>
					<v-tabs-slider color="cyan" :loaded="loaded"></v-tabs-slider>
                    <v-tab v-for="(item, index) in items" :key="item" @click="tabs(index)" :loaded="loaded">
                        {{ item }}
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item  v-for="(items,index) in qa_component" :key="index">
                        <keep-alive>
                            <ServiceTesterCheckListComponent :is="items.components" :qa_component="qa_component" :isActive_tab="items.status_text" :tab="index" :loaded="loaded"></ServiceTesterCheckListComponent>
                        </keep-alive>
                    </v-tab-item>
                </v-tabs-items>
            </v-app>
        </v-container>
            <DialogComponent :cp="components"  :scrollable="dialog_scrollable" :width="height(dialog_width)" :retain-focus="false" :loaded="loaded"></DialogComponent>
    </v-container>
</template>

<script>
import DialogComponent from '@/views/main/layouts/dialogs/Dialog.vue';

import ServiceTesterCheckListComponent from "@/views/main/modules/Services/Views/ServiceTesterComponent/ServiceTesterCheckListComponent.vue";

import ServiceTesterCheckListViewComponent from '@/views/main/layouts/dialogs/Service/CheckList/ServiceTesterCheckListViewComponent.vue';
import ServiceTesterItemsViewComponent from '@/views/main/layouts/dialogs/Service/CheckList/ServiceTesterItemsViewComponent.vue';
import ServiceTesterTemplateViewComponent from '@/views/main/layouts/dialogs/Service/CheckList/ServiceTesterTemplateViewComponent.vue';

import { mapGetters } from 'vuex';
export default {
    components:{
		DialogComponent,  
        ServiceTesterCheckListComponent,
        ServiceTesterCheckListViewComponent,
        ServiceTesterItemsViewComponent,
        ServiceTesterTemplateViewComponent
    },
    data(){
        return {
            components:'',
            items: ["Items", "Template", "Check List"],
            qa_component:[
                {
                components: ServiceTesterCheckListComponent,
                status_text: "item",
                dialog: ServiceTesterItemsViewComponent,
                dialog_title: 'Item',
                dialog_width: "35%",
                scrollable:true,
                dispatch:{
                        get:['itemsGet','item-with-template'],  // ['dispatch_name', 'url']
                        update:['itemsPost', 'update-item-template'],
                        delete:['itemsPost', 'delete-item-template'],
                    },
                },
                {
                components: ServiceTesterCheckListComponent,
                status_text: "template",
                dialog: ServiceTesterTemplateViewComponent,
                dialog_title:'Template',
                dialog_width: "35%",
                scrollable:true,
                dispatch:{
                        get:['serviceGet', 'qa-template'],
                        add:['servicePost', 'add-template'],
                        update:['servicePost','update-template'],
                        delete:['servicePost', 'destroy-template']
                    },
                },
                {
                components: ServiceTesterCheckListComponent,
                status_text: "checklist",
                dialog: ServiceTesterCheckListViewComponent,
                dialog_title:'Checklist',
                dialog_width: "35%",
                scrollable:true,
                dispatch:{
                        get:['serviceGet', 'qa-checklist'],
                        add:['servicePost', 'addchecklist'],
                        update:['servicePost', 'updatechecklist'],
                        delete:['servicePost', 'destroy-checklists']
                    },
                },
            ],

            dialog_width: "",
            loaded: false,
            options: {},
            itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
            itemsPerPage: 10,
            tab: null,
            dialog_scrollable:true,
        }
        
    },
    methods: {
        changeTab(index){
            this.$store.commit('TAB', index)
        },

        tabs(key) {
			this.components = this.qa_component[key].dialog;
			this.dialog_width = this.qa_component[key].dialog_width;
			this.dialog_scrollable = this.qa_component[key].scrollable;
			
            this.$store.commit('REFRESH', true)
			this.$store.commit('CHECKLIST_DIALOG_TITLE',this.qa_component[key].dialog_title)
			this.$store.commit('TAB',this.qa_component[key].status_text)
			this.$store.commit('CHECKLIST_DISPATCH',this.qa_component[key].dispatch)
            
			// this.$store.commit('TAB',this.qa_component[key].getters)
		},

        height(width) {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs': return "100%"
				case 'sm': return "100%"
				case 'md': return width
				case 'lg': return width
				case 'xl': return width
			}
		},
    },

    computed: {
        ...mapGetters([
            'TESTER_CHECK_LISTS',
            'GET_ALL_LIST_TEMPLATE',
            'GET_REFRESH',
            'GET_TABS',
        ]),
    },

    mounted() {
        this.tabs(0)
        this.$store.dispatch('getListTemplate',{code:'SR'})
    }

}
</script>

<style scoped>
#group-tab {
		padding: 0 !important;
	}
</style>
