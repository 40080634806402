	<template>
		<v-container fluid >
			<h3 class="page-title">Plantilla Management</h3>
			<v-container class="ma-0">
					<v-app id="item-tabs">
						<v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" background-color="#424242" dark show-arrows>
							<v-tabs-slider color="cyan"></v-tabs-slider>
								<v-tab ripple v-for="(item, i) in items" :key="item" @click="tabs(i)">
									{{ item }}
								</v-tab>
						</v-tabs>
						<v-tabs-items v-model="tab">
							<v-tab-item v-for="(table, index) in plantilla_component" :key="index">
								<keep-alive>
									<component :is="table.components" :plantilla_component="plantilla_component" :isActive_tab="table.status_text">
									</component>
								</keep-alive>
							</v-tab-item>
						</v-tabs-items>
					</v-app>
			</v-container>
			<DialogComponent :cp="components" :scrollable="dialog_scrollable" :width="height(dialog_width)" :retain-focus="false"></DialogComponent>
		</v-container>
	</template>

<script>
	import DialogComponent from '@/views/main/layouts/dialogs/Dialog.vue';

	import HRPlantillaComponent from '@/views/main/modules/HR/Plantilla/HRPlantillaComponent.vue'

	import HRPlantillaViewComponent from '@/views/main/layouts/dialogs/HR/Plantilla/PlantillaViewComponent.vue'

	import { mapGetters } from 'vuex';
	import PusherFunctionComponent from '@/views/main/Shared/PusherFunctionComponent.vue';
	export default {
		mixins:[PusherFunctionComponent],
		components:{
			DialogComponent,
			HRPlantillaComponent,
			HRPlantillaViewComponent

		},
		data() {
			return {
				components:'',
				items: ["Plantilla List"],
				plantilla_component: [
						{
						components: HRPlantillaComponent,
						status_text: "plantilla_list",
						dialog: HRPlantillaViewComponent,
						dialog_title:'Plantilla',
						dialog_width: "60%",
						scrollable:true,
						dispatch:{get:'GetHrPlantilla',
									add:'AddHrPlantilla',
									update:'UpdateHrPlantilla',
									delete:'DeleteHrPlantilla'},
						},
				],
				dialog_width: "",
				loaded: false,
				options: {},
				itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
				itemsPerPage: 10,
				tab: null,
				dialog_scrollable:true,
			};
		},
		computed:{
			...mapGetters(['USERACCOUNT_LOAD'])
		},
		mounted(){
		this.tabs(0)
		this.$store.dispatch('GetHrPlantilla')
		},
		methods:{
		tabs(key) {
			this.components = this.plantilla_component[key].dialog;
			this.dialog_width = this.plantilla_component[key].dialog_width;
			this.dialog_scrollable = this.plantilla_component[key].scrollable;

			this.$store.commit('HR_DIALOG_TITLE',this.plantilla_component[key].dialog_title)
			this.$store.commit('HR_TAB_IS_ACTIVE',this.plantilla_component[key].status_text)
			this.$store.commit('HR_DISPATCH',this.plantilla_component[key].dispatch)
			this.$store.commit('HR_GETTERS',this.plantilla_component[key].getters)
		},
		getSearchFromUrl() {
            if (Object.keys(this.$route.query).length != 0) {
                Object.keys(this.$route.query).forEach(e=>{
					if(e == 'for_approval'){
						this.tab = this.items.length - 1
						this.tabs(this.tab)
					}
                })
            }
        },
		height(width) {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs': return "100%"
				case 'sm': return "100%"
				case 'md': return width
				case 'lg': return width
				case 'xl': return width
			}
		},
	},
	watch:{
		USERACCOUNT_LOAD:{
			handler(val){
			}
		}
	}

	};
	</script>
	<style>
	#group-tab {
		padding: 0 !important;
	}
	</style>
