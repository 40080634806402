<template>
    <div class="tabs-body-color">
        <v-container fluid>
            <v-row>
                <AutoFocTableComponent v-if="isActive_tab == 'sp_item'" ></AutoFocTableComponent >  
            </v-row>
        </v-container>
    </div>
</template>

<script>
import AutoFocTableComponent from "@/views/main/modules/Services/Views/AutoFocComponent/AutoFocTableComponent.vue";
import Swal from 'sweetalert2'
import { mapGetters } from 'vuex';
export default {

    data(){
        return{
            
        }
    },

    props:["isActive_tab"],

    components: {
        AutoFocTableComponent,
    },

    computed: {
        ...mapGetters([
            'GET_REFRESH',
            'GET_FOC_TAB',
        ]),
    },

    mounted() {
        // this.$store.dispatch('getAutoFocSp', {code: 'SP'})
    },

    watch: {
        'isActive_tab':{
            handler(val){
                this.$store.commit('REFRESH', true)
            }
        }
    }
}
</script>

<style>

</style>