<template>
    <v-form ref="form" v-model="valid" lazy-validation>
        <!-- Functions -->
        <v-card class="px-3 rounded-0">
            <v-card-subtitle>
                <v-row>
                    <v-col cols="1">
                        <v-btn v-if="userAccess.includes('create')" class="m-3"
                            @click="()=>{
                                toggleDialog('open')
                                $store.commit('RECURRING_BILL_ACTION', 'create')
                            }"
                        >
                            <v-icon>mdi-plus</v-icon>Create
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-spacer/>
                    <v-col cols="3" class="pull-right">
                        <v-text-field
                            v-model="search"
                            label="Search by "
                            append-icon="mdi-magnify"
                            @keydown.enter="getAllRBTypes()"
                            @click:append="getAllRBTypes()"
                        />
                    </v-col>
                </v-row>
            </v-card-subtitle>
            <!-- Table -->
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-data-table
                            :headers="mainHeaders"
                            :items="mainItems"
                            :options.sync="options"
                            :server-items-length="totalItems"
                            :items-per-page="10"
                            :loading="tableLoad"
                            :footer-props="{
                                itemsPerPageOptions: itemsPerPageOptions
                            }"
                            dense
                        >
                            <template v-slot:[`item.coa_id`]="{ item }">
                                <span>
                                    {{ GET_CHART_OF_ACCOUNTS_SELECTION.find(e=>e.id == item.coa_id)?.account_name }}
                                </span>
                            </template>
                            <template v-slot:[`item.action`]="{ item }">
                                <v-btn v-if="userAccess.includes('view')" text icon color="orange" @click="viewEdit('view', item.id)">
                                    <v-icon small>mdi-eye</v-icon>
                                </v-btn>
                                <v-btn v-if="userAccess.includes('edit')" text icon color="primary" @click="viewEdit('edit', item.id)">
                                    <v-icon small>mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn v-if="userAccess.includes('delete')" text icon color="error" @click="destroy(item.id)">
                                    <v-icon small>mdi-delete</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
            <!-- Dialog -->
            <v-dialog v-model="mainDialog" persistent scrollable width="45%">
                <v-card elevation="0" class="pa-0 m-0 overflow-hidden">
                    <v-card-title v-if="!dialogLoader">
                        <v-container class="pa-0">
                            <v-row class="m-0">
                                <v-col>
                                    <span class="headline">{{ RECURRING_BILL.ACTION.charAt(0).toUpperCase() + RECURRING_BILL.ACTION.slice(1) }} Recurring Bill Type</span>
                                </v-col>
                                <v-col cols="pull-right-1">
                                    <v-btn text icon color="gray" class="float-right"
                                        @click="toggleDialog('close')"
                                    >
                                        <v-icon>mdi-close-circle</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-card elevation="0" dense>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-card elevation="0" dense>
                                                <v-card-text>
                                                    <v-row>
                                                        <v-col>
                                                            <v-text-field
                                                                v-model="form.code"
                                                                :readonly="isDisabled()"
                                                                :rules="required"
                                                                label="Code"
                                                                outlined
                                                                dense
                                                            />
                                                        </v-col>
                                                        <v-col>
                                                            <v-text-field
                                                                v-model="form.name"
                                                                :readonly="isDisabled()"
                                                                :rules="required"
                                                                label="Name"
                                                                outlined
                                                                dense
                                                            />
                                                        </v-col>
                                                        <v-col>
                                                            <v-autocomplete
                                                                v-model="form.coa_id"
                                                                :items="GET_CHART_OF_ACCOUNTS_SELECTION"
                                                                :readonly="isDisabled()"
                                                                :rules="required"
                                                                item-text="account_name"
                                                                item-value="id"
                                                                label="Select CoA"
                                                                outlined
                                                                dense
                                                            />
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <v-container class="mt-5">
                                <v-row>
                                    <v-col class="text-center">
                                        <v-btn
                                            v-if="RECURRING_BILL.ACTION != 'view'"
                                            :loading="btnLoad"
                                            @click="commitRB(RECURRING_BILL.ACTION, RECURRING_BILL.ACTION == 'create' ? 'addRBType' : 'updateRBType')"
                                        >
                                            {{ RECURRING_BILL.ACTION == 'create' ? 'Create' : 'Update' }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-container>
                    </v-card-title>
                    <div v-else class="text-center pa-2">
                        <v-progress-circular
                            :size="80"
                            color="primary"
                            indeterminate
                        />
                    </div>
                </v-card>
            </v-dialog>
        </v-card>
    </v-form>
</template>
<script>
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue'
export default {
    mixins: [SharedFunctionsComponentVue],
    props: ['userAccess'],
    data(){
        return{
            valid: true,
            mainDialog: false,
            mainHeaders: [
                { text: 'Code', value:'code', align: 'left', sortable: false },
                { text: 'Name', value:'name', align: 'left', sortable: false },
                { text: 'CoA', value:'coa_id', align: 'left', sortable: false },
                { text: 'Actions', value:'action', align: 'center', sortable: false }
            ],
            mainItems: [],
            options: {},
            totalItems: 0,
            tableLoad: false,
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            itemsPerPage: 10,
            search: '',
            dialogLoader: false,
            btnLoad: false,
            form:{
                id: null,
                code: null,
                name: null,
                coa_id: null
            },
            required: [
                v => !!v || 'This field is required',
            ],
        }
    },
    computed:{
        ...mapGetters([
            'RECURRING_BILL',
            'GET_CHART_OF_ACCOUNTS_SELECTION'
        ])
    },
    mounted(){
        document.addEventListener('keydown', this.handleKeyDown)
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.handleKeydown);
    },
    methods:{
        toggleDialog(action = 'close'){
            switch(action){
                case 'open':
                    this.mainDialog = true
                    break;
                case 'close':
                    this.mainDialog = false
                    this.getAllRBTypes()
                    this.clearFields()
                    break;
            }
        },
        clearFields(){
            this.$refs.form.resetValidation();
            this.$store.commit('RECURRING_BILL_ACTION', '')
            this.form = {
                id: null,
                code: null,
                name: null,
                coa_id: null
            }
        },
        commitRB(action, url){
            if (!this.$refs.form.validate()) {
                if (!this.checkRequiredFields()) return
            } else {
                this.btnLoad = true
                Swal.fire({
                    title: '',
                    text: `- Are you sure you want to ${action}?`,
                    icon: 'question',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    allowOutSideClick: false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor: 'grey',
                    confirmButtonColor: 'Confirm'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch('recurringBillPost', {form: this.form , url: url}).then(response => {
                            Swal.fire('', '- Type Saved!', 'success')
                            this.getAllRBTypes()
                            this.clearFields()
                            this.mainDialog = false
                            this.btnLoad = false
                        }).catch(error => {
                            if (error.response.status == 422) {
                                Swal.fire('', '- Error!', 'error')
                                console.log(error)
                                this.btnLoad = false
                            }
                        })
                    } else {
                        this.btnLoad = false
                    }
                })
            }
        },
        checkRequiredFields(){
            let emptyFields = []
            let required = {
                'code': 'Code',
                'name': 'Name',
                'coa_id': 'CoA'
            }
            Object.keys(required).forEach(e=>{
                if (!this.form[e]) {
                    emptyFields.push(required[e])
                }
            })
            if (emptyFields.length > 0) {
                Swal.fire({
                    title: 'Please fill the following fields: ',
                    html: emptyFields.map(field => `${field}<br>`).join(''),
                    icon: 'warning'
                })
                return false
            }
            return true
        },
        getAllRBTypes(){
            this.tableLoad = true
            this.mainItems = []
            let payload = {
                search: this.search,
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                url: 'getAllRBTypes'
            }
            this.$store.dispatch('recurringBillGet', payload).then(response=>{
                let data = response.data.data
                this.mainItems = data.data
                this.totalItems = data.total
                this.tableLoad = false
            })
        },
        viewEdit(action, id){
            this.dialogLoader = true
            this.mainDialog = true
            this.$store.commit('RECURRING_BILL_ACTION', action)
            this.$store.dispatch('recurringBillPost', {id: id, url: 'getRBType'}).then(response=>{
                this.form = { ...response.data }
                this.dialogLoader = false
            })
        },
        isDisabled(){
            switch(this.RECURRING_BILL.ACTION){
                case 'view':
                    return true
            }
        },
        destroy(id){
            Swal.fire({
                title: '',
                text: '- Are you sure you want to delete ?',
                icon: 'question',
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                allowOutSideClick: false,
                confirmButtonColor: '#397373',
                cancelButtonColor: 'grey',
                confirmButtonColor: 'Confirm'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('recurringBillPost', {id: id, url: 'deleteRBType'}).then(response => {
                        Swal.fire('', '- Type Deleted!', 'success')
                        this.getAllRBTypes();
                    }).catch(error => {
                        if (error.response.status == 422) {
                            Swal.fire('', '- Error!', 'error')
                            console.log(error)
                        }
                        this.getAllRBTypes()
                    })
                }
            })
        },
        handleKeyDown(event){
            if (this.mainDialog) this.closeDialogByEsc(event, this.toggleDialog)
        }
    },
    watch:{
        options:{
            handler(){
                this.getAllRBTypes()
            },
            deep:true
        }
    }
}
</script>
<style scoped>

</style>