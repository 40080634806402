<template>
    <v-card style="border-radius: 0px;" class="ma-0 pa-0 h-100" flat>
        <v-card-title>
            <v-row class="mx-2 mb-1 d-flex justify-content-center align-items-center">
                <v-col cols="4">
                    <v-img src="@/assets/ingcoph_logo/ingcoph_logo.jpg" :aspect-ratio="16/9" width="200px" contain></v-img>
                </v-col>
                <v-col class="d-flex justify-content-center align-items-center" cols="4">
                    <h2>{{ !!url_meta.type_text ? `${url_meta.queueing_type} (${url_meta.type_text})` : `${url_meta.queueing_type}` }}</h2>
                </v-col>
                <v-spacer></v-spacer>
            </v-row>
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-spacer></v-spacer>
                <v-col cols="3">
                    <v-text-field
                        v-model="form.employee_id"
                        @keypress.enter="findEmployee()"
                        prepend-inner-icon="mdi-account"
                        label="Employee ID"
                        type="number"
                        min="0"
                        clearable
                        outlined
                        dense
                    ></v-text-field>
                </v-col>
                <v-spacer></v-spacer>
            </v-row>
        </v-card-text>
        <v-dialog v-model="passcode.dialog" :width="height('30%')" persistent>
            <v-card>
                <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                <span class="text-h5">Confirm Passcode</span>
                    <v-row class="m-0">
                        <v-col cols="pull-right-10 p-2">
                            <v-btn
                                text
                                icon
                                small
                                color="gray"
                                class="float-right"
                                @click="passCodeDialog(false)"
                            >
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-row class="mt-2">
                        <v-col
                            cols="12"
                            sm="12"
                        >
                            <v-text-field
                                v-model="passcode.value"
                                @click:append="show1 = !show1"
                                @keypress.enter="checkPassCode()"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="show1 ? 'text' : 'password'"
                                name="input-10-1"
                                label="Passcode"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                            <!-- <input type="password" class="form-control" id="override_user_password"  v-model="passcode.value" autocomplete="new-password" autofocus> -->
                    </v-row>
                </v-card-text>
                <v-card-actions
                    class="text-center"
                    style="border-top: 1px solid #dee2e6"
                >
                    <v-row class="ma-1  pb-2">
                        <v-col>
                            <v-btn @click="checkPassCode()">Confirm</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="verifyDialog" :width="height('30%')" persistent>
            <v-card style="overflow:hidden">
                <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                <span class="text-h5">Verify It's you</span>
                    <v-row class="m-0">
                        <v-col cols="pull-right-10 p-2">
                            <v-btn
                                text
                                icon
                                small
                                color="gray"
                                class="float-right"
                                @click="verifyDialogTrigger(false)"
                            >
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-row class="pa-5">
                    <v-col cols="9">
                        <span>An OTP code will be sent to your email</span>
                        <v-radio-group v-model="active">
                            <v-radio
                                :label=" maskEmail(GET_HR_VERIFICATION.email)"
                                :value="1"
                                name="active"
                                class="mt-3 mb-3"
                            >
                            </v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col cols="3">
                        <v-row>
                            <v-col align="center" class="mt-5">
                                <v-icon style="font-size: 70px;">mdi-account-circle</v-icon>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-card-actions
                    class="text-center"
                    style="border-top: 1px solid #dee2e6"
                >
                    <v-row class="ma-1  pb-2">
                        <v-col>
                            <v-btn
                                @click="sendVerificationEmail()"
                                :loading="loading.confirm"
                            >Confirm</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <DialogComponent :cp="components" :width="height('40%')" :retain-focus="false"></DialogComponent>
        <LeaveRequestVerificationComponentViewVue/>
    </v-card>
</template>
<script>
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue'
import LeaveRequestViewComponent from '@/views/main/layouts/dialogs/HR/LeaveManagement/LeaveRequestViewComponent.vue'
import DialogComponent from '@/views/main/layouts/dialogs/Dialog.vue';
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2';
import LeaveRequestVerificationComponentViewVue from '@/views/main/layouts/dialogs/HR/LeaveManagement/LeaveRequestVerificationComponentView.vue';

export default {
    mixins: [SharedFunctionsComponentVue],
    components:{
        DialogComponent,
        LeaveRequestViewComponent,
        LeaveRequestVerificationComponentViewVue
    },
    data(){
        return {
            date: this.$dayjs().format('YYYY-MM-DD'),
            url_meta:{
                status_color:this.$router.currentRoute.meta.status_color,
                statuses:this.$router.currentRoute.meta.statuses,
                queueing_type:this.$router.currentRoute.meta.title,
                url:this.$router.currentRoute.meta.url,
                realtime_event:this.$router.currentRoute.meta.realtime_event,
                toggle_icon:this.$router.currentRoute.meta.toggle_icon,
                type_id:this.$router.currentRoute.meta.type_id,
                type_text:this.$router.currentRoute.meta.type_text,
            },
            form: {},
            components:'',
            passcode:{
                dialog: false,
                employee_passcode: '',
                value: '',
            },
            show1: false,
            getters: {
                get:'GetHrLeavesRequest',
                add:'AddHrLeavesRequest',
                update:'UpdateHrLeavesRequest',
                delete:'DeleteHrLeavesRequest',
                show: 'ShowHrLeavesRequest'
            },
            defaults: {
                dialog: false,
                employee_passcode: '',
                value: '',
            },
            verifyDialog: false,
            active: 1,
            loading:{
                confirm: false
            }
        }
    },
    async mounted(){
        await this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        this.components = LeaveRequestViewComponent;
        this.$store.commit('HR_DISPATCH',this.getters)
        this.getEmployeeDropdown();
    },
    methods:{
        async getEmployeeDropdown(){
            let payload = {
                active: 1
            }
            this.$store.dispatch('getEmployeeDataDropdown', payload)
        },
        async findEmployee(){
            let payload = {
                employee_id: this.form.employee_id
            }
            this.$store.dispatch('getEmployeeDataDetails', payload).then((res)=>{
                if(res){
                    this.$store.commit("HR_LEAVES_DATA_VIEW", res);
                    if(!res.personal_email){
                        Swal.fire(
                            "No Personal Email registered!",
                            "Please contact HR to update your record",
                            "warning"
                        )
                        return false
                    }
                    this.$store.commit("HR_VERIFICATION_EMPLOYEE_ID", res.id);
                    this.$store.commit("HR_VERIFICATION_EMAIL", res.personal_email);
                    this.verifyDialogTrigger(true)
                    // this.passcode.employee_passcode = data_found.passcode;
                    // this.passCodeDialog(true);
                    // this.openVerificationCodeDialog()
                    // this.openDialog();
                }
                else{
                    Swal.fire(
                        "Employee not found!",
                        "Please check Employee ID!",
                        "warning"
                    );
                    return false;
                }
            }).catch((err)=>{
                Swal.fire(
                    "Network Error",
                    "",
                    "error"
                )
                console.log(err)
            })
        },
        openDialog(){
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", "Submit");
        },
        height(width) {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs': return "100%"
				case 'sm': return "100%"
				case 'md': return width
				case 'lg': return width
				case 'xl': return width
			}
		},
        passCodeDialog(value){
            this.passcode.dialog = value;
        },
        checkPassCode(){
            if(this.passcode.value === this.passcode.employee_passcode){
                this.openDialog();
                this.passCodeDialog(false);
                this.resetPasscodeObj();
            }else{
                Swal.fire(
                    "Incorrect Passcode!",
                    "",
                    "warning"
                );
                this.passcode.value = null;
                return false
            }
        },
        resetPasscodeObj(){
            Object.assign(this.passcode, {
                dialog: this.defaults.dialog,
                employee_passcode: this.defaults.employee_passcode,
                value: this.defaults.value,
            })
        },
        openVerificationCodeDialog(){
            this.$store.commit('HR_VERIFICATION_DIALOG', true);
        },
        maskEmail(email) {
            if(email){
                const parts = email.split('@');
                const username = parts[0];
                const domain = parts[1];
                const maskedUsername = username.substr(0, 2) + '*'.repeat(username.length - 2);
                const maskedEmail = maskedUsername + '@' + domain;
                return maskedEmail;
            }
        },
        verifyDialogTrigger(value){
            this.verifyDialog = value;
        },
        sendVerificationEmail(){
            this.loading.confirm = true;
            let payload = {
                employee_id: this.form.employee_id
            }
            this.$store.dispatch('generateNewVerCode', payload).then((res)=>{
                Swal.fire({
                    title: res.icon.toUpperCase(),
                    html: res.message,
                    icon: res.icon,
                    confirmButtonText: `Ok`,
                }).then(action=>{
                    if(action.isConfirmed){
                        this.verifyDialogTrigger(false)
                        this.openVerificationCodeDialog();
                        this.loading.confirm = false;
                    }
                })
                // this.setCount();
                // this.loading = false
            }).catch((err)=>{
                Swal.fire(
                    "Network Error",
                    "",
                    "error"
                );
                this.loading.confirm = false;
                console.log(err)
            })
        }
    },
    computed: {
        ...mapGetters([
            'USER_ACCESS',
            'GET_EMPLOYEE_DATA',
            'GET_HR_LEAVES_NEW_DATA',
            'GET_HR_VERIFICATION'
        ])
    },
    watch:{
        GET_HR_LEAVES_NEW_DATA:{
            handler(val){
                if(val){
                    this.form.employee_id = null;
                    this.$store.commit('HR_LEAVES_NEW_DATA', false);
                }
            }
        },
    }
}
</script>

<style scoped>
.ul-listing {
    column-count: auto;
    column-gap: normal;
    column-fill: auto;
    height:77vh;
    overflow:auto
}
.large-spacing{
    column-width: 9.03rem;
}
.small-spacing{
    column-width: 8.5rem;
}
.toggle-list{
    max-height: 500px;
    column-count: auto;
    flex-flow: column wrap;
    list-style-type: disc;
    list-style-position: inside;
    overflow:auto
}
.listItem{
    display: inline-block;
    flex-direction: column;
    align-items: start;
    min-height: 0px !important;
    align-items: left !important;
    align-self:start !important
}
</style>
