<template>
    <v-dialog v-model="GET_BIOMETRICS_SUMMARY_DIALOG" persistent width="90%" class="overflow-auto" :retain-focus="false" scrollable>
        <v-card>
            <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                <span class="text-h5">Biometrics Summary</span>
                <v-row class="m-0">
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                            text
                            icon
                            small
                            color="gray"
                            class="float-right"
                            @click="closeDialog()"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-container class="pb-0 my-2" fluid>
                    <v-row>
                        <v-col cols="12" sm="5" class="pa-0 pr-1">
                            <v-text-field
                                v-model="form.bio_period"
                                label="Biometrics Period"
                                readonly
                                background-color="grey lighten-2"
                                outlined
                                required
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="12" sm="4" class="pa-0 pr-1">
                            <v-textarea
                                v-model="form.remarks"
                                label="Remarks"
                                outlined
                                dense
                                rows="2"
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-spacer></v-spacer>
                         <v-col cols="4">
                            <v-card>
                                <v-card-text>
                                    <v-row no-gutters>
                                        <v-col>
                                            Legend:
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <!-- <v-col v-for="(item, i) in legends" :key="i" cols="2" class="ma-0 "> -->
                                        <span v-for="(item, i) in legends" :key="i" class="ma-1">
                                            <v-chip :color="item.color" small>
                                                <span :style="`color:${item.text_color}`">{{ item.text }}</span>
                                            </v-chip>
                                        </span>
                                    <!-- </v-col> -->
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-spacer></v-spacer>
                        <v-col cols="2">
                            <v-switch
                                v-model="filters.with_incomplete_time_in_out"
                                :true-value="1"
                                :false-value="0"
                                label="Show Incomplete Records"
                                dense
                            >
                            </v-switch>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-card class=" pa-0 overflow-auto" style="max-height:60vh;" elevation="0">
                                <v-data-table
                                    v-if="filters.with_incomplete_time_in_out == 0"
                                    :headers="datatable.headers"
                                    :items="datatable.items"
                                    :expanded.sync="datatable.expanded"
                                    :loading="loading.datatable"
                                    :item-class="itemRowBackground"
                                    @item-expanded="loadItemsDetails"
                                    show-expand
                                    disable-sort
                                    item-key="employee_id"
                                    dense
                                >
                                    <template v-slot:[`item.employee_name`]="{item}">
                                            <span>{{ item.employee_name }}</span>
                                            <v-icon color="red" class="ml-2" small v-if="item.inCompleteLog > 0 && item.schedule.schedule_type_id !== 3">mdi-alert</v-icon>
                                    </template>
                                    <template v-slot:expanded-item="{ headers, item: parentItem }">
                                        <td :colspan="headers.length" >
                                            <v-data-table
                                                :headers="datatable.sub_header"
                                                :items="parentItem.biometricdata"
                                                :items-per-page="-1"
                                                class="w-100"
                                                disable-sort
                                                hide-default-footer
                                                dense
                                            >
                                                <template v-slot:header.action>
                                                    <v-row>
                                                        <v-col cols="4">
                                                            <v-btn fab dark small color="primary"
                                                                style="height: 20px; width: 20px"
                                                                @click="addRecord(parentItem), getCurrentIndexDates(parentItem.biometricdata)"
                                                            >
                                                                <v-icon dark small style="font-size: 12px">mdi-plus</v-icon>
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </template>
                                                <template v-slot:item="{ item, index }" >
                                                    <tr v-if="item.editActive == false"
                                                        :class="{
                                                            'leave_w_pay': checkLeaveData(item, 1),
                                                            'leave_wo_pay': checkLeaveData(item, 0),
                                                            'highlight_manual': item.manual == 1,
                                                            'deleted_item': item.deleted_record == 1,
                                                            'with_no_time_out': checkIfWithNoTimeOut(item, parentItem),
                                                            'rest_day_schedule': item.isRestDay,
                                                            'no_record': item.no_record == 1 && item.isRestDay == 0,
                                                        }"
                                                        >
                                                        <td> {{ item.date }} </td>
                                                        <td> {{ item.day }} </td>
                                                        <td> {{ item.excess_hours }} </td>
                                                        <td> {{ item.hours_worked }} </td>
                                                        <td> {{ item.late_in_hours }} </td>
                                                        <td> {{ item.location }} </td>
                                                        <td> {{ formatTime(item.date + ' ' +  item.time_in) }} </td>
                                                        <td> {{ formatTime(item.date + ' ' +  item.time_out) }} </td>

                                                        <td>
                                                            <v-tooltip bottom color="#337CCF">
                                                                <template v-slot:activator="{ on, attrs }" >
                                                                    <v-btn
                                                                        icon
                                                                        small
                                                                        @click="item.editActive = true"
                                                                        color="#337CCF"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                    >
                                                                        <v-icon>mdi-calendar-edit</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Edit Record</span>
                                                            </v-tooltip>

                                                            <!-- <v-tooltip bottom color="#2196f3" v-if="item.manual == 1">
                                                                <template v-slot:activator="{ on, attrs }" >
                                                                    <v-btn
                                                                        icon
                                                                        small
                                                                        @click="item.editActive = true"
                                                                        :color="item.uploadedFile ? '#2196f3' : '#fda1a1'"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                    >
                                                                        <v-icon>mdi-file-upload-outline</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Uploaded Proof </span>
                                                            </v-tooltip> -->

                                                            <v-tooltip bottom :color="item.deleted_record == 1 ? 'blue' : 'red'">
                                                                <template v-slot:activator="{ on, attrs }" >
                                                                    <v-btn
                                                                        icon
                                                                        small
                                                                        @click="deleteRecord(parentItem.employee_id, index, item, !item.deleted_record ? 1 : 0)"
                                                                        :color="item.deleted_record == 1 ? 'blue' : 'red'"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                    >
                                                                        <v-icon>{{item.deleted_record == 1 ? 'mdi-restore' : 'mdi-delete'}}</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>{{ item.deleted_record == 1 ? 'Restore' : 'Delete' }} Record</span>
                                                            </v-tooltip>
                                                        </td>
                                                    </tr>
                                                    <tr v-if="item.editActive == true">
                                                        <td>
                                                            <v-menu
                                                                v-model="item.date_menu"
                                                                :close-on-content-click="true"
                                                                transition="scale-transition"
                                                                offset-y
                                                            >
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-text-field
                                                                        v-model="item.date"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        :readonly="item.readOnly"
                                                                        outlined
                                                                        dense
                                                                    >
                                                                    </v-text-field>
                                                                </template>
                                                                <v-date-picker
                                                                    v-model="item.date"
                                                                    @input="setDay(item, parentItem)"
                                                                    @change="item.manual = 1"
                                                                    :allowed-dates="allowedDates"
                                                                    :min="GET_SELECTED_DATE_RANGE.from"
                                                                    :max="GET_SELECTED_DATE_RANGE.to"
                                                                    color="#f69f1a"
                                                                    header-color="#f69f1a"
                                                                    scrollable
                                                                >
                                                                </v-date-picker>
                                                            </v-menu>
                                                        </td>
                                                        <td>
                                                            <v-text-field
                                                                v-model="item.day"
                                                                outlined
                                                                readonly
                                                                background-color="grey lighten-2"
                                                                dense
                                                            >
                                                            </v-text-field>
                                                        </td>
                                                        <td>
                                                            <v-text-field
                                                                v-model="item.excess_hours"
                                                                outlined
                                                                readonly
                                                                background-color="grey lighten-2"
                                                                dense
                                                            >
                                                            </v-text-field>
                                                        </td>
                                                        <td>
                                                            <v-text-field
                                                                v-model="item.hours_worked"
                                                                outlined
                                                                readonly
                                                                background-color="grey lighten-2"
                                                                dense
                                                            >
                                                            </v-text-field>
                                                        </td>
                                                        <td>
                                                            <v-text-field
                                                                v-model="item.late_in_hours"
                                                                outlined
                                                                readonly
                                                                background-color="grey lighten-2"
                                                                dense
                                                            >
                                                            </v-text-field>
                                                        </td>
                                                        <td>
                                                            <v-text-field
                                                                v-model="item.location"
                                                                outlined
                                                                readonly
                                                                background-color="grey lighten-2"
                                                                dense
                                                            >
                                                            </v-text-field>
                                                        </td>
                                                        <td>
                                                            <v-menu
                                                                v-model="item.time_in_menu"
                                                                :close-on-content-click="false"
                                                                transition="scale-transition"
                                                                offset-y
                                                            >
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-text-field
                                                                        v-model="item.time_in"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        readonly
                                                                        outlined
                                                                        dense
                                                                    >
                                                                    </v-text-field>
                                                                </template>
                                                                <v-time-picker format="24hr" @change="item.manual = 1" :max="item.time_out" scrollable @click:minute="item.time_in_menu = false" v-model="item.time_in">
                                                                </v-time-picker>
                                                            </v-menu>
                                                        </td>
                                                        <td>
                                                            <v-menu
                                                                v-model="item.time_out_menu"
                                                                :close-on-content-click="false"
                                                                transition="scale-transition"
                                                                offset-y
                                                            >
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-text-field
                                                                        v-model="item.time_out"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        readonly
                                                                        outlined
                                                                        dense
                                                                    >
                                                                    </v-text-field>
                                                                </template>
                                                                <v-time-picker format="24hr" @change="item.manual = 1" scrollable @click:minute="item.time_out_menu = false" v-model="item.time_out">
                                                                </v-time-picker>
                                                            </v-menu>
                                                        </td>
                                                        <td>
                                                            <v-tooltip bottom color="blue">
                                                                <template v-slot:activator="{ on, attrs }" >
                                                                    <v-btn
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        icon
                                                                        small
                                                                        @click="reComputeRecord(parentItem, item)"
                                                                        color="blue"
                                                                    >
                                                                        <v-icon>mdi-check-bold</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Save Record</span>
                                                            </v-tooltip>

                                                            <input type="file" @change="handleFileUpload(parentItem, item, $event)" style="display: none;" ref="fileInput" />
                                                            <v-tooltip bottom color="#7383e3" v-if="item.manual == 1">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn
                                                                        icon
                                                                        small
                                                                        @click="$refs.fileInput.click()"
                                                                        color="#7383e3"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                    >
                                                                        <v-icon>mdi-file-upload-outline</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Upload Proof</span>
                                                            </v-tooltip>

                                                            <v-tooltip bottom color="red">
                                                                <template v-slot:activator="{ on, attrs }" >
                                                                    <v-btn
                                                                        icon
                                                                        small
                                                                        @click="deleteRecord(parentItem.employee_id, index, item, !item.deleted_record ? 1 : 0)"
                                                                        :color="item.deleted_record == 1 ? 'blue' : 'red'"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                    >
                                                                        <v-icon>{{item.deleted_record == 1 ? 'mdi-restore' : 'mdi-delete'}}</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>{{ item.deleted_record == 1 ? 'Restore' : 'Delete' }} Record</span>
                                                            </v-tooltip>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </v-data-table>
                                        </td>
                                    </template>

                                    <template v-slot:[`item.action`]="{item}">
                                        <v-tooltip bottom color="#B80000">
                                            <template v-slot:activator="{ on, attrs }" >
                                                <v-btn
                                                    icon
                                                    small
                                                    @click="removeEmployee(item.employee_id)"
                                                    color="#B80000"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    <v-icon>mdi-minus-circle</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Remove Employee</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                                <v-data-table
                                    v-else
                                    :headers="datatable.headers"
                                    :items="datatable.tempitems"
                                    :expanded.sync="datatable.expanded"
                                    :loading="loading.datatable"
                                    @item-expanded="loadItemsDetails"
                                    show-expand
                                    disable-sort
                                    item-key="employee_id"
                                    dense
                                >
                                    <template v-slot:[`item.employee_name`]="{item}">
                                            <span>{{ item.employee_name }}</span>
                                            <v-icon color="red" class="ml-2" small v-if="item.inCompleteLog > 0 && item.schedule.schedule_type_id !== 3">mdi-alert</v-icon>
                                    </template>
                                    <template v-slot:expanded-item="{ headers, item: parentItem }">
                                        <td :colspan="headers.length" >
                                            <v-data-table
                                                :headers="datatable.sub_header"
                                                :items="parentItem.biometricdata"
                                                :items-per-page="-1"
                                                class="w-100"
                                                disable-sort
                                                hide-default-footer
                                                dense
                                            >
                                                <template v-slot:header.action>
                                                    <v-row>
                                                        <v-col cols="4">
                                                            <v-btn fab dark small color="primary"
                                                                style="height: 20px; width: 20px"
                                                                @click="addRecord(parentItem), getCurrentIndexDates(parentItem.biometricdata)"
                                                            >
                                                                <v-icon dark small style="font-size: 12px">mdi-plus</v-icon>
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </template>
                                                <template v-slot:item="{ item, index }" >
                                                    <tr v-if="item.editActive == false"
                                                        :class="{
                                                        'highlight_manual': item.manual == 1,
                                                        'deleted_item': item.deleted_record == 1,
                                                        'with_no_time_out': checkIfWithNoTimeOut(item, parentItem),
                                                        'rest_day_schedule': item.isRestDay
                                                        }"
                                                        >
                                                        <td> {{ item.date }} </td>
                                                        <td> {{ item.day }} </td>
                                                        <td> {{ item.excess_hours }} </td>
                                                        <td> {{ item.hours_worked }} </td>
                                                        <td> {{ item.late_in_hours }} </td>
                                                        <td> {{ item.location }} </td>
                                                        <td> {{ formatTime(item.date + ' ' +  item.time_in) }} </td>
                                                        <td> {{ formatTime(item.date + ' ' +  item.time_out) }} </td>

                                                        <td>
                                                            <v-tooltip bottom color="#337CCF">
                                                                <template v-slot:activator="{ on, attrs }" >
                                                                    <v-btn
                                                                        icon
                                                                        small
                                                                        @click="item.editActive = true"
                                                                        color="#337CCF"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                    >
                                                                        <v-icon>mdi-calendar-edit</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Edit Record</span>
                                                            </v-tooltip>

                                                            <!-- <v-tooltip bottom color="#2196f3" v-if="item.manual == 1">
                                                                <template v-slot:activator="{ on, attrs }" >
                                                                    <v-btn
                                                                        icon
                                                                        small
                                                                        @click="item.editActive = true"
                                                                        :color="item.uploadedFile ? '#2196f3' : '#f44336'"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                    >
                                                                        <v-icon>mdi-file-upload-outline</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Uploaded Proof </span>
                                                            </v-tooltip> -->

                                                            <v-tooltip bottom :color="item.deleted_record == 1 ? 'blue' : 'red'">
                                                                <template v-slot:activator="{ on, attrs }" >
                                                                    <v-btn
                                                                        icon
                                                                        small
                                                                        @click="deleteRecord(parentItem.employee_id, index, item, !item.deleted_record ? 1 : 0)"
                                                                        :color="item.deleted_record == 1 ? 'blue' : 'red'"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                    >
                                                                        <v-icon>{{item.deleted_record == 1 ? 'mdi-restore' : 'mdi-delete'}}</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>{{ item.deleted_record == 1 ? 'Restore' : 'Delete' }} Record</span>
                                                            </v-tooltip>
                                                        </td>
                                                    </tr>
                                                    <tr v-if="item.editActive == true">
                                                        <td>
                                                            <v-menu
                                                                v-model="item.date_menu"
                                                                :close-on-content-click="true"
                                                                transition="scale-transition"
                                                                offset-y
                                                            >
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-text-field
                                                                        v-model="item.date"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        :readonly="item.readOnly"
                                                                        outlined
                                                                        dense
                                                                    >
                                                                    </v-text-field>
                                                                </template>
                                                                <v-date-picker
                                                                    v-model="item.date"
                                                                    @input="setDay(item, parentItem)"
                                                                    @change="item.manual = 1"
                                                                    :allowed-dates="allowedDates"
                                                                    :min="GET_SELECTED_DATE_RANGE.from"
                                                                    :max="GET_SELECTED_DATE_RANGE.to"
                                                                    color="#f69f1a"
                                                                    header-color="#f69f1a"
                                                                    scrollable
                                                                >
                                                                </v-date-picker>
                                                            </v-menu>
                                                        </td>
                                                        <td>
                                                            <v-text-field
                                                                v-model="item.day"
                                                                outlined
                                                                readonly
                                                                background-color="grey lighten-2"
                                                                dense
                                                            >
                                                            </v-text-field>
                                                        </td>
                                                        <td>
                                                            <v-text-field
                                                                v-model="item.excess_hours"
                                                                outlined
                                                                readonly
                                                                background-color="grey lighten-2"
                                                                dense
                                                            >
                                                            </v-text-field>
                                                        </td>
                                                        <td>
                                                            <v-text-field
                                                                v-model="item.hours_worked"
                                                                outlined
                                                                readonly
                                                                background-color="grey lighten-2"
                                                                dense
                                                            >
                                                            </v-text-field>
                                                        </td>
                                                        <td>
                                                            <v-text-field
                                                                v-model="item.late_in_hours"
                                                                outlined
                                                                readonly
                                                                background-color="grey lighten-2"
                                                                dense
                                                            >
                                                            </v-text-field>
                                                        </td>
                                                        <td>
                                                            <v-text-field
                                                                v-model="item.location"
                                                                outlined
                                                                readonly
                                                                background-color="grey lighten-2"
                                                                dense
                                                            >
                                                            </v-text-field>
                                                        </td>
                                                        <td>
                                                            <v-menu
                                                                v-model="item.time_in_menu"
                                                                :close-on-content-click="false"
                                                                transition="scale-transition"
                                                                offset-y
                                                            >
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-text-field
                                                                        v-model="item.time_in"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        readonly
                                                                        outlined
                                                                        dense
                                                                    >
                                                                    </v-text-field>
                                                                </template>
                                                                <v-time-picker format="24hr" @change="item.manual = 1" :max="item.time_out" scrollable @click:minute="item.time_in_menu = false" v-model="item.time_in">
                                                                </v-time-picker>
                                                            </v-menu>
                                                        </td>
                                                        <td>
                                                            <v-menu
                                                                v-model="item.time_out_menu"
                                                                :close-on-content-click="false"
                                                                transition="scale-transition"
                                                                offset-y
                                                            >
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-text-field
                                                                        v-model="item.time_out"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        readonly
                                                                        outlined
                                                                        dense
                                                                    >
                                                                    </v-text-field>
                                                                </template>
                                                                <v-time-picker format="24hr" @change="item.manual = 1" :min="item.time_in" scrollable @click:minute="item.time_out_menu = false" v-model="item.time_out">
                                                                </v-time-picker>
                                                            </v-menu>
                                                        </td>
                                                        <td>
                                                            <v-tooltip bottom color="blue">
                                                                <template v-slot:activator="{ on, attrs }" >
                                                                    <v-btn
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        icon
                                                                        small
                                                                        @click="reComputeRecord(parentItem, item)"
                                                                        color="blue"
                                                                    >
                                                                        <v-icon>mdi-check-bold</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Save Record</span>
                                                            </v-tooltip>

                                                            <input type="file" @change="handleFileUpload(parentItem, item, $event)" style="display: none;" ref="fileInput" />
                                                            <v-tooltip bottom color="#7383e3" v-if="item.manual == 1">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn
                                                                        icon
                                                                        small
                                                                        @click="$refs.fileInput.click()"
                                                                        color="#7383e3"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                    >
                                                                        <v-icon>mdi-file-upload-outline</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Upload Proof</span>
                                                            </v-tooltip>

                                                            <v-tooltip bottom color="red">
                                                                <template v-slot:activator="{ on, attrs }" >
                                                                    <v-btn
                                                                        icon
                                                                        small
                                                                        @click="deleteRecord(parentItem.employee_id, index, item, !item.deleted_record ? 1 : 0)"
                                                                        :color="item.deleted_record == 1 ? 'blue' : 'red'"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                    >
                                                                        <v-icon>{{item.deleted_record == 1 ? 'mdi-restore' : 'mdi-delete'}}</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>{{ item.deleted_record == 1 ? 'Restore' : 'Delete' }} Record</span>
                                                            </v-tooltip>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </v-data-table>
                                        </td>
                                    </template>

                                    <template v-slot:[`item.action`]="{item}">
                                        <v-tooltip bottom color="red">
                                            <template v-slot:activator="{ on, attrs }" >
                                                <v-btn
                                                    icon
                                                    small
                                                    @click="removeEmployee(item.employee_id)"
                                                    color="red"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    <v-icon>mdi-minus-circle</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Remove Employee</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions
                class="text-center"
                style="border-top: 1px solid #dee2e6"
            >
                <v-row class="ma-1 pb-2">
                    <v-col cols="12">
                       <v-btn
                            :loading="loading.button"
                            @click="saveBiometricRecord()"
                        >
                            <span>Save</span>
                        </v-btn>
                        <span class="pr-12">&nbsp;</span>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue'
import HRTimekeepingFunctionsComponent from '@/views/main/Shared/HRTimekeepingFunctionsComponent.vue'
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
export default {
    mixins: [
        SharedFunctionsComponentVue,
        HRTimekeepingFunctionsComponent
    ],
    data(){
        return{
            valid: false,
            overlay: false,
            disabled_view: false,
            errors: {
                required: [(v) => !!v || "Field is required"],
                number: [
                    (v) => !!v || "Field is required",
                    (v) => !isNaN(v) || "Input must be a number",
                ],
            },
            form: {},
            datatable:{
                headers: [
                    { text: 'Employee Name', align: 'start', value: 'employee_name'},
                    { text: 'Hours Worked', align: 'start', value: 'total_hours_worked'},
                    { text: 'Days Worked', align: 'start', value: 'days_worked'},
                    // { text: 'Overtime Hours', align: 'start', value: 'total_overtime_hours_worked'},
                    // { text: 'Days Leaved', align: 'start', value: 'days_leaved'},
                    { text: 'Late & Tardiness in Hours Total', align: 'start', value: 'total_late_in_hours'},
                    { text: 'Action', align: 'start', value: 'action'},
                ],
                sub_header: [
                    { text: 'Date', width:'15%', align: 'start', value: 'date'},
                    { text: 'Day', width:'15%', align: 'start', value: 'day'},
                    { text: 'Excess Hours', width:'8%', align: 'start', value: 'excess_hours'},
                    { text: 'Hours Worked', width:'10%', align: 'start', value: 'hours_worked'},
                    { text: 'Late In Hours', width:'10%', align: 'start', value: 'late_in_hours'},
                    { text: 'Location', width:'10%', align: 'start', value: 'location'},
                    { text: 'Time In', width:'12%', align: 'start', value: 'time_in'},
                    { text: 'Time Out', width:'12%', align: 'start', value: 'time_out'},
                    { text: 'Action', width:'8%',  align: 'center', value: 'action'},
                ],
                items: [],
                tempitems: [],
                sub_item: [],
                options: {},
                totalItems: 0,
                expanded: []
            },
            loading:{
                button: false
            },
            notIncludedDates: [],
            filters:{
                with_incomplete_time_in_out: 0
            },
            flexiManagerScheduleID: '',
            legends: [
                { text: "Inactive", color: "#f44336", text_color: "white" },
                { text: "Manual Add", color: "#a9dcb5", text_color: "black" },
                { text: "Deleted Record", color: "#fda1a1", text_color: "black" },
                { text: "Incomplete Logs", color: "#6c99fa", text_color: "white" },
                { text: "Rest Day", color: "#28ffe2", text_color: "black" },
                { text: "No Record/Absent", color: "#c990ff", text_color: "black" },
                { text: "Leave w/pay", color: "#eead8e", text_color: "black" },
                { text: "Leave wo/pay", color: "#ff92d5", text_color: "black" },
            ]
        }
    },
    computed:{
        ...mapGetters([
            'GET_BIOMETRICS_SUMMARY_DIALOG',
            'GET_EMPLOYEE_BIO_TIME',
            'GET_SELECTED_DATE_RANGE',
            'GET_BIOMETRICS_DATA',
            'GET_EMPLOYEE_SCHEDULE_TYPE_SELECTION'
        ]),
    },
    mounted(){
        this.$store.dispatch('getEmployeeScheduleType').then((res)=>{
            this.setManagerFlexiID()
        })
    },
    methods:{
        closeDialog(){
            this.$store.commit('BIOMETRICS_SUMMARY_DIALOG', false);
            this.$store.commit('BIOMETRICS_DATA', []);
            this.filters.with_incomplete_time_in_out = 0;
        },
        getBiometricDetails(){
            if(_.isEmpty(this.GET_BIOMETRICS_DATA)){
                this.loading.button = true;
                this.loading.datatable = true;
            }
            this.form.bio_period = this.dateWithFormat(this.GET_SELECTED_DATE_RANGE.from, "MMMM DD, YYYY") + ' - ' + this.dateWithFormat(this.GET_SELECTED_DATE_RANGE.to, "MMMM DD, YYYY")
        },
        loadItemsDetails(){
        },
        addRecord(item) {
            if(item.biometricdata.length < 15){
                let index = this.findIndexInArrayObjects(this.datatable.items, 'employee_id', item.employee_id);
                const newItem = {
                    date: this.$dayjs(this.GET_SELECTED_DATE_RANGE.from).format('YYYY-MM'),
                    day: '',
                    excess_hours: 0,
                    hours_worked: 0,
                    late_in_hours: 0,
                    time_in: '',
                    time_out: '',
                    editActive: true,
                    readOnly: false,
                    manual: 1
                };
                this.datatable.items[index].biometricdata.push(newItem)
            }else{
                Swal.fire({
                    title: "Excess Date",
                    text: `Must be only 15 days`,
                    icon: "warning",
                })
                return false
            }
        },
        allowedDates(val){
            return !this.notIncludedDates.includes(val);
        },
        deleteRecord(employee_id, key, item, value){
            Swal.fire({
                title: "Delete",
                text: `Are you sure you want to ${value == 1 ? 'delete' : 'restore'} ?`,
                icon: 'question',
                showConfirmButton:true,
                confirmButtonText:'Confirm',
                showCancelButton:true,
                showCloseButton:true,
                confirmButtonColor: '397373',
                cancelButtonColor:'grey',
                reverseButtons:true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    if(item.manual == 1){
                        let index = this.findIndexInArrayObjects(this.datatable.items, 'employee_id', employee_id);
                        this.datatable.items[index].biometricdata.splice(key, 1);
                    }else{
                        this.$set(item, 'deleted_record', value)
                    }
                }
            });
        },
        getCurrentIndexDates(data){
            let dateRange = this.getDatesFromRange(this.GET_SELECTED_DATE_RANGE.from, this.GET_SELECTED_DATE_RANGE.to, "array");
            this.notIncludedDates = data.map(e=>{
                if(dateRange.includes(e.date)){
                    return e.date
                }
            })
        },
        setDay(item, parentItem = null){
            let dayName = this.$dayjs(item.date).format('dddd');
            let scheduleData = parentItem.schedule.employee_schedule_details.find((x)=> x.day == dayName);

            // if(scheduleData){
                this.$set(item, 'day', dayName)
                this.$set(item, 'schedule_time_in', scheduleData.time_in)
                this.$set(item, 'schedule_time_out', scheduleData.time_out)

            // }else{
            //     Swal.fire(
            //         "No Schedule found for that day!",
            //         "",
            //         "warning"
            //     );
            //     this.$set(item, 'day', '')
            //     this.$set(item, 'date', '')
            //     this.$set(item, 'editActive', true)
            // }
        },
        saveBiometricRecord(){
            this.datatable.tempitems = this.datatable.items;
            // if(this.filterTable(1) > 0){
            //     Swal.fire(
            //         "There are incomplete Time In/Out!",
            //         "",
            //         "warning"
            //     )
            //     // this.filters.with_incomplete_time_in_out = 1
            //     // return false
            // }
            if (this.datatable.items.length == 0){
                Swal.fire(
                    "No records timekeeping records to save!",
                    "",
                    "warning"
                )
                return false;
            }
            // else{
            Swal.fire({
                title: "Save",
                text: this.filterTable(1) == 0 ? `Are you sure you want to save timekeeping record?` : 'Are you sure you want to save timekeeping record ? There are incomplete Time In/Out',
                icon: "warning",
                showConfirmButton:true,
                confirmButtonText:'Confirm',
                showCancelButton:true,
                showCloseButton:true,
                confirmButtonColor: '397373',
                cancelButtonColor:'grey',
                reverseButtons:true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.loading.button = true;
                    let payload = {
                        records: this.datatable.items,
                        timekeeping_period_start: this.GET_SELECTED_DATE_RANGE.from,
                        timekeeping_period_end: this.GET_SELECTED_DATE_RANGE.to,
                        remarks: this.form.remarks
                    }
                    this.$store.dispatch('saveTimekeepingRecord', payload).then((res)=>{
                        Swal.fire(
                            res.message,
                            "",
                            res.icon
                        )
                        this.loading.button = false;
                        this.$store.commit('BIOMETRICS_SUMMARY_DIALOG', false);
                    }).catch((err)=>{
                        this.loading.button = false;
                        console.log(err)
                        this.$store.commit('BIOMETRICS_SUMMARY_DIALOG', false);
                    })
                }
            });
            // }
        },
        handleFileUpload(parentItem, item, event) {
            const file = event.target.files[0];
            let that = this
            const blobFile = new Blob([file], { type: file.type });

            var reader  = new FileReader();
            reader.readAsDataURL(blobFile);
            reader.onloadend = function () {
                that.$set(item, 'uploadedFile', reader.result);
            }
        },
        checkIfWithNoTimeOut(item, parentItem){
            if(parentItem.schedule.schedule_type_id !== this.flexiManagerScheduleID && ((!item.time_out || !item.time_in) && !item.leave)  && item.isRestDay == 0 && item.isHoliday == 0){
                return true
            }
            return false
        },
        setManagerFlexiID(){
            this.flexiManagerScheduleID = this.GET_EMPLOYEE_SCHEDULE_TYPE_SELECTION.find((x) => x.code == 'FMG').value;
        },
        validateIncompleteTime(){
            let filteredBiometricData = this.datatable.items.map((e) => {
                return {
                    ...e,
                    biometricdata: e.biometricdata.filter((x) => !x.time_in || !x.time_out),
                };
            });

            // Filter this.datatable.items as well
            filteredBiometricData = filteredBiometricData.filter((e) => {
                if(e.schedule.schedule_type_id !== this.flexiManagerScheduleID){
                    return e.biometricdata.length > 0; // Filter out elements with no valid biometric data
                }
            });
            this.datatable.items = filteredBiometricData;
        },
        filterTable(for_validation = 0){
            let filteredBiometricData = this.datatable.tempitems.map((e) => {
                return {
                    ...e,
                    biometricdata: e.biometricdata.filter((x) => ( ((!x.time_out && x.time_in) || (!x.time_in && x.time_out)) && x.isRestDay == 0)),
                };
            });
            // Filter this.datatable.items as well
            filteredBiometricData = filteredBiometricData.filter((e) => {
                if(e.schedule){
                    if(e.schedule.schedule_type_id !== this.flexiManagerScheduleID){
                        return e.biometricdata.length > 0; // Filter out elements with no valid biometric data
                    }
                }
            });
            if(for_validation == 1){
                return filteredBiometricData.length
            }
            this.datatable.tempitems = filteredBiometricData;
        },
        removeEmployee(employee_id){
            Swal.fire({
                title: "Delete",
                text: `Are you sure you want to remove employee?`,
                icon: 'question',
                showConfirmButton:true,
                confirmButtonText:'Confirm',
                showCancelButton:true,
                showCloseButton:true,
                confirmButtonColor: '397373',
                cancelButtonColor:'grey',
                reverseButtons: true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    let index = this.findIndexInArrayObjects(this.datatable.items, 'employee_id', employee_id);
                    this.datatable.items.splice(index, 1);
                }
            })
        },
        itemRowBackground(item){
            if(item.active == 0){
                return 'inactive'
            }
            // return 'highlight_manual'
        },
        checkLeaveData(item, w_pay){
            if(_.isEmpty(item.leave)){
                return false
            }else{
                if(w_pay == 1){
                    return item.leave.days_with_pay > 0 && item.isRestDay == 0 && item.no_record == 0 ? true : false
                }else{
                    return item.leave.days_with_pay == 0 ? true : false
                }
            }
        }
    },
    watch:{
        GET_BIOMETRICS_SUMMARY_DIALOG:{
            handler(val){
                this.datatable.expanded = []
                if(val){
                    this.getBiometricDetails()
                    this.form.remarks = '';
                }
            }
        },
        GET_BIOMETRICS_DATA:{
            handler(val){
                this.datatable.items = [];
                this.loading.button = false;
                this.loading.datatable = false;
                this.datatable.items = val
            },
            deep: true
        },
        "datatable.items":{
            deep: true,
            handler(val){
                // console.log(val)
            }
        },
        "filters.with_incomplete_time_in_out":{
            handler(val){
                if(val == 1){
                    this.datatable.tempitems = this.datatable.items
                    this.filterTable();

                }else if (val == 0){
                    // this.datatable.items = this.datatable.tempitems;
                    this.datatable.tempitems.forEach((temp_el)=>{
                        const pos = this.datatable.items.map(x => x.employee_id).indexOf(temp_el.employee_id);
                        let dates = temp_el.biometricdata.map((e)=> temp_el.date)
                        if(pos != -1){
                            this.datatable.items[pos].biometricdata.forEach((item_el)=>{
                                if(dates.includes(item_el.date)){
                                    item_el = temp_el
                                }
                            })
                            // this.datatable.items.splice(pos, 1, e)
                        }
                    })
                    // this.datatable.items = this.GET_BIOMETRICS_DATA;
                    // console.log(this.datatable.items)
                }
            }
        }
    }
}
</script>
<style>
.highlight_manual{
    background-color: #a9dcb5;
}
.deleted_item{
    background-color: #fda1a1;
}
.with_no_time_out{
    background-color: #6c99fa;
}
.rest_day_schedule{
    background-color: #28ffe2;
}
.no_record{
    background-color: #c990ff;
}
.inactive{
    background-color: #f44336;
}
.leave_w_pay{
    background-color: #eead8e;
}
.leave_wo_pay{
    background-color: #ff92d5;
}
</style>
