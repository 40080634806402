<template>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
          <span class="text-h5">REQUEST</span>
          <v-row class="m-0">
            <v-col cols="pull-right-10 p-2">
              <v-btn
                text
                icon
                small
                color="gray"
                class="float-right"
                @click="closeDialog()">
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <!-- <v-menu
            top
            :offset-x="offset">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  icon
                  small
                  color="gray"
                  class="float-right"
                  v-bind="attrs"
                   v-on="on">
                  <v-icon small>mdi-help-circle</v-icon>
                </v-btn>
              </template>
              <v-card width="50%">
                <v-card-text >
                  <v-img src="@/assets/gif/admin-batchaddition.gif" class="img-fluid"></v-img>
                </v-card-text>
            </v-card>
          </v-menu> -->
          <v-card-text class="over-flow-auto">
            <v-container fluid >
              <v-row>
                <v-col cols="12" sm="12" lg="2" md="4">
                  <v-row class="pb-1">
                    <v-card>
                      <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                          <span class="text-caption font-weight-bold">Transaction Type</span>
                        </v-card-title>
                      <v-card-text>
                        <v-radio-group v-model="form.transaction_type" :disabled="ACTION != 'Submit'" row>
                          <v-radio ripple label="PRF" value="prf"></v-radio>
                          <v-radio ripple label="FAS" value="fas"></v-radio>
                        </v-radio-group>
                      </v-card-text>
                    </v-card>
                </v-row>
                    <v-row>
                      <v-radio-group v-model="form.type" row>
                        <v-radio label="Head Office" value="ho"></v-radio>
                        <v-radio label="Stores" value="stores"></v-radio>
                      </v-radio-group>
                    </v-row>
                  <v-row>
                      <v-text-field
                      v-model="form.fas_number"
                      label="Ref No. (optional)"
                      dense
                      outlined
                      >
                      </v-text-field>
                 </v-row>
                    <v-row v-if="form.type == 'stores'">
                 
                        <v-col>
                        <v-autocomplete
                            v-model="form.company_id"
                            :items="getAllCompanies"
                            dense
                            outlined
                            hide-details
                            label="Company"
                            clearable
                            auto-select-first
                            @change="storesSelect"
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-col>
                        <v-autocomplete
                            v-model="form.stores_id"
                            :items="clonedStores"
                            dense
                            outlined
                            auto-select-first
                            label="Stores"
                            hide-details
                            clearable
                            @change="companies"
                        >
                        </v-autocomplete>
                    </v-col>
                  
                    </v-row>
                    <v-row v-if="form.type == 'ho'" class="pa-0" >
                          <v-autocomplete
                          v-model="form.department_id"
                          outlined
                          :items="GET_LIST_DEPARTMENT"
                          item-text="text"
                          item-value="value"
                          auto-select-first
                          required
                          label="Department"
                          :readonly="ACTION === 'View' || disabled_view"
                          dense
                        ></v-autocomplete>
                    </v-row>
                    <v-row class="pa-0" v-if="form.type == 'ho'">
                          <v-autocomplete
                          v-model="form.location_id"
                          outlined
                          :items="GET_ADMIN_LOCATION"
                          item-text="text"
                          item-value="value"
                          auto-select-first
                          required
                          label="Location"
                          :readonly="ACTION === 'View' || disabled_view"
                          dense
                        ></v-autocomplete>
                    </v-row>
                    <v-row>
                     <v-textarea v-model="form.remarks" outlined rows="6" no-resize label="Remarks" :readonly="ACTION === 'View'"></v-textarea>
                    </v-row>
                </v-col>
                <v-col cols="12" lg="10" md="8">
                  <v-row class="pl-1">
                   <v-col cols="6">
                    <v-menu
                        :close-on-content-click="true"
                        :nudge-right="80"
                        :nudge-bottom="42"
                        transition="scale-transition"
                        max-width="290"
                        >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                style=""
                                v-model="form.expected_date"
                                dense
                                readonly
                                append-icon="mdi-calendar"
                                v-on="on"
                                label="Expected date"
                                outlined
                                :disabled="ACTION == 'View'"
                            ></v-text-field>
                        </template>
                            <v-date-picker v-model="form.expected_date">
                            </v-date-picker>
                        </v-menu>
                   </v-col>
                   <v-col cols="6">
                    <FileUploadComponent
                      class="pt-0" 
                      :hasCamera="true"
                      :hasFile="true"
                    ></FileUploadComponent>
                   </v-col>
                  </v-row>
                  <v-row>
                <v-col cols="12" class="ml-2 justify-content-between border-bottom border-top pr-2">
                  <v-btn class="float-start" @click="exportItem()" :disabled="disableZeroItem()">Export Items</v-btn>
                  <v-btn color="blue" class="float-start" @click="openDialogBatch()" :disabled="disabled_view">Batch Addition</v-btn>
                    <v-btn small icon color="red" class="float-end" @click="removeItem(-1)" :disabled="disabled_view"><v-icon>mdi-minus-circle</v-icon></v-btn>
                    <v-btn small icon color="blue" class="float-end" @click="addItems()" :disabled="disabled_view"><v-icon>mdi-plus-circle</v-icon></v-btn>
                    <!-- <v-btn
                    class="float-end"
                    color="light-green"
                    @click="massUpdateItems()"
                    :disabled="disabled_view"
                    >
                    mass update
                    </v-btn>
                    <v-autocomplete
                      class="float-end pr-1"
                      v-model="item_update"
                      :items="selection.admin_items"
                      item-value="admin_item_id"
                      item-text="item_name"
                      label="Select Item"
                      auto-select-first
                      outlined
                      dense
                      :readonly="ACTION === 'View' || disabled_view"
                    >
                    </v-autocomplete>
                    <v-text-field
                      class="float-end pr-1"
                      v-model="qty_update"
                      type="number"
                      min="0"
                      max="999"
                      label="Quantity"
                      outlined
                      dense
                      :readonly="ACTION === 'View' || disabled_view"
                      >
                    </v-text-field>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                      <v-btn icon color="green" class="mt-1 float-end"
                      @click="usePreviousData()"
                       :disabled="ACTION === 'View' || disabled_view"
                      v-bind="attrs"
                      v-on="on"

                      ><v-icon>mdi-sync-circle</v-icon></v-btn>
                        </template>
                      <span>Previous Items</span>
                    </v-tooltip> -->
                </v-col>
              </v-row>
              <v-row no-gutters v-if="!loaded">
                <v-col cols="12" class="pa-0">
                  <v-card-text >
                    <v-data-table
                      :headers="dataTable.headers"
                      :items="dataTable.items"
                      :items-per-page="10"
                      :footer-props="{
                              itemsPerPageOptions: dataTable.itemsPerPageOption,
                      }"
                      item-key="index"
                      loading-text="Loading... Please wait"
                      fixed-header
                      :item-style="setRowStyle"
                    >
                    <template v-slot:[`item.item_id`]="{ item,index }">
                            <v-combobox
                              v-model="item.item_id"
                              :items="selection.admin_items"
                              item-value="admin_item_id"
                               item-text="item_name"
                              auto-select-first
                              :disabled="disabled_view"
                              item-disabled="isDisabled"
                              outlined
                              dense
                              @change="usePreviousData(item,index)"
                            >
                            <template #prepend>
                                <v-tooltip top v-if="parseInt(item.qty) > parseInt(item.previous_qty) && item.previous_qty != 0">
                                  <template v-slot:activator="{ on }">
                                      <v-icon
                                          v-on="on"
                                          color="red"
                                      > 
                                        mdi-alert-circle-outline 
                                      </v-icon>
                                  </template>
                                  <span>This item must be less than to previous quantity</span>
                                </v-tooltip>
                              </template>
                            </v-combobox>
                         </template>
                         <template v-slot:[`item.uom_id`]="{ item }">
                          <v-autocomplete
                              v-model="item.uom_id"
                              :items="GET_ADMIN_UOM_SELECTION"
                              auto-select-first
                              :disabled="disabled_view"
                              outlined
                              dense
                            >
                            </v-autocomplete>
                         </template>
                         <template v-slot:[`item.qty`]="{ item }">
                            <v-text-field
                            v-model="item.qty"
                            type="number"
                            min="0"
                            max="999"
                            outlined
                            :disabled="disabled_view"
                            dense
                            >
                            </v-text-field>
                         </template>
                         <template v-slot:[`item.previous_qty`]="{ item }">
                            {{item.previous_qty}}
                         </template>
                         <template v-slot:[`item.action`]="{ item,index }">
                          <v-btn
                            text
                            icon
                            small
                            color="red"
                            class="float-right"
                            :disabled="GET_EDIT_FAS_REQUISITION.status == 2 || ACTION === 'View'"
                            @click="removeItem(index)">
                            <v-icon>mdi-minus-circle</v-icon>
                        </v-btn>
                         </template>
                         <template v-slot:[`item.justification_remarks`]="{ item }">
                            <v-textarea
                            v-model="item.justification_remarks"
                            outlined
                            dense
                            rows="2"
                            auto-grow
                            :disabled="GET_EDIT_FAS_REQUISITION.status == 2 || ACTION === 'View'"
                            >
                            </v-textarea>
                         </template>
                         <template v-slot:footer.prepend v-if="ACTION == 'View' && form.transaction_type == 'prf'">
                            <v-chip :disabled="countAsset() === 0" class="mr-2" color="#f4ff81" text-color="black" label @click="basketWithAssetDialog = true">With Assets: {{countAsset()}}</v-chip>
                        </template>

                  </v-data-table>

                  </v-card-text>

                </v-col>
              </v-row>
              <v-row v-if="loaded">
                <v-container bg fill-height grid-list-md text-sm-center >
                        <v-layout row wrap align-center>
                           <v-flex>
                              <v-progress-circular
                              :size="70"
                              color="primary"
                              indeterminate>
                            </v-progress-circular>
                          </v-flex>
                        </v-layout>
                  </v-container>
              </v-row>
                </v-col>
              </v-row>


              <!-- <v-row v-if="!loaded">
                <v-col cols="12"> -->
                      <!-- <v-simple-table
                        fixed-header
                        height="400px"
                        >
                        <template v-slot:default>
                            <thead class="pa-0">
                                <tr>
                                <th class="text-center">
                                    Code
                                </th>
                                <th class="text-center">
                                    QTY
                                </th>
                                <th class="text-center">
                                    PRICE
                                </th>
                                <th class="text-center">
                                    ACTION
                                </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                v-for="(item,i) in form.batchAdditionItems" :key="i"
                                >

                                    <td :class="'pa-0 pr-1'"><v-text-field v-model="form.batchAdditionItems[i].code" outlined dense @paste="onPaste" @click="$event.target.focus()" readonly>
                                      <template #append>  <v-chip :class=" !collection.includes(item.code) && collection.length > 0?'text-white':'d-none' " x-small color="red">{{ !collection.includes(item.code) && collection.length > 0?'not exists':'' }}</v-chip></template>
                                    </v-text-field></td>
                                    <td :class="'pa-0'"><v-text-field v-model="form.batchAdditionItems[i].qty" outlined dense :readonly="GET_EDIT_FAS_REQUISITION.status == 2?true:false">
                                      <template #append>  <v-chip :class="actions != 'Submit' && item.qty != item.approved_quantity && GET_EDIT_FAS_REQUISITION.status == 2?'text-white':'d-none' " x-small color="success">approved {{ item.quantity != item.approved_quantity?item.approved_quantity:'' }}</v-chip>
                                      </template>
                                    </v-text-field></td>
                                    <td :class="'pa-1'"><v-text-field v-model="form.batchAdditionItems[i].price" outlined dense readonly>
                                      <template #append>
                                      <v-chip :class="form.batchAdditionItems[i].price == 0 && collection.length > 0?'text-white':'d-none' " x-small color="red">{{ form.batchAdditionItems[i].price == 0?'No Price':'' }}</v-chip>

                                    </template>
                                    </v-text-field></td>
                                    <td>
                                      <v-btn
                                        text
                                        icon
                                        small
                                        color="red"
                                        class="float-right"
                                        :disabled="GET_EDIT_FAS_REQUISITION.status == 2 || ACTION === 'View'"
                                        @click="removeItem(i)">
                                        <v-icon>mdi-minus-circle</v-icon>
                                    </v-btn>
                                    </td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table> -->
<!--
                      </v-col>
                    </v-row> -->
            </v-container>
            <v-dialog width="45%" v-model="batch_dialog" scrollable persistent>
                <v-card>
                  <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                    <span class="text-h5">BATCH ADDITION</span>
                    <v-row class="m-0">
                      <v-col cols="pull-right-10 p-2">
                        <v-btn
                          text
                          icon
                          small
                          color="gray"
                          class="float-right"
                          @click="closeBatchDialog()">
                          <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-card-text style="max-height:200px;overflow:auto;">
                      <v-container fluid >
                        <v-file-input
                            ref="fileInput"
                            accept=".xls, .xlsx"
                            @change="handleFileUpload"
                            hide-input
                            hide-details
                            hide-spin-buttons
                            v-show="false"
                        />
                        <v-row>
                            <v-col cols="2" class="pa-2">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            color="primary"
                                            dark
                                            small
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="triggerFileInput"
                                        >
                                            Upload File
                                        </v-btn>
                                    </template>
                                    <span><img src="/images/SampleBatchAdditionInboundOutbound.png"/></span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                        <v-row class="pa-2" v-for="(items,i) in batch_items" :key="i">
                          <v-col cols="6">
                            <v-text-field
                            v-model="items.item_name"
                            label="Item"
                            dense
                            outlined
                            @paste="onPaste"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                            v-model="items.qty"
                            label="Quantity"
                            dense
                            outlined
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                  </v-card-text>
                  <v-card-actions class="text-center d-flex border-top justify-content-center">
                      <v-btn 
                        class="float-center"
                        color="grey"
                        @click="closeBatchDialog()"
                      >
                        <span>CLOSE</span>  
                      </v-btn>
                      <v-btn
                        class="float-center"
                        color="blue"
                        @click="saveBatch()"
                      >
                        <span>Save</span>
                      </v-btn>
                  </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card-text>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions :class="'text-center d-flex border-top'+ actions == 'View' && GET_EDIT_FAS_REQUISITION.status != 3?'justify-content-between':'justify-content-center'">
          <!-- <v-btn v-if="actions == 'View' && GET_EDIT_FAS_REQUISITION.status != 2"
          color="error"
            class="float-center"
            @click="reject()"
          ><span>REJECT</span>
          </v-btn> -->
          <v-btn v-if="actions == 'View'"
            class="float-center"
            @click="renderPDF()"
          ><span>Print</span>
          </v-btn>
          <v-btn v-if="GET_EDIT_FAS_REQUISITION.status == 2 && !loaded && ((this.userAccess.reject) || actions == 'Submit' || ACTION == 'Update')"
            class="float-center"
            color="red"
            @click="reject()"
          >
            <span>REJECT</span>  
          </v-btn>
          <v-btn v-if="(![3,5].includes(GET_EDIT_FAS_REQUISITION.status) && ((this.userAccess.approve || actions == 'View') || actions == 'Submit' || ACTION == 'Update'))"
            class="float-center"
            @click="createOrUpdateFasItemRequest()"
            :loading="submitLoad"
          >
            <span>{{ actions == 'View'?status_text:actions }}</span>
          </v-btn>
        </v-card-actions>
        <FasRequisitionComponent></FasRequisitionComponent>
        <v-dialog v-model="basketWithAssetDialog" width="50%" >
            <v-card>
                <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                  <span class="text-h5">With Assets</span>
                      <v-row class="m-0">
                        <v-col cols="pull-right-10 p-2">
                          <v-btn
                              text
                              icon
                              small
                              color="gray"
                              class="float-right"
                              @click="closeWithAssetDialog()">
                              <v-icon>mdi-close-circle</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                </v-card-title>
                <v-card-text style="min-height:400px;max-height:800px;overflow:auto;" class="pt-2">
                    <v-app id="item-tabs">
                        <v-tabs
                            v-model="tab"
                            id="group-tab"
                            color="cyan"
                            slider-color="cyan"
                            background-color="#424242"
                            dark
                            show-arrows
                        >
                            <v-tabs-slider color="cyan"></v-tabs-slider>
                            <v-tab
                                ripple
                                v-for="(item, i) in basketWithAssetItems"
                                :key="i"
                            >
                                {{ item.item_name }}
                            </v-tab>
                        </v-tabs>
                    </v-app>
                    <v-card>
                        <v-card-text style="min-height:300px;max-height:600px;overflow:auto;">
                          <v-row>
                              <v-spacer></v-spacer>
                              <v-col cols="3">
                                <v-row class="w-100 pb-8">
                                    <v-col cols="11">
                                        <v-text-field label="Search Asset Code" v-model="search_asset_tag" single-line hide-details @keydown.enter="searchAssetTag()"></v-text-field>
                                    </v-col>
                                    <v-col cols="1">
                                        <v-col class="pt-6">
                                            <v-icon @click="searchAssetTag()">mdi-magnify</v-icon>
                                        </v-col>
                                    </v-col>
                                </v-row>
                            </v-col>
                          </v-row>
                          <v-divider></v-divider>
                          <v-row class="p-4">
                              <v-col cols="4" class="d-flex justify-center align-center" v-for="(item,i) in item_list" :key="i">
                                <span class="text-black text-h6 asset-code"> &#8226;{{ item.asset_code }}</span>
                              </v-col>
                          </v-row>
                          <v-divider></v-divider>
                          <v-row>
                            <v-spacer></v-spacer>
                            <v-col class="d-flex justify-end">
                              <span class="text-black text-h6 text-bold pr-4"> Total: {{ item_total }}</span>
                            </v-col>
                          </v-row>
                        </v-card-text>
                    </v-card>
                </v-card-text>
            </v-card>
        </v-dialog>
      </v-card>
    </v-form>
  </template>
      <script>
  import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
  import Swal from "sweetalert2";
  import { mapGetters } from "vuex";
  import * as XLSX from 'xlsx';
  import FasRequisitionComponent from '@/views/main/layouts/PrintTemplate/FasRequisitionComponent.vue'
  import ItemsViewComponent from '@/views/main/layouts/dialogs/itemsViewComponent.vue';
  import FileUploadComponent from '@/views/main/Utils/FileUploadComponent.vue'

  export default {
    mixins: [
        SharedFunctionsComponentVue,
    ],
    data() {
      return {
        offset: true,
        valid:true,
        dialog:false,
        form: {
            department_id:'',
            location_id:'',
            remarks:'',
            status:1,
            batchAdditionItems:[],
            expected_date:'',
            fas_items:[],
            type:'ho',
            company_id:'',
            stores_id:'',
            transaction_type:'prf',
            trash_item:[],
            reject_reason:'',
            fas_number:''
        },
        file: null,
        menu:false,
        employee_selection_data:[{'value':0,'text':'GENERAL ACCOUNTABILITY','department_id':0}],
        search_employee:'',
        search_department:'',
        action:'',
        disabled_view: false,
        errors: {
          emp_id: [(v) => !!v || "Employee Name is required"],
          location: [(v) => !!v || "Location is required"],
        },
        URL:process.env.VUE_APP_API_HOST,
        batchAdditionItems:[],
        collection:[],
        collectionQTY:[],
        loaded:true,
        userAccess: {
          approve:false,
          reject:false,
        },
        dataTable:{
          headers:[
              { text: "Item Name", align: "left", value: "item_id",width:"500",sortable:false },
              { text: "UOM", align: "left", value: "uom_id",width:"250",sortable:false },
              { text: "QTY", align: "left", value: "qty",width:"250",sortable:false },
              { text: "Previous Qty", align: "left", value: "previous_qty",sortable:false },
              { text: "Justification", align: "left", value: "justification_remarks",sortable:false },
              { text: "", align: "center", value: "action",sortable:false}
          ],
          items:[],
          options:{},
          itemsPerPageOption:[5,15,20,100],
          totalItem:0,
          itemsPerpage:5,
          search:'',
          selectable:[],
          selectable_previous:[],
          previousData:[],
        },
        selection:{
            admin_items:[],
            uom:[]
          },
        item_update:'',
        qty_update:1,
        previousData_dialog:false,
        filter:{
          expected_date:''
        },
        clonedStores:[],
        stores_sync:{},
        getAllCompanies:[],
        tmp:0,
        submitLoad:true,
        batch_dialog:false,
        batch_items:[],
        importItems:[],
        basket_item_id_with_assets: [],
        tab: 0,
        item_list: [],
        basketWithAssetDialog: false,
        basketWithAssetItems: [],
        item_total: 0,
        search_asset_tag: '',
      };
    },
    components: {
      FasRequisitionComponent,
      ItemsViewComponent,
      FileUploadComponent,
    },
    computed: {
      ...mapGetters([
        "ACTION",
        "DIALOGS",
        "USER_ACCESS",
        "GET_ADMIN_LOCATION",
        "GET_EMPLOYEE_DATA_SELECTION",
        "GET_EDIT_ADMIN_FASREQUISITION",
        "GET_NEW_PURCHASE_REQUEST_TABLE",
        "GET_ADMIN_FASREQUISITION_DISPATCH",
        "GET_LIST_DEPARTMENT",
        "GET_UOM_SELECTION",
        "GET_NEW_ADMIN_FASREQUISITION",
        "GET_EDIT_FAS_REQUISITION",
        "GET_ADMIN_FASREQUISITION_STATUS",
        "GET_PV_COMPANIES_SELECTION",
        "GET_PV_STORE_BRANCHES_SELECTION",
        "GET_ADMIN_UOM_SELECTION",
        "USERACCOUNT_LOAD"
      ]),
      actions() {
        if (this.USER_ACCESS != "fail") {
            this.userAccess.approve = false
            this.USER_ACCESS.forEach((e) => {
             if(e.actions_code == 'approved'){
                this.userAccess.approve = true
              }
              else if(e.actions_code == 'reject'){
                this.userAccess.reject = true
              }
            });
          }
        if (this.ACTION == "Submit") {
          this.disabled_view = false;
          this.action = this.GET_ADMIN_FASREQUISITION_DISPATCH.add
          this.form.status = 1
          return "Submit";
        } else if (this.ACTION == "Update") {
          this.disabled_view = false;
          this.showItems()
          this.form.status = 1
          this.action = this.GET_ADMIN_FASREQUISITION_DISPATCH.update;

          return 'Update';
        } else if (this.ACTION == "View") {
          this.showItems()
          this.action = this.GET_ADMIN_FASREQUISITION_DISPATCH.update;

          this.disabled_view = true;
          return "View";
        }
      },
      status_text(){
          if(this.GET_ADMIN_FASREQUISITION_STATUS.tab_name == 'ALL'){
            if(this.form.status == 1){
                this.form.status = 2
                return 'Submit for Approval'
            }
            else if (this.form.status == 2){
              this.form.status = 3
              return 'Approve'
            }
          }
          if(this.GET_ADMIN_FASREQUISITION_STATUS.tab_name == 'PENDING'){
            return 'Approve'
          }
          if(this.GET_ADMIN_FASREQUISITION_STATUS.tab_name == 'FORM APPROVAL'){
            if(this.form.status == 2){
              this.form.status = 3;
            }
            return 'Submit for approval'
          }
      },
      previous_with_dialog(){
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return "100%"
          case 'sm': return "100%"
          case 'md': return "40%"
          case 'lg': return "40%"
          case 'xl': return "40%"
        }
      }
    },
    mounted() {
        this.addItems();
        this.getItemsSelection();
    },
    methods: {
    searchAssetTag(){
        if(this.search_asset_tag){
            this.item_list = this.item_list.filter(e => e.asset_code == this.search_asset_tag);
            this.item_total = this.item_list.length;
        } else {
            this.item_list = this.basketWithAssetItems[this.tab]?.items;
            this.item_total = this.item_list.length;
        }
    },
    closeWithAssetDialog(){
        this.basketWithAssetDialog = false;
    },
    countAsset(){
        return this.$queryBuilder.table(this.dataTable.items).where('admin_assets_lists_id','!=',0).count();
    },
    async fetchAssetBasketWithAssets(){
        let get_prf_with_assets = this.$queryBuilder.table(this.dataTable.items).where('admin_assets_lists_id','!=',0).select('admin_fas_basket_item_id').get();
        let ids = [];
        get_prf_with_assets.map((e) => {
          ids.push(e.admin_fas_basket_item_id);
        })
        if(ids.length > 0){
            await this.$store.dispatch('getPrfWithAssets',{get_prf_with_assets: ids}).then(response=> {
                this.basketWithAssetItems = response.data;
            });
        }
        
    },
    getBasketItemIdWithAssets(){
        return this.$queryBuilder.table(this.dataTable.items).where('admin_assets_lists_id','!=',0).get();
    },
    setRowStyle(item){
        if(this.form.transaction_type == 'prf'){
            let withAsset = item.admin_assets_lists_id;
            if(withAsset && withAsset != 0){
              return {backgroundColor:'#acdcf2'}
            }
            else{
              return {backgroundColor:'#FFFFFF'}
            }
        }
    },
    getItemsSelection(){
      this.$store.dispatch('getPRFAdminItems',
      {
        vendor_id:0,
        category:this.form.transaction_type
      }).then(response=> {
        this.selection.admin_items = response
       });
      this.$store.dispatch('getAdminItemUomSelection');
    },
    addItems(){

        this.dataTable.items.push({
          id:0,
          index:this.dataTable.items.length + 1,
          item_id:'',
          qty:1,
          uom_id:'',
          previous_qty:0,
          justification_remarks:''
        });
        // this.form.batchAdditionItems.push({
        //     id:'',
        //     code:'',
        //     qty:0,
        //     uom_id:'',
        //     exists:false,
        //     price:0,
        // })
    },
    addBatchItems(){
        this.batch_items.push({
          item_name:'',
          qty:0
        });
    },
  showItems(){
    this.dataTable.items = [];
      if(!this.tmp){
      this.form.id = this.GET_EDIT_FAS_REQUISITION.id
      this.form.expected_date = this.GET_EDIT_FAS_REQUISITION.expected_date
      this.form.department_id = this.GET_EDIT_FAS_REQUISITION.department_id;
      this.form.location_id = this.GET_EDIT_FAS_REQUISITION.location_id;
      this.form.remarks = this.GET_EDIT_FAS_REQUISITION.justification_remarks;
      this.form.type = this.GET_EDIT_FAS_REQUISITION.type;
      this.form.company_id = this.GET_EDIT_FAS_REQUISITION.company_id;
      this.form.stores_id = this.GET_EDIT_FAS_REQUISITION.store_branches_id;
      this.dataTable.items = this.GET_EDIT_ADMIN_FASREQUISITION;
      this.form.transaction_type = this.GET_EDIT_FAS_REQUISITION.t_type;
      this.form.status = this.GET_EDIT_FAS_REQUISITION.status;
      this.form.fas_number = this.GET_EDIT_FAS_REQUISITION.fas_number;
      this.$store.dispatch('geRelatedTransactionId',this.GET_EDIT_FAS_REQUISITION.id)
      // this.dataTable.items.forEach(async (e,index)=>{
      //   this.usePreviousData(await e,index)
      // })
          if(this.dataTable.items.length == 0){
            this.addItems()
          }
        }
    },
    clearItems(){
        this.dataTable.items = [];
        this.addItems()
    },
    removeItem(i){
        // this.form.batchAdditionItems.splice(i,1);
        if(this.dataTable.items[i] && Object.hasOwn(this.dataTable.items[i],'id')){
          this.form.trash_item.push(this.dataTable.items[i].id)
        }
        this.dataTable.items.splice(i,1);
        // this.collection.splice(i,1);
        // this.collectionQTY.splice(i,1);
        // this.batchAdditionItems.splice(i,1);
        // if(this.form.batchAdditionItems.length == 0){
        //     this.addItems()
        // }
        if(this.dataTable.items.length == 0){
            this.addItems()
        }
    },
    onPaste(evt) {;
            this.importItems = []
            var pastedText = evt.clipboardData.getData('Text');
            let tmp_data = '';
            if (!pastedText) {
                return false;
            }
            else {
                var rows = pastedText.split("\r\n")
                var cells = '';
                if (this.importItems.length > 1) {
                 this.importItems.pop();
                }
                let wrong_template = false;
                for (var y in rows) {
                    if (y != rows.length - 1) {
                        cells = rows[y].split("\t");
                        if (y == rows.length - 1) {
                          this.importItems.push({
                            item_name: cells[0],
                            qty: cells[1],
                        })
                        }
                        if(!Number.isInteger(parseInt(cells[1]))){
                            wrong_template = true;
                        } else {
                            this.importItems.push({
                              item_name:cells[0],
                              qty:cells[1],
                            })
                        }
                    }
                }
                if(wrong_template){
                    Swal.fire('Invalid Format, Sample format Downloaded!','','warning');
                    this.exportTemplate();
                    this.addBatchItems();
                }
            }
            setTimeout(e=>{
              this.batch_items = [...this.importItems]
            },1)
            // setTimeout(this.dialogOpen,1)
        },
        dialogOpen(){
          this.form.batchAdditionItems = [...this.batchAdditionItems]
          this.$store.commit("DIALOG", true)
          this.findFasItems();
        },
      findFasItems(){
        let data = this.batchAdditionItems;
        this.$store.dispatch('findFasByCode',{data:data}).then((response)=>{
          response.data.data.forEach(data=>{
            let batchCollectionById = this.form.batchAdditionItems.find(e=>e.code == data.code)
            batchCollectionById.id = data.id
            batchCollectionById.uom_id = data.uom_id
            batchCollectionById.price = data.price?data.price:0;
          })
        this.collection = [...response.data.data.map(e=>e.code)];
        this.collectionQTY = response.data.data;
        });
      },
     async createOrUpdateFasItemRequest(){
      this.submitLoad = true;
      this.tmp = 1;
      this.form.fas_items = this.dataTable.items
      const department_swal_text = this.form.department_id ? "" : "Department";
      const location_swal_text = this.form.location_id ? "" :   "Location";
      const expted_date_swal_text = this.form.expected_date ? "" :   "Expected Date";

      const array_text = [
        department_swal_text,
        location_swal_text,
        expted_date_swal_text
      ];
      const filtered = array_text.filter(function (el) {
        return el;
      });

      // if(this.form.batchAdditionItems.find(e=>e.id == '') != undefined && this.form.batchAdditionItems.find(e=>e.id == '').id == ''){
      //   let textArr = this.form.batchAdditionItems.map(e=>e.id == ''?'"'+e.code+'"':'').filter(el=>el != '')
      //   Swal.fire({text:'('+textArr+') Not exists',icon:'error'});
      // } else if (this.form.batchAdditionItems.find(e=>e.price == 0) != undefined && this.form.batchAdditionItems.find(e=>e.price == 0)){
      //   Swal.fire({
      //     html: `Zero price found`,
      //     icon: "warning",
      //     allowOutsideClick: false,
      //   });
      // }
      // else  if(!this.form.department_id && !this.form.location_id){
       if((this.form.type == 'ho' && !this.form.department_id &&  !this.form.expected_date) 
       || (this.form.type == 'stores' && !this.form.company_id  && !this.form.expected_date)){
        Swal.fire({
          html: `Fill Required Fields <br> ${array_text.join("<br>&#x2022; ")}`,
          icon: "warning",
          allowOutsideClick: false,
        });
        this.submitLoad = false

        return false;
      }
      let findItemexcessQty = this.$queryBuilder.table(this.dataTable.items).whereColumn('qty','>','previous_qty')
      .where('justification_remarks','')
      .where('previous_qty','!=',0)
      .first();
      if(findItemexcessQty){
        Swal.fire({
          html: `Please fill justification remarks`,
          icon: "warning",
          allowOutsideClick: false,
        });
        this.submitLoad = false
      }
      else{
        this.$store.dispatch(this.action,this.form).then(response=>{
          this.$store.dispatch('geRelatedTransactionId',response.data.id)
        }).catch(e=>{

          // this.$store.dispatch('saveFileDetails',e).saveFileDetails(this.form.id);
          this.submitLoad = false
        });
      }
      },
      async imageLoader(imagePath){
          let image = await this.loadImage(imagePath);
          this.$store.commit('COMPANY_IMAGE', image);
      },
      renderPDF(){
        this.$store.commit('PRINT_FAS_REQUISITION_DETAIL',{});
        console.log();
        this.imageLoader(this.GET_EDIT_ADMIN_FASREQUISITION[0].image_path);
        this.$store.commit('PRINT_FAS_REQUISITION_DETAIL',{items_list:this.GET_EDIT_ADMIN_FASREQUISITION,data:this.GET_EDIT_FAS_REQUISITION,get_image:this.GET_EDIT_ADMIN_FASREQUISITION[0]});
        this.$store.commit('PRINT_FAS_REQUISITION',true);
    },
      closeDialog() {
          this.$store.commit("DIALOG", false);
          this.resetFields();
          this.clearItems();
          this.$store.dispatch('geRelatedTransactionId','')  
          this.$store.commit('EDIT_FAS_REQUISITION',{});
          this.$store.commit('EDIT_ADMIN_FASREQUISITION',[]);
          this.form.type = 'ho'
          this.form.transaction_type = 'prf'
          this.$store.dispatch('geRelatedTransactionId','')  
          this.basket_item_id_with_assets = []
      },
      clearItems(){
        this.form.batchAdditionItems=[]
        this.batchAdditionItems = [];
        this.collection = [];
        this.collectionQTY = [];
        if(this.form.batchAdditionItems.length == 0){
          this.addItems()
        }
      },
      resetFields(){
        Object.assign(this.$data, this.$options.data.call(this));
      },
      massUpdateItems(){
          this.dataTable.selectable.forEach(e=>{
          if(this.item_update){
            e.item_id = this.item_update
          }
          e.qty = this.qty_update
        })
      },
      usePreviousData(item,index){
        let payload = {
            department_id:this.form.department_id,
            location_id:this.form.location_id,
            expected_date:this.filter.expected_date,
            item_id:item.item_id.admin_item_id
        }
        this.$store.dispatch('getPreviousData',payload).then((response)=>{
           item.qty = parseInt(item.qty);
           if(response.data.approved_quantity){
             item.previous_qty = (response.data.approved_quantity)
           }else{
            item.previous_qty  = 0
           }
        });
        
      },
      closePreviousDataDialog(){
        this.previousData_dialog = false;
        this.dataTable.previousData = [];
      },
     async pullPreviousData(){
        if(this.dataTable.previousData.length > 0){
          this.dataTable.items = this.dataTable.previousData.filter((v)=>{  v.id = 0; return v });
          this.closePreviousDataDialog()
        }
      },
      loadedTimeout(val = 2000){
          setTimeout(()=>{
            this.loaded = false
          },val);
      },
      async getBranches(){
            this.clonedStores = this.GET_PV_STORE_BRANCHES_SELECTION
            this.getAllCompanies = this.GET_PV_COMPANIES_SELECTION;
      },
      branchItems(){
                return this.clonedStores
        },
      insertStore(){
            this.form.stores_id = this.branchItems[0].value
        },
      companies(val){
        if(val){
          const query = this.$queryBuilder
          let store = this.GET_PV_STORE_BRANCHES_SELECTION
          let company = this.GET_PV_COMPANIES_SELECTION
          let find = query.table(store).where('value',val).first();
          if(find){
            this.getAllCompanies = query.table(company).where('value',find.company_id).get();
            this.form.company_id = query.table(company).where('value',find.company_id).first('value')
            // this.storesSelect(this.form.company_id)
          }else{
            this.form.company_id = ''
            this.getAllCompanies = query.table(company).get();
            this.clonedStores = query.table(store).get();
          }
        }
      },
      storesSelect(val){
        const query = this.$queryBuilder
        let store = this.GET_PV_STORE_BRANCHES_SELECTION
        let company = this.GET_PV_COMPANIES_SELECTION
        if(val){
          let find = query.table(company).where('value',val).first();
          if(find){
            this.clonedStores = query.table(store).where('company_id',find.value).get();
            this.getAllCompanies = query.table(company).where('value',find.value).get();
            this.form.stores_id = query.table(store).where('company_id',find.value).first('value')
          }
        }else{
          this.form.stores_id = ''
          this.getAllCompanies = query.table(company).get();
          this.clonedStores = query.table(store).get();
        }
     
      },
      reject(tmp = false){
          Swal.fire({
            icon:"question",
            text:"Are you sure you want to reject? This action cannot be undone.",
            inputPlaceholder:"Why? ",
            showCancelButton:true,
            reverseButtons:true,
            confirmButtonColor: '#397373',
            input:'text',
            preConfirm: (res)=>{
              if(res == ''){
                Swal.showValidationMessage(`Reason is Required`)
              }
            }
          }).then(res=>{
            if(res.isConfirmed && res.value != ''){
              this.form.reject_reason = res.value;
              this.form.status = 5;
              this.createOrUpdateFasItemRequest();
            }
          })
        },
        openDialogBatch(){
            this.batch_dialog = true
            this.addBatchItems();
        },
        closeBatchDialog(){
          this.batch_items = [];
          this.batch_dialog = false
        },
        itemPush(item,newItem){
          this.dataTable.items.push({
                id:0,
                index:this.dataTable.items.length + 1,
                item_id:item ?{admin_item_id:item.admin_item_id,item_name:item.item_name} : newItem.item_name,
                qty:newItem.qty,
                uom_id:'',
                previous_qty:0,
                justification_remarks:''
              });
        },
        saveBatch(){
          let old_data = this.dataTable.items;
          this.dataTable.items = [];
          this.loaded = true;
          let query = this.$queryBuilder
          this.batch_items.forEach(rows=>{
            let findItems = query.table(this.selection.admin_items).where('item_name','LIKE',rows.item_name).first();
            let get_old_qty = old_data.find(({item_id}) => parseInt(item_id.admin_item_id) == parseInt(findItems.admin_item_id));
            if(get_old_qty){
              rows.qty = parseInt(rows.qty) + parseInt(get_old_qty.qty);
            }
            if(findItems){
              this.itemPush(findItems,rows)
            }else{
              this.itemPush(findItems,rows)
            }
          })
          setTimeout(this.loaded = false,this.closeBatchDialog(),2000)
        },
        disableZeroItem(){
          return this.$queryBuilder.table(this.dataTable.items).where('item_id','').first() ? true : false;
        },
        exportItem(){
          let query = this.$queryBuilder;
          let arr = [];
          let data =  query.table(this.dataTable.items).select('item_id','uom_id','qty').get();
          data.forEach(e=>{
            let findUOM = query.table(this.GET_ADMIN_UOM_SELECTION).where('value',e.uom_id).first();
            arr.push({
              item_name:typeof e.item_id == 'object' ? e.item_id.item_name : e.item_id,
              uom_name:findUOM ? findUOM.text : '',
              qty:e.qty
            })
          })
          let exportHeadersAndData = {
                ['Items']: {
                    headers: [
                        { header: 'Item Name', width: 20, key: 'item_name'},
                        { header: 'UOM', width: 20, key: 'uom_name'},
                        { header: 'Quantity', width: 20, key: 'qty'},
                    ],
                    data: arr
                },
            }
            this.exportExcel(exportHeadersAndData, `Items`,'')
        },
        exportTemplate(){
            let arr = [];
            let exportHeadersAndData = {
                  ['Items']: {
                      headers: [
                          { header: 'Item Name', width: 20, key: 'item_name'},
                          { header: 'Quantity', width: 20, key: 'qty'},
                      ],
                      data: arr
                  },
              }
              this.exportExcel(exportHeadersAndData, `Item Template`,'')
        },
        handleFileUpload(event) {
            const file = event;
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: 'array' });
                    const firstSheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[firstSheetName];
                    const jsonData = XLSX.utils.sheet_to_json(worksheet, {
                        header: 1,
                        defval: '',
                        blankrows: false,
                        range: 1
                    });

                    const notFoundItems = [];
                    this.batch_items = [];
                    let wrong_template = false;
                    jsonData.map((e) => {
                        if(!Number.isInteger(parseInt(e[1]))){
                              wrong_template = true;
                        } else {
                            let item = this.$queryBuilder.table(this.selection.admin_items).where('item_name',e[0]).first();
                            if (item) {
                              this.batch_items.push({
                                  item_name: e[0],
                                  qty: e[1],
                              });
                            } else {
                                notFoundItems.push(e[0]);
                            }
                        }
                    });
                    if(wrong_template){
                        Swal.fire('Invalid Format, Sample format Downloaded!','','warning');
                        this.exportTemplate();
                        this.addBatchItems();
                    }
                    if (notFoundItems.length > 0) {
                        Swal.fire({
                            title: 'Items Not Found',
                            text: `The following items were not found: ${notFoundItems.join(', ')}`,
                            icon: 'warning',
                            confirmButtonText: 'Ok'
                        });
                    }
                };
                reader.readAsArrayBuffer(file);
            } else {
                console.log('No file selected');
            }
            this.$refs.fileInput.$el.querySelector('input[type="file"]').value = '';
        },
        triggerFileInput() {
            this.$refs.fileInput.$el.querySelector('input[type="file"]').click();
        },
    },
    watch: {
      GET_EDIT_ADMIN_FASREQUISITION:{
        handler(val){
          // this.loaded = true
          // this.submitLoad = true;
          // if(val.length > 0){
          //     this.loadedTimeout(3000)
          //  }

        }
      },
      GET_NEW_ADMIN_FASREQUISITION:{
        handler(val){
          if(val){
            this.closeDialog();
            Swal.fire("Success!", "Request Saved", "success");
          }
        }
      },
      DIALOGS: {
        handler(val) {
          this.$store.dispatch('getModel','AdminFasPurchaseRequisitionFiles')
          if(val){
            this.getItemsSelection()
            if(this.dataTable.items.length == 0){
              this.addItems();
            }
          }
        },
      },
      'form.type':{
        handler(val){
          this.dataTable.items = []
          this.form.department_id = ''
          this.form.location_id = ''
          this.form.company_id = ''
          this.form.stores_id = ''
          this.addItems()
          this.getBranches()
        }
      },
      'form.transaction_type':{
        handler(val){
          this.dataTable.items = []
          this.selection.admin_items = []
          this.loaded = true
          this.submitLoad = true
            if(val){
              this.getItemsSelection();
              this.addItems()
            }
          
        },
        deep:true
      },
      'dataTable':{
        handler(val){
          this.selection.admin_items.forEach(el=>{
            if(el.admin_item_id){
              el.isDisabled = val.items.filter(e=>e.item_id.admin_item_id == el.admin_item_id).length == 1 ? true : false
            }
          })
          val.items.forEach(e=>{
            e.qty = parseInt(e.qty);
          })
        },
        deep:true
      },
      'selection.admin_items':{
        handler(val){
          if(val.length > 0){
            this.loaded = false;
            this.submitLoad = false;
          }
        }
      },
      'dataTable.items':{
          handler(val){
              if(!!val){
                this.fetchAssetBasketWithAssets();
              }
          },
          deep:true
      },
      tab:{
        handler(val){
          this.item_list = this.basketWithAssetItems[val]?.items;
          this.item_total = this.item_list.length;
        },
      },
      basketWithAssetItems:{
        handler(val){
          this.item_list = this.basketWithAssetItems[this.tab]?.items;
          this.item_total = this.item_list.length;
        },
        deep: true
      }
    },
  };
  </script>
  <style scoped>
    .red-text {
      color: red;
    }
  </style>
  <style>
  .asset-code:hover {
    color: orange;
  }
  </style>
