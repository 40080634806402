<template>
    <div>
        <v-card-text class="bg-bluish-gray m-1">
            <v-row>
                <v-progress-linear indeterminate color="green" v-if="!loaded"></v-progress-linear>
            </v-row>
            <v-row>
                <v-col lg="10">
                    <slot name="first-row"></slot>
                </v-col>
                <v-col lg="2" class="d-flex justify-content-center">
                    <v-btn v-if="!disableButton" small style="margin-right: 3px" @click="resetFields" :loading="!loaded"> Clear </v-btn>
                    <v-btn v-if="exportExcel && !disableButton" small style="margin-right: 3px" @click="exportToExcel" :loading="!loaded"> Export </v-btn>
                    <!-- <v-menu transition="slide-y-transition" bottom v-if="exportExcelMultiple && !disableButton" :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" :loading="!loaded" small > Export </v-btn>
                        </template>
                        <v-list>
                            <v-list-item>
                                <v-card class="mx-auto" max-width="400">
                                    <v-card-text>
                                        <v-chip-group v-model="filters.selectedDetails" column multiple>
                                            <v-chip v-for="(selection,i) in exportExcelSelection" :key="i" filter outlined>
                                                {{ selection }}
                                            </v-chip>
                                        </v-chip-group>
                                    </v-card-text>
                                    <v-card-text>
                                        <v-btn small @click="exportMultiple()" class="pull-right float-right mx-2">Export</v-btn>
                                    </v-card-text>
                                </v-card>
                            </v-list-item>
                        </v-list>
                    </v-menu> -->
                    <v-btn v-if="!disableButton" small @click="list(1); options.page = 1;" :loading="!loaded">Filter</v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col lg="10">
                    <slot name="second-row"></slot>
                </v-col>
            </v-row>
            <v-row>
                <v-col lg="10">
                    <slot name="third-row"></slot>
                </v-col>
            </v-row>
            <v-row class="my-2">
                <v-col lg="10">
                    <slot name="fourth-row"></slot>
                </v-col>
            </v-row>

            <v-row style="background-color: white" v-if="totals && filtered" dense no-gutters>
                <v-col lg="2" sm="12">
                    <v-card class="total-cards" elevation="2">
                        <v-card-text class="d-flex justify-content-start align-items-center">
                            <div class="card-icon total-amount">
                                <v-icon class="text-white" style="font-size: 40px">mdi-cart</v-icon>
                            </div>
                            <div class="card-details">
                                <p>Net Sales Total</p>
                                <b style="color: black" >&#x20b1;{{ GET_SALES_REPORT_DATA.gross_sales_total_amount === 0 ? 0 : GET_SALES_REPORT_DATA.net_sales_total_amount | currency }}</b>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col lg="2" sm="12">
                    <v-card class="total-cards" elevation="2">
                        <v-card-text class="d-flex justify-content-start align-items-center">
                            <div class="card-icon total-amount">
                                <v-icon class="text-white" style="font-size: 40px">mdi-cart</v-icon>
                            </div>
                            <div class="card-details">
                                <p>Gross Sales Total</p>
                                <b style="color: black" >&#x20b1;{{ GET_SALES_REPORT_DATA.gross_sales_total_amount | currency }}</b>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col lg="2" sm="12">
                    <v-card class="total-cards" elevation="2">
                        <v-card-text class="d-flex justify-content-start align-items-center">
                            <div class="card-icon total-amount">
                                <v-icon class="text-white" style="font-size: 40px">mdi-cart</v-icon>
                            </div>
                            <div class="card-details">
                                <p>Net Adjustment</p>
                                <b style="color: black" >&#x20b1;{{ GET_SALES_REPORT_DATA.gross_sales_total_amount === 0 ? 0 : GET_SALES_REPORT_DATA.adjustments_total_amount | currency }}</b>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col lg="2" sm="12">
                    <v-card class="total-cards" elevation="2">
                        <v-card-text class="d-flex justify-content-start align-items-center">
                            <div class="card-icon total-customer">
                                <v-icon class="text-white" style="font-size: 40px">mdi-account-multiple-outline</v-icon>
                            </div>
                            <div class="card-details">
                                <p>Total Customer</p>
                                <b style="color: black">{{ GET_SALES_REPORT_DATA.gross_sales_total_amount === 0 ? 0 : GET_SALES_REPORT_DATA.customer_count}}</b>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col lg="2" sm="12" v-if="userCosting.showCost">
                    <v-card class="total-cards" elevation="2">
                        <v-card-text class="d-flex justify-content-start align-items-center">
                            <div class="card-icon total-amount">
                                <v-icon class="text-white" style="font-size: 40px">mdi-cart</v-icon>
                            </div>
                            <div class="card-details">
                                <p>Net Cost Total</p>
                                <b style="color: black" >&#x20b1;{{ GET_SALES_REPORT_DATA.net_cost_total_amount | currency }}</b>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col lg="2" sm="12" v-if="userCosting.showCost">
                    <v-card class="total-cards" elevation="2">
                        <v-card-text class="d-flex justify-content-start align-items-center">
                            <div class="card-icon total-amount">
                                <v-icon class="text-white" style="font-size: 40px">mdi-cart</v-icon>
                            </div>
                            <div class="card-details">
                                <p>Cost % | Margin %</p>
                                <b style="color: black">
                                    {{ (GET_SALES_REPORT_DATA.net_cost_total_amount / GET_SALES_REPORT_DATA.net_sales_total_amount) | percentTwoDecimals }} |
                                    {{ ((GET_SALES_REPORT_DATA.net_sales_total_amount - GET_SALES_REPORT_DATA.net_cost_total_amount) / GET_SALES_REPORT_DATA.net_sales_total_amount) | percentTwoDecimals }}
                                </b>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row style="background-color: white" v-if="!multiTable && filtered">
                <v-col lg="12">
                    <v-data-table
                        v-if="filtered"
                        :items="loaded ? datatable.items : []"
                        :headers="loaded ? headers : []"
                        :options.sync="options"
                        :server-items-length="loaded ? GET_SALES_REPORT_DATA.data.total : 0"
                        :loading="!loaded"
                        :expanded.sync="row_id"
                        :single-expand="singleExpand"
                        :show-expand="expand"
                        :footer-props="{
                            itemsPerPageOptions: itemsPerPageOptions,
                        }"
                        dense
                        @item-expanded="loadItemsDetails"
                    >
                        <template v-slot:[`item.total_amount`]="{ item }">
                            <span class="text-nowrap">
                                ₱ {{ item.total_amount | currency }}
                            </span>
                        </template>
                        <template v-slot:[`item.total_cost`]="{ item }">
                            <span class="text-nowrap">
                                $ {{ item.total_cost | currency }}
                            </span>
                        </template>
                        <template #item.data-table-expand="{ item, expand, isExpanded }">
                            <td v-if="item.show_expand_icon || item.show_expand_icon == null" class="text-start">
                                <v-btn
                                    icon
                                    class="v-data-table__expand-icon"
                                    :class="{'v-data-table__expand-icon--active' : isExpanded}"
                                    @click="expand(!isExpanded)"
                                >
                                    <v-icon>mdi-chevron-down</v-icon>
                                </v-btn>
                            </td>
                        </template>
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length" class="p-0">
                                <v-simple-table dark dense>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th v-for="(item, i) in item.expand_header" :key="i" :class="{ 'text-right': ['price', 'quantity'].includes(item.type) }">
                                                    {{ upperCaseFirstLetter(item.text.replaceAll("_", " "), item.hasOwnProperty('allCaps') ? item.allCaps : false) }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(object, index) in item.expand.data" :key="index">
                                                <td v-for="(attributes, i) in item.expand_header" :key="i" :class="{ 'text-right': ['price', 'quantity'].includes(attributes.type) }">
                                                    <span v-if="attributes.type == 'price'">
                                                        {{ object[attributes.text] | currency }}
                                                    </span>
                                                    <span v-else-if="attributes.type == 'quantity'">
                                                        {{ thousandSeprator(object[attributes.text]) }}
                                                    </span>
                                                    <span v-else>
                                                        {{ object[attributes.text] }}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </td>
                        </template>
                        <template v-slot:[`item.booked_amount`]="{ item }">
                            <span class="text-nowrap">
                                {{ item.booked_amount | currency }}
                            </span>
                        </template>
                        <template v-slot:[`item.invoiced_amount`]="{ item }">
                            <span class="text-nowrap">
                                {{ item.invoiced_amount | currency }}
                            </span>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <span class="text-nowrap">
                                <v-btn text icon color="orange">
                                    <v-icon class="btn-action" small @click="viewSODetails(item.customer_id, status_id)">mdi-eye</v-icon>
                                </v-btn>
                            </span>
                        </template>
                    </v-data-table>

                    <!-- Dialog for Booked Vs Invoiced -->
                    <v-dialog v-model="view_soa_dialog" persistent max-width="100%" scrollable>
                        <v-card>
                            <v-row class="m-0">
                                <v-card-title>
                                    <span class="headline">Sales Order Details</span>
                                </v-card-title>
                                <v-col cols="pull-right-10 p-2">
                                    <v-btn text icon color="gray" class="float-right" @click="clearDialog(); $emit('closeView');">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col lg="3" sm="6" md="3" class="ml-2">
                                    <v-card class="total-cards" elevation="2">
                                        <v-card-text class="d-flex justify-content-start align-items-center">
                                            <div class="card-icon total-amount">
                                                <v-icon class="text-white" style="font-size: 40px">mdi-cart</v-icon>
                                            </div>
                                            <div class="card-details">
                                                <p>Booked Total Amount</p>
                                                <b style="color: black">&#x20b1;{{ view_total_booked | currency }}</b>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col lg="3" sm="6" md="3" class="ml-2">
                                    <v-card class="total-cards" elevation="2">
                                        <v-card-text class="d-flex justify-content-start align-items-center">
                                            <div class="card-icon total-amount">
                                                <v-icon class="text-white" style="font-size: 40px">mdi-cart</v-icon>
                                            </div>
                                            <div class="card-details">
                                                <p>Invoiced Total Amount</p>
                                                <b style="color: black">&#x20b1;{{ view_total_invoiced | currency }}</b>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col> </v-col>
                                <v-col sm="12" md="3" class="text-right mr-3">
                                    <a href="#" class="badge badge-all ml-2" @click="status_id = -1; viewSODetails(customer_id, -1);">all</a>
                                    <a href="#" class="badge badge-saved ml-2" @click="status_id = 0; viewSODetails(customer_id, 0);">saved</a>
                                    <a href="#" class="badge badge-for-exec ml-2" @click="status_id = 11; viewSODetails(customer_id, 11);">for exec approval</a>
                                    <a href="#" class="badge badge-for-sales ml-2" @click="status_id = 1; viewSODetails(customer_id, 1);">for sales</a>
                                    <a href="#" class="badge badge-for-accounting ml-2" @click="status_id = 2; viewSODetails(customer_id, 2);">for accounting</a>
                                    <a href="#" class="badge white--text badge-info ml-2" @click="status_id = 9; viewSODetails(customer_id, 9);">exec approved</a>
                                    <a href="#" class="badge badge-approved ml-2" @click="status_id = 3; viewSODetails(customer_id, 3);">approved</a>
                                    <a href="#" class="badge badge-converted ml-2" @click="status_id = 8; viewSODetails(customer_id, 8);">converted</a>
                                    <a href="#" class="badge badge-partial ml-2" @click="status_id = 10; viewSODetails(customer_id, 10);">partial</a>
                                    <a href="#" class="badge badge-completed ml-2" @click="status_id = 4; viewSODetails(customer_id, 4);">completed</a>
                                    <!-- <a href="#" class="badge badge-warning" @click="status_id=5; getAllSalesOrders()">rejected</a> -->
                                    <a href="#" class="badge white--text badge-warning ml-2" @click="status_id = 12; viewSODetails(customer_id, 12);">stockcard</a>
                                    <a href="#" class="badge white--text badge-danger ml-2" @click="status_id = 6; viewSODetails(customer_id, 6);">canceled</a>
                                    <!-- <a href="#" class="badge badge-completed" @click="status_id=7; getAllSalesOrders()">deleted</a> -->
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col sm="12" md="2" class="ml-5 align-items-end">
                                    <v-text-field
                                        v-model="search"
                                        single-line
                                        label="Search SO#"
                                        @keydown.enter="searchInput()"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-card-text>
                                <v-data-table
                                    :headers="view_headers"
                                    :items="view_items"
                                    :options.sync="options"
                                    :server-items-length="loaded ? this.GET_SALES_REPORT_DATA.total : 0"
                                    :loading="!loaded"
                                    class="w-100"
                                >
                                    <template v-slot:[`item.booked_total`]="{ item }">
                                        <span class="text-nowrap">
                                            {{ item.booked_total | currency }}
                                        </span>
                                    </template>
                                    <template v-slot:[`item.invoiced_total`]="{ item }">
                                        <span class="text-nowrap">
                                            {{ item.invoiced_total | currency }}
                                        </span>
                                    </template>
                                    <template v-slot:[`item.view_total_amount`]="{ item }">
                                        <span class="text-nowrap">
                                            {{ item.booked_total | currency }}
                                        </span>
                                    </template>
                                    <template v-slot:[`item.status_text`]="{ item }">
                                        <span class="badge badge-saved" v-if="item.status_text == 'saved'">{{ item.status_text }}</span>
                                        <span class="badge badge-for-sales" v-else-if="item.status_text == 'for sales'">{{ item.status_text }}</span>
                                        <span class="badge badge-for-accounting" v-else-if="item.status_text == 'for accounting'">{{ item.status_text }}</span>
                                        <span class="badge badge-approved" v-else-if="item.status_text == 'approved'">{{ item.status_text }}</span>
                                        <span class="badge badge-completed" v-else-if="item.status_text == 'completed'">{{ item.status_text }}</span>
                                        <span class="badge badge-warning" v-else-if="item.status_text == 'rejected'">{{ item.status_text }}</span>
                                        <span class="badge badge-danger" v-else-if="item.status_text == 'canceled'">{{ item.status_text }}</span>
                                        <span class="badge badge-dark" v-else-if="item.status_text == 'deleted'">{{ item.status_text }}</span>
                                        <span class="badge badge-purple" v-else-if="item.status_text == 'converted'">{{ item.status_text }}</span>
                                        <span class="badge badge-info" v-else-if="item.status_text == 'exec approved'">{{ item.status_text }}</span>
                                        <span class="badge badge-pending" v-else-if="item.status_text == 'partial'">{{ item.status_text }}</span>
                                        <span class="badge badge-for-exec" v-else-if="item.status_text == 'for exec approval'">{{ item.status_text }}</span>
                                        <span class="badge badge-warning" v-else-if="item.status_text == 'stockcard'">{{ item.status_text }}</span>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <span class="text-nowrap">
                                            <v-btn text icon color="orange">
                                                <v-icon class="btn-action" small @click="viewSalesOrder(item.id)">mdi-eye</v-icon>
                                            </v-btn>
                                        </span>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>

            <v-row v-if="!!multiTable && filtered">
                <v-col lg="12" class="bg-white pa-5" v-for="(item, i) in GET_SALES_REPORT_DATA ? GET_SALES_REPORT_DATA : []" :key="i">
                    <h6> {{ item.title }} <span>{{ item.total_per_page }}</span> </h6>
                    <v-data-table
                        v-if="filtered"
                        :items="loaded ? item.data : []"
                        :headers="loaded ? headers : []"
                        :loading="!loaded"
                        dense
                    >
                        <template v-slot:[`item.total_amount`]="{ item }">
                            <span class="text-nowrap">
                                ₱ {{ item.total_amount | currency }}
                            </span>
                        </template>
                        <template v-slot:[`item.total_cost`]="{ item }">
                            <span class="text-nowrap">
                                $ {{ item.total_cost | currency }}
                            </span>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import swal from "sweetalert2";
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
var _ = require("lodash");
export default {
    mixins: [
        SharedFunctionsComponentVue
    ],
    props: {
        headers: {
            default: [],
            type: Array,
        },
        disableButton: {
            default: false,
            type: Boolean,
        },
        exportExcel: {
            default: true,
            type: Boolean,
        },
        exportExcelMultiple: {
            default: false,
            type: Boolean,
        },
        exportExcelSelection: {
            default: () => {
                return []
            },
            type: Array,
        },
        multiTable: {
            default: false,
            type: Boolean,
        },
        totals: {
            default: false,
            type: Boolean,
        },
        isBookedVsInv: {
            default: false,
            type: Boolean,
        },
        isSalesByCustomer: {
            default: false,
            type: Boolean,
        },
        trigger: {
            default: false,
            type: Boolean,
        },
        expand: {
            default: false,
            type: Boolean,
        },
        charts: {
            default: function () {
                return [];
            },
            type: Array,
        },
        viewSalesOrderId: {
            default: 0,
            type: Number,
        },
        isResetField: {
            default: 0,
            type: Number,
        },
        userCosting: {
            default: function () {
                return {}
            },
            type: Object,
        },
        singleExpand: {
            default: true,
            type: Boolean,
        },
    },
    data() {
        return {
            row_id: [],
            customer_header: [],
            customer_data: [],
            expanded: [],
            options: {},
            status_id: -1,
            view_items: [],
            view_soa_dialog: false,
            view_total_booked: "",
            view_total_invoiced: "",
            loaded: true,
            filtered: true,
            headers: [],
            view_headers: [],
            chartOptions: {
                pie: {
                    chart: {
                        id: "vuechart-example",
                    },
                    series: [30, 40, 35, 50, 49, 60, 70, 91],
                    labels: ["a", "b", "c", "p"],
                },
                line: {
                    chart: {
                        id: "vuechart-example",
                    },
                    xaxis: {
                        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
                    },
                    series: [
                        {
                            name: "series-1",
                            data: [30, 40, 45, 50, 49, 60, 70, 81],
                        },
                    ],
                },
            },
            datatable: {
                items: [],
                headers: [],
                expanded: [],
            },
            date_range: [],
            series: [],
            chartOptions: {
                chart: {
                    width: 380,
                    type: "pie",
                },
                labels: [
                    "Total Booked Amount",
                    "Total Invoiced Amount",
                    "Not Total Invoiced Amount",
                ],
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
            },
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            search: "",
            menu: false,
            filters: {
                selectedDetails: [0, 1]
            },
            initialLoad: true
        };
    },

    mounted() {
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
    },

    computed: {
        ...mapGetters([
            "GET_SALES_REPORT_DATA",
            "GET_SALES_REPORT_EXPANTION_DETAILS",
            "GET_SALES_REPORT_CHART",
            "GET_FILTER_VALUE",
        ]),
    },

    methods: {
        clearDialog() {
            this.view_soa_dialog = false;
            this.view_items = [];
        },
        viewSalesOrder(id) {
            this.viewSalesOrderId = id;
            this.$emit("viewSalesOrder", this.viewSalesOrderId);
        },
        async loadItemsDetails(item) {
            let data = this.$store.state.SalesReports.filters;

            let index = _.findIndex(this.datatable.items, item.item);
            let that = this;
            data.expansion_url = data.expand_url.replace("detail_id", item.item.id);

            await this.$store.dispatch("salesReportExpansion", data).then((e) => {
                e.data = e.data.map((e) => {
                    data.expand_header.forEach((element) => {
                        if (element.type == "price") {
                            e[element.text] = this.getTwoDecimals(e[element.text]);
                        }

                        if (element.type == "quantity") {
                            e[element.text] = this.thousandSeprator(e[element.text]);
                        }
                    });
                    return e;
                });
                that.datatable.items[index].expand = e;
                that.datatable.items[index].expand_header = data.expand_header;
            });

            this.$forceUpdate();
        },

        resetFields() {
            let filters = this.$store.state.SalesReports.filters;
            this.isResetField = this.$store.state.SalesReports.filters.isReset;

            for (const key in filters) {
                filters[key] = "";
            }

            this.$emit("resetFields", this.isResetField);
            this.$store.commit("SALES_REPORT_DATA", []);
            this.datatable.items = [];
        },

        async list(page = 1, itemsPerPage = 10) {
            this.loaded = false;

            let filters = this.$store.state.SalesReports.filters;

            this.headers = filters.headers;
            if (!filters) {
                this.loaded = true;
                swal.fire("Error!", "- filtered data not define", "error");
                return false;
            }

            filters.page = page;
            filters.itemsPerPage = itemsPerPage;
            delete filters.exportToExcel;
            await this.$store.dispatch("reports", filters).then((e) => {
                if (!this.multiTable) {
                    this.datatable.items = this.GET_SALES_REPORT_DATA.data.data;
                    const totalAmountSum = this.datatable.items.reduce((sum, e) => sum + (e.total_amount || 0), 0);
                    // if (totalAmountSum === 0) {
                    //     this.datatable.items = [];
                    // }
                }

                this.loaded = true;
                this.filtered = true;
                this.row_id = []
                this.expanded = [];
            });
        },

        async viewSODetails(customer_id, salesman_id, status_id) {
            this.customer_id = customer_id;
            let salesman_ids = salesman_id;
            this.status = status_id;

            let date_from = this.$store.state.SalesReports.filters.date_from;
            let date_to = this.$store.state.SalesReports.filters.date_to;
            this.date_range = this.getDatesFromRange(date_from, date_to, "array");
            this.view_headers = this.$store.state.SalesReports.filters.view_headers;
            this.salesman_id = this.$store.state.SalesReports.filters.salesman_id;

            await this.$store.dispatch("reports", {
                customer_id: this.customer_id,
                salesman_id: salesman_ids,
                status_filter: this.status_id,
                search: this.search,
                date_range: JSON.stringify(this.date_range),

                url: "viewSODetails",
            });
            this.view_items = this.GET_SALES_REPORT_DATA.sales;
            this.view_total_booked = this.GET_SALES_REPORT_DATA.total_booked_view;
            this.view_total_invoiced = this.GET_SALES_REPORT_DATA.total_invoiced_view;
            this.view_soa_dialog = true;
            this.search = "";
        },
        async exportToExcel() {
            this.loaded = false;
            this.filtered = false;
            let filters = this.$store.state.SalesReports.filters;
            filters.exportToExcel = true;

            const workbook = new this.$exceljs.Workbook();

            await this.$store
                .dispatch("reports", filters)
                .then(async (e) => {
                    this.loaded = true;

                    if (this.GET_SALES_REPORT_DATA.type === 'sales-by-clusters') {
                        const worksheet = workbook.addWorksheet('SUMMARY');
                        let headers = this.GET_SALES_REPORT_DATA.summary[0]

                        headers = Object.keys(headers).filter(e => {
                            return e.toUpperCase() != 'ID' && !e.toUpperCase().includes('EXPAND_ICON')
                        })

                        headers = headers.map((e) => {
                            return {
                                header: this.upperCaseFirstLetter(e.replaceAll("_", " ")),
                                key: e,
                                style:
                                    e.toUpperCase().includes("AMOUNT") ||
                                        e.toUpperCase().includes("COST")
                                        ? { numFmt: "#,##0.00" }
                                        : "",
                            };
                        });

                        worksheet.columns = headers;

                        // formatting validation before insert of data
                        for (let index in this.GET_SALES_REPORT_DATA.summary) {
                            const json = JSON.stringify(this.GET_SALES_REPORT_DATA.summary[index]);
                            const obj = JSON.parse(json);
                            for (const key of Object.keys(obj)) {
                                const value = obj[key];
                                if (value === "null") {
                                    obj[key] = null;
                                } else if (value === "true" || value === "false") {
                                    obj[key] = value === "true";
                                } else if (value === "") {
                                    obj[key] = "";
                                } else if (value === " ") {
                                    obj[key] = "";
                                } else if (value === null) {
                                    obj[key] = "";
                                } else if (!isNaN(value)) {
                                    obj[key] = Number(value);
                                } else if (this.$dayjs(value, 'YYYY-MM-DDTHH:MN:SS.MSSZ').isValid()) {
                                    obj[key] =
                                        value.indexOf(":") != -1
                                            ? new Date(Date.parse(value.replace(" ", "T") + ".000Z"))
                                            : new Date(value);
                                }
                            }

                            worksheet.getRow(1).font = { bold: true };
                            worksheet.addRow(obj);
                        }
                    }

                    const worksheet = workbook.addWorksheet(
                        this.GET_SALES_REPORT_DATA.type === 'sales-by-clusters'
                        ? 'DETAILED'
                        : filters.filename
                    );

                    let headers = this.GET_SALES_REPORT_DATA.data[0]

                    headers = Object.keys(headers).filter(e => {
                        return e.toUpperCase() != 'ID' && !e.toUpperCase().includes('EXPAND_ICON')
                    })

                    headers = headers.map((e) => {
                        return {
                            header: this.upperCaseFirstLetter(e.replaceAll("_", " ")),
                            key: e,
                            style:
                                e.toUpperCase().includes("AMOUNT") ||
                                    e.toUpperCase().includes("COST")
                                    ? { numFmt: "#,##0.00" }
                                    : "",
                        };
                    });

                    worksheet.columns = headers;

                    // formatting validation before insert of data
                    for (let index in this.GET_SALES_REPORT_DATA.data) {
                        const json = JSON.stringify(this.GET_SALES_REPORT_DATA.data[index]);
                        const obj = JSON.parse(json);
                        for (const key of Object.keys(obj)) {
                            const value = obj[key];
                            if (value === "null") {
                                obj[key] = null;
                            } else if (value === "true" || value === "false") {
                                obj[key] = value === "true";
                            } else if (value === "") {
                                obj[key] = "";
                            } else if (value === " ") {
                                obj[key] = "";
                            } else if (value === null) {
                                obj[key] = "";
                            } else if (!isNaN(value)) {
                                obj[key] = Number(value);
                            } else if (this.$dayjs(value, 'YYYY-MM-DDTHH:MN:SS.MSSZ').isValid()) {
                                obj[key] =
                                    value.indexOf(":") != -1
                                        ? new Date(Date.parse(value.replace(" ", "T") + ".000Z"))
                                        : new Date(value);
                            }
                        }

                        worksheet.getRow(1).font = { bold: true };
                        // this.GET_SALES_REPORT_DATA.data.forEach(e => {
                        //     worksheet.addRow(e);
                        // });
                        worksheet.addRow(obj);
                    }

                    if (this.isBookedVsInv) {
                        worksheet.spliceColumns(1, 1);
                    }

                    await workbook.xlsx.writeBuffer().then((data) => {
                        const blob = new Blob([data], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
                        });
                        this.$filesaver.saveAs(
                            blob,
                            `${filters.filename} ${filters.date_from} - ${filters.date_to}.xlsx`
                        );
                    });

                    this.list();
                })
                .catch((e) => {
                    console.log(e);
                    swal.fire("Error", " - An error occured", "error");
                });
            // }
        },
        searchInput() {
            this.viewSODetails(this.customer_id, this.status);
        },
        async exportMultiple() {
            this.loaded = false;
            this.filtered = false;
            let filters = this.$store.state.SalesReports.filters;
            filters.exportToExcel = true;

            const workbook = new this.$exceljs.Workbook();

            for (let i in this.filters.selectedDetails) {
                const worksheet = workbook.addWorksheet(filters.sheetName[this.filters.selectedDetails[i]]);
                filters.url = filters.multiExport[this.filters.selectedDetails[i]]

                await this.$store
                    .dispatch("reports", filters)
                    .then(async (e) => {
                        this.loaded = true;
                        let headers = this.GET_SALES_REPORT_DATA.data[0];
                        headers = Object.keys(headers).map((e) => {
                            return {
                                header: this.upperCaseFirstLetter(e.replaceAll("_", " ")),
                                key: e,
                                style:
                                    e.toUpperCase().includes("AMOUNT") ||
                                        e.toUpperCase().includes("COST") ||
                                        e.toUpperCase().includes("TOTAL BOOKED") ||
                                        e.toUpperCase().includes("TOTAL BOOKED PENDING AMOUNT") ||
                                        e.toUpperCase().includes('DISC PRICE') ||
                                        e.toUpperCase().includes("TOTAL INVOICED")
                                        ? { numFmt: "#,##0.00" }
                                        : "",
                            };
                        });

                        worksheet.columns = headers;

                        // formatting validation before insert of data
                        for (let index in this.GET_SALES_REPORT_DATA.data) {
                            const json = JSON.stringify(this.GET_SALES_REPORT_DATA.data[index]);
                            const obj = JSON.parse(json);
                            for (const key of Object.keys(obj)) {
                                const value = obj[key];
                                if (value === "null") {
                                    obj[key] = null;
                                } else if (value === "true" || value === "false") {
                                    obj[key] = value === "true";
                                } else if (value === "") {
                                    obj[key] = "";
                                } else if (value === " ") {
                                    obj[key] = "";
                                } else if (value === null) {
                                    obj[key] = "";
                                } else if (!isNaN(value)) {
                                    obj[key] = Number(value);
                                } else if (this.$dayjs(value, 'YYYY-MM-DDTHH:MN:SS.MSSZ').isValid()) {
                                    obj[key] =
                                        value.indexOf(":") != -1
                                            ? new Date(Date.parse(value.replace(" ", "T") + ".000Z"))
                                            : new Date(value);
                                }
                            }

                            worksheet.getRow(1).font = { bold: true };

                            worksheet.addRow(obj);
                        }
                    })

                if (this.isBookedVsInv) {
                    worksheet.spliceColumns(1, 1);
                }
            }

            await workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
                });
                this.$filesaver.saveAs(blob, `${filters.filename} ${filters.date_from} - ${filters.date_to}.xlsx`);
            });

            this.list();
        }
    },
    watch: {
        options: {
            handler(val) {
                if(!this.initialLoad){
                    this.list(val.page, val.itemsPerPage);
                }else{
                    this.initialLoad = false;
                }
            },
            deep: true,
        },

        GET_FILTER_VALUE: {
            handler(val) {
                this.headers = val.headers
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.card-icon {
    height: 70px;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.total-amount {
    background-color: #28a745;
}

.total-amount-booked {
    background-color: #1976d2;
}

.total-customer {
    background-color: #ffc107;
}

.card-details {
    height: 70px;
    padding-left: 9px;
    padding-top: 20px;
    // width: 50%;
    // background-color: #28a745;
    line-height: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.total-cards {
    // width: 90%;
    margin: 4px;
}

.v-btn {
    margin-right: 2px;
}
</style>
