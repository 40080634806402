<template>
    <v-container fluid>
        <ComponentHeader
            class="ma-n4"
            :button="true"
            title='Credit Memo Detailed'
            type='Accounting'
        ></ComponentHeader>
        <v-row>
            <v-app id="my_vue_app" style="width:100%" class="ml-3 mr-3 bg-bluish-gray pa-3">
                <v-row no-gutters>
                    <v-col sm="12" md="6">
                        <v-autocomplete
                            :items="GET_CUSTOMERS_SELECTION"
                            :loading="loading.customer_selection"
                            :disabled="loading.customer_selection"
                            multiple
                            chips
                            outlined
                            dense
                            v-model="selectedCustomers"
                            label="Select Customers"
                            class="d-inline-block w-100"
                            background-color="white"
                            small-chips
                        ></v-autocomplete>
                    </v-col>
                    <v-col sm="12" md="2">
                        <v-autocomplete
                            v-model="selectedCreditMemoClassification"
                            :items="GET_CREDIT_MEMO_CLASSIFICATION"
                            :loading="loading.credit_memo_classification_selection"
                            :disabled="loading.credit_memo_classification_selection"
                            label="Select Classification"
                            class="d-inline-block w-100"
                            background-color="white"
                            outlined
                            dense
                            hide-details
                        ></v-autocomplete>
                    </v-col>
                    <v-col sm="12" md="4" class="text-right mt-1">
                        <v-btn class="ml-1" small @click="resetFields()">Clear</v-btn>
                        <v-btn class="ml-1" small @click="exportCreditMemoDetailed()">Excel</v-btn>
                        <v-btn class="ml-1" small @click="getCreditMemoDetailed(-1,1,-1);expanded = false">Filter</v-btn>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col sm="12" md="1">
                        <v-autocomplete
                            outlined
                            dense
                            :items="origin_list"
                            v-model="origin"
                            label="Select Origin"
                            class="d-inline-block w-100"
                            background-color="white"
                        ></v-autocomplete>
                    </v-col>
                    <v-col sm="12" md="3">
                        <v-autocomplete
                            outlined
                            dense
                            :items="GET_CREDIT_MEMO_TYPES"
                            v-model="memo_type_id"
                            item-value="value"
                            item-text="text"
                            label="Select Memo Type"
                            class="d-inline-block w-100"
                            background-color="white"
                        ></v-autocomplete>
                    </v-col>
                    <v-col sm="12" md="2" class="d-flex flex-row">
                        <v-menu
                            v-model="date_from_menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                            class="mx-4"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="creditMemoList.datatable.filters.soa_month.date_from"
                                    readonly
                                    outlined
                                    dense
                                    background-color="white"
                                    append-icon="mdi-calendar-clock"
                                    v-on="on"
                                    label="Date From"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="creditMemoList.datatable.filters.soa_month.date_from"
                                type="month"
                                @input="date_from_menu = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col sm="12" md="2" class="d-flex flex-row">
                        <v-menu
                            v-model="date_to_menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="creditMemoList.datatable.filters.soa_month.date_to"
                                    readonly
                                    append-icon="mdi-calendar-clock"
                                    v-on="on"
                                    outlined
                                    dense
                                    background-color="white"
                                    label="Date To"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="creditMemoList.datatable.filters.soa_month.date_to"
                                type="month"
                                @input="date_to_menu = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>

                <div class="w-100 mt-2">
                    <v-card>
                        <v-container fluid>
                            <v-text-field v-if="!loaded" color="info" loading disabled></v-text-field>
                            <v-row v-if="loaded">
                                <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="ml-2">
                                    <v-card class="total-cards" elevation="2">
                                        <v-card-text class="d-flex justify-content-start align-items-center">
                                            <div class="card-icon pt-2 pb-2 pl-1 pr-1 rounded bg-warning">
                                                <v-icon class="text-white" style="font-size:40px">mdi-account-group</v-icon>
                                            </div>
                                            <div class="card-details ml-2">
                                                <p class="mb-0">Customers</p>
                                                <b style="color:black">{{ customer_count }}</b>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="ml-2">
                                    <v-card class="total-cards" elevation="2">
                                        <v-card-text class="d-flex justify-content-start align-items-center">
                                            <div class="card-icon pt-2 pb-2 pl-1 pr-1 rounded bg-warning">
                                                <v-icon class="text-white" style="font-size:40px">mdi-currency-usd</v-icon>
                                            </div>
                                            <div class="card-details ml-2">
                                                <p class="mb-0">Total CM Amount</p>
                                                <b style="color:black">&#x20b1;{{ total_cm | currency}}</b>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <div class="clearfix hidden-md-up"></div>
                            </v-row>

                            <v-container v-for="(d, i) in dataItems" :key="i">
                                <v-row no-gutters v-if="d.item_count != 0">
                                    <v-col cols="12" class="d-flex no-block align-items-cnter">
                                        <h6 class="report-title">{{ d.company_name }} - {{ d.item_count }} Items</h6>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-expansion-panels multiple v-model="d.expanded">
                                            <v-expansion-panel
                                                v-for="(item,index) in d.dates"
                                                :key="index"
                                                @click="getSelected(index,i,item.value)"
                                                item-key="value"
                                            >
                                                <v-expansion-panel-header>
                                                    {{ new Date(2000, item.selected_month - 1, 1).toLocaleString('default', { month: 'short' }) }} {{ item.selected_year }} - <strong>Total: &#x20b1;{{ item.total_amount | currency}}</strong>
                                                </v-expansion-panel-header>
                                                <v-expansion-panel-content>
                                                    <v-data-table
                                                        :headers="headers"
                                                        :items="item.items"
                                                        :search="search"
                                                        :items-per-page="10"
                                                        show-expand
                                                        class="w-100"
                                                        @item-expanded="loadDetails"
                                                        dense
                                                    >
                                                        <template v-slot:item.total_amount = "{item}">
                                                            {{ item.total_amount | currency }}
                                                        </template>

                                                        <template v-slot:item.status = "{ item }">
                                                            <span class="badge badge-saved" v-if="item.status_text=='pending'">{{ item.status_text }}</span>
                                                            <span class="badge badge-for-accounting" v-if="item.status_text=='for accounting'">{{ item.status_text }}</span>
                                                            <span class="badge badge-for-exec" v-else-if="item.status_text=='for exec'">{{ item.status_text }}</span>
                                                            <span class="badge badge-approved" v-else-if="item.status_text=='approved'">{{ item.status_text }}</span>
                                                            <span class="badge badge-completed" v-else-if="item.status_text=='completed'">{{ item.status_text }}</span>
                                                            <span class="badge badge-warning" v-else-if="item.status_text=='rejected'">{{ item.status_text }}</span>
                                                            <span class="badge badge-danger" v-else-if="item.status_text=='canceled'">{{ item.status_text }}</span>
                                                            <span class="badge badge-dark" v-else-if="item.status_text=='deleted'">{{ item.status_text }}</span>
                                                        </template>

                                                        <template v-slot:item.printed = "{ item }">
                                                            <span class="badge badge-primary" v-if="item.printed">Yes</span>
                                                            <span class="badge badge-danger" v-else>No</span>
                                                        </template>

                                                        <template v-slot:expanded-item="{ headers, item }">
                                                            <td v-if="item.credit_memo_type == 4"  :colspan="headers.length" class="p-0">
                                                                <v-simple-table dense dark >
                                                                    <template v-slot:default>
                                                                        <thead>
                                                                            <td width="30%" >Remarks</td>
                                                                            <td width="20%">Total Amount</td>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr v-for="(credit, i) in item.credit_items" :key="i">
                                                                                <td>{{ credit.remarks }}</td>
                                                                                <td>{{ credit.item_total_amount | currency }}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </template>
                                                                </v-simple-table>
                                                            </td>
                                                            <td v-if="item.credit_memo_type != 4" :colspan="headers.length" class="p-0">
                                                                <v-simple-table dense dark >
                                                                    <template v-slot:default>
                                                                        <thead>
                                                                            <td width="10%"> Item Model</td>
                                                                            <td width="15%">Item Name</td>
                                                                            <td width="5%">UOM</td>
                                                                            <!-- <td width="5%">Qty</td> -->
                                                                            <!-- <td width="10%">Unit Price</td> -->
                                                                            <td width="10%">Amount</td>
                                                                            <td width="30%">Remarks</td>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr v-for="(credit, i) in item.credit_items" :key="i">
                                                                                <td>{{ credit.model }}</td>
                                                                                <td>{{ credit.model_name }}</td>
                                                                                <td>{{ credit.uom }}</td>
                                                                                <!-- <td>{{ credit.qty }}</td> -->
                                                                                <!-- <td>{{ credit.unit_price | currency }}</td> -->
                                                                                <td>{{ credit.item_total_amount | currency }}</td>
                                                                                <td>{{ credit.remarks }}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </template>
                                                                </v-simple-table>
                                                            </td>
                                                        </template>
                                                    </v-data-table>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>
                                        </v-expansion-panels>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-container>
                    </v-card>
                </div>
            </v-app>
        </v-row>
    </v-container>
</template>

<script>
import SharedFunctionsComponentVue from '../../../Shared/SharedFunctionsComponent.vue';
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';

export default {
    mixins: [
        SharedFunctionsComponentVue
    ],
    data(){
        return {
            search: '',
            creditMemoList: {
                datatable: {
                    search: '',
                    filters: {
                        soa_month: {
                            date_from: this.$dayjs().startOf('year').format('YYYY-MM'),
                            date_to : this.$dayjs().endOf('year').format('YYYY-MM'),
                            menu: false,
                        },

                    },
                },
            },
            date_from_menu:false,
            date_to_menu:false,
            dates: [],
            errors: [],
            customer_count:0,

            total_cm:0,
            total_cm_list:[],

            memo_type_id: '',
            selectedCustomers: [],
            selectedCreditMemoClassification: [],
            item_type_list: [],
            item_type_ids: [],
            category_list:[],
            category_ids:[],
            origin_list: [
                { text: 'Local', value: 1 },
                { text: 'China', value: 2 },
            ],

            headers: [
                {text: 'CM #', width:"8%", value: 'credit_memo_num', sortable: false },
                {text: 'CM DATE', width:"8%", value: 'credit_memo_date', sortable: false },
                {text: 'CM TYPE', width:"8%", value: 'credit_memo_type_by_name', sortable: false },
                {text: 'PL REFS#', width:"10%", value: 'packing_list_nums', sortable: false },
                {text: 'DEALER REFS#', width:"10%", value: 'customer_ref_no', sortable: false },
                {text: 'STATUS', width:"5%", value: 'status', sortable: false },
                {text: 'PROCESSED BY',  width:"10%", value: 'created_by_name', sortable: false },
                {text: 'ACCT APPROVED BY', width:"10%", value: 'acct_approved_by_name', sortable: false },
                {text: 'EXEC APPROVED BY', width:"10%", value: 'exec_approved_by_name', sortable: false },
                {text: 'SOA MONTH', width:"8%", align: 'center', value: 'soa_month_year', sortable: false },
                {text: 'PRINTED', width:"5%" ,align: 'center', value: 'printed', sortable: false },
                {text: 'TOTAL CM AMOUNT', width:"5%" , align: 'right', value: 'total_amount', sortable: false },
            ],

            dataItems:[],

            loaded: true,
            options: {},
            itemsPerPageOptions: [5, 10 ,15, 50, 100, 250],
            itemsPerPage: 10,
            loading:{
                customer_selection: true,
                credit_memo_classification_selection: true,
            },
            selectedpanel:[],
            expanded: false,
            selected_total_cm: 0,
            date_items : [],
            expanded_items: [],
            origin: 1,
        }
    },
    components: {
        ComponentHeader,
    },
    mounted() {
        this.getDates()
        this.getCustomers()
        this.getCreditMemoTypes()
        this.getClassifications()
    },
    computed: {
        ...mapGetters([
            'GET_CUSTOMERS_SELECTION',
            'GET_CREDIT_MEMO_TYPES',
            'GET_CREDIT_MEMO_CLASSIFICATION',
        ]),
    },
    methods: {
        getCreditMemoTypes(){
            let payload = {
                origin: this.origin,
            }

            this.$store.dispatch('getCreditMemoTypes',payload)
        },
        getClassifications() {
            this.$store.dispatch('creditMemoClassificationDropdown')
        },
        getCustomers() {
            this.$store.dispatch('getAllcustomersSelection')
        },
        incrementedIndex(key){
            key = 0
            return key + 1;
        },
        loadDetails(item){
            if(item.value){
                let payload = {
                    params: {
                        memo_type_id: this.memo_type_id
                    },
                    id:item.item.id,
                }
                this.$store.dispatch('getItemCreditMemo',payload).then(response => {
                    var data = response.data
                    item.item.credit_items   = data.credit_memo
                    this.$forceUpdate()
                })
            }
        },
        async exportCreditMemoDetailed(index = 0){
            this.loaded = false

            let payload = {
                customer_id                 :    this.selectedCustomers,
                memo_type_id                :    this.memo_type_id,
                soa_month_value_date_from   :    this.creditMemoList.datatable.filters.soa_month.date_from,
                soa_month_value_date_to     :    this.creditMemoList.datatable.filters.soa_month.date_to,
                url:                "exportCreditMemoDetailed",
                month: index + 1,
                credit_memo_classification_id: this.selectedCreditMemoClassification,
                origin: this.origin,
            }
            let data = [];
            const workbook = new this.$exceljs.Workbook();
            await this.$store.dispatch('reports',payload).then(response => {
                const worksheet = workbook.addWorksheet(`Credit Memo Detailed Report`);
                data = response.data
                worksheet.columns = [
                    {header:'Customer Name',key:'customer_company_name'},
                    {header:'CM #',key:'credit_memo_num'},
                    {header:'CM Date',key:'credit_memo_date'},
                    {header:'CM Type',key:'credit_memo_type_by_name'},
                    {header:'PL Ref #',key:'packing_list_nums'},
                    {header:'Dealer Ref #',key:'customer_ref_no'},
                    {header:'CM Remarks',key:'remarks'},
                    {header: 'Total Amount', style: { numFmt: '#,##0.00' }, key:'total_amount'},
                    {header:'Model Name',key:'model'},
                    {header:'Item Name',key:'model_name'},
                    {header:'Item Type',key:'type'},
                    {header:'Quantity', style: { numFmt: '#,##0' }, key:'qty'},
                    {header:'Unit Price', style: { numFmt: '#,##0.00' }, key:'item_amount'},
                    {header:'Line Total', style: { numFmt: '#,##0.00' },key:'line_total'},
                    {header:'Status',key:'status_text'},
                    {header:'Processed By',key:'created_by_name'},
                    {header:'Acct Approved By',key:'acct_approved_by_name'},
                    {header:'Exec Approved By',key:'exec_approved_by_name'},
                    {header:'SOA Month',key:'soa_month_year'},
                    {header:'Printed',key:'printed'},
                ]

                data.forEach((e,i) => {
                    // e.data_items.forEach(x=>{
                    e.printed = e.printed == 1 ? 'YES' : 'NO'
                    e.qty = Number (e.qty)
                    e.item_amount = Number (e.item_amount)
                    e.total_amount = Number (e.total_amount)
                    e.line_total = Number (e.line_total)

                    worksheet.addRow(e);
                    // })
                });

                worksheet.getRow(`0${1}`).font = { bold: true }
            }).catch(e => {
                swal.fire('Error !', '', 'error')
                console.log(e)
                this.loaded = true
            })
            this.loaded = true

            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                this.$filesaver.saveAs(blob, `Credit Memo Detailed Report.xlsx`);
            });
        },

        async getCreditMemoDetailed(month = -1,withSoa = 0,index = -1){
            this.total_cm = 0 ;
            this.loaded = false

            let i = month
            let payload = {
                customer_id                 :    index == -1 ? this.selectedCustomers : [this.dataItems[index].id],
                memo_type_id                :    this.memo_type_id,
                soa_month_value_date_from   :    this.creditMemoList.datatable.filters.soa_month.date_from,
                soa_month_value_date_to     :    this.creditMemoList.datatable.filters.soa_month.date_to,
                url                         :    "getCreditMemoDetailed",
                month: month == -1 ? null : month,
                date_from : this.creditMemoList.datatable.filters.soa_month.date_from,
                date_to : this.creditMemoList.datatable.filters.soa_month.date_to,
                withSoa: withSoa,
                credit_memo_classification_id: this.selectedCreditMemoClassification,
                origin: this.origin,
            }

            await this.$store.dispatch('reports',payload).then(response => {
                if(withSoa == 1){
                    this.dataItems = response.data.customers.map(e=>{
                        Object.assign(e,{data:e.data_items})
                        return e
                    })
                    this.expanded_items = this.dataItems.map(e=>{
                        return e.dates
                    })
                    this.customer_count         = response.data.customer_count
                    this.total_cm_list          = response.data.customers.map(cm => cm.total_cm );
                    for (let i = 0; i < this.total_cm_list.length; i++) {

                        this.total_cm += parseFloat(this.total_cm_list[i]);
                    }
                }
                this.loaded = true
            });
        },
        resetFields(){
            this.errors             = []
            this.customer_count     = ''
            this.memo_type_id       = 0
            this.selectedCustomers  = []
            this.dataItems          = []
            this.creditMemoList.datatable.filters.soa_month.date_from = null
            this.creditMemoList.datatable.filters.soa_month.date_to = null

            this.getCustomers()
        },
        getSelected(index,i,value){
            // this.getCreditMemoDetailed(value,0,i)
        },
        getDates(){
            this.dates = Array.apply(0, Array(12)).map(function(_,i){return {text:this.$dayjs().month(i).format('MMM'),value:this.$dayjs().month(i).format('MM'),show:false,data:[]}});
        }
    },
    watch:{
        'GET_CUSTOMERS_SELECTION':{
            handler(val) {
                this.loading.customer_selection = false
            },
        },
        'GET_CREDIT_MEMO_CLASSIFICATION': {
            handler(val) {
                this.loading.credit_memo_classification_selection = false
            }
        },
        'origin': {
            handler(val) {
                this.getCreditMemoTypes()
            }
        },
        'expanded':{
            handler(val,selected_total_cm){
                if(!val == false){
                }
                else{
                }
            },
        },
    },
}
</script>
