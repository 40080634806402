<template>
    <v-form
        v-model="validForm"
        @submit.prevent="
            ACTION == 'Submit' ? createAssetCondition() : updateAssetCondition()
        "
    >
        <v-card>
            <v-row class="px-3 py-2" dense no-gutters>
                <v-col cols="11">
                    <v-card-title class="pa-0 pl-3">
                        <span class="text-h5">
                            {{ ACTION === "Submit" ? "Create" : ACTION }}
                            Asset Condition
                        </span>
                    </v-card-title>
                </v-col>
                <v-col cols="1">
                    <v-btn
                        text
                        icon
                        small
                        color="gray"
                        class="float-right"
                        @click="closeDialog()"
                    >
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class="pa-0 ma-0"></v-divider>
            <v-row class="px-3 py-2" dense no-gutters>
                <v-col cols="12">
                    <v-text-field
                        v-model="form.code"
                        :rules="readOnlyForm ? [] : rules.code"
                        :disabled="readOnlyForm"
                        :loading="form.loading"
                        label="Code"
                        dense
                        outlined
                    ></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-textarea
                        v-model="form.description"
                        :rules="readOnlyForm ? [] : rules.description"
                        :disabled="readOnlyForm"
                        :loading="form.loading"
                        label="Description"
                        dense
                        outlined
                    ></v-textarea>
                </v-col>
            </v-row>
            <v-divider class="px-0 ma-0" v-if="ACTION !== 'View'"></v-divider>
            <v-card-actions class="text-center" v-if="ACTION !== 'View'">
                <v-row class="ma-1">
                    <v-col cols="12">
                        <v-btn
                            :disabled="!validForm"
                            :loading="readOnlyForm"
                            type="submit"
                        >
                            <span>{{ ACTION }}</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script>
import { mapGetters } from "vuex";
import swal from "sweetalert2";

export default {
    data() {
        return {
            validForm: false,

            form: {
                code: "",
                description: "",

                loading: false,
                submitting: false,
            },
            rules: {
                code: [
                    (value) => {
                        if (value) return true;
                        return "Code is required";
                    },
                ],
                description: [
                    (value) => {
                        if (value) return true;
                        return "Description is required";
                    },
                ],
            },
        };
    },

    computed: {
        ...mapGetters([
            "ACTION",
            "GET_ADMIN_ASSET_CONDITION_VIEW_ID",
        ]),
        readOnlyForm() {
            return this.ACTION == "View" || this.form.submitting;
        },
        calcValidForm() {
            this.validForm = (this.form.code != "" && this.form.description != "");
        },
    },

    methods: {
        closeDialog() {
            this.resetForm();
            this.$store.commit("ADMIN_ASSET_CONDITION_VIEW_ID", undefined);
            this.$store.commit("ADMIN_ASSET_CONDITION_DATA_TABLE_RELOAD", true);
            this.$store.commit("DIALOG", false);
        },
        createAssetCondition() {
            this.form.submitting = true;

            const payload = {
                code: this.form.code,
                description: this.form.description,
            };

            this.$store
                .dispatch("adminAssetConditionPost", payload)
                .then(result => {
                    this.closeDialog();

                    swal.fire({
                        title: "Create Condition Success",
                        icon: "success",
                    });
                })
                .catch((error) => {
                    let title = "";

                    if (error.response.status === 400) title = "Missing Required Data On Create";
                    else if (error.response.status === 409) title = "Code Already Exists";
                    else title = "Create Condition Failed";

                    this.form.submitting = false;

                    swal.fire({
                        title: title,
                        icon: "error",
                    });
                });
        },
        resetForm() {
            this.form = {
                code: "",
                description: "",

                loading: false,
            };
        },
        updateAssetCondition() {
            this.form.submitting = true;

            const payload = {
                url: this.GET_ADMIN_ASSET_CONDITION_VIEW_ID,
                code: this.form.code,
                description: this.form.description,
            };

            this.$store
                .dispatch("adminAssetConditionPut", payload)
                .then(result => {
                    this.closeDialog();

                    swal.fire({
                        title: "Update Condition Success",
                        icon: "success",
                    });
                })
                .catch((error) => {
                    let title = "";

                    if (error.response.status === 400) title = "Missing Required Data On Update";
                    else if (error.response.status === 409) title = "Code Already Exists";
                    else title = "Update Condition Failed";

                    this.form.submitting = false;

                    swal.fire({
                        title: title,
                        icon: "error",
                    });
                });
        },
    },

    watch: {
        GET_ADMIN_ASSET_CONDITION_VIEW_ID: {
            handler(val) {
                if (!val) return;

                this.form.loading = true;
                this.$store
                    .dispatch("adminAssetConditionGet", { url: val })
                    .then((response) => {
                        this.form = response.data;
                        this.form.loading = false;
                    });
            },
            immediate: true,
        },
    },
};
</script>
