<template>
    <v-dialog v-model="ccDetailsDialog" persistent max-width="65%">
        <v-card class="pa-4">
            <v-row class="m-0">
                <v-col>
                    <v-card-title>
                        <span class="headline">Customer Conversation</span>
                    </v-card-title>
                </v-col>
                <v-col cols="pull-right-10 p-2">
                    <v-btn
                    text
                    icon
                    color="gray"
                    class="float-right"
                    @click="closeCcDialog()"
                    >
                    <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-divider class="mt-0" />

            <v-layout class="mx-0 px-4 pb-4">
                <v-flex lg6>
                    <div>Ticket Number: {{ ticketNumber }}</div>
                    <div>Company Name: {{ companyName }}</div>
                    <div>Customer Name: {{ customerName }}</div>
                    <div>Email: {{ email }}</div>
                    <div>Address: {{ address }}</div>
                    <div>Contact Number: {{ contactNumber }}</div>
                </v-flex>
                <v-flex lg3>
                    <div>
                        <span>Status: </span>
                        <span v-if="status==1" class="badge bg-success">open</span>
                        <span v-else-if="status==2" class="badge bg-danger">escalated</span>
                        <span v-else-if="status==3" class="badge bg-dark">closed</span>
                    </div>
                    <div>
                        <span>Source:</span>
                        <span>{{ source }}</span>
                    </div>
                    <div>Date: {{ createdAt }}</div>
                </v-flex>
                <v-flex lg3>
                    <div>Created By: {{ createdBy }}</div>
                    <div>Processed By: {{ processedBy }}</div>
                    <div>Escalated By: {{ escalatedBy }}</div>
                    <div>Finished By: {{ finishedBy }}</div>
                </v-flex>
            </v-layout>

            <v-divider class="mt-0" />

            <div class="mx-2 pb-4">
                <div>Issue:</div>

                <v-card class="pa-4">
                    <div>{{ issue }}</div>
                </v-card>
            </div>

            <div class="mx-2 pb-4">
                <div>Messages:</div>
                <v-card class="pa-4">
                    <div>
                        <v-data-table
                            :headers="messageTable"
                            :items="messages"
                            :loading="messagesLoading"
                        >
                            <template v-slot:item.type="{ item }">
                                <td>
                                    <span v-if="item.type=='Inbound'" class="badge bg-success">{{ item.type }}</span>
                                    <span v-else-if="item.type=='Outbound'" class="badge bg-danger">{{ item.type }}</span>
                                </td>
                            </template>

                            <template v-slot:item.status="{ item }">
                                <td>
                                    <span v-if="item.status==1" class="badge bg-success">open</span>
                                    <span v-else-if="item.status==2" class="badge bg-danger">escalated</span>
                                    <span v-else-if="item.status==3" class="badge bg-secondary">closed</span>
                                </td>
                            </template>
                        </v-data-table>

                        <v-layout row class="px-4">
                            <v-flex lg6 class="pr-2">
                                <v-textarea v-model="customerMessage" outlined dense label="Customer Message" :disabled="status == 3"></v-textarea>
                            </v-flex>
                            <v-flex lg6>
                                <v-textarea v-model="coordinatorMessage" outlined dense label="Coordinator Message" :disabled="status == 3"></v-textarea>
                            </v-flex>
                        </v-layout>
                    </div>

                    <div style="text-align:center">
                        <v-btn v-if="status != 3" @click="submit()">Submit</v-btn>
                    </div>
                </v-card>
            </div>

            <v-divider class="mt-0" />

            <v-layout row class="mx-0 px-4">
                <v-flex lg6>
                    <div v-if="status == 1" style="text-align:start">
                        <v-btn color="red" @click="escalate()">Escalate</v-btn>
                    </div>
                </v-flex>

                <v-flex lg6>
                    <div v-if="status != 3" style="text-align:end">
                        <v-btn color="primary" @click="finish()">Finish</v-btn>
                    </div>
                </v-flex>
            </v-layout>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
export default {
    data() {
        return {
            conversation: null,
            ticketNumber: '',
            companyName: '',
            customerName: '',
            address: '',
            email: '',
            contactNumber: '',
            status: '',
            source: '',
            issue: '',
            createdAt: '',
            createdBy: '',
            processedBy: '',
            escalatedBy: '',
            finishedBy: '',

            messageTable: [
                { text: 'Type', value: 'type' },
                { text: 'Customer Message', value: 'customer_message' },
                { text: 'Coordinator Message', value: 'coordinator_message' },
                { text: 'Submitted By', value: 'processed_by' },
                { text: 'Date & Time', value: 'created_at' },
            ],
            messages: [],
            customerMessage: '',
            coordinatorMessage: '',
            messagesLoading: true,
        }
    },
    computed: {
        ...mapGetters([
            'GET_CUSTOMER_CONVERSATION_ID',
            'GET_ALL_CUSTOMER_MESSAGES',
        ]),
        ccDetailsDialog() {
            return this.$store.state.service.callCenter.detailsDialog;
        },
        ccId() {
            return this.$store.state.service.callCenter.ccId;
        }
    },
    watch: {
        async ccId() {
            if(!!this.ccId) {
                await this.getCustomerConversation(this.ccId);
                await this.getMessages(this.ccId);
            }
        },
        conversation() {
            if(!!this.conversation) {

                const { ticket_number, company_name, customer_name,
                    status, source, issue, created_at, company_email,
                    company_contact_number, company_id, customer_contact_number,
                    customer_email, created_by, processed_by, escalated_by, finished_by
                } = this.conversation;

                this.ticketNumber = ticket_number;
                this.companyName = company_name;
                this.customerName = customer_name;
                this.contactNumber = company_id == 275 ? customer_contact_number : company_contact_number;
                this.email = company_id == 275 ? customer_email : company_email;
                this.status = status;
                this.source = source;
                this.issue = issue;
                this.createdAt = this.$dayjs(created_at).format('YYYY-MM-DD, hh:mm:ss a');
                this.createdBy = created_by;
                this.processedBy = processed_by;
                this.escalatedBy = escalated_by;
                this.finishedBy = finished_by;
            }
        },
        messages() {
            if(this.messages.length > 0) {
                this.messagesLoading = false;
            } else {
                setTimeout(() => {
                    this.messagesLoading = false;
                }, 3000)
            }
        }
    },
    methods: {
        clearFields() {
            this.conversation = null;

            this.ticketNumber = '';
            this.companyName = '';
            this.customerName = '';
            this.contactNumber = '';
            this.email = '';
            this.status = '';
            this.source = '';
            this.issue = '';
            this.createdAt = '';

            this.messages = [];
            this.coordinatorMessage = '';
            this.customerMessage = '';
        },
        closeCcDialog() {
            this.clearFields();
            this.$store.commit('closeCcDialog');
            this.$store.commit('unsetCcId');
        },
        getCustomerConversation(id){
            this.$store.dispatch('getCustomerConversationId',id).then(response=>{

                this.conversation = this.GET_CUSTOMER_CONVERSATION_ID;
            });
        },
        getMessages(id) {
            this.$store.dispatch('getAllCustomerMessages',id).then(response=>{

                this.messages = this.GET_ALL_CUSTOMER_MESSAGES;
            });
        },
        escalate() {
            swal.fire({
                title: 'Are you sure you want to Escalate?',
                text: 'Click ok to proceed',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then((result)=> {
                if(result.isConfirmed) {
                    let payload = {
                        ccId: this.ccId,
                    }
                    this.$store.dispatch('escalateCustomerConversation',payload).then(response=>{
                        if(!!response.data.error) {
                            swal.fire('', response.data.error, 'error');
                        } else {
                            this.closeCcDialog();
                            this.$store.commit('successAddEditCustConversation');
                            swal.fire("", response.data.msg, "success").then(ok=>{
                                this.$store.commit('unsuccessAddEditCustConversation');
                            });
                        }
                    });
                }
            });
        },
        finish() {
            swal.fire({
                title: 'Are you sure you want to Finish?',
                text: 'Click ok to proceed',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then((result)=> {
                if(result.isConfirmed) {
                    let payload = {
                        ccId: this.ccId,
                    }
                    this.$store.dispatch('finishCustomerConversation',payload).then(response=>{
                        if(!!response.data.error) {
                            swal.fire('', response.data.error, 'error');
                        } else {
                            this.closeCcDialog();
                            this.$store.commit('successAddEditCustConversation');
                            swal.fire("", response.data.msg, "success").then(ok=>{
                                this.$store.commit('unsuccessAddEditCustConversation');
                            });
                        }
                    });
                }
            });
        },
        submit() {
            if(!this.customerMessage && !this.coordinatorMessage || !!this.customerMessage && !!this.coordinatorMessage) {
                swal.fire({
                    title: 'Submit message one at a time',
                    icon: 'warning',
                    dangerMode: true
                })
            } else {
                swal.fire({
                    title: 'Are you sure you want to submit?',
                    text: 'Make sure that all information are correct',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then((result)=> {
                    if(result.isConfirmed) {
                        let payload = {
                            ccId: this.ccId,
                            type: !!this.customerMessage ? 'Inbound' : 'Outbound',
                            customerMessage: this.customerMessage,
                            coordinatorMessage: this.coordinatorMessage,
                        }
                        this.$store.dispatch('submitCustomerMessages',payload).then(response=>{
                            if(!!response.data.error) {
                                swal.fire('', response.data.error, 'error');
                            } else {
                                this.getMessages(this.ccId);
                                this.customerMessage = '';
                                this.coordinatorMessage = '';
                                swal.fire("", response.data.msg, "success");
                            }
                        });
                    }
                });
            }
        }
    }
}
</script>

<style>

</style>
