<template>
    <v-form ref="form" v-model="valid" lazy-validation>
        <v-card v-if="GET_CHART_OF_ACCOUNTS_STATUS.tab_name == 'ACCOUNT'" class="x-scroll">
            <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                <span class="text-h5">{{ actions + ` Account: ${form.name ?? ''}` }}</span>
                <v-row class="m-0">
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                            text
                            icon
                            small
                            color="gray"
                            class="float-right"
                            @click="closeDialog()"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text style="height: 250px;">
                <v-container fluid class="pb-0">
                    <v-row no-gutters>
                        <v-col cols="6">
                            <v-autocomplete
                                v-model="form.parent_id"
                                :items="getParentChartofAccounts"
                                item-text="account_name"
                                item-value="id"
                                label="Parent Account"
                                auto-select-first
                                outlined
                                dense

                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="form.code"
                                label="Account Code"
                                outlined
                                dense
                                :disabled="disabled_view"
                                :rules="errors.required"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6">
                            <v-text-field
                            v-model="form.name"
                            label="Account Name"
                            outlined
                            dense
                            :rules="errors.required"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-autocomplete
                                v-model="form.account_type_id"
                                :items="GET_ACCOUNT_TYPES_SELECTION"
                                item-text="text"
                                item-value="value"
                                auto-select-first
                                outlined
                                required
                                label="Account Type"
                                dense
                                :rules="errors.required"
                                :disabled="disabled_view"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6">
                            <v-autocomplete
                                v-model="form.fundamental_id"
                                :items="GET_FUNDAMENTAL_SELECTION"
                                item-text="text"
                                item-value="value"
                                auto-select-first
                                outlined
                                required
                                label="Fundamental"
                                dense
                                :rules="errors.required"

                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="6">
                            <v-autocomplete
                                v-model="form.account_group_id"
                                :items="GET_ACCOUNT_GROUP_SELECTION"
                                item-text="text"
                                item-value="value"
                                auto-select-first
                                outlined
                                label="Account Group"
                                dense
                                :required="account_group_required"
                                :rules="errors.required"
                                :disabled="disabled_view"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="4">
                            <v-autocomplete
                                v-model="form.currency_id"
                                :items="GET_CURRENCY"
                                item-text="code"
                                item-value="id"
                                label="Currency"
                                outlined
                                dense
                                :rules="errors.required"
                            ></v-autocomplete>
                        </v-col>
                        <!-- <v-col cols="3">
                            <vuetify-money
                                v-model="form.open_balance"
                                outlined
                                required
                                dense
                                label="Open Balance"
                                placeholder="0.00"
                                masked
                                disabled
                                :options="options"
                            ></vuetify-money>
                        </v-col>
                        <v-col cols="3">
                            <v-menu
                                :close-on-content-click="false"
                                :nudge-right="80"
                                :nudge-bottom="42"
                                transition="scale-transition"
                                max-width="290"
                                >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        style=""
                                        v-model="form.as_of"
                                        dense
                                        readonly
                                        append-icon="mdi-calendar"
                                        v-on="on"
                                        label="As of"
                                        outlined
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="form.as_of">
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="3">
                            <v-autocomplete
                                v-model="form.entry_type_id"
                                :items="GET_ENTRY_TYPES_SELECTION"
                                item-text="text"
                                item-value="value"
                                label="Entry Type"
                                outlined
                                dense
                            ></v-autocomplete>
                        </v-col>-->
                        <v-col cols="4">
                            <v-autocomplete
                                v-model="form.contra_coa_id"
                                :items="getChartofAccounts"
                                item-text="account_name"
                                item-value="id"
                                label="Contra COA"
                                outlined
                                dense
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="4">
                            <v-autocomplete
                                v-model="form.cash_flow_activity_id"
                                :items="GET_CASH_FLOW_ACTIVITY_SELECTION"
                                label="Cash Flow Activity"
                                outlined
                                dense
                            />
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <div
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-checkbox
                                        label="WTAX"
                                        class="p-0 ma-0 pt-4"
                                        :true-value="1"
                                        :false-value="0"
                                        v-model="form.withholding_tax"
                                    ></v-checkbox>
                                </div>
                            </template>
                            <span>With Holding Tax</span>
                        </v-tooltip>
                        <v-spacer/>
                        <v-col cols="3">
                            <v-autocomplete
                                v-model="form.coa_debit"
                                :items="itemSelection"
                                item-text="text"
                                item-value="value"
                                label="Debit"
                                outlined
                                dense
                                :rules="errors.required"
                                @change="updateOppositeField('coa_debit', form.coa_debit)"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="3">
                            <v-autocomplete
                                v-model="form.coa_credit"
                                :items="itemSelection"
                                item-text="text"
                                item-value="value"
                                label="Credit"
                                outlined
                                dense
                                :rules="errors.required"
                                @change="updateOppositeField('coa_credit', form.coa_credit)"
                            ></v-autocomplete>
                        </v-col>
                        <v-spacer/>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="4">
                            <v-autocomplete
                                v-model="assign_companies"
                                :items="GET_PV_COMPANIES_SELECTION"
                                label="Companies"
                                outlined
                                dense
                                small-chips
                                multiple
                                deletable-chips
                                auto-select-first
                                clearable
                            />
                        </v-col>
                        <v-col cols="2">
                            <v-btn
                                :disabled="assign_companies.length == 0"
                                @click="assignCompanies()"
                            >
                                ADD
                            </v-btn>
                        </v-col>
                        <v-spacer />
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-data-table
                                :headers="companies.headers"
                                :items="form.companies"
                                :items-per-page="5"
                                :loading="companies.load"
                                disable-sort
                            >
                                <template #[`item.branches`]="{ item }">
                                    <v-autocomplete
                                        v-model="form.branches"
                                        :items="GET_PV_STORE_BRANCHES_SELECTION.filter(e=>e.company_id == item.value)"
                                        multiple
                                        small-chips
                                        deletable-chips
                                        outlined
                                        dense
                                    ></v-autocomplete>
                                </template>
                                <template #[`item.action`]="{ item, index }">
                                    <v-btn text icon color="error" @click="removeCompany(item, index)">
                                        <v-icon small>mdi-delete</v-icon>
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="text-center">
                <v-row class="ma-1">
                    <v-col cols="12" >
                        <v-row >
                            <v-col cols="12" sm="12">
                                <v-btn
                                    v-if="['Create','Update'].includes(actions)"
                                    class="float-center"
                                    @click="createOrUpdate(actions)"
                                    >
                                    <span>{{ actions == 'View'?status_text:actions }}</span>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        <v-card v-if="tabNames.includes(GET_CHART_OF_ACCOUNTS_STATUS.tab_name)" class="x-scroll">
            <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                <span class="text-h5">{{ getAccountTypeText }}</span>
                <v-row class="m-0">
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                            text
                            icon
                            small
                            color="gray"
                            class="float-right"
                            @click="closeDialog()"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text style="height: 195px;">
                <v-container fluid class="pb-0">
                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-text-field
                            v-model="form.code"
                            label="Code"
                            outlined
                            dense
                            :disabled="disabled_view"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-text-field
                            v-model="form.name"
                            label="Name"
                            outlined
                            dense
                            :disabled="disabled_view"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters v-if="GET_CHART_OF_ACCOUNTS_STATUS.tab_name == 'FUNDAMENTALS'">
                        <v-col cols="12">
                            <v-text-field
                            v-model="form.debit"
                            label="Debit"
                            outlined
                            dense
                            :disabled="disabled_view"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters v-if="GET_CHART_OF_ACCOUNTS_STATUS.tab_name == 'FUNDAMENTALS'">
                        <v-col cols="12">
                            <v-text-field
                            v-model="form.credit"
                            label="Credit"
                            outlined
                            dense
                            :disabled="disabled_view"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-if="form.isActive !== undefined" align="center">
                        <v-col cols="12" class="d-flex align-center ml-4">
                            <v-switch
                                v-model="form.isActive"
                                :color="form.isActive ? 'blue' : 'red'"
                                @change="updateIsActive"
                                :disabled="disabled_view"
                            ></v-switch>
                            <span>{{ form.isActive ? 'Active' : 'Inactive' }}</span>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-divider></v-divider>
                <v-card-actions class="text-center">
                <v-row class="ma-1">
                    <v-col cols="12" >
                        <v-row >
                            <v-col cols="12" sm="12">
                                <v-btn
                                    v-if="['Create','Update'].includes(actions)"
                                    class="float-center"
                                    @click="createOrUpdateCOAData(actions)"
                                    >
                                    <span>{{ actions == 'View'?status_text:actions }}</span>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-form>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
export default {
    mixins: [
        SharedFunctionsComponentVue
    ],
    inject: ['theme'],
    data() {
        return {
            overlay:false,
            valid:true,
            form: {
                new: false,
                id: null,
                code:'',
                name:'',
                account_type_id:'',
                account_type: '',
                fundamental_id: '',
                account_group_id: '',
                open_balance: '',
                currency_id: 1,
                contra_coa_id: null,
                entry_type_id: '',
                coa_debit:'',
                coa_credit:'',
                debit:'',
                credit:'',
                as_of: this.$dayjs().format('YYYY-MM-DD-h:mm:ss'),
                parent_id:'',
                isActive: 0,
                withholding_tax: 0,
                cash_flow_activity_id: '',
                // parent_account_id:'',
                companies: [],
                branches:[]
            },
            options: {
                prefix: "₱",
                suffix: "",
                precision: 2
            },
            contra_coa: [],
            parent_coas: [],
            loading:false,
            action:'',
            disabled_view: false,
            disabled_fundamental: false,
            disabled_parent_account: false,
            URL:process.env.VUE_APP_API_HOST,
            noImage:"images/noimage.png",
            child_dialog:false,
            date_dialog: false,
            tabNames: ['TYPES', 'FUNDAMENTALS', 'ACCOUNT GROUP'],
            errors: {
                required: [(v) => !!v || "Field is required"],
            },
            account_group_required:true,
            itemSelection:[
                {text: '+', value: '+'},
                {text: '-', value: '-'}
            ],
            assign_companies: [],
            companies: {
                headers: [
                    { text: 'Code', value: 'code', align: 'start' },
                    { text: 'Company', value: 'text', align: 'start' },
                    { text: 'Branches', value: 'branches', align: 'start' },
                    { text: 'Action', value: 'action', align: 'center' },
                ],
                load: false
            }
        };
    },
    components: {},
    computed: {
        ...mapGetters([
            "ACTION",
            "DIALOGS",
            "USER_ACCESS",
            "GET_ACCOUNT_TYPES_SELECTION",
            "GET_CHART_OF_ACCOUNTS_DISPATCH",
            "GET_CHART_OF_ACCOUNT_PARENT_ID",
            "GET_NEW_CHART_OF_ACCOUNTS_TABLE",
            "GET_EDIT_CHART_OF_ACCOUNTS",
            "GET_CHART_OF_ACCOUNTS_STATUS",
            "GET_EDIT_CHART_OF_ACCOUNTS_TYPES",
            "GET_NEW_CHART_OF_ACCOUNTS_TYPES_TABLE",
            "GET_CHART_OF_ACCOUNTS",
            "GET_FUNDAMENTAL_SELECTION",
            "GET_ACCOUNT_GROUP_SELECTION",
            "GET_EDIT_FUNDAMENTALS",
            "GET_NEW_FUNDAMENTALS_TABLE",
            "GET_EDIT_ACCOUNTING_GROUPS",
            "GET_CURRENCY",
            "GET_CHART_OF_ACCOUNTS_SELECTION",
            "GET_ENTRY_TYPES_SELECTION",
            "GET_CASH_FLOW_ACTIVITY_SELECTION",
            "GET_PV_COMPANIES_SELECTION",
            "GET_PV_STORE_BRANCHES_SELECTION",
        ]),
        actions() {
            if (this.ACTION == "Submit" && this.GET_CHART_OF_ACCOUNTS_STATUS.tab_name == 'ACCOUNT') {
                if (this.form.fundamental_id && this.ACTION == "Submit") {
                    this.disabled_fundamental = true;
                    this.disabled_parent_account = true
                        if (this.form.fundamental_id && this.form.parent_id == '' && this.form.account_group_id == '') {
                            this.disabled_parent_account = true
                            this.disabled_fundamental = true;
                        } else if (this.form.fundamental_id && this.form.account_group_id) {
                            // this.disabled_view = true;
                            this.disabled_parent_account = true
                            this.disabled_fundamental = true;
                        }else if (this.form.fundamental_id && this.form.account_group_id == '') {
                            // this.disabled_view = true;
                            this.disabled_parent_account = true
                            this.disabled_fundamental = true;
                        } else {
                            this.disabled_view = false;
                        }
                } else {
                    this.disabled_view = false;
                }
                this.action = this.GET_CHART_OF_ACCOUNTS_DISPATCH.add
                return "Create";
            }
            else if (this.ACTION == "Update" && this.GET_CHART_OF_ACCOUNTS_STATUS.tab_name == 'ACCOUNT') {
                this.disabled_view = true;
                this.disabled_fundamental = true;
                this.disabled_parent_account = true
                this.action = this.GET_CHART_OF_ACCOUNTS_DISPATCH.update
                this.getEditCOA();
                return 'Update';
            }
            else if (this.ACTION == "View" && this.GET_CHART_OF_ACCOUNTS_STATUS.tab_name == 'ACCOUNT') {
                this.disabled_view = true;
                this.disabled_fundamental = true;
                this.disabled_parent_account = true
                this.getEditCOA();
                return "View";
            }
            else if (this.ACTION == "Submit" && this.GET_CHART_OF_ACCOUNTS_STATUS.tab_name == 'TYPES') {
                this.disabled_view = false;
                this.action = 'CreateChartOfAccountsTypes'
                return "Create";
            }
            else if (this.ACTION == "Update" && this.GET_CHART_OF_ACCOUNTS_STATUS.tab_name == 'TYPES') {
                this.getEditCOAData();
                this.disabled_view = false;
                this.action = 'UpdateChartOfAccountsTypes'
                return 'Update';
            }
            else if (this.ACTION == "View" && this.GET_CHART_OF_ACCOUNTS_STATUS.tab_name == 'TYPES') {
                this.getEditCOAData();
                this.disabled_view = true;
                return "View";
            }
            else if (this.ACTION == "Submit" && this.GET_CHART_OF_ACCOUNTS_STATUS.tab_name == 'FUNDAMENTALS') {
                this.disabled_view = false;
                this.action = 'CreateFundamentals'
                return "Create";
            }
            else if (this.ACTION == "Update" && this.GET_CHART_OF_ACCOUNTS_STATUS.tab_name == 'FUNDAMENTALS') {
                this.getEditCOAData();
                this.disabled_view = false;
                this.action = 'UpdateFundamentals'
                return 'Update';
            }
            else if (this.ACTION == "View" && this.GET_CHART_OF_ACCOUNTS_STATUS.tab_name == 'FUNDAMENTALS') {
                this.getEditCOAData();
                this.disabled_view = true;
                return "View";
            }
            else if (this.ACTION == "Submit" && this.GET_CHART_OF_ACCOUNTS_STATUS.tab_name == 'ACCOUNT GROUP') {
                this.disabled_view = false;
                this.action = 'CreateAccountGroups'
                return "Create";
            }
            else if (this.ACTION == "Update" && this.GET_CHART_OF_ACCOUNTS_STATUS.tab_name == 'ACCOUNT GROUP') {
                this.getEditCOAData();
                this.disabled_view = false;
                this.action = 'UpdateAccountGroups'
                return 'Update';
            }
            else if (this.ACTION == "View" && this.GET_CHART_OF_ACCOUNTS_STATUS.tab_name == 'ACCOUNT GROUP') {
                this.getEditCOAData();
                this.disabled_view = true;
                return "View";
            }
        },
        getAccountTypeText() {
            switch (this.GET_CHART_OF_ACCOUNTS_STATUS.tab_name) {
                case 'TYPES':
                return 'NEW ACCOUNT TYPES';
                case 'FUNDAMENTALS':
                return 'NEW FUNDAMENTALS';
                case 'ACCOUNT GROUP':
                return 'NEW ACCOUNT GROUP';
                default:
                return 'DEFAULT TEXT';
            }
        },
        getChartofAccounts(){
            this.updateContraCoa(this.GET_CHART_OF_ACCOUNTS_SELECTION)
            return this.contra_coa
        },
        getParentChartofAccounts(){
            this.updateParentCoas(this.GET_CHART_OF_ACCOUNTS_SELECTION)
            return this.parent_coas
        },
    },
    mounted() {},
    methods: {
        closeDialog(){
            this.resetfields();
            this.$store.commit('DIALOG',false);
            this.$store.commit("ACTION", '');
            this.$store.commit("EDIT_CHART_OF_ACCOUNTS", {});
            this.$store.commit("EDIT_FUNDAMENTALS", {});
            this.$store.commit("EDIT_ACCOUNTING_GROUPS", {});
        },
        createOrUpdate(actions) {
            if (
                !this.form.code ||
                !this.form.name ||
                !this.form.account_type_id ||
                !this.form.fundamental_id ||
                // !this.form.account_group_id ||
                !this.form.currency_id ||
                // !this.form.entry_type_id ||
                !this.form.coa_debit ||
                !this.form.coa_credit
                ) {
                Swal.fire({
                    title: 'Empty Fields Detected',
                    icon: 'error',
                    text: 'Please fill in all the required fields.',
                });
            } else {
                if (!this.form.parent_id) {
                    this.form.parent_id = this.GET_EDIT_CHART_OF_ACCOUNTS.parent_account_id;
                }
                Swal.fire({
                    title: "",
                    text: `- Are you sure you want to ${actions} Account?`,
                    icon: "question",
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch(this.action, this.form);
                        this.closeDialog();
                    }
                })
            }
        },
        createOrUpdateCOAData(actions) {
            if (!this.form.code || !this.form.name) {
                Swal.fire({
                    title: 'Empty Fields Detected',
                    icon: 'error',
                    text: 'Please fill in all the required fields.',
                });
            } else {
                Swal.fire({
                    title: "",
                    text: `- Are you sure you want to ${actions} Account?`,
                    icon: "question",
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch(this.action, this.form)
                            .then(() => {
                                if (this.GET_CHART_OF_ACCOUNTS_STATUS.tab_name == 'TYPES') {
                                    this.$store.commit("NEW_CHART_OF_ACCOUNTS_TYPES_TABLE", true);
                                    this.closeDialog();
                                }
                                else if (this.GET_CHART_OF_ACCOUNTS_STATUS.tab_name == 'FUNDAMENTALS') {
                                    this.$store.commit("NEW_FUNDAMENTALS_TABLE", true);
                                    this.closeDialog();
                                }
                                else if (this.GET_CHART_OF_ACCOUNTS_STATUS.tab_name == 'ACCOUNT GROUP') {
                                    this.$store.commit("NEW_ACCOUNTING_GROUPS_TABLE", true);
                                    this.closeDialog();
                                }
                            });
                    }
                })
            }
        },
        resetfields(){
            this.disabled_view = false,
            this.disabled_fundamental = false,
            this.disabled_parent_account = false,
            this.$store.commit('CHART_OF_ACCOUNT_PARENT_ID', 0);
            this.$store.commit('EDIT_CHART_OF_ACCOUNTS',{});
            Object.assign(this.$data, this.$options.data.call(this));
        },
        getEditCOA() {
            this.form.id = this.GET_EDIT_CHART_OF_ACCOUNTS.id;
            this.form.code = this.GET_EDIT_CHART_OF_ACCOUNTS.code;
            this.form.name = this.GET_EDIT_CHART_OF_ACCOUNTS.account_name;
            this.form.account_type_id = this.GET_EDIT_CHART_OF_ACCOUNTS.account_type_id;
            this.form.parent_id = this.GET_EDIT_CHART_OF_ACCOUNTS.parent_account_id;
            this.form.account_type = this.GET_EDIT_CHART_OF_ACCOUNTS.account_type;
            this.form.fundamental_id = this.GET_EDIT_CHART_OF_ACCOUNTS.fundamental_id;
            this.form.account_group_id = this.GET_EDIT_CHART_OF_ACCOUNTS.account_group_id;
            this.form.withholding_tax = this.GET_EDIT_CHART_OF_ACCOUNTS.withholding_tax;
            this.form.cash_flow_activity_id = this.GET_EDIT_CHART_OF_ACCOUNTS.cash_flow_activity_id;
            // this.form.open_balance = this.GET_EDIT_CHART_OF_ACCOUNTS.open_balance;
            // this.form.as_of = this.GET_EDIT_CHART_OF_ACCOUNTS.as_of;
            this.form.contra_coa_id = this.GET_EDIT_CHART_OF_ACCOUNTS.contra_coa_id;
            this.form.entry_type_id = this.GET_EDIT_CHART_OF_ACCOUNTS.entry_type_id;
            this.form.coa_debit = this.GET_EDIT_CHART_OF_ACCOUNTS.debit;
            this.form.coa_credit = this.GET_EDIT_CHART_OF_ACCOUNTS.credit;
        },
        async getEditCOAData() {
            const types = this.GET_CHART_OF_ACCOUNTS_STATUS.tab_name == 'TYPES';
            const fundamentals = this.GET_CHART_OF_ACCOUNTS_STATUS.tab_name == 'FUNDAMENTALS';
            const account_group = this.GET_CHART_OF_ACCOUNTS_STATUS.tab_name == 'ACCOUNT GROUP';

            let editCOAData;

            if (types) {
                editCOAData = this.GET_EDIT_CHART_OF_ACCOUNTS_TYPES;
            } else if (fundamentals) {
                editCOAData = this.GET_EDIT_FUNDAMENTALS;
            } else if (account_group) {
                editCOAData = this.GET_EDIT_ACCOUNTING_GROUPS;
            }
                this.form.id = editCOAData.id;
                this.form.code = editCOAData.code;
                this.form.name = editCOAData.name;
                this.form.isActive = editCOAData.isActive;
                this.form.debit = editCOAData.debit;
                this.form.credit = editCOAData.credit;
        },
        updateIsActive() {
            if (this.form.isActive) {
                this.form.isActive = 1;
            }
        },
        incrementCode(code) {
            let parts = code.split('-');
            let lastPart = parseInt(parts[parts.length - 1], 10);
            let newLastPart = lastPart + 1;
            return `${parts.slice(0, -1).join('-')}-${newLastPart}`;
        },
        insertCoaData() {
            if (this.form.parent_account_id && this.form.account_group_id) {
                this.account_group_required = false
            }
            let editChartData = this.GET_EDIT_CHART_OF_ACCOUNTS;
            let defaultDebitCredit = this.GET_FUNDAMENTAL_SELECTION.find(e=>e.value == editChartData.fundamental_id)
            this.form.name = editChartData.account_name;
            this.form.account_type_id = editChartData.account_type_id;
            this.form.account_type = editChartData.account_type;
            this.form.account_group_id = editChartData.account_group_id ? editChartData.account_group_id : '';
            // this.form.open_balance = editChartData.open_balance;
            // this.form.as_of = editChartData.as_of;
            this.form.contra_coa_id = editChartData.contra_coa_id;
            this.form.entry_type_id = editChartData.entry_type_id;
            this.form.coa_debit = defaultDebitCredit.debit ? defaultDebitCredit.debit : '';
            this.form.coa_credit = defaultDebitCredit.credit ? defaultDebitCredit.credit : '';
        },
        updateContraCoa(coaSelection) {
            let currentSelectionId = this.form.id;
            if (currentSelectionId) {
                let filteredSelection = coaSelection.filter(coa => coa.id !== currentSelectionId);
                let filteredParentCOAS = filteredSelection.filter(e => e.parent_account_id !== 0)
                this.contra_coa = filteredParentCOAS;
            } else {
                let filteredParentCOAS = this.GET_CHART_OF_ACCOUNTS_SELECTION.filter(e => e.parent_account_id !== 0)
                this.contra_coa = filteredParentCOAS
            }
        },
        updateParentCoas(parentCOA) {
            let filteredParentCOAS = this.GET_CHART_OF_ACCOUNTS_SELECTION
            this.parent_coas = filteredParentCOAS
        },
        updateOppositeField(targetField, setData) {
            const oppositeField = targetField === 'coa_debit' ? 'coa_credit' : 'coa_debit';
            const oppositeValue = setData === '+' ? '-' : '+';
            this.form[oppositeField] = oppositeValue;
        },
        insertCompanies(){
            if (this.GET_EDIT_CHART_OF_ACCOUNTS.companies != undefined) {
                this.assign_companies = this.GET_EDIT_CHART_OF_ACCOUNTS.companies;
                if (this.assign_companies.length > 0) {
                    this.assignCompanies()
                }
            }
            if (this.GET_EDIT_CHART_OF_ACCOUNTS.branches != undefined) {
                this.form.branches = this.GET_EDIT_CHART_OF_ACCOUNTS.branches;
            }
        },
        assignCompanies(){
            this.assign_companies.forEach(e=>{
                let company = this.GET_PV_COMPANIES_SELECTION.find(val => val.value == e)
                    let exists = this.form.companies.some(i => i.value == company.value)
                if (!exists) this.form.companies.push(company)
            })
            this.assign_companies = []
        },
        removeCompany(item, index){
            this.form.companies.splice(index, 1)
            this.form.branches.splice(index, 1)
        }
    },
    watch: {
        GET_EDIT_CHART_OF_ACCOUNTS: {
            handler(val) {
                if (val && Object.keys(val).length > 0 && this.ACTION == "Submit") {
                    let parentCode = val;
                    if (parentCode.active == 1) {
                        this.form.new = true,
                        this.form.code = '';
                        this.form.parent_id = '';
                        this.form.fundamental_id = !!parentCode.fundamental_id ? parentCode.fundamental_id : parentCode.id;
                    }
                    else if (parentCode.children.length == 0) {
                        let incrementedCode = parentCode.code.concat('-1')
                        this.form.code = incrementedCode;
                        this.form.parent_id = parentCode.id;
                        this.form.fundamental_id = !!parentCode.fundamental_id ? parentCode.fundamental_id : parentCode.id;
                    } else {
                        let lastChildrenCode = parentCode.children.filter(e=>e.parent_account_id == parentCode.id).slice(-1)
                        let incrementedChildrenCode = this.incrementCode(lastChildrenCode[0].code)
                        this.form.code = incrementedChildrenCode;
                        this.form.parent_id = parentCode.id;
                        this.form.fundamental_id = !!parentCode.fundamental_id ? parentCode.fundamental_id : parentCode.id;
                    }
                    this.insertCoaData()
                } else {
                    this.insertCompanies()
                }
            },
            immediate: true
        },
    },
};
</script>
<style scoped>
.x-scroll {
    overflow-x: hidden;
    height: 710px;
}
</style>