import JournalVoucherGroupComponent from "@/views/main/modules/Accounting/JournalVoucher/JournalVoucherGroupComponent.vue"
import JournalVoucherManagerGroupComponent from "@/views/main/modules/Accounting/JournalVoucherManager/JournalVoucherManagerGroupComponent.vue"
import repository from "@/api/credentials";

const routes = [{
    path: "/accounting/journalvoucher/:id",
    name: "acctjournalvoucher",
    component: JournalVoucherGroupComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
                }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Journal Voucher',
    }
},
{
    path: "/accounting/managersvoucher/:id",
    name: "manager-voucher",
    component: JournalVoucherManagerGroupComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
                }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Managers Voucher',
    }
}]

export default routes;
