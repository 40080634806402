<template>
    <div class="tabs-body-color">
        <v-container fluid class="pa-0">
            <v-row>
                <AdminInventoryTableComponent></AdminInventoryTableComponent>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import AdminInventoryTableComponent from "@/views/main/modules/Admin/AdminInventory/AdminInventoryTableComponent.vue";
import { mapGetters } from "vuex";
export default {
    components: {
        AdminInventoryTableComponent,
    },
    data() {
        return {
            userAccess: {
                create: false,
            },
        };
    },
    computed: {
        ...mapGetters(["USER_ACCESS"]),
    },
    mounted() {},
    methods: {
    },
    watch: {
        USER_ACCESS: {
            handler(val) {
                if (val != "fail") {
                    this.userAccess.create = false;
                    val.forEach((e) => {
                        if (e.actions_code == "create") {
                            this.userAccess.create = true;
                        }
                    });
                }
            },
        },
    },
};
</script>
