<template>
    <v-container fluid>

      <ComponentHeader
          :button="true"
          title='Cycle Count Status Percentage'
          type='Inventory'
      ></ComponentHeader>

        <v-container fluid class="bg-bluish-gray">
            <v-row class="pa-2">
                <v-col lg="6">
                    <v-autocomplete
                        dense
                        outlined
                        background-color="white"
                        :items="warehouses_list"
                        :loading="loading.warehouse_selection"
                        :disabled="loading.warehouse_selection"
                        v-model="warehouse_id"
                        item-value="value"
                        item-text="text"
                        placeholder="Select Warehouse"
                        class="small">
                    </v-autocomplete>
                </v-col>
                <v-col class="text-right">
                    <v-btn small class="ml-1" v-on:click="resetFields()">Clear</v-btn>
                    <v-btn small class="ml-1" v-on:click="exportExcel()">Excel</v-btn>
                    <v-btn small class="ml-1" v-on:click="getcycleCountStatusPercentage()">Filter</v-btn>
                </v-col>
            </v-row>

            <div class="row pa-2">
                <v-col lg="3" id="date_from">
                    <v-menu
                        v-model="date_from_dialog"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="date_from"
                                readonly
                                outlined
                                    dense
                                    background-color="white"
                                    label="Date From"
                                append-icon="mdi-calendar-clock"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="date_from" @input="date_from_dialog = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col lg="3" id="date_from">

                    <v-menu
                        v-model="date_to_dialog"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="date_to"
                                readonly
                                outlined
                                    dense
                                    background-color="white"
                                    label="Date From"
                                append-icon="mdi-calendar-clock"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="date_to" @input="date_to_dialog = false"></v-date-picker>
                    </v-menu>
                </v-col>
            </div>

            <div class="w-100">
                <v-card v-show="loaded">
                    <v-container fluid>
                        <v-data-table
                            :headers="datatable.headers"
                            :items="datatable.data"
                            :options.sync="datatable.pagination.options"
                            :items-per-page="datatable.pagination.perPageDefault"
                            :footer-props="{
                                showFirstLastPage: true,
                                itemsPerPageOptions: [2, 5, 10 ,15, 50, 100, 250, 500, 1000]
                            }"
                            :server-items-length="datatable.pagination.total"
                            dense>
                            <template v-slot:[`item.total_system_inventory`]="{ item }">
                                <span v-text="thousandSeprator(item.total_system_inventory ? item.total_system_inventory : 0)"></span>
                            </template>
                            <template v-slot:[`item.total_actual_inventory`]="{ item }">
                                <span v-text="thousandSeprator(item.total_actual_inventory ? item.total_actual_inventory : 0)"></span>
                            </template>
                            <template v-slot:[`item.total_inadequate`]="{ item }">
                                <span v-text="thousandSeprator(item.total_inadequate ? item.total_inadequate :0)"></span>
                            </template>
                            <template v-slot:[`item.total_adequate`]="{ item }">
                                <span v-text="thousandSeprator(item.total_adequate ? item.total_adequate : 0 )"></span>
                            </template>
                            <template v-slot:[`item.total_excess`]="{ item }">
                                <span v-text="thousandSeprator(item.total_excess ? item.total_excess : 0)"></span>
                            </template>
                        </v-data-table>
                    </v-container>
                </v-card>
                <v-app id="load_bar" v-if="!loaded">
            <v-text-field color="info" loading disabled></v-text-field>
        </v-app>
            </div>
        </v-container>

    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import swal from 'sweetalert2';
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
export default {
    name: "CycleCountStatusPercentageComponent",

    components: {
        ComponentHeader
    },

    mounted(){
        this.getWarehouses()
        // this.getcycleCountStatusPercentage();
    },

    data(){
        return{
            loaded: true,
            date_from_dialog: false,
            date_from: this.$dayjs().startOf('day').format('YYYY-MM-DD'),
            date_to_dialog: false,
            date_to: this.$dayjs().endOf('day').format('YYYY-MM-DD'),
            warehouses_list: [],
            warehouse_id: '',

            datatable:{
                headers: [
                    {text: 'NUMBER', value: 'cycle_count_number', sortable: false},
                    {text: 'DATE', value: 'cycle_count_date', sortable: false},
                    {text: 'SYSTEM', value: 'total_system_inventory', sortable: false},
                    {text: 'ACTUAL', value: 'total_actual_inventory', sortable: false},

                    {text: 'MISSING', value: 'total_missing_variance', sortable: false},
                    {text: 'ADEQUATE', value: 'total_adequate', sortable: false},
                    {text: 'EXCESS', value: 'total_excess_variance', sortable: false},

                    {text: 'MISSING %', value: 'missing_percent', sortable: false},
                    {text: 'ADEQUATE %', value: 'adequate_percent', sortable: false},
                    {text: 'EXCESS %', value: 'excess_percent', sortable: false}
                ],
                data: [],
                pagination:{
                    options:{},
                    total : 0,
                    perPageDefault: 1000
                }
            },

            loading:{
                warehouse_selection     : true,

            },
            initialLoad: true
        }
    },
    computed:{
        ...mapGetters([
            'GET_ALL_WAREHOUSES'
        ])
    },
    methods:{
        getcycleCountStatusPercentage(page = 1, ){
            let that = this;

            that.datatable.pagination.options.page = page;

            if (!that.loaded) {
                return false;
            }

            that.loaded = false;
            let payload = {
                page: page,
                itemsPerPage: that.datatable.pagination.options.itemsPerPage
                    || that.datatable.pagination.perPageDefault,
                warehouse_id: that.warehouse_id,
                date_from: that.date_from,
                date_to: that.date_to,
                url: "getCycleCountStatusPercentage"
            }
            this.$store.dispatch('reports',payload).then(response => {
                that.datatable.data = response.data.cycle_counts.data;
                that.datatable.pagination.total = response.data.cycle_counts.total;
                that.loaded = true;
            });
        },

        resetFields(){
            this.warehouse_id = '';
        },

        getWarehouses(){
                this.$store.dispatch('getAllWarehouses')
                this.warehouses_list = this.GET_ALL_WAREHOUSES
        },

        thousandSeprator(amount) {
            if (amount !== '' || amount !== undefined || amount !== 0 || amount !== '0' || amount !== null) {
                return amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            } else {
                return amount;
            }
        },

        async exportExcel(){

            let that = this;

            let filters = {
                warehouse_id: that.warehouse_id,
                date_from: that.date_from,
                date_to: that.date_to
            };

            let queryStringparams = Object.keys(filters).reduce((queryString, key)=>{
                return queryString ? `${queryString}&${key}=${filters[key]}` : `${key}=${filters[key]}`;
            },'');
            let payload = {
                ...queryStringparams,
                url: "exportCycleCountStatusPercentage"
            }
            let data = [];
            const workbook = new this.$exceljs.Workbook();
            await this.$store.dispatch('reports',payload).then(response=>{
                const worksheet = workbook.addWorksheet(`Item Movement ${this.$dayjs().format('YYYY-MM-DD')}`);
                data = response.data
                    worksheet.columns = [
                        {header:'NUMBER',key:'cycle_count_number'},
                        {header:'DATE',key:'cycle_count_date'},
                        {header:'SYSTEM',key:'total_system_inventory'},
                        {header:'ACTUAL',key:'total_actual_inventory'},
                        {header:'MISSING',key:'total_missing_variance'},
                        {header:'ADEQUATE',key:'total_adequate'},
                        {header:'EXCESS',key:'total_excess_variance'},
                        {header:'MISSING %',key:'missing_percent'},
                        {header:'ADEQUATE %',key:'adequate_percent'},
                        {header:'EXCESS %',key:'excess_percent'},
                    ]
                    data.forEach(e => {
                        worksheet.addRow(e);
                    });
                    worksheet.getRow(`0${1}`).font = { bold: true }
                    worksheet.getColumn('J').numFmt = '#,##0'
                    worksheet.getColumn('K').numFmt = '#,##0'
                    worksheet.getColumn('J').alignment = {horizontal: 'right' };
                    worksheet.getColumn('K').alignment = {horizontal: 'right' };
                    }).catch(e => {
                        swal.fire('Error !', '', 'error')
                        console.log(e)
                        this.loaded = true
                    })
                    this.loaded = true

                    workbook.xlsx.writeBuffer().then((data) => {
                        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                        this.$filesaver.saveAs(blob, `${this.date_from} ${this.date_to}-CycleCountStatusPercentage.xlsx`);
                    });
        },
    },

    watch:{
        'datatable.pagination.options': {
            handler() {
                if(!this.initialLoad){
                    this.getcycleCountStatusPercentage(this.datatable.pagination.options.page)
                }else{
                    this.initialLoad = false
                }
            },
            deep: true,
        },
        'GET_ALL_WAREHOUSES':{
            handler(val){

                this.loading.warehouse_selection = false
            }
        },
    }
}
</script>

<style scoped>

</style>
