import repository from "@/api/modules/Services/index";
import { reject } from "lodash";

export default {
    state: {
        rf_status: [],
        all_todays_report: [],
        all_repair_forms: [],
        search_repair_form: [],
        all_rf_est_conversation: [],
        all_rrf: [],
        rr_details: [],
        all_rfs: [],
        rf_details: [],
        service_all_customers: [],
        service_all_locations: [],
        service_get_current_user: [],
        report_repair_forms: [],
        service_get_end_user: [],
        service_get_items: [],
        service_get_all_items: [],
        repair_form_files: [],
        item_with_sp_files: [],
        all_processing_tabs: [],
        all_process_rfs: [],
        technian_rfs: [],
        all_technicians: [],
        same_part_number: [],
        rf_estimation: [],
        search_requested_item: [],
        search_requested_spare_parts: [],
        rf_parts_order_for_return: [],
        check_rf_conversation: [],
        service_warehouses: [],
        all_tech_sp_items: [],
        service_warehouse_item_stocks: [],
        all_sp_by_model: [],
        service_get_warehouses: [],
        approve_orders: [],
        service_sales_order_status: [],
        update_pl_printed: [],
        all_parts_order_tabs: [],
        all_wfp_rfs: [],
        all_ready_rfs: [],
        rf_parts_order: [],
        serve_item_replacement: [],
        all_ready_for_delivery_rfs: [],
        all_def_rfs: [],
        receiving_reports: [],
        sc_customers: [],
        delivery_receipt: [],
        customer_ready_rfs: [],
        delivery_receipts: [],
        for_export_service_delivery: [],
        technician: [],
        service_users: [],
        technicians: [],
        selected_technician_rfs: [],
        technician_profiles: [],
        all_tech_sp_items_post: [],
        foc_spare_parts: [],
        parts_order_item_files: [],
        all_tech_rfs: [],
        service_items_post: [],
        service_get_users: [],
        customer_conversations: [],
        all_conversation_sources: [],
        customer_conversation_id: [],
        all_customer_messages: [],
        show_location: [],
        all_baklas: [],
        all_baklas_items: [],
        baklas_item_spare_parts: [],
        report_spare_parts: [],
        for_china_claiming_items: [],
        all_item_warehouse_stocks: [],
        all_spare_parts_of_item: [],
        all_unassign_items:[],
        spare_parts_selection:[],
        all_item_exclude_sp:[],
        assign_spare_parts:[],
        spare_parts_item:[],
        // For getting spare part details
        spare_part: [],
        item_type_exclude_sp:[],
        export_tech_report:[],
        print_pdf_id: 0,
        add_unit:{
            action: {
                type: '',
                button_text: '',
                title_text: ''
            },
            dialogs: {
                add_unit_dialog: false,
            },
            data:{

            },
            reload_table: false
        },
        upload_warranty:[],
        sp_dialog: false,
        all_spare_parts:[],
        spare_part_parents:[],
        sc_parts_order: [],
        wh_item_stocks:[],
        service_all_location_warehouses: [],
        print_rr_id: 0,
        print_rf_id: 0,
        from_module:'',
    },

    getters: {
        GET_RF_STATUS: (state) => state.rf_status,
        GET_ALL_TODAYS_REPORT: (state) => state.all_todays_report,
        GET_ALL_REPAIR_FORMS: (state) => state.all_repair_forms,
        GET_SEARCH_REPAIR_FORM: (state) => state.search_repair_form,
        GET_ALL_RF_EST_CONVERSATION: (state) => state.all_rf_est_conversation,
        GET_ALL_RRF: (state) => state.all_rrf,
        GET_RR_DETAILS: (state) => state.rr_details,
        GET_ALL_RFS: (state) => state.all_rfs,
        GET_RF_DETAILS: (state) => state.rf_details,
        GET_SERVICE_ALL_CUSTOMERS: (state) => state.service_all_customers,
        GET_SERVICE_ALL_LOCATIONS: (state) => state.service_all_locations,
        GET_SERVICE_GET_CURRENT_USER: (state) => state.service_get_current_user,
        GET_REPORT_REPAIR_FORMS: (state) => state.report_repair_forms,
        GET_SERVICE_END_USER: (state) => state.service_get_end_user,
        GET_SERVICE_ITEMS: (state) => state.service_get_items,
        GET_SERVICE_ALL_ITEMS: (state) => state.service_get_all_items,
        GET_REPAIR_FORM_FILES: (state) => state.repair_form_files,
        GET_ITEM_WITH_SP_FILES: (state) => state.item_with_sp_files,
        GET_ALL_PROCESSING_TABS: (state) => state.all_processing_tabs,
        GET_ALL_PROCESS_RFS: (state) => state.all_process_rfs,
        GET_TECHNIAN_RFS: (state) => state.technian_rfs,
        GET_ALL_TECHNICIANS: (state) => state.all_technicians,
        GET_SAME_PART_NUMBER: (state) => state.same_part_number,
        GET_RF_ESTIMATION: (state) => state.rf_estimation,
        GET_SEARCH_REQUESTED_ITEM: (state) => state.search_requested_item,
        GET_SEARCH_REQUESTED_SPARE_PARTS: (state) => state.search_requested_spare_parts,
        GET_RF_PARTS_ORDER_FOR_RETURN: (state) => state.rf_parts_order_for_return,
        GET_CHECK_RF_CONVERSATION: (state) => state.check_rf_conversation,
        GET_SERVICE_WAREHOUSES: (state) => state.service_warehouses,
        GET_ALL_TECH_SP_ITEMS: (state) => state.all_tech_sp_items,
        GET_SERVICE_WAREHOUSE_ITEM_STOCKS: (state) => state.service_warehouse_item_stocks,
        GET_ALL_SP_BY_MODEL: (state) => state.all_sp_by_model,
        GET_SERVICE_GET_WAREHOUSES: (state) => state.service_get_warehouses,
        GET_APPROVE_ORDERS: (state) => state.approve_orders,
        GET_SERVICE_SALES_ORDER_STATUS: (state) => state.service_sales_order_status,
        GET_UPDATE_PL_PRINTED: (state) => state.update_pl_printed,
        GET_ALL_PARTS_ORDER_TABS: (state) => state.all_parts_order_tabs,
        GET_ALL_WFP_RFS: (state) => state.all_wfp_rfs,
        GET_ALL_READY_RFS: (state) => state.all_ready_rfs,
        GET_RF_PARTS_ORDER: (state) => state.rf_parts_order,
        GET_SERVE_ITEM_REPLACEMENT: (state) => state.serve_item_replacement,
        GET_ALL_READY_FOR_DELIVERY_RFS: (state) => state.all_ready_for_delivery_rfs,
        GET_ALL_DEF_RFS: (state) => state.all_def_rfs,
        GET_RECEIVING_REPORTS: (state) => state.receiving_reports,
        GET_SC_CUSTOMERS: (state) => state.sc_customers,
        GET_DELIVERY_RECEIPT: (state) => state.delivery_receipt,
        GET_CUSTOMER_READY_RFS: (state) => state.customer_ready_rfs,
        GET_DELIVERY_RECEIPTS: (state) => state.delivery_receipts,
        GET_FOR_EXPORT_SERVICE_DELIVERY: (state) => state.for_export_service_delivery,
        GET_TECHNICIAN: (state) => state.technician,
        GET_SERVICE_USERS: (state) => state.service_users,
        GET_TECHNICIANS: (state) => state.technicians,
        GET_SELECTED_TECHNICIAN_RFS: (state) => state.selected_technician_rfs,
        GET_TECHNICIAN_PROFILES: (state) => state.technician_profiles,
        GET_ALL_TECH_SP_ITEMS_POST: (state) => state.all_tech_sp_items_post,
        GET_FOC_SPARE_PARTS: (state) => state.foc_spare_parts,
        GET_PARTS_ORDER_ITEM_FILES: (state) => state.parts_order_item_files,
        GET_ALL_TECH_RFS: (state) => state.all_tech_rfs,
        GET_SERVICE_ITEMS_POST: (state) => state.service_items_post,
        GET_SERVICE_GET_USERS: (state) => state.service_get_users,
        GET_CUSTOMER_CONVERSATIONS: (state) => state.customer_conversations,
        GET_ALL_CONVERSATION_SOURCES: (state) => state.all_conversation_sources,
        GET_CUSTOMER_CONVERSATION_ID: (state) => state.customer_conversation_id,
        GET_ALL_CUSTOMER_MESSAGES: (state) => state.all_customer_messages,
        GET_SHOW_LOCATION: (state) => state.show_location,
        GET_ALL_BAKLAS: (state) => state.all_baklas,
        GET_ALL_BAKLAS_ITEMS: (state) => state.all_baklas_items,
        GET_BAKLAS_ITEM_SPARE_PARTS: (state) => state.baklas_item_spare_parts,
        GET_REPORT_SPARE_PARTS: (state) => state.report_spare_parts,
        GET_FOR_CHINA_CLAIMING_ITEMS: (state) => state.for_china_claiming_items,
        GET_ALL_ITEM_WAREHOUSE_STOCKS: (state) => state.all_item_warehouse_stocks,
        GET_ALL_SPARE_PARTS_OF_ITEM: (state) => state.all_spare_parts_of_item,
        GET_ALL_ITEM_UNASSIGN:(state) => state.all_unassign_items,
        GET_ALL_SPARE_PARTS:(state) => state.spare_parts_selection,
        GET_ALL_ITEM_EXCLUDE_SP:(state) => state.all_item_exclude_sp,
        GET_ALL_ITEM_SPARE_PARTS:(state) => state.spare_parts_item,
        GET_ITEM_TYPES_EXCLUDE_SP:(state) => state.item_type_exclude_sp,
        EXPORT_TECHNICAL_REPORT: state => state.export_tech_report,
        GET_ADD_UNIT: state => state.add_unit,
        GET_UPLOAD_WARRANTY: (state) => state.upload_warranty,
        GET_SP_DIALOG: (state) => state.sp_dialog,
           // For getting spare part details
        GET_SPARE_PART: (state) => state.spare_part,
        GET_ALL_SPARE_PARTSS: (state) => state.all_spare_parts,
        GET_SPARE_PART_PARENTS: (state) => state.spare_part_parents,
        GET_SC_PARTS_ORDER: (state) => state.sc_parts_order,
        GET_WH_ITEM_STOCKS: (state) => state.wh_item_stocks,
        GET_SERVICE_ALL_LOCATION_WAREHOUSES: (state) => state.service_all_location_warehouses,
        GET_FROM_MODULE: (state) => state.from_module,
    },
    actions: {
        getRFStatus({commit}) {
            return new Promise((resolve, reject) => {
                repository.getrfstatus().then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('RF_STATUS', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        seeAllChartRepairItems({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.seeallchartrepairitems(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllTodaysReport({commit}) {
            return new Promise((resolve, reject) => {
                repository.getalltodaysreport().then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_TODAYS_REPORT', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        seeAllRepairItems({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.seeallrepairitems(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllRepairForms({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getallrepairforms(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_REPAIR_FORMS', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        searchRepairForm({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.searchrepairform(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SEARCH_REPAIR_FORM', response.data)
                    }it
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllRfEstConversation({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getallrfestconversation(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_RF_EST_CONVERSATION', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        submitConversation({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.submitconversation(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllRrf({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getallrrf(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_RRF', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getRrDetails({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getrrdetails(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('RR_DETAILS', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        successRrPrint({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.successrrprint(payload.id,payload.params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllRfs({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getallrfs(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_RFS', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        async getRfDetails({commit},payload) {
            return await new Promise((resolve, reject) => {
                repository.getrfdetails(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('RF_DETAILS', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        successRfPrint({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.successrfprint(payload.id,payload.params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updateRepairFormStatus({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.updaterepairformstatus(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updatePrintedQrcode({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.updateprintedqrcode(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getServiceAllCustomers({commit}) {
            return new Promise((resolve, reject) => {
                repository.servicegetallcustomers().then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SERVICE_ALL_CUSTOMERS', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        serviceGetAllLocations({commit}) {
            return new Promise((resolve, reject) => {
                repository.servicegetalllocations().then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SERVICE_ALL_LOCATIONS', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        createRrAndRfs({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.createrrandrfs(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        serviceGetCurrentUser({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.servicegetcurrentuser(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SERVICE_CURRENT_USER', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getReportRepairForms({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getreportrepairforms(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('REPORT_REPAIR_FORMS', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        serviceGetEndUsers({commit}) {
            return new Promise((resolve, reject) => {
                repository.servicegetenduser().then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SERVICE_END_USER', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        serviceGetItems({commit}) {
            return new Promise((resolve, reject) => {
                repository.servicegetitems().then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SERVICE_ITEMS', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        editRepairForm({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.editrepairform(payload.params,payload.config).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        serviceGetAllItems({commit}) {
            return new Promise((resolve, reject) => {
                repository.servicegetallitems().then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SERVICE_ALL_ITEMS', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getRepairFormFiles({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getrepairformfiles(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('REPAIR_FORM_FILES', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getItemWithSpFiles({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getitemwithspfiles(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ITEM_WITH_SP_FILES', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        serviceDeleteFile({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.servicedeletefile(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllProcessingTabs({commit}) {
            return new Promise((resolve, reject) => {
                repository.getallprocessingtabs().then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_PROCESSING_TABS', response.data.map(e=>e = e.toUpperCase()))
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        async getAllProcessRfs({commit},payload) {
            let a = await new Promise((resolve, reject) => {
                repository.getallprocessrfs(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_PROCESS_RFS', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
            return a
        },
        cancelPending({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.cancelpending(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getTechnianRfs({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.gettechnianrfs(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('TECHNIAN_RFS', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllTechnicians({commit}) {
            return new Promise((resolve, reject) => {
                repository.getalltechnicians().then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_TECHNICIANS', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        assignTechnician({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.assigntechnician(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getSamePartNumber({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getsamepartnumber(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SAME_PART_NUMBER', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getRfEstimation({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getrfestimation(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('RF_ESTIMATION', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        searchRequestedItem({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.searchrequesteditem(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SEARCH_REQUESTED_ITEM', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        searchRequestedSpareParts({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.searchrequestedspareparts(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SEARCH_REQUESTED_SPARE_PARTS', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getRfPartsOrderForReturn({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getrfpartsorderforreturn(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('RF_PARTS_ORDER_FOR_RETURN', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        rfEstimateDisapproved({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.rfestimatedisapproved(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        transferPartsReturn({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.transferpartsreturn(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        rfEstimateApproved({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.rfestimateapproved(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        checkRfConversation({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.checkrfconversation(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('CHECK_RF_CONVERSATION', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        serviceGetWarehouses({commit}) {
            return new Promise((resolve, reject) => {
                repository.servicegetwarehouses().then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SERVICE_WAREHOUSES', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllTechSpItems({commit}) {
            return new Promise((resolve, reject) => {
                repository.getalltechspitems().then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_TECH_SP_ITEMS', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updateRfEstimationItems({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.updaterfestimationitems(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        approvedEstimation({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.approvedestimation(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        estConvertToSoSuccess({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.estconverttososuccess(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        confirmCancelledQuotation({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.confirmcancelledquotation(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        transferItemForCm({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.transferitemforcm(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        approvedForCm({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.approvedforcm(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        disapprovedParts({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.disapprovedparts(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        approvedForReplacement({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.approvedforreplacement(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        serviceApprovedFoc({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.serviceapprovedfoc(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        rejectWarrantyType({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.rejectwarrantytype(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        confirmChangeWarranty({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.confirmchangewarranty(payload.params,payload.config).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        successPrintSq({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.successPrintSq(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllSpByModel({commit}) {
            return new Promise((resolve, reject) => {
                repository.getallspbymodel().then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_SP_BY_MODEL', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getServiceWarehouses({commit}) {
            return new Promise((resolve, reject) => {
                repository.getservicewarehouses().then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SERVICE_GET_WAREHOUSES', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getApproveOrders({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getapproveorders(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('APPROVE_ORDERS', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        repairedRfConfirm({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.repairedrfconfirm(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        confirmRfPreparation({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.confirmrfpreparation(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        deliverItem({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.deliveritem(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        confirmSuccessDelivery({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.confirmsuccessdelivery(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        serviceGenerateSo({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.servicegenerateso(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        serviceGeneratePackingList({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.servicegeneratepackinglist(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        markRfReadyForDelivery({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.markrfreadyfordelivery(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        rfReEstimate({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.rfreestimate(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        qaDisapproved({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.qadisapproved(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        serviceGetSalesOrderStatus({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.servicegetsalesorderstatus(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SERVICE_SALES_ORDER_STATUS',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        rfdWarrantyReject({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.rfdwarrantyreject(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        rfdPartsReject({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.rfdpartsreject(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updatePLPrinted({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.updateplprinted(payload.id,payload.params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('UPDATE_PL_PRINTED',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllPartsOrderTabs({commit}) {
            return new Promise((resolve, reject) => {
                repository.getallpartsordertabs().then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_PARTS_ORDER_TABS',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllWfpRfs({commit}, payload) {
            return new Promise((resolve, reject) => {
                repository.getallwfprfs(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_WFP_RFS',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllReadyRfs({commit}, payload) {
            return new Promise((resolve, reject) => {
                repository.getallreadyrfs(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_READY_RFS',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getRfPartsOrder({commit}, payload) {
            return new Promise((resolve, reject) => {
                repository.getrfpartsorder(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('RF_PARTS_ORDER',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        servePartsOrder({commit}, payload) {
            return new Promise((resolve, reject) => {
                repository.servepartsorder(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        confirmItemReturned({commit}, payload) {
            return new Promise((resolve, reject) => {
                repository.confirmitemreturned(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        successReturnedItems({commit}, payload) {
            return new Promise((resolve, reject) => {
                repository.successreturneditems(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        serveItemReplacement({commit}, payload) {
            return new Promise((resolve, reject) => {
                repository.serveitemreplacement(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SERVE_ITEM_REPLACEMENT',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        autoServePartsOrder({commit}, payload) {
            return new Promise((resolve, reject) => {
                repository.autoservepartsorder(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        proceedPartsOrder({commit}, payload) {
            return new Promise((resolve, reject) => {
                repository.proceedpartsorder(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getallReadyForDeliveryRfs({commit}, payload) {
            return new Promise((resolve, reject) => {
                repository.getallreadyfordeliveryrfs(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_READY_FOR_DELIVERY_RFS',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getallDefRfs({commit}) {
            return new Promise((resolve, reject) => {
                repository.getalldefrfs().then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_DEF_RFS',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getReceivingReports({commit}, payload) {
            return new Promise((resolve, reject) => {
                repository.getreceivingreports(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('RECEIVING_REPORTS',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        createDeliveryReceipt({commit}, payload) {
            return new Promise((resolve, reject) => {
                repository.createdeliveryreceipt(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getScCustomers({commit}) {
            return new Promise((resolve, reject) => {
                repository.getsccustomers().then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SC_CUSTOMERS',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getDeliveryReceipt({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getdeliveryreceipt(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('DELIVERY_RECEIPT',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        successDrPrint({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.successdrprint(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        deliverItems({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.deliveritems(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        confirmReceivedDelivery({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.confirmreceiveddelivery(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        deleteDrRf({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.deletedrrf(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getCustomerReadyRfs({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getcustomerreadyrfs(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('CUSTOMER_READY_RFS',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getDeliveryReceipts({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getdeliveryreceipts(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('DELIVERY_RECEIPTS',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        deleteDeliveryReceipt({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.deletedeliveryreceipt(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getForExportServiceDelivery({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getforexportservicedelivery(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('FOR_EXPORT_SERVICE_DELIVERY',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getTechnician({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.gettechnician(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('TECHNICIAN',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getServiceUsers({commit}) {
            return new Promise((resolve, reject) => {
                repository.getserviceusers().then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SERVICE_USERS',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        createTechnician({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.createtechnician(payload.params,payload.config).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updateTechnician({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.updatetechnician(payload.params,payload.config).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getTechnicians({commit}) {
            return new Promise((resolve, reject) => {
                repository.gettechnicians().then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('TECHNICIANS',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        deleteTechnician({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.deletetechnician(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getSelectedTechnicianRfs({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getselectedtechnicianrfs(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SELECTED_TECHNICIAN_RFS',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        reAssigntechnician({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.reassigntechnician(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getTechnicianProfiles({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.gettechnicianprofiles(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('TECHNICIAN_PROFILES',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllTechSpItemsPost({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getalltechspitemspost(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_TECH_SP_ITEMS_POST',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        createRfItemEstimation({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.createrfitemestimation(payload.params,payload.config).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        logTechReceivedItems({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.logtechreceiveditems(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        confirmItemReceived({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.confirmitemreceived(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getFocSpareParts({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getfocspareparts(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('FOC_SPARE_PARTS',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        repairDone({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.repairdone(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        startRepair({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.startrepair(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        returnSpareParts({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.returnspareparts(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        continueRepair({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.continuerepair(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        returnSelectedSp({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.returnselectedsp(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getPartsOrderItemFiles({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getpartsorderitemfiles(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('PARTS_ORDER_ITEM_FILES',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        uploadSparePartImage({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.uploadsparepartimage(payload.params,payload.config).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        deleteSparePartImage({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.deletesparepartimage(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllTechRfs({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getalltechrfs(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_TECH_RFS',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getServiceItems({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getserviceitems(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SERVICE_ITEMS_POST',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        uploadExplodedViews({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.uploadexplodedviews(payload.params,payload.config).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        serviceGetUsers({commit}) {
            return new Promise((resolve, reject) => {
                repository.servicegetusers().then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SERVICE_GET_USERS',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updateUserTech({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.updateusertech(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getCustomerConversations({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getcustomerconversations(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('CUSTOMER_CONVERSATIONS',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllConversationSources({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getallconversationsources(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_CONVERSATION_SOURCES',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        createCustomerConversation({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.createcustomerconversation(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getCustomerConversationId({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getcustomerconversationid(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('CUSTOMER_CONVERSATION_ID',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllCustomerMessages({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getallcustomermessages(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_CUSTOMER_MESSAGES',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        escalateCustomerConversation({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.escalatecustomerconversation(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        finishCustomerConversation({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.finishcustomerconversation(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        submitCustomerMessages({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.submitcustomermessages(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        deleteLocation({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.deletelocation(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getLocation({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getlocation(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SHOW_LOCATION',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        createLocation({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.createlocation(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updateLocation({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.updatelocation(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllBaklas({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getallbaklas(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_BAKLAS',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllBaklasItems({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getallbaklasitems(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_BAKLAS_ITEMS',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getBaklasItemSpareParts({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getbaklasitemspareparts(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('BAKLAS_ITEM_SPARE_PARTS',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getReportSpareParts({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getreportspareparts(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('REPORT_SPARE_PARTS',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getForChinaClaimingItems({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getforchinaclaimingitems(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('FOR_CHINA_CLAIMING_ITEMS',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        deleteBaklas({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.deletebaklas(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        editBaklas({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.editbaklas(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllItemWarehouseStocks({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getallitemwarehousestocks(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_ITEM_WAREHOUSE_STOCKS',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        setUsedParts({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.setusedparts(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        transferBaklasSpareParts({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.transferbaklasspareparts(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        createBaklasItems({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.createbaklasitems(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllSparePartsOfItem({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getallsparepartsofitem(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_SPARE_PARTS_OF_ITEMS',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        async serviceGet({commit},payload) {
            return await new Promise((resolve,reject)=>{
                repository.serviceGet(payload).then(response=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        async servicePost({commit},payload) {
            return await new Promise((resolve,reject)=>{
                repository.servicePost(payload).then(response=>{
                    commit('REFRESH', true)
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        async getServiceItemUnassign({commit},payload) {
            return await new Promise((resolve,reject)=>{
                repository.getServicesItemsNullParent(payload).then(response=>{
                    resolve(response)
                        commit('ALL_ITEMS_UNASSIGN',response.data.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        async getAllItemSpareParts({commit},payload) {
            return await new Promise((resolve,reject)=>{
                repository.getAllItemSpareParts(payload).then(response=>{
                    resolve(response)
                        commit('SPARE_PARTS_SELECTIONS',response.data.data.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        async getAllItemsExcludeSp({commit},payload) {
            return await new Promise((resolve,reject)=>{
                repository.getAllItemsExcludeSp(payload).then(response=>{
                    resolve(response)
                        commit('GET_ALL_ITEM_EXCLUDE_SP',response.data.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        async sparePartsAssign({commit},payload) {
            return await new Promise((resolve,reject)=>{
                repository.sparePartsAssign(payload).then(response=>{
                    resolve(response)
                        commit('UPDATE_SPARE_PARTS',response.data.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        async unassignSpareParts({commit},payload) {
            return await new Promise((resolve,reject)=>{
                repository.unassignspareparts(payload).then(response=>{
                    resolve(response)
                        commit('UPDATE_SPARE_PARTS',response.data.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        async getItemSpareParts({commit},payload) {
            return await new Promise((resolve,reject)=>{
                repository.getItemSpareParts(payload).then(response=>{
                    resolve(response)
                        commit('GET_ITEM_SPARE_PARTS',response.data.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        async getItemTypeExcludeSp({commit},payload) {
            return await new Promise((resolve,reject)=>{
                repository.getItemTypeExcludeSp(payload).then(response=>{
                    resolve(response)
                        commit('ITEM_TYPES_EXCLUDE_SP',response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        printPdfTechnicalReport({ commit }, payload) {
            return new Promise((resolve, reject) => {
              repository.printPdfTechnicalReport(payload)
                .then(response => {
                  if (response.status == 200) {
                    resolve(response);
                    commit('EXPORT_TECHNICAL_REPORT', response.data.data)
                  }
                })
                .catch(error => {
                  reject(error);
                })
            });
        },
        getBaklasWarehouse({ commit }, payload) {
            return new Promise((resolve, reject) => {
              repository.getBaklasWarehouse(payload)
                .then(response => {
                  if (response.status == 200) {
                    resolve(response);
                  }
                })
                .catch(error => {
                  reject(error);
                })
            });
        },
        getWarehouseItemStocksBaklas({ commit }, payload) {
            return new Promise((resolve, reject) => {
              repository.getWarehouseItemStocksBaklas(payload)
                .then(response => {
                  if (response.status == 200) {
                    resolve(response);
                  }
                })
                .catch(error => {
                  reject(error);
                })
            });
        },
        getItemWithSpareParts({ commit }, payload) {
            return new Promise((resolve, reject) => {
              repository.getItemWithSpareParts(payload)
                .then(response => {
                  if (response.status == 200) {
                    resolve(response);
                  }
                })
                .catch(error => {
                  reject(error);
                })
            });
        },
        getAllSparePartsOfItem({ commit }, payload) {
            return new Promise((resolve, reject) => {
              repository.getAllSparePartsOfItem(payload)
                .then(response => {
                  if (response.status == 200) {
                    resolve(response);
                  }
                })
                .catch(error => {
                  reject(error);
                })
            });
        },
        createBaklas({ commit }, payload) {
            return new Promise((resolve, reject) => {
              repository.createBaklas(payload)
                .then(response => {
                  if (response.status == 200) {
                    resolve(response);
                  }
                })
                .catch(error => {
                  reject(error);
                })
            });
        },
        BaklasAddNewUnit({ commit }, payload) {
            return new Promise((resolve, reject) => {
              repository.BaklasAddNewUnit(payload)
                .then(response => {
                  if (response.status == 200) {
                    resolve(response);
                  }
                })
                .catch(error => {
                  reject(error);
                })
            });
        },
        getAllBaklasAddNewUnit({ commit }, payload) {
            return new Promise((resolve, reject) => {
              repository.getAllBaklasAddNewUnit(payload)
                .then(response => {
                  if (response.status == 200) {
                    resolve(response);
                    commit('BAKLAS_ADD_NEW_UNIT', response.data.data)
                  }
                })
                .catch(error => {
                  reject(error);
                })
            });
        },
        viewAllItemsBaklasNewUnit({ commit }, payload) {
            return new Promise((resolve, reject) => {
              repository.viewAllItemsBaklasNewUnit(payload)
                .then(response => {
                  if (response.status == 200) {
                    resolve(response);
                    commit('BAKLAS_ADD_NEW_UNIT', response.data.data)
                  }
                })
                .catch(error => {
                  reject(error);
                })
            });
        },
        approvedBaklasNewUnit({ commit }, payload) {
            return new Promise((resolve, reject) => {
              repository.approvedBaklasNewUnit(payload)
                .then(response => {
                  if (response.status == 200) {
                    resolve(response);
                  }
                })
                .catch(error => {
                  reject(error);
                })
            });
        },
        deleteBaklasNewUnit({ commit }, payload) {
            return new Promise((resolve, reject) => {
              repository.deleteBaklasNewUnit(payload)
                .then(response => {
                  if (response.status == 200) {
                    resolve(response);
                  }
                })
                .catch(error => {
                  reject(error);
                })
            });
        },
        updateBaklasNewUnit({ commit }, payload) {
            return new Promise((resolve, reject) => {
              repository.updateBaklasNewUnit(payload)
                .then(response => {
                  if (response.status == 200) {
                    resolve(response);
                  }
                })
                .catch(error => {
                  reject(error);
                })
            });
        },
        validateModel({ commit }, payload) {
            return new Promise((resolve, reject) => {
              repository.validateModel(payload)
                .then(response => {
                  if (response.status == 200) {
                    resolve(response);
                  }
                })
                .catch(error => {
                  reject(error);
                })
            });
        },
        getQaCheckListDelete({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getQaCheckListDelete(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                });
            })
        },
        uploadWarranty({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.uploadWarranty(payload.params,payload.config).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('UPLOAD_WARRANTY', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updateQaCheckList({ commit }, payload){
            return new Promise((resolve, reject) => {
                repository.updateQaCheckList(payload)
                  .then(response => {
                    if (response.status == 200) {
                      resolve(response);
                    }
                  })
                  .catch(error => {
                    reject(error);
                  })
              });
        },
        approvedWA({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.approvedwa(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        // New Added Actions
        // Action to check and display the image
        checkImagePath(item) {
            return item.image_path == null
            ? process.env.VUE_APP_API_HOST + "/images/items/no-image.png"
            : process.env.VUE_APP_API_HOST + "/" + item.image_path;
        },
        getSparePartParents({commit}, payload) {
            return new Promise((resolve, reject) => {
                repository.getSparePartParents(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SPARE_PART_PARENTS', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        async getAllSpareParts({commit},payload) {
            return await new Promise((resolve,reject)=>{
                repository.getAllSparePartItems(payload).then(response=>{
                    resolve(response)
                        commit('ALL_SPARE_PARTS',response.data.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        deleteSparePart({}, payload){
            return new Promise((resolve, reject) => {
                repository.deleteSparePart(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        cancelChangeWarranty({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.cancelChangeWarranty(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        successPrint({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.successPrint(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        // getCheckList({commit},payload){
        //     return new Promise((resolve, reject) => {
        //         repository.getchecklist(payload).then((response) => {
        //             if (response.status == 200) {
        //                 resolve(response)
        //                 commit('ALL_RRF', response.data)
        //             }
        //         }).catch((error) => {
        //             reject(error)
        //         });
        //     })
        // },

        getScPartsOrder({commit}, payload)
        {
            return new Promise((resolve, reject) =>{
                repository.getScPartsOrder(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SC_PARTS_ORDERS', response.data[0])
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        replaceSelectedSp({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.replaceSelectedSp(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        submitReasons({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.submitReasons(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },

        readyForQaTester({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.readyForQaTester(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },

        // currentUser({ commit }, payload) {
        //     return new Promise((resolve, reject) => {
        //       repository.currentUser(payload)
        //         .then(response => {
        //           if (response.status == 200) {
        //             localStorage.setItem('userData', JSON.stringify(response.data));
        //             resolve(response);
        //           }
        //         })
        //         .catch(error => {
        //           reject(error);
        //         })
        //     });
        // },
        submitReasons({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.submitReasons(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        fetchWarehouseItemStocks({commit}, payload) {
            return new Promise((resolve, reject) => {
                repository.serviceGet(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response);
                        commit('WH_ITEM_STOCKS', response.data);
                    }
                }).catch((error) => {
                    reject(error)
                })
            })
        },
        submitSpLocation({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.submitSpLocation(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        dataPrintTechnical({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.dataPrintTechnical(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        submitBatchLocation({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.submitBatchLocation(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        serviceGetAllLocationWarehouses({commit}) {
            return new Promise((resolve, reject) => {
                repository.serviceGetAllLocationWarehouses().then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SERVICE_ALL_LOCATION_WAREHOUSES', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updateRepairStatus({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.updateRepairStatus(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        submitFollowUpRemarks({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.submitFollowUpRemarks(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        servicePut({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.servicePut(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        serviceDelete({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.serviceDelete(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        uploadScReturnReceipFile({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.uploadscreturnreceiptfile(payload.params,payload.config).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
    },
    mutations: {
        RF_STATUS: (state, data) => { (state.rf_status = data) },
        ALL_TODAYS_REPORT: (state, data) => { (state.all_todays_report = data) },
        ALL_REPAIR_FORMS: (state, data) => { (state.all_repair_forms = data) },
        SEARCH_REPAIR_FORM: (state, data) => { (state.search_repair_form = data) },
        ALL_RF_EST_CONVERSATION: (state, data) => { (state.all_rf_est_conversation = data) },
        ALL_RRF: (state, data) => { (state.all_rrf = data) },
        RR_DETAILS: (state, data) => { (state.rr_details = data) },
        ALL_RFS: (state, data) => { (state.all_rfs = data) },
        RF_DETAILS: (state, data) => { (state.rf_details = data) },
        RF_DETAILS: (state, data) => { (state.rf_details = data) },
        SERVICE_ALL_CUSTOMERS: (state, data) => { (state.service_all_customers = data) },
        SERVICE_ALL_LOCATIONS: (state, data) => { (state.service_all_locations = data) },
        SERVICE_CURRENT_USER: (state, data) => { (state.service_get_current_user = data) },
        REPORT_REPAIR_FORMS: (state, data) => { (state.report_repair_forms = data) },
        SERVICE_END_USER: (state, data) => { (state.service_get_end_user = data) },
        SERVICE_ITEMS: (state, data) => { (state.service_get_items = data) },
        SERVICE_ALL_ITEMS: (state, data) => { (state.service_get_all_items = data) },
        REPAIR_FORM_FILES: (state, data) => { (state.repair_form_files = data) },
        ITEM_WITH_SP_FILES: (state, data) => { (state.item_with_sp_files = data) },
        ALL_PROCESSING_TABS: (state, data) => { (state.all_processing_tabs = data) },
        ALL_PROCESS_RFS: (state, data) => { (state.all_process_rfs = data) },
        TECHNIAN_RFS: (state, data) => { (state.technian_rfs = data) },
        ALL_TECHNICIANS: (state, data) => { (state.all_technicians = data) },
        SAME_PART_NUMBER: (state, data) => { (state.same_part_number = data) },
        RF_ESTIMATION: (state, data) => { (state.rf_estimation = data) },
        SEARCH_REQUESTED_ITEM: (state, data) => { (state.search_requested_item = data) },
        SEARCH_REQUESTED_SPARE_PARTS: (state, data) => { (state.search_requested_spare_parts = data) },
        RF_PARTS_ORDER_FOR_RETURN: (state, data) => { (state.rf_parts_order_for_return = data) },
        CHECK_RF_CONVERSATION: (state, data) => { (state.check_rf_conversation = data) },
        SERVICE_WAREHOUSES: (state, data) => { (state.service_warehouses = data) },
        ALL_TECH_SP_ITEMS: (state, data) => { (state.all_tech_sp_items = data) },
        SERVICE_WAREHOUSE_ITEM_STOCKS: (state, data) => { (state.service_warehouse_item_stocks = data) },
        ALL_SP_BY_MODEL: (state, data) => { (state.all_sp_by_model = data) },
        SERVICE_GET_WAREHOUSES: (state, data) => { (state.service_get_warehouses = data) },
        APPROVE_ORDERS: (state, data) => { (state.approve_orders = data) },
        SERVICE_SALES_ORDER_STATUS: (state, data) => { (state.service_sales_order_status = data) },
        UPDATE_PL_PRINTED: (state, data) => { (state.update_pl_printed = data) },
        ALL_PARTS_ORDER_TABS: (state, data) => { (state.all_parts_order_tabs = data) },
        ALL_WFP_RFS: (state, data) => { (state.all_wfp_rfs = data) },
        ALL_READY_RFS: (state, data) => { (state.all_ready_rfs = data) },
        RF_PARTS_ORDER: (state, data) => { (state.rf_parts_order = data) },
        SERVE_ITEM_REPLACEMENT: (state, data) => { (state.serve_item_replacement = data) },
        ALL_READY_FOR_DELIVERY_RFS: (state, data) => { (state.all_ready_for_delivery_rfs = data) },
        ALL_DEF_RFS: (state, data) => { (state.all_def_rfs = data) },
        RECEIVING_REPORTS: (state, data) => { (state.receiving_reports = data) },
        SC_CUSTOMERS: (state, data) => { (state.sc_customers = data) },
        DELIVERY_RECEIPT: (state, data) => { (state.delivery_receipt = data) },
        CUSTOMER_READY_RFS: (state, data) => { (state.customer_ready_rfs = data) },
        DELIVERY_RECEIPTS: (state, data) => { (state.delivery_receipts = data) },
        FOR_EXPORT_SERVICE_DELIVERY: (state, data) => { (state.for_export_service_delivery = data) },
        TECHNICIAN: (state, data) => { (state.technician = data) },
        SERVICE_USERS: (state, data) => { (state.service_users = data) },
        TECHNICIANS: (state, data) => { (state.technicians = data) },
        SELECTED_TECHNICIAN_RFS: (state, data) => { (state.selected_technician_rfs = data) },
        TECHNICIAN_PROFILES: (state, data) => { (state.technician_profiles = data) },
        ALL_TECH_SP_ITEMS_POST: (state, data) => { (state.all_tech_sp_items_post = data) },
        FOC_SPARE_PARTS: (state, data) => { (state.foc_spare_parts = data) },
        PARTS_ORDER_ITEM_FILES: (state, data) => { (state.parts_order_item_files = data) },
        ALL_TECH_RFS: (state, data) => { (state.all_tech_rfs = data) },
        SERVICE_ITEMS_POST: (state, data) => { (state.service_items_post = data) },
        SERVICE_GET_USERS: (state, data) => { (state.service_get_users = data) },
        CUSTOMER_CONVERSATIONS: (state, data) => { (state.customer_conversations = data) },
        ALL_CONVERSATION_SOURCES: (state, data) => { (state.all_conversation_sources = data) },
        CUSTOMER_CONVERSATION_ID: (state, data) => { (state.customer_conversation_id = data) },
        ALL_CUSTOMER_MESSAGES: (state, data) => { (state.all_customer_messages = data) },
        SHOW_LOCATION: (state, data) => { (state.show_location = data) },
        ALL_BAKLAS: (state, data) => { (state.all_baklas = data) },
        ALL_BAKLAS_ITEMS: (state, data) => { (state.all_baklas_items = data) },
        BAKLAS_ITEM_SPARE_PARTS: (state, data) => { (state.baklas_item_spare_parts = data) },
        REPORT_SPARE_PARTS: (state, data) => { (state.report_spare_parts = data) },
        FOR_CHINA_CLAIMING_ITEMS: (state, data) => { (state.for_china_claiming_items = data) },
        ALL_ITEM_WAREHOUSE_STOCKS: (state, data) => { (state.all_item_warehouse_stocks = data) },
        ALL_SPARE_PARTS_OF_ITEMS: (state, data) => { (state.all_spare_parts_of_items = data) },
        ALL_ITEMS_UNASSIGN:( state,data) => {(state.all_unassign_items = data)},
        SPARE_PARTS_SELECTIONS: (state, data) =>{(state.spare_parts_selection = data)},
        GET_ALL_ITEM_EXCLUDE_SP: (state, data) =>{(state.all_item_exclude_sp = data)},
        UPDATE_SPARE_PARTS:(state, data) => {(state.assign_spare_parts = data)},
        GET_ITEM_SPARE_PARTS: (state, data) => {(state.spare_parts_item = data)},
        ITEM_TYPES_EXCLUDE_SP: (state, data) => {(state.item_type_exclude_sp = data)},
        EXPORT_TECHNICAL_REPORT: (state , data) => { (state.export_tech_report = data) },
        GET_PRINT_PDF_ID: (state, data) => { state.print_pdf_id = data},
        ADD_UNIT_DIALOG: (state, data) => { state.add_unit = data},
        BAKLAS_ADD_NEW_UNIT: (state, data) => { state.add_unit.data = data},
        UPLOAD_WARRANTY: (state, data) => { (state.upload_warranty = data) },
        SP_DIALOG: (state, data) => { (state.sp_dialog = data) },
        ALL_SPARE_PARTS: (state, data) => { (state.all_spare_parts = data)},
        SPARE_PART: (state, data) => { (state.spare_part = data) },
        SPARE_PART_PARENTS: (state, data) => { (state.spare_part_parents = data)},
        SC_PARTS_ORDERS: (state, data) => { (state.sc_parts_order = data)},
        WH_ITEM_STOCKS: (state, data) => { (state.wh_item_stocks = data)},
        SERVICE_ALL_LOCATION_WAREHOUSES: (state, data) => { (state.service_all_location_warehouses = data)},
        PRINT_RR_ID:(state, data) => { (state.print_rr_id = data)},
        PRINT_RF_ID:(state, data) => { (state.print_rf_id = data)},
        FROM_MODULE:(state, data) => { (state.from_module = data)},
    }

}
