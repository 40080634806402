<template>
  <div class="w-100">
    <v-app id="my_vue_app">
      <v-card>
        <v-card-title>
          <v-container fluid>
            <v-row>
              <v-col cols="12" lg="4">
                <v-autocomplete
                v-model="department_id"
                :items="GET_LIST_DEPARTMENT"
                label="Select Department"
                item-value="value"
                item-text="text"
                dense
                outlined
                hide-details
                auto-select-first
                :disabled="USERACCOUNT_LOAD.super_admin == 0 || USERACCOUNT_LOAD.president == 0"
              ></v-autocomplete>
             
              </v-col>
              <v-col cols="12" lg="4">
                <v-btn class="ml-2" @click="options.page = 1; getAllData()">FILTER</v-btn>
              </v-col>
              <v-spacer/>
              <v-col cols="12" sm="12" lg="4" class="d-flex justify-content-start">
                <v-text-field
                  v-model="search"
                  label="Search by Name"
                  append-icon="mdi-magnify"
                  @keydown.enter="options.page = 1; getAllData()"
                  @click:append="options.page = 1; getAllData()"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-container fluid>
          <v-data-table
            :headers="GET_COMPONENT_SETTINGS.children.data_table.headers"
            :items="GET_COMPONENT_SETTINGS.children.data_table.items"
            :search="filter.search"
            :options.sync="
              GET_COMPONENT_SETTINGS.children.data_table.options
            "
            :server-items-length="
              GET_COMPONENT_SETTINGS.children.data_table.totalItems
            "
            :items-per-page="10"
            :footer-props="{
              itemsPerPageOptions:
                GET_COMPONENT_SETTINGS.children.data_table
                  .itemsPerPageOptions,
            }"
            :loading="!loaded"
            fixed-header
          >
          <template v-slot:[`item.company_id`]="{ item }">
            <span v-for="(row,i) in item.company_id" :key="i" class="pa-1">
              <v-badge

                bottom
                bordered
                color="teal darken-1"
                offset-x="10"
                offset-y="10"
                :content="'+'+(item.company_id.length - 2).toString()"
                v-if="item.company_id.length > 3 && i == 1"
              >
              <v-chip x-small color="success">{{ row['registered_name'] }}</v-chip>
              </v-badge>
              <v-chip v-else-if="i == 0 || item.company_id.length == 2" x-small color="success">{{ row['registered_name'] }}</v-chip>
            </span>    
          </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn text icon color="orange" v-if="userAccess.view">
                <v-icon
                  class="btn-action"
                  small
                  @click="ViewEditData(item, 'View')"
                  >mdi-eye</v-icon
                >
              </v-btn>
              <v-btn text icon color="blue">
                <v-icon
                  class="btn-action"
                  small
                  @click="ViewEditData(item, 'Update')"
                  >mdi-square-edit-outline</v-icon
                >
              </v-btn>
              <v-btn text icon color="red" v-if="userAccess.delete">
                <v-icon class="btn-action" small @click="DeleteData(item.id)"
                  >mdi-delete</v-icon
                >
              </v-btn>
            </template>
          </v-data-table>
        </v-container>
      </v-card>
    </v-app>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      loaded: false,
      totalItems: 0,
      options: {},
      items: [],
      search: "",
      filter: {
        search: "",
      },
      awaitingSearch: false,
      headers: [],
      count: 0,
      userAccess: {
        view: false,
        create: false,
        edit: false,
        delete: false,
      },
      department_id:""
    };
  },

  computed: {
    ...mapGetters(["USER_ACCESS", "GET_COMPONENT_SETTINGS","GET_NEW_DATA_FOR_DATA_TABLE","GET_LIST_DEPARTMENT","USERACCOUNT_LOAD"]),
    _cs(){
      return this.GET_COMPONENT_SETTINGS;
    }
  },
  mounted() {
    this.$store.dispatch(
      "checkAccessUser",
      this.$router.currentRoute.params.id
    );
    this.getAllData();
  },

  methods: {
    getAllData() {
        this._cs.children.data_table.items = []
        this.loaded = false;
        let payload =  this._cs.children.data_table.filters(this._cs.children.data_table.options,'');
          Object.assign(payload,this._cs.children.dispatch.get)
          payload.search = this.search
          payload.department_id = this.department_id
          this.$store.dispatch(this._cs.children.dispatch.get._action,payload).then((response) => {
          this._cs.children.data_table.items = response.data.data.data;
          this.loaded = true;
          this.$store.commit('NEW_DATA_FOR_DATA_TABLE',false)
          this._cs.children.data_table.totalItems =
            response.data.total;
        })
        .catch((e) => {
          this.loaded = true;
        });
    },
    ViewEditData(item, action) {
  
      this.$store.dispatch('getEditFromDataTable',item.id)
      this.$store.commit("DIALOG", true);
      this.$store.commit("ACTION", action);
    },
    DeleteData(id){
      this.$store.dispatch('SwalAlertQuestion',{
        title:'Are you sure you want to delete?',
        icon: 'question',
      }).then((res)=>{
        if(res.isConfirmed){
          let payload = {
            id:id,
          }
          Object.assign(payload,this._cs.children.dispatch.delete)
          this.$store.dispatch(this._cs.children.dispatch.delete._action,payload)
        }
      })
    
    }
  },
  components: {},
  watch: {
    "_cs.children.data_table.options": {
      handler() {
        this.getAllData();
      },
      deep: true,
    },
    GET_NEW_DATA_FOR_DATA_TABLE:{
      handler(val){
        if(val){
            this.getAllData();
        }
      }
    },
    USER_ACCESS: {
      handler(val) {
        if (val != "fail") {
          this.userAccess.edit = false;
          this.userAccess.delete = false;
          this.userAccess.view = false;
          this.userAccess.create = false;
          val.forEach((e) => {
            if (e.actions_code == "edit") {
              this.userAccess.edit = true;
            } else if (e.actions_code == "delete") {
              this.userAccess.delete = true;
            } else if (e.actions_code == "view") {
              this.userAccess.view = true;
            } else if (e.actions_code == "create") {
              this.userAccess.create = true;
            }
          });
        }
      },
    },
  },
};
</script>