<template>
    <div>
        <v-dialog v-model="DIALOGS">
            <v-card>
                <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                    <span class="text-h5" v-if="ACTION == 'Submit'">Add Item Stocks</span>
                    <span class="text-h5" v-else>Item Stocks</span>
                    <v-row class="m-0">
                        <v-col cols="pull-right-10 p-2">
                            <v-btn
                                text
                                icon
                                small
                                color="gray"
                                class="float-right"
                                @click="closeDialog()">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text style="height: 700px" class="overflow-auto">
                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-row>
                                <v-col cols="12" sm="12" md="12" lg="12">
                                    <v-card class="mt-4">
                                        <v-row>
                                            <v-col cols="1" class="ml-6">
                                                <v-checkbox
                                                    v-model="filter.inbound"
                                                    :true-value="1"
                                                    :false-value="0"
                                                    on-icon="mdi-check-circle"
                                                    off-icon="mdi-circle-outline"
                                                    color="#f69f1a"
                                                    label="Inbound"
                                                >
                                                </v-checkbox>
                                            </v-col>
                                            <v-col cols="1">
                                                <v-checkbox
                                                    v-model="filter.outbound"
                                                    :true-value="1"
                                                    :false-value="0"
                                                    on-icon="mdi-check-circle"
                                                    off-icon="mdi-circle-outline"
                                                    color="#f69f1a"
                                                    label="Outbound"
                                                >
                                                </v-checkbox>
                                            </v-col>
                                        </v-row>
                                        <v-card-text>
                                            <v-data-table
                                                :headers="dataTable.headers"
                                                :items="dataTable.items"
                                                :loading="dataTable.loading"
                                                :loading-text="dataTable.loading_text"
                                                disable-sort
                                                dense
                                            >
                                            </v-data-table>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider ></v-divider>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
  import { mapGetters } from "vuex";
  import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";

  export default {
      mixins: [SharedFunctionsComponentVue],
      inject: ["theme"],
      data() {
          return {
              valid: true,
              dataTable:{
                items:[],
                headers:[
                    { text:'Transaction',value:'document_number' },
                    { text:'User',value:'name' },
                    { text:'Transaction Date',value:'created_at' },
                    { text:'inbound Qty',value:'inbound_qty' },
                    { text:'Outbound Qty',value:'outbound_qty' },
                    { text:'Stocks',value:'stocks' },
                ],
                loading:false,
                loading_text:'Loading... Please Wait',
              },
              filter:{
                inbound:1,
                outbound:1,
              },

              tagged:[
                  { text:'no',badge_color:'gray' },
                  { text:'yes',badge_color:'success' },
              ],
              po_item: [],
              warehouse_items: []
          };
      },
      computed: {
          ...mapGetters([
              "ACTION",
              "DIALOGS",
              "SELECTED",
              // "GET_PRF_SELECTION",
              "USER_ACCESS",
              "GET_ADMIN_PO_NUM",
              "GET_NEW_STOCK_SUCCESS",
              "GET_ADM_WAREHOUSE_CODE_DROPDOWN",
              "GET_ADM_WAREHOUSE_ITEMS",
              "GET_PRF_STOCK_SELECTION",
              "GET_EDIT_ADM_WAREHOUSE_STOCKS",
              "GET_ADMIN_PO_ITEM_SELECTION",
              "GET_SEARCH_ITEM",
              "STATUS"
          ]),
      },
      mounted(){
          document.addEventListener('keydown', this.handleKeydown);
      },
      methods: {
          closeDialog() {
              this.$store.commit('ACTION','');
              this.$store.commit("DIALOG", false);
          },
          async list(){
            this.dataTable.loading = true
            await this.$store.dispatch("adminWarehouseItemStocksGet", {
                url:'admin-warehouse-item-stocks-log/' + this.SELECTED.admin_item_id,
                ...this.filter,...this.SELECTED
            }).then(response=>{
                this.dataTable.items = response
            }).catch(err=>{
                console.log(err)
            });
            this.dataTable.loading = false
          }
      },
      watch: {
        GET_NEW_STOCK_SUCCESS: {
            handler(val) {
                if (val) {
                    this.$store.commit('ACTION','');
                    this.$store.dispatch("SwalAlertSuccess", {
                    text: "Stock Saved",
                    });
                    this.$store.commit('NEW_STOCK_SUCCESS', false);
                    this.closeDialog()

                    Object.assign(this.$data, this.$options.data.call(this));
                }
            }
        },

        GET_ADM_WAREHOUSE_ITEMS:{
            handler(val){
                if(val){
                    this.loading = true
                    this.warehouse_items = val
                }else{
                    this.warehouse_items = this.GET_ADMIN_PO_ITEM_SELECTION
                }
                this.loading = false
            }
        },
        GET_ADMIN_PO_ITEM_SELECTION:{
            handler(val){
                if(val){
                    this.warehouse_items = val
                }else{
                    this.warehouse_items = []
                    this.loading = false
                }
                    this.loading = false
            }
        },
        GET_SEARCH_ITEM:{
            handler(val){
                this.search = val
            }
        },
        valid:{
            handler(val){
                console.log(1231231)
            }
        },
        DIALOGS:{
            async handler(val){
                if(val){
                    this.list()
                }
            }
        },
        filter:{
            handler(val){
                this.list()
            },
            deep:true
        }
      },
  };
</script>
<style scoped>
.red-text {
  color: red;
}
</style>
