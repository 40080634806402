<template>
    <div>
        <div class="page-wrapper">
            <div class="row p-2">
                <div class="col-12 d-flex no-block align-items-center">
                    <h4 class="page-title">Location</h4>
                </div>
            </div>
            <div class="container">
                <div id="item-tabs" class="row mx-auto">
                    <v-tabs id="group-tab" color="cyan" dark slider-color="cyan" background-color="#424242" class="col-12" show-arrows>
                        <v-tab ripple @click="status('location')">Location</v-tab>
                    </v-tabs>
                    <!-- <AddLocationDialogComponentVue /> -->
                    <LocationTableComponentVue />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import AddLocationDialogComponentVue from '../Views/LocationComponent/AddLocationDialogComponent.vue';
import LocationTableComponentVue from '../Views/LocationComponent/LocationTableComponent.vue';
export default {
    data() {
        return {
            locationStatus: 'location'
        }
    },
    mounted(){
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
    },
    components: {
        LocationTableComponentVue,
        // AddLocationDialogComponentVue
    },
    methods: {
        status(val) {
            this.locationStatus = val;
        }
    }
}
</script>

<style>

</style>
