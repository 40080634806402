<template>
    <v-container fluid>
        <h3 class="page-title">Admin Transmittal</h3>
        <v-container class="ma-0">
            <v-app id="item-tabs">
                <v-tabs
                    v-model="tab"
                    id="group-tab"
                    color="cyan"
                    slider-color="cyan"
                    background-color="#424242"
                    dark
                    show-arrows
                >
                    <v-tabs-slider color="cyan"></v-tabs-slider>
                    <v-tab @click="selectTab(0)">ALL</v-tab>
                    <v-tab
                        v-for="(item, index) in STATUS.ADMIN_TRANSMITTAL"
                        :key="index"
                        @click="selectTab(item.value)"
                    >{{ item.text }}</v-tab>
                </v-tabs>
                <AdminTransmittalComponent />
            </v-app>
        </v-container>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import AdminTransmittalComponent from "@/views/main/modules/Admin/Transmittal/AdminTransmittalComponent.vue";

export default {
    components: { AdminTransmittalComponent },

    data() {
        return {
            tab: 0,
        };
    },

    mounted() {
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id);
        this.$store.dispatch("getPvCompanies", { follow_user_access: 1 });
        this.$store.dispatch('getPaymentVoucherBranchesSelection', { follow_user_access: 1 });
        this.$store.dispatch('getDepartment');
    },

    computed: {
        ...mapGetters([
            'USERACCOUNT_LOAD',
            'STATUS',
        ]),
    },

    methods: {
        selectTab(tab) {
            this.$store.commit('SELECTED_TAB', tab);
        },
    },
}
</script>