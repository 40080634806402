<template>
    <v-container>
        <v-dialog v-model="dialogState" scrollable max-width="90%" persistent>
            <v-card class="pb-2">
                <v-card-title class="d-flex justify-between pt-2 pb-0 pl-2 pr-0">
                    <v-col cols=11 class="text-h5">{{ GET_DISPOSAL_ACTION }} Parts Claims</v-col>
                    <v-col cols=1>
                        <v-btn
                            text
                            icon
                            color="gray"
                            class="float-right"
                            @click="closeViewItemDisposal"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-card-title>
                <v-divider class="ma-0 pa-0"/>
                <v-card-text class="pb-0">
                    <v-container class="pt-4 pb-0 px-0">
                        <v-row class="mb-4">
                            <v-col cols="12" md="3">
                                <div>Parts Claim#: <span style="color:#000000">{{ GET_DISPOSAL_DETAILS.disposal_number }}</span></div>
                                <div>Status: <span style="color:#000000">{{ GET_DISPOSAL_DETAILS.status }}</span></div>
                                <div>Stock Transfer#: <span style="color:#000000">{{ GET_DISPOSAL_DETAILS.transfer_num }}</span></div>
                                <!-- <div>Source Warehouse: <span style="color:#000000">{{ GET_DISPOSAL_DETAILS.source_wh }}</span></div>
                                <div>Destination Warehouse: <span style="color:#000000">{{ GET_DISPOSAL_DETAILS.disposal_wh_code }}</span></div> -->
                            </v-col>
                            <v-col cols="12" md="3">
                                <!-- <div>Source: <span style="color:#000000">{{ GET_DISPOSAL_DETAILS.source }}</span></div> -->
                                <div>Created By: <span style="color:#000000">{{ GET_DISPOSAL_DETAILS.created_by }}</span></div>
                                <div>Approved by: <span style="color:#000000">{{ GET_DISPOSAL_DETAILS.approved_by }}</span></div>
                                <div v-if="GET_DISPOSAL_DETAILS.status == 'disposed'">Disposed by: <span style="color:#000000">{{ GET_DISPOSAL_DETAILS.disposed_by }}</span></div>
                                <div v-if="GET_DISPOSAL_DETAILS.status == 'recovered'">Disposed by: <span style="color:#000000">{{ GET_DISPOSAL_DETAILS.recovered_by }}</span></div>
                            </v-col>
                            <v-col cols="12" md="3">
                                <!-- <div v-if="GET_DISPOSAL_DETAILS.parts_transfer?.series">Parts Transfer#: <span style="color:#000000">{{ GET_DISPOSAL_DETAILS.parts_transfer.series }}</span></div> -->
                                <div>Created Date: <span style="color:#000000">{{ GET_DISPOSAL_DETAILS.created_at }}</span></div>
                                <div>Approved Date: <span style="color:#000000">{{ GET_DISPOSAL_DETAILS.approved_date }}</span></div>
                                <div v-if="GET_DISPOSAL_DETAILS.status == 'disposed'">Disposed Date: <span style="color:#000000">{{ GET_DISPOSAL_DETAILS.disposed_date }}</span></div>
                                <div v-if="GET_DISPOSAL_DETAILS.status == 'recovered'">Disposed Date: <span style="color:#000000">{{ GET_DISPOSAL_DETAILS.recovered_date }}</span></div>
                            </v-col>
                            <v-spacer />
                            <v-col cols="12" md="2" class="d-flex flex-column justify-end pr-4">
                                <v-row v-if="['approved'].includes(GET_DISPOSAL_DETAILS.status)" class="mt-0 pr-2">
                                    <v-text-field
                                        v-model="cmNumber"
                                        label="CM Number"
                                        ref="cmNumber"
                                        type="text"
                                        outlined
                                        dense
                                    />
                                </v-row>
                                <v-row class="d-flex justify-end mb-0">
                                    <BaseFilesViewer :disposalId="GET_DISPOSAL_DETAILS.id" :delete="true" :buttonColor="`#f69f1a`" class="mr-0 pa-0"/>
                                    <v-tooltip class="mr-6" bottom  color="#f69f1a">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    fab
                                                    text
                                                    small
                                                    v-on="on"
                                                    class="mr-2"
                                                    v-bind="attrs"
                                                    color="#f69f1a"
                                                    ref="attachFile"
                                                    @click="fileUpload=true"
                                                >
                                                    <v-icon>mdi-attachment</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Attach File</span>
                                    </v-tooltip>
                                    <v-tooltip bottom color="#f69f1a">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                fab
                                                icon
                                                text
                                                small
                                                v-on="on"
                                                v-bind="attrs"
                                                color="#f69f1a"
                                                @click="printPDF()"
                                            >
                                                <v-icon color="#f69f1a">mdi-cloud-download</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Print PDF</span>
                                    </v-tooltip>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-col v-if="GET_DISPOSAL_ACTION == 'Edit'" cols="12">
                            <v-simple-table dense outlined class="dynamic_list_table">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-center mx-2" width="10%">Item Model</th>
                                            <th class="text-center mx-2" width="19%">Item Name</th>
                                            <th class="text-center mx-2" width="4%">UOM</th>
                                            <th class="text-center mx-2" width="4%">Type</th>
                                            <th class="text-center mx-2" width="9%">Category</th>
                                            <th class="text-center mx-2" width="7%">ST Qty.</th>
                                            <th class="text-center mx-2" width="7%">Disposed Qty.</th>
                                            <th class="text-center mx-2" width="7%">Recovered Qty.</th>
                                            <th class="text-center mx-2" width="9%">On Process Qty.</th>
                                            <th class="text-center mx-2" width="7%">Remaining Qty.</th>
                                            <th class="text-center mx-2" width="7%">Quantity</th>
                                            <th class="text-center mx-2" width="22%">Remarks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item,i) in GET_DISPOSAL_ITEMS" :key="i">
                                            <td>
                                                <v-text-field
                                                    v-model="item.model"
                                                    dense
                                                    outlined
                                                    hide-details
                                                    readonly
                                                    background-color="grey lighten-2"
                                                ></v-text-field>
                                            </td>
                                            <td>
                                                <v-text-field
                                                    v-model="item.name"
                                                    dense
                                                    outlined
                                                    hide-details
                                                    readonly
                                                    background-color="grey lighten-2"
                                                ></v-text-field>
                                            </td>
                                            <td>
                                                <v-text-field
                                                    v-model="item.uom"
                                                    dense
                                                    outlined
                                                    readonly
                                                    hide-details
                                                    class="centered-input"
                                                    background-color="grey lighten-2"
                                                ></v-text-field>
                                            </td>
                                            <td>
                                                <v-text-field
                                                    v-model="item.type"
                                                    dense
                                                    outlined
                                                    readonly
                                                    hide-details
                                                    class="centered-input"
                                                    background-color="grey lighten-2"
                                                ></v-text-field>
                                            </td>
                                            <td>
                                                <v-text-field
                                                    v-model="item.category"
                                                    dense
                                                    outlined
                                                    readonly
                                                    hide-details
                                                    background-color="grey lighten-2"
                                                ></v-text-field>
                                            </td>
                                            <td>
                                                <v-text-field
                                                    v-model="item.st_original_qty"
                                                    dense
                                                    outlined
                                                    readonly
                                                    hide-details
                                                    class="centered-input"
                                                    background-color="grey lighten-2"
                                                    type="number"
                                                    min="0"
                                                ></v-text-field>
                                            </td>
                                            <td>
                                                <v-text-field
                                                    v-model="item.disposed_qty"
                                                    dense
                                                    outlined
                                                    readonly
                                                    hide-details
                                                    class="centered-input"
                                                    background-color="grey lighten-2"
                                                    type="number"
                                                    min="0"
                                                ></v-text-field>
                                            </td>
                                            <td>
                                                <v-text-field
                                                    v-model="item.recovered_qty"
                                                    dense
                                                    outlined
                                                    readonly
                                                    hide-details
                                                    class="centered-input"
                                                    background-color="grey lighten-2"
                                                    type="number"
                                                    min="0"
                                                ></v-text-field>
                                            </td>
                                            <td>
                                                <v-text-field
                                                    v-model="item.on_process_qty"
                                                    dense
                                                    outlined
                                                    readonly
                                                    hide-details
                                                    class="centered-input"
                                                    background-color="grey lighten-2"
                                                    type="number"
                                                    min="0"
                                                ></v-text-field>
                                            </td>
                                            <td>
                                                <v-text-field
                                                    v-model="item.remaining_qty"
                                                    dense
                                                    outlined
                                                    readonly
                                                    hide-details
                                                    class="centered-input"
                                                    background-color="grey lighten-2"
                                                    type="number"
                                                    min="0"
                                                ></v-text-field>
                                            </td>
                                            <td>
                                                <v-text-field
                                                    dense
                                                    min="0"
                                                    outlined
                                                    hide-details
                                                    type="number"
                                                    class="centered-input"
                                                    background-color="grey-lighten-5"
                                                    v-model="item.quantity"
                                                    :max="item.remaining_qty"
                                                    @input="enableButton"
                                                ></v-text-field>
                                            </td>
                                            <td>
                                                <v-text-field
                                                    v-model="item.remarks"
                                                    dense
                                                    outlined
                                                    hide-details
                                                    class="centered-input"
                                                    background-color="grey lighten-5"
                                                    @input="enableButton"
                                                ></v-text-field>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                            <v-row v-if="GET_REQUEST_ON_FLIGHT" sm="12" md="12"  class="d-flex justify-center mt-4">
                                <v-progress-circular
                                    :size="60"
                                    color="primary"
                                    indeterminate
                                ></v-progress-circular>
                            </v-row>
                        </v-col>
                        <v-data-table
                            v-else
                            dense
                            :headers="header"
                            :items="GET_DISPOSAL_ITEMS"
                            :loading="GET_REQUEST_ON_FLIGHT"
                        >
                        </v-data-table>
                    </v-container>
                </v-card-text>
                <v-card-actions class="d-flex justify-center mb-2">
                    <v-tooltip v-if="GET_DISPOSAL_ACTION == 'View' && userAccess.includes('approve')" top color="#f69f1a">
                        <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    fab
                                    text
                                    v-on="on"
                                    v-bind="attrs"
                                    color="#f69f1a"
                                    :loading="approveOnFlight"
                                    :class="checkDisposalStatus()"
                                    @click="approveDisposal"
                                >
                                    <v-icon style="font-size:40px">mdi-checkbox-marked-circle</v-icon>
                                </v-btn>
                            </template>
                        <span>Approve</span>
                    </v-tooltip>
                    <v-tooltip v-if="GET_DISPOSAL_ACTION == 'Edit'" top color="#f69f1a">
                        <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    fab
                                    text
                                    small
                                    outlined
                                    v-on="on"
                                    v-bind="attrs"
                                    color="#f69f1a"
                                    :disabled="disable"
                                    :loading="updateOnFlight"
                                    :class="checkDisposalStatus()"
                                    @click="updateDisposal"
                                >
                                    <v-icon>mdi-content-save-all</v-icon>
                                </v-btn>
                            </template>
                        <span>Update</span>
                    </v-tooltip>
                    <v-row v-if="GET_DISPOSAL_ACTION == 'View' && GET_DISPOSAL_DETAILS.status == 'approved'" class="d-flex justify-center">
                        <v-tooltip top color="#f69f1a" v-if="userAccess.includes('dispose')">
                            <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        fab
                                        small
                                        outlined
                                        v-on="on"
                                        v-bind="attrs"
                                        color="#f69f1a"
                                        class="mr-2"
                                        :loading="disposeOnFlight"
                                        @click="checkCMNumber('dispose')"
                                    >
                                        <v-icon>mdi-trash-can</v-icon>
                                    </v-btn>
                                </template>
                            <span>Dispose</span>
                        </v-tooltip>
                        <v-tooltip  top color="#f69f1a" v-if="userAccess.includes('recover')">
                            <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        fab
                                        small
                                        outlined
                                        v-on="on"
                                        v-bind="attrs"
                                        color="#f69f1a"
                                        :loading="recoverOnFlight"
                                        @click="checkCMNumber('recover')"
                                    >
                                        <v-icon>mdi-arrow-left-top</v-icon>
                                    </v-btn>
                                </template>
                            <span>Recover</span>
                        </v-tooltip>
                    </v-row>
                </v-card-actions>
            </v-card>
            <!-- FILE UPLOAD DIALOG -->
            <v-dialog v-model="fileUpload" persistent max-width="20%">
                <v-card class="pa-4">
                    <v-btn
                        text
                        icon
                        color="gray"
                        class="float-right"
                        @click="fileUpload=false"
                    >
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                    <div class="py-2">
                        <span>Attach File</span><span style="color:red">  *</span>
                        <v-divider/>
                    </div>
                    <div class="py-2 mt-2">
                        <v-layout row class="mx-0">
                            <v-flex lg2 class="mr-2">
                                <BaseCameraCaptureVue ref="cameraCapture" @capturedImages="getImages" />
                            </v-flex>
                            <div class="mt-2">
                                <v-chip
                                    v-if="!!images && images.length > 0"
                                    color="#f69f1a"
                                    outlined
                                    close
                                    @click:close="clearCameraCapture()"
                                >
                                    <span class="mr-2">Images Count: {{ images.length }}</span>
                                </v-chip>
                            </div>
                        </v-layout>
                    </div>

                    <div class="py-2 mt-3">
                        <v-layout row class="mx-0">
                            <v-flex :class="{'mr-2': true, 'lg3': !!uploadedImageFile && uploadedImageFile.attachments.length > 1, 'lg6': !!uploadedImageFile && uploadedImageFile.attachments.length == 1}">
                                <FileUploadComponentVue ref="fileUpload" @uploadedData="uploadedData" @reset="clearImageUpload"/>
                            </v-flex>
                            <v-flex v-if="!!uploadedImageFile && uploadedImageFile.attachments.length > 0" class="ml-2" >
                                <div class="mt-2">
                                    <v-chip
                                        v-if="!!uploadedImageFile && uploadedImageFile.attachments.length > 0"
                                        color="#f69f1a"
                                        outlined
                                        close
                                        @click:close="clearImageUpload()"
                                    >
                                        <span class="mr-2">Upload Count: {{ uploadedImageFile.attachments.length }}</span>
                                    </v-chip>
                                </div>
                            </v-flex>
                        </v-layout>
                    </div>
                    <div class="py-2 mt-3">
                        <v-card-actions class="d-flex justify-content-end">
                            <v-btn  @click="storeFileUpload">Submit</v-btn>
                        </v-card-actions>
                    </div>
                </v-card>
            </v-dialog>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
import FileUploadComponentVue from '@/views/main/Shared/FileUploadComponent.vue';
import BaseCameraCaptureVue from '@/views/main/modules/Services/Base/BaseCameraCapture.vue';
import BaseFilesViewer from '@/views/main/modules/Services/Base/BaseFilesViewer.vue';

export default {
    props: {
        disposalDetails: {
            type: Object,
            default: () => {}
        },
        dialogState: {
            type: Boolean,
            default: () => false,
        }
    },
    components:{
        FileUploadComponentVue,
        BaseCameraCaptureVue,
        BaseFilesViewer,
    },
    data() {
        return {
            header:[
                // {text:'Received Qty',value:'container_item.quantity_received', sortable:false, width:'10%'},
                {text:'Item Model',value:'model', sortable:false},
                {text:'Item Name',value:'name', sortable:false},
                {text:'UOM',value:'uom', sortable:false},
                {text:'Type',value:'type', sortable:false},
                {text:'Category',value:'category', sortable:false},
                {text:'Original Qty',value:'st_original_qty', sortable:false, width:'10%', align:'center'},
                {text:'Disposed Qty',value:'disposed_qty', sortable:false, width:'10%', align:'center'},
                {text:'Recovered Qty',value:'recovered_qty', sortable:false, width:'10%', align:'center'},
                {text:'Remaining Qty',value:'remaining_qty', sortable:false, width:'10%', align:'center'},
                {text:'Quantity',value:'quantity', sortable:false, width:'10%', align:'center'},
                {text:'Remarks',value:'remarks', sortable:false},
            ],
            pdfColumns: [
                // { header: "Received Qty", dataKey: "container_item.quantity_received" },
                { header: "Item Model", dataKey: "model" },
                { header: "Item Name", dataKey: "name" },
                { header: "UOM", dataKey: "uom" },
                { header: "Type", dataKey: "type" },
                { header: "Category", dataKey: "category" },
                { header: "Qty", dataKey: "quantity" },
                { header: "Remarks", dataKey: "remarks" }
            ],
            approveOnFlight: false,
            updateOnFlight: false,
            disable:true,
            userAccess:[],
            disposalStatus:'',
            disposeOnFlight: false,
            fileUpload: false,
            images: false,
            uploadedImageFile: null,
            cmNumber:'',
            recoverOnFlight: false,
        }
    },
    computed: {
        ...mapGetters([
            'GET_DISPOSAL_DIALOG',
            'GET_DISPOSAL_DETAILS',
            'GET_DISPOSAL_ITEMS',
            'GET_REQUEST_ON_FLIGHT',
            'GET_DISPOSAL_ACTION',
            'USER_ACCESS',
            'GET_UPLOADED_FILE_EXISTS',
        ])
    },
    methods: {
        closeViewItemDisposal() {
            // this.$store.commit('DISPOSAL_DIALOG', false);
            this.$emit('closeDialog', false);
            this.$store.commit('DISPOSAL_ITEMS', []);
            this.disable = true;
            this.cmNumber = '';
        },
        approveDisposal() {
            swal.fire({
                title: 'Are you sure to approve the disposal?',
                text: '-Confirm to proceed',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then( ans => {
                if (ans.isConfirmed) {
                    this.approveOnFlight = true;
                    let payload = {
                        disposal_id: this.GET_DISPOSAL_DETAILS.id,
                        source_warehouse: this.GET_DISPOSAL_DETAILS.warehouse_id,
                        disposal_src_wh_id: this.GET_DISPOSAL_DETAILS.disposal_src_wh_id,
                        disposal_dest_wh_id: this.GET_DISPOSAL_DETAILS.disposal_dest_wh_id,
                        disposed_items: this.GET_DISPOSAL_ITEMS,
                        url:'approve-disposal'
                    }
                    this.$store.dispatch('disposalPost', payload).then(res => {
                        const message = res.data.message;
                        swal.fire(message, '', message.toLowerCase());
                        this.approveOnFlight = false;
                        this.$emit('successTransaction');
                        this.closeViewItemDisposal();
                    }).catch( e => {
                        console.log(e);
                        this.approveOnFlight = false;
                    })
                }

            });
        },
        checkDisposalStatus() {
            if (this.GET_DISPOSAL_ACTION == 'View')
            {
                if(this.GET_DISPOSAL_DETAILS.status != 'saved') {
                    return 'd-none';
                }
            } else {
                if(!['saved','pending'].includes(this.GET_DISPOSAL_DETAILS.status)){
                    return 'd-none';
                }
            }
        },
        printPDF() {
            let doc = new this.$jspdf("p", "mm", "letter");

            const disposal = this.GET_DISPOSAL_DETAILS
            doc.autoTable({
                body: this.GET_DISPOSAL_ITEMS,
                columns: this.pdfColumns,
                headStyles: {
                    fillColor: '#F69F1A',
                    textColor: "black",
                    textType: "bold",
                },
                margin: {left: 10, right: 10},
                startY: 65,
                styles: {
                    fontSize: 8,
                    lineWidth: 0.1,
                    lineColor: 'black',
                    overflow: 'hidden'
                },
                showHead: 'firstPage',
                theme: "plain",
                didDrawPage: function(){
                    // Header
                    let ingcoPhLogo = new Image();
                    ingcoPhLogo.src = "/images/ingcoph-logo.png";
                    doc.addImage(ingcoPhLogo, "png", 65, 12.7, 90, 20);
                    doc.setFontSize(14);
                    doc.text('Disposal Details', 215/2, 38, "center");

                    doc.setFontSize(10);

                    doc.text(`Parts Claim#: ${disposal.id}`, 10, 52, "left");
                    doc.text(`Status: ${disposal.status}`, 10, 57, "left");
                    doc.text(`Stock Transfer#: ${disposal.transfer_num ?? ''}`, 10, 62, "left");
                    // doc.text(`Container#: ${disposal.transfer_num ?? ''}`, 10, 57, "left");
                    // doc.text(`Parts Transfer#: ${!!disposal.parts_transfer?.series ? disposal.parts_transfer.series : ''}`, 10, 62)

                    // doc.text(`Status: ${disposal.status}`, 92, 52, "left");
                    // doc.text(`Source: ${disposal.source}`, 92, 57, "left");
                    // doc.text(`Source WH: ${disposal.source_wh}`, 91, 62, "left");

                    // doc.text(`Disposal WH: ${disposal.disposal_wh_code}`, 155, 52, "left");
                    doc.text(`Created Date: ${disposal.created_at}`, 155, 52, "left");
                    doc.text(`Approved Date: ${!!disposal.approved_date ? disposal.approved_date : ''}`, 155, 57, "left");

                    // Footer
                    doc.text(`Created By: ${disposal.created_by}`,10, 240, "left");
                    doc.text(`Approved By: ${!!disposal.approved_by ? disposal.approved_by : ''}`,135, 240, "left");
                }
            });

            for (let page = 1; page <= doc.internal.getNumberOfPages(); page++) {
                doc.setPage(page);
                doc.setTextColor("black");
                doc.setFontSize(7);
                doc.text(
                `Page ${page} of ${doc.internal.getNumberOfPages()}`,
                191,
                doc.internal.pageSize.height - 7
                );
            }

            doc.autoPrint();
            window.open(doc.output('bloburl'), '_blank');
        },
        updateDisposal(){
            const validateError = this.validateSelectedItems(this.GET_DISPOSAL_ITEMS)

            if (_.compact(validateError).length > 0) {
                swal.fire("Invalid Quantity", "-Value can't be zero or above recieved quantity.", "warning");
                return;
            } else {
                swal.fire({
                    title: 'Are you sure to update the disposal?',
                    text: '-Confirm to proceed',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                }).then( ans => {
                    if (ans.isConfirmed) {
                        this.updateOnFlight = true;
                        let payload = {
                            disposal_status: this.GET_DISPOSAL_DETAILS.status,
                            disposal_id: this.GET_DISPOSAL_DETAILS.id,
                            disposed_items: this.GET_DISPOSAL_ITEMS,
                            url:'update-disposals',
                        }
                        this.$store.dispatch('disposalPost', payload).then( res => {
                            const message = res.data.message;
                            this.updateOnFlight = false;
                            swal.fire("", message, message.toLowerCase());
                        }).catch( e => {
                            console.log(e)
                            this.updateOnFlight = false;
                        });
                    }
                });
            }
        },
        validateSelectedItems(data) {
            return data.map( e => {
                if (e) {
                    if (!e.quantity || e.quantity <= 0 || e.quantity > e.remaining_qty) {
                        return e;
                    }
                }
            });
        },
        enableButton() {
            this.disable=false;
        },
        dispose() {
            swal.fire({
                title: 'Are you sure to dispose the items?',
                text: '-Confirm to proceed',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
            }).then( ans => {
                if (ans.isConfirmed){
                    this.disposeOnFlight = true;
                    let payload = {
                        disposal_id: this.GET_DISPOSAL_DETAILS.id,
                        cm_number: this.cmNumber,
                        url: 'dispose-disposal'
                    }

                    this.$store.dispatch('disposalPost', payload).then( res => {
                        let message = res.data;
                        swal.fire(Object.assign(message, {timer:1500}));
                        this.closeViewItemDisposal();
                        this.$emit('successTransaction');
                        this.disposeOnFlight = false;
                    }).catch(e => {
                        console.log(e);
                        this.disposeOnFlight = false;
                    })
                }
            });
        },
        getImages(images) {
            this.images = images;
        },
        clearCameraCapture() {
            this.$refs.cameraCapture.reset();
            this.images = null;
        },
        clearImageUpload() {
            this.uploadedImageFile = null;
            this.$refs.fileUpload.reset();
        },
        uploadedData(data) {
            if(!data) {
                this.uploadedImageFile = null;
            }

            this.uploadedImageFile = data;
        },
        storeFileUpload() {
            if (this.GET_DISPOSAL_DETAILS.id) {
                const data = new FormData();
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
                if(!!this.uploadedImageFile && this.uploadedImageFile.attachments.length > 0) {
                    let files = this.uploadedImageFile.attachments;

                    for(let i=0;i < _.compact(files).length;i++) {
                        data.append('files[]', files[i]);
                    }
                }

                if(!!this.images && this.images.length > 0) {
                    for(let i=0;i < _.compact(this.images).length;i++) {
                        data.append('images[]', _.compact(this.images)[i]);
                    }
                }
                data.append('disposalId', this.GET_DISPOSAL_DETAILS.id);
                this.$store.dispatch('uploadDisposalFile', { params: data, config: config }).then(response=>{
                    swal.fire(Object.assign(response.data, {timer:1500}));
                    this.fileUpload = false;
                    this.clearCameraCapture();
                    this.clearImageUpload();
                    this.$emit('successUpload', {disposalId:this.GET_DISPOSAL_DETAILS.id,containerId:this.GET_DISPOSAL_DETAILS.container_id});
                }).catch( e => {
                    console.log(e)
                })
            } else {
                console.error("Invalid Disposal ID");
            }
        },
        checkCMNumber(action){
            if (! this.GET_UPLOADED_FILE_EXISTS) {
                swal.fire("","-Please upload neccessary files", "info");
                this.$refs.attachFile.$el.focus();
                return;
            }

            if (this.cmNumber == '' || this.cmNumber == null || this.cmNumber == undefined) {
                swal.fire("","-CM Number is required.", "info");
                this.$refs.cmNumber.focus();
                return;
            } else {
                action == 'dispose' ? this.dispose() : this.recover();
            }
            return true;
        },
        recover() {
            swal.fire({
                title: 'Are you sure to recover the items?',
                text: '-Stocks will be transfer back to DM',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
            }).then( ans => {
                if (ans.isConfirmed){
                    this.recoverOnFlight = true;
                    let payload = {
                        disposal_id: this.GET_DISPOSAL_DETAILS.id,
                        cm_number: this.cmNumber,
                        url: 'recover-disposal'
                    }

                    this.$store.dispatch('disposalPost', payload).then( res => {
                        let message = res.data;
                        swal.fire(Object.assign(message, {timer:1500}));
                        this.closeViewItemDisposal();
                        this.$emit('successTransaction');
                        this.recoverOnFlight = false;
                    }).catch(e => {
                        console.log(e);
                        this.recoverOnFlight = false;
                    })
                }
            })
        }
    },
    watch: {
        USER_ACCESS: {
            handler(val) {
                if (val != 'fail'){
                    this.userAccess = val.map(e => e.actions_code)
                }
            }
        }
    },
    mounted() {
        this.$store.dispatch('checkAccessUser',this.$router.currentRoute.params.id);
    }

}
</script>

<style>

</style>