<template>
<div class="tabs-body-color" >

    <v-container>
        <v-row>
            <div class="col-12">
                <div class="btn-toolbar">
                    <!-- <buttons class="ml-3"
                        :action="buttons.actions.create"
                        :button_icon="buttons.icons.create"
                        :color="buttons.colors.create"
                        @click="showAddBundle()"
                    >
                    </buttons> -->
                    <div class="row my-1">
                        <div class="">
                            <div class="btn-toolbar">
                                <v-btn class="" @click="showAddBundle()">
                                    <v-icon>mdi-plus</v-icon>
                                    Add
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </v-row>

        <v-row class="row">
            <!-- Bundles Table -->
            <BundlesTableComponentVue :itemBundlesActionResult="action" @showEditModal="showEditModal"></BundlesTableComponentVue>
        </v-row>
    </v-container>

    <v-app id="addEditBundleModal">
        <v-dialog v-model="add_edit_bundle_dialog" persistent max-width="98%" scrollable>
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Bundle Information</span>
                        </v-card-title>
                    </v-col>

                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="add_edit_bundle_dialog = false; resetFields(); $emit('closeModal');">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-card-text>
                    <form class="form-horizontal" action="/itemBundles" method="post" v-on:submit.prevent="onSubmit">
                        <div class="card-body">

                            <div class="row">
                                <div class="col-sm-12 col-md-3">
                                    <label for="model" class="float-left control-label col-form-label">Model</label>
                                    <span>
                                        <v-autocomplete :items="GET_ITEMS" dense outlined disable-lookup
                                            v-model="item_id"
                                            placeholder="Select Model"
                                            class="d-inline-block small w-100"
                                            auto-select-first
                                            @input="selectModel(item_id)"
                                        >
                                        </v-autocomplete>
                                        <span v-for="errors in errors.item_id" class="text-warning" v-bind:key="errors">{{errors}}</span>
                                    </span>
                                    <!-- <span v-else>
                                        <v-text-field dense outlined hide-details class=" centered-input small" v-model="item.model" readonly>
                                    </span>-->
                                </div>
                                <div class="col-sm-12 col-md-4">
                                    <label for="name" class="float-left control-label col-form-label">Name</label>
                                    <span>
                                        <v-autocomplete :items="GET_ITEMS" dense outlined :disable-lookup="true"
                                            v-model="item_id"
                                            item-text = "name"
                                            placeholder="Select Name"
                                            class="d-inline-block small w-100"
                                            auto-select-first
                                            @input="selectModel(item_id)"
                                        >
                                        </v-autocomplete>
                                        <span v-for="errors in errors.item_id" class="text-warning" v-bind:key="errors">{{errors}}</span>
                                    </span>
                                    <!-- <span v-else>
                                        <v-text-field dense outlined hide-details class=" centered-input small" v-model="item.name" readonly>
                                    </span>-->
                                </div>
                                <div class="col-sm-12 col-md-3 pt-8">
                                    <v-checkbox
                                        v-model="is_active"
                                        label="Active"
                                        :true-value="1"
                                        :false-value="0"
                                        hide-details
                                    />
                                </div>
                            </div>

                            <v-card
                                outlined
                                class="p-2"
                            >
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="float-right mx-2">
                                            <v-btn
                                                fab
                                                dark
                                                small
                                                color="primary"
                                                style="height:20px; width:20px;"
                                                @click="addBundleItem()"
                                            >
                                                <v-icon dark small style="font-size:12px">
                                                    mdi-plus
                                                </v-icon>
                                            </v-btn>
                                            <v-btn
                                                fab
                                                dark
                                                small
                                                color="primary"
                                                style="height:20px; width:20px;"
                                                @click="removeBundleItem()"
                                            >
                                                <v-icon dark small style="font-size:12px">
                                                    mdi-minus
                                                </v-icon>
                                            </v-btn>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <v-simple-table dense class="dynamic_list_table">
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <!-- <th class="text-center mx-2" width="9%">Qty</th>
                                                        <th class="text-center mx-2" width="9%">UOM</th>
                                                        <th class="text-center mx-2" width="12%">Model</th>
                                                        <th class="text-center mx-2" width="22%" >Name</th>
                                                        <th class="text-center mx-2">Selling Price</th>
                                                        <th class="text-center mx-2">SP Price</th>
                                                        <th class="text-center mx-2" width="1%">FOC</th>
                                                        <th class="text-center mx-2">Total</th>
                                                        <th class="text-center mx-2" width="7%">Type</th>
                                                        <th class="text-center mx-2" width="1%"></th> -->

                                                        <th class="text-center mx-2" width="12%">Model</th>
                                                        <th class="text-center mx-2" width="22%" >Name</th>
                                                        <th class="text-center mx-2" width="9%">UOM</th>
                                                        <th class="text-center mx-2" width="9%">Qty</th>
                                                        <th class="text-center mx-2">Selling Price</th>
                                                        <th class="text-center mx-2">SP Price</th>
                                                        <th class="text-center mx-2" width="1%">FOC</th>
                                                        <th class="text-center mx-2">Total</th>
                                                        <th class="text-center mx-2" width="7%">Type</th>
                                                        <th class="text-center mx-2" width="1%"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        v-for="(order_item, i) in bundleItems"
                                                        :key="i"
                                                    >
                                                        <td>

                                                                <v-autocomplete :items="GET_ITEMS" dense outlined disable-lookup
                                                                    v-model="bundleItems[i].item_id"
                                                                    placeholder="Select Model"
                                                                    class="d-inline-block small w-100"
                                                                    auto-select-first
                                                                    @input="selectModel(i, bundleItems[i].item_id)"
                                                                >
                                                                </v-autocomplete>
                                                            <!--<span v-else>
                                                                <v-text-field dense outlined hide-details class=" centered-input small" v-model="bundleItems[i].item.model" readonly>
                                                            </span>-->
                                                        </td>
                                                        <td>

                                                                <v-autocomplete :items="GET_ITEMS" dense outlined
                                                                    v-model="bundleItems[i].item_id"
                                                                    item-text = "name"
                                                                    placeholder="Select Name"
                                                                    class="d-inline-block small w-100"
                                                                    auto-select-first
                                                                    @input="selectModel(i, bundleItems[i].item_id)"
                                                                >
                                                                </v-autocomplete>

                                                           <!-- <span v-else>
                                                                <v-text-field dense outlined hide-details class=" centered-input small" v-model="bundleItems[i].item.name" readonly>
                                                            </span>-->
                                                        </td>
                                                        <td>
                                                            <v-text-field dense outlined hide-details class="centered-input small" v-model="bundleItems[i].item.item_uom_text" readonly></v-text-field>
                                                        </td>
                                                        <td>
                                                            <v-text-field dense outlined hide-details class="centered-input" v-model="bundleItems[i].quantity" @keypress="numberOnly" @input="changeQuantity(i)"></v-text-field>
                                                        </td>
                                                        <td>
                                                            <v-text-field dense outlined hide-details class=" right-input small" v-model="bundleItems[i].item.selling_price" readonly></v-text-field>
                                                        </td>
                                                        <td>
                                                            <v-text-field dense outlined hide-details class=" right-input small" v-model="bundleItems[i].item.sp_price" readonly></v-text-field>
                                                        </td>
                                                        <td>
                                                            <v-checkbox
                                                                v-model="bundleItems[i].foc"
                                                                label=""
                                                                color="primary"
                                                                input-value="1"
                                                                class="align-center justify-center"
                                                                @change="changeFoc(i)"
                                                            ></v-checkbox>
                                                        </td>
                                                        <td v-if="bundleItems[i].override_amount == null || bundleItems[i].override_amount == 0">
                                                            <v-text-field dense outlined hide-details class=" right-input small" v-model="bundleItems[i].total_amount" readonly @click="showOverrideAmountDialog(i)"></v-text-field>
                                                        </td>
                                                        <td v-else>
                                                            <v-text-field dense outlined hide-details class=" right-input small bg-ingco" v-model="bundleItems[i].override_amount" readonly @click="showOverrideAmountDialog(i)"></v-text-field>
                                                        </td>
                                                        <td>
                                                            <v-text-field dense outlined hide-details class=" centered-input" v-model="bundleItems[i].item.item_type_text" readonly></v-text-field>
                                                        </td>
                                                        <td>
                                                            <v-btn
                                                                fab
                                                                dark
                                                                small
                                                                color="primary"
                                                                style="height:20px; width:20px;"
                                                                @click="removeItemByIndex(i)"
                                                            >
                                                                <v-icon dark small style="font-size:12px">
                                                                    mdi-minus
                                                                </v-icon>
                                                            </v-btn>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </div>
                                </div>
                            </v-card>

                        </div>

                        <div class="border-top">
                            <div class="card-body py-4 d-flex justify-content-center">
                                <v-btn  v-on:click="addNewBundle()" v-if="action=='Add'">Submit</v-btn>
                                <v-btn  v-on:click="editBundle()" v-else-if="action=='Edit'">Update</v-btn>
                            </div>
                        </div>
                    </form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

</div>
</template>

<script>
    import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
    import BundlesTableComponentVue from '../tables/BundlesTableComponent.vue';
    // import buttons from '@/views/main/layouts/Buttons/DialogButton.vue';
    // import buttons_components from '@/assets/js/components-js.js';
    import { mapGetters } from 'vuex';
    import swal from 'sweetalert2';
    import $ from 'jquery'

    export default {
        mixins: [SharedFunctionsComponentVue],
        data(){
            return {
                editBundleId: '',
                add_edit_bundle_dialog: false,
                items_list: [],
                item_id: '',
                item: {
                    model: '',
                    name: '',
                    item_uom_text: '',
                },
                bundleItems: [],
                errors: [],
                action: '',
                actionResult : {
                    model : 'Bundle',
                    action : ''
                },
                loaded: false,
                is_active: 0,
                // buttons:buttons_components.buttons
            }
        },
        components: {
            BundlesTableComponentVue,
            // buttons,
            // buttons_components,
        },
        computed:{
            ...mapGetters([
                'GET_ITEMS',
                'GET_ITEM_EDIT_INFO',
                'GET_STORE_ITEM_BUNDLE',
                'GET_ITEM_BUNDLE',
                'GET_UPDATE_ITEM_BUNDLE',
            ]),

        },
        methods: {
            showOverrideAmountDialog(){

            },
            changeQuantity(key){
                this.computeTotalAmountPerItem(key)
            },
            addBundleItem(){
                this.bundleItems.push({
                    quantity: '',
                    item_id: '',
                    foc: '',
                    total_amount: '',
                    override_amount: '',
                    item: {
                        id: '',
                        model: '',
                        name: '',
                        description: '',
                        type_id: '',
                        item_type_text: '',
                        category_id: '',
                        uom_id: '',
                        item_uom_text: '',
                        barcode: '',
                        selling_price: '',
                        sp_price: '',
                    },
                })
            },
            removeBundleItem(){
                if(this.bundleItems.length > 1){
                    this.bundleItems.pop();
                }
            },
            removeItemByIndex(index){
                this.bundleItems.splice(index, 1)
            },
            changeFoc(key){
               // this.bundleItems[key].override_amount     = ''
                this.computeTotalAmountPerItem(key)
            },
            getItems(){
                this.loaded = false
                this.$store.dispatch('getItems').then(response => {

                    this.items_list = this.GET_ITEMS
                    this.loaded = true
                });
            },
            selectModel(key, model_id) {
                if(model_id != null && model_id != ''){

                    this.$store.dispatch('getItemEditInfo',model_id).then(response => {
                        let data = this.GET_ITEM_EDIT_INFO
                        this.bundleItems[key].item.name           = data.name
                        this.bundleItems[key].item.model          = data.model
                        this.bundleItems[key].item.selling_price  = data.selling_price
                        this.bundleItems[key].item.sp_price       = data.sp_price
                        this.bundleItems[key].item.item_uom_text  = data.item_uom_text
                        this.bundleItems[key].item.type_id        = data.type_id
                        this.bundleItems[key].item.item_type_text = data.item_type_text
                        this.computeTotalAmountPerItem(key)
                    }).catch(error => {

                    });
                }
                $("input").blur();
            },
            computeTotalAmountPerItem(key){
                var final_price = this.bundleItems[key].item.selling_price.replace(/,/g, '');

                if(this.bundleItems[key].foc == "1"){
                    this.bundleItems[key].total_amount = (0).toFixed(2)
                }else{
                    if(this.bundleItems[key].quantity != ''){
                        if(this.bundleItems[key].item_id != null && this.bundleItems[key].item_id != ''){
                            this.bundleItems[key].total_amount = parseFloat(final_price) * parseInt(this.bundleItems[key].quantity)
                            this.bundleItems[key].total_amount = this.thousandSeprator((Math.round(this.bundleItems[key].total_amount*100)/100).toFixed(2))
                        }
                    }else{
                        this.bundleItems[key].total_amount = '' //do not remove, need this to trigger change in override
                        this.bundleItems[key].total_amount = (0).toFixed(2)
                    }
                }
            },
            showAddBundle(){
                this.add_edit_bundle_dialog = true
                this.action = 'Add'
            },
            addNewBundle(){
                this.errors = []
                let payload = {
                    item_id:            this.item_id,
                    is_active:          this.is_active,
                    bundleItems:        this.bundleItems
                }
                this.$store.dispatch('storeItemBundle',payload).then(response => {
                    let data = this.GET_STORE_ITEM_BUNDLE

                    this.resetFields()
                    this.add_edit_bundle_dialog = false

                    this.action = 'success'
                    // swal.fire("", data.msg, "success");
                }).catch(error => {
                    // if(error.response.status == 422){
                    //     this.errors = error.response.data.errors
                    // }

                });
            },
            showEditModal(value){
                this.action = 'Edit'
                this.editBundleId = value
                this.loaded = false

                if(this.editBundleId != null && this.editBundleId > 0){

                    this.$store.dispatch('getItemBundle',this.editBundleId).then(response => {
                        let data = this.GET_ITEM_BUNDLE

                        this.item_id                        = data.item_id
                        this.item.model                     = data.item_model
                        this.item.name                      = data.item_name
                        this.bundleItems                    = data.bundle_items
                        this.is_active                      = data.active
                        this.loaded = true
                        this.add_edit_bundle_dialog   = true

                    }).catch(error => {

                    });
                }
            },
            editBundle(){
                this.errors = []
                //console.log(this.bundle);
                let payload = {
                    item_id:            this.item_id,
                    is_active:          this.is_active,
                    bundleItems:        this.bundleItems,
                    item_ids:            this.editBundleId,
                }

                this.$store.dispatch('updateItemBundle',payload).then(response => {

                    let data = this.GET_UPDATE_ITEM_BUNDLE

                    if(data.error){
                        swal.fire("", data.error, "warning");
                        return false
                    }
                    this.resetFields()
                    this.add_edit_bundle_dialog = false
                    this.editBundleId= 0
                    this.action = 'success'
                    swal.fire('','Edit Bundle Succesfully','success');
                }).catch(error => {
                    swal.fire('','Edit Bundle Failed','success');
                });
            },
            resetFields(){
                this.errors              = []
                this.item_id             = ''
                this.is_active           = 1
                this.bundleItems         = []
                this.item    = {
                    model: '',
                    name: '',
                    item_uom_text: '',
                }
            },
            addKeyListener(){
                window.addEventListener("keyup", function(e) {
                    var keypressed = String.fromCharCode(e.keyCode)
                    if(e.ctrlKey && e.keyCode == 32){
                        this.addBundleItem()
                        $("input").blur();

                    }else if(keypressed == '-'){
                        this.removeBundleItem()
                        $("input").blur();
                    }

                }.bind(this));
            },
        },
        mounted: function (){
            this.addKeyListener()
             $('input').attr('autocomplete','off');
        },
        watch:{
            add_edit_bundle_dialog:function(){
                if(this.add_edit_bundle_dialog == true){
                    this.getItems();
                    if(this.action == 'Add'){
                        this.resetFields()

                        this.loaded = true
                    }
                }

                if(this.bundleItems.length == 0){
                    this.addBundleItem();
                }
            },

        },
    };
</script>
<style>
    .v-text-field.v-text-field--enclosed .v-text-field__details{
        display: none;
    }
    .centered-input input {
        text-align: center;
    }
    .right-input input {
        text-align: right;
    }
</style>
