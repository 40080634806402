<template>
    <v-container fluid>
        <h3 class="page-title">Spare Parts Staging Area</h3>
        <v-container class="ma-0">
            <v-app id="item-tabs">
                <v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" background-color="#424242" :loaded="loaded" dark show-arrows>
					<v-tabs-slider color="cyan" :loaded="loaded"></v-tabs-slider>
                    <v-tab v-for="(item, index) in items" :key="item" @click="tabs(index)" :loaded="loaded">
                        {{ item }}
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item  v-for="(items,index) in area_component" :key="index">
                        <keep-alive>
                            <SparePartsStagingAreaComponent :is="items.components" :area_component="area_component" :isActive_tab="items.status_text" :tab="index" :loaded="loaded"></SparePartsStagingAreaComponent>
                        </keep-alive>
                    </v-tab-item>
                </v-tabs-items>
            </v-app>
        </v-container>
            <DialogComponent :cp="components"  :scrollable="dialog_scrollable" :width="height(dialog_width)" :retain-focus="false" :loaded="loaded"></DialogComponent>
    </v-container>
</template>

<script>
import DialogComponent from '@/views/main/layouts/dialogs/Dialog.vue';

import SparePartsStagingAreaComponent from "@/views/main/modules/Services/Views/SparePartsStagingAreaComponent/SparePartsStagingAreaComponent.vue";

import SparePartsAreaSQViewComponent from '@/views/main/layouts/dialogs/Service/SparePartsArea/SparePartsAreaSQViewComponent.vue';
import SparePartsAreaSOViewComponent from '@/views/main/layouts/dialogs/Service/SparePartsArea/SparePartsAreaSOViewComponent.vue';
import SparePartsAreaInventoryViewComponent from '@/views/main/layouts/dialogs/Service/SparePartsArea/SparePartsAreaInventoryViewComponent.vue';

import { mapGetters } from 'vuex';
export default {
    components:{
		DialogComponent,  
        SparePartsStagingAreaComponent,
        SparePartsAreaSQViewComponent,
        SparePartsAreaSOViewComponent,
        SparePartsAreaInventoryViewComponent
    },
    data(){
        return {
            components:'',
            items: [
                "SQ", 
                "SO",
                // "Inventory"
            ],
            area_component:[
                {
                components: SparePartsStagingAreaComponent,
                status_text: "SQ",
                dialog: SparePartsAreaSQViewComponent,
                dialog_title: 'SQ',
                dialog_width: "75%",
                scrollable:true,
                dispatch:{
                        get: ['getSqArea', 'getSqItemArea'],  // ['url']
                        view: ['viewSqItem', 'viewSq'],
                        // update:['itemsPost', 'update-item-template'],
                        // delete:['itemsPost', 'delete-item-template'],
                    },
                },
                {
                components: SparePartsStagingAreaComponent,
                status_text: "SO",
                dialog: SparePartsAreaSOViewComponent,
                dialog_title:'SO',
                dialog_width: "75%",
                scrollable:true,
                dispatch:{
                        get:['getSoArea', 'getSoItemArea'],
                        view: ['viewSoItem', 'viewSo'],
                        // add:['servicePost', 'add-template'],
                        // update:['servicePost','update-template'],
                        // delete:['servicePost', 'destroy-template']
                    },
                },
                // {
                // components: SparePartsStagingAreaComponent,
                // status_text: "Inventory",
                // dialog: SparePartsAreaInventoryViewComponent,
                // dialog_title:'Inventory',
                // dialog_width: "75%",
                // scrollable:true,
                // dispatch:{
                //         get:['getInventoryArea', 'getInventoryItemArea'],
                //         view: ['viewInventoryItem', 'viewInventory'],
                //         add:['servicePost', 'addchecklist'],
                //         update:['servicePost', 'updatechecklist'],
                //         delete:['servicePost', 'destroy-checklists']
                //     },
                // },
            ],

            dialog_width: "",
            loaded: false,
            options: {},
            itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
            itemsPerPage: 10,
            tab: null,
            dialog_scrollable:true,
        }
        
    },
    methods: {
        changeTab(index){
            this.$store.commit('SPARE_PARTS_AREA_TAB', index)
        },

        tabs(key) {
            this.components = this.area_component[key].dialog;
			this.dialog_width = this.area_component[key].dialog_width;
			this.dialog_scrollable = this.area_component[key].scrollable;

            this.$store.commit('REFRESH_SP_AREA', true)
			this.$store.commit('SP_AREA_DIALOG_TITLE',this.area_component[key].dialog_title)
			this.$store.commit('SPARE_PARTS_AREA_TAB',this.area_component[key].status_text)
			this.$store.commit('SP_AREA_DISPATCH',this.area_component[key].dispatch)
            
			// this.$store.commit('TAB',this.area_component[key].getters)
		},

        height(width) {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs': return "100%"
				case 'sm': return "100%"
				case 'md': return width
				case 'lg': return width
				case 'xl': return width
			}
		},
    },

    computed: {
        ...mapGetters([
            'GET_SPARE_PARTS_STAGING_AREA',
            'GET_REFRESH_SP_AREA',
            'GET_SPARE_PARTS_AREA_TAB',
            'GET_SP_AREA_DISPATCH',
            'GET_SP_AREA_DIALOG_TITLE',
        ]),
    },

    mounted() {
        this.tabs(0)
        // this.$store.dispatch('getListTemplate',{code:'SR'})
    }

}
</script>

<style scoped>
#group-tab {
		padding: 0 !important;
	}
</style>
