<template>
    <sales-order-component :service="service"></sales-order-component>
</template>
<script>
import SalesOrderComponent from '@/views/main/modules/Sales/SalesOrdersNew/SalesOrderGroupComponent.vue';

export default {
    data() {
        return {
            tab: null,
            service:this.$router.currentRoute.meta.service
        }
    },
    components: {
        SalesOrderComponent,
    },
}
</script>
