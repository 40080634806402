<template>
    <div>
        <!-- {{ ADMIN_UNTAGGED_PO_ASSETS }} -->
        <v-dialog v-model="dialog.value" width="2000px" persistent>
            <v-card>
                <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                    <span class="text-h5">Print Asset tag</span>
                    <v-row class="m-0">
                        <v-col cols="pull-right-10 p-2">
                            <v-btn
                                text
                                icon
                                small
                                color="gray"
                                class="float-right"
                                @click="resetField()"
                            >
                            <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-title>
                    <v-card-text>
                        <v-card elevation="0" class="p-0">
                            <v-card-title>
                                <v-row>
                                    <!-- <v-col lg="3" class="my-3">
                                        <v-autocomplete
                                            v-model="selections.admin_purchase_orders.value"
                                            :items="selections.admin_purchase_orders.selection"
                                            :loading="selections.admin_purchase_orders.loading"
                                            :disabled="selections.admin_purchase_orders.loading"
                                            label="Select PO#"
                                            item-text="po_num"
                                            outlined
                                            dense
                                            hide-details
                                            clearable
                                            @change="updateItems"
                                            >

                                        </v-autocomplete>
                                    </v-col> -->
                                </v-row>
                            </v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-spacer></v-spacer>
                                    <v-col lg="1" class="d-flex justify-content-center align-items-center">
                                        <v-btn icon dark small color="primary" style="height: 20px; width: 20px"
                                        @click="addForm()">
                                        <v-icon>
                                            mdi-plus-circle
                                        </v-icon>
                                    </v-btn>
                                    <v-btn icon dark small color="primary" class="ml-1"
                                        style="height: 20px; width: 20px" @click="removeForm()">
                                        <v-icon>
                                            mdi-minus-circle
                                        </v-icon>
                                    </v-btn>
                                    </v-col>
                                </v-row>
                                <v-form ref="firstForm" v-model="firstForm">
                                    <v-row v-for="(item,index) in forms" :key="index">
                                        <v-col lg="3">
                                            <v-autocomplete
                                                v-model="item.admin_po_item_id"
                                                :items="selections.admin_items.selection"
                                                :loading="selections.admin_items.loading"
                                                item-text="text"
                                                item-value="admin_po_item_id"
                                                label="Select Item"
                                                hide-details
                                                disabled
                                                clearable
                                                dense
                                                outlined
                                                @change="updateItemDetails(index)"
                                            >
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col lg="3">
                                            <v-text-field
                                                v-model="item.description"
                                                :rules="[rules.required]"
                                                label="Desciption"
                                                hide-details
                                                dense
                                                outlined
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col lg="2">
                                            <v-text-field
                                                v-model="item.asset_code"
                                                :rules="[rules.required]"
                                                label="Asset Tag"
                                                hide-details
                                                dense
                                                outlined
                                            >
                                                <!-- <template #append>
                                                    <v-menu offset-y :close-on-content-click="false">
                                                        <template v-slot:activator="{ on, attrs }">
                                                          <v-btn
                                                            color="primary"
                                                            dark
                                                            text
                                                            v-bind="attrs"
                                                            v-on="on"
                                                          >
    
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                  <v-icon v-bind="attrs" v-on="on">mdi-format-list-bulleted-square</v-icon>
                                                                </template>
                                                                <span>View Asset Tags</span>
                                                              </v-tooltip>
                                                          </v-btn>
                                                        </template>
                                                        <v-list style="height:500px;overflow-y: scroll;">
                                                          <v-list-item
                                                            v-for="(i, index) in item.tags"
                                                            :key="index"
                                                          >
                                                            <v-list-item-title>
                                                                <v-text-field v-model="i.asset_code" outlined>
                                                                    <template #prepend>
                                                                        {{index}}.
                                                                    </template>
                                                                </v-text-field>
                                                            </v-list-item-title>
                                                          </v-list-item>
                                                        </v-list>
                                                      </v-menu>
                                                    <v-tooltip bottom>
                                                    </v-tooltip>
                                                  </template> -->
                                            </v-text-field>
                                        </v-col>
                                        <v-col lg="2">
                                            <v-text-field
                                                v-model="item.serial"
                                                :rules="[rules.required]"
                                                label="Serial"
                                                hide-details
                                                dense
                                                outlined
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col lg="1">
                                            <v-autocomplete
                                                v-model="item.status"
                                                :items="selections.status.selection"
                                                :rules="[rules.required]"
                                                label="Status"
                                                hide-details
                                                dense
                                                outlined
                                            >
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col lg="1" class="d-flex justify-content-center align-items-center">
                                            <v-btn icon dark small color="primary" class="ml-1"
                                            style="height: 20px; width: 20px" @click="removeForm(index)">
                                            <v-icon>
                                                mdi-minus-circle
                                            </v-icon>
                                        </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                <v-card-actions class="d-flex justify-content-center align-items-center">
                    <v-btn @click="submit" small color="orange" :loading="loading">
                        Print & submit
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <batchAdditionCopyPastComponentVue :dialog="batchAddition.dialog" @closeDialog="retrieveData" :headers="['Item Name','Quantity']"></batchAdditionCopyPastComponentVue>
        <ConfirmOverrideComponent
        :approve_override_dialog="override.override_dialog"
        :departments_allowed="override.override_departments_allowed"
        :heading="''"
        @closeConfirmOverride="closeConfirmOverride"
        ></ConfirmOverrideComponent>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue'
import batchAdditionCopyPastComponentVue from '@/views/main/layouts/dialogs/Operations/General/batchAdditionCopyPastComponent.vue'
import ConfirmOverrideComponent from '@/views/main/Utils/ConfirmOverrideComponent.vue';

export default {
    mixins:[SharedFunctionsComponentVue],
    props:{
        trigger_dialog:{
            default:()=>false,
            type:Boolean
        }
    },
    components: {
        batchAdditionCopyPastComponentVue,
        ConfirmOverrideComponent
    },
    data() {
        return{
            override: {
                override_dialog: false,
                errors: [],
                override_departments_allowed: ['admin'],
                override_authenticated: false,
            },
            batchAddition:{
                dialog:false
            },
            dialog:{
                value:false,
                actions:''
            },
            loading:false,
            default:{
                asset_num:null,
                asset_code:null,
                admin_purchase_order_item_id:0,
                admin_po_item_id:0,
                tags:[],
                description:''
                // name:null,
                // item_id:null,
                // status:5,
                // active:1,
                // serial:null,
                // classification_id:null,
                // sub_classification_id:null,
                // description:null,

            },
            form:{},
            forms:[],
            selections:{
                admin_purchase_orders:{
                    selection:[],
                    value:[],
                    loading:true
                },
                status:{
                    selection:[],
                    value:'',
                    loading:true
                },
                admin_items:{
                    selection:[],
                    loading:true
                }
            },
            rules: {
                required: value => !!value || 'Required.',
                positiveNumber: value => (value > 0) || 'Must be a positive number.',
            },
            firstForm: false,
            secondForm: false,
        }
    },
    methods:{
        async retrieveData(data){
            if(data.length >  0){
                data = data.map(e=>{
                    let obj = {
                        item_name:e[0],
                        quantity:e[1],
                    }
                    e = obj
                    return e
                })
                await this.$store.dispatch('postAccountabilityWithoutAsset',{
                    url:'check-item-consumable',
                    admin_items:data.map(e=>e.item_name),
                    warehouse: this.form.warehouse_id,
                }).then(response=>{
                    this.batchAddition.data = response
                    this.batchAddition.data.forEach(e =>{
                        e.quantity = data.filter(a => a.item_name.toUpperCase() == e.admin_item_name.toUpperCase())[0]?.quantity
                    })
                    this.addItem(3)
                })

            }
            this.batchAddition.dialog = false
        },
        addForm(){
            this.forms.push({
                admin_purchase_order_item_id:0,
                admin_po_item_id:0,
                tags:[],
                asset_num:null,
                description:'',
                asset_code:null,})
        },
        removeForm(index = null){
            if(index === null){
                if(this.forms.length != 1)
                    this.forms.pop();
            }else{
                if(this.forms.length != 1){
                    this.forms = this.forms.filter((e,i)=>{
                        if(i != index){
                            return e
                        }
                    })
                }
            }

            if(this.forms.length){
                this.selection.admin_purchase_orders.value = ''
            }
        },

        resetField(){
            this.form = this.default
            this.forms = [{
                asset_num:null,
                asset_code:null,
                admin_purchase_order_item_id:0,
                admin_po_item_id:0,
                description:'',
                tags:[],
            }]
            this.selections.admin_purchase_orders.value = ''
            this.dialog.value = false
            this.$store.commit('SELECTED',{})
            this.$emit('closeDialog')
        },
        submit(){
            if(this.$refs.firstForm.validate()){
                if(this.GET_ADMIN_TAB_IS_ACTIVE == 'assetslist'){
                    this.override.override_dialog = true
                    return
                }
                Swal.fire({
                    title:'Print Assets',
                    text:'- Are you sure you want to print assets ?',
                    icon:'warning',
                    showCancelButton:true,
                    reverseButtons:true,
                }).then(action=>{
                    if(action.isConfirmed){
                        this.loading = true
                        this.$store.dispatch('adminAssetManagementPost',{
                            url:'create-admin-asset-tags',
                            forms:this.forms
                        }).then(response=>{
                            this.loading = false
                            this.print();
                            Swal.fire('Success!','','success')
                            this.resetField()
                        }).catch(e=>{
                            console.log(e)
                            Swal.fire('Error!','- an error occured','error')
                            this.loading = false
                        })
                    }
                })
            } else {
                Swal.fire('Missing Fields!','','warning');
            }
        },
        print(){
            const workbook = new this.$exceljs.Workbook();
            let worksheet = workbook.addWorksheet('worksheet');
            worksheet.columns = [
                { header:'Asset Codes',key:'asset_code' },
            ]

            this.forms.forEach(e=>{
                worksheet.addRow(e)
            })
            let exportName = `Asset Tages`
            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                this.$filesaver.saveAs(blob, `${exportName}.xlsx`);
            });
        },
        async updateItems(po_id){
            this.forms = [{
                    asset_num:null,
                    asset_code:null,
                    admin_purchase_order_item_id:0,
                    admin_po_item_id:0,
                    description:'',
                    tags:[],
                }]

            this.forms = this.selections.admin_items.selection.filter(e=>{
                if(e.po_id == po_id){
                    e.asset_num = 0
                    e.asset_code = ''
                    e.tags = []
                    return e
                }

            })

            // distribute items base on untagged qty
            // let item_handler = []

            // this.forms.forEach(e=>{
            //     for(let i = 1; i <= parseInt(e.pending_untagged); i++){
            //         item_handler.push(e)
            //     }
            // })

            // this.forms = item_handler;

            if(this.forms.length == 0){
                this.forms = [{
                    asset_num:null,
                    asset_code:null,
                    admin_purchase_order_item_id:0,
                    admin_po_item_id:0,
                    description:'',
                    tags:[],
                }]

                return
            }

            for(let i = 0; i < this.forms.length; i++){
                await this.updateItemDetails(i)
            }
            this.$forceUpdate()
        },
        async getDropDowns(){

            this.selections.admin_items.selection = await this.loadDropDown('get-untagged-po-admin-items-selection')
            // this.selections.admin_purchase_orders.selection = await this.loadDropDown('get-received-admin-purchase-orders')
            this.selections.admin_items.loading = false
            this.selections.admin_purchase_orders.loading = false
            this.$forceUpdate();
        },
        async closeConfirmOverride(user_id, authenticated = false) {
                if (authenticated) {
                    this.$store.dispatch('adminAssetManagementPost',{
                        url:'create-admin-asset-tags',
                        forms:this.forms,
                        reprint:1
                    }).then(response=>{
                        this.loading = false
                        this.print();
                        this.override.override_dialog = false
                        Swal.fire('Success!','','success')
                        this.resetField()
                    }).catch(e=>{
                        this.override.override_dialog = false
                        Swal.fire('Error!','- an error occured','error')
                        this.loading = false
                    })
                } else {
                    this.override.override_dialog = false
                }
            },

        async updateItemDetails(index){

            Object.assign(this.forms[index],this.selections.admin_items.selection.find(e=>e.admin_po_item_id == this.forms[index].admin_po_item_id))

            this.forms[index].status = parseInt(this.forms[index].status)
            await this.$store.dispatch('adminAssetManagementGet',{
                url:'latest-asset-num/' + this.forms[index].classification_id,
            }).then(response =>{

                const currentDate = new Date();
                let check_existing_code = []

                this.forms.filter(e=>{
                    if(e.classification_code == this.forms[index].classification_code){
                        check_existing_code = check_existing_code.concat(e.tags)
                    }
                });

                let latest_asset_num = 1

                if(check_existing_code.length > 0){
                    latest_asset_num = check_existing_code.sort((a, b) => b.asset_num - a.asset_num)[0].asset_num;
                    latest_asset_num = parseInt(latest_asset_num) + 1
                }

                if(response.data){
                    let ideal_asset_code = response.data.asset_num.split('-')
                    ideal_asset_code = ideal_asset_code.pop()

                    //check if meron ng existing na classification para sa increment
                    if(check_existing_code.map(e=>e.classification_code == this.forms[index].classification_code).length > 0){
                        this.forms[index].asset_num = parseInt(response.data.asset_num) + latest_asset_num
                    }else{
                        this.forms[index].asset_num = parseInt(response.data.asset_num) + 1
                    }

                    this.forms[index].asset_code = [...ideal_asset_code,parseInt(this.forms[index].asset_num).toString().padStart(4,'00')].join('-')

                }else{
                    if(check_existing_code.map(e=>e.classification_code == this.forms[index].classification_code).length > 0){
                        this.forms[index].asset_num = latest_asset_num

                    }else{
                        this.forms[index].asset_num = 1
                    }

                    this.forms[index].asset_code = `${this.forms[index].classification_code} - ${currentDate.getFullYear()} - ${parseInt(this.forms[index].asset_num).toString().padStart(4,'00')}`
                }

                if(parseInt(this.forms[index].pending_untagged) > 0){
                    for(let i = 0; i <= parseInt(this.forms[index].pending_untagged); i++){
                        this.forms[index].tags.push({
                            asset_code:`${this.forms[index].classification_code} - ${currentDate.getFullYear()} - ${parseInt(this.forms[index].asset_num + i).toString().padStart(4,'00')}`,
                            classification_code:this.forms[index].classification_code,
                            asset_num:this.forms[index].asset_num + i

                        })
                    }
                }
            }).catch(err=>{
                console.log(err)
            })
            this.$forceUpdate()
            return
        }
    },
    beforeMount(){
        this.$store.dispatch('getStatus')
    },
    mounted(){
        this.form = this.default;
        this.forms.push(this.default)
        this.getDropDowns()
    },
    computed:{
        ...mapGetters([
            'ADMIN_ASSET_MANAGEMENT',
            'GET_ADMIN_TAB_IS_ACTIVE',
            'GET_STATUS_SELECTION',
            'SELECTED'
        ])
    },
    watch:{
        trigger_dialog:{
            handler(val){
                if(val){
                    if(Object.keys(this.SELECTED).length === 0){
                        Swal.fire('Error!','- Please select item to tag','error')
                        this.resetField()
                        return
                    }else{
                        this.SELECTED.forEach(element => {
                            this.forms.push(element)

                        });
                        this.forms.shift();
                        this.$forceUpdate();
                    }
                }

                this.dialog.value = val
            }
        },
        GET_STATUS_SELECTION:{
            handler(val){
                this.selections.status.selection = val.filter(e=>{
                    if(e.department_id == 1){
                        return e
                    }
                })
            }
        },
    }
}
</script>

<style>

</style>
