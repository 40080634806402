<template>
    <v-container fluid class="pa-3">
        <v-row class="w-100 pl-4 pt-2">
            <h3 class="page-title"> Store Flow</h3>
        </v-row>
        <v-container class="ma-0">
            <v-app id="item-tabs">
                <v-tabs id="group-tab" v-model="tab" color="cyan" slider-color="cyan" background-color="#424242" dark show-arrows>
                    <v-tab v-for="(item, index) in storeFlowComponent" :key="index" ripple>
                        {{ item.tabName }}
                    </v-tab>
                    <!-- <v-spacer />
                    <v-tab ripple>
                        Stores
                    </v-tab> -->
                </v-tabs>
                <v-tabs-items v-model="tab" touchless>
                    <v-tab-item v-for="(item,index) in storeFlowComponent" :key="index">
                        <keep-alive>
                            <component v-bind:is="item.componentName" :tabName="item.tabName"></component>
                        </keep-alive>
                    </v-tab-item>
                    <!-- <v-tab-item>
                        <keep-alive>
                            <component v-bind:is="'StoresComponent'"></component>
                        </keep-alive>
                    </v-tab-item> -->
                </v-tabs-items>
            </v-app>
        </v-container>
    </v-container>
</template>

<script>
import StoreFlowDialogComponent from '@/views/main/modules/Stores_/StoreFlow/StoreFlowDialogComponent.vue';
import StoreFlowComponent from '@/views/main/modules/Stores_/StoreFlow/StoreFlowComponent.vue';
// import StoresComponent from '@/views/main/modules/Stores_/StoreFlow/StoresComponent.vue';

export default {
    components: {
        StoreFlowComponent,
        StoreFlowDialogComponent,
        // StoresComponent,
    },
    data() {
        return {
            tab:0,
            storeFlowComponent:[
                {
                    componentName: 'StoreFlowComponent',
                    tabName: 'Store Flow',
                },
            ],
            storeDateDialog: false
        }
    },
    methods: {
        closeStoreDateDialog() {
            this.storeDateDialog = false;
        }
    }
}
</script>