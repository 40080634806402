<template>
    <v-container fluid>
        <h3 class="page-title"> Auto FOC </h3>
        <v-container class="ma-0">
            <v-app id="item-tabs">
                <v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" background-color="#424242" :loaded="loaded" dark show-arrows>
					<v-tabs-slider color="cyan" :loaded="loaded"></v-tabs-slider>
                    <v-tab v-for="(item, index) in items" :key="item" @click="tabs(index)" :loaded="loaded">
                        {{ item }}
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item  v-for="(items,index) in foc_component" :key="index">
                        <keep-alive>
                            <AutoFocComponent :is="items.components" :foc_component="foc_component" :isActive_tab="items.status_text" :tab="index" :loaded="loaded"></AutoFocComponent>
                        </keep-alive>
                    </v-tab-item>
                </v-tabs-items>
            </v-app>
        </v-container>
        <DialogComponent :cp="components"  :scrollable="dialog_scrollable" :width="height(dialog_width)" :retain-focus="false" :loaded="loaded"></DialogComponent>
    </v-container>
</template>

<script>
import DialogComponent from '@/views/main/layouts/dialogs/Dialog.vue';

import AutoFocComponent from "@/views/main/modules/Services/Views/AutoFocComponent/AutoFocComponent.vue";
import AutoFocItemsViewComponent from '@/views/main/layouts/dialogs/Service/FOC/AutoFocItemsViewComponent.vue';

import { mapGetters } from 'vuex';
export default {
    components: {
        DialogComponent,
        AutoFocComponent, 
        AutoFocItemsViewComponent, 
    },

    data(){
        return {
            components:'',
            items: ["SP Items"],
            foc_component:[
                {
                components: AutoFocComponent,
                status_text: "sp_item",
                dialog: AutoFocItemsViewComponent,
                dialog_title: 'SP Item',
                dialog_width: "35%",
                scrollable:true,
                dispatch:{
                        get: 'getAutoFocSp', 
                        update: 'updateAutoFoc',
                        delete: 'deleteAutoFoc',
                    },
                },
            ],

            dialog_width: "",
            loaded: false,
            options: {},
            itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
            itemsPerPage: 10,
            tab: null,
            dialog_scrollable:true,
        }
    },

    methods: {
        changeTab(index){
            this.$store.commit('FOC_TAB', index)
        },

        tabs(key) {
			this.components = this.foc_component[key].dialog;
			this.dialog_width = this.foc_component[key].dialog_width;
			this.dialog_scrollable = this.foc_component[key].scrollable;
			
            this.$store.commit('REFRESH_FOC', true)
			this.$store.commit('FOC_DIALOG_TITLE',this.foc_component[key].dialog_title)
			this.$store.commit('FOC_TAB',this.foc_component[key].status_text)
			this.$store.commit('FOC_DISPATCH',this.foc_component[key].dispatch)
            
			// this.$store.commit('FOC_TAB',this.foc_component[key].getters)
		},

        height(width) {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs': return "100%"
				case 'sm': return "100%"
				case 'md': return width
				case 'lg': return width
				case 'xl': return width
			}
		},
    },


    computed: {
        ...mapGetters([
            'GET_FOC_TAB',
            'GET_AUTO_FOC_SP',
            'GET_FOC_DISPATCH',
            'GET_FOC_DIALOG_TITLE',
            'GET_REFRESH_FOC'
        ]),
    },

    mounted() {
        this.tabs(0)
        // this.$store.dispatch('getAutoFocSp', {code: 'SP'})
    }
}
</script>

<style scoped>
#group-tab {
		padding: 0 !important;
	}
</style>