import MasterdataUsers from '@/views/main/modules/MasterData/UsersComponent.vue'
import masterdataDepartment from '@/views/main/modules/MasterData/DepartmentsComponent.vue'
import masterdataDepartmentAccess from '@/views/main/modules/MasterData/DepartmentAccessComponent.vue'
import masterdataRoles from '@/views/main/modules/MasterData/RolesComponent.vue'
import masterdataVehicles from '@/views/main/modules/MasterData/VehiclesGroupComponent.vue'
import masterdataWarehouse from '@/views/main/modules/MasterData/WarehouseComponent.vue'
import masterdataSalesman from '@/views/main/modules/MasterData/SalesmanComponent.vue'
import masterdataCustomer from '@/views/main/modules/MasterData/CustomerComponent.vue'
import masterdataVendor from '@/views/main/modules/MasterData/VendorComponent.vue'
import masterDataCompanies from '@/views/main/modules/MasterData/CompaniesComponent.vue'
import masterStoreBranch from '@/views/main/modules/MasterData/StoreBranches/StoreBranchesGroupComponent.vue'
import masterdataCluster from '@/views/main/modules/MasterData/ClusterComponent.vue'
import masterdataReportModule from '@/views/main/modules/MasterData/ReportModuleComponent.vue'
import masterdataVatType from '@/views/main/modules/MasterData/VatTypeComponent.vue'
import Approvers from '@/views/main/modules/MasterData/Approvers/ApproversGroupComponent.vue'
import APSettings from '@/views/main/modules/MasterData/Settings/AccountingAP/AccountsPayableGroupComponent.vue'

import repository from '@/api/credentials'

const routes = [{
    path: '/masterdata/users-list/:id',
    name: 'masterdatausers',
    props: true,
    component: MasterdataUsers,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Users',
    },
},
{
    path: '/masterdata/department/:id',
    name: 'masterdatadepartments',
    component: masterdataDepartment,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Departments',
    },
},
{
    path: '/masterdata/department-access/:id',
    name: 'masterdatadepartmentaccess',
    component: masterdataDepartmentAccess,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Department Access',
    },
},
{
    path: '/masterdata/roles/:id',
    name: 'masterdataroles',
    component: masterdataRoles,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })

    },
    meta: {
        requiresAuth: true,
        title: 'Roles',
    },
},
{
    path: '/masterdata/vehicles/:id',
    name: 'masterdatavehicles',
    component: masterdataVehicles,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Vehicles',
    },
},
{
    path: '/masterdata/warehouse/:id',
    name: 'masterdatawarehouse',
    component: masterdataWarehouse,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Warehouses',
    },
},
{
    path: '/masterdata/salesman/:id',
    name: 'masterdatasalesman',
    component: masterdataSalesman,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Salesman',
    },
},
{
    path: '/masterdata/customer/:id',
    name: 'masterdatacustomer',
    component: masterdataCustomer,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Customers',
    },
},
{
    path: '/masterdata/vendor/:id',
    name: 'masterdatavendor',
    component: masterdataVendor,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Vendors',
    },
},
{
    path: '/masterdata/companies/:id',
    name: 'masterdatacompanies',
    component: masterDataCompanies,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Companies',
    },
},
 {
     path: '/masterdata/cluster/:id',
    name: 'masterdatacluster',
    component: masterdataCluster,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Clusters',
    },
},
{
    path: '/masterdata/store-branches/:id',
    name: 'masterdatastorebranches',
    component: masterStoreBranch,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Store Branches',
    },
},
{
    path: '/masterdata/vat-type/:id',
    name: 'masterdatavattype',
    component: masterdataVatType,
    beforeEnter: (to, from, next) => {
       repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
       }).catch(() => {})
    },
    meta: {
        requiresAuth: true,
        title: 'Vat Types',
    },
},
{
    path: '/masterdata/approvers/:id',
    name: 'approvers',
    component: Approvers,
    beforeEnter: (to, from, next) => {
       repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
       }).catch(() => {})
    },
    meta: {
        requiresAuth: true,
        title: 'Approvers',
    },
},
{
    path: '/masterdata/report-module/:id',
    name: 'masterdataReportModule',
    component: masterdataReportModule,
    beforeEnter: (to, from, next) => {
    repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Report Module',
    },
},
{
    path: '/masterdata/accounting-ap-settings/:id',
    name: 'apsettings',
    component: APSettings,
    beforeEnter: (to, from, next) => {
       repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'AP Settings',
    },
},
];

export default routes;
