<template>
<v-app>
    <v-container fluid >
        <h3 class="page-title">General Settings</h3>
        <v-container class="ma-0">
            <v-card >
                <v-card-text style="height:800px" class="overflow-auto">
                    <v-container>
                        <v-row>
                            <v-col cols="12" lg="6">
                                <span class="font-weight-bold">Password Settings</span>
                                <PasswordDefaultList></PasswordDefaultList>
                            </v-col>
                            <v-col cols="12" lg="6">
                                <span class="font-weight-bold">BIO API Settings</span>
                                <BiometricAPIComponents></BiometricAPIComponents>
                            </v-col>
                        </v-row>
                        <hr data-content="Server Configuration" class="hr-text">
                        <v-row>
                            <v-col cols="12" lg="6">
                                <span class="font-weight-bold">Database Migration Settings</span>
                                <MigrationsSettings></MigrationsSettings>
                            </v-col>
                            <v-col cols="12" lg="4">
                                <span class="font-weight-bold">Optimize System</span>
                                <OptimizeSettings></OptimizeSettings>
                            </v-col>
                            <v-col cols="12" lg="12">
                                <span class="font-weight-bold">Server Logs</span>
                                <ServerLogsComponent></ServerLogsComponent>
                            </v-col>
                        </v-row>
                    </v-container>

                </v-card-text>
            </v-card>
        </v-container>
    </v-container>
</v-app>
</template>

  <script>
import PasswordDefaultList from '@/views/main/SystemSettings/OtherComponents/PasswordDefaultListComponent.vue'
import MigrationsSettings from '@/views/main/SystemSettings/OtherComponents/MigrationsSettingsComponent.vue'
import OptimizeSettings from '@/views/main/SystemSettings/OtherComponents/OptimizeSettingsComponent.vue'
  import { mapGetters } from 'vuex';
import BiometricAPIComponents from './OtherComponents/BiometricAPIComponents.vue';
import ServerLogsComponent from '@/views/main/SystemSettings/OtherComponents/ServerLogsComponent.vue'
  export default {
    components:{
        PasswordDefaultList,
        BiometricAPIComponents,
        MigrationsSettings,
        OptimizeSettings,
        ServerLogsComponent
    },
    data() {
        return {

        };
    },
    computed:{
        ...mapGetters([])
    },
    mounted(){

    },
    methods:{

    },
    watch:{

    }

  };
  </script>

