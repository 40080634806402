<template>
    <v-container>
        <v-row class="ma-0">
            <v-col cols="12" class="d-flex no-block align-items-center">
                <h4 class="page-title">Payments</h4>
            </v-col>
        </v-row>
            <v-tabs v-model="tab" id="group-tab" color="cyan" background-color="#424242" dark slider-color="cyan" class="mt-5" show-arrows>
                <v-tab ripple @click="setMenu('details')">Details</v-tab>
                <v-tab ripple @click="setMenu('allocations')">Allocations</v-tab>
                <v-tab ripple @click="setMenu('payment_types')">Payment Types</v-tab>
                <v-tab ripple @click="setMenu('unidentified')">Unidentified</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab" touchless style="background-color:unset" class="">
                <v-tab-item>
                    <CustomerPaymentsTableComponentVue :isUnidentified="false"/>
                </v-tab-item>
                <v-tab-item>
                    <PaymentAllocationTableComponentVue />
                </v-tab-item>
                <v-tab-item>
                    <PaymentTypesTableComponentVue />
                </v-tab-item>
                <v-tab-item>
                    <CustomerPaymentsTableComponentVue :isUnidentified="true"/>
                </v-tab-item>
            </v-tabs-items>
    </v-container>
</template>

<script>
import CustomerPaymentsTableComponentVue from './PaymentsComponent/CustomerPaymentsTableComponent.vue';
import PaymentAllocationTableComponentVue from './PaymentsComponent/PaymentAllocationTableComponent.vue';
import PaymentTypesTableComponentVue from './PaymentsComponent/PaymentTypesTableComponent.vue';

export default {
    data() {
        return {
            tab: null,
        }
    },
    mounted(){
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
        // this.checkAccess();
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id);
        this.$store.dispatch('getAllCustomers')
    },
    components: {
        CustomerPaymentsTableComponentVue,
        PaymentAllocationTableComponentVue,
        PaymentTypesTableComponentVue
    },
    methods: {
        setMenu(menu) {
            this.$store.commit('setPaymentsMenu', menu);
        },
        // checkAccess(){
        //     this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id);
        // },
    }
}
</script>

<style>

</style>
