<template>
  	<v-container style="height:75vh">
    	<v-row>
          	<v-col class="map" ref="mapContainer">
				<a href="https://www.maptiler.com/copyright/" target="_blank" style="position:absolute;left:10px;bottom:10px;z-index:999;">&copy; MapTiler</a> 
				<a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>
            	<div class="legend">
              		<header><h6 class="text-center font-weight-black mb-2">Legends</h6></header>
						<v-progress-linear
							:indeterminate="isIndeterminate"
							absolute
							color="primary"
						></v-progress-linear>
					<div class="scroll-content">
						<v-row v-for="(marker,i) in markers" :key="i" class="ma-0 pa-0 mb-1" style="align-items:center;">
							<v-col cols="2">
								<v-checkbox class="ma-0"
									v-model="marker.toggle"
									dense
									color="orange darken-3"
									hide-details
									@change="filterLegends(markers[i])"
									>
								</v-checkbox>
							</v-col>
							<v-col cols="2">
								<v-img 
									:src="host+marker.marker"
									max-height="23px"
									max-width="23px"
									class="">
								</v-img>
							</v-col>
							<v-col>
								<span class=" pl-1 ">
									{{marker.classification_name}}
								</span>
							</v-col>
						</v-row>  
					</div> 
            	</div>
          	</v-col>
        </v-row>
  </v-container>
</template>
  
  <script>
	import { Map,Marker,NavigationControl,Popup,FullscreenControl} from 'maplibre-gl';
	import { watch,shallowRef, onMounted, onUnmounted, markRaw, ref } from 'vue';
	//   import {Store} from 'vuex';

  import store from '@/store';
  import { mapGetters } from 'vuex';
  export default {
    name: "Map",
    data(){
        return{
			markers: store.state.CustomerMaps.legends,
			host:process.env.VUE_APP_API_HOST,
			isIndeterminate: false,
        }
    },
	computed:{
		...mapGetters([
			'MAP_REFRESH',
			'GET_TAB',
		])
	},
	methods:{
		filterLegends(marker){
			let payload = {
				id: marker.id,
				url: "updateToggle",
				toggle: marker.toggle,
				tab: 'map'
			}
			store.dispatch('postCustomerMaps',payload).then(response=>{
				store.commit("REFRESH_MAP", true);
			})
		},
		async renderLegends(){
			this.isIndeterminate = true;
			let payload = {
				url: 'getAllMapMarker',
				method: 'map',
				tab: 'marker'
			}
			await store.dispatch('getCustomerMaps',payload).then(response=>{
				// this.markers = response.data
				// store.commit('LEGENDS', response.data)
				store.commit("REFRESH_MAP", true);
				this.isIndeterminate = false;
			})
		},
	},
    setup () {
		
		const state = ref(
			 store.state.CustomerMaps,
		); 
		const legends = store.state.CustomerMaps.legends
		const mapContainer = shallowRef(null);
		const map = shallowRef(null);
		
		async function renderLegends(){
			let payload = {
				url: 'getAllMapMarker',
				method: 'map',
				tab: 'map'
			}
			await store.dispatch('getCustomerMaps',payload).then(response=>{
				store.commit('LEGENDS',response.data)
			})
		}
		async function renderPage(){	
			let marker = []
			const apiKey = 'nrb4ZIAEXB6zIDm95CmV';

			const initialState = { lng: 123.1168892, lat: 12.013136, zoom: 5.1 };
			const legend_map = legends.map(e=>{
				return e.classification_name
			})
			map.value = markRaw(new Map({
				container: mapContainer.value,
				style: `https://api.maptiler.com/maps/streets/style.json?key=${apiKey}`,
				center: [initialState.lng,initialState.lat],
				zoom: initialState.zoom
			}));
			map.value.addControl(new NavigationControl(), 'top-right');
			map.value.addControl(new FullscreenControl());
			const payload = {
				url: 'getAllCustomerMap',
				method: 'map',
				tab: 'map'
			};
			await store.dispatch("getCustomerMaps", payload).then((response) => {
				marker = response.data
				marker.forEach((e,i)=>{
					let image_path = ''
					if(legend_map.includes(e.classification_name)){
						legends.forEach(x=>{
							if(x.classification_name == e.classification_name){
								if(x.toggle == 1){
									image_path = process.env.VUE_APP_API_HOST+x.marker
								}
								else{
									image_path = ''
								}
							}
						})
					}
					let link = `https://www.google.com/maps/place/@${Number(e.latitude)},${Number(e.longitude)},19.62z/data=${e.data_location}`
					let el = document.createElement('div');
					el.className = 'marker';
					el.style.backgroundImage = `url('${image_path}')`
					el.style.backgroundSize = 'cover'
					el.style.width = '3vh';
					el.style.height = '3vh';
					let marker = new Marker(el)
						.setLngLat([Number(e.longitude),Number(e.latitude)])
						.setPopup(new Popup().setHTML("<b>"+e.customer_name+"</b><br/>"))
						.addTo(map.value)
						.setOffset([0, -5])
					el.addEventListener('mouseenter', function () {
						el.style.cursor = 'pointer'
						marker.togglePopup()
					});
					el.addEventListener('mouseleave', function () {
						marker.togglePopup()
					});
					el.addEventListener('click', function(){
						map.value.flyTo({
							center:[Number(e.longitude),Number(e.latitude)],
							zoom:19
						});
						setTimeout(function(){
							window.open(link,'_blank')
						}, 2500);
					})
				})
			}).catch((error) => {
				console.log(error);
			});
			var labelLayerId
		}
		watch(state.value, (currentValue, oldValue) => {
			if (currentValue.refresh_map){
				map.value?.remove();
				renderLegends()
				renderPage()
				store.commit('REFRESH_MAP',false)
			}
		});
      	onMounted(async() => {
			setTimeout(() => {
				renderLegends()
			}, 3000);
			setTimeout(() => {
				renderPage()
			}, 2000);
				renderPage()
		})
		onUnmounted(() => {
			map.value?.remove();
		})
		return {
			map, mapContainer 
		};
    },
	watch: {
		GET_TAB: {
			handler(val){
				store.commit("REFRESH_MAP", true);
				if(val == 2){
					this.renderLegends()
				}
				store.commit("REFRESH_MAP", false);
			}
		},
	}
  };
  </script>
  
  <style scoped>
  @import '~maplibre-gl/dist/maplibre-gl.css';
  
	.map {
		position: absolute;
		min-width: 100%;
		min-height: 100%;
	}
	.legend {
		position: absolute;
		top: 10px;
		left: 10px;
		background-color: rgba(255, 255, 255, 0.834);
		width: 210px;
		height: 50%;
		color: rgb(60, 60, 60);
		border: 1px solid #000000;
		z-index: 1000;
		border-radius: 5px;
		overflow-y: scroll;
	}
	.maplibregl-canvas, .mapboxgl-canvas{
		width: 100% !important; 
		height: 100% !important; 
	}
	header {
		position: sticky;
		top: 0;
		background-color: rgb(66, 66, 66);
		padding: 15px 10px;
		z-index: 1000;
		color:#F69F1A;
	}
	header::before {
		content: "";
		position: sticky;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgb(66, 66, 66); /* Set the background color to white */
	}
	.scroll-content {
		padding: 10px;
	}
	.legend::-webkit-scrollbar {
		width: 0;
		background: transparent;
	}
	.legend::-webkit-scrollbar-thumb {
	background: transparent;
	}
	.legend::-webkit-scrollbar-track {
	background: transparent;
	}

  </style>