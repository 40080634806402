<template>
    <v-form ref="form" lazy-validation>
      <v-card>
        <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
          <span class="text-h5">VIEW PURCHASE ORDER {{form.hasOwnProperty('id') ? '#: '+form.po_num : ''}}</span>
          <v-row class="m-0">
            <v-col cols="pull-right-10 p-2">
              <v-btn
                v-if="!loading"
                text
                icon
                small
                color="gray"
                class="float-right"
                @click="closeDialog()"
              >
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text style="height: 600px;" v-if="!loading">
            <v-container fluid class="pb-0">
                <slot name="type"></slot>
                <v-row>
                    <v-col cols="6" class="mb-2">
                       <SectionHeading :heading="'Company Details'"></SectionHeading>
                       <v-row>
                            <v-col cols="5" class="pa-4">
                                <v-autocomplete
                                    v-model="form.company_id"
                                    :items="GET_PV_COMPANIES_SELECTION"
                                    :disabled="ACTION == 'View'"
                                    item-text="text"
                                    item-value="value"
                                    auto-select-first
                                    dense
                                    outlined
                                    required
                                    @change="insertStore()"
                                >
                                    <template v-slot:label>
                                        <span>Company <span style="color: red;">*</span></span>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="5" class="pa-4">
                                <v-autocomplete
                                    v-model="form.store_branch_id"
                                    :items="branchItems"
                                    :disabled="ACTION == 'View'"
                                    item-text="text"
                                    item-value="value"
                                    auto-select-first
                                    dense
                                    outlined
                                    required
                                    @change="insertCompany(form.store_branch_id)"
                                >
                                    <template v-slot:label>
                                        <span>Branch <span style="color: red;">*</span></span>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                       </v-row>
                    </v-col>
                    <v-col cols="6" class="mb-2">
                        <SectionHeading :heading="'Requestor Details'"></SectionHeading>
                        <v-row>
                            <v-spacer></v-spacer>
                            <v-col cols="1" class="pa-4 ">
                                <v-checkbox
                                    v-model="form.billable"
                                    :true-value="1"
                                    :false-value="0"
                                    :disabled="ACTION == 'View'"
                                    style="margin-top: 4px"
                                    label="Billable"
                                    hide-details
                                ></v-checkbox>
                            </v-col>
                            <v-col cols="5" class="pa-4 ml-3">
                                <v-combobox
                                    v-model="form.po_requestor"
                                    :items="GET_CUSTOMERS_LIST_SELECTION"
                                    :disabled="ACTION == 'View' || !form.billable"
                                    label="Requestor"
                                    dense
                                    outlined
                                    hide-details
                                    auto-select-first
                                    @keypress="forceUpperCaseText($event)"
                                ></v-combobox>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="mb-2">
                       <SectionHeading :heading="'Vendor Details'"></SectionHeading>
                    </v-col>
                    <v-col cols="12" class="pa-4">
                        <v-row>
                            <v-col cols="3">
                                <v-autocomplete
                                    v-model="form.vendor_id"
                                    :items="GET_ADMIN_SUPPLIER_SELECTION"
                                    :disabled="ACTION == 'View'"
                                    item-text="text"
                                    item-value="value"
                                    auto-select-first
                                    dense
                                    outlined
                                    required
                                >
                                    <template v-slot:label>
                                        <span>Vendor <span style="color: red;">*</span></span>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="2">
                                <v-autocomplete
                                    v-model="form.vendor_payment_item_id"
                                    :items="GET_TERM_SELECTION"
                                    :disabled="ACTION == 'View'"
                                    item-text="text"
                                    item-value="value"
                                    label="Term"
                                    auto-select-first
                                    dense
                                    outlined
                                    required
                                ></v-autocomplete>
                            </v-col>
                            <v-col :cols="form.payment_method === 3 ? 2 : 4">
                                <v-autocomplete
                                    v-model="form.vendor_address_id"
                                    :items="GET_CONTACT_PERSON_SELECTION"
                                    :disabled="ACTION == 'View'"
                                    item-text="text"
                                    item-value="value"
                                    label="Contact person"
                                    auto-select-first
                                    dense
                                    outlined
                                    required
                                ></v-autocomplete>
                            </v-col>
                            <v-col :lg="form.payment_method === 3 ? 2 : 3" class="d-flex justify-content-center align-items-center">
                               <v-autocomplete
                                   v-model="form.payment_method"
                                   :items="PURCHASE_ORDER.PAYMENT_METHOD"
                                   :disabled="ACTION == 'View'"
                                   item-text="text"
                                   item-value='value'
                                   auto-select-first
                                   clearable
                                   dense
                                   outlined
                                   required
                               >
                                    <template v-slot:label>
                                        <span>Payment Method <span style="color: red;">*</span></span>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col lg="2" class="d-flex justify-content-center align-items-center" v-if="form.payment_method == 3">
                                <v-text-field
                                    v-model="form.amount_to_pay"
                                    :label="form.type == 0 ? 'Enter amount to pay' : 'Enter percentage to pay'"
                                    outlined
                                    dense
                                    :disabled="ACTION == 'View' || form.item_list[0].total_amount == 0"
                                    @keypress="numberandDotOnly"
                                >
                                <template v-slot:append>
                                        <v-btn
                                        icon
                                        text
                                        small
                                        :disabled="ACTION == 'View'"
                                        @click="form.type = form.type == 1 ? 0 : 1;cashOrPercentage()"
                                        >
                                            <v-icon v-if="form.type == 0" color="green">mdi-cash</v-icon>
                                            <v-icon v-else color="orange">mdi-percent-outline</v-icon>
                                        </v-btn>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col lg="1" class="d-flex justify-content-center align-items-center" v-if="form.payment_method == 3">
                                <v-text-field
                                    :value="percentageComputation(form.amount_to_pay, form.type)"
                                    :label="form.type == 1 ? 'Cash Value' : 'Percentage Value'"
                                    outlined
                                    dense
                                    disabled
                                >
                                <template v-slot:append>
                                        <v-btn
                                        icon
                                        text
                                        small
                                        disabled
                                        >
                                            <v-icon v-if="form.type == 1" color="green">mdi-cash</v-icon>
                                            <v-icon v-else color="orange">mdi-percent-outline</v-icon>
                                        </v-btn>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="mb-2">
                        <SectionHeading :heading="'PO Details'"></SectionHeading>
                    </v-col>
                    <v-col cols="12" class="pa-4">
                        <v-row>
                            <v-col cols="3">
                                <v-text-field
                                    v-model="form.po_num"
                                    label="PO#"
                                    outlined
                                    dense
                                    :disabled="ACTION == 'View'"
                                    @keydown="forceUpperCaseText($event)"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-autocomplete
                                    v-model="form.payment_type_id"
                                    :items="GET_PAYMENT_TYPES"
                                    outlined
                                    dense
                                    label="Payment Type"
                                    :disabled="ACTION == 'View'"
                                    item-text="name"
                                    item-value="id"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="3">
                                <v-autocomplete
                                    v-model="form.location_id"
                                    :items="GET_ADMIN_DELIVERY_ADDRESSES"
                                    :disabled="ACTION == 'View'"
                                    auto-select-first
                                    outlined
                                    required
                                    dense
                                    clearable
                                >
                                    <template v-slot:label>
                                        <span>Shipped to <span style="color: red;">*</span></span>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="2">
                                <v-autocomplete
                                    v-model="form.shipping_via_id"
                                    :items="GET_SHIPPING_VIA"
                                    :disabled="ACTION == 'View'"
                                    item-text="text"
                                    item-value="value"
                                    dense
                                    outlined
                                    required
                                >
                                    <template v-slot:label>
                                        <span>Shipping Via <span style="color: red;">*</span></span>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field
                                    v-model="form.contact_person"
                                    outlined
                                    required
                                    label="Shipped to Contact Person"
                                    :disabled="ACTION == 'View'"
                                    dense
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field
                                    v-model="form.contact_number"
                                    outlined
                                    required
                                    label="Shipped to Contact Number"
                                    :disabled="ACTION == 'View'"
                                    dense
                                ></v-text-field>
                            </v-col>
                            <v-col cols="2">
                                <v-menu
                                :close-on-content-click="true"
                                :nudge-right="80"
                                :nudge-bottom="42"
                                transition="scale-transition"
                                max-width="290"
                                >
                                <template v-slot:activator="{ on }">
                                   <v-text-field
                                       v-model="form.expected_date"
                                       v-on="on"
                                       :disabled="ACTION == 'View'"
                                       label="Expected date"
                                       append-icon="mdi-calendar"
                                       readonly
                                       dense
                                       outlined
                                   ></v-text-field>
                                </template>
                                    <v-date-picker v-model="form.expected_date">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="1">
                                <v-text-field
                                    v-model="form.number_of_days"
                                    class="rigth-input"
                                    outlined
                                    dense
                                    suffix="days"
                                    :disabled="ACTION == 'View'"
                                    @keypress="numberOnly"
                                />
                            </v-col>
                            <v-col cols="1">
                                <v-checkbox
                                    v-model="form.rush"
                                    :disabled="ACTION == 'View'"
                                    :true-value="1"
                                    :false-value="0"
                                    label="Rush"
                                    hide-details
                                    dense
                                >
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                               <v-textarea
                                   v-model="form.remarks"
                                   :auto-grow="true"
                                   :disabled="ACTION == 'View'"
                                   label="Remarks"
                                   dense
                                   outlined
                                   rows="1"
                               ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row v-if="[4].includes(form.status)">
                    <v-col cols="12" class="mb-2">
                        <SectionHeading :heading="'Receiving Details'"></SectionHeading>
                    </v-col>
                    <v-col cols="12" class="pa-4">
                        <v-row>
                            <v-col cols="2">
                                <v-autocomplete
                                v-model="form.admin_warehouse_id"
                                :items="admin_warehouse_dropdown"
                                item-text="text"
                                item-value="value"
                                auto-select-first
                                outlined
                                label="Inventory"
                                required
                                dense
                                :disabled="this.form.item_list.some(e=>e.received_qty > 0)"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field
                                v-model="form.dr_number"
                                :auto-grow="false"
                                label="DR#"
                                outlined
                                no-resize
                                dense
                                ></v-text-field>
                            </v-col>
                            <v-col cols="2">
                                <v-menu
                                :close-on-content-click="true"
                                :nudge-right="80"
                                :nudge-bottom="42"
                                transition="scale-transition"
                                max-width="290"
                                >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="form.received_date"
                                        v-on="on"
                                        append-icon="mdi-calendar"
                                        label="Received Date"
                                        dense
                                        readonly
                                        outlined
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="form.received_date"
                                :max="max_date"
                                >
                                </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-spacer/>
                    <div v-if="ACTION !== 'View' || [4].includes(form.status)" cols="3" class="pull-right pb-2">
                        <FileUploadComponentVue
                            style="display:inline-block; transform: translateY(5px);"
                            ref="fileUpload"
                            id="attachment"
                            @uploadedData="uploadedData"
                            @reset="clearFileUpload"
                        />
                    </div>
                    <!-- <div v-if="[4,6,7,9,10].includes(form.status) && ACTION == 'Submit'" class="p-0 mt-4"> -->
                        <!-- :deleteFilesPath="form.status''" -->
                    <div  class="p-0 mt-4">
                        <BaseFileViewerComponent
                            :access="userAccess.includes('file_view')"
                            :payloadId="SELECTED ? SELECTED.id : null"
                            :payloadIds="[]"
                            :getFilesPath="'getAdminPOFiles'"
                            :module="'admin'"
                            :folder="'po_file_uploads'"
                            :localFiles="uploadedFiles"
                            @files="processFiles"
                            ref="closeDialog"
                        />
                    </div>
                    <div class="py-2 mt-5" v-if="ACTION !== 'View' || [4].includes(form.status)">
                        <v-layout row class="mx-0">
                            <v-flex lg2 class="mr-2">
                                <BaseCameraCaptureVue ref="cameraCapture" @capturedImages="getImages" />
                            </v-flex>
                            <v-flex v-if="!!images && images.length > 0" lg3 class="mr-2 mt-1 ">
                                <span>Images Count: {{ images.length }}</span>
                            </v-flex>
                            <v-flex v-if="!!images && images.length > 0">
                                <v-btn @click="clearCameraCapture()">Clear</v-btn>
                            </v-flex>
                        </v-layout>
                    </div>
                </v-row>
                <v-row>
                    <v-col lg="3">
                        <v-btn @click="()=>{
                                batchAddition.dialog = !batchAddition.dialog
                            }"
                            color="orange"
                            :disabled="!form.vendor_id"
                            v-if="ACTION != 'View'"
                            small
                        >
                        <v-icon>mdi-plus</v-icon>    create batch
                        </v-btn>
                    </v-col>
                </v-row>
                 <v-row>
                    <v-col cols="12" class="mb-2">
                       <SectionHeading :heading="'Item Detials'"></SectionHeading>
                    </v-col>
                    <v-col cols="12" class="pa-4">
                        <v-row>
                            <v-col cols="12" >
                                <v-card elevation="0">
                                    <v-card-text style="max-height:600px;">
                                        <v-row>
                                            <v-col lg="12">
                                                <v-data-table
                                                    :items="form.item_list"
                                                    :headers="formattedHeaders(dataTable.headers)"
                                                    dense
                                                    disable-sort
                                                >
                                                <template v-slot:[`header.button`]="{ header }">
                                                    <v-btn
                                                        color="success"
                                                        x-small
                                                        fab
                                                        :disabled="!form.vendor_id"
                                                        v-if="ACTION != 'View'"
                                                        @click="modifyItem(1)"
                                                    >
                                                        <v-icon>mdi-plus</v-icon>
                                                    </v-btn>
                                                    <v-btn
                                                        color="error"
                                                        x-small
                                                        fab
                                                        v-if="ACTION != 'View'"
                                                        :disabled="!form.vendor_id"
                                                        @click="modifyItem(2)"
                                                    >
                                                    <v-icon>mdi-minus</v-icon>
                                                </v-btn>
                                                </template>

                                                    <template v-slot:[`item.item_name`]="{ item,index }">
                                                        <v-autocomplete
                                                            v-model="item.admin_item_id"
                                                            :items="selection.admin_items"
                                                            :disabled="ACTION == 'View'"
                                                            item-value="admin_item_id"
                                                            item-text="item_name"
                                                            dense
                                                            outlined
                                                            hide-details
                                                            @change="appendItem(item,index)"
                                                        >
                                                        </v-autocomplete>
                                                    </template>
                                                    <template v-slot:[`item.pending_po_count`]="{ item,index }">
                                                        <v-menu
                                                        open-on-hover
                                                        bottom
                                                        offset-y
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                            color="#8B9701"
                                                            dark
                                                            icon
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            <v-icon>mdi-alert</v-icon>
                                                        </v-btn>
                                                        </template>

                                                        <v-simple-table dark>
                                                            <template v-slot:default>
                                                            <thead>
                                                                <tr>
                                                                <th class="text-left">
                                                                    PO#
                                                                </th>
                                                                <th class="text-left">
                                                                    Pending
                                                                </th>
                                                                <th class="text-left">
                                                                    Date
                                                                </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr
                                                                v-for="(item,index) in item.pending_po"
                                                                :key="index"
                                                                >
                                                                <td>{{ item.po_num }}</td>
                                                                <td>{{ item.pending_qty }}</td>
                                                                <td>{{ item.created_at }}</td>
                                                                </tr>
                                                            </tbody>
                                                            </template>
                                                        </v-simple-table>
                                                    </v-menu>
                                                    </template>
                                                    <template v-slot:[`item.receive_qty`]="{ item,index }">
                                                        <v-text-field
                                                            v-model="item.receive_qty"
                                                            class="centered-input"
                                                            outlined
                                                            dense
                                                            :max="item.quantity - item.received_qty"
                                                            :min="0"
                                                            @keypress="countingNumberOnly"
                                                            @change="()=>{
                                                                quantityReceive(item);
                                                            }"
                                                            :disabled="(ACTION == 'View' && ![4, 9].includes(form.status)) || (item.received_qty == item.quantity)"
                                                        >
                                                        </v-text-field>
                                                    </template>
                                                    <template v-slot:[`item.received_qty`]="{ item,index }">
                                                        <v-text-field
                                                            v-model="item.received_qty"
                                                            v-if="ACTION !== 'View'"
                                                            class="centered-input"
                                                            background-color="#CFD8DC"
                                                            outlined
                                                            dense
                                                            type="number"
                                                            disabled
                                                        >
                                                        </v-text-field>
                                                        <span v-else>{{item.received_qty ? item.received_qty : 0}}</span>
                                                    </template>
                                                    <template v-slot:[`item.pending_qty`]="{ item,index }">
                                                        <v-text-field
                                                            v-model="item.pending_qty"
                                                            v-if="ACTION !== 'View'"
                                                            class="centered-input"
                                                            outlined
                                                            dense
                                                            disabled
                                                            background-color="#CFD8DC"
                                                        >
                                                        </v-text-field>
                                                        <span v-else>{{item.pending_qty ? item.pending_qty : 0}}</span>
                                                    </template>
                                                    <template v-slot:[`item.canceled_qty`]="{ item,index }">
                                                        <v-text-field
                                                            v-model="item.canceled_qty"
                                                            class="centered-input"
                                                            outlined
                                                            dense
                                                            disabled
                                                            background-color="#CFD8DC"
                                                            type="number"
                                                            @keypress="numberOnly"
                                                        >
                                                        </v-text-field>
                                                    </template>
                                                    <template v-slot:[`item.foc`]="{ item,index }">
                                                    <v-checkbox v-model="item.foc"
                                                            :disabled="ACTION == 'View'"
                                                            hide-details
                                                            dense
                                                            :true-value="1"
                                                            :false-value="0"
                                                            @change="()=>{
                                                                item.foc == 0 ? updatePrice(item) : item.total_amount = '0';
                                                            }"
                                                    >
                                                    </v-checkbox>
                                                    </template>
                                                    <template v-slot:[`item.uom_id`]="{ item }">
                                                        <v-text-field
                                                            :value="item.uom"
                                                            outlined
                                                            dense
                                                            readonly
                                                        ></v-text-field>
                                                    </template>
                                                    <template v-slot:[`item.priority`]="{ item,index }">
                                                        <v-checkbox
                                                            v-model="item.priority"
                                                            :value="item.receive_qty > 0 ? 0 : 1"
                                                            :disabled="ACTION == 'View'"
                                                            :true-value="0"
                                                            :false-value="1 || null"
                                                            hide-details
                                                            dense
                                                        >
                                                        </v-checkbox>
                                                    </template>
                                                    <template v-slot:[`item.quantity`]="{ item }">
                                                        <v-text-field
                                                            v-model="item.quantity"
                                                            class="centered-input"
                                                            outlined
                                                            dense
                                                            :disabled="ACTION == 'View'"
                                                            @keypress="countingNumberOnly"
                                                            @change="updatePrice(item)"
                                                        >
                                                        </v-text-field>
                                                    </template>
                                                    <template v-slot:[`item.total_original_price`]="{ item }">
                                                        <v-text-field
                                                            v-model="item.total_original_price"
                                                            v-if="form.status === 1 && ACTION !== 'View'"
                                                            outlined
                                                            dense
                                                            class="right-input"
                                                            :disabled="ACTION == 'View' || item.foc === 1"
                                                            @change="updateOriginalPrice(item)"
                                                            @keypress="countingNumberOnly"
                                                        >
                                                        </v-text-field>
                                                        <span v-else>{{item.total_original_price | currency}}</span>
                                                    </template>
                                                    <template v-slot:[`item.total_amount`]="{ item }">
                                                        <v-text-field
                                                            v-model="item.total_amount"
                                                            v-if="ACTION == 'Submit'"
                                                            class="rigth-input"
                                                            readonly
                                                            background-color="#CFD8DC"
                                                            outlined
                                                            dense
                                                            :disabled="ACTION == 'View' || item.foc === 1"
                                                        >
                                                        </v-text-field>
                                                        <span v-else>{{thousandSeparator(item.total_amount)}}</span>
                                                    </template>
                                                    <template v-slot:[`item.item_amount_to_pay`]="{ item }">
                                                        <v-text-field
                                                            :value="changeAmountToPay(item)"
                                                            v-if="ACTION !== 'View'"
                                                            background-color="#CFD8DC"
                                                            outlined
                                                            dense
                                                            readonly
                                                            class="rigth-input"
                                                            :disabled="ACTION == 'View'"
                                                        >
                                                        </v-text-field>
                                                        <span>{{item.item_amount_to_pay | currency }}</span>
                                                    </template>
                                                    <template v-slot:[`item.item_paid_amount`]="{ item }">
                                                        <v-text-field
                                                            :value="thousandSeprator(item.item_paid_amount)"
                                                            v-if="ACTION !== 'View'"
                                                            background-color="#CFD8DC"
                                                            outlined
                                                            dense
                                                            readonly
                                                            class="rigth-input"
                                                            :disabled="ACTION == 'View' || item.foc === 1"
                                                        >
                                                        </v-text-field>
                                                        <span v-else>{{item.item_paid_amount | currency}}</span>
                                                    </template>
                                                    <template  v-slot:[`item.original_price`]="{ item }">
                                                        <v-text-field
                                                            :value=" item.original_price ? thousandSeparator(item.original_price) : ''"
                                                            v-if="ACTION === 'Submit'"
                                                            outlined
                                                            dense
                                                            readonly
                                                            class='right-input'
                                                            background-color="#CFD8DC"
                                                            :disabled="ACTION == 'View' || item.foc === 1"
                                                        >
                                                        </v-text-field>
                                                        <span v-else>{{item.original_price | currency}}</span>

                                                    </template>
                                                    <template v-slot:[`item.ids_of_emp`]="{ item,index }">
                                                        <v-autocomplete
                                                            v-model="item.ids_of_emp"
                                                            @input="limitSelection(index, item)"
                                                            outlined
                                                            dense
                                                            label="Accountable"
                                                            :items="GET_EMPLOYEE_DATA"
                                                            multiple
                                                            :clearable="!ACTION == 'View'"
                                                            chips
                                                            deletable-chips
                                                            small-chips
                                                            :readonly="ACTION == 'View'"
                                                        >
                                                        </v-autocomplete>
                                                    </template>
                                                    <template v-slot:[`item.item_warehouse_id`]="{ item, index }">
                                                        <v-autocomplete
                                                            v-model="item.item_warehouse_id"
                                                            :items="warehouse_selections"
                                                            item-text="text"
                                                            item-value="value"
                                                            outlined
                                                            dense
                                                            label="Inventory"
                                                        >
                                                        </v-autocomplete>
                                                    </template>
                                                    <template v-slot:[`item.button`]="{ item, index }">
                                                        <span @click="file_index = item.id" v-if="[4,1].includes(form.status)">
                                                            <FileUploadComponentVue
                                                                ref="fileUpload"
                                                                id="attachment"
                                                                @uploadedData="uploadedData"
                                                                @reset="clearFileUpload"
                                                            />
                                                        </span>
                                                        <v-btn
                                                            color="error"
                                                            small
                                                            icon
                                                            :disabled="ACTION == 'View'"
                                                            @click="modifyItem(3, index)"
                                                            :class="[4,1].includes(form.status) ? 'mb-3' : ''"
                                                        >
                                                            <v-icon>
                                                                mdi-delete
                                                            </v-icon>
                                                        </v-btn>

                                                    </template>
                                                </v-data-table>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col lg="5" v-if="ACTION == 'Add'">
                                                <b>Item Not Found: </b> <span style="color:red">{{ errors.item_not_found.map(e=>e.item_name).join(", ") }}</span>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                    <v-card-actions class="justify-content-end border-top">
                                        <span class="font-weight-bold">Total : {{  ACTION != 'Submit' ? form.total_amount : total | currency }}</span>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                 </v-row>
                <v-card class="mt-3" v-if="ACTION =='Update' && userAccess.comments">
                    <v-card-text>
                    <v-container fluid class="pb-0">
                        <v-row pa-0>
                        <v-col cols="12">
                            <v-textarea outlined height="130" label="Comments" dense v-model="form.comments" :auto-grow="false" no-resize :disabled="disabled_view"></v-textarea>
                        </v-col>
                        </v-row>
                    </v-container>
                    </v-card-text>
                </v-card>

            </v-container>
        <v-container fluid class="pb-0" v-if="form.typeOf == 'PRF'">
          <v-row class="pa-2 pb-0">
          <v-col cols="12" lg="3">
            <!-- <v-row>
                <v-col cols="12">
                  <v-text-field v-model="form.po_num" label="PO Number" outlined dense readonly></v-text-field>
                </v-col>
            </v-row> -->
            <v-row>
              <v-col cols="12">
                  <v-autocomplete
                    v-model="form.vendor_id"
                    :items="GET_ADMIN_SUPPLIER_SELECTION"
                    item-text="text"
                    item-value="value"
                    auto-select-first
                    outlined
                    required
                    label="Supplier"
                    :disabled="disabled_view"
                    dense
                  ></v-autocomplete>
                </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                  <v-autocomplete
                    v-model="form.location_id"
                    :items="GET_ADMIN_DELIVERY_ADDRESSES"
                    item-text="text"
                    item-value="value"
                    auto-select-first
                    outlined
                    label="Location"
                    required
                    :disabled="disabled_view"
                    dense
                  ></v-autocomplete>
                </v-col>
            </v-row>
            <v-row>
                  <v-col cols="12">
                    <v-textarea outlined height="260"  label="Remarks" v-model="form.remarks" :auto-grow="false" no-resize :disabled="disabled_view"></v-textarea>
                  </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" lg="9">
          <v-row class="pl-1">
            <v-col cols="12" >
          </v-col>
          </v-row>
        </v-col>
      </v-row>
        </v-container>
      </v-card-text>
      <v-card-text v-else>
        <v-row>
            <v-col lg="12">
                <v-card elevation="0">
                    <v-card-text class="d-flex justify-content-center align-items-center">
                        <v-progress-linear
                            indeterminate
                            color="primary"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
      </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="text-center">
          <v-row class="ma-1">
            <v-col cols="12" >
              <v-row >
                <v-col lg="1">
                    <span >
                        <v-btn text color="orange" small :loading="loading"  v-if="ACTION == 'View' && PO_RECEIVING_DIALOG !== true" @click="nextItem(1)">
                            <v-icon >
                                mdi-arrow-left
                            </v-icon>
                        </v-btn>
                    </span>
                </v-col>
                    <v-col cols="3"  v-if="(([4].includes(form.status))) || ['Submit','Update'].includes(ACTION)" :sm="ACTION != 'View'?10:6">
                        <v-btn
                        color="orange"
                        :loading="loading"
                                class="float-center"
                                @click="createOrUpdatePurchaseOrder()"
                            >
                                <span>SAVE</span>
                        </v-btn>
                    </v-col>
                    <v-col v-if="ACTION == 'View' && PO_RECEIVING_DIALOG !== true">
                        <v-btn
                                small
                                class="float-center"
                                color="orange"
                                :loading="loading"
                                v-if="![6, 7, 10].includes(form.status)"
                                @click="updateStatus(form.status, 0)"
                            >
                                <span v-if="![1, 4, 6, 9].includes(form.status)">{{`${STATUS.ADMIN_PURCHASE_ORDER_STATUS.find(e => e.value === form.status)?.text} Approve`}}</span>
                                <span v-if="[4].includes(form.status)">{{'Receive'}}</span>
                        </v-btn>
                        <v-btn
                                small
                                color="orange"
                                class="float-center"
                                :loading="print_loading"
                                @click="renderPDF()"
                            ><span>Print</span>
                        </v-btn>
                        <v-btn
                                v-if="[4].includes(form.status) && (userAccess.includes('cancel') || USERACCOUNT_LOAD.manager == 1)"
                                :loading="loading"
                                small
                                color="red"
                                class="float-center"
                                @click="updateStatus(form.status, 1)"
                            ><span>Cancel</span>
                        </v-btn>
                    </v-col>
                    <v-col lg="1">
                        <v-btn text color="orange" small :loading="loading" @keydown="handleKeydown" v-if="ACTION == 'View' && PO_RECEIVING_DIALOG !== true" @click="nextItem(2)">
                            <v-icon>
                                mdi-arrow-right
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
          </v-row>
        </v-card-actions>
        <PurchaseOrderTemplateAlignToGsheet></PurchaseOrderTemplateAlignToGsheet>
            <v-dialog v-model="warehouse_allocation.dialog" width="60%">
                <v-card>
                    <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                    <span>Allocate Items to Desisnated Inventory</span>
                        <v-row class="m-0">
                            <v-col cols="pull-right-10 p-2">
                            <v-btn
                                text
                                icon
                                small
                                color="gray"
                                class="float-right"
                                @click="closeDialog()"
                            >
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                <v-card-text>
                    <v-container fluid>
                        <v-row>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="text-center pa-0">
                <v-row class="ma-1">
                    <v-col cols="12" >
                    <v-btn
                        small
                        class="float-center"
                        @click="()=>{
                            warehouse_allocation.click = !warehouse_allocation.click
                        }">
                        <span>Save and Close</span>
                    </v-btn>
                    </v-col>
                </v-row>
                </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- <EditCurrentItemsViewComponent></EditCurrentItemsViewComponent> -->
            <BatchAdditionCopyPasteDialogComponent :dialog="batchAddition.dialog" @closeDialog="retrieveData" :headers="['Item Name','Quantity']"></BatchAdditionCopyPasteDialogComponent>
        </v-card>
    </v-form>
</template>
<script>
    // import QrcodeVue from "qrcode.vue";
    import { mapGetters } from "vuex";
    import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
    import PurchaseOrderTemplateAlignToGsheet from '@/views/main/layouts/PrintTemplate/PurchaseOrderTemplateAlignToGsheet.vue'
    import EditCurrentItemsViewComponent from '@/views/main/layouts/dialogs/Admin/EditCurrentItemsViewComponent.vue';
    import BatchAdditionCopyPasteDialogComponent from "../Operations/General/batchAdditionCopyPastComponent.vue";
    import Swal from 'sweetalert2'
    import PurchaseOrderTableComponent from "../../../modules/Admin/PurchaseOrder/PurchaseOrderTableComponent.vue";
    import FileUploadComponentVue from '@/views/main/Shared/FileUploadComponent.vue'
    import BaseFileViewerComponent from "@/views/main/Shared/BaseFilesViewerComponent.vue"
    import BaseCameraCaptureVue from "@/views/main/modules/HR/Base/BaseCameraCapture.vue"
    import SectionHeading from '@/views/main/Components/SectionHeading.vue';

    export default {
        props:['boundType'],
        mixins: [
            SharedFunctionsComponentVue,
            PurchaseOrderTableComponent
        ],
        inject: ['theme'],
        data() {
            return {
                file_index: '',
                overlay:false,
                itemCheck:[],
                valid:true,
                dataTable:{
                    headers:[
                        { text:'Item Name',value:'item_name',width:'13%' },
                        { text:'UOM', align:'center', value:'uom_id',width:'7%' },
                        { text:'Quantity', align:'center', value:'quantity',width:'7%' },
                        { text:'Receive Qty', align:'center', value:'receive_qty',width:'7%' },
                        { text:'Received Qty', align:'center', value:'received_qty',width:'7%' },
                        { text:'Pending Qty', align:'center', value:'pending_qty',width:'7%' },
                        { text:'Canceled Qty', align:'center', value:'canceled_qty',width:'7%' },
                        { text:'SRP',align:'end', value:'original_price',width:'10%' },
                        { text:'Final Price',align:'end', value:'total_original_price',width:'10%' },
                        { text:'Total Amount', align:'end', value:'total_amount',width:'10%' },
                        { text:'Amount to pay', align:'end', value:'item_amount_to_pay',width:'10%' },
                        { text:'Paid Amount', align:'end', value:'item_paid_amount',width:'10%' },
                        { text:'FOC',value:'foc',width:'2%' },
                        { text:'Priority',value:'priority',width:'2%' },
                        // { text:'Remarks',value:'remarks',width:'20%' },
                        { text: 'Accountability', value: 'ids_of_emp', width: '15%'},
                        { text:'Key Datas',value:'key_datas',width:'7%' },
                        { text:'Pending PO/s',value:'pending_po_count',width:'10%' },
                        { text: 'Inventory', value: 'item_warehouse_id', width: '15%'},
                        { text:'Action',align:'center',value:'button',width:'10%' },
                    ]
                },
                form: {
                    index:0,
                    pending_qty: 0,
                    canceled_qty: 0,
                    // receive_qty: 0,
                    payment_method:0,
                    typeOf:'Supplier',
                    location: "",
                    supplier: "",
                    item_list:[
                        { value:'', total_amount:'', receive_qty: '', received_qty: '',  uom:'',vendor:'', ids_of_emp: []}
                    ],
                    selected:false,
                    select_all:false,
                    po_id_tag:'',
                    po_id:'',
                    po_num:'',
                    prf_num:[],
                    remarks:'',
                    status:1,
                    supplier_items:[],
                    total_amount:0,
                    comments:'',
                    file:[],
                    fileViewer:[],
                    vendor_address_id: '',
                    vendor_payment_item_id: '',
                    shipping_via_id: '',
                    expected_date: '',
                    vendor_id: '',
                    location_id: '',
                    amount_to_pay:'',
                    company_id: null,
                    store_branch_id: null,
                    cash: true,
                    type: 0,
                    vat_percentage: 0,
                    admin_warehouse_id: '',
                    received_date: '',
                    is_overdue: 0,
                    rush: 0,
                },
                selection:{
                    admin_items:[],
                    payment_method:[
                        { text:'Payment First', value:1},
                        { text:'Receive first', value: 2},
                        { text:'Partial payment', value: 3},
                    ]
                },
                index:0,
                default:{},
                loading:false,
                print_loading: false,
                disabled_view: false,
                URL:process.env.VUE_APP_API_HOST,
                userAccess:[],
                noImage:"images/noimage.png",
                file_upload_dialog:false,
                override: {
                    override_dialog: false,
                    authenticated:false,
                    override_user_password:"",
                    errors: [],
                    override_departments_allowed: ['admin'],
                    override_authenticated: false,
                    type:"",
                    isPrint:false
                },
                passwordRules:[],
                edit_selected_item_dialog:false,
                edit_selected_item:{},
                error_password:'',
                batchAddition:{
                    dialog:false,
                    data:[]
                },
                errors:{
                    item_not_found:[]
                },
                received: [],
                approve_accountability: false,
                itemMaxLimits: [],
                uploadedFiles: null,
                compiledFiles: [],
                testFiles: [],
                warehouse_allocation: {
                    dialog: false,
                    data: [],
                    click: true
                },
                warehouse_selections: [],
                admin_warehouse_dropdown: [],
                max_date: this.$dayjs().format('YYYY-MM-DD'),
                images: null,
                trash_item_ids: [],
                old_ids: [],
                uploadedImages: null,
                keys:{
                    action:0,
                    page:1
                },
                total: 0,
                clonedStores: [],
                viewUploadFiles: {
                    attachments: []
                }
            };
        },
        components: {
            // QrcodeVue,
            PurchaseOrderTemplateAlignToGsheet,
            EditCurrentItemsViewComponent,
            BatchAdditionCopyPasteDialogComponent,
            FileUploadComponentVue,
            BaseFileViewerComponent,
            BaseCameraCaptureVue,
            SectionHeading,
        },
        computed: {
            ...mapGetters([
                "ACTION",
                'GET_ADMIN_SUPPLIER_SELECTION',
                'PURCHASE_ORDER',
                "DIALOGS",
                'GET_TERM_SELECTION',
                'GET_ADMIN_DELIVERY_ADDRESSES',
                'GET_CONTACT_PERSON_SELECTION',
                'SELECTED',
                'STATUS',
                'USERACCOUNT_LOAD',
                'GET_COMPANY_SELECTION',
                'GET_PV_STORE_BRANCHES_SELECTION',
                'GET_EMPLOYEE_DATA',
                'GET_SHIPPING_VIA',
                'GET_ADM_WAREHOUSE_CODE_DROPDOWN',
                'DATA_TABLE',
                'PO_RECEIVING_DIALOG',
                'GET_PV_COMPANIES_SELECTION',
                'SELECTED_TAB',
                'GET_TRANSACT_TYPES_SELECTION',
                'GET_INBOUND_OUTBOUND_TYPE',
                'GET_CUSTOMERS_LIST_SELECTION',
                'GET_PAYMENT_TYPES',
            ]),
            imageFluid(){
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': return "card-img-top img-fluid"
                    case 'sm': return "card-img-top img-fluid"
                    case 'md': return "card-img-top"
                    case 'lg': return "card-img-top"
                    case 'xl': return "card-img-top"
                }
            },
            dialogwidth(){
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': return "100%"
                    case 'sm': return "100%"
                    case 'md': return "50%"
                    case 'lg': return "20%"
                    case 'xl': return "20%"
                }
            },
            edititemdialogwidth(){
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': return "100%"
                    case 'sm': return "100%"
                    case 'md': return "50%"
                    case 'lg': return "40%"
                    case 'xl': return "40%"
                }
            },
            // totalAmount() {
            //     return this.form.item_list.reduce((total, item) => total + parseFloat(item.total_amount), 0)
            // },
            branchItems(){
                let items = this.clonedStores.filter(e=>e.company_id == this.form.company_id)
                if (!this.form.company_id) return this.clonedStores
                if (!this.form.store_branch_id) this.form.store_branch_id = items[0].value
                return items
            }
        },
        beforeDestroy() {
            document.removeEventListener('keydown', this.handleKeydown);
        },
        mounted() {
            document.addEventListener('keydown', this.handleKeydown);
            this.default = this.form
            // this.$store.dispatch("getContactPersonSelection", 5);
            // this.$store.dispatch("getTermSelection", 5);
            this.$store.dispatch("getPvCompanies");
            this.$store.dispatch("getShippingVia");
            this.$store.dispatch('adminPurchaseOrderGet',{url:"getPoItemReceived"})
                .then(response=>{this.received = response})
            // this.resetItemSelection();
            this.$store.dispatch('getEmployeeDataDropdown');
            this.$store.dispatch('getAdminWarehouseCodeDropdown');
            this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id).then(response => {
                this.userAccess = response.map(e=>e.actions_code);
            })
            this.getBranches()
        },
        methods: {
        limitSelection(index, value) {
            if (value.ids_of_emp.length > value.quantity) {
                this.form.item_list[index].ids_of_emp.pop();
            }
        },
        renderPDF(){
            // this.$store.commit('PRINT_PURCHASE_ORDER_DETAIL',{});
            // this.$store.commit('PRINT_PURCHASE_ORDER_DETAIL',this.PURCHASE_ORDER.STATUS);
            this.$store.commit('PRINT_PURCHASE_ORDER', true);
        },
        async createOrUpdatePurchaseOrder(){
            let trash = this.form.item_list.map(e => e.id)
            this.trash_item_ids = this.old_ids.filter(e => !trash.includes(e))
                Swal.fire({
                title:'Warning',
                text: this.ACTION === 'View' ? 'Are you sure you want to Update?': 'Are you sure you want to '+this.ACTION+'?',
                showCancelButton:true,
                reverseButtons:true,
                icon:'warning'
            }).then(action=>{
                if(action.isConfirmed){
                    let checker = [
                        [this.form.item_list.length == 0,'- Please enter items'],
                        [this.form.item_list.some(e=>!e.quantity),'Please Check the item/s'],
                        [!this.form.shipping_via_id,'- Please enter Shipping Via'],
                        [!this.form.vendor_address_id,'- Please enter Contact person'],
                        // [!this.form.vendor_payment_item_id,'- Please enter terms'],
                        [!this.form.location_id,'- Please enter shipped to'],
                        [!this.form.expected_date,'- Please enter expected date'],
                        [!this.form.company_id,'- Please enter company'],
                        [this.ACTION == 'Submit' && this.form.payment_method == 3 && !this.form.amount_to_pay,'Please Enter Amount to Pay'],
                        [!this.uploadedFiles && !this.images && this.form.rush == 1 && this.ACTION == 'Submit',' Please Upload or Capture File'],
                    ]

                    if(checker.find(e=>e[0] == true)){
                        Swal.fire('Error!',`- ${checker.find(e=>e[0] == true)[1]}`,'error')
                        return
                    }
                    let total = this.form.item_list.map(e => parseFloat(e.total_amount)).reduce((accumulator, currentValue) => accumulator + currentValue,0, )
                    this.form.amount_to_pay = this.form.payment_method == 3 ? this.recomputeAmountToPay(this.form.amount_to_pay, total) : total;
                    this.$store.dispatch('adminPurchaseOrderPost',{
                        url:!this.form.id ? 'purchaseorder' : 'purchaseorder/edit',
                        ...this.form,
                        item_list: this.form.item_list.map(e=> {
                            e.pending_po = ''
                            return e;
                        }),
                        total_amount:total,
                        file: this.uploadedFiles,
                        image: this.uploadedImages,
                        trash_item_ids:  this.trash_item_ids,
                        is_overdue: this.form.expected_date >= this.$dayjs().format('YYYY-MM-DD') ? 0 : 1
                    }).then(response=>{
                        Swal.fire('Success!','','success')
                        this.$store.commit("DIALOG", false);
                        this.resetFields();
                        this.$store.dispatch('ACTION','DONE')
                    }).catch(e=>{
                        console.log(e)
                        Swal.fire('Error','','error')
                    });
                }
            })
            let date_now = this.$dayjs().format('YYYY-MM-DD');
            const supplier_swal_text = this.form.vendor_id ? "" : "Supplier";
            const location_swal_text = this.form.location_id ? "" : "Location";
            const array_text = [supplier_swal_text,location_swal_text];
            const filtered = array_text.filter((el)=> {
                    return el;
                });
            let tmp_boolean = false;
            let swal_obj = {
                title:'',
                text:'',
                allowOutsideClick:false,
            }
            if(this.form.selected.length== 0 && this.ACTION == "Submit"){
                swal_obj.text = "No item selected"
                this.$store.dispatch('SwalAlertWarning',swal_obj)
            }else if(this.form.item_list.length== 0){
                swal_obj.title = "items table is empty"
                this.$store.dispatch('SwalAlertWarning',swal_obj)
            }else{
                if (this.form.vendor_id == '' || this.form.location_id == ''){
                    swal_obj.text = `Fill Required Fields (${filtered})`
                    this.$store.dispatch('SwalAlertWarning',swal_obj)
                }else{
                    let formData = new FormData()
                    for(let i = 0; i < this.form.file.length; i++){
                        formData.append('file[]',this.form.file[i]);
                    }
                    const config = {
                            headers: {
                            'content-type': 'multipart/form-data,application/json'
                        }
                    }
                    formData.append('params',JSON.stringify(this.form))
                    let payload = {
                        params:formData,
                        config:config,
                        }
                    if([2,3].includes(this.form.status) || this.ACTION == 'Update' ){
                        let swal_obj = {
                            title:'',
                            text:`Are you sure you want to ${this.ACTION == 'Update'?'Update':'Approve'}?`,
                            icon:'question',
                            confirmButtonText:this.ACTION == 'Update'?'Update':'Approved',
                            cancelButtonText:'Cancel',
                            allowOutsideClick:false,
                        }
                        this.$store.dispatch('SwalAlertQuestion',swal_obj).then(e=>{
                            if(e.isConfirmed){
                                this.$store.dispatch(this.ACTION,payload);
                            }
                        })
                    }else{
                        this.$store.dispatch('adminPurchaseOrderPost',payload);
                    }
                }
            }
        },
      closeDialog() {
        //   if(this.ACTION == "Update" || this.ACTION == "View"){
        this.resetFields();
        //   }
        this.$refs.closeDialog.$emit('clear-files');
        this.$store.commit("DIALOG", false);
        this.$store.commit("PO_RECEIVING_DIALOG", false);
        this.$store.commit("ACTION", '');
      },
      resetFields(){
        this.form =  {
            receive_qty:0,
            payment_method:0,
                typeOf:'Supplier',
                location: "",
                supplier: "",
                item_list:[
                    { id:0,value:'', total_amount:'',uom:'',vendor:''}
                ],
                selected:false,
                select_all:false,
                po_id_tag:'',
                po_id:'',
                po_num:'',
                prf_num:[],
                remarks:'',
                status:1,
                supplier_items:[],
                total_amount:0,
                comments:'',
                file:[],
                fileViewer:[],
                vendor_address_id: '',
                vendor_payment_item_id: '',
                shipping_via_id: '',
                expected_date: '',
                vendor_id: '',
                location_id: '',
                amount_to_pay:'',
                store_branch_id: null,
                type: 0,
                received_date: ''
        }
        this.warehouse_allocation = {
            dialog: false,
            data: [],
            click: false
        }
        this.trash_item_ids = [];
        this.old_ids = [];
        this.uploadedFiles = null;
        this.selection.admin_items = []
        this.$store.commit("DIALOG", false);
        this.$store.commit('SELECTED',null);
        this.$store.commit('RESET',true);
        this.getAllPurchaseOrder();
        this.resetItemSelection();
        this.uploadedImages = null;
        this.images = null;
        this.total = 0;
        this.compiledFiles = [];
        this.viewUploadFiles = {
            attachments: []
        }
      },
      closeConfirmDialog(items,i){
        this.override.override_dialog = false;
        this.edit_selected_item_dialog = false
        this.edit_selected_item = {}
        this.override.override_user_password = ''
      },
      confirmoverride(){
        let payload = {
                departments_allowed:        this.override.override_departments_allowed,
                override_user_password:     this.override.override_user_password,
          }
        this.$store.dispatch('confirmOverride',payload).then(response=>{
          let data = this.GET_CONFIRM_OVERRIDE

          if(data.user_id){
            this.edit_selected_item_dialog = true
            this.override.override_dialog = false;
            this.override.override_user_password = ''
          }else{
            this.error_password = 'Unauthorized Access!';
            setTimeout(()=>{
              this.error_password = ''
            },3000)
          }
        })
      },
            saveEditPrice(){
                let swal_params = {
                    text:'Are you sure you want to update the item price?',
                    icon:'question'
                }
                this.$store.dispatch('SwalAlertQuestion',swal_params).then(e=>{
                if(e.isConfirmed){
                    this.$store.dispatch('purchaseOrderSaveEditPrice',this.edit_selected_item).then(response=>{
                    if(response){
                    let params = {
                        text:'item Price Saved!',
                    }
                    this.$store.dispatch('SwalAlertSuccess',params)
                    this.closeConfirmDialog()
                    }
                });
                }else{
                    this.closeConfirmDialog()
                }
                })
            },
            async retrieveData(data){
                if(data.length >  0){
                    data = data.map(e=>{
                        let obj = {
                            item_name:e[0],
                            quantity:e[1],
                        }
                        e = obj
                        return e
                    })

                    await this.$store.dispatch('getPRFAdminItems',{
                        vendor_id:this.form.vendor_id,
                    }).then(response=>{

                        this.errors.item_not_found = []
                        data = data.filter(e=>{
                            let item = response.find(el=>el.item_name == e.item_name)
                            if(!!item){
                                item.quantity = e.quantity
                                item.total_amount = item.total_amount * item.quantity
                                return item
                            }else{
                                this.errors.item_not_found.push(e)
                            }
                        })

                        this.form.item_list = data.map(e=>{
                            return response.find(el=>el.item_name == e.item_name)
                        })
                        this.$forceUpdate()
                    })
                }
                this.batchAddition.dialog = false
            },
            reject(){
                Swal.fire({
                title: 'Are you sure you want to reject? why?',
                input: 'textarea',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCancelButton: true,
                confirmButtonText: 'Reject',
                confirmButtonColor:'#ff5252',
                preConfirm: (login) => {
                    this.form.status = 3;
                    this.form.rejected_remarks = login
                },
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.createOrUpdatePurchaseOrder();
                    }
                })
            },
            closeUploadDialog(){
                if(this.form.po_num == ''){
                    this.form.fileViewer = []
                    this.form.file = []
                }
                this.file_upload_dialog = false
            },
            editPrice(item,i){
                this.override.override_dialog = true;
                this.edit_selected_item = item
                this.edit_selected_item.previous_amount = item.total_amount
            },
            closeConfirmDialog(items,i){
                this.override.override_dialog = false;
                this.edit_selected_item_dialog = false
                this.edit_selected_item = {}
                this.override.override_user_password = ''
            },
            confirmoverride(){
                let payload = {
                    departments_allowed:        this.override.override_departments_allowed,
                    override_user_password:     this.override.override_user_password,
                }
                this.$store.dispatch('confirmOverride',payload).then(response=>{
                let data = this.GET_CONFIRM_OVERRIDE
                    if(data.user_id){
                        this.edit_selected_item_dialog = true
                        this.override.override_dialog = false;
                        this.override.override_user_password = ''
                    }else{
                        this.error_password = 'Unauthorized Access!';
                        setTimeout(()=>{this.error_password = ''},3000)
                    }
                })
            },
            modifyItem(ACTION, index = -1){
                this.getAllPurchaseOrder()
                if([2,3].includes(ACTION)){
                    if(this.form.item_list.length < 2){
                        this.form.item_list[0] = [{ id:0, value:'', total_amount:'',uom:'',vendor:''}]
                    }else{
                        if(ACTION == 2){
                            this.form.item_list.pop()
                        }else{
                            if(index != -1){
                                this.form.item_list.splice(index, 1)
                            }
                        }
                    }
                    this.total = 0;
                    this.sumOfItems();
                }else if(ACTION == 1){
                    this.form.item_list.push({ id:0, value:'', total_amount:'',uom:'',vendor:''})
                }
                this.$forceUpdate()
            },
            async appendItem(item,index){
                await this.$store.dispatch('getPRFAdminItems',{
                    vendor_id:this.form.vendor_id,
                    admin_item_id:item.admin_item_id
                }).then(response=>{
                    this.form.item_list[index] = response[0]
                    this.sumOfItems();
                    this.$forceUpdate()
                })
            },

            updatePrice(item){
                // const amount = this.selection.admin_items.find(e => e.admin_item_id === item.admin_item_id)?.original_price
                item.total_amount = parseFloat(item.quantity * item.total_original_price)
                this.sumOfItems();
                // item.total_original_price = parseFloat(amount) * parseInt(item.quantity)
            },
            updateOriginalPrice(item){
                item.total_amount = parseFloat(item.quantity * item.total_original_price)
            },
            async updateStatus(status = 4, cancel = 0){
                let received = 0
                    if(this.form.item_list.every(e => e.quantity == (parseInt(e.receive_qty) + parseInt(e.received_qty))) || this.form.item_list.every(e => e.quantity == e.receive_qty)){
                        received = 1
                    }

                    if(status === 4 && cancel == 0){
                        let checker = [
                            [!this.form.dr_number,' Please enter DR#'],
                            [!this.form.received_date,' Please Enter Received Date'],
                            [!this.form.admin_warehouse_id,' Please Select Warehouse'],
                            [!this.viewUploadFiles.attachments.some(e => e.upload_type == 2) && !this.images,' Please Upload or Capture File'],
                            [this.form.status == 4 && this.form.item_list.every(e => e.receive_qty == 0 && e.pending_qty > 0), 'Receive Quantity cannot be 0'],
                        ]

                        if(checker.find(e=>e[0] == true)){
                            Swal.fire('Error!',`- ${checker.find(e=>e[0] == true)[1]}`,'error')
                            return
                        }
                    }

                    if(this.form.item_list.every(e => e.paid == 1) && this.form.item_list.every(e => e.received_qty == null) && cancel == 1){
                         Swal.fire({
                            title:'Cannot Cancel',
                            text:'Due to some items are already paid',
                            icon:'warning',
                        })
                    }else if(this.form.payment_method === 1 && this.form.status === 4 && this.form.item_list.every(e => e.receive_qty == 0) && cancel == 0){
                        Swal.fire({
                            title:'Insuficient quantity to receive',
                            text:'',
                            icon:'warning',
                        })
                    }else{
                        Swal.fire({
                            title: cancel === 1 ? 'Warning' : 'Question',
                            text: cancel === 1 && this.form.item_list.some(e => e.item_paid_amount > 0) ? '- Are you sure you want to cancel?'
                                : `- Are you sure you want to update status?`,
                            icon: cancel === 1 ? 'warning' : 'question',
                            showCancelButton:true,
                            reverseButtons:true
                        }).then(action=>{
                            if(action.isConfirmed){
                                if([4].includes(this.form.status) && this.form.item_list.every(e => e.receive_qty == 0) && cancel === 0){
                                    Swal.fire('Warning!', '- You cannot procceed without receive qty!', 'warning')
                                }else{
                                    this.$store.dispatch('adminPurchaseOrderPost',{
                                        url:'update-status',
                                        id: this.form.id,
                                        // item_list: this.form.item_list,
                                        item_list: this.form.item_list.map(e=> {
                                            e.pending_po = ''
                                            return e;
                                        }),
                                        cancel: cancel,
                                        status:parseInt(status),
                                        received: received,
                                        received_date: this.form.received_date,
                                        dr_number: this.form.dr_number,
                                        file: this.uploadedFiles,
                                        image: this.uploadedImages,
                                        admin_warehouse_id: this.form.admin_warehouse_id,
                                    }).then(response=>{
                                        if(this.boundType){
                                            this.form.admin_warehouse_id = this.form.item_list[0].item_warehouse_id;
                                            let payload = {
                                                form: this.form,
                                                inbound_outbound_type_id:this.GET_INBOUND_OUTBOUND_TYPE.find(e => e.code == this.boundType).value,
                                                type: this.SELECTED_TAB,
                                                url: 'createInboundOutbound'
                                            }
                                            this.$store.dispatch('adminInboundOutboundPost', payload)
                                        }
                                        Swal.fire('Success!','','success')
                                        if(this.SELECTED.autonext === 0){
                                            this.nextItem(2);
                                        }else{
                                            this.closeDialog();
                                        }
                                    }).catch(e=>{
                                        console.log(e)
                                        Swal.fire('Error','','error')
                                    })
                                }
                            }
                        })
                    }

            },
            checkQuantityReceived(){
                let checker = true
                this.form.item_list.forEach(e=>{
                    if(parseInt(e.quantity) != parseInt(e.receive_qty)){
                        checker = false
                    }
                })
                return checker
            },
            resetItemSelection(){
                this.selection.admin_items.forEach(e=>{
                    e.itemDisabled = false
                })
            },
            formattedHeaders(headers){
                if([7].includes(this.form.status)){
                    headers = this.dataTable.headers.filter(header => !['item_amount_to_pay', 'pending_qty', 'priority', 'receive_qty', 'item_warehouse_id'].includes(header.value))
                    return headers
                }else if([10].includes(this.form.status)){
                    headers = this.dataTable.headers.filter(header => !['pending_qty', 'received_qty', 'priority', 'item_amount_to_pay', 'item_warehouse_id'].includes(header.value))
                    return headers
                }else if([4].includes(this.form.status)){
                    headers = this.dataTable.headers.filter(header => !['canceled_qty', 'item_amount_to_pay', 'original_price', 'total_original_price', 'total_amount', 'item_paid_amount','ids_of_emp', 'item_warehouse_id'].includes(header.value))
                    return headers
                }else if([6].includes(this.form.status)){
                    headers = this.dataTable.headers.filter(header => !['canceled_qty', 'pending_qty', 'receive_qty', 'item_warehouse_id'].includes(header.value))
                    return headers
                }else if([9].includes(this.form.status)){
                    headers = this.dataTable.headers.filter(header => !['canceled_qty', 'pending_qty',  'receive_qty', 'quantity', 'received_qty', 'item_warehouse_id', 'original_price', 'total_original_price', ].includes(header.value))
                    return headers
                }else{
                    headers = this.dataTable.headers.filter(header => !['receive_qty', 'pending_qty', 'received_qty', 'item_paid_amount', 'canceled_qty', 'item_amount_to_pay', 'item_warehouse_id'].includes(header.value))
                    return headers
                }
            },
            recomputeAmountToPay(amount, total){
                if(this.form.type == 0){
                    return amount;
                }else{
                    let percentage = amount / 100;
                    let newAmount = percentage * total
                    return newAmount;
                }
            },
            countingNumberOnly ($event) {
                let keyCode = $event.keyCode ? $event.keyCode : $event.which;
                let inputValue = $event.target.value;
                // Check if the key is not a number or a decimal point, but allow 0
                if ((keyCode === 45) ||
                    ((keyCode < 48 || keyCode > 57) && keyCode !== 46)) { // Allow numbers and decimal
                    $event.preventDefault();
                }
                // Check if there's already a decimal point in the input value
                if (inputValue.includes('.') && keyCode === 46) {
                    $event.preventDefault();
                }
            },
            percentageComputation(amount){
                let total = this.form.item_list.length > 0 ? this.form.item_list.map(e => parseFloat(e.total_amount)).reduce((accumulator, currentValue) => accumulator + currentValue, 0) : 0
                let percentage = (amount / total) * 100
                if(amount && this.form.type == 0){
                    return Math.round(percentage);
                }
                else if(amount && this.form.type == 1){
                    let cash = ( amount * total) / 100
                    return this.thousandSeparator(cash);
                }else{
                    return 0
                }
            },
            changeAmountToPay(item){
                if(item.item_amount_to_pay){
                    return this.thousandSeprator(item.item_amount_to_pay)
                }else{
                    if(this.form.payment_method === 3){
                       return this.thousandSeprator(0)
                    }else if(item.foc === 0){
                        return this.thousandSeprator(item.receive_qty * item.original_price)
                    }else{
                        return this.thousandSeprator(0)
                    }
                }

            },
            thousandSeparator(amount) {
                if (amount !== '' && amount !== undefined && amount !== null && !isNaN(amount)) {
                    let formattedAmount = parseFloat(amount).toFixed(2);
                    formattedAmount = formattedAmount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                    return formattedAmount;
                } else {
                    return amount;
                }
            },
            formatAmount(amount){
                let raw_amount = amount;
                return this.thousandSeparator(raw_amount)

            },
             cashComputationFromPercentage(amount_to_pay, type){
                let total = this.form.item_list.length > 0 ? this.form.item_list.map(e => parseFloat(e.total_amount)).reduce((accumulator, currentValue) => accumulator + currentValue, 0) : 0
                if(amount_to_pay && type == 1){
                    let percentage = (amount_to_pay / total) * 100
                    let cash = percentage * total / 100
                    return this.thousandSeparator(cash);
                }else if(amount_to_pay){
                    let cash =  amount_to_pay * total / 100
                    return this.thousandSeparator(cash);
                }else{
                    return 0
                }
            },
            cashOrPercentage(){
                let total = this.form.item_list.length > 0 ? this.form.item_list.map(e => parseFloat(e.total_amount)).reduce((accumulator, currentValue) => accumulator + currentValue, 0) : 0
                if(this.form.type == 1){
                    this.form.amount_to_pay = this.form.amount_to_pay ? Math.round((this.form.amount_to_pay  / total) * 100) : 0
                }else{
                    this.form.amount_to_pay = this.form.amount_to_pay ? ((this.form.amount_to_pay / 100  ) * total).toFixed(2) : 0
                }
                this.$forceUpdate();
            },
            quantityReceive(item){

                if(parseFloat(item.receive_qty) > parseFloat(item.quantity)){
                    item.receive_qty = parseFloat(item.quantity) - parseFloat(item.received_qty)
                }else if(item.received_qty > 0){
                    if(parseFloat(item.receive_qty) > (parseFloat(item.quantity) - parseFloat(item.received_qty))){
                        item.receive_qty = parseFloat(item.quantity) - parseFloat(item.received_qty)
                    }else{
                        item.receive_qty = parseFloat(item.receive_qty)
                    }
                }
                item.pending_qty = ((item.quantity - item.receive_qty) - item.received_qty).toFixed(2)
            },
            uploadedData(data) {
                if (!data) {
                    this.uploadedFiles = null;
                } else {
                    if(this.viewUploadFiles.attachments.length > 0){

                        data.attachments.forEach(newAttachment => {
                            let duplicateFound = false;
                            // Loop through the existing attachments
                            for (let i = 0; i < this.viewUploadFiles.attachments.length; i++) {
                                if (this.viewUploadFiles.attachments[i].name === newAttachment.name) {
                                    this.viewUploadFiles.attachments[i] = newAttachment;
                                    duplicateFound = true;
                                    Swal.fire('Duplicated Files','','warning');
                                    break;
                                }
                            }
                            if (!duplicateFound) {
                                this.viewUploadFiles.attachments.push(newAttachment);
                            }
                        });
                        // this.viewUploadFiles.attachments.push(data.attachments[0]);
                    } else {
                        this.viewUploadFiles = data;
                    }
                    this.uploadedFiles = this.viewUploadFiles;
                }
            },
            clearFileUpload(confirm){
                if (confirm) {
                    this.uploadedFiles = null
                }
                this.uploadedFiles = null
                this.$refs.fileUpload.reset()
            },
            async processFiles(files){
                // this.uploadedFiles.attachments = []
                // files.forEach(e=>{
                //     this.uploadedFiles.attachments.push(
                //         e.localFileState
                //     )
                // })

                this.viewUploadFiles.attachments = this.viewUploadFiles.attachments.filter(compiledFile => {
                    return files.some(file => compiledFile.name === file.localFileState.name);
                });

                files = files.filter(file => {
                    return this.viewUploadFiles.attachments.some(compiledFile => compiledFile.name === file.localFileState.name);
                });

                let file_type_check = this.file_index === '' ? '2' : '1'
                let item_id = this.file_index
                this.compiledFiles = []
                files.forEach(e=>{
                    if(e.localFileState.upload_type === undefined){
                        e.localFileState.upload_type = file_type_check
                    }
                    if(e.localFileState.item_id === undefined){
                        e.localFileState.item_id = item_id
                    }
                    this.compiledFiles.push(
                        e.localFileState
                    )});
                this.file_index = ''
                let fileData = []
                for(let i = 0; i < this.compiledFiles.length; i++) {
                    const element = this.compiledFiles[i]
                    let reader = new FileReader()
                    reader.readAsDataURL(element)
                    await new Promise((resolve) => {
                        reader.onloadend = function () {
                            fileData.push({
                                name: element.name,
                                type: element.type,
                                data: reader.result.split(',')[1],
                                upload_type: element.upload_type,
                                item_id: element.item_id,
                            })
                            resolve()
                        }
                    })
                }
                this.uploadedFiles = fileData
                // console.log('uplaoded files' , this.uploadedFiles)
            },
            getImages(dataUrls) {
                this.images = dataUrls.map(dataUrl => {
                    try {
                        if (!dataUrl.startsWith('data:image/jpeg;base64,')) {
                            throw new Error('Invalid data URL format');
                        }

                        const base64String = dataUrl.split(',')[1];
                        const byteString = atob(base64String);
                        const arrayBuffer = new ArrayBuffer(byteString.length);
                        const uint8Array = new Uint8Array(arrayBuffer);

                        for (let i = 0; i < byteString.length; i++) {
                            uint8Array[i] = byteString.charCodeAt(i);
                        }

                        const blob = new Blob([uint8Array], { type: 'image/jpeg' });
                        const timestamp = new Date().toISOString().replace(/[:.-]/g, '');
                        const filename = `captured-image-${timestamp}.jpg`;
                        const file = new File([blob], filename, { type: 'image/jpeg' });
                        return file;
                    } catch (error) {
                        console.error('Failed to convert base64 to file:', error);
                        return null;
                    }
                }).filter(file => file !== null);
                this.uploadedImages = []
                this.images.forEach(e=>{
                    e.upload_type = 3,
                    this.uploadedImages.push(e)
                })
                let fileData = []
                for (let i = 0; i < this.uploadedImages.length; i++) {
                    const element = this.uploadedImages[i]
                    let reader = new FileReader()
                    reader.readAsDataURL(element)
                    new Promise((resolve)=>{
                        reader.onloadend = function () {
                            fileData.push({
                                name: element.name,
                                type: element.type,
                                data: reader.result.split(',')[1],
                                upload_type: element.upload_type,
                            })
                            resolve()
                        }
                    })
                }
                this.uploadedImages = fileData
            },

            clearCameraCapture(){
                this.$refs.cameraCapture.reset();
                this.images = null;
            },

            closeCameraCapture(){
                this.fileUploadDefective = false;
                this.clearCameraCapture()
                this.clearFileUpload(confirm)
                this.$refs.cameraCapture.reset();
                this.$refs.fileUpload.reset();
            },
            nextItem(action){
                const cacheAutonext = this.SELECTED.autonext;

                if(action == 1 && this.SELECTED.autonext === 0){
                    if(this.index > 0){
                        this.index -= 1
                        this.$store.commit('SELECTED',{...this.DATA_TABLE.items[this.index], autonext: cacheAutonext})
                    }else{
                        this.index = this.DATA_TABLE.items.length - 1
                        let payload = this.DATA_TABLE.payload
                        this.keys.page == 1 ? 1 : this.keys.page -= 1
                        payload.page = this.keys.page
                        this.$store.dispatch('adminPurchaseOrderGet', payload).then((response) => {
                            if(response.data.data.length != 0){
                                this.$store.commit('DATA_TABLE_ITEMS',response.data.data)
                                this.$store.commit('SELECTED',{...this.DATA_TABLE.items[this.DATA_TABLE.items.length - 1], autonext: cacheAutonext})
                            }else{
                                this.resetFields()
                            }

                        }).catch((e)=>{
                            this.loaded = true;
                        });
                    }
                }else if (action == 2 && this.SELECTED.autonext === 0){

                    if(this.index < this.DATA_TABLE.items.length - 1){
                        this.index += 1
                        this.$store.commit('SELECTED',{...this.DATA_TABLE.items[this.index], autonext: cacheAutonext})
                    }else{
                        this.index = 0
                        let payload = this.DATA_TABLE.payload
                        this.keys.page = parseInt(this.keys.page) + 1
                        payload.page = this.keys.page
                        this.$store.dispatch('adminPurchaseOrderGet', payload).then((response) => {
                            if(response.data.data.length != 0){
                                this.$store.commit('DATA_TABLE_ITEMS',response.data.data)
                                this.$store.commit('SELECTED',{...this.DATA_TABLE.items[0], autonext: cacheAutonext})
                            }else{
                                this.resetFields()
                            }

                        this.$store.commit('NEW_ENTER_BILL',false);
                        }).catch((e)=>{
                            this.loaded = true;
                        });
                    }
                }
            },
            handleKeydown(event){
                if((event.key == 'ArrowLeft' || this.keys.action == 1) && this.ACTION == 'View'){
                    if(this.index > 0){
                        this.index -= 1
                        this.$store.commit('SELECTED',this.DATA_TABLE.items[this.index])
                    }else{
                        this.index = this.DATA_TABLE.items.length - 1
                        let payload = this.DATA_TABLE.payload
                        this.keys.page == 1 ? 1 : this.keys.page -= 1
                        payload.page = this.keys.page
                        this.$store.dispatch('adminPurchaseOrderGet', payload).then((response) => {
                            if(response.data.data.length != 0){
                                this.$store.commit('DATA_TABLE_ITEMS',response.data.data)
                                this.$store.commit('SELECTED',this.DATA_TABLE.items[this.DATA_TABLE.items.length - 1])
                            }
                        }).catch((e)=>{
                            this.loaded = true;
                        });
                    }
                }else if ((event.key == 'ArrowRight' || this.keys.action == 2) && this.ACTION == 'View'){
                    if(this.index < this.DATA_TABLE.items.length - 1){
                        this.index += 1
                        this.$store.commit('SELECTED',this.DATA_TABLE.items[this.index])
                    }else{
                        this.index = 0
                        let payload = this.DATA_TABLE.payload
                        this.keys.page = parseInt(this.keys.page) + 1
                        payload.page = this.keys.page
                        this.$store.dispatch('adminPurchaseOrderGet', payload).then((response) => {
                            if(response.data.data.length != 0){
                                this.$store.commit('DATA_TABLE_ITEMS',response.data.data)
                                this.$store.commit('SELECTED',this.DATA_TABLE.items[0])
                            }
                        this.$store.commit('NEW_ENTER_BILL',false);
                        }).catch((e)=>{
                            this.loaded = true;
                        });
                    }
                }else if(event.key == 'Escape' && !this.batchAddition.dialog){
                  this.closeDialog();
                }
            },
            sumOfItems(){
                this.total = this.form.item_list.reduce((total, item) => total + parseFloat(item.total_amount), 0)
            },
            async getBranches(){
                try{
                    this.clonedStores = await this.getStoreBranches()
                } catch (error) {
                    console.error(error)
                }
            },
            insertCompany(storeId){
                if (!storeId) return
                let store = this.clonedStores.find(e=>e.value == storeId)
                this.form.company_id = this.GET_PV_COMPANIES_SELECTION.find(e=>e.value == store.company_id).value
            },
            insertStore(){
                this.form.store_branch_id = this.branchItems[0].value
            },
            async imageLoader(imagePath){
                let image = await this.loadImage(imagePath);
                this.$store.commit('COMPANY_IMAGE', image);
            },
        },
        watch: {
            // file_index:{
            //     handler(val){
            //         console.log('file_index',val)
            //     },
            //     deep: true
            // },
            'form.company_id': {
                handler(val) {
                    if (!val) {
                        this.form.store_branch_id = null
                    } else {
                        const storeBranchIds = this.GET_PV_STORE_BRANCHES_SELECTION
                            .filter(selection => selection.company_id == val)
                            .map(selection => selection.value);
                        if (!storeBranchIds.includes(this.form.store_branch_id)){
                            this.form.store_branch_id = undefined;
                        }
                    }
                },
                immediate: true
            },
            'form.store_branch_id':{
                handler(val){
                    if (!val) {
                        this.form.company_id = null
                    } else {
                        const storeBranchCompanyId = this.GET_PV_STORE_BRANCHES_SELECTION
                            .filter(selection => selection.value == val)
                            .shift().company_id;
                        this.form.company_id = storeBranchCompanyId;
                    }
                },
                immediate: true
            },
            'form.selected':{
                handler(item){
                    if(this.ACTION == 'Create'){
                        this.form.total_amount = 0
                        if(item.length > 0){

                            let sum = 0;
                            item.forEach(e=>{
                                let split = e.split(':')
                                sum+= parseInt(split[5]);
                            })
                            this.form.total_amount = sum;
                            this.form.select_all = false
                            let tmp = false
                            this.form.vendor_id_items.forEach((e,x)=>{
                                if(e.item_from_prf == item.prf_item_id){
                                    this.form.vendor_id_items.splice(x,1);
                                    tmp = true
                                }
                            });
                        }
                        else{
                            this.form.select_all = false
                        }
                    }
                }
            },
            'form.typeOf':{
                handler(val){
                    this.form.vendor_id_items = []
                }
            },
            'form.vendor_id': {
                async handler(newVal, oldVal) {
                    if(newVal){
                        await this.$store.dispatch("getContactPersonSelection", newVal);
                        await this.$store.dispatch("getTermSelection", newVal);
                        this.$store.dispatch('getPRFAdminItems',{
                            vendor_id:newVal
                        }).then(response=> {
                            this.selection.admin_items = response
                            this.form.location_id = this.ACTION == 'Submit' ? this.GET_ADMIN_DELIVERY_ADDRESSES.find(item => true)?.value : this.form.location_id;
                            this.form.vendor_address_id = this.ACTION == 'Submit' ? this.GET_CONTACT_PERSON_SELECTION.find(item => true)?.value : this.form.vendor_address_id;
                            this.form.vendor_payment_item_id = this.ACTION == 'Submit' ? this.GET_TERM_SELECTION.find(item => true)?.value : this.form.vendor_payment_item_id;

                            if(this.ACTION !== 'View' && (oldVal !== undefined && oldVal !== '')){
                                if(newVal !== oldVal){
                                    this.form.item_list = [
                                        { value:'', total_amount:'', receive_qty: '', received_qty: '',  uom:'',vendor:''}
                                    ]
                                }
                            }
                        })
                    }
                },
                immediate: true
            },
            'form.item_list':{
                handler(val) {
                    this.selection.admin_items.forEach(e=>{
                        val.forEach(item => {
                            if(e.admin_item_id === item.admin_item_id){
                                e.itemDisabled = true
                            }
                        })
                    })
                },
                deep: true
            },
            'form.po_id': {
                handler(val) {
                    if (val) {
                    this.clearFileUpload(true);
                        this.uploadedFiles = {
                            attachments: [],
                        }
                    }
                },
                deep: true
            },
            //   GET_NEW_PURCHASE_ORDER_TABLE:{
            //     handler(val){
            //       if(val){
            //         this.$store.commit('ACTION', '');
            //         this.$store.commit("DIALOG", false);
            //         this.resetFields();
            //         let swal_obj = {
            //           title:'Success!',
            //           text:'Purchase Order Save'
            //         }
            //         this.$store.dispatch('SwalAlertSuccess',swal_obj)
            //         this.$store.dispatch("getPRFSelection");
            //       }
            //     }
            //   },
            SELECTED:{
                async handler(val){
                    this.form.type = 0
                    this.loading = true
                    this.print_loading = true
                    this.$store.dispatch("getPaymentVoucherBranchesSelection", {company_id: null});
                    if(!!val && this.ACTION !== 'Submit'){
                        await this.$store.dispatch('adminPurchaseOrderGet',{
                            url:`getPurchaseOrderForPrint/${this.PO_RECEIVING_DIALOG ? val.po_id : val.id}`,
                            has_fas:val.has_fas,
                            fas:val.fas?val.fas:[]
                        }).then(response=>{
                            // hidden foc issue but the total will be affected
                            // response.item_list = response.item_list.filter(e=>e.total_amount = parseInt(e.total_amount))
                            if(!this.DIALOGS){
                                return
                            }else if(this.ACTION != 'Submit'){
                                this.form = response
                                Object.assign(this.form,{dr_number:'',received_date:this.$dayjs().format('YYYY-MM-DD'),
                                has_fas:val.has_fas,
                                fas:val.fas?val.fas:[]})
                            }
                            if(val.hasOwnProperty('selected_index')){
                                this.index = val.selected_index
                                this.keys.page = this.DATA_TABLE.option.page ?? 1
                            }

                            this.warehouse_selections = response.item_list[0].item_warehouse_id
                            this.form.item_list.forEach(e =>{ //Auto Select of Item Warehouse default C8
                               e.item_warehouse_id.forEach(i =>{
                                if(i.default == 1){
                                    e.item_warehouse_id = i.value
                                }
                               })
                            })
                            this.old_ids = response.item_list.map(e => e.id)
                            this.$store.commit('PURCHASE_ORDER_DATA', response)
                            this.imageLoader(response.image_path)
                            this.$store.dispatch('systemGet', {url:'get-system-theme'}).then((res)=>{
                                if(res.length > 0){
                                    this.$store.commit('SYSTEM_THEME', res[0])
                                    this.print_loading = false
                                }
                            })
                        }).catch(e=>{
                            console.log(e)
                        })
                    }
                    // this.cashOrPercentage(this.form.amount_to_pay, this.form.type)
                    setTimeout(()=>{
                        this.loading = false
                    },1000)

                    this.$forceUpdate();
                },
                deep:true,
                immediate:true
            },
            // totalAmount:{
            //     handler(val){
            //         if (val) {
            //             let vatAmount = val * (parseFloat(this.form.vat_percentage) / 100)
            //             // console.log(vatAmount)
            //             this.$store.commit('PO_VAT', vatAmount)
            //         }
            //     },
            //     immediate: true
            // }
            GET_ADM_WAREHOUSE_CODE_DROPDOWN:{
                handler(val){
                    this.admin_warehouse_dropdown = val
                }
            },
            'form.admin_warehouse_id':{
                handler(val){
                    if (val) {
                        this.form.item_list.forEach(item => {
                            item.item_warehouse_id = val;
                        });
                    }
                },
            },
            'form.total_amount':{
                handler(val){
                    if (val && this.form.type == 1 && this.form.payment_method === 3) {
                        //Fix the amount to pay on Partial Payment on viewing or editing of PO
                        this.form.amount_to_pay = ((this.form.amount_to_pay/val) * 100 ).toFixed(2)
                    }
                },
            },
        },
    };
</script>
<style scoped>
    .red-text {
      color: red;
    }
    .card{
        overflow: hidden;
    }
    div.ribbon-wrap{
        width: 150px;
        height: 40px;
        position: absolute;
        top: -3px;
        left: -44px;
        pointer-events: none;
        z-index: 1;
    }
    .ribbon{
        width: 190px;
        font-size: 0.700rem;
        text-align: center;
        padding: 1px 0;
        background: #FF6B6B;
        color: #fff;
        position: absolute;
        transform: rotate(-40deg);
        right: -17px;
        top: 29%;
    }
    .resize-icon .v-icon {
        font-size: 10px; /* Adjust size as needed */
      }
</style>
