<template>
    <v-container class="pa-2 ma-0">
        <v-row class="ma-1">
            <v-col cols="12" class="d-flex no-block align-items-center">
                <h4 class="page-title" v-if="origin == 1">Credit Memo (Local)</h4>
                <h4 class="page-title" v-else-if="origin == 2">Credit Memo (China)</h4>
                <h4 class="page-title" v-else>Credit Memo</h4>
            </v-col>
        </v-row>

        <v-row class="pa-0 mx-2" v-if="origin == 2">
            <v-col class="col-lg-12 col-sm-12 d-flex justify-content-end">
                <v-btn dark class="pa-0">
                    <v-menu
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="350px"
                    >
                        <template v-slot:activator="{ on }">
                        <v-text-field
                            style=""
                            v-model="selectedRange.from"
                            solo
                            dense
                            readonly
                            append-icon="mdi-calendar-clock"
                            v-on="on"
                            label="Start Date"
                            class="text-field-width-year mt-4"
                        ></v-text-field>
                        </template>
                        <v-date-picker v-model="selectedRange.from" @change="refreshData()">
                        </v-date-picker>
                    </v-menu>
                    <v-menu
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="350px"
                        color="white"
                    >
                        <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="selectedRange.to"
                            solo
                            dense
                            readonly
                            append-icon="mdi-calendar-clock"
                            v-on="on"
                            label="End Date"
                            color="white"
                            class="text-field-width-year mt-4"
                        ></v-text-field>
                        </template>
                        <v-date-picker v-model="selectedRange.to" @change="refreshData()">
                        </v-date-picker>
                    </v-menu>
                </v-btn>
                <!-- :disabled="(!selectedRange.from || !selectedRange.to) && (!year_range.start || !year_range.end)" -->
            </v-col>
        </v-row>
        <v-row class="pa-0 mx-2 my-5">
            <v-tabs v-model="tab" id="group-tab" color="cyan" background-color="#424242" dark slider-color="cyan" class="col-12" show-arrows @change="refreshData()" v-if="origin == 1">
                <v-tab ripple>ALL</v-tab>
                <v-tab ripple>ON PROCESS</v-tab>
                <v-tab ripple>APPROVAL - ACCT</v-tab>
                <!-- <v-tab ripple>APPROVAL - EXEC</v-tab> -->
                <v-tab ripple>APPROVED</v-tab>
                <v-tab ripple>CANCELLED</v-tab>
                <v-spacer></v-spacer>
                <v-divider vertical></v-divider>
                <v-tab ripple>Classification</v-tab>
                <v-tab ripple>Type</v-tab>

                <v-tabs-items v-model="tab" touchless>
                    <!-- ALL -->
                    <v-tab-item>
                        <CreditMemoComponentVue :refreshData="refresh_data[origin].all" :print="print" :origin="origin"></CreditMemoComponentVue>
                    </v-tab-item>

                    <!-- ON PROCESS -->
                    <v-tab-item>
                        <CreditMemoComponentVue :status_text="'for_processing'" :refreshData="refresh_data[origin].for_processing" :print="print" :origin="origin"></CreditMemoComponentVue>
                    </v-tab-item>

                    <!-- APPROVAL - ACCT -->
                    <v-tab-item>
                        <CreditMemoComponentVue :status_text="'for_acct_approval'" :refreshData="refresh_data[origin].for_acct_approval" :print="print" :origin="origin">
                        </CreditMemoComponentVue>
                    </v-tab-item>

                    <!-- APPROVAL - EXEC -->
                    <!-- <v-tab-item>
                        <CreditMemoComponentVue :status_text="'for_exec_approval'" :refreshData="refresh_data[origin].for_exec_approval" :print="print" :origin="origin">
                        </CreditMemoComponentVue>
                    </v-tab-item> -->

                    <!-- APPROVED -->
                    <v-tab-item>
                        <CreditMemoComponentVue :status_text="'approved'" :refreshData="refresh_data[origin].approved" :print="print" :origin="origin"></CreditMemoComponentVue>
                    </v-tab-item>

                    <!-- CANCELED -->
                    <v-tab-item>
                        <CreditMemoComponentVue :status_text="'cancelled'" :refreshData="refresh_data[origin].canceled" :print="print" :origin="origin"></CreditMemoComponentVue>
                    </v-tab-item>

                    <v-tab-item class="mr-0">
                        <CreditMemoClassificationComponentVue :origin="origin"></CreditMemoClassificationComponentVue>
                    </v-tab-item>

                    <v-tab-item class="mr-0">
                        <CreditMemoTypeComponentVue :origin="origin"></CreditMemoTypeComponentVue>
                    </v-tab-item>
                </v-tabs-items>
            </v-tabs>

            <v-tabs v-model="main_tab" id="group-tab" color="cyan" background-color="#424242" dark slider-color="cyan" show-arrows @change="refreshData()" v-if="origin == 2">
                <v-tab v-for="(cm_china_type,i) in cm_china_types" :key="i"
                @click="selected_type = cm_china_type.value; otherTab = ''">
                    {{ cm_china_type.code }}
                </v-tab>
                <v-spacer></v-spacer>
                <v-divider vertical></v-divider>
                <v-tab @click="otherTab = 'Classification'">Classification</v-tab>
                <v-tab @click="otherTab = 'Type'" ripple>Type</v-tab>
            </v-tabs>
            <v-tabs v-model="tab" id="group-tab" color="cyan" background-color="#FFF" slider-color="cyan" show-arrows @change="refreshData()" v-if="origin == 2 && otherTab == ''">
                <v-tab ripple>ALL</v-tab>
                <v-tab ripple>CONFIRMED</v-tab>
                <v-tab ripple>PENDING</v-tab>
                <v-tab ripple>APPROVED</v-tab>
                <v-tab ripple>CANCELED</v-tab>
                <v-spacer></v-spacer>
                <v-divider vertical></v-divider>
                <v-tab ripple>SOA</v-tab>
                <v-tabs-items v-model="tab" touchless>
                    <!-- ALL -->
                    <v-tab-item>
                        <CreditMemoComponentVue :status_text="''" :refreshData="refresh_data[origin].all" :print="print" :origin="origin" :selected_type="selected_type" :cm_date_range="selectedRange"></CreditMemoComponentVue>
                    </v-tab-item>

                     <!-- CONFIRMED -->
                     <v-tab-item>
                        <CreditMemoComponentVue :status_text="'confirmed'" :refreshData="refresh_data[origin].confirmed" :print="print" :origin="origin" :selected_type="selected_type" :cm_date_range="selectedRange"></CreditMemoComponentVue>
                    </v-tab-item>

                     <!-- PENDING -->
                     <v-tab-item>
                        <CreditMemoComponentVue :status_text="'pending'" :refreshData="refresh_data[origin].pending" :print="print" :origin="origin" :selected_type="selected_type" :cm_date_range="selectedRange"></CreditMemoComponentVue>
                    </v-tab-item>

                    <!-- SAVED -->
                    <v-tab-item>
                        <CreditMemoComponentVue :status_text="'approved'" :refreshData="refresh_data[origin].approved" :print="print" :origin="origin" :selected_type="selected_type" :cm_date_range="selectedRange"></CreditMemoComponentVue>
                    </v-tab-item>

                    <!-- CANCELED -->
                    <v-tab-item>
                        <CreditMemoComponentVue :status_text="'canceled'" :refreshData="refresh_data[origin].canceled" :print="print" :origin="origin" :selected_type="selected_type" :cm_date_range="selectedRange"></CreditMemoComponentVue>
                    </v-tab-item>

                    <!-- SOA -->
                    <v-tab-item>
                        <CreditMemoComponentVue :status_text="'canceled'" :refreshData="refresh_data[origin].canceled" :print="print" :origin="origin" :tab_name=true></CreditMemoComponentVue>
                    </v-tab-item>

                </v-tabs-items>
            </v-tabs>
            <CreditMemoClassificationComponentVue :origin="origin" v-if="isVisible('Classification')"></CreditMemoClassificationComponentVue>
            <CreditMemoTypeComponentVue :origin="origin" v-if="isVisible('Type')"></CreditMemoTypeComponentVue>
        </v-row>
    </v-container>
</template>
<script>
import CreditMemoComponentVue from './CreditMemoComponent.vue';
import CreditMemoTypeComponentVue from '../CreditMemoType/CreditMemoTypeComponent.vue'
import CreditMemoClassificationComponentVue from '../CreditMemoClassification/CreditMemoClassificationComponent.vue'
import { mapGetters } from 'vuex';
import ReportsCardsComponentVue from '@/views/main/layouts/Reports/ReportsCardsComponent.vue';
export default {
    components: {
        CreditMemoComponentVue,
        CreditMemoTypeComponentVue,
        CreditMemoClassificationComponentVue
    },
    props: [
        'origin',
    ],
    async mounted() {
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id);
        this.$store.dispatch('getAllCustomers');
        this.$store.dispatch('getCreditMemoTypeStatus')
        this.getAllCreditMemoTypes();
    },
    data() {
        return {
            tab: null,
            main_tab:null,
            refresh_data: {
                1: {
                    all: false,
                    for_processing: false,
                    for_acct_approval: false,
                    for_exec_approval: false,
                    approved: false,
                    canceled: false
                },
                2: {
                    all: false,
                    confirmed: false,
                    pending: false,
                    approved: false,
                    canceled: false
                }
            },

            tab_names: {
                1: [
                    'all',
                    'for_processing',
                    'for_acct_approval',
                    'for_exec_approval',
                    'approved',
                    'canceled'
                ],
                2: [
                    'all',
                    'confirmed',
                    'pending',
                    'approved',
                    'canceled'
                ]
            },

            print: {
                credit_memo_num: '',
                soa_month_year: '',
                credit_memo_date: '',
                customer_name: '',
                packing_list_nums: '',
                billing_address_text: '',
                customer_ref_no: '',
                billing_contact_person: '',
                billing_contact_number: '',
                credit_memo_items: [],
                remarks: '',
                total_amount: 0,
                created_by_name: '',
                acct_approved_by_name: '',
                exec_approved_by_name: '',
            },
            selection:[
                {
                    text:'Local',
                    value:1
                },
                {
                    text:'International',
                    value:2
                }
            ],
            selected:1,
            cm_china_types:[],
            selected_type:0,
            selectedRange:{
                from: this.$dayjs().startOf('year').format('YYYY-MM-DD'),
                to: this.$dayjs().endOf('year').format('YYYY-MM-DD')
            },
            otherTab:''
        }
    },
    methods: {
        refreshData() {
            this.refresh_data[this.origin][this.tab_names[this.origin][this.tab]] = true
            setTimeout(() => {
                this.refresh_data[this.origin][this.tab_names[this.origin][this.tab]] = false
            }, 500)
        },
        getAllCreditMemoTypes(){
            let payload = {
                origin:this.origin
            }
            this.$store.dispatch('getCreditMemoTypes',payload)
            this.$store.dispatch('getCreditMemoTypesCardTotal',payload)
        },
        isVisible(cp){
            if(cp == this.otherTab) return true
        }
    },
    computed:{
        ...mapGetters([
            'GET_CREDIT_MEMO_TYPES'
        ])
    },
    watch: {
        origin: {
            handler() {
                this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
            }
        },
        selected:{
            handler(){
                this.refreshData()
            }
        },
        GET_CREDIT_MEMO_TYPES:{
            handler(val){
                this.cm_china_types = []
                this.cm_china_types.push({
                    code:'All',
                    value:'0',
                })
                val.forEach(e=>{
                    this.cm_china_types.push(e)
                })
                this.main_tab = 2
            }
        }
    }
};
</script>
<style scoped>
#group-tab {
    padding: 0 !important;
}
.text-field-width-year {
    width: 160px !important;
    margin: 5px 2px 0px 2px !important;
}
</style>
