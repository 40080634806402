<template>
    <div class="w-100">
        <v-app id="my_vue_app">
            <v-card>
                <v-container fluid>
                    <v-row class="pa-3">                                                                <!--Placement of Selection and Button Actions-->
                        <v-col cols="2">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }" >
                                    <v-btn :disabled="editor_data ? false: true" v-on="on" v-bind="attrs" text @click="preview()">
                                        <v-icon color="orange" class="font-size-12">mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                                    <span>Preview</span>
                            </v-tooltip>

                            <!-- <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }" > -->
                                    <v-badge
                                        bordered
                                        color="primary"
                                        overlap
                                        v-if="!isSaved"
                                    >
                                        <v-btn text @click="saveEmailSettings()">
                                            <v-icon :color="isSaved ? 'blue': ''" class="font-size-12">mdi-content-save</v-icon>
                                        </v-btn>
                                    </v-badge>

                                    <v-btn v-if="isSaved"  text @click="saveEmailSettings()">
                                        <v-icon :color="isSaved ? 'blue': ''" class="font-size-12">mdi-content-save</v-icon>
                                    </v-btn>
                                <!-- </template>
                                    <span>Save</span>
                            </v-tooltip> -->
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>
                    <v-row class="pa-3">
                        <v-col cols="4">
                            <v-card
                                class="mx-auto"
                                elevation="1"
                            >
                                <v-card-title>
                                    <v-text-field
                                        @keydown.enter="addToRecipient"
                                        @input="changeTrigger()"
                                        v-model="email_to_field"
                                        label="To:"
                                        append-icon="mdi-plus"
                                    ></v-text-field>
                                </v-card-title>
                                <v-card-text
                                    style="max-height:200px; overflow:auto"
                                >
                                    <v-chip
                                    v-for="(email_to, i) in form.recipient_to"
                                    :key="i"
                                    :value="email_to"
                                    close
                                    @click:close="removeEmail(i,'recipient_to',email_to.id)"
                                    >
                                    {{ email_to.email }}
                                    </v-chip>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="4">
                            <v-card
                                class="mx-auto"
                                elevation="1"
                            >
                                <v-card-title>
                                    <v-text-field
                                        @keydown.enter="addCcRecipient"
                                        @input="changeTrigger()"
                                        v-model="email_cc_field"
                                        label="Cc:"
                                        append-icon="mdi-plus"
                                    ></v-text-field>
                                </v-card-title>
                                <v-card-text
                                    style="max-height:200px; overflow:auto"
                                >
                                    <v-chip
                                    v-for="(email_to, i) in form.recipient_cc"
                                    :key="i"
                                    :value="email_to"
                                    close
                                    @click:close="removeEmail(i,'recipient_cc',email_to.id)"
                                    >
                                    {{ email_to.email }}
                                    </v-chip>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="4">
                            <v-card
                                class="mx-auto"
                                elevation="1"
                            >
                                <v-card-title>
                                    <v-text-field
                                        @keydown.enter="addBccRecipient"
                                        @input="changeTrigger()"
                                        v-model="email_bcc_field"
                                        label="Bcc:"
                                        append-icon="mdi-plus"
                                    ></v-text-field>
                                </v-card-title>
                                <v-card-text
                                    style="max-height:200px; overflow:auto"
                                >
                                    <v-chip
                                    v-for="(email_to, i) in form.recipient_bcc"
                                    :key="i"
                                    :value="email_to"
                                    close
                                    @click:close="removeEmail(i,'recipient_bcc',email_to.id)"
                                    >
                                    {{ email_to.email }}
                                    </v-chip>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row class="px-3">
                        <v-col cols="4">
                            <v-text-field
                                v-model="form.subject"
                                @input="changeTrigger()"
                                label="Subject"
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                        <v-col class="d-flex justify-content-start pl-3">
                            <v-switch
                                v-model="form.with_attachment"
                                @input="changeTrigger()"
                                label="With Attachment"
                                hide-details
                                dense
                                :true-value="1"
                                :false-value="0"
                            >
                            </v-switch>
                        </v-col>
                    </v-row>
                    <v-row ref="scrollableRow" class="pa-3 overflow-auto" style="max-height: 60vh;">    <!--Rich Text Editor-->
                        <v-col cols="12">
                            <v-card elevation="1">
                                <ckeditor :editor="editorOptions.editor" v-model="editor_data" :config="editorOptions.editorConfig"></ckeditor>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
                <v-dialog v-model="preview_dialog" width="35%" persistent>                                         <!--Preview of the Settings-->
                     <div class="preview" >
                        <v-card class="">
                            <v-card-title class="pr-0 pt-0 pl-3 pb-0">
                                <v-row class="m-0">
                                    <v-col cols="pull-right-10 p-2">
                                        <v-btn
                                            text
                                            icon
                                            small
                                            color="gray"
                                            class="float-right"
                                            @click="closeDialog()"
                                        >
                                            <v-icon>mdi-close-circle</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <div class="px-8 py-0" v-html="editor_data"></div>
                        </v-card>
                    </div>
                </v-dialog>
            </v-card>
        </v-app>
    </div>
</template>
<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import Swal from 'sweetalert2'

import { mapGetters } from 'vuex';
export default {
    props:["isActive_tab","module_id","module_html","module_to","module_cc","module_bcc","module_email_message_id","module_with_attachment" ,"module_subject"],
    data(){
        return {
            userAccess: {
                view: false,
                create: false,
                edit: false,
                delete: false,
            },
            editorOptions:{
                editor: ClassicEditor,
                editorData: '',
                editorConfig: {
                },
            },
            dropdowns:{
                company_selection:[]
            },
            form:{
                html: '',
                id: '',
                recipient_to: [],
                recipient_cc: [],
                recipient_bcc: [],
                recipient_trash_ids: [],
                with_attachment: 0,
                subject: ''
            },

            preview_dialog: false,
            isSaved: true,
            tmp:false,
            editor_data:'',
            email_to_field:'',
            email_cc_field:'',
            email_bcc_field:'',
        }
    },
    async mounted(){
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        // await this.$store.dispatch('getCompanySelection');
        // await this.$store.dispatch(this.GET_HR_DISPATCH.get)
        // await this.setDefaultValues()
        // document.addEventListener("keydown", this.handleKeyDown);
        window.addEventListener('beforeunload', this.confirmClose);
        this.propsData
    },
    computed:{
        ...mapGetters([
            'GET_EMAIL_SETTINGS'
        ]),
        propsData(){
            this.editor_data = this.module_html ?? ''
            this.form.recipient_to = this.module_to ?? ''
            this.form.recipient_cc = this.module_cc ?? ''
            this.form.recipient_bcc = this.module_bcc ?? ''
            this.form.with_attachment = this.module_with_attachment ?? 0
            this.form.subject = this.module_subject ?? ''
            this.tmp = true
        }
    },
    components:{
    },
    beforeUnmount() {
        window.removeEventListener('beforeunload', this.confirmClose);
    },
    methods:{
        removeEmail(index,recipient_type,trash_id){
            this.form[recipient_type].splice(index,1)
            this.form.recipient_trash_ids.push(trash_id);
        },
        addToRecipient(item){
            let input = item.target.value;
            this.form.recipient_to.push({
                id: '',
                email: input,
                recipient_type: 'to'
            })
            this.email_to_field = ''
        },
        addCcRecipient(item){
            let input = item.target.value;
            this.form.recipient_cc.push({
                id: '',
                email: input,
                recipient_type: 'cc'
            })
            this.email_cc_field = ''
        },
        addBccRecipient(item){
            let input = item.target.value;
            this.form.recipient_bcc.push({
                id: '',
                email: input,
                recipient_type: 'bcc'
            })
            this.email_bcc_field = ''
        },
        confirmClose(event) {
            if (!this.isSaved) {
                event.preventDefault();
                event.returnValue = '';
                return 'Are you sure you want to leave this page?';
            }
        },
        handleKeyDown(event) {
        // Check if Ctrl + S is pressed
            if (event.ctrlKey && event.key === "s") {
                event.preventDefault(); // Prevent default browser save behavior
                this.saveEmailSettings();
            }
        },
        // async setDefaultValues() {
        //     Object.assign(this.form, {
        //         company_id: this.defaults.company_id,
        //     })
        //     this.changeSettings()
        // },
        checkRowScrollable() {
            const row = this.$refs.scrollableRow;
            if(row){
                const isScrollable = row.clientHeight < row.scrollHeight;
                if (isScrollable) {
                } else {
                    $('.ck').find('.ck-sticky-panel').removeClass('ck-sticky-panel')
                }
            }
        },
        saveEmailSettings(){
			this.form.html              = this.editor_data;
            this.form.id                = this.module_id
            this.form.email_message_id  = this.module_email_message_id
            this.$store.dispatch('addModuleEmailSettings', this.form).then(response=>{
                Swal.fire(response.icon.toUpperCase(),response.message,response.icon);
                    this.isSaved = true

            }).catch((err)=>{
                Swal.fire({
                    title: 'Error',
                    text: 'Network Error',
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
                console.log(err)
            })
        },
        preview(){
            this.preview_dialog = true
        },
        closeDialog(){
            this.preview_dialog = false
        },
        changeTrigger(){
            this.isSaved = false
        }
    },
    watch:{
        USER_ACCESS: {
            handler(val) {
                if(val != "fail"){
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                this.userAccess.create = false;
                    val.forEach(e => {
                        if (e.actions_code == 'edit') {
                            this.userAccess.edit = true;
                        } else if (e.actions_code == 'delete') {
                            this.userAccess.delete = true;
                        } else if (e.actions_code == 'view') {
                            this.userAccess.view = true;
                        } else if (e.actions_code == 'create') {
                            this.userAccess.create = true;
                        }
                    });
                }
            }
        },
         'editor_data': {
            handler(val){
                if(this.tmp != true){
                    this.isSaved = false
                }
                this.tmp = false
                this.checkRowScrollable()
            }
        },
    }
}
</script>
<style scoped>

</style>
