<template>
<div>
    <v-app id="load_bar" v-if="!loaded">
        <v-text-field color="info" loading disabled></v-text-field>
    </v-app>
    <v-app id="view_client_modal">
        <v-dialog v-model="inbound_dialog" persistent max-width="98%" scrollable>
            <v-card>
                <v-row class="m-0">
                    <v-col cols="pull-right-10 p-2">
                    <v-card-title>
                        <span class="headline">Inbound Details</span>
                    </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="clearInboundItems(); $emit('closeView');">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text>
                    <v-row class="m-0">
                        <v-container fluid>
                            <v-row>
                                <div class="col-sm-12 col-md-6 text-left text-dark">
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-4 p-0">Issuer: {{ inbound.user_name }}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">Inbound: {{ inbound.inbound_num }}</div>
                                        <div class="col-sm-12 col-md-4 p-0">Date Issued: {{ inbound.created_at | formatDateTime }}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">Warehouse: {{ inbound.warehouse_name }}</div>
                                        <div class="col-sm-12 col-md-4 p-0">Status: {{ inbound.status_text }}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">Remarks: {{ inbound.remarks }}</div>
                                        <div class="col-sm-12 col-md-4 p-0">PO Number: {{inbound.po_number}}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">Broker: {{ inbound.broker }}</div>
                                        <div class="col-sm-12 col-md-4 p-0">Estimated Warehouse Delivery: {{ inbound.estimated_warehouse_delivery}}</div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 d-flex justify-end">
                                    <v-flex shrink class="pt-2">
                                        <BaseFilesViewer  :inboundId="inbound.inbound_id" :delete="true" :buttonColor="`#f69f1a`" :toolTipMessage="`View Attachments`"/>
                                    </v-flex>
                                    <!-- <v-flex shrink class="pr-2 pt-2">
                                        <v-tooltip bottom  color="#f69f1a">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        color="#f69f1a"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        small
                                                        text
                                                        fab
                                                        @click="showImageUpload()"
                                                    >
                                                        <v-icon>mdi-attachment</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Attach Image</span>
                                        </v-tooltip>
                                    </v-flex> -->
                                    <v-flex shrink class="pr-2 pt-2">
                                        <v-tooltip bottom  color="#f69f1a">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        color="#f69f1a"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        small
                                                        text
                                                        fab
                                                        @click="exportToExcel()"
                                                    >
                                                        <v-icon>mdi-printer-pos-wrench</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Export to Excel</span>
                                        </v-tooltip>
                                    </v-flex>
                                    <v-flex shrink class="pr-2 pt-2">
                                        <v-tooltip bottom  color="#f69f1a">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        color="#f69f1a"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        small
                                                        text
                                                        fab
                                                        @click="renderPDF('download')"
                                                    >
                                                        <v-icon>mdi-cloud-download</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Download PDF</span>
                                        </v-tooltip>
                                    </v-flex>
                                    <!-- <v-btn class="ma-1" @click="showImageUpload()">Upload Image</v-btn>
                                    <v-btn class="ma-1" @click="exportToExcel()">Export to Excel</v-btn>
                                    <v-btn class="ma-1" @click="renderPDF('download')">Download PDF</v-btn> -->
                                </div>
                            </v-row>
                        </v-container>
                    </v-row>

                    <v-data-table
                        :headers="inboundItemsHeaders"
                        :items="inboundItems"
                        :items-per-page="500"
                        hide-default-footer
                        class="w-100"
                        dense>
                        <template v-slot:item="{ item }">

                            <tr style="pointer-events: none;"
                                :class="{'inactive-container-item': item.item.active === 0,
                                    'searched-container-item': item.model.includes(searchText) || item.name.includes(searchText),
                                    'over-receive-container-item': item.received_type == 1 ?
                                        item.quantity_master_box > item.quantity_received :
                                        item.quantity > item.quantity_received,
                                    'under-receive-container-item': item.received_type == 1 ?
                                        item.quantity_master_box < item.quantity_received :
                                        item.quantity < item.quantity_received}" :key="item.id">

                                <td v-text="item.model"></td>
                                <td v-text="item.name"></td>
                                <td v-text="item.item_uom_text"></td>
                                <td v-text="item.item_type_text"></td>
                                <td class="text-center" v-text="item.quantity"></td>
                                <td class="text-center" v-text="item.quantity_received"></td>
                            </tr>
                        </template>
                    </v-data-table >

                    <div class="border-top">
                        <div class="card-body py-4 d-flex justify-content-center">
                            <span class="m-1">
                                <v-btn
                                    :loading="loading_approve"
                                    @click="approveInbound()"
                                    v-if="ACCESS_INBOUND.includes('approve_inbound') &&
                                            inbound.status_text=='pending' &&
                                            !is_receiving &&
                                            inbound.received_date !== null"
                                >
                                    Approve
                                </v-btn>
                            </span>
                            <span class="m-1">
                                <v-btn
                                    :loading="loading_reject"
                                    color="error"
                                    @click="rejectInbound()"
                                    v-if="ACCESS_INBOUND.includes('reject_inbound') &&
                                            inbound.status_text=='pending' &&
                                            !is_receiving"
                                >
                                    Reject
                                </v-btn>
                            </span>
                            <span class="m-1">
                                <v-btn
                                    :loading="loading_receive"
                                    @click="receiveInbound()"
                                    v-if="ACCESS_INBOUND.includes('receive_inbound') &&
                                            inbound.status_text=='pending' &&
                                            is_receiving &&
                                            inbound.received_date === null"
                                >
                                    Receive
                                </v-btn>
                            </span>
                        </div>
                    </div>
                </v-card-text>

            </v-card>
        </v-dialog>

        <v-container fluid style="display:none">
            <div>
                <table id="inbound_details">
                    <tbody>
                        <tr>
                            <td>Warehouse:</td>
                            <td>{{ inbound.warehouse_name }}</td>
                            <td>Inbound:</td>
                            <td>{{ inbound.inbound_num }}</td>
                        </tr>
                        <tr>
                            <td>Remarks:</td>
                            <td>{{ inbound.remarks }}</td>
                            <td>Issuer:</td>
                            <td>{{ inbound.user_name }}</td>
                        </tr>
                        <tr>
                            <td>Date Issued:</td>
                            <td>{{ inbound.created_at | formatDate }}</td>
                        </tr>
                        <tr>
                            <td>Broker</td>
                            <td>{{ inbound.broker }}</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>

                <table id="inbound_item_detials">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Model</th>
                            <th>Name</th>
                            <th>UOM</th>
                            <th>Type</th>
                            <th>Quantity</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(inboundItem, index ) in inboundItems" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td>{{ inboundItem.model }}</td>
                            <td>{{ inboundItem.name }}</td>
                            <td>{{ inboundItem.item_uom_text }}</td>
                            <td>{{ inboundItem.item_type_text }}</td>
                            <td>{{ inboundItem.quantity }}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td>***** NOTHING FOLLOWS *****</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
        </v-container>
        <!-- IMAGE UPLOAD DIALOG -->
        <!-- <v-dialog v-model="imageUpload" persistent max-width="20%">
            <v-card class="pa-4">
                <v-btn
                    text
                    icon
                    color="gray"
                    class="float-right"
                    @click="closeImageUpload()"
                >
                    <v-icon>mdi-close-circle</v-icon>
                </v-btn>
                <div class="py-2">
                    <span>Attach Image File</span><span style="color:red">  *</span>
                    <v-divider/>
                </div>
                <div class="py-2 mt-2">
                    <v-layout row class="mx-0">
                        <v-flex lg2 class="mr-2">
                            <BaseCameraCaptureVue ref="cameraCapture" @capturedImages="getImages" />
                        </v-flex>
                        <div class="mt-2">
                            <v-chip
                                v-if="!!images && images.length > 0"
                                color="#f69f1a"
                                outlined
                                close
                                @click:close="clearCameraCapture()"
                            >
                                <span class="mr-2">Images Count: {{ images.length }}</span>
                            </v-chip>
                        </div>
                    </v-layout>
                </div>

                <div class="py-2 mt-3">
                    <v-layout row class="mx-0">
                        <v-flex :class="{'mr-2': true, 'lg3': !!uploadedImageFile && uploadedImageFile.attachments.length > 1, 'lg6': !!uploadedImageFile && uploadedImageFile.attachments.length == 1}">
                            <FileUploadComponentVue ref="fileUpload" @uploadedData="uploadedData" @reset="clearImageUpload"/>
                        </v-flex>
                        <v-flex v-if="!!uploadedImageFile && uploadedImageFile.attachments.length > 0" class="ml-2" ></v-flex>
                            <div class="mt-2">
                                <v-chip
                                    v-if="!!uploadedImageFile && uploadedImageFile.attachments.length > 0"
                                    color="#f69f1a"
                                    outlined
                                    close
                                    @click:close="clearImageUpload()"
                                >
                                    <span class="mr-2">Upload Count: {{ uploadedImageFile.attachments.length }}</span>
                                </v-chip>
                            </div>
                        </v-flex>
                    </v-layout>
                </div>
                <div class="py-2 mt-3">
                    <v-card-actions class="d-flex justify-content-end">
                        <v-btn  @click="submitImageUpload()">Submit</v-btn>
                    </v-card-actions>
                </div>
            </v-card>
        </v-dialog> -->
    </v-app>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import swal from 'sweetalert2'
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
// import BaseCameraCaptureVue from '@/views/main/modules/Services/Base/BaseCameraCapture.vue';
// import FileUploadComponentVue from '@/views/main/Shared/FileUploadComponent.vue';
import BaseFilesViewer from '@/views/main/modules/Services/Base/BaseFilesViewer.vue';

export default {
    mixins:[SharedFunctionsComponentVue],
    props: [
    'viewInboundId',
    'searchText',
    'is_receiving',
    'user_access'
    ],
    components: {
        // FileUploadComponentVue,
        // BaseCameraCaptureVue,
        BaseFilesViewer
    },
    data () {
        return {
            inboundItems: [],
            inboundItemsHeaders: [
                { text: 'Model', align: 'left', value: 'model', width: '20%' },
                { text: 'Name', align: 'left', value: 'name', width: '45%' },
                { text: 'UOM', align: 'left', value: 'item_uom_text' },
                { text: 'Type', align: 'left', value: 'item_type_text' },
                { text: 'Quantity UOM', align: 'center', value: 'quantity' },
                { text: 'Receive Quantity UOM', align: 'center', value: 'quantity_received' },
            ],

            loaded: true,
            inbound_dialog: false,
            inbound: {
                inbound_id:'',
                inbound_num: '',
                warehouse_id: '',
                warehouse_name: '',
                remarks: '',
                user_name: '',
                created_at: '',
                status: '',
                status_text: '',
                broker: '',
                po_number: '',
                estimated_warehouse_delivery:''
                },

                errors: [],
                userAccess: {
                    approve: false,
                    reject: false,
                    receive: false
                },

            loading_approve: false,
            loading_reject: false,
            loading_receive: false,
            // imageUpload: false,
            // uploadedImageFile: null,
            // images: null,

            }
        },
        computed:{
            ...mapGetters([
                    'RECEIVE_INBOUND',
                    'APPROVE_INBOUND',
                    'REJECT_INBOUND',
                    'EXPORT_INBOUND',
                    'GET_ALL_INBOUND',
                    'ACCESS_INBOUND'
                    ]),
        },
        methods: {
            toggleLoadingButtons(val=false){
                this.loading_approve = val
                this.loading_reject = val
                this.loading_receive = val
            },
            approveInbound(){
                this.toggleLoadingButtons(true)
                let inactiveItems = this.inboundItems.filter(
                    item => item.item.active === 0
                ).map(
                    item => {
                        return `- ${item.model} : ${item.name}`;
                    }
                );

            let impartialReceiveItems = this.inboundItems.filter(
                item => item.quantity_master_box != item.quantity_received
                ).map(
                    item => {
                    return `- ${item.model} : ${item.name}`
                    }
                )

            let confirmInactive = `<br/><br/>Confirming approval will activate these inactive items.<br/><br/>${inactiveItems.join('<br/>')}`

            let impartialReceive = `<br/><br/>Confirming approval will receive partially received items.<br/><br/>${impartialReceiveItems.join('<br/>')}`

            let confirmApproval = 'Are you sure you want to Approve?';

            swal.fire({
                title: '',
                html: confirmApproval + (inactiveItems.length ? confirmInactive : '') + (impartialReceiveItems.length ? impartialReceive : ''),
                icon: 'warning',
                showConfirmButton: true,
                confirmButtonText: 'Ok',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: '397373',
                cancelButtonColor: 'grey',
                reverseButtons: true
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch('approveInbound',this.viewInboundId).then(response => {
                            let data = this.APPROVE_INBOUND

                            if(data.error){
                                swal.fire("", 'Approve Inbound Failed', "warning");
                                this.toggleLoadingButtons(false)
                                return false
                            }
                            this.resetFields()
                            this.$emit('changeInboundStatus')

                            this.inbound_dialog = false

                            swal.fire("", "Approve Inbound Succesfful", "success");
                            this.toggleLoadingButtons(false)
                        }).catch(error => {
                            swal.fire("An Error Occured", error, "error");
                            this.toggleLoadingButtons(false)
                        })
                    } else {
                        this.toggleLoadingButtons(false)
                    }
                });
            },
            rejectInbound(){
                this.toggleLoadingButtons(true)
                swal.fire({
                    title: "",
                    text: "Are you sure you want to Reject?",
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton:true,
                    showCloseButton:true,
                    confirmButtonText:'Reject',
                    reverseButtons:true,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch('rejectInbound',this.viewInboundId).then(response => {
                            let data = this.REJECT_INBOUND
                            if(data.error){
                                swal.fire("", "Reject Inbound Failed", "warning");
                                this.toggleLoadingButtons(false)
                                return false
                            }
                            this.resetFields()
                            this.$emit('changeInboundStatus')

                            this.inbound_dialog = false

                            swal.fire("", "Inbound Rejected", "success");
                            this.toggleLoadingButtons(false)
                        }).catch(error => {
                            swal.fire("An Error Occured", error, "error");
                            this.toggleLoadingButtons(false)
                        })
                    } else {
                        this.toggleLoadingButtons(false)
                    }
                });
            },
            receiveInbound(){
                this.toggleLoadingButtons(true)
                swal.fire({
                    title: "",
                    text: "Are you sure you want to Receive? This will non-amendable afterwards.",
                    icon: "warning",
                    showConfirmButton:true,
                    showCloseButton:true,
                    confirmButtonText:'Confirm',
                    confirmButtonColor: '#397373',
                    showCancelButton:true,
                    reverseButtons:true,
                    cancelButtonColor : 'grey',

                })
                .then((result) => {
                    if (result.isConfirmed) {

                        this.$store.dispatch('receiveInbound',this.viewInboundId).then(response => {
                            let data = this.RECEIVE_INBOUND
                            if(data.error){
                                swal.fire("", "Inbound Receive Failed", "warning");
                                this.toggleLoadingButtons(false)
                                return false
                            }
                            this.resetFields()

                            this.inbound_dialog = false

                            swal.fire("", "Inbound Receive Successful", "success");
                            this.toggleLoadingButtons(false)
                        }).catch(error => {
                            swal.fire("An Error Occured", error, "error");
                            this.toggleLoadingButtons(false)
                        })
                    } else {
                        this.toggleLoadingButtons(false)
                    }
                });
            },
            clearInboundItems(){
                this.resetFields()
                this.inbound_dialog = false
            },
            async exportToExcel(){
                let that = this
                that.loaded = false

                const workbook = new this.$exceljs.Workbook();
                let headerStyle = {
                    style:{font:{bold: true, name: 'Comic Sans MS'}},
                }
                const payload = {
                    inbound_id : this.viewInboundId
                }
                await this.$store.dispatch('exportInbound',payload).then(response => {
                    const worksheet = workbook.addWorksheet('Raw Data')
                    worksheet.columns = [
                        {header:'INBOUND #',key:'inbound_num'},
                        {header:'DATE RECEIVED',key:'date_received'},
                        {header:'MODEL',key:'model'},
                        {header:'UOM',key:'uom'},
                        {header:'TYPE',key:'type'},
                        {header:'CATEGORY',key:'category'},
                        {header:'SRP',key:'srp'},
                    ]

                    this.EXPORT_INBOUND.forEach(e => {
                        worksheet.addRow(e);
                    });
                    worksheet.getRow(`0${1}`).font = { bold: true }
                    worksheet.getColumn('G').numFmt = '#,##0'
                    worksheet.getColumn('G').alignment = {horizontal: 'right' };
                    }).catch(e => {
                        swal.fire('Error !', '', 'error')
                        console.log(e)
                        that.loaded = true
                    })
                    that.loaded = true

                    workbook.xlsx.writeBuffer().then((data) => {
                        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                        this.$filesaver.saveAs(blob, `${this.inbound.inbound_num}.xlsx`);
                    });
    },
    renderPDF (pdfAction) {
      var doc = new this.$jspdf('p', 'mm', 'letter')

                doc.setFontSize(14)
                doc.text(6, 15, 'Inbound Details')

                doc.autoTable({
                    html: '#inbound_details',
                    theme: 'plain',
                    margin: {top: 20, left: 6, right: 6},
                    columnStyles: {
                        0: {cellWidth: 25},
                        1: {cellWidth: 120},
                        2: {cellWidth: 26},
                        3: {cellWidth: 30},
                    }
                });

                doc.autoTable({
                    html: '#inbound_item_detials',
                    startY: doc.autoTable.previous.finalY + 3,
                    margin: {left: 6, right: 6},
                    theme: 'grid',
                    headStyles: {
                        halign:'center',
                        fillColor: '#ffffff',
                        textColor: '#000000',
                        lineWidth: .2,
                        lineColor: '#000000',
                    },
                    styles: {
                        fontSize: 8
                    },
                    columnStyles: {
                        0: {cellWidth: 11, halign:'center', fontSize: 8, textColor: '#000000'},
                        1: {cellWidth: 27, halign:'center', fontSize: 7, textColor: '#000000'},
                        2: {halign:'center', fontSize: 7, textColor: '#000000'},
                        3: {cellWidth: 17, halign:'center', fontSize: 8, textColor: '#000000'},
                        4: {cellWidth: 11, halign:'center', fontSize: 8, textColor: '#000000'},
                        5: {cellWidth: 27, halign:'center', fontSize: 8, textColor: '#000000'}
                    },
                    bodyStyles: {
                        lineColor: '#000000',
                    }
                });

                if(pdfAction == 'download'){
                    doc.save('Inbound ' + this.inbound.inbound_num + '.pdf');
                }else if(pdfAction == 'print'){
                    doc.autoPrint();
                    window.open(doc.output('bloburl'), '_blank');
                }

            },
            resetFields(){
                this.inboundItems = []
                this.inbound    = {
                    inbound_id: '',
                    inbound_num: '',
                    warehouse_id: '',
                    warehouse_name: '',
                    remarks: '',
                    user_name: '',
                    created_at: '',
                    status: '',
                    status_text: '',
                    broker: '',
                    estimated_warehouse_delivery:''
                }
            },
            // showImageUpload() {
            //     this.imageUpload = true;
            // },
            // closeImageUpload() {
            //     this.imageUpload = false;
            //     this.clearImageUpload();
            //     this.clearCameraCapture();
            // },
            // submitImageUpload() {
            //     const data = new FormData();

            //     const config = {
            //         headers: {
            //             'content-type': 'multipart/form-data'
            //         }
            //     }
            //     if(!!this.uploadedImageFile && this.uploadedImageFile.attachments.length > 0) {
            //         let files = this.uploadedImageFile.attachments;

            //         for(let i=0;i < _.compact(files).length;i++) {
            //             data.append('files[]', files[i]);
            //         }
            //     }

            //     if(!!this.images && this.images.length > 0) {
            //         for(let i=0;i < _.compact(this.images).length;i++) {
            //             data.append('images[]', _.compact(this.images)[i]);
            //         }
            //     }
            //     data.append('inboundId', this.inbound.inbound_id);
            //     swal.fire({
            //             title: 'Are you sure you want to Capture or Upload Image?',
            //             icon: 'warning',
            //             showConfirmButton:true,
            //             showCancelButton  :  true,
            //             reverseButtons:true,
            //             allowOutsideClick :  false,
            //             confirmButtonColor: '#397373',
            //             cancelButtonColor : 'grey',
            //             confirmButtonText:'Confirm',
            //             showCloseButton:true,
            //         }).then(confirm=>{
            //             if(confirm.isConfirmed){
            //                 let payload = {
            //                     params: data,
            //                     config: config,
            //                 }
            //                 this.$store.dispatch('uploadInboundFile', payload).then(response=>{
            //                     const res = response.data.message;
            //                     swal.fire(res, '', res.toLowerCase());
            //                     this.$emit('successConfirm', true);
            //                     this.closeImageUpload();

            //                 }).catch(err=>{
            //                     console.log(err)
            //                     swal.fire('Failed!','','error')
            //                     })
            //                 }
            //         });
            // }
            // ,
            // clearImageUpload() {
            //     this.uploadedImageFile = null;
            //     this.$refs.fileUpload.reset();
            // },
            // uploadedData(data) {
            //     if(!data) {
            //         this.uploadedImageFile = null;
            //     }

            //     this.uploadedImageFile = data;
            // },
            // getImages(images) {
            //     this.images = images;
            // },
            // clearCameraCapture() {
            //     this.$refs.cameraCapture.reset();
            //     this.images = null;
            // }


        },
        watch: {
            viewInboundId: function(val){
                this.errors = []

                if(val != -1){
                    if (this.viewInboundId != null && this.viewInboundId > 0) {

                        this.$store.dispatch('viewInbound',this.viewInboundId).then(response => {
                            let data = response.data
                            this.inbound.inbound_id                     = data.id
                            this.inbound.inbound_num                  = data.inbound_num
                            this.inbound.warehouse_id                   = data.warehouse_id
                            this.inbound.warehouse_name                 = data.warehouse_name
                            this.inbound.remarks                        = data.remarks
                            this.inbound.user_name                      = data.user_name
                            this.inbound.created_at                     = data.created_at
                            this.inbound.status                         = data.status
                            this.inbound.status_text                    = data.status_text
                            this.inbound.received_by                    = data.received_by
                            this.inbound.received_date                  = data.received_date
                            this.inbound.broker                         = data.broker
                            this.inboundItems                           = data.inbound_items
                            this.inbound.po_number                      = data.po_number,
                            this.inbound.estimated_warehouse_delivery   = this.dateWithFormat(data.estimated_warehouse_delivery,'YYYY-MM-DD')
                            this.receive_type                             = data.receive_type
                            this.inbound_dialog                         = true

                        }).catch(error => {
                            if(error.response.status == 422){
                                this.errors = error.response.data.errors
                            }
                        });
                    }
                }

            },
            // USER_ACCESS:{
            //     handler(val){
            //         val.forEach((e) => {
            //             if (e.actions_code == "approve"){
            //                 this.userAccess.approve = true;
            //             }
            //             if (e.actions_code == "reject"){
            //                 this.userAccess.reject = true;
            //             }
            //             if (e.actions_code == "receive"){
            //                 this.userAccess.receive = true;
            //             }
            //         });
            //     }
            // }
        }
      }
    </script>
<style>
.v-avatar {
  margin: 15px 50px !important;
}
#client_modal_card {
  min-height: 160px !important;
}
</style>
