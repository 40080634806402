<template>
    <div class="page-wrapper">
        <!-- <div class="row p-2">
            <div class="col-12 d-flex no-block align-items-center">
                <h4 class="page-title">SOA Payment Status Report</h4>
            </div>
        </div> -->
        <ComponentHeader
            class="ml-2 mr-2 mb-n5"
            :button="true"
            title='SOA Payment Status Report'
            type='Accounting'
        ></ComponentHeader>
        <div class="container">
            <v-tabs v-model="tab" id="group-tab" color="cyan" dark slider-color="cyan" class="col-12 pb-0 px-0" show-arrows>
                <v-tab ripple @click="getStatus(10)">ALL</v-tab>
                <v-tab ripple @click="getStatus(7)">PAID</v-tab>
                <v-tab ripple @click="getStatus(9)">UNPAID</v-tab>
                <v-tab ripple @click="getStatus(6)">PARTIAL</v-tab>
                <v-tab ripple @click="getStatus(8)">OVERDUE</v-tab>
            </v-tabs>

            <AccountReceivablesTableComponentVue class="mt-4" :menuStatus="status" />
        </div>
    </div>
</template>

<script>
import AccountReceivablesTableComponentVue from './AccountReceivablesTableComponent.vue';
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
export default {
    data() {
        return {
            tab: null,
            status: 10
        }
    },
    components: {
        AccountReceivablesTableComponentVue,
        ComponentHeader
    },
    methods: {
        getStatus(status) {
            if(status) {
                this.status = status
            }
        }
    }
}
</script>

<style>

</style>
