    <template>
    <div class="container">

        <ComponentHeader
            :button="true"
            :title="'Pending Orders By Item'"
            :type="'Sales'"
        ></ComponentHeader>

        <div class="row">
            <div class="container bg-bluish-gray default-container m-3">
                <v-dialog v-model="loading.dialog" width="500">
                    <v-card>
                        <v-card-title >
                            <span style="font-size:12px">
                                Processing: {{ loading.text }}
                            </span>
                        </v-card-title>
                        <v-card-text>
                            <v-progress-linear
                                v-model="loading.value"
                                :buffer-value="loading.bufferValue"
                                color="green"
                            >
                            </v-progress-linear>
                            {{Math.ceil(loading.value)}}%
                        </v-card-text>
                    </v-card>
                </v-dialog>
            <v-dialog v-model="loading.dialog" width="500">
                <v-card>
                    <v-card-title >
                        <span style="font-size:12px">
                            Processing: {{ loading.text }}
                        </span>
                    </v-card-title>
                    <v-card-text>
                        <v-progress-linear
                            v-model="loading.value"
                            :buffer-value="loading.bufferValue"
                            color="green"
                        >
                        </v-progress-linear>
                        {{Math.ceil(loading.value)}}%
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-app id="my_vue_app" class="bg-bluish-gray">
                <div class="row">
                        <div class="col-sm-12 col-md-3">
                        <v-autocomplete
                            :items="GET_CLASSIFICATIONS_SELECTION"
                            :loading="loading.class_selection"
                            :disabled="loading.class_selection"
                            v-model="classification.selected"
                            item-value="value"
                            item-text="text"
                            background-color="white"
                            label="Select Classification"
                            class="w-100"
                            dense
                            outlined
                            return-object
                            multiple
                            chips
                            deletable-chips
                        >
                        </v-autocomplete>
                    </div>
                    <div class="col-sm-12 col-md-5">
                        <v-autocomplete
                            dense
                            outlined
                            :items="GET_CUSTOMERS_SELECTION"
                            :loading="loading.customer_selection"
                            :disabled="loading.customer_selection"
                            v-model="customer.selected"
                            item-value="value"
                            item-text="text"
                            background-color="white"
                            label="Select Customer"
                            class="small w-100"
                        >
                        </v-autocomplete>
                    </div>
                    <div class="col-sm-12 col-md-3 text-right">
                        <v-btn  v-on:click="resetFields()" :loading="!loaded">Clear</v-btn>
                        <v-btn  v-on:click="exportToExcel()" :loading="!loaded">Excel</v-btn>
                        <v-btn  v-on:click="getAllItems()" :loading="!loaded">Filter</v-btn>
                    </div>
                </div>
                <div class="row">
                        <div class="col-sm-12 col-md-2">
                        <v-autocomplete
                            :items="GET_ITEM_TYPES"
                            :loading="loading.item_type_selection"
                            :disabled="loading.item_type_selection"
                            v-model="type_id"
                            item-value="value"
                            item-text="text"
                            background-color="white"
                            label="Select Item Type"
                            class="d-inline-block small w-100"
                            dense
                            outlined
                            return-object
                            multiple
                            chips
                            deletable-chips
                        >
                        </v-autocomplete>
                    </div>
                    <div class="col-sm-12 col-md-2">
                        <v-autocomplete
                            :items="GET_ITEM_CATEGORIES"
                            :loading="loading.category_selection"
                            :disabled="loading.category_selection"
                            v-model="category_id"
                            item-value="value"
                            item-text="text"
                            background-color="white"
                            label="Select Category"
                            class="d-inline-block small w-100"
                            dense
                            outlined
                            return-object
                            multiple
                            chips
                            deletable-chips
                        >
                        </v-autocomplete>
                    </div>
                    <div class="col-sm-12 col-md-2">
                        <v-autocomplete
                            :items="GET_ITEM_SELECTION_DEFAULT"
                            :loading="loading.item_selection"
                            :disabled="loading.item_selection"
                            v-model="item_id"
                            item-value="value"
                            item-text="text"
                            background-color="white"
                            label="Select Model"
                            class="d-inline-block small w-100"
                            dense
                            outlined
                            return-object
                            multiple
                            chips
                            deletable-chips
                        >
                        </v-autocomplete>
                    </div>
                    <div class="col-sm-12 col-md-1 d-flex justify-content-center align-items-center ">
                        <v-checkbox
                            v-model="active"
                            label="Active"
                            color="info"
                            value="1"
                            hide-details
                        ></v-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 col-md-2" id="date_from">
                        <label class="float-left control-label col-form-label">Date From</label>
                        <v-menu
                            v-model="menu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="date_from"
                                    readonly
                                    append-icon="mdi-calendar-clock"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="date_from" @input="menu2 = false"></v-date-picker>
                        </v-menu>
                        <span v-for="errors in errors.date_from" class="text-warning" v-bind:key="errors">{{errors}}</span>
                    </div>
                    <div class="col-sm-6 col-md-2" id="date_to">
                        <label class="float-left control-label col-form-label">Date To</label>
                        <v-menu
                            v-model="menu3"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="date_to"
                                    readonly
                                    append-icon="mdi-calendar-clock"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="date_to" @input="menu3 = false"></v-date-picker>
                        </v-menu>
                        <span v-for="errors in errors.date_to" class="text-warning" v-bind:key="errors">{{errors}}</span>
                    </div>
                </div>
                <v-app id="load_bar" v-if="!loaded">
                    <v-text-field color="info" loading disabled></v-text-field>
                </v-app>
                <div class="w-100" v-if="items.length > 0">
                    <v-card v-show="loaded">
                        <v-container fluid>
                            <v-data-table
                                :headers="headers"
                                :items="items"
                                :search="search"
                                :options.sync="options"
                                :server-items-length="totalItems"
                                :items-per-page="10"
                                :expanded.sync="expanded"
                                :footer-props="{
                                    itemsPerPageOptions: itemsPerPageOptions
                                }"
                                show-expand
                                class="w-100"
                                @item-expanded="loadDetails"
                                dense
                            >

                            <template v-slot:[`item.selling_price`]="{ item }">
                                <span class="text-nowrap">
                                    &#x20b1;{{ item.selling_price | currency }}
                                </span>
                            </template>

                            <template v-slot:[`item.sp_price`]="{ item }">
                                <span class="text-nowrap">
                                    &#x20b1;{{ item.sp_price | currency }}
                                </span>
                            </template>

                            <template v-slot:expanded-item="{ headers, item }">
                                <td :colspan="headers.length" class="p-0">
                                    <v-simple-table dense dark >
                                        <template v-slot:default>
                                            <thead>
                                                <th style="font-size:10px;width:4%;padding:0 8px">Customer</th>
                                                <th style="font-size:10px;width:4%;padding:0 8px">Oldest</th>
                                                <th style="font-size:10px;width:4%;padding:0 8px">Latest</th>
                                                <!--<th>SO#</th>-->
                                                <th style="font-size:10px;width:4%;padding:0 8px">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span
                                                            dark
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            >
                                                            TSO
                                                            </span>
                                                        </template>
                                                        <span>TOTAL SO</span>
                                                    </v-tooltip>
                                                </th>
                                                <th style="font-size:10px;width:4%;padding:0 8px">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span
                                                            dark
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            >
                                                            SSO
                                                            </span>
                                                        </template>
                                                        <span>SERVED SO</span>
                                                    </v-tooltip>
                                                </th>
                                                    <th style="font-size:10px;width:4%;padding:0 8px">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span
                                                            dark
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            >
                                                            PSO
                                                            </span>
                                                        </template>
                                                        <span>PENDING SO</span>
                                                    </v-tooltip>
                                                </th>
                                                <td style="font-size:10px;width:4%;padding:0 8px" v-for="(wh, w) in item.warehouseItemStocks" :key="w"><center><strong>{{ wh.warehouse_code }}</strong></center></td> <!-- add foreach-->
                                            </thead>
                                            <tbody>
                                                <tr v-for="(salesOrder, i) in item.salesOrders" :key="i" style="cursor:pointer">
                                                    <td style="font-size:10px;width:4%;padding:0 8px">{{ salesOrder.company_name }}</td>
                                                    <td style="font-size:10px;width:4%;padding:0 8px">{{ salesOrder.oldest_date | formatDate }}</td>
                                                    <td style="font-size:10px;width:4%;padding:0 8px">{{ salesOrder.latest_date | formatDate }}</td>
                                                    <!--<td>{{ salesOrder.order_num }}</td>-->
                                                    <td>{{ salesOrder.item_qty }}</td>
                                                    <td>{{ salesOrder.quantity_served }}</td>
                                                    <td>{{ parseInt(salesOrder.item_qty) - (parseInt(salesOrder.quantity_served) + parseInt(salesOrder.quantity_canceled)) }}</td>
                                                    <!-- <td>{{ salesOrder.quantity_canceled }}jj</td> -->
                                                    <td style="font-size:10px;width:4%;padding:0 8px" v-for="(wh, w) in item.warehouseItemStocks" :key="w"><center>{{ wh.quantity | numberNoDecimal}}</center></td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </td>
                            </template>

                            </v-data-table>
                        </v-container>
                    </v-card>
                </div>
            </v-app>
            </div>
        </div>
    </div>


    </template>

    <script>
    import { mapGetters } from 'vuex';
    import swal from 'sweetalert2'
    import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
    export default {
        data(){
            return {
                expanded: [],
                errors: [],
                loaded: true,

                date_from: this.$dayjs().startOf('month').format('YYYY-MM-DD'),
                date_to: this.$dayjs().endOf('month').format('YYYY-MM-DD'),

                items_list: [],
                item_names: [],
                item_id: [],
                brand_id: '',
                brand_list: [],
                type_list: [],
                type_id: [],
                category_list: [],
                category_id: [],

                row: null,
                menu2: false,
                menu3: false,

                totalItems: 0,
                options: {},
                items: [],
                itemsPerPageOptions: [5, 10 ,15, 50, 100, 250],
                itemsPerPage: 10,
                search: '',
                awaitingSearch: false,
                headers: [
                    { text: 'Model Name', align: 'left', value: 'model', },
                    { text: 'Name', align: 'left', value: 'name', },
                    { text: 'Category', align: 'left', value: 'category_name', },
                    { text: 'UOM', align: 'left', value: 'uom_name', },
                    { text: 'Type', align: 'left', value: 'type_name', },
                    { text: 'Selling Price', align: 'left', value: 'selling_price', },
                ],
                json_data: [],
                json_meta: [
                    [
                        {
                            'key': 'charset',
                            'value': 'utf-8'
                        }
                    ]
                ],
                customer:{
                    selection:[],
                    selected:''
                },
                classification:{
                    selection:[],
                    selected:[]
                },
                loading: {
                    text: '',
                    bufferValue: 100,
                    value: 0,
                    dialog:false,

                    class_selection             : true,
                    customer_selection          : true,
                    item_selection              : true,
                    category_selection          : true,
                    item_type_selection         : true,
                },
                active:'1',
            }
        },
        components: {
            ComponentHeader
        },
        mounted: function (){
            this.$store.dispatch('getAllcustomersSelection')
            this.$store.dispatch('getAllcustomerClassificationSelection')
            this.$store.dispatch('getItemTypes')
            this.$store.dispatch('getItemCategories')
            this.$store.dispatch('getAllitemSelectionDefault')
        },
        computed:{
            ...mapGetters([
            'GET_CLASSIFICATIONS_SELECTION',
            'GET_CUSTOMERS_SELECTION',
            'GET_ITEM_SELECTION_DEFAULT',
            'GET_ITEM_CATEGORIES',
            'GET_ITEM_TYPES'
            ])
        },
        methods: {
            selecteditem(val){
                this.item_id = val
            },

            selectedCategory(val){
                this.category_id = val
            },

            selectedType(val){
                this.type_id = val
            },

            selectedClassification(val){
                this.classification.selected = val
            },

            loadDetails(item){
                if(item.value){
                    let payload = {
                        url:'getItemSalesOrdersByCustomer/'+item.item.id,
                        date_from:      this.date_from,
                        date_to:        this.date_to,
                        brand_id:       this.brand_id,
                        type_id:        this.type_id.map(e=>{return e.value}),
                        category_id:    this.category_id.map(e=>{return e.value}),
                        customer_id:    this.customer.selected,
                        calssification_id: this.classification.selected.map(e=>{return e.value})
                    }
                    this.$store.dispatch('reports',payload).then(response => {
                        var data = response.data
                        item.item.salesOrders = data.salesOrders
                        item.item.warehouseItemStocks = data.warehouseItemStocks
                        this.$forceUpdate()
                    })
                }
            },

            getAllItems(){
                this.loaded = false
                this.expanded = []

                this.$store.dispatch('reports',{

                        page:               this.options.page,
                        itemsPerPage:       this.options.itemsPerPage,
                        sortBy:             this.options.sortBy,
                        sortDesc:           this.options.sortDesc,
                        search:             this.search,
                        item_id:            this.item_id.map(e=>{return e.value}),
                        date_from:          this.date_from,
                        date_to:            this.date_to,
                        brand_id:           this.brand_id,
                        type_id:            this.type_id.map(e=>{return e.value}),
                        category_id:        this.category_id.map(e=>{return e.value}),
                        customer_id:        this.customer.selected,
                        calssification_id:  this.classification.selected.map(e=>{return e.value}),
                        active:             this.active,
                        url:                'getAllWithSO'

                }).then(response => {
                    this.items        = response.data.data
                    this.loaded       = true
                    this.totalItems   = response.data.total
                })
            },
            thousandSeprator(amount) {
                if (amount !== '' || amount !== undefined || amount !== 0 || amount !== '0' || amount !== null) {
                    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                    return amount;
                }
            },
            resetFields(){
                this.errors                 = []
                this.date_from              = ''
                this.date_to                = ''
                this.item_id                = ''
                this.expanded               = []
                this.item_id                = []
                this.category_id            = []
                this.customer.selected      = ''
                this.classification.selected = []
                this.type_id = this.type_id = this.type_list.filter(e=>{
                        return e.value != 3
                    })
                this.active = '1'
            },

            async exportToExcel() {
                let that = this
                let workbook = new this.$exceljs.Workbook();
                let style = { alignment: { vertical: 'middle', horizontal: 'center' }, width: 200 };
                let loopThruMonth = true;
                let beginningDate = this.$dayjs(that.date_from)
                let endDate = this.$dayjs(that.date_to)
                let counter = 0;
                let worksheet = []
                let activeItems = [];
                let inactiveItems = [];

                that.loaded = false
                that.loading.dialog = true

                that.loading.bufferValue = endDate.diff(beginningDate,'month')

                worksheet['rawData'] = workbook.addWorksheet('Pending Orders')
                worksheet['rawData'].columns = [
                    { header: 'Customer', key:'company_name' },
                    { header: 'SO', key:'order_num' },
                    { header: 'Created', key:'created_at', style: { numFmt: 'YYYY-MM-DD' } },
                    { header: 'Model', key:'model' },
                    { header: 'Name', key:'name' },
                    { header: 'Type', key:'type_name' },
                    { header: 'Category', key:'category_name' },
                    { header: 'UOM', key:'uom_name' },
                    { header: 'Qty', key:'item_qty' },
                    { header: 'Served', key:'quantity_served' },
                    { header: 'Pending', key:'pending_so_qty' },
                ]

                worksheet['active'] = workbook.addWorksheet('Active Item Total Stocks')
                worksheet['inactive'] = workbook.addWorksheet('Inactive Item Total Stocks')

                while (loopThruMonth) {
                    let date_from = ''
                    let date_to = ''

                    if (beginningDate.format('YYYY-MM-DD') == that.date_from) {
                        date_from = beginningDate.format('YYYY-MM-DD')
                        date_to = beginningDate.endOf('month').format('YYYY-MM-DD')

                    } else if (beginningDate.format('YYYY-MM') == endDate.format('YYYY-MM')) {
                        date_from = beginningDate.startOf('month').format('YYYY-MM-DD')
                        date_to = endDate.format('YYYY-MM-DD')

                    } else {
                        date_from = beginningDate.startOf('month').format('YYYY-MM-DD')
                        date_to = beginningDate.endOf('month').format('YYYY-MM-DD')
                    }

                    that.loading.text = beginningDate.format('MMM')
                    that.loading.value = (counter / that.loading.bufferValue) * 100

                    await this.$store.dispatch('reports',{
                            item_id:            this.item_id.map(e=>{return e.value}),
                            date_from:          date_from,
                            date_to:            date_to,
                            brand_id:           this.brand_id,
                            type_id:            this.type_id.map(e=>{return e.value}),
                            category_id:        this.category_id.map(e=>{return e.value}),
                            url:'exportPendingOrderByItemStocks'
                    }).then(response => {
                        activeItems.push(Object.values(response.data.active_items))
                        inactiveItems.push(Object.values(response.data.inactive_items))

                        response.data.data.forEach((e, i) => {
                            e.item_qty = parseInt(e.item_qty)
                            e.quantity_served   = parseInt(e.quantity_served)
                            e.pending_so_qty    = parseInt(e.pending_so_qty)
                            worksheet['rawData'].addRow(e)
                            // worksheet['rawData'].getCell(`B${i + 2}`).value = parseInt(e.order_num)
                            // worksheet['rawData'].getCell(`B${i + 2}`).numFmt = '0'
                        })
                    }).catch(e => {
                        swal.fire('Error !', '', 'error')
                        console.log(e)
                    })

                    if (beginningDate.format('YYYY-MM') == endDate.format('YYYY-MM')) {
                        loopThruMonth = false
                    }

                    counter++;
                    beginningDate = beginningDate.add(1,'month')
                }

                activeItems = activeItems.flat()
                inactiveItems = inactiveItems.flat()

                await this.$store.dispatch('reportsPost', {
                    items: _.uniq(activeItems),
                    inactive: _.uniq(inactiveItems),
                    url:'pending-so/list/export'
                }).then(response => {

                    let activeHeaders = [];
                    let inactiveHeaders = [];

                    if (!!response.data.active[0]){
                        activeHeaders = Object.keys(response.data.active[0]).map(e=> {
                            return {header:e,key:e}
                        })

                        worksheet['active'].columns = activeHeaders
                        response.data.active.forEach((e,i) => {

                            worksheet['active'].addRow(e)

                            // Total Stocks
                            worksheet['active'].getCell(`F${i + 2}`).value = parseInt(e.Total_stocks)
                            worksheet['active'].getCell(`F${i + 2}`).numFmt = '#,##0'
                            // C8
                            worksheet['active'].getCell(`G${i + 2}`).value = parseInt(e.C8)
                            worksheet['active'].getCell(`G${i + 2}`).numFmt = '#,##0'
                            // C7A
                            worksheet['active'].getCell(`H${i + 2}`).value = parseInt(e.C7A)
                            worksheet['active'].getCell(`H${i + 2}`).numFmt = '#,##0'
                            // C7B
                            worksheet['active'].getCell(`I${i + 2}`).value = parseInt(e.C7B)
                            worksheet['active'].getCell(`I${i + 2}`).numFmt = '#,##0'
                            // CA
                            worksheet['active'].getCell(`J${i + 2}`).value = parseInt(e.CA)
                            worksheet['active'].getCell(`J${i + 2}`).numFmt = '#,##0'
                            // CB
                            worksheet['active'].getCell(`K${i + 2}`).value = parseInt(e.CB)
                            worksheet['active'].getCell(`K${i + 2}`).numFmt = '#,##0'
                            // CM
                            worksheet['active'].getCell(`L${i + 2}`).value = parseInt(e.CM)
                            worksheet['active'].getCell(`L${i + 2}`).numFmt = '#,##0'
                            // DM
                            worksheet['active'].getCell(`M${i + 2}`).value = parseInt(e.DM)
                            worksheet['active'].getCell(`M${i + 2}`).numFmt = '#,##0'
                            // DMdashB
                            worksheet['active'].getCell(`N${i + 2}`).value = parseInt(e.DMdashB)
                            worksheet['active'].getCell(`N${i + 2}`).numFmt = '#,##0'
                            // DMdashD
                            worksheet['active'].getCell(`O${i + 2}`).value = parseInt(e.DMdashD)
                            worksheet['active'].getCell(`O${i + 2}`).numFmt = '#,##0'
                            // DMdashR
                            worksheet['active'].getCell(`P${i + 2}`).value = parseInt(e.DMdashR)
                            worksheet['active'].getCell(`P${i + 2}`).numFmt = '#,##0'
                            // MK
                            worksheet['active'].getCell(`Q${i + 2}`).value = parseInt(e.MK)
                            worksheet['active'].getCell(`Q${i + 2}`).numFmt = '#,##0'
                            // PC
                            worksheet['active'].getCell(`R${i + 2}`).value = parseInt(e.PC)
                            worksheet['active'].getCell(`R${i + 2}`).numFmt = '#,##0'
                            // QCM
                            worksheet['active'].getCell(`S${i + 2}`).value = parseInt(e.QCM)
                            worksheet['active'].getCell(`S${i + 2}`).numFmt = '#,##0'
                            // SCdashDMS
                            worksheet['active'].getCell(`T${i + 2}`).value = parseInt(e.SCdashDMS)
                            worksheet['active'].getCell(`T${i + 2}`).numFmt = '#,##0'
                            // SCdashDMSdashI
                            worksheet['active'].getCell(`U${i + 2}`).value = parseInt(e.SCdashDMSdashI)
                            worksheet['active'].getCell(`U${i + 2}`).numFmt = '#,##0'
                            // SR
                            worksheet['active'].getCell(`V${i + 2}`).value = parseInt(e.SR)
                            worksheet['active'].getCell(`V${i + 2}`).numFmt = '#,##0'

                        })
                    }

                    if (!!response.data.inactive[0]) {
                        inactiveHeaders = Object.keys(response.data.inactive[0]).map(e => {
                            return { header: e, key: e }
                        })

                        worksheet['inactive'].columns = inactiveHeaders
                        response.data.inactive.forEach((e,i) => {
                            worksheet['inactive'].addRow(e)

                            // Total Stocks
                            worksheet['inactive'].getCell(`F${i + 2}`).value = parseInt(e.Total_stocks)
                            worksheet['inactive'].getCell(`F${i + 2}`).numFmt = '#,##0'
                            // C8
                            worksheet['inactive'].getCell(`G${i + 2}`).value = parseInt(e.C8)
                            worksheet['inactive'].getCell(`G${i + 2}`).numFmt = '#,##0'
                            // C7A
                            worksheet['inactive'].getCell(`H${i + 2}`).value = parseInt(e.C7A)
                            worksheet['inactive'].getCell(`H${i + 2}`).numFmt = '#,##0'
                            // C7B
                            worksheet['inactive'].getCell(`I${i + 2}`).value = parseInt(e.C7B)
                            worksheet['inactive'].getCell(`I${i + 2}`).numFmt = '#,##0'
                            // CA
                            worksheet['inactive'].getCell(`J${i + 2}`).value = parseInt(e.CA)
                            worksheet['inactive'].getCell(`J${i + 2}`).numFmt = '#,##0'
                            // CB
                            worksheet['inactive'].getCell(`K${i + 2}`).value = parseInt(e.CB)
                            worksheet['inactive'].getCell(`K${i + 2}`).numFmt = '#,##0'
                            // CM
                            worksheet['inactive'].getCell(`L${i + 2}`).value = parseInt(e.CM)
                            worksheet['inactive'].getCell(`L${i + 2}`).numFmt = '#,##0'
                            // DM
                            worksheet['inactive'].getCell(`M${i + 2}`).value = parseInt(e.DM)
                            worksheet['inactive'].getCell(`M${i + 2}`).numFmt = '#,##0'
                            // DMdashB
                            worksheet['inactive'].getCell(`N${i + 2}`).value = parseInt(e.DMdashB)
                            worksheet['inactive'].getCell(`N${i + 2}`).numFmt = '#,##0'
                            // DMdashD
                            worksheet['inactive'].getCell(`O${i + 2}`).value = parseInt(e.DMdashD)
                            worksheet['inactive'].getCell(`O${i + 2}`).numFmt = '#,##0'
                            // DMdashR
                            worksheet['inactive'].getCell(`P${i + 2}`).value = parseInt(e.DMdashR)
                            worksheet['inactive'].getCell(`P${i + 2}`).numFmt = '#,##0'
                            // MK
                            worksheet['inactive'].getCell(`Q${i + 2}`).value = parseInt(e.MK)
                            worksheet['inactive'].getCell(`Q${i + 2}`).numFmt = '#,##0'
                            // PC
                            worksheet['inactive'].getCell(`R${i + 2}`).value = parseInt(e.PC)
                            worksheet['inactive'].getCell(`R${i + 2}`).numFmt = '#,##0'
                            // QCM
                            worksheet['inactive'].getCell(`S${i + 2}`).value = parseInt(e.QCM)
                            worksheet['inactive'].getCell(`S${i + 2}`).numFmt = '#,##0'
                            // SCdashDMS
                            worksheet['inactive'].getCell(`T${i + 2}`).value = parseInt(e.SCdashDMS)
                            worksheet['inactive'].getCell(`T${i + 2}`).numFmt = '#,##0'
                            // SCdashDMSdashI
                            worksheet['inactive'].getCell(`U${i + 2}`).value = parseInt(e.SCdashDMSdashI)
                            worksheet['inactive'].getCell(`U${i + 2}`).numFmt = '#,##0'
                            // SR
                            worksheet['inactive'].getCell(`V${i + 2}`).value = parseInt(e.SR)
                            worksheet['inactive'].getCell(`V${i + 2}`).numFmt = '#,##0'
                        });
                    }
                })

                workbook.xlsx.writeBuffer().then((data) => {
                    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                    this.$filesaver.saveAs(blob, `Pending Sales Orders By Items ${that.date_from}-${that.date_to}.xlsx`);
                });

                that.loaded = true
                that.loading.dialog = false
                that.loading.value = 0
                swal.fire('Success!','','success')
            }
        },

        watch:{
            // type_id(val){
            //     this.getItems()
            // },
            'GET_CLASSIFICATIONS_SELECTION':{
                handler(val){
                    this.classification.selection = val
                    this.loading.class_selection    = false
                }
                },
                'GET_CUSTOMERS_SELECTION':{
                handler(val){
                    this.customer.selection = val
                    this.loading.customer_selection    = false

                }
                },
                'GET_ITEM_SELECTION_DEFAULT':{
                    handler(val){
                        this.items_list = val
                    this.loading.item_selection    = false

                    }
                },
                'GET_ITEM_CATEGORIES':{
                    handler(val){
                        this.category_list = val.data
                    this.loading.category_selection    = false

                    }
                },
                'GET_ITEM_TYPES':{
                handler(val){
                    this.type_list = val.data
                    this.type_id = val.data.filter(e=>{
                            if(e.value != 3){
                                return e.value
                            }
                        })
                    this.loading.item_type_selection    = false
                }
                }
        }
    };
    </script>
    <style>
        .col{
            padding: 2px !important;
        }

        .card-body{
            padding: 3px !important;
        }
    </style>
