import TargetSales from "@/views/main/layouts/Target"
import repository from "@/api/credentials";

const routes = [{
    path: "/targetSales",
    name: "targetSales",
    component: TargetSales,
    // beforeEnter: (to, from, next) => {
    //     repository.checkAccessUser(to.params.id).then((response) => {
    //         if (response.data == 'fail') {
    //             next('/no-access')
    //         } else {
    //             next()
    //         }
    //     }).catch(() => {

    //     })
    // },
    meta: {
        requiresAuth: true,
        title: 'TargetSales',
    }
}]

export default routes;
