<template>
    <v-card elevation="0">
        <v-card-title>
        {{ tab }}
        </v-card-title>
        <v-card-text>
            <v-data-table
                :items="dataTable.items"
                :headers="dataTable.headers"
                :options.sync="dataTable.options"
                :server-items-length="dataTable.totalItems"
                :loading="!dataTable.loaded"
                dense
                item-key="id"

            >
            <template v-slot:item.actions="{ item }">
                <v-btn icon text @click="view(item,'view', tab == 1)" color="orange" small>
                    <v-icon small >mdi-eye</v-icon>
                </v-btn>
                <v-btn icon text @click="view(item,'edit', tab)" color="blue" small>
                    <v-icon small>mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon text @click="deleteItem(item)" color="red" small>
                    <v-icon small>mdi-delete</v-icon>
                </v-btn>
            </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
export default {

    props:{
        tab:
        {
            default:()=>1,
            type:Number
        }
    },
    
    data:()=>({
        dataTable:{
            items:[],
            loaded:true,
            totalItems:0,
            options:{},
            headers: [
                { text:'Model',value:'model' },
                { text:'Name',value:'item_name' },
                { text:'Category',value:'category' },
                { text:'Template',value:'name' },
                { text:'Actions',value:'actions' },
            ],
            templateHeaders:[
                { text:'Code',value:'code' },
                { text:'Description',value:'description' },
                { text:'Name',value:'name' },
                { text:'Actions',value:'actions' },
            ],
        },
        route:{
            list:{
                dispatch_name:'itemsGet',
                url:'item-with-check-list'
            },
            view:{
                dispatch_name:'itemsGet',
                url:'item-with-check-list'
            },
            delete:{
                dispatch_name:'itemsPost',
                url:'delete-item-check-list'
            }
        }
    }),

    computed:{
        ...mapGetters([
            'TESTER_CHECK_LISTS',
            'GET_REFRESH',
            'GET_CHECK_LIST_TABLE',
            'GET_CHECK_LIST',
            'GET_TABS',
        ]),

        routeList(){
            switch(this.tab){
                    case 0:
                        this.dataTable.headers = [
                            { text:'Model',value:'model' },
                            { text:'Name',value:'item_name' },
                            { text:'Category',value:'category' },
                            { text:'Template',value:'name' },
                            { text:'Actions', align: 'center' , width: "10%" , value:'actions' },
                        ]

                        this.route =  {
                            list:{
                                dispatch_name:'itemsGet',
                                url:'item-with-check-list'
                            },
                            view:{
                                dispatch_name:'itemsGet',
                                url:'item-with-check-list'
                            },
                            delete:{
                                dispatch_name:'itemsPost',
                                url:'delete-item-check-list'
                            }
                        }
                    break;

                    case 1:
                    this.dataTable.headers = [
                        { text:'Code',value:'code' },
                        { text:'Description',value:'description' },
                        { text:'Name',value:'name' },
                        { text:'Actions', align: 'center', width: "10%" , value:'actions' },
                    ]

                    this.route = {
                        list:{
                            dispatch_name:'serviceGet',
                            url:'qa-check-list'
                        },
                        view:{
                            dispatch_name:'servicePost',
                            url:'qa-check-list-view'
                        },
                        delete:{
                            dispatch_name:'servicePost',
                            url:'qa-check-list-delete'
                        },
                        edit:{
                            dispatch_name: 'servicePost',
                            url:'update-tester-lists'
                        },
                    }
                    break;

                    case 2:
                    this.dataTable.headers = [
                        { text:'Code',value:'code' },
                        { text:'Description',value:'description' },
                        { text:'Name',value:'name' },
                        { text:'Actions', align: 'center', width: "10%" , value:'actions' },
                    ]

                    this.route = {
                        list:{
                            dispatch_name:'serviceGet',
                            url:'qa-check-list'
                        },
                        view:{
                            dispatch_name:'servicePost',
                            url:'qa-check-list-view'
                        },
                        delete:{
                            dispatch_name:'servicePost',
                            url:'qa-check-list-delete'
                        },
                        edit:{
                            dispatch_name: 'servicePost',
                            url:'update-tester-lists'
                        },
                    }
                    break;
            }
        },

    },
    methods:{
        async list(){
            this.routeList
            this.dataTable.loaded = false
            this.dataTable.items = []
            // debugger
            await this.$store.dispatch(this.route.list.dispatch_name,{
                url:this.route.list.url,
                page:this.dataTable.options.page,
                itemsPerPage:this.dataTable.options.itemsPerPage,
                ...this.filters
            }).then(response =>{
                this.dataTable.items = response.data.data.data
                // console.log(this.dataTable.items)
                this.dataTable.loaded = true
                this.dataTable.totalItems = response.data.data.total
                this.dataTable.templateHeaders = this.route.view.url
            }) .catch( err =>{
                console.log(err)
                Swal.fire('Error!','- an error occured','error')
            })
            // console.log(this.GET_CHECK_LIST_TABLE)

        },
        view(item,action = 'view',tab  = 0){
            this.$store.commit('GET_CHECK_LIST_ACTION',action)

            // if(tab == 1 && action == 'edit'){
            //     this.toggleDialog('CHECK_LIST_TEMPLATE')
            //     return false
            // }
            this.toggleDialog('VIEW')

            // console.log(item)
            this.$store.dispatch(this.route.view.dispatch_name,{
                url:this.route.view.url,
                ...item,
                view:1
            }).then(response =>{
                if(tab == 1){
                    this.$store.commit('GET_CHECK_LIST_SELECTED_ITEM',item)
                }else{
                    // console.log(response.data[0])
                    this.$store.commit('GET_CHECK_LIST_SELECTED_ITEM',response.data[0])
                }
                // debugger
            })
        },
        toggleDialog(dialog){
            if(!this.TESTER_CHECK_LISTS.DIALOGS[dialog]){
                return false
            }

            if(!this.TESTER_CHECK_LISTS.DIALOGS[dialog].VALUE){
                Object.assign(this.$data, this.$options.data.call(this));
            }

            this.$store.commit(this.TESTER_CHECK_LISTS.DIALOGS[dialog].mutations,!this.TESTER_CHECK_LISTS.DIALOGS[dialog].VALUE)
        },
        deleteItem(item){
            Swal.fire({
                title:'Warning',
                text:'- Are you sure you want to remove template?',
                icon: 'question',
                showCancelButton:true,
                reverseButtons:true
            }).then(action=>{
                if(action.isConfirmed){
                    this.$store.dispatch(this.route.delete.dispatch_name,{
                            url:this.route.delete.url,
                            item_id:item.item_id,
                            id: item.id,
                            template_id: 0
                        }).then(response=>{
                            Swal.fire('Success!','','success')
                            this.list()
                        }).catch(err=>{
                            Swal.fire('Error!','','error')
                            console.log(err)
                        })
                }
            })
        },
        // updateItem(item){
        //     Swal.fire({
        //         title:'Warning!',
        //         icon:'warning',
        //         text:'- Are you sure you want to update?',
        //         showCancelButton:true,
        //         reverseButtons:true
        //     }).then(action=>{
        //             if(action.isConfirmed){
        //                 this.$store.dispatch(this.route.edit.dispatch_name,{
        //                     url:this.route.edit.url,
        //                     ...item,
        //                 }).then(response=>{
        //                     Swal.fire('Success!','','success')
        //                     this.toggleDialog('VIEW')
        //                 }).catch(err=>{
        //                     Swal.fire('Error!','','error')
        //                     console.log(err)
        //                 })
        //             }
        //     })
        // }
    },
    watch:{
        'dataTable.options':{
            handler(val){
                this.list()
            }
        },
        'TESTER_CHECK_LISTS.DIALOGS.VIEW.VALUE':{
            handler(val){
                if(!val){
                    this.list()
                }
            }
        },
        // tab(val){
        //     // console.log(val)
        //     switch(val){
        //         case 0:
        //             this.dataTable.headers = [
        //                 { text:'Model',value:'model' },
        //                 { text:'Name',value:'item_name' },
        //                 { text:'Category',value:'category' },
        //                 { text:'Template',value:'name' },
        //                 { text:'Actions',value:'actions' },
        //             ]

        //             this.route =  {
        //                 list:{
        //                     dispatch_name:'itemsGet',
        //                     url:'item-with-check-list'
        //                 },
        //                 view:{
        //                     dispatch_name:'itemsGet',
        //                     url:'item-with-check-list'
        //                 },
        //                 delete:{
        //                     dispatch_name:'itemsPost',
        //                     url:'delete-item-check-list'
        //                 }
        //             }
        //         break;

        //         case 1:
        //             this.dataTable.templateHeaders = [
        //                 { text:'Code',value:'code' },
        //                 { text:'Description',value:'description' },
        //                 { text:'Name',value:'name' },
        //                 { text:'Action',value:'actions' },
        //             ]

        //             this.route = {
        //                 list:{
        //                     dispatch_name:'serviceGet',
        //                     url:'qa-check-list'
        //                 },
        //                 view:{
        //                     dispatch_name:'updateQaCheckList',
        //                     url:'qa-check-list-view'
        //                 },
        //                 delete:{
        //                     dispatch_name:'getQaCheckListDelete',
        //                     url:'qa-check-list-delete'
        //                 }
        //             }
        //         break;
        //         case 2:
        //             this.dataTable.templateHeaders = [
        //                 { text:'Code',value:'code' },
        //                 { text:'Description',value:'description' },
        //                 { text:'Name',value:'name' },
        //                 { text:'Action',value:'actions' },
        //             ]

        //             this.route =  {
        //                 list:{
        //                     dispatch_name:'serviceGet',
        //                     url:'qa-check-list'
        //                 },
        //                 view:{
        //                     dispatch_name:'itemsGet',
        //                     url:'item-with-check-list'
        //                 },
        //                 delete:{
        //                     dispatch_name:'itemsPost',
        //                     url:'update-item-check-list'
        //                 }
        //             }
        //     }
        // },
        GET_REFRESH:{
            handler(val){
                if(val){
                    this.$store.commit('REFRESH', false)
                }
            }
        },
        route:{
            handler(val){
                // console.log(val)
                if(val){
                    this.route()
                }
            }
        },
    }
}
</script>

<style>

</style>
