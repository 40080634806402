<template>

    <v-container fluid>
        <ComponentHeader
            :button="true"
            title='Inventory Count'
            type='Inventory'
    ></ComponentHeader>

    <v-container fluid class="bg-bluish-gray">


            <v-text-field v-if="!loaded" color="info" loading disabled></v-text-field>

        <v-row class="pa-2">
            <v-col lg="5">
                <v-autocomplete :items="GET_ITEMS"
                    :loading="loading.item_selection"
                    :disabled="loading.item_selection"
                    background-color="white"
                    multiple
                    deletable-chips
                    small-chips
                    chips
                    outlined
                    dense
                    v-model="item_ids"
                    item-value="value"
                    item-text="text"
                    label="Select Model"
                    class="small"
                    @select="onSelectItems"
                >
                </v-autocomplete>
            </v-col>
            <v-col lg="4">
                <v-autocomplete :items="GET_ITEMS"
                    :loading="loading.item_selection"
                    :disabled="loading.item_selection"
                    background-color="white"
                    multiple
                    deletable-chips
                    small-chips
                    chips
                    outlined
                    dense
                    v-model="item_ids"
                    item-value="value"
                    item-text="name"
                    label="Select Name"
                    class="small"
                    @select="onSelectItems"
                >
                </v-autocomplete>
            </v-col>
            <div class="col-sm-12 col-md-3 text-right">
                <v-btn small class="ml-1" @click="resetFields()">Clear</v-btn>
                <v-btn small class="ml-1" @click="exportInventoryCountToFastExcel()">Excel</v-btn>
                <v-btn small class="ml-1" @click="getAllItemsInWarehouse()">Filter</v-btn>
            </div>
            </v-row>
        <v-row class="pa-2">
            <v-col lg="3">
                <v-autocomplete :items="GET_ALL_WAREHOUSES"
                    :loading="loading.warehouse_selection"
                    :disabled="loading.warehouse_selection"
                    background-color="white"
                    multiple
                    deletable-chips
                    small-chips
                    chips
                    outlined
                    dense
                    v-model="warehouse_ids"
                    item-value="value"
                    item-text="text"
                    label="Select Warehouse"
                    class="small"
                    @select="onSelectWarehouses"
                >
                </v-autocomplete>
            </v-col>
            <v-col lg="3">
                <v-autocomplete :items="GET_ITEM_TYPES"
                    :loading="loading.item_type_selection"
                    :disabled="loading.item_type_selection"
                    background-color="white"
                    multiple
                    deletable-chips
                    small-chips
                    chips
                    outlined
                    dense
                    v-model="item_type_ids"
                    item-value="value"
                    item-text="text"
                    label="Select Disc Type"
                    class="small"
                    @select="onSelectTypes"
                >
                </v-autocomplete>
            </v-col>
            <v-col lg="3">
                <v-autocomplete :items="GET_ITEM_CATEGORIES"
                    :loading="loading.category_selection"
                    :disabled="loading.category_selection"
                    background-color="white"
                    multiple
                    deletable-chips
                    small-chips
                    chips
                    outlined
                    dense
                    v-model="item_category_ids"
                    item-value="value"
                    item-text="text"
                    label="Select Item Category"
                    class="small"
                    @select="onSelectCategories"
                >
                </v-autocomplete>
            </v-col>


        <div class="w-100">
            <v-card v-show="loaded">
                <v-container fluid>
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        :options.sync="options"
                        :server-items-length="totalItems"
                        :items-per-page="itemsPerPage"
                        :footer-props="{
                            itemsPerPageOptions: itemsPerPageOptions
                        }"
                        class="w-100"
                        dense
                    >
                        <template v-slot:[`item.quantity`]="{ item }">
                            {{ thousandSeprator(item.quantity ? item.quantity : 0) }}
                        </template>

                        <template v-slot:[`item.incoming_stocks`]="{ item }">
                            {{ thousandSeprator(item.incoming_stocks ? item.incoming_stocks : 0) }}
                        </template>
                    </v-data-table>
                </v-container>
            </v-card>
        </div>
    </v-row>


</v-container>
</v-container>

</template>

<script>
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
import { mapGetters } from 'vuex';
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
import ReportBodyComponent from '@/views/main/layouts/Reports/ReportsBodyComponent.vue'
import swal from 'sweetalert2';
import { find } from 'rxjs';
export default {
    mixins: [ SharedFunctionsComponentVue ],
    data(){
        return {
            errors: [],
            loaded: true,

            items_list: [],
            item_names: [],
            item_ids: [],

            warehouses_list: [],
            warehouse_ids: [],

            item_type_list: [],
            item_type_ids: [],

            item_category_list: [],
            item_category_ids: [],

            row: null,

            totalItems: 0,
            options: {},
            items: [],
            itemsPerPageOptions: [5, 10 ,15, 50, 100, 250, 500, 1000],
            itemsPerPage: 50,
            headers: [
                { text: 'WAREHOUSE', align: 'start', value: 'warehouse', sortable: false, },
                { text: 'MODEL', align: 'start', value: 'model', sortable: false, },
                { text: 'NAME', align: 'start', value: 'name', sortable: false, },
                { text: 'UOM', align: 'center', value: 'uom', sortable: false, },
                { text: 'TYPE', align: 'center', value: 'type', sortable: false, },
                { text: 'CATEGORY', align: 'start', value: 'category', sortable: false, },
                { text: 'TOTAL STOCKS', align: 'end', value: 'quantity', sortable: false, },
                // { text: 'INCOMING STOCKS', align: 'end', value: 'incoming_stocks', sortable: false, },
            ],

            initialLoad: false,

            loading:{
                    item_selection          : true,
                    warehouse_selection     : true,
                    item_type_selection     : true,
                    category_selection      : true
            }
        }
    },
    components: {
        ComponentHeader,ReportBodyComponent
    },
    mounted(){
        this.getWarehouses()
        this.getItems()
        let items_payload = {
            active: [0, 1]
        }
        this.$store.dispatch('getItems', items_payload)
        this.$store.dispatch('getItemTypes')
        this.$store.dispatch('getItemCategories')
    },
    computed:{
        ...mapGetters([
            'GET_ITEMS',
            'GET_ALL_WAREHOUSES',
            'GET_ITEM_TYPES',
            'GET_ITEM_CATEGORIES',
        ])
    },
    methods: {
        onSelectItems(item){
            this.item_ids = item
        },
        onSelectTypes(item){
            this.item_type_ids = item
        },
        onSelectCategories(item){
            this.item_category_ids = item
        },
        onSelectWarehouses(item){
            this.warehouse_ids = item
        },
        getItems(){
                this.items_list = this.GET_ITEMS
        },
        getWarehouses(no_virtual = false){
            let payload = {
                    no_virtual: no_virtual
            }
                this.$store.dispatch('getAllWarehouses',payload).then((response)=>{
                    this.warehouses_list = this.GET_ALL_WAREHOUSES
            });
        },
        // getItemTypes(){
        //     this.$store.dispatch('getItemTypes').then(response => {
        //         this.item_type_list = this.GET_ITEM_TYPES
        //     });
        // },
        // getItemCategories(){
        //     this.$store.dispatch('getItemCategories').then(response => {
        //         this.item_category_list = this.GET_ITEM_CATEGORIES.data
        //     });
        // },
        getAllItemsInWarehouse(){
            this.loaded = false
            let payload = {
                page:               this.options.page,
                itemsPerPage:       this.options.itemsPerPage,
                item_ids:           this.item_ids,
                warehouse_ids:      this.warehouse_ids,
                item_type_ids:      this.item_type_ids,
                item_category_ids:  this.item_category_ids,
                url: "getInventoryCount",
            }
            this.$store.dispatch('reports',payload).then(response => {
                this.items        = response.data.items.data
                this.totalItems   = response.data.items.total

                this.loaded       = true
                this.initialLoad  = true
            })

        },
        async exportInventoryCountToFastExcel(){
            this.loaded = false

            var excel_data = [];
            var final_data = [];
            let incoming_stocks = []
            let data = [];
            const workbook = new this.$exceljs.Workbook();
            let wh_id = []
            wh_id = this.GET_ALL_WAREHOUSES.map(function(e){
                return e.value
            })
            if(this.warehouse_ids.length == 0 && this.item_type_ids == 3){
                swal.fire(
                    "Select 3 warehouses",
                    "",
                    "warning"
                );
                this.loaded = true
                return false
                // for(let i = 0; i<=wh_id.length; i++ ){

                //     let payload_container = {
                //     page:               this.options.page,
                //     itemsPerPage:       this.options.itemsPerPage,
                //     item_ids:           this.item_ids,
                //     warehouse_ids:      [wh_id[i]],
                //     item_type_ids:      this.item_type_ids,
                //     item_category_ids:  this.item_category_ids,
                //     url:                "getInventoryCountReportExportContainerItem",
                //     export:             true,
                //     }

                //     await this.$store.dispatch('reports',payload_container).then(async response => {
                //     console.log(response.data.data)
                //     incoming_stocks = response.data.data.map(function(e){
                //         return e
                //     })
                //     let payload = {
                //         page:               this.options.page,
                //         itemsPerPage:       this.options.itemsPerPage,
                //         item_ids:           this.item_ids,
                //         warehouse_ids:      [wh_id[i]],
                //         item_type_ids:      this.item_type_ids,
                //         item_category_ids:  this.item_category_ids,
                //         url:                "getInventoryCountReportExportItems",
                //         export:             true,
                //     }
                //     await this.$store.dispatch('reports',payload).then(response=>{
                //         console.log(response.data)
                //     // const excel_data = [{response: {data: "value1"}}, {response: {data: "value2"}}, {response: {data: "value3"}}];
                //     // let final_data = excel_data.map(element => element.response.data);

                //     response.data.data.forEach(function(data) {
                //         excel_data.push({
                //         category: data.category,
                //         item_id: data.item_id,
                //         model: data.model,
                //         name: data.name,
                //         quantity: data.quantity,
                //         type: data.type,
                //         uom: data.uom,
                //         warehouse: data.warehouse,
                //         warehouse_code: data.warehouse_code,
                //         warehouse_name: data.warehouse_name
                //         });
                //     });

                //     final_data = excel_data;
                //         data = final_data.map(e=>{
                //         let a = incoming_stocks.find(element => element.item_id == e.item_id )
                //         e.incoming_stocks = !!a ? parseInt(a.incoming_stocks) : 0
                //         return e
                //     })
                //     // console.log(excel_data)
                //     // // console.log(response.data)
                //     // // let jsonString = response.data
                //     // // let items = JSON.parse("[" + jsonString.replace(/}{/g, "},{") + "]");
                //     // // console.log(items);
                //     // console.log(response.data.data)
                //     //

                //     }).catch(e => {
                //         swal.fire('Error !', '', 'error')
                //         console.log(e)
                //         this.loaded = true
                //         })
                //     })
                // }
            } else if(this.warehouse_ids.length > 3 && this.item_type_ids == 3){
                swal.fire(
                    "Select only 3 warehouses",
                    "",
                    "warning"
                );
                this.loaded = true
                return false
            }
            else {
                let payload_container = {
                    item_ids:           this.item_ids,
                    warehouse_ids:      this.warehouse_ids,
                    item_type_ids:      this.item_type_ids,
                    item_category_ids:  this.item_category_ids,
                    url:                "getInventoryCountReportExportContainerItem",
                    export:             true,
                }

                await this.$store.dispatch('reports',payload_container).then(async response => {
                    incoming_stocks = response.data.data

                    let payload = {
                        item_ids:           this.item_ids,
                        warehouse_ids:      this.warehouse_ids,
                        item_type_ids:      this.item_type_ids,
                        item_category_ids:  this.item_category_ids,
                        url:                "getInventoryCountReportExportItems",
                        export:             true,
                    }

                    await this.$store.dispatch('reports', payload).then(response => {
                        response.data.data.forEach(function(data) {
                            excel_data.push({
                                category: data.category,
                                item_id: data.item_id,
                                model: data.model,
                                name: data.name,
                                quantity: data.quantity,
                                type: data.type,
                                uom: data.uom,
                                warehouse_id: data.warehouse_id,
                                warehouse: data.warehouse,
                                warehouse_code: data.warehouse_code,
                                warehouse_name: data.warehouse_name,
                                item_location:data.location
                            });
                        });

                        final_data = excel_data;
                        data = final_data
                        // data = final_data.map(e=>{
                        //     let a = incoming_stocks.find(element => element.item_id == e.item_id && element.warehouse_id == e.warehouse_id)
                        //     e.incoming_stocks = !!a ? parseInt(a.incoming_stocks) : 0
                        //     return e
                        // })
                    })
                    .catch(e => {
                        swal.fire('Error !', '', 'error')
                        console.log(e)
                        this.loaded = true
                    })
                })
            }

            const worksheet = workbook.addWorksheet(`Inventory Count ${this.$dayjs().format('YYYY-MM-DD')}`);

            worksheet.columns = [
                {header:'WAREHOUSE',key:'warehouse'},
                {header:'MODEL',key:'model'},
                {header:'NAME',key:'name'},
                {header:'UOM',key:'uom'},
                {header:'TYPE',key:'type'},
                {header:'CATEGORY',key:'category'},
                {header:'TOTAL STOCKS',key:'quantity'},
                {header:'Location',key:'item_location'},
                // {header:'INCOMING STOCKS',key:'incoming_stocks'},
            ]

            data.forEach((e, i) => {
                e.quantity = parseInt(e.quantity)
                worksheet.addRow(e);
            });

            worksheet.getRow(`0${1}`).font = { bold: true }

            await workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                this.$filesaver.saveAs(blob, `CurrentInventoryCount ${this.$dayjs().format('YYYY-MM-DD')}.xlsx`);
            });
            this.loaded = true
        },
        resetFields(){
            this.errors                 = []
            this.item_ids               = []
            this.warehouse_ids          = []
            this.item_type_ids          = []
            this.item_category_ids      = []
        },
        upperCaseFirstLetter(str){
            const arr = str.split(" ");
            for (var i = 0; i < arr.length; i++) {
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
            }
            str = arr.join(" ");
            return str
        },
    },
    watch: {
        options: {
            handler () {
                if(this.initialLoad == true){
                    this.getAllItemsInWarehouse()
                }
            },
            deep: true,
        },
        'GET_ITEM_TYPES':{
            handler(val){
                this.item_type_list = val.data
                this.loading.item_type_selection = false
            }
        },
        'GET_ITEM_CATEGORIES':{
            handler(val){
                this.item_category_list = val.data
                this.loading.category_selection = false
            }
        },
        'GET_ALL_WAREHOUSES':{
            handler(val){
                this.warehouses_list = val
                this.loading.warehouse_selection = false
            }
        },
        'GET_ITEMS':{
            handler(val){
                this.warehouses_list = val
                this.loading.item_selection = false
            }
        },
    }
};
</script>
<style>
    .col{
        padding: 2px !important;
    }

    .card-body{
        padding: 3px !important;
    }
</style>
