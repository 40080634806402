<template>
    <div class="pb-4" style="width:100%">
        <v-card class="bg-color" style="border-radius:0px;">
             <v-row class="pa-10">
                <v-col class="">
                    <h5>Unassigned Parts</h5>
                        <div class="d-flex justify-space-between">
                            <v-btn
                            small
                            v-if="userAccess.assign"
                            :disabled="isDisabled"
                            class="mt-1"
                            @click="dialog = true"
                            >Assign
                            </v-btn>
                            <v-tooltip right color="#F69F1A">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        :loading="loading"
                                        v-bind="attrs"
                                        class="ml-2"
                                        v-on="on"
                                        text
                                        icon
                                        @click="showBatchAdditionDialog()"
                                    >
                                        <v-icon class="btn-action" color="success">
                                            mdi-cloud-upload
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>Upload Excel File</span>
                            </v-tooltip>
                            <v-spacer></v-spacer>
                            <v-col md="3" class="d-flex justify-space-between">
                                <v-text-field
                                v-model="search"
                                label="Search"
                                single-line
                                hide-details
                                clearable
                                dense
                                append-icon="mdi-magnify"
                                @click:append="getAllSpareParts()"
                                @keydown.enter="getAllSpareParts()"
                            />
                            </v-col>
                        </div>

                        <!-- ITEM DATA TABLE -->

                        <v-data-table
                            :headers="headers"
                            v-model="selected"
                            :items="this.items"
                            :loading="loading"
                            :search="search"
                            :options.sync="options"
                            show-select
                            item-key="id"
                            :server-items-length="totalItems"
                            :items-per-page="itemsPerPage"
                        >
                        <template v-slot:[`item.image_path`]="{ item }">
                            <v-img class="img-thumbnail" width="50" height="50" :src="checkImagePath(item)" @error="checkImage(item)" eager contain></v-img>
                        </template>

                        <template v-slot:[`item.part_num`]="{ item }">
                            <span> {{ item.part_num === null || item.part_num == 'null' ? '-': item.part_num }} </span>
                        </template>

                        <template v-slot:[`item.action`]="{ item }">
                            <span class="text-nowrap">
                                <v-btn text icon color="red">
                                    <v-icon class="btn-action" small @click="viewDeleteItem(item)">mdi-delete</v-icon>
                                </v-btn>
                            </span>
                        </template>

                        </v-data-table>
                        <!-- ITEM DATA TABLE -->
                </v-col>
             </v-row>
        </v-card>

        <div class="text-center">
            <v-dialog
            v-model="dialog"
            persistent
            width="1200"
            >
                <v-card>
                    <v-btn text icon color="gray" class="float-right ma-2" @click="closeAssignDialog">
                            <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                    <v-card-text>
                        <v-row class="m-0">
                            <v-card-title>
                                <span class="black--text headline">Assign Spare Parts</span>
                            </v-card-title>
                        </v-row>

                        <v-row class="d-flex align-center pr-5 pl-5">
                            <v-col md="6">
                                <v-autocomplete
                                v-model="assign_item"
                                :items="GET_ITEM_SELECTION_EXCLUDE_SP"
                                item-text="selected_item"
                                auto-select-first
                                item-value="id"
                                variant="solo"
                                clearable
                                outlined
                                dense
                                label="Assign to"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col md="1">
                                <v-tooltip  v-if="view_item_btn" bottom color="#f69f1a">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="btn-action"
                                            color="#f69f1a"
                                            v-bind="attrs"
                                            v-on="on"
                                            fab
                                            text
                                            x-small
                                            @click="viewItem(assign_item)"
                                        >
                                            <v-icon>mdi-eye</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>View Item Details</span>
                                </v-tooltip>
                            </v-col>
                            <v-col md="5" class="d-flex align-center justify-start">
                                <img v-bind:src="this.selected_img" class="preview-image"/>
                            </v-col>
                        </v-row>
                        <v-row class="pa-5">
                            <v-col md="12">
                                <!-- ITEM DATA TABLE -->
                                <v-data-table
                                    :headers="headers_selected"
                                    :items="selected"
                                >
                                <template v-slot:[`item.image_path`]="{ item }">
                                    <v-img class="img-thumbnail" width="50" height="50" :src="checkImagePath(item)" @error="checkImage(item)" eager contain></v-img>
                                </template>

                                <template v-slot:[`item.part_num`]="{ item }">
                                    <span> {{ item.part_num === null || item.part_num == 'null' ? '-': item.part_num }} </span>
                                </template>

                                <template v-slot:[`item.action`]="{ item }">
                                    <span class="text-nowrap">
                                        <v-btn text icon color="red">
                                            <v-icon class="btn-action" small  @click="deleteItem(item.id)">mdi-delete</v-icon>
                                        </v-btn>
                                    </span>
                                </template>

                                <template v-slot:[`item.baklas_quantity`]="{ item }">
                                    <span class="text-nowrap">
                                        <v-text-field
                                            :background-color="item.background_color ?? ''"
                                            outlined
                                            dense
                                            :min="0"
                                            type="number"
                                            v-model="item.baklas_qty"
                                        >

                                        </v-text-field>
                                    </span>
                                </template>

                                <template v-slot:[`item.sp_number`]="{ item }">
                                    <span class="text-nowrap">
                                        <v-text-field
                                            :background-color="item.background_color ? 'red' : ''"
                                            outlined
                                            dense
                                            type="text"
                                            v-model="item.sp_number"
                                        >
                                        </v-text-field>
                                    </span>
                                </template>

                                </v-data-table>
                                <!-- ITEM DATA TABLE -->
                            </v-col >
                        </v-row>
                    </v-card-text>

                    <v-card-actions class="d-flex justify-end">
                        <v-col v-if="errors.length > 0" cols="12" lg="4">
                            <span class="red--text font-weight-bold">ERROR FOUND</span>
                            <v-simple-table dense class="bordered">
                                <thead>
                                    <tr>
                                        <th class="text-left">ROW</th>
                                        <th class="text-left">MODEL</th>
                                        <th class="text-left">FIELD</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(error, index) in errors" :key="index">
                                        <td class="red--text">{{ error.row }}</td>
                                        <td class="red--text">{{ error.model }}</td>
                                        <td class="red--text">{{ error.field }}</td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-col>
                        <v-spacer/>
                        <v-col cols="12" lg="6" class="d-flex justify-end">
                            <v-btn
                                :loading="assignSPLoading"
                                class="mb-2 px-4"
                                small
                                @click="AssignItem()">Submit
                            </v-btn>
                        </v-col>
                    <!-- <v-btn
                        v-if="assign_item_btn"
                        :disabled='isDisabled'
                        class="mb-2 px-4"
                        small
                        @click="AssignItem()">Submit
                    </v-btn> -->
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
        <div class="text-center">
            <v-dialog
            v-model="dialog2"
            width="1200"
            persistent
            >

            <v-card>
                <v-btn text icon color="gray" class="float-right" @click="closeExlDialog">
                        <v-icon>mdi-close-circle</v-icon>
                </v-btn>
                <v-card-text>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">Assign Spare Parts</span>
                    </v-card-title>
                </v-row>

                <v-row class="d-flex align-center pr-5 pl-5">
                    <v-col md="6">
                        <v-autocomplete
                        v-model="assign_item"
                        :items="parent_details"
                        item-text="selected_item"
                        auto-select-first
                        item-value="id"
                        variant="solo"
                        clearable
                        outlined
                        dense
                        label="Assign to"
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-col md="1">
                        <v-tooltip v-if="view_item_btn" bottom color="#f69f1a">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    class="btn-action"
                                    color="#f69f1a"
                                    v-bind="attrs"
                                    v-on="on"
                                    fab
                                    text
                                    x-small
                                    @click="viewItem(assign_item)"
                                >
                                    <v-icon>mdi-eye</v-icon>
                                </v-btn>
                            </template>
                            <span>View Item Details</span>
                        </v-tooltip>
                    </v-col>
                    <v-col md="5" class="d-flex align-center justify-start">
                        <img v-bind:src="this.selected_img" class="preview-image"/>
                    </v-col>
                </v-row>
                <v-row v-if="!batchAdditionDialog" class="pr-5 pl-5">
                    <v-col md="12">
                         <!-- ITEM DATA TABLE -->
                        <v-data-table
                            :headers="headers_excel"
                            :items="selected"
                        >
                        <template v-slot:[`item.image_path_pricelist`]="{ item }">
                            <v-img class="img-thumbnail" width="50" height="50" :src="checkImagePath(item)" @error="checkImage(item)" eager contain></v-img>
                        </template>

                        <template v-slot:[`item.part_num`]="{ item }">
                            <span> {{ item.part_num === null || item.part_num == 'null' ? '-': item.part_num }} </span>
                        </template>

                        <template v-slot:[`item.action`]="{ item }">
                            <span class="text-nowrap">
                                <v-btn text icon color="red">
                                    <v-icon class="btn-action" small  @click="deleteItem(item.id)">mdi-delete</v-icon>
                                </v-btn>
                            </span>
                        </template>

                        <template v-slot:[`item.baklas_quantity`]="{ item }">
                            <span class="text-nowrap">
                                <!-- <v-btn text icon color="red">
                                    <v-icon class="btn-action" small @click="deleteItem(item.id)">mdi-delete</v-icon>
                                </v-btn> -->
                                <v-text-field
                                outlined
                                dense
                                :min="0"
                                type="number"
                                v-model="item.baklas_qty"
                                >

                                </v-text-field>
                            </span>
                        </template>

                        <template v-slot:[`item.sp_number`]="{ item }">
                            <span class="text-nowrap">
                                <!-- <v-btn text icon color="red">
                                    <v-icon class="btn-action" small @click="deleteItem(item.id)">mdi-delete</v-icon>
                                </v-btn> -->
                                <v-text-field
                                outlined
                                dense
                                type="text"
                                v-model="item.sp_number"
                                >

                                </v-text-field>
                            </span>
                        </template>

                        </v-data-table>
                        <!-- ITEM DATA TABLE -->
                    </v-col >
                </v-row>
                <v-row v-if="batchAdditionDialog">
                    <v-col lg="12" class="d-flex justify-content-center align-items-center">
                        <LoadingComponentVue class="my-2"></LoadingComponentVue>
                    </v-col>
                </v-row>
                </v-card-text>

                <v-card-actions class="d-flex justify-end">
                    <v-col v-if="errors.length > 0" cols="12" lg="4">
                        <span class="red--text font-weight-bold">ERROR FOUND</span>
                        <v-simple-table dense class="bordered">
                            <thead>
                                <tr>
                                    <th class="text-left">ROW</th>
                                    <th class="text-left">MODEL</th>
                                    <th class="text-left">FIELD</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(error, index) in errors" :key="index">
                                    <td class="red--text">{{ error.row }}</td>
                                    <td class="red--text">{{ error.model }}</td>
                                    <td class="red--text">{{ error.field }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-col>
                    <v-spacer/>
                    <v-col cols="12" lg="8" class="d-flex justify-end">
                        <v-btn
                            small
                            v-if="!assign_item_btn"
                            :loading="assignSPLoading"
                            :disabled='!isDisabled'
                            @click="AssignItem('batch-upload')">Submit
                        </v-btn>
                        <v-btn
                            small
                            v-if="assign_item_btn"
                            :loading="assignSPLoading"
                            :disabled='isDisabled'
                            @click="AssignItem('batch-upload')">Submit
                        </v-btn>
                    </v-col>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </div>

        <div class="text-center">
            <v-dialog
            v-model="GET_SP_DIALOG"
            width="1200"
            >
                <DeleteSparePartDialog />
            </v-dialog>
        </div>

        <div>
            <v-dialog
                v-model="batchAdditionDialog"
                max-width="500"
                persistent
            >
                <v-card>
                    <v-card-title class="d-flex justify-content-between px-4">
                        <span class="text-lg font-weight-semibold" v-text="`Batch Upload Spare Parts`"></span>
                        <v-btn text icon color="gray" @click="closeBatchAdditionDialog">
                            <v-icon class="mr-0">mdi-close-circle</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-divider class="my-0"/>
                    <v-card-text class="mt-4">
                        <v-row class="mx-4">
                            <label>Please Upload File.
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        color="#F69F1A"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        mdi-file-question
                                      </v-icon>
                                    </template>
                                    <span><img src="/images/sp-batch_addition-format.png"/></span>
                                  </v-tooltip>
                            </label>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="pa-5">
                        <v-row class="px-4">
                            <v-col cols="12">
                                <v-file-input
                                    v-model="fileInput"
                                    accept=".xls, .xlsx"
                                    label="Attach Excel File"
                                    outlined
                                    dense
                                    @change="handleExlFile"
                                >
                                    <template v-slot:selection="{ text }">
                                        <v-chip
                                            small
                                            label
                                            color="#f69f1a"
                                        >
                                            {{ text }}
                                        </v-chip>
                                    </template>
                                </v-file-input>
                            </v-col>
                            <v-spacer></v-spacer>
                            <!-- <v-col lg="1">
                                <v-btn small @click="getSparePartDetails(records)" >Create</v-btn>
                            </v-col> -->
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>

         <!-- VIEW MODAL-->
         <ItemsViewComponent
            :viewItemId="viewItemId"
            @closeView="closeView"
        ></ItemsViewComponent>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import ItemsViewComponent from "@/views/main/layouts/dialogs/Sales/Items/ItemsViewComponent.vue";
import DeleteSparePartDialog from "@/views/main/modules/Services/Views/SparePartsComponent/DeleteSparePartsDialog.vue";
import LoadingComponentVue from '@/views/main/modules/Services/Views/TechnicianManagerComponent/LoadingComponent.vue';

export default {

    data(){
        return{
            dialog: false,
            dialog2: false,
            deleteDialog: false,
            search:'',
            selected:[],
            selected_item:[],
            selected_img:[],
            selected_item_model_name:[],
            items:[],
            loading: true,
            options:{},
            totalItems: 0,
            itemsPerPage: 10,
            assign_item:[],
            view_item_btn:false,
            assign_item_btn: false,
            viewItemId:'',
            closeView: false,
            view_items:[],
            isDisabled: true,
            headers: [
                { text: 'Image', align: 'left', value: 'image_path' },
                { text: 'Model', value: 'model'},
                { text: 'Name', value: 'name' },
                { text: 'UOM', value: 'uom' },
                { text: 'Baklas Qty', value: 'baklas_qty' },
                { text: 'Part#', value: 'part_num'},
                { text: 'Actions',align: 'center', value: 'action', sortable: false}
            ],
            headers_selected: [
                { text: 'Image', align: 'left', value: 'image_path' },
                { text: 'Model', value: 'model'},
                { text: 'Name', value: 'name' },
                { text: 'UOM', value: 'uom'},
                { text: 'Part#', value: 'part_num'},
                { text: 'SP Number', value: 'sp_number', width: "15%"},
                { text: 'Quantity', value: 'baklas_quantity', width: "10%"},
                { text: 'Actions', align: 'center', value: 'action', sortable: false }
            ],
            headers_excel: [
                { text: 'Image', align: 'left', value: 'image_path_pricelist'  },
                { text: 'Model', value: 'model'},
                { text: 'Name', value: 'name' },
                { text: 'UOM', value: 'item_uom_text' },
                { text: 'Part#', value: 'part_num'},
                { text: 'SP Number', value: 'sp_number', width: "15%"},
                { text: 'Quantity', value: 'baklas_quantity', width: "10%"},
                { text: 'Actions', align: 'center', value: 'action', sortable: false }
            ],
            userAccess: {
                view: false,
                edit: false,
                delete: false,
                assign: false,
            },
            reset_itemsperpage: false,
            parent_details: [],
            batchAdditionDialog: false,
            records: [],
            batchAdditionLoading: false,
            fileInput: null,
            assignSPLoading: false,
            errors:[],
        }

    },
    props: ['tab'],
    components:{
        ItemsViewComponent,
        DeleteSparePartDialog,
        LoadingComponentVue
    },
    computed:{
            ...mapGetters([
            'GET_ITEM_SELECTION_DEFAULT',
            'GET_ALL_ITEM_UNASSIGN',
            'GET_SHOW_ITEM',
            'USER_ACCESS',
            'GET_ITEM_SELECTION',
            'GET_ITEM_SELECTION_EXCLUDE_SP',
            'GET_SP_DIALOG',
            'GET_ITEMS_BY_MODELS',
            'GET_SPARE_PART_PARENTS',
            'GET_ALL_SPARE_PARTSS',

        ]),
    },
        methods:{
            getAllSpareParts(){
                if(this.reset_itemsperpage){
                    this.options.itemsPerPage = 10
                    this.reset_itemsperpage = false
                }
                this.loading = true;
                this.items = []
                let payload = {
                    page: this.options.page,
                    itemsPerPage: this.options.itemsPerPage,
                    search: this.search,
                }
                this.$store.dispatch('getAllSpareParts',payload).then(response=> {
                    this.items = this.GET_ALL_SPARE_PARTSS.data;
                    this.totalItems = response.data.totalItems
                    this.loading = false;

                })
            },
            // getAllPtItems(){
            //     if(this.reset_itemsperpage){
            //         this.options.itemsPerPage = 10
            //         this.reset_itemsperpage = false
            //     }
            //     this.loading = true;
            //     this.items = []
            //     let payload = {
            //         page: this.options.page,
            //         itemsPerPage: this.options.itemsPerPage,
            //         search: this.search,
            //     }
            //     this.$store.dispatch('getServiceItemUnassign',payload).then(response=> {

            //         this.items = this.GET_ALL_ITEM_UNASSIGN.data;
            //         this.totalItems = response.data.totalItems
            //         this.loading = false;

            //     })
            // },
            AssignItem(tag=''){
                this.errors = [];
                if(this.selected.length < 1 || this.assign_item == null || this.assign_item == ''){
                    Swal.fire("", "-Please fill required fields.", "info");
                    return;
                }
                let invalidQuantity = this.selected.filter(e => e.baklas_qty < 0 || e.baklas_qty == null || e.baklas_qty == '')
                let emptySpNumber = this.selected.filter(e => e.sp_number == '' || e.sp_number == null)
                let payload = {
                    update_items: this.selected.map(e=>{
                            return {
                                item_id: e.id,
                                part_num: e.part_num,
                                sp_number: e.sp_number ? e.sp_number : '',
                                baklas_quantity: e.baklas_qty
                            }
                    }),
                    parent_id: this.assign_item,
                }
                if (invalidQuantity.length > 0 || emptySpNumber.length > 0) {
                    if (emptySpNumber.length > 0) {
                        this.validateField(emptySpNumber, 'sp-number', tag)
                    }
                    if (invalidQuantity.length > 0) {
                        this.validateField(invalidQuantity, 'baklas_qty', tag)
                    }
                    Swal.fire('', 'Invalid values were found.', 'info');
                } else {
                    this.assignSPLoading = true;
                    this.$store.dispatch('sparePartsAssign',payload).then(response=> {
                        Swal.fire(response.data);
                        this.selected = [];
                        this.assignSPLoading = false;
                        this.dialog = false;
                        this.getAllSpareParts();
                        this.closeBatchAdditionDialog();
                    }).catch(e => {
                        console.log(e)
                        this.assignSPLoading = false;
                    })
                }
            },
            checkImagePath(item) {
                this.$store.dispatch('checkImagePath', item);
            },
            checkImage(item) {
                item.image_path = "/images/items/no-image.png";
                return item.image_path;
            },
            viewItem(id){
                this.viewItemId = id;
            },
            deleteItem(id){
                let remove_selected = this.selected.filter((el) => el.id !== id);
                this.selected = remove_selected;

                if (this.selected.length == 0) {
                    this.records = [];
                }
            },
            // method to view the to-delete item
            viewDeleteItem(item) {
                let payload = {
                    id: item.id,
                }
                this.$store.commit('SP_DIALOG', true);
                this.$store.dispatch('getSparePartParents', payload)
                this.$store.commit('SPARE_PART', item)
            },
            handleExlFile(event)
            {
                let exlFile = event;

                if(exlFile)
                {
                    const fileReader = new FileReader();
                    fileReader.onload = (event) => {
                        let data = event.target.result;
                        let workbook = XLSX.read(data, { type:'binary'});
                        let workSheetName = workbook.SheetNames[0];
                        let worksheet = workbook.Sheets[workSheetName];

                        this.parseExlFileData(worksheet);
                        this.dialog2 = true;
                    }
                    fileReader.readAsBinaryString(exlFile);
                }
            },
            /**
             * @param {string} sheet sheetname;
             */
            parseExlFileData(sheet)
            {
                let that = this;

                const range = XLSX.utils.decode_range(sheet['!ref']);
                // Get Columns Name
                let colNames = [];
                for (let colNum = range.s.c; colNum <= range.e.c; colNum++)
                {
                    const cellAddress = XLSX.utils.encode_cell({ r: range.s.r, c: colNum });
                    let columnName = sheet[cellAddress]?.v;

                    colNames.push(this.strNoHiPenAndSpace(columnName));
                }
                // Get Records
                for (let rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++)
                {
                    const row = {};
                    for (let colNum = range.s.c; colNum <= range.e.c; colNum++)
                    {
                        const cellAddress = XLSX.utils.encode_cell({ r: rowNum, c: colNum });
                        const cellValue = sheet[cellAddress]?.v;

                        row[colNames[colNum]] = cellValue;
                    }
                    if (row.model == undefined)
                    {
                        break;
                    }
                    that.records.push(row);
                }

                let parentItemModel = this.getParentDetails(that.records);
                let selectedParent = this.GET_ITEM_SELECTION_EXCLUDE_SP.filter(item => item.model === parentItemModel);

                this.parent_details = selectedParent;
                this.assign_item = selectedParent[0]?.id;

                this.getSparePartDetails(that.records);

            },
            /**
             * @param {object} records  data in form of Object
             */
            getParentDetails(records) {
                let keyValues = records.map((record) => record.parent_item);
                const keyValue = new Set(keyValues);
                const parentValue = keyValue.values();
                if (!!keyValues){
                    if (keyValue.size == 1)
                    {
                        const parentValuee = parentValue.next().value;
                        return parentValuee.trim();
                    } else if (keyValue.size > 1)
                    {
                        Swal.fire({
                            text: "Oops, more than one parent item not allowed.",
                            icon: 'error',
                            allowOutsideClick: false,},
                        );
                        console.error("Oops, No more than one parent at a time.")
                        this.closeExlDialog();
                        return;
                    } else {
                        Swal.fire({
                            text: "Unprocessable Content.",
                            icon: 'error',
                            allowOutsideClick: false,},
                        );
                        console.error("Unprocessable Content.");
                        this.closeExlDialog();
                        return;
                    }
                } else {
                    Swal.fire({
                            text: "Unprocessable Content.",
                            icon: 'error',
                            allowOutsideClick: false,},
                        );
                    console.error("Unprocessable Content.");
                    this.closeExlDialog();
                    return;
                }
            },
             /**
             * @param {object} records  data in form of Object
             */
            getSparePartDetails(records)
            {
                this.batchAdditionLoading = true;
                // const values = records.filter((record) => record.model);
                let sparePartsModel = records.map((record) => record.model.toUpperCase());
                if (!!sparePartsModel)
                {
                    let payload = {
                        items: sparePartsModel,
                        isChecking: false,
                    };
                    this.$store.dispatch("getItemsByModel", payload).then((response) => {
                        this.selected = this.compareTwoArrayObjects(this.GET_ITEMS_BY_MODELS, records);
                        if (!!this.selected) {
                            this.batchAdditionDialog = false;
                        }
                    });

                } else {
                    Swal.fire({
                        text: "Unprocessable Content.",
                        icon: 'error',
                        allowOutsideClick: false,},
                    );
                    this.closeExlDialog();
                    console.error("No Content Found.");
                    return;
                }
            },
            /**
             * @param {string} name column name.
             */
            strNoHiPenAndSpace(name)
            {
                if(!!name)
                {
                    const validName = name.replaceAll(/[-\s]/g, '_').toLowerCase();
                    return validName;
                } else {
                    Swal.fire({
                        text: "Unprocessable Content.",
                        icon: 'error',
                        allowOutsideClick: false,},
                    );
                    this.closeExlDialog();
                    console.error("No Content Found.");
                    return;
                }
            },
            /**
             * @param {object} obj1 array of object first
             * @param {object} obj2 array of object second
             */
            compareTwoArrayObjects(obj1, obj2)
            {
                let mergedObjs = [];
                for (let ob of obj1)
                {
                    let objInObj2 = obj2.find((item) => item.model === ob.model);

                    if (objInObj2)
                    {
                        const mergedObj = {...ob, ...objInObj2}
                        mergedObjs.push(mergedObj)
                    }
                }
                return mergedObjs;
            },
            closeAssignDialog() {
                this.dialog = false;
                this.selected = [];
                this.errros = [];
            },
            closeExlDialog()
            {
                this.dialog2 = false;
                this.closeBatchAdditionDialog();
            },
            showBatchAdditionDialog() {
                this.selected = [];
                this.records = [];
                this.errors = [];
                this.batchAdditionDialog = true;
            },
            closeBatchAdditionDialog() {
                this.batchAdditionDialog = false;
                this.records = [];
                this.selected = [];
                this.fileInput = null;
                this.errors = [];
            },
            validateField(data = [], field, tag){
                if(data.length > 0) {
                    data.forEach(e => {
                        console.log(e)
                        let index = tag == 'batch-upload' ? this.selected.findIndex(item => item.item_id == e.item_id) : this.selected.findIndex(item => item.id == e.id)
                        this.errors.push({
                            row: index + 1,
                            model: e.model,
                            field: field == 'sp-number' ? 'SP Number' : 'Quantity'
                        })
                    })
                }
            }
        },
        mounted: function() {
            this.getAllSpareParts()
            //  this.getAllPtItems();
            this.$store.dispatch('getAllitemSelectionDefault')
            this.$store.dispatch('itemselection')
            this.$store.dispatch('itemselectionExcludeSp')
            this.$store.dispatch("checkAccessUser", this.$router.currentRoute.params.id);
         },
        watch:{
            'GET_ALL_SPARE_PARTSS'() {
                if(this.GET_ALL_SPARE_PARTSS.length > 0) {
                    this.loading = false;
                }
            },
            'selected':{
                handler(val){
                    if(val.length > 0){
                        this.isDisabled = false
                    }else{
                        this.isDisabled = true
                        this.dialog = false
                        this.assign_item = ''
                        this.dialog2 = false
                    }
                }
            },
            'search':{
                handler(val){

                    if(!!val){
                    this.reset_itemsperpage = false
                    this.options.page = 1
                    }else{
                        this.reset_itemsperpage = true
                        this.getAllSpareParts();
                    }
                }
            },
            options: {
            handler (val) {
                if(val.itemsPerPage < 0){
                    val.itemsPerPage = this.totalItems;
                }
                this.getAllSpareParts();
            },
            deep: true,
            },
            assign_item:{
                handler(val){
                    if(val >= 1){
                        this.assign_item_btn = true
                    }else{
                        this.assign_item_btn = false
                    }
                    this.selected_item = this.GET_ITEM_SELECTION_DEFAULT
                    this.selected_item.forEach(x =>{
                       if(x.value == this.assign_item){
                            this.selected_img = process.env.VUE_APP_API_HOST + "/" + x.image_path
                            this.selected_item_model_name = x.text +" / "+ x.name
                       }
                    })
                    if(val >= 1){
                        this.view_item_btn = true;
                    }else{
                        this.view_item_btn = false;
                        this.selected_img = [];
                    }
                },
            },
            USER_ACCESS: {
                handler(val) {
                    if(val != "fail"){
                        this.userAccess.edit = false;
                        this.userAccess.delete = false;
                        this.userAccess.view = false;
                    val.forEach(e => {
                        if (e.actions_code == 'edit') {
                            this.userAccess.edit = true;
                        } else if (e.actions_code == 'delete') {
                            this.userAccess.delete = true;
                        } else if (e.actions_code == 'view') {
                            this.userAccess.view = true;
                        } else if (e.actions_code == 'assign') {
                            this.userAccess.assign = true;
                        }
                    });
                    }
                },
            },
            tab:{
                handler(val){
                    if(val == 1){
                        this.getAllSpareParts();
                    }
                }
            }
    }
}
</script>

<style scoped>
    .preview-image{
        max-width: 130px;
        height: auto;
    }
    .bordered {
        border: 1px solid #353535,
    }
</style>
