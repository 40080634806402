<template>
    <v-container fluid>
        <ComponentHeader :button="true" class="ma-n4" title='Sales By Items' type='Sales'></ComponentHeader>
        <v-card>
            <v-card-text class="bg-bluish-gray">
                <v-row class="mx-auto" dense>
                    <v-col cols="3">
                        <v-autocomplete
                            v-model="filters.item_ids"
                            :items="GET_ITEM_SELECTION_DEFAULT"
                            background-color="white"
                            label="Select Item Models"
                            dense
                            outlined
                            hide-details
                            multiple
                            chips
                            auto-select-first
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                        <v-autocomplete
                            v-model="filters.item_ids"
                            :items="GET_ITEM_SELECTION_DEFAULT"
                            background-color="white"
                            label="Select Item Names"
                            item-text="name"
                            dense
                            outlined
                            hide-details
                            multiple
                            chips
                            auto-select-first
                        ></v-autocomplete>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="12" sm="12" md="2" lg="3" xl="3" class="d-flex justify-content-end">
                        <v-btn :loading="exportData.loading" :disabled="dataTable.loading" small @click="list('xlsx')">Export</v-btn>
                        <v-btn :loading="dataTable.loading" :disabled="exportData.loading" small style="margin-right: 3px" @click="list()">Filter</v-btn>
                    </v-col>
                </v-row>
                <v-row class="mx-auto" dense>
                    <v-col cols="2">
                        <v-autocomplete
                            v-model="filters.item_type_ids"
                            :items="GET_ITEM_TYPES"
                            background-color="white"
                            label="Select Disc Type"
                            dense
                            outlined
                            hide-details
                            multiple
                            chips
                            small-chips
                            auto-select-first
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="4">
                        <v-autocomplete
                            v-model="filters.item_category_ids"
                            :items="GET_ITEM_CATEGORIES"
                            background-color="white"
                            label="Select Item Category"
                            dense
                            outlined
                            hide-details
                            multiple
                            chips
                            small-chips
                            auto-select-first
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="2">
                        <v-autocomplete
                            v-model="filters.active"
                            :items="dropdowns.active"
                            background-color="white"
                            label="Active Type"
                            dense
                            outlined
                            hide-details
                            auto-select-first
                        ></v-autocomplete>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <v-row class="mx-auto" dense>
                    <v-col cols="12">
                        <v-data-table
                            :headers="dataTable.headers"
                            :items="dataTable.items"
                            :server-items-length="dataTable.total"
                            :options.sync="dataTable.pagination"
                            :footer-props="dataTable.footerProps"
                            :loading="dataTable.loading"
                            dense
                            disable-sort
                            @update:page="list()"
                            @update:itemsPerPage="list()"
                        >
                            <template #item.image_path="{ item }">
                                <span class="d-flex justify-center">
                                    <v-img
                                        :src="checkImagePath(item)"
                                        :aspect-ratio="1/1"
                                        class="img-thumbnail"
                                        max-width="60"
                                        max-height="60"
                                        eager
                                        contain
                                        @error="checkImage(item)"
                                    ></v-img>
                                </span>
                            </template>
                            <template #item.selling_price="{ item }">
                                {{ item.selling_price | currency }}
                            </template>
                            <template #item.sp_price="{ item }">
                                {{ item.sp_price | currency }}
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent'
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            filters: {
                item_ids: [],
                item_type_ids: [1, 2],
                item_category_ids: [],
                active: 1,
            },
            dataTable: {
                headers: [
                    { text: 'Image', align: 'center', value: 'image_path' },
                    { text: 'Model', value: 'model' },
                    { text: 'Name', value: 'name' },
                    { text: 'UOM', value: 'uom' },
                    { text: 'Type', value: 'type' },
                    { text: 'Category', value: 'category' },
                    { text: 'SRP', align: 'end', value: 'selling_price' },
                    { text: 'SP', align: 'end', value: 'sp_price' },
                ],
                items: [],
                total: 0,
                pagination: {
                    itemsPerPage: 10,
                },
                footerProps: {
                    itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
                    showFirstLastPage: true,
                },
                loading: false,
            },
            exportData: {
                filename: 'Sales By Items',
                file_ext: 'xlsx',
                headers: [
                    { header: 'IMAGE', key: '', width: 22, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                    { header: 'MODEL', key: 'model', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                    { header: 'NAME', key: 'name', width: 30, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                    { header: 'UOM', key: 'uom', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                    { header: 'TYPE', key: 'type', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                    { header: 'CATEGORY', key: 'category', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                    { header: 'SRP', key: 'selling_price', width: 18, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: '#,##0.00' } },
                    { header: 'SP', key: 'sp_price', width: 18, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: '#,##0.00' } },
                ],
                items: [],
                loading: false,
            },
            dropdowns: {
                active: [
                    { value: -1, text: 'All' },
                    { value: 1, text: 'Active' },
                    { value: 0, text: 'Inactive' },
                ]
            }
        }
    },
    mixins: [
        SharedFunctionsComponent
    ],
    components: {
        ComponentHeader,
    },
    mounted() {
        this.$store.dispatch('getAllitemSelectionDefault')
        this.$store.dispatch('getItemTypes')
        this.$store.dispatch('getItemCategories')
    },
    computed: {
        ...mapGetters([
            'GET_ITEM_TYPES',
            'GET_ITEM_CATEGORIES',
            'GET_ITEM_SELECTION_DEFAULT'
        ]),
    },
    methods: {
        list(type = 'list') {
            if (type == 'list') {
                this.dataTable.loading = true
                this.dataTable.items = []
            } else {
                this.exportData.loading = true
                this.exportData.items = []
            }

            this.$store.dispatch('reports', {
                page: this.dataTable.pagination.page,
                itemsPerPage: this.dataTable.pagination.itemsPerPage,
                item_ids: this.filters.item_ids,
                item_type_ids: this.filters.item_type_ids,
                item_category_ids: this.filters.item_category_ids,
                active: this.filters.active,
                export: type == 'xlsx' ? 1 : 0,
                url: 'sales-by-items',
            }).then(async response => {
                let data = type == 'list' ? response.data.items.data : response.data.items
                let sales = response.data.sales
                let date_criteria = response.data.date_criteria

                if (type == 'list') {
                    this.dataTable.headers.splice(8, this.dataTable.headers.length - 8)

                    date_criteria.forEach(element => {
                        this.dataTable.headers.push(Object.assign({}, {
                            text: element + ' SALES',
                            value: element,
                            align: 'end'
                        }))
                    })

                    for (let item of data) {
                        date_criteria.forEach(element => {
                            item[element] = this.getTwoDecimals(parseFloat(sales[item.id].find(({ date_criteria }) => date_criteria == element).total_amount ?? 0))
                        })

                        this.dataTable.items.push(item)
                    }

                    this.dataTable.total = response.data.items.total

                    this.dataTable.loading = false
                } else if (type == 'xlsx') {
                    const workbook = new this.$exceljs.Workbook()
                    const worksheet = workbook.addWorksheet('Raw Data')

                    date_criteria.forEach(element => {
                        this.exportData.headers.push(Object.assign({}, {
                            header: element + ' SALES',
                            key: element,
                            width: 18,
                            style: { alignment: { vertical: 'middle', horizontal: 'center' }, numFmt: '#,##0.00' }
                        }))
                    })

                    worksheet.columns = this.exportData.headers

                    for (let i = 0; i < data.length; i++) {
                        let item = data[i]

                        date_criteria.forEach(element => {
                            item[element] = parseFloat(sales[item.id].find(({ date_criteria }) => date_criteria == element).total_amount ?? 0)
                        })

                        worksheet.addRow(item)
                        await this.addImageToWorksheet(workbook, worksheet, item, i + 1);
                    }

                    workbook.xlsx.writeBuffer().then((data) => {
                        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheetcharset=utf-8' })
                        this.$filesaver.saveAs(blob, `${this.exportData.filename} Report ${this.$dayjs().format('YYYY-MM-DD')}.${this.exportData.file_ext}`)
                    })

                    this.exportData.loading = false
                }
            }).catch(error => {
                if (type == 'list') {
                    this.dataTable.loading = false
                } else {
                    this.exportData.loading = false
                }
            })
        },
        checkImagePath(item) {
            return item.image_path == null
                ? process.env.VUE_APP_API_HOST + "/images/items/no-image.png"
                : process.env.VUE_APP_API_HOST + "/" + item.image_path;
        },
        checkImage(item) {
            item.image_path = process.env.VUE_APP_API_HOST + "/images/items/no-image.png";
            return item.image_path;
        },
    },
}
</script>
<style scoped>
.col {
    padding: 2px !important;
}

.card-body {
    padding: 3px !important;
}
</style>
