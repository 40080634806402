<template>
    <div class="tabs-body-color">
        <v-container fluid>
            <v-row dense>
                <!-- <v-col cols="6" class="d-flex no-block align-items-center">
                    <div class="btn-toolbar" v-if="userAccess.create">
                        <v-btn data-modal-id="addSalesOrderModal" @click="showAddSalesOrder()">
                            <v-icon>mdi-plus</v-icon>Add
                        </v-btn>
                    </div>
                </v-col> -->
                <v-spacer></v-spacer>
                <v-col cols="6" align="right">
                    <!-- <v-btn class="mr-1 mb-2" @click="exportSalesOrder(status_text)">Export</v-btn> -->
                    <v-menu offset-y open-on-hover>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-on="on" v-bind="attrs" depressed>Export</v-btn>
                        </template>
                        <v-list
                            color="blue-grey lighten-5"
                            outlined
                            dense
                            tile
                        >
                            <v-list-item
                                v-for="(item, index) in exportByStatus"
                                :key="index"
                                light
                                dense
                                @click="exportSalesOrder(item, status_text)"
                                >
                                <v-list-item-title>{{ item.text }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row>
                <sales-orders-table-component
                    :salesOrdersActionResult="action"
                    :status_text="status_text"
                    :service="service"
                    :print="print"
                    @showEditModal="showEditModal"
                    @filters="filters"
                ></sales-orders-table-component>
            </v-row>
        </v-container>

        <v-app id="addEditSalesOrderModal">
            <v-dialog v-model="add_edit_salesorder_dialog" persistent max-width="98%" scrollable>
                <v-card>
                    <v-row class="m-0">
                        <v-card-title>
                            <span class="headline ml-4">Sales Order</span>
                        </v-card-title>
                        <v-col class="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right"
                                @click="add_edit_salesorder_dialog = false; resetFields(); $emit('closeModal');">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card-text v-if="loaded == true">
                        <form class="form-horizontal" action="/salesorders" method="post"
                            v-on:submit.prevent="onSubmit">
                            <div class="card-body">
                                <div class="row m-0 text-dark" v-if="action == 'Edit'">
                                    <div class="col-sm-12 col-md-12 text-left">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-9 p-0"></div>
                                            <div class="col-sm-12 col-md-3 p-0">Sales Order No: {{ this.order_num }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12 col-md-9 p-0"></div>
                                            <div class="col-sm-12 col-md-3 p-0">Issuer: {{ this.issuer_name }}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12 col-md-9 p-0"></div>
                                            <div class="col-sm-12 col-md-3 p-0">Date Issued: {{ this.created_at |
                                                    formatDateTime
                                            }}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12 col-md-9 p-0"></div>
                                            <div class="col-sm-12 col-md-3 p-0">Promo: {{ this.promo_name }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md-3">
                                        <label for="customer_id" class="control-label col-form-label">Promo</label>
                                        <v-autocomplete outlined dense :items="promo_list" v-model="promo_id"
                                            item-value="value" item-text="text" label="Select Promo"
                                            class="d-inline-block small" :isDisabled="action == 'Edit'">
                                        </v-autocomplete>
                                        <span v-for="errors in errors.promo_id" class="text-warning"
                                            v-bind:key="errors">{{ errors }}</span>
                                    </div>
                                    <div class="col-sm-12 col-md-3">
                                        <label for="customer_id" class="control-label col-form-label">Delivery
                                            Method</label>
                                        <v-autocomplete outlined dense :items="delivery_method_list"
                                            v-model="delivery_method" item-value="value" item-text="text"
                                            placeholder="Select Delivery Method" class="d-inline-block small">
                                        </v-autocomplete>
                                        <span v-for="errors in errors.promo_id" class="text-warning"
                                            v-bind:key="errors">{{ errors }}</span>
                                    </div>
                                    <div class="col-sm-12 col-md-4">
                                        <br>
                                        <v-switch v-model="stock_card" label="Stock Card" color="primary"
                                            input-value="1"></v-switch>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md-3">
                                        <label for="customer_id"
                                            class="float-left control-label col-form-label">Customer</label>
                                        <v-autocomplete outlined dense :items="filtered_customers_list"
                                            v-model="customer_id" item-value="value" item-text="text"
                                            placeholder="Select Customer" class="d-inline-block small">
                                        </v-autocomplete>
                                        <span v-for="errors in errors.customer_id" class="text-warning"
                                            v-bind:key="errors">{{ errors }}</span>
                                    </div>
                                    <div class="col-sm-12 col-md-4">
                                        <label for="shipping_address"
                                            class="float-left control-label col-form-label">Shipping Address</label>
                                        <v-autocomplete v-model="customer.shipping_address"
                                            :items="shipping_address_list" item-text='text' item-value='value'
                                            placeholder="Select Address" dense outlined color="#b3d4fc"
                                            class="w-100 small" id="shipping_address_text"></v-autocomplete>
                                        <span v-for="errors in errors.shipping_address_text" class="text-warning"
                                            v-bind:key="errors">{{ errors }}</span>
                                    </div>
                                    <div class="col-sm-12 col-md-3">
                                        <label for="salesman_id"
                                            class="float-left control-label col-form-label">Salesman</label>
                                        <v-autocomplete outlined dense :items="salesman_list" v-model="salesman_id"
                                            item-value="value" item-text="text" placeholder="Select Salesman"
                                            class="d-inline-block">
                                        </v-autocomplete>
                                        <span v-for="errors in errors.salesman_id" class="text-warning"
                                            v-bind:key="errors">{{ errors }}</span>
                                    </div>
                                    <div class="col-sm-12 col-md-2">
                                        <label for="terms" class="float-left control-label col-form-label">Terms</label>
                                        <div class="input-group mb-3">
                                            <input type="text" class="form-control" placeholder="0" v-model="terms"
                                                @keypress="numberOnly" aria-label="%" aria-describedby="basic-addon2"
                                                :readonly="promo_id != ''">
                                            <div class="input-group-append">
                                                <span class="input-group-text" id="basic-addon2">days</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <div class="row ml-2 mt-2"
                                        v-if="customer != '' && customer.discounts.length > 0 && promo_id == null">
                                        <div class="col-sm-12 col-md-8">
                                            <div class="row p-0 text-dark">
                                                <div class="col-sm-12 col-md-6 py-0"
                                                    v-for="(disc, index ) in itemTypeDiscounts" :key="index"
                                                    v-show="orderList[0] != null && disc.length > 0 && disc.substring(0, 2) == orderList[0].type">
                                                    <span>{{ disc }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 p-0"></div>
                                    </div>
                                </div>
                                <div class="col-sm-6 offset-md-2 col-md-1">
                                    <v-textarea label="PO#" no-resize rows="2" v-model="customer_po_num"
                                        class="p-0"></v-textarea>
                                </div>
                                <div class="col-sm-6 col-md-3">
                                    <v-textarea label="Remarks" no-resize rows="2" v-model="remarks"
                                        class="p-0"></v-textarea>
                                </div>
                            </div>

                            <v-card outlined class="p-2">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="float-left mx-2">
                                            <v-btn dark small color="primary" :disabled="disableBatchAddition()"
                                                @click="batch_addition.dialog = true">
                                                Batch Addition
                                            </v-btn>
                                        </div>
                                        <div class="float-right mx-2">
                                            <v-btn fab dark small color="primary" style="height:20px; width:20px;"
                                                @click="addOrderItem()">
                                                <v-icon dark small style="font-size:12px">
                                                    mdi-plus
                                                </v-icon>
                                            </v-btn>
                                            <v-btn fab dark small color="primary" style="height:20px; width:20px;"
                                                @click="removeOrderItem()">
                                                <v-icon dark small style="font-size:12px">
                                                    mdi-minus
                                                </v-icon>
                                            </v-btn>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <v-simple-table dense class="dynamic_list_table">
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-center mx-2" width="12%">Model</th>
                                                        <th class="text-center mx-2" width="22%">Name</th>
                                                        <th class="text-center mx-2" width="9%">MOQ</th>
                                                        <th class="text-center mx-2" width="9%">Order Qty</th>
                                                        <th class="text-center mx-2" v-if="stock_card == 1">SC Qty</th>
                                                        <th class="text-center mx-2" width="9%">UOM</th>
                                                        <th class="text-center mx-2">SRP</th>
                                                        <th class="text-center mx-2">Disc. Price</th>
                                                        <th class="text-center mx-2" width="1%">FOC</th>
                                                        <th class="text-center mx-2">Total</th>
                                                        <th class="text-center mx-2" width="5%">Type</th>
                                                        <th class="text-center mx-2" width="1%"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(order_item, i) in orderList" :key="i">
                                                        <td>
                                                            <span
                                                                v-if="(action == 'Add' || orderList[i].item_id == '' || orderList[i].is_editable) && !orderList[i].is_bundle">
                                                                <v-autocomplete outlined dense
                                                                    :items="filtered_items_list"
                                                                    v-model="orderList[i].item_id"
                                                                    item-value="value"
                                                                    item-text="text" placeholder="Select Model"
                                                                    class="d-inline-block small"
                                                                    @change="selectModel(i, orderList[i].item_id, true)">
                                                                </v-autocomplete>
                                                            </span>
                                                            <span v-else>
                                                                <input type="text"
                                                                    class="form-control text-center small"
                                                                    v-model="orderList[i].item.model" readonly>
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span
                                                                v-if="(action == 'Add' || orderList[i].item_id == '' || orderList[i].is_editable) && !orderList[i].is_bundle">
                                                                <v-autocomplete outlined dense
                                                                    :items="filtered_items_list"
                                                                    v-model="orderList[i].item_id" item-value="value"
                                                                    item-text="name" placeholder="Select Name"
                                                                    class="d-inline-block small"
                                                                    @input="selectModel(i, orderList[i].item_id, true)">
                                                                </v-autocomplete>
                                                            </span>
                                                            <span v-else>
                                                                <input type="text"
                                                                    class="form-control text-center small"
                                                                    v-model="orderList[i].item.name" readonly>
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <input type="text" class="form-control text-center small"
                                                                v-model="orderList[i].item.moq" readonly>
                                                        </td>
                                                        <td>
                                                            <input type="text" class="form-control text-center"
                                                                v-model="orderList[i].quantity" @keypress="numberOnly"
                                                                @input="changeQuantity(i)" @blur="checkMoq(i)"
                                                                :disabled="orderList[i].is_bundle">
                                                        </td>
                                                        <td v-if="stock_card == 1">
                                                            <input type="text" class="form-control text-center"
                                                                v-model="orderList[i].sc_quantity"
                                                                @keypress="numberOnly">
                                                        </td>
                                                        <td>
                                                            <input type="text" class="form-control text-center small"
                                                                v-model="orderList[i].item.item_uom_text" readonly>
                                                        </td>
                                                        <td>
                                                            <input type="text" class="form-control text-right small"
                                                                v-model="orderList[i].item.display_srp" readonly
                                                                v-if="!orderList[i].foc">
                                                            <input type="text" class="form-control text-right small"
                                                                v-model="foc_price" readonly v-else>
                                                        </td>
                                                        <td
                                                            v-if="orderList[i].override_amount == null || orderList[i].override_amount == 0">
                                                            <input type="text" class="form-control text-right small"
                                                                v-model="orderList[i].display_disc_price" readonly
                                                                @click="showOverrideAmountDialog(i)"
                                                                v-if="!orderList[i].foc">
                                                            <input type="text" class="form-control text-right small"
                                                                v-model="foc_price" readonly v-else>
                                                        </td>
                                                        <td v-else>
                                                            <input type="text"
                                                                class="form-control text-right small bg-ingco"
                                                                v-model="orderList[i].display_override_amount" readonly
                                                                @click="showOverrideAmountDialog(i)">
                                                        </td>
                                                        <td>
                                                            <v-checkbox v-model="orderList[i].foc" label=""
                                                                color="primary" input-value="1"
                                                                class="align-center justify-center"
                                                                @change="changeFoc(i)"></v-checkbox>
                                                        </td>
                                                        <td>
                                                            <input type="text" class="form-control text-right small"
                                                                v-model="orderList[i].display_total_amount" readonly>
                                                        </td>
                                                        <td>
                                                            <input type="text" class="form-control text-center small"
                                                                v-model="orderList[i].type" readonly>
                                                        </td>
                                                        <td>
                                                            <v-btn fab dark small color="primary"
                                                                style="height:20px; width:20px;"
                                                                @click="removeOrderByIndex(i)">
                                                                <v-icon dark small style="font-size:12px">
                                                                    mdi-minus
                                                                </v-icon>
                                                            </v-btn>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </div>
                                </div>
                            </v-card>

                            <v-row class="m-0">
                                <v-container fluid>
                                    <v-row>
                                        <div class="col-sm-12 col-md-8 text-left">
                                            <div class="row ml-2">

                                            </div>
                                            <div class="row ml-2">

                                            </div>

                                        </div>
                                        <div class="col-sm-12 col-md-4 pull-right text-right">
                                            <h5>Total Amount: &#x20b1;{{ this.sales_total_amount | currency }}</h5>
                                        </div>
                                    </v-row>
                                </v-container>
                            </v-row>

                            <div class="col-sm-12 col-md-12">
                                <table class="table-bordered" style="font-size: 16px">
                                    <tr v-for="(e, i) in batch_addition.errors" :key="i">
                                        <th class="px-3">{{ e.title }}</th>
                                        <td class="px-4" style="color: red">{{ e.data }}</td>
                                    </tr>
                                </table>
                            </div>

                            <div class="border-top">
                                <div class="card-body py-4 d-flex justify-content-center">
                                    <v-btn v-on:click="addNewSalesOrder()" v-if="action == 'Add'">Save</v-btn>
                                    <v-btn v-on:click="editSalesOrder()" v-else-if="action == 'Edit'">Update</v-btn>
                                </div>
                            </div>
                        </form>
                    </v-card-text>
                    <v-card-text v-else-if="loaded == false">
                        <div class="text-center">
                            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                        </div>
                    </v-card-text>

                    <v-container fluid style="display:none;">
                        <div>
                            <table id="items_details">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>QTY</th>
                                        <th>UNITS</th>
                                        <th>MODEL</th>
                                        <th>DESCRIPTION</th>
                                        <th>UNIT PRICE</th>
                                        <th>TOTAL</th>
                                    </tr>
                                </thead>
                                <tbody id='tr_items'>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>***** NOTHING FOLLOWS *****</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </v-container>

                </v-card>
            </v-dialog>

            <v-dialog v-model="override_amount_dialog" persistent max-width="30%" scrollable>
                <v-card>
                    <v-row class="m-0">
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right" @click="override_amount_dialog = false;">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card-text>
                        <div class="row">
                            <div class="col-sm-12 col-md-6 m-auto text-center">
                                <label for="override_amount" class="control-label col-form-label">Override
                                    Amount</label>
                                <input type="text" class="form-control" id="override_amount" placeholder="0.00"
                                    v-model="override_amount" @keypress="decimalOnly" autocomplete="off">
                                <span v-for="errors in errors.override_amount" class="text-warning"
                                    v-bind:key="errors">{{ errors }}</span>
                            </div>
                        </div>
                        <div class="border-top">
                            <div class="card-body py-4 d-flex justify-content-center">
                                <v-btn v-on:click="confirmOverrideAmount();">Confirm</v-btn>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-dialog v-model="approve_override_dialog" persistent max-width="30%" scrollable>
                <v-card>
                    <v-row class="m-0">
                        <v-card-title>
                            <span class="headline">Confirm Override Amounts</span>
                        </v-card-title>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right"
                                @click="approve_override_dialog = false; override_username = ''; override_user_password = ''; override_user_id = -1;">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card-text>
                        <div class="row">
                            <div class="col-sm-12 col-md-12">
                                <label for="override_user_password"
                                    class="float-left control-label col-form-label">Password</label>
                                <input type="password" class="form-control" id="override_user_password"
                                    placeholder="Password" v-model="override_user_password" autocomplete="new-password">
                                <span v-for="errors in errors.override_user_password" class="text-warning"
                                    v-bind:key="errors">{{ errors }}</span>
                            </div>
                        </div>
                        <div class="border-top">
                            <div class="card-body py-4 d-flex justify-content-center">
                                <v-btn
                                    v-on:click="approveOverrideAmounts(); departments_allowed = ['SM', 'AC', 'EX', 'IT']">Confirm</v-btn>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-dialog v-model="approve_override_order_dialog" persistent max-width="30%" scrollable>
                <v-card>
                    <v-row class="m-0">
                        <v-card-title>
                            <span class="headline">Confirm Override Edit Sales Order</span>
                        </v-card-title>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right"
                                @click="approve_override_order_dialog = false; override_order_username = ''; override_order_user_password = ''; override_order_user_id = -1;">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card-text>
                        <div class="row">
                            <div class="col-sm-12 col-md-12">
                                <label for="override_order_user_password"
                                    class="float-left control-label col-form-label">Password</label>
                                <input type="password" class="form-control" id="override_order_user_password"
                                    placeholder="Password" v-model="override_order_user_password"
                                    autocomplete="new-password">
                                <span v-for="errors in errors.override_order_user_password" class="text-warning"
                                    v-bind:key="errors">{{ errors }}</span>
                            </div>
                        </div>
                        <div class="border-top">
                            <div class="card-body py-4 d-flex justify-content-center">
                                <v-btn v-on:click="approveOverrideEditOrder()">Confirm</v-btn>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-dialog v-model="batch_addition.dialog" persistent max-width="30%" scrollable>
                <v-card>
                    <v-row class="m-0">
                        <v-card-title>
                            <span class="headline">Copy Model and Qty From Excel</span>
                        </v-card-title>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right"
                                @click="closeBatchAdditionDialog(); dataFromExcel = ''">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card-text>
                        <v-row>
                            <v-textarea v-model="dataFromExcel" rows="1" small color="primary" label="Paste From Excel"
                                clearable :autofocus="batch_addition.dialog" @paste="onPaste"
                                @click="$event.target.focus()" @focus="dataFromExcel = null"></v-textarea>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-app>

    </div>
</template>

<script>
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue'
import SalesFunctionsComponent from '@/views/main/Shared/SalesFunctionsComponent.vue'
import SalesOrdersTableComponent from './SalesOrdersTableComponent.vue'
import swal from 'sweetalert2';
import { mapGetters } from "vuex";
export default {
    mixins: [
        SharedFunctionsComponent,
        SalesFunctionsComponent
    ],
    components: {
        SalesOrdersTableComponent,
    },
    props: [
        'status_text',
        'service',
        'print'
    ],
    data() {
        return {
            batch_addition: {
                dialog: false,
                data: [],
                excel_item_id: null,
                errors: []
            },
            dataFromExcel: '',
            fromShowEditModal: false,
            stock_card: 0,
            promo_name: '',
            promo_id: null,
            promo: {
                id: '',
                name: '',
                code: '',
            },
            customer_promo: {
                discount_rate: '',
                cash_price: ''
            },
            override_username: '',
            override_user_password: '',
            override_user_id: -1,
            override_amount: '',
            override_index: '',
            override_order_username: '',
            override_order_user_password: '',
            override_order_user_id: -1,
            editSalesOrderId: '',
            add_edit_salesorder_dialog: false,
            override_amount_dialog: false,
            approve_override_dialog: false,
            approve_override_order_dialog: false,
            quote_num: '',
            order_num: '',
            quote_date: '',
            customer_id: '',
            salesman_id: '',
            user_id: '',
            remarks: '',
            discounts: '',
            terms: '',
            orig_terms: '',
            convert_date: '',
            status: '',
            created_at: '',
            issuer_name: '',
            foc_price: '0.00',
            delivery_method: '',
            new_so_ids: null,

            tempOrderList: [],
            orderList: [],
            order: {
                sc_quantity: '',
                quantity: '',
                item_id: '',
                foc: '',
                disc_price: '',
                total_amount: '',
                amount: '',
                type: '',
                item: {
                    id: '',
                    model: '',
                    name: '',
                    description: '',
                    type_id: '',
                    category_id: '',
                    uom_id: '',
                    item_uom_text: '',
                    barcode: '',
                    selling_price: '',
                    sp_price: '',
                    srp: '',
                    override_amount: '',
                    moq: '',
                    brand_id: '',
                },
                is_bundle: false,
                promos: [],
                selected_promo: '',
                promo_items_id: '',
                promo_amount: '',
                parent_so_item_id: null,
            },
            customer: {
                shipping_address: 0,
                billing_address: 0,
                salesman_name: '',
                remarks: '',
                terms: '',
                discounts: [],
                is_sp: '',
                is_vip: '',
            },
            discount: {
                pt: '',
                ht: '',
                sp: '',
            },
            itemTypeDiscounts: [],
            max_discounts_count: 999,
            max_order_items_count: 999,
            shipping_address_list: [],
            customers_list: [],
            users_list: [],
            salesman_list: [],
            items_list: [],
            type_list: [],
            promo_list: [],
            shortlist_item_ids: [],
            shortlist_customer_ids: [],
            items_not_in_promo: [],
            delivery_method_list: [],
            errors: [],
            action: '',
            actionResult: {
                model: 'Sales Order',
                action: ''
            },
            loaded: false,
            userAccess: {
                create: false,
            },
            customer_po_num: '',
            orderListLength: 0,
            counter: 0,
            departments_allowed: [],
            filter_data:{},
            exportByStatus: [
                {text: 'Raw Data with Stocks', value: 0},
                {text: 'Raw Data without Stocks', value: 1},
                {text: 'Summary', value: 2},
            ],
            columns: [],
        }
    },
    methods: {
        disableBatchAddition() {
            if (this.customer_id != '' && (this.promo_id == null || this.promo_id == '')) {
                return false
            } else {
                return true
            }
        },
        closeBatchAdditionDialog() {
            this.batch_addition.data = []
            this.batch_addition.dialog = false
        },
        onPaste(evt) {
            this.batch_addition.data = []
            var pastedText = evt.clipboardData.getData('Text')
            var rows = pastedText.split("\r\n")
            for (var y in rows) {
                if (y != rows.length - 1) {
                    var cells = rows[y].split("\t");
                    this.batch_addition.data.push(Object.assign({}, {
                        model: cells[0],
                        qty: cells[1],
                        // foc: cells[2],
                        id: '',
                        skip: false,
                    }))
                }
            }
            this.getItemId()
            this.closeBatchAdditionDialog()
        },
        approveOverrideEditOrder() {
            // if(this.override_order_username == '' || this.override_order_user_password == ''){
            if (this.override_order_user_password == '') {
                this.override_order_user_id = -1
                this.approve_override_order_dialog = false
            } else {
                axios.post('/confirmOverrideOrder', {
                    departments_allowed: ['SM', 'AC', 'EX', 'IT'],
                    override_user_password: this.override_order_user_password,
                }).then(response => {
                    let data = response.data

                    // this.override_order_username = ''
                    this.override_order_user_password = ''

                    if (data.error) {
                        this.override_order_user_id = -1
                        swal.fire("", data.error, "warning");
                        return false
                    }

                    this.override_order_user_id = data.user_id //get user id after entering username password
                    this.approve_override_order_dialog = false
                    this.proceedUpdateOrder()
                }).catch(error => {
                    this.override_order_user_id = -1
                    if (error.response.status == 422) {
                        this.errors = error.response.data.errors
                    }
                });
            }
        },
        showAddSalesOrder() {
            this.add_edit_salesorder_dialog = true
            this.action = 'Add'
        },
        addNewSalesOrder() {
            if (this.checkItemsZeroSRP()) {
                this.override_user_id == -1
                this.showApproveOverrideAmountsDialog()
            } else {
                return false
            }
        },
        checkOrderTableHeight() {
            /*var limit = 155;
            var shipping_address_text = $('#shipping_address_text').parent().text();
            if(shipping_address_text != null && shipping_address_text != ''){
                if(shipping_address_text.length > 60){
                    limit = 145;
                }
            }*/

            /*var tableHeight =  this.computeOrderTableHeight()
            if(tableHeight > limit){
                swal.fire("", "This will render more than 1 page. \n Please delete item(s) to proceed", "warning");
            }else{
                if(this.action == 'Add'){
                    this.proceedSaveOrder()
                }else if(this.action == 'Edit'){
                    this.proceedUpdateOrder()
                }

            }*/
            //this.computeOrderTableHeight()

            if (this.action == 'Add') {
                this.proceedSaveOrder()
            } else if (this.action == 'Edit') {
                this.proceedUpdateOrder()
            }
        },
        proceedSaveOrder() {
            this.errors = []
            var shipping_address_text = $('#shipping_address_text').parent().text();
            this.checkOrderTableHeightAddItem()

            axios.post('/salesOrders', {
                customer_id: this.customer_id,
                salesman_id: this.salesman_id,
                remarks: this.remarks,
                orderList: this.orderList,
                shipping_address_index: this.customer.shipping_address,
                shipping_address_text: shipping_address_text,
                discounts: this.customer.discounts,
                terms: this.terms,
                override_by: this.override_user_id,
                promo_id: this.promo_id,
                customer_po_num: this.customer_po_num,
                delivery_method: this.delivery_method,
                stock_card: this.stock_card,
            }).then(response => {
                let data = response.data
                if (data.error) {
                    swal.fire("", data.error, "warning");
                    return false
                }

                this.resetFields()
                this.add_edit_salesorder_dialog = false
                this.action = 'success_' //use this to avoid double getAllSalesOrders
                swal.fire("", response.data.msg, "success").then((value) => {
                    location.reload();
                });

            }).catch(error => {
                if (error.response != null && error.response.status == 422) {
                    this.errors = error.response.data.errors
                }
            });
        },
        async showEditModal(value) {
            this.fromShowEditModal = true
            this.action = 'Edit'
            this.editSalesOrderId = value
            this.getCustomers(true)
            this.getUsers()
            this.getSalesmanList()
            this.getItems()
            this.getItemsNotInPromo()
            this.getItemTypes()
            this.getPromos()
            this.getCustomerPromoInfo()
            // this.getCustomersNotInPromo()
            this.delivery_method_list = [
                { 'value': 0, 'text': 'none' },
                { 'value': 1, 'text': 'pick-up' },
                { 'value': 2, 'text': 'delivery' },
            ]

            if (this.editSalesOrderId != null && this.editSalesOrderId > 0) {
                let payload = {
                        selected_id: this.editSalesOrderId,
                        url: `viewSalesOrder/${this.editSalesOrderId}`,
                    };
                await this.$store.dispatch("salesOrdersGet", payload).then(response => {
                    let data = response.data

                    if (data.error) {
                        swal.fire("", data.error, "warning");
                        return false
                    }

                    let sales_order = data.sales_order
                    let orderList = data.sales_order.sales_order_items

                    this.created_date = new Date(Date.parse(new Date(sales_order.created_at).toLocaleString('en-US', { timeZone: 'Asia/Manila' })))

                    this.customer.is_sp = sales_order.special

                    this.customer_promo = data.sales_order.customer_promo

                    if (sales_order.discounts != '') {
                        this.customer.discounts = JSON.parse(sales_order.discounts)
                    }

                    if (orderList != null && orderList.length > 0) {
                        orderList.forEach((order, index) => {

                            this.addOrderItem()

                            this.orderList[index].id = order.id
                            this.orderList[index].quantity = order.quantity
                            this.orderList[index].sc_quantity = order.sc_quantity

                            this.orderList[index].item.srp = order.srp
                            this.orderList[index].disc_price = order.disc_price

                            this.orderList[index].item.sp_price = order.item.sp_price
                            this.orderList[index].item.selling_price = order.item.selling_price

                            this.orderList[index].foc = order.foc

                            this.orderList[index].promos = order.promos

                            this.orderList[index].orig_sp_price = order.item.sp_price
                            this.orderList[index].orig_selling_price = order.item.selling_price

                            //this.changeFoc(index)

                            this.orderList[index].is_bundle = order.is_bundle
                            this.orderList[index].backend_promo = order.backend_promo

                            this.selectModel(index, order.item_id, false, order)
                            this.orderList[index].item_id = order.item_id

                            this.orderList[index].override_amount = order.override_amount
                            this.orderList[index].is_editable = true
                        })
                    }

                    this.order_num = sales_order.order_num
                    this.customer_id = sales_order.customer_id
                    this.salesman_id = sales_order.salesman_id
                    this.customer.shipping_address = sales_order.shipping_address_id
                    this.created_at = sales_order.created_at
                    this.issuer_name = sales_order.issuer_name
                    this.remarks = sales_order.remarks
                    this.override_user_id = sales_order.override_by
                    this.promo_id = sales_order.promo_id
                    this.promo_name = sales_order.promo_name
                    this.customer_po_num = sales_order.customer_po_num
                    this.delivery_method = sales_order.delivery_method
                    this.stock_card = sales_order.stock_card
                    this.status = sales_order.status
                    this.new_so_ids = sales_order.new_so_ids

                    this.add_edit_salesorder_dialog = true
                }).catch(error => {

                });
            }
        },
        editSalesOrder() {
            if (this.checkItemsZeroSRP()) {
                this.override_user_id == -1
                this.showApproveOverrideAmountsDialog()
            } else {
                return false
            }
        },
        proceedUpdateOrder() {
            this.errors = []
            var shipping_address_text = $('#shipping_address_text').parent().text();
            this.checkOrderTableHeightAddItem()

            //check if status is already approved
            if (this.status == 3 && this.override_order_user_id == -1) {
                this.approve_override_order_dialog = true
            } else {
                axios.put('/salesOrders/' + this.editSalesOrderId, {
                    customer_id: this.customer_id,
                    salesman_id: this.salesman_id,
                    remarks: this.remarks,
                    orderList: this.orderList,
                    shipping_address_index: this.customer.shipping_address,
                    shipping_address_text: shipping_address_text,
                    discounts: this.customer.discounts,
                    terms: this.terms,
                    override_by: this.override_user_id,
                    promo_id: this.promo_id,
                    customer_po_num: this.customer_po_num,
                    delivery_method: this.delivery_method,
                    stock_card: this.stock_card,
                    new_so_ids: this.new_so_ids,
                }).then(response => {
                    let data = response.data
                    if (data.error) {
                        swal.fire("", data.error, "warning");
                        return false
                    }

                    this.resetFields()
                    this.add_edit_salesorder_dialog = false
                    this.editSalesOrderId = 0
                    this.action = 'success_' //use this to avoid double getAllSalesOrders
                    swal.fire("", response.data.msg, "success").then((value) => {
                        location.reload();
                    });

                }).catch(error => {
                    if (error.response.status == 422) {
                        this.errors = error.response.data.errors
                    }
                });
            }


        },
        resetFields() {
            this.errors = []
            this.quote_num = ''
            this.order = ''
            this.quote_date = ''
            this.customer_id = ''
            this.salesman_id = ''
            this.user_id = ''
            this.remarks = ''
            this.customer.discounts = []
            this.discounts = ''
            this.terms = ''
            this.convert_date = ''
            this.status = ''
            this.customer_id = ''
            this.shipping_address_list = []
            this.orderList = []
            this.itemTypeDiscounts = []
            this.type_list = []
            this.override_user_id = -1
            this.override_amount = ''
            this.override_index = ''
            this.override_username = ''
            this.override_user_password = ''
            this.promo_list = []
            this.promo_id = null
            this.shortlist_item_ids = []
            this.shortlist_customer_ids = []
            this.promo = ''
            this.customer_promo = ''
            this.items_not_in_promo = []
            this.customer_po_num = ''
            this.stock_card = 0
            this.delivery_method = ''
            this.delivery_method_list = []
            this.tempOrderList = []
            this.override_order_username = ''
            this.override_order_user_password = ''
            this.override_order_user_id = -1
            this.batch_addition.errors = []
            this.new_so_ids = null
        },
        addKeyListener() {
            window.addEventListener("keyup", function (e) {
                var keypressed = String.fromCharCode(e.keyCode)
                if (e.ctrlKey && e.keyCode == 32) {
                    this.addOrderItem()
                    $("input").blur();

                } else if (keypressed == '-') {
                    this.removeOrderItem()
                    $("input").blur();
                }

            }.bind(this));
        },
        filters(value){
            this.filter_data = value;
        },
        async exportSalesOrder(items, status_text){
            if(items.value == 0 || items.value == 1) {
                this.columns = [
                    { header: 'SO#', key: 'order_num', style: { numFmt: '@' } },
                    { header: 'ITEM MODEL', key: 'item_model' },
                    { header: 'ITEM NAME', key: 'item_name' },
                    // { header: 'ITEM TYPE', key: 'item_type' },
                    // { header: 'UOM', key: 'item_uom_text' },
                    { header: 'CUSTOMER', key: 'company_name' },
                    // { header: 'SALESMAN', key: 'salesman_name'},
                    { header: 'CREATED BY', key: 'created_by' },
                    { header: 'FOC', key: 'foc' },
                    { header: 'REMARKS', key: 'remarks' },
                    { header: 'STATUS', key: 'status_text' },
                    { header: 'SRP', key: 'display_srp', style: { numFmt: '#,##0.00' } },
                    // { header: 'DISC PRICE', key: 'display_disc_price', style: { numFmt: '#,##0.00' } },
                    { header: 'PRICE', key: 'display_final_price', style: { numFmt: '#,##0.00' } },
                    { header: 'QTY', key: 'quantity', style: { numFmt: '#,##0' } },
                    { header: 'SERVED QTY', key: 'quantity_served', style: { numFmt: '#,##0' } },
                    { header: 'UNSERVED QTY', key: 'unserved_qty', style: { numFmt: '#,##0' } },
                    { header: 'CANCELLED QTY', key: 'quantity_canceled', style: { numFmt: '#,##0' } },
                    { header: 'PREPARED QTY', key: 'prepared_quantity', style: { numFmt: '#,##0' } },
                    // { header: 'TOTAL', key: 'display_total_amount' },
                    { header: 'DELIVERY DATE', key: 'delivery_date' },
                    // { header: 'DATE ISSUED', key: 'sales_order_date' },
                    // { header: 'DATE APPROVED(SALES)', key: 'approve_date' },
                    // { header: 'DATE APPROVED(ACCT)', key: 'acc_approved_date' },
                    // { header: 'ISSUER', key: 'issuer_name' },
                    // { header: 'PROMO', key: 'promo_id' },
                    // { header: 'SC', key: 'stock_card' },
                ]
            } else if (items.value == 2) {
                this.columns = [
                    { header: 'SO#', key: 'order_num', style: { numFmt: '@' } },
                    { header: 'SQ#', key: 'quote_num', style: { numFmt: '@' } },
                    { header: 'CUSTOMER', key: 'company_name' },
                    { header: 'PO#', key: 'customer_po_num' },
                    { header: 'REMARKS', key: 'remarks' },
                    { header: 'SALESMAN', key: 'salesman_name' },
                    { header: 'AMOUNT', key: 'display_total_amount', style: { numFmt: '#,##0.00' } },
                    { header: 'STATUS', key: 'status_text' },
                    { header: 'DATE ISSUED', key: 'sales_order_date', style: { numFmt: 'm/d/yyyy' } },
                    { header: 'DATE APPROVED (ACCTG)', key: 'acc_approved_date', style: { numFmt: 'm/d/yyyy' } },
                    { header: 'ISSUER', key: 'created_by' },
                    { header: 'PROMO', key: 'promo_id' },
                    { header: 'SC', key: 'stock_card' },
                ]
            }
            await this.$store.dispatch('getWarehousesRequest', this.USERACCOUNT_LOAD.id).then(async res => {
                if (items.value == 0) {
                    if (res.data.data) {
                        res.data.data.forEach(x => {
                            this.columns.push({ header: x.code, key: x.code, style: { numFmt: "#,##0" } })
                        })
                    }
                }

                this.filter_data.url = "getAll";
                this.filter_data.export = 1;
                this.filter_data.status_text = status_text;
                this.filter_data.export_status = items.value;

                let excelData = [];
                const workbook = new this.$exceljs.Workbook();
                const worksheet = workbook.addWorksheet('Sales Order')

                swal.fire('Exporting Data');
                swal.showLoading();

                await this.$store.dispatch("salesOrdersGet", this.filter_data).then((response) => {
                    excelData = response.data
                    worksheet.columns = this.columns

                    excelData.forEach((e, i) => {
                        if (items.value == 2) {
                            e.display_total_amount = parseFloat(e.display_total_amount)
                        } else {
                            e.foc = e.foc != 0 ? 'YES' : "NO";
                            e.delivery_date = e.delivery_date ? this.$dayjs(e.delivery_date).format('MM/DD/YYYY') : 'No Delivery Date'
                            e.display_srp = parseFloat(e.display_srp)
                            e.display_disc_price = parseFloat(e.display_disc_price)
                            e.display_final_price = parseFloat(e.display_final_price)
                        }

                        worksheet.addRow(e);
                    })

                }).catch((e) => {
                    swal.fire('Error !', '', 'error')
                    console.log(e)
                });
                workbook.xlsx.writeBuffer().then((data) => {
                    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                    this.$filesaver.saveAs(blob, `SalesOrder.xlsx`);
                    swal.close();
                });
            });
        },



    },
    mounted: function () {
        this.addKeyListener()
        $('input').attr('autocomplete', 'off');
        this.$store.dispatch("credentials");
    },
    watch: {
        add_edit_salesorder_dialog: function () {
            if (this.add_edit_salesorder_dialog == true) {
                this.loaded = false
                if (this.action == 'Add') {
                    this.orderList = []
                    this.getCustomers(true)
                    this.getUsers()
                    this.getSalesmanList()
                    this.getItems()
                    this.getItemsNotInPromo()
                    this.getItemTypes()
                    this.getPromos()
                    // this.getCustomersNotInPromo()
                    this.delivery_method_list = [
                        { 'value': 0, 'text': 'none' },
                        { 'value': 1, 'text': 'pick-up' },
                        { 'value': 2, 'text': 'delivery' },
                    ]
                    this.created_date = new Date(Date.parse(new Date().toLocaleString('en-US', 'Asia/Manila')))
                    // this.created_date = new Date()
                }

                /*if(this.customer.discounts.length == 0){
                    this.addDiscount();
                }*/

                if (this.orderList.length == 0) {
                    this.addOrderItem();
                }

                //this.loaded = true
            }
        },
        customer_id: function (value) {
            if (this.add_edit_salesorder_dialog == true) {
                this.loaded = false
                if (value > 0) {
                    this.$store.dispatch('showCustomers', value).then(response => {
                        let data = response.data

                        this.shipping_address_list = data.all_shipping_addresses
                        this.salesman_id = data.salesman_id
                        this.terms = data.terms
                        this.customer.is_sp = data.special
                        this.orig_terms = data.terms

                        if (data.discounts != null && data.discounts != '') {
                            this.customer.discounts = JSON.parse(data.discounts)
                        }

                        if (this.fromShowEditModal == false && this.orderList != null && this.orderList.length > 0) {
                            this.orderList.forEach((order, index) => {
                                this.selectModel(index, order.item_id, false)
                            })
                        } else {
                            this.fromShowEditModal = false
                        }

                        if (this.action == 'Add') {
                            this.orderList = []
                            this.addOrderItem()
                            this.getCustomerPromoInfo()
                        }

                        this.loaded = true
                    }).catch(error => {

                    });
                } else {
                    this.customer = {
                        shipping_address: 0,
                        billing_address: 0,
                        salesman_name: '',
                        remarks: '',
                        terms: '',
                        discounts: [],
                        is_sp: '',
                        is_vip: '',
                    }
                    this.loaded = true
                }
            }
        },
        promo_id: function (value) {
            if (value != null && value > 0) {
                this.loaded = false
                axios.get('/getPromoShortlists/' + value, {

                }).then(response => {
                    let data = response.data

                    this.shortlist_item_ids = data.item_ids
                    this.shortlist_customer_ids = data.customer_ids

                    if (this.action == 'Add') {
                        this.orderList = []
                        this.addOrderItem()
                        this.customer_id = ''
                    }

                    this.loaded = true

                }).catch(error => {

                });
            }
        },
        orderList: {
            deep: true,
            handler() {
                if (this.orderList.length > 0) {
                    this.orderList.forEach((order, index) => {
                        if (order.item.srp != null && order.item.srp != '') {
                            order.item.display_srp = this.getTwoDecimals(order.item.srp)
                        } else (
                            order.item.display_srp = ''
                        )

                        if (order.disc_price != null && order.disc_price != '') {
                            order.display_disc_price = this.getTwoDecimals(order.disc_price)
                        } else (
                            order.display_disc_price = ''
                        )

                        if (order.total_amount != null && order.total_amount != '') {
                            order.display_total_amount = this.getTwoDecimals(order.total_amount)
                        } else (
                            order.display_total_amount = ''
                        )

                        if (order.override_amount != null && order.override_amount != '') {
                            order.display_override_amount = this.getTwoDecimals(order.override_amount)
                        } else (
                            order.display_override_amount = ''
                        )

                    })
                }
                this.$forceUpdate()
            }
        },
    },
    computed:{
        ...mapGetters(["USERACCOUNT_LOAD"]),

    }

};
</script>
