<template>
    <v-container fluid style="display:none">
        <div id="ctrl_num_label" style="font-weight:bold">
            Control No.:
        </div>
        <div id="ctrl_num_value" style="font-weight:bold">
            {{ 'CM# ' + credit_memo.credit_memo_num }}
        </div>

        <div>
            <table id="headers">
                <tbody>
                    <tr>
                        <td>SOA Month:</td>
                        <td>{{ credit_memo.soa_month_year }}</td>
                        <td colspan="2" style="font-weight:bold">Date Prepared</td>
                        <td colspan="2">{{ credit_memo.credit_memo_date_long }}</td>
                    </tr>
                    <tr>
                        <td style="font-weight:bold">Customer:</td>
                        <td>{{ credit_memo.customer_name }}</td>
                        <td colspan="2" style="font-weight:bold">Ref PLs:</td>
                        <td colspan="2">{{ credit_memo.packing_list_nums }}</td>
                    </tr>
                    <tr>
                        <td style="font-weight:bold">Address:</td>
                        <td>{{ credit_memo.billing_address_text }}</td>
                        <td colspan="2" style="font-weight:bold">Dealers Ref No:</td>
                        <td colspan="2">{{ credit_memo.customer_ref_no }}</td>
                    </tr>
                    <tr>
                        <td style="font-weight:bold">Contact Person:</td>
                        <td>{{ credit_memo.billing_contact_person }}</td>
                        <td colspan="2" style="font-weight:bold">Contact Number:</td>
                        <td colspan="2">{{ credit_memo.billing_contact_number }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div>
            <table id="items">
                <thead>
                    <tr>
                        <td>ITEM CODE</td>
                        <td>DESCRIPTION</td>
                        <td>PARTICULARS</td>
                        <td>QTY</td>
                        <td>UOM</td>
                        <td>UNIT COST</td>
                        <td>ITEM TOTAL</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in credit_memo_items" :key="index">
                        <td>{{ item.item_model }}</td>
                        <td>{{ item.item_name }}</td>
                        <td>{{ item.remarks }}</td>
                        <td>{{ item.quantity_cm }}</td>
                        <td>{{ item.item_uom }}</td>
                        <td v-if="item.item_id != null">{{ item.item_amount | currency}}</td>
                        <td v-else>{{ }}</td>
                        <td v-if="item.item_id != null">{{ item.total_amount | currency}}</td>
                        <td v-else>{{ }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div>
            <table id="totals">
                <tbody>
                    <tr>
                        <td style="font-weight:bold">Remarks</td>
                        <td>{{ credit_memo.remarks }}<br></td>
                        <td colspan="2" style="font-weight:bold">AMOUNT FOR CM</td>
                        <td colspan="2">{{ credit_memo.total_amount | currency }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div>
            <table id="signatories">
                <tbody>
                    <tr>
                        <td>Processed by:</td>
                        <td><br><br>{{ credit_memo.created_by_name }}</td>
                        <td colspan="2">Checked by:</td>
                        <td colspan="2"><br><br>{{ credit_memo.acct_approved_by_name }}</td>
                    </tr>
                    <tr>
                        <td>Approved by:</td>
                        <td><br><br>{{ credit_memo.exec_approved_by_name }}</td>
                        <td colspan="2">Received by:</td>
                        <td colspan="2"><br><br>{{ }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div id="footer_1" style="font-size:10px">
            <span style="margin-right:16px"><i>WHITE - ACCTG COPY</i></span>
        </div>

        <div id="footer_2" style="font-size:10px">
            <span><i>PINK - CUSTOMER COPY</i></span>
        </div>
    </v-container>
</template>

<script>
export default {
    props: [
        'credit_memo',
        'credit_memo_items',
    ],
    data() {
        return {

        }
    },
    mounted(){
        this.print()
    },
    methods: {
        print() {
            let doc = new this.$jspdf('p', 'mm', 'letter');

            let ingcoPhLogo = new Image();
            ingcoPhLogo.src = '/images/ingcoph-logo.png';
            doc.addImage(ingcoPhLogo, 'png', 4, 3, 40, 15);

            let creditMemoLogo = new Image();
            creditMemoLogo.src = '/images/credit-memo-logo.png';
            doc.addImage(creditMemoLogo, 'png', 118, 6, 85, 8);

            doc.setFont(undefined, 'bold').setFontSize(12).text(132,21,"Control No: CM# "+this.credit_memo.credit_memo_num).setFont(undefined, 'normal')

            doc.autoTable({
                html: '#headers',
                theme: 'grid',
                margin: {top: 25, left: 4, right: 11},
                columnStyles: {
                    0: {cellWidth: 30, halign:'left', textColor: '#000000', fontSize: 8},
                    1: {cellWidth: 69, halign:'left', textColor: '#000000', fontSize: 8},
                    2: {cellWidth: 15, halign:'left', textColor: '#000000', fontSize: 8},
                    3: {cellWidth: 15, halign:'left', textColor: '#000000', fontSize: 8},
                    4: {cellWidth: 31, halign:'left', textColor: '#000000', fontSize: 8},
                    5: {cellWidth: 41, halign:'left', textColor: '#000000', fontSize: 8},
                },
                bodyStyles: {
                    lineColor: '#000000',
                }
            });

            doc.autoTable({
                html: '#items',
                theme: 'grid',
                margin: {left: 4, right: 11},
                startY: doc.autoTable.previous.finalY,
                headStyles: {
                    halign:'center',
                    fillColor: '#ffffff',
                    textColor: '#000000',
                    lineColor: '#000000',
                    lineWidth: .1,
                    fontSize: 8,
                },
                columnStyles: {
                    0: {cellWidth: 30, halign:'center', textColor: '#000000', fontSize: 8},
                    1: {cellWidth: 69, halign:'center', textColor: '#000000', fontSize: 8},
                    2: {cellWidth: 30, halign:'center', textColor: '#000000', fontSize: 8},
                    3: {cellWidth: 15, halign:'center', textColor: '#000000', fontSize: 8},
                    4: {cellWidth: 15, halign:'center', textColor: '#000000', fontSize: 8},
                    5: {cellWidth: 21, halign:'right', textColor: '#000000', fontSize: 8},
                    6: {cellWidth: 21, halign:'right', textColor: '#000000', fontSize: 8},
                },
                bodyStyles: {
                    lineColor: '#000000',
                }
            });

            doc.autoTable({
                html: '#totals',
                theme: 'grid',
                margin: {left: 4, right: 11},
                startY: doc.autoTable.previous.finalY,
                columnStyles: {
                    0: {cellWidth: 30, halign:'left', textColor: '#000000', fontSize: 8},
                    1: {cellWidth: 69, halign:'left', textColor: '#000000', fontSize: 8},
                    2: {cellWidth: 15, halign:'left', textColor: '#000000', fontSize: 8},
                    3: {cellWidth: 15, halign:'left', textColor: '#000000', fontSize: 8},
                    4: {cellWidth: 31, halign:'right', textColor: '#000000', fontSize: 8},
                    5: {cellWidth: 41, halign:'right', textColor: '#000000', fontSize: 8},
                },
                bodyStyles: {
                    lineColor: '#000000',
                }
            });

            doc.autoTable({
                html: '#signatories',
                theme: 'grid',
                margin: {left: 4, right: 11},
                startY: doc.autoTable.previous.finalY,
                columnStyles: {
                    0: {cellWidth: 30, halign:'left', textColor: '#000000', fontSize: 8},
                    1: {cellWidth: 69, halign:'center', textColor: '#000000', fontSize: 8},
                    2: {cellWidth: 15, halign:'left', textColor: '#000000', fontSize: 8},
                    3: {cellWidth: 15, halign:'left', textColor: '#000000', fontSize: 8},
                    4: {cellWidth: 31, halign:'center', textColor: '#000000', fontSize: 8},
                    5: {cellWidth: 41, halign:'left', textColor: '#000000', fontSize: 8},
                },
                bodyStyles: {
                    lineColor: '#000000',
                }
            });

            let footerY = doc.autoTable.previous.finalY + 4

            doc.setFont(undefined, 'italic').setFontSize(8).text(4,footerY,"WHITE - ACCTG COPY").setFont(undefined, 'normal')
            doc.setFont(undefined, 'italic').setFontSize(8).text(43,footerY,"PINK - CUSTOMER COPY").setFont(undefined, 'normal')

            this.$store.dispatch('printCreditMemo',this.credit_memo.id).then(response => {
                if (response.data == 'success') {
                    doc.autoPrint();
                    window.open(doc.output('bloburl'), '_blank');
                    this.$emit('closeDialog')
                    this.$emit('getAllCreditMemos')
                }
            })
        },
    },
    watch: {
        'credit_memo_items': {
            handler(){
                while (this.credit_memo_items.length < 4) {
                    this.credit_memo_items.push(Object.assign({},{
                        id: null,
                        credit_memo_id: null,
                        item_id: null,
                        item_model: null,
                        item_name: null,
                        uom: null,
                        quantity_cm: null,
                        item_amount: null,
                        total_amount: null,
                    }))
                }
            },
            immediate: true,
        }
    }
}
</script>

<style>

</style>
