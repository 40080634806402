<template>
    <v-container fluid>
            <ComponentHeader
                class="ma-n4"
                :button="true"
                :title="'Inactive Items: Actual vs Virtual Stocks'"
                :type="'Inventory'"
            ></ComponentHeader>
            <v-container class="bg-bluish-gray default-container ma-0">
            <v-row no-gutters class="pb-1">
                <v-col sm="12" md="2">
                    <v-autocomplete
                    v-model="filters.item_id"
                    :items="GET_ITEM_SELECTION_DEFAULT"
                    :loading="loading.item_selection"
                    :disabled="loading.item_selection"
                    label="Model"
                    background-color="white"
                    item-value="value"
                    item-text="text"
                    placeholder="Select Item"
                    clearable
                    multiple
                    small-chips
                    deletable-chips
                    outlined
                    dense
                    auto-select-first
                    hide-details
                >
                </v-autocomplete>
                </v-col>
                <v-col sm="12" md="2">
                    <v-autocomplete
                        v-model="filters.type_ids"
                        :items="dropdowns.item_types.list"
                        :loading="loading.item_types"
                        :disabled="loading.item_types"
                        class="d-inline-block w-100 small"
                        label="Type"
                        background-color="white"
                        clearable
                        multiple
                        small-chips
                        deletable-chips
                        outlined
                        dense
                        auto-select-first
                        hide-details
                    ></v-autocomplete>
                </v-col>
                <v-col sm="12" md="3">
                    <v-autocomplete
                        v-model="filters.item_category_ids"
                        :items="dropdowns.item_categories.list"
                        :loading="loading.item_categories"
                        :disabled="loading.item_categories"
                        class="d-inline-block w-100 small"
                        label="Category"
                        background-color="white"
                        clearable
                        multiple
                        small-chips
                        deletable-chips
                        outlined
                        dense
                        auto-select-first
                        hide-details
                    ></v-autocomplete>
                </v-col>

                <v-col sm="3" md="2">
                    <v-autocomplete
                        v-model="filters.phased_out"
                        :items="dropdowns.item_phased_out.list"
                        :loading="loading.item_types"
                        :disabled="loading.item_types"
                        class="d-inline-block w-100 small"
                        label="Phased Out"
                        background-color="white"
                        outlined
                        dense
                        auto-select-first
                        hide-details
                    ></v-autocomplete>
                </v-col>

                <v-spacer></v-spacer>
                <v-col sm="12" md="3" class="text-right">
                    <v-btn class="mr-1" @click="resetFields()">Clear</v-btn>
                    <v-btn @click="getAllItems(1)">Export</v-btn>
                    <v-btn class="ml-1" @click="clearUrlParams(); getAllItems()">Filter</v-btn>
                </v-col>
            </v-row>
            <v-row dense no-gutters>
                <v-col cols="12">
                    <v-card>
                        <v-container fluid>
                            <v-data-table
                                :headers="datatable.headers"
                                :items="datatable.items.list"
                                :options.sync="datatable.options"
                                :server-items-length="datatable.items.count"
                                :loading="toggles.loaded"
                                class="w-100"
                                dense
                                disable-sort

                            >
                                <!-- @update:page="getAllItems()"
                                @update:items-per-page="getAllItems()" -->
                            <template v-slot:[`item.image_path`]="{ item }">
                                <v-img class="img-thumbnail" width="50" height="50" :src="checkImagePath(item.image_path)" eager contain></v-img>
                            </template>

                            <template v-slot:[`item.selling_price`]="{ item }">
                                <span class="text-nowrap">
                                    {{ thousandSeprator(item.selling_price) }}
                                </span>
                            </template>

                            <template v-slot:[`item.actual_stocks`]="{ item }">
                                <span class="text-nowrap">
                                    {{ thousandSeprator(item.actual_stocks) }}
                                </span>
                            </template>

                            <template v-slot:[`item.virtual_stocks`]="{ item }">
                                <span class="text-nowrap">
                                    {{ thousandSeprator(item.virtual_stocks) }}
                                </span>
                            </template>

                            <template v-slot:[`item.action`]="{ item }">
                                <v-btn text icon color="orange">
                                    <v-icon class="btn-action" small @click="viewItem(item.id)">mdi-eye</v-icon>
                                </v-btn>
                            </template>

                            </v-data-table>
                        </v-container>
                    </v-card>
                    <ItemsViewComponent
                        :viewItemId="datatable.items.selected"
                        @closeView="closeView">
                    </ItemsViewComponent>
                </v-col>
            </v-row>
        </v-container>
    </v-container>

    </template>

<script>
import swal from "sweetalert2";
import { mapGetters } from 'vuex';
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent'
import ItemsViewComponent from "@/views/main/layouts/dialogs/Sales/Items/ItemsViewComponent.vue"

export default {
    mixins: [
        SharedFunctionsComponent
    ],
    components: {
        ComponentHeader,
        ItemsViewComponent
    },
    data(){
        return {
            urlParams: {
                type_ids: [],
                category_ids: [],
                item_id: null,
            },
            defaults: {
                item_type_ids: [],
                item_category_ids: [],
                allowable: 0,
                export_type: 1,
                for_export: 0,
                item_id: []
            },
            toggles: {
                loaded: false,
                date_to: false,
                custom_date: false,
                menu1: false,
                menu2: false,
            },
            loading: {
                item_types: true,
                item_categories: true,
                item_selection: true,
                initialLoad: true,
            },
            filters: {},
            dropdowns: {
                item_types: {
                    list: [],
                },
                item_categories: {
                    list: [],
                },
                item_phased_out:{
                    list: [
                        { text:'All', value:-1},
                        { text:'Phased Out',value:1},
                        { text:'Not Phased Out',value:0}
                    ],
                },
            },
            datatable: {
                items: {
                    list: [],
                    count: 0,
                    selected: 0,
                },
                headers: [
                    { text: 'IMAGE', align: 'start', value: 'image_path', },
                    { text: 'MODEL', align: 'start', value: 'model', },
                    { text: 'NAME', align: 'start', value: 'name', },
                    { text: 'UOM', align: 'start', value: 'UOM' },
                    { text: 'TYPE', align: 'start', value: 'type' },
                    { text: 'CATEGORY', align: 'start', value: 'category', },
                    { text: 'SRP', align: 'end', value: 'selling_price', },
                    { text: 'MOQ', align: 'center', value: 'moq', },
                    { text: 'MB', align: 'center', value: 'master_box_qty', },
                    { text: 'SB', align: 'center', value: 'small_box_qty', },
                    { text: 'ACTUAL STOCKS', align: 'end', value: 'actual_stocks', },
                    { text: 'VIRTUAL STOCKS', align: 'end', value: 'virtual_stocks', },
                    { text: 'DEACTIVATE REASON', align: 'start', value: 'deactivate_reason', },
                    { text: 'ACTION', align: 'center', value: 'action', },
                ],
                options: {

                },
            },
        }
    },
    async mounted(){
        await this.storeUrlParams()
        await this.loadDropdowns()
        await this.setDefaultValues()
        // this.getAllItems()
    },
    methods: {
        viewItem(item_id) {
            this.datatable.items.selected = item_id
        },
        closeView() {
            this.datatable.items.selected = 0
        },
        async storeUrlParams() {
            const params = new URLSearchParams(window.location.search)

            Object.assign(this.urlParams, {
                type_ids: params.get('type_ids') == null ? this.defaults.type_ids : JSON.parse(params.get('type_ids')),
                category_ids: params.get('category_id') == null ? this.defaults.item_category_ids : JSON.parse(params.get('category_id')),
                item_id: params.get('item_id') == null ? this.defaults.item_id : JSON.parse(params.get('item_id')),
            })

            Object.assign(this.filters, {
                item_type_ids: this.urlParams.type_ids,
                item_category_ids: this.urlParams.category_ids,
                item_id: this.urlParams.item_id,
            })
        },
        async setDefaultValues() {
            Object.assign(this.filters, {
                phased_out: -1 ,
                item_type_ids:
                    this.dropdowns.item_types.list.filter(f => {
                        return [1, 2].includes(f.value)
                    }).map(m => parseInt(m.value))
            })
        },
        showDatePicker() {
            this.filters.date_option == 5 ? this.toggles.custom_date = true : this.toggles.custom_date = false
        },
        getAllItems(for_export = 0){
            this.toggles.loaded = true
            this.datatable.items.list = []

            this.filters.for_export = for_export

            if(for_export == 1){
                swal.fire('Exporting Files Please wait.')
                swal.showLoading()
            }

            let payload = {
                pagination: this.datatable.options,
                filters: this.filters,
                url:'getInactiveItems'
            }
            this.$store.dispatch('reports',payload).then(async response => {
                if (for_export == 0) {
                    this.datatable.items.list = response.data.data.data
                    this.datatable.items.count = response.data.data.total
                    swal.close()
                } else {

                    let data = response.data.data
                    const workbook = new this.$exceljs.Workbook()
                    const rawDataSheetName = `New Items ${this.$dayjs().format('YYYY-MM-DD')}`
                    let worksheet = workbook.addWorksheet(rawDataSheetName)

                    const columns = [
                        { header: 'IMAGE', key: '', width: 22, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: true, raw_data: false },
                        { header: 'MODEL', key: 'model', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: true, raw_data: true },
                        { header: 'NAME', key: 'name', width: 30, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: false, raw_data: true },
                        { header: 'DESCRIPTION', key: 'description', width: 57, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: true, raw_data: true },
                        { header: 'TYPE', key: 'type', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: false, raw_data: true },
                        { header: 'UOM', key: 'UOM', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: true, raw_data: true },
                        { header: 'CATEGORY', key: 'category', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: false, raw_data: true },
                        { header: 'SRP', key: 'selling_price', width: 18, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: '#,##0.00' }, pricelist: true, raw_data: true },
                        { header: 'MOQ', key: 'moq', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: '#,##0' }, pricelist: false, raw_data: true },
                        { header: 'MB', key: 'master_box_qty', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: '#,##0' }, pricelist: false, raw_data: true },
                        { header: 'SMB', key: 'small_box_qty', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: '#,##0' }, pricelist: false, raw_data: true },
                        { header: 'ACTUAL STOCKS', key: 'actual_stocks', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: '#,##0' }, pricelist: false, raw_data: true },
                        { header: 'VIRTUAL STOCKS', key: 'virtual_stocks', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: '#,##0'}, pricelist: false, raw_data: true },
                        { header: 'DEACTIVATE REASON', key: 'deactivate_reason', width: 30, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }}, pricelist: false, raw_data: true }
                    ]

                    let pos = 1

                    worksheet.columns = columns

                    // data.forEach(async (e,i) => {
                    for await (const item of data) {

                        worksheet.addRow(item)

                        worksheet.getCell(`H${(pos - 1) + 2}`).value = parseFloat(item.selling_price)
                        worksheet.getCell(`I${(pos - 1) + 2}`).value = Number(item.moq)
                        worksheet.getCell(`J${(pos - 1) + 2}`).value = Number(item.master_box_qty)
                        worksheet.getCell(`K${(pos - 1) + 2}`).value = Number(item.small_box_qty)
                        worksheet.getCell(`L${(pos - 1) + 2}`).value = Number(item.actual_stocks)
                        worksheet.getCell(`M${(pos - 1) + 2}`).value = Number(item.virtual_stocks)

                        // let finalImage = workbook.addImage({
                        //     base64: e.base64img,
                        //     extension: 'jpg',
                        // })

                        // worksheet.addImage(finalImage, {
                        //     tl: { col: 0, row: pos + 0.2 },
                        //     br: { col: 1, row: pos + 1},
                        //     editAs: 'twoCell'
                        // })

                        let height = 80;
                        let width = 60;
                        let aspectRatio = null;
                        worksheet.getRow(pos + 1).height = height;

                        let image = new Promise((resolve) => {
                            let img = new Image();
                            img.src = item.base64img;
                            img.onload = () => resolve(img);
                        });
                        aspectRatio = image.naturalWidth / image.naturalHeight;
                        let imageHeight = aspectRatio * width;

                        let modelImage = workbook.addImage({
                            base64: item.base64img,
                            extension: 'jpg',
                        });

                        worksheet.addImage(modelImage, {
                            tl: { col: 0.1, row: pos + 0.1 },
                            br: { col: 1, row: worksheet.rowCount },
                            ext: { width: width, height: height },
                            editAs: 'twoCell',
                        });

                        let row = worksheet.getRow(worksheet.rowCount);
                        row.width = width
                        if (imageHeight > worksheet.getRow(pos + 1).height) {
                            worksheet.getRow(pos + 1).height = imageHeight;
                        }

                        pos++
                    // })
                    }

                    worksheet.getRow(1).font = { bold: true };
                    worksheet.getRow(1).height = 30;
                    let borderStyles = {
                        top: { style: "thin" },
                        left: { style: "thin" },
                        bottom: { style: "thin" },
                        right: { style: "thin" }
                    };
                    worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
                        row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
                            cell.border = borderStyles;
                        });
                    });

                    if (this.filters.export_type == 1) {
                        workbook.removeWorksheet(rawDataSheetName)
                        workbook.eachSheet(ws => {
                            if (ws.rowCount == 1) {
                                workbook.removeWorksheet(ws.name)
                            }
                        })
                    }

                    workbook.xlsx.writeBuffer().then((data) => {
                        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheetcharset=utf-8' })
                        const filename = `Inactive Items: Actual vs Virtual Stocks.xlsx`
                        this.$filesaver.saveAs(blob, filename)
                    })

                    this.getAllItems(0)
                    swal.close()
                    swal.fire('Please wait.')
                    swal.showLoading()
                    // swal.close()
                }
                this.toggles.loaded = false
            })
        },
        async loadDropdowns() {
            await this.$store.dispatch('getItemTypes')
            await this.$store.dispatch('getItemCategories')
            await this.$store.dispatch('getAllitemSelectionDefault')
        },
        clearUrlParams(){
            Object.assign(this.urlParams, {
                type_ids: [],
                category_ids: [],
            })
        },
        resetFields(){
            this.filters = JSON.parse(JSON.stringify(this.defaults))
        },
    },

    computed:{
        ...mapGetters([
            'GET_ITEM_SELECTION_DEFAULT',
            'GET_ITEM_CATEGORIES',
            'GET_ITEM_TYPES'
        ])
    },

    watch:{
        'GET_ITEM_SELECTION_DEFAULT':{
            handler(val){
                this.item_list = val
                this.loading.item_selection = false
            }
        },
        'GET_ITEM_TYPES': {
            handler(val) {
                this.dropdowns.item_types.list = val
                this.loading.item_types = false
            }
        },
        'GET_ITEM_CATEGORIES': {
            handler(val) {
                this.dropdowns.item_categories.list = val
                this.loading.item_categories = false
            }
        },
        'datatable.options': {
            handler(val){
                if(!this.loading.initialLoad){
                    this.getAllItems()
                }else{
                    this.loading.initialLoad = false
                }
            }
        },
    }
};
</script>
<style>
    .col{
        padding: 2px !important;
    }
    .card-body{
        padding: 3px !important;
    }
</style>
