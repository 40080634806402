<template>
    <div>
        <v-card>
            <v-layout row class="mx-0 pt-4 px-4">
                <v-flex lg3 class="mr-2">
                    <v-autocomplete
                        outlined
                        dense
                        :items="GET_CUSTOMERS_SELECTION"
                        :loading="loadview.customer_selection"
                        :disabled="loadview.customer_selection"
                        v-model="selectedCustomer"
                        item-value="value"
                        item-text="text"
                        label="Select Customer"
                    />
                </v-flex>

                <v-flex lg3>
                    <v-autocomplete
                        label="Select Payment Method"
                        item-value="value"
                        item-text="text"
                        outlined
                        dense
                        v-model="paymentMethodIds"
                        :items="paymentMethodList"
                        @change="onSelectPaymentMethod"
                        return-object
                    />
                </v-flex>

                <v-spacer v-if="menuStatus != 10 || menuStatus != 9" />

                <v-flex lg3>
                    <div style="text-align:end">
                        <v-btn small class="ml-1" @click="clearFilter()">Clear</v-btn>

                        <v-btn small class="ml-1" @click="showExportOptionDialog()">
                            Export
                        </v-btn>

                        <v-btn small class="ml-1" @click="filter()">Filter</v-btn>
                    </div>
                </v-flex>
            </v-layout>

            <v-layout row class="mx-0">
                <v-flex lg6>
                    <v-layout row class="mx-0 px-4 pt-4">
                        <v-flex lg2 class="pr-2 w-100">
                            <v-autocomplete
                                outlined
                                dense
                                :items="dateRangeList"
                                v-model="selectedDateRange"
                                item-value="value"
                                item-text="text"
                                label="Range"
                            />
                        </v-flex>
                        <v-flex v-if="selectedDateRange == 'custom'" lg2 class="mr-2">
                            <v-dialog
                                ref="dateStartDialog"
                                v-model="startDateModal"
                                :return-value.sync="startDate"
                                persistent
                                width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="startDate"
                                    label="Date Start"
                                    readonly
                                    dense outlined
                                    class="hide-text-field-details"
                                    v-bind="attrs"
                                    v-on="on"
                                    clearable
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="startDate"
                                    scrollable
                                    :max="minDate"
                                >
                                <v-spacer />
                                <v-btn
                                    text
                                    color="primary"
                                    @click="startDateModal = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.dateStartDialog.save(startDate)"
                                >
                                    OK
                                </v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-flex>
                        <v-flex v-if="selectedDateRange == 'custom'" lg2 class="mr-2">
                            <v-dialog
                                ref="endDateDialog"
                                v-model="endDateModal"
                                :return-value.sync="endDate"
                                persistent
                                width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="endDate"
                                    label="Date End"
                                    readonly
                                    dense
                                    outlined
                                    class="hide-text-field-details"
                                    v-bind="attrs"
                                    v-on="on"
                                    :disabled="!startDate"
                                />
                                </template>
                                <v-date-picker
                                    v-model="endDate"
                                    scrollable
                                    :min="startDate"
                                >
                                <v-spacer />
                                <v-btn
                                    text
                                    color="primary"
                                    @click="endDateModal = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.endDateDialog.save(endDate)"
                                >
                                    OK
                                </v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-flex>
                    </v-layout>
                </v-flex>
                <v-flex lg6>
                    <div style="text-align:end">
                        <div v-if="menuStatus == 10 || menuStatus == 9" style="text-align:end" class="px-4 pt-4">
                            <span v-if="menuStatus == 10" class=" ml-1 badge bg-light" style="cursor:pointer" @click="filterByStatus(10)">all</span>
                            <span v-if="menuStatus == 10" class=" ml-1 badge bg-saved" style="cursor:pointer" @click="filterByStatus(0)">saved</span>
                            <span class=" ml-1 badge bg-primary" style="cursor:pointer" @click="filterByStatus(5)">confirmed</span>
                            <span class=" ml-1 badge bg-warning" style="cursor:pointer" @click="filterByStatus(6)">partial</span>
                            <span v-if="menuStatus == 10" class=" ml-1 badge bg-success" style="cursor:pointer" @click="filterByStatus(7)">paid</span>
                            <span v-if="menuStatus == 10" class=" ml-1 badge bg-danger" style="cursor:pointer" @click="filterByStatus(8)">overdue</span>
                        </div>
                    </div>
                </v-flex>
            </v-layout>

            <v-card-title>
                <v-layout row class="mx-0">
                    <v-spacer />
                    <v-flex lg2>
                        <div style="text-align:end">
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="SoaId"
                                single-line
                                hide-details
                                clearable
                                @keydown.enter="searchSoa()"
                                @click:clear="clearFilter()"
                            />
                        </div>
                    </v-flex>
                </v-layout>
            </v-card-title>

            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="statementOfAccounts"
                    :search="search"
                    :server-items-length="totalStatementOfAccounts"
                    :options.sync="options"
                    :items-per-page="20"
                    :loading="loading"
                >
                    <template v-slot:[`item.total_amount`]="{ item }">
                        <span class="text-nowrap">
                            &#x20b1;{{ item.total_amount | currency }}
                        </span>
                    </template>

                    <template v-slot:[`item.paid_amount`]="{ item }">
                        <span class="text-nowrap">
                            &#x20b1;{{ item.paid_amount | currency }}
                        </span>
                    </template>
                    <template v-slot:[`item.balance_amount`]="{ item }">
                        <span class="text-nowrap">
                            &#x20b1;{{ item.balance_amount | currency }}
                        </span>
                    </template>

                    <template v-slot:[`item.status`]="{ item }">
                        <span v-if="item.status==0" class="badge bg-saved">saved</span>
                        <span v-else-if="item.status==5" class="badge bg-primary">confirmed</span>
                        <span v-else-if="item.status==6" class="badge bg-warning">partial</span>
                        <span v-else-if="item.status==7" class="badge bg-success">paid</span>
                        <span v-else-if="item.status==8" class="badge bg-danger">overdue</span>
                    </template>

                    <template v-slot:[`item.payment_method`]="{ item }">
                        <span class="badge bg-success" v-if="item.payment_method==0">cash</span>
                        <span class="badge bg-purple" v-else-if="item.payment_method==1">cheque</span>
                        <span class="badge bg-warning" v-else-if="item.payment_method==2">deposit</span>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <span class="text-nowrap">
                            <v-btn text icon color="orange">
                                <v-icon class="btn-action" small @click="viewStatementOfAccount(item.id)">mdi-eye</v-icon>
                            </v-btn>
                            <!-- <v-btn v-if="item.status != 7" text icon color="blue">
                                <v-icon class="btn-action" small @click="editStatementOfAccount(item)">fas fa-edit</v-icon>
                            </v-btn>
                            <v-btn v-if="item.status != 7" text icon color="red">
                                <v-icon class="btn-action" small @click="deleteStatementOfAccount(item.id)">fas fa-trash-alt</v-icon>
                            </v-btn> -->
                        </span>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <ViewArCollectionComponentDialogVue :soaId="soaId" @closeView="closeView" />

        <v-dialog v-model="exportDialog" persistent max-width="30%">
            <v-card>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">Export Options</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeExportOptionDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider />

                <v-layout row class="mx-0">
                    <v-flex lg6>
                        <div style="text-align:center">
                            <v-btn
                                v-if="exportSoaLoading"
                                :loading="exportSoaLoading"
                                :disabled="statementOfAccounts.length == 0"
                            >
                                SOA Details
                            </v-btn>

                            <download-excel
                                v-else
                                class="btn pa-0"
                                type="csv"
                                :data="exportStatementOfAccounts"
                                :fields="excelSoaFields"
                                :before-generate="startSoaDownload"
                                :before-finish="finishSoaDownload"
                                style="background-color:#397373;color:white"
                                :name="exportSoaName"
                            >
                                <v-btn>SOA Details</v-btn>
                            </download-excel>
                        </div>
                    </v-flex>
                    <v-flex lg6>
                        <div style="text-align:center">
                            <v-btn
                                v-if="exportSoaAllocationsLoading"
                                :loading="exportSoaAllocationsLoading"
                                :disabled="statementOfAccounts.length == 0"
                            >
                                SOA Payments
                            </v-btn>

                            <download-excel
                                v-else
                                class="btn pa-0"
                                type="csv"
                                :data="exportSoaAllocations"
                                :fields="excelSoaAllocationFields"
                                :before-generate="startSoaAllocationDownload"
                                :before-finish="finishSoaAllocationDownload"
                                style="background-color:#397373;color:white"
                                :name="exportSoaAllocationName"
                            >
                                <v-btn>SOA Payments</v-btn>
                            </download-excel>
                        </div>
                    </v-flex>
                </v-layout>

                <v-divider />

                <div class="pb-4"></div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import ViewArCollectionComponentDialogVue from './ViewArCollectionComponentDialog.vue';
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
export default {
    data() {
        return {
            headers: [
                { text: 'SOA Id', value: 'id', },
                { text: 'Customer', value: 'company_name' },
                { text: 'Confirmed At', value: 'confirmed_at' },
                { text: 'Due Date', value: 'due_date' },
                { text: 'Total Amount', value: 'total_amount' },
                { text: 'Paid Amount', value: 'paid_amount' },
                { text: 'Balance Amount', value: 'balance_amount' },
                { text: 'Payment Method', value: 'payment_method' },
                { text: 'Prepared By', value: 'prepared_by_name' },
                { text: 'Completed By', value: 'completed_by_name' },
                { text: 'Complete Date', value: 'complete_date' },
                { text: 'Status', value: 'status' },
                { text: 'Actions', value: 'actions' },
            ],
            statementOfAccounts: [],
            loading: true,
            options: {},
            search: '',
            page: 1,
            itemsPerPage: 10,
            totalStatementOfAccounts: 0,
            status: 10,
            customerList: [],
            selectedCustomer: '',
            soaId: '',

            exportDialog: false,
            exportSoaName: `SOA Details ${this.$dayjs().format('YYY-MM-DD, hh:mm:ss')}.xls`,
            exportSoaAllocationName: `SOA Allocations ${this.$dayjs().format('YYY-MM-DD, hh:mm:ss')}.xls`,
            exportSoaAllocationDetailedName: `SOA Allocations Detailed ${this.$dayjs().format('YYY-MM-DD, hh:mm:ss')}.xls`,
            excelSoaFields: {
                'SOA Id': 'id',
                'Customer': 'company_name',
                'Due Date': 'due_date',
                'Total Amount': 'total_amount',
                'Paid Amount': 'paid_amount',
                'Balance Amount': 'balance_amount',
                'Cash': 'cash',
                'Prepared By': 'prepared_by_name',
                'Confirmed At': 'confirmed_at',
                'Completed By': 'completed_by_name',
                'Complete Date': 'complete_date',
                'Status': 'status_name',
            },
            excelSoaAllocationFields: {
                'SOA Id': 'soa_id',
                'Reference Number': 'reference_number',
                'Payment Name': 'cheque_name',
                'Payment Date': 'cheque_date',
                'Bank Name': 'bank_name',
                'Payment Type': 'payment_type_name',
                'Allocated WHT': 'allocated_wht',
                'Allocated Amount': 'allocated_amount',
                'Allocated By': 'allocated_by',
                'Date': 'created_at',
            },

            exportSoaLoading: false,
            exportSoaAllocationsLoading: false,
            exportStatementOfAccounts: [],
            exportSoaAllocations: [],

            startDateModal: false,
            endDateModal: false,
            startDate: '',
            endDate: '',
            minDate: this.$dayjs().format('YYYY-MM-DD'),

            dateRangeList: [
                { text: 'Today', value: 'today' },
                { text: 'Current Week', value: 'currentWeek' },
                { text: 'Current Month', value: 'currentMonth' },
                { text: 'Custom', value: 'custom' },
            ],

            selectedDateRange: '',
            paymentMethodIds: [],
            paymentMethodList: [],

            loadview:{
                customer_selection : true
            },
            initialLoad: true
        }
    },
    components: {
        ViewArCollectionComponentDialogVue,
    },
    async mounted() {

        // await this.getAllStatementOfAccounts();
        await this.getAllCustomer();
        await this.getPaymentMethods();
    },
    props: ['menuStatus'],
    watch: {
        menuStatus() {
            if(!!this.menuStatus) {
                this.status = this.menuStatus;
                this.statementOfAccounts = [];
                this.getAllStatementOfAccounts();
            }
        },
        // statementOfAccounts() {
        //     if(this.statementOfAccounts.length > 0){
        //         this.loading = false;
        //     } else {
        //         this.loading = true;
        //     }
        //     this.$store.commit('clearPaymentsMenu');
        // },
        successUpdate() {
            if(this.successUpdate) {
                this.soadId = '';
                this.getAllStatementOfAccounts();
            }
        },
        options(data) {
            if(!this.initialLoad){
                // this.loading = true
                this.page = data.page;
                this.getAllStatementOfAccounts();
            }else{
                this.initialLoad = false
                this.loading = false
            }
        },
        selectedCustomer() {
            if(!!this.selectedCustomer) {
                this.getAllStatementOfAccounts();
            } else {
                this.selectedCustomer = '';
            }
        },
        exportStatementOfAccounts() {
            if(this.exportStatementOfAccounts.length > 0) {

            }
        },
        startDate() {
            if(!!this.startDate) {
                this.endDate = this.minDate;
            } else {
                this.endDate = '';
            }
        },
        selectedDateRange() {
            if(this.selectedDateRange == 'today') {
                this.startDate = this.$dayjs().format('YYYY-MM-DD');
                this.endtDate = this.$dayjs().format('YYYY-MM-DD');
            } else if(this.selectedDateRange == 'currentWeek') {
                this.startDate = this.$dayjs().startOf('week').format('YYYY-MM-DD');
                this.endtDate = this.$dayjs().endOf('week').format('YYYY-MM-DD');
            } else if(this.selectedDateRange == 'currentMonth') {
                this.startDate = this.$dayjs().startOf('month').format('YYYY-MM-DD');
                this.endtDate = this.$dayjs().endOf('month').format('YYYY-MM-DD');
            } else if(this.selectedDateRange == 'custom') {
                this.startDate = '';
                this.endtDate = '';
            }
        },
        paymentMethodIds() {

        },
        'GET_CUSTOMERS_SELECTION':{
            handler(val){
                this.dealersList = val.data
                this.loadview.customer_selection = false
            }
        },
    },
    computed: {
        ...mapGetters([
            'GET_CUSTOMERS_SELECTION'
        ]),
        successUpdate() {
            return this.$store.state.accounting.soaCustomerDetails.successUpdate;
        },
        menu() {
            return this.$store.state.accounting.payments.menu;
        },
    },
    methods: {
        clearFilter() {
            this.selectedCustomer = '';
            this.search = '';
            this.startDate = '';
            this.endDate = '';
            this.selectedDateRange = '';
            this.paymentMethodIds = [];
            this.getAllStatementOfAccounts();
        },
        clearFields() {
            this.status = '';
            this.search = '';
        },
        searchSoa() {
            this.selectedCustomer = '';
            this.status = 10;
            this.getAllStatementOfAccounts();
        },
        getAllCustomer(){
                this.$store.dispatch('getAllcustomersSelection')
                this.customerList = this.GET_CUSTOMERS_SELECTION
        },
        getAllStatementOfAccounts(){
            this.loading = true
            this.statementOfAccounts = []
            let payload = {
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                status: this.status,
                customerId: this.selectedCustomer,
                search: this.search,
                startDate: this.startDate,
                endDate: this.endDate,
                paymentMethodIds: this.paymentMethodIds,
                url: "getAllSoaReports",
            }

            this.$store.dispatch('reportsPost',payload) .then(response => {
                this.statementOfAccounts = response.data.data;
                this.totalStatementOfAccounts = response.data.total
                this.loading = false
                this.$store.commit('clearPaymentsMenu');
            })
        },
        filterByStatus(status) {
            this.status = status;
            this.statementOfAccounts = [];
            this.search = '';
            this.loading = true;
            this.getAllStatementOfAccounts();
        },
        viewStatementOfAccount(id) {
            this.soaId = id;
            this.$store.commit('custSoaPaymentDetailShow');
        },
        editStatementOfAccount(soa) {
            this.$store.commit('soaCustomerSetDetails', soa);
            this.$store.commit('soaShowCustDetailAddEditDialog');
        },
        deleteStatementOfAccount(id) {
        },
        closeView(confirm) {
            if(confirm) {
                this.soadId = '';
            }
        },
        checkMenuStatus() {
            if(this.menuStatus != 10 && this.menuStatus != 9) {
                return 'text-align:end';
            }

            return '';
        },
        getAllStatementOfAccountsForExport() {
            this.exportSoaLoading = true;
            let payload = {
                url: "getAllStatementOfAccountsForExport"
            }

            this.$store.dispatch('reports',payload).then(response=>{
                this.exportStatementOfAccounts = response.data;
                this.exportSoaLoading = false;
            }).catch(e=>{
                this.exportSoaLoading = false;
            });
        },
        getAllSoaAllocationsForExport() {
            this.exportSoaAllocationLoading = true;
            let payload = {
                url: "getAllSoaAllocationsForExport"
            }
            this.$store.dispatch('reports',payload).then(response=>{

                this.exportSoaAllocations = response.data;
                this.exportSoaAllocationLoading = false;
            }).catch(e=>{
                this.exportSoaAllocationLoading = false;
            });
        },
        showExportOptionDialog() {
            this.exportDialog = true;
            this.getAllStatementOfAccountsForExport();
            this.getAllSoaAllocationsForExport();
        },
        closeExportOptionDialog() {
            this.exportDialog = false;
        },
        startSoaDownload() {
            this.exportSoaLoading = true;
        },
        finishSoaDownload() {
            this.exportSoaLoading = false;
            swal.fire('', 'Export Success', 'success');
        },
        startSoaAllocationDownload() {
            this.exportSoaAllocationLoading = true;
        },
        finishSoaAllocationDownload() {
            this.exportSoaAllocationLoading = false;
            swal.fire('', 'Export Success', 'success');
        },
        filter() {
            this.search = '';
            this.getAllStatementOfAccounts();
        },
        onSelectPaymentMethod(pm) {
            this.paymentMethodIds = pm;
        },
        getPaymentMethods() {
            this.paymentMethodList = [
                { text: 'cash', value: 0 },
                { text: 'cheque', value: 1 },
                { text: 'deposit', value: 2 },
            ]
        }
    }
}
</script>

<style>
</style>
