<template>
	<v-container fluid>
	  <v-card-title>
		<v-row>
		  <v-col md="16" class="d-flex no-block align-items-center">
			<h4 class="page-title">CUSTOMER MAPS</h4>
		  </v-col>
		</v-row>
	  </v-card-title>
	  <v-container>
		<v-row>
		  <template>
			<v-tabs
			v-model="tab"
			color="cyan"
			dark
			slider-color="cyan"
			show-arrows
			background-color="#424242"
            class="mx-2"
			>
			  <v-tabs-slider color="cyan"></v-tabs-slider>
			  <v-tab
				ripple
				v-for="(item, i) in items"
				:key="item"
				@click="tabs(i)"
			  >
				{{ item }}
			  </v-tab>
			</v-tabs>
		  </template>
		</v-row>
	  </v-container>
	  <v-container class="ma-0 pa-2">
		<v-tabs-items v-model="tab">
		  <v-tab-item v-for="(table, index) in component_customer" :key="index">
			<keep-alive>
			  <component
				:is="table.components"
				:cp_text="table.status_text"
				:component_customer="component_customer"
			  ></component>
			</keep-alive>
		  </v-tab-item>
		</v-tabs-items>
	  </v-container>
	  <dialogs
		:cp="components"
		:scrollable="true"
		:width="dialog_width"
	  ></dialogs>
	</v-container>
  </template>
  <script>
  import customer from "./tables/CustomerMapComponent.vue";
  import Dialogs from "../../layouts/dialogs/Dialog.vue";
  import addCustomerMapDialog from "../../layouts/dialogs/Maps/CustomerMapComponent.vue";
  import addMapsMarkerDialog from "../../layouts/dialogs/Maps/MapsMarkerComponent.vue";
  import maps from "@/views/main/maps/MapComponent.vue"
  import marker from "./tables/MapsMarkerComponent.vue";
  import { mapGetters } from "vuex";
  export default {
	components: {
	  customer,
	  Dialogs,
	  addCustomerMapDialog,
	  maps,
	  addMapsMarkerDialog,
	},
	data() {
	  return {
		components: "",
		customer_component: addCustomerMapDialog,
		tab: 0,
		items: ["MARKER","LEGENDS","MAPS"],
		component_customer: [
		  {
			components: customer,
			status_text: "is_active",
			dialog: addCustomerMapDialog,
			dialog_width: "60%",
		  },
		  {
			components: marker,
			status_text: "marker",
			dialog: addMapsMarkerDialog,
			dialog_width: "30%",
		  },
		  {
			components: maps,
			status_text: "maps",
		  }

		],
		dialog_width: "70%",
		counter:0
	  };
	},
	mounted() {
	  this.tabs(0);
	  this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
	  this.$store.dispatch("getClassification")
	},
	methods: {
	  tabs(index) {
		this.$store.commit('TAB', index);
		if(index == 2){
			if(this.counter == 0){
				this.counter++
			}
			else{
				this.$store.commit('REFRESH_MAP',true)
			}
		}
		this.components = this.component_customer[index].dialog;
		this.dialog_width = this.component_customer[index].dialog_width;
	  },
	},
	computed: {
		...mapGetters([
			'GET_TAB'
		])
	},
	watch: {
		GET_NEW_CUSTOMER_MAPS: {
			handler(val) {
				this.$store.commit('DIALOG', false);
				Swal.fire({
					title: 'Success',
					icon: 'success',
					timer: 1500,
				});
				// this.$store.dispatch('getAllClassification')
				this.$store.commit('NEW_CUSTOMER_MAPS', false);
			}
		},
	},
  };
  </script>
  