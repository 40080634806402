<template>
    <v-dialog v-model="GET_ADMIN_EDIT_OTHER_ITEM_DIALOG" persistent width="40%">
        <v-card>
        <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
          <span class="text-h5">EDIT ITEM</span>
          <v-row class="m-0">
            <v-col cols="pull-right-10 p-2">
              <v-btn
                text
                icon
                small
                color="gray"
                class="float-right"
                @click="closeDialog()"
              >
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-container  style="overflow-x: hidden;overflow-y: scroll;">
          <v-row class="no-gutters">
            <v-col cols="12" sm="12" md="5" class="pl-1 pt-1 dropzone-container">
              <v-card height="100%" width="100%"  bg fill-height grid-list-md text-xs-center  @dragover="dragover" @dragleave="dragleave" @drop="drop">
                  <v-container bg fill-height grid-list-md text-xs-center >
                  <v-layout row wrap align-center>
                    <v-flex>
                      <v-container fluid>
                        <v-flex>
                        <v-row>
                            <v-col cols="12" class="text-center">
                              <v-container  :class="have_img?'position-relative fluid d-flex justify-content-center':'d-none'">
                                    <img id="image" class="img-thumbnail text-center" width="60%" height="60%"  eager contain/>
                              <div :class="'position-relative fluid d-flex justify-content-end'">
                                <v-btn
                                    text
                                    icon
                                    small
                                    color="white"
                                    :class="disabled_view?'d-none position-absolute':'position-absolute'"
                                    @click="clearImage()">
                                    <v-icon color="black">mdi-close-circle</v-icon>
                                  </v-btn>
                                </div>
                              </v-container>
                              <v-icon style="font-size:50px;" :class="have_img?'d-none':'d-block'">mdi-upload</v-icon>
                              <p :class="have_img?'d-none':'d-block'"><span>Drag & Drop <p >or <label for="upload-file" style="color:blue;cursor:pointer;">browse</label> </p></span></p>
                              <input type="file" id="upload-file" class="d-none" @change="onChange"
                              ref="file"
                              accept=".jpg,.jpeg,.png">
                            </v-col>
                        </v-row>
                      </v-flex>
                      </v-container>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="7">
              <v-card height="100%">

            <v-row class="no-gutters">
                <v-col cols="12">
                    <v-textarea v-model="form.item_name" dense outlined label="Item" :disabled="disabled_view" no-resize></v-textarea>
                </v-col>
              </v-row>
              <v-row class="no-gutters">
                <v-col cols="12">
                    <v-autocomplete
                      v-if="!isDisabled_vendor1 || form.vendor_name_1 == ''"
                      v-model="form.vendor_1"
                      :items="supplier_items_1"
                      item-text="text"
                      item-value="value"
                      auto-select-first
                      cache-items
                      outlined
                      label="Vendor 1"
                      required
                      @change="selectedSupplier"
                      :disabled="disabled_view"
                      dense
                      item-color="danger"
                    >
                    <template
                    v-slot:item="{ item }">
                    <span v-if="item.value == 0"  class="color-primary">
                            {{ item.text }}
                        </span>
                    <span v-if="item.value > 0" >
                      {{ item.text }}
                    </span>
                    </template>
                  </v-autocomplete>
                  <v-text-field
                  v-if="form.vendor_name_1 != '' && isDisabled_vendor1"
                  v-model="form.vendor_name_1"
                  outlined
                  label="Vendor 1"
                  dense
                  :readonly="!editNewVendor_1"
                  >
                <template #append>
                  <v-icon v-if="saveEditNewVendorIcon_1" class="hover-save" @click="SaveEditNewVendor(1)">mdi-content-save-edit</v-icon>
                  <v-icon v-if="!saveEditNewVendorIcon_1" class="hover-pencil" @click="EditNewVendor(1)">mdi-pencil</v-icon>
                  <v-icon v-if="!saveEditNewVendorIcon_1" class="hover-close" @click="clearnewVendor(1)">mdi-close</v-icon>
                </template>
                </v-text-field>
                </v-col>
              </v-row>
              <v-row class="no-gutters">
                <v-col cols="12">
                  <v-text-field
                  v-if="form.vendor_name_2 != '' && isDisabled_vendor2"
                  v-model="form.vendor_name_2"
                  outlined
                  label="Vendor 2"
                  dense
                  :readonly="!editNewVendor_2"
                  >
                <template #append>
                  <v-icon v-if="saveEditNewVendorIcon_2" class="hover-save" @click="SaveEditNewVendor(2)">mdi-content-save-edit</v-icon>
                  <v-icon v-if="!saveEditNewVendorIcon_2" class="hover-pencil" @click="EditNewVendor(2)">mdi-pencil</v-icon>
                  <v-icon v-if="!saveEditNewVendorIcon_2" class="hover-close" @click="clearnewVendor(2)">mdi-close</v-icon>
                </template>
                </v-text-field>
                    <v-autocomplete
                    v-if="!isDisabled_vendor2 || form.vendor_name_2 == ''"
                    v-model="form.vendor_2"
                      :items="supplier_items_2"
                      item-text="text"
                      item-value="value"
                      auto-select-first
                      cache-items
                      outlined
                      label="Vendor 2"
                      required
                      :disabled="disabled_view"
                      dense
                    >
                    <template
                    v-slot:item="{ item }">
                    <span v-if="item.value == 0"  class="color-primary">
                            {{ item.text }}
                        </span>
                    <span v-if="item.value > 0" >
                      {{ item.text }}
                    </span>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <!-- <v-row class="no-gutters">
                <v-col cols="12">
                  <v-autocomplete
                      v-model="form.department_id"
                      :items="GET_LIST_DEPARTMENT"
                      :search-input.sync="search_department"
                      item-text="text"
                      item-value="value"
                      auto-select-first
                      outlined
                      label="Department"
                      required

                      dense
                    ></v-autocomplete>
                </v-col>
              </v-row> -->
              <v-row class="no-gutters">
                <v-col cols="12">
                    <v-text-field v-model="form.price" dense outlined label="Price" :disabled="disabled_view"></v-text-field>
                </v-col>
              </v-row>
              <!-- <v-row class="no-gutters">
                <v-col cols="12">
                  <v-autocomplete
                      v-model="form.emp_id"
                      :items="GET_EMPLOYEE_DATA_SELECTION"
                      item-text="text"
                      item-value="value"
                      auto-select-first
                      outlined
                      label="Employee Name"
                      required
                      multiple
                      chips
                      small-chips
                      deletable-chips
                      dense
                    ></v-autocomplete>
                </v-col>
              </v-row> -->
              <v-dialog v-model="newVendorDialog" persistent width="20%">
                <v-card>
                  <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                  <span small>CREATE NEW VENDOR</span>
                  <v-row class="m-0">
                    <v-col cols="pull-right-10 p-2">
                      <v-btn
                        text
                        icon
                        small
                        color="gray"
                        class="float-right"
                        @click="newVendorDialog = false; form.vendor_name = '';"
                      >
                        <v-icon >mdi-close-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="pa-1">
            <v-text-field
            v-if="vendor_count == 1"
            v-model="form.vendor_name_1"
            label="Vendor Name"
            dense
            outlined
            >
            </v-text-field>
            <v-text-field
            v-if="vendor_count == 2"
            v-model="form.vendor_name_2"
            label="Vendor Name"
            dense
            outlined
            >
            </v-text-field>
        </v-card-text>
        <v-card-actions class="text-center">

            <v-col cols="12" >
              <v-btn
              small
                class="float-center"
                @click="savenewvendorfromselection()"
                >
                <span>Save</span>
              </v-btn>
            </v-col>

        </v-card-actions>
                </v-card>

              </v-dialog>
            </v-card>
            </v-col>

            </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions class="text-center">
          <v-row class="ma-1">
            <v-col cols="12" >
              <v-btn
              v-if="actions != 'View'"
                class="float-center"
                @click="saveOtherItems(action_dispatch)"
                :disabled="disabled_view">
                <span>{{ actions }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
  import Swal from "sweetalert2";
  import { mapGetters } from "vuex";
  import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
  import images from '../../../../../assets/ingcoph_bg/ingcoph_logo_alt.jpg'
  export default {
    mixins: [
        SharedFunctionsComponentVue
    ],
    data() {
      return {
        valid:true,
        dialog_title:"",
        form: {
            otherItem:true,
            category_id:'',
            id:0,
            prf_item_id:0,
            sub_total:0,
            image_path:'',
            item_name:'',
            price:'',
            prices_item_id:'',
            quantities: 1,
            quantity:'',
            remarks:'',
            sub_category_id:'',
            uom_id:'',
            vendor_id:'',
            vendor_1:'',
            vendor_2:'',
            vendor_name_1:'',
            vendor_name_2:'',
            department_id:'',
            emp_id:[],
            edited_item:false,
            previous_other_item_image_path:'',
            new_image:false,
            other_item_image_path:''

        },
        newVendorDialog:false,
        supplier_items_1:[],
        supplier_items_2:[],
        employee_selection_data:[{'value':0,'text':'GENERAL ACCOUNTABILITY','department_id':0}],
        supplier_id_1:0,
        search_classification:'',
        subClass:[],
        disabled_view: false,
        action:'',
        isDragging: false,
        files: [],
        have_img:false,
        isDisabled_vendor1:false,
        isDisabled_vendor2:false,
        editNewVendor_1:false,
        saveEditNewVendorIcon_1:false,
        editNewVendor_2:false,
        saveEditNewVendorIcon_2:false,
        vendor_count:0,
        employee:[],
        search_department:null,
        action_dispatch:'',
        noImage:"images/noimage.png",
      };
    },
    components: {

    },
    computed: {
      ...mapGetters([
        "ACTION",
        "DIALOGS",
        "GET_ADMIN_ITEM_DISPATCH",
        "GET_LIST_DEPARTMENT",
        "GET_UOM_SELECTION",
        "GET_ADMIN_CLASSIFICATION_SELECTION",
        "GET_ADMIN_SUPPLIER_SELECTION",
        "GET_EDIT_ADMIN_ITEMS",
        "GET_NEW_ADMIN_ITEM",
        "GET_ADMIN_EDIT_OTHER_ITEM_DIALOG",
        "GET_EMPLOYEE_DATA_SELECTION",
        "GET_ADMIN_EDIT_OTHER_CURRENT_ITEMS",
        "GET_SELECTED_ADMIN_ITEMS",
        "GET_EDIT_PURCHASE_REQUEST_TABLE"
      ]),
      actions() {
        if (this.ACTION == "Submit") {
          this.action_dispatch = "Submit"
          return "Submit";
        }
        if(this.ACTION == "Update"){
          this.action_dispatch = "Save Edit"

          this.newVendorDialog = false
          if(this.GET_ADMIN_EDIT_OTHER_ITEM_DIALOG){
            this.form.item_name = this.GET_ADMIN_EDIT_OTHER_CURRENT_ITEMS.items.item_name
            this.form.vendor_1 = this.GET_ADMIN_EDIT_OTHER_CURRENT_ITEMS.items.vendor_1
            this.form.vendor_2 = this.GET_ADMIN_EDIT_OTHER_CURRENT_ITEMS.items.vendor_2
            this.form.emp_id = this.GET_ADMIN_EDIT_OTHER_CURRENT_ITEMS.items.emp_id
            this.form.price = this.GET_ADMIN_EDIT_OTHER_CURRENT_ITEMS.items.price
            this.form.other_item_image_path = this.GET_ADMIN_EDIT_OTHER_CURRENT_ITEMS.items.other_item_image_path
          if(this.GET_ADMIN_EDIT_OTHER_CURRENT_ITEMS.items.vendor_name_1 != ''){
              this.isDisabled_vendor1 = true
          }
          else if(this.GET_ADMIN_EDIT_OTHER_CURRENT_ITEMS.items.vendor_name_2 != ''){
            this.isDisabled_vendor2 = true
          }
          if(this.GET_ADMIN_EDIT_OTHER_CURRENT_ITEMS.items.id != 0 && this.GET_ADMIN_EDIT_OTHER_CURRENT_ITEMS.items.image_path != null){
            $('#image').attr('src',this.GET_ADMIN_EDIT_OTHER_CURRENT_ITEMS.items.image_path?process.env.VUE_APP_API_HOST+"/"+this.GET_ADMIN_EDIT_OTHER_CURRENT_ITEMS.items.image_path:process.env.VUE_APP_API_HOST+"/"+this.noImage);

          }else{
            console.log('test')
            $('#image').attr('src',this.GET_ADMIN_EDIT_OTHER_CURRENT_ITEMS.items.other_item_image_path?process.env.VUE_APP_API_HOST+"/"+this.GET_ADMIN_EDIT_OTHER_CURRENT_ITEMS.items.other_item_image_path:process.env.VUE_APP_API_HOST+"/"+this.noImage);

          }
          this.have_img = true;
          }
          return "Save"
        }
      },
    },
    mounted() {
        this.supplierSelection()
    },
    methods: {
      supplierSelection(){
        if(this.GET_ADMIN_EDIT_OTHER_ITEM_DIALOG){
            this.supplier_items_1 = []
        this.supplier_items_2 = []
            let new_vendor_selection_value = {
                text:'+ADD NEW VENDOR',
                value:0
              }
              this.supplier_items_1.push(new_vendor_selection_value)
              this.supplier_items_2.push(new_vendor_selection_value)
              this.GET_ADMIN_SUPPLIER_SELECTION.forEach(e=>{
              this.supplier_items_1.push(e)
              this.supplier_items_2.push(e)
          })

          let not_null_vendor_1 = this.GET_ADMIN_EDIT_OTHER_CURRENT_ITEMS.items.vendor_name_1 != "" ?true:false
          let not_null_vendor_2 = this.GET_ADMIN_EDIT_OTHER_CURRENT_ITEMS.items.vendor_name_2 != "" ?true:false
            if(not_null_vendor_1){
                this.supplier_items_1 = []
                this.isDisabled_vendor1 = false
                let finds = this.GET_ADMIN_EDIT_OTHER_CURRENT_ITEMS.items
                this.form.vendor_name_1 = finds.vendor_name_1
                this.supplier_items_1.push({
                  text:finds.vendor_name_1,
                  value:finds.vendor_1
                })
            }
            if(not_null_vendor_2){
              this.supplier_items_2 = []
              this.isDisabled_vendor2 = false
              let finds = this.GET_ADMIN_EDIT_OTHER_CURRENT_ITEMS.items
              this.form.vendor_name_2 = finds.vendor_name_2
              this.supplier_items_2.push({
                  text:finds.vendor_name_2,
                  value:finds.vendor_2
                })
            }
        }

      },
      selectedSupplier(val){
        this.supplier_id_1 = val
      },
      amount(i){
        let num = this.form.price.replace(",","");
        this.form.price =  this.thousandSeprator(num)
      },
      onChange() {
        this.files = [...this.$refs.file.files];
        let self = this;
        if (this.$refs.file.files && this.$refs.file.files[0]) {
                var reader = new FileReader();
                reader.onload = function (e) {
                  if(e.target.result){
                    self.have_img = true;
                    self.form.other_item_image_path = e.target.result;
                    self.form.new_image = true
                    $('#image').attr('src', e.target.result);
                  }
                };
                reader.readAsDataURL(this.$refs.file.files[0]);
            }
         },
        dragover(e) {
          e.preventDefault();
          this.isDragging = true;
        },
        dragleave() {
          this.isDragging = false;
        },
        drop(e) {
          e.preventDefault();
          this.$refs.file.files = e.dataTransfer.files;
          this.onChange();
          this.isDragging = false;
        },
      saveOtherItems(action){

        let obj = {
            index:this.GET_ADMIN_EDIT_OTHER_CURRENT_ITEMS.index,
            items:this.form
        }
        this.form.edited_item = true
        this.GET_EDIT_PURCHASE_REQUEST_TABLE.purchase_request_item[obj.index].item_name = this.form.item_name
        this.GET_EDIT_PURCHASE_REQUEST_TABLE.purchase_request_item[obj.index].vendor_1 = this.form.vendor_1
        this.GET_EDIT_PURCHASE_REQUEST_TABLE.purchase_request_item[obj.index].vendor_2 = this.form.vendor_2
        this.GET_EDIT_PURCHASE_REQUEST_TABLE.purchase_request_item[obj.index].emp_id = this.form.emp_id
        this.GET_EDIT_PURCHASE_REQUEST_TABLE.purchase_request_item[obj.index].price = this.form.price
        this.GET_EDIT_PURCHASE_REQUEST_TABLE.purchase_request_item[obj.index].other_item_image_path = this.form.other_item_image_path
        this.GET_EDIT_PURCHASE_REQUEST_TABLE.purchase_request_item[obj.index].edited_item = this.form.edited_item
        this.GET_EDIT_PURCHASE_REQUEST_TABLE.purchase_request_item[obj.index].new_image = this.form.new_image
        this.$store.commit('ADMIN_EDIT_OTHER_ITEMS',obj)

        this.$store.commit('ADMIN_EDIT_OTHER_ITEM_DIALOG',false);
            this.resetFields();
            this.clearImage();
            this.newVendorDialog = false
            this.$store.commit('ADMIN_EDIT_OTHER_ITEMS',{})


      },
      closeDialog() {
          this.resetFields();
          this.clearImage()
          this.$store.commit('ADMIN_EDIT_OTHER_ITEM_DIALOG',false);

          this.newVendorDialog = false
      },
      resetFields(){
        this.form = {
            otherItem:true,
            category_id:'',
            id:0,
            prf_item_id:0,
            sub_total:0,
            image_path:'',
            item_name:'',
            price:'',
            prices_item_id:'',
            quantities: 1,
            quantity:'',
            remarks:'',
            sub_category_id:'',
            uom_id:'',
            vendor_id:'',
            vendor_1:'',
            vendor_2:'',
            vendor_name_1:'',
            vendor_name_2:'',
            department_id:'',
            emp_id:[]
     }
      },
      clearImage(){
        this.have_img = false;
        $('#image').attr('src', '');
      },
      savenewvendorfromselection(){
        if(this.vendor_count == 1){
          this.isDisabled_vendor1 = true
          this.newVendorDialog = false
        }
        else if(this.vendor_count == 2){
          this.isDisabled_vendor2 = true
          this.newVendorDialog = false
        }
      },
      EditNewVendor(vendor_count){
        if(vendor_count == 1){
          this.editNewVendor_1 = true;
         this.saveEditNewVendorIcon_1 =true
        }
        else if(vendor_count == 2){
          this.editNewVendor_2 = true;
         this.saveEditNewVendorIcon_2 =true
        }
      },
      SaveEditNewVendor(vendor_count){
        if(vendor_count == 1){
        this.editNewVendor_1 = false
        this.saveEditNewVendorIcon_1 = false
        }
        else if(vendor_count == 2){
        this.editNewVendor_2= false
        this.saveEditNewVendorIcon_2 = false
        }
      },
      clearnewVendor(vendor_count){
        if(vendor_count == 1){
          this.isDisabled_vendor1 = false
          this.form.vendor_name_1 = ''
          this.form.vendor_1 = -1
        }
        else if(vendor_count == 2){
          this.form.vendor_name_2 = ''
          this.isDisabled_vendor2 = false
          this.form.vendor_2 = -1
        }
      }
    },
    watch: {
      'form.vendor_1':{
        handler(val){
        //     if(this.GET_ADMIN_EDIT_OTHER_ITEM_DIALOG){
        //     let not_null = this.GET_ADMIN_EDIT_OTHER_CURRENT_ITEMS.items.vendor_name_1 != ""?true:false
        //     if(not_null)
        //       {
        //         this.supplier_items_1 = []
        //         this.isDisabled_vendor1 = false
        //         let finds = this.GET_ADMIN_EDIT_OTHER_CURRENT_ITEMS
        //         this.form.vendor_name_1 = finds.vendor_name_1
        //         this.supplier_items_1.push({
        //           text:finds.vendor_name_1,
        //           value:finds.vendor_1
        //         })
        //       return false
        //     }
        //     if(val == 0){
        //     if(this.GET_ADMIN_EDIT_OTHER_CURRENT_ITEMS.items){
        //       return false;
        //     }
        //     this.newVendorDialog = true
        //     this.vendor_count = 1
        //   }
        //     }
        }
      },
      'form.vendor_2':{
        handler(val){
        //     if(this.GET_ADMIN_EDIT_OTHER_ITEM_DIALOG){
        // let not_null = this.GET_ADMIN_EDIT_OTHER_CURRENT_ITEMS.items.vendor_name_2 != ""?true:false
        //     if(not_null){
        //       this.supplier_items_2 = []
        //       this.isDisabled_vendor2 = false
        //       let finds = this.GET_ADMIN_EDIT_OTHER_CURRENT_ITEMS
        //       this.form.vendor_name_2 = finds.vendor_name_2
        //       this.supplier_items_2.push({
        //         text:this.form.vendor_name_2,
        //         value:finds.vendor_2
        //       })

        //       return false
        //     }
        //     if(val == 0){
        //         if(this.GET_ADMIN_EDIT_OTHER_CURRENT_ITEMS.items){
        //           return false;
        //         }
        //         this.newVendorDialog = true
        //         this.vendor_count = 2
        //   }
        // }
        }
      },
      GET_ADMIN_SUPPLIER_SELECTION:{
        handler(val){
          this.supplier_items_1 = []
         this.supplier_items_2 = []
          let new_vendor_selection_value = {
            text:'+ADD NEW VENDOR',
            value:0
          }
        this.supplier_items_1.push(new_vendor_selection_value)
        this.supplier_items_2.push(new_vendor_selection_value)
        val.forEach(e=>{
            this.supplier_items_1.push(e)
            this.supplier_items_2.push(e)
          })
        //   let not_null_vendor_1 = this.GET_ADMIN_EDIT_OTHER_CURRENT_ITEMS.items.vendor_name_1 != ""?true:false
        //   let not_null_vendor_2 = this.GET_ADMIN_EDIT_OTHER_CURRENT_ITEMS.items.vendor_name_2 != ""?true:false
        //   if(not_null_vendor_1){
        //       this.supplier_items_1 = []
        //       this.isDisabled_vendor1 = false
        //       let finds = this.GET_ADMIN_EDIT_OTHER_CURRENT_ITEMS
        //       this.form.vendor_name_1 = finds.vendor_name_1
        //       this.supplier_items_1.push({
        //         text:this.form.vendor_name_1,
        //         value:finds.vendor_1
        //       })
        //     }


        //     if(not_null_vendor_2){
        //       this.supplier_items_2 = []
        //       this.isDisabled_vendor2 = false
        //       let finds = this.GET_ADMIN_EDIT_OTHER_CURRENT_ITEMS
        //       this.form.vendor_name_2 = finds.vendor_name_2
        //       this.supplier_items_2.push({
        //         text:this.form.vendor_name_2,
        //         value:finds.vendor_2
        //       })
        //     }
        }
      },
      search_classification: {
      handler(val) {
        this.GET_ADMIN_CLASSIFICATION_SELECTION.filter((e) => {
          if (e.text == val) {
            this.subClass = e.sub_class;
          }
        });
      },
    },
      GET_ADMIN_EDIT_OTHER_ITEM_DIALOG:{
        handler(val){
          this.supplierSelection()
        }
      }
    },
  };
  </script>
  <style scoped>
  .hover-pencil:hover{
    color: rgb(50, 117, 242);
    cursor: pointer;
  }
  .hover-close:hover{
    color: rgb(246, 80, 80);
    cursor: pointer;
  }
  .hover-save:hover{
    color: rgb(84, 180, 89);
    cursor: pointer;
  }
  .color-primary{
    color:rgb(50, 117, 242);
  }
  </style>