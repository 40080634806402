<template>
    <div>
        <div class="page-wrapper">
            <div class="row p-2">
                <div class="col-12 d-flex no-block align-items-center">
                <h4 class="page-title">Receiving</h4>
                </div>
            </div>
            <div class="container">
                <div id="item-tabs" class="row mx-auto mt-4">
                    <v-tabs id="group-tab" color="cyan" dark slider-color="cyan" background-color="#424242" class="col-12" show-arrows>

                        <v-tab ripple @click="state = 'rr'">RECEIVING DETAIL</v-tab>
                        <v-tab ripple @click="state = 'rf'">REPAIR FORM</v-tab>

                        <v-tab-item>
                            <AddReceivingReportDialogComponentVue @rrCreateSuccess="rrCreateSuccess" />
                            <ReceivingReportTableComponentVue :state="state" :reloadTable="reloadTable" />
                        </v-tab-item>
                        <v-tab-item>
                            <RepairFormTableComponentVue :state="state" />
                        </v-tab-item>
                    </v-tabs>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import AddReceivingReportDialogComponentVue from '../AddReceivingReportDialogComponent.vue';
import ReceivingReportTableComponentVue from '../ReceivingReportTableComponent.vue';
import RepairFormTableComponentVue from '../RepairFormTableComponent.vue';

export default {
    data() {
        return {
            reloadTable: false,
            state: '',
        }
    },
    mounted(){
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
        this.$store.dispatch('getServiceAllCustomers');
        this.$store.dispatch('serviceGetAllLocations');
        this.$store.dispatch('serviceGetCurrentUser');
        this.$store.dispatch('dropdownGet',{url:'itemsExcludedSp'});
        this.$store.dispatch('getBackJobDropDown');
    },
    components: {
        AddReceivingReportDialogComponentVue,
        ReceivingReportTableComponentVue,
        RepairFormTableComponentVue
    },
    methods: {
        rrCreateSuccess(confirm) {
            if(confirm) {
                this.reloadTable = true;
                this.rrLists = [];
            } else {
                this.reloadTable = false;
            }
        },
    }
}
</script>

<style>

</style>
