<template>
    <v-card>
        <v-card-title>
            <v-container class="border-bottom">
                <v-row>
                    <v-col>
                        <span>CHEQUE SETTINGS</span>
                        <v-btn icon text class="float-right" @click="closeDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-title>
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col cols="12" lg="4" md="4" sm="12">
                    <v-text-field
                        v-model="form.days"
                        label="Days"
                        class="center-align-text"
                        dense
                        :disabled="actions == 'View'"
                        type="number"
                        hide-spin-buttons
                        outlined
                    />
                    <v-row>
                        <v-col cols="12" md="12">
                            <span class="text-caption">Selected Color:</span>
                            <v-card>
                                <v-card-text :style="'background-color:'+form.color_code+';'">
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    </v-col>
                    <v-col cols="12" lg="4" md="4" sm="12">
                        <v-color-picker
                        v-model="form.color_code"
                        class="ma-5"
                        hide-canvas
                        hide-sliders
                        hide-inputs
                        show-swatches
                        swatches-max-height="100px"
                        :disabled="actions == 'View'"

                        ></v-color-picker>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
            <v-container class="text-center">
                <v-row>
                    <v-col>
                        <v-btn @click="createOrUpdate()" v-if="actions != 'View'">{{ actions }}</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
    export default{
        data(){
            return{
                form:{
                    days:'',
                    color_code:''
                },
                date:false,
                dates:[],
                userAccess: {
                    view: false,
                    create: false,
                    edit: false,
                    delete: false,
                },
                dispatch:''
            }
        },
        computed:{
            ...mapGetters(['ACTION','GET_NEW_CHEQUE_SETTINGS','GET_EDIT_CHEQUE_SETTING','USER_ACCESS']),
            actions(){
            if (this.ACTION == "Submit") {
                this.dispatch = 'saveChequeSettings'
                return "Save";
            } else if (this.ACTION == "Update") {
                this.getEdit();
                this.dispatch = 'updateChequeSetting'
                return "Update";
            } else if (this.ACTION == "View") {
                this.getEdit();
                return "View";
            }
            }
        },
        mounted(){
            this.insertDates(1)
        },
        methods:{
        getEdit(){
            this.form.days = this.GET_EDIT_CHEQUE_SETTING.days;
            this.form.id = this.GET_EDIT_CHEQUE_SETTING.id;
            this.form.color_code = this.GET_EDIT_CHEQUE_SETTING.color_code;
        },
        insertDates(source){
            switch (source) {
                case 1:
                    for (let i = 1; i <= 31; i++) {
                        this.dates.push({ text: this.suffix(i).toString(), value: i.toString() })
                    }
                        break;
                case 2:
                    for (let i = 1; i <= 29; i++) {
                        this.dates.push({ text: this.suffix(i).toString(), value: i.toString() })
                    }
                        break;
                case 3:
                    for (let i = 1; i <= 30; i++) {
                        this.dates.push({ text: this.suffix(i).toString(), value: i.toString() })
                    }
                        break;
            }
        },
        suffix(num){
            const suffixes = ["st", "nd", "rd"];
            const suffix = suffixes[(num - 1) % 10] || "th"
            return num + suffix + " day";
        },
        createOrUpdate(){
            if(this.form.days && this.form.color_code){
                Object.assign(this.form,{active:1});
                this.$store.dispatch(this.dispatch,this.form);
            }else{
                Swal.fire({
                    text:'Days & Color Code is required',
                    icon:'warning',
                    confirmButtonColor:'#397373'
                })
            }
        },
        closeDialog(){
            Object.assign(this.$data, this.$options.data.call(this))
            this.$store.commit('DIALOG',false);
            this.$store.commit("ACTION", '');
            this.insertDates(1)
        }
    },
    watch:{
        GET_NEW_CHEQUE_SETTINGS:{
            handler(val){
                if(val){
                    Swal.fire({
                        text:'Saved',
                        icon:'success',
                        confirmButtonColor:'#397373'
                    })
                    this.closeDialog()
                }
            }
        },
    }
}
</script>