<template>
    <v-container fluid>
        <v-card elevation="0">
            <v-card-title>
                <v-row class="mx-auto">
                    <v-col sm="12" md="12" lg="3" class="mx-1">
                        <v-autocomplete
                            :items="item_type_selection"
                            v-model="filter"
                            label="Item Types"
                            dense
                            outlined
                            append-icon="mdi-magnify"
                            auto-select-first
                            clearable
                            @change="getAllRfs()"
                        ></v-autocomplete>
                    </v-col>
                    <v-spacer />
                    <v-col sm="12" md="12" lg="2" class="mr-2">
                        <v-text-field
                            v-model="search"
                            label="Search RF# / RD#"
                            clearable
                            single-line
                            hide-details
                            @keydown.enter="searchInput()"
                            @click:clear="search=''; searchInput()"
                        ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="12" lg="2" class="mr-3">
                        <v-text-field
                            v-model="searchItem"
                            label="Search  Item Model / Name"
                            clearable
                            single-line
                            hide-details
                            append-outer-icon="mdi-magnify"
                            @keydown.enter="searchInput()"
                            @click:append-outer="searchInput()"
                            @click:clear="searchItem=''; searchInput()"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-card-text>
                <div>
                    <label for="">{{ rfStatus }} Count: </label>
                    <span style="font-weight:bold">{{ totalItems }}</span>
                </div>
                <v-data-table
                    :items="dataTable.defective"
                    :headers="defHeaders"
                    :options.sync="dataTable.options"
                    :search="search"
                    :server-items-length="totalItems"
                    :loading="!loaded"
                    dense
                >
                    <template v-slot:[`item.created_at`]="{ item }">
                        <td>{{ agingDate(item.created_at) }}</td>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn icon text @click="viewDefective('View',item)" color="orange" small>
                            <v-icon small >mdi-eye</v-icon>
                        </v-btn>
                        <!-- <v-btn icon text @click="editDefective(item)" color="blue" small>
                            <v-icon small>mdi-square-edit-outline</v-icon>
                        </v-btn>
                        <v-btn icon text @click="deleteDefective(item)" color="red" small>
                            <v-icon small>mdi-delete</v-icon>
                        </v-btn> -->
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import ServiceFunctionsComponentVue from '@/views/main/Shared/ServiceFunctionsComponent.vue';
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2'
export default {
    mixins:[
        ServiceFunctionsComponentVue
    ],
    data() {
        return {
            search: "",
            filter: "",
            loaded: false,
            totalItems:0,
            item_type_selection: [],
            dataTable: {
                defectiveHeaders: [
                    { text: 'RF#', value: 'rf_number' },
                    { text: 'RD#', value: 'sc_receiving_report.rr_number' },
                    { text: 'Item Model', value: 'sc_repair_form_item.item.model' },
                    { text: 'Item Name', value: 'sc_repair_form_item.product_name' },
                    { text: 'Serial#', value: 'sc_repair_form_item.serial' },
                    { text: 'Complaint/Symptom', value: 'sc_repair_form_item.defect_description' },
                    { text: 'Warranty Type', value: 'sc_repair_form_item.warranty_type' },
                    { text: 'Status', value: 'status' },
                    { text: 'Crate#', value: 'crate_number' },
                    { text: 'Loc#', value: 'location_number' },
                    { text: 'Date', value: 'date' },
                    { text: 'Days', value: 'created_at' },
                    { text: 'Action', value: 'actions' },
                ],
                defective: [],
                options:{},
            },
            searchItem:'',
        }

    },

    computed: {
        ...mapGetters([
            'GET_DEFECTIVE',
            'GET_DEFECTIVE_TAB',
            'GET_DEFECTIVE_DISPATCH',
            'GET_DEFECTIVE_DIALOG_TITLE',
            'DIALOG',
            'ACTION',
            'GET_CHECKLIST_DIALOG_VIEW',
            'GET_REFRESH_DEFECTIVE',
            'GET_ITEM_TYPES',
        ]),

        defHeaders() {
            if(this.GET_DEFECTIVE_TAB == 'DTA'){
                this.$store.commit('REFRESH_DEFECTIVE', true)

                return this.dataTable.defectiveHeaders
            } else if (this.GET_DEFECTIVE_TAB == 'DCA'){
                this.$store.commit('REFRESH_DEFECTIVE', true)

                return this.dataTable.defectiveHeaders
            } else {
                return this.dataTable.defectiveHeaders
            }
        },

    },

    methods: {
        async getAllRfs() {
            this.loaded = false
            this.$store.commit('REFRESH_DEFECTIVE', true)
            this.dataTable.defective = [];
            let payload = {
                status                       : this.rfStatus,
                itemsPerPage                 : this.dataTable.options.itemsPerPage,
                page                         : this.dataTable.options.page,
                search                       : this.search,
                activeTab                    : this.GET_DEFECTIVE_TAB,
                filter                       : this.filter,
                searchItem                   : this.searchItem,
            }
            await this.$store.dispatch(this.GET_DEFECTIVE_DISPATCH.get,payload).then(response => {
                this.dataTable.defective              = response.rfs.data.filter(e => {
                    e.date = this.$dayjs(e.created_at).format('YYYY-MM-DD, hh:mm:ss')
                    return e
                });
                this.totalItems             = response.rfs.total
                this.loaded                 = true
                this.$store.commit('REFRESH_DEFECTIVE', false)
            }).catch( err =>{
                console.log(err)
                Swal.fire('Error!','- an error occured','error')
            });
        },

        searchInput(){

            this.getAllRfs({
                page:               this.dataTable.options.page = 1,
                itemsPerPage:       this.dataTable.options.itemsPerPage,
                search:             this.search,
                status:             this.rfStatus,
                searchItem:         this.searchItem,
            });
        },

        viewDefective(action = 'View', item){
            this.$store.commit('REFRESH_DEFECTIVE', true)
            this.$store.commit('DEFECTIVE_DIALOG_VIEW', item)
            this.$store.commit('DIALOG', true)
            this.$store.commit('ACTION', action)

        },
    },

    props: ['rfStatus'],

    watch: {
        rfStatus:{
            handler(val){
                this.getAllRfs();
            }

        },

        'dataTable.options': {
            handler(val){
                this.getAllRfs();
            },
            deep: true,
        },

        GET_REFRESH_DEFECTIVE:{
            handler(val){
                if(val == true){
                    this.getAllRfs()
                }

            }
        },
    },

    mounted() {
        this.getAllRfs();
        this.$store.dispatch('getItemTypes').then((response)=>{
          this.item_type_selection = response.data.filter(e => {return e.text != 'SP'})
        })
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
    },
}
</script>

<style scoped>

</style>