<template>
    <v-container fluid>
        <v-card elevation="0">
            <v-card-title>
                <v-col sm="6" md="2" id="date_from">
                        <v-menu
                            v-model="menu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="sales_quotation_dates[0]"
                                    v-on="on"
                                    dense
                                    outlined
                                    hide-details
                                    readonly
                                    append-icon="mdi-calendar-clock"
                                    label="Date From"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="sales_quotation_dates[0]" @input="getDatePicker;menu2 = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col sm="6" md="2" id="date_to">
                        <v-menu
                            v-model="menu3"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="sales_quotation_dates[1]"
                                    v-on="on"
                                    dense
                                    outlined
                                    hide-details
                                    readonly
                                    append-icon="mdi-calendar-clock"
                                    label="Date To"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="sales_quotation_dates[1]" @input="getDatePicker;menu3 = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col v-if="switchItem == true" sm="6" md="2">
                        <v-autocomplete
                            v-model="filterWarehouse"
                            outlined
                            dense
                            label="Select Warehouse"
                            clearable
                            hide-details
                            :items="warehouseDropdown"
                            item-value="value"
                            item-text="text"
                            @change="getAllItemTable()"
                        />
                    </v-col>
                    <v-col>
                        <v-tooltip bottom color="#f69f1a">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    @click="getDataBySwitch()"
                                    fab
                                    text
                                    small
                                    color="#f69f1a"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon>mdi-filter</v-icon>
                                </v-btn>

                            </template>
                            <span>Filter</span>
                        </v-tooltip>
                    </v-col>
                <v-spacer />
                <v-switch
                    v-model="switchItem"
                    :label="switchItem == true ? `Per Item` : `Per ${isActive_tab}`"
                ></v-switch>
            </v-card-title>

            <v-card-text>
                <v-row>
                    <v-col class="pl-3" cols="6">
                        <label for="">{{ isActive_tab }} <span v-if="switchItem == true">Item</span> Count: </label>
                        <span style="font-weight:bold">{{ switchItem == true ? totalSpItems : totalItems }}</span>
                    </v-col>
                    <v-spacer />
                    <v-col sm="12" md="2" class="mr-3 align-items-end">
                        <v-text-field
                            v-model="search"
                            dense
                            :label="labelForSearch()"
                            single-line
                            hide-details
                            append-icon="mdi-magnify"
                            class="pb-5"
                            @keydown.enter="getDataBySwitch()"
                            @click:append="getDataBySwitch()"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-data-table
                    :items="spAreaItems"
                    :headers="spAreaHeaders"
                    :options.sync="dataTable.options"
                    :search="search"
                    :server-items-length="switchItem == true ? totalSpItems : totalItems"
                    :loading="!loaded"
                    dense
                >

                    <template v-slot:[`item.customer_name`]="{ item }">
                        <span style="font-size:12px !important;">
                            {{ item.customer_name }}
                        </span>
                    </template>

                    <template v-slot:[`item.total_amount`]="{ item }">
                        <span class="text-nowrap">
                            &#x20b1;{{ item.total_amount | currency }}
                        </span>
                    </template>

                    <template v-slot:[`item.created_at`]="{ item }">
                        <span class="text-nowrap">
                            {{ item.created_at | formatDateTime }}
                        </span>
                    </template>

                    <template v-slot:[`item.status_text`]="{ item }">
                        <span class="badge badge-saved" v-if="item.status_text == 'saved'">{{ item.status_text }}</span>
                        <span class="badge badge-for-sales" v-if="item.status_text == 'for sales'">{{ item.status_text }}</span>
                        <span class="badge badge-for-accounting" v-if="item.status_text == 'for accounting'">{{ item.status_text }}</span>
                        <span class="badge badge-approved" v-else-if="item.status_text == 'approved'">{{ item.status_text }}</span>
                        <span class="badge badge-completed" v-else-if="item.status_text == 'completed'">{{ item.status_text }}</span>
                        <span class="badge badge-warning" v-else-if="item.status_text == 'rejected'">{{ item.status_text }}</span>
                        <span class="badge badge-danger" v-else-if="item.status_text == 'canceled'">{{ item.status_text }}</span>
                        <span class="badge badge-dark" v-else-if="item.status_text == 'deleted'">{{ item.status_text }}</span>
                        <span class="badge badge-for-scheduling" v-else-if="item.status_text == 'for scheduling'">{{ item.status_text }}</span>
                        <span class="badge badge-purple" v-else-if="item.status_text == 'converted'">{{ item.status_text }}</span>
                        <span class="badge badge-exec-approved" v-else-if="item.status_text == 'exec approved'">{{ item.status_text }}</span>
                        <span class="badge badge-pending" v-else-if="item.status_text == 'partial'">{{ item.status_text }}</span>
                        <span class="badge badge-for-exec" v-else-if="item.status_text == 'for exec approval'">{{ item.status_text }}</span>
                        <span class="badge badge-warning" v-else-if="item.status_text == 'stockcard'">{{ item.status_text }}</span>
                        <!-- <span class="badge badge-for-credit-limit" v-else-if="item.status_text=='for credit limit'">{{ item.status_text }}</span> -->
                        <!-- <span class="badge badge-for-credit-terms" v-else-if="item.status_text=='for credit terms'">{{ item.status_text }}</span> -->
                        <span class="badge badge-submitted" v-else-if="item.status_text == 'waiting for stocks'">{{ item.status_text }}</span>
                        <span class="badge badge-preparing" v-else-if="item.status_text == 'preparing'">{{ item.status_text }}</span>
                        <span class="badge badge-prepared" v-else-if="item.status_text == 'prepared'">{{ item.status_text }}</span>
                        <span class="badge badge-checking" v-else-if="item.status_text == 'checking'">{{ item.status_text }}</span>
                        <span class="badge badge-checked" v-else-if="item.status_text == 'checked'">{{ item.status_text }}</span>
                        <span class="badge badge-invoiced" v-else-if="item.status_text == 'invoiced'">{{ item.status_text }}</span>
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <v-btn icon text @click="viewSqArea('View',item)" color="orange" small>
                            <v-icon small >mdi-eye</v-icon>
                        </v-btn>
                        <!-- <v-btn icon text @click="editDefective(item)" color="blue" small>
                            <v-icon small>mdi-square-edit-outline</v-icon>
                        </v-btn>
                        <v-btn icon text @click="deleteDefective(item)" color="red" small>
                            <v-icon small>mdi-delete</v-icon>
                        </v-btn> -->
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2'
export default {
    data() {
        return {
            search: "",
            filter: "",
            loaded: false,
            totalItems:0,
            totalSpItems:0,
            item_type_selection: [],
            dataTable: {
                spHeaders: [
                    { text: 'Quote Num', value: 'quote_num' },
                    { text: 'Customer', value: 'customer_name' },
                    // { text: 'PO#', value: 'customer_po_num' },
                    { text: 'SQ Item Quantity', value: 'quantity' },
                    { text: 'Remarks', value: 'remarks' },
                    // { text: 'Sales', value: 'salesman_name' },
                    { text: 'Amount', value: 'total_amount' },
                    { text: 'Status', value: 'status_text' },
                    // { text: 'Date Issued', value: 'created_at' },
                    // { text: 'Issuer', value: 'issuer_name' },
                    // { text: 'Promo', value: 'promo_id' },
                    // { text: 'SC', value: 'stock_card' },
                    // { text: 'Override', value: 'override_amount_approval' },
                    { text: 'Action', value: 'actions' },
                ],
                soHeaders: [
                    { text: 'Order Num', value: 'order_num' },
                    { text: 'Quote Num', value: 'sq_quote_num' },
                    { text: 'Customer', value: 'so_customer_name' },
                    // { text: 'PO#', value: 'customer_po_num' },
                    { text: 'SO Item Quantity', value: 'quantity' },
                    { text: 'Remarks', value: 'so_remarks' },
                    // { text: 'Sales', value: 'salesman_name' },
                    { text: 'Amount', value: 'total_amount' },
                    { text: 'Status', value: 'status_text' },
                    // { text: 'Date Issued', value: 'created_at' },
                    // { text: 'Issuer', value: 'issuer_name' },
                    // { text: 'Promo', value: 'promo_id' },
                    // { text: 'SC', value: 'stock_card' },
                    // { text: 'Override', value: 'override_amount_approval' },
                    { text: 'Action', value: 'actions' },
                ],
                spItemHeaders: [
                    // { text: 'Quote Num', value: 'quote_num' },
                    { text: 'Parts', value: 'model' },
                    { text: 'SQ Quantity', value: 'quantity' },
                    { text: 'Current Stocks', value: 'stocks' },
                    { text: 'Ideal Stocks', value: 'ideal_stocks' },
                    { text: 'Action', value: 'actions' },
                ],
                soItemHeaders: [
                    // { text: 'Quote Num', value: 'quote_num' },
                    { text: 'Parts', value: 'model' },
                    { text: 'SO Quantity', value: 'quantity' },
                    { text: 'Current Stocks', value: 'stocks' },
                    { text: 'Ideal Stocks', value: 'ideal_stocks' },
                    { text: 'Action', value: 'actions' },
                ],
                options:{},
            },
            spData: [],
            spItemData: [],
            switchItem: false,
            sales_quotation_dates: [
                this.$dayjs().startOf('year').format('YYYY-MM-DD'),
                this.$dayjs().endOf('month').format('YYYY-MM-DD'),
            ],
            menu2: false,
            menu3: false,
            filterWarehouse: 1,
            component: null,
            warehouseDropdown: [],
        }

    },

    computed: {
        ...mapGetters([
            'DIALOG',
            'ACTION',
            'GET_SP_AREA_DIALOG_VIEW',
            'GET_SPARE_PARTS_STAGING_AREA',
            'GET_REFRESH_SP_AREA',
            'GET_SPARE_PARTS_AREA_TAB',
            'GET_SP_AREA_DISPATCH',
            'GET_SP_AREA_DIALOG_TITLE',
            'GET_ALL_WAREHOUSES',
        ]),

        spAreaHeaders() {
            if(this.GET_SPARE_PARTS_AREA_TAB == 'SQ'){
                this.$store.commit('REFRESH_SP_AREA', true)

                if(this.switchItem == true) {
                    return this.dataTable.spItemHeaders
                } else {
                    return this.dataTable.spHeaders
                }
            } else if (this.GET_SPARE_PARTS_AREA_TAB == 'SO'){
                this.$store.commit('REFRESH_SP_AREA', true)

                if(this.switchItem == true) {
                    return this.dataTable.soItemHeaders
                } else {
                    return this.dataTable.soHeaders
                }
            }
        },

        spAreaItems() {
            if(this.switchItem == true) {
                return this.spItemData
            } else {
                return this.spData
            }
        }

    },

    methods: {
        async getAllTable() {
            this.spData = [];
            this.loaded = false
            this.$store.commit('REFRESH_SP_AREA', true)
            await this.$store.dispatch('serviceGet',{
                url                     : this.GET_SP_AREA_DISPATCH.get[0],
                itemsPerPage            : this.dataTable.options.itemsPerPage,
                page                    : this.dataTable.options.page,
                search                  : this.search,
                activeTab               : this.GET_DEFECTIVE_TAB,
                filter                  : this.filter,
                sales_quotation_dates   : this.sales_quotation_dates,
            }).then(response => {
                this.spData                 = response.data.data.data
                this.totalItems             = response.data.data.total
                this.loaded                 = true
                this.$store.commit('REFRESH_SP_AREA', false)
            }).catch( err =>{
                console.log(err)
                Swal.fire('Error!','- an error occured','error')
            });
        },

        async getAllItemTable() {
            this.spItemData = [];
            this.loaded = false
            this.$store.commit('REFRESH_SP_AREA', true)
            await this.$store.dispatch('serviceGet',{
                url                     : this.GET_SP_AREA_DISPATCH.get[1],
                itemsPerPage            : this.dataTable.options.itemsPerPage,
                page                    : this.dataTable.options.page,
                search                  : this.search,
                activeTab               : this.GET_DEFECTIVE_TAB,
                filter                  : this.filter,
                sales_quotation_dates   : this.sales_quotation_dates,
                filter_warehouse        : this.filterWarehouse,
            }).then(response => {
                this.spItemData             = response.data.data.data
                this.totalSpItems           = response.data.data.total
                this.loaded                 = true
                this.$store.commit('REFRESH_SP_AREA', false)
            }).catch( err =>{
                console.log(err)
                Swal.fire('Error!','- an error occured','error')
            });
        },

        getDataBySwitch() {
            if(this.switchItem == true) {
                this.getAllItemTable()
            } else {
                this.getAllTable()
            }
            this.$store.commit('SEARCH_DATA', this.search);
        },

        viewSqArea(action = 'View', item){
            this.$store.commit('SWITCH_ITEM', this.switchItem)
            this.$store.commit('FILTER_DATE', this.sales_quotation_dates)
            this.$store.commit('SP_AREA_DIALOG_VIEW', item)
            this.$store.commit('DIALOG', true)
            this.$store.commit('ACTION', action)
        },

        getDatePicker() {
            this.$store.commit('FILTER_DATE', this.sales_quotation_dates);
        },

        labelForSearch() {
            if(this.GET_SPARE_PARTS_AREA_TAB == 'SQ') {
                if(this.switchItem == true) {
                    return `Search Parts`;
                } else {
                    return `Search ${this.isActive_tab}# / Remarks`;
                }
            } else if (this.GET_SPARE_PARTS_AREA_TAB == 'SO') {
                if(this.switchItem == true) {
                    return `Search Parts`;
                } else {
                    return `Search ${this.isActive_tab}# / SQ# / Remarks`;
                }
            }
        },
    },

    props: ['isActive_tab'],

    watch: {
        'isActive_tab':{
            handler(val){
                this.getAllItemTable();
                this.getAllTable();
            }

        },

        'dataTable.options': {
            handler(val){
                this.getAllItemTable();
                this.getAllTable();
            },
            deep: true,
        },

        'switchItem' : {
            handler(val) {
                this.search = '';
                if(val == true) {
                    this.getAllItemTable();
                } else {
                    this.getAllTable();
                }
            }
        },

        'sales_quotation_dates': {
            handler(val) {
                this.$store.commit('FILTER_DATE', val);
            }
        },

        GET_REFRESH_SP_AREA: {
            handler(val){
                if(val == true) {
                }
            }
        },

        GET_SP_AREA_DIALOG_VIEW: {
            handler(val) {
            }
        },
    },

    mounted() {
        this.$store.dispatch('getAllWarehouses').then(response =>
           this.warehouseDropdown = response.data.filter(e => {return e.service != 0})
        )
        this.$store.commit('FILTER_DATE', this.sales_quotation_dates);
    },
}
</script>

<style scoped>

</style>