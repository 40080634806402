<template>
    <v-card>

        <v-container>
            
            <v-row>
                <v-col cols="12" lg="12">
                    <v-card-title class="d-flex justify-content-between pa-0 border-bottom border-right">
                        <span>Pending</span>
                        <span class="d-flex justify-content-end">
                            <v-text-field v-model="search" outlined dense label="Search Migration Name">
                        <template #append>
                            <v-btn icon text small>
                                <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                        </template>
                        </v-text-field>
                        <span class=""><v-btn icon @click="getAllMigrationsPendingList()"><v-icon color="primary">mdi-refresh</v-icon></v-btn></span></span>
                    </v-card-title>
                <v-data-table
                v-model="form.migration_list_pending_selected"
                :headers="headers"
                :items="migration_list_pending"
                :search="search"
                :items-per-page="5"
                show-select
                item-key="text"
                ></v-data-table>
                </v-col>
            </v-row>
            <v-expand-transition>
            <v-row v-show="terminal" class="box">
                <v-col cols="12" lg="12">
                    <v-card width="100%"  >
                        <v-card-title class="d-flex justify-content-end pa-0 bg-dark">
                             <span class=""><v-btn icon @click="hideterminal()"><v-icon color="primary">mdi-menu-up-outline</v-icon></v-btn></span>
                          </v-card-title>
                        <v-card-text style="height:150px; overflow: auto;" class="bg-dark">
                            <pre v-for="(item,i) in migration_text_result" :key="i" style="font-size:10px; color:greenyellow;" >{{ item }}</pre>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-expand-transition>
            <v-container class="pa-5 border-top">
                <v-row :class="`d-flex justify-content-${terminal?'end':'between'} pa-0`">
                    <v-btn small dense @click="showTerminal()" :loading="isLoading" v-if="!terminal">
                        show terminal
                    </v-btn>
                    <v-btn small dense @click="migrate()" :loading="isLoading">
                        MIGRATE
                    </v-btn>
                </v-row>
            </v-container>
        </v-container>

    </v-card>
</template>

<script>
import swal from 'sweetalert2';
import { mapGetters } from 'vuex'
  export default {
    data () {
      return {
        form:{
            migration_list_pending_selected:[]
        },
        migration_text_result:[],
        migration_list_pending:[],
        isLoading:false,
        isCheck:false,
        terminal:false,
        search:'',
        headers:[{
                text:'Migration Name',
                align:'left',
                value:'text'
            }]
      }
    },
    computed:{
       ...mapGetters(['GET_MIGRATION_PENDING_LIST'])
    },
    created(){
      
    },
    mounted(){
        this.getAllMigrationsPendingList()
    },
    methods:{
     migrate(){
        this.isLoading = true;
        this.$store.dispatch('postMigrationPendingList',this.form).then(response=>{
           this.migration_text_result = response
           this.isLoading = false;
           this.terminal = true
           this.getAllMigrationsPendingList()
        }).catch(err=>{
            this.isLoading = false;
        });
     },

     getAllMigrationsPendingList(){
        this.migration_list_pending = []
        this.isCheck = false
        this.form.migration_list_pending_selected = []
        this.$store.dispatch('getMigrationPendingList').then(response=>{
            this.migration_list_pending = response
        })
     },
     checkAll(evt){
        this.isCheck = evt
        this.form.migration_list_pending_selected = []
        if(evt){
            this.migration_list_pending.forEach(e=>{
                this.form.migration_list_pending_selected.push(e.text)
            })
        }
        else{
            this.form.migration_list_pending_selected = []
        }
     },
     checkItem(item){
        this.isCheck = false
        if(!this.form.migration_list_pending_selected.find(e=>e.text == item.text))
        {
            this.form.migration_list_pending_selected.push(item.text)
        }else{
            let index = this.form.migration_list_pending_selected.indexOf(item.text)
            this.form.migration_list_pending_selected.splice(index,1);
        }
     },
     showTerminal(){
        this.terminal = true
     },
     hideterminal(){
        this.terminal = false
     },
    },
    watch:{

    }
  }
</script>
