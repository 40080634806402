<template>
<v-container fluid>
        <ComponentHeader
            class="ma-n4"
            :button="true"
            :title="'Order Item With Stocks'"
            :type="'Inventory'"
        ></ComponentHeader>
    <v-row>
        <v-app id="my_vue_app" style="width:100%" class="ml-3 mr-3 bg-bluish-gray pa-3">
            <div class="row">
                    <div class="col-sm-12 col-md-2">
                    <v-autocomplete
                        :items="GET_ITEM_TYPES"
                        :loading="loading.item_type_selection"
                        :disabled="loading.item_type_selection"
                        v-model="type_id"
                        label="Select Types"
                        class="d-inline-block w-100"
                        @change="selectedType"
                        dense
                        outlined
                        background-color="white"
                        return-object
                        multiple
                        small-chips
                        deletable-chips
                    ></v-autocomplete>
                </div>
                <div class="col-sm-12 col-md-2">
                    <v-autocomplete
                        :items="GET_ITEM_CATEGORIES"
                        :loading="loading.item_category_selection"
                        :disabled="loading.item_category_selection"
                        v-model="category_id"
                        label="Select Categories"
                        class="d-inline-block w-100"
                        @change="selectedCategory"
                        dense
                        outlined
                        background-color="white"
                        return-object
                        multiple
                        small-chips
                        deletable-chips
                    >
                    </v-autocomplete>
                </div>
                <div class="col-sm-12 col-md-2">
                    <v-autocomplete
                        v-model="item_id"
                        :items="GET_ITEM_SELECTION_DEFAULT"
                        :loading="loading.item_selection"
                        :disabled="loading.item_selection"
                        label="Select Model"
                        class="d-inline-block w-100"
                        @change="selecteditem"
                        dense
                        outlined
                        background-color="white"
                        return-object
                        multiple
                        small-chips
                        deletable-chips
                    ></v-autocomplete>
                </div>
                <div class="col-sm-12 col-md-6 text-right">
                    <v-btn small class="ml-1" v-on:click="resetFields()" :loading="!loaded">Clear</v-btn>
                    <v-btn small class="ml-1" v-on:click="exportToExcel()" :loading="!loaded">Excel</v-btn>
                    <v-btn small class="ml-1" v-on:click="getAllItems()" :loading="!loaded">Filter</v-btn>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 col-md-2" id="date_from">
                    <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                        class="pa-2"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="date_from"
                                readonly
                                append-icon="mdi-calendar-clock"
                                v-on="on"
                                background-color="white"
                                label="Date From"
                                outlined
                                dense
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="date_from" @input="menu2 = false"></v-date-picker>
                    </v-menu>
                    <span v-for="errors in errors.date_from" class="text-warning" v-bind:key="errors">{{errors}}</span>
                </div>
                <div class="col-sm-6 col-md-2" id="date_to">
                    <v-menu
                        v-model="menu3"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="date_to"
                                readonly
                                append-icon="mdi-calendar-clock"
                                v-on="on"
                                background-color="white"
                                label="Date To"
                                outlined
                                dense
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="date_to" @input="menu3 = false"></v-date-picker>
                    </v-menu>
                    <span v-for="errors in errors.date_to" class="text-warning" v-bind:key="errors">{{errors}}</span>
                </div>
            </div>
            <div class="w-100 mt-2" v-if="items.length > 0">
                <v-card v-show="loaded">
                    <v-container fluid>
                        <v-data-table
                            :headers="headers"
                            :items="items"
                            :search="search"
                            :options.sync="options"
                            :server-items-length="totalItems"
                            :items-per-page="10"
                            :expanded.sync="expanded"
                            :footer-props="{
                                itemsPerPageOptions: itemsPerPageOptions
                            }"
                            class="w-100"
                            dense
                        >

                        <template v-slot:[`item.selling_price`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.selling_price | currency }}
                            </span>
                        </template>

                        <template v-slot:[`item.sp_price`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.sp_price | currency }}
                            </span>
                        </template>

                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length" class="p-0">
                                <v-simple-table dense dark >
                                    <template v-slot:default>
                                        <thead>
                                            <th style="font-size:10px;width:4%;padding:0 8px">Customer</th>
                                            <th style="font-size:10px;width:4%;padding:0 8px">Oldest</th>
                                            <th style="font-size:10px;width:4%;padding:0 8px">Latest</th>
                                            <!--<th>SO#</th>-->
                                            <th style="font-size:10px;width:4%;padding:0 8px">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <span
                                                        dark
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        >
                                                        TSO
                                                        </span>
                                                    </template>
                                                    <span>TOTAL SO</span>
                                                </v-tooltip>
                                            </th>
                                            <th style="font-size:10px;width:4%;padding:0 8px">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <span
                                                        dark
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        >
                                                        SSO
                                                        </span>
                                                    </template>
                                                    <span>SERVED SO</span>
                                                </v-tooltip>
                                            </th>
                                                        <th style="font-size:10px;width:4%;padding:0 8px">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <span
                                                        dark
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        >
                                                        PSO
                                                        </span>
                                                    </template>
                                                    <span>PENDING SO</span>
                                                </v-tooltip>
                                            </th>
                                            <td style="font-size:10px;width:4%;padding:0 8px" v-for="(wh, w) in item.warehouseItemStocks" :key="w"><center><strong>{{ wh.warehouse_code }}</strong></center></td> <!-- add foreach-->
                                        </thead>
                                        <tbody>
                                            <tr v-for="(salesOrder, i) in item.salesOrders" :key="i" @click="redirect(salesOrder.id)" style="cursor:pointer">
                                                <td style="font-size:10px;width:4%;padding:0 8px">{{ salesOrder.company_name }}</td>
                                                <td style="font-size:10px;width:4%;padding:0 8px">{{ salesOrder.oldest_date | formatDate }}</td>
                                                <td style="font-size:10px;width:4%;padding:0 8px">{{ salesOrder.latest_date | formatDate }}</td>
                                                <!--<td>{{ salesOrder.order_num }}</td>-->
                                                <td>{{ salesOrder.item_qty }}</td>
                                                <td>{{ salesOrder.quantity_served }}</td>
                                                <td>{{ parseInt(salesOrder.item_qty) - (parseInt(salesOrder.quantity_served) + parseInt(salesOrder.quantity_canceled)) }}</td>
                                                <!-- <td>{{ salesOrder.quantity_canceled }}jj</td> -->
                                                <td style="font-size:10px;width:4%;padding:0 8px" v-for="(wh, w) in item.warehouseItemStocks" :key="w"><center>{{ wh.quantity | numberNoDecimal}}</center></td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </td>
                        </template>
                        </v-data-table>
                    </v-container>
                </v-card>
            </div>
        </v-app>
        <v-app id="load_bar" v-if="!loaded" style="width:100%" class="ml-3 mr-3">
            <v-text-field color="info" loading disabled></v-text-field>
        </v-app>
            <v-dialog v-model="loading.dialog" width="500">
                <v-card>
                    <v-card-title >
                        <span style="font-size:12px">
                            Processing: {{ loading.text }}
                        </span>
                    </v-card-title>
                    <v-card-text>
                        <v-progress-linear
                            v-model="loading.value"
                            :buffer-value="loading.bufferValue"
                            color="green"
                        >
                        </v-progress-linear>
                        {{Math.ceil(loading.value)}}%
                    </v-card-text>
                </v-card>
            </v-dialog>

    </v-row>
</v-container>

</template>

<script>
import swal from "sweetalert2";
import { mapGetters } from 'vuex';
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'

export default {
    data(){
        return {
            expanded: [],
            errors: [],
            loaded: true,

            date_from: this.$dayjs().startOf('month').format('YYYY-MM-DD'),
            date_to: this.$dayjs().endOf('month').format('YYYY-MM-DD'),

            item_list: [],
            item_names: [],
            item_id: [],
            brand_id: '',
            brand_list: [],
            type_list: [],
            type_id: [],
            category_list: [],
            category_id: [],

            row: null,
            menu2: false,
            menu3: false,

            totalItems: 0,
            options: {},
            items: [],
            itemsPerPageOptions: [5, 10 ,15, 50, 100, 250],
            itemsPerPage: 10,
            search: '',
            awaitingSearch: false,
            headers: [
                { text: 'Model Name', align: 'left', value: 'model', },
                { text: 'Name', align: 'left', value: 'name', },
                { text: 'Category', align: 'left', value: 'categories', },
                { text: 'UOM', align: 'left', value: 'uom', },
                { text: 'Type', align: 'left', value: 'type', },
                { text: 'Total Pending SO', align: 'right', value: 'pending_so_qty', },
                { text: 'Available Stocks', align: 'right', value: 'total_stocks', },
            ],
            json_data: [],
            json_meta: [
                [
                    {
                        'key': 'charset',
                        'value': 'utf-8'
                    }
                ]
            ],
            customer:{
                selection:[],
                selected:''
            },
            classification:{
                selection:[],
                selected:[]
            },
            loading: {
                text: '',
                bufferValue: 100,
                value: 0,
                dialog:false,

                item_selection              : false,
                item_category_selection     : false,
                item_type_selection         : false
            },
            active:'1',
        }
    },
    components: {
        ComponentHeader
    },
    mounted: function (){
        this.$store.dispatch('getAllitemSelectionDefault')
        this.$store.dispatch('getItemTypes')
        this.$store.dispatch('getItemCategories')
    },
    methods: {
        selecteditem(val){
            this.item_id = val
        },

        selectedCategory(val){
            this.category_id = val
        },

        selectedType(val){
            this.type_id = val
        },

        selectedClassification(val){
            this.classification.selected = val
        },
        getAllItems(){
            this.loaded = false
            this.expanded = []

            this.$store.dispatch('reportsPost',{

                    page:               this.options.page,
                    itemsPerPage:       this.options.itemsPerPage,
                    item_id:            this.item_id.map(e=>{return e.value}),
                    date_from:          this.date_from,
                    date_to:            this.date_to,
                    type_id:            this.type_id.map(e=>{return e.value}),
                    category_id:        this.category_id.map(e => { return e.value }),
                    paginate: true,
                    export : false,
                    url:'sales-order/item/with-stocks/export'

            }).then(response => {
                this.items        = response.data.data
                this.loaded       = true
                this.totalItems   = response.data.total
            })
        },
        thousandSeprator(amount) {
            if (amount !== '' || amount !== undefined || amount !== 0 || amount !== '0' || amount !== null) {
                return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else {
                return amount;
            }
        },
        resetFields(){
            this.errors                 = []
            this.date_from              = ''
            this.date_to                = ''
            this.item_id                = ''
            this.expanded               = []
            this.item_id                = []
            this.category_id            = []
            this.customer.selected      = ''
            this.classification.selected = []
            this.type_id = this.type_id = this.type_list.filter(e=>{
                    return e.value != 3
                })
            this.active = '1'
        },

        async exportToExcel() {
            let that = this
            const workbook = new this.$exceljs.Workbook();
            const worksheet = workbook.addWorksheet('Order Item With Stocks')

            this.loaded = false

            let headers = [
                { header:'Model',key:'model' },
                { header:'Name',key:'name' },
                { header:'Categories',key:'categories' },
                { header:'Type',key:'type' },
                { header:'Uom',key:'uom' },
                { header:'Pending SO',key:'pending_so_qty' },
                { header:'Total Stocks',key:'total_stocks' },

            ]

            await this.$store.dispatch('reportsPost', {

                types:        this.type_id.map(e=>{return e.value}),
                categories:   this.category_id.map(e => { return e.value }),
                items:        this.item_id.map(e=>{return e.value}),
                date_to:      this.date_to,
                date_from:    this.date_from,
                export:        true,
                url:          'sales-order/item/with-stocks/export'

            }).then(response => {
                worksheet.columns = headers
                response.data.data.forEach((e, i ) => {
                    worksheet.addRow(e);
                    // Pending SO
                    worksheet.getCell(`F${i + 2}`).value = parseInt(e.pending_so_qty)
                    worksheet.getCell(`F${i + 2}`).numFmt = '#,##0'

                    // Total Stocks
                    worksheet.getCell(`G${i + 2}`).value = parseInt(e.total_stocks)
                    worksheet.getCell(`G${i + 2}`).numFmt = '#,##0'
                });
                // items = response.data.map(e => {
                //     return e.id
                // })

                // data = response.data
            })
            this.loaded = true

            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                this.$filesaver.saveAs(blob, `Order Items With Stocks ${that.date_from}-${that.date_to}.xlsx`);
            });

            that.loaded = true
            that.loading.dialog = false
            that.loading.value = 0
            swal.fire('Success!','','success')
        }
    },

    computed:{
        ...mapGetters([
            'GET_ITEM_SELECTION_DEFAULT',
            'GET_ITEM_CATEGORIES',
            'GET_ITEM_TYPES'
        ])
    },

    watch:{
        // type_id(val){
        //     this.getItems()
        // }

        'GET_ITEM_SELECTION_DEFAULT':{
            handler(val){
            this.item_list = val
            this.loading.item_selection = false

            }
        },
        'GET_ITEM_CATEGORIES':{
            handler(val){
            this.category_list = val.data
            this.loading.item_category_selection = false

            }
        },
        'GET_ITEM_TYPES':{
          handler(val){
            this.type_list = val.data
            this.loading.item_type_selection = false
          }
        }
    }
};
</script>
<style>
    .col{
        padding: 2px !important;
    }

    .card-body{
        padding: 3px !important;
    }
</style>
