	<template>
		<v-form ref="form" v-model="valid" lazy-validation>
            <v-card>
                <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                    <span class="text-h5">Holiday</span>
                    <v-row class="m-0">
                        <v-col cols="pull-right-10 p-2">
                        <v-btn text icon small color="gray" class="float-right" @click="closeDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                </v-card-text>
                <v-divider></v-divider>
            </v-card>
		</v-form>
	</template>
<script>
	import { mapGetters } from 'vuex'
	export default {
		data() {
		return {
                valid: false,
                form: {
                    code: '',
                    name: '',
                    id:'',
                    date_menu: false,
                    store_branch_ids: [],
                },
                item: '',
                errors: {
                    required: [v => !!v || 'The value is required'],
                    code: [v => !!v || 'The code is required'],
                    name: [v => !!v || 'The name is required'],
                },
                disabled_view:false,
                loading: {
                    button: false,
                }
            }
		},
		computed: {
            ...mapGetters([
                'ACTION',
                'DIALOGS',
                'USERACCOUNT_LOAD',
                'GET_HR_OTHER_TABS_DIALOG_VIEW',
                'GET_NEW_HOLIDAY_TRIGGER',
                'GET_HR_DIALOG_TITLE',
                'GET_HR_DISPATCH',
                'GET_HR_TAB_IS_ACTIVE',
                'GET_COMPANY_SELECTION',
                'GET_HOLIDAY_TYPE_SELECTION',
                'GET_STORE_BRANCHES_WITHOUT_ACCESS',
                'GET_YEAR_RANGE',
            ]),
            actions() {
                if (this.ACTION == 'Submit') {
                    this.disabled_view = false;
                return 'Submit'
                } else if (this.ACTION == 'Update') {
                    this.disabled_view = false;
                    this.getAllDialogViewData();
                return 'Update'
                }else if (this.ACTION == 'View') {
                    this.disabled_view = true;
                    this.getAllDialogViewData();
                return 'View'
                }
            },
		},
		mounted(){
            this.$store.dispatch('getStoreBranchesWithoutAccess');
		},
		methods: {
            closeDialog() {
                this.$store.commit('DIALOG', false);
                this.$store.commit('ACTION', '')
                this.resetfields();
            },
            getAllDialogViewData(){
                this.form       = this.GET_HR_OTHER_TABS_DIALOG_VIEW;
                this.form.id    = this.GET_HR_OTHER_TABS_DIALOG_VIEW.id
                this.form.code  = this.GET_HR_OTHER_TABS_DIALOG_VIEW.code
                this.form.name  = this.GET_HR_OTHER_TABS_DIALOG_VIEW.name

                this.form.date  = this.GET_HR_OTHER_TABS_DIALOG_VIEW.date
                this.form.year  = this.GET_HR_OTHER_TABS_DIALOG_VIEW.year
                this.form.holiday_type_id  = this.GET_HR_OTHER_TABS_DIALOG_VIEW.holiday_type_id
                this.form.provincial  = this.GET_HR_OTHER_TABS_DIALOG_VIEW.provincial
                this.form.store_branch_ids = this.GET_HR_OTHER_TABS_DIALOG_VIEW.store_branch_ids
            },
            resetfields() {
                this.form = {
                    code: '',
                    name: '',
                    id:'',
                    date_menu: false,
                    store_branch_ids: [],
                };
            },
        },
    watch: {
        GET_NEW_HOLIDAY_TRIGGER: {
            handler(val) {
                if(val){
                    this.resetfields()
                    this.$store.commit('DIALOG', false);
                    this.$store.commit("GET_NEW_HOLIDAY_TRIGGER", false);
                }
            }
        },
        DIALOGS:{
            handler(val){
                if(val){
                    this.addHoliday();
                }
            }
        },
    },
};
</script>
