<template>
    <div class="w-100">
        <v-app id="my_vue_app">
            <v-card>
                <v-card-title>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" sm="6" class="d-flex justify-content-start">
                                <v-col cols="12" sm="4" class="pa-0 pr-1">
                                    <v-autocomplete
                                        v-model="filters.company_classification_id"
                                        :items="GET_COMPANY_CLASSIFICATION_SELECTION"
                                        label="Classification"
                                        outlined 
                                        dense
                                        clearable
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="4" class="pa-0 pr-1">
                                    <v-autocomplete
                                        v-model="filters.vendor_id"
                                        :items="GET_ADMIN_SUPPLIER_SELECTION"
                                        label="Vendor"
                                        outlined 
                                        dense
                                        clearable
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="4" class="pa-0 pr-1">
                                    <v-autocomplete
                                        v-model="filters.company_id"
                                        :items="company_data.companies"
                                        label="Company"
                                        outlined 
                                        dense
                                        clearable
                                        @change="storesSelect"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="4" class="pa-0 pr-1">
                                    <v-autocomplete
                                        v-model="filters.store_branch_id"
                                        :items="company_data.branches"
                                        label="Branch"
                                        outlined 
                                        dense
                                        clearable
                                        @change="companies"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="4" class="pa-0">
                                    <v-btn @click="getAllCreditBill()">
                                        FILTER
                                    </v-btn>
                                </v-col>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" class="d-flex justify-content-start">
                                <v-col cols="12" sm="4" class="pa-0 pr-1">
                                    <v-autocomplete
                                        v-model="filters.bank_id"
                                        :items="GET_BANK_SELECTION"
                                        item-value="id"
                                        item-text="name"
                                        label="Bank"
                                        outlined 
                                        dense
                                        clearable
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="4" class="pa-0 pr-1">
                                    <v-autocomplete
                                        v-model="filters.bank_card_id"
                                        :items="GET_BANK_CARD_SELECTION"
                                        label="Bank Card"
                                        outlined 
                                        dense
                                        clearable
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="4" class="pa-0 pr-1">
                                    <v-autocomplete
                                        v-model="filters.prepared_by"
                                        :items="GET_PREPARED_BY"
                                        label="Prepared By"
                                        outlined 
                                        dense
                                        clearable
                                    ></v-autocomplete>
                                </v-col>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-spacer/>
                            <v-col cols="2" class="pa-0 pr-4">
                                <v-text-field
                                    v-model="filters.search"
                                    label="Search by Transaction #"
                                    singel-line
                                    hide-details
                                    append-icon="mdi-magnify"
                                    @keydown.enter="searchInput"
                                    @click:append="searchInput"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="pl-5">
                                <v-btn @click="massApprove()" v-if="selectCB.length > 0">APPROVE</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-title>
                <v-container fluid>
                    <v-data-table
                        v-model="selectCB"
                        :headers="headers"
                        :items="items"
                        :server-items-length="filters.totalItems"
                        :items-per-page="filters.itemsPerPage"
                        :loading="!loaded"
                        loading-text="Loading... Please Wait"
                        selectable-key="id"
                        show-select
                        fixed-header
                        disable-sort
                        @update:options="onPageChange"
                        @item-selected="onItemSelect"
                        @toggle-select-all="onSelectAll"
                    >
                        <template #[`item.total_amount`]="{ item }">
                            {{ item.total_amount | currency }}
                        </template>
                        <template #[`item.status`]="{ item }">
                            <a class="ml-1 badge text-white" :style="`background-color:${STATUS.CREDIT_BILL.find(e=>e.value === item.status)?.color}`">
                                {{STATUS.CREDIT_BILL.find(e=>e.value === item.status)?.text}}
                            </a>
                        </template>
                        <template #[`item.action`]="{ item }">
                            <v-btn text icon color="orange" v-if="USER_ACCESS.some(e=>e.actions_code == 'view')">
                                <v-icon class="btn-action" small @click="showItem(item, 'View')">
                                    mdi-eye
                                </v-icon>
                            </v-btn>
                            <v-btn text icon color="blue" v-if="USER_ACCESS.some(e=>e.actions_code == 'edit') && [1].includes(item.status) ">
                                <v-icon class="btn-action" small @click="showItem(item, 'Update')">
                                    mdi-square-edit-outline
                                </v-icon>
                            </v-btn>
                            <v-btn text icon color="red" v-if="USER_ACCESS.some(e=>e.actions_code == 'delete') && [1].includes(item.status)">
                                <v-icon class="btn-action" small @click="deleteItem(item.id)">
                                    mdi-delete
                                </v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-container>
            </v-card>
        </v-app>
    </div>
</template>
<script>
    import swal from 'sweetalert2'
    import { mapGetters } from 'vuex'
    export default {
        data() {
            return {
                loaded: false,
                items: [],
                itemsPerPageOptions: [5, 10, 15, 50, 100],
                filters: {
                    company_classification_id: null,
                    vendor_id: null,
                    company_id: null,
                    store_branch_id: null,
                    bank_id: null,
                    bank_card_id: null,
                    prepared_by: null,
                    search: '',
                    export: false,
                    totalItems: 0,
                    itemsPerPage: 10,
                    page : 1,
                },
                headers: [
                    { text: 'Transaction #', value: 'transaction_number', align: 'start' },
                    { text: 'Bank', value: 'bank_name', align: 'start' },
                    { text: 'Bank Card', value: 'bank_card_name', align: 'start' },
                    { text: 'Vendor', value: 'vendor_name', align: 'start' },
                    { text: 'Company', value: 'company_name', align: 'start' },
                    { text: 'Branch', value: 'store_branch_name', align: 'start' },
                    { text: 'Prepared By', value: 'prepared_by_name', align: 'start' },
                    { text: 'Approved By', value: 'approved_by_name', align: 'start' },
                    { text: 'Total Amount', value: 'total_amount', align: 'end' },
                    { text: 'Status', value: 'status', align: 'center' },
                    { text: 'Action', value: 'action', align: 'center' },
                ],
                company_data: {
                    companies: [],
                    branches: []
                },
                selectCB: [],
                selectAllFlag: false,
            }
        },
        computed: {
            ...mapGetters([
                'USER_ACCESS',
                'GET_CREDIT_BILL_STATUS',
                'GET_NEW_CREDIT_BILL',
                'GET_CREDIT_BILL_DISPATCH',
                'GET_COMPANY_CLASSIFICATION_SELECTION',
                'GET_ADMIN_SUPPLIER_SELECTION',
                'GET_PV_COMPANIES_SELECTION',
                'GET_PV_STORE_BRANCHES_SELECTION',
                'GET_BANK_SELECTION',
                'GET_BANK_CARD_SELECTION',
                'GET_PREPARED_BY',
                'STATUS',
                'USERACCOUNT_LOAD'
            ]),
        },
        mounted() {
            this.getAllCreditBill()
            this.getCompanyAndBranchData()
        },
        methods: {
            getAllCreditBill(filter = null) {
                this.loaded = false
                this.items = []
                let payload = {
                    filters: { ...this.filters, tab_name: this.GET_CREDIT_BILL_STATUS.tab_name},
                    url: this.GET_CREDIT_BILL_DISPATCH.url
                }
                this.$store.dispatch(this.GET_CREDIT_BILL_DISPATCH.index, payload).then((response) => {
                    this.items = response.data.data.data
                    this.filters.totalItems = response.data.data.total
                    this.loaded = true
                    this.$store.commit('NEW_CREDIT_BILL', false)
                }).catch((e) => {
                    this.loaded = true
                })
            },
            searchInput(){
                this.getAllCreditBill({ query: this.filters.search })
            },
            showItem(item, action) {
                this.$store.commit('DIALOG', true)
                this.$store.commit('ACTION', action)
                this.$store.commit('EDIT_CREDIT_BILL', item)
            },
            deleteItem(id) {
                swal.fire({
                    icon: 'warning',
                    text: 'Are you sure you want to Delete ?',
                    cancelButtonText: 'Cancel',
                    showCancelButton: true,
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        let payload = {
                            id: id,
                            url: this.GET_CREDIT_BILL_DISPATCH.url, 
                        }
                        this.$store.dispatch(this.GET_CREDIT_BILL_DISPATCH.destroy, payload).then(response => {
                            swal.fire('', response.data.message, response.data.status)
                            this.$store.commit('NEW_CREDIT_BILL', true)
                        }).catch(error => {
                            swal.fire('', error.response.data.message, error.response.data.status)
                        })
                    }
                })
            },
            getCompanyAndBranchData() {
                this.$store.dispatch('getPvCompanies').then(response => {
                    this.company_data.companies = response
                })
                this.$store.dispatch('getPaymentVoucherBranchesSelection').then(response => {
                    this.company_data.branches = response
                })
            },
            companies(val){
                if(val){
                    const query = this.$queryBuilder
                    let store = this.GET_PV_STORE_BRANCHES_SELECTION
                    let company = this.GET_PV_COMPANIES_SELECTION
                    let find = query.table(store).where('value',val).first();
                    if(find){
                        this.company_data.companies = query.table(company).where('value',find.company_id).get();
                        this.filters.company_id = query.table(company).where('value',find.company_id).first('value')
                        this.storesSelect(this.filters.company_id)
                    }else{
                        this.filters.company_id = null
                        this.company_data.companies = query.table(company).get();
                        this.company_data.branches = query.table(store).get();
                    }
                }
            },
            storesSelect(val){
                const query = this.$queryBuilder
                let store = this.GET_PV_STORE_BRANCHES_SELECTION
                let company = this.GET_PV_COMPANIES_SELECTION
                if(val){
                    let find = query.table(company).where('value',val).first();
                    if(find){
                        this.company_data.branches = query.table(store).where('company_id',find.value).get();
                        this.company_data.companies = query.table(company).where('value',find.value).get();
                        this.filters.store_branch_id = query.table(store).where('company_id',find.value).first('value')
                    }
                }else{
                    this.filters.store_branch_id = null
                    this.company_data.companies = query.table(company).get();
                    this.company_data.branches = query.table(store).get();
                }
            },
            onPageChange(newPage) {
                this.filters.page = newPage.page
                if (newPage.itemsPerPage == -1) {
                    this.itemsPerPage = this.filters.totalItems
                } else {
                    this.filters.itemsPerPage = newPage.itemsPerPage
                }
                this.getAllCreditBill()
            },
            massApprove() {
                swal.fire({
                    icon: 'question',
                    text: "Are you sure you want to approve the selected items? This action cannot be undone.",
                    cancelButtonText: "Cancel",
                    showCancelButton: true,
                    allowOutsideClick: false,
                    confirmButtonColor: "#397373",
                    cancelButtonColor: "#397373",
                }).then((result) => {
                    if (result.isConfirmed) {
                        let ids = this.$queryBuilder.table(this.selectCB).pluck('id').get()
                        let payload = {
                            url: this.GET_CREDIT_BILL_DISPATCH.approve_all,
                            ids: JSON.stringify(ids)
                        }
                        this.$store.dispatch(this.GET_CREDIT_BILL_DISPATCH.approve_all, payload).then((response) => {
                            swal.fire('', response.data.message, response.data.status)
                            this.selectCB = []
                            this.getAllCreditBill()
                        }).catch((e) => {
                            swal.fire('', error.response.data.message, error.response.data.status)
                            console.log(e)
                        })
                    }
                })
            },
            onSelectAll(item) {
                if (item.value == true && !this.selectAllFlag) {
                    this.selectAllFlag = true
                    let selectedItems = this.$queryBuilder.table(item.items).where('status', '!=' , 3).where('status', '!=' , 4).get()
                    this.selectCB = selectedItems.filter(e => {
                        if (e.status == 1) {
                            return e.prepared_by == this.USERACCOUNT_LOAD.id
                        }
                        if (e.status == 2) {
                            return this.USER_ACCESS.some(e=>e.actions_code == 'approve')
                        }
                        return true
                    })
                } else {
                    this.selectCB = []
                    this.selectAllFlag = false
                }
            },
            onItemSelect(item) {
                if (item.value) {
                    if (item.item.status == 1 && item.item.prepared_by == this.USERACCOUNT_LOAD.id) {
                        this.selectCB.push(item.item)
                    } else if (item.item.status == 2 && this.USER_ACCESS.some(e=>e.actions_code == 'approve')) {
                        this.selectCB.push(item.item)
                    } else {
                        this.selectCB = this.selectCB.filter(e => e.id !== item.item.id)
                    }
                }
            }
        },
        watch: {
            'GET_CREDIT_BILL_STATUS.tab_name': {
                handler(val) {
                    if (val) {
                        this.getAllCreditBill()
                    }
                },
                deep: true
            },
            GET_NEW_CREDIT_BILL: {
                handler(val) {
                    if (val) {
                        this.getAllCreditBill()
                        this.$store.commit('NEW_CREDIT_BILL', false)
                    }
                }
            },
        }
    }
</script>