<template>
  <div>
        <div class="pl-4 py-2">
            <div class="row">
                <div class="col-12 d-flex no-block align-items-center">
                    <div class="btn-toolbar">
                        <v-btn data-modal-id="rrFormModal" v-on:click="showRrForm()">
                            <i class="fas fa-plus pr-2"></i>Add
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
        <!-- Add Receiving Repair Form Dialog -->
        <v-dialog v-model="addRrfDialog" persistent max-width="98%" scrollable>
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Add Receiving Detail</span>
                        </v-card-title>
                    </v-col>

                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeAddRrfDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider class="mt-0" />
                <v-card-text>
                    <v-layout row>
                        <v-flex xl4 lg4 md4 sm12 xs12 style="height: fit-content" class="pr-2">
                            <v-card>
                                <div class="pa-2">
                                    <div class="py-2">
                                        <h6>Customer Information</h6>
                                    </div>
                                    <div>
                                        <v-layout>
                                            <v-flex>
                                                <v-autocomplete
                                                    v-model="selectedDealer"
                                                    :items="GET_SERVICE_ALL_CUSTOMERS"
                                                    :rules="rules.default"
                                                    item-text="company_name"
                                                    item-value="id"
                                                    label="Select Dealer"
                                                    auto-select-first
                                                    dense
                                                    outlined
                                                >
                                                    <template #append >
                                                        <span style="color:red">*</span>
                                                    </template>
                                                </v-autocomplete>
                                            </v-flex>
                                        </v-layout>

                                        <!-- Dealer Details -->
                                        <div v-if="!!selectedDealer" class="pt-2">
                                            <v-layout row class="mx-1">
                                                <v-flex lg3>
                                                    <div style="font-weight:bold">Company: </div>
                                                </v-flex>
                                                <v-flex lg9>
                                                    <div>{{ companyName }}</div>
                                                </v-flex>
                                            </v-layout>
                                            <v-layout row class="mx-1">
                                                <v-flex lg3>
                                                    <div style="font-weight:bold">Name: </div>
                                                </v-flex>
                                                <v-flex lg9>
                                                    <div>{{ companyOwner }}</div>
                                                </v-flex>
                                            </v-layout>
                                            <v-layout row class="mx-1">
                                                <v-flex lg3>
                                                    <div style="font-weight:bold">Address: </div>
                                                </v-flex>
                                                <v-flex lg9>
                                                    <div>{{ companyAddress }}</div>
                                                </v-flex>
                                            </v-layout>
                                            <v-layout row class="mx-1">
                                                <v-flex lg3>
                                                    <div style="font-weight:bold">Tel Number: </div>
                                                </v-flex>
                                                <v-flex lg9>
                                                    <div>{{ companyTelNumber }}</div>
                                                </v-flex>
                                            </v-layout>
                                            <v-layout row class="mx-1">
                                                <v-flex lg3>
                                                    <div style="font-weight:bold">Fax Number: </div>
                                                </v-flex>
                                                <v-flex lg9>
                                                    <div>{{ companyFaxNumber }}</div>
                                                </v-flex>
                                            </v-layout>
                                            <v-layout row class="mx-1">
                                                <v-flex lg3>
                                                    <div style="font-weight:bold">Mobile Number: </div>
                                                </v-flex>
                                                <v-flex lg9>
                                                    <div>{{ companyMobileNumber }}</div>
                                                </v-flex>
                                            </v-layout>
                                            <v-layout row class="mx-1 mb-2">
                                                <v-flex lg3>
                                                    <div style="font-weight:bold">Email: </div>
                                                </v-flex>
                                                <v-flex lg9>
                                                    <div>{{ companyEmail }}</div>
                                                </v-flex>
                                            </v-layout>
                                        </div>
                                    </div>
                                </div>
                            </v-card>

                            <v-card v-if="showEndUserDetails">
                                <div class="pa-2 mt-2">
                                    <div class="py-2">
                                        <h6>End User Information</h6>
                                    </div>
                                    <div>
                                        <label style="font-weight:bold">First Name: <span style="color:red">*</span></label>
                                    </div>
                                    <div>
                                        <v-text-field
                                            v-model="endUserFirstName"
                                            :rules="rules.default"
                                            dense
                                            outlined
                                        />
                                    </div>

                                    <div>
                                        <label style="font-weight:bold">Middle Name: </label>
                                    </div>
                                    <div>
                                        <v-text-field
                                            v-model="endUserMiddleName"
                                            outlined
                                            dense
                                        />
                                    </div>

                                    <div>
                                        <label style="font-weight:bold">Last Name: <span style="color:red">*</span></label>
                                    </div>
                                    <div>
                                        <v-text-field
                                            v-model="endUserLastName"
                                            :rules="rules.default"
                                            dense
                                            outlined
                                        />
                                    </div>

                                    <div>
                                        <label style="font-weight:bold">Contact Number: <span style="color:red">*</span></label>
                                    </div>
                                    <div>
                                        <v-text-field
                                            v-model="endUserContactNumber"
                                            :rules="rules.default"
                                            dense
                                            outlined
                                        />
                                    </div>

                                    <div>
                                        <label style="font-weight:bold">Address: <span style="color:red">*</span></label>
                                    </div>
                                    <div>
                                        <v-textarea
                                            v-model="endUserAddress"
                                            :rules="rules.default"
                                            dense
                                            outlined
                                        />
                                    </div>

                                    <div>
                                        <label style="font-weight:bold">Email: </label>
                                    </div>
                                    <div>
                                        <v-text-field
                                            v-model="endUserEmail"
                                            outlined
                                            dense
                                        />
                                    </div>
                                </div>
                            </v-card>
                        </v-flex>
                        <v-flex xl8 lg8 md8 sm12 xs12>
                            <v-card>
                                <div class="pa-2">
                                    <v-layout>
                                        <div class="py-2 pr-2">
                                            <h6>Items Information</h6>
                                        </div>
                                    </v-layout>
                                    <RepairFormTableList :customerId="selectedDealer" @inputItemList="inputItemList" :successCreate="successCreate" />
                                </div>
                            </v-card>
                            <v-card class="mt-2 pa-2">
                                <v-row class="d-flex align-center ma-0 px-2 pb-0">
                                    <v-col xs="12" md="1" class="px-2 pb-0">
                                        <div>
                                            <label style="color:gray">Carry In:</label>
                                        </div>

                                        <div>
                                            <v-checkbox v-model="carryIn" dense />
                                        </div>
                                    </v-col>
                                    <v-col cols="12" md="2" class="px-2 pb-0">
                                        <v-text-field v-model="pulloutNumber" :disabled="carryIn" :rules="rules.default" label="Pullout No" dense outlined>
                                            <template v-if="endUser" #append >
                                                <span style="color:red">*</span>
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="3" class="px-2 pb-0">
                                        <v-autocomplete
                                            v-model="selectedScLocation"
                                            :items="scLocations"
                                            :rules="rules.default"
                                            item-text="name"
                                            item-value="id"
                                            label="Select Location"
                                            auto-select-first
                                            dense
                                            outlined
                                        >
                                            <template v-if="endUser" #append >
                                                <span style="color:red">*</span>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="3"  class="px-2 pb-0">
                                        <v-text-field v-model="deliveredBy" :rules="rules.default" label="Deliverd By" dense outlined>
                                            <template v-if="endUser" #append >
                                                <span style="color:red">*</span>
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="3" class="px-2 pb-0">
                                        <v-text-field v-model="receivedBy" outlined dense readonly label="Received By"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-flex>
                    </v-layout>
                </v-card-text>
                <v-divider />

                <div class="pb-4 d-flex justify-center">
                    <v-btn :loading="submitLoader" @click="submit()">Submit</v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import RepairFormTableList from './RepairFormTableListComponent.vue';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
export default {
    data() {
        return {
            addRrfDialog: false,
            dealersList: [],
            selectedDealer: '',
            companyName: '',
            companyOwner: '',
            companyAddress: '',
            companyTelNumber: '',
            companyFaxNumber: '',
            companyMobileNumber: '',
            companyEmail: '',
            endUserFirstName: '',
            endUserMiddleName: '',
            endUserLastName: '',
            endUserContactNumber: '',
            endUserAddress: '',
            endUserEmail: '',
            showEndUserDetails: false,
            inputItems: [],
            successCreate: false,
            deliveredBy: '',
            receivedBy: '',
            submitLoader: false,
            currentUser: '',
            pulloutNumber: '',
            warehouse: '',
            carryIn: false,
            scLocations: [],
            selectedScLocation: '',
            loading:{
                dealer_selection:true
            },
            rules: {
                default: [
                v => !!v || 'This is required',
                ],
            },
        }
    },
    components: {
        RepairFormTableList
    },
    computed: {
        ...mapGetters([
            'GET_SERVICE_ALL_CUSTOMERS',
            'GET_SERVICE_ALL_LOCATIONS',
            'GET_SERVICE_GET_CURRENT_USER',
        ]),
        checkSelectedDealer() {
            if(!!this.selectedDealer) {
                if(this.selectedDealer == 275) {
                    return true;
                }
            }
            return false;
        },
        checkInputItems() {
            if(this.inputItems.length > 0) {
                return false;
            }
            return true;
        },
        endUser() {
            if(!!this.selectedDealer) {
                if(this.selectedDealer == 275 || !!this.carryIn) {
                    return false;
                }
            }
            return true;
        }
    },
    watch: {
        selectedDealer() {
            if(!!this.selectedDealer){
                this.getDealerDetails(this.selectedDealer);
                if(this.selectedDealer == 275) {
                    this.showEndUserDetails = true;
                } else {
                    this.showEndUserDetails = false;
                    this.resetEndUserFields();
                }
            } else {
                this.selectedDealer = "";
                this.showEndUserDetails = false;
            }
        },
        scLocations() {
            if(this.scLocations.length > 0) {
                let defaultLocation = this.scLocations.find(x=>x.default == 1);

                if(!!defaultLocation) {
                    this.selectedScLocation = defaultLocation.id;
                }
            }
        },
        'GET_SERVICE_ALL_LOCATIONS':{
            handler(val){
                this.scLocations = val
            }
        },
        'GET_SERVICE_GET_CURRENT_USER':{
            handler(val){
                this.receivedBy = val.name
            }
        },
    },
    methods: {
        showRrForm(){
            this.addRrfDialog = true;
            this.successCreate = false;
            this.warehouse = 'Del Monte (SC-DMS)';
            this.loading.dealer_selection = true
        },
        closeAddRrfDialog(){
            this.addRrfDialog = false;
            this.successCreate = false;
        },
        getDealers() {
            this.$store.dispatch('getServiceAllCustomers').then(response => {
                this.dealersList = this.GET_SERVICE_ALL_CUSTOMERS
            });

        },
        getAllLocations() {
            this.$store.dispatch('serviceGetAllLocations').then(response=>{
                this.scLocations = this.GET_SERVICE_ALL_LOCATIONS;
            });
        },
        getDealerDetails(id) {
            let dealer = this.GET_SERVICE_ALL_CUSTOMERS.find(dealer => dealer.id == id);
            let dealerName = [];
            let telNumber = [];
            let mobileNumber = [];
            let address = !dealer.shipping_addresses ? '' : JSON.parse(dealer.shipping_addresses)[0].address;
            if (!!dealer.tel_1) {
                telNumber.push(dealer.tel_1);
            } if (!!dealer.tel_2) {
                telNumber.push(dealer.tel_2);
            } if (!!dealer.tel_3) {
                telNumber.push(dealer.tel_3);
            }
            if (!!dealer.mobile_1) {
                mobileNumber.push(dealer.mobile_1);
            } if (!!dealer.mobile_2) {
                mobileNumber.push(dealer.mobile_2);
            } if (!!dealer.mobile_3) {
                mobileNumber.push(dealer.mobile_3);
            }
            this.companyName = dealer.company_name;
            this.companyTelNumber = telNumber.toString().replace(',', ' / ');
            this.companyFaxNumber = dealer.fax;
            this.companyMobileNumber = mobileNumber.toString().replace(',', ' / ');
            this.companyAddress = address;
            this.companyEmail = dealer.email;
        },
        inputItemList(itemList) {
            this.inputItems = itemList;
        },
        resetEndUserFields() {
            this.endUserFirstName = "";
            this.endUserMiddleName = "";
            this.endUserLastName = "";
            this.endUserContactNumber = "";
            this.endUserAddress = "";
            this.endUserEmail = "";
        },
        resetDealerFields() {
            this.selectedDealer = "";
            this.inputItems = [];
        },
        submit() {
            this.submitLoader = true;

            if(!this.requiredFields()) {
                swal.fire({
                    title: 'Are you sure you want to submit?',
                    text: 'Make sure that all information are correct',
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then((result)=> {
                    if(result.isConfirmed) {
                        let payload = {
                            customer_id: this.selectedDealer,
                            input_items: _.compact(this.inputItems),
                            end_user: this.selectedDealer == 275 ? true : false,
                            first_name: this.endUserFirstName,
                            middle_name: this.endUserMiddleName,
                            last_name: this.endUserLastName,
                            address: this.endUserAddress,
                            contact_number: this.endUserContactNumber,
                            email: this.endUserEmail,
                            pulloutNumber: this.pulloutNumber,
                            locationId: this.selectedScLocation,
                            delivered_by: this.deliveredBy,
                            received_by: this.receivedBy,
                        }
                        this.$store.dispatch('createRrAndRfs',payload).then(response => {
                            if(response.data.error) {
                                swal.fire('',response.data.error,'error').then(confirm=>{
                                    this.submitLoader = false;
                                });
                            } else {
                                this.resetDealerFields();
                                this.resetEndUserFields();
                                this.pulloutNumber = "";
                                this.warehouse = "";
                                this.deliveredBy = "";
                                this.successCreate = true;
                                this.$emit('rrCreateSuccess', this.successCreate);
                                this.addRrfDialog = false;
                                swal.fire("", response.data.msg, "success").then(() => {
                                    this.successCreate = false;
                                    this.$emit('rrCreateSuccess', this.successCreate);
                                    this.submitLoader = false;
                                })
                            }
                        }).catch(e => {
                            swal.fire("", e.data.error, "error").then(confirm=> {
                                this.submitLoader = false;
                            });
                        });
                    } else {
                        this.submitLoader = false;
                    }
                });
            }
        },
        requiredFields() {
            let warning = ''
            if (!this.selectedDealer) warning += 'Please select a Dealer.<br>';
            if(this.endUser) {
                if (!this.pulloutNumber) warning += 'Please ensure that the empty field for the pull out is filled in.<br>';
                if (!this.selectedScLocation) warning += 'Please select a Location.<br>';
                if (!this.deliveredBy) warning += 'Please ensure that the empty field for the delivered by is filled in.<br>';
            }

            if(this.inputItems.length == 0) {
                warning += 'Please select an Item.<br>';
            }

            if(this.selectedDealer == 275) {
                if (!this.endUserFirstName) warning += 'Please ensure that the empty field for the first name is filled in.<br>';
                if (!this.endUserLastName) warning += 'Please ensure that the empty field for the last name is filled in.<br>';
                if (!this.endUserContactNumber) warning += 'Please ensure that the empty field for the contact number is filled in.<br>';
                if (!this.endUserAddress) warning += 'Please ensure that the empty field for the address is filled in.<br>';
            }

            if (warning !== '') return swal.fire({
                title: 'Please Fill out Information:',
                html: warning,
                icon: "warning",
            }).then(confirm=> {
                this.submitLoader = false;
            });
        }
    }
}
</script>

<style>

</style>
