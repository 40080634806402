<template>
    <span>
        <v-tooltip top>
            <template v-slot:activator="{ on }">
                <v-btn
                    v-if="access"
                    v-on="on"
                    elevation="0"
                    text
                    icon
                    color="#ffa014"
                    @click="dialogVisibility(true)"
                ><v-icon small>mdi-file-document-multiple</v-icon></v-btn>
            </template>
            <span>View Files</span>
        </v-tooltip>
        <v-dialog
            v-model="isDialogOpen"
            persistent
            max-width="60rem"
        >
            <v-card class="p-4">
                <v-card-title class="p-0">
                    Files Viewer
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        small
                        icon
                        color="gray"
                        @click="dialogVisibility(false)"
                    ><v-icon>mdi-close-circle</v-icon></v-btn>
                </v-card-title>
                <v-data-table
                    class="mt-4 p-4 bg-light border"
                    :headers="fileHeaders"
                    :items="[...files, ...modifiedLocalFiles]"
                    :loading="loadingFiles"
                    :no-data-text="noDataText"
                >
                    <template v-slot:[`item.extension`]="{item}">
                        <span>
                            <span v-if="item.extension == 'docx'" class="badge primary" style="color: white;">{{ item.extension }}</span>
                            <span v-if="item.extension == 'xlsx'" class="badge green" style="color: white;">{{ item.extension }}</span>
                            <span v-if="item.extension == 'pdf'" class="badge error" style="color: white;">{{ item.extension }}</span>
                            <span v-if="['jpg', 'png'].includes(item.extension)" class="badge cyan" style="color: white;">{{ item.extension }}</span>
                        </span>
                    </template>
                    <template v-slot:[`item.action`]="{item, index}">
                        <span class="text-nonwrap">
                            <v-btn
                                text
                                icon
                                color="orange"
                                :disabled="!(fileTypes.images.includes(item.type) || fileTypes.documents.includes(item.type))"
                                @click="showFile(item)"
                            ><v-icon class="btn-action" small>mdi-eye</v-icon></v-btn>
                            <v-btn
                                text
                                icon
                                color="blue"
                                :disabled="item.local"
                                @click="downloadFile(item)"
                            ><v-icon class="btn-action" small>mdi-download</v-icon></v-btn>
                            <v-btn v-if="deleteFileDispatch && !viewing"
                                text
                                icon
                                color="red"
                                @click="deleteFile(item)"
                            ><v-icon class="btn-action" small>mdi-delete</v-icon></v-btn>
                        </span>
                    </template>
                </v-data-table>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="viewFile"
            persistent
            max-width="60%"
            :scrollable="false"
        >
        <v-card>
            <v-card-title>
                <span class="headline">{{ fileName }}</span>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    small
                    icon
                    color="gray"
                    @click="viewFileVisibility(false)"
                ><v-icon>mdi-close-circle</v-icon></v-btn>
            </v-card-title>
            <v-divider class="my-0" />
            <v-card-text>
                <div class="file-container-wrap">
                    <div class="file-container">
                        <img v-if="fileTypes.images.includes(fileType)" :src="fileSource" alt="">
                        <iframe v-if="fileTypes.documents.includes(fileType)" :src="fileSource" frameborder="0"></iframe>
                    </div>
                </div>
            </v-card-text>
        </v-card>
        </v-dialog>
    </span>
</template>

<script>
import swal from 'sweetalert2';
import {mapGetters} from 'vuex';
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue';

export default {
    mixins:[SharedFunctionsComponent],

    props: {
        payloadId: {
            type: Number,
            required: false,
        },
        module: {
            type: String,
            required: false,
        },
        folder: {
            type: String,
            required: false,
        },
        getFilesDispatch: {
            type: String,
            required: false,
        },
        deleteFileDispatch: {
            type: String,
            required: false,
        },
        localFiles: {
            required: false,
        },
        access: {
            default: true,
            required: false,
        },
        fileIndex: {
            default: 0,
            type: Number,
        },
        onLocalFileDelete: {
            type: Function,
        },
        viewing: {
            type: Boolean,
            default: false,
            required: false,
        },
    },

    data() {
        return {
            isDialogOpen: false,
            loadingFiles: false,
            viewFile: false,

            apiHost: process.env.VUE_APP_API_HOST,
            fileName: '',
            fileType: '',
            noDataText: 'No Uploaded Files',
            fileSource: '',

            fileHeaders: [
                { text: 'Name', value: 'name' },
                { text: 'Uploaded By', value: 'uploaded_by_name' },
                { text: 'Date', value: 'created_at' },
                { text: 'Type', value: 'extension' },
                { text: 'Action', align: 'center', sortable: false, value: 'action' },
            ],
            files: [],
            modifiedLocalFiles: [],
            fileTypes: {
                images: [
                    'image/jpeg',
                    'image/png',
                    'image/gif',
                    'image/bmp',
                    'image/webp',
                    'image/svg+xml',
                    'image/tiff',
                    'image/x-icon',
                ],
                documents: [
                    'application/pdf',
                    'text/plain',
                    'application/rtf',
                    'application/vnd.oasis.opendocument.text',
                ],
                words: [
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    'application/msword',
                ],
                spreadsheets: [
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'application/vnd.ms-excel',
                    'text/csv',
                    'text/tab-separated-values',
                    'application/vnd.oasis.opendocument.spreadsheet',
                    'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
                ],
            }
        };
    },

    methods: {
        deleteFile(file) {
            if (file.local) {
                const index = this.localFiles.files.findIndex(item => item.name == file.name);
                this.onLocalFileDelete(index, this.fileIndex);

                return;
            }

            swal.fire({
                text: 'Are you sure you want to delete?',
                icon: 'warning',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then((result) => {
            if(result.isConfirmed) {
                let payload = { id: file.id };

                this.$store.dispatch(this.deleteFileDispatch, payload).then(response => {
                    this.getFiles();
                    swal.fire("", 'deleted successfully', "success");
                }).catch(e => {
                    swal.fire("", e.data.error, "error");
                })
            }
            });
        },
        dialogVisibility(visibility) {
            if (this.clearFields) this.clearFileViewer();
            if (!visibility) this.clearFields = false;
            this.isDialogOpen = visibility;
        },
        downloadFile(file) {
            swal.fire({
                text: 'Are you sure you want to download?',
                icon: 'warning',
                dangerMode: true,
                showCancelButton: true,
                buttons: true
            }).then(async(result) => {
                if(result.isConfirmed) {
                    let url = `${process.env.VUE_APP_API_HOST}/img/${this.module}/${this.folder}?image_name=${this.fileName + '.' + file.extension}`;
                    fetch(url)
                    .then(resp => resp.blob())
                    .then(blobobject => {
                        const blob = window.URL.createObjectURL(blobobject);
                        const anchor = document.createElement('a');
                        anchor.style.display = 'none';
                        anchor.href = blob;
                        anchor.download = file.name;
                        document.body.appendChild(anchor);
                        anchor.click();
                        window.URL.revokeObjectURL(blob);
                    })
                    .catch(() => console.log('An error in downloading the file sorry'));
                }
            });
        },
        generateLocalFileURL(file) {
            return URL.createObjectURL(file);
        },
        getFiles() {
            this.loadingFiles = true;
            let payload = {
                id: this.payloadId,
            }
            this.$store.dispatch(this.getFilesDispatch, payload).then(response => {
                this.files = response.data;
                this.files.forEach(e=>{
                    e.created_at = this.$dayjs(e.created_at).format('YYYY-MM-DD, h:mm:ss');
                })
                this.loadingFiles = false;
            });
        },
        modifyLocalFiles() {
            this.modifiedLocalFiles = this.localFiles.files ?? [];
        },
        showFile(file){
            this.fileName = file.name;
            this.fileType = file.type;
            this.viewFileVisibility(true);
            this.$store.commit('filesClose');

            this.fileName = this.fileName.replaceAll(' ','%20');
            this.fileName = this.fileName.replaceAll('#','%23');

            if (file.local) return this.fileSource = this.generateLocalFileURL(file.localFileState);

            this.fileSource = `${this.apiHost}/uploads/${this.module}/${this.folder}/${this.fileName + '.' + file.extension}`;
        },
        viewFileVisibility(visibility) {
            this.viewFile = visibility;
        },
    },

    watch: {
        payloadId: {
            handler(val) {
                if (val) this.getFiles();
            },
            immediate: true,
        },
        localFiles: {
            handler(val) {
                if(!!val){
                    this.modifyLocalFiles();
                }
            },
            immediate: true,
            deep: true,
        },
    },
}
</script>

<style scoped>
    .local-files-viewer {
        height: 100%;
        overflow: auto;
    }
    .local-file-container {
        height: 12rem;
        background-color: white;
    }
    .local-file-container .v-image,
    .local-file-container iframe,
    .local-file-container .no-view-container {
        width: 100%;
        height: 100%;
        display: block;
    }
    .local-file-container .no-view-container {
        padding: .5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .file-table {
        border: 1px solid #000;
    }
    .file-container-wrap {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%;
    }
    .file-container {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        overflow-y: auto;
    }
    .file-container img {
        width: 100%;
    }
    .file-container iframe {
        width: 100%;
        height: 100%;
        display: block;
    }
</style>