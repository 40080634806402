<template>
    <v-container fluid>
        <ComponentHeader
            :button="true"
            :filterd="filters"
            :title="'Sales By Customer Detailed'"
            :type="'Sales'"
        ></ComponentHeader>
        <v-card>
            <v-card-text class="bg-bluish-gray">
                <v-row class="mx-auto" dense>
                    <v-col cols="3" style="padding: 3px">
                        <v-autocomplete
                            v-model="filters.mother_customer"
                            :items="GET_AFFLIATE_CUSTOMER"
                            :disabled="!!filters.customer"
                            label="Select Mother Customer"
                            background-color="white"
                            dense
                            outlined
                            hide-details
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="3" style="padding: 3px">
                        <v-autocomplete
                            v-model="filters.customer"
                            :items="GET_CUSTOMERS_SELECTION"
                            :disabled="!!filters.mother_customer"
                            label="Select Customer"
                            background-color="white"
                            dense
                            outlined
                            hide-details
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="3">
                        <v-autocomplete
                            v-model="filters.salesman"
                            :items="GET_SALESMAN_SELECTION"
                            label="Select Salesman"
                            background-color="white"
                            dense
                            outlined
                            hide-details
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="12" sm="12" md="2" lg="3" xl="3" class="d-flex justify-content-end" style="gap: 10px">
                        <v-btn :loading="exportData.loading" :disabled="dataTable.loading" small @click="list('xlsx');">Export</v-btn>
                        <v-btn :loading="dataTable.loading" :disabled="exportData.loading" small @click="list()">Filter</v-btn>
                    </v-col>
                </v-row>
                <v-row class="mx-auto" dense>
                    <v-col cols="2">
                        <v-autocomplete
                            v-model="filters.item"
                            :items="GET_ITEM_SELECTION_DEFAULT"
                            label="Select Item Model"
                            background-color="white"
                            dense
                            outlined
                            hide-details
                            multiple
                            small-chips
                            deletable-chips
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="2">
                        <v-autocomplete
                            v-model="filters.item_category"
                            :items="GET_ALL_ITEM_CATEGORIES"
                            label="Select Item Category"
                            background-color="white"
                            item-text="name"
                            item-value="id"
                            dense
                            outlined
                            hide-details
                            multiple
                            small-chips
                            deletable-chips
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="1">
                        <v-autocomplete
                            v-model="filters.trade"
                            :items="dropdowns.trade"
                            label="Trade"
                            background-color="white"
                            hide-details
                            clearable
                            dense
                            outlined
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row class="mx-auto" dense>
                    <v-col cols="2">
                        <v-autocomplete
                            v-model="filters.customer_category"
                            :items="GET_CUSTOMER_CATEGORY_SELECTION"
                            label="Select Customer Category"
                            background-color="white"
                            dense
                            outlined
                            hide-details
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="2">
                        <v-autocomplete
                            v-model="filters.customer_classification"
                            :items="GET_CLASSIFICATIONS_SELECTION"
                            label="Select Customer Classification"
                            background-color="white"
                            dense
                            outlined
                            hide-details
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="3">
                        <v-autocomplete
                            v-model="filters.customer_cluster"
                            :items="dropdowns.clusters"
                            label="Select Customer Cluster"
                            background-color="white"
                            dense
                            outlined
                            hide-details
                            clearable
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row class="mx-auto" dense>
                    <v-col cols="1" class="mx-1" v-if="GET_USER_COSTING">
                        <v-checkbox
                            v-model="filters.show_cost"
                            :true-value="1"
                            :false-value="null"
                            label="SC"
                            color="white"
                            hide-details
                        ></v-checkbox>
                    </v-col>
                    <v-col cols="3" class="mx-1">
                        <label class="float-left control-label col-form-label">Date From</label>
                        <v-menu
                            v-model="menus.date_from"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_from"
                                    readonly
                                    append-icon="mdi-calendar-clock"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_from" @input="menus.date_from = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="3" class="mx-1">
                        <label class="float-left control-label col-form-label">Date To</label>
                        <v-menu
                            v-model="menus.date_to"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_to"
                                    readonly
                                    append-icon="mdi-calendar-clock"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_to" @input="menus.date_to = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <ReportsCardsComponent
                    :setClass="'mx-auto my-1'"
                    :totals="totals"
                    :cards="cards"
                    style="width:100%;"
                ></ReportsCardsComponent>
                <v-row class="mx-auto" dense>
                    <v-col cols="12">
                        <v-data-table
                            :headers="dataTable.headers"
                            :items="dataTable.items"
                            :server-items-length="dataTable.total"
                            :loading="dataTable.loading"
                            :options.sync="dataTable.pagination"
                            :expanded.sync="dataTable.expand.expanded"
                            :footer-props="{
                                itemsPerPageOptions: dataTable.pagination.itemsPerPageOptions,
                            }"
                            show-expand
                            single-expand
                            disable-sort
                            dense
                            @item-expanded="loadDetails"
                            @update:page="list()"
                            @update:items-per-page="list()"
                        >
                            <template #item.promo="{ item }">
                                <span class="badge badge-primary" v-if="item.promo_id != null">Yes</span>
                                <span class="badge badge-danger" v-else>No</span>
                            </template>
                            <template #item.total_amount="{ item }">
                                {{ item.total_amount | currency }}
                            </template>
                            <template #expanded-item="{ headers }">
                                <td :colspan="headers.length" class="p-0">
                                    <v-data-table
                                        :headers="dataTable.expand.headers"
                                        :items="dataTable.expand.items"
                                        disable-sort
                                        disable-pagination
                                        hide-default-footer
                                        dense
                                        dark
                                    >
                                        <template #item.quantity="{ item }">
                                            {{ thousandSeprator(item.quantity) }}
                                        </template>
                                        <template #item.amount="{ item }">
                                            {{ item.amount | currency }}
                                        </template>
                                        <template #item.total_amount="{ item }">
                                            {{ item.total_amount | currency }}
                                        </template>
                                    </v-data-table>
                                </td>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
import ReportsCardsComponent from '@/views/main/layouts/Reports/ReportsCardsComponent.vue'
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue'
import { mapGetters } from 'vuex';

export default {
    data(){
        return{
            filters:{
                mother_customer: null,
                customer: null,
                salesman: null,
                item: [],
                item_category: [],
                customer_category: null,
                customer_classification: null,
                customer_cluster: null,
                show_cost: null,
                date_from: this.$dayjs().startOf('month').format('YYYY-MM-DD'),
                date_to: this.$dayjs().endOf('month').format('YYYY-MM-DD'),
                trade: 1,
            },
            menus: {
                date_from: false,
                date_to: false,
            },
            dataTable: {
                headers: [
                    { text: 'PL#', align: 'center', value: 'packing_list_num', },
                    { text: 'SO#', align: 'center', value: 'order_num', },
                    { text: 'PL Date', align: 'center', value: 'packing_list_date', },
                    { text: 'Customer', value: 'customer_name', },
                    { text: 'Salesman', value: 'salesman', },
                    { text: 'Category', value: 'customer_category', },
                    { text: 'Classification', value: 'customer_classification', },
                    { text: 'Cluster', value: 'customer_clusters', },
                    { text: 'Promo', align: 'center', value: 'promo', },
                    { text: 'Remarks', value: 'remarks', },
                    { text: 'SOA#', value: 'soa_ids', },
                    { text: 'Amount', align: 'end', width: '10%', value: 'total_amount', },
                ],
                items: [],
                pagination: {
                    itemsPerPageOptions: [10, 15, 25, 50, 100, -1],
                },
                loading: false,
                expand: {
                    headers: [
                        { text: 'MODEL', value: 'model', },
                        { text: 'NAME', value: 'name', },
                        { text: 'QTY', value: 'quantity', },
                        { text: 'PRICE', align: 'end', value: 'amount', },
                        { text: 'TOTAL AMOUNT', align: 'end', value: 'total_amount', },
                    ],
                    expanded: [],
                    items: [],
                },
            },
            exportData: {
                filename: 'Sales By Customer Detailed',
                file_ext: 'xlsx',
                columns: [
                    { header: 'CUSTOMER', key: 'customer_name' },
                    { header: 'PL#', key: 'packing_list_num', style: { numFmt: '@' } },
                    { header: 'SO#', key: 'order_num', style: { numFmt: '@' } },
                    { header: 'PO#', key: 'customer_po_num', style: { numFmt: '@' } },
                    { header: 'PL DATE', key: 'packing_list_date', style: { numFmt: 'm/d/yyyy' } },
                    { header: 'REMARKS', key: 'remarks' },
                    { header: 'SALESMAN', key: 'salesman' },
                    { header: 'STATUS', key: 'status_text' },
                    { header: 'TERMS', key: 'terms', style: { numFmt: '@' } },
                    { header: 'PL TOTAL', key: 'total_amount', style: { numFmt: '#,##0.00' } },
                    { header: 'MODEL', key: 'model' },
                    { header: 'NAME', key: 'name' },
                    { header: 'UOM', key: 'uom' },
                    { header: 'TYPE', key: 'type' },
                    { header: 'CATEGORY', key: 'category' },
                    { header: 'QTY', key: 'quantity', style: { numFmt: '#,##0' } },
                    { header: 'SRP', key: 'srp', style: { numFmt: '#,##0.00' } },
                    { header: 'FINAL PRICE', key: 'amount', style: { numFmt: '#,##0.00' } },
                    { header: 'FOC', key: 'foc' },
                    { header: 'PL ITEM TOTAL', key: 'item_total_amount', style: { numFmt: '#,##0.00' } },
                    { header: 'CUSTOMER CATEGORY', key: 'customer_category' },
                    { header: 'CUSTOMER CLASSIFICATION', key: 'customer_classification' },
                    { header: 'CUSTOMER CLUSTERS', key: 'customer_clusters' },
                    { header: 'NEW ITEM', key: 'new_item' },
                    { header: 'SOA#', key: 'soa_ids', style: { numFmt: '@' } },
                    { header: 'TRADE', key: 'trade' },
                ],
                items: [],
                loading: false,
            },
            cards: [
                { title: 'Net Sales Total', icon: 'mdi-cart', icon_class: 'total-amount', cols: 6, sm: 6, md: 3, lg: 3, xl: 3, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'net_sales_total_amount' },
                { title: 'Gross Sales Total', icon: 'mdi-cart', icon_class: 'total-amount', cols: 6, sm: 6, md: 3, lg: 3, xl: 3, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'gross_sales_total_amount' },
                { title: 'Net Adjustment', icon: 'mdi-cart', icon_class: 'total-amount', cols: 6, sm: 6, md: 3, lg: 3, xl: 3, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'adjustments_total_amount' },
                { title: 'Total Customer', icon: 'mdi-account-multiple-outline', icon_class: 'total-customer', cols: 6, sm: 6, md: 3, lg: 3, xl: 3, prefix: '', suffix: '', filter: '', value: 'customer_count' },
                { title: 'New Items Total', icon: 'mdi-cart', icon_class: 'total-amount', cols: 6, sm: 6, md: 3, lg: 3, xl: 3, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'new_items_total_amount' },
            ],
            cards_cost: [
                { title: 'Net Cost Total', icon: 'mdi-cart', icon_class: 'total-amount', cols: 6, sm: 6, md: 3, lg: 3, xl: 3, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'net_cost_total_amount' },
                { title: 'Cost % | Margin %', icon: 'mdi-cart', icon_class: 'total-amount', cols: 6, sm: 6, md: 3, lg: 3, xl: 3, prefix: '', suffix: '', filter: '', value: 'cost_margin_percentage' },
            ],
            totals: {
                net_sales_total_amount: 0,
                gross_sales_total_amount: 0,
                adjustments_total_amount: 0,
                customer_count: 0,
                new_items_total_amount: 0,
                net_cost_total_amount: 0,
                cost_margin_percentage: '',
            },
            dropdowns: {
                clusters: [],
                trade: [
                    { value: 1, text: 'Yes' },
                    { value: 0, text: 'No' },
                ],
            },
        }
    },
    mixins: [
        SharedFunctionsComponent,
    ],
    computed:{
        ...mapGetters([
            'GET_USER_COSTING',
            'GET_SALESMAN_SELECTION',
            'GET_CUSTOMERS_SELECTION',
            'GET_SALES_BY_CUSTOMER_DETAILED_DATA',
            'GET_ITEM_SELECTION_DEFAULT',
            'GET_ALL_ITEM_CATEGORIES',
            'GET_BRAND_DISCOUNT',
            'GET_AFFLIATE_CUSTOMER',
            'GET_CUSTOMER_CATEGORY_SELECTION',
            'GET_CLASSIFICATIONS_SELECTION',
            'GET_CLUSTER_SELECTION',
        ]),
    },
    mounted() {
        this.$store.dispatch('getUserCosting')
        this.$store.dispatch('getAffliatecustomer')
        this.$store.dispatch('getAllcustomersSelection', { include_mother: 0 })
        this.$store.dispatch('getAllsalesmanSelection')
        this.$store.dispatch('getBranddiscount')
        this.$store.dispatch('itemselection')
        this.$store.dispatch("getAllItemCategories")
        this.$store.dispatch('getallcustomerCategory')
        this.$store.dispatch('getAllcustomerClassificationSelection')
        this.$store.dispatch('getClusterSelection')
    },
    components:{
        ComponentHeader,
        ReportsCardsComponent,
    },
    methods:{
        list(type = 'list') {
            if (type == 'list') {
                this.dataTable.loading = true
                this.dataTable.items = []
                this.dataTable.total = 0

                Object.keys(this.totals).forEach(e => {
                    this.totals[e] = 0
                })
            } else if (type == 'xlsx') {
                this.exportData.loading = true
            }

            this.$store.dispatch('reports', {
                page: this.dataTable.pagination.page,
                itemsPerPage: this.dataTable.pagination.itemsPerPage,
                mother_customer: this.filters.mother_customer,
                customer: this.filters.customer,
                salesman: this.filters.salesman,
                item: this.filters.item,
                customer_category: this.filters.customer_category,
                customer_classification: this.filters.customer_classification,
                customer_cluster: this.filters.customer_cluster,
                show_cost: this.filters.show_cost,
                date_from: this.filters.date_from,
                date_to: this.filters.date_to,
                trade: this.filters.trade,
                export: type == 'xlsx' ? 1 : 0,
                filename:'Sales By Customer Detailed',
                url: 'sales-by-customer-detailed',
                expand_url:'packing-lists/getPackingListItems/detail_id',
            }).then(response => {
                if (type == 'list') {
                    this.dataTable.items = response.data.items.data
                    this.dataTable.total = response.data.items.total

                    Object.assign(this.totals, {
                        net_sales_total_amount: response.data.net_sales_total_amount,
                        gross_sales_total_amount: response.data.gross_sales_total_amount,
                        adjustments_total_amount: response.data.adjustments_total_amount,
                        customer_count: response.data.customer_count,
                        new_items_total_amount: response.data.new_items_total_amount,
                    })

                    this.dataTable.loading = false
                } else if (type == 'xlsx') {
                    this.exportData.items = response.data

                    const workbook = new this.$exceljs.Workbook()
                    const worksheet = workbook.addWorksheet('Detailed')

                    worksheet.columns = this.exportData.columns
                    const chunk_size = 15000
                    for (let i = 0; i < response.data.length; i += chunk_size) {
                        const chunk = response.data.slice(i, i + chunk_size)
                        worksheet.addRows(chunk)
                    }

                    workbook.xlsx.writeBuffer().then((data) => {
                        const blob = new Blob([data], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
                        })

                        this.$filesaver.saveAs(
                            blob,
                            `${this.exportData.filename} ${this.filters.date_from} - ${this.filters.date_to}.${this.exportData.file_ext}`
                        )
                    })

                    this.exportData.loading = false
                }
            }).catch(error => {
                console.log(error)

                if (type == 'list') {
                    this.dataTable.loading = false
                } else if (type == 'xlsx') {
                    this.exportData.loading = false
                }
            })
        },
        async loadDetails(item, value) {
            if (!value) {
                this.dataTable.expand.items = []

                await this.$store.dispatch('salesReportExpansion', {
                    expansion_url: 'packing-lists/getPackingListItems/' + item.item.id,
                }).then(response => {
                    this.dataTable.expand.items = response.data
                }).catch(error => {
                    console.log(error)
                    this.dataTable.expand.items = []
                })
            }
        },
    },
    watch: {
        'filters.show_cost': {
            handler(val) {
                if (val && this.GET_USER_COSTING) {
                    this.headers.splice(6, 0, {
                        text: 'Total Cost', align: 'right', width: '10%', value: 'total_cost',
                    })
                } else {
                    let index = this.headers.findIndex(f => {
                        return f.value == 'total_cost'
                    })

                    if (index !== -1) {
                        this.headers.splice(index, 1)
                    }
                }
            }
        },
        'GET_CLUSTER_SELECTION': {
            handler(val) {
                this.dropdowns.clusters = JSON.parse(JSON.stringify(val))
                this.dropdowns.clusters.push({
                    text: 'NO CLUSTER',
                    value: 0,
                })
            },
            deep: true,
        }
    }
}
</script>
