<template>
    <div class="w-100">
      <v-app id="my_vue_app">
        <v-card>
            <v-row class="ma-3 ml-4">
                <v-col sm="12" md="3">
                    <v-autocomplete
                        v-model="filters.customer_id"
                        :items="dropdowns.customers.lists"
                        @input="getAllWarehouseReturn()"
                        @click:clear="getAllWarehouseReturn()"
                        label="Select Customer"
                        dense
                        outlined
                        hide-details
                        clearable
                        auto-select-first
                    ></v-autocomplete>
                </v-col>
                <v-spacer></v-spacer>
                <v-col v-if="WIR_STATUS == 0" sm="12" md="2" class="text-right">
                    <a :class="`ml-1 badge badge-all ${filters.status_id == -1 ? `selected` : `non-selected`}`" @click="filterStatus(-1)">all</a>
                    <a :class="`ml-1 badge badge-saved ${filters.status_id == 1 ? `selected` : `non-selected`}`" @click="filterStatus(1)">saved</a>
                    <a :class="`ml-1 badge badge-for-cm ${filters.status_id == 2 ? `selected` : `non-selected`}`" @click="filterStatus(2)">for cm</a>
                    <a :class="`ml-1 badge badge-approved-wir ${filters.status_id == 3 ? `selected` : `non-selected`}`" @click="filterStatus(3)">approved</a>
                    <a :class="`ml-1 badge badge-cancelled ${filters.status_id == 4 ? `selected` : `non-selected`}`" @click="filterStatus(4)">cancelled</a>
                </v-col>
            </v-row>
            <v-row class="ma-3">
                <v-spacer></v-spacer>
                <v-col md="2">
                    <v-text-field dense v-model="search" label="Search Return#" single-line hide-details @keydown.enter="getAllWarehouseReturn()"> </v-text-field>
                </v-col>
                <v-col md="2">
                    <v-text-field dense v-model="searchItem" append-outer-icon="mdi-magnify"  label="Search Item Name/Model" single-line hide-details @keydown.enter="getAllWarehouseReturn()">
                    </v-text-field>
                </v-col>
            </v-row>

          <v-container fluid>
            <v-data-table
                :headers="headers"
                :items="Inbound"
                :options.sync="options"
                :server-items-length="totalInbound"
                :loading="!loaded"
                >

                <template v-slot:[`item.status_text`]="{ item }">
                    <span class="badge bg-secondary text-white" v-if="item.status == 1">{{ item.status_text }}</span>
                    <span class="badge bg-warning text-white" v-else-if="item.status == 2">{{ item.status_text }}</span>
                    <span class="badge bg-primary text-white" v-else-if="item.status == 3">{{ item.status_text }}</span>
                    <span class="badge bg-danger text-white" v-else-if="item.status == 4">{{ item.status_text }}</span>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                    <v-btn text icon color="orange" v-if="ACCESS_INBOUND.includes('view_wir')">
                    <v-icon class="btn-action" small @click="viewInbound(item)">mdi-eye</v-icon>
                    </v-btn>
                    <v-btn text icon color="blue" v-if="ACCESS_INBOUND.includes('edit_wir') && !item.cancel_date && [1].includes(item.status)">
                    <v-icon class="btn-action" small @click="editInbound(item)">mdi-pencil</v-icon>
                    </v-btn>

                    <v-btn text icon color="red" v-if="
                        (
                            item.status == 1 &&
                            ACCESS_INBOUND.includes('cancel_wir')
                        ) ||
                        (
                            item.status == 2 &&
                            !item.cancel_date &&
                            ACCESS_INBOUND.includes('delete_approved_wir')
                        )
                    ">
                    <v-icon class="btn-action" small @click="item.status == 1 ? cancelWir(item.id,1) : cancelInbound(item.id,1)">mdi-delete</v-icon>
                    </v-btn>

                    <!-- <v-btn text icon color="red" v-if="ACCESS_INBOUND.includes('cancel_wir') && item.status == 1">
                    <v-icon class="btn-action" small @click="cancelWir(item.id,1)">mdi-delete</v-icon>
                    </v-btn>
                    <v-btn text icon color="red" v-if="ACCESS_INBOUND.includes('delete_approved_wir') && !item.cancel_date && item.status == 2">
                    <v-icon class="btn-action" small @click="cancelInbound(item.id,1)">mdi-delete</v-icon>
                    </v-btn> -->
                </template>
                <template v-slot:[`item.approve_date`]="{ item }">
                        <span>{{ item.approve_date | formatDate }}</span>
                </template>
                <template v-slot:[`item.created_at`]="{ item }">
                        <span>{{ item.created_at | formatDate }}</span>
                </template>

                <template v-slot:item.file="{ item }">
                    <td>
                        <v-avatar
                            :color="!!item.files ? 'success' : 'error'"
                            size="15"
                        ></v-avatar>
                    </td>
                </template>
            </v-data-table>
          </v-container>
        </v-card>
      </v-app>

        <WarehouseReturnViewComponent
            :id="viewInboundId"
            :searchText="searchItem"
            :selected="selected"
            v-if="WIR_VIEW_DIALOG"
        >
        </WarehouseReturnViewComponent>
    </div>
  </template>

    <script>
    import swal from 'sweetalert2'
    import { mapGetters } from 'vuex';
    import {ref, defineAsyncComponent} from 'vue'
    const WarehouseReturnViewComponent = defineAsyncComponent({
        loader: ()=> import("@/views/main/layouts/dialogs/Operations/WarehouseReturn/WarehouseReturnViewComponent.vue")
    })
    // import WarehouseReturnViewComponent from '@/views/main/layouts/dialogs/Operations/WarehouseReturn/WarehouseReturnViewComponent.vue'

  export default {
    components: {
        WarehouseReturnViewComponent,
    },
    props: ['status', 'radioGroup','user_access'],
    mounted: function () {
        this.getSearchFromUrl();
        this.getAllWarehouseReturn();
        this.$store.dispatch("getCustomers", {});
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS',
            'LOAD',
            'WIR_VIEW_DIALOG',
            'WIR_STATUS',
            'ACCESS_INBOUND',
            'SELECTED_FILTER',
            'GET_CUSTOMERS'
        ])
    },
    data() {
      return {
            viewWir:false,
            actionResult: {
                model: 'Inbound',
                action: '',
            },
            loaded: false,
            totalInbound: 0,
            options: {},
            Inbound: [],
            itemsPerPageOptions: [5, 10, 15],
            itemsPerPage: 10,
            search: '',
            searchItem: null,
            awaitingSearch: false,
            radioValue: '',
            headers: [
                { text: 'WIR#', align: 'left', value: 'series' },
                { text: 'Warehouse', align: 'left', value: 'warehouse' },
                { text: 'Customers', align: 'left', value: 'customer' },
                { text: 'Created By', align: 'left', value: 'creator' },
                { text: 'Date Created', align: 'left', value: 'created_at' },
                { text: 'Check By', align: 'left', value: 'check_by' },
                { text: 'File', align: 'left',align:'center', value: 'file' },
                { text: 'Converted Date', align: 'left', value: 'approve_date' },
                { text: 'Status', align: 'left', value: 'status_text' },
                { text: 'Actions', align: 'center', value: 'action', sortable: false },
            ],

            viewInboundId: '',
            selected:null,
            dropdowns: {
                customers: {
                    lists: []
                }
            },
            filters: {
                customer_id: '',
                status_id: -1
            }
        };
    },
    methods: {
        getAllWarehouseReturn(status = 0) {
            this.loaded = false;
            this.Inbound = [];
            const payload = {
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                search: this.search,
                url:'index',
                status:this.WIR_STATUS,
                searchItem:this.searchItem,
                radio_value:this.SELECTED_FILTER,
                filters: this.filters
            }

            this.$store.dispatch('warehouseReturnGet', payload).then(response => {
            this.Inbound = response.data.data;
            this.loaded = true;
            this.totalInbound = response.data.total;
            });
        },
        getSearchFromUrl() {
            if (document.location.href.includes('search-model=')) {
            let item = document.location.href.split('search-model=');
            item = item[1].split('&')
            this.searchItem = item[0];
            this.searchInput();
            }
        },
        viewInbound(item) {
            this.$store.commit('WIR_SELECTED',item)
            this.$store.commit('WIR_VIEW_DIALOG',true)
            this.viewInboundId = item.id;
        },
        editInbound(item) {
            this.$emit('showEditModal', item);
            this.$store.commit('WIR_SELECTED',item)
        },
        cancelWir(id,type = 0 ) {
            swal
            .fire({
                title: '',
                text: 'Are you sure you want to Cancel?',
                icon: 'warning',
                showConfirmButton: true,
                confirmButtonText: 'Confirm',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: '397373',
                cancelButtonColor: 'grey',
                reverseButtons: false,
            })
            .then(result => {
                if (result.isConfirmed) {
                    let payload = {
                        url:'update/' + id,
                        cancel:true,
                        type:type
                    }
                    this.$store.dispatch('warehouseReturnGet', payload).then(response => {
                        swal.fire(response.data.title, response.data.message, response.data.icons);
                        this.getAllWarehouseReturn()
                    }).catch(error => {
                        swal.fire('An Error Occured', error, 'error')
                    })
                }
            });
        },
        cancelInbound(id,type = 0) {
            swal
            .fire({
                title: '',
                text: `Are you sure you want to Undo?`,
                icon: 'warning',
                showConfirmButton: true,
                confirmButtonText: 'Confirm',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: '397373',
                cancelButtonColor: 'grey',
                reverseButtons: false,
            })
            .then(result => {
                if (result.isConfirmed) {
                    let payload = {
                        url:'update/' + id,
                        undo:true,
                        type:type
                    }
                    this.$store.dispatch('warehouseReturnGet', payload).then(response => {
                        swal.fire(response.data.title, response.data.message, response.data.icons);
                        this.getAllWarehouseReturn()
                    }).catch(error => {
                        swal.fire('An Error Occured', error, 'error')
                    })
                }
            });
        },
        closeView() {
            this.viewInboundId = 0;
            this.viewWir = false
            this.getAllWarehouseReturn()
        },
        changeInboundStatus() {
            this.viewInboundId = 0;
            this.getAllWarehouseReturn();
        },
        searchInput() {
            this.getAllWarehouseReturn({ query: this.search });
        },
        addKeyListener() {
            window.addEventListener(
            'keyup',
            function (e) {
                if (e.keyCode == 13) {
                this.searchInput();
                $('input').blur()
                    }
            }).bind(this)
        },
        filterStatus(status_id){
            this.filters.status_id = status_id;
            this.getAllWarehouseReturn();
        }
    },
    watch: {
        radioGroup: function (val) {
            this.radioValue = val;
            this.getAllWarehouseReturn();
        },
        options: {
            handler() {
            this.getAllWarehouseReturn(this.WIR_STATUS);
            },
            deep: true,
        },
        WIR_STATUS(val){
            this.getAllWarehouseReturn(val)
        },
        WIR_VIEW_DIALOG(val){
            this.getAllWarehouseReturn()
        },
        'SELECTED_FILTER':{
            handler(val){
            this.getAllWarehouseReturn()
            }
        },
        GET_CUSTOMERS:{
            handler(val){
                this.dropdowns.customers.lists = val;
            }
        }
    },
};
  </script>
