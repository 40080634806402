<template>
      <v-container fluid>
        <h3 class="page-title">Parts Claims</h3>
        <v-container class="ma-0">
            <v-app id="item-tabs">
                <v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" background-color="#424242" :loaded="loading" dark show-arrows>
					<v-tabs-slider color="cyan" :loaded="loading"></v-tabs-slider>
                    <v-tab v-for="(item, index) in tabItems" :key="item" @click="getTab(index)" :loaded="loading">
                        {{ item }}
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item  v-for="(item,index) in disposal_component" :key="index">
                        <keep-alive>
                            <DisposalComponent :tabName="item.tabName"></DisposalComponent>
                        </keep-alive>
                    </v-tab-item>
                </v-tabs-items>
            </v-app>
        </v-container>
    </v-container>
</template>

<script>
import DisposalComponent from '@/views/main/modules/Services/Views/DisposalComponent/DisposalComponent.vue';
export default {
    components: {
        DisposalComponent,
    },
    data() {
        return {
            tab: null,
            component:'',
            disposal_component:[
                {
                    componentName: 'DisposalComponent',
                    tabName: 'Parts Claims',
                    dialog: 'DisposalViewComponent',
                    dialog_title:'Title here',
                    dialog_width: '75%',
                    scrollable: false,
                    dispatch: {
                        get:'getDisposal',
                        post:['','']
                    }
                }
            ],
            dialogWidth: '',
            dialog_scrollable: false,
            loading: false,
        }
    },
    computed: {
        tabItems() {
            return this.disposal_component.map( e => {
                return e.tabName;
            })
        }
    },
    methods: {
        getTab(key) {
            this.component = this.disposal_component[key].dialog;
			this.dialog_width = this.disposal_component[key].dialog_width;
			this.dialog_scrollable = this.disposal_component[key].scrollable;
			
            // this.$store.commit('REFRESH_DISPOSAL', true)
			// this.$store.commit('DISPOSAL_DIALOG_TITLE',this.disposal_component[key].dialog_title)
			// this.$store.commit('DISPOSAL_TAB',this.disposal_component[key].tabName)
			// this.$store.commit('DISPOSAL_DISPATCH',this.disposal_component[key].dispatch)
        },
    },
    mounted() {
        this.getTab(0);
    }

}
</script>

<style>

</style>