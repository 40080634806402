import CustomerMapComponent from '@/views/main/modules/Maps/CustomerMapComponent.vue'
import repository from "@/api/credentials";

const routes = [{
    path: "/customer-maps/:id",
    name: "customermaps",
    component: CustomerMapComponent,
    // beforeEnter: (to, from, next) => {
    //     repository.checkAccessUser(to.params.id).then((response) => {
    //         if (response.data == 'fail') {
    //             next('/no-access')
    //         } else {
    //             next()
    //         }
    //     }).catch(() => {

    //     })
    // },
    meta: {
        requiresAuth: true,
        title: 'Maps',
    }
}]

export default routes;
