<template>
    <div class="tabs-body-color">
            <div class="d-flex">
                <v-btn v-if="userAccess.create && deliveryAddressModule" class="py-auto my-3" @click="AddDialog()"><v-icon>mdi-plus</v-icon> Add </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    class="py-auto my-3"
                    @click="exportAdminDeliveryAddresses()"
                ><v-icon>mdi-file-export</v-icon> Export </v-btn>
            </div>
            <v-card flat class="pa-0 rounded-0">
                <v-card-text>
                    <v-row>
                        <v-col lg="12">
                            <AdminDeliveryAddressesTableComponent ref="dataTable"></AdminDeliveryAddressesTableComponent>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <AdminDeliveryAddressesViewComponent :dialogState="dialogState" @successTransaction="successTransaction" @closeDialog="closeDialog"></AdminDeliveryAddressesViewComponent>
     </div>
</template>
<script>
import AdminDeliveryAddressesTableComponent from '@/views/main/modules/Admin/AdminDeliveryAddresses/AdminDeliveryAddressesTableComponent.vue';
import AdminDeliveryAddressesViewComponent from '@/views/main/layouts/dialogs/Admin/AdminDeliveryAddressesViewComponent.vue';
import { mapGetters } from 'vuex';
export default {
    components: {
        AdminDeliveryAddressesTableComponent,
        AdminDeliveryAddressesViewComponent
    },
    props:{
        // 
    },
    data(){
        return {
            dialogState: false,
            default_delivery_address: {
                code: '',
                name: '',
                address:'',
                contact_number:'',
                location_type:''
            },
            userAccess: {
                view: false,
                create: false,
                edit: false,
                delete: false,
            },
        }
    },
    computed: {
        ...mapGetters([
            'USER_ACCESS'
        ]),
        deliveryAddressModule() {
            if (this.$route.name == 'admindeliveryaddresses') {
                return true;
            }
            return false
        }
    },
    methods:{
        AddDialog() {
            this.$store.commit('SELECTED_DELIVERY_ADDRESSES', this.default_delivery_address);
            this.dialogState = true;
            this.$store.commit('ADMIN_DELIVERY_ADDRESSES_ACTION', 'Add');
        },
        exportAdminDeliveryAddresses(){
            // 
        },
        closeDialog(val) {
            this.dialogState = val;
        },
        successTransaction() {
            this.$refs.dataTable.getAllAdminDeliverAdresses();
        }
    },
    watch:{
        USER_ACCESS: {
            handler(val) {
                if (val != "fail") {
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                this.userAccess.create = false;
                val.forEach((e) => {
                    if (e.actions_code == "edit") {
                        this.userAccess.edit = true;
                    } else if (e.actions_code == "delete") {
                        this.userAccess.delete = true;
                    } else if (e.actions_code == "view") {
                        this.userAccess.view = true;
                    } else if (e.actions_code == "create") {
                        this.userAccess.create = true;
                    }
                });
                }
            },
        },
    },
    mounted(){
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id);
    }
}
</script>

<style>

</style>