<template>
    <v-container fluid>
        <ComponentHeader class="ma-n4" :button="true" title='Collection Report' type='Accounting'></ComponentHeader>
        <v-row>
            <v-app id="my_vue_app" style="width:100%" class="mx-4 bg-bluish-gray pa-3 px-5">
                <v-row dense class="my-2">
                    <v-col sm="12" md="6">
                        <v-autocomplete
                            :items="GET_CUSTOMERS_SELECTION"
                            :loading="!GET_CUSTOMERS_SELECTION"
                            :disabled="!GET_CUSTOMERS_SELECTION"
                            v-model="filters.customers"
                            label="Select Customers"
                            @change="onSelectCustomers"
                            class="d-inline-block w-100"
                            outlined
                            dense
                            multiple
                            background-color="white"
                            small-chips
                            hide-details
                            clearable
                            deletable-chips
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-col sm="12" md="2">
                        <v-autocomplete
                            dense
                            outlined
                            :items="payments.selection"
                            v-model="payments.selected"
                            item-value="id"
                            item-text="name"
                            label="Payment Type"
                            background-color="white"
                            multiple
                            small-chips
                            hide-details
                            clearable
                            deletable-chips
                        />
                    </v-col>
                    <v-col sm="12" md="4" class="text-right">
                        <v-btn small class="ml-1" @click="resetFields()">Clear</v-btn>
                        <v-btn small class="ml-1" @click="renderPDF()">Print</v-btn>
                        <v-btn small class="ml-1" @click="exportToExcel()">Excel</v-btn>
                        <v-btn small class="ml-1" @click="getAllCollectionReport()">Filter</v-btn>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col sm="12" md="2" class="d-flex flex-row">
                        <v-menu
                            v-model="menu.date_from"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_from"
                                    prepend-inner-icon="mdi-calendar"
                                    outlined
                                    dense
                                    readonly
                                    v-on="on"
                                    style="display: block"
                                    background-color="white"
                                    label="Date From"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_from" @click:date="menu.date_from = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col sm="12" md="2" class="d-flex flex-row">
                        <v-menu
                            v-model="menu.date_to"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_to"
                                    prepend-inner-icon="mdi-calendar"
                                    outlined
                                    dense
                                    readonly
                                    v-on="on"
                                    style="display: block"
                                    background-color="white"
                                    label="Date To"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_to" @click:date="menu.date_to = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col sm="12" md="2" class="d-flex flex-row">
                        <v-autocomplete
                            dense
                            outlined
                            :items="collection_types.selection"
                            v-model="collection_types.selected"
                            label="Collection Type"
                            background-color="white"
                            hide-details
                            clearable
                        />
                    </v-col>
                    <v-col sm="12" md="2">
                        <v-autocomplete
                            dense
                            outlined
                            :items="banks.selection"
                            v-model="banks.selected"
                            item-value="id"
                            item-text="name"
                            label="Banks"
                            background-color="white"
                            multiple
                            small-chips
                            hide-details
                            clearable
                            deletable-chips
                        />
                    </v-col>
                </v-row>
            </v-app>
        </v-row>
        <v-row class="w-100 pa-2 bg-bluish-gray ml-0">
            <v-card v-for="data in collection.items" :key="data.period" :class="data.collections.length != 0? 'w-100 mt-2' : 'w-100'">
                <v-card-text v-if="data.collections.length != 0">
                    <v-card-title>
                        <b><span>{{ data.period | formatDateWithDay}} |  PHP {{ thousandSeprator(data.total) | currency }}</span></b>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-data-table
                        :headers="collection.headers"
                        :items="data.collections"
                        :loading="collection.loading"
                        :items-per-page="15"
                        item-key="period"
                        dense
                    >
                    <template v-slot:[`item.gross_amount`]="{ item }">
                        <span class="text-nowrap">
                            &#x20b1;{{ item.gross_amount | currency }}
                        </span>
                    </template>
                    <template v-slot:[`item.amount`]="{ item }">
                        <span class="text-nowrap">
                            &#x20b1;{{ item.amount | currency }}
                        </span>
                    </template>
                    <template v-slot:[`item.age`]="{ item }">
                        <span v-if="item.age < 0" class="badge bg-danger text-white">{{ item.age }}</span>
                        <span v-else-if="item.age == 0" class="badge bg-secondary text-white">{{ item.age }}</span>
                        <span v-else-if="item.age > 0" class="badge bg-success text-white">{{ item.age }}</span>
                    </template>
                    </v-data-table>

                </v-card-text>
            </v-card>
        </v-row>
        <div style="display:none" v-for="(data, index) in collection.items" :key="index">
            <table :id='"item_details_"+index' v-if="data.collections.length != 0">
                <thead>
                    <tr>
                        <th colspan="9" class="text-left">{{ data.period | formatDateWithDay}}</th>
                    </tr>
                    <tr>
                        <th>COMPANY</th>
                        <th>TERMS</th>
                        <th>CASH</th>
                        <th>AMOUNT</th>
                        <th>CHECK NUMBER</th>
                        <th>DATED</th>
                        <th>DUE</th>
                        <th>AGE</th>
                        <th>REMARKS</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(d, index) in data.collections" :key="index">
                        <td>{{ d.company_name }}</td>
                        <td>{{ d.terms }} </td>
                        <td>{{ thousandSeprator(d.amount) | currency}}</td>
                        <td>{{ thousandSeprator(d.gross_amount) | currency }}</td>
                        <td>{{ d.control_number }}</td>
                        <td>{{ d.payment_detail_date}}</td>
                        <td>{{ d.due_date }}</td>
                        <td>{{ d.age }}</td>
                        <td>{{ d.remarks }}</td>
                    </tr>
                    <tr>
                        <td colspan="2">AMOUNT: </td>
                        <td colspan="2">PHP {{ thousandSeprator(data.total) | currency }}</td>
                        <td colspan="5"></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div style="display:none;">
            <table id="cash_denominations">
                <thead>
                    <tr>
                        <th>DENOM</th>
                        <th>QTY</th>
                        <th>AMOUNT</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(breakdown) in cashDenominationBreakdown" :key="breakdown.code">
                        <td>{{ breakdown.value | currency}}</td>
                        <td>{{ breakdown.quantity}} </td>
                        <td>{{ breakdown.amount | currency }}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td>PHP {{ this.collection_total_amount | currency }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div style="display:none;">
            <table id="sub_total_details">
                <thead>
                    <tr>
                        <td colspan="3"> TOTAL </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>CASH</td>
                        <td>OTHERS</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>SUB TOTAL: </td>
                        <td>PHP {{ this.collection_total_amount | currency }}</td>
                        <td>PHP {{ this.collection_total_gross_amount | currency}}</td>
                    </tr>
                    <tr>
                        <td>TOTAL AMOUNT: </td>
                        <td></td>
                        <td>PHP {{ this.grand_total | currency}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div style="display: none;">
            <table id="transfer_to_pb_com">
                <thead>
                    <tr>
                        <th>COMPANY</th>
                        <th>TERMS</th>
                        <th>CASH</th>
                        <th>AMOUNT</th>
                        <th>BANK</th>
                        <th>CHECK NUMBER</th>
                        <th>DATED</th>
                        <th>DUE</th>
                        <th>AGE</th>
                        <th>REMARKS</th>
                    </tr>
                </thead>
                <tbody>
                     <tr v-for="(data, index) in transfer_to_pbcom" :key="index">
                        <td> {{ data.company_name }}</td>
                        <td>{{ data.terms }} </td>
                        <td></td>
                        <td>{{ data.gross_amount | currency }}</td>
                        <td>{{ data.bank_name }}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colspan="2"> Total Amount </td>
                        <td></td>
                        <td>0.00</td>
                        <td>{{ total_transfer_to_pbcom | currency }}</td>
                        <td colspan="6"></td>
                    </tr>
                    <!-- <tr>
                        <td colspan="2">AMOUNT: </td>
                        <td colspan="2">PHP {{ thousandSeprator(data.total) | currency }}</td>
                        <td colspan="5"></td>
                    </tr> -->
                </tbody>
            </table>
        </div>

        <div style="display:none">
            <table id="all_collections">
                <thead>
                    <tr>
                        <th>COMPANY</th>
                        <th>TERMS</th>
                        <th>CASH</th>
                        <th>AMOUNT</th>
                        <th>CHECK NUMBER</th>
                        <th>DATED</th>
                        <th>DUE</th>
                        <th>AGE</th>
                        <th>REMARKS</th>
                        <th style="display:none">Bank Id</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data, index) in all_collections" :key="index">
                        <td>{{ data.company_name }}</td>
                        <td>{{ data.terms }} </td>
                        <td>{{ data.amount | currency }}</td>
                        <td>{{ data.gross_amount | currency }}</td>
                        <td v-if="data.control_number == 'CASH'">
                            {{ data.control_number }}
                        </td>
                        <td v-else>
                            {{ !!data.control_number ? data.control_number+' - ' : ''}}
                            {{ data.bank_name }}
                        </td>
                        <td>{{ data.payment_detail_date}}</td>
                        <td>{{ data.due_date }}</td>
                        <td>{{ data.age }}</td>
                        <td>{{ data.remarks }}</td>
                        <td>{{ data.bank_id }}</td>
                    </tr>
                    <!-- <tr>
                        <td colspan="2">AMOUNT: </td>
                        <td colspan="2">PHP {{ thousandSeprator(data.total) | currency }}</td>
                        <td colspan="5"></td>
                    </tr> -->
                </tbody>
            </table>
        </div>
        <div style="display:none;">
            <table id="footer">
                <tbody>
                    <tr>
                        <td>PREPARED BY: <br/><br/> </td>
                        <td>NOTED BY: <br/><br/></td>
                        <td>NOTED BY: <br/><br/></td>
                        <td>RECEIVED BY: <br/><br/></td>
                    </tr>
                    <tr>
                        <td>{{ USERACCOUNT_LOAD.name | uppercase }}</td>
                        <td>JESSICA REAGO DEMETRIO</td>
                        <td>STEVEN ANDREW YANG</td>
                        <td>JONIE JUNIOR CHUA</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue';
export default {
    data() {
        return {
            payments:{
                selected: '',
                selection: [],
            },
            collection: {
                headers: [
                    { text: 'Company', width: '15%', value: 'company_name' },
                    { text: 'Payment Type', width: '8%', align: 'end', value: 'payment_name' },
                    { text: 'Terms', width: '8%', align: 'center', value: 'terms' },
                    { text: 'Cash', width: '8%', align: 'end', value: 'amount' },
                    { text: 'Amount', width: '8%', align: 'end', value: 'gross_amount' },
                    { text: 'Control Number', width: '8%', align: 'center', value: 'control_number' },
                    { text: 'Dated', width: '8%', align: 'center', value: 'payment_detail_date' },
                    { text: 'Due', width: '8%', align: 'center', value: 'due_date' },
                    { text: 'Age (Days)', width: '5%', align: 'center', value: 'age' },
                    { text: 'Remarks', width: '13%', align: 'start', value: 'remarks' },
                ],
                items: [],
                expanded: [],
                loading: false,
            },
            menu: {
                date_from: false,
                date_to: false,
            },

            filters: {
                date_from: this.$dayjs().startOf('year').format('YYYY-MM-DD'),
                date_to: this.$dayjs().endOf('month').format('YYYY-MM-DD'),
                customers: [],
                customer_classifications: [],
            },
            exportName: `Collection Report ${this.$dayjs().format('YYYY-MM-DD')}.csv`,
            exportFields: {
                'Company': 'company_name',
                'Terms': 'terms',
                'Payment Type': 'payment_name',
                'Cash': 'amount',
                'Amount': 'gross_amount',
                'Control Number': 'control_number',
                'Dated': 'payment_detail_date',
                'Due': 'due_date',
                'Age': 'age',
                'Remarks': 'remarks',
            },
            collection_total_amount:0,
            collection_total_gross_amount:0,
            grand_total:0,
            cashDenominationBreakdown:[],
            all_collections:[],
            transfer_to_pbcom:[],
            collection_types:{
                selection:[
                    {'text': 'All','value': 0},
                    {'text': 'Collection Only','value': 1},
                    {'text': 'Transfer To PBCOM','value': 2},
                ],
                selected:0
            },
            total_transfer_to_pbcom:0,
            banks:{
                selection:[],
                selected:''
            },
        }
    },
    mixins: [SharedFunctionsComponent],
    components: {
        ComponentHeader
    },
    mounted() {
        this.$store.dispatch('getAllcustomersSelection')
        this.$store.dispatch('getAllcustomerClassificationSelection')
        this.getPaymentTypes()
        this.getBanks()
    },
    computed: {
        ...mapGetters([
            'GET_CUSTOMERS_SELECTION',
            'GET_CLASSIFICATIONS_SELECTION',
            'USERACCOUNT_LOAD'
        ]),
    },
    methods: {
        getBanks() {
            this.$store.dispatch('getBankSelection', {
                type: 'AR',
            }).then(response=>{
                this.banks.selection = response
            })
        },
        getPaymentTypes(){
            this.$store.dispatch('getPaymentTypes').then(response=>{
                this.payments.selection = response.data
            })
        },
        resetFields() {
            Object.assign(this.$data, this.$options.data.call(this));
            this.getPaymentTypes()
            this.getBanks()
        },
        getAllCollectionReport() {
            swal.fire(
                {
                    text:'Loading... Please wait.',
                    allowOutsideClick:false
                }
            )
            swal.showLoading()
            this.collection_total_amount = 0,
            this.collection_total_gross_amount = 0,
            this.grand_total = 0,
            this.collection.loading = true
            this.cashDenominationBreakdown = []
            this.total_denomination = []
            this.all_collections = []
            this.transfer_to_pbcom = []
            let payload = {
                date_range:[this.filters.date_from,this.filters.date_to],
                payment_type:this.payments.selected,
                customer_ids:this.filters.customers,
                bank_ids:this.banks.selected,
                url: "getAllCollectionReport"
            }
            this.$store.dispatch('reportsPost', payload).then(response => {
                let data = response.data.data

                let denomination = []
                let total_denomination = []
                let transfer_to_pbcom_bank_ids = [1,2,3,34]

                data.forEach(e=>{
                    e.collections.forEach(x=>{
                        if(x.length != 0){
                            x.terms = x.terms == 0 ? 'CASH' : x.terms
                            if(x.payment_type_id == 1){
                                x.control_number = 'CASH'
                                x.amount = x.gross_amount
                                x.gross_amount = 0
                                x.payment_detail_date = x.payment_date
                                denomination.push(JSON.parse(x.cash_denomination_breakdown))
                                this.collection_total_amount += Number (x.amount)
                                x.bank_name = ''
                            }
                            else if(x.payment_type_id == 2){
                                x.control_number = x.cheque_account_number
                                x.payment_detail_date = x.cheque_date
                                x.amount = 0
                            }
                            else if(x.payment_type_id == 3){
                                x.control_number = x.account_number
                                x.payment_detail_date = x.account_date === null ? x.payment_date : x.account_date
                                x.amount = 0
                            }
                            else if(x.payment_type_id == 4){
                                x.control_number = x.online_transaction_number
                                x.payment_detail_date = x.online_date
                                x.amount = 0
                            }
                            else if(x.payment_type_id == 5){
                                x.control_number = x.card_number
                                x.payment_detail_date = x.card_date
                                x.amount = 0
                            }
                            x.age = this.agingDate(x.due_date,x.payment_detail_date)

                            if(x.payment_type_id == 3 || x.payment_type_id == 4){
                                if(transfer_to_pbcom_bank_ids.includes(x.bank_id)){
                                    this.transfer_to_pbcom.push(x);
                                }
                            }

                            this.collection_total_gross_amount += Number (x.gross_amount)
                        }
                    })
                })

                this.transfer_to_pbcom = _.groupBy(this.transfer_to_pbcom,'bank_name');
                let pb_com = [];
                for (let key in this.transfer_to_pbcom){
                    this.transfer_to_pbcom[key].forEach(e=>{
                        e.gross_amount = Number(e.gross_amount)
                    })
                    let x = _.sumBy(this.transfer_to_pbcom[key],'gross_amount')
                    pb_com.push({'company_name':"TRANSFER TO PBCOM",'gross_amount':x,'bank_name':key});
                }
                this.transfer_to_pbcom = pb_com;
                this.total_transfer_to_pbcom = _.sumBy(this.transfer_to_pbcom,'gross_amount')
                this.grand_total = this.collection_total_amount + this.collection_total_gross_amount
                this.collection.items = data
                this.collection.loading = false
                let denominations_total_breakdown = []
                let total_amounts = 0
                let total_quantity = 0
                let code_1000_amount = 0;
                let code_1000_qty = 0;
                let code_500_amount = 0;
                let code_500_qty = 0;
                let code_200_amount = 0;
                let code_200_qty = 0;
                let code_100_amount = 0;
                let code_100_qty = 0;
                let code_50_amount = 0;
                let code_50_qty = 0;
                let code_20_amount = 0;
                let code_20_qty = 0;
                let code_10_amount = 0;
                let code_10_qty = 0;
                let code_5_amount = 0;
                let code_5_qty = 0;
                let code_1_amount = 0;
                let code_1_qty = 0;
                let code_05_amount = 0;
                let code_05_qty = 0;
                let code_025_amount = 0;
                let code_025_qty = 0;
                let code_01_amount = 0;
                let code_01_qty = 0;
                let code_005_amount = 0;
                let code_005_qty = 0;
                let code_001_amount = 0;
                let code_001_qty = 0;
                denomination.forEach(e=>{
                    e.forEach((x,i)=>{
                        if(x.code == '1000'){
                            code_1000_amount += Number(x.amount)
                            code_1000_qty += Number(x.quantity)
                        }
                        else if(x.code == '500'){
                            code_500_amount += Number(x.amount)
                            code_500_qty += Number(x.quantity)
                        }
                        else if(x.code == '200'){
                            code_200_amount += Number(x.amount)
                            code_200_qty += Number(x.quantity)
                        }
                        else if(x.code == '100'){
                            code_100_amount += Number(x.amount)
                            code_100_qty += Number(x.quantity)
                        }
                        else if(x.code == '50'){
                            code_50_amount += Number(x.amount)
                            code_50_qty += Number(x.quantity)
                        }
                        else if(x.code == '20'){
                            code_20_amount += Number(x.amount)
                            code_20_qty += Number(x.quantity)
                        }
                        else if(x.code == '10'){
                            code_10_amount += Number(x.amount)
                            code_10_qty += Number(x.quantity)
                        }
                        else if(x.code == '5'){
                            code_5_amount += Number(x.amount)
                            code_5_qty += Number(x.quantity)
                        }
                        else if(x.code == '1'){
                            code_1_amount += Number(x.amount)
                            code_1_qty += Number(x.quantity)
                        }
                        else if(x.code == '0.5'){
                            code_05_amount += Number(x.amount)
                            code_05_qty += Number(x.quantity)
                        }
                        else if(x.code == '0.25'){
                            code_025_amount += Number(x.amount)
                            code_025_qty += Number(x.quantity)
                        }
                        else if(x.code == '0.1'){
                            code_01_amount += Number(x.amount)
                            code_01_qty += Number(x.quantity)
                        }
                        else if(x.code == '0.05'){
                            code_005_amount += Number(x.amount)
                            code_005_qty += Number(x.quantity)
                        }
                        else if(x.code == '0.01'){
                            code_001_amount += Number(x.amount)
                            code_001_qty += Number(x.quantity)
                        }
                    })
                })
                this.cashDenominationBreakdown.push(
                    {quantity: code_1000_qty, amount: code_1000_amount, id: 1, code: '1000', value: '1000.00'},
                    {quantity: code_500_qty, amount: code_500_amount, id: 2, code: '500', value: '500.00'},
                    {quantity: code_200_qty, amount: code_200_amount, id: 3, code: '200', value: '200.00'},
                    {quantity: code_100_qty, amount: code_100_amount, id: 4, code: '100', value: '100.00'},
                    {quantity: code_50_qty, amount: code_50_amount, id: 5, code: '50', value: '50.00'},
                    {quantity: code_20_qty, amount: code_20_amount, id: 6, code: '20', value: '20.00'},
                    {quantity: code_10_qty, amount: code_10_amount, id: 7, code: '10', value: '10.00'},
                    {quantity: code_5_qty, amount: code_5_amount, id: 8, code: '5', value: '5.00'},
                    {quantity: code_1_qty, amount: code_1_amount, id: 9, code: '1', value: '1.00'},
                    {quantity: code_05_qty, amount: code_05_amount, id: 10, code: '0.5', value: '0.50'},
                    {quantity: code_025_qty, amount: code_025_amount, id: 11, code: '0.25', value: '0.25'},
                    {quantity: code_01_qty, amount: code_01_amount, id: 12, code: '0.1', value: '0.10'},
                    {quantity: code_005_qty, amount: code_005_amount, id: 13, code: '0.05', value: '0.05'},
                    {quantity: code_001_qty, amount: code_001_amount, id: 14, code: '0.01', value: '0.01'},
                )
                this.collection.items.forEach(e=>{
                    if(e.collections.length != 0){
                        e.collections.forEach(x=>{
                            x.amount = Number(x.amount)
                            x.gross_amount = Number(x.gross_amount)
                            this.all_collections.push(x)
                        })
                    }
                })
                this.all_collections = _.orderBy(this.all_collections, ['classification_id','customer_id'],['asc', 'desc']);
                this.total_denomination = total_denomination;

                swal.close();
            })
        },
        onSelectCustomers(items) {
            this.filters.customers = items
        },
        startDownload() {
            this.exportLoading = true;
        },
        finishDownload() {
            this.exportLoading = false;
            swal.fire('', 'Export Success', 'success');
        },
        agingDate(due_date,dated) {
            if(!!due_date) {
                let startDay = this.$dayjs(dated);
                let endDay = this.$dayjs(due_date);
                let days =  this.$dayjs(endDay).diff(this.$dayjs(startDay), 'day');
                if(days >= 0){
                    days = 0
                    return days
                }
                else{
                    return days
                }
            }
            return '';
        },
        renderPDF(){
            var doc = new this.$jspdf({
                orientation: 'l',
                unit: 'mm',
                format: [215,330]
            });
            let left = 7;
            let top = 5;
            const imgWidth = 30;
            const imgHeight = 10;
            let host = window.location.origin;
            doc.addImage(`${host}/images/ingcoph-logo.png`, 'PNG', left, top, imgWidth, imgHeight);
            doc.setFont(undefined, 'bold').setFontSize(12).text(40, 13, `(${this.formatDateWithDays(this.filters.date_from)} - ${this.formatDateWithDays(this.filters.date_to)})`);
            // for(let i = 0; i<this.collection.items.length; i++){
            //     if(this.collection.items[i].collections.length != 0){
            //         doc.autoTable({
            //             html: '#item_details_'+i,
            //             theme: 'grid',
            //             margin: {top: 18, left: 6, right: 6},
            //             headStyles: {
            //                 halign:'center',
            //                 fontSize: 8,
            //                 fillColor: 'yellow',
            //                 textColor: '#000000',
            //             },
            //             columnStyles: {
            //                 0: {cellWidth: 65,halign:'center', fontSize: 8, textColor: '#000000'},
            //                 1: {cellWidth: 20,halign:'center', fontSize: 8, textColor: '#000000'},
            //                 2: {cellWidth: 30,halign:'center', fontSize: 8, textColor: '#000000'},
            //                 3: {cellWidth: 30,halign:'center', fontSize: 8, textColor: '#000000'},
            //                 4: {cellWidth: 35,halign:'center', fontSize: 8, textColor: '#000000'},
            //                 5: {cellWidth: 30,halign:'center', fontSize: 8, textColor: '#000000'},
            //                 6: {cellWidth: 30,halign:'center', fontSize: 8, textColor: '#000000'},
            //                 7: {cellWidth: 20,halign:'center', fontSize: 8, textColor: '#000000'},
            //                 8: {cellWidth: 60,halign:'left', fontSize: 8, textColor: '#000000'},
            //             },
            //         });
            //     }
            // }
            if(this.collection_types.selected == 0 || this.collection_types.selected == 1){
                doc.autoTable({
                    html: '#all_collections',
                    theme: 'grid',
                    margin: {top: 15, left: 6, right: 6},
                    headStyles: {
                        halign:'center',
                        fontSize: 8,
                        fillColor: 'yellow',
                        textColor: '#000000',
                    },
                    columnStyles: {
                        0: {cellWidth: 70,halign:'center', fontSize: 8, textColor: '#000000'},
                        1: {cellWidth: 15,halign:'center', fontSize: 8, textColor: '#000000'},
                        2: {cellWidth: 20,halign:'center', fontSize: 8, textColor: '#000000'},
                        3: {cellWidth: 20,halign:'center', fontSize: 8, textColor: '#000000'},
                        4: {cellWidth: 65,halign:'center', fontSize: 8, textColor: '#000000'},
                        5: {cellWidth: 20,halign:'center', fontSize: 8, textColor: '#000000'},
                        6: {cellWidth: 20,halign:'center', fontSize: 8, textColor: '#000000'},
                        7: {cellWidth: 10,halign:'center', fontSize: 8, textColor: '#000000'},
                        8: {cellWidth: 80,halign:'left', fontSize: 8, textColor: '#000000'},
                        9: {cellWidth: 15,halign:'left', fontSize: 8, textColor: '#000000'},
                    },
                    willDrawCell: function (data) {
                        var rows = data.table.body;
                        let day = Number(data.row.cells[7].text[0])
                        if(data.row.raw.length == 10){
                            let bank_id = Number(data.row.raw[9].content)
                            if(bank_id == 35){
                                doc.setFillColor('yellow');
                            }
                        }
                        if(day < 0){
                            data.row.cells[7].styles.textColor = 'red';
                        }
                    },
                });
            }
            if(this.collection_types.selected == 0 || this.collection_types.selected == 2){
                if(this.collection_types.selected == 0){
                    doc.addPage()
                }
                doc.addImage(`${host}/images/ingcoph-logo.png`, 'PNG', left, top, imgWidth, imgHeight);
                doc.setFont(undefined, 'bold').setFontSize(12).text(40, 13, `(${this.formatDateWithDays(this.filters.date_from)} - ${this.formatDateWithDays(this.filters.date_to)})`);
                doc.autoTable({
                    html: '#transfer_to_pb_com',
                    theme: 'grid',
                    margin: {top: 15, left: 6, right: 6},
                    headStyles: {
                        halign:'center',
                        fontSize: 8,
                        fillColor: 'yellow',
                        textColor: '#000000',
                    },
                    columnStyles: {
                        0: {cellWidth: 60,halign:'center', fontSize: 8, textColor: '#000000'},
                        1: {cellWidth: 15,halign:'center', fontSize: 8, textColor: '#000000'},
                        2: {cellWidth: 20,halign:'center', fontSize: 8, textColor: '#000000'},
                        3: {cellWidth: 30,halign:'center', fontSize: 8, textColor: '#000000'},
                        4: {cellWidth: 65,halign:'center', fontSize: 8, textColor: '#000000'},
                        5: {cellWidth: 20,halign:'center', fontSize: 8, textColor: '#000000'},
                        6: {cellWidth: 20,halign:'center', fontSize: 8, textColor: '#000000'},
                        7: {cellWidth: 10,halign:'center', fontSize: 8, textColor: '#000000'},
                        8: {cellWidth: 10,halign:'left', fontSize: 8, textColor: '#000000'},
                        9: {cellWidth: 70,halign:'left', fontSize: 8, textColor: '#000000'},
                    },
                });
            }
            if(this.collection_types.selected == 0 || this.collection_types.selected == 1){
                doc.addPage()
                doc.addImage(`${host}/images/ingcoph-logo.png`, 'PNG', left, top, imgWidth, imgHeight);
                doc.autoTable({
                    html: '#sub_total_details',
                    theme: 'grid',
                    headStyles: {
                        halign:'center',
                        fontSize: 8,
                        fillColor: 'yellow',
                        textColor: '#000000',
                    },
                    margin: {top: 15, left: 6, right: 6},
                    columnStyles: {
                        0: {cellWidth: 65,halign:'center', fontSize: 8, textColor: '#000000'},
                        1: {cellWidth: 65,halign:'center', fontSize: 8, textColor: '#000000'},
                        2: {cellWidth: 65,halign:'center', fontSize: 8, textColor: '#000000'},
                    }
                });
                doc.autoTable({
                    html: '#cash_denominations',
                    theme: 'grid',
                    headStyles: {
                        halign:'center',
                        fontSize: 8,
                        fillColor: 'yellow',
                        textColor: '#000000',
                    },
                    margin: {top: 8, left:155, right: 6},
                    columnStyles: {
                        0: {cellWidth: 35,halign:'center', fontSize: 8, textColor: '#000000'},
                        1: {cellWidth: 35,halign:'center', fontSize: 8, textColor: '#000000'},
                        2: {cellWidth: 35,halign:'center', fontSize: 8, textColor: '#000000'},
                    }
                });

                doc.autoTable({
                    html: '#footer',
                    theme: 'plain',
                    startY: doc.autoTable.previous.finalY+10,
                    margin: {top: 2, left: 6, right: 6},
                    columnStyles: {
                        0: {cellWidth: 80, halign:'center',fontSize: 8, textColor: '#000000'},
                        1: {cellWidth: 80, halign:'center',fontSize: 8, textColor: '#000000'},
                        2: {cellWidth: 80, halign:'center',fontSize: 8, textColor: '#000000'},
                        3: {cellWidth: 80, halign:'center',fontSize: 8, textColor: '#000000'},
                    },
                    bodyStyles: {
                        lineColor: '#000000',
                    }
                });
            }
            if(this.collection_types.selected == 2){
                doc.autoTable({
                    html: '#footer',
                    theme: 'plain',
                    startY: doc.internal.pageSize.height - 35,
                    margin: {top: 2, left: 6, right: 6},
                    columnStyles: {
                        0: {cellWidth: 80, halign:'center',fontSize: 8, textColor: '#000000'},
                        1: {cellWidth: 80, halign:'center',fontSize: 8, textColor: '#000000'},
                        2: {cellWidth: 80, halign:'center',fontSize: 8, textColor: '#000000'},
                        3: {cellWidth: 80, halign:'center',fontSize: 8, textColor: '#000000'},
                    },
                    bodyStyles: {
                        lineColor: '#000000',
                    }
                });
            }
            let pageCount = doc.internal.getNumberOfPages();
            for(let i = 0; i < pageCount; i++) {
                doc.setPage(i);
                let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber;
                doc.setFontSize(9).setFont(undefined, 'normal').text('Page: ' + pageCurrent + ' of ' + pageCount, doc.internal.pageSize.width - 25,12 );
            }
            doc.autoPrint();
            window.open(doc.output('bloburl'), '_blank');
        },
        async exportToExcel(){
            let that = this
            that.collection.loading = true

            const loadImage = async (url) => {
                const response = await fetch(url);
                const blob = await response.blob();
                return blob;
            };

            const workbook = new this.$exceljs.Workbook();
            let sheet = workbook.addWorksheet('Collection Report');

            sheet.pageSetup = {
                margins: {
                    left: 0.3,
                    right: 0.3,
                    top: 0.3,
                    bottom: 0.3,
                    header: 0.3,
                    footer: 0.3
                },
                orientation: 'landscape',
                fitToPage: true,
                fitToHeight: 0,
                fitToWidth: 1
            };

            const imageBlob = loadImage(`${window.location.origin}/images/ingcoph-logo.png`);
            const imageId = workbook.addImage({
                buffer: imageBlob,
                extension: 'png',
            });


            let formatFrom = this.formatDateWithDays(this.filters.date_from)
            let formatTo = this.formatDateWithDays(this.filters.date_to)

            sheet.headerFooter = {
                oddHeader: '&RPage: &P of &N'
            };

            function addHeaders(sheet, startRow) {
                sheet.addImage(imageId, {
                    tl: { col: 0, row: startRow },
                    ext: { width: 150, height: 55 }
                });
                sheet.getCell(`C${startRow + 2}`).value = `(${formatFrom} - ${formatTo})`;
            }

            function addTableToSheet(sheet, tableSelector, startRow) {
                let table = document.querySelector(tableSelector);
                let rows = table.querySelectorAll('tr');
                let excludeColumnIndex = -1;
                let columnData = -1;
                let ageColumn = -1;
                let termsColumn = -1;
                let cashColumm = -1;
                let qtyColumm = -1;
                let denomColumm = -1;

                rows.forEach((row, rowIndex) => {
                    let cells = row.querySelectorAll('td, th');
                    let excelRow = sheet.getRow(rowIndex + startRow);

                    cells.forEach((cell, cellIndex) => {
                        let cellText = cell.innerText.trim();
                        let excelCell = excelRow.getCell(cellIndex + 1);

                        if (cellText === 'Bank Id') {
                            excludeColumnIndex = cellIndex;
                        }

                        if (cellIndex === excludeColumnIndex) {
                            return;
                        }

                        if(cellText == 'AMOUNT') {
                            columnData = cellIndex
                        }

                        if (cellIndex === columnData) {
                            // AMOUNT DATA
                            let sample = /^\d{1,3}(,\d{3})*(\.\d{2})?$/.test(cellText)
                            if(sample == true) {
                                cellText = parseFloat(cellText.replace(/,/g, ''));
                                excelCell.value = cellText;
                                excelCell.numFmt = '#,##0.00';
                            } else {
                                excelCell.value = cellText
                            }
                        }

                        if(cellText == 'AGE') {
                            ageColumn = cellIndex
                        }

                        if(ageColumn === cellIndex) {
                            // AGE DATA
                            cellText = cellText == 'AGE' ? (cellText == '' ? '' : cellText) : Number(cellText)
                            if(/-/g.test(cellText)) {
                                excelCell.value = cellText
                                excelCell.font = { bold: true, color: { argb: 'FF0000' } }
                            }
                            excelCell.value = cellText
                        }

                        if(cellText == 'TERMS') {
                            termsColumn = cellIndex
                        }

                        if(termsColumn === cellIndex) {
                            // TERMS DATA
                            if(['TERMS', 'CASH', ''].includes(cellText)) {
                                cellText = cellText
                            } else {
                                cellText = Number(cellText)
                            }
                            excelCell.value = cellText
                        }

                        if(cellText == 'CASH') {
                            cashColumm = cellIndex
                        }

                        if(cashColumm === cellIndex) {
                            // CASH DATA
                            let sample = /^\d{1,3}(,\d{3})*(\.\d{2})?$/.test(cellText)
                            if(sample == true) {
                                if(cellText % 1 !== 0) {
                                    cellText = parseFloat(cellText.replace(/,/g, ''));
                                    excelCell.value = cellText
                                    excelCell.numFmt = '#,##0.00';
                                } else {
                                    if(typeof cellText == 'string') {
                                        cellText = Number(cellText);
                                        excelCell.value = cellText
                                        excelCell.numFmt = '#,##0.00';
                                    } else {
                                        cellText = parseFloat(cellText)
                                    }
                                }
                                excelCell.value = cellText;
                            } else {
                                excelCell.value = cellText
                            }
                        }

                        if(cellText == 'QTY') {
                            qtyColumm = cellIndex
                        }

                        if(qtyColumm === cellIndex) {
                            if(['QTY', ''].includes(cellText)) {
                                cellText = cellText
                            } else {
                                cellText = Number(cellText)
                            }
                            excelCell.value = cellText
                        }

                        if(cellText == 'DENOM') {
                            denomColumm = cellIndex
                        }

                        if(denomColumm === cellIndex) {
                            if(['DENOM', ''].includes(cellText)) {
                                cellText = cellText
                            } else {
                                cellText = parseFloat(cellText.replace(/,/g, ''));
                                excelCell.numFmt = '#,##0.00';
                            }
                            excelCell.value = cellText
                        }

                        let cellData = cellText;
                        excelCell.value = cellData;
                        excelCell.alignment = { horizontal: 'center' };
                        excelCell.font = { size: 10, color: { argb: 'FF000000' } };
                        excelCell.border = {
                            top: {style:'thin'},
                            left: {style:'thin'},
                            bottom: {style:'thin'},
                            right: {style:'thin'}
                        }
                        if (rowIndex === 0) {
                            excelCell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFFFF00' },
                            };
                            excelCell.font = { bold: true };
                        }

                    });
                });


            }

            function addPageBreakAndHeaders(sheet, startRow, addHeader) {
                sheet.getRow(startRow).addPageBreak();
                if (addHeader) {
                    addHeaders(sheet, startRow);
                }
            }

            const tables = [
                { selector: '#all_collections', condition: true }
            ];

            addHeaders(sheet, 0);

            tables.forEach((table, index) => {
                if (table.condition) {
                    let nextRow = sheet.rowCount + 2;
                    if (index > 0 && table.addHeader !== false) {
                        addPageBreakAndHeaders(sheet, nextRow, true);
                        nextRow += 4;
                    }
                    addTableToSheet(sheet, table.selector, nextRow);
                }
            });

            if (this.collection_types.selected === 0 || this.collection_types.selected === 2) {
                let nextRow = sheet.rowCount + 2;
                addPageBreakAndHeaders(sheet, nextRow, true);
                nextRow += 4;
                addTableToSheet(sheet, '#transfer_to_pb_com', nextRow);
            }

            if (this.collection_types.selected === 0 || this.collection_types.selected === 1) {
                let nextRow = sheet.rowCount + 2;
                addPageBreakAndHeaders(sheet, nextRow, true);
                nextRow += 4;
                addTableToSheet(sheet, '#sub_total_details', nextRow);
                nextRow = sheet.rowCount + 2;
                addTableToSheet(sheet, '#cash_denominations', nextRow);
                nextRow = sheet.rowCount + 2;
                addTableToSheet(sheet, '#footer', nextRow);
            }

            const buffer = await workbook.xlsx.writeBuffer();
            this.$filesaver.saveAs(new Blob([buffer]), `Collection Report ${this.filters.date_from}-${this.filters.date_to}.xlsx`);

            that.collection.loading = false
        },
        formatDateWithDays(value){
            if (value) {
                return this.$dayjs(String(value)).format('MMMM D, YYYY');
            }
        }

    },
}
</script>

<style>

</style>
