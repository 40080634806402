<template>
    <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
            <v-card-title class="d-flex justify-content-between pa-0">
                <h4 class="mx-3">{{ actions }} {{ GET_CHECKLIST_DIALOG_TITLE }}</h4>
                <v-btn text @click="closeDialog()" fab><v-icon>mdi-close-circle</v-icon></v-btn>
            </v-card-title>
            <v-divider/>

            <v-card-text>
                <v-row class="pl-3 pr-3">
                    <v-col lg="6">
                        <v-text-field 
                            v-model="checklist.fillables.model" 
                            label="Model" 
                            :readonly="disabled_view" 
                            outlined 
                            dense 
                        >
                        </v-text-field>
                    </v-col>

                    <v-col lg="6">
                        <v-text-field 
                            v-model="checklist.fillables.item_name" 
                            label="Item Name" 
                            :readonly="disabled_view" 
                            outlined 
                            dense 
                        >
                        </v-text-field>
                    </v-col>

                    <v-col lg="12">
                        <v-text-field 
                            v-model="checklist.fillables.category" 
                            label="Category" 
                            :readonly="disabled_view" 
                            outlined 
                            dense 
                        >
                        </v-text-field>
                    </v-col>

                    <v-col lg="12">
                        <v-autocomplete
                            :items="GET_ALL_LIST_TEMPLATE"
                            v-model="checklist.fillables.template_id"
                            item-value="id"
                            item-text="name"
                            label="Select Template"
                            dense
                            outlined
                            @change="getTemplate()" 
                            :readonly="ACTION == 'View'"
                            auto-select-first
                            clearable
                            :value="this.GET_ALL_LIST_TEMPLATE.find(e=>e.id).name"
                        >   
                        </v-autocomplete>
                    </v-col>

                    <v-col lg="12">
                        <v-autocomplete
                            v-if="ACTION.includes('Edit')"
                            :items="GET_CHECK_LISTS_SELECTION"
                            v-model="checklist.check_list_templates"
                            item-value="id"
                            item-text="name"
                            label="Check List"
                            append-icon="mdi-plus"
                            auto-select-first
                            outlined
                            dense
                            multiple 
                            small-chips
                            >
                            <template v-slot:selection="{ attrs, item, selected,index }">
                                <v-chip
                                    v-bind="attrs"
                                    :input-value="selected"
                                    :close="disabled_view"
                                    @click:close="removeCheckList(index)"
                                    small
                                    dark
                                    color="#397373"
                                >

                                    <strong>{{ item.name }}</strong>
                                </v-chip>
                            </template>
                        </v-autocomplete>
                    </v-col>
                     
                    <v-col v-if="ACTION.includes('View') && checklist.check_list_templates?.length">
                        <v-card class="w-100" height="300" style="overflow-y: scroll;">

                            <v-card-title>
                                <h6 class="mx-3"> Check Lists </h6>
                            </v-card-title>

                            <v-card-text>
                                <v-simple-table height="300px">

                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Code</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr 
                                            v-for="(check_list_template, index) in checklist.check_list_templates" 
                                            :key="index"
                                        >
                                            <td>
                                                <span>
                                                {{ check_list_template.name }}
                                                </span>
                                            </td>

                                            <td>
                                                <span>
                                                {{ check_list_template.code }}
                                                </span>
                                            </td>

                                            <td>
                                                <span>
                                                {{ check_list_template.description }}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>

                                </v-simple-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions v-if="!ACTION.includes('View')" class="d-flex justify-content-end">
                <v-btn v-if="actions != 'View'"  @click="update()" small><v-icon>mdi-content-save</v-icon> {{ actions }} </v-btn>
            </v-card-actions>

        </v-card>
    </v-form>
</template>

<script>
import Swal from 'sweetalert2'
import { mapGetters } from 'vuex';
export default {
    data(){
        return{
			disabled_view: false,
			valid: false,
			form: {},
            checklist:{
                fillables:{
                    item_name:'',
                    model:'',
                    category: '',
                    template_id: '',
                },
                list:[],
                loaded:true,
                deleted_checklist:[],
                check_list_templates:[],
                check_list_trash:[],
                template_lists: [],
            },
            activeTabs: '',
        }
    },

    computed:{
        ...mapGetters([
            'GET_ALL_LIST_TEMPLATE',
            'GET_REFRESH',
            'GET_TABS',
            'GET_CHECKLIST_DIALOG_TITLE',
            'ACTION',
            'GET_CHECKLIST_DIALOG_VIEW',
            'GET_CHECKLIST_DISPATCH',
            'DIALOGS',
            'TESTER_CHECK_LISTS',
            'GET_CHECK_LIST',
            'GET_CHECK_LISTS_SELECTION',

        ]),

        actions(){
            if(this.ACTION == 'Edit'){
                this.disabled_view = true;

                this.getItemsData()
                return 'Edit'
            } else if (this.ACTION == 'View'){
                this.disabled_view = true;

                this.getItemsData()
                return 'View'
            } else {
                this.ACTION
            }
        }
    },

    mounted(){
       
        this.$store.dispatch('getLists')
    },

    methods:{
        clearData(){
            this.checklist.list = [];
			this.checklist.check_list_trash = [];
			this.checklist.fillables.template_id = '';
            this.checklist.check_list_templates = [];
            this.checklist.template_lists = [];
        },

        getItemsData(){
            this.checklist.fillables.item_name        = this.GET_CHECKLIST_DIALOG_VIEW.item_name
            this.checklist.fillables.model            = this.GET_CHECKLIST_DIALOG_VIEW.model
            this.checklist.fillables.category         = this.GET_CHECKLIST_DIALOG_VIEW.category
            this.checklist.fillables.item_id          = this.GET_CHECKLIST_DIALOG_VIEW.item_id
            this.checklist.fillables.template_id      = this.GET_CHECKLIST_DIALOG_VIEW.qa_check_list_template_id
            this.checklist.check_list_templates       = this.GET_CHECKLIST_DIALOG_VIEW.check_list_templates
        },  

        getTemplate(){
            this.checklist.template_lists             = this.GET_ALL_LIST_TEMPLATE.find(e=>{if(e.id == this.checklist.fillables.template_id){return e.check_list_templates}});
            // console.log(this.checklist.template_lists.check_list_templates)

            this.checklist.check_list_templates       = this.checklist.template_lists.check_list_templates
        },
        
        removeCheckList(index){
            this.checklist.check_list_trash.push(this.checklist.check_list_templates[index]['id']);
            this.checklist.check_list_templates.splice(index, 1);
        },

        update(){
            this.activeTabs = this.GET_TABS;

            Swal.fire({
                title:'Update',
                text:'- Are you sure you want to Update?',
                icon: 'question',
                showCancelButton:true
            }).then(action=>{
                if(action.isConfirmed){
                    this.$store.dispatch(this.GET_CHECKLIST_DISPATCH.update[0],{
                        url:this.GET_CHECKLIST_DISPATCH.update[1],
                        item_id: this.checklist.fillables.item_id,
                        trash: this.checklist.check_list_trash,
                        check_list: this.checklist.check_list_templates,
                    }).then(response=>{
                        Swal.fire('Success!','','success')
                        this.$store.commit('TAB',this.activeTabs)
                        this.closeDialog()
                        this.$store.commit('REFRESH', true)
                    }).catch(err=>{
                        Swal.fire('Error!',err.response.data.message,'error')
                        this.closeDialog()
                    })
                }
            })

        },
        
        closeDialog() {
            this.clearData()
			this.$store.commit("DIALOG", false);
            this.$store.commit('CHECKLIST_DIALOG_VIEW', {})
		},

    },

    watch: {

        DIALOGS:{
            handler(val){
                // this.actions = val
            }
        }

    }
}
</script>

<style scoped>
</style>