<template>
    <v-container fluid>
        <ComponentHeader
            :button="true"
            :filterd="filters"
            :title="'Sales By Customer'"
            :type="'Sales'"
        ></ComponentHeader>
        <v-card>
            <v-card-text class="bg-bluish-gray">
                <v-row class="mx-auto" dense>
                    <v-col cols="3" style="padding: 3px">
                        <v-autocomplete
                            v-model="filters.mother_customer"
                            :items="GET_AFFLIATE_CUSTOMER"
                            :disabled="!!filters.customer"
                            label="Select Mother Customer"
                            background-color="white"
                            dense
                            outlined
                            hide-details
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="3" style="padding: 3px">
                        <v-autocomplete
                            v-model="filters.customer"
                            :items="GET_CUSTOMERS_SELECTION"
                            :disabled="!!filters.mother_customer"
                            label="Select Customer"
                            background-color="white"
                            dense
                            outlined
                            hide-details
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="3">
                        <v-autocomplete
                            v-model="filters.salesman"
                            :items="GET_SALESMAN_SELECTION"
                            label="Select Salesman"
                            background-color="white"
                            dense
                            outlined
                            hide-details
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="12" sm="12" md="2" lg="3" xl="3" class="d-flex justify-content-end" style="gap: 10px">
                        <v-btn :loading="exportData.loading" :disabled="dataTable.loading" small @click="list('xlsx');">Export</v-btn>
                        <v-btn :loading="dataTable.loading" :disabled="exportData.loading" small @click="list()">Filter</v-btn>
                    </v-col>
                </v-row>
                <v-row class="mx-auto" dense>
                    <v-col cols="3">
                        <v-autocomplete
                            v-model="filters.customer_category"
                            :items="GET_CUSTOMER_CATEGORY_SELECTION"
                            label="Select Category"
                            background-color="white"
                            dense
                            outlined
                            hide-details
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="3">
                        <v-autocomplete
                            v-model="filters.customer_classification"
                            :items="GET_CLASSIFICATIONS_SELECTION"
                            label="Select Classification"
                            background-color="white"
                            dense
                            outlined
                            hide-details
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="2">
                        <v-autocomplete
                            v-model="filters.customer_cluster"
                            :items="dropdowns.clusters"
                            label="Select Cluster"
                            background-color="white"
                            dense
                            outlined
                            hide-details
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="1">
                        <v-autocomplete
                            v-model="filters.trade"
                            :items="dropdowns.trade"
                            label="Trade"
                            background-color="white"
                            hide-details
                            clearable
                            dense
                            outlined
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row class="mx-auto" dense>
                    <v-col cols="1" class="mx-1" v-if="GET_USER_COSTING">
                        <v-checkbox
                            v-model="filters.show_cost"
                            :true-value="1"
                            :false-value="null"
                            label="SC"
                            color="white"
                            hide-details
                        ></v-checkbox>
                    </v-col>
                    <v-col cols="3" class="mx-1">
                        <label class="float-left control-label col-form-label">Date From</label>
                        <v-menu
                            v-model="menus.date_from"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_from"
                                    readonly
                                    append-icon="mdi-calendar-clock"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_from" @input="menus.date_from = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="3" class="mx-1">
                        <label class="float-left control-label col-form-label">Date To</label>
                        <v-menu
                            v-model="menus.date_to"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_to"
                                    readonly
                                    append-icon="mdi-calendar-clock"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_to" @input="menus.date_to = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <ReportsCardsComponent
                    :setClass="'mx-auto my-1'"
                    :totals="totals"
                    :cards="cards"
                    style="width:100%;"
                ></ReportsCardsComponent>
                <v-row class="mx-auto" dense>
                    <v-col cols="12">
                        <v-data-table
                            :headers="dataTable.headers"
                            :items="dataTable.items"
                            :server-items-length="dataTable.total"
                            :loading="dataTable.loading"
                            :options.sync="dataTable.pagination"
                            :expanded.sync="dataTable.expand.expanded"
                            :footer-props="{
                                showFirstLastPage: true,
                            }"
                            show-expand
                            single-expand
                            disable-sort
                            dense
                            @item-expanded="loadDetails"
                            @update:page="list()"
                            @update:items-per-page="list()"
                        >
                            <template #item.data-table-expand="{ item, expand, isExpanded }">
                                <td v-if="item.show_expand_icon || item.show_expand_icon == null" class="text-start">
                                    <v-btn
                                        icon
                                        class="v-data-table__expand-icon"
                                        :class="{'v-data-table__expand-icon--active' : isExpanded}"
                                        @click="expand(!isExpanded)"
                                    >
                                        <v-icon>mdi-chevron-down</v-icon>
                                    </v-btn>
                                </td>
                            </template>
                            <template #item.total_amount="{ item }">
                                {{ item.total_amount | currency }}
                            </template>
                            <template v-slot:expanded-item="{ headers, item }">
                                <td :colspan="headers.length" class="p-0">
                                    <v-data-table
                                        :headers="dataTable.expand.headers"
                                        :items="dataTable.expand.items"
                                        disable-sort
                                        disable-pagination
                                        hide-default-footer
                                        dense
                                        dark
                                    >
                                        <template #item.total_amount="{ item }">
                                            {{ item.total_amount | currency }}
                                        </template>
                                    </v-data-table>
                                </td>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
import ReportsCardsComponent from '@/views/main/layouts/Reports/ReportsCardsComponent.vue'
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue'
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            filters: {
                mother_customer: null,
                customer: null,
                salesman: null,
                customer_category: null,
                customer_classification: null,
                customer_cluster: null,
                show_cost: null,
                date_from: this.$dayjs().startOf('month').format('YYYY-MM-DD'),
                date_to: this.$dayjs().endOf('month').format('YYYY-MM-DD'),
                trade: 1,
            },
            menus: {
                date_from: false,
                date_to: false,
            },
            dataTable: {
                headers: [
                    { text: '', value: 'data-table-expand' },
                    { text: 'Customer', align: 'start', value: 'company_name', },
                    { text: 'Salesman', align: 'start', value: 'salesman_name', },
                    { text: 'Category', align: 'start', value: 'customer_category', },
                    { text: 'Classification', align: 'start', value: 'customer_classification', },
                    { text: 'Cluster', align: 'left', value: 'customer_clusters', },
                    { text: 'Amount', align: 'end', width: '10%', value: 'total_amount', },
                ],
                items: [],
                loading: false,
                expand: {
                    headers: [
                        { text: 'Customer', align: 'start', value: 'company_name', },
                        { text: 'Salesman', align: 'start', value: 'salesman_name', },
                        { text: 'Category', align: 'start', value: 'customer_category', },
                        { text: 'Classification', align: 'start', value: 'customer_classification', },
                        { text: 'Cluster', align: 'left', value: 'customer_clusters', },
                        { text: 'Amount', align: 'end', width: '10%', value: 'total_amount', },
                    ],
                    expanded: [],
                    items: [],
                },
            },
            exportData: {
                filename: 'Sales By Customer',
                file_ext: 'xlsx',
                columns: [
                    { header: 'CUSTOMER', key: 'company_name' },
                    { header: 'SALESMAN', key: 'salesman_name' },
                    { header: 'CUSTOMER CATEGORY', key: 'customer_category' },
                    { header: 'CUSTOMER CLASSIFICATION', key: 'customer_classification' },
                    { header: 'CUSTOMER CLUSTERS', key: 'customer_clusters' },
                    { header: 'TOTAL AMOUNT', key: 'total_amount', style: { numFmt: '#,##0.00' } },
                    { header: 'TRADE', key: 'trade', },
                ],
                items: [],
                loading: false,
            },
            cards: [
                { title: 'Net Sales Total', icon: 'mdi-cart', icon_class: 'total-amount', cols: 6, sm: 6, md: 3, lg: 3, xl: 3, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'net_sales_total_amount' },
                { title: 'Gross Sales Total', icon: 'mdi-cart', icon_class: 'total-amount', cols: 6, sm: 6, md: 3, lg: 3, xl: 3, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'gross_sales_total_amount' },
                { title: 'Net Adjustment', icon: 'mdi-cart', icon_class: 'total-amount', cols: 6, sm: 6, md: 3, lg: 3, xl: 3, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'adjustments_total_amount' },
                { title: 'Total Customer', icon: 'mdi-account-multiple-outline', icon_class: 'total-customer', cols: 6, sm: 6, md: 3, lg: 3, xl: 3, prefix: '', suffix: '', filter: '', value: 'customer_count' },
            ],
            cards_cost: [
                { title: 'Net Cost Total', icon: 'mdi-cart', icon_class: 'total-amount', cols: 6, sm: 6, md: 3, lg: 3, xl: 3, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'net_cost_total_amount' },
                { title: 'Cost % | Margin %', icon: 'mdi-cart', icon_class: 'total-amount', cols: 6, sm: 6, md: 3, lg: 3, xl: 3, prefix: '', suffix: '', filter: '', value: 'cost_margin_percentage' },
            ],
            totals: {
                net_sales_total_amount: 0,
                gross_sales_total_amount: 0,
                adjustments_total_amount: 0,
                customer_count: 0,
                net_cost_total_amount: 0,
                cost_margin_percentage: '',
            },
            dropdowns: {
                clusters: [],
                trade: [
                    { value: 1, text: 'Yes' },
                    { value: 0, text: 'No' },
                ],
            },
        }
    },
    mixins: [
        SharedFunctionsComponent,
    ],
    components: {
        ComponentHeader,
        ReportsCardsComponent,
    },
    mounted() {
        this.$store.dispatch('getUserCosting')
        this.$store.dispatch('getAffliatecustomer')
        this.$store.dispatch('getAllcustomersSelection', { include_mother: 0 })
        this.$store.dispatch('getAllsalesmanSelection')
        this.$store.dispatch('getallcustomerCategory')
        this.$store.dispatch('getAllcustomerClassificationSelection')
        this.$store.dispatch('getClusterSelection')
    },
    computed: {
        ...mapGetters([
            'GET_USER_COSTING',
            'GET_CUSTOMERS_SELECTION',
            'GET_SALESMAN_SELECTION',
            'GET_CUSTOMER_CATEGORY_SELECTION',
            'GET_CLASSIFICATIONS_SELECTION',
            'GET_CLUSTER_SELECTION',
            'GET_AFFLIATE_CUSTOMER',
        ]),
    },
    methods: {
        list(type = 'list') {
            if (type == 'list') {
                this.dataTable.loading = true
                this.dataTable.items = []
                this.dataTable.total = 0

                Object.keys(this.totals).forEach(e => {
                    this.totals[e] = 0
                })
            } else if (type == 'xlsx') {
                this.exportData.loading = true
            }

            this.$store.dispatch('reports', {
                page: this.dataTable.pagination.page,
                itemsPerPage: this.dataTable.pagination.itemsPerPage,
                mother_customer_id: this.filters.mother_customer,
                customer_id: this.filters.customer,
                salesman_id: this.filters.salesman,
                customer_category_id: this.filters.customer_category,
                customer_classification_id: this.filters.customer_classification,
                customer_cluster_id: this.filters.customer_cluster,
                date_from: this.filters.date_from,
                date_to: this.filters.date_to,
                exportToExcel: type == 'xlsx' ? 1 : 0,
                show_cost: this.filters.show_cost,
                trade: this.filters.trade,
                filename: 'Sales By Customer',
                url: 'sales-by-customer-with-affiliates',
                expand_url: 'reports/customer-affiliate-sales-totals/detail_id',
            }).then(response => {
                if (type == 'list') {
                    this.dataTable.items = response.data.items.data
                    this.dataTable.total = response.data.items.total

                    Object.assign(this.totals, {
                        net_sales_total_amount: response.data.net_sales_total_amount,
                        gross_sales_total_amount: response.data.gross_sales_total_amount,
                        adjustments_total_amount: response.data.adjustments_total_amount,
                        customer_count: response.data.customer_count,
                    })

                    this.dataTable.loading = false
                } else if (type == 'xlsx') {
                    this.exportData.items = response.data

                    const workbook = new this.$exceljs.Workbook()
                    const worksheet = workbook.addWorksheet('Summary')

                    worksheet.columns = this.exportData.columns
                    worksheet.addRows(this.exportData.items)

                    workbook.xlsx.writeBuffer().then((data) => {
                        const blob = new Blob([data], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
                        })

                        this.$filesaver.saveAs(
                            blob,
                            `${this.exportData.filename} ${this.filters.date_from} - ${this.filters.date_to}.${this.exportData.file_ext}`
                        )
                    })

                    this.exportData.loading = false
                }
            }).catch(error => {
                console.log(error)

                if (type == 'list') {
                    this.dataTable.loading = false
                } else if (type == 'xlsx') {
                    this.exportData.loading = false
                }
            })
        },
        async loadDetails(item, value) {
            if (!value) {
                this.dataTable.expand.items = []

                await this.$store.dispatch('salesReportExpansion', {
                    customer_id: this.filters.customer,
                    salesman_id: this.filters.salesman,
                    customer_category_id: this.filters.customer_category,
                    customer_classification_id: this.filters.customer_classification,
                    customer_cluster_id: this.filters.customer_cluster,
                    date_from: this.filters.date_from,
                    date_to: this.filters.date_to,
                    expansion_url: 'reports/customer-affiliate-sales-totals/' + item.item.id,
                }).then(response => {
                    this.dataTable.expand.items = response.data
                }).catch(error => {
                    console.log(error)
                    this.dataTable.expand.items = []
                })
            }
        },
    },
    watch: {
        'filters.show_cost': {
            handler(val) {
                if (val && this.GET_USER_COSTING) {
                    this.dataTable.headers.splice(1, 0, {
                        text: 'Total Cost', align: 'right', width: '10%', value: 'total_cost',
                    })
                } else {
                    let index = this.dataTable.headers.findIndex(f => {
                        return f.value == 'total_cost'
                    })

                    if (index !== -1) {
                        this.dataTable.headers.splice(index, 1)
                    }
                }
            },
            deep: true,
        },
        'GET_CLUSTER_SELECTION': {
            handler(val) {
                this.dropdowns.clusters = JSON.parse(JSON.stringify(val))
                this.dropdowns.clusters.push({
                    text: 'NO CLUSTER',
                    value: 0,
                })
            },
            deep: true,
        }
    }
};
</script>
