<template>
    <div class="tabs-body-color">
      <v-btn class="py-auto my-3" 
      v-if="INBOUND_OUTBOUND_BUTTON_LOADING" 
      @click="AddDialog()"
      >
        {{ STATUS.BOUND.find(e=>e.value == GET_SELECTED_TAB).text }} 
      </v-btn>
      <v-card fluid class="pa-0 rounded-0" style="overflow: hidden">
        <v-card-text>
          <v-row>
            <v-col lg="12">
              <BoundTableComponent v-if="STATUS.BOUND.find(e=>e.value == GET_SELECTED_TAB)"></BoundTableComponent>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </template>
  <script>
  import BoundTableComponent from "@/views/main/modules/Admin/Bound/BoundTableComponent.vue";
  import { mapGetters } from "vuex";
  export default {
    components: {
      BoundTableComponent,
    },
    data() {
      return {
        userAccess: {
          create: false,
        },
      };
    },
    computed: {
      ...mapGetters(["USER_ACCESS","GET_SELECTED_TAB","STATUS","INBOUND_OUTBOUND_BUTTON_LOADING"]),
    },
    mounted() {},
    methods: {
      AddDialog() {
        this.$store.commit("ADMIN_ITEMS_LIST_SECTION", true);
        this.$store.commit("DIALOG", true);
        this.$store.commit("ACTION", "Submit");
      },
    },
    watch: {
      USER_ACCESS: {
        handler(val) {
          if (val != "fail") {
            this.userAccess.create = false;
            val.forEach((e) => {
              if (e.actions_code == "create") {
                this.userAccess.create = true;
              }
            });
          }
        },
      },
      INBOUND_OUTBOUND_BUTTON_LOADING:{
        handler(val){
        },
        deep: true,
        immediate: true
      }
    },
  };
  </script>
  <style scoped>
  .rounded-0 {
    border-radius: 0;
  }
  </style>