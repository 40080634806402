<template>
    <v-card style="border:0px" elevation="0">
        <v-card-title class="d-flex justify-content-between" v-if="selected.is_selected" >
            <span style="font-size:12px">
                Quote #: <b>{{ selected.quote_num }}</b>
            </span>
            <v-btn @click="list()" text icon  color="red">
                <v-icon>
                    mdi-arrow-left-drop-circle
                </v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
            <v-data-table
            :headers="dataTable.headers"
            :items="dataTable.items"
            :loading="!dataTable.loaded"
            dense
        >
            <template v-slot:item.foc="{ item }">
                <v-avatar
                    :color="item.foc == 1 ? 'success' : 'error'"
                    size="15"
                ></v-avatar>
            </template>

            <template v-slot:item.total_amount="{ item }">
                {{ item.total_amount | currency }}
            </template>

            <template v-slot:item.srp="{ item }">
                {{ item.srp | currency }}
            </template>

            <template v-slot:item.actions="{ item }">
                <v-btn text icon color="orange">
                    <v-icon class="btn-action" small @click="list(item)">mdi-eye</v-icon>
                </v-btn>
            </template>
        </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props:{
        rfIds:{
            type:Array,
            default:()=>[]
        },
        headers:{
            type:Array,
            default:()=>[
                { text: 'Quotation No', value: 'quote_num' },
                { text: 'Total Amount', value: 'total_amount', align: 'right' },
                { text: 'Action',align:'center', value: 'actions' },
            ]
        },
        itemHeaders:{
            type:Array,
            default:()=>[
                { text: 'Model', value: 'model' },
                { text: 'Name', value: 'name', },
                { text: 'FOC', value: 'foc',align: 'center'},
                { text: 'SRP', value: 'srp',align: 'right' },
                { text: 'Total Amount', value: 'total_amount',align: 'right' },
            ]
        }
    },
    data(){
        return{
            dataTable:{
                loaded:true,
                items:[],
                headers:[]
            },
            selected:{
                is_selected:false,
                quote_num:''
            }
        }
    },
    mounted(){
        this.list()
    },
    methods:{
        async list(item = null){
            if(!!item){
                this.selected.quote_num = item.quote_num
                this.dataTable.headers = this.itemHeaders
            }else{
                this.dataTable.headers = this.headers
            }
            this.selected.is_selected = false
            this.dataTable.loaded = false
            this.dataTable.items = []
            await this.$store.dispatch('salesquotationGet',{
                url:!!item ? 'service-estimation-sales-quotation-items': 'service-estimation-sales-quotation',
                rf_ids:this.rfIds
            }).then(response=>{
                this.dataTable.items = response.data
            }).catch(err=>{
                console.log(err)
            })

            this.dataTable.loaded = true
        }
    },
    watch:{
        'rfIds':{
            handler(val){
                this.list()
            }
        }
    }
}
</script>

<style>

</style>
