<template>
    <div class="w-100">
        <v-app id="my_vue_app">
            <v-card flat>
                <v-row class="d-flex justify-end px-6 ma-4">
                    <v-col cols="3">
                        <v-row>
                        <v-col cols="11">
                            <v-text-field
                                id="searchBar"
                                v-model="filters.search"
                                label="Search Code / Name"
                                single-line
                                hide-details
                                @change="searchInput()"
                                @keydown.enter="searchInput()"

                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="1" class="pt-5">
                            <v-icon @click="searchInput()">mdi-magnify</v-icon>
                        </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-container fluid>
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        :search="filters.search"
                        :options.sync="options"
                        :server-items-length="totalItems"
                        :items-per-page="10"
                        :loading="loaded"
                        :footer-props="{
                            itemsPerPageOptions: itemsPerPageOptions,
                        }"
                        loading-text="Loading... Please wait"
                        fixed-header
                        disable-sort
                        @update:options="getAllAdminDeliverAdresses()"
                        >
                        <template v-slot:[`item.action`]="{ item }">
                            <v-btn text icon color="orange" v-if="userAccess.view" >
                                <v-icon class="btn-action" small @click="viewDeliveryAddresses(item)"
                                >mdi-eye</v-icon
                                >
                            </v-btn>
                            <v-btn text icon color="blue" v-if="userAccess.edit && deliveryAddressModule">
                                <v-icon class="btn-action" small @click="updateDeliveryAddresses(item)"
                                >mdi-square-edit-outline</v-icon
                                >
                            </v-btn>
                            <v-btn text icon color="red" v-if="userAccess.delete && deliveryAddressModule">
                                <v-icon class="btn-action" small @click="deleteDeliveryAddresses(item)"
                                >mdi-delete</v-icon
                                >
                            </v-btn>
                        </template>
                    </v-data-table>
                        <!-- :show-select="['assetslist-basket','assetslist'].includes(GET_ADMIN_TAB_IS_ACTIVE)" -->
                </v-container>
            </v-card>
            <AdminDeliveryAddressesViewComponent :dialogState="dialogState" @successTransaction="successTransaction" @closeDialog="closeDialog"></AdminDeliveryAddressesViewComponent>
        </v-app>
    </div>
</template>

<script>
import AdminDeliveryAddressesViewComponent from '@/views/main/layouts/dialogs/Admin/AdminDeliveryAddressesViewComponent.vue';
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2';
export default {
    components: {
        AdminDeliveryAddressesViewComponent
    },
    props:{
        // 
    },
    data(){
        return {
            dialogState:false,
            loaded: false,
            totalItems: 0,
            options: {},
            items: [],
            filters:{
                search: ''
            },
            itemsPerPageOptions: [5, 10, 15, 50, 100],
            itemsPerPage: 10,
            search: "",
            headers: [
                { text: "Code", align: "left", value: "code" },
                { text: "Name", align: "left", value: "name" },
                { text: "Address", align: "left", value: "address" },
                { text: "Contact Number", align: "left", value: "contact_number" },
                { text: "Action", align: "center", value: "action" },
            ],
            userAccess: {
                view: false,
                create: false,
                edit: false,
                delete: false,
            },
        }
    },
    computed: {
        ...mapGetters([
            'USER_ACCESS'
        ]),
        deliveryAddressModule() {
            if (this.$route.name == 'admindeliveryaddresses') {
                return true;
            }
            return false
        }
    },
    methods:{
        getAllAdminDeliverAdresses() {
            this.items = [];
            this.loaded = true;
            let payload = {
                page: this.options.page,
                itemPerPage: this.options.itemsPerPage,
                search: !!this.search ? this.search.trim() : '',
                search:this.filters.search,
                url:'admin-delivery-addresses'
            }
            this.$store.dispatch('adminDeliveryAddressesGet', payload).then( response => {
                let data = response.data;
                this.items = data.data;
                this.totalItems = data.total;
                this.loaded = false;
            }).catch($e => {
                console.log($e);
                this.loaded = false;
            })
        },
        closeDialog(val) {
            this.dialogState = val;
        },
        searchInput() {
            this.options.page = 1;
            this.getAllAdminDeliverAdresses();
        },
        viewDeliveryAddresses(item) {
            this.dialogState = true;
            this.$store.commit('ADMIN_DELIVERY_ADDRESSES_ACTION', 'View');
            this.$store.commit('SELECTED_DELIVERY_ADDRESSES', item);
        },
        updateDeliveryAddresses(item) {
            this.dialogState = true;
            this.$store.commit('ADMIN_DELIVERY_ADDRESSES_ACTION', 'Edit');
            this.$store.commit('SELECTED_DELIVERY_ADDRESSES', item);

        },
        deleteDeliveryAddresses(item) {
            this.$store.dispatch('adminDeliveryAddressesDelete', {url:`admin-delivery-addresses/${item.id}`}).then( response => {
                Swal.fire(response.data);
                this.getAllAdminDeliverAdresses();
            })
        },
        successTransaction() {
            this.getAllAdminDeliverAdresses();
        }

    },
    watch:{
        USER_ACCESS: {
            handler(val) {
                if (val != "fail") {
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                this.userAccess.create = false;
                val.forEach((e) => {
                    if (e.actions_code == "edit") {
                        this.userAccess.edit = true;
                    } else if (e.actions_code == "delete") {
                        this.userAccess.delete = true;
                    } else if (e.actions_code == "view") {
                        this.userAccess.view = true;
                    } else if (e.actions_code == "create") {
                        this.userAccess.create = true;
                    }
                });
                }
            },
        },
    },
    mounted(){
        this.getAllAdminDeliverAdresses();
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id);
    }
}
</script>

<style>

</style>