<template>
    <v-container fluid >
        <h3 class="page-title">FAS ITEMS</h3>
        <v-container class="ma-0">
            <v-app id="item-tabs">
                <v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" background-color="#424242" dark show-arrows>
                    <v-tabs-slider color="cyan"></v-tabs-slider>
                    <v-tab ripple v-for="(item, i) in items" :key="item" @click="tabs(i)">
                    {{ item }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
        <v-tab-item v-for="(table, index) in items" :key="index">
          <keep-alive>
            <component :is="adminitem[index].components" :isActive_tab="adminitem[index].status_text">
            </component>
          </keep-alive>
        </v-tab-item>
      </v-tabs-items>
            </v-app>
        </v-container>
        <DialogComponent :cp="components" :scrollable="dialog_scrollable" :width="width(dialog_width)" :retainfocus="false"></DialogComponent>
    </v-container>
  </template>
  
  <script>
  import DialogComponent from '@/views/main/layouts/dialogs/Dialog.vue';
  import FasItemsComponent from '@/views/main/modules/Admin/FASItems/FasItemsComponent.vue';
  import FasItemViewComponent from '@/views/main/layouts/dialogs/Admin/FasItemViewComponent.vue';
  import FasItemBatchFile from '@/views/main/layouts/dialogs/Admin/FasItemBatchFile.vue';
  import { mapGetters } from 'vuex';
  export default {
    components:{
        DialogComponent,
        FasItemViewComponent,
        FasItemBatchFile,
        FasItemsComponent,
    },
    data() {
        return {
          form: {
              code: '',
              name: '',
              sub_class:[],
              sub_class_trash:[],
            },
            components:'',
            items: ["ALL","INACTIVE ITEMS"],
            adminitem: [
                    {
                    components: FasItemsComponent,
                    status_text: 1,
                    dialog: '',
                    dialog_width: "35%",
                    scrollable:true,
                    dispatch:{
                             get:'getAlladminfasitems',
                              add:'CreateAdminfasItem',
                              update:'Updateadminfasitems',
                              delete:'Deleteadminfasitems'
                            },
                    },
                    {
                    components: FasItemsComponent,
                    status_text: 0,
                    dialog: FasItemViewComponent,
                    dialog_width: "70%",
                    scrollable:true,
                    dispatch:{
                              get:'getAlladminfasitems',
                              add:'CreateAdminfasItem',
                              update:'Updateadminfasitems',
                              delete:'Deleteadminfasitems'
                            },
                    },
            ],
            
            dialog_width: "",
            loaded: false,
            options: {},
            itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
            itemsPerPage: 10,
            tab: null,
            dialog_scrollable:true,
        };
    },
    computed:{
        ...mapGetters(['GET_NEW_ADMIN_OTHER_TABLE','GET_NEW_ADMIN_ASSETS_TABLE','GET_ADD_FAS_TYPE','DIALOGS'])
    },
    mounted(){
          this.dropDowns()
          this.tabs(0);
    },
    methods:{
    dropDowns(){
          this.$store.dispatch('getDepartment')
          this.$store.dispatch("getAdminSupplierSelection");
          this.$store.dispatch("getAdminClassificationSelection");
          this.$store.dispatch('getitemUomSelection');
          this.$store.dispatch("getAdminBrandSelection");
    },
    tabs(key) {
       this.dialog_width = this.adminitem[key].dialog_width;
       this.dialog_scrollable = this.adminitem[key].scrollable;
       this.$store.commit('ADMIN_FASITEM_STATUS',this.adminitem[key].status_text)
       this.$store.commit('ADMIN_FASITEM_DISPATCH',this.adminitem[key].dispatch)
    },
    width(width) {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return "100%"
          case 'sm': return "100%"
          case 'md': return width
          case 'lg': return width
          case 'xl': return width
        }
      },
    },
    watch:{
      DIALOGS:{
        handler(val){
          this.components = this.GET_ADD_FAS_TYPE=='from_add'?FasItemViewComponent:FasItemBatchFile;
        }
      }

    }
    
  };
  </script>
  <style>
  #group-tab {
    padding: 0 !important;
  }
  </style>
  