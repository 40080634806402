<template>
    <div class="w-100">
        <v-app id="my_vue_app">
            <v-card>
                <v-card-title>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" sm="9" class="d-flex justify-content-start">
                                <v-row>
                                    <v-col cols="12" lg="8" class="d-flex justify-content-between">
                                        <v-container fluid class="d-flex justify-content-between">

                                        </v-container>
                                    </v-col>
                                    <v-col cols="12" md="1">

                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="3">
                                <div class="row col-md-12 align-items-end">
                                    <v-text-field
                                        id="searchBar"
                                        v-model="filter.search"
                                        label="Search"
                                        single-line
                                        hide-details
                                        @change="searchInput()"
                                    >
                                    </v-text-field>
                                        <v-icon @click="searchInput()">mdi-magnify</v-icon>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-title>
                <v-container fluid>
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        :search="search"
                        :options.sync="options"
                        :server-items-length="totalItems"
                        :items-per-page="10"
                        :loading="!loaded"
                        :footer-props="{
                                itemsPerPageOptions: itemsPerPageOptions,
                        }"
                        loading-text="Loading... Please wait"
                        fixed-header
                        disable-sort
                    >
                        <template v-slot:[`item.action`]="{ item }">
                            <v-btn text icon color="orange" v-if="userAccess.view">
                                <v-icon class="btn-action" small @click="viewItemUom(item,'View')">
                                    mdi-eye
                                </v-icon>
                            </v-btn>
                            <v-btn text icon color="blue" v-if="userAccess.edit">
                                <v-icon class="btn-action" small @click="viewItemUom(item,'Update')">
                                    mdi-square-edit-outline
                                </v-icon>
                            </v-btn>
                           <v-btn text icon color="red" v-if="userAccess.delete">
                                <v-icon class="btn-action" small @click="deleteItemUom(item.id)">
                                    mdi-delete
                                </v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-container>
            </v-card>
        </v-app>
    </div>
</template>
<script>
import swal from "sweetalert2";
import { mapGetters } from 'vuex';
import SharedFunctionsComponent from "@/views/main/Shared/SharedFunctionsComponent.vue";

export default {
    mixins:[SharedFunctionsComponent],
    data() {
        return {
            loaded: false,
            totalItems: 0,
            options: {},
            items: [],
            itemsPerPageOptions: [5, 10, 15, 50, 100],
            itemsPerPage: 10,
            search: "",
            filter:{
                search:"",
            },
            headers: [
                { text: "Name", align: "left", value: "name" },
                { text: "Code", align: "left", value: "code" },
                { text: "Description", align: "left", value: "description" },
                { text: "Action", align: "center", value: "action",sortable:false},
            ],
            userAccess: {
                view: false,
                create: false,
                edit: false,
                delete: false,
            }
        };
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS',
            'GET_ADMIN_ITEM_DISPATCH',
            'GET_ADMIN_UOM_SELECTION',
            'GET_NEW_ADMIN_ITEM',
            "GET_EXPORT_ITEMS",
            "GET_EXPORT_ITEMS_WITH_PRICE",
        ])
    },
    mounted() {
        this.getAllAdminItemUom();
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
    },
    methods: {
        getAllAdminItemUom(filter = null) {
            this.items = [];
            this.loaded = false;
            let payload = {
                url: this.GET_ADMIN_ITEM_DISPATCH.get,
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                search: this.filter.search,
                filter: filter,
            };
            this.$store.dispatch('adminItemGet', payload).then((response) => {
                this.items = response.data.data.data;
                this.loaded = true;
                this.totalItems = response.data.data.total;

            }).catch((e)=>{
                this.loaded = true;
            });
        },
        searchInput(){
            this.options.page = 1
            this.getAllAdminItemUom({ query: this.search });
        },
        async viewItemUom(item,action) {
            await this.$store.dispatch('adminItemGet',{
                        url:'AdminViewAdminItemUom/'+item.id,
                    }).then(response=>{
                        this.$store.commit("EDIT_ADMIN_ITEM_UOM", response.data);
                    }).catch(e=>{
                        console.log(e)
                        swal.fire('Error','','error')
                    })
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", action);
        },
        deleteItemUom(id) {
            swal.fire({
            icon: "warning",
            text: "Are you sure you want to Delete?",
            cancelButtonText: "Cancel",
            reverseButtons: true,
            showCancelButton: true,
            allowOutsideClick: false,
            cancelButtonColor: "#grey",
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch("adminItemGet", {
                    url: this.GET_ADMIN_ITEM_DISPATCH.delete,
                    id: id
                    }).then(response=>{
                        this.getAllAdminItemUom();
                        swal.fire('Success', '', 'success');
                    });
                }
            });
        },
    },
    watch: {
        options: {
            handler() {
                this.getAllAdminItemUom();
            },
            deep: true,
        },
        GET_NEW_ADMIN_ITEM:{
            handler(val){
                if(val){
                    this.getAllAdminItemUom();
                }
            }
        },
        USER_ACCESS: {
            handler(val) {
                if (val != "fail") {
                    this.userAccess.edit = false;
                    this.userAccess.delete = false;
                    this.userAccess.view = false;
                    this.userAccess.create = false;
                    val.forEach((e) => {
                        if (e.actions_code == "edit") {
                            this.userAccess.edit = true;
                        } else if (e.actions_code == "delete") {
                            this.userAccess.delete = true;
                        } else if (e.actions_code == "view") {
                            this.userAccess.view = true;
                        } else if (e.actions_code == "create") {
                            this.userAccess.create = true;
                        }
                    });
                }
            },
        },
    },
};
</script>