<template>
    <v-form ref="form" v-model="valid" lazy-validation>
        <BaseFileViewer></BaseFileViewer>
        <v-card>
            <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                <span class="text-h5">{{GET_HR_DIALOG_TITLE}}</span>
                <v-row class="m-0">
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                            text
                            icon
                            small
                            color="gray"
                            class="float-right"
                            @click="closeDialog()"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-container class="pb-0 my-2" fluid>
                     <v-row dense>
                        <v-col cols="6" lg="2" md="2" sm="2">
                           <template>
                                <v-menu
                                    v-model="menu1"
                                    :close-on-content-click="false"
                                    :disabled="disabled_view"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            v-model="form.ot_date"
                                            :rules="errors.required"
                                            :height="40"
                                            readonly
                                            densed
                                            append-icon="mdi-calendar-clock"
                                            label="OT Date"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="form.ot_date"
                                    :allowed-dates="allowedDates"
                                    year-icon="mdi-calendar-blank"
                                    prev-icon="mdi-skip-previous"
                                    next-icon="mdi-skip-next"
                                    color="#f69f1a"
                                    @input="menu1 = false, initialForm(1)"
                                    ></v-date-picker>
                                </v-menu>
                            </template>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="2" v-if="!disabled_view">
                            <v-card>
                                <v-card-text>
                                    <v-row no-gutters>
                                        <v-col>
                                            Legend:
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col v-for="(item, i) in legends" :key="i">
                                            <v-icon :color="item.color">
                                                {{ item.icon }}
                                            </v-icon>
                                                {{ item.text }}
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="6" lg="2" md="2" sm="2">
                            <v-text-field
                                v-model="form.remarks"
                                :height="50"
                                :readonly="disabled_view"
                                label="OT Remarks"
                                outlined
                                required
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-container fluid class="pa-0 ma-0">
                            <v-card class="pa-0">
                                <v-card-title v-if="actions != 'Submit'">
                                    <v-row >
                                        <v-spacer></v-spacer>
                                        <v-col cols="3" class="pa-2 d-flex justify-content-end pr-4">
                                            <a
                                            v-for="(status, index ) in statuses" :key="index"
                                            :class="`ml-1 badge badge-${status.text} ${status.value == selected_value ? `selected` : `non-selected`}`" @click="filterItems(status)"
                                            >
                                            {{ status.text ? status.text : null  }}
                                            </a>
                                        </v-col>
                                    </v-row>
                                </v-card-title>
                                <v-card-title class="pr-0 pt-1 pr-1 pb-0 border-bottom"  >
                                    <v-row class="m-0" v-if="actions != 'View'">
                                        <v-col cols="pull-right-10">
                                            <v-btn
                                                fab
                                                dark
                                                small
                                                color="primary"
                                                style="height: 20px; width: 20px"
                                                @click="addEmployee()"
                                                class="float-right"
                                            >
                                                <v-icon dark small style="font-size: 12px"
                                                >mdi-plus</v-icon
                                                >
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-row v-else>
                                        <v-spacer></v-spacer>
                                    </v-row>
                                </v-card-title>

                                <v-card-text>
                                    <v-simple-table height="300px">
                                        <thead>
                                            <tr>
                                                <th width="1%" v-if="disabled_view && (userAccess.includes('approveOt') || USERACCOUNT_LOAD.manager == 1)">
                                                    <v-row>
                                                    <v-tooltip bottom color="#00897B">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                v-if="userAccess.includes('approveOt') || USERACCOUNT_LOAD.manager == 1"
                                                                icon
                                                                small
                                                                @click="approveSelected()"
                                                                color="#00897B"
                                                                class="mr-1"
                                                            >
                                                                <v-icon>mdi-account-multiple-check</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Approve/Confirm Selected</span>
                                                    </v-tooltip>
                                                    <v-tooltip bottom color="#D32F2F">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                v-if="userAccess.includes('rejectOt') || USERACCOUNT_LOAD.manager == 1"
                                                                icon
                                                                small
                                                                @click="rejectSelected()"
                                                                color="#D32F2F"
                                                            >
                                                                <v-icon>mdi-account-multiple-remove</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Reject Selected</span>
                                                    </v-tooltip>
                                                    </v-row>
                                                </th>
                                                <th width="19%">Employee</th>
                                                <th width="9%">OT Time In</th>
                                                <th width="9%">OT Time Out</th>
                                                <th width="8%">OT Minutes</th>
                                                <th width="8%">Late Minutes</th>
                                                <th width="8%">Regular Rate Mins.</th>
                                                <th width="8%">OT Rate Mins.</th>
                                                <!-- <th>Gross OT Hours</th>
                                                <th>Net OT Hours</th> -->
                                                <th v-if="disabled_view">Status</th>
                                                <th :width="disabled_view ? '10%' : '25%'">Reason</th>
                                                <th width="10%" v-if="disabled_view">Reject Reason</th>
                                                <th  width="7%" class="flex text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="loading.row">
                                                <td :colspan="10">
                                                <!-- Loading indicator (you can customize this based on your UI) -->
                                                <v-progress-linear indeterminate color="primary"></v-progress-linear>
                                                </td>
                                            </tr>
                                            <tr
                                                v-for="(
                                                data, i
                                                ) in form.Employee"
                                                :key="i"
                                                v-else
                                            >
                                                <td dense v-if="disabled_view && (userAccess.includes('approveOt') || USERACCOUNT_LOAD.manager == 1)">
                                                   <v-checkbox
                                                   v-model="data.selected_employee"
                                                   :disabled="data.status == 2 || data.status == 4 "
                                                   :value="selected_employee[data.id]"
                                                   @click="select_id(data.id, i)"
                                                   >
                                                   </v-checkbox>
                                                </td>
                                                <td dense>
                                                    <v-autocomplete
                                                        v-model="data.employee_id"
                                                        @change="checkEmployeeBioDate(data, data.employee_id)"
                                                        :items="dropdowns.employee_list"
                                                        :readonly="disabled_view"
                                                        :disabled="loading.employee_dropdown || !form.ot_date"
                                                        :rules="errors.required"
                                                        :background-color="data.status == 2  ? 'red' : 'transparent'"
                                                        :loading="loading.employee_dropdown"
                                                        item-disabled="isDisabled"
                                                        item-text="full_name"
                                                        item-value="value"
                                                        auto-select-first
                                                        outlined
                                                        required
                                                        dense
                                                    >
                                                        <template v-slot:item="data">
                                                            <v-list-item-content> {{ data.item.full_name }} </v-list-item-content>
                                                            <v-tooltip bottom color="green" v-if="data.item.hasLeave" v-model="data.item.hasLeave.show">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-list-item-icon>
                                                                        <v-icon
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            color="green"
                                                                        >
                                                                            mdi-walk
                                                                        </v-icon>
                                                                    </v-list-item-icon>
                                                                </template>
                                                                <span>Employee On {{ data.item.hasLeave.leave_type_name}} from {{ data.item.hasLeave.leave_date }}</span>
                                                            </v-tooltip>
                                                            <v-tooltip bottom color="red" v-if="data.item.active == 0">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-list-item-icon>
                                                                        <v-icon
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            color="red"
                                                                        >
                                                                            mdi-account-off
                                                                        </v-icon>
                                                                    </v-list-item-icon>
                                                                </template>
                                                                <span>Employee is inactive</span>
                                                            </v-tooltip>
                                                        </template>
                                                    </v-autocomplete>
                                                </td>

                                                <td >
                                                    <v-menu
                                                        v-model="data.ot_time_in_menu"
                                                        :disabled="disabled_view || !form.ot_date || !data.employee_id"
                                                        :close-on-content-click="false"
                                                        transition="scale-transition"
                                                        offset-y
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                v-model="data.ot_time_in"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                :disabled="!form.ot_date && !data.employee_id"
                                                                readonly
                                                                :rules="errors.required"
                                                                :background-color="data.status == 2  ? 'red' : 'transparent'"
                                                                append-icon="mdi-alarm"
                                                                outlined
                                                                dense
                                                                flat
                                                            ></v-text-field>
                                                        </template>
                                                        <v-time-picker format="24hr"  @click:hour="calculateOtHours(data, i)" :max="data.ot_time_out" scrollable @click:minute="data.ot_time_in_menu = false, calculateOtHours(data, i)" v-model="data.ot_time_in"></v-time-picker>
                                                        <!-- <v-time-picker format="24hr" :min="data.ot_min_time_in" @click:hour="calculateOtHours(data, i)" :max="data.ot_time_out" scrollable @click:minute="data.ot_time_in_menu = false, calculateOtHours(data, i)" v-model="data.ot_time_in"></v-time-picker> -->
                                                    </v-menu>
                                                </td>
                                                <td>
                                                    <v-menu
                                                        v-model="data.ot_time_out_menu"
                                                        :close-on-content-click="false"
                                                        :disabled="disabled_view || !form.ot_date || !data.employee_id"
                                                        transition="scale-transition"
                                                        offset-y
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                v-model="data.ot_time_out"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                :disabled="!form.ot_date && !data.employee_id"
                                                                readonly
                                                                :rules="errors.required"
                                                                :background-color="data.status == 2  ? 'red' : 'transparent'"
                                                                append-icon="mdi-alarm"
                                                                outlined
                                                                dense
                                                                flat
                                                            ></v-text-field>
                                                        </template>
                                                        <v-time-picker format="24hr" @click:hour="calculateOtHours(data, i)" scrollable @click:minute="calculateOtHours(data, i) , data.ot_time_out_menu = false" v-model="data.ot_time_out"></v-time-picker>
                                                        <!-- <v-time-picker format="24hr" :min="data.ot_min_time_in ? data.ot_min_time_in : data.ot_time_in" :max="data.ot_max_time_out" @click:hour="calculateOtHours(data, i)" scrollable @click:minute="calculateOtHours(data, i) , data.ot_time_out_menu = false" v-model="data.ot_time_out"></v-time-picker> -->
                                                    </v-menu>
                                                </td>

                                                <td>
                                                    <v-text-field
                                                       v-model="data.ot_minutes"
                                                        readonly
                                                        :background-color="data.status == 2  ? 'red' : 'grey lighten-2'"
                                                        :height="20"
                                                        :disabled="!form.ot_date"
                                                        type="decimal"
                                                        outlined
                                                        required
                                                        dense
                                                    ></v-text-field>
                                                </td>
                                                <td>
                                                    <v-text-field
                                                       v-model="data.late_in_minutes"
                                                        readonly
                                                        :background-color="data.status == 2  ? 'red' : 'grey lighten-2'"
                                                        :height="20"
                                                        :disabled="!form.ot_date"
                                                        type="decimal"
                                                        outlined
                                                        required
                                                        dense
                                                    ></v-text-field>
                                                </td>
                                                <td>
                                                    <v-text-field
                                                       v-model="data.regular_rate_minutes"
                                                        :background-color="data.status == 2  ? 'red' : 'grey lighten-2'"
                                                        :height="20"
                                                        :disabled="!form.ot_date"
                                                        readonly
                                                        type="decimal"
                                                        outlined
                                                        required
                                                        dense
                                                    ></v-text-field>
                                                </td>
                                                <td>
                                                    <v-text-field
                                                       v-model="data.ot_rate_minutes"
                                                        :background-color="data.status == 2  ? 'red' : 'grey lighten-2'"
                                                        :height="20"
                                                        :disabled="!form.ot_date"
                                                        readonly
                                                        type="decimal"
                                                        outlined
                                                        required
                                                        dense
                                                    ></v-text-field>
                                                </td>

                                                <td v-if="disabled_view">
                                                    <span :class="`badge badge-${badgeStatus(data.status)}`">{{ badgeStatus(data.status) }}</span>
                                                </td>
                                                <td>
                                                    <v-textarea
                                                       v-model="data.ot_reason"
                                                        :rules="errors.required"
                                                        :readonly="disabled_view"
                                                        :disabled="!form.ot_date"
                                                        :rows="1"
                                                        :background-color="data.status == 2  ? 'red' : 'transparent'"
                                                        label="OT Reason"
                                                        auto-grow
                                                        no-resize
                                                        outlined
                                                        required
                                                        dense
                                                    ></v-textarea>
                                                </td>

                                                <td v-if="disabled_view">
                                                    <v-text-field
                                                        v-model="data.reject_reason"
                                                        :readonly="userAccess.includes('rejectOt') && data.status == 1 ? false : true"
                                                        :background-color="data.status == 2  ? 'red' : 'transparent'"
                                                        :rules="errors.required"
                                                        outlined
                                                        label="Reject Reason"
                                                        required
                                                        dense
                                                        @change="rejectReasonToAll(data)"
                                                    ></v-text-field>
                                                </td>
                                                <td v-if="!disabled_view">
                                                    <v-row>
                                                        <v-tooltip bottom color="#546E7A">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    icon
                                                                    small
                                                                    color="#546E7A"
                                                                    @click="viewFiles(data.id, i)"
                                                                >
                                                                    <v-icon>mdi-file-document-multiple-outline</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>View File</span>
                                                        </v-tooltip>
                                                        <input type="file" @change="handleFileUpload(data, $event, i)" multiple style="display: none;" ref="fileInput" accept=".jpg,.jpeg,.png" />
                                                        <v-tooltip bottom color="#FB8C00">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    :rules="errors.required"
                                                                    icon
                                                                    small
                                                                    color="#FB8C00"
                                                                    @click="$refs.fileInput[i].click()"
                                                                >
                                                                    <v-icon>mdi-paperclip-plus</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Upload Proof</span>
                                                        </v-tooltip>
                                                        <v-tooltip bottom color="red">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    icon
                                                                    small
                                                                    color="red"
                                                                    @click="deleteEmployee(i, data.id)"
                                                                >
                                                                    <v-icon>mdi-delete</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Delete Employee</span>
                                                        </v-tooltip>
                                                    </v-row>
                                                </td>
                                                <td v-else>
                                                    <v-row class="d-flex justify-content-center">
                                                        <!-- <BaseFileViewer></BaseFileViewer> -->
                                                        <v-tooltip bottom color="#546E7A">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    color="#546E7A"
                                                                    icon
                                                                    small
                                                                    @click="viewFiles(data.id, i)"
                                                                >
                                                                    <v-icon>mdi-file-document-multiple-outline</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>File Viewer</span>
                                                        </v-tooltip>
                                                        <v-tooltip bottom color="#00897B" v-if="data.status == 1">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    v-if="(userAccess.includes('approveOt') || USERACCOUNT_LOAD.manager == 1) && ![2, 4].includes(data.status)"
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    icon
                                                                    small
                                                                    color="#00897B"
                                                                    @click="approveOvertime(data, i, data.status == 1 ? 'approve' : 'confirm')"
                                                                >
                                                                    <v-icon>mdi-check-bold</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Approve OT</span>
                                                            <!-- <span v-if="data.status == 3">Confirm OT</span> -->
                                                        </v-tooltip>
                                                        <v-tooltip bottom color="#D32F2F">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    v-if="(userAccess.includes('rejectOt') || USERACCOUNT_LOAD.manager == 1) && [1].includes(data.status)"
                                                                    icon
                                                                    small
                                                                    color="#D32F2F"
                                                                    @click="rejectOvertime(data, i)"
                                                                >
                                                                    <v-icon>mdi-close-thick</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Reject OT</span>
                                                        </v-tooltip>
                                                    </v-row>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </v-simple-table>
                                </v-card-text>
                            </v-card>
                        </v-container>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions
                class="text-center"
                style="border-top: 1px solid #dee2e6"
            >
                <v-row class="ma-1 pb-2">
                    <v-col cols="12" v-if="actions != 'View'">
                        <v-btn
                            @click="
                                actions == 'Submit'? addEmployeeOvertime() : updateEmployeeOvertime()"
                            :disabled="disabled_view"
                            :loading="loading.button"
                        >
                            <span>{{ actions }}</span>
                        </v-btn>
                        <span class="pr-12">&nbsp;</span>
                    </v-col>
                    <!-- <v-col cols="12" v-if="actions == 'View' && this.GET_HR_OTHER_TABS_DIALOG_VIEW.status != 2 && GET_HR_OTHER_TABS_DIALOG_VIEW.status !== 3">
                        <v-btn
                            v-if="userAccess.includes('approveOvertime') || USERACCOUNT_LOAD.manager == 1"
                            @click="approvedEmployeeOvertime()"
                        >
                            <span>Approve</span>
                        </v-btn>
                        <span class="pr-12">&nbsp;</span>
                    </v-col>
                    <v-col cols="12" v-if="actions == 'View' && GET_HR_OTHER_TABS_DIALOG_VIEW.status == 2 && GET_HR_OTHER_TABS_DIALOG_VIEW.status !== 3">
                        <v-btn
                            v-if="userAccess.includes('confirmOvertime') || USERACCOUNT_LOAD.manager == 1"
                            @click="approvedEmployeeOvertime()"
                        >
                            <span>Confirm</span>
                        </v-btn>
                        <span class="pr-12">&nbsp;</span>
                    </v-col> -->
                </v-row>
            </v-card-actions>
        </v-card>
    </v-form>
</template>
<script>
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
import FileUploadComponent from '@/views/main/Shared/FileUploadComponent.vue'
import BaseFileViewer from "@/views/main/layouts/dialogs/HR/Overtime/HROvertimeFileViewerComponent.vue"
import CameraLayout from '@/views/main/layouts/Camera/CameraComponent.vue';
import Swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
    mixins: [SharedFunctionsComponentVue],
    data() {
        return {
            valid: false,
            overlay: false,
            disabled_view: false,
            menu1: false,
            errors: {
                required: [(v) => !!v || "Field is required"],
                number: [
                    (v) => !!v || "Field is required",
                    (v) => !isNaN(v) || "Input must be a number",
                ],
            },
            form: {},
            uploadDialog: false,

            component_dispatches:[
		        'getEmployeeScheduleType',
                'getOvertimeRecord'

			],

            selected_id: "",
            selected_employee: {},
            batch_select: [],
            static_file_view: [],
            dropdowns: {
                employee_list: [],
            },
            included_dates: [],
            current_index: null,
            form: {
                Employee: {
                    uploaded_file: ''
                }
            },
            checked_department_ids: [],
            userAccess: [],
            loading:{
                employee_dropdown: false,
                button: false,
                row: false
            },
            statuses:[
                { text: 'all', value: 0},
                {text: 'pending', value: 1},
                {text: 'rejected', value: 2},
                {text: 'approved', value: 3},
                {text: 'confirmed', value: 4},
            ],
            selected_value: 0,
            legends: [
                { icon: "mdi-walk", text: "On Leave", color: "green" },
                { icon: "mdi-account-off", text: "Inactive", color: "red"},
            ],
            settings:{
                max_allowable_ot_hours_per_cut_off: 0,
                max_allowable_ot_hours_per_day: 0,
                ot_allowed_number_of_days: 0,
            },
            changeOTHours:{
                dialog: false,
                items: [],
            },
            employee_overtime_changes: null,
            identifier: {}
        };
    },
    computed: {
        ...mapGetters([
            "ACTION",
            "GET_HR_DIALOG_TITLE",
            // 'GET_EMPLOYEE_SCHEDULE_TYPE_SELECTION',
            "DIALOGS",
            "GET_HR_DISPATCH",
            "GET_HR_OTHER_TABS_DIALOG_VIEW",
            "GET_EMPLOYEE_DATA",
            "GET_HR_OVERTIME_VIEWFILE_ID",
            "GET_HR_OVERTIME_STATIC_VIEW_FILE",
            "USERACCOUNT_LOAD",
            "USER_ACCESS"
        ]),
        actions() {
            this.setIncludedDates();
            if (this.ACTION == "Submit") {
                this.disabled_view = false;
                this.isViewBtn = false;
                return "Submit";
            } else if (this.ACTION == "Update") {
                this.valid = true;
                this.disabled_view = false;
                this.isViewBtn = true;
                this.getOvertimeRecordData();
                return "Update";
            } else if (this.ACTION == "View") {
                this.disabled_view = true;
                this.isViewBtn = true;
                this.getOvertimeRecordData();
                return "View";
            }
        },
        dateRangeText () {
            return this.form.effectivity_date.join(' ~ ')
        },
    },
    created() {
        this.initialForm();
    },
    components:{
        FileUploadComponent,
        BaseFileViewer,
        CameraLayout
    },
    async mounted() {
        this.getAllDropdowns();
        await this.$store.dispatch("credentials");
        this.form.Employee.selected_employee = false;
        await this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id);
        this.checkDepartmentAccess();
        this.getModuleSettings();
    },
    methods: {
        getAllDropdowns(){
            this.loading.employee_dropdown = true;
            this.component_dispatches.forEach(el=>{
                this.$store.dispatch(el)
            })
        },
        initialForm(employee_only = 0) {
            if(employee_only === 0){
                this.batch_select = []
                this.form = {
                    Employee: [],
                    ot_date: null,
                    employee_trash_ids: [],
                };
                if(this.form.Employee.length == 0){
                    this.addEmployee();
                }
                this.selected_value = 0
            }else{
                this.form.Employee = [];
                if(this.form.Employee.length == 0){
                    this.addEmployee();
                }
            }
            this.getEmployeeDropdown();
        },
        handleFileUpload(data, event, index) {
            this.current_index = index
            const file = event.target.files[0];
            let that = this
            const blobFile = new Blob([file], { type: file.type });

            var reader  = new FileReader();
            reader.readAsDataURL(blobFile);
            reader.onloadend = function () {
                that.$set(data, 'uploadedFile', reader.result);
                const formattedDate = that.$dayjs(file.lastModifiedDate).format('YYYY-MM-DD');
                let join = {
                    name: file.name,
                    date: formattedDate,
                    type: file.type,
                    size: file.size,
                    uploaded_file: reader.result,
                    index: index
                };
                that.static_file_view.push(join);
                that.$store.commit('HR_OVERTIME_STATIC_VIEW_FILE', that.static_file_view)
            }
        },
        approvedEmployeeOvertime(){
            let saved = this.GET_HR_OTHER_TABS_DIALOG_VIEW.pending_count
            let reject = this.GET_HR_OTHER_TABS_DIALOG_VIEW.rejected_count
            let confirmed = this.GET_HR_OTHER_TABS_DIALOG_VIEW.confirmed_count
            let payload = this.GET_HR_OTHER_TABS_DIALOG_VIEW
            let items_count = payload.employee_overtime_record_items.length
            let param = reject + confirmed

            payload.isPartial = this.GET_HR_OTHER_TABS_DIALOG_VIEW.employee_overtime_record_items.filter((x)=> x.status != 2).every((e) => e.status === 4);
            let record_statuses = payload.employee_overtime_record_items.map((e) => e.status).filter((x) => x != 2);

            let all_statuses = [...new Set(record_statuses)];
            //  If all item status are not pending
            if([1,2].includes(payload.status) && payload.employee_overtime_record_items.every((item) => item.status !== 1) === true && all_statuses.length === 1){
                let status_to_check = all_statuses[0] + 1;
                if(all_statuses[0] != 4){
                    if(payload.employee_overtime_record_items.every((e)=> e.status === status_to_check)){
                        Swal.fire({
                            icon: 'question',
                            text: `Are you sure you want to ${payload.status == 1 ? 'approve' : 'confirm'}?`,
                            cancelButtonText: "Cancel",
                            showCancelButton: true,
                            allowOutsideClick: false,
                            reverseButtons:true,
                            confirmButtonColor: "#397373",
                            cancelButtonColor: "grey",
                        })
                        .then((result) => {
                            if (result.isConfirmed) {
                                this.$store.dispatch('approveEmployeeOvertime', payload).then((response) => {
                                    Swal.fire(`${payload.status == 1 ? 'Approval' : 'Confirmation'} Successful`, '', 'success');
                                    this.closeDialog();
                                });
                            }
                        });
                    }else{
                        Swal.fire(
                            'Cannot be confirmed!',
                            'There are still approved records!',
                            'warning'
                        );
                    }
                }else{
                    Swal.fire({
                        icon: 'question',
                        text: `Are you sure you want to ${payload.status == 1 ? 'approve' : 'confirm'}?`,
                        cancelButtonText: "Cancel",
                        showCancelButton: true,
                        allowOutsideClick: false,
                        reverseButtons:true,
                        confirmButtonColor: "#397373",
                        cancelButtonColor: "grey",
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            this.$store.dispatch('approveEmployeeOvertime', payload).then((response) => {
                                Swal.fire(`${payload.status == 1 ? 'Approval' : 'Confirmation'} Successful`, '', 'success');
                                this.closeDialog();
                            });
                        }
                    });
                }
            }
            else if([1,2].includes(payload.status) && payload.employee_overtime_record_items.every((item) => item.status !== 1)){
                Swal.fire({
                    icon: 'question',
                    text: `Are you sure you want to ${payload.status == 1 ? 'approve' : 'confirm'} partially?`,
                    cancelButtonText: "Cancel",
                    showCancelButton: true,
                    allowOutsideClick: false,
                    reverseButtons:true,
                    confirmButtonColor: "#397373",
                    cancelButtonColor: "grey",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch('approveEmployeeOvertime', payload).then((response) => {
                            Swal.fire('Approval Successful', '', 'success');
                            this.closeDialog();
                        });
                    }
                });
            } else if ([1,2].includes(payload.status)  && payload.employee_overtime_record_items.some((item) => item.status === 1)){
                Swal.fire(
                    'Cannot be confirmed!',
                    'There are still pending records!',
                    'warning'
                );
            }

            // if(payload.status == 2 && payload.employee_overtime_record_items.every((item) => item.status == 1)){
            //     Swal.fire(
            //         'Cannot be confirmed',
            //         'There are items not yet confirmed/rejected',
            //         'error'
            //     );
            // }
            // else{
            //     Swal.fire({
            //     icon: "info",
            //     text: "Are you sure you want to confirm?",
            //     cancelButtonText: "Cancel",
            //     showCancelButton: true,
            //     allowOutsideClick: false,
            //     reverseButtons:true,
            //     confirmButtonColor: "#397373",
            //     cancelButtonColor: "grey",
            //     })
            //     .then((result) => {
            //         if (result.isConfirmed) {
            //             this.$store.dispatch('approveEmployeeOvertime', payload).then((response) => {
            //                 Swal.fire('Approval Successful', '', 'success');
            //                 // this.closeDialog();
            //             });
            //         }
            //     });
            // }

            // if(payload.status == 1 && payload.employee_overtime_record_items.every((item) => item.status == 1)){
            //     Swal.fire(
            //         'Cannot be approved',
            //         'There are items not yet approved/rejected',
            //         'warning'
            //     );
            // }
            // else if(payload.status == 1 && items_count != saved && items_count != reject && items_count != confirmed){
            //     Swal.fire({
            //     icon: "info",
            //     text: "Are you sure you want to approved partially?",
            //     cancelButtonText: "Cancel",
            //     showCancelButton: true,
            //     allowOutsideClick: false,
            //     reverseButtons:true,
            //     confirmButtonColor: "#397373",
            //     cancelButtonColor: "grey",
            //     })
            //     .then((result) => {
            //         if (result.isConfirmed) {
            //             this.$store.dispatch('approveEmployeeOvertime', payload).then((response) => {
            //                 Swal.fire('Approval Successful', '', 'success');
            //                 this.closeDialog();
            //             });
            //         }
            //     });
            // }
        },
        approveOvertime(item, index, action){
            Swal.fire({
                icon: 'question',
                text: `Are sure you want to ${action} this specific OT?`,
                cancelButtonText: "Cancel",
                showCancelButton: true,
                allowOutsideClick: false,
                reverseButtons:true,
                confirmButtonColor: "#397373",
                cancelButtonColor: "grey",
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('changeOvertimeStatus', item).then((response) => {
                        if(response === 'No Time In/Out found for that day!'){
                            Swal.fire(
                                "Record Not Approved!",
                                response,
                                "warning"
                            )
                        }else{
                            Swal.fire('Approval Successful', '', 'success');
                            this.form.Employee.splice(index, 1, response)
                            // this.closeDialog();
                            this.checkStatus();
                        }
                    });
                }
            });
        },
        rejectOvertime(item, index){
            if(item.reject_reason == null || item.reject_reason == ''){
                Swal.fire('Reject reason is required', '', 'warning');
            }else{
                Swal.fire({
                    icon: 'question',
                    text: "Are sure you want to reject this specific OT?",
                    cancelButtonText: "Cancel",
                    showCancelButton: true,
                    allowOutsideClick: false,
                    reverseButtons:true,
                    confirmButtonColor: "#397373",
                    cancelButtonColor: "grey",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch('changeOvertimeStatus', item).then((response) => {
                            Swal.fire('Rejection Successful', '', 'success');
                            this.form.Employee.splice(index, 1, response)
                            // this.closeDialog();
                            this.checkStatus();
                        });
                    }
                });
            }
        },

        approveSelected(){
            if(this.batch_select.length < 2){
                Swal.fire('Must select multiple to proceed', '', 'warning');
            }else{
                Swal.fire({
                    icon: 'question',
                    text: "Are you sure you want to approve/confirm selected employees?",
                    cancelButtonText: "Cancel",
                    showCancelButton: true,
                    allowOutsideClick: false,
                    reverseButtons:true,
                    confirmButtonColor: "#397373",
                    cancelButtonColor: "grey",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        let payload = {
                            items: this.batch_select,
                            id: this.GET_HR_OTHER_TABS_DIALOG_VIEW.id
                        }
                        this.$store.dispatch('batchApproveAndReject', payload).then((response) => {
                            if(response.noTimeInData.length > 0){
                                Swal.fire(
                                    "There are records not approved!",
                                    "No timekeeping data found!",
                                    "warning"
                                )
                            }else{
                                Swal.fire('Updated Successfully', '', 'success');
                            }
                            if(response.data.length == 0){
                                this.closeDialog();
                            }
                            this.form.Employee = response.data
                        });
                    }
                });
            }
        },
        rejectSelected(){
            let selected = this.batch_select.map(e => e);
            let reject = selected.map(e => e.reject_reason == '' || e.reject_reason == null)

            if(reject.includes(true)){
                this.$refs.form.validate();
                Swal.fire('Reject reason is required on selected employees!', '', 'warning')
            return false
            }

            if(this.batch_select.length == 1 || this.batch_select.length == 0){
                Swal.fire('Must select multiple to proceed', '', 'warning');
            }else{
                Swal.fire({
                icon: 'question',
                text: "Are you sure you want to reject selected employees?",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                allowOutsideClick: false,
                reverseButtons:true,
                confirmButtonColor: "#397373",
                cancelButtonColor: "grey",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        let payload = {
                            items: this.batch_select
                        }
                        this.$store.dispatch('batchApproveAndReject', payload).then((response) => {
                            if(response.noTimeInData.length > 0){
                                Swal.fire(
                                    "There are records not approved!",
                                    "No timekeeping data found!",
                                    "warning"
                                )
                            }else{
                                Swal.fire('Updated Successfully', '', 'success');
                            }
                            if(response.data.length == 0){
                                this.closeDialog();
                            }
                            this.form.Employee = response.data
                        });
                    }
                });
            }
        },

        closeDialog() {
            this.initialForm();
            this.static_file_view = []
            this.form.Employee.selected_employee = false
            this.$store.commit("DIALOG", false);
            this.$store.commit('HR_OVERTIME_STATIC_VIEW_FILE', []);
        },
        addEmployeeOvertime() {
            this.loading.button = true
            if(this.validateFields()){
                this.loading.button = false
                return false
            }else{
                this.$store.dispatch(this.GET_HR_DISPATCH.add, this.form).then(response => {
                    Swal.fire({text: `Added Successful`, icon: 'success'});
                    this.closeDialog();
                    this.getAllDropdowns();
                    this.loading.button = false
                }).catch((err)=>{
                    Swal.fire(
                        "Network Error",
                        "",
                        "error"
                    )
                    console.log(err)
                });
            }
        },
        updateEmployeeOvertime() {
            this.loading.button = true
            if(this.validateFields()){
                this.loading.button = false
                return false
            }
            if(this.form.employee_trash_ids.length > 0){
                Swal.fire({
                icon: "info",
                text: "Removed records will be deleted!",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                allowOutsideClick: false,
                reverseButtons:true,
                confirmButtonColor: "#397373",
                cancelButtonColor: "#397373",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch(this.GET_HR_DISPATCH.update, this.form).then((response) => {
                            Swal.fire('Updated Successfully', '', 'success');
                            this.closeDialog();
                            this.loading.button = false
                        });
                    }
                });
            }else{
                this.$store.dispatch(this.GET_HR_DISPATCH.update, this.form).then((response) => {
                    Swal.fire('Updated Successfully', '', 'success');
                    this.closeDialog();
                    this.loading.button = false
			    });
            }
        },
        addEmployee(){
            this.form.Employee.push({
                employee_id: '',
                ot_time_in: '',
                ot_time_out: '',
                ot_reason: '',
                uploadedFile: '',
            })
        },
        deleteEmployee(index,item_id = -1){
            Swal.fire({
                text: 'Are you sure you want to delete ?',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then((result) => {
                if(result.isConfirmed) {
                    this.form.Employee.splice(index,1);
                    this.form.employee_trash_ids.push(item_id)
                }
            });

        },

        getOvertimeRecordData(){
            this.form.id                    = this.GET_HR_OTHER_TABS_DIALOG_VIEW.id;
            this.form.ot_date               = this.GET_HR_OTHER_TABS_DIALOG_VIEW.ot_date;
            this.form.remarks               = this.GET_HR_OTHER_TABS_DIALOG_VIEW.remarks;
            this.form.Employee              = this.GET_HR_OTHER_TABS_DIALOG_VIEW.employee_overtime_record_items;
            this.selected_id                = this.GET_HR_OTHER_TABS_DIALOG_VIEW.employee_overtime_record_items.id;
            this.loading.employee_dropdown = false;
            this.getEmployeeDropdown();
        },
        calculateOtHours(data, index){
            const ot_In = data.ot_time_in;
            const ot_Out = data.ot_time_out;
            let net_hours = 0;
            let gross_hours = 0;
            let late_in_minutes = 0;
            let regular_rate_hours = 0;
            let ot_rate_hours = 0;
            let gross_minutes = 0;


            if(ot_In && ot_Out){

                let ot_rate_minutes = 0;
                let regular_rate_minutes = 0;

                late_in_minutes = data.late_in_hours == 0 ? 0 :  (data.late_in_hours * 60).toFixed(0)
                    if(this.checkEmployeeOtTime(data, index)){
                        Swal.fire({
                            icon: "warning",
                            title: "OT Time must not overlap!",
                            allowOutsideClick: false,
                            confirmButtonColor: "#397373",
                            cancelButtonColor: "#397373",
                        })
                        .then((result) => {
                            if (result.isConfirmed) {}
                                this.$set(data, 'ot_time_in', '')
                                this.$set(data, 'ot_time_out', '')
                            })
                        return false
                    }
                const diffInMin = this.caculateOtInMinutes(ot_In, ot_Out) - late_in_minutes;
                const roundedDiffInMin = Math.floor(diffInMin / 30) * 30;
                if(diffInMin < 30){
                   Swal.fire({
                       icon: 'warning',
                       title: 'OT Hours should be greater than 30mins',
                       text: '',
                   });
                }else if(diffInMin % 30 !== 0){
                     Swal.fire({
                       icon: 'info',
                       title: 'OT Hours should be divisible by 30 mins',
                       text: 'The excess minutes will not be counted.',
                   });
                }
                const diffInHrs = roundedDiffInMin / 60;
                gross_minutes = roundedDiffInMin;
                net_hours = diffInHrs;
                gross_hours = diffInHrs;
                ot_rate_hours = (gross_hours - data.late_in_hours) < 0 ? 0 : parseFloat((gross_hours - data.late_in_hours)).toFixed(2);
                // ot_rate_minutes = parseFloat(ot_rate_hours * 60).toFixed(2);
                ot_rate_minutes = gross_minutes;

                regular_rate_hours      = (gross_hours - ot_rate_hours) < 0 ? 0 : parseFloat((gross_hours - ot_rate_hours)).toFixed(2);
                // regular_rate_minutes    = parseFloat(regular_rate_hours * 60).toFixed(2);
                regular_rate_minutes    = late_in_minutes;
                if(net_hours <= 0){
                     Swal.fire({
                        title: "Overtime Hours is 0",
                        icon: "warning",
                        text: `Overtime Record will be disregarded`,
                        allowOutsideClick: false,
                        confirmButtonColor: "#397373",
                        cancelButtonColor: "grey",
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            this.form.Employee.splice(index, 1);
                            this.addEmployee();
                        }
                    });
                }else{
                    this.$set(data, 'gross_ot_hours',  gross_hours )
                    this.$set(data, 'ot_minutes',  gross_hours * 60)
                    this.$set(data, 'late_in_minutes',  late_in_minutes)
                    this.$set(data, 'net_ot_hours',  net_hours)
                    this.$set(data, 'regular_rate_hours',  regular_rate_hours)
                    this.$set(data, 'ot_rate_hours',  ot_rate_hours)
                    this.$set(data, 'regular_rate_minutes',  regular_rate_minutes)
                    this.$set(data, 'ot_rate_minutes',  ot_rate_minutes)
                }
            }else{
                this.$set(data, 'gross_ot_hours',  0)
                this.$set(data, 'ot_minutes',  0)
                this.$set(data, 'late_in_minutes',  0)
                this.$set(data, 'net_ot_hours',  0)
                this.$set(data, 'regular_rate_minutes',  0)
                this.$set(data, 'ot_rate_minutes',  0)
            }
        },
        caculateOtInMinutes(ot_In, ot_Out){
            if(ot_In && ot_Out){
                let OtInMins = this.computeHours(ot_In, ot_Out)

                return OtInMins

                // const [hours1, minutes1] = ot_In.split(':').map(Number);
                // const [hours2, minutes2] = ot_Out.split(':').map(Number);

                // const totalMinutes1 = hours1 * 60 + minutes1;
                // const totalMinutes2 = hours2 * 60 + minutes2;

                // return totalMinutes2 - totalMinutes1;
            }else{
                return 0;
            }
        },
        computeHours(time_from, time_to) {
            let currentDate = this.$dayjs(this.form.ot_date).format('YYYY-MM-DD');
            let timeFormat = 'YYYY-MM-DD, hh:mm:ss';

            let combinedTimeFrom = this.$dayjs(`${currentDate}, ${time_from}`, timeFormat);
            let combinedTimeTo = this.$dayjs(`${currentDate}, ${time_to}`, timeFormat);

            if (combinedTimeTo.isBefore(combinedTimeFrom)) {
                combinedTimeTo.add(1, 'day');
            }

            let duration = this.$dayjs.duration(combinedTimeTo.diff(combinedTimeFrom));

            let hoursWorked = duration.asMinutes();

            return hoursWorked;
        },
        viewFiles(id, index){
            this.$store.commit('HR_OVERTIME_VIEWFILE_ID', id);
            this.$store.commit('CURRENT_INDEX', index);
            this.$store.commit('filesShow');

        },
        select_id(id){
            const exist = this.form.Employee.find(e => e.id == id);
            const index = this.batch_select.indexOf(exist);
                if (index === -1) {
                    this.batch_select.push(exist);
                } else {
                    this.batch_select.splice(index, 1);
                }
        },
        rejectReasonToAll(data){
            if(this.batch_select.length >= 1){
                if(data.reject_reason !== null && data.reject_reason !== ''){
                    Swal.fire({
                        icon: "question",
                        text: "Do you want to apply this reject reason on selected items?",
                        cancelButtonText: "Cancel",
                        showCancelButton: true,
                        allowOutsideClick: false,
                        reverseButtons:true,
                        confirmButtonColor: "#397373",
                        cancelButtonColor: "grey",
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            let selected = this.batch_select.map(e => e);
                            selected.forEach((e) => {
                                if(e.reject_reason === null || e.reject_reason === '') {
                                    e.reject_reason = data.reject_reason;
                                }
                            });
                        }
                    });
                }
            }
        },
        allowedDates(val){
            return this.USERACCOUNT_LOAD.manager == 1 ? val : this.included_dates.includes(val);
        },
        setIncludedDates(){
            this.included_dates.length = 0
            let currentDate = this.$dayjs().subtract(this.settings.ot_allowed_number_of_days, 'day').format('YYYY-MM-DD');
            let endDate = this.$dayjs().add(this.settings.ot_allowed_number_of_days, 'day').format('YYYY-MM-DD');
            this.included_dates = this.getDatesFromRange(currentDate, endDate, 'array')
        },
        async checkDepartmentAccess(){
            let payload ={
                access: this.USER_ACCESS.map(e => e.actions_code)
            }
            await this.$store.dispatch('checkDepartmentAccess', payload).then(async response=>{
                this.checked_department_ids = response;
                await this.getEmployeeDropdown();
            })

        },
        validateFields(){
            this.$refs.form.validate();
            let validations = [
                { validate:this.form.ot_date == null , message: 'OT Date' },
                // { validate:this.form.Employee.filter(e => e.uploadedFile === '').length > 0,message: 'Upload File'},
                { validate:this.form.Employee.filter(e => e.employee_id === '').length > 0 ,message: 'Employee'},
                { validate:this.form.Employee.filter(e => e.ot_time_in === '').length > 0 ,message: 'OT Time In'},
                { validate:this.form.Employee.filter(e => e.ot_time_out === '').length > 0 ,message: 'OT Time Out'},
                { validate:this.form.Employee.filter(e => e.ot_reason === '').length > 0 ,message: 'OT Reason'}
            ]
            if(validations.map(e=>e.validate).includes(true)){
                Swal.fire('Warning!',validations[validations.map(e=>e.validate).indexOf(true)].message+' is required','warning')
            }

            return validations.map(e=>e.validate).includes(true)
        },
        checkStatus(){
            this.$store.dispatch('showOvertimeRecord', this.GET_HR_OTHER_TABS_DIALOG_VIEW).then((res) => {
                this.$store.commit("HR_OTHER_TABS_DIALOG_VIEW", res);
            })
        },
        badgeStatus(status){
            switch(status){
                case 1:
                    return 'pending'
                case 2:
                    return 'rejected'
                case 3:
                    return 'approved'
                case 4:
                    return 'confirmed'
            }
        },
        filterItems(status){
            this.selected_value = status.value;
            if(this.selected_value == 0){
                this.form.Employee              = this.GET_HR_OTHER_TABS_DIALOG_VIEW.employee_overtime_record_items;
            }else{
                let filtered                    = this.GET_HR_OTHER_TABS_DIALOG_VIEW.employee_overtime_record_items.filter((x)=> x.status == this.selected_value);
                this.form.Employee              = filtered;
            }
        },
        checkEmployeeOtTime(item, index) {
            const employeeId = item.employee_id;
            const OTList = this.form.Employee.filter(e => e.employee_id === employeeId);

            if (OTList.length === 2) {
                const [time1In, time1Out] = [OTList[0].ot_time_in, OTList[0].ot_time_out];
                const [time2In, time2Out] = [OTList[1].ot_time_in, OTList[1].ot_time_out];

                // Check for overlap
                if (!(time1Out < time2In || time2Out < time1In)) {
                    // Overlapping time ranges
                    return true;
                } else {
                    return false;
                }
            }
        },
        resetEmployeeDropdown(){
            this.loading.employee_dropdown = false;
            this.dropdowns.employee_list = this.GET_EMPLOYEE_DATA;
        },
        validateOTTimeIn(ot_time_in, ot_time_out){
            let payload = {
                ot_range: [ot_time_in, ot_time_out],
                ot_date: this.form.ot_date
            }
        },
        checkEmployeeBioDate(item, employee_id){
            if(employee_id){
                this.loading.row = true;
                let payload = {
                    employee_id: employee_id,
                    ot_date: this.form.ot_date
                }
                this.$store.dispatch('checkEmployeeBioDate', payload).then((res)=>{
                    if(res.icon){
                        Swal.fire(
                            res.message,
                            `Excess Hours to file as OT: ${res.count} hours/s`,
                            res.icon
                        )
                        this.$set(item, 'employee_id', '');
                        this.resetItem(item);
                    }
                    else if (res == 'No Time In/Out found!'){
                        Swal.fire(
                            res,
                            `Record's Time In/Out will be validated upon approval.`,
                            "info"
                        )
                        // this.$set(item, 'employee_id', '');
                        this.$set(item, 'late_in_hours', 0);
                        this.resetItem(item);
                        // this.resetItem(item);
                    }
                    else{
                        let employeeBioTime = res;
                        this.$set(item, 'ot_min_time_in', employeeBioTime.schedule_time_out);
                        this.$set(item, 'ot_max_time_out', employeeBioTime.time_out);
                        this.$set(item, 'late_in_hours', employeeBioTime.late_in_hours);
                    }
                    this.loading.row = false;
                }).catch((err)=>{
                    Swal.fire(
                        "Network Error",
                        "",
                        "error"
                    )
                    console.log(err)
                    this.loading.row = false;
                })
            }else{
                this.resetItem(item);
            }
        },
        resetItem(item){
            this.$set(item, 'ot_min_time_in', 0);
            this.$set(item, 'ot_max_time_out', 0);
            this.$set(item, 'late_in_hours', 0);
            // this.$set(item, 'employee_id', 0);
            this.$set(item, 'ot_time_in', 0);
            this.$set(item, 'ot_time_out', 0);
            this.$set(item, 'ot_minutes', 0);
            this.$set(item, 'ot_rate_minutes', 0);
            this.$set(item, 'late_in_minutes', 0);
            this.$set(item, 'regular_rate_hours', 0);
            this.$set(item, 'ot_rate_hours', 0);
        },
        async getEmployeeDropdown(){
            this.loading.employee_dropdown = true;
            let payload = {
                access_department_ids: this.checked_department_ids,
                ot_date: this.form.ot_date
            }
            await this.$store.dispatch('getEmployeeDataDropdown', payload).then((res)=>{
                this.loading.employee_dropdown = false;
            })
        },
        async getModuleSettings(){
            let payload = {
                settings_type: 'timekeeping'
            }
            await this.$store.dispatch('getSettingsByModule', payload).then((res)=>{
                Object.keys(this.settings).forEach((key)=>{
                    this.settings[key] = parseInt(res.find((x)=> x.parameter == key).value)
                })
                this.setIncludedDates();
            })
        },
        async updateEmployeeOvertimeNow(from = 0){
            if(this.checkIfHasChanges() && this.changeOTHours.items.some((x) => x.reason === null) && this.GET_HR_OTHER_TABS_DIALOG_VIEW.created_by !== this.USERACCOUNT_LOAD.id){
                this.$store.dispatch('SwalAlertWarning', {
                    title: "Please input reason!",
                    text: ""
                })
                return false;
            }
            if(from == 1){
                this.form.Employee.forEach((el)=>{
                    if(el.hasChange == 1){
                        el.reason = this.changeOTHours.items.find((x)=> x.employee_overtime_record_item_id == el.id).reason
                    }
                })
            }
            await this.$store.dispatch(this.GET_HR_DISPATCH.update, this.form).then((response) => {
                Swal.fire('Updated Successfully', '', 'success');
                this.closeDialog();
                this.loading.button = false
            });
        },
        closeChangeOTHoursDialog(){
            this.changeOTHours.dialog = false;
        },
        checkIfHasChanges(){
            let hasChanges = this.form.Employee.filter((x)=> x.hasChange == 1);
            return hasChanges.length > 0;
        },
        viewOvertimeItemChanges(overtimeChanges, employee_id){
            this.$store.commit('HR_CHANGES_DIALOG', true);
            this.employee_overtime_changes = overtimeChanges;
            let employee_name = this.GET_EMPLOYEE_DATA.find(x=> x.id == employee_id);
            this.identifier = {
                key: 'Employee Name',
                value: employee_name.full_name,
            }
        }
    },
    watch: {
        USER_ACCESS:{
            handler(val){
                this.userAccess = val.map(e=>e.actions_code);
            }
        },
        DIALOGS: {
            handler(val) {
                if (val && this.ACTION == 'Submit') {
                    this.initialForm();
                }
                else if (val && this.ACTION == 'Update' || this.ACTION == 'View'){
                    this.getOvertimeRecordData();
                }
                this.resetEmployeeDropdown();
            },
        },
        GET_HR_OVERTIME_STATIC_VIEW_FILE: {
            handler(val){
                 val = this.static_file_view;
            }
        },
        GET_EMPLOYEE_DATA:{
            handler(val){
                // let filtered_employee = val.filter(e => e.department_id == this.USERACCOUNT_LOAD.department_id);
                this.loading.employee_dropdown = false;
                this.dropdowns.employee_list = val;
            }
        },
        // 'data.ot_time_in': 'calculateOtHours',
        'data.ot_time_out': 'calculateOtHours',
        "form.Employee":{
            handler(val){
                let employee_ids = []
                employee_ids = val.filter((x)=> ![2, 4].includes(x.status)).map((e)=> e.employee_id);

                this.dropdowns.employee_list.forEach((x)=>{
                    let emp_count = employee_ids.filter((e)=> e == x.value);

                    if(emp_count.length){
                        x.isDisabled = emp_count.length == 2 ? true : false;
                    }
                    // if(employee_ids.includes(x.value)){
                    //     x.isDisabled = true;
                    // }else{
                    //     x.isDisabled = false;
                    // }
                })
            },
            deep: true
        }
    },
};
</script>
<style scoped>
.modal_title {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: white;
}
</style>
