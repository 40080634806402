<template>
    <div>
        <v-card>
            <v-card-title>
                <v-row class="m-0">
                    <v-col lg="8">
                        <span>{{show.form?.name }}</span>
                    </v-col>
                    <v-col cols="pull-right-3 p-2">
                        <v-btn
                        text
                        icon
                        small
                        color="gray"
                        class="float-right"
                        @click="closeDialog()"
                        >
                        <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                    </v-row>
            </v-card-title>
            <v-card-text class="mx-5">
                <v-form ref="firstForm" v-model="firstForm" v-if="GET_ADMIN_TAB_IS_ACTIVE == 'assetslist-basket'">
                    <v-row >
                        <v-col cols="12" class="d-flex justify-end align-end">
                            <v-spacer></v-spacer>
                            <v-col cols="3">
                                <v-autocomplete
                                    v-model="status"
                                    :items="show.selections"
                                    label="Status"
                                    hide-details
                                    outlined
                                    dense
                                ></v-autocomplete>
                            </v-col>
                            <v-btn class="mr-8 mb-1" @click="massUpdate()" :disabled="!status">
                                Mass Update
                            </v-btn>
                        </v-col>
                        <v-col cols="4">
                            <v-textarea
                                v-model="show.form.description"
                                height="140"
                                :rules="[rules.required]"
                                outlined
                                label="Description"
                            ></v-textarea>
                            <v-row class="px-3 pt-2">
                                <v-col lg="12">
                                <b>Classification: </b>{{show.form.classification}}
                                </v-col>
                                <v-col lg="12">
                                    <b>Sub Class: </b>{{show.form.classification_sub}}
                                </v-col>
                                <v-col lg="12">
                                <b>Brand: </b>{{show.form.brand}}
                                </v-col>
                                <v-col lg="12">
                                <b>Location: </b>{{show.form.location}}
                                </v-col>
                                <v-col lg="12">
                                <b>Serial: </b>{{show.form.serial}}
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="8">
                            <v-simple-table fixed-header dense class="overflow-y-auto" style="border: 1px solid #e0e0e0; max-height: 600px; max-width: 94%;">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-center" width="50%">ASSET TAG</th>
                                                <th class="text-center" width="40%">STATUS</th>
                                                <th class="text-center" width="10%">ACTION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="font-weight-medium text-gray particulars-container-row" v-for="(item, index) in show.items" :key="index">
                                                <td  style="height: 50px;">
                                                    <v-row class="d-flex justify-center">
                                                        <v-col cols="10">
                                                            <v-text-field v-model="item.asset_code" hide-details label="Code"></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    
                                                </td>
                                                <td>
                                                    <v-row class="d-flex justify-center">
                                                        <v-col cols="10">
                                                            <v-autocomplete
                                                                v-model="item.status"
                                                                :items="show.selections"
                                                                label="Status"
                                                                :rules="[rules.required]"
                                                                hide-details
                                                            >
                                                            </v-autocomplete>
                                                        </v-col>
                                                    </v-row>
                                                </td>
                                                <td class="text-center">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn v-on="on" fab dark x-small color="error" style="height: 20px; width: 20px;" @click="removeItem(index)" >
                                                                <v-icon small>
                                                                    mdi-minus
                                                                </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Remove</span>
                                                    </v-tooltip>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                            </v-simple-table>
                            <v-row class="ma-4">
                                <v-col cols="11" class="d-flex justify-end align-end">
                                    <span class="text-subtitle-1"><b>Total Quantity: {{ totalQuantity }}</b></span>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
                <v-form ref="secondForm" v-model="secondForm" v-else>
                    <v-container >
                        <v-row>
                            <v-col lg="3">
                                <v-text-field v-model="show.form.asset_code" hide-details label="Code" :disabled="show.action == 'View'"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="pt-2">
                            <v-col lg="11">
                                <v-textarea
                                    v-model="show.form.description"
                                    height="162"
                                    outlined
                                    :rules="[rules.required]"
                                    label="Description"
                                    :disabled="show.action == 'View'"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row>
                            <v-col lg="12">
                                <b>Classification: </b>{{show.form.classification}}
                            </v-col>
                            <v-col lg="12">
                                <b>Sub Class: </b>{{show.form.classification_sub}}
                            </v-col>
                            <v-col lg="12">
                                <b>Brand: </b>{{show.form.brand}}
                            </v-col>
                            <v-col lg="12">
                                <b>Location: </b>{{show.form.location}}
                            </v-col>
                            <v-col lg="12">
                                <v-col lg="3">
                                    <v-text-field v-model="show.form.item_serial_number" hide-details label="Serial" :disabled="show.action == 'View'"></v-text-field>
                                </v-col>
                                <!-- <b>Serial: </b>{{show.form.item_serial_number}} -->
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-content-center align-items-center">
                <v-btn @click="update()" small color="orange" v-if="this.show.action == 'Update'">
                    Update
                </v-btn>
            </v-card-actions>
            <v-card-actions class="d-flex justify-content-center align-items-center">
                <v-btn @click="submit()" small color="orange" v-if="GET_ADMIN_TAB_IS_ACTIVE == 'assetslist-basket'">
                    Submit
                </v-btn>
            </v-card-actions>
        </v-card>
        <ConfirmOverrideComponentVue
        :approve_override_dialog="override.override_dialog"
        :departments_allowed="override.override_departments_allowed"
        :heading="''"
        @closeConfirmOverride="closeConfirmOverride"
        >

        </ConfirmOverrideComponentVue>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import swal from "sweetalert2";
const ExcelJS = require('exceljs')
import ConfirmOverrideComponentVue from '@/views/main/Utils/ConfirmOverrideComponent.vue';
export default {
    props:{
        showValue:{},
        default:()=>{},
        type:Object
    },
    components:{
        ConfirmOverrideComponentVue
    },
    data(){
        return{
            override: {
                override_dialog: false,
                errors: [],
                override_departments_allowed: ['admin'],
                override_authenticated: false,
            },
            show:{
                form:{
                    item:'',
                    classification:'',
                    classification_sub:'',
                    brand:'',
                    location:'',
                    company:'',
                    serial:'',
                    asset_code:'',
                    description: '',
                    item_serial_number: '',
                },
                dialog:false,
                action:'',
                items: [
                    {
                        id: '',
                        admin_po_item_id:'',
                        asset_code: '',
                        status: '',
                    }
                ],
                selections:[],
            },
            tmp: false,
            status: '',
            rules: {
                required: value => !!value || 'Required.',
                positiveNumber: value => (value > 0) || 'Must be a positive number.',
            },
            firstForm: false,
            secondForm: false,
        }
    },
    computed:{
        ...mapGetters([
            'GET_STATUS_SELECTION',
            'SELECTED',
            'ACTION',
            'SUB_DIALOG',
            'GET_ADMIN_TAB_IS_ACTIVE'
        ]),
        totalQuantity(){
            let totalItemAmount = this.show.items.length;
            return totalItemAmount;
        },
    },
    watch:{
        GET_STATUS_SELECTION:{
            async handler(val){
                this.show.selections = await val.filter(e=>e.department_id == 1)
            },
            immediate: true
        },
        GET_ADMIN_TAB_IS_ACTIVE:{
            handler(val){
            },
            immediate: true
        },
        SELECTED:{
            handler(val){
                if(val){
                    Object.assign(this.show.form,val[0])
                    // this.show.form = val[0]
                    this.show.items = val
                    this.show.dialog = true
                    this.show.action = this.ACTION
                }
                // await this.$store.dispatch('adminAssetManagementGet',{
                //     url:'admin-asset-management-details/' + val.form.id
                // }).then(response=>{
                //     this.show.form = response[0]
                // })
            },
            immediate: true
        }
    },
    methods:{
        massUpdate(){
            this.show.items = this.show.items.map((e) => {
                e.status = this.status;
                return e;
            })
        },  
        removeItem(i){
            if(this.show.items.length > 1){
                this.show.items = this.show.items.filter((e, index) => {
                if (i != index) {
                    return e
                }
            });
            }
        },
        print(){
            const workbook = new ExcelJS.Workbook();
            let worksheet = workbook.addWorksheet('worksheet');
            worksheet.columns = [
                { header:'Asset Codes',key:'asset_code' },
            ]

            this.show.items.forEach(e=>{
                worksheet.addRow(e)
            })
            let exportName = `Asset Tages`
            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                saveAs(blob, `${exportName}.xlsx`);
            });
        },
        submit(){
            if(this.GET_ADMIN_TAB_IS_ACTIVE == 'assetslist-basket'){
                if (this.$refs.firstForm.validate()) {
                    swal.fire({
                        title:'Print Assets',
                        text:'- Are you sure you want to print assets ?',
                        icon:'warning',
                        showCancelButton:true,
                        reverseButtons:true,
                    }).then(action=>{
                        if(action.isConfirmed){
                            this.$store.dispatch('adminAssetManagementPost',{
                                url:'create-admin-asset-tags',
                                forms: this.GET_ADMIN_TAB_IS_ACTIVE == 'assetslist-basket' ? this.show.items : this.show.form,
                                description: this.show.form.description
                            }).then(response=>{
                                this.print();
                                this.$store.commit('SELECTED',{});
                                this.$store.commit('ACTION','');
                                this.$store.commit('SUB_DIALOG',false);
                                this.show.dialog = false;
                                this.closeDialog();
                                swal.fire('Success!','','success')
                            }).catch(e=>{
                                swal.fire('Error!','- an error occured','error')
                            })
                        }
                    })
                }
                 else {
                    swal.fire('Missing Field!','','warning')
                }
            } else {
                if (this.$refs.secondForm.validate()) {
                    swal.fire({
                        title:'Print Assets',
                        text:'- Are you sure you want to print assets ?',
                        icon:'warning',
                        showCancelButton:true,
                        reverseButtons:true,
                    }).then(action=>{
                        if(action.isConfirmed){
                            this.$store.dispatch('adminAssetManagementPost',{
                                url:'create-admin-asset-tags',
                                forms: this.GET_ADMIN_TAB_IS_ACTIVE == 'assetslist-basket' ? this.show.items : this.show.form,
                                description: this.show.form.description
                            }).then(response=>{
                                this.print();
                                this.$store.commit('SELECTED',{});
                                this.$store.commit('ACTION','');
                                this.$store.commit('SUB_DIALOG',false);
                                this.show.dialog = false;
                                this.closeDialog();
                                swal.fire('Success!','','success')
                            }).catch(e=>{
                                swal.fire('Error!','- an error occured','error')
                            })
                        }
                    })
                }
                 else {
                    swal.fire('Missing Field!','','warning')
                }
            }
        },
        async closeConfirmOverride(user_id, authenticated = false) {
                if (authenticated) {
                    this.$store.dispatch('adminAssetManagementPost',{
                        url:'update-admin-asset-management',
                        item_id:this.show.form.item_id,
                        asset_code:this.show.form.asset_code,
                        description:this.show.form.description,
                        id:this.show.form.id
                    }).then(response=>{
                        swal.fire('Success!','','success')
                        this.override.override_dialog = false
                        this.closeDialog()
                    })
                } else {
                    this.override.override_dialog = false;
                }
            },
        async update(){
          swal.fire({
            title:'Are you sure you want to Update?',
            text:'-',
            icon:'warning',
            showCancelButton:true,
            reverseButtons:true
          }).then(action=>{
            if(action.isConfirmed){

                if(this.show.form.print == 1){
                    this.override.override_dialog = true
                }

                this.$store.dispatch('adminAssetManagementPost',{
                    url:'update-admin-asset-management',
                    item_id:this.show.form.item_id,
                    asset_code:this.show.form.asset_code,
                    description:this.show.form.description,
                    item_serial_number:this.show.form.item_serial_number,
                    id:this.show.form.id
                }).then(response=>{
                    swal.fire('Success!','','success')
                    this.closeDialog()
                })
            }
          })
        },
        closeDialog(){
            this.status = '';
            this.$store.commit('SELECTED',{});
            this.$store.commit('ACTION','');
            this.$store.commit('SUB_DIALOG',false);
            this.$emit('closeDialog',{
                form:{},
                dialog:false,
                action:false,
            });
            this.show.dialog = false;
        }
    }
}
</script>

<style>

</style>
