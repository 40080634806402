<template>
    <v-form ref="form" v-model="valid" lazy-validation>
        <v-card elevation="0" class="pa-0 m-0 overflow-hidden">
            <v-card-title v-if="!dialogLoader">
                <v-container class="pa-0">
                    <v-row class="m-0">
                        <v-col>
                            <span class="headline">{{ STORE_CASH_IN.ACTION.charAt(0).toUpperCase() + STORE_CASH_IN.ACTION.slice(1) }} Store Cash In</span>
                        </v-col>
                        <v-col cols="pull-right-1">
                            <v-btn text icon color="gray" class="float-right" @click="toggleDialog('close')">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card elevation="0" dense>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12">
                                    <v-card elevation="0" dense>
                                        <v-card-text>
                                            <v-row>
                                                <v-col cols="3">
                                                    <v-autocomplete
                                                        v-model="form.company_id"
                                                        :readonly="isDisabled()"
                                                        :items="GET_PV_COMPANIES_SELECTION"
                                                        :loading="GET_PV_COMPANIES_SELECTION.length == 0"
                                                        :rules="required"
                                                        :clearable="STORE_CASH_IN.ACTION != 'view'"
                                                        label="Select Company"
                                                        auto-select-first
                                                        outlined
                                                        dense
                                                        @change="insertStore()"
                                                    />
                                                </v-col>
                                                <v-col cols="3">
                                                    <v-autocomplete
                                                        v-model="form.store_branch_id"
                                                        :readonly="isDisabled()"
                                                        :items="branchItems"
                                                        :rules="required"
                                                        :clearable="STORE_CASH_IN.ACTION != 'view'"
                                                        label="Select Branch"
                                                        no-data-text="No Stores Found!"
                                                        auto-select-first
                                                        outlined
                                                        dense
                                                        @change="insertCompany(form.store_branch_id)"
                                                    />
                                                </v-col>
                                                <v-col cols="2">
                                                    <v-menu
                                                        :close-on-content-click="true"
                                                        transition="scale-transition"
                                                        max-width="290"
                                                        disabled
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <v-text-field
                                                                v-model="form.created_at"
                                                                v-on="on"
                                                                append-icon="mdi-calendar"
                                                                label="Created At"
                                                                readonly
                                                                outlined
                                                                dense
                                                                />
                                                            </template>
                                                            <v-date-picker v-model="form.created_at" />
                                                    </v-menu>
                                                </v-col>
                                                <v-col cols="2">
                                                    <v-menu
                                                        :close-on-content-click="true"
                                                        transition="scale-transition"
                                                        max-width="290"
                                                        :disabled="isDisabled()"
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                                <v-text-field
                                                                    v-model="form.sales_date"
                                                                    v-on="on"
                                                                    :rules="required"
                                                                    :clearable="STORE_CASH_IN.ACTION != 'view'"
                                                                    append-icon="mdi-calendar"
                                                                    label="Sales Date"
                                                                    readonly
                                                                    outlined
                                                                    dense
                                                                />
                                                        </template>
                                                            <v-date-picker v-model="form.sales_date" />
                                                    </v-menu>
                                                </v-col>
                                                <v-col cols="2">
                                                    <v-menu
                                                        :close-on-content-click="true"
                                                        transition="scale-transition"
                                                        max-width="290"
                                                        :disabled="isDisabled()"
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                                <v-text-field
                                                                    v-model="form.deposit_date"
                                                                    v-on="on"
                                                                    :rules="required"
                                                                    :clearable="STORE_CASH_IN.ACTION != 'view'"
                                                                    append-icon="mdi-calendar"
                                                                    label="Deposit Date"
                                                                    readonly
                                                                    outlined
                                                                    dense
                                                                />
                                                        </template>
                                                            <v-date-picker v-model="form.deposit_date" />
                                                    </v-menu>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-textarea
                                                        v-model="form.remarks"
                                                        :readonly="isDisabled()"
                                                        :rules="required"
                                                        label="Remarks"
                                                        rows="2"
                                                        auto-grow
                                                        outlined
                                                        dense
                                                    />
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" class="mt-2">
                                                    <v-card>
                                                        <v-data-table
                                                            :headers="store_cash_in_detail_headers"
                                                            :items="form.store_cash_in_details"
                                                            :items-per-page="10"
                                                            :loading="store_cash_in_details_load"
                                                            disable-sort
                                                            hide-default-footer
                                                        >
                                                            <template v-slot:[`item.total_amount`]="{item}">
                                                                <span v-if="STORE_CASH_IN.ACTION == 'view'">{{ item.total_amount }}</span>
                                                                <v-text-field
                                                                    v-else
                                                                    v-model="item.total_amount"
                                                                    :rules="required"
                                                                    class="right-align-text"
                                                                    dense
                                                                    @keypress="numberAndDotOnly($event, item)"
                                                                    @input="sumAmount()"
                                                                />
                                                            </template>
                                                            <template v-slot:body.append="{ headers, items }">
                                                                <tr v-if="items.length">
                                                                    <td :colspan="headers.length - 1" class="text-right">
                                                                        <strong>{{ `Total Amount: ` }}</strong>
                                                                    </td>
                                                                    <td class="text-right"><strong>{{!isNaN(form.total_amount) ? form.total_amount : ''}}</strong></td>
                                                                </tr>
                                                            </template>
                                                        </v-data-table>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                    <v-container>
                                        <v-row class="py-2">
                                            <v-col class="text-center">
                                                <v-btn :loading="btnLoad"
                                                    v-if="STORE_CASH_IN.ACTION != 'view'"
                                                    @click="commitStoreCashIn(STORE_CASH_IN.ACTION, STORE_CASH_IN.ACTION == 'create' ? 'addStoreCashIn' : 'updateStoreCashIn')"
                                                >
                                                    {{ STORE_CASH_IN.ACTION == 'create' ? 'Create' : 'Update' }}
                                                </v-btn>
                                                <v-btn v-else-if="form.status === 1 && userAccess.includes('confirm')" @click="commitStoreCashIn('approve','updateStoreCashIn')">
                                                    CONFIRM
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-container>
            </v-card-title>
            <div v-else class="text-center pa-2">
                <v-progress-circular
                    :size="80"
                    color="primary"
                    indeterminate
                />
            </div>
        </v-card>
    </v-form>
</template>
<script>
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue'
export default {
    mixins: [SharedFunctionsComponentVue],
    props: ['userAccess','viewStoreCashIn'],
    data(){
        return{
            valid: true,
            // userAccess :[],
            dialogLoader: false,
            form:{
                id: null,
                company_id: null,
                store_branch_id: null,
                created_at: this.$dayjs().format('YYYY-MM-DD'),
                deposit_date: this.$dayjs().format('YYYY-MM-DD'),
                sales_date: this.$dayjs().format('YYYY-MM-DD'),
                remarks: null,
                status: 1,
                total_amount: 0.00,
                store_cash_in_details: [],
                store_cash_in_template: {}
            },
            btnLoad: false,
            clonedStores: [],
            required: [
                v => !!v || 'This field is required'
            ],
            store_cash_in_detail_headers:[
                { text: 'Criteria', value: 'name', align: 'left' },
                { text: 'CoA', value: 'coa_name', align: 'center' },
                { text: 'Plus Minus', value: 'plus_minus', align: 'center' },
                { text: 'Amount', value: 'total_amount', align: 'right', width: 250 }
            ],
            store_cash_in_details_load: false,
            dispatchDetails: false,
            allFields: false,
            menu: {
                date_from: false,
                date_to: false,
            },
            minDate: this.$dayjs().format('YYYY-MM-DD'),
        }
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS',
            'USERACCOUNT_LOAD',
            'GET_CHART_OF_ACCOUNTS_SELECTION',
            'STORE_CASH_IN',
            'GET_PV_COMPANIES_SELECTION',
            'GET_PV_STORE_BRANCHES_SELECTION',
            'STATUS',
            'SELECTED_TAB',
            'GET_STORE_CASH_IN_SELECTION',
            'GET_PREPARED_BY'
        ]),
        branchItems(){
            let items = this.clonedStores.filter(e=>e.company_id == this.form.company_id)
            if (!this.form.company_id) return this.clonedStores
            if (!this.form.store_branch_id) this.form.store_branch_id = items[0].value
            return items
        },
    },
    mounted(){
        // this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        // this.getAllStoreCashIns()
        this.getBranches()
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.handleKeydown);
    },
    methods:{
        toggleDialog(action = 'close'){
            this.clearFields()
            this.$emit('closeView');
            // switch(action){
            //     case 'open':
            //         this.mainDialog = true
            //         document.addEventListener('keydown', this.handleKeyDown)
            //         break;
            //     case 'close':
            //         this.mainDialog = false
            //         this.clearFields()
            //         this.getAllStoreCashIns()
            //         document.removeEventListener('keydown', this.handleKeydown);
            //         break;
            // }
        },
        clearFields(){
            this.$refs.form.resetValidation()
            this.form = {
                id: null,
                company_id: null,
                store_branch_id: null,
                created_at: this.$dayjs().format('YYYY-MM-DD'),
                deposit_date: this.$dayjs().format('YYYY-MM-DD'),
                sales_date: this.$dayjs().format('YYYY-MM-DD'),
                remarks: null,
                status: 1,
                total_amount: 0.00,
                store_cash_in_details: []
            }
            this.chip_status = 0
            this.dispatchDetails = false
            this.$forceUpdate()
        },
        commitStoreCashIn(action, url){
            if (!this.$refs.form.validate()) {
                if (!this.checkRequiredFields()) return
            } else {
                if (this.form.store_cash_in_details.length == 0) {
                    Swal.fire('', '- No Details Found!', 'error')
                    return false
                }
                this.btnLoad = true
                Swal.fire({
                    title: '',
                    text: `Are you sure you want to ${action} store cash in?`,
                    icon: 'question',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    allowOutsideClick: false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor: 'grey',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.btnLoad = false
                        this.dialogLoader = true
                        if (action == 'approve') this.form.status = 2
                        let payload = {
                            form: this.form,
                            url: url
                        }
                        try {
                            this.$store.dispatch('storeCashInPost', payload).then(() => {
                                Swal.fire('', '- Store Cash In saved!', 'success');
                                // this.getAllStoreCashIns();
                                this.toggleDialog('close');
                                this.btnLoad = false
                                this.dialogLoader = false
                            })
                        } catch (error) {
                            console.error(error)
                            Swal.fire('', 'Transaction error!', 'error');
                            this.btnLoad = false
                            this.dialogLoader = false
                            this.toggleDialog('close');
                        }
                    } else {
                        this.btnLoad = false
                    }
                })
            }
        },
        checkRequiredFields(){
            let emptyFields = []
            let required = {
                'company_id': 'Company',
                'store_branch_id': 'Store Branch',
                'sales_date': 'Sales Date',
                'deposit_date': 'Deposit Date',
                'remarks': 'Remarks'
            }
            let requiredDetails = {
                'total_amount': 'Total Amount'
            }
            Object.keys(required).forEach(e=>{
                if (!this.form[e]) {
                    emptyFields.push(required[e])
                }
            })
            let rowCount = 1
            this.form.store_cash_in_details.forEach(item => {
                Object.entries(item).forEach(([key, value]) => {
                    if (key != 'deleted_at' && key != 'paid' && key != 'autoFill' && (value == null || value == '')) {
                        emptyFields.push(`Detail ${rowCount}: ${requiredDetails[key]}`)
                    }
                })
                rowCount++
            })
            if (emptyFields.length > 0) {
                Swal.fire({
                    title: 'Please fill the following fields: ',
                    html: emptyFields.map(field => `${field}<br>`).join(''),
                    icon: 'error'
                })
                return false
            }
            return true
        },
        getAllStoreCashIns(){
            this.tableLoad = true
            this.mainItems = []
            let payload = {
                prepared_by: this.filters.prepared_by,
                company_id: this.filters.company_id,
                store_branch_id: this.filters.store_branch_id,
                search: this.search,
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                tab: this.STORE_CASH_IN.TAB.tab_name,
                chip_status: this.chip_status,
                date_range:[this.filters.date_from,this.filters.date_to],
                url: 'getAllStoreCashIns'
            }
            this.$store.dispatch('transactionJournalGet', payload).then(response=>{
                let data = response.data.data
                this.mainItems = data.data
                this.totalItems = data.total
                this.tableLoad = false
            })
        },
        mode(action = 'create', id = null){
            this.$store.commit('STORE_CASH_IN_ACTION', action)
            if (!id) {
                this.clearFields()
                this.toggleDialog('open')
            } else {
                this.dialogLoader = true
                this.btnLoad = true
                this.toggleDialog('open')
                this.$store.dispatch('transactionJournalPost', {id: id, url: 'getStoreCashIn'}).then(response=>{
                    this.getStoreBranches(2)
                    this.form = response.data
                    this.form.created_at = this.$dayjs(this.form.created_at).format('YYYY-MM-DD')
                    this.form.deposit_date = this.$dayjs(this.form.deposit_date).format('YYYY-MM-DD')
                    this.form.sales_date = this.$dayjs(this.form.sales_date).format('YYYY-MM-DD')
                    this.form.total_amount = parseFloat(this.form.total_amount).toFixed(2)
                    this.form.store_cash_in_details.forEach(e=>{
                        e.name = e.store_cash_in_template.name
                        e.coa_name = e.store_cash_in_template.coa_name
                        e.plus_minus = e.store_cash_in_template.plus_minus
                        e.total_amount = parseFloat(e.total_amount).toFixed(2)
                    })
                    this.dialogLoader = false
                    this.btnLoad = false
                })
            }
        },
        isDisabled(){
            switch(this.STORE_CASH_IN.ACTION){
                case 'view':
                    return true
            }1
        },
        destroy(id){
            Swal.fire({
                title: '',
                text: 'Are you sure you want to delete store cash in?',
                icon: 'question',
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                allowOutsideClick: false,
                confirmButtonColor: '#397373',
                cancelButtonColor: 'grey',
                confirmButtonColor: 'Confirm'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('storeCashInPost', { id: id, url: 'deleteStoreCashIn' }).then(response => {
                        Swal.fire('', '- Store Cash In Deleted!', 'success')
                        // this.getAllStoreCashIns();
                    }).catch(error => {
                        if (error.response.status == 422) {
                            Swal.fire('', 'Error!', 'error')
                            console.log(error)
                        }
                        // this.getAllStoreCashIns()
                    })
                }
            })
        },
        async getBranches(){
            try{
                this.clonedStores = await this.getStoreBranches()
            } catch (error) {
                console.error(error)
            }
        },
        insertCompany(storeId){
            if (!storeId) return
            let store = this.clonedStores.find(e=>e.value == storeId)
            this.form.company_id = this.GET_PV_COMPANIES_SELECTION.find(e=>e.value == store.company_id).value
            if (this.form.company_id && this.form.store_branch_id) this.dispatchDetails = true
        },
        insertStore(){
            this.form.store_branch_id = this.branchItems[0].value
            if (this.form.company_id && this.form.store_branch_id) this.dispatchDetails = true
        },
        handleKeyDown(event){
            if (this.mainDialog && !this.dialogLoader) this.closeDialogByEsc(event, this.toggleDialog)
        },
        numberAndDotOnly($event, item) {
            const allowedCharacters = /^[0-9.]*$/;
            const inputChar = $event.key;
            if (!allowedCharacters.test(inputChar)) {
                $event.preventDefault();
                return
            }
            if (inputChar === '.' && item.total_amount.includes('.')) {
                $event.preventDefault();
            }
        },
        sumAmount(){
            this.form.total_amount = 0;
            this.form.store_cash_in_details.forEach(e => {
                if (e.plus_minus === "+") {
                    this.form.total_amount += parseFloat(e.total_amount);
                } else {
                    this.form.total_amount -= parseFloat(e.total_amount);
                }
            });
            this.form.total_amount = this.form.total_amount.toFixed(2);
        },
    },
    watch:{
        // USER_ACCESS:{
        //     handler(val){
        //         if (val != 'fail') {
        //             this.userAccess = val.map(e=>e.actions_code)
        //         }
        //     }
        // },
        options:{
            handler(){
                // this.getAllStoreCashIns()
            },
            deep:true
        },
        SELECTED_TAB:{
            handler(){
                this.options.page = 1
                // this.getAllStoreCashIns()
            }
        },
        search:{
            handler(val){
                if (val) {
                    this.options.page = 1
                }
            },
            immediate: true
        },
        'form.company_id':{
            handler(val){
                if (!val) {
                    this.form.store_branch_id = null
                }
            },
            immediate: true
        },
        'form.store_branch_id':{
            handler(val){
                if (!val) {
                    this.form.company_id = null
                }
            },
            immediate: true
        },
        'dispatchDetails':{
            handler(val){
                if (val) {
                    let payload = {
                        company_id: this.form.company_id,
                        store_branch_id: this.form.store_branch_id,
                        url: 'getStoreCashInTemplates'
                    }
                    this.$store.dispatch('storeCashInGet', payload).then(response=>{
                        this.store_cash_in_details_load = true
                        this.dispatchDetails = false
                        let data = response.data
                        this.form.store_cash_in_details = data
                        if (this.form.store_cash_in_details.length == 0) {
                            Swal.fire('No Template Found!', '- create a template in the TEMPLATES tab', 'error');
                        }
                        this.form.store_cash_in_details.forEach(e=>{
                            e.total_amount = 0.00
                        })
                        this.store_cash_in_details_load = false
                    })
                }
            },
            immediate: true
        },
        viewStoreCashIn: {
            handler(val){
                if (val) {
                    this.dialogLoader = true
                    this.btnLoad = true
                    this.$store.dispatch('storeCashInPost', {id: val, url: 'getStoreCashIn'}).then(response=>{
                        this.getStoreBranches(2)
                        this.form = response.data
                        this.form.created_at = this.$dayjs(this.form.created_at).format('YYYY-MM-DD')
                        this.form.deposit_date = this.$dayjs(this.form.deposit_date).format('YYYY-MM-DD')
                        this.form.sales_date = this.$dayjs(this.form.sales_date).format('YYYY-MM-DD')
                        this.form.total_amount = parseFloat(this.form.total_amount).toFixed(2)
                        this.form.store_cash_in_details.forEach(e=>{
                            e.name = e.store_cash_in_template.name
                            e.coa_name = e.store_cash_in_template.coa_name
                            e.plus_minus = e.store_cash_in_template.plus_minus
                            e.total_amount = parseFloat(e.total_amount).toFixed(2)
                        })
                        this.dialogLoader = false
                        this.btnLoad = false
                    })
                }
            },
            immediate: true,
            deep: true
        }
    }
}
</script>