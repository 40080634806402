	<template>
		<v-container fluid >
			<h3 class="page-title">Recruitment System</h3>
			<v-container class="ma-0">
					<v-app id="item-tabs">
						<v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" background-color="#424242" dark show-arrows>
							<v-tabs-slider color="cyan"></v-tabs-slider>
								<v-tab ripple v-for="(item, i) in items" :key="i" @click="tabs(i)" v-if="tabAccess(item)">
                                    <!-- <v-badge
                                        v-if="item == '(REQ) For Approval'"
                                        offset-x="20"
                                        offset-y="0"
                                        color="orange"
                                        content="6"
                                    >
                                    </v-badge> -->
									{{ item.tab_name }}
								</v-tab>
						</v-tabs>
						<v-tabs-items v-model="tab">
							<v-tab-item v-for="(table, index) in recruitment_component" :key="index" v-if="tabAccess(table)">
								<keep-alive>
									<component :is="table.components" :export_file_name="table.export_file_name" :isActive_tab="table.status_text">
									</component>
								</keep-alive>
							</v-tab-item>
						</v-tabs-items>
					</v-app>
			</v-container>
			<DialogComponent :cp="components" :scrollable="dialog_scrollable" :width="height(dialog_width)" :retain-focus="false"></DialogComponent>
		</v-container>
	</template>

<script>
	import DialogComponent from '@/views/main/layouts/dialogs/Dialog.vue';
    import PusherFunctionComponentVue from '@/views/main/Shared/PusherFunctionComponent.vue';
    import RecruitmentComponentVue from '@/views/main/modules/HR/RecruitmentSystem/RecruitmentComponent.vue';
    import ManpowerRequestViewComponentVue from '@/views/main/layouts/dialogs/HR/RecruitmentSystem/ManpowerRequestViewComponent.vue';
	import ApplicantDataViewComponent from '@/views/main/layouts/dialogs/HR/RecruitmentSystem/ApplicantDataViewComponent.vue';


	import { mapGetters } from 'vuex';
	export default {
		mixins:[PusherFunctionComponentVue],
		components:{
			DialogComponent,
			RecruitmentComponentVue,
			ManpowerRequestViewComponentVue,
			ApplicantDataViewComponent
		},
        mixins:[PusherFunctionComponentVue],
		data() {
			return {
				components:'',
				items: [
                    {tab_name:"Personnel Request", access:'personnel_request'},
                    {tab_name:"Applicant List",access:'applicant_list'},
                    {tab_name:"Talent Pool",access:'applicant_list'},
                    {tab_name:"(REQ) For Approval", access:'req_for_approval'},
                    {tab_name:"Privacy Settings", access:'privacy_settings'},
                    {tab_name:"Email Message", access:'email_message'}
                ],
				recruitment_component: [
						{
						components: RecruitmentComponentVue,
                        access:'personnel_request',
						status_text: "manpower_request",
						dialog: ManpowerRequestViewComponentVue,
						dialog_title:'Recruitment Request Form',
						dialog_width: "65%",
						scrollable:true,
						dispatch:{get:'GetHrManpowerRequest',
									add:'AddHrManpowerRequest',
									update:'UpdateHrManpowerRequest',
									delete:'DeleteHrManpowerRequest'},
						},
						{
						components: RecruitmentComponentVue,
                        access:'applicant_list',
						status_text: "applicant_list",
						dialog: ApplicantDataViewComponent,
						dialog_title:'Application Form',
						dialog_width: "80%",
						scrollable:true,
						dispatch:{get:'GetHrApplicationList',
									add:'AddHrApplicationList',
									update:'UpdateHrApplicationList',
									delete:'DeleteHrApplicationList'},
                        exportHeaders:[
                            { header: 'Applicant ID', key: 'applicant_id', width: 40, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }},
                            { header: 'Applicant NAME', key: 'full_name', width: 40, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }},
                            { header: 'Department', key: 'department_name', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }},
                            { header: 'Position', key: 'position_name', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }},
                            { header: 'Status', key: 'status_name', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }},
                            { header: 'Application Date', key: 'application_date', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }},
                        ],
                        export_file_name: 'Applicant List'
						},
						{
						components: RecruitmentComponentVue,
                        access:'applicant_list',
						status_text: "shortlisted_applicant_list",
						dialog: ApplicantDataViewComponent,
						dialog_title:'Application Form',
						dialog_width: "80%",
						scrollable:true,
						dispatch:{get:'GetHrApplicationList',
									add:'AddHrApplicationList',
									update:'UpdateHrApplicationList',
									delete:'DeleteHrApplicationList'},
						},
						{
						components: RecruitmentComponentVue,
                        access:'req_for_approval',
						status_text: "req_for_approval",
						dialog: ApplicantDataViewComponent,
						dialog_title:'Application Form',
						dialog_width: "80%",
						scrollable:true,
						dispatch:{
                                    get:'GetHrApplicationList',
									update:'UpdateHrApplicationList',
                                    approve:'',
                                    reject:''
                                },
						},
						{
						components: RecruitmentComponentVue,
                        access:'privacy_settings',
						status_text: "privacy_settings",
						dialog: ApplicantDataViewComponent,
						dialog_title:'Privacy Settings',
						dialog_width: "80%",
						scrollable:true,
						dispatch:{get:'GetHrPrivacySettings',
									add:'AddHrPrivacySettings',
									update:'UpdateHrPrivacySettings'},
						},
						{
						components: RecruitmentComponentVue,
                        access:'email_message',
						status_text: "email_message",
						dialog: ApplicantDataViewComponent,
						dialog_title:'Applicant Email Message',
						dialog_width: "80%",
						scrollable:true,
						dispatch:{get:'GetApplicantStatusMessage',
									update:'UpdateApplicantStatusMessage'},
						},
				],
				dialog_width: "",
				loaded: false,
				options: {},
				itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
				itemsPerPage: 10,
				tab: null,
				dialog_scrollable:true,
			};
		},
		computed:{
			...mapGetters(['USERACCOUNT_LOAD','USER_ACCESS'])
		},
		mounted(){
			this.tabs(0)
            this.callPusher()
            this.addEchoListener()

            this.$store.dispatch('checkAccessUser',this.$router.currentRoute.params.id);
			this.$store.dispatch('GetHrPlantilla')
		},
        beforeDestroy() {
            this.removeEchoListener();
        },
		methods:{
        addEchoListener(){
             window.Echo.channel('ingco-channel').listen('RealtimeRecruitmentNotification', (e) => {
                if(e.requestor_id == this.USERACCOUNT_LOAD.id){
                    this.$toast[e.icon](`${e.message} - ${e.position}`, {
                        position: 'bottom-right',
                        showCloseButtonOnHover: true,
                        timeout: 2500, // Time in milliseconds (ms) for the notification to disappear
                    });
                }
            });
        },
        removeEchoListener() {
            window.Echo.channel('ingco-channel').stopListening('RealtimeRecruitmentNotification', this.echoListener);
        },
		tabs(key) {
			this.components = this.recruitment_component[key].dialog;
			this.dialog_width = this.recruitment_component[key].dialog_width;
			this.dialog_scrollable = this.recruitment_component[key].scrollable;

			this.$store.commit('HR_DIALOG_TITLE',this.recruitment_component[key].dialog_title)
			this.$store.commit('HR_TAB_IS_ACTIVE',this.recruitment_component[key].status_text)
			this.$store.commit('HR_DISPATCH',this.recruitment_component[key].dispatch)
			this.$store.commit('HR_GETTERS',this.recruitment_component[key].getters)
			this.$store.commit('HR_GETTERS',this.recruitment_component[key].getters)
            this.$store.commit('EXPORT_HEADERS',this.recruitment_component[key].exportHeaders)
		},
		getSearchFromUrl() {
            if (Object.keys(this.$route.query).length != 0) {
                Object.keys(this.$route.query).forEach(e=>{
					if(e == 'for_approval'){
						this.tab = this.items.length - 1
						this.tabs(this.tab)
					}
                })
            }
        },
		height(width) {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs': return "100%"
				case 'sm': return "100%"
				case 'md': return width
				case 'lg': return width
				case 'xl': return width
			}
		},
        tabAccess(item){
            if(this.USER_ACCESS != "fail"){
                if(this.USER_ACCESS.find(e=>e.actions_code == item.access)){
                    if(this.USER_ACCESS.find(e=>e.actions_code == item.access).actions_code == item.access)
                    {
                        return true
                    }
                }
            }
        }
	},
	watch:{
		USERACCOUNT_LOAD:{
			handler(val){
			}
		},

	}

	};
	</script>
	<style>
	#group-tab {
		padding: 0 !important;
	}
	</style>

