var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"lg":"3"}},[_c('v-btn',{attrs:{"small":""},on:{"click":()=>{
                        _vm.dialog.value = true
                        _vm.dialog.action = 'Add'
                    }}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add Equipments ")],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"lg":"12"}},[_c('v-data-table',{attrs:{"items":_vm.dataTable.items,"headers":_vm.dataTable.headers,"options":_vm.dataTable.options,"items-per-page":_vm.dataTable.total,"dense":""},on:{"update:options":function($event){return _vm.$set(_vm.dataTable, "options", $event)}},scopedSlots:_vm._u([{key:`item.action`,fn:function({ item }){return [_c('v-btn',{attrs:{"text":"","icon":"","color":"blue"}},[_c('v-icon',{staticClass:"btn-action",attrs:{"small":""},on:{"click":function($event){return _vm.view(item,'Update')}}},[_vm._v("mdi-square-edit-outline")])],1),_c('v-btn',{attrs:{"text":"","icon":"","color":"red"}},[_c('v-icon',{staticClass:"btn-action",attrs:{"small":""},on:{"click":function($event){_vm.dialog.action = 'Delete';_vm.form.id = item.id;_vm.modifyEquipment('delete-equipment')}}},[_vm._v("mdi-delete")])],1)]}}],null,true)})],1)],1)],1),_c('v-dialog',{attrs:{"width":"600px"},model:{value:(_vm.dialog.value),callback:function ($$v) {_vm.$set(_vm.dialog, "value", $$v)},expression:"dialog.value"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v(_vm._s(_vm.dialog.action)+" Equipments")]),_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":()=>{
                       _vm.dialog.value = false
                       _vm.dialog.action = ''
                       _vm.dialog.selected = ''
                    }}},[_c('v-icon',[_vm._v("mdi-close-circle")])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"lg":"12"}},[_c('v-text-field',{attrs:{"label":"Name","dense":"","outlined":"","disabled":_vm.dialog.action == 'View'},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('v-col',{attrs:{"lg":"12"}},[_c('v-text-field',{attrs:{"label":"Description","dense":"","outlined":"","disabled":_vm.dialog.action == 'View'},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1),_c('v-col',{attrs:{"lg":"12"}},[_c('v-text-field',{attrs:{"label":"Description","dense":"","outlined":"","disabled":_vm.dialog.action == 'View'},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}})],1)],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-content-center align-items-center"},[_c('v-btn',{attrs:{"small":"","color":"orange"},on:{"click":function($event){return _vm.modifyEquipment('job-order-equipment')}}},[_vm._v(" "+_vm._s(_vm.dialog.action)+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }