<template>
    <v-dialog v-model="repairItemsDialog" persistent max-width="50%">
        <v-card>
            <v-row class="m-0">
                <v-card-title>
                    <span class="headline">{{ `${itemStatus == '>=30' ? 'Greater Than 30 Days Repair Items' : 'Less Than 30 Days Repair Items'}` }}</span>
                </v-card-title>
                <v-col cols="pull-right-10 p-2">
                    <v-btn text icon color="gray" class="float-right" @click="closeRepairItemsDialog()">
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-divider class="mt-0" />

            <div class="px-4">
                <v-layout row class="mx-0">
                    <v-spacer />
                    <v-flex lg2>
                        <v-autocomplete
                            :items="topRepairSelection"
                            v-model="selectedTop"
                            item-value="value"
                            item-text="text"
                            placeholder="Select Top"
                        />
                    </v-flex>
                </v-layout>

                <v-data-table
                    :headers="repairItemsHeaders"
                    :items="items"
                >

                </v-data-table>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            repairItemsHeaders: [
                { text: 'RF#', value: 'rf_number' },
                { text: 'Model', value: 'model' },
                { text: 'Category', value: 'category' },
                { text: 'Repair Days', value: 'days' },
                { text: 'Defect', value: 'defect_description' },
            ],
            items: [],
            status: '',
            topRepairSelection: [
                { text: 'All', value: 0 },
                { text: 'Top 5', value: 5 },
                { text: 'Top 10', value: 10 }
            ],
            selectedTop: 0
        }
    },
    async mounted() {

    },
    props: ['itemStatus'],
    computed: {
        repairItemsDialog() {

            return this.$store.state.dashboard.repair.itemsDialog;
        }
    },
    watch: {
        itemStatus() {
            if(!!this.itemStatus) {
                this.status = this.itemStatus;
            }
        },
        status() {
            if(!!this.status) {
                this.seeAllRepairItems();
            }
        },
        selectedTop() {
            this.seeAllRepairItems();
        },
    },
    methods: {
        closeRepairItemsDialog() {
            this.$store.commit('closeScEstDialog');
            this.$emit('closeDialog', true);
            this.items = [];
            this.status = '';
            this.selectedTop = 5;
        },
        seeAllRepairItems() {
            let payload = {
                status: this.status,
                top: this.selectedTop
            }

            this.$store.dispatch('seeAllRepairItems',payload).then(response=>{
                this.items = response.data.items;
            });
        }
    }
}
</script>

<style>

</style>
