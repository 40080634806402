<template>
    <v-container fluid>
        <v-row dense no-gutters tag="div" style="align-items: right">
            <v-spacer></v-spacer>
            <v-col cols="12" sm="3" md="3">
                <v-text-field
                    v-model="search"
                    @change="searchInput()"
                    label="Search RF # / SF #"
                    single-line
                    hide-details
                    dense
                    append-icon="mdi-magnify"
                >
                </v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-data-table
                :headers="dataTable.headers"
                :items="dataTable.items"
                :loading="loading"
                :multi-sort="true"
                :disable-sort="true"
                :options.sync="dataTable.options"
                :server-items-length="dataTable.totalItems"
                :footer-props="{
                    itemsPerPageOptions: dataTable.itemsPerPageOptions,
                }"
                loading-text="Loading... Please wait"
                class="elevation-1 w-100"
                fixed-header
            >
                <template v-slot:[`item.action`]="{ item }">
                    <v-btn text icon color="orange" v-if="userAccess.view">
                    <v-icon
                        class="btn-action"
                        small
                        @click="viewReturnForm(item.id,'Approve')"
                        >mdi-eye</v-icon
                    >
                    </v-btn>
                    <v-btn text icon color="blue" v-if="userAccess.update_return && item.status == 'pending'">
                    <v-icon
                        class="btn-action"
                        small
                        @click="viewReturnForm(item.id,'Update')"
                        >mdi-pencil</v-icon
                    >
                    </v-btn>
                    <!-- <v-btn text icon color="red" v-if="userAccess.delete">
                    <v-icon
                        class="btn-action"
                        small
                        @click="deleteSampleForm(item.id)"
                        >mdi-delete</v-icon
                    >
                    </v-btn> -->
                </template>

                <template v-slot:[`item.status`]="{ item }">
                    <span :class="`badge badge-${item.status.toLowerCase()}`">{{ item.status.replace('_',' ') }}</span>
                </template>

                <template v-slot:[`item.return_date`]="{ item }">
                    {{ new Date(item.return_date).toLocaleDateString() }}
                </template>

                <template v-slot:[`item.cancel_date`]="{ item }">
                    {{ item.cancel_date ? new Date(item.cancel_date).toLocaleDateString() :item.cancel_date}}
                </template>

            </v-data-table>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props:['tab_status'],
    data() {
        return {
            dataTable:{
                headers:[
                    { text: 'RF #', value: 'return_form_num', align: 'start', width:'10%'},
                    { text: 'SF #', value: 'sample_form_num', align: 'start', width:'10%'},
                    { text: 'Salesman', value: 'salesman_name', align: 'start', width:'10%'},
                    { text: 'Status', value: 'status', align: 'start', width:'8%' },
                    { text: 'Approved By', value: 'approved_by', align: 'start',width:'15%'},
                    { text: 'Remaining Qty', value: 'remaining_return_qty', align: 'right',width:'10%' },
                    { text: 'Return Date', value: 'return_date', align: 'start',width:'10%' },
                    { text: 'Cancelled By', value: 'cancel_by', align: 'start',width:'10%' },
                    { text: 'Cancelled Date', value: 'cancel_date', align: 'start',width:'10%' },
                    { text: 'Action', value: 'action', align: 'center' },
                ],
                items: [],
                options: {},
                itemsPerPageOptions: [5, 10, 15, 50, 100],
                itemsPerPage: 10,
                totalItems: 0,
            },
            userAccess: {
                view: false,
                update_return: false,
            },
            loading: false,
            search: '',
        }
    },
    mounted() {
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        // this.getInventoryReturnForms()
    },
    computed: {
        ...mapGetters([
            'USER_ACCESS',
            'GET_RF_TABLE_REFRESH',
            'GET_SF_ACTIVE_TAB'
        ])
    },
    methods: {
        getInventoryReturnForms() {
            this.dataTable.items = [];
            this.loading = true;
            let payload = {
                page: this.dataTable.options.page,
                itemsPerPage: this.dataTable.options.itemsPerPage,
                search: this.search,
				url: 'getInventoryReturnForms',
                tab: this.tab_status,
            }
            this.$store.dispatch('getInventoryReturnForms', payload).then(response => {
                this.dataTable.items = response.data.data.data
                this.loading = false
                this.dataTable.totalItems = response.data.data.total;
            })
        },
        async viewReturnForm(id, action) {
            await this.$store.dispatch('viewReturnFormDetails', id).then(response => {
                this.$store.commit("DIALOG", true);
                this.$store.commit("ACTION", action);
                this.$store.commit('VIEW_DATA',response.data)
            }).catch((err)=>{
                swal.fire({
                    title: 'Error',
                    text: 'Network Error',
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
                console.log(err)
                this.loading = false
            });;
        },
        searchInput() {
            this.getInventoryReturnForms({ query: this.search });
            this.dataTable.options.page = 1;
        },
    },
    watch: {
        USER_ACCESS: {
            handler(val) {
                if(val != "fail"){
                    this.userAccess.view = false;
                    this.userAccess.update_return = false;
                    this.userAccess.create_return = false;
                    val.forEach(e => {
                        if (e.actions_code == 'view') {
                            this.userAccess.view = true;
                        } else if (e.actions_code == 'create_return') {
                            this.userAccess.create_return = true;
                        } else if (e.actions_code == 'update_return') {
                            this.userAccess.update_return = true;
                        }
                    });
                }
            }
        },
        GET_RF_TABLE_REFRESH: {
            handler(val) {
                if(val){
                    this.getInventoryReturnForms();
                    this.$store.commit('RF_TABLE_REFRESH', false);
                    this.$store.commit('DIALOG',false);
                }
            }
        },
        'dataTable.options': {
            handler(val) {
                this.getInventoryReturnForms()
            },
            deep: true,
        },
        tab_status:{
            handler(val){
                this.getInventoryReturnForms();
            }
        }
    }
}
</script>

<style scoped>

</style>
