<template>
    <v-container class="ma-0 pa-2">
            <h4 class="page-title">Warehouse Calendar</h4>
        </v-card-title>
        <v-container class="ma-0 pa-1">
            <v-app id="item-tabs" class="mt-5">
                <v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" background-color="#424242" dark class="col-12" show-arrows>
                    <v-tab>WPF</v-tab>
                    <v-tab>CF</v-tab>
                    <v-tabs-items v-model="tab" touchless>
                        <v-tab-item>
                            <WarehouseCalendarComponentVue :tab="'wpf'"></WarehouseCalendarComponentVue>
                        </v-tab-item>
                        <v-tab-item>
                            <WarehouseCalendarComponentVue :tab="'cf'"></WarehouseCalendarComponentVue>
                        </v-tab-item>
                    </v-tabs-items>
                </v-tabs>
            </v-app>
        </v-container>
    </v-container>
</template>
<script>
import WarehouseCalendarComponentVue from './WarehouseCalendarComponent.vue';

export default {
    components: {
        WarehouseCalendarComponentVue,
    },
    data() {
        return {
            tab: null,
        }
    },
    mounted(){
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
        this.$store.dispatch('getAllCustomers')
        
    }
}
</script>
<style>
    #group-tab{
        padding: 0 !important;
    }
</style>
