<template>
    <v-card>
        <v-card-title>
            {{ ACTION == 'Submit' ? "Create" : ACTION }}
            Turn-over
            <v-spacer></v-spacer>
            <v-btn
                class="float-right"
                text
                icon
                small
                color="gray"
                @click="closeDialog()"
            ><v-icon>mdi-close-circle</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
            <slot name="type"></slot>
            <v-row class="mt-2" no-gutters>
                <v-col cols="6">
                    <v-row no-gutters>
                        <!-- <v-col cols="4">
                            <v-autocomplete
                                v-model="form.admin_location_id"
                                label="Location"
                                :items="GET_ADMIN_LOCATION"
                                item-text="text"
                                item-value="value"
                                :loading="formLoading"
                                :readonly="viewing"
                                outlined
                                required
                                dense
                            ></v-autocomplete>
                        </v-col> -->
                        <v-col cols="6">
                            <v-autocomplete
                                v-model="form.department_id"
                                label="Department"
                                :items="GET_LIST_DEPARTMENT"
                                item-text="text"
                                item-value="value"
                                :loading="formLoading"
                                :readonly="viewing"
                                outlined
                                required
                                dense
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="6">
                            <v-autocomplete
                                v-model="form.employee_data_ids"
                                label="Employee"
                                :items="employee_selection_data"
                                item-text="text"
                                item-value="value"
                                :loading="formLoading"
                                :readonly="viewing"
                                outlined
                                required
                                dense
                                multiple
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12" lg="6">
                            <v-text-field
                                v-model="form.purpose"
                                label="Purpose"
                                dense
                                outlined
                                :readonly="viewing"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="2">
                            <v-checkbox
                                class="checkbox"
                                v-model="form.ldrf"
                                label="LDRF"
                                :readonly="viewing"
                                @change="() => {
                                    form.repairable = undefined
                                    form.account_to_employee_data_id = ''
                                }"
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="6" v-if="form.ldrf">
                            <v-radio-group
                                row
                                v-model="form.repairable"
                                :readonly="viewing"
                            >
                                <v-radio

                                    label="Repairable"
                                    value="1"
                                ></v-radio>
                                <v-radio
                                    label="For disposal"
                                    value="0"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="6">
                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-textarea
                                v-model="form.audit_findings"
                                dense
                                outlined
                                label="Audit Findings"
                                :readonly="viewing"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="mt-2">
                <v-col class="pl-4 mt-12" lg="3">
                    <div class="w-50">
                        <v-text-field
                        v-model="accountedDataTable.search_key"
                        label="Search"
                        dense
                        append-icon="mdi-magnify"
                        clearable
                        :disabled="viewing"
                        @click:append="getAccountabilities"
                        @click:clear="clearAccountedDataTableSearch"
                        @keyup.enter="getAccountabilities"
                    />
                    </div>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="mt-12 mr-4" lg="3">
                    <v-autocomplete
                        v-if="!form.ldrf"
                        v-model="form.account_to_employee_data_id"
                        label="Account To"
                        :items="GET_EMPLOYEE_DATA"
                        item-text="text"
                        item-value="value"
                        :loading="formLoading"
                        :readonly="viewing"
                        outlined
                        required
                        dense
                    ></v-autocomplete>
                </v-col>
            </v-row>
            <v-row class="mt-2" no-gutters>
                <v-col cols="6">
                    <v-data-table
                        class="p-4 bg-light border"
                        :headers="accountedDataTable.headers"
                        :items="accountedDataTable.items"
                        :options.sync="accountedDataTable.options"
                        :server-items-length="accountedDataTable.total_items"
                        :loading="accountedDataTable.loading || accountedDataTable.dropdownLoading || formLoading"
                        item-key="id"
                        :show-expand="!viewing"
                        :expanded.sync="accountedDataTable.expanded"
                        disable-sort
                        @update:page="getAccountedAssets()"
                        @update:items-per-page="getAccountedAssets()"
                    >
                        <template v-slot:[`item.created_at`]="{ item }">
                            {{ formattedDate(new Date(item.created_at)) }}
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-btn
                                text
                                icon
                                color="orange"
                                :disabled="isAccountabilityItemsInTurnover(item, true) || viewing"
                                @click="addItem(item, true)"
                            ><v-icon small>mdi-arrow-right-bottom-bold</v-icon></v-btn>
                            <v-btn
                                text
                                icon
                                color="red"
                                :disabled="!isAccountabilityItemsInTurnover(item) || viewing"
                                @click="removeItem(item, true)"
                            ><v-icon small>mdi-arrow-left-bottom-bold</v-icon></v-btn>
                        </template>
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length" class="p-0 bg-white">
                                <v-data-table
                                    :items="accountedDataTableExpandItems(item)"
                                    :headers="[
                                        { text: 'Asset Code', value: 'asset_code', align: 'center' },
                                        { text: 'Asset Name', value: 'item_name' },
                                        { text: 'Date Accounted', value: 'created_at', align: 'center' },
                                        { text: 'Actions', value: 'actions', width: '95px' },
                                    ]"
                                    disable-sort
                                    dense
                                    hide-default-footer
                                >
                                    <template v-slot:[`item.created_at`]="{ item }">
                                        {{ formattedDate(new Date(item.created_at)) }}
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-btn
                                            text
                                            icon
                                            color="orange"
                                            :disabled="isItemInTurnover(item) || viewing"
                                            @click="addItem(item)"
                                        ><v-icon small>mdi-arrow-right-bottom-bold</v-icon></v-btn>
                                    </template>
                                </v-data-table>
                            </td>
                        </template>
                    </v-data-table>
                </v-col>
                <v-col cols="6">
                    <v-data-table
                        class="bg-light border"
                        :headers="turnoverDataTable.headers"
                        :items="turnoverDataTable.items"
                        :loading="turnoverDataTable.loading"
                        disable-sort
                    >
                        <template v-slot:[`item.admin_asset_condition_id`]="{ item }">
                            <!-- <v-autocomplete
                                v-model="item.admin_asset_condition_id"
                                :items="GET_ADMIN_ASSET_CONDITION_SELECTION"
                                item-value="value"
                                item-text="text"
                                dense
                                outlined
                                :disabled="viewing"
                            /> -->
                            <v-radio-group v-model="item.admin_asset_condition_id" row>
                                <v-radio
                                    v-for="(items,index) in GET_ADMIN_ASSET_CONDITION_SELECTION" :key="index"
                                    :label="items.text"
                                    :value="items.value"
                                    hide-details
                                    dense
                                >

                                </v-radio>
                                <v-spacer></v-spacer>
                            </v-radio-group>
                        </template>
                        <template v-slot:[`item.warehouse_id`]="{ item }">
                            {{ getWarehouse(item) }}
                        </template>
                        <template v-slot:[`item.actions`]="{ item, index }">
                            <div class="d-flex justify-content-center">
                                <v-btn
                                    text
                                    icon
                                    color="red"
                                    :disabled="viewing"
                                    @click="removeItem(item)"
                                ><v-icon small>mdi-arrow-left-bottom-bold</v-icon></v-btn>
                                <FileUploadComponentV2
                                    v-if="ACTION != 'View'"
                                    ref="fileUpload"
                                    id="attachment"
                                    :fileIndex="index"
                                    :onFileChange="attachFile"
                                    @reset="clearAttachments"
                                />
                                <BaseFilesViewerComponentV2
                                    ref="clearFiles"
                                    :payloadId="item.id ?? null"
                                    :getFilesDispatch="'getAdminAssetTurnoverItemFile'"
                                    :deleteFileDispatch="'deleteAdminAssetTurnoverItemFile'"
                                    :module="'admin'"
                                    :folder="'asset_turnover_file_uploads'"
                                    :localFiles="item.files"
                                    :fileIndex="index"
                                    :onLocalFileDelete="onLocalFileDelete"
                                    :viewing="ACTION == 'View'"
                                />
                            </div>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="mb-4 d-flex justify-content-center align-items-center">
            <v-btn
                v-if="ACTION == 'View' && accessCodes.includes('asset_turnover_approve') && form.status == 1"
                :loading="formLoading"
                @click="approveTurnover"
            >Approve</v-btn>
            <v-btn
                v-if="ACTION == 'View' && accessCodes.includes('asset_turnover_print')"
                :loading="formLoading"
                @click="renderTurnOverPDF"
            >Print</v-btn>
            <v-btn
                v-if="ACTION != 'View'"
                :loading="formLoading || accountedDataTable.dropdownLoading"
                @click="createOrUpdateTurnover"
            >{{ ACTION }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import Swal from 'sweetalert2';
import FileUploadComponentV2 from '@/views/main/Shared/FileUploadComponentV2.vue';
import BaseFilesViewerComponentV2 from "@/views/main/Shared/BaseFilesViewerComponentV2.vue";
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
import AccountabilityTurnoverFormPrintComponent from "@/views/main/layouts/PrintTemplate/AccountabilityTurnoverFormPrintComponent.vue";

export default {
    mixins: [ SharedFunctionsComponentVue, AccountabilityTurnoverFormPrintComponent ],
    props:['boundType'],
    components: {
        FileUploadComponentV2,
        BaseFilesViewerComponentV2,
    },

    data() {
        return {
            formLoading: false,

            accessCodes: [],
            form: {},

            employee_selection_data: [{
                'value': 0,
                'text': 'GENERAL ACCOUNTABILITY',
                'department_id': 0,
            }],

            accountedDataTable: {
                loading: false,
                dropdownLoading: false,
                total_items: 0,
                search_key: '',

                headers: [
                    { text: 'Doc #', value: 'accountability_num', align: 'center' },
                    { text: 'Category', value: 'admin_category', align: 'center' },
                    { text: 'Items Count', value: 'items_count', align: 'center' },
                    { text: 'Date Accounted', value: 'created_at', align: 'center' },
                    { text: 'Actions', value: 'actions', align: 'center', width: '120px' },
                ],
                items: [],
                dropdrownItems: [],
                expanded: [],
                options: {},
            },
            turnoverDataTable: {
                loading: false,

                headers: [
                    { text: 'Doc #', value: 'accountability_num', align: 'center' },
                    { text: 'Asset Code', value: 'asset_code', align: 'center' },
                    { text: 'Asset Name', value: 'item_name' },
                    { text: 'Condition', value: 'admin_asset_condition_id', align: 'center' },
                    { text: 'Inventory', value: 'warehouse_id' },
                    { text: 'Actions', value: 'actions', align: 'center', },
                ],
                items: [],
            },
        };
    },

    mounted() {
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        .then(() => {
            this.accessCodes = this.USER_ACCESS.map(access => access.actions_code);
        });
        this.resetForm();
    },

    computed: {
        ...mapGetters([
            "USER_ACCESS",
            "ACTION",
            "GET_ADMIN_ASSET_CONDITION_SELECTION",
            "GET_ADMIN_ASSET_TURNOVER_VIEW_ID",
            "GET_ADMIN_LOCATION",
            "GET_EMPLOYEE_DATA",
            "GET_LIST_DEPARTMENT",
            "GET_ADM_WAREHOUSE_CODE_DROPDOWN",
            "SELECTED_TAB",
            "GET_INBOUND_OUTBOUND_TYPE"
        ]),

        viewing() {
            return this.ACTION == 'View';
        },
        // accountTo(){
        //     return GET_LIST_DEPARTMENT.filter(e=>e.)
        // }
    },

    methods: {
        accountedDataTableExpandItems(item) {
            return this.accountedDataTable.dropdrownItems.filter(element => element.accountability_num == item.accountability_num);
        },
        addItem(item, all = false) {
            if (!all) return this.turnoverDataTable.items.push(item);

            const currentItemIds = this.turnoverDataTable.items.map(element => element.admin_accountability_item_id);
            const matchItems = this.accountedDataTable.dropdrownItems
                .filter(element => element.accountability_num == item.accountability_num && !currentItemIds.includes(element.admin_accountability_item_id));
            this.turnoverDataTable.items = this.turnoverDataTable.items.concat(matchItems);
        },
        approveTurnover() {
            Swal.fire({
                title:'Approve Turn Over!',
                text:'- Are you sure you want to Approve ?',
                showCancelButton:true,
                reverseButtons:true,
                icon:'question'
            }).then(action=>{
                if(action.isConfirmed){
                    this.$store.dispatch('adminAssetTurnoverPut', { url: `approve-admin-asset-turnover/${this.form.id}` }).then(() => {
                        Swal.fire({
                            title: 'Approve Success',
                            icon: 'success',
                        });
                        this.closeDialog();
                    }).catch(error => {
                        let text = '';

                        // if (error.response.status === 400) text = 'Missing required parameters.';

                        return Swal.fire({
                            title: `Internal Server Error`,
                            text: text,
                            icon: 'error',
                        });
                    });
                }
            })
        },
        attachFile(data, index){
            if (!data) {
                this.turnoverDataTable.items[index].files = [];
            }
            this.turnoverDataTable.items[index].files = data;
            this.$forceUpdate();
        },
        clearAccountedDataTableSearch() {
            this.accountedDataTable.search = '';
            this.getAccountabilities();
        },
        clearAttachments(index) {
            this.turnoverDataTable.items[index].files = [];
        },
        closeDialog() {
            this.accountedDataTable.items = [];
            this.turnoverDataTable.items = [];
            this.accountedDataTable.expanded = [];

            this.resetForm();
            this.$store.commit("ADMIN_ASSET_TURNOVER_DATA_TABLE_RELOAD", true);
            this.$store.commit("ADMIN_ASSET_TURNOVER_VIEW_ID", undefined);
            this.$store.commit("DIALOG", false);
            this.$store.commit("ACTION", "");
        },
        createOrUpdateTurnover() {

            let checkIfAllItemHasFile = false;
            this.turnoverDataTable.items.map((e) => {
                if(e.files.length == 0){
                    checkIfAllItemHasFile = true;
                }
            });

            if(checkIfAllItemHasFile && this.ACTION == 'Submit'){
                return Swal.fire('Files Attachment Required!','','warning');
            }

            let remove_item_ids = undefined;

            // if (!this.form.admin_location_id)
            //     return Swal.fire({
            //         title: `WARNING`,
            //         text: 'Please select a location.',
            //         icon: 'warning',
            //     });

            if (!this.form.department_id)
                return Swal.fire({
                    title: `WARNING`,
                    text: 'Please select a department.',
                    icon: 'warning',
                });

            if (this.form.employee_data_ids.length <= 0)
                return Swal.fire({
                    title: `WARNING`,
                    text: 'Please select an employee.',
                    icon: 'warning',
                });

            if (this.form.ldrf && !this.form.repairable) {
                return Swal.fire({
                    title: `WARNING`,
                    text: 'Please select a repairable state.',
                    icon: 'warning',
                });
            }

            if (this.turnoverDataTable.items.length <= 0)
                return Swal.fire({
                    title: `WARNING`,
                    text: 'No asset is selected to be turn-over.',
                    icon: 'warning',
                });

            // if (!this.form.warehouse_id)
            //     return Swal.fire({
            //         title: `WARNING`,
            //         text: 'Please select an warehouse.',
            //         icon: 'warning',
            //     });

            let hasInvalidItem = false;
            let invalidItem = undefined;

            this.turnoverDataTable.items.forEach(item => {
                if (!item.admin_asset_condition_id) {
                    invalidItem = item;
                    hasInvalidItem = true;

                    return;
                }
            });

            if (hasInvalidItem)
                return Swal.fire({
                    title: `WARNING`,
                    text: `Asset "${invalidItem.item_name}" has invalid field.`,
                    icon: 'warning',
                });

            if (this.ACTION == 'Update') {
                const turnoverItems = this.turnoverDataTable.items.map(item => item.admin_accountability_item_id);
                remove_item_ids = this.form.items
                    .filter(item => !turnoverItems.includes(item.admin_accountability_item_id))
                    .map(item => item.id);

                const currentItems = this.form.items.map(item => item.admin_accountability_item_id);
                this.turnoverDataTable.items.forEach(item1 => {
                    if (currentItems.includes(item1.admin_accountability_item_id))
                        item1.id = this.form.items.find(item2 => item2.admin_accountability_item_id == item1.admin_accountability_item_id).id;
                    else item1.new = 1;
                });
            }
            if(this.boundType){
                Object.assign(this.form,{inbound_outbound_type_id:this.GET_INBOUND_OUTBOUND_TYPE.find(e => e.code == this.boundType).value,type: this.SELECTED_TAB})
            }

            const payload = {
                url: this.form.id ?? '',
                admin_location_id: this.form.admin_location_id ?? '',
                department_id: this.form.department_id,
                employee_data_ids: this.form.employee_data_ids.toString(),
                ldrf: this.form.ldrf,
                repairable: this.form.repairable,
                purpose: this.form.purpose,
                audit_findings: this.form.audit_findings,
                items: this.turnoverDataTable.items,
                remove_item_ids: remove_item_ids,
                warehouse_id: this.form.warehouse_id,
                account_to_employee_data_id: this.form.account_to_employee_data_id,
            };
            if(this.boundType){
                Object.assign(payload,{inbound_outbound_type_id:this.GET_INBOUND_OUTBOUND_TYPE.find(e => e.code == this.boundType).value,type: this.SELECTED_TAB})
            }

            Swal.fire({
            icon: "question",
            text: `Are you sure you want to ${this.ACTION} Status?`,
            cancelButtonText: "Cancel",
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonColor: "#397373",
            cancelButtonColor: "grey",
            reverseButtons: true
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch(this.ACTION == 'Submit' ? 'adminAssetTurnoverPost' : 'adminAssetTurnoverPut', payload).then(() => {
                        Swal.fire({
                            title: `${this.ACTION == 'Submit' ? 'Create' : 'Update'} Success`,
                            icon: 'success',
                        });
                        this.closeDialog();
                    }).catch(error => {
                        let text = '';

                        if (error.response.status === 400) text = 'Missing required parameters.';

                        return Swal.fire({
                            title: `Internal Server Error`,
                            text: text,
                            icon: 'error',
                        });
                    });
                }
            });

            
        },
        getAccountabilities() {
            if (
                !this.form.department_id ||
                (this.form.employee_data_ids.length <= 0)
            ) return;

            const payload = {
                url: 'get-accountabilities',
                viewing: this.ACTION == 'Submit' ? undefined : 1,
                id: this.GET_ADMIN_ASSET_TURNOVER_VIEW_ID,
                department_id: this.form.department_id,
                employee_data_ids: this.form.employee_data_ids,
                search_key: this.accountedDataTable.search_key,
                ...this.accountedDataTable.options,
            };

            this.accountedDataTable.expanded = [];
            this.accountedDataTable.loading = true;

            this.$store.dispatch('adminAssetTurnoverGet', payload).then(response => {
                this.accountedDataTable.items = [];
                this.accountedDataTable.items = response.data.data.data;
                this.accountedDataTable.total_items = response.data.total_items;
                this.accountedDataTable.loading = false;


                // if (this.ACTION != 'Submit') {
                //     this.turnoverDataTable.items = [];
                //     this.accountedDataTable.items.forEach(item => {
                //         const formItems = this.form.items.map(element => element.admin_accountability_item_id);

                //         if (formItems.includes(item.admin_accountability_item_id)) this.addItem(item);
                //     });
                // }
            }).catch(error => {
                console.log(error);
                this.accountedDataTable.loading = false;
            });
        },
        getAccountedAssets() {
            if (
                !this.form.department_id ||
                (this.form.employee_data_ids.length <= 0)
            ) return;

            const payload = {
                url: 'get-accounted-assets',
                viewing: this.ACTION == 'Submit' ? undefined : 1,
                id: this.GET_ADMIN_ASSET_TURNOVER_VIEW_ID,
                department_id: this.form.department_id,
                employee_data_ids: this.form.employee_data_ids,
                search_key: this.accountedDataTable.search_key,
                ...this.accountedDataTable.options,
            };

            this.accountedDataTable.dropdownLoading = true;
            this.accountedDataTable.dropdrownItems = [];
            this.turnoverDataTable.items = [];

            this.$store.dispatch('adminAssetTurnoverGet', payload).then(response => {
                response.data.forEach(data => {
                    data.admin_asset_condition_id = undefined,
                    data.files = [];
                });
                this.accountedDataTable.dropdrownItems = []
                this.accountedDataTable.dropdrownItems = response.data;
                this.accountedDataTable.dropdownLoading = false;


                if (this.ACTION != 'Submit') {
                    this.turnoverDataTable.items = [];
                    this.accountedDataTable.dropdrownItems.forEach(item => {
                        const formItems = this.form.items.map(element => element.admin_accountability_item_id);

                        if (formItems.includes(item.admin_accountability_item_id)) {
                            const matchItem = this.form.items.find(element => element.admin_accountability_item_id == item.admin_accountability_item_id);
                            item.id = matchItem.id;
                            item.admin_asset_condition_id = matchItem.admin_asset_condition_id;
                            this.addItem(item);
                        };
                    });
                }
            }).catch(error => {
                console.log(error);
                this.accountedDataTable.dropdownLoading = false;
            });
        },
        getWarehouse(item) {
            item.account_to_employee_data_id = undefined;

            if (this.form.account_to_employee_data_id) {
                item.account_to_employee_data_id = this.form.account_to_employee_data_id;
                item.warehouse_id = undefined;
                return ``;
            }

            item.warehouse_id = (this.form.ldrf && this.form.repairable == 0)
                ? item.disposal_warehouse
                : item.warehouse;
            const warehouse = this.GET_ADM_WAREHOUSE_CODE_DROPDOWN.find(element => element.value == item.warehouse_id);

            return warehouse ? warehouse.text : "";
        },
        isAccountabilityItemsInTurnover(item, all = false) {
            const dropdownItems = this.accountedDataTable.dropdrownItems.filter(element => element.accountability_num == item.accountability_num);
            const itemsInTurnover = this.turnoverDataTable.items.filter(element => element.accountability_num == item.accountability_num);

            if (all && itemsInTurnover.length != dropdownItems.length) return false;
            else if (all && itemsInTurnover.length == dropdownItems.length) return true;
            else if (itemsInTurnover.length <= 0) return false;
            else return true;
        },
        isItemInTurnover(item) {
            const found = this.turnoverDataTable.items.find(element => element.asset_code == item.asset_code);

            if (found) return true;
            return false;
        },
        onLocalFileDelete(index, fileIndex) {
            if (index == -1) return;

            this.turnoverDataTable.items[fileIndex].files.attachments.splice(index, 1);
            this.turnoverDataTable.items[fileIndex].files.files.splice(index, 1);
            this.$forceUpdate();
        },
        removeItem(item, all = false) {
            if (!all) return this.turnoverDataTable.items = this.turnoverDataTable.items
                .filter(element => element.admin_accountability_item_id != item.admin_accountability_item_id);

            this.turnoverDataTable.items = this.turnoverDataTable.items
                .filter(element => element.accountability_num != item.accountability_num);
        },
        renderTurnOverPDF() {
            this.$store.dispatch('printTurnOverForm', {id: this.form.id}).then(response => {
                this.imageLoader(response.header.image_path);
                this.printAccountabilityTurnoverForm(response);
            });
        },
        async imageLoader(imagePath){
            let image = await this.loadImage(imagePath);
            this.$store.commit('COMPANY_IMAGE', image);
        },
        resetForm() {
            this.form = {
                // admin_location_id: undefined,
                department_id: undefined,
                employee_data_ids: [],
                ldrf: undefined,
                repairable: undefined,
                purpose: undefined,
                audit_findings: undefined,
                account_to_employee_data_id: undefined,
            };
        },
    },

    watch: {
        GET_ADMIN_ASSET_TURNOVER_VIEW_ID: {
            handler(val) {
                if (!val) return;

                this.formLoading = true;
                this.$store
                    .dispatch("adminAssetTurnoverGet", { url: val })
                    .then((response) => {
                        response.data.repairable = response.data.repairable.toString();
                        response.data.employee_data_ids = response.data.employee_data_ids.split(',').map(Number);
                        this.form = response.data;
                        this.formLoading = false;
                    });
            },
            immediate: true,
        },
        'form.department_id': {
            handler(val) {
                this.accountedDataTable.items = [];
                this.accountedDataTable.dropdrownItems = [];
                this.turnoverDataTable.items = [];

                if (!val) {
                    this.employee_selection_data = [{ 'value': 0, 'text': 'GENERAL ACCOUNTABILITY', 'department_id': 0 }];
                    this.GET_EMPLOYEE_DATA.filter(e => {
                        this.employee_selection_data.push(e);
                    })

                    return;
                }

                this.employee_selection_data = [{ 'value': 0, 'text': 'GENERAL ACCOUNTABILITY', 'department_id': 0 }];
                this.GET_EMPLOYEE_DATA.filter(e => {
                    if (e.department_id == val) this.employee_selection_data.push(e);
                })

                const ids = this.employee_selection_data.map(item => item.value);
                this.form.employee_data_ids = this.form.employee_data_ids.filter(element => ids.includes(element));

                this.getAccountabilities();
                this.getAccountedAssets();
            },
            immediate: true,
        },
        'form.employee_data_ids': {
            handler(val) {
                if (!val && val != 0) return;
                if (val.length <= 0) {
                    this.accountedDataTable.items = [];
                    this.accountedDataTable.dropdrownItems = [];
                    this.turnoverDataTable.items = [];
                }
                this.getAccountabilities();
                this.getAccountedAssets();
            },
            deep: true,
        },
    },
}
</script>

<style scoped>
    .checkbox {
        margin: 0;
        margin-top: 5px;
    }
</style>
