import { render, staticRenderFns } from "./FasRequisitionComponent.vue?vue&type=template&id=79d21fd7"
import script from "./FasRequisitionComponent.vue?vue&type=script&charset=utf-8&lang=js"
export * from "./FasRequisitionComponent.vue?vue&type=script&charset=utf-8&lang=js"
import style0 from "./FasRequisitionComponent.vue?vue&type=style&index=0&id=79d21fd7&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports