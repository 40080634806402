	<template>
		<v-form ref="form" v-model="valid" lazy-validation>
			<v-card>
				<v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
					<v-row :class="`m-0 ${ $vuetify.breakpoint.name == 'xs' ||  $vuetify.breakpoint.name == 'sm' ||  $vuetify.breakpoint.name == 'md' ? 'd-flex flex-column-reverse' : ''}`">
						<v-col :class="`p-2 ${  $vuetify.breakpoint.name == 'xs' ||  $vuetify.breakpoint.name  == 'sm' ||  $vuetify.breakpoint.name == 'md'  ?  'd-flex justify-center' :''}`" cols="12" sm="12" md="12" lg="11" xl="11">
							<span class="text-h5">{{ actions }} WEBMAIL LOGS</span>
						</v-col>
						<v-col :class="`p-2 pull-right ${ $vuetify.breakpoint.name == 'xs' ||  $vuetify.breakpoint.name  == 'sm' ? 'pull-right' :''}`" cols="12" sm="12" md="12" lg="1" xl="1">
							<v-btn text icon small color="gray" class="float-right" @click="closeDialog()">
								<v-icon>mdi-close-circle</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-card-title>
				<v-card-text class="pa-2 pb-0">
					<v-container fluid class="py-5 pa-2">
						<v-row class="pa-2">
							<v-col>
								<v-data-table
									:headers="datatable.email_logs_view.headers"
									:items="datatable.items"
									:search="filter.search"
									:options.sync="datatable.options"
									:server-items-length="datatable.totalItems"
									:items-per-page="10"
									:loading="loading.datatable"
									:footer-props="{
										itemsPerPageOptions: datatable.itemsPerPageOptions
									}"
									loading-text="Loading... Please wait"
									fixed-header
									class="custom-table"
									>

									<template v-slot:[`item.created_at`]="{ item }">
										{{ item.created_at | formatDate}}
									</template>

									<template v-slot:[`item.updated_by`]="{ item }">
										<span>{{ item.updated_by }}</span>
									</template>

									<template v-slot:item="{ item }">
										<tr style="height: 60px;"> <!-- Adjust the height as needed -->
										<td>{{ item.created_at }}</td>
										<td>{{ item.error }}</td>
										<td>{{ item.updated_by }}</td>
										<!-- Add more table cells as per your data structure -->
										</tr>
									</template>
								</v-data-table>

							</v-col>
						</v-row>				
					</v-container>
				</v-card-text>
			</v-card>
		</v-form>
	</template>
	<script>
	import Swal from 'sweetalert2'
	import { mapGetters } from 'vuex'
	export default {
	data() {
		return {
			valid: false,
			form: {},
			disabled_view:false,
			quotaIsDisabled: false,
			showPassword: false,
			loading:{
				button:false
			},
			breakpoint : this.$vuetify.breakpoint.name,
			dropdowns:{
				employee_list:[],
				department_list:[]
			},
			component_dispatches:[
				"getDepartment",
				"getEmployeeDataDropdown"
			],

            datatable:{

                email_logs_view:{
                    headers:[
                        { text: 'CREATED AT', align: 'start',value:'created_at' },
                        { text: 'MESSAGE', align: 'start', value: 'error'},
                        { text: 'UPDATED BY', align: 'start', value: 'updated_by'},
                    ],
                },

                items:[],
                options:{},
                itemsPerPageOptions: [5, 10, 15, 50, 100],
                itemsPerPage: 10,
                totalItems: 0,
            },
            filter:{
                search:"",
                department_id:'',
                job_title_id:'',
                job_level_id:'',
            },
		}
	},
	computed: {
		...mapGetters(['ACTION',
			'DIALOGS',
			'USERACCOUNT_LOAD',
			'GET_WEBMAIL_VIEW',
			'GET_WEBMAIL_NEW_DATA_TRIGGER',
			'GET_WEBMAIL_TAB_IS_ACTIVE',
			'GET_WEBMAIL_DIALOG_TITLE',
			'GET_WEBMAIL_DISPATCH',
			'GET_LIST_DEPARTMENT',
			'GET_EMPLOYEE_DATA'
		]),
		actions()
		{
			if (this.ACTION == 'View'){
				this.getWebEmail();
			return 'VIEW'
			}
		},
	},
	mounted(){   
		this.getAllDropdowns()
	},
	components: {
		},
	methods: {
		// convertToMegabytes() {
		// 	// Convert input value to megabytes (MB)
		// 	this.form.edit_pop_quota *= 1024;
		// },
		getAllDropdowns(){
			this.component_dispatches.forEach(el=>{
				this.$store.dispatch(el)
			})
		},
		closeDialog() {
			this.$store.commit('WEBMAIL_VIEW', [])
			this.$store.commit('DIALOG', false);
			this.$store.commit('ACTION', '')
			this.resetfields();
		},
		async getWebEmail(){
			this.datatable.items                    = this.GET_WEBMAIL_VIEW.data
            this.datatable.totalItems               = this.GET_WEBMAIL_VIEW.count
		},
		addEmailAccount() {
			this.$refs.form.validate()

			this.form.edit_pop_quota = this.form.unlimited_quota || this.form.unlimited_quota == 1  ? 0 : this.form.edit_pop_quota;

			this.loading.button = true;
			let email_swal_text = this.form.email ? '' : 'Email';
			let array_text = [email_swal_text]
			let filtered = array_text.filter(function (el) {
				return el.replace(',', '');
			})
			if (this.form.email) {
					this.$store.dispatch(this.GET_WEBMAIL_DISPATCH.add,this.form).then(response=>{
						this.loading.button = false;
						Swal.fire({
							text: response.data.message,
							icon: response.data.icon,
							allowOutsideClick: false,
						}).then((result) => {
							if (result.isConfirmed) {
									this.closeDialog()
								}
							});
					});
			} else {
				Swal.fire({
					text: `Fill Required Fields (${filtered})`,
					icon: 'warning',
					allowOutsideClick: false,
				});
			}
		},
		updateEmailAccount() {
			this.$refs.form.validate();
			this.form.edit_pop_quota = this.form.unlimited_quota || this.form.unlimited_quota == 1  ? 0 : this.form.edit_pop_quota;

			this.loading.button = true;
			let email_swal_text = this.form.email ? '' : 'Email';
			let array_text = [email_swal_text]
			let filtered = array_text.filter(function (el) {
				return el.replace(',', '');
			})
			if (this.form.email) {
				this.$store.dispatch(this.GET_WEBMAIL_DISPATCH.update, {
					id: this.form.email,
					payload: this.form,
				}).then(response=>{
					this.loading.button = false;
					Swal.fire(response.data.icon.toUpperCase(),response.data.message,response.data.icon).then(async(result) => {
						if(result.isConfirmed) {
							this.closeDialog()
						}
					});
				});
			} else {
				Swal.fire({
					text: `Fill Required Fields (${filtered})`,
					icon: 'warning',
					allowOutsideClick: false,
				});
			}
		},
		resetfields() {
			this.form = {
				email:'',
				department:'',
				suspend_login:'',
				suspend_incoming:'',
				suspend_outgoing:'',
				unlimited_quota: 0,
				edit_pop_quota : 0
			};
		},
		toggleUnlimitedStorage(){
			if(this.form.unlimited_quota) this.quotaIsDisabled = true
			else this.quotaIsDisabled = false
		}
	},
	watch: {
		DIALOGS:{
			handler(val){
				if(!val){
					this.resetfields()
				}
			}
		},
		'form.edit_pop_quota':{
			deep:true,
			handler(val){
				if(val != 0){
					this.form.unlimited_quota = false
				}
			}
		},
		GET_LIST_DEPARTMENT:{
			deep:true,
			handler(val){
					this.dropdowns.department_list = val
			}
		},
		GET_EMPLOYEE_DATA:{
			deep:true,
			handler(val){
				this.dropdowns.employee_list = val
			}
		}
	},
};
</script>
<style scoped>
::v-deep  .v-data-footer {
  padding: 0;
  margin: 0;
}
</style>
	